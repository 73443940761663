import React from 'react';

import { Button } from '@lumapps/lumx/react';

export type MenuTriggerButtonProps<C extends React.ElementType = typeof Button> = React.ComponentProps<C> & {
    as?: C;
};

/**
 * Menu trigger button.
 * Defaults to the LumX Button, but it can be customized with the `as` prop.
 *
 * @family Menus
 */
export const MenuTriggerButton = React.forwardRef(
    <C extends React.ElementType>(props: MenuTriggerButtonProps<C>, ref: React.Ref<HTMLElement>) => {
        const { as: Component = Button, ...forwardedProps } = props;
        return <Component ref={ref} {...forwardedProps} />;
    },
);
MenuTriggerButton.displayName = 'MenuTriggerButton';
