import { RefObject, useEffect } from 'react';

import { Falsy } from '../types/Falsy';

/**
 * Focus a given element
 * @param focusRef
 */
export const focusElement = (focusRef: RefObject<HTMLElement> | Falsy) => {
    setTimeout(() => {
        if (focusRef && focusRef.current) {
            focusRef.current.focus();
        }
    }, 50);
};

/**
 * Focus HTML element when possible.
 */
export function useFocus(ref: RefObject<HTMLElement> | Falsy, shouldFocus = true) {
    useEffect(() => {
        if (shouldFocus) {
            focusElement(ref);
        }
    }, [ref, shouldFocus]);
}
