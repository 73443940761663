export type AdditionalInfo = {
    i18nLabelCode: string;
    value: number;
};

export type KPI = {
    i18nNameCode: string;
    i18nDescriptionCode: string;
    value: number;
    additionalInfos: AdditionalInfo[];
    icon?: string;
};

export enum DateRangeType {
    sincePublication = 'since_publication',
    lastMonth = 'last_month',
    lastWeek = 'last_week',
    last90Days = 'last_quarter',
    lastYear = 'last_year',
    custom = 'custom',
}

export type Filter = {
    /** identifier of the filter */
    id: string;
    /** lokalise key to translate */
    label: string;
    /** Whether or not the filter should be displayed as disabled */
    isDisabled?: boolean;
};

export type CustomFilterType = {
    filter: {
        uid: string;
        name: Record<string, string>;
    };
    values: string[];
};

export type AnalyticsFilterValue = {
    label: string;
    value: string;
};

export type CustomFilterMappedType = {
    filter: {
        uid: string;
        name: Record<string, string>;
    };
    values: AnalyticsFilterValue[];
};

export type SunbursetDataType = {
    /** Label */
    id: string | null;
    /** Helper */
    helper: string | null;
    /** color */
    color?: string;
    /** Value */
    value?: number;
    /** Percentage */
    percentage?: number;
    /** Child */
    children: SunbursetDataType[];
    /**  */
    cumul?: number;
};

export type LegendInfo = {
    label: string;
    helper: string | null;
    color: string;
    primaryInfo: string;
    secondaryInfo: string;
};

export type TabsList = {
    name: string;
    shouldDisplay: boolean;
    label: string;
    component: JSX.Element;
}[];
