import React from 'react';

import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { FlexBox } from '@lumapps/lumx/react';

import * as type from '../../../../types';
import { BlockReactionsProps, BlockReactionVariants } from '../types';
import { FollowButtonSkeleton } from './FollowButtonSkeleton';
import { MetadataButtonSkeleton } from './MetadataButtonSkeleton';

const constants = getConstants();

export const BlockReactionsSkeleton: type.BlockComponent<BlockReactionsProps> = ({
    onReply,
    share,
    displayOptions,
    settings = {
        displayLikeButton: true,
    },
    isCommentCountVisible,
    theme,
}) => {
    const isWebviewApp = constants.applicationId === AppId.webview;

    const {
        variant = BlockReactionVariants.post,
        buttonSize,

        showButtonLabels = false,
    } = displayOptions || {};

    // Show a comment button links to the comment section of the target page (forced in webview app).
    const showCommentsLinkButton = isWebviewApp || (isCommentCountVisible && variant === BlockReactionVariants.content);

    // Show a comment button that triggers a onComment action (disabled in webview app).
    const showCommentActionButton = variant === BlockReactionVariants.post && isCommentCountVisible;
    // Show a comment button that triggers a onReplay action (disabled in webview app).
    const showReplyCommentButton = isCommentCountVisible && onReply && variant === BlockReactionVariants.comment;

    return (
        <FlexBox orientation="horizontal">
            {settings?.displayLikeButton && (
                <MetadataButtonSkeleton
                    buttonSize={buttonSize}
                    theme={theme}
                    labelSize={showButtonLabels ? 40 : undefined}
                />
            )}
            {showCommentActionButton && (
                <MetadataButtonSkeleton
                    buttonSize={buttonSize}
                    theme={theme}
                    labelSize={displayOptions?.showButtonLabels ? 90 : undefined}
                />
            )}
            {showReplyCommentButton && (
                <MetadataButtonSkeleton
                    buttonSize={buttonSize}
                    theme={theme}
                    labelSize={displayOptions?.showButtonLabels ? 40 : undefined}
                />
            )}
            {showCommentsLinkButton && (
                <MetadataButtonSkeleton
                    buttonSize={buttonSize}
                    theme={theme}
                    labelSize={displayOptions?.showButtonLabels ? 90 : undefined}
                />
            )}
            {settings.displayFollowButton && <FollowButtonSkeleton theme={theme} />}
            {share?.type === type.BlockReactionsShareTypes.post && !isWebviewApp && (
                <MetadataButtonSkeleton
                    buttonSize={buttonSize}
                    theme={theme}
                    labelSize={displayOptions?.showButtonLabels ? 40 : 0}
                />
            )}
        </FlexBox>
    );
};
