import produce from 'immer';
import { bool, func, shape, string } from 'prop-types';

const DOMAIN = 'ROOT/CONFIRM_BOX';

const SHOW_CONFIRM_BOX = `${DOMAIN}/SHOW_CONFIRM_BOX`;

const FREEZE_CONFIRM_BOX = `${DOMAIN}/FREEZE_CONFIRM_BOX`;

const CLOSE_CONFIRM_BOX = `${DOMAIN}/CLOSE_CONFIRM_BOX`;

const defaultState = {
    description: null,
    freezed: false,
    onConfirm: null,
    opened: false,
    title: null,
};

const confirmBoxType = shape({
    description: string,
    freezed: bool,
    onConfirm: func,
    opened: bool,
    title: string,
});

const reducer = produce((draft, action) => {
    switch (action.type) {
        case SHOW_CONFIRM_BOX:
            draft.opened = true;
            draft.title = action.title;
            draft.description = action.description;
            draft.onConfirm = action.onConfirm;
            draft.freezed = false;

            break;

        case FREEZE_CONFIRM_BOX:
            draft.freezed = true;

            break;

        case CLOSE_CONFIRM_BOX:
            draft = defaultState;

            break;

        default:
            return draft;
    }

    return draft;
}, defaultState);

const openConfirmBox = ({ onConfirm, title, description }) => ({
    description,
    onConfirm,
    title,
    type: SHOW_CONFIRM_BOX,
});

const closeConfirmBox = () => ({
    type: CLOSE_CONFIRM_BOX,
});

const freezeConfirmBox = () => ({
    type: FREEZE_CONFIRM_BOX,
});

export { confirmBoxType, freezeConfirmBox, reducer, openConfirmBox, closeConfirmBox };
