(function IIFE() {
    'use strict';

    /////////////////////////////

    function FavoriteButtonController(Analytics, Content, Favorite) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The favorite list key identifier.
         *
         * @type {string}
         */
        vm.listKey = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */

        vm.Favorite = Favorite;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get favorite button classes.
         *
         * @return {Array} An array of favorite button classes.
         */
        function getClasses() {
            var classes = [];

            if (vm.theme === 'dark') {
                classes.push('favorite-button__btn--theme-dark');
            } else {
                classes.push('favorite-button__btn--theme-light');
            }

            return classes;
        }

        function toggleFavorite(cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;
            var action = (vm.entity.markedAsFavorite) ? 'remove' : 'add';
            var currentContent = Content.getCurrent();

            if (vm.kind === 'DirectoryEntry') {
                Analytics.handleTaggingMap(action + '-favorite', 'directory-entry', {
                    directory: _.get(vm.entity, 'directory'),
                    isHomePage: (currentContent) ? Boolean(currentContent.isHomepage) : false,
                    item: vm.entity,
                });
            }

            if (vm.entity.markedAsFavorite) {
                Favorite.del(vm.entity, vm.kind, vm.listKey, cb, errCb);
            } else {
                Favorite.save(vm.entity, vm.kind, vm.listKey, cb, errCb);
            }
        }

        /////////////////////////////

        vm.getClasses = getClasses;
        vm.toggleFavorite = toggleFavorite;

        /////////////////////////////

        /**
         * Initialization of the controller.
         */
        function init() {
            vm.listKey = 'favorite-' + vm.entity.uid;

            vm.tooltip = vm.tooltip || {};
            vm.tooltip.add = vm.tooltip.add || '';
            vm.tooltip.remove = vm.tooltip.remove || '';

            vm.tooltipPosition = vm.tooltipPosition || 'left';
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive that display the favorite button to add or remove entity from user favorites.
     *
     * @param {Object} entity                   The entity (Directory entry, Instance) we want to add or remove from
     *                                          user favorites.
     * @param {string} kind                     The kind of object.
     * @param {string} [theme='light']          The theme to apply to the component.
     *                                          Can be either light or dark.
     * @param {Object} [tooltip]                The text to display in the tooltip (if none given, there will be no
     *                                          tooltip displayed).
     *                                          Must be an object containing two properties:
     *                                              - 'add': the text of the tooltip when the element will be added in
     *                                                       the favorites;
     *                                              - 'remove': the text of the tooltip when the element will be removed
     *                                                          from the favorites;
     * @param {string} [tooltipPosition='left'] The position of the tooltip.
     */

    function FavoriteButtonDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: FavoriteButtonController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                entity: '<lsEntity',
                kind: '@lsKind',
                theme: '@?lsTheme',
                tooltip: '<?lsTooltip',
                tooltipPosition: '@?lsTooltipPosition',
            },
            templateUrl: '/client/front-office/modules/favorites/views/favorite-button.html',
        };
    }

    angular.module('Directives').directive('lsFavoriteButton', FavoriteButtonDirective);
})();
