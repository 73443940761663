/**
 * Format the specified date in ISO format, without the timezone key.
 *
 * Ensure that dates sent to the Monolith backend are valid.
 * The UTC is not supported by the monolith, therefore we cannot use toISOString
 * Since the timezone key is not supported
 *
 * @param {Date} date The date to fix
 * @return {string} The ISO Date string formatted in "%Y-%m-%dT%H:%M:%S.%f"
 */
export const formatISODateString = (date: Date) => {
    return date.toISOString().split('Z')[0];
};
