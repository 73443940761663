import { NAVIGATION_PERMISSIONS } from '@lumapps/navigation/permissions';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

// isUserAllowed() already checks for super admin and instance admin presence
export const hasMenuEditRights = isUserAllowed(NAVIGATION_PERMISSIONS.MENU_EDIT);
export const hasMenuDeleteRights = isUserAllowed(NAVIGATION_PERMISSIONS.MENU_DELETE);
export const hasMenuDropRights = isUserAllowed(NAVIGATION_PERMISSIONS.MENU_DROP);

export const hasMainNavAdminAccess = createSelector(
    hasMenuEditRights,
    hasMenuDeleteRights,
    hasMenuDropRights,
    (menuEditRights, menuDeleteRights, menuDropRights) => menuEditRights || menuDeleteRights || menuDropRights,
);
