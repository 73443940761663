import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useTranslate } from '@lumapps/translations';

import { BROADCAST_ICON } from '../../constants';
import { BROADCASTS } from '../../keys';
import { broadcastTimelineRoute } from '../../routes';

export interface BroadcastSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** True if the user has access to the broadcasts section */
    isAllowed: boolean;
}

/**
 * The broadcast entry in the back-office side navigation
 * @param props BroadcastSideNavigationProps
 * @returns BroadcastSideNavigation
 */
const BroadcastSideNavigation = (props: Partial<BroadcastSideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={broadcastTimelineRoute}
            label={translateKey(BROADCASTS.TITLE)}
            icon={BROADCAST_ICON}
            {...restOfProps}
        />
    );
};

BroadcastSideNavigation.displayName = 'SideNavigationItem';

export { BroadcastSideNavigation };
