export const WIDGET_ARTICLE_LIST_TYPE = 'article-list';

export const DATA_SCOPE = 'widget-article-list-settings';

export enum INFORMATION_DISPLAYED {
    AUTHOR = 'author',
    PUBLICATION_DATE = 'published_at',
    COMMUNITY_NAME = 'shares',
    TITLE = 'title',
    EXCERPT = 'intro',
    SOCIAL = 'reactions',
}

export enum SORTING_VALUES {
    OLDEST_TO_LATEST = 'asc',
    LATEST_TO_OLDEST = 'desc',
}
