import React from 'react';

import { classnames } from '@lumapps/classnames';
import { margin, padding } from '@lumapps/classnames/utils';
import { SkeletonTypography, Typography, Text, Popover, FlexBox, Placement } from '@lumapps/lumx/react';

import { SEGMENT_POPOVER_CLASSNAME } from '../../constants';

export interface SegmentDetailsPopoverSkeletonProps {
    isOpen: boolean;
    anchorRef: React.RefObject<HTMLButtonElement>;
    descriptionTitle: string;
    sizeTitle: string;
}

export const SegmentDetailsPopoverSkeleton = ({
    anchorRef,
    isOpen,
    descriptionTitle,
    sizeTitle,
}: SegmentDetailsPopoverSkeletonProps) => {
    return (
        <Popover
            anchorRef={anchorRef}
            isOpen={isOpen}
            className={classnames(padding('all', 'big'), SEGMENT_POPOVER_CLASSNAME)}
            closeOnEscape
            closeOnClickAway
            placement={Placement.BOTTOM_START}
        >
            <FlexBox orientation="vertical" fillSpace>
                <div className={margin('bottom', 'regular')}>
                    <Text as="h2" className={padding('bottom', 'regular')} typography={Typography.subtitle1}>
                        {descriptionTitle}
                    </Text>
                    <SkeletonTypography typography={Typography.subtitle2} />
                    <SkeletonTypography typography={Typography.subtitle2} />
                    <SkeletonTypography typography={Typography.subtitle2} />
                    <SkeletonTypography typography={Typography.subtitle2} />
                </div>
                <div>
                    <Text as="h2" className={padding('bottom', 'regular')} typography={Typography.subtitle1}>
                        {sizeTitle}
                    </Text>
                    <SkeletonTypography typography={Typography.subtitle2} width="220px" />
                    <SkeletonTypography typography={Typography.subtitle2} width="220px" />
                </div>
            </FlexBox>
        </Popover>
    );
};
