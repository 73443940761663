import { getImageSize } from '@lumapps/utils/images/getImageSize';
import { PathRef, ReactEditor, Transforms, Editor, NodeEntry, Path } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { withoutHistory } from '@lumapps/wrex/slate/utils/withoutHistory';

import { ImageEditor, ImageEditorOptions, ImageGroupElement } from '../types';
import { updateImageNode } from './updateImageNode';

/**
 * Upload an inline image and update its status directly into the editor.
 */
export async function uploadImage(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    files: File[],
    pathRef: PathRef,
) {
    try {
        // Upload file to server.
        const uploadedImagesPromise = options.uploadFunction(files);

        let updatedImagesSizes: ImageGroupElement['images'];
        // Get image intrinsic size & update in node
        await Promise.all(files.map(getImageSize)).then((imagesSizes) => {
            const [imageGroup] = Editor.node(editor, pathRef.current as Path) as NodeEntry<ImageGroupElement>;
            updatedImagesSizes = imageGroup.images.map((image, i) => ({
                ...image,
                height: imagesSizes[i].height,
                width: imagesSizes[i].width,
            }));
            // Update image size
            withoutHistory(editor, () => {
                updateImageNode(editor, { images: updatedImagesSizes }, pathRef);
            });
        });

        const uploadedImages = await uploadedImagesPromise;

        // Image uploaded => update src and loading state
        withoutHistory(editor, () => {
            updateImageNode(
                editor,
                {
                    images: uploadedImages.map(({ id, url }, i) => ({
                        ...updatedImagesSizes[i],
                        id,
                        src: url,
                    })),
                    external: false,
                    shouldFocusCaptionOnMount: false,
                    isLoading: false,
                },
                pathRef,
            );
        });
    } catch (e) {
        const path = pathRef.current;
        if (!path) {
            return;
        }
        Transforms.removeNodes(editor, { at: path });
        focusAt(editor);
    }
}
