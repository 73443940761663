import React from 'react';

import filter from 'lodash/filter';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { Size, Text } from '@lumapps/lumx/react';
import { getRandomValue } from '@lumapps/utils/array/getRandomValue';

import './index.scss';

export interface ColorBubbleLetterProps {
    /** Text that will be truncated to display on the color bubble */
    text: string;
    /** custom className */
    className?: string;
    /** how many characters will be displayed in the bubble, by default is 1 */
    maxLength?: number;
    /** Size of the color bubble, by default is Size.m */
    size?: Size;
    /** List of colors to choose from when rendering the bubble */
    colors?: string[];
    /** display the bubble rounded instead of square */
    isRounded?: boolean;
}

const { defaultColors } = get();

const CLASSNAME = 'color-bubble-letter';

/**
 * Displays a small icon with the first letters of the given text. Used when we do not have the necessary
 * information to display a user's avatar, and instead we display this component with the first letter
 * of the user's name
 *
 * @family Texts
 * @param ColorBubbleLetterProps
 * @returns ColorBubbleLetter
 */
export const ColorBubbleLetter: React.FC<ColorBubbleLetterProps> = ({
    className,
    text,
    maxLength = 1,
    size = Size.m,
    colors = defaultColors,
    isRounded = false,
}) => {
    const textToDisplay = text.substring(0, maxLength).toUpperCase();
    const { element, block } = useClassnames(CLASSNAME);
    const backgroundColor = React.useMemo(() => {
        /**
         * Since the letter on the color bubble is white, we need to make sure that the list of random
         * colors that not include white, or any other colors that start with fff (meaning they are extremely close to white),
         * so we can avoid our users to have a difficulty while reading this component.
         */
        const noWhiteColors = filter(colors, (color) => color.toLowerCase().indexOf('#fff') !== 0);

        return getRandomValue(noWhiteColors, textToDisplay);
    }, [colors, textToDisplay]);

    if (colors.length === 0) {
        return null;
    }

    const sizeClassName = `${CLASSNAME}-${size}`;
    const colorBubbleLetterClassName = block({ rounded: isRounded }, [className, sizeClassName]);
    const letterClassName = element('letter', [sizeClassName]);

    return (
        <div className={colorBubbleLetterClassName} style={{ backgroundColor }}>
            <Text as="span" className={letterClassName}>
                {textToDisplay}
            </Text>
        </div>
    );
};
