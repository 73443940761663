/* eslint-disable default-case,padding-line-between-statements,sort-keys */
import produce from 'immer';

import set from 'lodash/set';

import {
    ANGULAR_INIT,
    DEFAULT_LANGUAGE,
    UPDATE_LOCALE_CURRENT,
    UPDATE_LOCALE_DEFAULT,
    UPDATE_LOCALE_FALLBACK,
    UPDATE_LOCALE_INPUT_CUSTOMER_LANGUAGE,
    UPDATE_LOCALE_INPUT_LANGUAGE,
    UPDATE_LOCALE_PREFERRED,
} from './constants';

// Default state.
const defaultState = {
    current: '',
    default: '',
    fallback: '',
    inputCustomerLanguage: '',
    inputLanguage: '',
    preferred: '',
};

const reducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        const { payload } = action;

        switch (action.type) {
            case ANGULAR_INIT: {
                const { newState } = action;
                return { ...draft, ...newState };
            }

            case UPDATE_LOCALE_CURRENT: {
                const { lang = DEFAULT_LANGUAGE } = payload;

                set(draft, 'current', lang);

                return draft;
            }

            case UPDATE_LOCALE_DEFAULT: {
                const { lang = DEFAULT_LANGUAGE } = payload;

                set(draft, 'default', lang);

                return draft;
            }

            case UPDATE_LOCALE_FALLBACK: {
                const { lang = DEFAULT_LANGUAGE } = payload;

                set(draft, 'fallback', lang);

                return draft;
            }

            case UPDATE_LOCALE_INPUT_CUSTOMER_LANGUAGE: {
                const { lang = DEFAULT_LANGUAGE } = payload;

                set(draft, 'inputCustomerLanguage', lang);

                return draft;
            }

            case UPDATE_LOCALE_INPUT_LANGUAGE: {
                const { lang = DEFAULT_LANGUAGE } = payload;

                set(draft, 'inputLanguage', lang);

                return draft;
            }

            case UPDATE_LOCALE_PREFERRED: {
                const { lang = DEFAULT_LANGUAGE } = payload;

                set(draft, 'preferred', lang);

                return draft;
            }

            default:
                return state;
        }
    });

export { reducer };
