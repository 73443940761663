/* eslint-disable no-use-before-define */
/* istanbul ignore file */
import { CSSProperties } from 'react';

import { DialogProps } from '@lumapps/lumx/react';

export interface CropFocalPointPickerProps {
    /** The url of the image */
    src: string;
    /** The data to display the crop box */
    cropData?: CropInfo | null;
    /** The coordinates of the focal point */
    focusData?: Point;
    /** The class of the element which is used to display crop preview */
    previewClass?: string;
    /**
     * Define the view mode of the cropper
     * See https://github.com/fengyuanchen/cropperjs#viewmode for documentation */
    viewMode?: ViewMode;
    /** Define the fixed aspect ratio of the crop box */
    aspectRatio?: number;
    /** Show the dashed lines above the crop box. */
    guides?: boolean;
    /** A number between 0 and 1. Define the automatic cropping area size (percentage). */
    autoCropArea?: number;
    /** Override style */
    style?: CSSProperties;
    /** Class to apply on the element */
    className?: string;
    /** Indicates if we can modify crop properties. */
    enabled?: boolean;
    /** Indicates if we want to display the internal focus inside the cropper */
    showFocus?: boolean;
    /** Indicates if we want to zoom the canvas on mouse wheel */
    zoomOnWheel?: boolean;
    /** Callback when user modify focal point position */
    onFocusChange: (params: Params) => void;
    /** Callback when user modify crop area */
    onCropChange: () => void;
    /** Called when image is loaded */
    onImageLoaded?: () => void;
}
export interface Params {
    crop: CropInfo | null;
    focus: Point;
}

export interface State {
    /** The coordinates of the focal point, in pixel */
    internalFocus: PixelPoint | null;
    /**
     * The coordinates of the focal point, with a value between
     * -1 and 1 for both axis (0 is center)
     */
    relativeFocus: Point;
}

export interface ImageInfo {
    width: number;
    height: number;
}

export interface CropInfo {
    x: number;
    y: number;
    width: number;
    height: number;
    scaleX?: number;
    scaleY?: number;
    rotate?: number;
}

export interface PixelPoint {
    x: number;
    y: number;
}

export interface Point {
    x: number;
    y: number;
}

export interface DragPosition {
    x: number;
    y: number;
}

export enum DragMode {
    crop = 'crop',
    move = 'move',
    none = 'none',
}

export type ViewMode = 0 | 1 | 2 | 3;

export interface CropperProps {
    style?: object; // eslint-disable-line react/forbid-prop-types
    className?: string;

    // react cropper options
    crossOrigin?: 'anonymous' | '';
    src: string;
    alt?: string;

    // props of option can be changed after componentDidmount
    aspectRatio?: number;
    initialAspectRatio?: number;
    dragMode?: DragMode;
    data?: {
        x: number;
        y: number;
        width: number;
        height: number;
        rotate?: number;
        scaleX?: number;
        scaleY?: number;
    };
    scaleX?: number;
    scaleY?: number;
    enable?: boolean;
    cropBoxData?: {
        left: number;
        top: number;
        width: number;
        height: number;
    };
    canvasData?: {
        left: number;
        top: number;
        width: number;
        height: number;
    };
    zoomTo?: number;
    moveTo?: number[];
    rotateTo?: number;

    // cropperjs options
    // https://github.com/fengyuanchen/cropperjs#options
    // aspectRatio, dragMode, data
    viewMode?: ViewMode;
    preview?: string;
    responsive?: boolean;
    restore?: boolean;
    checkCrossOrigin?: boolean;
    checkOrientation?: boolean;
    modal?: boolean;
    guides?: boolean;
    center?: boolean;
    highlight?: boolean;
    background?: boolean;
    autoCrop?: boolean;
    autoCropArea?: number;
    movable?: boolean;
    rotatable?: boolean;
    scalable?: boolean;
    zoomable?: boolean;
    zoomOnTouch?: boolean;
    zoomOnWheel?: boolean;
    wheelZoomRatio?: number;
    cropBoxMovable?: boolean;
    cropBoxResizable?: boolean;
    toggleDragModeOnDblclick?: boolean;
    minContainerWidth?: number;
    minContainerHeight?: number;
    minCanvasWidth?: number;
    minCanvasHeight?: number;
    minCropBoxWidth?: number;
    minCropBoxHeight?: number;
    ready: (cropperRef: Cropper) => void;
    cropstart?: (event: any) => void;
    cropmove?: (event: any) => void;
    cropend?: (event: any) => void;
    crop?: () => any;
    zoom?: () => any;
}

export interface CropperDialogProps extends DialogProps {
    /** Is the modal opened */
    isOpen: boolean;
    /** Image's url to crop */
    imageUrl: string;
    /** The focal point position */
    focalPoint?: Partial<Point>;
    /** Crop informations to display crop frame */
    cropInfo?: CropInfo;
    /** The function called when the cancel button is clicked. */
    onCancel?: () => void;
    /** The function called when the confirm button is clicked. blob is null if content is not cropped */
    onConfirm: (focus: Point, blob: Blob | null, cropInfo?: CropInfo) => void;
}
