import concat from 'lodash/fp/concat';
import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import getOr from 'lodash/fp/getOr';
import identity from 'lodash/fp/identity';
import map from 'lodash/fp/map';
import matchesProperty from 'lodash/fp/matchesProperty';
import some from 'lodash/fp/some';
import stubTrue from 'lodash/fp/stubTrue';

import { useMutation, useQueryClient } from '@lumapps/base-api/react-query';
import { getCurrentContentId } from '@lumapps/contents/ducks/selectors';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { selectSpaceId } from '@lumapps/spaces/ducks/selectors';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { GLOBAL } from '@lumapps/translations';
import { userIdSelector } from '@lumapps/user/ducks/selectors';

import { queryKeys, save } from '../api';
import { UserContent, UserWidgetData } from '../types';

export function useSetUserContent(legacyWidgetId: string, onSuccess?: () => void, onError?: () => void) {
    const currentContentId = useSelector(getCurrentContentId);
    const spaceId = useSelector(selectSpaceId);
    const { isSpace } = useSpace();
    // Getting content of space id
    const contentId = isSpace && spaceId ? spaceId : currentContentId;
    const userId = useSelector(userIdSelector);
    const queryClient = useQueryClient();
    const notify = useNotification();

    return useMutation({
        mutationFn: async (userWidgetData: UserWidgetData) => {
            // Get or initialize usercontent object
            const prevUserContent: UserContent = queryClient.getQueryData(queryKeys.get(contentId)) || {};

            const prevWidgets = getOr([], 'values.widgets', prevUserContent);
            const content: string = getOr(contentId, 'content', prevUserContent);
            const user = getOr(userId, 'user', prevUserContent);

            // Add or update widget params
            const widgets = some(matchesProperty('uuid', legacyWidgetId), prevWidgets)
                ? map(
                      cond([
                          [matchesProperty('uuid', legacyWidgetId), constant(userWidgetData)],
                          [stubTrue, identity],
                      ]),
                      prevWidgets,
                  )
                : concat(userWidgetData, prevWidgets);

            // Update usercontent
            return save({ content, user, values: { widgets } });
        },
        onSuccess: (result) => {
            queryClient.setQueryData(queryKeys.get(contentId), result);
            onSuccess?.();
        },
        onError: () => {
            notify.error({ translate: GLOBAL.GENERIC_ERROR });
            onError?.();
        },
    });
}
