/* eslint-disable max-lines-per-function */
import memoize from 'lodash/memoize';

/////////////////////////////

// eslint-disable-next-line require-jsdoc
function WidgetLinksSettingsController(
    $rootScope,
    $scope,
    Config,
    FormValidation,
    LxDialogService,
    LxNotificationService,
    Translation,
    UploaderAction,
    Utils,
    Widget,
) {
    'ngInject';

    const widgetLinksSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The active link reference.
     *
     * @type {Object}
     */
    let _activeLinkOriginRef;

    /**
     * Empty link modele, used as template for a new link.
     *
     * @type {Object}
     */
    const _emptyLink = {
        description: {},
        link: {},
        targetBlank: true,
        thumbnail: undefined,
        title: {},
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The Id of the add link dialog.
     *
     * @type {string}
     * @constant
     */
    widgetLinksSettings.DIALOG_ID = 'add-widget';

    /**
     * The current link being edited.
     *
     * @type {Object}
     */
    widgetLinksSettings.activeLink = undefined;
    widgetLinksSettings.isDialogOpen = false;

    /**
     * The link edition form.
     *
     * @type {Object}
     */
    widgetLinksSettings.form = {};

    /**
     * Flag to indicate an upload is in progress.
     *
     * @type {Boolean}
     */
    widgetLinksSettings.isUploading = false;

    /**
     * The Id of the thumbnail uploader.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    widgetLinksSettings.UPLOAD_ID = 'link-thumbnail-uploader';

    /////////////////////////////

    widgetLinksSettings.Config = Config;
    widgetLinksSettings.FormValidation = FormValidation;
    widgetLinksSettings.Translation = Translation;
    widgetLinksSettings.Utils = Utils;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a new link.
     */
    function addLink() {
        widgetLinksSettings.activeLink = angular.fastCopy(_emptyLink);
    }

    /**
     * Clear active link.
     */
    function clearActiveLink() {
        widgetLinksSettings.activeLink = undefined;
        _activeLinkOriginRef = undefined;
    }

    /**
     * Delete a link.
     *
     * @param {string} linkIndex The index of the link to delete.
     */
    function deleteLink(linkIndex) {
        LxNotificationService.confirm(
            Translation.translate('WIDGET_TYPE_LINKS_DELETE'),
            Translation.translate('WIDGET_TYPE_LINKS_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onDeleteLinkConfirm(answer) {
                if (answer) {
                    Widget.getCurrent().properties.items.splice(linkIndex, 1);
                }
            },
        );
    }


    /**
     * Save the new item.
     */
    function saveLink(newLink, index) {
        const { defaultLanguage, lang, image, ...link } = newLink;

        const widget = Widget.getCurrent();
        const currentWidgetProperties = widget.properties;

        if (angular.isUndefined(currentWidgetProperties.items)) {
            currentWidgetProperties.items = [];
        }

        if (index !== undefined) {
            Widget.getCurrent().properties.items[index] = link;
        } else {
            currentWidgetProperties.items.push(link);
        }

        $rootScope.$digest();
    }

    function onDialogClose() {
        clearActiveLink();
        widgetLinksSettings.isDialogOpen = false;

        console.log(Translation);
    }

    function getLinkImage(link) {
        return {
            id: link.blobKey,
            url: link.thumbnail,
        };
    }

    const getWidgetLinkImage = memoize(getLinkImage, (entry) => {
        return entry.thumbnail;
    });

    /////////////////////////////

    widgetLinksSettings.addLink = addLink;
    widgetLinksSettings.deleteLink = deleteLink;
    widgetLinksSettings.saveLink = saveLink;
    widgetLinksSettings.onDialogClose = onDialogClose;
    widgetLinksSettings.defaultLanguage = Translation.inputLanguage;
    widgetLinksSettings.getLinkImage = getWidgetLinkImage;
    widgetLinksSettings.imgProps = {
        className: 'ls-thumbnail',
    };

    widgetLinksSettings.imgStyle = {
        width: '40px',
    };

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const currentWidget = Widget.getCurrent();

        // Init link edit dialog id.
        widgetLinksSettings.DIALOG_ID += currentWidget.uuid;

        // Init items array if it's undefined.
        const linksArray = currentWidget.properties.items;
        if (angular.isUndefined(linksArray)) {
            currentWidget.properties.items = [];
        }
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetLinksSettingsController', WidgetLinksSettingsController);

/////////////////////////////

export { WidgetLinksSettingsController };
