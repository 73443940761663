/* istanbul ignore file */
import { isGroupsAllowed } from '@lumapps/groups/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { GroupsSideNavigation } from './GroupsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isGroupsAllowed(state),
});

const ConnectedGroupsSideNavigation = connect(mapStateToProps)(GroupsSideNavigation);

export { ConnectedGroupsSideNavigation as GroupsSideNavigation };
