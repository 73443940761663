import React from 'react';

import { BaseApiError } from '@lumapps/base-api';
import { getGroupAll } from '@lumapps/customer/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { DEFAULT_LANGUAGE, currentLanguageSelector, getLanguages } from '@lumapps/languages';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { usePlayUser } from '@lumapps/play-roles';
import { isVideoSizeError } from '@lumapps/play-video/utils';
import { createVideo } from '@lumapps/play/api';
import { CreateVideoBody, CreateVideoResult, IsHiddenInValues, UploadingVideo } from '@lumapps/play/api/types';
import { isPlayStandardEnabled } from '@lumapps/play/ducks/selectors';
import { PLAY } from '@lumapps/play/keys';
import { FileUploadFromDrive, UploadType } from '@lumapps/play/types';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PLAY_LIBRARY } from '../../keys';
import { removeExtension } from '../../utils/removeExtension';

export interface UseCreateVideoParams {
    /** Set the video status by default and disable the button select */
    defaultStatus?: 'draft' | 'published';
    /** Upload Type file */
    type?: UploadType;
    /**
     * Set the video availability by default
     * @default { content: false, gallery: false, search: false }
     * */
    isHiddenIn?: IsHiddenInValues;
    /** The loop selected for podcast video */
    loop?: string;
    /** This is the video file from cloud provider that will be uploaded. */
    videoFromDrive?: FileUploadFromDrive;
    /** This is the file that will be uploaded. */
    videoFile?: File;
    /** Callback for clearing file data after failure */
    clearFileData: () => void;
    /** Callback for successfully creating a video */
    onSuccess: () => void;
    /** Set the error to be displayed in the upload dialog */
    setCreateVideoError: (error: string) => void;
}

export const useCreateVideo = ({
    videoFromDrive,
    videoFile,
    type = UploadType.video,
    isHiddenIn = { content: false, gallery: false, search: false },
    loop,
    defaultStatus,
    onSuccess,
    setCreateVideoError,
    clearFileData,
}: UseCreateVideoParams) => {
    const [data, setData] = React.useState<CreateVideoResult | null>(null);

    const languages = useSelector(getLanguages);
    const currentLanguage = useSelector(currentLanguageSelector);
    const instanceId = useSelector(instanceIdSelector);
    const groupAll = useSelector(getGroupAll);
    const isPlayStandard = useSelector(isPlayStandardEnabled);

    const { maxUploadSize, humanReadableMaxUploadSize } = usePlayUser();
    const { translateAndReplace } = useTranslate();

    const defaultLanguage = DEFAULT_LANGUAGE;
    /**
     * The default language used for translatableTextField components. If the user current language is not available,
     * replace it with the default language
     */
    const translatableTextFieldDefaultLanguage = languages.includes(currentLanguage)
        ? currentLanguage
        : defaultLanguage;

    const { error: errorNotif } = useNotification();

    const initialTitle = React.useMemo(() => {
        return removeExtension(videoFile || videoFromDrive);
    }, [videoFile, videoFromDrive]);

    const initialVideo: UploadingVideo = React.useMemo(() => {
        return {
            assetId: '',
            assets: [],
            audioLanguage: translatableTextFieldDefaultLanguage,
            description: {
                lang: translatableTextFieldDefaultLanguage,
                value: '',
                translations: { [translatableTextFieldDefaultLanguage]: '' },
            },
            groupDetails: [{ id: groupAll.id, title: groupAll.name }],
            groupIds: [groupAll.id],
            id: '',
            isDownloadable: false,
            isHiddenIn,
            language: translatableTextFieldDefaultLanguage,
            siteId: instanceId,
            status: defaultStatus || 'draft',
            publicationStatus: defaultStatus || 'draft',
            title: {
                lang: translatableTextFieldDefaultLanguage,
                value: initialTitle,
                translations: { [translatableTextFieldDefaultLanguage]: initialTitle },
            },
            ...(type === UploadType.podcast && {
                kind: UploadType.podcast,
                loop: {
                    templateId: loop,
                },
            }),
            ...(videoFromDrive && {
                input: {
                    url: videoFromDrive.url,
                },
            }),
        };
    }, [
        defaultStatus,
        groupAll.id,
        groupAll.name,
        initialTitle,
        instanceId,
        isHiddenIn,
        loop,
        translatableTextFieldDefaultLanguage,
        type,
        videoFromDrive,
    ]);

    const [createdVideo, setCreatedVideo] = React.useState<UploadingVideo>(initialVideo);

    const onCreateVideo = React.useCallback(async () => {
        try {
            // Let the backend decide if maxUploadSize is undefined
            if (isVideoSizeError(videoFile?.size || videoFromDrive?.sizeBytes, maxUploadSize)) {
                throw new Error(
                    translateAndReplace(
                        !isPlayStandard ? PLAY_LIBRARY.LIMITED_UPLOAD_SIZE_UPGRADE : PLAY_LIBRARY.LIMITED_UPLOAD_SIZE,
                        {
                            SIZE: humanReadableMaxUploadSize,
                        },
                    ),
                );
            }
            const response = await createVideo(
                initialVideo as CreateVideoBody,
                videoFile?.size || videoFromDrive?.sizeBytes,
            );
            setData(response);
            setCreatedVideo({ ...initialVideo, id: response.videoId, assetId: response.assetId });
            onSuccess();
        } catch (error) {
            /** We need to clear the file data in case someone try to set a drive file then a regular file
             *  The result would be that we don't trigger the onError callback but the videoFromDrive error instead
             */
            clearFileData();

            if (error instanceof BaseApiError) {
                /** If the file comes from drive we handle the error in a toaster
                 * because we don't have any place to show the error */
                if (error.response?.status === 429) {
                    if (videoFromDrive) {
                        errorNotif({ translate: PLAY.UPLOAD_DIALOG_CONSUMPTION_WARNING });
                    } else {
                        setCreateVideoError(PLAY.UPLOAD_DIALOG_CONSUMPTION_WARNING);
                    }
                }

                if (error.response?.status === 413) {
                    setCreateVideoError(
                        translateAndReplace(
                            !isPlayStandard
                                ? PLAY_LIBRARY.LIMITED_UPLOAD_SIZE_UPGRADE
                                : PLAY_LIBRARY.LIMITED_UPLOAD_SIZE,
                            {
                                SIZE: humanReadableMaxUploadSize,
                            },
                        ),
                    );
                }
                return;
            }

            if (error instanceof Error) {
                setCreateVideoError(error.message);
                return;
            }

            errorNotif({ translate: GLOBAL.GENERIC_ERROR });
        }
    }, [
        clearFileData,
        errorNotif,
        humanReadableMaxUploadSize,
        initialVideo,
        isPlayStandard,
        maxUploadSize,
        onSuccess,
        setCreateVideoError,
        translateAndReplace,
        videoFile?.size,
        videoFromDrive,
    ]);

    React.useEffect(() => {
        if (videoFromDrive || videoFile) {
            onCreateVideo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoFile, videoFromDrive]);

    return React.useMemo(
        () => ({
            /** Video Unique ID */ // TODO => To be removed when videoFormDialog is implemented (createdVideo contains it now)
            videoId: data?.videoId,
            /** Presigned URL to upload the media file */
            upload: data?.upload,
            /** Media Unique ID */ // TODO => To be removed when videoFormDialog is implemented (createdVideo contains it now)
            assetId: data?.assetId,
            /** Created video datas */
            createdVideo,
            /** Set Created video datas  */
            setCreatedVideo,
        }),
        [createdVideo, data?.assetId, data?.upload, data?.videoId],
    );
};
