(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityRequestFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/request/:methodKey', {}, {
            approve: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'approve',
                },
            },

            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            reject: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'reject',
                },
            },

            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('CommunityRequestFactory', CommunityRequestFactory);
})();
