/**
 * Test if a string is an UUID.
 *
 * @param  {string}  str The string to test.
 * @return {boolean} Whether the string is an UUID or not.
 */
const isUUID = (str: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/g;

    return uuidRegex.test(str);
};

export { isUUID };
