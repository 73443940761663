import { Image } from '@lumapps/lumx-images/types';

/**
 * Format an array of File into the Image structure
 * @param files The picked files
 * @param keepOnlyImages Whether to filter by mimetype images
 * @param limitation Provide a limitation if you want to not iterate above a certain number of files
 * @returns Image[]
 */
export const formatFilesToImageStructure = (
    files: File[],
    keepOnlyImages?: boolean,
    limitation?: { maxNumber: number; currentNumber: number },
): Image[] => {
    const images: Image[] = [];
    const filteredPickedFiles = keepOnlyImages ? files.filter((file) => file.type.includes('image')) : files;

    filteredPickedFiles.every((file, index) => {
        const formattedFile = { alt: '', blobUrl: URL.createObjectURL(file), file };

        // Only keep the picked files not above the max images number
        if (limitation) {
            if (limitation.maxNumber >= index + 1 + limitation.currentNumber) {
                images.push(formattedFile);
                return true;
            }
            return false;
        }

        images.push(formattedFile);
        return true;
    });

    return images;
};
