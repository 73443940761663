/* Blocks: */
export const PARAGRAPH = 'paragraph';
export const HEADLINE = 'headline';
export const TITLE = 'title';
export const SUBTITLE = 'subtitle';
export const CODE_BLOCK = 'code-block';
export const DIVIDER = 'divider';

/* Inlines: */
export const INLINE_CODE = 'inline-code';

/* Marks: */
export const BOLD = 'bold';
export const ITALIC = 'italic';
export const UNDERLINE = 'underline';
export const SPAN = 'span';
export const STRIKETHROUGH = 'strikethrough';
export const COLORED = 'color';

/* Others: */
export const UNDO = 'undo';
export const REDO = 'redo';
export const BLOCK_BODY_TYPE_SELECTOR = 'BlockTypeSelector';

/** Available typography toggleable features */
export enum TYPOGRAPHY_FEATURES {
    /** Allow **bold** text mark */
    bold = BOLD,
    /** Allow *italic* text mark */
    italic = ITALIC,
    /** Allow underline text mark */
    underline = UNDERLINE,
    /** Allow color text mark */
    color = COLORED,
    /** Allow ~~strikethrough~~ text mark */
    strikethrough = STRIKETHROUGH,
    /** Allow paragraph element */
    paragraph = PARAGRAPH,
    /** Allow # Heading 1 element */
    headline = HEADLINE,
    /** Allow ## Heading 2 element */
    title = TITLE,
    /** Allow ### Heading 3 element */
    subtitle = SUBTITLE,
    /** Allow ```code block``` element */
    codeBlock = CODE_BLOCK,
    /** Allow --- divider element */
    divider = DIVIDER,
    /** Allow `inline code` element */
    inlineCode = INLINE_CODE,
    /** Allow text alignment */
    alignment = 'alignment',
}
