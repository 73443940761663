import React, { useCallback } from 'react';

import { FilterHookApi, FilterHookOptions } from '../types';

export type UseListFilterOptions<T> = FilterHookOptions<T, true>;

export type UseListFilter<T> = Required<FilterHookApi<T, true>> & {
    /** retrieve id from entity */
    getId?: (t1: T) => string;
};

const defaultGetId = <T>(t1: T) => t1;

/**
 * Hook that manages a filter that displays a list of items
 * @param options UseListFilterOptions
 * @returns UseListFilter
 */
export const useListFilter = <T>(options?: UseListFilterOptions<T>): UseListFilter<T> => {
    const getId = (options && options.getId) || defaultGetId;

    const [selected, setSelected] = React.useState<T[]>((options && options.defaultSelected) || []);
    const [selection, setSelection] = React.useState<T[]>(selected);

    const { defaultSelected, hasDynamicDefaultSelected } = options || {};

    /**
     * If the default values change, which could happen if the hook is displayed in a different
     * context, we need to reset the selected values on the internal state.
     */
    React.useEffect(() => {
        if (defaultSelected && hasDynamicDefaultSelected) {
            setSelected(defaultSelected);
        }
    }, [defaultSelected, hasDynamicDefaultSelected]);

    const isInSelection = (t: T) => {
        return selection.filter((t1) => getId(t1) === getId(t)).length === 1;
    };

    const onSelected = (sel: T, selToRemove?: T) => {
        let newSelected = [];

        if (!selToRemove) {
            if (isInSelection(sel)) {
                newSelected = selection.filter((s) => getId(s) !== getId(sel));
            } else {
                newSelected = [...selection, sel];
            }
        } else {
            newSelected = [...selection.filter((s) => getId(s) !== getId(selToRemove)), sel];
        }

        setSelection(newSelected);
    };

    const onSelectedMultiple = useCallback((sel: T[] = []) => {
        setSelection(sel);
    }, []);

    const onFilter = () => {
        setSelected(selection);

        return selection;
    };

    const onClearSelection = () => {
        setSelection(selected);
    };

    const onClearSelected = () => {
        setSelected([]);
        setSelection([]);
    };

    const onResetFilters = () => {
        if (options?.defaultSelected) {
            setSelected(options?.defaultSelected);
            setSelection(options?.defaultSelected);
        } else {
            onClearSelected();
        }
    };

    const isSelected = (t: T) => {
        return selected.filter((t1) => getId(t1) === getId(t)).length === 1;
    };

    return {
        onSelected,
        onFilter,
        selected,
        selection,
        onClearSelection,
        isInSelection,
        isSelected,
        onClearSelected,
        onResetFilters,
        onSelectedMultiple,
        getId: options?.getId,
    };
};
