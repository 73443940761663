import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { SettingsMenu } from '@lumapps/settings-menu';
import { isConnected as isConnectedSelector } from '@lumapps/user/ducks/selectors';

import { MENU_OFFSET, PLACEMENT } from '../../constants';

export type HeaderSettingsProps = {
    /** custom class name for the actions wrapper */
    className: string;
    /** whether the user is connected or not */
    isConnected?: boolean;
    theme?: Theme;
};

const CLASSNAME = 'settings-menu';
const HeaderSettings: React.FC<HeaderSettingsProps> = ({
    className = 'header-top',
    isConnected = false,
    theme = Theme.light,
}) => {
    const { element } = useClassnames(className);
    const { isMobile } = useResponsive();
    const isUserConnected = useSelector(isConnectedSelector);

    return !isMobile && (isUserConnected || isConnected) ? (
        <div className={element('settings-menu')}>
            <SettingsMenu
                className={CLASSNAME}
                scope="settings-menu"
                offset={MENU_OFFSET}
                theme={theme}
                placement={PLACEMENT}
            />
        </div>
    ) : null;
};

export { HeaderSettings };
