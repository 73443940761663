import { connect } from '@lumapps/redux/react';

import { getNextPage, getPosts, getPostByPostId, initState } from 'components/components/wall/_ducks/wall_action';

import WidgetWall from './WidgetWall';

/**
 * Map the redux store properties to the WidgetWall component.
 *
 * @param  {Object} state    The Redux Store.
 * @param  {Object} ownProps The WidgetWall props.
 * @return {Object} The mapped props.
 */
const mapStateToProps = (state, ownProps) => {
    if (ownProps.uuid) {
        const widgetScope = state.widgets.wall.find((widget) => widget.uuid === ownProps.uuid);
        const widgetLoadingState = state.loadingState[ownProps.uuid];

        if (widgetScope !== undefined) {
            return {
                hasNextPage: widgetScope.hasNextPage,
                isLoading: (widgetLoadingState && widgetLoadingState.loading) || false,
                nextPageCursor: widgetScope.nextPageCursor,
                posts: widgetScope.posts,
            };
        }
    }

    return {};
};

const ConnectedWidgetWall = connect(
    mapStateToProps,
    {
        getNextPage,
        getPostByPostId,
        getPosts,
        initState,
    },
)(WidgetWall);

export { ConnectedWidgetWall as WidgetWall };
