import React from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ToggleTipButton } from '@lumapps/lumx-buttons/components/ToggleTipButton';
import {
    mdiTableColumnPlusAfter,
    mdiTableColumnRemove,
    mdiTableRowPlusAfter,
    mdiTableRowRemove,
    mdiTrashCanOutline,
    mdiTableColumnPlusBefore,
    mdiTableRowPlusBefore,
    mdiInformationOutline,
} from '@lumapps/lumx/icons';
import {
    ColorPalette,
    Divider,
    Emphasis,
    FlexBox,
    IconButton,
    Orientation,
    Placement,
    Popover,
    PopoverProps,
    Tooltip,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ReactEditor } from '@lumapps/wrex/slate';

import { WREX_TABLE } from '../../../keys';
import { TableEditor } from '../../../types';
import { getActiveTableButtons } from '../../../utils/getActiveTableButtons';

import './index.scss';

interface TableToolbarProps {
    anchorRef: React.RefObject<HTMLElement>;
    editor: ReactEditor & TableEditor;
    isOpen: boolean;
    onClose: PopoverProps['onClose'];
    handleDelete: () => void;
}

const CLASSNAME = 'wrex-table-toolbar';

export const TableToolbar = ({ anchorRef, editor, isOpen, onClose, handleDelete }: TableToolbarProps) => {
    const { pluralize, translateAndReplace, translateKey } = useTranslate();
    const { get: getDataAttributes } = useDataAttributes('table-toolbar');
    const { block, element } = useClassnames(CLASSNAME);

    const { activeButtons, selectedCols, selectedRows } = getActiveTableButtons(editor);

    const toolbarOptions = [
        {
            type: 'button',
            actionId: 'add-column-before',
            label: translateKey(WREX_TABLE.TABLE_TOOLBAR_ADD_COLUMN_BEFORE),
            icon: mdiTableColumnPlusBefore,
            action: () => editor.addColumn('before'),
            isVisible: activeButtons.indexOf('add-column-before') !== -1,
        },
        {
            type: 'button',
            actionId: 'add-column-after',
            label: translateKey(WREX_TABLE.TABLE_TOOLBAR_ADD_COLUMN_AFTER),
            icon: mdiTableColumnPlusAfter,
            action: () => editor.addColumn('after'),
            isVisible: activeButtons.indexOf('add-column-after') !== -1,
        },
        {
            type: 'divider',
            isVisible: activeButtons.indexOf('add-row-before') !== -1 || activeButtons.indexOf('add-row-after') !== -1,
        },
        {
            type: 'button',
            actionId: 'add-row-before',
            label: translateKey(WREX_TABLE.TABLE_TOOLBAR_ADD_ROW_BEFORE),
            icon: mdiTableRowPlusBefore,
            action: () => editor.addRow('before'),
            isVisible: activeButtons.indexOf('add-row-before') !== -1,
        },
        {
            type: 'button',
            actionId: 'add-row-after',
            label: translateKey(WREX_TABLE.TABLE_TOOLBAR_ADD_ROW_AFTER),
            icon: mdiTableRowPlusAfter,
            action: () => editor.addRow('after'),
            isVisible: activeButtons.indexOf('add-row-after') !== -1,
        },
        {
            type: 'divider',
            isVisible: activeButtons.indexOf('delete-column') !== -1 || activeButtons.indexOf('delete-row') !== -1,
        },
        {
            type: 'button',
            actionId: 'delete-column',
            label: translateAndReplace(pluralize(WREX_TABLE.TABLE_TOOLBAR_DELETE_COLUMNS, selectedCols?.length || 1), {
                nb: selectedCols?.length || 1,
            }),
            icon: mdiTableColumnRemove,
            action: () => editor.deleteColumn(),
            color: ColorPalette.red,
            isVisible: activeButtons.indexOf('delete-column') !== -1,
        },
        {
            type: 'button',
            actionId: 'delete-row',
            label: translateAndReplace(pluralize(WREX_TABLE.TABLE_TOOLBAR_DELETE_ROWS, selectedRows?.length || 1), {
                nb: selectedRows?.length || 1,
            }),
            icon: mdiTableRowRemove,
            action: () => editor.deleteRow(),
            color: ColorPalette.red,
            isVisible: activeButtons.indexOf('delete-row') !== -1,
        },
        {
            type: 'divider',
            isVisible: activeButtons.indexOf('delete-table') !== -1,
        },
        {
            type: 'button',
            actionId: 'delete-table',
            label: translateKey(WREX_TABLE.TABLE_TOOLBAR_DELETE_TABLE),
            icon: mdiTrashCanOutline,
            action: handleDelete,
            color: ColorPalette.red,
            isVisible: activeButtons.indexOf('delete-table') !== -1,
        },
    ];

    return (
        <Popover
            contentEditable={false}
            anchorRef={anchorRef}
            isOpen={isOpen}
            placement={Placement.TOP_END}
            offset={{ away: 8 }}
            className={block()}
            closeOnClickAway
            onClose={onClose}
        >
            <FlexBox
                className={element('isolated-buttons', [margin('right', 'regular'), padding('all', 'tiny')])}
                vAlign="center"
                hAlign="center"
                orientation={Orientation.horizontal}
            >
                <ToggleTipButton
                    icon={mdiInformationOutline}
                    content={translateKey(WREX_TABLE.TABLE_TOOLBAR_INFORMATION_MESSAGE)}
                    label={translateKey(GLOBAL.INFORMATION)}
                    popoverPlacement="top"
                    tooltipPlacement="bottom"
                />
            </FlexBox>
            <FlexBox
                className={element('table-buttons', [padding('all', 'tiny')])}
                orientation={Orientation.horizontal}
            >
                {toolbarOptions.map(({ label, actionId, icon, action, type, color, isVisible }, index) =>
                    type === 'button' && isVisible ? (
                        <Tooltip label={label} key={label}>
                            <IconButton
                                label={label || ''}
                                icon={icon}
                                emphasis={Emphasis.low}
                                onClick={action}
                                color={color && color}
                                {...getDataAttributes({ element: 'button', action: actionId })}
                            />
                        </Tooltip>
                    ) : (
                        isVisible && (
                            <Divider
                                className={element('divider')}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`divider-${index}`}
                            />
                        )
                    ),
                )}
            </FlexBox>
        </Popover>
    );
};
