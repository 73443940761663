import { Targets } from '../types';
import store from './store';

const isCustomizationsApiUsed = () => {
    return Boolean(
        window &&
            window.lumapps &&
            window.lumapps.customize &&
            window.lumapps.subscribers &&
            window.lumapps.subscribers.length > 0,
    );
};

/**
 * Since Typescript does not recognize an enum as a Record<string, string>, we need to have something that
 * translates from a raw string to an Targets enum
 * @param target - target of the customization
 * @param fallback - target to be used in case there is no customization set
 */
const getComponent = (target: string, fallback?: Targets): Targets => {
    switch (target) {
        case Targets.APP:
            return Targets.APP;
        case Targets.CONTEXTUAL_ACTIONS:
            return Targets.CONTEXTUAL_ACTIONS;
        case Targets.CONTRIBUTION_MENU:
            return Targets.CONTRIBUTION_MENU;
        case Targets.CONTRIBUTION_BUTTON:
            return Targets.CONTRIBUTION_BUTTON;
        case Targets.HEADER:
            return Targets.HEADER;
        case Targets.NAVIGATION:
            return Targets.NAVIGATION;
        case Targets.SEARCH_BOX:
            return Targets.SEARCH_BOX;
        case Targets.SETTINGS:
            return Targets.SETTINGS;
        case Targets.PAGE:
            return Targets.PAGE;
        case Targets.USER_DIRECTORY:
            return Targets.USER_DIRECTORY;
        case Targets.CUSTOM_LIST:
            return Targets.CUSTOM_LIST;
        case Targets.NEWS:
            return Targets.NEWS;
        case Targets.DIRECTORY:
            return Targets.DIRECTORY;
        case Targets.GDPR_BANNER:
            return Targets.GDPR_BANNER;
        /**
         * All customizable pages need to through this switch
         */
        case Targets.CONTENT:
        case Targets.SEARCH:
        case Targets.PROFILE:
        case Targets.COMMUNITY:
            if (store.doesCustomizationExist({ target })) {
                return target as Targets;
            }

            /**
             * If there is a fallback passed in, before sending the APP target as the default, we send over fallback
             */
            if (fallback) {
                return fallback;
            }

            return target;
        default:
            if (store.doesCustomizationExist({ target })) {
                return target as Targets;
            }

            /**
             * If the target is `content-<id>` and there was no customization found for it on the previous check
             * we default to the CONTENT target. That way, if there is a customization added for all contents, we can display
             * it.
             */
            if (target.indexOf(`${Targets.CONTENT}-`) >= 0) {
                return Targets.CONTENT;
            }

            /**
             * If the target is `widget-<id>` we want to return that target so it can be created as an observer. That way,
             * when the customization for that widget comes in (usually as a HTML widget), the observer is there and the widget
             * can be updated.
             */
            if (target.indexOf(`${Targets.WIDGET}-`) >= 0) {
                return target as Targets;
            }

            /**
             * If there is a fallback passed in, before sending the APP target as the default, we send over fallback
             */
            if (fallback) {
                return fallback;
            }

            return Targets.APP;
    }
};

/**
 * Computes the given id and type in order to determine which targets need to be rendered.
 * @param id - target id
 * @param type - target type
 */
const computeCustomizationTargets = (id: string, type?: string) => {
    const computedPageId = getComponent(id);
    /**
     * If there is a specific type, we need to determine that:
     * - It is a valid type
     * - It is different than the target PAGE and APP, since those are already used
     * - That the computed type is different than the computed id.
     *
     * These checks all prevent that we do not render the same target twice.
     */
    const computedPageType = type && type !== Targets.PAGE ? getComponent(type) : Targets.APP;
    const shouldAddPageTypeCustomization =
        computedPageType !== Targets.APP && computedPageType !== Targets.PAGE && computedPageType !== computedPageId;

    return {
        id: computedPageId,
        type: computedPageType,
        shouldAddPageTypeCustomization,
    };
};

export { isCustomizationsApiUsed, getComponent, computeCustomizationTargets };
