/* istanbul ignore file */

import findIndex from 'lodash/findIndex';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import remove from 'lodash/remove';
import set from 'lodash/set';
import unset from 'lodash/unset';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { SA_TOPICS } from '../../keys';
import { TopicsEdition, TopicListItem, FetchInitialTopicsStatuses } from '../../types';

export interface TopicManagementFormState {
    fetchInitialTopicsStatus: FetchInitialTopicsStatuses;
    newTopicText: string;
    newTopicError: string;
    editTopicText: string;
    editTopicError: string;
    editingTopicId: TopicListItem['id'];
    topics: {
        [topicId: string]: TopicListItem;
    };
    changes: TopicsEdition<string>;
    confirmTopicDeletion: boolean;
    topicToDelete: TopicListItem | null;
    searchValue: string;
}

const initialState: TopicManagementFormState = {
    fetchInitialTopicsStatus: FetchInitialTopicsStatuses.initial,
    newTopicText: '',
    newTopicError: '',
    editTopicText: '',
    editTopicError: '',
    editingTopicId: '',
    topics: {},
    changes: {
        added: [],
        updated: [],
        deleted: [],
    },
    confirmTopicDeletion: false,
    topicToDelete: null,
    searchValue: '',
};

const { actions, reducer } = createSlice({
    domain: 'saTopics/topicManagement',
    initialState,
    reducers: {
        fetchTopics(state: TopicManagementFormState) {
            set(state, 'fetchInitialTopicsStatus', FetchInitialTopicsStatuses.loading);
        },
        fetchTopicsSuccess(state: TopicManagementFormState, action: PayloadAction<Array<TopicListItem>>) {
            set(state, 'fetchInitialTopicsStatus', FetchInitialTopicsStatuses.success);
            set(state, 'topics', keyBy(action.payload, 'id'));
        },
        fetchTopicsFailure(state: TopicManagementFormState, action: PayloadAction<Array<TopicListItem>>) {
            set(state, 'fetchInitialTopicsStatus', FetchInitialTopicsStatuses.failure);
            set(state, 'topics', keyBy(action.payload, 'id'));
        },
        setNewTopicText(state: TopicManagementFormState, action: PayloadAction<string>) {
            set(state, 'newTopicError', '');
            set(state, 'newTopicText', action.payload);
            if (
                findKey(
                    state.topics,
                    (topic) => topic.name.trim().toLowerCase() === action.payload.trim().toLowerCase(),
                )
            ) {
                set(state, 'newTopicError', SA_TOPICS.CREATE_TOPIC_DUPLICATE_ERROR);
            }
        },
        setEditText(state: TopicManagementFormState, action: PayloadAction<string>) {
            const { editingTopicId } = state;

            if (editingTopicId) {
                const value = action.payload;
                set(state, 'editTopicError', '');
                set(state, 'editTopicText', value);

                // If empty value, set as required
                if (!value) {
                    set(state, 'editTopicError', SA_TOPICS.EDIT_TOPIC_ERROR_EMPTY);
                }

                // If value already taken by another topic, set as duplicate
                if (
                    findKey(
                        state.topics,
                        (topic) => topic.name.toLowerCase() === value.toLowerCase() && topic.id !== editingTopicId,
                    )
                ) {
                    set(state, 'editTopicError', SA_TOPICS.CREATE_TOPIC_DUPLICATE_ERROR);
                }
            }
        },
        editTopic(state: TopicManagementFormState, action: PayloadAction<TopicListItem['id']>) {
            const topicToEdit = action.payload;
            set(state, 'editingTopicId', topicToEdit || '');
            set(state, 'editTopicText', get(state.topics, `${topicToEdit}.name`, ''));
            set(state, 'editTopicError', '');
        },
        /**
         * Adds a new topic to the list and to the "added" object
         * @param state The current state
         * @param action The payload with the new topic name
         */
        createNewTopic(state: TopicManagementFormState, action: PayloadAction<TopicListItem['name']>) {
            const tempId = generateUUID();
            const name = action.payload;

            if (name) {
                const newTopic = { name, id: tempId, isNew: true };
                set(state, `topics.${tempId}`, newTopic);
                state.changes.added.push(newTopic);
                set(state, 'newTopicText', '');
            }
        },
        /**
         * Updates an existing topic.
         * If the topic was just created, update the corresponding "added" item.
         * If it has already been updated, update the corresponding "updated" item.
         * Else, add to "updated" array.
         * @param state The current state
         * @param action The payload with the updated topic id and name
         */
        updateTopic(state: TopicManagementFormState, action: PayloadAction<{ id: string; name: string }>) {
            const { id, name } = action.payload;
            const topicToUpdate = state.topics[id];

            if (topicToUpdate.isNew) {
                const addedIndex = findIndex(state.changes.added, { name: topicToUpdate.name });
                set(state, `changes.added.${addedIndex}.name`, name);
            } else {
                const updatedIndex = findIndex(state.changes.updated, { id });
                if (updatedIndex > -1) {
                    set(state, `changes.updated.${updatedIndex}.name`, name);
                } else {
                    state.changes.updated.push({ id, name });
                }
            }
            set(state, `topics.${id}`, { id, name });
            set(state, 'editingTopicId', '');
        },
        /**
         * Remove a topic
         * If the topic was just created, remove the corresponding "added" item..
         * Else, remove from "updated" array if necessary and add to "deleted" array.
         * @param state The current state
         * @param action The payload with the updated topic id and name
         */
        removeTopic(state: TopicManagementFormState, action: PayloadAction<string>) {
            const id = action.payload;
            const topicToRemove = get(state, `topics.${id}`);

            if (topicToRemove.isNew) {
                remove(state.changes.added, { name: topicToRemove.name });
            } else {
                remove(state.changes.updated, { id: topicToRemove.id });
                state.changes.deleted.push({ id });
            }
            unset(state, `topics.${id}`);
        },
        openDeleteConfirmationModal: (state: TopicManagementFormState, action: PayloadAction<TopicListItem>) => {
            set(state, 'confirmTopicDeletion', true);
            set(state, 'topicToDelete', action.payload);
        },
        closeDeleteConfirmationModal: (state: TopicManagementFormState) => {
            set(state, 'confirmTopicDeletion', false);
        },
        onSearch: (state: TopicManagementFormState, action: PayloadAction<string>) => {
            set(state, 'searchValue', action.payload || '');
        },
        reset() {
            return initialState;
        },
    },
});

export { actions, reducer, initialState };
