import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiApps } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { DIRECTORIES } from '../../keys';
import { admin } from '../../routes';

export interface ModuleSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const ModuleSideNavigation = (props: Partial<ModuleSideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem route={admin()} label={translateKey(DIRECTORIES.MODULES)} icon={mdiApps} {...restOfProps} />
    );
};

ModuleSideNavigation.displayName = 'SideNavigationItem';

export { ModuleSideNavigation };
