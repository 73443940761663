import React, { createRef, PureComponent } from 'react';
import { bool, func, object, string } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';

import { mdiDotsVertical } from '@lumapps/lumx/icons';
import { Dropdown, Emphasis, IconButton, List, ListItem, Size, Theme } from '@lumapps/lumx/react';
import { DeleteDocumentDialog } from '../../../document/delete';
import { RenameDocumentDialog } from '../../../document/rename';
import { translate } from '../../../../translations';

/**
 * Button that display a dialog to create a folder.
 */
export class DocumentManagementMenu extends PureComponent {
    static propTypes = {
        /** Is button disabled. */
        // eslint-disable-next-line react/boolean-prop-naming
        disabled: bool,
        /** Document we want to manage. */
        media: object.isRequired,
        /** Function called after content changed. */
        onChange: func.isRequired,
        /** The theme to apply to the component. Can be either light or dark. */
        theme: string,
        /** Type of media (FILE or FOLDER). */
        type: string.isRequired,
    };

    static defaultProps = {
        disabled: false,
        theme: Theme.light,
    };

    constructor(props) {
        super(props);

        this._INITIAL_STATE = {
            isMenuOpen: false,
        };
        this.state = cloneDeep(this._INITIAL_STATE);

        this.anchorMenuRef = createRef(null);

        this.handleOpenDeleteDialog = this.handleOpenDeleteDialog.bind(this);
        this.handleOpenRenameDialog = this.handleOpenRenameDialog.bind(this);
        this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this);
        this.handleCloseRenameDialog = this.handleCloseRenameDialog.bind(this);
        this.handleValidateDeleteDialog = this.handleValidateDeleteDialog.bind(this);
        this.handleValidateRenameDialog = this.handleValidateRenameDialog.bind(this);

        this.ACTIONS_LIST = [
            {
                handler: this.handleOpenRenameDialog,
                label: 'GLOBAL.RENAME',
                value: 'RENAME',
            },
            {
                handler: this.handleOpenDeleteDialog,
                label: 'GLOBAL.DELETE',
                value: 'DELETE',
            },
        ];
    }

    /**
     * Open delete dialog.
     */
    toggleMenu() {
        const { isMenuOpen } = this.state;
        this.setState({ isMenuOpen: !isMenuOpen });
    }

    /**
     * Open delete dialog.
     */
    handleOpenDeleteDialog() {
        this.setState({ openDeleteDialog: true });
    }

    /**
     * Open rename dialog.
     */
    handleOpenRenameDialog() {
        this.setState({ openRenameDialog: true });
    }

    /**
     * Close delete dialog.
     */
    handleCloseDeleteDialog() {
        this.setState({ openDeleteDialog: false });
    }

    /**
     * Close rename dialog.
     */
    handleCloseRenameDialog() {
        this.setState({ openRenameDialog: false });
    }

    /**
     * Close delete dialog and call onChange props method.
     */
    handleValidateDeleteDialog() {
        const { onChange } = this.props;

        this.handleCloseDeleteDialog();
        onChange();
    }

    /**
     * Close rename dialog and call onChange props method.
     */
    handleValidateRenameDialog() {
        const { onChange } = this.props;

        this.handleCloseRenameDialog();
        onChange();
    }

    render() {
        const { disabled, media, theme, type } = this.props;
        const { isMenuOpen, openDeleteDialog, openRenameDialog } = this.state;
        const { docPath } = media;

        return (
            <>
                <IconButton
                    theme={theme}
                    disabled={disabled}
                    size={Size.l}
                    emphasis={Emphasis.low}
                    icon={mdiDotsVertical}
                    ref={this.anchorMenuRef}
                    onClick={() => this.toggleMenu()}
                    aria-haspopup
                    label={translate('FRONT.WIDGET.WIDGET_FILE_LIST.MENU.ARIA_LABEL')}
                    aria-expanded={isMenuOpen || undefined}
                />
                <Dropdown isOpen={isMenuOpen} onClose={() => this.toggleMenu()} anchorRef={this.anchorMenuRef}>
                    <List role="menu">
                        {map(this.ACTIONS_LIST, (choice) => (
                            <ListItem
                                key={choice.value}
                                onItemSelected={choice.handler}
                                size={Size.tiny}
                                linkProps={{
                                    role: 'menuitem',
                                }}
                                role="none"
                            >
                                {translate(choice.label)}
                            </ListItem>
                        ))}
                    </List>
                </Dropdown>
                {openRenameDialog && (
                    <RenameDocumentDialog
                        media={media}
                        open={openRenameDialog}
                        type={type}
                        onClose={this.handleCloseRenameDialog}
                        onValidate={this.handleValidateRenameDialog}
                    />
                )}

                {openDeleteDialog && (
                    <DeleteDocumentDialog
                        docPath={docPath}
                        open={openDeleteDialog}
                        type={type}
                        onClose={this.handleCloseDeleteDialog}
                        onValidate={this.handleValidateDeleteDialog}
                    />
                )}
            </>
        );
    }
}
