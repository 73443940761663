import { combineReducers } from '@lumapps/redux';
import createSlice from '@lumapps/redux/createSlice';

import * as listSliceConfig from './listSlice';
import * as modalSliceConfig from './modalSlice';

export interface MemberListDialogState {
    list: listSliceConfig.MemberListState;
    modal: modalSliceConfig.MemberModalState;
}

export const MemberListDialogStates = {
    list: listSliceConfig.MemberListStates,
    modal: modalSliceConfig.MemberModalStates,
};

export const initialState = {
    list: listSliceConfig.initialState,
    modal: modalSliceConfig.initialState,
};

const sliceCreator = (domainBase: string) => {
    const listSlice = createSlice({
        domain: `${domainBase}/list`,
        initialState: listSliceConfig.initialState,
        reducers: listSliceConfig.memberListReducer,
    });

    const modalSlice = createSlice({
        domain: `${domainBase}/modal`,
        initialState: modalSliceConfig.initialState,
        reducers: modalSliceConfig.memberModalReducers,
    });

    const actions = {
        list: listSlice.actions,
        modal: modalSlice.actions,
    };

    const reducer = combineReducers({
        list: listSlice.reducer,
        modal: modalSlice.reducer,
    });

    return { reducer, actions };
};

const { reducer: programMemberListDialogReducer, actions: programMemberListDialogActions } = sliceCreator(
    'SAMembers/ProgramMemberListDialog',
);

const { reducer: sharerListDialogReducer, actions: sharerListDialogActions } =
    sliceCreator('SAMembers/SharerListDialog');

export {
    programMemberListDialogReducer,
    programMemberListDialogActions,
    sharerListDialogReducer,
    sharerListDialogActions,
};
