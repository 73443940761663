import { createSelector } from '@lumapps/redux/reselect';

import { saProgramsSelector } from '../selectors';
import { SAProgramState } from '../types';
import { ProgramSelectionModalState } from './slice';

const programSelectionModalSelector = createSelector(
    saProgramsSelector,
    (saPrograms: SAProgramState) => saPrograms.programSelectionModal,
);

export const programSelectionModalValueSelector = createSelector(
    programSelectionModalSelector,
    (programSelectionModal: ProgramSelectionModalState) => programSelectionModal.value,
);

export const programSelectionModalSearchValueSelector = createSelector(
    programSelectionModalSelector,
    (programSelectionModal: ProgramSelectionModalState) => programSelectionModal.searchValue,
);

export const programSelectionModalProgramIdsByNameSelector = createSelector(
    programSelectionModalSelector,
    (programSelectionModal: ProgramSelectionModalState) => programSelectionModal.programIdsByName,
);

export const programSelectionModalStatusSelector = createSelector(
    programSelectionModalSelector,
    (programSelectionModal: ProgramSelectionModalState) => programSelectionModal.modalStatus,
);
