import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import type { UploadingVideoFormDialogProps } from './UploadingVideoFormDialog';

const LazyUploadingVideoFormDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'uploading-video-form-dialog' */
            './UploadingVideoFormDialog'
        ),
);

/**
 * FormDialog used to upload and edit new videos
 * @param UploadingVideoFormDialogProps
 * @returns UploadingVideoFormDialog
 */
export const UploadingVideoFormDialog = ({ isOpen, ...props }: UploadingVideoFormDialogProps) => (
    <DelayedSuspense shouldLoad={isOpen}>
        {(setShouldMount) => (
            <LazyUploadingVideoFormDialog onVisibilityChange={setShouldMount} isOpen={isOpen} {...props} />
        )}
    </DelayedSuspense>
);
