import React from 'react';

import {
    ChoiceRenderer,
    SelectFieldMultiple,
    SelectFieldMultipleProps,
} from '@lumapps/combobox/components/SelectFieldMultiple';

import { FILTERS_MODE } from '../../constants';
import { UseListFilter } from '../../hooks/useListFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

import './index.scss';

export interface MultipleSelectFilterProps<T> extends FilterProps {
    /** list of options to display as list items */
    options: T[] | ChoiceRenderer<T>;
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelectedMultiple: UseListFilter<T>['onSelectedMultiple'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    selection: UseListFilter<T>['selection'];
    /** custom props for the Select field multiple component */
    selectFieldMultipleProps?: Partial<SelectFieldMultipleProps>;
    /** callback when the select is open */
    onOpen?: () => void;
    /** callback when the select is closed */
    onSelectClose?: () => void;
    /**
     * callback to be executed once there is a change in the filter,
     * useful for doing custom logic when a filter changes
     */
    onChange?: UseListFilter<T>['onSelectedMultiple'];
}

const CLASSNAME = 'multiple-select-filter';

/**
 * Filter that displays a list of options in a multi select fashion.
 *
 * @family Filters
 * @param MultipleSelectFilterProps
 * @returns MultipleSelectFilter
 */
export const MultipleSelectFilter = <T,>({
    options,
    onSelectedMultiple,
    selection,
    selectFieldMultipleProps = {},
    mode,
    onOpen,
    onSelectClose,
    onChange,
    ...props
}: MultipleSelectFilterProps<T>) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    return (
        <Filter {...props} hideTitle mode={mode}>
            <SelectFieldMultiple<T>
                label={mode === FILTERS_MODE.EXPOSED_FACETS ? undefined : props.title}
                choices={options}
                onChange={(sel: T[]) => {
                    onSelectedMultiple(sel);

                    if (onChange) {
                        onChange(sel);
                    }
                }}
                isOpen={isOpen}
                setOpen={(open) => {
                    setIsOpen(open);

                    if (open && onOpen) {
                        onOpen();
                    }

                    if (!open && onSelectClose) {
                        onSelectClose();
                    }
                }}
                value={selection}
                className={CLASSNAME}
                {...selectFieldMultipleProps}
            />
        </Filter>
    );
};
