import { learningFilledImage } from '@lumapps/lumx/custom-icons';
import { ColorPalette } from '@lumapps/lumx/react';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta, NotificationDescriptionParams } from '../../types';
import { getContentTitle } from '../contentUtils';
import { getLearningRoute } from '../linksUtils';

const getLearningDescription = (params: NotificationDescriptionParams, notificationType: string): string => {
    const { notification, senderDetails, translation, currentLanguage } = params;

    const learningTitle = getContentTitle(translation, notification);

    return translation.translateAndReplace(notificationType, {
        AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
        LEARNING_TITLE: learningTitle,
    });
};

const notificationTypeLearning: Record<string, NotificationMeta> = {
    [NotificationTypes.LearningCongratulation]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_CONGRATULATION),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningReminder]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_REMINDER),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningCourseBeginning]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_COURSE_BEGINNING),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningCourseEndingToday]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_COURSE_ENDING_TODAY),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningCourseEndingSoon]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_COURSE_ENDING_SOON),
        getRoute: getLearningRoute,
    },
};

export { notificationTypeLearning };
