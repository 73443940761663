import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiGooglePodcast, mdiPlay } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    Badge,
    ColorPalette,
    Icon,
    Text,
    Thumbnail as LumxThumbnail,
    Typography,
} from '@lumapps/lumx/react';
import FallbackImage from '@lumapps/play/assets/images/fallback.svg';
import { PLAY } from '@lumapps/play/keys';
import { formatTime } from '@lumapps/play/utils';
import { useDimensions } from '@lumapps/responsive';
import { useTranslate } from '@lumapps/translations';

import { PLAY_VIDEO } from '../../../keys';
import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

import './index.scss';

interface ThumbnailProps {
    /** Image alternative text */
    alt?: string;
    /**
     * Whether the thumbnail is interactive or not (add hover state and Play icon)
     * ℹ️ This props is passed automagically by the <BlockVideo.Link /> and <BlockVideo.Button /> components
     */
    isInteractive?: boolean;
    /** Whether to display the badge or not */
    withBadge?: boolean;
    /** Boolean used to display caption in thumbnail */
    withCaption?: boolean;
    /** Whether to display the Play icon or not */
    withIcon?: boolean;
}

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-thumbnail` as const;

const ICON_SIZES = { m: 0, l: 256, xl: 640 };

/**
 * Thumbnail that display the video duration.
 * It can be interactive and display another state on hover.
 */
export const Thumbnail = ({ alt = '', isInteractive, withBadge, withCaption, withIcon }: ThumbnailProps) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    const { canDisplay, video, theme } = useBlockVideoContext();
    const { ref, currentBreakpoint: iconSize } = useDimensions({ breakpoints: ICON_SIZES });

    if (!canDisplay('FIGURE')) {
        return null;
    }

    if (!video?.restricted && !video?.thumbnail) {
        return null;
    }

    return (
        <div className={block({ 'is-interactive': Boolean(isInteractive) })} ref={ref}>
            <LumxThumbnail
                alt={alt}
                aspectRatio={AspectRatio.wide}
                badge={
                    withBadge && (
                        <Badge color={ColorPalette.grey}>
                            <Icon icon={video?.kind === 'podcast' ? mdiGooglePodcast : mdiPlay} />
                        </Badge>
                    )
                }
                className={element('image')}
                image={video.restricted || !video?.thumbnail ? '' : video.thumbnail.url}
                fallback={
                    <img
                        alt={video.restricted ? translateKey(PLAY_VIDEO.RESTRICTED_VIDEO) : ''}
                        className={element('fallback')}
                        src={FallbackImage}
                    />
                }
                theme={theme}
            />

            {withIcon && (
                <Icon
                    alt={translateKey(PLAY.PLAY_VIDEO)}
                    className={element('icon')}
                    color={ColorPalette.light}
                    icon={mdiPlay}
                    size={iconSize}
                />
            )}

            {withCaption && video.duration && canDisplay('DURATION') && (
                <Text
                    as="span"
                    className={element('video-duration')}
                    color={ColorPalette.light}
                    typography={Typography.caption}
                    noWrap
                >
                    {formatTime(video.duration)}
                </Text>
            )}
        </div>
    );
};
