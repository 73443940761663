import BaseApi, { BaseApiPromise } from '@lumapps/base-api';

import { RoleAssignment } from '../types';
import {
    CreateRoleAssignmentParams,
    GetMeResponse,
    GetRoleAssignmentListParams,
    GetRoleAssignmentListResponse,
    GetUserListParams,
    GetUserListResponse,
    UpdateRoleAssignmentParams,
} from './types';

const getBaseApi = (subPath: string) =>
    new BaseApi({
        path: `play/${subPath}`,
        version: BaseApi.versions.v2,
    });

const rolesApi = getBaseApi('role-assignments');
export const usersApi = getBaseApi('users');

/**
 * Query keys object used to manage React Query keys
 *
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const playUsersQueryKeys = {
    all: () => ['play', 'users'] as const,
    me: () => [...playUsersQueryKeys.all(), 'me'] as const,
    list: (params: GetUserListParams) => [...playUsersQueryKeys.all(), params],
};

/**
 * Use this utility to only get data from an axios promise.
 * It avoids being constantly doing `const {data} = await ...; return data`
 * @example
 * return getDataFromBaseApiPromise(rolesApi.get("..."))
 */
const getDataFromBaseApiPromise = async <T>(axiosPromise: BaseApiPromise<T>) => {
    const { data } = await axiosPromise;
    return data;
};

/**
 * Create a new role assignment.
 */
export const createRoleAssignment = (params: CreateRoleAssignmentParams) =>
    getDataFromBaseApiPromise(rolesApi.post<RoleAssignment>('', params));

/**
 * Retrieve list of role assignments.
 */
export const getRoleAssignmentList = (params: GetRoleAssignmentListParams, fetchKey?: string) =>
    getDataFromBaseApiPromise(rolesApi.get<GetRoleAssignmentListResponse>('', { params }, true, true, fetchKey));

/**
 * Cancel retrieving list of role assignments.
 */
export const cancelGetRoleAssignmentList = (fetchKey?: string) => rolesApi.cancel('', undefined, fetchKey);

/**
 * Retrieve one role assignment
 */
export const getRoleAssignment = (id: string) => getDataFromBaseApiPromise(rolesApi.get<RoleAssignment>(`/${id}`));

/**
 * Remove the assignment
 */
export const deleteRoleAssignment = (id: string) => rolesApi.delete<void>(`/${id}`);

/**
 * Update role assignment
 * @param {String} id The role ID
 * @param {UpdateRoleAssignmentParams} params
 */
export const updateRoleAssignment = (id: string, params: UpdateRoleAssignmentParams) =>
    getDataFromBaseApiPromise(rolesApi.patch<RoleAssignment>(`/${id}`, params));

/**
 * Get users, support pagination, search for autocomplete, optional calculated 'has a role in scope' field.
 */
export const getUserList = async (params: GetUserListParams, signal?: AbortSignal) => {
    const { data: userList } = await usersApi.get<GetUserListResponse>('', { params, signal });

    return userList;
};

/**
 * Get current user information, including permissions in Play
 */
export const getMe = async (signal?: AbortSignal) => {
    const { data: me } = await usersApi.get<GetMeResponse>('/me', { signal });

    return me;
};
