export enum PLAY_VIDEO_USAGE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457705311
     */
    CHINA_VIDEOS = 'PLAY.CHINA_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457705497
     */
    CHINA_VIDEOS_COUNT = 'PLAY.CHINA_VIDEOS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277231895
     */
    EMPLOYEE_VIDEOS = 'PLAY.EMPLOYEE_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277783848
     */
    EMPLOYEES_VIDEOS_COUNT = 'PLAY.EMPLOYEES_VIDEOS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277782894
     */
    PLATFORM_VIDEOS_COUNT = 'PLAY.PLATFORM_VIDEOS_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=276382214
     */
    VIDEO_USAGE = 'PLAY.VIDEO_USAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281765360
     */
    VIDEO_USAGE_EXCEEDED = 'PLAY.VIDEO_USAGE_EXCEEDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281765402
     */
    VIDEO_USAGE_UNAVAILABLE = 'PLAY.VIDEO_USAGE_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277223757
     */
    VIDEO_USAGE_WARNING_MESSAGE = 'PLAY.VIDEO_USAGE_WARNING_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=539121594
     */
    PLATFORM_USAGE = 'PLAY.PLATFORM_USAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=541339368
     */
    USAGE_PER_SITES = 'PLAY.USAGE_PER_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=541994134
     */
    CORPORATE_VIDEOS = 'PLAY.CORPORATE_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=541995496
     */
    OPTMIZED_VIDEOS = 'PLAY.OPTIMIZED_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=542108887
     */
    SHOW_SPECIFIC_SITE = 'PLAY.SHOW_SPECIFIC_SITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=542530102
     */
    VIDEO_QUOTA_NOT_CONFIGURED = 'PLAY.VIDEO_QUOTA_NOT_CONFIGURED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546403031
     */
    UNABLE_TO_DISPLAY_PAGE = 'PLAY.UNABLE_TO_DISPLAY_PAGE',
}
