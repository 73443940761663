import { connect } from '@lumapps/redux/react';

import { setFormFieldValue, initSocialNetworks } from '../../../ducks/shareableWorkflow/actions';

import {
    getAvailableSocialNetworks,
    getShareableWorkflowSocialNetworksStatus,
    getShareableWorkflowProgramId,
} from '../../../ducks/shareableWorkflow/selectors';

import { SocialNetworksManagement } from './SocialNetworksManagement';

const mapStateToProps = (state: any) => ({
    programId: getShareableWorkflowProgramId(state),
    status: getShareableWorkflowSocialNetworksStatus(state),
    availableSocialNetworks: getAvailableSocialNetworks(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setFormFieldValue: (fieldId: string, value: any) => dispatch(setFormFieldValue(fieldId, value)),
    initSocialNetworks: (programId: string) => dispatch(initSocialNetworks(programId)),
});

const ConnectedSocialNetworksManagement = connect(mapStateToProps, mapDispatchToProps)(SocialNetworksManagement);

export { ConnectedSocialNetworksManagement as SocialNetworksManagement };
