(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetInstanceListController($location, $scope, $state, Config, Content, Features, Instance,
        InstancePicker, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /*
         * Instance picker modal identifier - only for basic mode.
         *
         * @type {string}
         */
        var _INSTANCE_PICKER_ID = 'widget-instance-list-picker-';

        /**
         * Indicates if it's the first time we list the directory entries.
         *
         * @type {boolean}
         */
        var _isFirstFilterize = true;

        /**
         * Projection fields, field the backend will send back.
         *
         * @type {string}
         */
        var _projection = {
            items: {
                createdAt: true,
                deletableFromFavorite: true,
                description: true,
                favicon: true,
                homepage: true,
                id: true,
                isInFavoriteFeedKeys: true,
                logo: true,
                markedAsFavorite: true,
                metadata: true,
                name: true,
                properties: true,
                slug: true,
                subtitle: true,
                title: true,
                uid: true,
            },
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the fields to display.
         */
        function _initFields() {
            var properties = vm.widget.properties;

            if (angular.isUndefinedOrEmpty(properties.viewMode)) {
                properties.viewMode = 'vertical';
            }

            // Style backward compatibility.
            if (angular.isDefinedAndFilled(properties.viewMode)) {
                if (properties.viewMode === 'vertical') {
                    properties.viewMode = 'list';
                } else if (properties.viewMode === 'horizontal') {
                    properties.viewMode = 'grid';
                }
            }

            if (angular.isUndefinedOrEmpty(properties.viewModeVariant)) {
                properties.viewModeVariant = 'group';
            }

            if (angular.isUndefinedOrEmpty(properties.itemsSeparator)) {
                properties.itemsSeparator = true;
            }

            if (angular.isDefinedAndFilled(properties.itemsPerLine) && angular.isString(properties.itemsPerLine)) {
                var itemsPerLine = parseInt(properties.itemsPerLine, 10);

                if (angular.isNumber(itemsPerLine)) {
                    properties.itemsPerLine = itemsPerLine;
                }
            }

            if (angular.isDefinedAndFilled(properties.slideshow)) {
                properties.slideshowEnabled = properties.slideshow;
            }

            if (angular.isDefinedAndFilled(properties.slideshowAutoPlay)) {
                properties.slideshowAutoplay = properties.slideshowAutoPlay;
            }

            // Fields manager.
            var defaultInstanceFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.INSTANCE_FIELDS);

            if (angular.isUndefinedOrEmpty(properties.fields)) {
                if (angular.isString(defaultInstanceFields[0])) {
                    properties.fields = [];

                    angular.forEach(defaultInstanceFields, function forEachContentFields(instanceField) {
                        properties.fields.push({
                            enable: true,
                            name: instanceField,
                        });
                    });
                } else {
                    properties.fields = angular.fastCopy(defaultInstanceFields);
                }
            } else if (angular.isString(properties.fields[0])) {
                var fields = [];

                angular.forEach(properties.fields, function forEachFields(field) {
                    fields.push({
                        enable: true,
                        name: field,
                    });
                });

                angular.forEach(defaultInstanceFields, function forEachContentField(instanceField) {
                    if (angular.isObject(instanceField)) {
                        instanceField = instanceField.name;
                    }

                    if (!_.includes(properties.fields, instanceField)) {
                        fields.push({
                            enable: false,
                            name: instanceField,
                        });
                    }
                });

                properties.fields = fields;
            }
        }

        /**
         * In "list" configuration case, filter instances by the order selected in settings.
         *
         * @param {Object} additionalFilters The additional filters from contetn filter.
         */
        function _initInstanceList(additionalFilters) {
            var properties = _.get(vm.widget, 'properties', {});

            var params = {
                maxResults: parseInt(properties.maxNumber, 10) || Instance.maxResults,
                showHidden: false,
                sortOrder: '',
            };

            if (properties.type === 'favorites') {
                params.userFavoritesOnly = true;
            }

            var listKey = vm.widgetListCtrl.getOriginalListKey() + '-' + vm.widgetListCtrl.SELECTION_TYPES.list;

            vm.widgetListCtrl.setListKey(listKey);

            params.sortOrder = (properties.listOrderDir === 'desc') ? '-' : '';
            params.sortOrder += (properties.listOrder) ? properties.listOrder : 'name';

            if (angular.isDefinedAndFilled([properties.metadata, additionalFilters.metadata], 'some')) {
                var metadata = angular.fastCopy(properties.metadata);
                if (angular.isDefinedAndFilled(additionalFilters.metadata)) {
                    metadata = angular.fastCopy(additionalFilters.metadata);

                    angular.forEach(additionalFilters.metadata,
                        function forEachAdditionalMetadata(additionalMetadata, metadataId) {
                            if (angular.isUndefinedOrEmpty(additionalMetadata)) {
                                metadata[metadataId] = _.get(properties.metadata, '[' + metadataId + ']', []);
                            }
                        }
                    );
                }

                params.combinedMetadata = [];

                angular.forEach(metadata, function forEachMetadata(metadataKey) {
                    if ((angular.isString(metadataKey) || angular.isArray(metadataKey)) &&
                        angular.isDefinedAndFilled(metadataKey)) {
                        params.combinedMetadata.push({
                            metadata: metadataKey,
                        });
                    }
                });
            }

            if (angular.isDefinedAndFilled(additionalFilters)) {
                if (angular.isDefinedAndFilled(additionalFilters.query)) {
                    params.name = additionalFilters.query;
                }

                Instance.filterize(params, vm.widgetListCtrl.updateListItems,
                    vm.widgetListCtrl.updateListItems, listKey, _projection);
            } else if (_isFirstFilterize && vm.widget.isMainWidget &&
                angular.isDefinedAndFilled($location.search().filters)) {
                var filters = Utils.getSimpleUrlParams($location.search().filters);

                params.name = filters.query;

                if (angular.isDefinedAndFilled(filters.metadata)) {
                    filters.metadata = (angular.isArray(filters.metadata)) ? filters.metadata : [filters.metadata];
                    params.combinedMetadata = params.combinedMetadata || [];

                    angular.forEach(filters.metadata, function forEachFilterMetadata(metadataKeys) {
                        if (angular.isUndefinedOrEmpty(metadataKeys)) {
                            return;
                        }

                        // Might contains multiple comma separated values.
                        angular.forEach(metadataKeys.split(','), function forEachMetadataKeys(metadataKey) {
                            if (angular.isUndefinedOrEmpty(metadataKey) ||
                                (!angular.isString(metadataKey) && !angular.isArray(metadataKey))) {
                                return;
                            }

                            params.combinedMetadata.push({
                                metadata: metadataKey,
                            });
                        });
                    });
                }

                Instance.filterize(params, vm.widgetListCtrl.updateListItems,
                    vm.widgetListCtrl.updateListItems, listKey, _projection);
            } else {
                Instance.cacheFilterize(params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                    listKey, _projection);
            }

            _isFirstFilterize = false;
        }

        /**
         * In "pick" mode, display the instances previously picked in the settings.
         */
        function _initInstancePicked() {
            var listKey = vm.widgetListCtrl.getOriginalListKey() + '-' + vm.widgetListCtrl.SELECTION_TYPES.pick;
            var properties = _.get(vm.widget, 'properties', {});

            vm.widgetListCtrl.setListKey(listKey);

            if (angular.isDefinedAndFilled(properties.instancesIds)) {
                var params = {
                    ids: properties.instancesIds,
                    maxResult: properties.instancesIds.length,
                };

                Instance.cacheFilterize(params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                    listKey, _projection);
            } else {
                Instance.displayList(listKey, _projection).length = 0;
                vm.widgetListCtrl.updateListItems();
            }
        }

        /**
         * List instance depending on the selection type.
         *
         * @param {Object} additionalFilters The additional filters from contetn filter.
         */
        function _initList(additionalFilters) {
            switch (vm.widget.properties.type) {
                case vm.widgetListCtrl.SELECTION_TYPES.pick:
                    _initInstancePicked();
                    break;

                default:
                case 'favorites':
                case 'all':
                    _initInstanceList(additionalFilters);
                    break;
            }
        }

        /**
         * Open instance picker (only when widget selction type is pick).
         */
        function _openInstancePicker() {
            if (vm.widget.properties.type !== vm.widgetListCtrl.SELECTION_TYPES.pick ||
                Content.getViewMode() !== 'basic' || Content.getAction() === 'get') {
                return;
            }

            Utils.waitForAndExevute('#' + _INSTANCE_PICKER_ID, InstancePicker);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize controller.
         *
         * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
         */
        vm.init = function init(saveListKey) {
            _INSTANCE_PICKER_ID += vm.widget.uuid;

            _initFields();

            vm.widgetListCtrl.init(saveListKey);
        };

        /**
         * Set parent controller.
         *
         * @param {Object} widgetListCtrl The parent controller object.
         */
        vm.setParentController = function setParentController(widgetListCtrl) {
            vm.widgetListCtrl = widgetListCtrl;

            vm.widgetListCtrl.widgetListChildCtrl = {
                initList: _initList,
                onWidgetListClick: _openInstancePicker,
            };

            vm.init(true);
        };
    }

    /////////////////////////////

    /**
     * The instance list widget.
     * Displays a list of instances (either manually picked or the whole list with filters applied).
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetInstanceListDirective() {
        'ngInject';

        function WidgetInstanceListLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetInstanceListController,
            controllerAs: 'vm',
            link: WidgetInstanceListLink,
            replace: true,
            require: ['widgetInstanceList', '^widgetList'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-instance-list/views/widget-instance-list.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetInstanceList', WidgetInstanceListDirective);
})();
