import { connect } from '@lumapps/redux/react';
import { Dispatch, FrontOfficeStore } from '@lumapps/redux/types';

import { getWidget, getWidgetLoadingState } from '../../../ducks/selectors';
import { actions } from '../../../ducks/slice';
import { fetchWidgetBlocksById } from '../../../ducks/thunks/fetchWidgetBlocksById';
import { WidgetState } from '../../../ducks/type';
import { BlockTabFilteredContainer, BlockTabFilteredContainerProps } from './BlockTabFilteredContainer';

export const mapStateToProps = (state: FrontOfficeStore, props: BlockTabFilteredContainerProps) => {
    const { path } = props;
    const widgetId = path?.[0];
    const widget = getWidget(state, { widgetId });
    return {
        widgetType: widget?.widgetType as string,
        loadingState: getWidgetLoadingState(state, { widgetId }),
        blockResolutionInfo: widget?.blockResolutionInfo,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch, props: BlockTabFilteredContainerProps) => {
    const { path, widget } = props;
    const widgetId = path?.[0];
    return {
        onFetchTabContent: (
            widgetType: string,
            blockResolutionInfo?: WidgetState['blockResolutionInfo'],
            tabId?: string,
        ) => {
            const widgetFilters = widget.filters;

            const filters = tabId === 'ALL' ? { ...widgetFilters, tab: '' } : { ...widgetFilters, tab: tabId };
            if (widgetId) {
                dispatch(
                    fetchWidgetBlocksById({
                        params: { filters, ...blockResolutionInfo },
                        widgetId,
                        widgetType,
                        isMainWidget: Boolean(props.widget.isMainWidget),
                    }),
                );
                dispatch(
                    actions.setWidgetFilters({
                        widgetId,
                        filters,
                    }),
                );
            }
        },
        onFetchMoreTabContent: (
            widgetType: string,
            cursor?: string,
            blockResolutionInfo?: WidgetState['blockResolutionInfo'],
            tabId?: string,
        ) => {
            const widgetFilters = widget.filters;

            const filters = tabId === 'ALL' ? { ...widgetFilters, tab: undefined } : { ...widgetFilters, tab: tabId };

            if (widgetId) {
                dispatch(
                    fetchWidgetBlocksById({
                        params: { filters, cursor, ...blockResolutionInfo },
                        widgetId,
                        widgetType,
                        isMainWidget: Boolean(props.widget.isMainWidget),
                    }),
                );
            }
        },
    };
};

export const ConnectedBlockTabFilteredContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BlockTabFilteredContainer);
export { ConnectedBlockTabFilteredContainer as BlockTabFilteredContainer };
