(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserFeedSubscriptionsService(FeedSubscribersFactory, User) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains the list of users to add to the list of subscribers of the feed.
         *
         * @type {Array}
         */
        var _addSubscriptionList = [];

        /**
         * Contains the list of users to remove from the list of subscribers of the feed.
         *
         * @type {Array}
         */
        var _deleteSubscriptionList = [];

        /**
         * Indicates if the subscriptions are loading.
         *
         * @type {boolean}
         */
        var _isLoading = {};

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if a feed is already in the subscriptions.
         *
         * @param  {Array}   subscriptions The list of subscriptions.
         * @param  {string}  feedKey       The feed key we want to check if already in subscriptions.
         * @return {boolean} If the given feed key is already in the subscriptions.
         */
        function _containSubscription(subscriptions, feedKey) {
            if (angular.isUndefinedOrEmpty(subscriptions) || angular.isUndefinedOrEmpty(feedKey)) {
                return false;
            }

            var keys = _.map(subscriptions, 'feed');

            return _.includes(keys, feedKey);
        }

        /**
         * Save the subscribers of a feed.
         *
         * @param  {Object}   parameters The parameters of the save request.
         *                               It must contains at least the "feedKey" of the feed we want to update.
         *                               It should contains the users to add "addedUsers" and/or the users to remove
         *                               "removedUsers".
         * @param  {string}   listKey    The list key.
         * @param  {Function} [cb]       The success callback.
         * @param  {Function} [errCb]    The error callback.
         * @return {Promise}  The promise of the save.
         */
        function _save(parameters, listKey, cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            _isLoading[listKey] = true;

            return FeedSubscribersFactory.saveSubscription(parameters, function onSaveSubscriptionSuccess(response) {
                _addSubscriptionList = [];
                _deleteSubscriptionList = [];

                cb(response);

                _isLoading[listKey] = false;
            }, function onSaveSubscriptionError(err) {
                errCb(err);

                _isLoading[listKey] = false;
            }).$promise;
        }

        /**
         * Update the list of users having subscribed to a feed.
         *
         * @param {string} listKey The list key.
         * @param {Object} user    The user to update.
         */
        function _updateList(listKey, user) {
            var subscribers = User.displayList(listKey);

            if (angular.isUndefinedOrEmpty(subscribers)) {
                _.set(subscribers, '[0]', user);

                return;
            }

            for (var idx = 0, len = subscribers.length; idx < len; ++idx) {
                var subscriber = subscribers[idx];

                if (user.isRemoved) {
                    if (subscriber.email === user.email) {
                        subscribers.splice(idx, 1);

                        return;
                    }
                } else if (subscriber.id === user.id) {
                    subscriber = angular.fastCopy(user);

                    return;
                }
            }

            if (!user.isRemoved) {
                subscribers.push(user);
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add an user to the subscribers of a feed.
         *
         * @param {Object} user    The user we want to add to subscribers
         * @param {string} feedKey The feed key of the feed we want to add.
         * @param {string} listKey The list key.
         */
        function add(user, feedKey, listKey) {
            listKey = listKey || 'default';

            if (!angular.isArray(user.subscriptions)) {
                user.subscriptions = [];
            }

            if (_containSubscription(user.subscriptions, feedKey)) {
                return;
            }

            if (!_.includes(_addSubscriptionList, user.email)) {
                _addSubscriptionList.push(user.email);
            }

            _deleteSubscriptionList = _.without(_deleteSubscriptionList, user.email);

            user.isRemoved = false;

            _updateList(listKey, user);
        }

        /**
         * Check if the connected user has subscribed to the given feed.
         *
         * @param  {string}  feedKey The feed key we want to check if the user is subscribed to.
         * @return {boolean} If the user has subscribed to the given feed.
         */
        function hasSubscription(feedKey) {
            var connectedUser = User.getConnected();
            if (angular.isUndefined(connectedUser) || angular.isUndefinedOrEmpty(connectedUser.subscriptions) ||
                angular.isUndefinedOrEmpty(feedKey)) {
                return false;
            }

            return _containSubscription(connectedUser.subscriptions, feedKey);
        }

        /**
         * Check if the given list key is loading/saving.
         *
         * @param  {string}  listKey The list key.
         * @return {boolean} If the given list key is loading/saving.
         */
        function isLoading(listKey) {
            listKey = listKey || 'default';

            return _isLoading[listKey];
        }

        /**
         * Remove an user from the subscribers of a feed.
         *
         * @param {Object} user    The user we want to remove from the subscribers.
         * @param {string} listKey The list key.
         */
        function remove(user, listKey) {
            listKey = listKey || 'default';

            user.isRemoved = true;

            _addSubscriptionList = _.without(_addSubscriptionList, user.email);

            if (!_.includes(_deleteSubscriptionList, user.email)) {
                _deleteSubscriptionList.push(user.email);
            }

            _updateList(listKey, user);
        }

        /**
         * Save the subscribers of a feed.
         *
         * @param  {string}   listKey The list key.
         * @param  {string}   feedKey The feed key of the feed to save.
         * @param  {Function} [cb]    The success callback.
         * @param  {Function} [errCb] The error callback.
         * @return {Promise}  The promise of the save.
         */
        function save(listKey, feedKey, cb, errCb) {
            return _save({
                addedUsers: _addSubscriptionList,
                feed: feedKey,
                removedUsers: _deleteSubscriptionList,
            }, listKey, cb, errCb);
        }

        /////////////////////////////

        service.add = add;
        service.hasSubscription = hasSubscription;
        service.isLoading = isLoading;
        service.remove = remove;
        service.save = save;
    }

    /////////////////////////////

    angular.module('Services').service('UserFeedSubscriptions', UserFeedSubscriptionsService);
})();
