/* istanbul ignore file */
import { isNannyAllowed, isNannyEnabled } from '@lumapps/nanny/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { NannySideNavigation } from './NannySideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isNannyAllowed(state);
    const isEnabled = isNannyEnabled(state);

    return {
        isAllowed: isEnabled && isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedNannySideNavigation = connect(mapStateToProps, mapDispatchToProps)(NannySideNavigation);

export { ConnectedNannySideNavigation as NannySideNavigation };
