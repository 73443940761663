import { useContext } from 'react';

import { CustomizationsContext } from './context';

const useCustomizations = () => {
    const context = useContext(CustomizationsContext) || { modes: {}, featureFlags: {} };

    return {
        ...context,
    };
};

export { useCustomizations };
