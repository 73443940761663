import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { useTranslate } from '@lumapps/translations';
import { ChipGroup, Chip, Size, Icon, Tooltip } from '@lumapps/lumx/react';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';

import { SOCIAL_PLATFORMS_CONFIG } from '../../../constants';

export interface SocialNetworksSelectProps {
    availableSocialNetworks: Array<SocialNetworks>;
    selectedSocialNetworks: {
        hasError?: boolean;
        errorMessage?: string;
        value: Record<SocialNetworks, boolean>;
    };
    incompatibleSocialNetworks: Record<SocialNetworks, Array<string>>;
    onChange(value: any): void;
}

export const SocialNetworksSelect: React.FC<SocialNetworksSelectProps> = ({
    onChange,
    availableSocialNetworks,
    selectedSocialNetworks,
    incompatibleSocialNetworks,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();

    const values = selectedSocialNetworks?.value || {};
    const validSocialNetworks = availableSocialNetworks.filter(
        (socialNetworkId) => values[socialNetworkId] && !incompatibleSocialNetworks[socialNetworkId],
    );

    return (
        <>
            <p className="shareable-workflow-form__title shareable-workflow-form__title--light mt+">
                {translateKey('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.MAKE_SHAREABLE_ON')}
            </p>

            {selectedSocialNetworks.hasError && selectedSocialNetworks.errorMessage && (
                <p className="shareable-workflow-form__helper shareable-workflow-form__required mb">
                    {translateKey(selectedSocialNetworks.errorMessage)}
                </p>
            )}

            {validSocialNetworks.length === 0 && (
                <p className="shareable-workflow-form__helper shareable-workflow-form__required mb">
                    {translateKey('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.SELECT_SOCIAL_NETWORKS.REQUIRED')}
                </p>
            )}

            {availableSocialNetworks &&
                availableSocialNetworks.every((socialNetworkId) => incompatibleSocialNetworks[socialNetworkId]) && (
                    <p className="shareable-workflow-form__helper shareable-workflow-form__required mb">
                        {translateKey('FRONT.SOCIAL_ADVOCACY.SHAREABLE_CONTENT.MEDIA_TYPE_NOT_SUPPORTED')}
                    </p>
                )}

            {!isEmpty(availableSocialNetworks) && (
                <ChipGroup>
                    {availableSocialNetworks.map((socialNetworkId) => {
                        const socialNetworkConfig = SOCIAL_PLATFORMS_CONFIG[socialNetworkId];
                        const isSelected = values[socialNetworkId];
                        const isDisabled = Boolean(
                            incompatibleSocialNetworks[socialNetworkId] &&
                                incompatibleSocialNetworks[socialNetworkId].length > 0,
                        );
                        const handleClick = () => onChange({ ...values, [socialNetworkId]: !isSelected });
                        const messages = incompatibleSocialNetworks[socialNetworkId];

                        return (
                            <Tooltip
                                key={socialNetworkId}
                                label={
                                    messages &&
                                    messages
                                        .map((message) =>
                                            translateAndReplace(message, { SOCIAL_NETWORK: socialNetworkId }),
                                        )
                                        .join('\n')
                                }
                            >
                                <Chip
                                    before={<Icon icon={socialNetworkConfig?.icon} size={Size.xs} />}
                                    isDisabled={Boolean(isDisabled)}
                                    isSelected={isSelected}
                                    onClick={handleClick}
                                    onBeforeClick={handleClick}
                                >
                                    {socialNetworkConfig?.label}
                                </Chip>
                            </Tooltip>
                        );
                    })}
                </ChipGroup>
            )}
        </>
    );
};
