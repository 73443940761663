export const MAX_USERS_RETURNED = 30;
export const GROUP_METHODS = {
    BY_USERS: 'users',
    BY_GROUP_ID: 'group_id',
    DYNAMIC_GROUP: 'dynamic_group',
};
export const MAX_RULE_USER_ATTRIBUTES = 4;
export const MAX_FEED_RETURNED = 25;
export const DYNAMIC_GROUP_OPERATOR = {
    is: 'Is (single value)',
    is_not: 'Is not (single value)',
    in: 'In (multiple value)',
    not_in: 'Not in (multiple value)',
};
export const PLATFORM_TYPE = 'platform';
export const SITE_TYPE = 'site';
export const PATH_LIST = [
    'domain',
    'organizations[primary=true]/location',
    'organizations[primary=true]/department',
    'organizations/0/location',
    'addresses[primary=true]/country',
    'addresses/0/country',
];

export enum PROVISIONING_MODE {
    MANUAL = 'manual',
    PROVIDER = 'provider',
    DYNAMIC_BY_USER_ATTRIBUTES = 'dynamic_by_user',
}

export const GROUPS_PER_FETCH = 10;
