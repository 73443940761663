/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable id-blacklist */
import React, { useEffect } from 'react';

import { utc } from 'moment';

import { Progress } from '@lumapps/lumx/react';

import { any, arrayOf, func, string, bool, object, objectOf } from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import get from 'lodash/get';

import { isConnected, isExternal, isMicrosoft } from 'components/services/user';

import TaskGraphList from './taskGraphList/TaskGraphList';

import TaskHeader from './taskHeader/TaskHeader';

import TaskList from './taskList/TaskList';

import MyTasks from './myTasks/MyTasks';

import { error } from '../../../services/notification';

import { translate as t } from '../../../translations';

import { getPlannerPlanTasks, getUserTasks, patchPlannerTask, patchOutlookTask } from '../../tasks/api/TaskApi';

/**
 * Display the widget tasks component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The tasks react element.
 */

const WidgetTasks = ({
    content,
    filteredTasks,
    isFilteredByLateTasks,
    isFilteredByReminderTasks,
    isFilteredByTodayTasks,
    isInit,
    isLoading,
    isMyTasksCheckedItemsDisplayed,
    isOrderedByDueDateDesc,
    initState,
    myTasksNewTodo,
    myTasksTodoItems,
    myTasksUserContentWidget,
    selectedTasks,
    setIsFilteredByLateTasks,
    setIsFilteredByReminderTasks,
    setIsFilteredByTodayTasks,
    setIsLoading,
    setIsMyTasksCheckedItemsDisplayed,
    setIsOrderedByDueDateDesc,
    setFilteredTasks,
    setMyTasksNewTodo,
    setMyTasksTodoItems,
    setSelectedTasks,
    setMyTasksUserContentWidget,
    setTasks,
    style,
    tasks,
    value,
    updateTask,
    userContent,
    uuid,
}) => {
    const { alreadyDoneDay, bucket, isTeamScoped, plan, provider, viewMode } = value;
    const theme = get(style, ['content', 'theme'], 'light');

    if (!isConnected() || (provider !== 'lumapps' && (isExternal() || !isMicrosoft()))) {
        return null;
    }

    /* UseEffect for init and cleaning the widgetTasks component properties in the redux store. */
    useEffect(() => {
        initState({ uuid });
    }, []);

    /* UseEffect for retrieiving tasks. */
    useEffect(() => {
        if (tasks.length > 0) {
            setTasks({ data: [], uuid });
        }

        if (isEmpty(provider) || provider === 'lumapps' || (isTeamScoped && isEmpty(plan))) {
            return;
        }

        setIsLoading({ data: true, uuid });

        const usedFunc = plan && plan.id ? getPlannerPlanTasks : getUserTasks;
        usedFunc(plan && plan.id ? plan.id : provider)
            .then((res) => {
                setTasks({
                    data: res.data.items
                        ? res.data.items.map((_task) => Object.assign(_task, { isLoading: false }))
                        : [],
                    uuid,
                });
            })
            .catch(() => error(t('FRONT.WIDGET_TASKS.ERROR.FETCH_TASKS_ERROR')))
            .finally(() => setIsLoading({ data: false, uuid }));
    }, [provider, plan]);

    /* UseEffect that update the selected tasks array each time the tasks list change. */
    useEffect(() => {
        const _selectedTasks = tasks.filter((task) => task.status === 'completed').map((task) => task.id);
        setSelectedTasks({ data: _selectedTasks, uuid });
    }, [tasks]);

    /* UseEffect that filters and sorts the tasks. */
    useEffect(() => {
        const displayedTasks = tasks.filter((task) => {
            const taskDueDate = utc(task.dueDateTime);
            const taskCompletedDate = task.completedDateTime ? utc(task.completedDateTime) : null;
            const todayDate = utc();

            const isNotLateOrLateCompliant =
                !isFilteredByLateTasks ||
                (isFilteredByLateTasks && task.dueDateTime && taskDueDate.diff(todayDate, 'day') < 2);

            const isNotTodayOrTodayCompliant =
                !isFilteredByTodayTasks ||
                (isFilteredByTodayTasks && task.dueDateTime && taskDueDate.isSame(todayDate, 'day'));

            const isNotAReminderOrReminderCompliant =
                !isFilteredByReminderTasks || (isFilteredByReminderTasks && task.reminder);

            const isNotCompletedOrCompletedCompliant =
                task.status !== 'completed' ||
                (task.status === 'completed' &&
                    task.completedDateTime &&
                    (!isFilteredByLateTasks && !isFilteredByReminderTasks && !isFilteredByTodayTasks) &&
                    taskCompletedDate.diff(todayDate, 'day') >= -alreadyDoneDay &&
                    taskCompletedDate.diff(todayDate, 'day') <= 0);

            const isInTheCurrentSelectedBucket =
                !bucket || isEmpty(bucket) || (bucket !== {} && task.categoryId === bucket.id);

            if (
                isNotLateOrLateCompliant &&
                isNotTodayOrTodayCompliant &&
                isNotAReminderOrReminderCompliant &&
                isNotCompletedOrCompletedCompliant &&
                isInTheCurrentSelectedBucket
            ) {
                return true;
            }

            return false;
        });
        displayedTasks.sort((taskA, taskB) => {
            const isTaskASelected = selectedTasks.find((_task) => _task === taskA.id) !== undefined;
            const isTaskBSelected = selectedTasks.find((_task) => _task === taskB.id) !== undefined;

            if ((isTaskASelected && !isTaskBSelected) || (!isTaskASelected && isTaskBSelected)) {
                return isTaskASelected ? 1 : -1;
            } else if (isTaskASelected && isTaskBSelected && (taskA.completedDateTime && taskB.completedDateTime)) {
                if (isOrderedByDueDateDesc) {
                    return taskA.completedDateTime > taskB.completedDateTime ? 1 : -1;
                }

                return taskA.completedDateTime < taskB.completedDateTime ? 1 : -1;
            } else if ((!taskA.dueDateTime && taskB.dueDateTime) || (taskA.dueDateTime && !taskB.dueDateTime)) {
                return taskA.dueDateTime ? 1 : -1;
            } else if (isOrderedByDueDateDesc) {
                return taskA.dueDateTime > taskB.dueDateTime ? 1 : -1;
            }

            return taskA.dueDateTime < taskB.dueDateTime ? 1 : -1;
        });
        setFilteredTasks({ data: displayedTasks, uuid });
    }, [
        bucket,
        isFilteredByLateTasks,
        isFilteredByTodayTasks,
        isFilteredByReminderTasks,
        isOrderedByDueDateDesc,
        selectedTasks,
        alreadyDoneDay,
    ]);

    /**
     * UseEffect for update a task status.
     *
     * @param {Task} task The task to update.
     */
    const toggleTask = (task) => {
        const selectedIndex = selectedTasks.findIndex((_task) => _task === task.id);

        const taskIndex = tasks.findIndex((_task) => _task.id === task.id);
        const tasksCopy = JSON.parse(JSON.stringify(tasks));
        const taskCurrentState = tasksCopy[taskIndex];

        const usedFunc = provider === 'planner' ? patchPlannerTask : patchOutlookTask;

        // eslint-disable-next-line no-negated-condition
        if (selectedIndex !== -1) {
            updateTask({ data: Object.assign(taskCurrentState, { isLoading: true }), uuid });
            usedFunc(task.id, task.etag, { completedDateTime: null, status: 'notStarted' })
                .then((updatedTasks) => {
                    updateTask({ data: Object.assign(updatedTasks.data.item, { isLoading: false }), uuid });
                })
                .catch(() => {
                    error(t('FRONT.WIDGET_TASKS.ERROR.PATCH_TASK_ERROR'));
                    updateTask({ data: Object.assign(taskCurrentState, { isLoading: false }), uuid });
                });
        } else {
            updateTask({ data: Object.assign(taskCurrentState, { isLoading: true }), uuid });
            usedFunc(task.id, task.etag, { status: 'completed' })
                .then((updatedTasks) => {
                    updateTask({ data: Object.assign(updatedTasks.data.item, { isLoading: false }), uuid });
                })
                .catch(() => {
                    error(t('FRONT.WIDGET_TASKS.ERROR.PATCH_TASK_ERROR'));
                    updateTask({ data: Object.assign(taskCurrentState, { isLoading: false }), uuid });
                });
        }
    };

    return (
        <>
            {isInit && (
                <>
                    {provider === 'lumapps' && (
                        <MyTasks
                            content={content}
                            isCheckedItemsDisplayed={isMyTasksCheckedItemsDisplayed}
                            newTodo={myTasksNewTodo}
                            setIsCheckedItemsDisplayed={setIsMyTasksCheckedItemsDisplayed}
                            setNewTodo={setMyTasksNewTodo}
                            setTodoItems={setMyTasksTodoItems}
                            setUserContentWidget={setMyTasksUserContentWidget}
                            theme={theme}
                            todoItems={myTasksTodoItems}
                            userContentService={userContent}
                            userContentWidget={myTasksUserContentWidget}
                            uuid={uuid}
                        />
                    )}
                    {provider !== 'lumapps' && (
                        <div className="widget-tasks">
                            <TaskHeader
                                isFilteredByLateTasks={isFilteredByLateTasks}
                                isFilteredByReminderTasks={isFilteredByReminderTasks}
                                isFilteredByTodayTasks={isFilteredByTodayTasks}
                                isOrderedByDueDateDesc={isOrderedByDueDateDesc}
                                provider={provider}
                                setIsFilteredByLateTasks={setIsFilteredByLateTasks}
                                setIsFilteredByReminderTasks={setIsFilteredByReminderTasks}
                                setIsFilteredByTodayTasks={setIsFilteredByTodayTasks}
                                setIsOrderedByDueDateDesc={setIsOrderedByDueDateDesc}
                                theme={theme}
                                uuid={uuid}
                            />

                            {isLoading && <Progress className="centered-loader" theme={theme} />}

                            {!isLoading && viewMode === 'list' && tasks.length > 0 && (
                                <TaskList
                                    items={filteredTasks}
                                    selectedTasks={selectedTasks}
                                    theme={theme}
                                    toggleTask={toggleTask}
                                />
                            )}

                            {!isLoading && viewMode === 'graph' && tasks.length > 0 && (
                                <TaskGraphList items={filteredTasks} />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

WidgetTasks.defaultProps = {
    filteredTasks: [],
    isFilteredByLateTasks: false,
    isFilteredByReminderTasks: false,
    isFilteredByTodayTasks: false,
    isInit: false,
    isLoading: false,
    isMyTasksCheckedItemsDisplayed: false,
    isOrderedByDueDateDesc: true,
    myTasksNewTodo: {},
    myTasksTodoItems: [],
    myTasksUserContentWidget: {},
    selectedTasks: [],
    tasks: [],
};

WidgetTasks.propTypes = {
    /* The contentService injected by Angular by the react-element tag. */
    content: objectOf(any).isRequired,
    /* An array of the tasks to display. */
    filteredTasks: arrayOf(object),
    /* A function to initialize the component's properties in the redux store. */
    initState: func.isRequired,
    /* Define if we are filtering on the late tasks. */
    isFilteredByLateTasks: bool,
    /* Define if we are filtering on the reminder tasks. */
    isFilteredByReminderTasks: bool,
    /* Define if we are filtering on the today tasks. */
    isFilteredByTodayTasks: bool,
    /* Define if the component has been initialize or not. */
    isInit: bool,
    /* Define if we need to display a loader or not. */
    isLoading: bool,
    /* Define if we display the checked tasks or not in the "MyTasks" section. */
    isMyTasksCheckedItemsDisplayed: bool,
    /* Define if the tasks are displayed by ascending or descending order. */
    isOrderedByDueDateDesc: bool,
    /* The currently new todo draft in the "MyTasks" section. */
    myTasksNewTodo: objectOf(any),
    /* The todos Array in the "MyTasks" section. */
    myTasksTodoItems: arrayOf(any),
    /* The current content of the widget on the AngularSide in the "MyTasks" section. */
    myTasksUserContentWidget: objectOf(any),
    /* An array of the checked tasks. */
    selectedTasks: arrayOf(string),
    /* A function for setting the filtered tasks. */
    setFilteredTasks: func.isRequired,
    /* A function for setting the value of the "isFilteredByLateTasks" prop. */
    setIsFilteredByLateTasks: func.isRequired,
    /* A function for setting the value of the "isFilteredByReminderTasks" prop. */
    setIsFilteredByReminderTasks: func.isRequired,
    /* A function for setting the value of the "isFilteredByTodayTasks" prop. */
    setIsFilteredByTodayTasks: func.isRequired,
    /* A function for setting the value of the "isLoading" prop. */
    setIsLoading: func.isRequired,
    /* A function for setting the value of the "isMyTasksCheckedItemsDisplayed" prop. */
    setIsMyTasksCheckedItemsDisplayed: func.isRequired,
    /* A function for setting the value of the "isOrderedByDueDateDesc" prop. */
    setIsOrderedByDueDateDesc: func.isRequired,
    /* A function for setting the myTasks newTodo prop. */
    setMyTasksNewTodo: func.isRequired,
    /* A function for setting the myTasks todoItems prop. */
    setMyTasksTodoItems: func.isRequired,
    /* A function for setting the myTasks userContentWidget prop. */
    setMyTasksUserContentWidget: func.isRequired,
    /* A function for setting the selected tasks. */
    setSelectedTasks: func.isRequired,
    /* A function for setting the tasks. */
    setTasks: func.isRequired,
    /* The widget style. */
    style: objectOf(any).isRequired,
    /* The retrieved tasks. */
    tasks: arrayOf(object),
    /* The lumx theme. */
    updateTask: func.isRequired,
    /* The userContentService injected by Angular by the react-element tag. */
    userContent: objectOf(any).isRequired,
    /* The current widget uuid. */
    uuid: string.isRequired,
    /* The properties of the widget passed by the angular directive. */
    value: objectOf(any).isRequired,
};

WidgetTasks.isEditable = () => false;

/**
 * Defines whether the widget is empty or not.
 *
 * @param  {string}  value The recipent email address.
 * @return {boolean} Whether the recipent email address is empty or not.
 */

WidgetTasks.isWidgetEmpty = (params) => {
    return !params?.value?.provider;
};

/**
 * Determines whether the widget is hidden or not.
 *
 * @param  {Object}  params The widget props.
 * @return {boolean} Whether the widget is hidden or not.
 */
WidgetTasks.isWidgetHidden = (params) => {
    return (
        !isConnected() ||
        (params.value.provider !== 'lumapps' && (isExternal() || !isMicrosoft())) ||
        WidgetTasks.isWidgetEmpty(params)
    );
};

export default WidgetTasks;
