export enum WIDGET_EVENT_LIST {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=272703799
     */
    DISPLAY_EVENT = 'WIDGET_EVENT_LIST.DISPLAY_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=272355284
     */
    EVENT_ACTIONS = 'WIDGET_EVENT_LIST.EVENT_ACTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203150656
     */
    SETTINGS_SHOW_LOAD_MORE_HELPER = 'WIDGET_EVENT_LIST.SETTINGS_SHOW_LOAD_MORE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203152189
     */
    SETTINGS_SHOW_LOCATION = 'WIDGET_EVENT_LIST.SETTINGS_SHOW_LOCATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203152201
     */
    SETTINGS_SHOW_ATTENDEES = 'WIDGET_EVENT_LIST.SETTINGS_SHOW_ATTENDEES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203152374
     */
    SETTINGS_SHOW_LINK = 'WIDGET_EVENT_LIST.SETTINGS_SHOW_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=415045730
     */
    SETTINGS_FOLLOWED_COMMUNITIES_FILTER_HELPER = 'WIDGET_EVENT_LIST.SETTINGS_FOLLOWED_COMMUNITIES_FILTER_HELPER',
}
