import React from 'react';

import { useClassnames } from '@lumapps/classnames';

import { SkipLink, SkipLinkProps } from '../SkipLink';

import './index.scss';

export type SkipLinksListItemProps = Omit<SkipLinkProps, 'floating'>;

const CLASSNAME = 'skip-link-list-item';

/**
 * A single skip link to be used within a SkipLinkList component.
 * To display a single skip link, use `SkipLink` instead.
 *
 * @family Layouts
 * @param SkipLinksListItemProps
 * @returns SkipLinksListItem
 */
export const SkipLinksListItem: React.FC<SkipLinksListItemProps> = ({ children, anchorId: anchor }) => {
    const { block } = useClassnames(CLASSNAME);

    return (
        <li className={block()}>
            <SkipLink anchorId={anchor} floating>
                {children}
            </SkipLink>
        </li>
    );
};
