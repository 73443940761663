/* eslint-disable lines-around-directive */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetLearningTrainingCoursesInProgressController(ReduxStore) {
      'ngInject';

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;

      /**
       * Set parent controller.
       *
       * @param {Object} parentCtrl The parent controller object.
       */
      this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
      };

      /**
       * Get widget classes.
       *
       * @return {Array} An array of widget css classes.
       */
      function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);
        if (vm.widget.properties.viewModeVariant === 'ungrouped') {
            widgetClass.push('widget--view-mode-variant-ungroup');
        }
        return widgetClass;
      }


      function isEmpty() {
        const state = ReduxStore.store.getState();
        const widgetStatus = state.widget?.entities[vm.widget.uuid]?.state

        vm.hasNoResults = widgetStatus === 'empty' ? true : false

        return vm.hasNoResults;
      }

      /**
       * Function that conditions the rendering of the widget content.
       */
      function isWidgetEmpty() {
        return vm.parentCtrl.designerMode() && isEmpty();
      }

      /**
       * In designer mode, when the widget is empty
       *
       * In reader mode, when the widget is empty, it is not displayed at all
       */
      function isWidgetHidden() {
        return !vm.parentCtrl.designerMode() && isEmpty();
      }

      vm.getWidgetClass = getWidgetClass;
      vm.isWidgetEmpty = isWidgetEmpty;
      vm.isWidgetHidden = isWidgetHidden;

      function initProperties() {
        const properties = vm.widget.properties;
        if (angular.isUndefinedOrEmpty(properties.viewModeVariant)) {
          properties.viewModeVariant = 'grouped';
        }
      }

      initProperties();
    }

    function widgetLearningTrainingCoursesInProgressDirective() {
      'ngInject';

      function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
      }

      return {
        bindToController: true,
        controller: WidgetLearningTrainingCoursesInProgressController,
        controllerAs: 'vm',
        replace: true,
        require: ['widgetLearningTrainingCoursesInProgress', '^widget'],
        restrict: 'E',
        link,
        scope: {
          widget: '=',
        },
        templateUrl:
          '/client/front-office/modules/content/modules/widget/modules/widget-learning-training-courses-in-progress/views/widget-learning-training-courses-in-progress.html',
      };
    }

    window.angular.module('Widgets').directive('widgetLearningTrainingCoursesInProgress', widgetLearningTrainingCoursesInProgressDirective);
  })();