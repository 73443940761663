import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, TextProps, Typography } from '@lumapps/lumx/react';
import { VideoGroupDetails } from '@lumapps/play/components/VideoGroupDetails';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-group-details` as const;

type GroupDetailsProps = Omit<TextProps, 'as'>;

/**
 * Displays the group details of a video
 */
export const GroupDetails = ({ typography = Typography.body1 }: GroupDetailsProps) => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, video, theme } = useBlockVideoContext();

    if (!canDisplay('GROUP_DETAILS')) {
        return null;
    }

    if (isLoading) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="20%" />;
    }

    if (!video?.groupDetails?.length) {
        return null;
    }

    return <VideoGroupDetails groupNames={video.groupDetails.map((group) => group.title)} />;
};
