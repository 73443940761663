import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

interface CustomizationDropdownItemProps {
    icon?: string;
    className?: string;
    title: string;
    href?: string;
    openInNewTab?: boolean;
    onClick?(): void;
}

/**
 * Wrapper for the Menu.Item in order to allow the customizations API to use
 * menus as easily as possible.
 *
 * @param CustomizationDropdownItemProps
 */
export const CustomizationDropdownItem: React.FC<CustomizationDropdownItemProps> = ({
    title,
    href,
    onClick,
    icon,
    className,
    openInNewTab,
}) => {
    const { iconPath } = useDynamicIcon({ icon } as any);
    const linkProps = href ? { as: 'a', href, target: openInNewTab ? '_blank' : undefined } : undefined;
    return (
        <Menu.Item className={className} icon={icon ? iconPath : undefined} onClick={onClick} {...linkProps}>
            {title}
        </Menu.Item>
    );
};

CustomizationDropdownItem.displayName = 'DropdownItem';
