import { useCallback, useMemo, useState } from 'react';

interface UseFrontendSelectOptionsProps<Option> {
    /**
     * The complete list of items to use as options
     */
    itemList: Option[];
    /**
     * The function used to get the items names. Names is used to filter by search query.
     */
    getItemName: (option: Option) => string;
    /**
     * The number of items per page to display. If you have many results, please consider using
     * it to reduce the amount of displayed elements.
     */
    perPage: number;
}

interface UseFrontendSelectOptionsReturn<Option> {
    /** The items to display, according to search and pagination states. */
    items: Option[];
    /** The function to load next items. */
    getMoreItems: () => void;
    /** The search query string. */
    searchQuery: string;
    /** The function to update the search query. */
    onSearch: (query: string) => void;
    /** The current page, all pages before are displayed. Starts at 1. */
    currentPage: number;
    /** Whether the search has more results to display or not. */
    hasMore: boolean;
}

/**
 * Hooks that handles search and pagination for a list of items.
 *
 * NB: This hook handles the search on the frontend side and should be used with
 * caution. If a backend API allows you to perform a search, always prefer the
 * backend solution and don't use this hook.
 *
 * @param param
 */
export const usePaginatedFrontendSearch = <Option>({
    itemList = [],
    getItemName,
    perPage,
}: UseFrontendSelectOptionsProps<Option>): UseFrontendSelectOptionsReturn<Option> => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setPage] = useState(1);

    const filteredData = useMemo(() => {
        return itemList.filter((item) => getItemName(item).match(new RegExp(searchQuery, 'i')));
    }, [itemList, getItemName, searchQuery]);

    const items = useMemo(() => {
        return filteredData.slice(0, currentPage * perPage);
    }, [currentPage, filteredData, perPage]);

    const getMoreItems = useCallback(() => {
        if (items.length < filteredData.length) {
            setPage((page) => page + 1);
        }
    }, [filteredData.length, items.length]);

    const onSearch = (newQuery: string) => {
        if (newQuery !== searchQuery) {
            setSearchQuery(newQuery);

            // Reset pagination
            setPage(1);
        }
    };

    return { items, getMoreItems, searchQuery, onSearch, currentPage, hasMore: items.length < filteredData.length };
};
