import get from 'lodash/get';
import moment from 'moment';

import { TranslationAPI } from '@lumapps/translations';
import { getElapsedTimeFromUtcDate } from '@lumapps/utils/time/getElapsedTimeFromUtcDate';

import { Notification, NotificationContentType } from '../types';

const getContentTitle = (translation: TranslationAPI, notification?: Notification): string => {
    if (notification && notification.contentDetails) {
        return translation.translateObject(notification.contentDetails.title) || '';
    }

    return '';
};

const getContentType = (notification?: Notification): string => {
    return notification?.contentDetails?.type || '';
};

const isTheContentAnArticle = (notification?: Notification): boolean => {
    return getContentType(notification) === NotificationContentType.article;
};

const isTheContentAnEvent = (notification?: Notification): boolean => {
    return getContentType(notification) === NotificationContentType.event;
};

const isTheContentAPost = (notification?: Notification): boolean => {
    return getContentType(notification) === NotificationContentType.post;
};

const isTheContentAPage = (notification?: Notification): boolean => {
    return (
        getContentType(notification) !== NotificationContentType.post &&
        getContentType(notification) !== NotificationContentType.community
    );
};

const getFormattedContentEndDate = (notification?: Notification): string => {
    if (notification && notification.contentDetails) {
        const { endDate } = notification.contentDetails;

        return getElapsedTimeFromUtcDate(endDate, true);
    }

    return '';
};

const getParentContentTitle = (translation: TranslationAPI, notification?: Notification): string => {
    if (notification && notification.parentContentDetails) {
        return translation.translateObject(notification.parentContentDetails.title) || '';
    }

    return '';
};

const getParentInstanceTitle = (translation: TranslationAPI, notification?: Notification): string => {
    if (notification && notification.instanceDetails) {
        return (
            translation.translateObject(notification.instanceDetails.title) || notification.instanceDetails.name || ''
        );
    }

    return '';
};

const getContextTitle = (translation: TranslationAPI, notification?: Notification): string => {
    if (notification) {
        return translation.translateObject(notification.title) || '';
    }

    return '';
};

const getContextDescription = (translation: TranslationAPI, notification?: Notification): string => {
    if (notification) {
        return translation.translateObject(notification.description || {}) || '';
    }

    return '';
};

const getContentPostDetailTitle = (notification?: Notification): string => {
    return get(notification, 'contentDetails.postStatusDetails.title') || '';
};

const isContentExpired = (notification?: Notification) => {
    const endDate = notification?.contentDetails?.endDate;
    return Boolean(endDate) && moment.utc(endDate).local().isBefore();
};

export {
    getContentTitle,
    getParentContentTitle,
    getParentInstanceTitle,
    getContextTitle,
    getContextDescription,
    getFormattedContentEndDate,
    getContentPostDetailTitle,
    getContentType,
    isTheContentAPost,
    isTheContentAPage,
    isContentExpired,
    isTheContentAnArticle,
    isTheContentAnEvent,
};
