/* eslint-disable no-param-reassign */
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { ArticleLayout } from '../api/types';
import { ArticlesState, EditedArticleState } from '../types';

export const initialState: ArticlesState = {};

export const { actions, reducer } = createSlice({
    domain: 'article',
    initialState,
    reducers: {
        openDialog: (state: ArticlesState) => {
            state.isArticleDialogOpen = true;
        },
        closeDialog: (state: ArticlesState) => {
            delete state.isArticleDialogOpen;
            delete state.editedArticle;
        },
        setCurrentArticle: (state: ArticlesState, action: PayloadAction<ArticleLayout>) => {
            state.current = action.payload;
        },
        updateCurrentArticleCommentCount: (state: ArticlesState, actions: PayloadAction) => {
            if (state.current) {
                state.current.commentCount += actions.payload;
            }
        },
        clearCurrentArticle: (state: ArticlesState) => {
            delete state.current;
        },
        setEditedArticle: (state: ArticlesState, action: PayloadAction<EditedArticleState>) => {
            state.editedArticle = action.payload;
        },
        setArticleFromConversion: (
            state: ArticlesState,
            action: PayloadAction<ArticlesState['articleFromConversion']>,
        ) => {
            state.articleFromConversion = action.payload;
        },
        setIsPublishingArticle: (state: ArticlesState, action: PayloadAction<boolean>) => {
            if (!action.payload) {
                delete state.isArticleDialogPublishing;
            } else {
                state.isArticleDialogPublishing = action.payload;
            }
        },
        setIsReadOnlyArticle: (state: ArticlesState, action: PayloadAction<boolean>) => {
            if (!action.payload) {
                delete state.isArticleDialogReadOnly;
            } else {
                state.isArticleDialogReadOnly = action.payload;
            }
        },
    },
});
