import isEmpty from 'lodash/isEmpty';

import { lumappsAnalyticsPlugin } from '@lumapps/analytics-tracking/tracking';
import { type LumappsTrackingClickAction, TargetType, TrackingEventType } from '@lumapps/analytics-tracking/types';

export const sendTrackingDirectoryEntryClickActionEvent = ({
    targetId,
    url,
    thumbnail,
    title,
}: Omit<LumappsTrackingClickAction, 'type' | 'targetType'>) => {
    const clickAction: LumappsTrackingClickAction = {
        type: TrackingEventType.CLICK_ACTION,
        targetId,
        targetType: TargetType.DIRECTORY_ENTRY,
        url,
        thumbnail,
        title,
    };

    // Do not send event if link is empty
    if (!isEmpty(url)) {
        lumappsAnalyticsPlugin.track({ payload: { action: clickAction } });
    }
};
