import React, { ReactNode } from 'react';

import { get as getConstants } from '@lumapps/constants';
import { useCustomizations, MODES } from '@lumapps/customizations';
import { Header as HeaderWrapper } from '@lumapps/lumx-front-office/components/Header';
import { useStickyChildNavigation } from '@lumapps/navigation/hooks/useStickyChildNavigation';
import { useResponsive } from '@lumapps/responsive';
import { useDropdown } from '@lumapps/utils/hooks/useDropdown';

import { LoadingHeader } from '../LoadingHeader';
import { HeaderNavigation } from './HeaderNavigation';
import { TopBar } from './TopBar';

import './index.scss';

export type HeaderProps = {
    /**
     * Children to display inside the header
     */
    children?: ReactNode;
    /**
     * whether the header is in a loading state or not
     */
    isLoading?: boolean;
    /**
     * Styles to be applied to the top section of the bar
     */
    topStyles?: Record<string, any>;
    /**
     * Whether the user is connected or not
     */
    isConnected?: boolean;
    /**
     * the id of this site's parent instance
     */
    parentInstanceId?: string;
    /**
     * current instance id
     */
    instanceId: string;
    /**
     * current language
     */
    currentLanguage: string;
    /**
     * name of the current instance
     */
    instanceName?: string;
    /**
     * List of FFs and whether they are activated or not.
     */
    features: Record<string, boolean>;
    /**
     * The content's desired position
     */
    contentPosition?: number;
    /**
     * Whether the content header to be displayed has a height > 0
     */
    hasContentHeader?: boolean;
    /**
     * Whether the header should be sticky or not.
     */
    isStickyEnabled?: boolean;
    /**
     * Whether the header is displayed on a legacy context or not
     */
    isLegacyContext?: boolean;
    /**
     * Whether the content header should be displayed for the current page or not.
     */
    shouldShowContentHeaderInCurrentPage?: boolean;
};
const constants = getConstants();

export const CLASSNAME = 'header';

const Header: React.FC<HeaderProps> = ({
    children,
    isLoading = false,
    topStyles = {},
    isConnected = false,
    instanceId,
    instanceName,
    parentInstanceId,
    features,
    contentPosition,
    hasContentHeader,
    currentLanguage,
    isStickyEnabled = true,
    isLegacyContext = constants.isLegacyContext,
    shouldShowContentHeaderInCurrentPage = false,
}) => {
    const { isMobile, isSmall } = useResponsive();

    const { isOpen: isSideNavOpen, setIsOpen: setIsSideNavOpen } = useDropdown(false);

    const { modes } = useCustomizations();
    const isDisabled = modes[MODES.DISABLED_HEADER] || undefined;

    const {
        isChildNavSticky,
        navRef,
        headerRef,
        parentHeaderRef,
        isParentNavSticky,
        topNavigationInstanceId,
        isChildNavigationVisible,
    } = useStickyChildNavigation({
        isMainNavInheritanceEnabled: features ? features.mainNavInheritance : false,
        parentInstanceId,
        instanceId,
        isStickyEnabled,
    });

    const isParentNav = topNavigationInstanceId !== instanceId;

    let shouldShowContentHeader = hasContentHeader;

    /**
     * Content header is a special component that has an impact on the layout of the page. In order to avoid as much impact as possible
     * on the legacy side, if there is no reason to show the content header, we just go ahead and remove it and let the legacy application
     * show the legacy content header.
     *
     * When the child navigation is enabled, the content header displays between the parent nav and the
     * child nav, so we have no choice but to show that newly migrated content header.
     *
     * Finally, when we are in responsive or if the content header should be hidden on the current page, we never show the slideshow.
     */
    if (isLegacyContext) {
        shouldShowContentHeader = isChildNavigationVisible;
    } else if (isChildNavigationVisible) {
        shouldShowContentHeader = true;
    } else if (isSmall || !shouldShowContentHeaderInCurrentPage) {
        shouldShowContentHeader = false;
    }

    /**
     * For the content position, the scenarios where we do not need to add them are:
     * - If we are on the legacy, we should only ignore the content's position when the child navigation is not visible.
     * In that scenario, we display our top bar as it is by default, therefore the content page will manage the spacing. If
     * the nav is visible, it means that we are displaying the slideshow with the new components, therefore it is this new
     * code that needs to manage the spacing.
     * - If we are not on the legacy app, we should ignore the content position when we are in a scenario
     * where we are not displaying the content header or when we are in responsive. On both scenarios, the slideshow
     * will not appear, therefore the content position can be ignored.
     */
    const shouldIgnoreContentPosition = isLegacyContext
        ? !isChildNavigationVisible
        : isSmall || !shouldShowContentHeaderInCurrentPage;

    /**
     * For the default margin, the scenarios where we should add it are:
     * - If we are in the legacy app, we should never add them.
     * - If we are not on the legacy app, we should add the default margin when the slideshow is not displayed.
     */
    const shouldAddDefaultBottomMargin = isLegacyContext ? false : !shouldShowContentHeaderInCurrentPage;

    if (modes[MODES.HEADLESS]) {
        return null;
    }

    if (isLoading) {
        return <LoadingHeader isParentNav={isParentNav} />;
    }

    return (
        <HeaderWrapper
            className={CLASSNAME}
            hasNavigation={!isMobile}
            style={Boolean(contentPosition) && contentPosition !== 0 ? { marginBottom: contentPosition } : {}}
            anchorRef={parentHeaderRef}
            shouldIgnoreContentPosition={shouldIgnoreContentPosition}
            shouldAddDefaultBottomMargin={shouldAddDefaultBottomMargin}
        >
            <TopBar
                currentLanguage={currentLanguage}
                features={features}
                headerRef={headerRef}
                instanceId={instanceId}
                isSideNavOpen={isSideNavOpen}
                setIsSideNavOpen={setIsSideNavOpen}
                instanceName={instanceName}
                isConnected={isConnected}
                isStickyEnabled={isStickyEnabled}
                topStyles={topStyles}
                isDisabled={isDisabled}
            >
                {children}
            </TopBar>

            <HeaderNavigation
                currentLanguage={currentLanguage}
                features={features}
                instanceId={instanceId}
                isChildNavSticky={isChildNavSticky}
                isChildNavigationVisible={isChildNavigationVisible}
                isParentNav={isParentNav}
                isParentNavSticky={isParentNavSticky}
                navRef={navRef}
                shouldShowContentHeader={shouldShowContentHeader}
                topNavigationInstanceId={topNavigationInstanceId}
                hasContentHeader={hasContentHeader}
                parentInstanceId={parentInstanceId}
                isSideNavOpen={isSideNavOpen}
                setIsSideNavOpen={setIsSideNavOpen}
                isDisabled={isDisabled}
            />
        </HeaderWrapper>
    );
};

export { Header };
