/* istanbul ignore file */
import { SocialNetworks } from '../types';

const TIMEOUT_INTERVAL = 1000;

export const connectSocialNetwork = (socialNetwork: SocialNetworks, callback: any) => {
    const authWindow = (() =>
        window.open(
            `/sa/v2/social-network/oauth-authorize/${socialNetwork}`,
            undefined,
            `
              toolbar=no,
              location=no,
              status=no,
              menubar=no,
              scrollbars=yes,
              resizable=yes,
              width=600,
              height=600
          `,
        ))();

    return new Promise<void>((resolve) => {
        const onClose = async () => {
            await callback();
            resolve();
        };

        const intervalRef = setInterval(() => {
            if (authWindow?.closed) {
                clearInterval(intervalRef);
                onClose();
            } else {
                try {
                    const url = authWindow?.location.href;
                    if (url?.includes('oauth-authorize') && authWindow) {
                        clearInterval(intervalRef);
                        authWindow.close();
                        onClose();
                    }
                } catch (exception) {
                    return undefined;
                }
            }

            return undefined;
        }, TIMEOUT_INTERVAL);
    });
};
