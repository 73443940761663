(function IIFE() {
    'use strict';

    /////////////////////////////

    function ColorInjectorController(LxNotificationService, Style, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The names of the types of color that can be set.
         *
         * @type {Array}
         */
        var _COLOR_TYPES = ['primary', 'accent'];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the color code entered in the input field is valid or not.
         *
         * @type {boolean}
         */
        vm.isInvalidColor = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Style = Style;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a new color to the palette.
         */
        function addColor() {
            // Add the # in front of the colour code in case it was forgotten by mistake.
            if (angular.isDefinedAndFilled(vm.newColor) && vm.newColor.length === 6 &&
                vm.newColor.charAt(0) !== '#') {
                vm.newColor = '#' + vm.newColor;
            }

            // Not an hexa color code.
            if (!Utils.isHexadecimal(vm.newColor)) {
                vm.isInvalidColor = true;
                LxNotificationService.error(Translation.translate('ERROR_COLOR_BAD_FORMAT'));

                return;
            }

            // Duplicate entry.
            if (_.includes(vm.colors, vm.newColor)) {
                vm.isInvalidColor = true;
                LxNotificationService.error(Translation.translate('ERROR_COLOR_ALREADY_EXISTS'));

                return;
            }

            // Prepend the new color.
            vm.colors.unshift(vm.newColor);

            vm.isInvalidColor = false;
            vm.newColor = undefined;
        }

        /**
         * Define a color as either primary or accent.
         *
         * @param {string} color The code of the color to be defined as primary or accent.
         * @param {string} type  The type of color we should set it to.
         *                       Possible values are 'primary' or 'accent'.
         */
        function defineAs(color, type) {
            if (!_.includes(_COLOR_TYPES, type)) {
                return;
            }

            Style.getCurrent('global').properties = Style.getCurrent('global').properties || {};
            var properties = Style.getCurrent('global').properties;

            angular.forEach(_COLOR_TYPES, function forEachColorType(colorType) {
                if (properties[colorType] === color) {
                    properties[colorType] = undefined;
                }
            });

            properties[type] = color;
        }

        /**
         * Remove a color from the palette.
         *
         * @param {string} color The color code to be removed.
         */
        function removeColor(color) {
            vm.colors = _.without(vm.colors, color);
        }

        /////////////////////////////

        vm.addColor = addColor;
        vm.defineAs = defineAs;
        vm.removeColor = removeColor;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // The list of colors should be an array.
            if (angular.isUndefinedOrEmpty(vm.colors) || !angular.isArray(vm.colors)) {
                vm.colors = [];

                return;
            }

            vm.colors = _.uniq(vm.colors.filter(function filterColors(color) {
                return color === 'transparent' || Utils.isHexadecimal(color);
            }));
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive that displays a colour palette.
     *
     * @param {Array} colors The list of colours to be displayed in the palette.
     */

    function ColorInjectorDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ColorInjectorController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                colors: '=lsColors',
            },
            templateUrl: '/client/common/modules/color-injector/views/color-injector.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsColorInjector', ColorInjectorDirective);
})();
