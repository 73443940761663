import { handleNannyError, shouldUseNannyErrorHandler } from '@lumapps/nanny-details/utils/errorHandler';
import { handleTagzError, shouldUseTagzErrorHandler } from '@lumapps/folksonomy/utils/errorHandler';
import { actions as postActions } from '@lumapps/posts/ducks/slice';
import { PostType } from '@lumapps/posts/types';
import { isIE } from '@lumapps/utils/browser/isIE';
import { isLegacyEdge } from '@lumapps/utils/browser/isLegacyEdge';
import { createEmptyContent } from '@lumapps/wrex/utils/createEmptyContent';
import { getAllMentionedUsers } from '@lumapps/wrex-user-mention/utils/getAllMentionedUsers';
import { toMarkdown } from '@lumapps/wrex/serialization/markdown/toMarkdown';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';
import { escapeRegex } from 'components/utils';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import debounce from 'lodash/debounce';
import loFind from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import without from 'lodash/without';
import { FULL_DATE_SIX_FRACTIONAL_SECONDS } from '@lumapps/constants';
import { EVENTS_FF } from '@lumapps/events/ducks/selectors';
import moment from 'moment';
import { actions as foActions } from '@lumapps/communities/ducks/frontOfficeSlice';
// ///////////////////////////

function CommunityNewPostController(
    $rootScope,
    $scope,
    $state,
    AbstractPicker,
    ActivePost,
    Community,
    CommunityConstant,
    Config,
    Customer,
    Features,
    Instance,
    LsDatePickerService,
    LsTextEditor,
    LsTextEditorMarkdown,
    LxDialogService,
    LxNotificationService,
    MediaConstant,
    Post,
    Translation,
    ReduxStore,
    Upload,
    User,
    UserAccess,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this, angular/controller-as-vm
    const cnp = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The delay to debounce the find URL function.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEBOUND_FIND_URL_DELAY = 500;

    /**
     * The number of days to display the date of the post as a "from now" string (e.g. "1 hour ago").
     * Above this number of days, the date of the post will be displayed as a plain date.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DELTA_TEXT_FROM_NOW = 2;

    /**
     * The number of minutes in a half hour.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _HALF_AN_HOUR = 30;

    /**
     * The number of hours in a day.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _HOURS_IN_A_DAY = 24;

    /**
     * The lang to apply to the community search call.
     *
     * @type {string|Array}
     */
    let _SEARCH_LANG;

    /**
     * The post title placeholders.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _POST_TITLE_PLACEHOLDERS = {
        [Community.availablePostTypes.EVENT.value]: Translation.translate(
            'FRONT.COMMUNITY.POST.TITLE_PLACEHOLDER.EVENT',
        ),
        [Community.availablePostTypes.IDEA.value]: Translation.translate('GLOBAL.TITLE'),
        [Community.availablePostTypes.QUESTION.value]: Translation.translate('GLOBAL.TITLE'),
        default: Translation.translate('GLOBAL.TITLE'),
    };

    /**
     * The default post content value. Is in Slate format.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const DEFAULT_CONTENT = createEmptyContent();

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    cnp.MediaConstant = MediaConstant;

    /**
     * The list of the different line styles.
     * @type {string}
     * @constant
     * @readonly
     */
    cnp.HEADINGS_LIST = [
        'FRONT.TEXT_EDITOR.HEADINGS.HEADING1',
        'FRONT.TEXT_EDITOR.HEADINGS.HEADING2',
        'FRONT.TEXT_EDITOR.HEADINGS.NORMAL_TEXT',
    ];

    /**
     * The list key for the list of communities.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    cnp.LIST_KEY = 'community-list-key';

    /**
     * The community block fields used when picking a community.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    cnp.FIELDS = ['members', 'title', 'content'];

    /**
     * Indicates if we must consider the user as a community admin in the community selector.
     *
     * @type {boolean}
     * @constant
     * @readonly
     */
    cnp.WHERE_ADMIN = false;

    /**
     * The label for the submit button of the community selector.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    cnp.COMMUNITY_SELECTOR_SUBMIT_LABEL = Translation.translate('NEXT');

    /**
     * The current heading style.
     *
     * @type {string}
     */
    cnp.currentHeading = 'FRONT.TEXT_EDITOR.HEADINGS.NORMAL_TEXT';

    /**
     * List of classes applied to new post dialog.
     *
     * @type {array}
     */
    cnp.classes = [];

    /**
     * Contains the filters for the view.
     *
     * @type {Object}
     */
    cnp.filters = {
        community: undefined,
    };

    /**
     * Indicates if we are fecthing suggested dates from API in order to display a loader.
     *
     * @type {boolean}
     */
    cnp.isSuggestedMeetingLoading = false;

    /**
     * Available communities, use to select the community for the post.
     *
     * @type {Array}
     */
    cnp.listCommunities = [];

    /**
     * The pending community store.
     *
     * @type {Object}
     */
    cnp.pendingCommunity = undefined;

    /**
     * The date format to use for all dates being sent to the server.
     *
     * @type {string}
     */
    cnp.dateFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS';

    /**
     * Contains miscellaneous status about the controller.
     *
     * @type {Object}
     */
    cnp.is = {
        saving: false,
    };

    /**
     * Whether we are locking the new post to a specific parent content details (i.e. posting from a community).
     *
     * @type {boolean}
     */
    cnp.isSameOrigin = $state.current.name === 'app.front.community';

    /**
     * State of the connected user.
     *
     * @type {boolean}
     */
    cnp.isConnectedUserAdmin = false;

    /**
     * State of the user associated to the post, connected user in creation or author in edition.
     *
     * @type {boolean}
     */
    cnp.isUserAdmin = false;

    /**
     * The list of suggested meeting times to organizea meeting with community members.
     * Used only with MS env.
     *
     * @type {Array}
     */
    cnp.suggestedMeetingTimes = [];

    /**
     * The post publication date.
     * If create, right now.
     * If edit and less than 2 days, from now, else, full date.
     *
     * @type {string}
     */
    cnp.postDate = undefined;

    /**
     * The language to use for the post.
     *
     * @type {string}
     */
    cnp.postLang = Translation.getLang('current');

    /**
     * An object with all the possible post types.
     *
     * @type {Object}
     */
    cnp.postTypes = undefined;

    /**
     * The label of the save button. Depends on whether we're editing or creating one.
     *
     * @type {string}
     */
    cnp.saveButtonLabel = Translation.translate('POST');

    /**
     * The user associated to the post, the author in edition, the connected user in creation.
     *
     * @type {Object}
     */
    cnp.user = undefined;

    /**
     * The prefix for all keys related to the new post.
     *
     * @type {string}
     */
    cnp.keyPrefix = 'community-new-post';

    /**
     * A map of keys for various pickers / dialogs.
     *
     * @type {Object}
     */
    cnp.keys = {
        contentPicker: `${cnp.keyPrefix}-content-picker`,
        newPostDialog: `${cnp.keyPrefix}-dialog`,
    };

    /**
     * The settings for the new post attachments dialogs.
     *
     * @type {object}
     */
    cnp.dialogSettings = {
        files: {
            isOpen: false,
            key: `${cnp.keyPrefix}-file-picker`,
        },
        imageInsert: {
            images: [],
            isOpen: false,
            key: `${cnp.keyPrefix}-imageInsert-picker`,
        },
        images: {
            isOpen: false,
            key: `${cnp.keyPrefix}-image-picker`,
        },
        links: {
            isOpen: false,
            key: `${cnp.keyPrefix}-add-link-dialog`,
        },
    };

    /**
     * Indicates if a preview crawler is currently loading or not.
     *
     * @type {boolean}
     */
    cnp.isPreviewLoading = false;

    /**
     * A map of various loading states.
     * - searchIsLoading: whether the community search is currently loading or not.
     *
     * @type {Object}
     */
    cnp.is = {
        searchLoading: false,
    };

    /**
     * The selected post type.
     *
     * @type {Object}
     */
    cnp.postType = undefined;

    /**
     * Binding to the text editor action.
     *
     * @type {Object}
     */
    cnp.textEditorControl = {};

    /**
     * The different hours used in event post.
     *
     * @type {Array}
     */
    cnp.times = [];

    /**
     * The markdown mutator for the rich text editor.
     *
     * @type {Object}
     */
    cnp.editorDisplayOption = {
        displayStyleButton: false,
        displayViewSwitch: false,
    };

    /**
     * The post current community.
     */
    cnp.community = undefined;

    /**
     * String store of date picker ids.
     *
     * @type {Object}
     */
    cnp.datePickerIds = {
        end: generateUUID(),
        start: generateUUID(),
    };

    // TODO [max]: Going fast, this is also in ActivePost try to refactor to avoid duplication.
    /**
     * Connected user rights for the active post.
     *
     * @type {Object}
     */
    cnp.userRights = {
        admin: false,
        editor: false,
        isAuthor: false,
    };

    /**
     * Array of inline images.
     *
     * @type {{imageName: string, type: string, url: string}[]}
     */
    cnp.inlineImages = [];

    cnp.wrexEditorRef = null;
    cnp.wrexValue = null;
    cnp.wrexChangeId = null;
    cnp.wrexImageInsertionPoint = null;
    cnp.wrexOnChange = (editor) => {
        cnp.wrexEditorRef = editor;
        // Force the update of the toolbar by generating a random change id.
        cnp.wrexChangeId = Math.random();
    };

    /**
     * The post title placeholder. It depends on the type of post.
     * Defaults to the translation key 'GLOBAL.TITLE'
     *
     * @type {string}
     */
    cnp.titlePlaceholder = _POST_TITLE_PLACEHOLDERS.default;

    /**
     * Flag used to mark the IE compatibility mode as activated or not.
     */
    cnp.ieCompatibilityModeActive = isIE() || isLegacyEdge();

    /**
     * Content mode for IE compatibility mode (show editor or show preview).
     * @type {'edit' | 'preview'}
     */
    cnp.ieContentMode = 'edit';

    /**
     * Raw Markdown content for IE compatibility mode.
     */
    cnp.iePostContent = '';

    /**
     * On change function for IE compatibility mode.
     */
    cnp.ieOnChange = (value) => {
        cnp.iePostContent = value;
    };

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    cnp.ActivePost = ActivePost;
    cnp.Community = Community;
    cnp.Config = Config;
    cnp.Features = Features;
    cnp.LsTextEditor = LsTextEditor;
    cnp.LsTextEditorMarkdown = LsTextEditorMarkdown;
    cnp.LsDatePickerService = LsDatePickerService;
    cnp.LxDialogService = LxDialogService;
    cnp.Post = Post;
    cnp.Translation = Translation;
    cnp.User = User;
    cnp.Upload = Upload;
    cnp.User = User;
    cnp.Utils = Utils;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Consolidates the event date of the post:
     *     - create and single date 'eventStartDate' in the post from the event start date and time of the
     *       controller.
     *     - create and single date 'eventEndDate' in the post from the event end date and time of the controller.
     *
     * The format for the dates is: 2016-10-24T13:00:29.585525Z+02:00.
     */
    function _consolidateDates() {
        angular.forEach(['eventStartDate', 'eventEndDate'], function forEachDatesToProcess(dateToProcess) {
            const tempDate = moment(cnp[dateToProcess].date).format('YYYY-MM-DD');
            const tempTime = moment(
                cnp[dateToProcess].timeFormated,
                moment()
                    .locale(cnp.postLang)
                    .localeData()
                    .longDateFormat('LT'),
            )
                .locale(cnp.postLang)
                .format('HH:mm:00.000000');
            cnp.newPost[dateToProcess] = moment(`${tempDate}T${tempTime}`)
                .utc()
                .format(cnp.dateFormat);
        });
    }

    /**
     * Fetch suggested meetings times depending on the event post data.
     */
    function _fetchSuggestedMeetings() {
        cnp.isSuggestedMeetingLoading = true;
        const fetchStartDate = cnp.createdAt.format();
        const fetchEndDate = moment(fetchStartDate)
            .add(2, 'W')
            .format();
        const eventDuration = moment
            .duration(moment(cnp.eventEndDate.date).diff(moment(cnp.eventStartDate.date)))
            .asMinutes();

        const durationHours = Math.floor(eventDuration / 60);
        const durationMinutes = eventDuration % 60;

        const params = {
            fromDate: fetchStartDate,
            hours: durationHours,
            minutes: durationMinutes,
            toDate: fetchEndDate,
            uid: cnp.community.id,
        };

        Community.getSuggestedMeetings(params)
            .then(({ suggestions = [] } = {}) => {
                cnp.suggestedMeetingTimes = suggestions;

                angular.forEach(cnp.suggestedMeetingTimes, (suggestedTime) => {
                    suggestedTime.startDate = moment
                        .utc(suggestedTime.startDate)
                        .local()
                        .format();
                    suggestedTime.endDate = moment

                        .utc(suggestedTime.endDate)
                        .local()
                        .format();

                    suggestedTime.isEverybodyFree =
                        angular.isUndefinedOrEmpty(suggestedTime.busy) &&
                        angular.isUndefinedOrEmpty(suggestedTime.unknown);

                    cnp.isSuggestedMeetingLoading = false;
                });
            })
            .catch(() => {
                cnp.isSuggestedMeetingLoading = false;
            });
    }

    /**
     * Determine the listKey to be used on community post save.
     *
     * @return {string} The listKey string to use when saving the community post.
     */
    function _getListKey() {
        return `community-${cnp.newPost.parentContentDetails.id}`;
    }

    /**
     * Initialize all dates for an event post:
     *     - set the min start date to today.
     *     - set the default start date to today and start time at now (rounded at 0 minutes).
     *     - set the default end date to tomorrow, same time (now rounded at 0 minutes).
     */
    function _initDates() {
        cnp.createdAt = angular.isDefinedAndFilled(cnp.newPost.createdAt)
            ? moment.utc(cnp.newPost.createdAt).local()
            : moment();

        cnp.eventStartDate = {
            date: angular.isDefinedAndFilled(cnp.newPost.eventStartDate)
                ? moment.utc(cnp.newPost.eventStartDate).local()
                : angular.copy(cnp.createdAt),
        };

        cnp.eventEndDate = {
            date: angular.isDefinedAndFilled(cnp.newPost.eventEndDate)
                ? moment.utc(cnp.newPost.eventEndDate).local()
                : angular.copy(cnp.eventStartDate.date).add(1, 'hour'),
        };

        cnp.times = [];

        // Init the time choices.
        for (let i = 0, nbHours = _HOURS_IN_A_DAY; i < nbHours; i++) {
            const hour = moment()
                .startOf('hour')
                .hours(i);

            cnp.times.push(hour.format('LT'));
            cnp.times.push(hour.minutes(_HALF_AN_HOUR).format('LT'));
        }

        angular.forEach(['eventStartDate', 'eventEndDate'], function forEachDatesToProcess(dateToProcess, key) {
            cnp[dateToProcess].dateFormatted = cnp[dateToProcess].date.locale(cnp.postLang).format('ll');

            cnp[dateToProcess].timeFormated = cnp[dateToProcess].date;

            let isDefaultTime = false;
            if (angular.isUndefinedOrEmpty(cnp.newPost[dateToProcess])) {
                isDefaultTime = true;
                if (cnp[dateToProcess].timeFormated.minutes() >= _HALF_AN_HOUR) {
                    cnp[dateToProcess].timeFormated = cnp[dateToProcess].timeFormated.minutes(_HALF_AN_HOUR);
                } else {
                    cnp[dateToProcess].timeFormated = cnp[dateToProcess].timeFormated.minutes(0);
                }

                cnp[dateToProcess].timeFormated.add(1, 'hour');
            }
            cnp[dateToProcess].timeFormated = cnp[dateToProcess].timeFormated
                .startOf('minutes')
                .locale(cnp.postLang)
                .format('LT');

            if (isDefaultTime && key === 'eventEndDate') {
                cnp[dateToProcess].timeFormated.add(1, 'hour');
            }
        });
    }

    /**
     * Init/reset the newPost object.
     */
    function _initPost() {
        cnp.currentAttachmentType = undefined;
        cnp.feedMentioned = [];
        cnp.isSameOrigin = false;
        cnp.postLang = Translation.getLang('current');
        cnp.specialMentions = {};
        // We don't necessarily have a community set when we open the create post dialog so use a temp one: -1.
        cnp.specialMentions['-1'] = LsTextEditor.mentionAllKeywords;

        cnp.iePostContent = '';
        cnp.ieContentMode = 'edit';

        cnp.wrexValue = null;
        cnp.wrexEditorRef = null;
        cnp.newPost = {
            authorDetails: {
                uid: get(User.getConnected(), 'uid'),
            },
            calendarEventId: '',
            content: {},
            externalKey: undefined,
            feedKeys: undefined,
            files: [],
            images: [],
            kind: 'Content',
            links: [],
            location: {},
            parentContentDetails: {
                feedKeys: ['-1'],
            },
            postType: undefined,
            slug: {},
            tags: [],
            tagz: [],
            title: {},
        };

        // Init post date.
        cnp.postDate = Translation.translate('RIGHT_NOW');
    }

    /**
     * Build the needed element for the post edition.
     *
     * @param {Object} post The post to build the context setup.
     */
    function _buildSetup(post) {
        cnp.feedMentioned = [];
        cnp.iePostContent = '';
        cnp.wrexValue = null;
        cnp.ieContentMode = 'edit';

        if (post.content) {
            cnp.feedMentioned = LsTextEditor.getFeedMentionedFromFeedKeys(
                post.content[cnp.Translation.getLang('current')],
                post.mentionsFeedKeys,
            );
            cnp.postLang =
                Translation.getLang('current') in post.content
                    ? Translation.getLang('current')
                    : Translation.getFirstLangWithValue(post.content);
            if (cnp.ieCompatibilityModeActive) {
                cnp.iePostContent = post.content[cnp.postLang];
            } else {
                cnp.wrexValue = fromMarkdown(post.content[cnp.postLang], {mentionsDetails: post.mentionsDetails });
            }
        }
        cnp.newPost = angular.fastCopy(post);
        cnp.userMentioned = angular.fastCopy(post.mentionsDetails);

        // Need to reformat the date otherwise it breaks on saving the post again.
        cnp.newPost.lastUpdateDate = moment.utc(cnp.newPost.lastUpdateDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS');

        if (cnp.newPost.postType === Community.availablePostTypes.EVENT.value) {
            _initDates();
        }

        cnp.currentAttachmentType = Utils.getCurrentAttachmentType(cnp.newPost);

        // Init post date.
        if (moment().diff(moment(cnp.newPost.publicationDate), 'days') < _DELTA_TEXT_FROM_NOW) {
            cnp.postDate = moment
                .utc(cnp.newPost.publicationDate)
                .local()
                .fromNow();
        } else {
            cnp.postDate = moment
                .utc(cnp.newPost.publicationDate)
                .local()
                .format('ll');
        }
    }

    /**
     * Validate that dates are valid.
     * To be valid, end date must be after start date.
     *
     * @return {boolean} If the dates are valid.
     */
    function _validateDate() {
        return moment(cnp.newPost.eventEndDate).isAfter(cnp.newPost.eventStartDate);
    }

    /**
     * When the insert image media picker closes.
     */
    function _onInsertImage() {
        const { imageInsert } = cnp.dialogSettings;
        imageInsert.isOpen = false;

        const lang = Translation.getLang('current');

        let image;
        while ((image = imageInsert.images.pop())) {
            const croppedContent = loFind(image.croppedContent, { lang }) || first(image.croppedContent);
            const content = croppedContent || loFind(image.content, { lang }) || first(image.content);
            const url = content.servingUrl || content.url;

            cnp.wrexEditorRef?.insertImage(
                {
                    src: url,
                    alt: content.name,
                },
                cnp.wrexImageInsertionPoint,
            );
        }
        cnp.wrexImageInsertionPoint = null;
    }

    /**
     * returns the title placeholder depending on the currently selected post type.
     *
     * @param  {string} postType the type of post.
     *
     * @return {string} the post title placeholder
     */
    function _getTitlePlaceholder(type) {
        return _POST_TITLE_PLACEHOLDERS[type] || _POST_TITLE_PLACEHOLDERS.default;
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Change the type of post.
     * This is allowed only if it's a new post.
     *
     * @param {Object} postType The selected post type.
     */
    function changePostType(postType) {
        const newPostType = get(postType, 'value');

        if (angular.isUndefined(newPostType) || angular.isUndefined(cnp.postTypes[newPostType])) {
            return;
        }

        cnp.newPost.postType = newPostType;
        cnp.postType = postType;
        cnp.titlePlaceholder = _getTitlePlaceholder(cnp.newPost.postType);

        if (newPostType === Community.availablePostTypes.EVENT.value) {
            _initDates();
        }
    }

    /**
     * Callback function triggered when we have found a URL in the content of the post.
     *
     * @param {string} urlToFind The url found in the content of the post to be added as a link attachment.
     * @param {string} inText    The text in which the URL was found.
     */
    function findUrlsCallback(urlToFind, inText) {
        if (angular.isUndefinedOrEmpty(urlToFind)) {
            return;
        }

        // Ignore url found in Markdown image.
        const urlInMDImage = new RegExp(`!\\[[^\\]]*?\\]\\((\\w+)?://${escapeRegex(urlToFind)}\\)`);
        if (angular.isDefinedAndFilled(inText) && inText.match(urlInMDImage)) {
            return;
        }

        $scope.$broadcast('attachments__get-preview', urlToFind);
    }

    /**
     * Return the classes applied to the new post dialog.
     *
     * @return {Array} The list of classes to apply.
     */
    function getClasses() {
        return cnp.classes;
    }

    /**
     * Called whenever tagz are change in the selector.
     *
     * @param {Object} tagList The selected item in tagz selection component.
     */
    function handleTagzChange(tagList) {
        if (angular.isDefined(tagList)) {
            cnp.newPost.tagz = [...tagList];
        }
    }

    /**
     * Notify the end of the upload of the files drop or past.
     *
     * @param {string} settingsId The identifier of the dialog.
     */
    function pasteDropFilesEnd(settingsId) {
        $scope.$broadcast('attachments__paste-drop-files-end', settingsId);
    }

    /**
     * Attach the files to the post.
     *
     * @param {Array} files The uploaded files.
     */
    function onUploadSuccess(files) {
        Upload.onFileUploadSuccess(
            files,
            Community.getCurrent(),
            cnp.newPost,
            true,
            cnp.dialogSettings,
            cnp.pasteDropFilesEnd,
        );
    }

    /**
     * Format a date in the wanted format.
     *
     * @param {string} date   The selected date.
     * @param {Object} target The target to set the formatted date.
     */
    function formatDate(date, target) {
        target.dateFormatted = moment(date)
            .locale(cnp.postLang)
            .format('ll');

        if (!Features.hasFeature('microsoft') || !User.isMicrosoft()) {
            return;
        }

        target.date = moment(
            `${target.dateFormatted} [at] ${target.timeFormated}`,
            Translation.translate('DATE_FORMAT_COMPLETE'),
        );

        _fetchSuggestedMeetings();
    }

    /**
     * Select a community from the list, get it and set it as the community where to post.
     *
     * @param {string} id The id of the community.
     */
    function selectCommunity(id) {
        Community.get(
            {
                uid: id,
            },
            function onCommunityGetSuccess(response) {
                cnp.setCommunity(response);
            },
            Utils.displayServerError,
            undefined,
            undefined,
            false,
        );
    }

    // TODO [max]: Rename buildPost and add fct setCommunity that just set cnp.community.
    /**
     * Set the new post community (cnp.newPost must be inited first in both case: new or edit).
     *
     * @param {Object} community The community to set as current for the post.
     */
    function setCommunity(community) {
        const { dispatch } = ReduxStore.store;

        let { postTypes } = community;
        if (Features.hasFeature(EVENTS_FF)) {
            // Remove the Old post event type if the New event feature
            postTypes = without(postTypes, PostType.EVENT);
        }
        dispatch(
            postActions.setEditedPost({
                images: [],
                files: [],
                links: [],
                title: '',
                tagsDetails: [],
                postStatusDetails: {},
                eventStartDate: undefined,
                eventEndDate: undefined,
                uid: '',
                mentions: [],
                content: createEmptyContent(),
                postType: postTypes.includes(PostType.DEFAULT) ? PostType.DEFAULT : postTypes[0],
                externalKey: community.id,
                instance: community.instance,
                availableCategories: community.tagsDetails,
                parentContentDetails: {
                    /** List of all availables post types for the given community. */
                    postTypes,
                    /** List of availables post statuses for the given community. */
                    postStatuses: community.postStatuses,
                    /** The slug of the parent content. */
                    slug: community.slug,
                    /** Type of content */
                    type: community.type,
                    /** Indicates the option 'Resctricted storage' has been activated */
                    securedRepository: community?.securedRepository,
                },
            }),
        );
        openPostDialog();
        LxDialogService.close(cnp.keys.newPostDialog);
        // TODO [max]: reset status and some params when switching types ?
        if (angular.isDefined(community)) {
            cnp.community = community;
            cnp.postTypes = pick(Community.availablePostTypes, cnp.community.postTypes);

            // In case the set community is called from the community selector and new post is not set.
            if (angular.isUndefinedOrEmpty(get(cnp.newPost, 'postType'))) {
                if (angular.isUndefined(cnp.newPost)) {
                    _initPost();
                }

                cnp.postType = Community.getFirstAvailablePostTypes(cnp.postTypes);
                cnp.newPost.postType = cnp.postType.value;
            }

            const user = User.getConnected();
            const isUserAdmin = Community.isUserAdmin(cnp.community, user);

            cnp.postType = Community.getFirstAvailablePostTypes(cnp.postTypes);
            cnp.newPost.postType = get(cnp.postType, 'value', Community.availablePostTypes.DEFAULT.value);

            cnp.userRights.admin = isUserAdmin;
            cnp.userRights.isAuthor =
                get(cnp.newPost, 'authorDetails.uid') && get(user, 'uid') === cnp.newPost.authorDetails.uid;
            cnp.userRights.editor = UserAccess.isUserAllowed('COMMUNITY_POST_EDIT');

            cnp.newPost.parentContentDetails = community;
            cnp.isConnectedUserAdmin = isUserAdmin;
            cnp.isUserAdmin = Community.isUserAdmin(cnp.community, cnp.user);

            // Setting up the @all for the current community.
            cnp.specialMentions = {};
            cnp.specialMentions[community.communityFeedKey] = LsTextEditor.mentionAllKeywords;
        }
    }

    /**
     * Setter for event times, work as ng-model/ng-change.
     *
     * @param {Object} target The object to set time to.
     * @param {string} time   The time.
     */
    function setTime(target, time) {
        if (angular.isUndefined(target)) {
            return;
        }

        target.timeFormated = time;

        if (!Features.hasFeature('microsoft') || !User.isMicrosoft()) {
            return;
        }

        target.date = moment(
            `${target.dateFormatted} [at] ${target.timeFormated}`,
            Translation.translate('DATE_FORMAT_COMPLETE'),
        );

        _fetchSuggestedMeetings();
    }

    /**
     * Define the event date times with the selected suggested times.
     *
     * @param {Object} suggestedTime The selected suggested times.
     */
    function setSuggestedTime(suggestedTime) {
        cnp.eventStartDate.date = moment(suggestedTime.startDate);
        cnp.eventStartDate.dateFormatted = cnp.eventStartDate.date.format(Translation.translate('DATE_FORMAT'));
        cnp.eventStartDate.timeFormated = cnp.eventStartDate.date.format(Translation.translate('HOUR_FORMAT'));

        cnp.eventEndDate.date = moment(suggestedTime.endDate);
        cnp.eventEndDate.dateFormatted = cnp.eventEndDate.date.format(Translation.translate('DATE_FORMAT'));
        cnp.eventEndDate.timeFormated = cnp.eventEndDate.date.format(Translation.translate('HOUR_FORMAT'));
    }

    /**
     * Save the community post.
     */
    function submitPost() {
        if (cnp.is.saving) {
            return;
        }

        if (!cnp.ieCompatibilityModeActive) {
            cnp.userMentioned = getAllMentionedUsers(cnp.wrexEditorRef, cnp.wrexEditorRef.children.length);
        }
        cnp.is.saving = true;

        if (angular.isUndefinedOrEmpty(get(cnp.newPost.parentContentDetails, 'id'))) {
            LxNotificationService.error(Translation.translate('COMMUNITY_NOT_SELECTED'));
            cnp.is.saving = false;

            return;
        }

        if (cnp.newPost.postType === Community.availablePostTypes.EVENT.value) {
            _consolidateDates();

            if (!_validateDate()) {
                LxNotificationService.error(Translation.translate('COMMUNITY_NEW_POST_INVALID_DATES'));
                cnp.is.saving = false;

                return;
            }
            if (!Translation.hasTranslations(cnp.newPost.title, true)) {
                LxNotificationService.error(Translation.translate('COMMUNITY_NEW_POST_TITLE_REQUIRED'));
                cnp.is.saving = false;

                return;
            }
        }

        if (angular.isUndefined(cnp.newPost.content)) {
            cnp.newPost.content = {};
        }

        if (cnp.ieCompatibilityModeActive) {
            cnp.newPost.content[cnp.postLang] = cnp.iePostContent;
        } else {
            cnp.newPost.content[cnp.postLang] = toMarkdown(cnp.wrexEditorRef.children);
        }

        if (
            !Translation.hasTranslations(cnp.newPost.content, true) &&
            angular.isUndefinedOrEmpty(cnp.currentAttachmentType)
        ) {
            LxNotificationService.error(Translation.translate('COMMUNITY_NEW_POST_DESCRIPTION_REQUIRED'));
            cnp.is.saving = false;

            return;
        }

        angular.extend(cnp.newPost, {
            customer: Customer.getCustomerId(),
            instance: Instance.getCurrentInstanceId(),
            type: Config.AVAILABLE_CONTENT_TYPES.POST,
        });

        if (angular.isDefinedAndFilled(cnp.userMentioned)) {
            cnp.newPost.mentions = map(cnp.userMentioned, 'id');
        }

        if (angular.isDefined(cnp.feedMentioned)) {
            cnp.newPost.mentionsFeedKeys = map(cnp.feedMentioned, 'id');
        } else {
            cnp.newPost.mentionsFeedKeys = [];
        }

        Post.save(
            cnp.newPost,
            function onPostSaveSuccess(response) {
                // Let the community post know it got updated.
                Post.setCurrent(response);
                $rootScope.$broadcast('community__post-saved', response);
                cnp.is.saving = false;
                LxDialogService.close(cnp.keys.newPostDialog);

                let multipostingEnable = true;

                // check if the admin enabled/disabled the multiposting from the global settings
                const _mpGlobalSetting = loFind(
                    Instance.getProperty(Config.INSTANCE_PROPERTIES.SOCIAL_SETTINGS),
                    (item) => item.name === 'multiposting_allowed',
                );
                if (_mpGlobalSetting) {
                    multipostingEnable = _mpGlobalSetting.enable;
                }

                if (response.canShare && multipostingEnable) {
                    LxNotificationService.success(
                        Translation.translate('FRONT.COMMUNITY.NEW_POST.PUBLISHED'),
                        Translation.translate('FRONT.COMMUNITY.POST.ACTION.SHARE'),
                        () => $rootScope.$broadcast('community-multi-select__open', { post: response }),
                    );
                } else {
                    LxNotificationService.success(Translation.translate('FRONT.COMMUNITY.NEW_POST.PUBLISHED'));
                }
                Community.goTo(response.parentContentDetails, true);
            },
            function onPostSaveError(err) {
                cnp.is.saving = false;

                // Handle Nanny error.
                if (shouldUseNannyErrorHandler(ReduxStore.store.getState(), err)) {
                    ReduxStore.store.dispatch(handleNannyError(err));
                    return;
                }

                // Handle Tagz error.
                if (shouldUseTagzErrorHandler(ReduxStore.store.getState(), err)) {
                    ReduxStore.store.dispatch(handleTagzError(err));
                    return;
                }

                Utils.displayServerError(err);
            },
            _getListKey(),
        );
    }

    /**
     * Trigger when post detail field is focused.
     */
    function onFocusField() {
        if (cnp.classes.indexOf('community-new-post-dialog--focused') === -1) {
            cnp.classes.push('community-new-post-dialog--focused');
        }
    }

    /**
     * Insert inline image.
     */
    function openImagePicker() {
        const { imageInsert } = cnp.dialogSettings;
        imageInsert.isOpen = true;
        cnp.wrexImageInsertionPoint = cnp.wrexEditorRef?.selection?.focus;

        Utils.waitForAndExecute(`#${imageInsert.key}`, AbstractPicker);
    }

    /**
     * Closes the dialog when user clicks on CANCEL if he has made no edit yet.
     * Otherwise opens a Notification dialog to ask for confirmation before closing.
     */
    function closeDialog() {
        !Translation.hasTranslations(cnp.newPost.title, true) &&
        !Translation.hasTranslations(cnp.newPost.content, true) &&
        angular.isUndefinedOrEmpty(cnp.currentAttachmentType)
            ? LxDialogService.close(cnp.keys.newPostDialog)
            : LxNotificationService.confirm(
            Translation.translate('GLOBAL.DISCARD_DRAFT'),
            Translation.translate('FRONT.COMMUNITY.POST.WARNING.UNFINISHED_EDIT'),
            {
                cancel: Translation.translate('GLOBAL.GO_BACK'),
                ok: Translation.translate('GLOBAL.DISCARD'),
            },
            function onDiscardConfirm(answer) {
                if (!answer) {
                    return;
                }
                LxDialogService.close(cnp.keys.newPostDialog);
            },
            );
    }

    /**
     * Switch between edit and preview in IE compatibility mode.
     */
    function toggleIEPreviewMode() {
        cnp.ieContentMode = cnp.ieContentMode === 'edit' ? 'preview' : 'edit';
    }

    // ///////////////////////////

    cnp.changePostType = changePostType;
    cnp.debouncedFindUrls = debounce(Utils.findUrls, _DEBOUND_FIND_URL_DELAY);
    cnp.findUrlsCallback = findUrlsCallback;
    cnp.getClasses = getClasses;
    cnp.formatDate = formatDate;
    cnp.onFocusField = onFocusField;
    cnp.handleTagzChange = handleTagzChange;
    cnp.onUploadSuccess = onUploadSuccess;
    cnp.pasteDropFilesEnd = pasteDropFilesEnd;
    cnp.selectCommunity = selectCommunity;
    cnp.setCommunity = setCommunity;
    cnp.setSuggestedTime = setSuggestedTime;
    cnp.setTime = setTime;
    cnp.submitPost = submitPost;
    cnp.openImagePicker = openImagePicker;
    cnp.closeDialog = closeDialog;
    cnp.openPostDialog = openPostDialog;
    cnp.closePostDialog = closePostDialog;
    cnp.toggleIEPreviewMode = toggleIEPreviewMode;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    /**
     * Update the preview loading state.
     *
     * @param {Event}   evt       The event triggering this method.
     * @param {boolean} isLoading Whether the preview is loading or not.
     */
    $scope.$on('attachments__preview-loading-update', function onPreviewLoadingUpdate(evt, isLoading) {
        cnp.isPreviewLoading = isLoading;
    });

    $scope.$on('ls-rich-text-editor__movement', (evt, opt) => {
        cnp.currentHeading = opt.prefixMode;
    });


    cnp.isPostDialogOpen = false;

    function openPostDialog() {
        cnp.isPostDialogOpen = true;
    }

    function closePostDialog() {
        cnp.isPostDialogOpen = false;
    }

    /**
     * This event work as the controller init function.
     * This controller is called from different context, inside a community or from anywhere.
     * If a community is passed in the function params, we set it as the post default community.
     * Else the user will be prompted to select a community (work only for a new post currently).
     *
     * So this function only set the community and defined the origin.
     *
     * @param {Event}  evt           The original event triggering the opening of the dialog.
     * @param {Object} [post]        The post being edited.
     * @param {Object} community     The community the post belongs to.
     * @param {string} [newPostType] The type of post to create.
     * @param {string} [postContent] The content of post to pre-set.
     */
    $scope.$on('create-new-post__open', function setPost(evt, post = {}, community, newPostType, postContent) {
        const { dispatch } = ReduxStore.store;
        _SEARCH_LANG = angular.isDefinedAndFilled(Instance.getInstance().langs)
            ? Instance.getInstance().langs
            : Translation.getLang('current');

        let content;
        const translatedPostContent = Translation.translate(post.content);
        if (angular.isDefinedAndFilled(translatedPostContent)) {
            content = translatedPostContent;
        }

        if (angular.isDefinedAndFilled(postContent)) {
            content = postContent;
        }

        if (community) {
            cnp.community = community;

            let { postTypes } = community;
            if (Features.hasFeature(EVENTS_FF)) {
                // Remove the Old post event type if the New event feature
                postTypes = without(postTypes, PostType.EVENT);
            }
            /**
             * Set the community that is target for the post creation in store.
             * */
            dispatch(foActions.setSelectedCommunity({ ...community }));
            dispatch(
                postActions.setEditedPost({
                    postType: newPostType,
                    images: [],
                    files: [],
                    links: [],
                    tagsDetails: [],
                    postStatusDetails: {},
                    uid: '',
                    mentions: [],
                    ...post,
                    eventStartDate: post.eventStartDate
                        ? moment
                            .utc(post.eventStartDate)
                            .local()
                            .format(FULL_DATE_SIX_FRACTIONAL_SECONDS)
                        : undefined,
                    eventEndDate: post.eventEndDate
                        ? moment
                            .utc(post.eventEndDate)
                            .local()
                            .format(FULL_DATE_SIX_FRACTIONAL_SECONDS)
                        : undefined,
                    externalKey: community.id,
                    instance: community.instance,
                    content: angular.isUndefinedOrEmpty(content)
                        ? undefined
                        : fromMarkdown(content, { mentionsDetails: post.mentionsDetails }),
                    availableCategories: community.tagsDetails,
                    parentContentDetails: {
                        /** List of all availables post types for the given community. */
                        postTypes,
                        /** List of availables post statuses for the given community. */
                        postStatuses: community.postStatuses,
                        /** The slug of the parent content. */
                        slug: community.slug,
                        /** Type of content */
                        type: community.type,
                        /** Indicates the option 'Resctricted storage' has been activated */
                        securedRepository: community.securedRepository,
                    },
                    title: !angular.isUndefinedOrEmpty(post.title) ? Translation.translate(post.title) : '',
                }),
            );

            openPostDialog();
        }
    });

    /**
     * Sets the focus in the text field after the new post dialog has been opened.
     *
     * @param {Event}  evt      The event triggering this method.
     * @param {String} dialogId The id of the dialog that triggered this function.
     */
    $scope.$on('lx-dialog__open-end', function onNewPostDialogOpenEnd(evt, dialogId) {
        if (cnp.keys.newPostDialog === dialogId) {
            angular.element(`#${dialogId} textarea`).focus();
        }
    });

    /**
     * When posting from a community, we want to set the parentContentDetails to this community.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The id of the dialog being opened.
     */
    $scope.$on('lx-dialog__open-start', function onNewPostDialogOpen(evt, dialogId) {
        cnp.community = undefined;
    });

    /**
     * Reset the newPost object whenever we close the dialog so we start off with a clean state when we come back
     * later.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The id of the dialog being closed.
     */
    $scope.$on('lx-dialog__close-end', function onDialogClose(evt, dialogId) {
        // Reset post when closing the create new post dialog.
        if (cnp.keys.newPostDialog === dialogId) {
            _initPost();
        }
    });

    /**
     * When abstract picker closes.
     *
     * @param {Event}  evt      Close event.
     * @param {string} dialogId Dialog ID.
     */
    $scope.$on('abstract-picker__close-end', (evt, dialogId) => {
        if (dialogId === cnp.dialogSettings.imageInsert.key) {
            _onInsertImage();
        }
    });
}

// ///////////////////////////

angular.module('Controllers').controller('CommunityNewPostController', CommunityNewPostController);

// ///////////////////////////

export { CommunityNewPostController };
