import zipObject from 'lodash/zipObject';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function ActivePostService(Community, LxDialogService, Post, PostIdea, User, UserAccess) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The active post.
         *
         * @type {Object}
         */
        var _activePost;

        /**
         * Flag to know if the post will be save or update by reference.
         *
         * @type {boolean}
         */
        var _refSave = false;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The tags edit modal identifier prefix.
         *
         * @type {string}
         */
        service.tagsDialogId = 'post-tags-modal-identifier';

        /**
         * The tags edit modal identifier prefix.
         *
         * @type {string}
         */
        service.statusDialogId = 'post-status-modal-identifier';

        /**
         * Connected user rights for the active post.
         *
         * @type {Object}
         */
        service.userRights = {
            admin: false,
            editor: false,
            isAuthor: false,
        };

        /////////////////////////////

        /**
         * Services and utilities.
         */

        service.PostIdea = PostIdea;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Reset service.
         */
        function _reset() {
            _activePost = undefined;
            service.userRights.editor = false;
            service.userRights.admin = false;
            service.userRights.isAuthor = false;
            _refSave = false;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the current active post.
         *
         * @return {Object} The active post.
         */
        function getPost() {
            return _activePost;
        }

        /**
         * Set the new active post.
         *
         * @param {Object} post The new active post.
         */
        function setPost(post) {
            service.userRights.admin = Community.isUserAdmin(post.parentContentDetails, User.getConnected());
            service.userRights.isAuthor = _.get(post, 'authorDetails.uid') &&
                _.get(User.getConnected(), 'uid') === post.authorDetails.uid;
            service.userRights.editor = UserAccess.isUserAllowed('COMMUNITY_POST_EDIT');

            _activePost = post;
        }

        /**
         * If there a call in progress on the active post.
         *
         * @return {boolean} The call state.
         */
        function isCallInProgress() {
            if (_.has(_activePost, 'uid')) {
                return Post.isCallInProgress(_activePost.uid);
            }

            return false;
        }

        // eslint-disable-next-line no-warning-comments
        // TODO [max]: refactor the two function below as one with a hook system base on the dialog id.
        /**
         * Open the tag edition modal.
         *
         * @param {Object}  post            The post to edit.
         * @param {boolean} saveByReference If the post is saved or update reference by reference.
         */
        function openTagDialog(post, saveByReference) {
            service.setPost(post);

            if (!service.userRights.isAuthor && !service.userRights.admin && !service.userRights.editor) {
                _reset();

                return;
            }

            _refSave = Boolean(saveByReference);

            // Build pending to match checkbox model.
            _activePost.$pendingTags = _activePost.tags
                ? zipObject(_activePost.tags, new Array(_activePost.tags.length).fill(true))
                : {};

            LxDialogService.open(service.tagsDialogId);
        }

        /**
         * Open the tag edition modal.
         *
         * @param {Object}  post            The post to edit.
         * @param {boolean} saveByReference If the post is saved or update reference by reference.
         */
        function openStatusDialog(post, saveByReference) {
            service.setPost(post);

            if (!service.userRights.admin && !service.userRights.editor) {
                _reset();

                return;
            }

            _refSave = Boolean(saveByReference);

            PostIdea.pendingStatus = post.postStatus;

            LxDialogService.open(service.statusDialogId);
        }

        /**
         * Save the post status.
         */
        function saveStatus() {
            if (angular.isUndefined(_activePost)) {
                return;
            }

            PostIdea.saveStatus(_activePost, _refSave, function onSuccessCallback() {
                LxDialogService.close(service.statusDialogId);
            });
        }

        /**
         * Save the post tags.
         */
        function saveTags() {
            if (angular.isUndefined(_activePost)) {
                return;
            }

            // Get only the tags id.
            var payload = angular.copy(_activePost);
            payload.tags = [];

            angular.forEach(_activePost.$pendingTags, function getTagsId(val, key) {
                if (val) {
                    payload.tags.push(key);
                }
            });

            if (_refSave) {
                _activePost.tags = payload.tags;
                _reset();
            } else {
                Post.save(payload, function onSaveSuccess(response) {
                    _activePost.tags = response.tags;
                    _activePost.tagsDetails = response.tagsDetails;
                    _activePost.version = response.version;
                    _reset();

                    LxDialogService.close(service.tagsDialogId);
                }, undefined, _activePost.uid);
            }
        }

        /**
         * Get the active post parent community.
         * Read the comment below before using it.
         *
         * @return {Object} The wanted community.
         */
        function getCommunity() {
            if (angular.isDefined(_activePost)) {
                if (angular.isDefined(Community.getCurrent()) &&
                    Community.getCurrent().id === _activePost.externalKey) {
                    return Community.getCurrent();
                }

                // /!\ Be careful we cannot rely on this element.
                // In all the use case we currently have the object will be correctly filled.
                // But in some case the object have just a few information.
                // TODO [max]: Maybe check in the community list or get it ?
                return _activePost.parentContentDetails;
            }

            return undefined;
        }

        /////////////////////////////

        service.getCommunity = getCommunity;
        service.getPost = getPost;
        service.isCallInProgress = isCallInProgress;
        service.openStatusDialog = openStatusDialog;
        service.openTagDialog = openTagDialog;
        service.saveStatus = saveStatus;
        service.saveTags = saveTags;
        service.setPost = setPost;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('ActivePost', ActivePostService);
})();
