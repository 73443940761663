import React from 'react';

import BaseApi from '@lumapps/base-api';
import { classnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import {
    ServiceNotAvailableState,
    ServiceNotAvailableStateProps,
} from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { SkeletonRectangle, SkeletonRectangleVariant, SkeletonRectangleProps } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { getToken } from '@lumapps/user/ducks/selectors';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { reducer, initialState, actions } from './slice';

import './index.scss';

const Config = get();

export interface AuthenticatedIframeProps {
    /** URL iframe */
    src: string;
    /** whether the iframe should load or not */
    shouldLoad?: boolean;
    /** iframe's width */
    width?: string;
    /** iframe's height */
    height?: string;
    /** iframe's title */
    title: string;
    /** custom iframe's props */
    iframeProps?: Omit<
        React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>,
        'title' | 'src'
    >;
    /** custom skeleton's props */
    skeletonProps?: SkeletonRectangleProps;
    /** custom service not available's props */
    serviceNotAvailableProps?: ServiceNotAvailableStateProps;
    /**
     * The way in which the iframe will be loaded
     * - src: as usual, using the URL as the src of the iframe, no authentication included.
     * - blob: The iframe is loaded using a URL blob
     * - cross-frame: The iframe is loaded with the src, but the authentication data is sent over via post message
     */
    loadingType?: 'src' | 'blob' | 'cross-frame';
    /** alternate loading needs iframe origin host */
    origin?: string;
    /** for specific postMessage data */
    additionalPostMessageData?: Record<string, any>;
}

/**
 * Define a custom base api in order to avoid the base URL
 */
export const api = new BaseApi({
    baseURL: '',
});

/**
 * Creates an iframe that sends the token as a header in order to retrieve
 * the iframe.
 *
 * @family Layouts
 * @param AuthenticatedIframeProps
 * @returns AuthenticatedIframe
 */

const CLASSNAME = 'lumx-authenticated-iframe';

export const AuthenticatedIframe: React.FC<AuthenticatedIframeProps> = ({
    src,
    shouldLoad = true,
    skeletonProps,
    serviceNotAvailableProps,
    iframeProps,
    title,
    width = '100%',
    height = '100%',
    loadingType = 'blob',
    origin,
    additionalPostMessageData = {},
}) => {
    const iframeRef = React.useRef(null);

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const token = useSelector(getToken);

    /**
     * Retrieves the iframe and creates a blob so that it can be used
     * as the src for the iframe.
     */
    const loadIframe = React.useCallback(() => {
        dispatch(actions.setLoadingStatus(BaseLoadingStatus.loading));

        switch (loadingType) {
            case 'blob':
                // Loading with XHR and Blob
                api.get(src, {
                    headers: {
                        source: 'iframe',
                    },
                })
                    .then((response) => {
                        /**
                         * Converts the response into a blob and creates a
                         */
                        const blob = new Blob([response.data], { type: 'text/html' });
                        const url = window.URL.createObjectURL(blob);

                        dispatch(actions.setSrc(url));
                    })
                    .catch(() => {
                        dispatch(actions.setLoadingStatus(BaseLoadingStatus.error));
                    });
                break;
            default:
                // Loading with URL
                dispatch(actions.setSrc(src));
                break;
        }
    }, [src, loadingType]);

    React.useEffect(() => {
        if (shouldLoad && state.status === BaseLoadingStatus.initial) {
            loadIframe();
        }
    }, [loadIframe, shouldLoad, state.status]);

    /**
     * Send the updated token to the iframe if the loading type is cross-frame
     */
    React.useEffect(() => {
        if (loadingType === 'cross-frame') {
            if (iframeRef?.current) {
                const targetFrame = (iframeRef.current as any).contentWindow;

                targetFrame.postMessage({ type: 'token_refreshed', data: { token } }, origin);
            }
        }
    }, [loadingType, origin, token]);

    /**
     * When loading content via URL, connection information are forwarded
     * to the iframe via window messaging
     */
    const initFrameCom = () => {
        if (iframeRef?.current) {
            const targetFrame = (iframeRef.current as any).contentWindow;

            window.onmessage = (event) => {
                if (event?.data?.type === 'connection_details_requested') {
                    targetFrame.postMessage(
                        {
                            type: 'connection_details_sent',
                            data: { token, cell: Config.haussmannCell, ...additionalPostMessageData },
                        },
                        origin,
                    );
                } else if (event?.data?.type === 'fresh_token_requested') {
                    BaseApi.refreshToken();
                }
            };

            targetFrame.postMessage({ type: 'parent_ready' }, origin);
        }
    };

    /**
     * If we are currently loading, we display a skeleton instead of an emtpy section.
     */
    if (state.status === BaseLoadingStatus.loading) {
        return (
            <SkeletonRectangle
                variant={SkeletonRectangleVariant.rounded}
                {...skeletonProps}
                style={{
                    width,
                    height,
                }}
            />
        );
    }

    /**
     * If there was an error, we display a ServiceNotAvailable component
     */
    if (state.status === BaseLoadingStatus.error && !state.src) {
        return (
            <ServiceNotAvailableState
                {...serviceNotAvailableProps}
                wrapperProps={{ style: { width, height } }}
                onRetry={loadIframe}
            />
        );
    }

    /**
     * If it was loaded and we have the src as a blob or as an url, we go ahead
     * and display the iframe.
     */
    if (state.status === BaseLoadingStatus.idle && state.src) {
        return (
            <iframe
                {...iframeProps}
                className={classnames(CLASSNAME, iframeProps?.className)}
                title={title}
                src={state.src}
                height={height}
                width={height}
                ref={mergeRefs([iframeRef, iframeProps?.ref as any])}
                onLoad={loadingType === 'cross-frame' ? initFrameCom : undefined}
            />
        );
    }

    return null;
};
