/* eslint-disable max-classes-per-file */
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pull from 'lodash/pull';
import * as propTypes from 'prop-types';
import { Component, createElement } from 'react';
import { createPortal, render, unmountComponentAtNode } from 'react-dom';

import { get } from '@lumapps/constants';
import { Provider } from '@lumapps/redux/react';
import { injectTranslationService } from '@lumapps/translations/angular';
import { injectUserService } from '@lumapps/user/angular-user';
import { MemoryRouter } from '@lumapps/router';
import { Customizations } from '@lumapps/customizations';
import { PostContent } from '@lumapps/widget-legacy-post-list/components/PostContent';
import { PostBlockContent } from '@lumapps/widget-legacy-post-list/components/PostBlock';
import { BlockComments } from '@lumapps/widget-comments';
import { LegacyContributionField } from '@lumapps/widget-legacy-post-list/components/LegacyContributionField';

import { Message, Thumbnail } from '@lumapps/lumx/react';

import * as COMPONENTS from './components';
import * as types from './components/types';
import { setRouter } from './routes';
import { setGoogleAnalytics } from './services/analytics';
import { setChannel } from './services/channel';
import { setCustomer } from './services/customer';
import { setDocument } from './services/document';
import { setEmail } from './services/email';
import { setFroalaLoader } from './services/froalaLoader';
import { setGTranslate } from './services/gTranslate';
import { setInstance } from './services/instance';
import { setLayout } from './services/layout';
import { setLocalStorage } from './services/localStorage';
import { setMedia } from './services/media';
import { setMediaPicker } from './services/media-picker';
import { setModuleAdmin } from './services/module-admin';
import { setNotification } from './services/notification';
import { setRss } from './services/rss';
import { setSocialAdvocacy } from './services/socialAdvocacy';
import { setStyles } from './services/styles';
import { setUpload } from './services/upload';
import { setUser } from './services/user';
import { setUserSettings } from './services/userSettings';
import { setWidget } from './services/widget';
import { setTranslations } from './translations';
import { setPostService } from '@lumapps/posts/services';
import { setUtils } from './utils';
import { createQueryClient, QueryClientProvider, ReactQueryDevtools } from '@lumapps/base-api/react-query';

const { isDev } = get();

const { angular } = global;
const hasHotModuleReload = module && module.hot;
const LUMX_COMPONENTS = { Message, Thumbnail };
const LUMAPPS_COMPONENTS = {
    PostContent,
    PostBlockContent,
    BlockComments,
    LegacyContributionField,
};

/**
 * Create a Query client.
 * 
 * ℹ️ It will be shared between all <react-element> instances.
 */
const queryClient = createQueryClient();
const QUERY_DEVTOOLS_CLASSNAME = 'lumapps-react-query-devtools';

/**
 * Pool of watchable components that can be updated for hot-reloading.
 */
class Components {
    constructor() {
        this.list = { ...COMPONENTS, ...LUMX_COMPONENTS, ...LUMAPPS_COMPONENTS };
        this.elements = {};
    }

    updateComponents(nextList) {
        const { list } = this;
        this.list = nextList;
        for (const key in nextList) {
            if (key in list && nextList[key] !== list[key]) {
                this.updateElements(key);
            }
        }
    }

    subscribe(key, element) {
        if (!(key in this.list)) {
            return;
        }
        (this.elements[key] || (this.elements[key] = [])).push(element);
    }

    unsubscribe(key, element) {
        const elements = this.elements[key];
        if (!elements) {
            return;
        }
        pull(elements[key], element);
    }

    updateElements(key) {
        const elements = this.elements[key];
        if (!elements) {
            return;
        }
        const { length: size } = elements;
        for (let i = 0; i < size; i++) {
            const element = elements[i];
            if (element.state.reactError) {
                element.setState({ reactError: null });
            } else {
                element.forceUpdate();
            }
        }
    }
}

const components = new Components();

/**
 * Evaluates all given `props` from the given `scope`.
 *
 * @param  {Object} props The properties.
 * @param  {Object} scope The scope.
 * @return {Object} All props `props` evaluated.
 */
function evalProps(props, scope) {
    return mapValues(props, (value) => scope.$eval(value));
}

/**
 * Wraps a provided `callable` in `scope.$evalAsync` if not already wrapped.
 *
 * @param  {Function} callable The callable.
 * @param  {Object}   scope    The scope.
 * @return {Function} The wrapped callable.
 */
function applyCallable(callable, scope) {
    if (!callable || callable.$applied) {
        return callable;
    }
    const wrapper = (...args) => scope.$evalAsync(() => callable(...args));
    wrapper.$applied = true;

    return wrapper;
}

/**
 * Wraps callables found in `props[keys[i]]` in `scope.$evalAsync`, *in-place*.
 *
 * @param  {Array}  keys  The keys.
 * @param  {Object} props The properties.
 * @param  {Object} scope The scope.
 * @return {Object} The props.
 */
function applyCallables(keys, props, scope) {
    const { length: size } = keys;
    for (let i = 0; i < size; i++) {
        const key = keys[i];
        props[key] = applyCallable(props[key], scope);
    }

    return props;
}

/**
 * Strictly watches provided `props` `keys` and updates the `element` state when they change.
 *
 * @param {Object} element The React element.
 * @param {Array}  keys    List of prop names to watch.
 * @param {Array}  apply   List of prop names to wrap into `apply`.
 * @param {Object} props   The properties.
 * @param {Object} scope   The scope.
 */
function watchStrict(element, keys, apply, props, scope) {
    const { length: size } = keys;
    const propsToApply = fromPairs(map(apply, (key) => [key, true]));
    for (let i = 0; i < size; i++) {
        const key = keys[i];
        scope.$watch(
            props[key],
            key in propsToApply
                ? (value) => element.setState({ [key]: applyCallable(value, scope) })
                : (value) => element.setState({ [key]: value }),
            false,
        );
    }
}

/**
 * Deeply watches provided `props` `keys` and updates the `element` state when they change.
 *
 * @param {Object} element The React element.
 * @param {Array}  keys    List of prop names to watch.
 * @param {Object} props   The properties.
 * @param {Object} scope   The scope.
 */
function watchDeep(element, keys, props, scope) {
    const { length: size } = keys;
    for (let i = 0; i < size; i++) {
        const key = keys[i];
        scope.$watch(props[keys[i]], (value) => element.setState({ [key]: angular.fastCopy(value) }), true);
    }
}

/**
 * Shallowly watches provided `props` `keys` and updates the `element` state when they change.
 *
 * @param {Object} element The React element.
 * @param {Array}  keys    List of prop names to watch.
 * @param {Object} props   The properties.
 * @param {Object} scope   The scope.
 */
function watchShallow(element, keys, props, scope) {
    if (!angular.isFunction(scope.$watchCollection)) {
        watchDeep(element, keys, props, scope);

        return;
    }
    const { length: size } = keys;
    for (let i = 0; i < size; i++) {
        const key = keys[i];
        scope.$watchCollection(props[key], (value) =>
            element.setState({
                [key]: angular.isArray(value) ? [...value] : { ...value },
            }),
        );
    }
}

/**
 * Group watches provided `props` `keys` and updates the `element` state when they change.
 *
 * @param {Object} element The React element.
 * @param {Array}  keys    List of prop names to watch.
 * @param {Object} props   The properties.
 * @param {Object} scope   The scope.
 */
function watchGroup(element, keys, props, scope) {
    if (!angular.isFunction(scope.$watchGroup)) {
        watchStrict(element, keys, null, props, scope);

        return;
    }
    scope.$watchGroup(keys, (values) => element.setState(fromPairs(map(values, (value, i) => [keys[i], value]))));
}

/**
 * Determines if the `value` is defined or not according to the "Value stabilization algorithm".
 * See https://docs.angularjs.org/guide/expression#value-stabilization-algorithm.
 *
 * @param  {any}     value The value to check.
 * @return {boolean} `true` if defined, `false` otherwise.
 */
function isDefined(value) {
    if (!angular.isDefined(value)) {
        return false;
    }
    if (!angular.isObject(value)) {
        return true;
    }
    let allDefined = true;
    angular.forEach(value, function forEachValue(item) {
        if (!isDefined(item)) {
            allDefined = false;
        }
    });

    return allDefined;
}

/**
 * Watches provided `props` `keys` once and updates the `element` state when they change.
 *
 * @param {Object} element The React element.
 * @param {Array}  keys    List of prop names to watch.
 * @param {Object} props   The properties.
 * @param {Object} scope   The scope.
 */
function watchOnce(element, keys, props, scope) {
    const { length: size } = keys;
    for (let i = 0; i < size; i++) {
        const key = keys[i];
        const unwatch = scope.$watch(
            props[key],
            (value) => {
                if (!isDefined(value)) {
                    return;
                }
                scope.$evalAsync(unwatch);
                element.setState({ [key]: value });
            },
            false,
        );
    }
}

/**
 * Applies the `setState` on specified `props` according to `setup`.
 *
 * @param {Object} element The React element.
 * @param {Object} setup   The setup.
 * @param {Object} apply   List of prop names to wrap into `apply`.
 * @param {Object} props   The properties.
 * @param {Object} scope   The scope.
 */
function watchProps(element, setup, apply, props, scope) {
    if (!setup) {
        return;
    }
    const { strict, deep, shallow, group, once } = setup;
    if (strict) {
        watchStrict(element, strict, apply, props, scope);
    }
    if (deep) {
        watchDeep(element, deep, props, scope);
    }
    if (shallow) {
        watchShallow(element, shallow, props, scope);
    }
    if (group) {
        watchGroup(element, group, props, scope);
    }
    if (once) {
        watchOnce(element, once, props, scope);
    }
}

/**
 * Renders a React element.
 */
class ReactElement extends Component {
    static propTypes = {
        /** List of prop names to wrap into `apply`. */
        apply: types.attributes,
        /** Component to render. */
        component: propTypes.string,
        /** Element props. */
        props: propTypes.object.isRequired,
        /** Angular scope object. */
        scope: propTypes.object.isRequired,
        /** Props watch setup. */
        watch: propTypes.shape({
            deep: types.attributes,
            group: types.attributes,
            once: types.attributes,
            shallow: types.attributes,
            strict: types.attributes,
        }),
    };

    constructor(initialProps) {
        super(initialProps);
        const { apply, watch, scope, props } = initialProps;
        this.state = apply ? applyCallables(apply, evalProps(props, scope), scope) : evalProps(props, scope);

        if (watch) {
            watchProps(this, watch, apply, props, scope);
        }
    }

    componentDidMount() {
        if (!hasHotModuleReload) {
            return;
        }

        /** ℹ️ We want to make sure the devtools are only added to the DOM once. */
        if (document.querySelector(`.${QUERY_DEVTOOLS_CLASSNAME}`)) {
            this.setState({ isQueryClientMounted: true })
        }

        components.subscribe(this.props.component, this);
    }

    componentDidCatch(error, info) {
        this.setState({ reactError: { error, info } });
        console.error(error);
        console.error(info.componentStack);
    }

    componentWillUnmount() {
        if (!hasHotModuleReload) {
            return;
        }
        components.unsubscribe(this.props.component, this);
    }

    render() {
        const { component, reduxStore } = this.props;
        const { reactError: error, isQueryClientMounted } = this.state;

        const canDisplayReactQueryDevtools = isDev && !isQueryClientMounted;
        
        return error
            ? createElement(
                  'details',
                  { style: { whiteSpace: 'pre-wrap' } },
                  createElement('summary', null, error.error.toString()),
                  createElement('p', null, error.info.componentStack),
              )
            : // CreateElement(StrictMode, null,  // String mode is causing issue with Material-ui.
            createElement(
                QueryClientProvider,
                { client: queryClient },
                createElement(
                    Provider,
                    { store: reduxStore },
                    createElement(
                        MemoryRouter,
                        {},
                        createElement(
                            Customizations,
                            {},
                            createElement(components.list[component] || component, this.state),
                        ),
                    ),
                ),
                /** 
                 * ℹ️ Devtools are excluded in production builds 
                 * @see https://tanstack.com/query/latest/docs/react/devtools#devtools-in-production
                 */
                canDisplayReactQueryDevtools ? createPortal(createElement(ReactQueryDevtools, { panelProps: { className: QUERY_DEVTOOLS_CLASSNAME } }), document.body)  : null,
            );
    }
}

if (hasHotModuleReload) {
    module.hot.accept('./components', (path) => {
        try {
            components.updateComponents(require('./components'));
        } catch (exception) {
            /* eslint-disable no-console */
            console.error(`Error while reloading "${path}"`);
            console.error(exception);
            /* eslint-enable no-console */
        }
    });
}

angular.module('Directives').directive('reactElement', [
    'Analytics',
    'Channel',
    'Customer',
    'Document',
    'Email',
    'FroalaLoader',
    'GTranslate',
    'Instance',
    'Layout',
    'LocalStorage',
    'LxNotificationService',
    'Media',
    'ModuleAdmin',
    'Post',
    'ReduxStore',
    'ReusableMediaPicker',
    'Rss',
    'SocialAdvocacy',
    'Style',
    'Translation',
    'Upload',
    'User',
    'UserSettings',
    'Utils',
    'Widget',
    '$state',
    /* eslint-disable-next-line lumapps/max-params */
    function reactElement(
        Analytics,
        Channel,
        Customer,
        Document,
        Email,
        FroalaLoader,
        GTranslate,
        Instance,
        Layout,
        LocalStorage,
        LxNotificationService,
        Media,
        ModuleAdmin,
        Post,
        ReduxStore,
        ReusableMediaPicker,
        Rss,
        SocialAdvocacy,
        Style,
        Translation,
        Upload,
        User,
        UserSettings,
        Utils,
        Widget,
        $state,
    ) {
        setChannel(Channel);
        setCustomer(Customer);
        setDocument(Document);
        setEmail(Email);
        setFroalaLoader(FroalaLoader);
        setGTranslate(GTranslate);
        setGoogleAnalytics(Analytics);
        setInstance(Instance);
        setLayout(Layout);
        setLocalStorage(LocalStorage);
        setMedia(Media);
        setMediaPicker(ReusableMediaPicker);
        setModuleAdmin(ModuleAdmin);
        setNotification(LxNotificationService);
        setRouter($state);
        setRss(Rss);
        setSocialAdvocacy(SocialAdvocacy);
        setStyles(Style);
        setTranslations(Translation);
        setPostService(Post);
        injectTranslationService(Translation);
        injectUserService(User);
        setUpload(Upload);
        setUser(User);
        setUserSettings(UserSettings);
        setUtils(Utils);
        setWidget(Widget);

        return {
            link: function link(scope, node, attributeValues) {
                /**
                 * Exclude attributes attached to the 'react-element-directive' that we do not want to pass on to the ReactElement as props.
                 * @example ['$$element', $attr'] to remove angular specific attributes.
                 * @example ['ngIf', 'ngElse'] to remove angular custom directive that might be used to show | hide | customize the directive.
                 * @example ['class'] to remove custom html attribute that are not supported by React.
                 * */
                const excludedAttributeKeys = ['$$element', '$attr', 'class', 'ngIf', 'ngElse', 'ngSwitchWhen', 'ngSwitchDefault', 'ngHide', 'ngStyle', 'ngRepeat', 'ngClass'];

                const {
                    reactComponent: component,
                    reactWatch: watch,
                    reactApply: apply,
                    reactReplace: replace = 'false',
                    ...props
                } = attributeValues;
                const reactElementRoot = createElement(ReactElement, {
                    apply: apply ? scope.$eval(apply) : null,
                    component,
                    props: omit(props, excludedAttributeKeys),
                    reduxStore: ReduxStore.store,
                    scope,
                    watch: watch ? scope.$eval(watch) : null,
                });
                let rootNode = node[0];
                if (scope.$eval(replace)) {
                    const { parentNode } = rootNode;
                    parentNode.removeChild(rootNode);
                    rootNode = parentNode;
                }
                render(reactElementRoot, rootNode);
                scope.$on('$destroy', () => unmountComponentAtNode(rootNode));
            },
            restrict: 'E',
        };
    },
]);
