export enum PLAY_GALLERY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240836393
     */
    ALL_VIDEOS = 'PLAY.ALL_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=123595699
     */
    MANAGE_VIDEOS = 'PLAY.MANAGE_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119722998
     */
    MOST_VIEWED = 'PLAY.MOST_VIEWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124899734
     */
    NO_AVAILABLE_VIDEOS = 'PLAY.NO_AVAILABLE_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231772741
     * */
    NO_PODCAST = 'PLAY.NO_PODCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124899868
     */
    NO_VIDEOS_FOUND = 'PLAY.NO_VIDEOS_FOUND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134862475
     * */
    SEARCH_VIDEOS = 'PLAY.SEARCH_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13586913
     */
    STARRED = 'FRONT.WIDGET_FILE_LIST.SETTINGS.FOLDER.DRIVE.STARRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111207498
     */
    UPLOAD_DIALOG_CTA = 'PLAY.UPLOAD_DIALOG_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326094667
     */
    UPLOAD_FROM_COMPUTER = 'PLAY.UPLOAD_FROM_COMPUTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326094683
     */
    UPLOAD_FROM_DRIVE = 'PLAY.UPLOAD_FROM_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=120686514
     */
    VIDEO_GALLERY_TITLE = 'PLAY.VIDEO_GALLERY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=237038935
     */
    VIDEO_PODCASTS = 'PLAY.PODCASTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=273002248
     */
    WATCH_VIDEO = 'PLAY.WATCH_VIDEO',
}
