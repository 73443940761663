import { BaseApiError } from '@lumapps/base-api';
import { get } from '@lumapps/constants';
import { updateInstance } from '@lumapps/instance/utils/updateInstance';
import { Dispatch } from '@lumapps/redux/types';
import { actions as routerActions } from '@lumapps/router/ducks/slice';

import { get as getCommunity } from '../../api/index';
import { CommunityLinkRef, GetCommunityParams } from '../../types';

type Params = {
    to: CommunityLinkRef;
    languageHeader: string;
    currentInstanceId?: string;
    dispatch: Dispatch;
    translateObject: any;
};
type Required = Params & {
    to: CommunityLinkRef & { id: string };
};

const { instanceId: currentInstanceId } = get();

export async function fetchUpdateCommunityLinkRef(params: Params): Promise<CommunityLinkRef> {
    const { to, translateObject } = params;

    if (!to.id && !to.slug) {
        return to;
    }

    // If missing essential metadata, we are feching the community settings to get instance details and rendering type
    if ((to.id || to.slug) && !to.renderingType) {
        try {
            const params: GetCommunityParams = {
                instance: to.instance?.id,
                fields: 'id,uid,slug,renderingType,instanceDetails(id,slug)',
                // Get by id
                uid: to.id as string,
            };
            if (to.slug && !params.uid) {
                // Get by slug
                params.slug = to.slug;
                // Fallback to the current instance because the instance id is required
                if (!params.instance) {
                    params.instance = currentInstanceId;
                }
            }
            // Get community
            const { data: community } = await getCommunity(params, true);

            return {
                ...to,
                id: to.id || community.id || community.uid,
                slug: to.slug || translateObject(community.slug),
                renderingType: community.renderingType,
                instance: community.instanceDetails,
            };
        } catch (error) {
            const errorStatus = (error as BaseApiError)?.response?.status;

            return { ...to, errorStatus };
        }
    }

    const requiredParams = params as Required;

    // If we already know the rendering type, we are going for a lighter API call to get instance details
    try {
        const response = await updateInstance(requiredParams);

        return { ...to, ...response };
    } catch (error) {
        const errorStatus = (error as BaseApiError)?.response?.status;

        return { ...to, errorStatus };
    }
}

export function prepareFetchUpdateCommunityLinkRef(params: Params) {
    const { dispatch } = params;

    let promise: Promise<CommunityLinkRef> | undefined;

    const fetch = async () => {
        if (!promise) {
            // Show global router loader.
            dispatch(routerActions.showLoader());

            promise = fetchUpdateCommunityLinkRef(params)
                // Hide global router loader.
                .finally(() => dispatch(routerActions.hideLoader()));
        }

        return promise;
    };

    return { fetch };
}
