import { RESOURCE_FILTER_TO_TRANSLATION_KEY } from '../constants';
import { ManagedStreamResources } from '../types';

/** Get the correct translation for the given stream resource */
export const getResourceTranslation = (resource: ManagedStreamResources) => {
    // First check if we have a translation key for the given resource
    const translationKey = RESOURCE_FILTER_TO_TRANSLATION_KEY[resource];

    if (!translationKey) {
        return resource;
    }

    return translationKey;
};
