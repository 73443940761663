const desktopMinSize = 1024;

const mobileQuery = '(max-width: 1023px)';
const wideQuery = '(min-width: 1280px)';
const desktopQuery = `(max-width: ${desktopMinSize}px)`;
const smallQuery = '(max-width: 480px)';
const tabletQuery = '(max-width: 740px)';

enum DIMENSION {
    MOBILE = 'mobile',
    SMALL = 'small',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
    WIDE = 'wide',
}

export { mobileQuery, desktopQuery, wideQuery, smallQuery, tabletQuery, DIMENSION, desktopMinSize };
