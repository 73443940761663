import compact from 'lodash/compact';
import filter from 'lodash/filter';
import first from 'lodash/first';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import groupBy from 'lodash/groupBy';
import loFind from 'lodash/find';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';

/////////////////////////////

function WidgetContentFilterController(
    $httpParamSerializer,
    $location,
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $window,
    Community,
    Config,
    Content,
    ContentTemplate,
    CustomContentType,
    Directory,
    Features,
    InitialSettings,
    Instance,
    Interest,
    LxDropdownService,
    Metadata,
    PostConstant,
    SocialSubscription,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The format for the cycle date.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _CYCLE_DATE_FORMAT = 'MMMM D, YYYY';

    /**
     * Date format for URL query strings.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DATE_FORMAT_URL = 'YYYY-MM-DD';

    /**
     * Filter names.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _FILTER_NAMES = [
        'author',
        'communityTags',
        'customContentType',
        'startDate',
        'endDate',
        'startDateStr',
        'endDateStr',
        'tags',
        'onlyFeatureFeeds',
        'metadata',
        'postStatus',
        'postType',
        'hasRelevant',
        'isMerged',
        'reportStatus',
    ];

    /**
     * The id of the widget content filter dialog.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    let _PICKER_ID = 'widget-content-filter-';

    /**
     * Contains the list of all the directories selected.
     *
     * @type {Array}
     */
    let _directories = [];

    /**
     * Contains the list of elements filtered in the linked widget (metadata, tags..).
     * This var will contains the filterRestriction var of the widget.
     *
     * @type {Object}
     */
    let _linkedWidgetRestrictions;

    /**
     * Array of basic content types.
     *
     * @type {Array}
     */
    const _basicContentTypes = [
        InitialSettings.CONTENT_TYPES.CUSTOM_LIST,
        InitialSettings.CONTENT_TYPES.CUSTOM,
        InitialSettings.CONTENT_TYPES.PAGE,
        InitialSettings.CONTENT_TYPES.NEWS,
    ];

    /**
     * The type of the current content.
     *
     * @type {string}
     */
    let _contentType;

    /**
     * Contains the instances ids, used to fetch the content types available for these instances.
     *
     * @type {Array}
     */
    let _instanceIds = [];

    /**
     * Indicates if the filter is for a post list widget.
     *
     * @type {boolean}
     */
    let _isPostListFilter = false;

    /**
     * The linked widget if this widget is linked. Could be a directory entry widget or a content list.
     *
     * @type {Object}
     */
    let _linkedWidget;

    /**
     * The list of custom content type selected to filter the tags.
     *
     * @type {Array}
     */
    let _selectedCustomContentType;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the user can subscribe to its search.
     * A search is subscribable only if the linked widget is a content list widget.
     */
    vm.canSubscribe = false;

    /**
     * Current community, used to filter the posts by community tags.
     *
     * @type {Object}
     */
    vm.currentCommunity = {};

    /**
     * The list of allowed post types for the current community.
     *
     * @type {Array}
     */
    vm.currentCommunityPostTypes = [];

    /**
     * Contains the allowed community tags in filters.
     *
     * @type {Array}
     */
    vm.currentCommunityTags = [];

    /**
     * Current directory, used to filter the directory entries by tags.
     *
     * @type {Object}
     */
    vm.currentDirectory = {};

    /**
     * Contains the allowed directory tags in filters.
     *
     * @type {Array}
     */
    vm.currentDirectoryTags = [];

    /**
     * Contains the list of custom content types, used to display the content type list in the select field.
     *
     * @type {Object}
     */
    vm.customContentTypes = {};

    /**
     * Contains the allowed tags in filters.
     *
     * @type {Object}
     */
    vm.customContentTypeTags = {};

    /**
     * Contains the list of tags to display in the interest block.
     *
     * @type {Array}
     */
    vm.customContentTypeTagsForInterest = [];

    /**
     * Indicates if the content filter could be linked to a local widget.
     *
     * @type {boolean}
     */
    vm.couldBeLinkedToALocalWidget = false;

    /**
     * The advanced filters dropdown id.
     *
     * @type {string}
     */
    vm.dropdownId = `widget-content-filter-dropdown-${vm.widget.uuid}`;

    /**
     * The advanced filters dropdown target (the search form).
     *
     * @type {string}
     */
    vm.dropdownTarget = `widget-content-filter-dropdown-target-${vm.widget.uuid}`;

    /**
     * Contains the filtered metadata according to restrictions.
     * The restrictions are the selected children metadata in the linked widget (content list or directory entries).
     *
     * @type {Object}
     */
    vm.filteredMetadata = {};

    /**
     * Indicates if we are searching for a matching subscription.
     *
     * @type {boolean}
     */
    vm.findingSubscription = false;

    /**
     * Indicates if there is custom content type tags to display in the filter.
     *
     * @type {boolean}
     */
    vm.hasCustomContentTypeTags = false;

    /**
     * Indicates if there is directory tags to display in the filter.
     *
     * @type {boolean}
     */
    vm.hasCurrentDirectoryTags = false;

    /**
     * Contains the list of instances, used to enable or not the instance filter.
     *
     * @type {Array}
     */
    vm.instances = [];

    /**
     * Indicates the status of the widget.
     *
     * @type {Object}
     */
    vm.is = {
        initializing: {
            ccts: true,
            instances: true,
            metadata: true,
            tags: true,
        },
    };

    /**
     * Number of custom content types to display in the filter, used to display the filter.
     *
     * @type {number}
     */
    vm.nbCustomContentTypes = 0;

    /**
     * The latest subscription check parameters.
     * This is used to get the list of subscription corresponding to a cct, tags and metadata or to subscribe to
     * it if there is no existing subscription.
     *
     * @type {Object}
     */
    vm.subscriptionParams = {};

    /**
     * The custom content type defined in the subscription params.
     *
     * @type {Object}
     */
    vm.subscriptionContentTypes = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.CustomContentType = CustomContentType;
    vm.Directory = Directory;
    vm.Features = Features;
    vm.Instance = Instance;
    vm.Interest = Interest;
    vm.PostConstant = PostConstant;
    vm.Metadata = Metadata;
    vm.SocialSubscription = SocialSubscription;
    vm.Translation = Translation;
    vm.User = User;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Define the custom content type define in the subscription params via its id.
     */
    function _defineSubscriptionContentType() {
        CustomContentType.getItems(vm.subscriptionParams.customContentTypes).then((ccts) => {
            vm.subscriptionContentTypes = ccts;
        });
    }

    /**
     * Only keep the elements who are not restricted from the list of items.
     * The _linkedWidgetRestrictions variable represents the selected tags and metadata from the linked widget.
     * We will compare the selected elements with the list of available items to remove only the restricted one.
     *
     * @param  {Array}  elementList The element list to filter. Could be for example a list of metadata or tags.
     * @param  {string} type        The type of elements (tags, metadata...).
     * @param  {string} [key="id"]  The key on which to filter.
     * @return {Array}  The filtered elements.
     */
    function _filterRestricted(elementList, type, key = 'id') {
        if (angular.isUndefinedOrEmpty(get(_linkedWidgetRestrictions, type))) {
            return elementList;
        }

        // Directory specific case for metadata. In this case, we'll get each value of the object in an array.
        if (angular.isObject(_linkedWidgetRestrictions[type])) {
            _linkedWidgetRestrictions[type] = values(_linkedWidgetRestrictions[type]);
        }

        let filterRestrictionArray = [];
        if (angular.isArray(_linkedWidgetRestrictions[type][0])) {
            /*
                If we have an array of array, concat each array in a unique array.
                The goal is to be able to filter the string values later.
                */
            filterRestrictionArray = union(..._linkedWidgetRestrictions[type]);
        } else {
            // There is no array of array, just keep this array of string.
            filterRestrictionArray = _linkedWidgetRestrictions[type];
        }

        // If the type is tags, we need to rebuild the object filtered without the selected tag.
        if(type === 'tags'){
            let tagsObj = {}
            const dictKey = Object.keys(elementList)
            const tagsArray = filter(Object.values(elementList)[0], (item) => !includes(filterRestrictionArray, item[key]))
            tagsObj[dictKey] = tagsArray
            return tagsObj
        }

        // Filter the array of string to get only the non-restricted items.
        return filter(elementList, (item) => !includes(filterRestrictionArray, item[key]));
    }

    /**
     * Check what the widget displays : search filters and/or follow interest button.
     * Also called when widget settings are updated.
     */
    function _checkOptions() {
        const properties = get(vm.widget, 'properties', {});

        // Whether the widget displays search filters or not, used to hide the search buttons.
        vm.hasFilters = some(get(properties, 'filters', []), ['enable', true]);

        // Whether the widget displays a follow button or not.
        vm.hasFollowButton =
            vm.isContentListFilter() && Features.hasFeature('social') && !properties.disableFollowButton;
    }

    /**
     * Check if the content filter widget will filter on communities or not.
     *
     * @return {boolean} If we will filter on communities or not.
     */
    function _isCommunityListFilter() {
        return (
            angular.isDefinedAndFilled(_linkedWidget) &&
            _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.COMMUNITY_LIST
        );
    }

    /**
     * Check if the content filter widget will filter on directory entries or not.
     *
     * @return {boolean} If we will filter on directory entries or not.
     */
    function _isDirectoryEntriesFilter() {
        return (
            _contentType === InitialSettings.CONTENT_TYPES.DIRECTORY ||
            (angular.isDefinedAndFilled(_linkedWidget) &&
                _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY)
        );
    }

    /**
     * Check if the content filter widget will filter on instance list.
     *
     * @return {boolean} If we will filter on instances or not.
     */
    function _isInstanceFilter() {
        return (
            angular.isDefinedAndFilled(_linkedWidget) &&
            _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.INSTANCE_LIST
        );
    }

    /**
     * Check if the user has already subscribed to the search.
     */
    function _isSubscribed() {
        if (!Features.hasFeature('social')) {
            return;
        }

        vm.isSubscribed = false;

        if (!vm.canSubscribe) {
            return;
        }

        const linkedWidgetProperties = get(_linkedWidget, 'properties');
        if (angular.isUndefinedOrEmpty(linkedWidgetProperties)) {
            return;
        }
        const localWidgetProperties = get(vm.widget, 'properties', {});

        let customContentTypes = angular.isUndefinedOrEmpty(get(localWidgetProperties, 'customContentType'))
            ? get(linkedWidgetProperties, 'customContentType')
            : localWidgetProperties.customContentType;

        let cctIds = [];
        angular.forEach(customContentTypes, (customContentType, index) => {
            if (angular.isObject(customContentType) && angular.isDefinedAndFilled(customContentType.id)) {
                cctIds[index] = customContentType.id;
            } else {
                cctIds[index] = customContentType;
            }
        });

        let { tags } = localWidgetProperties;

        if (angular.isUndefinedOrEmpty(tags)) {
            tags = [];

            const customContentTypeTagsById = zipObject(
                map(vm.customContentTypeTags, 'uuid'),
                vm.customContentTypeTags,
            );

            // Keep only the tags for the currently selected custom content type.
            angular.forEach(linkedWidgetProperties.customContentTypeTags, (tagUuid) => {
                if (angular.isDefinedAndFilled(customContentTypeTagsById[tagUuid])) {
                    tags.push(tagUuid);
                }
            });
        }

        const metadata = angular.fastCopy(get(linkedWidgetProperties, 'metadata', {}));

        if (angular.isDefinedAndFilled(localWidgetProperties.metadata)) {
            angular.forEach(localWidgetProperties.metadata, (localMetadata, id) => {
                metadata[id] = angular.fastCopy(localMetadata);
            });
        }
        const orMetadata = angular.isDefinedAndFilled(values(metadata)) ? compact(flattenDeep(values(metadata))) : [];

        vm.subscriptionParams = {
            customContentTypes: cctIds,
            customContentTypeTags: angular.fastCopy(tags),
            orMetadata,
        };

        _defineSubscriptionContentType();

        vm.findingSubscription = true;
    }

    /**
     * Process the custom content types in _getCustomContentTypes.
     *
     * @param  {Array}   customContentTypesIds The list of custom content types ids to process.
     * @return {Promise} A promise that resolves when all custom content types have been processed.
     */
    async function _processCustomContentTypes(customContentTypesIds) {
        const heritableKey = Translation.translate('CUSTOM_CONTENT_TYPE_SELECT_HERITABLE');
        const hasInstanceProperties = angular.isDefinedAndFilled(get(vm.widget, 'properties.instance', []));

        let nbCustomContentTypes = 0;

        // Get each custom content type object.
        const customContentTypes = await CustomContentType.getItems(compact(customContentTypesIds));

        // Get instance for each custom content types.
        const customContentTypesInstances = customContentTypes.map(({ instance }) =>
            Instance.instanceKeyToInstanceFromSiblings(instance, true),
        );

        const customContentTypesByInstance = {};
        for (const index of customContentTypes.keys()) {
            const customContentType = customContentTypes[index];
            const instance = customContentTypesInstances[index];
            const key = get(instance, 'name');

            const heritableAlreadyHere = angular.isDefined(customContentTypesByInstance[heritableKey])
                ? map(customContentTypesByInstance[heritableKey], 'id')
                : [];

            if (customContentType.heritable) {
                if (angular.isUndefined(customContentTypesByInstance[heritableKey])) {
                    customContentTypesByInstance[heritableKey] = [];
                }

                if (!includes(heritableAlreadyHere, customContentType.id)) {
                    customContentTypesByInstance[heritableKey].push(customContentType);
                    heritableAlreadyHere.push(customContentType.id);

                    nbCustomContentTypes++;
                }
            } else if (!hasInstanceProperties || includes(vm.widget.properties.instance, customContentType.instance)) {
                if (angular.isUndefined(key)) {
                    return;
                }

                if (angular.isUndefined(customContentTypesByInstance[key])) {
                    customContentTypesByInstance[key] = [];
                }

                if (!includes(customContentTypesByInstance[key], customContentType)) {
                    customContentTypesByInstance[key].push(customContentType);
                    nbCustomContentTypes++;
                }
            }
        }

        for (const [key, customContentTypesForKey] of Object.entries(customContentTypesByInstance)) {
            if (angular.isUndefinedOrEmpty(customContentTypesForKey)) {
                delete vm.customContentTypes[key];
            } else {
                vm.customContentTypes[key] = sortBy(customContentTypesForKey, (cct) => Translation.translate(cct.name));
            }
        }

        vm.nbCustomContentTypes = nbCustomContentTypes;
    }

    /**
     * Bind the right custom content type for this widget.
     *
     * @return {Promise} The promise that resolve with the custom content type list sorted by instance name.
     */
    function _getCustomContentTypes() {
        if (_isPostListFilter) {
            return $q.resolve();
        }

        vm.is.initializing.ccts = true;

        let customContentTypesIds = [];
        const currentContent = Content.getCurrent();
        const { properties } = vm.widget;
        let promises = [];

        if (angular.isDefinedAndFilled(get(properties, 'customList.customContentType'))) {
            customContentTypesIds.push(properties.customList.customContentType);
        } else if (vm.widget.properties.linkedToLocalWidget && !_isDirectoryEntriesFilter()) {
            // If there is only one custom content type, use it.
            if (angular.isDefinedAndFilled(get(_linkedWidget, 'properties.customContentType'))) {
                customContentTypesIds = _linkedWidget.properties.customContentType;
            } else if (angular.isDefinedAndFilled(_instanceIds)) {
                angular.forEach(_instanceIds, (instanceId) => {
                    promises.push(
                        $q((resolve) => {
                            CustomContentType.listForInstance(instanceId)
                                .then((ccts) => {
                                    if (angular.isUndefinedOrEmpty(ccts)) {
                                        resolve();

                                        return;
                                    }

                                    customContentTypesIds = customContentTypesIds.concat(map(ccts, 'id'));
                                    resolve();
                                })
                                .catch(resolve);
                        }),
                    );
                });
            } else {
                customContentTypesIds.push(currentContent.customContentType);
            }
        }

        if (angular.isUndefinedOrEmpty(promises)) {
            promises = [$q.resolve(customContentTypesIds)];
        }

        return $q((resolve, reject) => {
            $q.all(promises)
                .then(() => {
                    _processCustomContentTypes(customContentTypesIds)
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        }).finally(() => {
            vm.is.initializing.ccts = false;
        });
    }

    /**
     * Return a list of directories tags.
     *
     * @param  {Array}  directories Directories to get tags.
     * @return {Object} list of tags categorized by translated directory name.
     */
    function _getDirectoriesTags(directories) {
        return reduce(
            directories,
            (list, directory) => {
                if (angular.isDefinedAndFilled(directory.tags)) {
                    list[Translation.translate(directory.name)] = directory.tags;
                }

                return list;
            },
            {},
        );
    }

    /**
     * Get every metadata displayable in filters.
     * We will check if the metadata is visible, has items and belongs to the current custom content type.
     */
    function _getDisplayableMetadata() {
        vm.is.initializing.metadata = true;
        vm.availableMetadata = [];
        vm.filteredMetadata = {};

        const hasSomeDirectoriesMeta = some(_directories, (directory) => directory.entriesHasMetadata);

        if (!_isDirectoryEntriesFilter() || angular.isUndefinedOrEmpty(_directories) || hasSomeDirectoriesMeta) {
            Metadata.getRefactoredMetadata().then((refactoredMetadata) => {
                // Get metadata displayable in filters.
                const metadata = Metadata.getMetadataFilteredByProperty('displayInFilter', true, refactoredMetadata);
                // Check if there is metadata restriction in the linked widget.
                const isMetadataRestricted = angular.isDefinedAndFilled(get(_linkedWidgetRestrictions, 'metadata'));
                const selectedCustomContentTypesId = map(_selectedCustomContentType, 'uid');

                // Process all metadata choices.
                angular.forEach(metadata, (availableMetadata) => {
                    if (angular.isUndefinedOrEmpty(availableMetadata.id)) {
                        return;
                    }

                    const isGlobalMetadata = angular.isUndefinedOrEmpty(availableMetadata.customContentTypes);
                    const isPlatformMetadata = angular.isUndefinedOrEmpty(availableMetadata.instance);
                    const hasRightCustomContentType =
                        !_isDirectoryEntriesFilter() &&
                        (angular.isUndefinedOrEmpty(selectedCustomContentTypesId) ||
                            angular.isDefinedAndFilled(
                                intersection(availableMetadata.customContentTypes, selectedCustomContentTypesId),
                            ));

                    if (
                        get(_linkedWidget, 'widgetType') === InitialSettings.WIDGET_TYPES.INSTANCE_LIST &&
                        !isPlatformMetadata
                    ) {
                        return;
                    }

                    // If there is more than one CCT, we never display metadata in the filter unless it is global.
                    const displayMeta = isGlobalMetadata || hasRightCustomContentType;

                    if (!displayMeta) {
                        return;
                    }
                    // This metadata is authorized to be shown in filters, add it to the list.
                    vm.availableMetadata.push(availableMetadata);

                    // Get the children of this metadata and display only the filtered one.
                    const childrenMetadata = Metadata.getFilteredMetadata(availableMetadata.id, true);
                    if (isMetadataRestricted) {
                        /*
                         * _filterRestricted will get the non-restricted elements.
                         * So we just have to do a inter to get the restricted elements!
                         */
                        const allowedElements = _filterRestricted(childrenMetadata, 'metadata');

                        if (allowedElements.length === childrenMetadata.length) {
                            vm.filteredMetadata[availableMetadata.id] = childrenMetadata;
                        } else {
                            vm.filteredMetadata[availableMetadata.id] = childrenMetadata.filter(
                                (filteredMetadata) =>
                                    !allowedElements.some(
                                        (allowedMetadata) => filteredMetadata.id === allowedMetadata.id,
                                    ),
                            );
                        }
                    } else {
                        vm.filteredMetadata[availableMetadata.id] = childrenMetadata;
                    }
                });

                const linkedWidgetMeta = flattenDeep(values(get(_linkedWidget, 'properties.metadata')));

                if (angular.isUndefinedOrEmpty(linkedWidgetMeta)) {
                    vm.is.initializing.metadata = false;

                    return;
                }

                const widgetMeta = vm.widget.properties.metadata;

                angular.forEach(get(_linkedWidget, 'properties.metadata', []), (ids, parentId) => {
                    if (angular.isUndefinedOrEmpty(ids)) {
                        return;
                    }
                    widgetMeta[parentId] = (widgetMeta[parentId] || []).concat(ids);
                });

                vm.is.initializing.metadata = false;
            });
        }

        vm.is.initializing.metadata = false;
    }

    /**
     * Returns the filters for use in the URL query string.
     *
     * @return {Array} The filters formatted to the URL format.
     */
    function _getFilters() {
        const filters = [];

        if (angular.isDefinedAndFilled(vm.widget.properties.query)) {
            filters.push(`query_${vm.widget.properties.query}`);
        }

        if (angular.isDefinedAndFilled(get(vm.widget, 'properties.customContentType'))) {
            filters.push(
                `customContentType_${
                    angular.isArray(vm.widget.properties.customContentType)
                        ? vm.widget.properties.customContentType.join(',')
                        : vm.widget.properties.customContentType
                }`,
            );
        }

        // It's a boolean indeed. But I need to send the value (true or false) by url.
        if (angular.isDefined(vm.widget.properties.onlyFeatureFeeds)) {
            filters.push(`onlyFeatureFeeds_${vm.widget.properties.onlyFeatureFeeds}`);
        }

        angular.forEach(vm.widget.properties.tags, (tag) => {
            if (angular.isDefinedAndFilled(tag)) {
                filters.push(`tags_${tag}`);
            }
        });

        angular.forEach(vm.widget.properties.metadata, (metadata) => {
            if (angular.isDefinedAndFilled(metadata)) {
                filters.push(`metadata_${metadata}`);
            }
        });

        if (angular.isDefinedAndFilled(vm.widget.properties.startDate)) {
            filters.push(`startDate_${moment(vm.widget.properties.startDate).format(_DATE_FORMAT_URL)}`);
        }

        if (angular.isDefinedAndFilled(vm.widget.properties.endDate)) {
            filters.push(`endDate_${moment(vm.widget.properties.endDate).format(_DATE_FORMAT_URL)}`);
        }

        return filters;
    }

    /**
     * Get the instances from the main widget.
     *
     * @return {Promise} The promise of the initialization of the instances.
     */
    function _getInstances() {
        vm.instances = [];

        return $q((resolve, reject) => {
            vm.is.initializing.instances = true;

            Instance.getSiblings(false)
                .then((siblings) => {
                    _instanceIds = [];

                    if (siblings.length > 1) {
                        // Get the instances ids from the siblings or from the selected instances.
                        if (vm.widget.properties.linkedToLocalWidget && !_isDirectoryEntriesFilter()) {
                            if (get(_linkedWidget, 'properties.isAllInstancesSiblings', false)) {
                                _instanceIds = Instance.getSiblingsIds(true);
                                vm.instances = angular.fastCopy(siblings);
                            } else {
                                _instanceIds = get(_linkedWidget, 'properties.instance', []);
                                vm.instances = filter(siblings, (sibling) =>
                                    includes(get(_linkedWidget, 'properties.instance', []), sibling.uid),
                                );
                            }
                        }
                    } else {
                        _instanceIds = [Instance.getCurrentInstanceId()];
                        const currentInstance = angular.fastCopy(Instance.getCurrent());

                        if (angular.isDefinedAndFilled(currentInstance)) {
                            vm.instances = [currentInstance];
                        }
                    }

                    if (angular.isDefinedAndFilled(_instanceIds) && angular.isUndefinedOrEmpty(vm.instances)) {
                        const params = {
                            ids: _instanceIds,
                        };

                        Instance.filterize(params, (response) => {
                            vm.instances = response;

                            resolve(vm.instances);
                        });
                    } else {
                        resolve(vm.instances);
                    }
                })
                .catch(reject);
        }).finally(() => {
            vm.is.initializing.instances = false;
        });
    }

    /**
     * Returns the filters from the URL query string.
     *
     * @return {Array} The filters extracted from the URL query string.
     */
    function _getLocationFilters() {
        const { filters } = $location.search();

        if (!angular.isArray(filters)) {
            return angular.isDefinedAndFilled(filters) ? [filters] : undefined;
        }

        return filters;
    }

    /**
     * Manage tags for directory or custom content types.
     *
     * @param  {Array}   items Directory or CustomContentType object that we will get the tags from.
     * @return {Promise} A promise that will resolve with the list of tags if available.
     */
    function _manageTags(items) {
        if (angular.isUndefinedOrEmpty(items)) {
            return $q.resolve({});
        }

        vm.is.initializing.tags = true;

        const { properties } = vm.widget;
        let instanceList = [];

        return $q((resolve) => {
            if (angular.isDefinedAndFilled(properties.instance)) {
                instanceList = properties.instance;
            } else if (get(_linkedWidget, 'properties.isAllInstancesSiblings', false)) {
                instanceList = undefined;
            } else if (angular.isDefinedAndFilled(get(_linkedWidget, 'properties.instance', []))) {
                instanceList = _linkedWidget.properties.instance;
            } else {
                instanceList = [Instance.getCurrentInstanceId()];
            }

            // Check if there is metadata restriction in the linked widget.
            const isMetadataRestricted = angular.isDefinedAndFilled(get(_linkedWidgetRestrictions, 'tags'));

            if (_isDirectoryEntriesFilter()) {
                let tags = _getDirectoriesTags(items);

                if (isMetadataRestricted) {
                    tags = _filterRestricted(tags, 'tags', 'uuid');
                }

                resolve(tags);

                return;
            }

            CustomContentType.getTagsForHeaderSelect(
                map(items, 'uid'),
                (tagsWithHeader) => {
                    angular.forEach(Object.keys(tagsWithHeader), (key) => {
                        tagsWithHeader[key] = sortBy(tagsWithHeader[key], (tag) => Translation.translate(tag.name));
                    });

                    resolve(tagsWithHeader);
                },
                () => {
                    resolve({});
                },
                instanceList,
                isMetadataRestricted ? _linkedWidgetRestrictions.tags : undefined,
            );
        }).finally(() => {
            vm.is.initializing.tags = false;
        });
    }

    /**
     * Format date in the right format.
     *
     * @param  {Object} date                        The date to format.
     * @param  {string} [format=_CYCLE_DATE_FORMAT] The format to transform the date into.
     * @param  {string} [locale=current]            The locale/lang.
     * @return {string} The formatted date.
     */
    function _formatDate(date, format = _CYCLE_DATE_FORMAT, locale = Translation.getLang('current')) {
        if (angular.isUndefined(date)) {
            return '';
        }

        return moment(date)
            .locale(locale)
            .format(format);
    }

    /**
     * Pre-filter on the content list with the params sent in URL.
     *
     * @param {Array|string} filters The filters received from the URL.
     */
    async function _handleParamsFilters(filters) {
        const properties = Utils.getSimpleUrlParams(filters);

        vm.widget.properties.query = properties.query;
        vm.widget.properties.tags =
            angular.isUndefined(properties.tags) || angular.isArray(properties.tags)
                ? properties.tags
                : properties.tags.split(',');

        properties.customContentType = properties.customContentType || [];

        if (!angular.isArray(properties.customContentType)) {
            vm.widget.properties.customContentType = properties.customContentType.split(',');
        }

        if (angular.isDefined(properties.startDate)) {
            vm.widget.properties.startDate = moment(properties.startDate, _DATE_FORMAT_URL);
            vm.widget.properties.startDateStr = _formatDate(moment(properties.startDate, _DATE_FORMAT_URL));
        }

        if (angular.isDefined(properties.endDate)) {
            vm.widget.properties.endDate = moment(properties.endDate, _DATE_FORMAT_URL);
            vm.widget.properties.endDateStr = _formatDate(moment(properties.endDate, _DATE_FORMAT_URL));
        }

        if (angular.isDefinedAndFilled(properties.metadata)) {
            const promiseAllMetadata = [];

            vm.widget.properties.metadata = vm.widget.properties.metadata || {};
            properties.metadata = angular.isArray(properties.metadata) ? properties.metadata : [properties.metadata];

            angular.forEach(properties.metadata, (metadataKeys) => {
                if (angular.isUndefinedOrEmpty(metadataKeys) || !angular.isString(metadataKeys)) {
                    return;
                }

                /*
                 *  If is string and have at least one element.
                 *  Might contains multiple comma separated values.
                 */
                const splitMetadataKeys = metadataKeys.split(',');

                angular.forEach(splitMetadataKeys, (metadataKey) => {
                    promiseAllMetadata.push(Metadata.getMetadataFromKey(metadataKey, false, false));
                });
            });

            const responses = await $q.all(promiseAllMetadata);
            if (angular.isUndefinedOrEmpty(responses)) {
                return;
            }

            // Index metadata id by parent id.
            const newMetadataByParent = groupBy(responses.filter(Boolean), 'topParent');

            // Merge newly selected metadata with current metadata selection.
            const metadataTree = Object.assign(
                vm.widget.properties.metadata,
                mapValues(newMetadataByParent, (children, parentId) => {
                    const oldMetadataIds = vm.widget.properties.metadata?.[parentId] ?? [];
                    const newMetadataIds = map(children, 'id');
                    return uniq(oldMetadataIds.concat(newMetadataIds));
                }),
            );
            // Remove metadata without parent.
            delete metadataTree[undefined];

            // Update widget metadata properties.
            vm.widget.properties.metadata = metadataTree;
        }
    }

    /**
     * Reset the default properties to keep clean the tags and metadata.
     */
    function _resetDefaultProperties() {
        vm.widget.properties.tags = [];
        vm.widget.properties.metadata = {};
    }

    /**
     * Update the allowed filters according to the linked content list settings if any.
     */
    function _updateFilterData() {
        if (angular.isUndefinedOrEmpty([vm.widget.properties.linkedUuid, _linkedWidget], 'some')) {
            return;
        }

        let restrictions;

        // Set filter restriction defined in linked content list settings.
        if (
            _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY &&
            angular.isDefinedAndFilled(_directories)
        ) {
            restrictions = {
                metadata: angular.fastCopy(get(_linkedWidget.properties, 'metadata')),
                tags: angular.fastCopy(get(_linkedWidget.properties, 'directoryTags')),
            };
            _linkedWidgetRestrictions = restrictions;
        } else if (
            _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.COMMUNITY_POST_LIST &&
            angular.isDefinedAndFilled(vm.currentCommunity)
        ) {
            _linkedWidgetRestrictions = {
                communityTags: angular.fastCopy(get(_linkedWidget.properties, 'communityTags')),
            };
        } else {
            _linkedWidgetRestrictions = angular.fastCopy(get(_linkedWidget.properties, 'filterRestriction'));
        }

        if (angular.isDefinedAndFilled(_linkedWidgetRestrictions)) {
            angular.forEach(_linkedWidgetRestrictions.metadata, (metadata, index) => {
                if (angular.isDefined(metadata) && !angular.isArray(metadata)) {
                    _linkedWidgetRestrictions.metadata[index] = [metadata];
                }
            });
        }
    }

    /**
     * Initialize filter data.
     * We will get the right custom content types,
     * the displayable metadata and set the cct, tags and directory variables.
     *
     * @param {boolean} [initInstances=false] Indicates if we also want to initialize instances list.
     * @param {boolean} [initCct=false]       Indicates if we also want to initialize custom content types list.
     */
    function _initFilterData(initInstances, initCct) {
        _resetDefaultProperties();

        let instancePromise = $q.resolve();

        if (initInstances) {
            instancePromise = _getInstances();
        }

        instancePromise.then(() => {
            _defineSubscriptionContentType();

            let cctPromise = $q.resolve();

            if (initCct) {
                cctPromise = _getCustomContentTypes();
            }

            cctPromise.then(() => {
                if (angular.isDefinedAndFilled(vm.customContentTypes)) {
                    const promises = [];

                    _selectedCustomContentType = [];

                    if (vm.nbCustomContentTypes === 1) {
                        _selectedCustomContentType = vm.customContentTypes[first(Object.keys(vm.customContentTypes))];

                        // Update custom content type tags and apply filters if needed.
                        const tagsPromise = _manageTags(_selectedCustomContentType);

                        promises.push(tagsPromise);

                        tagsPromise.then((tags) => {
                            vm.customContentTypeTags = tags;
                            vm.customContentTypeTagsForInterest = angular.isObject(tags)
                                ? flattenDeep(values(tags))
                                : tags;
                            _getDisplayableMetadata();
                        });
                    } else {
                        let widgetCustomContentTypes = get(vm.widget, 'properties.customContentType', []);
                        const linkedWidgetCustomContentTypes = get(_linkedWidget, 'properties.customContentType', []);

                        if (
                            angular.isUndefinedOrEmpty(widgetCustomContentTypes) &&
                            angular.isDefinedAndFilled(linkedWidgetCustomContentTypes)
                        ) {
                            widgetCustomContentTypes = linkedWidgetCustomContentTypes;
                        }

                        angular.forEach(widgetCustomContentTypes, (cct) => {
                            // Since CCTS are handled asynchronously, we have to wait for the fetch to continue.
                            // eslint-disable-next-line angular/deferred
                            const deferred = $q.defer();
                            promises.push(deferred.promise);

                            CustomContentType.getItem(
                                {
                                    uid: cct,
                                },
                                (response) => {
                                    _selectedCustomContentType.push(response);

                                    // Update custom content type tags and apply filters if needed.
                                    _manageTags(_selectedCustomContentType).then((tags) => {
                                        vm.customContentTypeTags = tags;
                                        vm.customContentTypeTagsForInterest = angular.isObject(tags)
                                            ? flattenDeep(values(tags))
                                            : tags;
                                        deferred.resolve();
                                    });
                                },
                                deferred.resolve,
                                undefined,
                                vm.widget.uuid,
                                false,
                            );
                        });

                        $q.all(promises).then(() => {
                            _getDisplayableMetadata();
                        });
                    }

                    // Update filter restrictions.
                    $q.all(promises).then(() => {
                        _updateFilterData();

                        vm.hasCustomContentTypeTags = angular.isDefinedAndFilled(vm.customContentTypeTags)
                            ? vm.customContentTypeTags[first(Object.keys(vm.customContentTypeTags))].length > 0
                            : false;
                    });
                } else if (_isDirectoryEntriesFilter()) {
                    if (
                        angular.isDefinedAndFilled(Directory.getCurrent()) &&
                        _contentType === InitialSettings.CONTENT_TYPES.DIRECTORY
                    ) {
                        _directories = [Directory.getCurrent()];
                        vm.currentDirectory = _directories[0];
                    } else if (angular.isDefinedAndFilled(get(_linkedWidget, 'properties.directory'))) {
                        _directories = [];
                        angular.forEach(_linkedWidget.properties.directory, (directoryId) => {
                            const directory = Directory.getById(directoryId);
                            if (angular.isDefinedAndFilled(directory)) {
                                _directories.push(directory);
                            }
                        });

                        if (_linkedWidget.properties.directory.length === 1) {
                            vm.currentDirectory = _directories[0];
                        }
                    } else {
                        _directories = [];
                        vm.currentDirectory = {};
                    }

                    // Now we know if a directory is defined, update filter restrictions.
                    _updateFilterData();

                    // Update directory tags and apply filters if needed.
                    _manageTags(_directories).then((tags) => {
                        vm.currentDirectoryTags = tags;
                        vm.hasCurrentDirectoryTags = angular.isDefinedAndFilled(vm.currentDirectoryTags);

                        _getDisplayableMetadata();
                    });
                } else if (_isPostListFilter) {
                    if (angular.isDefinedAndFilled(Community.getCurrent())) {
                        vm.currentCommunity = Community.getCurrent();
                    } else if (
                        angular.isDefinedAndFilled(get(_linkedWidget, 'properties.community', [])) &&
                        _linkedWidget.properties.community.length === 1 &&
                        _linkedWidget.properties.displayType === 'pick'
                    ) {
                        Community.get(
                            {
                                uid: _linkedWidget.properties.community[0],
                            },
                            (response) => {
                                Community.setCurrent(angular.fastCopy(response));
                                vm.currentCommunity = Community.getCurrent();

                                // Update filter restrictions.
                                _updateFilterData();

                                // Only display communityTags that have been selected in the post list widget settings.
                                if (angular.isDefinedAndFilled(get(_linkedWidgetRestrictions, 'communityTags'))) {
                                    vm.currentCommunityTags = vm.currentCommunity.tagsDetails.filter((tag) =>
                                        includes(_linkedWidgetRestrictions.communityTags, tag.uid),
                                    );
                                } else {
                                    vm.currentCommunityTags = vm.currentCommunity.tagsDetails;
                                }

                                if (angular.isUndefinedOrEmpty(get(_linkedWidget, 'properties.postType'))) {
                                    vm.currentCommunityPostTypes =
                                        vm.currentCommunity.postTypes || values(InitialSettings.POST_TYPES);
                                }
                            },
                            Utils.displayServerError,
                        );
                    } else {
                        vm.currentCommunity = {};
                    }

                    // Update filter restrictions.
                    _updateFilterData();

                    // Update community tags and apply filters if needed.
                    vm.currentCommunityTags = vm.currentCommunity.tagsDetails;

                    if (angular.isDefinedAndFilled(get(_linkedWidget, 'properties.postType'))) {
                        vm.currentCommunityPostTypes = angular.fastCopy(_linkedWidget.properties.postType);
                    } else {
                        vm.currentCommunityPostTypes =
                            vm.currentCommunity.postTypes || values(InitialSettings.POST_TYPES);
                    }
                }
            });
        });
    }

    /**
     * Reset widget filters.
     */
    function _resetFilters() {
        let defaultFilters = Instance.getProperty(Config.INSTANCE_PROPERTIES.CONTENT_FILTERS);

        if (_isDirectoryEntriesFilter()) {
            defaultFilters = Instance.getProperty(Config.INSTANCE_PROPERTIES.DIRECTORY_FILTERS);
        } else if (_isCommunityListFilter()) {
            defaultFilters = Instance.getProperty(Config.INSTANCE_PROPERTIES.COMMUNITY_FILTERS);
        } else if (_isPostListFilter) {
            defaultFilters = Instance.getProperty(Config.INSTANCE_PROPERTIES.POST_FILTERS);
        } else if (_isInstanceFilter()) {
            defaultFilters = Instance.getProperty(Config.INSTANCE_PROPERTIES.INSTANCE_FILTERS);
        }

        if (angular.isUndefinedOrEmpty(defaultFilters)) {
            vm.widget.properties.filters = [];

            return;
        }

        if (angular.isString(defaultFilters[0])) {
            vm.widget.properties.filters = [];

            angular.forEach(defaultFilters, (filterName) => {
                vm.widget.properties.filters.push({
                    enable: true,
                    name: filterName,
                });
            });
        } else {
            vm.widget.properties.filters = angular.copy(defaultFilters);
        }
    }

    /**
     * Reset the linked widget.
     * We will put the widget uuid and init again the widget content filter.
     *
     * @param {string} widgetUuid The widget uuid of the linked widget.
     */
    function _resetLinkedWidget(widgetUuid) {
        const currentContent = Content.getCurrent();
        const linkedWidget = ContentTemplate.getElement(currentContent, 'widget', 'uuid', widgetUuid);

        // Looking for the content-list that is main widget, the one and only list that should be !!
        if (linkedWidget.isMainWidget) {
            vm.widget.properties.linkedUuid = widgetUuid;

            // Init again the current widget to refresh filters and tags.
            vm.init();
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close advanced filters dropdown.
     *
     * @param {Event} [evt] The click event.
     */
    function closeDropdown(evt) {
        if (angular.isDefinedAndFilled(evt)) {
            evt.stopPropagation();
        }

        LxDropdownService.close(vm.dropdownId);
    }

    /**
     * Check if a filter is visible.
     *
     * @param  {Object}  filterEntry The filter to check.
     * @return {boolean} If the filter is visible or not.
     */
    function filterIsVisible(filterEntry) {
        const filterIsDisabled = angular.isUndefinedOrEmpty(get(filterEntry, 'name')) || !filterEntry.enable;
        const contentFilterIsNotValid =
            !_isDirectoryEntriesFilter() &&
            !_isPostListFilter &&
            includes(_basicContentTypes, _contentType) &&
            !includes(Config.CONTENT_FILTERS, filterEntry.name);
        const directoryFilterIsNotValid =
            _isDirectoryEntriesFilter() && !includes(Config.DIRECTORY_FILTERS, filterEntry.name);
        const postFilterIsNotValid = _isPostListFilter && !includes(Config.POST_FILTERS, filterEntry.name);

        if (filterIsDisabled || contentFilterIsNotValid || directoryFilterIsNotValid || postFilterIsNotValid) {
            return false;
        }

        const filterName = filterEntry.name.toLowerCase();
        const user = User.getConnected();
        const isAdmin = User.isAdmin() || Community.isUserAdmin(vm.currentCommunity, user);

        switch (filterName) {
            case 'author':
                return (
                    angular.isDefinedAndFilled(vm.currentCommunity) ||
                    isContentListFilter() ||
                    _isPostListFilter ||
                    isAdmin
                );

            case 'community-tags':
                return angular.isDefinedAndFilled(get(vm.currentCommunity, 'tagsDetails'));

            case 'directory-tags':
                return angular.isDefinedAndFilled(vm.currentDirectoryTags);

            case 'metadata':
                const hasSomeDirectoriesMeta = some(_directories, (directory) => {
                    get(directory, 'entriesHasMetadata');
                });

                return (
                    includes(_basicContentTypes, _contentType) ||
                    (_contentType === InitialSettings.CONTENT_TYPES.DIRECTORY && hasSomeDirectoriesMeta) ||
                    (angular.isDefinedAndFilled(_linkedWidget) &&
                        _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY)
                );

            case 'post-merged':
                return isAdmin;

            case 'post-status':
                return (
                    includes(vm.currentCommunity.postTypes, InitialSettings.POST_TYPES.IDEA) &&
                    angular.isDefinedAndFilled(vm.currentCommunity.postStatuses)
                );

            case 'report-status':
                return isAdmin;

            case 'tags':
                let isPresent = false;

                if (angular.isUndefinedOrEmpty(_selectedCustomContentType)) {
                    return false;
                }

                angular.forEach(_selectedCustomContentType, (cct) => {
                    if (angular.isDefinedAndFilled(get(cct, 'tags')) && angular.isArray(cct.tags)) {
                        isPresent = true;
                    }
                });

                return isPresent;

            default:
                return true;
        }
    }

    /**
     * Return class if metadata has a functionalInnerId.
     *
     * @param  {Object} metadata The metadata object.
     * @return {string} Class of the metadata.
     */
    function getMetadataClass(metadata) {
        let metadataClass = metadata.heritable ? 'widget-content-filter__metadata--is-heritable' : '';

        if (angular.isDefinedAndFilled(metadata.functionalInnerId)) {
            metadataClass += ` metadata-selector--${metadata.functionalInnerId}`;
        }

        return metadataClass;
    }

    /**
     * Get widget classes.
     *
     * @return {Array} The widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        if (vm.widget.properties.hideSubheader) {
            widgetClass.push('widget-content-filter--subheader-hidden');
        }

        return widgetClass;
    }

    /**
     * Refresh the display of the subscribe button.
     * Show/hide it according to the search (to be visible, the search must be on contents and with only one
     * custom content type) and check if we already have subscribed to this search.
     */
    function refreshSubscribeButton() {
        if (!Features.hasFeature('social')) {
            vm.canSubscribe = false;

            return;
        }

        const filters = get(vm.widget, 'properties', {});

        vm.canSubscribe =
            angular.isDefinedAndFilled(_linkedWidget) &&
            _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.CONTENT_LIST &&
            angular.isUndefinedOrEmpty(filters.query) &&
            !filters.onlyFeatureFeeds &&
            angular.isUndefinedOrEmpty(filters.startDateStr) &&
            angular.isUndefinedOrEmpty(filters.endDateStr) &&
            (get(_linkedWidget, 'properties.customContentType.length', 0) > 0 ||
                angular.isDefinedAndFilled(filters.customContentType));

        if (vm.canSubscribe) {
            _isSubscribed();
        } else {
            vm.isSubscribed = false;
        }
    }

    /**
     * Action when the displayed date of the date pickers change
     *
     * @param {('start'|'end')}  dateType The type of date changed.
     */
    function onDateStringChange(dateType) {
        /**
         * When start and end date changes to an empty value, also remove the date objects
         */
        if(dateType === 'start' && !angular.isDefinedAndFilled(vm.widget.properties.startDateStr)){
            vm.widget.properties.startDate = undefined
        }
        if(dateType === 'end' && !angular.isDefinedAndFilled(vm.widget.properties.endDateStr)){
            vm.widget.properties.endDate = undefined
        }
        /** Refresh the display of the subscribe button */
        refreshSubscribeButton();
    }

    /**
     * Go to the filtered custom list according to selected params.
     *
     * @param {boolean} closeAdvancedFilters Whether to close the advanced filters dropdown or not.
     */
    function goToFilteredCustomList(closeAdvancedFilters) {
        let uri;

        if (closeAdvancedFilters) {
            vm.closeDropdown();
        }

        if (vm.widget.properties.linkedToLocalWidget) {
            $timeout(() => {
                $rootScope.$broadcast('widget-content-filter-search', vm.widget.uuid);
            });
            vm.refreshSubscribeButton();
            const filters = _getFilters();
            if (angular.equals(filters, _getLocationFilters())) {
                return;
            }
            $location.search('filters', filters);

            return;
        }

        if (Instance.getCurrentInstanceId() === vm.widget.properties.customList.instance) {
            uri = Utils.uri(
                $state.href(Content.getStateName(vm.widget.properties.customList.type), {
                    slug: Translation.translate(vm.widget.properties.customList.slug),
                }),
                _getFilters(),
            );
            $location.url(uri);
        } else {
            Instance.getInstanceById(vm.widget.properties.customList.instance, (response) => {
                uri = Utils.uri(
                    $state.href(
                        'app.front.content-get',
                        {
                            instance: response.slug,
                            slug: Translation.translate(vm.widget.properties.customList.slug),
                        },
                        {
                            absolute: true,
                        },
                    ),
                    _getFilters(),
                );

                $window.open(decodeURIComponent(uri), '_blank');
            });
        }
    }

    /**
     * Determines if it has filled filters.
     *
     * @return {boolean} If the filter has filled filters or not.
     */
    function hasFilledFilters() {
        const { properties } = vm.widget;

        if (angular.isDefined(properties.query)) {
            return true;
        }

        const filterValues = map(_FILTER_NAMES, (filterName) => properties[filterName]);

        return angular.isDefinedAndFilled(filterValues, 'some');
    }

    /**
     * Get Directory from tag identifier.
     *
     * @param {string}   value       Value to transform.
     * @param {Function} cb          Function called with transformation result.
     * @param {Object}   directories Object that contain seek value.
     * @param {string}   identifier  Key to check for value.
     */
    function idToDirectory(value, cb, directories, identifier) {
        angular.forEach(directories, (directory, dirName) => {
            const foundDirectory = loFind(directories[dirName], (tag) => tag[identifier] === value);

            if (angular.isDefined(foundDirectory)) {
                (cb || noop)(foundDirectory);
            }
        });
    }

    /**
     * Check if the content filter widget will filter on content or not.
     *
     * @return {boolean} If we will filter on content or not.
     */
    function isContentListFilter() {
        return (
            angular.isDefinedAndFilled(_linkedWidget) &&
            _linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.CONTENT_LIST
        );
    }

    /**
     * Is widget empty in designer mode.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        return (
            (!vm.hasFilters && !vm.hasFollowButton) ||
            (angular.isUndefinedOrEmpty(vm.widget.properties.customList) && !vm.widget.properties.linkedToLocalWidget)
        );
    }

    /**
     * Is widget hidden in reading mode.
     *
     * @return {boolean} If this widget is hidden or not.
     */
    function isWidgetHidden() {
        vm.parentCtrl.isHidden = !vm.parentCtrl.designerMode() && vm.isWidgetEmpty();

        return vm.parentCtrl.isHidden;
    }

    /**
     * Open advanced filters dropdown.
     *
     * @param {Event} evt The click event.
     */
    function openDropdown(evt) {
        evt.stopPropagation();

        LxDropdownService.open(vm.dropdownId, `#${vm.dropdownTarget}`);
    }

    /**
     * Reset advanced filters.
     */
    function resetFilter() {
        const { properties } = vm.widget;
        angular.forEach(_FILTER_NAMES, (filterName) => {
            properties[filterName] = undefined;
        });
    }

    /**
     * Reset all filters.
     */
    function resetAllFilters() {
        vm.widget.properties.query = undefined;
        vm.resetFilter();
        vm.goToFilteredCustomList(true);
    }

    /**
     * A new custom content type is selected in the filters.
     * Update the associated tags and metadata.
     */
    function onCustomContentTypeChange() {
        _initFilterData(false, false);
        vm.refreshSubscribeButton();
    }

    /**
     * A new instance is selected in the filters.
     * Update the associated content types, tags and metadata.
     */
    function onInstanceChange() {
        _initFilterData(false, true);
        vm.refreshSubscribeButton();
    }

    /////////////////////////////

    vm.closeDropdown = closeDropdown;
    vm.filterIsVisible = filterIsVisible;
    vm.getMetadataClass = getMetadataClass;
    vm.getWidgetClass = getWidgetClass;
    vm.goToFilteredCustomList = goToFilteredCustomList;
    vm.hasFilledFilters = hasFilledFilters;
    vm.idToDirectory = idToDirectory;
    vm.isContentListFilter = isContentListFilter;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.onCustomContentTypeChange = onCustomContentTypeChange;
    vm.onInstanceChange = onInstanceChange;
    vm.openDropdown = openDropdown;
    vm.onDateStringChange = onDateStringChange;
    vm.refreshSubscribeButton = refreshSubscribeButton;
    vm.resetAllFilters = resetAllFilters;
    vm.resetFilter = resetFilter;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the isMainWidget switch on a content-list widget or a directory-entry widget is toggled.
     *
     * @param {Event}   evt        The event.
     * @param {string}  widgetUuid The id of the widget whose settings are changed.
     * @param {boolean} isMain     If the widget is main or not.
     */
    $scope.$on('widget-is-main-toggled', (evt, widgetUuid, isMain) => {
        if (vm.widget.properties.linkedToLocalWidget) {
            _resetLinkedWidget(widgetUuid);
            _resetFilters();

            if (!isMain) {
                vm.widget.properties.linkedToLocalWidget = false;
            }
        }
    });

    /**
     * When the content picker closes, re-initialize the filters.
     *
     * @param {Event}  evt      The content picker close event.
     * @param {string} pickerId The id of the picker that closes.
     */
    $scope.$on('content-picker__close-start', (evt, pickerId) => {
        if (_PICKER_ID === pickerId) {
            vm.init();
            _resetFilters();
        }
    });

    /**
     * When the settings of the content filters are changed, re-initialize the filters.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the content filter widget whose settings are changed.
     */
    $scope.$on('widget-content-filter-settings', (evt, widgetUuid) => {
        if (vm.widget.uuid === widgetUuid) {
            vm.init();
            _resetFilters();
        }
    });

    /**
     * When the settings of the community list are changed, re-initialize the filters.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the content list widget whose settings are changed.
     */
    $scope.$on('widget-community-list-settings', (evt, widgetUuid) => {
        if (vm.widget.properties.linkedToLocalWidget) {
            _resetLinkedWidget(widgetUuid);
        }
    });

    /**
     * When the settings of the content list are changed, re-initialize the filters.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the content list widget whose settings are changed.
     */
    $scope.$on('widget-content-list-settings', (evt, widgetUuid) => {
        if (vm.widget.properties.linkedToLocalWidget) {
            _resetLinkedWidget(widgetUuid);
        }
    });

    /**
     * When the settings of the directory entry widget are changed, re-initialize this widget.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the directory entry widget whose settings are changed.
     */
    $scope.$on('widget-directory-entry-list-settings', (evt, widgetUuid) => {
        if (vm.widget.properties.linkedToLocalWidget) {
            _resetLinkedWidget(widgetUuid);
        }
    });

    /**
     * When the settings of the instance widget are changed, re-initialize this widget.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the instance widget whose settings are changed.
     */
    $scope.$on('widget-instance-list-settings', (evt, widgetUuid) => {
        if (vm.widget.properties.linkedToLocalWidget) {
            _resetLinkedWidget(widgetUuid);
        }
    });

    /**
     * When the settings of the post list widget are changed, re-initialize this widget.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the post list widget whose settings are changed.
     */
    $scope.$on('widget-post-list-settings', (evt, widgetUuid) => {
        if (vm.widget.properties.linkedToLocalWidget) {
            _resetLinkedWidget(widgetUuid);
        }
    });

    /**
     * When the settings of the widget content-filter are changed, update the options.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the current content filter widget whose settings are changed.
     */
    $scope.$on('widget-content-filter-settings-update', (evt, widgetUuid) => {
        if (widgetUuid === vm.widget.uuid) {
            _checkOptions();
        }
    });

    /**
     * When the URL query string changes, set the filter to the params found in the URL query string
     * and apply it to the content list.
     */
    const _destroyRootScopeOn = $rootScope.$on('$locationChangeSuccess', (evt, url, oldUrl) => {
        if (angular.isDefined(oldUrl) && !angular.equals(url.split('?')[0], oldUrl.split('?')[0])) {
            return;
        }
        _handleParamsFilters(_getLocationFilters()).then(() => {
            goToFilteredCustomList(false);
        });
    });

    /**
     * When the scope is removed, stop listening for URL changes.
     */
    $scope.$on('$destroy', _destroyRootScopeOn);

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters settings and refresh the list of the filters.
     * Debounce the refreshing so that we don't refresh to often.
     *
     * @param {Object} newValue The new filter properties.
     * @param {Object} oldValue The old filter properties.
     */
    $scope.$watch(
        'vm.widget.properties.filters',
        function onFilterChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                _checkOptions();
            }
        },
        true,
    );

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        _PICKER_ID = `widget-content-filter-${vm.widget.uuid}`;

        vm.widget = vm.widget || {};

        vm.widget.properties = get(vm.widget, 'properties', {});

        const { properties } = vm.widget;
        const currentContent = Content.getCurrent();

        _contentType = get(currentContent, 'type');

        if (properties.linkedToLocalWidget) {
            _linkedWidget = ContentTemplate.getElement(currentContent, 'widget', 'isMainWidget', true) ||
                properties.linkedWidget || {
                    properties: {},
                };

            const widgetType = get(_linkedWidget, 'widgetType');

            _isPostListFilter = widgetType === InitialSettings.WIDGET_TYPES.COMMUNITY_POST_LIST;

            vm.widget.properties.onlyFeatureFeeds = get(_linkedWidget, 'properties.onlyFeatureFeeds');

            vm.refreshSubscribeButton();
        } else if (angular.isDefinedAndFilled(get(properties, 'customList.type'))) {
            _contentType = properties.customList.type;
        } else {
            properties.filters = [];

            return;
        }

        let allowedFilters = Config.CONTENT_FILTERS;
        if (_isDirectoryEntriesFilter()) {
            allowedFilters = Config.DIRECTORY_FILTERS;
        } else if (_isPostListFilter) {
            allowedFilters = Config.POST_FILTERS;
        } else if (_isCommunityListFilter()) {
            allowedFilters = Config.COMMUNITY_FILTERS;
        }

        if (angular.isDefinedAndFilled(properties.filters)) {
            properties.filters = filter(properties.filters, (propFilter) => includes(allowedFilters, propFilter.name));
        }

        /*
         * The isObject check if the properties is in the right new format.
         * Must be a collection at a time it was only a list of string.
         */
        if (angular.isUndefinedOrEmpty(properties.filters) || !angular.isObject(first(properties.filters))) {
            _resetFilters();
        } else {
            let filters = Config.CONTENT_FILTERS;

            if (_isDirectoryEntriesFilter()) {
                filters = Config.DIRECTORY_FILTERS;
            } else if (_isPostListFilter) {
                filters = Config.POST_FILTERS;
            } else if (_isCommunityListFilter()) {
                filters = Config.COMMUNITY_FILTERS;
            }

            // Add the missing filters as disabled (it happens when we add new filters).
            const existingFilters = map(properties.filters, 'name');
            angular.forEach(filters, (contentFilterName) => {
                if (!includes(existingFilters, contentFilterName)) {
                    properties.filters.push({
                        enable: false,
                        name: contentFilterName,
                    });
                }
            });
        }

        // Force condensed view mode in community.
        if (_contentType === 'community' && angular.isUndefined(get(properties, 'viewMode'))) {
            properties.viewMode = 'condensed';
        }

        // Force theme light in condensed view mode.
        if (properties.viewMode === 'condensed') {
            properties.style.content.theme = 'light';
        }

        _instanceIds = [];
        vm.instances = [];

        // Check if has a linked content list and update fields restriction.
        _initFilterData(true, true);

        const locationFilters = _getLocationFilters();

        // Filter content list if $location contain filters.
        if (angular.isDefinedAndFilled(locationFilters)) {
            _handleParamsFilters(locationFilters).then(() => {
                goToFilteredCustomList(false);
            });
        }

        // Check if the widget displays filters and / or follow interest button.
        _checkOptions();
    };

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = (parentCtrl) => {
        vm.parentCtrl = parentCtrl;

        vm.init();
    };
}

/////////////////////////////
/**
 * Display a content filter widget.
 *
 * @param {Object} widget The widget object, with properties.
 */

function WidgetContentFilterDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetContentFilterController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetContentFilter', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-content-filter/views/widget-content-filter.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetContentFilter', WidgetContentFilterDirective);

/////////////////////////////

export { WidgetContentFilterController, WidgetContentFilterDirective };
