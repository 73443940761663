import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { BookmarksSliceProps } from './types';

const bookmarksSliceSelector = (state: FrontOfficeStore): BookmarksSliceProps => state.bookmarks;

const bookmarksSelector = createSelector([bookmarksSliceSelector], (bookmarks) => bookmarks?.items);

export { bookmarksSelector, bookmarksSliceSelector };
