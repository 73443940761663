(function IIFE() {
    'use strict';

    /////////////////////////////

    function FeedFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/feed/:methodKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            'get': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            'getMulti': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'getMulti',
                },
            },

            'list': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            'search': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'search',
                },
            },

            'synchronize': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'synchronize',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('FeedFactory', FeedFactory);
})();
