/* istanbul ignore file */
import { gTranslate } from '@lumapps/auto-translation';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { error as notifyError } from '@lumapps/notifications';
import { actions } from '@lumapps/posts/ducks/slice';
import { Dispatch } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

export const translatePost = (postId: string, content: string) => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    const targetLang = currentLanguageSelector(state);
    gTranslate({ content, targetLang, isContentHTML: true })
        .then((contentText) => {
            dispatch(
                actions.updatePost({
                    postId,
                    post: {
                        content: {
                            [`google_${targetLang}`]: contentText,
                        },
                    },
                }),
            );
        })
        .catch(() => {
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        });
};
