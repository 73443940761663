import React from 'react';

export type InlineAutocompleteSearchContext = {
    setHighlightedItem(item?: any): void;
};

export const InlineAutocompleteSearchContext = React.createContext<undefined | InlineAutocompleteSearchContext>(
    undefined,
);

export const useInlineAutocompleteSearchContext = () =>
    React.useContext(InlineAutocompleteSearchContext) as InlineAutocompleteSearchContext;
