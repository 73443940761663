import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { getFavoriteDirectories } from '../../ducks/selectors';
import { Favorites } from './Favorites';

const mapStateToProps = (state: FrontOfficeStore) => {
    return {
        directories: getFavoriteDirectories(state),
        language: currentLanguageSelector(state),
    };
};

const ConnectedFavorites = connect(mapStateToProps)(Favorites);

export { ConnectedFavorites as Favorites };
