import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

import { addBaseSlug } from '@lumapps/router/utils';
import { MetadataDetails, InstanceDetails, TagsDetails, ParentContentDetails } from '@lumapps/search/types';
import { TranslationAPI } from '@lumapps/translations/types';

import { Level } from './types';

interface BreadcrumbPropsOptions {
    translate: TranslationAPI['translate'];
    instance?: InstanceDetails;
    metadata?: MetadataDetails[];
    content?: ParentContentDetails;
    tags?: TagsDetails[];
    formatContent?: (content: ParentContentDetails, translate: TranslationAPI['translate']) => Level;
}

const defaultFormatContent = (content: ParentContentDetails, translate: TranslationAPI['translate']) => {
    const translatedTitle = content.title ? translate(content.title) : '';
    return translatedTitle as string;
};

export const getInstanceLevel = (instance: InstanceDetails, translate: TranslationAPI['translate']): Level => {
    const instanceName = translate(instance.name) as string;
    return instance.slug ? { url: addBaseSlug(instance.slug, false), label: instanceName } : instanceName;
};

const props = (options: BreadcrumbPropsOptions) => {
    const { translate, instance, metadata, tags, content, formatContent = defaultFormatContent } = options;
    const breadcrumbs: Array<Level> = instance ? [getInstanceLevel(instance, translate)] : [];

    // TODO: this logic should be on the backend side, no need to do these groups on the frontend.
    if (metadata && metadata.length > 0) {
        const groupedMetadata = groupBy(metadata, ({ parent }) => (parent ? translate(parent.name) : ''));
        const metadataNamesByParent = mapValues(groupedMetadata, (metaList) =>
            map(metaList, ({ name }) => translate(name)),
        );

        const metadatas: string[] = [];

        keys(metadataNamesByParent).forEach((key) => {
            metadatas.push(`${key}: ${metadataNamesByParent[key]}`);
        });

        breadcrumbs.push(metadatas);
    }

    if (content) {
        breadcrumbs.push(formatContent(content, translate));
    }

    if (tags && tags.length > 0) {
        tags.forEach((tag) => {
            const translatedTagName = tag.name ? translate(tag.name) : '';
            breadcrumbs.push(translatedTagName as string);
        });
    }

    return breadcrumbs;
};

export { props };
