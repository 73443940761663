import debounce from 'lodash/debounce';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { isSuperAdmin } from '@lumapps/permissions';
import { Dispatch } from '@lumapps/redux';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { search, suggest } from '../api';
import { search as lumappsSearch } from '../api/lumapps';
import { SEARCH_FIRST_PAGE, SEARCH_FORMAT, ENGINES, MAX_SEARCH_RESULTS } from '../constants';
import { FetchResultsParams, SearchParams } from '../types';
import { shouldUseGroupsRestrictions } from '../utils/shouldUseGroupsRestrictions';
import { getCurrentFeatures, getCursor, getFiltersMetadata, getSelectedFilterId } from './selectors';
import { actions } from './slice';

export const getSearchParams = (
    currentState: FrontOfficeStore,
    {
        query,
        filter,
        currentFacets = {},
        moreResults = false,
        sort,
        page = SEARCH_FIRST_PAGE,
        format = SEARCH_FORMAT.JSON,
        context,
    }: FetchResultsParams,
): SearchParams => {
    const instanceId = instanceIdSelector(currentState);
    const currentCursor = getCursor(currentState);
    const currentLanguage = currentLanguageSelector(currentState);
    const features = getCurrentFeatures(currentState);
    // for some filter, more configuration like sharepoint / drive
    // const filterConfig = getInstanceFilter(filter)(currentState);
    const filtersMeta = getFiltersMetadata(currentState);
    const filterId = filter || getSelectedFilterId(currentState);
    const isGlobalAdmin = isSuperAdmin(currentState);

    // Should we use groups restrictions
    const useGlobalAdminPower = isGlobalAdmin && !shouldUseGroupsRestrictions();

    return {
        query,
        instanceId,
        lang: currentLanguage,
        filter: filterId,
        facets: currentFacets,
        cursor: moreResults ? currentCursor ?? undefined : undefined,
        maxResults: MAX_SEARCH_RESULTS,
        features,
        sort,
        config: filtersMeta && filterId ? filtersMeta[filterId] : undefined,
        useGlobalAdminPower,
        page,
        format,
        context: features.isCoveoEnabled ? context : undefined,
    };
};

export const runSearch = (
    currentState: FrontOfficeStore,
    resultsParams: FetchResultsParams,
    traceId: string,
    engine?: ENGINES,
) => {
    const searchParams = getSearchParams(currentState, resultsParams);

    return search(searchParams, traceId, engine);
};

export const runDownloadPage = (currentState: FrontOfficeStore, resultsParams: FetchResultsParams) => {
    const searchParams = getSearchParams(currentState, resultsParams);

    return lumappsSearch(searchParams, true, true, false);
};

/** Retrieves the suggestions for the specific query */
export const fetchSuggestions = async (
    params: { query: string; siteId?: string; acceptLanguage?: string },
    engine: ENGINES,
    dispatch: Dispatch,
) => {
    const { query, siteId, acceptLanguage } = params;
    try {
        dispatch(actions.transitionSuggestionsToLoadingState());
        const response = await suggest({ query, siteId, acceptLanguage }, engine);
        dispatch(actions.setSuggestionResults(response));
    } catch (error) {
        dispatch(actions.resetSuggestions());
    }
};

export const debouncedFetchSuggestions = debounce(fetchSuggestions, DEBOUNCE_DELAY);
