import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';

import { getLanguages, currentLanguageSelector } from '../ducks/selectors';

const mapStateToProps = (state: BaseStore, props: any) => {
    const { useCustomerLanguages } = props;

    return {
        languages: getLanguages(state, useCustomerLanguages),
        defaultLanguage: currentLanguageSelector(state),
    };
};

const mapDispatchToProps = {};

const connectToLanguages = (Component: React.FC<any>) => connect(mapStateToProps, mapDispatchToProps)(Component);

export { connectToLanguages, mapStateToProps, mapDispatchToProps };
