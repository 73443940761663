import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchNode } from '@lumapps/wrex/serialization/dita/fromDITA/utils';

import { createListItem } from '../../utils/createListItem';
import { createOrderedList } from '../../utils/createOrderedList';
import { createUnorderedList } from '../../utils/createUnorderedList';

/**
 * Convert Dita li into slate list-item element.
 */
export const LIST_ITEM_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'li' }),
    transform: (node, children) => createListItem(children),
};
/**
 * Convert Dita ul into slate unordered-list element.
 */
export const UNORDERED_LIST_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'ul' }),
    transform: (node, children) => createUnorderedList(children),
};
/**
 * Convert Dita ol into slate ordered-list element.
 */
export const ORDERED_LIST_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'ol' }),
    transform: (node, children) => createOrderedList(children),
};
