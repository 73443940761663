import React from 'react';

import { ListWidgetStyleSetting } from '@lumapps/widget-settings/components/ListWidgetStyleSetting';
import { StyleSettingType } from '@lumapps/widget-settings/types';

import { DATA_SCOPE } from '../../../constants';
import { WidgetArticleListViewModeType, WidgetArticleListViewModeVariantType } from '../../../types';

export interface WidgetArticleListStylesProps {
    className?: string;
    currentStylesSetting?: StyleSettingType<WidgetArticleListViewModeType, WidgetArticleListViewModeVariantType>;
    onChange: (
        properties: StyleSettingType<WidgetArticleListViewModeType, WidgetArticleListViewModeVariantType>,
    ) => void;
}

export const WidgetArticleListStyles: React.FC<WidgetArticleListStylesProps> = ({
    className,
    currentStylesSetting,
    onChange,
}) => {
    return (
        <ListWidgetStyleSetting<WidgetArticleListViewModeType, WidgetArticleListViewModeVariantType>
            onChange={onChange}
            currentStylesSetting={currentStylesSetting}
            className={className}
            dataScope={DATA_SCOPE}
        />
    );
};
