import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import { GenericEntityBlockSkeleton } from '@lumapps/lumx-blocks/components/GenericEntityBlockSkeleton';
import { FlexBox } from '@lumapps/lumx/react';

/**
 *
 * @param CertificateSkeletonProps
 * @returns CertificatesSkeleton
 */
export const CertificateSkeleton: React.FC = () => {
    return (
        <FlexBox
            className={classnames(margin('left', 'big'), margin('top', 'regular'))}
            orientation="vertical"
            gap="big"
        >
            <GenericEntityBlockSkeleton hasImage hasMetadata />
            <GenericEntityBlockSkeleton hasImage hasMetadata />
            <GenericEntityBlockSkeleton hasImage hasMetadata />
        </FlexBox>
    );
};
