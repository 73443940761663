import { useMemo } from 'react';

import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import matchesProperty from 'lodash/fp/matchesProperty';
import pipe from 'lodash/fp/pipe';

import { useGetUserContent } from '@lumapps/content-usercontent/hooks/useGetUserContent';
import { UserWidgetData } from '@lumapps/content-usercontent/types';

export function useGetUserSurveyData(legacyWidgetId?: string) {
    const enabled = !!legacyWidgetId;
    const { data, isFetching } = useGetUserContent(enabled);

    const userSurveyData: UserWidgetData['answer'] = useMemo(
        () =>
            // @ts-expect-error: TypeScript does not recognize the correct type for this pipe of functions
            pipe(getOr([], 'values.widgets'), find(matchesProperty('uuid', legacyWidgetId)), get('answer'))(data) ?? {},
        [data, legacyWidgetId],
    );

    return { isFetching, userSurveyData };
}
