import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';

import { FULL_DATE_MIDNIGHT } from 'common/constants';

/////////////////////////////

function ContentListController(
    $location,
    ConfigTheme,
    Content,
    CustomContentType,
    InitialSettings,
    LxNotificationService,
    Metadata,
    ModuleFront,
    Translation,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The parameters of the content filter for an "empty" search.
     *
     * @type {Object}
     */
    const _emptyContentFilter = {
        endDate: undefined,
        metadata: {},
        query: '',
        startDate: undefined,
        tags: [],
    };

    /**
     * The parameters of the search.
     *
     * @type {Object}
     */
    const _params = {
        action: 'PAGE_READ',
        customContentType: undefined,
        customContentTypeTags: [],
        endDate: undefined,
        excludeType: [InitialSettings.CONTENT_TYPES.CUSTOM_LIST],
        metadata: undefined,
        sortOrder: undefined,
        startDate: undefined,
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list key for listing the contents.
     *
     * @type {string}
     * @constant
     */
    vm.LIST_KEY = 'content-list';

    /**
     * The current content filter.
     *
     * @type {Object}
     */
    vm.contentFilter = angular.fastCopy(_emptyContentFilter);

    /**
     * The custom content type tags available for custom content type.
     *
     * @type {Array}
     */
    vm.customContentTypeTags = [];

    /**
     * The metadata available for the custom content type.
     *
     * @type {Array}
     */
    vm.filteredMetadata = [];

    /////////////////////////////

    /*
     * Services and utilities.
     */
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.CustomContentType = CustomContentType;
    vm.Metadata = Metadata;
    vm.ModuleFront = ModuleFront;
    vm.Translation = Translation;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Initialize the contents filters according to the custom content type.
     * Retrieve tags and metadata.
     *
     * @param {string} customContentTypeId The custom content type identifier.
     */
    function _initFilters(customContentTypeId) {
        CustomContentType.getItem(
            {
                uid: customContentTypeId,
            },
            function onCustomContentTypeGetSuccess(response) {
                if (angular.isUndefinedOrEmpty(response)) {
                    return;
                }

                const currentContent = Content.getCurrent();

                /*
                 * Fill the tags.
                 * Only keeps tags that are allowed by `customContentTypeTags` in the content.
                 * Of course, if this property is empty, it means that all tags are allowed.
                 */
                if (angular.isArray(response.tags) && angular.isDefinedAndFilled(response.tags)) {
                    angular.forEach(response.tags, function forEachTags(tag) {
                        if (
                            angular.isUndefinedOrEmpty(currentContent.customContentTypeTags) ||
                            includes(currentContent.customContentTypeTags, tag.uuid)
                        ) {
                            vm.customContentTypeTags.push(tag);
                        }
                    });
                }
            },
        );

        /*
         * Fill the metadata.
         * Only keep metadata with items.
         */
        const metadata = Metadata.getMetadataFilteredByProperty(
            'displayInFilter',
            true,
            Metadata.listByCustomContentTypeId(customContentTypeId),
        );
        vm.filteredMetadata = metadata.filter(function filterMetadataWithItems(meta) {
            return angular.isArray(meta.items) && angular.isDefinedAndFilled(meta.items);
        });
    }

    /**
     * Pre filter the content list with the parameters that may have been given through the URL.
     *
     * @param {Array|Object} filters The filters coming from the URL.
     */
    function _handleParamsFilters(filters) {
        filters = angular.isArray(filters) ? filters : [filters];

        angular.forEach(filters, function forEachFilters(filter) {
            // Must be a filter like 'tags_123'.
            const splittedFilter = filter.split('_');

            // eslint-disable-next-line no-magic-numbers
            if (angular.isUndefinedOrEmpty(splittedFilter) || splittedFilter.length < 2) {
                return;
            }

            const paramName = splittedFilter[0];

            if (angular.isUndefinedOrEmpty(paramName) || angular.isUndefined(vm.contentFilter[paramName])) {
                return;
            }

            const value = splittedFilter[1];

            let contentFilter;
            if (paramName === 'metadata' && angular.isDefinedAndFilled(value)) {
                // Might contains multiple comma separated values.
                const metadataValues = value.split(',');

                angular.forEach(metadataValues, function forEachMetadataValues(metadataValue) {
                    const metadata = Metadata.getMetadataFromKey(metadataValue, true, true);
                    if (angular.isUndefinedOrEmpty(metadata.parent)) {
                        return;
                    }

                    contentFilter = get(vm.contentFilter, `${paramName}.${metadata.parent}`);
                    if (angular.isUndefinedOrEmpty(contentFilter)) {
                        vm.contentFilter.metadata[metadata.parent] = [metadataValue];
                    } else if (!includes(contentFilter, metadataValue)) {
                        contentFilter.push(metadataValue);
                    }
                });
            } else {
                contentFilter = get(vm.contentFilter, paramName);

                // It's an array filter, check if the element already exists and push it if not.
                if (angular.isArray(contentFilter) && !includes(contentFilter, value)) {
                    contentFilter.push(value);
                } else {
                    vm.contentFilter[paramName] = value;
                }
            }
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the current content has a tip widget.
     *
     * @return {boolean} If the current content has a tip widget or not.
     */
    function hasTipWidget() {
        return angular.isDefinedAndFilled(
            loFind(get(Content.getCurrent(), 'template.components'), function findFilledTipWidget(component) {
                return (
                    angular.isDefinedAndFilled(component) &&
                    component.type === 'widget' &&
                    Translation.hasTranslation(get(component, 'properties.content'))
                );
            }),
        );
    }

    /**
     * Filter the contents list based on the content filter.
     */
    function filterContent() {
        const filterParams = angular.fastCopy(_params);

        // If start date is bigger than end date, warn the user.
        if (
            angular.isDefinedAndFilled(vm.contentFilter.startDate) &&
            angular.isDefinedAndFilled(vm.contentFilter.endDate) &&
            vm.contentFilter.startDate > vm.contentFilter.endDate
        ) {
            LxNotificationService.warning(Translation.translate('CONTENT_FILTER_START_DATE_BIGGER_THAN_END_DATE'));

            return;
        }

        if (angular.isDefinedAndFilled(vm.contentFilter.query)) {
            filterParams.query = vm.contentFilter.query;
        }

        if (angular.isDefinedAndFilled(vm.contentFilter.metadata)) {
            filterParams.combinedMetadata = [];

            angular.forEach(vm.contentFilter.metadata, function forEachMetadata(metadata) {
                if (angular.isArray(metadata) && angular.isDefinedAndFilled(metadata)) {
                    filterParams.combinedMetadata.push({
                        metadata,
                    });
                }
            });
        }

        if (angular.isDefinedAndFilled(vm.contentFilter.startDate)) {
            filterParams.startDate = moment(vm.contentFilter.startDate).format(FULL_DATE_MIDNIGHT);
        }

        if (angular.isDefinedAndFilled(vm.contentFilter.endDate)) {
            filterParams.endDate = moment(vm.contentFilter.endDate).format(FULL_DATE_MIDNIGHT);
        }

        if (angular.isDefined(vm.contentFilter.tags)) {
            filterParams.customContentTypeTags = angular.isDefined(vm.contentFilter.tags)
                ? vm.contentFilter.tags
                : _params.customContentTypeTags;
        }

        Content.filterize(filterParams, undefined, undefined, vm.LIST_KEY);
    }

    /////////////////////////////

    vm.filterContent = filterContent;
    vm.hasTipWidget = hasTipWidget;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const currentContent = Content.getCurrent();

        _params.customContentType = currentContent.customContentType;
        _params.customContentTypeTags = currentContent.customContentTypeTags;

        // Define the sort order.
        if (get(currentContent, 'properties.sortOrder', 'desc') === 'asc') {
            _params.sortOrder = ['publicationDate', 'createdAt'];
        } else {
            _params.sortOrder = ['-publicationDate', '-createdAt'];
        }

        // Init customContentType to be able to get tags.
        _initFilters(_params.customContentType);

        const { filters } = $location.search();

        if (angular.isDefinedAndFilled(filters)) {
            _handleParamsFilters(filters);
        }

        vm.filterContent();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('ContentListController', ContentListController);

/////////////////////////////

export { ContentListController };
