import includes from 'lodash/includes';

/////////////////////////////

function PickerController($rootScope, $scope, Config, Features, Media) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The list of events to forward to the abstract picker.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _EVENTS = ['open-start', 'open-end', 'close-start', 'close-end'];

    /**
     * Contains all the listener of the pickers events.
     *
     * @type {Array}
     */
    let _listenedEvents = [];

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $scope.$on('abstract-picker__open-start', (evt, pickerId) => {
        if (pickerId !== vm.pickerId) {
            return;
        }
        vm.isMediaModel = angular.isUndefinedOrEmpty(vm.isMediaModel) ? true : vm.isMediaModel;
        vm.editionModel = Media.transformForMediaPicker(vm.ngModel, vm.isMediaModel);
    });

    $scope.$on('abstract-picker__close-start', (evt, pickerId, hasDataToSave = false) => {
        if (pickerId !== vm.pickerId || !hasDataToSave) {
            return;
        }

        vm.ngModel = Media.transformForMediaClient(vm.editionModel, vm.isMediaModel);
    });

    /**
     * When the directive is destroyed, remove all listeners of pickers events.
     */
    $scope.$on('$destroy', () => {
        angular.forEach(_listenedEvents, (listenedEvent) => {
            listenedEvent();
        });

        _listenedEvents = [];
    });

    /////////////////////////////
}

/////////////////////////////

/**
 * Picker directive.
 * Handle the initialization of the new abstract (or advanced) picker.
 */

function PickerDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: PickerController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            /* eslint-disable no-inline-comments, line-comment-position, no-multi-spaces */
            allowEmptySelection: '<?lsAllowEmptySelection', // For the Media Picker.
            allowFolderPick: '<?lsAllowFolderPick', // For the Media Picker.
            allowToUpdateEmpty: '<?lsAllowToUpdateEmpty', // For the User Picker.
            allowedProviders: '<?lsAllowedProviders', // For the Media Picker.
            allowedTypes: '<?lsAllowedTypes', // For the Abstract Picker.
            customActions: '<?lsCustomActions', // For the User Picker.
            embedded: '<?lsEmbedded', // For the Abstract Picker.
            filterActions: '=?lsFilterActions', // For the Content Picker.
            filterView: '<?lsFilterView', // For the Abstract Picker.
            forcedCustomContentTypes: '=?lsForcedCustomContentTypes', // For the Content Picker.
            forcedTypes: '=?lsForcedTypes', // For the Content Picker.
            hasTemplateSidebar: '<?lsHasSidebar', // For the Abstract Picker.
            hideInstanceSelector: '=?lsHideInstanceSelector', // For the Content Picker.
            isMediaModel: '<?lsIsMediaModel', // See below.
            /**
             * Media model === the old media format (as opposed to the one wrapped within MediaWithContext).
             * MediaWithContext = {
             *   ...contextProps,
             *   object: mediaModel,
             * }.
             */
            kind: '@?lsKind', // For the Content Picker.
            maxSelect: '<?lsMaxSelect', // For the Abstract and User Picker.
            mediaList: '=?lsMediaList', // For the Media Picker.
            multiple: '<?lsMultiple', // For the Abstract Picker.
            language: '@?lsLanguage', // For the Abstract Picker.
            ngModel: '=?', // For all the pickers.
            objectType: '@lsType', // For the Abstract Picker.
            objectTypePath: '@?lsTypePath', // For the Abstract Picker.
            paginateSelection: '<?lsPaginateSelection', // For the User Picker.
            pickIds: '<?lsPickIds', // For the User Picker.
            pickerId: '@?lsPickerId', // For all the pickers.
            providerService: '@?lsProviderService', // For the Media picker.
            showFoldersOnly: '=?lsShowFoldersOnly', // For the Media Picker.
            showHidden: '<lsShowHidden', // For the User Picker.
            title: '@?lsTitle', // For the Abstract, Content and User Picker.
            viewMode: '@?lsViewMode', // For the Content Picker.
            widgetUuid: '@?lsWidgetUuid', // For the Abstract and Media Picker.
            /* eslint-enable no-inline-comments, line-comment-position, no-multi-spaces */
        },
        templateUrl: '/client/common/modules/abstract-picker/views/picker.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsPicker', PickerDirective);

/////////////////////////////

export { PickerDirective };
