/* istanbul ignore file */
export interface Preview {
    uid: string;
    title: string;
    link: string;
    id?: string;
}

export enum SharingStep {
    selectUserSpace = 'selectUserSpace',
    prepareMessage = 'prepareMessage',
}

export interface ShareMenuItemBaseProps {
    onClick: () => void;
    scope: string;
    key?: string;
}
