import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { mdiImage } from '@lumapps/lumx/icons';
import { UploadDocumentProps } from '@lumapps/medias-document/components/UploadDocument';
import { useSelector } from '@lumapps/redux/react';

export const useDirectoryEntryImageProps = ({
    onUploaded,
    selected,
    ...props
}: UploadDocumentProps): UploadDocumentProps => {
    const currentLanguage = useSelector(currentLanguageSelector);
    const instanceId = useSelector(instanceIdSelector);
    const imageProps: UploadDocumentProps = {
        uploadDocumentOptions: {
            lang: currentLanguage,
            shared: false,
            parentPath: `provider=local/site=${instanceId}`,
        },
        thumbnailProps: {
            size: 'xl',
        },
        icon: mdiImage,
        size: 'xl',
        useBlobLocalStorage: true,
    };

    if (onUploaded) {
        imageProps.onDocumentUploaded = onUploaded;
    }

    if (selected) {
        imageProps.selectedDocument = selected;
    }

    return {
        ...imageProps,
        ...props,
    };
};
