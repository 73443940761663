import React from 'react';

import { mdiClose, mdiHelpCircle } from '@lumapps/lumx/icons';
import { GenericBlock, Icon, Theme, ColorPalette, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BlockHtml } from '@lumapps/widget-html/components/BlockHtml';

interface HelpMessageProps {
    theme?: Theme;
    html: string;
    onDelete?: () => void;
}

/** Display the help message */
export const HelpMessage: React.FC<HelpMessageProps> = ({ html, theme, onDelete }) => {
    const { translateKey } = useTranslate();
    return (
        <GenericBlock orientation="horizontal" gap="huge" hAlign="top">
            <GenericBlock.Figure>
                <Icon
                    icon={mdiHelpCircle}
                    size="m"
                    color={theme === Theme.dark ? ColorPalette.light : ColorPalette.primary}
                />
            </GenericBlock.Figure>

            <GenericBlock.Content>
                <BlockHtml theme={theme} html={html} />
            </GenericBlock.Content>

            {onDelete && (
                <GenericBlock.Actions>
                    <IconButton
                        emphasis="low"
                        theme={theme}
                        icon={mdiClose}
                        label={translateKey(GLOBAL.CLOSE)}
                        onClick={onDelete}
                    />
                </GenericBlock.Actions>
            )}
        </GenericBlock>
    );
};
