import { Route, AppId, createPageRoute } from '@lumapps/router';

export const mobileDontKnowHowToSignInRoute: Route = createPageRoute({
    slug: 'mobile/dont-know-how-to-sign-in',
    id: 'mobile-login-helper',
    appId: AppId.backOffice,
});

export const mobileQrCodeAccessRoute: Route = createPageRoute({
    slug: 'mobile/qr-code-access',
    id: 'mobile-login-qr-code',
    appId: AppId.backOffice,
});
