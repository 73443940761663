import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import { padding } from '@lumapps/classnames';
import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { mdiMagnify } from '@lumapps/lumx/icons';
import { Theme, TextField } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_POST_LIST } from '../../../keys';

interface PostSearchQueryFilterBlockProps {
    onSearchQuery: (query: string) => void;
    selectedQuery?: string;
    theme: Theme;
}

export const PostSearchQueryFilterBlock: React.FC<PostSearchQueryFilterBlockProps> = ({
    onSearchQuery,
    selectedQuery = '',
    theme,
}) => {
    const { translateKey } = useTranslate();
    const [query, setQuery] = useState(selectedQuery);

    useEffect(() => {
        setQuery(selectedQuery);
    }, [selectedQuery]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchQuery = useCallback(
        debounce((query: string) => {
            onSearchQuery(query);
        }, DEBOUNCE_DELAY),
        [onSearchQuery],
    );

    const handleOnChange = (value: string) => {
        setQuery(value);
        debouncedSearchQuery(value);
    };
    return (
        <TextField
            className={padding('all', null)}
            icon={mdiMagnify}
            onChange={handleOnChange}
            value={query}
            theme={theme}
            clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
            placeholder={translateKey(WIDGET_POST_LIST.FILTERS_SEARCH_FOR_A_POST)}
        />
    );
};
