import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import union from 'lodash/union';
import without from 'lodash/without';

import { type ContentStream } from '@lumapps/content-streams/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { move } from '@lumapps/utils/array/move';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { DEFAULT_STREAM, NEW_STREAM_PREFIX } from '../../constants';
import { ContentStreamAdminState, Fields, Form, Status } from './types';

const initialState: ContentStreamAdminState = {
    status: Status.loading,
    entities: {},
    forms: {},
    streamList: [],
};

/* eslint-disable no-param-reassign */

function createForm(stream: ContentStream): Form {
    return {
        uid: stream.uid,
        fields: {
            label: { value: stream.label },
            feeds: { value: stream.feedIds ?? [] },
            viewMode: { value: stream.viewMode ?? DEFAULT_STREAM.viewMode },
            limit: { value: stream.limit ?? DEFAULT_STREAM.limit },
            featuredOnly: { value: stream.filterFields.featuredOnly },
            highlightedOnly: { value: stream.filterFields.highlightedOnly },
            defaultInstanceRelatives: { value: stream.filterFields.defaultInstanceRelatives },
            instances: { value: stream.filterFields.instanceIds ?? [] },
            favoriteSitesOnly: { value: stream.filterFields.favoriteSitesOnly },
            contentTypes: { value: stream.filterFields.contentTypeIds ?? [] },
            categories: { value: stream.filterFields.categories ?? [] },
            metadata: { value: stream.filterFields.metadataList?.[0] ?? [], loadedFamilies: [] },
        },
    };
}

export const { reducer, actions } = createSlice({
    domain: 'contentStreamAdmin',
    initialState,
    reducers: {
        /**
         * Reset state (on content stream admin page load).
         */
        reset() {
            return initialState;
        },
        /**
         * Set loading status for the whole page or individual stream.
         */
        setStatus(
            state: ContentStreamAdminState,
            { payload: { status, streamUID } }: PayloadAction<{ status: Status; streamUID?: string }>,
        ) {
            if (streamUID) {
                // Set stream loading status.
                state.forms[streamUID].status = status;
            } else {
                // Set whole page loading status.
                state.status = status;
            }
        },
        /**
         * Content stream fetch success.
         */
        updateStreamList(
            state: ContentStreamAdminState,
            { payload: { streams } }: PayloadAction<{ streams: ContentStream[] }>,
        ) {
            state.entities = Object.assign(state.entities, keyBy(streams, 'uid'));
            state.streamList = union(state.streamList, map(streams, 'uid'));
            const forms = map(streams, createForm);
            state.forms = Object.assign(state.forms, keyBy(forms, 'uid'));
        },
        /**
         * Update stream (after a save).
         */
        updateStream(
            state: ContentStreamAdminState,
            { payload: { oldUID, newStream } }: PayloadAction<{ oldUID: string; newStream: ContentStream }>,
        ) {
            delete state.entities[oldUID];
            delete state.forms[oldUID];

            const indexOfOldUID = state.streamList.indexOf(oldUID);
            const newUID = newStream.uid.toString();
            if (indexOfOldUID > -1) {
                state.streamList[indexOfOldUID] = newUID;
            } else {
                state.streamList.unshift(newUID);
            }
            state.entities[newUID] = newStream;
            state.forms[newUID] = createForm(newStream);
        },
        /**
         * Set stream open/close.
         */
        setStreamOpen(
            state: ContentStreamAdminState,
            { payload: { uid, isOpen } }: PayloadAction<{ uid: string; isOpen: boolean }>,
        ) {
            const form = state.forms[uid];
            if (form) {
                form.isOpen = isOpen;
            }
        },
        /**
         * Update field props.
         */
        updateStreamField(
            state: ContentStreamAdminState,
            action: PayloadAction<{ uid: string; field: Fields; props: Record<string, any> }>,
        ) {
            const { uid, field, props } = action.payload;
            const form = state.forms[uid];
            Object.assign(get(form, ['fields', field]), props);
            form.wasTouched = true;
        },
        /**
         * Move stream in list
         */
        moveStream(
            state: ContentStreamAdminState,
            { payload: { from, to } }: PayloadAction<{ from: number; to: number }>,
        ) {
            state.streamList = move(state.streamList, from, to);
        },
        /**
         * New content stream.
         */
        newStream(state: ContentStreamAdminState) {
            // Temporary UID.
            const uid = NEW_STREAM_PREFIX + generateUUID();
            state.forms[uid] = createForm({ ...DEFAULT_STREAM, uid });
            state.streamList.unshift(uid);

            // Open by default.
            state.forms[uid].isOpen = true;
        },
        /**
         * Confirm delete stream.
         */
        confirmDeleteStream(
            state: ContentStreamAdminState,
            { payload: { uid, isConfirmDeleteOpen } }: PayloadAction<{ uid: string; isConfirmDeleteOpen: boolean }>,
        ) {
            state.forms[uid].isConfirmDeleteOpen = isConfirmDeleteOpen;
        },
        /**
         * Delete stream.
         */
        deleteStream(state: ContentStreamAdminState, { payload: uid }: PayloadAction<string>) {
            delete state.forms[uid];
            delete state.entities[uid];
            state.streamList = without(state.streamList, uid);
        },
    },
});
