import { Icon, Size } from '@lumapps/lumx/react';

import React from 'react';
import { mdiInformation } from '@lumapps/lumx/icons';
import { string } from 'prop-types';

/**
 * Inline help / notification bar.
 *
 * @param  {string}       message The content-block size attribute.
 * @return {ReactElement} The context Bar.
 */
const SAContextHelpBar = ({ message }) => {
    return message ? (
        <div className="sa-context-help-bar">
            <Icon className="sa-context-help-bar__icon" icon={mdiInformation} size={Size.s} />
            <p className="sa-context-help-bar__message">{message}</p>
        </div>
    ) : null;
};

SAContextHelpBar.propTypes = {
    message: string,
};

SAContextHelpBar.defaultProps = {
    message: null,
};

export { SAContextHelpBar };
