import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { mdiCloseCircle, mdiFilterVariant, mdiMenuDown } from '@lumapps/lumx/icons';
import { Icon, Chip, Size, ChipProps, FlexBox, Orientation, Text } from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';
import { DIMENSION } from '@lumapps/responsive/constants';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { getLimitedListToString } from '@lumapps/utils/string/getLimitedListToString';

import './index.scss';

export interface FilterTriggerProps {
    /** callback to be executed once the clear button on the main CTA is clicked */
    onClearClick: () => void;
    /** override the label displayed for the chip that will open the dropdown  */
    label?: string;
    /** whether the filter chip label should be truncated when needed */
    truncateLabel?: boolean;
    /** custom size to truncate the label (only if truncateLabel is true) */
    truncateLabelSize?: number;
    /** whether the dropdown is open or not */
    isFilterDropdownOpen?: boolean;
    /** callback for setting whether the dropdown is open or not */
    setIsFilterDropdownOpen: (isFilterDropdownOpen: boolean, event?: any) => void;
    /** whether the dropdown is disabled or not */
    isDisabled?: boolean;
    /** the total amount of selected filters to display in the button that opens the dropdown */
    totalSelectedDisplayedFilters?: number;
    /** list of selected filters */
    selectedFilter?: string[];
    /** props to be passed down to the dropdown trigger */
    triggerProps?: Partial<ChipProps>;
    /** reference for the trigger */
    anchorRef: React.RefObject<any>;
}

/**
 * Component to be used if you want to display the Filters Trigger as loading.
 */
export const LoadingFilterTrigger: React.FC = () => {
    const { translateKey } = useTranslate();

    return (
        <Chip
            before={<Icon icon={mdiFilterVariant} size={Size.xs} />}
            isDisabled
            aria-label={translateKey(GLOBAL.FILTERS)}
        >
            {translateKey(GLOBAL.FILTERS)}
        </Chip>
    );
};

const CLASSNAME = 'filter-trigger';

/**
 * Component that displays the name of the filter as well as the values selected for it. Upon clikcing this
 * component, the filter associated to it should be triggered or displayed.
 * @family Filters
 * @param FilterTriggerProps
 * @returns FilterTrigger
 */
export const FilterTrigger: React.FC<FilterTriggerProps> = ({
    label,
    setIsFilterDropdownOpen,
    isFilterDropdownOpen = false,
    selectedFilter = [],
    onClearClick,
    triggerProps,
    totalSelectedDisplayedFilters = 3,
    truncateLabel,
    truncateLabelSize = 74,
    isDisabled = false,
    anchorRef,
}) => {
    const toggleDropdown = (event: any) => {
        event.persist();

        setIsFilterDropdownOpen(!isFilterDropdownOpen, event);
    };
    const { element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { dimension } = useResponsive();
    const isSelected = selectedFilter.length > 0;
    const selectedLabel = React.useMemo(
        () => getLimitedListToString(selectedFilter, totalSelectedDisplayedFilters),
        [selectedFilter, totalSelectedDisplayedFilters],
    );
    const shouldTruncateChipLabel = dimension !== DIMENSION.MOBILE && truncateLabel;

    return (
        <Chip
            before={<Icon icon={mdiFilterVariant} size={Size.xs} />}
            after={<Icon icon={isSelected ? mdiCloseCircle : mdiMenuDown} size={Size.xs} />}
            isSelected={isSelected}
            onClick={toggleDropdown}
            onAfterClick={isSelected ? onClearClick : toggleDropdown}
            className={element('trigger')}
            isDisabled={isDisabled}
            aria-expanded={isFilterDropdownOpen}
            {...triggerProps}
            ref={anchorRef}
            aria-label={
                selectedLabel?.firstFilter
                    ? `${translateKey(GLOBAL.FILTERS)} - ${selectedLabel?.firstFilter}`
                    : translateKey(GLOBAL.FILTERS)
            }
        >
            {label && !selectedLabel ? label : null}

            {selectedLabel ? (
                <FlexBox orientation={Orientation.horizontal}>
                    <Text
                        as="span"
                        title={selectedLabel?.firstFilter}
                        className={!shouldTruncateChipLabel ? margin('right', 'tiny') : ''}
                        truncate
                        style={{
                            maxWidth: shouldTruncateChipLabel ? truncateLabelSize : undefined,
                        }}
                    >
                        {selectedLabel?.firstFilter}
                    </Text>
                    {selectedLabel?.filterNumber && selectedLabel.filterNumber > 0 ? (
                        <Text as="span">+{selectedLabel.filterNumber}</Text>
                    ) : null}
                </FlexBox>
            ) : null}

            {!label && !selectedLabel ? translateKey(GLOBAL.FILTERS) : null}
        </Chip>
    );
};
