import toNumber from 'lodash/toNumber';

import { FocusPoint } from '@lumapps/lumx/react';

export function formatFocalPoint(focalPointX?: number | string, focalPointY?: number | string): FocusPoint | undefined {
    if (!focalPointX && !focalPointY) {
        return undefined;
    }
    return {
        x: toNumber(focalPointX) || 0,
        y: toNumber(focalPointY) || 0,
    };
}
