import React, { useRef, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Icon, Button, Size, Tooltip, Placement } from '@lumapps/lumx/react';
import { mdiTranslate } from '@lumapps/lumx/icons';

import { translate as t } from 'components/translations';

import { WidgetTranslationDialog } from './WidgetTranslationDialog';

/**
 * Component that renders a block with a button opening a modal allowing us to
 * auto-translate a widget content.
 *
 * @param  {Object}   props.widget        The selected widget.
 * @param  {Function} props.setSourceLang Function to update the source lang, it takes an object with a
 *                                        `sourceLang` key.
 * @param  {string}   props.inputLanguage The current language of the edited content.
 * @param  {Function} props.openModal     Function that open the modal.
 * @return {Object}   The component.
 */
const WidgetTranslationSettings = ({ widget, setSourceLang, inputLanguage, openModal }) => {
    /** Languages that has a content for the selected widget. */
    const availableLangs =
        (widget &&
            widget.properties &&
            widget.properties.content &&
            Object.keys(widget.properties.content).filter(
                (lang) => lang !== inputLanguage && !isEmpty(widget.properties.content[lang]),
            )) ||
        [];
    const isHidden = availableLangs.length === 0;

    /** The ref of the trigger button to open the modal. Useful to re-focus when modal is closed. */
    const openModalBtnRef = useRef(null);

    /**
     * Opens the modal and set the sourceLang to init the dialog content.
     */
    const onOpen = useCallback(() => {
        setSourceLang({ sourceLang: availableLangs[0] });
        openModal();
    }, [availableLangs, openModal, setSourceLang]);

    if (isHidden) {
        return null;
    }

    return (
        <div className="widget-translation-settings">
            <Icon icon={mdiTranslate} size={Size.s} />
            <div className="widget-translation-settings__content">
                <p>{t('FRONT.CONTENT_TRANSLATION.SETTINGS.HAS_TRANSLATIONS_MESSAGE')}</p>
                <Tooltip label={t('FRONT.CONTENT_TRANSLATION.SETTINGS.SEE_TRANSLATIONS')} placement={Placement.TOP}>
                    <Button
                        ref={openModalBtnRef}
                        className="widget-translation-settings__button"
                        onClick={onOpen}
                    >
                        {t('FRONT.CONTENT_TRANSLATION.SETTINGS.SEE_TRANSLATIONS')}
                    </Button>
                </Tooltip>
            </div>
            <WidgetTranslationDialog openModalBtnRef={openModalBtnRef} />
        </div>
    );
};

export { WidgetTranslationSettings };
