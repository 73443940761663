import qs from '@lumapps/base-api/qs';

import BaseApi from '@lumapps/base-api';

const userApi = new BaseApi({ path: 'user' });

const getDefaultParams = () => ({});

/**
 * Change user setting 'hasacceptedTerms'.
 *
 * @param  {boolean} hasAccepted User has accepted or refused T&Cs.
 * @return {Promise} The request promise.
 */
const acceptTermsAndConditions = (hasAccepted = false) => {
    return userApi.get('hasacceptedTerms', {
        params: {
            hasAccepted,
        },
    });
};

/**
 * List users.
 *
 * @param  {Object}  params Additional request params.
 * @return {Promise} The request promise.
 */
const list = (params = {}) => {
    const paramsSerializer = (parameters) => {
        return qs.stringify(parameters, { arrayFormat: 'repeat' });
    };

    return userApi.get('list', {
        params: { ...getDefaultParams(), ...params },
        paramsSerializer,
    });
};

export { acceptTermsAndConditions, list };
