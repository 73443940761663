import React from 'react';

import { FlexBox, Size, SkeletonRectangle, SkeletonTypography, Typography } from '@lumapps/lumx/react';

/**
 * Loading state for the Category component
 * @returns LoadingCategoryItem
 */

export const LoadingCategoryItem: React.FC = () => {
    return (
        <FlexBox>
            <SkeletonRectangle height={Size.xl} />
            <SkeletonTypography typography={Typography.display1} />
        </FlexBox>
    );
};
