import { Layout } from '../types';
import { getAllWidgetsInContent } from './getAllWidgetsInContent';

/**
 * Whether one of the specific widget types are in the content
 */
export const hasOneOfWidgetTypesInContent = (widgetTypes: string[], content?: Layout): boolean => {
    if (!content?.components) {
        return false;
    }

    const contentWidgets = getAllWidgetsInContent(content.components);

    return contentWidgets.some(({ widgetType }) => widgetTypes.includes(widgetType));
};
