import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { groupViolationsByLanguage } from '../utils/groupViolations';

const nannySelector = (state: FrontOfficeStore) => state.nanny;

const getFrontOfficeSelector = createSelector(nannySelector, (fo) => fo);

const getViolationsByLanguageSelector = createSelector(nannySelector, (fo) => groupViolationsByLanguage(fo.violations));

export { getFrontOfficeSelector, getViolationsByLanguageSelector };
