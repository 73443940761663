/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
    ON_GLOBAL_ACTIVE_AMB_RESULTS,
    ON_GLOBAL_NEW_POSTS_RESULTS,
    ON_GLOBAL_TOTAL_SHARES_RESULTS,
    ON_INTERACTIONS_RATE_RESULT,
    ON_INTERACTIONS_TOTAL_COMMENTS_RESULT,
    ON_INTERACTIONS_TOTAL_LIKES_RESULT,
    UPDATE_ALL_ANALYTICS_FILTER,
    UPDATE_ANALYTICS_FILTER,
    FETCH_ALL_STARTED,
    ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT,
    ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT,
    ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT,
    ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT,
    FETCH_ALL_TOPS_STARTED,
    ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT,
    ON_FETCH_TOP_POSTS_BY_LIKES_RESULT,
    ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT,
    ON_FETCH_TOP_POSTS_BY_SHARES_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT,
    SINGLE_FETCH_STARTED,
} from './actions';

const defaultState = {
    currentSAFilters: {
        ambassadors: [],
        sharers: [],
        // eslint-disable-next-line no-magic-numbers
        timeframe: 7,
        topics: [],
        programId: null,
    },
    globalActivity: {
        activeAmbassadors: {
            fetching: true,
            hasError: false,
            infoDetails: null,
            value: null,
        },
        newSocialPost: {
            fetching: true,
            hasError: false,
            infoDetails: null,
            value: null,
        },
        totalShares: {
            fetching: true,
            hasError: false,
            infoDetails: null,
            value: null,
        },
    },
    interactions: {
        rate: {
            fetching: true,
            hasError: false,
            infoDetails: null,
            value: null,
        },
        totalComment: {
            fetching: true,
            hasError: false,
            infoDetails: null,
            value: null,
        },
        totalLikes: {
            fetching: true,
            hasError: false,
            infoDetails: null,
            value: null,
        },
    },
    topAmbassadors: {
        byComments: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byInteractions: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byLikes: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byShares: {
            entries: [],
            fetching: true,
            hasError: false,
        },
    },
    topSocialPosts: {
        byComments: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byInteractions: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byLikes: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byShares: {
            entries: [],
            fetching: true,
            hasError: false,
        },
    },
    topTopics: {
        byInteractions: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byLikes: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byShareable: {
            entries: [],
            fetching: true,
            hasError: false,
        },
        byShares: {
            entries: [],
            fetching: true,
            hasError: false,
        },
    },
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        // -------------- FILTERS ----------------.
        case UPDATE_ANALYTICS_FILTER:
            return produce(state, (draft) => {
                draft.currentSAFilters[action.key] = action.value;
            });

        case UPDATE_ALL_ANALYTICS_FILTER:
            return produce(state, (draft) => {
                draft.currentSAFilters = {
                    ...defaultState.currentSAFilters,
                    ...action.values,
                };
            });

        // -------------- LOADERS MANAGEMENT ----------------.
        case FETCH_ALL_STARTED:
            return produce(state, (draft) => {
                draft.globalActivity.activeAmbassadors.fetching = true;
                draft.globalActivity.newSocialPost.fetching = true;
                draft.globalActivity.totalShares.fetching = true;
                draft.interactions.rate.fetching = true;
                draft.interactions.totalLikes.fetching = true;
                draft.interactions.totalComment.fetching = true;
            });

        case FETCH_ALL_TOPS_STARTED:
            return produce(state, (draft) => {
                draft.topTopics.byInteractions.fetching = true;
                draft.topTopics.byLikes.fetching = true;
                draft.topTopics.byShares.fetching = true;
                draft.topSocialPosts.byInteractions.fetching = true;
                draft.topSocialPosts.byLikes.fetching = true;
                draft.topSocialPosts.byShares.fetching = true;
                draft.topSocialPosts.byComments.fetching = true;
                draft.topAmbassadors.byInteractions.fetching = true;
                draft.topAmbassadors.byLikes.fetching = true;
                draft.topAmbassadors.byShares.fetching = true;
                draft.topAmbassadors.byComments.fetching = true;
            });

        // -------------- GLOABAL ----------------.
        case ON_GLOBAL_ACTIVE_AMB_RESULTS:
            return produce(state, (draft) => {
                draft.globalActivity.activeAmbassadors.fetching = false;
                if (action.error) {
                    draft.globalActivity.activeAmbassadors.hasError = true;
                } else {
                    draft.globalActivity.activeAmbassadors.value = action.value;
                    draft.globalActivity.activeAmbassadors.infoDetails = action.infoDetails;
                    draft.globalActivity.activeAmbassadors.hasError = false;
                }
            });

        case ON_GLOBAL_NEW_POSTS_RESULTS:
            return produce(state, (draft) => {
                draft.globalActivity.newSocialPost.fetching = false;
                if (action.error) {
                    draft.globalActivity.newSocialPost.hasError = true;
                } else {
                    draft.globalActivity.newSocialPost.value = action.value;
                    draft.globalActivity.newSocialPost.hasError = false;
                }
            });

        case ON_GLOBAL_TOTAL_SHARES_RESULTS:
            return produce(state, (draft) => {
                draft.globalActivity.totalShares.fetching = false;
                if (action.error) {
                    draft.globalActivity.totalShares.hasError = true;
                } else {
                    draft.globalActivity.totalShares.hasError = false;
                    draft.globalActivity.totalShares.value = action.value;
                    draft.globalActivity.totalShares.infoDetails = action.infoDetails;
                }
            });

        // -------------- INTERACTIONS SECTION ----------------.
        case ON_INTERACTIONS_RATE_RESULT:
            return produce(state, (draft) => {
                draft.interactions.rate.fetching = false;
                if (action.error) {
                    draft.interactions.rate.hasError = true;
                } else {
                    draft.interactions.rate.value = action.value;
                    draft.interactions.rate.infoDetails = action.infoDetails;
                    draft.interactions.rate.hasError = false;
                }
            });

        case ON_INTERACTIONS_TOTAL_LIKES_RESULT:
            return produce(state, (draft) => {
                draft.interactions.totalLikes.fetching = false;
                if (action.error) {
                    draft.interactions.totalLikes.hasError = true;
                } else {
                    draft.interactions.totalLikes.value = action.value;
                    draft.interactions.totalLikes.infoDetails = action.infoDetails;
                    draft.interactions.totalLikes.hasError = false;
                }
            });

        case ON_INTERACTIONS_TOTAL_COMMENTS_RESULT:
            return produce(state, (draft) => {
                draft.interactions.totalComment.fetching = false;
                if (action.error) {
                    draft.interactions.totalComment.hasError = true;
                } else {
                    draft.interactions.totalComment.value = action.value;
                    draft.interactions.totalComment.infoDetails = action.infoDetails;
                    draft.interactions.totalComment.hasError = false;
                }
            });

        // -------------- TOP TOPICS RESULTS ----------------.
        case ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT:
            return produce(state, (draft) => {
                draft.topTopics.byInteractions.fetching = false;
                if (action.error) {
                    draft.topTopics.byInteractions.hasError = true;
                } else {
                    draft.topTopics.byInteractions.hasError = false;
                    draft.topTopics.byInteractions.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT:
            return produce(state, (draft) => {
                draft.topTopics.byLikes.fetching = false;
                if (action.error) {
                    draft.topTopics.byLikes.hasError = true;
                } else {
                    draft.topTopics.byLikes.hasError = false;
                    draft.topTopics.byLikes.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT:
            return produce(state, (draft) => {
                draft.topTopics.byShares.fetching = false;
                if (action.error) {
                    draft.topTopics.byShares.hasError = true;
                } else {
                    draft.topTopics.byShares.hasError = false;
                    draft.topTopics.byShares.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT:
            return produce(state, (draft) => {
                draft.topTopics.byShareable.fetching = false;
                if (action.error) {
                    draft.topTopics.byShareable.hasError = true;
                } else {
                    draft.topTopics.byShareable.hasError = false;
                    draft.topTopics.byShareable.entries = action.entries;
                }
            });

        // -------------- TOP POSTS RESULTS ----------------.
        case ON_FETCH_TOP_POSTS_BY_SHARES_RESULT:
            return produce(state, (draft) => {
                draft.topSocialPosts.byShares.fetching = false;
                if (action.error) {
                    draft.topSocialPosts.byShares.hasError = true;
                } else {
                    draft.topSocialPosts.byShares.hasError = false;
                    draft.topSocialPosts.byShares.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT:
            return produce(state, (draft) => {
                draft.topSocialPosts.byInteractions.fetching = false;
                if (action.error) {
                    draft.topSocialPosts.byInteractions.hasError = true;
                } else {
                    draft.topSocialPosts.byInteractions.hasError = false;
                    draft.topSocialPosts.byInteractions.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_POSTS_BY_LIKES_RESULT:
            return produce(state, (draft) => {
                draft.topSocialPosts.byLikes.fetching = false;
                if (action.error) {
                    draft.topSocialPosts.byLikes.hasError = true;
                } else {
                    draft.topSocialPosts.byLikes.hasError = false;
                    draft.topSocialPosts.byLikes.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT:
            return produce(state, (draft) => {
                draft.topSocialPosts.byComments.fetching = false;
                if (action.error) {
                    draft.topSocialPosts.byComments.hasError = true;
                } else {
                    draft.topSocialPosts.byComments.hasError = false;
                    draft.topSocialPosts.byComments.entries = action.entries;
                }
            });

        // -------------- TOP AMBASSADORS RESULTS ----------------.
        case ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT:
            return produce(state, (draft) => {
                draft.topAmbassadors.byShares.fetching = false;
                if (action.error) {
                    draft.topAmbassadors.byShares.hasError = true;
                } else {
                    draft.topAmbassadors.byShares.hasError = false;
                    draft.topAmbassadors.byShares.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT:
            return produce(state, (draft) => {
                draft.topAmbassadors.byInteractions.fetching = false;
                if (action.error) {
                    draft.topAmbassadors.byInteractions.hasError = true;
                } else {
                    draft.topAmbassadors.byInteractions.hasError = false;
                    draft.topAmbassadors.byInteractions.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT:
            return produce(state, (draft) => {
                draft.topAmbassadors.byLikes.fetching = false;
                if (action.error) {
                    draft.topAmbassadors.byLikes.hasError = true;
                } else {
                    draft.topAmbassadors.byLikes.hasError = false;
                    draft.topAmbassadors.byLikes.entries = action.entries;
                }
            });

        case ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT:
            return produce(state, (draft) => {
                draft.topAmbassadors.byComments.fetching = false;
                if (action.error) {
                    draft.topAmbassadors.byComments.hasError = true;
                } else {
                    draft.topAmbassadors.byComments.hasError = false;
                    draft.topAmbassadors.byComments.entries = action.entries;
                }
            });

        case SINGLE_FETCH_STARTED:
            return produce(state, (draft) => {
                switch (action.target) {
                    case 'topic.interactions':
                        draft.topTopics.byInteractions.fetching = true;

                        break;

                    case 'topic.likes':
                        draft.topTopics.byLikes.fetching = true;

                        break;

                    case 'topic.shareable':
                        draft.topTopics.byShareable.fetching = true;

                        break;

                    case 'topic.shares':
                        draft.topTopics.byShares.fetching = true;

                        break;

                    case 'post.interactions':
                        draft.topSocialPosts.byInteractions.fetching = true;

                        break;

                    case 'post.shares':
                        draft.topSocialPosts.byShares.fetching = true;

                        break;

                    case 'post.likes':
                        draft.topSocialPosts.byLikes.fetching = true;

                        break;

                    case 'post.comments':
                        draft.topSocialPosts.byComments.fetching = true;

                        break;

                    case 'amb.interactions':
                        draft.topAmbassadors.byInteractions.fetching = true;

                        break;

                    case 'amb.shares':
                        draft.topAmbassadors.byShares.fetching = true;

                        break;

                    case 'amb.likes':
                        draft.topAmbassadors.byLikes.fetching = true;

                        break;

                    case 'amb.comments':
                        draft.topAmbassadors.byComments.fetching = true;

                        break;

                    case 'totalComments':
                        draft.interactions.totalComment.fetching = true;

                        break;

                    case 'totalLikes':
                        draft.interactions.totalLikes.fetching = true;

                        break;

                    case 'interactionRate':
                        draft.interactions.rate.fetching = true;

                        break;

                    case 'totalShares':
                        draft.globalActivity.totalShares.fetching = true;

                        break;

                    case 'newSocialPost':
                        draft.globalActivity.newSocialPost.fetching = true;

                        break;

                    case 'activeAmbassadors':
                        draft.globalActivity.activeAmbassadors.fetching = true;

                        break;

                    default:
                        break;
                }
            });

        default:
            return state;
    }
};
