import React from 'react';

import { EventPickerDialog } from '@lumapps/event-pickers/components/EventPickerDialog';
import { EventPickerDialogProps } from '@lumapps/event-pickers/types';
import { getEvents } from '@lumapps/events/api/getEvents';
import { EVENTS } from '@lumapps/events/keys';
import { Event } from '@lumapps/events/types';
import { RelativeDate } from '@lumapps/lumx-texts/components/RelativeDate';
import { mdiCalendar } from '@lumapps/lumx/icons';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { useTranslate } from '@lumapps/translations';
import { SelectionSetting } from '@lumapps/widget-settings/components/SelectionSetting';
import { SelectionSettingType } from '@lumapps/widget-settings/types';
import { DITA } from '@lumapps/wrex/serialization/dita/types';
import { featuredImageFromDita } from '@lumapps/wrex/serialization/dita/utils/featuredImageFromDita';

import { DATA_SCOPE } from '../../../constants';

export interface SelectionSectionProps {
    currentSelection?: SelectionSettingType;
    onChange: (properties: { selection: SelectionSettingType }) => void;
}

const CLASSNAME = 'selection-section';

export const SelectionSection: React.FC<SelectionSectionProps> = ({
    currentSelection: widgetCurrentSelection,
    onChange,
}) => {
    const { translateObject } = useTranslate();

    const getEventImage = (event: Event) => {
        const featuredImage = featuredImageFromDita(
            translateObject(event.structuredContent.dita?.translations) as DITA,
        );

        return makeSecuredMediaURLRelative(featuredImage?.url) || '';
    };

    const getEventSubtitle = (event: Event) => {
        return <RelativeDate key="date" date={event.createdAt} />;
    };

    const handleOnChange = (genericSelection: SelectionSettingType) => {
        onChange({
            selection: genericSelection,
        });
    };

    return (
        <SelectionSetting<Event, EventPickerDialogProps>
            entityBlock={{
                title: (event) => translateObject(event.structuredContent.title?.translations),
                subtitle: (event) => getEventSubtitle(event),
                icon: mdiCalendar,
                image: (event) => getEventImage(event),
            }}
            entityPicker={{
                component: EventPickerDialog,
                props: { isMultipleSelect: true },
            }}
            manualButtonText={EVENTS.SELECT_EVENTS}
            onChange={handleOnChange}
            getEntities={getEvents}
            currentSelection={widgetCurrentSelection}
            dataScope={`${DATA_SCOPE}-selection`}
            className={CLASSNAME}
        />
    );
};
