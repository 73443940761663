(function IIFE() {
    // ///////////////////////////

    function WidgetDirectoryEntryListController(
        $location,
        $scope,
        $state,
        Analytics,
        Content,
        ContentTemplate,
        Directory,
        DirectoryEntry,
        DirectoryEntryPicker,
        InitialSettings,
        Instance,
        LxDialogService,
        Translation,
        Utils,
        User,
    ) {
        'ngInject';

        const vm = this;

        // ///////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        // ///////////////////////////

        /**
         * Directory entry picker modal identifier - only for basic mode.
         *
         * @type {string}
         */
        let _DIRECTORY_ENTRY_PICKER_ID = 'widget-directory-entry-list-picker-';

        /**
         * Contains the list of the pickable tags of all the directories of the instance.
         *
         * @type {Array}
         */
        let _directoriesTags = [];

        /**
         * Indicates if it's the first time we list the directory entries.
         *
         * @type {boolean}
         */
        let _isFirstFilterize = true;

        // ///////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        // ///////////////////////////

        /**
         * Indicates if the current user is allowed to add local entries to the current directory.
         * Local entries are only seen by the person adding them.
         *
         * @type {boolean}
         */
        vm.canAddLocalEntry = false;

        // ///////////////////////////

        /**
         * Services and utilities.
         */
        vm.DirectoryEntry = DirectoryEntry;

        // ///////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        // ///////////////////////////

        /**
         * Check if the current user has the rights to add a local entry to the current directory.
         *
         * @return {boolean} If the current user has the rights to add a local entry.
         */
        function _canAddLocalEntry() {
            const currentDirectory = Directory.getCurrent();

            if (
                angular.isUndefinedOrEmpty(currentDirectory) ||
                _.get(Content.getCurrent(), 'type') !== InitialSettings.CONTENT_TYPES.DIRECTORY ||
                angular.isUndefinedOrEmpty(currentDirectory.userLocalEntryFeedKeys) ||
                !vm.widget.isMainWidget
            ) {
                vm.canAddLocalEntry = false;

                return vm.canAddLocalEntry;
            }

            vm.canAddLocalEntry = User.hasSubscriptions(currentDirectory.userLocalEntryFeedKeys);

            return vm.canAddLocalEntry;
        }

        /**
         * Compute the CSS classes that are specific to the widget content list.
         *
         * @return {Array} A list of CSS classes.
         */
        function _computeClasses() {
            const classes = [];
            const { properties } = vm.widget;

            if (angular.isUndefinedOrEmpty(properties.directory)) {
                return classes;
            }

            if (angular.isArray(properties.directory) && properties.directory.length === 1) {
                const directory = Directory.getById(properties.directory[0]);
                if (angular.isDefined(directory) && angular.isDefinedAndFilled(directory.type)) {
                    classes.push(`widget-directory-entry-list--${directory.type}`);
                }
            } else if (angular.isDefinedAndFilled(properties.directory.type)) {
                classes.push(`widget-directory-entry-list--${properties.directory.type}`);
            }

            return classes;
        }

        /**
         * Compute the tabs name for each directory tag selected.
         */
        function _computeTabsNames() {
            const tagsUid = vm.widget.properties.directoryTags;
            let firstTab;

            Utils.empty(vm.widgetListCtrl.tabs.items);

            if (angular.isUndefinedOrEmpty([tagsUid, _directoriesTags], 'some')) {
                return;
            }

            const tagsById = _.zipObject(_.map(_directoriesTags, 'uuid'), _directoriesTags);

            angular.forEach(tagsUid, function forEachTag(tagUuid) {
                if (angular.isUndefinedOrEmpty(tagUuid)) {
                    return;
                }

                const tag = tagsById[tagUuid];
                if (angular.isUndefinedOrEmpty(tag) || !Translation.hasTranslations(tag.name)) {
                    return;
                }

                vm.widgetListCtrl.tabs.items.push({
                    name: Translation.translate(tag.name),
                    uid: tag.uuid,
                });

                if (angular.isUndefined(firstTab)) {
                    firstTab = angular.fastCopy(_.last(vm.widgetListCtrl.tabs.items));
                }
            });

            vm.widgetListCtrl.selectTab(vm.widget.properties.allTabEnabled ? undefined : firstTab);
        }

        /**
         * Compute the tags that must be used to get the list of directory entries.
         *
         * @param  {Object}       additionalFilters The additional filters.
         * @return {Array|string} The tags to use to get the directory entries.
         */
        function _computeTags(additionalFilters) {
            if (angular.isDefinedAndFilled(_.get(additionalFilters, 'tags'))) {
                return additionalFilters.tags;
            }

            const { properties } = vm.widget;
            if (angular.isUndefinedOrEmpty(properties.directoryTags)) {
                return undefined;
            }

            if (
                properties.tabsEnabled &&
                angular.isDefined(vm.widgetListCtrl.tabSelected) &&
                vm.widgetListCtrl.tabSelected.uid !== 'all'
            ) {
                return [vm.widgetListCtrl.tabSelected.uid];
            }

            return properties.directoryTags;
        }

        /**
         * Initialize the list of pickable tags.
         *
         * @param {Function} [cb] A callback function to execute on success.
         */
        function _initTags(cb) {
            cb = cb || angular.noop;

            if (!angular.isArray(vm.widget.properties.instance)) {
                cb();

                return;
            }

            _directoriesTags = [];

            Directory.getMultiInstanceDirectories(
                vm.widget.properties.instance,
                function onGetMultiInstancesDirectoriesSuccess(instances) {
                    angular.forEach(instances, function forEachInstances(instance) {
                        if (angular.isDefinedAndFilled(_.get(instance, 'tags'))) {
                            _directoriesTags = _directoriesTags.concat(instance.tags);
                        }
                    });

                    cb();
                },
                true,
            );
        }

        /**
         * Initialize the list of directory entries.
         *
         * @param {Object}  [additionalFilters]       The additional filters.
         * @param {boolean} [resetSelectedTabs=false] Indicates if we want to reset the selected tab.
         * @param {boolean} [fromInit=false]          Indicates if we are initializing the list from the init of the
         *                                            widget.
         */
        function _initList(additionalFilters, resetSelectedTabs, fromInit) {
            const params = {
                currentInstance: Instance.getCurrentInstanceId(),
                ignore403: true,
            };
            const listKey = vm.widgetListCtrl.getListKey();

            let sortDirection;

            const { properties } = vm.widget;

            if (angular.isDefinedAndFilled(properties.listOrderDir)) {
                sortDirection = properties.listOrderDir.toUpperCase() === 'ASC' ? '' : '-';
            }

            // Backward compatibility.
            if (angular.isDefinedAndFilled(properties.sortOrder)) {
                params.sortOrder = properties.sortOrder;
                params.displaySortMandatory = false;
            } else if (angular.isDefinedAndFilled(properties.listOrder)) {
                params.sortOrder = angular.isDefined(sortDirection)
                    ? sortDirection + properties.listOrder
                    : properties.listOrder;
                params.displaySortMandatory = false;
            } else {
                params.sortOrder = 'order';
                params.displaySortMandatory = true;
            }

            if (angular.isDefinedAndFilled(properties.instance)) {
                params.instance = properties.instance;
            }

            if (angular.isDefinedAndFilled(properties.metadata) || additionalFilters) {
                const metadata =
                    additionalFilters && angular.isDefinedAndFilled(additionalFilters.metadata)
                        ? additionalFilters.metadata
                        : properties.metadata;

                params.combinedMetadata = [];
                angular.forEach(metadata, function forEachMetadata(meta) {
                    if (angular.isUndefinedOrEmpty(meta) || (!angular.isString(meta) && !angular.isArray(meta))) {
                        return;
                    }

                    params.combinedMetadata.push({
                        metadata: meta,
                    });
                });
            }

            params.tags = _.compact(_computeTags(additionalFilters));

            const currentContent = Content.getCurrent();
            if (_.get(currentContent, 'type') === InitialSettings.CONTENT_TYPES.DIRECTORY && vm.widget.isMainWidget) {
                if (angular.isUndefinedOrEmpty([currentContent.uid, currentContent.externalKey], 'every')) {
                    vm.widgetListCtrl.updateListItems();

                    return;
                }

                params.directory = currentContent.externalKey;
            } else if (angular.isDefinedAndFilled(properties.directory)) {
                if (angular.isArray(properties.directory)) {
                    params.directory = properties.directory;
                } else if (angular.isDefined(properties.directory.id)) {
                    params.directory = [properties.directory.id];
                }
            }

            if (properties.type === 'pick') {
                params.ids = _.map(properties.entries, 'id');
                params.queryType = 'getDirectoryEntriesByIds';
                // Reset sort order.
                params.sortOrder = undefined;

                DirectoryEntry.cacheFilterize(
                    params,
                    function onListItemsUpdated(response) {
                        vm.widgetListCtrl.updateListItems();

                        if (response.length < params.ids.length) {
                            // Directory Entries has been removed.
                            vm.widget.properties.entries = angular.fastCopy(response);
                        }
                    },
                    vm.widgetListCtrl.updateListItems,
                    listKey,
                    undefined,
                    fromInit,
                );

                _isFirstFilterize = false;

                return;
            }

            if (angular.isNumber(properties.maxNumber)) {
                params.maxResults = properties.maxNumber;
            }

            if (properties.type === 'favorites') {
                params.userFavoritesOnly = true;
            }

            params.lang = Translation.getLang('current');

            if (angular.isDefinedAndFilled(additionalFilters)) {
                params.query = additionalFilters.query;

                DirectoryEntry.filterize(
                    params,
                    vm.widgetListCtrl.updateListItems,
                    vm.widgetListCtrl.updateListItems,
                    listKey,
                );
            } else if (
                _isFirstFilterize &&
                vm.widget.isMainWidget &&
                angular.isDefinedAndFilled($location.search().filters)
            ) {
                const filters = Utils.getSimpleUrlParams($location.search().filters);

                params.query = filters.query;
                params.tags = filters.tags;

                if (angular.isDefinedAndFilled(filters.metadata)) {
                    filters.metadata = angular.isArray(filters.metadata) ? filters.metadata : [filters.metadata];
                    params.combinedMetadata = params.combinedMetadata || [];

                    angular.forEach(filters.metadata, function forEachFilterMetadata(metadataKeys) {
                        if (angular.isUndefinedOrEmpty(metadataKeys)) {
                            return;
                        }

                        // Might contains multiple comma separated values.
                        angular.forEach(metadataKeys.split(','), function forEachMetadataKeys(metadataKey) {
                            if (
                                angular.isUndefinedOrEmpty(metadataKey) ||
                                (!angular.isString(metadataKey) && !angular.isArray(metadataKey))
                            ) {
                                return;
                            }

                            params.combinedMetadata.push({
                                metadata: metadataKey,
                            });
                        });
                    });
                }

                DirectoryEntry.filterize(
                    params,
                    vm.widgetListCtrl.updateListItems,
                    vm.widgetListCtrl.updateListItems,
                    listKey,
                );
            } else {
                DirectoryEntry.cacheFilterize(
                    params,
                    vm.widgetListCtrl.updateListItems,
                    vm.widgetListCtrl.updateListItems,
                    listKey,
                    undefined,
                    fromInit,
                );
            }

            _isFirstFilterize = false;
        }

        /**
         * Initialize the widget properties and the controller parameters.
         */
        function _initProperties() {
            vm.widget.properties = vm.widget.properties || {};
            const { properties } = vm.widget;

            if (angular.isUndefined(properties.entries)) {
                properties.entries = [];
            }

            if (angular.isUndefinedOrEmpty(properties.type)) {
                properties.type = 'pick';
            }

            if (angular.isDefinedAndFilled(properties.instance) && angular.isString(properties.instance)) {
                properties.instance = [properties.instance];
            }

            if (angular.isUndefinedOrEmpty(properties.viewMode)) {
                properties.viewMode = 'vertical';
            }

            // Style backward compatibility.
            if (angular.isDefinedAndFilled(properties.viewMode)) {
                if (properties.viewMode === 'vertical') {
                    properties.viewMode = 'list';
                } else if (properties.viewMode === 'horizontal') {
                    properties.viewMode = 'grid';
                }
            }

            if (angular.isUndefinedOrEmpty(properties.viewModeVariant)) {
                properties.viewModeVariant = 'group';
            }

            if (angular.isUndefinedOrEmpty(properties.itemsSeparator)) {
                properties.itemsSeparator = true;
            }

            if (angular.isUndefined(properties.itemsPerLine) && angular.isDefinedAndFilled(properties.perLine)) {
                const perLine = angular.isString(properties.perLine)
                    ? parseInt(properties.perLine, 10)
                    : properties.perLine;

                if (angular.isNumber(perLine)) {
                    properties.itemsPerLine = perLine;
                }

                delete properties.perLine;
            }

            if (angular.isDefinedAndFilled(properties.itemsPerLine) && angular.isString(properties.itemsPerLine)) {
                const itemsPerLine = parseInt(properties.itemsPerLine, 10);

                if (angular.isNumber(itemsPerLine)) {
                    properties.itemsPerLine = itemsPerLine;
                }
            }

            // Backward for old slideshow properties.
            if (angular.isDefinedAndFilled(properties.slideshow)) {
                properties.slideshowEnabled = properties.slideshow;
                delete properties.slideshow;
            }

            if (angular.isDefinedAndFilled(properties.slideshowAutoPlay)) {
                properties.slideshowAutoplay = properties.slideshowAutoPlay;
                delete properties.slideshowAutoPlay;
            }
        }

        /**
         * Open directory entry picker (only when widget selection type is pick and in basic mode).
         */
        function _openDirectoryEntryPicker() {
            if (
                vm.widget.properties.type !== vm.widgetListCtrl.SELECTION_TYPES.pick ||
                Content.getViewMode() !== 'basic' ||
                Content.getAction() === 'get'
            ) {
                return;
            }

            Utils.waitForAndExecute(`#${_DIRECTORY_ENTRY_PICKER_ID}`, DirectoryEntryPicker);
        }

        /**
         * An extra condition specific to this type of widget to determine if the widget should be considered empty.
         *
         * @return {boolean} Whether the widget should be empty or not.
         */
        function _isWidgetEmpty() {
            return !vm.canAddLocalEntry;
        }

        /**
         * Indicates if the tabs should be displayed in the widget or not.
         *
         * @return {boolean} Whether the tabs should be displayed or not.
         */
        function _shouldDisplayListTabs() {
            const { properties } = vm.widget;

            return (
                properties.type !== 'pick' &&
                angular.isDefinedAndFilled(properties.directoryTags) &&
                properties.directoryTags.length > 1
            );
        }

        /**
         * Synchronize the items from the widget list with another var.
         *
         * Todo [Arnaud]: we should get rid of that at some point.
         * Not touching it coz we do weird things with parent scopes in some areas and I don't want it all to explode...
         */
        function _synchronizeDirectoryEntries() {
            vm.parentCtrl.directoryEntries = _.filter(vm.widgetListCtrl.items, function onFilter(item) {
                return angular.isDefinedAndFilled(item.id);
            });
        }

        // ///////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        // ///////////////////////////
        // Don't remove this stub because of the `init` function exposed in `vm`.

        // ///////////////////////////

        /**
         * Initialize the controller.
         *
         * @param {boolean} saveListKey Indicates if we want to save the list key as original.
         */
        vm.init = function init(saveListKey) {
            if (saveListKey) {
                _DIRECTORY_ENTRY_PICKER_ID += vm.widget.uuid;
            }

            vm.widget.properties = vm.widget.properties || {};
            const { properties } = vm.widget;

            if (angular.isDefinedAndFilled(properties.instance) && angular.isString(properties.instance)) {
                properties.instance = [properties.instance];
            }
            if (!angular.isArray(properties.instance)) {
                properties.instance = undefined;
            }

            if (saveListKey) {
                _initTags(vm.widgetListCtrl.init.bind(vm, saveListKey));
            } else {
                vm.widgetListCtrl.init(saveListKey);
            }

            _canAddLocalEntry();
        };

        /**
         * Set parent controller.
         *
         * @param {Object} widgetListCtrl The parent controller.
         */
        this.setParentController = function setParentController(widgetListCtrl) {
            vm.widgetListCtrl = widgetListCtrl;

            vm.widgetListCtrl.widgetListChildCtrl = {
                computeClasses: _computeClasses,
                computeTabsNames: _computeTabsNames,
                initList: _initList,
                initProperties: _initProperties,
                isWidgetEmpty: _isWidgetEmpty,
                onListItemsUpdated: _synchronizeDirectoryEntries,
                onWidgetListClick: _openDirectoryEntryPicker,
                shouldDisplayListTabs: _shouldDisplayListTabs,
            };

            vm.parentCtrl = {
                // Do not delete this! This is used for widget overrides.
                directoryEntries: [],
            };

            vm.init(true);
        };
    }

    // ///////////////////////////

    /**
     * The directory entry widget.
     * This widget lists entries of directories.
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetDirectoryEntryListDirective() {
        'ngInject';

        function WidgetDirectoryEntryListLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetDirectoryEntryListController,
            controllerAs: 'vm',
            link: WidgetDirectoryEntryListLink,
            replace: true,
            require: ['widgetDirectoryEntryList', '^widgetList'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-directory-entry-list/views/widget-directory-entry-list.html',
        };
    }

    // ///////////////////////////

    angular.module('Widgets').directive('widgetDirectoryEntryList', WidgetDirectoryEntryListDirective);
})();
