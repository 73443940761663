import React from 'react';

import { Alignment, FlexBox, ProgressCircular } from '@lumapps/lumx/react';

/**
 * Default loading state for adaptive cards
 */
export const LoadingState = () => {
    return (
        <FlexBox orientation="vertical" hAlign={Alignment.center} vAlign={Alignment.center} fillSpace>
            <ProgressCircular />
        </FlexBox>
    );
};
