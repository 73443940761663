/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyComponent = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'event-notification-dialog' */
            './EventNotificationDialog'
        ),
);

export const EventNotificationDialog: React.FC<React.ComponentProps<typeof LazyComponent> & { isOpen?: boolean }> = ({
    isOpen,
    ...props
}) => (
    <DelayedSuspense isDialog shouldLoad={isOpen}>
        {(setShouldMount) => <LazyComponent isOpen={isOpen} onVisibilityChange={setShouldMount} {...props} />}
    </DelayedSuspense>
);
