import React from 'react';

import { useDispatch } from '@lumapps/redux/react';

import { NOTIFICATION_TRANSITION_TIME } from '../../../constants';
import { actions } from '../../../ducks/slice';
import { NotificationInStore } from '../../../ducks/types';

/**
 * Handle removing the notification when fully hidden
 */
export function useHandleRemoveTimeout({
    notification,
    wasVisible,
}: {
    notification: NotificationInStore;
    wasVisible: React.RefObject<boolean>;
}) {
    const dispatch = useDispatch();
    const { id, visible, onNotificationHidden } = notification;

    // Using a ref to avoid unnecessarily updating the useEffect
    const onNotificationHiddenRef = React.useRef(onNotificationHidden);

    // Visibility false => remove after the hide transition
    React.useEffect(() => {
        if (wasVisible.current && !visible) {
            const timerId = setTimeout(() => {
                onNotificationHiddenRef.current?.();
                dispatch(actions.remove({ id }));
            }, NOTIFICATION_TRANSITION_TIME);
            return () => clearTimeout(timerId);
        }
        return undefined;
    }, [dispatch, id, visible, wasVisible]);

    React.useEffect(() => {
        onNotificationHiddenRef.current = onNotificationHidden;
    }, [onNotificationHidden]);
}
