import React from 'react';
import { number, arrayOf, node } from 'prop-types';
import classNames from 'classnames';

import omit from 'lodash/omit';

import { getWidgetContentStyle } from 'components/services/styles';
import { PADDING_PROPERTY_NAMES } from 'components/components/styles/constants';
import { widgetType } from 'components/components/widgets/types';
import { VIEW_MODE_VARIANTS_VALUES } from 'components/components/widgets/constants';

/**
 * Get the widget style object to apply to the proper element.
 * - If in `group` mode, style should be applied to the grid element.
 * - If in `ungroup` mode, style should be applied to the item elements except for the padding property.
 *
 * @param  {Object} parentWidgetProperties The parent widget `properties` object.
 * @return {Object} The object style describing how the cascinding grid will looks.
 */
const getStyleFromWidget = ({ parentWidgetProperties }) => {
    const { viewModeVariant } = parentWidgetProperties;

    const isUngrouped = viewModeVariant === VIEW_MODE_VARIANTS_VALUES.UNGROUP;
    const itemStyle = {
        ...getWidgetContentStyle(parentWidgetProperties, false),
    };

    const wrapperStyle = getWidgetContentStyle(parentWidgetProperties, true);

    return {
        gridStyle: isUngrouped ? wrapperStyle : itemStyle,
        itemStyle: isUngrouped ? omit(itemStyle, PADDING_PROPERTY_NAMES) : null,
    };
};

/**
 * Generic grid view element. This element accepts a list of components to display.
 *
 * @return {Element} The grid view react element.
 */
const GridView = ({ columnCount, list, parentWidgetProperties = {} }) => {
    const { gridStyle, itemStyle } = getStyleFromWidget({ parentWidgetProperties });
    const { viewModeVariant } = parentWidgetProperties;

    return (
        <div
            className={classNames(
                'grid-view',
                `grid-view--column-count-${columnCount}`,
                `grid-view--view-mode-variant-${viewModeVariant}`,
            )}
            style={gridStyle}
        >
            {list.map((item) => (
                <div key={item.key} className="grid-view_item" style={itemStyle}>
                    {item}
                </div>
            ))}
        </div>
    );
};

GridView.protoTypes = {
    /** The number of columns to display. */
    columnCount: number,
    /** A list of nodes to display in the grid view. */
    list: arrayOf(node),
    /** The properties object retrieved from the parent widget. */
    parentWidgetProperties: widgetType.component.properties,
};

export { GridView };
