import { get } from '@lumapps/constants';

import { getTimezoneAbbr } from './getTimezoneAbbr';

const { userLang } = get();

export const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getCurrentTimezoneShort = () =>
    new Intl.DateTimeFormat(userLang, { timeZoneName: 'short' })
        .formatToParts()
        .find(({ type }) => type === 'timeZoneName')?.value;

export const getCurrentTimezoneFormated = () => {
    const timezone = getCurrentTimezone();
    const formatedTimezone = getTimezoneAbbr(timezone) || getCurrentTimezoneShort();
    return formatedTimezone;
};
