import * as termsAndConditionsApi from '@lumapps/terms-and-conditions/api';

/**
 * Get the current terms and conditions.
 *
 * @param  {string} customerId The customer id.
 * @return {Object} The current terms and conditions.
 */
const getTermsAndConditionsTask = (customerId) => {
    return termsAndConditionsApi.getTermsAndConditions({ customerId });
};

/**
 * Reset the terms and conditions approuvment for all users.
 * @return {Object} The backend confirmation.
 */
const resetTermsAndConditionsTask = () => {
    return termsAndConditionsApi.resetTermsAndConditions();
};

/**
 * Get the current terms and conditions.
 *
 * @param  {Object} params The save params.
 * @return {Object} The saved terms and conditions.
 */
const saveTermsAndConditionsTask = async (params) => {
    const response = await termsAndConditionsApi.saveTermsAndConditions(params);

    return response.data;
};

/////////////////////////////

export { getTermsAndConditionsTask, resetTermsAndConditionsTask, saveTermsAndConditionsTask };
