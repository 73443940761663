/* istanbul ignore file */

/** Name of the widget as saved in the layout within widgetType */
export const WIDGET_SURVEY_TYPE = 'standalone-question';

/** CSS class applied to the survey block */
export const CLASSNAME = 'survey-block';

/** Scope */
export const SCOPE = 'widget-survey';
