import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Program } from '../../types';

export enum ProgramModalFormStatus {
    openEdit = 'openEdit',
    openCreate = 'open.create',
    closed = 'closed',
}

export interface ProgramFormModalState {
    openStatus: ProgramModalFormStatus;
    /**
     * Whether the modal is loading or not
     */
    isLoading: boolean;
    /**
     * The program currently being edited
     */
    programId?: Program['id'];
}

const initialState: ProgramFormModalState = {
    openStatus: ProgramModalFormStatus.closed,
    isLoading: false,
    programId: undefined,
};

export const programFormModalReducers = {
    openModal: (state: ProgramFormModalState, action: PayloadAction<ProgramFormModalState['programId']>) => {
        set(state, 'openStatus', action?.payload ? ProgramModalFormStatus.openEdit : ProgramModalFormStatus.openCreate);
        set(state, 'programId', action.payload);
    },
    closeModal: (state: ProgramFormModalState): void => {
        set(state, 'openStatus', ProgramModalFormStatus.closed);
    },
    setLoading: (state: ProgramFormModalState, action: PayloadAction<ProgramFormModalState['isLoading']>): void => {
        set(state, 'isLoading', action.payload);
    },
};

const { actions, reducer } = createSlice({
    domain: 'programFormModal',
    initialState,
    reducers: programFormModalReducers,
});

export { actions, reducer, initialState };
