import { and } from '@lumapps/utils/function/predicate/and';
import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute, matchNode } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { Mention } from '@lumapps/wrex/serialization/dita/types';

import type { UserMentionElement } from '../../types';
import { createUserMention } from '../../utils/createUserMention';

/**
 * Convert Dita local xref into slate Mention element.
 */
export const MENTION_CONVERT: ElementConvertOption<Mention> = {
    test: and(matchNode({ name: 'xref' }), matchAttribute('type', /lumapps:user/)),
    transform: (node, children): UserMentionElement =>
        createUserMention({
            id: node.attributes.keyref,
            fullName: (children[0] as any).text,
        }),
};
