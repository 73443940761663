import React, { PureComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from '@lumapps/lumx-files/components/DragAndDrop';
import { array, func, string } from 'prop-types';
import classNames from 'classnames';
import { move } from '@lumapps/utils/array/move';

import { CustomField } from './CustomField';

/**
 * Displays a list of custom fields for a given Google CloudSearch data source.
 */
class CustomFieldList extends PureComponent {
    constructor(props) {
        super(props);

        this.moveCustomField = this.moveCustomField.bind(this);
    }

    moveCustomField(result) {
        // Dropped outside the list OR moving to same position as it already is.
        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }
        const customFields = move(this.props.customFields, result.source.index, result.destination.index);

        // Update the custom fields back in AngularJS-land.
        /*
         * Todo [Arnaud]: there is a slight flicker when dropping the item in the list, but fixing it would require to
         * move the add custom field dialog into React which is out of scope for now.
         */
        this.props.onDragEnd({ customFields });
    }

    render() {
        const { customFields, datasourceId, onDragEnd, ...otherProps } = this.props;

        return (
            <DragDropContext onDragEnd={this.moveCustomField}>
                <Droppable droppableId={`customFieldList-${datasourceId}`}>
                    {(droppableProvided) => (
                        <ol
                            ref={droppableProvided.innerRef}
                            className="bare-list"
                            {...droppableProvided.droppableProps}
                        >
                            {customFields.map((item, index) => (
                                <Draggable key={index} draggableId={item.name} index={index}>
                                    {(draggableProvided, draggableStateSnapshot) => (
                                        <li
                                            ref={draggableProvided.innerRef}
                                            className={classNames(
                                                'data-source-custom-field',
                                                draggableStateSnapshot.isDragging &&
                                                    'data-source-custom-field--is-dragging',
                                            )}
                                            {...draggableProvided.draggableProps}
                                        >
                                            <CustomField
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                                item={item}
                                                {...otherProps}
                                            />
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                        </ol>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

/////////////////////////////

CustomFieldList.propTypes = {
    /** A list of custom fields to be listed for a given data source. */
    customFields: array.isRequired,
    /** The identifier of the datasource we're listing custom fields for. */
    datasourceId: string.isRequired,
    /** Function called when a custom field is changed. */
    onChange: func.isRequired,
    /** A function to execute when a draggable item is dropped. */
    onDragEnd: func.isRequired,
    /** A function to be executed when a custom field is removed from the list. */
    onRemove: func.isRequired,
};

/////////////////////////////

export { CustomFieldList };
