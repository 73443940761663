/* istanbul ignore file */

import React, { useRef, useEffect } from 'react';

import Cropper from 'cropperjs';

import { classnames } from '@lumapps/classnames';
import { hasKey } from '@lumapps/utils/object/hasKey';

import { DragMode, CropperProps } from '../../types';

const DEFAULT_PROPS = {
    src: '',
    dragMode: DragMode.crop,
    data: null,
    scaleX: 1,
    scaleY: 1,
    enable: true,
    zoomTo: 1,
    rotateTo: 0,
};

const optionProps = [
    'dragMode',
    'aspectRatio',
    'data',
    'crop',
    // unchangeable props start from here
    'viewMode',
    'preview',
    'responsive',
    'restore',
    'checkCrossOrigin',
    'checkOrientation',
    'modal',
    'guides',
    'center',
    'highlight',
    'background',
    'autoCrop',
    'autoCropArea',
    'movable',
    'rotatable',
    'scalable',
    'zoomable',
    'zoomOnTouch',
    'zoomOnWheel',
    'wheelZoomRatio',
    'cropBoxMovable',
    'cropBoxResizable',
    'toggleDragModeOnDblclick',
    'minContainerWidth',
    'minContainerHeight',
    'minCanvasWidth',
    'minCanvasHeight',
    'minCropBoxWidth',
    'minCropBoxHeight',
    'ready',
    'cropstart',
    'cropmove',
    'cropend',
    'zoom',
];

const CLASSNAME = 'react-cropper';

/**
 * React wrapper to CropperJs library.
 * Credits: https://github.com/roadmanfong/react-cropper/blob/master/src/react-cropper.js
 */
const ReactCropper: React.FC<CropperProps> = ({
    style,
    className,
    crossOrigin,
    src = DEFAULT_PROPS.src,
    dragMode = DEFAULT_PROPS.dragMode,
    alt,
    ...props
}) => {
    const reactCropperClassname = classnames(CLASSNAME, className);
    const img = useRef<HTMLImageElement>(null);
    const imgReady = useRef(false);
    const cropper = useRef<Cropper>();

    const shouldDisable = () => {
        if (props.enable) {
            (cropper.current as Cropper).enable();
        } else {
            (cropper.current as Cropper).disable();
        }
    };

    useEffect(() => {
        const options = Object.keys(props)
            .filter((propKey) => optionProps.indexOf(propKey) !== -1)
            .reduce(
                (prevOptions, propKey) => ({
                    ...prevOptions,
                    ...(hasKey(props, propKey) ? { [propKey]: props[propKey] } : {}),
                }),
                {},
            );
        const imgNode = img.current;

        if (imgNode) {
            cropper.current = new Cropper(imgNode, {
                ...options,
                ready: () => {
                    imgReady.current = true;
                    if (props.data) {
                        (cropper.current as Cropper).setData(props.data);
                    }
                    shouldDisable();
                    props.ready(cropper.current as Cropper);
                },
            });
        }

        return () => {
            if (imgNode) {
                // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
                (cropper.current as Cropper).destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cropper.current) {
            cropper.current.reset().clear().replace(src);
        }
    }, [src]);

    /**
     * For any crop modification, we need cropper enable.
     * So we enable and disable after if required
     */
    useEffect(() => {
        if (cropper.current && props.aspectRatio !== undefined) {
            (cropper.current as Cropper).enable();
            if (Number.isNaN(props.aspectRatio)) {
                (cropper.current as Cropper).setAspectRatio(props.aspectRatio);
                cropper.current.reset();
            } else {
                (cropper.current as Cropper).setAspectRatio(props.aspectRatio);
                if (props.data) {
                    (cropper.current as Cropper).setData(props.data);
                }
            }

            if (!props.enable) {
                (cropper.current as Cropper).disable();
            }

            if (imgReady.current && props.cropend) {
                props.cropend({ detail: { action: 'ALL' } });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.aspectRatio]);

    useEffect(() => {
        shouldDisable();
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.enable]);

    useEffect(() => {
        if (props.data) {
            (cropper.current as Cropper).setData(props.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.data]);

    useEffect(() => {
        (cropper.current as Cropper).setDragMode(dragMode);
    }, [dragMode]);

    useEffect(() => {
        if (props.cropBoxData) {
            (cropper.current as Cropper).setCropBoxData(props.cropBoxData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.cropBoxData]);

    useEffect(() => {
        if (props.canvasData) {
            (cropper.current as Cropper).setCanvasData(props.canvasData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.canvasData]);

    useEffect(() => {
        if (props.moveTo) {
            // Second value is optionnal so we check if we have it.
            if (props.moveTo.length > 1) {
                (cropper.current as Cropper).moveTo(props.moveTo[0], props.moveTo[1]);
            } else {
                (cropper.current as Cropper).moveTo(props.moveTo[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.moveTo]);

    useEffect(() => {
        if (props.zoomTo) {
            (cropper.current as Cropper).zoomTo(props.zoomTo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.zoomTo]);

    useEffect(() => {
        if (props.rotateTo) {
            (cropper.current as Cropper).rotateTo(props.rotateTo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.rotateTo]);

    useEffect(() => {
        if (props.scaleX) {
            (cropper.current as Cropper).scaleX(props.scaleX);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.scaleX]);

    useEffect(() => {
        if (props.scaleY) {
            (cropper.current as Cropper).scaleY(props.scaleY);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
    }, [props.scaleY]);

    return (
        <div style={style} className={reactCropperClassname}>
            <img
                crossOrigin={crossOrigin}
                ref={img}
                src={src}
                alt={alt || 'picture'}
                style={{ opacity: 0, width: '100%' }}
            />
        </div>
    );
};

export { ReactCropper };
