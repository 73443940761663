import { connect } from '@lumapps/redux/react';

import { getShareableContentByCoreId } from 'components/components/social-advocacy/ducks/shareableContents/actions';
import { makeGetContentByContentCoreId } from 'components/components/social-advocacy/ducks/shareableContents/selectors';

import { SAShareableWarning } from './SAShareableWarning';

const mapStateToProps = (state, ownProps) => {
    const shareableContentSelector = makeGetContentByContentCoreId(state);

    return {
        isShareable: ownProps.contentId && Boolean(shareableContentSelector(ownProps.contentId.toString())),
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchShareableContent: (contentId) => dispatch(getShareableContentByCoreId(contentId)),
});

const ConnectedSAShareableWarning = connect(mapStateToProps, mapDispatchToProps)(SAShareableWarning);

export { ConnectedSAShareableWarning as SAShareableWarning };
