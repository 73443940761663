import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { CustomizationComponent, PLACEMENT, Targets, doesCustomizationExist } from '@lumapps/customizations/api';
import { MODES } from '@lumapps/customizations/modes/constants';
import { useCustomizations } from '@lumapps/customizations/useCustomizations';
import { mdiChevronLeft, mdiChevronRight } from '@lumapps/lumx/icons';
import { Divider } from '@lumapps/lumx/react';
import { MovingFocusProvider } from '@lumapps/moving-focus';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { Falsy } from '@lumapps/utils/types/Falsy';

import { ToolbarAction, Action } from './ToolbarAction';
import { useCollapseContextualActions } from './useCollapseContextualActions';

import './index.scss';

export interface ToolbarDivider {
    type: 'divider';
    key: string;
}

export const isDivider = (item: Action | ToolbarDivider): item is ToolbarDivider => (item as any).type === 'divider';
export interface ContextualActionsProps {
    /** className to be added to the wrapping div */
    className?: string;
    /** List of available actions. */
    actions: Array<Action | Falsy | ToolbarDivider> | Falsy;
}

const CLASSNAME = 'contextual-actions';

/**
 * This component provide a contextual menu in the product which regroups a list of options related to a page.
 * @family Front office
 */
export const ContextualActions: React.FC<ContextualActionsProps> = ({ className, actions }) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const [isCollapsed, toggleCollapse] = useCollapseContextualActions();

    const filteredActions = actions && (actions.filter(Boolean) as (Action | ToolbarDivider)[]);
    const isContextualActionsCustomized =
        doesCustomizationExist({ target: Targets.CONTEXTUAL_ACTIONS, placement: PLACEMENT.ABOVE }) ||
        doesCustomizationExist({ target: Targets.CONTEXTUAL_ACTIONS, placement: PLACEMENT.UNDER });

    const { modes } = useCustomizations();
    const hasNoTopBar = modes[MODES.NO_TOP_BAR];

    if (hasNoTopBar || (!isContextualActionsCustomized && (!filteredActions || filteredActions.length === 0))) {
        return null;
    }

    return (
        <MovingFocusProvider options={{ direction: 'both', loopAround: true }}>
            <div
                className={block(
                    {
                        'is-collapsed': isCollapsed,
                    },
                    [className],
                )}
                aria-label={translateKey(GLOBAL.CONTEXTUAL_ACTIONS_MENU)}
                role="toolbar"
            >
                <ToolbarAction
                    action={toggleCollapse}
                    iconSvgPath={isCollapsed ? mdiChevronRight : mdiChevronLeft}
                    labelKey={
                        isCollapsed ? GLOBAL.DISPLAY_CONTEXTUAL_ACTIONS_MENU : GLOBAL.HIDE_CONTEXTUAL_ACTIONS_MENU
                    }
                    dataAttributes={{ element: 'chevron', action: Boolean(isCollapsed).toString() }}
                    hideLabel
                />

                {!isCollapsed && (
                    <>
                        <Divider role="none" className={element('divider')} />

                        <CustomizationComponent target={Targets.CONTEXTUAL_ACTIONS} placement={PLACEMENT.ABOVE} />

                        {filteredActions &&
                            filteredActions.map((action) => {
                                if (isDivider(action)) {
                                    return <Divider key={action.key} role="separator" className={element('divider')} />;
                                }
                                return <ToolbarAction {...action} key={action.labelKey} />;
                            })}

                        <CustomizationComponent target={Targets.CONTEXTUAL_ACTIONS} placement={PLACEMENT.UNDER} />
                    </>
                )}
            </div>
        </MovingFocusProvider>
    );
};

export * from './ToolbarAction';

ContextualActions.displayName = 'ContextualActions';
