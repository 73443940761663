import React from 'react';

import { useGenerateAriaId } from '@lumapps/aria-ids/hooks/useGenerateAriaId';
import { classnames, font } from '@lumapps/classnames';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { CommunityView } from '@lumapps/communities/constants';
import { RenderingType } from '@lumapps/communities/types';
import { ContentTypes } from '@lumapps/content-types/types';
import { Heading, Link, Theme } from '@lumapps/lumx/react';
import { SiteReference } from '@lumapps/widget-base/types';

import './index.scss';

interface PostTitleProps {
    title: string;
    postId: string;
    communitySlug: string;
    communityId: string;
    site: Partial<SiteReference>;
    dataId?: string;
    target?: string;
    theme?: string;
    renderingType?: RenderingType;
}

const CLASSNAME = 'block-post-title';

const PostTitle: React.FC<PostTitleProps> = ({
    title,
    postId,
    communitySlug,
    communityId,
    renderingType,
    site,
    dataId,
    target,
    theme,
    ...props
}) => {
    const titleId = useGenerateAriaId('label');

    return (
        <Link
            id={titleId}
            color={theme === Theme.light ? 'dark' : 'light'}
            className={CLASSNAME}
            linkAs={CommunityLink}
            data-id={dataId}
            target={target}
            to={{
                id: communityId,
                slug: communitySlug,
                type: ContentTypes.POST,
                instance: { id: site?.siteId, slug: site?.slug },
                view: CommunityView.post,
                identifier: postId,
                renderingType,
            }}
            dir="auto"
            {...props}
        >
            <Heading
                as="h2"
                typography="custom-title4"
                className={classnames({
                    [font('dark', 'N')]: theme === Theme.light,
                    [font('light', 'N')]: theme === Theme.dark,
                })}
            >
                {title}
            </Heading>
        </Link>
    );
};

export { PostTitle };
