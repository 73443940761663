export enum CONTENT_STREAMS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11503482
     */
    CONTENT_STREAM = 'ADMIN.NAV.MOBILE_SETTINGS_CONTENT_STREAMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11191291
     */
    SOURCE_SITE = 'ADMIN.CUSTOMER.STREAMS.INSTANCE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11191556
     */
    VIEW_MODE = 'ADMIN.CUSTOMER.STREAMS.VIEW_MODE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11195180
     */
    VIEW_MODE_LIST = 'ADMIN.CUSTOMER.STREAMS.VIEW_MODE_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11195182
     */
    VIEW_MODE_SPOTLIGHT = 'ADMIN.CUSTOMER.STREAMS.VIEW_MODE_SPOTLIGHT',
}
