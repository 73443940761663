import { translate as t } from 'components/translations';

import { SET_LOADING_STATE, CLEAR_LOADING_STATE, SET_FAILURE_STATE } from 'components/components/ducks/loadingStates';
import { translate as gTranslate } from 'components/services/gTranslate';

// eslint-disable-next-line id-blacklist
import { error as notifyError } from 'components/services/notification';

const DOMAIN_NAMESPACE = 'admin/widget';
const ENTITY_NAME = 'widget-translation';
const ACTIONS_PREFIX = `${DOMAIN_NAMESPACE}/${ENTITY_NAME}`;

const OPEN_MODAL = `${ACTIONS_PREFIX}/OPEN_MODAL`;
const CLOSE_MODAL = `${ACTIONS_PREFIX}/CLOSE_MODAL`;

const SET_SOURCE_LANG = `${ACTIONS_PREFIX}/SET_SOURCE_LANG`;

/**
 * Dispatch the OPEN_MODAL action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const openModal = () => (dispatch) =>
    dispatch({
        type: OPEN_MODAL,
    });

/**
 * Dispatch the CLOSE_MODAL action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const closeModal = () => (dispatch) =>
    dispatch({
        type: CLOSE_MODAL,
    });

/**
 * Dispatch the SET_SOURCE_LANG action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setSourceLang = ({ sourceLang }) => (dispatch) =>
    dispatch({
        payload: {
            sourceLang,
        },
        type: SET_SOURCE_LANG,
    });

const requestTranslation = ({ sourceLang, targetLang, content, isContentHTML, onSuccess, onError }) => (dispatch) => {
    dispatch({
        component: 'widgetTranslation',
        type: SET_LOADING_STATE,
    });

    gTranslate(
        content,
        targetLang,
        sourceLang,
        (response) => {
            dispatch({
                component: 'widgetTranslation',
                type: CLEAR_LOADING_STATE,
            });
            onSuccess(response);
        },
        (err) => {
            notifyError(t(err && err.data && err.data.error && err.data.error.message));
            dispatch({
                component: 'widgetTranslation',
                type: err.status === -1 ? CLEAR_LOADING_STATE : SET_FAILURE_STATE,
            });
            if (onError) {
                onError(err);
            }
        },
        isContentHTML,
    );
};

export { OPEN_MODAL, openModal, CLOSE_MODAL, closeModal, SET_SOURCE_LANG, setSourceLang, requestTranslation };
