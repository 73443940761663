/**
 * Min width of the category component inside the catalog.
 */
export const MIN_WIDTH_CATALOG_CATEGORY = 190;

/**
 * Max number of collumns in our catalog grid
 */
export const DEFAULT_MAX_NUMBER_COLUMNS_CATALOG_GRID = 3;

export const SCOPE = 'category';
