export enum WIDGET_SETTINGS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=224896814
     */
    DOUBLE_CLICK_INFO = 'WIDGET_CONTRIBUTION.SETTINGS_DOUBLE_CLICK_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11191556
     */
    VIEW_MODE = 'ADMIN.CUSTOMER.STREAMS.VIEW_MODE',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=414583247
     */
    RESTRICTIONS_SECTION_TITLE = 'WIDGET_SETTINGS.RESTRICTIONS_SECTION_TITLE',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=414643832
     */
    FOLLOWED_COMMUNITIES_ONLY = 'WIDGET_SETTINGS.FOLLOWED_COMMUNITIES_ONLY',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=415045718
     */
    DISABLED_FOLLOWED_COMMUNITIES_FILTER_HELPER = 'WIDGET_EVENT_LIST.SETTINGS_DISABLED_FOLLOWED_COMMUNITIES_FILTER_HELPER',
}
