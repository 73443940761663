import { TranslateObject } from '@lumapps/translations';
import { LegacyWidgetProperties } from '@lumapps/widget-base/types';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

export enum BlockVideoSource {
    drive = 'drive',
    embed = 'embed',
}

export interface LegacyVideoProperties {
    /** embed code of the video */
    embed?: TranslateObject;
    /** url in the drive case to we must embed in an iframe widget manually */
    embedUrl?: string;
    /** represents the encoding state of the video on the drive side */
    encoded?: boolean;
    /** disables fallback if the embed video is not available in the main language */
    noFallback?: LegacyWidgetProperties['noFallback'];
    /** id of the picked video in the drive case */
    pickedId?: string;
    /** url of the picked video in the drive case */
    pickedUrl?: string;
    /** name of the picked video in the drive case */
    pickedName?: string;
    /** source is either an iframe from tube site (value is "embed") or a Google drive (value is "drive") */
    source?: BlockVideoSource;
    /** style property of the widget forwarded here */
    style?: LegacyWidgetProperties['style'];
    noResults?: LegacyWidgetProperties['noResults'];
    helper?: LegacyWidgetProperties['helper'];
}

export interface ClientComputedVideoProps extends ClientComputedProps {
    properties?: LegacyVideoProperties;
}
