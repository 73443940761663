export enum WIDGET_PERSONAL_FEED_PREVIEW {
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=437178532
     */
    ACCESS_FEED = 'WIDGET_PERSONAL_FEED_PREVIEW.ACCESS_FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441901399
     */
    CONTEXTUAL_BANNER = 'WIDGET_PERSONAL_FEED_PREVIEW.CONTEXTUAL_BANNER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=436998489
     */
    SEE_MORE_IN_YOUR_FEED = 'WIDGET_PERSONAL_FEED_PREVIEW.SEE_MORE_IN_YOUR_FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441900279
     */
    WELCOME_MESSAGE = 'WIDGET_PERSONAL_FEED_PREVIEW.WELCOME_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441901284
     */
    WELCOME_MESSAGE_DESCRIPTION = 'WIDGET_PERSONAL_FEED_PREVIEW.WELCOME_MESSAGE_DESCRIPTION',
}
