import { connect } from '@lumapps/redux/react';

import WidgetChart from './WidgetChart';

import { initState, getChart, resetState, setWidgetWidth } from '../../chart/_ducks/chart_action';

/**
 * Map the redux store properties to the WidgetChart component.
 *
 * @param  {Object} state    The Redux Store.
 * @param  {Object} ownProps The WidgetChart props.
 * @return {Object} The mapped props.
 */
const mapStateToProps = (state, ownProps) => {
    if (!ownProps.uuid) {
        return {};
    }

    const widgetScope = state.widgets.chart.find((widget) => widget.uuid === ownProps.uuid);

    if (widgetScope !== undefined) {
        return {
            isLoading: widgetScope.isLoading,
            chartImageUrl: widgetScope.chartImageUrl,
            widgetWidth: widgetScope.widgetWidth,
        };
    }

    return {};
};

const ConnectedWidgetChart = connect(mapStateToProps, {
    initState,
    getChart,
    resetState,
    setWidgetWidth,
})(WidgetChart);

export { ConnectedWidgetChart as WidgetChart };
