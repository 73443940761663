/**
 * @param array - an array of [(hours,)minutes,seconds]
 * @returns a string of numbers, separated with ':', prefixed with '0' if number < 10
 */
const formatArray = (array: (string | number)[]): string => {
    return array.join(':');
};

/**
 * @param number - the number as string
 * @returns - the number prefixed with 0 when number < 10
 */
const prefixNumbersWith0 = (number: string): string => {
    return number.replace(/\b(\d)\b/g, '0$1');
};

/**
 * @param milliSeconds - The duration to transform (999)
 * @returns the duration as (00:)01:02
 */
const formatTime = (milliSeconds: number | null, withLetters = false): string | null => {
    if (milliSeconds) {
        const hours = Math.floor(milliSeconds / 60 / 60) || null;
        const minutes = Math.floor((milliSeconds / 60) % 60).toString();
        const seconds = Math.floor(milliSeconds % 60).toString();
        const formattedSeconds = prefixNumbersWith0(seconds);
        if (hours) {
            const formattedMinutes = prefixNumbersWith0(minutes);
            return withLetters
                ? `${hours}h ${formattedMinutes}min`
                : formatArray([hours, formattedMinutes, formattedSeconds]);
        }
        if (withLetters) {
            if (minutes !== '0') {
                return `${minutes}min ${formattedSeconds}s`;
            }
            return `${formattedSeconds}s`;
        }
        return formatArray([minutes, formattedSeconds]);
    }
    if (withLetters) {
        return '0min';
    }
    return null;
};

export { formatTime };
