import { gTranslate } from '@lumapps/auto-translation';
import { GetFrontOfficeState } from '@lumapps/redux/types';
import { getInstanceColorPalette } from '@lumapps/style/ducks/selectors';

import { WidgetState } from '../ducks/type';
import { isBlockHtml, isBlockIntro, isBlockStructuredContent } from './blockPredicates';

export const getWidgetTranslatedData = async (
    widget: Partial<WidgetState>,
    widgetType: string,
    targetLang: string,
    getState: GetFrontOfficeState,
) => {
    const { header, body } = widget;

    const state = getState();
    const colorPalette = getInstanceColorPalette(state);

    return Promise.all([
        header && header.label ? gTranslate({ content: header.label, targetLang }) : null,
        body && isBlockIntro(body, widgetType) && body.text ? gTranslate({ content: body.text, targetLang }) : null,
        body && isBlockHtml(body, widgetType) && body.html
            ? gTranslate({ content: body.html, targetLang, isContentHTML: true })
            : null,
        /** To remove after DITA->Slate migration */
        body && isBlockStructuredContent(body, widgetType) && body.ditaHtmlValues && !body.richText
            ? gTranslate({
                  content: body.ditaHtmlValues,
                  targetLang,
                  isContentHTML: true,
              })
            : null,
        /** End: To remove after DITA->Slate migration */
        import('@lumapps/wrex/serialization/slate/utils/htmlValuesToSlate'),
        body && isBlockStructuredContent(body, widgetType) && body.slateHtml && body.richText
            ? gTranslate({
                  content: body.slateHtml,
                  targetLang,
                  isContentHTML: true,
              })
            : null,
    ]).then(([label, text, html, structuredContentHtml, htmlValuesToSlate, slateHtml]) => {
        const widgetTranslatedData: Partial<WidgetState> = {};

        if (label) {
            widgetTranslatedData.header = { label };
        }

        if (text) {
            widgetTranslatedData.body = { text } as any;
        }

        if (html) {
            widgetTranslatedData.body = { html } as any;
        }

        if (body && isBlockStructuredContent(body, widgetType)) {
            if (slateHtml && body.richText?.children) {
                const translatedRichText = htmlValuesToSlate.htmlValuesToSlate(body.richText.children, slateHtml, {
                    colorPalette,
                });

                widgetTranslatedData.body = {
                    translatedRichText,
                } as any;
            } else if (structuredContentHtml) {
                /** To remove after DITA->Slate migration */
                widgetTranslatedData.body = {
                    ditaHtmlValues: structuredContentHtml,
                } as any;
                /** End: To remove after DITA->Slate migration */
            }
        }

        return widgetTranslatedData;
    });
};
