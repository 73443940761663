import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { mdiHome, mdiChevronRight } from '@lumapps/lumx/icons';
import { Emphasis, Size, IconButton, Icon, Theme } from '@lumapps/lumx/react';
import { Navigation } from '@lumapps/navigation/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { BreadcrumbElement } from '../BreadcrumbElement';

import './index.scss';

export interface BreadcrumbProps {
    className: string;
    currentContent: {
        id: string;
        slug?: string;
        title: string;
    };
    home: {
        title: string;
        id: string;
    };
    breadcrumb?: Navigation;
    theme?: Theme;
}

/**
 * Breadcrumb widget. Display recursively the path using `children` attribute.
 * If there is no initial children, display the home then the current content.
 */
export const Breadcrumb: React.FC<BreadcrumbProps> = ({ className, currentContent, home, breadcrumb, theme }) => {
    const { element } = useClassnames(className);
    const { translateKey } = useTranslate();

    return (
        <nav aria-label={translateKey(GLOBAL.BREADCRUMB)}>
            <ol className={element('wrapper')}>
                <li className={element('list-item')}>
                    <IconButton
                        linkAs={ContentLink}
                        icon={mdiHome}
                        size={Size.m}
                        to={{ id: home.id, isHomePage: true }}
                        hasBackground={false}
                        emphasis={Emphasis.low}
                        label={home.title}
                        className={element('home')}
                        theme={theme}
                    />

                    <Icon
                        className={element('separator')}
                        icon={mdiChevronRight}
                        size={Size.xs}
                        theme={theme}
                        aria-hidden="true"
                    />
                </li>

                {/**
                 * Recursively display the breadcrumb path iterating trough the children
                 */}
                {breadcrumb?.children && breadcrumb.children.length > 0 ? (
                    <BreadcrumbElement className={className} node={breadcrumb.children[0]} theme={theme} />
                ) : (
                    <li>
                        <ContentLink
                            className={classnames(element('link'), element('link', 'last'))}
                            to={{
                                id: currentContent.id,
                                slug: currentContent.slug,
                            }}
                            theme={theme}
                            aria-current="page"
                        >
                            {currentContent.title}
                        </ContentLink>
                    </li>
                )}
            </ol>
        </nav>
    );
};

Breadcrumb.displayName = 'ClientComputedBreadcrumb';
