/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bool, func, number } from 'prop-types';
import classNames from 'classnames';

import { translate as t } from '../../../translations';
import * as inputTypes from './types';

/**
 * Renders a choice element for `<Select />`.
 *
 * @return {Component} The Choice component.
 */
const Choice = ({ ChoiceComponent, hasSelectedStyle, id, isError, isSelected, onClick, value }) => {
    return (
        <li
            className={classNames('dropdown-link custom-select__choice', {
                'custom-select__choice--is-active': isSelected && hasSelectedStyle,
            })}
            id={id}
            onClick={onClick}
        >
            {ChoiceComponent && (
                <ChoiceComponent isError={isError} isSelected={isSelected} label={value.label} value={value.value} />
            )}
            <span className="custom-select__choice-label">{t(value.label)}</span>
        </li>
    );
};

Choice.propTypes = {
    /** Custom choice component. */
    ChoiceComponent: func,
    /** Prevent selected style to be applied - useful with multiselect behavior. */
    hasSelectedStyle: bool,
    /** The index of the choice within a list of choices. */
    id: number.isRequired,
    /* * Indicates if the field contains errors (Used for langague selector). */
    isError: bool,
    /** Whether this choice is selected or not. */
    isSelected: bool,
    /** Triggered when clicking on the element. */
    onClick: func.isRequired,
    /** The choice value to render. */
    value: inputTypes.choice.isRequired,
};

Choice.defaultProps = {
    ChoiceComponent: null,
    hasSelectedStyle: false,
    isError: false,
    isSelected: false,
};

export { Choice };
