import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isNewsletterAllowed } from '../../ducks/selectors';
import { NewsletterSettingsMenu } from './NewsletterSettingsMenu';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isNewsletterAllowed(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const Connected = connect(mapStateToProps, mapDispatchToProps)(NewsletterSettingsMenu);

export { Connected as NewsletterSettingsMenu };
