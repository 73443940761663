function InputLanguageController($rootScope, Config, Customer, Instance, Translation) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Instance = Instance;
    vm.Translation = Translation;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Set the default parameters of the directive.
     */
    function _setDefaultParams() {
        vm.color = vm.color || 'dark';
        vm.emphasis = vm.emphasis || 'medium';
        vm.fullLang = angular.isUndefined(vm.fullLang) ? true : vm.fullLang;
        vm.languageType = vm.isCustomer ? Translation.CUSTOMER_LANGUAGE_TYPE : Translation.INSTANCE_LANGUAGE_TYPE;
        vm.position = vm.position || 'right';
        vm.type = vm.type || 'button';
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Select a new language.
     *
     * @param {string} language The new selected value for the input language.
     */
    function selectLanguage(language) {
        if (Translation[vm.languageType] === language) {
            return;
        }

        Translation[vm.languageType] = language;

        $rootScope.$broadcast(vm.languageType, {
            identifier: vm.identifier,
            lang: Translation[vm.languageType],
        });
    }

    /////////////////////////////

    vm.selectLanguage = selectLanguage;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _setDefaultParams();

        vm.languageChoices = vm.isCustomer
            ? Customer.getProperty(Config.INSTANCE_PROPERTIES.AVAILABLE_LANGS)
            : Instance.getInstance().langs;
    }

    init();
}

/////////////////////////////

/**
 * A directive that allows to switch between different languages.
 *
 * @param {string}  [color='dark']      The color of the toggle button (if not link type).
 * @param {string}  [emphasis='medium'] The emphasis of the toggle button (if not link type).
 * @param {boolean} [fullLang=false]    Indicates if we want to display the full name of the lang when not showing
 *                                      the flag.
 * @param {boolean} [hasIcon=false]     Indicates if the selector has a decorator icon.
 * @param {string}  [identifier]        The identifier of the input language.
 * @param {boolean} [isCustomer=false]  Indicates if the selector uses the available languages of the customer.
 * @param {string}  [position='right']  The position of the dropdown when it's open.
 * @param {string}  [type='button']     The type of the toggle button.
 *                                      Can be one of: 'button', 'link'.
 */

function InputLanguageDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: InputLanguageController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            color: '@?',
            emphasis: '@?',
            fullLang: '<?',
            hasIcon: '<?',
            hasMenuIcon: '<?',
            identifier: '@?',
            isCustomer: '<?',
            position: '@?',
            type: '@?',
        },
        templateUrl: '/client/common/modules/input-language/views/input-language.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('inputLanguage', InputLanguageDirective);

/////////////////////////////

export { InputLanguageDirective };
