import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { AsyncMenu } from '@lumapps/lumx-menu/components/AsyncMenu';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiCheckboxMultipleBlank } from '@lumapps/lumx/icons';
import { Placement, Offset } from '@lumapps/lumx/react';
import { addBaseSlug } from '@lumapps/router/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { searchInstances } from '../../api';
import { INSTANCE } from '../../keys';
import { Instance } from '../../types';

import './index.scss';

export type FavoriteSitesProps = {
    /** The dropdown placement */
    placement?: Placement;
    /** The dropdown offset */
    offset?: Offset;
    /** custom styles passed down to the icon */
    styles?: Record<string, any>;
    /** current instance ID */
    instanceId: string;
};

const CLASSNAME = 'favorite-sites';
const SCOPE = 'favorite-sites';

export const FavoriteSites: React.FC<FavoriteSitesProps> = ({
    offset = {},
    styles = {},
    placement = Placement.AUTO_START,
    instanceId,
}) => {
    const { translateKey, translate } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <AsyncMenu
            id={CLASSNAME}
            className={block()}
            offset={offset}
            placement={placement}
            styles={styles}
            width={{ minWidth: REAL_SIZE_FOR_LUMX_SIZE.xxl }}
            scope={SCOPE}
            icon={mdiCheckboxMultipleBlank}
            get={searchInstances}
            name={translateKey(GLOBAL.FAVORITE_SITES)}
            emptyMessage={translateKey(INSTANCE.FAVORITES_NO_ENTRIES)}
        >
            {({ items }) => {
                return items?.map((item: Instance) => (
                    <Menu.Item
                        key={item.id}
                        className={element('item', { 'is-highlighted': instanceId === item.id })}
                        as="a"
                        href={addBaseSlug(item.slug, false)}
                        target="_blank"
                    >
                        {translate(item.title) || item.name}
                    </Menu.Item>
                ));
            }}
        </AsyncMenu>
    );
};
