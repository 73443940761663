/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyPostDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'post-dialog' */
            './ConnectedPostDialog'
        ),
);

export const PostDialog = (props: any) => {
    const { isOpen, ...otherProps } = props;

    return (
        <DelayedSuspense shouldLoad={isOpen}>
            {(setShouldMount) => <LazyPostDialog isOpen={isOpen} onVisibilityChange={setShouldMount} {...otherProps} />}
        </DelayedSuspense>
    );
};
