/* istanbul ignore file */
import { getInstanceLogoProps } from '@lumapps/instance/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { InstanceLogo } from './InstanceLogo';

const mapStateToProps = (state: FrontOfficeStore) => {
    const props = getInstanceLogoProps(state);

    return props;
};

const mapDispatchToProps = {};

const ConnectedInstanceLogo = connect(mapStateToProps, mapDispatchToProps, undefined, { forwardRef: true })(
    InstanceLogo,
);

export { ConnectedInstanceLogo as InstanceLogo };
