(function IIFE() {
    'use strict';

    /////////////////////////////

    function NoResultsController(Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The full list of CSS classes of the widget.
         *
         * @type {Array}
         */
        vm.classes = [];

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            if (vm.hasBackground) {
                vm.classes.push('no-results--has-background');
            }

            vm.text = vm.text || Translation.translate('NO_RESULTS');
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive to display a 'no results' message after an async call for example.
     *
     * @param {boolean} hasBackground Indicates if the container has a solid background or is transparent.
     */

    function NoResultsDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: NoResultsController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                hasBackground: '<?',
                text: '@?',
            },
            templateUrl: '/client/common/modules/no-results/views/no-results.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('noResults', NoResultsDirective);
})();
