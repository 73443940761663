import first from 'lodash/first';
import includes from 'lodash/includes';

/////////////////////////////

function LsTextEditorMarkdownService() {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Template string to emulate template.
     *
     * @type {string}
     */
    const _REPLACE_TEMPLATE = '{$VALUE}';

    /**
     * Title prefix pattern.
     *
     * @type {string}
     */
    const TITLE_PREFIX_PATTERN = '^#+ (.*)$';

    /**
     * Text action types.
     *
     * @type {Object}
     */
    const _headerTextAction = {
        insert: 'insert',
        wrap: 'wrap',
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Markdown possible actions.
     *
     * @type {Object}
     */
    service.MARKDOWN_CONFIG = {
        'FRONT.TEXT_EDITOR.HEADINGS.HEADING1': {
            action: _headerTextAction.insert,
            prefixLine: true,
            value: `# ${_REPLACE_TEMPLATE}`,
        },
        'FRONT.TEXT_EDITOR.HEADINGS.HEADING2': {
            action: _headerTextAction.insert,
            prefixLine: true,
            value: `## ${_REPLACE_TEMPLATE}`,
        },
        'FRONT.TEXT_EDITOR.HEADINGS.NORMAL_TEXT': {
            action: _headerTextAction.insert,
            prefixLine: true,
            value: `${_REPLACE_TEMPLATE}`,
        },
        bold: {
            action: _headerTextAction.wrap,
            value: `**${_REPLACE_TEMPLATE}**`,
        },
        bulletList: {
            action: _headerTextAction.insert,
            newLine: true,
            value: `- ${_REPLACE_TEMPLATE}`,
        },
        image: {
            action: _headerTextAction.insert,
            avoidSplitParagraph: true,
            newLine: true,
        },
        italic: {
            action: _headerTextAction.wrap,
            value: `_${_REPLACE_TEMPLATE}_`,
        },
        link: {
            action: _headerTextAction.wrap,
            value: `[${_REPLACE_TEMPLATE}](url)`,
        },
    };

    /**
     * Available action enumeration.
     *
     * @type {Object}
     */
    service.MARKUP_ACTIONS = {
        bold: 'bold',
        bulletList: 'bulletList',
        image: 'image',
        italic: 'italic',
        link: 'link',
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get offset generated by the wrapper.
     *
     * @param  {Object} config The action configuration.
     * @return {Object} An object with the start and end offset.
     */
    function _getWrapperOffset(config) {
        const wrappers = config.value.split(_REPLACE_TEMPLATE);

        // If end is undefined it take the start value, so the caret is well positioned.
        return {
            end: wrappers[1] ? wrappers[1].length : wrappers[0].length,
            endVal: wrappers[1],
            start: angular.isString(wrappers[0]) ? wrappers[0].length : 0,
            startVal: wrappers[0],
        };
    }

    /**
     * Return the textarea selection content.
     *
     * @param  {Object} textarea The textarea element to perform the action.
     * @return {string} The selection.
     */
    function _getSelection(textarea) {
        return textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    }

    /**
     * Check if the selection is fully wrapped.
     * Ex: **test** || _test_ are fully wrapped.
     *
     * @param  {Object} textarea The textarea where to perform action.
     * @param  {Object} config   The action configuration.
     * @return {string} Return the wrapped string.
     */
    function _isFullyWrapped(textarea, config) {
        const selection = _getSelection(textarea);
        if (angular.isUndefinedOrEmpty(selection)) {
            return undefined;
        }

        const reg = new RegExp(`^${config.value.replace(/[-*]/g, '\\$&').replace(_REPLACE_TEMPLATE, '(.*)')}$`, 'gm');
        const match = reg.exec(selection);

        // If match full pattern.
        if (angular.isDefinedAndFilled(match) && angular.isString(match[1])) {
            return match[1];
        }

        return undefined;
    }

    /**
     * Check if the selection is prefixed.
     *
     * @param  {Object}  textarea The textarea where to perform action.
     * @return {boolean} Return the wrapped string.
     */
    function _isPrefixed(textarea) {
        const selection = _getSelection(textarea);
        if (angular.isUndefinedOrEmpty(selection)) {
            return false;
        }

        const reg = new RegExp(TITLE_PREFIX_PATTERN, 'gm');
        const match = reg.exec(selection);

        // If match full pattern.
        if (angular.isDefinedAndFilled(match) && angular.isString(match[1])) {
            return true;
        }

        return false;
    }

    /**
     * Insert text at wanted position, can replace in case of selection.
     *
     * @param {Object} textarea The target textarea.
     * @param {string} content  The content to insert.
     * @param {number} [start]  The start position.
     * @param {number} [end]    The end position.
     */
    function _textInsert(textarea, content, start, end) {
        start = angular.isNumber(start) ? start : textarea.selectionStart;
        end = angular.isNumber(end) ? end : textarea.selectionEnd;

        textarea.value =
            textarea.value.substring(0, start) + content + textarea.value.substring(end, textarea.value.length);
    }

    /**
     * Giving an index, returns the corresponding line content.
     *
     * @param  {Object} textarea  The target textarea.
     * @param  {number} lineIndex The index to decide which line should be picked.
     * @return {string} The content of the line corresponding to the passed index.
     */
    function _getLineContent(textarea, lineIndex) {
        const startOfLine = textarea.value.slice(0, lineIndex).lastIndexOf('\n') + 1;
        const endOfLine =
            textarea.value.indexOf('\n', startOfLine) > -1
                ? textarea.value.indexOf('\n', startOfLine)
                : textarea.value.length;

        return textarea.value.slice(startOfLine, endOfLine);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     *  Format a link to use rich text markdown.
     *
     * @param {string} [link]          The link entered in the add link dialog.
     * @param {Object} richTextControl The rich text editor controls to edit markdown.
     */
    function formatLink(link, richTextControl) {
        if (!service.hasSelectedText(richTextControl) || angular.isUndefinedOrEmpty(richTextControl)) {
            return;
        }

        const textarea = first(richTextControl.textAreaElement);

        link = link || service.getWrappedLink(textarea);

        // Wrap the selected content into a markdown link -> [content](url).
        const config = angular.fastCopy(service.MARKDOWN_CONFIG.link);

        // Replace url by the one entered.
        config.value = config.value.replace('url', link);
        richTextControl.triggerAction('link', config);
    }

    /**
     * Return the wrapped link in the textarea markdown based on the selection.
     *
     * @param  {Object} textarea The current textarea.
     * @return {string} The link found, undefined if no link found.
     */
    function getWrappedLink(textarea) {
        let selection = _getSelection(textarea);

        // Escape characters that may interfere with regexp expression.
        selection = selection.replace(/([.*+?^=!:${}()|[\]\\/\\])/g, '\\$1');

        const reg = new RegExp(`\\[${selection}\\]\\(([^ \n]*)\\)`);
        const match = reg.exec(textarea.value);

        if (angular.isDefinedAndFilled(match) && angular.isString(match[1])) {
            return match[1];
        }

        return undefined;
    }

    /**
     * Use to determine if a text is currently selected in the textarea.
     *
     * @param  {Object}  richTextControl The rich text editor controls.
     * @return {boolean} True if a text is highlighted in the textarea.
     */
    function hasSelectedText(richTextControl) {
        if (angular.isUndefinedOrEmpty(richTextControl)) {
            return false;
        }

        const textarea = first(richTextControl.textAreaElement);

        return textarea.selectionStart !== textarea.selectionEnd;
    }

    /**
     * Check if the selected text is already wrapped as link.
     *
     * @param  {Object}  richTextControl The text element controls.
     * @return {boolean} True if the selected text is wrapped.
     */
    function isSelectionLinkWrapped(richTextControl) {
        const textarea = first(richTextControl.textAreaElement);
        const link = service.getWrappedLink(textarea);
        const config = angular.fastCopy(service.MARKDOWN_CONFIG.link);

        config.value = config.value.replace('url', link);

        return service.isWrapped(textarea, config);
    }

    /**
     * Check if the text is fully wrapped with the wrapper string.
     *
     * @param  {Object}  textarea The textarea to perform action.
     * @param  {Object}  config   The action configuration.
     * @return {boolean} If the text is fully wrapped with wrapper.
     */
    function isWrapped(textarea, config) {
        // Work with: `**test**` is selected or `test` wrapped between **.

        if (
            (config.prefixLine && _isPrefixed(textarea, config)) ||
            angular.isString(_isFullyWrapped(textarea, config))
        ) {
            return true;
        }

        const offset = _getWrapperOffset(config);

        return (
            textarea.value.substr(textarea.selectionStart - offset.start, offset.start) === offset.startVal &&
            textarea.value.substr(textarea.selectionEnd, offset.end) === offset.endVal
        );
    }

    /**
     * Remove the wrapper from the selection (remove markdown).
     * Assume that isWrapped is called before this call.
     *
     * @param {Object} textarea The textarea element to perform the action.
     * @param {string} config   The action configuration.
     */
    function unWrap(textarea, config) {
        // Start and end are value before text modification.
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        const offset = _getWrapperOffset(config);

        const selection = _getSelection(textarea);

        // In the case of a bullet list, remove the bullet points from all the lines.
        const prefix = offset.startVal.replace(/[-*]/g, '\\$&');
        const suffix = offset.endVal.replace(/[-*]/g, '\\$&');
        const regexp = new RegExp(`^${prefix}(.*)${suffix}$`, 'gm');

        let unwrapedSelection = selection.replace(regexp, '$1');
        // When removing an empty bullet point, also remove the newline.
        if (unwrapedSelection === '\n') {
            unwrapedSelection = '';
        }

        _textInsert(textarea, unwrapedSelection, start, end);
        textarea.selectionStart = start;
        textarea.selectionEnd = start + unwrapedSelection.length;
    }

    /**
     * If in a word or at the beginning/end capture it.
     *
     * @param {Object} textarea The textarea element to perform the action.
     */
    function selectCloseElement(textarea) {
        const breaker = ['\r\n', '\r', '\n', ' '];

        while (!includes(breaker, textarea.value[textarea.selectionStart - 1]) && textarea.selectionStart > 0) {
            textarea.selectionStart--;
        }

        while (
            !includes(breaker, textarea.value[textarea.selectionEnd]) &&
            textarea.selectionEnd < textarea.value.length
        ) {
            textarea.selectionEnd++;
        }
    }

    /**
     * If in a word or at the beginning/end capture it.
     *
     * @param {Object} textarea The textarea element to perform the action.
     */
    function selectLine(textarea) {
        const breaker = ['\n'];

        while (!includes(breaker, textarea.value[textarea.selectionStart - 1]) && textarea.selectionStart > 0) {
            textarea.selectionStart--;
        }

        while (
            !includes(breaker, textarea.value[textarea.selectionEnd]) &&
            textarea.selectionEnd < textarea.value.length
        ) {
            textarea.selectionEnd++;
        }
    }

    /**
     * Strip all markdown from a string.
     *
     * @param  {string} text    The text we want to strip markdown from.
     * @param  {Object} options The options for the stripping.
     *                              - [stripListLeaders=true] {boolean} Indicates if we want to strip the list
     *                                                                  headers.
     *                              - [gfm=true]              {boolean} Indicates if we want to also strip Github
     *                                                                  Flavored Markdown.
     *
     * @return {string} The text stripped from the markdown.
     */
    function stripMarkdown(text, options) {
        options = options || {};
        options.stripListLeaders = angular.isUndefined(options.stripListLeaders) ? true : options.stripListLeaders;
        options.gfm = angular.isUndefined(options.gfm) ? true : options.gfm;

        let output = text;

        try {
            if (options.stripListLeaders) {
                output = output.replace(/^([\s\t]*)([*\-+]|\d\.)\s+/gm, '$1');
            }
            if (options.gfm) {
                // Header.
                output = output.replace(/\n={2,}/g, '\n');

                // Strikethrough.
                output = output.replace(/~~/g, '');

                // Fenced codeblocks.
                output = output.replace(/`{3}.*\n/g, '');
            }

            // Remove HTML tags.
            output = output.replace(/<(.*?)>/g, '$1');

            // Remove setext-style headers.
            output = output.replace(/^[=-]{2,}\s*$/g, '');

            // Remove footnotes.
            output = output.replace(/\[\^.+?\](: .*?$)?/g, '');
            output = output.replace(/\s{0,2}\[.*?\]: .*?$/g, '');

            // Remove images.
            output = output.replace(/!\[.*?\][[(].*?[\])]/g, '');

            // Remove inline links.
            output = output.replace(/\[(.*?)\][[(].*?[\])]/g, '$1');

            // Remove Blockquotes.
            output = output.replace(/>/g, '');

            // Remove reference-style links.
            output = output.replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '');

            // Remove atx-style headers.
            output = output.replace(/#{1,6}\s*([^#]*)\s*(#{1,6})?/gm, '$1');
            output = output.replace(/([*_]{1,3})(.*?)\1/g, '$2');
            output = output.replace(/(`{3,})(.*?)\1/gm, '$2');
            output = output.replace(/^-{3,}\s*$/g, '');
            output = output.replace(/`(.+?)`/g, '$1');
            output = output.replace(/\n{2,}/g, '\n\n');
        } catch (exception) {
            return text;
        }

        return output;
    }

    /**
     * Wrap the selection with the specified wrapper.
     *
     * @param {Object} textarea The textarea element to perform the action.
     * @param {Object} config   The action configuration.
     */
    function wrap(textarea, config) {
        const isBulletList = config.value === service.MARKDOWN_CONFIG.bulletList.value;

        // Save position before insert.
        let start = textarea.selectionStart;
        let end = textarea.selectionEnd;

        const offset = _getWrapperOffset(config);

        const selection = _getSelection(textarea);

        const splittedSelection = angular.isDefinedAndFilled(selection) ? selection.split('\n') : [];
        let content;
        // When wrapping a bullet point, wrap each line separately.
        if (angular.isDefinedAndFilled(selection) && !config.avoidSplitParagraph) {
            content = '';
            angular.forEach(splittedSelection, (line, index) => {
                content += config.value.replace(_REPLACE_TEMPLATE, line);

                if (index < splittedSelection.length - 1) {
                    content += '\n';
                }
            });
        } else {
            content = config.value.replace(_REPLACE_TEMPLATE, '');
        }

        const isNewLine = textarea.value.substr(textarea.selectionStart - 1, 1) === '\n';

        // Check if a new line is needed.
        let contentToInsert = content;
        if (
            config.newLine &&
            angular.isDefinedAndFilled(textarea.value) &&
            textarea.value !== selection &&
            !isNewLine
        ) {
            contentToInsert = `\n${contentToInsert}`;
            // Increment due to new char.
            offset.start += 1;
            offset.end += 1;
        }

        if (config.avoidSplitParagraph) {
            end = start;
            const startOfParagraph = start === 0 || textarea.value[start - 1] === '\n';
            if (!startOfParagraph) {
                const nextParagraph = textarea.value.indexOf('\n', start);
                start = nextParagraph === -1 ? textarea.value.length : nextParagraph + 1;
                end = start;
            }
        }

        _textInsert(textarea, contentToInsert, start, end);

        if (config.avoidSplitParagraph) {
            textarea.selectionStart = start;
            textarea.selectionEnd = end;
        } else if (content === offset.startVal + offset.endVal && !config.newLine) {
            // When adding markdown without any selection, place the cursor between the begin and end marker.
            textarea.selectionStart = start + offset.start;
            textarea.selectionEnd = textarea.selectionStart;
        } else {
            textarea.selectionEnd =
                end + (splittedSelection.length || 1) * offset.end + (splittedSelection.length || 1) * offset.end;

            const valueToReplace = config.value.replace(_REPLACE_TEMPLATE, '');
            // In the case of an empty bullet point, simply move the carret after the bullet.
            if (isBulletList && content === valueToReplace) {
                textarea.selectionStart = textarea.selectionEnd;
                // Else select all the wrapped content.
            } else {
                textarea.selectionStart = start;
            }
        }
    }

    /**
     * Wrap the selection with the specified wrapper.
     *
     * @param {Object} textarea The textarea element to perform the action.
     * @param {Object} config   The action configuration.
     */
    function prefixLine(textarea, config) {
        // Save position before insert.
        const start = textarea.selectionStart;

        const offset = _getWrapperOffset(config);

        const selection = _getSelection(textarea);
        const splittedSelection = angular.isDefinedAndFilled(selection) ? selection.split('\n') : [];

        let content;
        // When wrapping a bullet point, wrap each line separately.
        if (angular.isDefinedAndFilled(selection)) {
            content = '';
            angular.forEach(splittedSelection, (line, index) => {
                const reg = new RegExp(TITLE_PREFIX_PATTERN, 'gm');
                const match = reg.exec(line);
                const unprefixedLine = match ? match[1] : line;
                if (line.length > 0) {
                    content += config.value.replace(_REPLACE_TEMPLATE, unprefixedLine);
                }

                if (index < splittedSelection.length - 1) {
                    content += '\n';
                }
            });
        } else {
            content = config.value.replace(_REPLACE_TEMPLATE, '');
        }

        const isNewLine = textarea.value.substr(textarea.selectionStart - 1, 1) === '\n';

        // Check if a new line is needed.
        let contentToInsert = content;
        if (
            config.newLine &&
            angular.isDefinedAndFilled(textarea.value) &&
            textarea.value !== selection &&
            !isNewLine
        ) {
            contentToInsert = `\n${contentToInsert}`;
            // Increment due to new char.
            offset.start += 1;
            offset.end += 1;
        }

        _textInsert(textarea, contentToInsert, textarea.selectionStart, textarea.selectionEnd);

        textarea.selectionStart = start + offset.startVal.length;

        textarea.selectionEnd = textarea.selectionStart;
    }

    /**
     * Get the prefix of the line where the cursor is, if multiple lines are selected,
     * it will return the prefix only if all lines have the same prefix, if not it will return `null`.
     * @param  {Object} textarea The textarea element to perform the action.
     * @return {string} The prefix of the current (where the cursor is) line.
     */
    function getCurrentPrefixMode(textarea) {
        const prefixModes = Object.keys(service.MARKDOWN_CONFIG).filter(
            (mdConf) => service.MARKDOWN_CONFIG[mdConf].prefixLine,
        );
        const selection = _getSelection(textarea);
        const splittedSelection = selection.split('\n');
        const linesContent = splittedSelection.reduce((acc, cur) => {
            const lineIndex = selection[acc.length - 1]
                ? acc[acc.length - 1].lineIndex + acc[acc.length - 1].content.length + 1
                : textarea.selectionStart;
            const lineContent = _getLineContent(textarea, lineIndex);

            return [
                ...acc,
                {
                    content: cur,
                    lineIndex,
                    prefix: prefixModes.find((prefixMode) => {
                        const match = lineContent.match(
                            new RegExp(
                                `^${service.MARKDOWN_CONFIG[prefixMode].value.replace(_REPLACE_TEMPLATE, '(.*)')}$`,
                            ),
                        );

                        return Boolean(match);
                    }),
                },
            ];
        }, []);

        const { prefix } = linesContent[0];
        for (let i = 1; i < linesContent.length; i++) {
            const line = linesContent[i];
            if (line.prefix !== prefix) {
                return null;
            }
        }

        return prefix;
    }

    /////////////////////////////

    service.formatLink = formatLink;
    service.getWrappedLink = getWrappedLink;
    service.hasSelectedText = hasSelectedText;
    service.isSelectionLinkWrapped = isSelectionLinkWrapped;
    service.isWrapped = isWrapped;
    service.selectCloseElement = selectCloseElement;
    service.selectLine = selectLine;
    service.stripMarkdown = stripMarkdown;
    service.unWrap = unWrap;
    service.wrap = wrap;
    service.prefixLine = prefixLine;
    service.getCurrentPrefixMode = getCurrentPrefixMode;
}

/////////////////////////////

angular.module('Services').service('LsTextEditorMarkdown', LsTextEditorMarkdownService);
