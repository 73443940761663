import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { mdiBell } from '@lumapps/lumx/icons';
import { FlexBox, Alignment, Icon, ColorPalette, ColorVariant, Orientation, Size, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { NOTIFICATIONS_CENTER } from '../../keys';

const CLASSNAME = 'notification-center-empty-list';

const NotificationEmptyList: React.FC = () => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox
            className={CLASSNAME}
            orientation={Orientation.vertical}
            vAlign={Alignment.center}
            hAlign={Alignment.center}
        >
            <FlexBox
                className={element('icon', [margin('bottom', 'big')])}
                vAlign={Alignment.center}
                hAlign={Alignment.center}
            >
                <Icon
                    icon={mdiBell}
                    size={Size.xxl}
                    hasShape
                    color={ColorPalette.dark}
                    colorVariant={ColorVariant.L2}
                />
            </FlexBox>
            <Text as="p" color="dark" colorVariant="L3">
                {translateKey(NOTIFICATIONS_CENTER.NO_NOTIFICATION)}
            </Text>
        </FlexBox>
    );
};

export { NotificationEmptyList };
