import React, { useCallback } from 'react';

import trimStart from 'lodash/trimStart';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { QuickSearchEntity } from '@lumapps/quick-search/types';
import {
    removeSearchHistorySuggestion,
    removeInteractionSuggestion,
} from '@lumapps/quick-search/utils/suggestionsUtils';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore, Dispatch } from '@lumapps/redux/types';
import { useResponsive } from '@lumapps/responsive';
import { useRecommendations } from '@lumapps/search-recommendations/hooks/useRecommendations';
import { areSuggestionsLoading } from '@lumapps/search/ducks/selectors';
import { actions } from '@lumapps/search/ducks/slice';
import { changeSearchQuery } from '@lumapps/search/ducks/thunks';
import { useSearch } from '@lumapps/search/hooks/useSearch';
import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { ALL_SPACES_PATTERN } from '@lumapps/utils/string/constants';

import { useSuggestions } from '../../hooks/useSuggestions';
import { SearchBox as SearchBoxUI } from './SearchBox';

import './index.scss';

export interface SearchBoxProps {
    /** whether the autocomplete feature is enabled or not */
    autocompleteEnabled?: boolean;
    /** list of suggestions */
    suggestions?: SearchSuggestion[];
    /** whether the suggestions are loading or not */
    isLoading?: boolean;
    /** instance id */
    instanceId: string;
    /** Custom placeholder */
    placeholder?: string;
    /** is the user connected */
    isUserConnected: boolean;
    /** theme to use */
    theme?: Theme;
    /** callback to reset the suggestions */
    resetSuggestions: () => void;
    /** callback to be executed when the query changes */
    onQueryChange?: (s: string, autocompleteEnabled: boolean) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({
    suggestions = [],
    isLoading = false,
    instanceId,
    isUserConnected,
    theme,
    ...forwardedProps
}) => {
    const { isMobile } = useResponsive();
    const { onSearch, query, routes } = useSearch({ keepFiltersOnSearch: true });
    const [isFocus, setIsFocus] = React.useState(routes.mobileRoute);
    const { items } = useRecommendations(isFocus && isUserConnected);
    const newSuggestions = useSuggestions({
        canFetch: isFocus && isUserConnected,
        instanceId,
        query,
        recommendations: items,
    });
    const canUseSearchAutocomplete = !isLoading && suggestions.length > 0 && isFocus;
    const canUseSearchHistory = newSuggestions.length > 0 && isFocus;
    const canUseSearchFor = trimStart(query, ALL_SPACES_PATTERN).length > 0 && isFocus;
    const list = canUseSearchHistory ? newSuggestions : suggestions;

    const onDeleteSuggestion = useCallback(
        (suggestion: SearchSuggestion) => {
            switch (suggestion.type) {
                case SearchSuggestionType.HISTORY:
                    removeSearchHistorySuggestion(
                        {
                            query: suggestion.query,
                            siteId: instanceId,
                        },
                        isUserConnected,
                    );
                    break;
                case SearchSuggestionType.INTERACTION:
                    removeInteractionSuggestion(
                        {
                            ...((suggestion.item as QuickSearchEntity) || {}),
                            siteId: instanceId,
                        },
                        isUserConnected,
                    );
                    break;
                default:
                    break;
            }
        },
        [instanceId, isUserConnected],
    );

    return (
        <SearchBoxUI
            onSelect={onSearch}
            searchQuery={query}
            forceFocus={routes.mobileRoute}
            theme={!isMobile ? theme : Theme.light}
            showSuggestions={canUseSearchAutocomplete || canUseSearchHistory || canUseSearchFor}
            suggestions={list}
            isLoading={isLoading}
            onFocus={setIsFocus}
            onDeleteSuggestion={onDeleteSuggestion}
            instanceId={instanceId}
            isUserConnected={isUserConnected}
            isMobile={isMobile}
            {...forwardedProps}
        />
    );
};

const mapStateToProps = (state: FrontOfficeStore) => {
    const isLoading = areSuggestionsLoading(state);
    const instanceId = instanceIdSelector(state);
    const isUserConnected = isConnected(state);

    return {
        isLoading,
        instanceId,
        isUserConnected,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onQueryChange: (query: string) => {
            dispatch(changeSearchQuery(query));
        },
        resetSuggestions: () => {
            dispatch(actions.resetSuggestions());
        },
    };
};

const ConnectedSearchBox = connect(mapStateToProps, mapDispatchToProps)(SearchBox);

export { ConnectedSearchBox as SearchBox };
