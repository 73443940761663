import { Element, Editor, Range, Point, Transforms, Node, Path } from 'slate';

import { MatchFn } from './matchFn';

/**
 * Select element (if not selected) on delete backward or forward.
 */
export function selectElementOnDelete<T extends Node>(editor: Editor, forward: boolean, match: MatchFn<T>) {
    const { selection } = editor;

    const getElement = (at: Point | undefined) => {
        const [entry] = Array.from(Editor.nodes(editor, { at, match }));
        return entry;
    };

    if (!selection) {
        return false;
    }
    const [node] = getElement(selection.focus) ?? [];
    if (!Range.isCollapsed(selection) || match(node as Element)) {
        return false;
    }

    const entry = getElement(forward ? Editor.after(editor, selection.focus) : Editor.before(editor, selection.focus));
    if (!entry) {
        return false;
    }

    // Get root node at focus
    const [currentNode] = Editor.node(editor, selection.focus, {
        depth: 1,
    });

    const [, path] = entry;

    const pathRef = Editor.pathRef(editor, path);

    // Remove current root node if empty
    if (!Node.string(currentNode)) {
        Transforms.removeNodes(editor, {
            at: selection.focus,
        });
    }

    const updatedPath = pathRef.unref() as Path;

    const focus = { path: [...updatedPath, 0], offset: 0 };
    Transforms.setSelection(editor, { focus, anchor: focus });
    return true;
}
