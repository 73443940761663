import { Editor } from 'slate';

import { isElementType } from './isElementType';

/**
 * Check if editor selection contains a given element type.
 */
export const hasElementTypeInSelection = (editor: Editor, elementType: string, universal = false) => {
    if (!editor.selection) {
        return false;
    }
    const nodes = Array.from(
        Editor.nodes(editor, { at: editor.selection, match: isElementType(elementType), universal }),
    );
    return Boolean(nodes.length);
};
