import * as React from 'react';
import * as propTypes from 'prop-types';

import { margin } from '@lumapps/classnames';
import { Message, Text } from '@lumapps/lumx/react';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { translate as t } from '../../../../translations';
import { AccordionBox } from '../../../ui';
import { SpacingSettings } from '../../settings/SpacingSettings';
import { BorderSettings } from '../../settings/BorderSettings';
import { BackgroundSettings } from '../../settings/background/BackgroundSettings';

export class MainSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.mainStyle.isRequired,
        /** Define if the cell of the widget is in plain mode */
        isInPlainCell: propTypes.bool,
        name: types.property.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Current main style. */
        value: styleTypes.mainStyle,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { value, name: key, onChange, defaults, isInPlainCell } = this.props;

        return (
            <div className="widget-style-main__settings-wrapper">
                <AccordionBox label={t('STYLE.SPACINGS')} variant="simple">
                    <Message kind="info" className={margin('bottom', 'big')}>
                        <Text
                            as="span"
                            typography="caption"
                            color="dark"
                            colorVariant="L2"
                        >
                            {`${t('WIDGET_SETTINGS_STYLE_NOT_SAVED_PLURAL')} ${t(
                                'WIDGET_SETTINGS_RESPONSIVE_SPACING_HELPER',
                            )}`}
                        </Text>
                    </Message>
                    <SpacingSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BORDERS')} variant="simple">
                    <BorderSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BACKGROUND')} variant="simple">
                    <BackgroundSettings
                        value={value}
                        name={key}
                        onChange={onChange}
                        defaults={defaults}
                        shadow
                        propName={isInPlainCell ? 'backgroundPlainColor' : 'backgroundColor'}
                    />
                </AccordionBox>
            </div>
        );
    }
}
