import React from 'react';

import { mdiDotsVertical } from '@lumapps/lumx/icons';
import { IconButton, IconButtonProps } from '@lumapps/lumx/react';

import { Menu, SUB_COMPONENTS } from '../Menu';
import { MenuTrigger, MenuTriggerProps } from '../Menu/MenuTrigger';
import { MenuTriggerButton } from '../Menu/MenuTriggerButton';

export interface ActionMenuProps extends IconButtonProps {
    /** Action menu content (use `ActionMenu.Item`, `ActionMenu.Divider`, etc.) */
    children: React.ReactNode;
    /** Customized menu trigger props */
    triggerProps?: MenuTriggerProps;
}

const Inner = React.forwardRef<HTMLElement, ActionMenuProps>(
    ({ label, emphasis = 'low', children, triggerProps, ...rest }, ref) => (
        <MenuTrigger {...triggerProps}>
            <MenuTriggerButton
                ref={ref}
                as={IconButton}
                icon={mdiDotsVertical}
                label={label}
                emphasis={emphasis}
                {...rest}
            />
            <Menu>{children}</Menu>
        </MenuTrigger>
    ),
);

/**
 * Simple "triple dot" action menu.
 *
 * Wrapper component around the `Menu.Trigger`, `Menu.TriggerButton` and `Menu` to simplify this specific use case.
 *
 * Has the exact same subcomponents as `Menu` (`ActionMenu.Item`, `ActionMenu.Divider`, etc.).
 *
 * @family Menus
 */
export const ActionMenu = Object.assign(Inner, SUB_COMPONENTS);
ActionMenu.displayName = 'ActionMenu';
