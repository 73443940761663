function UserDirectoryFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/user/directory/:methodKey`,
        {},
        {
            delete: {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            list: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/user/directory/:methodKey`,
            },

            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            search: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'search',
                },
            },
        },
    );
}

/////////////////////////////

angular.module('Factories').factory('UserDirectoryFactory', UserDirectoryFactory);
