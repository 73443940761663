import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import type { ElementRender } from '@lumapps/wrex/types';

import { QUOTE_BLOCK } from '../../../constants';
import type { QuoteBlockElement } from '../../../types';

import './index.scss';

export const QuoteBlock: ElementRender<QuoteBlockElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const theme = useThemeContext();

    return (
        <blockquote
            {...forwardedProps}
            className={classnames(className, 'wrex-content-quote-block', `wrex-content-quote-block--theme-${theme}`)}
            ref={elementRef}
        >
            {children}
        </blockquote>
    );
};
QuoteBlock.displayName = QUOTE_BLOCK;
