/* eslint-disable no-param-reassign */
import produce from 'immer';

import { createFormReducer } from 'components/components/ducks/form';

import {
    DOMAIN,
    TOGGLE_OPEN_WORKFLOW,
    SET_CONTENT_PREVIEW,
    CLEAR_CONTENT_PREVIEW,
    RESET_WORKFLOW_FORM,
    TOGGLE_WORKFLOW_PREVIEW,
    ON_FETCH_MOST_USED_TOPICS_RESULT,
    SET_PREVIEW_TAB,
    SET_INCOMPATIBLE_SOCIAL_NETWORKS,
    FETCH_SOCIAL_NETWORKS,
    FETCH_SOCIAL_NETWORKS_FAILURE,
    FETCH_SOCIAL_NETWORKS_SUCCESS,
} from './actions';

import { SHAREABLE_CONTENT_TYPES } from '../shareableContents/constants';
import { FETCH_SN_STATUSES } from './constants';

const DEFAULT_STATE = {
    contentId: null,
    programId: null,
    contentPreview: {},
    currentPreviewTab: 0,
    form: {
        commonMessage: '',
        contentId: null,
        contentTitle: '',
        contentUrl: '',
        customizedMessages: {},
        images: [],
        sameMessage: true,
        shareableOn: {},
        topics: [],
        type: SHAREABLE_CONTENT_TYPES.link,
    },
    incompatibleSocialNetworks: {},
    socialNetworksStatus: FETCH_SN_STATUSES.initial,
    socialNetworks: [],
    isPreviewOpen: false,
    isWorkflowOpen: false,
    shareableContent: null,
};

const { form: defaultForm, ...defaultState } = DEFAULT_STATE;

const mainReducer = produce((draft, action) => {
    switch (action.type) {
        case TOGGLE_OPEN_WORKFLOW: {
            draft.isWorkflowOpen = action.isWorkflowOpen;
            draft.shareableContent = action.shareableContent;
            draft.contentId = action.contentId;
            draft.programId = action.programId;

            return draft;
        }

        case SET_CONTENT_PREVIEW: {
            draft.contentPreview = action.payload;

            return draft;
        }

        case CLEAR_CONTENT_PREVIEW: {
            draft.contentPreview = {};

            return draft;
        }

        case ON_FETCH_MOST_USED_TOPICS_RESULT: {
            if (action.error) {
                draft.mostUsedTopics.hasError = true;
            } else {
                draft.mostUsedTopics.entries = action.entries;
                draft.mostUsedTopics.hasError = false;
            }

            return draft;
        }

        case TOGGLE_WORKFLOW_PREVIEW: {
            draft.isPreviewOpen = action.payload;

            return draft;
        }

        case RESET_WORKFLOW_FORM: {
            return defaultState;
        }

        case SET_PREVIEW_TAB: {
            draft.currentPreviewTab = action.payload;

            return draft;
        }

        case SET_INCOMPATIBLE_SOCIAL_NETWORKS: {
            draft.incompatibleSocialNetworks = action.payload;

            return draft;
        }

        case FETCH_SOCIAL_NETWORKS: {
            draft.socialNetworksStatus = FETCH_SN_STATUSES.loading;

            return draft;
        }

        case FETCH_SOCIAL_NETWORKS_SUCCESS: {
            draft.socialNetworksStatus = FETCH_SN_STATUSES.success;
            draft.socialNetworks = action.payload;

            return draft;
        }

        case FETCH_SOCIAL_NETWORKS_FAILURE: {
            draft.socialNetworksStatus = FETCH_SN_STATUSES.failure;

            return draft;
        }

        default: {
            return draft;
        }
    }
}, defaultState);

const formReducer = createFormReducer(`${DOMAIN}`, defaultForm);

const reducer = (state = defaultState, action) => {
    const { form, ...mainState } = state;

    return {
        ...mainReducer(mainState, action),
        form: formReducer(form, action),
    };
};

export { reducer, defaultState };
