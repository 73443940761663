import React, { useRef } from 'react';
import { List, ListItem, Size, Button, Dropdown, Emphasis } from '@lumapps/lumx/react';
import { mdiTranslate, mdiMenuDown } from '@lumapps/lumx/icons';

import classnames from 'classnames';

import { translate as t } from 'components/translations';

const DEFAULT_PROPS = {
    availableLangs: [],
    currentLang: '',
    isOpen: false,
};

/**
 * The LanguageSelector component.
 * @param  {Object}        props                Component's props.
 * @param  {Array<string>} props.availableLangs List of available languages.
 * @param  {string}        props.className      The className passed to the button.
 * @param  {string}        props.currentLang    The selected lang, will be displayed in the button.
 * @param  {boolean}       props.isOpen         Wether the dropdown should be open or not.
 * @param  {Function}      props.onChange       The function triggered when a language is selected.
 * @param  {Function}      props.onClick        The function triggered when the button is clicked.
 * @param  {Function}      props.onClose        The function triggered when Escape is pressed or when you click
 *                                              away from the dropdown.
 * @param  {...Object}     otherProps           Other props will be passed down to the Button component.
 * @return {Component}     The Language Selector Component.
 */
const LanguageSelector = ({
    availableLangs = DEFAULT_PROPS.availableLangs,
    className,
    currentLang = DEFAULT_PROPS.currentLang,
    isOpen = DEFAULT_PROPS.isOpen,
    onChange,
    onClick,
    onClose,
    ...otherProps
}) => {
    const anchorRef = useRef(null);

    return (
        <>
            <Button
                ref={anchorRef}
                className={classnames('ls-language-selector', className)}
                emphasis={Emphasis.medium}
                leftIcon={mdiTranslate}
                rightIcon={mdiMenuDown}
                onClick={onClick}
                {...otherProps}
            >
                {t(`LANG_${currentLang.toUpperCase()}`)}
            </Button>
            <Dropdown anchorRef={anchorRef} isOpen={isOpen} onClose={onClose}>
                <List isClickable={isOpen}>
                    {availableLangs.map((lang) => (
                        <ListItem
                            key={lang}
                            size={Size.tiny}
                            onMouseDownCapture={() => {
                                onChange(lang);
                            }}
                        >
                            {t(`LANG_${lang.toUpperCase()}`)}
                        </ListItem>
                    ))}
                </List>
            </Dropdown>
        </>
    );
};

export { LanguageSelector };
