import React from 'react';

import { MenuSection, MenuSectionProps } from '../MenuSection';

interface MenuSectionRadioGroupProps extends MenuSectionProps {
    value?: string;
    onChange: (value: string) => void;
}

export const MenuSectionRadioGroupContext = React.createContext<{
    value?: string;
    setValue?: (value: string) => void;
}>({});

/**
 * Menu radio group (ARIA `radiogroup`).
 * To use inside a Menu.
 *
 * @family Menus
 */
export const MenuSectionRadioGroup = React.forwardRef<HTMLDivElement, MenuSectionRadioGroupProps>((props, ref) => {
    const { children, onChange, value, ...forwardedProps } = props;

    const contextValue = React.useMemo(
        () => ({
            value,
            setValue: onChange,
        }),
        [onChange, value],
    );

    return (
        <MenuSection ref={ref} {...forwardedProps}>
            <MenuSectionRadioGroupContext.Provider value={contextValue}>
                {children}
            </MenuSectionRadioGroupContext.Provider>
        </MenuSection>
    );
});

MenuSectionRadioGroup.displayName = 'MenuSection';
