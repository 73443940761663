import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Size, Theme, Typography } from '@lumapps/lumx/react';

import { BaseState, BaseStateProps } from '../BaseState';

export interface EmptyStateProps {
    /** empty state title */
    title?: string;
    /** component to be displayed before the title */
    beforeTitle?: BaseStateProps['beforeTitle'];
    /** Text to be displayed after the title */
    message?: BaseStateProps['message'];
    /** Text component props */
    messageProps?: BaseStateProps['messageProps'];
    /** component to be displayed after the message */
    actions?: BaseStateProps['actions'];
    /** icon props */
    iconProps?: BaseStateProps['iconProps'];
    /** call to action button props */
    buttonProps?: BaseStateProps['buttonProps'];
    /** Link props */
    linkProps?: BaseStateProps['linkProps'];
    /** props for the component wrapper */
    wrapperProps?: BaseStateProps['wrapperProps'];
    /** whether this component will be displayed vertically centered or not */
    isVerticallyCentered?: boolean;
    /** Theme adapting the component to light or dark background. */
    theme?: Theme;
}

const CLASSNAME = 'lumx-empty-state';
/**
 * Component usually displayed when rendering lists of entities and not finding any entity.
 * All back office pages should use this component when rendering empty states.
 *
 * @family States
 * @param EmptyStateProps
 * @returns EmptyState
 */
export const EmptyState: React.FC<EmptyStateProps> = ({
    wrapperProps,
    iconProps,
    buttonProps,
    title,
    beforeTitle,
    message,
    messageProps,
    linkProps,
    actions,
    isVerticallyCentered = true,
    theme,
}) => {
    return (
        <BaseState
            className={classnames(CLASSNAME, wrapperProps?.className)}
            iconProps={iconProps ? { size: Size.xl, ...iconProps } : undefined}
            buttonProps={buttonProps}
            beforeTitle={beforeTitle}
            title={title}
            titleProps={{ typography: Typography.subtitle1 }}
            message={message}
            messageProps={messageProps}
            linkProps={linkProps}
            fillSpace={isVerticallyCentered}
            wrapperProps={wrapperProps}
            actions={actions}
            theme={theme}
        />
    );
};
