/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyPostIdeaStatusDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'post-idea-status-dialog' */
            './PostIdeaStatusDialogWithEffects'
        ),
);

/** Lazy loaded post idea status dialog. */
export const PostIdeaStatusDialog: React.FC<React.ComponentProps<typeof LazyPostIdeaStatusDialog>> = ({
    isOpen,
    ...props
}) => (
    <DelayedSuspense shouldLoad={isOpen}>
        {(setShouldMount) => (
            <LazyPostIdeaStatusDialog isOpen={isOpen} onVisibilityChange={setShouldMount} {...props} />
        )}
    </DelayedSuspense>
);
