import React from 'react';

import moment from 'moment';

import { Typography, Theme, Placement } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { UserLink } from '@lumapps/user/components/UserLink';
import { BlockContextBanner } from '@lumapps/widget-base/components/Block/BlockContextBanner';

import { STREAM_ACTION_TO_CONTEXT_BANNER_ICON } from '../../../constants';
import { BlockStreamItemByResource } from '../../../types';
import { getContextBannerTranslation } from '../../../utils/getContextBannerTranslation';

export type GenericStreamContextBannerProps = BlockStreamItemByResource<'content' | 'post' | 'event' | 'article'>;

const CLASSNAME = 'generic-stream-context-banner';

/**
 * Generic Context Banner for simple context (avatar- user - action text - date). Manages
 *  - Content (custom or not):
 *      - New comment
 *  - Post
 *      - New comment
 *  - Event
 *      - New attendee
 *  - Article
 *      - New comment
 *  - Learning
 *      - New Enrollment
 */
export const GenericStreamContextBanner: React.FC<GenericStreamContextBannerProps> = ({
    resourceType,
    userAction,
    content,
    theme,
}) => {
    const { user, actionDate, action } = userAction;
    const { pictureUrl } = user;

    const { translateObject, translateAndReplaceWithComponents } = useTranslate();

    const actionIcon = STREAM_ACTION_TO_CONTEXT_BANNER_ICON[resourceType]?.[action];

    const actionDateText = actionDate ? moment.utc(actionDate).local().fromNow() : undefined;

    const userComponent = (
        <UserLink
            user={{ id: user.userId, fullName: user.fullName }}
            userCardPopoverProps={{
                showFollow: true,
                profilePicture: pictureUrl,
                placement: Placement.BOTTOM_START,
            }}
            key={user.fullName}
            color={theme === Theme.dark ? Theme.light : Theme.dark}
            colorVariant="N"
            typography={Typography.subtitle1}
        >
            {user.fullName}
        </UserLink>
    );

    const contextBannerText = getContextBannerTranslation({
        resourceType,
        action,
        userComponent,
        content,
        translateObject,
        translateAndReplaceWithComponents,
    });

    return (
        <BlockContextBanner
            className={CLASSNAME}
            icon={actionIcon}
            pictureUrl={resourceType === 'learning' ? undefined : pictureUrl}
            contextBannerText={contextBannerText}
            actionDate={actionDateText}
            theme={theme}
        />
    );
};
