import { get } from '@lumapps/constants';

const constants = get();
const TOTAL_AMOUNT_OF_LANGUAGES = 3;

const roundWeight = (weight: number) => {
    return Math.round(weight * 100) / 100;
};

/**
 * From a list of languages the function returns the value to be used
 * as the Accept-Language when doing an API call. The total amount of languages
 * that can be passed in is 3, if there are more than 3 languages on the array, only the
 * first ones will be taken into consideration. This function will also add `en` as the last
 * language to default to.
 *
 * If you just need to pass the default language header and your use case is a standard one,
 * please use the getLanguageHeader selector from @lumapps/languages. Only use this function
 * directly if you need to customize the languages you send to the backend.
 *
 * @param langs - list of languages to send in the header
 * @returns value to be used as the Accept-Language header.
 */
export const formatLanguageHeader = (langs: string[], addDefaultLang = true) => {
    let weight = 0.9;
    let header = '';

    for (let i = 0, addedLangs = 0; i < langs.length && addedLangs < TOTAL_AMOUNT_OF_LANGUAGES; i++) {
        const lang = langs[i];
        if (lang) {
            header = `${header}${lang};q=${roundWeight(weight)},`;
            weight -= 0.1;
            addedLangs += 1;
        }
    }

    if (addDefaultLang) {
        header = `${header}${constants.defaultLanguage};q=${roundWeight(weight)},`;

        weight -= 0.1;

        header = `${header}*;q=${roundWeight(weight)}`;
    } else {
        header = header.slice(0, -1);
    }

    return header;
};
