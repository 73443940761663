import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Button, Theme } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { useTranslate } from '@lumapps/translations';
import { feedRoute } from '@lumapps/user-feed/routes';
import { ContainerBlockVariant } from '@lumapps/widget-base/types';

import { SCOPE } from '../../constants';
import { WIDGET_PERSONAL_FEED_PREVIEW } from '../../keys';
import { BlockPersonalFeedPreview } from '../../types';

export interface BlockPersonalFeedPreviewFooterProps {
    theme: BlockPersonalFeedPreview['theme'];
    variant: BlockPersonalFeedPreview['variant'];
    contentStyles: BlockPersonalFeedPreview['contentStyles'];
}

const CLASSNAME = 'block-personal-feed-preview-footer';

/**
 *
 * @param theme Theme
 * @param variant ContainerBlockVariant
 * @returns BlockPersonalFeedPreviewFooter
 */
export const BlockPersonalFeedPreviewFooter: React.FC<BlockPersonalFeedPreviewFooterProps> = ({
    theme = Theme.light,
    variant,
    contentStyles,
}) => {
    const { block } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(SCOPE);
    const { translateKey } = useTranslate();

    const ButtonElement = (
        <Button
            className={block(variant.toLowerCase())}
            emphasis="medium"
            fullWidth
            size="m"
            theme={theme}
            linkAs={RouterLink}
            to={feedRoute}
            {...get({ element: 'button', action: 'footer-redirect' })}
        >
            {translateKey(WIDGET_PERSONAL_FEED_PREVIEW.SEE_MORE_IN_YOUR_FEED)}
        </Button>
    );

    return variant === ContainerBlockVariant.grouped ? (
        ButtonElement
    ) : (
        // Add a div with padding so the button is inside a background
        <div className={block('button-wrapper')} style={contentStyles}>
            {ButtonElement}
        </div>
    );
};
