(function IIFE() {
    'use strict';

    /////////////////////////////

    function CloudSearchCustomFieldsService(CloudSearchCustomFieldsFactory, LumsitesBaseService) {
        'ngInject';

        return LumsitesBaseService.createLumsitesBaseService(CloudSearchCustomFieldsFactory, {
            autoInit: false,
            objectIdentifier: 'name',
        });
    }

    /////////////////////////////

    angular.module('Services').service('CloudSearchCustomFields', CloudSearchCustomFieldsService);
})();
