(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetAwesomeTableSettingsController($rootScope, $scope, $timeout, $window, LxDialogService, Translation,
        Utils, Widget) {
        'ngInject';

        var widgetAwesomeTableSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Whether to display a warning regarding mixed https & http content.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        widgetAwesomeTableSettings.DIALOG_ID = 'awesome-table-dialog';

        /**
         * Whether to display a warning regarding mixed https & http content.
         *
         * @type {boolean}
         */
        widgetAwesomeTableSettings.displayHttpsWarning = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetAwesomeTableSettings.Translation = Translation;
        widgetAwesomeTableSettings.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Updates widget when url or height is changed.
         */
        function updateWidget() {
            $timeout(function timedOutAwesomeTableSettingsBroadcast() {
                $rootScope.$broadcast('widget-awesome-table-settings', Widget.getCurrent().uuid);
            });
        }

        /**
         * Closes the dialog when user clicks OK.
         */
        function closeDialog() {
            if (angular.isUndefinedOrEmpty(Widget.getCurrent().properties.embedUrl)) {
                return;
            }

            LxDialogService.close(widgetAwesomeTableSettings.DIALOG_ID);
        }

        /**
         * Deletes selected file by clearing all properties.
         */
        function removeSelectedTable() {
            var currentWidget = Widget.getCurrent();
            var properties = _.get(currentWidget, 'properties');
            if (angular.isUndefined(properties)) {
                return;
            }

            properties.embedUrl = undefined;

            widgetAwesomeTableSettings.updateWidget();
        }

        /**
         * Checks if a file/file id is set else clear the folder property.
         *
         * @return {boolean} Whether or not the file is selected.
         */
        function isTableSelected() {
            return angular.isDefinedAndFilled(Widget.getCurrent().properties.embedUrl);
        }

        /**
         * Opens the dialog when user clicks 'Select Table' button.
         */
        function openDialog() {
            Utils.waitForAndExecute('#' + widgetAwesomeTableSettings.DIALOG_ID);
        }

        /**
         * Creates the link of the file the user wants to view.
         *
         * @param {MessageEvent} evt The selection of the file.
         */
        function setTableToPreview(evt) {
            if (evt.origin !== 'https://awesome-table.com' || !(angular.isObject(evt.data))) {
                return;
            }

            var setId = _.get(evt, 'data.setId');
            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            if (angular.isDefinedAndFilled(setId)) {
                properties.embedUrl = 'https://view-awesome-table.com/' + setId + '/view';
            }

            widgetAwesomeTableSettings.updateWidget();
        }

        /////////////////////////////

        widgetAwesomeTableSettings.closeDialog = closeDialog;
        widgetAwesomeTableSettings.removeSelectedTable = removeSelectedTable;
        widgetAwesomeTableSettings.isTableSelected = isTableSelected;
        widgetAwesomeTableSettings.openDialog = openDialog;
        widgetAwesomeTableSettings.setTableToPreview = setTableToPreview;
        widgetAwesomeTableSettings.updateWidget = updateWidget;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Remove the event listener added during the init when the directive is destroyed.
         */
        $scope.$on('$destroy', function removeMessageListener() {
            $window.removeEventListener('message', setTableToPreview);
        });

        /**
         * Makes sure nothing shows up if no file is selected (when the link contains "undefined" instead of an id).
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering the close start event.
         */
        $scope.$on('lx-dialog__close-start', function onDialogCloseStart(evt, dialogId) {
            var currentWidget = Widget.getCurrent();
            var properties = currentWidget.properties || {};

            if (widgetAwesomeTableSettings.DIALOG_ID === dialogId &&
                currentWidget.properties.embedUrl === 'https://view-awesome-table.com/undefined/view') {
                properties.embedUrl = undefined;
                properties.height = undefined;
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Listen to message from child window (within the iframe).
            $window.addEventListener('message', setTableToPreview, false);
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetAwesomeTableSettingsController',
        WidgetAwesomeTableSettingsController);
})();
