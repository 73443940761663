import { Dispatch } from '@lumapps/redux/types';

import * as api from '../../api';
import { actions, ProgramSelectionModalStatus } from './slice';

export const fetchProgramsByIds = (programIds: string[]) => async (dispatch: Dispatch) => {
    dispatch(actions.setModalStatus(ProgramSelectionModalStatus.loading));
    try {
        const { data } = await api.fetchPrograms({ ids: programIds });

        const programIdsByName = data?.items?.reduce((acc: any, program: any) => {
            acc[program.name?.value] = program.id;
            return acc;
        }, {});

        dispatch(actions.setProgramIdsByName(programIdsByName));
        dispatch(actions.setModalStatus(ProgramSelectionModalStatus.loaded));
    } catch (e) {
        dispatch(actions.setModalStatus(ProgramSelectionModalStatus.error));
    }
};
