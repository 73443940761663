import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useSelected } from '@lumapps/wrex/slate';
import type { ElementRender } from '@lumapps/wrex/types';

import { DIVIDER } from '../../../constants';
import type { DividerElement } from '../../../types';
import { CLASSNAME, Divider } from '../../blocks/Divider';

import './index.scss';

/**
 * Display a divider for edition
 */
export const EditableDivider: ElementRender<DividerElement, HTMLHRElement> = (props) => {
    const { children, ...forwardedProps } = props;
    const { block } = useClassnames(CLASSNAME);
    const selected = useSelected();

    return (
        <Divider className={block({ editable: true, selected })} {...forwardedProps}>
            {children}
        </Divider>
    );
};

/** The display name should be equal to the DividerElement's type */
EditableDivider.displayName = DIVIDER;
