import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import map from 'lodash/map';

import * as tabTypes from './types';
import { Icon } from '../icons/Icon';

/**
 * Used internally by `<Tabs />` to render the tab elements and highlight the active one.
 */
export class Navigation extends React.PureComponent {
    static propTypes = {
        className: propTypes.string,
        /** Class name for tab items. */
        classNameItem: propTypes.string,
        /** Class name for active tab items. */
        classNameItemActive: propTypes.string,
        /** Tab items. */
        items: tabTypes.items.isRequired,
        /** Called when the tab changes, with `(id)`. */
        onChange: propTypes.func.isRequired,
        /** Currently active tab id. */
        value: propTypes.string,
    };

    constructor(props) {
        super(props);
        this.selectItem = (occurence) => this.props.onChange(occurence.currentTarget.id);
    }

    render() {
        const { value, items, className, classNameItem, classNameItemActive } = this.props;

        return (
            <div className={className}>
                {map(items, ({ id, label, icon }) => (
                    <a
                        key={id}
                        className={classNames(classNameItem, id === value && classNameItemActive)}
                        id={id}
                        onClick={this.selectItem}
                    >
                        {label && <span>{label}</span>}
                        {icon && <Icon value={icon} />}
                    </a>
                ))}
            </div>
        );
    }
}
