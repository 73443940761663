function WidgetCommunityIntroLinkController(Content) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The current community.
     *
     * @type {Object}
     */
    vm.community = undefined;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the widget is empty.
     * This is used in the designer.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        return (
            angular.isUndefined(vm.community.description) ||
            (!vm.parentCtrl.getWidgetTranslation(vm.community.description) &&
                angular.isUndefinedOrEmpty(vm.community.tagz))
        );
    }

    /**
     * Get the widget classes.
     *
     * @return {Array} The widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        return widgetClass;
    }

    /**
     * Check if the widget is hidden.
     * This is used in read mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        if (!vm.parentCtrl.designerMode() && isWidgetEmpty()) {
            vm.parentCtrl.isHidden = true;
        } else {
            vm.parentCtrl.isHidden = false;
        }

        return vm.parentCtrl.isHidden;
    }

    /////////////////////////////

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetHidden = isWidgetHidden;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.community = Content.getCurrent();
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = (parentCtrl) => {
        vm.parentCtrl = parentCtrl;

        init();
    };
}

/////////////////////////////

/**
 * The community navigation widget.
 * This widget provide the user navigation tools and shortcut.
 *
 * @param {Object} widget The widget configuration object.
 */

function WidgetCommunityIntroDirective() {
    'ngInject';

    function WidgetCommunityIntroLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetCommunityIntroLinkController,
        controllerAs: 'vm',
        link: WidgetCommunityIntroLink,
        replace: true,
        require: ['widgetCommunityIntro', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-community-intro/views/widget-community-intro.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetCommunityIntro', WidgetCommunityIntroDirective);

export { WidgetCommunityIntroDirective };
