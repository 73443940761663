import debounce from 'lodash/debounce';
import loFind from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import values from 'lodash/values';
import without from 'lodash/without';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

// ///////////////////////////

function WidgetPostListSettingsController(
    $rootScope,
    $scope,
    $timeout,
    Community,
    Config,
    Content,
    ContentPicker,
    ContentTemplate,
    CustomContentType,
    Features,
    InitialSettings,
    Instance,
    PostConstant,
    Translation,
    Utils,
    Widget,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetPostListSettings = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The delay for debounced functions.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEBOUNCE_DELAY = 500;

    /**
     * The vote sort order choice.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _VOTE_ORDER_CHOICE = 'score.total';

    /**
     * The initial sort order choices.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _INITIAL_LIST_ORDER_CHOICES = ['publicationDate', 'likes', 'comments', 'updatedAt', _VOTE_ORDER_CHOICE];

    /**
     * The sort order choices specific to the event post.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _EVENT_LIST_ORDER_CHOICES = ['eventStartDate', 'eventEndDate'];

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The traduction key to use as content picker title prefix.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    widgetPostListSettings.PICKER_TITLE_KEY = 'GLOBAL.COMMUNITY';

    /**
     * The types of posts available in the selected communities (according to the display type).
     *
     * @type {Array}
     */
    widgetPostListSettings.availablePostTypes = values(InitialSettings.POST_TYPES);

    /**
     * A list of communities (full objects) to filter the post list on.
     *
     * @type {Array}
     */
    widgetPostListSettings.community = [];

    /**
     * The current content.
     *
     * @type {Object}
     */
    widgetPostListSettings.content = undefined;

    /**
     * The id of the community picker.
     *
     * @type {string}
     */
    widgetPostListSettings.contentPickerId = 'widget-cp-list-settings-content-picker-';

    /**
     * The list key used for calls to the Post api in the post list widget.
     *
     * @type {string}
     */
    widgetPostListSettings.listKey = undefined;

    /**
     * Whether to display the filters in the settings or not. Based on the feature flags.
     *
     * @type {boolean}
     */
    widgetPostListSettings.showFilters = false;

    /**
     * A list of tags for the communities of the current widget keyed with the community title.
     * Used in the tags select.
     *
     * @type {Object}
     */
    widgetPostListSettings.tags = {};

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    widgetPostListSettings.Community = Community;
    widgetPostListSettings.Config = Config;
    widgetPostListSettings.Content = Content;
    widgetPostListSettings.CustomContentType = CustomContentType;
    widgetPostListSettings.Instance = Instance;
    widgetPostListSettings.PostConstant = PostConstant;
    widgetPostListSettings.Utils = Utils;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Build the tag list (an object with community title as a key and tagsDetails as a value).
     *
     * @param {Array} communities A list of communities to get the tagsDetails from.
     */
    function _buildTagList(communities) {
        const tags = {};

        angular.forEach(communities, (community) => {
            if (angular.isDefinedAndFilled(community.tagsDetails)) {
                tags[Translation.translate(community.title)] = community.tagsDetails;
            }
        });

        widgetPostListSettings.tags = tags;
    }

    /**
     * Remove tags from communities that are not selected.
     */
    function _filterTags() {
        const widget = Widget.getCurrent();
        // Get all tags from the currently selected communities.
        // Flatten them all in one array.
        // Get the intersection of this and our current community tags.
        widget.properties.communityTags = intersection(
            flattenDeep(map(widgetPostListSettings.community, 'tags')),
            widget.properties.communityTags,
        );
    }

    /**
     * Handle the backward compatibility of some of the properties of the widget.
     */
    function _handleBackwardCompatibility() {
        const widget = Widget.getCurrent() || {};
        widget.properties = widget.properties || {};
        const { properties } = widget;

        // TODO [Arnaud]: Remove this once the backend script has been run.
        // Handle backward compatibility.
        // We used to be able to only select one community - now we can select multiple ones.
        if (angular.isDefined(properties.community) && !angular.isArray(properties.community)) {
            properties.community = properties.community.id;
            widgetPostListSettings.community = [properties.community];
        } else {
            properties.community = properties.community || [];
        }

        // We changed this because we always display publicationDate.
        if (properties.listOrder === 'createdAt') {
            properties.listOrder = 'publicationDate';
        }
    }

    /**
     * Initialize the tag list based on the currently selected communities.
     */
    function _initTags() {
        const widget = Widget.getCurrent();
        const communityIds = widget.properties.community;

        if (angular.isDefinedAndFilled(communityIds)) {
            const params = {
                ids: communityIds,
                queryType: 'getContentByIds',
                status: [Config.CONTENT_STATUS.LIVE.value],
            };

            // Retrieve the current communities from their ids (we only store the ids in the model).
            Community.filterize(
                params,
                (communities) => {
                    widgetPostListSettings.community = communities;
                    widgetPostListSettings.updateTags(false);
                },
                undefined,
                widgetPostListSettings.listKey,
            );
        }
    }

    /**
     * Update the sort order choices according to the postType selection.
     */
    function _updateOrderChoices() {
        widgetPostListSettings.isOnlyEventTypeSelected = isEqual(Widget.getCurrent().properties.postType, [
            InitialSettings.POST_TYPES.EVENT,
        ]);

        widgetPostListSettings.listOrderSelection = angular.fastCopy(_INITIAL_LIST_ORDER_CHOICES);

        if (widgetPostListSettings.isOnlyEventTypeSelected) {
            // If we have only event posts, we remove the vote order and add the events specific orders.
            widgetPostListSettings.listOrderSelection = without(_INITIAL_LIST_ORDER_CHOICES, _VOTE_ORDER_CHOICE).concat(
                _EVENT_LIST_ORDER_CHOICES,
            );
        }
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Open content picker to select what community the posts are listed from.
     */
    function openContentPicker() {
        Utils.waitForAndExecute(`#${widgetPostListSettings.contentPickerId}`, ContentPicker);
    }

    /**
     * Convert the value stored in the ng-model into the value displayed in the choice list to match the selection.
     *
     * @param {string}   uid The unique identifier of a tag that has been selected.
     * @param {Function} cb  The function to execute if the tag is found in our tag list.
     */
    function modelToSelection(uid, cb) {
        cb = cb || angular.noop;

        angular.forEach(Object.keys(widgetPostListSettings.tags), (key) => {
            const needle = loFind(widgetPostListSettings.tags[key], (tag) => {
                return tag.uid === uid;
            });

            if (angular.isDefined(needle)) {
                cb(needle);
            }
        });
    }

    /**
     * If this widget is now a "Main Widget", remove the isMainWidget attribute to the other widgets.
     * Todo [Arnaud]: move this to widget list directive.
     */
    function setAsMainWidget() {
        const currentWidget = Widget.getCurrent();
        if (currentWidget.isMainWidget) {
            const widgetList = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isMainWidget', true);
            angular.forEach(widgetList, (widget) => {
                if (widget.uuid !== currentWidget.uuid) {
                    widget.isMainWidget = false;
                }
            });
        }

        $rootScope.$broadcast('widget-is-main-toggled', currentWidget.uuid, currentWidget.isMainWidget);
    }

    /**
     * Switch widget view mode.
     *
     * @param {string} viewMode The wanted view mode.
     */
    function switchViewMode(viewMode) {
        if (Widget.getCurrent().properties.viewMode === viewMode) {
            return;
        }

        Widget.getCurrent().properties.viewMode = viewMode;

        if (Widget.getCurrent().properties.viewMode === 'cascade') {
            Widget.getCurrent().properties.viewModeVariant = 'ungroup';
        } else {
            Widget.getCurrent().properties.viewModeVariant = 'group';
        }

        widgetPostListSettings.updateWidget();
    }

    /**
     * Switch widget view mode variant.
     *
     * @param {string} viewModeVariant The wanted view mode variant.
     */
    function switchViewModeVariant(viewModeVariant) {
        if (Widget.getCurrent().properties.viewModeVariant === viewModeVariant) {
            return;
        }

        Widget.getCurrent().properties.viewModeVariant = viewModeVariant;

        widgetPostListSettings.updateWidget();
    }

    /**
     * Toggle field.
     *
     * @param {Object} field The field that is toggled.
     */
    function toggleField(field) {
        if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
            return;
        }

        field.enable = !field.enable;

        widgetPostListSettings.updateWidget(true);
    }

    function getFieldLabel(field) {
        if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
            return;
        }

        const fieldName = field.name.toUpperCase();

        // Title is a generic key, we add an exception to optimize our lokalise keys use.
        if (fieldName === 'TITLE') {
            return Translation.translate('GLOBAL.TITLE')
        }
        
        return Translation.translate(`WIDGET_TYPE_POST-LIST_${fieldName}`);
    }

    /**
     * Toggles the 'isInstanceNameDisplayed' property on current widget.
     */
    function toggleInstanceNameOption() {
        const { properties } = Widget.getCurrent();
        properties.isInstanceNameDisplayed = !properties.isInstanceNameDisplayed;

        widgetPostListSettings.updateWidget();
    }

    /**
     * Updates the instance name display property value according to `isAllInstancesSiblings` property value.
     */
    function updateInstanceNameProperty() {
        const properties = Widget.getCurrent().properties || {};

        if (properties.isAllInstancesSiblings) {
            properties.isInstanceNameDisplayed = true;
        }

        widgetPostListSettings.updateWidget();
    }

    /**
     * Update the tag list because the communities have changed.
     *
     * @param {Function} sendUpdateWidgetEvent Indicates if we want to update the widget too.
     */
    function updateTags(sendUpdateWidgetEvent) {
        sendUpdateWidgetEvent = angular.isUndefined(sendUpdateWidgetEvent) ? true : sendUpdateWidgetEvent;

        _buildTagList(widgetPostListSettings.community);

        _filterTags();

        if (sendUpdateWidgetEvent) {
            // Update the widget now that we have a new list of tags.
            widgetPostListSettings.updateWidget();
        }
    }

    /**
     * Update widget on settings change.
     */
    function updateWidget() {
        $timeout(() => {
            $rootScope.$broadcast('widget-post-list-settings', Widget.getCurrent().uuid);
        });
    }

    /**
     * Update widget on settings change.
     */
    function updatePostType() {
        const properties = Widget.getCurrent().properties || {};

        if (properties.postType && properties.postType.length > 0) {
            properties.isContributionFieldDisplayed = false;
        }
        widgetPostListSettings.updateWidget();

        $timeout(() => {
            _updateOrderChoices();
            $rootScope.$broadcast('widget-post-list-settings-update-selected-types', Widget.getCurrent().uuid);
        });
    }

    /**
     * Callback called when display type is changed.
     */
    function updateDisplayType() {
        const properties = Widget.getCurrent().properties || {};

        // If the displayType is changed to anything but current, we need
        // to hide the contribution field.
        if (properties.displayType !== 'current') {
            properties.isContributionFieldDisplayed = false;
        }

        widgetPostListSettings.updateWidget();
    }

    /**
     * Callback called when a filter is changed (pinnedOnly or withAttachmentOnly).
     */
    function updateFilters() {
        const properties = Widget.getCurrent().properties || {};

        if (properties.pinnedOnly || properties.withAttachmentOnly) {
            properties.isContributionFieldDisplayed = false;
        }

        widgetPostListSettings.updateWidget();
    }

    function isContributionFieldEnabled() {
        return widgetPostListSettings.content.type === 'community';
    }

    function isPostListFiltered() {
        const properties = Widget.getCurrent().properties || {};
        const isFiltered = properties.pinnedOnly || properties.withAttachmentOnly || properties.postType.length > 0;
        const showCurrentCommunityPosts = properties.displayType === 'current';
        return isFiltered || !showCurrentCommunityPosts;
    }

    // ///////////////////////////

    widgetPostListSettings.debouncedUpdateWidget = debounce(updateWidget, _DEBOUNCE_DELAY);
    widgetPostListSettings.isUndefinedOrEmpty = angular.isUndefinedOrEmpty;
    widgetPostListSettings.modelToSelection = modelToSelection;
    widgetPostListSettings.openContentPicker = openContentPicker;
    widgetPostListSettings.setAsMainWidget = setAsMainWidget;
    widgetPostListSettings.switchViewMode = switchViewMode;
    widgetPostListSettings.switchViewModeVariant = switchViewModeVariant;
    widgetPostListSettings.toggleField = toggleField;
    widgetPostListSettings.getFieldLabel = getFieldLabel;
    widgetPostListSettings.toggleInstanceNameOption = toggleInstanceNameOption;
    widgetPostListSettings.updateTags = updateTags;
    widgetPostListSettings.updateInstanceNameProperty = updateInstanceNameProperty;
    widgetPostListSettings.updatePostType = updatePostType;
    widgetPostListSettings.updateDisplayType = updateDisplayType;
    widgetPostListSettings.updateFilters = updateFilters;
    widgetPostListSettings.updateWidget = updateWidget;
    widgetPostListSettings.isContributionFieldEnabled = isContributionFieldEnabled;
    widgetPostListSettings.isPostListFiltered = isPostListFiltered;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    /**
     * Trigger a widget update whenever we close the content picker.
     */
    $scope.$on('content-picker__close-start', () => {
        // Keep community in sync with the content picker. We only store ids though.
        Widget.getCurrent().properties.community = map(widgetPostListSettings.community, 'id');

        widgetPostListSettings.updateTags();
    });

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const currentWidget = Widget.getCurrent();
        const widgetUuidPrefix = `widget-${currentWidget.uuid}-`;

        widgetPostListSettings.content = Content.getCurrent();
        currentWidget.properties = currentWidget.properties || {};

        widgetPostListSettings.contentPickerId += currentWidget.uuid;

        widgetPostListSettings.listKey = angular.isDefined(widgetPostListSettings.content.id)
            ? widgetUuidPrefix + widgetPostListSettings.content.id
            : widgetUuidPrefix + generateUUID();

        widgetPostListSettings.showFilters = Features.hasFeature('social');

        _handleBackwardCompatibility();

        _initTags();

        _updateOrderChoices();

        const community = Community.getCurrent();
        widgetPostListSettings.isSpace = community ? community.renderingType === 'space' : false;
    }

    init();
}

// ///////////////////////////

angular.module('Controllers').controller('WidgetPostListSettingsController', WidgetPostListSettingsController);

// ///////////////////////////

export { WidgetPostListSettingsController };
