import * as React from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames';

import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import * as types from '../types';
import * as styleTypes from './types';
import { withOnChangeProperty, stoppedEvent } from '../../utils';
import { DEFAULT_CELL_WIDGET_STYLE, DEFAULT_PLAIN_CELL_WIDGET_STYLE, DEFAULT_WIDGET_STYLE } from './constants';
import { Area } from './widget/area/Area';
import { Content } from './widget/content/Content';
import { Main } from './widget/main/Main';

/**
 * Renders a style editor inspector.
 */
export class WidgetStyle extends React.PureComponent {
    static propTypes = {
        /** Define if the cell of the widget is in plain mode */
        isInPlainCell: bool,
        name: types.properties,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: func.isRequired,
        /** Called when the styling state changes, with `(state)`. */
        onChangeState: func,
        /** Current state style. */
        state: styleTypes.state,
        /** Current widget style. */
        value: styleTypes.styles,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);

        this.state = {
            area: null,
        };

        /**
         * Toggles an area style editor (when clicking on a part of the proxy).
         *
         * @param {string} area       The name of the clicked area.
         * @param {Object} occurrence The event object.
         */
        this.toggleArea = (area, occurrence) => {
            stoppedEvent(occurrence);
            this.setState({ area: this.state.area === area ? null : area });
        };

        /**
         * Toggles the main area style editor (when clicking on the outer border of the proxy).
         *
         * @param  {Object} occurrence The event object.
         * @return {void}   Nothing.
         */
        this.toggleMainArea = (occurrence) => {
            switch (occurrence.target.className) {
                case 'widget-style-content__outside':
                case 'widget-style-area__outside':
                    return this.toggleArea('main', occurrence);

                default:
                    return undefined;
            }
        };

        /**
         * Focuses the outer border when mouse overing.
         *
         * @param  {Object} occurrence The event object.
         * @return {void}   Nothing.
         */
        this.onMouseOver = (occurrence) => {
            switch (occurrence.target.className) {
                case 'widget-style-content__outside':
                case 'widget-style-area__outside':
                    return this.setState({ over: true });

                default:
                    return this.setState({ over: false });
            }
        };

        this.onMouseOut = () => this.setState({ over: false });
    }

    render() {
        const { isInPlainCell, onChangeState, value = {}, state, hideMoreLink, hideTitle } = this.props;
        const { area, over } = this.state;
        let { defaults = cloneDeep(DEFAULT_WIDGET_STYLE) } = this.state;

        // Manage the modification of margin when the cell is in plain mode.
        defaults = merge(
            defaults,
            isInPlainCell ? cloneDeep(DEFAULT_PLAIN_CELL_WIDGET_STYLE) : cloneDeep(DEFAULT_CELL_WIDGET_STYLE),
        );

        return (
            <div
                className={classNames(
                    'widget-style',
                    area && `widget-style--${area}-open`,
                    over && 'widget-style--is-focus',
                )}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={this.toggleMainArea}
            >
                <Area
                    hide={hideTitle}
                    value={value.header}
                    name="header"
                    onChange={this.onChangeProperty}
                    active={area === 'header'}
                    onToggle={this.toggleArea}
                    defaults={defaults.header}
                />
                <Content
                    value={value.content}
                    name="content"
                    onChange={this.onChangeProperty}
                    active={area === 'content'}
                    onToggle={this.toggleArea}
                    defaults={defaults.content}
                />
                <Area
                    hide={hideMoreLink}
                    value={value.footer}
                    name="footer"
                    onChange={this.onChangeProperty}
                    active={area === 'footer'}
                    onToggle={this.toggleArea}
                    defaults={defaults.footer}
                    onChangeState={onChangeState}
                    state={state}
                />
                <Main
                    value={value.main}
                    name="main"
                    onChange={this.onChangeProperty}
                    active={area === 'main'}
                    defaults={defaults.main}
                    isInPlainCell={isInPlainCell}
                />
            </div>
        );
    }
}
