import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, FlexBoxProps, Orientation } from '@lumapps/lumx/react';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

import './Content.scss';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-content` as const;

type ContentProps = Pick<FlexBoxProps, 'as' | 'children' | 'gap'>;

/**
 * Main content of the component
 */
export const Content = ({ as, children, gap }: ContentProps) => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, orientation } = useBlockVideoContext();

    if (!canDisplay('AUTHOR', 'DATE', 'DESCRIPTION', 'GROUP_DETAILS', 'SITE_DETAILS', 'STATUS', 'TITLE', 'VIEWS')) {
        return null;
    }

    return (
        <FlexBox
            as={as}
            className={block()}
            fillSpace={isLoading && orientation === Orientation.horizontal}
            gap={gap}
            orientation={Orientation.vertical}
        >
            {children}
        </FlexBox>
    );
};
