/* istanbul ignore file */
import { isSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { CustomerSettingsSideNavigation } from './CustomerSettingsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isSuperAdmin(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedCustomerSettingsSideNavigation = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerSettingsSideNavigation);

export { ConnectedCustomerSettingsSideNavigation as CustomerSettingsSideNavigation };
