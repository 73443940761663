/* eslint-disable react/forbid-elements */
/* eslint-disable lumapps/no-classname-strings */
import React from 'react';

import { htmlDecode } from '@lumapps/utils/string/htmlDecode';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BLOCK_INTRO_CLASSNAME } from '../../constants';
import { BlockIntro as BlockIntroProps } from '../../type';

import './index.scss';

/**
 * Block that allows rendering a simple text on a page.
 */
export const BlockIntro: BlockComponent<BlockIntroProps> = ({ text }) => (
    <p className={BLOCK_INTRO_CLASSNAME}>{htmlDecode(text)}</p>
);

BlockIntro.displayName = 'BlockIntro';
