import { useCallback, useMemo, useState } from 'react';

import identity from 'lodash/fp/identity';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import isObject from 'lodash/fp/isObject';
import map from 'lodash/fp/map';
import pickBy from 'lodash/fp/pickBy';
import pipe from 'lodash/fp/pipe';
import property from 'lodash/fp/property';
import reject from 'lodash/fp/reject';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useDispatch } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { WIDGET_SURVEY } from '../keys';
import { SurveyBlockProps, RawChoice, SurveyProperties, Values } from '../types';
import { formatAnswers } from '../utils/formatAnswers';
import { updateAnswers } from '../utils/updateAnswers';
import { useGetUserSurveyData } from './useGetUserSurveyData';
import { useSetUserSurveyData } from './useSetUserSurveyData';

export function useSurveyBlock({
    legacyWidgetId,
    properties,
    uuid,
}: {
    legacyWidgetId?: string;
    properties?: SurveyProperties;
    uuid: string;
}) {
    const dispatch = useDispatch();
    const { translateObject } = useTranslate();
    const { error: notifyError, success: notifySuccess } = useNotification();

    const choices = useMemo(
        () =>
            pipe(
                map(({ value, identifier }: RawChoice) => ({
                    label: translateObject(value),
                    value: identifier,
                })),
                reject(pipe(property('label'), isNil)),
            )(properties?.questionSettings?.choices) as SurveyBlockProps['choices'],
        [properties?.questionSettings?.choices, translateObject],
    );

    const [areResultsHidden, setAreResultsHidden] = useState(false);
    const [answersCount, setAnswersCount] = useState(properties?.stats?.count ?? 0);
    const [answers, setAnswers] = useState(formatAnswers(choices, properties?.stats?.answers || {}, answersCount));

    const { isFetching, userSurveyData } = useGetUserSurveyData(legacyWidgetId);
    const { saveUserSurveyData } = useSetUserSurveyData(
        legacyWidgetId ?? '',
        () => notifySuccess({ translate: WIDGET_SURVEY.ANSWER_SAVED }),
        () => {
            // Error callback: canceling optimistic update by restoring initial answers
            formatAnswers(choices, properties?.stats?.answers || {}, properties?.stats?.count ?? 0);
            setAreResultsHidden(true);
        },
    );

    const userChoices = userSurveyData?.value;

    const onSave = useCallback(
        (value: string | Values) => {
            // No value or in the "isMultiple" case no true value
            if (!value || (isObject(value) && isEmpty(pickBy(identity, value)))) {
                notifyError({ translate: WIDGET_SURVEY.ANSWER_EMPTY });
            } else {
                saveUserSurveyData(value);
                // Optimistic update
                updateAnswers({
                    previousUserChoices: userChoices,
                    newUserChoices: value,
                    answers,
                    answersCount,
                    handleAnswersChange: setAnswers,
                    handleAnswersCountChange: setAnswersCount,
                });
                setAreResultsHidden(false);
            }
        },
        [answers, answersCount, notifyError, saveUserSurveyData, userChoices],
    );

    const onClear = useCallback(() => setAreResultsHidden(true), [setAreResultsHidden]);

    return useMemo(() => {
        const question = translateObject(properties?.questionSettings?.title);
        if (!properties || !legacyWidgetId || !question || isEmpty(choices)) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
            return undefined;
        }

        return {
            answersCount,
            answers,
            areResultsHidden,
            choices,
            isAnswersCountHidden: !!properties.hideToolbar,
            isAnswerUpdateDisabled: !!properties.cantChangeAnswer,
            isLoading: isFetching,
            isMultiple: !!properties.questionSettings?.isMultiple,
            isOpen: !!properties.isOpen,
            onClear,
            onSave,
            question,
            surveyId: legacyWidgetId,
            theme: properties.style?.content?.theme,
            userChoices,
        };
    }, [
        answers,
        answersCount,
        areResultsHidden,
        choices,
        dispatch,
        isFetching,
        legacyWidgetId,
        onClear,
        onSave,
        properties,
        translateObject,
        userChoices,
        uuid,
    ]);
}
