import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Link as LumxLink, type LinkProps as LumxLinkProps, Text, type TextProps } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router';

import { getPlaylistRouteById } from '../../../routes';
import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

import './Link.scss';

const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-link` as const;

interface LinkProps extends Omit<LumxLinkProps, 'className' | 'linkAs' | 'to'> {
    /** Component children */
    children?: React.ReactNode;
    /**
     * Control whether the text should truncate or not.
     * Setting as `true` will make the text truncate on a single line.
     * Setting as `{ lines: number }` will make the text truncate on a multiple lines.
     */
    truncate?: TextProps['truncate'];
}

/**
 * Component to use to link something to the playlist page
 */
export const Link = ({ children, truncate, ...linkProps }: LinkProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { isLoading, playlist } = useBlockPlaylistContext();

    /**
     * ✅ We don't want to return a link until the component has loaded
     * We simply return children which should take care of their skeletons
     */
    if (isLoading || !playlist) {
        return <Text as="span">{children}</Text>;
    }

    const title = typeof playlist.title === 'string' ? playlist.title : playlist.title?.value;

    return (
        <LumxLink
            className={block({ 'is-truncated': Boolean(truncate) })}
            linkAs={RouterLink}
            to={getPlaylistRouteById({ id: playlist.id })}
            {...linkProps}
        >
            <Text as="span" truncate={truncate}>
                {children
                    ? React.Children.map(
                          children,
                          (child) =>
                              React.isValidElement<{ isInteractive: boolean }>(child) &&
                              React.cloneElement(child, { isInteractive: true }),
                      )
                    : title}
            </Text>
        </LumxLink>
    );
};
