import React from 'react';

import find from 'lodash/find';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiFormatSize } from '@lumapps/lumx/icons';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { TypographyEditor } from '../../../types';

import './index.scss';

const CLASSNAME = 'block-type-selector';

export interface BlockTypeOption {
    type: string;
    label: string;
    renderer?: React.FC<{ label: string }>;
    default?: boolean;
    icon?: string;
}

export const useBlockTypeSelector = ({
    blockTypeOptions,
    iconOnly,
    withIcon,
    withVerticalIcon = true,
    withChildIcon,
    tooltipLabel,
    otherProps,
}: {
    blockTypeOptions: BlockTypeOption[];
    iconOnly?: boolean;
    withIcon?: boolean;
    withVerticalIcon?: boolean;
    withChildIcon?: boolean;
    tooltipLabel?: string;
    otherProps?: any;
}): ToolbarItem => {
    const { get } = useDataAttributes('block-type-selector');
    const { element } = useClassnames(CLASSNAME);

    const editor = useSlateStatic() as ReactEditor & TypographyEditor;

    const blockTypeAtSelection = editor.getBlockType();
    const currentBlock = blockTypeAtSelection ? find(blockTypeOptions, { type: blockTypeAtSelection }) : null;

    // Disable selector if no selection or if current block is not recognized.
    const disabledBlockTypeSelector = editor.selection && !currentBlock;

    /**
     * The label is "fixed" in a state, and recomputed on submenu close (triggerProps: onToggle),
     * because if the label is recomputed immediatly, the Submenu ToolbarItem get forced to closed before
     * the "closeRoot". It cause problems such as automatically open other submenu on focus, even aria-disabled ones
     */
    const labelValue = React.useMemo(() => {
        return iconOnly ? '' : currentBlock?.label ?? (find(blockTypeOptions, 'default')?.label || '');
    }, [blockTypeOptions, currentBlock?.label, iconOnly]);
    const [labelState, setLabelState] = React.useState(labelValue);

    const icon = React.useMemo(() => {
        return withChildIcon
            ? currentBlock?.icon ?? (find(blockTypeOptions, 'default')?.icon || undefined)
            : mdiFormatSize;
    }, [blockTypeOptions, currentBlock?.icon, withChildIcon]);

    /**
     * On selection change, we want to update the label displayed in the submenu
     * "labelValue" is excluded of the dependencies list, because it's the "closeRoot" problem we fix
     */
    React.useEffect(() => {
        setLabelState(labelValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor.selection, iconOnly]);

    return {
        type: 'submenu',
        itemKey: 'blocktype-submenu',
        verticalModeProps: {
            icon: withVerticalIcon ? icon : undefined,
            tooltipLabel,
        },
        icon: withIcon ? icon : undefined,
        label: labelState,
        tooltipLabel,
        'aria-disabled': Boolean(disabledBlockTypeSelector),
        childrenOptions: blockTypeOptions.map((b) => {
            const Renderer = b.renderer;
            return {
                type: 'toggle-option',
                isChecked: blockTypeAtSelection === b.type || (Boolean(b.default) && !blockTypeAtSelection),
                label: Renderer ? <Renderer label={b.label} /> : b.label,
                itemKey: b.type,
                icon: b.icon,
                onClick: () => {
                    // Last selection (before opening the dropdown) or on the last row in content.
                    const at = editor.selection || editor.getLastSelection();
                    editor.changeBlockType(b.type, at);
                },
                'aria-disabled': !editor.isBlockTypeAllowed(b.type),
                otherProps: {
                    ...get({ element: 'option', action: b.type }),
                    className: `${otherProps?.className} ${element('menu-option')}`,
                },
            };
        }),
        otherProps: {
            ...otherProps,
            triggerProps: {
                onToggle: (isOpen: boolean) => {
                    if (!isOpen) {
                        setLabelState(labelValue);
                    }
                },
            },
        },
    };
};
