import React, { useRef, useEffect } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiBell, mdiRecord } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Size, Placement, Offset, Icon, ColorPalette, PopoverDialog } from '@lumapps/lumx/react';
import { useOnNavigation } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDropdown } from '@lumapps/utils/hooks/useDropdown';
import { useInfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';

import { useNotificationLabel } from '../../hooks/useNotificationLabel';
import { NotificationStatus } from '../../types';
import { NotificationList, NotificationListProps } from '../NotificationList';
import { NotificationListHeader, NotificationListHeaderProps, TITLE_ID } from '../NotificationListHeader';

import './index.scss';

export type NotificationMenuProps = NotificationListProps &
    Omit<
        NotificationListHeaderProps,
        'onSettingsClick' | 'onFilterAll' | 'onFilterUnread' | 'notificationStatus' | 'hasMore'
    > & {
        /** Is notifications loading? */
        isLoading?: boolean;
        /** Is the dropdown opened */
        isOpen?: boolean;
        /** Is there at least one notification - read or unread */
        hasNotifications?: boolean;
        /** An error occured while fetching notifications */
        hasError?: boolean;
        /** Function to get notifications count */
        countUnread(): void;
        /** Function to get notifications */
        listNotifications(notificationStatus?: NotificationStatus, cursor?: string): void;
        /** notification count */
        unreadNotificationsCount?: number;
        /** The dropdown placement */
        placement?: Placement;
        /** Is there more notification to load ? */
        more: boolean;
        /** Pagination cursor */
        cursor: string | undefined;
        /** The dropdown offset */
        offset?: Offset;
        /** whether this component should be rendered or not */
        isVisible: boolean;
        /** functions to set notifications as read / unread / deleted in backend */
        setAsRead: (notificationId: string) => void;
        setAsUnread: (notificationId: string) => void;
        deleteNotification(notificationId: string, isNotificationRead: boolean): void;
        /** custom styles passed down to the notifications icon */
        styles?: Record<string, any>;
        /** action triggered when the notifications refreshes */
        refreshNotifications(notificationStatus: NotificationStatus, forceRefresh?: boolean): void;
    };

const CLASSNAME = 'notification-center-menu';
const SCOPE = 'notifications';
const NotificationMenu: React.FC<NotificationMenuProps> = ({
    isOpen = false,
    isLoading = false,
    hasError = false,
    hasNotifications = false,
    countUnread,
    listNotifications,
    unreadNotificationsCount = 0,
    placement = Placement.BOTTOM_END,
    deleteAll,
    setAllAsRead,
    notifications,
    setAsRead,
    setAsUnread,
    deleteNotification,
    more,
    cursor,
    offset = {},
    isVisible = true,
    styles = {},
    refreshNotifications,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const anchorNotificationDropdownRef = useRef(null);
    const [notificationStatus, setNotificationStatus] = React.useState(NotificationStatus.all);
    const { isOpen: isDropdownOpen, setIsOpen: setDropdownIsOpen } = useDropdown(isOpen);
    const { get } = useDataAttributes(SCOPE);
    const popoverRef = useRef(null);
    const labelRef = useRef(null);
    const buttonTitle = translateKey(GLOBAL.NOTIFICATIONS);
    const notificationsTitle = useNotificationLabel(unreadNotificationsCount);
    const forceRefreshRef = useRef<boolean>(false);

    const onInfiniteScroll = () => {
        if (more && !isLoading) {
            listNotifications(notificationStatus, cursor);
        }
    };

    useInfiniteScroll(popoverRef, onInfiniteScroll);

    useEffect(() => {
        // Count unread notification on button visible.
        if (isVisible) {
            countUnread();
        }
    }, [countUnread, isVisible]);

    useEffect(() => {
        // List or refresh notification on dropdown open.
        if (isDropdownOpen) {
            refreshNotifications(notificationStatus, forceRefreshRef.current);
            forceRefreshRef.current = false;
        }
    }, [isDropdownOpen, notificationStatus, refreshNotifications]);

    useOnNavigation(() => {
        // Count unread notification on react app navigation.
        if (isVisible) {
            countUnread();
        }
    }, [refreshNotifications, isVisible]);

    if (!isVisible) {
        return null;
    }

    const openDropdown = (open: boolean) => () => setDropdownIsOpen(open);

    const setNotificationRead = (notificationId: string, isRead: boolean, shouldCloseDropdown = true) => {
        if (shouldCloseDropdown) {
            setDropdownIsOpen(false);
        }

        if (isRead) {
            setAsRead(notificationId);
        } else {
            setAsUnread(notificationId);
        }
    };

    const onSettingsClick = () => {
        openDropdown(false)();
    };

    const handleFilterAll = () => {
        if (notificationStatus !== NotificationStatus.all) {
            setNotificationStatus(NotificationStatus.all);
            forceRefreshRef.current = true;
        }
    };

    const handleFilterUnread = () => {
        if (notificationStatus !== NotificationStatus.unread) {
            setNotificationStatus(NotificationStatus.unread);
            forceRefreshRef.current = true;
        }
    };

    return (
        <div className={CLASSNAME}>
            {unreadNotificationsCount > 0 && (
                <Icon className={element('badge')} color={ColorPalette.red} icon={mdiRecord} size={Size.xs} />
            )}

            <IconButton
                ref={anchorNotificationDropdownRef}
                emphasis={Emphasis.low}
                icon={mdiBell}
                size={Size.m}
                onClick={openDropdown(!isDropdownOpen)}
                className={element('button', { 'is-active': isDropdownOpen })}
                color={styles.themeColor}
                label={`${buttonTitle}, ${notificationsTitle}`}
                {...get({ element: 'menu', type: unreadNotificationsCount.toString() })}
            />
            <PopoverDialog
                aria-labelledby={TITLE_ID}
                isOpen={isDropdownOpen}
                anchorRef={anchorNotificationDropdownRef}
                placement={placement}
                onClose={openDropdown(isOpen)}
                offset={offset}
                fitWithinViewportHeight
                focusElement={labelRef}
                className={element('popover')}
            >
                <div ref={popoverRef} className={element('popover-content')}>
                    <NotificationListHeader
                        hasError={hasError}
                        hasNotifications={hasNotifications}
                        unreadNotificationsCount={unreadNotificationsCount}
                        isLoading={isLoading}
                        deleteAll={deleteAll}
                        setAllAsRead={setAllAsRead}
                        onSettingsClick={onSettingsClick}
                        labelRef={labelRef}
                        notificationStatus={notificationStatus}
                        onFilterAll={handleFilterAll}
                        onFilterUnread={handleFilterUnread}
                    />
                    <NotificationList
                        notifications={notifications}
                        setNotificationRead={setNotificationRead}
                        deleteNotification={deleteNotification}
                        closeNotificationMenu={() => setDropdownIsOpen(false)}
                        hasError={hasError}
                        hasNotifications={hasNotifications}
                        isLoading={isLoading}
                        listNotifications={listNotifications}
                    />
                </div>
            </PopoverDialog>
        </div>
    );
};

export { NotificationMenu, CLASSNAME };
