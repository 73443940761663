export enum WIDGET_DIRECTORY_ENTRIES {
    FAVORITE_BUTTON_ARIA = 'FRONT.DIRECTORY_ENTRIES.FAVORITE_BUTTON.ARIA',
    ENTRY_SAVED = 'ADMIN_DIRECTORY_ENTRY_SAVE_SUCCESS',
    ENTRY_DELETED = 'ADMIN_DIRECTORY_ENTRY_DELETE_SUCCESS',
    DELETE_ENTRY_CONFIRM_TITLE = 'WIDGET_TYPE_DIRECTORY-ENTRY_DELETE',
    DELETE_ENTRY_CONFIRM_MESSAGE = 'WIDGET_TYPE_DIRECTORY-ENTRY_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565518425
     */
    REMOVE_ENTRY_FROM_FAVORITE = 'DIRECTORY.REMOVE_ENTRY_FROM_FAVORITE',
}
