import React from 'react';

import {
    EventTypes,
    type GetCurrentTimeEvent,
    type GetDurationEvent,
    type OnPlayerEvent,
    type PauseEvent,
    type PlayEvent,
    type SetCurrentTimeEvent,
    type SnapshotEvent,
} from './types';

export type UsePlayerEventsParams = {
    /** Callback called when there is a chapter change in the video */
    onChapterChange?: (start: number) => void;
    /** Callback called when the player is unmounted */
    onDispose?: () => void;
    /** Callback called when the video ends */
    onEnded?: () => void;
    /** Callback called when you want to get the current time of the video */
    onGetCurrentTime?: (time: number) => void;
    /** Callback called when you want to get the duration of the video */
    onGetDuration?: (duration: number) => void;
    /** Callback called when metadata of the video have been loaded (native 'loadedmetadata' video event) */
    onLoadedMetadata?: () => void;
    /** Callback called when the player gets paused */
    onPause?: () => void;
    /** Callback called when the player gets play */
    onPlay?: () => void;
    /** Callback called when the player is ready */
    onReady?: () => void;
    /** Callback called after the player seeked in the video (after navigating in the video timeline) */
    onSeeked?: () => void;
    /** Callback called when a snapshot of the video has been made */
    onSnapshot?: (snapshot: Blob) => void;
};

/**
 * Use this hook to control the player programmatically.
 * @example
 * const { setCurrentTime } = usePlayerEvents(ref);
 * setCurrentTime(20) // Go to 20s in video
 *
 * @example
 * const onChapterChange = console.log('chapter change')
 * usePlayerEvents(ref, { onChapterChange }) // logs 'chapter change' when the video goes to another chapter
 */
export const usePlayerEvents = (
    /** Iframe ref to use with event. */
    playerRef: React.RefObject<HTMLIFrameElement>,
    {
        onChapterChange,
        onDispose,
        onEnded,
        onGetCurrentTime,
        onGetDuration,
        onLoadedMetadata,
        onPause,
        onPlay,
        onReady,
        onSeeked,
        onSnapshot,
    }: UsePlayerEventsParams = {},
) => {
    const handleMessage = React.useCallback(
        ({ data: event, source }: MessageEvent<OnPlayerEvent>) => {
            if (source !== playerRef.current?.contentWindow) {
                return;
            }
            switch (event.type) {
                case EventTypes.ChaptersChange: {
                    if (onChapterChange) {
                        onChapterChange(event.data.newChapter.startTime);
                    }

                    break;
                }

                case EventTypes.Dispose: {
                    if (onDispose) {
                        onDispose();
                    }

                    break;
                }

                case EventTypes.Ended: {
                    if (onEnded) {
                        onEnded();
                    }

                    break;
                }

                case EventTypes.GetCurrentTime: {
                    if (onGetCurrentTime) {
                        onGetCurrentTime(event.data.currentTime);
                    }

                    break;
                }

                case EventTypes.GetDuration: {
                    if (onGetDuration) {
                        onGetDuration(event.data.duration);
                    }

                    break;
                }

                case EventTypes.GetSnapshot: {
                    if (onSnapshot) {
                        onSnapshot(event.data.snapshot);
                    }

                    break;
                }

                case EventTypes.LoadedMetadata: {
                    if (onLoadedMetadata) {
                        onLoadedMetadata();
                    }

                    break;
                }

                case EventTypes.Pause: {
                    if (onPause) {
                        onPause();
                    }

                    break;
                }

                case EventTypes.Play: {
                    if (onPlay) {
                        onPlay();
                    }

                    break;
                }

                case EventTypes.Ready: {
                    if (onReady) {
                        onReady();
                    }

                    break;
                }

                case EventTypes.Seeked: {
                    if (onSeeked) {
                        onSeeked();
                    }

                    break;
                }

                default: {
                    break;
                }
            }
        },
        [
            onChapterChange,
            onDispose,
            onEnded,
            onGetCurrentTime,
            onGetDuration,
            onLoadedMetadata,
            onPause,
            onPlay,
            onReady,
            onSeeked,
            onSnapshot,
            playerRef,
        ],
    );

    const getCurrentTime = React.useCallback(() => {
        const messageEvent: GetCurrentTimeEvent = {
            type: EventTypes.GetCurrentTime,
        };

        if (playerRef.current?.contentWindow) {
            playerRef.current.contentWindow.postMessage(messageEvent, '*');
        }
    }, [playerRef]);

    const getDuration = React.useCallback(() => {
        const messageEvent: GetDurationEvent = {
            type: EventTypes.GetDuration,
        };

        if (playerRef.current?.contentWindow) {
            playerRef.current.contentWindow.postMessage(messageEvent, '*');
        }
    }, [playerRef]);

    const getSnapshot = React.useCallback(() => {
        const messageEvent: SnapshotEvent = { type: EventTypes.GetSnapshot };

        if (playerRef.current?.contentWindow) {
            playerRef.current.contentWindow.postMessage(messageEvent, '*');
        }
    }, [playerRef]);

    const pause = React.useCallback(() => {
        const messageEvent: PauseEvent = {
            type: EventTypes.Pause,
        };

        if (playerRef.current?.contentWindow) {
            playerRef.current.contentWindow.postMessage(messageEvent, '*');
        }
    }, [playerRef]);

    const play = React.useCallback(() => {
        const messageEvent: PlayEvent = {
            type: EventTypes.Play,
        };

        if (playerRef.current?.contentWindow) {
            playerRef.current.contentWindow.postMessage(messageEvent, '*');
        }
    }, [playerRef]);

    const setCurrentTime = React.useCallback(
        (time: number) => {
            const messageEvent: SetCurrentTimeEvent = {
                type: EventTypes.SetCurrentTime,
                data: {
                    currentTime: time,
                },
            };

            if (playerRef.current?.contentWindow) {
                playerRef.current.contentWindow.postMessage(messageEvent, '*');
            }
        },
        [playerRef],
    );

    React.useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [handleMessage]);

    return {
        /** Function you can use to get the current time of the player you are referring to */
        getCurrentTime,
        /** Function you can use to get the total video duration of the player you are referring to */
        getDuration,
        /** Function you can use to take an image snapshot of the player you are referring to */
        getSnapshot,
        /** Function you can use to pause the player you are referring to */
        pause,
        /** Function you can use to play the player you are referring to */
        play,
        /** Function you can use to set the current time of the player you are referring to (to seek in the video) */
        setCurrentTime,
    };
};
