import { createUrl, getAbsoluteUrl } from '@lumapps/router/utils';

import { getPlayVideoRouteById } from '../routes';

interface GetFullVideoLinkProps {
    /** Video id */
    videoId: string;
    /** Instance slug */
    instanceSlug?: string;
}
/**
 * This util returns the absolute url of a video page
 *
 * @param {string} id
 *
 * @returns {string} Absolute url of a video
 *
 * @example https://my-domain.com/qa-layout/home/ls/play-playlist/1234-56è8-9101112
 */
export const getFullVideoLink = ({ videoId, instanceSlug }: GetFullVideoLinkProps) => {
    return getAbsoluteUrl(createUrl(getPlayVideoRouteById(videoId, instanceSlug)));
};
