import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { classnames, useClassnames } from '@lumapps/classnames';
import { GenericProps, Text } from '@lumapps/lumx/react';

import { BASE_CLASSNAME } from '../constants';

export type MenuSectionProps = {
    children: React.ReactNode;
    label: React.ReactNode;
} & GenericProps;

export const CLASSNAME = `${BASE_CLASSNAME}-section`;

/**
 * Menu section (ARIA `group`).
 *
 * To use inside a `Menu` or `Menubar`.
 *
 * @family Menus
 */
export const MenuSection = React.forwardRef((props: MenuSectionProps, ref: React.Ref<HTMLElement>) => {
    const { className, children, label, ...forwardedProps } = props;
    const { block, element } = useClassnames(CLASSNAME);
    const hidden = props['aria-hidden'] === 'true';
    const sectionId = React.useMemo(() => (!hidden ? uniqueId(CLASSNAME) : undefined), [hidden]);
    return (
        <li
            ref={ref as React.Ref<HTMLLIElement>}
            role="none"
            className={classnames(className, block())}
            {...forwardedProps}
        >
            {label && (
                <Text
                    as="span"
                    className={element('header', undefined, 'lumx-list-subheader')}
                    aria-hidden="true"
                    id={sectionId}
                >
                    {label}
                </Text>
            )}
            <ul role={!hidden ? 'group' : undefined} aria-labelledby={sectionId} className={element('content')}>
                {children}
            </ul>
        </li>
    );
});

MenuSection.displayName = 'MenuSection';
