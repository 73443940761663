/* istanbul ignore file */
import { LegacyServerError } from '@lumapps/base-api';
import { getLegacyServerError } from '@lumapps/base-api/utils/getServerError';
import { actions as communityActions } from '@lumapps/communities/ducks/frontOfficeSlice';
import { setSelectedCommunityId } from '@lumapps/communities/ducks/thunks/setSelectedCommunityId';
import { success, error } from '@lumapps/notifications';
import { share as sharePost } from '@lumapps/posts/api';
import { Post } from '@lumapps/posts/types';
import { Dispatch } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { copyText } from '@lumapps/utils/browser/copyText';

import { SHARE } from '../keys';

export const copyToClipboard = (string: string) => async (dispatch: Dispatch) => {
    copyText(string);
    dispatch(success({ translate: GLOBAL.COPIED }));
};

/** Share a post to other communities */
export const shareToCommunity =
    (postId: string, communitiesIds: string[], shareId: string, callback?: (updatedPost: Post) => void) =>
    async (dispatch: Dispatch) => {
        dispatch(communityActions.setIsLoadingForPostDialog(true));
        try {
            const { data: updatedPost } = await sharePost(postId, communitiesIds, shareId);
            dispatch(success({ translate: SHARE.POST_SHARED_SUCCESS }));

            if (callback) {
                callback(updatedPost);
            }
        } catch (exception) {
            dispatch(error({ translate: getLegacyServerError(exception as LegacyServerError) }));
        } finally {
            dispatch(setSelectedCommunityId(''));
            dispatch(communityActions.setIsLoadingForPostDialog(false));
        }
    };
