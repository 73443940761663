import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { GlobalSize } from '@lumapps/lumx/react';

/**
 * Resize an image.
 *
 * @param {string}  imageUrl         The url of the image to be resized.
 * @param {number}  size             The size wanted.
 * @param {boolean} shouldBeResized  Whether the image should be resized if already sized.
 */
export const resizeImage = (imageUrl: string, size: number, replace = true) => {
    if (!imageUrl) {
        return '';
    }
    let newUrl = imageUrl;
    if (/\/$/g.test(newUrl)) {
        newUrl = newUrl.slice(0, -1);
    }
    const sizingPattern = /(=s[0-9]+)$/;
    const isAlreadyResized = sizingPattern.test(newUrl);
    if (isAlreadyResized && replace) {
        newUrl = newUrl.replace(sizingPattern, `=s${size}`);
    } else if (!isAlreadyResized) {
        newUrl = `${newUrl}=s${size}`;
    }

    return newUrl;
};

/**
 * Resize an image to the width matching the lumx size.
 * This can be very useful to match the actual image width to the one it will be displayed at.
 *
 * @param {string}  imageUrl         The url of the image to be resized.
 * @param {number}  lumxSize         The lumx size wanted.
 * @param {boolean} shouldBeResized  Whether the image should be resized if already sized.
 */
export const resizeImageToLumxSize = (imageUrl: string, lumxSize: GlobalSize, replace = true) => {
    return resizeImage(imageUrl, REAL_SIZE_FOR_LUMX_SIZE[lumxSize], replace);
};
