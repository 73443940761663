import { Video } from '@lumapps/play/api/types';
import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

import type { Playlist } from './types';

const playlistsRouteBase = `/${URL_PREFIX}/play-playlist`;

const playlistRoute: Route = {
    path: `${playlistsRouteBase}/:id`,
    appId: AppId.frontOffice,
};

export type GetPlaylistRouteByIdParams = {
    /** Playlist id */
    id: Playlist['id'];
    /** Instance slug */
    instanceSlug?: Route['instanceSlug'];
    /** Video id */
    videoId?: Video['id'];
};

const getPlaylistRouteById = ({ id, instanceSlug, videoId }: GetPlaylistRouteByIdParams): Route => ({
    ...playlistRoute,
    ...(instanceSlug && { instanceSlug }),
    /**
     * The mobilePageDescriptor key is used in the Mobile Webview React App to trigger
     * an event to the host mobile app indicating which page should be opened next.
     *
     * @see https://we.lumapps.com/battlefront/ls/content/5831209251635200/react-webview-app
     */
    mobilePageDescriptor: {
        type: 'playlist',
        id,
        videoId,
    },
    params: { id },
    ...(videoId && {
        query: { v: videoId },
    }),
});

export { getPlaylistRouteById, playlistRoute, playlistsRouteBase };
