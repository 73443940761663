import React from 'react';

import { GLOBAL, useTranslate } from '@lumapps/translations';
import { formatDate } from '@lumapps/utils/date/formatDate';

import { BaseAttribute, AttributeProps } from './BaseAttribute';

type UserActionTypes = 'create' | 'update';

const translationsForUserActionType: Record<UserActionTypes, string> = {
    create: GLOBAL.CREATED_ON,
    update: GLOBAL.LAST_UPDATED_ON,
};

export interface UserActionProps extends Partial<AttributeProps> {
    /** date when the action occurred */
    date: string;
    /** type of action that the user performed */
    type: UserActionTypes;
    /** title override */
    label?: string;
}

/**
 * Component to be used for displaying an action that a user has performed while using the `GenericEntityBlock`
 * pattern. For example, when an entity was created, or when an entity was updated.
 *
 * @param UserActionProps
 * @family Blocks
 * @returns UserActionAttribute
 */
export const UserActionAttribute: React.FC<UserActionProps> = ({ label, type, date, ...props }) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const title = label || translateKey(translationsForUserActionType[type]);

    return (
        <BaseAttribute
            as="span"
            key="user-action-attribute"
            {...props}
            value={translateAndReplace(GLOBAL.FORMAT_COLON, {
                key: title,
                value: formatDate(date),
            })}
        />
    );
};
