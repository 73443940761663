export const PLAYER_CLASSNAME = 'video-player';

export const INVALID_THUMBNAIL_KEYWORDS = [
    'encoding-72.jpg',
    'encoding-216.jpg',
    'encoding-360.jpg',
    'encoding-540.jpg',
    'encoding-720.jpg',
    'encoding-1080.jpg',
    'unavailable-72.jpg',
    'unavailable-216.jpg',
    'unavailable-360.jpg',
    'unavailable-540.jpg',
    'unavailable-720.jpg',
    'unavailable-1080.jpg',
];
