/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const contentTagAdminRoute: Route = {
    path: '/admin/tag/:uid',
    legacyId: 'app.admin.tag',
    appId: AppId.backOffice,
};

export const adminContentTag = (uid: string): Route => ({
    ...contentTagAdminRoute,
    params: { customContentType: uid, uid },
});
