import React, { Reducer, useEffect, useMemo, useReducer } from 'react';

import range from 'lodash/range';

import { Instance } from '@lumapps/instance/types';
import { Orientation, Theme } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useDispatch } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { BlockGrid } from '@lumapps/widget-base/components/Block/BlockGrid/BlockGrid';
import { BlockList } from '@lumapps/widget-base/components/Block/BlockList/BlockList';
import { BlockSlideshow } from '@lumapps/widget-base/components/Block/BlockSlideshow';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ContainerBlockVariant } from '@lumapps/widget-base/types';

import { SITE_BLOCK_MIN_WIDTH } from '../../constants';
import { initialState, reducer } from '../../ducks/slice';
import { fetchMoreList, fetchSiteList, markSiteFavorite, markSiteUnFavorite } from '../../ducks/thunks';
import { ClientComputedSiteListProps, SiteListState } from '../../types';
import { SiteBlock } from '../SiteBlock';
import { SiteBlockSkeleton } from '../SiteBlock/siteBlockSkeleton';

const MAX_SKELETON_SIZE = 3;

export const getSiteListSkeleton = ({
    label,
    isFavoriteIconVisible,
    orderedInfo,
    viewMode,
    theme,
    maxNumber,
}: {
    theme?: Theme;
    orderedInfo: string[];
    viewMode?: 'grid' | 'list';
    label: string;
    isFavoriteIconVisible: boolean;
    maxNumber: number;
}) =>
    range(Math.min(MAX_SKELETON_SIZE, maxNumber)).map((value, index) => {
        const key = `${label}-${index}`;
        return (
            <SiteBlockSkeleton
                theme={theme}
                orientation={viewMode === 'grid' ? Orientation.vertical : Orientation.horizontal}
                orderedInfo={orderedInfo}
                isFavoriteIconVisible={isFavoriteIconVisible}
                key={key}
            />
        );
    });

export const ClientComputedSiteList: React.FC<ClientComputedSiteListProps> = ({ uuid, properties, isMainWidget }) => {
    const {
        type,
        instancesIds,
        style,
        fields,
        viewMode,
        viewModeVariant,
        itemsMargin,
        itemsPerLine,
        itemsSeparator,
        isFavoriteButtonHidden,
        maxNumber,
        slideshowEnabled,
        slideshowAutoplay = false,
        slideshowInterval,
        listOrder,
        listOrderDir,
    } = properties || {};
    const { theme, ...contentStyles } = style?.content || {};
    const { translate } = useTranslate();
    const orderedInfo = fields?.filter((field) => field.enable).map((field) => field.name) || [];
    const variant = viewModeVariant === 'ungroup' ? ContainerBlockVariant.ungrouped : ContainerBlockVariant.grouped;

    const [combinedState, dispatchAction] = useReducer<Reducer<any, any>>(reducer, initialState);
    const state: SiteListState = combinedState;

    const maxResults = maxNumber ?? 30;

    const fetchData = useMemo(
        () => fetchSiteList({ dispatchAction, type, instancesIds, maxResults, listOrder, listOrderDir }),
        [instancesIds, listOrder, listOrderDir, maxResults, type],
    );

    const fetchMoreData = useMemo(() => fetchMoreList({ dispatchAction, state: state.list }), [state]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { success, error } = useNotification();
    const markFavorite = useMemo(
        () => markSiteFavorite({ state, dispatchAction, success, error }),
        [error, state, success],
    );
    const markUnFavorite = useMemo(
        () => markSiteUnFavorite({ state, dispatchAction, success, error }),
        [error, state, success],
    );

    const isLoading =
        state.list.status && [BaseLoadingStatus.loading, BaseLoadingStatus.loadingMore].includes(state.list.status);

    const sites = state.list.items;

    const isEmpty =
        // Pick mode and no site was picked
        (type === 'pick' && !instancesIds?.length) ||
        // Other modes (favorites/all) when not loading and no site was fetched
        (!isLoading && !sites?.length);
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (isEmpty) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, isEmpty, uuid]);

    const { favoritesStatus, unFavoritesStatus } = state.widget;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onLoadMore = isMainWidget && !isLoading && state.list.hasMore && fetchMoreData ? fetchMoreData : undefined;

    const mapInfo = () =>
        sites?.map((info: Instance) => {
            const {
                id,
                title,
                name,
                subtitle,
                slug,
                markedAsFavorite = false,
                deletableFromFavorite = true,
                isInFavoriteFeedKeys = [],
            } = info;

            const isForcedFav = isInFavoriteFeedKeys.length > 0;

            const isFavorite =
                favoritesStatus[id] === BaseLoadingStatus.idle || (unFavoritesStatus[id] ? false : markedAsFavorite);
            return (
                <SiteBlock
                    key={id}
                    theme={theme}
                    orientation={viewMode === 'grid' ? Orientation.vertical : Orientation.horizontal}
                    favorite={() => markFavorite(id)}
                    unfavorite={() => markUnFavorite(id)}
                    orderedInfo={orderedInfo}
                    title={translate(title)}
                    name={translate(name)}
                    subtitle={translate(subtitle)}
                    slug={slug}
                    isFavoriteDisabled={
                        favoritesStatus[id] === BaseLoadingStatus.loading ||
                        unFavoritesStatus[id] === BaseLoadingStatus.loading ||
                        (isForcedFav ? !deletableFromFavorite : false)
                    }
                    isFavorite={isFavorite}
                    isFavoriteIconVisible={!isFavoriteButtonHidden}
                />
            );
        });

    if (viewMode === 'grid') {
        if (slideshowEnabled) {
            return (
                <BlockSlideshow
                    contentStyles={contentStyles}
                    theme={theme}
                    isAutoplayEnabled={slideshowAutoplay}
                    autoplayDelay={slideshowInterval}
                    visibleItems={itemsPerLine}
                    variant={variant}
                    navigation
                    itemMinWidth={SITE_BLOCK_MIN_WIDTH}
                >
                    {isLoading &&
                        getSiteListSkeleton({
                            theme,
                            viewMode,
                            label: 'loading',
                            isFavoriteIconVisible: !isFavoriteButtonHidden,
                            orderedInfo,
                            maxNumber: itemsPerLine ?? 1,
                        })}
                    {mapInfo()}
                </BlockSlideshow>
            );
        }
        return (
            <BlockGrid
                onLoadMore={onLoadMore}
                contentStyles={contentStyles}
                theme={theme}
                variant={variant}
                columns={itemsPerLine}
                loadingState="loaded"
                itemMinWidth={SITE_BLOCK_MIN_WIDTH}
            >
                {mapInfo()}
                {isLoading &&
                    getSiteListSkeleton({
                        theme,
                        viewMode,
                        label: 'loading',
                        isFavoriteIconVisible: !isFavoriteButtonHidden,
                        orderedInfo,
                        maxNumber: maxResults,
                    })}
            </BlockGrid>
        );
    }

    return (
        <BlockList
            onLoadMore={onLoadMore}
            contentStyles={contentStyles}
            theme={theme}
            variant={variant}
            hasSeparator={variant === ContainerBlockVariant.grouped && itemsSeparator}
            loadingState="loaded"
            gapSize={itemsMargin}
        >
            {mapInfo()}
            {isLoading &&
                getSiteListSkeleton({
                    theme,
                    viewMode,
                    label: 'loading',
                    isFavoriteIconVisible: !isFavoriteButtonHidden,
                    orderedInfo,
                    maxNumber: maxResults,
                })}
        </BlockList>
    );
};
