import { connect } from '@lumapps/redux/react';
import get from 'lodash/get';

import { updateWidgetContent } from 'components/components/widget/ducks/widget_actions';

import { closeModal, setSourceLang, requestTranslation } from '../_ducks/widget_translation_action';
import { WidgetTranslationDialog } from './WidgetTranslationDialog';

const mapStateToProps = (state) => {
    return {
        inputLanguage: get(state, ['locale', 'inputLanguage'], ''),
        isError: get(state, ['loadingState', 'widgetTranslation', 'failure']),
        isLoading: get(state, ['loadingState', 'widgetTranslation', 'loading']),
        isOpen: get(state, ['widgetTranslation', 'isModalOpen'], false),
        sourceLang: get(state, ['widgetTranslation', 'sourceLang'], ''),
        widget: get(state, ['legacyWidget', 'currentWidget'], {}),
    };
};

const mapDispatchToProps = {
    closeModal,
    requestTranslation,
    setSourceLang,
    updateWidgetContent,
};

const connectedWidgetTranslationDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WidgetTranslationDialog);

/////////////////////////////

export { connectedWidgetTranslationDialog as WidgetTranslationDialog };
