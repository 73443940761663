/* eslint-disable react/forbid-elements */
import React from 'react';

import { classnames } from '@lumapps/classnames';
import { ALIGNMENTS, ALIGNMENT_CLASSES } from '@lumapps/wrex/constants';
import type { ElementRender } from '@lumapps/wrex/types';

import { PARAGRAPH } from '../../../constants';
import { ParagraphElement } from '../../../types';

import './index.scss';

export const Paragraph: ElementRender<ParagraphElement, HTMLParagraphElement> = (props) => {
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const alignment = element?.alignment ? element.alignment : ALIGNMENTS.start;
    return (
        <p
            {...forwardedProps}
            className={classnames(className, 'wrex-content-body', ALIGNMENT_CLASSES[alignment])}
            ref={elementRef}
        >
            {children}
        </p>
    );
};
Paragraph.displayName = PARAGRAPH;
