import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockTitle as BlockTitleProps } from '../../type';

import './index.scss';

const CLASSNAME = 'block-title';

export const BlockTitle: BlockComponent<BlockTitleProps> = ({ text, theme = Theme.light }) => {
    const { block } = useClassnames(CLASSNAME);
    // eslint-disable-next-line react/forbid-elements
    return <h1 className={block(`theme-${theme}`)}>{text}</h1>;
};

BlockTitle.displayName = 'BlockTitle';
