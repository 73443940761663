import React, { useState, useEffect, useRef } from 'react';

import map from 'lodash/map';
import classNames from 'classnames';

import { mdiCrop } from '@lumapps/lumx/icons';
import { Dialog, Size, IconButton, Button, Emphasis, Toolbar, ButtonGroup, Tooltip } from '@lumapps/lumx/react';

import { CropFocalPointPicker } from '@lumapps/focal-point';
import { DEFAULT_ASPECT_RATIO } from './CropperArea';
import { translate as t } from '../../../../translations';

/**
 * Displays possible actions a user can do.
 * All action comes with :
 *  - A tooltip that will be displayed on mouseover.
 *  - A function to execute once the action button is clicked.
 *  - An icon identifier.
 *  - An `isActive` predicate (optional).
 *
 * @param  {Array}        actions All the possible actions a user can interact with.
 * @return {ReactElement} The formatted actions to display.
 */
const displayActions = ({ actions }) =>
    map(actions, ({ tooltipKey, isActive, onClick, icon, text, disabled }, key) => {
        return icon ? (
            <IconButton
                className={classNames('cropper__action', {
                    'cropper__action--is-active': isActive && isActive(),
                })}
                disabled={disabled}
                emphasis={Emphasis.low}
                isSelected={isActive && isActive()}
                size={Size.m}
                icon={icon}
                onClick={onClick}
                label={t(tooltipKey)}
            />
        ) : (
            <Tooltip key={key} placement="top" label={t(tooltipKey)}>
                <Button disabled={disabled} emphasis={Emphasis.low} onClick={onClick}>
                    {t(text)}
                </Button>
            </Tooltip>
        );
    });

/**
 * Displays the cropper dialog.
 */
const CropperDialog = ({ mediaToCrop, onClose, isOpen, onConfirm, ...props }) => {
    const CENTER_FOCAL_POINT = { x: 0, y: 0 };

    const isCropEnable = !!mediaToCrop.cropInfo && mediaToCrop.cropInfo !== {};
    const hasBeenTouched = !!mediaToCrop.focalPoint || isCropEnable;

    const [cropData, setCropData] = useState(mediaToCrop.cropInfo);
    const [focus, setFocus] = useState(mediaToCrop.focalPoint ?? CENTER_FOCAL_POINT );
    const [cropEnabled, setCropEnabled] = useState(isCropEnable);
    const [ratio, setRatio] = useState(isCropEnable ? DEFAULT_ASPECT_RATIO : NaN);
    const [isOkBtnDisabled, setOkBtnDisabled] = useState(false);
    const [isResetBtnEnabled, setResetBtnEnabled] = useState(hasBeenTouched);
    const cropFocalPickerRef = useRef(null);

    const onClickConfirm = () => {
        setOkBtnDisabled(true);

        let roundedCropInfo;
        let canvasCrop;

        if (cropEnabled) {
            roundedCropInfo = {
                x: Math.round(cropData.x),
                y: Math.round(cropData.y),
                width: Math.round(cropData.width),
                height: Math.round(cropData.height),
            };
            canvasCrop = cropFocalPickerRef.current.getCroppedCanvas();
        }

        onConfirm(focus, roundedCropInfo, canvasCrop);
    };

    /**
     * All available resize actions.
     *
     * @type {Object}
     * @readOnly
     * @constant
     */
    const CROPPER_RESIZE_ACTIONS = [
        {
            icon: mdiCrop,
            isActive: () => cropEnabled,
            onClick: () => {
                const inverted = !cropEnabled;
                setRatio(inverted ? DEFAULT_ASPECT_RATIO : NaN);
                setCropEnabled(inverted);
            },
            tooltipKey: 'RATIO_3:2',
        },
    ];

    const CROPPER_RESET_ACTION = [
        {
            text: 'RESET',
            disabled: !isResetBtnEnabled,
            onClick: () => {
                setResetBtnEnabled(false);
                setCropEnabled(false);
                setRatio(NaN);
                cropFocalPickerRef.current.reset();
            },
            tooltipKey: 'RESET',
        },
    ];

    useEffect(() => {
        if (Number.isNaN(ratio)) {
            setCropEnabled(false);
        }
    }, [ratio]);

    return (
        <Dialog
            disableBackdropClick
            className="cropper-dialog"
            zIndex={3000} // TODO: zindex should be autoincremented. Should be removed when all dialog will be in React
            maxWidth={false}
            size={Size.tiny}
            isOpen={isOpen}
            {...props}
        >
            <header>
                <Toolbar label={<span className="lumx-typography-title">{t('FRONT.CROPPER_DIALOG.TITLE')}</span>} />
            </header>
            <div className="cropper__body">
                <p className="lumx-typography-subtitle1">{t('FRONT.CROPPER_DIALOG.SUBTITLE')}</p>
                <p className="lumx-typography-body1">{t('FRONT.CROPPER_DIALOG.BODY')}</p>

                <div className="cropper__content">
                    {mediaToCrop && mediaToCrop.servingUrl && (
                        <CropFocalPointPicker
                            ref={cropFocalPickerRef}
                            src={mediaToCrop.servingUrl}
                            focusData={focus}
                            cropData={cropData}
                            aspectRatio={ratio}
                            className="picker"
                            guides={false}
                            enabled={cropEnabled}
                            onFocusChange={(data) => {
                                setFocus(data.focus);
                                setResetBtnEnabled(true);
                            }}
                            onCropChange={() => {
                                setCropData(cropFocalPickerRef.current.getData());
                                setResetBtnEnabled(true);
                            }}
                        />
                    )}
                    <div className="cropper__actions">
                        <div className="cropper__actions--col-left">
                            {displayActions({
                                actions: CROPPER_RESET_ACTION,
                            })}
                        </div>
                        <div className="cropper__actions--col-right">
                            {displayActions({
                                actions: CROPPER_RESIZE_ACTIONS,
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Toolbar
                    after={
                        <ButtonGroup>
                            <Button emphasis={Emphasis.medium} onClick={onClose} className="lumx-spacing-margin-right">
                                {t('CANCEL')}
                            </Button>
                            <Button disabled={isOkBtnDisabled} onClick={onClickConfirm}>
                                {t('OK')}
                            </Button>
                        </ButtonGroup>
                    }
                />
            </footer>
        </Dialog>
    );
};

export { CropperDialog };
