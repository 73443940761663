(function IIFE() {
    'use strict';

    /////////////////////////////

    function FolderFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/media/folder/:methodKey', {}, {
            folder: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            move: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'move',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('FolderFactory', FolderFactory);
})();
