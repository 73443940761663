import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import {
    MAX_ASIDE_SEARCH_RESULTS,
    ENGINES,
    ZENDESK_RESULT_TYPES_TRANSLATIONS,
    getPrefixedFacet,
    ZENDESK_RESULT_TYPES,
    SEARCH_API_TIMEOUT,
} from '../constants';
import { SEARCH } from '../keys';
import { ZendeskSearchResult, SearchResults, SearchParams, SearchTab, FacetFilter } from '../types';
import { createSearchEngineFilter, createSearchEngineMetadata } from '../utils';

const zendeskSearchApi = new BaseApi({ path: 'zendesk' });

export interface ZendeskSearchParams extends SearchParams {
    instanceId?: string;
    customerId?: string;
    langs?: string[];
}

export interface ZendeskSearchResults extends SearchResults {
    items: ZendeskSearchResult[];
}

/**
 * From the current selected options, we generate the necessary object for the backend to respond to our
 * current filters.
 * @param facets - list of filters to apply
 */
const generateFilterOptions = (facets?: Record<string, string>) => {
    const facetType = facets && facets[getPrefixedFacet('type')];

    return {
        type: facetType ? [facetType] : [ZENDESK_RESULT_TYPES.ARTICLE, ZENDESK_RESULT_TYPES.TICKET],
    };
};

/**
 * Returns a promise with the search results from the zendesk API.
 * @param params parameters that will be used to search on Zendesk
 */
const search = ({
    query,
    maxResults = MAX_ASIDE_SEARCH_RESULTS,
    instanceId,
    langs,
    customerId,
    facets: selectedFacets,
    timeout = SEARCH_API_TIMEOUT,
    filter,
}: ZendeskSearchParams): Promise<ZendeskSearchResults> => {
    const filterOptions = generateFilterOptions(selectedFacets);

    return zendeskSearchApi
        .postCacheFirst(
            'search',
            {
                maxResults,
                query: decodeURIComponentSafe(query),
                currentInstance: instanceId,
                customer: customerId,
                filters: filterOptions,
                ignore403: true,
                lang: langs,
            },
            CACHE_TYPE.MEMORY,
            PRIORITY.HIGH,
            { timeout },
        )
        .then((response) => {
            const { items = [], more } = response.data;

            /**
             * Since Zendesk does not return the selected filters from the backend,
             * we need to add them to the response so that the UI shows them appropriately.
             *
             * So we add the necessary information (filters, metadata and facets) so that the UI
             * can properly render the results.
             */
            const filters = [createSearchEngineFilter(ENGINES.ZENDESK)];

            const metadata: Record<string, SearchTab> = {
                [ENGINES.ZENDESK]: createSearchEngineMetadata(ENGINES.ZENDESK),
            };

            /**
             * Since the backend does not return the facets selected, we need to create them by hand.
             * We already know that we want to display the type, so we add that one with the possible values
             * article and ticket.
             *
             * And if the user has selected a value for that facet, we can retrieve it
             * from the filters that we sent to the backend. With them, we can determine which filter is selected
             * and show the UI accordingly.
             *
             * If there are no results, then there is no need to show these facets since the user will not be able
             * to filter a list of empty results. In that case, we do not add any facets.
             */
            const facets: FacetFilter[] =
                items.length > 0
                    ? [
                          {
                              id: 'type',
                              field: 'type',
                              label: SEARCH.ZENDESK_TYPE,
                              choices: [
                                  {
                                      label: ZENDESK_RESULT_TYPES_TRANSLATIONS[ZENDESK_RESULT_TYPES.ARTICLE],
                                      value: ZENDESK_RESULT_TYPES.ARTICLE,
                                  },
                                  {
                                      label: ZENDESK_RESULT_TYPES_TRANSLATIONS[ZENDESK_RESULT_TYPES.TICKET],
                                      value: ZENDESK_RESULT_TYPES.TICKET,
                                  },
                              ],
                              shouldDisplayAllValues: false,
                              /**
                               * If a facet type was selected, we know that we sent to the backend that specific facet.
                               * So to show the selected filter we go to the `filterOptions` object and retrieve the first type value.
                               * We can only select one filter at a time, so retreiving the first one will always be the correct type.
                               */
                              value:
                                  selectedFacets && selectedFacets[getPrefixedFacet('type')]
                                      ? {
                                            label: ZENDESK_RESULT_TYPES_TRANSLATIONS[
                                                filterOptions.type[0] as ZENDESK_RESULT_TYPES
                                            ],
                                            value: filterOptions.type[0],
                                        }
                                      : undefined,
                          },
                      ]
                    : [];

            /**
             * TODO: we need to make sure that the backend returns the amount of results wanted.
             * Sadly, this is not the case today, so we need to do the slice when we are showing these results on the right
             * hand side. When the zendesk filter is applied, we just show all results without the slice.
             */
            const results = filter && filter === ENGINES.ZENDESK ? items : items.slice(0, maxResults);

            return {
                items: results,
                /** Same thing here, unfortunately the backend does not paginate, so we need to do this by hand */
                more: more || items.length > maxResults,
                filters,
                metadata,
                facets,
            };
        });
};

export { zendeskSearchApi, search };
