import map from 'lodash/map';

/////////////////////////////

function DrivePickerBuilderService($window, Config, Features, Translation) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The base MIME types name for all G Suite / Google Drive types.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _GOOGLE_MIME_TYPE = 'application/vnd.google-apps.';

    /**
     * The MIME type for draw.io files.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DRAWIO_MIME_TYPE = 'application/vnd.jgraph.mxfile.realtime,application/vnd.jgraph.mxfile';

    /**
     * Handlers for picker views config. Configure Google Drive picker docsView.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _DOCS_VIEW_PARAMS_HANDLERS = {
        includeFolders: function setIncludeFoldersFlag(docsView, value) {
            docsView.setIncludeFolders(value);
        },
        mimeTypes: function setMimeTypes(docsView, value) {
            docsView.setMimeTypes(value);
        },
        parent: function setParentFolder(docsView, value) {
            docsView.setParent(value);
        },
        selectFolder: function setSelectFolderEnabledFlag(docsView, value) {
            docsView.setSelectFolderEnabled(value);
        },
        shared: function setShareDocsFlag(docsView, value) {
            docsView.setOwnedByMe(!value);
        },
        starred: function setStarredDocsFlag(docsView, value) {
            docsView.setStarred(value);
        },
        teamDrives: function setTeamDrivesDocsFlag(docsView, value) {
            docsView.setEnableTeamDrives(value);
        },
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a view config to given viewsConfig array.
     *
     * @param {Array}  viewsConfig        Views config array.
     * @param {Object} viewConfig         View config to add.
     * @param {Object} [commonViewConfig] Optional viewConfig to extend from.
     */
    function _addViewConfig(viewsConfig, viewConfig, commonViewConfig) {
        viewsConfig.push(angular.extend(angular.fastCopy(commonViewConfig) || {}, viewConfig));
    }

    /**
     * Build a picker using the picker views.
     *
     * @param  {string}   token          Token for Google Drive picker API.
     * @param  {Array}    docsViews      The picker views.
     * @param  {Function} pickerCallback Function to be called after user select a file.
     * @param  {Object}   [params]       Picker parameters.
     * @return {Object}   The Google Drive picker object.
     */
    function _buildPicker(token, docsViews, pickerCallback, params) {
        params = params || {};

        const enableTeamDrives = Features.hasFeature('team_drive');
        const enableUrlInput = enableTeamDrives && params.showType === 'folder';
        const picker = new $window.google.picker.PickerBuilder()
            .enableFeature(params.selectMultiple ? $window.google.picker.Feature.MULTISELECT_ENABLED : undefined)
            .enableFeature(enableTeamDrives ? $window.google.picker.Feature.SUPPORT_TEAM_DRIVES : undefined)
            .enableFeature(enableUrlInput ? $window.google.picker.Feature.wx : undefined)
            .setLocale(Translation.getLang('current', 'drive'))
            .setOAuthToken(token)
            .setDeveloperKey(Config.DEVELOPER_KEY)
            .setCallback(pickerCallback);

        angular.forEach(docsViews, function addView(view) {
            picker.addView(view);
        });

        return picker.build();
    }

    /**
     * Build a Google Drive picker view using the given config.
     *
     * @param  {Object} config The picker view (tab) configuration.
     * @return {Object} The Google Drive picker view.
     */
    function _docsViewBuilder(config) {
        const docsView = new (config.viewType || $window.google.picker.DocsView)(config.viewId);

        if (!angular.isDefinedAndFilled(config)) {
            return docsView;
        }

        angular.forEach(config, function setDocsViewParam(docsViewParamValue, docsViewParamName) {
            if (_DOCS_VIEW_PARAMS_HANDLERS.hasOwnProperty(docsViewParamName)) {
                _DOCS_VIEW_PARAMS_HANDLERS[docsViewParamName](docsView, docsViewParamValue);
            }
        });

        return docsView;
    }

    /**
     * Return common options for DocsView.
     *
     * @param  {string}  viewId          The type of view we want to display.
     * @param  {boolean} includeFolders  Do we display folders to user.
     * @param  {boolean} canSelectFolder Allows the user to select a folder in Google Drive.
     * @param  {string}  mimeTypes       Filter documents based on those mime types.
     * @return {Object}  The configuration for MyDrive tab.
     */
    function _getCommonDocsViewConfig(viewId, includeFolders, canSelectFolder, mimeTypes) {
        return {
            includeFolders,
            mimeTypes,
            selectFolder: canSelectFolder,
            viewId,
        };
    }

    /**
     * Get the mime type depending on given showType.
     *
     * @param  {string} [showType] Type of docs to show.
     * @return {string} The mime type.
     */
    function _getMime(showType) {
        if (!showType) {
            return undefined;
        }

        if (showType === 'drawio') {
            return _DRAWIO_MIME_TYPE;
        }

        return _GOOGLE_MIME_TYPE + showType;
    }

    /**
     * Get the correct view id depending on viewMode.
     *
     * @param  {string} [viewMode] View mode of the tab.
     * @return {string} Google Drive picker view id.
     */
    function _getViewId(viewMode) {
        if (viewMode === 'video') {
            return $window.google.picker.ViewId.DOCS_VIDEOS;
        }

        return $window.google.picker.ViewId.DOCS;
    }

    /**
     * Use the picker configuration to generate picker views.
     *
     * @param  {Array} viewsConfig An array containing all picker views (tabs) configuration.
     * @return {Array} Google drive picker views.
     */
    function _makeDrivePickerViews(viewsConfig) {
        const docsViews = [];

        Array.prototype.push.apply(
            docsViews,
            map(viewsConfig, function makeDocsPickerView(viewConfig) {
                return _docsViewBuilder(viewConfig);
            }),
        );

        return docsViews;
    }

    /**
     * Makes a typical configuration for our Google Drive pickers.
     *
     * @param  {string}  viewId            What type of picker should we display. Eg. ViewId.DOCS, ViewId.DOCS_VIDEOS
     * @param  {string}  type              Type of picker to display. Eg. "full", "uploader".
     * @param  {string}  mimeTypes         List of comma separated mime types to filter.
     * @param  {boolean} [includeFolders]  Should we include (display) folders.
     * @param  {boolean} [canSelectFolder] Allows the user to select a folder in Google Drive.
     * @param  {boolean} [teamDrives=true] Whether we want to display team drive tab.
     * @param  {string}  [destination]     If type is upload, tells where to store file.
     * @return {Array}   The Google Drive picker tabs configurations.
     */
    function _makeDrivePickerViewsConfig(
        viewId,
        type,
        mimeTypes,
        includeFolders,
        canSelectFolder,
        teamDrives = true,
        destination,
    ) {
        const isFullMode = type === 'full';
        const viewsConfig = [];
        let commonDocsViewConfig;

        if (angular.isUndefinedOrEmpty(type) || isFullMode) {
            commonDocsViewConfig = _getCommonDocsViewConfig(viewId, includeFolders, canSelectFolder, mimeTypes);

            /* eslint-disable object-curly-newline */
            // "My Drive" tab.
            _addViewConfig(viewsConfig, { shared: false }, commonDocsViewConfig);
            // "Team Drives" tab.
            if (Features.hasFeature('team_drive') && teamDrives) {
                _addViewConfig(viewsConfig, { teamDrives }, commonDocsViewConfig);
            }
            // "Shared with me" tab.
            _addViewConfig(viewsConfig, { shared: true }, commonDocsViewConfig);
            // "Recently picked" tab.
            _addViewConfig(viewsConfig, { viewId: $window.google.picker.ViewId.RECENTLY_PICKED });
            /* eslint-enable object-curly-newline */
        }

        if (type === 'uploader' || isFullMode) {
            // "Upload" tab.
            _addViewConfig(viewsConfig, {
                parent: destination,
                viewType: $window.google.picker.DocsUploadView,
            });
        }

        return viewsConfig;
    }

    /**
     * Should the user select folders (or files).
     *
     * @param  {string}  [showType] Type of content display.
     * @return {boolean} Should the user select folders.
     */
    function _shouldSelectFolders(showType) {
        return showType === 'folder';
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Create a picker with the auth token.
     *
     * @param  {string}   token    Authorization token from the Drive api.
     * @param  {Object}   params   Parameters for Google Drive picker configuration.
     * @param  {Function} pickerCb Function to be called after a file is selected.
     * @return {Object}   The newly created picker.
     */
    function createPicker(token, params, pickerCb) {
        const viewId = _getViewId(params.viewMode);
        const selectFolders = _shouldSelectFolders(params.showType);
        const mimeTypes = _getMime(params.showType);

        const viewsConfig = _makeDrivePickerViewsConfig(
            viewId,
            params.type,
            mimeTypes,
            params.includeFolders,
            selectFolders,
            params.teamDrives,
            params.destination,
        );
        const docsViews = _makeDrivePickerViews(viewsConfig);

        return _buildPicker(token, docsViews, pickerCb, params);
    }

    /////////////////////////////

    service.createPicker = createPicker;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('DrivePickerBuilder', DrivePickerBuilderService);

/////////////////////////////

export { DrivePickerBuilderService };
