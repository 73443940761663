import React from 'react';

import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { FlexBox } from '@lumapps/lumx/react';
import { useDispatch } from '@lumapps/redux/react';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { MetadataSelectionContentList, ClientComputedMetadataSelectionProps } from '../../types';
import { MetadataSelectionBlock } from '../MetadataSelectionBlock';
import { MetadataSelectionSkeleton } from '../MetadataSelectionBlock/MetadataSelectionSkeleton';

export const ClientComputedMetadataSelection: React.FC<ClientComputedMetadataSelectionProps> = ({
    fetchContentMetadata,
    currentContent,
    properties,
    uuid,
}) => {
    const dispatch = useDispatch();
    const { id, metadataDetails, actionState, metadata } = currentContent;
    const { style, metadata: widgetMetadata } = properties || {};
    const theme = style?.content?.theme;
    const metadataDetailsState = actionState?.fetch?.metadataDetails;
    const [contentMetadata, setContentMetadata] = React.useState<MetadataSelectionContentList>([]);
    const [isEmptyWidget, setIsEmptyWidget] = React.useState(isEmpty(metadata) && isEmpty(widgetMetadata));

    // Fetch content metadata
    React.useEffect(() => {
        // Check if metadata haven't already been fetched
        if (id && !includes(['loading', 'loaded', 'error'], metadataDetailsState)) {
            fetchContentMetadata(id);
        }
    }, [fetchContentMetadata, id, metadataDetailsState]);

    React.useEffect(() => {
        if (metadataDetailsState === 'loaded') {
            // Filtering out metadata that are not part of the metadata selection widget
            const metadataSelectionContent = widgetMetadata?.map((rootMetatdataId) => {
                const metadataSibling = metadataDetails?.filter(({ rootId }) => rootId === rootMetatdataId);
                const metadataRoot = metadataSibling ? metadataSibling[0]?.root : null;
                return { metadataRoot, metadataSibling };
            });
            // Removing metadata from selection if root or sibling metadata is not defined
            const filteredMetadataSelectionContent = metadataSelectionContent?.filter(
                ({ metadataRoot, metadataSibling }) => Boolean(metadataRoot) && Boolean(metadataSibling),
            );

            if (filteredMetadataSelectionContent?.length) {
                setContentMetadata(filteredMetadataSelectionContent as MetadataSelectionContentList);
            } else {
                setIsEmptyWidget(true);
            }
        }
    }, [widgetMetadata, metadataDetails, metadataDetailsState]);

    // Removing widget from layout if no content to display
    React.useEffect(() => {
        if (isEmptyWidget) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, isEmptyWidget, uuid]);

    if (isEmptyWidget) {
        return null;
    }

    return (
        <ErrorBoundary>
            <FlexBox orientation="vertical" gap="big">
                {metadataDetailsState === 'loading' ? (
                    <>
                        <MetadataSelectionSkeleton theme={theme} />
                        <MetadataSelectionSkeleton theme={theme} />
                    </>
                ) : (
                    contentMetadata.map(({ metadataRoot, metadataSibling }) => (
                        <MetadataSelectionBlock
                            key={metadataRoot.id}
                            category={metadataRoot}
                            metadata={metadataSibling}
                            theme={theme}
                            contentTypeId={currentContent.customContentType}
                        />
                    ))
                )}
            </FlexBox>
        </ErrorBoundary>
    );
};

ClientComputedMetadataSelection.displayName = 'ClientComputedMetadataSelection';
