export enum SEARCH {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453451
     */
    ALL_TAB = 'SEARCH_PLUS_ALL_TAB',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453455
     */
    DRIVE = 'SEARCH_PLUS_DRIVE_TAB',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453461
     */
    ZENDESK = 'SEARCH_PLUS_ZENDESK_TAB',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=14685234
     */
    SHAREPOINT = 'SEARCH_PLUS_SHAREPOINT_TAB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10273872
     */
    SEARCH_RESULTS_FOR = 'FRONT.SEARCH_RESULTS_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=76735581
     */
    RESULT_IS_PROMOTED = 'FRONT.SEARCH.RESULT_IS_PROMOTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=98745556
     */
    RESULT_IS_FEATURED = 'FRONT.SEARCH.RESULT_IS_FEATURED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209005
     */
    ZENDESK_TYPE = 'FRONT.ZENDESK.TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17813698
     */
    GOOGLE_DRIVE_PRIVACY_PRIVATE_DESCRIPTION = 'FRONT.GOOGLE_DRIVE_PRIVACY.PRIVATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17813688
     */
    GOOGLE_DRIVE_PRIVACY_PUBLIC_DESCRIPTION = 'FRONT.GOOGLE_DRIVE_PRIVACY.PUBLIC_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=17813699
     */
    GOOGLE_DRIVE_PRIVACY_PUBLIC_WITH_LINK_DESCRIPTION = 'FRONT.GOOGLE_DRIVE_PRIVACY.PUBLIC_WITH_LINK_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8256068
     */
    ZENDESK_TYPE_ARTICLE = 'FRONT.ZENDESK.TYPE.ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8256069
     */
    ZENDESK_TYPE_TICKET = 'FRONT.ZENDESK.TYPE.TICKET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209759
     */
    ZENDESK_TICKET_STATUSES_CLOSED = 'FRONT.ZENDESK.TICKET_STATUS.CLOSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209757
     */
    ZENDESK_TICKET_STATUSES_HOLD = 'FRONT.ZENDESK.TICKET_STATUS.HOLD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209742
     */
    ZENDESK_TICKET_STATUSES_OPEN = 'FRONT.ZENDESK.TICKET_STATUS.OPEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209743
     */
    ZENDESK_TICKET_STATUSES_PENDING = 'FRONT.ZENDESK.TICKET_STATUS.PENDING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209743
     */
    ZENDESK_TICKET_STATUSES_SOLVED = 'FRONT.ZENDESK.TICKET_STATUS.SOLVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8209741
     */
    ZENDESK_TICKET_STATUSES_NEW = 'FRONT.ZENDESK.TICKET_STATUS.NEW',
}
