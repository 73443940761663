import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

const playVideoRouteBase = `/${URL_PREFIX}/play-video`;

const playVideoRoute: Route = {
    path: `${playVideoRouteBase}/:id`,
    appId: AppId.frontOffice,
};

const getPlayVideoRouteById = (id: string, instanceSlug?: string): Route => ({
    ...playVideoRoute,
    params: { id },
    instanceSlug,
    /**
     * The mobilePageDescriptor key is used in the Mobile Webview React App to trigger
     * an event to the host mobile app indicating which page should be opened next.
     *
     * @see https://we.lumapps.com/battlefront/ls/content/5831209251635200/react-webview-app
     */
    mobilePageDescriptor: { type: 'video', id },
});

export { getPlayVideoRouteById, playVideoRoute, playVideoRouteBase };
