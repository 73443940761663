import React from 'react';

import { BlockArticlePreview as BlockArticle } from '@lumapps/widget-article-list/components/BlockArticlePreview';
import { BlockCommunityPreview } from '@lumapps/widget-community-list/components/BlockCommunityPreview/BlockCommunityPreview';
import { BlockPagePreview } from '@lumapps/widget-content-list/components/BlockPagePreview';
import { BlockStructuredContent } from '@lumapps/widget-contribution/components/BlockStructuredContent';
import { BlockAddDirectoryUserEntry } from '@lumapps/widget-directory-entries/components/BlockAddDirectoryUserEntry/BlockAddDirectoryUserEntry';
import { BlockDirectoryEntry } from '@lumapps/widget-directory-entries/components/BlockDirectoryEntry';
import { BlockEventPreview } from '@lumapps/widget-event-list/components/BlockEventPreview';
import { BlockEventMetadata } from '@lumapps/widget-event-metadata/components/BlockEventMetadata';
import { BlockFeaturedImage } from '@lumapps/widget-featured-image/components/BlockFeaturedImage';
import { BlockHtml } from '@lumapps/widget-html/components/BlockHtml';
import { BlockImagePreview } from '@lumapps/widget-image-gallery/components/BlockImagePreview';
import { BlockIntro } from '@lumapps/widget-intro/components/BlockIntro';
import { BlockPersonalFeedPreview } from '@lumapps/widget-personal-feed-preview/components/BlockPersonalFeedPreview';
import { BlockPlayVideo } from '@lumapps/widget-play/components/BlockPlayVideo';
import { BlockPlayVideoPlaylist } from '@lumapps/widget-playlist/components/BlockPlayVideoPlaylist';
import { BlockPost } from '@lumapps/widget-post-list/components/BlockPost';
import { BlockLearningPreview } from '@lumapps/widget-recommended-training-courses/components/BlockLearningPreview/BlockLearningPreview';
import { BlockRemote } from '@lumapps/widget-remote/components/BlockRemote';
import { BlockResourceCreationInfo } from '@lumapps/widget-resource-metadata/components/BlockResourceCreationInfo';
import { BlockStreamItem } from '@lumapps/widget-stream/components/BlockStreamItem';
import { BlockSummary } from '@lumapps/widget-summary/components/BlockSummary/BlockSummary';
import { BlockTitle } from '@lumapps/widget-title/components/BlockTitle';
import { BlockUserProfilePreview } from '@lumapps/widget-user-list/components/BlockUserProfilePreview';

import { Blocks } from '../../types';
import { BlockAugmentedContainer } from './BlockAugmentedContainer';
import { BlockCascade } from './BlockCascade';
import { BlockGrid } from './BlockGrid';
import { BlockList } from './BlockList';
import { BlockNoResults } from './BlockNoResults';
import { BlockReactions } from './BlockReactions';
import { BlockSlideshow } from './BlockSlideshow';
import { BlockTabFilteredContainer } from './BlockTabFilteredContainer';
import { BlockUser } from './BlockUser';
import { BlockUserList } from './BlockUserList';

type BlocksWithoutClientComputed = Exclude<Blocks['type'], 'BlockClientComputed'>;

/**
 * Index of all possible block renderers by block type.
 *
 * @see `Blocks` for the union type of all possible blocks.
 */
export const BLOCK_RENDERERS: { [blockType in BlocksWithoutClientComputed]: React.FC<any> } = {
    BlockNoResults,
    BlockHtml,
    BlockUser,
    BlockResourceCreationInfo,
    BlockDirectoryEntry,
    BlockAddDirectoryUserEntry,
    BlockPagePreview,
    BlockCommunityPreview,
    BlockList,
    BlockTabFilteredContainer,
    BlockSlideshow,
    BlockGrid,
    BlockCascade,
    BlockTitle,
    BlockIntro,
    BlockFeaturedImage,
    BlockImagePreview,
    BlockUserList,
    BlockUserProfilePreview,
    BlockAugmentedContainer,
    BlockStreamItem,
    BlockPersonalFeedPreview,
    BlockLearningPreview,
    /* Lazy blocks: */
    BlockReactions,
    BlockStructuredContent,
    BlockSummary,
    BlockEventMetadata,
    BlockPost,
    BlockRemote,
    BlockEventPreview,
    BlockArticle,
    BlockPlayVideo,
    BlockPlayVideoPlaylist,
};
