import { Route } from '@lumapps/router';

export interface UseWizardProps<T = void> {
    /** configuration steps */
    // eslint-disable-next-line no-use-before-define
    steps: Step<T>[];
    /** starting step */
    startingStep?: number;
    /** starting step by id */
    startingStepId?: string;
}

export interface StepProps<T = void> {
    /** callback to move the wizard to the next step */
    goToNextStep: () => void;
    /** callback to move the wizard to the previous step */
    goToPreviousStep: () => void;
    /** state of the current wizard */
    state: any;
    /** callback to set the current wizard state */
    setWizardState: (state: any) => void;
    /** index of the current step */
    index: number;
    /** Step configuration */
    // eslint-disable-next-line no-use-before-define
    step: Step<T>;
    /** whether this is the first step */
    isFirstStep: boolean;
    /** whether this is the last step */
    isLastStep: boolean;
    /** Back Button to be displayed if needed */
    backButton?: React.ReactNode;
    /** ref to pass in to the step in order to focus it when changing */
    focusRef?: React.RefObject<HTMLDivElement>;
}

export interface Step<T = void> {
    /** Step ID */
    id: string;
    /** Step component to be used for rendering the step */
    Component: React.FC<StepProps<T>>;
    /** route to update the URL for. If one step has a route, then all steps need to have a route! */
    route?: () => Route;
}

export enum PaginationMode {
    /** Full pagination mode with "..." between pages
     * Example: <[1] [2] [3] [...] [30]>
     */
    full = 'full',
    /** Compact pagination mode with "on" keyword between page number
     * Example: <[1] on [7]>
     */
    compact = 'compact',
}
