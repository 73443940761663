import { useRouteMatch } from '@lumapps/router';

import { searchRoute } from '../routes';

export const useIsSearchPage = () => {
    const match = useRouteMatch({ path: searchRoute.path });
    const isSearchPage = Boolean(match);

    return isSearchPage;
};
