import loGet from 'lodash/get';
import loSet from 'lodash/set';


/**
 * Handles the End user translation feature.
 */
function EndUserTranslationService($rootScope, Content, ContentTemplate, Features, GTranslate, Translation, User, LxNotificationService) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const service = this;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * Language configured in the user's browser.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.BROWSER_LANGUAGE = 'en';

    /**
     * Language configured in the user's browser.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.INPUT_LANGUAGE = '';

    /**
     * Widget properties to translate.
     * @constant
     * @readonly
     *
     * @type {Array}
     */
    service.PROPERTIES_TO_TRANSLATE = ['title', 'properties.content', 'properties.more.label'];

    /**
     * Widget types to translate.
     * @constant
     * @readonly
     *
     * @type {Array}
     */
    service.WIDGET_TYPES = ['intro', 'html', 'title'];

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Get the language configured in the browser and strip the last part.
     *
     * @return {string} Return the current language of the browser.
     */
    function getBrowserLanguage() {
        return navigator.language.split('_')[0].split('-')[0] || 'en';
    }

    /**
     * Get translation language for the connected user
     * 
     * @return {string} Return the translation language for the connected user
     */
    function getUserLanguage(){
        let _connectedUser = User.getConnected();
        
        if(_connectedUser.lang){
            return _connectedUser.lang
        } else if(_connectedUser.langs && _connectedUser.langs.length > 0){
            return _connectedUser.langs[0];
        } else {
            return Translation.getLang('current');
        }
    }

    /**
     * Get a list of the widgets (intro, html) in the current content.
     *
     * @return {string} Return a list of widgets (intro, html).
     */
    function getTranslatableWidgets() {
        return service.WIDGET_TYPES.reduce(
            (acc, widgetType) => [
                ...acc,
                ...ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'widgetType', widgetType),
            ],
            [],
        );
    }

    /**
     * Handles the change of state of this feature and translate or rollback widgets.
     */
    function handleWidgetTranslation(isTranslationActive) {
        const handleTranslation = isTranslationActive ? service.translateProperty : service.rollbackPropertyTranslation;

        /**
         * Handle the page title to affect title widget.
         */
        handleTranslation(Content.getCurrent(), 'title');

        /**
         * Translate Intro and HTML Widgets.
         */
        const translatableWidgets = service.getTranslatableWidgets();

        if (translatableWidgets.length > 0) {
            /**
             * Translate the labels of a widget.
             */
            for (const widget of translatableWidgets) {
                const isHtmlWidget = widget.widgetType === 'html';

                for (const propertyPath of service.PROPERTIES_TO_TRANSLATE) {
                    const isHtmlContent = isHtmlWidget && propertyPath.lastIndexOf('.content') > -1;
                    handleTranslation(widget, propertyPath, isHtmlContent);
                }
            }
        }
    }

    /**
     * Check if the feature could be used.
     *
     * @return {boolean} Whether the feature could be used or not.
     */
    function isFeatureEnabled() {
        return (
            Features.hasFeature('translation-end-user') &&
            // Remove check for now, waiting for workshop on this MVP (CMS-286).
            // service.INPUT_LANGUAGE !== service.BROWSER_LANGUAGE &&
            service.getTranslatableWidgets().length > 0
        );
    }

    /**
     * Rollback the previously translated property of a widget.
     *
     * @param {object} currentObject Widget or content containing the property.
     * @param {string} propertyPath  The path in the object to trhe property.
     * @param {boolean} isHtmlContent Whether the property is 'content' in the HTML widget.
     */
    function rollbackPropertyTranslation(currentObject, propertyPath, isHtmlContent) {
        const value = loGet(currentObject, propertyPath, {});
        const originalKey = Object.keys(value).find((key) => key.indexOf('original-') > -1);

        if (
            value &&
            // Remove check for now, waiting for workshop on this MVP (CMS-286).
            // inputLanguage !== browserLanguage &&
            originalKey
        ) {
            const langId = originalKey.substring(9);
            const newValue = {
                ...value,
                [langId]: value[originalKey],
            };

            delete newValue[originalKey];

            loSet(currentObject, propertyPath, newValue);

            // Refresh html widget when concerned.
            if (isHtmlContent) {
                $rootScope.$broadcast('content_refresh');
            }
        }
    }
    /**
     * Translate the property of a widget.
     *
     * @param {object} currentObject Widget or content containing the property.
     * @param {string} propertyPath  The path in the object to trhe property.
     * @param {boolean} isHtmlContent Whether the property is 'content' in the HTML widget.
     */
    function translateProperty(currentObject, propertyPath, isHtmlContent = false) {
        const originalValue = loGet(currentObject, propertyPath, {});
        if (!originalValue) return;
        const contentLanguage = Translation.getLangIdForContent(originalValue);
        const valueToTranslate = originalValue[contentLanguage];
        const userLanguage = service.getUserLanguage();

        // Remove check for now, waiting for workshop on this MVP (CMS-286).
        if (valueToTranslate /** && inputLanguage !== browserLanguage */) {
            GTranslate.translate(
                valueToTranslate,
                userLanguage,
                undefined,
                ({ contentText }) => {
                    const newValue = {
                        ...originalValue,
                        [`original-${contentLanguage}`]: valueToTranslate,
                        [contentLanguage]: contentText,
                    };
                    loSet(currentObject, propertyPath, newValue);

                    if (isHtmlContent) {
                        $rootScope.$broadcast('content_refresh');
                    }
                },
                (err) => {
                    const errorData = err.data.error;

                    const isConnected = User.isConnected();

                    if (!isConnected && errorData.code === 400 && errorData.message === 'REQUEST_TOO_LARGE') {
                        // ERROR 
                        LxNotificationService.error(Translation.translate('AUTO_TRANSLATION.NOT_LOGGED_RATE_LIMIT_EXCEEDED'));
                    }
                },
                isHtmlContent,
            )
        }
    }

    // ///////////////////////////

    service.getBrowserLanguage = getBrowserLanguage;
    service.getUserLanguage = getUserLanguage;
    service.getTranslatableWidgets = getTranslatableWidgets;
    service.handleWidgetTranslation = handleWidgetTranslation;
    service.isFeatureEnabled = isFeatureEnabled;
    service.rollbackPropertyTranslation = rollbackPropertyTranslation;
    service.translateProperty = translateProperty;

    // ///////////////////////////

    /**
     * Initialize the service.
     */
    service.init = function init() {
        service.BROWSER_LANGUAGE = service.getBrowserLanguage();
        // eslint-disable-next-line prefer-destructuring
        service.INPUT_LANGUAGE = Translation.inputLanguage;
    };

    service.init();

    // ///////////////////////////

    return service;
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular.module('Services').service('EndUserTranslation', EndUserTranslationService);
