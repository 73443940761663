import uniq from 'lodash/uniq';

import { Editor, Node, NodeEntry } from '@lumapps/wrex/slate';

import type { UserMentionElement } from '../types';
import { isMention } from './isMention';

export const getAllMentionedUsers = (editor: Editor, nbRoots: number) => {
    const children = [];
    for (let index = 0; index < nbRoots; index++) {
        const nodeChildren = Node.children(editor, [index]);
        const nodeChildrenArr = Array.from(nodeChildren);
        children.push(...nodeChildrenArr);
    }

    const userMentionsEntries = children.filter(([node]) => isMention(node)) as Array<NodeEntry<UserMentionElement>>;

    const userMentions = userMentionsEntries.map((mentionEntry) => mentionEntry[0].user);
    return uniq(userMentions);
};
