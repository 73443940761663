import React from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, ColorVariant, TextProps, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { BaseState } from '../BaseState';

export interface NoResultsStateProps {
    /** forwarded class name. */
    className?: string;
    /** forwarded text props */
    textProps?: Partial<TextProps>;
    /** searchValue of the input */
    searchValue?: string;
    /** text to be displayed when result is empty and filters other than query have been applied */
    emptyWithFiltersText?: string;
    /** label of the clear button */
    clearButtonLabel?: string;
    /** callback to be executed once the clear button is clicked */
    onClear?(): void;
    /** theme */
    theme?: Theme;
}

const allowedTags = ['strong', 'b'];
const CLASSNAME = 'lumx-no-results';

/**
 * No results component
 *
 * @family States
 * @param NoResultsProps
 * @returns NoResults
 */
export const NoResultsState: React.FC<NoResultsStateProps> = ({
    className = padding('all', 'big'),
    textProps,
    emptyWithFiltersText,
    searchValue,
    onClear,
    clearButtonLabel,
    theme,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();

    return (
        <BaseState
            className={classnames(CLASSNAME, className)}
            theme={theme}
            buttonProps={
                onClear
                    ? {
                          emphasis: Emphasis.medium,
                          children: clearButtonLabel || translateKey(GLOBAL.CLEAR),
                          leftIcon: mdiClose,
                          onClick: onClear,
                      }
                    : undefined
            }
            message={emptyWithFiltersText || (!searchValue ? translateKey(GLOBAL.NO_RESULTS) : undefined)}
            messageProps={{
                colorVariant: ColorVariant.N,
                dangerouslySetInnerHTML:
                    searchValue && !emptyWithFiltersText
                        ? {
                              __html: sanitizeHTML(
                                  translateAndReplace(GLOBAL.NO_RESULTS_FOR, {
                                      text: searchValue,
                                  }),
                                  { allowedTags },
                              ),
                          }
                        : undefined,
                ...textProps,
            }}
            wrapperProps={{
                role: 'alert',
            }}
        />
    );
};
