import React from 'react';
import { useContent } from '@lumapps/contents/hooks/useContent';
import {
    CustomizationComponent,
    Targets,
    PLACEMENT,
    computeCustomizationTargets,
    getComponent,
} from '@lumapps/customizations/api';

/**
 * Customizations wrapper useful for embedding the customizations component for the application
 * on the legacy side.
 */
export const CustomizationsApp = () => {
    return (
        <>
            <CustomizationComponent target={Targets.APP} placement={PLACEMENT.LEFT} />
            <CustomizationComponent target={Targets.APP} placement={PLACEMENT.UNDER} />
            <CustomizationComponent target={Targets.APP} placement={PLACEMENT.RIGHT} />
        </>
    );
};

export const CustomizationsPage = (props) => {
    const { id, placement } = props;

    const { currentContentType } = useContent();

    const pageTokens = id.split('.');

    const is404Page = 'app.front.404' === id;

    const pageId = pageTokens[pageTokens.length - 1].replace('-get', '').replace('profile', 'user-profile');

    const { id: computedPageId, type: computedPageType, shouldAddPageTypeCustomization } = computeCustomizationTargets(
        pageId,
        currentContentType,
    );


    // The 404 page already has its customizations since it is a React page
    if (is404Page) {
        return null;
    }

    if (placement === PLACEMENT.ABOVE) {
        return (
            <>
                {/**
                 * Customizations on this page, for the ABOVE placement follow a specific hierarchy, depending on specificity
                 * - First, we render the most generic customization, which is for PAGE, which applies to all pages
                 * - Second, we render the customizations associated to a specific PAGE id, like CONTENT or SEARCH
                 * - Third, we render the most specific customizations, which target either a specific content type, like NEWS, or a
                 * specific content, like CONTENT-<id>
                 */}
                <CustomizationComponent target={Targets.PAGE} placement={PLACEMENT.ABOVE} />

                {computedPageId !== Targets.APP ? (
                    <CustomizationComponent target={computedPageId} placement={PLACEMENT.ABOVE} />
                ) : null}

                {shouldAddPageTypeCustomization ? (
                    <CustomizationComponent target={computedPageType} placement={PLACEMENT.ABOVE} />
                ) : null}
            </>
        );
    }

    return (
        <>
            {/**
             * Customizations on this page, for the UNDER placement follow a specific hierarchy, depending on specificity
             * - First, we render the most specific customizations, which target either a specific content type, like NEWS, or a
             *   specific content, like CONTENT-<id>
             * - Second, we render the customizations associated to a specific PAGE id, like CONTENT or SEARCH
             * - Third, we render the most generic customization, which is for PAGE, which applies to all pages
             */}
            {shouldAddPageTypeCustomization ? (
                <CustomizationComponent target={computedPageType} placement={PLACEMENT.UNDER} />
            ) : null}

            {computedPageId !== Targets.APP ? (
                <CustomizationComponent target={computedPageId} placement={PLACEMENT.UNDER} />
            ) : null}

            <CustomizationComponent target={Targets.PAGE} placement={PLACEMENT.UNDER} />
        </>
    );
};

export const CustomizationsWidget = (props) => {
    const { id, placement } = props;

    return <CustomizationComponent target={getComponent(id)} placement={placement} />;
};
