function WidgetChannelListController() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget classes.
     *
     * @return {Array} The list of classes applied  to the widget.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        return widgetClass;
    }

    /////////////////////////////

    vm.getWidgetClass = getWidgetClass;

    /////////////////////////////

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };
}

/////////////////////////////

function WidgetChannelListDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetChannelListController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetChannelList', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-channel-list/views/widget-channel-list.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetChannelList', WidgetChannelListDirective);

/////////////////////////////

export { WidgetChannelListController, WidgetChannelListDirective };
