import React, { useState, useEffect } from 'react';

import { mdiMagnify } from '@lumapps/lumx/icons';
import {
    TextField,
    List,
    ListItem,
    ListSubheader,
    ListDivider,
    Size,
    Select,
    Progress,
    FlexBox,
    Alignment,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { ListParameters } from '../../api';
import { UserDirectory } from '../../types';

export interface UserDirectorySearchFieldProps {
    /** The selected user directory. */
    value?: UserDirectory;
    /** The label of the select input. */
    label?: string;
    /** The list of user directories to display in the select. */
    userDirectories?: UserDirectory[];
    /** The current cursor used for the infinite scroll. */
    cursor?: string;
    /** Whether the request has more results or not. */
    hasMore?: boolean;
    /** Whether the request is loading or not. */
    isLoading?: boolean;
    /** The current language. */
    lang?: string;
    /** The function called on user directory selection. */
    onChange(userDirectory: UserDirectory | null): void;
    /** The function called to get user directories. */
    fetchUserDirectories(parameters: ListParameters & { add?: boolean }): void;
}

/**
 * Display a list of user directories in a picker autocomplete fashion
 * @family Pickers
 */
const UserDirectorySearchField: React.FC<UserDirectorySearchFieldProps> = ({
    value,
    label,
    onChange,
    userDirectories,
    fetchUserDirectories,
    cursor,
    hasMore,
    isLoading,
    lang,
}) => {
    const { translateObject, translateKey } = useTranslate();
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [filterValue, setFilterValue] = useState('');

    const openSuggestions = () => {
        setShowSuggestions(true);
    };
    const selectValue = (value?.title && translateObject(value.title)) || '';

    useEffect(() => {
        fetchUserDirectories({
            maxResults: 10,
            query: filterValue,
            queryFields: ['title'],
            lang,
        });
    }, [fetchUserDirectories, filterValue, lang]);

    const closeSuggestions = () => {
        setShowSuggestions(false);
    };

    const onInfiniteScroll = () => {
        if (hasMore) {
            fetchUserDirectories({
                maxResults: 10,
                query: filterValue,
                cursor,
                queryFields: ['title'],
                lang,
                add: true,
            });
        }
    };

    const handleChange = (userDirectory: UserDirectory) => {
        onChange(userDirectory);
        closeSuggestions();
    };

    return (
        <Select
            style={{ width: '100%' }}
            isOpen={showSuggestions}
            value={selectValue}
            label={label}
            onDropdownClose={closeSuggestions}
            onInputClick={openSuggestions}
            onClear={() => onChange(null)}
            onInfiniteScroll={onInfiniteScroll}
            clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
        >
            <List isClickable>
                <ListSubheader>
                    <TextField value={filterValue} onChange={setFilterValue} icon={mdiMagnify} size={Size.tiny} />
                </ListSubheader>
                <ListDivider />
                {!isLoading && userDirectories && userDirectories.length === 0 && (
                    <ListItem key={0} size={Size.tiny}>
                        {translateKey(GLOBAL.NO_RESULTS)}
                    </ListItem>
                )}
                {userDirectories &&
                    userDirectories.length > 0 &&
                    userDirectories.map((uD) => (
                        <ListItem
                            isSelected={uD.id === value?.id}
                            size={Size.tiny}
                            isClickable
                            key={uD.id}
                            onItemSelected={() => handleChange(uD)}
                        >
                            <div>{translateObject(uD.title)}</div>
                        </ListItem>
                    ))}
                {isLoading && (
                    <FlexBox vAlign={Alignment.center}>
                        <Progress />
                    </FlexBox>
                )}
            </List>
        </Select>
    );
};

UserDirectorySearchField.defaultProps = {};

export { UserDirectorySearchField };
