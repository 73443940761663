import { GLOBAL } from '@lumapps/translations';
import { getUserMsToken } from '@lumapps/user/api';
import { UserMicrosoftToken } from '@lumapps/user/interface';

import { getOneDriveItemFromSharedURL } from '../api';
import { ONEDRIVE_PICKER_KEYS } from '../keys';
import { OneDrivePickerConfiguration } from '../types';
import { encodeSharedURL } from './encodeSharedURL';

interface FetchDocumentProps {
    url: string;
    pickerConfig: OneDrivePickerConfiguration;
    token?: UserMicrosoftToken | Promise<UserMicrosoftToken | undefined>;
}

const getAccessToken = async () => {
    try {
        const { data: token } = await getUserMsToken();

        return token;
    } catch (e) {
        throw new Error(GLOBAL.GENERIC_ERROR);
    }
};

const fetchMSDocument = async (accessToken: string, encodedUrl: string) => {
    try {
        const { data: document } = await getOneDriveItemFromSharedURL(accessToken, encodedUrl);

        return document;
    } catch (e) {
        throw new Error(ONEDRIVE_PICKER_KEYS.NO_ACCESS);
    }
};

/**
 * Try to fetch a OneDrive document based on a shared URL link (should be generated within the OneDrive application).
 * The current user microsoft token is required, to access the microsoft APIs, and to test the access rights of the user.
 * A configuration object allow for restriction in the document type we want to get.
 * The method will return either:
 *  - A promise, containing the document information.
 *  - Or an error, containing a custom error message based on the error source :
 *      - No token available
 *      - Wrongly formatted URL or no right for accessing the file
 *      - Type of document not matching the one required in the configuration
 * */
export const fetchDocument = async ({ url, pickerConfig, token }: FetchDocumentProps) => {
    /**
     * If available, we are trying to use the provided accessToken before fetching a new one.
     * */
    const { accessToken } = (await token) ?? (await getAccessToken());

    const encodedUrl = encodeSharedURL(url);

    const document = await fetchMSDocument(accessToken, encodedUrl);

    /**
     * Filter the document based on the available type in the configuration.
     * */
    switch (pickerConfig.type) {
        case 'file':
        case 'folder':
            if (!document[pickerConfig.type]) {
                throw new Error(ONEDRIVE_PICKER_KEYS.WRONG_FORMAT);
            }
            return document;
        default:
            return document;
    }
};
