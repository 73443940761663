import React, { ReactNode } from 'react';

import { typography, useClassnames } from '@lumapps/classnames';
import { DATA_LISTING_SEPARATOR } from '@lumapps/constants/specialCharacters';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { Avatar, Badge, ColorPalette, Icon, Link, Placement, Size, Text, Theme, Typography } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { UserCardPopover, useUserCardPopover } from '@lumapps/user/components/UserCardPopover';
import { DEFAULT_AVATAR } from '@lumapps/user/constants';
import { getAvatarUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';

import { UserReference } from '../../../types';

import './index.scss';

export interface BlockAuthorProps {
    /** Post author data */
    author: UserReference;
    /** Theme. */
    theme?: Theme;
    description?: ReactNode;
    avatarBadge?: {
        icon: string;
        alt: string;
    };
    publicationDate?: string;
    hideDate?: boolean;
}

/**
 * The number of days to display the date of the post as a "from now" string (e.g. "1 hour ago").
 * Above this number of days, the date of the post will be displayed as a plain date.
 *
 * @type {number}
 * @constant
 * @readonly
 */
const DELTA_DAY_FROM_NOW = 1;

const CLASSNAME = 'block-author';

/**
 * Component to render author's information in a post.
 * Display current community if has communityName props
 * Display categories if an array of tags is given
 */
export const BlockAuthor: React.FC<BlockAuthorProps> = ({
    author,
    theme = Theme.light,
    description,
    avatarBadge,
    publicationDate,
    hideDate = false,
}) => {
    const customerId = useSelector(customerIdSelector);
    const userAvatar = getAvatarUrl(author.userId, customerId) || DEFAULT_AVATAR;
    const { userId, fullName } = author;
    const ref = React.useRef<HTMLDivElement>(null);
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    const {
        onMouseEnter,
        onMouseLeave,
        onUserCardMouseEnter,
        onUserCardMouseLeave,
        userProfile,
        isLoadingUser,
        showUserCard,
    } = useUserCardPopover({
        id: userId,
        name: fullName,
    });

    const { route: userProfileRoute } = useSocialProfileRoute({
        userData: { id: userId, fullName },
        routeParams: { userId },
    });

    const displayedPublicationDate = publicationDate
        ? formatFromNow({
              date: publicationDate,
              dateStringFormat: 'LL',
              thresholdDisplayFullDate: DELTA_DAY_FROM_NOW,
          })
        : translateKey(GLOBAL.RIGHT_NOW);

    return (
        <>
            <div ref={ref} className={block(`theme-${theme}`)}>
                <div className={element('avatar')} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <Avatar
                        theme={theme}
                        image={userAvatar}
                        size={Size.m}
                        linkAs={RouterLink}
                        linkProps={{ to: userProfileRoute, tabIndex: -1, 'aria-hidden': true } as any}
                        alt=""
                        badge={
                            avatarBadge ? (
                                <Badge color={ColorPalette.light}>
                                    <Icon color={ColorPalette.dark} icon={avatarBadge?.icon} alt={avatarBadge?.alt} />
                                </Badge>
                            ) : undefined
                        }
                    />
                </div>
                <div className={element('wrapper')}>
                    <Link
                        className={element('name')}
                        color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                        linkAs={RouterLink}
                        to={userProfileRoute}
                        typography={Typography.subtitle1}
                    >
                        {fullName}
                    </Link>
                    <Text
                        as="p"
                        className={element('field')}
                        typography="body1"
                        color={theme === Theme.light ? Theme.dark : Theme.light}
                        colorVariant="L2"
                    >
                        <Text
                            typography="body1"
                            as="span"
                            color={theme === Theme.light ? Theme.dark : Theme.light}
                            colorVariant="N"
                        >
                            {/* Build publication description with status and location */}
                            {description}
                        </Text>
                        {!hideDate && (
                            <>
                                <Text typography="body1" as="span">
                                    {DATA_LISTING_SEPARATOR}
                                </Text>
                                {/* Publication Date */}
                                <time className={typography('body1')} dateTime={publicationDate}>
                                    {displayedPublicationDate}
                                </time>
                            </>
                        )}
                    </Text>
                </div>
            </div>

            <UserCardPopover
                showFollow
                user={userProfile}
                isDetailsOpen={showUserCard}
                anchorRef={ref}
                profilePicture={userAvatar}
                onClose={onUserCardMouseLeave}
                isLoading={isLoadingUser}
                onMouseEnter={onUserCardMouseEnter}
                placement={Placement.BOTTOM_START}
            />
        </>
    );
};
