/* istanbul ignore file */
import React from 'react';

import { Size, TypographyCustom } from '@lumapps/lumx/react';
import { BlockPlaylist } from '@lumapps/play-playlists';
import { Suspense } from '@lumapps/utils/components/Suspense';

const LazyBlockPlayVideoPlaylist = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'block-play-video-playlist' */
            './BlockPlayVideoPlaylist'
        ),
);

export const BlockPlayVideoPlaylist: React.FC<React.ComponentPropsWithoutRef<typeof LazyBlockPlayVideoPlaylist>> = (
    props,
) => {
    const { description, playVideoPlaylistId, thumbnail, title, updatedAt, orientation } = props;

    return (
        <Suspense
            loadingFallback={
                <BlockPlaylist
                    isLoading
                    playlist={{ description, id: playVideoPlaylistId, thumbnail, title, updatedAt }}
                    orientation={orientation.toLowerCase() as Lowercase<typeof orientation>}
                >
                    <BlockPlaylist.Figure>
                        <BlockPlaylist.Thumbnail withIcon />
                    </BlockPlaylist.Figure>

                    <BlockPlaylist.Content gap={Size.regular}>
                        <BlockPlaylist.Title typography={TypographyCustom.title4} />

                        <BlockPlaylist.Description />
                    </BlockPlaylist.Content>
                </BlockPlaylist>
            }
        >
            <LazyBlockPlayVideoPlaylist {...props} />
        </Suspense>
    );
};
