import { bool, shape } from 'prop-types';

const DOMAIN = 'ROOT/LOADING_STATE';

const SET_LOADING_STATE = `${DOMAIN}/SET_LOADING_STATE`;
const SET_FAILURE_STATE = `${DOMAIN}/SET_FAILURE_STATE`;
const CLEAR_LOADING_STATE = `${DOMAIN}/CLEAR_LOADING_STATE`;

const CLEAR_ALL_LOADING_STATES = `${DOMAIN}/CLEAR_ALL_LOADING_STATES`;

const defaultState = {};

const loadingStateType = shape({
    blocking: bool,
    failure: bool,
    loading: bool,
});

const makeDefaultLoadingState = (blocking) => ({
    blocking,
    failure: false,
    loading: true,
});

const makeDefaultFailureState = (blocking) => ({
    blocking,
    failure: true,
    loading: false,
});

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_LOADING_STATE:
            return { ...state, [action.component]: makeDefaultLoadingState(action.isBlocking) };
        case SET_FAILURE_STATE:
            return { ...state, [action.component]: makeDefaultFailureState(action.isBlocking) };
        case CLEAR_LOADING_STATE:
            return { ...state, [action.component]: null };
        case CLEAR_ALL_LOADING_STATES:
            return defaultState;

        default:
            return state;
    }
};

const setLoadingState = (component, isBlocking) => ({
    component,
    isBlocking,
    type: SET_LOADING_STATE,
});

const setFailureState = (component, isBlocking) => ({
    component,
    isBlocking,
    type: SET_FAILURE_STATE,
});

const clearLoadingState = (component) => ({
    component,
    type: CLEAR_LOADING_STATE,
});

export {
    setLoadingState,
    SET_LOADING_STATE,
    setFailureState,
    SET_FAILURE_STATE,
    clearLoadingState,
    CLEAR_LOADING_STATE,
    CLEAR_ALL_LOADING_STATES,
    reducer,
    loadingStateType,
};
