import React from 'react';

import { FULL_DATE_THRESHOLD } from '../../constants';
import { formatDateForVideo } from '../../utils';

export interface VideoDateProps {
    /** Capitalize date string */
    capitalize?: boolean;
    /** Custom class name */
    className?: string;
    /** The date to format and display */
    date: string;
    /** The nb of days before the date format changes */
    fullDateThreshold?: number;
}

export const VideoDate = ({ capitalize, className, date, fullDateThreshold = FULL_DATE_THRESHOLD }: VideoDateProps) => {
    return (
        <time dateTime={date} className={className}>
            {formatDateForVideo({ date, fullDateThreshold, capitalize })}
        </time>
    );
};
