import React, { useState } from 'react';

import { margin } from '@lumapps/classnames';
import { LanguageSwitcher } from '@lumapps/languages/components/LanguageSwitcher';
import { Emphasis, Kind } from '@lumapps/lumx/react';
import { CodeMirrorTextArea } from '@lumapps/style/components/CodeMirrorTextArea';
import { LANGUAGES } from '@lumapps/style/components/CodeMirrorTextArea/useCodeMirrorTextArea';
import { ADVANCED_STYLES } from '@lumapps/style/keys';
import { useTranslate } from '@lumapps/translations';
import { useThirdPartyLibrary, THIRD_PARTY_LIBRARIES } from '@lumapps/utils/libs/useThirdPartyLibrary';

/**
 * So, CodeMirror is a particular library. When a value has changed on the code mirror text area, the callback
 * setFooterForLanguage will be executed, BUT, language will be the first used language on this component. Why?
 * CodeMirror events cannot be changed once the CodeMirror instance was already defined. That means that the
 * function that we pass into code mirror that updates the language for the footer always has the first language
 * that the function was originally created with. The only way around this was to save in memory the currently
 * selected language and use that one.
 */
let currentSelectedLanguage = '';

export interface FooterEditorOptions {
    /** current user language */
    currentLanguage: string;
    /** values for the footer */
    footer?: Record<string, string>;
    /** callback executed once the footer has changed */
    onChange: (values: Record<string, string>) => void;
}

/**
 * Component used on Instance Style in order to edit a footer. This component should only be used ONCE, this was
 * not meant to be reused.
 * @param FooterEditorOptions
 * @returns FooterEditor
 */
const FooterEditor: React.FC<FooterEditorOptions> = ({ currentLanguage, footer, onChange }) => {
    const { translateKey } = useTranslate();
    const [language, setLanguage] = useState(currentLanguage);
    const [hasLoaded, setHasLoaded] = React.useState(false);

    currentSelectedLanguage = language || currentLanguage;

    useThirdPartyLibrary({
        lib: THIRD_PARTY_LIBRARIES.CODE_MIRROR,
        shouldImportCss: false,
        shouldLoadLibrary: true,
        onLoad: () => {
            setHasLoaded(true);
        },
    });

    const setSelectedLanguage = (lang: string) => {
        setLanguage(lang);
        currentSelectedLanguage = language;
    };

    const setFooterForLanguage = (footerForCurrentLanguage: string) => {
        onChange({
            ...footer,
            [currentSelectedLanguage]: footerForCurrentLanguage,
        });
    };

    return (
        <>
            <LanguageSwitcher
                onChange={setSelectedLanguage}
                defaultLanguage={language}
                shouldShowLeftIcon={false}
                shouldShowRightIcon={false}
                emphasis={Emphasis.medium}
                isVisible
            />
            <CodeMirrorTextArea
                id="footer-editor"
                language={LANGUAGES.html}
                hasFinishedLoading
                shouldApplyHighlightSyntax={hasLoaded}
                value={footer ? footer[language] : ''}
                setValue={setFooterForLanguage}
                wrapperClassName={margin('top', 'big')}
                message={{
                    kind: Kind.warning,
                    children: translateKey(ADVANCED_STYLES.CUSTOM_JAVASCRIPT_WARNING),
                    hasBackground: true,
                }}
                editorSize={{ height: '350px' }}
            />
        </>
    );
};

export { FooterEditor };
