/* istanbul ignore file */
export const SA_FEATURE_NAME = 'social-advocacy';

export const ROLES = {
    ADMIN: 'ADMIN',
    AMBASSADOR: 'AMBASSADOR',
    PROGRAM_MANAGER: 'PROGRAM_MANAGER',
};

export const PERMISSIONS = {
    CAN_ACCESS_ALL_SHARES_DATA: 'CAN_ACCESS_ALL_SHARES_DATA',
    CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES: 'CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES',
    CAN_MANAGE_SHAREABLE_CONTENTS: 'CAN_MANAGE_SHAREABLE_CONTENTS',
    CAN_MANAGE_SOCIAL_ADVOCACY_AMBASSADORS: 'CAN_MANAGE_SOCIAL_ADVOCACY_AMBASSADORS',
    CAN_MANAGE_SOCIAL_ADVOCACY_PROGRAM_MANAGERS: 'CAN_MANAGE_SOCIAL_ADVOCACY_PROGRAM_MANAGERS',
    CAN_MANAGE_SOCIAL_ADVOCACY_TOPICS: 'CAN_MANAGE_SOCIAL_ADVOCACY_TOPICS',
    CAN_MANAGE_SOCIAL_NETWORK_CONFIGURATIONS: 'CAN_MANAGE_SOCIAL_NETWORK_CONFIGURATIONS',
    CAN_SHARE_SHAREABLE_CONTENTS: 'CAN_SHARE_SHAREABLE_CONTENTS',
};

export const PUBLIC_MODE_PATH_PREFIX = 'ls/share';
