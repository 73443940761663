import { and } from '@lumapps/utils/function/predicate/and';
import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchNode, hasAttribute } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { Link } from '@lumapps/wrex/serialization/dita/types';

import type { LinkElement } from '../../types';
import { createLink } from '../../utils/createLink';

/**
 * Convert Dita local xref into slate link element.
 */
export const LINK_CONVERT: ElementConvertOption<Link> = {
    test: and(matchNode({ name: 'xref' }), hasAttribute('href')),
    transform: ({ attributes }, children): LinkElement => createLink({ href: attributes.href }, children),
};
