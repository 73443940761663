export enum CHAT {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=41982896
     */
    GET_AUTHORIZE_LINK_ERROR = 'FRONT.CHAT_GET_AUTHORIZE_LINK_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42305584
     */
    NO_USER_SPACE_FOUND = 'CHAT.NO_USER_SPACE_FOUND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42305588
     */
    CONNECT = 'CHAT.SLACK.CONNECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42305591
     */
    DISCONNECT = 'CHAT.SLACK.DISCONNECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42305593
     */
    NO_USER_SPACE_SELECTED = 'CHAT.SLACK.NO_USER_SPACE_SELECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42305594
     */
    SELECT_USER_SPACE = 'CHAT.SLACK.SELECT_USER_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=44874869
     */
    SELECT_CHANNEL = 'FRONT.SHARE_POSTS_TEAMS.SELECT_CHANNEL.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68866548
     */
    SELECT_WORKSPACE = 'FRONT.SHARE_VIA_SLACK.DIALOG.SELECT_WORKSPACE',
    GET_USER_TOKEN_STATUS_ERROR = 'FRONT.CHAT_GET_USER_TOKEN_STATUS_ERROR.ERROR',
    DISCONNECT_USER_TOKEN_ERROR = 'FRONT.CHAT_DISCONNECT_USER_TOKEN_ERROR',
    FETCH_GROUPS_ERROR = 'FRONT.FETCH_GROUPS_ERROR',
}
