import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function CustomContentTypeService($injector, $q, $rootScope, Config, CustomContentTypeFactory,
        CustomContentTypeTagFactory, Instance, LumsitesBaseService, Translation, Utils) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(CustomContentTypeFactory, {
            autoInit: false,
            fullListResponse: true,
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            postList: _postList,
            // eslint-disable-next-line no-use-before-define
            postSave: _postSave,
        });

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The default maximum number of results when listing custom content types.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _MAX_RESULTS = 100;

        /**
         * The promise of the load of siblings custom content types.
         *
         * @type {Promise}
         */
        var _cctsLoadingDeferred = $q.defer();

        /**
         * Contains the list of all siblings custom content types.
         *
         * @type {Array}
         */
        var _customContentTypesCache = [];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The list key for the custom content types of the current instance (own and inherited).
         *
         * @type {string}
         * @constant
         * @readonly
         */
        service.CURRENT_INSTANCE_CCT_LIST_KEY = 'main';

        /**
         * The maximum number of instance siblings that can be used to post filter any list of custom content type.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        service.MAX_INSTANCE_SIBLINGS = 30;

        /**
         * The defautl parameter for the base service's calls.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /**
         * Contains various indicators about the state of the service.
         *
         * @type {Object}
         */
        service.is = {
            initialized: false,
            initializing: false,
        };

        /**
         * This contains the callback to be executed as soon as the custom content type tags has been loaded.
         *
         * @type {Object}
         */
        service.tagsCallback = {};

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Retrieve the list of tags from the custom content type.
         * The tags is added only if it's contained in the allowed tags list and if the instance of the  custom content
         * type is in the instance list.
         *
         * @param  {Object} cct           The custom content type.
         * @param  {Array}  [instances]   The list of instances in which fetch the content types.
         * @param  {Array}  [allowedTags] The list of allowed tags.
         * @return {Object} The list of tags.
         */
        function _addCustomContentTypeTags(cct, instances, allowedTags) {
            var tags = {};
            var instance = Instance.instanceKeyToInstanceFromSiblings(cct.instance, true);

            if (!angular.isArray(cct.tags) || angular.isUndefinedOrEmpty(cct.tags) ||
                angular.isUndefinedOrEmpty(instance)) {
                return tags;
            }

            var isPageOrNews = _.includes([Config.AVAILABLE_CONTENT_TYPES.PAGE, Config.AVAILABLE_CONTENT_TYPES.NEWS],
                cct.functionalInnerId);

            if ((angular.isUndefinedOrEmpty(instances) || _.includes(instances, instance.id) || !isPageOrNews) &&
                (angular.isUndefinedOrEmpty(allowedTags) ||
                angular.isDefinedAndFilled(_.intersection(allowedTags, _.map(cct.tags, 'uuid'))))) {
                var tagName = Translation.translate(cct.name) + ' - ' + instance.name;

                tags[tagName] = (angular.isDefinedAndFilled(allowedTags)) ?
                    _.filter(cct.tags, function filterTags(tag) {
                        return _.includes(allowedTags, tag.uuid);
                    }) : cct.tags;
            }

            return tags;
        }

        /**
         * Retrieve the list of inherited custom content type in the list of instance given.
         *
         * @param  {string}  parentCctId   The id of the parent custom content type.
         * @param  {Array}   [instanceIds] The list of instances ids we want to look in.
         * @return {Promise} A promise that will resolves with the inherited custom content types.
         */
        function _getInheritedCcts(parentCctId, instanceIds) {
            if (angular.isUndefinedOrEmpty(parentCctId)) {
                return $q.reject();
            }

            var ccts = [];

            return $q(function deferInheritedCcts(resolve, reject) {
                service.getAllPages({
                    instanceIds: (angular.isDefinedAndFilled(instanceIds)) ?
                        instanceIds.slice(0, service.MAX_INSTANCE_SIBLINGS - 1) : undefined,
                    parentId: parentCctId,
                }, function onCctPage(cctsInPage) {
                    ccts = ccts.concat(cctsInPage);
                }, function onAllPages() {
                    resolve(ccts);
                }, reject,
                `listInherited-${parentCctId}-${(instanceIds || []).join('_')}-${generateUUID()}`,
                undefined, false, 'listInherited');
            });
        }

        /**
         * The post list hook function. Called everytime a "list" call resolves.
         * Simply add a sort order to the custom content types: display the "Pages" custom content type first, then the
         * "News" one then all the custom content types created by the user.
         *
         * @param  {Object} ccts The list of custom content types to be processed.
         * @return {Array}  The list of custom content type processed and ordered.
         */
        function _postList(ccts) {
            if (angular.isUndefinedOrEmpty(ccts)) {
                return [];
            }

            angular.forEach(ccts, function forEachCustomContentTypes(cct) {
                switch (cct.functionalInnerId) {
                    case Config.AVAILABLE_CONTENT_TYPES.PAGE:
                        cct.sortOrder = 1;
                        break;

                    case Config.AVAILABLE_CONTENT_TYPES.NEWS:
                        cct.sortOrder = 2;
                        break;

                    default:
                        cct.sortOrder = 3;
                        break;
                }
            });

            return ccts.sort((cct1, cct2) => {
                if(cct1.sortOrder < cct2.sortOrder) return -1;
                if(cct1.sortOrder > cct2.sortOrder) return 1;

                const cct1Name = Translation.translate(cct1.name).toLocaleLowerCase();
                const cct2Name = Translation.translate(cct2.name).toLocaleLowerCase();

                return cct1Name.localeCompare(cct2Name);
            });
        }

        /**
         * The post save hook function. Called everytime a "save" call resolves.
         * Ensure that all needed properties exists and are set.
         *
         * @param  {Object} cct The saved custom content type.
         * @return {Object} The saved content processed.
         */
        function _postSave(cct) {
            cct.endDateDelta = Number(cct.endDateDelta) || 0;

            var index = _.findIndex(_customContentTypesCache, {
                uid: cct.uid,
            });

            if (index === -1) {
                _customContentTypesCache.push(cct);
            } else {
                _customContentTypesCache[index] = cct;
            }

            return cct;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Transform a full custom content type to its id.
         * This is mainly used by LumX lxSelect "selectionToModel".
         *
         * @param  {Object}   cct  The custom content type we want to transform to its id.
         * @param  {Function} [cb] The lxSelect callback function
         * @return {string}   The id of the custom content type.
         */
        function customContentTypeToId(cct, cb) {
            cb = cb || angular.noop;

            if (angular.isUndefinedOrEmpty(cct)) {
                return undefined;
            }

            cb(cct.id);

            return cct.id;
        }

        /**
         * Get the list of the tags of the given custom content type.
         *
         * @param  {string}  cctId The id of the custom content type we want the tags of.
         * @return {Promise} A promise that will resolves with the list of tags.
         */
        function getCustomContentTypesTags(cctId) {
            return $q(function deferGetCustomContentTypesTags(resolve, reject) {
                service.getItem(cctId, function onItemGetSuccess(cct) {
                    resolve(_.get(cct, 'tags'));
                }, reject, undefined, false);
            });
        }

        /**
         * Get the functional inner id of a given custom content type.
         *
         * @param  {Object} cct The custom content type we want to get the functional inner id of.
         * @return {string} The functional inner id of the given custom content type.
         */
        function getFunctionalInnerId(cct) {
            if (angular.isUndefinedOrEmpty(cct)) {
                return Config.AVAILABLE_CONTENT_TYPES.CUSTOM;
            }

            var functionalInnerId = cct.functionalInnerId;

            var ALLOWED_FUNCTIONAL_INNER_IDS =
                [Config.AVAILABLE_CONTENT_TYPES.PAGE, Config.AVAILABLE_CONTENT_TYPES.NEWS];
            if (angular.isDefinedAndFilled(functionalInnerId) &&
                _.includes(ALLOWED_FUNCTIONAL_INNER_IDS, functionalInnerId)) {
                return functionalInnerId;
            }

            return Config.AVAILABLE_CONTENT_TYPES.CUSTOM;
        }

        /**
         * Get a custom content type.
         * First check if it exists in the custom content types list given by the backend in the Jinja.
         * If not, get it from the backend.
         *
         * @param {string|Object} params              The parameters of the query.
         *                                            It should at least contain an `uid` property (or be a string) so
         *                                            that we can use this id to search for it in the list.
         *                                            If no id is found, then a query to the server will be done.
         * @param {Function}      [cb]                The function to execute when the custom content type has been
         *                                            found.
         * @param {Function}      [errCb]             The function to execute when the custom content type query has
         *                                            failed.
         * @param {string}        [listKey='default'] The list key of the query (for the base service call).
         * @param {boolean}       [setAsCurrent=true] Indicates if we want to set the got custom content type as
         *                                            current.
         */
        function getItem(params, cb, errCb, listKey, setAsCurrent) {
            // Make sure we wait for the CCT.init() to be done before trying to get individual CCTs.
            service.promiseList().then(function onServiceInitCompleted() {
                cb = cb || angular.noop;
                errCb = errCb || angular.noop;
                setAsCurrent = (angular.isUndefined(setAsCurrent)) ? true : setAsCurrent;

                if (angular.isUndefinedOrEmpty(params)) {
                    errCb();

                    return;
                }

                service._current = service._current || {};

                if (angular.isString(params)) {
                    params = {
                        uid: params,
                    };
                }

                if (angular.isDefinedAndFilled(params.uid) && angular.isDefinedAndFilled(_customContentTypesCache)) {
                    var cct = _.find(_customContentTypesCache, {
                        uid: params.uid,
                    });

                    if (angular.isDefinedAndFilled(cct)) {
                        if (setAsCurrent) {
                            service.setItem(cct, cb, listKey);
                        } else {
                            cb(cct);
                        }

                        return;
                    }
                }

                service.get(params, function onCustomContentTypeGetSuccess(response) {
                    _customContentTypesCache.push(response);

                    if (setAsCurrent) {
                        service.setItem(response, cb, listKey);
                    } else {
                        cb(response);
                    }
                }, errCb, listKey);
            });
        }

        /**
         * Get multiple custom content types from their ids.
         *
         * @param  {Array}   cctIds The list of ids of the custom content types we want to get.
         * @return {Promise} A promise that will resolve with the list of custom content types.
         */
        function getItems(cctIds) {
            if (angular.isUndefinedOrEmpty(cctIds)) {
                return $q.resolve([]);
            }

            return CustomContentTypeFactory.getMulti({
                uids: cctIds,
            }).$promise.then(function onGetMultiSuccess(response) {
                return response.items;
            });
        }

        /**
         * Get a tag by its UUID.
         *
         * @param  {string} tagUuid The UUID of the tag we want to get.
         * @param  {Array}  tags    The list of tags in which we want to search for our tags.
         * @return {Object} The tag corresponding to the given UUID.
         */
        function getTagByUuid(tagUuid, tags) {
            if (angular.isUndefinedOrEmpty(tags) || !angular.isArray(tags) || angular.isUndefinedOrEmpty(tagUuid)) {
                return undefined;
            }

            return _.find(tags, {
                uuid: tagUuid,
            });
        }

        /**
         * Get tags of a custom content types with multiple ids and return a select with header ready object.
         *
         * @param {Array}    ccts                The list of custom content type (list of id or full objects).
         * @param {Function} [cb]                The function to execute when the list is ready.
         * @param {Function} [errCb]             The function to execute when the list couldn't have been computed.
         * @param {Array}    [instanceIds]       The list of instances ids to fetch inherited custom content types.
         * @param {Array}    [restrictedTags]    The list of allowed tags.
         * @param {string}   [listKey='default'] The list key of the query (for the base service call).
         */
        function getTagsForHeaderSelect(ccts, cb, errCb, instanceIds, restrictedTags, listKey) {
            if (angular.isUndefinedOrEmpty(ccts) || !angular.isArray(ccts)) {
                cb({});

                return;
            }

            // Make sure we wait for the CCT.init() to be done before trying to get individual CCTs.
            service.promiseList().then(function onServiceInitCompleted() {
                errCb = errCb || angular.noop;
                var parentCcts = [];
                var tags = {};
                var promises = [];

                // eslint-disable-next-line func-style
                var processCct = function processCct(cct) {
                    if (cct.heritable) {
                        parentCcts.push(cct.uid);
                    }

                    tags = angular.merge(_addCustomContentTypeTags(cct, instanceIds, restrictedTags), tags);
                };

                // Get the tags for each custom content type required.
                angular.forEach(ccts, function forEachCcts(cct) {
                    if (angular.isUndefinedOrEmpty(cct)) {
                        return;
                    }

                    var defer = $q.defer();
                    promises.push(defer.promise);

                    if (angular.isString(cct)) {
                        service.getItem(cct, function onCctLoaded(cctLoaded) {
                            processCct(cctLoaded);

                            defer.resolve();
                        }, function onCctLoadError(err) {
                            errCb(err);

                            defer.resolve();
                        }, listKey, false);
                    } else {
                        processCct(cct);

                        defer.resolve();
                    }
                });

                $q.all(promises).then(function onAllCctLoaded() {
                    if (angular.isUndefinedOrEmpty(parentCcts)) {
                        cb(tags);

                        return;
                    }

                    var inheritancePromises = [];
                    angular.forEach(parentCcts, function forEachParentCcts(parentCct) {
                        var promise = $q(function getInheritedCcts(resolve, reject) {
                            _getInheritedCcts(parentCct, instanceIds).then(
                                function onInheritedCctListSuccess(inheritedCcts) {
                                    if (angular.isUndefinedOrEmpty(inheritedCcts)) {
                                        resolve();

                                        return;
                                    }

                                    angular.forEach(inheritedCcts, function forEachInheritedCcts(inheritedCct) {
                                        tags = angular.merge(
                                            _addCustomContentTypeTags(inheritedCct, instanceIds, restrictedTags),
                                            tags
                                        );
                                    });

                                    resolve();
                                }
                            ).catch(reject);
                        });

                        inheritancePromises.push(promise);
                    });

                    $q.all(inheritancePromises).then(function onAllInheritanceResolved() {
                        cb(tags);
                    }).catch(errCb);
                }).catch(errCb);
            });
        }

        /**
         * Group custom content type by instance id.
         *
         * @param {Array}    instancesIds          The list of instances ids to group by.
         * @param {boolean}  isOnlyHeritable       Indicates whether the non heritables custom types should be added.
         * @param {boolean}  isOnlyParentHeritable Indicates whether if the non heritables content types from the parent
         *                                         should be added.
         * @param {Function} [endCb]               The function to execute when the custom content type have been
         *                                         grouped by instance.
         * @param {Array}    [instances]           The instance object list to retrieve instance information.
         * @param {boolean}  [onlyInherited=false] Indicates if we want to display only inherited CCT when more than one
         *                                         instance has been given.
         */
        function groupByInstances(instancesIds, isOnlyHeritable, isOnlyParentHeritable, endCb, instances,
            onlyInherited) {
            if (angular.isUndefinedOrEmpty(instancesIds)) {
                endCb({});

                return;
            }

            var heritableKey = Translation.translate('CUSTOM_CONTENT_TYPE_SELECT_HERITABLE');
            var instanceCount = (angular.isArray(instancesIds)) ? instancesIds.length : 0;
            var triggerCount = 0;
            var target = {};
            endCb = endCb || angular.noop;

            // eslint-disable-next-line func-style
            var end = function end() {
                // Use to return the computed target only when all promise have returned.
                if (triggerCount === instanceCount) {
                    angular.forEach(target, function forEachInstance(ccts, instanceName) {
                        if (angular.isUndefinedOrEmpty(ccts)) {
                            delete target[instanceName];
                        }
                    });

                    endCb(target);
                }
            };

            // eslint-disable-next-line func-style
            var concatAndFilter = function concatAndFilter(ccts) {
                triggerCount++;

                if (!angular.isArray(ccts) || angular.isUndefinedOrEmpty(ccts)) {
                    end();

                    return;
                }

                var instance;
                var instanceIdFromCCT = ccts[0].instance;

                if (angular.isDefinedAndFilled(instances)) {
                    instance = _.find(instances, {
                        id: instanceIdFromCCT,
                    });
                } else {
                    instance = Instance.instanceKeyToInstanceFromSiblings(instanceIdFromCCT, true);
                }

                if (!angular.isObject(instance) || angular.isUndefinedOrEmpty(instance)) {
                    end();

                    return;
                }

                var key = instance.name;
                var heritableAlreadyHere = (angular.isDefined(target[heritableKey])) ?
                    _.map(target[heritableKey], 'id') : [];

                if (!isOnlyHeritable && (!isOnlyParentHeritable || angular.isDefinedAndFilled(instance.parent))) {
                    target[key] = [];
                }

                angular.forEach(ccts, function forEachCcts(cct) {
                    // Sanitize CCT name
                    cct['name'] = Object.fromEntries(
                        Object.entries(cct['name']).map(([lang, value]) => {
                            return [lang, sanitizeHTML(value)];
                        }),
                    );

                    if (!cct.heritable && !isOnlyHeritable &&
                        angular.isUndefinedOrEmpty(cct.parentCustomContentType) &&
                        (!isOnlyParentHeritable || angular.isDefinedAndFilled(instance.parent))) {
                        target[key].push(cct);

                        return;
                    }

                    if (angular.isUndefined(target[heritableKey])) {
                        target[heritableKey] = [];
                    }

                    if (!_.includes(heritableAlreadyHere, cct.id) && cct.heritable) {
                        target[heritableKey].push(cct);
                        heritableAlreadyHere.push(cct.id);
                    }
                });

                end();
            };

            if (instanceCount === 1) {
                service.filterize({
                    instance: _.first(instancesIds),
                }, concatAndFilter, undefined, generateUUID());
            } else if (onlyInherited) {
                instanceCount = 1;
                service.promiseList().then(function onCurrentInstanceCctLoaded(ccts) {
                    var inheritedCct = _.filter(ccts, {
                        heritable: true,
                    });

                    concatAndFilter(inheritedCct);
                });
            } else {
                angular.forEach(instancesIds, function forEachInstances(instance) {
                    service.filterize({
                        instance: instance,
                    }, concatAndFilter, undefined, generateUUID());
                });
            }
        }

        /**
         * Get the list of custom content type for a given instance.
         *
         * @param  {string}  instanceId The id of the instance we want the custom content type from.
         * @return {Promise} A promise that will resolve with the list of custom content types.
         */
        function listForInstance(instanceId) {
            return service.promiseFilterize({
                instance: instanceId,
            }, 'cct-' + instanceId);
        }

        /**
         * Get the list of custom content type for the current instance (including inherited ones) with a promise.
         *
         * @return {Promise} A promise that will resolve with the list of custom content type.
         */
        function promiseList() {
            return _cctsLoadingDeferred.promise;
        }

        /**
         * Set a custom content type in the service.
         * This will call the eventual existing tags callback and set the custom content type as current.
         *
         * @param {Object}   cct                 The custom content type to set.
         * @param {Function} [cb]                The function to execute when the custom content type query has been
         *                                       set.
         * @param {string}   [listKey='default'] The list key of the query.
         */
        function setItem(cct, cb, listKey) {
            listKey = listKey || 'default';
            cb = cb || angular.noop;

            service.setCurrent(cct, listKey);

            var tagsCbs = service.tagsCallback[listKey];
            if (angular.isDefinedAndFilled(tagsCbs)) {
                angular.forEach(tagsCbs, function forEachTagsCallbacks(tagsCb) {
                    service.uuidToTag(tagsCb.data, tagsCb.cb, listKey, cct);
                });

                service.tagsCallback[listKey] = [];
            }

            cb(cct);
        }

        /**
         * Transform a full custom content type tag to its UUID.
         * This is mainly used by LumX lxSelect "selectionToModel".
         *
         * @param  {Object}   tag  The custom content type tag we want to transform to its UUID.
         * @param  {Function} [cb] The lxSelect callback function
         * @return {string}   The UUID of the custom content type tag.
         */
        function tagToUuid(tag, cb) {
            cb = cb || angular.noop;

            if (angular.isUndefinedOrEmpty(tag)) {
                return undefined;
            }

            cb(tag.uuid);

            return tag.uuid;
        }

        /**
         * Transform a custom content type tags's uuid to a full custom content type tags.
         * This is mainly used by LumX lxSelect "modelToSelection".
         *
         * @param  {Object}         tagUuid The UUID of the custom content type tag we want to get.
         * @param  {Function}       [cb]    The lxSelect callback function.
         * @param  {Object}         [tags]  The list of tags where we could possibly find the wanted tag.
         * @return {Object|Promise} The wanted tag or a promise that will resolve with the wanted tag.
         */
        function uuidToTag(tagUuid, cb, tags) {
            if (angular.isUndefinedOrEmpty(tagUuid)) {
                return undefined;
            }

            cb = cb || angular.noop;

            if (angular.isDefinedAndFilled(tags)) {
                var tag = service.getTagByUuid(tagUuid, tags);
                cb(tag);

                return tag;
            }

            var promise = CustomContentTypeTagFactory.get({
                uuid: tagUuid,
            }).$promise;

            promise.then(cb);

            return promise;
        }

        /////////////////////////////

        service.customContentTypeToId = customContentTypeToId;
        service.getFunctionalInnerId = getFunctionalInnerId;
        service.getCustomContentTypesTags = getCustomContentTypesTags;
        service.getItem = getItem;
        service.getItems = getItems;
        service.getTagByUuid = getTagByUuid;
        service.getTagsForHeaderSelect = getTagsForHeaderSelect;
        service.groupByInstances = groupByInstances;
        service.listForInstance = listForInstance;
        service.promiseList = promiseList;
        service.setItem = setItem;
        service.tagToUuid = tagToUuid;
        service.uuidToTag = uuidToTag;

        /////////////////////////////

        /**
         * Initialize the service.
         *
         * @return {Promise} The promise of the initialisation.
         */
        service.init = function init() {
            var currentInstanceId = Instance.getCurrentInstanceId();

            service.defaultParams = {
                instance: currentInstanceId,
                maxResults: _MAX_RESULTS,
            };

            if (!$injector.get('User').isConnected()) {
                _cctsLoadingDeferred.reject();

                return _cctsLoadingDeferred.promise;
            }

            if (service.is.initializing || service.is.initialized) {
                return _cctsLoadingDeferred.promise;
            }

            _customContentTypesCache = [];
            service.is.initializing = true;

            // TODO [Clément]: implement projection for retrieving only what's necessary from the CCTs.
            service.getAllPages({
                includeInstanceSiblings: false,
                instance: currentInstanceId,
            }, function onCctsListSuccess(ccts) {
                // Set the GLOBAL custom content type to default list.
                angular.forEach(ccts, function forEachCustomContentTypes(cct) {
                    _customContentTypesCache.push(cct);
                    service.displayList(service.CURRENT_INSTANCE_CCT_LIST_KEY).push(cct);
                });
            }, function onAllCctsListSuccess() {
                $rootScope.$broadcast('custom-content-type-list-updated');

                _cctsLoadingDeferred.resolve(service.displayList(service.CURRENT_INSTANCE_CCT_LIST_KEY));

                service.is.initialized = true;
                service.is.initializing = false;
            }, function onCctsListError(err) {
                _cctsLoadingDeferred.reject(err);

                service.is.initialized = true;
                service.is.initializing = false;
            }, undefined);

            return _cctsLoadingDeferred.promise;
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('CustomContentType', CustomContentTypeService);
})();
