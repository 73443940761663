import React, { useEffect, useMemo, useState } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { currentLanguageSelector } from '@lumapps/languages';
import { mdiStar } from '@lumapps/lumx/icons';
import { FlexBox, Size, Text, Icon, ColorPalette, Orientation, Alignment, Link, Avatar } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { useTranslate } from '@lumapps/translations';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { DEFAULT_AVATAR } from '@lumapps/user/constants';
import { User, UserAccountType } from '@lumapps/user/types';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { SCOPE } from '../../constants';
import { LEADERBOARD } from '../../keys';
import './index.scss';
import { RankingData, userTypes } from '../../types';

export interface RankingListItemProps {
    ranking: RankingData;
    userType: userTypes;
    showAverageScore?: boolean;
}
/**
 * the ranking list that will be a part of the leaderboard widget
 * @param RankingListItemProps
 * @returns RankingListItem
 */
const CLASSNAME = 'ranking-list-item';
export const RankingListItem: React.FC<RankingListItemProps> = ({
    ranking,
    userType = '',
    showAverageScore = false,
}) => {
    const hexToIntUserId = (hexId: string) => {
        const cleanHexId = hexId.replace(/-/g, '');
        const bigintId = BigInt(`0x${cleanHexId}`);
        return bigintId.toString();
    };
    const { element } = useClassnames(CLASSNAME);
    const language = useSelector(currentLanguageSelector);
    const [fullName, setFullName] = useState('');
    const currentUser: User = useMemo(
        () => ({
            id: hexToIntUserId(ranking.identifier),
            fullName: '',
            accountType: UserAccountType.EXTERNAL,
            lastName: ranking?.lastName,
            firstName: ranking?.firstName,
            email: '',
        }),
        [ranking.identifier, ranking.lastName, ranking.firstName],
    );

    const { translateAndReplace } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    const { route: userProfileRoute } = useSocialProfileRoute({
        routeParams: {
            userId: currentUser.id,
        },
        userData: {
            id: currentUser?.id,
            fullName,
        },
    });

    useEffect(() => {
        setFullName(getUserFullName(currentUser, language));
    }, [currentUser, language]);

    return (
        <Link
            linkAs={RouterLink}
            to={userProfileRoute}
            {...get({ element: 'list-element', action: 'open-learners-Ranking-profile' })}
            className={element('link')}
        >
            <FlexBox
                orientation={Orientation.horizontal}
                vAlign={Alignment.spaceBetween}
                className={element(`wrapper-${userType}`)}
                {...get({ element: 'list-element', action: 'open-learners-Ranking-profile' })}
                wrap={false}
            >
                <FlexBox
                    orientation={Orientation.horizontal}
                    hAlign={Alignment.center}
                    gap={Size.big}
                    vAlign={Alignment.spaceAround}
                    className={element('left-column')}
                >
                    <Text as="p" typography="subtitle1" color="dark" colorVariant="N" className={element('text')}>
                        {ranking.ranking > 0 ? ranking.ranking : '-'}{' '}
                    </Text>
                    <Avatar image={ranking?.avatar || DEFAULT_AVATAR} alt="M" size={Size.m} />
                    <FlexBox orientation={Orientation.vertical}>
                        <Text as="p" typography="subtitle1" color="dark" colorVariant="N">
                            {getUserFullName(currentUser, language)}
                        </Text>
                        {showAverageScore && (
                            <Text as="p" typography="body1" color="dark" colorVariant="L2">
                                {translateAndReplace(LEADERBOARD.AVERAGE_SCORE, {
                                    AVERAGE_SCORE: ranking.averageScore,
                                })}
                            </Text>
                        )}
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    orientation={Orientation.horizontal}
                    hAlign={Alignment.center}
                    gap={Size.tiny}
                    className={element('right-column')}
                >
                    <Text as="p" typography="body1" color="dark" colorVariant="L2">
                        {ranking.totalPoints}
                    </Text>
                    <Icon icon={mdiStar} color={ColorPalette.yellow} />
                </FlexBox>
            </FlexBox>
        </Link>
    );
};
