import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Attributes } from '@lumapps/data-attributes';
import { Size, UserBlockSize } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { UserBlock } from '@lumapps/user/components/UserBlock';

import { BlockComponent, BlockUser as BlockUserType, BlockUserVariant } from '../../../types/blocks';

import './BlockUser.scss';

interface BlockUserProps extends BlockUserType {
    className?: string;
    avatarSize?: UserBlockSize;
    attributes?: Attributes;
    scope?: string;
}

export const CLASSNAME = 'block-user';

export const BlockUser: BlockComponent<BlockUserProps> = ({
    className,
    userId,
    fullName,
    email,
    variant = BlockUserVariant.regular,
    avatarSize = Size.m as UserBlockSize,
    theme,
    profilePrimaryFieldValues,
    scope,
    attributes,
}) => {
    const { translateKey } = useTranslate();
    // Show user fields only in regular variant.
    const blockProps =
        variant === BlockUserVariant.regular
            ? { fullName, email, primaryFields: profilePrimaryFieldValues }
            : {
                  avatarProps: {
                      alt: fullName || translateKey(GLOBAL.UNKNOWN_USER),
                  },
              };
    return (
        <UserBlock
            className={classnames(className, CLASSNAME)}
            userId={userId}
            size={avatarSize}
            theme={theme}
            scope={scope}
            {...attributes}
            {...blockProps}
        />
    );
};
