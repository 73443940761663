import React, { PureComponent } from 'react';
import { bool, func } from 'prop-types';

import { inputType } from './types';
import { properties, stringOrNumber } from '../../types';

import { handleNumberInput } from './utils';

/**
 * Renders an input text field.
 */
export class Input extends PureComponent {
    static propTypes = {
        /** Default value if the value is nil. */
        defaultValue: stringOrNumber,
        /** Set the focus on the element once it is mounted. */
        focusOnMount: bool,
        /** In case of type number, defines the max value. */
        max: stringOrNumber,
        /** In case of type number, defines the min value. */
        min: stringOrNumber,
        /** Name of this value. See `utils.onChangeProperties`. */
        name: properties.isRequired,
        /** See the DOM `input`. */
        onBlur: func,
        /** Called when the value changes with `(value, name, evt)`. */
        onChange: func.isRequired,
        /** See the DOM `input`. */
        onFocus: func,
        /** See the DOM `input`. */
        placeholder: stringOrNumber,
        /**
         * If no `value` is set, sets it to the `placeholder` value on focus.
         * This enables users to start editing the `value` from a default setting.
         */
        revivePlaceholder: bool,
        /** Set the focuson the element and select the value once it is mounted. */
        selectOnMount: bool,
        /** See the DOM `input`. */
        type: inputType,
        /** Value to display. */
        value: stringOrNumber,
    };

    static defaultProps = {
        defaultValue: '',
        focusOnMount: false,
        max: undefined,
        min: undefined,
        onBlur: undefined,
        onFocus: undefined,
        placeholder: undefined,
        revivePlaceholder: false,
        selectOnMount: false,
        type: 'string',
        value: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };

        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onMount = this.onMount.bind(this);
    }

    /**
     * Function called when input is blurred.
     *
     * @param {Event} evt Blur DOM event.
     */
    onBlur(evt) {
        const { onBlur } = this.props;

        this.setState({ focused: false }, () => onBlur && onBlur(evt));
    }

    /**
     * Calls `onChange` with the new value parsed based on `props.type`.
     *
     * @param {Object} evt The event triggering this method.
     */
    onChange(evt) {
        const { name, onChange } = this.props;
        const { focused } = this.state;
        let { value } = evt.target;

        if (value) {
            const { min, max, type } = this.props;

            value = type === 'number' ? handleNumberInput({ max, min, value }) : value;
        }

        onChange(value, name, evt);
        focused && this.setState({ focused: false });
    }

    /**
     * Function called when an input is focused.
     *
     * @param {Event} evt Focus DOM event.
     */
    onFocus(evt) {
        const { onFocus } = this.props;

        this.setState({ focused: true }, () => onFocus && onFocus(evt));
    }

    onMount(node) {
        const { selectOnMount, focusOnMount } = this.props;

        return (
            node && ((selectOnMount && node.select && node.select()) || (focusOnMount && node.focus && node.focus()))
        );
    }

    render() {
        const {
            focusOnMount,
            selectOnMount,
            revivePlaceholder,
            defaultValue,
            placeholder,
            value,
            onFocus,
            onBlur,
            ...props
        } = this.props;
        const { focused } = this.state;
        const isValueEmpty = value === null || value === undefined || value === '';

        return (
            <input
                ref={!selectOnMount && !focusOnMount ? null : this.onMount}
                {...props}
                placeholder={placeholder}
                value={isValueEmpty ? (revivePlaceholder && focused ? placeholder : defaultValue) : value}
                onBlur={revivePlaceholder ? this.onBlur : onBlur}
                onChange={this.onChange}
                onFocus={revivePlaceholder ? this.onFocus : onFocus}
            />
        );
    }
}
