import { useState } from 'react';
/**
 * Hook that centralises the logic for opening a dropdown while avoiding a scroll on the page. This should be used
 * when the dropdown shows a very large list of items and scrolling inside the dropdown is highly possible
 *
 * @param initialState - initial state for the dropdown, determines whether the dropdown is open by default or not
 */
const useDropdown = (initialState = false) => {
    const [isOpen, setIsOpen] = useState(initialState);

    const toggle = () => setIsOpen(!isOpen);

    return {
        isOpen,
        setIsOpen,
        toggle,
    };
};

export { useDropdown };
