import isEmpty from 'lodash/fp/isEmpty';
import isObject from 'lodash/fp/isObject';
import map from 'lodash/fp/map';

import { Answers, UserChoices } from '../types';
import { gerPercentage } from './getPercentage';
import { getUserChoicesGap } from './getUserChoiceGap';

// Update quantity & percentage of the provided answer with the current use choice
export const updateAnswers = ({
    previousUserChoices,
    newUserChoices,
    answers,
    answersCount,
    handleAnswersChange,
    handleAnswersCountChange,
}: {
    previousUserChoices?: UserChoices;
    newUserChoices: UserChoices;
    answers: Answers;
    answersCount: number;
    handleAnswersChange: (answers: Answers) => void;
    handleAnswersCountChange: (answersCount: number) => void;
}) => {
    // Initial choice
    if (isEmpty(previousUserChoices)) {
        const updateAnswers = map((answer) => {
            const quantity = isObject(newUserChoices)
                ? answer.quantity + Number(newUserChoices[answer.value])
                : answer.quantity + Number(answer.value === newUserChoices);
            const percentage = gerPercentage(quantity, answersCount + 1);
            return { ...answer, quantity, percentage };
        }, answers);
        handleAnswersCountChange(answersCount + 1);
        handleAnswersChange(updateAnswers);
        return;
    }
    // Update choice
    if (isObject(newUserChoices) && isObject(previousUserChoices)) {
        const updateAnswers = map((answer) => {
            const quantity =
                answer.quantity + getUserChoicesGap(previousUserChoices[answer.value], newUserChoices[answer.value]);
            const percentage = gerPercentage(quantity, answersCount);
            return { ...answer, quantity, percentage };
        }, answers);
        handleAnswersChange(updateAnswers);
        return;
    }
    const updateAnswers = map((answer) => {
        const quantity =
            answer.quantity - Number(answer.value === previousUserChoices) + Number(answer.value === newUserChoices);
        const percentage = gerPercentage(quantity, answersCount);
        return { ...answer, quantity, percentage };
    }, answers);
    handleAnswersChange(updateAnswers);
};
