/**
 * Sort object by value.
 * Value should be a string
 * We are dismantling the key value object and rebuilding it after sort
 */
export const sortByValue = (record: Record<string, string>) => {
    return Object.entries(record)
        .sort(([, a], [, b]) => {
            return a.localeCompare(b);
        })
        .reduce<Record<string, string>>((acc, [key, value]) => {
            acc[key] = value;

            return acc;
        }, {});
};
