import { Node } from '@lumapps/wrex/slate';

import { isHeadline } from './isHeadline';
import { isParagraph } from './isParagraph';
import { isSubtitle } from './isSubtitle';
import { isTitle } from './isTitle';

/**
 * Check if the given node is a node which we can text apply alignment
 */
export const isAuthorizedAlignmentNode = (node: Node) => {
    return isParagraph(node) || isHeadline(node) || isTitle(node) || isSubtitle(node);
};
