import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { TextField, Emphasis, Dropdown, Button, TextFieldProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate, getKeyForLanguage } from '@lumapps/translations';
import { sortByValue } from '@lumapps/utils/object/sortByValue';

import { SCOPE } from '../../constants';

import './index.scss';

export interface TranslationsDropdownProps {
    /** key/value object where the key is the language and the value is the translation for that language */
    values: Record<string, string>;
    /** key/value object where the key is the language and the value is the label for that language */
    labels?: Record<string, string>;
    /** list of languages ids ('es', 'en', 'fr') */
    languages: string[];
    /** callback to be executed if any of the text fields change */
    onChange: (languages: Record<string, string>) => void;
    /** callback to be executed once OK button is clicked */
    onAccept: () => void;
    /** callback to be executed once the dropdown is closed */
    onClose: () => void;
    /** Whether the dropdown is open or not */
    isOpen?: boolean;
    /** Element that the dropdown will be anchored to */
    anchorRef: React.RefObject<HTMLElement>;
    /** wrapper custom class name */
    className?: string;
    /** whether the dropdown is displayed in a modal or not. */
    isInModal?: boolean;
    /** scope to be used for tracking purposes */
    scope?: string;
    /** props to be passed down to the main text field */
    textField?: Pick<TextFieldProps, 'maxLength' | 'minimumRows' | 'multiline'>;
}

const CLASSNAME = 'translations-dropdown';
/**
 * Displays a Dropdown with a list of Text Fields, where one Text Field corresponds to one of the provided
 * languages. This component is meant to be used as a way of adding multiple translations for a single input
 * in a Dropdown fashion.
 *
 * @family Translations
 * @param TranslationsDropdownProps
 * @returns TranslationsDropdown
 */
const TranslationsDropdown: React.FC<TranslationsDropdownProps> = ({
    values = {},
    labels = {},
    languages,
    onChange,
    onClose,
    onAccept,
    isOpen = false,
    anchorRef,
    className,
    isInModal = false,
    scope = SCOPE,
    textField = {},
}) => {
    const { translateKey } = useTranslate();
    const { get: getDataAttributes } = useDataAttributes(scope);
    const { block, element } = useClassnames(CLASSNAME);
    const languageLabels = React.useMemo(() => {
        const res: Record<string, string> = {};
        if (languages) {
            languages.forEach((language) => {
                res[language] =
                    labels && labels[language] ? labels[language] : translateKey(getKeyForLanguage(language));
            });
        }

        // Sort by language label
        return sortByValue(res);
    }, [languages, translateKey, labels]);

    /**
     * If the languages parameter is undefined or it is defined but the list is empty, we should
     * not render this component.
     */
    if (!languages || (languages && languages.length === 0)) {
        return null;
    }

    /**
     * Callback to be executed once the translation is entered on the text field.
     * It will update the internal state with the new values.
     */
    const onTranslationChange = (language: string) => (value: string) => {
        const updatedValues = {
            ...values,
            [language]: value,
        };

        onChange(updatedValues);
    };

    /**
     * Callback to be executed once the OK button is clicked. It will trigger the onChange
     * callback and close the dropdown
     */
    const onTranslationsAccepted = (event: React.MouseEvent) => {
        event.preventDefault();
        onAccept();
    };

    return (
        <Dropdown
            className={block(
                {
                    'in-modal': isInModal,
                },
                [className],
            )}
            isOpen={isOpen}
            closeOnEscape
            onClose={onClose}
            anchorRef={anchorRef}
            {...getDataAttributes({
                element: 'dropdown',
            })}
        >
            {Object.keys(languageLabels).map((language) => {
                return (
                    <TextField
                        key={language}
                        label={languageLabels[language]}
                        className={element('lang-text-field')}
                        value={values[language] || ''}
                        onChange={onTranslationChange(language)}
                        {...getDataAttributes({
                            element: 'text-field',
                            action: language,
                        })}
                        {...textField}
                    />
                );
            })}
            <Button
                className={element('accept')}
                emphasis={Emphasis.low}
                onClick={onTranslationsAccepted}
                {...getDataAttributes({
                    element: 'confirm',
                })}
            >
                {translateKey(GLOBAL.OK)}
            </Button>
        </Dropdown>
    );
};

export { TranslationsDropdown };
