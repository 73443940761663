import React from 'react';
import classNames from 'classnames';
import { string, shape, func } from 'prop-types';

import * as iconTypes from './types';
import * as uiTypes from '../types';

/**
 * Renders an icon.
 *
 * @return {ReactElement} The icon element.
 */
const Icon = ({ value, className, size, color, type, onClick, style, children }) => (
    <i
        className={classNames(
            className,
            'icon',
            'mdi',
            value ? `mdi-${value}` : undefined,
            size && `icon--${size}`,
            color && `icon--${color}`,
            type && `icon--${type}`,
        )}
        style={style}
        onClick={onClick}
    >
        {children}
    </i>
);
Icon.defaultProps = {
    children: null,
    className: null,
    color: null,
    onClick: null,
    size: null,
    style: null,
    type: null,
    value: null,
};
Icon.propTypes = {
    /** Extra class name. */
    children: shape(),
    /** Color to apply on the icon, from a `icon--${color}` CSS selector. */
    className: string,
    /** When the icon is clicked. */
    color: uiTypes.color,
    /** Color to apply on the icon, from a `icon--${size}` CSS selector. */
    onClick: func,
    /** The icon css style. */
    size: uiTypes.size,
    /** Type to apply on the icon, from a `icon--${type}` CSS selector. */
    style: shape(),
    /** Icon identifier from https://materialdesignicons.com. */
    type: iconTypes.type,
    value: string,
};

export { Icon };
