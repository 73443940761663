import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';

import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { onKey } from '@lumapps/utils/event/onKey';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { Editor, Transforms, ReactEditor } from '@lumapps/wrex/slate';

import type { QuoteEditor } from '../../types';
import { isQuoteBlock } from '../../utils/isQuoteBlock';

/**
 * Return true if Enter is pressed when the cursor is:
 * - In a quote block
 * - On the last paragraph of this codeblock
 * - And this paragraph is empty
 *
 * @param editor
 */
const isEnterPressedOnLastEmptyParagraphInQuoteBlock = (editor: Editor & QuoteEditor) => (event: KeyboardEvent) => {
    if (!editor.selection) {
        return false;
    }
    if (!isHotKey('Enter')(event)) {
        return false;
    }

    const currentQuoteBlockEntry = first(
        Array.from(
            editor.nodes({
                at: editor.selection,
                match: isQuoteBlock,
            }),
        ),
    );

    if (!currentQuoteBlockEntry) {
        return false;
    }

    const currentParagraphEntry = first(
        Array.from(
            editor.nodes({
                at: editor.selection,
                match: isParagraph,
            }),
        ),
    );

    if (!currentParagraphEntry || last(currentQuoteBlockEntry[0].children) !== currentParagraphEntry[0]) {
        return false;
    }

    return Editor.isEmpty(editor, currentParagraphEntry[0]);
};

/**
 * Return true if ArrowUp is pressed when the cursor is:
 * - In a quote block
 * - At the top of the document
 * - Is at the start of a paragraph and first paragraph inside the quote block
 */
const isExitingQuoteFromTop = (editor: ReactEditor & QuoteEditor) => (event: KeyboardEvent) => {
    if (!editor.selection) {
        return false;
    }

    const isArrowUpPressed = isHotKey('ArrowUp')(event);
    if (!isArrowUpPressed) {
        return false;
    }

    const [currentBlockAtFirstLevel, quoteBlockPath] = Editor.node(editor, editor.selection, {
        depth: 1,
    });

    if (!isQuoteBlock(currentBlockAtFirstLevel)) {
        return false;
    }

    if (!isEqual(quoteBlockPath, [0])) {
        return false;
    }

    const [currentParagraph] = Editor.node(editor, editor.selection, {
        depth: 2,
    });

    if (first(currentBlockAtFirstLevel.children) !== currentParagraph) {
        return false;
    }

    const currentNodeAsDOM = ReactEditor.toDOMNode(editor as ReactEditor, currentParagraph);
    const selectionRangeAsDOM = ReactEditor.toDOMRange(editor as ReactEditor, editor.selection);
    const isOnFirstLineOfBlock =
        currentNodeAsDOM.getBoundingClientRect().y === selectionRangeAsDOM.getBoundingClientRect().y - 2;

    return isOnFirstLineOfBlock;
};

/**
 * Initialize key event handler for the quote editor.
 *
 * When pressing 'Enter' in a quote block, on an empty paragraph, this paragraph should be removed,
 * and the cursor should be out of the quote block.
 *
 * @param editor          The editor
 */
export const getKeyHandler = (editor: ReactEditor & QuoteEditor) =>
    onKey(
        [
            {
                match: isExitingQuoteFromTop(editor),
                handler: () => {
                    // Insert a paragraph above the Quoteblock
                    Transforms.insertNodes(editor, createParagraph(), { at: [0] });
                    // Put the cursor on this paragraph
                    Transforms.select(editor, [0, 0]);
                },
            },
            {
                match: isEnterPressedOnLastEmptyParagraphInQuoteBlock(editor),
                handler: () => {
                    // Lift the last empty paragraph
                    Transforms.liftNodes(editor, {
                        match: isParagraph,
                    });
                },
            },
        ],
        { preventDefault: true },
    );
