import difference from 'lodash/difference';

import BaseApi from '@lumapps/base-api';
import { uploadFiles } from '@lumapps/base-api/utils/files';
import { ContainerType } from '@lumapps/medias/types';
import { encodeBase64UrlSafe } from '@lumapps/utils/string/base64UrlSafe';
import { sleep } from '@lumapps/utils/time/sleep';

import { DocumentV2 } from '../types';

export const v2Api = new BaseApi({
    version: BaseApi.versions.v2,
});

/** API parameterized paths. */
export const paths = {
    documents: () => 'documents',
    documentById: (docId: string) => `documents/${docId}`,
    documentsByIds: () => 'documents/get-by-ids',
};

export const getDocuments = (ids: string[]) =>
    v2Api.post(paths.documentsByIds(), {
        ids,
    });

export const redundantFetchMultiple = (
    ids: string[],
    maxTry: number,
    delay: number,
    documents: any[],
): Promise<(Omit<DocumentV2, 'docId'> & { id: string })[]> =>
    getDocuments(ids).then((res) => {
        if (maxTry === 1) {
            throw new Error('MAX TRY');
        }
        const newDocuments = [...documents, ...res.data.items];
        const missingIds = difference(
            ids,
            newDocuments.map((d) => d.id),
        );

        if (missingIds.length === 0) {
            return ids.map((id) => newDocuments.find((doc) => doc.id === id));
        }
        return sleep(delay).then(() => redundantFetchMultiple(missingIds, maxTry - 1, delay, newDocuments));
    });

/**
 * This function will create the documents scoped to the specified container (can be a
 * community or an organization), then upload every files to the previously created documents.
 * If `withRedundantGet` is set to true (default value), after uploading the files a call to
 * get the document will be performed redundantly, to be sure the document is available at the given
 * URL.
 * @returns A promise containing, when resolved, the uploaded document objects.
 */
export const uploadDocumentv2 = async ({
    container,
    files,
    abortController,
    withRedundantGet = true,
}: {
    /**
     * Object representing the container of the new documents.
     * The uploaded documents will be scoped to this container, only users that can
     * access the container will be able to display the images.
     */
    container: { id: string; type: ContainerType };
    /**
     * The files to upload.
     */
    files: File[] | FileList;
    /**
     * AbortController instance to cancel the call.
     */
    abortController?: AbortController;
    /**
     * Whether the GET call should be performed.
     * You should set it to true if you need to be sure the file link
     * is available. There is a short delay between the moment the file is uploaded
     * and the moment the file is available. This GET call is here to ensure the document is
     * available.
     * You can gain some seconds on your call setting this option to false.
     */
    withRedundantGet?: boolean;
}): Promise<DocumentV2[]> => {
    const MAX_TRY = 10;
    const DELAY_RETRY = 1000;
    if (!container.id) {
        throw new Error('');
    }

    const filesNames = Array.from(files).map((file) => file.name);

    const parentDocId = `provider=media/containerType=${container.type}/containerId=${container.id}`;

    const { data: documentsPostResponse } = await v2Api.post(
        paths.documents(),
        {
            names: filesNames,
            parentDocId: encodeBase64UrlSafe(parentDocId),
        },
        {
            signal: abortController?.signal,
        },
    );

    const documentsToUploadIds = documentsPostResponse.items.map((document: any) => document.docId);

    await uploadFiles({
        files,
        abortController,
        uploadFilesConfiguration: documentsPostResponse,
    });

    if (withRedundantGet) {
        return redundantFetchMultiple(documentsToUploadIds, MAX_TRY, DELAY_RETRY, []).then((items) =>
            items.map((it) => ({ docId: it.id, name: it.name, permalink: it.permalink })),
        );
    }

    return documentsPostResponse.items;
};
