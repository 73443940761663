/* istanbul ignore file */
import React from 'react';

import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';
import { isLayoutEnabled } from '@lumapps/widget-layout/ducks/selectors';

const TutorialLazy = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'tutorials-details' */
            './ConnectedTutorial'
        ),
);

/**
 * Lazy loads tutorials if needed
 */
const Tutorial: React.FC = () => {
    const user = useSelector(connectedUserSelector);
    const instanceId = useSelector(instanceIdSelector);
    const customerId = useSelector(customerIdSelector);
    const isLayoutOn = useSelector(isLayoutEnabled);

    /**
     * The only scenario where we need to load the tutorials is when there is a user
     * connected, when the current page should display a tutorial and when
     * the layout feature flag is enabled.
     */
    if (!user || !isLayoutOn) {
        return null;
    }

    return (
        <DelayedSuspense isDialog shouldLoad>
            <TutorialLazy
                instanceId={instanceId}
                customerId={customerId}
                userTutorials={user.tutorials as Record<string, Record<string, string>>}
            />
        </DelayedSuspense>
    );
};

export { Tutorial };
