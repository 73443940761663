import { useMemo } from 'react';

import filter from 'lodash/filter';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { useRouter } from '@lumapps/router';
import { DEFAULT_FILTER } from '@lumapps/search/constants';
import { search } from '@lumapps/search/routes';

const getFilterFacetSort = (sort?: string) => {
    switch (sort) {
        case '-publication_date':
        case '-updated_date':
            return '_metadata.updateTime';
        case 'publication_date':
        case 'updated_date':
            return '-_metadata.updateTime';
        default:
            return undefined;
    }
};

export const useGetSearchURL = (filtersValues: Record<string, any> | undefined) => {
    const { getUrl } = useRouter();
    const locale = useSelector(currentLanguageSelector);

    const advancedSearchURL = useMemo(() => {
        /** Selected filters are taking precedence over the prefilters */

        const searchQuery = filtersValues?.searchQuery || '*';
        const sort = getFilterFacetSort(filtersValues?.sort);
        const metadataIdForReal = map(filtersValues?.metadata, 'id');
        const siteNamesForReal = map(filtersValues?.site, 'name');
        const contentTypeNamesForReal = map(filtersValues?.contentTypes, (item) =>
            get(item?.name?.translations, locale),
        );

        /**
         * Listing facets
         */
        const facets = [
            { id: 'metadata.id', value: metadataIdForReal },
            { id: 'site.name', value: siteNamesForReal },
            { id: '_metadata.type', value: 'content' },
            { id: 'author.fullName.raw', value: filtersValues?.author?.fullName },
            { id: `facet.customContentType.name.${locale}`, value: contentTypeNamesForReal },
        ];

        /**
         * Removing facets with null, undefined or empty array value
         */
        const filteredFacets = filter(
            facets,
            (facet) => !isNil(facet.value) && !(Array.isArray(facet.value) && facet.value.length === 0),
        );

        const searchRoute = search(searchQuery, DEFAULT_FILTER, filteredFacets, sort);

        return getUrl(searchRoute);
    }, [filtersValues, locale, getUrl]);

    return advancedSearchURL;
};
