import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Link as LumxLink, type LinkProps as LumxLinkProps, Text, TextProps } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router';

import { getPlayVideoRouteById } from '../../../routes';
import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

import './index.scss';

const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-link` as const;

interface LinkProps extends Omit<LumxLinkProps, 'className' | 'linkAs' | 'to'> {
    /** Component children */
    children?: React.ReactNode;

    /** This stretches the link's layout over the closest parent with a relative position */
    stretched?: boolean;
    /**
     * Control whether the text should truncate or not.
     * Setting as `true` will make the text truncate on a single line.
     * Setting as `{ lines: number }` will make the text truncate on a multiple lines.
     */
    truncate?: TextProps['truncate'];
}

/**
 * Component to use to link something to the video page
 */
export const Link = ({ children, stretched, truncate, ...linkProps }: LinkProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { isLoading, video } = useBlockVideoContext();

    /**
     * ✅ We don’t want to return a link until the component has loaded
     * We simply return children which should take care of their skeletons
     */
    if (isLoading) {
        return <Text as="span">{children}</Text>;
    }

    const title = typeof video.title === 'string' ? video.title : video.title?.value;

    return (
        <LumxLink
            className={block({ 'is-truncated': Boolean(truncate), 'is-stretched': Boolean(stretched) })}
            linkAs={RouterLink}
            to={getPlayVideoRouteById(video.id)}
            {...linkProps}
        >
            <Text as="span" truncate={truncate}>
                {children
                    ? React.Children.map(
                          children,
                          (child) =>
                              React.isValidElement<{ isInteractive: boolean }>(child) &&
                              React.cloneElement(child, { isInteractive: true }),
                      )
                    : title}
            </Text>
        </LumxLink>
    );
};
