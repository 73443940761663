import { ChatProviderId, ChatProviderValues } from './types';

/** Chat providers feature tokens */
export const YAMMER_FEATURE = 'yammer';
export const WORKPLACE_FEATURE = 'workplace';
export const TEAMS_FEATURE = 'teams';

/**
 * Defines the values for each chat providers.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
export const CHAT_PROVIDER_VALUES: ChatProviderValues = {
    FBWORKPLACE: { id: ChatProviderId.fbworkplace, name: 'Workplace' },
    SLACK: { id: ChatProviderId.slack, name: 'Slack' },
    GOOGLE_CHAT: { id: ChatProviderId.google_chat, name: 'Google Chat' },
    TEAMS: { id: ChatProviderId.teams, name: 'Teams' },
    YAMMER: { id: ChatProviderId.yammer, name: 'Yammer' },
};

/**
 * Corresponding providers names to there values.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
export const CHAT_PROVIDER_NAMES: Record<ChatProviderId, any> = {
    fbworkplace: CHAT_PROVIDER_VALUES.FBWORKPLACE,
    slack: CHAT_PROVIDER_VALUES.SLACK,
    google_chat: CHAT_PROVIDER_VALUES.GOOGLE_CHAT,
    teams: CHAT_PROVIDER_VALUES.TEAMS,
    yammer: CHAT_PROVIDER_VALUES.YAMMER,
};

export const MS_CHAT_PROVIDERS = [ChatProviderId.teams, ChatProviderId.yammer];
export const EXTERNAL_CHAT_PROVIDERS = [ChatProviderId.fbworkplace, ChatProviderId.slack];

const MAX_RESULTS = 30;
const TIMEOUT_INTERVAL = 1000;
const SLACK_FEATURE = 'slack';
const SLACK_DEFAULT_FEATURE_CODE = 'slack-default-app';
const SLACK_PROVIDER = 'slack';
const SLACK_DEFAULT_APP_ID = '186469210080672980362574161471706310161';
const SLACK_COLOR = '#4B154A';

export {
    MAX_RESULTS,
    SLACK_FEATURE,
    SLACK_DEFAULT_FEATURE_CODE,
    SLACK_PROVIDER,
    SLACK_DEFAULT_APP_ID,
    TIMEOUT_INTERVAL,
    SLACK_COLOR,
};
