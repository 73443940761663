import { useLocation as useReactRouterLocation } from 'react-router-dom';

import { get as getConstants } from '@lumapps/constants';

import { removeBaseSlug } from '../utils';

const constants = getConstants();

/**
 * Returns the current pathname.
 * If we are in the react context, return the location
 * from react-router-drom
 */
export const useLocation = () => {
    const location = useReactRouterLocation();

    if (!constants.isLegacyContext) {
        return location;
    }

    const currentLocation = window.location.pathname;

    return {
        key: undefined,
        ...window.location,
        pathname: removeBaseSlug(currentLocation),
    };
};
