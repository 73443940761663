import React from 'react';

import { InstancesCopySideNavigation } from '@lumapps/instance-copy/components/InstancesCopySideNavigation/InstancesCopySideNavigation';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiCheckboxMultipleBlank } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SIDE_NAV_ID } from '../../constants';
import { INSTANCE } from '../../keys';
import { instances } from '../../routes';

export interface InstancesSideNavigationProps {
    /** Is user allowed */
    isAllowed: boolean;
}

const InstancesSideNavigation: React.FC<InstancesSideNavigationProps> = ({ isAllowed }) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(INSTANCE.INSTANCES_TITLE)}
            icon={mdiCheckboxMultipleBlank}
            id={SIDE_NAV_ID}
        >
            <SideNavigationItem label={translateKey(INSTANCE.MANAGEMENT)} route={instances()} />
            <InstancesCopySideNavigation />
        </SideNavigationItem>
    );
};

InstancesSideNavigation.displayName = 'SideNavigationItem';

export { InstancesSideNavigation };
