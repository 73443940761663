import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import unset from 'lodash/unset';

import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

import createSlice, { PayloadAction } from '../createSlice';
import { Values, Schema, SchemaOption, Errors, Touched, FormState, CreateFormSliceOptions } from './types';

/**
 * Converts the given validationSchema to object if a function is passed
 * @param validationSchema The validation schema
 * @param state The state to validate
 */
const createValidationSchema = <T>(validationSchema: SchemaOption<T>, state: FormState<T>): Schema<T> =>
    typeof validationSchema === 'function' ? validationSchema(state) : validationSchema;

/**
 * Recursively validates values with a schema
 * @param values The values object to recursively validate
 * @param schema The schema to use to validate each field
 * @param rootValues The complete root values object
 * @return {Object}        The error object, mapping every field with the corresponding error.
 */
export const validateValues = <T extends Values>(values: T, schema: Schema<T>): Errors<T> => {
    const errors = {};
    const validationsKeys = Object.keys(schema);

    for (let i = 0; i < validationsKeys.length; i++) {
        const key = validationsKeys[i];
        const fieldValidation = schema[key];
        const currentValues = get(values, key);

        if (fieldValidation) {
            if (typeof fieldValidation === 'function') {
                const value = get(values, key);
                const error = fieldValidation(value);

                if (error) {
                    set(errors, key, error);
                }
            } else {
                const currentErrors = validateValues(currentValues, fieldValidation);

                if (!isEmpty(currentErrors)) {
                    set(errors, key, currentErrors);
                }
            }
        }
    }

    return errors;
};

/**
 * Wrap a simple values object into an object containing additional informations.
 *
 * @param values           An object containing the values of your form.
 * @param validationSchema The schema that will be used to validate each field.
 * @return                 The values wrapped with other informations.
 */
const formatValues = <T extends Values>(values: T, validationSchema?: SchemaOption<T>): FormState<T> => {
    const formState: FormState<T> = {
        errors: {},
        hasError: false,
        isTouched: false,
        isDirty: false,
        touched: {},
        meta: {},
        dirty: {},
        values,
    };
    const errors = validationSchema ? validateValues(values, createValidationSchema(validationSchema, formState)) : {};

    return {
        ...formState,
        errors,
        hasError: !isEmpty(errors),
    };
};

/**
 * Unsets an element from an object, and empties parent if no element is left
 * @param object The object to remove the field from
 * @param fieldPath The path to unset
 */
const unsetFieldFromObject = (object: Record<string, any>, fieldPath: string) => {
    unset(object, `${fieldPath}`);

    // Check if parent has any child left. If not, unset parent
    const [parentPath] = /.*(?=\.)/.exec(fieldPath) || [];
    if (parentPath && isEmpty(get(object, parentPath, {}))) {
        unsetFieldFromObject(object, parentPath);
    }
};

/**
 * Please use @lumapps/lumx-forms in order to manage the state of your form.
 * A wrapper to create a pre-configured slice designed to handle forms.
 *
 * @deprecated
 * @param domain           The domain name of the form reducer.
 * @param initialValues    The initial values of your form.
 * @param validationSchema The validation schema.
 * @return                 A slice with form actions.
 */
const createFormSlice = <T extends Values>({ domain, initialValues, validationSchema }: CreateFormSliceOptions<T>) => {
    let initialState = formatValues(initialValues, validationSchema);
    const formSlice = createSlice({
        domain,
        initialState,
        reducers: {
            /**
             * Sets a value to a field and triggers it's validation.
             * Can also ask for complete form validation.
             * If field is nested, use dot anotation : user.address.city
             */
            setFormFieldValue: (
                state: FormState<T>,
                action: PayloadAction<{
                    /**
                     * The field to set the value to.
                     */
                    fieldId: string;
                    /**
                     * The value to set.
                     */
                    value: any;
                    /**
                     * Whether the field should be set as touched or not
                     * True by default.
                     */
                    isTouched?: boolean;
                    /**
                     * Whether the whole form should be validated or not.
                     * False by default.
                     */
                    validateForm?: boolean;
                    /**
                     * Whether the field should be validated or not.
                     * True by default.
                     */
                    validateField?: boolean;
                    /**
                     * Whether we should test on trimmed value or not, while setting the non-trimmed value in the store.
                     *
                     * @type {boolean}
                     */
                    withTrim?: boolean;
                }>,
            ) => {
                const {
                    fieldId,
                    value,
                    validateForm = false,
                    isTouched = true,
                    validateField = true,
                    withTrim = false,
                } = action.payload;

                const testValue = withTrim ? value.trim() : value;

                // If withTrim is set to true, we still want to set the non-trimmed value in the store
                // This way we the component that reads the store value still can type spaces in the text input
                set(state, `values.${fieldId}`, value);
                set(state, `touched.${fieldId}`, isTouched);
                set(state, 'isTouched', state.isTouched || isTouched);

                // Manage dirty value
                const initialValue = get(initialState, `values.${fieldId}`);
                const isDirty = isArray(testValue) ? !isEqual(testValue, initialValue) : testValue !== initialValue;

                if (isDirty) {
                    set(state, `dirty.${fieldId}`, isDirty);
                } else {
                    unsetFieldFromObject(state.dirty, fieldId);
                }
                set(state, 'isDirty', !isEmpty(state.dirty));

                // Manage errors
                if (validationSchema) {
                    const validationFuncs = createValidationSchema(validationSchema, state);

                    const fieldValidation = get(validationFuncs, fieldId);

                    if (validateForm) {
                        const errors = validateValues(state.values, validationFuncs);
                        set(state, 'errors', errors);
                    } else if (validateField && typeof fieldValidation === 'function') {
                        const fieldError = fieldValidation(testValue);

                        if (fieldError) {
                            set(state, `errors.${fieldId}`, fieldError);
                        } else {
                            unsetFieldFromObject(state.errors, fieldId);
                        }
                    }
                }

                set(state, 'hasError', !isEmpty(state.errors));

                return state;
            },
            /**
             * Set multiple form values.
             * Current form values will be overridden if merge option is undefined or at false.
             */
            setFormValues: (
                state: FormState<T>,
                action: PayloadAction<
                    | {
                          /**
                           * An object of values to set.
                           * ex: { name: 'foo', description: 'bar }
                           */
                          values: T;
                          /**
                           * whether the new values should be seen as initial or not
                           * If set as true, calling resetForm will set the form with these values.
                           */
                          setAsInitial?: boolean;
                          /**
                           * If true, merges the value instead of overriding the whole form.
                           */
                          merge?: false;
                      }
                    | {
                          /**
                           * An object of values to set.
                           * ex: { name: 'foo', description: 'bar }
                           */
                          values: Partial<T>;
                          /**
                           * whether the new values should be seen as initial or not
                           * If set as true, calling resetForm will set the form with these values.
                           */
                          setAsInitial?: boolean;
                          /**
                           * If true, merges the value instead of overriding the whole form.
                           */
                          merge: true;
                      }
                >,
            ) => {
                const { values, setAsInitial = false, merge = false } = action.payload;
                let newValues = values as T;

                if (merge) {
                    const stateValues = cloneDeep(state.values);
                    newValues = mergeObjectOnly(stateValues, values) as T;
                }

                const newState = formatValues(newValues, validationSchema);

                if (setAsInitial) {
                    initialState = newState;
                }

                return newState;
            },
            /**
             * Set a meta data value for a field.
             * Sets field as touched and "isTouched" to true.
             * If field is nested, use dot anotation : user.address.city
             */
            setFieldMeta: (
                state: FormState<T>,
                action: PayloadAction<{
                    /**
                     * The field to set the meta data to.
                     */
                    fieldId: string;
                    /**
                     * The name of the meta field
                     * ex: isOpen
                     */
                    metaName: string;
                    /**
                     * The value to set.
                     */
                    metaValue: any;
                    /**
                     * The value to set.
                     */
                    validateForm?: boolean;
                }>,
            ) => {
                const { fieldId, metaName, metaValue, validateForm = false } = action.payload;

                if (metaName) {
                    set(state, `meta.${fieldId}.${metaName}`, metaValue);
                    set(state, `touched.${fieldId}`, true);
                    set(state, 'isTouched', true);

                    if (validationSchema && validateForm) {
                        const validationFuncs = createValidationSchema(validationSchema, state);
                        const errors = validateValues(state.values, validationFuncs);

                        set(state, 'errors', errors);
                        set(state, 'hasError', !isEmpty(errors));
                    }
                }

                return state;
            },
            /**
             * Sets an error on a field.
             * To remove an error, set error as empty string.
             * Will set "hasError" to true if at least one field has an error.
             */
            setFieldError: (
                state: FormState<T>,
                action: PayloadAction<{
                    /**
                     * The field to set the error to.
                     */
                    fieldId: string;
                    /**
                     * The error to set.
                     * Empty string for no error.
                     */
                    errorMessage: string;
                    /**
                     * Whether the field should be set as touched or not
                     * False by default.
                     */
                    setTouched?: boolean;
                }>,
            ) => {
                const { fieldId, errorMessage, setTouched } = action.payload;

                if (errorMessage) {
                    set(state, `errors.${fieldId}`, errorMessage);
                } else {
                    unsetFieldFromObject(state.errors, fieldId);
                }

                if (setTouched) {
                    set(state, `touched.${fieldId}`, true);
                }

                set(state, 'hasError', !isEmpty(state.errors));

                return state;
            },
            /**
             * Set all of the forms errors.
             * Current form errors will be overridden.
             * Will set "hasError" to true if at least one field has an error.
             */
            setFormErrors: (
                state: FormState<T>,
                action: PayloadAction<{
                    /**
                     * An object of errors to set.
                     * ex: { name: 'foo', description: 'bar }
                     */
                    errors: Errors<Values>;
                    /**
                     * Whether the fields should also be set as touched or not.
                     * False by default.
                     */
                    setTouched?: boolean;
                }>,
            ) => {
                const { errors, setTouched } = action.payload;
                set(state, 'errors', errors);

                if (setTouched && errors) {
                    Object.keys(errors).forEach((fieldId) => {
                        set(state, ['touched', fieldId], true);
                    });
                }

                set(state, 'hasError', !isEmpty(state.errors));

                return state;
            },
            /**
             * Sets a field as touched.
             * Will set "isTouched" to true if at least one field in form is touched.
             */
            setFieldTouched: (
                state: FormState<T>,
                action: PayloadAction<{
                    /**
                     * The field to set as touched.
                     */
                    fieldId: string;
                    /**
                     * Whether the field is touched or not.
                     */
                    isTouched: boolean;
                }>,
            ) => {
                const { fieldId, isTouched } = action.payload;

                if (isTouched) {
                    set(state, `touched.${fieldId}`, isTouched);
                } else {
                    unsetFieldFromObject(state.touched, fieldId);
                }

                set(state, 'isTouched', !isEmpty(state.touched));

                return state;
            },
            /**
             * Set all of the forms touched fields.
             * Current form touched object will be overridden.
             * Will set "isTouched" to true if at least one field touched.
             */
            setFormTouched: (
                state: FormState<T>,
                action: PayloadAction<{
                    /**
                     * An object of values to be touched or not.
                     * Ex: { name: true }
                     */
                    touched: Touched<Values>;
                }>,
            ) => {
                const { touched } = action.payload;

                set(state, 'touched', touched);

                set(state, 'isTouched', !isEmpty(state.touched));

                return state;
            },
            /**
             * Resets form to it's initial values.
             */
            resetForm: () => {
                initialState = formatValues(initialValues, validationSchema);
                return initialState;
            },
        },
    });

    return { ...formSlice, initialState };
};

export default createFormSlice;
