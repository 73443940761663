import { SEGMENT } from './keys';
import { PropertiesList, QueryContextKey, SegmentFieldId, SegmentStatus } from './types';

/** Feature flags */
export const SEGMENT_ADMIN_FEATURE_TOKEN = 'segment';
export const DISCOVER_ALPHA_FEATURE_TOKEN = 'discover-alpha';
export const DISCOVER_BETA_FEATURE_TOKEN = 'discover-beta';

export const SEGMENT_TITLE_MAX_LENGTH = 200;
export const SEGMENT_DESCRIPTION_MAX_LENGTH = 150;
export const SEGMENT_GRP_PICKER_PAGE_SIZE = 30;
export const SEGMENT_LIST_PAGE_SIZE = 30;

export const MAX_OR_BLOCKS_PER_AND_BLOCK = 10;
export const MAX_AND_BLOCKS = 10;
export const MAX_USER_PROFILE_VALUES_PER_SELECT = 50;
export const MAX_METADATA_VALUES_PER_SELECT = 50;
export const MAX_GROUPS_PER_SELECT = 50;
export const MAX_USERS_PER_SELECT = 50;
export const MAX_TEXT_FIELD_CHARACTERS = 50;
export const SEGMENT_CHIP_SIZE_TRUNCATE = 35;

/** Limit of the number of in/not-in csv operators allowed */
export const SEGMENT_IN_CSV_OPERATORS_LIMIT = 5;

export const SEGMENT_CDN_FOLDER_PATH = 'segment';
export const SEGMENT_CDN_CSV_EXAMPLE_FILENAME = 'segment_import_example.csv';

export const SCOPE = 'segment';

export const EMPTY_CRITERIA = {
    in: { 'user.groups': [] },
};

export const EMPTY_AND_BLOCK = {
    or: [EMPTY_CRITERIA],
};

export const EMPTY_QUERY = {
    and: [EMPTY_AND_BLOCK],
};

export const EMPTY_SEGMENT = {
    status: SegmentStatus.live,
    title: '',
    query: EMPTY_QUERY,
};

export const INITIAL_PROPERTIES_VALUES: PropertiesList = {
    promotion: false,
    analytics: false,
};

export const SEGMENT_EMPTY_DESC_PLACEHOLDER = '-';
export const SEGMENT_IN_GROUP_PICKER_IDENTIFIER = 'segment/';
export const SEGMENT_POPOVER_CLASSNAME = 'segment-description-popover';

export const SEGMENT_DATA_IDS_MAPPING: Record<string, string> = {
    create: 'creation',
    edit: 'edition',
    drawer: 'list-drawer',
};

export const QueryContextKeyToAttribute = {
    [QueryContextKey.DATE]: 'user.profile.date.',
    [QueryContextKey.METADATAS]: 'user.profile.metadata.',
    [QueryContextKey.USERS]: 'user.profile.',
    [QueryContextKey.USER_PROFILE_DEFINITIONS]: 'user.profile.',
    [QueryContextKey.GROUPS]: 'user.profile.',
};

export const SystemDateList = [
    SegmentFieldId.USER_ACCOUNT_CREATION,
    SegmentFieldId.USER_FIRST_SESSION,
    SegmentFieldId.USER_LAST_SESSION,
];

export const SystemDatesToAttribute = {
    [SegmentFieldId.USER_ACCOUNT_CREATION]: SEGMENT.ACCOUNT_CREATION_DATE,
    [SegmentFieldId.USER_FIRST_SESSION]: SEGMENT.ACCOUNT_FIRST_SESSION,
    [SegmentFieldId.USER_LAST_SESSION]: SEGMENT.ACCOUNT_LAST_SESSION,
};

export const RELATIVE_DATE_QUANTITY_MAX_VALUE = 100000;
export const RELATIVE_DATE_QUANTITY_MIN_VALUE = 0;
