export interface BoundInputSelectionOptionsType {
    /** The minimum position allowed for the selection */
    min?: number;
    /** The maximum position allowed for the selection */
    max?: number;
}

/**
 * Bound the text selection in an input (or textarea)
 * between two given positions.
 */
export const boundInputSelection = (elem: HTMLInputElement, options: BoundInputSelectionOptionsType) => {
    if (!elem) {
        return;
    }
    /** Get the current selection's start and end position.
     * If no text is selected, both will return the current
     * cursor position.
     *
     */
    const { selectionStart, selectionEnd } = elem;
    const { min, max } = options;

    /**
     * if the selection range goes below the given minimum,
     * set the selection start to this minimum.
     */
    if (min && (selectionStart || selectionStart === 0) && selectionStart < min) {
        // eslint-disable-next-line no-param-reassign
        elem.selectionStart = min;
    }

    /**
     * if the selection range goes above the given maximum,
     * set the selection start to this maximum.
     */
    if (max && selectionEnd && selectionEnd > max) {
        // eslint-disable-next-line no-param-reassign
        elem.selectionEnd = max;
    }
};
