import get from 'lodash/get';
import includes from 'lodash/includes';

function CustomerService(
    $injector,
    $location,
    $q,
    AdvancedSettingsConstant,
    BaseService,
    Config,
    CustomerFactory,
    ReduxStore,
    LocalStorage,
    Utils,
) {
    'ngInject';

    /* eslint-disable consistent-this */
    const service = BaseService.createPaginatedService(CustomerFactory);

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Contains an empty customer used in the login page without any customer and/or instance slug.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _EMPTY_CUSTOMER = {
        configurations: [],
        excludedFeatures: [],
        externalAccounts: true,
        features: [],
        googleCustomer: false,
        hasGoogleSso: false,
        loginGoogleOnly: false,
        loginMethods: ['google', 'email'],
        // eslint-disable-next-line camelcase
        logout_urls: {
            email: '/logout',
            google: 'https://accounts.google.com/logout',
        },
        overrides: [],
        properties: {},
        ssoEnabled: false,
        themes: [],
    };

    /**
     * Contains the projections needed in the service.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    // TODO[Marco]: Use this projections in Settings.init.
    // eslint-disable-next-line no-unused-vars
    const _PROJECTIONS = {
        admin: {
            account: true,
            adminEmail: true,
            configurations: true,
            contactEmail: true,
            contactFirstName: true,
            contactLastName: true,
            contactPhone: true,
            country: true,
            domain: true,
            excludedFeatures: true,
            externalAccounts: true,
            externalDirectory: true,
            features: true,
            feedAll: true,
            feedPublic: true,
            googleCustomer: true,
            hasGoogleSso: true,
            id: true,
            isReseller: true,
            loginGoogleOnly: true,
            loginMethods: true,
            mobileCustomization: true,
            mobileGoogleAnalyticsID: true,
            mobileInstanceKeys: true,
            mobileSearchInstanceKeys: true,
            name: true,
            overrides: true,
            properties: true,
            publicContentAuthorized: true,
            size: true,
            slug: true,
            ssoEnabled: true,
            themes: true,
            trustedTester: true,
            uid: true,
            userProfilePicture: true,
        },
        get: {
            configurations: true,
            domain: true,
            excludedFeatures: true,
            externalAccounts: true,
            externalDirectory: true,
            features: true,
            feedAll: true,
            feedPublic: true,
            googleCustomer: true,
            id: true,
            isReseller: true,
            loginGoogleOnly: true,
            loginMethods: true,
            mobileCustomization: true,
            mobileInstanceKeys: true,
            mobileSearchInstanceKeys: true,
            name: true,
            overrides: true,
            properties: true,
            publicContentAuthorized: true,
            slug: true,
            themes: true,
            trustedTester: true,
            uid: true,
            userProfilePicture: true,
        },
    };

    /**
     * The customer object.
     *
     * @type {Object}
     */
    let _customer;

    /**
     * The promise of the load of the customer.
     *
     * @type {Promise}
     */
    const _customerLoadingDeferred = $q.defer();

    /**
     * Contains the customer status used in the admin customer page.
     *
     * @type {Object}
     */
    let _customerStatus = {
        // eslint-disable-next-line id-blacklist
        error: 'LOADING.CUSTOMER_STATUS_ERROR',
        status: false,
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The default parameters used for all the endpoints calls in this service.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     * Contains various indicators about the state of the service.
     *
     * @type {Object}
     */
    service.is = {
        initialized: false,
        initializing: false,
    };

    /**
     * The key of the object identifier for the customer.
     *
     * @type {string}
     */
    service.objectIdentifier = 'uid';

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Generate the registration link for a customer.
     *
     * @param  {string}   kind                 The type of registration link to generate. Either 'google' or 'classic'.
     * @param  {Function} [cb=angular.noop]    A callback function to exectue on success.
     * @param  {Function} [errCb=angular.noop] A callback function to execute on error.
     * @return {Promise}  The promise of the registrationLink call.
     */
    function generateRegistrationLink(kind, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        return CustomerFactory.registrationLink(
            {
                kind,
            },
            function onRegistrationLinkSuccess(response) {
                if (angular.isDefinedAndFilled(response.url)) {
                    response.url = location.origin + response.url;
                }

                cb(response);
            },
            errCb,
        );
    }

    /**
     * Get a property from the current instance.
     *
     * @param  {string}  key           The Property name.
     * @param  {*}       [fallback]    The fallback value if nothing found.
     * @param  {boolean} [force=false] Act as overridden property, for to get customer property.
     * @return {*}       The wanted property if found.
     */
    function getAdminProperty(key, fallback, force) {
        const defaultValue = get(AdvancedSettingsConstant.OPTIONS, `${Utils.toUpperSnakeCase(key)}.default`, fallback);

        return Utils.getAdminProperty(key, service.getCustomer(), defaultValue, fallback, force);
    }

    /**
     * Get all Customer statuses available.
     *
     * @return {Array} An array of statuses objects.
     */
    function getAllStatus() {
        return Config.CUSTOMER_STATUS;
    }

    /**
     * Get all Customer license types available.
     *
     * @return {Array} An array of license types objects.
     */
    function getAllLicenseTypes() {
        return Config.CUSTOMER_LICENSE_TYPES;
    }

    /**
     * Get available configurations for instances.
     *
     * @return {Array} A list of the configuration objects for the current customer.
     */
    function getConfigurations() {
        return service.getCustomer().configurations;
    }

    /**
     * Get the current customer.
     *
     * @param  {boolean} [async=false] If we want to fetch the customer asynchronously
     *                                 to make sure it is set when we need it.
     * @return {Object}  The customer object.
     */
    function getCustomer(async) {
        async = async || false;

        if (async) {
            if (service.is.initializing) {
                return _customerLoadingDeferred.promise;
            }

            return $q.resolve(_customer || {});
        }

        return _customer || {};
    }

    /**
     * Get the customer id from Jinja.
     *
     * @return {string} The current customer id.
     */
    function getCustomerId() {
        return service.getCustomer().id;
    }

    /**
     * Get the customer slug from Jinja.
     *
     * @param  {boolean}       [asObject=false] Indicates if we want the response as an object containing a `isDomain`
     *                                          property that indicates if the slug returned is in fact the domain
     *                                          of the customer and a `slug` property containing the computed slug
     *                                          (or domain).
     * @return {string|Object} The current customer slug (as object or string according to the `asObject` param).
     */
    function getCustomerSlug(asObject) {
        const customer = service.getCustomer();

        if (angular.isDefinedAndFilled(get(customer, 'slug'))) {
            return asObject
                ? {
                      isDomain: false,
                      slug: customer.slug,
                  }
                : customer.slug;
        }

        const isCustomDomain = !includes($location.url(), '/a/');

        /*
         * Fallback to computing the slug with the URL.
         * There is two cases:
         *  - the URL contains a '/a/' part, which mean that the customer slug is the next part enclosed by '/';
         *  - the URL doesn't contains a '/a/' part (it usually is the case for custom domains), in which case the
         *    "slug" (in fact, the part that can be used to identify the customer) is the domain of the URL;
         */
        const slug = isCustomDomain ? $location.host() : ($location.path().split('/') || ['', 'a', '', ''])[2];

        if (asObject) {
            return {
                isDomain: isCustomDomain,
                slug,
            };
        }

        return slug;
    }

    /**
     * Get current customer domain.
     *
     * @return {string} The current customer domain.
     */
    function getDomain() {
        return service.getCustomer().domain;
    }

    /**
     * Get the error related to the customer in the customer admin page..
     *
     * @return {string} The error related to the customer.
     */
    function getError() {
        return _customerStatus.error || '';
    }

    /**
     * Get external directory url.
     *
     * @return {string} The url to the external directory for the current customer.
     */
    function getExternalDirectory() {
        return service.getCustomer().externalDirectory;
    }

    /**
     * Return available overrides for instances.
     *
     * @return {Array} A list of the overrides for the current customer.
     */
    function getOverrides() {
        return service.getCustomer().overrides;
    }

    /**
     * Get a property from instance.properties else fallback in different configuration services.
     *
     * @param  {string} key The config key identifier.
     * @return {*}      The wanted property.
     */
    function getProperty(key) {
        const value = Utils.getProperty(key, service.getCustomer());

        if (angular.isDefined(value)) {
            return value;
        }

        return Utils.getConfigProperty(key);
    }

    /**
     * Get the status of the customer.
     *
     * @return {boolean} The status of the customer.
     */
    function getStatus() {
        return _customerStatus.status || false;
    }

    /**
     * Get available themes for instances of the current customer.
     *
     * @return {Array} A list of themes available for the current customer.
     */
    function getThemes() {
        return service.getCustomer().themes;
    }

    /**
     * Get user profile picture.
     *
     * @return {string} The path to the user profile picture.
     */
    function getUserProfilePicture() {
        return service.getCustomer().userProfilePicture;
    }

    /**
     * Initialize an empty customer.
     *
     * @param {boolean} [googleCustomer=false] Indicates if we want the empty customer to be a google customer.
     */
    function initEmptyCustomer(googleCustomer) {
        const emptyCustomer = angular.fastCopy(_EMPTY_CUSTOMER);
        emptyCustomer.googleCustomer = Boolean(googleCustomer);

        if (angular.isDefinedAndFilled($location.search().token)) {
            delete emptyCustomer.googleCustomer;
        }

        service.setCustomer(emptyCustomer);

        _customerStatus = {
            // eslint-disable-next-line id-blacklist
            error: undefined,
            status: true,
        };
    }

    /**
     * Retrieve the status of a customer for the customer admin page.
     *
     * @param  {string}  [token] The token used to create/administrate the customer.
     * @return {Promise} The promise of the initialization of the status of the customer.
     */
    function initStatus(token) {
        return $q((resolve, reject) => {
            CustomerFactory.status({
                customerId: service.getCustomerId(),
                token,
            })
                .$promise.then((response) => {
                    _customerStatus = {
                        // eslint-disable-next-line id-blacklist
                        error: response.error,
                        status: response.status,
                    };

                    resolve(_customerStatus);
                })
                .catch(reject);
        });
    }

    /**
     * Check if customer allows external accounts.
     *
     * @return {boolean} Whether this customer allows external accounts or not.
     */
    function isExternalAccounts() {
        return service.getCustomer().externalAccounts;
    }

    /**
     * Check if customer is a Google customer.
     *
     * @return {boolean} Whether the customer is a google customer or not.
     */
    function isGoogleCustomer() {
        return get(service.getCustomer(), 'googleCustomer', false);
    }

    /**
     * Check if public content is allowed for the current customer.
     *
     * @return {boolean} Whether the current customer can have public content or not.
     */
    function isPublicContentAuthorized() {
        return service.getCustomer().publicContentAuthorized;
    }

    /**
     * Check if customer is reseller.
     *
     * @return {boolean} Whether the customer is a reseller or not.
     */
    function isReseller() {
        return service.getCustomer().isReseller;
    }

    /**
     * Check if SSO is enabled for this customer.
     *
     * @return {boolean} Whether single sign on is enabled or not for this customer.
     */
    function isSsoEnabled() {
        const customer = service.getCustomer();

        return customer.ssoEnabled && customer.googleCustomer;
    }

    /**
     * Sets the current customer.
     *
     * @param {Object} customer The customer to set the current customer to.
     */
    function setCustomer(customer) {
        _customer = customer;
        window.CUSTOMER_ID = customer.id;
        LocalStorage.put(`customer-${_customer.slug}`, _customer);
        LocalStorage.put(`customer-${_customer.uid}`, _customer);

        $injector.get('Feed').setTechnicalFeeds(_customer.feedAll, _customer.feedPublic);
    }

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should retrun the service data that need to be synced with redux.
     *
     * @return {Object} The data. Aka. store shape.
     */
    function mapStateToRedux() {
        const availableLanguages = service.getProperty(Config.INSTANCE_PROPERTIES.AVAILABLE_LANGS);
        const enabledFeatures = get(_customer, 'enabledFeatures', []);
        const excludedFeatures = get(_customer, 'excludedFeatures', []);
        const features = get(_customer, 'features', []);
        const feedAll = get(_customer, 'feedAll', {});
        const feedPublic = get(_customer, 'feedPublic', {});
        const hasTermsAndConditions = get(_customer, 'hasTermsAndConditions', false);
        const id = get(_customer, 'id');
        const isReseller = get(_customer, 'isReseller', []);
        const name = get(_customer, 'name');
        const slug = get(_customer, 'slug', {});
        const useBookmarks = get(_customer, 'useBookmarks', false);

        return {
            availableLanguages,
            enabledFeatures,
            excludedFeatures,
            features,
            feedAll,
            feedPublic,
            hasTermsAndConditions,
            id,
            isReseller,
            name,
            slug,
            useBookmarks
        };
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'customer';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'customer/update';

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////

    service.generateRegistrationLink = generateRegistrationLink;
    service.getAllStatus = getAllStatus;
    service.getAllLicenseTypes = getAllLicenseTypes;
    service.getConfigurations = getConfigurations;
    service.getCustomer = getCustomer;
    service.getCustomerId = getCustomerId;
    service.getCustomerSlug = getCustomerSlug;
    service.getDomain = getDomain;
    service.getError = getError;
    service.getExternalDirectory = getExternalDirectory;
    service.getOverrides = getOverrides;
    service.getAdminProperty = getAdminProperty;
    service.getProperty = getProperty;
    service.getStatus = getStatus;
    service.getThemes = getThemes;
    service.getUserProfilePicture = getUserProfilePicture;
    service.initEmptyCustomer = initEmptyCustomer;
    service.initStatus = initStatus;
    service.isExternalAccounts = isExternalAccounts;
    service.isGoogleCustomer = isGoogleCustomer;
    service.isPublicContentAuthorized = isPublicContentAuthorized;
    service.isReseller = isReseller;
    service.isSsoEnabled = isSsoEnabled;
    service.setCustomer = setCustomer;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    service.init = function init() {
        // Enable Redux sync.
        ReduxStore.subscribe(service);
    };

    ////////////////////////////
    return service;
}

/////////////////////////////

angular.module('Services').service('Customer', CustomerService);

/////////////////////////////

export { CustomerService };
