import { TranslationAPI } from '@lumapps/translations';

import { Header } from '../../types';

/**
 * Get widget header label using the translated `label` prop in priority or translate the `labelKey`.
 */
export function getHeaderLabel(
    header: Header | undefined,
    translateKey: TranslationAPI['translateKey'],
): string | undefined {
    if (header?.label) {
        return header?.label;
    }
    if (header?.labelKey) {
        return translateKey(header?.labelKey);
    }
    return undefined;
}
