import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchNode } from '@lumapps/wrex/serialization/dita/fromDITA/utils';

import { createTable } from '../../utils/createTable';
import { createTD } from '../../utils/createTD';
import { createTR } from '../../utils/createTR';

/**
 * Convert Dita simpletable into slate Table element.
 */
export const SIMPLETTABLE_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'simpletable' }),
    transform: (node, children) => createTable({}, children),
};

/**
 * Convert Dita strow into slate TR element.
 */
export const STROW_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'strow' }),
    transform: (node, children) => createTR(children),
};

/**
 * Convert Dita stentry into slate TD element.
 */
export const STENTRY_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'stentry' }),
    transform: (node, children) => createTD(children),
};
