(function IIFE() {
    'use strict';

    /////////////////////////////

    function FrontController(Instance, $state, $scope) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Instance = Instance;

        vm.currentView = $state.current.name;

        $scope.$on('$stateChangeSuccess', function onStateChangeStart(evt, toState) {
            vm.currentView = $state.current.name;
        });
    }

    /////////////////////////////

    angular.module('Controllers').controller('FrontController', FrontController);
})();
