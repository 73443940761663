/* istanbul ignore file */
import React from 'react';

import { Suspense, SuspenseProps } from '@lumapps/utils/components/Suspense';

import { AdaptiveCardProps } from '../../types';
import { LoadingState } from './LoadingState';

const LazyAdaptiveCard = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'adaptive-card' */
            './AdaptiveCard'
        ),
);

/**
 * Lazy loaded Adaptive Card.
 * A loader will be shown while the componente is being downloaded.
 */
export const AdaptiveCard = ({
    loadingFallback,
    ...props
}: AdaptiveCardProps & { loadingFallback?: SuspenseProps['loadingFallback'] }) => (
    <Suspense loadingFallback={loadingFallback || <LoadingState />}>
        <LazyAdaptiveCard {...props} />
    </Suspense>
);
