import { UserProfile } from '@lumapps/user/types';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

/**
 * enum with all the available views for user profile
 */
export enum USER_PROFILE_VIEW {
    activity = 'activity',
    about = 'about',
}

export interface UserProfileNavigationItemsType {
    /** The label that will be shown in the navigation bar. */
    label: string;
    /** The view name to which the user will be redirected */
    view: USER_PROFILE_VIEW;
    /**
     * Conditions to fulfill to display the item.
     * All given conditions must be fulfilled for the item to be displayed
     */
    displayedWhen?: {
        hasSocial?: boolean;
    };
}

export interface GetUserProfileParams {
    /** whether we want to retrieve this information from the memory cache */
    getCacheFirst?: boolean;
    /** user's id */
    userId: string;
    /** Whether the cached value should be cleared before fetching. */
    clearCacheFirst?: boolean;
}

export interface GetOrgChartParams {
    /** whether we want to retrieve this information from the memory cache */
    getCacheFirst?: boolean;
    /** user's id */
    userId: string;
    /** Whether the cached value should be cleared before fetching. */
    clearCacheFirst?: boolean;
}

export interface OrganizationChartUserType
    extends Pick<
        UserProfile,
        'id' | 'profilePictureUrl' | 'email' | 'firstName' | 'lastName' | 'jobTitle' | 'departmentName' | 'locationName'
    > {
    reports?: {
        values: Array<OrganizationChartUserType>;
        more: boolean;
        cursor: string | null;
    };
}

export interface UserProfileRoutingData {
    user?: {
        id: string;
        fullName?: string;
    };
}

export interface UserProfilePermissions {
    isCommunityAdmin: boolean;
}

/** Enum for user profile specific statuses */
export enum UserProfileSpecificStatus {
    /**
     * Status set when saving the user profile, as we only need to reload the user fields
     * and not the whole profile.
     */
    reloadingUserFields = 'reloadingUserFields',
}

export type UserProfileStatuses = BaseLoadingStatus | UserProfileSpecificStatus;
export const UserProfileStatuses = { ...BaseLoadingStatus, ...UserProfileSpecificStatus };
