import { useEffect, useState } from 'react';

const detectMobileAppWebView = (): boolean => {
    const { userAgent } = navigator;

    return /LumApps-WebInMobile\/([\w.]+)(?:-debug)?\((\d+)\) (\w+)\/([A-z0-9.]+)(?:\((\d+)\))?/.test(userAgent);
};

export const useDetectMobileAppWebView = (): boolean => {
    const [isMobileAppWebView, setIsMobileAppWebView] = useState(false);

    useEffect(() => {
        const hasDetectedMobileAppWebView = detectMobileAppWebView();

        setIsMobileAppWebView(hasDetectedMobileAppWebView);
    }, []);

    return isMobileAppWebView;
};
