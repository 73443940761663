(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileHistoryController($scope, SocialActivity, SocialProfile, Features) {
        'ngInject';

        var vmHistory = this;
        // eslint-disable-next-line lumapps/file-format
        var vm = $scope.$parent.vm;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The default parameters to use on the calls to the API.
         *
         * @type {Object}
         */
        var _DEFAULT_PARAMS = {
            maxResults: 5,
            userId: SocialProfile.getCurrent().id,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The listkey to separate activities from history.
         *
         * @type {string}
         */
        vmHistory.listKey = 'social-profile-history';

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vmHistory.SocialActivity = SocialActivity;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Filter activity history.
         */
        function _filterActivityHistory() {
            var params = angular.copy(_DEFAULT_PARAMS);

            // Update params.
            SocialProfile.addGlobalParams(params);

            // Fetch subscriptions.
            SocialActivity.filterize(params, undefined, undefined, vmHistory.listKey);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Whenever we switch scope (going from platform activities to instance activities only).
         */
        $scope.$on('scope-toggle', _filterActivityHistory);

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            if (Features.hasFeature('social')) {
                _filterActivityHistory();
            }
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('SocialProfileHistoryController', SocialProfileHistoryController);
})();
