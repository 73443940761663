/**
 * Defines the values for each navigation type.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const NAVIGATION_TYPES_VALUES = {
    BULLETS: 'bullets',
    THUMBNAILS: 'thumbnails',
};

/**
 * Defines the values for each view mode.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const VIEW_MODES_VALUES = {
    GRID: 'grid',
    SLIDESHOW: 'slideshow',
};

/**
 * Defines the values for each view mode variant.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const VIEW_MODE_VARIANTS_VALUES = {
    GROUP: 'group',
    UNGROUP: 'ungroup',
};

export { NAVIGATION_TYPES_VALUES, VIEW_MODES_VALUES, VIEW_MODE_VARIANTS_VALUES };
