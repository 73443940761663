import React, { useEffect, useRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { CategoryList } from '@lumapps/learning-categories/components/CategoryList';
import { TrainingList } from '@lumapps/learning-trainings/components/TrainingList';
import { TrainingCoursesViewModeVariantType } from '@lumapps/learning-trainings/types';
import { SearchField } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { EmptyState } from '@lumapps/lumx-states/components/EmptyState';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { learningHatImage } from '@lumapps/lumx/custom-icons';
import { mdiChevronLeft } from '@lumapps/lumx/icons';
import {
    ColorPalette,
    ColorVariant,
    FlexBox,
    Link,
    Text,
    Typography,
    Orientation,
    Alignment,
} from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive/hooks/useResponsive';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import {
    isLoading,
    isEmpty,
    isEmptyFiltered,
    isError,
    BaseLoadingStatus,
} from '@lumapps/utils/types/BaseLoadingStatus';
import { WidgetContentStyles } from '@lumapps/widget-styles/types';

import { DEFAULT_WIDTH, SCOPE, WIDTH_IN_SYSTEM_PAGE } from '../../constants';
import './index.scss';
import { useCatalog } from '../../hooks/useCatalog';
import { CATALOG } from '../../keys';

export interface CatalogProps {
    categoryCode?: string;
    viewModeVariant?: TrainingCoursesViewModeVariantType;
    inSystemPage?: boolean;
    contentStyle?: WidgetContentStyles;
}

const CLASSNAME = 'learning-catalog';

/**
 * Learning Catalog widget, responsible to render the the training catalog.
 * @returns Catalog
 */
export const Catalog: React.FC<CatalogProps> = ({
    categoryCode,
    contentStyle,
    viewModeVariant,
    inSystemPage = false,
}) => {
    const {
        status,
        categories,
        trainings,
        breadcrumb,
        categoryCounter,
        trainingCounter,
        searchValue,
        searchTrainingsResults,
        fetchCatalog,
        handleSelectedCategory,
        handleReturnToParentCategory,
        handleSearch,
    } = useCatalog();

    const { get } = useDataAttributes(SCOPE);
    const categoryCodeRef = useRef(categoryCode);
    const { element } = useClassnames(CLASSNAME);
    const catalogRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useResponsive();

    useEffect(() => {
        if (status === BaseLoadingStatus.initial || categoryCodeRef.current !== categoryCode) {
            fetchCatalog(categoryCode);
            categoryCodeRef.current = categoryCode;
        }
    }, [fetchCatalog, status, categoryCode]);

    useEffect(() => {
        if (breadcrumb && isMobile) {
            catalogRef.current?.scrollIntoView();
        }
    }, [breadcrumb, isMobile]);

    const { pluralize, translateKey, translateAndReplace } = useTranslate();
    return (
        <FlexBox
            style={contentStyle}
            ref={catalogRef}
            gap="huge"
            orientation={Orientation.vertical}
            hAlign={Alignment.center}
        >
            <FlexBox
                className={element('search', {
                    ungrouped: viewModeVariant === 'ungrouped',
                })}
            >
                <SearchField
                    onSearch={handleSearch}
                    label={translateKey(CATALOG.SEARCH)}
                    debounced
                    value={searchValue}
                    textFieldProps={{ ...get({ element: 'input', action: 'search-catalog' }) }}
                />
            </FlexBox>

            {isError(status) && <ServiceNotAvailableState shouldShowIcon onRetry={() => fetchCatalog(categoryCode)} />}

            {isEmpty(status) && (
                <EmptyState
                    title={translateKey(CATALOG.NO_CATALOG)}
                    message={translateKey(CATALOG.ACCESS_TO_CATALOG)}
                    iconProps={{ icon: learningHatImage }}
                />
            )}

            {isEmptyFiltered(status) && <NoResultsState searchValue={searchValue} onClear={() => handleSearch('')} />}

            {!isError(status) &&
                !isEmpty(status) &&
                status !== BaseLoadingStatus.filtering &&
                !isEmptyFiltered(status) && (
                    <>
                        {breadcrumb && (
                            // Linter asks for this to be a button, we whant the breadcrumb to look like a Link not a button
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link
                                typography={Typography.subtitle1}
                                color={ColorPalette.primary}
                                colorVariant={ColorVariant.N}
                                onClick={handleReturnToParentCategory}
                                leftIcon={mdiChevronLeft}
                                {...get({ element: 'breadcrum', action: 'return-parent-category' })}
                            >
                                {breadcrumb}
                            </Link>
                        )}
                        {categoryCounter > 0 && (
                            <Text as="p" typography={Typography.overline}>
                                {translateAndReplace(pluralize(GLOBAL.SUB_CATEGORY, categoryCounter), {
                                    COUNT: categoryCounter,
                                })}
                            </Text>
                        )}
                        {(categories.length > 0 || isLoading(status)) && (
                            <CategoryList
                                isLoading={isLoading(status)}
                                categories={categories}
                                onSelectCategory={handleSelectedCategory}
                            />
                        )}
                        {trainingCounter > 0 && (
                            <Text as="p" typography={Typography.overline}>
                                {translateAndReplace(pluralize(GLOBAL.COURSE, trainingCounter), {
                                    COUNT: trainingCounter,
                                })}
                            </Text>
                        )}
                        {trainings.length > 0 && (
                            <TrainingList
                                minWidth={inSystemPage ? WIDTH_IN_SYSTEM_PAGE : DEFAULT_WIDTH}
                                scope={SCOPE}
                                viewModeVariant={viewModeVariant}
                                trainings={trainings}
                                isLoading={isLoading(status)}
                            />
                        )}
                    </>
                )}

            {status === BaseLoadingStatus.filtering && (
                <>
                    <Text as="p" typography={Typography.overline}>
                        {translateAndReplace(pluralize(GLOBAL.COURSE, trainingCounter), {
                            COUNT: searchTrainingsResults.length,
                        })}
                    </Text>
                    <TrainingList scope={SCOPE} viewModeVariant={viewModeVariant} trainings={searchTrainingsResults} />
                </>
            )}
        </FlexBox>
    );
};
