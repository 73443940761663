import { isHotKey } from '@lumapps/utils/browser/isHotKey';

import { Editor, Path, NodeMatch, Node, Element } from '..';
import { isSelectionCollapsed } from './isSelectionCollapsed';

/**
 * Check that we are leaving an inline element through left and right arrows.
 * @param editor The current editor
 * @param match The function used to specify which type of node we are targetting. By default check if element is an inline element.
 * @returns Whether we are leaving the matching type of element
 */
export const isLeavingInline =
    <T extends Node>(editor: Editor, match?: NodeMatch<T>) =>
    (event: KeyboardEvent) => {
        if (!editor.selection || !isSelectionCollapsed(editor)) {
            return false;
        }

        const left = isHotKey('ArrowLeft')(event);
        const right = isHotKey('ArrowRight')(event);

        if (!left && !right) {
            return false;
        }

        const isReverse = left;

        const targetPoint = isReverse
            ? Editor.before(editor, editor.selection.anchor, {
                  unit: 'offset',
                  distance: 1,
              })
            : Editor.after(editor, editor.selection.anchor, {
                  unit: 'offset',
                  distance: 1,
              });

        if (!targetPoint) {
            return false;
        }

        // The node we are in before the move
        const [currentNode] = Array.from(
            Editor.nodes(editor, {
                at: editor.selection,
                match: (node, path) =>
                    match ? match(node, path) : Element.isElement(node) && Editor.isInline(editor, node),
            }),
        );

        // The node we will be in after the move
        const [targetElement] = Array.from(
            Editor.nodes(editor, {
                at: targetPoint,
                match: (node, path) =>
                    match ? match(node, path) : Element.isElement(node) && Editor.isInline(editor, node),
            }),
        );

        const isTargetElementMatching = Boolean(targetElement);

        const isCurrentElementMatching = Boolean(currentNode);

        const isNavigatingThroughSameElement =
            isCurrentElementMatching && isTargetElementMatching && Path.compare(currentNode[1], targetElement[1]) === 0;

        return isCurrentElementMatching && !isNavigatingThroughSameElement;
    };
