/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';

const saApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy' });

const getUser = () => saApi.getCacheFirst('users/me', undefined, PRIORITY.LOW);

const getSocialNetworkAccesses = (useCache = true) => {
    if (!useCache) {
        return saApi.get('users/me/social-network-accesses');
    }

    return saApi.getCacheFirst('users/me/social-network-accesses', undefined, PRIORITY.LOW);
};

const deleteSocialNetworkAccess = (id: string) => saApi.delete(`users/me/social-network-accesses/${id}`);

export { saApi, getUser, getSocialNetworkAccesses, deleteSocialNetworkAccess };
