import { usePendo } from '@lumapps/metric/hooks/usePendo';
import { mapStateToProps } from '@lumapps/metric/components/Metrics';
import { connect } from '@lumapps/redux/react';

/**
 * This is a wrapper component in order to use the usePendo hook
 * in an Angular JS context. This component will be used with the react-element-directive
 * and it will allow us to remove the legacy code for pendo and just reuse the new architecture code.
 */
const Pendo = (props) => {
    usePendo(props);

    return null;
};

const ConnectedPendo = connect(mapStateToProps)(Pendo);

export { ConnectedPendo as Pendo };
