import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../types';
import * as tabTypes from '../../ui/tabs/types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { withOnChangeProperty } from '../../../utils';
import { Tab, Tabs } from '../../ui';
import { FontSettings } from './FontSettings';
import { IconSettings } from './IconSettings';

/**
 * Renders a form that enables editing label settings.
 */
export class LabelSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.areaStyle.isRequired,
        name: types.properties,
        /** Called when the current state style changes with `(state)`. */
        onChangeState: propTypes.func,
        /** Current state style. */
        state: propTypes.string,
        /** Style states. */
        states: tabTypes.items,
        /** Current area style. */
        value: styleTypes.areaStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { value = {}, defaults, state, states, onChangeState } = this.props;

        return (
            <Tabs state={state} states={states} onChangeState={onChangeState}>
                <Tab id="label" label={t('STYLE.LABEL')}>
                    <FontSettings
                        value={value.label}
                        name="label"
                        onChange={this.onChangeProperty}
                        defaults={defaults.label}
                    />
                </Tab>
                <Tab id="icon" label={t('STYLE.ICON')}>
                    <IconSettings
                        value={value.icon}
                        name="icon"
                        onChange={this.onChangeProperty}
                        defaults={defaults.icon}
                    />
                </Tab>
            </Tabs>
        );
    }
}
