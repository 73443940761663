(function IIFE() {
    'use strict';

    /////////////////////////////

    /* @ngInject */
    function WidgetWeatherController($scope) {
        var vm = this;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Refresh weather after current language update.
         *
         * @param {Event}  evt        The original event triggering this method.
         * @param {string} widgetUuid The widget Uuid.
         */
        $scope.$on('inputLanguage', function updateWidgetLang(evt, inputLang) {
            var properties = _.get(vm.widget, 'properties', {});

            properties.inputLang = inputLang.lang;
        });

        /////////////////////////////

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    /**
     * Widget weather.
     * Display the weather for a specific place or a place from the user directory.
     * From 1 to 7 days. In degree Celsius or Fahrenheit.
     *
     * @param {Object} widget The widget configuration object.
     */

    function widgetWeatherDirective() {
        'ngInject';

        function widgetWeatherLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetWeatherController,
            controllerAs: 'vm',
            link: widgetWeatherLink,
            replace: true,
            require: ['widgetWeather', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-weather/views/widget-weather.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetWeather', widgetWeatherDirective);
})();
