export enum PLAY_VIDEO {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194287967
     */
    RESTRICTED_VIDEO = 'PLAY.RESTRICTED_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=316172665
     */
    VIDEO_DETAILS_TABS = 'PLAY.VIDEO_DETAILS_TABS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119071587
     */
    VIDEO_ENCODING = 'PLAY.VIDEO_ENCODING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=352249146
     */
    VIDEO_NO_DOWNLOADABLE = 'PLAY.VIDEO_NO_DOWNLOADABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=319277204
     */
    VIDEO_TRANSCRIPT = 'PLAY.VIDEO_TRANSCRIPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389310
     */
    VIDEO_UPLOAD_PROGRESS_UPLOADED = 'PLAY.VIDEO_UPLOAD_PROGRESS_UPLOADED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389289
     */
    VIDEO_UPLOAD_PROGRESS_UPLOADING = 'PLAY.VIDEO_UPLOAD_PROGRESS_UPLOADING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205873891
     */
    VIDEO_UPLOAD_PROGRESS_WARNING = 'PLAY.VIDEO_UPLOAD_PROGRESS_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194287993 */
    VIDEO_NO_ACCESS = 'PLAY.VIDEO_VISIBILITY_RESTRICTED',
}
