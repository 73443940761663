import { TranslationAPI } from '@lumapps/translations';

import { STREAM_ACTION_TO_CONTEXT_BANNER_KEY } from '../constants';
import { BlockStreamItemByResource, ManagedStreamResources, StreamActions } from '../types';

interface GetContextBannerTranslation {
    resourceType: ManagedStreamResources;
    action: StreamActions;
    userComponent: JSX.Element;
    content?: BlockStreamItemByResource<'content'>['content'];
    translateAndReplaceWithComponents: TranslationAPI['translateAndReplaceWithComponents'];
    translateObject: TranslationAPI['translateObject'];
}

/** Get the correct translation for the given stream context banner */
export const getContextBannerTranslation = ({
    resourceType,
    action,
    userComponent,
    content,
    translateAndReplaceWithComponents,
}: GetContextBannerTranslation) => {
    // First check if we have a translation key for the given resource
    const translationKey = STREAM_ACTION_TO_CONTEXT_BANNER_KEY[resourceType]?.[action];

    if (!translationKey) {
        return '';
    }

    const replacements: Record<string, JSX.Element | string> = {
        user: userComponent,
    };

    if (content) {
        replacements.content = content.contentType?.name.value || '';
    }

    return translateAndReplaceWithComponents(translationKey, replacements);
};
