import React from 'react';

import { FormActionsProps } from '@lumapps/lumx-forms/components/FormActions';
import { UserMicrosoftToken } from '@lumapps/user/interface';

import { OneDriveDocument, OneDrivePickerConfiguration } from '../../types';
import { OneDriveFilePicker } from './OneDriveFilePicker';
import { OneDriveFolderPicker } from './OneDriveFolderPicker';

export interface OneDrivePickerProps {
    /** User MS token */
    token?: UserMicrosoftToken | Promise<UserMicrosoftToken | undefined>;
    /** OneDrivePicker configuration object */
    pickerConfig: OneDrivePickerConfiguration;
    /** whether the picker is open or not */
    isOpen: boolean;
    /** props to pass to the form dialog */
    formActionProps?: Partial<FormActionsProps>;
    /** callback on close */
    onClose(): void;
    /** callback on selection */
    onSelect(files: OneDriveDocument): void;
}

/**
 * The OneDrivePicker will let the user pick a document based on a Shared URL from OneDrive.
 * Only available for user using a microsoft provider.
 * Document is retrieved using the microsoft graph APIs to test the shared URL.
 * */
export const OneDrivePicker: React.FC<OneDrivePickerProps> = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    const isFileOnlyPicker = props.pickerConfig.type === 'file';

    return isFileOnlyPicker ? <OneDriveFilePicker {...props} /> : <OneDriveFolderPicker {...props} />;
};
