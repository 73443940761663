import { PERMISSIONS, ROLES, SA_FEATURE_NAME } from 'components/components/social-advocacy/constants';

import {
    getUser,
    isSAEnabled,
    shouldGetUser,
    isAdmin,
    isAmbassador,
    isProgramManager,
    canAccessAllSharesData,
    canAccessSocialAdvocacyBasicFeatures,
    canManageShareableContents,
    canManageSocialAdvocacyAmbassadors,
    canManageSocialAdvocacyProgramManagers,
    canManageSocialAdvocacyTopics,
    canManagePlatformSocialNetworks,
    canShareShareableContents,
    canManagePlaftormPrograms,
} from '@lumapps/sa-connected-user/ducks';

import { getAllSocialNetworks } from '@lumapps/sa-social-networks/ducks/actions';

function SocialAdvocacyService($q, LumsitesBaseService, SocialAdvocacyFactory, ReduxStore) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(SocialAdvocacyFactory);

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    service.SA_FEATURE_NAME = SA_FEATURE_NAME;
    service.ROLES = ROLES;
    service.PERMISSIONS = PERMISSIONS;
    service.connectedUserDeferred = $q.defer();

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    const injectState = (func) => () => func(ReduxStore.store.getState());

    service.isEnabled = injectState(isSAEnabled);
    service.shouldGetUser = injectState(shouldGetUser);
    service.isAdmin = injectState(isAdmin);
    service.isAmbassador = injectState(isAmbassador);
    service.isProgramManager = injectState(isProgramManager);
    service.canAccessAllSharesData = injectState(canAccessAllSharesData);
    service.canAccessSocialAdvocacyBasicFeatures = injectState(canAccessSocialAdvocacyBasicFeatures);
    service.canManageShareableContents = injectState(canManageShareableContents);
    service.canManageSocialAdvocacyAmbassadors = injectState(canManageSocialAdvocacyAmbassadors);
    service.canManageSocialAdvocacyProgramManagers = injectState(canManageSocialAdvocacyProgramManagers);
    service.canManageSocialAdvocacyTopics = injectState(canManageSocialAdvocacyTopics);
    service.canManagePlatformSocialNetworks = injectState(canManagePlatformSocialNetworks);
    service.canShareShareableContents = injectState(canShareShareableContents);
    service.canManagePlaftormPrograms = injectState(canManagePlaftormPrograms);

    /////////////////////////////

    /**
     * Initialize the controller.
     */

    service.init = async function init() {
        if (service.isEnabled() && service.shouldGetUser()) {
            try {
                const user = await ReduxStore.store.dispatch(getUser());
                if (
                    user &&
                    user.permissions &&
                    user.permissions.includes(PERMISSIONS.CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES)
                ) {
                    ReduxStore.store.dispatch(getAllSocialNetworks());
                }
            } catch (exception) {
                // Ignored.
            }
        }
        service.connectedUserDeferred.resolve();
    };

    /////////////////////////////

    return service;
}

angular.module('Services').service('SocialAdvocacy', SocialAdvocacyService);

/////////////////////////////

export { SocialAdvocacyService };
