import get from 'lodash/get';

import { list } from '../../document/api/documentApi';
import { fetchCommunityMemberList, manageFollowing } from '../api/communityApi';
import { fetchGroupApplications } from '../api/groupsApi';
import { fetchGroup } from '../api/workspaceApi';

const fetchMembersTask = ({ page, maxResults, uid }) => {
    return fetchCommunityMemberList({ page, maxResults, uid }).then(
        (response) => ({
            payload: response.data,
        }),
        (err) => ({
            payload: {
                errorCode: get(err.response, 'data.error.code'),
                errorMessage: get(err.response, 'data.error.message'),
            },
        }),
    );
};

/**
 * Fetch apps of a group.
 *
 * @param  {string}  groupId The Id of the group.
 * @return {Promise} The request promise.
 */
const fetchAppsTask = ({ groupId }) => {
    return fetchGroupApplications(groupId).then(
        (response) => ({
            payload: response.data,
        }),
        (err) => ({
            payload: {
                errorCode: get(err.response, 'data.error.code'),
                errorMessage: get(err.response, 'data.error.message'),
            },
        }),
    );
};

/**
 * Set the follow of a community.
 *
 * @param  {boolean} notify Sets true to follow, false to unfollow.
 * @param  {string}  uid    Community UID.
 * @return {Promise} The request promise.
 */
const manageFollowingTask = ({ notify, uid }) => {
    return manageFollowing({ notify, uid }).then(
        (response) => ({
            payload: response.data,
        }),
        (err) => ({
            payload: {
                errorCode: get(err.response, 'data.error.code'),
                errorMessage: get(err.response, 'data.error.message'),
            },
        }),
    );
};

const fetchFilesTask = ({ docPath }) => {
    const params = {
        docPath,
        maxResult: 8,
    };

    return list(params).then(
        (response) => ({
            payload: response.data,
        }),
        (err) => ({
            payload: {
                errorCode: get(err.response, 'data.error.code'),
                errorMessage: get(err.response, 'data.error.message'),
            },
        }),
    );
};

/**
 * Fetch the community group from a workspace.
 *
 * @param  {string}  workspaceId The Id of the workspace.
 * @return {Promise} The request promise.
 */
const fetchGroupTask = ({ workspaceId }) => {
    return fetchGroup(workspaceId).then(
        (response) => ({
            payload: response.data,
        }),
        (err) => ({
            payload: {
                errorCode: get(err.response, 'data.error.code'),
                errorMessage: get(err.response, 'data.error.message'),
            },
        }),
    );
};

export { fetchAppsTask, fetchMembersTask, fetchFilesTask, manageFollowingTask, fetchGroupTask };
