import set from 'lodash/set';

import { Training } from '@lumapps/learning-trainings/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { CatalogData, CatalogSearch, CategoryData } from '../types';

export interface CatalogState {
    breadcrumb: string;
    catalog: CatalogData;
    categories: CategoryData[];
    trainings: Training[];
    categoryCounter: number;
    trainingCounter: number;
    parentCategoryStack: CategoryData[];
    searchValue: string;
    searchTrainingsResults: Training[];
}

export const initialState: CatalogState = {
    breadcrumb: '',
    catalog: {},
    categories: [],
    trainings: [],
    categoryCounter: 0,
    trainingCounter: 0,
    parentCategoryStack: [],
    searchValue: '',
    searchTrainingsResults: [],
};

export const reducers = {
    setInitialCatalog: (state: CatalogState, action: PayloadAction<CatalogData>) => {
        set(state, 'catalog', action.payload);
        set(state, 'categories', action.payload.categories || []);
        set(state, 'trainings', action.payload.trainings || []);
        set(state, 'breadcrumb', '');
        set(state, 'categoryCounter', 0);
        set(state, 'trainingCounter', 0);
        set(state, 'parentCategoryStack', []);
        set(state, 'searchValue', '');
        set(state, 'searchTrainingsResults', []);
    },
    enterCategory: (state: CatalogState, action: PayloadAction<CategoryData>) => {
        set(state, 'categories', action.payload.children);
        set(state, 'trainings', action.payload.trainings);
        set(state, 'breadcrumb', action.payload.title);
        set(state, 'categoryCounter', (action.payload.children || []).length);
        set(state, 'trainingCounter', (action.payload.trainings || []).length);
    },
    popParentCategoryBreadcrumb: (state: CatalogState) => {
        const updatedParentCategoryStack = [...state.parentCategoryStack];
        updatedParentCategoryStack.pop();
        set(state, 'parentCategoryStack', updatedParentCategoryStack);
    },
    pushParentCategoryBreadcrumb: (state: CatalogState, action: PayloadAction<CategoryData>) => {
        set(state, 'parentCategoryStack', [...state.parentCategoryStack, action.payload]);
    },
    doSearch: (state: CatalogState, action: PayloadAction<CatalogSearch>) => {
        set(state, 'searchValue', action.payload.query);
        set(state, 'searchTrainingsResults', action.payload.results);
        set(state, 'trainingCounter', (action.payload.results || []).length);
    },
    resetSearch: (state: CatalogState) => {
        set(state, 'searchValue', '');
        set(state, 'searchTrainingsResults', []);
        set(state, 'trainingCounter', (state.trainings || []).length);
    },
};

export const { actions, reducer: catalogReducer } = createSlice({
    domain: 'catalog',
    initialState,
    reducers,
});
