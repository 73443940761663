import BaseApi from '@lumapps/base-api';

const TaskApi = new BaseApi({ path: 'task' });
const WorkSpaceApi = new BaseApi({ path: 'workspace' });

/**
 * Get planner buckets associated to a specific plan.
 *
 * @param  {string}  folderId The plan ID.
 * @return {Promise} The API Call promise.
 */
const getPlannerBucketBySpecificPlans = (folderId) => {
    return TaskApi.post('/list/categories', {
        folderId,
        provider: 'planner',
    });
};

/**
 * Patch a planner task.
 *
 * @param  {string}  taskId        The task ID.
 * @param  {string}  etag          The task etag.
 * @param  {Object}  patchedValues The task fields updated.
 * @return {Promise} The API Call promise.
 */
const patchPlannerTask = (taskId, etag, patchedValues) => {
    return TaskApi.patch('/update', {
        etag,
        properties: patchedValues,
        provider: 'planner',
        taskId,
    });
};

/**
 * Get the planner plans for a specific community.
 *
 * @param  {string}  groupId The community ID.
 * @return {Promise} The API Call promise.
 */
const getPlannerTeamPlans = (groupId) => {
    return TaskApi.post('/list/groupFolders', {
        groupId,
        provider: 'planner',
    });
};

/**
 * Get the planner tasks associated to a specific plan.
 *
 * @param  {string}  planId The plan ID.
 * @return {Promise} The API Call promise.
 */
const getPlannerPlanTasks = (planId) => {
    return TaskApi.post('list', {
        folderId: planId,
        provider: 'planner',
    });
};

/**
 * Get the planner plans for the current authentified user.
 *
 * @return {Promise} The API Call promise.
 */
const getPlannerUserPlans = () => {
    return TaskApi.post('/list/userFolders', {
        provider: 'planner',
    });
};

/**
 * Get the user's tasks of a specific provider.
 *
 * @param  {string}  provider The provider tag.
 * @return {Promise} The API Call promise.
 */
const getUserTasks = (provider) => {
    return TaskApi.post('list/userTasks', {
        provider,
    });
};

/**
 * Patch an outlook task.
 *
 * @param  {string}  taskId        The task ID.
 * @param  {string}  etag          The task etag.
 * @param  {Object}  patchedValues The task fields updated.
 * @return {Promise} The API Call promise.
 */
const patchOutlookTask = (taskId, etag, patchedValues) => {
    return TaskApi.patch('/update', {
        etag,
        properties: patchedValues,
        provider: 'outlook',
        taskId,
    });
};

/**
 * Get the user's tasks associated to a specific folder ID.
 *
 * @param  {string}  folderId The folder ID.
 * @return {Promise} The API Call promise.
 */
const getOutlookUserTasksBySpecificFolder = (folderId) => {
    return TaskApi.post('/list', {
        folderId,
        provider: 'outlook',
    });
};

/**
 * Get the workspace informations.
 *
 * @param  {string}  workspaceId The workspace ID.
 * @return {Promise} The API Call promise.
 */
const getWorkspace = (workspaceId) => {
    return WorkSpaceApi.get('/get', {
        params: {
            workspaceId,
        },
    });
};

export {
    getPlannerBucketBySpecificPlans,
    getPlannerTeamPlans,
    getPlannerPlanTasks,
    getPlannerUserPlans,
    getUserTasks,
    getOutlookUserTasksBySpecificFolder,
    getWorkspace,
    patchPlannerTask,
    patchOutlookTask,
};
