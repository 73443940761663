import React from 'react';

/**
 * Gets React children and a mapping method
 * and returns a flatten version of mapped chidren
 */
export const flatMapChildrenToMapper = (
    children: React.ReactNode,
    mapMethod: (child: React.ReactNode) => any,
): any[] => {
    return React.Children.toArray(children).flatMap(mapMethod);
};
