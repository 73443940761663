import produce from 'immer';

import {
    MULTI_COMMUNITY_SHARE_SUCCESS,
    MULTI_COMMUNITY_SHARE_ERROR,
    MULTI_COMMUNITY_UNSHARE_SUCCESS,
    MULTI_COMMUNITY_UNSHARE_ERROR,
    MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD
} from './post_actions';

// Default state.
const defaultState = { 
    multiSharePayload : null
};

const posts = (state = defaultState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD:
                    draft.multiSharePayload = null;
            break;
            case MULTI_COMMUNITY_SHARE_SUCCESS:
                    draft.multiSharePayload = action.multiSharePayload;
                break;
            case MULTI_COMMUNITY_SHARE_ERROR:
                    draft.multiSharePayload = {error:true};
                break;
            case MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD:
                    draft.multiSharePayload = null;
            break;
            case MULTI_COMMUNITY_UNSHARE_SUCCESS:
                    draft.multiSharePayload = action.multiSharePayload;
                break;
            case MULTI_COMMUNITY_UNSHARE_ERROR:
                    draft.multiSharePayload = {error:true};
                break;
            default:
                break;
            
        }

        return draft;
    });

export { posts };
