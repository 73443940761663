import React from 'react';

import { addBlob } from '@lumapps/lumx-images/components/BlobThumbnail/store';
import { EditableImage, EditableImageProps } from '@lumapps/lumx-images/components/EditableImage';
import { Image } from '@lumapps/lumx-images/types';
import { ThumbnailProps } from '@lumapps/lumx/react';
import { UseFilePickerProps } from '@lumapps/utils/hooks/useFilePicker';

import { UploadDocumentOptions } from '../../api';
import { useUploadDocument, UseUploadDocumentProps } from '../../hooks/useUploadDocument';

export interface UploadDocumentProps extends Partial<EditableImageProps> {
    className?: string;
    /** options to be passed in into the document api */
    uploadDocumentOptions?: Omit<UploadDocumentOptions, 'file'>;
    /** component that will be displayed before the uploader */
    before?: React.ReactNode;
    /** component that will be displayed after the uploader */
    after?: React.ReactNode;
    /** props to be passed in to the thumbnail displayed */
    thumbnailProps?: Partial<ThumbnailProps>;
    /** callback to be executed once the document has been uploaded */
    onDocumentUploaded?: UseUploadDocumentProps['onDocumentUploaded'];
    /** callback to be executed once the document is selected for upload */
    onDocumentSelected?: EditableImageProps['onImageSelected'];
    /** options for the file picker */
    filePickerOptions?: Partial<UseFilePickerProps>;
    /** already selected document */
    selectedDocument?: UseUploadDocumentProps['selectedDocument'];
    /**
     * whether blob local storage should be used or not. This means that the uploaded
     * document will be saved in local storage as a URL
     */
    useBlobLocalStorage?: boolean;
}

/**
 * Displays a component that upload a file to the document storage
 *
 * @family Files
 * @param UploadDocumentProps
 * @returns UploadDocument
 */
export const UploadDocument: React.FC<UploadDocumentProps> = ({
    uploadDocumentOptions,
    onDocumentUploaded,
    onDocumentSelected,
    selectedDocument,
    useBlobLocalStorage = false,
    ...props
}) => {
    const { state, isDocumentLoading, onSelectDocument, onDelete } = useUploadDocument({
        uploadDocumentOptions,
        onDocumentUploaded: (document?: Image) => {
            if (onDocumentUploaded) {
                onDocumentUploaded(document);
            }

            if (document && useBlobLocalStorage) {
                addBlob(document);
            }
        },
        selectedDocument,
    });

    const { document } = state;

    return (
        <EditableImage
            {...props}
            isImageLoading={isDocumentLoading}
            image={document}
            onDelete={onDelete}
            onImageSelected={(image) => {
                if (image) {
                    onSelectDocument(image);

                    if (onDocumentSelected) {
                        onDocumentSelected(image);
                    }
                }
            }}
        />
    );
};
