import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import first from 'lodash/first';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import set from 'lodash/set';
import some from 'lodash/some';

import { MediaConstant as MediaConstants } from 'front/modules/media/js/media_constant';

import { FOLDER_TYPES } from '../../modules/widget-file-list/js/widget-file-list_constant';

/**
 * Defines restricted categories for each providers.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const _RESTRICTED_CATEGORIES = {
    [MediaConstants.PROVIDERS.lumapps]: [MediaConstants.CATEGORIES.mine, MediaConstants.CATEGORIES.library],
    [MediaConstants.PROVIDERS.google]: [MediaConstants.CATEGORIES.mine],
    [MediaConstants.PROVIDERS.microsoft]: [MediaConstants.CATEGORIES.mine],
    [MediaConstants.PROVIDERS.community]: [MediaConstants.CATEGORIES.library],
};

/////////////////////////////

function WidgetSettingsFileSelectionController(
    $injector,
    $q,
    $rootScope,
    $scope,
    $timeout,
    AbstractPicker,
    Config,
    Content,
    Document,
    DrivePicker,
    Features,
    InitialSettings,
    MediaConstant,
    Tag,
    Translation,
    Utils,
    Widget,
    WidgetFileListConstant,
    WidgetSettingsConstant,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Name document provider service to use.
     * Default is 'DocumentProvider', but may be override for eg. 'CommunityDocumentProvider'.
     *
     * @type {string}
     */
    let _documentProviderService = 'DocumentProvider';

    /**
     * Used document provider service.
     */
    let _documentProvider;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The identifier of the file picker.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.FILE_PICKER_ID = 'widget-file-selection-picker-';

    /**
     * All file types specific properties.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.FILE_TYPES_CONFIG = {
        file: {
            icon: 'file',
            pluralKey: 'FRONT.FILE_TYPES.FILES',
            singularKey: 'FRONT.FILE_TYPES.FILE',
        },
        image: {
            allowedFileTypes: MediaConstant.DOCUMENT_TYPES.imagesAndFolders,
            icon: 'image',
            pluralKey: 'FRONT.FILE_TYPES.IMAGES',
            singularKey: 'FRONT.FILE_TYPES.IMAGE',
        },
    };

    /**
     * Folder types constants.
     * @constant
     * @readonly
     */
    vm.FOLDER_TYPES = FOLDER_TYPES;

    /**
     * The options for the Froala editor in th edition of files overrides.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.FROALA_OPTIONS = {};

    /**
     * The list of files that are picked in the widget.
     *
     * @type {Array}
     */
    vm.files = [];

    /**
     * Contains some indicators about the status of the controller.
     *
     * @type {Object}
     */
    vm.is = {
        editing: undefined,
        loading: {
            files: false,
            providers: true,
        },
    };

    /**
     * Indicates if the widget is in a community context.
     *
     * @type {boolean}
     */
    vm.isCommunityContext = false;

    /**
     * The parameters to set to the media picker.
     *
     * @type {Object}
     */
    vm.mediaPickerParams = {};

    /**
     * The list of pickable special folder for the selected provider.
     *
     * @type {Array}
     */
    vm.pickableFolders = [];

    /**
     * Indicates if we want to show the file picker dialog.
     *
     * @type {boolean}
     */
    vm.showMediaPickerDialog = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Document = Document;
    vm.DrivePicker = DrivePicker;
    vm.MediaConstant = MediaConstant;
    vm.Tag = Tag;
    vm.Translation = Translation;
    vm.Widget = Widget;
    vm.WidgetFileListConstant = WidgetFileListConstant;
    vm.WidgetSettingsConstant = WidgetSettingsConstant;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget properties.
     *
     * @return {Object} Widget properties.
     */
    function _getWidgetProperties() {
        const currentWidget = Widget.getCurrent();
        currentWidget.properties = currentWidget.properties || {};

        return currentWidget.properties;
    }

    /**
     * Add a media docPath to widget.
     *
     * @param {string} docPath Document path.
     */
    function _addFileDocPathToWidget(docPath) {
        const properties = _getWidgetProperties();

        if (angular.isUndefinedOrEmpty(properties.files)) {
            properties.files = [];
        }

        // Only add newly selected entries.
        if (!includes(properties.files, docPath)) {
            properties.files.push(docPath);
        }
    }

    /**
     * Add media override to widget.
     *
     * @param {Object} override     Media override.
     * @param {Object} mediaContent Media content.
     */
    function _addMediaOverrideToWidget(override, mediaContent) {
        const properties = _getWidgetProperties();

        if (
            angular.isDefinedAndFilled(override) &&
            some([override.name, override.description, override.thumbnail], Translation.hasTranslations)
        ) {
            if (angular.isUndefined(properties.overrides)) {
                properties.overrides = {};
            }

            properties.overrides[mediaContent.docPath] = override;
        }
    }

    /**
     * Add a selected file to widget.
     *
     * @param {Object} modelObject File to add.
     */
    function _addFileToWidget(modelObject) {
        const mediaContent = Document.getMediaContentByLang(modelObject.object, true);
        const { docPath, override } = modelObject.object;

        _addFileDocPathToWidget(docPath);
        _addMediaOverrideToWidget(override, mediaContent);
    }

    /**
     * Add selected medias to widget.
     */
    function _addSelectedFilesToWidget() {
        _getWidgetProperties().files = [];
        forEach(vm.files, _addFileToWidget);
    }

    /**
     * Clean widget overrides by removing old files.
     */
    function _cleanWidgetOverrides() {
        const properties = _getWidgetProperties();

        forEach(properties.overrides, (override, docPath) => {
            const fileInProperties = properties.files.some((file) => file.indexOf(docPath) > -1);
            if (!fileInProperties) {
                delete properties.overrides[docPath];
            }
        });

        // No more overrides ? Remove attribute then.
        if (angular.isUndefinedOrEmpty(properties.overrides)) {
            delete properties.overrides;
        }
    }

    /**
     * Get current category.
     *
     * @return {Promise} Provider Category promise.
     */
    function _getCurrentCategory() {
        const { categoryId, providerId } = _getWidgetProperties().folder;

        return _documentProvider.getProviderCategory(providerId, categoryId);
    }

    /**
     * Get current provider id.
     *
     * @return {string} Current provider id.
     */
    function _getCurrentProviderId() {
        return get(_getWidgetProperties().folder, 'providerId');
    }

    /**
     * Get default document provider id.
     *
     * @return {string} Default document provider id.
     */
    function _getDefaultProviderId() {
        return 'local';
    }

    /**
     * Init widget folder doc path and parameters.
     *
     * @return {Promise} Init promise.
     */
    async function _initWidgetFolderDocPathAndParams() {
        const widgetProperties = _getWidgetProperties();
        const category = await _getCurrentCategory();

        if (!widgetProperties.folder.categoryId && angular.isDefinedAndFilled(category)) {
            widgetProperties.folder.categoryId = category.id;
        }

        if (!widgetProperties.folder.docPath && angular.isDefinedAndFilled(category)) {
            widgetProperties.folder.docPath = category.rootPath;
        }
    }

    /**
     * Init the override property if we have one.
     *
     * @param {Object} properties The widget properties.
     */
    function _initOverrides(properties) {
        angular.forEach(vm.files, (file) => {
            const mediaContent = Document.getMediaContentByLang(file, true);

            if (angular.isUndefinedOrEmpty(properties.overrides[mediaContent.fileId])) {
                return;
            }

            file.override = properties.overrides[mediaContent.fileId];
        });
    }

    /**
     * Init pickable folders list from provider.
     *
     * @param  {string}  providerId Provider id to get folders from.
     * @return {Promise} Request promise.
     */
    function _initPickableFolders(providerId) {
        return _documentProvider.getProviderCategories(providerId).then((categories) => {
            let pickableFolders = filter(categories, { canPick: true });

            pickableFolders = filter(pickableFolders, (folder) =>
                includes(get(_RESTRICTED_CATEGORIES, providerId, []), folder.id),
            );

            vm.pickableFolders = pickableFolders;
        });
    }

    /**
     * Remove old selection mode model properties.
     *
     * @param {string} newSelectionMode New selection mode.
     */
    function _removeOldProperties(newSelectionMode) {
        const properties = _getWidgetProperties();

        if (angular.isUndefinedOrEmpty(properties)) {
            return;
        }

        const list = {
            [WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER]: ['folder'],
            [WidgetSettingsConstant.SELECTION_TYPES.LIST]: ['files'],
            [WidgetSettingsConstant.SELECTION_TYPES.PICK]: ['folder', 'overrides'],
        };

        forEach(list[newSelectionMode], (attribute) => {
            delete properties[attribute];
        });
    }

    /**
     * Set widget provider id.
     *
     * @param {string} providerId Provider id.
     */
    function _setProviderId(providerId) {
        const properties = _getWidgetProperties();

        properties.folder = { providerId };
    }

    /**
     * Set widget selected folder.
     *
     * @param {string} categoryId Category id.
     * @param {string} folderType Folder type (CATEGORY or CUSTOM).
     * @param {Object} folder     Folder to set.
     * @param {string} providerId Provider id.
     */
    function _setWidgetFolder({ categoryId, folderType, object: folder, providerId }) {
        if (angular.isUndefinedOrEmpty(folder)) {
            return;
        }

        const properties = _getWidgetProperties();

        properties.folder = {
            categoryId,
            docPath: folder.docPath,
            folderType,
            providerId,
        };
    }

    /**
     * Update widget with selected medias ("pick" mode).
     */
    function _updateWidgetWithSelectedFiles() {
        _addSelectedFilesToWidget();
        _cleanWidgetOverrides();
    }

    /**
     * Update widget with selected folder ("list" mode).
     */
    function _updateWidgetWithSelectedFolder() {
        const properties = _getWidgetProperties();

        // Remove override property from a previous "pick" mode.
        delete properties.override;

        // We want only one.
        if (angular.isArray(vm.files)) {
            vm.files = first(vm.files);
        }

        _setWidgetFolder({
            ...vm.files,
            folderType: FOLDER_TYPES.CUSTOM,
        });
    }

    /**
     * Update Widget properties and refresh widget.
     *
     * @return {Promise} Refresh promise.
     */
    async function _updateWidgetProperties() {
        const { displayType } = _getWidgetProperties();

        displayType === WidgetSettingsConstant.SELECTION_TYPES.PICK
            ? _updateWidgetWithSelectedFiles()
            : _updateWidgetWithSelectedFolder();

        // eslint-disable-next-line no-use-before-define
        await vm.updateWidget(true, false);
    }

    /**
     * Update the media picker as it has different parameters depending on the selection type, source type etc...
     */
    function _updateMediaPicker() {
        const properties = _getWidgetProperties();

        const isManualSelection = properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.PICK;

        const allowedTypes = isManualSelection
            ? get(vm.FILE_TYPES_CONFIG, [vm.fileTypes, 'allowedFileTypes'])
            : undefined;

        vm.mediaPickerParams = {
            allowFolderPick: !isManualSelection,
            allowedProviders: isManualSelection ? map(vm.providerList, 'id') : get(properties.folder, 'providerId'),
            allowedTypes,
            multiple: isManualSelection,
            showFoldersOnly: !isManualSelection,
        };
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Change the mode of the widget.
     *
     * @param {string} selectionMode The selection mode.
     *                               Possible values are: 'pick', 'list' or 'community-folder'.
     */
    async function changeMode(selectionMode) {
        vm.is.selected = undefined;

        _removeOldProperties(selectionMode);

        if (selectionMode === WidgetSettingsConstant.SELECTION_TYPES.PICK) {
            await vm.updateWidget(true, true);
        } else if (selectionMode === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER) {
            const properties = _getWidgetProperties();
            const { docPath, source: providerId } = Content.getCurrent().driveFolder;

            properties.folder = {
                categoryId: 'TEAM_DRIVE',
                docPath,
                folderType: FOLDER_TYPES.CUSTOM,
                providerId,
            };

            await vm.updateWidget(true, true);
        } else {
            await vm.toggleProvider();
        }
    }

    /**
     * Clear currently selected folders of a given type.
     */
    async function clearFolder() {
        const properties = _getWidgetProperties();

        if (angular.isUndefinedOrEmpty(properties)) {
            return;
        }

        delete properties.folder;

        await vm.updateWidget(true, true);
    }

    /**
     * Remove the overrides for the given file.
     *
     * @param {string} fileId The id of the file to remove the overrides of.
     * @param {string} [lang] The language we want to remove the override from.
     *                        If none given, remove all the overrides for the media.
     */
    async function eraseOverride(fileId, lang) {
        if (angular.isUndefinedOrEmpty(fileId)) {
            return;
        }

        const properties = _getWidgetProperties();

        if (angular.isUndefinedOrEmpty(lang)) {
            set(properties, `overrides.${fileId}`, {});

            return;
        }

        set(properties, ['overrides', fileId, 'description', lang], '');
        set(properties, ['overrides', fileId, 'name', lang], '');
        set(properties, ['overrides', fileId, 'thumbnail', lang], '');

        await vm.updateWidget(true, false);
    }

    /**
     * Get the description of a file.
     *
     * @param  {Object} mediaContent The media content of the file we want to get the description of.
     * @return {string} The file description.
     */
    function getFileDescription(mediaContent) {
        const properties = _getWidgetProperties();

        return Document.getDescription(
            mediaContent,
            get(properties, `overrides[${get(mediaContent, 'fileId')}]`),
            Translation.inputLanguage,
        );
    }

    /**
     * Get the name of a file.
     *
     * @param  {Object} mediaContent The media content of the file we want to get the name of.
     * @return {string} The file name.
     */
    function getFileName(mediaContent) {
        const properties = _getWidgetProperties();

        return Document.getName(
            mediaContent,
            get(properties, `overrides[${get(mediaContent, 'fileId')}]`),
            Translation.inputLanguage,
        );
    }

    /**
     * Returns current property for the current durective's file type.
     *
     * @param  {string} propertyName The property to get.
     * @return {string} The property value.
     */
    function getFileTypeConfigProperty(propertyName) {
        return get(vm.FILE_TYPES_CONFIG, [vm.fileTypes, propertyName]);
    }

    /**
     * Get the options of the Froala editor for the description of the given media content.
     * The standard options are extended with the placeholder.
     *
     * @param  {Object} mediaContent The media content of the media we want to edit the description of.
     * @return {Object} The froala options for the edition of the description of the given media.
     */
    function getFroalaOptions(mediaContent) {
        const description = Translation.translate_(mediaContent.description, Translation.inputLanguage, true);

        if (angular.isDefinedAndFilled(description)) {
            vm.FROALA_OPTIONS.placeholderText = description;
        } else {
            delete vm.FROALA_OPTIONS.placeholderText;
        }

        return vm.FROALA_OPTIONS;
    }

    /**
     * Check if a document has a title in the current lang.
     *
     * @param  {Object}  doc The document to check for a title.
     * @return {boolean} If the document has title in the current lang or not.
     */
    function hasTitle(doc) {
        const mediaContent = Document.getMediaContentByLang(doc, true);

        return angular.isDefinedAndFilled(get(mediaContent, 'title'));
    }

    /**
     * Open the media picker.
     *
     * @return {Promise} Open media picker promise.
     */
    function openMediaPicker() {
        _updateMediaPicker();

        vm.showMediaPickerDialog = true;
        vm.is.loading.files = true;

        return Utils.waitForAndExecute(`#${vm.FILE_PICKER_ID}`, AbstractPicker);
    }

    /**
     * Remove the file from the selected files list.
     *
     * @param {number} index The index of the file to remove.
     */
    async function removeFile(index) {
        const properties = _getWidgetProperties();

        if (
            angular.isUndefinedOrEmpty(index) ||
            properties.displayType !== WidgetSettingsConstant.SELECTION_TYPES.PICK ||
            index < 0 ||
            index > get(properties.files, 'length', 0)
        ) {
            return;
        }

        const removedFile = first(vm.files.splice(index, 1));
        const removedMediaContent = Document.getMediaContentByLang(removedFile.object || removedFile, true);
        const removedFileId = get(removedMediaContent, 'fileId');

        properties.files.splice(index, 1);

        if (angular.isDefinedAndFilled(properties.overrides) && angular.isDefinedAndFilled(removedFileId)) {
            delete properties.overrides[removedFileId];
        }

        vm.is.editing = undefined;

        await vm.updateWidget(true, false);
    }

    /**
     * Remove the origin file from the list of files after dropping it elsewhere in the list.
     *
     * @param {number} index The index of the file to remove from the list.
     */
    async function removeOriginFile(index) {
        vm.files.splice(index, 1);

        await vm.updateWidget(true, false, true);
    }

    /**
     * Select a folder to list files from.
     *
     * @param {string}  folderType       The type of selected folder.
     * @param {string}  categoryId       The category id.
     * @param {boolean} [fromInit=false] Indicates if we are setting the provider from the init function.
     */
    async function selectFolder(folderType, categoryId, fromInit) {
        let docPath;

        if (folderType === FOLDER_TYPES.CATEGORY) {
            docPath = (await _documentProvider.getProviderCategory(_getCurrentProviderId(), categoryId)).rootPath;
        }

        const { folder } = _getWidgetProperties();

        folder.categoryId = categoryId;
        folder.docPath = docPath;
        folder.folderType = folderType;

        vm.files = [];
        await vm.updateWidget(true, !fromInit);
    }

    /**
     * Toggle field.
     *
     * @param {Object} field The field that is toggled.
     * Todo [Arnaud]: refactor this into a WidgetSettings service?
     */
    async function toggleField(field) {
        if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
            return;
        }

        field.enable = !field.enable;

        await vm.updateWidget(true, false);
    }

    /**
     * Toggle the edition of overrides for a file.
     *
     * @param {string} fileId The id of the file to edit the overrides of.
     */
    function toggleFile(fileId) {
        if (angular.isUndefinedOrEmpty(fileId)) {
            return;
        }

        const properties = _getWidgetProperties();

        vm.is.editing = vm.is.editing === fileId ? undefined : fileId;

        const override = get(properties, `overrides[${fileId}]`);

        if (angular.isDefinedAndFilled(vm.is.editing)) {
            properties.overrides = properties.overrides || {};
            properties.overrides[fileId] = properties.overrides[fileId] || {
                description: {},
                name: {},
                thumbnail: {},
            };
        } else if (
            angular.isDefined(override) &&
            angular.isUndefinedOrEmpty([override.name, override.description, override.thumbnail], 'every')
        ) {
            delete properties.overrides[fileId];
        }
    }

    /**
     * Set the provider selected when the widget is in "Folder list" mode.
     *
     * @param  {string}  [providerId]     The selected provider.
     * @param  {boolean} [fromInit=false] Indicates if we are setting the provider from the init function.
     *                                    In this case, we don't wipe the properties.
     * @param  {boolean} [reset=false]    Indicates if you want to force reset the provider.
     * @return {Promise} Set provider promise.
     */
    function toggleProvider(providerId = _getDefaultProviderId(), fromInit, reset) {
        const properties = _getWidgetProperties();
        const previousProviderId = get(properties.folder, 'providerId');

        // If keeping the same provider.
        if (
            angular.isDefinedAndFilled(previousProviderId) &&
            providerId === previousProviderId &&
            !fromInit &&
            !reset
        ) {
            return vm.updateWidget(true, true);
        }

        _setProviderId(providerId);

        return _initPickableFolders(providerId).then(() =>
            vm.selectFolder(FOLDER_TYPES.CATEGORY, get(first(vm.pickableFolders), 'id'), fromInit),
        );
    }

    /**
     * Update widget on settings change.
     *
     * @param {boolean} [broadcast=true]      Indicates if we want to send the update event.
     * @param {boolean} [updateFiles=false]   Indicates if we want to update the files list.
     * @param {boolean} [isDragAndDrop=false] Indicates if we want to update the files list after drag & drop file.
     */
    async function updateWidget(broadcast, updateFiles, isDragAndDrop) {
        const properties = _getWidgetProperties();

        broadcast = angular.isUndefined(broadcast) ? true : Boolean(broadcast);

        if (updateFiles && !isDragAndDrop) {
            vm.is.loading.files = true;

            let promise = $q.resolve();

            vm.files = [];

            if (properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.PICK) {
                $scope.$evalAsync(() => {
                    vm.categoryAllowFileManagement = false;
                    properties.canManageFiles = false;
                });

                if (angular.isDefinedAndFilled(properties.files)) {
                    promise = Document.getMulti(properties.files);

                    promise.then(({ items = [] }) => {
                        vm.files = map(items, (item) => {
                            return {
                                id: item.id,
                                object: item,
                            };
                        });

                        if (angular.isDefinedAndFilled(properties.overrides)) {
                            _initOverrides(properties);
                        }
                    });
                }
            } else if (
                properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.LIST ||
                properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
            ) {
                await _initWidgetFolderDocPathAndParams();

                if (get(properties.folder, 'folderType') === FOLDER_TYPES.CUSTOM) {
                    const { categoryId, docPath, providerId } = properties.folder;

                    promise = Document.promiseGet({
                        docPath,
                    });

                    promise.then((files = {}) => {
                        vm.files = {
                            categoryId,
                            id: files.id,
                            object: files,
                            providerId,
                        };
                    });
                }
            }

            promise.finally(() => {
                vm.is.loading.files = false;
            });
        } else if (isDragAndDrop) {
            properties.files = map(vm.files, 'object.docPath');
        }

        if (angular.isFunction(vm.parentUpdateWidget)) {
            await vm.parentUpdateWidget();
        }

        if (broadcast) {
            $timeout(() => {
                $rootScope.$broadcast('widget-file-selection-settings', Widget.getCurrent().uuid);
            });
        }
    }

    /////////////////////////////

    vm.changeMode = changeMode;
    vm.clearFolder = clearFolder;
    vm.debouncedUpdateWidget = debounce(updateWidget, WidgetSettingsConstant.DEBOUNCE_DELAY);
    vm.eraseOverride = eraseOverride;
    vm.getFileDescription = getFileDescription;
    vm.getFileName = getFileName;
    vm.getFileTypeConfigProperty = getFileTypeConfigProperty;
    vm.getFroalaOptions = getFroalaOptions;
    vm.hasTitle = hasTitle;
    vm.openMediaPicker = openMediaPicker;
    vm.removeFile = removeFile;
    vm.removeOriginFile = removeOriginFile;
    vm.selectFolder = selectFolder;
    vm.toggleField = toggleField;
    vm.toggleFile = toggleFile;
    vm.toggleProvider = toggleProvider;
    vm.updateWidget = updateWidget;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the media picker dialog closes, remove it from the DOM.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The identifier of the dialog being closed.
     */
    $scope.$on('abstract-picker__close-end', (evt, dialogId) => {
        if (dialogId !== vm.FILE_PICKER_ID) {
            return;
        }

        if (angular.isDefinedAndFilled(vm.files)) {
            $scope.$apply(async () => {
                await _updateWidgetProperties();
            });
        }

        $timeout(() => {
            vm.showMediaPickerDialog = false;
            vm.is.loading.files = false;
        });
    });

    /**
     * When the input language changes, re-display the files.
     */
    $scope.$on('inputLanguage', async () => {
        await vm.updateWidget(false, false);
    });

    /////////////////////////////

    /**
     * Init newly added widget.
     *
     * @return {Promise} Widget initialization promise.
     */
    async function _initNewWidget() {
        const currentWidget = Widget.getCurrent();

        if (angular.isUndefinedOrEmpty(currentWidget.properties)) {
            currentWidget.properties = {};
        }

        if (angular.isUndefinedOrEmpty(currentWidget.properties.folder)) {
            currentWidget.properties.folder = {};
        }

        const { providerId } = currentWidget.properties.folder;

        if (angular.isUndefinedOrEmpty(providerId)) {
            await vm.toggleProvider(_getDefaultProviderId(), true);
        } else {
            await _initPickableFolders(providerId);
        }
    }

    /**
     * Init document provider service.
     */
    function _initDocumentProvider() {
        if (vm.isCommunityContext) {
            // Note: wait for the backend to be implemented before uncomment this.
            // _documentProviderService = 'CommunityDocumentProvider';
        }

        _documentProvider = $injector.get(_documentProviderService);
    }

    /**
     * Init list of available document providers.
     *
     * @return {Promise} Init promise.
     */
    async function _initDocumentProviderList() {
        vm.is.loading.providers = true;
        vm.providerList = await _documentProvider.getList();

        // Remove specific provider if we want to get rid of it and use the brand new MP Widget instead
        if (Features.hasFeature('file-list-disable-ms-provider')) {
            for (let i = 0 ; i < vm.providerList.length; i++) {
                if (vm.providerList[i].id && vm.providerList[i].id === 'onedrive') {
                    vm.providerList.splice(i, 1);
                }
            }
        }

        $scope.$apply(() => {
            vm.is.loading.providers = false;
        });
    }

    /**
     * Initialize the controller.
     */
    async function init() {
        vm.isCommunityContext = Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

        _initDocumentProvider();
        await _initDocumentProviderList();
        await _initNewWidget();

        const { uuid } = Widget.getCurrent();
        const properties = _getWidgetProperties();

        if(!properties.displayType){
            properties.displayType = WidgetSettingsConstant.SELECTION_TYPES.PICK;
        }
        if (Utils.isDesignerMode()) {
            const FroalaService = $injector.get('FroalaService');
            // Image Gallery Widget does not support formatting options
            vm.FROALA_OPTIONS =
                Widget.getCurrent().widgetType === 'image-gallery'
                    ? FroalaService.getOptions(true, true, false, false, [])
                    : FroalaService.getOptions(false, false, false, false, FroalaService.LIGHT_OPTIONS_TOOLBAR_BUTTONS);
        }

        vm.FILE_PICKER_ID += uuid;

        await vm.updateWidget(false, true);

        if (
            vm.isCommunityContext &&
            _getWidgetProperties().currentCommunityDrive &&
            properties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
        ) {
            _documentProviderService = 'CommunityDocumentProvider';
            const { docPath, source: providerId } = Content.getCurrent().driveFolder;

            properties.folder = {
                categoryId: 'TEAM_DRIVE',
                docPath,
                folderType: FOLDER_TYPES.CUSTOM,
                providerId,
            };
        }

        Tag.init(Config.TAG_TYPE.MEDIA, WidgetFileListConstant.LIST_KEY_MEDIA_TAGS);
    }

    init();
}

/////////////////////////////

/**
 * Generic file selection settings directive.
 *
 * @param {string} [fileTypes='file'] The type of file that will be available in the media picker (images|files).
 */

function WidgetSettingsFileSelectionDirective() {
    'ngInject';

    // eslint-disable-next-line angular/prefer-component
    return {
        bindToController: true,
        controller: WidgetSettingsFileSelectionController,
        controllerAs: 'vm',
        require: ['widgetSettingsFileSelection', '^widgetSettings'],
        restrict: 'E',
        scope: {
            fileTypes: '@?',
            parentUpdateWidget: '&?updateWidget',
        },
        templateUrl:
            '/client/front-office/modules/content/modules/widget/common/views/partials/widget-settings-file-selection.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsWidgetSettingsFileSelection', WidgetSettingsFileSelectionDirective);

/////////////////////////////

export { WidgetSettingsFileSelectionDirective };
