import isEmpty from 'lodash/isEmpty';

import { Image } from '@lumapps/lumx-images/types';
import { GLOBAL } from '@lumapps/translations';
import { getImageSize } from '@lumapps/utils/images/getImageSize';
import { ImageEditor, ImageEditorOptions, ImageElement } from '@lumapps/wrex-enhanced-image/types';
import { ReactEditor } from '@lumapps/wrex/slate';

// Function for get images elements from images
const getImagesElementFromImages = (
    images: Image[],
    uploadResponse?: {
        imagesSizes: { width: number; height: number }[];
        imagesDocuments: { id: string; url: string }[];
    },
) => {
    const imageElements: ImageElement[] = [];
    let uploadedImageIndex = 0;

    const imagesUploaded = uploadResponse?.imagesDocuments?.map(({ id, url }, i) => ({
        id,
        src: url,
        height: uploadResponse?.imagesSizes && uploadResponse?.imagesSizes[i].height,
        width: uploadResponse?.imagesSizes && uploadResponse?.imagesSizes[i].width,
    }));

    // Check whether the image has already been loaded or not
    images.forEach((image, index) => {
        if (!image.url && imagesUploaded) {
            imageElements.push({ ...imagesUploaded[uploadedImageIndex], tempSrc: image.blobUrl, alt: image.alt });

            if (index === images.length - 1) {
                uploadedImageIndex = 0;
            } else {
                uploadedImageIndex += 1;
            }
        } else {
            imageElements.push({
                tempSrc: image.blobUrl,
                src: image.url as string,
                height: image?.height,
                width: image?.width,
                alt: image?.alt || '',
            });
        }
    });

    return imageElements;
};

/**
 * Upload an image gallery and update its status directly into the editor.
 */
export async function uploadImageGallery(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    images: Image[],
) {
    const filteredImages = images.filter((image) => !!image.file);
    const files = filteredImages.map((image) => image.file as File);

    if (!isEmpty(files)) {
        // If files exist, we uploads files, and generates image elements.
        // Otherwise, we just generates image elements.
        const [imagesSizes, imagesDocuments] = await Promise.all([
            // Get image original size
            Promise.all(files.map(getImageSize)),
            // Upload file to server.
            options.uploadFunction(files, GLOBAL.UNABLE_TO_SAVE),
        ]);

        return getImagesElementFromImages(images, { imagesSizes, imagesDocuments });
    }
    return getImagesElementFromImages(images);
}
