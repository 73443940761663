import mapValues from 'lodash/mapValues';

import { FROALA_TOOL_ICONS } from '@lumapps/wrex-legacy-froala/constants';

/**
 * An object with Froala settings.
 *
 * @type {Object}
 * @readonly
 */
const FroalaSettings = {
    // Available font size.
    fontSize: [
        '8',
        '9',
        '10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '18',
        '20',
        '22',
        '24',
        '26',
        '28',
        '30',
        '32',
        '36',
        '40',
        '46',
        '52',
        '60',
        '72',
        '96',
    ],
    // Available default fonts.
    fonts: {
        "'Roboto', sans-serif": 'Roboto',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Arial,Helvetica,sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        'Verdana,Geneva,sans-serif': 'Verdana',
    },
    // Key.
    key: 'lC5D4C1D2B1C1F1pI1A1A1B6A1F6B4A1I4E3kWa1WDPTf1ZNRGb1OG1g1==',
    // Links options.
    linkEditButtons: ['linkStyle', 'linkEditWrapper', 'linkRemove'],
    linkInsertButtons: ['linkBack'],
    // Available SVG attributes.
    svgAttributes: [
        'accent-height',
        'accumulate',
        'additive',
        'alignment-baseline',
        'allowReorder',
        'alphabetic',
        'amplitude',
        'arabic-form',
        'ascent',
        'attributeName',
        'attributeType',
        'autoReverse',
        'azimuth',
        'baseFrequency',
        'baseline-shift',
        'baseProfile',
        'bbox',
        'begin',
        'bias',
        'by',
        'calcMode',
        'cap-height',
        'class',
        'clip',
        'clipPathUnits',
        'clip-path',
        'clip-rule',
        'color',
        'color-interpolation',
        'color-interpolation-filters',
        'color-profile',
        'color-rendering',
        'contentScriptType',
        'contentStyleType',
        'cursor',
        'cx',
        'cy',
        'd',
        'decelerate',
        'descent',
        'diffuseConstant',
        'direction',
        'display',
        'divisor',
        'dominant-baseline',
        'dur',
        'dx',
        'dy',
        'edgeMode',
        'elevation',
        'enable-background',
        'end',
        'exponent',
        'externalResourcesRequired',
        'fill',
        'fill-opacity',
        'fill-rule',
        'filter',
        'filterRes',
        'filterUnits',
        'flood-color',
        'flood-opacity',
        'font-family',
        'font-size',
        'font-size-adjust',
        'font-stretch',
        'font-style',
        'font-variant',
        'font-weight',
        'format',
        'from',
        'fr',
        'fx',
        'fy',
        'g1',
        'g2',
        'glyph-name',
        'glyph-orientation-horizontal',
        'glyph-orientation-vertical',
        'glyphRef',
        'gradientTransform',
        'gradientUnits',
        'hanging',
        'hreflang',
        'horiz-adv-x',
        'horiz-origin-x',
        'ideographic',
        'image-rendering',
        'in',
        'in2',
        'intercept',
        'k',
        'k1',
        'k2',
        'k3',
        'k4',
        'kernelMatrix',
        'kernelUnitLength',
        'kerning',
        'keyPoints',
        'keySplines',
        'keyTimes',
        'lengthAdjust',
        'letter-spacing',
        'lighting-color',
        'limitingConeAngle',
        'local',
        'marker-end',
        'marker-mid',
        'marker-start',
        'markerHeight',
        'markerUnits',
        'markerWidth',
        'mask',
        'maskContentUnits',
        'maskUnits',
        'mathematical',
        'method',
        'mode',
        'numOctaves',
        'offset',
        'opacity',
        'operator',
        'order',
        'orient',
        'orientation',
        'origin',
        'overflow',
        'overline-position',
        'overline-thickness',
        'panose-1',
        'paint-order',
        'path',
        'pathLength',
        'patternContentUnits',
        'patternTransform',
        'patternUnits',
        'ping',
        'pointer-events',
        'points',
        'pointsAtX',
        'pointsAtY',
        'pointsAtZ',
        'preserveAlpha',
        'preserveAspectRatio',
        'primitiveUnits',
        'r',
        'radius',
        'referrerPolicy',
        'refX',
        'refY',
        'rel',
        'rendering-intent',
        'repeatCount',
        'repeatDur',
        'requiredExtensions',
        'requiredFeatures',
        'restart',
        'result',
        'rotate',
        'rx',
        'ry',
        'scale',
        'seed',
        'shape-rendering',
        'slope',
        'spacing',
        'specularConstant',
        'specularExponent',
        'speed',
        'spreadMethod',
        'startOffset',
        'stdDeviation',
        'stemh',
        'stemv',
        'stitchTiles',
        'stop-color',
        'stop-opacity',
        'strikethrough-position',
        'strikethrough-thickness',
        'string',
        'stroke',
        'stroke-dasharray',
        'stroke-dashoffset',
        'stroke-linecap',
        'stroke-linejoin',
        'stroke-miterlimit',
        'stroke-opacity',
        'stroke-width',
        'surfaceScale',
        'systemLanguage',
        'tableValues',
        'target',
        'targetX',
        'targetY',
        'text-anchor',
        'text-decoration',
        'text-rendering',
        'textLength',
        'to',
        'transform',
        'type',
        'u1',
        'u2',
        'underline-position',
        'underline-thickness',
        'unicode',
        'unicode-bidi',
        'unicode-range',
        'units-per-em',
        'v-alphabetic',
        'v-hanging',
        'v-ideographic',
        'v-mathematical',
        'values',
        'vector-effect',
        'version',
        'vert-adv-y',
        'vert-origin-x',
        'vert-origin-y',
        'viewBox',
        'viewTarget',
        'visibility',
        'widths',
        'word-spacing',
        'writing-mode',
        'x',
        'x-height',
        'x1',
        'x2',
        'xChannelSelector',
        'xlink:actuate',
        'xlink:arcrole',
        'xlink:href',
        'xlink:role',
        'xlink:show',
        'xlink:title',
        'xlink:type',
        'xml:base',
        'xml:lang',
        'xml:space',
        'xmlns',
        'xmlns:xlink',
        'y',
        'y1',
        'y2',
        'yChannelSelector',
        'z',
        'zoomAndPan',
    ],
    // Available SVG tags.
    svgTags: [
        'altGlyph',
        'altGlyphDef',
        'altGlyphItem',
        'animate',
        'animateColor',
        'animateMotion',
        'animateTransform',
        'circle',
        'clipPath',
        'color-profile',
        'cursor',
        'defs',
        'desc',
        'discard',
        'ellipse',
        'feBlend',
        'feColorMatrix',
        'feComponentTransfer',
        'feComposite',
        'feConvolveMatrix',
        'feDiffuseLighting',
        'feDisplacementMap',
        'feDistantLight',
        'feDropShadow',
        'feFlood',
        'feFuncA',
        'feFuncB',
        'feFuncG',
        'feFuncR',
        'feGaussianBlur',
        'feImage',
        'feMerge',
        'feMergeNode',
        'feMorphology',
        'feOffset',
        'fePointLight',
        'feSpecularLighting',
        'feSpotLight',
        'feTile',
        'feTurbulence',
        'filter',
        'font',
        'font-face',
        'font-face-format',
        'font-face-name',
        'font-face-src',
        'font-face-uri',
        'foreignObject',
        'g',
        'glyph',
        'glyphRef',
        'hatch',
        'hatchpath',
        'hkern',
        'image',
        'line',
        'linearGradient',
        'marker',
        'mask',
        'mesh',
        'meshgradient',
        'meshpatch',
        'meshrow',
        'metadata',
        'missing-glyph',
        'mpath',
        'path',
        'pattern',
        'polygon',
        'polyline',
        'radialGradient',
        'rect',
        'set',
        'solidcolor',
        'stop',
        'style',
        'svg',
        'switch',
        'symbol',
        'text',
        'textPath',
        'title',
        'tref',
        'tspan',
        'use',
        'view',
        'vkern',
    ],
    // Available buttons.
    tools: mapValues(FROALA_TOOL_ICONS, (icon) => {
        return {
            icon
        };
    }),
};

/////////////////////////////

angular.module('Constants').constant('FroalaSettings', FroalaSettings);

/////////////////////////////

export { FroalaSettings };
