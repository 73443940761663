import { getAttributes } from '@lumapps/data-attributes';
import { REFRESH_EVENTS } from '@lumapps/posts/constants';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetCommentsController(Content, InitialSettings) {
        'ngInject';

        var vm = this;

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode.
         *
         * @return {boolean} Whether or not the comments widget is empty.
         */
        function isWidgetEmpty() {
            return vm.parentCtrl.designerMode();
        }

        /**
         * Is widget hidden in reading mode.
         *
         * @return {boolean} Whether or not the widget is hidden.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = false;

            return vm.parentCtrl.isHidden;
        }

        /**
         * A function to pass to the new comment widget in React, in order to give it the ability
         * to update the parent content, which is implemented in legacy so impossible to reach with redux.
         * @param {string} event  A type of event.
         * @param {string} params Additional params to use if needed.
         */
        function onPostUpdate(event, params) {
            if (event === REFRESH_EVENTS.COMMENT_ADD) {
                vm.currentContent.comments += 1;
            } else if (event === REFRESH_EVENTS.COMMENT_HIDE) {
                vm.currentContent.comments -= 1;
            } else if (event === REFRESH_EVENTS.COMMENT_MARK_RELEVANT) {
                vm.currentContent.relevantComment = params.relevantCommentId;
            } else if (event === REFRESH_EVENTS.COMMENT_UNMARK_RELEVANT) {
                vm.currentContent.relevantComment = undefined;
            }
        }

        /////////////////////////////

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.onPostUpdate = onPostUpdate;
        vm.CommentAttributes = getAttributes('block-comments');

        /**
         * Initialize the controller.
         */
        function init() {
            vm.widget.properties = _.get(vm.widget, 'properties', {});
            var properties = vm.widget.properties;

            vm.currentContent = angular.isDefinedAndFilled(vm.parentCtrl.content)
                ? vm.parentCtrl.content
                : Content.getCurrent();

            properties.isEnabled = angular.isUndefined(properties.isEnabled) ? true : properties.isEnabled;
        }

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            init();
        };
    }

    function WidgetCommentsDirective() {
        'ngInject';

        function WidgetCommentsLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetCommentsController,
            controllerAs: 'vm',
            link: WidgetCommentsLink,
            replace: true,
            require: ['widgetComments', '^widget'],
            restrict: 'E',
            scope: {
                widget: '=',
            },
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules' +
                '/widget-comments/views/widget-comments.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetComments', WidgetCommentsDirective);
})();
