import isEmpty from 'lodash/isEmpty';

import { get } from '@lumapps/constants';
import { getCurrentContentHeader } from '@lumapps/contents/ducks/selectors';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { isMainNavInheritanceEnabled } from '@lumapps/navigation/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { HeaderState } from './types';

const constants = get();

const getDefaultHeader = (isInheritanceEnabled: boolean): Partial<HeaderState> => {
    return {
        properties: {
            media: [],
            autoplay: false,
            interval: 5,
            wrapperHeight: 170,
            layoutPosition: isInheritanceEnabled ? 24 : -50,
            slideshowHeight: 0,
        },
        height: 270,
    };
};

const headerSelector = (state: FrontOfficeStore) => state.header;

const getCurrentHeader = createSelector(
    getCurrentContentHeader,
    headerSelector,
    isMainNavInheritanceEnabled,
    getCurrentInstance,
    (contentHeader, instanceHeader, isInheritanceEnabled, instance) => {
        /**
         * If this is the legacy application and main nav inheritance is disabled, the header is managed
         * by the legacy application. In that case we just return undefined so that the new header does not
         * render anything related to the content header
         */
        if (constants.isLegacyContext && !isInheritanceEnabled) {
            return undefined;
        }

        /**
         * If there is a specific header that has been overriden for the current content, use that one instead of
         * the instance one.
         */
        if (contentHeader && !isEmpty(contentHeader)) {
            return contentHeader;
        }

        /**
         * If there is no style set for the current instance and no header defined, it means that this is a new site,
         * so in that case we use the default header.
         */
        if (!instance.style && !instanceHeader) {
            return getDefaultHeader(isInheritanceEnabled);
        }

        return instanceHeader;
    },
);

const doesHaveAContentHeader = createSelector(getCurrentHeader, (header) => {
    const headerHeight = header?.height ?? 0;

    return headerHeight > 0;
});

export { headerSelector, getCurrentHeader, doesHaveAContentHeader, getDefaultHeader };
