import {
    viewModeGrid,
    viewModeGridGroup,
    viewModeGridUngroup,
    viewModeListGroup,
    viewModeListUngroup,
    viewModeList,
    viewModeSlideshow,
} from '@lumapps/lumx/custom-icons';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';

import { AVAILABLE_OPTIONS, ViewModeType } from './types';

/**
 * Defines the values for each view mode.
 */
export enum VIEW_MODES_VALUES {
    GRID = 'grid',
    LIST = 'list',
    SLIDESHOW = 'slideshow',
}

/**
 * Defines the values for each view mode variant.
 */
export enum VIEW_MODE_VARIANTS_VALUES {
    GROUPED = 'grouped',
    UNGROUPED = 'ungrouped',
}

/**
 * Defines the icons for each view mode.
 */
export enum VIEW_MODE_ICONS {
    GRID = viewModeGrid,
    GRID_GROUP = viewModeGridGroup,
    GRID_UNGROUP = viewModeGridUngroup,
    LIST = viewModeList,
    LIST_GROUP = viewModeListGroup,
    LIST_UNGROUP = viewModeListUngroup,
    SLIDESHOW = viewModeSlideshow,
}

/**
 * In which view mode the widget should be displayed.
 */
export const VIEW_MODES: ViewModeType<string>[] = [
    {
        availableOptions: [AVAILABLE_OPTIONS.VARIANT, AVAILABLE_OPTIONS.MAIN, AVAILABLE_OPTIONS.SEPARATOR],
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_LIST,
        icon: VIEW_MODE_ICONS.LIST,
        value: VIEW_MODES_VALUES.LIST,
    },
    {
        availableOptions: [
            AVAILABLE_OPTIONS.CAPTION_POSITION,
            AVAILABLE_OPTIONS.VARIANT,
            AVAILABLE_OPTIONS.COLUMN_COUNT,
            AVAILABLE_OPTIONS.ORIENTATION,
            AVAILABLE_OPTIONS.MAIN,
        ],
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_GRID,
        icon: VIEW_MODE_ICONS.GRID,
        value: VIEW_MODES_VALUES.GRID,
    },
    {
        availableOptions: [],
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_SLIDESHOW,
        icon: VIEW_MODE_ICONS.SLIDESHOW,
        value: VIEW_MODES_VALUES.SLIDESHOW,
    },
];

/**
 * In list mode, in which variants items blocks should be listed.
 */
export const VIEW_MODE_LIST_VARIANTS: ViewModeType<string>[] = [
    {
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_GROUPED,
        value: VIEW_MODE_VARIANTS_VALUES.GROUPED,
        icon: VIEW_MODE_ICONS.LIST_GROUP,
    },
    {
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_UNGROUPED,
        value: VIEW_MODE_VARIANTS_VALUES.UNGROUPED,
        icon: VIEW_MODE_ICONS.LIST_UNGROUP,
    },
];

/**
 * In grid mode, in which variants items blocks should be listed.
 */
export const VIEW_MODE_GRID_VARIANTS: ViewModeType<string>[] = [
    {
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_GROUPED,
        value: VIEW_MODE_VARIANTS_VALUES.GROUPED,
        icon: VIEW_MODE_ICONS.GRID_GROUP,
    },
    {
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_UNGROUPED,
        value: VIEW_MODE_VARIANTS_VALUES.UNGROUPED,
        icon: VIEW_MODE_ICONS.GRID_UNGROUP,
    },
];
