import { Editor, ReactEditor } from '@lumapps/wrex/slate';

import { isTableRow } from './isTableRow';

export const getCurrentRowsInTable = (editor: ReactEditor, tablePath: number[]) => {
    /* get the number of row in the table  */
    const currentNumberOfRowsInTable = Array.from(Editor.nodes(editor, { match: isTableRow, at: tablePath }));
    return {
        rowsInTable: currentNumberOfRowsInTable,
        rowsLength: currentNumberOfRowsInTable.length,
    };
};
