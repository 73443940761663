import { cleanParams } from '@lumapps/base-api';
import { ConnectorSearchParam } from '@lumapps/connectors/hooks/useConnectorsList';
import { CONNECTOR_SORT, ConnectorListResponse } from '@lumapps/connectors/types';

import { organizationInstalledExtensionApi } from './extensionApi';

export interface FetchExtensionConnectorsParams {
    cancelKey?: string;
    cursor?: string;
    extensionId: string;
    maxResults?: number;
    site?: string;
    searchParams?: ConnectorSearchParam;
    sort?: CONNECTOR_SORT;
}

export const fetchExtensionConnectors = ({
    cancelKey,
    extensionId,
    searchParams,
    sort,
    ...rest
}: FetchExtensionConnectorsParams) => {
    return organizationInstalledExtensionApi.get<ConnectorListResponse>(
        `/${extensionId}/connectors`,
        {
            params: cleanParams({ ...rest, ...searchParams, sort }),
        },
        true,
        true,
        cancelKey,
    );
};
