import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface SkeletonWrapperProps {
    /** Children to display, should be the skeletons. */
    children: React.ReactNode;
    /** Custom message to display to screen readers. */
    message?: string;
}

/**
 * Component to set around skeletons in order to add a hidden textual message
 * informing screen reader users that something is loading.
 * inspired by https://adrianroselli.com/2020/11/more-accessible-skeletons.html
 *
 * @family Loaders
 * @param SkeletonWrapperProps
 * @returns SkeletonWrapper
 */
export const SkeletonWrapper: React.FC<SkeletonWrapperProps> = ({ children, message }) => {
    const { translateKey } = useTranslate();

    return (
        <>
            <Text as="p" className={visuallyHidden()}>
                {message || translateKey(GLOBAL.LOADING)}
            </Text>
            {children}
        </>
    );
};
