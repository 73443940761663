import React from 'react';

import { classnames, margin, useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { useCustomizations, MODES } from '@lumapps/customizations';
import { Header as HeaderWrapper } from '@lumapps/lumx-front-office/components/Header';
import { Size, SkeletonRectangle, SkeletonRectangleVariant, SkeletonCircle } from '@lumapps/lumx/react';
import { MainTopNavigationLoadingState } from '@lumapps/navigation/components/MainTopNavigation/MainTopNavigationLoadingState';
import { useIsNavigationDisabled } from '@lumapps/navigation/hooks/useIsNavigationDisabled';
import { useResponsive } from '@lumapps/responsive';
import { LoadingSearchbox } from '@lumapps/search-box/components/SearchBox/LoadingSearchbox';

import { InstanceLogo } from '../InstanceLogo/InstanceLogo';
import { MobileLoadingHeader } from './Mobile';

const constants = get();

const CLASSNAME = 'header';
const CLASSNAME_TOP = 'header-top';
const CLASSNAME_NAV = 'header-main-nav';

const LoadingHeader: React.FC<any> = () => {
    const { isMobile } = useResponsive();
    const { modes } = useCustomizations();
    const { element, block } = useClassnames(CLASSNAME_TOP);
    const { isNavigationDisabled, isNavigationUiDisabled } = useIsNavigationDisabled();
    /**
     * Since the loading header is displayed outside of the customizations and routing context, we need to
     * fallback to constants in order to determine which modes are enabled.
     */
    const noTopBarModeEnabled = modes[MODES.NO_TOP_BAR] || constants.modes?.indexOf(MODES.NO_TOP_BAR) >= 0;

    const iconClasses = margin('horizontal', 'regular');
    const topStyles = {
        backgroundColor: constants.instanceTopBackgroundColor,
    };

    const navStyles = {
        backgroundColor: constants.instanceNavBackgroundColor,
    };

    const instanceLogoSrc = constants.logo;

    if (isMobile) {
        return <MobileLoadingHeader topStyles={topStyles} instanceLogoSrc={instanceLogoSrc} />;
    }

    return (
        <HeaderWrapper className={CLASSNAME} hasNavigation>
            {!noTopBarModeEnabled ? (
                <div className={block(['sticky'])} style={{ backgroundColor: topStyles.backgroundColor }}>
                    <div className={element('wrapper')}>
                        <div className={element('logo')} style={{ marginLeft: '0' }}>
                            <InstanceLogo
                                homeImageSrc={instanceLogoSrc as string}
                                fallbackHomeImageSrc={constants.defautlLogoUrl}
                                isLoading
                            />
                        </div>

                        <div className={element('actions')}>
                            <LoadingSearchbox style={{ width: '500px' }} />

                            <SkeletonRectangle
                                variant={SkeletonRectangleVariant.squared}
                                className={margin('horizontal', 'big')}
                                style={{ width: '84px', height: '36px', borderRadius: '2px' }}
                            />

                            <SkeletonRectangle
                                variant={SkeletonRectangleVariant.squared}
                                className={classnames(iconClasses, 'header__skeleton--triangle')}
                            />

                            <SkeletonRectangle
                                variant={SkeletonRectangleVariant.squared}
                                className={iconClasses}
                                style={{ width: '20px', height: '20px', borderRadius: '2px' }}
                            />

                            <SkeletonCircle size={Size.xs} className={iconClasses} />

                            <SkeletonCircle size={Size.m} className={margin('horizontal', 'big')} />

                            <SkeletonCircle size={Size.xs} className={iconClasses} />
                        </div>
                    </div>
                </div>
            ) : null}

            {!isNavigationDisabled && !isNavigationUiDisabled ? (
                <div className={classnames(CLASSNAME_NAV, 'sticky')}>
                    <MainTopNavigationLoadingState navStyles={navStyles} />
                </div>
            ) : null}
        </HeaderWrapper>
    );
};

export { LoadingHeader };
