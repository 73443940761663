(function IIFE() {
    'use strict';

    /////////////////////////////

    function GmailService(GmailFactory, LumsitesBaseService) {
        'ngInject';

        return LumsitesBaseService.createLumsitesBaseService(GmailFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });
    }

    /////////////////////////////

    angular.module('Services').service('Gmail', GmailService);
})();
