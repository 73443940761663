import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { DataAttributesOptions, useDataAttributes } from '@lumapps/data-attributes';
import {
    Size,
    Tooltip,
    Button,
    ButtonProps,
    FlexBox,
    Orientation,
    SkeletonCircle,
    SkeletonTypography,
    Typography,
    Alignment,
    Text,
} from '@lumapps/lumx/react';
import { useRovingTabIndex } from '@lumapps/moving-focus';
import { useTranslate } from '@lumapps/translations';
import { useFocus } from '@lumapps/utils/hooks/useFocus';

export interface ActionLink {
    href: string;
    target?: string;
}

export type ActionCallback = () => void;

export interface Action {
    /** Options to use in the data attributes. */
    dataAttributes?: DataAttributesOptions;
    /** Whether the action should appear selected or not. */
    isSelected?: boolean;
    /** Whether the action is loading. If so, show a skeleton */
    isLoading?: boolean;
    /** The action icon in SVG path format. */
    iconSvgPath: string;
    /** The action label translation key. */
    labelKey: string;
    /** The action which can be either a link or a callback. */
    action: ActionLink | ActionCallback;
    /** The action tooltip label translation key. */
    tooltipLabelKey?: string;
    /** Whether or not the action is disabled. */
    isDisabled?: boolean;
    /** Whether or not the action label is hidden. */
    hideLabel?: boolean;
    /** Custom? className */
    className?: string;
    /** Ref that will be passed to the action button */
    buttonRef?: React.RefObject<HTMLButtonElement>;
}

const SCOPE = 'contextual-actions';
const CLASSNAME = 'contextual-actions';

/**
 * Component that represent a single action on the Contextual actions menu.
 *
 * @param Action
 * @returns ToolbarAction
 */
export const ToolbarAction: React.FC<Action> = ({
    labelKey,
    isSelected,
    isLoading,
    dataAttributes = {},
    iconSvgPath,
    action,
    isDisabled,
    tooltipLabelKey,
    hideLabel,
    className,
    buttonRef,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const internalRef = React.useRef<HTMLButtonElement>(null);
    const ref = buttonRef || internalRef;
    const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(ref, !!isDisabled || Boolean(isLoading));
    const { get } = useDataAttributes(SCOPE);
    const { translateKey } = useTranslate();

    useFocus(ref, focused);

    const dynamicProps: Partial<ButtonProps> = {};

    if (typeof action === 'function') {
        dynamicProps.onClick = action;
    } else {
        Object.assign(dynamicProps, action);
    }
    Object.assign(dynamicProps, get(dataAttributes));

    return (
        <div role="presentation">
            <Tooltip label={tooltipLabelKey ? translateKey(tooltipLabelKey) : null}>
                {isLoading ? (
                    <FlexBox
                        className={element('skeleton')}
                        orientation={Orientation.horizontal}
                        hAlign={Alignment.center}
                    >
                        <SkeletonCircle size={Size.xxs} className={element('skeleton-icon')} />
                        {!hideLabel && (
                            <SkeletonTypography
                                className={element('skeleton-label', margin('left', 'tiny'))}
                                typography={Typography.body1}
                                width="50px"
                            />
                        )}
                    </FlexBox>
                ) : (
                    <Button
                        className={element('action', className)}
                        size={Size.s}
                        leftIcon={iconSvgPath}
                        aria-label={translateKey(labelKey)}
                        aria-disabled={isDisabled}
                        aria-pressed={isSelected}
                        isSelected={isSelected}
                        isDisabled={isDisabled}
                        emphasis="low"
                        ref={ref}
                        tabIndex={tabIndex}
                        onKeyDown={handleKeyDown}
                        onClick={handleClick}
                        {...dynamicProps}
                    >
                        {!hideLabel && (
                            <Text as="span" className={element('action-label')}>
                                {translateKey(labelKey)}
                            </Text>
                        )}
                    </Button>
                )}
            </Tooltip>
        </div>
    );
};
