import { mdiTwitter, mdiTwitterBox, mdiLinkedin, mdiLinkedinBox } from '@lumapps/lumx/icons';

const SA_FEATURE_NAME = 'social-advocacy';

const ROLES = {
    ADMIN: 'ADMIN',
    AMBASSADOR: 'AMBASSADOR',
    PROGRAM_MANAGER: 'PROGRAM_MANAGER',
};

const PERMISSIONS = {
    CAN_ACCESS_ALL_SHARES_DATA: 'CAN_ACCESS_ALL_SHARES_DATA',
    CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES: 'CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES',
    CAN_MANAGE_SHAREABLE_CONTENTS: 'CAN_MANAGE_SHAREABLE_CONTENTS',
    CAN_MANAGE_SOCIAL_ADVOCACY_AMBASSADORS: 'CAN_MANAGE_SOCIAL_ADVOCACY_AMBASSADORS',
    CAN_MANAGE_SOCIAL_ADVOCACY_PROGRAM_MANAGERS: 'CAN_MANAGE_SOCIAL_ADVOCACY_PROGRAM_MANAGERS',
    CAN_MANAGE_SOCIAL_ADVOCACY_TOPICS: 'CAN_MANAGE_SOCIAL_ADVOCACY_TOPICS',
    CAN_MANAGE_SOCIAL_NETWORK_CONFIGURATIONS: 'CAN_MANAGE_SOCIAL_NETWORK_CONFIGURATIONS',
    CAN_SHARE_SHAREABLE_CONTENTS: 'CAN_SHARE_SHAREABLE_CONTENTS',
};

const PUBLIC_MODE_PATH_PREFIX = 'ls/share';

// These are the widgetTypes allowed in public view (shared page).
// @TODO check the meta-data widget compatibility.
const WHITE_LISTED_WIDGETS = ['html', 'intro', 'featured-image', 'title'];

const MAX_TWITTER_MESSAGE_LENGTH = 250;
const MAX_LINKEDIN_MESSAGE_LENGTH = 1300;

const SOCIAL_PLATFORMS_CONFIG = {
    linkedin: {
        icon: mdiLinkedin,
        iconBox: mdiLinkedinBox,
        label: 'LinkedIn',
        textLimit: MAX_LINKEDIN_MESSAGE_LENGTH,
    },
    twitter: {
        icon: mdiTwitter,
        iconBox: mdiTwitterBox,
        label: 'Twitter',
        textLimit: MAX_TWITTER_MESSAGE_LENGTH,
    },
};

const SA_FEATURE_FLAGS = {
    selectSNForShareableContent: true,
};

export {
    PERMISSIONS,
    ROLES,
    SA_FEATURE_NAME,
    SA_FEATURE_FLAGS,
    WHITE_LISTED_WIDGETS,
    PUBLIC_MODE_PATH_PREFIX,
    SOCIAL_PLATFORMS_CONFIG,
    MAX_TWITTER_MESSAGE_LENGTH,
};
