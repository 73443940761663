/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isStyleAllowed } from '../../ducks/selectors';
import { StyleSideNavigation } from './StyleSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isStyleAllowed(state),
});

const ConnectedStyleSideNavigation = connect(mapStateToProps)(StyleSideNavigation);

export { ConnectedStyleSideNavigation as StyleSideNavigation };
