export enum ADVANCED_STYLES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103765351
     */
    CUSTOM_CODE = 'ADVANCED_STYLES.CUSTOM_CODE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=106055650
     */
    EDIT_CUSTOM_CODE = 'ADVANCED_STYLES.EDIT_CUSTOM_CODE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102479940
     */
    ADVANCED_WARNING = 'ADMIN.INSTANCE_STYLE.ADVANCED_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103764538
     */
    UNSAVED_CHANGES = 'ADVANCED_STYLES.UNSAVED_CHANGES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452119
     */
    INSTANCE_CSS_ROOT = 'ADMIN_INSTANCE-THEME_CSS_ROOT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452117
     */
    INSTANCE_CSS_THEME = 'ADMIN_INSTANCE-THEME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8106120
     */
    CUSTOM_CSS_WARNING = 'ADMIN.INSTANCE_STYLE.CUSTOM_CSS_WARNING',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452113
     */
    INSTANCE_HEAD = 'ADMIN_INSTANCE-HEAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102480713
     */
    CUSTOM_JAVASCRIPT_WARNING = 'ADMIN.INSTANCE_STYLE.CUSTOM_JAVASCRIPT_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244410
     */
    INSTANCE_STYLE = 'ADMIN.NAV.INSTANCE_STYLE',
}
