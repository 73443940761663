import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiTrophyAward } from '@lumapps/lumx/icons';
import { SideNavigationItemProps } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { canAccessPromotedResults } from '../../ducks/selectors';
import { SEARCH_PROMOTED } from '../../keys';
import { promotedResultsAdmin } from '../../routes';

export interface PromotedResultsSideNavigationProps extends Omit<SideNavigationItemProps, 'route' | 'label' | 'icon'> {
    withIcon?: boolean;
}

const PromotedResultsSideNavigation = ({ withIcon = true, ...rest }: any) => {
    const isAllowed = useSelector(canAccessPromotedResults);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={promotedResultsAdmin()}
            label={translateKey(SEARCH_PROMOTED.PROMOTED_RESULTS_TITLE)}
            icon={withIcon ? mdiTrophyAward : null}
            {...rest}
        />
    );
};

PromotedResultsSideNavigation.displayName = 'SideNavigationItem';

export { PromotedResultsSideNavigation };
