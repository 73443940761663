export enum SHORTCUTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k= INSERT KEY HERE
     */
    UP_ARROW_LABEL = 'SHORTCUTS.UP_ARROW_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493004333
     */
    DOWN_ARROW_LABEL = 'SHORTCUTS.DOWN_ARROW_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493004335
     */
    RIGHT_ARROW_LABEL = 'SHORTCUTS.RIGHT_ARROW_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493004347
     */
    LEFT_ARROW_LABEL = 'SHORTCUTS.LEFT_ARROW_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493004350
     */
    ENTER_LABEL = 'SHORTCUTS.ENTER_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493473488
     */
    ARROW_KEYS = 'SHORTCUTS.ARROW_KEYS',
}
