import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import {
    Alignment,
    FlexBox,
    Icon,
    IconProps,
    Orientation,
    RadioButton,
    RadioButtonProps,
    Size,
    Text,
    Typography,
} from '@lumapps/lumx/react';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

import './index.scss';

const CLASSNAME = 'lumx-forms-radio-button';

export type FormRadioButtonProps = Omit<RadioButtonProps, 'onChange' | 'isChecked'> &
    BaseInput & { icon?: string; iconProps?: IconProps };

/**
 * Component that displays a radio button and manages it inside a form.
 * @family Forms
 * @param FormRadioButtonProps
 * @returns FormRadioButton
 */
export const FormRadioButton: React.FC<FormRadioButtonProps> = ({
    name,
    controllerProps,
    label,
    icon,
    size = Size.m,
    ...props
}) => {
    const { block } = useClassnames(CLASSNAME);

    const { valueToUse, field, getDataAttributes } = useFormField<string>({
        name,
        controllerProps,
        label,
    });

    return (
        <RadioButton
            {...getDataAttributes({ element: 'radio', action: name, type: props.value })}
            {...props}
            isChecked={valueToUse === props.value}
            name={name}
            /** If icon props is defined,
             * use a Flexbox component as label instead of a string label
             */
            label={
                icon ? (
                    <FlexBox orientation={Orientation.horizontal} hAlign={Alignment.center} gap="big">
                        <Icon
                            icon={icon}
                            hasShape
                            className={margin('left', 'regular')}
                            size={size}
                            {...props.iconProps}
                        />
                        <Text as="span" typography={Typography.subtitle1}>
                            {label}
                        </Text>
                    </FlexBox>
                ) : (
                    label
                )
            }
            onChange={field.onChange}
            className={block({ [`size-${size}`]: Boolean(icon) }, [props.className])}
        />
    );
};
