import includes from 'lodash/includes';

import { RecursivePartial } from '@lumapps/utils/types/RecursivePartial';

import { User } from '../types';

/*
 * Variable used for defining the languages which reverts user's first and last name
 */
const REVERTED_FULL_NAME_LANGS = ['hu', 'ja'];

/**
 * Very partial user definition used to generate the full name.
 */
type PartialUser = RecursivePartial<Pick<User, 'fullName' | 'firstName' | 'lastName' | 'apiProfile'>>;

/**
 * Get the user full name adapted to the current language.
 * TODO BizApps: This logic should be in backend, this is a temporary quickfix that we should not use for too long.
 * See LUM-11591
 *
 * @param  [user] The user or author to return full name from.
 * @param  [language] The current language to know whether we should reverse first and last names
 * @return The formatted user full name.
 */
export function getUserFullName(user: PartialUser | undefined | null, language: string): string {
    if (!user) {
        return '';
    }

    const hasReversedFullName = includes(REVERTED_FULL_NAME_LANGS, language);
    const canComputeName = Boolean(
        user.firstName || user.apiProfile?.name?.givenName || user.lastName || user.apiProfile?.name?.familyName,
    );
    /**
     * If the user fullName exists, it means that the provider returns the right information, simply return it.
     */
    if (user.fullName && (!hasReversedFullName || !canComputeName)) {
        return user.fullName;
    }

    /**
     * If the user fullName doesn't exists, there's no way to get the current lang
     * in the backend and know the reversed names.
     * We use user's firstName and lastName to display the right user fullName.
     */
    const fullNameArray = [];
    fullNameArray.push(user.firstName || user.apiProfile?.name?.givenName);
    fullNameArray.push(user.lastName || user.apiProfile?.name?.familyName);

    return hasReversedFullName ? fullNameArray.reverse().join(' ').trim() : fullNameArray.join(' ').trim();
}
