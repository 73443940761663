import { escapeRegExp } from '@lumapps/utils/string/escapeRegExp';

export const findQueryInText = (text: string, searchQuery: string) => {
    const escapedQuery = escapeRegExp(searchQuery);

    const regex = new RegExp(`(${escapedQuery})`, 'i');

    return text
        .split(regex)
        .filter(Boolean)
        .map((str, i) => {
            return { index: i, str, matches: regex.test(str) };
        });
};
