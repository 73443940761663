import { LikeListParams, LikeState, MinimalUserList, ResourceRef } from './types';

/** Generic toggle like/unlike query function type */
export type FetchToggleLike<T extends string = string> = (
    resourceRef: ResourceRef<T>,
    isLiked: boolean,
) => Promise<LikeState>;

/** Generic fetch like user list function type */
export type FetchLikeUserList<T extends string = string> = (
    resourceRef: ResourceRef<T>,
    params: LikeListParams,
) => Promise<MinimalUserList>;

/**
 * Generic reactions query keys (independent of v1 or v2)
 */
export const reactions = {
    listLikesQueryKeys: ({ resourceType, resourceId }: ResourceRef<string>, pageParams?: LikeListParams) => [
        'reactions',
        resourceType,
        resourceId,
        pageParams,
    ],
};
