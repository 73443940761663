import { Community } from '@lumapps/communities/types';
import { Content } from '@lumapps/contents/types';
import { Instance } from '@lumapps/instance/types';

import { BASIC_CONTENT_TYPES, ContentTypes, CustomContentType, NON_DELETABLE_CONTENT_TYPES } from '../types';

export const isBasicContentType = (type: string) => {
    return BASIC_CONTENT_TYPES.indexOf(type) >= 0;
};

export const isCustomList = (type: string) => {
    return type === ContentTypes.CUSTOM_LIST;
};

export const isDirectory = (type: string) => {
    return type === ContentTypes.DIRECTORY;
};

export const isCommunity = (content?: Content): content is Community => {
    return !!content && content.type === ContentTypes.COMMUNITY;
};

export const isUserDirectory = (type?: string) => {
    return type === ContentTypes.USER_DIRECTORY;
};

export const isContentWithContextualAction = (type: string) => {
    return (
        [
            ContentTypes.CUSTOM,
            ContentTypes.CUSTOM_LIST,
            ContentTypes.DIRECTORY,
            ContentTypes.PAGE,
            ContentTypes.NEWS,
        ] as string[]
    ).includes(type);
};

/**
 * Determines whether a content type is inherited or not.
 * @param type - content type to evaluate
 * @param currentInstance - current instance
 */
export const isCustomContentTypeInherited = (type: CustomContentType, currentInstance: Instance) => {
    return type.instance !== currentInstance.id;
};

/**
 * Determines whether a content type can be deleted
 * @param type - content type to evaluate
 */
export const isCustomContentTypeDeletable = (type: CustomContentType, currentInstance: Instance) => {
    if (!type.functionalInnerId) {
        return true;
    }

    if (isCustomContentTypeInherited(type, currentInstance)) {
        return false;
    }

    return NON_DELETABLE_CONTENT_TYPES.indexOf(type.functionalInnerId) === -1;
};

/**
 * Determines whether CCTs are deletable massively or not.
 * @param types - custom content types
 */
export const areCustomContentTypesDeletable = (types: CustomContentType[], currentInstance: Instance) => {
    return types.filter((cct) => isCustomContentTypeDeletable(cct, currentInstance)).length === types.length;
};

/**
 * Determines whether a content type can be edited
 * @param type - content type to evaluate
 */
export const isCustomContentTypeEditable = (type: CustomContentType, currentInstance: Instance) => {
    return !isCustomContentTypeInherited(type, currentInstance);
};

/**
 * Determines whether CCTs are editable massively or not.
 * @param types - custom content types
 */
export const areCustomContentTypesEditable = (types: CustomContentType[], currentInstance: Instance) => {
    return types.filter((cct) => isCustomContentTypeEditable(cct, currentInstance)).length === types.length;
};

/**
 * Determines whether a content type can be flagged as inheritable or not.
 * @param currentInstance - current instance
 * @param type - content type to evaluate
 */
export const canCustomContentTypeByInherited = (currentInstance: Instance, type?: CustomContentType) => {
    const doesInstanceHaveAParent = Boolean(currentInstance.parent);

    if (type) {
        return !isCustomContentTypeInherited(type, currentInstance) && !doesInstanceHaveAParent;
    }

    return !doesInstanceHaveAParent;
};
