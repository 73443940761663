const didRequestSucceed = (request: XMLHttpRequest) => {
    const { status } = request;

    return status === 200 || status === 304;
};

const didRequestFail = (request: XMLHttpRequest, withCode?: number) => {
    const { status } = request;

    if (withCode) {
        return status === withCode;
    }

    return status === 404 || status >= 500;
};

const didRequestFinish = (request: XMLHttpRequest) => request.readyState === 4;

export { didRequestSucceed, didRequestFail, didRequestFinish };
