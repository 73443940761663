import { connect } from '@lumapps/redux/react';

import WidgetDelve from './WidgetDelve';

import {
    getCommunities,
    getPeople,
    getSharedDocuments,
    getTrendingDocuments,
    getUsedDocuments,
    initState,
    setCurrentlyOpenedPopover,
} from '../../delve/_ducks/delve_action';

/**
 * Map the redux store properties to the WidgetDelve component.
 *
 * @param  {Object} state    The Redux Store.
 * @param  {Object} ownProps The WidgetDelve props.
 * @return {Object} The mapped props.
 */
const mapStateToProps = (state, ownProps) => {
    if (ownProps.uuid) {
        const widgetScope = state.widgets.delve.find((widget) => widget.uuid === ownProps.uuid);

        if (widgetScope !== undefined) {
            return {
                currentlyOpenedPopover: widgetScope.currentlyOpenedPopover,
                documents: widgetScope.documents,
                isLoading: widgetScope.isLoading,
                people: widgetScope.people,
            };
        }
    }

    return {};
};

const ConnectedWidgetDelve = connect(
    mapStateToProps,
    {
        getCommunities,
        getPeople,
        getSharedDocuments,
        getTrendingDocuments,
        getUsedDocuments,
        initState,
        setCurrentlyOpenedPopover,
    },
)(WidgetDelve);

export { ConnectedWidgetDelve as WidgetDelve };
