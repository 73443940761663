import React from 'react';

import { NavigationSkeleton } from '@lumapps/lumx-navigation/components/NavigationSkeleton';
import { ViewMode } from '@lumapps/lumx-navigation/constants';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { CommunityNavigation as CommunityNavigationProps } from '../../types';

/* istanbul ignore next */
const LazyCommunityNavigation = React.lazy(async () => {
    const resolved = await import(
        /* webpackChunkName: 'community-navigation' */
        './CommunityNavigation'
    );

    return { default: resolved.CommunityNavigation };
});

const CommunityNavigation = (props: CommunityNavigationProps) => {
    const { viewMode = ViewMode.vertical } = props;

    return (
        <Suspense loadingFallback={<NavigationSkeleton viewMode={viewMode} />}>
            <LazyCommunityNavigation {...props} />
        </Suspense>
    );
};

export { CommunityNavigation };
