export enum ASK_AI_CONFIGURATIONS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505795448
     */
    CONFIGURATION = 'ASK_AI_CONFIGURATIONS.CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505795456
     */
    NEW_CONFIGURATION_BUTTON_LABEL = 'ASK_AI_CONFIGURATIONS.NEW_CONFIGURATION_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505795463
     */
    NEW_CONFIGURATION_CREATED_NOTIFICATION = 'ASK_AI_CONFIGURATIONS.NEW_CONFIGURATION_CREATED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505795521
     */
    EMPTY_STATE_TITLE = 'ASK_AI_CONFIGURATIONS.EMPTY_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505796977
     */
    EMPTY_STATE_DESCRIPTION = 'ASK_AI_CONFIGURATIONS.EMPTY_STATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505795442
     */
    CONFIGURATIONS = 'ASK_AI_CONFIGURATIONS.CONFIGURATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=507567692
     */
    NEW_CONFIGURATION = 'ASK_AI_CONFIGURATIONS.NEW_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=507570925
     */
    EDIT_CONFIGURATION = 'ASK_AI_CONFIGURATIONS.EDIT_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=507622596
     */
    CONFIGURATION_CREATED_NOTIFICATION = 'ASK_AI_CONFIGURATIONS.CONFIGURATION_CREATED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=507622619
     */
    CONFIGURATION_EDITED_NOTIFICATION = 'ASK_AI_CONFIGURATIONS.CONFIGURATION_EDITED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=508002574
     */
    FORM_GENERAL_SECTION_DESCRIPTION = 'ASK_AI_CONFIGURATIONS.FORM_GENERAL_SECTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=508935104
     */
    FORM_ALREADY_USED_SITES_ERROR = 'ASK_AI_CONFIGURATIONS.FORM_ALREADY_USED_SITES_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509053298
     */
    FORM_REMOVE_SITES = 'ASK_AI_CONFIGURATIONS.FORM_REMOVE_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509667822
     */
    FORM_VISIBILITY_SECTION = 'ASK_AI_CONFIGURATIONS.FORM_VISIBILITY_SECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=512997917
     */
    FORM_GROUPS_PLACEHOLDER = 'ASK_AI_CONFIGURATIONS.FORM_GROUPS_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=511667379
     */
    CONFIGURATION_DELETED_NOTIFICATION = 'ASK_AI_CONFIGURATIONS.CONFIGURATION_DELETED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=511675306
     */
    DELETE_CONFIGURATION = 'ASK_AI_CONFIGURATIONS.DELETE_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=511703394
     */
    CONFIRM_DELETE_CONFIGURATION = 'ASK_AI_CONFIGURATIONS.CONFIRM_DELETE_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530154113
     */
    APPLY_AS_DEFAULT_FOR_ALL_SITES = 'ASK_AI_CONFIGURATION.APPLY_AS_DEFAULT_FOR_ALL_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530154134
     */
    LIMIT_TO_SPECIFIC_SITE_SELECTION = 'ASK_AI_CONFIGURATION.LIMIT_TO_SPECIFIC_SITE_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530744312
     */
    LIMIT_THE_CONFIGURATION_TO_SPECIFIC_SITE_SELECTION = 'ASK_AI_CONFIGURATION.LIMIT_THE_CONFIGURATION_TO_SPECIFIC_SITE_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530221426
     */
    CONFIGURATION_ALREADY_SET_AS_DEFAULT = 'ASK_AI_CONFIGURATION.CONFIGURATION_ALREADY_SET_AS_DEFAULT',
}
