import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { BaseApiError } from '@lumapps/base-api';
import { getServiceServerError } from '@lumapps/base-api/utils/getServerError';
import * as notify from '@lumapps/notifications';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';

import { getArticles } from '../../api/getArticles';
import { Article } from '../../api/types';
import { actions } from '../articlePreviewSlice';
import { articleBatchIdsSelector } from '../selectors';

/**
 * Fetch articles by ids and store them as article entities.
 *
 * @param articleIds article identifiers
 */
export const fetchArticleListByIds = () => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
    const state = getState();
    const articleBatch = articleBatchIdsSelector(state);

    if (!articleBatch || isEmpty(articleBatch)) {
        return;
    }

    dispatch(actions.deleteBatchIdArticle(articleBatch));

    const articleEntities: Record<string, Article | null> = {};

    try {
        const result = await getArticles({ ids: articleBatch });
        const articles = result.items;

        for (const item of articleBatch) {
            const article = find(articles, (a) => a?.id === item);

            articleEntities[item] = article || null;
        }
    } catch (error) {
        if (error instanceof BaseApiError) {
            if (error.response?.status === 404) {
                throw new Error('NOT_FOUND');
            } else {
                dispatch(notify.error({ translate: getServiceServerError(error.response) }));
            }
        }

        for (const item of articleBatch) {
            articleEntities[item] = null;
        }
    } finally {
        dispatch(actions.setArticleEntities(articleEntities));
    }
};
