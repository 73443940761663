import React from 'react';

import { background, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiCheckCircleOutline } from '@lumapps/lumx/icons';
import { Button, ColorPalette, ColorVariant, Icon, Size, Text, Typography } from '@lumapps/lumx/react';
import { Link as RouterLink, Route } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { DISTRIBUTE } from '../../keys';

import './index.scss';

const CLASSNAME = 'distribute-content-published-notification';
const SCOPE = 'distribute-content-published-notification';

interface NotificationActionsProps {
    /** link of the CTA to display distribute form in dialog */
    route: Route;
    /** callback when clicking the link */
    onClick: () => void;
}

/**
 * Actions displayed in extended notification when a piece of content is published
 */
export const NotificationActions: React.FC<NotificationActionsProps> = ({ route, onClick }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    return (
        <Button linkAs={RouterLink} to={route} onClick={onClick} {...get({ element: 'link', action: 'open-form' })}>
            {translateKey(DISTRIBUTE.DISTRIBUTE_CONTENT)}
        </Button>
    );
};

/**
 * Content of the extended notification when a piece of content is published
 */
export const NotificationContent = () => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(SCOPE);

    return (
        <Text
            as="span"
            typography={Typography.body1}
            color={ColorPalette.dark}
            colorVariant={ColorVariant.L2}
            className={element('content')}
            {...get({ element: 'description' })}
        >
            {translateKey(DISTRIBUTE.NOTIFICATION_DISTRIBUTE_CONTENT_DESCRIPTION)}
        </Text>
    );
};

/**
 * Icon displayed in the extended notification
 */
export const NotificationFigure = () => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <div className={element('figure', [padding('all', Size.big), background(ColorPalette.green, ColorVariant.L4)])}>
            <Icon icon={mdiCheckCircleOutline} size={Size.m} color={ColorPalette.green} />
        </div>
    );
};
