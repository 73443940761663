import React, { useEffect } from 'react';

import { get as getConfig } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { get } from '@lumapps/user-directory/api';

import { User, UserAccountType } from '../../types';

export interface UseUserCardPopoverOptions {
    /** name of the user to display */
    name?: string;
    /** email of the user to display */
    email?: string;
    /** id of the user to display */
    id?: string;
    /**
     * The delay in ms before the popover should open / close
     *
     * Default: 500
     */
    delay?: number;
}

const useUserCardPopover = ({ name, email, id, delay = 500 }: UseUserCardPopoverOptions) => {
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const [showUserCard, setShowUserCard] = React.useState<boolean>(false);
    const [isLoadingUser, setIsLoadingUser] = React.useState<boolean>(false);
    const [userProfile, setUserProfile] = React.useState<User>({
        accountType: UserAccountType.GOOGLE,
        fullName: name || '',
        id: id || '',
        email: email || '',
        lastName: '',
        firstName: '',
    });
    const isWebview = getConfig().applicationId === AppId.webview;
    // Disable on webview app or if neither the id nor the email is provided.
    const isUserCardDisplayable = !isWebview && (Boolean(email) || Boolean(id));

    /**
     * Set the user as loading then fetch its information from API.
     */
    const fetchUserInfo = React.useCallback(() => {
        setIsLoadingUser(true);

        get({ email, uid: id, cacheFirst: true })
            .then((response) => {
                setUserProfile(response.data);
            })
            .finally(() => {
                setIsLoadingUser(false);
            });
    }, [email, id]);

    const onMouseEnter = () => {
        setIsHovered(true);
    };

    const onMouseLeave = () => {
        setIsHovered(false);
    };

    /**
     * When either the trigger element or the popover itself hover status changes,
     * change status after given delay and clear previous timeout.
     */
    useEffect(() => {
        const openTimer = setTimeout(() => {
            if (isUserCardDisplayable) {
                setShowUserCard(isHovered);
            }
        }, delay);
        // clear timeout
        return () => clearTimeout(openTimer);
    }, [isHovered, isUserCardDisplayable, delay]);

    /**
     * Fetch the user info when card is shown.
     */
    useEffect(() => {
        if (showUserCard) {
            fetchUserInfo();
        }
    }, [fetchUserInfo, showUserCard]);

    return {
        onMouseLeave,
        onMouseEnter,
        onUserCardMouseLeave: onMouseLeave,
        onUserCardMouseEnter: onMouseEnter,
        isLoadingUser,
        userProfile,
        showUserCard,
    };
};

export { useUserCardPopover };
