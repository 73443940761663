import { createEvent } from '@lumapps/utils/event/createEvent';

import { Cache } from '../types';

const storePersistent = (key: string, value: string) => {
    const event = createEvent('cacheChange');
    document.dispatchEvent(event);
    localStorage.setItem(key, value);
};

const storeObjectPersistent = (key: string, value: [] | object) => {
    storePersistent(key, JSON.stringify(value));
};

const findInPersistentStore = (key: string) => {
    return localStorage.getItem(key);
};

const findObjectInPersistentStore = <T>(key: string): T | null => {
    const item = findInPersistentStore(key);

    if (item) {
        return JSON.parse(item);
    }

    return null;
};

const removePersistent = (key: string) => {
    localStorage.removeItem(key);
};

const persistentCache: Cache = {
    store: (key: string, value: any) => {
        try {
            storePersistent(key, JSON.stringify(value));
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.warn('error while saving this value to local storage', value);
        }
    },
    remove: (key: string) => {
        try {
            removePersistent(key);
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.warn('error while deleting this key from local storage', key);
        }

        return null;
    },
    retrieve: (key: string) => {
        try {
            const item = findInPersistentStore(key);

            if (item) {
                return JSON.parse(item);
            }
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.warn('error while retrieving this key from local storage', key);
        }

        return null;
    },
    clearAll: () => {
        // TODO: implement this method.
    },
};

export {
    storePersistent,
    storeObjectPersistent,
    findInPersistentStore,
    findObjectInPersistentStore,
    removePersistent,
    persistentCache,
};
