import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { SORT_ORDER } from '@lumapps/lumx-filter-and-sort/constants';
import { encodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { TAGZ_NUMBER_TAGZ_FOR_SECTION, TAGZ_NUMBER_TAGZ_FOR_ALL_TAGS_SECTION, TAG_RECENT_SORT } from '../constants';
import { TagListQuery, TagListAllResponse, Tag, TagDetailsQuery, TagDetailsResponse } from '../types';

export const tagzApi = new BaseApi({
    path: 'tagz',
    version: BaseApi.versions.v2,
});

export interface SuggestAPIParams {
    query: string;
    params?: {
        limit: number;
        tagz: string;
    };
}

/**
 * Return a list of tags matching the suggestion. Tags with the highest occurence appeared first.
 * should looks like /v2/organizations/{orgID}/tagz/suggest/{query}
 * @param  {SuggestAPIParams}  params The parameters to pass to the search endpoint.
 * @return {Promise} The promise of the search call.
 */
export const suggestTag = ({ query = '', params }: SuggestAPIParams) => {
    return tagzApi
        .getCacheFirst(`suggest/${query}`, CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params }, undefined, true)
        .then((response) => {
            const { data } = response;

            return data || [];
        });
};

/**
 * Cancel any call to suggestion api with given params.
 */
export const cancelSuggestTag = ({ query = '', params }: SuggestAPIParams) => {
    return tagzApi.cancel(`suggest/${query}`, params);
};

/**
 * Get all tags paginated
 * @param param0 params TagListQuery
 */
export const getAllTagz = ({
    sort,
    order,
    page = 0,
    limit = TAGZ_NUMBER_TAGZ_FOR_ALL_TAGS_SECTION,
    query,
}: TagListQuery): Promise<TagListAllResponse> => {
    return tagzApi
        .get<TagListAllResponse>('all', {
            params: {
                sort,
                order: order === SORT_ORDER.DECREASING ? 'desc' : 'asc',
                limit,
                page,
                query,
            },
        })
        .then((response) => {
            const { data } = response;

            return {
                ...data,
                tags: data.tags || [],
            };
        });
};

/**
 * Get all trending tags
 * @param param0 Params
 */
export const getTrendingTagz = ({ limit = TAGZ_NUMBER_TAGZ_FOR_SECTION }: TagListQuery): Promise<Tag[]> => {
    return tagzApi
        .get<TagListAllResponse>('trending', {
            params: {
                limit,
            },
        })
        .then((response) => {
            const { data } = response;

            return data.tags || [];
        });
};

/**
 * Get all recent tags
 * @param param0 Params
 */
export const getRecentTagz = ({
    startDate,
    endDate,
    limit = TAGZ_NUMBER_TAGZ_FOR_SECTION,
    sort = TAG_RECENT_SORT,
    order = SORT_ORDER.DECREASING,
}: TagListQuery): Promise<Tag[]> => {
    return tagzApi
        .get<TagListAllResponse>('all', {
            params: {
                limit,
                startDate,
                endDate,
                sort,
                order: order === SORT_ORDER.DECREASING ? 'desc' : 'asc',
            },
        })
        .then((response) => {
            const { data } = response;

            return data.tags || [];
        });
};

/**
 * Get all informations about a specific Tag
 * @param param TagId
 */
export const getTagDetails = ({ tagId }: TagDetailsQuery): Promise<TagDetailsResponse> => {
    return tagzApi.get<TagDetailsResponse>(`details/${encodeURIComponentSafe(tagId)}`).then((response) => {
        const { data } = response;

        return data;
    });
};
