(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetDriveController($scope, $window, Analytics, Drive, Instance, Media, Translation,
        WidgetDrive) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The base url of Google Drive files.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        var _DRIVE_BASE_URL = 'https://drive.google.com';

        /**
         * The identifier of the folder currently displayed to the user.
         *
         * @type {string}
         */
        var _currentFolderId;

        /**
         * The current order settings of the files within the list currently displayed.
         *
         * @type {Object}
         */
        var _orderBy = {
            field: 'title',
            type: undefined,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The list of folder identifiers that have been viewed by the user since the initial load of the widget, in
         * chronological order. This is used as a history stack to navigate back up the tree structure.
         *
         * @type {Array}
         */
        vm.browsedFolderIds = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Drive = Drive;
        vm.Media = Media;
        vm.Translation = Translation;
        vm.moment = moment;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the widget properties and set some default values.
         */
        function _initProperties() {
            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            if (angular.isUndefined(properties.isRootFolder)) {
                properties.isRootFolder = true;
            }

            if (angular.isUndefined(properties.more)) {
                properties.more = {
                    label: {},
                    link: {},
                };
            }
        }

        /**
         * Load the files of a given folder.
         *
         * @param {string} folderId The identifier of the folder to load.
         * @param {Object} orderBy  The order settings to apply when loading the folder.
         */
        function _loadFolder(folderId, orderBy) {
            var params = {
                ignore403: true,
            };
            var order = orderBy.field;
            var listKey = vm.parentCtrl.getListKey();

            if (angular.isDefined(orderBy.type)) {
                order = order + ' ' + orderBy.type;
            }

            params.folderId = folderId;
            params.maxResults = (angular.isDefinedAndFilled(vm.widget.properties.number)) ?
                vm.widget.properties.number : undefined;
            params.orderBy = order;

            Drive.filterize(params, function onDriveListSuccess() {
                _currentFolderId = folderId;
            }, undefined, listKey);

            if (angular.isDefinedAndFilled(folderId)) {
                Drive.get({
                    fileId: folderId,
                    ignore403: true,
                }, undefined, undefined, listKey);
            } else {
                Drive.setCurrent(undefined, listKey);
            }
        }

        /**
         * Adds a new folder to the history stack and load it immediately.
         *
         * @param {string} [folderId] The identifier of the folder to load. If undefined, use the root folder.
         */
        function _loadNextFolder(folderId) {
            vm.browsedFolderIds.push(folderId);
            _loadFolder(folderId, _orderBy);
        }

        /**
         * Update the 'more' link according to selected folder.
         */
        function _updateMoreLink() {
            var widgetFolderId = vm.widget.properties.folderId;

            angular.forEach(Instance.getInstance().langs, function forEachInstanceLang(lang) {
                var currentLangMoreLink = _.get(vm.widget, 'properties.more.link.' + lang);

                if (angular.isUndefinedOrEmpty(currentLangMoreLink) ||
                    _.includes(currentLangMoreLink, _DRIVE_BASE_URL)) {
                    if (angular.isDefined(widgetFolderId) && angular.isDefinedAndFilled(widgetFolderId[lang])) {
                        _.set(vm.widget, 'properties.more.link' + lang,
                            _DRIVE_BASE_URL + '/drive/folders/' + widgetFolderId[lang]);
                    } else {
                        _.set(vm.widget, 'properties.more.link' + lang, _DRIVE_BASE_URL);
                    }
                }
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Check if the widget is empty.
         *
         * Used by the designer mode.
         *
         * @return {boolean} Whether the widget is empty or not.
         */
        function isWidgetEmpty() {
            var key = vm.parentCtrl.getListKey();

            return !Drive.isCallInProgress(key) && angular.isUndefinedOrEmpty(Drive.displayList(key)) &&
                vm.browsedFolderIds.length <= 1;
        }

        /**
         * Indicates if the widget is hidden or not.
         *
         * This is used in reading mode.
         *
         * @return {boolean} Whether the widget is hidden or not.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = (!vm.parentCtrl.designerMode() && !vm.widget.properties.noResults &&
                vm.isWidgetEmpty());

            return vm.parentCtrl.isHidden;
        }

        /**
         * Load the previous folder in the history stack.
         */
        function loadPreviousFolder() {
            vm.browsedFolderIds.pop();
            _loadFolder(_.last(vm.browsedFolderIds), _orderBy);
        }

        /**
         * Navigate into a folder or open a file in a new window.
         *
         * @param {Object} file The file or folder to open.
         */
        function openFileOrFolder({ file }) {
            if (angular.isUndefinedOrEmpty(file)) {
                return;
            }

            if (file.isFolder) {
                _loadNextFolder(file.id);
            } else {
                Drive.openFile({ file });
            }

            Analytics.handleTaggingMap('open-drive-element', 'open-drive-element', {
                item: file,
            });
        }

        /**
         * Toggle the sort order on a given field name.
         *
         * @param {string} fieldName The name of the field to sort by.
         */
        function toggleSortOrder(fieldName) {
            var folderId = _currentFolderId || WidgetDrive.getFolderLangProperty(vm.widget.properties, 'folderId');

            _orderBy.field = fieldName;
            _orderBy.type = (angular.isDefined(_orderBy.type)) ? undefined : 'desc';

            _loadFolder(folderId, _orderBy);
        }

        /////////////////////////////

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.loadPreviousFolder = loadPreviousFolder;
        vm.openFileOrFolder = openFileOrFolder;
        vm.toggleSortOrder = toggleSortOrder;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Whenever the widget drive settings are updated, reload the folder.
         *
         * @param {Event}  evt  The original event triggering this method.
         * @param {string} uuid The identifier of the widget drive that just got updated.
         */
        $scope.$on('widget-drive-settings', function onWidgetDriveSettingsChange(evt, uuid) {
            if (vm.widget.uuid === uuid) {
                _updateMoreLink();

                _loadNextFolder(Translation.translate(_.get(vm.widget, 'properties.folderId')));
            }
        });

        /////////////////////////////

        /**
         * Initialize controller.
         */
        vm.init = function init() {
            _initProperties();

            _updateMoreLink();

            _loadNextFolder(Translation.translate(_.get(vm.widget, 'properties.folderId')));
        };

        /**
         * Set the parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    /////////////////////////////

    /**
     * The widget drive directive.
     * Displays a simple list of files from a given folder in Google Drive.
     *
     * Note: do not confuse it with WidgetFileManagement which is the 'Advanced' Widget Drive (where you can create new
     * files for example). Ideally, these two will get merged at some point OR will be super-seeded by WidgetFileList
     * which aims to reconcile functionality from both of the Drive widgets + introduce the 'lumapps' library factor to
     * it.
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetDriveDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetDriveController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetDrive', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-drive/views/widget-drive.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetDrive', WidgetDriveDirective);
})();
