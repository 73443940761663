(function IIFE() {
    'use strict';

    /////////////////////////////

    function FavoriteFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/favorite/:methodKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'having_target',
                },
            },

            'list': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'targets',
                },
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'mark',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('FavoriteFactory', FavoriteFactory);
})();
