import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';

import { DefaultContentList } from '../types';

export const contentListsApiV2 = new BaseApi({
    version: BaseApi.versions.v2,
    path: 'content-lists',
});

export type GetDefaultContentListsParams = { siteIds: string | string[] };
export type GetDefaultContentListsResponse = {
    items: DefaultContentList[];
    more: boolean;
};

/** Get default content lists of site */
export const getDefaultContentLists = async (params: GetDefaultContentListsParams) => {
    return contentListsApiV2
        .getCacheFirst<GetDefaultContentListsResponse>('default', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params })
        .then((response) => response.data);
};
