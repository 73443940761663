/* istanbul ignore file */
import { useCallback, useState, useEffect } from 'react';

import debounce from 'lodash/debounce';

export function useValidationState<T>(value: T, predicate: (value: T) => boolean) {
    const [isValid, setValidity] = useState(false);
    const [isDirty, setDirty] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetValidity = useCallback(
        debounce((val: boolean) => {
            setValidity(val);
        }, 500),
        [],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetDirty = useCallback(
        debounce(() => {
            setDirty(true);
        }, 600),
        [],
    );

    useEffect(() => {
        debouncedSetValidity(predicate(value));

        return () => {
            debouncedSetValidity.cancel();
        };
    }, [debouncedSetValidity, predicate, value]);

    useEffect(() => {
        if (value) {
            debouncedSetDirty();
        }

        return () => {
            debouncedSetDirty.cancel();
        };
    }, [debouncedSetDirty, value]);

    return {
        isValid,
        isDirty,
    };
}
