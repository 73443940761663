import assign from 'lodash/assign';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

/////////////////////////////

function StyleService(
    $injector,
    $q,
    ConfigTheme,
    Customer,
    Instance,
    LumsitesBaseService,
    Media,
    ReduxStore,
    StyleFactory,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(StyleFactory, {
        autoInit: false,
        fullListResponse: true,
        objectIdentifier: 'uid',
        // eslint-disable-next-line no-use-before-define
        preSave: _deleteMainMargins,
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default opacity for the shadow.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_OPACITY = 14;

    /**
     * The maximum number of results to get.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_STYLES_RESULTS = 30;

    /**
     * The projections that can be used for the styles.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _PROJECTIONS = {
        list: {
            id: true,
            instance: true,
            isDefault: true,
            name: true,
            properties: true,
            stylesheets: true,
            type: true,
            uuid: true,
        },
    };

    /**
     * The current instance specific style.
     *
     * @type {string}
     */
    let _defaultInstanceStyle;

    /**
     * The global style object for the current instance.
     *
     * @type {Object}
     */
    let _instanceGlobalStyle;

    /**
     * The global style object for the current instance.
     *
     * @type {Object}
     */
    let _widgetsGlobalStyle = [];

    /**
     * The style object for the parent of the current instance.
     *
     * @type {Object}
     */
    let _instanceParentStyle;

    /**
     * The style id for the parent of the current instance.
     *
     * @type {string}
     */
    let _instanceParentStyleId;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The default parameters for the service requests.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     * The different types of styles.
     *
     * @type {Object}
     */
    service.types = {
        customer: 'globalCustomer',
        instance: 'global',
        parent: 'parent',
        widget: 'widget',
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Compare values in an array.
     *
     * @param  {Array}   values The values to compare.
     * @return {boolean} Whether the values in the array are all equals.
     */
    function _areValuesAllEquals(values) {
        return values.reduce(function reduce(acc, value) {
            return value === first(values);
        });
    }

    /**
     * Clean the global properties if specific values are different.
     * Used when properties have been set in normal mode with the lock and specific values are set in hovering mode.
     *
     * @param  {Object} style The merged style with normal and hovering attributes.
     * @return {Object} The updated style.
     */
    function _cleanHoverStyle(style) {
        if (
            !_areValuesAllEquals([
                style.wrapper.borderBottomWidth,
                style.wrapper.borderLeftWidth,
                style.wrapper.borderRightWidth,
                style.wrapper.borderTopWidth,
            ])
        ) {
            delete style.wrapper.borderWidth;
        }

        if (
            !_areValuesAllEquals([
                style.wrapper.borderBottomColor,
                style.wrapper.borderLeftColor,
                style.wrapper.borderRightColor,
                style.wrapper.borderTopColor,
            ])
        ) {
            delete style.wrapper.borderColor;
        }

        if (
            !_areValuesAllEquals([
                style.wrapper.borderBottomLeftRadius,
                style.wrapper.borderBottomRightRadius,
                style.wrapper.borderTopLeftRadius,
                style.wrapper.borderTopRightRadius,
            ])
        ) {
            delete style.wrapper.borderRadius;
        }

        if (
            !_areValuesAllEquals([
                style.wrapper.marginBottom,
                style.wrapper.marginLeft,
                style.wrapper.marginRight,
                style.wrapper.marginTop,
            ])
        ) {
            delete style.wrapper.margin;
        }

        if (
            !_areValuesAllEquals([
                style.wrapper.paddingBottom,
                style.wrapper.paddingLeft,
                style.wrapper.paddingRight,
                style.wrapper.paddingTop,
            ])
        ) {
            delete style.wrapper.padding;
        }

        return style;
    }

    /**
     * Compute the colors of the style.
     *
     * @param {Function} resolve The function to use to resolve the promise.
     */
    function _computeColors(resolve) {
        if (angular.isUndefinedOrEmpty(_instanceGlobalStyle)) {
            resolve();

            return;
        }

        const colors = get(_instanceGlobalStyle.properties, 'colors', []);

        if (!includes(colors, 'transparent')) {
            colors.unshift('transparent');
        }

        resolve();
    }

    /**
     * Computes the instance global style and colors.
     *
     * @param {Function} resolve The resolve function to call once global styles and color are fully computed.
     * @param {Function} reject  The reject function to call when an error is catched.
     */
    function _computeGlobalStyle(resolve, reject) {
        // If we didn't compute the global style yet.
        if (angular.isUndefinedOrEmpty(_instanceGlobalStyle)) {
            const currentInstance = Instance.getInstance();
            const defaultInstanceStyleId = get(_defaultInstanceStyle, 'id');
            const instanceStyle = get(currentInstance, 'style', {});

            /*
             * In the case where the style attached to the instance is a platform style, we need to get
             * the style twice, the first is to instanciate the '_instanceGlobalStyle' with the site style
             * and the second is to insert the current instance style with the proper list key.
             *
             * If no style is attached to the current instance, we set the default style as current style.
             */
            if (angular.isUndefinedOrEmpty([instanceStyle, defaultInstanceStyleId], 'every')) {
                service.setCurrent(service.getEmptyGlobalStyle(), service.types.instance);
                resolve();

                return;
            }

            // If there's no default style or if the default style is used by the current instance.
            if (angular.isUndefinedOrEmpty(defaultInstanceStyleId) || defaultInstanceStyleId === instanceStyle) {
                // The current style attached to the instance is a site style.
                service.get(
                    instanceStyle,
                    function onStyleGetSuccess(response) {
                        _instanceGlobalStyle = angular.fastCopy(response);
                        _computeColors(resolve);
                    },
                    reject,
                    service.types.instance,
                );

                return;
            }

            service.get(
                defaultInstanceStyleId,
                function onStyleGetSuccess(response) {
                    _instanceGlobalStyle = angular.fastCopy(response);
                    _computeColors(resolve);
                },
                reject,
                service.types.instance,
            );

            // Put the style attached (which is plateform) to the instance in the proper list key.
            service.get(instanceStyle, undefined, reject, service.types.instance);

            return;
        }

        _computeColors(resolve);
    }

    /**
     * Compute each instance styles.
     *
     * @param {Array} styles A list of styles to compute.
     */
    function _computeStyles(styles) {
        if (angular.isUndefinedOrEmpty(styles)) {
            return;
        }

        const currentInstance = Instance.getInstance();
        const instanceStyle = get(currentInstance, 'style', {});

        angular.forEach(styles, function forEachStyle(style) {

            // If the style.status is DELETED, we don't want to display it in the style list.
            if (style.status === 'LIVE') {
                // Init the default properties.
                style.properties = style.properties || {};
                style.properties.mainNav = style.properties.mainNav || {};
                style.properties.top = style.properties.top || {};
                style.properties.search = style.properties.search || {};

                // Instance OR Customer style.
                if (style.type === service.types.instance) {
                    service._services[service.types.instance]._list.push(angular.fastCopy(style));

                    // Save the instance style even if the current style apply to the instance is a platform style.
                    if (style.instance === currentInstance.uid) {
                        _defaultInstanceStyle = style;
                    }

                    // If style.instance is undefined, it's a platform style and we add it to the list.
                    if (angular.isUndefinedOrEmpty(style.instance)) {
                        service._services[service.types.customer]._list.push(angular.fastCopy(style));
                    }

                    // The current style is given by the instanceStyle.
                    if (instanceStyle === style.uid) {
                        _instanceGlobalStyle = angular.fastCopy(style);
                        service.setCurrent(_instanceGlobalStyle, service.types.instance);
                    }

                    // Instance parent style (if we have it).
                    if (_instanceParentStyleId === style.uid) {
                        _instanceParentStyle = angular.fastCopy(style);
                    }

                    // Widget styles.
                } else if (style.type === service.types.widget) {
                    service._services[service.types.widget]._list.push(angular.fastCopy(style));
                    _widgetsGlobalStyle[style.uid] = angular.fastCopy(style);
                }
            }
        });
    }

    /**
     * Delete main margin before saving global style.
     *
     * @param  {Object} style The style we want to save.
     * @return {Object} The style without main margins.
     */
    function _deleteMainMargins(style) {
        if (angular.isUndefinedOrEmpty(style.properties.main)) {
            return style;
        }

        delete style.properties.main.marginLeft;
        delete style.properties.main.marginRight;
        delete style.properties.main.marginTop;
        delete style.properties.main.marginBottom;
        delete style.properties.main.margin;

        return style;
    }

    /**
     * Get all styles asynchronously.
     *
     * @return {Promise} A promise that will resolve once all styles are loaded.
     */
    function _getAsyncStyles() {
        return $q(function defer(resolve, reject) {
            service.getAllPages(
                {
                    getAll: true,
                    instance: Instance.getCurrentInstanceId(),
                    maxResults: _MAX_STYLES_RESULTS,
                },
                _computeStyles,
                function onStyleListSuccess() {
                    _computeGlobalStyle(resolve, reject);
                },
                reject,
                Instance.getCurrentInstanceId(),
                _PROJECTIONS.list,
            );
        });
    }

    /**
     * Get styles synchronously by treating the `styles` array given by the `service.init` api call.
     *
     * @param  {Array}   styles The array of styles to compute.
     * @return {Promise} A promise that will resolve once all styles are loaded.
     */
    function _getSyncStyles(styles) {
        _computeStyles(styles);

        return $q(function defer(resolve, reject) {
            _computeGlobalStyle(resolve, reject);
        });
    }

    /**
     * Gets the footer style.
     *
     * @param  {Object}  style      The widget footer style to compute.
     * @param  {boolean} isHovering Idincates whether the footer is hover or not.
     * @return {Object}  The footer style.
     */
    function _getWidgetFooterStyle(style, isHovering) {
        return isHovering ? _cleanHoverStyle(angular.merge({}, style, style.hover || {})) : style;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get a new global style.
     *
     * @return {Object} A new global style object.
     */
    function getEmptyGlobalStyle() {
        // Make sure 'transparent' is the first 'color' in the array.
        const colors = ['transparent'].concat(angular.fastCopy(ConfigTheme.COLORS) || []);

        return {
            customer: Customer.getCustomerId(),
            properties: {
                accent: ConfigTheme.COLORS_ACCENT,
                colors,
                mainNav: {},
                primary: ConfigTheme.COLORS_PRIMARY,
                search: {},
                top: {},
            },
            type: service.types.instance,
        };
    }

    /**
     * Get specific styles for widget list blocks. Can be either `community-block`, `directory-entry-block` or
     * `post-block` directives.
     *
     * Note: This function should be placed in a `WidgetStyleUtils` service.
     *
     * @param  {Object} widgetProperties The widget overall properties.
     * @param  {string} viewMode         The widget list view mode (list, grid...).
     * @param  {string} viewModeVariant  The widget list view mode variant (group, ungroup...).
     * @return {Object} The correctly formated style object.
     */
    function getBlockStyle(widgetProperties, viewMode, viewModeVariant) {
        const blockStyle = {};

        if (viewMode === 'list') {
            const margin = widgetProperties.itemsMargin;
            if (angular.isDefinedAndFilled(margin)) {
                blockStyle.marginBottom = `${margin}px`;

                if (viewModeVariant === 'group') {
                    blockStyle.paddingBottom = `${margin}px`;
                }
            }

            if (!widgetProperties.itemsSeparator) {
                blockStyle.border = 'none';
            }
        }

        if (viewMode === 'cascade' || viewModeVariant === 'ungroup') {
            if (!widgetProperties.isBackwardedStyle) {
                assign(
                    blockStyle,
                    pick(widgetProperties.style.content, [
                        'padding',
                        'paddingTop',
                        'paddingBottom',
                        'paddingLeft',
                        'paddingRight',
                    ]),
                );
            }

            assign(
                blockStyle,
                pick(widgetProperties.style.content, [
                    'backgroundColor',
                    'borderRadius',
                    'borderTopLeftRadius',
                    'borderTopRightRadius',
                    'borderBottomLeftRadius',
                    'borderBottomRightRadius',
                    'borderWidth',
                    'borderTopWidth',
                    'borderRightWidth',
                    'borderBottomWidth',
                    'borderLeftWidth',
                    'borderColor',
                    'borderBottomColor',
                    'borderLeftColor',
                    'borderRightColor',
                    'borderTopColor',
                ]),
            );

            if (viewModeVariant === 'ungroup') {
                assign(blockStyle, pick(widgetProperties.style.content, ['shadowElevation', 'shadowOpacity']));
            }
        }

        return blockStyle;
    }

    /**
     * Returns a `box-shadow` CSS value for a given `elevation` and `opacity`.
     *
     * @param  {number} [elevation=0]      The elevation.
     * @param  {number} [opacity=14]       The opacity between 0 and 100.
     * @param  {number} [defaultElevation] The default elevation to use if no elevation set.
     * @return {string} The shadow CSS string.
     */
    function getShadow(elevation, opacity, defaultElevation) {
        opacity = angular.isDefined(opacity) ? opacity : _DEFAULT_OPACITY;
        // eslint-disable-next-line no-magic-numbers
        const formattedOpacity = (opacity / 100).toFixed(2);
        elevation = angular.isDefinedAndFilled(elevation) ? elevation : defaultElevation;

        switch (elevation) {
            case 0:
                return 'none';

            case 1:
                return `0 2px 4px rgba(0,0,0,${formattedOpacity})`;

            // eslint-disable-next-line no-magic-numbers
            case 2:
                return `0 4px 8px rgba(0,0,0,${formattedOpacity})`;

            // eslint-disable-next-line no-magic-numbers
            case 3:
                return `0 8px 16px rgba(0,0,0,${formattedOpacity})`;

            // eslint-disable-next-line no-magic-numbers
            case 4:
                return `0 16px 32px rgba(0,0,0,${formattedOpacity})`;

            // eslint-disable-next-line no-magic-numbers
            case 5:
                return `0 32px 64px rgba(0,0,0,${formattedOpacity})`;

            case null:
            case undefined:
            // eslint-disable-next-line padding-line-between-statements, no-fallthrough
            default:
                return '';
        }
    }

    /**
     * Returns a new style object with the `shadowElevation` and `shadowOpacity`
     * transformed into a `boxShadow` property.
     *
     * @param  {Object} style The style object.
     * @return {Object} A new object if `shadowElevation` and `shadowOpacity` are set,
     *                  `style` otherwise.
     */
    function adjustShadow(style) {
        return assign({}, omit(style, ['shadowElevation', 'shadowOpacity']), {
            boxShadow: service.getShadow(style.shadowElevation, style.shadowOpacity, style.defaultShadowElevation),
        });
    }

    /**
     * Removes left/right margins/paddings on small screens.
     *
     * @param  {Object} style The style object.
     * @return {Object} A new style object with the properties omitted
     *                  if displayed on small screen,
     *                  the same object otherwise.
     */
    function adjustSpacings(style) {
        if ($injector.get('Layout').breakpoint === 'desk') {
            return style || {};
        }

        return omit(style, ['marginLeft', 'marginRight', 'paddingLeft', 'paddingRight']);
    }

    /**
     * Compute the style, adjust the shadow and the background image.
     *
     * @param  {Object}  style                The style we want to compute.
     * @param  {boolean} [isAdjustBackground] Indicates whether we need to adjust the background attributes.
     * @return {Object}  The adjusted style.
     */
    function adjustStyle(style, isAdjustBackground) {
        return service.adjustShadow(isAdjustBackground ? Media.adjustBackgroundImage(style) : style);
    }

    /**
     * Compute the widget footer style.
     *
     * @param  {Object}  style      The widget footer style.
     * @param  {boolean} isHovering Whether the widget is hoverred or not.
     * @param  {string}  area       The widget footer area.
     * @param  {boolean} [isAdjust] Whether the style need to be adjsted or not.
     * @return {Object}  The footer style corresponding to the wanted area.
     */
    function adjustFooterStyle(style, isHovering, area, isAdjust) {
        const areaStyle = angular.extend(angular.fastCopy(_getWidgetFooterStyle(style, isHovering)[area])) || {};

        return isAdjust ? service.adjustShadow(Media.adjustBackgroundImage(areaStyle)) : areaStyle;
    }

    /**
     * Filter out deprecated properties from the global style object
     * This is currently used to remove the fullHeight property that is no longer available in the global style
     *
     * @param  {Object}  style The widget global style.
     * @return {Object}  The global style corresponding to the wanted area.
     */
    function cleanWidgetGlobalStyle(style) {
        /**
         * The fullHeight properties should not be stored in the global style.
         * This a property that should not be compatible with the global style feature.
         * */
        if (angular.isDefined(style?.properties?.content?.fullHeight)) {
            delete style.properties.content.fullHeight;
        }

        return style;
    }

    /**
     * Get the specific style of the current intance.
     *
     * @return {Object} The style object.
     */
    function getInstanceDefaultStyle() {
        return _defaultInstanceStyle;
    }

    /**
     * Get global style used by the current instance.
     *
     * @return {Object} The global style object used by the current instance.
     */
    function getInstanceGlobalStyle() {
        return _instanceGlobalStyle;
    }

    /**
     * Get global style used by the current instance.
     *
     * @return {Object} The global style object used by the current instance.
     */
    function getWidgetsGlobalStyle() {
        return _widgetsGlobalStyle;
    }

    /**
     * Get the current instance parent style.
     *
     * @return {Object} The parent style object for the current instance.
     */
    function getInstanceParentStyle() {
        return _instanceParentStyle;
    }

    /**
     * Get widget content style according to widget properties.
     *
     * @param  {Object}  properties The current widget properties.
     * @param  {boolean} useOmit    Whether we want to `omit` keys instead of `pick`.
     * @return {Object}  The widget content style.
     */
    function getWidgetContentStyle(properties, useOmit = true) {
        let propertiesToOmitOrPick = ['fullHeight'];
        const omitOrPick = useOmit ? omit : pick;

        if (properties.viewMode === 'cascade' || properties.viewModeVariant === 'ungroup' || !useOmit) {
            propertiesToOmitOrPick = propertiesToOmitOrPick.concat([
                'backgroundColor',
                'backgroundImage',
                'backgroundPosition',
                'shadowElevation',
                'shadowOpacity',
                'borderWidth',
                'borderTopWidth',
                'borderRightWidth',
                'borderBottomWidth',
                'borderLeftWidth',
                'borderColor',
                'borderTopColor',
                'borderRightColor',
                'borderBottomColor',
                'borderLeftColor',
            ]);

            if (!properties.isBackwardedStyle) {
                propertiesToOmitOrPick = propertiesToOmitOrPick.concat(
                    'padding',
                    'paddingTop',
                    'paddingBottom',
                    'paddingLeft',
                    'paddingRight',
                    'backgroundColor',
                );
            }
        }

        return service.adjustShadow(
            Media.adjustBackgroundImage(omitOrPick(get(properties, 'style.content'), propertiesToOmitOrPick)),
        );
    }

    /**
     * Check if style is available for the customer (== platform).
     *
     * @param  {Object|string} style   The style object to check.
     * @param  {string}        listKey The list key to use to compare the style with.
     * @return {boolean}       Whether or not the style is a customer style.
     */
    function isCustomer(style, listKey) {
        return !service.isInstance(style, listKey);
    }

    /**
     * Check if style is available for the instance (== site).
     *
     * @param  {Object|string} style   The style object to check.
     * @param  {string}        listKey The list key to use to compare the style with.
     * @return {boolean}       Whether or not the style is an instance style.
     */
    function isInstance(style, listKey) {
        if (angular.isObject(style)) {
            return Boolean(style.instance);
        }

        const styleList = service.displayList(listKey);
        if (angular.isArray(styleList)) {
            const styleListItem = loFind(styleList, {
                id: style,
            });

            if (angular.isUndefinedOrEmpty(styleListItem)) {
                return false;
            }

            return angular.isDefinedAndFilled(styleListItem.instance);
        }

        return false;
    }

    /**
     * Should retrun the service data that need to be synced with redux.
     *
     * @return {Object} The redux's store shape.
     */
    function mapStateToRedux() {
        // eslint-disable-next-line no-shadow
        const global = getInstanceGlobalStyle();
        const widgets = getWidgetsGlobalStyle();
        const parent = getInstanceParentStyle();

        const styles = {
            global,
            widgets,
        };

        if (angular.isDefinedAndFilled(parent)) {
            styles.parent = parent;
        }

        const newStyles = {};

        /**
         * This looks like a really messy code, I know, but let me explain why.
         * the mapStateToRedux takes the state from the Angular JS application and
         * adds it to the redux store. In order for those changes to apply, the new
         * state needs to be a new object with a new reference. If we pass to the redux
         * store the angular JS object, if there are any changes they will not apply
         * since the object is the same one. In order to copy those values, we use
         * Object.assign on several levels.
         */
        if (styles) {
            if (styles.global) {
            Object.assign(newStyles, {
                global: {
                ...styles.global,
                }
            });

            if (styles.global.properties) {
                Object.assign(newStyles.global, {
                properties: {
                    ...styles.global.properties,
                }
                });

                if (styles.global.properties.top) {
                Object.assign(newStyles.global.properties, styles.global.properties.top);
                }

                if (styles.global.properties.mainNav) {
                Object.assign(newStyles.global.properties, styles.global.properties.mainNav);
                }
            }
            }

            if (styles.parent) {
                Object.assign(newStyles, {
                parent: {
                    ...styles.parent,
                },
                child: styles.global,
                });

                if (styles.parent.properties) {
                Object.assign(newStyles.parent, {
                    properties: {
                    ...styles.parent.properties,
                    }
                });

                if (styles.parent.properties.top) {
                    Object.assign(newStyles.parent.properties, styles.parent.properties.top);
                }

                if (styles.parent.properties.mainNav) {
                    Object.assign(newStyles.parent.properties, styles.parent.properties.mainNav);
                }
                }
            }

            if (styles.widgets) {
                Object.assign(newStyles, {
                    widgets: {
                    ...styles.widgets,
                    },
                });
            }
        }

        return newStyles;
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'style';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'style/update';

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////

    service.adjustFooterStyle = adjustFooterStyle;
    service.adjustShadow = adjustShadow;
    service.adjustSpacings = adjustSpacings;
    service.adjustStyle = adjustStyle;
    service.cleanWidgetGlobalStyle = cleanWidgetGlobalStyle;
    service.getEmptyGlobalStyle = getEmptyGlobalStyle;
    service.getInstanceDefaultStyle = getInstanceDefaultStyle;
    service.getInstanceGlobalStyle = getInstanceGlobalStyle;
    service.getInstanceParentStyle = getInstanceParentStyle;
    service.getBlockStyle = getBlockStyle;
    service.getShadow = getShadow;
    service.getWidgetContentStyle = getWidgetContentStyle;
    service.isCustomer = isCustomer;
    service.isInstance = isInstance;

    /////////////////////////////

    /**
     * Initialize the service.
     *
     * @param  {Array}   [styles]               An array of styles to init the service with.
     * @param  {boolean} [asynchronously=false] Whether we want to force async fetch.
     * @param  {number}  [parentStyleId]        The id of the style of the parent instance.
     * @return {Promise} The promise of the initialisation.
     */
    service.init = function init(styles, asynchronously, parentStyleId) {
        _instanceParentStyleId = parentStyleId;

        asynchronously = asynchronously || false;

        const currentInstance = Instance.getInstance();
        if (angular.isUndefinedOrEmpty(currentInstance)) {
            return $q.reject('Instance is empty');
        }

        service.defaultParams = {
            customer: Customer.getCustomerId(),
            instance: currentInstance.uid,
        };

        service.initList(service.types.instance);
        service.initList(service.types.customer);
        service.initList(service.types.widget);

        ReduxStore.subscribe(service);

        return angular.isUndefinedOrEmpty(styles) || asynchronously ? _getAsyncStyles() : _getSyncStyles(styles);
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Style', StyleService);

/////////////////////////////

export { StyleService };
