/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isMediaAllowed, isAdvancedPickerEnabled as isAPEnabled } from '../../ducks/selectors';
import { MediaSideNavigation } from './MediaSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isMediaAllowed(state);
    const isAdvancedPickerEnabled = isAPEnabled(state);

    return {
        isAllowed,
        isAdvancedPickerEnabled,
    };
};

const mapDispatchToProps = {};

const ConnectedMediaSideNavigation = connect(mapStateToProps, mapDispatchToProps)(MediaSideNavigation);

export { ConnectedMediaSideNavigation as MediaSideNavigation };
