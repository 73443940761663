import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiAccount } from '@lumapps/lumx/icons';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { administratorsRoute } from '../../routes';

export interface InstanceAdminSideNavigationProps extends SideNavigationItemProps {
    isAllowed: boolean;
}

const InstanceAdminSideNavigation = ({ isAllowed }: InstanceAdminSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(GLOBAL.SITE_ADMINS)} icon={mdiAccount} route={administratorsRoute} />
    );
};

InstanceAdminSideNavigation.displayName = 'SideNavigationItem';

export { InstanceAdminSideNavigation };
