import React, { useRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Message, Tooltip, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { upperSnakeCase } from '@lumapps/utils/string/upperSnakeCase';

import { WIDGET_BASE, getWidgetDeprecationWarningMessage } from '../../keys';

import './index.scss';

const CLASSNAME = 'widget-deprecation-warning';

export interface WidgetDeprecationWarningProps {
    isLarge?: boolean;
    widgetType: string;
}

export const WidgetDeprecationWarning = ({ isLarge = false, widgetType = '' }) => {
    const anchorRefTooltip = useRef(null);
    const { translateKey } = useTranslate();
    /** retrieve the list of deprecated widgets from lokalise, which is always in english (no idea why this was configured like this) */
    const deprecatedWidgetList = translateKey(WIDGET_BASE.DEPRECATION_WARNING_WIDGET_LIST, 'en').split(',');
    const isDeprecated = deprecatedWidgetList.includes(widgetType);
    const widgetTypeUpperSnakeCase = upperSnakeCase(widgetType);
    const { block } = useClassnames(CLASSNAME);

    if (!isDeprecated) {
        return null;
    }

    return (
        <Tooltip label={!isLarge && translateKey(getWidgetDeprecationWarningMessage(widgetTypeUpperSnakeCase))}>
            <Message
                className={block({
                    'is-large': isLarge,
                })}
                ref={anchorRefTooltip}
                hasBackground
                kind="warning"
            >
                <Text
                    as="p"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: isLarge
                            ? translateKey(getWidgetDeprecationWarningMessage(widgetTypeUpperSnakeCase))
                            : translateKey(WIDGET_BASE.DEPRECATION_WARNING_SELECTION_MESSAGE),
                    }}
                />
            </Message>
        </Tooltip>
    );
};
