/* istanbul ignore file */
import React from 'react';

import { mdiBullhorn, mdiThumbUp } from '@lumapps/lumx/icons';
import { ListSubheader, ListItem, Size, Icon, ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { useRouter } from '@lumapps/router';
import { programsAdminRoute } from '@lumapps/sa-programs/routes';
import { useTranslate } from '@lumapps/translations';

import { SA_NAVIGATION } from '../../keys';
import { saSocialNetworks } from '../../routes';

export interface SettingsNavigationProps {
    userCanManagePlatformPrograms: boolean;
    userCanManagePlatformSocialNetworks: boolean;
}

/**
 * /!\ DO NOT USE THIS FILE AS MODEL /!\
 * /!\ DO NOT USE THIS FILE AS MODEL /!\
 *
 * The front-end is being reworked completely and the settings menu will probably
 * change. This is just temporary and should not be the duplicated.
 *
 * /!\ DO NOT USE THIS FILE AS MODEL /!\
 * /!\ DO NOT USE THIS FILE AS MODEL /!\
 */
const SettingsNavigation: React.FC<SettingsNavigationProps> = ({
    userCanManagePlatformPrograms,
    userCanManagePlatformSocialNetworks,
}) => {
    const { translateKey } = useTranslate();
    const { redirect } = useRouter();
    return (
        <>
            <ListSubheader>{translateKey(SA_NAVIGATION.SOCIAL_ADVOCACY)}</ListSubheader>
            {userCanManagePlatformPrograms && (
                <ListItem
                    before={
                        <Icon
                            icon={mdiBullhorn}
                            color={ColorPalette.dark}
                            colorVariant={ColorVariant.L2}
                            size={Size.xs}
                        />
                    }
                    onItemSelected={() => redirect(programsAdminRoute)}
                    size={Size.tiny}
                >
                    {translateKey(SA_NAVIGATION.PROGRAMS)}
                </ListItem>
            )}
            {userCanManagePlatformSocialNetworks && (
                <ListItem
                    before={
                        <Icon
                            icon={mdiThumbUp}
                            color={ColorPalette.dark}
                            colorVariant={ColorVariant.L2}
                            size={Size.xs}
                        />
                    }
                    size={Size.tiny}
                    onItemSelected={() => redirect(saSocialNetworks())}
                >
                    {translateKey(SA_NAVIGATION.SOCIAL_NETWORKS)}
                </ListItem>
            )}
        </>
    );
};

export { SettingsNavigation };
