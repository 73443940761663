import { getBaseUrl } from '@lumapps/router';

/** This function returns the pathname without the base url in
 *
 * @param {string} pathname - a pathname given by a router or obtained by retrieving window.location.pathname
 */
export const removeBaseUrlFromPathname = (pathname: string): string => {
    const baseUrl = getBaseUrl(pathname);

    return pathname.includes(baseUrl) ? pathname.replace(baseUrl, '') : pathname;
};
