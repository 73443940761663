import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { Program } from '../../types';

export interface ProgramAdminState {
    status: BaseLoadingStatus;
    deletingStatus: BaseLoadingStatus;
    programs: Program[];
}

const initialState: ProgramAdminState = {
    status: BaseLoadingStatus.initial,
    deletingStatus: BaseLoadingStatus.initial,
    programs: [],
};

export const programAdminReducers = {
    fetchPrograms: (state: ProgramAdminState): void => {
        set(state, 'status', BaseLoadingStatus.loading);
    },
    fetchProgramsSuccess: (state: ProgramAdminState, action: PayloadAction<Program[]>): void => {
        set(state, 'status', BaseLoadingStatus.idle);
        set(state, 'programs', action.payload || []);
    },
    fetchProgramsFailure: (state: ProgramAdminState): void => {
        set(state, 'status', BaseLoadingStatus.error);
    },
    setStatus: (state: ProgramAdminState, action: PayloadAction<BaseLoadingStatus>): void => {
        set(state, 'status', action.payload);
    },
    setDeletingStatus: (state: ProgramAdminState, action: PayloadAction<BaseLoadingStatus>): void => {
        set(state, 'deletingStatus', action.payload);
    },
};

const { actions, reducer } = createSlice({
    domain: 'programAdmin',
    initialState,
    reducers: programAdminReducers,
});

export { actions, reducer, initialState };
