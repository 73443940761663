import React, { ReactNode } from 'react';

import { MovingFocusProvider } from '@lumapps/moving-focus';

import { ComboboxContext, ComboboxContextProps } from './ComboboxContext';

export interface ComboboxProviderProps {
    children: ReactNode;
    /** The data to pass the provider. Can be generated by the useCombobox hook */
    value: ComboboxContextProps;
}

/** Context provider for the combobox. */
export const ComboboxProvider = ({ children, value }: ComboboxProviderProps) => {
    return (
        <ComboboxContext.Provider value={value}>
            <MovingFocusProvider
                options={{
                    direction: value.type === 'grid' ? 'both' : 'vertical',
                    firstFocusDirection: value.type === 'grid' ? 'vertical' : undefined,
                    loopAround: {
                        row: 'next-loop',
                        col: 'inside',
                    },
                    gridJumpToShortcutDirection: 'vertical',
                    defaultSelectedId: value.selectedIds?.[0],
                    listKey: value.inputValue,
                }}
            >
                {children}
            </MovingFocusProvider>
        </ComboboxContext.Provider>
    );
};
