/* istanbul ignore file */

export enum SEARCH_ANALYTICS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131378662
     */
    NAVIGATION = 'ADMIN.SEARCH_ANALYTICS_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=129501119
     */
    EXPORT_DIALOG_LABEL = 'ADMIN.SEARCH_ANALYTICS_EXPORT_DIALOG_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=129501488
     */
    DATE_RANGE_LABEL = 'ADMIN.SEARCH_ANALYTICS_DATE_RANGE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=129502700
     */
    SITES_LABEL = 'ADMIN.SEARCH_ANALYTICS_SITES_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=129502809
     */
    USER_LANGUAGES_LABEL = 'ADMIN.SEARCH_ANALYTICS_USER_LANGUAGES_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=129504449
     */
    DATE_RANGE_DAYS = 'ADMIN.SEARCH_ANALYTICS_DATE_RANGE_DAYS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130727695
     */
    ANALYTICS_MODULE_LABEL = 'ADMIN.SEARCH_ANALYTICS_MODULE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130776063
     */
    ANALYTICS_MODULE_DATES = 'ADMIN.SEARCH_ANALYTICS_MODULE_DATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130775967
     */
    ANALYTICS_MODULE_SEARCH = 'ADMIN.SEARCH_ANALYTICS_MODULE_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131257748
     */
    ANALYTICS_MODULE_LOADING_TITLE = 'ADMIN.SEARCH_ANALYTICS_MODULE_LOADING_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131259220
     */
    ANALYTICS_MODULE_LOADING_SUBTITLE = 'ADMIN.SEARCH_ANALYTICS_MODULE_LOADING_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130776010
     */
    SEARCH_TERMS = 'ADMIN.SEARCH_ANALYTICS_SEARCH_TERMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=130729801
     */
    TOTAL_SEARCHES = 'ADMIN.SEARCH_ANALYTICS_TOTAL_SEARCHES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131957815
     */
    NOT_ENOUGH_DATA_TITLE = 'ADMIN.SEARCH_ANALYTICS_NOT_ENOUGH_DATA_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=131957919
     */
    NOT_ENOUGH_DATA_SUBTITLE = 'ADMIN.SEARCH_ANALYTICS_NOT_ENOUGH_DATA_SUBTITLE',
}
