import React from 'react';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { cancelList, listCacheFirst } from '@lumapps/user/api';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { usePaginatedFetchWithStatus } from '@lumapps/utils/hooks/usePaginatedFetchWithStatus';

const fields = 'items(apiProfile(name(givenName,familyName)),firstName,fullName,id,lastName),more,callId,cursor';

/** Fetch user mention options with search term */
export function useFetchUserMention(searchTerm: string | undefined) {
    const instance = useSelector(instanceIdSelector);
    const lang = useSelector(currentLanguageSelector);
    const BASE_PARAMS = React.useMemo(
        () => ({
            lang,
            instance,
            maxResults: 15,
            queryFields: ['fullName', 'partialSearch', 'emails'],
            showHidden: false,
            status: 'ENABLED',
            fields,
        }),
        [instance, lang],
    );

    const { fetch, cancelFetch, status, response } = usePaginatedFetchWithStatus({
        onFetch: listCacheFirst,
        onFetchCancel: cancelList,
        debounceDelay: 100,
    });

    React.useEffect(() => {
        if (searchTerm !== undefined) {
            const fetchKey = `wrex-user-mention-${searchTerm}`;
            fetch({ params: [{ ...BASE_PARAMS, searchTerm }, fetchKey], debounce: true });
            return () => cancelFetch({ params: [fetchKey] });
        }
        return undefined;
    }, [BASE_PARAMS, cancelFetch, fetch, searchTerm]);

    const entities = response?.items?.map((user) => ({
        id: user.id,
        fullName: getUserFullName(user, lang),
    }));
    const cursor = response?.cursor;
    const hasMore = response?.more;

    const fetchMore = React.useCallback(() => {
        fetch({ params: [{ ...BASE_PARAMS, searchTerm, cursor }], fetchMore: true });
    }, [BASE_PARAMS, cursor, fetch, searchTerm]);

    return { status, entities, fetchMore, hasMore };
}
