import React, { isValidElement } from 'react';

import uniq from 'lodash/uniq';

import { BlockPlayVideoInterface } from '@lumapps/widget-play/types';

import { useResourcePropsOverride } from '../../../hooks/useResourcePropsOverride';
import { BlockStreamItemByResource } from '../../../types';

export type PlayVideoStreamItemProps = BlockStreamItemByResource<'play_video'>;

/**
 * We want to force the display of 'DURATION' in the order when 'THUMBNAIL' is also available
 */
const playVideoBlockPropsOverride =
    ({ order }: BlockPlayVideoInterface) =>
    () => ({
        order: uniq(order.includes('THUMBNAIL') ? [...order, 'DURATION'] : order),
    });

/** Component to display video blocks in a stream. */
export const PlayVideoStreamItem: React.FC<PlayVideoStreamItemProps> = ({ children, play_video: playVideo }) => {
    const playVideoChild = useResourcePropsOverride(children, playVideoBlockPropsOverride(playVideo));

    return isValidElement(playVideoChild) ? playVideoChild : null;
};
