import { useCallback } from 'react';

import { error } from '@lumapps/notifications';
import { pin, unpin } from '@lumapps/posts/api';
import { REFRESH_EVENTS } from '@lumapps/posts/constants';
import { RefreshPosts } from '@lumapps/posts/types';
import { useDispatch } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';

import { usePostListsRefresh } from './usePostListsRefresh';

export const usePostPin = (postId: string, communityId: string, refreshOverride?: RefreshPosts) => {
    const [isUnpinConfirmOpen, , closeUnpinConfirm, openUnpinConfirm] = useBooleanState(false);
    const dispatch = useDispatch();

    const refreshPostLists = usePostListsRefresh(refreshOverride);
    const { fetch: performPin, status: pinStatus } = useFetchWithStatus({
        onFetch: () => pin(postId, communityId),
    });

    const { fetch: performUnpin, status: unpinStatus } = useFetchWithStatus({
        onFetch: () => unpin(postId, communityId),
    });

    const onUnpinPost = useCallback(() => {
        performUnpin({
            callback: (params) => {
                if (params.success && refreshPostLists) {
                    refreshPostLists(REFRESH_EVENTS.POST_UNPIN, params.response);
                    closeUnpinConfirm();
                } else {
                    dispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
                    closeUnpinConfirm();
                }
            },
        });
    }, [closeUnpinConfirm, dispatch, performUnpin, refreshPostLists]);

    const onPinPost = useCallback(() => {
        performPin({
            callback: (params) => {
                if (params.success && refreshPostLists) {
                    refreshPostLists(REFRESH_EVENTS.POST_PIN, params.response);
                } else {
                    dispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
                }
            },
        });
    }, [dispatch, performPin, refreshPostLists]);

    const unpinConfirmDialogProps = {
        isOpen: isUnpinConfirmOpen,
        isLoading: unpinStatus === 'loading' || pinStatus === 'loading',
        onConfirm: onUnpinPost,
        onClose: closeUnpinConfirm,
    };

    return {
        unpinConfirmDialogProps,
        openUnpinConfirm,
        onPinPost,
    };
};
