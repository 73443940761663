import sortBy from 'lodash/sortBy';

import { ContainerFilter } from '@lumapps/widget-base/types';

const DEFAULT_SORT_ORDER = [
    'SEARCH_QUERY',
    'AUTHOR_EMAIL',
    'POST_TYPES',
    'POST_CATEGORIES',
    'POST_STATUSES',
    'SITE_IDS',
    'HAS_RELEVANT_COMMENT',
];

export const sortFilters = (filters: ContainerFilter[], order = DEFAULT_SORT_ORDER) => {
    return sortBy(filters, ({ type }) => order.indexOf(type));
};
