import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function TutorialController($document, $element, $scope, $timeout, InitialSettings, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Widget HTML in tutorial default style.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        var _WIDGET_HTML_DEFAULT_STYLE = {
            content: {
                backgroundColor: 'transparent',
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
            },
            footer: {},
            global: {
                borderRadius: 0,
                boxShadow: 0,
            },
            header: {},
        };

        /**
         * The identifier of the current tutorial being displayed.
         *
         * @type {string}
         */
        var _currentTutorialId;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The index of the currently displayed slide in the array of slides.
         *
         * @type {number}
         */
        vm.currentSlideIndex = 0;

        /**
         * Indicates if there is a slide after the current one.
         *
         * @type {boolean}
         */
        vm.hasNext = false;

        /**
         * Indicates if there is a slide before the current one.
         *
         * @type {boolean}
         */
        vm.hasPrevious = false;

        /**
         * Contains the HTML widgets to display the content of the slides.
         *
         * @type {Array}
         */
        vm.htmlWidgets = [];

        /**
         * Indicates if the tutorial should be displayed or not.
         *
         * @type {boolean}
         */
        vm.isDisplayed = false;

        /**
         * Indicates if the user is currently saving the tutorial (marking it as read).
         *
         * @type {boolean}
         */
        vm.isSaving = false;

        /**
         * The list of slides of the tutorial.
         *
         * @type {Array}
         */
        vm.slides = [];

        /**
         * A class name to apply to the tutorial wrapper element.
         *
         * @type {string}
         */
        vm.tutorialClass = '';

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Translation = Translation;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the current slide has a slide to navigate to next or not.
         *
         * @return {boolean} Whether the current slide has a next slide.
         */
        function _hasNext() {
            vm.hasNext = vm.currentSlideIndex < (vm.slides.length - 1) && !vm.isSaving;

            return vm.hasNext;
        }

        /**
         * Check if the current slide has a previous slide or not.
         *
         * @return {boolean} Whether the current slide has a previous slide.
         */
        function _hasPrevious() {
            vm.hasPrevious = vm.currentSlideIndex > 0 && vm.slides.length > 0 && !vm.isSaving;

            return vm.hasPrevious;
        }

        /**
         * Handle pressing down keys while tutorial is displayed.
         *
         * @param {Event} evt The keydown event triggering this method.
         */
        function _keyDown(evt) {
            if (!vm.isDisplayed) {
                return;
            }

            var key = (angular.isDefined(evt.keyCode)) ? evt.keyCode : evt.which;

            switch (key) {
                // Left arrow or `a` or `q`.
                case 37:
                case 65:
                case 81:
                    if (vm.hasPrevious) {
                        vm.goToSlide(vm.currentSlideIndex - 1);
                    }
                    break;
                    // Right arrow or `d`.
                case 39:
                case 68:
                    if (vm.hasNext) {
                        vm.goToSlide(vm.currentSlideIndex + 1);
                    }
                    break;
                default:
                    break;
            }
        }

        /**
         * When a key is pressed in the document.
         *
         * @param {Event} evt The keydown event.
         */
        function _onKeyDown(evt) {
            $scope.$apply(_keyDown.bind(vm, evt));
        }

        /**
         * Update all the navigation buttons depending on the current slide.
         */
        function _updateNavigation() {
            _hasNext();
            _hasPrevious();
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Hide the tutorial directive and save.
         */
        function closeTutorial() {
            $document.find('.app-content').removeClass('app-content--tutorial-mode');
            $document.find('body').removeClass('no-scroll');

            if (vm.isSaving) {
                return;
            }

            vm.isSaving = true;

            $timeout(function delayHideTutorial() {
                $document.unbind('keydown');
                $element.hide();
                vm.isDisplayed = false;

                vm.postCloseCallback()();
            }, 500);

            vm.preCloseCallback()(_currentTutorialId, function onUserSettingsSaveSuccess() {
                vm.isSaving = false;
            });
        }

        /**
         * Go to the specified slide index within the list of slides of the tutorial.
         *
         * @param {number} slideIndex The index of the slide to navigate to.
         */
        function goToSlide(slideIndex) {
            if (vm.isSaving || slideIndex > vm.slides.length || slideIndex < 0) {
                return;
            }

            vm.currentSlideIndex = slideIndex;

            // Handle the slide change + animation.
            var slide = $element.find('.tutorial-slides');
            slide.removeClass().addClass('tutorial-slides').addClass('tutorial-slides--left-' + vm.currentSlideIndex);

            _updateNavigation();
        }

        /**
         * Reset controller.
         *
         * @param {Tutorial} newTutorial The new tutorial object to display.
         */
        function reset(newTutorial) {
            if (angular.isUndefinedOrEmpty(newTutorial)) {
                return;
            }

            vm.currentSlideIndex = 0;

            vm.slides = newTutorial.slides || [];

            // We need to declare an empty style to avoid to use a possible default style.
            angular.forEach(vm.slides, function forEachSlides(slide) {
                vm.htmlWidgets.push({
                    properties: {
                        'class': 'tutorial-slides__description',
                        'content': Translation.translate(slide.text),
                        'style': _WIDGET_HTML_DEFAULT_STYLE,
                    },
                    style: _WIDGET_HTML_DEFAULT_STYLE,
                    uuid: generateUUID(),
                    widgetType: InitialSettings.WIDGET_TYPES.HTML,
                });
            });

            vm.isSaving = false;
            vm.isDisplayed = true;

            _updateNavigation();
        }

        /////////////////////////////

        vm.closeTutorial = closeTutorial;
        vm.goToSlide = goToSlide;
        vm.reset = reset;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch for any change on the tutorial as it's being fetched asynchronously.
         *
         * @param {Tutorial} newTutorial The new tutorial object.
         * @param {Tutorial} oldTutorial The old tutorial object.
         */
        $scope.$watch('vm.tutorialInfo', function tutorialInfoWatch(newTutorial, oldTutorial) {
            vm.isDisplayed = false;

            if (angular.isUndefinedOrEmpty(_.get(newTutorial, 'slides')) ||
                _.get(oldTutorial, 'id') === newTutorial.id) {
                return;
            }

            vm.init(newTutorial);
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         *
         * @param {Tutorial} tutorial The tutorial to be displayed.
         */
        vm.init = function init(tutorial) {
            if (angular.isUndefinedOrEmpty(tutorial)) {
                return;
            }

            _currentTutorialId = tutorial.id;

            vm.tutorialClass = _.get(tutorial, 'properties.class', '');

            vm.reset(tutorial);

            $element.show();

            $document.bind('keydown', _onKeyDown);

            $document.find('.app-content').addClass('app-content--tutorial-mode');
            $document.find('body').addClass('no-scroll')
        };
    }

    /////////////////////////////

    /**
     * A directive that displays a tutorial module and handles paging between the different slides.
     *
     * @param {Function} postCloseCallback A function to execute once the tutorial has been closed.
     * @param {Function} preCloseCallback  A function to execute just before the tutorial is closed.
     * @param {Tutorial} tutorialInfo      A tutorial object to be displayed.
     */

    function TutorialDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: TutorialController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                postCloseCallback: '&',
                preCloseCallback: '&',
                tutorialInfo: '=',
            },
            templateUrl: '/client/common/modules/module/modules/tutorial/views/tutorial.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('tutorial', TutorialDirective);
})();
