import { useQuery } from '@lumapps/base-api/react-query';
import { useSelector } from '@lumapps/redux/react';
import * as api from '@lumapps/widget-base/api';
import { getOwnerResourceInfo, isNGIContentFiltersFFEnabled } from '@lumapps/widget-base/ducks/selectors';
import type { WidgetState } from '@lumapps/widget-base/ducks/type';
import type { LegacyWidget } from '@lumapps/widget-base/types';

import { CONTENT_FILTER_SUPPORTED_WIDGETS } from '../constants';
import type { GenericListFilterProperties } from '../types';

/**
 * Fetch the widget filter properties for the widget connected to the given content-filter
 *
 * @param widget Widget to fetch filter properties from
 */
export function useFetchWidgetFilterProperties(widget: Partial<WidgetState> = {}): {
    filterProperties: GenericListFilterProperties | undefined;
    error?: unknown;
} {
    const isNGIContentFiltersEnabled = useSelector(isNGIContentFiltersFFEnabled);

    const enabled = Boolean(
        widget.widgetId &&
            widget.widgetType &&
            isNGIContentFiltersEnabled &&
            CONTENT_FILTER_SUPPORTED_WIDGETS.includes(widget.widgetType),
    );

    const ownerResourceInfo = useSelector(getOwnerResourceInfo);

    const widgetParams = {
        legacyWidget: widget.legacyWidget as LegacyWidget,
        widgetId: widget.widgetId as string,
        widgetType: widget.widgetType as string,
        params: widget.blockResolutionInfo,
    };

    const { data: filterProperties, error } = useQuery({
        enabled,
        queryKey: api.queryKeys.getWidgetFilterProperties(widgetParams),
        queryFn: ({ signal }) => {
            if (widgetParams.legacyWidget) {
                // OTF widget filters
                return api.getWidgetFilterPropertiesByProperties<GenericListFilterProperties>({
                    ...widgetParams,
                    ownerResourceInfo,
                    signal,
                });
            }
            // widget filters by ID.
            return api.getWidgetFilterPropertiesById<GenericListFilterProperties>({
                ...widgetParams,
                signal,
            });
        },
    });

    return { filterProperties, error };
}
