import { favorite, unfavorite } from '@lumapps/directories-favorites/api';
import { error as notifyError } from '@lumapps/notifications';
import { Dispatch } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { actions } from '@lumapps/widget-base/ducks/slice';

export const toggleFavorite =
    (isFavorite: boolean, directoryEntryId: string, widgetId: string, blockPath: (string | number)[]) =>
    async (dispatch: Dispatch) => {
        try {
            const apiCall = isFavorite ? unfavorite : favorite;
            await apiCall(directoryEntryId);
            dispatch(
                actions.setBlockProperties({
                    widgetId,
                    properties: {
                        isFavorite: !isFavorite,
                    },
                    path: blockPath,
                }),
            );
        } catch (err) {
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        }
    };
