(function IIFE() {
    'use strict';

    /////////////////////////////

    function DirectoryEntryUserService(DirectoryEntry, DirectoryEntryUserFactory, LumsitesBaseService) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(DirectoryEntryUserFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            preSave: function servicePreSave(obj) {
                return DirectoryEntry.formatClientObjectForServer(angular.fastCopy(obj));
            },
        });

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * The default maximum number of results when listing user's directory entries.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _MAX_RESULTS = 10;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The default parameters for the service requests.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                maxResults: _MAX_RESULTS,
            };
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('DirectoryEntryUser', DirectoryEntryUserService);
})();
