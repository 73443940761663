import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import isEqual from 'lodash/fp/isEqual';
import property from 'lodash/fp/property';

import { PostListFilterChoice } from '../../types';

export function createGetChoiceName<T>(choices: PostListFilterChoice<T>[]) {
    return function getChoiceName(value: T): string {
        return flow(find(flow(property('value'), isEqual(value))), property('name'))(choices);
    };
}
