import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { VideoDate } from '@lumapps/play/components/VideoDate';
import { handleVideoDatePrefix } from '@lumapps/play/utils';
import { useTranslate } from '@lumapps/translations';

import { PLAYLIST } from '../../../keys';
import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-date` as const;

type DateProps = Omit<TextProps, 'as'>;

/**
 * Displays the date of a playlist
 */
export const Date = ({ typography = Typography.body1, props }: DateProps) => {
    const { translateKey } = useTranslate();
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, playlist, theme } = useBlockPlaylistContext();

    if (!canDisplay('UPDATED_AT')) {
        return null;
    }

    if (isLoading || !playlist) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="30%" />;
    }

    return (
        <Text {...props} as="span">
            {handleVideoDatePrefix({
                date: playlist?.updatedAt,
                keyWithSuffix: translateKey(PLAYLIST.UPDATED_ON),
                keyWithoutSuffix: translateKey(PLAYLIST.UPDATED),
            })}
            &nbsp;
            <VideoDate capitalize={false} date={playlist?.updatedAt} />
        </Text>
    );
};
