import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

export const microAppByIdFrontOfficeRoute: Route = {
    path: '/ls/apps/:id',
    legacyId: 'app.apps.view',
    appId: AppId.frontOffice,
};

export const webviewMicroAppByIdPath = `/${URL_PREFIX}/webview/apps/:id`;

export interface MicroAppByIdFrontOfficeParams {
    id: string;
}

export const microAppByIdFrontOffice = (params: MicroAppByIdFrontOfficeParams): Route => ({
    ...microAppByIdFrontOfficeRoute,
    params: {
        id: params.id,
    },
    mobilePageDescriptor: {
        type: 'micro-app',
        id: params.id,
    },
});

export const webviewMicroAppViewRoute: Route = {
    path: webviewMicroAppByIdPath,
    appId: AppId.webview,
};
