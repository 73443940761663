import React from 'react';

import { translate as t } from 'components/translations';

const WidgetBlock = (props) => {
    const { widget } = props;

    return (
        <p className="component-settings__widget-description">
            {widget.icon !== undefined && <img alt="" src={t(widget.icon)} />}
            <strong>{t(widget.name)}</strong>
            <span>{t(widget.description)}</span>
        </p>
    );
};

export { WidgetBlock };
