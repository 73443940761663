import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiAttachment } from '@lumapps/lumx/icons';
import { Icon, Text } from '@lumapps/lumx/react';

import { getBaseClassName } from '../../helpers';

import './index.scss';

export interface AttachmentsCountProps {
    /** total number of attachments */
    count: number;
}

const NAMESPACE = 'attachments';
const baseClassName = getBaseClassName(NAMESPACE);
const AttachmentsCount: React.FC<AttachmentsCountProps> = ({ count }) => {
    const { element } = useClassnames(baseClassName);

    return (
        <div className={baseClassName}>
            <Icon icon={mdiAttachment} className={element('icon')} />
            <Text as="span">{count}</Text>
        </div>
    );
};

export { AttachmentsCount };
