/* eslint-disable angular/component-limit */

/**
 * Allow to instantiate a dynamic controller.
 *
 * @param  {AngularService} $compile The AngularJS $compile service.
 * @param  {AngularService} $parse   The AngularJS $parse service.
 * @return {Directive}      The dynamic-controller directive.
 */
function DynamicControllerDirective($compile, $parse) {
    'nginject';

    return {
        link: function linkDynamicControllerDirective(scope, elem) {
            let controllerName = $parse(elem.attr('dynamic-controller'))(scope);
            const controllerAs = elem.attr('dynamic-controller-as');

            controllerName += angular.isDefinedAndFilled(controllerAs) ? ` as ${controllerAs}` : '';

            elem.removeAttr('dynamic-controller');
            elem.attr('ng-controller', controllerName);

            $compile(elem)(scope);
        },
        // Use priority 550 to be run before `ng-controller` but after `ng-if`.
        priority: 550,
        restrict: 'A',
        terminal: true,
    };
}

DynamicControllerDirective.$inject = ['$compile', '$parse'];

/////////////////////////////

/**
 * Replaces the markup `ng-include` with the included content.
 *
 * @return {Directive} The include-replace directive.
 */
function IncludeReplaceDirective() {
    'nginject';

    return {
        link: function includeReplaceLink(scope, el) {
            el.replaceWith(el.children());
        },
        require: 'ngInclude',
        restrict: 'A',
    };
}

/////////////////////////////

/**
 * Allows to bind a function when the `Enter` key is pressed.
 *
 * @param  {Function}  ngEnter The function to execute when the enter key is pressed.
 * @return {Directive} The ng-enter directive.
 */
function NgEnterDirective() {
    'nginject';

    return function ngEnterCode(scope, el, attrs) {
        el.bind('keydown keypress', function ngEnterOnKeyDowKeyPress(evt) {
            if (evt.which === 13) {
                scope.$apply(function ngEnterApply() {
                    scope.$eval(attrs.ngEnter);
                });

                evt.preventDefault();
            }
        });
    };
}

/////////////////////////////

/**
 * Converts a string (from an input text) into an integer on a given ngModel.
 *
 * @return {Directive} The parse integer directive.
 */
function ParseIntegerDirective() {
    'nginject';

    return {
        link: function parseIntegerLink(scope, el, attrs, ctrl) {
            ctrl.$parsers.unshift(function parseIntValue(viewValue) {
                return parseInt(viewValue, 10);
            });
        },
        require: 'ngModel',
    };
}

/////////////////////////////

/**
 * Block any event propagation by adding the `prevent` attribute.
 * Used to block the action of a link or a button for example.
 *
 * @return {Directive} The prevent directive.
 */
function PreventDirective() {
    'nginject';

    return function preventCode(scope, el) {
        el.on('click', function preventDefault(evt) {
            evt.preventDefault();
        });
    };
}

/////////////////////////////

/**
 * Blocks the propagation of an event in the DOM.
 *
 * @param  {string}    stopPropagation The name of the event (or events) to be stopped.
 * @return {Directive} The stop propagation directive.
 */
function StopPropagationDirective() {
    'nginject';

    return function stopPropagationCode(scope, el, attrs) {
        el.on(attrs.stopPropagation, function stopPropagation(evt) {
            evt.stopPropagation();
        });
    };
}

/////////////////////////////
/**
 * Directive that makes component a bit more mobile friendly
 * i.e. add a ng-touchend event (~= click event).
 *
 * @return {Directive} The ng-touchend directive.
 */
function TouchendableComponentDirective() {
    'nginject';

    return {
        link: (scope, elm, attrs) => {
            if ('ontouchstart' in window) {
                let originY = -1;
                elm.bind('touchend', (evt) => {
                    if (originY === -1 || Math.abs(originY - evt.originalEvent.changedTouches[0].clientY) < 10) {
                        scope.$eval(attrs.ngTouchend);
                    }
                });
                elm.bind('touchstart', (evt) => {
                    originY = evt.originalEvent.touches[0].clientY;
                });
            }
        },
        restrict: 'A',
    };
}

/////////////////////////////

/**
 * Place here only small, highly re-usable directives.
 * For a big complex directive, use a separate own file.
 * For a directive specific to your application, place it in your application.
 */
angular
    .module('Directives')
    .directive('dynamicController', DynamicControllerDirective)
    .directive('includeReplace', IncludeReplaceDirective)
    .directive('ngEnter', NgEnterDirective)
    .directive('parseInteger', ParseIntegerDirective)
    .directive('prevent', PreventDirective)
    .directive('stopPropagation', StopPropagationDirective)
    .directive('ngTouchend', TouchendableComponentDirective);

/////////////////////////////

export {
    DynamicControllerDirective,
    IncludeReplaceDirective,
    NgEnterDirective,
    ParseIntegerDirective,
    PreventDirective,
    StopPropagationDirective,
};
