import React from 'react';

import { useTranslate } from '@lumapps/translations';

import type { GetIframeParamsDTO } from '../../api/stream/types';
import { useIframe, usePlayerClassnames } from '../../hooks';
import { PLAYER } from '../../keys';
import { usePlayerContext } from '../Player';
import { Skeleton } from '../Skeleton';

import './Iframe.scss';

type IframeProps = GetIframeParamsDTO;

export const Iframe = React.forwardRef<HTMLIFrameElement, IframeProps>(function Iframe(iframeProps, ref) {
    const { translateKey } = useTranslate();

    const { element } = usePlayerClassnames();

    const {
        state: { isFacadeVisible, videoId },
    } = usePlayerContext();

    const { data: iframe, isLoading } = useIframe({ videoId, ...iframeProps }, { enabled: !isFacadeVisible });

    if (isFacadeVisible) {
        return null;
    }

    if (isLoading) {
        return <Skeleton />;
    }

    if (!iframe) {
        return null;
    }

    return (
        <iframe
            ref={ref}
            name="play-video-player"
            className={element('iframe')}
            allow="fullscreen; autoplay"
            title={translateKey(PLAYER.TITLE)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            loading="lazy"
            srcDoc={iframe}
        />
    );
});
