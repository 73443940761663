import React, { PureComponent } from 'react';

import { Icon } from 'components/components/ui/icons';

import { conversationType } from './types';

/**
 * Display a conversation block from a channel.
 */
export class ConversationBlock extends PureComponent {
    static propTypes = {
        /** The conversation to display. */
        conversation: conversationType.isRequired,
    };

    constructor(props) {
        super(props);

        this.openConversation = this.openConversation.bind(this);
    }

    /**
     * Open the channel in a new tab.
     */
    openConversation() {
        const { conversation } = this.props;

        window.open(conversation.channelUrl, '_blank');
    }

    render() {
        const { conversation } = this.props;

        return (
            <div key={conversation.id} className="conversation-block" onClick={this.openConversation}>
                <div className="conversation-block__icon">
                    <Icon value="message-text-outline" />
                </div>

                <div className="conversation-block__title">
                    <span>{conversation.name}</span>
                </div>

                <div className="conversation-block__action">
                    <Icon value="arrow-right" />
                </div>
            </div>
        );
    }
}
