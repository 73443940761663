import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { SkeletonRectangle } from '@lumapps/lumx/react';
import { isFirefox } from '@lumapps/utils/browser/isFirefox';
import { isSafari } from '@lumapps/utils/browser/isSafari';
import { useEventListener } from '@lumapps/utils/hooks/useEventListener';
import { MediaPreview } from '@lumapps/widget-base/types';

import { usePdfToolbar } from '../../hooks/usePdfToolbar';
import { MediaSource } from '../../types';
import { getMediaUrl } from '../../utils';

import './index.scss';

export interface EmbeddedPdfProps {
    media?: MediaPreview;
    defaultZoom?: number;
    /** Force the iframe size to fit horizontally to his parent, overriding his default zoom */
    fitHorizontally?: boolean;
}

const CLASSNAME = 'embedded-pdf';

/**
 * Displays a given pdf as an embed pdf
 *
 * @family Files
 * @param EmbeddedPdfProps
 * @returns EmbeddedPdf
 */
export const EmbeddedPdf: React.FC<EmbeddedPdfProps> = ({ media, defaultZoom = 80, fitHorizontally = false }) => {
    const { element } = useClassnames(CLASSNAME);
    const isSourceCorrect = media?.source !== MediaSource.GOOGLE_DRIVE && media?.source !== MediaSource.MICROSOFT;
    const [isPdfEmbedAllowed, setIsPdfEmbedAllowed] = React.useState(media && isSourceCorrect);

    const [isPdfEmbedLoaded, setIsPdfEmbedLoaded] = React.useState(false);
    const [shouldFitToParent, setShouldFitToParent] = React.useState(fitHorizontally);
    const pdfIframeRef = React.useRef(null);
    const isSafariBrowser = isSafari();

    const mediaUrl = React.useMemo(() => {
        if (!media) {
            return undefined;
        }

        return media?.source === MediaSource.HAUSSMANN_MEDIA ? getMediaUrl(media.uuid || media.mediaId) : media?.url;
    }, [media]);

    const { currentZoom, PdfToolBar } = usePdfToolbar({
        defaultZoom,
        mediaUrl: mediaUrl || '',
        onZoomClick: shouldFitToParent ? () => setShouldFitToParent(false) : undefined,
    });

    const onPdfEmbedError = () => {
        setIsPdfEmbedAllowed(false);
        setIsPdfEmbedLoaded(true);
    };

    React.useEffect(() => {
        if (mediaUrl) {
            setIsPdfEmbedAllowed(isSourceCorrect);
            setIsPdfEmbedLoaded(true);
        } else {
            setIsPdfEmbedLoaded(false);
        }
    }, [mediaUrl, isSourceCorrect]);

    const queryParams = React.useMemo(() => {
        if (isFirefox()) {
            return shouldFitToParent ? '#zoom=FitH' : `#zoom=${currentZoom}`;
        }

        // view query param for Chrome and Safari browser
        return shouldFitToParent && !isSafari()
            ? `#toolbar=0&zoom=${currentZoom}&view=FitH`
            : `#toolbar=0&zoom=${currentZoom}`;
    }, [currentZoom, shouldFitToParent]);

    useEventListener('error', onPdfEmbedError, pdfIframeRef);

    return (
        <>
            {!isPdfEmbedLoaded && <SkeletonRectangle className={element('pdf-iframe-wrapper')} />}
            {isPdfEmbedAllowed && mediaUrl && (
                <div
                    className={classnames(
                        isPdfEmbedLoaded
                            ? // eslint-disable-next-line lumapps/no-manual-bems
                              `${CLASSNAME}__pdf-iframe-wrapper`
                            : // eslint-disable-next-line lumapps/no-manual-bems
                              `${CLASSNAME}__pdf-iframe-wrapper--is-loading`,
                    )}
                >
                    <object
                        data={`${mediaUrl}${queryParams}`}
                        ref={pdfIframeRef}
                        onLoad={() => setIsPdfEmbedLoaded(true)}
                        aria-label="Pdf Iframe"
                        className={element('pdf-iframe')}
                        // Prevent embed not displaying correctly on Safari because of type attribute
                        type={!isSafariBrowser ? 'application/pdf' : undefined}
                    />
                    <PdfToolBar />
                </div>
            )}
        </>
    );
};
