/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

import { ContributionFieldProps } from '../../types';

// picking props not provided through the redux store
type ConnectedBlockContributionFieldProps = Pick<
    ContributionFieldProps,
    'theme' | 'style' | 'availablePostTypes' | 'className'
>;

const LazyConnectedBlockContributionField = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'block-contribution-field' */
            './ConnectedBlockContributionField'
        ),
);

export const BlockContributionField = (props: ConnectedBlockContributionFieldProps) => {
    return (
        <Suspense loadingFallback={<WidgetSkeletonRectangle style={{ height: '136px' }} useDefaultHeight={false} />}>
            <LazyConnectedBlockContributionField {...props} />
        </Suspense>
    );
};
