import get from 'lodash/get';
import map from 'lodash/map';

function LoginChoiceController($window, Instance, User, resolveInitialSetting) {
    'ngInject';

    // This is here to trick the linter into thinking that the blocking resolve injection is used.
    angular.noop(resolveInitialSetting);

    /////////////////////////////

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains informations about all the accounts the user is attached to.
     *
     * @type {Array}
     */
    vm.accounts = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Instance = Instance;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Format username to enhance visibility.
     *
     * @param  {Object} account One of the account.
     * @return {Object} Account with formatted lastName property.
     */
    function _formatLastName(account) {
        return angular.extend(angular.fastCopy(account), {
            lastName:
                (angular.isDefinedAndFilled(account.lastName) ? `${account.lastName} - ` : '') + account.customer.name,
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Redirects the page to the customer's homepage.
     *
     * @param {Object} customer A customer object.
     */
    function goToAccountHome(customer) {
        if (angular.isDefinedAndFilled([customer.slug, get(customer.instance, 'slug')], 'every')) {
            $window.location.href = `/a/${customer.slug}/${customer.instance.slug}`;
        }
    }

    /////////////////////////////

    vm.goToAccountHome = goToAccountHome;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const accountChoices = User.getAccountChoices();

        // Remapping the fields of the account object so it fits in our <user-block> directive.
        vm.accounts = map(accountChoices || vm.accounts || [], _formatLastName);
    }

    init();
}

angular.module('Controllers').controller('LoginChoiceController', LoginChoiceController);

export { LoginChoiceController };
