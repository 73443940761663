/* istanbul ignore file */
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { PlatformExtensionsSideNavigation } from './PlatformExtensionsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isInstanceSuperAdmin(state),
});

const ConnectedPlatformExtensionsSideNavigation = connect(mapStateToProps)(PlatformExtensionsSideNavigation);

export { ConnectedPlatformExtensionsSideNavigation as PlatformExtensionsSideNavigation };
