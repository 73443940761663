export enum SocialNetworks {
    linkedin = 'linkedin',
    twitter = 'twitter',
}

/**
 * Not all social networks can be customized.
 * Some (like facebook) have API constraints that prevent
 * us from setting a default comment for example.
 */
export type CustomizableSocialNetworks = SocialNetworks.twitter | SocialNetworks.linkedin;

export type SocialNetworkStatus = {
    socialNetworkId: SocialNetworks;
    active: boolean;
};

export interface SocialNetworkConfig {
    id: SocialNetworks;
    label: string;
    /** Logo (as img) */
    logo: string;
    color: string;
    maxMessageLength?: number;
}

export interface SocialNetworkSettings {
    socialNetworkId: SocialNetworks;
    active: boolean;
    useCustomApplication: boolean;
    clientId: string | null;
}

export interface UserSocialNetworkAccess {
    socialNetworkId: SocialNetworks;
    userName: string;
    profilePictureUrl: string;
    authorizationRefreshRequired: boolean;
}

export interface SocialNetworksWithAccess extends SocialNetworkSettings {
    isConnected?: boolean;
    userName?: UserSocialNetworkAccess['userName'];
}
