import React from 'react';

import { useDelayedBooleanState } from './useDelayedBooleanState';

export interface Params {
    /** Disable */
    disable?: boolean;
    /** Time (in ms) after which we detect a long press */
    thresholdDelay?: number;
    /** Time (in ms) after which we unset the long press boolean */
    releaseDelay?: number;
}

interface Output {
    /** Whether the element is currently being long pressed */
    isLongPressed: boolean;
    /** Method to register an element to observe */
    registerElement: React.RefCallback<HTMLElement>;
}

const DEFAULT_THRESHOLD_DELAY = 250;
const DEFAULT_RELEASE_DELAY = 3000;

/**
 * Detect a long press (can be uses as an alternative to `useHover` on touch device).
 *
 * @example
 *      const { isLongPressed, registerElement } = useLongPress();
 *      return <Button ref={registerElement}> {isLongPressed ? 'LONG PRESSED' : 'NOT PRESSED'} </Button>;
 */
export const useLongPress = ({
    disable,
    thresholdDelay = DEFAULT_THRESHOLD_DELAY,
    releaseDelay = DEFAULT_RELEASE_DELAY,
}: Params = {}): Output => {
    const [isLongPressed, setDelayedLongPress, setInstantLongPress] = useDelayedBooleanState(false, {
        trueDelay: thresholdDelay,
        falseDelay: releaseDelay,
    });

    const [element, registerElement] = React.useState<HTMLElement | null>();

    React.useEffect(() => {
        if (!element || disable) {
            setInstantLongPress(false);
            return undefined;
        }

        let longPressActivated = false;

        const onClick = (evt: Event) => {
            if (longPressActivated) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        };

        const start = () => {
            longPressActivated = false;
            setDelayedLongPress(true, () => {
                longPressActivated = true;
            });
        };

        const end = (evt: Event) => {
            if (longPressActivated) {
                // Mouse/Touch ended after the long press threshold
                // Prevent click & touchend event (long press takes priority)
                evt.stopPropagation();
                evt.preventDefault();
                // Close with delay.
                setDelayedLongPress(false);
            } else {
                // Mouse/Touch ended before the long press threshold
                // Close immediately
                setInstantLongPress(false);
            }
        };

        const hasTouch = 'ontouchstart' in window;

        // Start, end and click
        const events = [
            [hasTouch ? 'touchstart' : 'mousedown', start],
            [hasTouch ? 'touchend' : 'mouseup', end],
            ['click', onClick],
        ] as const;

        // Close on click away
        const clickAway = (evt: Event) => {
            if (!element.contains(evt.target as any)) {
                setInstantLongPress(false);
            }
        };

        // Listen
        for (const [event, handler] of events) {
            element.addEventListener(event, handler);
        }
        document.addEventListener('click', clickAway);

        // Clean up
        return () => {
            for (const [event, handler] of events) {
                element.removeEventListener(event, handler);
            }
            document.removeEventListener('click', clickAway);
        };
    }, [disable, element, thresholdDelay, setDelayedLongPress, setInstantLongPress]);

    return { isLongPressed, registerElement };
};
