import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import type { Connector } from './types';

const platformConnectorsRoute: Route = {
    path: '/admin/connectors',
    appId: AppId.backOffice,
};

const platformConnectors = (): Route => platformConnectorsRoute;

export { platformConnectors, platformConnectorsRoute };

/**
 * Frontiers
 */

export const connectorCreateRoute: Route = {
    appId: AppId.backOffice,
    path: `${platformConnectorsRoute.path}/new`,
};

/**
 * The connector create route
 *
 * @returns {Route}
 */
export const connectorCreate = (): Route => connectorCreateRoute;

export const connectorEditRoute: Route = {
    appId: AppId.backOffice,
    path: `${platformConnectorsRoute.path}/:id`,
};

export interface ConnectorEditParams {
    /** Connector id being edited */
    id: Connector['id'];
}

/**
 * The connector edit route
 *
 * @param {ConnectorEditParams} params
 * @returns {Route}
 */
export const connectorEdit = (params: ConnectorEditParams): Route => ({
    ...connectorEditRoute,
    params: { id: params.id },
});
