import React from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { FormDialog } from '@lumapps/lumx-forms/components/FormDialog';
import { FormTextField } from '@lumapps/lumx-forms/components/FormTextField';
import { DialogSizes, Message, Thumbnail, AspectRatio, Text, Heading } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { LUMX_DIALOGS } from '../../keys';

import './index.scss';

const CLASSNAME = 'insert-alt-text-dialog';

export interface InsertAltTextTextDialogProps {
    /** Callback when discard button is pressed */
    onClose(): void;
    /** Callback when submit button is pressed */
    onChange(altText: string): void;
    /** Value of the current alt */
    currentAlt: string;
    /** Dialog is open or not */
    isOpen?: boolean;
    /** Manage what title will the dialog take */
    title?: string;
    /** Src of the image is passed */
    image: string;
    /** Size of the dialog */
    size?: DialogSizes;
    /** Help message the dialog will take */
    helpMessage?: string;
    /** Input lable the dialog will take */
    inputLabel?: string;
    /** Feature scope for tracking and a11y purposes */
    scope: string;
}
/**
 * Component that displays a dialog in order to enter an alt text on an image.
 *
 * @family Dialogs
 * @param InsertAltTextDialogProps
 * @returns InsertAltTextDialog
 */

export const InsertAltTextDialog: React.FC<InsertAltTextTextDialogProps> = (props) => {
    const {
        onClose,
        onChange,
        currentAlt,
        isOpen,
        title = currentAlt
            ? LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_EDIT_TITLE
            : LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_ADD_TITLE,
        image,
        size = 'tiny',
        inputLabel = LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_TEXTFIELD_LABEL,
        helpMessage = LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_HELP_MESSAGE,
        scope,
    } = props;
    const { block, element } = useClassnames(CLASSNAME);
    const [altText, setAltText] = React.useState(currentAlt);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    const handleInsertion = React.useCallback(
        (data: any) => {
            setAltText(data.altText);
            onChange(data.altText);
        },
        [onChange],
    );

    return (
        <FormDialog
            className={block()}
            form={{
                defaultValues: {
                    altText,
                },
            }}
            heading={
                <Heading as="h3" typography="title">
                    {translateKey(title)}
                </Heading>
            }
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleInsertion}
            formActionProps={{
                saveProps: {
                    children: translateKey(currentAlt ? GLOBAL.APPLY : GLOBAL.ADD),
                    ...get({ element: 'button', action: currentAlt ? 'apply-alt-text' : 'add-alt-text' }),
                },
                cancelProps: {
                    children: translateKey(GLOBAL.DISCARD),
                    ...get({ element: 'button', action: 'discard-alt-text' }),
                },
            }}
            dialogProps={{ size }}
            scope={scope}
        >
            <Thumbnail
                className={element('thumbnail')}
                alt={altText}
                align="center"
                image={image}
                aspectRatio={AspectRatio.original}
            />
            <FormTextField
                className={padding('top', null)}
                placeholder={translateKey(LUMX_DIALOGS.INSERT_ALT_TEXT_DIALOG_INPUT_PLACEHOLDER)}
                label={translateKey(inputLabel)}
                name="altText"
                helper={
                    <Message className={margin('top', 'big')} hasBackground kind="info">
                        <Text as="p">{translateKey(helpMessage)}</Text>
                    </Message>
                }
            />
        </FormDialog>
    );
};
