/* istanbul ignore file */

import React from 'react';

import { SkeletonRectangle } from '@lumapps/lumx/react';
import { Suspense } from '@lumapps/utils/components/Suspense';

export const loadBlockEventMetadata = () =>
    import(
        /* webpackChunkName: 'block-event-metadata' */
        './BlockEventMetadata'
    );

const LazyBlockEventMetadata = React.lazy(loadBlockEventMetadata);

export const BlockEventMetadata: React.FC<React.ComponentProps<typeof LazyBlockEventMetadata>> = (props) => (
    <Suspense loadingFallback={<SkeletonRectangle style={{ height: '100vh' }} />}>
        <LazyBlockEventMetadata {...props} />
    </Suspense>
);
