import { FROALA_HTML_TOOLBAR } from './types';

export const DEFAULT_FROALA_HTML_TOOLBAR = [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'quote',
    'subscript',
    'superscript',
    'fontFamily',
    'fontSize',
    '|',
    'color',
    'inlineStyle',
    'paragraphStyle',
    '|',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'insertHR',
    '-',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    'insertPopin',
    'editPopin',
    'editContent',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
    'html',
];

export const FROALA_TOOL_ICONS: Record<string, string> = {
    align: 'format-align-left',
    'align-center': 'format-align-center',
    'align-justify': 'format-align-justify',
    'align-left': 'format-align-left',
    'align-right': 'format-align-right',
    bold: 'format-bold',
    clearFormatting: 'format-clear',
    color: 'format-color-fill',
    editContent: 'pencil-box',
    editPopin: 'message-draw',
    emoticons: 'emoticon-happy',
    fontFamily: 'format-text',
    fontSize: 'format-size',
    formatOL: 'format-list-numbered',
    formatUL: 'format-list-bulleted',
    fullscreen: 'fullscreen',
    fullscreenCompress: 'fullscreen-exit',
    html: 'code-tags',
    'image-align': 'format-align-left',
    'image-align-center': 'format-align-justify',
    'image-align-left': 'format-align-left',
    'image-align-right': 'format-align-right',
    imageAlt: 'tooltip-image',
    imageRemove: 'delete',
    imageSize: 'arrow-expand-all',
    imageStyle: 'auto-fix',
    indent: 'format-indent-increase',
    inlineStyle: 'brush',
    insertFile: 'file',
    insertHR: 'color-helper',
    insertImage: 'image-area',
    insertLink: 'link',
    insertPopin: 'message-plus',
    insertTable: 'table-large',
    insertVideo: 'video',
    italic: 'format-italic',
    linkContent: 'content-duplicate',
    linkEdit: 'pencil',
    linkOpen: 'open-in-new',
    linkRemove: 'link-off',
    linkStyle: 'auto-fix',
    outdent: 'format-indent-decrease',
    paragraphFormat: 'format-paragraph',
    paragraphStyle: 'auto-fix',
    quote: 'format-quote-close',
    redo: 'redo',
    selectAll: 'select-all',
    strikeThrough: 'format-strikethrough',
    subscript: 'format-subscript',
    superscript: 'format-superscript',
    tableCellBackground: 'format-color-fill',
    tableCellHorizontalAlign: 'format-horizontal-align-left',
    tableCellStyle: 'auto-fix',
    tableCellVerticalAlign: 'format-vertical-align-top',
    tableCells: 'crop-square',
    tableColumns: 'table-column-plus-after',
    tableHeader: 'format-title',
    tableRemove: 'delete',
    tableRows: 'table-row-plus-after',
    tableStyle: 'brush',
    underline: 'format-underline',
    undo: 'undo',
    'video-align': 'format-align-left',
    'video-align-center': 'format-align-center',
    'video-align-left': 'format-align-left',
    'video-align-right': 'format-align-right',
    videoEmbed: 'code-tags',
    videoRemove: 'delete',
    videoReplace: 'refresh',
    videoSize: 'arrow-expand-all',
};

const FROALA_TRANSLATION_KEY_PREFIX = 'FRONT.FROALA.';

export const VERTICAL_SEPARATOR: FROALA_HTML_TOOLBAR = {
    icon: 'format-title',
    name: 'VERTICAL.SEPARATOR',
    unique: false,
    translationKey: `${FROALA_TRANSLATION_KEY_PREFIX}VERTICAL.SEPARATOR`,
    id: '|',
};

export const HORIZONTAL_SEPARATOR: FROALA_HTML_TOOLBAR = {
    icon: 'subdirectory-arrow-left',
    name: 'HORIZONTAL.SEPARATOR',
    unique: false,
    translationKey: `${FROALA_TRANSLATION_KEY_PREFIX}HORIZONTAL.SEPARATOR`,
    id: '-',
};

export const getToolbarForToolId = (toolId: string) => {
    const toolIdInUpperCase = toolId.toUpperCase();

    switch (toolId) {
        case '-':
            // Horizontal separator exception.
            return HORIZONTAL_SEPARATOR;
        case '|':
            // Vertical separator exception.
            return VERTICAL_SEPARATOR;
        default:
            // For all other tools, get icon from Froala icons override.
            return {
                icon: FROALA_TOOL_ICONS[toolId],
                name: toolIdInUpperCase,
                translationKey: `${FROALA_TRANSLATION_KEY_PREFIX}${toolIdInUpperCase}`,
                unique: true,
                id: toolId,
            };
    }
};

/**
 * Build the Froala toolbar choices.
 *
 * @return {Object} The toolbar choices.
 */
export const getWysiwygToolbar = () => {
    const toolObject: Record<string, FROALA_HTML_TOOLBAR> = {};

    DEFAULT_FROALA_HTML_TOOLBAR.forEach((toolId) => {
        toolObject[toolId] = getToolbarForToolId(toolId);
    });

    return toolObject;
};
