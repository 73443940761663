(function IIFE() {
    'use strict';

    /////////////////////////////

    function MergePostsDialogController($rootScope, $scope, Community, LxDialogService, LxNotificationService, Post,
        Translation, User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The post block fields to display.
         * We want to remove the comments field.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.POST_FIELDS = ['metadata', 'title', 'dates', 'content', 'attachments'];

        /**
         * The identifier for the post search list.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.POST_SEARCH_DUPLICATES_KEY = 'post-search-duplicates';

        /**
         * The identifier of the dialog to search for duplicates.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.POST_SEARCH_DIALOG_ID = 'post-search-duplicates-dialog';

        /**
         * A map of boolean objects handling various states in the dialog.
         *
         * @type {Object}
         */
        vm.is = {
            // Indicates if the current user is an admin of the master post of the report.
            admin: false,
            // Indicates if the actions buttons are disabled or not.
            disabled: false,
            // Indicates if a merge report is currently being generated / accepted / rejected.
            loading: false,
            // Indicates if a merge report is currently pending or not for the master post.
            mergePending: false,
            /*
             * Indicates if the report is in read mode or not.
             * In read mode, there are no toolbars on posts and no report action buttons.
             */
            readMode: true,
        };

        /**
         * A list of the slave posts that are already merged in the master post.
         *
         * @type {Array}
         */
        vm.mergedSlavePostsIds = [];

        /**
         * A list of all the slaves posts (posts that are deemed duplicates of the master).
         *
         * @type {Array}
         */
        vm.slavePosts = [];

        /**
         * A temporary list of slaves for the search dialog.
         * This is so we don't modify the slaves directly when toggling their state in the search dialog.
         *
         * @type {Array}
         */
        vm.tempSlavePosts = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.LxDialogService = LxDialogService;
        vm.Post = Post;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the details of the slaves so we can display the full merge report.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The id of the dialog being opened.
         * @param {Object} [params] An optional object that holds options for the dialog.
         *     - {boolean} [isReadMode] Indicates if the dialog should be in read mode or not.
         */
        function _onMergeDialogOpen(evt, dialogId, params) {
            if (vm.dialogId !== dialogId) {
                return;
            }

            vm.is.readMode = _.get(params, 'isReadMode', true);
            vm.is.mergePending = Post.isMergePending(vm.masterPost);
            vm.is.admin = Community.isEditableBy(User.getConnected(), _.get(vm.masterPost, 'parentContentDetails'));

            var idsToList = _.get(vm.masterPost, 'merge.slavesPosts', []);

            // Merge is not pending but there may already be some slaves.
            if (!vm.is.mergePending) {
                vm.mergedSlavePostsIds = idsToList;

                vm.canSetAsOriginal = !vm.is.mergePending && angular.isUndefinedOrEmpty(vm.mergedSlavePostsIds);

                if (!vm.is.readMode) {
                    vm.getPostList();

                    // If post is merged and we want to add more duplicates, we want all the stuff below to be done.
                    if (!vm.masterPost.isMerged) {
                        return;
                    }
                }
            }

            vm.is.loading = true;

            var actualMasterUid = _.get(vm.masterPost.merge, 'masterPost');

            // We can open a merge report either from the master or from (one of) the slaves.
            var isMaster = (vm.masterPost.uid === actualMasterUid);

            // If we open the report from a slave, add it straight away to the slaves and don't fetch it.
            if (!isMaster) {
                idsToList = _.union([actualMasterUid], _.without(idsToList, vm.masterPost.uid));
            }

            if (angular.isUndefinedOrEmpty(idsToList)) {
                return;
            }

            vm.mergedSlavePostsIds = idsToList;

            vm.canSetAsOriginal = !vm.is.mergePending && angular.isUndefinedOrEmpty(vm.mergedSlavePostsIds);

            Post.filterize({
                ids: idsToList,
            }, function onSlaveListSuccess(response) {
                // If we opened from the master, we just requested the slaves.
                if (isMaster) {
                    vm.slavePosts = response || [];
                // If we opened from the slave, we need to update the masterPost.
                } else {
                    vm.slavePosts = _.union([angular.fastCopy(vm.masterPost)], _.reject(response, {
                        uid: actualMasterUid,
                    }));
                    vm.masterPost = _.find(response, {
                        uid: actualMasterUid,
                    });
                }

                vm.is.loading = false;
            }, function onSlaveListError(err) {
                Utils.displayServerError(err);
                vm.is.loading = false;
            }, 'slave-post-details', Post.PROJECTION.list);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if a given post can be removed from a merge report or not.
         *
         * @param  {Object}  post A post object to check.
         * @return {boolean} Whether the post can be removed or not.
         */
        function canRemove(post) {
            return !vm.is.readMode && !_.includes(vm.mergedSlavePostsIds, post.uid);
        }

        /**
         * Cancel a merge report creation / moderation.
         */
        function cancelMergeReport() {
            if (vm.is.mergePending || angular.isUndefinedOrEmpty(vm.slavePosts) || vm.is.readMode) {
                LxDialogService.close(vm.dialogId);

                return;
            }

            LxNotificationService.confirm(
                Translation.translate('COMMUNITY_POST_MERGE_MANAGE'),
                Translation.translate('COMMUNITY_POST_MERGE_CANCEL_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onCancelMergeReportConfirm(answer) {
                    if (!answer) {
                        return;
                    }

                    LxDialogService.close(vm.dialogId);
                }
            );
        }

        /**
         * Get the list of posts for the duplicates search.
         */
        function getPostList() {
            Post.filterize({
                contentId: vm.masterPost.externalKey,
                isMerged: (Community.isEditableBy(User.getConnected(), _.get(vm.masterPost, 'parentContentDetails'))) ?
                    undefined : false,
                maxResults: 5,
                query: vm.searchPostQuery,
            }, undefined, undefined, vm.POST_SEARCH_DUPLICATES_KEY);
        }

        /**
         * Indicates if the given post is already part of the slavePosts or not.
         *
         * @param  {Post}    post   A post from the current community.
         * @param  {Array}   slaves The list of slaves to check. Can be the temporary one or the 'real' one.
         * @return {boolean} Whether the post is currently selected or not.
         */
        function isSelected(post, slaves) {
            return angular.isDefinedAndFilled(_.find(slaves, {
                uid: post.uid,
            }));
        }

        /**
         * Switches the masterPost with the given post.
         *
         * @param {Post}   newMasterPost The post that will become the masterPost of the merge report.
         * @param {number} listIndex     The index of the post in the slave list.
         */
        function switchMasterPost(newMasterPost, listIndex) {
            if (angular.isUndefinedOrEmpty([newMasterPost, listIndex], 'some') ||
                angular.isDefinedAndFilled(vm.mergedSlavePostsIds) ||
                newMasterPost.uid === vm.masterPost.uid) {
                return;
            }

            var tempPost = vm.masterPost;

            vm.masterPost = newMasterPost;

            vm.slavePosts[listIndex] = tempPost;
        }

        /**
         * Toggle the selected 'slave' state of a given post.
         *
         * @param {Post}    post     The post object to be toggled.
         * @param {boolean} [isTemp] Indicates if the post is being toggled on the temporary list or the 'real' one.
         */
        function toggleSlave(post, isTemp) {
            if (angular.isUndefinedOrEmpty(post) ||
                vm.masterPost.uid === post.uid ||
                _.includes(vm.mergedSlavePostsIds, post.uid)) {
                return;
            }

            // Defines if we're working on the temporary list of slaves or the 'real' one.
            isTemp = (angular.isUndefined(isTemp)) ? false : isTemp;

            var slaves = (isTemp) ? vm.tempSlavePosts : vm.slavePosts;

            if (vm.isSelected(post, slaves)) {
                // No need to confirm on temporary list items.
                if (isTemp) {
                    Utils.reject(slaves, {
                        uid: post.uid,
                    });
                } else {
                    LxNotificationService.confirm(
                        Translation.translate('COMMUNITY_POST_MERGE_REMOVE'),
                        Translation.translate('COMMUNITY_POST_MERGE_REMOVE_DESCRIPTION'), {
                            cancel: Translation.translate('CANCEL'),
                            ok: Translation.translate('OK'),
                        }, function onRemoveDuplicateConfirm(answer) {
                            if (!answer) {
                                return;
                            }

                            Utils.reject(slaves, {
                                uid: post.uid,
                            });
                        }
                    );
                }

                return;
            }

            slaves.push(post);
        }

        /**
         * Union the temporary list with the actual slave list.
         */
        function updateSlaves() {
            vm.slavePosts = angular.fastCopy(vm.tempSlavePosts);
            vm.tempSlavePosts = [];

            LxDialogService.close(vm.POST_SEARCH_DIALOG_ID);
        }

        /////////////////////////////

        vm.canRemove = canRemove;
        vm.cancelMergeReport = cancelMergeReport;
        vm.getPostList = getPostList;
        vm.isSelected = isSelected;
        vm.switchMasterPost = switchMasterPost;
        vm.toggleSlave = toggleSlave;
        vm.updateSlaves = updateSlaves;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Do stuff when a dialog opens.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The id of the dialog being opened.
         * @param {Object} [params] An optional object that holds options for the dialog.
         */
        $scope.$on('lx-dialog__open-start', function onDialogOpen(evt, dialogId, params) {
            if (dialogId === vm.POST_SEARCH_DIALOG_ID) {
                vm.tempSlavePosts = angular.fastCopy(vm.slavePosts);
            } else {
                _onMergeDialogOpen(evt, dialogId, params);
            }
        });

        /**
         * Whenever we get to the bottom of the dialog, try to get the next page of results.
         *
         * @param {Event}  evt      The original scroll event triggering this method.
         * @param {string} dialogId The identifier of the dialog in which the event is triggered.
         */
        $scope.$on('lx-dialog__scroll-end', function onDialogScrollEnd(evt, dialogId) {
            if (vm.POST_SEARCH_DIALOG_ID === dialogId) {
                Post.displayNextPage(vm.POST_SEARCH_DUPLICATES_KEY);
            }
        });
    }

    /**
     * A dialog to handle merging posts. Used for both the report and the review phases.
     *
     * @param {string} dialogId   The identifier of the dialog.
     * @param {Post}   masterPost The post object to set as master in the merge.
     */

    function MergePostsDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: MergePostsDialogController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                dialogId: '@lsDialogId',
                masterPost: '<?lsMasterPost',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/communities/post/views/partials/merge-posts-dialog.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsMergePostsDialog', MergePostsDialogDirective);
})();
