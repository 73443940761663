import { ComboboxOption } from '../ComboboxOption';
import { SelectButton as BaseSelectButton } from './SelectButton';

export { type SelectButtonProps } from './SelectButton';

/**
 * A Button with a select dropdown to choose between a list of options.
 *
 * @family Combobox
 */
export const SelectButton = Object.assign(BaseSelectButton, { Option: ComboboxOption });
