import React from 'react';

import { FetchLikeUserList, FetchToggleLike } from '../../api/query';
import { addReaction, deleteReaction, listUserReactions } from '../../api/v2';
import { ReactionResource } from '../../api/v2/types';
import { GenericLikeButton, GenericLikeButtonProps } from '../GenericLikeButton';

export interface LikeButtonProps
    extends Omit<GenericLikeButtonProps, 'resourceRef' | 'fetchToggleLike' | 'fetchLikeUserList'>,
        ReactionResource {}

/** Like button for v2 resources (articles, events, etc.) */
export const LikeButton = React.forwardRef<HTMLButtonElement, LikeButtonProps>((props, ref) => {
    const { resourceType, resourceId, ...forwardedProps } = props;

    // Toggle like/unlike
    const fetchToggleLike = React.useCallback<FetchToggleLike<typeof resourceType>>(async (resourceRef, isLiked) => {
        const call = isLiked ? deleteReaction : addReaction;
        const data = await call({ ...resourceRef, reactionType: 'like' });
        return { isLiked: data.hasUserReacted, likeCount: data.count };
    }, []);

    // Fetch users who like the resource
    const fetchLikeUserList = React.useCallback<FetchLikeUserList<typeof resourceType>>(async (resourceRef, params) => {
        const data = await listUserReactions(resourceRef, params);

        // format users
        const users = data.items
            .filter(({ reactions }) => reactions.includes('like'))
            .map(({ user: { userId, fullName } }) => ({ id: userId, fullName }));
        return { ...data, items: users };
    }, []);

    return (
        <GenericLikeButton
            ref={ref}
            resourceRef={{ resourceType, resourceId }}
            fetchToggleLike={fetchToggleLike}
            fetchLikeUserList={fetchLikeUserList}
            {...forwardedProps}
        />
    );
});
LikeButton.displayName = 'LikeButton';
