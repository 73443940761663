/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import type { ArticlePickerDialogProps } from '../../types';

const LazyArticlePickerDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'article-picker-dialog' */
            './ArticlePickerDialog'
        ),
);

const ArticlePickerDialog: React.FC<ArticlePickerDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyArticlePickerDialog {...props} />
        </DelayedSuspense>
    );
};

export { ArticlePickerDialog };
export type { ArticlePickerDialogProps };
