export enum REACTIONS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59685972
     */
    COMMENT_BUTTON_ARIA = 'FRONT.CONTENTS.COMMENT_BUTTON.ARIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=404451694
     */
    COMMENT_BUTTON_LINK_ARIA_LABEL = 'REACTIONS.COMMENT_BUTTON_LINK_ARIA_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59685966
     */
    LIKE_BUTTON_ARIA = 'FRONT.CONTENTS.LIKE_BUTTON.ARIA',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453772
     */
    USER_LIST_LIKES = 'USER_LIST_LIKES',
}
