import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { isPlatformExtensionsEnabled } from '@lumapps/reseller-console/ducks/selectors';

import { MICRO_APPS_ALPHA, MICRO_APPS_BETA, MICRO_APPS_FF } from '../constants';

export const isMicroAppsAlphaEnabled = isFeatureEnabled(MICRO_APPS_ALPHA);
export const isMicroAppsBetaEnabled = isFeatureEnabled(MICRO_APPS_BETA);
export const isMicroAppsEnabled = isFeatureEnabled(MICRO_APPS_FF);

export const canAccessMicroAppFrontOffice = createSelector(
    [isPlatformExtensionsEnabled, isMicroAppsEnabled],
    (isPlatformExtensionsEnabled, isMicroAppsEnabled) => isPlatformExtensionsEnabled && isMicroAppsEnabled,
);
