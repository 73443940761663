import React from 'react';

import { BaseApiError, isCancel } from '@lumapps/base-api';
import { getGroupAll } from '@lumapps/customer/ducks/selectors';
import { useGoogleDrivePicker } from '@lumapps/google-drive-pickers/hooks/useGoogleDrivePicker';
import { GDPickerConfiguration } from '@lumapps/google-drive-pickers/types';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { usePlayUser } from '@lumapps/play-roles';
import { isVideoSizeError } from '@lumapps/play-video/utils';
import { createVideo } from '@lumapps/play/api';
import { CreateVideoBody, UploadingVideo } from '@lumapps/play/api/types';
import { DRIVE_VIDEO_DEFAULT_CONFIG } from '@lumapps/play/constants';
import { isPlayStandardEnabled } from '@lumapps/play/ducks/selectors';
import { PLAY } from '@lumapps/play/keys';
import { FileUploadFromDrive } from '@lumapps/play/types';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';

import { PLAY_LIBRARY } from '../../keys';
import { removeExtension } from '../../utils/removeExtension';

export interface UseGoogleDriveUploadProps {
    /** Allow multiple files to upload */
    multiselect?: boolean;
    onVideosCreated?: () => void;
    /** Event called after user picked file(s) */
    onFilesSelected?: (files: FileUploadFromDrive[]) => void;
}

export const useGoogleDriveUpload = ({
    multiselect,
    onFilesSelected,
    onVideosCreated,
}: UseGoogleDriveUploadProps = {}) => {
    const currentUser = useSelector(connectedUserSelector);
    const currentLanguage = useSelector(currentLanguageSelector);
    const instanceId = useSelector(instanceIdSelector);
    const groupAll = useSelector(getGroupAll);
    const isPlayStandard = useSelector(isPlayStandardEnabled);

    const { maxUploadSize, humanReadableMaxUploadSize } = usePlayUser();

    const { success: showSuccessNotification, error: showErrorNotification } = useNotification();

    const { translateKey, translateAndReplace } = useTranslate();

    const createVideos = React.useCallback(
        (videosFromDrive?: FileUploadFromDrive[]) => {
            const queries = videosFromDrive?.map((videoProvider) => {
                const initialVideo: UploadingVideo = {
                    assetId: '',
                    assets: [],
                    audioLanguage: currentLanguage,
                    description: {
                        lang: currentLanguage,
                        value: '',
                        translations: { [currentLanguage]: '' },
                    },
                    groupDetails: [{ id: groupAll.id, title: groupAll.name }],
                    groupIds: [groupAll.id],
                    id: '',
                    isDownloadable: false,
                    language: currentLanguage,
                    siteId: instanceId,
                    status: 'draft',
                    publicationStatus: 'draft',
                    title: {
                        lang: currentLanguage,
                        value: removeExtension(videoProvider),
                        translations: { [currentLanguage]: removeExtension(videoProvider) },
                    },
                    ...(videoProvider && {
                        input: {
                            url: videoProvider.url,
                        },
                    }),
                };
                return createVideo(initialVideo as CreateVideoBody, videoProvider?.sizeBytes || 0);
            });

            async function createAllVideos() {
                try {
                    if (queries) {
                        await Promise.all(queries);
                        onVideosCreated?.();
                        showSuccessNotification({ translate: PLAY_LIBRARY.VIDEO_UPLOAD_PROGRESS_UPLOADED });
                    }
                } catch (error) {
                    if (isCancel(error)) {
                        return;
                    }

                    if (error instanceof BaseApiError) {
                        if (error.response?.status === 429) {
                            showErrorNotification({ translate: PLAY.UPLOAD_DIALOG_CONSUMPTION_WARNING });
                        }

                        if (error.response?.status === 413) {
                            showErrorNotification({
                                content: translateAndReplace(
                                    !isPlayStandard
                                        ? PLAY_LIBRARY.LIMITED_UPLOAD_SIZE_UPGRADE
                                        : PLAY_LIBRARY.LIMITED_UPLOAD_SIZE,
                                    {
                                        SIZE: humanReadableMaxUploadSize,
                                    },
                                ),
                            });
                        }
                    }

                    showErrorNotification({ translate: GLOBAL.GENERIC_ERROR });
                }
            }

            createAllVideos();
        },
        [
            currentLanguage,
            groupAll.id,
            groupAll.name,
            humanReadableMaxUploadSize,
            instanceId,
            isPlayStandard,
            onVideosCreated,
            showErrorNotification,
            showSuccessNotification,
            translateAndReplace,
        ],
    );

    const { openGoogleDrivePicker } = useGoogleDrivePicker(
        {
            ...DRIVE_VIDEO_DEFAULT_CONFIG,
            locale: currentLanguage,
            multiselect,
            setIncludeFolders: true,
            setSelectFolderEnabled: true,
            starredTranslation: translateKey(PLAY_LIBRARY.STARRED),
            token: currentUser?.token,
        } as GDPickerConfiguration,
        {
            onFilesSelected: (files) => {
                let isError = false;
                files.forEach((videoFile: FileUploadFromDrive) => {
                    if (isVideoSizeError(videoFile?.sizeBytes, maxUploadSize)) {
                        isError = true;
                        showErrorNotification({
                            content: translateAndReplace(
                                !isPlayStandard
                                    ? PLAY_LIBRARY.LIMITED_UPLOAD_SIZE_UPGRADE
                                    : PLAY_LIBRARY.LIMITED_UPLOAD_SIZE,
                                {
                                    SIZE: humanReadableMaxUploadSize,
                                },
                            ),
                        });
                    }
                });

                if (isError) {
                    return;
                }

                if (onFilesSelected) {
                    onFilesSelected(files);
                }
                if (files.length > 1) {
                    createVideos(files);
                }
            },
        },
    );

    const isGoogleUser = React.useMemo(() => currentUser?.accountType === 'google', [currentUser?.accountType]);

    return {
        isGoogleUser,
        openGoogleDrivePicker,
    };
};
