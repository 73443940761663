export const CACHE_KEY = 'customizations.modes';

export const MODES = {
    /**
     * If enabled, it shows LumApps without the header and the footer. This mode is disabled
     * by default, both in production and development
     */
    HEADLESS: 'headless',
    /**
     * If enabled, it shows LumApps with the customizations added by on the site head
     * field in the Style admin, as well as the custom css stylesheets.
     */
    WITH_CUSTOM_CODE: 'with-custom-code',
    /**
     * Enables the reworked user profile and the new reading history section
     */
    USER_PROFILE_REACT: 'user-profile-react',
    /**
     * If enabled, it shows LumApps header without the top barm, meaning that it only displayes the navigation. This mode is disabled
     * by default, both in production and development
     */
    NO_TOP_BAR: 'no-top-bar',
    /**
     * Display a preview for some search results
     */
    SEARCH_PREVIEW: 'search-preview',
    /**
     * Display backoffice without the side navigation
     */
    NO_SIDE_NAV_BO: 'no-side-nav-bo',
    /**
     * Disable the front-office header
     */
    DISABLED_HEADER: 'disabled-header',
};

/** Handles the way the modes should be stored. Either in local storage or in momentaneous memory */
export const CACHE_TYPE_MODES = {
    STORAGE: [MODES.HEADLESS, MODES.NO_TOP_BAR, MODES.SEARCH_PREVIEW, MODES.USER_PROFILE_REACT, MODES.WITH_CUSTOM_CODE],
    MEMORY: [MODES.NO_SIDE_NAV_BO, MODES.DISABLED_HEADER],
};

export const DISABLE_MODE_CHAR = '!';

export const MODES_DESCRIPTIONS = {
    HEADLESS:
        'If enabled, it shows LumApps without the header and the footer. This mode is disabled by default, both in production and development',
    WITH_CUSTOM_CODE:
        'If enabled, it shows LumApps with the customizations added by on the site head field in the Style admin, as well as the custom css stylesheets.',
    USER_PROFILE_REACT: 'Enables the next generation user profile',
    NO_TOP_BAR:
        'If enabled, it shows LumApps header without the top barm, meaning that it only displayes the navigation',
    SEARCH_PREVIEW: 'Display preview for some lumapps results (only content for now)',
    NO_SIDE_NAV_BO: 'Display backoffice without the side navigation',
    DISABLED_HEADER: 'Disable the front-office header',
};

export const ENVIRONMENT_MODES = {
    production: [MODES.WITH_CUSTOM_CODE],
    development: [MODES.SEARCH_PREVIEW],
    test: [MODES.SEARCH_PREVIEW],
};

export const MODES_SUPERSEEDED_DISABLED = [MODES.WITH_CUSTOM_CODE];
