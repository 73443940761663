import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import fromMarkdownMdast from 'mdast-util-from-markdown';
import gfm from 'mdast-util-gfm';
import gfmSyntax from 'micromark-extension-gfm';

import { User } from '@lumapps/user/types';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';

import type { Wrex } from '../../../types';
import { ZERO_WIDTH_SPACE } from '../constants';
import { OPTIONS } from './options';
import {
    CustomElementParser,
    CustomElementParserContext,
    DeserializeOptions,
    ElementConverter,
    Marks,
    MdastNode,
} from './types';

interface ContentContext {
    mentionsDetails?: Partial<User>[];
}

/**
 * Parse a custom element from text nodes.
 */
const parseCustomElement =
    (context: CustomElementParserContext) =>
    (nodes: Wrex.Nodes, [regExp, parser]: [string, CustomElementParser]): Wrex.Nodes => {
        return flatMap(nodes, (node) => {
            if (!(node as any).text) {
                return node;
            }
            const { text } = node as Wrex.Text;

            let indexOffset = 0;
            const output: Wrex.Nodes = [];
            for (const match of text.matchAll(new RegExp(regExp, 'g'))) {
                const parsed = parser.parse(context, match);
                if (parsed) {
                    const { parsedNode, start, end } = parsed;
                    const textBefore = text.substring(indexOffset, start);
                    output.push(
                        // Insert text before
                        context.createTextNode(textBefore),
                        // Insert parsed node
                        parsedNode,
                    );

                    indexOffset = end;
                }
            }
            // Insert text after
            output.push(context.createTextNode(text.substr(indexOffset)));

            return output;
        });
    };

/**
 * Convert MDAST node to slate nodes.
 */
// eslint-disable-next-line no-use-before-define
function convertNode(
    node: MdastNode,
    activeMarks: Marks = {},
    context: ContentContext = {},
    options: DeserializeOptions = OPTIONS,
): Wrex.Nodes {
    // Convert text node
    if (node.type === 'text') {
        const createTextNode = (text: string) => ({ text, ...activeMarks });

        // we need to remove zero-width-space characters to enhance the experience when editing the text
        const textNode = createTextNode(node.value ? node.value.replace(new RegExp(ZERO_WIDTH_SPACE, 'g'), '') : '');

        return Object.entries(options.customElements).reduce(parseCustomElement({ createTextNode, context }), [
            textNode,
        ]);
    }

    // Convert mark nodes into mark attributes for slate text node.
    const newActiveMarks: Marks = { ...activeMarks };
    const mark = options.marks[node.type];
    if (mark) {
        newActiveMarks[mark] = true;
    }

    // Convert children nodes.
    const childrenNodes = (node.children as MdastNode[]) || [{ type: 'text', value: node.value } as MdastNode];
    const children = flatMap(childrenNodes, (child) => convertNode(child, newActiveMarks, context, options));

    // Convert slate element nodes.
    const elementConverter = (options.elements as any)[node.type] as ElementConverter<any, any>;
    if (elementConverter) {
        const output: Wrex.Nodes[] = [];
        let childrenGroup: Wrex.Nodes = [];
        // Move `forcedRootElement` children out of the current element.
        for (const child of children) {
            const isRootElement = Object.entries(options.elements).find(
                ([type, { isStrictlyRootElement }]: any) => isStrictlyRootElement && type === (child as any).type,
            );
            if (isRootElement) {
                if (childrenGroup.length) {
                    const slateNode = elementConverter.convert(node, childrenGroup);
                    if (slateNode) {
                        output.push(slateNode);
                    }
                }
                output.push([child]);
                childrenGroup = [];
            } else {
                childrenGroup.push(child);
            }
        }
        if (childrenGroup.length || !output.length) {
            if (elementConverter.isInlineElement) {
                // Makes sure inline element always have a text node before.
                output.push([{ text: '' }]);
            }
            const slateNode = elementConverter.convert(node, childrenGroup);
            if (slateNode) {
                output.push(slateNode);
            }
            if (elementConverter.isInlineElement) {
                // Makes sure inline element always have a text node after.
                output.push([{ text: '' }]);
            }
        }
        return flatten(output);
    }

    // Unknown MDAST node: returning children.
    return children;
}

/** Function for convert markdown to slate nodes */
export function fromMarkdown(
    markdown: string,
    context: ContentContext = {},
    options: DeserializeOptions = OPTIONS,
): Wrex.Nodes {
    if (markdown === '') {
        return [createParagraph()] as any;
    }
    const mdastTree = fromMarkdownMdast(markdown, {
        extensions: [gfmSyntax()],
        mdastExtensions: [gfm.fromMarkdown],
    });
    return convertNode(mdastTree, {}, context, options);
}
