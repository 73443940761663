import { ThumbnailVariant } from '@lumapps/lumx/react';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { SEARCH_SOURCE_TYPES } from '@lumapps/search/keys/sourceTypes';
import { BaseSearchResult, FeaturesStatus } from '@lumapps/search/types';
import { addBaseSlugToSearchUrl } from '@lumapps/search/utils';
import { TranslationAPI } from '@lumapps/translations';
import { retrieveKeyFromApiProfile } from '@lumapps/user/utils/apiProfile';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';

export interface Options {
    translate: TranslationAPI['translate'];
    features: FeaturesStatus;
}

const props = (result: BaseSearchResult, baseProps: any, options: Options) => {
    const { features, translate } = options;
    const { onClick, flag, quickViewUrl, onQuickViewClick } = baseProps;
    const { user, customFields } = result;

    if (!user) {
        return baseProps;
    }

    const email = user.apiProfile && user.apiProfile?.primaryEmail ? user.apiProfile.primaryEmail : user.email;

    return {
        onClick,
        flag,
        quickViewUrl,
        onQuickViewClick,
        customFields,
        thumbnail: getUserProfilePictureUrl(user),
        thumbnailVariant: ThumbnailVariant.rounded,
        id: user.id,
        name: user.fullName,
        email: features.isHideEmailEnabled ? undefined : email,
        url: addBaseSlugToSearchUrl(user.url),
        jobTitle: retrieveKeyFromApiProfile('title', user),
        location: retrieveKeyFromApiProfile('location', user),
        department: retrieveKeyFromApiProfile('department', user),
        phone:
            user.apiProfile?.phones && user.apiProfile.phones.length > 0 ? user.apiProfile.phones[0].value : undefined,
        position: result.position,
        kind: translate(SEARCH_SOURCE_TYPES.USER),
        hideKindInBreadcrumb: true,
        breadcrumbLevels: [features?.isHideEmailEnabled ? undefined : email],
        type: SEARCH_RESULT_TYPES.USER,
    };
};

export { props };
