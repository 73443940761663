import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import type { SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiWeb } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { canAccessDomainsManagement } from '../../ducks/selectors';
import { DOMAINS_MANAGEMENT } from '../../keys';
import { domainsManagementRoute } from '../../routes';

const DomainsManagementSideNavigation = (props: Partial<SideNavigationItemProps>) => {
    const isAllowed = useSelector(canAccessDomainsManagement);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            icon={mdiWeb}
            label={translateKey(DOMAINS_MANAGEMENT.TITLE)}
            route={domainsManagementRoute}
            {...props}
        />
    );
};

DomainsManagementSideNavigation.displayName = 'SideNavigationItem';

export { DomainsManagementSideNavigation };
