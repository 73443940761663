/**
 * Convert an hexadecimal colour code to RGB components (object with red, green and blue properties).
 *
 * @param  color An hexadecimal colour code (with or without a prefixed #).
 *                      Supports both short and long versions of colour codes.
 * @return The RGB object.
 *
 * See https://stackoverflow.com/a/5624139/1300306 .
 */
export const parseHex = (color: string): { red: number; green: number; blue: number } | undefined => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF").
    const SHORTHAND_REGEX = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    const newHex = color.replace(SHORTHAND_REGEX, (match, red, green, blue) => red + red + green + green + blue + blue);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);

    if (!result) {
        return undefined;
    }

    return {
        blue: parseInt(result[3], 16),
        green: parseInt(result[2], 16),
        red: parseInt(result[1], 16),
    };
};
