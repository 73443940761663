import React from 'react';

import { ComponentTypes, TranslatableObject } from 'lumapps-sdk-js';

import { margin, useClassnames } from '@lumapps/classnames';
import { Kind, Message, Heading, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { MARKETPLACE } from '../../keys';

export interface UnavailableExtensionProps {
    name?: TranslatableObject;
    type: ComponentTypes;
}

const CLASSNAME = 'extension-unavailable';
export const UnavailableExtension: React.FC<UnavailableExtensionProps> = ({ name, type }) => {
    const { translateKey, translateObject } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);

    return (
        <>
            {type === ComponentTypes.content && (
                <div className={block()}>
                    <Heading as="h3" typography="headline" className={element('title')}>
                        {translateObject(name)}
                    </Heading>

                    <Text as="p" className={element('content')}>
                        {translateKey(MARKETPLACE.UNAVAILABLE_REPLACE)}
                    </Text>
                </div>
            )}

            {type === ComponentTypes.settings && (
                <Message kind={Kind.warning} hasBackground className={margin('top', 'big')}>
                    <Text as="p">{translateKey(MARKETPLACE.EXTENSION_UNAVAILABLE)}</Text>
                </Message>
            )}
        </>
    );
};
