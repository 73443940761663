import last from 'lodash/last';
import partial from 'lodash/partial';
import uniq from 'lodash/uniq';

import { Editor, Node, NodeEntry, Path, Transforms } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import type { Wrex, WrexEditor } from '@lumapps/wrex/types';

import { TD, TR } from '../components/blocks';
import { EditableTable } from '../components/editableBlocks/EditableTable';
import type { TableEditor, TableElement, TDElement } from '../types';
import { addColumn } from '../utils/addColumn';
import { addRow } from '../utils/addRow';
import { createTable } from '../utils/createTable';
import { deleteColumn } from '../utils/deleteColumn';
import { deleteContent } from '../utils/deleteContent';
import { deleteRow } from '../utils/deleteRow';
import { deleteTable } from '../utils/deleteTable';
import { insertTable } from '../utils/insertTable';
import { insertTableInTable } from '../utils/insertTableInTable';
import { isTable } from '../utils/isTable';
import { isTableCell } from '../utils/isTableCell';
import { isTableRow } from '../utils/isTableRow';
import { updateCellSelection } from '../utils/updateCellSelection';
import { getKeyHandler } from './event/keyboard';
import { normalizeTable } from './normalize/normalizeTable';
import { normalizeTableCell } from './normalize/normalizeTableCell';
import { normalizeTableRow } from './normalize/normalizeTableRow';

const createPluginEditor = (): CreatePluginEditor<TableEditor, WrexEditor<TableEditor>> => (editor) => {
    const { normalizeNode, deleteFragment, setFragmentData, insertData } = editor;
    let cellSelection: Path[] = [];
    let currentlySelectedTablePath: Path = [];

    return {
        setFragmentData: (data, originEvent) => {
            // If multi-cell selection is active we have to manually set the copied data
            // because the native behavior will copy cells that are not in our selection.
            if (cellSelection.length) {
                // Get all rows index in selection (cells are always on the second position of a path)
                const rowsIndexInSelection = uniq(cellSelection.map((c) => c[1]));
                // Get all columns index in selection (cells are always on the third position of a path)
                const columnsIndexInSelection = uniq(cellSelection.map((c) => c[2]));

                // All rows of the currently selected table
                const tableRows = Array.from(
                    Editor.nodes(editor, {
                        at: currentlySelectedTablePath,
                        match: isTableRow,
                    }),
                );

                // Get all rows (as NodeEntries) that are in the selection.
                const rowsInSelection = tableRows.filter(([, rowPath]) => {
                    // last(rowPath) matches the index of the row
                    return rowsIndexInSelection.includes(last(rowPath) as number);
                });

                // We rebuild rows, by removing cells that are not in the selection
                const filteredRows = rowsInSelection.map(([row]) => {
                    const firstColInSelection = Math.min(...columnsIndexInSelection);
                    const lastColInSelection = Math.max(...columnsIndexInSelection);
                    // we keep only the cells that are between the first and last columns in selection
                    const cells = row.children.slice(firstColInSelection, lastColInSelection + 1) as TDElement[];

                    return {
                        ...row,
                        // Cells that are in the selection will have isInCellSelection set to true, so we have to
                        // force it to false, otherwise the selected style will be copied too.
                        children: cells.map((cell) => ({ ...cell, isInCellSelection: false })),
                    };
                });

                // We wrap the rebuild rows in a table.
                const copiedTable = createTable({}, filteredRows);
                // Now that we have our rebuild table, we can set it as the copied data as a Slate Fragment
                // DataTransfer only takes strings, so we have to encode it
                data.setData('application/x-slate-fragment', btoa(encodeURIComponent(JSON.stringify(copiedTable))));

                return;
            }

            setFragmentData(data, originEvent);
        },
        insertData: (data) => {
            const fragmentData = data.getData('application/x-slate-fragment');

            if (fragmentData) {
                const decodedFragment = JSON.parse(decodeURIComponent(atob(fragmentData))) as Wrex.Nodes;

                if (decodedFragment.length === 1) {
                    if (isTable(decodedFragment[0])) {
                        // Check if there is only a table in the clipboard

                        const pastedTable = decodedFragment[0] as TableElement;

                        // Get all cells in this table fragment
                        const cells = Array.from(
                            Node.descendants(pastedTable, {
                                pass: ([n]) => isTableCell(n),
                            }),
                        ).filter(([n]) => isTableCell(n));

                        if (cells.length === 1) {
                            // If only one cell in the table fragment, then we just paste the content of it
                            const cell = cells[0][0] as TDElement;
                            const cellContent = cell.children;

                            Transforms.insertFragment(editor, cellContent);
                            return;
                        }

                        const targetTableEntry =
                            editor.selection &&
                            (Array.from(
                                Editor.nodes(editor, { at: editor.selection, match: isTable }),
                            )[0] as NodeEntry<TableElement>);
                        if (editor.selection && targetTableEntry) {
                            const startCell = Array.from(
                                Editor.nodes(editor, { at: editor.selection.anchor, match: isTableCell }),
                            )[0] as NodeEntry<TDElement>;

                            insertTableInTable(editor, pastedTable, targetTableEntry, startCell);

                            return;
                        }

                        Transforms.insertFragment(editor, decodedFragment);

                        return;
                    }
                }
            }

            insertData(data);
        },
        normalizeNode: (nodeEntry) => {
            const nodePathRef = Editor.pathRef(editor, nodeEntry[1]);
            if (normalizeTable(nodeEntry, editor)) {
                return;
            }
            if (normalizeTableRow(nodeEntry, editor)) {
                return;
            }
            if (normalizeTableCell(nodeEntry, editor)) {
                return;
            }

            const updatedPathRef = nodePathRef.unref();
            if (updatedPathRef) {
                normalizeNode([Node.get(editor, updatedPathRef), updatedPathRef]);
            }
        },
        deleteFragment: (direction) => {
            if (editor.getCellSelection().length > 0) {
                deleteContent(editor);
            } else {
                deleteFragment(direction);
            }
        },
        insertTable: partial(insertTable, editor),
        deleteTable: partial(deleteTable, editor),
        addColumn: partial(addColumn, editor),
        deleteColumn: partial(deleteColumn, editor),
        addRow: partial(addRow, editor),
        deleteRow: partial(deleteRow, editor),
        updateCellSelection: partial(updateCellSelection, editor),
        getCellSelection: () => {
            return cellSelection;
        },
        setCellSelection: (newCellSelection) => {
            cellSelection = newCellSelection;
        },
        isTableCurrentlySelected: (path) => {
            return currentlySelectedTablePath.length > 0 && Path.compare(path, currentlySelectedTablePath) === 0;
        },
        setCurrentlySelectedTable: (path) => {
            currentlySelectedTablePath = path;
        },
    };
};

/**
 * A plugin that can handle tables and how to edit it.
 *
 */
export const withTable = () =>
    createPlugin<TableEditor, WrexEditor<TableEditor>>({
        createPluginEditor: createPluginEditor(),
        getKeyHandler,
        elements: [EditableTable, TR, TD],
    });
