import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { Path, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import { T_D, T_R } from '../constants';
import type { TableEditor, TDElement, TRElement } from '../types';
import { getCurrentCellsInRow } from './getCurrentCellsInRow';
import { getCurrentSelectionInfos } from './getCurrentSelectionInfos';

interface AddRowOptions {
    at?: Path;
    preventFocus?: boolean;
}

/** Add a row before or after the current position in a table. */
export const addRow = (editor: ReactEditor & TableEditor, position: 'before' | 'after', options?: AddRowOptions) => {
    /* get selection details (null if no selection or if selection not in table) */
    const selectionInfos = getCurrentSelectionInfos(editor);

    if (!selectionInfos) {
        return undefined;
    }

    const { isSelectionInOneCell, startRow, endRow } = selectionInfos;

    const tablePath = [(options?.at || Range.start(editor.selection as Range).path)[0]];

    /* get the cells to add in the new row */
    const getNewCells = () => {
        /* get the number of cell to add in the row */
        const { cellsInRow } = getCurrentCellsInRow(editor, [...tablePath, startRow]);
        const cellsToAdd = cellsInRow.map(() => ({ type: T_D, children: [createParagraph()] }) as TDElement);
        return cellsToAdd;
    };

    /* build the new row */
    const node: TRElement = {
        type: T_R,
        children: getNewCells(),
    };

    /* define the position to insert row (before or after the current position in table) */

    const getInsertionRow = () => {
        if (position === 'before') {
            return startRow;
        }
        return isSelectionInOneCell ? startRow + 1 : endRow + 1;
    };

    /* insert the new row */
    Transforms.insertNodes(editor, node, { at: [...tablePath, getInsertionRow()] });

    /* focus on the first cell of the new row */
    const newCellPosition = {
        anchor: {
            path: [...tablePath, getInsertionRow(), 0, 0, 0],
            offset: 0,
        },
        focus: {
            path: [...tablePath, getInsertionRow(), 0, 0, 0],
            offset: 0,
        },
    };

    // For some reason, the focusAt doesn't work properly in the table context if we do not delay it.
    if (!options?.preventFocus) {
        setTimeout(() => {
            focusAt(editor, newCellPosition);
        }, 1);
    }
    return newCellPosition;
};
