(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetDriveSettingsController($rootScope, $scope, $timeout, DrivePicker, Translation, Widget,
        WidgetDrive) {
        'ngInject';

        var widgetDriveSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Keep a reference of the previously saved folder.
         *
         * @type {Object}
         */
        var _lastFolderSave = {
            id: undefined,
            name: undefined,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
        * Services and utilities.
        */
        widgetDriveSettings.DrivePicker = DrivePicker;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Callback function called after picking a drive folder.
         *
         * @param {Object} folderInfo An object with info details about the selected folder.
         */
        function drivePickerCallback(folderInfo) {
            if (angular.isUndefinedOrEmpty(folderInfo)) {
                return;
            }

            var properties = Widget.getCurrent().properties;

            $scope.$apply(function applyNewFolder() {
                if (angular.isDefinedAndFilled(folderInfo.id)) {
                    WidgetDrive.setFolderLangProperty(properties, 'folderId', folderInfo.id);
                    WidgetDrive.setFolderLangProperty(properties, 'folderName', folderInfo.name);
                // FIXME [elie]: remove when the TD picker is available.
                } else if (angular.isDefinedAndFilled(folderInfo.url)) {
                    WidgetDrive.setFolderLangProperty(properties, 'folderId', folderInfo.url.split('/').pop());
                }

                widgetDriveSettings.updateWidget();
            });
        }

        /**
         * Get the folder name or the folder id.
         *
         * @return {string} The folder name or id.
         */
        function getFolderName() {
            var properties = Widget.getCurrent().properties;
            var lang = Translation.inputLanguage;

            return WidgetDrive.getFolderLangProperty(properties, 'folderName', lang) ||
                WidgetDrive.getFolderLangProperty(properties, 'folderId', lang, false);
        }

        /**
         * Check if a folder / folder id is set. Otherwise, clear the folder properties.
         *
         * @return {boolean} Whether a folder is selected or not.
         */
        function isFolderSelected() {
            if (angular.isDefined(WidgetDrive.getFolderLangProperty(Widget.getCurrent().properties, 'folderId'))) {
                return true;
            }

            Widget.getCurrent().properties.folderId = undefined;
            Widget.getCurrent().properties.folderName = undefined;

            return false;
        }

        /**
         * Set the folder to default folder or re-set to previous setting.
         */
        function setFolder() {
            var properties = Widget.getCurrent().properties;

            if (properties.isRootFolder) {
                _lastFolderSave.id = angular.copy(WidgetDrive.getFolderLangProperty(properties, 'folderId'));
                _lastFolderSave.name = angular.copy(WidgetDrive.getFolderLangProperty(properties, 'folderName'));

                Widget.getCurrent().properties.folderId = undefined;
                Widget.getCurrent().properties.folderName = undefined;
            } else {
                WidgetDrive.setFolderLangProperty(properties, 'folderId', _lastFolderSave.id || undefined);
                WidgetDrive.setFolderLangProperty(properties, 'folderName', _lastFolderSave.name || undefined);
            }

            widgetDriveSettings.updateWidget();
        }

        /**
         * Update widget on settings change.
         */
        function updateWidget() {
            $timeout(function timedOutSettingsUpdate() {
                $rootScope.$broadcast('widget-drive-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetDriveSettings.drivePickerCallback = drivePickerCallback;
        widgetDriveSettings.getFolderName = getFolderName;
        widgetDriveSettings.isFolderSelected = isFolderSelected;
        widgetDriveSettings.setFolder = setFolder;
        widgetDriveSettings.updateWidget = updateWidget;
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetDriveSettingsController', WidgetDriveSettingsController);
})();
