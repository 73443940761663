import { getMetadataByContentId } from '@lumapps/metadata/api';
import { error as notifyError } from '@lumapps/notifications';
import { batch } from '@lumapps/redux/react';
import { Dispatch } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

import { actions } from '../slice';

/**
 * Get metadata for a content.
 */
export const getContentMetadata = (contentId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.setFetchState({ id: 'metadataDetails', status: 'loading' }));
    try {
        const response = await getMetadataByContentId(contentId);
        batch(() => {
            dispatch(actions.setContentMetadata(response));
            dispatch(actions.setFetchState({ id: 'metadataDetails', status: 'loaded' }));
        });
    } catch (e) {
        batch(() => {
            dispatch(actions.setFetchState({ id: 'metadataDetails', status: 'error' }));
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        });
    }
};
