import React, { useEffect } from 'react';
import { useTranslate } from '@lumapps/translations';
import { Message } from '@lumapps/lumx/react';

import { string, func, bool } from 'prop-types';

/**
 * Will request for a shareable content linked to the given contentId.
 * If it exists, will show a warning message.
 */
const SAShareableWarning = ({ contentId, isShareable, fetchShareableContent }) => {
    const { translateKey } = useTranslate();

    useEffect(() => {
        if (contentId && fetchShareableContent) {
            fetchShareableContent(contentId);
        }
    }, [contentId, fetchShareableContent]);

    return (
        isShareable && (
            <Message kind="warning" hasBackground>
                <p>{translateKey('FRONT.SOCIAL_ADVOCACY.CONTENT.IS_SHAREABLE.WARNING')}</p>
            </Message>
        )
    );
};

SAShareableWarning.propTypes = {
    contentId: string,
    isShareable: bool,
    fetchShareableContent: func,
};

SAShareableWarning.defaultProps = {
    contentId: null,
    isShareable: false,
    fetchShareableContent: null,
};

export { SAShareableWarning };
