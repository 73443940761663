/* istanbul ignore file */
export enum MICRO_APP_CORE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=14002506
     */
    FILE_NAME = 'FRONT.WIDGET_FILE_LIST.SETTINGS.FILE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567866912
     */
    MAX_ELEMENT_DISPLAY_HELPER = 'MICRO_APPS.MAX_ELEMENT_DISPLAY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567866788
     */
    MAX_ELEMENT_DISPLAY_LABEL = 'MICRO_APPS.MAX_ELEMENT_DISPLAY_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567875273
     */
    MAX_ELEMENT_DISPLAY_PLACEHOLDER = 'MICRO_APPS.SET_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13520867
     */
    NO_SELECTED_FILE = 'FRONT.WIDGET_FILE_LIST.SETTINGS.NO_SELECTED_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567853499
     */
    NO_SELECTED_FOLDER = 'MICRO_APP_CORE.NO_SELECTED_FOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567866521
     */
    NO_SELECTED_FOLDER_MESSAGE = 'MICRO_APP_CORE.NO_SELECTED_FOLDER_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13520807
     */
    PICKER_SELECTION_TYPE_FILES = 'FRONT.WIDGET_FILE_LIST.SETTINGS.SELECTION_PICK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13520809
     */
    PICKER_SELECTION_TYPE_FOLDER = 'FRONT.WIDGET_FILE_LIST.SETTINGS.SELECTION_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13966628
     */
    RENAME_FILE_DESC = 'FRONT.WIDGET_FILE_LIST.SETTINGS.RENAME_FILE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567851851
     */
    RESTORE_CONTENT = 'MICRO_APPS.RESTORE_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=522950389
     */
    RESTRICTED_ACCESS_DESCRIPTION = 'MICRO_APP_CORE.RESTRICTED_ACCESS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13520864
     */
    SELECTED_FILES = 'FRONT.WIDGET_FILE_LIST.SETTINGS.SELECTED_FILES',
}
