import React from 'react';

import { classnames } from '@lumapps/classnames';
import type { ElementRender } from '@lumapps/wrex/types';

import { ORDERED_LIST } from '../../../constants';
import type { OrderedListElement } from '../../../types';

import './index.scss';

export const OrderedList: ElementRender<OrderedListElement, HTMLOListElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;

    return (
        <ol {...forwardedProps} className={classnames(className, 'wrex-content-ordered-list')} ref={elementRef}>
            {children}
        </ol>
    );
};
OrderedList.displayName = ORDERED_LIST;
