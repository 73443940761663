export enum LUMX_INPUTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130157
     */
    URL_FIELD_LABEL = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.URL_FIELD.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130166
     */
    URL_FIELD_ERROR_MESSAGE = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.URL_FIELD.ERROR_MESSAGE',
}
