import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { User } from '@lumapps/user/types';

export interface ListParameters {
    maxResults?: number;
    more?: boolean;
    cursor?: string;
    query?: string;
    queryFields?: string[];
    lang?: string;
}
export interface GetParameters {
    /** user's email */
    email?: string;
    /** whether we want to retrieve this information from the memory cache */
    cacheFirst?: boolean;
    /** user's id */
    uid?: string;
    /** user directory id */
    contentId?: string;
}

const userDirectoryApi = new BaseApi({
    path: 'user/directory',
});

const contentApi = new BaseApi({
    path: 'content',
});

const list = (parameters: ListParameters) => {
    return userDirectoryApi.post('/list', parameters);
};

const directoriesList = (parameters: ListParameters) => {
    return contentApi.post('/list', { ...parameters, type: ['user_directory'] });
};

const get = ({ cacheFirst, ...params }: GetParameters) => {
    if (cacheFirst) {
        return userDirectoryApi.getCacheFirst('/get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params });
    }

    return userDirectoryApi.get('/get', { params });
};

export interface SaveParameters extends User {
    /** The id of the user directory to save to */
    contentId: string;
}

const save = (user: SaveParameters) => {
    return userDirectoryApi.post<User>('/save', user);
};

export { list, userDirectoryApi, directoriesList, contentApi, get, save };
