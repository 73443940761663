export enum LEADERBOARD {
    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=480287460
    USER_RANKING = 'LEARNING.USER_RANKING',
    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=480287469
    FULL_RANKING = 'LEARNING.FULL_RANKING',
    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481995778
    AVERAGE_SCORE = 'LEARNING.AVERAGE_SCORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498755468
     */
    CURRENT = 'LEARNING.CURRENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498755477
     */
    PICK = 'LEARNING.PICK',
}
