import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';

export const CONTRIBUTION_WIDGET_FF = 'rich-text-widget';

/** Contribution widget FF enabled. */
export const isContributionWidgetFeatureEnabled = createSelector(
    isFeatureEnabled(CONTRIBUTION_WIDGET_FF),
    (isContributionWidgetFFEnabled) => isContributionWidgetFFEnabled,
);
