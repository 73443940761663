import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { ColorBubbleLetter, ColorBubbleLetterProps } from '@lumapps/lumx-texts/components/ColorBubbleLetter';
import {
    ThumbnailProps as ImageProps,
    Icon,
    IconProps,
    Size,
    IconSizes,
    Thumbnail,
    ThumbnailSize,
    ThumbnailVariant,
    AspectRatio,
} from '@lumapps/lumx/react';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

const Config = get();
// const DEFAULT_SVG = 'M20 20';

export interface ThumbnailProps extends Partial<ImageProps>, Partial<IconProps> {
    /** custom classname for the thumbnail */
    className?: string;
    /** icon to be displayed */
    iconName?: string;
    /** fallback icon that will be showed in case of error */
    fallbackIcon?: IconProps['icon'];
    /** image to be displayed */
    image?: ImageProps['image'];
    /** size of the image or icon to be displayed */
    size?: ImageProps['size'];
    /** Whether the image or icon will be displayed as an inline element or as a block */
    isInline?: boolean;
    /** colors to be used for rendering the color bubble component, by default it uses a list of base lumapps colors */
    colors?: ColorBubbleLetterProps['colors'];
    /** Total number of characters to display when using Color Bubble Letter */
    charactersToDisplay?: number;
    /** whether it should show a loading state while the image is not there */
    useSkeleton?: boolean;
}

const CLASSNAME = 'searchbox-suggestions-thumbnail';

export const SearchBoxOptionThumbnail: React.FC<ThumbnailProps> = ({
    iconName,
    image,
    size = Size.s,
    hasShape,
    colors = Config.defaultColors,
    charactersToDisplay = 1,
    useSkeleton = false,
    alt,
    children,
    ...restOfProps
}) => {
    const { iconPath } = useDynamicIcon({ icon: iconName || '' });
    const { element, block } = useClassnames(CLASSNAME);
    if (!iconName && !image && !alt && !children) {
        return null;
    }

    const isIconValid = Boolean(iconName);

    const imageClassName = element('img', {
        rounded: !isIconValid,
        skeleton: useSkeleton,
    });

    if (children) {
        return <div className={block()}>{children}</div>;
    }

    return (
        <div className={block()} title={alt}>
            {image && (
                <Thumbnail
                    {...restOfProps}
                    image={image}
                    size={size as ThumbnailSize}
                    className={imageClassName}
                    alt={alt || ''}
                    variant={ThumbnailVariant.rounded}
                    aspectRatio={AspectRatio.square}
                />
            )}
            {!image && isIconValid && iconPath && (
                <Icon {...restOfProps} icon={iconPath} hasShape={hasShape} size={size as IconSizes} />
            )}
            {!image && (!iconPath || !isIconValid) && alt && (
                <ColorBubbleLetter colors={colors} text={alt} size={size} maxLength={charactersToDisplay} />
            )}
        </div>
    );
};
