import isEmpty from 'lodash/isEmpty';

import { getContentLayoutById } from '@lumapps/content-layout/api';
import { ContentTypes } from '@lumapps/content-types/types';
import { updateInstance } from '@lumapps/instance/utils/updateInstance';
import { Dispatch } from '@lumapps/redux/types';
import { actions as routerActions } from '@lumapps/router/ducks/slice';
import { standardizeTranslateObject } from '@lumapps/translations';

import { getContentForView } from '../../api/contents';
import { actions } from '../../ducks/slice';
import { Content, ContentLinkRef } from '../../types';

type Params = {
    to: ContentLinkRef;
    languageHeader: string;
    currentInstanceId?: string;
    dispatch: Dispatch;
    translateObject: any;
};
type Required = Params & {
    to: ContentLinkRef & { id: string };
};

/** Fetch content to update the content link ref. */
async function updateContent({ to, dispatch, translateObject }: Required): Promise<ContentLinkRef> {
    const contentLinkRef: ContentLinkRef = {};
    const content = await getContentForView(to.id);
    if (content.link && !isEmpty(content.link)) {
        contentLinkRef.link = translateObject(standardizeTranslateObject(content.link));
    } else if (content.type !== ContentTypes.POST) {
        contentLinkRef.slug = translateObject(standardizeTranslateObject(content.slug));
    }
    dispatch(actions.setContents([content]));
    return contentLinkRef;
}

/** Fetch layout to update the content link ref. */
async function updateLayout({ to, languageHeader, dispatch }: Required): Promise<ContentLinkRef | undefined> {
    // Skip updating the layout if we already know the content is not V2 compatible
    if (to.isV2Compatible === false) {
        return undefined;
    }
    const layoutPromise = await getContentLayoutById(to.id, languageHeader).catch(() => null);
    const isV2Compatible = Boolean(layoutPromise?.isV2Compatible);
    // Mark content as V2 compatible in the store
    dispatch(actions.setContents([{ id: to.id, template: { isV2Compatible } } as Content]));
    return { isV2Compatible };
}

/**
 * Fetch content+layout (+instance if needed) to update the content link ref.
 */
export async function fetchUpdateContentLinkRef(params: Params): Promise<ContentLinkRef> {
    const { to } = params;
    if (!to.id) {
        return to;
    }
    const requiredParams = params as Required;
    try {
        // Call layout & content (+instance) in parallel.
        const responses = await Promise.all([
            updateLayout(requiredParams),
            updateContent(requiredParams),
            updateInstance(requiredParams),
        ]);

        // Merge data into one content link ref.
        return responses.reduce((acc: ContentLinkRef, contentLinkRef) => {
            if (contentLinkRef) {
                return { ...acc, ...contentLinkRef };
            }
            return acc;
        }, to);
    } catch (e) {
        // Return unchanged content link ref.
        return to;
    }
}

/**
 * Prepare call to `fetchUpdateContentLinkRef` with `prefetch` and `fetch` functions.
 */
export function prepareFetchUpdateContentLinkRef(params: Params) {
    const { dispatch } = params;

    const fetchWithoutLoader = () => {
        return fetchUpdateContentLinkRef(params);
    };
    const fetchWithLoader = async () => {
        try {
            // Show global router loader.
            dispatch(routerActions.showLoader());

            return await fetchWithoutLoader();
        } finally {
            // Hide global router loader.
            dispatch(routerActions.hideLoader());
        }
    };

    return { prefetch: fetchWithoutLoader, fetch: fetchWithLoader };
}
