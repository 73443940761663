import React from 'react';

import { getCurrentInstanceParentId } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { useChildNavStyles } from '@lumapps/style/hooks/useChildNavStyles';
import { useNavStyles } from '@lumapps/style/hooks/useNavStyles';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { useIsNavigationDisabled } from '../../hooks/useIsNavigationDisabled';
import { ResponsiveMainSideNavigationProps, MainTopNavigationProps } from '../../types';
import { MainTopNavigationLoadingState } from '../MainTopNavigation/MainTopNavigationLoadingState';

const Navigation = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'desktop-main-side-navigation' */
            '../MainTopNavigation'
        ),
);

const ResponsiveNavigation = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'responsive-main-side-navigation' */
            '../ResponsiveMainSideNavigation'
        ),
);

export type MainNavigationProps = Partial<ResponsiveMainSideNavigationProps> &
    Partial<MainTopNavigationProps> & {
        instance: string;
    };

const MainNavigation: React.FC<MainNavigationProps> = ({
    id,
    isOpen,
    onClose,
    instance,
    isMainNavInheritanceEnabled,
    isParentNav,
    isDisabled,
    ...rest
}) => {
    const { isMobile } = useResponsive();
    const isUserConnected = useSelector(isConnected);
    const parentId = useSelector(getCurrentInstanceParentId);
    let navStyles = useNavStyles();
    const childNavStyles = useChildNavStyles();
    const { isNavigationDisabled, isSubNavigationDisabled, isNavigationUiDisabled, isSubNavigationUiDisabled } =
        useIsNavigationDisabled();

    /**
     * When it comes to styles, we need to determine which one to use depending on the current context.
     * If the navigation inheritance feature is enabled, we need to check whether we are rendering the
     * child navigation or the parent one, and use the correct style.
     *
     * If that FF is not enabled, but the current site has a parent site
     * the style that we want to use is the style related to the child instance.
     *
     * Unfortunately this only applies to the new apps env, since the legacy application does not distinguish
     * between child and parent styles.
     *
     * This behaviour is also present in the following files, so keep in mind to change them
     * as well if you change something here:
     * - packages/lumx/components/Backoffice/NavigationHeader/NavigationHeader.tsx
     * - packages/header/components/Header/index.tsx
     */
    if (isMainNavInheritanceEnabled) {
        navStyles = isParentNav ? navStyles : childNavStyles;
    } else if (parentId && !childNavStyles.isDefault) {
        navStyles = childNavStyles;
    }

    // Close the sideNavigation if we are not on a mobile
    React.useLayoutEffect(() => {
        if (!isMobile && onClose) {
            onClose();
        }
    }, [isMobile, onClose]);

    if (isMobile) {
        if (isUserConnected) {
            return isOpen ? (
                <Suspense>
                    <ResponsiveNavigation
                        isOpen
                        onClose={onClose}
                        instance={instance}
                        parentInstanceId={parentId}
                        {...rest}
                    />
                </Suspense>
            ) : null;
        }

        return null;
    }

    /**
     * These variables will help determine whether we need to download the navigation code and/or display the loading
     * state for the navigation. Throught the customizations API, devs are able to hide the entire navigation or subnavigation,
     * thus avoiding downloading code as well as data, or they can just hide the UI, which means that the data will be fetched
     * but the UI will not display.
     * - The code should only be downloaded when either the main nav or the sub nav are enabled.
     * - The loading state should only be displayed when the UIs are not disabled.
     */
    const hasParentNav = isMainNavInheritanceEnabled && parentId;

    const isMainNavEnabled = hasParentNav ? !isNavigationDisabled && isParentNav : !isNavigationDisabled;
    const isSubNavEnabled = hasParentNav ? !isSubNavigationDisabled && !isParentNav : false;
    const isMainNavUiEnabled = hasParentNav ? !isNavigationUiDisabled && isParentNav : !isNavigationUiDisabled;
    const isSubNavUiEnabled = hasParentNav ? !isSubNavigationUiDisabled && !isParentNav : false;

    const shouldLoadComponent = isMainNavEnabled || isSubNavEnabled;
    const shouldShowLoadingState = isMainNavUiEnabled || isSubNavUiEnabled;

    return shouldLoadComponent ? (
        <Suspense
            // eslint-disable-next-line react/jsx-no-useless-fragment
            loadingFallback={shouldShowLoadingState ? <MainTopNavigationLoadingState navStyles={navStyles} /> : <></>}
        >
            <Navigation
                id={id}
                instance={instance}
                {...rest}
                styles={navStyles}
                isMainNavInheritanceEnabled={isMainNavInheritanceEnabled}
                isParentNav={isParentNav}
                isDisabled={isDisabled}
            />
        </Suspense>
    ) : null;
};

export { MainNavigation };
