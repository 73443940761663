import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { instanceSettingsRoute } from '@lumapps/instance-settings/routes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiChartLine, mdiDns, mdiMenu, mdiSettings } from '@lumapps/lumx/icons';
import { METADATA_SCOPES } from '@lumapps/metadata-admin/constants';
import { metadataAdminSectionRoute } from '@lumapps/metadata-admin/routes';
import { adminMainNavigation } from '@lumapps/navigation-admin/routes';
import { Link as RouterLink } from '@lumapps/router';
import { siteAnalyticsRoute } from '@lumapps/site-analytics/routes';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SETTINGS_MENU } from '../../keys';

export interface SettingsMenuSiteProps {
    canAccessSiteAnalytics: boolean;
    hasMainNavAdminAccess: boolean;
    canEditInstance: boolean;
    hasMetadataAdminRights: boolean;
    scope: string;
}

export const SettingsMenuSite: React.FC<SettingsMenuSiteProps> = ({
    canAccessSiteAnalytics,
    hasMainNavAdminAccess,
    canEditInstance,
    hasMetadataAdminRights,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!canAccessSiteAnalytics && !canEditInstance && !hasMainNavAdminAccess && !hasMetadataAdminRights) {
        return null;
    }

    return (
        <Menu.Section label={translateKey(SETTINGS_MENU.SUB_INSTANCE)} {...get({ element: 'instance' })}>
            {hasMetadataAdminRights && (
                <Menu.Item
                    icon={mdiDns}
                    as={RouterLink}
                    to={metadataAdminSectionRoute({ section: METADATA_SCOPES.site })}
                    {...get({ element: 'metadata' })}
                >
                    {translateKey(GLOBAL.METADATA) || ''}
                </Menu.Item>
            )}
            {canAccessSiteAnalytics && (
                <Menu.Item
                    icon={mdiChartLine}
                    as={RouterLink}
                    to={siteAnalyticsRoute}
                    {...get({ element: 'analytics' })}
                >
                    {translateKey(GLOBAL.ANALYTICS) || ''}
                </Menu.Item>
            )}
            {hasMainNavAdminAccess && (
                <Menu.Item icon={mdiMenu} as={RouterLink} to={adminMainNavigation()} {...get({ element: 'main-nav' })}>
                    {translateKey(SETTINGS_MENU.NAVIGATION) || ''}
                </Menu.Item>
            )}
            {canEditInstance && (
                <Menu.Item
                    icon={mdiSettings}
                    as={RouterLink}
                    to={instanceSettingsRoute}
                    {...get({ element: 'instance-settings' })}
                >
                    {translateKey(GLOBAL.SETTINGS) || ''}
                </Menu.Item>
            )}
        </Menu.Section>
    );
};

SettingsMenuSite.displayName = 'SettingsMenuSite';
