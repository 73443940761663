import React from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { EmptyState, EmptyStateProps } from '@lumapps/lumx-states/components/EmptyState';
import { mdiPlus } from '@lumapps/lumx/icons';
import {
    AlertDialog,
    Button,
    Dialog,
    Emphasis,
    FlexBox,
    Heading,
    Kind,
    Message,
    MessageProps,
    Orientation,
    Size,
    Toolbar,
    Text,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { focusNext } from '@lumapps/utils/elements/focus/focusNext';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import './index.scss';

const CLASSNAME = 'lumx-resource-ordering-dialog';

interface ActiveState {
    index: number;
    changed: boolean;
    doFocusGrid: boolean;
}

export type WithKey = { key: string };

export interface RightElementProps<T extends WithKey> {
    /** The active resource */
    active: ActiveState;
    /** Set the active resource */
    setActive: React.Dispatch<React.SetStateAction<ActiveState>>;
    /** The ref of the right element */
    ref: React.Ref<HTMLDivElement>;
    /** The resources to display */
    resources: T[];
}
export interface LeftElementProps {
    /** The resources to display */
    resourcesItems: JSX.Element[];
    /** Set the resources to display */
    setResourcesItems?: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
    /** Callback when focusing a resource */
    onResourceFocus: (index: number) => void;
    /** Callback when pressing enter on a resource */
    onResourceEnter: () => void;
}

export interface ResourceOrderingDialogProps<T extends WithKey> {
    /** Title of the dialog */
    dialogTitle: string;
    /** The props to pass to the resource empty state */
    emptyStateProps: EmptyStateProps;
    /** Whether the dialog is open */
    isOpen?: boolean;
    /** Whether the dialog is loading */
    isLoading?: boolean;
    /** The props to pass to the resource message */
    resourceMessageProps?: Partial<MessageProps>;
    /** Text to display on the add resource button */
    addResourceButtonLabel?: string;
    /** Message to warn the user about the max resource number */
    maxResourceMessage?: string;
    /** The maximum number of resources allowed */
    maxResourcesNumber?: number;
    /** Feature scope for tracking and a11y purposes */
    scope: string;
    /** Whether a confirm dialog should be displayed on discard */
    shouldDisplayConfirmDialog?: boolean;
    /** Callback when picking more resources on the dialog */
    onAddResources?: () => void;
    /** Callback when closing the dialog */
    onClose: () => void;
    /** Callback when saving the dialog */
    onSave: () => void;
    /** The resources to display */
    resources: T[];
    /** The renderer for each resource in the grid */
    resourceRenderer: (resource: T, index: number) => React.ReactNode;
    /** The right side element of the dialog */
    rightElementRenderer: (props: RightElementProps<T>) => React.ReactNode;
    /** The left side element of the dialog */
    leftElementRenderer: (props: LeftElementProps) => React.ReactNode;
    /** Whether we should focus the resource parent or the resource itself */
    shouldFocusResourceParent?: boolean;
    /** Whether we open dialog in edition mode */
    isEdit?: boolean;
}

/**
 * Component that displays a dialog allowing ordering, removing and replacing of picked resources.
 * @family Dialogs
 * @param ResourceOrderingDialogProps
 * @returns ResourceOrderingDialog
 */
export const ResourceOrderingDialog: <T extends WithKey>(
    props: ResourceOrderingDialogProps<T>,
) => React.ReactElement<ResourceOrderingDialogProps<T>> = ({
    dialogTitle,
    emptyStateProps,
    isOpen,
    isLoading,
    resourceMessageProps,
    addResourceButtonLabel,
    maxResourceMessage,
    maxResourcesNumber,
    scope,
    shouldDisplayConfirmDialog,
    onAddResources,
    onClose,
    onSave,
    resources,
    resourceRenderer,
    rightElementRenderer,
    leftElementRenderer,
    shouldFocusResourceParent = false,
    isEdit,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);
    const { block, element } = useClassnames(CLASSNAME);
    const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
    const resourcesNumber = resources?.length || 0;

    const rightElementRef = React.useRef<HTMLDivElement>(null);
    const [active, setActive] = React.useState<ActiveState>({
        index: 0,
        changed: false,
        doFocusGrid: true,
    });
    const activeRef = React.useCallback(
        (node: HTMLDivElement) => {
            if (!node) {
                return;
            }

            // Scroll the active resource into view when it changes
            // from the right element
            node.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

            // Focus the active resource when it changes
            // Because the grid re-renders when the active resource changes
            if (active.doFocusGrid) {
                if (shouldFocusResourceParent) {
                    node.parentElement?.focus();
                } else {
                    node.focus();
                }
            }
        },
        [active, shouldFocusResourceParent],
    );

    const handleClose = () => {
        if (shouldDisplayConfirmDialog) {
            openConfirmDialog();
        } else {
            onClose();
        }
    };

    const handleConfirmDialogClose = () => {
        closeConfirmDialog();
        onClose();
    };

    return (
        <>
            <Dialog
                {...get({ element: 'dialog' })}
                className={block([scope])}
                isOpen={isOpen}
                forceFooterDivider
                forceHeaderDivider
                size={Size.huge}
            >
                <header>
                    <Toolbar label={<Heading typography="title">{dialogTitle}</Heading>} />
                </header>
                {resources && resources.length > 0 ? (
                    <FlexBox className={element('content')} orientation={Orientation.horizontal}>
                        <FlexBox
                            className={element('left-content', [padding('all', 'huge')])}
                            orientation={Orientation.vertical}
                            gap="big"
                        >
                            <FlexBox
                                className={element('resource-list-header')}
                                orientation={Orientation.horizontal}
                                gap="regular"
                                hAlign="center"
                                vAlign="space-between"
                            >
                                {maxResourcesNumber && maxResourceMessage && (
                                    <Message kind={Kind.info} {...resourceMessageProps}>
                                        {maxResourceMessage}
                                    </Message>
                                )}

                                {(!maxResourcesNumber || resourcesNumber < maxResourcesNumber) && !!onAddResources && (
                                    <Button
                                        emphasis={Emphasis.medium}
                                        onClick={onAddResources}
                                        leftIcon={mdiPlus}
                                        {...get({ element: 'button', action: 'add-resources' })}
                                    >
                                        {addResourceButtonLabel}
                                    </Button>
                                )}
                            </FlexBox>
                            <FlexBox className={element('resource-list')} orientation={Orientation.vertical}>
                                {leftElementRenderer({
                                    resourcesItems: resources?.map((resource, index) => {
                                        return (
                                            <div
                                                className={element('resource-button', {
                                                    active: active.index === index,
                                                })}
                                                key={resource.key}
                                                ref={index === active.index ? activeRef : undefined}
                                            >
                                                {resourceRenderer(resource, index)}
                                            </div>
                                        );
                                    }),
                                    onResourceFocus: (index) => {
                                        if (index !== active.index) {
                                            setActive({ index, changed: true, doFocusGrid: true });
                                        }
                                    },
                                    onResourceEnter: () => {
                                        if (rightElementRef.current) {
                                            focusNext(rightElementRef.current);
                                        }
                                    },
                                })}
                            </FlexBox>
                        </FlexBox>
                        <FlexBox
                            className={element('right-content', [padding('all', 'huge')])}
                            orientation={Orientation.vertical}
                            gap="big"
                        >
                            {rightElementRenderer({ active, setActive, ref: rightElementRef, resources })}
                        </FlexBox>
                    </FlexBox>
                ) : (
                    <FlexBox
                        className={element('content', [padding('all', 'huge')])}
                        gap="big"
                        orientation={Orientation.vertical}
                    >
                        {maxResourcesNumber && maxResourceMessage && (
                            <Message kind={Kind.info} {...resourceMessageProps}>
                                {maxResourceMessage}
                            </Message>
                        )}
                        <EmptyState {...emptyStateProps} />
                    </FlexBox>
                )}
                <footer>
                    <Toolbar
                        after={
                            <>
                                <Button
                                    className={element('cancel-button')}
                                    emphasis={Emphasis.medium}
                                    onClick={handleClose}
                                    {...get({ element: 'button', action: 'close' })}
                                >
                                    {translateKey(GLOBAL.DISCARD)}
                                </Button>

                                <Button
                                    className={element('save-button', [margin('left', 'regular')])}
                                    onClick={onSave}
                                    isDisabled={resourcesNumber <= 0 || isLoading}
                                    {...get({ element: 'button', action: isEdit ? 'edit-save' : 'save' })}
                                >
                                    {translateKey(GLOBAL.SAVE)}
                                </Button>
                            </>
                        }
                    />
                </footer>
            </Dialog>

            <AlertDialog
                kind={Kind.warning}
                isOpen={isConfirmDialogOpen}
                title={translateKey(GLOBAL.DISCARD_CHANGES)}
                confirmProps={{
                    onClick: handleConfirmDialogClose,
                    label: translateKey(GLOBAL.DISCARD),
                }}
                cancelProps={{
                    onClick: closeConfirmDialog,
                    label: translateKey(GLOBAL.KEEP_EDITING),
                }}
            >
                <Text as="p" typography={Typography.body1}>
                    {translateKey(GLOBAL.WARNING_UNSAVED_CHANGES)}
                </Text>
            </AlertDialog>
        </>
    );
};
