import BaseApi from '@lumapps/base-api';

import { FollowInterestPayload } from '../types';

export const followInterestApi = new BaseApi({
    version: BaseApi.versions.v1,
    path: 'content',
});

export const findInterest = async (payload: FollowInterestPayload) => followInterestApi.post('interest/find', payload);

export const saveInterest = async (payload: FollowInterestPayload) => followInterestApi.post('interest/save', payload);
