import React from 'react';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { getActionsHistory } from '@lumapps/quick-search/utils/cacheUtils';
import { useSelector } from '@lumapps/redux/react';
import { queueTrackDocument, queueTrackSearch } from '@lumapps/search/api/analytics';
import { getCurrentFeatures, searchContextSelector } from '@lumapps/search/ducks/selectors';
import { useSearch } from '@lumapps/search/hooks/useSearch';
import { BaseSearchResult, DOCUMENT_CAUSES, SearchItem } from '@lumapps/search/types';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { fetchRecommendations } from '../api';
import { LUMAPPS_RECO_ORIGIN } from '../constants';
import { FetchRecommendationsResponse } from '../types';

type SimplifiedSearchItem = Pick<SearchItem, 'id' | 'title'>;

export type UseRecommendationsReturn = {
    /** The current fetch status. */
    status: BaseLoadingStatus;
    items?: BaseSearchResult[] | null;
    onItemClick: (item: SimplifiedSearchItem, position: number) => void;
};

export const useRecommendations = (canFetch = true, skipAnalyticsOnFetch = false): UseRecommendationsReturn => {
    const lang = useSelector(currentLanguageSelector);
    const { isRecommendationEnabled } = useSelector(getCurrentFeatures);
    const context = useSelector(searchContextSelector);
    const instanceId = useSelector(instanceIdSelector);

    const { cause, query } = useSearch();

    const { status, response, fetch } = useFetchWithStatus<FetchRecommendationsResponse>({
        onFetch: () => fetchRecommendations({ lang }),
        fetchOnMount: false,
    });

    React.useEffect(() => {
        if (isRecommendationEnabled && canFetch && status === BaseLoadingStatus.initial) {
            fetch();
        }
    }, [canFetch, fetch, isRecommendationEnabled, status]);

    React.useEffect(() => {
        if (response && !skipAnalyticsOnFetch) {
            const { splitTestRunName, splitTestRunVersion, resultCountExact, page, responseTime, searchQueryUid } =
                response;
            // Send Search analytics
            queueTrackSearch({
                splitTestRunName,
                splitTestRunVersion,
                language: lang,
                actionCause: cause,
                numberOfResults: resultCountExact,
                queryText: query || '',
                pageNumber: page,
                responseTime,
                searchQueryUid,
                context,
                actionsHistory: getActionsHistory(instanceId),
                originLevel2: LUMAPPS_RECO_ORIGIN,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    const onItemClick = ({ id, title }: SimplifiedSearchItem, position: number) => {
        const result = response?.items.find((item) => item.id === id);

        if (result) {
            queueTrackDocument({
                originLevel2: LUMAPPS_RECO_ORIGIN,
                actionCause: DOCUMENT_CAUSES.click,
                documentPosition: position,
                documentTitle: title,
                documentUri: result.metadata.documentUri,
                documentUriHash: result.metadata.documentUriHash,
                documentUrl: result.metadata.documentUri,
                language: lang,
                sourceName: result.metadata.sourceName,
                searchQueryUid: response?.searchQueryUid,
                rankingModifier: result.metadata.rankingModifier,
                actionsHistory: getActionsHistory(instanceId),
                customData: {
                    contentIDKey: result.metadata.contentIDKey,
                    contentIDValue: result.metadata.contentIDValue,
                    documentTitle: title,
                    documentUrl: result.metadata.documentUri,
                },
                context,
            });
        }
    };

    return {
        status,
        items: response?.items,
        onItemClick,
    };
};
