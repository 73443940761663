import React from 'react';

import { Button, Emphasis } from '@lumapps/lumx/react';
import { useDispatch } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { requestAccess } from '../../ducks/thunks';
import { SPACE_KEYS } from '../../keys';
import { SpaceAccessRequestStatus } from '../../types';

interface SpaceAccessRequestProps {
    canRequest: boolean;
    isRequestLoading?: boolean;
    requestStatus?: SpaceAccessRequestStatus;
}

export const SpaceAccessRequest: React.FC<SpaceAccessRequestProps> = ({
    canRequest,
    isRequestLoading = false,
    requestStatus,
}) => {
    const { translateKey } = useTranslate();
    const dispatch = useDispatch();

    const onRequestAccess = () => dispatch(requestAccess);

    const isRequestPending = requestStatus === SpaceAccessRequestStatus.pending;

    if (canRequest || isRequestPending || isRequestLoading) {
        return (
            <Button
                emphasis={Emphasis.medium}
                isDisabled={isRequestPending || isRequestLoading}
                onClick={onRequestAccess}
            >
                {translateKey(isRequestPending ? SPACE_KEYS.ACCESS_REQUEST_PENDING : SPACE_KEYS.ACCESS_REQUEST)}
            </Button>
        );
    }

    return null;
};
