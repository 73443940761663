import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { DeserializeOptions } from '@lumapps/wrex/serialization/markdown/fromMarkdown/types';

import { createTable } from '../../utils/createTable';
import { createTD } from '../../utils/createTD';
import { createTR } from '../../utils/createTR';

export const TABLE_ELEMENTS: DeserializeOptions['elements'] = {
    table: {
        convert(_, children) {
            // <th> are not supported for now, so we keep them as <td>
            return createTable({ readOnly: true }, children);
        },
    },
    tableRow: {
        convert: (_, children) => createTR(children),
    },
    tableCell: {
        convert: (_, children) => {
            if (!children.length) {
                // Children can't be empty.
                children.push({ text: '' });
            }
            return createTD([createParagraph(children)]);
        },
    },
};
