/* eslint-disable no-param-reassign */
import difference from 'lodash/difference';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Event } from '../api/types';
import { EventPreviewState } from '../types';

export const initialState: EventPreviewState = {};

export const { actions, reducer } = createSlice({
    domain: 'eventPreview',
    initialState,
    reducers: {
        pushBatchIdEvent: (state: EventPreviewState, action: PayloadAction<string>) => {
            const eventBatchIds = [...(state.eventBatchIds || [])];
            eventBatchIds.push(action.payload);
            state.eventBatchIds = eventBatchIds;
        },
        setEventEntities: (state: EventPreviewState, action: PayloadAction<Record<string, Event | null>>) => {
            if (!state.entities) {
                state.entities = {};
            }

            state.entities = { ...state.entities, ...action.payload };
        },
        deleteBatchIdEvent: (state: EventPreviewState, action: PayloadAction<string[]>) => {
            state.eventBatchIds = state.eventBatchIds && difference([...state.eventBatchIds], action.payload);
        },
    },
});
