import curry from 'lodash/fp/curry';
import getOr from 'lodash/fp/getOr';
import identity from 'lodash/fp/identity';
import isArray from 'lodash/fp/isArray';
import isObject from 'lodash/fp/isObject';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

type Transformation = (arg: any) => any;

/**
 * This function creates a new object by transforming each property according to the transformation function having the same keys in the transformations object.
 * A transformation function will not be invoked if its corresponding key does not exist in the object to be evolved.
 * If a value does not have a corresponding transformation function it will simply be identical in the output.
 */
export default curry(function evolve(
    transformations: Record<string, Transformation> | Array<Transformation>,
    objectOrArray: Record<string, any> | Array<any>,
): Record<string, any> | Array<any> {
    if (!isObject(objectOrArray) && !isArray(objectOrArray)) {
        return objectOrArray;
    }

    const iteratee = (value: any, key: string) => {
        const transformation = getOr(identity, key, transformations);

        if (typeof transformation === 'function') {
            return transformation(value);
        }
        if (transformation && typeof transformation === 'object') {
            return evolve(transformation, value);
        }
        return value;
    };

    return isArray(objectOrArray) ? map(objectOrArray, iteratee) : mapValues(objectOrArray, iteratee);
});
