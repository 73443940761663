/* istanbul ignore file */

import BaseApi from '@lumapps/base-api';
import { ServerListResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { ContactField } from '@lumapps/user/types';

import {
    UserTokenStatusQuery,
    UserTokenStatusAPI,
    ChatProviderId,
    UserSpaceProps,
    ChatChannel,
    ChatProvider,
} from '../types';

const chatApi = new BaseApi({ path: 'chat' });

export interface FetchProvidersResponse {
    providers: Array<ChatProvider>;
}

const fetchProviders = () => {
    return chatApi.getCacheFirst<FetchProvidersResponse>('providers/list', CACHE_TYPE.MEMORY, PRIORITY.LOW);
};

export interface FetchUserSpacesParams {
    // Provider (teams, yammer, slack...).
    provider: ChatProviderId;
    // Page max results.
    maxResults?: number;
    // Page cursor.
    cursor?: string;
    // Page number (used for manual pagination)
    page?: number;
}

export interface FetchUserSpacesResponse extends ServerListResponse<UserSpaceProps> {
    // Page number (used for manual pagination)
    page?: number;
}

const listUserSpaces = ({ provider, maxResults, cursor, page }: FetchUserSpacesParams, fetchKey?: string) => {
    return chatApi.post<FetchUserSpacesResponse>(
        'userSpaces/list',
        {
            cursor,
            maxResults,
            page,
            provider,
        },
        undefined,
        true,
        fetchKey,
    );
};

export interface FetchUserSpaceById {
    // Provider (teams, yammer, slack...).
    provider: ChatProviderId;
    // Page max results.
    spaceId?: string;
}
export interface FetchUserSpaceByIdResponse {
    space: UserSpaceProps;
}

export interface FetchJoinedUserSpacesQuery {
    provider: string;
    maxResults?: number;
    page?: number;
}

const fetchJoinedUserSpaces = ({ provider, maxResults, page }: FetchJoinedUserSpacesQuery) => {
    return chatApi.post('userSpaces/list/joined', {
        maxResults,
        page,
        provider,
    });
};

export interface FetchChannelsQuery {
    provider: string;
    spaceId: string;
    maxResults?: number;
    page?: number;
}

const fetchChannels = ({ provider, spaceId, maxResults, page }: FetchChannelsQuery, fetchKey?: string) => {
    return chatApi.post<ServerListResponse<ChatChannel>>(
        'channels/list',
        {
            maxResults,
            page,
            provider,
            spaceId,
        },
        undefined,
        true,
        fetchKey,
    );
};

const fetchJoinedChannels = ({ provider, spaceId, maxResults, page }: FetchChannelsQuery) => {
    return chatApi.post<ServerListResponse<ChatChannel>>('channels/list/joined', {
        maxResults,
        page,
        provider,
        spaceId,
    });
};

export interface FetchChannelByNameQuery {
    provider: ChatProviderId;
    spaceId: string;
    // The channel name to search
    channelName: string;
}
export interface FetchChannelByNameResponse {
    channel: Partial<ChatChannel>;
}

export interface FetchChannelByIdQuery {
    provider: ChatProviderId;
    spaceId: string;
    // The channel id to search
    channelId: string;
}
export interface FetchChannelByIdResponse {
    channel: Partial<ChatChannel>;
}

export interface PostToChannelsQuery {
    provider: string;
    spaceId: string;
    channelId: string;
    body: string;
    bodyContentType: string;
    chatMessageId?: string;
}

const postToChannel = ({ provider, spaceId, channelId, chatMessageId, body, bodyContentType }: PostToChannelsQuery) => {
    return chatApi.post('messages/create', {
        body,
        bodyContentType,
        channelId,
        chatMessageId,
        provider,
        spaceId,
    });
};

const fetchUserTokenStatus = (params: UserTokenStatusQuery) => {
    return chatApi.get<UserTokenStatusAPI>('userToken/status', { params });
};

const fetchUserTokenAuthorize = (params: UserTokenStatusQuery) => {
    return chatApi.get<any>('userToken/authorize', { params });
};

const disconnectUserToken = (params: UserTokenStatusQuery) => {
    return chatApi.get<any>('userToken/disconnect', { params });
};

export interface ContactLinksQuery {
    currentUserEmail: string;
    userEmail: string;
}

export interface ContactLinksResponse {
    contacts: ContactField[];
}

/**
 *
 * Get a list of contacts links
 *
 */
const getContactLinks = async (params: ContactLinksQuery) => {
    return chatApi.get<ContactLinksResponse>('contact/user/list', { params });
};

export {
    fetchProviders,
    fetchJoinedUserSpaces,
    fetchChannels,
    fetchJoinedChannels,
    postToChannel,
    listUserSpaces,
    chatApi,
    fetchUserTokenStatus,
    fetchUserTokenAuthorize,
    disconnectUserToken,
    getContactLinks,
};
