import React from 'react';

import { FormProvider as ReactHookFormProvider, FormProviderProps } from 'react-hook-form';

import { LumxFormContext, LumxFormContextOptions } from './FormContext';

export type LumxFormProviderProps = FormProviderProps & LumxFormContextOptions;

/**
 * LumxFormProvider that wraps the FormProvider from react-hook-form and adds additional custom features.
 * @returns LumxFormProvider
 */
const LumxFormProvider: React.FC<LumxFormProviderProps> = ({ children, defaultFormValues, scope, ...props }) => {
    const contextValues = React.useMemo(() => {
        return {
            defaultFormValues,
            scope,
        };
    }, [defaultFormValues, scope]);

    return (
        <LumxFormContext.Provider value={contextValues}>
            <ReactHookFormProvider {...props}>{children}</ReactHookFormProvider>
        </LumxFormContext.Provider>
    );
};

export { LumxFormProvider as FormProvider };
