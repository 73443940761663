/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyComponent = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'lumx-calendar' */
            './components/Calendar/Calendar'
        ),
);

/**
 * Display a Calendar with three available display mode :
 * - Week: show the current week
 * - Month: show the current month
 * - Schedule: Show the next batch of events, ordered by days.
 */
export const Calendar: React.FC<React.ComponentProps<typeof LazyComponent>> = ({ ...props }) => {
    return (
        <DelayedSuspense shouldLoad>
            <LazyComponent {...props} />
        </DelayedSuspense>
    );
};
