import set from 'lodash/set';

import { PayloadAction } from '@lumapps/redux/createSlice';

import { FetchMembersResult } from '../../api';
import { Member } from '../../types';

export enum MemberListStates {
    initial = 'initial',
    loading = 'loading',
    reloading = 'reloading',
    loadingMore = 'loadingMore',
    fetchFailure = 'fetchFailure',
    hasMore = 'hasMore',
    done = 'done',
}

export interface MemberListState {
    state: MemberListStates;
    entities: Member[];
    cursor: string;
    count: number;
    maxResults: number;
    filters: Record<string, any>;
}

export const initialState: MemberListState = {
    state: MemberListStates.initial,
    entities: [],
    cursor: '',
    count: 0,
    maxResults: 0,
    filters: {
        role: '',
        memberName: '',
    },
};

export type SetFiltersPayload = {
    filters: Record<string, any>;
    merge?: boolean;
};

export const memberListReducer = {
    fetch: (state: MemberListState): void => {
        set(state, 'state', MemberListStates.loading);
    },
    fetchMore: (state: MemberListState): void => {
        set(state, 'state', MemberListStates.loadingMore);
    },
    fetchSuccess: (state: MemberListState, action: PayloadAction<FetchMembersResult>): void => {
        set(state, 'cursor', action.payload.cursor);
        set(state, 'count', action.payload.count);
        set(state, 'maxResults', action.payload.maxResults);
        set(
            state,
            'entities',
            state.state === MemberListStates.loadingMore
                ? [...state.entities, ...action.payload.items]
                : action.payload.items,
        );
        set(state, 'state', action.payload.more ? MemberListStates.hasMore : MemberListStates.done);
    },
    fetchFailure: (state: MemberListState): void => {
        set(
            state,
            'state',
            // If current state was loadingMore, return to hasMoreState
            state.state === MemberListStates.loadingMore ? MemberListStates.hasMore : MemberListStates.fetchFailure,
        );
    },
    setFilters: (state: MemberListState, action: PayloadAction<SetFiltersPayload>): void => {
        const newFilters = action.payload.filters;
        set(state, 'cursor', '');
        set(state, 'entities', []);
        set(state, 'count', 0);
        set(state, 'filters', action.payload.merge ? { ...state.filters, ...newFilters } : newFilters);
        set(state, 'state', MemberListStates.reloading);
    },
    reset: () => {
        return initialState;
    },
};
