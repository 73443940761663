import produce from 'immer';

import { FETCH_APPS_ERROR, FETCH_APPS_LOADING, FETCH_APPS_SUCCESS } from '../actions/community_apps_actions';

// Default state.
const defaultState = { appIsLoading: false, errorCode: undefined, errorMessage: '', items: [] };

const appsReducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_APPS_LOADING:
                draft.appIsLoading = true;

                break;

            case FETCH_APPS_SUCCESS:
                draft.items = action.payload.items || [];
                draft.appIsLoading = false;

                break;

            case FETCH_APPS_ERROR:
                draft.errorCode = action.payload.errorCode;
                draft.errorMessage = action.payload.errorMessage;
                draft.appIsLoading = false;

                break;

            default:
                break;
        }

        return draft;
    });

export { appsReducer };
