import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Tag } from '../types';
import { TagzState } from './types';

export const initialState: TagzState = {
    isLoadingSearchTagSuggestions: false,
    searchTagSuggestions: [],
};

const slice = createSlice({
    domain: 'folksonomy',
    initialState,
    reducers: {
        loadSuggestions: (state: TagzState, action: PayloadAction<boolean>): void => {
            const { payload = true } = action || {};
            set(state, 'isLoadingSearchTagSuggestions', payload);
        },
        fetchTagSuggestions: (state: TagzState, action: PayloadAction<Tag[]>): void => {
            set(state, 'searchTagSuggestions', action.payload);
            set(state, 'isLoadingSearchTagSuggestions', false);
        },
        fetchTagSuggestionsError: (state: TagzState): void => {
            set(state, 'searchTagSuggestions', initialState.searchTagSuggestions);
            set(state, 'isLoadingSearchTagSuggestions', false);
        },
    },
});

const { actions, reducer } = slice;

export { actions, reducer };
