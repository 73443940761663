import { getWysiwygToolbar } from '@lumapps/wrex-legacy-froala/constants';

import { DEFAULT_CONTRIBUTION_LANGS } from '../../config';

/**
 * The default length of the excerpt of a content in a content list.
 * In number of character.
 *
 * @type {number}
 * @constant
 * @readonly
 */
const _DEFAULT_CONTENT_LIST_EXCERPT_SIZE = 100;

/////////////////////////////

/**
 * Advanced settings.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const AdvancedSettingsConstant = {
    CATEGORIES: {
        default: {
            title: 'ADMIN.ADVANCED_SETTINGS.DEFAULT_CATEGORY',
        },
        extensions: {
            title: 'ADMIN.NAV.PLATFORM_EXTENSIONS',
        },
        wysiwyg: {
            title: 'ADVANCED.SETTINGS.WYSIWYG',
        },
        beta: {
            title: 'ADVANCED.SETTINGS.BETA_FEATURES',
        },
    },

    OPTIONS: {
        BROWSER_TRANSLATION: {
            choices: ['Yes', 'No'],
            default: 'Yes',
            type: 'select',
        },
        AVAILABLE_LANGS: {
            choices: DEFAULT_CONTRIBUTION_LANGS,
            default: DEFAULT_CONTRIBUTION_LANGS,
            multiple: true,
            type: 'language',
        },

        FALLBACK_LANGS: {
            choices: DEFAULT_CONTRIBUTION_LANGS,
            default: DEFAULT_CONTRIBUTION_LANGS,
            multiple: true,
            type: 'language',
        },

        // eslint-disable-next-line sort-keys
        COMMUNITY_FILTERS: {
            choices: [
                {
                    enable: true,
                    name: 'query',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_COMMUNITY-FILTER_',
            translate: true,
            type: 'select',
        },

        CONTENT_LIST_EXCERPT: {
            default: _DEFAULT_CONTENT_LIST_EXCERPT_SIZE,

            sub_type: 'number',
            type: 'input',
        },

        CONTENT_LIST_LINK_OPEN_NEW_TAB: {
            choices: ['new_tab', 'same_tab'],
            default: 'new_tab',
            type: 'select',
            // A select with translate set to true will be prefixed by 'PLATFORM_SETTINGS_' + the value
            translate: true,
        },

        // eslint-disable-next-line sort-keys
        CONTENT_FIELDS: {
            choices: [
                {
                    enable: true,
                    name: 'author',
                },
                {
                    enable: true,
                    name: 'publication-date',
                },
                {
                    enable: true,
                    name: 'tags',
                },
                {
                    enable: true,
                    name: 'title',
                },
                {
                    enable: true,
                    name: 'excerpt',
                },
                {
                    enable: true,
                    name: 'metadata',
                },
                {
                    enable: true,
                    name: 'social',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_CONTENT-LIST_',
            translate: true,
            type: 'select',
        },

        CONTENT_FILTERS: {
            choices: [
                {
                    enable: true,
                    name: 'query',
                },
                {
                    enable: true,
                    name: 'tags',
                },
                {
                    enable: true,
                    name: 'metadata',
                },
                {
                    enable: true,
                    name: 'author',
                },
                {
                    enable: true,
                    name: 'dates',
                },
                {
                    enable: true,
                    name: 'feature-feeds',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_CONTENT-FILTER_',
            translate: true,
            type: 'select',
        },

        DIRECTORY_FILTERS: {
            choices: [
                {
                    enable: true,
                    name: 'query',
                },
                {
                    enable: true,
                    name: 'directory-tags',
                },
                {
                    enable: true,
                    name: 'metadata',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_CONTENT-FILTER_',
            translate: true,
            type: 'select',
        },

        FILE_FIELDS: {
            choices: [
                {
                    enable: true,
                    name: 'thumbnail',
                },
                {
                    enable: true,
                    name: 'name',
                },
                {
                    enable: true,
                    name: 'tags',
                },
                {
                    enable: true,
                    name: 'description',
                },
            ],
            ordered: true,
            prefix: 'FRONT.SETTINGS.',
            translate: true,
            type: 'select',
        },

        META_FIELDS: {
            choices: [
                {
                    enable: true,
                    name: 'author',
                },
                {
                    enable: true,
                    name: 'publication-date',
                },
                {
                    enable: true,
                    name: 'tags',
                },
                {
                    enable: true,
                    name: 'metadata',
                },
                {
                    enable: true,
                    name: 'social',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_CONTENT-LIST_',
            translate: true,
            type: 'select',
        },

        META_SOCIAL_FIELDS: {
            choices: [
                {
                    enable: true,
                    name: 'like',
                },
                {
                    enable: true,
                    name: 'comment',
                },
                {
                    enable: true,
                    name: 'follow',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_META-SOCIAL_',
            translate: true,
            type: 'select',
        },
        POST_FIELDS: {
            choices: [
                {
                    enable: true,
                    name: 'sharedInCommunities',
                },
                {
                    enable: true,
                    name: 'metadata',
                },
                {
                    enable: true,
                    name: 'content',
                },
                {
                    enable: true,
                    name: 'title',
                },
                {
                    enable: true,
                    name: 'dates',
                },
                {
                    enable: false,
                    name: 'attachments',
                },
                {
                    enable: false,
                    name: 'comments',
                },
            ],
            ordered: true,
            prefix: 'WIDGET_TYPE_POST-LIST_',
            translate: true,
            type: 'select',
        },

        SOCIAL_SETTINGS: {
            choices: [
                {
                    enable: true,
                    name: 'multiposting_allowed',
                },
            ],
            ordered: true,
            prefix: 'SOCIAL_SETTINGS_',
            translate: true,
            type: 'select',
        },

        USER_BLOCK_DEFAULT_FIELD: {
            default: 'primaryEmail',
            // eslint-disable-next-line camelcase
            sub_type: 'text',
            type: 'input',
        },

        USER_BLOCK_USER_DIRECTORY_FIELD: {
            default: 'organizations[primary=true]/title',
            // eslint-disable-next-line camelcase
            sub_type: 'text',
            type: 'input',
        },

        WYSIWYG_PASTEPLAIN: {
            category: 'wysiwyg',
            choices: ['Yes', 'No'],
            default: 'Yes',
            ordered: false,
            type: 'select',
        },

        WYSIWYG_TOOLBAR: {
            category: 'wysiwyg',
            choices: getWysiwygToolbar(),
            dragAndDropType: 'froala-toolbar',
            translationPrefix: 'FRONT.FROALA.',
            type: 'drag-and-drop',
        },

        /**
         * Here is the dedicated section for Beta Features in the product
         */

        BLOCK_XSS_HTML_TAGS: {
            category: 'beta',
            choices: ['html_whitelist_all', 'html_whitelist_platform', 'html_whitelist_site'],
            default: 'html_whitelist_all',
            type: 'select',
            // A select with translate set to true will be prefixed by 'PLATFORM_SETTINGS_' + the value
            translate: true,
        },
    },
};

/////////////////////////////

angular.module('Constants').constant('AdvancedSettingsConstant', AdvancedSettingsConstant);

/////////////////////////////

export { AdvancedSettingsConstant };
