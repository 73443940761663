import { formatISODateString } from '@lumapps/utils/date/formatISODateString';

import { ListEventParams } from '../types';

/**
 * Extract the params that will be used for fetching the events associated to a Calendar.
 * */
export const getFetchParams = (
    calendarId: string,
    maxResults: number,
    startDate: Date,
    endDate?: Date,
): ListEventParams => ({
    startDate: formatISODateString(startDate),
    endDate: endDate ? formatISODateString(endDate) : undefined,
    calendarId,
    maxResults,
});
