import React from 'react';

import { mdiPlay } from '@lumapps/lumx/icons';
import { VideoPickerDialog } from '@lumapps/play-pickers/components/VideoPickerDialog';
import type { Video } from '@lumapps/play/api/types';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { COMMUNITIES } from '../../keys';

export interface UseVideoPickerButtonProps {
    onSubmit(video: Video): void;
}

export const useVideoPickerButton = ({ onSubmit }: UseVideoPickerButtonProps) => {
    const isPlayEnabled = useSelector(isPlayVideoEnabled);

    const [isPickerDialogOpen, setPickerDialogOpen] = React.useState(false);
    const openPickerDialog = () => setPickerDialogOpen(true);
    const closePickerDialog = () => setPickerDialogOpen(false);

    const handleVideoSelection = React.useCallback(
        (videos: Video[]) => {
            onSubmit(videos[0]);
            closePickerDialog();
        },
        [onSubmit],
    );

    const PickerDialog = React.useMemo(
        () => (
            <VideoPickerDialog
                isOpen={isPickerDialogOpen}
                onSelectionCancel={closePickerDialog}
                onSelectionConfirm={handleVideoSelection}
            />
        ),
        [handleVideoSelection, isPickerDialogOpen],
    );

    const pickerButtonProps = React.useMemo(
        () => ({
            icon: mdiPlay,
            label: COMMUNITIES.PICK_FROM_VIDEO_GALLERY,
            onSelected: openPickerDialog,
        }),
        [],
    );

    return { ...(isPlayEnabled && { pickerButtonProps, PickerDialog, isPickerDialogOpen }) };
};
