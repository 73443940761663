import React, { useCallback, useMemo } from 'react';

import filter from 'lodash/filter';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

import { SelectFieldMultiple } from '@lumapps/combobox/components/SelectFieldMultiple';
import { renderGroupedChoice } from '@lumapps/combobox/components/SelectFieldMultiple/renderChoicesFactories';
import { getDirectories } from '@lumapps/directories/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { usePaginatedFrontendSearch } from '@lumapps/utils/hooks/usePaginatedFrontendSearch';

import { FormattedTagFilter, FormattedDirectoryFilter } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

interface TagsFromDirectoriesFilterProps {
    onChange: (tags: FormattedTagFilter[]) => void;
    directories?: FormattedDirectoryFilter[];
    selectedTags?: FormattedTagFilter[];
    shouldBeHiddenIfEmpty?: boolean;
    theme: Theme;
    itemsPerPage?: number;
    scope: string;
    hideSubheader: boolean;
}

export const TagsFromDirectoriesFilter = ({
    onChange,
    directories,
    selectedTags,
    shouldBeHiddenIfEmpty,
    theme = Theme.light,
    itemsPerPage = 20,
    scope,
    hideSubheader,
}: TagsFromDirectoriesFilterProps) => {
    const allDirectories = useSelector(getDirectories);
    const { translateKey, translateObject } = useTranslate();
    const [isOpen, toggleOpen] = useBooleanState(false);

    /** Extracting all tags from selected directories, adding information about there directories */
    const selectedDirectoriesIds = map(directories, 'id');
    const allTagsWithDirectories = useMemo(() => {
        /** Getting all information about selected directories from the store */
        const selectedDirectories = filter(allDirectories, (directory) =>
            includes(selectedDirectoriesIds, directory.id),
        );
        return selectedDirectories.reduce<FormattedTagFilter[]>((result, directory): FormattedTagFilter[] => {
            const tagsFromDirectories = map(get(directory, 'tags', []), (tag) => ({
                id: get(tag, 'uuid'),
                name: translateObject(get(tag, 'name')) || '',
                directory: {
                    id: get(directory, 'id'),
                    name: translateObject(get(directory, 'name')) || '',
                },
            }));
            return [...result, ...tagsFromDirectories];
        }, []);
    }, [allDirectories, selectedDirectoriesIds, translateObject]);

    /** Pagination */
    const { getMoreItems, items, searchQuery, onSearch } = usePaginatedFrontendSearch({
        itemList: allTagsWithDirectories,
        perPage: itemsPerPage,
        getItemName: (tag: FormattedTagFilter) => tag.name,
    });

    /**
     * Group tags by their directories
     * We are formatting the data to use the renderGroupChoice function that can
     * create select options grouped by sections
     * */
    const groups = useMemo(() => {
        const groupedItems = groupBy(items, (item) => get(item, 'directory.name'));
        return map(groupedItems, (tags) => {
            const directoryName = get(tags[0], 'directory.name', '');
            return { group: { directoryName }, children: tags };
        });
    }, [items]);

    /** Get up to date tag name from redux store */
    const getValueName = useCallback(
        (tag) => {
            const tagDictionnary = keyBy(allTagsWithDirectories, 'id');
            return tag.name || tagDictionnary[tag.id]?.name;
        },
        [allTagsWithDirectories],
    );

    /** Get tag id */
    const getValueId = useCallback((tag) => tag.id, []);

    if (shouldBeHiddenIfEmpty && isEmpty(selectedDirectoriesIds)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.CATEGORY)} theme={theme} />}
            <SelectFieldMultiple<FormattedTagFilter>
                value={selectedTags || []}
                isOpen={isOpen}
                searchText={searchQuery}
                theme={theme}
                scope={scope}
                searchFieldProps={{
                    isCompact: true,
                }}
                onInfiniteScroll={getMoreItems}
                onSearch={onSearch}
                onChange={onChange}
                getValueId={getValueId}
                getValueName={getValueName}
                label={translateKey(GLOBAL.TAGS)}
                setOpen={toggleOpen}
                choices={renderGroupedChoice<
                    { group: { directoryName: string }; children: FormattedTagFilter[] },
                    FormattedTagFilter,
                    { directoryName: string }
                >({
                    groups,
                    getGroupName: (group) => group.directoryName,
                    getValueName: (tag) => tag.name,
                    getValueId: (tag) => tag.id,
                })}
            />
        </>
    );
};
