import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { PlayStatusFlag } from '@lumapps/play/components/PlayStatusFlag';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-status` as const;

/**
 * Displays the Status of a video
 */
export const Status = () => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, playlist } = useBlockPlaylistContext();

    if (!canDisplay('STATUS')) {
        return null;
    }

    return <PlayStatusFlag className={block()} status={playlist?.status} />;
};
