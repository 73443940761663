import React from 'react';

import { Avatar, Badge, Size, Icon, ColorPalette } from '@lumapps/lumx/react';
import { DEFAULT_AVATAR } from '@lumapps/user/constants';
import { getAvatarUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { getNotificationMeta } from '../../notification-meta';
import { Notification, NotificationSenderDetails } from '../../types';

export type NotificationListItemBeforeProps = {
    className?: string;
    // The notification
    notification: Notification;
    // Details about the sender
    senderDetails?: NotificationSenderDetails | null;
};

const NotificationListItemBefore: React.FC<NotificationListItemBeforeProps> = ({
    className,
    notification,
    senderDetails,
}) => {
    const { type } = notification;
    const {
        hasThumbnail = false,
        iconProps = {
            icon: 'alert',
            color: ColorPalette.primary,
        },
    } = getNotificationMeta(type);
    const { iconPath: iconDynamicPath } = useDynamicIcon({ icon: iconProps.icon });
    const iconPath = iconProps.customIcon || iconDynamicPath;

    if (hasThumbnail && senderDetails) {
        const { uid, apiProfile, profilePictureUrl } = senderDetails;
        // Get the avatar image with the correct priority precedence
        const image =
            // If profilePictureUrl is set, use it
            profilePictureUrl ||
            // If profilePicture inside apiProfile is set, use it
            apiProfile?.profilePicture ||
            // If apiProfile.thumbnail has photoData, use it
            (apiProfile && apiProfile.thumbnail && apiProfile.thumbnail.photoData
                ? `data:image/jpeg;base64,${apiProfile.thumbnail.photoData}`
                : // Else construct Avatar url from user data
                  getAvatarUrl(uid, notification.customerId));

        return (
            <Avatar
                className={className}
                image={image || DEFAULT_AVATAR}
                size={Size.m}
                alt=""
                badge={
                    <Badge color={iconProps.color}>
                        <Icon icon={iconPath} />
                    </Badge>
                }
            />
        );
    }

    return (
        <Icon
            className={className}
            icon={iconPath}
            color={iconProps.color}
            colorVariant={iconProps.colorVariant}
            hasShape
        />
    );
};

export { NotificationListItemBefore };
