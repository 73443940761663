import React from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { TextField, TextFieldProps } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';
import { DefaultValueWrapper } from '../DefaultValueWrapper';

export type FormTextFieldProps = Omit<TextFieldProps, 'onChange' | 'value'> &
    BaseInput & {
        displayValidityOnBlur?: boolean;
        type?: 'number' | 'text' | 'date';
    };

/**
 * A text field component that can be added into a Form in order to be used with react-hook-form.
 * @family Forms
 * @param FormTextFieldProps
 * @returns FormTextField
 */
export const FormTextField: React.FC<FormTextFieldProps> = ({
    name,
    isRequired,
    controllerProps,
    isMultiLanguage = false,
    preventEmptyFields = false,
    languagesFormat,
    displayValidityOnBlur = false,
    label,
    type = 'text',
    ...props
}) => {
    const { translateKey } = useTranslate();

    const [wasBlurred, setWasBlurred] = React.useState(false);
    const {
        valueToUse,
        field,
        onMultilanguageChange,
        fieldState,
        defaulValueWrapperProps,
        isFieldDisabled,
        inputProps,
        getDataAttributes,
    } = useFormField<string | number>({
        name,
        controllerProps,
        isMultiLanguage,
        preventEmptyFields,
        isRequired,
        languagesFormat,
        label,
    });

    const hasError = Boolean(fieldState.error);

    const hasValue = valueToUse && valueToUse !== '';

    return (
        <DefaultValueWrapper {...defaulValueWrapperProps}>
            <TextField
                inputRef={field.ref}
                value={valueToUse?.toString() || ''}
                name={field.name}
                isRequired={isRequired}
                label={label}
                aria-invalid={hasError}
                type={type}
                onBlur={() => {
                    if (isMultiLanguage) {
                        onMultilanguageChange(valueToUse);
                    }

                    field.onBlur();

                    setWasBlurred(true);
                }}
                onFocus={() => setWasBlurred(false)}
                hasError={hasError}
                error={fieldState.error?.message || translateKey(GLOBAL.FIELD_IS_REQUIRED)}
                /**
                 * We only want to display that the field is valid when the user has made some changes to the field
                 * and has already left it. We can determine this by checking the `isDirty` and `isTouched` values
                 * at the same time.
                 */
                isValid={
                    wasBlurred && displayValidityOnBlur && hasValue && fieldState.isDirty && fieldState.isTouched
                        ? !hasError
                        : undefined
                }
                {...getDataAttributes({ action: name, element: 'text-field' })}
                {...props}
                onChange={(val) => {
                    const value = type === 'number' ? parseInt(val, 10) : val;

                    if (isMultiLanguage) {
                        onMultilanguageChange(value);
                    } else {
                        field.onChange(value);
                    }
                }}
                isDisabled={props.isDisabled || isFieldDisabled}
                {...inputProps}
                className={classnames(props.className, padding('vertical', null))}
            />
        </DefaultValueWrapper>
    );
};
