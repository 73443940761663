import * as React from 'react';
import * as propTypes from 'prop-types';
import includes from 'lodash/includes';
import get from 'lodash/get';

import * as types from '../../types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { Style as StyleService } from '../../../services/styles';
import { stringsToChoices } from '../../ui/inputs/utils';
import { BORDER_COLOR_FIELDS, BORDER_RADIUS_FIELDS, BORDER_WIDTH_FIELDS } from '../constants';
import { ColorSelect, PositiveNumberInput, Tab, Tabs } from '../../ui';
import { Measures } from './Measures';

/**
 * Renders a form that edits border attributes.
 */
export class BorderSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.borderStyle.isRequired,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Called when the current state style changes with `(state)`. */
        onChangeState: propTypes.func,
        /** Current state style. */
        state: propTypes.string,
        /** Style states. */
        states: propTypes.array,
        /** Current border style. */
        value: styleTypes.borderStyle,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { value, name: key, onChange, defaults, state, states, onChangeState } = this.props;
        let colors = get(StyleService.getCurrent('global'), 'properties.colors');

        if (!includes(colors, 'transparent')) {
            colors.push('transparent');
        }
        return (
            <Tabs state={state} states={states} onChangeState={onChangeState}>
                <Tab id="size" label={t('STYLE.SIZE')}>
                    <Measures
                        key="size"
                        value={value}
                        name={key}
                        onChange={onChange}
                        field="borderWidth"
                        fields={BORDER_WIDTH_FIELDS}
                        defaults={defaults}
                        Input={PositiveNumberInput}
                        suffix="px"
                    />
                </Tab>
                <Tab id="color" label={t('STYLE.COLOR')}>
                    <Measures
                        key="color"
                        value={value}
                        name={key}
                        onChange={onChange}
                        field="borderColor"
                        fields={BORDER_COLOR_FIELDS}
                        defaults={defaults}
                        Input={ColorSelect}
                        choices={stringsToChoices(colors)}
                        hasClearChoice
                    />
                </Tab>
                <Tab id="radius" label={t('STYLE.RADIUS')}>
                    <Measures
                        key="radius"
                        value={value}
                        name={key}
                        onChange={onChange}
                        field="borderRadius"
                        fields={BORDER_RADIUS_FIELDS}
                        defaults={defaults}
                        Input={PositiveNumberInput}
                        suffix="px"
                    />
                </Tab>
            </Tabs>
        );
    }
}
