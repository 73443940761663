/* istanbul ignore file */
export enum SPACE_NAVIGATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=538643891
     */
    EXPLORE = 'SPACES.EXPLORE_MENU_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=538658637
     */
    EXPLORE_SECTION_ASSOCIATED = 'SPACES.EXPLORE_MENU_SECTION_ASSOCIATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=538653190
     */
    EXPLORE_SECTION_RESOURCES = 'SPACES.EXPLORE_MENU_SECTION_RESOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=352461775
     */
    EXPLORE_SECTION_SOCIAL = 'GLOBAL.SOCIAL',
}
