import { errorRoute } from "@lumapps/router";
import { angularApi } from "@lumapps/router/routers";

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetPostDetailsController(
        $state,
        $stateParams,
        Community,
        Content,
        Notification,
        Post,
        Translation,
        Utils,
        Config,
    ) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The current community.
         *
         * @type {Object}
         */
        vm.community = undefined;

        /**
         * The post displayed in the widget.
         *
         * @type {Object}
         */
        vm.post = undefined;

        /**
         * Loading state of the post.
         * @type {boolean}
         */
        vm.isLoading = false;

        /**
         * Fields of the post that will be shown in the post detail.
         * @type {boolean}
         */
        vm.fields = Utils.getConfigProperty(Config.INSTANCE_PROPERTIES.POST_FIELDS);

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the current post.
         *
         * @param {string} postId The post identifier.
         */
        function _getPost(postId) {
            if (angular.isUndefinedOrEmpty(postId)) {
                return;
            }

            vm.isLoading = true;

            Post.get(
                postId,
                function onPostGetSuccess(response) {
                    vm.post = response;
                    vm.isLoading = false;
                    Notification.setAllAsRead(postId);
                },
                function onPostGetError(error) {
                    vm.isLoading = false;
                    if(error.status === 404){
                        angularApi.redirect({
                            ...errorRoute,
                            legacyForceReload: true,
                        });
                    }
                },
            );
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Community return call.
         */
        function getBack() {
            $state.go('app.front.community', {
                identifier: undefined,
                slug: Translation.translate(vm.community.slug),
                view: Community.availableViews.posts.id,
            });
        }

        /**
         * Check if the widget is empty.
         * This is used in the designer.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            return angular.isUndefinedOrEmpty(vm.post) && !vm.isLoading;
        }

        /**
         * Check if the widget is hidden.
         * This is used in read mode.
         *
         * @return {boolean} If the widget is hidden or not.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = vm.isWidgetEmpty() && !vm.parentCtrl.designerMode();

            return vm.parentCtrl.isHidden;
        }

        /////////////////////////////

        vm.getBack = getBack;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.community = Content.getCurrent();

            _getPost($stateParams.identifier || $stateParams.postId);
        }

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            init();
        };
    }

    /////////////////////////////

    /**
     * The community navigation widget.
     * This widget provide the user navigation tools and shortcut.
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetPostDetailsDirective() {
        'ngInject';

        function WidgetPostDetailsLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetPostDetailsController,
            controllerAs: 'vm',
            link: WidgetPostDetailsLink,
            replace: true,
            require: ['widgetPostDetails', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-post-details/views/widget-post-details.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetPostDetails', WidgetPostDetailsDirective);
})();
