import { useEffect, useState } from 'react';

import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { Video } from '../../api/types';
import { BASE_THUMBNAIL_URL, THUMBNAIL_ENDPOINT } from '../../constants';

export interface UseGetVideoThumbnailProps {
    /** Get thumbnail url via Monolite (true)? Or via Play API (false | undefined)? */
    fromMonolite?: boolean;
    /** Play video */
    video?: Partial<Video> & Pick<Video, 'id' | 'thumbnail'>;
}

/**
 * This hooks will return a video thumbnail either via Monolite or
 * via Play
 */
export const useGetVideoThumbnail = ({ fromMonolite, video }: UseGetVideoThumbnailProps) => {
    const customerId = useSelector(customerIdSelector);
    const [thumbnailUrl, setThumbnailUrl] = useState('');

    useEffect(() => {
        if (!video) {
            return;
        }
        setThumbnailUrl(
            fromMonolite
                ? `${BASE_THUMBNAIL_URL}/${customerId}/videos/${video.id}/${THUMBNAIL_ENDPOINT}`
                : video.thumbnail?.url || '',
        );
    }, [customerId, fromMonolite, video]);

    return thumbnailUrl;
};
