/* eslint-disable no-param-reassign */
import { combineReducers } from '@lumapps/redux';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { createListSlice, initialState as initialListState } from '@lumapps/redux/genericListDucks';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { SiteListState, SiteListWidgetState } from '../types';

export const domain = 'widget-site-list';

export const { reducer: listReducer, actions: listActions } = createListSlice({
    /** Action type prefix. */
    domain,
});

const initialWidgetState: SiteListWidgetState = {
    favoritesStatus: {},
    unFavoritesStatus: {},
};

export const { reducer: widgetReducer, actions: widgetActions } = createSlice({
    domain,
    initialState: initialWidgetState,
    reducers: {
        setFavoriteStatus: (
            state: SiteListWidgetState,
            action: PayloadAction<{ id: string; status: BaseLoadingStatus }>,
        ) => {
            const { id, status } = action.payload;
            state.favoritesStatus[id] = status;
        },

        deleteFavoriteStatusHandled: (state: SiteListWidgetState, action: PayloadAction<string>) => {
            const id = action.payload;
            delete state.favoritesStatus[id];
        },

        setUnFavoriteStatus: (
            state: SiteListWidgetState,
            action: PayloadAction<{ id: string; status: BaseLoadingStatus }>,
        ) => {
            const { id, status } = action.payload;
            state.unFavoritesStatus[id] = status;
        },

        deleteUnfavoriteStatusHandled: (state: SiteListWidgetState, action: PayloadAction<string>) => {
            const id = action.payload;
            delete state.unFavoritesStatus[id];
        },
    },
});

const initialState: SiteListState = { list: initialListState, widget: initialWidgetState };

const reducer = combineReducers({
    list: listReducer,
    widget: widgetReducer,
});

export { initialState, reducer };
