/* istanbul ignore file */
/** tested with integration tests */
import React from 'react';

const useNoTopBar = ({ isEnabled = true }) => {
    React.useLayoutEffect(() => {
        if (isEnabled) {
            const { body } = document;

            if (!body.classList.contains('no-top-bar')) {
                body.classList.add('no-top-bar');
            }
        }
    });
};

export { useNoTopBar };
