/* istanbul ignore file */
import { Program } from '@lumapps/sa-programs/types';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';
import { Topic } from '@lumapps/sa-topics/types';
import { TranslatableObject } from '@lumapps/translations';

export interface ContentActionsType {
    canMakeShareableOn: Array<Program['id']>;
    canShareTo: Record<SocialNetworks, Array<Program['id']>>;
}
export enum ShareableContentStatuses {
    live = 'live',
    disabled = 'disabled',
    deleted = 'deleted',
}

export enum ShareableContentNotificationType {
    link = 'link',
    image = 'image',
}

export enum ShareableContentType {
    link = 'LINK',
    images = 'IMAGES',
}

export enum mediaTypes {
    jpeg = 'image/jpeg',
    png = 'image/png',
    gif = 'image/gif',
}

export type CanBeSharedOnType = Record<SocialNetworks, Array<Program['id']>>;

export interface ShareableContentSocialNetwork {
    /**
     * The social platform's id
     */
    socialNetworkId: SocialNetworks;
    /**
     * Whether the social network is enabled for this content
     */
    sharingEnabled: boolean;
    /**
     * Whether the social network is enabled for this content
     */
    suggestedComment: TranslatableObject;
}

export interface ShareableContentLink {
    /** The canonical URL. */
    url: TranslatableObject | null;
    /** The URL to use for sharing. */
    shareableUrl: TranslatableObject | null;
    /** In case of a link to a LumApps content. */
    contentInternalId: string | null;
    /** The target content's title, if available. */
    title: TranslatableObject | null;
    /** The target content's description, if available. */
    description: TranslatableObject | null;
    /** The target content's image URL, if available. */
    imageUrl: TranslatableObject | null;
}

export interface ShareableContentMedia {
    url: string;
    type: mediaTypes;
    imageUrl: string;
}

export interface ShareableContent {
    id: string;
    createdAt: string;
    updatedAt: string;
    program: Program | null;
    createdBy: object;
    topics: Array<Topic>;
    /**
     * This field is the resolution of the per-social network information which can be
     * edited via the socialNetworks field.
     * This field is guaranteed to contain an item for each available social network.
     */
    resolvedSocialNetworks: Array<ShareableContentSocialNetwork>;
    /**
     * The data related to each social networks for this content.
     */
    socialNetworks: Array<ShareableContentSocialNetwork>;
    /**
     * The suggested comment common to each social networks
     */
    suggestedComment: TranslatableObject | null;
    /** An editable title for non-link shareable content. */
    customTitle: TranslatableObject | null;
    /**
     * A title to render this shareable content within a view.
     * This field will hold either the link.title or customTitle field value accordingly to type field.
     *
     */
    title:
        | (ShareableContentType.link extends ShareableContent['type']
              ? ShareableContentLink['title']
              : ShareableContent['customTitle'])
        | null;
    /**
     * 	An image URL to use to render this shareable content within a view.
     * This field will hold either the link.imageUrl or mediaList[0].imageUrl field value accordingly to type field.
     */
    imageUrl:
        | (ShareableContentType.link extends ShareableContent['type']
              ? ShareableContentLink['imageUrl']
              : ShareableContentMedia['imageUrl'])
        | null;
    link: ShareableContentType.link extends ShareableContent['type'] ? ShareableContentLink : null;
    mediaList: ShareableContentType.images extends ShareableContent['type'] ? Array<ShareableContentMedia> : null;
    type: ShareableContentType;
    canShare: boolean;
    canEdit: boolean;
    canArchive: boolean;
}
