/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';

import { BlockReactionsSkeleton } from './BlockReactionsSkeleton/BlockReactionsSkeleton';

const LazyBlockReactions = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-base-block-reactions' */
            './BlockReactions'
        ),
);

export const BlockReactions: React.FC<React.ComponentProps<typeof LazyBlockReactions>> = (props) => (
    <Suspense loadingFallback={<BlockReactionsSkeleton {...props} />}>
        <LazyBlockReactions {...props} />
    </Suspense>
);
