import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import * as propTypes from 'prop-types';

import isFunction from 'lodash/isFunction';

import { Icon } from '../icons';

/**
 * Renders an accordion box, that is, a panel with a title whose content
 * expands when clicking on it.
 */
export class AccordionBox extends PureComponent {
    static propTypes = {
        /** Whether the box should have a border or not. */
        border: propTypes.bool,
        /**
         * The colour scheme of the component. Note that depending on the variant type, it means different things.
         * With the default variant, primary means white background and secondary means light grey background.
         * With the simple variant, primary and secondary define the colour of the text label.
         */
        children: propTypes.node,
        /** Content of the box. */
        color: propTypes.oneOf(['primary', 'secondary']),
        /** Custom header node. */
        customHeader: propTypes.node,
        /** The props of the handle when using a drag and drop component. */
        customToggleAction: propTypes.func,
        /** Custom function called on toggle. */
        dragHandleProps: propTypes.shape(),
        /** Label of the box. */
        label: propTypes.string,
        /** Whether the box should be initially open or not. */
        value: propTypes.bool,
        /**
         * The style variant of the accordion box.
         * While simple is just a primary coloured text with inline arrow, default is close to what some would call an
         * expansion panel.
         */
        variant: propTypes.oneOf(['default', 'simple']),
    };

    constructor(props) {
        super(props);
        this.state = { value: props.value || false };
        this.toggle = () => {
            const value = this.state.value;
            this.setState({ value: !this.state.value });

            if (isFunction(props.customToggleAction) && !value) {
                props.customToggleAction();
            }
        };
    }

    render() {
        const {
            border = true,
            children,
            color = 'primary',
            customHeader,
            dragHandleProps,
            label,
            variant = 'default',
        } = this.props;
        const { value } = this.state;

        return (
            <div
                className={classNames(
                    'custom-accordion-box',
                    variant === 'simple' && 'custom-accordion-box--simple',
                    `custom-accordion-box--${color}`,
                    `custom-accordion-box--is-${value ? 'open' : 'close'}`,
                    !border && 'custom-accordion-box--no-border',
                )}
            >
                <div className="custom-accordion-box__header" onClick={this.toggle}>
                    {customHeader ? (
                        customHeader
                    ) : (
                        <Fragment>
                            {dragHandleProps && (
                                <div className="custom-accordion-box__handle" {...dragHandleProps}>
                                    <Icon value="drag-vertical" />
                                </div>
                            )}
                            <span className="custom-accordion-box__label">{label}</span>
                            <Icon className="custom-accordion-box__chevron" value="chevron-down" />
                        </Fragment>
                    )}
                </div>
                {value && (
                    <div className="custom-accordion-box__content">
                        <div className="ph+">{children}</div>
                    </div>
                )}
            </div>
        );
    }
}
