import { arrayFromObjectOfEnabledValues } from '@lumapps/play/utils';

import { WidgetPlayPropertiesType, AllVideoMetadata, VisibleMetadataObject } from '../../types';

export interface UseMetadataOptionsParams {
    onChange: ({ order }: WidgetPlayPropertiesType) => void;
    visibleMetadata: VisibleMetadataObject;
}

export const useMetadataOptions = ({ onChange, visibleMetadata }: UseMetadataOptionsParams) => {
    // Transforms the object of metadata into a list of enabled fields
    const visibleMetadataToArray = arrayFromObjectOfEnabledValues(visibleMetadata);

    // Toggle 1 metadata at a time
    const toggleMetadataVisibility = (metadataItem: keyof typeof AllVideoMetadata) => {
        const filteredMetadataList = visibleMetadataToArray.filter((item) => item !== metadataItem);
        const nextOrder = visibleMetadataToArray.includes(metadataItem)
            ? filteredMetadataList
            : [...visibleMetadataToArray, metadataItem];

        onChange({
            order: nextOrder,
        });
    };

    return {
        // toggle one metadata at a time
        toggleMetadataVisibility,
        // object of visible metadata
        visibleMetadata,
    };
};
