import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { UserBlock } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';

import { getFullName, getPictureUrl } from '../../ducks/selectors';
import { UserMenuLink } from '../../types';
import { UserMenuItem } from './UserMenuItem';

export type UserMenuProps = {
    /** Custom links like `logout` */
    links?: UserMenuLink[];
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
    /** true if we do not wish to display the user block */
    withoutUserBlock?: boolean;
};

const CLASSNAME = 'user-menu';

export const UserMenu: React.FC<UserMenuProps> = ({ links = [], scope, withoutUserBlock }) => {
    const avatarImageSrc = useSelector(getPictureUrl);
    const fullName = useSelector(getFullName);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope as string);
    const { route } = useSocialProfileRoute({});
    const { element } = useClassnames(CLASSNAME);

    return (
        <>
            {!withoutUserBlock && (
                <>
                    <Menu.Item
                        className={element('profile')}
                        as={RouterLink}
                        to={route}
                        {...get({ element: 'user-profile' })}
                    >
                        <UserBlock
                            className={element('profile-block')}
                            name={fullName}
                            fields={[translateKey(GLOBAL.VIEW_USER_PROFILE)]}
                            avatarProps={avatarImageSrc ? { image: avatarImageSrc, alt: '' } : undefined}
                        />
                    </Menu.Item>
                    {links.length > 0 && <Menu.Divider />}
                </>
            )}

            {links.map(({ key, url, isLoading: isLoadingItem, target, ...other }) =>
                isLoadingItem ? (
                    <Menu.ItemSkeleton key={key} hasIcon loadingMessage={translateKey(GLOBAL.LOADING)} />
                ) : (
                    <UserMenuItem key={key} {...other} linkProps={{ href: url, target }} id={key} scope={scope} />
                ),
            )}
        </>
    );
};
