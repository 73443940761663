import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import curry from 'lodash/fp/curry';
import filter from 'lodash/fp/filter';
import first from 'lodash/fp/first';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import over from 'lodash/fp/over';
import reverse from 'lodash/fp/reverse';
import last from 'lodash/last';
import range from 'lodash/range';

import { CalendarConfig, CalendarViews, CalendarWeekConfig, Day, WeekDay } from '../../types';
import { nextDaysUntilWeekday, prevDaysUntilWeekday } from '../date/transformDates';
import { getDayFromDate } from './getDayFromDate';

/**
 * Get Day object for the specified day, reconstructed based on the year|month and day numeric value.
 * @return Day[]
 * */
const getDate = curry((locale: CalendarConfig['locale'], year: number, month: number, i: number) =>
    getDayFromDate(locale, new Date(year, month, i + 1)),
);

/**
 * If the config specified not to show the weekends, we filter out Saturday's and Sunday's
 * */
const filterWeekends = curry(
    (showWeekend: boolean, weekend: CalendarWeekConfig['weekend'], day: Day) =>
        showWeekend || !weekend.has(day.date.getDay()),
);

/**
 * When in Month view, get all the days to display.
 * Based on the week configuration and the current day to display.
 * */
const getMonthDays = (
    month: number,
    year: number,
    weekConfig: CalendarWeekConfig,
    locale: CalendarConfig['locale'],
) => {
    /**
     * Get all numeric values of the month days
     * @example January => 1 -> 31
     * @return number[]
     * */
    const numOfDays = new Date(year, month + 1, 0).getDate();

    return flow(
        /**
         * Get all days in the current month.
         * @return Day[]
         * */
        map(getDate(locale, year, month)),
        /**
         * Remove weekends if necessary.
         * */
        filter(filterWeekends(weekConfig.showWeekend, weekConfig.weekend)),
        /**
         * Make sure that all the weeks are complete by filling days from previous and next months.
         * @return Array<Day[]>
         * */
        over([
            /**
             * Pre-fill with days from the previous month to fill the first week empty spots if they exist
             * @example If a month start on wednesday, and the week start on monday, we'll add the last two days of the previous month
             * @return Day[]
             * */
            flow(
                first,
                get('date'),
                /**
                 * Move backward on the month first date until reaching the first day of the week based on the firstDay
                 * Each date encounter should be added to the list, and returned. This is the date from the previous month that we want to display to fill the calendar.
                 * */
                prevDaysUntilWeekday(weekConfig.firstDay, locale),
                filter(filterWeekends(weekConfig.showWeekend, weekConfig.weekend)),
                /**
                 * Since we are looping backward, we need to reverse the result.
                 * */
                reverse,
            ),
            /**
             * Keep the month days in the middle.
             * */
            (days: Day[]) => days,
            /**
             * Pre-fill with days from the next month to fill the first week empty spots if they exist
             * @example If a month end on wednesday, and the week start on monday, and we do not show the weekends
             * then we'll add the first two days of the next month
             * @return Day[]
             * */
            flow(
                last,
                get('date'),
                /**
                 * Move forward on the month last date until reaching the last day of the week based on the firstDay
                 * Each date encounter should be added to the list, and returned. This is the date from the next month that we want to display to fill the calendar.
                 * */
                nextDaysUntilWeekday(
                    weekConfig.firstDay === WeekDay.sunday ? WeekDay.saturday : weekConfig.firstDay - 1,
                    locale,
                ),
                filter(filterWeekends(weekConfig.showWeekend, weekConfig.weekend)),
            ),
        ]),
        /**
         * Flatten the previous array
         * @param {days} Array<Day[]>
         * @return Day[]
         * */
        flatten,
    )(range(numOfDays));
};

/**
 * When in week view, get all the days of the current week.
 * Based on the week configuration and the current day to display.
 * */
const getWeekDays = (day: Date, weekConfig: CalendarWeekConfig, locale: CalendarConfig['locale']) => {
    const week: Date[] = [];
    /**
     * Duplicate date, to avoid mutating the current object.
     * */
    const current = new Date(day);

    /**
     * Get the week starting day
     * */
    current.setDate(current.getDate() - current.getDay() + weekConfig.firstDay);

    /**
     * Move forward to get all the day's of the current week, until reaching the next "firstDay" day.
     * Add all available days encounter to the list.
     * */
    do {
        /**
         * If we want to show all days
         * or the current day is not part of the weekend in the current local
         * */
        if (weekConfig.showWeekend || !weekConfig.weekend.has(current.getDay())) {
            week.push(new Date(current));
        }

        // Increment the current date, to get the next day.
        current.setDate(current.getDate() + 1);
    } while (current.getDay() !== weekConfig.firstDay);

    return map(getDayFromDate(locale), week);
};

/**
 * Return all days to display in the Calendar
 * Based on the current view and date
 * */
export const getVisibleDays = (config: CalendarConfig, currentDate: Date, view: CalendarViews): Day[] => {
    switch (view) {
        case CalendarViews.month:
            return getMonthDays(currentDate.getMonth(), currentDate.getFullYear(), config.week, config.locale);
        case CalendarViews.week:
            return getWeekDays(currentDate, config.week, config.locale);
        default:
            return [];
    }
};
