import { list } from '../../api/documentProviderApi';

/**
 * Triggers an API call to fetch the current instance providers.
 *
 * @param  {string}  instanceId The instance for which we want to fetch the provider list.
 * @return {Promise} The call promise.
 */
const fetchProvidersTask = ({ instanceId }) => {
    return list({ instanceId });
};

export { fetchProvidersTask };
