const ENC = {
    '+': '-',
    '/': '_',
};

const DEC = {
    '-': '+',
    _: '/',
};

export const encodeBase64UrlSafe = (toEncode: string) => {
    const base64 = btoa(toEncode);
    return base64.replace(/[+/]/g, (m: string) => ENC[m as '+' | '/']);
};

export const decodeBase64UrlSafe = (toDecode: string) => {
    return atob(toDecode.replace(/[-_]/g, (m) => DEC[m as '-' | '_']));
};
