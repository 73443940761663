/* eslint-disable lumapps/no-classname-strings */
/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import { ContentHeaderDisplayType } from '@lumapps/contents/types';
import { Media } from '@lumapps/medias/types';
import { useTranslate, TranslateObject } from '@lumapps/translations';

import { ContentHeaderOverlay } from './ContentHeaderOverlay';
import { ContentHeaderSlideshow } from './ContentHeaderSlideshow';

import './index.scss';

export interface ContentHeaderProps {
    slideshowHeight?: number;
    wrapperHeight?: number;
    displayType?: ContentHeaderDisplayType;
    mediaList?: Media[];
    mediaClass?: Record<string, string>;
    autoplayInterval?: number;
    hasAutoplay?: boolean;
    hasRandom?: boolean;
    defaultTitle?: TranslateObject;
    defaultDescription?: TranslateObject;
    currentLanguage: string;
}

export const CLASSNAME = 'header-content';

export const ContentHeader: React.FC<ContentHeaderProps> = ({
    slideshowHeight,
    displayType,
    mediaList = [],
    mediaClass = {},
    autoplayInterval,
    hasAutoplay,
    hasRandom,
    defaultTitle,
    defaultDescription,
    wrapperHeight,
    currentLanguage,
}) => {
    const { translateObject } = useTranslate();

    if (slideshowHeight === 0) {
        return null;
    }

    return (
        <div className={CLASSNAME}>
            {mediaList.length > 0 ? (
                <ContentHeaderSlideshow
                    className={`${CLASSNAME}__slideshow`}
                    mediaList={mediaList}
                    autoplayInterval={autoplayInterval}
                    hasAutoplay={hasAutoplay}
                    hasRandom={hasRandom}
                    displayType={displayType}
                    wrapperHeight={wrapperHeight}
                    slideshowHeight={slideshowHeight}
                    currentLanguage={currentLanguage}
                    mediaClass={mediaClass}
                />
            ) : (
                <ContentHeaderOverlay
                    className={`header-default ${CLASSNAME}__default`}
                    title={defaultTitle && (translateObject(defaultTitle) as string)}
                    description={defaultDescription && (translateObject(defaultDescription) as string)}
                    displayType={displayType}
                    wrapperHeight={wrapperHeight}
                    style={{ height: slideshowHeight }}
                />
            )}
        </div>
    );
};
