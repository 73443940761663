/* istanbul ignore file */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { SharingStep } from '../../components/types';

export interface ShareToSlackState {
    currentDialogPostId?: string;
    userSpacesStatus: null | 'loading' | 'loaded';
    channelsStatus: null | 'loading' | 'loaded';
    userSpaceAuthenticationStatus: null | 'loading' | 'authenticated' | 'unauthenticated';
    userSpaces: Array<any>;
    filterUserSpaceValue: string;
    showUserSpaceSuggestions: boolean;
    selectedUserSpace?: any;
    channels: Array<any>;
    filterChannelValue: string;
    showChannelSuggestions: boolean;
    navigationChannelSuggestionValue: string;
    selectedChannelValues: Array<any>;
    message: string;
    postingToChannel: boolean;
    sharingStep: SharingStep;
}

export const initialState: ShareToSlackState = {
    currentDialogPostId: undefined,
    userSpacesStatus: null,
    userSpaces: [],
    filterUserSpaceValue: '',
    showUserSpaceSuggestions: false,
    selectedUserSpace: undefined,
    channelsStatus: null,
    userSpaceAuthenticationStatus: null,
    channels: [],
    filterChannelValue: '',
    showChannelSuggestions: false,
    navigationChannelSuggestionValue: '',
    selectedChannelValues: [],
    message: '',
    postingToChannel: false,
    sharingStep: SharingStep.selectUserSpace,
};

export const { actions, reducer } = createSlice({
    domain: 'shareToSlack',
    initialState,
    reducers: {
        toggleDialog: (state: ShareToSlackState, action: PayloadAction<string>) => {
            set(state, 'currentDialogPostId', action.payload);
        },
        setSharingStep: (state: ShareToSlackState, action: PayloadAction<SharingStep>) => {
            set(state, 'sharingStep', action.payload);
        },
        fetchSlackWorkspacesLoading: (state: ShareToSlackState) => {
            set(state, 'userSpacesStatus', 'loading');
        },
        fetchSlackWorkspacesSuccess: (state: ShareToSlackState, action: PayloadAction<any>) => {
            set(state, 'userSpaces', action.payload);
            set(state, 'userSpacesStatus', 'loaded');
        },
        fetchSlackWorkspacesError: (state: ShareToSlackState) => {
            set(state, 'userSpaces', initialState.userSpaces);
            set(state, 'userSpacesStatus', null);
        },
        fetchUserSpaceAuthorizationLoading: (state: ShareToSlackState) => {
            set(state, 'userSpaceAuthenticationStatus', 'loading');
        },
        fetchUserSpaceAuthorizationSuccess: (state: ShareToSlackState) => {
            set(state, 'userSpaceAuthenticationStatus', 'authenticated');
        },
        fetchUserSpaceAuthorizationError: (state: ShareToSlackState) => {
            set(state, 'userSpaceAuthenticationStatus', 'unauthenticated');
        },
        fetchSlackChannelsLoading: (state: ShareToSlackState) => {
            set(state, 'channels', initialState.channels);
            set(state, 'filterChannelValue', initialState.filterChannelValue);
            set(state, 'showChannelSuggestions', initialState.showChannelSuggestions);
            set(state, 'navigationChannelSuggestionValue', initialState.navigationChannelSuggestionValue);
            set(state, 'selectedChannelValues', initialState.selectedChannelValues);
            set(state, 'channelsStatus', 'loading');
        },
        fetchSlackChannelsSuccess: (state: ShareToSlackState, action: PayloadAction<any>) => {
            const { items } = action.payload;
            set(state, 'channels', items);
            set(state, 'channelsStatus', 'loaded');
        },
        fetchSlackChannelsError: (state: ShareToSlackState) => {
            set(state, 'channels', initialState.channels);
            set(state, 'channelsStatus', null);
        },
        setFilterWorkspaceValue: (state: ShareToSlackState, action: PayloadAction<string>) => {
            set(state, 'filterUserSpaceValue', action.payload);
            set(state, 'selectedUserSpace', initialState.selectedUserSpace);
            set(state, 'selectedChannelValues', initialState.selectedChannelValues);
            set(state, 'channels', initialState.channels);
            set(state, 'channelsStatus', null);
        },
        setShowWorkspaceSuggestions: (state: ShareToSlackState, action: PayloadAction<boolean>) => {
            set(state, 'showUserSpaceSuggestions', action.payload);
        },
        setSelectedWorkspace: (state: ShareToSlackState, action: PayloadAction<any>) => {
            set(state, 'selectedUserSpace', action.payload);
        },
        setFilterSlackChannelValue: (state: ShareToSlackState, action: PayloadAction<string>) => {
            set(state, 'filterChannelValue', action.payload);
        },
        setShowSlackChannelSuggestions: (state: ShareToSlackState, action: PayloadAction<boolean>) => {
            set(state, 'showChannelSuggestions', action.payload);
        },
        setNavigationSlackChannelSuggestionValue: (state: ShareToSlackState, action: PayloadAction<string>) => {
            set(state, 'navigationChannelSuggestionValue', action.payload);
        },
        setSelectedSlackChannelValues: (state: ShareToSlackState, action: PayloadAction<any>) => {
            set(state, 'selectedChannelValues', action.payload);
        },
        setSlackMessage: (state: ShareToSlackState, action: PayloadAction<string>) => {
            set(state, 'message', action.payload);
        },
        postingToSlackChannel: (state: ShareToSlackState) => {
            set(state, 'postingToChannel', true);
        },
        postingToSlackChannelError: (state: ShareToSlackState) => {
            set(state, 'postingToChannel', false);
        },
        resetForm: (state: ShareToSlackState) => {
            set(state, 'currentDialogPostId', initialState.currentDialogPostId);
            set(state, 'userSpacesStatus', initialState.userSpacesStatus);
            set(state, 'userSpaces', initialState.userSpaces);
            set(state, 'filterUserSpaceValue', initialState.filterUserSpaceValue);
            set(state, 'showUserSpaceSuggestions', initialState.showUserSpaceSuggestions);
            set(state, 'selectedUserSpace', initialState.selectedUserSpace);
            set(state, 'channelsStatus', initialState.channelsStatus);
            set(state, 'channels', initialState.channels);
            set(state, 'filterChannelValue', initialState.filterChannelValue);
            set(state, 'showChannelSuggestions', initialState.showChannelSuggestions);
            set(state, 'navigationChannelSuggestionValue', initialState.navigationChannelSuggestionValue);
            set(state, 'userSpaceAuthenticationStatus', initialState.userSpaceAuthenticationStatus);
            set(state, 'selectedChannelValues', initialState.selectedChannelValues);
            set(state, 'message', initialState.message);
            set(state, 'postingToChannel', initialState.postingToChannel);
            set(state, 'sharingStep', initialState.sharingStep);
        },
    },
});
