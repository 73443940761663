import * as notify from 'components/services/notification';

import { exportAnalytics as exportAnalyticsApiCall } from 'components/components/social-advocacy/api';
import { translate as t } from 'components/translations';

import { setLoadingState, clearLoadingState } from 'components/components/ducks/loadingStates';

const DOMAIN = 'SA/EXPORT';

const TOGGLE_EXPORT_MODAL = `${DOMAIN}/TOGGLE_EXPORT_MODAL`;
const UPDATE_FORM = `${DOMAIN}/UPDATE_FORM`;
const SET_FORM_FIELD_ERROR = `${DOMAIN}/SET_FORM_FIELD_ERROR`;

const DELAY = 250;

/**
 * Toggle export modal.
 * @param  {boolean}  isOpen Whether the modal is open.
 * @return {Function} Dispatch actions.
 */
const toggleExportModal = (isOpen) => {
    return {
        isOpen,
        type: TOGGLE_EXPORT_MODAL,
    };
};

/**
 * Set form field value.
 *
 * @param  {string}   fieldId Field id.
 * @param  {Object}   field   Field object.
 * @return {Function} Dispatch actions.
 */
const setFormField = (fieldId, field) => (dispatch) => {
    const validatedForm = {
        fields: {
            [fieldId]: {
                ...field,
                wasTouched: true,
            },
        },
        wasTouched: true,
    };

    return dispatch({
        payload: { form: validatedForm },
        type: UPDATE_FORM,
    });
};

/**
 * Set form field error.
 * @param  {string}   fieldId      Field id.
 * @param  {string}   errorMessage Error message.
 * @return {Function} Dispatch actions.
 */
const setFormFieldError = (fieldId, errorMessage) => (dispatch) =>
    dispatch({
        payload: { errorMessage, fieldId },
        type: SET_FORM_FIELD_ERROR,
    });

/**
 * Export SA analytics.
 * @param  {Object}   filters Analytics filters.
 * @return {Function} Dispatch actions.
 */
const exportAnalytics = (filters) => async (dispatch) => {
    dispatch(setLoadingState('EXPORT_SA_ANALYTICS'));

    try {
        const result = await exportAnalyticsApiCall(filters);

        const id = `sa_export_${filters.dateFrom}-${filters.dateTo}`;
        const link = document.createElement('a');
        link.setAttribute('id', id);
        link.download = `${id}.csv`;
        link.href = URL.createObjectURL(
            new File([result.data], `${id}.csv`, {
                type: 'text/csv',
            }),
        );
        link.click();

        return setTimeout(() => {
            link.remove();
        }, DELAY);
    } catch (exception) {
        return notify.error(t('GLOBAL.ERROR.GENERIC'));
    } finally {
        dispatch(clearLoadingState('EXPORT_SA_ANALYTICS'));
    }
};

export {
    setFormField,
    setFormFieldError,
    toggleExportModal,
    exportAnalytics,
    SET_FORM_FIELD_ERROR,
    TOGGLE_EXPORT_MODAL,
    UPDATE_FORM,
};
