import { Editor, Path, NodeEntry, Element, Range } from '@lumapps/wrex/slate';
import { isGrandParent } from '@lumapps/wrex/slate/utils/isGrandParent';

import { isListItem } from './isListItem';

/**
 * List nodes in range.
 */
export function getLowestListItemsInRange(editor: Editor, at: Range): Array<NodeEntry<Element>> {
    const nodes = Editor.nodes(editor, { at, match: isListItem });
    const start = Range.start(at || editor.selection);
    const seenNodePaths: Path[] = [];
    let started = false;
    const listItems: Array<NodeEntry<Element>> = [];
    for (const [node, path] of nodes) {
        if (
            (!started && isGrandParent(start.path, path)) ||
            (started && !seenNodePaths.some((seenPath) => Path.isDescendant(path, seenPath)))
        ) {
            started = true;

            seenNodePaths.push(path);
            listItems.push([node, path]);
        }
    }
    return listItems;
}
