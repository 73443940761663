/* istanbul ignore file */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { ChatsProps, UserTokenStatusProps, UserSpaces } from '../types';

const initialState: ChatsProps = {
    providers: [],
    chatProvidersStatus: null,
    userSpaces: [],
    userSpacesStatus: BaseLoadingStatus.initial,
    userTokenLoadingStatus: BaseLoadingStatus.initial,
    userTokenStatus: null,
    isUserTokenStatus: false,
    isDisconnecting: false,
    isFetchAuthorizeLink: false,
};

const { actions, reducer } = createSlice({
    domain: 'chat',
    initialState,
    reducers: {
        fetchProvidersLoading: (state: ChatsProps) => {
            set(state, 'chatProvidersStatus', 'loading');
        },
        fetchProvidersSuccess: (state: ChatsProps, action: PayloadAction<any>) => {
            const { providers } = action.payload;
            set(state, 'providers', providers);
            set(state, 'chatProvidersStatus', 'loaded');
        },
        fetchProvidersError: (state: ChatsProps, action: PayloadAction<string>) => {
            set(state, 'providers', initialState.providers);
            set(state, 'chatProvidersStatus', null);
            set(state, 'errorMessage', action.payload);
        },
        fetchUserSpacesLoading: (state: ChatsProps) => {
            set(state, 'userSpacesStatus', BaseLoadingStatus.loading);
        },
        fetchUserSpacesSuccess: (state: ChatsProps, action: PayloadAction<UserSpaces>) => {
            set(state, 'userSpaces', action.payload);
            set(state, 'userSpacesStatus', BaseLoadingStatus.idle);
        },
        fetchUserSpacesError: (state: ChatsProps) => {
            set(state, 'userSpaces', initialState.userSpaces);
            set(state, 'userSpacesStatus', BaseLoadingStatus.error);
        },
        setUserTokenStatus: (state: ChatsProps, action: PayloadAction<UserTokenStatusProps>) => {
            const status = action.payload;
            set(state, 'userTokenStatus', status);
        },
        setIsUserTokenStatus: (state: ChatsProps, action: PayloadAction<boolean>) => {
            const isUserTokenStatus = action.payload;
            set(state, 'isUserTokenStatus', isUserTokenStatus);
        },
        setUserTokenLoadingStatus: (state: ChatsProps, action: PayloadAction<BaseLoadingStatus>) => {
            set(state, 'userTokenLoadingStatus', action.payload);
        },
        setIsDisconnecting: (state: ChatsProps, action: PayloadAction<boolean>) => {
            const isDisconnecting = action.payload;
            set(state, 'isDisconnecting', isDisconnecting);
        },
        setIsFetchAuthorizeLink: (state: ChatsProps, action: PayloadAction<boolean>) => {
            const isFetchAuthorizeLink = action.payload;
            set(state, 'isFetchAuthorizeLink', isFetchAuthorizeLink);
        },
    },
});

export { actions, reducer };
