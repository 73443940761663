import get from 'lodash/get';
import { connect } from '@lumapps/redux/react';

import {
    DOMAIN,
    saveTermsAndConditions,
} from 'components/components/terms-and-conditions/ducks/termsAndConditions_actions';

import { TermsAndConditionsSaveButton } from './TermsAndConditionsSaveButton';

const mapStateToProps = (state) => {
    const { loadingState } = state;

    const isLoading = get(loadingState, [DOMAIN, 'loading'], false);

    return {
        isLoading,
    };
};

const connectedTermsAndConditionsSaveButton = connect(
    mapStateToProps,
    {
        saveTermsAndConditions,
    },
)(TermsAndConditionsSaveButton);

/////////////////////////////

export { connectedTermsAndConditionsSaveButton as TermsAndConditionsSaveButton };
