import { Route, AppId, createPageRoute } from '@lumapps/router';

import { METADATA_SCOPES } from './constants';

export interface AdminPageParams {
    section?: METADATA_SCOPES;
}

export const metadataBaseRoute: Route = createPageRoute({
    slug: 'metadata/:section?',
    appId: AppId.backOffice,
    id: 'metadata',
});

export const metadataAdminSectionRoute = (params: AdminPageParams): Route => {
    return {
        ...metadataBaseRoute,
        params: {
            section: params.section,
        },
    };
};
