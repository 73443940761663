import BaseApi from '@lumapps/base-api';
import { get as getConfig } from '@lumapps/constants';

import { SLACK_PROVIDER } from '../constants';

const ChatApi = new BaseApi({ path: 'chat' });

// BaseAPI for slack
const slackBaseAPI = (organizationId) => {
    const Config = getConfig();
    const slackBaseURL = `${Config.applicationHost}/_ah/api/lumsites/v2/organizations/${organizationId}`;
    return new BaseApi({ baseURL: slackBaseURL });
};

/**
 * Fetch the list of available user spaces for a provider.
 *
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of user spaces depending on the provider.
 */
const listUserSpaces = ({ provider, maxResults, cursor, page }) => {
    return ChatApi.post('userSpaces/list', {
        cursor,
        maxResults,
        page,
        provider,
    });
};

/**
 * Fetch the list of joined user spaces for a provider.
 *
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of joined user spaces depending on the provider.
 */
const listJoinedUserSpaces = ({ provider, maxResults, cursor, page }) => {
    return ChatApi.post('userSpaces/list/joined', {
        cursor,
        maxResults,
        page,
        provider,
    });
};

/**
 * Get a specific userSpace given a channel id.
 *
 * @param  {string}  provider  Provider (teams, yammer, slack...).
 * @param  {string}  spaceId   User space id.
 * @return {Promise} Returns the user space.
 */
const getUserSpaceById = ({ provider, spaceId }) => {
    const queryObject = {
        provider,
        spaceId,
    };

    const keys = Object.keys(queryObject).filter((key) => queryObject[key]);
    const queryParams = keys.map((key) => `${key}=${queryObject[key]}`).join('&');

    return ChatApi.get(`userSpaces/get?${queryParams}`);
};

/**
 * Fetch the list of available channels for a user space.
 *
 * @param  {string}  [callId]     Unique identifier of the api call.
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {string}  spaceId      User space id.
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of channels depending on the user space.
 */
const listChannelsForUserSpace = ({ callId, provider, spaceId, maxResults, cursor, page }) => {
    return ChatApi.post('channels/list', {
        callId,
        cursor,
        maxResults,
        page,
        provider,
        spaceId,
    });
};

/**
 * Fetch the list of joined channels for a user space.
 *
 * @param  {string}  [callId]     Unique identifier of the api call.
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {string}  spaceId      User space id.
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of channels depending on the user space.
 */
const listMyChannelsForUserSpace = ({ callId, provider, spaceId, maxResults, cursor, page }) => {
    return ChatApi.post('channels/list/joined', {
        callId,
        cursor,
        maxResults,
        page,
        provider,
        spaceId,
    });
};

/**
 * Get a specific channel for a user space given a channel id.
 *
 * @param  {string}  channelId Channel id.
 * @param  {string}  provider  Provider (teams, yammer, slack...).
 * @param  {string}  spaceId   User space id.
 * @return {Promise} Returns the channel.
 */
const getChannelById = ({ channelId, provider, spaceId }) => {
    const queryObject = {
        channelId,
        provider,
        spaceId,
    };

    const keys = Object.keys(queryObject).filter((key) => queryObject[key]);
    const queryParams = keys.map((key) => `${key}=${queryObject[key]}`).join('&');

    return ChatApi.get(`channels/get?${queryParams}`);
};

/**
 * Get a specific channel for a user space given a channel name.
 *
 * @param  {string}  channelName Channel name.
 * @param  {string}  provider    Provider (teams, yammer, slack...).
 * @param  {string}  spaceId     User space id.
 * @return {Promise} Returns the channel.
 */
const getChannelByName = ({ channelName, provider, spaceId }) => {
    const queryObject = {
        channelName,
        provider,
        spaceId,
    };

    const keys = Object.keys(queryObject).filter((key) => queryObject[key]);
    const queryParams = keys.map((key) => `${key}=${queryObject[key]}`).join('&');

    return ChatApi.get(`channels/getByName?${queryParams}`);
};

/**
 * Fetch the list of messages for a channel.
 *
 * @param  {string}  [callId]        Unique identifier of the api call.
 * @param  {string}  provider        Provider (teams, yammer, slack...).
 * @param  {string}  spaceId         User space id.
 * @param  {string}  channelId       Channel id.
 * @param  {string}  [chatMessageId] Message id (fetch the replies).
 * @param  {number}  [maxResults]    Page max results.
 * @param  {string}  [cursor]        Page cursor.
 * @param  {number}  [page]          Page number (used for manual pagination)
 * @return {Promise} Returns the list of messages depending on the channel.
 */
const listMessagesForChannel = ({
    callId,
    provider,
    spaceId,
    channelId,
    chatMessageId,
    fields,
    maxResults,
    cursor,
    page,
    organizationId,
    isSlackDefaultAppEnabled,
}) => {
    const queryParams = (qObject) => {
        const keys = Object.keys(qObject).filter((key) => qObject[key]);
        return keys.map((key) => `${key}=${qObject[key]}`).join('&');
    };

    if (provider === SLACK_PROVIDER && isSlackDefaultAppEnabled) {
        const queryObjectSlack = {
            cursor,
            maxResults,
        };
        const baseApiV2 = slackBaseAPI(organizationId);

        const getMessage = `/chats/${provider}/workspaces/${spaceId}/channels/${channelId}/messages`;

        // Fetch replies
        if (chatMessageId) {
            return baseApiV2.get(`${getMessage}/${chatMessageId}/replies?${queryParams(queryObjectSlack)}`);
        }

        return baseApiV2.get(`${getMessage}?${queryParams(queryObjectSlack)}`);
    }

    const queryObject = {
        callId,
        channelId,
        chatMessageId,
        cursor,
        fields,
        maxResults,
        page,
        provider,
        spaceId,
    };

    return ChatApi.get(`messages/list?${queryParams(queryObject)}`);
};

/**
 * Post a new message to a channel.
 *
 * @param  {string}  [callId]        Unique identifier of the api call.
 * @param  {string}  provider        Provider (teams, yammer, slack...).
 * @param  {string}  spaceId         User space id.
 * @param  {string}  channelId       Channel id.
 * @param  {string}  [chatMessageId] Message id (fetch the replies).
 * @param  {string}  body            Content of the message.
 * @param  {string}  bodyContentType Content type of the message (html, text...).
 * @return {Promise} Returns the new message posted.
 */
const postMessageToChannel = ({ callId, provider, spaceId, channelId, chatMessageId, body, bodyContentType }) => {
    return ChatApi.post('messages/create', {
        body,
        bodyContentType,
        callId,
        channelId,
        chatMessageId,
        provider,
        spaceId,
    });
};

/**
 * Fetch the profile picture of a user (from provider user id).
 *
 * @param  {string}  provider Provider (teams, yammer, slack...).
 * @param  {string}  userId   Provider user id.
 * @return {Promise} Returns the user profile picture.
 */
const getUserPhoto = ({ provider, userId }) => {
    const queryObject = {
        provider,
        userId,
    };

    const keys = Object.keys(queryObject).filter((key) => queryObject[key]);
    const queryParams = keys.map((key) => `${key}=${queryObject[key]}`).join('&');

    return ChatApi.get(`userPhoto?${queryParams}`);
};

/**
 * Fetch the list of available chat providers.
 * @param  {string}  userId A user id.
 *
 * @return {Promise} Returns the list of available chat providers.
 */
const listProviders = (params) => {
    let queryParams = '';

    if (params && params.userId) {
        queryParams = `userId=${params.userId}`;
    }

    return ChatApi.get(`providers/list?${queryParams}`);
};

/**
 * Delete an userspace.
 *
 * @param  {Object}  params Object with the uuid of the provider.
 * @return {Promise} The request promise.
 */
const deleteUserSpace = (params) => {
    return ChatApi.delete('userSpaces/unregister', {
        ...params,
    });
};

/**
 * Get the Userspace detail information, including creadentials
 *
 * @return {Promise} Returns the list of available chat providers.
 */
const getUserSpaceDetail = (params) => {
    return ChatApi.get(`userSpaces/get?spaceId=${params.spaceId}&provider=${params.provider}`);
};

/**
 * Save an userspace.
 *
 * @param  {Object}  params Additional request params.
 * @return {Promise} The request promise.
 */
const saveUserSpace = (params) => {
    return ChatApi.post('userSpaces/register', {
        ...params,
    });
};

/**
 * Get a user link to its IM.
 *
 * @return {Promise} Returns the link of the IM on the provider.
 */
async function getChannelLinkToUser({ provider, spaceId, userEmail, userId }) {
    const queryObject = {
        provider,
        spaceId,
        userEmail,
        userId,
    };

    const keys = Object.keys(queryObject).filter((key) => queryObject[key]);
    const queryParams = keys.map((key) => `${key}=${queryObject[key]}`).join('&');

    return ChatApi.get(`channels/user/link?${queryParams}`);
}

/**
 * Get a user link to its IM.
 *
 * @return {Promise} Returns the link of the IM on the provider.
 */
async function hasUserJoinedChannel({ provider, spaceId, channelId }) {
    const queryObject = {
        provider,
        spaceId,
        channelId,
    };

    const keys = Object.keys(queryObject).filter((key) => queryObject[key]);
    const queryParams = keys.map((key) => `${key}=${queryObject[key]}`).join('&');

    return ChatApi.get(`channels/user/joined?${queryParams}`);
}

export {
    getChannelById,
    getChannelByName,
    getChannelLinkToUser,
    getUserSpaceById,
    hasUserJoinedChannel,
    listUserSpaces,
    listJoinedUserSpaces,
    listChannelsForUserSpace,
    listMyChannelsForUserSpace,
    listMessagesForChannel,
    postMessageToChannel,
    getUserPhoto,
    listProviders,
    deleteUserSpace,
    getUserSpaceDetail,
    saveUserSpace,
};
