/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { learningApi } from '@lumapps/learning/constants';

import { LeaderboardDataType } from '../types';

/**
 * Leaderboard API to retrieve leaderboard data for learning feature.
 *
 */
const leaderboardApi = () =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL: learningApi.URL,
    });
const paths = {
    leaderboard: 'learning-analytics/v2/leaderboard',
};

export async function getLeaderboard(offset: number, limit: number, groupId?: string[]): Promise<LeaderboardDataType> {
    const { data } = await leaderboardApi().get<LeaderboardDataType>(paths.leaderboard, {
        params: { offset, limit, groupId },
    });

    return data;
}

export { leaderboardApi };
