import React from 'react';

import { useIsInView, type IntersectionOptions } from '../../hooks/useIsInView';
import { Suspense, type SuspenseProps } from '../Suspense';

export interface ImportOnVisibilityProps extends SuspenseProps {
    /** which HTML element to render for displaying the component. */
    as?: React.ElementType;
    /** component to lazy load when visible on screen. */
    children: React.ReactNode;
    /** className to be added to the component. */
    className?: string;
    /**
     * options to pass to the intersection observer.
     * `triggerOnce` is omitted because we hard code it to `true` inside the component.
     * (We want to import a component only once after all)
     */
    options?: Omit<IntersectionOptions, 'triggerOnce'>;
    /**
     * Additional props to forward to the root element (the `as` element)
     */
    rootProps?: any;
}

/**
 * This component, wrapping the React Suspense one, allows us to import components
 * only when they are visible in the viewport.
 *
 * To be considered:
 * ++ Faster initial load
 * -- Layout shift: A layout shift can occur if your fallback component and the component that eventually gets rendered differ a lot in size.
 *
 * @example
   const LazyComponent = React.lazy(() => import('./Component'));

   <ImportOnVisibility loadingFallback={<ComponentSkeleton />}>
       <LazyComponent />
   </ImportOnVisibility>
 */
export const ImportOnVisibility = ({
    as: IntersectionObserver = 'div',
    children,
    className,
    options,
    rootProps,
    ...suspenseProps
}: ImportOnVisibilityProps) => {
    const { ref, inView } = useIsInView({ ...options, triggerOnce: true });

    return (
        <IntersectionObserver className={className} ref={ref} {...rootProps}>
            <Suspense {...suspenseProps}>{inView ? children : suspenseProps.loadingFallback}</Suspense>
        </IntersectionObserver>
    );
};
