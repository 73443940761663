export enum WREX_LINK_PREVIEW {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=409367556
     */
    INSERT_RESOURCE = 'WREX_LINK_PREVIEW.INSERT_RESOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=524253687
     */
    EDIT_RESOURCE_PREVIEW = 'WREX_LINK_PREVIEW.EDIT_RESOURCE_PREVIEW',
}
