/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

const LazyComponent = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'emoji-picker' */
            './EmojiPickerDialog'
        ),
);

export const EmojiPickerDialog: React.FC<React.ComponentProps<typeof LazyComponent> & { isOpen?: boolean }> = ({
    isOpen,
    ...props
}) => (
    <DelayedSuspense shouldLoad={isOpen}>
        <LazyComponent isOpen={isOpen} {...props} />
    </DelayedSuspense>
);
