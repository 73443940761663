import BaseApi from '@lumapps/base-api';

const ChartApi = new BaseApi({ path: 'sheets' });

/**
 * Get the sheet list of a specific file.
 *
 * @param  {string}  fileId The selected file id.
 * @return {Promise} The API Call promise.
 */
const getSheetList = (fileId) => {
    return ChartApi.get(`/list?provider=excel&fileId=${fileId}`);
};

/**
 * Get the available chart list for a specific file.
 *
 * @param  {string}  fileId  The selected file id.
 * @param  {string}  sheetId The selected sheet id.
 * @return {Promise} The API Call promise.
 */
const getChartList = (fileId, sheetId) => {
    return ChartApi.get(`/charts/list?provider=excel&fileId=${fileId}&sheetId=${sheetId}`);
};

/**
 * Get the chart image for the specific chart a specific file.
 *
 * @param  {string}  chartId     The selected chart id.
 * @param  {string}  fileId      The file id.
 * @param  {string}  sheetId     The selected sheet id.
 * @param  {number}  chartWidth  The request chart width.
 * @param  {number}  chartHeight The request chart height.
 * @return {Promise} The API Call promise.
 */
const getChartImage = (chartId, fileId, sheetId, chartWidth, chartHeight) => {
    return ChartApi.get(
        `/charts/image?provider=excel&fileId=${fileId}&sheetId=${sheetId}&chartId=${chartId}&width=${chartWidth}&height=${chartHeight}&fittingMode=fit`,
    );
};

export { getSheetList, getChartList, getChartImage };
