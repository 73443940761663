import { get } from '@lumapps/constants/config';

import { Route } from './types';

const { applicationId } = get();

/**
 * Not found
 */
export const notFoundRoute: Route = {
    path: '/page-not-found',
    appId: applicationId as any,
};

/**
 * Unexpected Error
 */
export const errorRoute: Route = {
    path: '/unknown-error-occurred',
    appId: applicationId as any,
};

/**
 * Not Allowed
 * Home page displaying a "Not Allowed" toast message (see `ContentPage` for the implementation).
 * TODO: CF-657 improve UX with a dedicated page or a fallback to "not found"
 */
export const notAllowedRoute: Route = {
    path: '/',
    appId: applicationId as any,
    anchor: '#not-allowed',
};
