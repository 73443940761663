import React, { useCallback, useMemo, useRef, useState } from 'react';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useFeedKeyboardNavigation } from './useFeedKeyboardNavigation';

export interface UseFeedProps {
    /** The current status of the feed */
    status: BaseLoadingStatus;
    /** Callback when more items should be loaded */
    onLoadMore?: () => void;
}

/**
 * Hook to manage a Feed behavior
 *
 * @family Feed
 *  */
export const useFeed = ({ status, onLoadMore }: UseFeedProps) => {
    /** The reference to set on the feed element */
    const feedRef = useRef<HTMLDivElement | null>(null);

    /** Whether the feed is currently loading */
    const isLoading = [BaseLoadingStatus.loading, BaseLoadingStatus.loadingMore].includes(status);

    /**
     * The current state of the infinite scroll feature.
     */
    const [infiniteScrollEnabled, setInfiniteScroll] = useState(true);

    /**
     * When the user triggers a keyboard shortcut to set the focus on the first element
     * after the feed, we disable infinite scroll to avoid it being triggered when this element
     * is beneath the feed.
     */
    const onFocusOut = useCallback(() => {
        setInfiniteScroll(false);
    }, []);

    /**
     * Action when the focus is set back into the feed.
     * If the infinite scroll has been disabled, we re-enable it
     */
    const onFocusIn = useCallback(() => {
        if (!infiniteScrollEnabled) {
            setInfiniteScroll(true);
        }
    }, [infiniteScrollEnabled]);

    /**
     * Callback to set on <InfiniteScroll /> component.
     * This callback manages when more items should be fetched.
     *
     * We only fetch items when:
     * * No call is in progress
     * * Infinite scroll is enabled
     */
    const handleMore = React.useCallback(() => {
        if (status === BaseLoadingStatus.idle && infiniteScrollEnabled && onLoadMore) {
            onLoadMore();
        }
    }, [infiniteScrollEnabled, onLoadMore, status]);

    /** Keyboard navigation management */
    useFeedKeyboardNavigation({ feedRef, onFocusIn, onFocusOut });

    const response = useMemo(
        () => ({
            feedProps: {
                role: 'feed',
                'aria-busy': isLoading ? 'true' : undefined,
                ref: feedRef,
            } as const,
            handleMore,
        }),
        [handleMore, isLoading],
    );

    return response;
};
