import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';
import uniq from 'lodash/uniq';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { UserDirectory } from '../types';

const initialState = {
    entities: {},
    userListSettings: {
        query: '',
        isLoading: false,
        cursor: undefined,
        ids: [],
        hasMore: true,
    },
};

export interface UserDirectoriesResponse {
    cursor?: string;
    more?: string;
    items: Array<UserDirectory>;
}

export interface State {
    entities: Array<UserDirectory>;
    userListSettings: {
        isLoading: false;
        errorMessage?: string;
        cursor?: string;
        hasMore?: boolean;
    };
}

type ErrorMessage = string;

const { actions, reducer } = createSlice({
    domain: 'userDirectory',
    initialState,
    reducers: {
        setUserDirectoriesSearchStatus: (state: any, action: PayloadAction<boolean>) => {
            set(state, ['userListSettings', 'isLoading'], action.payload);
        },
        fetchUserDirectoriesSearchSuccess: (
            state: any,
            action: PayloadAction<UserDirectoriesResponse & { add?: boolean }>,
        ) => {
            const items = action.payload.items || [];
            const listByIds = keyBy(items, 'id');
            set(state, 'entities', { ...state.entities, ...listByIds });

            set(
                state,
                ['userListSettings', 'ids'],
                action.payload.add
                    ? uniq([...get(state, ['userListSettings', 'ids']), ...Object.keys(listByIds)])
                    : Object.keys(listByIds),
            );

            set(state, ['userListSettings', 'isLoading'], false);
            set(state, ['userListSettings', 'cursor'], action.payload.cursor);
            set(state, ['userListSettings', 'hasMore'], action.payload.more);
        },
        fetchUserDirectoriesSearchError: (state: any, action: PayloadAction<ErrorMessage>) => {
            set(state, ['userListSettings', 'ids'], initialState.userListSettings.ids);
            set(state, ['userListSettings', 'isLoading'], false);
            set(state, ['userListSettings', 'errorMessage'], action.payload);
        },
    },
});

export { actions, reducer };
