import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import isNaN from 'lodash/isNaN';
import trim from 'lodash/trim';

import { User, FieldValue, Selector } from '../types';
import { isUUID } from './uuid';

/**
 * Parse the value to the proper type
 * ex: 'true' => true
 * ex: '22' => 22
 * @param value
 */
const parseFilterValue = (value: string): FieldValue => {
    const v = trim(value);
    // Boolean value
    if (v === 'true' || v === 'false') {
        return v === 'true';
    }

    // Number value
    const parsedValue = parseInt(v, 10);
    if (!isNaN(parsedValue)) {
        return parsedValue;
    }

    return v;
};

/**
 * Compute a selector from a selector query
 * ex: organizations[primary=true] => { key: organizations, filterKey: primary, filterValue: true }
 * ex: title => { key: title }
 */
const computeSelector = (selector: string): Selector => {
    const regexp = /(.*)\[(.*)=(.*)]/;
    const match = regexp.exec(selector);

    // is there any filters?
    if (match) {
        return {
            key: match[1],
            filterKey: match[2],
            filterValue: parseFilterValue(match[3]),
        };
    }

    return {
        key: selector,
    };
};

const getApiProfileFieldFromMap = (id: string, apiProfile: User['apiProfile']): FieldValue => {
    if (!id || id === '') {
        return null;
    }

    const locators = id.split('/');

    // We start at the start of the tree
    let value: any = apiProfile;

    for (let index = 0; index < locators.length; index++) {
        const locator = locators[index];

        const selector = computeSelector(locator);

        // Get the value
        value = value[selector.key];

        // Check the value
        if (!value) {
            return null;
        }

        // Should we filter the data from the selectorFilter?
        if (selector.filterKey) {
            const values = filter(value, (v) => isEqual(v[selector.filterKey as string], selector.filterValue));

            value = values.length === 1 ? values[0] : values;
        }
    }

    return value || null;
};

const getCustomProfileData = (id: string, customProfile: User['customProfile']): FieldValue => {
    if (!customProfile || !id || id === '') {
        return null;
    }

    return customProfile[id] || null;
};

/**
 * Return the value of the user's ApiProfile field matching the path.
 * The field path can be like: "organizations/0/name", "addresses[type=home]/formatted", ...
 *
 * @param  {string}  userFieldPath               The path of the field we want to get in the user's
 *                                           ApiProfile or CustomProfile, if fieldPath is an
 *                                           UUID.
 * @param  {Object}  [user=<Connected User>] The user we want to get the ApiProfile field or
 *                                           CustomProfile value.
 * @param  {boolean} [patternOnly=false]     Only return the parsed pattern.
 * @return {*}       The user's ApiProfile field or CustomProfile value for the given path.
 */
const getFieldDataFromMap = (id: string, user: User): FieldValue => {
    if (!isUUID(id)) {
        const fieldPath = id.replace(/\./g, '/');
        return getApiProfileFieldFromMap(fieldPath, user.apiProfile);
    }

    return getCustomProfileData(id, user.customProfile);
};

/**
 * Retrieves a key from the api profile data structure.
 * @param key - key to search in the user api profile
 * @param user - user data
 */
const retrieveKeyFromApiProfile = (key: string, user: User) => {
    /**
     * We are ignoring this typescript error since TS wants us to use only the possible
     * keys from user.apiProfile.organizations, which seems like an overkill.
     */
    return user.apiProfile?.organizations && user.apiProfile.organizations.length > 0
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user.apiProfile.organizations[0][key]
        : undefined;
};

export { getApiProfileFieldFromMap, getCustomProfileData, getFieldDataFromMap, retrieveKeyFromApiProfile };
