import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { VideoDate } from '@lumapps/play/components/VideoDate';
import { handleVideoDatePrefix } from '@lumapps/play/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-video-date` as const;

interface DateProps extends Omit<TextProps, 'as'> {
    /**
     * Which date is displayed?
     * @default createdAt
     */
    is?: 'createdAt' | 'updatedAt';
    /** Whether to display add a prefix to the date  */
    withPrefix?: boolean;
    /* Used to know if we are inside a video picker */
    isPickerContext?: boolean;
}

const VIDEO_STATUS_DATE: Record<string, { translationKey: string; dateKey: 'startsAt' | 'publishedAt' }> = {
    scheduled: {
        translationKey: GLOBAL.SCHEDULED_ON_DATE,
        dateKey: 'startsAt',
    },

    published: {
        translationKey: GLOBAL.PUBLISHED_ON_DATE_COLON,
        dateKey: 'publishedAt',
    },
};

/**
 * Displays the date of a video
 */
export const Date = ({
    is = 'createdAt',
    typography = Typography.body1,
    withPrefix,
    isPickerContext,
    ...textProps
}: DateProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey, translateAndReplace } = useTranslate();

    const dateStringToLocaleString = (dateString?: string) => {
        return formatFromNow({
            date: dateString,
            thresholdDisplayFullDate: 0,
            aboveThresholdDisplayFullDate: 0,
            dateStringFormat: 'll',
        });
    };

    const { canDisplay, isLoading, video, theme } = useBlockVideoContext();

    if (!canDisplay('DATE')) {
        return null;
    }

    if (isLoading) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="15%" />;
    }

    if (!video?.date) {
        return null;
    }

    const publishedAtDate =
        (video.status === 'published' || video.publicationStatus === 'published') && video.publishedAt;

    const selectedPublicationStatusDate =
        video.status === 'published' || video.publicationStatus === 'published'
            ? VIDEO_STATUS_DATE.published
            : VIDEO_STATUS_DATE.scheduled;

    return (
        <Text {...textProps} as="span" truncate>
            {is === 'updatedAt' && withPrefix && (
                <>
                    {handleVideoDatePrefix({
                        date: video.date,
                        keyWithSuffix: translateKey(GLOBAL.UPDATED_ON),
                        keyWithoutSuffix: translateKey(GLOBAL.UPDATED),
                    })}
                    &nbsp;
                </>
            )}
            {isPickerContext ? (
                <time>
                    {translateAndReplace(selectedPublicationStatusDate.translationKey, {
                        DATE: dateStringToLocaleString(video[selectedPublicationStatusDate.dateKey] || video.date),
                    })}
                </time>
            ) : (
                <VideoDate capitalize={!withPrefix} date={publishedAtDate || video.date} />
            )}
        </Text>
    );
};
