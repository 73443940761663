import React from 'react';

import { BroadcastSettingMenu } from '@lumapps/broadcasts/components/BroadcastSettingMenu';
import { CampaignSettingMenu } from '@lumapps/campaigns/components/CampaignSettingMenu';
import { ContentStreamSettingsMenu } from '@lumapps/content-streams-admin/components/ContentStreamSettingsMenu';
import { useDataAttributes } from '@lumapps/data-attributes';
import { JourneysSettingMenu } from '@lumapps/journeys-admin/components/JourneysSettingMenu';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { SegmentSettingMenu } from '@lumapps/segment-admin/components/SegmentSettingMenu/SegmentSettingMenu';
import { useTranslate } from '@lumapps/translations';

import { SETTINGS_MENU } from '../../keys';

export interface SettingsMenuContentStrategyProps {
    canAccessBroadcast: boolean;
    canAccessJourneys: boolean;
    canAccessSegment: boolean;
    canAccessContentStream: boolean;
    scope: string;
}

export const SettingsMenuContentStrategy: React.FC<SettingsMenuContentStrategyProps> = ({
    canAccessBroadcast,
    canAccessJourneys,
    canAccessSegment,
    canAccessContentStream,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!canAccessBroadcast && !canAccessJourneys && !canAccessSegment) {
        return null;
    }

    return (
        <Menu.Section label={translateKey(SETTINGS_MENU.CONTENT_STRATEGY)} {...get({ element: 'content-strategy' })}>
            {canAccessBroadcast && (
                <>
                    <BroadcastSettingMenu scope={scope} />
                    <CampaignSettingMenu scope={scope} />
                </>
            )}
            {canAccessJourneys && <JourneysSettingMenu scope={scope} />}
            {canAccessSegment && <SegmentSettingMenu scope={scope} />}
            {canAccessContentStream && <ContentStreamSettingsMenu scope={scope} />}
        </Menu.Section>
    );
};

SettingsMenuContentStrategy.displayName = 'SettingsMenuContentStrategy';
