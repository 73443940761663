import React from 'react';

import { SPACES } from '@lumapps/communities/keys';
import { RenderingType } from '@lumapps/communities/types';
import { spacesAdminRoute } from '@lumapps/community-admin/routes';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiCubeUnfolded } from '@lumapps/lumx/icons';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

export interface SpacesModuleSettingsMenuEntryProps {
    isAllowed: boolean;
    scope: string;
}

export const SpacesModuleSettingsMenuEntry: React.FC<SpacesModuleSettingsMenuEntryProps> = ({ isAllowed, scope }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item
            icon={mdiCubeUnfolded}
            as={RouterLink}
            to={spacesAdminRoute}
            {...get({ element: RenderingType.space })}
        >
            {translateKey(SPACES.NAV)}
        </Menu.Item>
    );
};
