import { Image } from '@lumapps/lumx-images/types';
import { getImageForThumbnail } from '@lumapps/lumx-images/utils';

import { DirectoryEntry } from './types';

export const getDirectoryEntryImage = (entry: DirectoryEntry): Image | undefined => {
    const { thumbnail } = entry;

    return getImageForThumbnail(thumbnail);
};
