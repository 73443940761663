import { HeaderSettings } from '@lumapps/header/components/Header/HeaderSettings';

import React from 'react';
import { RouterWrapper } from './router-wrapper';

const HeaderSettingsLegacy = ({ current, params }) => {
    return (
        <RouterWrapper current={current} params={params}>
            <HeaderSettings />
        </RouterWrapper>
    );
};

export { HeaderSettingsLegacy as HeaderSettings };
