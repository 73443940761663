import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useCustomizations, MODES } from '@lumapps/customizations';
import { Targets, CustomizationComponent, PLACEMENT } from '@lumapps/customizations/api';
import { MAIN_NAV_ID } from '@lumapps/navigation/constants';
import { useIsNavigationDisabled } from '@lumapps/navigation/hooks/useIsNavigationDisabled';
import { MainNavigation as Navigation } from '@lumapps/navigation/top-navigation';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { ContentHeader } from '../ContentHeader';

export interface HeaderNavigationProps {
    /**
     * the id of this site's parent instance
     */
    parentInstanceId?: string;
    /**
     * current instance id
     */
    instanceId: string;
    /**
     * current language
     */
    currentLanguage: string;
    /**
     * List of FFs and whether they are activated or not.
     */
    features: Record<string, boolean>;
    /**
     * Whether the content header to be displayed has a height > 0
     */
    hasContentHeader?: boolean;
    /**
     * Whether the child navigation is visible
     */
    isChildNavigationVisible: boolean;
    /**
     * Whether the parent navigation is sticky
     */
    isParentNavSticky: boolean;
    /**
     * Instance id for the parent navigation
     */
    topNavigationInstanceId: string;
    /**
     * Whether there is a parent navigation or not
     */
    isParentNav: boolean;
    /**
     * Whether the content header should be displayed
     */
    shouldShowContentHeader?: boolean;
    /**
     * Whether the child navigation is sticky
     */
    isChildNavSticky: boolean;
    /**
     * Reference to the navigation
     */
    navRef: React.RefObject<HTMLDivElement>;
    /**
     * callback to open the side navigation
     */
    setIsSideNavOpen: (isOpen: boolean) => void;
    /**
     * whether the side nav is open
     */
    isSideNavOpen: boolean;
    /**
     * Disables the navigation
     */
    isDisabled?: boolean;
}

export const CLASSNAME = 'header';
const CLASSNAME_NAV = 'header-main-nav';

export const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
    instanceId,
    parentInstanceId,
    features,
    hasContentHeader,
    currentLanguage,
    isChildNavigationVisible,
    isParentNavSticky,
    topNavigationInstanceId,
    isParentNav,
    shouldShowContentHeader,
    isChildNavSticky,
    navRef,
    setIsSideNavOpen,
    isSideNavOpen,
    isDisabled,
}) => {
    const { modes } = useCustomizations();
    const { isNavigationDisabled, isSubNavigationDisabled } = useIsNavigationDisabled();
    const hasParentDefined = Boolean(parentInstanceId);

    /**
     * HEAD'S UP!
     * If you are going to add any components to the header, you will need to update the component
     * LoadingHeader and add a skeleton for the one you added. Also, you will need to go to the
     * /apps/front-office/public/index.html and change the HTML markup to add that skeleton as well.
     *
     * That markup is the one displayed between the moment the browser has downloaded the HTML of the
     * page, but the javascript has not yet loaded. Since we do not have React to render something, that is why
     * the code is directly as an HTML.
     */
    return (
        <>
            <CustomizationComponent target={Targets.NAVIGATION} placement={PLACEMENT.ABOVE} />

            {!isNavigationDisabled ? (
                <div
                    className={classnames(CLASSNAME_NAV, {
                        sticky: isParentNavSticky,
                        [`${CLASSNAME_NAV}--no-top-bar`]: Boolean(modes[MODES.NO_TOP_BAR]),
                        [`${CLASSNAME_NAV}--parent`]: isChildNavigationVisible,
                    })}
                >
                    <Navigation
                        id={MAIN_NAV_ID}
                        onClose={() => setIsSideNavOpen(false)}
                        isOpen={isSideNavOpen}
                        instance={topNavigationInstanceId}
                        isParentNav={isParentNav}
                        isMainNavInheritanceEnabled={features ? features.mainNavInheritance : false}
                        isDisabled={isDisabled}
                    />
                </div>
            ) : null}

            <CustomizationComponent target={Targets.NAVIGATION} placement={PLACEMENT.UNDER} />

            {shouldShowContentHeader ? (
                <ErrorBoundary>
                    <ContentHeader currentLanguage={currentLanguage} />
                </ErrorBoundary>
            ) : null}

            {hasParentDefined ? (
                <CustomizationComponent target={Targets.SUB_NAVIGATION} placement={PLACEMENT.ABOVE} />
            ) : null}

            {isChildNavigationVisible && !isSubNavigationDisabled ? (
                <div
                    className={classnames(`${CLASSNAME_NAV} ${CLASSNAME_NAV}--child`, {
                        sticky: isChildNavSticky,
                        [`${CLASSNAME_NAV}--child-no-content-header`]: !hasContentHeader && !isChildNavSticky,
                    })}
                    ref={navRef}
                >
                    <Navigation
                        onClose={() => setIsSideNavOpen(false)}
                        isOpen={isSideNavOpen}
                        instance={instanceId}
                        isMainNavInheritanceEnabled
                        isDisabled={isDisabled}
                    />
                </div>
            ) : null}

            {hasParentDefined ? (
                <CustomizationComponent target={Targets.SUB_NAVIGATION} placement={PLACEMENT.UNDER} />
            ) : null}
        </>
    );
};
