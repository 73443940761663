import React from 'react';

import { classnames } from '@lumapps/classnames';
import { mdiAccount } from '@lumapps/lumx/icons';
import { Link, LinkProps, Placement } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { UserCardPopover, useUserCardPopover, UserCardPopoverProps } from '../UserCardPopover';

export interface UserLinkProps
    extends Pick<LinkProps, 'leftIcon' | 'isDisabled' | 'color' | 'colorVariant' | 'target' | 'typography'> {
    /** Base user info */
    user: { id: string; fullName: string };
    /** Class name to forward to the link. */
    className?: string;
    /** Displays a mdi-account icon on the left of the link. */
    showIcon?: boolean;
    /** (default: true) Displays the user card popover on mouse hover. */
    showCardOnHover?: boolean;
    /** Forward props to the user card popover. */
    userCardPopoverProps?: Partial<UserCardPopoverProps>;
    children?: any;
}

const CLASSNAME = 'user-link';

/**
 * LumX Link specialized to link user to it's profile
 * - Shows user card popover on mouse hover
 * - Can show an icon if requested
 */
export const UserLink = React.forwardRef<HTMLAnchorElement, UserLinkProps>((props, ref) => {
    const {
        className,
        user,
        showIcon,
        showCardOnHover,
        children,
        isDisabled,
        leftIcon,
        userCardPopoverProps,
        ...forwardedProps
    } = props;

    const { route: userProfileRoute } = useSocialProfileRoute({
        routeParams: { userId: user.id, query: { details: true } },
        userData: user,
    });

    const {
        onMouseEnter,
        onMouseLeave,
        onUserCardMouseEnter,
        onUserCardMouseLeave,
        userProfile,
        isLoadingUser,
        showUserCard,
    } = useUserCardPopover(user);
    const refForCard = React.useRef<HTMLAnchorElement>(null);

    const linkProps = !isDisabled ? { linkAs: RouterLink, to: userProfileRoute } : undefined;

    return (
        <>
            <Link
                ref={mergeRefs([ref, refForCard])}
                {...forwardedProps}
                className={classnames(className, CLASSNAME)}
                leftIcon={leftIcon || showIcon ? mdiAccount : undefined}
                {...linkProps}
                onMouseEnter={!isDisabled && showCardOnHover ? onMouseEnter : undefined}
                onMouseLeave={!isDisabled && showCardOnHover ? onMouseLeave : undefined}
            >
                {children || user.fullName}
            </Link>
            {showCardOnHover && (
                <UserCardPopover
                    {...userCardPopoverProps}
                    user={userProfile}
                    isDetailsOpen={showUserCard}
                    anchorRef={refForCard}
                    onClose={onUserCardMouseLeave}
                    isLoading={isLoadingUser}
                    onMouseEnter={onUserCardMouseEnter}
                    placement={Placement.BOTTOM_START}
                />
            )}
        </>
    );
});
UserLink.defaultProps = { showCardOnHover: true };
UserLink.displayName = 'UserLink';
