import { connect } from '@lumapps/redux/react';

import { getCurrentWidgets } from 'components/components/social-advocacy/ducks/widgets';

import { SAPublicTemplate } from './SAPublicTemplate';

const mapStateToProps = (state) => ({
    logo: state.instance.logo,
    style: state.style.global,
    widgets: getCurrentWidgets(state),
});

const mapDispatchToProps = {};

const ConnectedSAPublicTemplate = connect(mapStateToProps, mapDispatchToProps)(SAPublicTemplate);

export { ConnectedSAPublicTemplate as SAPublicTemplate };
