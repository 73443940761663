/* eslint-disable id-blacklist */
/* eslint-disable import/unambiguous */

import { error } from 'components/services/notification';

import { translate as t } from 'components/translations';

import { clearLoadingState, setLoadingState } from 'components/components/ducks/loadingStates';

import { getPostsTask, getPostByIdTask } from './wall_tasks';

const APPEND_POSTS = 'wall/APPEND_POSTS';
const INIT_STATE = 'wall/INIT_STATE';
const SET_POSTS = 'wall/SET_POSTS';
const SET_NEXTPAGE = 'wall/SET_NEXTPAGE';

/**
 * Dispatch the INIT_STATE action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const initState = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: INIT_STATE,
        });
    })();
};

/**
 * Get post.
 *
 * @param  {string}  provider    The provider used.
 * @param  {string}  spaceId     The provider group.
 * @param  {number}  maxResults  The maximum number of results returned by the request.
 * @param  {string}  messageType The type of post.
 * @param  {string}  channelId   The id of the channel
 * @param  {string}  orderBy     Key to order to.
 * @param  {boolean} orderAsc    The order direction.
 * @param  {string}  uuid        The widget uuid.
 * @return {Promise} The API Call promise.
 */
const getPosts = (provider, spaceId, maxResults, messageType, channelId, orderBy, orderAsc, uuid) => (dispatch) => {
    dispatch(setLoadingState(uuid, false));

    return getPostsTask(provider, spaceId, maxResults, messageType, null, channelId, orderBy, orderAsc)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_NEXTPAGE,
            });
            dispatch({
                payload: {
                    data: payload.items,
                    uuid,
                },
                type: SET_POSTS,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_WALL.ERROR.FETCH_POSTS_ERROR')))
        .finally(() => {
            dispatch(clearLoadingState(uuid));
        });
};

/**
 * Get post by ID.
 *
 * @param  {string}  provider  The provider used.
 * @param  {string}  spaceId   The provider group.
 * @param  {string}  channelId The provider channel.
 * @param  {string}  postId    The targeted post id.
 * @param  {string}  uuid      The uuid.
 * @return {Promise} The API Call promise.
 */
const getPostByPostId = (provider, spaceId, channelId, postId, uuid) => (dispatch) => {
    dispatch(setLoadingState(uuid, false));

    return getPostByIdTask(provider, spaceId, channelId, postId)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: { cursor: '', more: null },
                    uuid,
                },
                type: SET_NEXTPAGE,
            });
            dispatch({
                payload: {
                    data: [payload.item],
                    uuid,
                },
                type: SET_POSTS,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_WALL.ERROR.FETCH_POSTS_ERROR')))
        .finally(() => {
            dispatch(clearLoadingState(uuid));
        });
};

/**
 * Get next page.
 *
 * @param  {string}  provider       The provider used.
 * @param  {string}  spaceId        The provider group.
 * @param  {number}  maxResults     The maximum number of results returned by the request.
 * @param  {string}  messageType    The type of post.
 * @param  {string}  nextPageCursor The next page cursor.
 * @param  {string}  channelId      The id of the channel
 * @param  {string}  orderBy        Key to order to.
 * @param  {boolean} orderAsc       The order direction.
 * @param  {string}  uuid           The widget uuid.
 * @return {Promise} The API Call promise.
 */
const getNextPage = (
    provider,
    spaceId,
    maxResults,
    messageType,
    nextPageCursor,
    channelId,
    orderBy,
    orderAsc,
    uuid,
) => (dispatch) => {
    dispatch(setLoadingState(uuid, false));

    return getPostsTask(provider, spaceId, maxResults, messageType, nextPageCursor, channelId, orderBy, orderAsc)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_NEXTPAGE,
            });
            dispatch({
                payload: {
                    data: payload.items,
                    uuid,
                },
                type: APPEND_POSTS,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_WALL.ERROR.FETCH_POSTS_ERROR')))
        .finally(() => {
            dispatch(clearLoadingState(uuid));
        });
};

export { APPEND_POSTS, INIT_STATE, SET_NEXTPAGE, SET_POSTS, getNextPage, getPosts, getPostByPostId, initState };
