import isEmpty from 'lodash/isEmpty';

import { get } from '@lumapps/constants';
import { calculateThemeColor } from '@lumapps/utils/css/calculateThemeColor';

import { useStyles, useChildStyles } from './useStyles';

const constants = get();

export interface UseTopStylesProps {
    /** Color to be used depending on the current theme selected */
    themeColor: string;
    /** theme selected */
    theme: string;
    /** background color for the top bar */
    backgroundColor: string | null;
    /** determines whether the style is the default one or not */
    isDefault?: boolean;
}

/**
 * Retrieves the customizations implemented to the current instance that are related to the top bar
 */
const useTopStyles = (): UseTopStylesProps => {
    const { styles } = useStyles({ property: 'top' }) || {};
    const theme = styles.theme || constants.topStyles?.theme;
    const themeColor = calculateThemeColor(theme);

    return {
        ...styles,
        themeColor,
        theme,
        backgroundColor: styles.backgroundColor || constants.instanceTopBackgroundColor,
        isDefault: isEmpty(styles),
    };
};

/**
 * Retrieves the customizations implemented to the current instance that are related to the top bar
 */
const useChildTopStyles = (): UseTopStylesProps => {
    const { styles } = useChildStyles({ property: 'top' }) || {};
    const { theme } = styles;
    const themeColor = calculateThemeColor(theme);

    return {
        ...styles,
        themeColor,
        theme,
        backgroundColor: styles.backgroundColor,
        isDefault: isEmpty(styles),
    };
};

export { useTopStyles, useChildTopStyles };
