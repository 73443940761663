import React from 'react';

import { ColorPalette, ColorVariant, InlineList, Text, Theme, Typography } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { PLAY_VIDEO } from '../../../keys';
import { Author } from '../Author';
import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';
import { Date } from '../Date';
import { GroupDetails } from '../GroupDetails';
import { SiteDetails } from '../SiteDetails';
import { Status } from '../Status';
import { Views } from '../Views';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-metadata` as const;

/**
 * Displays the metadata of a video
 */
export const Metadata = ({ children }: { children: React.ReactNode }) => {
    const { translateKey } = useTranslate();

    const { canDisplay, isLoading, theme, video } = useBlockVideoContext();

    const metadatas = React.useMemo(() => {
        if (!children) {
            return null;
        }

        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return null;
            }
            /**
             * ⚠️ The <InlineList /> component maps its children to know whether it should display the dot separators.
             * It is important to leave the check here
             */
            switch (child.type) {
                case Author: {
                    return (isLoading || video.createdByDetails) && canDisplay('AUTHOR') && child;
                }

                case Date: {
                    return (isLoading || video.date) && canDisplay('DATE') && child;
                }

                case GroupDetails: {
                    return (isLoading || video.groupDetails?.length) && canDisplay('GROUP_DETAILS') && child;
                }

                case SiteDetails: {
                    return (isLoading || video.siteDetails) && canDisplay('SITE_DETAILS') && child;
                }

                case Status: {
                    return (isLoading || video.status) && canDisplay('STATUS') && child;
                }

                case Views: {
                    return (isLoading || video.views) && canDisplay('VIEWS') && child;
                }

                default: {
                    return child;
                }
            }
        })?.filter(Boolean);
    }, [
        canDisplay,
        children,
        isLoading,
        video?.createdByDetails,
        video?.date,
        video?.groupDetails?.length,
        video?.siteDetails,
        video?.status,
        video?.views,
    ]);

    const styles = React.useMemo(
        () => ({
            color: theme === Theme.light ? ColorPalette.dark : ColorPalette.light,
            colorVariant: theme === Theme.light ? ColorVariant.L2 : ColorVariant.L2,
            typography: Typography.body1,
        }),
        [theme],
    );

    if (!metadatas?.length) {
        return null;
    }

    return video?.restricted ? (
        <Text as="span" {...styles}>
            {translateKey(PLAY_VIDEO.VIDEO_NO_ACCESS)}
        </Text>
    ) : (
        <InlineList {...styles}>{metadatas}</InlineList>
    );
};
