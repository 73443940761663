import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface UseSlideshowControlLabelsProps {
    /** whether the slideshow is currently autoplaying or not */
    isAutoPlaying?: boolean;
}

export interface UseSlideshowControlLabels {
    /** label to be used for the next button */
    next: string;
    /** label to be used for the previous button */
    previous: string;
    /** label to be used for the play button */
    play: string;
    /** label to be used for each pagination item */
    paginationItem: (index: number) => string;
    /** label to be used for each slide group */
    slideGroup: (currentGroup: number, totalGroups: number) => string;
}

/**
 * Returns the labels that should be used for the slideshow controls component.
 * @param props - UseSlideshowControlLabelsProps
 * @returns labels to be used
 */
export const useSlideshowControlLabels = (props?: UseSlideshowControlLabelsProps): UseSlideshowControlLabels => {
    const { isAutoPlaying } = props || {};
    const { translateKey, translateAndReplace } = useTranslate();

    return {
        next: translateKey(GLOBAL.NEXT_SLIDE),
        previous: translateKey(GLOBAL.PREVIOUS_SLIDE),
        play: isAutoPlaying ? translateKey(GLOBAL.PAUSE) : translateKey(GLOBAL.PLAY),
        paginationItem: (index) => translateAndReplace(GLOBAL.SLIDE_NB, { NB: index + 1 }),
        slideGroup: (currentGroup, totalGroup) =>
            translateAndReplace(GLOBAL.SLIDE_NB_OF_NB_TOTAL, { NB_SLIDE: currentGroup, NB_TOTAL: totalGroup }),
    };
};
