import { getToken } from '../../../services/user';

/**
 * Get default common request headers
 *
 * @return {Object} Common default request headers.
 */
function getDefaultHeaders() {
    return {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${getToken()}`,
        'Content-Type': 'application/json; charset=UTF-8',
    };
}

/**
 * Send a post request.
 *
 * @param  {string}      url       Url of request.
 * @param  {Object|File} [payload] Request body content.
 * @param  {Object}      [config]  Request configuration.
 * @async
 * @return {Promise}     Request promise.
 */
export async function post({ url, payload = {}, config = {} }) {
    const { headers: additionalHeaders, ...additionalParams } = config;
    const headers = Object.assign(getDefaultHeaders(), additionalHeaders);

    const fetchConfig = Object.assign({
        body: (payload instanceof File) ? payload : JSON.stringify(payload),
        credentials: 'same-origin',
        headers,
        method: 'POST',
    }, additionalParams);

    return await (await (fetch(url, fetchConfig))).json();
}
