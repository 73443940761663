/* global angular */
'use strict'; // jshint ignore:line


angular
    .module('Directives')
    .directive('adminNavAccordion', function()
    {
        return {
            restrict: 'A',
            scope: {},
            controller: ['$scope', '$element', function($scope, $element)
            {
                var openingState = false;

                this.init = function(closed)
                {
                    $scope.opened = !!(angular.isUndefined(closed) || (angular.isDefined(closed) && !closed));
                };

                this.open = function()
                {
                    $scope.opened = true;
                };

                this.close = function()
                {
                    if (!openingState)
                    {
                        $scope.opened = false;
                    }
                };

                this.toggle = function()
                {
                    $scope.opened = !$scope.opened;
                };

                $scope.$watch('opened', function(isOpened, wasOpened)
                {
                    if (isOpened !== wasOpened && isOpened)
                    {
                        openAccordionBox();
                    }

                    if (isOpened !== wasOpened && !isOpened)
                    {
                        closeAccordionBox();
                    }

                    if (isOpened === wasOpened && !isOpened && !wasOpened)
                    {
                        $element.next().hide();
                    }

                    if (isOpened)
                    {
                        $element.removeClass('admin-nav__link--accordion-close');
                        $element.addClass('admin-nav__link--accordion-open');
                    }
                    else
                    {
                        $element.removeClass('admin-nav__link--accordion-open');
                        $element.addClass('admin-nav__link--accordion-close');
                    }
                });

                function openAccordionBox()
                {
                    openingState = true;

                    $element
                        .next()
                            .velocity('slideDown', {
                                duration: 400,
                                easing: 'easeOutQuint',
                                complete: function()
                                {
                                    openingState = false;
                                }
                            });
                }

                function closeAccordionBox()
                {
                    $element
                        .next()
                            .velocity('slideUp', {
                                duration: 400,
                                easing: 'easeOutQuint'
                            });
                }
            }],
            link: function(scope, element, attrs, ctrl)
            {
                ctrl.init(attrs.closed);

                element.bind('click', function()
                {
                    scope.$apply(function()
                    {
                        ctrl.toggle();
                    });
                });

                attrs.$observe('closed', function()
                {
                    if (scope.$eval(attrs.closed))
                    {
                        ctrl.close();
                    }
                    else
                    {
                        ctrl.open();
                    }
                });
            }
        };
    });
