import React from 'react';

import set from 'lodash/set';

import { Image } from '@lumapps/lumx-images/types';
import { FocusPoint } from '@lumapps/lumx/react';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { CENTER_FOCAL_POINT } from '../constants';
import { CropInfo, Point } from '../types';
import { areFocusPointDifferent } from '../utils';

export interface UseCroppedImageState {
    image?: Image;
    originalImage?: Image;
    focalPoint: Point;
    cropInfo?: CropInfo;
}

export const initialState: UseCroppedImageState = {
    focalPoint: CENTER_FOCAL_POINT,
};

export const reducers = {
    reset: (state: UseCroppedImageState) => {
        const { originalImage } = state;

        set(state, 'image', originalImage);
        set(state, 'focalPoint', initialState.focalPoint);
        set(state, 'cropInfo', undefined);
        set(state, 'originalImage', undefined);
    },
    setCroppedImage: (state: UseCroppedImageState, action: PayloadAction<UseCroppedImageState>) => {
        const { image, originalImage } = state;

        set(state, 'image', action.payload.image);
        set(state, 'focalPoint', action.payload.focalPoint);
        set(state, 'cropInfo', action.payload.cropInfo);

        if (!originalImage) {
            set(state, 'originalImage', {
                ...image,
            });
        }
    },
    setImage: (state: UseCroppedImageState, action: PayloadAction<Image>) => {
        set(state, 'image', action.payload);
        set(state, 'focalPoint', initialState.focalPoint);
        set(state, 'cropInfo', undefined);
        set(state, 'originalImage', undefined);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'use-cropped-image',
    initialState,
    reducers,
});

export interface UseCroppedImageOptions {
    /** image to be cropped */
    image?: Image;
    /** callback on image cropped */
    onCrop: (image: Image, focus: FocusPoint) => void;
}

export interface UseCroppedImage extends UseCroppedImageState {
    /** resets the cropped image to the original one */
    reset: () => void;
    /** set current image */
    setImage: (image: Image) => void;
    /** change the internal state of the cropped image by hand */
    setCroppedImage: (info: UseCroppedImageState) => void;
    /** callback on cropping an image */
    onCroppedImage: (focus: Point, blob: Blob | null, cropInfo?: CropInfo) => void;
}

/**
 * Hook that manages the internal state of a cropped image.
 */
export const useCroppedImage = ({ image, onCrop }: UseCroppedImageOptions): UseCroppedImage => {
    const [state, dispatch] = React.useReducer(reducer, { ...initialState, image });

    const setCroppedImage = (info: UseCroppedImageState) => {
        dispatch(actions.setCroppedImage(info));
    };

    const reset = () => {
        dispatch(actions.reset());
    };

    const setImage = (image: Image) => {
        dispatch(actions.setImage(image));
    };

    React.useEffect(() => {
        if (image) {
            /**
             * We only update the current image if:
             * - If there is no original image (meaning it was not cropped), we update
             * the image only if the ids are different
             */
            if (!image.originalImage && image.id !== state.image?.id) {
                setImage(image);
            }

            /**
             * If there is an original image, we compare the ids of those two. The id of a cropped
             * image is different from the id of the original image, so we take both images, the current
             * and the new image and compare their original image ids. If they are different
             * we can say that they are different images.
             */
            if (image.originalImage && state.originalImage?.id !== image.originalImage.id) {
                setImage(image);
            }
        }
    }, [image, state.image?.id, state.originalImage?.id]);

    const onCroppedImage = React.useCallback(
        (focus: Point, blob: Blob | null, cropInfo?: CropInfo) => {
            const { originalImage, focalPoint, image: currentImage } = state;

            if (currentImage) {
                /**
                 * If there is an image, it means that the image was cropped.
                 * In that scenario, we need to create a new file for the cropped image,
                 * create a blob url for it and then update the set and call the onCrop callback
                 * accordingly
                 */
                if (blob) {
                    const file = new File([blob], (currentImage.name || currentImage.file?.name) as string);
                    const blobUrl = URL.createObjectURL(file);

                    const newImage: Image = {
                        ...currentImage,
                        blobUrl,
                        file,
                        originalImage: originalImage || currentImage,
                    };

                    setCroppedImage({
                        focalPoint: focus,
                        image: newImage,
                        cropInfo,
                    });

                    onCrop(newImage, focus);
                    /**
                     * When the image is not defined but the focalPoint is different, it means
                     * that only the focal point was changed. In that scenario we don't need to
                     * create anything for the image, we just return the image that we already
                     * have and update the focal point.
                     *
                     * We also check that this is not the case of a reset. If that is the case, then
                     * the `y` coordinate will be -0. In that scenario we avoid entering this condition
                     * and we let the code go to the else.
                     */
                    // eslint-disable-next-line no-compare-neg-zero
                } else if (focus && focalPoint && areFocusPointDifferent(focus, focalPoint) && focus.y !== -0) {
                    onCrop(
                        {
                            ...currentImage,
                            originalImage: undefined,
                        },
                        focalPoint,
                    );

                    setCroppedImage({
                        focalPoint: focus,
                        image: currentImage,
                        cropInfo,
                    });
                } else {
                    reset();

                    if (originalImage) {
                        onCrop(
                            {
                                ...originalImage,
                                originalImage: undefined,
                            },
                            focalPoint,
                        );
                    }
                }
            }
        },
        [onCrop, state],
    );

    return {
        ...state,
        reset,
        setCroppedImage,
        onCroppedImage,
        setImage,
    };
};
