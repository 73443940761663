import React, { FormEvent, ReactNode } from 'react';

import { Button, ButtonProps, Emphasis, Toolbar } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface FiltersFormProps {
    /** callback to be executed once the clear button is clicked. */
    onClearAll?: () => void;
    /** callback on clear all filters */
    onClearAllFilters?: () => void;
    /** Callback triggered when filters are selected */
    onFiltering: (value?: string) => void;
    /** Props to forward to the dropdown */
    filterProps?: Partial<ButtonProps>;
    /**
     * Components to render for each filter.
     * Should be generated via the `useFilters` hook.
     */
    filtersToRender: ReactNode[];
}
const FORM_ID = 'filters-form';

export const FiltersForm: React.FC<FiltersFormProps> = ({
    onClearAll,
    filtersToRender,
    filterProps,
    onClearAllFilters,
    onFiltering,
}) => {
    const { translateKey } = useTranslate();
    const onCancelFilters = () => {
        if (onClearAll) {
            onClearAll();
            if (onClearAllFilters) {
                onClearAllFilters();
            }
        }
    };

    const handleSubmit = (event?: FormEvent) => {
        event?.preventDefault();

        return onFiltering();
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(); // submit form when "enter" key is pressed
        }
    };
    return (
        <form id={FORM_ID} onSubmit={handleSubmit} noValidate>
            {filtersToRender}
            <Toolbar
                before={
                    onClearAll && (
                        <Button emphasis={Emphasis.low} onClick={onCancelFilters}>
                            {translateKey(GLOBAL.CANCEL)}
                        </Button>
                    )
                }
                after={
                    <Button type="submit" onKeyDown={handleKeyDown} {...filterProps}>
                        {translateKey(GLOBAL.FILTER)}
                    </Button>
                }
            />
        </form>
    );
};
