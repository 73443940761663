import { Route } from '@lumapps/router';
import { URL_PREFIX, AppId } from '@lumapps/router/constants';
import { USER_SUBSCRIPTIONS_FILTERS } from '@lumapps/subscriptions/types';

const subscriptionsRoute: Route = {
    path: `/${URL_PREFIX}/subscriptions`,
    legacyId: 'app.front.subscriptions',
    appId: AppId.frontOffice,
};

interface SubscriptionRouteParams {
    filter?: USER_SUBSCRIPTIONS_FILTERS;
}

const subscriptions = (params?: SubscriptionRouteParams): Route => {
    const queryParams: Route['query'] = {};

    if (params?.filter) {
        queryParams.filter = params.filter;
    }

    return {
        ...subscriptionsRoute,
        params: {},
        query: queryParams,
    };
};

export { subscriptions, subscriptionsRoute };
