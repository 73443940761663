/**
 * The MomentJS format to display a full date at midnight.
 *
 * @type {string}
 */
const FULL_DATE_MIDNIGHT = 'YYYY-MM-DD[T]00:00:00.000000';

/**
 * The MomentJS format to display a full date with 6 fractional seconds numbers.
 *
 * @type {string}
 */
const FULL_DATE_SIX_FRACTIONAL_SECONDS = 'YYYY-MM-DD[T]HH:mm:ss.SSSSSS';

/**
 * The MomentJS format to display a time with timezone.
 * Ex: 21:00:00.000+02:00
 * @type {string}
 */
const TIME_WITH_TIMEZONE = 'HH:mm:ssZ';

const DEFAULT_DATE_LOCALE = 'en-US';

const HALF_AN_HOUR = 30;
const HOURS_IN_A_DAY = 24;

export {
    FULL_DATE_MIDNIGHT,
    FULL_DATE_SIX_FRACTIONAL_SECONDS,
    HALF_AN_HOUR,
    HOURS_IN_A_DAY,
    TIME_WITH_TIMEZONE,
    DEFAULT_DATE_LOCALE,
};
