export enum HEADER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40827683
     */
    POST_TYPE_DEFAULT = 'FRONT.COMMUNITY.POST.TYPE.DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=52182651
     */
    OPEN_NAVIGATION = 'FRONT.OPEN_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54691671
     */
    NEW_CONTENT = 'FRONT.CONTRIBUTION_MENU.NEW_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=56996162
     */
    CREATE_BUTTON = 'FRONT.CONTRIBUTION_MENU.CREATE_BUTTON',
}
