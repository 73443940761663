import React from 'react';

import { useSelector } from '@lumapps/redux/react';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { getFrontOfficeSelector } from '../../ducks/selectors';

const NannyDetailsModal = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'nanny-details-modal' */
            './ConnectedDetailModal'
        ),
);

export const NannyDetails = () => {
    const { isAlertDisplayed } = useSelector(getFrontOfficeSelector);

    return isAlertDisplayed ? (
        <Suspense isDialog>
            <NannyDetailsModal />
        </Suspense>
    ) : null;
};
