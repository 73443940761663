(function IIFE() {
    'use strict';

    /////////////////////////////

    function TranslateFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/translate/:methodKey', {}, {
            googleTranslate: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'get',
                },
                cancellable: true,
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('TranslateFactory', TranslateFactory);
})();
