import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { DialogProps, AlertDialog, AlertDialogProps, Kind } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface ConfirmDialogProps extends DialogProps {
    /** Id used for data attr */
    id: string;
    /** Modal title. Also used on the confirm button */
    title: string;
    /** Modal body */
    confirmMessage: React.ReactNode;
    /** Callback when confirm button is pressed */
    onConfirm: () => void;
    /** Callback when cancel button is pressed */
    onClose: () => void;
    /** additional props for cancel button */
    cancelProps?: Partial<AlertDialogProps['cancelProps']>;
    /** additional props for confirm button */
    confirmProps?: Partial<AlertDialogProps['confirmProps']>;
    /** optional kind, defaults to warning */
    kind?: Kind;
}

/**
 * Dialog to warn the user about something.
 * Provides 2 actions "Cancel" and "Confirm"
 *
 * @family Dialogs
 * @param ConfirmDialogProps DialogProps
 * @returns Dialog component
 */
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    id,
    title,
    confirmMessage,
    onConfirm,
    onClose,
    cancelProps = {},
    confirmProps = {},
    kind = Kind.warning,
    ...props
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('confirm-dialog');

    return (
        <AlertDialog
            id={id}
            kind={kind}
            title={title}
            confirmProps={{
                ...get({ element: id, action: 'confirm' }),
                onClick: onConfirm,
                label: translateKey(GLOBAL.CONFIRM),
                ...confirmProps,
            }}
            cancelProps={{
                ...get({ element: id, action: 'cancel' }),
                onClick: onClose,
                label: translateKey(GLOBAL.CANCEL),
                ...cancelProps,
            }}
            {...get({ element: id })}
            {...props}
        >
            {confirmMessage}
        </AlertDialog>
    );
};
