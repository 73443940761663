import React from 'react';

import { Button, FlexBox, Message, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PREVIEW } from '../../keys';

export interface PreviewErrorProps {
    error?: number;
    onRetry?: () => void;
    className?: string;
}

export const PreviewError: React.FC<PreviewErrorProps> = ({ error, onRetry, className }) => {
    const { translateKey } = useTranslate();

    return (
        <Message hasBackground kind="info" className={className}>
            <FlexBox orientation="horizontal">
                <Text as="p">
                    {error === 404
                        ? translateKey(PREVIEW.INSERT_LINK_DIALOG_PREVIEW_BLOCK_ERROR_NOT_FOUND_URL)
                        : translateKey(PREVIEW.INSERT_LINK_DIALOG_PREVIEW_BLOCK_ERROR_DEFAULT_ERROR)}
                </Text>
                {error !== 404 && onRetry ? (
                    <Button
                        color="dark"
                        emphasis="medium"
                        // eslint-disable-next-line lumapps/no-classname-strings
                        className="lumx-flex-box--margin-auto-left"
                        onClick={onRetry}
                    >
                        {translateKey(GLOBAL.TRY_AGAIN)}
                    </Button>
                ) : null}
            </FlexBox>
        </Message>
    );
};
