import find from 'lodash/find';
import get from 'lodash/get';

import { createSelector } from '@lumapps/redux/reselect';
import { BackOfficeStore } from '@lumapps/redux/types';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';

import { ShareableContent, ShareableContentType } from '../types';
import { SAShareableContentsState } from './types';

export const SaShareableContentsSelector = (state: BackOfficeStore): SAShareableContentsState =>
    state.saShareableContent;

/**
 * Get the social network data from a shareable content
 * @param shareableContent The shareableContent to get the social networks from
 * @param socialNetwork The social network to get
 * @param resolved Whether we should take value resolved by backend or not
 */
export const shareableContentSocialNetworkSelector = (
    shareableContent: ShareableContent,
    socialNetwork: SocialNetworks,
    resolved?: boolean,
) => {
    const socialNetworks = get(shareableContent, resolved ? 'resolvedSocialNetworks' : 'socialNetworks') || [];

    return find(socialNetworks, { socialNetworkId: socialNetwork });
};

/**
 * Get the set comment of the given social network.
 *
 * @param shareableContent The shareableContent to get the comment from
 */
export const shareableContentCommentSelector = (shareableContent: ShareableContent) =>
    shareableContent?.suggestedComment?.value;

/**
 * Get the set comment of the given social network.
 *
 * @param shareableContent The shareableContent to get the social networks from
 * @param socialNetwork The social network to get
 */
export const shareableContentSocialNetworkCommentSelector = createSelector(
    [shareableContentSocialNetworkSelector],
    (socialNetwork) => socialNetwork?.suggestedComment?.value,
);

export const shareableContentTypeSelector = (shareableContent: ShareableContent): ShareableContent['type'] =>
    get(shareableContent, 'type');

export const shareableContentTitleSelector = (shareableContent: ShareableContent): string =>
    get(shareableContent, 'title.value');

export const shareableContentDescriptionSelector = (shareableContent: ShareableContent): string =>
    get(shareableContent, 'link.description.value');

export const shareableContentUrlSelector = (shareableContent: ShareableContent): string =>
    get(shareableContent, 'link.url.value');

export const shareableContentMediaListSelector = (shareableContent: ShareableContent): ShareableContent['mediaList'] =>
    get(shareableContent, 'mediaList', []);

export const shareableContentImageUrlSelector = (shareableContent: ShareableContent): ShareableContent['imageUrl'] =>
    get(shareableContent, 'imageUrl');

export const shareableContentImagesSelector = createSelector(
    [shareableContentTypeSelector, shareableContentMediaListSelector, shareableContentImageUrlSelector],
    (type, mediaList, imageUrl) =>
        type === ShareableContentType.link && imageUrl?.value ? [imageUrl.value] : mediaList.map((media) => media.url),
);
