import React, { useMemo } from 'react';

import { cache, CACHE_TYPE } from '@lumapps/cache';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { SideNavigationSkeletonItems } from '@lumapps/lumx-back-office/components/SideNavigation/SideNavigationSkeletonItems';
import { useSelector } from '@lumapps/redux/react';

import { getCustomContentTypesCacheKey } from '../../ducks/selectors';

/**
 * Displays the loading skeletons for rendering custom content types. If we already have in cache how many
 * CCTs there are for the given instance, we will display the exact amount of skeletons to show.
 * @returns Loading skeleton for custom content types
 */
export const LoadingCustomContentTypes: React.FC = () => {
    const cacheKey = useSelector(getCustomContentTypesCacheKey);
    const instanceId = useSelector(instanceIdSelector);

    /**
     * We retrieve the cache key from local storage, which will give us a key/value where the key is the instance
     * and the value is the amount of CCTs that there are. We have a default value in case this is the first time the
     * component is displayed for the current user, which defaults to 3. We do not allow changing the default value
     * nor we do not want the total amount of skeletons to be overriden in order to provide the same experience
     * everywhere this component is displayed. That is way the default value is hardcoded, and the `count` prop
     * is added after the {...props} when using the DropdownSkeletonItemsGroup component.
     */
    const skeletonsToDisplay = useMemo(() => {
        const cachedValues = cache.retrieve(cacheKey, CACHE_TYPE.STORAGE);
        if (cachedValues && cachedValues[instanceId]) {
            return cachedValues;
        }

        return { [instanceId]: 3 };
    }, [cacheKey, instanceId]);

    return <SideNavigationSkeletonItems count={skeletonsToDisplay[instanceId]} />;
};
