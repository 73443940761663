import { func, string } from 'prop-types';
import React, { useState } from 'react';

import * as notify from 'components/services/notification';
import { translate as t } from 'components/translations';
import { InputTextFieldDialog } from 'components/components/ui/dialogs/input-text-field';

/**
 * Dialog used to create file or folder by name.
 *
 * @param  {Object}       props The dialog props.
 * @return {ReactElement} The dialog.
 */
const CreateFileFolderDialog = (props) => {
    const { title, action, onValidate } = props;

    const [openDialog, setOpenDialog] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleValidate = async (value) => {
        setLoading(true);

        try {
            await action(value.name);
            onValidate(true);
        } catch (exception) {
            notify.error(t('GLOBAL.ERROR.GENERIC'));
            onValidate(false);
        }
        setOpenDialog(false);
        setLoading(false);
    };

    return (
        <InputTextFieldDialog
            fullWidth
            label="GLOBAL.NAME"
            loading={loading}
            min={1}
            name="name"
            open={openDialog}
            title={title}
            value=""
            onClose={() => onValidate(false)}
            onValidate={handleValidate}
        />
    );
};

/////////////////////////////

CreateFileFolderDialog.propTypes = {
    /** Async action called when the dialog is validated. */
    action: func.isRequired,
    /** Dialog title. */
    onValidate: func.isRequired,
    /** Called after the dialog was validated and the action completed. */
    title: string.isRequired,
};

CreateFileFolderDialog.defaultProps = {};

/////////////////////////////

export { CreateFileFolderDialog };
