import React from 'react';

import { ColorPalette, ColorVariant, InlineList, Theme, Typography } from '@lumapps/lumx/react';

import { Author } from '../Author';
import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';
import { Date } from '../Date';
import { Status } from '../Status';
import { VideosCount } from '../VideosCount';

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-metadata` as const;

/**
 * Displays the metadata of a playlist
 */
export const Metadata = ({ children }: { children: React.ReactNode }) => {
    const { canDisplay, isLoading, playlist, theme } = useBlockPlaylistContext();

    // if (!canDisplay('AUTHOR', 'STATUS', 'UPDATED_AT', 'VIDEO_COUNT')) {
    //     return null;
    // }

    const metadatas = React.useMemo(() => {
        if (!children) {
            return null;
        }

        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return null;
            }

            /**
             * ⚠️ The <InlineList /> component maps its children to know whether it should display the dot separators.
             * It is important to leave the check here
             */
            switch (child.type) {
                case Author: {
                    return (isLoading || playlist.createdByDetails) && canDisplay('AUTHOR') && child;
                }

                case Date: {
                    return (isLoading || playlist.updatedAt) && canDisplay('UPDATED_AT') && child;
                }

                case Status: {
                    return (isLoading || playlist.status) && canDisplay('STATUS') && child;
                }

                case VideosCount: {
                    return (
                        (isLoading || typeof playlist.videosCount === 'number') && canDisplay('VIDEO_COUNT') && child
                    );
                }

                default: {
                    return child;
                }
            }
        })?.filter(Boolean);
    }, [
        canDisplay,
        children,
        isLoading,
        playlist?.createdByDetails,
        playlist?.status,
        playlist?.updatedAt,
        playlist?.videosCount,
    ]);

    if (!metadatas?.length) {
        return null;
    }

    return (
        <InlineList
            color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
            colorVariant={theme === Theme.light ? ColorVariant.L2 : ColorVariant.L2}
            typography={Typography.body1}
        >
            {metadatas}
        </InlineList>
    );
};
