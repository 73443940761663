import React from 'react';

import { ColorVariant } from '@lumapps/lumx/react';
import { Route } from '@lumapps/router';

export enum MenuOptionType {
    option = 'option',
    divider = 'divider',
}

export type MenuOptionDivider = {
    /** type of the option. */
    type: MenuOptionType;
};

export type MenuOptionBasic = {
    /** type of the option. */
    type: MenuOptionType;
    /** Dynamic icon of the item. It must follow the legacy syntax (mdiIceCream => 'ice-cream') */
    icon?: string;
    /** custom color variant for the displayed icon */
    iconColorVariant?: ColorVariant;
    /** Static icon of the item (mdi SVG path, example: mdiIceCream). */
    iconStatic?: string;
    /** The translation key of the text to show */
    labelKey: string;
    /** Action callback when item is selected */
    onSelect?(evt?: React.MouseEvent): void;
    /** The route to redirect to the content if this item is a link to a content */
    route?: Route;
    /** Any other DropdownItem props you want to pass */
    otherProps?: any;
    /** Whether the option is disabled or not. */
    isDisabled?: boolean;
    /** Whether the option is selected or not. */
    isSelected?: boolean;
    /** Tooltip label key. */
    tooltipLabelKey?: string;
    /** Ref to pass to button */
    ref?: React.RefObject<HTMLElement>;
    /** Option children to have another level in the menu */
    children?: Array<MenuOptionDivider | MenuOptionBasic>;
};

export type MenuOption = MenuOptionDivider | MenuOptionBasic;
