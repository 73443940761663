import { communityPostView, communityView } from '@lumapps/communities/routes';
import { ContentTypes } from '@lumapps/content-types/types';
import { directoryView } from '@lumapps/directories-page/routes';
import { OptionalAppRoute, Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';
import { isExternalUrl, createRelativeUrl } from '@lumapps/router/utils';
import { userDirectoryView } from '@lumapps/user-directory-front-office/routes';

import { ContentLinkRef } from '../types';

/* Paths: */
export const homePath = '/';
export const legacyContentPath = '/*';
export const contentViewIdPath = `/${URL_PREFIX}/content/id/:id`;
// `*` is the content slug, but it can contain multiple parts if nested in the main nav or sub nav
export const contentViewPath = `/${URL_PREFIX}/content/:id/*`;

// (won't be needed in react-router v6 since `/:id/*` will also match `/:id`, no need to the slash)
const contentViewBasePath = `/${URL_PREFIX}/content/:id`;

/* Routes: */
export const legacyContentViewRoute: Route<string[]> = {
    path: [
        /** Legacy content URL. */
        legacyContentPath,
        /** Home page URL. Keep it at the end of the array or else params from other paths won't be reachable. */
        homePath,
    ],
    legacyId: 'app.front.content-get',
    appId: AppId.legacy,
};

export const homePageViewRoute: OptionalAppRoute = {
    path: homePath,
    legacyId: 'app.front.content-get',
};

export const contentViewRoute: Route<string[]> = {
    path: [contentViewIdPath, contentViewPath, contentViewBasePath, homePath],
    appId: AppId.frontOffice,
};

/* Parameterized routes: */

/**
 * Home page URL.
 * With layout v2 enabled, we consider the home page to always be compatible v2.
 */
export const home = ({
    isV2Compatible,
    anchor,
    query,
    instanceSlug,
}: {
    isV2Compatible?: boolean;
    anchor?: Route['anchor'];
    query?: Route['query'];
    instanceSlug?: string;
}): Route => {
    if (isV2Compatible) {
        return {
            ...homePageViewRoute,
            anchor,
            query,
            instanceSlug,
            legacyId: undefined,
            appId: AppId.frontOffice,
        };
    }
    return {
        ...homePageViewRoute,
        anchor,
        query,
        instanceSlug,
        // Required for the legacy to know we actually changed page
        params: { slug: null } as any,
        appId: AppId.legacy,
    };
};

/**
 * Switches between the legacy & new app content view route depending on the layout FF and the content migration status.
 */
export function contentView(params: {
    to: ContentLinkRef;
    isLayoutEnabled: boolean;
    isReactUserDirectoryEnabled?: boolean;
}): Route {
    const {
        id,
        slug,
        type,
        isHomePage,
        anchor,
        keepParentPath = false,
        instance,
        isV2Compatible,
        query,
        link = isExternalUrl(slug) ? slug : undefined,
    } = params.to;
    const instanceSlug = instance?.slug;
    const correctedSlug = (keepParentPath ? slug : slug?.split('/').pop()) || '';

    // Home page.
    if (isHomePage) {
        return home({ isV2Compatible: params.isLayoutEnabled && isV2Compatible, instanceSlug, anchor, query });
    }

    // Content external link.
    if (link) {
        return {
            appId: AppId.external,
            anchor,
            query,
            path: link,
        };
    }

    /**
     * Redirect to the route associated with the content type.
     * */
    switch (type) {
        case ContentTypes.COMMUNITY:
            return communityView({ slug: slug as string, id, anchor, instanceSlug });
        case ContentTypes.POST:
            return communityPostView({ slug: slug as string, postId: id as string, anchor, instanceSlug });
        case ContentTypes.DIRECTORY:
            return directoryView(params);
        case ContentTypes.USER_DIRECTORY:
            if (params.isReactUserDirectoryEnabled && id) {
                return userDirectoryView({ contentId: id, slug: slug as string, instanceSlug });
            }
            break;
        default:
            break;
    }

    // Add mobile webview page descriptor when possible.
    let mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined;
    if (id) {
        mobilePageDescriptor = {
            id,
            type: 'content',
            section: anchor?.match(/#?comments/) ? 'comments' : undefined,
        };
    }

    // Layout v2 content by id (and optionally slug).
    if (id && params.isLayoutEnabled && isV2Compatible) {
        return {
            ...contentViewRoute,
            // We calculate the path here and not in the router to handle the wildcard slug path (`0` => `*` in the regexp)
            path: createRelativeUrl({ path: contentViewPath, params: { id, '0': correctedSlug } }),
            anchor,
            query,
            instanceSlug,
            mobilePageDescriptor,
        };
    }

    // Legacy layout v1 route.
    return {
        ...legacyContentViewRoute,
        path: `/${correctedSlug}`,
        // Slug param is needed for the angular router
        params: { slug: correctedSlug },
        anchor,
        query,
        instanceSlug,
        mobilePageDescriptor,
    };
}
