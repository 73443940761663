import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Group } from '@lumapps/groups/types';
import { AlertDialog, Kind, Size, InlineList, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { CONTENTS } from '../../keys';

import './index.scss';

type FeedFields = Pick<Group, 'id' | 'name'>;

export interface ContentNotificationAlertDialogProps {
    isOpen: boolean;
    selectedFeeds: Array<FeedFields>;
    allFeedId?: string;
    onConfirm(): void;
    onCancel(): void;
}

const CLASSNAME = 'content-notification-alert-dialog';

export const ContentNotificationAlertDialog: React.FC<ContentNotificationAlertDialogProps> = ({
    isOpen,
    selectedFeeds = [],
    allFeedId,
    onConfirm,
    onCancel,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey, translateAndReplace, pluralize } = useTranslate();
    const hasAllFeed = allFeedId && selectedFeeds.findIndex((feed: any) => feed.id === allFeedId) > -1;
    // If All feed is selected,set as first to make it very visible
    const sortedFeeds =
        hasAllFeed && selectedFeeds.length > 1
            ? selectedFeeds
                  // set All feed first to make it very visible
                  .sort((feed) => (feed.id === allFeedId ? -1 : 1))
            : selectedFeeds;

    return (
        <AlertDialog
            isOpen={isOpen}
            // If 'All' group is selected, show the dialog as warning
            kind={hasAllFeed ? Kind.warning : undefined}
            title={translateKey(CONTENTS.CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_TITLE)}
            confirmProps={{
                label: translateKey(CONTENTS.CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_PUBLISH),
                onClick: onConfirm,
            }}
            cancelProps={{
                label: translateKey(CONTENTS.CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_CHANGE_SETTINGS),
                onClick: onCancel,
            }}
            size={Size.regular}
        >
            {
                /*
                 * If only the 'All' group is selected show a specific message.
                 * Otherwise show the group as a DataList
                 */
                selectedFeeds.length === 1 && hasAllFeed ? (
                    <Text
                        as="span"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: translateAndReplace(
                                CONTENTS.CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_SINGLE_FEED,
                                {
                                    FEED: translateKey(CONTENTS.CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_ALL_FEED),
                                },
                            ),
                        }}
                    />
                ) : (
                    <Text as="span" typography="body1">
                        {pluralize(
                            CONTENTS.CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_FEED_LIST_MESSAGE,
                            selectedFeeds.length,
                        )}

                        <br />

                        <InlineList className={element('feed-list')}>
                            {sortedFeeds.map((feed: FeedFields) => {
                                return <b key={feed.id}>{feed.name}</b>;
                            })}
                        </InlineList>
                    </Text>
                )
            }
        </AlertDialog>
    );
};
