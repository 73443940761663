/* istanbul ignore file */
import { BaseApiError } from '@lumapps/base-api';
import { error as notifyError } from '@lumapps/notifications';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import * as api from '../api';
import { CHAT } from '../keys';
import { UserTokenStatusQuery } from '../types';
import {
    chatProvidersStatusSelector,
    chatIsUserTokenStatusSelector,
    chatIsDisconnectingSelector,
    chatIsFetchAuthorizeLinkSelector,
} from './selectors';
import { actions } from './slice';

const fetchChatProviders = () => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
    const chatProvidersStatus = chatProvidersStatusSelector(getState());

    // Avoid re-fetching if a fetch is already in progress
    if (chatProvidersStatus !== 'loading') {
        dispatch(actions.fetchProvidersLoading());
        try {
            const response = await api.fetchProviders();

            const { data } = response;

            dispatch(actions.fetchProvidersSuccess(data));
        } catch (error) {
            if (error instanceof BaseApiError) {
                dispatch(actions.fetchProvidersError((error.response as any).message));
            }
        }
    }
};

/**
 * Fetch the list of available user spaces for a provider.
 * @param  {string}  provider     Provider (teams, yammer, slack...).
 * @param  {number}  [maxResults] Page max results.
 * @param  {string}  [cursor]     Page cursor.
 * @param  {number}  [page]       Page number (used for manual pagination)
 * @return {Promise} Returns the list of user spaces depending on the provider.
 */
const fetchUserSpaces = (options: any) => async (dispatch: Dispatch) => {
    dispatch(actions.fetchUserSpacesLoading());

    try {
        const response = await api.listUserSpaces(options);

        const { data } = response;

        dispatch(actions.fetchUserSpacesSuccess(data.items));
    } catch (exception) {
        dispatch(actions.fetchUserSpacesError());
    }
};

const fetchUserTokenStatus =
    (params: UserTokenStatusQuery) => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const isFetching = chatIsUserTokenStatusSelector(getState());

        if (!isFetching) {
            dispatch(actions.setIsUserTokenStatus(true));
            dispatch(actions.setUserTokenLoadingStatus(BaseLoadingStatus.loading));

            try {
                const { data } = await api.fetchUserTokenStatus(params);
                const { spaceId, provider } = params;
                const formatedData = {
                    [provider]: {
                        [spaceId]: { ...data.status },
                    },
                };

                dispatch(actions.setUserTokenStatus(formatedData));
                dispatch(actions.setUserTokenLoadingStatus(BaseLoadingStatus.idle));
            } catch (error) {
                dispatch(notifyError({ translate: CHAT.GET_USER_TOKEN_STATUS_ERROR }));
                dispatch(actions.setUserTokenLoadingStatus(BaseLoadingStatus.error));
            } finally {
                dispatch(actions.setIsUserTokenStatus(false));
            }
        }
    };

const disconnectUserToken =
    (params: UserTokenStatusQuery, callback?: () => void) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const chatIsDisconnecting = chatIsDisconnectingSelector(getState());

        if (!chatIsDisconnecting) {
            dispatch(actions.setIsDisconnecting(true));
            try {
                await api.disconnectUserToken(params);

                if (callback) {
                    callback();
                }
            } catch (error) {
                dispatch(notifyError({ translate: CHAT.DISCONNECT_USER_TOKEN_ERROR }));
            } finally {
                dispatch(actions.setIsDisconnecting(false));
            }
        }
    };

const fetchAuthorizeLink =
    (params: UserTokenStatusQuery) => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const isFetchAuthorizeLink = chatIsFetchAuthorizeLinkSelector(getState());

        if (!isFetchAuthorizeLink) {
            dispatch(actions.setIsFetchAuthorizeLink(true));
            try {
                const { data } = await api.fetchUserTokenAuthorize(params);
                return data.link;
            } catch (error) {
                dispatch(notifyError({ translate: CHAT.GET_AUTHORIZE_LINK_ERROR }));
                return null;
            } finally {
                dispatch(actions.setIsFetchAuthorizeLink(false));
            }
        }

        return null;
    };

export { fetchChatProviders, fetchUserTokenStatus, disconnectUserToken, fetchAuthorizeLink, fetchUserSpaces };
