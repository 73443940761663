import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

export interface UserSpaceProps {
    id: string;
    name: string;
    description?: string;
    provider: string;
}

export type UserSpaces = UserSpaceProps[];

export interface UserSpacePickerProps {
    userSpaces: any;
    filterValue: any;
    setFilterValue: any;
    showSuggestions: any;
    setShowSuggestions: any;
    setSelectedUserSpace: any;
    label: string;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
}

export enum ChatProviderId {
    fbworkplace = 'fbworkplace',
    google_chat = 'google_chat',
    slack = 'slack',
    teams = 'teams',
    yammer = 'yammer',
}

/** Chat channel interface */
export interface ChatChannel {
    channelUrl: string;
    description?: string;
    id: string;
    name: string;
    provider: ChatProviderId;
    spaceId: string;
    visibility: string;
}

/** Channel props used by the channel picker */
export interface ChannelProps {
    id: string;
    name: string;
}

/** Channel picker props */
export interface ChannelPickerProps {
    channels: any;
    filterValue: any;
    setFilterValue: any;
    showSuggestions: any;
    setShowSuggestions: any;
    navigationSuggestionValue: any;
    setNavigationSuggestionValue: any;
    selectedValues: any;
    setSelectedValues: any;
    isDisabled?: boolean;
    isLoading?: boolean;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
}

export interface UserTokenStatusQuery {
    provider: string;
    spaceId: string;
}
export interface UserTokenStatusAPI {
    status: {
        is_connected: boolean;
    };
}

export type UserTokenStatusProvider = Record<string, UserTokenStatusAPI['status']> | undefined;

export type UserTokenStatusProps = Record<string, UserTokenStatusProvider> | null;

export interface ChatsProps {
    providers: any;
    chatProvidersStatus: null | 'loading' | 'loaded';
    userSpaces: UserSpaces;
    userSpacesStatus: BaseLoadingStatus;
    userTokenLoadingStatus: BaseLoadingStatus;
    userTokenStatus: UserTokenStatusProps;
    isUserTokenStatus: boolean;
    isDisconnecting: boolean;
    isFetchAuthorizeLink: boolean;
}

export interface ChatProvider {
    name: ChatProviderId;
    isConfigurable?: boolean;
}

export interface ChatProviderValue {
    id: ChatProviderId;
    name: string;
}

export type ChatProviderValues = Record<string, ChatProviderValue>;
