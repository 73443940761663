/* istanbul ignore file */
/** tested with integration tests */
import { useMemo } from 'react';

import { cache, CACHE_TYPE } from '@lumapps/cache';
import { ENVS, get } from '@lumapps/constants';
import { useQueryParams } from '@lumapps/router';
import { addQueryParamsToUrl, removeParamFromUrl } from '@lumapps/router/utils';

import { ENVIRONMENT_MODES, MODES, DISABLE_MODE_CHAR, CACHE_KEY, CACHE_TYPE_MODES } from './constants';
import { useCustomColors } from './custom-colors';
import { useHeadless } from './headless';
import { useInstanceHead } from './instance-head';
import { useInstanceStylesheets } from './instance-stylesheets';
import { useNoSideNavBo } from './no-side-nav-bo';
import { useNoTopBar } from './no-top-bar';

const Config = get();

const { environment } = Config;

let cachedEnabledModes: Record<string, boolean> = {};

/**
 * From the record of modes and whether they are available or not, it computes them
 * and applies them to the page. Modes are saved on local storage and the page is then refreshed
 * in order to show the page with those modes.
 * @param modes Record of modes to apply
 * @param reload whether the page should be reloaded or not
 */
export const applyModes = (modes: Record<string, boolean>, reload = false) => {
    let storageModesParameter = '';
    let memoryModesParameter = '';

    Object.keys(modes).forEach((mode) => {
        const isModeEnabled = modes[mode];
        const modeQueryParam = isModeEnabled ? mode : `${DISABLE_MODE_CHAR}${mode}`;

        if (CACHE_TYPE_MODES.STORAGE.includes(mode)) {
            storageModesParameter =
                storageModesParameter.length > 0 ? `${storageModesParameter},${modeQueryParam}` : modeQueryParam;
        } else if (CACHE_TYPE_MODES.MEMORY.includes(mode)) {
            memoryModesParameter =
                memoryModesParameter.length > 0 ? `${memoryModesParameter},${modeQueryParam}` : modeQueryParam;
        }
    });

    cache.store(CACHE_KEY, storageModesParameter, CACHE_TYPE.STORAGE);
    cache.store(CACHE_KEY, memoryModesParameter, CACHE_TYPE.MEMORY);

    if (reload) {
        /**
         * Custom JS can only be removed from the backend, so when that specific mode is enabled, we add the safe parameter
         * to the URL which will make the backend not send custom JS to the frontend.
         */
        if (modes[MODES.WITH_CUSTOM_CODE] === false) {
            if (!window.location.href.includes('?safe')) {
                const newUrl = addQueryParamsToUrl(
                    window.location.href,
                    { modes: memoryModesParameter, safe: 'true' },
                    false,
                );
                window.location.href = newUrl;
                return;
            }
        }

        const newUrl = removeParamFromUrl('safe', window.location.href || '');
        window.location.href = addQueryParamsToUrl(newUrl, { modes: memoryModesParameter });
    }
};

/**
 * This method determines the current enabled modes, taking into consideration
 * a list feature flags. This method takes into consideration the modes
 * configured for the current environment (production, development) and computes
 * the modes that are enabled.
 * @param features enabled feature flags
 */
export const getEnabledModes = (features?: Record<string, boolean>, env?: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const modesForEnvironment = ENVIRONMENT_MODES[env || environment || ENVS.DEVELOPMENT];
    const availableModes = Object.keys(MODES);
    const modesToEnable: Record<string, boolean> = {};
    const storageModes = cache.retrieve(CACHE_KEY, CACHE_TYPE.STORAGE);
    const memoryModes = cache.retrieve(CACHE_KEY, CACHE_TYPE.MEMORY);
    let tokens = storageModes ? storageModes.split(',') : [];
    if (memoryModes) {
        tokens = [...tokens, ...memoryModes.split(',')];
    }

    const allOverridenModes: string[] = [];

    if (tokens.length > 0) {
        allOverridenModes.push(...tokens);
    }

    availableModes.forEach((modeKey) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const mode = MODES[modeKey];
        const isModeOverriden = allOverridenModes.filter((m) => m.replace(DISABLE_MODE_CHAR, '') === mode);

        if (isModeOverriden && isModeOverriden.length > 0) {
            modesToEnable[mode] = isModeOverriden[isModeOverriden.length - 1].indexOf(DISABLE_MODE_CHAR) < 0;
        } else {
            const environmentModePosition = modesForEnvironment.indexOf(mode);
            modesToEnable[mode] = environmentModePosition >= 0;
        }

        if (features && features[mode] !== undefined) {
            modesToEnable[mode] = features[mode];
        }
    });

    applyModes(modesToEnable);

    cachedEnabledModes = modesToEnable;

    return modesToEnable;
};

export const useModes = () => {
    const queryParams = useQueryParams();
    const featureFlags = useMemo(() => {
        const featureFlags: Record<string, boolean> = {};

        /**
         * Override any feature flags by using the `modes` query param
         */
        if (queryParams && queryParams.modes) {
            const tokens = queryParams.modes.split(',');

            tokens.forEach((token) => {
                featureFlags[token.replace(DISABLE_MODE_CHAR, '')] = token.indexOf(DISABLE_MODE_CHAR) < 0;
            });
        }

        /**
         * Override any feature flags by using the `safe` query param
         */
        if (queryParams && queryParams.safe) {
            featureFlags[MODES.WITH_CUSTOM_CODE] = false;
        } else if (environment === ENVS.PRODUCTION) {
            featureFlags[MODES.WITH_CUSTOM_CODE] = true;
        }
        return featureFlags;
    }, [queryParams]);

    /**
     * In order to determine the modes that are enabled, we need to take into consideration
     * the modes that could be passed in by query param, and the ones that are on local storage.
     *
     * We retrieve both modes and create a common array with all of them.
     */
    const currentModes = useMemo(() => getEnabledModes(featureFlags), [featureFlags]);

    const isModeEnabled = (mode: string) => {
        return currentModes[mode];
    };

    /**
     * Since these modes can impact the look and feel of our page, we call specific custom hooks
     * for each mode, and let those modes make the necessary adjustments to the page.
     */
    useCustomColors({ isEnabled: isModeEnabled(MODES.WITH_CUSTOM_CODE), env: environment });
    useInstanceHead({ isEnabled: isModeEnabled(MODES.WITH_CUSTOM_CODE), env: environment });
    useInstanceHead({ isEnabled: isModeEnabled(MODES.WITH_CUSTOM_CODE), env: environment, prefix: 'theme-head' });
    useInstanceStylesheets({ isEnabled: isModeEnabled(MODES.WITH_CUSTOM_CODE), env: environment });
    useHeadless({ isEnabled: isModeEnabled(MODES.HEADLESS) });
    useNoTopBar({ isEnabled: isModeEnabled(MODES.NO_TOP_BAR) });
    useNoSideNavBo({ isEnabled: isModeEnabled(MODES.NO_SIDE_NAV_BO) });

    return {
        modes: currentModes,
        isModeEnabled,
        featureFlags,
    };
};

/**
 * Return the latest processed enabled modes. Useful when we do not want to query the local storage
 * to retrieve a mode.
 * @returns enabled modes
 */
export const getCachedEnabledModes = (): Record<string, boolean> => {
    return cachedEnabledModes;
};

/**
 * Resets the current overrides for LumApps modes and refreshes the page.
 */
export const resetModes = () => {
    cache.store(CACHE_KEY, '', CACHE_TYPE.STORAGE);
    cache.store(CACHE_KEY, '', CACHE_TYPE.MEMORY);

    window.location.href = removeParamFromUrl('modes', window.location.href || '');
};

export * from './constants';
