import * as React from 'react';
import * as propTypes from 'prop-types';

import map from 'lodash/map';
import join from 'lodash/join';
import split from 'lodash/split';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { couplesAreEqual } from '../../utils';
import { BACKGROUND_POSITIONS } from '../../constants';
import { BackgroundImagePositionChoice } from './BackgroundImagePositionChoice';

/**
 * Renders a form that enables editing the background image position.
 */
export class BackgroundImagePosition extends React.PureComponent {
    static propTypes = {
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func,
        /** Current background position. */
        value: styleTypes.backgroundPosition,
    };

    constructor(props) {
        super(props);
        this.onClick = (occurrence) => {
            const position = join(BACKGROUND_POSITIONS[occurrence.currentTarget.id], ' ');
            this.props.onChange(position === this.props.value ? undefined : position, this.props.name, occurrence);
        };
    }

    render() {
        const value = split(this.props.value, ' ');

        return (
            <div className="background-image-manager__position">
                {map(BACKGROUND_POSITIONS, (position, index) => (
                    <BackgroundImagePositionChoice
                        key={index}
                        id={index}
                        selected={couplesAreEqual(position, value)}
                        onClick={this.onClick}
                        value={position}
                    />
                ))}
            </div>
        );
    }
}
