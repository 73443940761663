import React, { Ref } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { BookmarkType } from '../../../types';

import './index.scss';

export interface BookmarkProps {
    /** className to be added to the wrapping div */
    className?: string;
    /** Bookmark to display */
    item: BookmarkType;
    /** reference to the bookmark link */
    linkRef?: Ref<HTMLAnchorElement>;
}

const CLASSNAME = 'bookmark';
const SCOPE = 'bookmark';

/**
 * This component display a links with an icon.
 */
const Bookmark: React.FC<BookmarkProps> = ({ className = '', item, linkRef }) => {
    const { element, block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const title = translateKey(item.title);

    return (
        <li className={block([className])} {...get({ element: 'item' })}>
            <a className={element('link')} href={item.link} target="_blank" rel="noopener noreferrer" ref={linkRef}>
                <div className={element('icon')} style={{ backgroundImage: `url(${item.icon})` }} />

                <Text as="span" typography="caption" className={element('title')}>
                    {title}
                </Text>
            </a>
        </li>
    );
};

export { Bookmark };
