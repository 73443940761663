import { Point, ReactEditor } from '@lumapps/wrex/slate';
import { insertVoidBlock } from '@lumapps/wrex/slate/utils/insertVoidBlock';

import { EmbeddedVideoEditor, EmbeddedVideoElement } from '../types';
import { createEmbeddedVideo } from './createEmbeddedVideo';

/**
 * Insert file after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertEmbeddedVideo(
    editor: ReactEditor & EmbeddedVideoEditor,
    link: Partial<EmbeddedVideoElement>,
    point?: Point | undefined,
) {
    const node = createEmbeddedVideo({
        title: link.title,
        description: link.description,
        thumbnailUrl: link.thumbnailUrl,
        url: link.url || '',
    });

    const path = insertVoidBlock(editor, node, point);

    return path;
}
