import includes from 'lodash/includes';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function DragAndDropController(LxDialogService, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The list of items to add.
     *
     * @type {Array}
     */
    let _itemsToAdd = [];

    /**
     * The new index of the last moved item.
     *
     * @type {number}
     */
    let _newIndex;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The id of the "Add item" dialog.
     *
     * @type {string}
     * @constant
     */
    vm.DRAG_AND_DROP_ADD_DIALOG_ID = 'drag-and-drop-add-dialog-';

    /**
     * All items (object) that can be added.
     *
     * @type {Array}
     */
    vm.availableAddableItems = [];

    /**
     * If we can add an item from the choice object.
     *
     * @type {boolean}
     */
    vm.canAddItem = false;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Trigger ng-change function.
     */
    function _executeNgChange() {
        if (angular.isFunction(vm.ngChange)) {
            vm.ngChange();
        }
    }

    /**
     * Refresh available items list. An available item is a item which is not already in the drag and drop
     * or an item which is not unique.
     */
    function _refreshAvailableItemsList() {
        if (!vm.canAddItem) {
            return;
        }

        vm.availableAddableItems = [];

        angular.forEach(vm.choice, (item, itemId) => {
            if (!item.unique || !includes(vm.ngModel, itemId)) {
                angular.extend(item, {
                    id: itemId,
                });

                vm.availableAddableItems.push(item);
            }
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add selected items to the settings.
     */
    function addSelectedItems() {
        Array.prototype.push.apply(vm.ngModel, _itemsToAdd);

        LxDialogService.close(vm.DRAG_AND_DROP_ADD_DIALOG_ID);

        _executeNgChange();
    }

    /**
     * Delete item.
     *
     * @param {number} itemIndex The index of the item to delete.
     */
    function deleteItem(itemIndex) {
        vm.ngModel.splice(itemIndex, 1);

        _executeNgChange();
    }

    /**
     * Check if the addable item is selected.
     *
     * @param  {string}  itemId The id of the addable item.
     * @return {boolean} If the addable item is selected.
     */
    function isAddableItemSelected(itemId) {
        return includes(_itemsToAdd, itemId);
    }

    /**
     * Called after an item has been moved.
     *
     * @param {number} oldIndex The original index of the moved item.
     */
    function moveItem(oldIndex) {
        if (oldIndex > _newIndex) {
            vm.ngModel.splice(oldIndex + 1, 1);
        } else {
            vm.ngModel.splice(oldIndex, 1);
        }

        _executeNgChange();
    }

    /**
     * Open the "Add item" dialog.
     */
    function openAddDialog() {
        Utils.waitForAndExecute(`#${vm.DRAG_AND_DROP_ADD_DIALOG_ID}`);

        // Reset the items to add list.
        _itemsToAdd = [];

        // Reset the addable items filter.
        vm.filter = '';

        _refreshAvailableItemsList();
    }

    /**
     * Toggle item in the items selection.
     *
     * @param {string} itemId The id of the item to toggle.
     */
    function toogleAddableItem(itemId) {
        const index = _itemsToAdd.indexOf(itemId);

        if (index === -1) {
            _itemsToAdd.push(itemId);
        } else {
            _itemsToAdd.splice(index, 1);
        }
    }

    /**
     * Update the index of an item if this item has been moved.
     *
     * @param  {number} newIndex The index of the moved item.
     * @param  {string} itemId   The id of the moved item.
     * @return {string} The id of the moved item. If we don't do it, the drag and drop is cancelled.
     */
    function updateIndex(newIndex, itemId) {
        _newIndex = newIndex;

        return itemId;
    }

    /////////////////////////////

    vm.addSelectedItems = addSelectedItems;
    vm.deleteItem = deleteItem;
    vm.isAddableItemSelected = isAddableItemSelected;
    vm.moveItem = moveItem;
    vm.openAddDialog = openAddDialog;
    vm.toogleAddableItem = toogleAddableItem;
    vm.updateIndex = updateIndex;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // We can add an item only if we have a choices filled object.
        vm.canAddItem = angular.isDefinedAndFilled(vm.choice);

        const dragAndDropUUID = `dragAndDrop-${generateUUID()}`;

        // We need a type to avoid conflict when we have multiple drag and drop on the same page.
        if (angular.isUndefinedOrEmpty(vm.type)) {
            vm.type = dragAndDropUUID;
        }

        if (vm.canAddItem) {
            // Set add dialog id if we need it.
            vm.DRAG_AND_DROP_ADD_DIALOG_ID += dragAndDropUUID;
        }
    }

    init();
}

/////////////////////////////

/**
 * The drag and drop.
 * Displays list of choices. Each choice can be moved, edited or deleted.
 *
 * @param {Object}   choice              All item we can have in our selection.
 * @param {boolean}  [disabled=false]    Indicates if we can edit the selection or not.
 * @param {string}   [label='']          The label of the drag and drop.
 * @param {Array}    ngModel             The Ng-model where to store our selection.
 * @param {Function} [ngChange]          The function to execute when the model changes.
 * @param {string}   [translationPrefix] The translation prefix to use for items name and filter.
 * @param {string}   [type]              The type of drag and drop.
 */

function DragAndDropDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: DragAndDropController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            choice: '<lsChoice',
            disabled: '<?lsDisabled',
            label: '@?lsLabel',
            ngChange: '&?',
            ngModel: '=',
            translationPrefix: '@?lsTranslationPrefix',
            type: '@?lsType',
        },
        templateUrl: '/client/common/modules/drag-and-drop/views/drag-and-drop.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsDragAndDrop', DragAndDropDirective);

/////////////////////////////

export { DragAndDropDirective };
