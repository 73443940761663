import isEmpty from 'lodash/isEmpty';

import { angularApi } from './angular-user';
import { connectedUserSelector, getToken } from './ducks/selectors';
import { UserAPI } from './interface';
import { User } from './types';

const service: UserAPI = {
    getConnected: (state?: any) => {
        if (state) {
            return connectedUserSelector(state);
        }

        return angularApi.getConnected() ?? null;
    },
    refreshToken: () => {
        return angularApi.refreshToken();
    },
    getUserToken: (state?: any) => {
        if (state) {
            const connectedUser = connectedUserSelector(state);

            if (connectedUser && !isEmpty(connectedUser)) {
                return getToken(state);
            }
        }

        return angularApi.getUserToken();
    },
    redirectToProfile: (user: User) => angularApi.redirectToProfile(user),
};

export { service };
