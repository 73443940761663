import { io, Socket } from 'socket.io-client';

import BaseAPI from '@lumapps/base-api';
import { get } from '@lumapps/constants';

import { DEFAULT_RECONNECT_TRY_COUNT } from '../constants';

const Config = get();
const wssCell = Config.haussmannCell?.replace('api.lumapps.com', 'wss.lumapps.com') || '';
const orgId = Config.customerId;

export type Transport = 'polling' | 'websocket';

export interface CreateSocketParams {
    apiInstance: BaseAPI;
    socketPath: string;
    maxReconnectTryCount?: number;
    transports: Transport[];

    fatalConnectionErrorCallback(err: Error): void;
}

let reconnectTryCount = 0;

const handleAuthError = async (socket: Socket) => {
    /** refresh the auth header */
    await BaseAPI.refreshToken();
    const freshAuthorizationHeader = BaseAPI.getBaseConfig()?.headers?.Authorization || '';

    // We authorize ourselves to reassign some fields of param "socket" as it is an unconventional
    // object that could not be easily deep copy (and that doing so might break things).
    // eslint-disable-next-line no-param-reassign
    socket.io.opts.extraHeaders = {
        ...socket.io.opts.extraHeaders,
        Authorization: typeof freshAuthorizationHeader === 'string' ? freshAuthorizationHeader : '',
    };

    // Same as above (for eslint disabling explanation)
    // eslint-disable-next-line no-param-reassign
    socket.auth = {
        ...socket.auth,
        authorizationHeader: freshAuthorizationHeader,
    };

    socket.close();
    socket.connect();
};

export const createSocket = ({
    apiInstance,
    socketPath,
    maxReconnectTryCount = DEFAULT_RECONNECT_TRY_COUNT,
    transports = ['polling', 'websocket'],
    fatalConnectionErrorCallback,
}: CreateSocketParams): Socket => {
    const authorizationHeader = BaseAPI.getBaseConfig()?.headers?.Authorization || '';
    // Note: socket initialization (and auth passing configuration in particular) must
    // be compatible with "old" backend config ("polling-first") and new backend config
    // ("socket w/ Redis" => "websocket only")
    const socket = io(wssCell, {
        path: `/${apiInstance.path}/${socketPath}`,
        // Long polling auth passing
        extraHeaders: {
            Authorization: typeof authorizationHeader === 'string' ? authorizationHeader : '',
            'organization-id': orgId,
        },
        // Websocket auth passing
        auth: {
            authorizationHeader,
            orgId,
        },
        // Only websocket transport is supported on new backend (socket w/ Redis), but during the short time interval when old and new versions of front and
        // backend might coexist, we need to support long-polling as well.
        transports,
        forceNew: true,
        // Commented this because it can mess with "old back" setup (=> duplicated first message)
        // retries: 10,
    });

    socket.on('connect_error', async (err: any) => {
        if (err.message === 'not authorized') {
            // Note: chances are that this actually never gets called, even with "old" config (with polling-first setup)
            // Indeed, in this config, polling connection is upgraded to websocket conn => HTTP request are not
            // used afterward, and hence related Authorization and other headers not checked once connection
            // is established.
            try {
                if (reconnectTryCount < maxReconnectTryCount) {
                    reconnectTryCount += 1;
                    await handleAuthError(socket);
                } else {
                    fatalConnectionErrorCallback(new Error('Failed to reconnect websockets, try to refresh the page'));
                }
            } catch (err) {
                fatalConnectionErrorCallback(err as Error);
            }
        }
    });

    socket.on('connect', () => {
        reconnectTryCount = 0;
    });

    socket.on('auth_error', async () => {
        await handleAuthError(socket);
    });

    return socket;
};
