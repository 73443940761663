(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSettingsDefaultController($scope, ContentPicker, Share, Translation, Utils, Widget) {
        'ngInject';

        var widgetSettingsDefault = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetSettingsDefault.Share = Share;
        widgetSettingsDefault.Translation = Translation;
        widgetSettingsDefault.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};

            // Add debounce in the widget settings text fields for new widgets
            widgetSettingsDefault.isBlocksWidget = ['article-list', 'event-list', 'summary', 'personal-feed-preview'].includes(
                currentWidget.widgetType,
            );

            var properties = currentWidget.properties;

            if (angular.isUndefined(properties.more)) {
                properties.more = {};
            }
        }

        init();

        /**
         * Set the parent controller.
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            widgetSettingsDefault.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function WidgetSettingsDefaultDirective() {
        'ngInject';

        function WidgetSettingsDefaultLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetSettingsDefaultController,
            controllerAs: 'widgetSettingsDefault',
            link: WidgetSettingsDefaultLink,
            require: ['widgetSettingsDefault', '^widgetSettings'],
            restrict: 'E',
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/common/views/widget-settings-default.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('widgetSettingsDefault', WidgetSettingsDefaultDirective);
})();
