import toPairs from 'lodash/toPairs';

import BaseApi from '@lumapps/base-api';

import { Member, MemberType } from '../types';

const saApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy' });

// https://sites-sa.lumapps.com/sa/v2/_doc/ui/redoc#operation/program_memberships.query
export interface FetchQuery {
    roles?: string;
    cursor?: string;
    memberType?: MemberType;
    memberName?: string;
    programId?: string | null;
    maxResults?: string;
    sortField?: string;
    sortDir?: '-1' | '1';
}

/**
 * The result we get from /program-memberships endpoint
 */
export interface FetchMembersResult {
    count: number;
    maxResults?: number;
    cursor?: string;
    more: boolean;
    items: Member[];
}

// eslint-disable-next-line default-param-last
const fetchMembers = (query: FetchQuery = {}, fetchKey?: string) => {
    const defaultQuery: FetchQuery = {
        sortField: 'createdAt',
        sortDir: '-1',
    };

    const queryString = toPairs({ ...defaultQuery, ...query })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        .filter(([key, value]) => {
            return Boolean(value);
        })
        .map(([key, value]) => {
            return `${key}=${value}`;
        })
        .join('&');
    const baseUrl = '/program-memberships';
    const url = queryString.length > 0 ? `${baseUrl}?${queryString}` : baseUrl;

    return saApi.get<FetchMembersResult>(url, {}, undefined, true, fetchKey);
};

// eslint-disable-next-line default-param-last
const fetchSharers = (shareableId: string, query: FetchQuery = {}, fetchKey?: string) => {
    const queryString = toPairs(query)
        .map(([key, value]) => {
            return `${key}=${value}`;
        })
        .join('&');

    let url = `/shareable-contents/${shareableId}/sharers`;
    url = queryString.length > 0 ? `${url}?${queryString}` : url;
    return saApi.get(url, {}, undefined, true, fetchKey);
};

/**
 * Cancel the program-membership call with the given token.
 */
const cancelFetchMembers = (fetchKey: string) => {
    return saApi.cancel('/program-memberships', undefined, fetchKey);
};

export { fetchMembers, fetchSharers, saApi, cancelFetchMembers };
