import { useMemo } from 'react';

import { searchForMoving } from '@lumapps/communities/api';
import { CommunitiesSearchForMovingRequestParams } from '@lumapps/communities/types';
import { useSelector } from '@lumapps/redux/react';
import { userMainLocaleSelector } from '@lumapps/translations/selectors';

import { CommunityPickerDialogHook } from '../types';
import { useCommunityPickerFilters } from './useCommunityPickerFilters';

export const useSearchForMovingPicker: CommunityPickerDialogHook<
    CommunitiesSearchForMovingRequestParams,
    typeof searchForMoving
> = (fetchParams, query = '') => {
    const lang = useSelector(userMainLocaleSelector);
    const { component, sortOrder, filters, resetFilters } = useCommunityPickerFilters({
        enableMultipleInstancesSearch: false,
    });

    const fetchAPIParams: CommunitiesSearchForMovingRequestParams = useMemo(
        () => ({
            ...filters,
            ...fetchParams,
            sortOrder,
            lang,
            postId: fetchParams.postId as string,
            query,
            instanceId: fetchParams.instanceId,
        }),
        [fetchParams, filters, lang, query, sortOrder],
    );

    return {
        fetchAPIParams,
        fetchAPI: searchForMoving,
        additionalFilterFields: component,
        hasNoFilter: !fetchAPIParams.followingOnly && !fetchAPIParams.query,
        resetFilters,
    };
};
