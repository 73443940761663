/* eslint-disable react/self-closing-comp */
import React from 'react';

import { useClassnames, margin, classnames } from '@lumapps/classnames';
import { HeadingProps, Size, SkeletonTypography, Heading, TypographyInterface } from '@lumapps/lumx/react';

export interface HeaderTitleProps {
    /** The title to show */
    title?: string | React.ReactNode;
    /** The subtitle to show */
    subtitle?: string | React.ReactNode;
    /** id for the header title */
    headerTitleId: string;
    /** custom title props */
    titleProps?: Partial<HeadingProps>;
    /** custom class name */
    CLASSNAME: string;
    /** whether the element is loading or not */
    isLoading?: boolean;
    /** title typography */
    typography: TypographyInterface;
}

export const HeaderTitle: React.FC<HeaderTitleProps> = ({
    title,
    subtitle,
    headerTitleId,
    titleProps,
    typography,
    CLASSNAME,
    isLoading,
}) => {
    const { element } = useClassnames(CLASSNAME);

    if (isLoading) {
        return <SkeletonTypography typography={typography} width="256px" />;
    }

    return (
        <Heading
            as="h1"
            typography={typography}
            {...titleProps}
            id={headerTitleId}
            className={classnames(
                element('title', { 'with-subtitle': Boolean(subtitle) }),
                {
                    [margin('bottom', Size.tiny)]: Boolean(subtitle),
                },
                [titleProps?.className],
            )}
        >
            {title}
        </Heading>
    );
};
