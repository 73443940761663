import React from 'react';

import { padding } from '@lumapps/classnames';
import {
    GroupChipPickerField,
    GroupChipPickerFieldProps,
} from '@lumapps/group-pickers/components/GroupChipPickerField';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export interface FormGroupPickerFieldProps
    extends Omit<GroupChipPickerFieldProps, 'selectedGroups' | 'onGroupsSelected'> {
    name: string;
}

/**
 * Component that connects the Group picker to our forms system.
 * @family Forms
 * @param FormGroupPickerFieldProps
 * @returns FormGroupPickerField
 */
export const FormGroupPickerField: React.FC<FormGroupPickerFieldProps & Omit<BaseInput, 'name'>> = ({
    name,
    controllerProps,
    isRequired,
    label,
    ...props
}) => {
    const { translateKey } = useTranslate();
    const { field, fieldState, valueToUse, scope, reset } = useFormField({
        name,
        isRequired,
        controllerProps,
        label,
    });

    const areValuesIds = valueToUse && valueToUse.length > 0 && typeof valueToUse[0] === 'string';

    return (
        <GroupChipPickerField
            onGroupsSelected={(groups) => {
                field.onChange(groups);

                /**
                 * If this is the first time we are loading groups, we need to reset the form
                 * so that it does not detect that it is dirty.
                 */
                if (areValuesIds) {
                    reset(undefined, { keepDirty: false, keepDirtyValues: true, keepValues: true });
                }
            }}
            selectedGroups={valueToUse}
            scope={`${scope}-${name}`}
            {...props}
            defaultValuesIds={areValuesIds ? valueToUse : undefined}
            onDefaultGroupsFetch={field.onChange}
            shouldDisplayLoadingState
            label={label}
            isRequired={isRequired}
            textFieldProps={{
                className: padding('vertical', null),
            }}
            errorMessage={
                fieldState.error?.message ||
                (Boolean(fieldState.error) && translateKey(GLOBAL.FIELD_IS_REQUIRED)) ||
                undefined
            }
        />
    );
};
