import produce from 'immer';

import { OPEN_MODAL, CLOSE_MODAL, SET_SOURCE_LANG } from './widget_translation_action';

const initialState = {
    isModalOpen: false,
    sourceLang: '',
};

/**
 * The reducer for the tasks actions.
 *
 * @param  {Object} state  The current store state.
 * @param  {string} action The current dispatched action.
 * @return {Object} The new state of the redux store.
 */
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MODAL: {
            return produce(state, (draft) => {
                draft.isModalOpen = true;

                return draft;
            });
        }

        case CLOSE_MODAL: {
            return produce(state, (draft) => {
                draft.isModalOpen = false;

                return draft;
            });
        }

        case SET_SOURCE_LANG: {
            return produce(state, (draft) => {
                draft.sourceLang = action.payload.sourceLang;

                return draft;
            });
        }

        default:
            return state;
    }
};

export { reducer };
