function SocialAdvocacyController($scope, $stateParams, Header, SocialAdvocacy, Layout, Features, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Check if SA feature is enable.
     *
     * @type {Boolean}
     */
    vm.isSoscialAdvocacyEnabled = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Header = Header;
    vm.SocialAdvocacy = SocialAdvocacy;
    vm.slug = $stateParams.slug;
    vm.view = $stateParams.view;
    vm.getMarginTop = () => Utils.getMarginTop(Header, Features, Layout);

    $scope.$on('$stateChangeSuccess', () => {
        vm.slug = $stateParams.slug;
        vm.view = $stateParams.view;
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.isSoscialAdvocacyEnabled = SocialAdvocacy.isEnabled();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('SocialAdvocacyController', SocialAdvocacyController);

/////////////////////////////

export { SocialAdvocacyController };
