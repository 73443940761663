import BaseApi from '@lumapps/base-api';

const CommunityApi = new BaseApi({ path: 'community' });

/**
 * Subscribe or unsubscribe to the community.
 * @param  {boolean} notify True: follow, False: unfollow.
 * @param  {number}  uid    The ID of the targeted community.
 * @return {Promise} Returns.
 */
const manageFollowing = ({ notify, uid }) => {
    return CommunityApi.post(`${uid}/${notify ? 'follow' : 'unfollow'}`);
};

const fetchCommunityMemberList = ({ page, maxResults, uid }) => {
    return CommunityApi.post('users/details', { maxResults, page, uid });
};

/**
 * Used to share a post on multiple communities.

 * @param {string} postId The post id
 * @param {array} addToCommunities Where the post should be shared
 */
const sharePost = (postId, addToCommunities) => {
    return CommunityApi.post('post/share', { postId, addToCommunities });
};

/**
 * Used to unshare a post from a community.

 * @param {*} postId The post Id
 * @param {*} communityId Where the post should be removed from
 */
const unsharePost = (postId, communityId) => {
    return CommunityApi.post('post/unshare', { postId, removeFromCommunities: [communityId] });
};

export { manageFollowing, fetchCommunityMemberList, sharePost, unsharePost };
