import React, { ReactNode, createContext, useContext } from 'react';

import { RegisteredComboboxOption } from '../types';

export const ComboboxOptionIdContext = createContext<RegisteredComboboxOption['generatedId']>('');

interface ComboboxOptionIdProviderProps {
    /** The optionId this action is a part of */
    optionId: string;
    /** Option to display */
    children: ReactNode;
}

/** Context Provider to store the current combobox option id. */
export const ComboboxOptionIdProvider = ({ optionId, children }: ComboboxOptionIdProviderProps) => {
    return <ComboboxOptionIdContext.Provider value={optionId}>{children}</ComboboxOptionIdContext.Provider>;
};

/**
 * Retrieve the current combobox option id.
 * Must be used within a ComboboxOptionIdProvider
 */
export const useComboboxOptionId = () => {
    const comboboxOptionId = useContext(ComboboxOptionIdContext);

    if (!comboboxOptionId) {
        throw new Error('This hook must be used within a ComboboxOptionIdProvider');
    }

    return comboboxOptionId;
};
