import React from 'react';

import { Theme } from '@lumapps/lumx/react';
import { ArticleListFilters } from '@lumapps/widget-article-list/components/ArticleListFilters';
import { WIDGET_ARTICLE_LIST_TYPE } from '@lumapps/widget-article-list/constants';
import { EventListFilters } from '@lumapps/widget-event-list/components/EventListFilters/EventListFilters';
import { WIDGET_EVENT_LIST_TYPE } from '@lumapps/widget-event-list/constants';
import { PostListFilters } from '@lumapps/widget-post-list/components/BlockContainerFilters/PostListFilters';
import { WIDGET_COMMUNITY_POST_LIST_TYPE } from '@lumapps/widget-post-list/constants';
import { StreamFilters } from '@lumapps/widget-stream/components/StreamFilters';
import { WIDGET_STREAM_TYPE } from '@lumapps/widget-stream/constants';

import { WidgetState } from '../../../ducks/type';
import { BlockContainerFilters as BlockContainerFiltersBaseProps, FilterComponentProps } from '../../../types';

const WIDGET_FILTERS_COMPONENTS: { [widgetType: string]: React.FC<FilterComponentProps> } = {
    [WIDGET_ARTICLE_LIST_TYPE]: ArticleListFilters,
    [WIDGET_EVENT_LIST_TYPE]: EventListFilters,
    [WIDGET_COMMUNITY_POST_LIST_TYPE]: PostListFilters,
    [WIDGET_STREAM_TYPE]: StreamFilters,
};

export interface BlockContainerFiltersProps extends BlockContainerFiltersBaseProps {
    theme: Theme;
    activeFilters: Record<string, any>;
    onApply(): void;
    onReset(filterIds?: string[]): void;
    onFilterChange(newFilters: Record<string, any>): void;
    widgetType: string;
    loadingState?: WidgetState['state'];
}

/**
 * This component is responsible of the display of the widget filters bar.
 */
export const BlockContainerFilters: React.FC<BlockContainerFiltersProps> = ({
    theme,
    widgetType,
    activeFilters,
    availableFilters,
    loadingState,
    onApply,
    onReset,
    onFilterChange,
}) => {
    const FilterComponent = WIDGET_FILTERS_COMPONENTS[widgetType];

    if (!FilterComponent || !onApply || !onReset || !onFilterChange) {
        return null;
    }

    return (
        <FilterComponent
            theme={theme}
            availableFilters={availableFilters}
            activeFilters={activeFilters}
            onApply={onApply}
            onReset={onReset}
            onFilterChange={onFilterChange}
            loadingState={loadingState}
        />
    );
};
