import React from 'react';

import { classnames } from '@lumapps/classnames';
import type { ElementRender } from '@lumapps/wrex/types';

import { UNORDERED_LIST } from '../../../constants';
import type { UnorderedListElement } from '../../../types';

import './index.scss';

export const UnorderedList: ElementRender<UnorderedListElement, HTMLUListElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;

    return (
        <ul {...forwardedProps} className={classnames(className, 'wrex-content-unordered-list')} ref={elementRef}>
            {children}
        </ul>
    );
};
UnorderedList.displayName = UNORDERED_LIST;
