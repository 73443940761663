import { func, string, shape } from 'prop-types';

/**
 * Defines the `mode` object that can be passed to `ModeSettings` component.
 */
export const modeTypes = shape({
    /** The label to display. */
    label: string,
    /** What will be displayed above the `label`. */
    render: func,
    /** The mode value to set. */
    value: string.isRequired,
});
