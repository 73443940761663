import BaseApi from '@lumapps/base-api';

const favoriteApi = new BaseApi({
    path: 'favorite',
});

const favorite = async (id: string) => {
    await favoriteApi.post('/mark', { target: { kind: 'DirectoryEntry', uid: id } });
};

const unfavorite = async (id: string) => {
    await favoriteApi.delete(`/having_target?kind=DirectoryEntry&uid=${id}`);
};

export { favoriteApi, favorite, unfavorite };
