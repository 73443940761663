import noop from 'lodash/noop';

import { useDispatch } from '@lumapps/redux/react';

import { fetchWidgetBlocksById } from '../ducks/thunks/fetchWidgetBlocksById';
import { fetchWidgetBlocksByProperties } from '../ducks/thunks/fetchWidgetBlocksByProperties';
import { WidgetState } from '../ducks/type';

/**
 * Re-fetch the block for the designated widget.
 * Set the widget to it's initial state
 * */
export const useResetBlock = (widget?: WidgetState) => {
    const dispatch = useDispatch();

    if (!widget || !widget.widgetId || !widget.widgetType) {
        return noop;
    }

    const { widgetId, widgetType, legacyWidget, blockResolutionInfo, isMainWidget } = widget;

    return () => {
        if (legacyWidget) {
            dispatch(
                fetchWidgetBlocksByProperties({
                    legacyWidget,
                    params: blockResolutionInfo,
                    isMainWidget: Boolean(isMainWidget),
                }),
            );
        } else {
            dispatch(
                fetchWidgetBlocksById({
                    widgetId,
                    widgetType,
                    params: blockResolutionInfo,
                    isMainWidget: Boolean(isMainWidget),
                }),
            );
        }
    };
};
