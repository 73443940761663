import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const instancesCopyRoute: Route = {
    path: '/admin/instances-copy',
    legacyId: 'app.admin.instances-copy',
    appId: AppId.backOffice,
};

/** Retrieves the route for the instances copy admin */
const instancesCopy = (): Route => instancesCopyRoute;

export { instancesCopy, instancesCopyRoute };
