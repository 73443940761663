import { contentPage, learningHatImage, lumappsJourney, lumappsMicroApp } from '@lumapps/lumx/custom-icons';
import {
    mdiCalendar,
    mdiCellphone,
    mdiEmailOutline,
    mdiGoogleCirclesExtended,
    mdiLink,
    mdiMessageText,
    mdiNewspaperVariantOutline,
    mdiPlay,
    mdiPlayBoxMultiple,
} from '@lumapps/lumx/icons';
import { AspectRatio, Size } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations';

import { JOURNEY_CORE } from './keys';
import { CHANNEL_TYPES, RESOURCE_TYPES, SCHEDULE_OFFSET_DIRECTION, SCHEDULE_OFFSET_UNIT } from './types';

export const JOURNEYS_ICON = lumappsJourney;

export const JOURNEYS_FF = 'journey-journey';
/** Feature flag used for under development features */
export const JOURNEYS_ALPHA_FF = 'journey-alpha';
/** Feature flag used for features ready but non available for customers */
export const JOURNEYS_BETA_FF = 'journey-beta';

export const EXTERNAL_RESOURCE_IMG_CONFIG = {
    aspectRatio: AspectRatio.horizontal,
    size: Size.xl,
};

export const fetchCommunityParams = {
    // fields params overrides for fetching community to add instanceDetails
    fields: 'items(id,uid,title,description,renderingType,privacy,slug,thumbnail,customer,instance,instanceDetails,feedKeys),more,cursor',
};

export const fetchContentParams = {
    // fields params overrides for fetching content, so we get the excerpt
    fields: 'items(id,title,slug,type,customContentType,mediaThumbnail,feedKeys,createdAt,instance,excerpt),more,cursor',
};

/**
 * Configuration to be used for displaying resources with their associated
 * icons and labels.
 *
 */
export const RESOURCES_CONFIG = {
    icons: {
        [RESOURCE_TYPES.ARTICLE]: mdiNewspaperVariantOutline,
        [RESOURCE_TYPES.COMMUNITY]: mdiGoogleCirclesExtended,
        [RESOURCE_TYPES.CONTENT]: contentPage,
        [RESOURCE_TYPES.EXTERNAL_LINK]: mdiLink,
        [RESOURCE_TYPES.EVENT]: mdiCalendar,
        [RESOURCE_TYPES.LEARNING_PATH]: learningHatImage,
        [RESOURCE_TYPES.MICRO_APP]: lumappsMicroApp,
        [RESOURCE_TYPES.PLAY_VIDEO]: mdiPlay,
        [RESOURCE_TYPES.PLAYLIST]: mdiPlayBoxMultiple,
        [RESOURCE_TYPES.POST]: mdiMessageText,
        [RESOURCE_TYPES.TRAINING_COURSE]: learningHatImage,
    },
    labels: {
        [RESOURCE_TYPES.ARTICLE]: GLOBAL.ARTICLE,
        [RESOURCE_TYPES.COMMUNITY]: GLOBAL.COMMUNITY,
        [RESOURCE_TYPES.CONTENT]: GLOBAL.CONTENT,
        [RESOURCE_TYPES.EXTERNAL_LINK]: GLOBAL.LINK,
        [RESOURCE_TYPES.EVENT]: GLOBAL.EVENT,
        [RESOURCE_TYPES.LEARNING_PATH]: GLOBAL.LEARNING,
        [RESOURCE_TYPES.MICRO_APP]: GLOBAL.MICRO_APP,
        [RESOURCE_TYPES.PLAY_VIDEO]: JOURNEY_CORE.VIDEO,
        [RESOURCE_TYPES.PLAYLIST]: GLOBAL.PLAYLISTS,
        [RESOURCE_TYPES.POST]: GLOBAL.POST,
        [RESOURCE_TYPES.TRAINING_COURSE]: GLOBAL.LEARNING,
    },
};

/**
 * Configuration to be used for displaying channel with their associated
 * icons and labels.
 */
export const CHANNEL_CONFIG = {
    icons: {
        [CHANNEL_TYPES.EMAIL]: mdiEmailOutline,
        [CHANNEL_TYPES.MOBILE_PUSH]: mdiCellphone,
    },
    labels: {
        [CHANNEL_TYPES.EMAIL]: GLOBAL.EMAIL,
        [CHANNEL_TYPES.MOBILE_PUSH]: GLOBAL.MOBILE_PUSH,
    },
};

/* Object operators for Unit and Operator */
export const offsetOperatorsMapper = {
    [SCHEDULE_OFFSET_UNIT.DAYS]: GLOBAL.DAYS,
    [SCHEDULE_OFFSET_UNIT.WEEKS]: GLOBAL.WEEKS,
    [SCHEDULE_OFFSET_DIRECTION.BEFORE]: GLOBAL.BEFORE,
    [SCHEDULE_OFFSET_DIRECTION.AFTER]: GLOBAL.AFTER,
};

/**
 * The basic dateOffset of a scheduleConfiguration object
 * when related to a baseline date but not on the same day
 * */
export const BASE_DATE_OFFSET = {
    unit: SCHEDULE_OFFSET_UNIT.DAYS,
    value: 0,
    direction: SCHEDULE_OFFSET_DIRECTION.AFTER,
} as const;

/**
 * Form's translatable fields to be used to update the source language
 */
export const FORM_TRANSLATABLE_FIELDS: Record<string, { id: string; path: string }> = {
    resourceTitle: {
        id: 'resourceTitle',
        path: 'attachment.title',
    },
    resourceDescription: {
        id: 'resourceDescription',
        path: 'attachment.description',
    },
    senderName: {
        id: 'senderName',
        path: 'senderName',
    },
    emailSubject: {
        id: 'emailSubject',
        path: 'subject',
    },
    emailMessage: {
        id: 'emailMessage',
        path: 'message',
    },
    richText: {
        id: 'richText',
        path: 'richText',
    },
};

export const SEGMENT_MAX_LIMIT = 20;

export const SENDER_NAME_MAX_LENGTH = 50;
export const SUBJECT_MAX_LENGTH_MOBILE_PUSH = 35;
export const SUBJECT_MAX_LENGTH_CHANNELS = 100;
export const MESSAGE_MAX_LENGTH_MOBILE_PUSH = 240;

/** Rich text wrapper and message background color */
export const DEFAULT_WRAPPER_BACKGROUND_COLOR = '#F3F3F3';
export const DEFAULT_MESSAGE_BACKGROUND_COLOR = '#FFFFFF';
