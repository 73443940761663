/**
 * Returns a code from a given string. Useful when you want to convert a string to a specific code,
 * that you can use it as a specific seed for a randomic function
 * @param str - string that will be converted into a hash code
 */
export const hashCode = (str: string) => {
    let hash = 0;

    const len = str.length;
    if (len === 0) {
        return hash;
    }

    for (let i = 0; i < len; i++) {
        const char = str.charCodeAt(i);
        /* eslint-disable no-bitwise */
        hash = (hash << 5) - hash + char;
        // Convert to 32bits integer.
        hash |= 0;
        /* eslint-enable no-bitwise */
    }

    return hash;
};
