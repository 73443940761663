import React from 'react';

import { Group } from '@lumapps/groups/types';

import { getGroupAfter } from '../../utils';
import { GroupChipPickerField, GroupChipPickerFieldProps } from '../GroupChipPickerField';

import './index.scss';

export const CLASSNAME = 'group-segment-picker';

export interface GroupAndSegmentPickerFieldProps extends Omit<GroupChipPickerFieldProps, 'onGroupsSelected'> {
    restrictToGroupsIds?: string[];
    defaultSelectedValues?: string[];
    onChange: (groups: Group[]) => void;
}

/**
 *  This component is used when we want to add segments in legacy components, such as:
 * - restrictTo in widget settings
 * - usableBy in content template permissions
 * @family Pickers
 * @param GroupAndSegmentPickerFieldProps
 * @returns GroupAndSegmentPickerField
 */
export const GroupAndSegmentPickerField = ({
    label,
    helper,
    restrictToGroupsIds,
    defaultSelectedValues = [],
    displayAllGroup = true,
    displayPublicGroup = true,
    isRequired = false,
    isDisabled = false,
    onChange,
}: GroupAndSegmentPickerFieldProps) => {
    const customParams = React.useMemo(
        () => ({
            restrictToFeeds: restrictToGroupsIds,
            excludeSegments: false,
        }),
        [restrictToGroupsIds],
    );

    const [groups, setGroups] = React.useState<Group[]>([]);

    const onChangeSelectedGroups = (groups: Group[]) => {
        if (onChange) {
            onChange(groups);
        }
        setGroups(groups);
    };

    return (
        <GroupChipPickerField
            shouldDisplayLoadingState
            label={label}
            onGroupsSelected={onChangeSelectedGroups}
            getGroupAfter={getGroupAfter}
            shouldCloseOnSelect={false}
            customSearchParams={customParams}
            fitToAnchorWidth="width"
            isRequired={isRequired}
            helper={helper}
            defaultValuesIds={defaultSelectedValues}
            isDisabled={isDisabled}
            displayAllGroup={displayAllGroup}
            displayPublicGroup={displayPublicGroup}
            selectedGroups={groups}
            icon={false}
            className={CLASSNAME}
        />
    );
};
