import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { useDispatch } from '@lumapps/redux/react';
import { BlockNoResults } from '@lumapps/widget-base/components/Block/BlockNoResults';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { useDriveVideoUrl } from '../../hooks/useDriveVideoUrl';
import { useEmbedVideoHTML } from '../../hooks/useEmbedVideoHTML';
import { ClientComputedVideoProps } from '../../types';
import { BlockVideo } from '../BlockVideo';

/* TODO once we develop designer v2, use translateObject(properties?.helper) as the placeholderText prop of the
     BlockNoResults component only when in the designer to display the helper (custom placeholder)
 */
export const ClientComputedVideo: React.FC<ClientComputedVideoProps> = ({ properties = {}, uuid }) => {
    const dispatch = useDispatch();
    const embedVideoHTML = useEmbedVideoHTML(properties);
    const driveVideoUrl = useDriveVideoUrl(properties);

    const noResults = properties?.noResults;
    const style = properties?.style;

    const isEmptyWidget = isEmpty(embedVideoHTML?.trim()) && !driveVideoUrl;

    if (isEmptyWidget) {
        if (noResults) {
            return <BlockNoResults theme={style?.content?.theme} />;
        }

        if (!properties || isEmpty(properties.embed)) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
            return null;
        }

        return <BlockVideo properties={properties} needSandbox />;
    }

    return <BlockVideo properties={properties} />;
};
