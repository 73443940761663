import type { Wrex } from '@lumapps/wrex/types';

import { TABLE } from '../constants';
import type { TableElement } from '../types';

/** Create a table element */
export const createTable = (
    attributes: Wrex.ElementAttributes<TableElement>,
    children: TableElement['children'],
): TableElement => {
    return { type: TABLE, ...attributes, children };
};
