(function()
{
    'use strict';

    angular
        .module('Factories')
        .factory('CalendarFactory', CalendarFactory);

    function CalendarFactory($resource, Config)
    {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/calendar/:methodKey',
        {},
        {
            list:
            {
                method: 'GET',
                params:
                {
                    methodKey: 'list'
                },
                isArray: false
            },
            get:
            {
                method: 'GET',
                params:
                {
                    methodKey: 'get'
                },
                isArray: false
            },
        });
    }
})();
