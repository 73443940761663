import React, { useRef } from 'react';

import {
    Button,
    Emphasis,
    ColorPalette,
    Orientation,
    Placement,
    Popover,
    Size,
    Theme,
    UserBlock,
} from '@lumapps/lumx/react';

import { arrayOf, any, func, string, objectOf } from 'prop-types';

import { translate as t } from 'components/translations';

import { redirectToUserDirectoryProfile } from 'components/services/user';

/**
 * Display the widget WidgetDelveUser component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The WidgetDelveUser react element.
 */
const WidgetDelveUser = ({ currentlyOpenedPopover, fields, setCurrentlyOpenedPopover, theme, user, uuid }) => {
    const anchorRef = useRef(null);

    return (
        <div className="widget-delve_user">
            <UserBlock
                avatarProps={
                    user.profilePictureUrl
                        ? {
                              image: user.profilePictureUrl,
                              alt: user.fullName,
                          }
                        : undefined
                }
                fields={fields}
                name={user.fullName}
                size={Size.l}
                theme={theme}
                ref={anchorRef}
                onMouseEnter={() => setCurrentlyOpenedPopover({ close: false, data: user.id + uuid, uuid })}
                onMouseLeave={() => setCurrentlyOpenedPopover({ close: true, data: user.id + uuid, uuid })}
            />
            <Popover
                anchorRef={anchorRef}
                placement={Placement.TOP_START}
                isOpen={currentlyOpenedPopover === user.id + uuid}
                offset={{ away: 20 }}
            >
                <div className="popover-content-container">
                    <UserBlock
                        avatarProps={
                            user.profilePictureUrl
                                ? {
                                      image: user.profilePictureUrl,
                                      alt: user.fullName,
                                  }
                                : undefined
                        }
                        fields={fields}
                        name={user.fullName}
                        orientation={Orientation.vertical}
                        simpleAction={
                            <div className="user-block__more-info">
                                <Button
                                    color={ColorPalette.blue}
                                    emphasis={Emphasis.low}
                                    size={Size.s}
                                    theme={theme}
                                    onClick={() => redirectToUserDirectoryProfile(user)}
                                >
                                    {t('FRONT.USER.PROFILE.MORE_INFO.LINK')}
                                </Button>
                            </div>
                        }
                        theme={theme}
                    />
                </div>
            </Popover>
            <Button
                color={theme === Theme.dark ? ColorPalette.light : undefined}
                emphasis={Emphasis.medium}
                theme={theme}
                onClick={() => redirectToUserDirectoryProfile(user)}
            >
                {t('FRONT.WIDGET_DELVE.MY_PROFILE')}
            </Button>
        </div>
    );
};

WidgetDelveUser.defaultProps = {
    theme: undefined,
};

WidgetDelveUser.propTypes = {
    /* The id of the current opened popover of the user block. */
    currentlyOpenedPopover: string.isRequired,
    /* The user's informations displayed. */
    fields: arrayOf(string).isRequired,
    /* A function for setting the value of the "currentlyOpenedPopover" prop. */
    setCurrentlyOpenedPopover: func.isRequired,
    /* The lumx theme. */
    theme: string,
    /* The current targeted user. */
    user: objectOf(any).isRequired,
    /* The current widget uuid. */
    uuid: string.isRequired,
};

export default WidgetDelveUser;
