(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetShareController($location, Share) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The current page url.
         *
         * @type {string}
         */
        var _CURRENT_URL = $location.absUrl();

        /**
         * A list of the service identifiers that can be used to share a link.
         *
         * @type {Array}
         */
        var _DEFAULT_SERVICES = ['google-currents', 'facebook', 'twitter', 'linkedin','email'];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Share = Share;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Make sure we always have all the services in all widgets, even if some services have been added after the
         * widget was initially created.
         */
        function _handleBackwardCompatibility() {
            var widgetServices = _.map(vm.widget.properties.shareOn, (service) => {
                return service.id;
            });

            var missingServices = _.difference(_DEFAULT_SERVICES, widgetServices);

            angular.forEach(missingServices, function forEachMissingService(serviceId) {
                vm.widget.properties.shareOn.push({
                    enable: true,
                    id: serviceId,
                });
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the url to share for a given service.
         *
         * @param  {string} serviceId The identifier of the service to get a link for.
         * @return {string} The share link for the requested service.
         */
        function getShareLink(serviceId) {
            var baseLink;

            switch (serviceId) {
                case 'email':
                    baseLink = 'mailto:?body=';
                    break;

                case 'facebook':
                    baseLink = 'https://www.facebook.com/sharer/sharer.php?u=';
                    break;

                case 'google-currents':
                    baseLink = 'https://plus.google.com/share?url=';
                    break;

                case 'linkedin':
                    baseLink = 'https://www.linkedin.com/shareArticle?mini=true&url=';
                    break;

                case 'twitter':
                    baseLink = 'https://twitter.com/home?status=';
                    break;

                default:
                    break;
            }

            if (angular.isDefinedAndFilled(baseLink)) {
                return baseLink + _CURRENT_URL;
            }

            return undefined;
        }

        /**
         * Get widget classes according to its properties.
         *
         * @return {Array} A list of css classnames to apply to the widget.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            return widgetClass;
        }

        /**
         * Indicates if the widget is empty.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            return false;
        }

        /**
         * Indicates if the widget must be hidden.
         *
         * @return {boolean} If the widget is hidden or not.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = false;

            return vm.parentCtrl.isHidden;
        }

        /////////////////////////////

        vm.getShareLink = getShareLink;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            if (angular.isUndefined(vm.widget.properties.shareOn)) {
                vm.widget.properties.shareOn = [];
            }

            _handleBackwardCompatibility();
        }

        init();

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function WidgetShareDirective() {
        'ngInject';

        function WidgetShareLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetShareController,
            controllerAs: 'vm',
            link: WidgetShareLink,
            replace: true,
            require: ['widgetShare', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-share/views/widget-share.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetShare', WidgetShareDirective);
})();
