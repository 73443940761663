import { NannyViolation, NannyViolationGrouped, NannyMatch } from '@lumapps/nanny/types';

/**
 * Groups the violations detected by the backend by language, in order to display them
 * on the frontend. This function expect the object `violations` to follow the format
 * [{
 *      "id": "655d7c92-d49c-42de-bb34-cbab591f21",
 *      "rule": "ACCT_NUM_PATTERN",
 *      "explanation": "Profanities are not tollerated on the intranet.",
 *      "matches": {
 *          "en": {
 *              "occurrences": ["shit", "fuck", "dick"],
 *              "more": true
 *           },
 *           "fr": {
 *               "occurrences": ["merde", "putain"],
 *               "more": true
 *            }
 *       }
 *   }]
 * Then, it returns this list of violations grouped by language, and in the meantime it changes the text to be displayed
 * for the translated one, passed through the `translations`
 * object ({ "en": "English version", "fr": "French version" })
 * {
 *      "English version": {
 *           "occurrences": ["shit", "fuck", "dick"],
 *           "more": true,
 *           "explanation": "Profanities are not tollerated on the intranet.",
 *           "rule": "655d7c92-d49c-42de-bb34-cbab591f21",
 *      }
 * }.
 * @param {object} violations   list of violations.
 */
const groupViolationsByLanguage = (violations?: Array<NannyViolation> | null) => {
    if (!violations || !violations.length || violations.length === 0) {
        return {};
    }

    const violationsGroupedByLanguage: Record<string, NannyViolationGrouped[]> = {};

    violations.forEach((violation) => {
        const { matches, explanation, id } = violation;

        const languages = Object.keys(matches);

        languages.forEach((language: string) => {
            if (!violationsGroupedByLanguage[language]) {
                violationsGroupedByLanguage[language] = [];
            }

            violationsGroupedByLanguage[language].push({
                explanation,
                match: matches[language] as NannyMatch,
                rule: id,
            });
        });
    });

    return violationsGroupedByLanguage;
};

const groupOccurences = (occurrences: string[]): Record<string, number> => {
    const counts: Record<string, number> = {};

    occurrences.forEach((occurence) => {
        counts[occurence] = 1 + (counts[occurence] || 0);
    });

    return counts;
};

export { groupViolationsByLanguage, groupOccurences };
