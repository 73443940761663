import { isFeatureEnabled } from '@lumapps/features';
import { isSuperAdmin, isInstanceSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { isConnected } from '@lumapps/user/ducks/selectors';

import { PendoTrigger } from './PendoTrigger';

const mapStateToProps = (state: FrontOfficeStore) => {
    const isSuper = isSuperAdmin(state);
    const isInstanceAdmin = isInstanceSuperAdmin(state);
    const isPendoEnabled = isFeatureEnabled('pendo-analytics')(state);
    const isUserConnected = isConnected(state);

    return {
        isVisible: isUserConnected && (isSuper || isInstanceAdmin) && isPendoEnabled,
    };
};

const ConnectedComponent = connect(mapStateToProps)(PendoTrigger);

export { ConnectedComponent as PendoTrigger };
