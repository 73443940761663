import { dangerousAxiosInstanceUseOnlyIfNeeded } from '@lumapps/base-api';
import { getUserMsToken } from '@lumapps/user/api';

import { OneDriveDocument } from '../types';

/**
 * Sharepoint graph APIs base endpoint.
 * */
export const BASE_URL = 'https://graph.microsoft.com/v1.0';

export interface BatchOneDriveFilesRequestParam {
    isMicrosoftUser?: boolean;
    token?: string;
    files?: { id: string; url: string; driveId: string }[];
    /**
     * Original files to return when an error occured on one of the batch request
     * */
    backupFiles?: any[];
}

export interface BatchOneDriveFilesRequestResponse {
    id: string;
    status: number;
    headers: any;
    body: OneDriveDocument;
}

/**
 * Try to access the DriveItem from a shared URL
 * @param {string} requestToken - User microsoft graph access token
 * @param {string} encodedUrl - Encoded shared URL (for encoding rules see : https://learn.microsoft.com/en-us/graph/api/shares-get?view=graph-rest-1.0&tabs=http#encoding-sharing-urls)
 * */
export const getOneDriveItemFromSharedURL = async (requestToken: string, encodedUrl: string) => {
    return dangerousAxiosInstanceUseOnlyIfNeeded.get<OneDriveDocument>(`${BASE_URL}/shares/${encodedUrl}/driveItem`, {
        headers: {
            Authorization: `Bearer ${requestToken}`,
        },
    });
};

/**
 * Get the provided one drive files
 * Limited to 20 files
 * cf: https://learn.microsoft.com/en-us/graph/json-batching
 */
export const getBatchOneDriveFilesApi = async (
    params?: BatchOneDriveFilesRequestParam,
    signal?: AbortSignal,
): Promise<OneDriveDocument[]> => {
    if (!params || !params.files || !params.isMicrosoftUser) {
        return [];
    }

    const { files } = params;

    const requestPayload = {
        requests: files.map((file, index) => {
            return {
                id: index,
                method: 'GET',
                url: `/drives/${file.driveId}/items/${file.id}`,
            };
        }),
    };

    const { data: tokenData } = await getUserMsToken();

    if (tokenData) {
        const { data }: { data: { responses: BatchOneDriveFilesRequestResponse[] } } =
            await dangerousAxiosInstanceUseOnlyIfNeeded.post(`${BASE_URL}/$batch`, requestPayload, {
                signal,
                headers: {
                    Authorization: `Bearer ${tokenData.accessToken}`,
                },
            });

        return data.responses.map((res) => {
            const responseBody = res.body as any;
            // Pass the file to the response to know exactly which of the given files had an issue
            if (responseBody.error) {
                return {
                    ...responseBody,
                    error: {
                        ...responseBody.error,
                        backupFile: params.backupFiles?.[Number(res.id)] || files[Number(res.id)],
                    },
                };
            }
            return responseBody as OneDriveDocument;
        });
    }

    return [];
};

export const oneDriveQueryKeys = {
    all: () => ['one-drive'] as const,
    'batch-files': (params?: BatchOneDriveFilesRequestParam) =>
        [...oneDriveQueryKeys.all(), 'batch-files', params] as const,
};
