import React from 'react';

import throttle from 'lodash/throttle';

/**
 * Compute the width and height from the element
 * @param elem The current ref of the element
 */
function getSize(elem: any) {
    const size = {
        width: elem ? elem.clientWidth : null,
        height: elem ? elem.clientHeight : null,
    };

    return size;
}

/**
 * Hook used to get width and height of an element
 * @param ref
 */
const useElementSize = (ref: any): { width: number | null; height: number | null } => {
    const isClient = typeof window === 'object';
    const [size, setSize] = React.useState(getSize(ref.current));

    React.useLayoutEffect(() => {
        if (!isClient) {
            return;
        }

        setSize(getSize(ref.current));

        const handleResize = throttle(() => {
            setSize(getSize(ref.current));
        }, 100);

        window.addEventListener('resize', handleResize);
        // eslint-disable-next-line consistent-return
        return () => window.removeEventListener('resize', handleResize);
    }, [isClient, ref]);

    return size;
};

export { useElementSize, getSize };
