import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiOpenInNew } from '@lumapps/lumx/icons';
import { Link, LinkProps, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { wrapStringGroupWithComponent } from '@lumapps/utils/string/wrapStringGroupWithComponent';
import { RecursiveObjectValues } from '@lumapps/utils/types/RecursiveObjectValues';

export const DOCUMENTATION_HOST = 'https://docs.lumapps.com/docs/';

/**
 * Links to documentation pages.
 */
export const DOCUMENTATION_LINKS = {
    providers: {
        identityProviders: 'https://docs.lumapps.com/docs/iam-l22659659721175995',
        okta: 'https://docs.lumapps.com/docs/iam-l36356647126206965',
        saml: 'https://docs.lumapps.com/docs/docs/install-installation-landing/install-l14099983831526286/install-l7563189571340669/install-l17378001563365952/install-l8127685322964276',
    },
    segments: {
        createNewSegment: 'https://docs.lumapps.com/docs/knowledge-l03682006399613036',
    },
    installation: {
        configureDomains: 'https://docs.lumapps.com/docs/install-l29002037584623097',
        noVanityUrl: 'https://docs.lumapps.com/docs/install-l26555459124839775',
    },
    spaces: {
        articleCreation: 'https://docs.lumapps.com/docs/contrib-l22176052364731347',
    },
    communities: {
        communityManagement: 'https://docs.lumapps.com/docs/contrib-l1588236638117807communities',
    },
    mobile: {
        configuration: 'https://docs.lumapps.com/docs/mobile-l44254682558051694',
        brandedApp: 'https://docs.lumapps.com/docs/mobile-l8906471165872433',
        designer: 'https://docs.lumapps.com/docs/mobile-l4289631097056079',
        communities: 'https://docs.lumapps.com/docs/mobile-l3010095420266914',
        socialAdvocacy: 'https://docs.lumapps.com/docs/mobile-l8940759620586598',
        navigation: 'https://docs.lumapps.com/docs/mobile-l700322478696006',
        video: 'https://docs.lumapps.com/docs/mobile-l20478242519772938',
        webPage: 'https://docs.lumapps.com/docs/mobile-l5002294971327048',
        featureManagement: 'https://docs.lumapps.com/docs/mobile-l8906471165872433/mobile-l5002294971328048',
    },
    contentStreams: 'https://docs.lumapps.com/docs/knowledge-l04258651893187049',
    nanny: 'https://docs.lumapps.com/docs/contrib-l11049920157940274',
    cookies: {
        overview: 'https://docs.lumapps.com/docs/intro-l3325713270907623',
    },
    notifications: {
        interests: 'https://docs.lumapps.com/docs/user-l04832974969237602',
    },
    iam: {
        roles: 'https://docs.lumapps.com/docs/docs/iam-iam-landing/iam-l06857869035934039',
    },
    askAi: {
        configurations:
            'https://docs.lumapps.com/docs/ls/content/4558811600985179/docs/knowledge-knowledge-management-landing/knowledge-l1561709127419536/knowledge-l22153940014185214',
        vertexAiSearch:
            'https://docs.lumapps.com/docs/ls/content/134765513732671/docs/knowledge-knowledge-management-landing/knowledge-l1561709127419536/knowledge-l045239975215653594',
        azureAiChat:
            'https://docs.lumapps.com/docs/ls/content/2088414732881597/docs/knowledge-knowledge-management-landing/knowledge-l1561709127419536/knowledge-l5692211291652458',
        vertexAgent:
            'https://docs.lumapps.com/docs/ls/content/1562398491741799/docs/knowledge-knowledge-management-landing/knowledge-l1561709127419536/knowledge-l6308115406755489',
    },
    play: {
        upload: 'https://docs.lumapps.com/docs/ls/content/7862286073011741/contrib-l3817787421146367',
    },
} as const;

/** Props for the ReferToDocumentation component */
export interface ReferToDocumentationProps extends TextProps {
    /**
     * The documentation link to display.
     * Must come from the DOCUMENTATION_LINKS object.
     */
    link: RecursiveObjectValues<typeof DOCUMENTATION_LINKS>;
    linkProps?: Omit<LinkProps, 'href'>;
    /** Set the typography and the size of icons */
    typography?: Typography;
}

const CLASSNAME = 'lumx-refer-to-documentation';

/**
 * Display the sentence "For more information, refer to the documentation" with a link set on the word "documentation".
 * The link must come from the DOCUMENTATION_LINKS object.
 *
 * @family Texts
 */
export const ReferToDocumentation: React.FC<ReferToDocumentationProps> = ({
    link,
    className,
    linkProps,
    typography,
    ...textProps
}) => {
    const { translateKey } = useTranslate();

    const { block } = useClassnames(CLASSNAME);

    const translationWithLink = useMemo(() => {
        const translation = translateKey(GLOBAL.REFER_TO_DOCUMENTATION_LINK);

        /**
         * To be able to easily insert a link into the translation,
         * we use a special translation that wraps the word `documentation` into empty <a> tags (`<a>documentation</a>`).
         * We then use this function to find it and wrap the translated string into an actual link.
         */
        return wrapStringGroupWithComponent(translation, { start: '<a>', end: '</a>' }, (value, index) => (
            <Link
                key={`replacement-${index}`}
                href={link}
                target="_blank"
                rightIcon={mdiOpenInNew}
                typography={typography}
                {...linkProps}
            >
                {value}
            </Link>
        ));
    }, [link, linkProps, translateKey, typography]);

    return (
        <Text typography={typography} className={block([className])} {...textProps}>
            {translationWithLink}
        </Text>
    );
};
