import React from 'react';

import loFind from 'lodash/find';
import includes from 'lodash/includes';

import { Alignment, Kind, Message, Switch } from '@lumapps/lumx/react';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';

import { WidgetImageGallery } from '../WidgetImageGallery';
import { CAPTION_POSITIONS_MODES, NAVIGATION_TYPES, ORIENTATIONS, VIEW_MODES, VIEW_MODE_VARIANTS } from '../constants';
import { Select, InputTextField } from '../../../ui';
import { ModeSettings } from '../../../styles';
import { translate as t } from '../../../../translations';

const { properties: DEFAULT_PROPS } = WidgetImageGallery.defaultProps;

/**
 * A list of all view modes that will be displayed by a `ModeSettings` component.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const MODES = {
    captionPosition: {
        modes: CAPTION_POSITIONS_MODES,
        propertyPath: ['captionPosition'],
    },
    navigationType: {
        modes: NAVIGATION_TYPES,
        propertyPath: ['navigationType'],
    },
    viewMode: {
        modes: VIEW_MODES,
        propertyPath: ['viewMode'],
    },
    viewModeVariant: {
        modes: VIEW_MODE_VARIANTS,
        propertyPath: ['viewModeVariant'],
    },
};

const WidgetImageGalleryStyleSettings = ({ properties = {}, isLayoutActivated, onChange }) => {
    /**
     * Render a `ModeSettings` component for a given property that will match a key
     * of the `MODES` constant.
     *
     * @param  {string}       property The viewMode that match the property.
     * @return {ModeSettings} The settings component.
     */
    const renderModeSettingsForProperty = (property) => {
        return (
            <ModeSettings
                current={properties[property] || DEFAULT_PROPS[property]}
                modes={MODES[property].modes}
                property={MODES[property].propertyPath}
                onChange={onChange}
            />
        );
    };

    /**
     * Defines whether an option block should be displayed for the current view mode.
     *
     * @param  {string}  option The option name to check.
     * @return {boolean} Is the option block displayed.
     */
    const shouldDisplayOptionForMode = (option) => {
        const { viewMode = DEFAULT_PROPS.viewMode } = properties;

        const parentPropertyValue = viewMode;
        const parentPropertyDefinition = loFind(MODES.viewMode.modes, {
            value: parentPropertyValue,
        });

        return includes(parentPropertyDefinition.availableOptions, option);
    };

    return (
        <>
            <div className="p+">
                <span className="display-block mb+ fs-body-2 tc-primary">{t('WIDGET_SETTINGS_VIEW_MODE')}</span>
                {renderModeSettingsForProperty('viewMode')}
            </div>
            {shouldDisplayOptionForMode('viewModeVariant') && (
                <>
                    <div className="divider divider--dark" />
                    <div className="p+">
                        <span className="display-block mb+ fs-body-2 tc-primary">
                            {t('FRONT.SETTINGS.VIEW_MODE_VARIANTS')}
                        </span>
                        {renderModeSettingsForProperty('viewModeVariant')}
                    </div>
                </>
            )}

            {shouldDisplayOptionForMode('captionPosition') && (
                <>
                    <div className="divider divider--dark" />
                    <div className="p+">
                        <span className="display-block mb+ fs-body-2 tc-primary">{t('FRONT.SETTINGS.CAPTION')}</span>
                        {renderModeSettingsForProperty('captionPosition')}
                    </div>
                </>
            )}

            <div className="divider divider--dark" />

            <div className="p+">
                <span className="display-block mb fs-body-2 tc-primary">{t('FRONT.SETTINGS.OPTIONS')}</span>
                {shouldDisplayOptionForMode('columnCount') && (
                    <InputTextField
                        helpText={`${t('FRONT.SETTINGS.DEFAULT_VALUE_IS')} ${DEFAULT_PROPS.columnCount}`}
                        label={t('WIDGET_SETTINGS_COLUMN_COUNT')}
                        max={12}
                        min={1}
                        name="columnCount"
                        placeholder={DEFAULT_PROPS.columnCount}
                        type="number"
                        value={properties.columnCount}
                        onChange={(value, propertyName) => onChange(value || undefined, propertyName)}
                    />
                )}
                {shouldDisplayOptionForMode('hasAutoplay') && (
                    <div className="pt+">
                        <Switch
                            checked={
                                properties.hasAutoplay === undefined
                                    ? DEFAULT_PROPS.hasAutoplay
                                    : properties.hasAutoplay
                            }
                            helpher={t('FRONT.SETTINGS.AUTOPLAY.HELPER')}
                            name="hasAutoplay"
                            position={Alignment.right}
                            onChange={(checked) => onChange(checked, 'hasAutoplay')}
                        >
                            {t('FRONT.SETTINGS.AUTOPLAY')}
                        </Switch>
                    </div>
                )}
            </div>

            {shouldDisplayOptionForMode('interval') && properties.hasAutoplay && (
                <>
                    <div className="divider divider--dark" />
                    <div className="p+">
                        <InputTextField
                            helpText={`${t('FRONT.SETTINGS.SLIDE_INTERVAL.HELPER')}\n${t(
                                'FRONT.SETTINGS.DEFAULT_VALUE_IS',
                            )} ${DEFAULT_PROPS.interval}.`}
                            label={t('FRONT.SETTINGS.SLIDE_INTERVAL')}
                            min={1}
                            name="interval"
                            placeholder={DEFAULT_PROPS.interval}
                            type="number"
                            value={properties.interval || DEFAULT_PROPS.interval}
                            onChange={onChange}
                        />
                    </div>
                </>
            )}

            {shouldDisplayOptionForMode('navigationControls') && (
                <>
                    <div className="divider divider--dark" />
                    <div className="p+">
                        <Switch
                            checked={
                                properties.navigationControls === undefined
                                    ? DEFAULT_PROPS.navigationControls
                                    : properties.navigationControls
                            }
                            helper={t('FRONT.SETTINGS.NAVIGATION_CONTROLS.HELPER')}
                            name="navigationControls"
                            position={Alignment.right}
                            onChange={(checked) => onChange(checked, 'navigationControls')}
                        >
                            {t('FRONT.SETTINGS.NAVIGATION_CONTROLS')}
                        </Switch>
                        {isLayoutActivated && (
                            <Message kind={Kind.info} hasBackground>
                                <p>{t(WIDGET_BASE.SETTINGS_OPTION_NOT_SUPPORTED_NGI)}</p>
                            </Message>
                        )}
                    </div>
                </>
            )}

            {shouldDisplayOptionForMode('orientation') && (
                <>
                    <div className="divider divider--dark" />
                    <div className="p+">
                        <span className="display-block mb fs-body-2 tc-primary">{t('GLOBAL.THUMBNAIL')}</span>
                        <Select
                            choices={ORIENTATIONS}
                            label={t('FRONT.SETTINGS.ORIENTATION')}
                            name="orientation"
                            placeholder={DEFAULT_PROPS.orientation}
                            value={properties.orientation || DEFAULT_PROPS.orientation}
                            onChange={onChange}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export { WidgetImageGalleryStyleSettings };
