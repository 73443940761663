import React from 'react';

import { TreeOpenStateContext } from '@lumapps/utils/components/TreeOpenStateContext';

/**
 * Handle open/close state for a specific submenu ID.
 */
export function useSubmenuOpenState(submenuId: string, onToggle?: (isOpen: boolean) => void) {
    const context = React.useContext(TreeOpenStateContext);
    const [localOpenedMenuId, setLocalOpenedMenuId] = React.useState<string>();
    const openedMenuId = context?.openedSubMenu || localOpenedMenuId;
    const setOpenedMenuId = context?.setOpenedSubMenu || setLocalOpenedMenuId;
    const isOpen = openedMenuId === submenuId;

    // Open or close the submenu depending on the return value of the predicate callback function `condition`
    const setOpenIf = React.useCallback(
        (condition: (prevId?: string) => boolean) => {
            setOpenedMenuId((prevId) => {
                const isOpening = condition(prevId);
                onToggle?.(isOpening);
                return isOpening ? submenuId : undefined;
            });
        },
        [onToggle, setOpenedMenuId, submenuId],
    );

    const open = React.useCallback(() => setOpenIf(() => true), [setOpenIf]);
    const close = React.useCallback(() => setOpenIf(() => false), [setOpenIf]);

    return React.useMemo(
        () => ({ openedMenuId, isOpen, close, open, setOpenIf, setOpenedMenuId }),
        [openedMenuId, isOpen, close, open, setOpenIf, setOpenedMenuId],
    );
}

export type MenuOpenState = ReturnType<typeof useSubmenuOpenState>;
