import type { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import type { LinkPreviewType } from '@lumapps/wrex/serialization/dita/types';

import type { LinkPreviewElement } from '../../types';

/** Convert slate Link Preview to DITA Link Preview. */
export const LINK_PREVIEW_CONVERT: Converter<LinkPreviewElement> = (node) => {
    const linkChildren: LinkPreviewType['children'] = [
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:type',
                value: 'website',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:title',
                value: node?.title || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:description',
                value: node?.description || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:image',
                value: node?.thumbnailUrl || '',
            },
            children: [],
        },
    ];

    const ditaNode: LinkPreviewType = {
        type: 'element',
        name: 'data-about',
        attributes: {
            href: node?.url || '',
            scope: 'external',
            type: 'lumapps:link-preview',
        },
        children: linkChildren,
    };
    return ditaNode;
};
