import React from 'react';

/** The current value of the boolean state */
type Value = boolean;
/** Callback to toggle the state */
type ToggleBoolean = () => void;
/** Callback to set the state to false */
type SetToFalse = () => void;
/** Callback to set the state to true */
type SetToTrue = () => void;

/**
 * This utils give the setter functions for a simple useState<boolean>.
 *
 * @param defaultValue Default value to use.
 * @param onChange Callback on boolean value change.
 * @return {array} A tuple following this format [value, toggleBoolean, setToFalse, setToTrue].
 */
export const useBooleanState = (
    defaultValue: boolean,
    onChange?: (value: boolean) => void,
): [Value, ToggleBoolean, SetToFalse, SetToTrue] => {
    const [value, setValue] = React.useState(defaultValue);

    const setToFalse = React.useCallback(() => {
        onChange?.(false);
        setValue(false);
    }, [onChange]);
    const setToTrue = React.useCallback(() => {
        onChange?.(true);
        setValue(true);
    }, [onChange]);
    const toggleBoolean = React.useCallback(
        () =>
            setValue((previousValue) => {
                const newValue = !previousValue;
                onChange?.(newValue);
                return newValue;
            }),
        [onChange],
    );

    return [value, toggleBoolean, setToFalse, setToTrue];
};
