import type { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import type { PlayVideoType } from '@lumapps/wrex/serialization/dita/types';

import type { PlayVideoElement } from '../../types';

/** Convert slate Play Video to DITA Play Video. */
export const PLAY_VIDEO_CONVERT: Converter<PlayVideoElement> = (node) => {
    const playVideoChildren: PlayVideoType['children'] = [
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'lumapps:id',
                value: node?.id || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:title',
                value: node?.title || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:url',
                value: node?.url || '',
            },
            children: [],
        },
    ];

    const ditaNode: PlayVideoType = {
        type: 'element',
        name: 'data-about',
        attributes: {
            type: 'lumapps:play',
        },
        children: playVideoChildren,
    };

    return ditaNode;
};
