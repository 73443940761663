import React, { useEffect } from 'react';

import keyBy from 'lodash/keyBy';

import { Instance } from '@lumapps/instance/types';
import { getAllFamilyMetadata, getAllInstanceFamilyMetadata } from '@lumapps/metadata/api';
import { Metadata, MetadataByID } from '@lumapps/metadata/types';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { DEFAULT_METADATA_PICKER_REQUEST_FIELDS } from '../constants';
import { MetadataFields, MetadataFilter } from '../types';
import { MetadataTree, buildMetadataTree } from '../utils/buildMetadataTree';

export type UseFetchMetadataFamilyTreeProps = {
    /**
     * The id of the family of metadata to fetch.
     */
    familyId: Metadata['familyKey'];
    /**
     * The instance on which the metadata should be fetched.
     */
    instanceId?: Instance['id'];
    /**
     * Default values to add to the dictionary.
     * Useful if you already have data from some metadata that you want to display
     * before fetching.
     */
    defaultDictionary?: MetadataByID | Record<string, MetadataFilter>;
    /** Whether the hook should fetch */
    shouldFetch?: boolean;
    /**
     *
     * Fields to request to the api
     * Will be appended to default (['id', 'name', 'parent', 'sortOrder']);
     *
     * ! Warning: The picker will fetch **all** metadata of a family.
     * ! Requesting too many fields will result in a slow user experience.
     */
    extraRequestFields?: MetadataFields;
    /**
     * use /instance/metadata/list instead of /metadata/list
     */
    useInstanceMetadataApi?: boolean;
    /** whether the tree built should show multiple levels or just the first one */
    showMultipleLevels?: boolean;
};

/**
 * Hook to fetch all metadata belonging to a family.
 *
 * ! This will make a recursive `list` call to get all pages until the list is complete.
 * ! To avoid too many calls, the default maxResults is set as 9999 with very minimal projection fields.
 * ! You can change these attributes using the given props but beware that a bigger projection
 * ! will cause a slower and heavier call.
 */
export const useFetchMetadataFamilyTree = ({
    familyId,
    instanceId,
    shouldFetch,
    defaultDictionary = {},
    extraRequestFields = [],
    useInstanceMetadataApi = false,
    showMultipleLevels = false,
}: UseFetchMetadataFamilyTreeProps) => {
    const [metadataList, setItems] = React.useState<MetadataTree>([]);

    /**
     * Build dictionary from results to easily get metadata info from id.
     * */
    const metadataDictionary = React.useMemo(() => keyBy(metadataList, 'id'), [metadataList]);

    const { status, fetch } = useFetchWithStatus<any>({
        onFetch: useInstanceMetadataApi ? (getAllInstanceFamilyMetadata as any) : (getAllFamilyMetadata as any),
    });

    const fetchMetadataFamily = React.useCallback(() => {
        const buildFields = [...DEFAULT_METADATA_PICKER_REQUEST_FIELDS, ...extraRequestFields].join(',');

        fetch({
            params: {
                familyId,
                // If multiple levels are desired, we remove the parent property so that the backend
                // only filters by familyId, therefore return the entire tree.
                parent: showMultipleLevels ? undefined : familyId,
                instance: instanceId,
                fields: `items(${buildFields}),more,cursor`,
            },
            callback: ({ success, response }) => {
                if (success && response.length > 0) {
                    setItems(buildMetadataTree(response, familyId));
                }
            },
        });
    }, [extraRequestFields, fetch, familyId, showMultipleLevels, instanceId]);

    useEffect(() => {
        if (shouldFetch && status === BaseLoadingStatus.initial) {
            fetchMetadataFamily();
        }
    }, [fetchMetadataFamily, shouldFetch, status]);

    return {
        status,
        metadataList,
        metadataDictionary: { ...defaultDictionary, ...metadataDictionary },
        fetchMetadataFamily,
    };
};
