import React from 'react';

import { CommunityLink, CommunityLinkProps } from '@lumapps/communities/components/CommunityLink';
import { Community } from '@lumapps/communities/types';
import { ContentTypes } from '@lumapps/content-types/types';
import { DataAttributesOptions, useDataAttributes } from '@lumapps/data-attributes';
import { Thumbnail, ThumbnailProps } from '@lumapps/lumx/react';
import { TranslationAPI, useTranslate } from '@lumapps/translations';
import type { FontColorStyle, FontSizeStyle, FontWeightStyle } from '@lumapps/widget-styles/types';

import { Content } from '../../types';
import { ContentLink, ContentLinkProps } from '../ContentLink';

interface LegacyContentLinkProps {
    className?: string;
    content: Content;
    label: string;
    scope?: string;
    attributes?: DataAttributesOptions;
    thumbnailProps?: ThumbnailProps;
    linkStyle?: FontColorStyle & FontSizeStyle & FontWeightStyle;
    // Forwarded props.
    [key: string]: any;
}

type GenericLink = typeof ContentLink | typeof CommunityLink;
type GenericLinkProps = ContentLinkProps | CommunityLinkProps;

/** Generate link config from legacy content object */
function getLinkConfig(content: Content, translateObject: TranslationAPI['translateObject']) {
    let defaultScope = 'legacy-content-link';
    let LinkComponent: GenericLink = ContentLink;
    const linkProps: GenericLinkProps = { crossInstanceInNewTab: true, to: {} };

    if (content.type === ContentTypes.COMMUNITY) {
        LinkComponent = CommunityLink;
        defaultScope = 'legacy-community-link';
        (linkProps as CommunityLinkProps).to.renderingType = (content as Community).renderingType;
    }

    linkProps.to.slug = (content.slug && translateObject(content.slug)) || undefined;
    linkProps.to.link = (content.link && translateObject(content.link)) || undefined;
    linkProps.to.id = content.id;

    if (content.instanceDetails) {
        const { id: instanceId, slug: instanceSlug } = content.instanceDetails;
        linkProps.to.instance = { id: instanceId, slug: instanceSlug };
    }
    if (content.instance && !linkProps.to.instance) {
        linkProps.to.instance = { id: content.instance };
    }

    return { LinkComponent, defaultScope, linkProps };
}

/**
 * Legacy content link accepts any legacy content (even communities) and
 * properly route to NGI or space depending on FF and content-level
 * compatibility.
 *
 * Wrapper around ContentLink and CommunityLink to use in legacy context
 */
export const LegacyContentLink: React.FC<LegacyContentLinkProps> = ({
    className,
    content,
    label,
    thumbnailProps,
    scope,
    attributes,
    linkStyle,
    ...props
}) => {
    const { translateObject } = useTranslate();
    const { linkProps, LinkComponent, defaultScope } = React.useMemo(
        () => getLinkConfig(content, translateObject),
        [content, translateObject],
    );
    const { get } = useDataAttributes(scope || defaultScope);

    if (!linkProps.to) {
        return null;
    }

    return (
        <LinkComponent
            className={className}
            {...get({ element: linkProps?.id, action: 'link', ...attributes })}
            {...linkProps}
            style={linkStyle}
            {...props}
        >
            {thumbnailProps ? <Thumbnail {...thumbnailProps} /> : label}
        </LinkComponent>
    );
};
