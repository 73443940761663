import set from 'lodash/set';

import { ServerListResponse } from '@lumapps/base-api/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

/** */
export interface GenericPickerState {
    // The current status.
    status: BaseLoadingStatus;
    // Whether the choices are displayed or not.
    isOpen: boolean;
    // The stored choices.
    choices: any[];
    // The current search value.
    searchValue: string;
    // The current displayed value.
    displayedValue: string;
    // The data stored from the fetch.
    fetchData?: ServerListResponse;
}

/** The picker's initial state */
const initialState: GenericPickerState = {
    isOpen: false,
    status: BaseLoadingStatus.initial,
    choices: [],
    displayedValue: '',
    searchValue: '',
    fetchData: undefined,
};

export const reducers = {
    /** Action to set the current status. */
    setStatus: (state: GenericPickerState, action: PayloadAction<BaseLoadingStatus>) => {
        set(state, 'status', action.payload);
    },
    /**
     * Action to toggle the display of the choices.
     * A specific state can be passed as payload.
     *
     * By default, will set the "isOpen" as it's opposite value.
     */
    onToggleOpen: (state: GenericPickerState, action?: PayloadAction<boolean>) => {
        set(state, 'isOpen', action?.payload || !state.isOpen);
    },
    /**
     * Action to change the search value.
     * Dispatching this action will empty the current choices and fetchData.
     * It will also set the picker as "reloading".
     */
    setSearchValue: (state: GenericPickerState, action: PayloadAction<string>) => {
        set(state, 'searchValue', action.payload);
        set(state, 'choices', []);
        set(state, 'fetchData', {});
        set(state, 'status', BaseLoadingStatus.loading);
    },
    /**
     * Action to only change the search value, which allows to control all the other props
     * on the state, unlike `setSearchValue`
     */
    onlySetSearchValue: (state: GenericPickerState, action: PayloadAction<string>) => {
        set(state, 'searchValue', action.payload);
    },
    /**
     * Action to set the selected choice.
     * Will also close the choices.
     */
    setSelectedChoice: (state: GenericPickerState, action: PayloadAction<string>) => {
        set(state, 'displayedValue', action.payload);
        set(state, 'isOpen', false);
    },
    /**
     * Action when the choice fetch is successfull.
     *
     * Will either set the items in payload as choices
     * or append them if the status is at "loadingMore".
     * It will also store the fetchData and set the status as idle.
     */
    onFetchSuccess: (state: GenericPickerState, action: PayloadAction<ServerListResponse>) => {
        const { items, ...fetchdata } = action.payload;
        set(state, 'choices', state?.status === BaseLoadingStatus.loadingMore ? [...state.choices, ...items] : items);
        set(state, 'fetchData', fetchdata);
        set(state, 'status', BaseLoadingStatus.idle);
    },
    /**
     * Action on fetch failure.
     * Will set the status as error.
     */
    onFetchFailure: (state: GenericPickerState) => {
        set(state, 'status', BaseLoadingStatus.error);
    },
    /**
     * Resets all values to initial state
     */
    reset: (state: GenericPickerState) => {
        Object.assign(state, initialState);
    },
    /**
     * Action that unnsets selected choice and empties the search value.
     */
    unsetSelectedChoice: (state: GenericPickerState) => {
        set(state, 'displayedValue', initialState.displayedValue);
        set(state, 'searchValue', initialState.searchValue);
    },
};

const { actions, reducer, domain } = createSlice({
    domain: 'genericPicker',
    initialState,
    reducers,
});

export { actions, reducer, domain, initialState };
