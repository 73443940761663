import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';

export interface UseFilePickerProps {
    /** Function to upload from computer. */
    onSelectFiles(files: File[]): void;
    /** Ability to select multiple files. */
    multiple?: boolean;
    /** Whether attachments are disabled. */
    disabled?: boolean;
    /**
     * Filter for what file types the user can pick from the file input dialog box
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept#unique_file_type_specifiers
     */
    accept?: string;
    /** to identify to whitch component the input is linked (data-id) */
    inputOrigin?: string;
    /** additional props to be passed into the hidden input */
    inputProps?: Partial<React.InputHTMLAttributes<HTMLInputElement>>;
}

/**
 *
 * Hook to manage file picking pattern.
 * Returns a hidden input field.
 *
 * TODO: IMPROVE A11Y. (see https://lumapps.atlassian.net/browse/AU-192)
 */
export const useFilePicker = ({
    accept = '*',
    multiple = false,
    onSelectFiles,
    inputOrigin,
    inputProps,
}: UseFilePickerProps) => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const { get } = useDataAttributes('file-picker');

    const openPicker = React.useCallback(() => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []);

    const hiddenInput = React.useMemo(() => {
        return (
            <input
                onChange={(e) => {
                    const files = e.target.files && Array.from(e.target.files);
                    if (files) {
                        onSelectFiles(files);
                    }
                }}
                type="file"
                accept={accept}
                multiple={multiple}
                hidden
                ref={fileInputRef}
                value=""
                {...inputProps}
                {...get({ element: inputOrigin ? `${inputOrigin}-input` : 'input' })}
            />
        );
    }, [accept, multiple, inputProps, get, inputOrigin, onSelectFiles]);

    return { openPicker, hiddenInput };
};
