import React, { useEffect, useState, useRef } from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import { mdiPencil, mdiLinkVariantOff } from '@lumapps/lumx/icons';
import { Text, IconButton, Emphasis, FlexBox, Orientation, Alignment } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WREX_LINK } from '../../../../keys';
import { LinkElement } from '../../../../types';
import { Link } from '../../../blocks/Link';

import './index.scss';

interface Props {
    linkElement: LinkElement;
    onEdit(): void;
    onDelete(): void;
}

const CLASSNAME = 'wrex-link-editable';
export const LinkPreview: React.FC<Props> = ({ linkElement, onEdit, onDelete }) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const [hostName, setHostname] = useState<string | undefined>('');
    const linkRef = useRef<HTMLAnchorElement>(null);
    useEffect(() => {
        if (linkElement.href?.startsWith('mailto:')) {
            setHostname(linkElement.href.substring(7));
        } else {
            setHostname(linkRef.current?.hostname);
        }
    }, [linkRef, linkElement.href]);

    return (
        <FlexBox
            orientation={Orientation.horizontal}
            hAlign={Alignment.center}
            className={element('container', [padding('all', 'tiny')])}
        >
            <Text as="span" truncate className={element('url', [margin('horizontal')])}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link elementRef={linkRef} element={linkElement} target="_blank">
                    {hostName}
                </Link>
            </Text>
            <IconButton
                icon={mdiPencil}
                emphasis={Emphasis.low}
                onClick={onEdit}
                label={translateKey(GLOBAL.EDIT)}
                hasBackground
            />
            <IconButton
                onClick={onDelete}
                icon={mdiLinkVariantOff}
                emphasis={Emphasis.low}
                label={translateKey(WREX_LINK.LINK_REMOVE)}
                hasBackground
            />
        </FlexBox>
    );
};
LinkPreview.displayName = 'LinkPreview';
