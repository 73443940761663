import { Editor, NodeEntry, Point, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import type { TableEditor, TableElement, TDElement, TRElement } from '../types';
import { addColumn } from './addColumn';
import { addRow } from './addRow';

/**
 * Insert a table into another, given a start cell position.
 * If the inserted table is too large to be inserted in the target table,
 * the target table will be extended in order to receive the whole inserted table.
 *
 * @param editor The target editor.
 * @param insertedTable The table to insert
 * @param targetTableEntry The NodeEntry of the target table.
 * @param targetCellEntry The NodeEntry of the target cell (in the targetted table).
 */
export const insertTableInTable = (
    editor: ReactEditor & TableEditor,
    insertedTable: TableElement,
    targetTableEntry: NodeEntry<TableElement>,
    targetCellEntry: NodeEntry<TDElement>,
) => {
    const targetTableLastRowIndex = targetTableEntry[0].children.length - 1;
    const targetTableLastColIndex = (targetTableEntry[0].children[0] as TRElement).children.length - 1;

    const insertedTableHeight = insertedTable.children.length;
    const insertedTableWidth = (insertedTable.children[0] as TRElement).children.length;

    /** The start cell y coordinate */
    const startRowIndex = targetCellEntry[1][1];
    /** The start cell x coordinate */
    const startColIndex = targetCellEntry[1][2];

    /** The number of columnss to add, in case the target table is not large enough to receive the whole inserted table (minimum 0). */
    const nbColsToAdd = Math.max(0, startColIndex + insertedTableWidth - 1 - targetTableLastColIndex);

    /** The number of rows to add, in case the target table is not high enough to receive the whole inserted table (minimum 0). */
    const nbRowsToAdd = Math.max(0, startRowIndex + insertedTableHeight - 1 - targetTableLastRowIndex);
    // Add as many columns as needed to receive the whole inserted table
    for (let index = 0; index < nbColsToAdd; index++) {
        // We add the new columns at the end of the table.
        addColumn(editor, 'after', {
            at: [...targetTableEntry[1], targetTableLastRowIndex, targetTableLastColIndex],
            preventFocus: true,
        });
    }

    // Add as many rows as needed to receive the whole inserted table
    for (let index = 0; index < nbRowsToAdd; index++) {
        // We add the new rows at the end of the table.
        addRow(editor, 'after', {
            at: [...targetTableEntry[1], targetTableLastRowIndex, targetTableLastColIndex],
            preventFocus: true,
        });
    }

    // We are going to go through each cells to replace, and replace its content
    // We need to batch the replacement of the cells to avoid invalid transition states that will trigger the normalization.
    Editor.withoutNormalizing(editor, () => {
        for (let i = 0; i < insertedTableHeight; i++) {
            for (let j = 0; j < insertedTableWidth; j++) {
                // We simply remove the current cell in the target table
                Transforms.delete(editor, {
                    at: [...targetTableEntry[1], startRowIndex + i, startColIndex + j],
                });

                // And replace it with the corresponding cell from the insertedTable
                Transforms.insertNodes(editor, (insertedTable.children[i] as TRElement).children[j], {
                    at: [...targetTableEntry[1], startRowIndex + i, startColIndex + j],
                });
            }
        }
    });

    const newPosition: Point = {
        offset: 0,
        path: [
            ...targetTableEntry[1],
            startRowIndex + insertedTableHeight - 1,
            startColIndex + insertedTableWidth - 1,
            0,
            0,
        ],
    };

    // We put the focus at the end cell of the inserted table.
    focusAt(editor, newPosition);
};
