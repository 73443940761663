import { BaseApiError } from '@lumapps/base-api';
import { type UseQueryOptions, useQuery } from '@lumapps/base-api/react-query';

import { getIframe, videoPlayerQueryKeys } from '../../api/stream';
import type { GetIframeParamsDTO, GetIframeResponseDTO, VideoId } from '../../api/stream/types';

export interface UseIframeProps extends GetIframeParamsDTO {
    /** Video id */
    videoId: VideoId;
}

export function useIframe(
    { videoId, ...iframeParams }: UseIframeProps,
    options?: UseQueryOptions<
        GetIframeResponseDTO,
        BaseApiError,
        GetIframeResponseDTO,
        ReturnType<typeof videoPlayerQueryKeys.iframe>
    >,
) {
    const params = { controls: true, fit: 'contain', ...iframeParams } as const;

    const query = useQuery({
        ...options,
        queryKey: videoPlayerQueryKeys.iframe(videoId, params),
        queryFn: async ({ signal }) => getIframe(videoId, params, signal),
    });

    return query;
}
