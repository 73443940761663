import isEmpty from 'lodash/isEmpty';

import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { LegacyVideoProperties, BlockVideoSource } from '../types';

const ALLOWED_HTML_TAGS = ['iframe'];
const ALLOWED_ATTRIBUTES = [
    'height',
    'width',
    'src',
    'title',
    'frameborder',
    'allow',
    'allowfullscreen',
    'referrerpolicy',
    'sandbox',
];

export const useEmbedVideoHTML = (properties: LegacyVideoProperties): string | null => {
    const { translateObject } = useTranslate();
    const currentLanguage = useSelector(currentLanguageSelector);

    const { embed, noFallback = false, source } = properties;

    if (source !== BlockVideoSource.embed || !embed || isEmpty(embed)) {
        return null;
    }

    const rawHTML =
        embed && (!noFallback || embed[currentLanguage]) ? translateObject(standardizeTranslateObject(embed)) : null;

    return rawHTML ? sanitizeHTML(rawHTML, { allowedTags: ALLOWED_HTML_TAGS, allowedAttr: ALLOWED_ATTRIBUTES }) : null;
};
