/* istanbul ignore file */
/* eslint-disable import/no-mutable-exports */
import noop from 'lodash/noop';

let reloadPostList = noop;

/**
 * Binds functions to angular `Translation` service.
 *
 * @param {Translation} translations The angular translation service.
 */
function setPostService(post: any) {
    reloadPostList = post.reloadPostList;
}

export { setPostService, reloadPostList };
