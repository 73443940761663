import { isUserAllowed, MATCHES_METHODS } from '@lumapps/permissions';

import { NEWSLETTER_PERMISSIONS } from '../permissions';

const isNewsletterAllowed = isUserAllowed(
    [NEWSLETTER_PERMISSIONS.NEWSLETTER_EDIT, NEWSLETTER_PERMISSIONS.NEWSLETTER_DELETE],
    { matches: MATCHES_METHODS.SOME },
);

export { isNewsletterAllowed };
