import flow from 'lodash/flow';
import filter from 'lodash/fp/filter';

import { and } from '@lumapps/utils/function/predicate/and';
import { not } from '@lumapps/utils/function/predicate/not';
import { ElementConvertOption, MarkConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { hasLength, matchAttribute, matchNode } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { isAttachmentsTopic } from '@lumapps/wrex/serialization/dita/types';

import { COLORED } from '../../constants';
import { createCodeBlock } from '../../utils/createCodeBlock';
import { createHeadline } from '../../utils/createHeadline';
import { createInlineCode } from '../../utils/createInlineCode';
import { createParagraph } from '../../utils/createParagraph';
import { createSubTitle } from '../../utils/createSubTitle';
import { createTitle } from '../../utils/createTitle';

/**
 * Convert Dita P into slate paragraph element.
 */
export const PARAGRAPH_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'p' }),
    transform: (node, children) => createParagraph(children, node.attributes?.outputclass?.substring(6)),
};

/**
 * Convert Dita H1 into slate Headline element.
 */
export const HEADLINE_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'title' }),
    testParents: flow(
        filter(
            and(
                matchNode({ name: 'topic' }),
                // Not an attachments topic
                not(isAttachmentsTopic),
            ),
        ),
        hasLength(1),
    ),
    transform: (node, children) => createHeadline(children, node.attributes?.outputclass?.substring(6)),
};

/**
 * Convert Dita H2 into slate Title element.
 */
export const TITLE_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'title' }),
    testParents: flow(filter(matchNode({ name: 'topic' })), hasLength(2)),
    transform: (node, children) => createTitle(children, node.attributes?.outputclass?.substring(6)),
};

/**
 * Convert Dita H3 into slate SubTitle element.
 */
export const SUBTITLE_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'title' }),
    testParents: flow(filter(matchNode({ name: 'topic' })), hasLength(3)),
    transform: (node, children) => createSubTitle(children, node.attributes?.outputclass?.substring(6)),
};

/**
 * Convert Dita bold (in various form) into slate bold mark.
 */
export const BOLD_CONVERT: MarkConvertOption = {
    test: matchNode({ name: 'b' }),
    mark: 'bold',
};

/**
 * Convert Dita italic (in various form) into slate italic mark.
 */
export const ITALIC_CONVERT: MarkConvertOption = {
    test: matchNode({ name: 'i' }),
    mark: 'italic',
};

/**
 * Convert Dita underline (in various form) into slate underline mark.
 */
export const UNDERLINE_CONVERT: MarkConvertOption = {
    test: matchNode({ name: 'u' }),
    mark: 'underline',
};

/**
 * Convert Dita strike (in various form) into slate strike mark.
 */
export const STRIKETHROUGH_CONVERT: MarkConvertOption = {
    test: matchNode({ name: 'line-through' }),
    mark: 'strikethrough',
};

/**
 * Convert Dita codeph (in various form) into slate inline code element.
 */
export const CODE_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'codeph' }),
    transform: (node, children) => createInlineCode(children),
};

/**
 * Convert Dita codeblock into slate CodeBlock element.
 */
export const CODE_BLOCK_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'codeblock' }),
    transform: (node, children) => createCodeBlock(children),
};

/**
 * Convert Dita colored text into slate colored mark.
 */
export const COLORED_TEXT_CONVERT: MarkConvertOption = {
    test: and(matchNode({ name: 'ph' }), matchAttribute('outputclass', /^color-([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/)),
    mark: COLORED,
    markValue: (node) => `#${node.attributes?.outputclass.substring(6)}`,
};
