import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../types';
import * as tabTypes from '../../ui/tabs/types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { MARGIN_FIELDS, PADDING_FIELDS, POSITIVE_SIZE_CHOICES, SIZE_CHOICES } from '../constants';
import { Tab, Tabs } from '../../ui';
import { Measures } from './Measures';

/**
 * Renders a form that edits outer/inner spacing attributes.
 */
export class SpacingSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.spacingStyle.isRequired,
        /** Define if the margin tab is hidden */
        hideMarginTab: propTypes.bool,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Called when the current state style changes with `(state)`. */
        onChangeState: propTypes.func,
        /** Current state style. */
        state: propTypes.string,
        /** Style states. */
        states: tabTypes.items,
        /** Current spacing style. */
        value: styleTypes.spacingStyle,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { value, name: key, onChange, defaults, state, states, onChangeState, hideMarginTab } = this.props;

        return (
            <Tabs state={state} states={states} onChangeState={onChangeState}>
                {!hideMarginTab && (
                    <Tab id="margin" label={t('STYLE.MARGIN')}>
                        <Measures
                            value={value}
                            name={key}
                            onChange={onChange}
                            choices={SIZE_CHOICES}
                            field="margin"
                            fields={MARGIN_FIELDS}
                            defaults={defaults}
                            hasClearChoice
                            suffix="px"
                        />
                    </Tab>
                )}
                <Tab id="padding" label={t('STYLE.PADDING')}>
                    <Measures
                        value={value}
                        name={key}
                        onChange={onChange}
                        choices={POSITIVE_SIZE_CHOICES}
                        field="padding"
                        fields={PADDING_FIELDS}
                        defaults={defaults}
                        hasClearChoice
                        suffix="px"
                    />
                </Tab>
            </Tabs>
        );
    }
}
