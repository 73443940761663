import { Coordinates, MapPlace } from './types';

/**
 * Get the embed map url for google map directions.
 *
 * @param  {Coordinates} coordinates  The map coordinates (lat, lon and places).
 * @param  {string} locale  The current user locale.
 * @return {string} The created embed map url.
 */
export const getPlacesEmbedUrl = (coordinates: Coordinates, locale: string) => {
    const { lon, lat, place } = coordinates;
    const places = place as MapPlace[];

    if (places.length >= 2) {
        const numberToAdd = places.length * 6;

        const baseUrl = `https://www.google.com/maps/embed?pb=!1m${
            16 + numberToAdd
        }!1m12!1m3!1d22269.344065016696!2d${lat}!3d${lon}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m${
            1 + numberToAdd
        }!3e0`;

        const embedUrl = places.reduce((url, place) => {
            return url.concat(`!4m5!1s${place.data}!2s${place.name}!3m2!1d${place.lat}!2d${place.lon}`);
        }, baseUrl);

        return embedUrl.concat(`!5e0!3m2!1s${locale}!2sfr!4v1651837320908!5m2!1s${locale}!2sfr`);
    }

    return undefined;
};
