(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileActivityActionController(Config, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The action description.
         *
         * @type {string}
         */
        vm.actionTypeDescription = undefined;

        /**
         * The activity publication date.
         * If less than 2 days, from now, else, full date.
         *
         * @type {string}
         */
        vm.activityDate = undefined;

        /**
         * The detailed activity about the current action.
         *
         * @type {Object}
         */
        vm.activityDetails = undefined;

        /**
         * The content type description.
         *
         * @type {string}
         */
        vm.contentTypeDescription = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Config = Config;
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            if (angular.isDefinedAndFilled(vm.action.items)) {
                vm.activityDetails = vm.action.items[0];
            }

            if (moment().diff(moment(vm.activityDetails.createdAt), 'days') < 2) {
                vm.activityDate = moment.utc(vm.activityDetails.createdAt).local().fromNow();
            } else {
                vm.activityDate = moment.utc(vm.activityDetails.createdAt).local().format('LL');
            }

            var actionTypeDescription = 'GROUPED_ACTIVITY_ACTION_TYPE_' + vm.actionType.toUpperCase();
            if (vm.action.count > 1) {
                actionTypeDescription += '_PLURAL';
            }
            vm.actionTypeDescription = actionTypeDescription;

            if (angular.isDefinedAndFilled(vm.contentType)) {
                vm.contentTypeDescription = 'GROUPED_ACTIVITY_CONTENT_TYPE_' + vm.contentType.toUpperCase();
            }

            vm.hasContentLink = vm.contentType === 'post';
        }

        init();
    }

    function SocialProfileActivityActionDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: SocialProfileActivityActionController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                action: '=',
                actionType: '@',
                contentType: '@?',
                parentContentDetails: '=',
            },
            templateUrl: '/client/front-office/modules/social/profile/views/social-profile-activity-action.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('socialProfileActivityAction', SocialProfileActivityActionDirective);
})();
