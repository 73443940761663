import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { SHADOW_ELEVATION_CHOICES } from '../constants';
import { Input, Select, TextField } from '../../ui';
import { withOnChangeProperty } from '../../../utils';

/**
 * Renders a form that edits shadow settings.
 */
export class ShadowSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.shadowStyle.isRequired,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Current shadow style. */
        value: styleTypes.shadowStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { value = {}, defaults } = this.props;

        return (
            <div flex-container="row" flex-gutter="16" className="p+">
                <div flex-item="flex-item">
                    <TextField label={t('STYLE.ELEVATION')}>
                        <Select
                            value={value.shadowElevation}
                            name="shadowElevation"
                            choices={SHADOW_ELEVATION_CHOICES}
                            placeholder={defaults.shadowElevation}
                            onChange={this.onChangeProperty}
                            hasClearChoice
                        />
                    </TextField>
                </div>
                <div flex-item="flex-item">
                    <TextField label={t('STYLE.OPACITY')} suffix="%">
                        <Input
                            value={value.shadowOpacity}
                            name="shadowOpacity"
                            onChange={this.onChangeProperty}
                            type="number"
                            min="0"
                            max="100"
                            placeholder={defaults.shadowOpacity}
                            revivePlaceholder
                        />
                    </TextField>
                </div>
            </div>
        );
    }
}
