import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiClose, mdiPound } from '@lumapps/lumx/icons';
import { Chip, Icon, Size, Theme } from '@lumapps/lumx/react';
import { Route, useLinkProps } from '@lumapps/router';

import { TAGZ_CONTEXT_NAME } from '../../constants';
import { useSearchRoutingData } from '../../hooks/useSearchRouterData';
import * as types from '../../types';

export interface TagProps {
    /** Individual tag */
    tag: types.Tag;
    /**  Determines whether the tag can be edited or not. */
    isEditable?: boolean;
    /** Determines whether the tag is highlighted or not. */
    isHighlighted?: boolean;
    /** the position in the tag list */
    position?: number;
    /** current theme to apply */
    theme?: Theme;
    /** route to redirect to */
    to?: Route;
    /** Callback to be executed when the tag was cleared. */
    onClear?: (event: React.MouseEvent, tag: types.Tag) => void;
    /** Callback to be executed when the tag was clicked. */
    onClick?: (event: React.MouseEvent, tag: types.Tag) => void;
    /** Callback executed when the user focuses on the tag. */
    onFocus?(): void;
    /** Callback executed when the user blurs out the tag. */
    onBlur?(): void;
}

/**
 * Displays an individual Tag
 * */
const CLASSNAME = 'tag';
const Tag: React.FC<TagProps> = ({
    tag,
    isEditable = false,
    onClear,
    onClick,
    isHighlighted = false,
    position,
    theme,
    to,
    onFocus,
    onBlur,
}) => {
    const { block } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(TAGZ_CONTEXT_NAME);
    const { routerData } = useSearchRoutingData({ id: 'tag' });
    const linkProps = useLinkProps({ route: to, routerData });

    const editableProps = {
        after: <Icon icon={mdiClose} size={Size.xxs} />,
        onAfterClick: (evt: React.MouseEvent) => onClear && onClear(evt, tag),
    };

    const onChipClick = onClick ? (evt: React.MouseEvent) => onClick(evt, tag) : undefined;

    return (
        <Chip
            className={block(to ? 'link' : '')}
            isHighlighted={isHighlighted}
            size={Size.s}
            onClick={onChipClick}
            before={<Icon icon={mdiPound} size={Size.xxs} />}
            onFocus={onFocus}
            onBlur={onBlur}
            theme={theme}
            {...(isEditable ? editableProps : {})}
            {...linkProps}
            {...get({ element: tag.id, position })}
        >
            {tag.label}
        </Chip>
    );
};

export { Tag };
