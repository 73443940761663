import BaseApi from '@lumapps/base-api';

import { UserContent } from '../types';

const path = 'usercontent';
const userContentApi = new BaseApi({ path });

/** Get `usercontent` for the current user in the given content */
export async function get(contentId: string) {
    try {
        const { data } = await userContentApi.get<UserContent>('get', { params: { content: contentId } });
        return data;
    } catch (e) {
        return {};
    }
}

/** Set `usercontent` for the current user in the given content */
export async function save(userContent: UserContent) {
    const { data } = await userContentApi.post<UserContent>('save', userContent);
    return data;
}

/** React-query keys */
export const queryKeys = {
    get: (contentId: string) => [path, contentId],
};
