import React from 'react';

import { CONTENT_STREAMS } from '@lumapps/content-streams/keys';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { contentPage } from '@lumapps/lumx/custom-icons';
import { useTranslate } from '@lumapps/translations';

import { contentStreamsAdmin } from '../../routes';

export interface ContentStreamsSideNavigationProps {
    isAllowed: boolean;
}

export const ContentStreamsSideNavigation = ({ isAllowed }: ContentStreamsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(CONTENT_STREAMS.CONTENT_STREAM)}
            icon={contentPage}
            route={contentStreamsAdmin()}
        />
    );
};

ContentStreamsSideNavigation.displayName = 'SideNavigationItem';
