export const REGULAR_SPACE = ' ';
/**
 * This may seem like a regular space symbol, but it's not.
 * It's actually a special japanese space.
 */
export const JAPANESE_SPACE = '　';
/**
 * This pattern include REGULAR_SPACE and JAPANESE_SPACE
 */
export const ALL_SPACES_PATTERN = ' 　';

export const FORBIDDEN_SLUG_CHARACTERS = [
    ':',
    '/',
    '?',
    '#',
    '[',
    ']',
    '@',
    '!',
    '$',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    ';',
    '=',
    '.',
    ' ',
];

export const FORBIDDEN_SLUG_CHARACTERS_REGEXP = new RegExp(`[${FORBIDDEN_SLUG_CHARACTERS.join('\\')}]+`, 'g');

/**
 * Regex to detect if a string includes a `@` mention.
 * Example of matching texts:
 * `@foo`
 * `test @foo`
 * `test @foo something`
 *
 * If the `@` mention is joined to another string (something@else), the regex won't match
 */
export const AT_MENTION_REGEXP = /(?:(?:\s@)|^@)([^\s]+)/;
