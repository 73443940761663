import React from 'react';

import { classnames, margin, padding } from '@lumapps/classnames';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { mdiBullhorn } from '@lumapps/lumx/icons';
import {
    Size,
    Dialog,
    Button,
    Toolbar,
    FlexBox,
    Orientation,
    Alignment,
    Emphasis,
    Autocomplete,
    List,
    ListItem,
    Text,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { ProgramSelectionModalStatus, ProgramIdsByName } from '../../ducks/ProgramSelectionModal/slice';
import { SA_PROGRAMS } from '../../keys';

export interface ProgramSelectionModalProps {
    // Whether the modal is closed or open
    isOpen: boolean;
    // Whether the modal is loading, loaded, or has errors
    modalStatus: ProgramSelectionModalStatus;
    // The list of program Ids to select within
    programIds: string[];
    // The dictionnary of ids by their names. Name is the key bc it's faster than the opposite as we search by name.
    programIdsByName: ProgramIdsByName;
    // The searchValue shown in the Autocomplete bar
    searchValue: string;
    // The selected value
    value: string;
    // The function to fetch the Program names
    fetchProgramsByIds(programIds: string[]): any;
    // The function to set the search value in the Autocomplete bar
    setValue(value: string): void;
    // The function to set the value in the Autocomplete bar
    setSearchValue(value: string): void;
    // The callback when a program has been selected
    onProgramSelected(programId: string): void;
    // The callback when the user cancels
    onCancel(): void;
}

const CLASSNAME = 'program-selection-modal';

export const ProgramSelectionModal: React.FC<ProgramSelectionModalProps> = ({
    modalStatus,
    programIds,
    programIdsByName,
    value,
    searchValue,
    setValue,
    setSearchValue,
    fetchProgramsByIds,
    onProgramSelected,
    onCancel,
    isOpen,
}) => {
    const { translateKey } = useTranslate();

    // Whether we should show the suggestion list
    const [showSuggestions, setShowSuggestions] = React.useState(false);

    // Used to control the text input
    const inputRef = React.useRef<HTMLInputElement>(null);

    // When programIds changes, load the program names from backend and set the dictionary.
    React.useEffect(() => {
        if (isOpen && modalStatus === ProgramSelectionModalStatus.initial) {
            fetchProgramsByIds(programIds);
        }
    }, [programIds, modalStatus, fetchProgramsByIds, isOpen]);

    // When a program is selected, close the suggestion list
    const selectItem = (item: string) => {
        if (value === item) {
            setValue('');
            setSearchValue('');
        } else {
            setValue(item);
            setSearchValue(item);
        }
        setShowSuggestions(false);
    };

    const handleProgramSelected = () => {
        setValue('');
        setSearchValue('');
        setShowSuggestions(false);
        onProgramSelected(programIdsByName[value]);
    };

    const handleCancel = () => {
        setValue('');
        setSearchValue('');
        setShowSuggestions(false);
        onCancel();
    };

    const handleChangeSearch = (val: string) => {
        if (val === '') {
            setValue('');
        }
        setSearchValue(val);
    };

    // Automatically focus in the searchBar to open the suggestions. We setTimout() to wait for the dialog to be fully
    // opened, or else the suggestion list will be opened to early and misplaced because of the animation.
    const focusOnModalOpen = () => {
        setTimeout(() => {
            if (inputRef?.current?.focus && isOpen) {
                inputRef.current.focus();
            }
        }, 350);
    };

    // The list of programs that corresponding to the search text
    const filteredItems = Object.keys(programIdsByName).filter(
        (programName: string) => programName?.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1,
    );

    // Allows the user to navigate the list using keyboard
    const { activeItemIndex } = List.useKeyboardListNavigation(
        filteredItems,
        inputRef,
        selectItem,
        undefined, // onListItemNavigated
        undefined, // onEnterPressed
        undefined, // onBackspacePressed
        undefined, // keepFocusAfterSelection
        0, // initialIndex
    );

    return (
        <Dialog
            className={CLASSNAME}
            isOpen={isOpen}
            isLoading={modalStatus === ProgramSelectionModalStatus.loading}
            size={Size.tiny}
            preventAutoClose
            onOpen={focusOnModalOpen}
        >
            <Toolbar
                label={
                    <Text as="span" typography="title">
                        {translateKey(SA_PROGRAMS.SELECT_A_PROGRAM)}
                    </Text>
                }
            />

            {modalStatus === ProgramSelectionModalStatus.error ? (
                <FlexBox
                    className={classnames('program-form-step-error', padding('vertical', 'huge'))}
                    orientation={Orientation.vertical}
                    vAlign={Alignment.center}
                    hAlign={Alignment.center}
                >
                    <Text as="p" typography="body2" color="dark" colorVariant="L3" className={margin('bottom', 'tiny')}>
                        {translateKey(SA_PROGRAMS.UNAVAILABLE)}
                    </Text>
                    <Text as="p" typography="body2" color="dark" colorVariant="L3" className={margin('bottom', 'tiny')}>
                        {translateKey(GLOBAL.TRY_RELOAD)}
                    </Text>
                </FlexBox>
            ) : (
                <div className={padding('horizontal', 'huge')}>
                    <Autocomplete
                        value={searchValue}
                        isOpen={showSuggestions}
                        onChange={handleChangeSearch}
                        onFocus={() => setShowSuggestions(true)}
                        onClose={() => {
                            setSearchValue(value);
                            setShowSuggestions(false);
                        }}
                        label={translateKey(SA_PROGRAMS.SELECT_A_PROGRAM)}
                        clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                        closeOnClick={false}
                        closeOnEscape
                        icon={mdiBullhorn}
                        inputRef={inputRef}
                    >
                        {modalStatus === ProgramSelectionModalStatus.loaded &&
                            (filteredItems.length > 0 ? (
                                <List isClickable>
                                    {filteredItems.map((programName, index) => (
                                        <ListItem
                                            isSelected={value === programName}
                                            isHighlighted={index === activeItemIndex}
                                            key={programName}
                                            size={Size.tiny}
                                            onItemSelected={() => selectItem(programName)}
                                        >
                                            {programName}
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                searchValue !== '' && (
                                    <NoResultsState
                                        searchValue={searchValue}
                                        onClear={() => {
                                            setSearchValue(value);
                                        }}
                                    />
                                )
                            ))}
                    </Autocomplete>
                </div>
            )}

            <FlexBox className={padding('all', 'huge')} orientation={Orientation.horizontal} vAlign={Alignment.right}>
                <Button onClick={handleCancel} emphasis={Emphasis.medium} className={margin('right')}>
                    {translateKey(GLOBAL.CANCEL)}
                </Button>
                <Button onClick={handleProgramSelected} disabled={programIdsByName[value] === undefined}>
                    {translateKey(SA_PROGRAMS.SELECT_PROGRAM)}
                </Button>
            </FlexBox>
        </Dialog>
    );
};
