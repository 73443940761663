import isEmpty from 'lodash/isEmpty';
import { Editor, Path } from 'slate';

import { isTable } from '@lumapps/wrex-table/utils/isTable';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';

import { findParent } from './findParent';

/**
 * Check if specified path is empty (empty text and no node)
 */
export function isSelectionEmpty(editor: Editor, path?: Path): boolean | null {
    if (!path) {
        return null;
    }

    const [nodeOnSelection] = findParent(editor, path) as any;
    const tableNode = findParent(editor, path, isTable);

    if (!nodeOnSelection) {
        return true;
    }

    const isSelectedNodeFilled =
        !isParagraph(nodeOnSelection) ||
        !!tableNode ||
        nodeOnSelection.children.some((element: any) => !isEmpty(element.text) || element.type);

    return !isSelectedNodeFilled;
}
