import React, { useEffect } from 'react';

import { Leaderboard } from '@lumapps/leaderboard/components/Leaderboard';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { LeaderboardProps } from '../../types';

export const ClientComputedLeaderboard: React.FC<LeaderboardProps> = ({ properties = {}, uuid }) => {
    const dispatch = useDispatch();
    const LearningEnabled = useSelector(isLearningEnabled);

    useEffect(() => {
        if (!LearningEnabled) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [LearningEnabled, dispatch, uuid]);
    if (!LearningEnabled) {
        return null;
    }
    return (
        <Leaderboard
            groups={properties?.groups || []}
            displayType={properties?.displayType}
            isSpaceDetectedFromLegacyApp={properties.isSpaceDetectedFromLegacyApp}
            spaceId={properties?.spaceId}
        />
    );
};
