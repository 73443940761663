import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import React, { PureComponent } from 'react';
import classNames from "classnames";

import { widgetLinksTypes } from './types';
import { getInputLanguage, getLang, translate as t } from '../../../translations';

import { isDesignerMode } from '../../../utils';
import { LinkBlock } from './LinkBlock';

/**
 * Compute the list of links from items in properties.
 * Translate the title, link and description and remove non translated links.
 *
 * @param  {Array}   items      The list of links.
 * @param  {boolean} noFallback Whether we should disable fallback.
 * @return {Array}   The filtered list of links with translated properties.
 */
const computeLinks = ({ items, noFallback }) => {
    const currentLang = noFallback ? getLang('current') : undefined;
    const lang = isDesignerMode() ? getInputLanguage() : currentLang;

    const links = map(items, (link) => {
        const translatedLink = t(link.link, undefined, lang);
        const translatedTitle = t(link.title, undefined, lang);
        const translatedDescription = t(link.description, undefined, lang);

        return {
            description: translatedDescription,
            link: translatedLink,
            targetBlank: link.targetBlank,
            thumbnail: link.thumbnail,
            title: translatedTitle,
        };
    });

    return filter(links, (link) => link.link !== '' && link.title !== '');
};

/**
 * Display the widget links.
 */
export class WidgetLinks extends PureComponent {
    static propTypes = widgetLinksTypes;

    /**
     * Defines this widget as editable.
     *
     * @return {boolean} False.
     */
    static isEditable() {
        return true;
    }

    /**
     * Defines whether the widget is empty or not.
     *
     * @param  {Array}   value The current widget properties.
     * @return {boolean} Whether the list of links is empty or not in the current lang.
     */
    static isWidgetEmpty({ value }) {
        const { items, noFallback } = value;

        return computeLinks({ items, noFallback }).length === 0;
    }

    /**
     * Determines whether the widget is hiddentor not.
     *
     * @param  {Object}  params The widget props.
     * @return {boolean} Whether the widget is hidden or not.
     */
    static isWidgetHidden(params) {
        return WidgetLinks.isWidgetEmpty(params);
    }

    state = {
        links: undefined,
        hasThumbnail: true
    };

    componentDidMount() {
        this.initList();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props, prevProps)) {
            this.initList();
        }
    }

    /**
     * Initialisze the list of links.
     * Translate the link, title and description with current lang.
     */
    initList() {
        const { value } = this.props;
        const { items, noFallback, hasThumbnail = true } = value;

        const links = computeLinks({ items, noFallback });

        this.setState({
            links,
            hasThumbnail,
        });
    }

    render() {
        const { links, hasThumbnail } = this.state;

        return (
            <ul className={classNames('widget-links__list', {'widget-links__list--no-thumbnail': !hasThumbnail})}>
                {map(links, (link, index) => (
                    <LinkBlock key={index} link={link} hasThumbnail={hasThumbnail} />
                ))}
            </ul>
        );
    }
}
