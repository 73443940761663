(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserFavoriteFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/user/favorite/:methodKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'destroy',
                },
            },
            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'add',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('UserFavoriteFactory', UserFavoriteFactory);
})();
