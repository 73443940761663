import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import loFind from 'lodash/find';

(function IIFE() {
    /////////////////////////////

    function WidgetCalendarSettingsController(
        $rootScope,
        Calendar,
        ConfigTheme,
        Content,
        InitialSettings,
        LxDialogService,
        LxNotificationService,
        Style,
        Translation,
        Utils,
        Widget,
    ) {
        'ngInject';

        const widgetCalendarSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Debounce delay for search requests.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        const _DEBOUNCE_DELAY = 300;

        /**
         * The maximum number of calendars to get for each request.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        const _MAX_REQUEST_RESULTS = 50;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the add calendar dialog.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        widgetCalendarSettings.DIALOG_ID = 'add-calendar-dialog';

        /**
         * The list key to get the list of calendars.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        widgetCalendarSettings.LIST_KEY = 'calendar-widget';

        /**
         * The list of view mode available in the calendar widget.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        widgetCalendarSettings.VIEW_MODE = {
            MONTH: 'month',
            PLANNING: 'planning',
            WEEK: 'week',
        };

        /**
         * The list of calendars that can be selected.
         *
         * @type {Array}
         */
        widgetCalendarSettings.availableCalendars = [];

        /**
         * During the selection updating, disable the select.
         *
         * @type {boolean}
         */
        widgetCalendarSettings.calendarsSelectionIsUpdating = false;

        /**
         * The selection state of the user calendar in the add calendar dialog.
         *
         * @type {boolean}
         */
        widgetCalendarSettings.isCalendarUserChecked = false;

        /**
         * The selection state of the current community calendar in the add calendar dialog.
         *
         * @type {boolean}
         */
        widgetCalendarSettings.isCalendarCommunityChecked = false;

        /**
         * Indicates if the current calendar is in a Community context.
         * Used to display a switch to display or no the current community calendar.
         *
         * @type {boolean}
         */
        widgetCalendarSettings.isCommunityContext = false;

        /**
         * Indicates if we want to shox the add clandar dialog in the DOM.
         *
         * @type {boolean}
         */
        widgetCalendarSettings.showCalendarDialog = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetCalendarSettings.Calendar = Calendar;
        widgetCalendarSettings.Colors = _.get(
            Style.getCurrent('global'),
            'properties.colors',
            ConfigTheme.COLORS_WIDGET,
        );
        widgetCalendarSettings.Content = Content;
        widgetCalendarSettings.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the first page of calendar list available to the current user.
         */
        function _getCalendarList() {
            widgetCalendarSettings.availableCalendars.length = 0;

            const queryParams = {
                maxResults: _MAX_REQUEST_RESULTS,
            };

            if (angular.isDefinedAndFilled(widgetCalendarSettings.calendarSearchText)) {
                queryParams.query = widgetCalendarSettings.calendarSearchText;
            }

            Calendar.filterize(
                queryParams,
                (calendars) => {
                    if (angular.isArray(calendars)) {
                        widgetCalendarSettings.availableCalendars.push(...calendars);
                    }
                },
                undefined,
                widgetCalendarSettings.LIST_KEY,
            );
        }

        /**
         * Enable calendars select and refresh widget.
         */
        function _refreshCalendarsSelection() {
            widgetCalendarSettings.calendarsSelectionIsUpdating = false;
            widgetCalendarSettings.updateWidget();
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a calendar to the displayed calendar list.
         */
        function addCalendar() {
            widgetCalendarSettings.showCalendarDialog = true;

            if (widgetCalendarSettings.isCommunityContext) {
                Utils.reject(widgetCalendarSettings.availableCalendars, {
                    id: Content.getCurrent().calendarId,
                });
            }

            Utils.waitForAndExecute(`#${widgetCalendarSettings.DIALOG_ID}`);
        }

        /**
         * Adds a public calendar.
         *
         * @param  {string} publicCalendarId The public calendar identifier.
         * @return {Object} The new public calendar to add to the list.
         */
        function addPublicCalendar(publicCalendarId) {
            const newPublicCalendar = {
                id: publicCalendarId,
                summary: publicCalendarId,
            };

            Calendar.get(
                {
                    uid: publicCalendarId,
                },
                function onCalendarGetSuccess(response) {
                    newPublicCalendar.summary = _.get(response, 'calendar.summary', publicCalendarId);
                },
            );

            return newPublicCalendar;
        }

        /**
         * Close de add calendar dialog.
         * Reset the selcted calednar list and the search filter.
         */
        function closeDialog() {
            widgetCalendarSettings.filters = {};

            angular.forEach(Calendar.displayList(widgetCalendarSettings.LIST_KEY), function forEachCalendar(calendar) {
                if (calendar.isChecked) {
                    calendar.isChecked = false;
                }
            });

            LxDialogService.close(widgetCalendarSettings.DIALOG_ID);
        }

        /**
         * Remove the calendar from displayed calendar list.
         *
         * @param {string} calendarId The calendar identifier to delete from the list.
         */
        function deleteCalendar(calendarId) {
            const currenWidget = Widget.getCurrent();

            if (calendarId === 'community') {
                currenWidget.properties.currentCommunityCalendar = false;
                widgetCalendarSettings.updateWidget();

                return;
            }

            if (calendarId === 'user') {
                currenWidget.properties.userCalendarChecked = false;
                widgetCalendarSettings.updateWidget();

                return;
            }

            currenWidget.properties.calendarsSelected = _.filter(
                currenWidget.properties.calendarsSelected,
                function filterCalendar(calendar) {
                    return calendar.id !== calendarId;
                },
            );

            widgetCalendarSettings.updateWidget();
        }

        /**
         * Get next page of calendar list.
         */
        function getNextCalendars() {
            if (!Calendar.hasMore(widgetCalendarSettings.LIST_KEY)) {
                return;
            }

            Calendar.nextPage(
                (calendars) => {
                    if (!angular.isArray(calendars)) {
                        return;
                    }

                    const newCalendars = filter(calendars, (calendar) => {
                        const { id } = calendar;

                        return !loFind(widgetCalendarSettings.availableCalendars, { id });
                    });
                    widgetCalendarSettings.availableCalendars.push(...newCalendars);
                },
                undefined,
                widgetCalendarSettings.LIST_KEY,
            );
        }

        /**
         * Indicates if the calendar is already displayed in the widget.
         *
         * @param  {string}  calendarId The calendar identifier.
         * @return {boolean} The presence of the calendar in the displayed calendar list.
         */
        function isAlreadyDisplayed(calendarId) {
            return _.includes(_.map(Widget.getCurrent().properties.calendarsSelected, 'id'), calendarId);
        }

        /**
         * Filters the available calendars to match the search filter.
         *
         * @param {string} [search=''] The search filter.
         */
        function _filterCalendars(search = '') {
            widgetCalendarSettings.calendarSearchText = search;
            _getCalendarList();
        }

        /**
         * Apply the selected calendars to the displayed calendars in the widget.
         */
        function setCalendars() {
            if (widgetCalendarSettings.calendarsSelectionIsUpdating) {
                return;
            }

            widgetCalendarSettings.calendarsSelectionIsUpdating = true;

            const currentCalendar = Widget.getCurrent();
            currentCalendar.properties = currentCalendar.properties || {};
            const { properties } = currentCalendar;
            let indexOffset = 0;

            if (widgetCalendarSettings.isCalendarUserChecked || properties.userCalendarChecked) {
                properties.userCalendarChecked = true;
                widgetCalendarSettings.isCalendarUserChecked = false;
                properties.userCalendarColor = properties.userCalendarColor || Utils.getItemColor(indexOffset);

                indexOffset++;
            }

            if (widgetCalendarSettings.isCalendarCommunityChecked || properties.currentCommunityCalendar) {
                properties.currentCommunityCalendar = true;
                widgetCalendarSettings.isCalendarCommunityChecked = false;
                properties.currentCommunityCalendarColor =
                    properties.currentCommunityCalendarColor || Utils.getItemColor(indexOffset);

                indexOffset++;
            }

            angular.forEach(Calendar.displayList(widgetCalendarSettings.LIST_KEY), function forEachCalendar(calendar) {
                if (calendar.isChecked) {
                    properties.othersCalendarsChecked = true;
                    properties.calendarsSelected.push(calendar);
                    calendar.isChecked = false;

                    calendar.color = calendar.color || Utils.getItemColor(indexOffset);

                    indexOffset++;
                }
            });
            _refreshCalendarsSelection();

            widgetCalendarSettings.calendarsSelectionIsUpdating = false;

            widgetCalendarSettings.filters = {};

            LxDialogService.close(widgetCalendarSettings.DIALOG_ID);
        }

        /**
         * Update Calendar selection.
         */
        function updateCalendarsSelection() {
            if (!widgetCalendarSettings.calendarsSelectionIsUpdating) {
                widgetCalendarSettings.calendarsSelectionIsUpdating = true;

                const newCalendar = _.last(Widget.getCurrent().properties.calendarsSelected);
                // Check if new item is an object (a user calendar) or a string (a public calendar).
                if (angular.isString(newCalendar)) {
                    // Remove Id from collection.
                    Widget.getCurrent().properties.calendarsSelected.pop();

                    // Try to get calendar information from id.
                    const calendarQueryParams = {
                        uid: newCalendar,
                    };

                    Calendar.get(
                        calendarQueryParams,
                        function onCalendarGetSuccess(calendar) {
                            Widget.getCurrent().properties.calendarsSelected.push(calendar);

                            _refreshCalendarsSelection();
                        },
                        function onCalendarGetError() {
                            LxNotificationService.error(
                                Translation.translate('WIDGET_TYPE_CALENDAR_SETTINGS_ADD_PUBLIC_CALENDAR_ERROR'),
                            );

                            _refreshCalendarsSelection();
                        },
                    );
                } else {
                    _refreshCalendarsSelection();
                }
            }
        }

        /**
         * Update widget on settings change.
         *
         * @param {string} [changeType] The type of change that trigger the update of the widget.
         *                              Possible value is: 'display'.
         *                              When triggering a 'display' change, don't reload the events of the widget.
         * @param {string} [idCalendar] The id of the calendar whose display config has been updated.
         */
        function updateWidget(changeType, idCalendar) {
            const currentWidget = Widget.getCurrent();

            if (!currentWidget.properties.displayWeekend) {
                currentWidget.properties.startDayOnSunday = false;
            }

            $rootScope.$broadcast('widget-calendar-settings', currentWidget.uuid, changeType, idCalendar)
        }

        /**
         * Switch the calendar view mode.
         *
         * @param {string} viewMode The view mode we want to use in the calendar.
         *                          Can be either 'planning', 'week' or 'month'.
         */
        function switchViewMode(viewMode) {
            if (Widget.getCurrent().properties.visualizationMode === viewMode) {
                return;
            }

            Widget.getCurrent().properties.visualizationMode = viewMode;
            widgetCalendarSettings.updateWidget();
        }

        /**
         * Toggle the selection state of a calendar in the add calendar dialog.
         *
         * @param {string} calendarId The identifier of the calendar.
         */
        function toggleCalendar(calendarId) {
            if (calendarId === 'user') {
                widgetCalendarSettings.isCalendarUserChecked = !widgetCalendarSettings.isCalendarUserChecked;

                return;
            }

            if (calendarId === 'community') {
                widgetCalendarSettings.isCalendarCommunityChecked = !widgetCalendarSettings.isCalendarCommunityChecked;

                return;
            }

            const toogledCalendar = _.find(Calendar.displayList(widgetCalendarSettings.LIST_KEY), {
                id: calendarId,
            });

            if (angular.isDefinedAndFilled(toogledCalendar)) {
                toogledCalendar.isChecked = !toogledCalendar.isChecked;
            }
        }

        /////////////////////////////

        widgetCalendarSettings.addCalendar = addCalendar;
        widgetCalendarSettings.addPublicCalendar = addPublicCalendar;
        widgetCalendarSettings.closeDialog = closeDialog;
        widgetCalendarSettings.debouncedFilterCalendars = debounce(_filterCalendars, _DEBOUNCE_DELAY);
        widgetCalendarSettings.deleteCalendar = deleteCalendar;
        widgetCalendarSettings.getNextCalendars = getNextCalendars;
        widgetCalendarSettings.isAlreadyDisplayed = isAlreadyDisplayed;
        widgetCalendarSettings.setCalendars = setCalendars;
        widgetCalendarSettings.updateCalendarsSelection = updateCalendarsSelection;
        widgetCalendarSettings.updateWidget = updateWidget;
        widgetCalendarSettings.switchViewMode = switchViewMode;
        widgetCalendarSettings.toggleCalendar = toggleCalendar;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            widgetCalendarSettings.isCommunityContext =
                Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

            _getCalendarList();
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetCalendarSettingsController', WidgetCalendarSettingsController);
})();
