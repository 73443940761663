import {
    getInsightPeople,
    getInsightTrendingDocuments,
    getInsightUsedDocuments,
    getInsightSharedDocuments,
} from '../api/DelveApi';

const getPeopleTask = (maxResults, maxResultsToDisplay) => {
    return getInsightPeople(maxResults).then((res) => ({
        payload: res.data.items.filter((item) => item.personType === 'Person').slice(0, maxResultsToDisplay),
    }));
};

const getCommunitiesTask = (maxResults, maxResultsToDisplay) => {
    return getInsightPeople(maxResults).then((res) => ({
        payload: res.data.items.filter((item) => item.personType === 'Group').slice(0, maxResultsToDisplay),
    }));
};

const getSharedDocumentsTask = (maxResultsToDisplay) => {
    return getInsightSharedDocuments().then((res) => ({
        payload: res.data.items.slice(0, maxResultsToDisplay),
    }));
};

const getTrendingDocumentsTask = (maxResultsToDisplay) => {
    return getInsightTrendingDocuments(maxResultsToDisplay).then((res) => ({
        payload: res.data.items,
    }));
};

const getUsedDocumentsTask = (maxResultsToDisplay) => {
    return getInsightUsedDocuments().then((res) => ({
        payload: res.data.items.slice(0, maxResultsToDisplay),
    }));
};

export { getCommunitiesTask, getPeopleTask, getSharedDocumentsTask, getTrendingDocumentsTask, getUsedDocumentsTask };
