export enum PROVISIONING_DASHBOARD {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290622165
     */

    DASHBOARD = 'PROVISIONING_DASHBOARD.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343067915
     */
    MASS_DEACTIVATION_MESSAGE_ALERT = 'PROVISIONING_DASHBOARD.MASS_DEACTIVATION_ALERT_BODY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343065625
     */
    PROFILE_WILL_BE_DEACTIVATED = 'PROVISIONING_DASHBOARD.PROFILES_TO_BE_DEACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343073126
     */
    MASS_DEACTIVATION_DIALOG_TITLE = 'PROVISIONING_DASHBOARD.MASS_DEACTIVATION_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343065179
     */
    MASS_DEACTIVATION_ALERT_TITLE = 'PROVISIONING_DASHBOARD.MASS_DEACTIVATION_ALERT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343073138
     */
    MASS_DEACTIVATION_DIALOG = 'PROVISIONING_DASHBOARD.MASS_DEACTIVATION_DIALOG_BODY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343073539
     */
    DISPLAY_CONFLICTS = 'PROVISIONING_DASHBOARD.DISPLAY_CONFLICT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343073142
     */
    FEDERATION_CONFLICTS = 'PROVISIONING_DASHBOARD.FEDERATION_CONFLICTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343130844
     */
    PROFILES_ARE_DUPLICATED = 'PROVISIONING_DASHBOARD.DUPLICATED_PROFILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343130908
     */
    PROFILES_ARE_NOT_ALIGNED = 'PROVISIONING_DASHBOARD.NOT_ALIGNED_PROFILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343130911
     */
    PROFILES_ARE_MULTIPLE = 'PROVISIONING_DASHBOARD.DUPLICATED_IN_PROVIDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=343073042
     */
    CONFIRM_DEACTIVATION = 'PROVISIONING_DASHBOARD.CONFIRM_DEACTIVATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487070718
     */
    SYNCHRONIZATION_HISTORY = 'PROVISIONING_DASHBOARD.SYNCHRONIZATION_HISTORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487070823
     */
    PROCESS_USE_CASE = 'PROVISIONING_DASHBOARD.PROCESS_USE_CASE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487071064
     */
    NB_PROCESSED_USERS = 'PROVISIONING_DASHBOARD.NB_PROCESSED_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487071286
     */
    NB_UPDATED_USERS = 'PROVISIONING_DASHBOARD.NB_UPDATED_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487071363
     */
    EMPTY_STATE_TITLE = 'PROVISIONING_DASHBOARD.EMPTY_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487073935
     */
    EMPTY_STATE_DESCRIPTION = 'PROVISIONING_DASHBOARD.EMPTY_STATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487074066
     */
    PROCESS_ID = 'PROVISIONING_DASHBOARD.PROCESS_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487074639
     */
    NB_ENABLED_USERS = 'PROVISIONING_DASHBOARD.NB_ENABLED_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487074649
     */
    NB_DISABLED_USERS = 'PROVISIONING_DASHBOARD.NB_DISABLED_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487074757
     */
    NB_FILTERED_OUT_USERS = 'PROVISIONING_DASHBOARD.NB_FILTERED_OUT_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487074762
     */
    PROCESS_DAILY_PROVISIONING = 'PROVISIONING_DASHBOARD.PROCESS_DAILY_PROVISIONING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487074970
     */
    PROCESS_ON_DEMAND = 'PROVISIONING_DASHBOARD.PROCESS_ON_DEMAND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=491184747
     */
    SYNCHRONIZATION_HISTORY_BETA_WARNING = 'PROVISIONING_DASHBOARD.SYNCHRONIZATION_HISTORY_BETA_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536639825
     */
    DUPLICATED_PROFILES = 'PROVISIONING_DASHBOARD.DUPLICATED_PROFILES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536647787
     */
    DUPLICATED_PROFILES_DESC = 'PROVISIONING_DASHBOARD.DUPLICATED_PROFILES_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536644518
     */
    INCONSISTENT_PROFILES = 'PROVISIONING_DASHBOARD.INCONSISTENT_PROFILES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536647950
     */
    INCONSISTENT_PROFILES_DESC = 'PROVISIONING_DASHBOARD.INCONSISTENT_PROFILES_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536645659
     */
    INCOMPATIBLE_PROFILES = 'PROVISIONING_DASHBOARD.INCOMPATIBLE_PROFILES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536647957
     */
    INCOMPATIBLE_PROFILES_DESC = 'PROVISIONING_DASHBOARD.INCOMPATIBLE_PROFILES_DESC',
}
