import React, { useRef } from 'react';

import isEqual from 'lodash/isEqual';

import { classnames, useClassnames } from '@lumapps/classnames';
import { FlexBox, Orientation } from '@lumapps/lumx/react';
import { MovingFocusProvider } from '@lumapps/moving-focus';
import { useOption } from '@lumapps/utils/hooks/useOption';

import './index.scss';

export interface CustomRadioListProps<I> {
    'aria-labelledby': string;
    items: I[];
    itemRenderer: React.FC<I>;
    itemKeySelector(item: I): string | number;
    itemIdSelector?(item: I): string;
    className?: string;
    orientation?: Orientation;
    isMultiple?: false;
    selectedItem: I;
    setSelectedItem(item?: I): void;
    selectedItems?: never;
    setSelectedItems?: never;
    dataScope?: string;
}

const CLASSNAME = 'custom-radio-list';

export const CustomRadioList = <I,>(props: CustomRadioListProps<I>) => {
    const {
        items,
        setSelectedItem,
        selectedItem,
        itemKeySelector,
        itemIdSelector,
        itemRenderer: ItemRenderer,
        orientation = Orientation.horizontal,
        className,
        dataScope,
        ...forwardedProps
    } = props;

    return (
        <MovingFocusProvider options={{ direction: 'both', loopAround: true }}>
            <FlexBox
                orientation={orientation}
                hAlign="center"
                className={classnames(CLASSNAME, className)}
                role="radiogroup"
                {...forwardedProps}
            >
                {items?.map((item) => (
                    // eslint-disable-next-line no-use-before-define
                    <CustomRadioListItem
                        id={itemIdSelector && itemIdSelector(item)}
                        isChecked={isEqual(item, selectedItem)}
                        selectItem={() => setSelectedItem(item)}
                        key={itemKeySelector(item)}
                    >
                        <ItemRenderer {...item} dataScope={dataScope} />
                    </CustomRadioListItem>
                ))}
            </FlexBox>
        </MovingFocusProvider>
    );
};

interface CustomRadioListItemProps {
    id?: string;
    isChecked: boolean;
    selectItem(): void;
}

/**
 * ListItem-like component implementing the "radio" role and the rolling tab index pattern.
 */
export const CustomRadioListItem: React.FC<CustomRadioListItemProps> = ({ isChecked, selectItem, children, id }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { element } = useClassnames(CLASSNAME);

    // ARIA option & roving tab index
    const { tabIndex, onClick, onKeyDown } = useOption(ref, selectItem, false, false);
    return (
        <div
            ref={ref}
            className={element('item', {
                'is-checked': isChecked,
                'is-clickable': true,
            })}
            // ARIA option role.
            role="radio"
            // ARIA checked.
            aria-checked={isChecked}
            // ARIA roving tab index state.
            tabIndex={tabIndex}
            // ARIA roving tab index keyboard navigation behavior.
            onKeyDown={onKeyDown}
            onClick={onClick}
            aria-labelledby={id && `radio-${id}-title`}
        >
            {children}
        </div>
    );
};
CustomRadioListItem.displayName = 'CustomRadioListItem';
