/* istanbul ignore file */
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { getInstanceDirectories, isDirectoriesAllowed } from '../../ducks/selectors';
import { DirectoriesSideNavigation } from './DirectoriesSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const directories = getInstanceDirectories(state);
    const isAllowed = isDirectoriesAllowed(state);
    const isSuperAdmin = isInstanceSuperAdmin(state);

    return {
        directories,
        isAllowed,
        isSuperAdmin,
    };
};

const mapDispatchToProps = {};

const ConnectedDirectoriesSideNavigation = connect(mapStateToProps, mapDispatchToProps)(DirectoriesSideNavigation);

export { ConnectedDirectoriesSideNavigation as DirectoriesSideNavigation };
