import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ColorPalette, Text, Theme } from '@lumapps/lumx/react';

import { FILE_PREVIEW_BLOCK_CLASSNAME as CLASSNAME } from '../../constants';

export const FilePreviewDataElement: React.FC<{
    mediaDate?: string;
    theme?: Theme;
}> = ({ mediaDate, theme }) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <Text
            className={element('file-date')}
            as="span"
            color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
            truncate
            typography="caption"
            title={mediaDate}
        >
            {mediaDate}
        </Text>
    );
};
