/* eslint-disable no-param-reassign */
/* istanbul ignore file */
import assignWith from 'lodash/assignWith';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import { produce } from '@lumapps/redux/immer';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

import {
    GET_ALL_SOCIAL_NETWORKS,
    TOGGLE_OPEN_CUSTOM_SETTINGS,
    UPDATE_SOCIAL_NETWORK,
    SET_FORM_FIELD_ERROR,
    TOGGLE_ADVANCED_OPTIONS_DROPDOWN,
    UPDATE_FORM,
} from './actions';

const defaultState = {
    current: null,
    entities: {},
    form: {},
    isAdvancedOptionOpen: {},
    isCustomSettingsOpen: false,
};

export const reducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_SOCIAL_NETWORKS:
            action.socialNetworks.forEach(
                // eslint-disable-next-line no-return-assign
                (socialNetwork) => (draft.entities[socialNetwork.socialNetworkId] = socialNetwork),
            );

            return draft;

        case TOGGLE_OPEN_CUSTOM_SETTINGS:
            draft.current = action.current;
            draft.isCustomSettingsOpen = action.current !== undefined;

            return draft;

        case UPDATE_SOCIAL_NETWORK:
            Object.assign(draft.entities[action.socialNetwork.socialNetworkId], action.socialNetwork);
            draft.current = undefined;
            draft.isCustomSettingsOpen = false;

            return draft;

        case TOGGLE_ADVANCED_OPTIONS_DROPDOWN:
            draft.isAdvancedOptionOpen[action.id] = action.isOpen;

            return draft;

        case UPDATE_FORM: {
            const { form } = action.payload;

            assignWith(draft.form, form, mergeObjectOnly);

            return draft;
        }

        case SET_FORM_FIELD_ERROR: {
            const { fieldId, errorMessage } = action.payload;
            const { form } = draft;

            set(form, ['fields', fieldId, 'errorMessage'], errorMessage);

            const formHasError = Object.values(form.fields).find((field) => !isEmpty(field.errorMessage));
            set(form, ['hasError'], Boolean(formHasError));

            return draft;
        }

        default:
            return draft;
    }
}, defaultState);
