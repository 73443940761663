import { useParseQueryParams } from '@lumapps/router/hooks/useQueryParams';

/**
 * Checks whether the autotest query param is present in the current URL.
 * @returns boolean
 */
export const useIsAutoTestEnabled = () => {
    const queryParams = useParseQueryParams({ rawUrlParams: window.location.search });

    return Boolean(queryParams && queryParams.autotest);
};
