import React from 'react';

import { ASK_AI_CONCEPTS } from '@lumapps/ask-ai-concepts/keys';
import { askAiConceptsAdminRoute } from '@lumapps/ask-ai-concepts/routes';
import { ASK_AI_CONFIGURATIONS } from '@lumapps/ask-ai-configurations/keys';
import { askAiConfigurationsAdminRoute } from '@lumapps/ask-ai-configurations/routes';
import { ASK_AI_RULES } from '@lumapps/ask-ai-rules/keys';
import { askAiBaseRulesAdminRoute } from '@lumapps/ask-ai-rules/routes';
import { askAiBaseSourcesAdminRoute } from '@lumapps/ask-ai-sources/routes';
import { canAccessAskAiAdmin } from '@lumapps/ask-ai/ducks/selectors';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiSparkles } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export type SearchAiSideNavigationProps = {
    id: string;
};

/**
 * Side navigation section for the Ask AI feature.
 */
export const SearchAiSideNavigation: React.FC<SearchAiSideNavigationProps> = ({ id }) => {
    const { translateKey } = useTranslate();

    const isAskAiEnabled = useSelector(canAccessAskAiAdmin);

    if (!isAskAiEnabled) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(GLOBAL.ASK_AI)} icon={mdiSparkles} id={id}>
            <SideNavigationItem
                route={askAiConfigurationsAdminRoute}
                label={translateKey(ASK_AI_CONFIGURATIONS.CONFIGURATIONS)}
                id={`${id}--configurations`}
            />
            <SideNavigationItem
                route={askAiBaseRulesAdminRoute}
                label={translateKey(ASK_AI_RULES.RULES)}
                id={`${id}--rules`}
            />
            <SideNavigationItem
                route={askAiConceptsAdminRoute}
                label={translateKey(ASK_AI_CONCEPTS.CONCEPTS)}
                id={`${id}--concepts`}
            />
            <SideNavigationItem
                route={askAiBaseSourcesAdminRoute}
                label={translateKey(GLOBAL.SOURCES)}
                id={`${id}--sources`}
            />
        </SideNavigationItem>
    );
};
