import { generateUUID } from '@lumapps/utils/string/generateUUID';
import pick from 'lodash/pick';

import { getAttributes } from '@lumapps/data-attributes';
 
import find from 'lodash/find';
import {remove as _remove} from 'lodash';

import { MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD } from '../../../../../components/components/post/ducks/post_actions'

(function IIFE() {
    'use strict';

    /////////////////////////////

    function PostService($q, $rootScope, $state, Community, Customer, InitialSettings, Instance,
        LumsitesBaseService, LxDialogService, LxNotificationService, MainNav, PostFactory, ReduxStore, Translation, User, Utils) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(PostFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            preSave: _formatPostForServer,
        });

        /**
     * The namespace of this service in the redux store.
     *
     * @type {string}
     * @constant
     */
    service.reduxReducerName = 'posts';

    /**
     * The action type triggered when Angular updated the state.
     *
     * @type {string}
     * @constant
     */
    service.reduxUpdateActionType = 'posts/update';

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Keep a copy of the last query params for a given list key.
         *
         * @type {Object}
         */
        var _lastQueryParams = {};

        /**
         * Stores a list of promises related to API calls. The way the promises are stored works like a map, with
         * the call liskKey as the key, and the promise attached to this listKey as value.
         *
         * @type {Object}
         */
        var _postPromises = {};

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The fields and properties to be listed in the response of the post.list endpoint.
         *
         * @type {Object}
         */
        service.PROJECTION = {
            list: {
                items: {
                    alreadyReported: true,
                    authorDetails: true,
                    comments: true,
                    content: true,
                    customer: true,
                    eventEndDate: true,
                    eventStartDate: true,
                    externalKey: true,
                    feedKeys: true,
                    files: true,
                    images: true,
                    instance: true,
                    isMerged: true,
                    liked: true,
                    likes: true,
                    links: true,
                    mentionsDetails: true,
                    mentionsFeedKeys: true,
                    parentContentDetails: true,
                    pinDetails: true,
                    postStatus: true,
                    postType: true,
                    publicationDate: true,
                    relevantComment: true,
                    relevantCommentDetails: true,
                    reportStatus: true,
                    score: true,
                    tags: true,
                    tagsDetails: true,
                    title: true,
                    type: true,
                    uid: true,
                    updateAt: true,
                    userVote: true,
                    version: true,
                },
            },
            reportList: {
                postId: true,
                report: true,
            },
            reportSpammer: {
                spammerInfo: true,
            },
        };

        /**
         * The possible report actions.
         * https://github.com/lumapps/lumsites/wiki/API-Specification-Community-report-inappropriate-post#moderate-post
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        service.MODERATION_ACTIONS = {
            DECLINE: 'DECLINE',
            DELETE: 'DELETE',
        };

        /**
         * The possible report statuses.
         * https://github.com/lumapps/lumsites/wiki/API-Specification-Community-report-inappropriate-post#definitions
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        service.REPORT_STATUSES = {
            REPORTED: 'REPORTED',
        };

        /**
         * The possible report types.
         * https://github.com/lumapps/lumsites/wiki/API-Specification-Community-report-inappropriate-post#definitions
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        service.REPORTED_AS = {
            SPAM: 'SPAM',
        };

        /**
         * The default parameters for the service requests.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /**
         * The post being moved in an other community.
         *
         * @type {Object}
         */
        service.movedPost = undefined;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Reformat the object right before sending it to the server.
         *
         * @param  {Object} post A post object to be reformatted.
         * @return {Object} A post object.
         */
        function _formatPostForServer(post) {
            if (angular.isUndefinedOrEmpty(post)) {
                return {};
            }

            // For new posts (not edit).
            if (angular.isUndefinedOrEmpty(post.uid)) {
                post.slug = post.slug || {};

                var currentLang = Translation.getLang('current');

                post.slug[currentLang] = generateUUID();
            }

            if (angular.isDefinedAndFilled(post.parentContentDetails)) {
                post.externalKey = post.parentContentDetails.id;
                post.feedKeys = post.parentContentDetails.feedKeys;
            }

            return post;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Approve a merge report for a given post.
         * If approved, it will trigger a task to merge all the likes / comments from the posts.
         *
         * @param {Object}   master  The master post (the one that is, we think, duplicated).
         * @param {Array}    slaves  A list of posts that are deemed duplicates of the master post.
         * @param {Function} [cb]    A callback function to execute on merge spam success.
         * @param {Function} [errCb] A callback function to execute on merge spam error.
         */
        function approveMerge(master, slaves, cb, errCb) {
            if (angular.isUndefinedOrEmpty([master, slaves], 'some')) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            LxNotificationService.confirm(
                Translation.translate('COMMUNITY_POST_MERGE_APPROVE'),
                Translation.translate('COMMUNITY_POST_MERGE_APPROVE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onMergeApproveConfirmed(answer) {
                    if (!answer) {
                        return;
                    }

                    PostFactory.approveMerge({
                        uid: master.uid,
                    }, {
                        postUids: _.map(slaves, 'uid'),
                    }, function onApproveMergeSuccess(response) {
                        LxNotificationService.success(Translation.translate('COMMUNITY_POST_MERGE_APPROVE_SUCCESS'));

                        if (angular.isDefinedAndFilled(response)) {
                            master = response;
                            $rootScope.$broadcast('community__post-saved', response);
                        }

                        cb(response);

                        LxDialogService.close();
                    }, function onApproveMergeError(response) {
                        Utils.displayServerError(response);
                        errCb(response);
                    });
                });
        }

        /**
         * When listing posts via the API, make sure we cleanup any set mode value for the given listKey.
         *
         * @param  {Object}   params  A filter object to apply to the list API call.
         * @param  {Function} [cb]    A callback function to execute on success.
         * @param  {Function} [errCb] A callback function to execute on error.
         * @param  {string}   listKey The list key to associate the post list with.
         * @return {Promise}  The promise of the call to the server if any is done.
         *
         * @override
         */
        function filterize(params, cb, errCb, listKey, projection) {
            if (angular.isDefinedAndFilled(listKey)) {
                // Reset last query params.
                _lastQueryParams[listKey] = params;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            projection = projection || service.PROJECTION.list;

            return LumsitesBaseService.proto.prototype.filterize.call(service, params, cb, errCb, listKey, projection);
        }

        /**
         * Get values for 2 related status flags.
         * This is used for isMerged on post list for example where isMergePending depend on
         * the values of isMerged.
         *
         * @param  {string|boolean} flagValue The value of isMerged flag.
         * @return {Array}          A list of 2 values, the first one being the transformed original one.
         */
        function getRelatedStatusFlags(flagValue) {
            var flags = [flagValue, undefined];

            if (flagValue === 'pending') {
                flags[0] = undefined;
                flags[1] = true;
            }

            return flags;
        }

        /**
         * Fetch report list for a given entity.
         *
         * @param  {Object}  params     The parameters of the request.
         * @param  {Object}  projection The fields we want to retrieve.
         * @param  {string}  listKey    The list's listKey.
         * @return {Promise} The request promise.
         */
        function getReportList(params, projection, listKey) {
            if (angular.isDefinedAndFilled([listKey, service.displayList(listKey)], 'every')) {
                return $q.resolve(service.displayList(listKey));
            }

            var reportListPromise;

            if (!_postPromises[listKey]) {
                _postPromises[listKey] = $q.defer();
            }

            reportListPromise = _postPromises[listKey];

            LumsitesBaseService.proto.prototype.filterize.call(service, params, reportListPromise.resolve,
                reportListPromise.reject, listKey, projection, 'reportList');

            return reportListPromise.promise;
        }

        /**
         * Get the writer/author name for a given post.
         *
         * @param  {Object} post A post object to get the author of.
         * @return {Object} The name of the author.
         */
        function getWriterFullName(post) {
            if (angular.isUndefinedOrEmpty(post)) {
                return undefined;
            }

            return Community.getWriterFullName(post);
        }

        /**
         * Navigate to a post page.
         * This method overwrites the AbstractContentService.goTo method.
         *
         * @param {Object} post A post object to navigate to.
         */
        function goTo(post) {
            if (angular.isUndefinedOrEmpty([post, post.parentContentDetails], 'some')) {
                return;
            }

            var community = post.parentContentDetails;

            var blank = community.instance !== Instance.getCurrentInstanceId();

            MainNav.goTo('app.front.community', community.id, community.instance, community.slug, post.uid,
                InitialSettings.CONTENT_TYPES.COMMUNITY, blank);
        }

        /**
         * Return the destination address of a post page as a Promise.
         *
         * @param {Object} post A post object to navigate to.
         *
         * @return {Promise} The destination address of the post as a Promise.
         */
        function getDestinationAsPromise(post) {
            if (angular.isUndefinedOrEmpty([post, post.parentContentDetails], 'some')) {
                return new Promise((resolve, reject) => {
                    return reject();
                });
            }

            const community = post.parentContentDetails;
            const instanceId = community.instance;
            const state = 'app.front.community';

            const params = {
                identifier: post.uid,
                view: 'post',
                slug: Translation.translate(community.slug),
            };

            if (Instance.getCurrentInstanceId() === instanceId) {
                return new Promise((resolve) => {
                    const linkUrl = $state.href(state, params, {
                        absolute: true,
                    });
                    resolve({ destination: linkUrl, isInstanceDifferent: false });
                });
            }

            return new Promise((resolve, reject) => {
                Instance.getInstanceById(
                    instanceId,
                    (response) => {
                        params.instance = response.slug;
                        const contentLink = $state.href(state, params, { absolute: true });
                        const absoluteLink = decodeURIComponent(contentLink);
                        resolve({ destination: absoluteLink, isInstanceDifferent: true });
                    },
                    () => {
                        reject();
                    },
                );
            });
        }

        /**
         * Check if a post has at least one attachment of any kind.
         *
         * @param  {Object}  post The post object to check the attachments of.
         * @return {boolean} Whether or not the given post has any attachments.
         */
        function hasAttachment(post) {
            if (angular.isUndefinedOrEmpty(post)) {
                return false;
            }

            return angular.isDefinedAndFilled([post.images, post.files, post.links], 'some');
        }

        /**
         * Indicates if a call to listing or search posts is in progress.
         *
         * @param  {string}  listKey The list identifier to match the posts with.
         * @return {boolean} Whether a call to listing posts with the given listKey is currently in progress.
         *
         * @override
         */
        function isCallInProgress(listKey) {
            return Boolean(LumsitesBaseService.proto.prototype.isCallInProgress.call(service, listKey))
        }

        /**
         * Indicates if the post is the master of the merge report or not.
         *
         * @param  {Post}    post A post object to check the merge master status of.
         * @return {boolean} Whether the post is the master in the merge or not.
         */
        function isMergeMaster(post) {
            return post.isMerged && angular.isDefinedAndFilled(_.get(post, 'merge')) &&
                (post.uid === post.merge.masterPost);
        }

        /**
         * Indicates if the post has a pending merge or not.
         *
         * @param  {Post}    post A post object to check the merge report status of.
         * @return {boolean} Whether the post has a pending merge or not.
         */
        function isMergePending(post) {
            return !post.isMerged && angular.isDefinedAndFilled(_.get(post, 'merge'));
        }

        /**
         * Indicates if the post is a slave of the merge report or not.
         *
         * @param  {Post}    post A post object to check the merge slave status of.
         * @return {boolean} Whether the post is a slave in the merge or not.
         */
        function isMergeSlave(post) {
            return post.isMerged && angular.isDefinedAndFilled(_.get(post, 'merge')) &&
                (post.uid !== post.merge.masterPost);
        }

        /**
         * Make the moderate call.
         *
         * @param  {Object}  params The request parameters.
         * @return {Promise} The moderate call promise.
         */
        function moderatePost(params) {
            params = service.computeProjection(params, service.PROJECTION.reportList);

            return PostFactory.moderate({}, params).$promise;
        }

        /**
         * Pin a given post.
         *
         * @param {Object}   post    The post to be pinned.
         * @param {string}   communityId    the community id where we want the post to be pinned
         * @param {string}   title   The title of the post to be used in the pinned post.
         * @param {Function} [cb]    A callback function to be called if the pin call is successful.
         * @param {Function} [errCb] A callback function to be called if the pin call errors.
         */
        function pin(post, communityId, title, cb, errCb) {
            if (angular.isUndefinedOrEmpty(post)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            var titleTranslations = title;
            if (angular.isString(titleTranslations)) {
                titleTranslations = {};
                titleTranslations[Translation.inputLanguage] = title;
            }

            PostFactory.pin({
                uid: post.uid,
                communityId
            }, function onPinSuccess(response) {
                if (angular.isDefinedAndFilled(response)) {
                    // Update the existing post object with the latest from the server.
                    angular.extend(post, response);
                }
                cb(response);
            }, errCb);
        }

        /**
         * Prepend a content/post to the service list.
         *
         * @param {Object} post    The post to prepend to the service list.
         * @param {string} listKey The service list key identifier.
         */
        function prependPost(post, listKey) {
            listKey = service._getListKey(listKey);
            var targetService = service._services[listKey];

            if (targetService && angular.isArray(targetService._list)) {
                targetService._list.unshift(post);
            }
        }

        /**
         * Reject a merge report for a given post.
         *
         * @param {Object}   master  The master post (the one that is, we think, duplicated).
         * @param {Function} [cb]    A callback function to execute on reject success.
         * @param {Function} [errCb] A callback function to execute on reject error.
         */
        function rejectMerge(master, cb, errCb) {
            if (angular.isUndefinedOrEmpty(master)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            PostFactory.rejectMerge({
                uid: master.uid,
            }, {}, function onRejectMergeSuccess(response) {
                LxNotificationService.success(Translation.translate('COMMUNITY_POST_MERGE_REJECT_SUCCESS'));

                if (angular.isDefinedAndFilled(response)) {
                    master = response;
                    $rootScope.$broadcast('community__post-saved', response);
                }

                cb(response);
                LxDialogService.close();
            }, function onRejectMergeError(response) {
                Utils.displayServerError(response);
                errCb(response);
            });
        }

        /**
         * Remove a post.
         *
         * @param {Object}   post      The post object to remove.
         * @param {Function} [cb]      A callback function to execute on deletion success.
         * @param {Function} [errCb]   A callback function to execute on deletion error.
         * @param {string}   [listKey] The list key identifier.
         */
        function remove(post, cb, errCb, listKey) {
            if (angular.isUndefinedOrEmpty(post)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            LxNotificationService.confirm(
                Translation.translate('COMMUNITY_POST_DELETE'),
                Translation.translate('COMMUNITY_POST_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onDeletionConfirmed(answer) {
                    if (!answer) {
                        return;
                    }

                    // FIXME [max]: Uid/id, remove when all projection issues are fixed.
                    service.del((post.id || post.uid), function onDeleteSuccess(response) {
                        LxNotificationService.success(Translation.translate('COMMUNITY_POST_DELETE_SUCCESS'));
                        cb(response);
                    }, function onDeleteError(response) {
                        Utils.displayServerError(response);
                        errCb(response);
                    }, listKey);
                });
        }

        /**
         * Report a post as duplicated.
         *
         * @param {Object}   master            The master post (the one that is, we think, duplicated).
         * @param {Array}    slaves            A list of posts that are deemed duplicates of the master post.
         * @param {Array}    [mergedSlavesIds] A list of post ids that are already merged slaves of the master post.
         * @param {Function} [cb]              A callback function to execute on report spam success.
         * @param {Function} [errCb]           A callback function to execute on report spam error.
         */
        function reportMerge(master, slaves, mergedSlavesIds, cb, errCb) {
            if (angular.isUndefinedOrEmpty([master, slaves], 'some')) {
                LxNotificationService.error(Translation.translate('COMMUNITY_POST_MERGE_REPORT_ERROR'));

                return;
            }

            mergedSlavesIds = mergedSlavesIds || [];

            // We want all the slave ids minus the ones that are already merged.
            var slaveIds = _.difference(_.map(slaves, 'uid'), mergedSlavesIds);

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            var isAdmin = Community.isEditableBy(User.getConnected(), _.get(master, 'parentContentDetails'));

            PostFactory.reportMerge({
                uid: master.uid,
            }, {
                postUids: slaveIds,
            }, function onReportMergeSuccess(response) {
                LxNotificationService.success(Translation.translate((isAdmin) ?
                    'COMMUNITY_POST_MERGE_APPROVE_SUCCESS' : 'COMMUNITY_POST_MERGE_REPORT_SUCCESS'));

                if (angular.isDefinedAndFilled(response)) {
                    master = response;
                    $rootScope.$broadcast('community__post-saved', response);
                }

                cb(response);
                LxDialogService.close();
            }, function onReportMergeError(response) {
                Utils.displayServerError(response);
                errCb(response);
            });
        }

        /**
         * Report a given post.
         *
         * @param {Object}   post    The post object to report.
         * @param {Function} [cb]    A callback function to execute on report success.
         * @param {Function} [errCb] A callback function to execute on report error.
         */
        function reportPost(post, cb, errCb) {
            if (angular.isUndefinedOrEmpty(post)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            var params = {
                uid: (post.id || post.uid),
            };

            params = service.computeProjection(params, service.PROJECTION.reportList);
            // TODO[Marco]: transform to promise call.
            PostFactory.report({}, params, cb, function onReportSpamError(response) {
                Utils.displayServerError(response);
                errCb(response);
            });
        }


        function reloadPostList(savedPost) {
            $rootScope.$broadcast('community__post-saved', savedPost);
        }

        /**
         * Save a post and update the associated list / prepend it.
         * Note: the only use of this function is to prepend new items instead of pushing at the end of the list!
         *
         * @param  {Object}   post    A post object to save.
         * @param  {Function} [cb]    A callback function to execute on success.
         * @param  {Function} [errCb] A callback function to execute on error.
         * @param  {string}   listKey The list key to associate the call to the endpoint with.
         * @return {Promise}  The promise of the call to the server if any is done.
         *
         * @override
         */
        function save(post, cb, errCb, listKey) {
            if (angular.isUndefinedOrEmpty(post)) {
                return undefined;
            }
            // Keep only the allowed JSON fields documented in the backend API docs.
            // @see https://api.lumapps.com/docs/output/_schemas/post
            post = pick(post, [
                'alreadyReported',
                'analyticsViewSum',
                'archivers',
                'attachmentsCount',
                'author',
                'authorDetails',
                'authorId',
                'calendarEventId',
                'canDelete',
                'canEdit',
                'canMarkRelevant',
                'canPin',
                'canonicalUrl',
                'comments',
                'content',
                'count',
                'createdAt',
                'customContentType',
                'customContentTypeDetails',
                'customContentTypeTags',
                'customer',
                'deleters',
                'editors',
                'endDate',
                'eventEndDate',
                'eventStartDate',
                'excerpt',
                'externalKey',
                'featuredEndDate',
                'featuredFeedKeys',
                'featuredStartDate',
                'feedKeys',
                'files',
                'following',
                'hasCommentWidget',
                'hasRelevantComment',
                'header',
                'headerDetails',
                'id',
                'images',
                'instance',
                'isCustomizableLayout',
                'isDefaultContentList',
                'isDefaultUserDirectory',
                'isFeatured',
                'isHomepage',
                'isMerged',
                'isNavItem',
                'isOpen',
                'isPinned',
                'lastRevision',
                'liked',
                'likes',
                'link',
                'links',
                'location',
                'mediaThumbnail',
                'mentions',
                'mentionsDetails',
                'mentionsFeedKeys',
                'merge',
                'metadata',
                'navigation',
                'notificationsEnabled',
                'notifyAuthor',
                'notifyFeedKeys',
                'notifyUsers',
                'parentContentDetails',
                'parentCustomContentType',
                'parentsNavigation',
                'pinDetails',
                'postStatus',
                'postStatusDetails',
                'postType',
                'properties',
                'public',
                'publicationDate',
                'publishers',
                'readers',
                'relevantComment',
                'relevantCommentDetails',
                'reportStatus',
                'score',
                'slug',
                'startDate',
                'status',
                'subscribedFeedKeys',
                'subscription',
                'subtitle',
                'tags',
                'tagsDetails',
                'tagz',
                'template',
                'thumbnail',
                'thumbnailBlobkey',
                'title',
                'type',
                'uid',
                'updatedAt',
                'updatedBy',
                'updatedByDetails',
                'updatedById',
                'url',
                'userContent',
                'userVote',
                'version',
                'weight',
                'writer',
                'writerDetails',
                'writerId',
            ]);

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            // Get current list.
            listKey = service._getListKey(listKey);
            var targetService = service._services[listKey];

            // Trying to find if the post we're saving is already in our current list.
            var postToUpdateIndex = _.findIndex(targetService._list, function isMatchingUid(item) {
                return item.uid === post.uid;
            });

            // Hack !!
            var pass = !_lastQueryParams[listKey] || angular.isUndefinedOrEmpty(_lastQueryParams[listKey].tags);
            var matchCurrentList = pass || angular.isDefinedAndFilled(_.intersection(_lastQueryParams[listKey].tags,
                post.tags));

            return LumsitesBaseService.proto.prototype.save.call(service, post, function onPostSaveSuccess(response) {
                // Update an existing post.
                if (postToUpdateIndex > -1) {
                    targetService._list[postToUpdateIndex] = response;
                // Create a new post.
                } else if (matchCurrentList) {
                    // Remove last element.
                    targetService._list.pop();

                    // Adds new element at the beginning of the list.
                    targetService._list.unshift(response);
                }

                cb(response);
            }, errCb, listKey);
        }

        /**
         * Define the post being moved.
         *
         * @param {Object} post The post being moved.
         */
        function setMovedPost(post) {
            service.movedPost = post;
        }

        /**
         * Update an old post if found.
         *
         * @param {Object} newPost The new post.
         * @param {string} listKey The list key identifier.
         */
        function updateLocalPost(newPost, listKey) {
            listKey = service._getListKey(listKey);

            var targetService = service._services[listKey];
            if (targetService && angular.isArray(targetService._list)) {
                var oldPost = _.find(targetService._list, function finder(elem) {
                    // FIXME [max]: Uid/id, remove when all projection issues are fixed.
                    return (elem.uid || elem.id) === newPost.id;
                });

                // Update reference.
                if (oldPost) {
                    angular.extend(oldPost, newPost);
                }
            }
        }

        /**
         * Get post from local list, do not use service.get that set setCurrent and call the server if post not found.
         *
         * @param  {string} identifier The wanted post identifier.
         * @param  {string} listKey    The list key identifier.
         * @return {Object} The wanted post or undefined.
         */
        function getLocalPost(identifier, listKey) {
            listKey = service._getListKey(listKey);

            return _.find(service._services[listKey]._list, function finder(post) {
                return (post.uid || post.id) === identifier;
            });
        }

        /**
         * Unpin a given post.
         *
         * @param {string}   post    The post object to be unpinned.
         * @param {Function} [cb]    A callback function to be called if the unpin call is successful.
         * @param {Function} [errCb] A callback function to be called if the unpin call errors.
         */
        function unpin(post, communityId, cb, errCb) {
            if (angular.isUndefinedOrEmpty(post)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            LxNotificationService.confirm(Translation.translate('UNPIN'),
                Translation.translate('UNPIN_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onUnpinPostConfirm(answer) {
                    if (!answer) {
                        return;
                    }

                    PostFactory.unpin({
                        communityId,
                        uid: post.uid,
                    }, function onUnpinSuccess(response) {
                        if (angular.isDefinedAndFilled(response)) {
                            // Update the existing post object with the latest from the server.
                            angular.extend(post, response);
                            // since we are just patching the cached Post we need to handle properties deleted from the endpoint
                            // endpoints do not return empty lists
                            if(response.pinnedInCommunitiesDetails === undefined){
                                delete post['pinnedInCommunitiesDetails'];
                            }
                        }
                        cb(response);
                    }, errCb);
                });
        }

     /**
     * The data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        return {};
    }

     /**
     * Triggered when synced data is changed by redux.
     * It takes in the new state and should update the Angular service accordingly.
     *
     * @param {reduxState} newState The part of the state that this service is concerned about.
     */
    function mapReduxToAngular(newState) {

         if(newState.multiSharePayload){
             let isSuccess = false;
            const { postId, post, error, isUnshare, listkey } = newState.multiSharePayload;

            if(!error){
                Object.values(service._services).forEach(aService=> {
                    const postToUpdate = find(aService._list, entry => entry.uid == postId);
                    if(postToUpdate){
                        postToUpdate.canShare = post.canShare;
                        postToUpdate.visibleInCommunitiesCount = post.visibleInCommunitiesCount;
                        postToUpdate.visibleInCommunitiesDetails = post.visibleInCommunitiesDetails;
                    }
                    });
                
                    LxNotificationService.success(Translation.translate(isUnshare ? 'FRONT.COMMUNITY.POST.UNSHARED.SUCCESS':'FRONT.COMMUNITY.POST.SHARED.SUCCESS'));
                    isSuccess = true;   
        
            }
            else{
                LxNotificationService.error(Translation.translate('FRONT.COMMUNITY.POST.SHARED.FAILED'));
            }
            ReduxStore.store.dispatch({type:MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD});
            if(isUnshare){
                _remove(service._services[listkey]._list, storedPost => storedPost.uid === postId);
                $rootScope.$broadcast('community__post-deleted', post.uid);
                $rootScope.$broadcast('refresh-posts', listkey, false);
            }
            else{
                $rootScope.$broadcast("community-multi-share__ended", {success:isSuccess});
            }
        }

        

    }

        /////////////////////////////

        service.approveMerge = approveMerge;
        service.filterize = filterize;
        service.getLocalPost = getLocalPost;
        service.getRelatedStatusFlags = getRelatedStatusFlags;
        service.getReportList = getReportList;
        service.getWriterFullName = getWriterFullName;
        service.goTo = goTo;
        service.getDestinationAsPromise = getDestinationAsPromise;
        service.hasAttachment = hasAttachment;
        service.isCallInProgress = isCallInProgress;
        service.isMergeMaster = isMergeMaster;
        service.isMergePending = isMergePending;
        service.isMergeSlave = isMergeSlave;
        service.moderatePost = moderatePost;
        service.pin = pin;
        service.prependPost = prependPost;
        service.rejectMerge = rejectMerge;
        service.remove = remove;
        service.reportMerge = reportMerge;
        service.reportPost = reportPost;
        service.save = save;
        service.setMovedPost = setMovedPost;
        service.updateLocalPost = updateLocalPost;
        service.unpin = unpin;
        const getPostsAttributes = getAttributes('posts');
        service.getAttributes = getPostsAttributes;
        service.displayPostActionAttributes = getPostsAttributes({ element: 'actions', action: 'display-post' });
        service.displayPostTitleAttributes = getPostsAttributes({ element: 'title', action: 'display-post' });
        service.displayPostContentAttributes = getPostsAttributes({ element: 'content', action: 'expand-post' });
        service.reloadPostList = reloadPostList;
        service.mapStateToRedux = mapStateToRedux;
        service.mapReduxToAngular = mapReduxToAngular;
        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                customerId: Customer.getCustomerId(),
                instanceId: Instance.getCurrentInstanceId(),
                lang: Translation.getPreferredContributionLanguage(),
            };

            ReduxStore.subscribe(service, true);
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Post', PostService);
})();
