import { MediaSourceV2 } from '@lumapps/medias/types';

/**
 * Check if the given node is a drive file
 * A specific drive to check can be provided
 */
export const isDriveFile = (node?: any, driveProvider?: MediaSourceV2.GOOGLE_DRIVE | MediaSourceV2.MICROSOFT) => {
    if (driveProvider) {
        return node?.provider === driveProvider;
    }

    return node?.provider === MediaSourceV2.GOOGLE_DRIVE || node?.provider === MediaSourceV2.MICROSOFT;
};
