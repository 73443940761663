import { useCallback, useMemo, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { Community, RenderingType } from '@lumapps/communities/types';
import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { moveMedia } from '@lumapps/medias/api';
import { error, success } from '@lumapps/notifications';
import { movePost } from '@lumapps/posts/api';
import { REFRESH_EVENTS } from '@lumapps/posts/constants';
import { POSTS } from '@lumapps/posts/keys';
import { RefreshPosts } from '@lumapps/posts/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { MediaPreview } from '@lumapps/widget-base/types';

import { getMediasToUpdate } from '../utils/getMediasToUpdate';
import { usePostListsRefresh } from './usePostListsRefresh';

export interface UsePostMoveProps {
    postId: string;
    refreshOverride?: RefreshPosts;
    files?: MediaPreview[];
    images?: MediaPreview[];
    canMoveToSpace?: boolean;
}

export const usePostMove = ({ postId, refreshOverride, files, images, canMoveToSpace }: UsePostMoveProps) => {
    const moveBtnRef = useRef<HTMLElement>(null);
    const instanceId = useSelector(instanceIdSelector);
    const { id: customerId } = useSelector(customerSelector);

    const fetchParams = useMemo(() => {
        return {
            customerId,
            instanceId,
            isCommunityAdmin: true,
            renderingTypes: [RenderingType.community, ...(canMoveToSpace ? [RenderingType.space] : [])],
        };
    }, [canMoveToSpace, customerId, instanceId]);

    const [isLoading, setIsLoading] = useState(false);

    const [isCommunityPickerOpen, , closeCommunityPicker, openCommunityPicker] = useBooleanState(false);

    const dispatch = useDispatch();

    const refreshPostLists = usePostListsRefresh(refreshOverride);

    const onMovePost = useCallback(
        async ([community]: Community[]) => {
            setIsLoading(true);
            const communityId = community.uid;
            try {
                const result = await movePost(postId, communityId);
                const mediasToUpdate = getMediasToUpdate(communityId, files, images);
                if (result) {
                    if (!isEmpty(mediasToUpdate)) {
                        const res = await moveMedia(mediasToUpdate);
                        if (result && res) {
                            dispatch(success({ translate: POSTS.POST_MOVE_SUCCESS }));
                        } else {
                            dispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
                        }
                    } else {
                        dispatch(success({ translate: POSTS.POST_MOVE_SUCCESS }));
                    }
                    closeCommunityPicker();
                    refreshPostLists(REFRESH_EVENTS.POST_MOVE, result.data);
                }
            } catch (e) {
                closeCommunityPicker();
                dispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
            } finally {
                setIsLoading(false);
            }
        },
        [closeCommunityPicker, dispatch, files, images, postId, refreshPostLists],
    );

    const communityPickerProps = {
        isOpen: isCommunityPickerOpen,
        isLoading,
        onConfirm: onMovePost,
        onCancel: closeCommunityPicker,
        fetchParams,
    };

    return {
        moveBtnRef,
        communityPickerProps,
        openCommunityPicker,
        closeCommunityPicker,
    };
};
