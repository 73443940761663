import { useEffect } from 'react';

import flow from 'lodash/fp/flow';
import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import mapKeys from 'lodash/fp/mapKeys';
import omitBy from 'lodash/fp/omitBy';
import pick from 'lodash/fp/pick';
import property from 'lodash/fp/property';
import snakeCase from 'lodash/fp/snakeCase';

import { and } from '@lumapps/utils/function/predicate/and';
import { not } from '@lumapps/utils/function/predicate/not';
import { or } from '@lumapps/utils/function/predicate/or';
import { useUpdateWidgetURLFilters } from '@lumapps/widget-base/hooks/useUpdateWidgetURLFilters';

import { PostListFilterType, URLPostListFiltersType } from '../../types';

export const useUpdatePostListURLFilters = (activeFiltersValues?: Partial<PostListFilterType>) => {
    const { updateURLFilters } = useUpdateWidgetURLFilters();

    const formatFiltersForURL = flow(omitBy(or(isNil, and(isEmpty, not(isBoolean)))), mapKeys(snakeCase));

    useEffect(() => {
        if (activeFiltersValues) {
            const authorEmail = property(['authorEmail', 0, 'email'], activeFiltersValues);

            const isAuthorFilterValid = not(or(isNil, isEmpty))(authorEmail);

            const filters: URLPostListFiltersType = formatFiltersForURL({
                ...(isAuthorFilterValid ? { authorEmail } : {}),
                ...pick(['hasRelevantComment', 'postCategories', 'searchQuery', 'postTypes'], activeFiltersValues),
            });

            updateURLFilters(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFiltersValues]);
};
