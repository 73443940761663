import { getFocusableTreeWalker } from './getFocusableTreeWalker';

/**
 * Focus next element in `root` (optionally after a specific element)
 */
export function focusNext(root: Element, after?: Element | null) {
    const node = after || document.activeElement;
    const walker = getFocusableTreeWalker(root);
    if (root.contains(node)) {
        walker.currentNode = node as Node;
    }
    const nextNode = walker.nextNode() as HTMLElement;
    if (nextNode) {
        nextNode.focus();
    }
    return nextNode;
}
