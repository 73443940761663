import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import { Segment } from './types';

export const segmentBaseRoute: Route = {
    path: '/admin/segment',
    appId: AppId.backOffice,
    legacyId: 'app.admin.segment',
};

export const segmentCreateRoute: Route = {
    path: `${segmentBaseRoute.path}/new`,
    appId: AppId.backOffice,
    legacyId: 'app.admin.segment',
};

export const segmentEditRoute: Route = {
    path: `${segmentBaseRoute.path}/edit/:id`,
    appId: AppId.backOffice,
    legacyId: 'app.admin.segment',
};

export const getSegmentRoute = () => {
    return segmentBaseRoute;
};

export const getEditSegmentRoute = (segment: Segment) => {
    return {
        ...segmentEditRoute,
        params: {
            id: segment.id,
        },
    };
};
export interface SegmentParams {
    id: string;
}
