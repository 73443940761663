import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

const SAPublicTemplate = ({ widgets, logo, style }) => {
    const renderWidget = ({ content, format, style: widgetStyle }) => {
        switch (format) {
            case 'html':
                return (
                    <div style={{ backgroundColor: widgetStyle.content.backgroundColor }}>
                        <div className="innerContent">
                            <div className="widget-html__content fr-element">
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </div>
                        </div>
                    </div>
                );

            case 'text':
            case 'intro':
                return (
                    <div className="widget-intro__content fr-element">
                        <p dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                );

            case 'title':
                return (
                    <div className="sa-public-template-wrapper-widget__widget-title">
                        <h2 dangerouslySetInnerHTML={{ __html: content }} className="widget-title__title fr-element" />
                    </div>
                );

            case 'image':
                return (
                    <div
                        className="widget-featured-image__image"
                        style={{
                            backgroundImage: `url(${content})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            height: 200,
                        }}
                    />
                );

            default:
                return '';
        }
    };

    return widgets ? (
        <div className="sa-public-template">
            <div
                className="sa-public-template__header"
                style={{ backgroundColor: style && style.properties ? style.properties.top.backgroundColor : '' }}
            >
                <img alt="Logo" src={logo} />
            </div>
            <div className="sa-public-template__wrapper sa-public-template-wrapper">
                {widgets.map((widget) => (
                    <div
                        key={widget.id}
                        className="sa-public-template-wrapper__widget sa-public-template-wrapper-widget"
                    >
                        {renderWidget(widget)}
                    </div>
                ))}
            </div>
        </div>
    ) : null;
};

SAPublicTemplate.propTypes = {
    logo: string.isRequired,
    style: shape({}).isRequired,
    widgets: arrayOf(Object).isRequired,
};

export { SAPublicTemplate };
