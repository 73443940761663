import { isCodeBlock } from '@lumapps/wrex-typography/utils/isCodeBlock';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { Editor } from '@lumapps/wrex/slate';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';

export const isEmojiAllowed = (editor: Editor) => (): boolean => {
    // Editor cursor should be available.
    if (!editor.selection) {
        return true;
    }

    const elements = Array.from(Editor.nodes(editor, { at: editor.selection, match: isElement, mode: 'lowest' }));

    // Editor selection should include only one block.
    if (elements.length !== 1) {
        return false;
    }

    return elements.every(([node]) => isParagraph(node) || isCodeBlock(node) || editor.isInline(node));
};
