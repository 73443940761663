export const DEFAULT_RATIO = 56.25;

export const computeIFrameRatio = (iFrameHTML: string) => {
    const dimensions: Record<string, number> = {};

    iFrameHTML.replace(/(width|height)=["']([^"']*)["']/gi, (all, type, value) => {
        dimensions[type] = Number(value);
        return iFrameHTML;
    });

    // 56.25 is for 16/9 ratio by default
    return dimensions.height && dimensions.width ? (dimensions.height / dimensions.width) * 100 : DEFAULT_RATIO;
};
