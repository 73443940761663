import React, { useRef } from 'react';

import { mdiShare } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Size, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useHover } from '@lumapps/utils/hooks/useHover';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { SHARE } from '../../keys';
import { ShareContentDropdown } from './ShareContentDropdown';
import { ShareContentDropdownBaseProps } from './ShareContentDropdown/types';

export type ShareContentButtonWrapperProps = { theme?: Theme } & Pick<
    ShareContentDropdownBaseProps,
    'contentInternalId' | 'onClickCopy' | 'preview'
>;

const CLASSNAME = 'content-share-button__wrapper';

export const ShareContentButtonWrapper: React.FC<ShareContentButtonWrapperProps> = (props) => {
    const { contentInternalId, onClickCopy, preview, theme } = props;
    const buttonRef = useRef(null);
    const { translateKey } = useTranslate();
    const [isOpen, toggleOpen, close] = useBooleanState(false);
    const { isHovered, registerElement } = useHover();

    const title = translateKey(SHARE.SHARE_KEY);

    return (
        <div className={CLASSNAME}>
            <IconButton
                ref={mergeRefs([buttonRef, registerElement])}
                emphasis={Emphasis.low}
                theme={theme || Theme.light}
                size={Size.m}
                icon={mdiShare}
                onClick={toggleOpen}
                label={title}
                aria-haspopup
                aria-expanded={isOpen}
            />

            <DelayedSuspense shouldLoad={isHovered || isOpen}>
                <ShareContentDropdown
                    contentInternalId={contentInternalId}
                    isOpen={isOpen}
                    buttonRef={buttonRef}
                    onClickCopy={onClickCopy}
                    preview={preview}
                    onClose={close}
                />
            </DelayedSuspense>
        </div>
    );
};
