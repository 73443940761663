import { oneOf } from 'prop-types';

import { NAVIGATION_TYPES_VALUES, VIEW_MODES_VALUES, VIEW_MODE_VARIANTS_VALUES } from '../constants';
import { ORIENTATIONS_VALUES, CAPTION_POSITIONS } from './constants';

const captionPositionTypes = oneOf([CAPTION_POSITIONS.OVER, CAPTION_POSITIONS.BELOW]);

const orientationTypes = oneOf([
    ORIENTATIONS_VALUES.HORIZONTAL,
    ORIENTATIONS_VALUES.VERTICAL,
    ORIENTATIONS_VALUES.ORIGINAL,
]);

const navigationControlsTypes = oneOf([NAVIGATION_TYPES_VALUES.BULLETS, NAVIGATION_TYPES_VALUES.THUMBNAILS]);

const viewModeTypes = oneOf([VIEW_MODES_VALUES.GRID, VIEW_MODES_VALUES.SLIDESHOW]);

const viewModeVariantTypes = oneOf([VIEW_MODE_VARIANTS_VALUES.GROUP, VIEW_MODE_VARIANTS_VALUES.UNGROUP]);

export { captionPositionTypes, orientationTypes, navigationControlsTypes, viewModeTypes, viewModeVariantTypes };
