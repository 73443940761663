import { Dispatch } from '@lumapps/redux/types';

import * as api from '../api';
import { actions } from './slice';

/**
 * Retrieves the user directory
 */
const fetchUserDirectories = (options: api.ListParameters & { add: boolean }) => async (dispatch: Dispatch) => {
    try {
        const { add, ...requestParams } = options;
        const response = await api.directoriesList(requestParams);

        const {
            data = {
                items: [],
            },
        } = response;
        dispatch(actions.fetchUserDirectoriesSearchSuccess({ ...data, add }));
    } catch (exception) {
        const { response } = exception as any;

        dispatch(actions.fetchUserDirectoriesSearchError(response ? response.message : 'Error'));
    }
};

const fetchUserDirectoriesForUserListSettings =
    (options: api.ListParameters & { add: boolean }) => async (dispatch: Dispatch) => {
        dispatch(actions.setUserDirectoriesSearchStatus(true));
        fetchUserDirectories(options)(dispatch);
    };

export { fetchUserDirectories, fetchUserDirectoriesForUserListSettings };
