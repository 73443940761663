(function IIFE() {
    'use strict';

    /////////////////////////////

    function EventFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/calendar/event/:methodKey', {}, {
            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('EventFactory', EventFactory);
})();
