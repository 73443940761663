import * as React from 'react';
import * as propTypes from 'prop-types';

import map from 'lodash/map';
import slice from 'lodash/slice';
import omit from 'lodash/omit';

import * as types from '../../types';
import * as menuTypes from '../../ui/menus/types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { withOnChangeProperty } from '../../../utils';
import { choicesToMap, commonValueOrDefault, objectFromValue } from '../utils';
import { Input, LockGroup, Select, TextField } from '../../ui';
import { DEFAULT_VALUE } from '../constants';

/**
 * Renders a generic form that consists of four lockable inputs.
 * Usually used for editing properties that have four sub-values such as
 * `margin`, `padding`, `border`, `borderColor`, etc.
 */
export class Measures extends React.PureComponent {
    static propTypes = {
        /** Component to use to render input fields. */
        Input: propTypes.func,
        /** Input choices. */
        choices: menuTypes.choices,
        /** Whether the input fields are clearable or not. */
        hasClearChoice: propTypes.bool,
        /** Default values. */
        defaults: propTypes.object.isRequired,
        /** Main field name. */
        field: types.property.isRequired,
        /** Field key names and labels. */
        fields: styleTypes.measureFields.isRequired,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Suffix, passed to input fields. */
        suffix: propTypes.string,
        /** Current values. */
        value: propTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value[props.field] : undefined,
        };

        withOnChangeProperty(this, (value, propertyValue) => {
            const { fields, field } = this.props;
            this.setState({ value: propertyValue });

            return value[field] === null || value[field] === undefined
                ? value
                : {
                      ...value,
                      [field]: propertyValue,
                      ...objectFromValue(fields, propertyValue),
                  };
        });

        this.onChangeLocked = (locked) => {
            const { value = {}, name: key, onChange, field, fields, defaults } = this.props;
            const { value: lastValue } = this.state;
            const fieldValue = locked
                ? lastValue !== null && lastValue !== undefined
                    ? lastValue
                    : commonValueOrDefault(fields, value, defaults)
                : null;

            return onChange(
                fieldValue === null || fieldValue === undefined
                    ? omit(value, field)
                    : {
                          ...value,
                          [field]: fieldValue,
                          ...objectFromValue(fields, fieldValue),
                      },
                key,
            );
        };
    }

    renderFields(fields, side) {
        const {
            choices,
            value = {},
            Input: InputComponent = choices ? Select : Input,
            field: mainField,
            suffix,
            defaults,
            hasClearChoice,
        } = this.props;
        const mainValue = value[mainField];
        const choicesByValue = choicesToMap(choices);

        return map(fields, (field) => {
            const defaultValue =
                choicesByValue === null || choicesByValue === undefined || defaults[field.name] === DEFAULT_VALUE
                    ? defaults[field.name]
                    : choicesByValue[defaults[field.name]];
            const fieldValue = mainValue !== null && mainValue !== undefined ? mainValue : value[field.name];
            const label = t(field.label);

            return choices ? (
                <InputComponent
                    key={field.name}
                    value={fieldValue}
                    name={field.name}
                    onChange={this.onChangeProperty}
                    align={side}
                    label={label}
                    choices={choices}
                    placeholder={defaultValue}
                    hasClearChoice={hasClearChoice}
                    suffix={suffix}
                />
            ) : (
                <TextField key={field.name} label={label} suffix={suffix}>
                    <InputComponent
                        value={fieldValue}
                        name={field.name}
                        onChange={this.onChangeProperty}
                        placeholder={defaultValue}
                        revivePlaceholder
                    />
                </TextField>
            );
        });
    }

    render() {
        const { field: mainField, fields, value = {} } = this.props;
        const halfLength = parseInt(fields.length / 2, 10);

        return (
            <div className="p+">
                <LockGroup
                    value={value[mainField] !== null && value[mainField] !== undefined}
                    name="locked"
                    onChange={this.onChangeLocked}
                    left={this.renderFields(slice(fields, 0, halfLength), 'left')}
                    right={this.renderFields(slice(fields, halfLength), 'right')}
                />
            </div>
        );
    }
}
