import { connect } from '@lumapps/redux/react';

import WidgetTasks from './WidgetTasks';

import {
    initState,
    setFilteredTasks,
    setIsFilteredByLateTasks,
    setIsFilteredByReminderTasks,
    setIsFilteredByTodayTasks,
    setIsLoading,
    setIsMyTasksCheckedItemsDisplayed,
    setIsOrderedByDueDateDesc,
    setMyTasksNewTodo,
    setMyTasksTodoItems,
    setMyTasksUserContentWidget,
    setSelectedTasks,
    setTasks,
    updateTask,
} from '../../tasks/_ducks/tasks_action';

/**
 * Map the redux store properties to the WidgetTasks component.
 *
 * @param  {Object} state    The Redux Store.
 * @param  {Object} ownProps The WidgetTasks props.
 * @return {Object} The mapped props.
 */
const mapStateToProps = (state, ownProps) => {
    if (ownProps.uuid) {
        const widgetScope = state.widgets.tasks.find((widget) => widget.uuid === ownProps.uuid);

        if (widgetScope !== undefined) {
            return {
                filteredTasks: widgetScope.filteredTasks,
                isFilteredByLateTasks: widgetScope.isFilteredByLateTasks,
                isFilteredByReminderTasks: widgetScope.isFilteredByReminderTasks,
                isFilteredByTodayTasks: widgetScope.isFilteredByTodayTasks,
                isInit: widgetScope.isInit,
                isLoading: widgetScope.isLoading,
                isMyTasksCheckedItemsDisplayed: widgetScope.isMyTasksCheckedItemsDisplayed,
                isOrderedByDueDateDesc: widgetScope.isOrderedByDueDateDesc,
                myTasksNewTodo: widgetScope.myTasksNewTodo,
                myTasksTodoItems: widgetScope.myTasksTodoItems,
                myTasksUserContentWidget: widgetScope.myTasksUserContentWidget,
                selectedTasks: widgetScope.selectedTasks,
                tasks: widgetScope.tasks,
            };
        }
    }

    return {};
};

const ConnectedWidgetTasks = connect(
    mapStateToProps,
    {
        initState,
        setFilteredTasks,
        setIsFilteredByLateTasks,
        setIsFilteredByReminderTasks,
        setIsFilteredByTodayTasks,
        setIsLoading,
        setIsMyTasksCheckedItemsDisplayed,
        setIsOrderedByDueDateDesc,
        setMyTasksNewTodo,
        setMyTasksTodoItems,
        setMyTasksUserContentWidget,
        setSelectedTasks,
        setTasks,
        updateTask,
    },
)(WidgetTasks);

export { ConnectedWidgetTasks as WidgetTasks };
