export enum AppId {
    external = 'external',
    legacy = 'legacy',
    frontOffice = 'front-office',
    backOffice = 'back-office',
    webview = 'webview',
    login = 'login',
    digitalAssistant = 'digital-assistant',
    support = 'support',
    embedded = 'embedded',
    devPortal = 'dev-portal',
    designer = 'designer',
}
