import difference from 'lodash/difference';
import get from 'lodash/get';

import { fetchAll } from '@lumapps/base-api';
import { getCustomer } from '@lumapps/customer/api';
import { customerSelector } from '@lumapps/customer/ducks/selectors';

import { getInstance, listInstances, listInstanceSiblings } from '../api';
import {
    FETCH_INSTANCE_ERROR,
    FETCH_INSTANCE_LIST_ERROR,
    FETCH_INSTANCE_LIST_LOADING,
    FETCH_INSTANCE_LIST_SUCCESS,
    FETCH_INSTANCE_SIBLINGS_ERROR,
    FETCH_INSTANCE_SIBLINGS_LOADING,
    FETCH_INSTANCE_SIBLINGS_SUCCESS,
    FETCH_INSTANCE_LOADING,
    FETCH_INSTANCE_SUCCESS,
} from './actions';
import { getInstanceById } from './selectors';

const MINIMAL_INSTANCE_FIELDS = ['id', 'name', 'title', 'parent', 'slug', 'uid', 'isDefaultInstance'].join(',');

/**
 * Fetch all instances.
 *
 * @return {Function} thunk.
 */
export const fetchAllInstances = (params) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_INSTANCE_LIST_LOADING,
        });

        const instances = await fetchAll(listInstances, params);

        dispatch({
            payload: { instances },
            type: FETCH_INSTANCE_LIST_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FETCH_INSTANCE_LIST_ERROR,
        });
    }
};

/**
 * Fetch instance by id.
 *
 * @param  {Object}   params The query params object.
 * @return {Function} thunk.
 */
export const fetchInstance = (params) => async (dispatch) => {
    try {
        const { id } = params;

        dispatch({
            type: FETCH_INSTANCE_LOADING,
        });

        const response = await getInstance({
            fields: MINIMAL_INSTANCE_FIELDS,
            uid: id,
        });

        dispatch({
            payload: response.data,
            type: FETCH_INSTANCE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FETCH_INSTANCE_ERROR,
        });
    }
};

/**
 * Fetch instance siblings.
 */
export const fetchInstanceSiblings = (instanceId) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_INSTANCE_SIBLINGS_LOADING,
        });

        const response = await listInstanceSiblings({ instanceId, fields: MINIMAL_INSTANCE_FIELDS });
        const instances = get(response, ['data', 'items']) ?? [];

        dispatch({
            payload: { instances },
            type: FETCH_INSTANCE_SIBLINGS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FETCH_INSTANCE_SIBLINGS_ERROR,
        });
    }
};

/**
 * Fetch default instance.
 */
export const fetchDefaultInstance = () => async (dispatch, getState) => {
    const customer = customerSelector(getState());

    let defaultInstance = customer?.defaultInstance;
    if (!defaultInstance) {
        const customerResponse = await getCustomer({ id: customer.id, fields: 'defaultInstance' });
        defaultInstance = customerResponse.data.defaultInstance;
    }
    await dispatch(fetchInstance({ id: defaultInstance }));
};

/**
 * Get or fetch instances.
 */
export const getOrFetchInstances =
    ({ ids, ...params }) =>
    async (dispatch, getState) => {
        const entities = getInstanceById(getState());
        const existingIds = entities ? Object.keys(entities) : [];
        // Only request ids not in store.
        const requestedIds = difference(ids, existingIds);

        if (requestedIds.length === 0) {
            return;
        }

        await dispatch(fetchAllInstances({ ...params, ids: requestedIds }));
    };

/**
 * Get or fetch instance slug.
 */
export const getOrFetchInstanceSlug = (instanceId) => async (dispatch, getState) => {
    const getInstanceSlug = (currentState) => get(getInstanceById(currentState), [instanceId, 'slug']);
    let slug = getInstanceSlug(getState());

    if (!slug) {
        // OR fetch instance.
        await dispatch(fetchInstance({ id: instanceId }));
        slug = getInstanceSlug(getState());
    }
    return slug;
};
