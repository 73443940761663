/**
 * Get the value of the cookie passed as argument.
 * @param  {string}  cookieName The name of the cookie you need to get.
 * @return {string} The value of the cookie. If no cookie for this name, returns `undefined`.
 */
export const getCookie = (cookieName: string) => {
    const cookiePair = document.cookie.split(';').find((cookie) => cookie.trim().startsWith(`${cookieName}`));

    return cookiePair ? cookiePair.split('=')[1] : undefined;
};

/**
 * Set a cookie.
 * @param {string}      cookieName  The cookie identifier.
 * @param {string}      cookieValue The cookie value.
 * @param {string|Date} expires     The expire date if the cookie.
 * @param {string}      path        The path of the cookie.
 */
export const setCookie = (cookieName: string, cookieValue: string, expires: string | Date, path = '/') => {
    const expireDate = typeof expires === 'string' ? expires : expires.toUTCString();
    document.cookie = `${cookieName}=${cookieValue}; expires = ${expireDate}; path=${path} `;
};

/**
 * Remove the cookie matching the name passed as argument.
 * @param {string} cookieName The name of the cookie you need to remove.
 * @param {string} path       The path of the cookie you need to remove.
 * @param {string} domain     The domain of the cookie you need to remove.
 */
export const removeCookie = (cookieName: string, path = '/', domain = window.location.hostname) => {
    document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain} `;
};
