import React from 'react';

import * as propTypes from 'prop-types';

import { Size, Icon } from '@lumapps/lumx/react';

import { mdiInformation } from '@lumapps/lumx/icons';

/**
 * Displays an alert message: an alert circle icon and the provided text
 *
 * @return {JSX.Element | null}  AlertMessage component.
 */
const AlertMessage = ({ text, noBackground }) => {
    return (
        <div className={`alert-message__container${noBackground ? '-noBackground' : ''}`}>
            <div className="alert-message__icon-container">
                <Icon color="grey" icon={mdiInformation} size={Size.xxs} />
            </div>
            <div className="alert-message__text">{text}</div>
        </div>
    );
};

AlertMessage.propTypes = {
    text: propTypes.string.isRequired,
    noBackground: propTypes.bool,
};

AlertMessage.defaultProps = {
    noBackground: false,
};

export { AlertMessage };
