import React from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import {
    AspectRatio,
    FlexBox,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    SkeletonTypography,
    Typography,
} from '@lumapps/lumx/react';

import './index.scss';

import { TrainingCoursesViewModeVariantType } from '../../types';

export interface TrainingCardLoadingSkeletonProps {
    viewModeVariant?: TrainingCoursesViewModeVariantType;
}

const CLASSNAME = 'training-card-skeleton';

/**
 *
 * @param TrainingCardLoadingSkeletonProps
 * @returns TrainingCardLoadingSkeleton
 */
export const TrainingCardLoadingSkeleton: React.FC<TrainingCardLoadingSkeletonProps> = ({ viewModeVariant }) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <FlexBox
            className={element('box', viewModeVariant === 'ungrouped' ? 'with-shadow' : undefined)}
            orientation="vertical"
        >
            <SkeletonRectangle className={element('img')} aspectRatio={AspectRatio.horizontal} />
            <FlexBox className={viewModeVariant === 'ungrouped' ? padding('all', 'big') : padding('top', 'big')}>
                <SkeletonRectangle
                    className={margin('top', 'tiny')}
                    width={Size.xl}
                    height={Size.xs}
                    variant={SkeletonRectangleVariant.pill}
                />
                <SkeletonTypography className={margin('top', 'regular')} typography={Typography.body1} width="160px" />
                <SkeletonTypography typography={Typography.body1} width="80px" />
            </FlexBox>
        </FlexBox>
    );
};
