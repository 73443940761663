const getPermissionKey = (name: string, type: string, customContentType?: any) => {
    let fullActionStr = `${name}_${type}`.toUpperCase();

    if (customContentType) {
        fullActionStr = `${fullActionStr}_${customContentType}`;
    }

    return fullActionStr;
};

export { getPermissionKey };
