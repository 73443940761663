/* eslint-disable import/unambiguous */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetTasksController(Content, UserContent) {
        'ngInject';

        const vm = this;

        /////////////////////////////

        /**
         * Services and utilities.
         */

        vm.Content = Content;
        vm.UserContent = UserContent;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function WidgetTasksDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetTasksController,
            controllerAs: 'vm',
            link,
            replace: true,
            require: ['widgetTasks', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-tasks/views/widget-tasks.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetTasks', WidgetTasksDirective);

    /////////////////////////////

    // Export { WidgetTasksController, WidgetTasksDirective };
})();
