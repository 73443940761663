import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { translate as t } from '../../../../translations';
import { Style as StyleService } from '../../../../services/styles';
import { AccordionBox } from '../../../ui';
import { HeightSettings } from '../../settings/HeightSettings';
import { SpacingSettings } from '../../settings/SpacingSettings';
import { BorderSettings } from '../../settings/BorderSettings';
import { BackgroundSettings } from '../../settings/background/BackgroundSettings';

/**
 * Renders a content style editor inspector.
 */
export class ContentSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.contentStyle.isRequired,
        name: types.property.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Current content style. */
        value: styleTypes.contentStyle,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { value, name: key, onChange, defaults } = this.props;

        return (
            <div className="widget-style-content__settings-wrapper">
                <AccordionBox label={t('STYLE.HEIGHT')} variant="simple">
                    <HeightSettings value={value} name={key} onChange={onChange} defaults={defaults} fullHeight />
                </AccordionBox>
                <AccordionBox label={t('STYLE.SPACINGS')} variant="simple">
                    <SpacingSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BORDERS')} variant="simple">
                    <BorderSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BACKGROUND')} variant="simple">
                    <BackgroundSettings value={value} name={key} onChange={onChange} defaults={defaults} theme shadow />
                </AccordionBox>
            </div>
        );
    }
}
