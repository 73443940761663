import { cache, CACHE_TYPE } from '@lumapps/cache';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { SESSION_ID_CACHE_KEY, SESSION_ID_MAX_DURATION } from './constants';
import { SessionId } from './types';

/**
 * Returns an object with a new session id along with a ttl
 */
export const generateSessionId = (): SessionId => ({ id: generateUUID(), ttl: Date.now().toString() });

/**
 * Set session Id in local storage
 */
export const setSessionId = (defaultValue: SessionId) => {
    return cache.store(SESSION_ID_CACHE_KEY, defaultValue, CACHE_TYPE.STORAGE);
};

/**
 * Get session Id from local storage
 */
export const getSessionId = (defaultValue = generateSessionId()): SessionId => {
    return cache.retrieve(SESSION_ID_CACHE_KEY, CACHE_TYPE.STORAGE) || defaultValue;
};

/**
 * set session ID in local storage. If more than 30 minutes have elapsed since the last action or
 * if there is no session ID, a new session id is set. Otherwise we just update the time of the last action.
 */

export const handleSessionId = () => {
    const currentSessionId = getSessionId();
    if (Date.now() - Number(currentSessionId.ttl) > SESSION_ID_MAX_DURATION) {
        setSessionId(generateSessionId());
    } else {
        setSessionId({ id: currentSessionId.id, ttl: Date.now().toString() });
    }
};
