import React, { Component } from 'react';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import map from 'lodash/map';

import { getInputLanguage, getLang, translate as t } from 'components/translations';
import { getApiProfileFieldFromMap, isDesignerMode } from 'components/utils';
import { Loader } from 'components/components/ui';
import * as weatherApi from 'components/components/widgets/weather/api';

import { widgetWeatherType } from './types';
import { ForecastBlock } from './ForecastBlock';

/**
 * Displays the widget weather.
 */
export class WidgetWeather extends Component {
    static propTypes = widgetWeatherType;

    /**
     * Defines this widget as editable.
     *
     * @return {boolean} False.
     */
    static isEditable() {
        return false;
    }

    /**
     * Defines whether the widget is empty or not.
     *
     * @param  {Array}   value The widget properties.
     * @return {boolean} Whether the location is defined or not.
     */
    static isWidgetEmpty({ value }) {
        return (
            value === undefined ||
            ((value.apiProfileAddress === undefined || value.apiProfileAddress === '') &&
                (t(value.location) === '' || t(value.location) === undefined))
        );
    }

    /**
     * Determines whether the widget is hidden or not.
     *
     * @param  {Object}  params The widget props.
     * @return {boolean} Whether the widget is hidden or not.
     */
    static isWidgetHidden(params) {
        return WidgetWeather.isWidgetEmpty(params);
    }

    state = {
        isLoading: false,
        isWeatherQueryError: false,
        weather: undefined,
    };

    componentDidMount() {
        this.loadWeather();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props, prevProps)) {
            this.loadWeather();
        }
    }

    /**
     * Load the weather forecast information from widget properties.
     */
    async loadWeather() {
        const { properties } = this.props;
        const currentLang = getLang('current');
        const inputLang = isDesignerMode() ? getInputLanguage() : currentLang;
        const unit = properties.unit || 'c';
        const apiProfileAddress = get(properties, 'apiProfileAddress');
        const maxNumber = this.setMaxNumber();

        const address =
            apiProfileAddress !== undefined && apiProfileAddress !== ''
                ? getApiProfileFieldFromMap(apiProfileAddress)
                : t(properties.location, undefined, inputLang);

        const defaultAddress =
            apiProfileAddress === undefined || apiProfileAddress === ''
                ? t(properties.location, undefined, inputLang)
                : undefined;

        if (address === undefined && unit === undefined) {
            this.setState({ isWeatherQueryError: true });

            return;
        }

        this.setState({ isLoading: true });

        try {
            const {
                data: { city, forecast },
            } = await weatherApi.get({
                address,
                defaultAddress,
                unit: t(unit, undefined, inputLang),
            });

            const formatedForecasts = map(forecast, (forecastInfo) => {
                const forecastDate = moment(forecastInfo.date, 'YYYY-MM-DDTHH:mm:ss');
                forecastInfo.formattedDate = capitalize(forecastDate.format('dddd DD'));

                return forecastInfo;
            });

            const weather = {
                city,
                forecast: formatedForecasts.slice(0, maxNumber + 1),
                inputLang,
                unit,
            };

            this.setState({
                isLoading: false,
                isWeatherQueryError: false,
                weather,
            });
        } catch (exception) {
            this.setState({
                isLoading: false,
                isWeatherQueryError: true,
            });
        }
    }

    /**
     * Define the max number and force values if max number is below 1 or above 7.
     *
     * @return {string} The max number.
     */
    setMaxNumber() {
        const defaultValue = 4;
        const maxValue = 7;
        const minValue = 1;
        const { properties } = this.props;
        let maxNumber = get(properties, 'maxNumber', defaultValue);

        if (maxNumber < minValue) {
            maxNumber = minValue;
        } else if (maxNumber > maxValue) {
            maxNumber = maxValue;
        }

        return maxNumber;
    }

    render() {
        const { isLoading, isWeatherQueryError, weather } = this.state;
        const isWeatherReady = !isLoading && !isWeatherQueryError && weather;

        return (
            <>
                <div className="widget-weather__items">
                    {isWeatherReady &&
                        map(weather.forecast, (forecast, index) => (
                            <ForecastBlock
                                key={index}
                                city={weather.city}
                                forecast={forecast}
                                inputLang={weather.inputLang}
                                isFirst={index === 0}
                                unit={weather.unit}
                            />
                        ))}
                </div>

                {isWeatherQueryError && <div className="widget-weather__error">{t('FRONT.WEATHER.ERROR')}</div>}

                <Loader isLoading={isLoading} />
            </>
        );
    }
}
