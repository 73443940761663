import { MutableRefObject, useEffect } from 'react';

type TargetType = HTMLElement | null | undefined;
type ElementOrElementRef =
    | {
          /** element to add the focus within to */
          element: TargetType;
          /** Reference of the element to add the focus within to */
          elementRef?: never;
      }
    | {
          /** element to add the focus within to */
          element?: never;
          /** Reference of the element to add the focus within to */
          elementRef: MutableRefObject<TargetType>;
      };

export type UseFocusWithinOptions = ElementOrElementRef & {
    /** callback to be executed on focus in */
    onFocusIn?: (ev: FocusEvent) => void;
    /** callback to be executed on focus out */
    onFocusOut?: (ev: FocusEvent) => void;
    shouldFocus?: boolean;
};

/**
 * Hook that allows to control when there is a focus event within a given element, meaning
 * that any element within the given target will trigger the focus in and focus out events.
 * @param options - UseFocusWithinOptions
 */
export const useFocusWithin = ({
    element,
    elementRef,
    onFocusIn,
    onFocusOut,
    shouldFocus = true,
}: UseFocusWithinOptions) => {
    useEffect(() => {
        const target = element || elementRef?.current;

        if (target && shouldFocus) {
            if (onFocusIn) {
                target.addEventListener('focusin', onFocusIn);
            }

            if (onFocusOut) {
                target.addEventListener('focusout', onFocusOut);
            }
        }

        return () => {
            if (target && shouldFocus) {
                if (onFocusIn) {
                    target.removeEventListener('focusin', onFocusIn);
                }
                if (onFocusOut) {
                    target.removeEventListener('focusout', onFocusOut);
                }
            }
        };
    }, [onFocusIn, element, onFocusOut, shouldFocus, elementRef]);
};
