(function IIFE() {
    'use strict';

    /////////////////////////////

    function RowController($scope, $timeout, Cell, Media, Row, Utils, Widget, Content) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * List of wrapper style property names.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        var _WRAPPER_STYLE_PROPERTIES = ['padding', 'paddingBottom', 'paddingLeft', 'paddingRight', 'paddingTop'];

        /**
         * The full list of CSS classes of the row.
         *
         * @type {Array}
         */
        var _rowClasses = [];

        /**
         * The full list of CSS classes of the row wrapper.
         *
         * @type {Array}
         */
        var _rowWrapperClasses = [];

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the row CSS class.
         *
         * @return {Array} The row CSS class(es).
         */
        function getRowClass() {
            Utils.empty(_rowClasses);

            if (vm.root) {
                _rowClasses.push('component-row--is-root');

                var isCurrentRow = _.get(Row.getCurrent(), 'uuid') === vm.row.uuid;

                if (isCurrentRow && Row.isFocused) {
                    _rowClasses.push('component-row--is-focused');
                }

                if (isCurrentRow && Row.isActive) {
                    _rowClasses.push('component-row--is-active');
                }
            }

            if (angular.isDefinedAndFilled(_.get(vm.row, 'properties.class'))) {
                Array.prototype.push.apply(
                    _rowClasses,
                    Utils.getCustomClass(vm.row.properties.class, 'component-row--'),
                );
            }

            return _rowClasses;
        }

        /**
         * Get the row wrapper CSS class.
         *
         * @return {Array} The row wrapper CSS class(es).
         */
        function getRowWrapperClass() {
            Utils.empty(_rowWrapperClasses);

            var properties = vm.row.properties;
            properties = properties || {};

            if (vm.isRowFluid()) {
                _rowWrapperClasses.push('component-row__wrapper--is-fluid');
            } else {
                if (vm.root && properties.sizeVariant) {
                    _rowWrapperClasses.push(`component-row__wrapper--is-${properties.sizeVariant}`);
                } else {
                    _rowWrapperClasses.push('component-row__wrapper--is-fixed');
                }
            }

            return _rowWrapperClasses;

        }

        /**
         * Get the padding style values of the row.
         *
         * @return {Object} An object with the padding values for the current row.
         */
        function getRowWrapperStyle() {
            var properties = vm.row.properties;
            properties = properties || {};

            if (angular.isUndefinedOrEmpty(properties)) {
                return {};
            }

            return _.pick(properties.style, _WRAPPER_STYLE_PROPERTIES);
        }

        /**
         * Get the CSS styles of the row.
         *
         * @return {Object} The row CSS styles.
         */
        function getRowStyle() {
            var properties = vm.row.properties;
            properties = properties || {};

            if (angular.isUndefinedOrEmpty(properties)) {
                return {};
            }

            return Media.adjustBackgroundImage(_.omit(properties.style, _WRAPPER_STYLE_PROPERTIES));
        }

        /**
         * Check if the row is fluid (aka fullWidth) or not.
         *
         * @return {boolean} Whether the row is fluid or not.
         */
        function isRowFluid() {
            return _.get(vm.row, 'properties.fullWidth', false);
        }

        /**
         * Open row settings.
         */
        function openRowSettings() {
            var currentRow = Row.getCurrent();
            Row.setCurrent(vm.row);
            Row.isFocused = false;

            if (currentRow === vm.row) {
                Row.isActive = true;
            } else {
                Row.isActive = false;
                $timeout(function timedOut() {
                    Row.isActive = true;
                });
            }

            var row = Row.getCurrent();

            if (Utils.hasChild(row, Cell.getCurrent())) {
                Cell.isFocused = true;
                Cell.isActive = false;
            } else {
                Cell.resetCell();
            }

            if (Utils.hasChild(row, Widget.getCurrent())) {
                Widget.isFocused = true;
                Widget.isActive = false;
            } else {
                Widget.resetWidget();
            }
        }

        /////////////////////////////

        vm.getRowClass = getRowClass;
        vm.getRowWrapperStyle = getRowWrapperStyle;
        vm.getRowWrapperClass = getRowWrapperClass;
        vm.getRowStyle = getRowStyle;
        vm.isRowFluid = isRowFluid;
        vm.openRowSettings = openRowSettings;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * We catch the event coming from a widget to broadcast it to all the cells within the same row.
         */
        $scope.$on('widget-size-update', function onWidgetSizeUpdate() {
            $scope.$broadcast('widget-size-update-in-row');
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.root = angular.isDefined(vm.root) ? vm.root : false;

            const currentContent = Content.getCurrent();

            if (angular.isUndefined(vm.row.properties)) {
                vm.row.properties = {};
            }

            var properties = vm.row.properties;

            // Migrate styles from version 1 to 2.
            if (angular.isDefinedAndFilled(properties.color)) {
                if (angular.isUndefined(properties.style)) {
                    properties.style = {};
                }

                properties.style.backgroundColor = properties.color;
                delete properties.color;
            }

            // Migrate styles from version 2 to 3.
            Utils.copyProperties(
                [
                    'paddingBottom',
                    'paddingLeft',
                    'paddingRight',
                    'paddingTop',
                    'marginBottom',
                    'marginLeft',
                    'marginRight',
                    'marginTop',
                ],
                '',
                'style',
                properties,
                true,
            );

            //  Expliciting the Row context and overriding the full width for Spaces
            if(currentContent?.renderingType === 'space') {
                properties.isSpace = true;
                properties.fullWidth = false;
            }
        }

        init();
    }

    /////////////////////////////

    /**
     * Directive that displays and handles a row in the designer.
     *
     * @param {boolean} [customizationDisabled] Indicates if the row is customizable by the user or not.
     * @param {boolean} root                    Indicates if the row is at the root level structure.
     * @param {Object}  row                     The object containing the row properties and settings.
     */

    function RowDirective(Content, Features, RecursionHelper) {
        'ngInject';

        function compile(el) {
            return RecursionHelper.compile(el, function recursiveCompile(scope, recursiveElement, attrs, ctrl) {
                recursiveElement.on('click', function onRecursiveElementClick(evt) {
                    if (
                        Content.getAction() !== 'get' &&
                        (
                            Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER') ||
                            Content.isCurrentDesignerMode('NEW_SIMPLE_EXPERT_MODE')
                        )
                    ) {
                        evt.stopPropagation();

                        if (
                            !scope.vm.root ||
                            (!angular.element(evt.target).hasClass('component-row') &&
                                !angular.element(evt.target).hasClass('component-row__wrapper'))
                        ) {
                            return;
                        }

                        scope.$apply(function applyRowSettingsOpening() {
                            ctrl.openRowSettings();
                        });
                    }
                });

                scope.$on('$destroy', function onDestroy() {
                    recursiveElement.off();
                });
            });
        }

        return {
            bindToController: true,
            compile: compile,
            controller: RowController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                customizationDisabled: '=lsCustomizationDisabled',
                root: '=lsRoot',
                row: '=lsRow',
            },
            templateUrl: '/client/front-office/modules/content/modules/row/views/row.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsRow', RowDirective);
})();
