import groupBy from 'lodash/groupBy';

import { Metadata, MetadataByParent } from '@lumapps/metadata/types';

import { PickerMetaData } from '../types';

export interface MetadataTreeChild extends PickerMetaData {
    level?: number;
}

export type MetadataTree = Array<MetadataTreeChild>;

/**
 * A recursive function to build a flat array of metadata
 * with their levels, sorted by their sortOrder parameter.
 */
const buildMetadataFamily = (
    /**
     * An object with familyKeys as keys and array of children as values.
     * Used to build the current level.
     */
    metadataByFamily: MetadataByParent,
    /** The current family to build */
    currentFamilyKey: Metadata['familyKey'],
    /** The current level */
    level = 0,
): MetadataTreeChild[] => {
    /** If the family is not in the dictionary, return early.  */
    if (!metadataByFamily[currentFamilyKey]) {
        return [];
    }

    return (
        metadataByFamily[currentFamilyKey]
            /** Sort the children by sortOrder */
            .sort((a, b) => +a.sortOrder - +b.sortOrder)
            /** Remove all that are parent of themselves */
            .filter((item) => item.id !== currentFamilyKey)
            .reduce((acc: MetadataTreeChild[], curr: Metadata) => {
                /** Add the level to the element  */
                const treeElement: MetadataTreeChild = {
                    level,
                    ...curr,
                };

                /** If the metadata is parent of another, add it to the array followed by its children.  */
                if (metadataByFamily[curr.id]) {
                    return [...acc, treeElement, ...buildMetadataFamily(metadataByFamily, curr.id, level + 1)];
                }
                /** If not, simply add it to the array */
                return [...acc, treeElement];
            }, [])
    );
};

/**
 * Build an array of metadata calculating its level for each metadata.
 */
export const buildMetadataTree = (metadataList: Array<Metadata>, familyKey: Metadata['familyKey']): MetadataTree => {
    const metadataByParent = groupBy(metadataList, 'parent');
    /** If there is no children linked to the family key, simply return the array as is. */
    if (!metadataByParent[familyKey]) {
        return metadataList;
    }

    return buildMetadataFamily(metadataByParent, familyKey);
};
