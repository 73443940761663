import last from 'lodash/last';

import { ParagraphElement } from '@lumapps/wrex-typography/types';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';

import { Transforms } from '..';
import { getLastRootPath } from './getLastRootPath';
import { isEditorEmpty } from './isEditorEmpty';

/* utils to set a base selection in case the user did not focus
in the editor yet but wants to access toolbar actions */

export const initSelection = (editor: any, elementType: 'block' | 'inline') => {
    /* if editor is NOT empty (edition mode) */
    if (!isEditorEmpty(editor)) {
        const lastRootPath = getLastRootPath(editor);

        const lastRootElement = last(editor.children);

        const isLastElementAParagraph = isParagraph(lastRootElement);

        /* If the element to insert is and inline element and the last element in the editor is a paragraph element */
        if (elementType === 'inline' && isLastElementAParagraph) {
            /* we get the length of the paragraph to build the path to insert the element */
            const paragraphChildLength = (lastRootElement as ParagraphElement).children.length;
            /* we set the selection at the paragraph element */
            const newSelectionToSet = {
                offset: 0,
                path: [lastRootPath[0], paragraphChildLength - 1],
            };
            Transforms.select(editor, newSelectionToSet);
            /* then move at the end of the paragraph to have the offset where to insert the element */
            Transforms.move(editor, { distance: 1, unit: 'line' });
            /* and return the new editor selection */
            return editor.selection;
        }
        /* If the element to insert is a block OR if it is an inline but the last element is not a paragraph element */
        /* we insert a new paragraph element at the end */

        Transforms.insertNodes(editor, createParagraph(), { at: [lastRootPath[0] + 1] });
        /* set the selection on it last paragraph element and return it location */
        const newSelection = {
            offset: 0,
            path: [lastRootPath[0] + 1, 0],
        };
        Transforms.select(editor, newSelection);

        return {
            focus: newSelection,
            anchor: newSelection,
        };
    }

    /* if editor is empty (creation mode) */
    /* we set the selection at the first paragraph element and return the location */
    const newSelection = {
        offset: 0,
        path: [0, 0],
    };
    Transforms.select(editor, newSelection);

    return {
        focus: newSelection,
        anchor: newSelection,
    };
};
