(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsLightboxController($document, $rootScope, $scope, InitialSettings, Lightbox, LxDepthService, Media,
        Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The index of the image being currently displayed.
         *
         * @type {number}
         */
        var _currentIndex = 0;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The lightbox jQuery element.
         *
         * @type {jQElement}
         * @constant
         */
        vm.$LIGHTBOX = undefined;

        /**
         * The lightbox wrapper jQuery element.
         *
         * @type {jQElement}
         * @constant
         */
        vm.$LIGHTBOX_WRAPPER = undefined;

        /**
         * The previous button jQuery element in the lightbox.
         *
         * @type {jQElement}
         * @constant
         */
        vm.$LIGHTBOX_PREV = undefined;

        /**
         * The next button jQuery element in the lightbox.
         *
         * @type {jQElement}
         * @constant
         */
        vm.$LIGHTBOX_NEXT = undefined;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the transform properties for all browsers. Used for the transition effect between images in the lightbox.
         *
         * @param  {number} index The position of the image in the list to use to calculate the transition.
         * @return {Object} An object of all the transform properties for all browsers.
         */
        function _getTransformProperty(index) {
            var translate3d = 'translate3d(' + ((100 * index) * -1) + '%, 0, 0)';

            return {
                'moz-transform': translate3d,
                'ms-transform': translate3d,
                'o-transform': translate3d,
                'transform': translate3d,
                'webkit-transform': translate3d,
            };
        }

        /**
         * Update the next and previous buttons display.
         * On the first item, do not show a previous button and on the last one do not show a next button.
         */
        function _updateNavButtons() {
            vm.$LIGHTBOX.removeClass('lightbox--first-image lightbox--last-image');

            if (_currentIndex === 0) {
                vm.$LIGHTBOX.addClass('lightbox--first-image');
            }

            if (_currentIndex === vm.imgSources.length - 1) {
                vm.$LIGHTBOX.addClass('lightbox--last-image');
            }
        }

        /**
         * Transition to the next item in the list.
         */
        function _next() {
            vm.$LIGHTBOX_WRAPPER.css(_getTransformProperty(_currentIndex + 1));

            _currentIndex++;

            _updateNavButtons();
        }

        /**
         * Transition to the previous item in the list.
         */
        function _previous() {
            vm.$LIGHTBOX_WRAPPER.css(_getTransformProperty(_currentIndex - 1));

            _currentIndex--;

            _updateNavButtons();
        }

        /**
         * Handle pressing down keys while the lightbox is displayed.
         *
         * @param {Event} evt The keydown event triggering this method.
         */
        function _keyDown(evt) {
            switch (evt.which) {
                // Left.
                case 37:
                    if (_currentIndex > 0) {
                        _previous();
                    }
                    break;

                // Right.
                case 39:
                    if (_currentIndex < vm.imgSources.length - 1) {
                        _next();
                    }
                    break;

                default:
                    return;
            }

            evt.preventDefault();
        }

        /**
         * Bind the click and keydown events to the correct selectors.
         */
        function _bindEvents() {
            vm.$LIGHTBOX.bind('click', Lightbox.close);

            if (vm.imgSources.length === 1) {
                return;
            }

            $document.bind('keydown', _keyDown);

            vm.$LIGHTBOX_PREV.bind('click', function onPreviousButtonClick(evt) {
                evt.preventDefault();
                evt.stopPropagation();
                _previous();
            });

            vm.$LIGHTBOX_NEXT.bind('click', function onNextButtonClick(evt) {
                evt.preventDefault();
                evt.stopPropagation();
                _next();
            });
        }

        /**
         * Close the lightbox and unbind events.
         */
        function _close() {
            vm.$LIGHTBOX.removeClass('lightbox--is-shown');

            $document.unbind('keydown', _keyDown);
            vm.$LIGHTBOX.unbind('click', Lightbox.close);
        }

        /**
         * Set the sources of the images to be used to build the image gallery.
         *
         * @param {Array} imgList A list of images or image urls.
         */
        function _setImageSources(imgList) {
            vm.imgSources = _.map(imgList, function forEachMedia(img) {
                var mediaContent = Media.getMediaContent(img, Translation.inputLanguage, true);

                return (angular.isString(img)) ?
                    img : Media.getMediaImage(img, mediaContent, true, InitialSettings.MAX_IMAGE_SIZE);
            });
        }

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the lightbox opens set all the images.
         *
         * @param {Event} evt The original event triggering this method.
         */
        vm.unregisterLightboxOpen = $rootScope.$on('ls-lightbox__open', function onLightboxOpen(evt, imgList, index) {
            if (!angular.isArray(imgList)) {
                imgList = [imgList];
            }

            vm.init(imgList, index);
        });

        /**
         * When the lightbox closes clear all the events that have been bound.
         */
        vm.unregisterLightboxClose = $rootScope.$on('ls-lightbox__close', function onLightboxClose() {
            _close();
        });

        /**
         * Cleanup on destroy.
         */
        $scope.$on('$destroy', function onDestroy() {
            if (angular.isDefined(vm.unregisterLightboxOpen) && angular.isFunction(vm.unregisterLightboxOpen)) {
                vm.unregisterLightboxOpen();

                delete vm.unregisterLightboxOpen;
            }

            if (angular.isDefined(vm.unregisterLightboxClose) && angular.isFunction(vm.unregisterLightboxClose)) {
                vm.unregisterLightboxClose();

                delete vm.unregisterLightboxClose;
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         *
         * @param {Array}  imgList   The list of images to display in the lightbox.
         * @param {number} [index=0] The position of the image in the list we want to open right away.
         */
        vm.init = function init(imgList, index) {
            _currentIndex = index || 0;

            LxDepthService.register();

            _setImageSources(imgList);

            _bindEvents();

            _updateNavButtons();

            vm.$LIGHTBOX_WRAPPER.css(_getTransformProperty(_currentIndex));

            vm.$LIGHTBOX.css('z-index', LxDepthService.getDepth()).addClass('lightbox--is-shown');
        };
    }

    /////////////////////////////

    /**
     * A directive that displays an image list above everything else in a gallery manner.
     */

    function lsLightboxDirective() {
        'ngInject';

        function LightboxDirectiveLink(scope, el) {
            scope.vm.$LIGHTBOX = el;
            scope.vm.$LIGHTBOX_WRAPPER = el.find('.lightbox__wrapper');
            scope.vm.$LIGHTBOX_PREV = el.find('.lightbox__nav--prev');
            scope.vm.$LIGHTBOX_NEXT = el.find('.lightbox__nav--next');
        }

        return {
            bindToController: true,
            controller: LsLightboxController,
            controllerAs: 'vm',
            link: LightboxDirectiveLink,
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: '/client/common/modules/lightbox/views/lightbox.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsLightbox', lsLightboxDirective);
})();
