import BaseApi, { makeParamsSerializer } from '@lumapps/base-api';
import { MemberRoles } from '@lumapps/sa-members/types';
import { UserListRequestParams } from '@lumapps/user/types';

import {
    PostProgram,
    Program,
    PutProgram,
    ProgramStatus,
    FetchProgramsSortFields,
    FetchProgramsSortDir,
} from '../types';

const programsApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy' });

export interface FetchProgramsParams {
    /**
     * Filter programs by status
     * Default ['live']
     */
    statuses?: Array<ProgramStatus>;
    /**
     * Filter programs by ids
     * Default []
     */
    ids?: Array<string>;
    /**
     * Filter programs by slug
     * Default null
     */
    slug?: string | null;
    /**
     * Filter programs by name
     * Default null
     */
    name?: string | null;
    /**
     * Filter programs by current user roles
     * Default []
     */
    roles?: Array<MemberRoles>;
    /**
     * The field to sort the programs by
     * Default "createdAt"
     */
    sortField?: FetchProgramsSortFields;
    /**
     * The direction to sort the programs on
     * Default "1"
     */
    sortDir?: FetchProgramsSortDir;
    /**
     * Cursor for pagination
     * Default null
     */
    cursor?: string | null;
    /**
     * The max results to fetch
     * Default 100
     */
    maxResults?: number;
}

// When fetching all the programs, we want them to be sorted by name by default.
const fetchPrograms = ({ sortField = FetchProgramsSortFields.name, ...query }: FetchProgramsParams = {}) => {
    return programsApi.get('/programs', {
        params: { sortField, ...query },
        paramsSerializer: makeParamsSerializer({ arrayFormat: 'comma', encode: false }),
    });
};

const fetchProgram = (programId: Program['id']) => {
    return programsApi.get(`/programs/${programId}`);
};

const editProgram = (program: PutProgram, programId: Program['id']) => {
    return programsApi.put(`/programs/${programId}`, program);
};

const createProgram = (program: PostProgram) => {
    return programsApi.post('/programs', program);
};

const deleteProgram = (programId: Program['id']) => {
    return programsApi.delete(`/programs/${programId}`);
};

export interface FetchUsersForProgramParams extends Partial<UserListRequestParams> {
    organizationId: string;
    instanceId: string;
    programId?: Program['id'];
    wrapper?: {
        end: string;
        start: string;
    };
}

const fetchUsersForProgram = (params: FetchUsersForProgramParams, fetchKey?: string) => {
    return programsApi.post(
        '/users/search',
        {
            query: params.searchTerm,
            maxResults: params.maxResults,
            lang: params.lang,
            programId: params.programId,
            cursor: params.cursor,
            wrapper: {
                end: '</mark>',
                start: '<mark class="search-result-highlight">',
            },
            ...params,
        },
        undefined,
        fetchKey !== undefined,
        fetchKey,
    );
};

const cancelFetchUsersForProgram = (fetchKey: string) => {
    return programsApi.cancel('/users/search', undefined, fetchKey);
};

export {
    programsApi,
    createProgram,
    fetchPrograms,
    fetchProgram,
    editProgram,
    deleteProgram,
    fetchUsersForProgram,
    cancelFetchUsersForProgram,
};
