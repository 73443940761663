import React, { Fragment } from 'react';

import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { classnames, margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { SearchField } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import {
    Button,
    ColorPalette,
    ColorVariant,
    Emphasis,
    FlexBox,
    List,
    ListDivider,
    ListItem,
    Size,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { User } from '../../types';
import { UserBlock, UserBlockSkeletons } from '../UserBlock';

import './index.scss';

export interface UserListProps {
    /** A react node or text to be displayed when there is no search and the list is still empty (renders null when omitted) */
    emptyState?: React.ReactNode;
    /** Whether the users list is loading or not, displays skeletons */
    isLoading?: boolean;
    /** The callback to handle a user clicking the "load more" button. When defined, the button is displayed at the bottom */
    onLoadMore?(): void;
    /** The callback to handle a user using the search field. When defined a search field is displayed at the top */
    onSearch?(searchText: string): void;
    /** The callback to handle a user clicking the "clear" button on the search field */
    onSearchClear?(): void;
    /** Scope of the data attributes */
    scope?: string;
    /** The current value of the search field (note that the onSearch callback should be defined too) */
    searchText?: string;
    /** The array of users to display */
    users: Array<{
        id: User['id'];
        fullName: User['fullName'];
        profilePictureUrl?: User['profilePictureUrl'];
        email?: User['email'];
        primaryFields?: string[];
        secondaryFields?: string[];
    }>;
    /** Whether dividers are displayed between users block */
    withDividers?: boolean;
}

/**
 * This component displays a list of users by reusing the userBlock
 * (profile picture, name, email/primary/secondary fields)
 *
 * Several options are available
 * - a skeleton, if the list is loading (i.e. isLoading is true), there are two cases:
 *       1. only a skeleton when users is empty
 *       2. a skeleton after the list when loading more, i.e. users is not empty
 * - a load more button (optional, you have to pass onLoadMore callback for it to be displayed)
 *       NB: to manage the button disappearing, simply pass undefined in onLoadMore once there is no more elements.
 * - a search field (optional, you have to pass onSearch callback for it to be displayed)
 * - dividers to display the users separated
 */
export const UserList: React.FC<UserListProps> = ({
    emptyState,
    isLoading = false,
    onLoadMore,
    onSearch,
    onSearchClear,
    scope,
    searchText,
    users,
    withDividers = false,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames('user-list');

    const { get } = useDataAttributes(scope || 'user-list');

    const hasNoResults = !isLoading && (!users || isEmpty(users));

    if (hasNoResults && !onSearch && !emptyState) {
        return null;
    }

    const renderNoResults = () => {
        if (onSearch && searchText) {
            return (
                <NoResultsState
                    className={element('no-results', [
                        margin('top', 'huge'),
                        withDividers && margin('horizontal', 'huge'),
                    ])}
                    textProps={{
                        typography: Typography.body1,
                        color: ColorPalette.dark,
                        colorVariant: ColorVariant.L2,
                    }}
                    searchValue={searchText}
                />
            );
        }

        return emptyState;
    };

    return (
        <>
            {onSearch && (
                <FlexBox fillSpace className={element('search-field', [withDividers && margin('horizontal', 'huge')])}>
                    <SearchField
                        label={translateKey(GLOBAL.SEARCH_FOR_SOMEONE)}
                        value={searchText}
                        onSearch={onSearch}
                        textFieldProps={{ onClear: onSearchClear }}
                    />
                </FlexBox>
            )}
            {hasNoResults && (onSearch || emptyState) ? (
                renderNoResults()
            ) : (
                <List>
                    {users.map((user, index) => {
                        return (
                            <Fragment key={user.id}>
                                {withDividers && index !== 0 && <ListDivider />}
                                <ListItem
                                    className={classnames({
                                        [margin('horizontal', 'huge')]: withDividers,
                                        [margin('bottom')]: withDividers,
                                    })}
                                    size={Size.regular}
                                >
                                    <UserBlock
                                        userId={user.id}
                                        picture={user.profilePictureUrl}
                                        {...pick(user, ['email', 'fullName', 'primaryFields', 'secondaryFields'])}
                                        {...get({ element: 'user-block', action: user.id })}
                                    />
                                </ListItem>
                            </Fragment>
                        );
                    })}
                    {withDividers && isLoading && !isEmpty(users) && <ListDivider />}
                    {isLoading && (
                        <UserBlockSkeletons
                            withDivider={withDividers}
                            hasAdditionalInformation={false}
                            className={element(
                                'skeleton',
                                withDividers ? [margin('horizontal', 'huge'), margin('bottom')] : undefined,
                            )}
                        />
                    )}
                </List>
            )}
            {!isEmpty(users) && onLoadMore && (
                <FlexBox
                    fillSpace
                    className={element(
                        'skeleton',
                        withDividers ? [margin('horizontal', 'huge'), margin('bottom')] : undefined,
                    )}
                >
                    <Button
                        fullWidth
                        onClick={onLoadMore}
                        emphasis={Emphasis.medium}
                        isDisabled={isLoading}
                        {...get({ element: 'button', action: 'load-more' })}
                    >
                        {translateKey(GLOBAL.LOAD_MORE)}
                    </Button>
                </FlexBox>
            )}
        </>
    );
};
