export type TranslateKey = string;
export type TranslateObject<T = any> = Record<string, T>;

export interface TranslatableObject<T = string> {
    value?: T;
    lang?: string;
    // The translations key is required when creating a new object
    // But not when returned by the backend see: https://github.com/lumapps/api-v2/blob/master/doc/adr/0027-define-localized-parameter-format.md
    translations: TranslateObject<T>;
}

/**
 * Contains the user's language, the dictionnary of the translations keys, the site's languages
 * and the state.
 */
export interface TranslationConfig {
    loaded?: boolean;
    preferredLanguage?: string;
    translations: Record<string, Record<string, string>>;
    error?: boolean;
    instanceLanguages?: string[] | undefined;
    instanceDefaultLanguage?: string | undefined;
}

export type TranslateAndReplaceReplacements = {
    key: TranslateKey;
    replacements: Record<string, string>;
};
export type TranslateTypes = TranslateKey | TranslateObject | TranslateAndReplaceReplacements;

export enum PluralTypes {
    ONE = 'one',
    TWO = 'two',
    MORE = 'other',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    OTHER = 'other',
}

export const getPluralType = (count: number) => {
    switch (count) {
        case 1:
            return PluralTypes.ONE;
        case 2:
            return PluralTypes.TWO;
        default:
            return PluralTypes.OTHER;
    }
};

export type PluralObject = Partial<Record<PluralTypes, string>>;

export interface TranslationAPI {
    /**
     * Returns the translate and pluralized value for the provided key using the defined preferred language.
     */
    pluralize(key: string, count: number): string;

    /**
     * Returns the translation for the provided key using the defined preferred language.
     */
    translateKey(key: TranslateKey, overrideLanguage?: string): string;

    /**
     * Returns the value from a object based using overridedLanguage || preferredLanguage.
     * If there is no results and ignoreFallbackLanguageWhenTranslating falsy, we fallback to alternativeLanguage or defaultLanguage
     */
    translateObject<T = string>(
        obj: TranslateObject | undefined | null,
        overrideLanguage?: string,
        ignoreFallbackLanguageWhenTranslating?: boolean,
    ): T | null;

    /**
     * Returns the translation for the provided key using the defined preferred language
     * and replace variable by their values.
     */
    translateAndReplace(
        key: TranslateKey,
        replacements: Record<string, string | number>,
        overrideLanguage?: string,
    ): string;

    /**
     * Returns the translation for the provided key using the defined preferred language
     * and replace variable by their values, accepting React Element as value.
     */
    translateAndReplaceWithComponents(
        key: TranslateKey,
        replacements: Record<string, string | number | JSX.Element>,
        overrideLanguage?: string,
    ): string | (string | number | JSX.Element)[];

    /**
     * Returns the translation for the provided key using the defined preferred language
     * and replace variable by their values.
     */
    translateAndReplaceList(
        key: TranslateKey,
        keys: string[],
        replacements: string[],
        overrideLanguage?: string,
    ): string;

    /**
     * Wrapper that allows to execute any of the translate functions depending on the parameters provided
     */
    translate(toTranslate: TranslateTypes | undefined | null, overrideLanguage?: string): string | null;

    /**
     * Returns the translate and pluralized value for the provided object using the defined preferred language.
     * @param obj Object to pluralize
     * @param count The number of elements
     */
    pluralizeObject(obj: PluralObject, count: number): string;
}

export interface ContextTranslationAPI {
    pluralize(context: TranslationConfig): TranslationAPI['pluralize'];

    pluralizeObject(context: TranslationConfig): TranslationAPI['pluralizeObject'];

    translateKey(context: TranslationConfig): TranslationAPI['translateKey'];

    translateObject(context: TranslationConfig): TranslationAPI['translateObject'];

    translateAndReplace(context: TranslationConfig): TranslationAPI['translateAndReplace'];

    translateAndReplaceWithComponents(context: TranslationConfig): TranslationAPI['translateAndReplaceWithComponents'];

    translateAndReplaceList(context: TranslationConfig): TranslationAPI['translateAndReplaceList'];

    translate(context: TranslationConfig): TranslationAPI['translate'];
}
