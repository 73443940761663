(function IIFE() {
    'use strict';

    /////////////////////////////

    function NavigationFolderController(DriveFileService) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.DriveFileService = DriveFileService;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Fold or unfold a folder.
         *
         * @param {Object} folder A Google Drive folder.
         */
        function unfold(folder) {
            folder.displayChildren = !folder.displayChildren;

            if (!folder.displayChildren) {
                return;
            }

            DriveFileService.loadSubFolders(vm.listKey, _.map(folder.children, 'id'));
        }

        /////////////////////////////

        vm.unfold = unfold;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.level = vm.level || 1;
        }

        init();
    }

    /////////////////////////////

    /**
     * Displays a Google Drive folder in the sidebar in the widget file management.
     *
     * @param {Object} folder  The folder to display and list the files of.
     * @param {number} level   The current level of the folder within the tree.
     * @param {string} listKey The identifier of the list of folders.
     */

    function NavigationFolderDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: NavigationFolderController,
            controllerAs: 'vm',
            replace: true,
            require: ['^widgetFileManagement'],
            restrict: 'E',
            scope: {
                folder: '<lsFolder',
                level: '<lsLevel',
                listKey: '@lsListKey',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-file-management/views/partials/navigation-folder.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsNavigationFolder', NavigationFolderDirective);
})();
