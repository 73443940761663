import { ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { reviews } from '@lumapps/reports/routes';
import { Link } from '@lumapps/router';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta } from '../../types';
import {
    getContentTitle,
    getParentInstanceTitle,
    getFormattedContentEndDate,
    isTheContentAPost,
    getParentContentTitle,
    isContentExpired,
} from '../contentUtils';
import { getContentRoute, getEditContentRoute, getContentLink, getEmptyRoute, getPostLink } from '../linksUtils';

const notificationTypeContent: Record<string, NotificationMeta> = {
    [NotificationTypes.ContentExpiration]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.yellow,
            icon: 'calendar-alert',
        },
        getDescription: ({ notification, translation }) => {
            // If content expiration date is in the past, we show the content expired description
            if (isContentExpired(notification)) {
                return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_EXPIRED_DESCRIPTION, {
                    CONTENT_TITLE: getContentTitle(translation, notification),
                });
            }
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_EXPIRATION_DESCRIPTION, {
                CONTENT_TITLE: getContentTitle(translation, notification),
                EXPIRATION_DATE: getFormattedContentEndDate(notification),
            });
        },
        getRoute: getEditContentRoute,
    },
    [NotificationTypes.ContentLike]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.red,
            icon: 'heart',
        },
        getDescription: ({ notification, count, translation, senderDetails, currentLanguage }) => {
            let key;
            const contentTitle = getContentTitle(translation, notification);

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? NOTIFICATIONS_CENTER.CONTENT_POST_LIKE_DESCRIPTION
                    : NOTIFICATIONS_CENTER.CONTENT_POST_LIKE_WITHOUT_TITLE_DESCRIPTION;
            } else {
                key = NOTIFICATIONS_CENTER.CONTENT_LIKE_DESCRIPTION;
            }

            if (count > 1) {
                key = translation.pluralize(`${key}_OTHERS`, count - 1);
            }

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                CONTENT_TITLE: contentTitle,
                NB_OTHERS: count - 1,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: (params) => {
            if (!params) {
                return null;
            }
            if (isTheContentAPost(params.notification)) {
                return getPostLink(params);
            }

            return getContentLink(params);
        },
    },
    [NotificationTypes.ContentMention]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'at',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.CONTENT_MENTION_WITH_SITE_NAME;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                CONTENT_TITLE: contentTitle,
                SITE_NAME: getParentInstanceTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getContentLink,
    },
    [NotificationTypes.ContentNew]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'newspaper-variant',
        },
        getDescription: ({ notification, translation, senderDetails, currentLanguage }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_NEW_DESCRIPTION, {
                CONTENT_TITLE: getContentTitle(translation, notification),
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
            });
        },
        getRoute: getContentRoute,
        getLinkAs: getContentLink,
    },
    [NotificationTypes.ContentRefused]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.red,
            icon: 'close',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_REFUSED_DESCRIPTION, {
                CONTENT_TITLE: getContentTitle(translation, notification),
            });
        },
        getRoute: getEditContentRoute,
    },
    [NotificationTypes.ContentUpdate]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'newspaper-variant',
        },
        getDescription: ({ notification, translation, senderDetails, currentLanguage }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_UPDATE_DESCRIPTION, {
                CONTENT_TITLE: getContentTitle(translation, notification),
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
            });
        },
        getRoute: getContentRoute,
        getLinkAs: getContentLink,
    },
    [NotificationTypes.ContentValidated]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.green,
            icon: 'check-circle',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_VALIDATED_DESCRIPTION, {
                CONTENT_TITLE: getContentTitle(translation, notification),
            });
        },
        getRoute: getContentRoute,
        getLinkAs: getContentLink,
    },
    [NotificationTypes.ContentWorkflow]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'timer-sand-full',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_WORKFLOW_DESCRIPTION, {
                CONTENT_TITLE: getContentTitle(translation, notification),
            });
        },
        getRoute: getEditContentRoute,
    },

    [NotificationTypes.ContentReportCreated]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key =
                count === 1
                    ? NOTIFICATIONS_CENTER.CONTENT_REPORT_CREATED_ONE_DESCRIPTION
                    : NOTIFICATIONS_CENTER.CONTENT_REPORT_CREATED_MORE_DESCRIPTION;

            return translation.translateAndReplace(key, {
                LAST_REPORTER_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHER_REPORTS: count - 1,
                CONTENT_TITLE: contentTitle,
            });
        },
        getRoute: getContentRoute,
        getLinkAs: () => ({ linkAs: Link, to: reviews() }),
    },
    [NotificationTypes.ContentReportReviewed]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, translation }) => {
            const contentTitle = getContentTitle(translation, notification);

            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_REPORT_REVIEWED_DESCRIPTION, {
                CONTENT_TITLE: contentTitle,
            });
        },
        getRoute: getContentRoute,
        getLinkAs: getContentLink,
    },
    [NotificationTypes.ContentReportAccepted]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-message-outline',
        },
        getDescription: ({ notification, translation }) => {
            const contentTitle = getContentTitle(translation, notification);

            return translation.translateAndReplace(NOTIFICATIONS_CENTER.CONTENT_REPORT_ACCEPTED_DESCRIPTION, {
                CONTENT_TITLE: contentTitle,
            });
        },
        getRoute: getContentRoute,
        getLinkAs: getContentLink,
    },
};

export { notificationTypeContent };
