(function IIFE() {
    'use strict';

    /////////////////////////////

    function CloudSearchCustomFieldsFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/cloudsearch/customfields/:methodKey', {}, {
            list: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('CloudSearchCustomFieldsFactory', CloudSearchCustomFieldsFactory);
})();
