import includes from 'lodash/includes';

import { get } from '@lumapps/constants';
import { TranslationAPI } from '@lumapps/translations';
import { getKeyForLanguage } from '@lumapps/translations/keys';

const Config = get();

const getDefaultLanguage = () => {
    return Config.defaultLanguage;
};

/** Returns the first possible alternative language */
const getFirstAlternativeLanguage = () => {
    const userAlternativeLangs =
        Config.userAlernativeLangs.length > 0 ? Config.userAlernativeLangs : [Config.defaultLanguage];

    let possibleAlternativeLang = userAlternativeLangs[0];

    if (possibleAlternativeLang === Config.userLang && Config.userAlernativeLangs.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        possibleAlternativeLang = Config.userAlernativeLangs[1];
    }

    return possibleAlternativeLang;
};

/** Returns the list of alternative languages */
const getAlternativeLanguages = () => {
    return Config.userAlernativeLangs.length > 0 ? Config.userAlernativeLangs : [Config.defaultLanguage];
};

/** Returns the list of user's preferred contribution languages */
const getContributionLanguages = () => {
    return Config.userContributionLangs.length > 0 ? Config.userContributionLangs : [Config.defaultLanguage];
};

/** Returns the list of default contribution languages */
const getDefaultContributionLanguages = () => {
    return Config.defaultContributionLanguages.length > 0
        ? Config.defaultContributionLanguages
        : [Config.defaultLanguage];
};

/** Returns the preferred contribution as the first contribution language available. */
const getPreferredContributionLanguage = () => {
    return getContributionLanguages()[0];
};

// eslint-disable-next-line default-param-last
const getInputLanguage = (instance: any = {}, currentLang: string, fallbackLang: string) => {
    let intialInputLanguage = currentLang;

    if (instance.langs) {
        // Check if the current language exists.
        if (includes(instance.langs, currentLang)) {
            intialInputLanguage = currentLang;
        } else if (instance.defaultLang && includes(instance.langs, instance.defaultLang)) {
            intialInputLanguage = instance.defaultLang;
            // Check if the fallback language exists.
        } else if (includes(instance.langs, fallbackLang)) {
            intialInputLanguage = fallbackLang;
            // Else set the input language to the first element of the instance langs.
        } else {
            [intialInputLanguage] = instance.langs;
        }
    }

    return intialInputLanguage;
};

const createXMLHttpRequestToRetrieveLanguage = (
    language: string,
    onLoad: () => void,
    onError: (excp?: any) => void,
) => {
    const { isLegacyBrowser } = Config;
    const langPath = isLegacyBrowser ? `translations/${language}.json` : `translations/br/${language}.json`;

    const langRequest = new XMLHttpRequest();
    langRequest.open('GET', `${window.PUBLIC_PATH}${langPath}`);
    langRequest.addEventListener('load', () => onLoad());
    langRequest.addEventListener('error', () => onError());

    langRequest.onloadend = function onloadend() {
        if (langRequest.status === 404) {
            onError();
        }
    };

    try {
        langRequest.send();
    } catch (excp) {
        onError(excp);
    }

    return langRequest;
};

const translateAndSortLanguages = ({
    languages,
    translateKey,
    keepLanguageOrder,
}: {
    languages: string[];
    translateKey: TranslationAPI['translateKey'];
    keepLanguageOrder?: boolean;
}) => {
    const transLangs = languages.map((lang) => ({
        key: lang,
        label: lang !== '' ? translateKey(getKeyForLanguage(lang)) : null,
    }));

    return keepLanguageOrder
        ? transLangs
        : transLangs.sort((a, b) => {
              if (a.label && b.label) {
                  return a.label.localeCompare(b.label);
              }

              return 0;
          });
};

/**
 * Sorts languages based on:
 * - If translations are completed for both languages, it sorts them alphabetically
 * - If translations are completed for one language but not the other, it returns the completed one as first
 * - If translations are not completed for either language, it sorts them alphabetically.
 * @param options
 * @returns string[]
 */
const sortLanguagesByCompleteness = ({
    translatableLanguages,
    languageCompleteness,
    translateKey,
}: {
    translatableLanguages: string[];
    translateKey: TranslationAPI['translateKey'];
    languageCompleteness: Record<string, { isComplete: boolean; isPartial: boolean }>;
}) => {
    return translatableLanguages.sort((a, b) => {
        const translatedA = translateKey(getKeyForLanguage(a));
        const translatedB = translateKey(getKeyForLanguage(b));

        if (
            (languageCompleteness[a].isComplete && languageCompleteness[b].isComplete) ||
            (languageCompleteness[a].isPartial && languageCompleteness[b].isPartial)
        ) {
            return translatedA < translatedB ? -1 : 1;
        }

        if (languageCompleteness[a].isComplete && languageCompleteness[b].isPartial) {
            return -1;
        }

        if (languageCompleteness[a].isComplete) {
            return -1;
        }

        if (languageCompleteness[a].isPartial && languageCompleteness[b].isComplete) {
            return 1;
        }

        if (languageCompleteness[b].isComplete) {
            return 1;
        }

        return translatedA < translatedB ? -1 : 1;
    });
};

export {
    getFirstAlternativeLanguage,
    getAlternativeLanguages,
    getContributionLanguages,
    getDefaultContributionLanguages,
    getPreferredContributionLanguage,
    getInputLanguage,
    createXMLHttpRequestToRetrieveLanguage,
    getDefaultLanguage,
    translateAndSortLanguages,
    sortLanguagesByCompleteness,
};
