/* istanbul ignore file */

import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

/**
 * Event view page path.
 *
 * Same as content view page path but with `type` replaced with `event`.
 */
export const eventViewPath = `/${URL_PREFIX}/event/id/:id`;

export const eventViewRoute: Route = {
    path: eventViewPath,
    appId: AppId.frontOffice,
};

export const eventView = (id: string, anchor?: string, instanceSlug?: string): Route => {
    return {
        ...eventViewRoute,
        anchor,
        params: { id },
        instanceSlug,
        mobilePageDescriptor: { type: 'event', id },
    };
};
