import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

export interface SelectedFile {
    file: File;
    blobUrl: string;
    id?: string;
    url?: string;
}

export interface SelectedFilesState {
    selectedFiles: SelectedFile[];
}

export const initialState: SelectedFilesState = {
    selectedFiles: [],
};

export const reducers = {
    setFiles: (state: SelectedFilesState, action: PayloadAction<SelectedFile[]>) => {
        set(state, 'selectedFiles', action.payload);
    },
    resetFiles: (state: SelectedFilesState) => {
        set(state, 'selectedFiles', initialState.selectedFiles);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'upload-files',
    initialState,
    reducers,
});
