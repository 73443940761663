export enum CONNECTORS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471531816
     */
    ADD_AN_ACTION = 'CONNECTORS.ADD_AN_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471482635
     */
    ADD_DOMAIN = 'CONNECTORS.ADD_DOMAIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240913003
     */
    ADD_BASE_URL = 'CONNECTORS.ADD_BASE_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932707
     */
    ADD_CREDENTIALS = 'CONNECTORS.ADD_CREDENTIALS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268686689
     */
    ADD_FIELD = 'CONNECTORS.ADD_FIELD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932644
     */
    ADD_KEY = 'CONNECTORS.ADD_KEY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240907394
     */
    ADD_NAME = 'CONNECTORS.ADD_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277222012
     */
    ADMIN_SIDE_NAVIGATION = 'CONNECTORS.ADMIN_SIDE_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447140359
     */
    ADVANCED_SETTINGS = 'CONNECTORS.ADVANCED_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932659
     */
    API_KEY_HELPER = 'CONNECTORS.API_KEY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=499486654
     */
    API_KEY_LOCATION = 'CONNECTORS.API_KEY_LOCATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268683759
     */
    AUTHORIZATION = 'CONNECTORS.AUTHORIZATION',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=499494647
     */
    ATTRIBUTE_NAME = 'CONNECTORS.ATTRIBUTE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505815855
     */
    ATTRIBUTE_NAME_HELPER = 'CONNECTORS.ATTRIBUTE_NAME_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472070603
     */
    AUTHORIZE_USER_ACCESS_CONNECTOR = 'CONNECTORS.AUTHORIZE_USER_ACCESS_CONNECTOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472070409
     */
    AUTHORIZE_USER_ACCESS_CONNECTOR_DESCRIPTION = 'CONNECTORS.AUTHORIZE_USER_ACCESS_CONNECTOR_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268760396
     */
    AUTHORIZATION_CODE = 'CONNECTORS.AUTHORIZATION_CODE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932766
     */
    AUTHORIZATION_TYPE = 'CONNECTORS.AUTHORIZATION_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=275141996
     */
    AUTHORIZATION_TYPE_BASIC = 'CONNECTORS.AUTHORIZATION_TYPE_BASIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=275156851
     */
    AUTHORIZATION_TYPE_CUSTOM = 'CONNECTORS.AUTHORIZATION_TYPE_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268645153
     */
    AUTHORIZATION_URI = 'CONNECTORS.AUTHORIZATION_URI',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268645420
     */
    AUTHORIZATION_URI_HELPER = 'CONNECTORS.AUTHORIZATION_URI_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932884
     */
    AUTHORIZATION_TYPE_PLACEHOLDER = 'CONNECTORS.AUTHORIZATION_TYPE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504700073
     */
    ASSERTION_PAYLOAD = 'CONNECTORS.ASSERTION_PAYLOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240912984
     */
    BASE_URL = 'CONNECTORS.BASE_URL',
    /**
     * TODO: Create lokalize key
     */
    BASE_URL_HELP = 'The base url should look like: %BASE_URL%',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471452195
     */
    CERTIFCATE_ENFORCE_SECURITY = 'CONNECTORS.CERTIFCATE_ENFORCE_SECURITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268760384
     */
    CLIENT_CREDENTIALS = 'CONNECTORS.CLIENT_CREDENTIALS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=482010540
     */
    CLIENT_CREDENTIALS_NEW = 'CONNECTORS.CLIENT_CREDENTIALS_NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268678641
     */
    CLIENT_ID = 'CONNECTORS.CLIENT_ID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268679072
     */
    CLIENT_ID_HELPER = 'CONNECTORS.CLIENT_ID_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268679629
     */
    CLIENT_SECRET = 'CONNECTORS.CLIENT_SECRET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268679814
     */
    CLIENT_SECRET_HELPER = 'CONNECTORS.CLIENT_SECRET_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268684290
     */
    COMPLIANCE_AUTHORIZATION_DESC = 'CONNECTORS.COMPLIANCE_AUTHORIZATION_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268757676
     */
    COMPLIANCE_RESPONSE_DESC = 'CONNECTORS.COMPLIANCE_RESPONSE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473752496
     */
    COMPLIANCE_JWT_HEADERS_DESCRIPTION = 'CONNECTORS.COMPLIANCE_JWT_HEADERS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473752473
     */
    COMPLIANCE_JWT_PAYLOAD_DESCRIPTION = 'CONNECTORS.COMPLIANCE_JWT_PAYLOAD_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473752487
     */
    COMPLIANCE_JWT_HEADERS = 'CONNECTORS.COMPLIANCE_JWT_HEADERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473752389
     */
    COMPLIANCE_JWT_PAYLOAD = 'CONNECTORS.COMPLIANCE_JWT_PAYLOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268758745
     */
    COMPLIANCE_KEY_DESC = 'CONNECTORS.COMPLIANCE_KEY_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268758800
     */
    COMPLIANCE_VALUE_DESC = 'CONNECTORS.COMPLIANCE_VALUE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268759175
     */
    COMPLIANCE_TOKEN_DESC = 'CONNECTORS.COMPLIANCE_TOKEN_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=453398495
     */
    CONFIGURATION_TYPE = 'CONNECTORS.CONFIGURATION_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=453398790
     */
    CONFIGURATION_TYPE_DESCRIPTION = 'CONNECTORS.CONFIGURATION_TYPE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248369327
     */
    CONNECTOR_CREATED = 'CONNECTORS.CONNECTOR_CREATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449812211
     */
    CONNECT_DOMAIN_DESCRIPTION = 'CONNECTORS.CONNECT_DOMAIN_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248369135
     */
    CONNECTOR_EDITED = 'CONNECTORS.CONNECTOR_EDITED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=413579936
     */
    CONNECTOR_NOT_MUTABLE = 'CONNECTORS.NOT_MUTABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230803328
     */
    CONNECTOR_PROTOCOL_API_KEY = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.CONNECTORS.PROTOCOL.API_KEY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24992959
     */
    CONNECTOR_PROTOCOL_BASIC = 'GLOBAL.BASIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230803378
     */
    CONNECTOR_PROTOCOL_OAUTH2 = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.CONNECTORS.PROTOCOL.OAUTH2',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301351203
     */
    CREATE_CONFIGURATION = 'CONNECTORS.CREATE_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=454235263
     */
    CREATE_CONFIG_CUSTOM = 'CONNECTORS.CREATE_CONFIG_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268755659
     */
    CREDENTIALS_BODY = 'CONNECTORS.CREDENTIALS.BODY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268757537
     */
    CREDENTIALS_HEADER = 'CONNECTORS.CREDENTIALS.HEADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932677
     */
    CREDENTIALS = 'CONNECTORS.CREDENTIALS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471494015
     */
    CREDENTIALS_DESCRIPTION = 'CONNECTORS.CREDENTIALS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268757631
     */
    CREDENTIALS_OAUTH_HELPER = 'CONNECTORS.CREDENTIALS_OAUTH_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240932731
     */
    CREDENTIALS_HELPER = 'CONNECTORS.CREDENTIALS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248299130
     */
    DELETE_CONNECTORS_INFORMATION = 'CONNECTORS.DELETE_CONNECTORS_INFORMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=282355680
     */
    DELETE_CONNECTOR = 'CONNECTORS.DELETE_CONNECTOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=248295110
     */
    DELETE_CONNECTORS_TITLE = 'CONNECTORS.DELETE_CONNECTORS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268702032
     */
    DISPLAY_LESS_OPTIONS = 'CONNECTORS.DISPLAY_LESS_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268702787
     */
    DISPLAY_MORE_OPTIONS = 'CONNECTORS.DISPLAY_MORE_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447118081
     */
    DISTINGUISH_CONNNECTORS = 'CONNECTORS.DISTINGUISH_CONNNECTORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447648792
     */
    DOMAIN = 'CONNECTORS.DOMAIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471494061
     */
    DUPLICATED_KEY = 'CONNECTORS.DUPLICATED_KEY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242958931
     */
    EDIT_CONNECTOR = 'CONNECTORS.EDIT_CONNECTOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471493989
     */
    ENTER_PASSWORD = 'CONNECTORS.ENTER_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471488600
     */
    ENTER_USERNAME = 'CONNECTORS.ENTER_USERNAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472780943
     */
    EXTERNAL_TENANT_ID_HELPER = 'CONNECTORS.EXTERNAL_TENANT_ID_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268640077
     */
    GRANT_TYPE = 'CONNECTORS.GRANT_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449822447
     */
    GRANT_TYPE_DESCRIPTION = 'CONNECTORS.GRANT_TYPE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268645251
     */
    GRANT_TYPE_HELPER = 'CONNECTORS.GRANT_TYPE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=61011595
     */
    INFORMATION = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.INFORMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471494074
     */
    INVALID_KEY = 'CONNECTORS.INVALID_KEY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471452921
     */
    JWT_BEARER = 'CONNECTORS.JWT_BEARER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471439799
     */
    JWT_BEARER_HELPER = 'CONNECTORS.JWT_BEARER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=230551694
     */
    NEW_CONNECTOR = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS.CONNECTORS.NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301351045
     */
    PICK_PUBLISHER_PRESET = 'CONNECTORS.PICK_PUBLISHER_PRESET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=454234503
     */
    PRESET_CONFIG = 'CONNECTORS.PRESET_CONFIG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471495580
     */
    PRIVATE_KEY_CERTIFICATE = 'CONNECTORS.PRIVATE_KEY_CERTIFICATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447140296
     */
    PROTOCOL = 'CONNECTORS.PROTOCOL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449127932
     */
    PROTOCOL_TYPE_DESCRIPTION = 'CONNECTORS.PROTOCOL_TYPE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240913181
     */
    PROVIDER = 'CONNECTORS.PROVIDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447617883
     */
    PROVIDER_COMPATIBLE_EXTENSION = 'CONNECTORS.PROVIDER_COMPATIBLE_EXTENSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240913319
     */
    PROVIDER_HELPER_EXT = 'CONNECTORS.PROVIDER_HELPER_EXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240916533
     */
    PROVIDER_PLACEHOLDER = 'CONNECTORS.PROVIDER_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447701998
     */
    PROVIDER_TYPE = 'CONNECTORS.PROVIDER_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=506629465
     */
    API_KEY_QUERY_PARAMETERS = 'CONNECTORS.API_KEY_QUERY_PARAMETERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268725663
     */
    RESPONSE = 'CONNECTORS.RESPONSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471495592
     */
    RETRIEVE_PRIVATE_KEY_CERTIFICATE = 'CONNECTORS.RETRIEVE_PRIVATE_KEY_CERTIFICATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301351032
     */
    PUBLISHER_PRESET = 'CONNECTORS.PUBLISHER_PRESET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502333056
     */
    SAML_BEARER = 'CONNECTORS.SAML_BEARER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502334515
     */
    SAML_BEARER_HELPER = 'CONNECTORS.SAML_BEARER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472725812
     */
    SCOPES_SEPARATOR = 'CONNECTORS.SCOPES_SEPARATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265096092
     */
    SEARCH_CONFIGURATION_TYPE = 'CONNECTORS.SEARCH_CONFIGURATION_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265096924
     */
    SEARCH_CONFIGURATION_TYPE_CUSTOM = 'CONNECTORS.SEARCH_CONFIGURATION_TYPE_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265096911
     */
    SEARCH_CONFIGURATION_TYPE_PUBLISHERS = 'CONNECTORS.SEARCH_CONFIGURATION_TYPE_PUBLISHERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=454233982
     */
    SELECT_PRESET = 'CONNECTORS.SELECT_PRESET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=265096978
     */
    SEARCH_SETUP = 'CONNECTORS.SEARCH_SETUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301344748
     */
    SELECT_TYPE = 'CONNECTORS.TYPE_SELECTOR_SELECT_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268730529
     */
    SEPARATOR = 'CONNECTORS.SEPARATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268741536
     */
    SEPARATOR_HELPER = 'CONNECTORS.SEPARATOR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240919451
     */
    SETUP = 'CONNECTORS.SETUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241271611
     * TODO: Split into two translations
     */
    SETUP_INFORMATION = 'CONNECTORS.SETUP_INFORMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268657044
     */
    SCOPES = 'CONNECTORS.SCOPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268659436
     */
    SCOPES_HELPER = 'CONNECTORS.SCOPES_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472731693
     */
    SCOPES_SEPARATOR_PLACEHOLDER = 'CONNECTORS.SCOPES_SEPARATOR_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268680472
     */
    SHOW_AUTHORIZE_COMPLIANCE = 'CONNECTORS.SHOW_AUTHORIZE_COMPLIANCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268682626
     */
    SHOW_AUTHORIZE_COMPLIANCE_HELPER = 'CONNECTORS.SHOW_AUTHORIZE_COMPLIANCE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286043652
     */
    STATUS_AVAILABLE = 'CONNECTORS.STATUS_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286043700
     */
    STATUS_ACTION_REQUIRED = 'CONNECTORS.STATUS_ACTION_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286043675
     */
    STATUS_UNAVAILABLE = 'CONNECTORS.STATUS_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471482425
     */
    SELECT_PROVIDER_TYPE = 'CONNECTORS.SELECT_PROVIDER_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=61011584
     */
    SETTINGS = 'ADMIN.MARKETPLACE.EXTENSION.SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470834236
     */
    SETTINGS_DESCRIPTION = 'CONNECTORS.SETTINGS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301351260
     */
    TEMPLATE_KEYS = 'CONNECTORS.TEMPLATE_KEYS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268725650
     */
    TOKEN = 'CONNECTORS.TOKEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268656844
     */
    TOKEN_URI = 'CONNECTORS.TOKEN_URI',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=268656872
     */
    TOKEN_URI_HELPER = 'CONNECTORS.TOKEN_URI_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301350613
     */
    TYPE_SELECTOR_HELPER = 'CONNECTORS.TYPE_SELECTOR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=301350818
     */
    TYPE_SELECTOR_PRESET_OPTION = 'CONNECTORS.TYPE_SELECTOR_PRESET_OPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=471450680
     */
    USE_CERTIFICATE = 'CONNECTORS.USE_CERTIFICATE',
}
