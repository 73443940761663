import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiRefresh } from '@lumapps/lumx/icons';
import { FlexBox, Alignment, Button, Emphasis, Orientation, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { DATA_NAMESPACE } from '../../constants';
import { NOTIFICATIONS_CENTER } from '../../keys';

const CLASSNAME = 'notification-center-error-state';

export type NotificationErrorStateProps = {
    listNotifications(): void;
};

const NotificationErrorState: React.FC<NotificationErrorStateProps> = ({ listNotifications }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(DATA_NAMESPACE);

    return (
        <FlexBox
            className={CLASSNAME}
            orientation={Orientation.vertical}
            vAlign={Alignment.center}
            hAlign={Alignment.center}
        >
            <Text
                as="p"
                color="dark"
                colorVariant="L3"
                className={classnames(margin('bottom', 'big'), 'lumx-base-text-center')}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: translateKey(NOTIFICATIONS_CENTER.ERROR_MESSAGE) }}
            />

            <Button
                leftIcon={mdiRefresh}
                onClick={() => listNotifications()}
                emphasis={Emphasis.medium}
                className={margin('right', 'big')}
                {...get({ element: 'button', action: 'refresh' })}
            >
                {translateKey(NOTIFICATIONS_CENTER.REFRESH_BUTTON)}
            </Button>
        </FlexBox>
    );
};

export { NotificationErrorState };
