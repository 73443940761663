import { toCompatibleLanguage } from '@lumapps/translations';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSubNavItemController($rootScope, $stateParams, $timeout, Config, MainNav, Translation, Widget) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The input language.
         *
         * @type {string}
         */
        var _lang;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the item is opened.
         *
         * @type {boolean}
         */
        vm.isOpen = false;

        /**
         * The selected property of the item.
         *
         * @type {Object}
         */
        vm.selectedProperty = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.MainNav = MainNav;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the active item is one of the current item children.
         *
         * @return {boolean} If the current item has active child or not.
         */
        function _hasActiveChild() {
            return (function computeHasActiveChild(children) {
                if (angular.isUndefinedOrEmpty(children)) {
                    return undefined;
                }

                return _.find(children, function findActiveChild(child) {
                    return vm.isActive(child) || computeHasActiveChild(child.children);
                });
            })(vm.item.children);
        }

        /**
         * Select nav item.
         */
        function _selectNavItem() {
            if (angular.isUndefined(vm.selectedProperty.navSelected)) {
                vm.selectedProperty.navSelected = {};
            }

            vm.selectedProperty.navSelected[_lang] = {
                uuid: vm.item.uuid,
            };

            $timeout(function delayBroadcast() {
                $rootScope.$broadcast('widget-sub-nav-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Handle nav item.
         * If in edit mode, use to select the nav.
         * Else redirect the user or open the menu.
         */
        function handleNavItem() {
            if (vm.edit) {
                _selectNavItem();
            } else if (
                vm.item.type === Config.AVAILABLE_CONTENT_TYPES.MENU &&
                !Translation.hasTranslations(vm.item.link, true)
            ) {
                vm.toggleChildren();
            } else {
                MainNav.openNavItemLink(vm.item);
            }
        }

        /**
         * Check if nav item is active in widget mode.
         *
         * @param  {Object}  item The nav item to check.
         * @return {boolean} If the nav item is active or not.
         */
        function isActive(item) {
            item = item || vm.item;

            if (vm.edit || angular.isUndefinedOrEmpty($stateParams.slug)) {
                return false;
            }

            // Get last element of each state and item and compare them.
            var stateSlug = _.last($stateParams.slug.split('/'));
            var navItemSlug = _.last(Translation.translate(item.slugFull).split('/'));

            return stateSlug === navItemSlug;
        }

        /**
         * Check if nav item is selected in settings mode.
         *
         * @return {boolean} If the nav item is selected or not.
         */
        function isSelected() {
            return (
                vm.edit &&
                Translation.hasTranslation(vm.selectedProperty.navSelected, _lang) &&
                Translation.translate_(vm.selectedProperty.navSelected, _lang).uuid === vm.item.uuid
            );
        }

        /**
         * Toggle the children.
         */
        function toggleChildren() {
            vm.isOpen = !vm.isOpen;
        }

        /////////////////////////////

        vm.handleNavItem = handleNavItem;
        vm.isActive = isActive;
        vm.isSelected = isSelected;
        vm.toggleChildren = toggleChildren;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            _lang = toCompatibleLanguage(Translation.inputLanguage);

            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            properties[_lang] = properties[_lang] || {};

            if (angular.isDefinedAndFilled(vm.property)) {
                properties[_lang][vm.property] = properties[_lang][vm.property] || {};
                vm.selectedProperty = properties[_lang][vm.property];
            } else if (angular.isDefinedAndFilled(properties[_lang].__default)) {
                vm.selectedProperty = properties[_lang].__default;
            } else {
                vm.selectedProperty = properties;
            }

            vm.isOpen = isActive() || _hasActiveChild();
        }

        init();
    }

    /////////////////////////////

    function WidgetSubNavItemDirective(RecursionHelper) {
        'ngInject';

        function compile(el) {
            return RecursionHelper.compile(el);
        }

        return {
            bindToController: true,
            compile: compile,
            controller: WidgetSubNavItemController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                edit: '=',
                item: '=',
                parent: '=',
                property: '=',
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-sub-nav/views/widget-sub-nav-item.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetSubNavItem', WidgetSubNavItemDirective);
})();
