import React, { useContext } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlateStatic, useSelected, Transforms, Path } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';

import { WrexFileContext } from '../../../context';
import { FileEditor } from '../../../types';
import { CLASSNAME, File } from '../../blocks/File';

import './index.scss';

export const EditableFile: typeof File = ({ element, children, ...forwardedProps }) => {
    const editor = useSlateStatic() as ReactEditor & FileEditor;
    const selected = useSelected();
    const { block, element: classElement } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { onFocus, onDelete } = useInlineVoid(editor, element as any);
    const contextData = useContext(WrexFileContext);

    const updatedFile = React.useMemo(() => {
        if (contextData && element && !contextData.isFetching && contextData.updatedFiles?.length) {
            return contextData.updatedFiles.find((file) => file.id === element.id);
        }
        return undefined;
    }, [contextData, element]);

    React.useEffect(() => {
        if (updatedFile && element) {
            const path = ReactEditor.findPath(editor, element) as Path;
            Transforms.setNodes(editor, updatedFile, { at: path });
        }
    }, [editor, element, updatedFile]);

    return (
        <File
            className={block({ editable: true, selected })}
            element={element}
            onFocus={onFocus}
            tabIndex={0}
            actions={[
                <IconButton
                    className={classElement('actions')}
                    key={element?.id}
                    icon={mdiClose}
                    emphasis={Emphasis.low}
                    hasBackground
                    onClick={onDelete}
                    label={translateKey(GLOBAL.DELETE)}
                />,
            ]}
            {...forwardedProps}
        >
            {children}
        </File>
    );
};

EditableFile.displayName = 'file';
