(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetUserController($scope, Content, Features, InitialSettings, Translation, User, UserDirectory,
        UserPicker, Utils, ContentFactory) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * User picker modal identifier - only for basic mode.
         *
         * @type {string}
         */
        var _USER_PICKER_ID = 'widget-user-list-picker-';

        /**
         * Projections fields, field the backend will send back.
         *
         * @type {string}
         */
        var _projection = {
            items: {
                apiProfile: true,
                customProfile: true,
                email: true,
                firstName: true,
                gamification:false,
                id: true,
                lastName: true,
                fullName: true,
                profilePicture: true,
                profilePictureUrl: true,
                profileStatus: Features.hasFeature('social'),
                settings: Features.hasFeature('social'),
                uid: true,
            },
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Handle backward compatibility. This is due to changes in models.
         *
         * Todo [Arnaud]: create a backend script so we can get rid of this.
         */
        function _handleBackwardCompatibility() {
            if (angular.isDefinedAndFilled(vm.widget.properties.maxNumber)) {
                vm.widget.properties.maxNumber = parseInt(vm.widget.properties.maxNumber, 10);
            }
        }

        /**
         * Initialize the fields to display.
         */
        function _initFields() {
            var properties = vm.widget.properties;
            if (angular.isUndefinedOrEmpty(_.get(properties, 'userDirectory')) ||
                angular.isUndefinedOrEmpty(_.get(properties, 'displayFields'))) {
                return;
            }

            /**
             * For some reason the user directory set in `properties.userDirectory` doesn't always have
             * up-to-date information.
             * This can be the case for the feedKeys array that sometimes doesn't have the correct feeds.
             *
             * The `userDirectories` field of the init seems to have the correct information, so we need to use this one instead of the one
             * set in the widget properties.
             */
            const selectedUserDirectoryId = properties.userDirectory.uid;
            const selectedUserDirectory = InitialSettings.USER_DIRECTORIES.find((userDirectory) => userDirectory.uid === selectedUserDirectoryId);

            // Get the groups in which the current user belongs to
            const userFeedIds = _.map(_.get(User.getConnected(), 'subscriptions', []), 'feed');
            // Check that user has access to the selected user directory before trying to fetch it
            const userHasAccessToUserDirectory = User.isAdmin() || selectedUserDirectory?.feedKeys?.some((feedKey) => userFeedIds.includes(feedKey));

            if(!userHasAccessToUserDirectory) {
                properties.displayFields = []
                return;
            }

            // Get the settings from the User directory
            ContentFactory.get(
                {
                    'uid': properties.userDirectory.uid,
                },
                function onContentGetSuccess(response) {
                    if (!response) {
                        return;
                    }

                    var displayedFields = _.map(properties.displayFields, 'uuid');
                    var hasDisplayedFields = angular.isDefinedAndFilled(displayedFields);

                    // Empty the displayFields first
                    properties.displayFields = [];

                    var directory = response;
                    if (angular.isUndefinedOrEmpty(_.get(directory, 'template.components'))) {
                        return;
                    }

                    // Get the groups in which the current user belongs to
                    var userFeedIds = _.map(_.get(User.getConnected(), 'subscriptions', []), 'feed');

                    // Add the components of the UD to the widget's displayFields if:
                    // - 1) the widget params includes this component
                    // - 2) And either:
                    //    - the component's settings allows it to be seen by one of the user's groups
                    //    - or the user is admin
                    properties.displayFields = _.filter(directory.template.components,
                        function filterComponents(component) {
                            return (
                                hasDisplayedFields ? _.includes(displayedFields, component.uuid) : _.get(component.properties, 'displayInList', false)
                            ) && (
                                User.isAdmin() || UserDirectory.hasSubscription(_.get(component.properties, 'seeFeeds', []), userFeedIds)
                            );
                        }
                    );
                },
                function onContentGetError() {
                    return;
                },
            );
        }

        /**
         * In "list" configuration case, filter users by the order selected in settings.
         */
        function _initUserList() {
            if (!User.isConnected()) {
                return;
            }

            var params = {
                maxResults: parseInt(vm.widget.properties.maxNumber, 10) || User.maxResults,
                showHidden: false,
                sortOrder: '',
                status: 'enabled',
            };

            var listKey = vm.widgetListCtrl.getOriginalListKey() + '-' + vm.widgetListCtrl.SELECTION_TYPES.list;

            vm.widgetListCtrl.setListKey(listKey);

            params.sortOrder = (vm.widget.properties.listOrderDir === 'asc') ? '' : '-';
            params.sortOrder += (vm.widget.properties.listOrder) ? vm.widget.properties.listOrder : 'registrationDate';

            if (vm.widget.properties.feedKey) {
                params.feeds = vm.widget.properties.feedKey;
            }

            if (vm.widget.properties.registeredSince && params.sortOrder.indexOf('registrationDate') >= 0) {
                params.registeredSince = vm.widget.properties.registeredSince;
            }

            User.cacheFilterize(params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems, listKey,
                _projection);
        }

        /**
         * In "pick" mode, display the users previously picked in the settings.
         */
        function _initUserPicked() {
            var listKey = vm.widgetListCtrl.getOriginalListKey() + '-' + vm.widgetListCtrl.SELECTION_TYPES.pick;

            vm.widgetListCtrl.setListKey(listKey);

            if (angular.isUndefinedOrEmpty(vm.widget.properties.usersIds) || !User.isConnected()) {
                User.displayList(listKey, _projection).length = 0;
                vm.widgetListCtrl.updateListItems();
            } else {
                var params = {
                    ids: vm.widget.properties.usersIds,
                    maxResults: vm.widget.properties.usersIds.length,
                    // Monolite param. Display the hidden users when in edit mode, but not in display mode
                    showHidden: Utils.isDesignerMode() ? true : undefined,
                    // Only show enabled users in view mode
                    status: Utils.isDesignerMode() ? undefined : 'enabled',
                };

                User.cacheFilterize(params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                    listKey, _projection);
            }
        }

        /**
         * List users depending on the selection type.
         */
        function _initList() {
            switch (vm.widget.properties.type) {
                case vm.widgetListCtrl.SELECTION_TYPES.list:
                    _initUserList();
                    break;

                case vm.widgetListCtrl.SELECTION_TYPES.pick:
                default:
                    _initUserPicked();
                    break;
            }
        }

        /**
         * Open user picker (only when widget selection type is pick).
         */
        function _openUserPicker() {
            if (vm.widget.properties.type !== vm.widgetListCtrl.SELECTION_TYPES.pick ||
                Content.getViewMode() !== 'basic' || Content.getAction() === 'get') {
                return;
            }

            Utils.waitForAndExecute('#' + _USER_PICKER_ID, UserPicker);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize controller.
         *
         * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
         */
        vm.init = function init(saveListKey) {
            _USER_PICKER_ID += vm.widget.uuid;

            // Profile status should only be available when social is turned on.
            if (Features.hasFeature('social')) {
                _projection.items.profileStatus = true;
            }

            _initFields();

            vm.widgetListCtrl.init(saveListKey);
        };

        /**
         * Set parent controller.
         *
         * @param {Object} widgetListCtrl The parent controller object.
         */
        this.setParentController = function setParentController(widgetListCtrl) {
            vm.widgetListCtrl = widgetListCtrl;

            vm.widgetListCtrl.widgetListChildCtrl = {
                handleBackwardCompatibility: _handleBackwardCompatibility,
                initList: _initList,
                onWidgetListClick: _openUserPicker,
            };

            vm.init(true);
        };
    }

    /////////////////////////////

    /**
     * The user list widget.
     * Displays a list of users (either manually picked or the whole list with filters applied).
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetUserListDirective() {
        'ngInject';

        function WidgetUserListLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetUserController,
            controllerAs: 'vm',
            link: WidgetUserListLink,
            replace: true,
            require: ['widgetUserList', '^widgetList'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-user-list/views/widget-user-list.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetUserList', WidgetUserListDirective);
})();
