import isInteger from 'lodash/isInteger';
import isUndefined from 'lodash/isUndefined';

/**
 * Get the RGB components (blue, green, red) of the given rgb color.
 *
 * @param color The RGB color.
 *              Note: rgb(x x x) and rgb(x x x / 20%) are supported
 * @param onlyOpaque Whether we should only accept rgb color with an alpha equal to 1.
 *
 * @return The RGB object.
 */
export const parseRgb = (
    color: string,
    onlyOpaque = true,
): { red: number; green: number; blue: number; alpha?: number } | undefined => {
    // Check for rgb(x, x, x), rgb(x x x), rgb(x x x / 20%), rgb(x x x / 0.2)
    const colors = /rgb\((\d{1,3})[,\s]\s*(\d{1,3})[,\s]\s*(\d{1,3})\s*(\/\s*(\d{1,3}%?|0?\.\d|1(\.0)?))?\)/.exec(
        color,
    );

    if (!colors) {
        return undefined;
    }

    let alpha: number | undefined;
    if (colors[5]) {
        const floatAlpha = parseFloat(colors[5]);
        alpha = isInteger(floatAlpha) && floatAlpha !== 1 ? floatAlpha / 100 : floatAlpha;
    }

    if (alpha && onlyOpaque && alpha !== 1) {
        return undefined;
    }

    return {
        blue: Number(colors[3]),
        green: Number(colors[2]),
        red: Number(colors[1]),
        ...(!isUndefined(alpha) && { alpha }),
    };
};
