import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';

/* utils to check whether the editor as already comme elements in it or not
to check if we're in creation mode or edition mode of a structured content */

export const isEditorEmpty = (editor: any) => {
    const baseChildren = createParagraph();

    if (isEmpty(editor.children) || (editor.children.length === 1 && isEqual(editor.children[0], baseChildren))) {
        return true;
    }
    return false;
};
