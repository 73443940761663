import { Image } from '@lumapps/wrex-image/components/blocks/Image';
import { Link } from '@lumapps/wrex-link/components/blocks/Link';
import { ListItem } from '@lumapps/wrex-list/components/blocks/ListItem';
import { OrderedList } from '@lumapps/wrex-list/components/blocks/OrderedList';
import { UnorderedList } from '@lumapps/wrex-list/components/blocks/UnorderedList';
import { Table, TD, TR } from '@lumapps/wrex-table/components/blocks';
import { CodeBlock } from '@lumapps/wrex-typography/components/blocks/CodeBlock';
import { Headline } from '@lumapps/wrex-typography/components/blocks/Headline';
import { InlineCode } from '@lumapps/wrex-typography/components/blocks/InlineCode';
import { Bold, Italic, Strikethrough } from '@lumapps/wrex-typography/components/blocks/Mark';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';
import { Subtitle } from '@lumapps/wrex-typography/components/blocks/Subtitle';
import { Title } from '@lumapps/wrex-typography/components/blocks/Title';

import { WREX_GENERATED_CONTENT } from './keys';

export const GENERATIVE_CONTENT = 'generative-content';
export const GENERATIVE_AI_FEATURE_TOKEN = 'generative-ai';

export interface PromptHelpers {
    [key: string]: {
        resume: string;
        prompt: string;
        dataId: string;
    };
}

export interface RefinePromptHelpers extends PromptHelpers {
    [key: string]: {
        resume: string;
        prompt: string;
        refinePrompt: string;
        dataId: string;
    };
}

export const SUGGESTED_PROMPTS: PromptHelpers = {
    START_WRITING_ABOUT: {
        resume: WREX_GENERATED_CONTENT.PROMPT_SUGGESTION_LABEL_WRITING_ABOUT,
        prompt: WREX_GENERATED_CONTENT.PROMPT_SUGGESTION_RESULT_WRITING_ABOUT,
        dataId: 'start-writing-about',
    },
    ARTICLE: {
        resume: WREX_GENERATED_CONTENT.CHIP_ARTICLE,
        prompt: WREX_GENERATED_CONTENT.PROMPT_ARTICLE,
        dataId: 'article',
    },
    INTERNAL_COMMUNICATION: {
        resume: WREX_GENERATED_CONTENT.CHIP_INTERNAL_COMMUNICATION,
        prompt: WREX_GENERATED_CONTENT.PROMPT_INTERNAL_COMMUNICATION,
        dataId: 'internal-communication',
    },
    JOB_OFFER: {
        resume: WREX_GENERATED_CONTENT.CHIP_JOB_OFFER,
        prompt: WREX_GENERATED_CONTENT.PROMPT_JOB_OFFER,
        dataId: 'job-offer',
    },
    TO_DO_LIST: {
        resume: WREX_GENERATED_CONTENT.CHIP_TO_DO_LIST,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TO_DO_LIST,
        dataId: 'todo-list',
    },
    PLAN: {
        resume: WREX_GENERATED_CONTENT.CHIP_PLAN,
        prompt: WREX_GENERATED_CONTENT.PROMPT_PLAN,
        dataId: 'plan',
    },
};

export const SUGGESTED_FORMALITIES: RefinePromptHelpers = {
    CASUAL: {
        resume: WREX_GENERATED_CONTENT.CHIP_FORMALITY_CASUAL,
        prompt: WREX_GENERATED_CONTENT.PROMPT_FORMALITY_CASUAL,
        refinePrompt: 'Make this text more casual.',
        dataId: 'casual',
    },
    NEUTRAL: {
        resume: WREX_GENERATED_CONTENT.CHIP_FORMALITY_NEUTRAL,
        prompt: WREX_GENERATED_CONTENT.PROMPT_FORMALITY_NEUTRAL,
        refinePrompt: 'Make this text more neutral.',
        dataId: 'neutral',
    },
    FORMAL: {
        resume: WREX_GENERATED_CONTENT.CHIP_FORMALITY_FORMAL,
        prompt: WREX_GENERATED_CONTENT.PROMPT_FORMALITY_FORMAL,
        refinePrompt: 'Make this text more formal.',
        dataId: 'formal',
    },
};

export const SUGGESTED_TONES: RefinePromptHelpers = {
    PROFESSIONAL: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_PROFESSIONAL,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_PROFESSIONAL,
        refinePrompt: 'Make this text more professional.',
        dataId: 'professional',
    },
    HUMOUROUS: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_HUMOUROUS,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_HUMOUROUS,
        refinePrompt: 'Make this text more humourous.',
        dataId: 'humourous',
    },
    FRIENDLY: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_FRIENDLY,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_FRIENDLY,
        refinePrompt: 'Make this text more friendly.',
        dataId: 'friendly',
    },
    DIRECT: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_DIRECT,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_DIRECT,
        refinePrompt: 'Make this text more direct.',
        dataId: 'direct',
    },
    SUPPORTIVE: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_SUPPORTIVE,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_SUPPORTIVE,
        refinePrompt: 'Make this text more supportive.',
        dataId: 'supportive',
    },
    POSITIVE: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_POSITIVE,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_POSITIVE,
        refinePrompt: 'Make this text more positive.',
        dataId: 'positive',
    },
    CONFIDENT: {
        resume: WREX_GENERATED_CONTENT.CHIP_TONE_CONFIDENT,
        prompt: WREX_GENERATED_CONTENT.PROMPT_TONE_CONFIDENT,
        refinePrompt: 'Make this text more confident.',
        dataId: 'confident',
    },
};

/** List of markdown Wrex elements used in generated content preview */
export const WREX_MARKDOWN_ELEMENTS = [
    Paragraph,
    Headline,
    Title,
    Subtitle,
    CodeBlock,
    InlineCode,
    Table,
    TR,
    TD,
    UnorderedList,
    OrderedList,
    ListItem,
    Link,
    Image,
];

/** List of markdown Wrex marks used in generated content preview */
export const WREX_MARKDOWN_MARKS = [Bold, Italic, Strikethrough];
