import { useMemo } from 'react';

import { codeBlock } from '@lumapps/lumx/custom-icons';
import { useTranslate } from '@lumapps/translations';
import { ListEditor } from '@lumapps/wrex-list/types';
import { ReactEditor, useSlate } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { ToolbarItem } from '@lumapps/wrex/types';

import { CODE_BLOCK, PARAGRAPH } from '../../../constants';
import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

export const useCodeBlockButton = (otherProps?: any) => {
    const editor = useSlate() as ReactEditor & ListEditor & TypographyEditor;

    const isCurrentNodeACodeBlock = editor.getBlockType() === CODE_BLOCK;
    const { translateKey } = useTranslate();

    const isSelected = isCurrentNodeACodeBlock;
    const isDisabled = !editor.isCodeBlockAllowed();

    const codeBlockButton = useMemo(() => {
        const onClick = () => {
            const at = editor.selection || editor.getLastSelection();

            if (editor.getBlockType() === CODE_BLOCK) {
                editor.changeBlockType(PARAGRAPH, at);
            } else {
                editor.changeBlockType(CODE_BLOCK, at);
            }
            focusAt(editor, editor.selection);
        };

        const codeBlockButtonProps: ToolbarItem = {
            type: 'toggle-option',
            itemKey: CODE_BLOCK,
            icon: codeBlock,
            tooltipLabel: translateKey(WREX_TYPOGRAPHY.CODEBLOCK_LABEL),
            onClick,
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            verticalModeProps: {
                label: translateKey(WREX_TYPOGRAPHY.CODEBLOCK_LABEL),
                tooltipLabel: undefined,
            },
            otherProps,
        };
        return codeBlockButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, isSelected, translateKey]);

    return codeBlockButton;
};
