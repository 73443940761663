/**
 * Format a number to it's compact correspondance with translation (Ex: 92253000 -> 92,253M)
 * Documentation : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * The value of the number to format
 * @param  {number} value
 * The langage format
 * @param  {string} lang
 * The maximumSignificantsDigits to display
 * @param  {number} maximumSignificantDigits
 */

export function numberFormat(value: number, lang: string, maximumSignificantDigits: number) {
    try {
        return new Intl.NumberFormat(lang, { notation: 'compact', maximumSignificantDigits }).format(value);
    } catch (e) {
        // If the Intl.NumberFormat function failed, convert to string and add a space every 3 numbers, no translation handled
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
