import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { requestOnIdleCallback } from '@lumapps/utils/function/requestIdleCallback';

import { SearchSuggestionParams, SearchSuggestionResponse } from '../types';

const gcsSearchApi = new BaseApi({
    path: 'cloudsearch',
    version: BaseApi.versions.v2,
});

/**
 * Get CloudSearch suggestions for a given query.
 *
 * @param  {string}  params The parameters to pass to the suggest endpoint.
 * @return {Promise} The promise of the suggest call.
 */
const suggest = (params: SearchSuggestionParams): Promise<SearchSuggestionResponse> => {
    const { acceptLanguage, ...queryParams } = params;
    return gcsSearchApi
        .postCacheFirst(
            'suggest',
            { ...queryParams },
            CACHE_TYPE.MEMORY,
            PRIORITY.HIGH,
            acceptLanguage
                ? {
                      headers: {
                          'Accept-Language': acceptLanguage,
                      },
                  }
                : undefined,
        )
        .then((response) => {
            const { items = [] } = response.data;

            return { queries: items, results: [] };
        });
};

/**
 * Pass a URI we want the backend to call to bypass CORS.
 *
 * @param  {string}  uri An absolute URI.
 * @param  {boolean}  isGCSEnabled Whether google cloud search is enabled
 * @return {Promise} The promise of the call to the external uri.
 */
const outOfCORS = (uri: string, isGCSEnabled: boolean) => {
    if (isGCSEnabled) {
        requestOnIdleCallback(() => {
            gcsSearchApi.post('outofcors', { uri }, { baseURL: '/' });
        });
    }
};

export { suggest, outOfCORS, gcsSearchApi };
