export enum PROVIDERS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=29544116
     */
    PROVIDER = 'ADMIN.NAV.PROVIDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42164621
     */
    IDP = 'ADMIN.NAV.PROVIDER.IDP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=42164634
     */
    SERVICE = 'ADMIN.NAV.PROVIDER.SERVICE',
}
