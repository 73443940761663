import filter from 'lodash/filter';
import some from 'lodash/some';
import trimStart from 'lodash/trimStart';

import { cleanSpaces, smashAllSpaces } from '@lumapps/utils/string/cleanSpaces';
import { ALL_SPACES_PATTERN } from '@lumapps/utils/string/constants';

import { SPECIAL_POUND_CARACTER, TAGZ_FORBIDDEN_CHARACTERS } from '../../constants';
import * as types from '../../types';

/**
 * Returns true if the two tags provided are equivalent.
 * @param  {Tag}  firstTag  First tag.
 * @param  {Tag}  secondTag Second tag.
 * @return {boolean} returns true if the tags are equivalent.
 */
const areTagsEquivalent = (firstTag: types.Tag, secondTag: types.Tag) => {
    const noSpacesFirstTag = cleanSpaces(firstTag.id);
    const noSpacesSecondTag = cleanSpaces(secondTag.id);

    return noSpacesFirstTag === noSpacesSecondTag;
};

/**
 * Returns true if there is already a tag with the provided name.
 * @param  {Array}   tags List of tags.
 * @param  {Object}  tag  Tag to check uniqueness.
 * @return {boolean} returns true if there is already a tag with the provided name.
 */
const isTagDuplicated = (tags: types.Tag[], tag: types.Tag) =>
    some(tags, (selectedTag) => areTagsEquivalent(selectedTag, tag));

/**
 * Returns a list of tags without the provided tag.
 * @param  {Array}   tags List of tags.
 * @param  {Object}  tag  Tag to check uniqueness.
 * @return {boolean} returns true if there is already a tag with the provided name.
 */
const removeTagFromTagsList = (tags: types.Tag[], tag: types.Tag) =>
    filter(tags, (selectedTag) => !areTagsEquivalent(selectedTag, tag));

/**
 * Returns the given label without any pound symbol.
 */
const removePoundFromLabel = (label = '') => {
    const poundSymbols = `#|${SPECIAL_POUND_CARACTER}`;
    const re = new RegExp(poundSymbols, 'g');
    return label.replace(re, '');
};

/** Cleans tag label by removing initial pound symbol and unwanted spaces.  */
const cleanTagLabel = (label = '') => cleanSpaces(removePoundFromLabel(label));

/**
 * Returns true if the tag name is valid.
 * The tag will be cleaned before being tested.
 *
 * A tag considered valid if it is
 * * not undefined
 * * not empty
 *
 * @param  {string}  tagName Search term.
 * @return {boolean} returns true if the search term is valid.
 */
const isTagNameValid = (tagName = ''): boolean => {
    if (!tagName) {
        return false;
    }

    const cleanedTagName = cleanTagLabel(tagName);

    return Boolean(cleanedTagName && cleanedTagName.length > 0);
};

/** Creates a cleaned up lowercased id from a label.  */
const normalizeTagLabel = (label = '') => {
    return cleanTagLabel(label).toLowerCase();
};

/**
 * Creates a tagSuggestion object (types.Tag) with a cleaned up label from a string.
 *
 * @param tagName string
 * @returns Tag
 */ const createTagSuggestion = (tagName: string): types.Tag => {
    return { id: normalizeTagLabel(tagName), label: cleanTagLabel(tagName) };
};

/**
 * Remove forbidden characters, smash spaces (regular and japanese)
 * then trim ALL_SPACES_PATTERN from the start
 * @param tagName string
 * @returns string
 */
const formatTagNameInput = (tagNameInput: string): string => {
    return trimStart(
        smashAllSpaces(filter([...tagNameInput], (char) => !TAGZ_FORBIDDEN_CHARACTERS.includes(char)).join('')),
        ALL_SPACES_PATTERN,
    );
};

export {
    isTagNameValid,
    isTagDuplicated,
    areTagsEquivalent,
    removeTagFromTagsList,
    normalizeTagLabel,
    cleanTagLabel,
    createTagSuggestion,
    removePoundFromLabel,
    formatTagNameInput,
};
