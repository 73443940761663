import { Route } from '@lumapps/router';
import { URL_PREFIX, AppId } from '@lumapps/router/constants';

const historyRoute: Route = {
    path: `/${URL_PREFIX}/reading-history`,
    legacyId: 'app.front.reading-history',
    appId: AppId.frontOffice,
};

const history = (): Route => ({
    ...historyRoute,
    params: {},
});

export { history, historyRoute };
