import React from 'react';

import { get as getConfig } from '@lumapps/constants';
import { useDispatch } from '@lumapps/redux/react';
import { actions } from '@lumapps/router/ducks/slice';

const constants = getConfig();

interface UsePageTitleOptions {
    isPageLoading?: boolean;
}

/**
 * Hook to dynamically set the html page title.
 * Uses the instance name as default title and appends it to given title if defined.
 */
export const usePageTitle = (title?: string, subtitle?: string, options: UsePageTitleOptions = {}) => {
    const { isPageLoading } = options;
    const dispatch = useDispatch();

    React.useEffect(() => {
        /**
         * If the page is currently loading, prevent update unless a title is specifically set.
         * This will ensure that titles are not updated or read during loading.
         */
        const shouldUpdate = !isPageLoading || title;
        let pageTitle = constants.instanceName;

        if (title && !subtitle) {
            pageTitle = `${title} - ${constants.instanceName}`;
        } else if (title && subtitle) {
            pageTitle = `${subtitle} - ${title} - ${constants.instanceName}`;
        }
        /**
         * If a page hasn't been lazy loaded yet, it will be in "loading" state while the bundle downloads.
         * As the page title is set from the page itself, we cannot update the title during this transition.
         * Instead, we wait for the loading state to end before updating the title.
         */
        if (shouldUpdate) {
            document.title = pageTitle;
        }
        dispatch(actions.setPageTitle(shouldUpdate ? pageTitle : ''));
    }, [isPageLoading, dispatch, subtitle, title]);
};
