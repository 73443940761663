import get from 'lodash/get';

import { error as notifyError } from '@lumapps/notifications';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { isTagzEnabled } from '../ducks/selectors';

const shouldUseTagzErrorHandler = (state: FrontOfficeStore, error: any) => {
    const realError = get(error, 'data.error', null);
    const hasTagz = isTagzEnabled(state);

    if (realError && hasTagz) {
        // Try to read the message.
        try {
            const message = JSON.parse(realError.message);
            // If the message contains the TAGZ key
            return message.tagzError !== null;
        } catch (e) {
            return false;
        }
    }

    return false;
};

const handleTagzError = (error: any) => {
    const message = JSON.parse(error.data.error.message);

    const { tagzError } = message;

    // Fire the event to display the modal.
    return notifyError({ content: tagzError });
};

export { shouldUseTagzErrorHandler, handleTagzError };
