import mdast from 'mdast';

import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { DeserializeOptions } from '@lumapps/wrex/serialization/markdown/fromMarkdown/types';

import { createListItem } from '../../utils/createListItem';
import { createOrderedList } from '../../utils/createOrderedList';
import { createUnorderedList } from '../../utils/createUnorderedList';

export const LIST_ELEMENTS: DeserializeOptions['elements'] = {
    listItem: {
        convert(_, children) {
            if (!children.length) {
                // Children can't be empty.
                children.push(createParagraph());
            }
            return createListItem(children);
        },
    },
    list: {
        convert: ({ ordered }: mdast.List, children) => {
            if (ordered) {
                return createOrderedList(children);
            }
            return createUnorderedList(children);
        },
    },
};
