/**
 * Wrap the parse function from the JSON api
 * @param toParse string to parse
 * @param defaultValue default value to use if an error is raised
 */
export const parseJson = (toParse: string, defaultValue = {}) => {
    try {
        return JSON.parse(toParse);
    } catch (ex) {
        return defaultValue;
    }
};

/**
 * Wrap the stringify function from the JSON api
 * So if toTransform is undefined, it will return 'undefined'
 * @param toTransform object to stringify
 * @param defaultValue default value to use if an error is raised
 */
export const stringify = (toTransform: any, defaultValue = '') => {
    try {
        return JSON.stringify(toTransform);
    } catch (ex) {
        return defaultValue;
    }
};
