/* eslint-disable react/no-unused-prop-types */
import React from 'react';

export enum STATUS {
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error',
    INITIAL = 'initial',
}

export type Library = {
    cssUrl?: string;
    url: string;
    status: STATUS;
};

export interface UseInjectLibraryOptions {
    /**
     * Library to download.
     */
    library: Library;
    /**
     * Whether we should download the CSS associated to this library. Please be careful with this flag, the fact
     * that you can pass this as true does not mean that there is a CSS file for the library. Make sure that the lib
     * that you are downloading has some CSS associated to it.
     */
    shouldImportCss?: boolean;
    /**
     * Callback to be executed once the JS lib is loaded.
     */
    onLoad?: () => void;
    /**
     * Callback to be executed if there was an error while loading the JS lib.
     */
    onError?: () => void;
    /**
     * Whether this hook should be executed or not.
     */
    shouldLoadLibrary?: boolean;
}

/**
 * Hook that retrieves a specific library from the CDN in order to lazy load it into our frontend app.
 * The idea behind this is to avoid installing, importing and bundle manage these libraries, knowing that
 * we can just easily download them from our CDN and use them as any other library.
 * @param options - use inject lib options.
 */
export const useInjectLibrary = (options: UseInjectLibraryOptions) => {
    const { library, shouldImportCss, onLoad, shouldLoadLibrary = true, onError } = options;
    const [libraryStatus, setLibraryStatus] = React.useState<STATUS>(STATUS.INITIAL);

    React.useEffect(() => {
        if (shouldLoadLibrary && library) {
            if (libraryStatus !== STATUS.LOADED && libraryStatus !== STATUS.LOADING) {
                const assetUrl = library.url;

                const libScript = document.createElement('script');
                libScript.setAttribute('src', assetUrl);

                libScript.onload = () => {
                    setLibraryStatus(STATUS.LOADED);

                    if (onLoad) {
                        onLoad();
                    }
                };

                libScript.onerror = () => {
                    setLibraryStatus(STATUS.ERROR);

                    if (onError) {
                        onError();
                    }
                };

                document.head.appendChild(libScript);

                if (shouldImportCss) {
                    const cssAssetUrl = library.cssUrl || '';
                    const link = document.createElement('link');
                    link.setAttribute('rel', 'stylesheet');
                    link.setAttribute('href', cssAssetUrl);
                    document.head.appendChild(link);
                }

                setLibraryStatus(STATUS.LOADING);
            } else if (onLoad && libraryStatus === STATUS.LOADED) {
                onLoad();
            }
        }
    }, [shouldImportCss, onLoad, shouldLoadLibrary, onError, library, libraryStatus]);
};
