import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    ColorPalette,
    FlexBox,
    GenericBlock,
    Icon,
    Orientation,
    Size,
    Text,
    Typography,
    Link,
    Theme,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';
import { MediaPreview } from '@lumapps/widget-base/types';

import { MediaSource } from '../../types';
import { getMediaIcon, getMediaUrl } from '../../utils';
import { EmbeddedPdf } from '../EmbeddedPdf/EmbeddedPdf';

import '../EmbeddedPdf/index.scss';

export interface PdfPreviewBlockProps {
    media?: MediaPreview;
    className?: string;
    theme?: Theme;
}

const CLASSNAME = 'embedded-pdf';

/**
 * Displays a given pdf as an embed pdf with a GenericBlock below
 *
 * @family Files
 * @param PdfPreviewBlockProps
 * @returns PdfPreviewBlock
 */
export const PdfPreviewBlock: React.FC<PdfPreviewBlockProps> = ({ media, className, theme = Theme.light }) => {
    const { translateAndReplace } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    const isLightTheme = theme === Theme.light;
    // TODO: Always display informations when https://lumapps.atlassian.net/browse/CTRIB-3319 fix is done
    const isCreatedAtCorrect = false;

    const iconInformation = getMediaIcon(media);
    const formatedCreatedAtDate = formatFromNow({
        date: media?.createdAt,
        dateStringFormat: 'LL',
        thresholdDisplayFullDate: 2,
    });

    const mediaUrl = React.useMemo(() => {
        if (!media) {
            return undefined;
        }

        return media?.source === MediaSource.HAUSSMANN_MEDIA ? getMediaUrl(media.uuid || media.mediaId) : media?.url;
    }, [media]);

    return (
        <FlexBox
            className={block({ 'theme-light': isLightTheme, 'theme-dark': !isLightTheme }, [className])}
            orientation={Orientation.vertical}
        >
            <EmbeddedPdf media={media} fitHorizontally />
            <GenericBlock className={element('pdf-informations')} orientation="horizontal" hAlign="center">
                <GenericBlock.Figure>
                    <Icon color={iconInformation.color} hasShape icon={iconInformation.icon} size={Size.m} />
                </GenericBlock.Figure>
                <GenericBlock.Content>
                    {mediaUrl ? (
                        <Link
                            color={isLightTheme ? ColorPalette.dark : ColorPalette.light}
                            href={mediaUrl}
                            target="_blank"
                            typography={Typography.subtitle2}
                        >
                            {media?.fileName}
                        </Link>
                    ) : (
                        <Text
                            as="span"
                            typography={Typography.subtitle2}
                            color={isLightTheme ? ColorPalette.dark : ColorPalette.light}
                        >
                            {media?.fileName}
                        </Text>
                    )}

                    {!!formatedCreatedAtDate && isCreatedAtCorrect && (
                        <Text
                            as="span"
                            typography="body1"
                            color={isLightTheme ? ColorPalette.dark : ColorPalette.light}
                            colorVariant="L2"
                        >
                            {translateAndReplace(GLOBAL.RELATIVE_CREATED_AT, {
                                RELATIVE_DATE: formatedCreatedAtDate,
                            })}
                        </Text>
                    )}
                </GenericBlock.Content>
            </GenericBlock>
        </FlexBox>
    );
};
