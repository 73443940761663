export enum WIDGET_POST_LIST {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=146299304
     */
    POST_ACTIONS_LABEL = 'COMMUNITY_POST.ACTIONS_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=146301970
     */
    DISPLAY_POST = 'COMMUNITY_POST.DISPLAY_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144395828
     */
    PIN_POST = 'COMMUNITY_POST.PIN_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144395829
     */
    UNPIN_POST = 'COMMUNITY_POST.UNPIN_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144505287
     */
    UNPIN_CONFIRM_TITLE = 'COMMUNITY_POST.UNPIN_CONFIRM_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144505450
     */
    UNPIN_CONFIRM_DESCRIPTION = 'COMMUNITY_POST.UNPIN_CONFIRM_DIALOG_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144395832
     */
    DELETE_POST = 'COMMUNITY_POST.DELETE_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=147209388
     */
    DELETE_CONFIRM_TITLE = 'COMMUNITY_POST.DELETE_CONFIRM_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=147209354
     */
    DELETE_CONFIRM_DESCRIPTION = 'COMMUNITY_POST.DELETE_CONFIRM_DIALOG_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144395833
     */
    MOVE_POST = 'COMMUNITY_POST.MOVE_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182710020
     */
    USER_SHARED_FOR = 'COMMUNITY_POST.USER_SHARED_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183799314
     */
    YOU_SHARED_FOR = 'COMMUNITY_POST.YOU_SHARED_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30579820
     */
    DISCARD_SHARE = 'FRONT.COMMUNITY.POST.ACTION.UNSHARE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32309794
     */
    DISCARD_SHARE_SUCCESS = 'FRONT.COMMUNITY.POST.UNSHARED.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32314573
     */
    DISCARD_SHARE_FAILURE = 'FRONT.COMMUNITY.POST.UNSHARED.FAILED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=31969297
     */
    SHARED_WITH = 'FRONT.COMMUNITY.POST.SHARED_WITH',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452562
     */
    PINNED = 'COMMUNITY_POST_PINNED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453384
     */
    FLAG_ANSWERED = 'POST_FLAG_ANSWERED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453387
     */
    FLAG_UNANSWERED = 'POST_FLAG_UNANSWERED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=192788015
     */
    BACK_TO_POSTS_LIST = 'COMMUNITY_POST.BACK_TO_POSTS_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=218673670
     */
    FILTERS_IS_REPORTED = 'COMMUNITY_POST.FILTERS_IS_REPORTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222946793
     */
    FILTERS_SEARCH_FOR_A_POST = 'COMMUNITY_POST.FILTERS_SEARCH_FOR_A_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222915924
     */
    FILTERS_AS_RELEVANT = 'COMMUNITY_POST.FILTERS_AS_RELEVANT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222916203
     */
    FILTERS_NOT_AS_RELEVANT = 'COMMUNITY_POST.FILTERS_NOT_AS_RELEVANT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=236519814
     */
    FILTERS_COMMENTS_RELEVANCY = 'COMMUNITY_POST.FILTERS_COMMENTS_RELEVANCY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=151743815
     */
    FILTERS_CATEGORIES = 'WIDGET_POST_LIST.CATEGORIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241470605
     */
    FILTERS_SITES = 'COMMUNITY_POST.FILTERS_SITES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=241223493
     */
    FILTERS_TYPES = 'COMMUNITY_POST.FILTERS_POST_TYPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=296928873
     */
    PUBLISHED_A_POST = 'POSTS.PUBLISHED_A_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=296928889
     */
    PUBLISHED_A_POST_WITH_STATUS = 'POSTS.PUBLISHED_A_POST_WITH_STATUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=296928895
     */
    PUBLISHED_A_POST_IN_LOCATION = 'POSTS.PUBLISHED_A_POST_IN_LOCATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=296928926
     */
    PUBLISHED_A_POST_IN_LOCATION_WITH_STATUS = 'POSTS.PUBLISHED_A_POST_IN_LOCATION_WITH_STATUS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=296935009
     */
    USER_SHARED_A_POST = 'POSTS.USER_SHARED_A_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=297234778
     */
    YOU_SHARED_A_POST = 'POSTS.YOU_SHARED_A_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=338153576
     */
    USER_SHARED_A_POST_IN_LOCATION = 'POSTS.USER_SHARED_A_POST_IN_LOCATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=443274285
     */
    WARNING_MESSAGE_SLIDESHOW_VIEW = 'WIDGET_POST_LIST.WARNING_MESSAGE_SLIDESHOW_VIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=338153519
     */
    YOU_SHARED_A_POST_IN_LOCATION = 'POSTS.YOU_SHARED_A_POST_IN_LOCATION',
}

export const getPostPublicationTranslationKey = ({
    hasLocation,
    hasStatus,
}: {
    hasLocation: boolean;
    hasStatus: boolean;
}) => {
    if (hasLocation && hasStatus) {
        return WIDGET_POST_LIST.PUBLISHED_A_POST_IN_LOCATION_WITH_STATUS;
    }
    if (hasLocation) {
        return WIDGET_POST_LIST.PUBLISHED_A_POST_IN_LOCATION;
    }
    if (hasStatus) {
        return WIDGET_POST_LIST.PUBLISHED_A_POST_WITH_STATUS;
    }

    return WIDGET_POST_LIST.PUBLISHED_A_POST;
};
