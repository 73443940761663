/* istanbul ignore file */
import { isMobileAllowed } from '@lumapps/mobile/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isQrCodeLoginFeatureEnabled } from '../../ducks/selectors';
import { MobileLoginConfigurationSideNavigation } from './MobileLoginConfigurationSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isMobileAllowed(state);
    const isQrCodeFeatureEnabled = isQrCodeLoginFeatureEnabled(state);

    return {
        isAllowed,
        isQrCodeFeatureEnabled,
    };
};

const ConnectedMobileLoginConfigurationSideNavigation = connect(mapStateToProps)(
    MobileLoginConfigurationSideNavigation,
);

export { ConnectedMobileLoginConfigurationSideNavigation as MobileLoginConfigurationSideNavigation };
