(function IIFE() {
    'use strict';

    /////////////////////////////

    function FeedTypeService(Customer, FeedTypeFactory, Instance, LumsitesBaseService) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(FeedTypeFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            postSave: _postSave,
            // eslint-disable-next-line no-use-before-define
            preSave: _formatFeedTypeForServer,
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The default parameters for the service requests.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Format a feed type object before sending it to the server.
         *
         * @param  {Object} feedType A feed type object to be formatted.
         * @return {Object} The formatted feed type object ready to be sent to the server.
         */
        function _formatFeedTypeForServer(feedType) {
            var feedTypeCopy = angular.fastCopy(feedType);

            var map = {
                id: feedTypeCopy.map,
                text: feedTypeCopy.map,
            };

            feedTypeCopy.map = map;

            return feedTypeCopy;
        }

        /**
         * Execute this method right after a feed type has been saved on the server.
         *
         * @param  {Object} feedType The feed type that just got saved.
         * @return {Object} The formatted feed type object for the client.
         */
        function _postSave(feedType) {
            return service.formatObjectForClient(angular.fastCopy(feedType));
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Format the server object to fit with the client model.
         *
         * @param  {Object} feedType The feed type object to be formatted.
         * @return {Object} The formatted feed type object for the client.
         */
        function formatObjectForClient(feedType) {
            if (angular.isUndefinedOrEmpty(feedType.map)) {
                feedType.map = undefined;
                return feedType;
            }

            if (angular.isString(feedType.map)) {
                return feedType;
            }

            if (angular.isString(feedType.map.text)) {
                feedType.map = feedType.map.text;
            } else {
                feedType.map = undefined;
            }

            return feedType;
        }

        /////////////////////////////

        service.formatObjectForClient = formatObjectForClient;

        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                customer: Customer.getCustomerId(),
                instance: Instance.getCurrentInstanceId(),
            };
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('FeedType', FeedTypeService);
})();
