(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileNotificationsController($scope, LxNotificationService, Notification, Translation) {
        'ngInject';

        var vmNotifications = this;
        // eslint-disable-next-line lumapps/file-format
        var vm = $scope.$parent.vm;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////


        /////////////////////////////

        /**
         * Services and utilities.
         */
        vmNotifications.Notification = Notification;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Set all notifications as read, but don't delete them from notification number.
         */
        function clearNotifications() {
            LxNotificationService.confirm(
                Translation.translate('ALERT_NOTIFICATION_CLOSE_ALL'),
                Translation.translate('ALERT_NOTIFICATION_CLOSE_ALL_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onClearAllNotificationsConfirm(answer) {
                    if (answer) {
                        Notification.setAllAsRead();
                    }
                });
        }

        /////////////////////////////

        vmNotifications.clearNotifications = clearNotifications;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Nothing to do here.
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('SocialProfileNotificationsController',
        SocialProfileNotificationsController);
})();
