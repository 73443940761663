import { useCallback, useEffect, useMemo, useState } from 'react';

import compact from 'lodash/compact';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { MetadataFilter } from '@lumapps/metadata-pickers/types';
import { getMultipleMetadata } from '@lumapps/metadata/api';

interface UseUpdateMetadataProps {
    metadata: MetadataFilter[];
    handleUpdateMetadata: (metadata: MetadataFilter[]) => void;
}

export const useUpdateMetadata = ({ metadata, handleUpdateMetadata }: UseUpdateMetadataProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [udpatedMetadata, setUpdatedMetadata] = useState<MetadataFilter[]>([]);

    const metadataToUpdate: string[] = useMemo(
        () => metadata?.filter(({ name, rootId }) => isEmpty(name) || isEmpty(rootId)).map(({ id }) => id),
        [metadata],
    );

    /** Method to fetch metadata information */
    const getMetadata = useCallback(async (metadataIds: string[]) => {
        try {
            setIsLoading(true);
            setHasError(false);
            const data = await getMultipleMetadata(metadataIds);
            const response = await data;
            const formatedData = response.data.items.map(({ id, name, familyKey }) => ({
                id,
                name,
                rootId: familyKey,
            }));
            setUpdatedMetadata(formatedData);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    /** Trigger a fetch when there are uncomplete metadata */
    useEffect(() => {
        if (!isEmpty(metadataToUpdate)) {
            getMetadata(metadataToUpdate);
        }
    }, [getMetadata, metadataToUpdate]);

    /** Trigger a filter local update when updated metadata are ready */
    useEffect(() => {
        if (!isEmpty(udpatedMetadata)) {
            /** Update all the metadata with newly fetched information */
            const allUpdatedMetadata = metadata.map((value) =>
                includes(metadataToUpdate, value.id) ? find(udpatedMetadata, ({ id }) => id === value?.id) : value,
            );
            handleUpdateMetadata(compact(allUpdatedMetadata));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [udpatedMetadata]);

    return {
        isLoading,
        hasError,
    };
};
