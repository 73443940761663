import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function LikeButtonController($rootScope, $timeout, Features, LxDropdownService, Translation, Reaction, User) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The dropdown toggle timeout in ms.
     *
     * @type {number}
     */
    const _DROPDOWN_TOGGLE_TIMEOUT = 500;

    /**
     * The maximum number of likes to display in the dropdown.
     *
     * @type {number}
     */
    const _MAX_NB_LIKE_REACTIONS = 10;

    /**
     * The fields to retrieve during the call on the list of users liking the content.
     *
     * @type {Object}
     */
    const _USER_PROJECTION = {
        items: {
            firstName: true,
            lastName: true,
            uid: true,
        },
    };

    /**
     * Contains the promise of the timeout when we enter the dropdown.
     *
     * @type {$timeout}
     */
    let _enterTimeout;

    /**
     * Whether the mouse is on dropdown or not.
     *
     * @type {boolean}
     */
    let _isMouseOnDropdown = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if a call to toggle the like status is already in progress or not.
     *
     * @type {boolean}
     */
    vm.isTogglingInProgress = false;

    /**
     * The list key to use for the latest likes call.
     *
     * @type {string}
     */
    vm.KEY_LATEST_LIKES = 'content-latest-likes-';

    /**
     * The like button id.
     *
     * @type {string}
     */
    vm.LIKE_BUTTON_ID = `like-button-${generateUUID()}`;

    /**
     * The dropdown id displaying the list of users who liked the content.
     *
     * @type {string}
     */
    vm.DROPDOWN_ID = `likers-list-${generateUUID()}`;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.User = User;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Update the list of users liking the current content.
     *
     * @param {Object}  response    The original response from the like/unlike endpoint success call.
     * @param {Object}  user        The simplified user object that is being added / removed.
     * @param {boolean} [add=false] Indicates if we should add the new user to the list or remove it.
     */
    function _updateLatestUserLikes(response, user, add) {
        if (angular.isUndefinedOrEmpty(user)) {
            vm.isTogglingInProgress = false;

            return;
        }

        add = add || false;

        const userList = User.displayList(vm.KEY_LATEST_LIKES);
        const userIndex = findIndex(User.displayList(vm.KEY_LATEST_LIKES), {
            uid: user.id,
        });

        if (add && userList.length < _MAX_NB_LIKE_REACTIONS && userIndex === -1) {
            userList.push(user);
        } else if (!add && userIndex > -1) {
            userList.splice(userIndex, 1);
        }

        vm.isTogglingInProgress = false;
    }

    /**
     * Toggle the like status of the current content for the current user.
     */
    function _toggleLikeStatus() {
        if (vm.isTogglingInProgress) {
            return;
        }

        vm.isTogglingInProgress = true;

        Reaction.toggleLike(
            vm.content,
            vm.kind,
            function onToggleLikeSuccess(resource, user, add) {
                _updateLatestUserLikes(resource, user, Boolean(add));
            },
            function onToggleLikeError() {
                vm.isTogglingInProgress = false;
            },
        );

        if (Features.hasFeature('advanced_community') && vm.content.type === 'post') {
            // So we can synchronise pinned posts / posts.
            $rootScope.$broadcast('post__update', vm.content);
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close dropdown on mouse leave.
     *
     * @param {string} target From which target the function has been called.
     */
    function closeDropdown(target) {
        if (vm.content.likes === 0) {
            return;
        }

        if (target === 'dropdown') {
            _isMouseOnDropdown = false;
        }

        if (target === 'source' && !_isMouseOnDropdown) {
            $timeout.cancel(_enterTimeout);
        }

        $timeout(() => {
            if (target === 'source' && _isMouseOnDropdown) {
                return;
            }

            LxDropdownService.close(vm.DROPDOWN_ID);
        }, _DROPDOWN_TOGGLE_TIMEOUT);
    }

    /**
     * Retrieve the latest 10 likes for the current content.
     */
    function getLatestLikes() {
        if (vm.content.likes === 0) {
            return;
        }

        User.cacheFilterize(
            angular.extend(angular.fastCopy(vm.reactedEntityFilter), {
                action: 'PAGE_READ',
                maxResults: _MAX_NB_LIKE_REACTIONS,
                showHidden: false,
                status: 'enabled',
            }),
            undefined,
            undefined,
            vm.KEY_LATEST_LIKES,
            _USER_PROJECTION,
        );

        _enterTimeout = $timeout(() => {
            LxDropdownService.open(vm.DROPDOWN_ID, {
                target: `#${vm.LIKE_BUTTON_ID}`,
            });
        }, _DROPDOWN_TOGGLE_TIMEOUT);
    }

    /**
     * Set mouse on dropdown to true.
     */
    function isOnDropdown() {
        _isMouseOnDropdown = true;
    }

    /**
     * Open a dialog with all the users that have liked the related content.
     */
    function openLikesDialog() {
        $rootScope.$broadcast('user-list-dialog__open', {
            params: vm.reactedEntityFilter,
            title: Translation.translate('USER_LIST_LIKES'),
        });
    }

    /////////////////////////////

    vm.closeDropdown = closeDropdown;
    vm.debouncedToggleLikeStatus = debounce(_toggleLikeStatus, 200);
    vm.getLatestLikes = getLatestLikes;
    vm.isOnDropdown = isOnDropdown;
    vm.openLikesDialog = openLikesDialog;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.kind = vm.kind || 'Content';
        vm.theme = vm.theme || 'light';

        // Initialize the like count if it doesn't exist.
        if (angular.isUndefined(vm.content.likes)) {
            vm.content.likes = 0;
        }

        if (angular.isUndefinedOrEmpty(vm.content.uid) && angular.isDefinedAndFilled(vm.content.id)) {
            vm.content.uid = vm.content.id;
        }

        vm.content.liked = angular.isUndefined(vm.content.liked) ? false : vm.content.liked;

        vm.reactedEntityFilter = {
            reactedEntityKey: vm.content.uid,
            reactedEntityKind: vm.content.type,
        };

        vm.KEY_LATEST_LIKES = `content-latest-likes-${vm.content.uid}`;
    }

    init();
}

/**
 * Display a like count and allows toggling of the like status for the current user.
 *
 * @param {Object}  content          The related content object to be liked / unliked.
 * @param {boolean} [isExtended]     Indicates if we need to display the label next to the icon or not.
 * @param {string}  [kind='Content'] The kind of the related content object.
 * @param {string}  [theme='light']  The theme to apply to the template.
 */

function LikeButtonDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: LikeButtonController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            content: '<lsContent',
            isExtended: '<?lsIsExtended',
            kind: '@?lsKind',
            theme: '@?lsTheme',
        },
        templateUrl: '/client/front-office/modules/social/views/like-button.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsLikeButton', LikeButtonDirective);

/////////////////////////////

export { LikeButtonDirective };
