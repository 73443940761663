/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

const LazyBlockMetadata = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-metadata-block-metadata' */
            './ConnectedBlockMetadata'
        ),
);

export const BlockMetadata = (props: any) => (
    <Suspense loadingFallback={<WidgetSkeletonRectangle style={{ height: '40px' }} useDefaultHeight={false} />}>
        <LazyBlockMetadata {...props} />
    </Suspense>
);
