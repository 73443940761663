/* istanbul ignore file */
import { Select, SelectMultiple, Autocomplete, AutocompleteMultiple } from '@lumx/react';

/**
 * @deprecated Select is being deprecated because it provides a poor UX, a11y and API.
 *      Replace it with the SelectTextField with `selectionType="single"`.
 *      It does not provide the same level of customizability, but it's generally for the best to simplify and
 *      homogenize our UIs. Contact the DS team for help in the migration.
 */
const DeprecatedSelect = Select;

/**
 * @deprecated SelectMultiple is being deprecated because it provides a poor UX, a11y and API.
 *      Replace it with the SelectTextField with `selectionType="multiple"`.
 *      It does not provide the same level of customizability, but it's generally for the best to simplify and
 *      homogenize our UIs. Contact the DS team for help in the migration.
 */
const DeprecatedSelectMultiple = SelectMultiple;

/**
 * @deprecated Autocomplete is being deprecated because it provides a poor UX, a11y and API.
 *      Replace it with the SelectTextField with `selectionType="single"`.
 *      It does not provide the same level of customizability, but it's generally for the best to simplify and
 *      homogenize our UIs. Contact the DS team for help in the migration.
 */
const DeprecatedAutocomplete = Autocomplete;

/**
 * @deprecated AutocompleteMultiple is being deprecated because it provides a poor UX, a11y and API.
 *      Replace it with the SelectTextField with `selectionType="multiple"`.
 *      It does not provide the same level of customizability, but it's generally for the best to simplify and
 *      homogenize our UIs. Contact the DS team for help in the migration.
 */
const DeprecatedAutocompleteMultiple = AutocompleteMultiple;

export * from '@lumx/react';

export {
    DeprecatedSelect as Select,
    DeprecatedSelectMultiple as SelectMultiple,
    DeprecatedAutocomplete as Autocomplete,
    DeprecatedAutocompleteMultiple as AutocompleteMultiple,
};
