(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetContactController(Content) {
        'ngInject';

        var vm = this;

        vm.Content = Content;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    /**
     * The contact form widget.
     *
     * Displays a contact form with subject and message field that sends an email to a given email address.
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetContactDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetContactController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetContact', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-contact/views/widget-contact.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetContact', WidgetContactDirective);
})();
