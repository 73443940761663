import React from 'react';

import { flatMapChildrenToMapper } from '@lumapps/utils/element/flatMapChildrenToMapper';

import { MainAction } from '../components/SelectableCard/SelectableCard.Actions';

/**
 * The mapping method that checks whether children are of type MainAction
 */
export const mainActionMapper = (child: React.ReactNode): boolean | boolean[] => {
    if (React.isValidElement(child)) {
        if (child.type === MainAction) {
            return true;
        }

        if (child.props?.children) {
            return flatMapChildrenToMapper(child.props.children, mainActionMapper);
        }
    }

    return false;
};

/**
 * This function recursively check each child's type and return how many of them are MainAction
 * @param children
 * @returns number
 */
export const getMainActionCount = (children: React.ReactNode): number => {
    const arr = flatMapChildrenToMapper(children, mainActionMapper);
    return arr.filter(Boolean).length;
};
