/**
 * Unescape HTML open and close tag
 * @param str The text to unescape
 */
export function unescapeHTML(str: string) {
    return str
        .replace(/&nbsp;/g, '\u00A0')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
}
