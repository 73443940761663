import { toCompatibleLanguage } from '@lumapps/translations';

/* eslint-disable */
(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSubNavController($scope, $stateParams, Content, Instance, MainNav, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The current language.
         *
         * @type {string}
         */
        var _currentLanguage;

        /**
         * The complete slug of parent page.
         *
         * @type {string}
         */
        var _slugFull;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The currently selected property.
         *
         * @type {Object}
         */
        vm.selectedProperty = {};

        /**
         * Services and utilities.
         */
        vm.Content = Content;
        vm.MainNav = MainNav;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the correct nav item for current language.
         * If the nav item is not set for the current language, the fallback language is used in read mode.
         *
         * @return {Object} The nav item to display.
         */
        function _getSelectedNavItem() {
            var selectedPropertyForCurrentLanguage = _.get(vm.selectedProperty.navSelected, _currentLanguage, {});

            if (!angular.isDefinedAndFilled(selectedPropertyForCurrentLanguage.uuid) && !vm.parentCtrl.designerMode()) {
                selectedPropertyForCurrentLanguage = _.get(
                    vm.selectedProperty.navSelected,
                    Instance.getInstance().defaultLang || 'en',
                    {},
                );
            }

            /**
             * If the listCurrentSub is list, either return the selectedPropertyForCurrentLanguage if same id as current content
             * or else return the first available item of the current content in the nav
             */
            const isListMode = _.get(vm.selectedProperty, 'listCurrentSub') === 'list';
            const currentContentId = Content.getCurrent().id;

            return isListMode && currentContentId !== selectedPropertyForCurrentLanguage?.uuid
                ? MainNav.findNavItemById(currentContentId)
                : selectedPropertyForCurrentLanguage;
        }

        /**
         * Build the correct sub nav items to display.
         */
        function _buildSubNavItems() {
            vm.mainNavItem = [];

            var selectedPropertyForCurrentLanguage = _getSelectedNavItem();

            if (angular.isUndefinedOrEmpty(selectedPropertyForCurrentLanguage)) {
                return;
            }

            var currentSelectedSlugFull = _.get(selectedPropertyForCurrentLanguage, 'slugFull', '__default');

            if (currentSelectedSlugFull === '__default') {
                vm.mainNavItem = MainNav.findNavItemById(selectedPropertyForCurrentLanguage.uuid);

                return;
            }

            /*
             * For top level elements of the main nav, we don't prepend the content slug otherwise
             * the comparison in findNavItemBySlug is wrong and does things like:
             * '/my-root-url' === 'my-root-url'.
             */
            var preSlug = _.includes(currentSelectedSlugFull, '/') ? '' : currentSelectedSlugFull;

            var currentContentSlug = _.get(Content.getCurrent(), 'slug');

            vm.mainNavItem = MainNav.findNavItemBySlug(currentSelectedSlugFull);
            if (angular.isUndefinedOrEmpty(vm.mainNavItem) && angular.isDefinedAndFilled(preSlug)) {
                vm.mainNavItem = MainNav.findNavItemBySlug(preSlug + Translation.translate(currentContentSlug));
            }
            if (angular.isUndefinedOrEmpty(vm.mainNavItem)) {
                vm.mainNavItem = MainNav.findNavItemBySlug(Translation.translate(currentContentSlug));
            }
        }

        /**
         * Returns a new instance of widget properties to handle backward compatibility.
         *
         * @return {Object} The empty object with good properties.
         */
        function _getNewDefaultEmptyObj() {
            return angular.extend(
                {
                    isDefault: true,
                    navSelected: {},
                },
                _.pick(vm.widget.properties, 'listCurrentSub', 'showHidden', 'showParent'),
            );
        }

        /**
         * Backward compatibility for old sub nav settings.
         *
         * @return {Object} A selectedProperty object in the new format.
         */
        function _getSelectedPropertyFromOldSettings() {
            var selectedProperty = _getNewDefaultEmptyObj();
            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;
            var navSelected = _.get(properties, 'navSelected', {});

            // Backward compatibility.
            if (angular.isUndefinedOrEmpty(navSelected)) {
                return selectedProperty;
            }

            // Example of old navSelected format: { en: { uuid: '123' }, fr: { uuid: '456' } }.
            var navSelectedLangs = _.omit(navSelected, ['uuid', 'id']);

            // Set it as the '__default' value for each of the existing langs that have been set.
            angular.forEach(navSelectedLangs, function forEachSelectedElements(value, key) {
                var navSelectedElement = {};
                navSelected[key] = value;

                properties[key] = {
                    __default: angular.extend(_getNewDefaultEmptyObj(), {
                        navSelected: navSelectedElement,
                    }),
                };

                // For the current lang, also set the selectedProperty.
                if (key === _currentLanguage) {
                    selectedProperty.navSelected[key] = value;
                }
            });

            var selectedNavId = navSelected.uuid || navSelected.id;

            // Some older backward compatibility... used on Dalkia for example.
            if (angular.isDefinedAndFilled(selectedNavId)) {
                var navSelectedToSet = {};
                navSelectedToSet[_currentLanguage] = {
                    uuid: selectedNavId,
                };

                // Fix for some old backward config in Dalkia where this value would be a boolean.
                if (!angular.isObject(properties[_currentLanguage])) {
                    properties[_currentLanguage] = {};
                }

                // Set the current lang value.
                properties[_currentLanguage].__default = _.extend(_getNewDefaultEmptyObj(), {
                    navSelected: navSelectedToSet,
                });

                selectedProperty.navSelected = navSelectedToSet;
            }

            // Delete old properties.
            delete properties.listCurrentSub;
            delete properties.showHidden;
            delete properties.showParent;
            delete properties.navSelected;

            return selectedProperty;
        }

        /**
         * Sets the current language of the page.
         * Adapt to compatible v1 language (e.g. pt-BR => pt_br)
         */
        function _setCurrentLanguage() {
            _currentLanguage = toCompatibleLanguage(
                vm.parentCtrl.designerMode() ? Translation.inputLanguage : Translation.getLang('current'),
            );
        }

        /**
         * Figure out what the current selected property is
         * handles backward compatibility and multi language settings.
         */
        function _setSelectedProperty() {
            var properties = vm.widget.properties || {};

            // Backward compatibility - turn any existing value in the __default one.
            if (properties.navSelected) {
                vm.selectedProperty = _getSelectedPropertyFromOldSettings() || {};

                return;
            }

            var currentPropertiesForSlug = _.get(properties, '[' + _currentLanguage + '][' + _slugFull + ']', {});
            var defaultPropertiesForCurrentLanguage = _.get(properties, [_currentLanguage] + '.__default', {});

            if (
                angular.isDefinedAndFilled([_slugFull, currentPropertiesForSlug.listCurrentSub], 'every') &&
                !currentPropertiesForSlug.useDefaultSettings
            ) {
                vm.selectedProperty = currentPropertiesForSlug || {};

                return;
            }

            /*
             * When we want 'default settings' and just list the current children,
             * we can set slugFull to the current $state slug 'on the fly'.
             */
            if (defaultPropertiesForCurrentLanguage.listCurrentSub === 'list') {
                _.set(defaultPropertiesForCurrentLanguage, 'navSelected[' + _currentLanguage + '].slugFull', _slugFull);
            }

            vm.selectedProperty = defaultPropertiesForCurrentLanguage || {};
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get all widget classes.
         *
         * @return {Array} The computed widget classes.
         */
        function getWidgetClass() {
            var widgetClasses = [];

            vm.parentCtrl.getWidgetClass(widgetClasses);

            if (vm.isWidgetEmpty()) {
                widgetClasses.push('widget--is-empty');
            }

            return widgetClasses;
        }

        /**
         * Check if the widget is empty.
         *
         * @return {boolean} Whether the widget is empty.
         */
        function isWidgetEmpty() {
            return (
                !MainNav.isCallInProgress() &&
                (angular.isUndefined(vm.mainNavItem) ||
                    (!vm.selectedProperty.showHidden && !MainNav.hasVisibileChildren(vm.mainNavItem)) ||
                    (angular.isDefined(vm.mainNavItem) && angular.isUndefinedOrEmpty(vm.mainNavItem.children)))
            );
        }

        /**
         * Check if the widget is hidden in read mode.
         *
         * @return {boolean} Whether the widget is hidden.
         */
        function isWidgetHidden() {
            var isHidden = !vm.parentCtrl.designerMode() && !vm.selectedProperty.noResults && vm.isWidgetEmpty();

            vm.parentCtrl.isHidden = isHidden;

            return isHidden;
        }

        /////////////////////////////

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Initialize widget on settings change.
         *
         * @param {Event}  evt        The original event triggering this method.
         * @param {string} widgetUuid The id of the updated widget.
         */
        $scope.$on('widget-sub-nav-settings', function onWidgetSubNavSettingsChange(evt, widgetUuid) {
            if (vm.widget.uuid === widgetUuid) {
                vm.init();
            }
        });

        /**
         * Initialize widget when the main nav is loaded.
         */
        $scope.$on('main-nav-loaded', function onMainNavigationLoad() {
            vm.init();
        });

        /////////////////////////////

        /**
         * Initialize controller.
         */
        vm.init = function init() {
            if (angular.isUndefinedOrEmpty(MainNav.getCurrent())) {
                return;
            }

            _setCurrentLanguage();

            var properties = vm.widget.properties || {};

            properties[_currentLanguage] = Translation.translate(properties) || {
                __default: {
                    listCurrentSub: 'pick',
                    navSelected: {},
                },
            };

            _slugFull = $stateParams.slug ? Utils.getParentFullSlug($stateParams.slug) : undefined;

            _setSelectedProperty();
            _buildSubNavItems();
        };

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    /////////////////////////////

    /**
     * Widget sub nav directive.
     * Displays a navigation widget according to the widget properties.
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetSubNavDirective() {
        'ngInject';

        function WidgetSubNavLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetSubNavController,
            controllerAs: 'vm',
            link: WidgetSubNavLink,
            replace: true,
            require: ['widgetSubNav', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-sub-nav/views/widget-sub-nav.html',
        };
    }

    angular.module('Widgets').directive('widgetSubNav', WidgetSubNavDirective);
})();
