import { WIDGET_PERSONAL_FEED_PREVIEW_DEFAULT_PROPERTIES } from '@lumapps/widget-personal-feed-preview/constants';

/* eslint-disable lines-around-directive */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetPersonalFeedPreviewController(ReduxStore) {
        'ngInject';

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            const widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);
            if (vm.widget.properties.viewModeVariant === 'ungrouped') {
                widgetClass.push('widget--view-mode-variant-ungroup');
            }
            return widgetClass;
        }

        vm.getWidgetClass = getWidgetClass;

        /**
         * Initialize the widget properties and the controller parameters.
         * If property already exists then use it, if not fill with default value
         */
        function _initProperties() {
            vm.widget.properties = { ...WIDGET_PERSONAL_FEED_PREVIEW_DEFAULT_PROPERTIES, ...vm.widget.properties };
        }

        function init() {
            _initProperties();
        }

        init();
    }

    function widgetPersonalFeedPreviewDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetPersonalFeedPreviewController,
            controllerAs: 'vm',
            replace: true,
            require: ['widgetPersonalFeedPreview', '^widget'],
            restrict: 'E',
            link,
            scope: {
                widget: '=',
            },
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-personal-feed-preview/views/widget-personal-feed-preview.html',
        };
    }

    window.angular.module('Widgets').directive('widgetPersonalFeedPreview', widgetPersonalFeedPreviewDirective);
})();
