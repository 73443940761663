import { shape, bool } from 'prop-types';

import { widgetType } from '../types';

/** The prop types definition for the widget community intro. */
export const widgetCommunityIntroTypes = {
    ...widgetType,
    component: shape({
        ...widgetType.component,
        properties: shape({
            ...widgetType.component.properties,
        }),
    }),
    isTagzEnabled: bool.isRequired,
};
