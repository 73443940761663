import has from 'lodash/has';
import zipObject from 'lodash/zipObject';
import { angularApi } from '@lumapps/router/routers';
import { communityEvents } from '@lumapps/community-events/routes';

// ///////////////////////////

function WidgetCommunityNavigationController(
    $rootScope,
    $state,
    $stateParams,
    Instance,
    Community,
    CommunityTemplate,
    Content,
    LxDialogService,
    User,
    Features,
    Translation,
) {
    'ngInject';

    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The current community.
     */
    let _community;

    /**
     * The route to navigate to when we switch tabs.
     *
     * @type {string}
     */
    const _stateRoute = 'app.front.community';

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * Current community available/enable views.
     *
     * @type {Object}
     */
    vm.views = undefined;

    /**
     * Current community type.
     *
     * @type {Object}
     */
    vm.communityType = undefined;

    /**
     * Current user connected type.
     *
     * @type {Object}
     */
    vm.currentUserType = undefined;

    /**
     * Current displayed page/template.
     *
     * @type {string}
     */
    vm.currentView = undefined;

    /**
     * Which navigation component to show based on feature flag.
     *
     * @type {boolean}
     */
    vm.showReactNav = false;

    /**
     * Community slug.
     *
     * @type {string}
     */
    vm.slug = undefined;

    /**
     * Current site ID and community ID
     * Used to retrieve the community custom navigation.
     *
     * @type {Object}
     */
    vm.communityNavigationQuery = false;

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Get widget class.
     *
     * @return {Array} Widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        return widgetClass;
    }

    /**
     * Indicates if the widget is empty or not while in designer mode.
     *
     * @return {boolean} Whether the widget is empty or not.
     */
    function isWidgetEmpty() {
        return (
            !_community ||
            (!vm.views.calendar &&
                !vm.views.media &&
                !vm.views.dashboard &&
                !vm.views.files &&
                !vm.showReactNav &&
                !vm.views.events)
        );
    }

    /**
     * Indicates if the widget needs to be hidden when in reading mode.
     *
     * @return {boolean} Whether the widget is hidden or not.
     */
    function isWidgetHidden() {
        // The widget should not be accessible outside a community context.
        vm.parentCtrl.isHidden = !vm.parentCtrl.designerMode() && vm.isWidgetEmpty();

        return vm.parentCtrl.isHidden;
    }

    /**
     * Switch to a given view (which really are just tabs).
     *
     * @param {string} view   The view to display.
     * @param {Object} [post] The post to display in the view.
     */
    function switchView(view, post) {
        const identifier = (post && post.uid) || undefined;

        if (!has(vm.views, view)) {
            return;
        }

        $state
            .go(
                _stateRoute,
                {
                    identifier,
                    view,
                },
                {
                    notify: false,
                },
            )
            .then(() => {
                // Replace template with sub-template.
                Content.getCurrent().template = CommunityTemplate.getTemplateByName(_community, view);

                Community.setAllNotificationsAsRead(view);

                $rootScope.$broadcast('community__switch-view', view);
            });
    }

    /**
     * Redirect the user to the community events page (page present in React only).
     */
    function redirectToEventsRoute() {
        _community = Content.getCurrent();
        const translatedCommunitySlug = Translation.translate(_community.slug);

        angularApi.redirect(communityEvents(translatedCommunitySlug));
    }

    // ///////////////////////////

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.switchView = switchView;
    vm.redirectToEventsRoute = redirectToEventsRoute;

    // ///////////////////////////

    /**
     * Initialize controller.
     */
    function init() {
        _community = Content.getCurrent();
        const views = Community.getAvailableViews(_community);
        const currentStateView =
            $state.current.name === 'app.front.content-edit' ? $stateParams.subTemplate : $stateParams.view;
        const currentUser = User.getConnected();
        vm.communityType = _community.communityType;
        vm.currentUserType = currentUser.accountType;
        vm.isOneDriveCommunity = Community.isOneDriveCommunity(_community);
        vm.isOutlookCommunity = Community.isOutlookCommunity(_community);
        vm.views = zipObject(views, views);
        vm.currentView = Community.getDefaultLandingView(currentStateView, views);
        vm.widget.properties.viewMode = vm.widget.properties.viewMode || 'vertical';
        vm.showReactNav = Features.hasFeature('new-community-navigation');
        vm.isSecuredMediaEnabled = Features.hasFeature('security-at-community-level');
        vm.isEventsEnabled = Features.hasFeature('events');
        vm.communityHasEventsEnabled = _community.hasEventsEnabled;
        vm.slug = Translation.translate(_community.slug);

        vm.communityNavigationQuery = {
            communityId: Community.getCurrent().id,
            siteId: Instance.getCurrentInstanceId(),
        };
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = (parentCtrl) => {
        vm.parentCtrl = parentCtrl;

        init();
    };

    // ///////////////////////////

    vm.openCommunityDetailsCardDialog = () => {
        const currentCommunity = _community;
        if (currentCommunity && currentCommunity.id) {
            LxDialogService.open(`community-details-card-${currentCommunity.id}`);
        } else {
            throw new Error('No current community to show');
        }
    };
}

// ///////////////////////////

/**
 * The community navigation widget.
 * This widget provide the user navigation tools and shortcut.
 *
 * @param {Object} widget The widget configuration object.
 */

function WidgetCommunityNavigationDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetCommunityNavigationController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetCommunityNavigation', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-community-navigation/views/widget-community-navigation.html',
    };
}

// ///////////////////////////

angular.module('Widgets').directive('widgetCommunityNavigation', WidgetCommunityNavigationDirective);

// ///////////////////////////

export { WidgetCommunityNavigationDirective };
