/* istanbul ignore file */
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { DOMAIN } from '../constants';
import { initDirectoryEntries } from '../transforms';
import { Directory } from '../types';
import { DirectoriesState } from './types';

let initialState: DirectoriesState = {
    directories: [],
};

/**
 * Since the Legacy application has a lot of complexities, in order to initialize this state,
 * we need to inject the state when we receive the response from the backend, and save it here.
 *
 * Once the redux store begins setup, we hook into one of the angular specific dispatches and update
 * the state with the one processed.
 *
 * TODO: remove this code once we have migrated to React.
 */
function setInitialState(state: any) {
    const newState: any = {
        directories: state.directories,
    };

    initialState = initDirectoryEntries(newState)[DOMAIN];
}

const { actions, reducer } = createSlice({
    domain: DOMAIN,
    initialState,
    reducers: {
        '__angular__/instance/update': (state: any): void => {
            Object.assign(state, initialState);
        },
        updateDirectory: (state: DirectoriesState, action: PayloadAction<Directory>): void => {
            const updatedDirectories = state.directories.map((directory) => {
                if (directory.id === action.payload.id) {
                    return action.payload;
                }

                return directory;
            });

            Object.assign(state, {
                directories: updatedDirectories,
            });
        },
    },
});

export { actions, reducer, setInitialState };
