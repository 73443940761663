import fromMarkdownMdast from 'mdast-util-from-markdown';
import gfm from 'mdast-util-gfm';
import toString from 'mdast-util-to-string';
import gfmSyntax from 'micromark-extension-gfm';

/** Function for convert markdown to text brut without marks */
export function fromMarkdownToText(markdown: string) {
    const mdastTree = fromMarkdownMdast(markdown, {
        extensions: [gfmSyntax()],
        mdastExtensions: [gfm.fromMarkdown],
    });

    return toString(mdastTree);
}
