(function IIFE() {
    'use strict';

    /////////////////////////////

    function ListDialogController($injector, $rootScope, $scope, LxDataTableService, LxDialogService, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the data table.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.LIST_ITEMS_DATA_TABLE_ID = 'list-items-data-table';

        /**
         * The list key used for list calls.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.LIST_KEY = 'list-items-dialog';

        /**
         * The search query to apply to the list of items.
         *
         * @type {string}
         */
        vm.query = '';

        /**
         * The service from which the list will be fetched and the search applied on.
         *
         * @type {Service}
         */
        vm.service = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.LxDialogService = LxDialogService;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the items that have been removed from the original items since the last save.
         *
         * @return {Array} The list of items that were removed from the original items.
         */
        function _getRemovedOriginalItems() {
            return _.reject(vm.originalItems, function isInCurrentItems(item) {
                return _.includes(vm.currentItemsNames, item.name);
            });
        }

        /**
         * List items for a given service.
         */
        function _listItems() {
            var removedOriginalItems = _getRemovedOriginalItems();

            if (angular.isDefinedAndFilled([vm.query, vm.serviceSettings.searchFilterName], 'every')) {
                vm.service.defaultParams[vm.serviceSettings.searchFilterName] = vm.query;
            } else if (angular.isDefinedAndFilled(vm.serviceSettings.searchFilterName)) {
                vm.service.defaultParams[vm.serviceSettings.searchFilterName] = undefined;
            }

            vm.service.filterize(vm.serviceSettings.defaultParams, function onListItemsSuccess(response) {
                if (angular.isUndefinedOrEmpty(response)) {
                    return;
                }

                var service = vm.service._services[vm.LIST_KEY];

                // Remove all the results that are already in the currentItems.
                angular.forEach(vm.currentItems, function removeCurrentItemFromList(item) {
                    Utils.reject(vm.service.displayList(vm.LIST_KEY), {
                        name: item.name,
                    });
                });

                // Remove items from removedOriginalItems that are already in the list.
                angular.forEach(vm.service.displayList(vm.LIST_KEY), function addRemovedOriginalItems(item) {
                    Utils.reject(removedOriginalItems, {
                        name: item.name,
                    });
                });

                var selectedIds = _.map(vm.selectedRows, 'id');

                // Refresh the selected list in lumx datatable.
                angular.forEach(vm.service.displayList(vm.LIST_KEY), function checkItems(item) {
                    if (!_.includes(selectedIds, item.id)) {
                        return;
                    }

                    var selectedItem = _.find(vm.selectedRows, {
                        id: item.id,
                    });

                    $rootScope.$broadcast('lx-data-table__unselect', vm.LIST_ITEMS_DATA_TABLE_ID, selectedItem);
                    $rootScope.$broadcast('lx-data-table__select', vm.LIST_ITEMS_DATA_TABLE_ID, item);
                });

                // Add all the items that were in the original items and that are not in the current ones.
                service._list = service._list.concat(removedOriginalItems);
            }, undefined, vm.LIST_KEY);
        }

        /**
         * Update the representation of the rows that are selected in the data table.
         *
         * @param {Event}  evt          The original event triggering this method.
         * @param {string} dataTableId  The identifier of the data table updating.
         * @param {Array}  selectedRows The selected rows in the data table.
         */
        function _updateRows(evt, dataTableId, selectedRows) {
            if (vm.LIST_ITEMS_DATA_TABLE_ID === dataTableId) {
                vm.selectedRows = selectedRows;
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Forward the selected items to the onAdd callback function and close the dialog.
         */
        function addItems() {
            vm.onAdd(vm.selectedRows);
            LxDialogService.close(vm.dialogId);
        }

        /////////////////////////////

        vm.addItems = addItems;
        vm.debouncedListItems = _.debounce(_listItems, 500);

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Update the selected rows when something is selected in the data table.
         */
        $scope.$on('lx-data-table__selected', _updateRows);

        /**
         * Update the selected rows when something is unselected in the data table.
         */
        $scope.$on('lx-data-table__unselected', _updateRows);

        /**
         * When the custom fields dialog opens, list the custom fields that can be added for that data source.
         *
         * @param {Event}  evt      The dialog close event.
         * @param {string} dialogId The id of the dialog that opens.
         */
        $scope.$on('lx-dialog__open-start', function onDialogCloseEnd(evt, dialogId) {
            if (dialogId !== vm.dialogId) {
                return;
            }

            vm.init();
        });

        /**
         * When the custom fields dialog close, make sure we unselect all selected rows from the data table.
         *
         * @param {Event}  evt      The dialog close event.
         * @param {string} dialogId The id of the dialog that opens.
         */
        $scope.$on('lx-dialog__close-end', function onDialogCloseEnd(evt, dialogId) {
            if (dialogId !== vm.dialogId) {
                return;
            }

            LxDataTableService.unselectAll(vm.LIST_ITEMS_DATA_TABLE_ID);
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            vm.currentItems = vm.currentItems || [];
            vm.onAdd = vm.onAdd || angular.noop;
            vm.originalItems = vm.originalItems || [];
            vm.service = $injector.get(vm.serviceSettings.name);

            vm.isSearchEnabled = vm.isSearchEnabled || false;
            vm.selectedRows = [];

            vm.originalItemsNames = _.map(vm.originalItems, 'name');
            vm.currentItemsNames = _.map(vm.currentItems, 'name');

            _listItems();
        };
    }

    /////////////////////////////

    /**
     * A directive that displays a dialog with a list of items in it.
     * Items can optionnally be searched through and selected (only adding items is supported, not removing).
     *
     * @param {Array}    [currentItems]          A list of items that are already currently selected.
     *                                           They shouldn't be in the response of the list call to the API.
     * @param {string}   dialogId                The identifier of the dialog.
     * @param {string}   [dialogTitle]           The title of the dialog to be displayed in the header.
     * @param {boolean}  [isSearchEnabled=false] Indicates if there's a search field above the list of results.
     * @param {Function} [onAdd]                 A callback function to execute when the add button is clicked.
     * @param {Array}    [originalItems]         A list of items that were originally selected.
     *                                           This is to be able to do a diff between the original items and the
     *                                           current ones for example.
     * @param {Object}   serviceSettings         An object with various options related to the service used to list the items.
     *                                           For example, the name of the service, or the defaultParams to use in all list requests..........
     */

    function ListDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ListDialogController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                currentItems: '<?lsCurrentItems',
                dialogId: '@lsDialogId',
                dialogTitle: '@?lsDialogTitle',
                isSearchEnabled: '<?lsIsSearchEnabled',
                onAdd: '<?lsOnAdd',
                originalItems: '<?lsOriginalItems',
                serviceSettings: '<lsServiceSettings',
            },
            templateUrl: '/client/common/modules/list-dialog/views/list-dialog.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsListDialog', ListDialogDirective);
})();
