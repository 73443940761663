/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

export const loadBlockPost = () =>
    import(
        /* webpackChunkName: 'widget-post-list-block-post' */
        './BlockPost'
    );

const LazyBlockPost = React.lazy(loadBlockPost);

type LazyBlockPostProps = React.ComponentProps<typeof LazyBlockPost> & { loadingFallback?: React.ReactNode };

export const BlockPost: React.FC<LazyBlockPostProps> = ({ loadingFallback, ...props }) => (
    <Suspense loadingFallback={loadingFallback || <WidgetSkeletonRectangle />}>
        <LazyBlockPost {...props} />
    </Suspense>
);
