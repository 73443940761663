import { Point, ReactEditor } from '@lumapps/wrex/slate';
import { insertVoidBlock } from '@lumapps/wrex/slate/utils/insertVoidBlock';

import { EmbeddedMapEditor, EmbeddedMapElement } from '../types';
import { createEmbeddedMap } from './createEmbeddedMap';

/**
 * Insert embed map after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertEmbeddedMap(
    editor: ReactEditor & EmbeddedMapEditor,
    link: Partial<EmbeddedMapElement>,
    point?: Point | undefined,
) {
    const node = createEmbeddedMap({
        title: link.title,
        description: link.description,
        thumbnailUrl: link.thumbnailUrl,
        url: link.url || '',
    });

    const path = insertVoidBlock(editor, node, point);

    return path;
}
