import get from 'lodash/get';

/////////////////////////////

function WidgetHeaderController($element, $scope, $timeout, $transclude, Media, Style) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetHeader = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The widget element.
     *
     * @type {jQElement}
     */
    let _widgetElement;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get wrapper classes.
     *
     * @return {Array} The wrapper CSS classes.
     */
    function getWrapperClasses() {
        const wrapperClasses = [];
        const widgetStyle = widgetHeader.parentCtrl.widget.properties.style;

        const flexDirection = get(widgetStyle, 'header.wrapper.flexDirection') || 'row';

        if (flexDirection === 'row') {
            wrapperClasses.push('widget-header__wrapper--layout-left');
        }

        if (flexDirection === 'row-reverse') {
            wrapperClasses.push('widget-header__wrapper--layout-right');
        }

        if (flexDirection === 'column') {
            wrapperClasses.push('widget-header__wrapper--layout-top');
        }

        if (flexDirection === 'column-reverse') {
            wrapperClasses.push('widget-header__wrapper--layout-bottom');
        }

        return wrapperClasses;
    }

    /**
     * Get widget header style according to widget properties.
     *
     * @return {Object} The widget header style.
     */
    function getWidgetHeaderStyle() {
        const mainStyle = { ...widgetHeader.parentCtrl.widget?.properties?.style?.header?.main };

        const parentStyle = widgetHeader.parentCtrl.widget?.properties?.style?.main;
        const mainDisplay = mainStyle?.display || 'block';
        const isFolded = _widgetElement.hasClass('widget--is-folded');
        const isStandalone = isFolded && !_widgetElement.hasClass('widget--has-footer');
        if (mainDisplay === 'block') {
            if (parentStyle?.borderTopRightRadius) {
                mainStyle.borderTopRightRadius = parentStyle.borderTopRightRadius;
            }
            if (parentStyle?.borderTopLeftRadius) {
                mainStyle.borderTopLeftRadius = parentStyle.borderTopLeftRadius;
            }
            if (isStandalone) {
                if (parentStyle?.borderBottomRightRadius) {
                    mainStyle.borderBottomRightRadius = parentStyle.borderBottomRightRadius;
                }
                if (parentStyle?.borderBottomLeftRadius) {
                    mainStyle.borderBottomLeftRadius = parentStyle.borderBottomLeftRadius;
                }
            }
        }

        return Style.adjustShadow(Media.adjustBackgroundImage(mainStyle));
    }

    /**
     * Get widget header wrapper style according to widget properties.
     *
     * @return {Object} The widget header wrapper style.
     */
    function getWidgetHeaderWrapperStyle() {
        const wrapperStyle = get(widgetHeader.parentCtrl.widget, 'properties.style.header.wrapper', {});

        return Style.adjustShadow(Media.adjustBackgroundImage(wrapperStyle));
    }

    /**
     * Get widget header icon style according to widget properties.
     *
     * @return {Object} The widget header icon style.
     */
    function getWidgetHeaderIconStyle() {
        return Media.adjustBackgroundImage(get(widgetHeader.parentCtrl.widget, 'properties.style.header.icon', {}));
    }

    /**
     * Get widget header label style according to widget properties.
     *
     * @return {Object} The widget header label style.
     */
    function getWidgetHeaderLabelStyle() {
        return Media.adjustBackgroundImage(get(widgetHeader.parentCtrl.widget, 'properties.style.header.label', {}));
    }

    /**
     * Toggle widget content.
     */
    function toggleWidgetContent() {
        const isCollapsible = get(widgetHeader.parentCtrl.widget, 'properties.isCollapsible');
        if (!_widgetElement || (angular.isDefined(isCollapsible) && !isCollapsible)) {
            return;
        }

        _widgetElement.toggleClass('widget--is-folded');
        $scope.$emit('widget-resized');
    }

    /////////////////////////////

    widgetHeader.getWidgetHeaderStyle = getWidgetHeaderStyle;
    widgetHeader.getWidgetHeaderWrapperStyle = getWidgetHeaderWrapperStyle;
    widgetHeader.getWidgetHeaderIconStyle = getWidgetHeaderIconStyle;
    widgetHeader.getWidgetHeaderLabelStyle = getWidgetHeaderLabelStyle;
    widgetHeader.getWrapperClasses = getWrapperClasses;
    widgetHeader.toggleWidgetContent = toggleWidgetContent;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        $timeout(function initHeaderState() {
            _widgetElement = $element.closest('.widget--has-header');
        });
    }

    init();

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        widgetHeader.parentCtrl = parentCtrl;
    };

    /**
     * Check if directive has transcluded content.
     */
    $transclude(function hasTrancluded(clone) {
        // eslint-disable-next-line lumapps/angular-isdefined
        if (clone.length > 0) {
            widgetHeader.hasTranscluded = true;
        }
    });
}

/////////////////////////////

function WidgetHeaderDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetHeaderController,
        controllerAs: 'widgetHeader',
        link,
        replace: true,
        require: ['widgetHeader', '^widget'],
        restrict: 'E',
        templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-header.html',
        transclude: true,
    };
}

/////////////////////////////

/* eslint-disable angular/directive-name */
angular.module('Directives').directive('widgetHeader', WidgetHeaderDirective);

/////////////////////////////

export { WidgetHeaderController, WidgetHeaderDirective };
