import { hexToRGBA } from '@lumapps/utils/color';

function ChartService(Utils) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default opacity for a chart item.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_OPACITY = 0.7;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     *  Global chart config.
     *
     * @type {Object}
     */
    service.chartOptions = {
        /* eslint-disable no-magic-numbers */
        animation: {
            animateRotate: true,
            animateScale: false,
            currentStep: 50,
            duration: 750,
            easing: 'easeOutQuad',
            numSteps: 50,
        },
        borderWidth: 10,
        defaultColor: '#fff',
        legend: {
            display: false,
        },
        maintainAspectRatio: true,
        percentageInnerCutout: 50,
        responsive: true,
        scaleFontColor: 'rgba(0,0,0,0.54)',
        scaleFontFamily: "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        scaleFontSize: 12,
        scaleFontStyle: 'normal',
        scaleLineColor: 'rgba(0,0,0,0.38)',
        segmentShowStroke: true,
        segmentStrokeColor: '#fff',
        segmentStrokeWidth: 8,
        tooltips: {
            backgroundColor: '#616161',
            bodyAlign: 'top',
            bodySpacing: 2,
            callbacks: {
                label: function chartTooltipInit(tooltipItem, tooltipData) {
                    const value = tooltipData.datasets[0].tooltipData[tooltipItem.index];
                    const label = tooltipData.labels[tooltipItem.index];

                    return `${label} ${parseFloat(value)}`;
                },
            },
            caretSize: 0,
            cornerRadius: 0,
            footerFontSize: 10,
            mode: 'single',
            titleColor: '#fff',
            titleFontFamily: "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            titleFontSize: 10,
            titleFontStyle: 'normal',
            titleSpacing: 0,
        },
        /* eslint-enable no-magic-numbers */
    };

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Returns as many colors there are chart items.
     *
     * @param  {number} itemsLen      How many items we have.
     * @param  {number} [opacity=0.7] Color opacity.
     * @return {Object} background-color and hover background arrays.
     */
    function getChartItemsColors(itemsLen, opacity) {
        opacity = angular.isNumber(opacity) ? opacity : _DEFAULT_OPACITY;

        const colors = {
            backgroundColor: [],
            hoverBackgroundColor: [],
        };

        for (let i = 0; i < itemsLen; ++i) {
            const itemColor = Utils.getItemColor(i);
            // Background-color.
            colors.backgroundColor.push(itemColor);
            // Background-color hover.
            colors.hoverBackgroundColor.push(service.getHoverColor(itemColor, opacity));
        }

        return colors;
    }

    /**
     * Return hover color from color.
     *
     * @param  {string} color     Hexadecimal color.
     * @param  {number} [opacity] Opacity value.
     * @return {string} hoverColor in RGBa.
     */
    function getHoverColor(color, opacity) {
        if (angular.isUndefinedOrEmpty(color)) {
            return undefined;
        }

        return hexToRGBA(color, opacity);
    }

    /**
     * Return Pie/Douhgnut chart data Object.
     *
     * @param  {Array}  chartValues                 An array of number.
     * @param  {Array}  chartLabels                 An array of string.
     * @param  {Array}  [itemBackgroundColor=]      An array of colors.
     * @param  {Array}  [itemHoverBackgroundColor=] An array of colors.
     * @return {Object} Chart data (colors, values, labels).
     *
     */
    function getPieData(chartValues, chartLabels, itemBackgroundColor, itemHoverBackgroundColor) {
        if (
            angular.isUndefinedOrEmpty(chartValues) ||
            angular.isUndefinedOrEmpty(chartLabels) ||
            chartLabels.length !== chartValues.length
        ) {
            return undefined;
        }

        const nbChartItems = chartValues.length;

        // Check if we have enough colors, if not use default colors.
        if (
            angular.isUndefinedOrEmpty(itemBackgroundColor) ||
            itemBackgroundColor.length !== nbChartItems ||
            itemBackgroundColor.length !== itemHoverBackgroundColor.length
        ) {
            const colors = service.getChartItemsColors(nbChartItems);
            itemBackgroundColor = colors.backgroundColor;
            itemHoverBackgroundColor = colors.hoverBackgroundColor;
        }

        return {
            datasets: [
                {
                    backgroundColor: itemBackgroundColor,
                    // eslint-disable-next-line id-blacklist
                    data: chartValues,
                    hoverBackgroundColor: itemHoverBackgroundColor,
                    hoverBorderWidth: 0,
                },
            ],
            labels: chartLabels,
        };
    }

    /////////////////////////////

    service.getChartItemsColors = getChartItemsColors;
    service.getHoverColor = getHoverColor;
    service.getPieData = getPieData;
}

/////////////////////////////

angular.module('Services').service('Charts', ChartService);

/////////////////////////////

export { ChartService };