(function IIFE() {
    'use strict';

    /////////////////////////////

    function SpammerDetailsController(Community, Post) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The list-key attached to the current spammer.
         *
         * @type {string}
         * @constant
         */
        var _SPAMMER_DETAILS_LIST_KEY = 'spammer-details-';

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The last time the spammer has been reported.
         *
         * @type {string}
         */
        vm.lastReportDate = undefined;

        /**
         * The number of times the spammer has been reported.
         *
         * @type {number}
         */
        vm.nbReported = 0;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Computes the needed information about the spammer.
         *
         * @param {Object} items The report list request items.
         */
        function _computeSpammerDetails(items) {
            if (angular.isUndefinedOrEmpty(items)) {
                return;
            }

            var spammerInfo = _.get(_.first(items), 'spammerInfo', {});
            vm.nbReported = spammerInfo.nbReported;
            vm.hasReports = vm.nbReported > 0;
            vm.lastReportDate = moment.utc(spammerInfo.lastReportDate).local().fromNow();

            vm.spammer.spammerInfo = spammerInfo;
        }

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentCommunity = Community.getCurrent();

            if (angular.isUndefinedOrEmpty(currentCommunity)) {
                return;
            }

            var spammerId = vm.spammer.uid;

            _SPAMMER_DETAILS_LIST_KEY += spammerId;

            // To see request specifications: https://github.com/lumapps/lumsites/wiki/API-Specification-Community-report-inappropriate-post#list-report.
            var params = {
                communityId: currentCommunity.uid,
                maxResults: 1,
                spammerId: spammerId,
            };

            Post.getReportList(params, Post.PROJECTION.reportSpammer, _SPAMMER_DETAILS_LIST_KEY)
                .then(_computeSpammerDetails);
        }

        init();
    }

    /**
     * Displays informations about a spammer history.
     *
     * @param {object}  spammer                     The concerned spammer we want to display the spam history of.
     * @param {boolean} isFetchingModerationDetails Whether the directive is fetching moderation details or not.
     */

    function SpammerDetailsDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: SpammerDetailsController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                hasReports: '=lsHasReports',
                spammer: '=lsSpammer',
            },
            templateUrl: '/client/common/modules/spammer-details/views/spammer-details.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsSpammerDetails', SpammerDetailsDirective);
})();
