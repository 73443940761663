/**
 * Add the text in the clipboard.
 *
 * @param textToCopy The text to copy to the clipboard.
 */
export const copyText = (textToCopy: string) => {
    const copyingTextArea = document.createElement('textarea');
    copyingTextArea.value = textToCopy;

    document.body.appendChild(copyingTextArea);

    copyingTextArea.select();
    document.execCommand('copy');

    document.body.removeChild(copyingTextArea);
};
