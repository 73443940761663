(function IIFE() {
    'use strict';

    /////////////////////////////

    function ReportPostDialogController($rootScope, LxDialogService, Post) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Whether a report call is in progress or not.
         *
         * @type {boolean}
         */
        vm.isReporting = false;

        /**
         * Defines if the report has been sent or not.
         *
         * @type {boolean}
         */
        vm.isReportSent = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Post = Post;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Sends the post report.
         */
        function sendReport() {
            vm.isReporting = true;
            Post.reportPost(vm.post, function onReportSuccess(response) {
                if (vm.post.uid !== response.postId) {
                    return;
                }

                vm.post.alreadyReported = true;
                vm.post.reportStatus = Post.REPORT_STATUSES.REPORTED;
                vm.post.report = response.report || [];
                vm.isReportSent = true;

                $rootScope.$broadcast('post-reported', vm.post);
            }, function onReportError() {
                vm.isReporting = false;
                LxDialogService.close(vm.dialogId);
            });
        }

        /////////////////////////////

        vm.sendReport = sendReport;
    }

    /**
     * A dialog to handle post reporting.
     *
     * @param {string} dialogId The identifier of the dialog.
     * @param {Post}   post     The post to report.
     */

    function ReportPostDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ReportPostDialogController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                dialogId: '@lsDialogId',
                post: '<lsPost',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/communities/post/views/partials/report-post-dialog.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsReportPostDialog', ReportPostDialogDirective);
})();
