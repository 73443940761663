import { Editor, Path, Transforms } from '@lumapps/wrex/slate';

import { isMention } from './isMention';

/**
 * Removing user mention keeping only the text inside.
 */
export function unwrapMention(editor: Editor, at: Path) {
    Transforms.unwrapNodes(editor, { at, match: isMention, voids: true });
}
