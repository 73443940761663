import { shape, number, bool, string } from 'prop-types';

import { widgetType } from '../types';

/** The prop types definition for the widget channel list */
export const widgetChannelListType = {
    ...widgetType,
    component: shape({
        ...widgetType.component,
        properties: shape({
            ...widgetType.component.properties,
            limitConversation: bool,
            numberConversations: number,
        }),
    }),
};

/** The prop types definition for a channel */
export const channelType = shape({
    id: string.isRequired,
    name: string.isRequired,
    provider: string,
});

/** The prop types definition for a conversation */
export const conversationType = shape({
    channelUrl: string,
    id: string.isRequired,
    name: string.isRequired,
    provider: string,
});
