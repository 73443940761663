export enum WIDGET_ARTICLE_LIST {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277417599
     */
    ARTICLE_ACTIONS = 'WIDGET_ARTICLE_LIST.ARTICLE_ACTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277431034
     */
    DISPLAY_ARTICLE = 'WIDGET_ARTICLE_LIST.DISPLAY_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277431402
     */
    COPY_ARTICLE_LINK = 'WIDGET_ARTICLE_LIST.COPY_ARTICLE_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=282905338
     */
    CREATE_ARTICLE = 'WIDGET_ARTICLE_LIST.CREATE_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=282887125
     */
    EMPTY_STATE_DESCRIPTION = 'WIDGET_ARTICLE_LIST.EMPTY_STATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290071029
     */
    EMPTY_STATE_SEARCH = 'WIDGET_ARTICLE_LIST.EMPTY_STATE_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=282886992
     */
    EMPTY_STATE_TITLE = 'WIDGET_ARTICLE_LIST.EMPTY_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=287164141
     */
    SEARCH_BY_TEXT = 'WIDGET_ARTICLE_LIST.SEARCH_BY_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422631252
     */
    SETTINGS_FOLLOWED_COMMUNITIES_FILTER_HELPER = 'WIDGET_ARTICLE_LIST.SETTINGS_FOLLOWED_COMMUNITIES_FILTER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=359928914
     */
    SETTINGS_SHOW_LOAD_MORE_HELPER = 'WIDGET_ARTICLE_LIST.SETTINGS_SHOW_LOAD_MORE_HELPER',
}
