import hast from 'hast';
import flatMap from 'lodash/flatMap';
import is from 'unist-util-is';

import { HTMLPasteOptions } from '@lumapps/wrex-html-paste/types';

import type { Wrex } from '../../../types';
import { HTMLConvertOptions, Marks } from './types';
import { isParent, isRoot, isText, isValidHTMLTextParent } from './utils';

type SlateType = 'root' | 'element';

/**
 * Recursively transform HAST node into slate node(s).
 *
 * @param options          HAST node convert options.
 * @param node             HAST node to convert.
 * @param index            HAST node index in parent.
 * @param parent           Parent HAST node.
 * @param activeMarks      Active marks in the context.
 * @param parentSlateType  The type of the parent slate node converted.
 */
export function transform(
    options: HTMLPasteOptions,
    node: hast.Node,
    index?: number,
    parent?: hast.Parent,
    activeMarks?: Marks,
    parentSlateType?: SlateType,
    givenParentWithChildAttributes?: hast.Node,
): Wrex.Nodes {
    const hasValidParent = !!parentSlateType || isValidHTMLTextParent(parent);

    // If text node (& has a slate parent).
    if (isText(node) && hasValidParent) {
        return [{ text: node.value, ...activeMarks }];
    }

    // If soft break (& has a slate parent).
    if (is(node, { tagName: 'br' }) && hasValidParent) {
        return [{ text: '\n' }];
    }

    const newActiveMarks: Marks = { ...activeMarks };

    // If mark node.
    const markOptions = options.marks.filter(({ test }) => is(node, test, index, parent));
    for (const markOption of markOptions) {
        newActiveMarks[markOption.mark] = markOption.value ? markOption.value(node.properties, options) : true;
    }

    const elementOption = options.elements.find(({ test }) => is(node, test, index, parent));
    let slateType: SlateType | undefined;
    if (markOptions.length > 0) {
        slateType = parentSlateType;
    } else if (elementOption) {
        slateType = 'element';
    } else if (isRoot(node)) {
        slateType = 'root';
    }

    // Find parent node with specific attributes
    const parentWithChildAttributes =
        isParent(node) &&
        options.elements.find(({ testParent }) => (testParent ? is(node, testParent, index, parent) : false))
            ? node
            : undefined;

    // Transform node children into slate nodes.
    const slateChildren = isParent(node)
        ? flatMap(node.children, (child, i) =>
              transform(
                  options,
                  child,
                  i,
                  node,
                  newActiveMarks,
                  slateType,
                  parentWithChildAttributes || givenParentWithChildAttributes,
              ),
          )
        : [];

    // If element node (the current node) is validating the test() from one of the options.elements
    if (elementOption) {
        // Get specific attributes from tested parent node
        const parentNodeAttributes =
            givenParentWithChildAttributes && elementOption.getAttributesFromParent
                ? elementOption.getAttributesFromParent(givenParentWithChildAttributes)
                : undefined;

        const transformedNode = elementOption.transform
            ? elementOption.transform(
                  { ...(node as hast.Element), attributes: parentNodeAttributes },
                  slateChildren,
                  {
                      // Check if alignment is allowed
                      alignmentAllowed: options.allowedAlignments.includes(
                          elementOption.type as HTMLConvertOptions['allowedAlignments'][0],
                      ),
                  },
                  options,
              )
            : { type: elementOption.type, children: slateChildren };
        return [transformedNode];
    }

    return slateChildren;
}

/**
 * Unified compiler processor.
 */
export function rehype2slate(this: any, options: HTMLPasteOptions) {
    this.Compiler = (tree: hast.Root) => {
        return transform(options, tree);
    };
}
