import React from 'react';

import { Action, ToolbarAction } from '@lumapps/lumx-front-office/components/ContextualActions';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

interface CustomizationContextualActionProps extends Omit<Action, 'iconSvgPath'> {
    icon: string;
}

/**
 * Component that wraps the Contextual Action element so that it retrieves the icons from our CDN. If we do not
 * do this, we would need to include in the JS bundle all the icons that are available in DS, and
 * that would have a heavy toll in our performance. In order to avoid add, we allow the customizations
 * API to use buttons but specify which icons they want to use as strings rather that importing the icon
 * directly.
 * @param props - CustomizationContextualActionProps
 */
export const CustomizationContextualAction: React.FC<CustomizationContextualActionProps> = (props) => {
    const { icon, ...otherProps } = props;

    const { iconPath } = useDynamicIcon({ icon: icon || '' });

    return <ToolbarAction {...otherProps} iconSvgPath={iconPath} />;
};

CustomizationContextualAction.displayName = 'ContextualAction';
