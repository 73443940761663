import partial from 'lodash/partial';

import { Editor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { WrexEditor } from '@lumapps/wrex/types';

import { ListItem } from '../components/blocks/ListItem';
import { OrderedList } from '../components/blocks/OrderedList';
import { UnorderedList } from '../components/blocks/UnorderedList';
import { LIST_FEATURES } from '../constants';
import { ListEditor, ListEditorOptions } from '../types';
import { isList } from '../utils/isList';
import { getKeyHandler } from './event/keyboard';
import { normalizeListNodes } from './normalize';
import { decreaseListIndent } from './transforms/decreaseListIndent';
import { increaseListIndent } from './transforms/increaseListIndent';
import { setListType } from './transforms/setListType';

function isListActive(editor: Editor, type: string) {
    return findParent(editor, editor.selection?.focus.path, isList)?.[0]?.type === type;
}

const getEnabledElements = (enabledFeatures: LIST_FEATURES[]) => {
    const enabledListFeatures = [UnorderedList, OrderedList].filter((element) =>
        enabledFeatures.includes(element.displayName as LIST_FEATURES),
    );

    return [...enabledListFeatures, ListItem];
};

const createPluginEditor =
    (options: ListEditorOptions): CreatePluginEditor<ListEditor, WrexEditor<ListEditor>> =>
    (editor) => {
        const { normalizeNode } = editor;

        return {
            increaseListIndent: partial(increaseListIndent, editor),
            decreaseListIndent: partial(decreaseListIndent, editor),
            normalizeNode: partial(normalizeListNodes, editor, normalizeNode),
            isListActive: partial(isListActive, editor),
            setListType: partial(setListType, editor, options.defaultBlockType),
            isListFeatureEnabled: (feature: LIST_FEATURES) => {
                return options.enabledFeatures.includes(feature);
            },
        };
    };

/**
 * A list plugin capable of handling (possibly nested) ordered and unordered in slate.
 *
 * @param defaultBlockType Default block type to use when removing a list item.
 */
export const withList = (options: ListEditorOptions) =>
    createPlugin({
        createPluginEditor: createPluginEditor(options),
        getKeyHandler,
        elements: getEnabledElements(options.enabledFeatures),
    });
