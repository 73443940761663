import { CACHE_MAX_DURATION } from '../constant';
import { QuickSearchResultsQueryParams } from '../types';
import {
    storeInteractionsSuggestions,
    storeSearchSuggestions,
    getSearchSuggestions,
    getInteractionsSuggestions,
} from '../utils/cacheUtils';
import { entitiesToSuggestions } from '../utils/entitiesToSuggestions';
import { queriesToSuggestions } from '../utils/queriesToSuggestions';
import { fetchAll } from './index';

export const fetchAndStoreQuickSearchItems = async (params: QuickSearchResultsQueryParams) => {
    try {
        const { siteId } = params;
        const suggestions = getSearchSuggestions(siteId);
        const { ttl } = getInteractionsSuggestions(siteId);
        const invalidCache =
            suggestions.length === 0 || ttl === undefined || Date.now() - Number(ttl) > CACHE_MAX_DURATION;
        /**
         * If we have something stored in the storage we don't need to fetch data from the server
         * We can't use the cache system from the BaseAPI since we are managing the storage in the front end in another place
         * cache data won't be alligned
         */
        if (invalidCache) {
            const res = await fetchAll(params);
            const newTtl = Date.now().toString();
            storeInteractionsSuggestions(entitiesToSuggestions(res.entities), siteId, newTtl);
            storeSearchSuggestions(queriesToSuggestions(res.queries), siteId);
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
