import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { TAGZ_CONTEXT_NAME } from '@lumapps/folksonomy/constants';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiPoundBox } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { FOLKSONOMY_ADMIN } from '../../keys';
import { admin } from '../../routes';

export interface TagzSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const TagzSideNavigation: React.FC<Partial<TagzSideNavigationProps>> = ({ isAllowed, ...restOfProps }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(TAGZ_CONTEXT_NAME);

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={admin()}
            label={translateKey(FOLKSONOMY_ADMIN.TITLE)}
            icon={mdiPoundBox}
            {...restOfProps}
            {...get({ element: 'side-navigation' })}
        />
    );
};

TagzSideNavigation.displayName = 'SideNavigationItem';

export { TagzSideNavigation };
