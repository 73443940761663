import React, { PureComponent } from 'react';
import { func, object, shape, string } from 'prop-types';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { AccordionBox } from '../ui/accordions';
import { Button } from '../ui';
import { InputTextField } from '../ui/inputs';

import { translate as t } from '../../translations';

/**
 * Display a custom field and its details.
 */
class CustomField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {};

        this.removeItem = this.removeItem.bind(this);
        this.updateLocalizedName = this.updateLocalizedName.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state = {}) {
        const current = get(nextProps, ['item', 'localizedName', nextProps.inputLanguage]);

        if (current && isEqual(current, state.localizedName)) {
            return null;
        }

        return {
            localizedName: current,
        };
    }

    /**
     * Update the current custom field localizedName for the current inputLanguage.
     *
     * @param {string} newValue The new localized name for the custom field.
     */
    updateLocalizedName(newValue) {
        const { inputLanguage, item, onChange } = this.props;

        this.setState(
            {
                localizedName: newValue,
            },
            () => {
                const newLocalizedName = {
                    ...item.localizedName,
                    [inputLanguage]: newValue,
                };

                onChange({
                    changes: {
                        localizedName: newLocalizedName,
                    },
                    customField: item,
                });
            },
        );
    }

    /**
     * Trigger the removal of the item from the custom field list.
     */
    removeItem() {
        this.props.onRemove(this.props.item);
    }

    render() {
        const { dragHandleProps, item } = this.props;
        const { localizedName } = this.state;

        return (
            <AccordionBox color="secondary" dragHandleProps={dragHandleProps} label={`${localizedName || item.name}`}>
                <InputTextField
                    label={t('GLOBAL.LABEL')}
                    helpText={t('ADMIN.CUSTOM_FIELD.LABEL_HELP')}
                    onChange={this.updateLocalizedName}
                    type="string"
                    value={localizedName}
                />

                <Button className="mt++" color="red" size="m" variant="flat" onClick={this.removeItem}>
                    {t('GLOBAL.REMOVE')}
                </Button>
            </AccordionBox>
        );
    }
}

/////////////////////////////

CustomField.propTypes = {
    /** Props for the handle in case the custom field can be dragged and dropped. */
    dragHandleProps: shape(),
    /** The current input language of the custom field. */
    inputLanguage: string.isRequired,
    /** A custom field object to be displayed. */
    item: object.isRequired,
    /** Function called when the custom field is changed. Useful to set the form it belongs to as dirty for ex. */
    onChange: func.isRequired,
    /** Function called when the custom field is removed from the list it belongs to. */
    onRemove: func.isRequired,
};

/////////////////////////////

export { CustomField };
