import React from 'react';

import { any, func, string, arrayOf } from 'prop-types';

import TaskItem from '../taskItem/TaskItem';

/**
 * Display the uncheckedItems section of the MyTasks component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The uncheckedItems section react element of the MyTasks component.
 */
const UncheckedItems = ({ todoItems, checkItem, removeTodo, uuid }) => {
    return (
        <div className="widget-todo__unchecked-items">
            <div className="widget-todo__task">
                {todoItems
                    .filter((item) => !item.isChecked)
                    .map((task) => {
                        return (
                            <TaskItem
                                key={task.id}
                                checkItem={checkItem}
                                removeTodo={removeTodo}
                                task={task}
                                uuid={uuid}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

UncheckedItems.propTypes = {
    checkItem: func.isRequired,
    removeTodo: func.isRequired,
    todoItems: arrayOf(any).isRequired,
    uuid: string.isRequired,
};

export default UncheckedItems;
