import React, { ReactNode } from 'react';

import { useApi } from './api';
import { CustomizationsContext } from './context';
import { MODES, MODES_DESCRIPTIONS, getEnabledModes, DISABLE_MODE_CHAR, useModes } from './modes';
import { useTappers } from './tappers';
import { useInjectTapper } from './tappers/useInjectTapper';
import { useCustomizations } from './useCustomizations';

interface CustomizationsProviderProps {
    children: ReactNode;
}

const CustomizationsProvider: React.FC<CustomizationsProviderProps> = ({ children }) => {
    const { modes, isModeEnabled, featureFlags } = useModes();
    useTappers();
    useApi();

    const contextValue = React.useMemo(() => {
        return {
            modes,
            isModeEnabled,
            featureFlags,
        };
    }, [featureFlags, isModeEnabled, modes]);

    return <CustomizationsContext.Provider value={contextValue}>{children}</CustomizationsContext.Provider>;
};

export {
    CustomizationsProvider as Customizations,
    useCustomizations,
    MODES,
    MODES_DESCRIPTIONS,
    DISABLE_MODE_CHAR,
    getEnabledModes,
    useInjectTapper,
};
