(function IIFE() {
    'use strict';

    /////////////////////////////

    function CalendarRequestFactoryService($q) {
        'ngInject';

        /**
         * Constructor for RequestFactory.
         *
         * @param {Function} sort Method used to sort the merged lists.
         * @param {Function} cb   Callback method executed after requests resolv/reject.
         * @constructor
         */
        function RequestFactory(sort, cb) {
            /**
             * Determine if a request is still running.
             *
             * @type {boolean}
             */
            this._isCallInProgress = false;

            /**
             * Total amount of results retrieved from the server.
             *
             * @type {number}
             */
            this.fetchedResults = 0;

            /**
             * Total amount of results returned to the client.
             *
             * @type {number}
             */
            this.maxResults = 0;

            /**
             * List of pending requests.
             *
             * @type {Array}
             */
            this.requests = [];

            this.sort = sort;
            this.cb = cb;
        }

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Increment total amount of results retrieved from the server.
         *
         * @param {Array} results Request results.
         */
        RequestFactory.prototype._addFetchedResults = function _addFetchedResults(results) {
            this.fetchedResults += ((angular.isArray(results)) ? results.length : 0);
        };

        /**
         * Get a promise for each request still racing.
         *
         * @return {Array} List of promises of pending requests.
         */
        RequestFactory.prototype._getPendingRequests = function _getPendingRequests() {
            var promises = [];

            angular.forEach(this.requests, function forEachRequest(request) {
                if (_.get(request, 'params.more', false)) {
                    promises.push(request.method(request.params).$promise);
                }
            });

            return promises;
        };

        /**
         * Create a sorted list of results to send to the client.
         *
         * @return {Object} Object containing the list of events and a boolean telling if there is more results.
         */
        RequestFactory.prototype._getResultsSubset = function _getResultsSubset() {
            var results = this.requests.reduce(function reduceResults(currentBulk, request) {
                // Note: Union is to prevent events duplication (eg. from different calendars).
                currentBulk.events = _.union(currentBulk.events, request.items);
                currentBulk.more = currentBulk.more || request.params.more;

                return currentBulk;
            }, {
                events: [],
                more: false,
            });

            results.events = _.sortBy(_.compact(results.events), this.sort)
                .slice(0, this.maxResults);

            return results;
        };

        /**
         * Process Results by storing values and send a bunch of them to the client.
         *
         * @param {Object} results Results of requests.
         */
        RequestFactory.prototype._processResults = function _processResults(results) {
            var resultsSubset;

            this._setCallInProgress(false);
            this._storeFulfilledResults(results);

            resultsSubset = this._getResultsSubset();
            this.cb(resultsSubset.events, resultsSubset.more);
        };

        /**
         * Store fulfilled results.
         * Note that we do not throw/log/handle request errors.
         *
         * @param {Object} results Results of executed requests.
         */
        RequestFactory.prototype._storeFulfilledResults = function _storeFulfilledResults(results) {
            var that = this;

            angular.forEach(results, function storeRequestStates(request, i) {
                if (request.state !== 'fulfilled') {
                    return;
                }
                that._storeRequestStates(request.value, i);
                that._addFetchedResults(request.value.items);
            });
        };

        /**
         * Store request states.
         *
         * @param {Object} results Results of this request.
         * @param {number} i       Results position in requests array.
         */
        RequestFactory.prototype._storeRequestStates = function _storeRequestStates(results, i) {
            this.requests[i].params.cursor = results.cursor;
            this.requests[i].params.more = results.more;
            if (results.items) {
                this.requests[i].items = this.requests[i].items.concat(results.items);
            }
        };

        /**
         * Sets the "call in progress" status.
         *
         * @param {boolean} inProgress Is call in progress?
         */
        RequestFactory.prototype._setCallInProgress = function _setCallInProgress(inProgress) {
            this._isCallInProgress = inProgress;
        };

        /**
         * Increment total amount of results to return to the client.
         */
        RequestFactory.prototype._setMaxResults = function _setMaxResults() {
            this.maxResults += this.requests[this.requests.length - 1].params.maxResults;
        };

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a request.
         *
         * @param {Function} method Method used to fetch data.
         * @param {Object}   params Params for the method.
         */
        RequestFactory.prototype.add = function addRequest(method, params) {
            if (angular.isUndefinedOrEmpty([method, params], 'some')) {
                return;
            }
            if (angular.isUndefinedOrEmpty(params.maxResults) || params.maxResults <= 0) {
                params.maxResults = 30;
            }

            this.requests.push({
                items: [],
                method: method,
                params: angular.extend({
                    cursor: undefined,
                    maxResults: 30,
                    more: true,
                }, params),
            });
        };

        /**
         * Run requests and execute callback when all are resolved or rejected.
         */
        RequestFactory.prototype.exec = function execRequests() {
            // Note: Doesn't run nor put in queue requests if one is already in progress.
            if (this._isCallInProgress || this.requests.length === 0) {
                return;
            }
            this._setCallInProgress(true);

            var promises = this._getPendingRequests();
            this._setMaxResults();

            $q.allSettled(promises)
                .then(this._processResults.bind(this));
        };

        /**
         * Whether the requests has more pending results.
         *
         * @return {boolean} Are their pending results.
         */
        RequestFactory.prototype.hasMore = function checkHasMore() {
            if (this.fetchedResults > this.maxResults) {
                return true;
            }

            for (var i in this.requests) {
                if (this.requests[i].params.more) {
                    return true;
                }
            }

            return false;
        };

        /**
         * Whether the requests are still running.
         *
         * @return {boolean} Is their request in progress.
         */
        RequestFactory.prototype.isCallInProgress = function getCallInProgess() {
            return this._isCallInProgress;
        };

        return RequestFactory;
    }

    /////////////////////////////

    angular.module('Factories').factory('CalendarRequestFactory', CalendarRequestFactoryService);
})();
