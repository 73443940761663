export enum EventTypes {
    ChaptersChange = 'player:chapters:change',
    Dispose = 'player:dispose',
    Ended = 'player:ended',
    GetCurrentTime = 'player:get:currentTime',
    GetDuration = 'player:get:duration',
    GetSnapshot = 'player:get:snapshot',
    LoadedMetadata = 'player:loadedmetadata',
    Pause = 'player:pause',
    Play = 'player:play',
    Ready = 'player:ready',
    Seeked = 'player:seeked',
    SetCurrentTime = 'player:set:currentTime',
}

type Event<Type extends EventTypes, Data extends Record<string, unknown> | undefined = undefined> = Data extends Record<
    string,
    unknown
>
    ? { type: Type; data: Data }
    : Data extends undefined
    ? { type: Type }
    : never;

/**
 * Receiving Events
 */
type OnChapterChangeEvent = Event<
    EventTypes.ChaptersChange,
    {
        oldChapter?: {
            startTime: number;
            endTime: number;
        };
        newChapter: {
            startTime: number;
            endTime: number;
        };
    }
>;

type OnDisposeEvent = Event<EventTypes.Dispose>;

type OnEnded = Event<EventTypes.Ended>;

type OnGetCurrentTimeEvent = Event<EventTypes.GetCurrentTime, { currentTime: number }>;

type OnGetDurationEvent = Event<EventTypes.GetDuration, { duration: number }>;

type OnLoadedMetadata = Event<EventTypes.LoadedMetadata>;

type OnPauseEvent = Event<EventTypes.Pause>;

type OnPlayEvent = Event<EventTypes.Play>;

type OnReadyEvent = Event<EventTypes.Ready>;

type OnSeekedEvent = Event<EventTypes.Seeked>;

type OnSnapshotEvent = Event<EventTypes.GetSnapshot, { snapshot: Blob }>;

export type OnPlayerEvent =
    | OnChapterChangeEvent
    | OnDisposeEvent
    | OnEnded
    | OnGetCurrentTimeEvent
    | OnGetDurationEvent
    | OnLoadedMetadata
    | OnPauseEvent
    | OnPlayEvent
    | OnReadyEvent
    | OnSeekedEvent
    | OnSnapshotEvent;

/**
 * Sending Events
 */
export type GetCurrentTimeEvent = Event<EventTypes.GetCurrentTime>;

export type GetDurationEvent = Event<EventTypes.GetDuration>;

export type PauseEvent = Event<EventTypes.Pause>;

export type PlayEvent = Event<EventTypes.Play>;

export type SetCurrentTimeEvent = Event<EventTypes.SetCurrentTime, { currentTime: number }>;

export type SnapshotEvent = Event<EventTypes.GetSnapshot>;

export type PlayerEvent =
    | GetCurrentTimeEvent
    | OnGetDurationEvent
    | PauseEvent
    | PlayEvent
    | SetCurrentTimeEvent
    | SnapshotEvent;
