(function IIFE() {
    'use strict';

    /////////////////////////////

    function AccordionBoxController($q, $element, $scope) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the accordion is currently in a opening phase.
         *
         * @type {Promise}
         */
        var _openingState;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close the accordion, set the closed flag to true.
         */
        function closeAccordion() {
            $q.when(_openingState).then(function onOpeningFinished() {
                vm.closed = true;

                $element.find('.accordion-box-content').velocity('slideUp', {
                    complete: function onCloseComplete() {
                        if (angular.isFunction(vm.toggleCallback)) {
                            vm.toggleCallback();
                        }

                        if (angular.isFunction(vm.closeCallback)) {
                            vm.closeCallback();
                        }
                    },
                    duration: 400,
                    easing: 'easeOutQuint',
                });
            });
        }

        /**
         * Open the accordion, set the closed flag to false.
         */
        function openAccordion() {
            _openingState = $q.defer();
            vm.closed = false;

            $element.find('.accordion-box-content').velocity('slideDown', {
                complete: function onOpenComplete() {
                    _openingState.resolve();
                    _openingState = undefined;

                    if (angular.isFunction(vm.toggleCallback)) {
                        vm.toggleCallback();
                    }

                    if (angular.isFunction(vm.openCallback)) {
                        vm.openCallback();
                    }
                },
                duration: 400,
                easing: 'easeOutQuint',
            });
        }

        /**
         * Switch the current state of the accordion box.
         * This state might be set externaly, that's why we have to keep the watcher.
         */
        function toggle() {
            vm.closed = !vm.closed;
        }

        /////////////////////////////

        vm.closeAccordion = closeAccordion;
        vm.openAccordion = openAccordion;
        vm.toggle = toggle;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch for any change in the opened state.
         *
         * @param {boolean} isOpened  The new value of the opened flag.
         * @param {boolean} wasOpened The previous value of the open flag.
         */
        $scope.$watch(function isAccordionOpenedWatcher() {
            return vm.closed;
        }, function isAccordionOpenedWatch(isClosed, wasClosed) {
            if (isClosed !== wasClosed) {
                if (isClosed) {
                    vm.closeAccordion();
                } else {
                    vm.openAccordion();
                }
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.closed = vm.closed || false;
            vm.isToggleDisabled = vm.isToggleDisabled || false;
            vm.isToggleHidden = vm.isToggleHidden || false;
        }

        init();
    }

    /////////////////////////////

    /**
     * The accordion box.
     *
     * @param {Function} [closeCallback]          A callback function to execute after the accordion is closed.
     * @param {boolean}  [closed=false]           Indicates if the accordion is closed or not.
     * @param {string}   [icon]                   The icon name displayed in the accordion box header.
     * @param {boolean}  [isToggleDisabled=false] Determine if the toggle button is disabled or not.
     * @param {boolean}  [isToggleHidden=false]   Determine if the toggle button is hidden or not.
     * @param {boolean}  [ngDisabled]             Determine if the content is disabled or not.
     * @param {Function} [openCallback]           A callback function to execute after the accordion is open.
     * @param {Function} [toggleCallback]         A callback function to execute when the accordion box is open or closed.
     * @param {string}   [type]                   The type of accordion box.
     *                                            Can be empty (the default value) which means default accordion styles or
     *                                            'card' like in the datasource administration page.
     */

    function accordionBoxDirective() {
        'ngInject';

        function link(scope, el, attrs) {
            if (angular.isDefined(attrs.scrollable)) {
                el.find(attrs.scrollableTarget).css({
                    maxHeight: attrs.scrollableHeight,
                    overflow: 'auto',
                });
            }
        }

        return {
            bindToController: true,
            controller: AccordionBoxController,
            controllerAs: 'accordionBox',
            link: link,
            replace: true,
            restrict: 'E',
            scope: {
                closeCallback: '&?',
                closed: '=?',
                icon: '@?',
                isToggleDisabled: '<?',
                isToggleHidden: '<?',
                ngDisabled: '=?',
                openCallback: '&?',
                toggleCallback: '&?',
                type: '@?',
            },
            templateUrl: '/client/common/modules/accordion-box/views/accordion-box.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('accordionBox', accordionBoxDirective);

    /////////////////////////////

    function AccordionBoxHeaderController() {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The parent controller of the AccordionBoxHeader controller.
         * @type {Object}
         */
        vm.parentCtrl = {};

        /////////////////////////////

        /**
         * Set the parent controller.
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function accordionBoxHeaderDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: AccordionBoxHeaderController,
            controllerAs: 'accordionBoxHeader',
            link: link,
            replace: true,
            require: ['accordionBoxHeader', '^accordionBox'],
            restrict: 'E',
            templateUrl: '/client/common/modules/accordion-box/views/accordion-box-header.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('accordionBoxHeader', accordionBoxHeaderDirective);

    /////////////////////////////

    function AccordionBoxContentController($element) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The parent controller of the AccordionBoxHeader controller.
         * @type {Object}
         */
        vm.parentCtrl = {};

        /////////////////////////////

        /**
         * Set the parent controller.
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            var isHidden = Boolean(vm.parentCtrl.closed);

            if (isHidden) {
                $element.hide();
            }
        };
    }

    /////////////////////////////

    function accordionBoxContentDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: AccordionBoxContentController,
            controllerAs: 'accordionBoxContent',
            link: link,
            replace: true,
            require: ['accordionBoxContent', '^accordionBox'],
            restrict: 'E',
            templateUrl: '/client/common/modules/accordion-box/views/accordion-box-content.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('accordionBoxContent', accordionBoxContentDirective);
})();
