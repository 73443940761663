import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import indexOf from 'lodash/indexOf';
import map from 'lodash/map';

/////////////////////////////

function UserPickerController($scope, $timeout, Config, Feed, Translation, User, UserDirectory, UserPicker, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Original state of the directive's ngModel.
     *
     * @type {Array}
     */
    let _originalNgModel;

    /**
     * The page size of the selected users list.
     *
     * @type {number}
     */
    let _pageSize = 50;

    /**
     * Indicates if we want to pick the ids of the user instead of the full users in the ngModel.
     *
     * @type {boolean}
     */
    let _pickIds = false;

    /**
     * Indicates the beginning of the slice of the selected users list.
     *
     * @type {number}
     */
    let _sliceFrom = 0;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list key of the selected user.
     *
     * @type {string}
     * @readonly
     */
    vm.SELECTION_LIST_KEY = 'user-picker-selection-';

    /**
     * The available values for view mode.
     *
     * @type {Object}
     * @readonly
     */
    vm.VIEW_MODES = {
        library: 'library',
        organize: 'organize',
    };

    /**
     * The various filter properties we can filter users by.
     *
     * @type {Object}
     */
    vm.filter = {};

    /**
     * Indicates if the filter is currently displayed or not.
     *
     * @type {boolean}
     */
    vm.isFilterDisplayed = true;

    /**
     * Indicates if the user picker is open or not.
     *
     * @type {boolean}
     */
    vm.isOpen = false;

    /**
     * Contains the list of user ids to get.
     *
     * @type {Array}
     */
    vm.userIds = [];

    /**
     * The view mode the user picker is currently in.
     * Possible values are:
     *     - 'organize': display the list of selected users and allow to organize them;
     *     - 'library': display the list of users according to the filters and allow the user to select them;
     *
     * @type {string}
     */
    vm.viewMode = vm.VIEW_MODES.organize;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.Feed = Feed;
    vm.Translation = Translation;
    vm.User = User;
    vm.UserDirectory = UserDirectory;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Filter users based on the filter fields value.
     */
    function _filterUser() {
        const types = [];
        angular.forEach(vm.filter.accountType, (value, key) => {
            if (value) {
                types.push(key);
            }
        });

        const { listParams = { showHidden: false } } = vm;
        const queryParams = {
            feeds: vm.filter.feedKeys || [],
            firstName: vm.filter.firstName,
            lastName: vm.filter.lastName,
            primaryEmail: vm.filter.email,
            types,
            ...listParams,
        };

        UserDirectory.filterize(queryParams);
    }

    const _debouncedFilterUser = debounce(_filterUser, 500);

    /**
     * Initialize the list of users.
     */
    function _initList() {
        vm.isOpen = true;
        // List of selected users that we show in organize view mode
        vm.tmpUserList = [];

        if (angular.isDefinedAndFilled(vm.ngModel)) {
            vm.userIds = _pickIds ? vm.ngModel : map(vm.ngModel, 'id');

            vm.getListNextPage();
        }
    }

    /**
     * Initialize the picker.
     * The picker is initialized either when the dialog is opened (when not in embedded mode) or when the directive
     * initializes (when in embedded mode).
     */
    function _initPicker() {
        vm.isOpen = true;

        _pickIds = false;
        _sliceFrom = 0;

        vm.ngModel = vm.ngModel || [];

        /**
         * We have to keep a copy of the original ngModel in case the user cancels his picks.
         * The original vm.ngModel will be mutated at any actions the user makes.
         */
        _originalNgModel = angular.fastCopy(vm.ngModel);

        if (angular.isUndefined(vm.pickIds)) {
            _pickIds = angular.isDefinedAndFilled(vm.ngModel) ? !angular.isObject(vm.ngModel[0]) : false;
        } else {
            _pickIds = vm.pickIds;
        }

        if (_pickIds) {
            if (!vm.paginateSelection) {
                _pageSize = undefined;
            } else if (angular.isNumber(vm.paginateSelection)) {
                _pageSize = vm.paginateSelection;
            }
        } else {
            _pageSize = undefined;
        }

        _filterUser();
        _initList();
    }

    /**
     * Reset the filters.
     */
    function _resetFilters() {
        vm.filter = {
            accountType: {},
            email: undefined,
            feedKeys: [],
            firstName: undefined,
            lastName: undefined,
        };
    }

    /**
     * Reset the list of users.
     */
    function _resetList() {
        vm.selectedUser = undefined;
        vm.tmpUserList = undefined;
        vm.userIds = [];
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close the user picker.
     * If the param userList isn't defined, the user didn't submit a new list.
     * In this case, the ngModel shouldn't be modified.
     *
     * @param {Array} [userList] The user list to save.
     */
    function closeUserPicker(userList) {
        if (angular.isUndefined(userList)) {
            vm.ngModel = _originalNgModel;
        }

        UserPicker.close(vm.pickerId, userList);
    }

    /**
     * Close the user filter.
     */
    function closeFilter() {
        vm.isFilterDisplayed = false;
    }

    /**
     * Get the user apiProfile.
     *
     * @param  {Object} user The user to get the apiProfile of.
     * @return {Object} The apiProfile details or the user itself.
     */
    function getApiProfile(user) {
        return angular.isDefined(user.apiProfile) ? user.apiProfile : user;
    }

    /**
     * Get the next page of users.
     */
    function getListNextPage() {
        User.filterize(
            {
                ids: vm.userIds.slice(_sliceFrom, angular.isNumber(_pageSize) ? _sliceFrom + _pageSize : undefined),
                maxResults: angular.isNumber(_pageSize) ? _pageSize : vm.userIds.length,
                // We want the already selected users that are hidden to still appear. They will keep being unsearchable and un-addable.
                showHidden: true,
            },
            (response) => {
                vm.tmpUserList = vm.tmpUserList.concat(response.items || response);
            },
            undefined,
            vm.SELECTION_LIST_KEY,
        );

        _sliceFrom += _pageSize;
    }

    /**
     * Check if a user is selected.
     *
     * @param  {Object}  user The user to check the current selection status of.
     * @return {boolean} Whether the user is selected or not.
     */
    function isSelected(user) {
        return vm.userIds.indexOf(user.id) > -1;
    }

    /**
     * Open the user filter.
     */
    function openFilter() {
        vm.isFilterDisplayed = true;
    }

    /**
     * Submit user list.
     */
    function submitUserList() {
        vm.ngModel = _pickIds ? angular.fastCopy(vm.userIds) : angular.fastCopy(vm.tmpUserList);

        if (!vm.embedded) {
            $timeout(() => {
                vm.closeUserPicker(vm.tmpUserList);
            });
        }
    }

    /**
     * Switch the user list to a specific view mode.
     *
     * @param {string} viewMode The view mode name to switch to.
     *                          Can be either 'organize' or 'library'.
     */
    function switchViewMode(viewMode) {
        if (angular.isDefinedAndFilled(vm.VIEW_MODES[viewMode])) {
            vm.viewMode = viewMode;
        }

        vm.selectedUser = undefined;

        if (vm.viewMode === vm.VIEW_MODES.organize && vm.embedded) {
            vm.submitUserList();
        }
    }

    /**
     * Toggle the details of a user in the side panel.
     *
     * @param {Object} user The user to display the details of.
     */
    function toggleUserDetails(user) {
        if (user === vm.selectedUser) {
            vm.selectedUser = undefined;
        } else {
            vm.selectedUser = user;
        }
    }

    /**
     * Toggle the selection status of a given user.
     *
     * @param {Object} user The user to toggle the status of.
     */
    function toggleUserSelection(user) {
        if (angular.isUndefinedOrEmpty(user)) {
            return;
        }

        const userIndex = indexOf(vm.userIds, user.id);

        if (userIndex > -1) {
            vm.userIds.splice(vm.userIds.indexOf(user.id), 1);
            vm.tmpUserList.splice(userIndex, 1);

            if (vm.viewMode === vm.VIEW_MODES.organize && vm.embedded) {
                vm.submitUserList();
            }
        } else if (angular.isUndefined(vm.maxSelect) || vm.userIds.length < vm.maxSelect) {
            vm.tmpUserList.push(user);
            vm.userIds.push(user.id);
        }
    }

    /**
     * Toggle the "all" selection.
     * If all items are selected, then unselected them all.
     * Else, select them all.
     */
    function toggleAll() {
        const usersInListIds = map(UserDirectory.getList(), 'id');
        const notSelectedUsersInList = difference(usersInListIds, vm.userIds);

        const unselect =
            angular.isUndefinedOrEmpty(notSelectedUsersInList) || (vm.maxSelect && vm.userIds.length >= vm.maxSelect);

        angular.forEach(UserDirectory.getList(), (user) => {
            if ((unselect && vm.isSelected(user)) || (!unselect && !vm.isSelected(user))) {
                vm.toggleUserSelection(user);
            }
        });
    }

    /////////////////////////////

    vm.closeFilter = closeFilter;
    vm.closeUserPicker = closeUserPicker;
    vm.getApiProfile = getApiProfile;
    vm.getListNextPage = getListNextPage;
    vm.isSelected = isSelected;
    vm.openFilter = openFilter;
    vm.submitUserList = submitUserList;
    vm.switchViewMode = switchViewMode;
    vm.toggleAll = toggleAll;
    vm.toggleUserDetails = toggleUserDetails;
    vm.toggleUserSelection = toggleUserSelection;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch changes on the filter object to refresh the list of users.
     */
    $scope.$watch(
        () => vm.filter,
        (newValue, oldValue) => {
            if (newValue !== oldValue) {
                _debouncedFilterUser();
            }
        },
        true,
    );

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the user picker closes, reset the lists and filters.
     *
     * @param {Event}  evt      The user picker close event.
     * @param {string} pickerId The id of the user picker that closes.
     */
    $scope.$on('user-picker__close-end', (evt, pickerId) => {
        if (vm.pickerId === pickerId) {
            vm.isOpen = false;

            _resetFilters();
            _resetList();
        }
    });

    /**
     * When the user picker opens, switch to the right mode and initialize the lists.
     *
     * @param {Event}  evt      The user picker open event.
     * @param {string} pickerId The id of the user picker that opens.
     */
    $scope.$on('user-picker__open-start', (evt, pickerId) => {
        if (vm.pickerId === pickerId) {
            _initPicker();
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.title = vm.title || Translation.translate('USER_PICKER_HEADER_TITLE');
        vm.userIds = [];

        vm.SELECTION_LIST_KEY += vm.pickerId;

        _resetFilters();

        // Embed mode will never trigger the user-picker__open-start event so load users manually.
        if (vm.embedded) {
            _initPicker();
        }
    }

    init();
}

/////////////////////////////

/**
 * User picker directive.
 * Handle a dialog (or an embedded) user picker that allow to search and select users.
 *
 * @param {boolean} [embedded=false]                   Indicates if we want the user picker be displayed as a dialog
 *                                                     or directly embedded to its position.
 * @param {boolean} [allowToUpdateEmpty=false]         Indicates if we want to allow to click the "Update selection"
 *                                                     button, even when the selection is empty
 * @param {number}  [maxSelect]                        The maximum number of users that can be selected.
 * @param {Object}  [listParams]                       Parameters to use in calls to the list/user endpoint.
 * @param {Array}   ngModel                            The model where to store the picked users (or users ids).
 * @param {boolean} [paginateSelection=false]          Indicates if we want also wants to paginate the users
 *                                                     selection list.
 * @param {boolean} [pickIds=<depends on model>]       Indicates if we want to store full users object in the model
 *                                                     or only theirs ids.
 *                                                     If nothing is given, we check the first item of the model (if
 *                                                     any: if it's an object, then we'll pick full users object, if
 *                                                     it's a string, then we'll only pick users ids. If there is no
 *                                                     element in the model yet, then we'll pick full users objects.
 * @param {string}  pickerId                           The id of the picker.
 * @param {string}  [title=<USER_PICKER_HEADER_TITLE>] The title of the userpicker.
 */

function UserPickerDirective() {
    'ngInject';

    function link(scope, el) {
        if (!scope.vm.embedded) {
            el.appendTo('body');
        }

        scope.$on('$destroy', () => {
            if (!scope.vm.embedded) {
                el.remove();
            }
        });
    }

    return {
        bindToController: true,
        controller: UserPickerController,
        controllerAs: 'vm',
        link,
        restrict: 'E',
        scope: {
            allowToUpdateEmpty: '<?lsAllowToUpdateEmpty',
            customActions: '<?lsCustomActions',
            embedded: '<?lsEmbedded',
            listParams: '<?lsListParams',
            maxSelect: '<?lsMaxSelect',
            ngModel: '=',
            paginateSelection: '<?lsPaginateSelection',
            pickIds: '<?lsPickIds',
            pickerId: '@lsPickerId',
            title: '@lsTitle',
        },
        templateUrl: '/client/common/modules/user-picker/views/user-picker.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsUserPicker', UserPickerDirective);

/////////////////////////////

export { UserPickerDirective, UserPickerController };
