import React from 'react';

/* istanbul ignore next */
const ConnectedSADashboard = React.lazy(() =>
    import(
        /* webpackChunkName: 'social-advocacy-dashboard' */
        './ConnectedSADashboard'
    ),
);

export const SADashboard = (props) => {
    return (
        <React.Suspense fallback={<></>}>
            <ConnectedSADashboard {...props} />
        </React.Suspense>
    );
};
