(function IIFE() {
    'use strict';

    /////////////////////////////

    function RowService($rootScope, Header, LxNotificationService, Translation) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The currently selected row.
         *
         * @type {Object}
         */
        var _currentRow;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the currently selected row is active.
         *
         * @type {boolean}
         */
        service.isActive = false;

        /**
         * Indicates if the currently selected row is focused.
         *
         * @type {boolean}
         */
        service.isFocused = false;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Delete the current row.
         *
         * @param {Object} component The component that contains the cell we want to delete.
         */
        function deleteCurrent(component) {
            LxNotificationService.confirm(
                Translation.translate('ROW_DELETE'),
                Translation.translate('ROW_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function onDeleteRpwConfirm(answer) {
                    if (!answer) {
                        return;
                    }

                    if (component.components.length > 1) {
                        component.components.splice(component.components.indexOf(_currentRow), 1);

                        $rootScope.$broadcast('designer-component__delete', 'row');
                    } else {
                        LxNotificationService.error(Translation.translate('ROW_ERROR_DELETE_LAST'));
                    }
                });
        }

        /**
         * Get the current row.
         *
         * @return {Object} The current row.
         */
        function getCurrent() {
            return _currentRow;
        }

        /*
         * Reset current row and row states.
         */
        function resetRow() {
            _currentRow = undefined;
            service.isFocused = false;
            service.isActive = false;

            $rootScope.$broadcast('designer-component__unset', 'row');
        }

        /**
         * Set the current row.
         *
         * @param {Object} row The row to set.
         */
        function setCurrent(row) {
            _currentRow = row;

            if (angular.isDefined(_currentRow)) {
                Header.isActive = false;
                $rootScope.$broadcast('designer-component__set', 'row');
            } else {
                $rootScope.$broadcast('designer-component__unset', 'row');
            }
        }

        /////////////////////////////

        service.deleteCurrent = deleteCurrent;
        service.getCurrent = getCurrent;
        service.resetRow = resetRow;
        service.setCurrent = setCurrent;
    }

    /////////////////////////////

    angular.module('Services').service('Row', RowService);
})();
