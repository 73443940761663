import { Editor, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import { TableEditor } from '../types';
import { deleteTable } from './deleteTable';
import { getCurrentRowsInTable } from './getCurrentRowsInTable';
import { getCurrentSelectionInfos } from './getCurrentSelectionInfos';

/** Delete a row at the current position in a table. */
export const deleteRow = (editor: ReactEditor & TableEditor) => {
    /* get selection details (null if no selection or if selection not in table) */
    const selectionInfos = getCurrentSelectionInfos(editor);

    if (!selectionInfos) {
        return undefined;
    }

    const { startRow, selectedRows } = selectionInfos;

    const tablePath = [Range.start(editor.selection as Range).path[0]];

    /* get the number of row in the table  */
    const { rowsLength } = getCurrentRowsInTable(editor, tablePath);

    if (rowsLength === 1 || selectedRows.length === rowsLength) {
        /* if there is only one row remaining in the table, we remove the table */
        const newCursorPosition = deleteTable(editor);
        return newCursorPosition;
    }

    Editor.withoutNormalizing(editor, () => {
        /* repeat the operation for each selected row (reverse to be sure to not try to delete an already deleted row */
        selectedRows.reverse().forEach((row) => {
            /* delete row at current cursor position */
            Transforms.removeNodes(editor, { at: [...tablePath, row] });
        });
    });
    const newRowLength = rowsLength - selectedRows.length;
    /* position the cursor at the same position except if the deleted column was the last on, the cursor is moved to the new last column */
    const newCursorPosition = {
        anchor: {
            path: [...tablePath, Math.min(startRow, newRowLength - 1), 0, 0, 0],
            offset: 0,
        },
        focus: {
            path: [...tablePath, Math.min(startRow, newRowLength - 1), 0, 0, 0],
            offset: 0,
        },
    };

    // For some reason, the focusAt doesn't work properly in the table context if we do not delay it.
    setTimeout(() => {
        focusAt(editor, newCursorPosition);
    }, 1);

    return newCursorPosition;
};
