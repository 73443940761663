/* eslint-disable @typescript-eslint/camelcase */
import BaseApi from '@lumapps/base-api';

export const saApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy' });

export const backendErrorCodeToIntlMessage = {
    authorization_required: 'authorization_required',
    invalid_request: 'invalid_request',
    'domain.post.create.unauthorized_access': 'FRONT.SOCIAL_ADVOCACY.SHARE.LINKEDIN_LOGIN_EXPIRED',
    'domain.shareable_content.create.content_cannot_be_made_shareable':
        'FRONT.SOCIAL_ADVOCACY.MAKE_SHAREABLE.EMPTY_META_DATA',
    'domain.shareable_content.create.duplicate_on_content_core_id':
        'FRONT.SOCIAL_ADVOCACY.MAKE_SHAREABLE.DUPLICATED_CONTENT',
    'domain.shareable_content.create.duplicate_on_content_url':
        'FRONT.SOCIAL_ADVOCACY.MAKE_SHAREABLE.DUPLICATED_CONTENT',
    'domain.shareable_content.create.total_media_count_too_high':
        'FRONT.SOCIAL_ADVOCACY.SHAREABLE_CONTENT.SHARING_NOT_SUPPORTED.GALLERY',
};
