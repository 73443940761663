import React from 'react';

import { useDispatch, useSelector } from '@lumapps/redux/react';
import { isTranslatableObject, isTranslateObject, TranslatableObject, TranslateObject } from '@lumapps/translations';
import { inputLocaleSelector } from '@lumapps/translations/selectors';

import { actions } from '../ducks/slice';
import type { LegacyWidget } from '../types';
import type { EditableWidgetProps } from '../types/designer';

export const useEditableWidget = <LegacyWidgetProps>(
    initLegacyWidget: LegacyWidget<LegacyWidgetProps>,
    switchToReadMode: EditableWidgetProps<LegacyWidgetProps>['switchToReadMode'],
    saveTextInWidgetProperties: EditableWidgetProps<LegacyWidgetProps>['saveTextInWidgetProperties'],
    getTradObject: (properties?: LegacyWidgetProps) => TranslatableObject | TranslateObject | undefined,
) => {
    const dispatch = useDispatch();

    const currentInputLang = useSelector(inputLocaleSelector);
    const wrapperRef = React.useRef(null);
    const childrenRefs = React.useRef([wrapperRef]);

    /**
     * Since <react-element> don't watch the object entirely, but watch it's 'properties' properties
     * (to avoid re-rendering and make first double click to edit work), we need to destructure the object
     * (since only the 'properties' properties is watched).
     */
    const legacyWidget = { ...initLegacyWidget } as LegacyWidget<LegacyWidgetProps>;

    const getText = React.useCallback(
        (lang, properties?: LegacyWidgetProps) => {
            const trad = getTradObject(properties);
            if (isTranslatableObject(trad)) {
                return trad.translations[lang] ?? '';
            }
            if (isTranslateObject(trad)) {
                return trad[lang] ?? '';
            }
            return '';
        },
        [getTradObject],
    );

    const [content, onChange] = React.useState(getText(currentInputLang, legacyWidget?.properties));

    const onExit = React.useCallback(() => {
        const trad = getTradObject(legacyWidget.properties) ?? {};

        if (isTranslatableObject(trad)) {
            saveTextInWidgetProperties({
                ...trad,
                translations: {
                    ...trad.translations,
                    [currentInputLang]: content,
                },
            });
        } else {
            saveTextInWidgetProperties({
                ...trad,
                [currentInputLang]: content,
            });
        }
    }, [content, currentInputLang, getTradObject, legacyWidget.properties, saveTextInWidgetProperties]);

    /** on click outside the editor, we call onExit callback and switch to read mode */
    const exitEditionMode = React.useCallback(() => {
        onExit();

        switchToReadMode();
    }, [switchToReadMode, onExit]);

    const theme = legacyWidget.properties?.style?.content?.theme;

    /**
     * Update content translated value when the  current input lang change
     * */
    React.useEffect(() => {
        onChange(getText(currentInputLang, legacyWidget?.properties));
    }, [currentInputLang, getText, legacyWidget?.properties]);

    /**
     * Register the widget into redux.
     * Needed in order to fetch the widget with the Block API
     * */
    React.useEffect(() => {
        const { widgetType, uuid: widgetId } = initLegacyWidget;
        if (widgetId) {
            dispatch(
                actions.setWidget({
                    widgetId,
                    widgetType,
                }),
            );

            dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: undefined } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        legacyWidget,
        content,
        editableWidgetProps: {
            wrapperRef,
            childrenRefs,
            exitEditionMode,
            content,
            onChange,
            theme,
        },
    };
};
