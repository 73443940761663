import { RefObject, useCallback, useEffect } from 'react';

import noop from 'lodash/noop';

import { usePrevious } from './usePrevious';

/**
 * This utils append a custom event listener to a specific element define by a ref
 *
 * @param elementRef the ref to the element to add the event
 * @param eventName the name of the event
 * @param eventHandler event to execute
 */
export function useCustomEventListener<T>(
    elementRef: RefObject<HTMLElement>,
    eventName: string,
    eventHandler: (data: T) => void,
) {
    const handleEvent = useCallback(
        (event: CustomEvent | Event) => {
            const data = (event as CustomEvent).detail;
            eventHandler(data);
        },
        [eventHandler],
    );

    const previous = usePrevious({ element: elementRef.current });

    useEffect(() => {
        if (previous?.element) {
            previous.element.removeEventListener(eventName, handleEvent, false);
        }

        if (elementRef.current) {
            // eslint-disable-next-line
            const current = elementRef.current;

            current.addEventListener(eventName, handleEvent, false);

            return () => {
                current.removeEventListener(eventName, handleEvent, false);
            };
        }

        return noop;
    }, [elementRef, eventName, handleEvent, previous]);
}
