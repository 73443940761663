import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { NavigationSkeleton } from '@lumapps/lumx-navigation/components/NavigationSkeleton';
import { ViewMode } from '@lumapps/lumx-navigation/constants';
import { SkeletonRectangle, Theme, FlexBox } from '@lumapps/lumx/react';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';

const CLASSNAME = 'space-header';
export const SpaceHeaderSkeleton: React.FC = () => {
    const isInWebview = useDetectMobileAppWebView();
    const { block, element } = useClassnames(CLASSNAME);

    if (isInWebview) {
        return null;
    }

    return (
        <FlexBox orientation="vertical" gap="big" as="header" className={block([margin('bottom', 'big')])}>
            <SkeletonRectangle className={element('banner')} theme={Theme.light} />
            <NavigationSkeleton theme={Theme.light} viewMode={ViewMode.horizontal} />
        </FlexBox>
    );
};
