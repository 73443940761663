import { WidgetPersonalFeedPreviewPropertiesType } from './types';

/** name of the widgetType in the widget personal feed preview */
export const WIDGET_PERSONAL_FEED_PREVIEW_TYPE = 'personal-feed-preview';

/** Feature Flag to display the widget personal feed preview */
export const WIDGET_PERSONAL_FEED_PREVIEW_FF = 'personal-feed-preview-widget';

export const SCOPE = 'widget-personal-feed';

/** The default properties for the widget personal feed preview */
export const WIDGET_PERSONAL_FEED_PREVIEW_DEFAULT_PROPERTIES: WidgetPersonalFeedPreviewPropertiesType = {
    siteId: undefined,
    maxNumber: 3,
    displayHeader: true,
    fields: ['contextual_banner', 'title', 'excerpt', 'reactions'],
    viewMode: 'list',
    viewModeVariant: 'grouped',
};
