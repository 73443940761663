(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetEmailController($scope, $window, ConfigTheme, Gmail, Instance, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
        * Services and utilities.
        */
        vm.Gmail = Gmail;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the widget properties.
         */
        function _initProperties() {
            if (angular.isDefinedAndFilled(vm.widget.properties.number)) {
                // Backward compatibility.
                vm.widget.properties.number = parseInt(vm.widget.properties.number, 10);
            }

            if (angular.isUndefinedOrEmpty(vm.widget.properties.more)) {
                vm.widget.properties.more = {
                    label: {},
                    link: {},
                };

                angular.forEach(Instance.getInstance().langs, function forEachInstanceLanguage(language) {
                    vm.widget.properties.more.link[language] = 'https://mail.google.com';
                });
            }

            if (angular.isUndefinedOrEmpty(vm.widget.properties.truncate)) {
                vm.widget.properties.truncate = 200;
            }
        }

        /**
         * Load a list of emails.
         */
        function _loadEmails() {
            Gmail.filterize({
                maxResults: vm.widget.properties.number,
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Captures the name of the sender or, failing that, the email address of the sender.
         * For example: i.e. "Arnaud Valle" <arnaud@lumapps.com>.
         *
         * @param  {string} sender The name and email of the person sending the email.
         * @return {string} The name or email address of the sender.
         */
        function getSender(sender) {
            if (angular.isUndefinedOrEmpty(sender)) {
                return '';
            }

            var translated = Translation.replace(
                sender, 'FRONT.WIDGET.EMAIL.HEADER_SEND_ON_BEHALF', Translation.translate('FRONT.WIDGET.EMAIL.HEADER_SEND_ON_BEHALF')
            )
            var values = translated.match(/(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)/);

            return _.get(values, 1, _.get(values, 2, translated));
        }

        /**
         * Get the class names to apply to the widget.
         *
         * @return {Array} An array of class names.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Indicates if the widget is empty or not while in designer mode.
         *
         * @return {boolean} Whether the widget is empty or not.
         */
        function isWidgetEmpty() {
            return !Gmail.isCallInProgress() && angular.isUndefinedOrEmpty(Gmail.getList());
        }

        /**
         * Indicates if the widget needs to be hidden when in reading mode.
         *
         * @return {boolean} Whether the widget is hidden or not.
         */
        function isWidgetHidden() {
            if (!vm.parentCtrl.designerMode() &&
                !vm.widget.properties.noResults &&
                !Gmail.isCallInProgress() &&
                angular.isUndefinedOrEmpty(Gmail.getList())) {
                vm.parentCtrl.isHidden = true;

                return true;
            }
            vm.parentCtrl.isHidden = false;

            return false;
        }

        /**
         * Open a given email in a new window.
         *
         * @param {string} link The link to the open the mail in mail application.
         */
        function openEmail(link) {
            if (angular.isUndefinedOrEmpty(link)) {
                return;
            }

            $window.open(link, '_blank');
        }

        /////////////////////////////

        vm.getSender = getSender;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.openEmail = openEmail;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Whenever the widget email settings are updated, reload the email list.
         *
         * @param {Event}  evt  The original event triggering this method.
         * @param {string} uuid The identifier of the widget email that just got updated.
         */
        $scope.$on('widget-email-settings', function onWidgetEmailSettingsChange(evt, uuid) {
            if (vm.widget.uuid === uuid) {
                _loadEmails();
            }
        });

        /////////////////////////////

        /**
         * Initialize controller.
         */
        function init() {
            _initProperties();

            _loadEmails();
        }

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            init();
        };
    }

    function WidgetEmailDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetEmailController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetEmail', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-email/views/widget-email.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetEmail', WidgetEmailDirective);
})();
