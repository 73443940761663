import React from 'react';

import isEqual from 'lodash/isEqual';

import { get as getConfig } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { ImageLightbox, ImageLightboxProps } from '@lumapps/lumx/react';
import { sendWebviewMobileMessage } from '@lumapps/mobile-webview/message-emit';
import { addLocationOriginToUrl } from '@lumapps/router/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useSlideshowControlLabels } from '@lumapps/utils/hooks/useSlideshowControlLabels';

const { applicationId } = getConfig();

interface Options {
    /** Current application id (DO NOT USE, TEST ONLY) */
    app?: AppId;
}

export type UseImageLightbox = ReturnType<typeof ImageLightbox.useImageLightbox>;

/**
 * A hook to setup the `ImageLightbox` and providing a callback to open it
 */
export function useImageLightbox(images: ImageLightboxProps['images'] = [], options: Options = {}): UseImageLightbox {
    const { app = applicationId } = options;
    const isWebview = app === AppId.webview;
    const slideshowLabels = useSlideshowControlLabels();
    const { translateKey } = useTranslate();

    const [savedImages, setSavedImages] = React.useState(images);
    React.useEffect(() => {
        if (!isEqual(savedImages, images)) {
            setSavedImages(images);
        }
    }, [images, savedImages]);

    // Initialize DS ImageLightbox
    const { getTriggerProps: initialGetTriggerProps, imageLightboxProps } = ImageLightbox.useImageLightbox({
        images,
        'aria-label':
            images.length > 1 ? translateKey(GLOBAL.FULLSCREEN_IMAGE_SLIDESHOW) : translateKey(GLOBAL.FULLSCREEN_IMAGE),
        closeButtonProps: { label: translateKey(GLOBAL.CLOSE) },
        zoomInButtonProps: { label: translateKey(GLOBAL.ZOOM_IN) },
        zoomOutButtonProps: { label: translateKey(GLOBAL.ZOOM_OUT) },
        slideshowControlsProps: {
            nextButtonProps: { label: slideshowLabels.next },
            previousButtonProps: { label: slideshowLabels.previous },
            paginationItemProps: (index: number) => ({
                label: slideshowLabels.paginationItem(index),
            }),
        },
    });

    // Override getTriggerProps
    const getTriggerProps = React.useCallback<UseImageLightbox['getTriggerProps']>(
        (options) => {
            const props = initialGetTriggerProps(options);
            const onClick = (event: React.MouseEvent) => {
                if (isWebview) {
                    /* In webview app: send images to the host mobile app instead. */
                    const images = savedImages.map(({ image }) => ({ image: addLocationOriginToUrl(image) }));
                    sendWebviewMobileMessage({
                        type: 'open-image-slideshow',
                        payload: { images, activeImageIndex: options?.activeImageIndex || 0 },
                    });
                } else {
                    props.onClick(event);
                }
            };

            const activeIndex = options?.activeImageIndex || 0;

            return {
                ...props,
                onClick,
                'aria-label': savedImages[activeIndex]?.alt
                    ? `${translateKey(GLOBAL.OPEN_IMAGE_IN_FULLSCREEN)} ${savedImages[activeIndex].alt}`
                    : translateKey(GLOBAL.OPEN_IMAGE_IN_FULLSCREEN),
            };
        },
        [initialGetTriggerProps, savedImages, translateKey, isWebview],
    );

    return { getTriggerProps, imageLightboxProps };
}
