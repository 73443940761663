import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

/**
 * Build an array of custom CSS classes from a string of comma separated CSS classes.
 *
 * @param  {string} customClasses    The custom CSS classes string (comma separated CSS classes).
 * @param  {string} [classPrefix=''] A prefix to add before each CSS class.
 * @return {Array}  The array of all (prefixed if needed) CSS classes.
 */
function getCustomClass(customClasses, classPrefix) {
    classPrefix = classPrefix || '';

    const classesArray = [];

    if (isUndefined(customClasses) || customClasses === '') {
        return classesArray;
    }

    const customClassesSplit = customClasses.split(',');
    if (isUndefined(customClassesSplit) || customClassesSplit === '') {
        return classesArray;
    }

    map(customClassesSplit, (customClass) => {
        if (!isUndefined(customClass) && customClass !== '') {
            classesArray.push(classPrefix + customClass.trim());
        }
    });

    return classesArray;
}

/**
 * Highlight Range Filter.
 * Highlight the given range(s) of characters within a string.
 *
 * @param  {string} str                                       The string containing the ranges to highlight.
 * @param  {Array}  ranges                                    The ranges of characters within str to highlight.
 * @param  {string} [wrapperSelector='cloudsearch-highlight'] The selector to apply to the wrapper element on
 *                                                            highlighted ranges.
 * @return {string} The highlighted string.
 */
function highlightRange(str, ranges, wrapperSelector = 'cloudsearch-highlight') {
    if (!ranges || ranges.length === 0) {
        return str;
    }

    let highlighted = '';
    const wrapperStart = `<mark class="${wrapperSelector}">`;
    const wrapperEnd = '</mark>';
    let headStart = 0;

    ranges.forEach((range, index) => {
        range.start = range.start || 0;
        range.end = range.end || str.length;
        headStart = index > 0 ? ranges[index - 1].end : 0;

        // Anything between the previous range.end and this range.start.
        const head = str.substring(headStart, range.start);

        // Anything between the last range.end and the end of the original string.
        const tail = index === ranges.length - 1 ? str.substring(range.end, str.length) : '';

        highlighted += head + wrapperStart + str.substring(range.start, range.end) + wrapperEnd + tail;
    });

    return highlighted;
}

/**
 * Security / XSS convenient method that removes js script that can be placed in HTML anchor href. 
 * Replaces href="javascript:xxxxxxxxx" with href=""
 * 
 * @param {string} html Html string to be analysed
 * 
 * @return {string} Sanitized html string
 */
function preventJSInHTMLLinks(html) {
    return html.replace(/href="(javascript:(.*)?)"/g, ()=>'href=""');
}

export { getCustomClass, highlightRange, preventJSInHTMLLinks };
