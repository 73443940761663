import React from 'react';

import { getLanguageHeader } from '@lumapps/languages';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { GetBookmarkResponse, GetUserBookmarkParams } from '@lumapps/user-bookmarks/api';
import { userIdSelector } from '@lumapps/user/ducks/selectors';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { createSavedItem, deleteSavedItem, getSavedItem } from '../api';
import { USER_SAVED_ITEMS } from '../keys';

export interface UseSaveButtonParams extends Pick<GetUserBookmarkParams, 'resourceId' | 'resourceType' | 'priority'> {
    fetchOnMount?: boolean;
}

/**
 * Hook that will fetch the saved status of the given object and return the props to manage the saving
 */
export const useSaveButton = ({ resourceId, resourceType, fetchOnMount, priority }: UseSaveButtonParams) => {
    const currentUserId = useSelector(userIdSelector) || '';
    const acceptLanguageHeader = useSelector(getLanguageHeader);

    const [savedItemId, setSavedItemId] = React.useState<string | undefined>(undefined);
    const [isDisabled, setIsDisabled] = React.useState(false);

    const { error: notificationError } = useNotification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSavedItemParams: GetUserBookmarkParams = {
        userId: currentUserId,
        resourceId,
        resourceType,
        priority,
        acceptLanguageHeader,
    };

    const {
        fetch,
        status: getSavedItemStatus,
        response: savedItemResponse,
    } = useFetchWithStatus<GetBookmarkResponse>({
        onFetch: getSavedItem,
        fetchOnMount,
        initialFetchParams: fetchOnMount
            ? {
                  params: getSavedItemParams,
              }
            : undefined,
    });

    const fetchSavedItem = React.useCallback(() => {
        if ([BaseLoadingStatus.initial, BaseLoadingStatus.error].includes(getSavedItemStatus)) {
            fetch({
                params: getSavedItemParams,
            });
            setIsDisabled(false);
        }
    }, [fetch, getSavedItemParams, getSavedItemStatus]);

    React.useEffect(() => {
        if (getSavedItemStatus === BaseLoadingStatus.idle && savedItemResponse && savedItemResponse.items?.length > 0) {
            setSavedItemId(savedItemResponse.items[0]?.id);
        } else if (getSavedItemStatus === BaseLoadingStatus.error) {
            notificationError({ translate: USER_SAVED_ITEMS.SAVE_UNAVAILABLE });
            setIsDisabled(true);
        }
    }, [savedItemResponse, getSavedItemStatus, notificationError]);

    /*
     * Save or delete saved item depending on current state.
     * Call the API and set the local status.
     */
    const toggleSave = React.useCallback(async () => {
        if (getSavedItemStatus === BaseLoadingStatus.idle && !isDisabled) {
            setIsDisabled(true);
            try {
                if (savedItemId === undefined) {
                    // If we need to create a new savedItem
                    const { status, data } = await createSavedItem({
                        userId: currentUserId,
                        resourceId,
                        resourceType,
                    });
                    if (status === 201) {
                        setSavedItemId(data.id);
                    }
                } else {
                    // If we need to delete current savedItem
                    const { status } = await deleteSavedItem({ userId: currentUserId, itemId: savedItemId });
                    if (status === 204) {
                        setSavedItemId(undefined);
                    }
                }
            } catch (error) {
                notificationError({ translate: GLOBAL.ACTION_NOT_COMPLETED_TRY_AGAIN });
            } finally {
                setIsDisabled(false);
            }
        }
    }, [currentUserId, savedItemId, getSavedItemStatus, isDisabled, notificationError, resourceId, resourceType]);

    return {
        fetchSavedItem,
        status: getSavedItemStatus,
        isSaved: savedItemId !== undefined,
        onClick: toggleSave,
        isDisabled,
    };
};
