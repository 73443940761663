import { saApi } from './constants';

/* Analytics. */

export const exportAnalytics = (filters) => saApi.post('analytics/export', { filters });

/**
 *  Get Active Ambassadors.
 *
 * @param  {*}                     filters Search criterias.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getActiveAmbassadors = (filters) => {
    let body = {};
    if (filters) {
        body = { filters };
    }

    body.metrics = ['sharer_count', 'user_count', 'sharer_rate'];

    return saApi.post('analytics', body);
};

/**
 *  Get The number of of social post created.
 *
 * @param  {*}                     filters Search criterias.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getNewSocialPost = (filters) => {
    let body = {};
    if (filters) {
        body = { filters };
    }

    body.metrics = ['shareable_content_created_count'];

    return saApi.post('analytics', body);
};

/**
 * Get the total shares.
 *
 * @param  {*}                     filters Search criterias.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getTotalShares = (filters) => {
    let body = {};
    if (filters) {
        body = { filters };
    }

    body.metrics = ['share_created_count', 'share_created_count_by_creator'];

    return saApi.post('analytics', body);
};

/**
 *  Get Interaction the global interaction rate.
 *
 * @param  {*}                     filters Search criterias.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getInteractionRate = (filters) => {
    let body = {};
    if (filters) {
        body = { filters };
    }

    body.metrics = ['share_interaction_count', 'share_interaction_rate'];

    return saApi.post('analytics', body);
};

/**
 *  Get the global number of likes.
 *
 * @param  {*}                     filters Search criterias.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */

export const getTotalLikes = (filters) => {
    let body = {};
    if (filters) {
        body = { filters };
    }

    body.metrics = [
        'share_like_count',
        'share_like_creator_count',
        'share_like_shareable_content_count',
        'share_like_count_by_creator',
        'share_like_count_by_shareable_content',
    ];

    return saApi.post('analytics', body);
};

/**
 * Get the global amount of comments.
 *
 * @param  {*}                     filters Search criterias.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getTotalComments = (filters) => {
    let body = {};
    if (filters) {
        body = { filters };
    }

    body.metrics = [
        'share_comment_count',
        'share_comment_creator_count',
        'share_comment_shareable_content_count',
        'share_comment_count_by_creator',
        'share_comment_count_by_shareable_content',
    ];

    return saApi.post('analytics', body);
};

/**
 *  Generic method that builds a Top *dimension* query.
 *
 * @param  {*}                     filters   Search criterias.
 * @param  {string}                metric    The metric to use for calculation.
 * @param  {string}                dimension The Dimension to use.
 * @param  {number}                limit     Number of entries returned by the API.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const buildTopQuery = (filters, metric, dimension, limit = 3) =>
    saApi.post(
        'analytics',
        Object.assign(filters ? { filters } : {}, {
            dimensions: [dimension],
            limit,
            metrics: [metric],
            sorts: [{ dir: -1, metric }],
        }),
    );

/**
 * Query the api to get a top of topics dimension.
 *
 * @param  {*}                     filters Search criterias.
 * @param  {string}                metric  The metric to use for calculation.
 * @param  {number}                limit   Number of entries returned by the API.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getTopTopicsByMetric = (filters, metric, limit = 3) => buildTopQuery(filters, metric, 'topic', limit);

/**
 * Query the api to get a top of Shareable Content dimension.
 *
 * @param  {*}                     filters Search criterias.
 * @param  {string}                metric  The metric to use for calculation.
 * @param  {number}                limit   Number of entries returned by the API.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getTopPostsByMetric = (filters, metric, limit = 3) =>
    buildTopQuery(filters, metric, 'shareable_content', limit);

/**
 *  Query the api to get a top of Ambassadors dimension.
 *
 * @param  {*}                     filters Search criterias.
 * @param  {string}                metric  The metric to use for calculation.
 * @param  {number}                limit   Number of entries returned by the API.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getTopAmbassadorsByMetric = (filters, metric, limit = 3) =>
    buildTopQuery(filters, metric, 'sharer', limit);
