import React, { HTMLAttributes, ReactNode } from 'react';

import { Feed } from '@lumapps/lumx-feed/components/Feed';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { WidgetState } from '../../../ducks/type';
import { BlockList as BlockListType } from '../../../types';

import './index.scss';

/**
 * Translate the widget states to the "BaseLoadingStatus" states used by the Feed component.
 */
const blockLoadingStatusToBaseLoadingStatus: { [K in WidgetState['state'] as string]: BaseLoadingStatus } = {
    loading: BaseLoadingStatus.loading,
    loadingmore: BaseLoadingStatus.loadingMore,
    loaded: BaseLoadingStatus.idle,
};

/**
 * Props needed by the BlockListWrapper to function
 */
export interface BlockListWrapperProps extends Partial<HTMLAttributes<HTMLDivElement>> {
    /** The current state of the widget */
    loadingState: WidgetState['state'];
    /** Callback to trigger when more items must be fetched */
    onLoadMore: BlockListType['onLoadMore'];
    /**
     * The pagination type to use.
     * If "infinite" is set, the "Feed" pattern will be applied
     */
    paginationType: WidgetState['paginationType'];
    /**
     * The "load more" button to render in "load_more" pagination type.
     */
    loadMoreButton: ReactNode;
    /**
     * The id of the related label element.
     * Used to generate the aria-labelledby props of the Feed
     */
    ariaLabelledBy?: string;
}

/**
 * Wrapper to hold the block list children.
 * Depending on the pagination type, can either be a Feed (infinite scroll) or simple div.
 */
export const BlockListWrapper: React.FC<BlockListWrapperProps> = ({
    children,
    onLoadMore,
    loadingState,
    paginationType,
    loadMoreButton,
    ariaLabelledBy,
    ...wrapperProps
}) => {
    if (paginationType === 'infinite') {
        return (
            <Feed
                {...wrapperProps}
                as="div"
                aria-label={undefined}
                aria-labelledby={ariaLabelledBy || ''}
                /**
                 * Widgets set "onLoadMore" to undefined when no more elements are available
                 */
                hasMore={Boolean(onLoadMore)}
                onLoadMore={onLoadMore}
                status={
                    loadingState && loadingState in blockLoadingStatusToBaseLoadingStatus
                        ? blockLoadingStatusToBaseLoadingStatus[loadingState]
                        : BaseLoadingStatus.idle
                }
            >
                {children}
            </Feed>
        );
    }

    return (
        <>
            <div {...wrapperProps}>{children}</div>
            {onLoadMore && loadingState === 'loaded' && loadMoreButton}
        </>
    );
};
BlockListWrapper.displayName = 'BlockListWrapper';
