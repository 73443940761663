import debounce from 'lodash/debounce';
import first from 'lodash/first';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import union from 'lodash/union';
import without from 'lodash/without';

import { RenderingType } from '@lumapps/communities/types';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import loFind from 'lodash/find';

// ///////////////////////////

function CommunityService(
    $state,
    $timeout,
    AbstractContent,
    CommunityFactory,
    CommunityWizardConfiguration,
    Config,
    Customer,
    Features,
    Feed,
    InitialSettings,
    Instance,
    LumsitesBaseService,
    MainNav,
    MediaConstant,
    Notification,
    Translation,
    User,
    Utils,
    ReduxStore,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = AbstractContent.createAbstractContentService(CommunityFactory);

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * Enum of all the possible views.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _AVAILABLE_VIEWS = {
        calendar: {
            icon: 'calendar',
            id: 'calendar',
            label: 'FRONT.COMMUNITY.CALENDAR',
            // eslint-disable-next-line no-magic-numbers
            order: 6,
        },
        dashboard: {
            icon: 'home-outline',
            id: 'dashboard',
            label: 'FRONT.COMMUNITY.DASHBOARD',
            order: 0,
        },
        media: {
            icon: 'google-drive',
            id: 'media',
            label: 'FRONT.COMMUNITY.DRIVE',
            // eslint-disable-next-line no-magic-numbers
            order: 5,
        },
        post: {
            icon: 'comment-text',
            id: 'post',
            label: 'FRONT.COMMUNITY.POST_DETAILS',
            // eslint-disable-next-line no-magic-numbers
            order: 2,
        },
        posts: {
            icon: 'comment-text-outline',
            id: 'posts',
            label: 'FRONT.COMMUNITY.POSTS',
            order: 1,
        },
        files: {
            icon: 'file-multiple',
            id: 'files',
            label: 'COMMUNITIES.FILE_LIBRARY',
            order: 4,
        },
        events: {
            icon: 'calendar',
            id: 'events',
            label: 'COMMUNITY_EVENTS.EVENTS_PAGE_NAVIGATION_BUTTON',
            order: 3,
        },
    };

    /**
     * The available tabs on the community page for the current user.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _DEFAULT_AVAILABLE_VIEWS = [_AVAILABLE_VIEWS.post.id, _AVAILABLE_VIEWS.posts.id];

    /**
     * The default landing view in order.
     *
     * If dashboard not available the default will be posts (which is always available).
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _DEFAULT_LANDING_VIEWS = [_AVAILABLE_VIEWS.dashboard.id, _AVAILABLE_VIEWS.posts.id];

    /**
     * The delay to use to debounce the list communities function.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _LIST_COMMUNITIES_DEBOUNCE_DELAY = 500;

    /**
     * The maximum number of communities to list.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_COMMUNITIES_NUMBER = 8;

    /**
     * Cache of last search request state indexed by list key.
     * Used to store the loading state, the fields projection and the cursor.
     *
     * @type {{string: object}}
     * @constant
     */
    const _searchRequests = {};

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * List of the available post types.
     *
     * @type {Object}
     */
    service.availablePostTypes = {
        DEFAULT: {
            icon: 'message-text-outline',
            value: undefined,
        },
        EVENT: {
            icon: 'calendar',
            value: undefined,
        },
        IDEA: {
            icon: 'lightbulb-outline',
            value: undefined,
        },
        QUESTION: {
            icon: 'help',
            value: undefined,
        },
    };

    /**
     * Enum of all the possible views.
     *
     * @type {Object}
     */
    service.availableViews = _AVAILABLE_VIEWS;

    /**
     * A list of the default community post types.
     *
     * @type {Array}
     */
    service.defaultPostTypes = CommunityWizardConfiguration.DEFAULT_POST_TYPES;

    /**
     * The different types of privacy for a community.
     *
     * @type {Object}
     */
    service.privacyTypes = {
        descriptionOnly: 'description_only',
        open: 'open',
        readOnly: 'read_only',
        restricted: 'restricted',
    };

    // ///////////////////////////
    //                         //
    //     Private functions   //
    //                         //
    // ///////////////////////////

    /**
     * Get the CommunityLink for cross instance communities
     *
     * @param {Object} community community data object
     * @param {Array} fetchedSiblings Array of siblings instance
     * @return {Object} CommunityLink
     */
    function _getCommunityLinkFromChildSite(community, fetchedSiblings) {
        const { instance: instanceId } = community;
        const target = loFind(fetchedSiblings, {
            id: instanceId,
        });


        return _getCommunityLink(community, target)
    }

    /**
     * Get the CommunityLink for cross instance communities
     *
     * @param {Object} community community data object
     * @param {Object|undefined} instance instance of the community format { id, slug }
     * @return {Object} CommunityLink
     */
    function _getCommunityLink(community, instance) {
        const { id, slug, renderingType } = community;
        const slugTranslation = Translation.translate(slug);
        const currentInstance = { id: Instance.getCurrentInstanceId(), slug: Instance.getCurrentInstanceSlug() };

        return {
            id,
            renderingType,
            slug: slugTranslation,
            instance: instance ?? currentInstance,
        }
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Build a new community post category shell.
     *
     * @return {Object} A new empty category.
     */
    function categoryCreate() {
        return {
            $tempId: generateUUID(),
            instance: Instance.getCurrentInstanceId(),
            kind: Config.TAG_TYPE.COMMUNITY,
            name: {},
            status: 'LIVE',
        };
    }

    /**
     * Redirect user to the community template edition.
     *
     * @param {string} communityId The community identifier to redirect to.
     * @param {string} template    The target template name.
     */
    function editTemplate(communityId, template) {
        const stateParams = {
            contentType: InitialSettings.CONTENT_TYPES.COMMUNITY,
            key: communityId,
            subTemplate: template,
        };

        if (template === _AVAILABLE_VIEWS.post.id && $state.params.identifier) {
            stateParams.postId = $state.params.identifier;
        }

        $state.go('app.front.content-edit', stateParams);
    }

    /**
     * Get community available views.
     *
     * @param  {Object} community The community to get views from.
     * @return {Array}  The list of available views for the community.
     */
    function getAvailableViews(community) {
        if (angular.isUndefinedOrEmpty(community)) {
            return undefined;
        }

        let views = angular.copy(_DEFAULT_AVAILABLE_VIEWS);

        // A user that cannot contribute to a read only community cannot see it's google calendar and drive.
        const userCanSeeGoogleOptions = !service.isReadOnly(community) || service.isWriteable(community);

        if (community.hasCalendarId && userCanSeeGoogleOptions) {
            views.push(_AVAILABLE_VIEWS.calendar.id);
        } else {
            views = without(views, _AVAILABLE_VIEWS.calendar.id);
        }

        if (community.hasDriveFolder && userCanSeeGoogleOptions) {
            views.push(_AVAILABLE_VIEWS.media.id);
        } else {
            views = without(views, _AVAILABLE_VIEWS.media.id);
        }

        if (community.securedRepository) {
            views.push(_AVAILABLE_VIEWS.files.id);
        } else {
            views = without(views, _AVAILABLE_VIEWS.files.id);
        }

        if (community.hasEventsEnabled && Features.hasFeature('events')) {
            views.push(_AVAILABLE_VIEWS.events.id);
        } else {
            views = without(views, _AVAILABLE_VIEWS.events.id);
        }

        const properties = get(community, 'properties', {});
        if (properties.hasDashboard === true) {
            views.push(_AVAILABLE_VIEWS.dashboard.id);
        } else {
            views = without(views, _AVAILABLE_VIEWS.dashboard.id);
        }

        return views;
    }

    /**
     * Get the default landing view.
     *
     * @param  {string} view           Usually the $state.params.view, the wanted view.
     * @param  {Array}  availableViews The community available views.
     * @return {string} The first available view.
     */
    function getDefaultLandingView(view, availableViews) {
        const landings = angular.copy(_DEFAULT_LANDING_VIEWS);

        if (angular.isDefinedAndFilled(view)) {
            // Put the view in priority.
            landings.unshift(view);
        }

        // Set first available view.
        return first(intersection(landings, availableViews));
    }

    /**
     * Return the first available post types (check between all post type and the community post types).
     *
     * @param  {Object} postTypes     The list of post types available for the community.
     * @param  {string} [defaultType] The default post type to use from the post types list.
     * @return {Object} The first available post type or default if available.
     */
    function getFirstAvailablePostTypes(postTypes, defaultType) {
        defaultType = defaultType || get(postTypes, 'DEFAULT.value');
        postTypes = angular.isObject(postTypes) ? Object.keys(postTypes) : postTypes;

        const key = has(service.availablePostTypes, defaultType) ? defaultType : first(postTypes);

        return get(service.availablePostTypes, key);
    }

    /**
     * Retrieve the best times to organize a meeting.
     *
     * @param  {Object}  params   The request params.
     * @param  {Date}    fromDate The start date used to search the best time.
     * @param  {string}  hours    The number of hours of the meeting.
     * @param  {string}  minutes  The number of minutes of the meeting.
     * @param  {Date}    toDate   The end date used to search the best time.
     * @param  {string}  uid      The identifier of the community.
     * @return {Promise} The response pomise.
     */
    function getSuggestedMeetings(params) {
        return CommunityFactory.planMeeting({ ...params }).$promise;
    }

    /**
     * Get a community user list.
     *
     * @param  {Object}   params     The parameters to send to the request.
     * @param  {Function} cb         The success callback function.
     * @param  {Function} errCb      The error callback function.
     * @param  {string}   listKey    The list key attached to this request.
     * @param  {Object}   projection The list of fields we want to retrieve.
     * @return {Array}    The collection of users.
     */
    function getUserList(params, cb, errCb, listKey, projection) {
        return LumsitesBaseService.proto.prototype.filterize.call(
            service,
            params,
            cb,
            errCb,
            listKey,
            projection,
            'getUserList',
        );
    }

    /**
     * Get the feed keys that can be used within user.list calls.
     * For example, we need to filter out the ALL feed for readOnly communities otherwise we get all users from the
     * instance in the user list dialog.
     *
     * @param  {Object} community A community object to get feedKeys from.
     * @return {Array}  A list of feed keys.
     */
    function getUserListFeedKeys(community) {
        if (angular.isUndefinedOrEmpty(community)) {
            return [];
        }

        if (community.privacy === service.privacyTypes.open) {
            // If the instance of the community is restricted to a specific group, only fetch this group.
            const { restrictToFeeds } = Instance.getInstance();

            return angular.isDefinedAndFilled(restrictToFeeds) ? restrictToFeeds : [Feed.ALL.id];
        }

        /*
         * The `community.feedKeys` array contains the `community.communityFeedKey` which is the feed key
         * refering to manually picked managers/contributors. It also contains the `community.publishers` array
         * containing all groups picked as contributors.
         * To get all the community feed keys, we merge this `community.feedKeys` array with the
         * `community.editors` array which contains all groups picked as managers.
         */
        const feedKeys = without(community.feedKeys || [], Feed.ALL.id);
        const editors = community.editors || [];

        return union(feedKeys, editors);
    }

    /**
     * Extract properties for the CommunityLink component (NGI)
     * and assign them in the 'communityLink' key from the component.
     * Get the instance data for the community.
     * @param {Object} community A community object.
     * @param {boolean}[sync=false] boolean to define the returned method async | sync
     * @return {Promise|Object} Either a promise that will resolve with the CommunityLink, or the CommunityLink itself.
     * */
    function getCommunityLink(community, sync= false) {
        const { instance: instanceId } = community;

        if (instanceId !== Instance.getCurrentInstanceId()) {
            return sync
                ? _getCommunityLinkFromChildSite(community, Instance.getSiblings(sync))
                : Instance.getSiblings(false).then((fetchedSiblings) => _getCommunityLinkFromChildSite(community, fetchedSiblings));
        }

        const communityLink = _getCommunityLink(community);
        return sync ? communityLink : Promise.resolve(communityLink);
    }

    /**
     * Navigate to a community page.
     * This method overwrites the AbstractContentService.goTo method.
     *
     * @param {Object} community A community object to navigate to.
     * @param {boolean} landOnPostsView Should we land on posts view ?
     */
    function goTo(community, landOnPostsView = false) {
        if (angular.isUndefinedOrEmpty(community)) {
            return;
        }

        const blank = community.instance !== Instance.getCurrentInstanceId();

        MainNav.goTo(
            'app.front.community',
            community.id,
            community.instance,
            community.slug,
            landOnPostsView ? -1 : undefined,
            Config.AVAILABLE_CONTENT_TYPES.COMMUNITY,
            blank,
        );
    }

    /**
     * Check if a given community has a privacy of description only or not.
     *
     * @param  {Object}  [community] The community to check the privacy of. Defaults to the current community.
     * @return {boolean} Whether the community is description only or not.
     */
    function isDescriptionOnly(community) {
        community = community || service.getCurrent();

        return get(community, 'privacy') === service.privacyTypes.descriptionOnly;
    }

    /**
     * Check if a user can edit a community (change its settings).
     *
     * @param  {Object}  user                         A user to check the edit permission of.
     * @param  {Object}  [community=currentCommunity] The community to check the editability of.
     *                                                Defaults to the current community.
     * @return {boolean} Whether the user can edit the community or not.
     */
    function isEditableBy(user, community) {
        community = community || service.getCurrent();

        if (angular.isUndefinedOrEmpty([community, user], 'some')) {
            return false;
        }

        return (
            user.isSuperAdmin ||
            User.isInstanceAdmin() ||
            includes(community.adminKeys, user.id) ||
            User.hasSubscriptions(community.editors)
        );
    }

    /**
     * Indicates if the drive folder of the community is a Microsoft One Drive folder or if the folder is not set
     * and if the user is a microsoft user.
     *
     * @param  {Object}  community The community to check if the Drive folder is a Microsoft folder or if
     *                             connected user is a Microsft user.
     * @return {boolean} Whether the drive folder is a Microsoft One drive folder.
     */
    function isOneDriveCommunity(community) {
        community = community || service.getCurrent();

        if (angular.isUndefinedOrEmpty(community)) {
            return false;
        }

        return (
            (angular.isDefinedAndFilled(community.driveFolder) &&
                community.driveFolder.source === MediaConstant.PROVIDERS.microsoft) ||
            (angular.isUndefinedOrEmpty(community.driveFolder) && User.isMicrosoft())
        );
    }

    /**
     * Indicate if the calendar of the community is a Microsoft Outlook calendar or if the folder is not set
     * and if the user is a microsoft user.
     *
     * @param  {Object}  community The community to check if the calender is a Microsoft calendar or if
     *                           connected user is a Microsft user.
     * @return {boolean} Whether the drive folder is a Microsoft One drive folder.
     */
    function isOutlookCommunity(community) {
        community = community || service.getCurrent();

        if (angular.isUndefinedOrEmpty(community)) {
            return false;
        }

        return (
            community.calendarIdpType === 'microsoft' ||
            (angular.isUndefinedOrEmpty(community.calendarIdpType) &&
                User.isMicrosoft() &&
                (community.createCalendar || angular.isDefinedAndFilled(community.calendarId)))
        );
    }

    /**
     * Check if a given community has a privacy of read only or not.
     *
     * @param  {Object}  [community] The community to check the privacy of. Defaults to the current community.
     * @return {boolean} Whether the community is read only or not.
     */
    function isReadOnly(community) {
        community = community || service.getCurrent();

        return (
            get(community, 'privacy') === service.privacyTypes.readOnly ||
            get(community, 'privacy') === service.privacyTypes.descriptionOnly
        );
    }

    /**
     * Returns whether the content is a Community or another type of content
     *
     * @param  {Object} content A content object.
     * @return {boolean} Whether the content is a community.
     */
    function isCommunity(content) {
        content = content || service.getCurrent();

        if (!angular.isDefinedAndFilled(content)) {
            return false;
        }

        const contentType = get(content, 'type');

        return contentType === 'community';
    }

    /**
     * Returns whether the community is a space or a good old community
     *
     * @param  {Object} community A community object.
     * @return {boolean} Whether the community is a space.
     */
    function isSpace(community) {
        community = community || service.getCurrent();

        if (!angular.isDefinedAndFilled(community)) {
            return false;
        }

        const renderingType = get(community, 'renderingType');

        return renderingType === RenderingType.space;
    }

    /**
     * Check if a user is admin of a community.
     *
     * @param  {Object}  community The community.
     * @param  {Object}  user      The user to check.
     * @return {boolean} If user is admin or not.
     */
    function isUserAdmin(community, user) {
        let isEditor, isManager = false
        if (angular.isUndefinedOrEmpty([community, user], 'some')) {
            return false;
        }

        if (angular.isDefinedAndFilled(community.editors)) {
            // Check if community editors ids intersect with user subscriptions ids.
            isEditor = Boolean(intersection(community.editors, map(user.subscriptions, 'feed')).length);
        }
        if (angular.isDefined(user) && has(community, 'adminKeys')) {
            isManager = includes(community.adminKeys, user.id);
        }

        return isEditor || isManager
    }

    /**
     * Check if a user can write posts in a community.
     *
     * @param  {Object}  [community] The community to check the writeability of. Defaults to the current community.
     * @return {boolean} Whether the user can write in the community or not.
     */
    function isWriteable(community) {
        community = community || service.getCurrent();

        if (angular.isUndefinedOrEmpty(community)) {
            return false;
        }

        return community.canContribute;
    }

    /**
     * Fetch the current instance available communities.
     *
     * @param {Object}              [params]     The parameters to apply to the list endpoint call.
     * @param {Function}            [cb]         A callback function to execute on success.
     * @param {Function}            [errCb]      A callback function to execute on error.
     * @param {string}              listKey      The list key identifier.
     * @param {string|Object|Array} [projection] The field we want to get in the result object.
     */
    function listAvailable(params, cb, errCb, listKey, projection) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        const fullParams = angular.extend(
            {},
            {
                customerId: Customer.getCustomerId(),
                instanceId: [Instance.getCurrentInstanceId()],
                lang: Translation.getPreferredContributionLanguage(),
                // We should consider the smallest communities block case.
                maxResults: _MAX_COMMUNITIES_NUMBER,
            },
            params,
        );

        service.filterize(fullParams, cb, errCb, listKey, projection);
    }

    /**
     * Return the list of communty of a certain type.
     *
     * @param  {string}  params     The type of community.
     *                              Can be either 'google', 'microsoft', or 'email'.
     * @param  {string}  listKey    The list key used for this call.
     * @param  {Object}  projection The filedto retrieve.
     * @param  {string}  endpoint   The name of theendpoint to call.
     * @return {Promise} The call promise.
     */
    function listCommunityByType(params, listKey, projection, endpoint) {
        return LumsitesBaseService.proto.prototype.promiseFilterize.call(
            service,
            params,
            listKey,
            projection,
            endpoint,
        );
    }

    /**
     * Save only the layout of the community.
     *
     * @param {Object}   community The current community
     * @param {Function} [cb]      A callback function to be called if the saveLayout call is successful.
     * @param {Function} [errCb]   A callback function to be called if the saveLayout call errors.
     */
    function saveLayout(community, cb, errCb) {
        if (angular.isUndefinedOrEmpty(community)) {
            return;
        }

        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        /*
         * Clear community.userContent just like we do for a Content in abstract-content_service >
         * _formatClientObjectForServer.
         */
        delete community.userContent;

        CommunityFactory.saveLayout(
            community,
            function onPinSuccess(response) {
                cb(response);
            },
            errCb,
        );
    }

    /**
     * Set all notifications as read for either the current community or the current post.
     *
     * @param {string} view The current view of the community page.
     */
    function setAllNotificationsAsRead(view) {
        if (view !== _AVAILABLE_VIEWS.posts.id) {
            return;
        }

        // Clear all notifications for the current community.
        Notification.setAllAsRead(get(service.getCurrent(), 'uid', -1));
    }

    /**
     * Build a new community post status shell.
     *
     * @return {Object} A new empty status.
     */
    function statusCreate() {
        return {
            title: {},
            uuid: generateUUID(),
        };
    }

    /**
     * Return tag uid from a tag.
     *
     * @param {Object}   tag  A tag object.
     * @param {Function} [cb] A function to execute.
     */
    function tagToUid(tag, cb) {
        cb = cb || angular.noop;

        if (angular.isDefined(tag.uid)) {
            cb(tag.uid);
        }
    }

    /**
     * Search for communities for current instance.
     *
     * @param  {Object}  params  Search parameters.
     * @param  {string}  listKey The list to update.
     * @return {Promise} Search response promise.
     */
    async function search(params, listKey) {
        const request = CommunityFactory.search(params).$promise;

        // Clear list, loading state and cursor.
        if (!params.cursor) {
            service.initList(listKey, null, [], false, undefined);
        }
        _searchRequests[listKey] = {
            ..._searchRequests[listKey],
            cursor: null,
            loading: true,
            params,
        };

        const list = service.getList(undefined, undefined, listKey);

        try {
            const response = await request;
            // Update list.
            if (angular.isDefinedAndFilled(response.items)) {
                list.push(...response.items);
            }
            $timeout(() => service.displayList(listKey));
            // Update cursor.
            _searchRequests[listKey].cursor = response.cursor;
        } catch (exception) {
            throw exception;
        } finally {
            // Clear loading state.
            _searchRequests[listKey].loading = false;
        }
    }

    /**
     * Check whether a call is ongoing.
     *
     * @param  {string}  listKey The list key.
     * @param  {string}  method  The method key (ex: 'list', 'search').
     * @return {boolean} true if call is in progress; false otherwise.
     */
    function isCallInProgress(listKey, method) {
        if (listKey in _searchRequests && method === 'search') {
            // Use custom 'search' request loading status.
            return _searchRequests[listKey].loading;
        }

        // Else use default base service implementation.
        return LumsitesBaseService.proto.prototype.isCallInProgress.call(service, listKey, method);
    }

    /**
     * Check whether a call has more elements.
     *
     * @param  {string}  listKey The list key.
     * @return {boolean} true if call has more; false otherwise.
     */
    function hasMore(listKey) {
        if (listKey in _searchRequests) {
            return !isCallInProgress(listKey, 'search') && Boolean(_searchRequests[listKey].cursor);
        }

        // Else use default base service implementation.
        return LumsitesBaseService.proto.prototype.hasMore.call(service, listKey);
    }

    /**
     * Display next page for list.
     *
     * @param {string} listKey  The list to paginate on.
     * @param {string} endpoint The name of the endpoint.
     */
    function displayNextPage(listKey, endpoint) {
        if (listKey in _searchRequests && hasMore(listKey)) {
            const { cursor, params } = _searchRequests[listKey];
            search({ ...params, cursor }, listKey);
        }

        // Else use default base service implementation.
        LumsitesBaseService.proto.prototype.displayNextPage.call(service, listKey, endpoint);
    }
    
    // ///////////////////////////
    
    service.debouncedListAvailable = debounce(listAvailable, _LIST_COMMUNITIES_DEBOUNCE_DELAY);
    service.categoryCreate = categoryCreate;
    service.displayNextPage = displayNextPage;
    service.editTemplate = editTemplate;
    service.getAvailableViews = getAvailableViews;
    service.getDefaultLandingView = getDefaultLandingView;
    service.getFirstAvailablePostTypes = getFirstAvailablePostTypes;
    service.getSuggestedMeetings = getSuggestedMeetings;
    service.getUserList = getUserList;
    service.getUserListFeedKeys = getUserListFeedKeys;
    service.getCommunityLink = getCommunityLink;
    service.goTo = goTo;
    service.hasMore = hasMore;
    service.isCallInProgress = isCallInProgress;
    service.isDescriptionOnly = isDescriptionOnly;
    service.isEditableBy = isEditableBy;
    service.isReadOnly = isReadOnly;
    service.isOneDriveCommunity = isOneDriveCommunity;
    service.isOutlookCommunity = isOutlookCommunity;
    service.isSpace = isSpace;
    service.isCommunity = isCommunity;
    service.isUserAdmin = isUserAdmin;
    service.isWriteable = isWriteable;
    service.listAvailable = listAvailable;
    service.listCommunityByType = listCommunityByType;
    service.saveLayout = saveLayout;
    service.search = search;
    service.setAllNotificationsAsRead = setAllNotificationsAsRead;
    service.statusCreate = statusCreate;
    service.tagToUid = tagToUid;

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    service.initService = function initService() {
        angular.forEach(service.availablePostTypes, function forEachPostType(postTypeObject, type) {
            postTypeObject.value = InitialSettings.POST_TYPES[type];
        });

        service.init();
    };

    // ///////////////////////////

    return service;
}

// ///////////////////////////

angular.module('Services').service('Community', CommunityService);

// ///////////////////////////

export { CommunityService };
