import { ButtonProps } from '@lumapps/lumx/react';
import { matchPath, Route, useHistory } from '@lumapps/router';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { usePreviousPath } from '@lumapps/router/hooks/usePreviousPath';
import { replacePathWithParamsForRoute } from '@lumapps/router/utils';

interface UseBackButtonProps {
    /**
     * Route to redirect to when clicking on the button.
     * If undefined, the button will redirect to the previous entry of the browser's history
     * */
    defaultRoute?: Route;
    /**
     * Array of routes to redirect to if it was the previous route.
     * If undefined or none matches, uses the goTo route.
     * */
    goToIfPrevious?: Route[];
}

/**
 * Hook to manage the "go back" button
 */
const useBackButton = ({ defaultRoute, goToIfPrevious = [] }: UseBackButtonProps = {}): Partial<ButtonProps> => {
    const history = useHistory();
    const previousPath = usePreviousPath();
    let route = defaultRoute;

    /**
     * For each given routes, check if it matches the previous route
     */
    if (previousPath) {
        goToIfPrevious.forEach((overrideRoute) => {
            // Build the route path with the params.
            const pathWithParams = replacePathWithParamsForRoute(overrideRoute);
            // check if the previous route matches the override route.
            const match = matchPath(previousPath, {
                path: pathWithParams,
            });

            // if it does, set it as the route to redirect to.
            if (match) {
                route = { ...overrideRoute, ...match };
            }
        });
    }

    /**
     * If no specific route is defined, go back to previous history
     * page.
     */
    if (!route) {
        return {
            onClick: history.goBack,
        };
    }

    return {
        linkAs: RouterLink,
        to: route,
    };
};

export { useBackButton };
