import { Point, ReactEditor } from '@lumapps/wrex/slate';
import { insertVoidBlock } from '@lumapps/wrex/slate/utils/insertVoidBlock';

import type { PlayVideoEditor, PlayVideoElement } from '../types';
import { createPlayVideo } from './createPlayVideo';

/**
 * Insert video after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertPlayVideo(
    editor: ReactEditor & PlayVideoEditor,
    link: Partial<PlayVideoElement>,
    point?: Point | undefined,
) {
    const node = createPlayVideo({
        id: link.id || '',
        title: link.title || '',
        url: link.url || '',
    });

    const path = insertVoidBlock(editor, node, point);

    return path;
}
