/* eslint-disable no-param-reassign */
import {
    isNewInterfaceRoutingEnabled as isNewInterfaceRoutingEnabledSelector,
} from '@lumapps/content-layout/ducks/selectors';
import {
    isLayoutEnabled as isLayoutEnabledSelector,
} from '@lumapps/widget-layout/ducks/selectors';
import { contentView } from '@lumapps/contents/routes';
import { angularApi } from '@lumapps/router/routers';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
/* eslint-disable no-undef */
import get from 'lodash/get';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';

import { programDashboardRoute } from '@lumapps/sa-programs/routes';
import { URL_PREFIX } from '@lumapps/router/constants';

import { getTemplatePath, provideTemplate, resolvePromise, PERMISSIONS } from 'common/utils/states-utils';
import { publicModeEnabled as isSocialAdvocacyPublicPage } from 'components/components/social-advocacy/utils';

import { PERMISSIONS as SA_PERMISSIONS } from 'components/components/social-advocacy/constants';

import { USER_SPACE_FEATURE_TOKEN } from '@lumapps/user-profile/constants';

// ///////////////////////////
//                         //
//     Public functions    //
//                         //
// ///////////////////////////

/**
 * Define the states for the Front-Office.
 *
 * @param {Provider} $stateProvider The state provider.
 */
const setup = ($stateProvider) => {
    $stateProvider
        .state('app.front', {
            abstract: true,
            resolve: {
                resolveLoadingFront(resolveInitialSetting) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveInitialSetting);

                    return true;
                },
            },
            views: {
                footer: {
                    controller: 'FooterController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, ConfigTheme, Instance) {
                        'ngInject';

                        const templatePath = getTemplatePath(
                            Instance,
                            'layout/modules/footer/views/footer.html',
                            ConfigTheme.HAS_CUSTOM_FOOTER,
                            'common',
                        );
                        if (isSocialAdvocacyPublicPage()) {
                            return null;
                        }

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
                header: {
                    controller: 'HeaderController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, ConfigTheme, Instance) {
                        'ngInject';

                        const templatePath = getTemplatePath(
                            Instance,
                            'layout/modules/header/common/views/header.html',
                            ConfigTheme.HAS_CUSTOM_HEADER,
                            'common',
                        );

                        if (isSocialAdvocacyPublicPage()) {
                            return null;
                        }

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
                main: {
                    controller: 'FrontController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, Content, ConfigTheme, Instance) {
                        'ngInject';

                        let templatePath;
                        if (isSocialAdvocacyPublicPage()) {
                            templatePath = getTemplatePath(
                                Instance,
                                'layout/modules/front/views/front-social-advocacy-public-template.html',
                                ConfigTheme.HAS_CUSTOM_FRONT,
                            );
                        } else {
                            templatePath = getTemplatePath(
                                Instance,
                                'layout/modules/front/views/front.html',
                                ConfigTheme.HAS_CUSTOM_FRONT,
                            );
                        }

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
            },
        })
        .state('app.front.profile', {
            params: {
                subscriptionType: {
                    squash: true,
                    value: null,
                },
                view: {
                    squash: true,
                    value: null,
                },
            },
            reloadOnSearch: false,
            resolve: {
                resolveContent(
                    $state,
                    $stateParams,
                    $timeout,
                    SocialProfile,
                    User,
                    UserDirectoryFactory,
                    UserDirectory,
                    resolveLoadingFront,
                ) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    if (angular.isUndefinedOrEmpty($stateParams.userIdentifier)) {
                        $timeout(function timeout() {
                            $state.go('app.front.404');
                        });

                        return false;
                    }

                    // If user space is enabled and the view is migrated with corresponding FF enabled, redirect to new User Profile
                    if (UserDirectory.shouldRedirectToV2Profile($stateParams.view)) {
                        UserDirectory.redirectToV2Profile($stateParams.userIdentifier, $stateParams.view);
                        return false;
                    }

                    const userId =
                        $stateParams.userIdentifier === 'me'
                            ? get(User.getConnected(), 'uid')
                            : $stateParams.userIdentifier;
                    const contentId = UserDirectory.getDefaultUserDirectory(User.getConnected()).id;

                    return resolvePromise(
                        UserDirectoryFactory.get(
                            {
                                contentId,
                                uid: userId,
                            },
                            function onUserDirectoryGetSuccess(response) {
                                // Set profile user.
                                SocialProfile.setCurrent(response);
                            },
                            function onUserDirectoryGetError() {
                                // Just to be sure.
                                SocialProfile.setCurrent(undefined);
                            },
                        ),
                    );
                },
                resolveLoading(Settings, resolveContent) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveContent);

                    Settings.endLoading();

                    return true;
                },
            },
            url: `/${URL_PREFIX}/profile/:userIdentifier/:view/:subscriptionType`,
            views: {
                content: {
                    controller: 'SocialProfileController',
                    controllerAs: 'vm',
                    // If social profile v2 flag (=user-space) is enabled, switch to the "legacy" angular v2 view
                    templateProvider($http, $templateCache, Features) {
                        'ngInject';

                        const templatePath = `/client/front-office/modules/social/profile/views/social-profile${
                            Features.hasFeature(USER_SPACE_FEATURE_TOKEN) ? '-v2' : ''
                        }.html`;
                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
            },
        })
        .state('app.front.404', {
            resolve: {
                resolveLoading(Settings, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/404',
            views: {
                content: {
                    templateUrl: '/client/front-office/modules/error-pages/views/404.html',
                },
            },
        })
        .state('app.front.community', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'get',
                designer: false,
            },
            params: {
                identifier: {
                    squash: true,
                    value: null,
                },
                slug: {
                    value: null,
                },
                view: {
                    squash: true,
                    value: null,
                },
            },
            reloadOnSearch: false,
            resolve: {
                resolveContent(
                    $q,
                    $stateParams,
                    Analytics,
                    Community,
                    CommunityConstant,
                    Content,
                    Customer,
                    Instance,
                    Settings,
                    Translation,
                    User,
                    resolveLoadingFront,
                ) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    let promise;
                    const currentContent = Content.getCurrent();

                    if (Settings.is.firstContentLoader && angular.isDefinedAndFilled(currentContent)) {
                        Content.setViewMode('locked');
                        Content.setAction('get');

                        Community.setViewMode('default');
                        Community.setCurrent(currentContent);
                        promise = $q.resolve(currentContent);

                        // Resets `firstContentLoader` to correctly get content asynchronously from now.
                        Settings.is.firstContentLoader = false;
                    } else {
                        let slug = '';
                        const projection = angular.extend(angular.fastCopy(CommunityConstant.PROJECTION.GET), {
                            comments: true,
                            likes: true,
                        });

                        if (angular.isDefinedAndFilled($stateParams.slug)) {
                            const fullSlug = $stateParams.slug.split('/');
                            slug = fullSlug[fullSlug.length - 1];
                        }

                        const params = {
                            action: 'PAGE_READ',
                            lang: angular.isDefinedAndFilled($stateParams.hl)
                                ? $stateParams.hl
                                : Translation.getLang('current'),
                            slug,
                        };

                        const instanceId = Instance.getCurrentInstanceId();
                        if (angular.isDefinedAndFilled(instanceId)) {
                            params.instance = instanceId;
                        } else {
                            let customerSlug = Customer.getCustomerSlug(true);
                            const propertyName = customerSlug.isDomain ? 'customerHost' : 'customerSlug';
                            customerSlug = customerSlug.slug;

                            params[propertyName] = customerSlug;
                            params.instanceSlug = Instance.getCurrentInstanceSlug();
                        }

                        promise = Community.getAsync(
                            params,
                            function onCommunityGetSuccess() {
                                Content.setViewMode('locked');
                                Content.setAction('get');

                                Community.setViewMode('default');
                                Content.setCurrent(Community.getCurrent());
                            },
                            undefined,
                            undefined,
                            projection,
                        ).$promise;
                    }

                    if (promise) {
                        Analytics.handleTaggingMap('read-content', undefined, {
                            content: promise,
                            user: User.getConnected(),
                        });

                        return promise;
                    }

                    return false;
                },
                resolveLoading(Settings, resolveContent) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveContent);

                    Settings.endLoading();

                    return true;
                },
            },
            url: `/${URL_PREFIX}/community/:slug/:view/:identifier`,
            views: {
                content: {
                    controller: 'CommunityController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/communities/community/views/community.html',
                },
            },
        })
        .state('app.front.old-community', {
            params: {
                identifier: {
                    squash: true,
                    value: null,
                },
                slug: {
                    value: null,
                },
                type: {
                    value: null,
                },
                view: {
                    squash: true,
                    value: null,
                },
            },
            redirectTo: 'app.front.community',
            resolve: {
                resolveLoading(Settings, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    Settings.endLoading();

                    return true;
                },
            },
            url: `/${URL_PREFIX}/{type:group|workspace}/:slug/:view/:identifier`,
        })
        .state(programDashboardRoute.legacyId, {
            params: {
                permissions: [PERMISSIONS.superAdmin, SA_PERMISSIONS.CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES],
                slug: {
                    value: 'default',
                },
                view: {
                    value: 'feed',
                    squash: true,
                },
            },
            resolve: {
                resolveLoading: function resolveLoading(Settings, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    Settings.endLoading();

                    return true;
                },
            },
            url: programDashboardRoute.path,
            views: {
                content: {
                    controller: 'SocialAdvocacyController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/social-advocacy/views/social-advocacy-dashboard.html',
                },
            },
        })
        .state('app.front.content-get', {
            // eslint-disable-next-line id-blacklist
            data: {
                action: 'get',
                designer: false,
            },
            params: {
                customData: null,
                slug: {
                    squash: true,
                    value: null,
                },
            },
            resolve: {
                resolveContent(
                    $q,
                    $stateParams,
                    $location,
                    Analytics,
                    Content,
                    Customer,
                    Instance,
                    ReduxStore,
                    Settings,
                    Translation,
                    User,
                    resolveLoadingFront,
                ) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveLoadingFront);

                    const isFirstContentLoader = Settings.is.firstContentLoader;
                    const fullSlug = $stateParams.slug;

                    let promise;

                    if (Settings.is.firstContentLoader && angular.isDefinedAndFilled(Content.getCurrent())) {
                        promise = $q.resolve(Content.getCurrent());

                        // Resets `firstContentLoader` to correctly get content asynchronously from now.
                        Settings.is.firstContentLoader = false;
                    } else {
                        let slug;

                        if (angular.isDefinedAndFilled(fullSlug)) {
                            const fullSlugArray = fullSlug.split('/');
                            slug = fullSlugArray[fullSlugArray.length - 1];
                        } else {
                            slug = '';
                        }

                        const params = {
                            action: 'PAGE_READ',
                            lang: angular.isDefinedAndFilled($stateParams.hl)
                                ? $stateParams.hl
                                : Translation.getLang('current'),
                            slug,
                        };

                        const instanceId = Instance.getCurrentInstanceId();
                        if (angular.isDefinedAndFilled(instanceId)) {
                            params.instance = instanceId;
                        } else {
                            let customerSlug = Customer.getCustomerSlug(true);
                            const propertyName = customerSlug.isDomain ? 'customerHost' : 'customerSlug';
                            customerSlug = customerSlug.slug;

                            params[propertyName] = customerSlug;
                            params.instanceSlug = Instance.getCurrentInstanceSlug();
                        }

                        promise = Content.getAsync(params).$promise;
                    }

                    // Intercept loaded content to try redirect to the V2 page when possible.
                    const state = ReduxStore.store.getState();
                    const isLayoutEnabled = isLayoutEnabledSelector(state);
                    const isNewInterfaceRoutingEnabled = isNewInterfaceRoutingEnabledSelector(state);
                    if (!isFirstContentLoader && isNewInterfaceRoutingEnabled && isLayoutEnabled) {
                        promise = promise.then((content) => {
                            const {
                                id,
                                slug,
                                template: { isV2Compatible },
                            } = content;
                            const isHomePage = id === getCurrentInstance(state)?.homePage;
                            // Skip content not V2.
                            if (!isV2Compatible) {
                                return content;
                            }

                            // Else redirect to V2 page.
                            angularApi.redirect(
                                contentView({
                                    isLayoutEnabled,
                                    to: {
                                        id,
                                        slug: angular.isDefinedAndFilled(fullSlug) ? fullSlug : Translation.translate(slug),
                                        isHomePage,
                                        isV2Compatible,
                                        keepParentPath: true,
                                        // pass query params to the redirected, useful for filtered url
                                        query: $location.search(),
                                    },
                                }),
                            );
                            return content;
                        });
                    }

                    if (angular.isObject(promise)) {
                        Analytics.handleTaggingMap('read-content', undefined, {
                            content: promise,
                            user: User.getConnected(),
                        });

                        return promise;
                    }

                    return false;
                },
                resolveDirectory(Content, Directory, InitialSettings, resolveContent) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveContent);

                    if (Content.getCurrent().type === InitialSettings.CONTENT_TYPES.DIRECTORY) {
                        const promise = Directory.get(
                            {
                                uid: Content.getCurrent().externalKey,
                            },
                            function directoryGetSuccess(response) {
                                Directory.setCurrent(response);
                            },
                        );
                        return resolvePromise(promise);
                    }
                    return true;
                },
                // eslint-disable-next-line sort-keys
                resolveContentProcessing(
                    $state,
                    Content,
                    ContentTemplate,
                    InitialSettings,
                    Translation,
                    resolveContent,
                ) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveContent);

                    const currentContent = Content.getCurrent();
                    if (currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
                        $state.go('app.front.community', {
                            slug: Translation.translate(currentContent.slug),
                            type: currentContent.type,
                        });

                        return false;
                    }

                    ContentTemplate.applyUserContent(currentContent);

                    return true;
                },
                resolveCustomContentType: angular.noop,
                resolveLoading(Settings, resolveDirectory, resolveLoadingFront) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveDirectory, resolveLoadingFront);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/{slug:path}?group',
            views: {
                content: {
                    controller: 'ContentFrontController',
                    controllerAs: 'vm',
                    templateUrl: '/client/front-office/modules/content/common/views/content-front.html',
                },
            },
        });
};

export { setup };
