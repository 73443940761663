import React from 'react';

import { CONTENT_TYPES } from '@lumapps/content-types/keys';
import { useDataAttributes } from '@lumapps/data-attributes';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiTablet, mdiViewQuilt } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { usePaginatedFetchWithStatus } from '@lumapps/utils/hooks/usePaginatedFetchWithStatus';

import { list, listCancel } from '../../api';
import { contentCreation, contentCreationCustomTemplate } from '../../routes';
import { Template } from '../../types';
import { FIELDS, MAX_RESULTS } from './constants';

export interface TemplateMenuProps {
    /** Id of the content type owning the templates. Retrieved from the store. */
    contentTypeId: string;
    /** whether the defaul template option is enabled for this type of template */
    defaultTemplateEnabled: boolean;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
}

/**
 * Menu of all avaible content template to create content from.
 */
export const TemplateMenu: React.FC<TemplateMenuProps> = (props) => {
    const { contentTypeId, defaultTemplateEnabled, scope } = props;
    const { get } = useDataAttributes(scope);
    const { translateKey, translateObject } = useTranslate();

    const lang = useSelector(currentLanguageSelector);
    const instance = useSelector(instanceIdSelector);

    const params = React.useMemo(
        () => ({
            fields: FIELDS,
            customContentType: contentTypeId,
            instance,
            lang,
            maxResults: MAX_RESULTS,
            showTemplatesWithInheritedOnlyParam: true,
        }),
        [contentTypeId, instance, lang],
    );

    const { status, fetch, cancelFetch, response } = usePaginatedFetchWithStatus({
        onFetch: list,
        onFetchCancel: listCancel,
    });

    React.useEffect(() => {
        // Fetch on mount
        fetch({ params });
        // Cancel on unmount
        return () => cancelFetch();
    }, [cancelFetch, fetch, params]);

    React.useEffect(() => {
        const cursor = response?.cursor;
        if (!cursor) {
            return;
        }
        // Fetch more
        fetch({ params: { ...params, cursor }, fetchMore: true });
    }, [fetch, params, response?.cursor]);

    const templateList = response?.items as Template[];

    if (status === 'loading' || status === 'initial') {
        return <Menu.ItemsSkeleton hasIcon itemCount={3} loadingMessage={translateKey(GLOBAL.LOADING)} />;
    }

    return (
        <>
            {Object.values(templateList).map((template) => (
                <Menu.Item
                    key={template.id}
                    icon={mdiViewQuilt}
                    as={RouterLink}
                    to={contentCreationCustomTemplate(contentTypeId, template.id)}
                    {...get({ element: contentTypeId, action: 'template', type: template.id })}
                >
                    {translateObject(template.name)}
                </Menu.Item>
            ))}

            {defaultTemplateEnabled && templateList?.length > 0 && <Menu.Divider />}

            {defaultTemplateEnabled && (
                <Menu.Item
                    icon={mdiTablet}
                    as={RouterLink}
                    to={contentCreation(contentTypeId)}
                    {...get({ element: contentTypeId, action: 'template', type: 'empty' })}
                >
                    {translateKey(CONTENT_TYPES.CREATE_FROM_SCRATCH)}
                </Menu.Item>
            )}
        </>
    );
};
