import { useCallback } from 'react';

import mapKeys from 'lodash/fp/mapKeys';
import omit from 'lodash/fp/omit';
import snakeCase from 'lodash/fp/snakeCase';

import { paramsParser, paramsSerializer } from '@lumapps/base-api';
import { useHistory, useLocation } from '@lumapps/router';

/**
 * Update the URL with the filters object passed as parameter
 * The generated URL format is the following: ?filters[filter1]=value1&filters[filter1]=value2&filters[filter2]=value3
 * It can optionally be disabled through its options
 * @return a callback that will update the URL with the provided filters as parameters
 */

export const useUpdateWidgetURLFilters = () => {
    const { replace: historyReplace } = useHistory();
    const { pathname, search } = useLocation();

    const updateURLFilters = useCallback(
        (currentFilters: Record<string, any>) => {
            // eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
            const otherParams = omit(['filters'], paramsParser(search, { ignoreQueryPrefix: true }));

            const nextSearch = paramsSerializer(
                { ...otherParams, filters: mapKeys(snakeCase, currentFilters) },
                { addQueryPrefix: false, encode: false, indices: false },
            );

            historyReplace({ pathname, search: nextSearch });
        },
        [historyReplace, pathname, search],
    );

    return { updateURLFilters };
};
