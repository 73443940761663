import { useMemo } from 'react';

import pick from 'lodash/pick';

import { useDataAttributes, Attributes } from '@lumapps/data-attributes';
import { Route } from '@lumapps/router';

export interface SideBarNavigationDataAttributesOptions {
    route?: Route;
    alternativeId?: string;
    isSection?: boolean;
}

/**
 * Specific hook to retrieve data attributes for a side bar navigation.
 * We need to do some cleanup to the legacy id in order to make it a little bit
 * prettier when printing the id. We remove all '.', as well as the preffixes app and admin.
 *
 * Also, if the URL is dynamic, we want to add an id at the end, so we try to retrieve it from the
 * route.params property. If there is something like that, we add it at the end of the id.
 */
const useSideBarNavigationDataAttributes = (options: SideBarNavigationDataAttributesOptions): Attributes => {
    const { route, alternativeId, isSection = false } = options;
    const { get } = useDataAttributes('side-nav');

    const dataAttributes = useMemo(() => {
        let element = alternativeId;
        let params = '';

        if (route) {
            element = route.legacyId?.replace(/\./g, '').replace('app', '').replace('admin', '');

            if (route.params) {
                const ids = Object.values(pick(route.params, ['id', 'uid'])) as string[];

                if (ids.length > 0) {
                    [params] = ids;
                }
            }
        }

        return get({
            element,
            action: `${params ? `${params}-` : ''}${isSection ? 'section' : 'link'}`,
        });
    }, [route, alternativeId, get, isSection]);

    return dataAttributes;
};

export { useSideBarNavigationDataAttributes };
