import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiFormatListText } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { MOBILE_NAVIGATION } from '../../keys';
import { adminMobileNavigationRoute } from '../../routes';

export interface MobileNavigationAdministrationSideNavigationProps {
    isAllowed: boolean;
}

const MobileNavigationAdministrationSideNavigation = ({
    isAllowed,
}: MobileNavigationAdministrationSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(MOBILE_NAVIGATION.TITLE)}
            icon={mdiFormatListText}
            route={adminMobileNavigationRoute}
        />
    );
};

MobileNavigationAdministrationSideNavigation.displayName = 'SideNavigationItem';

export { MobileNavigationAdministrationSideNavigation };
