import React from 'react';

import { BaseSearchResult } from '@lumapps/search/types';
import { getAvatarUrl } from '@lumapps/user/utils/getUserProfilePicture';

import {
    scoreGenerator,
    timestampGenerator,
    getMetadataTimestampLabel,
    likesGenerator,
    commentsGenerator,
    attachmentsGenerator,
} from './generators';
import { ContentMetadataPropsOptions } from './types';

/**
 * List of generators that will retrieve information from the result and transform
 * them into text or React nodes that will be used inside the ContentMetadata component
 */
const metadataGenerators: ((
    result: BaseSearchResult,
    options: ContentMetadataPropsOptions,
) => string | React.ReactNode)[] = [
    timestampGenerator,
    scoreGenerator,
    likesGenerator,
    commentsGenerator,
    attachmentsGenerator,
];

const props = (result: BaseSearchResult, options: ContentMetadataPropsOptions) => {
    const { isAuthorVisible } = options;
    const { article, post } = result;
    const { authorDetails } = article || post || {};
    const { fullName, id, customerId, customer } = authorDetails || {};
    const metadataToDisplay: Array<string | React.ReactNode> = [];

    metadataGenerators.forEach((metadataGenerator) => {
        const possibleMetadata = metadataGenerator(result, options);

        if (possibleMetadata) {
            metadataToDisplay.push(possibleMetadata);
        }
    });

    const cust = (customerId || customer) as string;

    return {
        name: fullName,
        // set to undefined to let the component decide if we should use a default avatar or not display it
        image: isAuthorVisible && id && cust ? getAvatarUrl(id, cust) : undefined,
        metadata: metadataToDisplay,
        authorId: id,
        isAuthorVisible,
    };
};

export { props, getMetadataTimestampLabel };
