import React from 'react';

import { Icon } from '@lumapps/lumx/react';

import { string } from 'prop-types';
import classNames from 'classnames';

const SASocialHeader = ({ socialPlatform, icon }) => {
    return (
        <div className={classNames('sa-social-header', `sa-social-header--${socialPlatform}`)}>
            <span className="sa-social-header__logo-wrapper">
                {icon && <Icon className="sa-social-header__logo" icon={icon} size="s" />}
            </span>
            <span className="sa-social-header__rectangle-wrapper">
                <span className="sa-social-header__rectangle" />
            </span>
            <span className="sa-social-header__ovals">
                <span className="sa-social-header__oval" />
                <span className="sa-social-header__oval" />
                <span className="sa-social-header__oval" />
            </span>
        </div>
    );
};

SASocialHeader.propTypes = {
    icon: string.isRequired,
    socialPlatform: string.isRequired,
};

export { SASocialHeader };
