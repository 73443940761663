/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';
import type { WidgetReference } from '@lumapps/widget-layout/types';

import { Widget, Blocks, isSingleContainerBlock, isArrayContainerBlock } from '../types';
import { getUpdatedWidgetState } from '../utils/getUpdatedWidgetState';
import { domain } from './domain';
import { WidgetDomainState, WidgetState } from './type';

export const initialState: WidgetDomainState = {
    entities: {},
    translationEntities: {},
};

export const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        setWidgets(
            state: WidgetDomainState,
            action: PayloadAction<{ preloadedWidgets: WidgetState[]; widgetRefs?: WidgetReference[] }>,
        ) {
            const {
                payload: { preloadedWidgets, widgetRefs = [] },
            } = action;
            state.entities = keyBy(
                [
                    ...map(preloadedWidgets, (widget) => ({ ...widget, state: getUpdatedWidgetState(widget) })),
                    ...widgetRefs,
                ],
                'widgetId',
            );
        },
        setWidget(state: WidgetDomainState, action: PayloadAction<WidgetState>) {
            const { payload: widget } = action;
            const previousWidgetState = state.entities[widget.widgetId];
            const filters = previousWidgetState?.filters;
            const legacyWidget = previousWidgetState?.legacyWidget;
            const isMainWidget = previousWidgetState?.isMainWidget;

            state.entities[widget.widgetId] = {
                ...(filters ? { filters } : {}),
                ...widget,
                state: 'loaded',
                // Keep the legacy widget
                legacyWidget,
                // Keep isMainWidget
                isMainWidget,
            };
        },
        setWidgetProperties(
            state: WidgetDomainState,
            action: PayloadAction<{ widgetId: string; widgetProperties: Partial<WidgetState> }>,
        ) {
            state.entities[action.payload.widgetId] = mergeObjectOnly(
                state.entities[action.payload.widgetId],
                action.payload.widgetProperties,
            ) as Widget;
        },
        setWidgetFilters(
            state: WidgetDomainState,
            action: PayloadAction<{ widgetId: string; filters: Record<string, any> }>,
        ) {
            if (action.payload.widgetId) {
                set(state, ['entities', action.payload.widgetId, 'filters'], action.payload.filters);
            }
        },
        setBlockProperties(
            state: WidgetDomainState,
            action: PayloadAction<{ widgetId: string; properties: Record<string, any>; path: (string | number)[] }>,
        ) {
            const block = get(state.entities[action.payload.widgetId], action.payload.path);
            Object.assign(block, action.payload.properties);
        },
        appendWidgetPage(
            state: WidgetDomainState,
            action: PayloadAction<{ widgetId: string; nextItems?: Blocks[]; cursor?: string; more: boolean }>,
        ) {
            const widget = state.entities[action.payload.widgetId];
            const { body } = widget;
            const listContainer = body && isSingleContainerBlock(body) ? body.container : body;
            if (action.payload.nextItems && listContainer && isArrayContainerBlock(listContainer)) {
                listContainer.items.push(...action.payload.nextItems);
            }
            widget.cursor = action.payload.cursor;
            widget.more = action.payload.more;
        },
        setTranslationEntity(
            state: WidgetDomainState,
            action: PayloadAction<{ widgetId: string; widget: Partial<WidgetState> }>,
        ) {
            const { widgetId, widget } = action.payload;
            state.translationEntities[widgetId] = widget;
        },
        reset: () => initialState,
    },
});
