import { GLOBAL } from '@lumapps/translations';

import { PostType } from './types';

export enum POSTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447140749
     */
    ARTICLE_INFO = 'POSTS.ARTICLE_INFO',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452535
     */
    EVENT_FROM_DATE = 'COMMUNITY_POST_EVENT_FROM_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452537
     */
    EVENT_TO_DATE = 'COMMUNITY_POST_EVENT_TO_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=445604130
     */
    BACK_TO_POST = 'POSTS.BACK_TO_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=445609885
     */
    CONVERSION_MESSAGE = 'POSTS.CONVERSION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444724563
     */
    CONVERT_TO_ARTICLE = 'POSTS.CONVERT_TO_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962680
     */
    CREATE_A_POST = 'FRONT.COMMUNITY.POST.CREATE.TYPE.DEFAULT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452604
     */
    COMMUNITY_STATUS_PREFIX = 'COMMUNITY_STATUS_PREFIX',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452606
     */
    COMMUNITY_TAG_ADD = 'COMMUNITY_TAG_ADD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453382
     */
    POST_DESCRIPTION = 'POST_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447140699
     */
    PROPOSAL_CONVERT_TO_ARTICLE = 'POSTS.PROPOSAL_CONVERT_TO_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=6975642
     */
    DELETE_ALL = 'FRONT.ATTACHMENTS.DELETE_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=33220258
     */
    WARNING_UNFINISHED_EDIT = 'FRONT.COMMUNITY.POST.WARNING.UNFINISHED_EDIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74749897
     */
    TRANSLATE_ACTION = 'FRONT.AUTO_TRANSLATION.TRANSLATE_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95898123
     */
    CONFIRM_DIALOG_TITLE_DELETE_ARTICLE = 'FRONT.COMMUNITY.POST.CONFIRM_DIALOG.TITLE.DELETE_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95898177
     */
    CONFIRM_DIALOG_BUTTONS_DELETE_ARTICLE = 'FRONT.COMMUNITY.POST.CONFIRM_DIALOG.BUTTONS.DELETE_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95898362
     */
    CONFIRM_DIALOG_BODY_DELETE_ARTICLE = 'FRONT.COMMUNITY.POST.CONFIRM_DIALOG.BODY.DELETE_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101357789
     */
    CONFIRM_DIALOG_TITLE_DELETE_EVENT = 'FRONT.COMMUNITY.POST.CONFIRM_DIALOG.TITLE.DELETE_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101357822
     */
    CONFIRM_DIALOG_BUTTONS_DELETE_EVENT = 'FRONT.COMMUNITY.POST.CONFIRM_DIALOG.BUTTONS.DELETE_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=101357848
     */
    CONFIRM_DIALOG_BODY_DELETE_EVENT = 'FRONT.COMMUNITY.POST.CONFIRM_DIALOG.BODY.DELETE_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105079760
     */
    COMMUNITY_STATUS_ADD = 'COMMUNITY_STATUS_ADD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=129473353
     */
    OPEN_TYPE_OPTIONS = 'OPEN_TYPE_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169165744
     */
    ENTER_URL = 'FRONT.ENTER_VIDEO_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58237521
     */
    ATTACHMENTS_OR_LINK_WARNING = 'FRONT.ATTACHMENTS.ATTACHMENTS_OR_LINK_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=6975639
     */
    ATTACHMENTS_ADD_LINKS = 'FRONT.ATTACHMENTS.ADD_LINKS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452531
     */
    POST_DELETE_SUCCESS = 'COMMUNITY_POST_DELETE_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=231550499
     */
    DIALOG_DROP_UPLOAD = 'POSTS.DIALOG_DROP_UPLOAD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453390
     */
    POST_MOVE_SUCCESS = 'POST_MOVE_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30484852
     */
    POST_PUBLISHED = 'FRONT.COMMUNITY.NEW_POST.PUBLISHED',
    // eslint-disable-next-line lumapps/no-duplicate-translate-key-url
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452604
     */
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    WITH_STATUS = 'COMMUNITY_STATUS_PREFIX',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453391
     */
    POST_STATUS = 'POST_STATUS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453824
     */
    VOTE = 'VOTE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453825
     */
    VOTES = 'VOTES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546515519
     */
    UPVOTE = 'POST_UPVOTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533529991
     */
    PLURALIZED_UPVOTES = 'POST_PLURALIZED_UPVOTES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546515485
     */
    DOWNVOTE = 'POST_DOWNVOTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=533530362
     */
    PLURALIZED_DOWNVOTES = 'POST_PLURALIZED_DOWNVOTES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54691669
     */
    NEW_POST = 'FRONT.CONTRIBUTION_MENU.NEW_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551316747
     */
    NEW_IDEA = 'FRONT.CONTRIBUTION_MENU.NEW_IDEA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551316756
     */
    NEW_QUESTION = 'FRONT.CONTRIBUTION_MENU.NEW_QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96006365
     */
    NEW_EVENT = 'FRONT.EVENT.DIALOG.NEW_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=144395818
     */
    EDIT_POST = 'COMMUNITY_POST.EDIT_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551328400
     */
    EDIT_IDEA = 'COMMUNITY_POST.EDIT_IDEA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551332574
     */
    EDIT_QUESTION = 'COMMUNITY_POST.EDIT_QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193770664
     */
    EDIT_EVENT = 'FRONT.EVENT.DIALOG.EDIT_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962752
     */
    POST_CREATE_TYPE_IDEA = 'FRONT.COMMUNITY.POST.CREATE.TYPE.IDEA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962728
     */
    POST_CREATE_TYPE_EVENT = 'FRONT.COMMUNITY.POST.CREATE.TYPE.EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962759
     */
    POST_CREATE_TYPE_QUESTION = 'FRONT.COMMUNITY.POST.CREATE.TYPE.QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27770298
     */
    POST_TYPE_QUESTION = 'COMMUNITY_POST_TYPE_QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228647476
     */
    POST_TYPE_EVENT = 'COMMUNITY_POST_TYPE_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228647478
     */
    POST_TYPE_IDEA = 'COMMUNITY_POST_TYPE_IDEA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228647474
     */
    POST_TYPE = 'COMMUNITY_POST_TYPE_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523955379
     */
    POST_ANALYTICS = 'POSTS.POST_ANALYTICS',
}

export const getCommunityPostType = (type: string) => `COMMUNITY_POST_TYPE_${type}`;

export const POST_TITLE_PLACEHOLDER_BY_TYPE: { [key in PostType]: string } = {
    [PostType.DEFAULT]: GLOBAL.TITLE,
    [PostType.IDEA]: GLOBAL.TITLE,
    [PostType.QUESTION]: GLOBAL.TITLE,
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38212639
     */
    [PostType.EVENT]: 'FRONT.COMMUNITY.POST.TITLE_PLACEHOLDER.EVENT',
};
