import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Link as LinkUI, Text, Theme } from '@lumapps/lumx/react';
import { useMetadataLink } from '@lumapps/metadata/hooks/useMetadataLink';
import { Link } from '@lumapps/router';

import { MetadataLinkProps } from '../../types';

import './index.scss';

const CLASSNAME = 'metadata-link';

/**
 * Link to the search filtering by metadata
 */
export const MetadataLink: React.FC<MetadataLinkProps> = ({
    metadata,
    theme = Theme.light,
    contentTypeId,
}: MetadataLinkProps): React.ReactElement => {
    const { block } = useClassnames(CLASSNAME);
    const route = useMetadataLink(metadata, contentTypeId);

    if (route) {
        return (
            <LinkUI linkAs={Link} to={route} className={block({ [`theme-${theme}`]: true, 'is-link': true })}>
                <Text as="span" truncate>
                    {metadata.rootName ? `${metadata.rootName} / ${metadata.name}` : `${metadata.name}`}
                </Text>
            </LinkUI>
        );
    }

    return (
        <Text as="span" className={block(`theme-${theme}`)}>
            <Text as="span" truncate>
                {metadata.rootName ? `${metadata.rootName} / ${metadata.name}` : `${metadata.name}`}
            </Text>
        </Text>
    );
};
