import React, { useMemo } from 'react';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { Dialog, Button, Emphasis, Toolbar, Progress, ProgressVariant } from '@lumapps/lumx/react';

import { translate as t, transRep as tr } from '../../../../translations';

/**
 * Translate the item.
 * The item can be a translation key or an array of a translation key and its replacement.
 *
 * @param  {string | Array} item The item to translate.
 * @return {string}         The translation.
 */
const _getTranslation = (item) => {
    if (isString(item)) {
        return t(item);
    } else if (isArray(item)) {
        return tr(item[0], item[1], item[2]);
    }

    return undefined;
};

const ConfirmDialog = ({
    description,
    hasCancelButton,
    isLoading,
    isOpen,
    onClose,
    onValidate,
    title,
    zIndex,
    ...props
}) => {
    const footerButtons = useMemo(
        () => (
            <>
                {hasCancelButton && (
                    <Button disabled={isLoading} emphasis={Emphasis.medium} onClick={onClose}>
                        {t('GLOBAL.CANCEL')}
                    </Button>
                )}
                <Button
                    autoFocus
                    className="lumx-spacing-margin-left-regular"
                    disabled={isLoading}
                    onClick={onValidate}
                >
                    {t('GLOBAL.OK')}
                </Button>
            </>
        ),
        [hasCancelButton, isLoading, onClose, onValidate],
    );

    return (
        <Dialog
            footer={<Toolbar after={footerButtons} />}
            header={<Toolbar label={<span className="lumx-typography-title">{_getTranslation(title)}</span>} />}
            isOpen={isOpen}
            zIndex={zIndex}
            {...props}
        >
            <div className="lumx-spacing-padding-horizontal-huge">
                <p>{_getTranslation(description)}</p>
            </div>
            {isLoading && <Progress variant={ProgressVariant.linear} />}
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    /** Dialog description. Can be a translation key or an array 'key', [replacementKeys], [replacementValues]. */
    description: oneOfType([string, array]),
    /** Hide the cancel button. */
    hasCancelButton: bool,
    /** Disable buttons. */
    isLoading: bool,
    /** Should open dialog. */
    isOpen: bool,
    /** Called when closing dialog or choose "Cancel". */
    onClose: func,
    /** Called when the user choose "OK". */
    onValidate: func.isRequired,
    /** Dialog title. Can be a translation key or an array 'key', [replacementKeys], [replacementValues]. */
    title: oneOfType([string, array]),
    /** Z-index. */
    zIndex: number,
};

ConfirmDialog.defaultProps = {
    description: '',
    hasCancelButton: true,
    isLoading: false,
    isOpen: true,
    onClose: undefined,
    title: '',
    zIndex: undefined,
};

export { ConfirmDialog };
