import { useEffect } from 'react';

import castArray from 'lodash/castArray';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import get from 'lodash/get';

import { requestOnIdleCallback } from '@lumapps/utils/function/requestIdleCallback';

export enum TOPICS {
    SEARCH_ANALYTICS = 'search-analytics',
    GOOGLE_ANALYTICS = 'google-analytics',
    TRACKING_FRONT = 'tracking-front',
}

export interface Event {
    /**
     * Payload that will be sent to the service that will be tracking
     * these events.
     */
    payload: any;
    /**
     * Unique ID that will group events into the same "bag". This means that
     * events that have the same topic will be dispatched together using the same
     * service.
     */
    topic: TOPICS;
}

/**
 * Service that will dispatch the gathered events.
 */
export type Service = (events: Event[]) => void;

interface EventsQueue {
    /**
     * list of events to dispatch to the service
     */
    evs: Event[];
    /**
     * service to use.
     */
    service: Service | null;
}

/**
 * We save in memory the list of events that we will dispatch when the time is right.
 */
let events: Record<TOPICS, EventsQueue>;

export const resetEvents = () => {
    events = {
        [TOPICS.SEARCH_ANALYTICS]: { evs: [], service: null },
        [TOPICS.GOOGLE_ANALYTICS]: { evs: [], service: null },
        [TOPICS.TRACKING_FRONT]: { evs: [], service: null },
    };
};

/**
 * Directly call resetEvents to init events var.
 * We need to be sure that events is using another reference of the object
 * this is why we have not used an "INITITAL STATE" variable
 */
resetEvents();

/**
 * Function that will retrieve the current events added to the local store
 * and dispatch them one by one. It finally clears the store, resetting the events.
 *
 * This function should not be called directly, it is already executed when an
 * event is added to the queue.
 */
const sendEvents = () => {
    requestOnIdleCallback(() => {
        Object.keys(events).forEach((key: string) => {
            const keyTopic = key as TOPICS;

            if (events[keyTopic]) {
                const { evs, service } = events[keyTopic];
                if (service) {
                    service(evs);
                }
            }
        });

        resetEvents();
    });
};

const debouncedSendEvents = debounce(sendEvents, 100);
const debouncedSendBatchedEvents = debounce(sendEvents, 3000);

/**
 * Adds an event to a queue, dispatching said event to the provided Service
 * when the time is right.
 * @param ev - Event to track
 * @param service - Service to use in order to dispatch the event
 */
export const queueEvent = (ev: Event[] | Event, service: Service) => {
    const eventsToQueue = Array.isArray(ev) ? ev : [ev];

    eventsToQueue.forEach((event: Event) => {
        if (!events[event.topic]) {
            events[event.topic] = {
                evs: [],
                service,
            };
        }

        events[event.topic].evs.push(event);
        events[event.topic].service = service;
    });

    debouncedSendEvents();
};

/**
 * Adds an event to a queue, batching every events while new events are coming,
 * then dispatching said events to the provided Service when the time is right.
 * @param ev - Event to track
 * @param service - Service to use in order to dispatch the event
 */
export const queueBatchedEvents = (eventsToQueue: Event[] | Event, service: Service) => {
    castArray(eventsToQueue).forEach((eventToQueue: Event) => {
        const { topic, payload } = eventToQueue;

        // Init event of current topic if not existing
        if (!events[topic]) {
            events[topic] = {
                evs: [],
                service,
            };
        }

        // Getting the first existing event of the current topic that will be used to batch subsequent event payloads
        const existingEvents = first(get(events[topic], 'evs', []));
        // Adding the new event payload to the existing events payload
        const allEvents = [...(existingEvents?.payload.events || []), ...payload.events];

        // Buiding the event with batched payloads
        events[topic].evs = [{ payload: { events: allEvents }, topic }];
        events[topic].service = service;
    });

    debouncedSendBatchedEvents();
};

/**
 * Function that forces the events dispatch. This should be only used in an extreme scenario,
 * where you are 100% sure that the queued events will not be dispatched due to a specific
 * browser behaviour (a redirection, a refresh, etc.)
 */
export const forceEventsDispatch = () => {
    sendEvents();
};

/**
 * Retrieves the list of events queued for the given topic
 * @param topic - given topic
 * @returns list of events queued for the given topic
 */
export const getEventsForTopic = (topic: TOPICS) => {
    return events[topic] ? events[topic].evs : [];
};

/**
 * Hook that dispatches the list of pending events when the browser's visibility
 * has changed in any way.
 */
export const useLumAppsAnalytics = () => {
    useEffect(() => {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                forceEventsDispatch();
            }
        });
    });
};
