import kebabCase from 'lodash/kebabCase';

/**
 * Take the given string and returns a slugified version of the string.
 * @param  value The value to slugify.
 * @return The slugified value
 */
export const slugify = (value: string): string => {
    const firstLetter = value.charAt(0);
    const lastLetter = value.charAt(value.length - 1);
    const newValue = kebabCase(value.toLowerCase());

    /**
     * Lodash's kebabCase function doesn't allow trailing dashes and spaces.
     * In some case, we might want to keep this trailing dash (when someone is typing for instance).
     */
    if (newValue !== '' && firstLetter !== '-' && (lastLetter === '-' || lastLetter === ' ')) {
        return `${newValue}-`;
    }

    return newValue;
};
