import isArray from 'lodash/isArray';

import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';
import { getArrayFacetValue } from '@lumapps/router/utils';

import { FACET_PREFIX, SEARCH_GROUPS_RESTRICTIONS_PARAM, getPrefixedFacet, SEARCH_PAGE_PARAM } from './constants';
import { FacetOptions } from './types';
import { shouldUseGroupsRestrictions } from './utils/shouldUseGroupsRestrictions';

const searchRoute: Route = {
    legacyId: 'app.front.search',
    path: '/search/:query/:filter?',
    appId: AppId.frontOffice,
};

const mobileSearchRoute: Route = {
    ...searchRoute,
    path: '/mobile/search',
    appId: AppId.frontOffice,
};

const isSearchUrl = (slug: string) => {
    return slug.indexOf('/search/') === 0 || slug.indexOf(mobileSearchRoute.path) === 0;
};

const getSearchQueryParam = (facets?: FacetOptions[], sort?: string, page?: number) => {
    const queryParams: Record<string, string> = {};

    if (facets) {
        facets.forEach((facet: FacetOptions) => {
            const facetValue = isArray(facet.value) ? getArrayFacetValue(facet.value) : facet.value;

            Object.assign(queryParams, {
                [facet.id.indexOf(FACET_PREFIX) >= 0 ? facet.id : getPrefixedFacet(facet.id)]: facetValue,
            });
        });
    }

    if (sort) {
        queryParams.sort = sort;
    }

    // forward the groups restrictions param
    if (shouldUseGroupsRestrictions()) {
        queryParams[SEARCH_GROUPS_RESTRICTIONS_PARAM] = 'true';
    }

    if (page) {
        queryParams[SEARCH_PAGE_PARAM] = `${page}`;
    }

    return queryParams;
};

/**
 * Main search page entry point for the search page.
 * @param query string to be used for searching
 * @param filter the filter that will be shown upon redirection. By default is 'all'
 */
const search = (query: string, filter?: string, facets?: FacetOptions[], sort?: string, page?: number): Route => {
    const params = {
        query,
        /**
         * Using `filter || undefined` here instead of letting
         * js setting undefined by default will remove the trailing
         * slash in the url if filter is not set.
         */
        filter: filter || undefined,
    };

    const queryParams = getSearchQueryParam(facets, sort, page);

    return {
        ...searchRoute,
        params,
        query: queryParams,
    };
};

export { search, searchRoute, mobileSearchRoute, isSearchUrl };
