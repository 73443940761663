function WidgetCommunityMembersController($state, Community, Content, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current community.
     *
     * @type {Object}
     */
    let _community;

    /**
     * Indicates if the  current view is the template view of a community.
     *
     * @type {boolean}
     */
    let _isTemplate = false;

    /**
     * The list of classes to apply to the widget.
     *
     * @type {Array}
     */
    const _widgetClasses = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.Content = Content;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget classes.
     *
     * @return {Array} Widget classes.
     */
    function getWidgetClass() {
        Utils.empty(_widgetClasses);

        vm.parentCtrl.getWidgetClass(_widgetClasses);

        if (vm.isWidgetEmpty()) {
            _widgetClasses.push('widget--is-empty');
        }

        return _widgetClasses;
    }

    /**
     * Indicates if the widget is empty or not while in designer mode.
     *
     * @return {boolean} Whether the widget is empty or not.
     */
    function isWidgetEmpty() {
        return !_community || _isTemplate;
    }

    /////////////////////////////

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;

    /////////////////////////////

    /**
     * Initialize controller.
     */
    vm.init = function init() {
        const stateParams = $state.params;
        _community = Content.getCurrent();
        _isTemplate =
            stateParams.contentType === 'community' && (stateParams.type === 'template' || stateParams.isTemplate);
    };

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        vm.init();
    };
}

/////////////////////////////

/**
 * The community navigation widget.
 * This widget provide the user navigation tools and shortcut.
 *
 * @param {Object} widget The widget configuration object.
 */

function WidgetCommunityMembersDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetCommunityMembersController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetCommunityMembers', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-community-members/views/widget-community-members.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetCommunityMembers', WidgetCommunityMembersDirective);

/////////////////////////////

export { WidgetCommunityMembersController, WidgetCommunityMembersDirective };
