import React, { useRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiArrowDownThick, mdiArrowUpThick } from '@lumapps/lumx/icons';
import { Orientation, FlexBox, Alignment, Emphasis, Theme, Button, Size, Tooltip } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { useElementSize } from '@lumapps/utils/hooks/useElementSize';

import { POSTS } from '../../../keys';
import { IdeaVote } from '../../../types';
import { usePostVote } from './usePostVote';

import './index.scss';

interface PostVoteProps {
    theme?: Theme;
    initialVotes: {
        userVote?: string | IdeaVote;
        upVotes: number;
        downVotes: number;
    };
    postId: string;
    isPreviewOnly?: boolean;
}

const CLASSNAME = 'block-post-vote';
const SCOPE = 'post-vote';
const COMPACT_BREAKPOINT = 400;

const PostVote: React.FC<PostVoteProps> = ({ initialVotes, theme, postId, isPreviewOnly }) => {
    const { pluralize, translateAndReplace, translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const { element, block } = useClassnames(CLASSNAME);
    const ref = useRef(null);

    const { width } = useElementSize(ref);

    const {
        isVoteLoading,
        onVote,
        onDeleteVote,
        votes: { downVotes, upVotes, userVote },
    } = usePostVote({
        postId,
        initialVotes,
    });

    const handleVote = (vote: IdeaVote) => {
        // If current vote is similar as previous one -> delete
        if (userVote === vote) {
            onDeleteVote();
        } else {
            onVote(vote);
        }
    };

    const localVote = userVote?.toLowerCase();
    const isCompact = (width || 0) < COMPACT_BREAKPOINT;
    const pluralizedUpvotes = translateAndReplace(pluralize(POSTS.PLURALIZED_UPVOTES, upVotes || 1), {
        number: upVotes,
    });
    const pluralizedDownvotes = translateAndReplace(pluralize(POSTS.PLURALIZED_DOWNVOTES, downVotes || 1), {
        number: downVotes,
    });

    return (
        <FlexBox
            theme={theme}
            orientation={Orientation.horizontal}
            vAlign={Alignment.right}
            className={block()}
            gap={Size.regular}
            fillSpace
            ref={ref}
        >
            <Tooltip label={translateKey(POSTS.UPVOTE)} ariaLinkMode="aria-describedby" closeMode="hide">
                <Button
                    theme={theme}
                    emphasis={Emphasis.medium}
                    leftIcon={mdiArrowUpThick}
                    onClick={() => handleVote(IdeaVote.UP)}
                    disabled={isVoteLoading}
                    isSelected={localVote === IdeaVote.UP}
                    className={element('up-btn', {
                        'is-preview-only': Boolean(isPreviewOnly),
                    })}
                    aria-label={isCompact ? pluralizedUpvotes : undefined}
                    {...get({ element: 'button', action: 'upvote' })}
                >
                    {isCompact ? upVotes.toString() : pluralizedUpvotes}
                </Button>
            </Tooltip>
            <Tooltip label={translateKey(POSTS.DOWNVOTE)} ariaLinkMode="aria-describedby" closeMode="hide">
                <Button
                    theme={theme}
                    emphasis={Emphasis.medium}
                    leftIcon={mdiArrowDownThick}
                    onClick={() => handleVote(IdeaVote.DOWN)}
                    disabled={isVoteLoading}
                    isSelected={localVote === IdeaVote.DOWN}
                    className={element('down-btn', {
                        'is-preview-only': Boolean(isPreviewOnly),
                    })}
                    aria-label={isCompact ? pluralizedDownvotes : undefined}
                    {...get({ element: 'button', action: 'downvote' })}
                >
                    {isCompact ? downVotes.toString() : pluralizedDownvotes}
                </Button>
            </Tooltip>
        </FlexBox>
    );
};

export { PostVote };
