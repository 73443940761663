import React, { useCallback } from 'react';

import property from 'lodash/fp/property';

import { currentLanguageSelector } from '@lumapps/languages';
import { Theme } from '@lumapps/lumx/react';
import { GenericEntityPicker } from '@lumapps/pickers/components/GenericEntityPicker';
import { useGenericEntityPicker, UseGenericPickerProps } from '@lumapps/pickers/hooks/useGenericEntityPicker';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { list, cancelList, fetchSingleUser } from '@lumapps/user/api';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { FormattedAuthorFilter } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';
import { SkeletonFilter } from '../SkeletonFilter';

interface AuthorContentFilterProps {
    onChange: (author: FormattedAuthorFilter) => void;
    selectedValue?: FormattedAuthorFilter;
    theme: Theme;
    scope: string;
    hideSubheader: boolean;
}

interface AuthorFilterBlockProps extends Omit<AuthorContentFilterProps, 'hideSubheader'> {
    label: string;
    getChoiceName: (author: FormattedAuthorFilter) => string;
}

const AuthorFilterBlock = ({ selectedValue, getChoiceName, onChange, theme, scope, label }: AuthorFilterBlockProps) => {
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    const { displayedValue, reset, ...userPickerProps } = useGenericEntityPicker<FormattedAuthorFilter>({
        onFetch: list as UseGenericPickerProps<FormattedAuthorFilter>['onFetch'],
        onFetchCancel: cancelList,
        fetchParams: { fields: 'items(email,firstName,lastName, fullName),more,callId,cursor' },
        displayedValueSelector: getChoiceName,
        initialValue: selectedValue,
        onChange: (author: FormattedAuthorFilter) => {
            onChange(author);
        },
    });

    return (
        <GenericEntityPicker<FormattedAuthorFilter>
            label={label}
            theme={theme}
            scope={scope}
            choiceDisplayedValueSelector={getChoiceName}
            choiceKeySelector={property('email')}
            choiceSelectedSelector={(author: FormattedAuthorFilter) => getChoiceName(author) === displayedValue}
            displayedValue={selectedValue?.email ? getChoiceName(selectedValue) : ''}
            {...userPickerProps}
        />
    );
};

export const AuthorContentFilter = ({
    selectedValue,
    onChange,
    theme,
    scope,
    hideSubheader,
}: AuthorContentFilterProps) => {
    const currentLanguage = useSelector(currentLanguageSelector);
    const { translateKey } = useTranslate();

    const { fullName, firstName, lastName, email } = selectedValue || {};

    const getChoiceName = useCallback(
        (author: FormattedAuthorFilter) => author.fullName || getUserFullName(author, currentLanguage),
        [currentLanguage],
    );

    const getAuthorDetail = useCallback(
        async (email: string) => {
            await fetchSingleUser(
                { email, fields: 'email,firstName,lastName, fullName' },
                /** Success callback */
                (author) => {
                    onChange(author as FormattedAuthorFilter);
                },
                /** Error callback */
                () => onChange({ fullName: translateKey(GLOBAL.UNKNOWN_USER), email }),
            );
        },
        [onChange, translateKey],
    );

    /**
     * If incomplete author information, we are fetching them,
     * end update the author filter content with the result.
     * */
    if (!fullName && !firstName && !lastName && email) {
        getAuthorDetail(email);
        return <SkeletonFilter />;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.AUTHOR)} theme={theme} />}
            <AuthorFilterBlock
                selectedValue={selectedValue}
                getChoiceName={getChoiceName}
                onChange={onChange}
                label={translateKey(GLOBAL.AUTHOR)}
                theme={theme}
                scope={scope}
            />
        </>
    );
};
