import React, { RefObject } from 'react';

import { AlertDialog, Size, Text, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { actions } from '../../ducks/slice/reportSlice';
import { REPORT_ALREADY_REPORTED, REPORT_ALREADY_REPORTED_DIALOG_MESSAGE, REPORTS } from '../../keys';
import { ReportedResourceType } from '../../types';
import { ReportDialog } from '../ReportDialog';
import { ThanksDialog } from '../ThanksDialog';

export interface ReportTunnelProps {
    resourceType: ReportedResourceType;
    resourceId: string;
    reportBtnRef?: RefObject<HTMLButtonElement>;
    isReportDialogOpen: boolean;
    isReportDialogLoading: boolean;
    isAlreadyReportedDialogOpen: boolean;
    isThanksDialogOpen: boolean;
    dispatchDialogActions: React.Dispatch<any>;
    setIsResourceAlreadyReported: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * This component groups all dialogs required to report a resource.
 * The useReport hook returns all the props needed.
 */
export const ReportTunnel = ({
    resourceId,
    resourceType,
    isReportDialogOpen,
    isReportDialogLoading,
    isAlreadyReportedDialogOpen,
    dispatchDialogActions,
    reportBtnRef,
    setIsResourceAlreadyReported,
    isThanksDialogOpen,
}: ReportTunnelProps) => {
    const { translateKey } = useTranslate();

    return (
        <>
            <ReportDialog
                isLoading={isReportDialogLoading}
                resourceId={resourceId}
                resourceType={resourceType}
                isOpen={isReportDialogOpen}
                triggerElement={reportBtnRef}
                onCancel={() => dispatchDialogActions(actions.closeReportDialog())}
                onSuccessCallback={() => {
                    dispatchDialogActions(actions.openThanksDialog());
                    dispatchDialogActions(actions.closeReportDialog());
                    dispatchDialogActions(actions.setReportDialogStatus(false));
                }}
                setIsReportDialogLoading={(status) => dispatchDialogActions(actions.setReportDialogStatus(status))}
            />
            <AlertDialog
                isOpen={isAlreadyReportedDialogOpen}
                title={translateKey(REPORT_ALREADY_REPORTED[resourceType])}
                confirmProps={{
                    onClick: () => dispatchDialogActions(actions.closeAlreadyReportedDialog()),
                    label: translateKey(GLOBAL.OK),
                }}
                size={Size.tiny}
            >
                <Text as="p" typography={Typography.body1}>
                    {translateKey(REPORT_ALREADY_REPORTED_DIALOG_MESSAGE[resourceType])}
                </Text>
            </AlertDialog>
            <ThanksDialog
                isOpen={isThanksDialogOpen}
                closeModal={() => {
                    dispatchDialogActions(actions.closeThanksDialog());
                    setIsResourceAlreadyReported(true);
                }}
                message={translateKey(REPORTS.THANKS_DIALOG_MESSAGE)}
            />
        </>
    );
};
