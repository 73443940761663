import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { MAX_NOTIFICATION_NUMBER } from '../constants';
import { LastNotification, NotificationCenter, NotificationGroup, NotificationResponse } from '../types';

const initialState: NotificationCenter = {
    notificationGroups: [],
    unreadNotificationsCount: 0,
    isLoading: true,
    hasError: false,
    more: false,
    lastNotification: {},
};

const { actions, reducer } = createSlice({
    domain: 'notificationCenter',
    initialState,
    reducers: {
        setIsLoadingNotifications: (state: NotificationCenter, action: PayloadAction<boolean>) => {
            set(state, 'isLoading', action.payload);
        },
        addNotifications: (state: NotificationCenter, action: PayloadAction<NotificationResponse>) => {
            const { notifications, more, cursor, unreadNotificationsCount } = action.payload;
            const { notificationGroups: stateNotifications } = state;

            if (notifications && notifications.length > 0) {
                set(state, 'notificationGroups', [...stateNotifications, ...notifications]);
            }
            set(state, 'unreadNotificationsCount', unreadNotificationsCount);
            set(state, 'more', more);
            set(state, 'cursor', cursor);
            set(state, 'hasError', false);
        },
        setNotifications: (state: NotificationCenter, action: PayloadAction<NotificationResponse>) => {
            const { notifications, more, cursor, unreadNotificationsCount } = action.payload;
            set(state, 'notificationGroups', notifications);
            set(state, 'unreadNotificationsCount', unreadNotificationsCount);
            set(state, 'more', more);
            set(state, 'cursor', cursor);
            set(state, 'hasError', false);
        },
        setNotificationsError: (state: NotificationCenter, action: PayloadAction<boolean>) => {
            set(state, 'hasError', action.payload);
        },
        setNotificationRead: (state: NotificationCenter, action: PayloadAction<string>) => {
            if (state.unreadNotificationsCount <= MAX_NOTIFICATION_NUMBER) {
                set(state, 'unreadNotificationsCount', state.unreadNotificationsCount - 1);
            }
            const notifGroup = find(
                state.notificationGroups,
                (group: NotificationGroup) => group.notification?.uid === action.payload,
            );

            if (notifGroup && notifGroup.notification) {
                set(notifGroup.notification, 'isRead', true);
            }
        },
        setNotificationUnreadCount: (state: NotificationCenter, action: PayloadAction<number>) => {
            set(state, 'unreadNotificationsCount', action.payload);
        },
        setNotificationUnread: (state: NotificationCenter, action: PayloadAction<string>) => {
            if (state.unreadNotificationsCount <= MAX_NOTIFICATION_NUMBER) {
                set(state, 'unreadNotificationsCount', state.unreadNotificationsCount + 1);
            }
            const notifGroup = find(
                state.notificationGroups,
                (group: NotificationGroup) => group.notification?.uid === action.payload,
            );

            if (notifGroup && notifGroup.notification) {
                set(notifGroup.notification, 'isRead', false);
            }
        },
        setAllNotificationRead: (state: NotificationCenter) => {
            set(state, 'unreadNotificationsCount', 0);
            state.notificationGroups.forEach((notifGroup: NotificationGroup) => {
                if (notifGroup.notification) {
                    set(notifGroup.notification, 'isRead', true);
                }
            });
        },
        deleteNotification: (state: NotificationCenter, action: PayloadAction<{ uid: string; isRead: boolean }>) => {
            if (!action.payload.isRead && state.unreadNotificationsCount <= MAX_NOTIFICATION_NUMBER) {
                set(state, 'unreadNotificationsCount', state.unreadNotificationsCount - 1);
            }
            const notifIndex = findIndex(
                state.notificationGroups,
                (group: NotificationGroup) => group.notification?.uid === action.payload.uid,
            );

            if (notifIndex >= 0) {
                const newGroup = [...state.notificationGroups];
                newGroup.splice(notifIndex, 1);
                set(state, 'notificationGroups', newGroup);
            }
        },
        clearNotifications: (state: NotificationCenter) => {
            set(state, 'unreadNotificationsCount', 0);
            set(state, 'notificationGroups', []);
        },
        setLastNotification: (state: NotificationCenter, action: PayloadAction<LastNotification | undefined>) => {
            set(state, 'lastNotification', action.payload);
        },
    },
});
export { actions, reducer, initialState };
