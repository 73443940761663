import { isCommunitiesAllowed, isSpacesFeatureEnabled } from '@lumapps/communities/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/stores/backOffice';

import { SpacesSideNavigation } from './SpacesSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isCommunitiesAllowed(state) && isSpacesFeatureEnabled(state),
});

const ConnectedSpacesSideNavigation = connect(mapStateToProps)(SpacesSideNavigation);

export { ConnectedSpacesSideNavigation as SpacesSideNavigation };
