import { BaseApiError, isBaseApiError } from '@lumapps/base-api';

export interface LogExceptionOptions {
    /**
     * Whether API response exception should also be logged.
     * This is set at `false` by default as api exceptions already logs an error by default.
     */
    logResponseException?: boolean;
}

/**
 * Log given exception as error.
 * This should be used when unexpected errors can be caught in a try/catch
 */
export const logException = (exception: BaseApiError | unknown, options: LogExceptionOptions = {}) => {
    if (!exception || (isBaseApiError(exception) && !options.logResponseException)) {
        return;
    }

    // eslint-disable-next-line no-console
    console.error(exception);
};
