import BaseApi from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { follow as followContent, unfollow as unfollowContent } from '@lumapps/contents/api/contents';
import { follow as followUser, unfollow as unfollowUser } from '@lumapps/user/api/index';
import { User } from '@lumapps/user/types';
import { OneOrMany } from '@lumapps/utils/types/OneOrMany';

import { FollowingContentType, SubscriptionsTypes, SubscriptionType } from '../types';

const subscriptionsApi = new BaseApi({
    path: 'social/subscription',
});

export interface GetSubscriptionToUserParameters {
    /**
     * The resource type to get the subscription from.
     * Default as "content";
     * */
    resourceType?: SubscriptionsTypes;
    /** The resource's id */
    resourceId?: string;
    /** whether we want to retrieve this information from the memory cache */
    cacheFirst?: boolean;
}

/**
 * THIS METHOD IS DEPRECATED, PLEASE USE querySubscription.
 * Retrieves the subscription for a given resource id and type. This API returns a 404 if
 * the subscription does not exist, which throws out an error. This is why this method is deprecated
 * @deprecated
 * @param GetSubscriptionToUserParameters
 * @returns Promise<Subscription>
 */
const getSubscription = ({ resourceType, resourceId, cacheFirst }: GetSubscriptionToUserParameters) => {
    const params = {
        [resourceType === SubscriptionsTypes.user ? 'followingUserKey' : 'followingContentKey']: resourceId,
        status: 'LIVE',
    };
    if (cacheFirst) {
        return subscriptionsApi.getCacheFirst('/get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params });
    }

    return subscriptionsApi.get('/get', {
        params,
    });
};

export interface SubscriptionToggleParameters {
    /**
     * The resource type to toggle the subscription.
     * Default as "content";
     * */
    resourceType?: SubscriptionsTypes;
    /** The resource's id */
    resourceId: string;
    /** Whether it should also activate notifications */
    notify?: boolean;
}

const subscribe = ({ resourceType, resourceId, notify = false }: SubscriptionToggleParameters) => {
    return resourceType === SubscriptionsTypes.user
        ? followUser(resourceId, { notify })
        : followContent(resourceId, { notify });
};

const unsubscribe = ({ resourceType, resourceId }: SubscriptionToggleParameters) => {
    return resourceType === SubscriptionsTypes.user ? unfollowUser(resourceId) : unfollowContent(resourceId);
};

const saveSubscription = (subscription: SubscriptionType) => {
    return subscriptionsApi.post('/save', subscription);
};

/**
 * https://apiv1.lumapps.com/#operation/Social%20Subscription/List
 */
export interface ListSubscriptionsParameters extends Omit<ServerListRequest, 'fields'> {
    /** User who subscribe to a content */
    followerUserKey?: string;
    /** Content user wants to follow */
    followingContentKey?: string;
    /** Type of content associated to subscription */
    followingContentType?: OneOrMany<FollowingContentType>;
    /** User profile that user wants to follow */
    followingUserKey?: string;
    /** The ID of the instance */
    instanceKey?: string;
    /** retrieving language */
    lang?: string;
    /** subscription status */
    status?: string;
}

/**
 * List the subscriptions of the current user
 */
const listSubscriptions = (params: ListSubscriptionsParameters, fetchKey?: string) => {
    return subscriptionsApi.get<ServerListResponse<SubscriptionType>>(
        '/list',
        { params },
        undefined,
        Boolean(fetchKey),
        fetchKey,
    );
};

const querySubscription = ({ resourceType, resourceId }: GetSubscriptionToUserParameters) => {
    const params: ListSubscriptionsParameters = {
        [resourceType === SubscriptionsTypes.user ? 'followingUserKey' : 'followingContentKey']: resourceId,
        status: 'LIVE',
    };

    return listSubscriptions(params);
};

/** Cancel the subscriptions call */
const cancelListSubscriptions = (fetchKey: string) => {
    return subscriptionsApi.cancel('/list', undefined, fetchKey);
};

/**
 * https://apiv1.lumapps.com/#operation/Social%20Subscription/Following/List
 */
export interface ListFollowingParameters extends Omit<ServerListRequest, 'fields'> {
    /** The ID of the instance */
    instanceKey: string;
    /** retrieving language */
    lang: string;
}

/**
 * List the subscriptions of the current user
 */
const listFollowing = (params: ListFollowingParameters, fetchKey?: string) => {
    return subscriptionsApi.get<ServerListResponse<User>>(
        '/following/list',
        { params },
        undefined,
        Boolean(fetchKey),
        fetchKey,
    );
};

/**
 * List the subscriptions of the current user
 */
const cancelListFollowing = (fetchKey: string) => {
    return subscriptionsApi.cancel('/following/list', undefined, fetchKey);
};

/**
 * https://apiv1.lumapps.com/#operation/Social%20Subscription/Following/List
 */
export interface ListFollowersParameters extends Omit<ServerListRequest, 'fields'> {
    /** The ID of the instance */
    instanceKey: string;
    /** retrieving language */
    lang: string;
}

/**
 * List the subscriptions of the current user
 */
const listFollowers = (params: ListFollowersParameters, fetchKey?: string) => {
    return subscriptionsApi.get<ServerListResponse<User>>(
        '/follower/list',
        { params },
        undefined,
        Boolean(fetchKey),
        fetchKey,
    );
};

/**
 * List the subscriptions of the current user
 */
const cancelListFollowers = (fetchKey: string) => {
    return subscriptionsApi.cancel('/follower/list', undefined, fetchKey);
};

export {
    getSubscription,
    subscribe,
    unsubscribe,
    subscriptionsApi,
    saveSubscription,
    listSubscriptions,
    cancelListSubscriptions,
    listFollowing,
    cancelListFollowing,
    listFollowers,
    cancelListFollowers,
    querySubscription,
};
