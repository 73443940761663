import React from 'react';

import { angularApi } from '../angular/angular-translation';
import { api } from '../api';
import { TranslationAPI } from '../types';
import { useTranslationsContext } from './useTranslationsContext';

const useTranslate = (): TranslationAPI => {
    const context = useTranslationsContext();

    return React.useMemo(
        () =>
            context && context.loaded
                ? {
                      pluralize: api.pluralize(context),
                      translateKey: api.translateKey(context),
                      translateObject: api.translateObject(context),
                      translateAndReplace: api.translateAndReplace(context),
                      translateAndReplaceWithComponents: api.translateAndReplaceWithComponents(context),
                      translateAndReplaceList: api.translateAndReplaceList(context),
                      translate: api.translate(context),
                      pluralizeObject: api.pluralizeObject(context),
                  }
                : {
                      pluralize: angularApi.pluralize,
                      translateKey: angularApi.translateKey,
                      translateObject: angularApi.translateObject,
                      translateAndReplace: angularApi.translateAndReplace,
                      translateAndReplaceWithComponents: angularApi.translateAndReplaceWithComponents,
                      translateAndReplaceList: angularApi.translateAndReplaceList,
                      translate: angularApi.translate,
                      pluralizeObject: angularApi.pluralizeObject,
                  },
        [context],
    );
};

export { useTranslate };
