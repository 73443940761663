import { DEFAULT_LANGUAGE } from '@lumapps/languages/constants';

const DOMAIN = 'locale';

const ANGULAR_INIT = `__angular__/${DOMAIN}/update`;

const UPDATE_LOCALE_CURRENT = `${DOMAIN}/UPDATE_CURRENT`;
const UPDATE_LOCALE_DEFAULT = `${DOMAIN}/UPDATE_DEFAULT`;
const UPDATE_LOCALE_FALLBACK = `${DOMAIN}/UPDATE_FALLBACK`;
const UPDATE_LOCALE_INPUT_CUSTOMER_LANGUAGE = `${DOMAIN}/UPDATE_INPUT_CUSTOMER_LANGUAGE`;
const UPDATE_LOCALE_INPUT_LANGUAGE = `${DOMAIN}/UPDATE_INPUT_LANGUAGE`;
const UPDATE_LOCALE_PREFERRED = `${DOMAIN}/UPDATE_PREFERRED`;

export {
    ANGULAR_INIT,
    DEFAULT_LANGUAGE,
    UPDATE_LOCALE_CURRENT,
    UPDATE_LOCALE_DEFAULT,
    UPDATE_LOCALE_FALLBACK,
    UPDATE_LOCALE_INPUT_CUSTOMER_LANGUAGE,
    UPDATE_LOCALE_INPUT_LANGUAGE,
    UPDATE_LOCALE_PREFERRED,
    DOMAIN,
};
