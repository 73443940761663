import { isExtendedAnalyticsEnabled } from '@lumapps/analytics';
import { CUSTOM_CONTENT_TYPE_PERMISSIONS } from '@lumapps/content-types/permissions';
import {
    canUserEditCurrentContent,
    getCurrentCustomContentTypeId,
    isEditorOfContent,
} from '@lumapps/contents/ducks/selectors';
import { Content } from '@lumapps/contents/types';
import { isUserAllowed } from '@lumapps/permissions';
import { getPermissionKey } from '@lumapps/permissions/utils';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import type { Row } from '@lumapps/widget-layout/types';

import { AnalyticsContent } from '../types';
import { hasMandatoryReadWidget } from '../utils/hasMandatoryReadWidget';

export const isContentAnalyticsEnabled = isExtendedAnalyticsEnabled;

/**
 * Checks whether the current user can access the currently displayed content's analytics
 * @param state
 * @param customContentTypeId
 * @returns boolean
 */
export const canViewContentAnalytics = (state: BaseStore, customContentTypeId: any) =>
    createSelector(
        isContentAnalyticsEnabled,
        canUserEditCurrentContent,
        isUserAllowed('CONTENT_EDIT'),
        isUserAllowed(getPermissionKey(CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT, customContentTypeId)),
        (isFFEnabled, canEditCurrentContent, canEditContentFromPerm, canEditContentType) => {
            return Boolean(isFFEnabled && (canEditCurrentContent || canEditContentFromPerm || canEditContentType));
        },
    )(state);

export const canAccessContentAnalytics = (state: BaseStore) =>
    createSelector(getCurrentCustomContentTypeId, (customContentTypeId) =>
        canViewContentAnalytics(state, customContentTypeId),
    )(state);
/**
 * Checks whether the current user can access the analytics of a given content. Differs from `canViewContentAnalytics`
 * since it is not linked to the currently displayed content, but any content
 */
export const canViewSpecificContentAnalytics = createSelector(
    isContentAnalyticsEnabled,
    isEditorOfContent,
    isUserAllowed('CONTENT_EDIT'),
    (isFFEnabled, canEditCurrentContent, canEditContentFromPerm) => {
        return (content: Content) => {
            return Boolean(isFFEnabled && (canEditCurrentContent(content) || canEditContentFromPerm));
        };
    },
);

export const analyticsContentSelector = ({ content }: BaseStore): AnalyticsContent => {
    const components: Row[] = (content?.template as any)?.components || [];

    return {
        id: content?.id,
        title: content?.title,
        hasMandatoryReadWidget: hasMandatoryReadWidget(components),
    };
};
