import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

import { ProgramFormState } from './programForm/slice';
import { SAProgramState } from './types';

export const saProgramsSelector = (state: BaseStore): SAProgramState => state.saPrograms || {};

export const programFormSelector = createSelector(
    saProgramsSelector,
    (saPrograms: SAProgramState): ProgramFormState => saPrograms.programForm,
);
