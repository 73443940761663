/* istanbul ignore file */
import React from 'react';

import { DEFAULT_LOADING_LIVE_MESSAGE_TIMEOUT } from '@lumapps/aria-live/constants';
import { useUpdateGlobalLiveMessage } from '@lumapps/aria-live/hooks/useUpdateGlobalLiveMessage';
import { ServerListResponse } from '@lumapps/base-api';
import { classnames, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Text, Emphasis, IconButton, Offset, Placement } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { Menu } from '../Menu';
import { MenuTriggerProps } from '../Menu/MenuTrigger';

import './index.scss';

export type AsyncMenuProps<D = any> = {
    /** dropdown's id to display for tracking purposes */
    id: string;
    /** custom class name for the dropdown */
    className: string;
    /** dropdown's icon to display  */
    icon: string;
    /** dropdown's name */
    name: string;
    /** Action to fetch the entries to display */
    get(params?: any): Promise<ServerListResponse<D>>;
    /** params to be used when calling the API */
    params?: any;
    /** The dropdown placement */
    placement?: Placement;
    /** The dropdown offset */
    offset?: Offset;
    /** The dropdown width */
    width?: MenuTriggerProps['popoverWidth'];
    /** custom styles passed down to the icon */
    styles?: Record<string, any>;
    /** an id that shows where this component will be used */
    scope: string;
    /** Message to display when the result is empty */
    emptyMessage?: string;
    /** function that will render the list of results */
    children(params: ServerListResponse<D>): React.ReactNode;
    /** function that will render a react node after the list */
    renderAfter?(params: ServerListResponse<D>, status: BaseLoadingStatus): React.ReactNode;
};

export const CLASSNAME = 'async-dropdown';

/**
 * This component renders an icon buton that, on clicked, displays menu where the items are retrieved from an API.
 * It centralises logic for retrieving items from an API, displaying a skeleton and rendering the results.
 *
 * @family Menus
 */
export const AsyncMenu = <D,>(props: AsyncMenuProps<D>) => {
    const {
        id,
        icon,
        name,
        get,
        params,
        children,
        offset = {},
        styles = {},
        width,
        scope,
        placement = Placement.AUTO_START,
        className,
        emptyMessage,
        renderAfter,
    } = props;
    const [isOpen, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState(BaseLoadingStatus.initial);
    const [data, setData] = React.useState<any>(null);
    const { get: getDataAttributes } = useDataAttributes(scope);
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    const isLoading = status === BaseLoadingStatus.loading;
    const hasData = !isLoading && data?.items && data.items.length > 0;

    React.useEffect(() => {
        if (!isOpen) {
            return;
        }
        setStatus(BaseLoadingStatus.loading);
        get(params)
            .then((data) => {
                setData(data);
                setStatus(BaseLoadingStatus.idle);
            })
            .catch(() => {
                setStatus(BaseLoadingStatus.error);
            });
    }, [isOpen, get, params]);

    const MenuComponent = isLoading ? Menu.Skeleton : Menu;

    // Announce the empty state message
    useUpdateGlobalLiveMessage(emptyMessage, {
        enabled: isOpen && status === BaseLoadingStatus.idle && !hasData,
        debounceTimeout: DEFAULT_LOADING_LIVE_MESSAGE_TIMEOUT,
    });

    return (
        <Menu.Trigger
            popoverProps={{
                className: classnames(block(), className),
                placement,
                offset,
                ...getDataAttributes({ element: 'dropdown', action: id }),
            }}
            popoverWidth={width}
            loadingMessage={`${name}; ${translateKey(GLOBAL.LOADING)}`}
            onToggle={setOpen}
            isLoading={isLoading}
        >
            <Menu.TriggerButton
                className={element('button', { active: isOpen })}
                as={IconButton}
                icon={icon}
                emphasis={Emphasis.low}
                color={styles.themeColor}
                label={name}
                {...getDataAttributes({ element: 'button', action: id })}
            />

            <MenuComponent className={element('popover-content')}>
                {isLoading ? (
                    <Menu.SectionSkeleton hasIcon label={name} />
                ) : (
                    <Menu.Section label={name}>
                        {emptyMessage && !hasData && (
                            <li aria-hidden="true" className={element('no-data')}>
                                <Text as="p" typography="body1" color="dark" colorVariant="L3">
                                    {emptyMessage}
                                </Text>
                            </li>
                        )}
                        {hasData && children(data)}
                    </Menu.Section>
                )}
                {renderAfter?.(data, status)}
            </MenuComponent>
        </Menu.Trigger>
    );
};
