import React from 'react';

import range from 'lodash/range';

import {
    GenericEntityBlockSkeleton,
    GenericEntityBlockSkeletonProps,
} from '@lumapps/lumx-blocks/components/GenericEntityBlockSkeleton';
import { IMAGE_SHAPE } from '@lumapps/lumx-blocks/constants';
import { ListDivider, ListItem, ListItemProps } from '@lumapps/lumx/react';

export type UserBlockSkeletonProps = Partial<GenericEntityBlockSkeletonProps> & {
    listItemProps?: Partial<ListItemProps>;
    withDivider?: boolean;
    total?: number;
};

export const UserBlockSkeleton: React.FC<UserBlockSkeletonProps> = (props) => {
    return (
        <GenericEntityBlockSkeleton
            hasImage
            hasAdditionalInformation
            thumbnailSize="m"
            imageShape={IMAGE_SHAPE.CIRCLE}
            hAlign="center"
            titleSkeletonWidth="20%"
            attributeSkeletonWidths={['20%', '10%']}
            {...props}
        />
    );
};

export const UserBlockSkeletons: React.FC<UserBlockSkeletonProps> = ({
    total = 3,
    listItemProps,
    withDivider = false,
    ...props
}) => {
    return (
        <>
            {range(total).map((i, index) => {
                return (
                    <React.Fragment key={i}>
                        <ListItem {...listItemProps}>
                            <UserBlockSkeleton {...props} />
                        </ListItem>

                        {withDivider && index < total - 1 ? <ListDivider /> : undefined}
                    </React.Fragment>
                );
            })}
        </>
    );
};
