import { NotificationMeta } from './types';
import { notificationTypeArticle } from './utils/notificationType/notificationTypeArticle';
import { notificationTypeComment } from './utils/notificationType/notificationTypeComment';
import { notificationTypeCommunity } from './utils/notificationType/notificationTypeCommunity';
import { notificationTypeContent } from './utils/notificationType/notificationTypeContent';
import { notificationTypeCustom } from './utils/notificationType/notificationTypeCustom';
import { notificationTypeEvent } from './utils/notificationType/notificationTypeEvent';
import { notificationTypeLearning } from './utils/notificationType/notificationTypeLearning';
import { notificationTypePost } from './utils/notificationType/notificationTypePost';
import { notificationTypeSA } from './utils/notificationType/notificationTypeSA';

/**
 * The list of notification and how we render them, host some logic that need
 * to be move to backend in the Notification service.
 *
 * One file per type (Content, Comment, SA, ...).
 *
 * To add a new notification, add this notification in the corresponding file (or create a new file)
 * A notification need to have a `hasThumbnail` and an iconProps` properties, and functions to compute description and redirection
 */
const NOTIFICATIONS_META: Record<string, NotificationMeta> = {
    ...notificationTypeArticle,
    ...notificationTypeComment,
    ...notificationTypeCommunity,
    ...notificationTypeContent,
    ...notificationTypeCustom,
    ...notificationTypeEvent,
    ...notificationTypeLearning,
    ...notificationTypePost,
    ...notificationTypeSA,
};

const getNotificationMeta = (type: string) => {
    return NOTIFICATIONS_META[type] || {};
};

export { getNotificationMeta };
