(function IIFE() {
    'use strict';

    /////////////////////////////

    function FolderService(Customer, FolderFactory, Instance, LumsitesBaseService, Translation) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(FolderFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The parameters for default search results in abstract picker.
         *
         * @type {Object}
         */
        service.defaultPickerParams = {
            sortOrder: 'name',
        };

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Create folder with name and description.
         *
         * @param  {Object}   folder  The folder object to create.
         * @param  {Function} cb      The succes callback function.
         * @param  {Function} errorCb The error callback function.
         * @param  {string}   listKey The identifier of the list that is linked to the display of the loader.
         * @return {Object}   The folder object we create.
         */
        function createFolder(folder, cb, errorCb, listKey) {
            if (angular.isUndefinedOrEmpty(folder)) {
                return false;
            }

            return FolderFactory.folder({
                customer: Customer.getCustomerId(),
                description: folder.description,
                instance: Instance.getCurrentInstanceId(),
                lang: Translation.inputLanguage,
                name: folder.name,
                parentFolder: folder.parentFolder,
            }, cb, errorCb, listKey);
        }
        /**
         * Get folder icon depending on the folder object.
         *
         * @param  {Object} folder The folder object to get the folder icon of.
         * @return {string} The icon classname.
         */
        function getFolderIcon(folder) {
            if (angular.isDefinedAndFilled(_.get(folder, 'hasChild'))) {
                return 'mdi-folder-multi';
            }

            return 'mdi-folder';
        }

        /**
         * Move the folder to another Folder.
         *
         * @param  {string}   folderId       The folderId we want to move.
         * @param  {string}   parentFolderId The parentFolderId to move the folder to.
         * @param  {Function} cb             The succes callback function.
         * @param  {Function} errorCb        The error callback function.
         * @param  {string}   listKey        The identifier of the list that is linked to the display of the loader.
         * @return {Object}   The folder we moved.
         */
        function moveFolder(folderId, parentFolderId, cb, errorCb, listKey) {
            if (angular.isUndefinedOrEmpty(folderId) || angular.isUndefinedOrEmpty(parentFolderId)) {
                return false;
            }

            return FolderFactory.move({
                customer: Customer.getCustomerId(),
                destinationFolderId: parentFolderId,
                itemId: folderId,
                lang: Translation.inputLanguage,
            }, cb, errorCb, listKey);
        }

        /**
         * Update folder with name and description.
         *
         * @param  {Object}   folder  The folder object to update.
         * @param  {Function} cb      The succes callback function.
         * @param  {Function} errorCb The error callback function.
         * @param  {string}   listKey The identifier of the list that is linked to the display of the loader.
         * @return {Object}   The folder object we update.
         */
        function updateFolder(folder, cb, errorCb, listKey) {
            if (angular.isUndefinedOrEmpty(folder)) {
                return false;
            }

            var folderName = folder.name;
            if (angular.isString(folderName)) {
                folderName = {};
                folderName[Translation.inputLanguage] = folder.name;
            }

            return FolderFactory.folder({
                customer: Customer.getCustomerId(),
                instance: Instance.getCurrentInstanceId(),
                isShared: folder.isShared,
                lang: Translation.inputLanguage,
                name: folderName,
                parentFolder: folder.parentFolder,
                uid: folder.uid,
            }, cb, errorCb, listKey);
        }

        /////////////////////////////

        service.createFolder = createFolder;
        service.getFolderIcon = getFolderIcon;
        service.moveFolder = moveFolder;
        service.updateFolder = updateFolder;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Folder', FolderService);
})();
