/* istanbul ignore file */
import { getGroupPublic, getGroupAll } from '@lumapps/customer/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { GroupPickerField, GroupPickerFieldProps } from './GroupPickerField';

const matchStateToProps = (state: BackOfficeStore, props: GroupPickerFieldProps) => {
    return {
        language: currentLanguageSelector(state),
        instance: props.instance || instanceIdSelector(state),
        groupPublic: getGroupPublic(state),
        groupAll: getGroupAll(state),
    };
};

const ConnectedGroupPickerField = connect(matchStateToProps)(GroupPickerField);

export { ConnectedGroupPickerField as GroupPickerField };
export type { GroupPickerFieldProps };
