/**
 * Use this character instead of adding three dots next to each others.
 * This will allow screen to read it as "ellipsis" and the dots will be managed as a single character.
 */
export const ELLIPSIS = '…';
/**
 * This may seem like a regular `#` symbol, but it's not.
 * It's actually a special character.
 *
 * Screen readers also read it as "hashtag".
 */
export const POUND = '＃';

/** Dot separator wrapped in non breaking spaces. */
export const DATA_LISTING_SEPARATOR = '\u00a0•\u00a0';
