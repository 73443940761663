import last from 'lodash/last';
import partial from 'lodash/partial';
import take from 'lodash/take';

import { Editor, Path, PathRef, Transforms } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';

import { getLowestListItemsInRange } from '../../utils/getLowestListItemsInRange';
import { isListItem } from '../../utils/isListItem';

/**
 * Move list item down a level.
 */
function decreaseListItemIndent(editor: Editor, pathRef: PathRef) {
    const [node, path] = Editor.node(editor, pathRef.current as Path);
    if (!isListItem(node)) {
        return false;
    }

    // Find grand parent list.
    const [list, listPath] = Editor.parent(editor, path);
    const [, parentListItemPath] = findParent(editor, path, isListItem) ?? [];
    Editor.withoutNormalizing(editor, () => {
        if (!parentListItemPath) {
            const position = last(pathRef.current as Path);

            // Split parent list.
            Transforms.splitNodes(editor, { at: pathRef.current as Path });
            if (position !== list.children.length - 1) {
                Transforms.splitNodes(editor, { at: getSiblingPath(pathRef.current as Path, 'after') as Path });
            }

            // Unwrap list and list item.
            const insertUlPath = take(pathRef.current as Path);
            Transforms.unwrapNodes(editor, { at: pathRef.current as Path });
            Transforms.unwrapNodes(editor, { at: insertUlPath });
        } else {
            // Move in parent list item.

            Transforms.splitNodes(editor, { at: path });
            const splitPath = getSiblingPath(listPath, 'after') as Path;
            const insertPath = getSiblingPath(parentListItemPath, 'after') as Path;
            Transforms.moveNodes(editor, { at: [...splitPath, 0], to: insertPath });
            Transforms.moveNodes(editor, { at: splitPath, to: [...insertPath, node.children.length] });
        }
    });

    pathRef.unref();
    return true;
}

/**
 * Move list item down a level (at editor selection).
 *
 * @param  editor The editor.
 * @return        `true` if the list item could be moved; `false` otherwise.
 */
export function decreaseListIndent(editor: Editor): boolean {
    if (!editor.selection) {
        return false;
    }
    // Get list items in at Range, if not provided, editor.selection is used as fallback.
    const listItems = getLowestListItemsInRange(editor, editor.selection);
    const listItemPathRefs = listItems.map(([, path]) => Editor.pathRef(editor, path));
    if (!listItemPathRefs.length) {
        return false;
    }
    listItemPathRefs.forEach(partial(decreaseListItemIndent, editor));
    return true;
}
