import { Offset, Placement } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations/keys';
import { ContactFieldType, ProfileFieldType } from '@lumapps/user/types';

import { UserProfileNavigationItemsType, USER_PROFILE_VIEW } from './types';

export const USER_PROFILE_NAVIGATION_LABELS: Record<USER_PROFILE_VIEW, string> = {
    [USER_PROFILE_VIEW.activity]: GLOBAL.ACTIVITY,
    [USER_PROFILE_VIEW.about]: GLOBAL.ABOUT,
};

/**
 * Navigation items displayed in the react user profile.
 */
export const USER_PROFILE_NAVIGATION_ITEMS: Array<UserProfileNavigationItemsType> = [
    // Activity
    {
        view: USER_PROFILE_VIEW.activity,
        label: USER_PROFILE_NAVIGATION_LABELS[USER_PROFILE_VIEW.activity],
        displayedWhen: {
            hasSocial: true,
        },
    },
    // About Page
    {
        view: USER_PROFILE_VIEW.about,
        label: USER_PROFILE_NAVIGATION_LABELS[USER_PROFILE_VIEW.about],
    },
];

/** Get navigation items depending on display conditions.
 * TODO: simplify this part when removing the pages
 */
export const getUserProfileNavigationItems = (displayedWhen: UserProfileNavigationItemsType['displayedWhen'] = {}) => {
    return USER_PROFILE_NAVIGATION_ITEMS.filter((item) => {
        if (!item.displayedWhen || !displayedWhen) {
            return true;
        }
        const conditions = Object.entries(item.displayedWhen);
        return conditions.every(
            ([key, value]) => displayedWhen[key as keyof UserProfileNavigationItemsType['displayedWhen']] === value,
        );
    });
};

/**
 * All fields managed by the user list details.
 * All other types will be ignored
 * */
export const USER_DETAILS_MANAGED_FIELDS: Array<string> = [
    ContactFieldType.email,
    ContactFieldType.phone,
    ContactFieldType.mobilePhone,
    ProfileFieldType.text,
    ProfileFieldType.user,
    ProfileFieldType.metadata,
    ProfileFieldType.float,
    ProfileFieldType.date,
];

/** The user space feature flag token.  */
export const USER_SPACE_FEATURE_TOKEN = 'user-space';

/** The user profile react feature flag token.  */
export const USER_PROFILE_REACT_FEATURE_TOKEN = 'user-profile-react';

/**
 * The placeholder used in the profile route to display the current user's profile.
 * */
export const CURRENT_USER_ID_ROUTE_PLACEHOLDER = 'me';

/**
 * The placement and offset of the user menu in the header of the user profile.
 * */
export const USER_MENU_OFFSET: Offset = { away: 8 };
export const USER_MENU_PLACEMENT = Placement.BOTTOM_END;

/** Scope for the user profile page */
export const USER_PROFILE_SCOPE = 'user-profile';
