/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isModuleAllowed } from '../../ducks/selectors';
import { ModuleSideNavigation } from './ModuleSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isModuleAllowed(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedModuleSideNavigation = connect(mapStateToProps, mapDispatchToProps)(ModuleSideNavigation);

export { ConnectedModuleSideNavigation as ModuleSideNavigation };
