import React from 'react';

import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { SelectFieldMultiple } from '@lumapps/combobox/components/SelectFieldMultiple';
import { renderGroupedChoice } from '@lumapps/combobox/components/SelectFieldMultiple/renderChoicesFactories';
import { Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { usePaginatedFrontendSearch } from '@lumapps/utils/hooks/usePaginatedFrontendSearch';

import { useAvailableTags } from '../../hooks/useAvailableTags';
import { FormattedSiteFilter, FormattedTagFilter, SiteFilteredBy, TagFilteredByReference } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

export interface CustomContentTypeTagsFilterProps {
    theme: Theme;
    scope: string;
    /** Whether the filter should be hidden if there is no available tags. */
    shouldBeHiddenIfEmpty?: boolean;
    /** The list of selected tags */
    selectedTags?: FormattedTagFilter[];
    /** The list of tag references that can be used. */
    tagReferences?: TagFilteredByReference[];
    /** The list of sites related to the content types. */
    sites?: FormattedSiteFilter[];
    /** The list of site references available for the filter. */
    siteReferences?: SiteFilteredBy[];
    /** Does siblings sites are included in the site */
    includeSiblingSites?: boolean;
    /** Number of items per page */
    itemsPerPage?: number;
    /** The callback that will be trigger on each value change of the select */
    onChange: (tags: FormattedTagFilter[]) => void;
    hideSubheader: boolean;
}

const getTagName = (t: FormattedTagFilter) => t.name;

/**
 * A select field to select multiple content type tags,
 * filtered by content type ids.
 * The list of tags will be grouped by ccts.
 *
 * @param CustomContentTypeTagsFilterProps
 * @returns CustomContentTypeTagsFilter
 */
export const CustomContentTypeTagsFilter: React.FC<CustomContentTypeTagsFilterProps> = ({
    scope,
    sites,
    siteReferences,
    includeSiblingSites,
    selectedTags = [],
    tagReferences,
    shouldBeHiddenIfEmpty,
    onChange,
    hideSubheader,
    theme = Theme.light,
    itemsPerPage = 20,
}) => {
    const { translateKey } = useTranslate();

    /** Get all available tags for the current configuration; based on the selected sites. */
    const availableTags = useAvailableTags(tagReferences, sites, siteReferences, includeSiblingSites);

    const { getMoreItems, items, searchQuery, onSearch } = usePaginatedFrontendSearch({
        itemList: availableTags ?? [],
        perPage: itemsPerPage,
        getItemName: getTagName,
    });

    /**
     * Group tags by their custom content types.
     * Tags are always linked to a CCT so tag options should be contextualised
     * because some tags can share the same names, and the only way to differentiate
     * them is to know their parents.
     *
     * We are formatting the data to use the renderGroupChoice function that can
     * create select options grouped by sections.
     * */
    const groups = React.useMemo(() => {
        const groupedItems = groupBy(items, (c) => `${c.site?.id}_${c.cct?.id}`);

        return Object.values(groupedItems).map((tags) => {
            const [firstItem] = tags;

            const siteName = firstItem.site?.name;
            const cctName = firstItem.cct?.name;

            return { group: { siteName, cctName }, children: tags };
        });
    }, [items]);

    const [isOpen, setOpen] = React.useState(false);

    if (shouldBeHiddenIfEmpty && isEmpty(tagReferences)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.TAGS)} theme={theme} />}
            <SelectFieldMultiple<FormattedTagFilter>
                theme={theme}
                scope={scope}
                onInfiniteScroll={getMoreItems}
                onSearch={onSearch}
                getValueId={(tag) => tag.id}
                getValueName={(tag) => tag.name}
                searchFieldProps={{
                    isCompact: true,
                }}
                searchText={searchQuery}
                onChange={onChange}
                label={translateKey(GLOBAL.TAGS)}
                value={selectedTags}
                isOpen={isOpen}
                setOpen={setOpen}
                choices={renderGroupedChoice<
                    { group: { cctName?: string; siteName?: string }; children: FormattedTagFilter[] },
                    FormattedTagFilter,
                    { cctName?: string; siteName?: string }
                >({
                    groups,
                    getGroupName: (g) => {
                        return `${g.cctName} - ${g.siteName}`;
                    },
                    getValueName: (tag) => tag.name,
                    getValueId: (tag) => tag.id,
                })}
            />
        </>
    );
};
