export enum ASK_AI {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460423028
     */
    NO_RESOURCES_TITLE = 'ASK_LUMAPPS.NO_RESOURCES_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460423356
     */
    ERROR_DESC = 'ASK_LUMAPPS.ERROR_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460428052
     */
    ERROR_TITLE = 'ASK_LUMAPPS.ERROR_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460866431
     */
    BAD_RESPONSE = 'ASK_LUMAPPS.BAD_RESPONSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=465396681
     */
    SHOW_RELATED_RESOURCES = 'ASK_LUMAPPS.SHOW_RELATED_RESOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=465396682
     */
    HIDE_RELATED_RESOURCES = 'ASK_LUMAPPS.HIDE_RELATED_RESOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484534932
     */
    VISIBLE_TO_ADMINISTRATORS = 'ASK_LUMAPPS.VISIBLE_TO_ADMINISTRATORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=489936308
     */
    RULE_BASED_RESULT = 'ASK_LUMAPPS.RULE_BASED_RESULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=534611171
     */
    GOOD_RESPONSE = 'ASK_AI.GOOD_RESPONSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=535026188
     */
    FEEDBACK_REASON_TITLE = 'ASK_AI.FEEDBACK_REASON_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536209057
     */
    FEEDBACK_REASON_IRRELEVANT = 'ASK_AI.FEEDBACK_REASON_IRRELEVANT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=536209051
     */
    FEEDBACK_REASON_INCORRECT = 'ASK_AI.FEEDBACK_REASON_INCORRECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=535804465
     */
    FEEDBACK_SENT = 'ASK_AI.FEEDBACK_SENT',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=513475491
     */
    ASK_ANYTHING = 'ASK_AI.ASK_ANYTHING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546988623
     */
    AUTHORIZATION_ERROR_WARNING = 'ASK_AI.AUTHORIZATION_ERROR_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=548679960
     */
    AUTHORIZATION_SUCCESS_MESSAGE = 'ASK_AI.AUTHORIZATION_SUCCESS_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563179654
     */
    HOW_DESCRIBE_ANSWER = 'ASK_AI.HOW_DESCRIBE_ANSWER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563181188
     */
    HOW_CAN_ANSWER_BE_IMPROVED = 'ASK_AI.HOW_CAN_ANSWER_BE_IMPROVED',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=460509904
     */
    SEARCH_AI = 'ASK_LUMAPPS_ADMIN.SEARCH_AI',
}
