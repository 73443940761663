import React, { PureComponent } from 'react';
import { bool, func, string } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';

import { ConfirmDialog } from '../../ui/dialogs/confirm';

import { translate as t } from '../../../translations';
import { trash } from '../../../services/document';
// eslint-disable-next-line id-blacklist
import { error, success } from '../../../services/notification';

/**
 * Open a dialog to edit a document name.
 */
class DeleteDocumentDialog extends PureComponent {
    constructor(props) {
        super(props);

        this._INITIAL_STATE = {
            loading: false,
        };
        this.state = cloneDeep(this._INITIAL_STATE);

        this.handleClose = this.handleClose.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
    }

    /**
     * Reset state and call onClose props method.
     */
    handleClose() {
        const { onClose } = this.props;

        this.setState(cloneDeep(this._INITIAL_STATE));
        onClose();
    }

    /**
     * Makes delete request, reset state and call onValidate props method.
     */
    async handleValidate() {
        const { onValidate } = this.props;

        await this.delete();
        this.setState(cloneDeep(this._INITIAL_STATE));
        onValidate();
    }

    /**
     * Delete document.
     *
     * @return {Promise} The delete promise.
     */
    delete() {
        const { docPath, type } = this.props;

        this.setState({ loading: true });

        return trash(docPath).then(
            () => {
                success(t(`FRONT.DOCUMENT.DELETE.${type}.SUCCESS`));
            },
            () => {
                error(t(`FRONT.DOCUMENT.DELETE.${type}.ERROR`));
            },
        );
    }

    render() {
        const { onClose, open: openDialog } = this.props;
        const { loading } = this.state;

        return (
            <ConfirmDialog
                description="WIDGET_TYPE_MEDIA_DELETE_DESCRIPTION"
                isLoading={loading}
                isOpen={openDialog}
                title="WIDGET_TYPE_MEDIA_DELETE"
                onClose={onClose}
                onValidate={this.handleValidate}
            />
        );
    }
}

/////////////////////////////

DeleteDocumentDialog.propTypes = {
    /** Display input language selector. */
    canChangeLanguage: bool,
    /** DocPath of document we want to remove. */
    docPath: string.isRequired,
    /** Called when closing dialog. */
    onClose: func.isRequired,
    /** Called when the document has been deleted. */
    onValidate: func.isRequired,
    /** Is dialog open. */
    open: bool.isRequired,
    /** Type of media (FILE or FOLDER). */
    type: string.isRequired,
};

DeleteDocumentDialog.defaultProps = {
    canChangeLanguage: false,
};

/////////////////////////////

export { DeleteDocumentDialog };
