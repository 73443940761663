import isEmpty from 'lodash/isEmpty';

/**
 * Construct an AI summary prompt
 * @param content the textual content that needs to be summarized
 * @param title the title that represent this content
 * @param targetLang the wanted summary language (use language code or full language name)
 * @returns the summary prompt to be used with getCompletions()
 */
export const getSummaryPrompt = (content: string, title?: string, targetLang?: string) => {
    if (isEmpty(content)) {
        return '';
    }

    const contentExample =
        "The History of Artificial Intelligence Artificial intelligence (AI) is the simulation of human intelligence processes by machines, especially computer systems. AI research has been highly successful in developing effective techniques for solving a wide range of problems, from game playing to medical diagnosis. The Early Days of AI The history of AI can be traced back to the early days of computing. In the 1940s and 1950s, a number of scientists and engineers began to explore the possibility of creating machines that could think for themselves. Among the pioneers of AI were Alan Turing, John von Neumann, and Norbert Wiener. In 1950, Turing published a paper entitled 'Computing Machinery and Intelligence,' in which he proposed a test for determining whether a machine is intelligent. The Turing test involves a human interrogator who converses with a machine and a human being, both of whom are hidden from the interrogator. If the interrogator cannot reliably distinguish between the machine and the human, then the machine is said to have passed the Turing test.";
    const contentSummaryExample =
        "AI simulates human intelligence processes using machines, particularly computer systems. AI research has achieved success in solving various problems, from gaming to medical diagnosis. The history of AI dates back to the early days of computing, with pioneers like Alan Turing, John von Neumann, and Norbert Wiener. Turing proposed the Turing test in 1950 to assess a machine's intelligence.";
    let prompt =
        "Generate a comprehensive and short summary of my content in less than 500 characters, do not write the content title again, do not tell me it's a summary and never ever talk to me about the provided example. The content can contains multiple language, use all of them to make a summary. Do not use markdown, list and table.";

    if (targetLang) {
        prompt = prompt.concat(' ', `Make sure to return the summary in the ${targetLang} locale.`);
    }

    const examplePrompt = `### Example Title: The History of Artificial Intelligence Content: ${contentExample} Summary: ${contentSummaryExample} ### Your turn`;
    prompt = prompt.concat(' ', examplePrompt);

    if (title) {
        prompt = prompt.concat(' ', `Title: ${title}`);
    }

    prompt = prompt.concat(' ', `Content: ${content.replace(/\n|\r/g, '')} Summary: `);

    return prompt;
};
