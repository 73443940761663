import { Editor, Path } from 'slate';

/**
 * Get last root path in editor's content.
 */
export const getLastRootPath = (editor: Editor): Path => {
    const { length } = editor.children;
    if (!length) {
        return [];
    }
    return [length - 1];
};
