import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { TextField, Theme, Typography } from '@lumapps/lumx/react';
import { ClickAwayProvider } from '@lumapps/lumx/react/utils';
import { useTranslate } from '@lumapps/translations';

import { CLASSNAME, EDITION_INTRO_TEXTAREA_CLASSNAME } from '../../constants';
import { WIDGET_INTRO_KEYS } from '../../keys';

import './index.scss';

export interface EditableWidgetIntroProps {
    wrapperRef: React.MutableRefObject<null>;
    childrenRefs: React.MutableRefObject<React.MutableRefObject<null>[]>;
    exitEditionMode: (content: string) => void;
    content: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    theme?: Theme;
}

export const EditableWidgetIntro: React.FC<EditableWidgetIntroProps> = ({
    wrapperRef,
    childrenRefs,
    exitEditionMode,
    content,
    onChange,
    theme = Theme.light,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);

    return (
        <ClickAwayProvider callback={() => exitEditionMode(content)} childrenRefs={childrenRefs}>
            <div className={element('editable-wrapper')}>
                <TextField
                    typography={Typography.custom.intro}
                    className={element(EDITION_INTRO_TEXTAREA_CLASSNAME)}
                    value={content}
                    placeholder={translateKey(WIDGET_INTRO_KEYS.PLACEHOLDER)}
                    onChange={onChange}
                    theme={theme}
                    multiline
                    ref={wrapperRef}
                    autoFocus
                />
            </div>
        </ClickAwayProvider>
    );
};
