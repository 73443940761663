export enum WIDGET_SITE_LIST {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189306329
     */
    ADD_TO_MY_SITES = 'WIDGET_SITE_LIST.ADD_TO_MY_SITES',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189306375
     */
    REMOVE_FROM_MY_SITES = 'WIDGET_SITE_LIST.REMOVE_FROM_MY_SITES',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189306424
     */
    USER_FAVORITES_ADD = 'WIDGET_SITE_LIST.USER_FAVORITES_ADD',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=198134925
     */
    USER_FAVORITES_DELETE = 'WIDGET_SITE_LIST.USER_FAVORITES_DELETE',
}
