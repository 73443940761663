export enum USER_SAVED_ITEMS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=168422413
     */
    SAVED_ITEMS = 'USER_SAVED_ITEMS.SAVED_ITEMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171185081
     */
    PRIVATE_FROM_OTHERS = 'USER_SAVED_ITEMS.PRIVATE_FROM_OTHERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170097774
     */
    SAVE_UNAVAILABLE = 'USER_SAVED_ITEMS.SAVE_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172939926
     */
    CLEAR_ITEMS = 'USER_SAVED_ITEMS.CLEAR_ITEMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172939930
     */
    ITEMS_WILL_BE_REMOVED = 'USER_SAVED_ITEMS.ITEMS_WILL_BE_REMOVED',
}
