import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const nannyAdminRoute: Route = {
    path: '/admin/sensitive-data-flagging',
    legacyId: 'app.admin.nanny',
    appId: AppId.backOffice,
};

/**
 * Retrieves the route for nanny's admin.
 */
const admin = (): Route => {
    return nannyAdminRoute;
};

export { admin, nannyAdminRoute };
