import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Button, ButtonProps, FlexBox, FlexBoxProps, Heading, HeadingProps, Text } from '@lumapps/lumx/react';

import { ColorCard, ColorCardProps } from '../ColorCard';
import { ActionableCardSubtitle, ActionableCardSubtitleProps } from './ActionableCardSubtitle';

import './index.scss';

export interface ActionableCardProps extends ColorCardProps {
    /** title to display on the card */
    title: string;
    /** additional props for the title */
    titleProps?: Partial<HeadingProps>;
    /** subtitle to display on the card  */
    subtitle?: ActionableCardSubtitleProps;
    /** action description, displayed below the subtitle */
    description?: React.ReactNode;
    /** action to be displayed on the right hand side of the component */
    action: ButtonProps | React.ReactNode;
    /** element to be displayed after the title */
    afterTitle?: React.ReactNode;
    /** displayed after the action */
    afterAction?: React.ReactNode;
    /** props to pass in to the flexbox wrapper */
    wrapperProps?: Partial<FlexBoxProps>;
    /** element to display after the description */
    afterDescription?: React.ReactNode;
}

const CLASSNAME = 'lumx-actionable-card';
/**
 * Compontent showing a card that displays a title, a subtitle, a small description and an action on the right hand side
 * @param ActionableCardProps
 * @returns ActionableCard
 */
export const ActionableCard: React.FC<ActionableCardProps> = ({
    title,
    subtitle,
    description,
    action,
    afterTitle,
    titleProps = {},
    afterAction,
    wrapperProps = {},
    afterDescription,
    ...props
}) => {
    const { element } = useClassnames(CLASSNAME);
    const Action = React.isValidElement(action) ? action : <Button emphasis="medium" {...(action as ButtonProps)} />;

    return (
        <ColorCard color="dark" {...props}>
            <FlexBox orientation="horizontal" vAlign="space-between" hAlign="top" {...wrapperProps}>
                <FlexBox orientation="vertical" gap="tiny">
                    <Heading
                        as="h3"
                        typography="subtitle2"
                        {...titleProps}
                        className={element('title', [titleProps.className])}
                    >
                        {title}

                        {afterTitle}
                    </Heading>

                    {subtitle ? <ActionableCardSubtitle {...subtitle} /> : null}

                    {description && typeof description === 'string' ? (
                        <Text as="p" typography="body1" colorVariant="L2" color="dark">
                            {description}
                        </Text>
                    ) : null}

                    {description && React.isValidElement(description) ? description : null}

                    {afterDescription}
                </FlexBox>

                {afterAction ? (
                    <FlexBox orientation="horizontal">
                        {Action}
                        {afterAction}
                    </FlexBox>
                ) : (
                    Action
                )}
            </FlexBox>
        </ColorCard>
    );
};
