(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetStockExchangeSettingsController($rootScope, $timeout, Widget) {
        'ngInject';

        var widgetStockExchangeSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * List of available decimal separators.
         *
         * @type {Array}
         */
        widgetStockExchangeSettings.decimalSeparators = ['.', ','];

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Update currency on settings change.
         */
        function updateCurrency() {
            $timeout(function onSettingsChange() {
                $rootScope.$broadcast('widget-stock-exchange-settings-currency', Widget.getCurrent().uuid);
            });
        }

        /**
         * Update widget on settings change.
         */
        function updateWidget() {
            $timeout(function onSettingsChange() {
                $rootScope.$broadcast('widget-stock-exchange-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetStockExchangeSettings.updateCurrency = updateCurrency;
        widgetStockExchangeSettings.updateWidget = updateWidget;
    }

    /////////////////////////////

    // eslint-disable-next-line max-len
    angular.module('Controllers').controller('WidgetStockExchangeSettingsController', WidgetStockExchangeSettingsController);
})();
