import BaseApi from '@lumapps/base-api';
import { getLanguageHeader } from '@lumapps/play';

import type { GetIframeParamsDTO, GetIframeResponseDTO, VideoId } from './types';

export const PATH = 'videos';

export const api = new BaseApi({
    path: PATH,
    version: BaseApi.versions.v2,
});

/**
 * Query keys object used to manage React Query keys
 *
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const videoPlayerQueryKeys = {
    all: [PATH] as const,
    iframe: (videoId: VideoId, params?: GetIframeParamsDTO) =>
        [...videoPlayerQueryKeys.all, videoId, 'iframe', params] as const,
};

/**
 * Function used to get the iframe html displaying a Play video player
 */
export const getIframe = async (videoId: VideoId, params?: GetIframeParamsDTO, signal?: AbortSignal) => {
    const { data: iframeHtml } = await api.get<GetIframeResponseDTO>(`/${videoId}/iframe`, {
        headers: { 'Accept-Language': getLanguageHeader() },
        params,
        signal,
    });

    return iframeHtml;
};
