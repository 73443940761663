/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

export const communityEventsRoute: Route = {
    path: `/${URL_PREFIX}/community/:slug/events`,
    appId: AppId.frontOffice,
};

export const communityEvents = (slug: string): Route => {
    return {
        ...communityEventsRoute,
        params: {
            slug,
        },
    };
};
