(function IIFE() {
    'use strict';

    /////////////////////////////

    function DataTableService(Utils) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The property to use as the identifier of the object.
         *
         * @type {string}
         */
        var _identifier;

        /**
         * Contains the reference to the list of items.
         *
         * @type {Array|Function}
         */
        var _listReference;

        /**
         * Contains the selected items in the data table.
         *
         * @type {Array}
         */
        var _selectedItems = [];

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the list of items.
         * If the list reference is a function, call it. Else directly use the list reference.
         *
         * @return {Array} The list of items.
         */
        function _getList() {
            return (angular.isFunction(_listReference)) ? _listReference() : _listReference;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if all the data table items are selected.
         *
         * @return {boolean} If all the items are selected.
         */
        function areAllItemsSelected() {
            return service.hasSelectedItems() && (service.getTableLength() === _selectedItems.length);
        }

        /**
         * Destroy the service and clean all references.
         */
        function destroy() {
            _listReference = undefined;
            _identifier = undefined;
            _selectedItems = [];
        }

        /**
         * Return the first selected element.
         *
         * @return {Object} The first selected item.
         */
        function getFirstSelectedItem() {
            if (!service.hasSelectedItems()) {
                return undefined;
            }

            var firstSelectedItemId = _selectedItems[0];

            return _.find(_getList(), function findFirstSelectedItem(item) {
                return item[_identifier] === firstSelectedItemId;
            });
        }

        /**
         * Get all the non selected items of the list.
         *
         * @param  {boolean} [identifierOnly=false] Indicates if we want to get only the ids of the objects or the full
         *                                          objects.
         * @return {Array}   The non selected items.
         */
        function getNonSelectedItems(identifierOnly) {
            if (!service.hasSelectedItems()) {
                return (identifierOnly) ? _.map(_getList(), _identifier) : angular.fastCopy(_getList());
            }

            var nonSelectedItems = [];

            angular.forEach(_getList(), function forEachItems(item) {
                if (!service.isSelected(item)) {
                    nonSelectedItems.push((identifierOnly) ? item[_identifier] : item);
                }
            });

            return nonSelectedItems;
        }

        /**
         * Return the selected items of the list.
         *
         * @param  {boolean} [identifierOnly=false] Indicates if we want to get only the ids of the objects or the full
         *                                          objects.
         * @return {Array}   The selected items.
         */
        function getSelectedItems(identifierOnly) {
            if (identifierOnly) {
                return _selectedItems;
            }

            var selectedItems = [];

            angular.forEach(_getList(), function forEachItems(item) {
                if (service.isSelected(item[_identifier])) {
                    selectedItems.push(item);
                }
            });

            return selectedItems;
        }

        /**
         * Get the number of selected items.
         *
         * @return {number} The number of selected items.
         */
        function getSelectedItemsNumber() {
            return (_selectedItems || []).length;
        }

        /**
         * Return the number of items in the table.
         *
         * @return {number} The number of items in the table.
         */
        function getTableLength() {
            return (angular.isArray(_getList())) ? _getList().length : 0;
        }

        /**
         * Check if there are selected items.
         *
         * @return {boolean} If there are selected items or not.
         */
        function hasSelectedItems() {
            return angular.isDefinedAndFilled(_selectedItems);
        }

        /**
         * Initialize the data table.
         *
         * @param {Array|Function} items The items to display in the data table.
         * @param {string}         id    The identifier to uniquely identify an object (and avoid full object
         *                               manipulation).
         */
        function init(items, id) {
            _listReference = items;
            _identifier = id;
            _selectedItems = [];
        }

        /**
         * Check if the item is selected.
         *
         * @param  {Object}  item The item to check.
         * @return {boolean} If the item is selected or not.
         */
        function isSelected(item) {
            return _.includes(_selectedItems, item[_identifier]);
        }

        /**
         * Refresh the list with new items.
         *
         * @param {Array|Function} items The new list reference.
         */
        function refresh(items) {
            Utils.arrayRefresh(_getList(), angular.fastCopy(items));
            service.resetSelectedItems();
        }

        /**
         * Reset all the selected items.
         */
        function resetSelectedItems() {
            Utils.empty(_selectedItems);
        }

        /**
         * Toggle the selection state of an item.
         *
         * @param {Object} item The item to toggle.
         */
        function toggleItem(item) {
            var idx = _selectedItems.indexOf(item[_identifier]);

            if (idx === -1) {
                _selectedItems.push(item[_identifier]);
            } else {
                _selectedItems.splice(idx, 1);
            }
        }

        /**
         * Toggle the selected status of all items.
         * If all items are selected, then unselect all items. Else, select all items.
         */
        function toggleAllItems() {
            if (service.areAllItemsSelected()) {
                service.resetSelectedItems();
            } else {
                _selectedItems = _.map(_getList(), _identifier);
            }
        }

        /////////////////////////////

        service.areAllItemsSelected = areAllItemsSelected;
        service.destroy = destroy;
        service.getFirstSelectedItem = getFirstSelectedItem;
        service.getNonSelectedItems = getNonSelectedItems;
        service.getSelectedItems = getSelectedItems;
        service.getSelectedItemsNumber = getSelectedItemsNumber;
        service.getTableLength = getTableLength;
        service.hasSelectedItems = hasSelectedItems;
        service.init = init;
        service.isSelected = isSelected;
        service.refresh = refresh;
        service.resetSelectedItems = resetSelectedItems;
        service.toggleAllItems = toggleAllItems;
        service.toggleItem = toggleItem;
    }

    /////////////////////////////

    angular.module('Services').service('DataTable', DataTableService);
})();
