(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetBreakingNewsController($scope, Analytics, Config, Content, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The full list of CSS classes of the widget.
         *
         * @type {Array}
         */
        vm.classes = [];

        /**
         * Services and utilities.
         */
        vm.Content = Content;
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Compute the breaking news widget CSS classes.
         *
         * @return {Array} The widget class(es).
         */
        function _computeClasses() {
            Utils.empty(vm.classes);

            if (!angular.isArray(vm.classes)) {
                vm.classes = [];
            }

            vm.parentCtrl.getWidgetClass(vm.classes);

            if (vm.isWidgetEmpty()) {
                vm.classes.push('widget--is-empty');
            }

            return vm.classes;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the widget is empty or not.
         *
         * @return {boolean} Whether or not the widget is empty.
         */
        function isWidgetEmpty() {
            var listKey = vm.parentCtrl.getListKey();

            return !Content.isCallInProgress(listKey) &&
                angular.isUndefinedOrEmpty(Content.displayList(listKey));
        }

        /**
         * Indicates if the widget is hidden or not.
         *
         * @return {boolean} Whether or not the widget is hidden.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = !vm.parentCtrl.designerMode() &&
                !vm.widget.properties.noResults &&
                vm.isWidgetEmpty();

            return vm.parentCtrl.isHidden;
        }

        /**
         * Record analytics action when the content is clicked.
         *
         * @param {Object} content The content item that just got clicked on.
         */
        function openLink(content) {
            var current = Content.getCurrent();

            Analytics.handleTaggingMap('breaking-news', 'news', {
                isHomePage: (angular.isDefinedAndFilled(current)) ? current.isHomepage : false,
                item: content,
            });
        }

        /////////////////////////////

        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.openLink = openLink;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the style of the content list widget changes, re-compute the classes.
         *
         * @param {Event}  evt        The event.
         * @param {string} widgetUuid The identifier of the widget targeted by the event.
         */
        $scope.$on('widget-style-settings', function onWidgetStyleSettings(evt, widgetUuid) {
            if (vm.widget.uuid === widgetUuid) {
                _computeClasses();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            Content.filterize({
                isFeatured: true,
                maxResults: 1,
                sortOrder: ['-publicationDate', '-createdAt'],
                type: Config.AVAILABLE_CONTENT_TYPES.NEWS,
            }, undefined, undefined, vm.parentCtrl.getListKey());

            _computeClasses();
        };

        /**
         * Set the parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        vm.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    /////////////////////////////

    function WidgetBreakingNewsDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetBreakingNewsController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetBreakingNews', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-breaking-news/views/widget-breaking-news.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetBreakingNews', WidgetBreakingNewsDirective);
})();
