import React from 'react';

import { background, classnames, margin, padding } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { mdiClose, mdiExclamation } from '@lumapps/lumx/icons';
import { AlertDialog, ColorPalette, FlexBox, IconButton, Kind, Text } from '@lumapps/lumx/react';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getMetadataForFrontendVersion } from '../../api';
import { useWrongDeploymentWarning } from '../../hooks/useWrongDeploymentWarning';
import { VersionMetadata } from '../../types';

const { applicationId } = get();

export interface ToolbarProps {
    version: string | null;
}

/**
 * Displays a toolbar at the bottom of the page, in order to convey important information
 * to our developers
 * @param ToolbarProps
 * @returns Toolbar
 */
export const Toolbar: React.FC<ToolbarProps> = ({ version }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isToolbarDisplayed, setIsToolbarDisplayed] = React.useState(true);
    const deploymentWarning = useWrongDeploymentWarning({});

    const { response, status } = useFetchWithStatus<VersionMetadata>({
        fetchOnMount: Boolean(version),
        onFetch: getMetadataForFrontendVersion,
        initialFetchParams: {
            params: {
                version,
            },
        },
    });

    if (!version) {
        return null;
    }

    if (status === BaseLoadingStatus.loading || !response) {
        return null;
    }

    const isCurrentAppDeployed = response.apps.includes(applicationId);
    const isDeploymentOK = isCurrentAppDeployed && !deploymentWarning;

    return !isDeploymentOK && isToolbarDisplayed ? (
        <>
            <AlertDialog
                title="Current deployment"
                kind={Kind.warning}
                isOpen={isOpen}
                confirmProps={{
                    onClick: () => setIsOpen(false),
                    label: 'OK',
                }}
            >
                <Text as="p" typography="body1">
                    The frontend application you are looking at <b>WAS NOT</b> deployed with the current version, please
                    do not perform any tests on this app as they will not be testing the code deployed on the version
                </Text>
                <Text as="p" typography="body1">
                    {deploymentWarning}
                </Text>
                <br />
                <Text as="p" typography="body1">
                    <b>Deployed apps:</b> {response?.apps.join(', ')}
                </Text>
                <Text as="p" typography="body1">
                    <b>Current app:</b> {applicationId}
                </Text>
            </AlertDialog>
            <FlexBox
                className={classnames(background('dark', 'N'), padding('all', 'big'))}
                style={{
                    position: 'fixed',
                    bottom: '32px',
                    left: '45%',
                    zIndex: 1000000,
                    borderRadius: '4px',
                }}
            >
                <Text as="span" typography="body1" color="light" colorVariant="N">
                    Deployment warning
                </Text>
                <IconButton
                    className={margin('left')}
                    onClick={() => setIsOpen(true)}
                    icon={mdiExclamation}
                    label="Deployment has some warnings"
                    color={ColorPalette.yellow}
                    size="s"
                />
                <IconButton
                    className={margin('left')}
                    onClick={() => setIsToolbarDisplayed(false)}
                    icon={mdiClose}
                    label="Close"
                    hasBackground={false}
                    size="s"
                    emphasis="low"
                    theme="dark"
                />
            </FlexBox>
        </>
    ) : null;
};
