const DOMAIN_NAMESPACE = 'admin/widget';
const ACTIONS_PREFIX = `${DOMAIN_NAMESPACE}`;
const UPDATE_WIDGET_CONTENT = `${ACTIONS_PREFIX}/UPDATE_WIDGET_CONTENT`;

/**
 * Dispatch the UPDATE_WIDGET_CONTENT action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const updateWidgetContent = ({ lang, content }) => (dispatch) =>
    dispatch({
        payload: {
            content,
            lang,
        },
        type: UPDATE_WIDGET_CONTENT,
    });

export { UPDATE_WIDGET_CONTENT, updateWidgetContent };
