import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { get } from '@lumapps/constants';
import { TranslationAPI } from '@lumapps/translations/types';

import { Instance, InstanceLogo } from '../types';

const { logo: instanceLogo } = get();

/**
 * Retrieves the instance logo URL from the configured logo and fallback image
 */
const getCurrentInstanceLogoUrl = ({
    homeImageSrc,
    fallbackHomeImageSrc,
    translateObject,
}: {
    translateObject: TranslationAPI['translateObject'];
    /** The fallback image if there is an error with the homeImageSrc */
    fallbackHomeImageSrc: string;
    /** The image source of the company logo */
    homeImageSrc: string | Record<string, any>;
}) => {
    if (homeImageSrc) {
        if (isObject(homeImageSrc)) {
            const logoForLang = translateObject(homeImageSrc) as any;

            if (logoForLang) {
                return logoForLang.url;
            }

            return fallbackHomeImageSrc;
        }

        return homeImageSrc;
    }

    return fallbackHomeImageSrc;
};

/**
 * Retrieves the logo for the given instance
 * @param instance
 * @returns logo
 */
const getInstanceLogo = (instance: Instance): Record<string, InstanceLogo> => {
    const { logo } = instance;

    if (isArray(logo)) {
        return logo.reduce((values, l) => ({ ...values, [l.lang]: l }), {});
    }

    return logo || instanceLogo || {};
};

export { getCurrentInstanceLogoUrl, getInstanceLogo };
