import React, { SyntheticEvent, useMemo } from 'react';

import { SelectFieldSimpleProps } from '@lumapps/combobox/components/SelectFieldSimple';
import { currentLanguageSelector } from '@lumapps/languages';
import { LearningCatalogSelectField } from '@lumapps/learning-catalog/components/LearningCatalogSelectField';
import { useLearningCatalogSelectField } from '@lumapps/learning-catalog/hooks/useLearningCatalogSelectField';
import { CategoryData } from '@lumapps/learning-catalog/types';
import { FlexBox, Orientation, Size, Text } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { WIDGET_SETTINGS } from '@lumapps/widget-settings/keys';

import { LearningCatalogSettingsProps } from '../../types';

export const LearningCatalogSettings: React.FC<LearningCatalogSettingsProps> = ({ onChange, properties }) => {
    const { translateKey } = useTranslate();
    const locale = useSelector(currentLanguageSelector);

    const {
        selectFieldSimpleProps: { choices, ...selectCatalogProps },
        useQueryResults: { refetch, isError },
    } = useLearningCatalogSelectField(locale);

    const handleOnChange = (category?: CategoryData) => {
        onChange({
            categoryTitle: category?.title ?? '',
            categoryCode: category?.code ?? '',
        });
    };
    const handleSelectClear = (e: SyntheticEvent) => {
        e.preventDefault();
        handleOnChange();
    };

    const value = useMemo(
        () =>
            properties?.categoryCode
                ? ({ code: properties?.categoryCode, title: properties?.categoryTitle } as CategoryData)
                : null,
        [properties?.categoryCode, properties?.categoryTitle],
    );

    return (
        <FlexBox gap={Size.huge} orientation={Orientation.vertical}>
            <Text as="h4" typography="subtitle1" color="dark" colorVariant="N">
                {translateKey(WIDGET_SETTINGS.RESTRICTIONS_SECTION_TITLE)}
            </Text>
            <FlexBox gap={Size.regular}>
                <LearningCatalogSelectField
                    {...selectCatalogProps}
                    label={translateKey(GLOBAL.CATEGORY)}
                    hasLoadingError={isError}
                    onRetryLoading={refetch}
                    choices={choices}
                    value={value}
                    onChange={handleOnChange}
                    onSelectClear={handleSelectClear as SelectFieldSimpleProps['onSelectClear']}
                />
            </FlexBox>
        </FlexBox>
    );
};
