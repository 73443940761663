import {
    getSocialNetworkAccesses,
    getUser,
    disconnectSocialNetwork,
    connectSocialNetwork,
    verifySocialNetworkAccess,
} from '@lumapps/sa-connected-user/ducks';

import { fetchMembers } from '@lumapps/sa-members/api';
import { MemberType } from '@lumapps/sa-members/types';

import { setLoadingState, clearLoadingState } from 'components/components/ducks/loadingStates';

const DOMAIN = 'SA/USERS';

const GET_USERS_BY_ROLES = 'GET_USERS_BY_ROLES';

const FETCH_USERS_SUCCESS = `${DOMAIN}/FETCH_USERS_SUCCESS`;

const ADD_ROLE = `${DOMAIN}/ADD_ROLE`;

const DELETE_ROLE = `${DOMAIN}/DELETE_ROLE`;

export const getProgramMembers = (programId) => async (dispatch) => {
    dispatch(setLoadingState(GET_USERS_BY_ROLES));

    const { data } = await fetchMembers({ programId, maxResults: '1000', memberType: MemberType.user });
    await dispatch({
        type: FETCH_USERS_SUCCESS,
        users: data.items.map(({ user }) => user),
    });

    dispatch(clearLoadingState(GET_USERS_BY_ROLES));
};

export {
    GET_USERS_BY_ROLES,
    FETCH_USERS_SUCCESS,
    ADD_ROLE,
    DELETE_ROLE,
    getSocialNetworkAccesses,
    getUser,
    connectSocialNetwork,
    disconnectSocialNetwork,
    verifySocialNetworkAccess,
};
