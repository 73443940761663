import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { openPopupAndWait } from '@lumapps/marketplace/sdk';
import { sendWebviewMobileMessage } from '@lumapps/mobile-webview/message-emit';

export interface ConnectorLoginRedirectProps {
    connectorId: string;
    redirectUrl: string;
}

export const connectorLoginRedirect = async ({ connectorId, redirectUrl }: ConnectorLoginRedirectProps) => {
    const { applicationId } = getConstants();
    const isMobileWebView = applicationId === AppId.webview;

    if (isMobileWebView) {
        sendWebviewMobileMessage({
            type: 'redirect',
            payload: {
                type: 'connector-card',
                connectorId,
                url: redirectUrl,
            },
        });

        return;
    }

    await openPopupAndWait(redirectUrl);
};
