import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { DEFAULT_UNGROUPED_CONTENT_STYLE, WIDGET_CONTENT_STYLES } from '../constants';
import { WidgetContentStyles } from '../types';
import { computeStyles } from './computeStyles';

/** Compute widget content styles. */
export function computeWidgetContentStyles(style?: WidgetContentStyles, hasUngroupedChildren?: boolean) {
    const styles = computeStyles({
        // Use default ungrouped content styles if applicable.
        ...(hasUngroupedChildren ? DEFAULT_UNGROUPED_CONTENT_STYLE : undefined),
        // Override with custom styles.
        ...style,
    });

    // Widget content with fixed height should be scrollable.
    if (styles?.height) {
        styles.overflow = 'auto';
    }

    if (styles && hasUngroupedChildren) {
        const contentStyles = pick(styles, WIDGET_CONTENT_STYLES);
        const filteredStyles = omit(styles, WIDGET_CONTENT_STYLES);
        return {
            contentStyles: isEmpty(contentStyles) ? undefined : contentStyles,
            styles: isEmpty(filteredStyles) ? undefined : filteredStyles,
        };
    }
    return { styles };
}
