import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import type { PlayVideoId, PlayVideoTitle, PlayVideoType, PlayVideoURL } from '@lumapps/wrex/serialization/dita/types';

import type { PlayVideoElement } from '../../types';
import { createPlayVideo } from '../../utils/createPlayVideo';

/**
 * Convert Dita Play Video into slate Play Video element.
 */
export const PLAY_VIDEO_CONVERT: ElementConvertOption = {
    test: matchAttribute('type', /lumapps:play/),
    transform: (nd): PlayVideoElement => {
        const node = nd as PlayVideoType;
        const ogIdNode = node.children.find(matchAttribute('name', /lumapps:id/)) as PlayVideoId;
        const ogTitleNode = node.children.find(matchAttribute('name', /og:title/)) as PlayVideoTitle;
        const ogUrlNode = node.children.find(matchAttribute('name', /og:url/)) as PlayVideoURL;

        return createPlayVideo({
            id: ogIdNode?.attributes.value,
            title: ogTitleNode?.attributes.value,
            url: ogUrlNode?.attributes.value,
        });
    },
};
