import React from 'react';

import { mdiArrowDown } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SHORTCUTS } from '../../keys';
import { KeyboardKey } from '../KeyboardKey';

/** Display the "Down" arrow key with an accessible label. */
export const KeyDownArrow = () => {
    const { translateKey } = useTranslate();

    return <KeyboardKey label={translateKey(SHORTCUTS.DOWN_ARROW_LABEL)} icon={mdiArrowDown} />;
};
