import isArray from 'lodash/isArray';

import { get as getConstants } from '@lumapps/constants';

import { getPlacesEmbedUrl } from './getPlacesEmbedUrl';
import { Coordinates } from './types';

/**
 * Get the embed map url based on given coordinates and website.
 *
 * @param  {Coordinates} coordinates  The map coordinates.
 * @param  {string} websiteName  The name of the map website should be googleMap or openStreetMap.
 * @return {string} The created embed map url or undefined if a wrong websiteName was given.
 */
export const getEmbedMapUrl = (coordinates: Coordinates, websiteName: string | undefined): string | undefined => {
    const constants = getConstants();
    const { lon, lat, layer, place } = coordinates;
    const locale = constants.userLang;

    switch (websiteName) {
        case 'googleMap':
            if (place) {
                // Used on direction links
                if (isArray(place)) {
                    return getPlacesEmbedUrl(coordinates, locale);
                }
                // Used on search links
                if (!place.data) {
                    return `https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d22267.260716789446!2d${lat}!3d${lon}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s${place.name}!5e0!3m2!1s${locale}!2sfr!4v1651831088129!5m2!1s${locale}!2sfr`;
                }
                // Used on place links
                return `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11133.517142995044!2d${lon}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s${place.data}!2s${place.name}!5e0!3m2!1s${locale}!2sfr!4v1651652000697!5m2!1s${locale}!2sfr`;
            }
            return `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d22269.5699705695!2d${lat}!3d${lon}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1s${locale}!2sfr!4v1651217270543!5m2!1s${locale}!2sfr`;
        case 'openStreetMap':
            if (place) {
                if (isArray(place)) {
                    return undefined;
                }
                return `https://www.openstreetmap.org/export/embed.html?bbox=${lat}%2C${lon}%2C${lat}%2C${lon}&layer=${layer}&marker=${place.lon}%2C${place.lat}`;
            }
            return `https://www.openstreetmap.org/export/embed.html?bbox=${lat}%2C${lon}%2C${lat}%2C${lon}&layer=${layer}&marker=${lon}%2C${lat}`;

        default:
            return undefined;
    }
};
