import React from 'react';

import { useDelayedBooleanState } from './useDelayedBooleanState';

interface Params {
    disable?: boolean;
    /** Delay the hovered state change on enter */
    enterDelay?: number;
    /** Delay the un-hovered state change on leave */
    leaveDelay?: number;
}

interface Output {
    /** Whether the element is currently being hovered or not */
    isHovered: boolean;
    /** Method to register an element to observe */
    registerElement?: React.RefCallback<HTMLElement>;
}

/**
 * Utility hook to manage hover state on one or more elements.
 *
 * @example
 *      const { isHovered, registerElement } = useHover();
 *      return <Button ref={registerElement}> {isHovered ? 'HOVER' : 'NOT HOVERED'} </Button>;
 */
export const useHover = ({ disable, enterDelay = 0, leaveDelay = 0 }: Params = {}): Output => {
    const [isHovered, setDelayedHovered, setInstantHovered] = useDelayedBooleanState(false, {
        trueDelay: enterDelay,
        falseDelay: leaveDelay,
    });

    // Observed elements
    const elementsRef = React.useRef(new Set<HTMLElement>());

    // Callback registering new elements
    const [registerElement, setRegisterElement] = React.useState<Output['registerElement']>();

    React.useEffect(() => {
        if (disable) {
            return undefined;
        }

        const enter = () => setDelayedHovered(true);
        const leave = () => setDelayedHovered(false);

        // Set callback to register new elements
        setRegisterElement(() => (element?: HTMLElement | null) => {
            if (!element || elementsRef.current.has(element)) {
                return;
            }
            elementsRef.current.add(element);
            element.addEventListener('mouseenter', enter);
            element.addEventListener('mouseleave', leave);
        });

        const elements = elementsRef.current;

        // Make sure to remove the hovered style if one element is disabled
        for (const element of elements) {
            if ((element as any).disabled) {
                setInstantHovered(false);
                return undefined;
            }
        }

        // Clean up
        return () => {
            for (const element of elements) {
                element.removeEventListener('mouseenter', enter);
                element.removeEventListener('mouseleave', leave);
            }
            elementsRef.current = new Set();
        };
    }, [setInstantHovered, setDelayedHovered, disable]);

    return { isHovered, registerElement };
};
