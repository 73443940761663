import React, { ReactNode, useMemo } from 'react';

import { classnames, padding, visuallyHidden } from '@lumapps/classnames';
import { mdiClose, mdiTune } from '@lumapps/lumx/icons';
import {
    Orientation,
    Button,
    PopoverDialog,
    Emphasis,
    Theme,
    Text,
    Badge,
    ColorPalette,
    Toolbar,
    IconButton,
    Typography,
    FlexBox,
    Heading,
    Size,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { WIDGET_CONTENT_FILTER } from '../../keys';
import { FormattedSiteFilter, GenericListFilterType } from '../../types';
import { getActiveFilters } from '../../utils/getActiveFilters';

interface ContentFilterResponsiveWrapperProps {
    /**
     * Display popover
     * */
    isOpen: boolean;
    onOpen(): void;
    onClose(): void;
    /** Currently active filters */
    filtersCurrentValues: GenericListFilterType;
    /** The list of available site references to choose from in the filter. */
    siteReferences?: FormattedSiteFilter[];
    /** Widget properties */
    scope: string;
    theme: Theme;
    /** Dialog footer */
    footer: ReactNode;
}

/**
 * Wrapper used in order to display the filters within a popoverDialog, when in a responsive context
 * */
export const ContentFilterResponsiveWrapper: React.FC<ContentFilterResponsiveWrapperProps> = ({
    children,
    filtersCurrentValues,
    siteReferences,
    theme,
    scope,
    isOpen,
    onOpen,
    onClose,
    footer,
}) => {
    const { translateKey, translateAndReplace, pluralize } = useTranslate();
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const ariaLabelledById = `${scope}-wrapper-${generateUUID()}`;

    const activeFiltersNb: number = useMemo(
        () => getActiveFilters(filtersCurrentValues, siteReferences),
        [filtersCurrentValues, siteReferences],
    );

    return (
        <section>
            <Button ref={anchorRef} onClick={onOpen} leftIcon={mdiTune} emphasis={Emphasis.medium} theme={theme}>
                <FlexBox as="span" orientation="horizontal" gap={Size.regular} hAlign="center">
                    <Text as="span">{translateKey(GLOBAL.SHOW_FILTERS)}</Text>
                    {!!activeFiltersNb && (
                        <Badge color={ColorPalette.primary} style={{ position: 'static' }}>
                            <Text as="span" aria-hidden="true">
                                {activeFiltersNb}
                            </Text>
                            <Text as="span" className={visuallyHidden()}>
                                {translateAndReplace(
                                    pluralize(WIDGET_CONTENT_FILTER.ACTIVE_FILTERS_COUNT, activeFiltersNb),
                                    {
                                        COUNT: activeFiltersNb,
                                    },
                                )}
                            </Text>
                        </Badge>
                    )}
                </FlexBox>
            </Button>
            <PopoverDialog aria-labelledby={ariaLabelledById} anchorRef={anchorRef} isOpen={isOpen} onClose={onClose}>
                <FlexBox orientation={Orientation.vertical}>
                    <Toolbar
                        label={
                            <Heading id={ariaLabelledById} as="h3" typography={Typography.title}>
                                {translateKey(GLOBAL.FILTERS)}
                            </Heading>
                        }
                        after={
                            <IconButton
                                label={translateKey(GLOBAL.HIDE_FILTERS)}
                                icon={mdiClose}
                                onClick={onClose}
                                emphasis={Emphasis.low}
                            />
                        }
                    />
                    <div className={classnames(padding('bottom', 'huge'), padding('horizontal', 'huge'))}>
                        {children}
                    </div>
                    <div className={classnames(padding('vertical', 'big'), padding('horizontal', 'huge'))}>
                        {footer}
                    </div>
                </FlexBox>
            </PopoverDialog>
        </section>
    );
};
