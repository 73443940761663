import React from 'react';

import { Avatar, Size } from '@lumapps/lumx/react';
import { string, oneOfType, bool, arrayOf, shape } from 'prop-types';

import classNames from 'classnames';

import linkedinAvatar from 'assets/img/default-avatar/avatar-default-linkedin.svg';
import twitterAvatar from 'assets/img/default-avatar/avatar-default-twitter.svg';

import { translate as t } from 'components/translations';

import { InitialSettings } from 'common/modules/constants/initial-settings_constants';
import { SOCIAL_PLATFORMS_CONFIG } from 'components/components/social-advocacy/constants';

import { SALinkPreviewLarge } from '../SALinkPreviewLarge';
import { SASocialHeader } from './SASocialHeader';

const SAShareableContentPreview = ({ description, images, title, url, socialPlatform, suggestedMessage, type }) => {
    const avatars = {
        linkedin: linkedinAvatar,
        twitter: twitterAvatar,
    };

    const config = SOCIAL_PLATFORMS_CONFIG[socialPlatform] || {};

    return (
        <div className={classNames('sa-shareable-content-preview', `sa-shareable-content-preview--${socialPlatform}`)}>
            <SASocialHeader icon={config?.icon} socialPlatform={socialPlatform} />
            <div className="p+">
                <div className="sa-shareable-content-preview__content">
                    <div className="sa-shareable-content-preview__content-header">
                        {avatars[socialPlatform] ? (
                            <img
                                alt={t('GLOBAL.AMBASSADOR')}
                                className="sa-shareable-content-preview__avatar"
                                src={avatars[socialPlatform]}
                            />
                        ) : (
                            <Avatar image={InitialSettings.DEFAULT_AVATAR} size={Size.m} alt={t('GLOBAL.AMBASSADOR')} />
                        )}

                        <span className="sa-shareable-content-preview__username">{t('GLOBAL.AMBASSADOR')}</span>
                    </div>
                    {suggestedMessage && <p className="sa-shareable-content-preview__message">{suggestedMessage}</p>}
                    <div className="sa-shareable-content-preview__preview-wrapper">
                        <SALinkPreviewLarge
                            description={description}
                            images={images}
                            title={title}
                            url={url}
                            type={type}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

SAShareableContentPreview.propTypes = {
    description: string,
    images: arrayOf(
        shape({
            type: string,
            url: string,
        }),
    ),
    socialPlatform: string,
    suggestedMessage: oneOfType([string, bool]),
    title: string,
    url: string,
};

SAShareableContentPreview.defaultProps = {
    description: null,
    images: [],
    socialPlatform: 'default',
    suggestedMessage: null,
    title: null,
    url: null,
};

export { SAShareableContentPreview };
