import React from 'react';

import { padding, useClassnames } from '@lumapps/classnames';
import { Dialog } from '@lumapps/lumx/react';
import { applyRelativeURLForSecuredImagesInHTML } from '@lumapps/medias/utils';
import { useInjectHTMLWithJS } from '@lumapps/utils/hooks/useInjectHTMLWithJS';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BLOCK_HTML_CLASSNAME } from '../../constants';
import { BlockHtml as BlockHtmlProps } from '../../type';
import { useResolveHTMLPageLinks } from './useResolveHTMLPageLinks';
import { useResolveHTMLPopin } from './useResolveHTMLPopin';

import './index.scss';

const CLASSNAME = BLOCK_HTML_CLASSNAME;
/**
 * Block that allows rendering HTML on a page.
 */
export const BlockHtml: BlockComponent<BlockHtmlProps> = ({ html, theme, popin }) => {
    const { ref: divRef, isInjected } = useInjectHTMLWithJS({ html: applyRelativeURLForSecuredImagesInHTML(html) });
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const { block } = useClassnames(CLASSNAME);

    const openPopin =
        (open = true) =>
        () => {
            setIsDialogOpen(open);
        };

    useResolveHTMLPopin(divRef, openPopin());

    const links = useResolveHTMLPageLinks(isInjected && divRef);

    return (
        <>
            <div className={block(`${theme}`)} dir="auto" ref={divRef as any} />
            {popin && (
                <Dialog isOpen={isDialogOpen} onClose={openPopin(false)}>
                    <div className={padding('all', 'huge')}>
                        <BlockHtml html={popin} />
                    </div>
                </Dialog>
            )}
            {links}
        </>
    );
};

BlockHtml.displayName = 'BlockHtml';
