function CommunityFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:methodKey`,
        {},
        {
            delete: {
                isArray: false,
                method: 'delete',
                params: {
                    methodKey: 'delete',
                },
            },

            deleteMulti: {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'deleteMulti',
                },
            },

            follow: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'follow',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:id/:methodKey`,
            },

            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:methodKey`,
            },

            getUserList: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/user/:methodKey`,
            },

            list: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:methodKey`,
            },

            listByType: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:communityType/:methodKey`,
            },

            planMeeting: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'plan-meeting',
                },
            },

            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            saveLayout: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/layout/:methodKey`,
            },

            search: {
                isArray: false,
                method: 'POST',
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/search/community/`,
            },

            unfollow: {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'unfollow',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:id/:methodKey`,
            },
        },
    );
}

/////////////////////////////

angular.module('Factories').factory('CommunityFactory', CommunityFactory);

/////////////////////////////

export { CommunityFactory };
