import debounce from 'lodash/debounce';
import get from 'lodash/get';

/////////////////////////////

function WidgetFileListSettingsController(
    $injector,
    $rootScope,
    $scope,
    $timeout,
    Content,
    InitialSettings,
    Widget,
    WidgetSettingsConstant,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this, angular/controller-as-vm
    const widgetFileListSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Name document provider service to use.
     * Default is 'DocumentProvider', but may be override for eg. 'CommunityDocumentProvider'.
     *
     * @type {string}
     */
    const _documentProviderService = 'DocumentProvider';

    /**
     * Used document provider service.
     */
    let _documentProvider;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the category allow file management in the widget or not.
     *
     * @type {boolean}
     */
    widgetFileListSettings.categoryAllowFileManagement = false;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget properties.
     *
     * @return {Object} Widget properties.
     */
    function _getWidgetProperties() {
        const currentWidget = Widget.getCurrent();
        currentWidget.properties = currentWidget.properties || {};

        return currentWidget.properties;
    }

    /**
     * Get current category.
     *
     * @return {Promise} Provider Category promise.
     */
    function _getCurrentCategory() {
        const widgetProperties = _getWidgetProperties();

        if (angular.isUndefinedOrEmpty(widgetProperties.folder)) {
            return undefined;
        }

        const { categoryId, providerId } = _getWidgetProperties().folder;

        return _documentProvider.getProviderCategory(providerId, categoryId);
    }

    /**
     * Get current provider id.
     *
     * @return {string} Current provider id.
     */
    function _getCurrentProviderId() {
        return get(_getWidgetProperties().folder, 'providerId');
    }

    /**
     * Init document provider service.
     */
    function _initDocumentProvider() {
        if (widgetFileListSettings.isCommunityContext) {
            // Note: wait for the backend to be implemented before uncomment this.
            // _documentProviderService = 'CommunityDocumentProvider';
        }

        _documentProvider = $injector.get(_documentProviderService);
    }

    /**
     * Check if file management is allowed for current category.
     *
     * @return {boolean} Whether or not the file management is allowed.
     */
    async function _isCategoryAllowingFileManagement() {
        const category = await _getCurrentCategory();
        const providerId = _getCurrentProviderId();
        const widgetProperties = _getWidgetProperties();

        return (
            (get(category, 'canManage', false) && providerId !== 'local') ||
            widgetProperties.displayType === WidgetSettingsConstant.SELECTION_TYPES.COMMUNITY_FOLDER
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Toggle field.
     *
     * @param {Object} field The field that is toggled.
     * Todo [Arnaud]: refactor this into a WidgetSettings service?
     */
    async function toggleField(field) {
        if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
            return;
        }

        field.enable = !field.enable;

        await widgetFileListSettings.updateWidget(true);
    }

    /**
     * Update widget on settings change.
     *
     * @param {boolean} [broadcast=true] Indicates if we want to send the update event.
     */
    async function updateWidget(broadcast = true) {
        const properties = _getWidgetProperties();

        const allowFileManagement = await _isCategoryAllowingFileManagement();

        $scope.$apply(() => {
            widgetFileListSettings.categoryAllowFileManagement = allowFileManagement;

            if (!allowFileManagement) {
                properties.canManageFiles = false;
            }
        });

        if (!broadcast) {
            return;
        }

        $timeout(() => {
            $rootScope.$broadcast('widget-file-list-settings', Widget.getCurrent().uuid);
        });
    }

    /////////////////////////////

    widgetFileListSettings.debouncedUpdateWidget = debounce(updateWidget, WidgetSettingsConstant.DEBOUNCE_DELAY);
    widgetFileListSettings.toggleField = toggleField;
    widgetFileListSettings.updateWidget = updateWidget;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the input language changes, re-display the files.
     */
    $scope.$on('inputLanguage', async () => {
        await widgetFileListSettings.updateWidget(false, false);
    });

    /**
     * Initialize the controller.
     */
    async function init() {
        widgetFileListSettings.isCommunityContext =
            Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

        _initDocumentProvider();

        await widgetFileListSettings.updateWidget(false, true);
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetFileListSettingsController', WidgetFileListSettingsController);

export { WidgetFileListSettingsController };
