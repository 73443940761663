export const WIDGET_INTRO_TYPE = 'intro';

export const CLASSNAME = 'widget-intro';

/** Classname of an intro 'block' in legacy (used in the widget summary for generation)
 *
 * **WARNING**: When the widget intro will use the react BlockIntro, an update of the classname
 * needs to be done on the widget summary
 */
export const LEGACY_BLOCK_INTRO_CLASSNAME = 'widget-intro__content';

/** Classname of an intro wysiwyg element in legacy (used in the widget summary for generation)
 *
 * **WARNING**: When the widget intro will use a react EditableWidgetIntro, an update of the classname
 * needs to be done on the widget summary
 */
export const LEGACY_EDITION_INTRO_CLASSNAME = 'widget-intro__wysiwyg';

export const BLOCK_INTRO_CLASSNAME = 'block-intro';

export const EDITION_INTRO_TEXTAREA_CLASSNAME = 'editable-textarea';
