(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetDrawioDirective() {
        'ngInject';

        function WidgetDrawioLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: 'WidgetIframeController',
            controllerAs: 'vm',
            link: WidgetDrawioLink,
            replace: true,
            require: ['widgetDrawio', '^widget'],
            restrict: 'E',
            scope: {
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-iframe/views/widget-iframe.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetDrawio', WidgetDrawioDirective);
})();
