/* istanbul ignore file */
/** tested with integration tests */
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';

import { useInjectTapper } from './useInjectTapper';

const useNotificationsTapper = () => {
    const notifications = useNotification();

    useInjectTapper({
        displayNotification: (type: string, text: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const notification = notifications[type];

            if (notification) {
                notification({ translate: text });
            }
        },
    });
};

export { useNotificationsTapper };
