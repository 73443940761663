import { list } from '@lumapps/user/api';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import loFind from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import reduce from 'lodash/reduce';
import without from 'lodash/without';

/////////////////////////////

function FeedService($q, Customer, FeedFactory, Instance, LumsitesBaseService, ReduxStore, Translation) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(FeedFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The key to use for the 'others' feed.
     *
     * @type {string}
     */
    let _KEY_OTHERS = '';

    /**
     * The max number of restrictedFeed to put in the search, limited by backend.
     *
     * @type {string}
     */
    let _MAX_RESTRICTED_FEED = '100';

    /**
     * The key to use for the technical feed.
     *
     * @type {string}
     */
    let _KEY_TECHNICAL = '';

    /**
     * The key to use for the prefetch processed by a getMulti.
     *
     * @type {string}
     */
    let _KEY_GET_MULTI = 'get-multi';

    /**
     * A reference to the filtered feed lists. Used as a 'cache' so we don't need to refetch every single time.
     *
     * @type {Object}
     */
    const _filteredFeedList = {};

    /**
     * Contains the list of technical feeds for each list key.
     * Technical feeds are the "All" and "Public" feeds of instances.
     *
     * @type {Object}
     */
    const _technicalFeeds = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The Public feed.
     *
     * @type {Object}
     */
    service.ALL = {};

    /**
     * The Public feed.
     *
     * @type {Object}
     */
    service.PUBLIC = {};

    /**
     * Contains the feed ALL and PUBLIC ids.
     *
     * @type {Array}
     */
    service.FEED_ALL_AND_PUBLIC = [];

    /**
     * The default parameters for the service requests.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Define the Technical feeds if needed.
     *
     * @return {Array} The technical feeds.
     */
    function _computeFeedAllAndPublic() {
        const technicalFeeds = [];
        angular.forEach(service.FEED_ALL_AND_PUBLIC, (feedId) => {
            let feed;
            if (feedId === service.PUBLIC.id) {
                feed = service.PUBLIC;

                if (angular.isDefinedAndFilled(feed)) {
                    feed.name = 'FEED_NAME_PUBLIC';
                }
            } else {
                feed = service.ALL;
            }

            if (angular.isDefinedAndFilled(feed)) {
                technicalFeeds.push(feed);
            }
        });

        return technicalFeeds;
    }

    /**
     * Get the technical feeds of a list key according to the text filter.
     *
     * @param  {string} [textFilter]        The text to use to filter the technical feeds.
     * @param  {Object} params              The parameters of the query.
     * @param  {string} [listKey="default"] The list key.
     * @return {Array}  The technical feeds.
     */
    function _getTechnicalFeeds(textFilter, params, listKey) {
        if (
            angular.isUndefinedOrEmpty(_technicalFeeds[listKey]) ||
            angular.isDefinedAndFilled(get(params, 'restrictToFeeds'))
        ) {
            return [];
        }

        listKey = listKey || 'default';

        const technicalFeeds = _technicalFeeds[listKey] || [];
        const except = angular.fastCopy(get(params, 'exceptFeeds', []));

        if (
            (!Customer.isPublicContentAuthorized() || !Instance.getInstance().publicContentAuthorized) &&
            angular.isDefinedAndFilled(service.PUBLIC)
        ) {
            except.push(service.PUBLIC.id);
        }

        const intersectionFeed = intersection(except, service.FEED_ALL_AND_PUBLIC);
        if (intersectionFeed.length === service.FEED_ALL_AND_PUBLIC.length) {
            return [];
        }

        const hasTextFilter = angular.isDefinedAndFilled(textFilter);
        if (hasTextFilter) {
            textFilter = textFilter.toLowerCase();
        }

        return filter(technicalFeeds, (technicalFeed) => {
            return (
                (!hasTextFilter || includes(Translation.translate(technicalFeed.name).toLowerCase(), textFilter)) &&
                !includes(except, technicalFeed.id)
            );
        });
    }

    /**
     * Process the received list of feeds.
     *
     * @param {Array}   feeds               The received list of feeds.
     * @param {boolean} [isFirstPage=false] Indicates if it's the first page.
     * @param {string}  [textFilter]        The text used to filter the feeds.
     * @param {Object}  [parameters]        The parameters of the feeds list.
     * @param {string}  [listKey="default"] The list key.
     */
    function _processFeeds(feeds, isFirstPage = false, textFilter, parameters, listKey = 'default', getMulti=false) {
        let filteredList = service.getFilteredList(listKey);

        const feedList = isFirstPage
            ? feeds
            : get(filteredList, _KEY_TECHNICAL, [])
                  .concat(get(filteredList, _KEY_OTHERS, []))
                  .concat(feeds);

        if(angular.isUndefinedOrEmpty(_filteredFeedList[listKey])) {
            _filteredFeedList[listKey] = {};
        }
        filteredList = service.getFilteredList(listKey);

        if (angular.isUndefinedOrEmpty(_technicalFeeds[listKey])) {
            _technicalFeeds[listKey] = _computeFeedAllAndPublic();
        }

        if (angular.isDefinedAndFilled(_technicalFeeds[listKey])) {
            filteredList[_KEY_TECHNICAL] = _getTechnicalFeeds(textFilter, parameters, listKey);
        }
        if (angular.isUndefinedOrEmpty(filteredList[_KEY_TECHNICAL])) {
            delete filteredList[_KEY_TECHNICAL];
        }

        filteredList[_KEY_OTHERS] = filter(feedList, (feed) => {
            return !includes(service.FEED_ALL_AND_PUBLIC, feed.id);
        });

        if (getMulti) {
            filteredList[_KEY_GET_MULTI] = angular.fastCopy(feedList);
        }
    }

    /**
     * Update the array of restricted feeds in case ALL or PUBLIC feeds are in restrictTo array, display all feeds.
     *
     * @param  {Array} restrictTo A list of feeds we want to restrict the selector to.
     * @param  {Array} [except]   The list of feeds we don't want in the list.
     * @return {Array} The new list of feeds to restrict the selector to.
     */
    function _updateRestriction(restrictTo, except) {
        const newRestrictTo = angular.isDefinedAndFilled(intersection(restrictTo, service.FEED_ALL_AND_PUBLIC))
            ? []
            : restrictTo;

        if (angular.isUndefinedOrEmpty(except)) {
            return newRestrictTo;
        }

        return difference(newRestrictTo, except);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Convert the value stored in the ng-model into the value displayed in the choice list to match the selection.
     *
     * @param  {string}   feedKey   The identifier of the feed to convert.
     * @param  {Function} [cb]      A callback function to call with the converted value.
     * @param  {string}   [listKey] The key of the list the feed to convert belongs to.
     * @return {Promise}  The promise of the resolution.
     */
    function feedKeyToFeed(feedKey, cb = angular.noop, listKey) {
        if (angular.isUndefinedOrEmpty(feedKey)) {
            cb();

            return $q.reject();
        }

        return service
            .getFeedById(feedKey, listKey)
            .then(cb)
            .catch(() => {
                cb();
            });
    }

    /**
     * Convert the feed object selected in the choice list into the value stored in the ng-model.
     *
     * @param  {Object}   feed The original feed object from the choice list.
     * @param  {Function} [cb] A callback function to call with the converted value.
     * @return {string}   The identifier of the feed.
     */
    function feedToFeedKey(feed, cb = angular.noop) {
        if (angular.isUndefinedOrEmpty(get(feed, 'id'))) {
            cb();

            return undefined;
        }

        cb(feed.id);

        return feed.id;
    }

    /**
     * Get a feed object in a given list based on its id.
     *
     * @param  {string}  feedId    The identifier of the feed to get.
     * @param  {string}  [listKey] The key of the list to search the feed in.
     * @return {Promise} The promise of the get of the feed.
     */
    function getFeedById(feedId, listKey) {
        if (angular.isUndefinedOrEmpty(feedId)) {
            return $q.reject();
        }

        let feed;

        if (feedId === service.ALL.id) {
            feed = service.ALL;
        } else if (feedId === service.PUBLIC.id) {
            feed = angular.fastCopy(service.PUBLIC);
            feed.name = 'FEED_NAME_PUBLIC';
        }

        if (angular.isUndefinedOrEmpty(feed) && angular.isDefinedAndFilled(listKey)) {
            let filteredList = service.getFilteredList(listKey);
            filteredList = get(filteredList, _KEY_TECHNICAL, [])
                .concat(get(filteredList, _KEY_OTHERS, []))
                .concat(get(filteredList, _KEY_GET_MULTI, []));
            feed = loFind(filteredList, {
                id: feedId,
            });
        }

        if (angular.isDefinedAndFilled(feed)) {
            return $q.resolve(angular.fastCopy(feed));
        }

        const allFeedsList = reduce(
            _filteredFeedList,
            (globalList, filteredFeed) => {
                return globalList.concat(get(filteredFeed, _KEY_OTHERS, []));
            },
            [],
        );
        feed = loFind(allFeedsList, {
            id: feedId,
        });

        if (angular.isDefinedAndFilled(feed)) {
            return $q.resolve(feed);
        }

        return $q((resolve, reject) => {
            service.get(feedId, resolve, reject, listKey, undefined, false);
        });
    }

    /**
     * Get the key for the "Others" feeds.
     *
     * @return {Object} The "Others" feed key.
     */
    function getKeyOthers() {
        return _KEY_OTHERS;
    }

    /**
     * Get the filtered list of a given list key.
     *
     * @param  {string} [listKey="default"] The list key we want to get the filtered list of.
     * @return {Object} The filtered list with technical and others feeds.
     */
    function getFilteredList(listKey) {
        return _filteredFeedList[listKey || 'default'];
    }

    /**
     * Get multiple feeds from theirs ids.
     *
     * @param  {Array}   ids                 The ids of the feeds we want to get.
     * @param  {string}  [listKey="default"] The list key.
     * @return {Promise} The promise of the get multi.
     */
    function getMulti(ids, listKey = 'default') {
        if (angular.isUndefinedOrEmpty(ids)) {
            $q.resolve([]);
        }

        const promise = FeedFactory.getMulti({
            uid: ids,
        }).$promise;
        promise.then((feeds) => {
            if (angular.isDefinedAndFilled(feeds.items)) {
                _processFeeds(feeds.items, true, undefined, undefined, listKey, true);
            }
        });

        return promise;
    }

    /**
     * Initialize the list of feeds.
     * Get the list of feeds if it doesn't exists yet.
     *
     * @param  {Object}  [params]            The parameters for getting the list of feeds.
     * @param  {string}  [listKey="default"] The list key of the list of feeds.
     * @return {Promise} The promise of the getting of the list.
     */
    function initFeedList(params, listKey = 'default') {
        let existingList;

        if (angular.isDefinedAndFilled(existingList)) {
            return $q.resolve(existingList);
        }

        return service.search(undefined, params, listKey);
    }

    /**
     * Remove feed ids from model which are in the source feed ids array.
     *
     * @param  {Array} sourceFeedIds The origin feed ids array.
     * @param  {Array} modelFeedIds  The model feed ids to filtered.
     * @return {Array} The filtered feed ids only with feed ids not contained in the source feed ids.
     */
    function removeFeedIdsInSourceFeedArray(sourceFeedIds, modelFeedIds) {
        return without(modelFeedIds, sourceFeedIds);
    }

    /**
     * Remove feed ids from model which are not existing in the source feed ids array.
     *
     * @param  {Array} sourceFeedIds The origin feed ids array.
     * @param  {Array} modelFeedIds  The model feed ids to filtered.
     * @return {Array} The filtered feed ids only with feed ids contained in the source feed ids.
     */
    function removeFeedIdsNotInSourceFeedArray(sourceFeedIds, modelFeedIds) {
        return includes(sourceFeedIds, service.ALL.uid) ? modelFeedIds : intersection(sourceFeedIds, modelFeedIds);
    }

    /**
     * Search for feeds.
     *
     * @param  {string}  [textFilter]        The text filter to filter the feeds.
     * @param  {Object}  [params]            The parameters of the feed list.
     * @param  {string}  [listKey="default"] The list key for the list of feeds.
     * @param  {boolean} [nextPage=false]    Indicates if we are loading another page than the first one.
     * @return {Promise} The promise of the search.
     */
    function search(textFilter, params, listKey = 'default', nextPage = false) {
        const parameters = angular.fastCopy(params) || {};

        // Define restrictTo empty if it contains ALL or PUBLIC.
        if (angular.isDefinedAndFilled(parameters.restrictTo)) {
            parameters.restrictTo = parameters.restrictTo.splice(0, _MAX_RESTRICTED_FEED);
            parameters.restrictToFeeds = _updateRestriction(parameters.restrictTo, parameters.except);
        }

        if (angular.isDefinedAndFilled(parameters.except)) {
            parameters.exceptFeeds = angular.fastCopy(parameters.except);
        }

        delete parameters.restrictTo;
        delete parameters.except;

        parameters.maxResults = angular.isUndefined(parameters.maxResults) ? 30 : parameters.maxResults;

        if (angular.isUndefinedOrEmpty(textFilter) && angular.isDefinedAndFilled(parameters.restrictToFeeds)) {
            parameters.restrictToFeeds = parameters.restrictToFeeds.splice(0, _MAX_RESTRICTED_FEED);
        }

        parameters.query = textFilter;

        return $q((resolve, reject) => {
            service.getMinResults(
                parameters,
                (feeds, isFirstPage) => {
                    _processFeeds(feeds, isFirstPage, textFilter, parameters, listKey);
                },
                () => {
                    // No more limit with lx-infinite-scroll.
                    return 0;
                },
                () => {
                    resolve(service.getFilteredList(listKey));
                },
                reject,
                listKey,
                'search',
                nextPage,
            );
        });
    }

    /**
     * Set the all and/or public feeds.
     *
     * @param {Object} [allFeed]    The "All" feed.
     * @param {Object} [publicFeed] The "Public" feed.
     */
    function setTechnicalFeeds(allFeed = service.ALL, publicFeed = service.PUBLIC) {
        service.ALL = angular.fastCopy(allFeed);
        service.PUBLIC = angular.fastCopy(publicFeed);

        service.FEED_ALL_AND_PUBLIC = [];
        if (angular.isDefinedAndFilled(service.ALL)) {
            service.FEED_ALL_AND_PUBLIC.push(service.ALL.id);
        }
        if (angular.isDefinedAndFilled(service.PUBLIC)) {
            service.FEED_ALL_AND_PUBLIC.push(service.PUBLIC.id);
        }
    }

    /**
     * Synchronize a given feed.
     *
     * Update the global list of items to keep the list up to date.
     *
     * @param {string}   id      The identifier of the feed to synchronize.
     * @param {Function} [cb]    A callback function to execute on success of the sync API call.
     * @param {Function} [errCb] A callback function to execute on error of the sync API call.
     */
    function synchronize(id, cb = angular.noop, errCb = angular.noop) {
        if (angular.isUndefinedOrEmpty(id)) {
            return;
        }

        FeedFactory.synchronize(id, cb, errCb);
    }

    /////////////////////////////

    service.feedKeyToFeed = feedKeyToFeed;
    service.feedToFeedKey = feedToFeedKey;
    service.getFeedById = getFeedById;
    service.getFilteredList = getFilteredList;
    service.getKeyOthers = getKeyOthers;
    service.getMulti = getMulti;
    service.initFeedList = initFeedList;
    service.removeFeedIdsInSourceFeedArray = removeFeedIdsInSourceFeedArray;
    service.removeFeedIdsNotInSourceFeedArray = removeFeedIdsNotInSourceFeedArray;
    service.search = search;
    service.setTechnicalFeeds = setTechnicalFeeds;
    service.synchronize = synchronize;

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        return {
            ALL: service.ALL,
            FEED_ALL_AND_PUBLIC: service.FEED_ALL_AND_PUBLIC,
            PUBLIC: service.PUBLIC,
        };
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'feed';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'feed/update';

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = () => {
        _KEY_OTHERS = `<i class="mdi mdi-account-multiple"></i> ${Translation.translate('FEED_GROUP_OTHERS')}`;
        _KEY_TECHNICAL = `<i class="mdi mdi-earth"></i> ${Translation.translate('FEED_GROUP_TECHNICAL')}`;

        service.defaultParams = {
            customerId: Customer.getCustomerId(),
            instance: Instance.getCurrentInstanceId(),
        };

        ReduxStore.subscribe(service, true);
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Feed', FeedService);

/////////////////////////////

export { FeedService };
