import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function TemplateBuilderService(CommunityTemplate, ContentTemplate, InitialSettings, Metadata, Instance, Utils) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the base template for a content.
         *
         * @return {Object} The template object.
         */
        function _getBaseTemplate() {
            return {
                components: [
                    {
                        cells: [
                            {
                                components: [],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 12,
                            }],
                        type: 'row',
                        uuid: generateUUID(),
                    }],
                fixedLayout: false,
                fixedWidgets: false,
                properties: {},
            };
        }

        /**
         * Return the base template for a content list module.
         *
         * @param  {string}  customContentTypeId The custom content type id.
         * @param  {boolean} showFilter          If we will put a content filter widget or not.
         * @return {Object}  The template object.
         */
        function _getBaseContentListTemplate(customContentTypeId, showFilter) {
            var _widgetTitle = {
                properties: {
                    marginTop: 0,
                    isCollapsible: false,
                    style: {
                        content: {
                            paddingBottom: 16,
                            paddingTop: 16,
                        },
                        global: {
                            borderBottomWidth: 1,
                        },
                    },
                },
                type: 'widget',
                uuid: generateUUID(),
                widgetType: InitialSettings.WIDGET_TYPES.TITLE,
            };

            var _cellContentList = {
                components: [
                    {
                        isMainWidget: true,
                        properties: {
                            customContentType: [
                                {
                                    id: customContentTypeId,
                                }],
                            maxNumber: 30,
                            noResults: true,
                            instance: [Instance.getCurrentInstanceId()],
                            type: 'list',
                            isCollapsible: false,
                            listOrderDir: 'desc',
                            listOrder: 'publicationDate',
                        },
                        type: 'widget',
                        uuid: generateUUID(),
                        widgetType: InitialSettings.WIDGET_TYPES.CONTENT_LIST,
                    }],
                type: 'cell',
                uuid: generateUUID(),
                width: (showFilter) ? 8 : 12,
            };
            var _cellContentFilter = {
                components: [
                    {
                        properties: {
                            isCollapsible: false,
                            linkedToLocalWidget: true,
                            style: {
                                content: {
                                    paddingBottom: 24,
                                    paddingTop: 24,
                                },
                                global: {
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                },
                            },
                        },
                        type: 'widget',
                        uuid: generateUUID(),
                        widgetType: InitialSettings.WIDGET_TYPES.CONTENT_FILTER,
                    }],
                type: 'cell',
                uuid: generateUUID(),
                width: 4,
            };

            var _rowCells = (showFilter) ? [_cellContentList, _cellContentFilter] : [_cellContentList];

            return {
                components: [
                    {
                        cells: [
                            {
                                components: [
                                    _widgetTitle,
                                    {
                                        cells: _rowCells,
                                        type: 'row',
                                        uuid: generateUUID(),
                                    },
                                ],
                                properties: {
                                    paddingBottom: 12,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 0,
                                    plain: true,
                                },
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 12,
                            }],
                        type: 'row',
                        uuid: generateUUID(),
                    }],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /**
         * Return the base template for a content list module.
         *
         * @param  {boolean} showFilter If we will put a content filter widget or not.
         * @return {Object}  The template object.
         */
        function _getBaseDirectoryTemplate(showFilter) {
            var _widgetTitle = {
                properties: {
                    isCollapsible: false,
                    marginTop: 0,
                    style: {
                        content: {
                            paddingBottom: 16,
                            paddingTop: 16,
                        },
                        global: {
                            borderBottomWidth: 1,
                        },
                    },
                },
                type: 'widget',
                uuid: generateUUID(),
                widgetType: InitialSettings.WIDGET_TYPES.TITLE,
            };

            var _cellContentList = {
                components: [
                    {
                        isMainWidget: true,
                        properties: {
                            isCollapsible: false,
                            maxNumber: 30,
                            type: 'all',
                            viewMode: 'vertical',
                        },
                        type: 'widget',
                        uuid: generateUUID(),
                        widgetType: InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY,
                    }],
                type: 'cell',
                uuid: generateUUID(),
                width: (showFilter) ? 8 : 12,
            };
            var _cellContentFilter = {
                components: [
                    {
                        properties: {
                            isCollapsible: false,
                            linkedToLocalWidget: true,
                            style: {
                                content: {
                                    paddingBottom: 24,
                                    paddingTop: 24,
                                },
                                global: {
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    borderTopWidth: 1,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                },
                            },
                        },
                        type: 'widget',
                        uuid: generateUUID(),
                        widgetType: InitialSettings.WIDGET_TYPES.CONTENT_FILTER,
                    }],
                type: 'cell',
                uuid: generateUUID(),
                width: 4,
            };

            var _rowCells = (showFilter) ? [_cellContentList, _cellContentFilter] : [_cellContentList];

            return {
                components: [
                    {
                        cells: [
                            {
                                components: [
                                    _widgetTitle,
                                    {
                                        cells: _rowCells,
                                        type: 'row',
                                        uuid: generateUUID(),
                                    },
                                ],
                                properties: {
                                    paddingBottom: 12,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 0,
                                    plain: true,
                                },
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 12,
                            }],
                        type: 'row',
                        uuid: generateUUID(),
                    }],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Rework the directory template.
         * This method will rework an old directory template according to it options & settings.
         * This is a backward compatibility method.
         * Todo [?]: method to remove when the backward compatibility will be done in back end.
         *
         * @param {Object} content             The current content to parse and modify.
         * @param {Object} directoryProperties The current directory properties.
         */
        function reworkDirectoryTemplate(content, directoryProperties) {
            if (content.type !== InitialSettings.CONTENT_TYPES.DIRECTORY) {
                return;
            }

            directoryProperties = (angular.isDefinedAndFilled(directoryProperties)) ? directoryProperties : {};
            var contentWidgetDirectoryEntry = ContentTemplate.getElement(
                content, 'widget', 'widgetType', InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY
            );
            if (angular.isUndefinedOrEmpty(contentWidgetDirectoryEntry)) {
                // Keep old widget tip.
                var widgetTip = (angular.isDefined(content.template) &&
                    angular.isDefinedAndFilled(content.template.components)) ?
                    content.template.components[0] : undefined;

                // Put base content template.
                content.template = _getBaseDirectoryTemplate(directoryProperties.showFilter);

                // Add the widget tip if defined.
                if (angular.isDefinedAndFilled(_.get(widgetTip, 'properties.content'))) {
                    content.template.components.unshift({
                        cells: [
                            {
                                components: [widgetTip],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 12,
                            }],
                        type: 'row',
                        uuid: generateUUID(),
                    });
                }

                // Replace content list parameters.
                var widgetDirectoryEntry = ContentTemplate.getElement(
                    content, 'widget', 'widgetType', InitialSettings.WIDGET_TYPES.DIRECTORY_ENTRY
                );

                widgetDirectoryEntry.properties = angular.extend(widgetDirectoryEntry.properties, {
                    listOrder: directoryProperties.listOrder,
                    listOrderDir: directoryProperties.listOrderDir,
                    maxNumber: directoryProperties.maxNumber,
                    perLine: directoryProperties.perLine,
                    viewMode: directoryProperties.viewMode,
                });

                // Handle backward compatibility and fix metadata.
                if (angular.isDefinedAndFilled(content.properties.metadata)) {
                    angular.forEach(content.properties.metadata, function forEachMetadata(value, key) {
                        var parentMeta = Metadata.getMetadataFromKey(key, false, true);

                        if (angular.isDefined(parentMeta) && angular.isDefinedAndFilled(parentMeta.id) &&
                            !parentMeta.multiple) {
                            content.properties.metadata[key] = value[0];
                        }
                    });

                    widgetDirectoryEntry.properties.metadata = content.properties.metadata;
                }
            }
        }

        /**
         * Rework the content list template.
         * This method will rework an old content-list template according to it options & settings.
         * This is a backward compatibility method.
         * Todo [?]: method to remove when the backward compatibility will be done in back end.
         *
         * @param {Object} content The current content to parse and modify.
         */
        function reworkContentListTemplate(content) {
            if (angular.isDefinedAndFilled(content) &&
                (content.type !== InitialSettings.CONTENT_TYPES.CUSTOM_LIST ||
                    angular.isUndefinedOrEmpty(content.properties) ||
                (!content.properties.showFilter && !content.properties.contentFields))) {
                return;
            }

            // Keep old widget tip.
            var widgetTip = _.get(content, 'template.components[0]');

            // Put base content template.
            content.template = _getBaseContentListTemplate(
                content.customContentType, content.properties.showFilter
            );

            // Add the widget tip if defined.
            if (angular.isDefinedAndFilled(_.get(widgetTip, 'properties.content'))) {
                content.template.components.unshift({
                    cells: [
                        {
                            components: [widgetTip],
                            type: 'cell',
                            uuid: generateUUID(),
                            width: 12,
                        }],
                    type: 'row',
                    uuid: generateUUID(),
                });
            }

            // Replace content list parameters.
            var widgetContentList = ContentTemplate.getElement(content, 'widget', 'widgetType', 'content-list');

            widgetContentList.properties = angular.extend(widgetContentList.properties, {
                customContentTypeTags: content.customContentTypeTags,
                fields: content.properties.contentFields,
                fullExcerpt: content.properties.fullExcerpt,
                listOrderDir: content.properties.sortOrder,
                maxNumber: content.properties.maxNumber,
                perLine: content.properties.perLine,
                truncate: content.properties.truncate,
                viewMode: content.properties.viewMode,
            });

            // Handle backward compatibility and fix metadata.
            if (angular.isDefinedAndFilled(content.properties.metadata)) {
                angular.forEach(content.properties.metadata, function forEachMetadata(value, key) {
                    var parentMeta = Metadata.getMetadataFromKey(key, false, true);

                    if (angular.isDefined(parentMeta) &&
                        angular.isDefinedAndFilled(parentMeta.id) && !parentMeta.multiple) {
                        content.properties.metadata[key] = value[0];
                    }
                });

                widgetContentList.properties.metadata = content.properties.metadata;
            }

            delete content.properties.showFilter;
            delete content.properties.contentFields;
        }

        /**
         * Create base template for a new content.
         *
         * @param  {string} contentType           The type of content we are creating.
         *                                        Possible values are: 'content' or 'community'.
         * @param  {string} type                  The content type for the new template.
         *                                        For the 'content' contentType, possible values are: 'customList',
         *                                        'directory' or any other value.
         *                                        For the 'community' contentType, possible values are: 'dashboard',
         *                                        'posts', 'post', 'media' or 'calendar'.
         * @param  {string} [customContentTypeId] Optional customContentTypeId
         * @return {Object} The template object.
         */
        function createBaseTemplate(contentType, type, customContentTypeId) {
            var template;

            if (contentType === 'content') {
                switch (type) {
                    case InitialSettings.CONTENT_TYPES.CUSTOM_LIST:
                        template = _getBaseContentListTemplate(customContentTypeId, true);
                        break;

                    case InitialSettings.CONTENT_TYPES.DIRECTORY:
                        template = _getBaseDirectoryTemplate(true);
                        break;

                    default:
                        template = _getBaseTemplate();
                        break;
                }

                template.customContentType = customContentTypeId;
            } else if (contentType === 'community') {
                template = CommunityTemplate.getDefaultPageTemplate(type);
            }

            return template;
        }

        /////////////////////////////

        service.createBaseTemplate = createBaseTemplate;
        service.reworkContentListTemplate = reworkContentListTemplate;
        service.reworkDirectoryTemplate = reworkDirectoryTemplate;
    }

    /////////////////////////////

    angular.module('Services').service('TemplateBuilder', TemplateBuilderService);
})();
