import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';

import { UserReference } from '../../../types';
import { BlockAuthor, BlockAuthorProps } from '../BlockAuthor/BlockAuthor';

import './BlockPublicationInfo.scss';

interface BlockPublicationInfoProps {
    className?: string;
    /** Post author data */
    author: UserReference;
    /** Post share information */
    publicationDate?: string;
    /** Theme. */
    theme?: Theme;
    /** Type of the post  */
    publicationDescription: ReactNode;
    avatarBadge?: BlockAuthorProps['avatarBadge'];
    action: ReactNode;
    hideDate?: boolean;
}

const CLASSNAME = 'block-publication-info';

/**
 * Component to render author's information in a post.
 * Display current community if has communityName props
 * Display categories if an array of tags is given
 */
export const BlockPublicationInfo: React.FC<BlockPublicationInfoProps> = ({
    className,
    author,
    publicationDate,
    theme = Theme.light,
    publicationDescription,
    avatarBadge,
    action,
    hideDate,
}) => {
    const { block } = useClassnames(CLASSNAME);

    return (
        <div className={block(`theme-${theme}`, [className])}>
            <BlockAuthor
                author={author}
                avatarBadge={avatarBadge}
                publicationDate={publicationDate}
                description={publicationDescription}
                theme={theme}
                hideDate={hideDate}
            />
            {action}
        </div>
    );
};
