export enum ASK_AI_CONCEPTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470718847
     */
    CONCEPTS = 'ASK_LUMAPPS_ADMIN.CONCEPTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470846068
     */
    NEW_CONCEPT = 'ASK_LUMAPPS_CONCEPTS.NEW_CONCEPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470846876
     */
    EMPTY_STATE_MESSAGE = 'ASK_LUMAPPS_CONCEPTS.EMPTY_STATE_MESSAGE',
    /**
     https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470846826
     */
    NO_CONCEPT = 'ASK_LUMAPPS_CONCEPTS.NO_CONCEPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481038633
     */
    KEYWORD = 'ASK_LUMAPPS_CONCEPTS.KEYWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484837756
     */
    DELETE_CONCEPT = 'ASK_LUMAPPS_CONCEPTS.DELETE_CONCEPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484837864
     */
    CONFIRM_DELETE_CONCEPT = 'ASK_LUMAPPS_CONCEPTS.CONFIRM_DELETE_CONCEPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485116142
     */
    EDIT_CONCEPT = 'ASK_LUMAPPS_CONCEPTS.EDIT_CONCEPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=490322412
     */
    SELECT_CONCEPTS = 'ASK_LUMAPPS_CONCEPTS.SELECT_CONCEPTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=490331715
     */
    CONCEPTS_SELECT_HELPER = 'ASK_LUMAPPS_CONCEPTS.CONCEPTS_SELECT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493638832
     */
    ASSOCIATED_RULES = 'ASK_LUMAPPS_CONCEPTS.ASSOCIATED_RULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493643210
     */
    NO_ASSOCIATED_RULES = 'ASK_LUMAPPS_CONCEPTS.NO_ASSOCIATED_RULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=494658808
     */
    ASSOCIATED_RULES_WITH_NUMBER = 'ASK_LUMAPPS_CONCEPTS.ASSOCIATED_RULES_WITH_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497071891
     */
    KEYWORDS_FIELD_PLACEHOLDER = 'ASK_LUMAPPS_CONCEPTS.KEYWORDS_FIELD_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497081054
     */
    CONCEPT_CREATED_NOTIFICATION = 'ASK_LUMAPPS_CONCEPTS.CONCEPT_CREATED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497362495
     */
    CONCEPT_UPDATED_NOTIFICATION = 'ASK_LUMAPPS_CONCEPTS.CONCEPT_UPDATED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497369745
     */
    CONCEPT_DELETED_NOTIFICATION = 'ASK_LUMAPPS_CONCEPTS.CONCEPT_DELETED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501144638
     */
    CONCEPT = 'ASK_LUMAPPS_CONCEPTS.CONCEPT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504683001
     */
    DUPLICATED_ERROR_HELPER = 'ASK_LUMAPPS_CONCEPTS.DUPLICATED_ERROR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504683030
     */
    CONCEPT_ALREADY_EXISTS = 'ASK_LUMAPPS_CONCEPTS.CONCEPT_ALREADY_EXISTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=518957547
     */
    SYNONYMS = 'ASK_AI_CONCEPTS.SYNONYMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=518957551
     */
    ADD_SYNONYMS = 'ASK_AI_CONCEPTS.ADD_SYNONYMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=521920848
     */
    WRITE_SYNONYM = 'ASK_AI_CONCEPTS.WRITE_SYNONYM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523456701
     */
    USE_LEXICAL_MATCHING_LABEL = 'ASK_AI_CONCEPTS.USE_LEXICAL_MATCHING_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523459906
     */
    USE_LEXICAL_MATCHING_HELPER = 'ASK_AI_CONCEPTS.USE_LEXICAL_MATCHING_HELPER',
}
