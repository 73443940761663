import produce from 'immer';

import { FETCH_GROUP_ERROR, FETCH_GROUP_LOADING, FETCH_GROUP_SUCCESS } from '../actions/community_group_actions';

// Default state.
const defaultState = { errorCode: undefined, errorMessage: '', group: {}, groupIsLoading: false };

const groupReducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_GROUP_LOADING:
                draft.groupIsLoading = true;

                break;
            case FETCH_GROUP_SUCCESS:
                draft.group = action.payload.group || {};
                draft.groupIsLoading = false;

                break;
            case FETCH_GROUP_ERROR:
                draft.errorCode = action.payload.errorCode;
                draft.errorMessage = action.payload.errorMessage;
                draft.groupIsLoading = false;

                break;

            default:
        }

        return draft;
    });

export { groupReducer };
