import React, { useEffect } from 'react';

import { Certificate } from '@lumapps/learning-certificate/components/Certificate';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { LearningCertificateProps } from '../../types';

export const ClientComputedCertificate: React.FC<LearningCertificateProps> = ({ uuid }) => {
    const dispatch = useDispatch();
    const learningEnabled = useSelector(isLearningEnabled);

    useEffect(() => {
        if (!learningEnabled) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [learningEnabled, dispatch, uuid]);

    return <Certificate />;
};
