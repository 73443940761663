import get from 'lodash/get';

import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { Point, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isSelectionEmpty } from '@lumapps/wrex/slate/utils/isSelectionEmpty';

import { Table } from '../components/blocks';
import { T_D, T_R } from '../constants';
import type { TableEditor, TableElement, TDElement, TRElement } from '../types';

/**
 * Insert table after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertTable(
    editor: ReactEditor & TableEditor,
    attributes: Partial<TableElement>,
    point?: Point | undefined,
) {
    const at = point || editor.selection?.focus;
    const firstPathNumber = [get(at, ['path', 0]) as number];
    const after = getSiblingPath(firstPathNumber, 'after');
    const canInsertOnSelection = at && isSelectionEmpty(editor, at.path);
    const tableAttributes = { ...attributes };
    const node: TableElement = {
        type: Table.displayName,
        ...tableAttributes,
        children: [
            {
                type: T_R,
                children: [
                    { type: T_D, children: [createParagraph()] } as TDElement,
                    { type: T_D, children: [createParagraph()] } as TDElement,
                ],
            } as TRElement,
            {
                type: T_R,
                children: [
                    { type: T_D, children: [createParagraph()] } as TDElement,
                    { type: T_D, children: [createParagraph()] } as TDElement,
                ],
            } as TRElement,
        ],
    };

    Transforms.insertNodes(editor, node, {
        at: canInsertOnSelection ? firstPathNumber : after ?? [editor.children.length],
    });

    const path = canInsertOnSelection ? firstPathNumber : after ?? [editor.children.length - 2];
    const firstCellPath = [...path, 0, 0, 0, 0];

    focusAt(editor, firstCellPath);

    return path;
}
