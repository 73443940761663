import uniqueId from 'lodash/uniqueId';

import { SearchSuggestion } from '@lumapps/search/types';
import { slugify } from '@lumapps/utils/string/slugify';

/**
 * Generate a unique Id for quick search suggestions.
 * As the same result cannot
 */
export const generateSuggestionId = (suggestion: SearchSuggestion) => {
    const parts = [];
    const itemId = suggestion.item?.entityId;

    if (suggestion.type) {
        parts.push(suggestion.type);
    }

    if (suggestion.query) {
        /**
         * Sometimes "slugify" will return an empty string
         * if the query is not "slugifiable", as with special characters such as "?".
         * In that case, generate a uniqueId.
         */
        const slugifiedQuery = slugify(suggestion.query) || uniqueId();
        parts.push(slugifiedQuery);
    }

    if (itemId) {
        parts.push(itemId);
    }

    const suggestionId = parts.length > 0 ? parts.join('-') : uniqueId();

    return suggestionId;
};
