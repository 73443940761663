import capitalize from 'lodash/capitalize';

/////////////////////////////

function CommunityModerationController(
    $filter,
    $scope,
    $state,
    Community,
    ConfigTheme,
    InitialSettings,
    LxDataTableService,
    Post,
    PostConstant,
    Translation,
    User,
) {
    'ngInject';

    var vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The fields and properties to be listed in the response of the super admin list endpoints.
     *
     * @type {Object}
     */
    var _PROJECTION = {
        postFilter: {
            items: {
                authorDetails: {
                    firstName: true,
                    lastName: true,
                },
                comments: true,
                likes: true,
                parentContentDetails: {
                    slug: true,
                    title: true,
                },
                postType: true,
                score: {
                    total: true,
                },
                uid: true,
            },
        },
        userFilter: {
            items: {
                email: true,
                firstName: true,
                lastName: true,
            },
        },
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The identifier of the data table.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.COMMUNITY_MODERATION_DATA_TABLE_ID = 'communityModerationDataTable';

    /**
     * The list of data table thead and items.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.COMMUNITY_MODERATION_DATA_TABLE_HEADERS = [
        {
            format: function formatType(row) {
                return User.getUserFullName(row.authorDetails);
            },
            label: Translation.translate('AUTHOR'),
            name: 'authorDetails.firstName',
            sortable: true,
        },
        {
            format: function formatType(row) {
                return Translation.translate(row.parentContentDetails.title);
            },
            label: Translation.translate('COMMUNITY'),
            name: 'parentContentDetails.title.' + Translation.getLang('current'),
            sortable: true,
        },
        {
            format: function formatType(row) {
                return Translation.translate('FRONT.COMMUNITY.POST.TYPE.' + _.get(row, 'postType'));
            },
            label: Translation.translate('COMMUNITY_POST_TYPES'),
            name: 'postType',
            sortable: true,
        },
        {
            label: capitalize(Translation.translate('LIKES')),
            name: 'likes',
            sortable: true,
        },
        {
            label: capitalize(Translation.translate('COMMENTS')),
            name: 'comments',
            sortable: true,
        },
        {
            format: function formatType(row) {
                return _.get(row, 'score.total', '').toString();
            },
            label: Translation.translate('COMMUNITY_POST_SCORE'),
            name: 'score.total',
            sortable: true,
        },
    ];

    /**
     * The default filter object to apply to the post list.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.DEFAULT_FILTER = {
        author: undefined,
        hasRelevant: undefined,
        isMergePending: undefined,
        isMerged: undefined,
        isSpam: undefined,
        isSpamPending: undefined,
        postType: [],
        query: undefined,
    };

    /**
     * The key of the list of super admin users.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.LIST_KEY = 'community-moderation';

    /**
     * A list of postTypes values that can be selected.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.POST_TYPES = _.values(InitialSettings.POST_TYPES);

    /**
     * Indicates if the data table filter is displayed or not.
     *
     * @type {boolean}
     */
    vm.isFilterVisible = false;

    /**
     * The selected rows in the super admin data table.
     *
     * @type {Array}
     */
    vm.selectedRows = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.ConfigTheme = ConfigTheme;
    vm.Community = Community;
    vm.Post = Post;
    vm.PostConstant = PostConstant;
    vm.User = User;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Filter the posts by applying a given set of parameters.
     *
     * @param {Object} filter An object of parameters to filter the posts on.
     */
    function _filterPosts(filter) {
        LxDataTableService.unselectAll(vm.COMMUNITY_MODERATION_DATA_TABLE_ID);

        filter = filter || {};

        var mergeFlags = Post.getRelatedStatusFlags(_.get(filter, 'isMerged.value'));
        var reportStatus = filter.reportStatus === true ? Post.REPORT_STATUSES.REPORTED : undefined;

        var params = angular.extend(angular.fastCopy(filter), {
            author: _.get(filter.author, 'email'),
            hasRelevant: _.get(filter.hasRelevant, 'value'),
            isMergePending: mergeFlags[1],
            isMerged: mergeFlags[0],
            reportStatus: reportStatus,
        });

        Post.filterize(params, undefined, undefined, vm.LIST_KEY, _PROJECTION.postFilter);
    }
    var _debouncedFilterPosts = _.debounce(_filterPosts, 500);

    /**
     * Search for post authors based on the filter input value.
     *
     * @param {string} inputValue The value typed in the lx-select input field.
     */
    function _filterUsers(inputValue) {
        User.filterize(
            {
                action: 'COMMUNITY_PUBLISH',
                query: inputValue,
                showHidden: false,
                status: 'enabled',
            },
            undefined,
            undefined,
            vm.LIST_KEY,
            _PROJECTION.userFilter,
        );
    }

    /**
     * Update the representation of the rows that are selected in the data table.
     *
     * @param {Event}  evt          The original event triggering this method.
     * @param {string} dataTableId  The identifier of the data table being updated.
     * @param {Array}  selectedRows The selected rows in the data table.
     */
    function _updateRows(evt, dataTableId, selectedRows) {
        if (vm.COMMUNITY_MODERATION_DATA_TABLE_ID === dataTableId) {
            vm.selectedRows = selectedRows;
        }
    }

    /**
     * Update the sort order of the rows in the data table.
     *
     * @param {Event}  evt         The original event triggering this method.
     * @param {string} dataTableId The identifier of the data table being updated.
     * @param {Object} column      The column object to sort on.
     */
    function _updateSort(evt, dataTableId, column) {
        if (dataTableId !== vm.COMMUNITY_MODERATION_DATA_TABLE_ID) {
            return;
        }

        Post._services[vm.LIST_KEY]._list = $filter('orderBy')(
            Post.displayList(vm.LIST_KEY),
            function getOrderByValue(item) {
                return _.get(item, column.name);
            },
            column.sort === 'desc',
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Unselect all rows when a post is successfully deleted.
     */
    function onRemovePostSuccess() {
        LxDataTableService.unselectAll(vm.COMMUNITY_MODERATION_DATA_TABLE_ID);
    }

    /**
     * Toggle the filters panel.
     */
    function toggleFilter() {
        vm.isFilterVisible = !vm.isFilterVisible;

        // Reset the filter fields if it's hidden.
        if (!vm.isFilterVisible) {
            vm.filter = angular.fastCopy(vm.DEFAULT_FILTER);
        }
    }

    /**
     * View the currently selected post details.
     */
    function viewPost() {
        if (angular.isUndefinedOrEmpty(vm.selectedRows)) {
            return;
        }

        $state.go('app.front.community', {
            identifier: vm.selectedRows[0].uid,
            slug: Translation.translate(vm.selectedRows[0].parentContentDetails.slug),
            view: 'post',
        });
    }

    /////////////////////////////

    vm.debouncedFilterUsers = _.debounce(_filterUsers, 500);
    vm.onRemovePostSuccess = onRemovePostSuccess;
    vm.toggleFilter = toggleFilter;
    vm.viewPost = viewPost;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters and refresh the list of posts.
     * Debounce the refreshing so that we don't refresh too often.
     *
     * @param {Object} newFilter The new value of the filter.
     * @param {Object} oldFilter The old value of the filter.
     */
    $scope.$watch(
        'vm.filter',
        function onFilterChange(newFilter, oldFilter) {
            if (newFilter !== oldFilter) {
                _debouncedFilterPosts(newFilter);
            }
        },
        true,
    );

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Update the selected rows when something is selected in the data table.
     */
    $scope.$on('lx-data-table__selected', _updateRows);

    /**
     * Update the selected rows when a sort order is applied.
     */
    $scope.$on('lx-data-table__sorted', _updateSort);

    /**
     * Update the selected rows when something is unselected in the data table.
     */
    $scope.$on('lx-data-table__unselected', _updateRows);

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.filter = angular.fastCopy(vm.DEFAULT_FILTER);

        _filterPosts(vm.filter);
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('CommunityModerationController', CommunityModerationController);

/////////////////////////////

export { CommunityModerationController };
