import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { WidgetLoadingState } from '../../../types';

export const CLASSNAME = 'block-grid';

export const DEFAULT_COLUMNS = 1;

/** Default values for the grid. */
export const GRID_ITEM_UNGROUPED_DEFAULT_PADDING = 24;

/** Map widget loading status to the grid loading status */
export const GRID_LOADING_STATUS_MAPPING: Record<
    Extract<WidgetLoadingState, 'loading' | 'loadingmore'>,
    BaseLoadingStatus
> = {
    loading: BaseLoadingStatus.loading,
    loadingmore: BaseLoadingStatus.loadingMore,
};
