import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { withOnChangeProperty } from '../../../../utils';
import { iconLocationFromStyle, styleFromIconLocation } from '../../utils';
import { IconArea } from './IconArea';
import { JustifyButton } from './JustifyButton';
import { IconProxy } from './IconProxy';

/**
 * Enables the edition of the layout of the header/footer area (icon/label location).
 */
export class AreaLayout extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.areaStyle.isRequired,
        /** `display` CSS style attribute. */
        display: propTypes.string,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Called when clicking on the area. Usually used to toggle the area settings. */
        onClick: propTypes.func,
        /** The current area style. */
        value: styleTypes.areaStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
        this.state = {
            dragging: false,
            hovering: null,
        };

        /**
         * When picking up the icon, enter dragging state.
         */
        this.onPick = () => this.setState({ dragging: true });

        /**
         * When dropping the icon, set the new icon location, if it changed,
         * and leave the dragging state.
         */
        this.onDrop = () =>
            this.setState({ dragging: false }, () => {
                const { value: { wrapper = {} } = {}, defaults } = this.props;

                if (this.state.hovering === iconLocationFromStyle(wrapper)) {
                    return;
                }

                this.onChangeProperty(
                    {
                        ...wrapper,
                        ...styleFromIconLocation(
                            this.state.hovering,
                            wrapper.alignItems,
                            wrapper.flexDirection,
                            wrapper.justifyContent,
                            defaults,
                        ),
                    },
                    'wrapper',
                );
            });

        /**
         * When entering an icon drop area while dragging, store its name.
         *
         * @param  {string} hovering The hovered drop location area.
         */
        this.onEnter = (hovering) => this.setState({ hovering });
    }

    render() {
        const { value = {}, name: key, onChange, defaults, display, onClick, hide } = this.props;
        const { dragging, hovering } = this.state;
        const iconLocation = iconLocationFromStyle(value.wrapper);
        const icon = <IconProxy value={hovering} onPick={this.onPick} onDrop={this.onDrop} />;
        const onEnter = dragging ? this.onEnter : null;

        return (
            <div className="widget-style-area__wrapper">
                <div className="widget-style-area__outside" style={{ display }}>
                    {!hide && (
                        <>
                            <JustifyButton
                                value={value}
                                name={key}
                                onChange={onChange}
                                position="left"
                                mode="flex"
                                defaults={defaults}
                            />
                            <div className="widget-style-area__inside" onMouseUp={onClick}>
                                <JustifyButton
                                    value={value}
                                    name={key}
                                    onChange={onChange}
                                    position="left"
                                    mode="block"
                                    defaults={defaults}
                                />
                                <div
                                    className={classNames(
                                        'widget-style-area__dropzone-wrapper',
                                        `widget-style-area__dropzone-wrapper--on-${iconLocation}`,
                                        hovering && `widget-style-area__dropzone-wrapper--over-${hovering}`,
                                    )}
                                >
                                    <div className="widget-style-area__dropzone-left">
                                        <IconArea onEnter={onEnter} value={hovering === 'left'} name="left">
                                            {iconLocation === 'left' && icon}
                                        </IconArea>
                                    </div>
                                    <div className="widget-style-area__dropzone-middle">
                                        <div className="widget-style-area__dropzone-top">
                                            <IconArea onEnter={onEnter} value={hovering === 'top-left'} name="top-left">
                                                {iconLocation === 'top-left' && icon}
                                            </IconArea>
                                            <IconArea onEnter={onEnter} value={hovering === 'top-center'} name="top-center">
                                                {iconLocation === 'top-center' && icon}
                                            </IconArea>
                                            <IconArea onEnter={onEnter} value={hovering === 'top-right'} name="top-right">
                                                {iconLocation === 'top-right' && icon}
                                            </IconArea>
                                        </div>
                                        <div className="widget-style-area__label" />
                                        <div className="widget-style-area__dropzone-bottom">
                                            <IconArea onEnter={onEnter} value={hovering === 'bottom-left'} name="bottom-left">
                                                {iconLocation === 'bottom-left' && icon}
                                            </IconArea>
                                            <IconArea
                                                onEnter={onEnter}
                                                value={hovering === 'bottom-center'}
                                                name="bottom-center"
                                            >
                                                {iconLocation === 'bottom-center' && icon}
                                            </IconArea>
                                            <IconArea onEnter={onEnter} value={hovering === 'bottom-right'} name="bottom-right">
                                                {iconLocation === 'bottom-right' && icon}
                                            </IconArea>
                                        </div>
                                    </div>
                                    <div className="widget-style-area__dropzone-right">
                                        <IconArea onEnter={onEnter} value={hovering === 'right'} name="right">
                                            {iconLocation === 'right' && icon}
                                        </IconArea>
                                    </div>
                                </div>
                                <JustifyButton
                                    value={value}
                                    name={key}
                                    onChange={onChange}
                                    position="right"
                                    mode="block"
                                    defaults={defaults}
                                />
                            </div>
                            <JustifyButton
                                value={value}
                                name={key}
                                onChange={onChange}
                                position="right"
                                mode="flex"
                                defaults={defaults}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }
}
