import { connect } from '@lumapps/redux/react';
import { createStructuredSelector } from '@lumapps/redux/reselect';
import { fetchAllTopics } from 'components/components/social-advocacy/ducks/topics/actions';
import { fetchContentActions } from '@lumapps/sa-shareable-contents/ducks';
import { SAShareableWorkflow } from './SAShareableWorkflow';

import {
    createShareableContent,
    resetWorkflowForm,
    toggleWorkflowPreview,
    fetchMostUsedTopics,
    setContentUrlValue,
} from '../ducks/shareableWorkflow/actions';

import {
    getWorkflowLoadingStatus,
    canSaveContent,
    getShareableWorkflowContentId,
    getShareableWorkflowProgramId,
    getShareableWorkflowCurrentPreviewTab,
    getShareableworkflowForm,
    getShareableWorkflowIsPreviewOpen,
    getShareableWorkflowShareableContent,
} from '../ducks/shareableWorkflow/selectors';

const mapStateToProps = createStructuredSelector({
    canPreviewContent: canSaveContent,
    canSaveContent,
    contentId: getShareableWorkflowContentId,
    programId: getShareableWorkflowProgramId,
    currentPreviewTab: getShareableWorkflowCurrentPreviewTab,
    form: getShareableworkflowForm,
    isLoading: getWorkflowLoadingStatus,
    isPreviewOpen: getShareableWorkflowIsPreviewOpen,
    shareableContent: getShareableWorkflowShareableContent,
});

const mapDispatchToProps = (dispatch) => {
    return {
        createShareableContent: (formValues, programId, contentId, onClose) => {
            const successCallback = () => {
                if (contentId) {
                    dispatch(fetchContentActions(contentId));
                }
                if (onClose) {
                    onClose();
                }
            };
            dispatch(createShareableContent(formValues, programId, successCallback));
        },
        fetchMostUsedTopics: (programId) => dispatch(fetchMostUsedTopics(programId)),
        getTopics: (programId) => dispatch(fetchAllTopics(programId)),
        resetWorkflowForm: (...args) => dispatch(resetWorkflowForm(...args)),
        setContentUrlValue: (...args) => dispatch(setContentUrlValue(...args)),
        toggleWorkflowPreview: (...args) => dispatch(toggleWorkflowPreview(...args)),
    };
};

const ConnectedSAShareableWorkflow = connect(mapStateToProps, mapDispatchToProps)(SAShareableWorkflow);

export { ConnectedSAShareableWorkflow as SAShareableWorkflow };
