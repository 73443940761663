import { dangerousAxiosInstanceUseOnlyIfNeeded } from '@lumapps/base-api';

import { FILE_FIELDS } from '../constants';
import { GDFile, GDPickerConfiguration } from '../types';
import { getDriveToken } from '../utils/getDriveToken';

export interface BatchGoogleDriveFilesRequestParam {
    isGoogleUser?: boolean;
    token?: GDPickerConfiguration['token'];
    filesId?: string[];
    batchParams?: { fields?: string; supportsAllDrives?: boolean };
}

const getBatchRequestPayload = (
    filesId: BatchGoogleDriveFilesRequestParam['filesId'],
    token: string,
    batchParams: BatchGoogleDriveFilesRequestParam['batchParams'],
) => {
    if (!filesId) {
        return undefined;
    }

    let stringRequest = '--END_OF_PART\n';

    filesId.forEach((fileId, index) => {
        stringRequest = stringRequest.concat(
            `Content-Type: application/http\n content-id: ${
                index + 1
            }\nContent-Transfer-Encoding: binary\n\nGET https://www.googleapis.com/drive/v3/files/${fileId}?fields=${
                batchParams?.fields || FILE_FIELDS
            }&supportsAllDrives=${
                batchParams?.supportsAllDrives || true
            }\nAuthorization: Bearer ${token}\nContent-type: application/json\n--END_OF_PART${
                filesId.length - 1 === index ? '--' : ''
            }\n`,
        );
    });

    return stringRequest;
};

export const getBatchGoogleDriveFilesApi = async (params?: BatchGoogleDriveFilesRequestParam, signal?: AbortSignal) => {
    if (!params || !params.filesId?.length || !params.isGoogleUser) {
        return [];
    }

    const { filesId, token: tokenConfig, batchParams } = params;

    const token = await getDriveToken({ fetchKey: 'getToken', token: tokenConfig });

    if (!token) {
        return [];
    }

    const requestPayload = getBatchRequestPayload(filesId, token, batchParams);

    const { data } = await dangerousAxiosInstanceUseOnlyIfNeeded.post(
        'https://www.googleapis.com/batch/drive/v3',
        requestPayload,
        {
            signal,
            headers: {
                'Content-Type': 'multipart/mixed; boundary=END_OF_PART',
            },
        },
    );

    // get the files from string thanks to the --batch delimeter
    const filesJSON = data.split('--batch').slice(1, -1);
    // todo: handle errors (eg.: invalid file id)
    const parsedFiles: GDFile[] = filesJSON.map((fileJSON: string) => {
        const start = fileJSON.indexOf('{');
        const end = fileJSON.lastIndexOf('}') + 1;
        const fileString = fileJSON.slice(start, end);

        return JSON.parse(fileString);
    });

    return parsedFiles;
};

export const googleDriveQueryKeys = {
    all: () => ['google-drive'] as const,
    'batch-files': (params?: BatchGoogleDriveFilesRequestParam) =>
        [...googleDriveQueryKeys.all(), 'batch-files', params] as const,
};
