(function IIFE() {
    'use strict';

    /////////////////////////////

    function ContentTemplate(UserContent) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * User custom layout.
         * The custom Layout is the personnalized template for a specific user.
         * It's the way the user organize the widgets in a specific page for himself.
         *
         * @type {Array}
         */
        var _userCustomLayout = [];

        /**
         * Local variable to handle a list of items.
         *
         * @type {Object}
         */
        var _itemsList = {
            cell: [],
            row: [],
            widget: [],
        };

        /**
         * Current item context.
         * The item could be a cell, a row or a widget and the context means
         * the parent element and index of this item in a template.
         *
         * @type {Object}
         */
        var _context = {};

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Set the current item found context.
         *
         * @param {Object} item   The current item. Could be a widget, a row or a cell.
         * @param {Object} parent The current item parent. Could be a widget, a row or a cell.
         * @param {number} index  The current item index.
         */
        function _setContext(item, parent, index) {
            _context = {
                index: index,
                item: item,
                parent: parent,
            };
        }

        /**
         * Check if the types in params match the current type.
         *
         * @param  {string}       currentType The current type we are looking for.
         * @param  {string|Array} paramsType  The available types to compare with.
         * @return {boolean}      If the currentType match with the paramsType.
         */
        function _hasRightType(currentType, paramsType) {
            return angular.isDefinedAndFilled(paramsType) &&
                ((angular.isArray(paramsType) && _.includes(paramsType, currentType)) || paramsType === currentType);
        }

        /**
         * Check if the element has the right values to be parsed.
         *
         * @param  {Object}  el     The current element we are parsing.
         * @param  {Object}  params The parameters to compare with the current element.
         * @return {boolean} If the element attribute match with the params value.
         */
        function _hasRightValues(el, params) {
            return (angular.isDefinedAndFilled(el[params.attr]) &&
                el[params.attr] === params.value) || angular.isDefinedAndFilled(params.updateElement);
        }

        /**
         * Parse item and apply parameters to be formated correctly before return it.
         *
         * @param  {Object}       el     The element we will parse and update.
         * @param  {string|Array} type   Could be an array or a string.
         * @param  {Object}       parent The parent object.
         * @param  {number}       index  Current index in the loop.
         * @param  {Object}       params The parameters to determine which action we will do.
         * @return {Object}       The widget, cell or row and it parent and index.
         */
        function _parseItem(el, type, parent, index, params) {
            var itemValue;
            // If the parent is a 'row', beware, the children are 'cells' and not components.
            var childrenNames = (type === 'cell') ? 'cells' : 'components';
            // Get a copy of the item if it will be removed to avoid errors, if not, return the object.
            var currentElement = (params.removeItems || (params.removeMatchingItems && params.removeMatchingItems(el))) ? angular.copy(parent[childrenNames][index]) : el;

            // 'updateElement' is set, we update the element using the function before everything.
            if (angular.isDefinedAndFilled(params.updateElement)) {
                params.updateElement(currentElement);
            }

            // Multiple items to save, let update the list.
            if (params.multiple) {
                _itemsList[type].push(currentElement);
            } else {
                // Single item, set the context and set the 'itemValue'.
                itemValue = currentElement;
                _setContext(itemValue, parent, index);
            }

            if (params.removeItems || (params.removeMatchingItems && params.removeMatchingItems(currentElement))) {
                parent[childrenNames].splice(index, 1);
            }

            return itemValue;
        }

        /**
         * Parse the content template and return the wanted element.
         *
         * @param  {Object} content The current content or another one.
         * @param  {Object} params  Possibilities:
         *                              - type {string} the type: widget, cell, row.
         *                              - attr {string} object attribute to search.
         *                              - value {string} object attribute value to search.
         *                              - multiple {true/false} get multiple widgets.
         *                              - removeItems {true/false} remove the widget specified in "objectUuid" param.
         *                              - updateElement {Function} function to use when parsing the wanted element.
         *                              - updateUserLayout {true/false} update the global variable "_userCustomLayout".
         * @return {Object} The wanted element or list of elements.
         */
        function _parseTemplate(content, params) {
            var itemValue;

            if (angular.isDefined(_.get(content, 'template.components'))) {
                for (var i = 0, len = content.template.components.length; i < len; i++) {
                    itemValue = service.parseRow(content.template.components[i], params, content.template, i);
                    if (angular.isDefinedAndFilled(itemValue)) {
                        break;
                    }
                }
            }

            return itemValue;
        }

        /**
         * Parse the template to add the widget in params in the right cell.
         *
         * @param {Object} content            The current content or another one.
         * @param {Object} customLayoutObject The user custom layout.
         * @param {Object} widget             The widget to add.
         */
        function _parseTemplateAndAddWidget(content, customLayoutObject, widget) {
            var cell = service.getElement(content, 'cell', 'uuid', customLayoutObject.cellUuid);

            if (angular.isDefined(cell) && angular.isDefined(cell.components)) {
                if (customLayoutObject.isHiddenByUser) {
                    widget.properties = widget.properties || {};
                    widget.properties.isHiddenByUser = true;
                }

                // If the element has no next element, push it at the end.
                // Otherwise, insert it before the next element.
                if (angular.isUndefinedOrEmpty(customLayoutObject.nextUuid)) {
                    cell.components.push(widget);
                } else {
                    for (var i = 0; i < cell.components.length; i++) {
                        if (cell.components[i].uuid === customLayoutObject.nextUuid) {
                            cell.components.splice(i, 0, widget);

                            break;
                        } else if (i + 1 === cell.components.length) {
                            cell.components.push(widget);

                            break;
                        }
                    }
                }
            }
        }

        /**
         * Manage a Widget.
         *
         * @param  {Object} widget The current widget we are looking into.
         * @param  {Object} params The parameters to determine which action we will do.
         * @param  {Object} parent The parent object.
         * @param  {number} index  Current index in the loop.
         * @return {Object} The widget, cell or row.
         */
        function _parseWidget(widget, params, parent, index) {
            var itemValue;

            if (params.updateUserLayout) {
                // Find the element next to the widget to replace it correctly afterwards.
                // A "previous uuid" strategy could not be used for backward compatibility reasons.
                // Indeed, before widgets were simply pushed at the end of the cell.
                var nextUuid;

                for (var i = 0, len = parent.components.length; i < len; i++) {
                    if ((i + 1) <= (len - 1) && parent.components[i].uuid === widget.uuid) {
                        nextUuid = parent.components[i + 1].uuid;
                        break;
                    }
                }

                // Update the custom user layout.
                _userCustomLayout.push({
                    cellUuid: parent.uuid,
                    isHiddenByUser: (angular.isDefinedAndFilled(widget.properties) &&
                        widget.properties.isHiddenByUser) ? widget.properties.isHiddenByUser : false,
                    nextUuid: nextUuid,
                    widgetUuid: widget.uuid,
                });
            } else if (_hasRightType('widget', params.type) && _hasRightValues(widget, params)) {
                itemValue = _parseItem(widget, 'widget', parent, index, params);
            }

            return itemValue;
        }

        /**
         * Reset the base items list.
         */
        function _resetItemsList() {
            _itemsList = {
                cell: [],
                row: [],
                widget: [],
            };
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Apply the new user content to the current content.
         *
         * @param {Object} content The current content or another one.
         */
        function applyUserContent(content) {
            if (angular.isUndefinedOrEmpty(_.get(content, 'userContent.values'))) {
                return;
            }
        }

        /**
         * Get a specific element from the given template.
         *
         * @param  {Object}       content     The current content or another one.
         * @param  {string|Array} type        Could be an array or a string.
         * @param  {string}       attr        The attribute that the value will match.
         * @param  {string}       value       The matching value of the previous attr.
         * @param  {boolean}      removeItems Delete the items found.
         * @return {Object}       Could be a widget, a row or a cell.
         */
        function getElement(content, type, attr, value, removeItems) {
            return _parseTemplate(content, {
                attr: attr,
                removeItems: removeItems,
                type: type,
                value: value,
            });
        }

        /**
         * Get the context of an element: the element, it parent, and index.
         *
         * @param  {Object}       content The current content or another one.
         * @param  {string|Array} type    Could be an array or a string.
         * @param  {string}       attr    The attribute that the value will match.
         * @param  {string}       value   The matching value of the previous attr.
         * @return {Object}       The widget, cell or row and it parent and index.
         */
        function getElementContext(content, type, attr, value) {
            _context = {};
            _parseTemplate(content, {
                attr: attr,
                type: type,
                value: value,
            });

            return _context;
        }

        /**
         * Get a list of widgets from the template.
         *
         * @param  {Object}       content     The current content or another one.
         * @param  {string|Array} type        Could be an array or a string.
         * @param  {string}       attr        The attribute that the value will match.
         * @param  {string}       value       The matching value of the previous attr.
         * @param  {boolean}      removeItems Delete the items found.
         * @return {Array}        Could be a list of widgets, rows or cells.
         */
        function getElementList(content, type, attr, value, removeItems) {
            _resetItemsList();
            _parseTemplate(content, {
                attr: attr,
                multiple: true,
                removeItems: angular.isDefinedAndFilled(removeItems) && removeItems,
                type: type,
                value: value,
            });

            return (angular.isString(type)) ? _itemsList.widget : _itemsList;
        }

        /**
         * Reset user customization of the current page.
         *
         * @param {Object}   content The current content or another one.
         * @param {Object}   user    The user who belongs the custom layout to reset.
         * @param {Function} cb      The callback when the task is complete.
         */
        function resetUserTemplate(content, user, cb) {
            if (angular.isDefinedAndFilled(_.get(content.userContent, 'values.customLayout'))) {
                delete content.userContent.values.customLayout;

                UserContent.save({
                    content: content.id,
                    user: user.id,
                    values: content.userContent.values,
                }, cb);
            }
        }

        /**
         * Save the User Customizable Template.
         *
         * @param {Object}   content The current content or another one.
         * @param {Object}   user    The user who belongs the custom layout to reset.
         * @param {Function} cb      The callback when the task is complete.
         * @param {Function} errCb   The callback if the task fail.
         */
        function saveUserTemplate(content, user, cb, errCb) {
            if (content.isCustomizableLayout) {
                _userCustomLayout = [];

                _parseTemplate(content, {
                    updateUserLayout: true,
                });

                // Save the user content profile.
                if (angular.isUndefinedOrEmpty(content.userContent)) {
                    content.userContent = {
                        content: content.id,
                        values: {},
                    };
                }

                content.userContent.values.customLayout = _userCustomLayout;
                UserContent.save({
                    content: content.id,
                    user: user.id,
                    values: angular.copy(content.userContent.values),
                }, cb, errCb);
            }
        }

        /**
         * Parse a template cell.
         *
         * @param  {Object} cell   The current cell we are looking into.
         * @param  {Object} params The parameters to determine which action we will do.
         * @param  {Object} parent The parent object.
         * @param  {number} index  Current index in the loop.
         * @return {Object} The widget, cell or row.
         */
        function parseCell(cell, params, parent, index) {
            var itemValue;

            if (angular.isDefined(cell.components)) {
                if (_hasRightType('cell', params.type) && _hasRightValues(cell, params)) {
                    itemValue = _parseItem(cell, 'cell', parent, index, params);

                    if (angular.isDefinedAndFilled(itemValue)) {
                        return itemValue;
                    }
                }

                // It's a cell, parse widgets.
                if (angular.isArray(cell.components)) {
                    for (var j = 0, lenJ = cell.components.length; j < lenJ; j++) {
                        if (angular.isUndefinedOrEmpty(cell.components[j])) {
                            continue;
                        }

                        if (angular.isDefinedAndFilled(cell.components[j].type) && cell.components[j].type === 'cell') {
                            // A cell in a cell, parse again a cell.
                            itemValue = parseCell(cell.components[j], params, cell, j);

                            if (angular.isDefinedAndFilled(itemValue)) {
                                break;
                            }
                        } else if (angular.isDefinedAndFilled(cell.components[j].type) &&
                            cell.components[j].type === 'row') {
                            // A row in a cell, parse again a row.
                            itemValue = service.parseRow(cell.components[j], params, cell, j);

                            if (angular.isDefinedAndFilled(itemValue)) {
                                break;
                            }
                        } else {
                            // It's a Widget, manage it.
                            itemValue = _parseWidget(cell.components[j], params, cell, j);

                            if (angular.isDefinedAndFilled(itemValue)) {
                                break;
                            }
                        }
                    }
                }
            }

            return itemValue;
        }

        /**
         * Parse a template row.
         *
         * @param  {Object} row    The current row we are looking into.
         * @param  {Object} params The parameters to determine which action we will do.
         * @param  {Object} parent The parent object.
         * @param  {number} index  Current index in the loop.
         * @return {Object} The widget, cell or row.
         */
        function parseRow(row, params, parent, index) {
            var itemValue;

            if (angular.isDefinedAndFilled(row.cells)) {
                if (_hasRightType('row', params.type) && _hasRightValues(row, params)) {
                    itemValue = _parseItem(row, 'row', parent, index, params);
                    if (angular.isDefinedAndFilled(itemValue)) {
                        return itemValue;
                    }
                }

                // Iterate on the cells.
                for (var i = 0, len = row.cells.length; i < len; i++) {
                    // We found a cell in the row, parse it.
                    itemValue = parseCell(row.cells[i], params, row, i);
                    if (angular.isDefinedAndFilled(itemValue)) {
                        break;
                    }
                }
            }

            return itemValue;
        }

        /////////////////////////////

        service.applyUserContent = applyUserContent;
        service.getElementContext = getElementContext;
        service.getElement = getElement;
        service.getElementList = getElementList;
        service.resetUserTemplate = resetUserTemplate;
        service.saveUserTemplate = saveUserTemplate;
        service.parseRow = parseRow;
        service.parseCell = parseCell;
    }

    /////////////////////////////

    angular.module('Services').service('ContentTemplate', ContentTemplate);
})();
