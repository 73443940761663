/* istanbul ignore file */
import { SelectMultipleProps, ThOrder } from '@lumapps/lumx/react';

export enum DateRangeType {
    DAYS = 'DateRangeType/DAYS',
}

export interface DateRange {
    type: DateRangeType;
    value: string;
}

export interface SelectChoice<T> {
    label: string;
    value: T;
}

export interface MultipleSelectProps {
    availableValues: string[];
    selectMultipleProps: SelectMultipleProps;
    onItemSelected: (value: string) => void;
}

export interface SearchAnalytic {
    searchTerms: string;
    totalSearches: number;
    totalPercent: number;
}

export interface AnalyticsModuleResultsHeaderBaseCell {
    label: string;
    name: keyof SearchAnalytic;
    width?: string;
    /** Custom classname to be able to center text for example */
    className?: string;
}

export interface AnalyticsModuleResultsHeaderSortableCell extends AnalyticsModuleResultsHeaderBaseCell {
    sortOrder: ThOrder;
}

export type AnalyticsModuleResultsHeaderCell =
    | AnalyticsModuleResultsHeaderBaseCell
    | AnalyticsModuleResultsHeaderSortableCell;

export interface SortInformations {
    key: keyof SearchAnalytic;
    order: ThOrder;
}

export type UserLanguage = string;
export type Site = string;
export type PageNumber = number;

export enum AnalyticsStatus {
    INITIAL = 'initial',
    LOADING = 'loading',
    READY = 'ready',
    NOT_ENOUGH_DATA = 'not_enough_data',
    ERROR = 'error',
    NO_RESULTS_FOUND = 'no_results_found',
}
export interface AnalyticsInformations {
    analytics: SearchAnalytic[];
    totalAnalytics: number;
    status: AnalyticsStatus;
}
