(function IIFE() {
    'use strict';

    /////////////////////////////

    function ContentCreateButtonController($scope, $stateParams, $timeout, Content, CustomContentType, MainNav,
        Template, User, UserAccess) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the user as the right to drop an item in the current menu position.
         *
         * @type {boolean}
         */
        var _canDropMenuHere = false;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the dropdown.
         *
         * @type {string}
         */
        vm.DROPDOWN_ID = 'dropdown-content-create-button';

        /**
         * A list of the custom content types the user can edit.
         *
         * @type {Array}
         */
        vm.allowedCustomContentTypes = [];

        /**
         * Indicates if the current user can create content in the current location.
         *
         * @type {boolean}
         */
        vm.canCreateContent = false;

        /**
         * Indicates if the current user can create a content with no template applied to the new content.
         *
         * @type {boolean}
         */
        vm.canCreateFromScratch = false;

        /**
         * The list key for the selected custom content type template list.
         *
         * @type {string}
         */
        vm.currentListKey = 'template-list-';

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.CustomContentType = CustomContentType;
        vm.Template = Template;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Re-initialize the custom content type list and check if the user can create a content here.
         */
        function _initCcts() {
            var ccts = angular.fastCopy(CustomContentType.displayList(CustomContentType.CURRENT_INSTANCE_CCT_LIST_KEY));

            vm.allowedCustomContentTypes = ccts.filter(
                function filterCustomContentType(customContentType) {
                    return UserAccess.isUserAllowed('CUSTOM_CONTENT_EDIT', {
                        customContentTypeId: customContentType.uid,
                    });
                }
            );

            vm.canCreateContent = _canDropMenuHere && angular.isDefinedAndFilled(vm.allowedCustomContentTypes);
        }
        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Select a specific custom content type.
         *
         * @param {Event}  evt               The original event triggering this method.
         * @param {Object} customContentType The custom content type to select.
         */
        function selectCustomContentType(evt, customContentType) {
            evt.stopPropagation();

            if (angular.isUndefinedOrEmpty(customContentType)) {
                return;
            }

            vm.selectedCustomContentType = customContentType;
            vm.canCreateFromScratch = !vm.selectedCustomContentType.createFromScratchLocked ||
                UserAccess.canManageInstanceSettings();
            vm.currentListKey = 'template-list-' + vm.selectedCustomContentType.uid;

            // Get the list of templates for content of the selected custom content type.
            Template.cacheFilterize({
                customContentType: vm.selectedCustomContentType.uid,
                visibleInheritedOnly: false,
            }, undefined, undefined, vm.currentListKey);
        }

        /////////////////////////////

        vm.selectCustomContentType = selectCustomContentType;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Whenever a dropdown is closed, catch the event to see if it was the content create button dropdown.
         *
         * @param {Event}  evt    The original event triggering this method.
         * @param {Object} params An object holding the identifier of the dropdown.
         */
        $scope.$on('lx-dropdown__close', function onDropdownClose(evt, params) {
            if (params.uuid === vm.DROPDOWN_ID) {
                $timeout(function timedOutCCTReset() {
                    vm.selectedCustomContentType = undefined;
                });
            }
        });

        /**
         * Only initialize all the content create button stuff when the navigation is done loading.
         */
        $scope.$on('main-nav-loaded', function onMainNavLoaded() {
            vm.init();
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            // Make sure the MainNav is not still loading and that the current page is in the MainNav.
            if (MainNav.is.initializing || angular.isUndefinedOrEmpty(Content.getCurrent().navigation)) {
                return;
            }

            vm.currentPageNavItem = MainNav.findNavItemBySlug($stateParams.slug);

            if (angular.isUndefinedOrEmpty(vm.currentPageNavItem)) {
                return;
            }

            _canDropMenuHere = UserAccess.isUserAllowed('MENU_DROP', {
                checkContent: true,
                ignoreAdmins: false,
                uuid: vm.currentPageNavItem.uuid,
            });

            CustomContentType.init().then(_initCcts);
        };

        vm.init();
    }

    function ContentCreateButtonDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ContentCreateButtonController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: '/client/front-office/modules/content/common/views/content-create-button.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsContentCreateButton', ContentCreateButtonDirective);
})();
