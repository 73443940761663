import React from 'react';

import { Size, SkeletonRectangle, SkeletonRectangleVariant, SkeletonRectangleProps } from '@lumapps/lumx/react';

/**
 * The skeleton of the follow user button.
 *
 * Used to show a loading state.
 */
export const FollowUserButtonSkeleton: React.FC<SkeletonRectangleProps> = ({ size, ...props }) => (
    <SkeletonRectangle
        variant={SkeletonRectangleVariant.rounded}
        height={size}
        width={size === Size.s ? Size.l : Size.xl}
        style={{ margin: '0', borderRadius: '2px' }}
        {...props}
    />
);
