import hast from 'hast';
import isEmpty from 'lodash/isEmpty';

import { parseInlineCSS } from '@lumapps/utils/css/parseInlineCSS';
import { and } from '@lumapps/utils/function/predicate/and';
import { or } from '@lumapps/utils/function/predicate/or';
import { ALIGNMENTS, ALIGNMENT_CLASSES } from '@lumapps/wrex/constants';
import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchAttribute, matchNode } from '@lumapps/wrex/serialization/html/fromHTML/utils';
import { getTextNodes } from '@lumapps/wrex/slate/utils/getTextNodes';
import { isText } from '@lumapps/wrex/slate/utils/isText';
import { Alignment } from '@lumapps/wrex/types/core';

import { CODE_BLOCK, HEADLINE, INLINE_CODE, PARAGRAPH, SUBTITLE, TITLE } from '../../../constants';
import { createCodeBlock } from '../../../utils/createCodeBlock';
import { createHeadline } from '../../../utils/createHeadline';
import { createInlineCode } from '../../../utils/createInlineCode';
import { createParagraph } from '../../../utils/createParagraph';
import { createSubTitle } from '../../../utils/createSubTitle';
import { createTitle } from '../../../utils/createTitle';

type ElementTypes =
    | typeof PARAGRAPH
    | typeof TITLE
    | typeof HEADLINE
    | typeof SUBTITLE
    | typeof CODE_BLOCK
    | typeof INLINE_CODE;

const getAlignment = (node: hast.Parent): Alignment | undefined => {
    const { style, className } = node.properties as { style: string; className: string[] };

    if (className?.includes(ALIGNMENT_CLASSES[ALIGNMENTS.start])) {
        return ALIGNMENTS.start;
    }
    if (className?.includes(ALIGNMENT_CLASSES[ALIGNMENTS.center])) {
        return ALIGNMENTS.center;
    }
    if (className?.includes(ALIGNMENT_CLASSES[ALIGNMENTS.end])) {
        return ALIGNMENTS.end;
    }

    const styleObject = parseInlineCSS(style);
    const styleValue = styleObject?.['text-align'];

    if (!styleValue) {
        return undefined;
    }

    if ([ALIGNMENTS.start, 'left'].includes(styleValue)) {
        return ALIGNMENTS.start;
    }
    if (styleValue === ALIGNMENTS.center) {
        return ALIGNMENTS.center;
    }
    if ([ALIGNMENTS.end, 'right'].includes(styleValue)) {
        return ALIGNMENTS.end;
    }

    return undefined;
};

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    /**
     * Convert HTML H1 into slate Headline element.
     */
    headline: {
        test: or(
            // Classic HTML H1
            matchNode({ tagName: 'h1' }),
            // ARIA heading (used in Microsoft Office 360 web)
            and(matchAttribute('role', /^heading$/), matchAttribute('ariaLevel', /^1$/)),
            // data-ccp-parastyle attribute (used in Microsoft Word)
            matchAttribute('dataCcpParastyle', /^Title$/),
        ),
        transform: (node, children: any, { alignmentAllowed }) => {
            return createHeadline(
                isEmpty(children) ? [createParagraph()] : children,
                alignmentAllowed ? getAlignment(node) : undefined,
            );
        },
        type: HEADLINE,
    },

    /**
     * Convert HTML H3 into slate SubTitle element.
     */
    [SUBTITLE]: {
        test: or(
            // Classic HTML H3 to H6 headings
            matchNode({ tagName: 'h3' }),
            matchNode({ tagName: 'h4' }),
            matchNode({ tagName: 'h5' }),
            matchNode({ tagName: 'h6' }),
            // ARIA heading (used in Microsoft Office 360 web)
            and(matchAttribute('role', /^heading$/), matchAttribute('ariaLevel', /^[3456]$/)),
        ),

        transform: (node, children: any, { alignmentAllowed }) => {
            return createSubTitle(
                isEmpty(children) ? [createParagraph()] : children,
                alignmentAllowed ? getAlignment(node) : undefined,
            );
        },
        type: SUBTITLE,
    },

    /**
     * Convert HTML H2 into slate Title element.
     */
    title: {
        test: or(
            // Classic HTML H2 to H6 headings
            matchNode({ tagName: 'h2' }),
            matchNode({ tagName: 'h3' }),
            matchNode({ tagName: 'h4' }),
            matchNode({ tagName: 'h5' }),
            matchNode({ tagName: 'h6' }),
            // ARIA heading (used in Microsoft Office 360 web)
            and(matchAttribute('role', /^heading$/), matchAttribute('ariaLevel', /^[23456]$/)),
            // data-ccp-parastyle attribute (used in Microsoft Word)
            matchAttribute('dataCcpParastyle', /^Subtitle$/),
        ),
        transform: (node, children: any, { alignmentAllowed }) => {
            return createTitle(
                isEmpty(children) ? [createParagraph()] : children,
                alignmentAllowed ? getAlignment(node) : undefined,
            );
        },
        type: TITLE,
    },

    /**
     * Convert HTML P into slate paragraph element.
     */
    [PARAGRAPH]: {
        test: matchNode({ tagName: 'p' }),
        transform(node, children: any, { alignmentAllowed }) {
            if (children && !children.find((child: any) => child.type)) {
                return createParagraph(
                    isEmpty(children) ? undefined : children,
                    alignmentAllowed ? getAlignment(node) : undefined,
                );
            }

            // Handle Word adding empty paragraph to centered image
            if (
                children[0].type === 'image-group' &&
                children[1] &&
                isText(children[1]) &&
                !(children[1].text as string).replace(/\s/g, '').length
            ) {
                return createParagraph([children[0]], alignmentAllowed ? getAlignment(node) : undefined);
            }

            return createParagraph(children, alignmentAllowed ? getAlignment(node) : undefined);
        },
        type: PARAGRAPH,
    },

    /**
     * Convert HTML code into slate inline code element.
     */
    [INLINE_CODE]: {
        test: matchNode({ tagName: 'code' }),
        transform(_, children: any) {
            if (children.length === 0) {
                return createInlineCode();
            }
            return createInlineCode(children);
        },
        type: INLINE_CODE,
    },

    /**
     * Convert HTML codeblocks into slate code block element.
     */
    [CODE_BLOCK]: {
        test: matchNode({ tagName: 'pre' }),
        transform(_, children: any) {
            return createCodeBlock(getTextNodes(children));
        },
        type: CODE_BLOCK,
    },
};

/**
 * Get HTML to slate conversion options for the requested elements.
 */
export function getElementConvertOptions(elements: Array<ElementTypes>) {
    // Pick elements from the constant.
    // Their order should be the same as in the constant above.
    return Object.entries(ELEMENTS)
        .map(([element, option]) => (elements.includes(element as any) ? option : undefined))
        .filter(Boolean) as HTMLElementConvertOption[];
}
