/* istanbul ignore file */
import { baseAdminDirectory } from '@lumapps/directories/routes';
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const directoryTagsRoutes: Route = {
    path: '/admin/directory/tag/:id',
    legacyId: 'app.admin.directory-tag',
    appId: AppId.backOffice,
};

const adminDirectoryTags = (id: string): Route => baseAdminDirectory(id, directoryTagsRoutes);

export { adminDirectoryTags, directoryTagsRoutes };
