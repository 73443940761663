import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';

import { translate as t } from '../../../translations';
import { Icon } from '../icons';

import { PROGRESS_TYPE } from './constant';
import { Progress } from './Progress';

/**
 * Display a circular or a linear loader (Lumx like).
 * The loader can also display a "no-results".
 */
export class Loader extends PureComponent {
    static propTypes = {
        /** The color of the loader (primary per default). */
        color: string,
        /** If the no-result has a background. */
        hasBackground: bool,
        /** If the loader has to display a no-result. */
        hasNoResults: bool,
        /** The condition to display the loader. */
        isLoading: bool,
        /** The text of the no-results (if we want to override the default text). */
        noResultsText: string,
        /** The type of the loader, can be circular (default) or linear. */
        type: string,
    };

    static defaultProps = {
        color: 'primary',
        hasBackground: false,
        hasNoResults: false,
        isLoading: true,
        type: PROGRESS_TYPE.CIRCULAR,
    };

    render() {
        const { color, hasBackground, hasNoResults, isLoading, noResultsText, type } = this.props;

        return (
            <div
                className={classNames('loader', `loader--${type}`, {
                    'loader--has-background': hasBackground,
                    'loader--no-results': hasNoResults,
                })}
            >
                {isLoading && !hasNoResults && <Progress color={color} diameter="50" type={type} />}

                {hasNoResults && (
                    <div className={classNames('no-results', { hasBackground: 'no-results--has-background' })}>
                        <Icon type="flat" value="information-outline" />
                        <span>{noResultsText ? noResultsText : t('NO_RESULTS')}</span>
                    </div>
                )}
            </div>
        );
    }
}
