import { dangerousAxiosInstanceUseOnlyIfNeeded } from '@lumapps/base-api';
import { logException } from '@lumapps/utils/log/logException';
import { Editor, NodeEntry, ReactEditor } from '@lumapps/wrex/slate';

import { ImageEditor, ImageEditorOptions, ImageGroupElement } from '../types';
import { uploadImage } from './uploadImage';

/**
 * Upload an image from a website URL (used for insertion via HTML Paste for example)
 * and update its status directly into the editor.
 */
export async function uploadExternalImage(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    entry: NodeEntry<ImageGroupElement>,
) {
    const [element, path] = entry;
    const url = element.images[0]?.tempSrc;
    if (!url) {
        return;
    }
    const pathRef = Editor.pathRef(editor, path);
    try {
        // Download external image as a Blob.
        const { data: blob } = await dangerousAxiosInstanceUseOnlyIfNeeded.get<Blob>(url, { responseType: 'blob' });

        // Cast Blob to File and upload to server.
        const file = new File([blob], '', {
            type: blob.type,
        });

        await uploadImage(options, editor, [file], pathRef);
    } catch (error) {
        logException(error);
    }
    pathRef.unref();
}
