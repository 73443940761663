/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const contentTypeRoute: Route = {
    path: '/admin/custom-content-type',
    legacyId: 'app.admin.customContentType',
    appId: AppId.backOffice,
};

export const adminContentType = (): Route => contentTypeRoute;
