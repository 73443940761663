import type { Wrex } from '@lumapps/wrex/types';

import { IMAGE_GROUP } from '../constants';
import type { ImageGroupElement } from '../types';

/** Create an image group element */
export function createImageGroup(attributes: Wrex.ElementAttributes<ImageGroupElement>): ImageGroupElement {
    return {
        type: IMAGE_GROUP,
        title: '',
        ...attributes,
        children: [{ text: '' }],
    };
}
