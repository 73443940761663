import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { MetadataLinkList } from '@lumapps/metadata-link/components/MetadataLinkList';
import { MetadataListV2 } from '@lumapps/metadata/types';

import { MetadataSelectionBlock as MetadataSelectionBlockProps } from '../../types';
import { MetadataSelectionSkeleton } from './MetadataSelectionSkeleton';

import './index.scss';

export const CLASSNAME = 'metadata-selection-block';

export const MetadataSelectionBlock: React.FC<MetadataSelectionBlockProps> = ({
    category,
    metadata,
    loading = false,
    theme = Theme.light,
    contentTypeId = '',
}) => {
    const metadataList: MetadataListV2 = metadata.map((meta) => ({ id: meta.id, name: meta.name.value || '_' }));
    const { element } = useClassnames(CLASSNAME);
    const categoryClassName = element('category', {
        'theme-light': theme === Theme.light,
        'theme-dark': theme === Theme.dark,
    });

    if (loading) {
        return <MetadataSelectionSkeleton theme={theme} />;
    }

    return (
        <div className={CLASSNAME}>
            {/* eslint-disable-next-line react/forbid-elements */}
            {category && <h3 className={categoryClassName}>{category.name.value}</h3>}
            <MetadataLinkList
                metadataList={metadataList}
                theme={theme}
                loading={loading}
                contentTypeId={contentTypeId}
            />
        </div>
    );
};
