import { Instance } from '@lumapps/instance/types';
import { endUserJourneysBaseRoute } from '@lumapps/journeys-front-office/routes';
import { PLAY_GALLERY } from '@lumapps/play-gallery/keys';
import { playGalleryRoute } from '@lumapps/play-gallery/routes';
import { Route } from '@lumapps/router';
import { createUrl } from '@lumapps/router/utils';
import { GLOBAL, TranslationAPI } from '@lumapps/translations';
import { feedRoute } from '@lumapps/user-feed/routes';

import { NavigationItemType, SystemPageReference } from '../types';

/** Dictionary of the route for each system page reference */
export const SYSTEM_PAGES_ROUTES: Record<SystemPageReference, Route> = {
    [NavigationItemType.JOURNEYS]: endUserJourneysBaseRoute,
    [NavigationItemType.PERSONAL_FEED]: feedRoute,
    [NavigationItemType.VIDEO_GALLERY]: playGalleryRoute,
};

/** Dictionary of the default keys for each system page reference */
const SYSTEM_PAGE_DEFAULT_NAMES: Record<SystemPageReference, string> = {
    [NavigationItemType.JOURNEYS]: GLOBAL.JOURNEYS,
    [NavigationItemType.PERSONAL_FEED]: GLOBAL.FEED,
    [NavigationItemType.VIDEO_GALLERY]: PLAY_GALLERY.VIDEO_GALLERY_TITLE,
};

/** Generate the correct system page url depending on the systemPageReference, or empty string as a fallback */
export const getSystemPageUrl = (systemPageReference: SystemPageReference, parentInstanceSlug?: Instance['slug']) =>
    SYSTEM_PAGES_ROUTES[systemPageReference]
        ? createUrl(SYSTEM_PAGES_ROUTES[systemPageReference], null, parentInstanceSlug)
        : '';

/** Generate the correct system page default title depending on the systemPageReference, or undefined as a fallback */
export const getSystemPageDefaultTitle = (
    translate: TranslationAPI['translate'],
    systemPageReference?: SystemPageReference,
) =>
    systemPageReference && SYSTEM_PAGE_DEFAULT_NAMES[systemPageReference]
        ? translate(SYSTEM_PAGE_DEFAULT_NAMES[systemPageReference]) || undefined
        : undefined;
