import { getAttributes } from '@lumapps/data-attributes';

const getSocialProfileAttributes = getAttributes('social-profile');

(function IIFE() {
    /////////////////////////////

    function SocialProfileService(
        InitialSettings,
        Instance,
        Interest,
        ReduxStore,
        SocialSubscription,
        User,
        UserFollower,
        UserFollowing,
        UserSettings,
    ) {
        'ngInject';

        const service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The current user profile.
         *
         * @type {Object}
         */
        let _currentSocialProfile;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Whether the current scope for calls is the instance or the customer.
         *
         * @type {boolean}
         */
        service.isInstanceScope = true;

        /**
         * A config object with all of the params required for each type of subscription call.
         *
         * @type {Object}
         */
        service.quickApiConfig = {
            communities: {
                api: SocialSubscription,
                filtered: {},
                query: {
                    followingContentType: [],
                },
            },
            content: {
                api: SocialSubscription,
                filtered: {},
                query: {
                    followingContentType: [],
                },
            },
            following: {
                api: UserFollowing,
                filtered: {},
                sidebarLimit: 7,
            },
            interests: {
                api: Interest,
                filtered: {},
                query: {},
            },
            // eslint-disable-next-line sort-keys
            followers: {
                api: UserFollower,
                filtered: {},
                sidebarLimit: 7,
            },
        };

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add params to all queries made to the social profile.
         *
         * @param {Object} params           The initial parameters to use for all queries.
         * @param {string} [key='instance'] The backend property names vary depending on endpoints. In most cases, it is
         *                                  'instance' but sometimes it may be 'instanceKey' for example.
         */
        function addGlobalParams(params, key) {
            const profileId = service.getCurrent().id;
            key = key || 'instanceId';

            // Add current instance id to the request params if current scope is instance.
            params[key] = service.isInstanceScope ? Instance.getCurrentInstanceId() : undefined;

            // If current user profile is not the connected user profile, add userKey to query.
            if (profileId !== User.getConnected().uid) {
                params.userKey = profileId;
            }
        }

        /**
         * Return current social profile.
         *
         * @return {Object} The current social profile in use.
         */
        function getCurrent() {
            return _currentSocialProfile;
        }

        /**
         * Quick filterize on a given API described in service.quickApiConfig.
         *
         * @param {string}   apiKey              The key of the API in the quickApiConfig object to be used for the
         *                                       filterize call.
         * @param {string}   [listKey='default'] The list key identifier used for the filterize call.
         * @param {Function} [cb]                A success callback function to be called when the filterize is successful.
         * @param {Function} [errCb]             An error callback function to be called when the filterize call fails.
         */
        function quickFilter(apiKey, listKey, cb, errCb) {
            if (angular.isUndefinedOrEmpty(service.quickApiConfig[apiKey])) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;
            listKey = listKey || 'default';

            const config = service.quickApiConfig[apiKey];
            const params = {};

            // Update params.
            service.addGlobalParams(params, 'instanceKey');

            if (angular.isDefined(config.query)) {
                angular.extend(params, config.query);
            }

            // Fetch subscriptions of a given type.
            config.api.filterize(params, cb, errCb, listKey);

            // Set filter flag for later use.
            config.filtered[listKey] = true;
        }

        /**
         * Remove a specific user from the list of following users.
         *
         * @param {string} userId The user identifier to be removed.
         */
        function removeFollowing(userId) {
            const userList = service.quickApiConfig.following.api.displayList('following');
            const userToRemoveIndex = _.findIndex(userList, {
                uid: userId,
            });

            if (userToRemoveIndex > -1) {
                userList.splice(userToRemoveIndex, 1);
            }
        }

        /**
         * Set the current social profile using a given user.
         *
         * @param {Object} user A user object to use as the current social profile.
         */
        function setCurrent(user) {
            _currentSocialProfile = user;
        }

         /// ///////// REDUX STUFF START HERE aka. ReduxSyncableServiceInterface ////////////

        // /**.
        //  * Redux synced services are in charge of their own reducer.
        //  * ? could we put them somewhere else.
        //  *
        //  * @param {*} state  Current state of the reducer.
        //  * @param {*} action Incoming action.
        //  */
        // Function reduxReducer(state = mapStateToRedux(), action) {.
        // Switch (action.type) {.
        // Case service.reduxUpdateActionType:.
        //             Return action.newState; // Convention ??
        // Default:.
        //             Return state;
        // }.
        // }.

        // /**.
        //  * Should retrun the service data that need to be synced with redux.
        //  *
        //  * @return The data. Aka. store shape.
        //  */
        // Function mapStateToRedux() {.
        //     Const current = getCurrent();
        // If (!current) {.
        //         Return {};
        // }.
        //     Return current;
        // }.

        // /**.
        //  * Triggered when synced data is changed by redux.
        //  * It takes in the new state and should update the Angular service accordingly.
         //  */
        // Function mapReduxToAngular(newState) {.
        //     SetCurrent(newState);
        // }.

        // // The namespace for this service in the redux store.
        // Service.reduxReducerName = 'socialProfile';

        // // The action type triggered when Angular updated the state.
        // Service.reduxUpdateActionType = 'socialProfile/update';

        // // Expose the appropriate functions.
        // Service.mapStateToRedux = mapStateToRedux;
        // Service.mapReduxToAngular = mapReduxToAngular;
        // Service.reduxReducer = reduxReducer;

        /////////////////////////////

        service.addGlobalParams = addGlobalParams;
        service.getCurrent = getCurrent;
        service.quickFilter = quickFilter;
        service.removeFollowing = removeFollowing;
        service.setCurrent = setCurrent;
        service.getSocialProfileAttributes = getSocialProfileAttributes;
        service.UserSettings = UserSettings;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        service.init = function init() {
            service.quickApiConfig.communities.query.followingContentType = [InitialSettings.CONTENT_TYPES.COMMUNITY];

            service.quickApiConfig.content.query.followingContentType = [
                InitialSettings.CONTENT_TYPES.PAGE,
                InitialSettings.CONTENT_TYPES.CUSTOM_LIST,
                InitialSettings.CONTENT_TYPES.NEWS_LIST,
                InitialSettings.CONTENT_TYPES.NEWS,
                InitialSettings.CONTENT_TYPES.CUSTOM,
                InitialSettings.CONTENT_TYPES.DIRECTORY,
            ];

            // Enable Redux sync.
            // ReduxStore.subscribe(service);
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('SocialProfile', SocialProfileService);
})();
