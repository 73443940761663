import React, { Suspense as ReactSuspense, ReactNode, ErrorInfo } from 'react';

import { Message, Kind, Text } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { ErrorBoundary, ErrorBoundaryProps } from '../ErrorBoundary';
import { LinearProgressSuspenseFallback } from '../LinearProgressSuspenseFallback';

export interface SuspenseProps {
    children?: ReactNode;
    /** Fallback react node used when loading. */
    loadingFallback?: NonNullable<ReactNode> | null;
    /** Fallback react node used when an error occurred. */
    errorFallback?: NonNullable<ReactNode> | null;
    /** Callback on error */
    onError?: ErrorBoundaryProps['onError'];
    /** Whether the default error fallback should be an error notification or an inline error message. */
    isDialog?: boolean;
}

/** Generic error display. */
const GenericError: React.FC<{ isDialog: boolean }> = ({ isDialog }) => {
    const { translateKey } = useTranslate();
    const { error } = useNotification();

    React.useEffect(() => {
        if (isDialog) {
            error({ translate: GLOBAL.ERROR_WHILE_FETCHING_DATA });
        }
    }, [error, isDialog]);

    return isDialog ? null : (
        <Message kind={Kind.error} hasBackground>
            <Text as="p">{translateKey(GLOBAL.GENERIC_ERROR)}</Text>
            <Text as="p">{translateKey(GLOBAL.TRY_RELOAD)}</Text>
        </Message>
    );
};

/**
 * A React.Suspense and ErrorBoundary wrapper with sensible fallback defaults.
 */
export const Suspense: React.FC<SuspenseProps> = ({
    loadingFallback,
    errorFallback,
    children,
    onError,
    isDialog = false,
}) => {
    const onBoundaryError = (error: Error, info: ErrorInfo) => {
        if (onError) {
            onError(error, info);
        }

        // We want to log errors so that in the future we can track them and get notified when they happen.
        // eslint-disable-next-line no-console
        console.error(error, info);
    };

    return (
        <ErrorBoundary fallback={errorFallback || <GenericError isDialog={isDialog} />} onError={onBoundaryError}>
            <ReactSuspense fallback={loadingFallback || <LinearProgressSuspenseFallback />}>{children}</ReactSuspense>
        </ErrorBoundary>
    );
};
