import React from 'react';

import { margin } from '@lumapps/classnames';
import { SkeletonTypography, SkeletonRectangle, Size, GlobalSize } from '@lumapps/lumx/react';

/**
 * Form field loading state
 * @returns FieldLoadingState
 */
export const FieldLoadingState: React.FC<{ width?: string }> = ({ width = '200px' }) => {
    return (
        <>
            <SkeletonTypography typography="body1" width={width} className={margin('bottom')} />
            <SkeletonRectangle width={'100%' as GlobalSize} height={Size.m} className={margin('bottom', 'big')} />
        </>
    );
};
