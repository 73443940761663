import { connect } from '@lumapps/redux/react';
import { Dispatch, BaseStore } from '@lumapps/redux/types';
import { canAccessSASelector, shouldGetUser, isSAEnabled } from '@lumapps/sa-connected-user/ducks/selectors';
import { getUser } from '@lumapps/sa-connected-user/ducks/thunks';
import {
    userProgramsListSelector,
    userProgramsStatusSelector,
} from '@lumapps/sa-programs/ducks/userPrograms/selectors';
import { fetchUserPrograms } from '@lumapps/sa-programs/ducks/userPrograms/thunks';
import { isConnected } from '@lumapps/user/ducks/selectors';

import { SAMenu } from './SAMenu';

const mapStateToProps = (state: BaseStore) => ({
    isSAEnabled: isConnected(state) && isSAEnabled(state),
    canAccessSa: canAccessSASelector(state),
    programs: userProgramsListSelector(state),
    fetchProgramsStatus: userProgramsStatusSelector(state),
    shouldGetUser: shouldGetUser(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchUserPrograms: () => dispatch(fetchUserPrograms()),
    getUser: () => dispatch(getUser()),
});

const ConnectedSAMenu = connect(mapStateToProps, mapDispatchToProps)(SAMenu);

export { ConnectedSAMenu as SAMenu };
