/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isAdminSettingsAllowed } from '../../ducks/selectors';
import { InstanceSettingsSideNavigation } from './InstanceSettingsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isAdminSettingsAllowed(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedInstanceSettingsSideNavigation = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InstanceSettingsSideNavigation);

export { ConnectedInstanceSettingsSideNavigation as InstanceSettingsSideNavigation };
