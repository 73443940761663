import { Alignment } from '@lumapps/wrex/types/core';

import { TITLE } from '../constants';
import type { TitleElement } from '../types';

/** Create title element */
export function createTitle(
    children: TitleElement['children'] = [{ text: '' }],
    alignment: Alignment | undefined = undefined,
): TitleElement {
    return { type: TITLE, children, ...(alignment ? { alignment } : undefined) };
}
