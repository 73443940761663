import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';

import { FetchBlockParams, getWidgetBlocks } from '../../api';
import { getFormattedWidgetFilterFetchParams } from '../../utils/getFormattedWidgetFilterFetchParams';
import { fetchWidgetBlocks } from './fetchWidgetBlocks';

export const fetchWidgetBlocksById =
    ({
        params,
        widgetId,
        widgetType,
        isMainWidget,
    }: {
        params?: FetchBlockParams;
        widgetId: string;
        widgetType: string;
        isMainWidget: boolean;
    }) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const currentState = getState();
        const filterFetchParams = getFormattedWidgetFilterFetchParams(currentState, params, widgetId);
        const fetchParams = { ...params, ...filterFetchParams };

        dispatch(
            fetchWidgetBlocks(
                widgetId,
                async (acceptLanguageHeader) => {
                    return getWidgetBlocks(widgetType, widgetId, fetchParams, acceptLanguageHeader);
                },
                fetchParams,
                isMainWidget,
            ),
        );
    };
