import { get } from '@lumapps/constants';

const constants = get();

/**
 * Logic to have a common id between legacy and new app
 */
export const normalisePageId = (page: string) => {
    let pageId = page;

    if (constants.isLegacyContext) {
        const pageTokens = page.split('.');

        pageId = pageTokens[pageTokens.length - 1].replace('-get', '');
    }

    return pageId;
};
