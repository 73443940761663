/* eslint-disable import/no-mutable-exports */

import noop from 'lodash/noop';

let closeMobileSidebar = noop;

/**
 * Check if the screen is < 1024 (the breakpoint to display the mobile sidebar).
 *
 * @return {boolean} Whether or not the width is < 1024px.
 */
function isMobile() {
    return window.innerWidth < 1024;
}

/**
 * Blinds functions to angular `layout` service.
 *
 * @param {Layout} Layout The angular Layout service.
 */
function setLayout(Layout) {
    closeMobileSidebar = Layout.closeMobileSidebar;
}

/////////////////////////////

export { closeMobileSidebar, isMobile, setLayout };
