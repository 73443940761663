import React from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { Dialog, Toolbar, DialogProps } from '@lumapps/lumx/react';

import { useForm } from '../../hooks/useForm';
import { useFormDialog } from '../../hooks/useFormDialog';
import { FormProps } from '../Form';
import { Form } from '../Form/Form';
import { FormActions } from '../FormActions';

export interface FormDialogProps extends FormProps {
    /** custom classname */
    className?: string;
    /** heading for the dialog */
    heading: React.ReactNode;
    /** callback for closing the dialog */
    onClose?: DialogProps['onClose'];
    /** whether the dialog is open or not */
    isOpen: DialogProps['isOpen'];
    /** additional props for the dialog */
    dialogProps?: Partial<DialogProps>;
    /** Id set on the heading element, to link it to the form and dialog */
    headingId?: string;
    /** feature scope. needed for tracking and a11y purposes */
    scope: string;
}

const CLASSNAME = 'lumx-form-dialog';

/**
 * Component that displays a form inside a dialog, controlling and preventing submit if the
 * dialog is not valid.
 *
 * @family Forms
 * @param FormDialogProps
 * @returns FormDialog
 */
export const FormDialog: React.FC<FormDialogProps> = ({
    className,
    dialogProps,
    heading,
    children,
    onClose,
    onSubmit,
    scope,
    isOpen,
    formActionProps,
    form,
    status,
    headingId,
    ...props
}) => {
    const { defaultValues } = form || { defaultValues: {} };
    const { methods, onSubmit: onFormSubmit } = useForm({
        onSubmit,
        form,
    });

    const { onCloseDialog, onVisibilityChange } = useFormDialog({
        reset: methods.reset,
        onClose,
        defaultValues,
        values: form?.values,
    });

    return (
        <Dialog
            className={classnames(className, CLASSNAME)}
            {...dialogProps}
            dialogProps={{
                'aria-labelledby': headingId,
                ...dialogProps?.dialogProps,
            }}
            isOpen={isOpen}
            onClose={onCloseDialog}
            onVisibilityChange={(open) => {
                onVisibilityChange(open);

                if (dialogProps?.onVisibilityChange) {
                    dialogProps.onVisibilityChange(open);
                }
            }}
        >
            <header>
                <Toolbar label={heading} />
            </header>

            <Form
                scope={scope}
                {...props}
                status={status}
                methods={methods}
                onSubmit={onFormSubmit}
                useExternalActions
                formProps={{ id: scope, className: padding('horizontal', 'huge'), 'aria-labelledby': headingId }}
            >
                {children}
            </Form>

            <footer>
                <FormActions
                    onSubmit={onFormSubmit}
                    onCancel={props.onCancel || onClose}
                    scope={scope}
                    isFormDirty={methods.formState.isDirty}
                    {...formActionProps}
                    saveProps={{ form: scope, ...(formActionProps ? formActionProps.saveProps || {} : {}) }}
                    toolbarProps={{
                        ...(formActionProps?.toolbarProps || {}),
                        style: { paddingRight: '24px' },
                    }}
                    status={status}
                />
            </footer>
        </Dialog>
    );
};
