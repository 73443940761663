import React from 'react';

import { useClassnames } from '@lumapps/classnames';

import { useSideBarNavigationDataAttributes } from '../../../hooks/useSideBarNavigationDataAttributes';
import { SideNavigationHeader } from '../SideNavigationHeader';

import './index.scss';

export interface SideNavigationSectionProps {
    children?: React.ReactNode;
    /** The text that will be displayed for the navigation section */
    header: React.ReactNode;
    /** Indicates that this section will be loaded async */
    isAsyncSection?: boolean;
    /** Section id used for tracking */
    id: string;
    /** Custom? className */
    className?: string;
}

const CLASSNAME = 'side-navigation-section';
const SideNavigationSection: React.FC<SideNavigationSectionProps> = ({
    children,
    header,
    isAsyncSection = false,
    id,
    className,
}) => {
    const sectionRef = React.useRef(null);
    const { block, element } = useClassnames(CLASSNAME);
    const dataAttributes = useSideBarNavigationDataAttributes({ alternativeId: id, isSection: true });
    let shouldHeaderBeDisplayed = Boolean(children);

    if (isAsyncSection) {
        shouldHeaderBeDisplayed = false;
    }

    const [showHeader, setShowHeader] = React.useState(shouldHeaderBeDisplayed);
    const [isLoading, setIsLoading] = React.useState(isAsyncSection);
    // Store if we already checked children
    const [wasChecked, setWasChecked] = React.useState(false);

    /**
     * This callback will be executed once the sections is loaded, in order to let this component know that it should
     * recalculate and determine whether there are sections rendered or not. This will trigger the `useLayoutEffect`
     * below, and allow the desired effect.
     */
    const onFinishedLoading = () => {
        if (isLoading) {
            setIsLoading(false);
        }
    };

    const shouldHeaderBeVisible = React.useCallback((): boolean => {
        const { childNodes } = sectionRef.current as any;
        let totalChildNodes = 0;

        /**
         * We have two types of sections, the ones that can already be rendered with the information that we
         * have right now, and the ones that need to execute an API call to retrieve their information (isAsyncSection)
         *
         * For the ones that we already have the information available, we need to see if that we have more than 1 node.
         * If we only have one, it means that we have only rendered the header, and nothing else, so we need to hide it.
         * In order to do so, we retrieve the node that holds the list of rendered links (which will always be the last node in the component)
         * and retrieve the total amount of nodes.
         *
         * For the scenario where the section is async, we need to check whether we have anything rendered, since by default
         * the header is hidden when the section is async. If we render anything, we know that the user can access those sections,
         * so we show the header.
         */
        try {
            const listSection = childNodes.item(childNodes.length - 1);

            totalChildNodes = listSection?.childNodes.length;
        } catch (excp) {
            // eslint-disable-next-line no-console
            console.log(excp);
        }

        const shouldShowHeader = totalChildNodes > 0;

        return shouldShowHeader;
    }, [sectionRef]);

    /**
     * In order to hide the header if there are no nodes rendered, we need to use a useLayoutEffect
     * that executes after we have rendered this component, in order to determine if the components inside
     * this section were rendered or not.
     * Here we use a useLayoutEffect since we want to make this change sync with the rendering of the component,
     * and render synchronously, in order to avoid a flash when the header is available and then it isn't
     */
    React.useLayoutEffect(() => {
        if (sectionRef && !isLoading) {
            const shouldShowHeader = shouldHeaderBeVisible();

            setShowHeader(shouldShowHeader);

            /**
             * Since the sidebar is also present on the Angular JS for now, we need to do a second validation
             * since there could be the possibility that this effect is triggered but the children are still not
             * rendered, we need to double check once more. We just do a simple setTimeout to check that everything is in place.
             * If we should render it, we set the show header to true.
             */
            if (!shouldShowHeader) {
                setTimeout(() => {
                    const secondCheckShouldHeaderBeVisible = shouldHeaderBeVisible();
                    if (secondCheckShouldHeaderBeVisible) {
                        setShowHeader(secondCheckShouldHeaderBeVisible);
                    }
                    setWasChecked(true);
                }, 100);
            } else {
                setWasChecked(true);
            }
        }
    }, [sectionRef, setShowHeader, isLoading, isAsyncSection, shouldHeaderBeVisible]);

    // If we should not display the header and we have already checked children
    if (!showHeader && wasChecked) {
        return null;
    }

    return (
        <li className={block([className])} ref={sectionRef} {...dataAttributes}>
            {showHeader && <SideNavigationHeader className={element('header')}>{header}</SideNavigationHeader>}
            {React.Children.count(children) > 0 && (
                <ul className={element('sub-section')}>
                    {React.Children.map(children, (child: React.ReactNode) => {
                        if (React.isValidElement(child)) {
                            return <child.type {...child.props} {...(isAsyncSection ? { onFinishedLoading } : {})} />;
                        }

                        return null;
                    })}
                </ul>
            )}
        </li>
    );
};

SideNavigationSection.displayName = 'SideNavigationItem';

export { SideNavigationSection };
