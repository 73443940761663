import { isFeatureEnabled } from '@lumapps/features';
import { instanceSelector } from '@lumapps/instance/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';

const isGDPRBannerEnabled = isFeatureEnabled('gdpr-banner-widget');
const isPendoEnabled = isFeatureEnabled('pendo-analytics');

// We need to check if GTM or GA tags are defined
// These tags are only available in the store for legacy and react apps under the Instance selector
const isGAorGTMEnabled = createSelector(instanceSelector, (info) => {
    const instanceId = info?.id;

    return (
        Boolean(info?.googleAnalytics) ||
        Boolean(info?.googleTagManager) ||
        Boolean(info?.entities?.[instanceId]?.googleAnalytics) ||
        Boolean(info?.entities?.[instanceId]?.googleTagManager)
    );
});

const areAnalyticsFeaturesDisabledSelector = createSelector(
    [isPendoEnabled, isGAorGTMEnabled],
    (isPendoEnabled, isGAorGTMEnabled) => !isPendoEnabled && !isGAorGTMEnabled,
);

export { isGDPRBannerEnabled, isPendoEnabled, isGAorGTMEnabled, areAnalyticsFeaturesDisabledSelector };
