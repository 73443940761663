import { useModes } from '@lumapps/customizations/modes';

import React from 'react';
import { MemoryRouter, Context } from '@lumapps/router';

const RouterWrapper = ({ current, params, children }) => {
    useModes();

    return (
        <Context.Provider
            value={{
                currentView: current,
                params,
            }}
        >
            <MemoryRouter>{children}</MemoryRouter>
        </Context.Provider>
    );
};

export { RouterWrapper };
