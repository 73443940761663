import { Editor, Node, NodeEntry } from 'slate';

import { MatchFn } from './matchFn';
/**
 * Find parent at path.
 */
export function findParent<T extends Node>(editor: Editor, path?: number[], match?: MatchFn<T>): NodeEntry<T> | null {
    if (!path) {
        return null;
    }
    try {
        const entry = Editor.parent(editor, path);
        const [parent, parentPath] = entry;
        if (!match || match(parent)) {
            return entry as NodeEntry<T>;
        }
        return findParent(editor, parentPath, match);
    } catch (e) {
        return null;
    }
}
