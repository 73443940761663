import { createSelector } from '@lumapps/redux/reselect';

/* eslint-disable camelcase */

import get from 'lodash/get';
import memoize from 'lodash/memoize';

const shareableContentsEntitiesSelector = (state) => get(state, 'socialAdvocacy.shareableContents.entities', {});
const shareableContentsListSelector = (state) => get(state, 'socialAdvocacy.shareableContents.contentList', []);

/**
 * Retrieve the shareable content list.
 *
 * @param  {Object} state Redux state.
 * @return {Array}  The content list.
 */
const getShareableContentList = createSelector(
    [shareableContentsEntitiesSelector, shareableContentsListSelector],
    (entities, contentList) => contentList.map((contentId) => entities[contentId]),
);

const makeGetContentByContentCoreId = createSelector(shareableContentsEntitiesSelector, (entities) =>
    memoize(
        (contentInternalId) =>
            contentInternalId &&
            entities &&
            Object.values(entities).find(
                ({ shareableContent: { contentInternalId: shareableContentInternalId } }) =>
                    contentInternalId === shareableContentInternalId,
            ),
    ),
);

const shareableContentByContentIdSelector = createSelector(shareableContentsEntitiesSelector, (entities) =>
    memoize((contentId) => entities[contentId]),
);

export { getShareableContentList, makeGetContentByContentCoreId, shareableContentByContentIdSelector };
