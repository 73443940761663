import type { Wrex } from '@lumapps/wrex/types';

import { LINK } from '../constants';
import type { LinkElement } from '../types';

/** Create a link element */
export const createLink = (
    attributes: Wrex.ElementAttributes<LinkElement>,
    children: LinkElement['children'],
): LinkElement => {
    return { type: LINK, ...attributes, children };
};
