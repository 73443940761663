import { any, arrayOf, bool, number, oneOf, oneOfType, shape, string } from 'prop-types';

import map from 'lodash/map';
import join from 'lodash/join';

import * as uiTypes from '../ui/types';
import { BACKGROUND_SIZE_CHOICES, BACKGROUND_POSITIONS, FONT_WEIGHT_CHOICES } from './constants';

export const backgroundPosition = oneOf(map(BACKGROUND_POSITIONS, (position) => join(position, ' ')));

const shadowProperties = {
    shadowElevation: number,
    shadowOpacity: number,
};

export const shadowStyle = shape(shadowProperties);

const backgroundProperties = {
    backgroundColor: uiTypes.color,
    backgroundImage: shape({}),
    backgroundPosition: oneOf(map(BACKGROUND_POSITIONS, (position) => join(position, ' '))),
    backgroundSize: oneOf(map(BACKGROUND_SIZE_CHOICES, 'value')),
};

export const backgroundStyle = shape({
    ...backgroundProperties,
    ...shadowProperties,
    theme: uiTypes.theme,
});

export const fontStyle = shape({
    color: uiTypes.color,
    fontSize: number,
    fontWeight: oneOfType([oneOf(map(FONT_WEIGHT_CHOICES, 'value')), number]),
});

const borderProperties = {
    borderBottomColor: uiTypes.color,
    borderBottomLeftRadius: number,
    borderBottomRightRadius: number,
    borderBottomWidth: number,
    borderColor: uiTypes.color,
    borderLeftColor: uiTypes.color,
    borderLeftWidth: number,
    borderRadius: number,
    borderRightColor: uiTypes.color,
    borderRightWidth: number,
    borderTopColor: uiTypes.color,
    borderTopLeftRadius: number,
    borderTopRightRadius: number,
    borderTopWidth: number,
    borderWidth: number,
};

export const borderStyle = shape(borderProperties);

const marginProperties = {
    margin: number,
    marginBottom: number,
    marginLeft: number,
    marginRight: number,
    marginTop: number,
};

export const marginStyle = shape(marginProperties);

const paddingProperties = {
    padding: number,
    paddingBottom: oneOfType([number, string]),
    paddingLeft: oneOfType([number, string]),
    paddingRight: oneOfType([number, string]),
    paddingTop: oneOfType([number, string]),
};

export const paddingStyle = shape(paddingProperties);

export const spacingStyle = shape({
    ...marginProperties,
    ...paddingProperties,
});

const heightProperties = {
    fullHeight: bool,
    height: number,
};

export const heightStyle = shape(heightProperties);

const widthProperties = {
    fullWidth: bool,
};

export const widthStyle = shape(widthProperties);

export const areaType = oneOf(['header', 'footer']);

export const areaModes = oneOf(['block', 'flex']);

export const flexDirection = oneOf(['row', 'row-reverse', 'column', 'column-reverse']);

export const justifyContent = oneOf(['flex-start', 'center', 'flex-end']);

export const alignItems = oneOf(['flex-start', 'center', 'flex-end']);

export const areaStyle = shape({
    icon: shape({
        color: uiTypes.color,
        fontSize: number,
    }),
    label: shape({
        color: uiTypes.color,
        fontFamily: string,
        fontSize: number,
        fontWeight: number,
    }),
    main: shape({
        ...backgroundProperties,
        display: areaModes,
        justifyContent,
        ...shadowProperties,
    }),
    wrapper: shape({
        alignItems,
        ...borderProperties,
        flexDirection,
        ...heightProperties,
        justifyContent,
        ...marginProperties,
        ...paddingProperties,
        ...shadowProperties,
    }),
});

export const contentStyle = shape({
    ...backgroundProperties,
    ...borderProperties,
    ...heightProperties,
    ...marginProperties,
    ...paddingProperties,
    ...shadowProperties,
    theme: uiTypes.theme,
});

export const mainStyle = contentStyle;

export const rowStyle = shape({
    ...backgroundProperties,
    ...marginProperties,
    ...borderProperties,
    ...paddingProperties,
});

export const cellStyle = shape({
    components: shape({
        ...marginProperties,
        ...borderProperties,
        ...paddingProperties,
    }),
    main: shape({
        ...backgroundProperties,
        ...shadowProperties,
    }),
});

export const measureFields = arrayOf(
    shape({
        default: any,
        label: string,
        name: string,
    }),
);

export const state = oneOf(['default', 'hover']);

export const styles = shape({
    content: contentStyle,
    footer: areaStyle,
    header: areaStyle,
    main: mainStyle,
});
