import type { GetSelectedDateTimeZone } from '@lumapps/pickers/components/DateTimeZonePickerField/utils';
import type { Segment } from '@lumapps/segment/types';
import type { TranslatableObject, TranslateObject } from '@lumapps/translations';
import type { User } from '@lumapps/user/types';
import type { LinkPreviewElement } from '@lumapps/wrex-link-preview/types';
import type { Wrex } from '@lumapps/wrex/types';

/** ******************************************
 *********************************************
 **                                         **
 **                  ENUMS                  **
 **                                         **
 *********************************************
 ******************************************* */
/** They need to be before anything else because most types refer to several enums */

/** Types of status to be used in action buttons */
export enum ACTION_BUTTON_STATUS {
    DRAFT = 'draft',
    LIVE = 'live',
    LIVE_CONFIRMED = 'live-confirmed',
}

/** **********************************
 *             RESOURCE              *
 *********************************** */
/**
 * Types of resources that can be used in a broadcast or journey
 *
 * /!\ when updating this enum, you HAVE to update also NotificationTargetType in analytics/analytics-tracking package as well
 * since it's a copy of this one (in order to avoid circular dependencies)
 * */
export enum RESOURCE_TYPES {
    ARTICLE = 'article',
    COMMUNITY = 'community',
    CONTENT = 'content',
    EXTERNAL_LINK = 'external_link',
    EVENT = 'event',
    LEARNING_PATH = 'learning_path',
    MICRO_APP = 'micro_app',
    PLAY_VIDEO = 'play_video',
    PLAYLIST = 'play_video_playlist',
    POST = 'post',
    TRAINING_COURSE = 'training_course',
}

/** **********************************
 *              CHANNEL              *
 *********************************** */
/**
 * Types of channels that can be used in a broadcast or journey
 *
 * /!\ when updating this enum, you HAVE to update also NotificationChannelType in analytics/analytics-tracking package as well
 * since it's a copy of this one (in order to avoid circular dependencies)
 */
export enum CHANNEL_TYPES {
    EMAIL = 'email',
    MARKETPLACE_EXTENSION = 'marketplace_extension',
    MOBILE_PUSH = 'mobile_push',
}

/** **********************************
 *           NOTIFICATION            *
 *********************************** */
/** all the statuses a notification can have when retrieved from API */
export enum NOTIFICATION_API_STATUS {
    CLICKED = 'clicked',
    DELIVERED = 'delivered',
    FAILED = 'failed',
    NOT_SENT = 'not_sent',
    OPENED = 'opened',
    PENDING = 'pending',
    SCHEDULED = 'scheduled',
    SENT = 'sent',
}

/** all the statuses a notification can have in UI */
export enum NOTIFICATION_UI_STATUS {
    ACKNOWLEDGED = 'acknowledged',
    ARCHIVED = 'archived',
    NOT_DELIVERED = 'not_delivered',
    NOT_SENT = 'not_sent',
    TO_ACKNOWLEDGE = 'to_acknowledge',
    TO_OPEN = 'to_open',
    TO_VIEW = 'to_view',
    PAUSED = 'paused',
    PENDING = 'pending',
    SCHEDULED = 'scheduled',
    VIEWED = 'viewed',
}

/** if a notification could not been sent, API adds a reason why */
export enum NOTIFICATION_STATUS_REASON {
    // should never be returned by API
    ENROLLMENT_WAS_DELETED = 'enrollment_was_deleted',
    ITEM_CONFIGURATION_DEACTIVATED = 'item_configuration_deactivated',
    JOURNEY_ARCHIVED = 'journey_archived',
    JOURNEY_DEACTIVATED = 'journey_deactivated',
    SCHEDULED_IN_THE_PAST = 'scheduled_in_the_past',
    STAKEHOLDER_NOT_AVAILABLE = 'stakeholder_not_available',
}

/**
 * Type of a recipient in journey message
 */
export enum RECIPIENT_TYPE {
    PARTICIPANT = 'participant',
    STAKEHOLDER = 'stakeholder',
}

/** **********************************
 *            SCHEDULING             *
 *********************************** */
/**
 * Used as is for item-configurations
 * and to extend broadcast scheduling types as well
 */
export enum BASELINE_SCHEDULING_TYPE {
    ON_BASELINE_DATE = 'on_baseline_date',
    FROM_BASELINE_DATE = 'from_baseline_date',
}

/**
 * BROADCAST Scheduling options, whether the broadcast is linked to a campaign or not
 */
export enum BROADCAST_ONLY_SCHEDULING_TYPE {
    IMMEDIATELY = 'immediately',
    ABSOLUTE_DATE = 'absolute_date',
}

/** When schedule is related to a baseline date, available offset directions */
export enum SCHEDULE_OFFSET_DIRECTION {
    BEFORE = 'before',
    AFTER = 'after',
}

/** When schedule is related to a baseline date, available offset units */
export enum SCHEDULE_OFFSET_UNIT {
    DAYS = 'days',
    WEEKS = 'weeks',
}

/** Baseline select types for baseline date offset */
export enum BASELINE_SELECT_TYPES {
    UNIT = 'unit',
    DIRECTION = 'direction',
}

/** **********************************
 *              JOURNEY              *
 *********************************** */
/** Statuses that a journey can have. */
export enum JOURNEY_STATUS {
    LIVE = 'live',
    DEACTIVATED = 'deactivated',
    ARCHIVED = 'archived',
    DELETED = 'deleted',
}

/** **********************************
 *        ITEM CONFIGURATION         *
 *********************************** */
/** Statuses that an item configuration can have. */
export enum ITEM_CONFIGURATIONS_STATUS {
    DRAFT = 'draft',
    LIVE = 'live',
    DEACTIVATED = 'deactivated',
    ARCHIVED = 'archived',
    DELETED = 'deleted',
}

/** All item configuration types in API */
export enum ITEM_CONFIGURATION_API_TYPE {
    RESOURCE = 'resource',
    // TODO: to uncomment when meetings item type is available
    // MEETING = 'meeting',
}

/** All item configuration types in UI */
export enum ITEM_CONFIGURATION_UI_TYPE {
    MESSAGE = 'message',
    MEETING = 'meeting',
}

/** **********************************
 *             TIMELINE              *
 *********************************** */
/** Statuses that an item configuration can have. */
export enum TIMELINE_DIRECTION {
    NEXT = 'next',
    PREVIOUS = 'previous',
}

/** **********************************
 *           STAKEHOLDER             *
 *********************************** */
/** all avaliable source of stakeholders */
export enum STAKEHOLDER_SOURCE {
    USER_PROFILE_FIELD = 'user_profile_field',
}

/** ******************************************
 *********************************************
 **                                         **
 **            TYPES/INTERFACES             **
 **                                         **
 *********************************************
 ******************************************* */

/** **********************************
 *              COMMON               *
 *********************************** */
/** Used by several other sub-packages */

export interface BaseField {
    /** the form mode */
    mode: 'edit' | 'view';
    /** whether the form is saving */
    isSaving?: boolean;
}

/** Baseline date */
export interface BaselineDateType {
    id?: string;
    title: string;
    date?: string;
}

/** Common interface for item-configuration and broadcast */
export interface BaseForm {
    /** if channel is from marketplace, this is its id */
    channelExtensionId?: string;
    /** if channel is from marketplace, this is its name */
    channelName: TranslatableObject;
    /** whether it is an email, marketplace extension etc. */
    channelType: CHANNEL_TYPES;
    /** channel message */
    message: TranslatableObject;
    /** on behalf of who the item-conf/broadcast should be sent */
    senderName: TranslatableObject;
    /** subject of the email/title of the notification that will be sent */
    subject: TranslatableObject;
}

/** **********************************
 *             RESOURCE              *
 *********************************** */

/**
 * Base resource interface. This will be extended by each
 * type of resource type
 */
export interface BaseResource {
    type: RESOURCE_TYPES;
    title?: TranslatableObject;
    description?: TranslatableObject;
    slug?: TranslatableObject;
    /* The site data returned from the backend, if any. We don't need to send it back, only type and id are necessary */
    site?: {
        id: string;
        slug: string;
    };
}

/** Resource of type Article, which refers to a LumApps article. */
export interface ArticleResource extends BaseResource {
    type: RESOURCE_TYPES.ARTICLE;
    id: string;
    thumbnailUrl?: TranslatableObject;
}

/** Resource of type Community, which refers to a community. */
export interface CommunityResource extends BaseResource {
    type: RESOURCE_TYPES.COMMUNITY;
    id: string;
    /* The slug returned from the backend, if any. We don't need to send it back, only type and id are necessary */
    slug?: TranslatableObject;
    thumbnailUrl?: string;
}

/** Resource of type Content, which refers to a content. */
export interface ContentResource extends BaseResource {
    type: RESOURCE_TYPES.CONTENT;
    id: string;
    /* The slug returned from the backend, if any. We don't need to send it back, only type and id are necessary */
    slug?: TranslatableObject;
    thumbnailUrl?: string;
}

/** Resource of type ExternalLink, which refers to an external Link */
export interface ExternalLinkResource extends BaseResource {
    type: RESOURCE_TYPES.EXTERNAL_LINK;
    url: string;
    thumbnailUrl?: string;
}

/** Resource of type Playlist, which refers to a LumApps Play video playlist. */
export interface PlaylistResource extends BaseResource {
    type: RESOURCE_TYPES.PLAYLIST;
    id: string;
    thumbnailUrl?: string;
}

/** Resource of type Video, which refers to a LumApps Play video. */
export interface PlayVideoResource extends BaseResource {
    type: RESOURCE_TYPES.PLAY_VIDEO;
    id: string;
    thumbnailUrl?: string;
}

/** Resource of type Learning path */
export interface LearningPathResource extends BaseResource {
    type: RESOURCE_TYPES.LEARNING_PATH;
    id: string;
    thumbnailUrl?: string;
}

/** Resource of type Training course */
export interface TrainingCourseResource extends BaseResource {
    type: RESOURCE_TYPES.TRAINING_COURSE;
    id: string;
    thumbnailUrl?: string;
}

/** Resource of type MicroApp */
export interface MicroAppResource extends BaseResource {
    type: RESOURCE_TYPES.MICRO_APP;
    id: string;
    thumbnailUrl?: TranslatableObject;
}

/** All the possible Resources for a broadcast or a journey */
export type Resource =
    | ArticleResource
    | ContentResource
    | CommunityResource
    | ExternalLinkResource
    | PlaylistResource
    | PlayVideoResource
    | LearningPathResource
    | MicroAppResource
    | TrainingCourseResource;

export interface BaseResourceField extends BaseField {
    /** resource */
    resource: Resource | undefined;
}

/** Proptypes for components of all kinds of resources */
export interface BaseResourceProps extends BaseResourceField {
    /** callback when the type is selected */
    onResourceTypePicked: () => void;
    /** callback to be executed when the external link resource changes */
    onChange: (resource?: Resource) => void;
    /** callback when the remove button is clicked */
    onRemove: () => void;
    /** props to pass to the ResourceActions component */
    actionsProps?: {
        [key: string]: any;
    };
    scope: string;
    /** whether this type of resource is selected */
    isSelected: boolean;
}

/** **********************************
 *              SEGMENT              *
 *********************************** */

/** Segment included in the channel */
export interface IncludedSegment {
    id: string;
    title?: string;
    status?: string;
}

/** **********************************
 *              CHANNEL              *
 *********************************** */

/** Base channel interface. This will be extended by each type of channel type */
export interface BaseChannel {
    type?: CHANNEL_TYPES;
    subject?: TranslatableObject;
    message?: TranslatableObject;
    senderName?: TranslatableObject;
    includedSegments?: IncludedSegment[];
    recipientCount?: number;
}

/** Channel of type Email */
export interface EmailChannel extends BaseChannel {
    type: CHANNEL_TYPES.EMAIL;
}

/** Channel of type Push Notification */
export interface MobilePushChannel extends BaseChannel {
    type: CHANNEL_TYPES.MOBILE_PUSH;
}

/** Channel of type Marketplace extension */
export interface MarketplaceExtensionChannel extends BaseChannel {
    type: CHANNEL_TYPES.MARKETPLACE_EXTENSION;
    extensionId: string;
    extensionPreview?: {
        description?: TranslatableObject;
        name: TranslatableObject;
        thumbnailUrl?: TranslatableObject;
    };
}

/** All the possible Channels for a broadcast or a journey */
export type Channel = EmailChannel | MobilePushChannel | MarketplaceExtensionChannel;

export interface BaseChannelField extends BaseField {
    /** channel configuration */
    channelConfiguration: Partial<Channel> | undefined;
    /** callback to be executed when the channel changes */
    onChange: (channel: Partial<Channel>) => void;
    /** the language */
    language: string;
    /** scope */
    scope: string;
}

/** **********************************
 *            RICH TEXT              *
 *********************************** */

export interface RichTextRenderingSettings {
    backgroundColor: string /* hex only */;
    messageBackgroundColor: string /* hex only */;
}

export interface NotificationRichTextValue {
    children: Wrex.Nodes;
}

/** **********************************
 *           NOTIFICATION            *
 *********************************** */

/** Type of a preview notification */
export interface PreviewNotificationProps {
    channelConfiguration: Partial<Channel> | undefined;
    className?: string;
    isAcknowledgeEnabled?: boolean;
    journeyId?: string;
    resource: Resource | undefined;
    siteId: string | undefined;
    type: 'broadcast' | 'journey';
    richText?: TranslatableObject<NotificationRichTextValue>;
    richTextRenderingSettings?: RichTextRenderingSettings;
}

/** A type of item-configuration (the only one available so far) */
export interface Notification {
    id: string;
    scheduledAt: string;
    status: NOTIFICATION_API_STATUS;
    statusReason: NOTIFICATION_STATUS_REASON;
    createdAt: string;
    updatedAt: string;
}

/** **********************************
 *            SCHEDULING             *
 *********************************** */

/** Gathers all scheduling types in a single one */
export type SchedulingType = BROADCAST_ONLY_SCHEDULING_TYPE | BASELINE_SCHEDULING_TYPE;

/** When scheduling is 'from baseline date', we must provide the offset */
export interface DateOffset {
    value: number;
    unit: SCHEDULE_OFFSET_UNIT;
    direction: SCHEDULE_OFFSET_DIRECTION;
}

/** Callback parameters when changing data in DateTimeZonePicker  */
export interface DateTimePickerChangeProps {
    timestamp: string;
    data: GetSelectedDateTimeZone;
}

/** Common props for all types of schedule configurations */
export interface BaseScheduleConfiguration {
    type: SchedulingType;
    time: string; // "02:00:00+00:00"
    displayZoneInfo: string;
}

/** Schedule configuration ON baseline date */
export interface ScheduleConfigurationOnBaselineDate extends BaseScheduleConfiguration {
    type: BASELINE_SCHEDULING_TYPE.ON_BASELINE_DATE;
    baselineDateId: string;
}

/** Schedule configuration FROM baseline date */
export interface ScheduleConfigurationFromBaselineDate extends Omit<ScheduleConfigurationOnBaselineDate, 'type'> {
    type: BASELINE_SCHEDULING_TYPE.FROM_BASELINE_DATE;
    offsetInDays?: number;
    dateOffset: DateOffset;
}

/** All schedule configurations relative to a baseline date */
export type BaselineScheduleConfiguration = ScheduleConfigurationOnBaselineDate | ScheduleConfigurationFromBaselineDate;

/** Schedule configuration immediately (only for broadcast) */
export interface ScheduleConfigurationImmediately {
    type: BROADCAST_ONLY_SCHEDULING_TYPE.IMMEDIATELY;
}

/** Schedule configuration on a specific date (only for broadcast) */
export interface ScheduleConfigurationAbsoluteDate {
    type: BROADCAST_ONLY_SCHEDULING_TYPE.ABSOLUTE_DATE;
    date: string;
    displayZoneInfo: string;
}

/** All schedule configurations for broadcast only */
export type BroadcastOnlyScheduleConfiguration = ScheduleConfigurationImmediately | ScheduleConfigurationAbsoluteDate;

/** All possible schedule configurations ever */
export type ScheduleConfiguration = BaselineScheduleConfiguration | BroadcastOnlyScheduleConfiguration;

/** **********************************
 *           STAKEHOLDERS            *
 *********************************** */

/**
 * A stakeholder configuration associated with a Journey. A stakeholder configuration defines a user,
 * related to the journey participants, that is taking part in their journey. This relation is materialized
 * by a field in the participant's profile. For example, the manager of a given participant can be made part
 * of the journey by creating a stakeholder configuration on the "Manager" profile field definition.
 * The actual manager is resolved when the participant is enrolled.
 * A Journey can have zero or more (currently max 50) stakeholder configurations.
 */
export interface StakeholderConfiguration {
    /** Stakeholder id */
    id?: string;
    /** User profile field definition */
    profileFieldDefinitionReference: { id?: string; name?: TranslatableObject };
    /** The source of the stakeholder configuration */
    source: STAKEHOLDER_SOURCE.USER_PROFILE_FIELD;
    /** Stakeholder's title */
    title: TranslatableObject;
}

export interface FormStakeholder {
    apiId?: string;
    source: STAKEHOLDER_SOURCE.USER_PROFILE_FIELD;
    title: TranslateObject;
    userProfileField?: { id?: string; title?: TranslatableObject };
}

/**
 * Recipient interface. A recipient can be a participant or stakeholder type.
 */
export interface Recipient {
    /** id of a message recipient. It's not defined when creating a new recipient */
    id?: string;
    /** Types of the stakeholder recipient. This field is required
     * when creating/updating a recipient of stakeholder type */
    stakeholderConfigurationReference?: {
        /** stakeholder's id */
        id: StakeholderConfiguration['id'];
        /** stakeholder's title */
        title: StakeholderConfiguration['title'];
    };
    /** message recipient's type */
    type: RECIPIENT_TYPE;
}

/** **********************************
 *            RICH TEXT              *
 *********************************** */

export interface RichTextPreviewValue {
    /** Plain text extracted from the rich text & rendered
     * using the recipient's context (e.g. %firstname %lastname).
     * All rich text styling (such as color, bold, etc.) is lost.
     * Extracted elements (such as 2 paragraphs) are separated by line breaks.
     * Note that this can equal an empty string "" when the rich text does not
     * contain any paragraph elements (example: the rich text is only an image) */
    text: string;
    /** Link preview elements extracted from the rich text. The array may be empty */
    linkPreviews: LinkPreviewElement[];
}

/**
 * Elements extracted from rich text to display resources for enrollment
 * items that use rich text only, otherwise the property is omitted
 */
export interface RichTextPreview {
    /** rich text value containing text and resources */
    value: RichTextPreviewValue;
    /** current language */
    lang: string;
}

/** **********************************
 *      CONTINUOUS ENROLLMENT        *
 *********************************** */
// ⚠️ Is before Journey interface because ContinuousEnrollmentSettings is used in Journey
/**
 * The reference date that should be used as the baseline date
 * value for all enrollments created via continuous enrollment.
 */
export enum BASELINE_DATE_REFERENCE {
    /** the date at which the enrolled user's account was created */
    ACCOUNT_CREATION = 'account_creation',
    /** the date at which the enrollment was created. This is the default value */
    ENROLLMENT = 'enrollment',
}

/**
 * Settings used to manage continuous enrollments within a Journey.
 * Every 24 hours, all of the users that are part of at least one of the provided
 * segments and who have not yet been enrolled, will be enrolled into the Journey.
 */
export interface ContinuousEnrollmentSettings {
    /** The reference date that should be used as the baseline date
     * value for all enrollments created via continuous enrollment */
    baselineDateReference?: BASELINE_DATE_REFERENCE;
    /** List of segments enrolled */
    segments?: Segment[];
}

/** **********************************
 *              JOURNEY              *
 *********************************** */

export interface Journey {
    title: TranslatableObject;
    siteId: string;
    description: TranslatableObject;
    id: string;
    site?: {
        id: string;
        name: string;
    };
    createdAt: string;
    createdBy?: Partial<User>;
    updatedAt: string;
    updatedBy?: Partial<User>;
    status: JOURNEY_STATUS;
    itemConfigurationCountByStatus?: { [key in ITEM_CONFIGURATIONS_STATUS]?: string };
    baselineDates: BaselineDateType[];
    stakeholderConfigurations?: StakeholderConfiguration[];
    continuousEnrollmentSettings?: ContinuousEnrollmentSettings;
}

export interface FormJourney {
    /** the title of the journey */
    title: TranslateObject;
    /** the description of the journey, only visible in BO */
    description: TranslateObject;
    /** the name of the baseline date */
    baselineDateName: string;
    /** list of stakeholders */
    stakeholders: FormStakeholder[];
}

export interface JourneyListFilters {
    cursor?: string;
    title?: string;
    siteIds?: string[];
}

/** **********************************
 *        ITEM CONFIGURATION         *
 *********************************** */

/** this key is just an intermediate for ItemByNotificationStatus because it cannot be be created directly */
type DontUseMe = {
    [key in NOTIFICATION_API_STATUS]: number;
};

/**
 *  Number of items, ordered by notification statuses. Something like:
 * {
 *    clicked: 15,
 *    delivered: 5,
 *    failed: 0,
 *    notSent: 1,
 *    opened: 3,
 *    pending: 8,
 *    scheduled: 9,
 *    sent: 7,
 * }
 * since NOTIFICATION_API_STATUS has 'not_sent' but not 'notSent',
 * we use this "converter" interface to DRY (Don't Repeat Yourself)
 */
export interface ItemByNotificationStatus extends Omit<DontUseMe, 'not_sent'> {
    notSent: number;
}

/** Common props to all kinds of item configuration */
export interface BaseItemConfiguration {
    createdAt: string;
    createdBy?: Partial<User>;
    description?: string;
    id?: string;
    journey?: Partial<Journey>;
    journeyId?: string;
    scheduleConfiguration?: BaselineScheduleConfiguration;
    /** Attribute used for the translation pattern */
    sourceLanguage?: string;
    status: ITEM_CONFIGURATIONS_STATUS;
    title?: string;
    type: ITEM_CONFIGURATION_API_TYPE;
    updatedAt: string;
    updatedBy?: Partial<User>;
}

/** Item configuration of type resource (API)/message (UI) */
export interface ApiMessage extends BaseItemConfiguration {
    /** all possible channels for a message  */
    channelConfiguration?: Partial<Channel>;
    /** whether acknowledgment is enabled or not */
    isAcknowledgeEnabled?: boolean;
    /** all possible resources for a message */
    resource?: Resource;
    /** item configuration type */
    type: ITEM_CONFIGURATION_API_TYPE.RESOURCE;
    /** recipient of a journey message */
    recipient: Recipient;
    /** all settings for rich text (mostly background colors) */
    richTextRenderingSettings?: RichTextRenderingSettings;
    /** rich content if available */
    richText?: TranslatableObject<NotificationRichTextValue>;
}

/** All different item configurations  */

export type CompleteItemConfiguration = Required<ApiMessage>;

export type ItemConfiguration = ApiMessage;

/** **********************************
 *            ENROLLMENT             *
 *********************************** */

/** An item (notification, task etc.) of any enrollment */
export interface EnrollmentItem {
    /** user assigned to the enrollment item */
    assignedUser: User;
    /** when the item was created, with format YYYY-MM-DDTHH:mm:ssZ */
    createdAt: string;
    /** id of the current enrollment item */
    id: string;
    /** whether the assigned user marked the item as acknowledged */
    isAcknowledged: boolean;
    /** whether this enrollment item can be acknowledged or not */
    isAcknowledgeEnabled: boolean;
    /** item configuration relative to the current enrollment item */
    itemConfiguration: ItemConfiguration;
    /** a notification relative to an enrollment item. Each enrollmentItem is associated with at least one notification */
    mainNotification: Notification;
    /** absolute url of the resource. For enrollment items using rich text, this is always equal to an empty string */
    resourceUrl: string;
    /** elements extracted from rich text for items with rich text only */
    richTextPreview?: RichTextPreview;
    /** when the item was updated, with format YYYY-MM-DDTHH:mm:ssZ */
    updatedAt: string;
    /**
     * indicates when the related enrolled user has viewed the associated resource for the first time.
     * Only set if the view occurred after the scheduledAt property of the associated mainNotification.
     * */
    viewedResourceAt?: string;
}

/**  */
interface MyEnrollmentMessageWithRichText {
    /** data of item-conf 'channel' binded to the user */
    channelDetails: Pick<Channel, 'type' | 'senderName' | 'subject'>;
    /** id of the enrollment */
    enrollmentId: string;
    /** id of the current enrollment item */
    id: string;
    /** whether the assigned user marked the item as acknowledged */
    isAcknowledged: boolean;
    /** whether this enrollment item can be acknowledged or not */
    isAcknowledgeEnabled: boolean;
    /** id of the journey */
    journeyId: string;
    /** a notification relative to an enrollment item. Each enrollmentItem is associated with at least one notification */
    mainNotification: Notification;
    /** elements extracted from rich text for items with rich text only */
    richText?: TranslatableObject<NotificationRichTextValue>;
    /** elements extracted from rich text for items with rich text only */
    richTextRenderingSettings: RichTextRenderingSettings;
    /**
     * indicates when the related enrolled user has viewed the associated resource for the first time.
     * Only set if the view occurred after the scheduledAt property of the associated mainNotification.
     * */
    viewedResourceAt?: string;
}

export type MyEnrollmentItem = MyEnrollmentMessageWithRichText;
