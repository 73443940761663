import React from 'react';

import { classnames, color, useClassnames, margin, visuallyHidden } from '@lumapps/classnames';
import { ColorPalette, ColorVariant, FlexBox, Orientation, Size, Text, Typography } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import './index.scss';

export type Variant = 'squared' | 'rounded';

export interface ProgressionBarProps {
    /** Forwarded class name */
    className?: string;
    /** Width of progress bar. Default: 200px */
    maxWidth?: string;
    /** Number of percent of Done of progress bar */
    percents: number;
    /** Color of Done from DS */
    colorDone?: { colorPalette: ColorPalette; colorVariant: ColorVariant };
    /** Color of Remaining from DS */
    colorRemaining?: { colorPalette: ColorPalette; colorVariant: ColorVariant };
    /** Props for have or no label */
    label?: boolean;
    /** If we have label we give value of label */
    labelValue?: string;
    /** ProgressionBar border variant */
    variant?: Variant;
}

const CLASSNAME = 'lumx-progression-bar';

/**
 * Component for display progress user
 * @param ProgressionBarProps
 * @returns ProgressionBar
 * @family Progress
 */
export const ProgressionBar: React.FC<ProgressionBarProps> = ({
    className,
    percents,
    colorDone = { colorPalette: 'blue', colorVariant: 'D2' },
    colorRemaining = {
        colorPalette: 'blue',
        colorVariant: 'L5',
    },
    maxWidth = '200px',
    label = false,
    labelValue,
    variant = 'rounded',
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateAndReplace, pluralize } = useTranslate();

    /* 100 - this is % all progress */
    const allPercentsProgress = 100;
    /* Remaining percents of all progress */
    const remainingPercents = allPercentsProgress - percents;
    /* Array with all types of the tasks with their parameters */
    const data = [
        /* Done progress */
        {
            percents,
            colorPalette: colorDone.colorPalette,
            colorVariant: colorDone.colorVariant,
            description: 'done',
        },
        /* Remaining progress */
        {
            percents: remainingPercents,
            colorPalette: colorRemaining.colorPalette,
            colorVariant: colorRemaining.colorVariant,
            description: 'remaining',
        },
    ];

    /* we use visually-hidden classes for accessibility
     * we use classnames 'bar' & 'one-color-bar' to make border-radius on both sides in all cases
     *
     * */
    const bars = data.map((item) => (
        <FlexBox
            key={item.description}
            /* As this will be inside the final paragraph, set as span */
            as="span"
            className={classnames(color('background', item.colorPalette, item.colorVariant), element('bar'))}
            style={{ width: `${item.percents}%` }}
        >
            <Text as="span" className={visuallyHidden()}>
                {translateAndReplace(pluralize(item.description, item.percents), {
                    number: percents,
                })}
            </Text>
        </FlexBox>
    ));

    const completionLabel = () => {
        return (
            <FlexBox className={element('label', [margin('bottom', Size.regular)])}>
                <Text
                    as="p"
                    className={classnames({ [margin('right', Size.regular)]: labelValue })}
                    typography={Typography.overline}
                >
                    {labelValue}
                </Text>
                <Text as="p" typography={Typography.overline}>
                    {percents}%
                </Text>
            </FlexBox>
        );
    };

    return (
        <FlexBox className={block({ [`variant-${variant}`]: !!variant }, className)} style={{ maxWidth }}>
            {label ? completionLabel() : null}
            <FlexBox as="p" className={element('bars')} orientation={Orientation.horizontal}>
                {bars}
            </FlexBox>
        </FlexBox>
    );
};
