import { ASK_AI_ADMIN_ROOT_PATH } from '@lumapps/ask-ai/constants';
import { Route, AppId, createPageRoute } from '@lumapps/router';

/**
 * Ask Ai Concepts Route
 */
export const askAiConceptsAdminRoute: Route = createPageRoute({
    slug: `${ASK_AI_ADMIN_ROOT_PATH}/expressions`,
    id: 'ai-search-concepts',
    appId: AppId.backOffice,
});
