import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchNode } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from '../../../constants';
import { createListItem } from '../../../utils/createListItem';
import { createOrderedList } from '../../../utils/createOrderedList';
import { createUnorderedList } from '../../../utils/createUnorderedList';

type ElementTypes = typeof UNORDERED_LIST | typeof ORDERED_LIST | typeof LIST_ITEM;

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    'unordered-list': {
        test: matchNode({ tagName: 'ul' }),
        type: UNORDERED_LIST,
        transform: (_, children: any) => {
            return createUnorderedList(children);
        },
    },
    'ordered-list': {
        test: matchNode({ tagName: 'ol' }),
        type: ORDERED_LIST,
        transform: (_, children: any) => {
            return createOrderedList(children);
        },
    },
    'list-item': {
        test: matchNode({ tagName: 'li' }),
        type: LIST_ITEM,
        transform: (_, children: any) => {
            const cleanedChildren = children;
            // Handle empty parent case (<ul><li><br><li><ul><li>Child</li></ul></ul>)
            // We want to get rid of the newline, an empty paragraph is enought
            if (cleanedChildren.length > 0 && cleanedChildren[0].text === '\n') {
                cleanedChildren[0].text = '';
            }
            return createListItem([createParagraph(cleanedChildren)]);
        },
    },
};
