/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const adminContentListRoute: Route = {
    path: '/admin/content-list/:uid',
    legacyId: 'app.admin.content-list',
    appId: AppId.backOffice,
};

const newContentListRoute = {
    path: '/content/create/custom_list/:uid',
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

const editContentListRoute = {
    path: '/content/edit/:contentIdSelected',
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

const copyContentListRoute = {
    path: '/content/duplicate/:contentIdSelected',
    legacyId: 'app.front.content-duplicate',
    appId: AppId.legacy,
};

const previewContentListRoute = {
    path: '/:url',
    legacyId: 'app.front.content-preview',
    appId: AppId.legacy,
};

const adminContentList = (uid: string): Route => ({
    ...adminContentListRoute,
    params: { customContentType: uid, uid },
});

const newContentList = (uid: string): Route => ({
    ...newContentListRoute,
    params: { uid },
});

const editContentList = (contentIdSelected: string, instanceSlug?: string): Route => ({
    ...editContentListRoute,
    params: { contentIdSelected },
    instanceSlug,
});

const copyContentList = (contentIdSelected: string, customContentType: string): Route => ({
    ...copyContentListRoute,
    params: { contentIdSelected },
    query: { customContentType },
});

/** Generate a Route object in order to redirect to a content type content list page */
const previewContentList = (id: string, slug: string, metadataId?: string): Route => {
    return {
        ...previewContentListRoute,
        params: { url: slug },
        /**
         * Apply the metadata to the URL query params, in order to pre-filter the page on load.
         * We're using the legacy format for the filter, since it's supported in both legacy and NGI.
         * */
        query: metadataId ? { filters: `metadata_${metadataId}` } : undefined,
        /** Implement the mobilePageDescriptor user to send a redirect message to the webview application */
        mobilePageDescriptor: {
            type: 'content',
            id,
            /** Pass to the webview application the list of filters to apply when loading the page */
            filters: metadataId
                ? {
                      metadataIds: [metadataId],
                  }
                : undefined,
        },
    };
};

export {
    adminContentList,
    newContentList,
    editContentList,
    copyContentList,
    previewContentList,
    adminContentListRoute,
    newContentListRoute,
    editContentListRoute,
    copyContentListRoute,
    previewContentListRoute,
};
