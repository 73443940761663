import { PRIORITY, ServerListRequest, ServerListResponse } from '@lumapps/base-api';
import BaseApi from '@lumapps/base-api/api/BaseApi';
import { userProfileApi } from '@lumapps/user-profile/api';

import { AVAILABLE_USER_BOOKMARK_RESOURCES, MAX_USER_BOOKMARK_RESULT } from '../constants';
import { UserBookmarkTypes, UserBookmarksResourceTypes, BookmarkItem } from '../types';

const userBookmarksApiParams = {
    path: 'users',
    version: BaseApi.versions.v2,
};

const userBookmarksApi = new BaseApi(userBookmarksApiParams);

/** All available api paths for the bookmark service */
export const paths = {
    bookmarksList: (bookmarkType: UserBookmarkTypes, userId: string) => `${userId}/${bookmarkType}`,
    bookmarkItem: (bookmarkType: UserBookmarkTypes, userId: string, itemId: string) =>
        `${userId}/${bookmarkType}/${itemId}`,
};

export interface GetUserBookmarksBaseParams extends ServerListRequest {
    userId: string;
    lang?: string;
    priority?: PRIORITY;
    // Array of resource type to filter by.
    resourceTypeIn?: Array<UserBookmarksResourceTypes>;
    // Search value to filter by.
    query?: string;
    /** The Accept-Language header. use `getAcceptLanguageHeader` selector */
    acceptLanguageHeader: string;
}

export type GetBookmarkResponse = ServerListResponse<BookmarkItem>;

/**
 * Request the bookmarks of the given user Id
 */
const getUserBookmarks = (bookmarkType: UserBookmarkTypes, params: GetUserBookmarksBaseParams, fetchKey?: string) => {
    const { userId, priority, acceptLanguageHeader, ...otherParams } = params;
    return userBookmarksApi.getWithPriority<GetBookmarkResponse>(
        paths.bookmarksList(bookmarkType, userId),
        priority,
        {
            params: otherParams,
            headers: {
                'Accept-Language': acceptLanguageHeader,
            },
        },
        undefined,
        Boolean(fetchKey),
        fetchKey,
    );
};

/** Interface for when we want to list the bookmark data of a user */
export interface ListUserBookmarksParams extends GetUserBookmarksBaseParams {
    query?: string;
}

/** Interface for when we want to get the bookmark data of a specific resource */
export interface GetUserBookmarkParams extends GetUserBookmarksBaseParams {
    resourceId: string;
    resourceType: UserBookmarksResourceTypes;
}
const listUserBookmarks = (
    bookmarkType: UserBookmarkTypes,
    { resourceTypeIn = [], maxResults = MAX_USER_BOOKMARK_RESULT, ...otherParams }: ListUserBookmarksParams,
) =>
    getUserBookmarks(bookmarkType, {
        /**
         * Fetch all managed resource types by default to make sure we don't fetch
         * resource types we don't manage yet.
         * */
        resourceTypeIn: resourceTypeIn?.length > 0 ? resourceTypeIn : AVAILABLE_USER_BOOKMARK_RESOURCES,
        maxResults,
        ...otherParams,
    });

/** Get a single bookmark data */
const getUserBookmark = (bookmarkType: UserBookmarkTypes, params: GetUserBookmarkParams) =>
    getUserBookmarks(bookmarkType, params);

/** Cancel any request to the user bookmarks api */
const cancelGetUserBookmarks = (bookmarkType: UserBookmarkTypes, userId: string, fetchKey: string = bookmarkType) => {
    return userBookmarksApi.cancel(paths.bookmarksList(bookmarkType, userId), undefined, fetchKey);
};

interface ClearUserBookmarks {
    /** The user to remove the bookmarks from */
    userId: string;
}

/** Request to delete a particular bookmarks entry */
const clearUserBookmarks = (bookmarkType: UserBookmarkTypes, { userId }: ClearUserBookmarks) =>
    userBookmarksApi.delete(paths.bookmarksList(bookmarkType, userId));

export interface DeleteUserBookmarkItemParams {
    /** The user to remove the bookmark entry from */
    userId: string;
    /** The item id to remove */
    itemId: string;
}

/** Request to delete a particular bookmark entry */
const deleteBookmarkItem = (bookmarkType: UserBookmarkTypes, { userId, itemId }: DeleteUserBookmarkItemParams) =>
    userBookmarksApi.delete(paths.bookmarkItem(bookmarkType, userId, itemId));

/** Interface for creating a bookmark */
export interface CreateUserBookmarkParams {
    userId: string;
    resourceId: string;
    resourceType: UserBookmarksResourceTypes;
    description?: string;
}
/**
 * Create a saved item, given its resourceId and resourceType
 */
const createUserBookmark = (bookmarkType: UserBookmarkTypes, { userId, ...bodyPayload }: CreateUserBookmarkParams) => {
    return userProfileApi.post(paths.bookmarksList(bookmarkType, userId), bodyPayload);
};

export {
    userBookmarksApi,
    listUserBookmarks,
    getUserBookmark,
    createUserBookmark,
    cancelGetUserBookmarks,
    deleteBookmarkItem,
    clearUserBookmarks,
};
