(function IIFE() {
    'use strict';

    /////////////////////////////

    function InstancePickerController($scope, $timeout, Config, Instance, InstancePicker, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The page size of the selected instances list.
         *
         * @type {number}
         * @constant
         */
        var _PAGE_SIZE = 30;

        /**
         * Indicates the beginning of the slice of the selected instances list.
         *
         * @type {number}
         */
        var _sliceFrom = 0;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The list key of the selected instance.
         *
         * @type {string}
         * @readonly
         */
        vm.SELECTION_LIST_KEY = 'instance-picker-selection-';

        /**
         * The available values for view mode.
         *
         * @type {Object}
         * @readonly
         */
        vm.VIEW_MODES = {
            library: 'library',
            organize: 'organize',
        };

        /**
         * The various filter properties we can filter instances by.
         *
         * @type {Object}
         */
        vm.filter = {};

        /**
         * Indicates if the filter is currently displayed or not.
         *
         * @type {boolean}
         */
        vm.isFilterDisplayed = true;

        /**
         * Indicates if the instance picker is open or not.
         *
         * @type {boolean}
         */
        vm.isOpen = false;

        /**
         * Contains the list of instance ids to get.
         *
         * @type {Array}
         */
        vm.instanceIds = [];

        /**
         * The view mode the instance picker is currently in.
         * Possible values are:
         *     - 'organize': display the list of selected instances and allow to organize them;
         *     - 'library': display the list of instances according to the filters and allow the instance to select them;
         *
         * @type {string}
         */
        vm.viewMode = vm.VIEW_MODES.organize;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Config = Config;
        vm.Translation = Translation;
        vm.Instance = Instance;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Filter instances based on the filter fields value.
         */
        function _filterInstance() {
            var queryFields = {
                name: vm.filter.name,
                showHidden: false,
            };

            Instance.filterize(queryFields);
        }
        var _debouncedFilterInstance = _.debounce(_filterInstance, 500);

        /**
         * Initialize the list of instances.
         */
        function _initList() {
            vm.isOpen = true;
            vm.tmpInstanceList = [];

            if (angular.isDefinedAndFilled(vm.ngModel)) {
                vm.instanceIds = _.map(vm.ngModel, 'id');

                vm.getListNextPage();
            }
        }

        /**
         * Reset the filters.
         */
        function _resetFilters() {
            vm.filter = {
                name: undefined,
            };
        }

        /**
         * Reset the list of instances.
         */
        function _resetList() {
            vm.selectedInstance = undefined;
            vm.tmpInstanceList = undefined;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close the instance picker.
         */
        function closeInstancePicker() {
            InstancePicker.close(vm.pickerId, vm.tmpInstanceList);
        }

        /**
         * Close the instance filter.
         */
        function closeFilter() {
            vm.isFilterDisplayed = false;
        }

        /**
         * Get the instance thumbnail.
         *
         * @param  {Object} instance The instance to get the logo of.
         * @return {Object} The thumbnail based on the instance logo.
         */
        function getThumbnail(instance) {
            return Utils.getBackgroundImage((angular.isDefinedAndFilled(instance.logo)) ?
                instance.logo[0].url : '', 80, false);
        }

        /**
         * Get the next page of instances.
         */
        function getListNextPage() {
            Instance.filterize({
                ids: vm.instanceIds.slice(_sliceFrom, (angular.isNumber(_PAGE_SIZE)) ?
                    _sliceFrom + _PAGE_SIZE : undefined),
                maxResults: (angular.isNumber(_PAGE_SIZE)) ? _PAGE_SIZE : vm.instanceIds.length,
            }, function onInstanceFilterizeSuccess(response) {
                vm.tmpInstanceList = vm.tmpInstanceList.concat(response.items || response);
            }, undefined, vm.SELECTION_LIST_KEY);

            _sliceFrom += _PAGE_SIZE;
        }

        /**
         * Check if an instance is selected.
         *
         * @param  {Object}  instance The instance to check the current selection status of.
         * @return {boolean} Whether the instance is selected or not.
         */
        function isSelected(instance) {
            return vm.instanceIds.indexOf(instance.id) > -1;
        }

        /**
         * Open the instance filter.
         */
        function openFilter() {
            vm.isFilterDisplayed = true;
        }

        /**
         * Submit instance list.
         */
        function submitInstanceList() {
            vm.ngModel = angular.fastCopy(vm.tmpInstanceList);

            $timeout(closeInstancePicker);
        }

        /**
         * Switch the instance list to a specific view mode.
         *
         * @param {string} viewMode The view mode name to switch to.
         *                          Can be either 'organize' or 'library'.
         */
        function switchViewMode(viewMode) {
            if (angular.isDefinedAndFilled(vm.VIEW_MODES[viewMode])) {
                vm.viewMode = viewMode;
            }
        }

        /**
         * Toggle the details of a instance in the side panel.
         *
         * @param {Object} instance The instance to display the details of.
         */
        function toggleInstanceDetails(instance) {
            if (instance === vm.selectInstance) {
                vm.selectedInstance = undefined;
            } else {
                vm.selectedInstance = instance;
            }
        }

        /**
         * Toggle the selection status of a given instance.
         *
         * @param {Object} instance The instance to toggle the status of.
         */
        function toggleInstanceSelection(instance) {
            if (angular.isUndefinedOrEmpty(instance)) {
                return;
            }

            var instanceIndex = _.findIndex(vm.tmpInstanceList, {
                id: instance.id,
            });

            if (instanceIndex > -1) {
                vm.instanceIds.splice(vm.instanceIds.indexOf(instance.id), 1);
                vm.tmpInstanceList.splice(instanceIndex, 1);
            } else {
                vm.tmpInstanceList.push(instance);
                vm.instanceIds.push(instance.id);
            }
        }

        /////////////////////////////

        vm.closeFilter = closeFilter;
        vm.closeInstancePicker = closeInstancePicker;
        vm.getThumbnail = getThumbnail;
        vm.getListNextPage = getListNextPage;
        vm.isSelected = isSelected;
        vm.openFilter = openFilter;
        vm.submitInstanceList = submitInstanceList;
        vm.switchViewMode = switchViewMode;
        vm.toggleInstanceDetails = toggleInstanceDetails;
        vm.toggleInstanceSelection = toggleInstanceSelection;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch changes on the filter object to refresh the list of instances.
         */
        $scope.$watch(function filterWatcher() {
            return vm.filter;
        }, function filterWatch(newValue, oldValue) {
            if (newValue !== oldValue) {
                _debouncedFilterInstance();
            }
        }, true);

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the instance picker closes, reset the lists and filters.
         *
         * @param {Event}  evt      The instance picker close event.
         * @param {string} pickerId The id of the instance picker that closes.
         */
        $scope.$on('instance-picker__close-end', function onInstancePickerCloseEnd(evt, pickerId) {
            if (vm.pickerId === pickerId) {
                vm.isOpen = false;

                _resetFilters();
                _resetList();
            }
        });

        /**
         * When the instance picker opens, switch to the right mode and initialize the lists.
         *
         * @param {Event}  evt      The instance picker open event.
         * @param {string} pickerId The id of the instance picker that opens.
         */
        $scope.$on('instance-picker__open-start', function onInstancePickerOpenStart(evt, pickerId) {
            if (vm.pickerId === pickerId) {
                vm.isOpen = true;
                vm.ngModel = vm.ngModel || [];

                _initList();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.SELECTION_LIST_KEY += vm.pickerId;

            _resetFilters();

            _filterInstance();
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive that display an instance picker.
     *
     * @param {Object} ngModel  The model containig the selected instances.
     * @param {string} pickerId The identifier of the instance picker.
     */

    function InstancePickerDirective() {
        'ngInject';

        function link(scope, el) {
            el.appendTo('body');

            scope.$on('$destroy', function onScopeDestroy() {
                el.remove();
            });
        }

        return {
            bindToController: true,
            controller: InstancePickerController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                ngModel: '=',
                pickerId: '@lsPickerId',
            },
            templateUrl: '/client/common/modules/instance-picker/views/instance-picker.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsInstancePicker', InstancePickerDirective);
})();
