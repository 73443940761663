import React from 'react';

import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiChevronDown, mdiChevronUp } from '@lumapps/lumx/icons';
import { Icon, Placement, Popover, Size, Text, Theme, Tooltip } from '@lumapps/lumx/react';

import { ItemVariant, ViewMode } from '../../constants';
import { useTooltipLabel } from '../../hooks/useTooltipLabel';
import { CLASSNAME as ITEM_CLASSNAME } from '../NavigationItem';

import './index.scss';

export interface NavigationSectionProps {
    /** Classname that will be used for the nav wrapping element */
    className?: string;
    /** Items inside the section */
    children: React.ReactNode;
    /** the element used to create data attributes */
    element?: string;
    /** Icon (SVG path). */
    icon?: string;
    /** Label content. */
    label: string | React.ReactNode;
    /** Whether the section should be opened on component mount. Defaults to false */
    openOnMount?: boolean;
    /** The scope used to create data attributes */
    scope?: string;
    /** Theme that will be applied to the element, either Theme.dark or Theme.light */
    theme?: Theme;
    /** The variant used to determine whether to display text and/or icon. Default displays both */
    variant?: ItemVariant;
    /** The view mode used to display the nav, either 'horizontal' or 'vertical'. Defaults to 'vertical' */
    viewMode?: ViewMode;
}

const CLASSNAME = 'navigation-section';

const NavigationSection = React.forwardRef<HTMLLIElement, NavigationSectionProps>((props, ref) => {
    const {
        children,
        className,
        element,
        icon,
        label,
        openOnMount = false,
        theme,
        scope,
        variant,
        viewMode,
        ...forwardedProps
    } = props;

    const isHorizontalViewMode = viewMode === ViewMode.horizontal;
    const { block: cssBlock, element: cssElement } = useClassnames(CLASSNAME);
    const { element: itemCssElement } = useClassnames(ITEM_CLASSNAME);
    const [isOpen, setIsOpen] = React.useState(openOnMount);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const { labelRef, tooltipLabel } = useTooltipLabel({ label, variant });

    const { get } = useDataAttributes(scope ?? '');
    const dataAttributes = scope && element ? get({ element }) : {};

    const childrenArray = React.Children.toArray(children);

    if (!childrenArray.length) {
        return null;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        if (!openOnMount) {
            setIsOpen(false);
        }
    }, [openOnMount, viewMode]);

    return (
        <li
            className={cssBlock(
                {
                    horizontal: isHorizontalViewMode,
                },
                [className, ITEM_CLASSNAME],
            )}
            ref={ref}
            {...forwardedProps}
        >
            <Tooltip label={tooltipLabel} placement={Placement.TOP}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <button
                    aria-controls={`section-${label}`}
                    aria-expanded={isOpen}
                    className={itemCssElement('link', {
                        dark: theme === Theme.dark,
                    })}
                    ref={buttonRef}
                    {...dataAttributes}
                    onClick={(event) => {
                        setIsOpen(!isOpen);
                        event.stopPropagation();
                    }}
                    onKeyUp={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                            event.stopPropagation();
                        }
                    }}
                    type="button"
                >
                    {!!icon && variant !== ItemVariant.labelOnly && (
                        <Icon className={itemCssElement('icon')} icon={icon} size={Size.xs} theme={theme} />
                    )}

                    <Text
                        as="span"
                        className={itemCssElement(
                            'label',
                            variant === ItemVariant.iconOnly ? [visuallyHidden()] : undefined,
                        )}
                        ref={labelRef}
                    >
                        {label}
                    </Text>
                    <Icon
                        className={itemCssElement('icon', cssElement('chevron'))}
                        icon={isOpen ? mdiChevronUp : mdiChevronDown}
                    />
                </button>
            </Tooltip>
            {isOpen &&
                (isHorizontalViewMode ? (
                    <Popover
                        anchorRef={buttonRef}
                        isOpen={isOpen}
                        placement={Placement.BOTTOM_START}
                        usePortal={false}
                        closeOnClickAway
                        closeOnEscape
                        onClick={() => setIsOpen(false)}
                        onClose={() => setIsOpen(false)}
                        onKeyUp={(event: React.KeyboardEvent) => {
                            if (event.key === 'Enter') {
                                setIsOpen(false);
                            }
                        }}
                        zIndex={996}
                    >
                        <ul className={cssElement('drawer--popover')} id={`section-${label}`}>
                            {childrenArray.map((child) => React.cloneElement(child as React.ReactElement, { scope }))}
                        </ul>
                    </Popover>
                ) : (
                    <ul className={cssElement('drawer')} id={`section-${label}`}>
                        {childrenArray.map((child) =>
                            React.cloneElement(child as React.ReactElement, { theme, scope }),
                        )}
                    </ul>
                ))}
        </li>
    );
});

NavigationSection.displayName = 'NavigationSection';

export { NavigationSection };
