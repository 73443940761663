import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiFileDocumentBox } from '@lumapps/lumx/icons';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { adminContentType } from '../../routes';

export interface ContentTypesSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const ContentTypesSideNavigation = (props: Partial<ContentTypesSideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={adminContentType()}
            label={translateKey(GLOBAL.CONTENT_TYPES)}
            icon={mdiFileDocumentBox}
            {...restOfProps}
        />
    );
};

ContentTypesSideNavigation.displayName = 'SideNavigationItem';

export { ContentTypesSideNavigation };
