import partial from 'lodash/partial';

import { Editor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';
import { selectElementOnDelete } from '@lumapps/wrex/slate/utils/selectElementOnDelete';
import type { WrexEditor } from '@lumapps/wrex/types';

import { editableLinkPreviewWithOptions } from '../components/editableBlocks/EditableLinkPreview';
import type { LinkPreviewEditor, LinkPreviewEditorOptions } from '../types';
import { insertLinkPreview } from '../utils/insertLinkPreview';
import { isLinkPreview } from '../utils/isLinkPreview';
import { updateLinkPreview } from '../utils/updateLinkPreview';

const createPluginEditor = (): CreatePluginEditor<LinkPreviewEditor, WrexEditor<LinkPreviewEditor>> => (editor) => {
    const { isVoid, normalizeNode, deleteBackward, deleteForward } = editor;

    return {
        normalizeNode([node, path]) {
            const pathRef = Editor.pathRef(editor, path);
            normalizeLiftToRootNode(editor, [node, path], isLinkPreview);
            const changedPath = pathRef.unref();
            if (changedPath) {
                normalizeNode([node, changedPath]);
            }
        },

        isVoid: (element) => isLinkPreview(element) || isVoid(element),

        deleteBackward(unit) {
            if (unit === 'character' && selectElementOnDelete(editor, false, isLinkPreview)) {
                return;
            }
            deleteBackward(unit);
        },

        deleteForward(unit) {
            if (unit === 'character' && selectElementOnDelete(editor, true, isLinkPreview)) {
                return;
            }
            deleteForward(unit);
        },

        insertLinkPreview: partial(insertLinkPreview, editor),

        updateLinkPreview: partial(updateLinkPreview, editor),
    };
};

export const withLinkPreview = (options: LinkPreviewEditorOptions = {}) =>
    createPlugin({
        createPluginEditor: createPluginEditor(),
        elements: [editableLinkPreviewWithOptions(options)],
    });
