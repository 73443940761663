import groupBy from 'lodash/groupBy';

import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';

import { QUICK_SEARCH } from '../keys';

const SECTION_ORDERS = [
    SearchSuggestionType.HISTORY,
    SearchSuggestionType.SUGGESTION,
    SearchSuggestionType.RECOMMENDATION,
] as const;

export const SECTION_TITLES = {
    [SearchSuggestionType.HISTORY]: QUICK_SEARCH.HISTORY_SUGGEST,
    [SearchSuggestionType.SUGGESTION]: QUICK_SEARCH.OTHER_SUGGEST,
    [SearchSuggestionType.RECOMMENDATION]: QUICK_SEARCH.RECOMMENDATIONS_SUGGEST,
} as const;

type SuggestionGroup = {
    type: (typeof SECTION_ORDERS)[number];
    translationKey: string;
    suggestions: Array<SearchSuggestion>;
};

/**
 * Group the suggestions in sections to be displayed in the search box
 */
export const groupSuggestions = (suggestions: Array<SearchSuggestion>, areSuggestionLoading: boolean) => {
    const groupedByType = groupBy(suggestions, 'type');

    return SECTION_ORDERS.reduce<Array<SuggestionGroup>>((acc, suggestionType) => {
        let currentTypeSuggestions = groupedByType[suggestionType] || [];

        const interactionSuggestions = groupedByType[SearchSuggestionType.INTERACTION] || [];
        /** History and Interaction are grouped together in the "History" section */
        if (suggestionType === SearchSuggestionType.HISTORY && interactionSuggestions) {
            currentTypeSuggestions = [...currentTypeSuggestions, ...interactionSuggestions];
        }

        /**
         * Don't return sections without any suggestions
         * Except for Suggestions that needs to be displayed when loading. */
        if (
            currentTypeSuggestions.length > 0 ||
            (areSuggestionLoading && suggestionType === SearchSuggestionType.SUGGESTION)
        ) {
            const searchSuggestion = {
                type: suggestionType,
                translationKey: SECTION_TITLES[suggestionType],
                suggestions:
                    // Hide recommendations during loading
                    areSuggestionLoading && suggestionType === SearchSuggestionType.RECOMMENDATION
                        ? []
                        : currentTypeSuggestions,
            };
            return [...acc, searchSuggestion];
        }
        return acc;
    }, []);
};
