export enum SOCIAL_NETWORKS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19317253
     */
    SOCIAL_NETWORK = 'ADMIN.NAV.SOCIAL_ADVOCACY.SOCIAL.NETWORK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27149307
     */
    SOCIAL_PROFILE_TITLE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PROFILE.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27149308
     */
    SOCIAL_PROFILE_DESCRIPTION = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PROFILE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264249
     */
    SOCIAL_PLATFORM_ACTIVATED = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_ACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264251
     */
    SOCIAL_PLATFORM_NOT_ACTIVATED = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_NOT_ACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264255
     */
    SOCIAL_PLATFORM_ACTIVATE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_ACTIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264272
     */
    SOCIAL_PLATFORM_DEACTIVATE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_DEACTIVATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264712
     */
    SOCIAL_PLATFORM_NOT_CONNECTED = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_NOT_CONNECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264713
     */
    SOCIAL_PLATFORM_CONNECT = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_CONNECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27264714
     */
    SOCIAL_PLATFORM_DISCONNECT = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM_DISCONNECT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28121462
     */
    DISCONNECT_CONFIRM_TITLE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PROFILE.DISCONNECT.CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28121465
     */
    DISCONNECT_CONFIRM_DESCRIPTION = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PROFILE.DISCONNECT.CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28121466
     */
    DISCONNECT_NOTIFICATION = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PROFILE.DISCONNECT.NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28211905
     */
    NO_ACTIVE_NETWORK = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PROFILE.NO_ACTIVE_NETWORK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419834
     */
    USE_CUSTOM = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.USE_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419837
     */
    USE_DEFAULT = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.USE_DEFAULT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419858
     */
    ACTIVATION_SUCCESS = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.ACTIVATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419860
     */
    DEACTIVATION_SUCCESS = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.DEACTIVATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419912
     */
    DEACTIVATE_CONFIRM_TITLE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.DEACTIVATE_CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419914
     */
    DEACTIVATE_CONFIRM_DESCRIPTION = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.DEACTIVATE_CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419918
     */
    DEACTIVATE_LAST_CONFIRM_TITLE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.DEACTIVATE_LAST_CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419920
     */
    DEACTIVATE_LAST_CONFIRM_DESCRIPTION = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.DEACTIVATE_LAST_CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419922
     */
    SWITCH_APP_CONFIRM_TITLE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.SWITCH_APP_CONFIRM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30419925
     */
    SWITCH_APP_CONFIRM_DESCRIPTION = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.SWITCH_APP_CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30435008
     */
    CUSTOM_APP_TITLE = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.CUSTOM_APP_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30435538
     */
    CUSTOM_APP_HELPER = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.CUSTOM_APP_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30435542
     */
    CLIENT_ID_PLACEHOLDER = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.CLIENT_ID_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30435544
     */
    CLIENT_SECRET_PLACEHOLDER = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.CLIENT_SECRET_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30435546
     */
    SUBMIT_CUSTOM_APP = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_PLATFORM.SUBMIT_CUSTOM_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46886146
     */
    NOT_ACTIVATED = 'FRONT.SOCIAL_ADVOCACY.SOCIAL_NETWORKS.NO_ACTIVATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46886162
     */
    EMPTY_HELP = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.SOCIAL_NETWORKS.EMPTY_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=341606456
     */
    SHARE_CONTENT_ON_PLATFORM = 'SOCIAL_ADVOCACY.SHARE_CONTENT_ON_PLATFORM',
}
