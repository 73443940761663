import React from 'react';

import { isCommunitiesFeatureEnabled, selectedCommunityIdSelector } from '@lumapps/communities/ducks/selectors';
import { Community as CommunityType } from '@lumapps/communities/types';
import { CommunityPickerDialog } from '@lumapps/community-pickers/components/CommunityPickerDialog';
import { useSearchForPostingPicker } from '@lumapps/community-pickers/hooks/useSearchForPostingPicker';
import { EventNotificationDialog } from '@lumapps/events/components/EventNotificationDialog';
import { isEventNotificationDialogOpenSelector } from '@lumapps/events/ducks/selectors';
import { useEventDialog } from '@lumapps/events/hooks/useEventDialog';
import { PostDialog } from '@lumapps/posts/components/PostDialog';
import { editedPostSelector, isQuickPostDisabled } from '@lumapps/posts/ducks/selectors';
import { usePostDialog } from '@lumapps/posts/hooks/usePostDialog';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { GLOBAL } from '@lumapps/translations/keys';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

/** Hook grouping all community contributions that can be triggered in the contribution menu. */
export const useCommunityContributionMenu = ({ triggerElement }: { triggerElement: React.RefObject<any> }) => {
    const areCommunitiesEnabled = useSelector(isCommunitiesFeatureEnabled);
    const isQPostDisabled = useSelector(isQuickPostDisabled);
    const isCreateNewPostEnabled = areCommunitiesEnabled && !isQPostDisabled;
    const editedPost = useSelector(editedPostSelector);
    const selectedCommunityId = useSelector(selectedCommunityIdSelector);

    const { openEventDialog } = useEventDialog();
    const {
        isOpen: isPostDialogOpen,
        createPost: openPostDialog,
        closePostDialog,
    } = usePostDialog({ openEventDialog });
    const isEventNotificationDialogOpen = useSelector(isEventNotificationDialogOpenSelector);

    const { translateKey } = useTranslate();

    const [isCommunityPickerOpen, toggleCommunityPicker, closeCommunityPicker, openCommunityPicker] =
        useBooleanState(false);
    const handleCommunityConfirmed = React.useCallback(
        (communities: CommunityType[]) => {
            const { uid, renderingType } = communities[0] as CommunityType;
            openPostDialog({ containerId: uid, renderingType });

            closeCommunityPicker();
        },
        [closeCommunityPicker, openPostDialog],
    );

    const communityContribDialogs = (
        <>
            <CommunityPickerDialog
                communityPickerHook={useSearchForPostingPicker}
                onConfirm={handleCommunityConfirmed}
                onCancel={toggleCommunityPicker}
                isOpen={isCommunityPickerOpen}
                parentElement={triggerElement}
                confirmButtonProps={{ children: translateKey(GLOBAL.NEXT) }}
            />
            <PostDialog
                isOpen={isPostDialogOpen}
                communityId={selectedCommunityId}
                post={editedPost}
                triggerElement={triggerElement}
                onClose={closePostDialog}
            />

            <EventNotificationDialog isOpen={isEventNotificationDialogOpen} triggerElement={triggerElement} />
        </>
    );

    return { communityContribDialogs, onCreatePost: isCreateNewPostEnabled ? openCommunityPicker : undefined };
};
