import { useGetUserContent } from '@lumapps/content-usercontent/hooks/useGetUserContent';

/** Get the deletion status of a widget help */
export function useGetDeleted(
    enabled: boolean,
    { legacyWidgetId, lastUuidEdit }: { legacyWidgetId?: string; lastUuidEdit?: string },
) {
    // Fetch user content metadata
    const { data, isLoading } = useGetUserContent(enabled);

    // Check that the widget has been deleted by the user
    const wasDeleted = data?.values?.widgets?.find((userWidgetParams) => {
        const { uuid, active, params } = userWidgetParams;
        const isCurrentWidget = uuid === legacyWidgetId;
        const isDeleted = !active;
        const isSameEditVersion = params?.lastUuidEdit === lastUuidEdit;
        return isCurrentWidget && isDeleted && isSameEditVersion;
    });

    return { wasDeleted, isLoading: enabled && isLoading } as const;
}
