function WidgetPlaylistSettingsController(Widget) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const widgetPlaylistSettings = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The current widget we get from `Widget` service.
     *
     * @type {Object}
     */
    // eslint-disable-next-line no-underscore-dangle
    const _currentWidget = Widget.getCurrent();

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    widgetPlaylistSettings.Widget = Widget;

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Set the newly updated properties to the current widget.
     *
     * @param {Object} properties The updated properties.
     */
    function onChange(updatedProperties) {
        _currentWidget.properties = { ..._currentWidget.properties, ...updatedProperties };
    }

    // ///////////////////////////

    widgetPlaylistSettings.onChange = onChange;

    // ///////////////////////////
}

window.angular.module('Controllers').controller('WidgetPlaylistSettingsController', WidgetPlaylistSettingsController);
