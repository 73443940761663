import BaseApi, { BaseApiPromise, PRIORITY, ServerListRequest, ServerListResponse } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { splitArray } from '@lumapps/utils/array/splitArray';
import { OneOrMany } from '@lumapps/utils/types/OneOrMany';

import { Group, GroupType } from '../types';

// Legacy endpoint to manage LumApps Feeds
export const groupsApi = new BaseApi({ path: 'feed' });
export const groupsTypesApi = new BaseApi({ path: 'feedtype' });

export const groupMembershipApi = new BaseApi({
    version: BaseApi.versions.v2,
    path: 'groups',
});
export const deleteGroup = (uid: string) => {
    return groupsApi.delete('/delete', { params: { uid } });
};
export const deleteCategory = (uid?: string) => {
    return groupsTypesApi.delete('/delete', { params: { uid } });
};

export interface ListGroupParams {
    action: string;
    customerId: string;
    maxResults: number;
    more?: boolean;
    type: string;
    instance?: string;
    cursor?: string | null;
}

export const apiSaveCategory = (form: Partial<GroupType>) => {
    return groupsTypesApi.post('/save', form);
};
export const listGroup = (item: GroupType, params: ListGroupParams) => {
    return groupsApi.get('/list', { params });
};

export const fetchGroup = (uid: string) => {
    return groupsApi.get('/get', { params: { uid } });
};

export const saveGroup = (form: Partial<Group>) => {
    return groupsApi.post('/save', form);
};

export interface ListTypeParams {
    customer: string;
    maxResults?: number;
    herited?: boolean;
    instance?: string;
}

export const listTypes = (params: ListTypeParams) => {
    return groupsTypesApi.get('/list', { params });
};

export const saveUsersGroup = (uid: string, addedUsers: string[] = [], removedUsers: string[] = []) => {
    const params = {
        feed: uid,
        addedUsers,
        removedUsers,
    };
    return groupsApi.post('/subscribers/save', params);
};

export const synchronizeUsers = (uid: string) => {
    return groupMembershipApi.post(
        `/${uid}/group-membership-provisioning-tasks`,
        {},
        { params: { fromScratch: true } },
    );
};

/**
 * Feed API.
 * https://api.lumapps.com/docs/feed
 */
export const feedApi = new BaseApi({ path: 'feed' });

const RESTRICT_TO_FEED_LIMIT = 100;

export interface ListFeedParams extends ServerListRequest {
    exceptFeeds?: string[];
    restrictToFeeds?: string[];
    herited?: boolean;
    instance?: string;
    customer?: string;
    hasGroup?: boolean;
    type?: string;
    // default = en
    lang?: string;
    // whether segments created from groups should be excluded or not. Defaults to true
    excludeSegments?: boolean;
    provisioningMode?: 'MANUAL' | 'PROVIDER' | 'DYNAMIC_BY_USER';
}

export const listFeed = (params: ListFeedParams, listKey?: string) => {
    /**
     * If the total queried `restrictToFeeds` is more than RESTRICT_TO_FEED_LIMIT, the backend will return a 400
     * since it has a fixed limit on the backend. We fetch these items in batchs in order to avoid that error,
     * and return a single promise that will resolve when all those promises are finished.
     */
    if (params.restrictToFeeds && params.restrictToFeeds.length > 0) {
        if (params.restrictToFeeds.length > RESTRICT_TO_FEED_LIMIT) {
            const restrictToFeedsList = splitArray(params.restrictToFeeds, RESTRICT_TO_FEED_LIMIT);
            const promises: BaseApiPromise<ServerListResponse<Group>>[] = restrictToFeedsList.map((list: string[]) => {
                return listFeed(
                    {
                        ...params,
                        restrictToFeeds: list,
                    },
                    listKey,
                );
            });

            return Promise.all(promises).then((responses) => {
                const items: Group[] = [];

                responses.forEach((response) => {
                    const { data } = response;

                    items.push(...data.items);
                });

                /**
                 * we use all metadata and headers of the first request, and return
                 * the total items from all responses. Not the best, we are trying to find a fix
                 * on the backend side for this.
                 */
                return {
                    ...responses[0],
                    data: {
                        items,
                        cursor: '',
                        more: false,
                    },
                };
            });
        }

        /**
         * If `restrictToFeeds` is less than the limit, we pass in `maxResults` as the same as
         * the total `restrictToFeeds` to have them all.
         */
        Object.assign(params, { maxResults: params.restrictToFeeds.length.toString() });
    }

    return feedApi.getCacheFirst<ServerListResponse<Group>>(
        'list',
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        { params },
        true,
        true,
        listKey,
    );
};

export const cancelListFeed = (listKey: string) => feedApi.cancel('list', undefined, listKey);
export interface GetMultiFeedParams {
    uid: OneOrMany<string>;
    fields?: string;
}

export const getMultiFeed = (data: GetMultiFeedParams) => feedApi.post<ServerListResponse<Group>>('getMulti', data);

export interface GetUserSubscriptionsParams {
    userId: string;
}

export const getUserSubscriptions = (params: GetUserSubscriptionsParams) =>
    feedApi.get('/user/subscription/list', { params });
