/**
 * UploaderAction service.
 *
 * @param {Object} $timeout Angular $timeout service.
 * @constructor
 */
function UploaderActionService($timeout) {
    'ngInject';

    const service = {};

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Open the file picker dialog.
     *
     * @param {string} uuid The unique identifier of the element containing the file input.
     */
    function openSelection(uuid) {
        if (angular.isUndefinedOrEmpty(uuid)) {
            return;
        }

        $timeout(() => {
            const el = angular.element(`ls-uploader[ls-uuid='${uuid}']`);

            el.find('.uploader__file-input')
                .on('click', (evt) => {
                    // Avoid infinite loop on featured image widget in basic mode.
                    evt.stopPropagation();
                })
                .trigger('click');
        });
    }

    function clearInput(uuid) {
        if (angular.isUndefinedOrEmpty(uuid)) {
            return;
        }

        $timeout(() => {
            const el = angular.element(`ls-uploader[ls-uuid='${uuid}']`);

            el.find('.uploader__file-input').val('');
        });
    }

    /////////////////////////////

    service.openSelection = openSelection;
    service.clear = clearInput;

    /////////////////////////////

    return service;
}

angular.module('Services').service('UploaderAction', UploaderActionService);

export { UploaderActionService };
