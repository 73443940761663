import get from 'lodash/get';
import loFind from 'lodash/find';
import map from 'lodash/map';
import max from 'lodash/max';

/**
 * Get the maximum z-index value of a set of nodes.
 *
 * @param  {string} selector Get the max `z-index` attributes for a nodelist matching
 *                           the given selector.
 * @return {number} The maximum `z-index` value for the given nodes.
 */
const getMaxZIndex = (selector) => {
    const nodeList = document.querySelectorAll(selector);
    const zIndexList = map(nodeList, (node) => get(node, ['style', 'zIndex']));

    return parseInt(max(zIndexList), 10) || 0;
};

/**
 * Format a label given multiple values.
 *
 * We want to display the 1st selected item from the choices list + a counter if there are more than 1 values.
 * I.e. : `My First +3` would mean there are 4 selected choices.
 *
 * @param  {string} [fallback]     The value to return by default or if there is no selection.
 * @param  {Array}  list           The list of objects to choose the values from.
 * @param  {Array}  selectedValues The list of string values selected by the user.
 * @return {Object} `text` property is the non-translated text to display as the selected value.
 *                  `counter` property is for multi values only and indicates how many more values are selected.
 */
const formatMultiValueSelectionLabel = ({ fallback = '', list, selectedValues = [] }) => {
    // Missing params or nothing selected => display a default value.
    if (!selectedValues || !list || !selectedValues[0]) {
        return { text: fallback };
    }

    const firstChoice = loFind(list, {
        value: selectedValues[0],
    });
    const firstChoiceLabel = get(firstChoice, 'label');

    if (selectedValues.length === 1) {
        // Only one selected item, just display the label.
        return { text: firstChoiceLabel };
    }

    // More than 1 selected value add a counter after label.
    return {
        counter: selectedValues.length - 1,
        text: firstChoiceLabel,
    };
};

/**
 * Find the current value to display based on the selection type (single or multi).
 *
 * If single, grab the label matching the current value in the provided choices.
 * If multi, build a string based on the selected values (cf. formatMultiValueSelectionLabel()).
 *
 * @param  {Array}         choices       A list of choices objects.
 * @param  {boolean}       isMulti       Indicates if the label should display a counter after the first selected value.
 * @param  {string}        [placeholder] The translated label to display when no value is selected.
 * @param  {string}        [suffix]      Some text to display after the current value.
 * @param  {string|number} value         One or more selected values from choices.
 * @return {Object}        `text` property is the non-translated text to display as the selected value.
 *                         `counter` property is for multi values only and indicates how many more values are selected.
 */
const getLabel = ({ choices = [], isMulti, placeholder = '', suffix, value }) => {
    let selectedValue = value;

    if (value === undefined || value === null) {
        selectedValue = placeholder;
    }

    if (isMulti) {
        return formatMultiValueSelectionLabel({
            fallback: placeholder,
            list: choices,
            selectedValues: value,
        });
    }

    const currentChoice = loFind(choices, {
        value: selectedValue,
    });
    const hasSuffix = value && suffix;

    return {
        text: get(currentChoice, 'label', `${selectedValue}${hasSuffix ? suffix : ''}`),
    };
};

export { formatMultiValueSelectionLabel, getLabel, getMaxZIndex };
