import React, { useRef } from 'react';

import take from 'lodash/take';

import { useClassnames } from '@lumapps/classnames';
import { get as getConstants } from '@lumapps/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiDotsVertical } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Size, FlexBox, Orientation } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { UserListDialog } from '@lumapps/user/components/UserListDialog';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { WIDGET_SIZE_BREAKPOINTS } from '../../../constants';
import { BlockComponent, BlockUserList as BlockUserListProps } from '../../../types/blocks';
import { getHeaderLabel } from '../../WidgetHeader/getHeaderLabel';
import { BlockUser } from '../BlockUser';

import './index.scss';

const CLASSNAME = 'block-user-list';

const constants = getConstants();

/**
 * Change configuration for each responsive breakpoints.
 */
const RESPONSIVE_CONFIG: Record<keyof typeof WIDGET_SIZE_BREAKPOINTS, { nbItem?: number; orientation: Orientation }> = {
    l: { nbItem: 4, orientation: 'horizontal' },
    m: { nbItem: 2, orientation: 'horizontal' },
    s: { orientation: 'vertical' },
    xs: { orientation: 'vertical' },
};

export const BlockUserList: BlockComponent<BlockUserListProps> = ({ items, widget }) => {
    const { translateKey } = useTranslate();
    const { ref, currentBreakpoint } = useDimensions({ breakpoints: WIDGET_SIZE_BREAKPOINTS });
    const { get } = useDataAttributes(CLASSNAME);
    const { element, block } = useClassnames(CLASSNAME);

    // Change number of items to display in responsive:
    const config = RESPONSIVE_CONFIG[currentBreakpoint];
    const itemsToDisplay = config?.nbItem ? take(items, config.nbItem) : items;

    // Handle see more:
    const hasMore = itemsToDisplay.length < items.length;
    const seeMoreButtonRef = useRef(null);
    const [isSeeMoreOpen, toggleSeeMore] = useBooleanState(false);
    return (
        <FlexBox
            {...get({ element: 'block' })}
            gap="big"
            orientation={config?.orientation}
            className={block({
                [`size-${currentBreakpoint}`]: Boolean(currentBreakpoint),
            })}
            ref={ref}
        >
            {itemsToDisplay.map((item) => (
                <BlockUser key={item.userId} className={element('item')} {...item} />
            ))}
            {hasMore && (
                <>
                    {/* See more button (triple dot). */}
                    <IconButton
                        {...get({ element: 'button', action: 'see-more' })}
                        ref={seeMoreButtonRef}
                        className={element('see-more-button')}
                        label={translateKey(GLOBAL.MORE)}
                        icon={mdiDotsVertical}
                        emphasis={Emphasis.medium}
                        size={Size.m}
                        onClick={toggleSeeMore}
                    />
                    {/* Full user list displayed in a dialog. */}
                    <UserListDialog
                        className={element('see-more-dialog')}
                        title={getHeaderLabel(widget?.header, translateKey)}
                        isOpen={isSeeMoreOpen}
                        onClose={toggleSeeMore}
                        users={items.map((user) => ({
                            ...user,
                            id: user.userId,
                            fullName: getUserFullName(user, constants.userLang),
                            profilePictureUrl: user.picture,
                        }))}
                    />
                </>
            )}
        </FlexBox>
    );
};
BlockUserList.displayName = 'BlockUserList';
