import { MAP_URL_PATTERNS } from './constants';
import { MapUrlPatterns } from './types';

export const getMapWebsiteName = (url: string) => {
    const websiteName = Object.keys(MAP_URL_PATTERNS).find((name) => {
        return MAP_URL_PATTERNS[name as keyof MapUrlPatterns].find((pattern: string) => url.indexOf(pattern) > -1);
    });

    return websiteName;
};
