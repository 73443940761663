import React, { isValidElement } from 'react';

import { BlockStreamItemByResource } from '../../../types';

export type ArticleStreamItemProps = BlockStreamItemByResource<'article'>;

/** Component to display articles in a stream. */
export const ArticleStreamItem: React.FC<ArticleStreamItemProps> = ({ children: articleChild }) => {
    return isValidElement(articleChild) ? articleChild : null;
};
