import React, { useCallback, useMemo, useRef, useState } from 'react';

import { padding } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiClose, mdiInformationOutline } from '@lumapps/lumx/icons';
import {
    Button,
    Dialog,
    Divider,
    Emphasis,
    FlexBox,
    Heading,
    IconButton,
    Size,
    Tab,
    TabList,
    TabPanel,
    TabProvider,
    Toolbar,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SPACE_KEYS } from '../../keys';
import { SpaceData, SpaceVisibility } from '../../types';
import { Description } from './Description';
import { Members } from './Members';

type SpaceAboutDialogProps = Pick<SpaceData, 'title' | 'description' | 'visibility' | 'folksonomyTags'> & {
    isMobile?: boolean;
};

enum Tabs {
    description = 'description',
    members = 'members',
}

export const SpaceAboutDialog: React.FC<SpaceAboutDialogProps> = ({
    title,
    description,
    visibility,
    folksonomyTags,
    isMobile,
}) => {
    const { get } = useDataAttributes('space-about');
    const { translateKey, translateObject } = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const titleRef = useRef(null);

    const tabs: Tabs[] = useMemo(
        () => (visibility === SpaceVisibility.open ? [Tabs.description] : Object.values(Tabs)),
        [visibility],
    );

    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const toggleDialog = useCallback(() => setIsOpen(true), []);

    const renderTabs = (tab: Tabs) => {
        return tab === Tabs.members ? (
            <Members />
        ) : (
            <Description description={description} visibility={visibility} folksonomyTags={folksonomyTags} />
        );
    };

    return (
        <>
            {isMobile ? (
                <IconButton
                    emphasis={Emphasis.medium}
                    onClick={toggleDialog}
                    icon={mdiInformationOutline}
                    label={translateKey(SPACE_KEYS.ABOUT_DIALOG_TITLE)}
                    {...get({ element: 'button', action: 'toggle' })}
                />
            ) : (
                <Button
                    onClick={toggleDialog}
                    leftIcon={mdiInformationOutline}
                    emphasis={Emphasis.medium}
                    {...get({ element: 'button', action: 'toggle' })}
                >
                    {translateKey(SPACE_KEYS.ABOUT_DIALOG_TITLE)}
                </Button>
            )}
            <TabProvider>
                <Dialog
                    size={Size.regular}
                    isOpen={isOpen}
                    onClose={handleClose}
                    focusElement={titleRef}
                    header={
                        <>
                            <Toolbar
                                label={
                                    <Heading as="h1" typography={Typography.title} ref={titleRef}>
                                        {translateObject(title)}
                                    </Heading>
                                }
                                after={
                                    <IconButton
                                        emphasis={Emphasis.low}
                                        icon={mdiClose}
                                        onClick={handleClose}
                                        label={translateKey(GLOBAL.CLOSE)}
                                        {...get({ element: 'button', action: 'close' })}
                                    />
                                }
                            />

                            {tabs.length > 1 ? (
                                <TabList aria-label="">
                                    {tabs.map((tab) => (
                                        <Tab
                                            key={tab}
                                            label={translateKey(
                                                tab === Tabs.description
                                                    ? SPACE_KEYS.ABOUT_TAB_DESCRIPTION
                                                    : SPACE_KEYS.ABOUT_TAB_MEMBERS,
                                            )}
                                            id={`tab-${tab}`}
                                            {...get({ element: 'tab', action: 'change-tab', type: tab })}
                                        />
                                    ))}
                                </TabList>
                            ) : (
                                <Divider />
                            )}
                        </>
                    }
                >
                    {tabs.map((tab) => {
                        const Wrapper = tabs.length > 1 ? TabPanel : FlexBox;

                        return (
                            <Wrapper key={tab} className={padding('all', 'huge')}>
                                {renderTabs(tab)}
                            </Wrapper>
                        );
                    })}
                </Dialog>
            </TabProvider>
        </>
    );
};
