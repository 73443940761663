import { Path, Range, Point, Transforms } from 'slate';

import { ReactEditor } from '..';

/**
 * Focus editor and put cursor at point or range.
 */
export const focusAt = (editor: ReactEditor, at?: Point | Range | Path | null): void => {
    ReactEditor.focus(editor);
    if (at) {
        setTimeout(() => {
            Transforms.select(editor, at);
        }, 1);
    }
};
