import BaseApi from '@lumapps/base-api';
import { getLang } from 'components/translations';

const documentApi = new BaseApi({ path: 'document' });

const getDefaultParams = () => ({
    lang: getLang('current'),
});

/**
 * Get a document from a docPath.
 *
 * @param  {string}  docPath Document path.
 * @return {Promise} Resolved with document.
 */
const get = ({ docPath }) => {
    return documentApi.post('get', {
        ...getDefaultParams(),
        docPath,
    });
};

/**
 * Gets a list of files the user picked manually.
 *
 * @param  {string[]} docPaths The `docPaths` list corresponding to the files the user picked manually.
 * @return {Promise}  The request promise.
 */
const getMulti = ({ docPaths }) => {
    return documentApi.post('getMulti', {
        ...getDefaultParams(),
        docPaths,
    });
};

/**
 * List document entities.
 *
 * @param  {Object}  params The request parameters.
 * @return {Promise} The request promise.
 */
const list = (params) => {
    return documentApi.post('list', {
        ...getDefaultParams(),
        ...params,
    });
};

export { get, getMulti, list };
