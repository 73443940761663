import { connect } from '@lumapps/redux/react';

import { setFormFieldValue } from '../../../ducks/shareableWorkflow/actions';

import {
    getAvailableSocialNetworks,
    makeShareableworkflowFormFieldelector,
} from '../../../ducks/shareableWorkflow/selectors';

import { SocialNetworksMessages } from './SocialNetworksMessages';

const mapStateToProps = (state: any) => {
    const fieldSelector = makeShareableworkflowFormFieldelector(state) as any;

    /*
     * Until backend is done we need this part to filter facebook
     */
    // ts-ignore
    const { facebook, ...selectedSocialNetworks } = fieldSelector('shareableOn').value;

    return {
        availableSocialNetworks: getAvailableSocialNetworks(state),
        selectedSocialNetworks,
        useSameMessage: fieldSelector('sameMessage')?.value,
        commonMessage: fieldSelector('commonMessage'),
        customizedMessages: fieldSelector('customizedMessages'),
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onUseSameMessageChange: (value: boolean) => dispatch(setFormFieldValue('sameMessage', value)),
    onCustomizedMessagesChange: (values: any) => dispatch(setFormFieldValue('customizedMessages', values)),
    onCommonMessageChange: (value: string) => dispatch(setFormFieldValue('commonMessage', value)),
});

const ConnectedSocialNetworksMessages = connect(mapStateToProps, mapDispatchToProps)(SocialNetworksMessages);

export { ConnectedSocialNetworksMessages as SocialNetworksMessages };
