import partial from 'lodash/partial';

import { Editor, NodeEntry, Path, PathRef, Transforms } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { getSibling } from '@lumapps/wrex/slate/utils/getSibling';
import type { Wrex } from '@lumapps/wrex/types';

import { getLowestListItemsInRange } from '../../utils/getLowestListItemsInRange';
import { isList } from '../../utils/isList';
import { isListItem } from '../../utils/isListItem';

/**
 * Move list item up a level (into previous list item).
 */
function increaseListItemIndent(editor: Editor, pathRef: PathRef) {
    const [, path] = Editor.node(editor, pathRef.current as Path);

    const entryBefore = getSibling(editor, path, 'before');
    if (!entryBefore || !isListItem(entryBefore[0])) {
        return false;
    }

    const [listItemBefore, listItemBeforePath] = entryBefore;

    const newPath = [...listItemBeforePath, listItemBefore.children.length];
    Editor.withoutNormalizing(editor, () => {
        Transforms.moveNodes(editor, { at: path, to: newPath });
        const [list] = findParent(editor, pathRef.current as Path, isList) as NodeEntry<Wrex.Element>;

        const listType = list.type as string;
        Transforms.wrapNodes(
            editor,
            {
                type: listType,
                children: [],
            } as Wrex.Element,
            {
                at: pathRef.current as Path,
            },
        );

        pathRef.unref();
    });
    return true;
}

/**
 * Move list items up a level (at editor selection).
 *
 * @param  editor The editor.
 * @return        `true` if the list item could be moved; `false` otherwise.
 */
export function increaseListIndent(editor: Editor): boolean {
    if (!editor.selection) {
        return false;
    }

    // Get list items in selection.
    const listItems = getLowestListItemsInRange(editor, editor.selection);
    const listItemRefs = listItems.map(([, path]) => Editor.pathRef(editor, path));
    if (!listItemRefs.length) {
        return false;
    }
    listItemRefs.forEach(partial(increaseListItemIndent, editor));

    return true;
}
