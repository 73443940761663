export enum SEARCH_PROMOTED {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79092732
     */
    SEE_MORE_PROMOTED_RESULTS = 'FRONT.SEARCH.SEE_MORE_PROMOTED_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79587010
     */
    PROMOTED_RESULTS_TITLE = 'ADMIN.PROMOTED_RESULTS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79587152
     */
    NO_PROMOTED = 'ADMIN.PROMOTED_RESULTS.NO_PROMOTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79587204
     */
    NO_PROMOTED_DESC = 'ADMIN.PROMOTED_RESULTS.NO_PROMOTED_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79587241
     */
    PROMOTE_NEW = 'ADMIN.PROMOTED_RESULTS.PROMOTE_NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79587655
     */
    UNPROMOTE = 'ADMIN.PROMOTED_RESULTS.UNPROMOTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=81274706
     */
    DELETE_PROMOTION_SUCCESS = 'ADMIN.PROMOTED_RESULTS.DELETE_PROMOTION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=81274712
     */
    DELETE_PROMOTION_ERROR = 'ADMIN.PROMOTED_RESULTS.DELETE_PROMOTION_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=81274722
     */
    PROMOTE_SUCCESS = 'ADMIN.PROMOTED_RESULTS.PROMOTE_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=81274746
     */
    PROMOTE_ERROR = 'ADMIN.PROMOTED_RESULTS.PROMOTE_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83704070
     */
    ITEM_PROMOTED_DATE = 'ADMIN.PROMOTED_RESULTS.ITEM_PROMOTED_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83998161
     */
    PICK_CONTENT = 'ADMIN.PROMOTED_RESULTS.PICK_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83998279
     */
    PROMOTE = 'ADMIN.PROMOTED_RESULTS.PROMOTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84015203
     */
    DELETE_MANY_PROMOTION_SUCCESS = 'ADMIN.PROMOTED_RESULTS.DELETE_MANY_PROMOTION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84015520
     */
    UNPROMOTE_MANY = 'ADMIN.PROMOTED_RESULTS.UNPROMOTE_MANY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84591221
     */
    RESTORE_MANY_SUCCESS = 'ADMIN.PROMOTED_RESULTS.RESTORE_MANY_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84591226
     */
    RESTORE_SUCCESS = 'ADMIN.PROMOTED_RESULTS.RESTORE_SUCCESS',
    PROMOTE_MANY_SUCCESS = 'ADMIN.PROMOTED_RESULTS.PROMOTE_MANY_SUCCESS',
    DELETE_MANY_PROMOTION_ERROR = 'ADMIN.PROMOTED_RESULTS.DELETE_MANY_PROMOTION_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=283708972
     */
    PROMOTED_SINCE = 'SEARCH_PROMOTED.SINCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=283715743
     */
    UNPROMOTE_DESC = 'SEARCH_PROMOTED.UNPROMOTE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=283714994
     */
    UNPROMOTE_TITLE = 'SEARCH_PROMOTED.UNPROMOTE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=284327650
     */
    UNPROMOTE_BUTTON_LABEL = 'SEARCH_PROMOTED.REMOVE_PROMOTION_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=284420759
     */
    PROMOTE_BUTTON_LABEL = 'SEARCH_PROMOTED.ADD_PROMOTION_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286085538
     */
    KEYWORDS_TITLE = 'SEARCH_PROMOTED.KEYWORDS_TITLE',
    /**
     *https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286085589
     */
    PROMOTE_ITEM_LABEL = 'SEARCH_PROMOTED.PROMOTE_ITEM_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286085657
     */
    KEYWORDS_TEXT_FIELD_LABEL = 'SEARCH_PROMOTED.KEYWORDS_TEXT_FIELD_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286085906
     */
    KEYWORDS_TEXT_FIELD_HELPER = 'SEARCH_PROMOTED.KEYWORDS_TEXT_FIELD_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300524988
     */
    KEYWORDS_TEXT_FIELD_HELPER2 = 'SEARCH_PROMOTED.KEYWORDS_TEXT_FIELD_HELPER_2',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=286085955
     */
    KEYWORDS_TEXT_FIELD_ERROR_MESSAGE = 'SEARCH_PROMOTED.KEYWORDS_TEXT_FIELD_ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=288798893
     */
    KEYWORDS_TEXT_FIELD_MAX_LIMIT_HELPER = 'SEARCH.PROMOTED.REACH_KEYWORD_LIMIT',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=288278927
     */
    ADD_KEYWORDS = 'SEARCH_PROMOTED.ADD_KEYWORDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=288788633
     */
    ADMIN_INFO_MESSAGE = 'SEARCH_PROMOTED.ADMIN_INFO_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=288791171
     */
    PAGE_LINK_TITLE = 'SEARCH_PROMOTED.PAGE_LINK_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292295265
     */
    EDIT_PROMOTION_TITLE = 'SEARCH_PROMOTED.EDIT_PROMOTION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290726618
     */
    ITEM_TYPE_DIALOG_DESC = 'SEARCH_PROMOTED.SELECT_ITEM_TYPE_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=297834542
     */
    GROUPS_TEXT_FIELD_HELPER = 'SEARCH_PROMOTED.GROUP_TEXT_FIELD_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=297838958
     */
    GROUPS_TEXT_FIELD_LABEL = 'SEARCH_PROMOTED.GROUP_TEXT_FIELD_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=312249388
     */
    CHANGE_ITEM = 'SEARCH_PROMOTED.CHANGE_ITEM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=318702126
     */
    MAXIMUM_NUMBER_PROMOTION = 'SEARCH_PROMOTED.MAXIMUM_NUMBER_PROMOTION',
}
