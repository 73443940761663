import { useTranslate } from '@lumapps/translations';

import { MAX_NOTIFICATION_NUMBER } from '../constants';
import { NOTIFICATIONS_CENTER } from '../keys';

/** Generate the label to use depending on the notification count */
export const useNotificationLabel = (notificationCount: number) => {
    const { translateKey, translateAndReplace } = useTranslate();

    let notificationsTitle = translateKey(NOTIFICATIONS_CENTER.NO_UNREAD_NOTIFICATIONS);

    if (notificationCount > MAX_NOTIFICATION_NUMBER) {
        notificationsTitle = translateAndReplace(NOTIFICATIONS_CENTER.MORE_THAN_X_NOTIFICATIONS_UNREAD, {
            COUNT: notificationCount,
        });
    } else if (notificationCount > 0) {
        notificationsTitle =
            notificationCount === 1
                ? translateAndReplace(NOTIFICATIONS_CENTER.X_UNREAD_NOTIFICATION, {
                      COUNT: notificationCount,
                  })
                : translateAndReplace(NOTIFICATIONS_CENTER.X_UNREAD_NOTIFICATIONS, {
                      COUNT: notificationCount,
                  });
    }

    return notificationsTitle;
};
