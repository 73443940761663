(function IIFE() {
    'use strict';

    /////////////////////////////

    function ReactionService(Analytics, ContentFactory, User) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a like to a content and adds the related reaction on the content itself.
         *
         * @param {Object} content The content being liked.
         */
        function _addLike(content) {
            content.liked = true;
            content.likes = Math.max(parseInt(content.likes, 10) + 1, 0);
        }

        /**
         * Remove a like from a content for a given user and removes the related reaction.
         *
         * @param {Object} content The content being unliked.
         */
        function _removeLike(content) {
            content.liked = false;
            content.likes = Math.max(parseInt(content.likes, 10) - 1, 0);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the reactions of a certain type for a given content.
         *
         * @param  {Object} content The content object to get the reactions from.
         * @param  {string} kind    The kind of reactions to retrieve.
         * @return {Array}  A list of reaction objects.
         */
        function getByKind(content, kind) {
            if (angular.isUndefinedOrEmpty([_.get(content, 'reactions'), kind], 'some')) {
                return [];
            }

            return content.reactions.filter(function filterContentReactions(reaction) {
                return reaction.kind === kind;
            });
        }

        /**
         * Like a content object.
         *
         * @param {Object}   content The content to like.
         * @param {string}   user    The user liking the content.
         * @param {Function} [cb]    A callback function to execute on success.
         * @param {Function} [errCb] A callback function to execute on error.
         */
        function like(content, user, cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if (angular.isUndefinedOrEmpty([_.get(content, 'uid'), user], 'some') || content.liked) {
                errCb();

                return;
            }

            var Api = ContentFactory;

            var userToAdd = _.pick(user, 'firstName', 'lastName', 'uid');

            // Optimistic approach. Add the like before the endpoint call is actually successful.
            _addLike(content);

            Api.like({
                uid: content.uid,
            }, function onLikeSuccess(response) {
                Analytics.handleTaggingMap('like', 'content', {
                    content: content,
                });

                cb(response, userToAdd, true);
            }, function onLikeError(error) {
                _removeLike(content);
                errCb(error);
            });
        }

        /**
         * Like or unlike a content.
         *
         * @param {Object}   content The content to like or unlike.
         * @param {string}   kind    The kind of content object to like or unlike.
         *                           Can be 'Content' or 'Comment'.
         * @param {Function} [cb]    A callback function to execute on success.
         * @param {Function} [errCb] A callback function to execute on error.
         */
        function toggleLike(content, kind, cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            var user = User.getConnected();

            if (angular.isUndefinedOrEmpty(user)) {
                errCb();

                return;
            }

            // Note: for now we need to handle both cases because the api isn't really consistent.
            if (angular.isUndefinedOrEmpty(content.uid) && angular.isDefinedAndFilled(content.id)) {
                content.uid = content.id;
            }

            if (_.get(content, 'liked', false)) {
                service.unlike(content, user, cb, errCb);
            } else {
                service.like(content, user, cb, errCb);
            }
        }

        /**
         * Unlike a content object.
         *
         * @param {Object}   content The content to unlike.
         * @param {string}   user    The user unliking the content.
         * @param {Function} [cb]    A callback function to execute on success.
         * @param {Function} [errCb] A callback function to execute on error.
         */
        function unlike(content, user, cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if (angular.isUndefinedOrEmpty([_.get(content, 'uid'), user], 'some') ||
                _.get(content, 'liked', false) === false) {
                errCb();

                return;
            }

            var Api = ContentFactory;

            var userToRemove = _.pick(user, 'firstName', 'lastName', 'uid');

            // Optimistic approach. Remove the like before the endpoint call is actually successful.
            _removeLike(content);

            Api.unlike({
                uid: content.uid,
            }, function onUnlikeSuccess(response) {
                Analytics.handleTaggingMap('unlike', 'content', {
                    content: content,
                });

                cb(response, userToRemove);
            }, function onUnlikeError(error) {
                _addLike(content);
                errCb(error);
            });
        }

        /////////////////////////////

        service.getByKind = getByKind;
        service.like = like;
        service.toggleLike = toggleLike;
        service.unlike = unlike;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Reaction', ReactionService);
})();
