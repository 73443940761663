import { Dispatch } from '@lumapps/redux/types';
import { cancelFetchSingleUser, fetchSingleUser } from '@lumapps/user/api';

import { Author } from '../../types';
import { actions } from './authorFilterSlice';

const fields = 'email,firstName,lastName';

/**
 * Fetch the post list author filter info
 */
export const fetchAuthor = (email: string) => async (dispatch: Dispatch) => {
    dispatch(actions.fetchAuthorLoading());

    await fetchSingleUser(
        { email, fields },
        (author) => {
            dispatch(actions.fetchAuthorSuccess(author as Author));
        },
        () => {
            dispatch(actions.fetchAuthorError());
        },
    );
};

/**
 * Cancel the post list author filter info fetching
 */
export const cancelFetchAuthor = (email: string) => (dispatch: Dispatch) => {
    cancelFetchSingleUser({ email, fields });
    dispatch(actions.fetchAuthorCancel());
};
