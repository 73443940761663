import React, { ReactNode } from 'react';

import flatten from 'lodash/flatten';

import { FlexBox, Orientation } from '@lumapps/lumx/react';

import { FILTERS_MODE } from '../../constants';
import { FilterHookApi } from '../../types';
import { FiltersDropdown, FiltersDropdownProps } from '../FiltersDropdown';
import { FiltersForm } from '../FiltersForm';

export interface FiltersProps {
    /** The display mode of the filter */
    mode?: FILTERS_MODE;
    /** Whether the dropdown should be set as opened */
    isDropdownOpen?: boolean;
    /** Callback triggered when filters are selected */
    onFiltering: (value?: string) => void;
    /** Render function to display a selected value */
    renderSelectedFilter: (hookValue: FilterHookApi, index: number) => string;
    /** Callback to update the open state of the dropdown */
    setIsDropdownOpen: (value: boolean) => void;
    /** Callback triggered when all filters are cleared */
    onClearAll: (closeDropdown?: boolean) => void;
    /** callback on clear all filters */
    onClearAllFilters?: () => void;
    /** Props to forward to the dropdown */
    filterDropdownProps?: Partial<FiltersDropdownProps>;
    /**
     * Components to render for each filter.
     * Should be generated via the `useFilters` hook.
     */
    filtersToRender: ReactNode[];
    /**
     * Values for each filter components generated by their corresponding hooks.
     * Should be generated via the `useFilters` hook.
     */
    FiltersHooks: FilterHookApi[];
}

/**
 * UI for the Filters components
 * The props can be generated via the `useFilters` hooks.
 */
export const Filters = ({
    mode,
    isDropdownOpen,
    onFiltering,
    FiltersHooks,
    renderSelectedFilter,
    setIsDropdownOpen,
    onClearAll,
    onClearAllFilters,
    filterDropdownProps,
    filtersToRender: FiltersToRender,
}: FiltersProps) => {
    if (mode === FILTERS_MODE.SEPARATED) {
        return (
            <FiltersForm
                filtersToRender={FiltersToRender}
                onFiltering={onFiltering}
                onClearAllFilters={onClearAllFilters}
                onClearAll={onClearAll}
            />
        );
    }

    if (mode === FILTERS_MODE.GROUPED) {
        return (
            <FiltersDropdown
                isOpen={isDropdownOpen}
                onFilter={onFiltering}
                selectedFilter={flatten(FiltersHooks.map(renderSelectedFilter))}
                onClose={() => {
                    FiltersHooks.forEach((hook) => {
                        hook.onClearSelection();
                    });

                    setIsDropdownOpen(false);
                }}
                onClearClick={() => {
                    onClearAll(true);

                    if (onClearAllFilters) {
                        onClearAllFilters();
                    }
                }}
                onClearAll={() => {
                    onClearAll(true);

                    if (onClearAllFilters) {
                        onClearAllFilters();
                    }
                }}
                onOpen={() => setIsDropdownOpen(true)}
                {...filterDropdownProps}
            >
                {FiltersToRender}
            </FiltersDropdown>
        );
    }

    return (
        <FlexBox orientation={Orientation.horizontal} gap="medium">
            {React.Children.map(FiltersToRender, (child: React.ReactNode, index) => {
                if (React.isValidElement(child)) {
                    return (
                        <child.type
                            {...child.props}
                            onClose={child.props.onClearSelection}
                            onClearClick={() => onFiltering(child.props.id)}
                            selectedFilter={renderSelectedFilter(FiltersHooks[index], index)}
                            onFilter={onFiltering}
                            {...filterDropdownProps}
                        />
                    );
                }
                return null;
            })}
        </FlexBox>
    );
};
