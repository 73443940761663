import React from 'react';

import { classnames } from '@lumapps/classnames';
import type { HtmlAttributesWithData } from '@lumapps/data-attributes/types';
import { Typography, ColorPalette, ColorVariant, Text, TextElement } from '@lumapps/lumx/react';

export type CustomizationTextTextProps = HtmlAttributesWithData & {
    /** The tag to use to wrap the text. Defaults to `<span/>` */
    textAs?: TextElement;
    /** typography to be used */
    typography?: Typography;
    /** custom class name */
    className?: string;
    /** color to be used */
    color?: ColorPalette;
    /** color variant to be used */
    colorVariant?: ColorVariant;
};

/**
 * TEXT COMPONENT AVAILABLE FOR CUSTOMIZATIONS, NOT RELATED TO OUR DS COMPONENT, IT HAS LESS FEATURES AND WE DO NOT WANT TO
 * REMOVE IT SINCE CUSTOMERS COULD BE USING IT.
 * @deprecated Use Text from `@lumapps/lumx/react` instead
 * @family Texts
 */
export const CustomizationText: React.FC<CustomizationTextTextProps> = ({
    children,
    className,
    textAs = 'span',
    ...rest
}) => {
    return (
        <Text className={classnames(className)} {...rest} as={textAs}>
            {children}
        </Text>
    );
};

CustomizationText.displayName = 'Text';
