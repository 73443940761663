import React, { useState } from 'react';
import { useContentActions } from '@lumapps/sa-shareable-contents/hooks';
import { ProgramSelectionModal } from '@lumapps/sa-programs/components/ProgramSelectionModal';
import { IconButton, Size, ColorPalette } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { bullhornPlus } from '../../../custom_icon';
import { SAShareableWorkflow } from '../SAShareableWorkflow';

export interface SAMakeShareableFromContentProps {
    // The content Id (duh)
    contentId: string;
    // Function that will disptach toggleOpenWorkflow so the SAShareableWorkflow will be filled corretly.
    onProgramSelected(programId: string, contentId: string): void;
}

export const SAMakeShareableFromContent: React.FC<SAMakeShareableFromContentProps> = ({
    contentId,
    onProgramSelected,
}) => {
    const { translateKey } = useTranslate();
    // Fetch the list of program ids we can share this content in
    const { canMakeShareableOn } = useContentActions(contentId.toString(), true);

    // Whether modals can be opened. Internal state because we can have the same widget multiple time but want to
    // open only one modal at a time.
    const [isProgramSelectionOpen, setIsProgramSelectionOpen] = useState(false);
    const [isShareableWorkflowOpen, setIsShareableWorkflowOpen] = useState(false);

    // Close the Program Selection modal, and open the ShareableWorkflow modal.
    const handleProgramSelected = (programId: string) => {
        setIsProgramSelectionOpen(false);
        onProgramSelected(programId, contentId);
        setIsShareableWorkflowOpen(true);
    };

    // If we can only share in one program, do not show the program selection modal.
    const onClick = () => {
        if (canMakeShareableOn.length === 1) {
            handleProgramSelected(canMakeShareableOn[0]);
        } else if (canMakeShareableOn.length > 1) {
            setIsProgramSelectionOpen(true);
        }
    };

    const closeModal = () => {
        setIsProgramSelectionOpen(false);
        setIsShareableWorkflowOpen(false);
    };

    return canMakeShareableOn.length === 0 ? null : (
        <>
            <IconButton
                size={Size.m}
                color={ColorPalette.dark}
                className="sa-make-shareable__edit-button"
                data-testid="button"
                icon={bullhornPlus}
                onClick={onClick}
                label={translateKey('FRONT.SOCIAL_ADVOCACY.FORM_MAKE_SHAREABLE.BTN_TITLE')}
            />

            <ProgramSelectionModal
                programIds={canMakeShareableOn}
                isOpen={isProgramSelectionOpen}
                onProgramSelected={handleProgramSelected}
                onCancel={closeModal}
            />
            <SAShareableWorkflow isWorkflowOpen={isShareableWorkflowOpen} onClose={closeModal} />
        </>
    );
};
