import { getEmbedMapUrl } from './getEmbedMapUrl';
import { getMapUrlCoordinates } from './getMapUrlCoordinates';
import { getMapWebsiteName } from './getMapWebsiteName';

/**
 * Get the embed map url based on given map URL.
 *
 * @param  url  The map URL coordinates.
 * @return The created embed map url or undefined the given website URL is not valid map URL.
 */
export const getEmbedMapUrlFromUrl = (url?: string): string | undefined => {
    if (!url) {
        return undefined;
    }
    const mapWebsiteName = getMapWebsiteName(url);
    const urlCoords = getMapUrlCoordinates(url);
    if (!urlCoords || !mapWebsiteName) {
        return undefined;
    }
    return getEmbedMapUrl(urlCoords, mapWebsiteName);
};
