import set from 'lodash/set';
import without from 'lodash/without';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

/** The different type of aria attributes managed */
export type AriaIdType = 'label' | 'description';

export type AriaIdsState = { label: string[]; description: string[] };

/** Options for the register/unregister actions */
export type RegisterAriaIdPayload = {
    /**
     * The type of attribute to edit
     */
    ariaType: AriaIdType;
    /**
     * The values to set in the array.
     * Can either be a single string or an array.
     */
    value?: string | Array<string>;
};

export const initialState: AriaIdsState = {
    label: [],
    description: [],
};

export const reducers = {
    /** Appends the given id or array of ids to the given aria type array */
    add: (state: AriaIdsState, action: PayloadAction<RegisterAriaIdPayload>) => {
        const { ariaType, value } = action.payload;
        const currentIds = ariaType ? state[ariaType] : [];
        const valueAsArray = Array.isArray(value) ? value : [value];

        const valuesWithoutAlreadyExisting = without(valueAsArray, ...currentIds);

        if (ariaType && value && valuesWithoutAlreadyExisting.length > 0) {
            set(state, ariaType, [...currentIds, ...valuesWithoutAlreadyExisting]);
        }
    },
    /** Removes the given id or array of ids from the given aria type array */
    remove: (state: AriaIdsState, action: PayloadAction<RegisterAriaIdPayload>) => {
        const { ariaType, value } = action.payload;
        const currentIds = ariaType ? state[ariaType] : [];
        const valueAsArray = Array.isArray(value) ? value : [value];

        if (ariaType && value) {
            set(state, ariaType, without(currentIds, ...valueAsArray));
        }
    },
};

export const { actions, reducer } = createSlice({
    domain: 'aria-ids',
    initialState,
    reducers,
});
