import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const tagzAdminRoute: Route = {
    path: '/admin/folksonomy',
    legacyId: 'app.admin.folksonomy',
    appId: AppId.backOffice,
};

/**
 * Retrieves the route for nanny's admin.
 */
const admin = (): Route => {
    return tagzAdminRoute;
};

export { admin, tagzAdminRoute };
