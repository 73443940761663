import get from 'lodash/get';
import includes from 'lodash/includes';

import { getEnabledModes, MODES } from '@lumapps/customizations/modes';
import { USER_SPACE_FEATURE_TOKEN, USER_PROFILE_REACT_FEATURE_TOKEN } from '@lumapps/user-profile/constants';
import { GLOBAL } from '@lumapps/translations';

/////////////////////////////

function SocialProfileController(
    $location,
    $scope,
    $state,
    $stateParams,
    $window,
    Content,
    Features,
    Header,
    Instance,
    LocalStorage,
    Layout,
    SocialProfile,
    SocialProfileUser,
    Translation,
    User,
    UserDirectory,
    UserSettings,
    SocialAdvocacy,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Whether the user-space Feature flag is enabled
     */
    vm.hasUserSpace = Features.hasFeature(USER_SPACE_FEATURE_TOKEN);

    /**
     * Whether the user-space-history-page Feature flag is enabled
     */
    vm.hasUserProfileReact = Features.hasFeature(USER_PROFILE_REACT_FEATURE_TOKEN);

    /**
     * Connected user can access all thoses views.
     * Note 2022/08/01: only history view should be accessible now as the other pages are migrated. We do not cleanup
     * this file to reflect this, as legacy will be removed altogether asap. Instead we just rewrite vm.navItems below.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.VIEWS = {
        // eslint-disable-next-line sort-keys
        activities: {
            enabled: true,
            icon: 'message-outline',
            name: 'activities',
            v2Name: GLOBAL.FEED,
        },
        // eslint-disable-next-line sort-keys
        history: {
            enabled: true,
            icon: 'history',
            name: 'history',
            getV2Name: () => {
                if (vm.hasUserProfileReact) {
                    return GLOBAL.ACTIVITY;
                }
                return vm.isConnectedUser ? GLOBAL.SOCIAL_PROFILE_HISTORY : GLOBAL.ACTIVITY;
            },
        },
        // eslint-disable-next-line sort-keys
        'reading-history': {
            // Do not show if the feature user-profile-react is disabled
            enabled: vm.hasUserProfileReact,
            icon: 'history',
            name: 'reading-history',
            v2Name: GLOBAL.HISTORY,
        },
        // eslint-disable-next-line sort-keys
        subscriptions: {
            enabled: true,
            icon: 'bookmark-outline',
            name: 'subscriptions',
            v2Name: GLOBAL.SUBSCRIPTIONS,
        },
        // eslint-disable-next-line sort-keys
        notifications: {
            enabled: false,
            icon: 'bell-outline',
            name: 'notifications',
        },
        // eslint-disable-next-line sort-keys
        about: {
            enabled: vm.hasUserSpace || vm.hasUserProfileReact,
            icon: 'account', // should not be used
            name: 'about',
            v2Name: GLOBAL.ABOUT,
        },
    };

    /**
     * Contains the filters for the achievements.
     *
     * @type {Object}
     */
    vm.achievements = {
        available: false,
        completed: true,
        inProgress: true,
    };

    /**
     * The current user profile view.
     *
     * @type {string}
     */
    vm.currentView = undefined;

    /**
     * Indicates if the current scope is customer scope.
     *
     * @type {boolean}
     */
    vm.customerScope = false;

    /**
     * Indicates if the status is in edit mode.
     *
     * @type {boolean}
     */
    vm.editingStatus = false;

    /**
     * Indicates if the current user is the connected user.
     *
     * @type {boolean}
     */
    vm.isConnectedUser = false;

    vm.isSocialActivated = false;
    vm.hasNativeIntegrations = false;

    /**
     * The navigation items.
     * As of 2022.08.01, this is always undefined (the other views are migrated)
     *
     * @type {Array}
     */
    vm.navItems = undefined;

    /**
     * The current user profile.
     *
     * @type {Object}
     */
    vm.profile = undefined;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Header = Header;
    vm.SocialProfile = SocialProfile;
    vm.SocialProfileUser = SocialProfileUser;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;
    vm.getMarginTop = () => Utils.getMarginTop(Header, Features, Layout);

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if status is in edit mode.
     */
    function _checkStatus() {
        if (vm.isConnectedUser) {
            vm.editingStatus = !Translation.hasTranslation(get(SocialProfile.getCurrent(), 'profileStatus'));
        }
    }

    /**
     * Set current view.
     *
     * @param {string} view The wanted view.
     */
    function _setCurrentView(view) {
        if (angular.isDefinedAndFilled(vm.VIEWS[view.name])) {
            vm.currentView = view.name;
        }
    }

    /**
     * Update the fields of the popover when the instances siblings are loaded.
     */
    function _updateFields() {
        Instance.getSiblings(false).then(function onSiblingsListSuccess() {
            vm.fields = UserDirectory.getPopoverFields(UserDirectory.getMainUserDirectory(), User.getConnected());
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Edit connected user profile status.
     */
    function editStatus() {
        if (vm.isConnectedUser) {
            vm.editingStatus = true;
        }
    }

    /**
     * Logout the user.
     * Wipe the Local and Session storage and redirect him to the logout page (so that he is logged out from the
     * backend too).
     */
    function logout() {
        LocalStorage.reset();
        $window.location.href = '/logout';
    }

    /**
     * Switch profile scope.
     */
    function switchScope() {
        SocialProfile.isInstanceScope = !vm.customerScope;

        $scope.$broadcast('scope-toggle');
    }

    /**
     * Switch view.
     *
     * @param {string} view The wanted view.
     */
    function switchView(view) {
        const viewName = view.name;

        if (angular.isUndefinedOrEmpty(vm.VIEWS[viewName]) || vm.currentView === viewName) {
            return;
        }

        if (UserDirectory.shouldRedirectToV2Profile(viewName)) {
            UserDirectory.redirectToV2Profile(vm.profile.id, viewName);
        } else {
            $state.go(
                'app.front.profile',
                {
                    subscriptionType: viewName === vm.VIEWS.subscriptions.name ? 'communities' : undefined,
                    userIdentifier: SocialProfile.getCurrent().id,
                    view: viewName,
                },
                {
                    notify: false,
                },
            );
            _setCurrentView(view);
        }
    }

    /**
     * Update current user status (handle current lang).
     */
    function updateStatus() {
        SocialProfileUser.save(
            {
                profileStatus: SocialProfile.getCurrent().profileStatus,
            },
            _checkStatus,
        );
    }

    /**
     * When v2 view is enabled with user-space FF, check if the current view has a side bar
     */
    function v2HasSideBar() {
        return vm.currentView === vm.VIEWS.activities.name || vm.currentView === vm.VIEWS.history.name;
    }

    /**
     * When v2 view is enabled with user-space FF, open the infos dialog in edit mode
     */
    function v2EditProfile() {
        if (vm.hasUserSpace || vm.hasUserProfileReact) {
            // Add editDetails=true to the url params if not already present
            $location.search({ editDetails: true });
            // Open the dialog in edit mode
            UserDirectory.setDisplayedUser(vm.profile.id);
        }
    }

    /////////////////////////////

    vm.editStatus = editStatus;
    vm.logout = logout;
    vm.switchScope = switchScope;
    vm.switchView = switchView;
    vm.updateStatus = updateStatus;

    vm.v2HasSideBar = v2HasSideBar;
    vm.v2EditProfile = v2EditProfile;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the list of instances siblings is updated, recompute the popover fields for the user.
     */
    $scope.$on('instances-siblings-list-updated', function onInstancesSiblingsUpdated() {
        _updateFields();
    });

    /**
     * When the user has successfully been unfollowed, remove the following from its list of follows.
     *
     * @param {Event}  evt  The user unfollow event.
     * @param {Object} args The user properties.
     */
    $scope.$on('user-unfollow-success', function onUnfollowSuccess(evt, args) {
        SocialProfile.removeFollowing(args.userId);
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _updateFields();

        vm.profile = SocialProfile.getCurrent();
        vm.isConnectedUser = vm.profile.id === User.getConnected().uid;
        vm.isSocialActivated = Features.hasFeature('social');
        vm.hasNativeIntegrations = Features.hasFeature('slack') || Features.hasFeature('workplace');
        const modes = getEnabledModes(window.LUMAPPS_MODES ? window.LUMAPPS_MODES.split(',') : []);
        vm.hasUserProfileReact = vm.hasUserProfileReact || modes[MODES.USER_PROFILE_REACT];

        /**
         * We can display activities if SOCIAL is enabled
         * AND if it's the current user
         * or if the user is ok to show their activities
         */
        vm.canViewActivities =
            vm.isSocialActivated && (vm.isConnectedUser || vm.profile.settings.social.isActivityVisible);

        Content.setCurrent(undefined);

        /**
         * Set default view:
         *   - If current user or other user which we can see the activity, set to history
         *   - Else set nothing
         */
        const defaultView = vm.canViewActivities ? vm.VIEWS.history : [];

        if (
            angular.isDefinedAndFilled(vm.VIEWS[$stateParams.view]) &&
            includes(vm.navItems, vm.VIEWS[$stateParams.view])
        ) {
            _setCurrentView(vm.VIEWS[$stateParams.view]);
        } else {
            _setCurrentView(defaultView);
        }

        const params = $location.search();
        if (params.editDetails) {
            vm.v2EditProfile();
        } else if (params.details) {
            User.redirectToUserDirectoryProfile(vm.profile);
        }

        // Set secondary field.
        vm.secondaryField = User.getSecondaryField(vm.profile.apiProfile);

        // Check status mode.
        _checkStatus();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('SocialProfileController', SocialProfileController);

/////////////////////////////

export { SocialProfileController };
