import React from 'react';

import pick from 'lodash/pick';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Divider } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { ListWidgetPropertiesSetting } from '@lumapps/widget-settings/components/ListWidgetPropertiesSetting';
import { ListWidgetProperties, SELECTION_TYPE_VALUES, SelectionSettingType } from '@lumapps/widget-settings/types';

import { DATA_SCOPE } from '../../constants';
import { WIDGET_ARTICLE_LIST } from '../../keys';
import { WidgetArticleListPropertiesType } from '../../types';
import { InformationDisplayedSection } from './InformationDisplayedSection';
import { RestrictionSection } from './RestrictionSection';
import { SelectionSection } from './SelectionSection';
import { SortingSection } from './SortingSection';

export interface WidgetArticleListSettingsProps {
    properties?: WidgetArticleListPropertiesType;
    onChange: (properties: WidgetArticleListPropertiesType) => void;
}

const CLASSNAME = 'widget-article-list-settings';

export const WidgetArticleListSettings: React.FC<WidgetArticleListSettingsProps> = ({ properties, onChange }) => {
    const instanceId = useSelector(instanceIdSelector);
    const { translateKey } = useTranslate();

    const propertiesToDisplay = React.useMemo(() => {
        return properties?.viewMode === 'slideshow'
            ? [ListWidgetProperties.maxNumber]
            : [ListWidgetProperties.filters, ListWidgetProperties.maxNumber, ListWidgetProperties.loadMore];
    }, [properties?.viewMode]);

    const computedSelection: SelectionSettingType = React.useMemo(() => {
        let selectedOption;
        let selectedSubOption;

        if (properties?.displayType === 'pick') {
            selectedOption = SELECTION_TYPE_VALUES.MANUAL;
        } else if (properties?.displayType === 'current_community') {
            selectedOption = SELECTION_TYPE_VALUES.CURRENT_COMMUNITY;
        } else {
            selectedOption = SELECTION_TYPE_VALUES.DYNAMIC;
            if (!properties?.communityIds?.length) {
                selectedSubOption = SELECTION_TYPE_VALUES.ALL_COMMUNITIES;
            } else {
                selectedSubOption = SELECTION_TYPE_VALUES.MANUAL_COMMUNITY;
            }
        }

        return {
            selectedOption,
            selectedCommunitiesIds: properties?.communityIds,
            selectedEntitiesIds: properties?.articleIds,
            selectedSubOption,
        };
    }, [properties]);

    return (
        <div className={CLASSNAME}>
            <SelectionSection
                onChange={({ selection }) => {
                    if (selection.selectedOption === SELECTION_TYPE_VALUES.MANUAL) {
                        onChange({
                            articleIds: selection.selectedEntitiesIds,
                            communityIds: [],
                            displayType: 'pick',
                            siteId: instanceId,
                        });
                    } else if (selection.selectedOption === SELECTION_TYPE_VALUES.CURRENT_COMMUNITY) {
                        onChange({
                            articleIds: [],
                            displayType: 'current_community',
                            siteId: instanceId,
                            communityIds: selection.selectedCommunitiesIds,
                        });
                    } else if (selection.selectedSubOption === SELECTION_TYPE_VALUES.ALL_COMMUNITIES) {
                        onChange({
                            articleIds: [],
                            communityIds: [],
                            displayType: 'list',
                            siteId: instanceId,
                        });
                    } else {
                        onChange({
                            articleIds: [],
                            communityIds: selection.selectedCommunitiesIds,
                            displayType: 'list',
                            siteId: instanceId,
                        });
                    }
                }}
                currentSelection={computedSelection}
            />
            <Divider />
            <RestrictionSection
                onChange={onChange}
                currentSelectionType={computedSelection}
                currentRestrictions={properties && pick(properties, 'isFollowedCommunityFilterActive')}
            />
            <Divider />
            <SortingSection onChange={onChange} currentSorting={properties?.sort} />
            <Divider />
            <ListWidgetPropertiesSetting
                onChange={(input) =>
                    onChange({
                        canLoadMore: input.canLoadMore,
                        hasFilters: input.hasFilters,
                        maxNumber: input?.maxNumber,
                    })
                }
                currentPropertiesSetting={{
                    canLoadMore: properties?.canLoadMore ?? true,
                    hasFilters: properties?.hasFilters ?? false,
                    maxNumber: properties?.maxNumber,
                }}
                loadMoreHelper={translateKey(WIDGET_ARTICLE_LIST.SETTINGS_SHOW_LOAD_MORE_HELPER)}
                defaultMaxNumber="3"
                dataScope={DATA_SCOPE}
                propertiesToDisplay={propertiesToDisplay}
            />
            <Divider />
            <InformationDisplayedSection onChange={onChange} currentInformationDisplayedSetting={properties?.fields} />
        </div>
    );
};
