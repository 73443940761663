import React, { ReactNode, useReducer } from 'react';

import { AriaIdsContext } from '../../context/AriaIdsContext';
import { AriaIdsRegisterContext } from '../../context/AriaIdsRegisterContext';
import { reducer, initialState, actions, RegisterAriaIdPayload } from '../../ducks/reducer';

/**
 * Provider to store and update ids that can then be used for aria attributes
 *
 * @family A11Y
 */
export const AriaIdsProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const register = React.useMemo(
        () => ({
            add: (payload: RegisterAriaIdPayload) => dispatch(actions.add(payload)),
            remove: (payload: RegisterAriaIdPayload) => dispatch(actions.add(payload)),
        }),
        [],
    );

    return (
        <AriaIdsContext.Provider value={state}>
            <AriaIdsRegisterContext.Provider value={register}>{children}</AriaIdsRegisterContext.Provider>
        </AriaIdsContext.Provider>
    );
};
