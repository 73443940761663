import capitalize from 'lodash/capitalize';

/////////////////////////////

function SocialProfileSubscriptionController(Interest, LxNotificationService, SocialSubscription, Translation,
    Utils) {
    'ngInject';

    var vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the list of the fields we want to display in the content list.
     *
     * @type {Array}
     */
    vm.contentFields = ['title'];

    /**
     * The followed entity.
     *
     * @type {Object}
     */
    vm.following = undefined;

    /**
     * The followed entity type (content, user, ...).
     *
     * @type {string}
     */
    vm.followingType = undefined;

    /**
     * Indicates if the subscription is related to a content.
     *
     * @type {boolean}
     */
    vm.isContentSubscription = false;

    /**
     * Indicates if the subscription is related to an interest.
     *
     * @type {boolean}
     */
    vm.isInterestSubscription = false;

    /**
     * Indicates if the subscription is related to a user.
     *
     * @type {boolean}
     */
    vm.isUserSubscription = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.SocialSubscription = SocialSubscription;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Un-follow entity.
     */
    function unsubscribe() {
        LxNotificationService.confirm(
            Translation.translate('PROFILE_SUBSCRIPTION_DELETE'),
            Translation.translate('PROFILE_SUBSCRIPTION_DELETE_DESCRIPTION'), {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            }, function unsubscribeConfirmCallback(answer) {
                if (!answer) {
                    return;
                }

                if (vm.isInterestSubscription) {
                    Interest.unsubscribe(vm.subscription,
                        function onInterestUnsubscribeSuccess() {
                            LxNotificationService.success(
                                Translation.translate('PROFILE_SUBSCRIPTION_DELETE_SUCCESS')
                            );
                        },
                        Utils.displayServerError,
                        vm.listKey
                    );
                } else {
                    SocialSubscription.unfollow(
                        vm.subscription.uid,
                        vm.subscription['following' + capitalize(vm.followingType) + 'Key'],
                        vm.followingType,
                        function onUnfollowSuccess() {
                            LxNotificationService.success(
                                Translation.translate('PROFILE_SUBSCRIPTION_DELETE_SUCCESS')
                            );

                            Utils.reject(SocialSubscription.displayList(vm.listKey), {
                                uid: vm.subscription.uid,
                            });
                        },
                        Utils.displayServerError,
                        vm.listKey
                    );
                }
            });
    }

    /////////////////////////////

    vm.unsubscribe = unsubscribe;

    /////////////////////////////

    /**
     * Initialize the controller.
     * TODO [max]: add sub to cache at init?
     */
    function init() {
        if (angular.isUndefinedOrEmpty(vm.subscription.type)) {
            if (vm.listKey === 'interests') {
                vm.isInterestSubscription = true;
            } else {
                vm.isUserSubscription = true;
            }

            return;
        }

        vm.isContentSubscription = true;

        var subTypes = vm.subscription.type.split('_');
        var type = SocialSubscription.FOLLOWING_TYPES[_.last(subTypes).toLowerCase()];

        if (angular.isDefinedAndFilled(type)) {
            var followingKey = 'following' + capitalize(type) + 'Details';

            if (angular.isDefinedAndFilled(vm.subscription[followingKey])) {
                vm.following = vm.subscription[followingKey];
                vm.followingType = type;
            }
        }
    }

    init();
}

/////////////////////////////

/**
 * A directive that displays a social profile subscription.
 * It can be of 3 types that will look slightly different: content, interest, user.
 *
 * @param {string} listKey      The identifier of the list the subscription is part of.
 * @param {Object} subscription The subscription object to display.
 * @param {Object} userFields   The fields of the user subscriptions to be displayed.
 */

function SocialProfileSubscriptionDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: SocialProfileSubscriptionController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            listKey: '@',
            subscription: '=',
            userFields: '=',
        },
        templateUrl: '/client/front-office/modules/social/profile/views/social-profile-subscription.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('socialProfileSubscription', SocialProfileSubscriptionDirective);


/////////////////////////////

export { SocialProfileSubscriptionController, SocialProfileSubscriptionDirective };