import { fetchFilesTask } from '../community_tasks';

/**
 * Actions types.
 */
const FETCH_FILES_LOADING = 'community/FETCH_FILES_LOADING';
const FETCH_FILES_SUCCESS = 'community/FETCH_FILES_SUCCESS';
const FETCH_FILES_ERROR = 'community/FETCH_FILES_ERROR';

/**
 * Dispatcher methods.
 *
 * @param  {string} docPath The path of the folder of the community
 * @return {void}   The actions creator's thunk.
 */
const dispatchFetchFiles = (docPath) => (dispatch) => {
    dispatch({ type: FETCH_FILES_LOADING });

    return fetchFilesTask({ docPath })
        .then(({ payload } = {}) => {
            dispatch({
                payload,
                type: FETCH_FILES_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: FETCH_FILES_ERROR,
            });
        });
};

export { FETCH_FILES_ERROR, FETCH_FILES_LOADING, FETCH_FILES_SUCCESS, dispatchFetchFiles };
