import React from 'react';

import { lumappsAnalyticsPlugin } from '@lumapps/analytics-tracking/tracking';
import { articleView } from '@lumapps/articles/routes';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { isSpacesFeatureEnabled } from '@lumapps/communities/ducks/selectors';
import { useCommunityLink } from '@lumapps/communities/hooks/useCommunityLink';
import type { CommunityLinkRef } from '@lumapps/communities/types';
import { getCommunityRoute } from '@lumapps/communities/utils/community-link/getCommunityRoute';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { useContentLink } from '@lumapps/contents/hooks/useContentLink';
import { useHomeRoute } from '@lumapps/contents/hooks/useHomeRoute';
import type { ContentLinkRef } from '@lumapps/contents/types';
import { eventView } from '@lumapps/events/routes';
import { getEventLink } from '@lumapps/events/utils/getEventLink';
import { trainingView } from '@lumapps/learning-trainings/routes';
import { ButtonProps, ColorPalette, LinkProps } from '@lumapps/lumx/react';
import { microAppByIdFrontOffice } from '@lumapps/micro-apps/routes';
import { getPlaylistRouteById } from '@lumapps/play-playlists/routes';
import { getPlayVideoRouteById } from '@lumapps/play-video/routes';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { addLocationOriginToUrl, createUrl } from '@lumapps/router/utils';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../../../constants';
import type { Resource } from '../../../types';
import { type LinkPreviewTrackingActionProps, useLinkPreviewTrackingContext } from '../context';

type StrictTarget = {
    // needed because Button props are stricter than "string"
    target?: ButtonProps['target'];
};

type InnerLinkProps = LinkProps & StrictTarget;
type HtmlLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
    StrictTarget & {
        // needed because Button props are stricter than "string"
        type?: React.ButtonHTMLAttributes<any>['type'];
        color?: ColorPalette;
    };

export type UseResourceLink =
    // Special community link (handles auto redirect to v1 or v2)
    | { linkAs: typeof CommunityLink; to: CommunityLinkRef; onClick?: (props: any) => void }
    // Special content link (handles auto redirect to v1 or v2)
    | { linkAs: typeof ContentLink; to: ContentLinkRef; onClick?: (props: any) => void }
    | InnerLinkProps
    | HtmlLinkProps
    | undefined;

/**
 * Generates a props object for Link or Button
 * @param resource - data for a link preview with lumapps internal resource
 * @returns UseResourceLink
 */
export const useResourceLinkProps = (resource?: Resource, url?: string): UseResourceLink => {
    const isSpaceFeatureEnabled = useSelector(isSpacesFeatureEnabled);
    const homeRoute = useHomeRoute();
    const context = useLinkPreviewTrackingContext();

    const handleClick = React.useCallback(
        (props) => {
            if (!context?.getTrackingAction) {
                return undefined;
            }

            return () => {
                const actionsProps: LinkPreviewTrackingActionProps = {
                    resourceId: resource?.id || '',
                    resourceType: resource?.type,
                    title: resource?.title || '',
                    ...props,
                };
                const action = context.getTrackingAction(actionsProps);
                lumappsAnalyticsPlugin.track({ payload: { action } });
            };
        },
        [context, resource?.id, resource?.title, resource?.type],
    );

    const communityLink = useCommunityLink(
        resource?.type === LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY
            ? {
                  id: resource.id,
                  slug: resource.slug,
                  instance: { slug: resource.siteSlug },
              }
            : {
                  id: '',
                  slug: '',
                  instance: { slug: '' },
              },
    );

    const contentLink = useContentLink(
        resource?.type === LINK_PREVIEW_RESOURCE_TYPES.CONTENT
            ? {
                  id: resource.id,
                  slug: resource.slug,
                  instance: { slug: resource.siteSlug },
              }
            : {
                  id: '',
                  slug: '',
                  instance: { slug: '' },
              },
    );

    switch (resource?.type) {
        case LINK_PREVIEW_RESOURCE_TYPES.ARTICLE:
            return {
                linkAs: RouterLink,
                to: articleView(resource.id || ''),
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(articleView(resource.id))) }),
            };
        case LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY:
            return {
                linkAs: CommunityLink,
                to: {
                    id: resource.id,
                    slug: resource.slug,
                    instance: { slug: resource.siteSlug },
                },
                onClick: handleClick({ url: communityLink.props.href || '' }),
            };
        case LINK_PREVIEW_RESOURCE_TYPES.CONTENT:
            return {
                linkAs: ContentLink,
                to: {
                    id: resource.id,
                    slug: resource.slug,
                    instance: { slug: resource.siteSlug },
                },
                onClick: handleClick({ url: contentLink.props.href || '' }),
            };
        case LINK_PREVIEW_RESOURCE_TYPES.EVENT:
            return {
                ...getEventLink(resource?.id || '', undefined, undefined, { slug: resource?.siteSlug }),
                onClick: handleClick({
                    url: addLocationOriginToUrl(createUrl(eventView(resource.id, undefined, resource?.siteSlug))),
                }),
            };
        case LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP: {
            const to = microAppByIdFrontOffice({ id: resource?.id || '' });

            return {
                linkAs: RouterLink,
                to,
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(to)) }),
            };
        }
        case LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH: {
            const to = trainingView(resource?.id || '');

            return {
                linkAs: RouterLink,
                to,
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(to)) }),
            };
        }
        case LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE: {
            const to = trainingView(resource?.id || '');

            return {
                linkAs: RouterLink,
                to,
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(to)) }),
            };
        }
        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO: {
            const to = getPlayVideoRouteById(resource?.id || '');

            return {
                linkAs: RouterLink,
                to,
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(to)) }),
            };
        }
        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST: {
            const to = getPlaylistRouteById({ id: resource?.id || '' });

            return {
                linkAs: RouterLink,
                to,
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(to)) }),
            };
        }
        case LINK_PREVIEW_RESOURCE_TYPES.POST: {
            const to = getCommunityRoute(
                {
                    id: resource.communityId,
                    slug: resource.communitySlug,
                    identifier: resource.id,
                    view: 'post',
                    instance: { id: resource.siteSlug, slug: resource.siteSlug },
                },
                { isSpaceFeatureEnabled, homeRoute },
            ).route;

            return {
                linkAs: RouterLink,
                to,
                onClick: handleClick({ url: addLocationOriginToUrl(createUrl(to)) }),
            };
        }
        default:
            return {
                linkAs: 'a',
                href: url || '',
                target: '_blank',
                rel: 'noopener noreferrer',
            };
    }
};
