const regex =
    /((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)/g;
const ponctuationRegExp = /([,;\s])/;
const endPonctuationRegExp = /[.,;:]$/;

export const containsLinksOrEmails = (text: string): boolean => {
    regex.lastIndex = 0; // reset the last index since regex is global
    return regex.test(text);
};

/**
 * Splits a string on link and email
 * e.g.
 * "This is a paragraph with https://first.com, and second.com, then an email address :mail@example.com."
 * [ "This is a paragraph with ", "https://first.com", ", and ", "second.com", ", then an email address :", "mail@example.com", "." ]
 */
export const splitOnLinksAndEmails = (text: string): string[] => {
    let lastIndex = 0;
    regex.lastIndex = 0; // reset the last index since regex is global
    const result = [];

    let match = regex.exec(text);
    while (match !== null) {
        // If the match ends with a ponctuation, remove the char from the match and adjust the last index
        if (endPonctuationRegExp.test(match[0].slice(-1))) {
            match[0] = match[0].slice(0, -1);
            regex.lastIndex -= 1;
        }

        if (match.index !== lastIndex) {
            result.push(text.substring(lastIndex, match.index));
        }

        // Now we have the link or email address, we split it again on ponctuation
        result.push(...match[0].split(ponctuationRegExp));
        lastIndex = regex.lastIndex;

        match = regex.exec(text);
    }

    if (lastIndex !== text.length) {
        result.push(text.substring(lastIndex));
    }

    return result;
};
