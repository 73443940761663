/* istanbul ignore file */

export enum MemberRoles {
    manager = 'MANAGER',
    ambassador = 'AMBASSADOR',
}

export enum MemberType {
    user = 'user',
    group = 'group',
}

export enum MemberPermissions {
    CAN_ACCESS_BASIC_DATA = 'CAN_ACCESS_BASIC_DATA',
    CAN_ACCESS_ALL_SHARES_DATA = 'CAN_ACCESS_ALL_SHARES_DATA',
    CAN_MANAGE_PROGRAM_DATA = 'CAN_MANAGE_PROGRAM_DATA',
    CAN_MANAGE_MANAGERS = 'CAN_MANAGE_MANAGERS',
    CAN_MANAGE_AMBASSADORS = 'CAN_MANAGE_AMBASSADORS',
    CAN_MANAGE_TOPICS = 'CAN_MANAGE_TOPICS',
    CAN_MANAGE_SHAREABLE_CONTENTS = 'CAN_MANAGE_SHAREABLE_CONTENTS',
    CAN_SHARE_SHAREABLE_CONTENTS = 'CAN_SHARE_SHAREABLE_CONTENT',
}

/**
 * The user object we get from /program-membership
 */
export interface MemberUser {
    customer: string;
    id: string;
    fullName: string;
    profilePictureUrl?: string;
    jobTitle?: string;
}

/**
 * The group object we get from /program-membership
 */
export interface MemberGroup {
    id: string;
    // customer: string;
    name: string;
}

/**
 * The item we get from of /program-membership (see /social-advocacy/sa-members/api/index.ts)
 */
export interface Member {
    id: string;
    role: MemberRoles;
    memberType: MemberType;
    user?: MemberUser;
    group?: MemberGroup;
    permissions: Array<MemberPermissions>;
}
