import { KeyboardEventHandler } from 'react';

import last from 'lodash/last';

import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { onKey } from '@lumapps/utils/event/onKey';
import { and } from '@lumapps/utils/function/predicate/and';
import { or } from '@lumapps/utils/function/predicate/or';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { BaseRange, Path, Range } from '@lumapps/wrex/slate';
import { hasElementTypeInSelection } from '@lumapps/wrex/slate/utils/hasElementTypeInSelection';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { isAtStartOfTextNode } from '@lumapps/wrex/slate/utils/isAtStartOfTextNode';
import { WrexEditor } from '@lumapps/wrex/types';

import { LIST_FEATURES, LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from '../../constants';
import { ListEditor } from '../../types';
import { splitListItem } from '../../utils/splitListItem';

/**
 * Initialize key event handler for the list editor.
 */
export const getKeyHandler = (editor: WrexEditor<ListEditor>): KeyboardEventHandler => {
    const isListItemSelected = () => hasElementTypeInSelection(editor, LIST_ITEM);
    const hasSelection = () => !!editor.selection;

    return onKey(
        [
            // Increase list indent level at selection.
            {
                match: and(isListItemSelected, isHotKey('Tab')),
                handler: () => {
                    editor.increaseListIndent();
                },
            },
            // Decrease list indent level at selection.
            {
                match: and(
                    isListItemSelected,
                    or(
                        isHotKey('shift+Tab'),
                        and(
                            or(isHotKey('Enter'), isHotKey('Backspace')),
                            hasSelection,
                            () => Range.isCollapsed(editor.selection as BaseRange),
                            () => last(Path.parent(editor.selection?.focus.path as Path)) === 0,
                            () => isAtStartOfTextNode(editor),
                        ),
                    ),
                ),
                handler: () => {
                    editor.decreaseListIndent();
                },
            },
            {
                match: and(isListItemSelected, isHotKey('Enter'), hasSelection),
                handler: () => {
                    splitListItem(editor);
                },
            },
            // Shortcut to toggle unordered list
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[UNORDERED_LIST].hotkey), () =>
                    editor.isListFeatureEnabled(LIST_FEATURES.unorderedList),
                ),
                handler: () => {
                    if (!editor.selection) {
                        initSelection(editor, 'block');
                    }
                    editor.setListType(editor.isListActive(UNORDERED_LIST) ? null : UNORDERED_LIST);
                },
            },
            // Shortcut to toggle ordered list
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[ORDERED_LIST].hotkey), () =>
                    editor.isListFeatureEnabled(LIST_FEATURES.orderedList),
                ),
                handler: () => {
                    if (!editor.selection) {
                        initSelection(editor, 'block');
                    }
                    editor.setListType(editor.isListActive(ORDERED_LIST) ? null : ORDERED_LIST);
                },
            },
        ],
        { preventDefault: true },
    );
};
