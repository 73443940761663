/* eslint-disable camelcase */
import get from 'lodash/get';

import * as notify from 'components/services/notification';

import { translate as t } from 'components/translations';
import { clearLoadingState, setLoadingState } from 'components/components/ducks/loadingStates';
import { closeConfirmBox, freezeConfirmBox } from 'components/components/ducks/confirmBox';

import {
    deactivateShareableContent,
    getShareableContent,
    getShareableContentsWithAnalytics,
} from 'components/components/social-advocacy/api';

import { LOADING_NAMESPACE_CONTENT_LIST_LOAD_MORE, LOADING_NAMESPACE_CONTENT_LIST_REFRESH } from './constants';

const PAGE_SIZE = 20;

const DOMAIN = 'SA/SHAREABLE_CONTENT';

const FETCH_ALL_SUCCESS = `${DOMAIN}/FETCH_ALL_SUCCESS`;

const FETCH_ALL_ERROR = `${DOMAIN}/FETCH_ALL_ERROR`;

const FETCH_BY_ID_SUCCESS = `${DOMAIN}/FETCH_BY_ID_SUCCESS`;

const RESET_CURSOR = `${DOMAIN}/RESET_CURSOR`;

const ARCHIVED_CONTENT_SUCCESS = `${DOMAIN}/ARCHIVED_CONTENT_SUCCESS`;

const GET_SHAREABLE_CONTENT_WITH_STATS_SUCCESS = `${DOMAIN}/GET_SHAREABLE_CONTENT_WITH_STATS_SUCCESS`;

const TOGGLE_SHAREABLE_CONTENT_SORT_DIRECTION = `${DOMAIN}/TOGGLE_SHAREABLE_CONTENT_SORT_DIRECTION`;

const OPEN_SHAREABLE_CONTENT_GALLERY_DIALOG = `${DOMAIN}/OPEN_SHAREABLE_CONTENT_GALLERY_DIALOG`;

const CLOSE_SHAREABLE_CONTENT_GALLERY_DIALOG = `${DOMAIN}/CLOSE_SHAREABLE_CONTENT_GALLERY_DIALOG`;

const getShareableContents = (initiator, options = {}) => (dispatch, getState) => {
    if (initiator) {
        dispatch(setLoadingState(initiator, false));
    }

    const {
        socialAdvocacy: {
            shareableContents: { cursor, shareableContentSortDir },
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    const currentFilters = options.filters || {
        sharerIds: currentSAFilters.sharers,
        topicIds: currentSAFilters.topics,
    };

    if (options.programId) {
        currentFilters.programIds = [options.programId];
    }

    const requestNextPage = initiator === LOADING_NAMESPACE_CONTENT_LIST_LOAD_MORE;

    getShareableContentsWithAnalytics(
        Object.assign(currentFilters || {}, { active: true }),
        PAGE_SIZE,
        options.sortDir || shareableContentSortDir,
        requestNextPage ? cursor : undefined,
    ).then((response) => {
        const { data: contents } = response;
        if (contents) {
            if (initiator) {
                dispatch(clearLoadingState(initiator));
            }

            return dispatch({
                cursor: contents.cursor,
                hasMore: contents.more,
                isNextPage: requestNextPage,
                shareableContents: contents.items,
                type: FETCH_ALL_SUCCESS,
            });
        }

        return dispatch({
            type: FETCH_ALL_ERROR,
        });
    });
};

const setShareableContentSortDirection = (requestedSortDir, programId) => (dispatch) => {
    dispatch({
        requestedSortDir,
        type: TOGGLE_SHAREABLE_CONTENT_SORT_DIRECTION,
    });
    dispatch(getShareableContents(LOADING_NAMESPACE_CONTENT_LIST_REFRESH, { programId }));
};

const getShareableContentByCoreId = (contentCoreId) => async (dispatch, getState) => {
    const state = getState();
    const { loadingState } = state;

    if (!get(loadingState, 'GET_SHAREABLE_CONTENT_BY_CONTENT_ID.loading')) {
        dispatch(setLoadingState('GET_SHAREABLE_CONTENT_BY_CONTENT_ID'));

        try {
            const shareableContent = await getShareableContent(contentCoreId);
            if (shareableContent) {
                dispatch({
                    shareableContent,
                    type: FETCH_BY_ID_SUCCESS,
                });
            }
        } finally {
            dispatch(clearLoadingState('GET_SHAREABLE_CONTENT_BY_CONTENT_ID'));
        }
    }
};

const getShareableContentWithStatsByCoreId = (contentCoreId) => (dispatch) => {
    getShareableContentsWithAnalytics({ active: true, shareableContentIds: [contentCoreId] }).then((response) => {
        const { data: contents } = response;
        if (contents) {
            dispatch({
                shareableContent: contents.items[0],
                type: GET_SHAREABLE_CONTENT_WITH_STATS_SUCCESS,
            });
        }
    });
};

const resetCursor = () => (dispatch) =>
    dispatch({
        type: RESET_CURSOR,
    });

const addContentShareable = (shareableContent) => (dispatch) => {
    dispatch({
        shareableContent,
        type: FETCH_BY_ID_SUCCESS,
    });
};

const archiveContentShareable = (contentId) => (dispatch) => {
    dispatch(freezeConfirmBox());
    deactivateShareableContent(contentId)
        .then((contentShareable) => {
            if (contentShareable) {
                dispatch({
                    contentId,
                    type: ARCHIVED_CONTENT_SUCCESS,
                });
            }
        })
        .then(() => {
            dispatch(closeConfirmBox());
            notify.success(t('SOCIAL_ADVOCACY_ARCHIVE_SUCCESS'));
        })
        .catch(() => {
            dispatch(closeConfirmBox());
            notify.error(t('SOCIAL_ADVOCACY_ARCHIVE_ERROR'));
        });
};

const openGalleryDialog = (images, initialIndex = 0, title = '') => (dispatch) =>
    dispatch({
        images,
        title,
        initialIndex,
        type: OPEN_SHAREABLE_CONTENT_GALLERY_DIALOG,
    });

const closeGalleryDialog = () => (dispatch) =>
    dispatch({
        type: CLOSE_SHAREABLE_CONTENT_GALLERY_DIALOG,
    });

export {
    getShareableContents,
    resetCursor,
    getShareableContentByCoreId,
    getShareableContentWithStatsByCoreId,
    archiveContentShareable,
    addContentShareable,
    setShareableContentSortDirection,
    closeGalleryDialog,
    openGalleryDialog,
    FETCH_ALL_SUCCESS,
    FETCH_BY_ID_SUCCESS,
    RESET_CURSOR,
    GET_SHAREABLE_CONTENT_WITH_STATS_SUCCESS,
    ARCHIVED_CONTENT_SUCCESS,
    TOGGLE_SHAREABLE_CONTENT_SORT_DIRECTION,
    OPEN_SHAREABLE_CONTENT_GALLERY_DIALOG,
    CLOSE_SHAREABLE_CONTENT_GALLERY_DIALOG,
};
