(function IIFE() {
    'use strict';

    /////////////////////////////

    function PostFactory($resource, Config) {
        'ngInject';

        /**
         * The url to use for endpoints related to the merging of posts.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        var _MERGE_ENDPOINT_URL = Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/:uid/merge/:methodKey';

        /**
         * The url to use for endpoints related to marking posts as spam.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        var _REPORT_ENDPOINT_URL = Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/report/:methodKey';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/:methodKey', {}, {
            'approveMerge': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'approve',
                },
                url: _MERGE_ENDPOINT_URL,
            },

            'delete': {
                isArray: false,
                method: 'delete',
                params: {
                    methodKey: 'delete',
                },
            },

            'get': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/:methodKey'
            },

            'list': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'search',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/:methodKey'
            },

            'moderate': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'moderate',
                },
                url: _REPORT_ENDPOINT_URL,
            },

            'pin': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'pin',
                },
            },

            'rejectMerge': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'reject',
                },
                url: _MERGE_ENDPOINT_URL,
            },

            'report': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
                url: _REPORT_ENDPOINT_URL,
            },

            'reportList': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: _REPORT_ENDPOINT_URL,
            },

            'reportMerge': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'report',
                },
                url: _MERGE_ENDPOINT_URL,
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            'unpin': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'unpin',
                },
            },
        });
    }

    function PostMoveFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/:uid/move/:communityId', null, {
            move: {
                isArray: false,
                method: 'POST',
            },
        });
    }

    function PostVoteFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/community/post/:uid/vote/:vote', null, {
            vote: {
                isArray: false,
                method: 'PUT',
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('PostVoteFactory', PostVoteFactory);
    angular.module('Factories').factory('PostMoveFactory', PostMoveFactory);
    angular.module('Factories').factory('PostFactory', PostFactory);
})();
