import React from 'react';
import { func, string, arrayOf, bool } from 'prop-types';

import includes from 'lodash/includes';
import map from 'lodash/map';
import noop from 'lodash/noop';

import { Icon, Size } from '@lumapps/lumx/react';
import { mdiAlertCircle } from '@lumapps/lumx/icons';

import { ConfigConstant } from 'common/constants/config_constants';
import { getProperty as getCustomerProperty } from 'components/services/customer';
import { getCurrentInstanceLangs } from 'components/services/instance';
import { Select } from 'components/components/ui/menus';

/**
 * Define how we want to display choices in select component.
 *
 * @return {HTMLElement} Component template.
 */
function ChoiceComponent({ isError }) {
    return isError && <Icon className="pr lumx-color-font-red-L1" icon={mdiAlertCircle} size={Size.xxs} />;
}

ChoiceComponent.propTypes = {
    // eslint-disable-next-line react/forbid-foreign-prop-types
    label: Select.propTypes.label.isRequired,
};

/**
 * Display an input language picker.
 *
 * @return {ReactDOM} the input languge selector element.
 */
function InputLanguageMenu({ errorLanguages, isPlatform, onChange, value }) {
    // Get the languages choices source, can be platform or instance.
    const languagesChoicesSource = isPlatform
        ? getCustomerProperty(ConfigConstant.INSTANCE_PROPERTIES.AVAILABLE_LANGS)
        : getCurrentInstanceLangs();

    const languageChoices = map(languagesChoicesSource, (lang) => {
        return {
            isError: includes(errorLanguages, lang),
            label: `LANG_${lang.toUpperCase()}`,
            value: lang,
        };
    });

    return (
        <Select
            ChoiceComponent={ChoiceComponent}
            choices={languageChoices}
            className="input-language btn--flat"
            hasBackground={false}
            name="inputLanguage"
            value={value}
            onChange={onChange}
        />
    );
}

/////////////////////////////

InputLanguageMenu.propTypes = {
    /** The list of languages that containes errors.  */
    errorLanguages: arrayOf(string),
    /** Whether the input language has to use the platform languages as choice. */
    isPlatform: bool,
    /** Called when the `value` changes, with `(value, name, event)`. */
    onChange: func,
    /** Current input language value. */
    value: string,
};

InputLanguageMenu.defaultProps = {
    errorLanguages: null,
    isPlatform: false,
    onChange: noop,
    value: '',
};

/////////////////////////////

export { InputLanguageMenu };
