import React from 'react';

import { Divider } from '@lumapps/lumx/react';
import { PlaylistFieldOrder } from '@lumapps/play-playlists/types';
import { VideoItemFieldOrder } from '@lumapps/play-video/types';

import { DEFAULT_WIDGET_PROPERTIES } from '../../constants';
import { WidgetPlaylistProperties } from '../../types';
import { switchPropertiesOptions } from '../../utils';
import { PlaylistMetadataSelection } from './PlaylistMetadataSelection';
import { PlaylistUploader } from './PlaylistUploader';
import { VideoMetadataSelection } from './VideoMetadataSelection';

import './index.scss';

export interface WidgetPlaySettingsProps {
    properties: WidgetPlaylistProperties;
    onChange: (properties: WidgetPlaylistProperties) => void;
}

const CLASSNAME = 'widget-playlist-settings';

export const WidgetPlaylistSettings = ({ properties, onChange }: WidgetPlaySettingsProps) => {
    const widgetProperties: WidgetPlaylistProperties = { ...DEFAULT_WIDGET_PROPERTIES, ...properties };

    const handlePlaylistFieldOrderChange =
        <K extends keyof WidgetPlaylistProperties>(fieldName: K) =>
        (nextValue: PlaylistFieldOrder) => {
            switchPropertiesOptions<PlaylistFieldOrder>({
                properties: widgetProperties,
                prop: widgetProperties.playlistFieldOrder,
                fieldName,
                callback: onChange,
                nextValue,
            });
        };

    const handleVideoItemFieldChange =
        <K extends keyof WidgetPlaylistProperties>(fieldName: K) =>
        (nextValue: VideoItemFieldOrder) => {
            if (fieldName === 'videoItemFieldOrder') {
                switchPropertiesOptions<VideoItemFieldOrder>({
                    properties: widgetProperties,
                    prop: widgetProperties.videoItemFieldOrder,
                    fieldName,
                    callback: onChange,
                    nextValue,
                });
            } else {
                onChange({
                    ...widgetProperties,
                    [fieldName]: nextValue,
                });
            }
        };

    const onRemovePlaylist = () => {
        onChange({
            ...widgetProperties,
            playlist: { id: '' },
        });
    };

    const { playlist: playlistProperties, playlistFieldOrder, videoItemFieldOrder, maxNumber } = widgetProperties;

    return (
        <div className={CLASSNAME}>
            <PlaylistUploader
                properties={{ playlist: playlistProperties }}
                onChange={(newPlaylist) => onChange({ ...widgetProperties, ...newPlaylist })}
                onPlaylistRemove={onRemovePlaylist}
            />
            <Divider />
            <PlaylistMetadataSelection
                properties={{ playlistFieldOrder }}
                onFieldChange={handlePlaylistFieldOrderChange}
            />
            <Divider />
            <VideoMetadataSelection
                properties={{ videoItemFieldOrder, maxNumber }}
                onFieldChange={handleVideoItemFieldChange}
            />
        </div>
    );
};
