(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsColorPickerController(Style) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Style = Style;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * When the value is emptied or changed.
         */
        function onChange() {
            vm.ngChange();
        }

        /////////////////////////////

        vm.onChange = onChange;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Avoid duplicated entries from color config.
            vm.colors = vm.colors || angular.fastCopy(_.uniq(Style.getCurrent('global').properties.colors));

            // Always have 'transparent' at first in color picker.
            if (!_.includes(vm.colors, 'transparent')) {
                vm.colors.unshift('transparent');
            }

            vm.ngChange = vm.ngChange || angular.noop;
            vm.allowClear = (angular.isUndefined(vm.allowClear)) ? true : vm.allowClear;
            vm.allowTransparent = (angular.isUndefined(vm.allowTransparent)) ? true : vm.allowTransparent;

            if (!vm.allowTransparent) {
                vm.colors = vm.colors.filter(function filterColors(color) {
                    return angular.isDefinedAndFilled(color) && color.toLowerCase() !== 'transparent';
                });
            }
        }

        init();
    }

    /////////////////////////////

    /**
     * The color picker.
     * Displays a color picker to choose a color from the instance's allowed colors.
     *
     * @param {boolean}  [allowClear=true]       Indicates if we want to allow to clear the selected color.
     * @param {boolean}  [allowTransparent=true] Indicates if we want to allow to pick the 'transparent' color.
     * @param {boolean}  [disabled=false]        Indicates if the color picker is disabled.
     * @param {string}   [label='']              The label of the color picker.
     * @param {*}        model                   The Ng-model where to store the picked color.
     * @param {Function} [ngChange]              A function to execute everytime the selected color changes.
     */

    function lsColorPickerDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LsColorPickerController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                allowClear: '<?lsAllowClear',
                allowTransparent: '<?lsAllowTransparent',
                colors: '<?lsColors',
                disabled: '<?ngDisabled',
                label: '@?lsLabel',
                model: '=ngModel',
                ngChange: '&?',
            },
            templateUrl: '/client/common/modules/color-picker/views/color-picker.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsColorPicker', lsColorPickerDirective);
})();
