import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { isMacUser } from '@lumapps/utils/browser/isMacUser';
import { onKey } from '@lumapps/utils/event/onKey';
import { and } from '@lumapps/utils/function/predicate/and';
import { or } from '@lumapps/utils/function/predicate/or';
import { isTable } from '@lumapps/wrex-table/utils/isTable';
import { ALIGNMENTS, PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { Editor, Element, Point, Range, Transforms } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { isEnteringInline } from '@lumapps/wrex/slate/utils/isEnteringInline';
import { isLeavingInline } from '@lumapps/wrex/slate/utils/isLeavingInline';
import { isSelectionCollapsed } from '@lumapps/wrex/slate/utils/isSelectionCollapsed';
import type { Wrex, WrexEditor } from '@lumapps/wrex/types';

import {
    BOLD,
    CODE_BLOCK,
    HEADLINE,
    INLINE_CODE,
    ITALIC,
    STRIKETHROUGH,
    SUBTITLE,
    TITLE,
    TYPOGRAPHY_FEATURES,
    UNDERLINE,
} from '../../constants';
import { TypographyEditor } from '../../types';
import { createParagraph } from '../../utils/createParagraph';
import { isCodeBlock } from '../../utils/isCodeBlock';
import { isInlineCode } from '../../utils/isInlineCode';

const TITLE_BLOCK_TYPES = [HEADLINE, TITLE, SUBTITLE];

const getParentBlock = (editor: Editor) => {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
        return undefined;
    }

    return findParent(editor, selection.focus.path, (node) => Element.isElement(node) && Editor.isBlock(editor, node));
};
/**
 * 'Enter' is pressed at the end of a title block.
 */
const isEnterPressedAtEndOfTitle = (editor: WrexEditor<TypographyEditor>) => (event: KeyboardEvent) => {
    // Enter key pressed.
    if (!isHotKey('Enter')(event)) {
        return false;
    }

    // Has collapsed selection.
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
        return false;
    }

    // Has parent block
    const parentEntry = getParentBlock(editor);
    if (!parentEntry) {
        return false;
    }

    const [parentBlock, parentPath] = parentEntry;

    // At the end of the block
    const atTheEnd = Point.equals(selection.focus, Editor.end(editor, parentPath));
    if (!atTheEnd) {
        return false;
    }

    // In a title block.
    return (TITLE_BLOCK_TYPES as any).includes((parentBlock as Wrex.Element).type);
};

/**
 * 'Enter' is pressed at the end of a code block.
 */
const isEnterPressedWithinCodeBlock = (editor: WrexEditor<TypographyEditor>) => (event: KeyboardEvent) => {
    // Enter key pressed.
    if (!isHotKey('Enter')(event)) {
        return false;
    }

    // Has collapsed selection.
    const { selection } = editor;
    if (!selection) {
        return false;
    }

    // Has parent block
    const parentEntry = getParentBlock(editor);
    if (!parentEntry) {
        return false;
    }

    const [parentBlock] = parentEntry;

    // In a title block.
    return (parentBlock as Wrex.Element).type === CODE_BLOCK;
};

/**
 * 'Enter' is pressed on a void element.
 */
const isEnterPressedOnAVoidElement = (editor: WrexEditor<TypographyEditor>) => (event: KeyboardEvent) => {
    // Enter key pressed.
    if (!isHotKey('Enter')(event)) {
        return false;
    }

    // Has collapsed selection.
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
        return false;
    }

    // Has parent void element
    const parentEntry = findParent(
        editor,
        selection.focus.path,
        (node) => (Element.isElement(node) && Editor.isVoid(editor, node)) || isCodeBlock(node),
    );
    return !!parentEntry;
};

/**
 * 'ArrowUp' or 'ArrowLeft' is pressed on a void element.
 */
const isArrowPressedOnFirstVoidElement = (editor: WrexEditor<TypographyEditor>) => (event: KeyboardEvent) => {
    // Arrow key pressed.
    if (!isHotKey('ArrowLeft')(event) && !isHotKey('ArrowUp')(event)) {
        return false;
    }

    // Has collapsed selection.
    const { selection } = editor;

    if (!selection || !Range.isCollapsed(selection)) {
        return false;
    }

    const node = findParent(
        editor,
        selection.focus.path,
        (node) => (Element.isElement(node) && Editor.isVoid(editor, node)) || isTable(node) || isCodeBlock(node),
    );

    // Has not a void block selected or has not a table block selected
    if (!node) {
        return false;
    }
    const previousEntry = Editor.before(editor, [selection.focus.path[0]]);

    // In table plugin allow detection of arrow in the first row for ArrowUp and in the first column for ArrowLeft
    if (isTable(node[0]) && !previousEntry) {
        const tableRowIndex = selection.focus.path[1];
        const tableColumnIndex = selection.focus.path[2];
        const textLineIndex = selection.focus.path[3];

        if (isHotKey('ArrowUp')(event)) {
            return tableRowIndex === 0 && textLineIndex === 0;
        }

        return tableRowIndex === 0 && tableColumnIndex === 0 && textLineIndex === 0 && selection.focus.offset === 0;
    }

    return !previousEntry;
};

function insertParagraphBlock(editor: WrexEditor<TypographyEditor>) {
    editor.insertNode(createParagraph());
}

/**
 * Initialize key event handler for the typography editor.
 *
 * When pressing 'Enter' at the end of a title, a new paragraph block is created after the title.
 *
 * @param editor          The editor
 */
export const getKeyHandler = (editor: WrexEditor<TypographyEditor>) =>
    onKey(
        [
            {
                match: isEnterPressedWithinCodeBlock(editor),
                handler: () => {
                    editor.insertText('\n');
                },
            },
            {
                match: isEnterPressedAtEndOfTitle(editor),
                handler: () => insertParagraphBlock(editor),
            },
            {
                match: isEnterPressedOnAVoidElement(editor),
                handler: () => insertParagraphBlock(editor),
            },
            {
                match: isArrowPressedOnFirstVoidElement(editor),
                handler: () => {
                    const { selection } = editor;

                    if (!selection) {
                        return;
                    }
                    const node = findParent(
                        editor,
                        selection.focus.path,
                        (node) =>
                            (Element.isElement(node) && Editor.isVoid(editor, node)) ||
                            isTable(node) ||
                            isCodeBlock(node),
                    );

                    if (!node) {
                        return;
                    }

                    Transforms.insertNodes(editor, createParagraph(), { at: [0], select: true });
                },
            },
            // When entering or leaving an inline code, we want to move of one offset instead of a character (default unit).
            // It allows the user to place the cursor just out of the inline code or just at the beginning/end of it.
            {
                match: and(
                    isHotKey('ArrowRight'),
                    () => isSelectionCollapsed(editor),
                    or(isEnteringInline(editor, isInlineCode), isLeavingInline(editor, isInlineCode)),
                ),
                handler: () => {
                    Transforms.move(editor, { unit: 'offset' });
                },
            },
            // When entering or leaving an inline code, we want to move of one offset instead of a character (default unit).
            // It allows the user to place the cursor just out of the inline code or just at the beginning/end of it.
            {
                match: and(
                    isHotKey('ArrowLeft'),
                    () => isSelectionCollapsed(editor),
                    or(isEnteringInline(editor, isInlineCode), isLeavingInline(editor, isInlineCode)),
                ),
                handler: () => {
                    Transforms.move(editor, { unit: 'offset', reverse: true });
                },
            },
            {
                match: isHotKey(isMacUser() ? 'alt+ArrowDown' : 'mod+ArrowDown'),
                handler: () => {
                    if (!editor.selection) {
                        return;
                    }

                    Transforms.move(editor, { distance: 1, unit: 'line' });
                },
            },
            {
                match: isHotKey(isMacUser() ? 'alt+ArrowUp' : 'mod+ArrowUp'),
                handler: () => {
                    if (!editor.selection) {
                        return;
                    }

                    Transforms.move(editor, { distance: 1, unit: 'line', reverse: true });
                },
            },
            // Shortcut to toggle bold
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[BOLD].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.bold),
                ),
                handler: () => {
                    editor.toggleMark(BOLD);
                },
            },
            // Shortcut to toggle italic
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[ITALIC].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.italic),
                ),
                handler: () => {
                    editor.toggleMark(ITALIC);
                },
            },
            // Shortcut to toggle underline
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[UNDERLINE].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.underline),
                ),
                handler: () => {
                    editor.toggleMark(UNDERLINE);
                },
            },
            // Shortcut to toggle strikethrough
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[STRIKETHROUGH].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.strikethrough),
                ),
                handler: () => {
                    editor.toggleMark(STRIKETHROUGH);
                },
            },
            // Shortcut to toggle inline code
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[INLINE_CODE].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.inlineCode),
                ),
                handler: () => {
                    editor.toggleCode();
                },
            },
            // Shortcut to toggle text start align (left)
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[ALIGNMENTS.start].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.alignment),
                ),
                handler: () => {
                    editor.changeTextAlignment(ALIGNMENTS.start);
                },
            },
            // Shortcut to toggle text center align
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[ALIGNMENTS.center].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.alignment),
                ),
                handler: () => {
                    editor.changeTextAlignment(ALIGNMENTS.center);
                },
            },
            // Shortcut to toggle text end align (right)
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[ALIGNMENTS.end].hotkey), () =>
                    editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.alignment),
                ),
                handler: () => {
                    editor.changeTextAlignment(ALIGNMENTS.end);
                },
            },
        ],
        { preventDefault: true },
    );
