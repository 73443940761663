/* istanbul ignore file */
import set from 'lodash/set';
import values from 'lodash/values';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';

import { ContentActionsType, CanBeSharedOnType } from '../../types';

export enum ContentActionStatus {
    initial = 'initial',
    loading = 'loading',
    success = 'success',
    failure = 'failure',
}

export interface SingleContentActionsState extends ContentActionsType {
    status: ContentActionStatus;
}

export interface ContentActionsState {
    [contentId: string]: SingleContentActionsState;
}

export const contentInitialState: SingleContentActionsState = {
    status: ContentActionStatus.initial,
    canMakeShareableOn: [],
    canShareTo: values(SocialNetworks).reduce(
        (acc, socialNetwork) => ({ ...acc, [socialNetwork]: [] }),
        {} as CanBeSharedOnType,
    ),
};

export const initialState: ContentActionsState = {};

export const reducers = {
    // Set the given content as loading and reset it's data
    fetchContentActions: (state: ContentActionsState, action: PayloadAction<{ contentId: string }>) => {
        set(state, action.payload.contentId, { ...contentInitialState, status: ContentActionStatus.loading });
    },
    // On success, update the given content
    fetchContentActionsSuccess: (
        state: ContentActionsState,
        action: PayloadAction<{ contentId: string; contentActions: ContentActionsType }>,
    ) => {
        const { contentActions, contentId } = action.payload;

        set(state, `${contentId}.status`, ContentActionStatus.success);
        set(state, `${contentId}.canMakeShareableOn`, contentActions.canMakeShareableOn);
        set(state, `${contentId}.canShareTo`, contentActions.canShareTo);
    },
    fetchContentActionsFailure: (state: ContentActionsState, action: PayloadAction<{ contentId: string }>) => {
        const { contentId } = action.payload;
        set(state, `${contentId}.status`, ContentActionStatus.failure);
    },
    resetContentActions: (state: ContentActionsState, action: PayloadAction<{ contentId: string }>) => {
        set(state, action.payload.contentId, contentInitialState);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'ContentActions',
    initialState,
    reducers,
});
