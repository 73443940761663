import React, { useMemo, useState, useCallback } from 'react';
import { Button, Dialog, Toolbar, Emphasis } from '@lumapps/lumx/react';
import isEmpty from 'lodash/isEmpty';

import { translate as t } from 'components/translations';
import { cancelCurrentQuery } from 'components/services/gTranslate';

import { LanguageSelector } from 'components/components/ui/language-selector';

const WidgetTranslationDialog = ({
    closeModal,
    widget,
    sourceLang,
    inputLanguage,
    isOpen,
    openModalBtnRef,
    updateWidgetContent,
    isLoading,
    requestTranslation,
    setSourceLang,
}) => {
    const widgetContent = widget && widget.properties.content;
    const isContentHTML = widget.widgetType === 'html';
    const availableLangs =
        (widgetContent &&
            Object.keys(widgetContent).filter((lang) => lang !== inputLanguage && !isEmpty(widgetContent[lang]))) ||
        [];

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const onCancel = () => {
        cancelCurrentQuery();
    };

    const onCloseModal = useCallback(() => {
        if (isLoading) {
            onCancel();
        }

        closeModal();
        setDropdownOpen(false);
    }, [closeModal, isLoading]);

    const onTranslationRequest = useCallback(() => {
        requestTranslation({
            content: widgetContent[sourceLang],
            isContentHTML,
            onSuccess: (response) => {
                updateWidgetContent({ content: response.contentText, lang: inputLanguage });
                onCloseModal();
            },
            sourceLang,
            targetLang: inputLanguage,
        });
    }, [
        inputLanguage,
        isContentHTML,
        onCloseModal,
        requestTranslation,
        sourceLang,
        updateWidgetContent,
        widgetContent,
    ]);

    const onInsert = useCallback(() => {
        updateWidgetContent({ content: widgetContent[sourceLang], lang: inputLanguage });
        onCloseModal();
    }, [inputLanguage, onCloseModal, sourceLang, updateWidgetContent, widgetContent]);

    /**
     * Updates the sourceLang.
     *
     * @param {string} lang The selected language.
     */
    const onLanguageChange = (lang) => {
        setSourceLang({ sourceLang: lang });
        setDropdownOpen(false);
    };

    const footerBtns = useMemo(
        () => (
            <>
                <Button disabled={isLoading} emphasis={Emphasis.medium} onClick={onInsert}>
                    {t('FRONT.CONTENT_TRANSLATION.DIALOG.INSERT_CONTENT')}
                </Button>
                <Button
                    className="lumx-spacing-margin-left-regular"
                    disabled={isLoading}
                    onClick={onTranslationRequest}
                >
                    {t('FRONT.CONTENT_TRANSLATION.DIALOG.TRANSLATE_AND_INSERT')}
                </Button>
            </>
        ),
        [isLoading, onInsert, onTranslationRequest],
    );

    return (
        <Dialog
            preventAutoClose
            footer={
                <Toolbar
                    after={footerBtns}
                    before={
                        <Button emphasis={Emphasis.medium} onClick={isLoading ? onCancel : onCloseModal}>
                            {t(isLoading ? 'GLOBAL.CANCEL' : 'GLOBAL.CLOSE')}
                        </Button>
                    }
                />
            }
            header={
                <Toolbar
                    after={
                        <div className="widget-translation-dialog__language-picker-container">
                            <LanguageSelector
                                availableLangs={availableLangs}
                                currentLang={sourceLang}
                                isOpen={isDropdownOpen}
                                onChange={onLanguageChange}
                                onClick={() => {
                                    setDropdownOpen(!isDropdownOpen);
                                }}
                                onClose={() => setDropdownOpen(false)}
                            />
                            <span className="lumx-typography-subtitle1 lumx-spacing-margin-left-regular">
                                {`${t('FRONT.CONTENT_TRANSLATION.DIALOG.TO').toLowerCase()} ${t(
                                    `LANG_${inputLanguage.toUpperCase()}`,
                                ).toLowerCase()}`}
                            </span>
                        </div>
                    }
                    label={<span className="lumx-typography-title">{t('FRONT.CONTENT_TRANSLATION.DIALOG.TITLE')}</span>}
                />
            }
            isLoading={isLoading}
            isOpen={isOpen}
            parentElement={openModalBtnRef}
            onClose={onCloseModal}
        >
            <div className="widget-translation-dialog__content">
                <div
                    className="widget-html__content fr-element lumx-spacing-padding-horizontal-huge"
                    dangerouslySetInnerHTML={{ __html: widgetContent[sourceLang] }}
                />
            </div>
        </Dialog>
    );
};

export { WidgetTranslationDialog };
