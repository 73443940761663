import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiPlus } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { Link } from '../../types';
import { LinkDialog, LinkDialogProps } from '../LinkDialog';

/**
 * Component that displays a button for creating a new link and triggers the dialog for creating a link dialog.
 */
export const AddLinkButton: React.FC<LinkDialogProps> = ({
    onSubmit,
    isOpen: isDefaultOpen = false,
    onClose,
    ...props
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('widget-link');

    React.useEffect(() => {
        setIsOpen(isDefaultOpen);
    }, [isDefaultOpen]);

    return (
        <>
            <IconButton
                label={translateKey(GLOBAL.ADD)}
                onClick={() => setIsOpen(true)}
                emphasis={Emphasis.low}
                icon={mdiPlus}
                {...get({
                    element: 'button',
                    action: 'add',
                })}
            />
            <LinkDialog
                {...props}
                onClose={() => {
                    setIsOpen(false);

                    if (onClose) {
                        onClose();
                    }
                }}
                isOpen={isOpen}
                onSubmit={(link: Link) => {
                    onSubmit(link);
                    setIsOpen(false);
                }}
            />
        </>
    );
};
