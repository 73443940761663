import React from 'react';

import { mdiVideo } from '@lumapps/lumx/icons';
import { DialogSizes } from '@lumapps/lumx/react';
import { VideoWebsites } from '@lumapps/medias/types';
import { getVideoId, getVideoUrlWebsiteName } from '@lumapps/medias/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isUrl } from '@lumapps/utils/string/isUrl';

import { useLinkPreview } from '../../hooks/useLinkPreview';
import { PREVIEW } from '../../keys';
import type { LinkPreviewMetadata } from '../../types';
import { InsertUrlPreviewDialog } from './InsertUrlPreviewDialog';
import { useValidationState } from './useValidationState';

const CLASSNAME = 'insert-video-dialog';

export interface InsertVideoDialogProps {
    /** Cancel button callback. */
    onClose(): void;
    /** Confirm button callback. */
    onInsert(link: LinkPreviewMetadata): void;
    /** Open/close dialog. */
    isOpen?: boolean;
    /** Dialog size. */
    size?: DialogSizes;
    /** Provide a message in to put inside the */
    children?: React.ReactNode;
}

/**
 * Component that displays a dialog in order to enter a video URL.
 *
 * @family Dialog
 * @param InsertVideoDialogProps
 * @returns InsertVideoDialog
 */
export const InsertVideoDialog: React.FC<InsertVideoDialogProps> = (props) => {
    const { onClose, onInsert, isOpen } = props;
    const [url, setUrl] = React.useState('');
    const { isValid: isUrlValid, isDirty: isUrlDirty } = useValidationState(
        url,
        (urlToCheck) =>
            Boolean(isUrl(urlToCheck)) &&
            Boolean(getVideoUrlWebsiteName(urlToCheck)) &&
            Boolean(getVideoId(urlToCheck, getVideoUrlWebsiteName(urlToCheck) as VideoWebsites)),
    );
    const { preview, previewError, isPreviewLoading, onPreviewThumbnailSwitch, fetchPreview } = useLinkPreview(
        url,
        isUrlValid && Boolean(url),
    );

    const { translateKey } = useTranslate();
    const propsToPass = {
        setUrl,
        url,
        isUrlDirty,
        isUrlValid,
        preview,
        previewError,
        isPreviewLoading,
        onPreviewThumbnailSwitch,
        fetchPreview,
    };

    return (
        <InsertUrlPreviewDialog
            isOpen={isOpen}
            title={translateKey(PREVIEW.INSERT_VIDEO_DIALOG_TITLE)}
            inputIcon={mdiVideo}
            inputErrorMessage={
                url
                    ? translateKey(PREVIEW.INSERT_VIDEO_DIALOG_URL_FIELD_ERROR_BAD_FORMAT)
                    : translateKey(GLOBAL.ERROR_MANDATORY_FIELD)
            }
            inputLabel={translateKey(PREVIEW.INSERT_VIDEO_DIALOG_URL_FIELD_LABEL)}
            inputHelper={translateKey(PREVIEW.INSERT_VIDEO_DIALOG_URL_FIELD_HELPER)}
            {...propsToPass}
            onClose={onClose}
            onInsert={onInsert}
            classNamePrefix={CLASSNAME}
        />
    );
};
InsertVideoDialog.displayName = 'InsertVideoDialog';
