/* eslint-disable no-param-reassign */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Route } from '../types';
import { RouterStatus, RouterState, RouterData } from './types';

const initialState: RouterState = {
    status: RouterStatus.idle,
    data: {},
    currentPage: '',
    pageTitle: '',
};

export const reducers = {
    setStatus: (state: RouterState, action: PayloadAction<RouterStatus>) => {
        set(state, 'status', action.payload);
    },
    setPageTitle: (state: RouterState, action: PayloadAction<string>) => {
        set(state, 'pageTitle', action.payload);
    },
    showLoader: (state: RouterState) => {
        set(state, 'status', RouterStatus.loading);
    },
    hideLoader: (state: RouterState) => {
        set(state, 'status', RouterStatus.idle);
    },
    setBlockedRedirection: (state: RouterState, action: PayloadAction<{ route: Route; history: any }>) => {
        set(state, ['blockedRedirection', 'blockedRoute'], action.payload.route);
        set(state, ['blockedRedirection', 'history'], action.payload.history);
    },
    blockRedirection: (state: RouterState) => {
        set(state, 'blockedRedirection', {
            blockingRoute: window.location.href,
        });
    },
    unblockRedirection: (state: RouterState) => {
        delete state.blockedRedirection;
    },
    clearData: (state: RouterState, action: PayloadAction<RouterData['id']>) => {
        const id = action.payload;

        if (state.data[id]) {
            delete state.data[id];
        }
    },
    setData: (state: RouterState, action: PayloadAction<RouterData>) => {
        const { id, data, mergeData } = action.payload;

        /**
         * If we are not merging the data or the state is empty,
         * we just go ahead and assign the given data to the store.
         */
        if (!mergeData || !state.data[id]) {
            set(state, ['data', id], data);
        } else {
            /**
             * Else, it means that we are merging data and there is something for
             * the given namespace. In that case, we merge
             */
            Object.assign(state.data[id], data);
        }
    },
    setCurrentPage: (state: RouterState, action: PayloadAction<string>) => {
        set(state, 'currentPage', action.payload);
    },
};

const { actions, reducer } = createSlice({
    domain: 'router',
    initialState,
    reducers,
});

export { actions, reducer };
