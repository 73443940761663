(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityRequestController(Community, CommunityRequest, ModuleAdmin) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.CommunityRequest = CommunityRequest;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add the user that initiated the access request to the list of users that can contribute to the community.
         */
        function addRequestUserToCommunity() {
            var community = Community.getCurrent(ModuleAdmin.getListKey());

            if (vm.request.communityKey === community.id) {
                community.usersDetails = community.usersDetails || [];
                community.usersDetails.push(vm.request.userDetails);

                if (angular.isUndefined(community.userKeys)) {
                    community.userKeys = [];
                }
                community.userKeys.push(vm.request.userKey);

                if (angular.isFunction(vm.onApprove)) {
                    vm.onApprove();
                }
            }
        }

        /////////////////////////////

        vm.addRequestUserToCommunity = addRequestUserToCommunity;

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            if (angular.isUndefinedOrEmpty(vm.listKey) &&
                angular.isDefinedAndFilled(_.get(vm.request, 'communityKey'))) {
                vm.listKey = 'community-requests-' + vm.request.communityKey;
            }
        }

        init();
    }

    /////////////////////////////

    function CommunityRequestDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: CommunityRequestController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                listKey: '=?lsListKey',
                onApprove: '&?lsOnApprove',
                onRefuse: '&?lsOnRefuse',
                request: '=lsRequest',
            },
            templateUrl: '/client/front-office/modules/communities/community/views/community-request.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsCommunityRequest', CommunityRequestDirective);
})();
