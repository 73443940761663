import * as React from 'react';

import { string, arrayOf, shape } from 'prop-types';
import { Mosaic, LinkPreview, Size } from '@lumapps/lumx/react';
import truncate from 'lodash/truncate';
import isEmpty from 'lodash/isEmpty';

import { SHAREABLE_CONTENT_TYPES } from 'components/components/social-advocacy/ducks/shareableContents/constants';

const MAX_TITLE_LENGTH = 80;

/**
 * Display the meta data of an URL.
 *
 * @param  {Array<string>} image   A string.
 * @param  {string}        title   Title of the page.
 * @param  {string}        url     URL of the page.
 * @param  {Object}        classes JSS styles.
 * @return {React.Element} React Element.
 */
const SALinkPreviewLarge = ({ images, title, url, description, type }) => {
    const hasTitle = !isEmpty(title);
    const hasUrl = !isEmpty(url);
    const hasImages = !isEmpty(images.filter((image) => image.url));

    return (
        <>
            {type === SHAREABLE_CONTENT_TYPES.images && hasImages && (
                <>
                    <Mosaic
                        thumbnails={images.map((image) => ({
                            image: image.url,
                        }))}
                    />
                    {hasTitle && (
                        <div className="lumx-typography-subtitle1 lumx-spacing-margin-top">
                            {truncate(title, { length: MAX_TITLE_LENGTH, separator: ' ' })}
                        </div>
                    )}
                </>
            )}

            {type === SHAREABLE_CONTENT_TYPES.link && hasUrl && (
                <LinkPreview
                    size={Size.big}
                    link={url}
                    title={title}
                    description={description}
                    thumbnailProps={
                        hasImages
                            ? {
                                  image: images[0].url,
                                  alt: description,
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
};

SALinkPreviewLarge.propTypes = {
    description: string,
    images: arrayOf(
        shape({
            type: string,
            url: string,
        }),
    ),
    title: string,
    url: string,
};

SALinkPreviewLarge.defaultProps = {
    description: null,
    images: [],
    title: null,
    url: null,
};

export { SALinkPreviewLarge };
