import React, { useState } from 'react';

import { classnames } from '@lumapps/classnames';
import { currentLanguageSelector, getLanguages } from '@lumapps/languages';
import { FormCheckboxField } from '@lumapps/lumx-forms/components/FormCheckboxField';
import { FormTextField } from '@lumapps/lumx-forms/components/FormTextField';
import { FormURLTextField } from '@lumapps/lumx-forms/components/FormURLTextField';
import {
    MultiLanguageFormDialog,
    MultiLanguageFormDialogProps,
} from '@lumapps/lumx-forms/components/MultiLanguageFormDialog';
import { Image } from '@lumapps/lumx-images/types';
import { FlexBox, Heading } from '@lumapps/lumx/react';
import { getImageUrlFromMedia } from '@lumapps/medias/utils';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isRelativeUrlValid } from '@lumapps/utils/string/isUrl';

import { WIDGET_LINKS } from '../../keys';
import { Link } from '../../types';
import { LinkUploadImage } from '../LinkUploadImage';

export interface LinkDialogProps
    extends Omit<MultiLanguageFormDialogProps, 'heading' | 'scope' | 'defaultLanguage' | 'languages'> {
    /** link to edit */
    link?: Link;
    /** force default language to a specific one */
    lang?: string;
}

const emptyLink: Link = {
    description: {},
    link: {},
    targetBlank: true,
    thumbnail: undefined,
    title: {},
};

const CLASSNAME = 'link-creation-dialog';

/**
 * Link creation dialog for the widget links
 * @param LinkDialogProps
 * @returns LinkDialog
 */
export const LinkDialog: React.FC<LinkDialogProps> = ({ className, link, onSubmit, lang, ...props }) => {
    const { translateKey, translate } = useTranslate();

    const currentLanguage = useSelector(currentLanguageSelector);
    const languages = useSelector(getLanguages);

    const [isImageUploading, setIsImageUploading] = useState(false);

    const languageToUse = lang || currentLanguage;

    /**
     * Since the thumbnail format expected by the form differs from the one returned by the API,
     * we do a small conversion before initialising the dialog.
     */
    const image: Image | null =
        link?.thumbnail && link?.blobKey
            ? {
                  id: link.blobKey,
                  url: link.thumbnail,
              }
            : null;

    /**
     * Callback on link submit. This manages the conversion back to the expected value for the
     * thumbnail.
     */
    const onLinkSubmit = (submittedLink: Link) => {
        const { image } = submittedLink;

        if (image) {
            Object.assign(submittedLink, {
                blobKey: image.id,
                thumbnail: image.rawMedia ? getImageUrlFromMedia(image.rawMedia, translate) : image.url,
            });
        }

        onSubmit(submittedLink);
    };

    return (
        <MultiLanguageFormDialog
            key={languageToUse}
            className={classnames(className, CLASSNAME)}
            {...props}
            heading={
                <Heading as="h2" typography="title">
                    {translateKey(WIDGET_LINKS.DIALOG_TITLE)}
                </Heading>
            }
            form={{
                defaultValues: link ? { ...link, image } : emptyLink,
            }}
            formActionProps={{ ...(props.formActionProps ?? {}), isDisabled: isImageUploading }}
            defaultLanguage={languageToUse}
            languages={languages}
            scope="widget-links-dialog"
            onSubmit={onLinkSubmit}
        >
            <FlexBox orientation="horizontal" gap="huge" hAlign="top">
                <LinkUploadImage
                    onImageSelected={() => setIsImageUploading(true)}
                    onImageUploaded={() => setIsImageUploading(false)}
                />

                <FlexBox orientation="vertical" gap="huge" fillSpace>
                    <FormURLTextField
                        label={translateKey(GLOBAL.URL)}
                        name="link"
                        isMultiLanguage
                        isRequired
                        urlValidator={isRelativeUrlValid}
                    />
                    <FormTextField name="title" isMultiLanguage isRequired label={translateKey(GLOBAL.TITLE)} />
                    <FormTextField
                        name="description"
                        isMultiLanguage
                        multiline
                        label={translateKey(GLOBAL.DESCRIPTION)}
                    />
                    <FormCheckboxField name="targetBlank" label={translateKey(WIDGET_LINKS.ADD_TARGET_BLANK)} />
                </FlexBox>
            </FlexBox>
        </MultiLanguageFormDialog>
    );
};
