import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import { utc } from 'moment';

import { BaseSearchResult } from '@lumapps/search/types';
import { TranslationAPI } from '@lumapps/translations/types';

import { props as contentMetadataProps } from '../../ContentMetadata/props';

export interface CalendarPropsOptions {
    translate: TranslationAPI['translate'];
}

/**
 * Format the start and end dates of a Google Calendar event.
 *
 * @param  {string} start The start date of the event.
 * @param  {string} [end] The end date of the event.
 * @return {string} The formatted dates for the Google Calendar event.
 */
const getEventDates = (start: string, end: string) => {
    const startDate = utc(start);
    const endDate = utc(end);
    const startDateMidnight = utc(start).startOf('day');
    const endDateMidnight = utc(end).startOf('day');

    const dates = [];

    /*
     * Single full day event.
     *
     * Note: for now the API doesnt tell us if it's a full day event or not.
     * We just assume that if the event starts at midnight and ends at midnight the following day, then it's a full
     * day event.
     */
    if (
        startDate.isSame(startDateMidnight) &&
        endDate.isSame(endDateMidnight) &&
        endDate.diff(startDate, 'days') === 1
    ) {
        dates.push({
            datetime: startDate.format(),
            formattedDate: startDate.format('MMM DD'),
        });
    } else if (startDate.isSame(endDate, 'day')) {
        // Single day with time.
        dates.push(
            {
                datetime: startDate.format(),
                formattedDate: startDate.format('MMM DD, h:mm A'),
            },
            {
                datetime: endDate.format(),
                formattedDate: endDate.format('h:mm A'),
            },
        );
        // Multiple full days event.
    } else if (startDate.isSame(startDateMidnight) && endDate.isSame(endDateMidnight)) {
        dates.push(
            {
                datetime: startDate.format(),
                formattedDate: startDate.format('MMM DD'),
            },
            {
                datetime: endDate.format(),
                formattedDate: endDate.format('MMM DD'),
            },
        );
    } else {
        // Multiple days with time.
        dates.push(
            {
                datetime: startDate.format(),
                formattedDate: startDate.format('MMM DD, h:mm A'),
            },
            {
                datetime: endDate.format(),
                formattedDate: endDate.format('MMM DD, h:mm A'),
            },
        );
    }

    return dates;
};

export const props = (result: BaseSearchResult, baseProps: any, options: CalendarPropsOptions) => {
    const { url, name, snippet, onClick, sourceUid, flag, quickViewUrl, onQuickViewClick } = baseProps;
    const bProps = {
        url,
        name,
        snippet,
        flag,
        quickViewUrl,
        onQuickViewClick,
    };

    const { metadata } = result;
    const { fields } = metadata;

    const startTime = find(fields, {
        name: 'startTime',
    });
    const endTime = find(fields, {
        name: 'endTime',
    });

    const start = first(get(startTime, 'timestampValues.values', []));
    const end = first(get(endTime, 'timestampValues.values', []));

    return {
        ...bProps,
        id: `${sourceUid}-${encodeURIComponent(name)}`,
        metadata: contentMetadataProps(result, options),
        title: name,
        dates: getEventDates(start as string, end as string),
        position: result.position,
        onClick,
        iconName: 'calendar',
    };
};
