import * as React from 'react';
import * as propTypes from 'prop-types';

/**
 * Renders a tab content, used by `<Tabs />`.
 */
export class Tab extends React.PureComponent {
    static propTypes = {
        /** Tab content. */
        children: propTypes.any,
        /** Tab icon id displayed in `<Tabs />`. See `<widgets.Icon />`. */
        icon: propTypes.string,
        /** Tab identifier. */
        id: propTypes.string.isRequired,
        /** Tab label displayed in `<Tabs />` */
        label: propTypes.string,
    };

    render() {
        const { children } = this.props;

        return <div className="custom-tabs__pane">{children}</div>;
    }
}
