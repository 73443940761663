import { getParameterByName } from '@lumapps/router/utils';

import { SEARCH_GROUPS_RESTRICTIONS_PARAM } from '../constants';

/**
 * Check in the url if the param useGroupsRestrictions
 * @returns if we should use groups restrictions or not
 */
export const shouldUseGroupsRestrictions = (): boolean => {
    const param = getParameterByName(SEARCH_GROUPS_RESTRICTIONS_PARAM);

    return param === 'true' || param === '';
};
