import { connect } from '@lumapps/redux/react';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';

import { setFormFieldValue } from '../../../ducks/shareableWorkflow/actions';

import {
    getShareableWorkflowIncompatibleNetworks,
    getAvailableSocialNetworks,
    makeShareableworkflowFormFieldelector,
} from '../../../ducks/shareableWorkflow/selectors';

import { SocialNetworksSelect } from './SocialNetworksSelect';

type FieldSelectorType = (fieldName: string) => {
    hasError?: boolean;
    errorMessage?: string;
    value: Record<SocialNetworks, boolean>;
};

const mapStateToProps = (state: any) => {
    const fieldSelector = makeShareableworkflowFormFieldelector(state) as FieldSelectorType;

    /*
     * Until backend is done we need this part to filter facebook
     */
    // @ts-ignore
    const { facebook, ...selectedSocialNetworks } = fieldSelector('shareableOn');

    return {
        selectedSocialNetworks,
        availableSocialNetworks: getAvailableSocialNetworks(state),
        incompatibleSocialNetworks: getShareableWorkflowIncompatibleNetworks(state),
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onChange: (value: any) => dispatch(setFormFieldValue('shareableOn', value)),
});

const ConnectedSocialNetworksSelect = connect(mapStateToProps, mapDispatchToProps)(SocialNetworksSelect);

export { ConnectedSocialNetworksSelect as SocialNetworksSelect };
