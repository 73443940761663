import { useMemo } from 'react';

import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { useTranslate } from '@lumapps/translations';
import { getMoment } from '@lumapps/utils/time/getMoment';
import { getCurrentTimezoneFormated } from '@lumapps/utils/timezones/getCurrentTimezone';
import { DITA } from '@lumapps/wrex/serialization/dita/types';
import { featuredImageFromDita } from '@lumapps/wrex/serialization/dita/utils/featuredImageFromDita';

import { Event, LayoutEvent } from '../api/types';
import { eventView } from '../routes';

export const useEventPreview = (event: Event | LayoutEvent, isLegacy?: boolean) => {
    const moment = getMoment();

    const { translateObject } = useTranslate();

    const eventRoute = useMemo(() => eventView(event.id), [event.id]);

    const imageInfos = () => {
        if (isLegacy) {
            const featuredImage = featuredImageFromDita(
                translateObject((event as Event).structuredContent.dita?.translations) as DITA,
            );
            return featuredImage
                ? {
                      href: eventRoute,
                      alt: featuredImage && featuredImage.alt,
                      url: featuredImage && (makeSecuredMediaURLRelative(featuredImage.url) as string),
                      focusPoint: featuredImage && featuredImage.focalPoint,
                  }
                : undefined;
        }
        return (event as LayoutEvent).image
            ? {
                  href: eventRoute,
                  url: (event as LayoutEvent).image,
                  focusPoint: (event as LayoutEvent).focalPoint,
              }
            : undefined;
    };

    const title = !isLegacy
        ? (event as LayoutEvent).title
        : translateObject((event as Event).structuredContent.title?.translations);

    const formatedTimezone = getCurrentTimezoneFormated();

    const formatedStartsAt = moment.utc(event.startsAt).local().format('LLLL');
    const formatedEndsAt = moment.utc(event.endsAt).local().format('LLLL');

    const intro = !isLegacy
        ? (event as LayoutEvent).description
        : translateObject((event as Event).structuredContent.intro?.translations);

    return {
        id: event.id,
        eventRoute,
        image: imageInfos(),
        title,
        startDate: {
            startsAt: event.startsAt,
            formatedStartsAt,
            formatedTimezone,
        },
        endDate: formatedEndsAt,
        duration: event.duration,
        location: event.location,
        externalUrl: event.externalUrl,
        externalUrlLabel: event.externalUrlLabel,
        intro,
        currentRegistrationStatus: event?.registrationStatus,
        organizers: event?.organizers,
        remainingSpots: event?.remainingSpots,
        hasLimitedCapacity: event?.hasLimitedCapacity,
        hasRegistrationEnabled: event?.hasRegistrationEnabled ?? true,
    };
};
