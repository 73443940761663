(function IIFE() {
    'use strict';

    /////////////////////////////

    function StepperDialogController($scope, StepperDialog) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.StepperDialog = StepperDialog;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Listen to the end of the scroll in this dialog.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering this method.
         */
        $scope.$on('lx-dialog__scroll-end', function onStepperDialogScrollEnd(evt, dialogId) {
            if (vm.dialogId === dialogId && angular.isFunction(vm.onscrollend)) {
                vm.onscrollend();
            }
        });

        /**
         * When the dialog opens, reset all the steps status.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering this method.
         */
        $scope.$on('lx-dialog__open-start', function onStepperDialogScrollEnd(evt, dialogId) {
            if (vm.dialogId === dialogId) {
                StepperDialog.activeStep = 0;

                StepperDialog.isStep.first = true;
                StepperDialog.isStep.last = false;
                StepperDialog.isStep.loading = false;
            }
        });

        /**
         * When the stepper indicates the activation of a step, update the current step status and the current step
         * index.
         *
         * @param {Event}   evt   The step event.
         * @param {string}  id    The id of the stepper.
         * @param {number}  index The index of the step being activated.
         * @param {boolean} first Indicates if the step is the first one.
         * @param {boolean} last  Indicates if the step is the last one.
         */
        $scope.$on('ls-stepper__step', function onStepperDialogFirstStep(evt, id, index, first, last) {
            if (id !== vm.dialogId) {
                return;
            }

            StepperDialog.activeStep = index;

            StepperDialog.isStep.first = first;
            StepperDialog.isStep.last = last;
        });

        /**
         * When the stepper indicates the beginning of a step's loading (validation), update the current step status.
         *
         * @param {Event}  evt   The step event.
         * @param {string} id    The id of the stepper.
         * @param {number} index The index of the step being loaded.
         */
        $scope.$on('ls-stepper__step-loading', function onStepperDialogStepLoading(evt, id, index) {
            if (id === vm.dialogId && StepperDialog.activeStep === index) {
                StepperDialog.isStep.loading = true;
            }
        });

        /**
         * When the stepper indicates the end of a step's loading (validation), update the current step status.
         *
         * @param {Event}  evt   The step event.
         * @param {string} id    The id of the stepper.
         * @param {number} index The index of the step that has been loaded.
         */
        $scope.$on('ls-stepper__step-loaded', function onStepperDialogStepLoaded(evt, id, index) {
            if (id === vm.dialogId && StepperDialog.activeStep === index) {
                StepperDialog.isStep.loading = false;
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.autoClose = (angular.isUndefined(vm.autoClose)) ? true : vm.autoClose;
            vm.displayCancelButton = (angular.isUndefined(vm.displayCancelButton)) ? true : vm.displayCancelButton;
            vm.displayConfirmButton = (angular.isUndefined(vm.displayConfirmButton)) ? true : vm.displayConfirmButton;
            vm.inputLanguage = vm.inputLanguage || false;

            vm.lsLayout = (angular.isUndefined(vm.lsLayout)) ? 'horizontal' : vm.lsLayout;
        }

        init();
    }

    /**
     * Default dialog directive. Used for all basic dialogs within the application with no complex custom logic.
     *
     * @param {boolean}  [autoClose=true]            Indicates if the dialog should be closed when clicking outside of
     *                                               the dialog wrapper.
     *                                               Defaults to true.
     * @param {string}   [backLabel]                 The text to use for the back button.
     *                                               If none is given, take the one in "lsLabels".
     * @param {string}   [cancelLabel]               The text to use for the cancel button.
     *                                               If none is given, take the one in "lsLabels".
     * @param {string}   [confirmLabel]              The text to use for the confirm button.
     *                                               If none is given, take the one in "lsLabels".
     * @param {string}   [continueLabel]             The text to use for the continue button.
     *                                               If none is given, take the one in "lsLabels".
     * @param {string}   [deleteLabel]               The text to use for the delete button.
     *                                               If none is given, take the one in "lsLabels".
     * @param {string}   dialogId                    The identifier of the dialog.
     * @param {string}   [dialogTitle]               The text to use as title of the dialog.
     * @param {boolean}  [displayCancelButton=true]  Indicates if we should display the cancel button or not.
     *                                               Defaults to true.
     * @param {boolean}  [displayConfirmButton=true] Indicates if we should display the confirm button or not.
     * @param {boolean}  [displayDeleteButton=false] Indicates if we should display the delete button or not.
     * @param {boolean}  [inputLanguage=false]       Indicates if we should display the language switch or not.
     *                                               Defaults to false.
     * @param {boolean}  [isDisabled]                Indicates if the action buttons should be disabled or not.
     * @param {boolean}  [isLoading]                 Indicates if the dialog is loading or not.
     * @param {boolean}  [lsIsLinear]                The 'isLinear' option for the stepper.
     * @param {boolean}  [lsLabels]                  The 'labels' option for the stepper.
     * @param {boolean}  [lsLayout]                  The 'layout' option for the stepper.
     * @param {Function} [oncancel]                  A function to execute when the cancel button is pressed.
     * @param {Function} [onconfirm]                 A function to execute when the confirm button is pressed.
     * @param {Function} [ondelete]                  A function to execute when the delete button is pressed.
     * @param {Function} [onscrollend]               A function to execute when the user is done scrolling the dialog.
     * @param {string}   [size]                      Specify the size of the dialog.
     *                                               Can be: 'l', 'm' or 's'.
     * @param {boolean}  [stepSave=false]            Indicates if we allow to directly save to each step when in non
     *                                               linear mode.
     */

    function StepperDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: StepperDialogController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                autoClose: '=?lsAutoClose',
                backLabel: '@?lsBackLabel',
                cancelLabel: '@?lsCancelLabel',
                confirmLabel: '@?lsConfirmLabel',
                continueLabel: '@?lsContinueLabel',
                deleteLabel: '@?lsDeleteLabel',
                dialogId: '@lsDialogId',
                dialogTitle: '@?lsDialogTitle',
                displayCancelButton: '=?lsDisplayCancelButton',
                displayConfirmButton: '=?lsDisplayConfirmButton',
                displayDeleteButton: '=?lsDisplayDeleteButton',
                inputLanguage: '=?lsInputLanguage',
                isDisabled: '=?lsIsDisabled',
                isLoading: '=?lsIsLoading',
                lsIsLinear: '<?',
                lsLabels: '=?',
                lsLayout: '@?',
                oncancel: '&?lsOnCancel',
                onconfirm: '&?lsOnConfirm',
                ondelete: '&?lsOnDelete',
                onscrollend: '&?lsOnScrollEnd',
                save: '&?lsSave',
                size: '@?',
                stepSave: '<?lsStepSave',
            },
            templateUrl: '/client/common/modules/stepper-dialog/views/stepper-dialog.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsStepperDialog', StepperDialogDirective);
})();
