import React from 'react';

import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';

import { classnames, useClassnames } from '@lumapps/classnames';
import { SelectButton } from '@lumapps/combobox/components/SelectButton';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiCheck, mdiClose, mdiHelp } from '@lumapps/lumx/icons';
import { ButtonProps, ButtonSize, Emphasis, FlexBox, Icon, Orientation, Size, Text, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { RegistrationStatusTypes } from '../../api/types';
import { EVENTS } from '../../keys';
import { RegisterButtonOption, RegisterButtonOptions, RegistrationStatuses } from '../../types';

import './index.scss';

const CLASSNAME = 'registration-button';

export interface RegistrationButtonProps {
    registerToEvent: (status: RegistrationStatusTypes) => void;
    registrationStatus: RegistrationStatusTypes;
    remainingSpots?: number;
    theme?: Theme;
    size?: ButtonSize;
    fullWidth?: ButtonProps['fullWidth'];
    isUserOrganizer: boolean;
    hasLimitedCapacity: boolean;
    className?: string;
}

export const RegistrationButton = ({
    registerToEvent,
    registrationStatus,
    remainingSpots,
    theme,
    fullWidth,
    size = Size.s,
    isUserOrganizer,
    hasLimitedCapacity,
    className,
}: RegistrationButtonProps) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey, translateAndReplace, pluralize } = useTranslate();
    const { get } = useDataAttributes('event-registration');

    const isEventFull =
        !hasLimitedCapacity && isUndefined(remainingSpots) ? false : remainingSpots === 0 && !isUserOrganizer;
    const unansweredFullEvent = isEventFull && registrationStatus === RegistrationStatuses.unanswered;

    const registerButtonOptions: RegisterButtonOptions = React.useMemo(() => {
        return {
            [RegistrationStatuses.unanswered]: {
                label: translateKey(EVENTS.ATTEND),
                status: RegistrationStatuses.unanswered,
                className: element('unanswered'),
            },
            [RegistrationStatuses.going]: {
                label: translateKey(EVENTS.GOING),
                leftIcon: mdiCheck,
                status: RegistrationStatuses.going,
                className: element('going'),
                isDisabled: isEventFull && registrationStatus !== RegistrationStatuses.going,
            },
            [RegistrationStatuses.notGoing]: {
                label: translateKey(EVENTS.NOT_GOING),
                leftIcon: mdiClose,
                status: RegistrationStatuses.notGoing,
                isDisabled: false,
                className: element('not-going'),
            },
            [RegistrationStatuses.maybe]: {
                label: translateKey(EVENTS.MAYBE),
                leftIcon: mdiHelp,
                status: RegistrationStatuses.maybe,
                className: element('maybe'),
                isDisabled: isEventFull && registrationStatus === RegistrationStatuses.notGoing,
            },
        };
    }, [translateKey, isEventFull, registrationStatus, element]);

    const onItemSelected = (option: RegisterButtonOption) => {
        registerToEvent(option.status);
    };

    const selectedStatus = registerButtonOptions[registrationStatus];
    const isDisabled = unansweredFullEvent || selectedStatus?.isDisabled;
    return (
        <FlexBox orientation={Orientation.vertical} gap="tiny" className={classnames(className, CLASSNAME)}>
            {!isUndefined(remainingSpots) ? (
                <Text as="span" typography="caption" {...get({ element: 'spot-left', action: 'display' })}>
                    {remainingSpots === 0
                        ? translateKey(EVENTS.REGISTRATION_NO_SPOT_LEFT)
                        : translateAndReplace(pluralize(EVENTS.REGISTRATION_SPOT_LEFT, remainingSpots), {
                              NB: remainingSpots,
                          })}
                </Text>
            ) : null}
            <SelectButton<RegisterButtonOption>
                emphasis={Emphasis.medium}
                size={size}
                theme={theme}
                fullWidth={fullWidth}
                onChange={onItemSelected}
                isSelected={registrationStatus !== RegistrationStatuses.unanswered}
                isDisabled={isDisabled}
                options={Object.values(omit(registerButtonOptions, ['unanswered']))}
                getOptionId="status"
                getOptionName={(option) =>
                    unansweredFullEvent ? translateKey(EVENTS.REGISTRATION_FULLY_BOOKED) : option.label
                }
                renderOption={(option) => (
                    <SelectButton.Option
                        before={option.leftIcon ? <Icon icon={option.leftIcon} /> : undefined}
                        isDisabled={option.isDisabled}
                        tooltipProps={
                            option.isDisabled && isEventFull
                                ? { label: translateKey(EVENTS.REGISTRATION_FULLY_BOOKED_TOOLTIP) }
                                : undefined
                        }
                        {...get({ element: 'button-registration-option', action: option.status.replace(/_/g, '-') })}
                    >
                        {option.label}
                    </SelectButton.Option>
                )}
                value={selectedStatus}
                label={translateKey(EVENTS.ATTEND)}
                // Label is normally provided by the tooltip. But when disabled the tooltip cannot add the label
                // TODO: remove after we remake the DS disabled states (DSW-2)
                aria-label={isDisabled ? translateKey(EVENTS.ATTEND) : undefined}
                className={selectedStatus?.className}
                leftIcon={selectedStatus?.leftIcon}
            />
        </FlexBox>
    );
};
