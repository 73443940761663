/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import isNumber from 'lodash/isNumber';
import startsWith from 'lodash/startsWith';

import { parseHex } from './parseHex';
import { parseRgb } from './parseRgb';

/**
 * The default constrast threshold to switch between white or black.
 *
 * @type {number}
 */
const DEFAULT_CONTRAST_THRESHOLD = 150;

/**
 * The WCAG contrast ratio requirement for small text in AA-level.
 *
 * @type {number}
 */
const SMALL_TEXT_AA_RATIO = 0.22222;

/**
 * Get the RGB components (blue, green, red) of the given color.
 *
 * @param colorCode The hexadecimal or RGB color code.
 *                            Note: short, long hexadecimal and rgb with alpha are supported.
 * @return The RGB components.
 */
function getRGBValues(colorCode: string) {
    return parseRgb(colorCode) || parseHex(colorCode);
}

/**
 * Get the contrast color for a given color.
 *
 * @param  {string} color           The color we want to compute the contrast of.
 *                                  This can be in Hex (short or long) or RGB format.
 * @param  {number} [threshold=150] The contrast threshold to switch between black and white.
 * @return {string} The contrast color.
 *                  This can be either 'white' or 'black'.
 */
function getContrastColor(color: string, threshold = DEFAULT_CONTRAST_THRESHOLD) {
    const rgb = getRGBValues(color);

    if (rgb) {
        // eslint-disable-next-line no-magic-numbers
        const contrast = Math.round((rgb.red * 299 + rgb.green * 587 + rgb.blue * 114) / 1000);

        return contrast > threshold ? 'black' : 'white';
    }

    return 'white';
}

/**
 * Convert Hexadecimal color to a RGBA one.
 *
 * @param  {string} hexColor    Hexadecimal color with a prefixed #.
 * @param  {number} [opacity=1] RGBA Alpha value, between 0 (fully transparent) and 1 (fully opaque).
 * @return {string} RGBA Color.
 */
function hexToRGBA(hexColor: string, opacity: number) {
    // Be sure hexColor is an hexadecimal color (it can be "transparent").
    if (!startsWith(hexColor, '#')) {
        return hexColor;
    }

    const newOpacity = isNumber(opacity) ? opacity : 1;

    const colorCode: RegExpMatchArray | string = hexColor.replace('#', '');

    // eslint-disable-next-line no-magic-numbers
    const colorCodeMatch = colorCode.match(new RegExp(`(.{${colorCode.length / 3}})`, 'g')) || [];

    colorCodeMatch.forEach((colorSegment, index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        colorCodeMatch[index] = parseInt(colorSegment.length === 1 ? colorSegment + colorSegment : colorSegment, 16);
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    colorCodeMatch.push(newOpacity);

    return `rgba(${colorCodeMatch.join(',')})`;
}

/**
 * Get the luminance for a given color.
 *
 * @param  {string} color           The color we want to get the luminance of.
 *                                  This can be in Hex (short or long) or RGB format.
 * @param  {number} [threshold=150] The contrast threshold to switch between black and white.
 * @return {string} The contrast color.
 *                  This can be either 'white' or 'black'.
 */
function getColorLuminance(color: string) {
    const rgb = getRGBValues(color);
    if (rgb) {
        const { red, green, blue } = rgb;
        // Luminance formula explained on the WCAG documentation: https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
        const a = [red, green, blue].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }
    return null;
}

/**
 * Check if two colors contrast ratio respect the WCAG standard.
 *
 * @param  {string} foregroundcolor     The foreground color to check (hex or rgb format).
 * @param  {string} backgroundcolor     The background color to check (hex or rgb format).
 * @return {boolean} The check boolean value.
 */
function isColorContrastRatioCorrect(foregroundColor: string, backgroundColor: string) {
    const foregroundLuminance = getColorLuminance(foregroundColor);
    const backgroundLuminance = getColorLuminance(backgroundColor);
    if (foregroundLuminance && backgroundLuminance) {
        // Ratio formula explained on the WCAG documentation: https://www.w3.org/TR/WCAG21/#dfn-contrast-ratio
        const ratio =
            foregroundLuminance > backgroundLuminance
                ? (backgroundLuminance + 0.05) / (foregroundLuminance + 0.05)
                : (foregroundLuminance + 0.05) / (backgroundLuminance + 0.05);

        return ratio < SMALL_TEXT_AA_RATIO;
    }
    return false;
}

/**
 * Lighten or darken.
 *
 * @param  {string} color  The color to lighten or darken (hex).
 * @param  {number} amount The lighten/darken amount.
 * @return {string} The new color.
 */
function lightenDarkenColor(color: string, amount: number) {
    return `#${color
        .replace(/^#/, '')
        .replace(/../g, (clr) =>
            `0${Math.min(255, Math.max(0, parseInt(clr, 16) + amount)).toString(16)}`.substr(-2),
        )}`;
}

/**
 * Shade (lighten or darken) and blend two colors.
 *
 * @param  {number} percentage The percentage of shading represented as a decimal number between -1 and 1.
 *                             Negative percentage is darkening, positive is lightening.
 * @param  {string} color1     The first color to blend (hex or rgb format).
 * @param  {string} color2     The second color to blend (hex or rgb format).
 *                             If none given, use black for darkening and white for lightening.
 * @return {string} The new shaded and blend color.
 */
function shadeBlendColors(percentage: number, color1: string, color2: string) {
    const originalPercentage = percentage;
    percentage = percentage < 0 ? percentage * -1 : percentage;

    const rgb = getRGBValues(color1);
    let splittedColor2;

    // Color1 is in RGB format.
    // eslint-disable-next-line no-magic-numbers
    if (color1.length > 7 && rgb) {
        color2 = color2 || (originalPercentage < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)');

        splittedColor2 = color2.split(',');

        // eslint-disable-next-line no-magic-numbers
        return `rgb(${Math.round((parseInt(splittedColor2[0].slice(4), 10) - rgb.red) * percentage) + rgb.red},${
            Math.round((parseInt(splittedColor2[1], 10) - rgb.green) * percentage) + rgb.green
        },${Math.round((parseInt(splittedColor2[2], 10) - rgb.blue) * percentage) + rgb.blue})`;
    }

    // Color1 is in hexadecimal format.
    color2 = color2 || (originalPercentage < 0 ? '#000000' : '#FFFFFF');

    splittedColor2 = parseInt(color2.slice(1), 16);

    if (rgb) {
        /* eslint-disable no-bitwise, no-magic-numbers */
        return `#${(
            0x1000000 +
            (Math.round(((splittedColor2 >> 16) - rgb.red) * percentage) + rgb.red) * 0x10000 +
            (Math.round((((splittedColor2 >> 8) & 0x00ff) - rgb.green) * percentage) + rgb.green) * 0x100 +
            (Math.round(((splittedColor2 & 0x0000ff) - rgb.blue) * percentage) + rgb.blue)
        )
            .toString(16)
            .slice(1)}`;
        /* eslint-enable no-magic-numbers, no-bitwise */
    }

    return color1;
}

export { getContrastColor, getRGBValues, hexToRGBA, isColorContrastRatioCorrect, lightenDarkenColor, shadeBlendColors };
