import React from 'react';

import isObject from 'lodash/isObject';

import { SkipLink } from '@lumapps/a11y/components/SkipLink';
import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { Avatar, Size, Tooltip } from '@lumapps/lumx/react';
import { Link, Route } from '@lumapps/router';
import { useComputedTopStyles } from '@lumapps/style/hooks/useComputedTopStyles';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { MAIN_CONTENT_ID } from '../../constants';

const { isDev, defautlLogoUrl } = get();

export interface NavigationHeaderProps {
    /** The fallback image if there is an error with the homeImageSrc */
    fallbackHomeImageSrc: string;
    /** The image source of the company logo */
    homeImageSrc: string | Record<string, any>;
    /** The url where to redirect for the logo */
    homeLinkUrl: string;
    /** The user avatar image source */
    avatarImageSrc?: string;
    /** The route where to redirect for the user profile */
    userProfileRoute?: Route;
    /** Should the component be sticky to the top? */
    isSticky?: boolean;
    /** background color override */
    backgroundColor?: string;
    children?: React.ReactNode;
    /** the instance name */
    siteName: string;
    /** parent instance id */
    parentInstance?: string;
    /** whether the nav inheritance FF is enabled or not */
    isMainNavInheritanceEnabled?: boolean;
}

const CLASSNAME = 'navigation-header';

/**
 * Component displayed on the top of the side navigation in the back office. It should not be reused
 *
 * @family Back office
 * @param NavigationHeaderProps
 * @returns NavigationHeader
 */
export const NavigationHeader: React.FC<NavigationHeaderProps> = ({
    fallbackHomeImageSrc,
    homeImageSrc,
    homeLinkUrl,
    avatarImageSrc,
    userProfileRoute,
    backgroundColor,
    siteName,
    isSticky = false,
    children,
    parentInstance,
    isMainNavInheritanceEnabled,
    ...props
}) => {
    const { translateKey, translateObject, translateAndReplace } = useTranslate();
    const topStyles = useComputedTopStyles({ parentInstance, isMainNavInheritanceEnabled });
    const { block, element } = useClassnames(CLASSNAME);

    // Get the proper image url
    const imageSrc = React.useMemo(() => {
        /**
         * While in development, display the default logo since the backend cannot manage the localhost
         * env correctly and we do not want to display a broken image all the time while development
         */
        if (isDev) {
            return defautlLogoUrl;
        }

        if (homeImageSrc) {
            if (isObject(homeImageSrc)) {
                const logoForLang = translateObject(homeImageSrc) as any;

                if (logoForLang) {
                    return logoForLang.url;
                }

                return fallbackHomeImageSrc;
            }

            return homeImageSrc;
        }

        return fallbackHomeImageSrc;
    }, [homeImageSrc, translateObject, fallbackHomeImageSrc]);

    return (
        <>
            <SkipLink anchorId={MAIN_CONTENT_ID} className={element('skip-link')} floating>
                {translateKey(GLOBAL.SKIP_TO_CONTENT)}
            </SkipLink>
            <li
                className={block({ 'is-sticky': isSticky })}
                style={{ backgroundColor: topStyles.backgroundColor || backgroundColor }}
                {...props}
            >
                <Tooltip label={translateKey(GLOBAL.VIEW_HOMEPAGE)}>
                    {/* Using target _self to force reload and switch to v1 or v2 if needed. */}
                    <a href={homeLinkUrl} target="_self" className={element('logo')}>
                        <img src={imageSrc} alt={translateAndReplace(GLOBAL.HOME_PAGE, { site: siteName })} />
                    </a>
                </Tooltip>

                {avatarImageSrc && (
                    <Tooltip label={translateKey(GLOBAL.VIEW_USER_PROFILE)}>
                        <Link to={userProfileRoute} className={element('user')}>
                            <Avatar image={avatarImageSrc} size={Size.m} alt={translateKey(GLOBAL.VIEW_USER_PROFILE)} />
                        </Link>
                    </Tooltip>
                )}

                {children}
            </li>
        </>
    );
};

NavigationHeader.displayName = 'SideNavigationItem';

export { CLASSNAME };
