import React from 'react';

import { FieldValues, UseFormReturn } from 'react-hook-form';

export interface UseFormDialogOptions {
    /** callback for resetting the form */
    reset: UseFormReturn['reset'];
    /** callback on closing the dialog */
    onClose?: () => void;
    /** default values for the form */
    defaultValues?: FieldValues;
    /** values for the form */
    values?: FieldValues;
}

export interface UseFormDialogMethods {
    /** callback to be used when closing the dialog */
    onCloseDialog: () => void;
    /** callback to be used when visibility changes for the dialog */
    onVisibilityChange: (open: boolean) => void;
}

/**
 * This custom hook creates the necessary callbacks in order to manage a form inside a dialog
 */
export const useFormDialog = ({
    reset,
    onClose,
    defaultValues,
    values,
}: UseFormDialogOptions): UseFormDialogMethods => {
    /**
     * When the dialog is closed, we need to reset the form so that
     * next time it is opened again, we display the default values directly.
     */
    const onCloseDialog = () => {
        reset();

        if (onClose) {
            onClose();
        }
    };

    /**
     * When visibility changes, we either need to reset the entire form (since it is no longer visible),
     * or reset it to the default values (when it is opened)
     */
    const onVisibilityChange = React.useCallback(
        (open) => {
            if (!open) {
                reset();
            } else {
                reset(defaultValues);
            }
        },
        [defaultValues, reset],
    );

    /**
     * If the values change from the outside and pass to be empty, we need to reset the form so that
     * those changes are reflected on the dialog.
     */
    React.useEffect(() => {
        if (!values && defaultValues) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return {
        onCloseDialog,
        onVisibilityChange,
    };
};
