/* istanbul ignore file */
import { hasMainNavAdminAccess } from '@lumapps/navigation-admin/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { MainNavSideNavigation } from './MainNavSideNavigation';

const mapStateToProps = (state: FrontOfficeStore) => {
    const canAccessMainNavAdmin = hasMainNavAdminAccess(state);

    return {
        canAccessMainNavAdmin,
    };
};

const mapDispatchToProps = {};

const ConnectedMainNavSideNavigation = connect(mapStateToProps, mapDispatchToProps)(MainNavSideNavigation);

export { ConnectedMainNavSideNavigation as MainNavSideNavigation };
