import React, { useCallback, useEffect, useMemo, useState } from 'react';

import isUndefined from 'lodash/fp/isUndefined';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { MetadataFilter } from '@lumapps/metadata-pickers/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useBlockFilters } from '@lumapps/widget-base/components/Block/BlockAugmentedContainer/useBlockFilters';
import { getMainWidget } from '@lumapps/widget-base/ducks/selectors';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { LegacyFieldsType } from '@lumapps/widget-base/types';

import {
    FILTERS_THAT_SHOULD_NOT_BE_CLEARED,
    LEGACY_TO_NGI_FILTERS_MAP,
    FILTERS_WITH_POTENTIAL_DEFAULT_VALUES,
} from '../constants';
import { FormattedTagFilter, GenericListFilterType, LegacyFilterId, NGIFilterId } from '../types';
import { useFetchWidgetFilterProperties } from './useFetchWidgetFilterProperties';
import { useFilterProperties } from './useFilterProperties';
import { useUpdateContentTypeTags } from './useUpdateContentTypeTags';
import { useUpdateGenericListURLFilters } from './useUpdateGenericListURLFilters';
import { useUpdateMetadata } from './useUpdateMetadata';

interface UseContentFiltersProps {
    rawFilters?: LegacyFieldsType<LegacyFilterId>;
}

export const useContentFilters = (contentFilterWidgetId: string, props: UseContentFiltersProps) => {
    const { rawFilters } = props;
    const dispatch = useDispatch();
    const mainWidget = useSelector(getMainWidget);

    // Fetch filter properties for the connected main widget
    const { filterProperties, error } = useFetchWidgetFilterProperties(mainWidget);

    // On error => set the filter widget error state
    React.useEffect(() => {
        if (error) {
            dispatch(
                actions.setWidgetProperties({
                    widgetId: contentFilterWidgetId,
                    widgetProperties: { state: 'error' },
                }),
            );
        }
    }, [contentFilterWidgetId, dispatch, error]);

    const { widgetId: mainWidgetId, widgetType: mainWidgetType, blockResolutionInfo, legacyWidget } = mainWidget || {};

    const { onApply, onFilterChange, onFilterReplace, activeFilters } = useBlockFilters({
        widgetId: mainWidgetId || '',
        widgetType: mainWidgetType,
        blockResolutionInfo,
        legacyWidget,
        isMainWidget: !isEmpty(mainWidget),
    });

    /** Local filters and active filters management */
    const [filtersCurrentValues, setFiltersCurrentValues] = useState<GenericListFilterType>(
        activeFilters as GenericListFilterType,
    );

    /** Update active filters with filter properties */
    const filtersFromWidgetProperties = useFilterProperties({
        filterProperties,
        activeFilters,
        onFilterChange,
        mainWidgetType: mainWidget?.widgetType,
    });

    /**
     * Enabled filters from Content Filter configuration
     * If the filtered list is using tabs, we are not displaying the tags filter
     * */
    const availableFilters = useMemo(
        () =>
            rawFilters
                ?.filter((filter) => {
                    if (filter.name === LegacyFilterId.tags || filter.name === LegacyFilterId.directoryTags) {
                        return !filtersFromWidgetProperties?.areTabsEnabled && filter.enable;
                    }

                    return filter.enable;
                })
                .map((filter) => LEGACY_TO_NGI_FILTERS_MAP[filter.name]),
        [filtersFromWidgetProperties?.areTabsEnabled, rawFilters],
    );

    /**
     * ---------------------- HANDLERS
     */

    const setFilterValues = useCallback(
        (filterId: NGIFilterId | string, values: GenericListFilterType[NGIFilterId]) => {
            setFiltersCurrentValues({
                ...filtersCurrentValues,
                [filterId]: values,
            });
        },
        [filtersCurrentValues],
    );

    const handleApply = useCallback(() => {
        if (onApply && onFilterChange) {
            /**
             * When using the content filter, we want to be able to override the default filtering defined in the designer
             * To do, for each concerned filters, we need to inform the backend wich one should be ignored
             *  */
            const defaultFiltersException = availableFilters?.length
                ? intersection(FILTERS_WITH_POTENTIAL_DEFAULT_VALUES, availableFilters)
                : undefined;

            onFilterChange({ ...filtersCurrentValues, ignoredDefaultFilters: defaultFiltersException });
            onApply();
        }
    }, [availableFilters, filtersCurrentValues, onApply, onFilterChange]);

    const handleClear = useCallback(() => {
        if (onApply) {
            /** Extract filters we want to preserve from activeFilters */
            const persistedFilters = pick(activeFilters, FILTERS_THAT_SHOULD_NOT_BE_CLEARED);
            /** Replace current filters with those from the properties (minus exceptions) */
            onFilterReplace({ ...filtersFromWidgetProperties, ...persistedFilters });
            /** Display filters from properties when possible */
            setFiltersCurrentValues(filtersFromWidgetProperties || {});
            onApply();
        }
    }, [activeFilters, filtersFromWidgetProperties, onApply, onFilterReplace]);

    /**
     * ---------------------- EFFECTS
     */

    /** Update current URL with active filters  */
    useUpdateGenericListURLFilters(activeFilters);

    /** Fetch metadata information for the URL metadata filters  */
    useUpdateMetadata({
        metadata: filtersCurrentValues?.metadata || [],
        handleUpdateMetadata: (newMetadata: MetadataFilter[]) => {
            setFilterValues(NGIFilterId.metadata, newMetadata);
        },
    });

    /** Initialise filters with active filters */
    useEffect(() => {
        setFiltersCurrentValues(activeFilters || {});
    }, [activeFilters]);

    /**
     * Hook used to update the currently available tags by
     * - Removing outdated tags (the parent content types is not available anymore, happen when we remove a site from the selected sites)
     * - Update tags that are uncompleted (happened when the tag comes from the URL parameters, and only contain the ID and has no name)
     * */
    useUpdateContentTypeTags({
        selectedTags: filtersCurrentValues?.tags,
        sites: filtersCurrentValues?.site,
        siteReferences: filterProperties?.siteReferences,
        includeSiblingSites: filterProperties?.includeSiblingSites,
        tagReferences: filterProperties?.tagReferences,
        ready: !isUndefined(filterProperties),
        handleTagFilter: (tags: FormattedTagFilter[]) => {
            setFilterValues(NGIFilterId.tags, tags);
        },
    });

    return {
        /** Main widget Type */
        mainWidgetType,
        /** List of available filters from content filter properties */
        availableFilters,
        /** Filters values currently applied, but not necessarily active yet */
        filtersCurrentValues,
        /** Filters value from the main widget configuration */
        filtersFromWidgetProperties,
        /** Raw filters from the main widget configuration */
        filterProperties,
        /** Currently active filters */
        activeFilters: activeFilters as GenericListFilterType,
        /** Apply handler */
        handleApply,
        /** Clear handler */
        handleClear,
        /** Filter modification handler */
        handleFilterChange: setFilterValues,
    };
};
