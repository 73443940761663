import React from 'react';

interface Output {
    onMouseDownCapture: React.MouseEventHandler;
    onClickCapture: React.MouseEventHandler;
    onBlurCapture: React.FocusEventHandler;
}

/**
 * Disable blur on an element while it is being activated (between mouse down & mouse click)
 *
 * This can be used to fix Safari bugs where buttons gets blurred on click.
 *
 * @return event handlers that should be applied to the element.
 */
export function useDisableBlurWhileActivating(): Output {
    return React.useMemo(() => {
        let isActivating = false;
        return {
            onMouseDownCapture() {
                isActivating = true;
            },
            onClickCapture() {
                isActivating = false;
            },
            onBlurCapture(event) {
                // Prevent blur event if element is activating
                if (isActivating) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            },
        };
    }, []);
}
