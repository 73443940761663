import React, { ReactNode } from 'react';

import { useFeedArticle } from '../../hooks/useFeedArticle';
import { FeedInfiniteTrigger } from '../FeedInfiniteTrigger';

export type FeedArticleProps<C extends React.ElementType = any> = React.ComponentProps<C> & {
    as?: C;
    /** The content of the feed article */
    children: ReactNode;
    /** The current index of the article within the feed. Index starts at 0. */
    index: number;
    /** The number of total items within the feed. */
    total: number;
};

/**
 * Article within a Feed.
 * Defaults to an `article` html element, but it can be customized with the `as` prop.
 *
 * @family Feed
 */
export const FeedArticle = React.forwardRef(
    <C extends React.ElementType>(props: FeedArticleProps<C>, ref: React.Ref<HTMLElement>) => {
        const { as: Component = 'article', index, total, children, ...forwardedProps } = props;
        const feedArticleProps = useFeedArticle({ index, total });

        return (
            <Component ref={ref} {...forwardedProps} {...feedArticleProps}>
                <FeedInfiniteTrigger index={index} total={total} />
                {children}
            </Component>
        );
    },
);
