import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Editable } from '@lumapps/wrex/slate';

interface Props {
    className?: string;
    Editable: typeof Editable;
}

export const PostEditor: React.FC<Props> = ({ className, Editable }) => {
    return <Editable className={classnames('wrex-editor', className)} />;
};
PostEditor.displayName = 'PostEditor';
