// Avoid circular dependencies with Instance
import every from 'lodash/every';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import some from 'lodash/some';

import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

export enum MATCHES_METHODS {
    /** The user needs to have every single action on their roles */
    EVERY = 'every',
    /** The user needs to have some of the actions on their roles */
    SOME = 'some',
}

export interface Options {
    /** Determines how the match against a list of actions is done */
    matches: MATCHES_METHODS;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const matchesMethod: Record<string, Function> = {
    [MATCHES_METHODS.EVERY]: every,
    [MATCHES_METHODS.SOME]: some,
};

const permissionsSelector = (state: BaseStore) => state.permissions || {};

/** Retrieves whether the current user is super admin or not */
const isSuperAdmin = createSelector(permissionsSelector, (permissions) => Boolean(permissions.isSuperAdmin));

/** Retrieves the list of authorizations for the current user */
const authorizations = createSelector(permissionsSelector, (permissions) => permissions.authorizations || {});

/** Retrieves the list of authorizations for the current user */
const canEditOnlyOwnContentAuthorizations = createSelector(
    permissionsSelector,
    (permissions) => permissions.canEditOnlyOwnContentAuthorizations || {},
);

/** Retrieves the list of instances where the current user is super admin */
const instancesSuperAdmin = createSelector(permissionsSelector, (permissions) => permissions.instancesSuperAdmin);

/** Check if the current user is super admin on at least one instance */
const isAdminOfAnyInstance = createSelector(instancesSuperAdmin, (instSuperAdmin) => (instSuperAdmin || []).length > 0);

const isInstanceSuperAdmin = createSelector(
    isSuperAdmin,
    instanceIdSelector,
    instancesSuperAdmin,
    (superAdmin, instanceId, instances) => {
        return superAdmin || includes(instances, instanceId);
    },
);

const getAuthorizationsList = createSelector(authorizations, (auths) => Object.keys(auths));

/**
 * Returns a selector that determines whether the user is allowed to access a feature or not.
 * @param requestedAction - action to validate, usually has the format <FEATURE>_<EDIT|DELETE>
 * @param params - parameters in order to configure specific aspects of the authorization validation
 */
const isUserAllowed = (requestedAction?: string | string[], params: Options = { matches: MATCHES_METHODS.EVERY }) =>
    createSelector(isInstanceSuperAdmin, authorizations, (instanceAdmin, auths) => {
        /**
         * If the user is instance admin, they
         * are automatically allowed to access the feature
         */
        if (instanceAdmin) {
            return true;
        }

        if (requestedAction) {
            /** If the requested action is not an array, we look through the different auths that we have */
            if (!isArray(requestedAction)) {
                return Boolean(auths[requestedAction]);
            }

            /** If it is a list of actions, we retrieve the appropriate method and we run it through the list of actions */
            const { matches } = params;
            const matchMethod = matchesMethod[matches];

            return matchMethod(requestedAction, (action: string) => auths[action]);
        }

        return false;
    });

const isReseller = createSelector(customerSelector, (customer) => {
    return Boolean(customer.isReseller);
});

export {
    isUserAllowed,
    isSuperAdmin,
    isAdminOfAnyInstance,
    isInstanceSuperAdmin,
    permissionsSelector,
    getAuthorizationsList,
    isReseller,
    authorizations,
    canEditOnlyOwnContentAuthorizations,
};
