import React from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { classnames, color, margin } from '@lumapps/classnames';
import { Heading } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { useDraggable } from '../../hooks/useDraggable';
import { LUMX_LISTS } from '../../keys';
import { DraggableListDisplay } from '../../types';
import { getItemArray } from '../../utils';
import { DraggableGridView } from './DraggableGridView';
import { DraggableListView } from './DraggableListView';

export type DraggableListProps<T> = {
    /** Custom class name */
    className?: string;
    /** The display properties of the list */
    display?: DraggableListDisplay;
    /** Id for the area */
    droppableId: string;
    /** Whether the first item of the list should be focused on mount */
    focusFirstItemOnMount?: boolean;
    /** Callback to render an item */
    itemRenderer: (
        item: T,
        dragHandleProps: any,
        index: number,
        ref?: React.RefObject<HTMLElement> | ((value: HTMLElement | null) => void),
        onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void,
        isDragging?: boolean,
    ) => React.ReactNode;
    /** Items to render */
    items: T[];
    /** Key to identify an item ex: id */
    keyPropName?: string;
    /** Callback when the order has changed. Items are passed with the new order */
    move(items: T[], srcIndex: number, destIndex: number): void;
    /** Callback when an item is focused */
    onFocus?: (index: number) => void;
    /** whether the entire list is disabled or not */
    isDisabled?: boolean;
    /** label to be displayed above the list */
    label?: string;
    /** node to be rendered after all items have been rendered */
    afterItems?: React.ReactNode;
    /**
     * whether the draggable list should take the opportunity to ensure that all ids
     * provided are unique. WARNING: this might have an impact on performance, but it ensures
     * that all ids are unique, and ensures that at least there are no errors due to the fact
     * that ids can be potentially repeated. Drag and drop and repeated ids do not go well together,
     * since the lib cannot distinguish items between each other.
     */
    shouldEnsureIdUniqueness?: boolean;
    /** ref to the first element of the list */
    firstElementRef?: React.RefObject<HTMLElement>;
};

/**
 * Generic simple Draggable list
 * Only support one drop zone
 * @family Lists
 * @param param0 DraggableListProps
 * @returns component
 */
export const DraggableList = <T,>({
    afterItems,
    className,
    display = { mode: 'vertical' },
    droppableId,
    focusFirstItemOnMount,
    isDisabled,
    items,
    itemRenderer,
    keyPropName,
    label,
    move,
    onFocus,
    shouldEnsureIdUniqueness = false,
    firstElementRef,
}: DraggableListProps<T>) => {
    const { translateAndReplace } = useTranslate();
    const [dropZoneIndexes, setDropZoneIndexes] = React.useState<
        { listIndex: number; index: number; showAfter: boolean } | undefined
    >();
    const [isDraggingWithKeyboard, setIsDraggingWithKeyboard] = React.useState(false);
    const [currentDraggedItemIndex, setCurrentDraggedItemIndex] = React.useState<string | undefined>();
    const parentRef = React.useRef<HTMLDivElement>(null);

    const viewClassName = display.mode === 'grid' ? 'lumx-draggable-grid' : 'lumx-draggable-list';

    const draggable = useDraggable({
        afterDragEnd: (result, provided, srcIndex, destIndex) => {
            if (display.mode === 'grid') {
                // Reset dropzone and dragged index
                setDropZoneIndexes(undefined);
                setCurrentDraggedItemIndex(undefined);

                if (srcIndex === destIndex) {
                    // Drag is canceled, announce to the screen reader
                    provided.announce(
                        translateAndReplace(LUMX_LISTS.DRAG_CANCELED, {
                            START_POSITION: srcIndex + 1,
                        }),
                    );
                } else {
                    // Drag is successful, announce to the screen reader
                    provided.announce(
                        translateAndReplace(LUMX_LISTS.DRAG_ENDED, {
                            START_POSITION: srcIndex + 1,
                            END_POSITION: destIndex + 1,
                        }),
                    );
                }

                // Focus the moved element on drop
                draggable.itemsRef.get(destIndex)?.current?.focus();
            }
        },
        afterDragStart: (result, provided, srcIndex) => {
            if (display.mode === 'grid') {
                const element = document.activeElement as HTMLElement;
                setIsDraggingWithKeyboard(result.mode === 'SNAP');
                // Unfocus the focused element on drag
                element?.blur();
                setCurrentDraggedItemIndex(result.draggableId);

                // Annouce drag start to the screen reader
                provided.announce(
                    translateAndReplace(LUMX_LISTS.DRAG_STARTING, {
                        START_POSITION: srcIndex + 1,
                    }),
                );
            }
        },
        afterDragUpdate: (result, provided, srcIndex, destIndex) => {
            if (display.mode === 'grid') {
                if (isEqual(result.destination, result.source) || !result.destination) {
                    setDropZoneIndexes(undefined);
                }
                // If the element is dragged after it's current position,
                // the drop indication should be displayed after the element
                else if (destIndex > srcIndex) {
                    // Dropzone should be shown after the item hovered
                    setDropZoneIndexes({
                        listIndex: result.destination.index,
                        index: getItemArray(get(result, 'destination.droppableId')),
                        showAfter: true,
                    });
                } else {
                    // Dropzone should be before after the item hovered
                    setDropZoneIndexes({
                        listIndex: result.destination.index,
                        index: getItemArray(get(result, 'destination.droppableId')),
                        showAfter: false,
                    });
                }

                // Announce the current drag position to the screen reader
                provided.announce(
                    translateAndReplace(LUMX_LISTS.DRAG_UPDATES, {
                        START_POSITION: srcIndex + 1,
                        END_POSITION: destIndex + 1,
                    }),
                );
            }
        },
        className: viewClassName,
        display,
        droppableId,
        items,
        move,
        parentRef,
        keyPropName,
        shouldEnsureIdUniqueness,
        listIdPrefix: 'row',
    });
    const { itemsRef } = draggable;

    React.useEffect(() => {
        if (focusFirstItemOnMount) {
            itemsRef.get(0)?.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- itemsRef MUST not be in the deps, otherwise it will prevent the focus on any other item than the first one
    }, [focusFirstItemOnMount]);

    return (
        <>
            {label ? (
                <Heading
                    as="h3"
                    className={classnames(
                        'lumx-text-field__label',
                        isDisabled && color('font', 'dark', 'L3'),
                        margin('bottom'),
                    )}
                >
                    {label}
                </Heading>
            ) : null}

            {display.mode === 'grid' ? (
                <DraggableGridView
                    afterItems={afterItems}
                    className={className}
                    currentDraggedItemIndex={currentDraggedItemIndex}
                    display={display}
                    draggable={draggable}
                    dropZoneIndexes={dropZoneIndexes}
                    isDisabled={isDisabled}
                    isDraggingWithKeyboard={isDraggingWithKeyboard}
                    itemRenderer={itemRenderer}
                    keyPropName={keyPropName}
                    parentRef={parentRef}
                    onFocus={onFocus}
                    firstElementRef={firstElementRef}
                />
            ) : (
                <DraggableListView
                    afterItems={afterItems}
                    className={className}
                    draggable={draggable}
                    display={display}
                    isDisabled={isDisabled}
                    itemRenderer={itemRenderer}
                    keyPropName={keyPropName}
                    parentRef={parentRef}
                />
            )}
        </>
    );
};
