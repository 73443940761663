import React from 'react';

import { SkeletonTypography, Theme, Typography } from '@lumapps/lumx/react';

import { MetadataSelectionSkeleton as MetadataSelectionSkeletonProps } from '../../types';

export const MetadataSelectionSkeleton: React.FC<MetadataSelectionSkeletonProps> = ({ theme = Theme.light }) => {
    return (
        <div>
            <SkeletonTypography
                theme={theme}
                typography={Typography.body1}
                style={{ maxWidth: '87px', width: '100%' }}
            />
            <SkeletonTypography
                theme={theme}
                typography={Typography.body1}
                style={{ maxWidth: '192px', width: '100%' }}
            />
        </div>
    );
};
