export const FETCH_INSTANCE_LOADING = 'instance/FETCH_INSTANCE_LOADING';
export const FETCH_INSTANCE_SUCCESS = 'instance/FETCH_INSTANCE_SUCCESS';
export const FETCH_INSTANCE_ERROR = 'instance/FETCH_INSTANCE_ERROR';

export const FETCH_INSTANCE_LIST_LOADING = 'instance/FETCH_INSTANCE_LIST_LOADING';
export const FETCH_INSTANCE_LIST_SUCCESS = 'instance/FETCH_INSTANCE_LIST_SUCCESS';
export const FETCH_INSTANCE_LIST_ERROR = 'instance/FETCH_INSTANCE_LIST_ERROR';

export const FETCH_INSTANCE_SIBLINGS_LOADING = 'instance/FETCH_INSTANCE_SIBLINGS_LOADING';
export const FETCH_INSTANCE_SIBLINGS_SUCCESS = 'instance/FETCH_INSTANCE_SIBLINGS_SUCCESS';
export const FETCH_INSTANCE_SIBLINGS_ERROR = 'instance/FETCH_INSTANCE_SIBLINGS_ERROR';
