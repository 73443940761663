import React, { useEffect, useLayoutEffect, useRef } from 'react';

import get from 'lodash/get';

import { Progress } from '@lumapps/lumx/react';

import { any, bool, func, string, number, objectOf } from 'prop-types';

import { isConnected, isExternal, isMicrosoft } from 'components/services/user';

/**
 * Display the widget chart component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The chart react element.
 */
const WidgetChart = ({
    chartImageUrl,
    initState,
    isLoading,
    getChart,
    resetState,
    setWidgetWidth,
    style,
    uuid,
    value,
    widgetWidth,
}) => {
    const { selectedChart, selectedFile, selectedSheet } = value;
    const theme = get(style, ['content', 'theme'], 'light');
    const chartRef = useRef(null);

    /* UseEffect for init and cleaning the widgetChart component properties in the redux store. */
    useEffect(() => {
        initState({ uuid });
    }, []);

    /* UseLayoutEffect for retrieving widget current width. */
    useLayoutEffect(() => {
        setTimeout(() => {
            if (!chartRef.current) {
                return;
            }
            const currentWidgetWidth = chartRef.current.offsetWidth;

            if (!widgetWidth || currentWidgetWidth !== widgetWidth) {
                setWidgetWidth({ data: currentWidgetWidth, uuid });
            }
        }, 1);
    }, [setWidgetWidth, uuid, widgetWidth, chartRef.current]);

    /* UseEffect for retrieving the chart image. */
    useEffect(() => {
        if (!widgetWidth || !selectedChart || !selectedFile || !selectedSheet || !uuid) {
            resetState({ data: '', uuid });
            return;
        }
        if (selectedChart.id && selectedFile.id && selectedSheet.id && uuid) {
            getChart(selectedChart.id, selectedFile.id, selectedSheet.id, widgetWidth, widgetWidth, uuid);
        }
    }, [selectedChart, selectedFile, selectedSheet, widgetWidth, uuid, getChart]);

    if (!isConnected() || isExternal() || !isMicrosoft()) {
        return null;
    }

    return (
        <div className="widget-chart" id="widget-chart" ref={chartRef}>
            {!isLoading && chartImageUrl !== '' && (
                <img alt="" className="widget-chart_chart" src={`data:image/gif;base64,${chartImageUrl}`} />
            )}
            {isLoading && <Progress className="centered-loader" theme={theme} />}
        </div>
    );
};

WidgetChart.propTypes = {
    /* The chart image url. */
    chartImageUrl: string,
    /* A function that set the chartImageUrl prop value. */
    getChart: func.isRequired,
    /* A function to initialize the component's properties in the redux store. */
    initState: func.isRequired,
    /* Define if we are currently retrieving datas. */
    isLoading: bool,
    /* A function that set the widgetWidth prop value. */
    setWidgetWidth: func.isRequired,
    /* The widget style. */
    style: objectOf(any).isRequired,
    /* The current widget uuid. */
    uuid: string.isRequired,
    /* The properties of the widget passed by the angular directive. */
    value: objectOf(any).isRequired,
    /* The current widget of the widget. */
    widgetWidth: number,
};

WidgetChart.defaultProps = {
    chartImageUrl: '',
    isLoading: false,
    widgetWidth: null,
};

WidgetChart.isEditable = () => false;

/**
 * Defines whether the widget is empty or not.
 *
 * @param  {string}  value The recipent email address.
 * @return {boolean} Whether the recipent email address is empty or not.
 */

WidgetChart.isWidgetEmpty = (value) => {
    return value === undefined || value.length === 0;
};

/**
 * Determines whether the widget is hidden or not.
 *
 * @param  {Object}  params The widget props.
 * @return {boolean} Whether the widget is hidden or not.
 */
WidgetChart.isWidgetHidden = (params) => {
    return !isConnected() || isExternal() || !isMicrosoft() || WidgetChart.isWidgetEmpty(params);
};

export default WidgetChart;
