import debounce from 'lodash/debounce';

/////////////////////////////

function WidgetSharepointSiteListSettingsController($rootScope, $scope, $timeout, Widget, WidgetSettingsConstant) {
    'ngInject';

    // eslint-disable-next-line consistent-this, angular/controller-as-vm
    const widgetSiteListSettings = this;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Toggle field.
     *
     * @param {Object} field The field that is toggled.
     */
    async function toggleField(field) {
        if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
            return;
        }

        field.enable = !field.enable;

        await widgetSiteListSettings.updateWidget(true);
    }

    /**
     * Update widget on settings change.
     *
     * @param {boolean} [broadcast=true] Indicates if we want to send the update event.
     */
    function updateWidget(broadcast = true) {
        if (!broadcast) {
            return;
        }

        $timeout(() => {
            $rootScope.$broadcast('widget-site-list-settings', Widget.getCurrent().uuid);
        });
    }

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    widgetSiteListSettings.debouncedUpdateWidget = debounce(updateWidget, WidgetSettingsConstant.DEBOUNCE_DELAY);
    widgetSiteListSettings.toggleField = toggleField;
    widgetSiteListSettings.updateWidget = updateWidget;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the input language changes, re-display the sites.
     */
    $scope.$on('inputLanguage', async () => {
        await widgetSiteListSettings.updateWidget(false);
    });

    /**
     * Initialize the controller.
     */
    async function init() {
        await widgetSiteListSettings.updateWidget(false);
    }

    init();
}

/////////////////////////////

angular
    .module('Controllers')
    .controller('WidgetSharepointSiteListSettingsController', WidgetSharepointSiteListSettingsController);

export { WidgetSharepointSiteListSettingsController };
