import moment from 'moment';

import { formatFromNow } from '@lumapps/utils/date/formatFromNow';
import { capitalizeFirstLetter } from '@lumapps/utils/string/capitalizeFirstLetter';

import { FULL_DATE_THRESHOLD } from '../constants';

interface DatePrefixData {
    /**
     * The date to check for the time difference
     */
    date: string;
    /**
     * Should capitalize the resulted date label: “Published 3 days ago”
     *
     * @default true
     */
    capitalize?: boolean;
    /**
     * The desired nb of days before the prefix changes,
     *
     * @default FULL_DATE_THRESHOLD
     */
    threshold?: number;
    /**
     * The text with suffix: “Published on”
     */
    keyWithSuffix: string;
    /**
     * The text without suffix: “Published 3 days ago”
     */
    keyWithoutSuffix: string;
}

/**
 * @param param - The formatting options
 * @returns the date prefix based on the date format
 * ex: 'Published' 3 days ago || 'Published on' November 28, 2020
 */
const handleVideoDatePrefix = ({
    capitalize = true,
    date,
    threshold = FULL_DATE_THRESHOLD,
    keyWithSuffix,
    keyWithoutSuffix,
}: DatePrefixData) => {
    const dateDifference = moment().diff(moment(date), 'days');
    const formatDate = (date: string) => (capitalize ? capitalizeFirstLetter(date) : date);

    if (dateDifference > threshold) {
        return formatDate(keyWithSuffix);
    }

    return formatDate(keyWithoutSuffix);
};

interface FormatDateForVideoProps {
    date: string;
    fullDateThreshold?: number;
    capitalize?: boolean;
}

/**
 * @param date - The date
 * @param fullDateThreshold - The nb of days to go from one format to the other
 * @returns The date formatted as 'a month ago' if date threshold is < 1 year, then the date as 'November 9, 2020'
 */
const formatDateForVideo = ({
    date,
    fullDateThreshold = FULL_DATE_THRESHOLD,
    capitalize = true,
}: FormatDateForVideoProps) => {
    const dateString = formatFromNow({
        date,
        dateStringFormat: 'LL',
        thresholdDisplayFullDate: fullDateThreshold,
    });

    return capitalize ? capitalizeFirstLetter(dateString) : dateString;
};

export { handleVideoDatePrefix, formatDateForVideo };
