import endsWith from 'lodash/endsWith';
import isString from 'lodash/isString';

import { isCommunityUrl } from '@lumapps/communities/routes';
import { get } from '@lumapps/constants';
import { isDesignerUrl } from '@lumapps/contents/routes/admin';
import { getBaseUrl, isAdminUrl } from '@lumapps/router/utils';
import { isSearchUrl } from '@lumapps/search/routes';
import { isUserProfileUrl } from '@lumapps/user-profile/routes';

import { UsePendoProps } from '../../types';

const constants = get();

const { haussmannCell } = constants;

/**
 * Creates the base URL for pendo sanitized urls
 */
const createBaseUrl = (env: UsePendoProps) => {
    const { customer, instance } = env;
    const haussmannCellDomain = new URL(haussmannCell || '').hostname;

    return `https://${haussmannCellDomain}/a/${isString(customer.slug) ? customer.slug : customer.id}/${
        endsWith(instance.slug, '/') ? instance.slug.replace('/', '') : instance.slug
    }`;
};

/**
 * Translates a content slug to the current user language and removes additional
 * slashes that can be added when the content is part of the navigation.
 * @param slug - content slug
 */
const cleanUpContentSlug = (slug: string) => {
    let cleanSlug;

    if (slug) {
        cleanSlug = slug.split('/').pop();
    }

    return cleanSlug;
};

const addBaseQueryParams = (env: UsePendoProps, slug: string) => {
    const { customer, instance } = env;
    const { userLang } = constants;

    const params = `organizationID=${customer.id}&siteID=${instance.id}&lang=${userLang}`;

    return slug.indexOf('?') >= 0 ? `${slug}&${params}` : `${slug}?${params}`;
};

const createUrl = (env: UsePendoProps, slug: string) => {
    const baseUrl = createBaseUrl(env);
    const url = addBaseQueryParams(env, `${baseUrl}${slug}`);

    return url;
};

const createContentUrl = (env: UsePendoProps, slug: string) => {
    const baseUrl = createBaseUrl(env);

    const url = addBaseQueryParams(env, `${baseUrl}/${cleanUpContentSlug(slug)}`);

    return url;
};

/**
 * Sanitizes the URL sent to pendo so that we have a stable URL, no matter the customer
 * or environment that we have.
 */
const sanitizeUrl = (env: UsePendoProps) => (url: string) => {
    const baseUrl = getBaseUrl(window.location.pathname);
    const tokens = url.split(baseUrl);
    const content = window.lumapps.getCurrentContent();

    const slug = tokens[tokens.length - 1];

    if (
        content &&
        content.id &&
        content.id !== '' &&
        !isAdminUrl(slug) &&
        !isUserProfileUrl(slug) &&
        !isSearchUrl(slug) &&
        !isDesignerUrl(slug) &&
        !isCommunityUrl(slug)
    ) {
        return createContentUrl(env, slug);
    }

    return createUrl(env, slug);
};

export { sanitizeUrl };
