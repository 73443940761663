import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { Element } from '@lumapps/wrex/serialization/dita/types';

/** Convert slate unordered-list element to DITA ul. */
export const UL_CONVERT: Converter = (node, children) => ({
    type: 'element',
    name: 'ul',
    children: children as Element[],
});

/** Convert slate ordered-list element to DITA ol */
export const OL_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'ol',
    children,
});

/** Convert slate list-item element to DITA li */
export const LI_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'li',
    children,
});
