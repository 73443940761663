import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { ContentSettings } from './ContentSettings';

/**
 * Renders a content style editor.
 */
export class Content extends React.PureComponent {
    static propTypes = {
        /** Whether the editor should be visible or not. */
        active: propTypes.bool,
        defaults: styleTypes.contentStyle.isRequired,
        name: types.properties,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Called with `('content', payload)` when clicking on it. */
        onToggle: propTypes.func.isRequired,
        /** The current content style. */
        value: styleTypes.contentStyle,
    };

    constructor(props) {
        super(props);
        this.state = { over: false };

        /**
         * Call `onToggle` with the current area name (`'content'`).
         *
         * @param  {Object} occurrence The event object.
         */
        this.toggleActive = (occurrence) => this.props.onToggle('content', occurrence);

        this.onMouseEnter = () => this.setState({ over: true });
        this.onMouseLeave = () => this.setState({ over: false });
    }

    render() {
        const { value, name: key, active, onChange, defaults } = this.props;
        const { over } = this.state;

        return (
            <div className={classNames('widget-style-content', active && 'widget-style-content--is-active')}>
                <div className="widget-style-content__wrapper widget-style-content__wrapper--top">
                    <div className="widget-style-content__outside">
                        <div
                            className={classNames(
                                'widget-style-content__inside',
                                over && 'widget-style-content__inside--is-focus',
                            )}
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                            onClick={this.toggleActive}
                        />
                    </div>
                </div>
                <div className="widget-style-content__settings">
                    {active && <ContentSettings value={value} name={key} onChange={onChange} defaults={defaults} />}
                </div>
                <div className="widget-style-content__wrapper widget-style-content__wrapper--bottom">
                    <div className="widget-style-content__outside">
                        <div
                            className={classNames(
                                'widget-style-content__inside',
                                over && 'widget-style-content__inside--is-focus',
                            )}
                            onMouseEnter={this.onMouseEnter}
                            onMouseLeave={this.onMouseLeave}
                            onClick={this.toggleActive}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
