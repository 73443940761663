export enum CUSTOMER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=16974407
     */
    CUSTOMER_PREVIEW_USERS = 'ADMIN.NAV.CUSTOMER_PREVIEW_USERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244413
     */
    CUSTOMER_SETTINGS = 'ADMIN.NAV.CUSTOMER_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11135095
     */
    DISABLED = 'GLOBAL.DISABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11142167
     */
    ENABLED = 'GLOBAL.ENABLED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452343
     */
    GLOBAL_ADMIN_ADD = 'ADMIN_SUPER_ADMIN_ADD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452344
     */
    GLOBAL_ADMIN_DELETE = 'ADMIN_SUPER_ADMIN_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452345
     */
    GLOBAL_ADMIN_DELETE_DESCRIPTION = 'ADMIN_SUPER_ADMIN_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452346
     */
    GLOBAL_ADMIN_DELETE_SUCCESS = 'ADMIN_SUPER_ADMIN_DELETE_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244417
     */
    GLOBAL_ADMINS = 'ADMIN.NAV.SUPER_ADMINS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227924593
     */
    PENDING = 'GLOBAL.PENDING',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452347
     */
    SEARCH_USER_PLACEHOLDER = 'ADMIN_SUPER_ADMIN_EMAIL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452350
     */
    SEARCH_USERS = 'ADMIN_SUPER_ADMIN_SEARCH_USERS',
}
