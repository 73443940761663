/**
 * Generates a random number using a seed, useful for generating keys for React list rendering
 * @param seed - seed to use for random number
 */
export function generateRandomWithSeed(seed: number) {
    const s = seed + 1;
    const x = Math.sin(s) * 10000;
    const randomWithSeed = `${Math.abs((x - Math.floor(x)) * 100000)}`.replace('.', '');

    return randomWithSeed;
}
