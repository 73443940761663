import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import join from 'lodash/join';

import * as types from '../../../types';

/**
 * Renders background position choice for `<BackgroundImagePosition />`.
 */
export class BackgroundImagePositionChoice extends React.PureComponent {
    static propTypes = {
        /** Used by `<BackgroundImagePosition />` to identify the current position. */
        id: types.property.isRequired,
        /** Called with `(event)` when clicking on this element. */
        onClick: propTypes.func,
        /** Whether this position reflects the current background position or not. */
        selected: propTypes.bool,
        /** Position expressed as `[vertical, horizontal]`. */
        value: propTypes.arrayOf(propTypes.string).isRequired,
    };

    render() {
        const { id, selected, onClick, value } = this.props;

        return (
            <a
                id={id}
                className={classNames(
                    'background-image-manager__area',
                    selected
                        ? 'background-image-manager__area--is-selected'
                        : `background-image-manager__area--${join(value, '-')}`,
                )}
                onClick={onClick}
            />
        );
    }
}
