(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsDirectoryEntryPickerController($scope, $timeout, Directory, DirectoryEntry, DirectoryEntryPicker,
        Instance, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The delay for debouncing the trigger of the search when filters are updated (in milliseconds).
         *
         * @type {number}
         */
        var _FILTER_DEBOUNCE_DELAY = 500;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The list key to get the list of directories.
         *
         * @type {string}
         * @readonly
         */
        vm.LIBRARY_LIST_KEY = 'directory-entry-picker';

        /**
         * The list key for the selected directories.
         *
         * @type {string}
         * @readonly
         */
        vm.SELECTION_LIST_KEY = 'directory-entry-picker-selection-' + vm.pickerId;

        /**
         * The available values for view mode.
         *
         * @type {Object}
         * @readonly
         */
        vm.VIEW_MODES = {
            library: 'library',
            organize: 'organize',
        };

        /**
         * Contains the list of the directories.
         *
         * @type {Object}
         */
        vm.directories = {};

        /**
         * Contains the filters used to search through the list of directories.
         *
         * @type {Object}
         */
        vm.filter = {
            currentInstance: undefined,
            directory: [],
            instance: [],
        };

        /**
         * Indicates if the picker is opened.
         *
         * @type {boolean}
         */
        vm.isOpen = false;

        /**
         * Indicates if we show the filters panel.
         *
         * @type {boolean}
         */
        vm.showFilter = true;

        /**
         * The display mode of the picker.
         * Possible values are 'organize' or 'libraries'.
         *
         * Todo [Clément]: indicates what each view mode does.
         *
         * @type {string}
         */
        vm.viewMode = vm.VIEW_MODES.organize;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Directory = Directory;
        vm.DirectoryEntry = DirectoryEntry;
        vm.Translation = Translation;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Filter the directory entries.
         */
        function _filterDirectoryEntry() {
            DirectoryEntry.filterize({
                currentInstance: Instance.getCurrentInstanceId(),
                directory: vm.filter.directory,
                instance: vm.filter.instance,
            }, undefined, Utils.displayServerError, vm.LIBRARY_LIST_KEY);
        }
        var _debouncedeFilterDirectoryEntry = _.debounce(_filterDirectoryEntry, _FILTER_DEBOUNCE_DELAY);

        /**
         * Init the directories list.
         */
        function _initList() {
            vm.isOpen = true;
            vm.tmpList = [];

            if (angular.isDefinedAndFilled(vm.ngModel)) {
                DirectoryEntry.filterize({
                    ids: _.map(vm.ngModel, 'id'),
                    currentInstance: Instance.getCurrentInstanceId(),
                    queryType: 'getDirectoryEntriesByIds',
                }, function onDirectoryEntryFilterizeSuccess(response) {
                    vm.tmpList = response;
                }, Utils.displayServerError, vm.SELECTION_LIST_KEY);
            }
        }

        /**
         * Reset the directories list.
         */
        function _resetList() {
            vm.isOpen = false;
            vm.selectedItem = undefined;
            vm.tmpList = undefined;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close the directory entry picker.
         */
        function closePicker() {
            DirectoryEntryPicker.close(vm.pickerId);
        }

        /**
         * Close the filters panel.
         */
        function closeFilter() {
            vm.showFilter = false;
        }

        /**
         * Check if the directory selector can be displayed.
         *
         * @return {boolean} If the directory selector can be displayed.
         */
        function displayDirectorySelector() {
            return angular.isDefinedAndFilled(vm.filter.instance) && angular.isDefinedAndFilled(vm.directories);
        }

        /**
         * Convert a directory id to the directory object.
         * This is the model to selection function for the lxSelect.
         *
         * @param {string}   idDirectory The directory identifier.
         * @param {Function} cb          The lxSelect callback to execute when the object has been transformed.
         */
        function idToDirectory(idDirectory, cb) {
            if (!angular.isFunction(cb) || angular.isUndefinedOrEmpty(idDirectory)) {
                return;
            }

            for (var instance in vm.directories) {
                if (!vm.directories.hasOwnProperty(instance)) {
                    continue;
                }

                var directory = _.find(vm.directories[instance], {
                    id: idDirectory,
                });

                if (angular.isDefinedAndFilled(directory)) {
                    cb(directory);

                    return;
                }
            }

            cb();
        }

        /**
         * Check if a directory is selected.
         *
         * @param  {Object}  directory The directory to check.
         * @return {boolean} If the directory is selected or not.
         */
        function isSelected(directory) {
            if (angular.isUndefinedOrEmpty([directory, vm.tmpList], 'some')) {
                return false;
            }

            return angular.isDefinedAndFilled(_.find(vm.tmpList, {
                id: directory.id,
            }));
        }

        /**
         * Open the filters panel.
         */
        function openFilter() {
            vm.showFilter = true;
        }

        /**
         * Submit the selected directory list.
         */
        function submitList() {
            vm.ngModel = angular.fastCopy(vm.tmpList);

            $timeout(function delayClosePicker() {
                vm.closePicker();
            });
        }

        /**
         * Switch the view mode.
         *
         * @param {string} viewMode The new view mode to set.
         *                          Possible values are 'organize' or 'library'.
         */
        function switchViewMode(viewMode) {
            if (angular.isDefinedAndFilled(vm.VIEW_MODES[viewMode])) {
                vm.viewMode = viewMode;
            }
        }

        /**
         * Toggle a directory details.
         *
         * @param {Object} directory The directory to toggle the display.
         */
        function toggleDetails(directory) {
            if (directory === vm.selectedItem) {
                vm.selectedItem = undefined;
            } else {
                vm.selectedItem = directory;
            }
        }

        /**
         * Toggle a directory selection.
         *
         * @param {Object} directory The directory to toggle the selection.
         */
        function toggleSelection(directory) {
            if (vm.isSelected(directory)) {
                var index = _.findIndex(vm.tmpList, {
                    id: directory.id,
                });

                if (index > -1) {
                    vm.tmpList.splice(index, 1);
                }
            } else {
                vm.tmpList.push(directory);
            }
        }

        /**
         * Update the list of directories.
         *
         * @param {Array} selectedInstances The list of selected instances.
         */
        function updateDirectoryList(selectedInstances) {
            // Build select without header.
            Directory.getMultiInstanceDirectories(selectedInstances,
                function onGetMultiInstanceDirectoriesSuccess(list, directoryIds) {
                    vm.filter.directory = _.intersection(angular.fastCopy(vm.filter.directory), directoryIds);
                    vm.directories = list;
                }
            );
        }

        /////////////////////////////

        vm.closeFilter = closeFilter;
        vm.closePicker = closePicker;
        vm.displayDirectorySelector = displayDirectorySelector;
        vm.idToDirectory = idToDirectory;
        vm.isSelected = isSelected;
        vm.openFilter = openFilter;
        vm.submitList = submitList;
        vm.switchViewMode = switchViewMode;
        vm.toggleDetails = toggleDetails;
        vm.toggleSelection = toggleSelection;
        vm.updateDirectoryList = updateDirectoryList;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch for any changes in the filters and refresh the search after a little delay.
         */
        $scope.$watch(function filterWatcher() {
            return vm.filter;
        }, function filterWatch(newValue, oldValue) {
            if (newValue !== oldValue) {
                _debouncedeFilterDirectoryEntry();
            }
        }, true);

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the directory entry picker closes, reset the list.
         *
         * @param {Event}  evt      The close event.
         * @param {string} pickerId The id of the directory entry picker that closes.
         */
        $scope.$on('directory-entry__close-end', function onDirectoryEntryPickerClose(evt, pickerId) {
            if (vm.pickerId === pickerId) {
                _resetList();
            }
        });

        /**
         * When the directory entry picker opens, initialize the list.
         *
         * @param {Event}  evt      The open event.
         * @param {string} pickerId The id of the directory entry picker that opens.
         */
        $scope.$on('directory-entry__open-start', function onDirectoryEntryPickerOpen(evt, pickerId) {
            if (vm.pickerId === pickerId) {
                _initList();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentInstanceId = Instance.getCurrentInstanceId();
            vm.filter.currentInstance = currentInstanceId;
            vm.filter.instance.push(currentInstanceId);

            // Get the first list of directories.
            _filterDirectoryEntry();

            // Init the filter if instances are set in default filter.
            if (angular.isArray(vm.filter.instance) && angular.isDefinedAndFilled(vm.filter.instance)) {
                vm.updateDirectoryList(vm.filter.instance);
            }
        }

        init();
    }

    /////////////////////////////

    /**
     * Directory entry picker directive.
     *
     * @param {Array}  ngModel  The model in which to store the selected directories entries.
     * @param {string} pickerId The identifier of the directory entry picker.
     */

    function LsDirectoryEntryPickerDirective() {
        'ngInject';

        function link(scope, el) {
            el.appendTo('body');

            scope.$on('$destroy', function onDestroy() {
                el.remove();
            });
        }

        return {
            bindToController: true,
            controller: LsDirectoryEntryPickerController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                ngModel: '=',
                pickerId: '@lsPickerId',
            },
            templateUrl: '/client/common/modules/directory-entry-picker/views/directory-entry-picker.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsDirectoryEntryPicker', LsDirectoryEntryPickerDirective);
})();
