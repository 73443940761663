import { sendWebviewMobileMessage } from '@lumapps/mobile-webview/message-emit';

import { Route } from '../types';
import { addLocationOriginToUrl, createRelativeUrl, createUrl } from '../utils';

/**
 * In the webview app we don't really redirect to anywhere, we just notify the host mobile app where it needs to go
 * (mobilePageDescriptor) or give it a fallback URL (so it can open it in a browser).
 */
export const webviewRouterApi = {
    redirect: (route: Route) => {
        const relativeUrl = createRelativeUrl(route);
        const url = addLocationOriginToUrl(createUrl(route, relativeUrl, route.instanceSlug));

        const page = route.mobilePageDescriptor || { type: 'unknown' };
        sendWebviewMobileMessage({ type: 'redirect', payload: { ...page, url } });
    },
};
