import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-site-details` as const;

type SiteDetailsProps = Omit<TextProps, 'as'>;

/**
 * Displays the site details of a video
 */
export const SiteDetails = ({ typography = Typography.body1 }: SiteDetailsProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    const { canDisplay, isLoading, video, theme } = useBlockVideoContext();

    if (!canDisplay('SITE_DETAILS')) {
        return null;
    }

    if (isLoading) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="20%" />;
    }

    if (!video?.siteDetails) {
        return null;
    }

    return (
        <Text as="span" className={block()}>
            {translateKey(GLOBAL.SOURCE)}:&nbsp;{video.siteDetails.name}
        </Text>
    );
};
