/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isRoleAllowed } from '../../ducks/selectors';
import { RoleSideNavigation } from './RoleSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isRoleAllowed(state),
});

const ConnectedRoleSideNavigation = connect(mapStateToProps)(RoleSideNavigation);

export { ConnectedRoleSideNavigation as RoleSideNavigation };
