import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { Size, SkeletonTypography, Text } from '@lumapps/lumx/react';

import { CLASSNAME } from '.';
import { ComboboxOptionProps } from '../../types';
import './index.scss';

export interface ComboboxOptionSkeletonProps {
    index?: number;
    children?: ReactNode | ((options: { index?: number }) => ReactNode);
    before?: ComboboxOptionProps['before'];
    after?: ComboboxOptionProps['after'];
    size?: ComboboxOptionProps['size'];
}

// Default widths the skeletons must have
const defaultWidths = [REAL_SIZE_FOR_LUMX_SIZE.xxl, REAL_SIZE_FOR_LUMX_SIZE.xl, REAL_SIZE_FOR_LUMX_SIZE.l];

/**
 * Skeleton for a combobox option.
 * A typography skeleton is rendered by default but can be overridden by passing children.
 */
export const ComboboxOptionSkeleton = ({
    index,
    before,
    after,
    size = Size.tiny,
    children,
}: ComboboxOptionSkeletonProps) => {
    const { block, element } = useClassnames(CLASSNAME);

    const renderedChildren = typeof children === 'function' ? children({ index }) : children;
    const content = renderedChildren || (
        <SkeletonTypography
            typography="body1"
            width={index !== undefined && defaultWidths[index] ? defaultWidths[index] : REAL_SIZE_FOR_LUMX_SIZE.xl}
        />
    );

    return (
        <li role="presentation" className={block(undefined, ['lumx-list-item', `lumx-list-item--size-${size}`])}>
            <div role="presentation" className={element('content', undefined, 'lumx-list-item__wrapper')}>
                {before && (
                    <Text
                        as="span"
                        role="presentation"
                        className={element('before', undefined, 'lumx-list-item__before')}
                    >
                        {before}
                    </Text>
                )}
                {content}
            </div>
            {after && (
                <div role="presentation" className={element('after', undefined, 'lumx-list-item__after')}>
                    {after}
                </div>
            )}
        </li>
    );
};
