import React from 'react';

import { classnames, color, useClassnames, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import {
    Alignment,
    ColorPalette,
    ColorVariant,
    FlexBox,
    Heading,
    HeadingProps,
    Orientation,
    Placement,
    Text,
    Theme,
    Tooltip,
    Typography,
} from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'lumx-multi-progression';

interface Item {
    /** Section percentage. Determines the section width */
    percentage: number;
    /** Color of the progress bar section */
    colorPalette: ColorPalette;
    /** Color variant of the progress bar section */
    colorVariant: ColorVariant;
    /**
     * info to be attached to the bar
     * /!\ This info is visually hidden
     */
    description?: string;
    /** Message to display in the tooltip */
    tooltipMessage: string;
    /** Section count to display below the bar */
    count?: number;
}

export interface MultiProgressionProps {
    /** Optional info to add to each bar.
     *
     * /!\ This info is visually hidden
     */
    children?: string;
    /** Custom class name */
    className?: string;
    /** Description to display under the progress bars */
    description?: string;
    /** The progress bars to appear on the meter element tag */
    items: Item[];
    /** Label to pass to the <meter> for accessibility purposes */
    ariaLabel: string;
    /** Meter's max value
     *
     * Pairing this with the 'total' prop will result in an additional 'total/max'
     * info displayed next to the meter
     */
    maxValue?: number;
    /** Optional title */
    title?: string;
    /** Optional props for the <Heading /> component */
    titleProps?: HeadingProps;
    /** Sum of all item counts
     *
     * Pairing this with the 'maxValue' prop will result in an additional 'total/max'
     * info displayed next to the meter
     */
    total?: number;
    /** Display the 'total/max' info in percentage instead of raw values next to the meter */
    showPercentage?: boolean;
    /** Theme */
    theme?: Theme;
    /**   */
    scope?: string;
}

/**
 * Render a component with a 'meter' role, which contains multiple sections and a tooltip for displaying more info.
 *
 * Using all available props will improve accessibility 👍
 * @param MultiProgressionProps
 * @returns MultiProgression
 * @family Progression
 */
export const MultiProgression: React.FC<MultiProgressionProps> = ({
    children,
    className,
    description,
    items,
    ariaLabel,
    maxValue,
    title,
    titleProps,
    total,
    showPercentage,
    theme = Theme.light,
    scope = CLASSNAME,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope);
    const firstBar = React.useMemo(() => items.find((item) => item.percentage > 0), [items]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const totalPercentage = React.useMemo(() => items.reduce((all, curr) => all + curr.percentage ?? 0, 0), [items]);
    const shouldDisplayCounter = Number(total) >= 0 && Number(maxValue) >= 0;

    return (
        <FlexBox className={block()} orientation={Orientation.vertical}>
            {title && (
                <Heading
                    className={element('title', { [`theme-${theme}`]: true })}
                    typography={Typography.subtitle1}
                    {...get({ element: 'title' })}
                    {...titleProps}
                >
                    {title}
                </Heading>
            )}

            <FlexBox
                className={element('meter-wrapper')}
                hAlign={Alignment.center}
                orientation={Orientation.horizontal}
            >
                <FlexBox
                    aria-describedby={description}
                    aria-label={ariaLabel}
                    aria-valuemax={maxValue}
                    aria-valuemin={0}
                    aria-valuenow={total}
                    className={classnames(element('meter', { [`theme-${theme}`]: true }), className)}
                    orientation={Orientation.horizontal}
                    role="meter"
                >
                    {items.map((item, idx) => {
                        const key = item.tooltipMessage + item.percentage;

                        return (
                            <Tooltip delay={0.5} key={key} label={item.tooltipMessage} placement={Placement.TOP}>
                                <FlexBox
                                    as="span"
                                    className={classnames(
                                        element('bar', {
                                            first: item === firstBar,
                                            'with-border': item.percentage > 0 && idx !== items.length - 1,
                                            rounded: idx === items.length - 1 && totalPercentage >= 98,
                                        }),
                                        color('background', item.colorPalette, item.colorVariant),
                                    )}
                                    style={{ width: `${item.percentage}%` }}
                                    tabIndex="0"
                                >
                                    <Text as="span" className={visuallyHidden()}>
                                        {item.description || children}
                                    </Text>

                                    {!!item.count && (
                                        <Text
                                            as="p"
                                            className={element('count', {
                                                [`theme-${theme}`]: true,
                                            })}
                                            typography={Typography.overline}
                                            {...get({ element: 'count' })}
                                        >
                                            {item.count}
                                        </Text>
                                    )}
                                </FlexBox>
                            </Tooltip>
                        );
                    })}
                </FlexBox>

                {shouldDisplayCounter && (
                    <Text
                        as="p"
                        className={element('total', { [`theme-${theme}`]: true })}
                        typography={Typography.overline}
                        {...get({ element: 'total' })}
                    >
                        {showPercentage ? `${totalPercentage}%` : `${total}/${maxValue}`}
                    </Text>
                )}
            </FlexBox>

            {description && (
                <Text
                    as="p"
                    className={element('description', { [`theme-${theme}`]: true })}
                    id={description}
                    typography={Typography.caption}
                    colorVariant={ColorVariant.L2}
                    {...get({ element: 'description' })}
                >
                    {description}
                </Text>
            )}
        </FlexBox>
    );
};
