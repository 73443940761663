import React from 'react';

import type { Video } from '../../types';
import { Api, Facade, type FacadeProps } from '../Player.Facade';

type State = {
    /** Api to use to fetch video details */
    api: Api;
    /** Play video */
    video?: Video;
    /** Play video id */
    videoId: string;
    /** Whether the player facade is visible or not */
    isFacadeVisible?: boolean;
};

type Action =
    /** Action used to hide the facade */
    | { type: 'HIDE_FACADE' }
    /** Action used to set the Play video */
    | { type: 'SET_VIDEO'; video: Video };

type Dispatch = (action: Action) => void;

export function PlayerReducer(state: State, action: Action) {
    switch (action.type) {
        case 'HIDE_FACADE': {
            return { ...state, isFacadeVisible: false };
        }

        case 'SET_VIDEO': {
            return { ...state, video: action.video };
        }

        default: {
            throw new Error('Unhandled action type');
        }
    }
}

const PlayerContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

interface PlayerContextProviderProps {
    /** Component children */
    children: React.ReactNode;
    /** Play video id */
    videoId: string;
}

export function PlayerContextProvider({ children, videoId }: PlayerContextProviderProps) {
    const FacadeChildComponent = React.Children.toArray(children).find(
        (child) => React.isValidElement(child) && child.type === Facade,
    );

    const isFacadeVisible = Boolean(FacadeChildComponent);
    const api = (React.isValidElement<FacadeProps>(FacadeChildComponent) && FacadeChildComponent.props.api) as Api;

    const [state, dispatch] = React.useReducer(PlayerReducer, {
        api,
        isFacadeVisible,
        video: undefined,
        videoId,
    });

    const value = React.useMemo(() => ({ state, dispatch }), [state]);

    return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>;
}

export function usePlayerContext() {
    const context = React.useContext(PlayerContext);

    if (!context) {
        throw new Error('Child components of Player cannot be rendered outside the Player component!');
    }

    return context;
}
