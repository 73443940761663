import React from 'react';

import { DataAttributesOptions, useDataAttributes } from '@lumapps/data-attributes';
import { Thumbnail, ThumbnailProps } from '@lumapps/lumx/react';
import type { FontColorStyle, FontSizeStyle, FontWeightStyle } from '@lumapps/widget-styles/types';

import { CommunityLink, CommunityLinkProps } from '../CommunityLink';

/**
 Wrapper for the Legacy angular Application
 Allow the use of the react-component directive with the CommunityLink
 */
export const LegacyCommunityLink: React.FC<
    CommunityLinkProps & {
        label: string;
        scope: string;
        attributes: DataAttributesOptions;
        thumbnailProps?: ThumbnailProps;
        linkStyle?: FontColorStyle & FontSizeStyle & FontWeightStyle;
    }
> = ({ label, thumbnailProps, scope = 'legacy-community-link', attributes, linkStyle, ...props }) => {
    const { get } = useDataAttributes(scope);

    if (!props?.to) {
        return null;
    }

    return (
        <CommunityLink {...get({ element: props?.to?.id, action: 'link', ...attributes })} {...props} style={linkStyle}>
            {thumbnailProps ? <Thumbnail {...thumbnailProps} /> : label}
        </CommunityLink>
    );
};
