import { Editor, Location } from '..';

/**
 * Wrapper on Editor.node() to return NodeEntry if found or undefined if not
 * (instead of an exception)
 */
export function getNode(editor: Editor, at?: Location | null) {
    if (!at) {
        return undefined;
    }
    try {
        return Editor.node(editor, at);
    } catch (e) {
        return undefined;
    }
}
