/* istanbul ignore file */
/** tested with integration tests */
import { useEffect } from 'react';

/**
 * Injects an object into the lumapps global variable
 * @param toInject - variables to inject to the global lumapps variable
 */
const useInjectTapper = (toInject: Record<string, any>) => {
    useEffect(() => {
        Object.assign(window.lumapps, toInject);
    });
};

export { useInjectTapper };
