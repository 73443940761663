import get from 'lodash/get';

import { imageAlignmentToTextAlignment } from '@lumapps/wrex-enhanced-image/constants';
import { isAlignedImageWrapper } from '@lumapps/wrex-enhanced-image/utils/isAlignedImageWrapper';
import { isImageGroup } from '@lumapps/wrex-enhanced-image/utils/isImageGroup';
import { ALIGNMENTS } from '@lumapps/wrex/constants';
import { Editor, Element, NodeEntry, Range, ReactEditor } from '@lumapps/wrex/slate';
import { isElementType } from '@lumapps/wrex/slate/utils/isElementType';
import { Alignment } from '@lumapps/wrex/types/core';

import { isAuthorizedAlignmentNode } from './isAuthorizedAlignmentNode';

export const getTextAlignment = (editor: Editor | ReactEditor, at?: Range | null): Alignment | null => {
    const selection = at || editor.selection;
    if (!selection) {
        return null;
    }

    const [imageNodeEntry] = Editor.nodes(editor, {
        at: selection,
        mode: 'highest',
        match: (node) => isImageGroup(node) || isAlignedImageWrapper(node),
    });

    // Allow enhanced image alignment with the text align button
    if (imageNodeEntry) {
        const [imageGroupEntry] = Editor.nodes(editor, {
            at: selection,
            mode: 'lowest',
            match: (node) => isImageGroup(node),
        });

        // Only get alignment with image when we are selecting only the image group
        if (imageGroupEntry) {
            const [imageNode] = imageNodeEntry as NodeEntry<any>;

            const textButtonAlignment = imageNode?.width
                ? imageAlignmentToTextAlignment[imageNode.width]
                : imageAlignmentToTextAlignment[imageNode.alignment];

            if (textButtonAlignment) {
                return textButtonAlignment;
            }
        }
    }

    const entries = Array.from(
        Editor.nodes(editor, {
            match: (node) => Element.isElement(node) && Editor.isBlock(editor, node) && isAuthorizedAlignmentNode(node),
            mode: 'highest',
        }),
    );

    // Get type from first not entry.
    const nodeType = get(entries, [0, 0, 'type']) as string;
    const nodeAlignment = ((get(entries, [0, 0, 'alignment']) as string) || ALIGNMENTS.start) as Alignment;
    if (!nodeType || !entries.every(([node]) => isElementType(nodeType)(node))) {
        // Heterogeneous block types.
        return null;
    }

    return nodeAlignment;
};
