export const enum MissionCenterPermitionRoles {
    Manager = 'Manager',
    SuperAdmin = 'SuperAdmin',
    AnalystManager = 'AnalystManager',
    Author = 'Author',
    PerimeterTrainer = 'PerimeterTrainer',
    CommunicationManager = 'CommunicationManager',
    ContentLicenseProvider = 'ContentLicenseProvider',
    TrainingPublisher = 'TrainingPublisher',
    PublisherManager = 'PublisherManager',
    CommentsModerator = 'CommentsModerator',
    ExpertiseManager = 'ExpertiseManager',
    AutoTranslator = 'AutoTranslator',
    AnalyticsFromApp = 'AnalyticsFromApp',
    Coach = 'Coach',
    Analyst = 'Analyst',
    ThemeManager = 'ThemeManager',
    Trainer = 'Trainer',
    LearnerManager = 'LearnerManager',
    TrainingManager = 'TrainingManager',
    ContentLicenseImporter = 'ContentLicenseImporter',
    CatalogManager = 'CatalogManager',
    AdminManager = 'AdminManager',
    LearnersMassDeleter = 'LearnersMassDeleter',
    WallPublisherAddOn = 'WallPublisherAddOn',
    EventsManager = 'EventsManager',
    AutoTranslatorComm = 'AutoTranslatorComm',
}

export type LearningRolesData = MissionCenterPermitionRoles[];

export interface LaunchUrlData {
    launchUrl: string;
}

export interface JwtCheckData {
    status: string;
}
