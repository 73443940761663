import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiCheck, mdiClose } from '@lumapps/lumx/icons';
import { ButtonProps, Tooltip, Button, Emphasis, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useHover } from '@lumapps/utils/hooks/useHover';

import { SUBSCRIPTIONS } from '../../keys';
import './index.scss';

const SCOPE = 'follow';

export const FollowButton: React.FC<ButtonProps> = ({
    isSelected,
    leftIcon,
    disabled,
    isDisabled,
    onBlur,
    onFocus,
    scope,
    className = '',
    ...buttonProps
}) => {
    const { element } = useClassnames('follow-button');
    const { get } = useDataAttributes(scope || SCOPE);
    const { translateKey } = useTranslate();

    const { isHovered, registerElement } = useHover();
    const [isFocused, setIsFocused] = React.useState(false);

    const buttonIsActive = isHovered || isFocused;

    const handleBlur = () => {
        if (onBlur) {
            onBlur();
        }
        setIsFocused(false);
    };

    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
        setIsFocused(true);
    };

    /**
     * "onBlur" isn't triggered when disabled/isDisabled is set.
     * We need to manually set the focused state to false.
     */
    React.useEffect(() => {
        if (disabled || isDisabled) {
            setIsFocused(false);
        }
    }, [disabled, isDisabled]);

    return (
        <Tooltip label={translateKey(isSelected ? SUBSCRIPTIONS.UNFOLLOW_HELP_TEXT : SUBSCRIPTIONS.FOLLOW_HELP_TEXT)}>
            <Button
                ref={registerElement}
                emphasis={Emphasis.medium}
                isSelected={isSelected}
                onBlur={handleBlur}
                onFocus={handleFocus}
                leftIcon={(isSelected && (buttonIsActive ? mdiClose : mdiCheck)) || leftIcon}
                disabled={disabled}
                isDisabled={isDisabled}
                {...buttonProps}
                className={className}
                {...get({ element: 'button', action: isSelected ? 'unfollow' : 'follow' })}
            >
                {isSelected ? (
                    <div className={element('grid')}>
                        <Text
                            as="span"
                            className={element(buttonIsActive ? 'active' : 'idle')}
                            aria-hidden={!buttonIsActive}
                        >
                            {translateKey(GLOBAL.UNFOLLOW)}
                        </Text>
                        <Text
                            as="span"
                            className={element(buttonIsActive ? 'idle' : 'active')}
                            aria-hidden={!!buttonIsActive}
                        >
                            {translateKey(GLOBAL.FOLLOWED)}
                        </Text>
                    </div>
                ) : (
                    translateKey(GLOBAL.FOLLOW)
                )}
            </Button>
        </Tooltip>
    );
};
