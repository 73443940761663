import { useCallback } from 'react';

import { error, success } from '@lumapps/notifications';
import { deletePost } from '@lumapps/posts/api';
import { REFRESH_EVENTS } from '@lumapps/posts/constants';
import { POSTS } from '@lumapps/posts/keys';
import { RefreshPosts } from '@lumapps/posts/types';
import { useDispatch } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';

import { usePostListsRefresh } from './usePostListsRefresh';

export const usePostDelete = (postId: string, refreshOverride?: RefreshPosts) => {
    const [isDeleteConfirmOpen, , closeDeleteConfirm, openDeleteConfirm] = useBooleanState(false);
    const dispatch = useDispatch();

    const refreshPostLists = usePostListsRefresh(refreshOverride);

    const { fetch: performDelete, status: deleteStatus } = useFetchWithStatus({
        onFetch: () => deletePost(postId),
    });

    const onDeletePost = useCallback(() => {
        performDelete({
            callback: (params) => {
                if (params.success && refreshPostLists) {
                    closeDeleteConfirm();
                    refreshPostLists(REFRESH_EVENTS.POST_DELETE);
                    dispatch(success({ translate: POSTS.POST_DELETE_SUCCESS }));
                } else {
                    dispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
                    closeDeleteConfirm();
                }
            },
        });
    }, [closeDeleteConfirm, dispatch, performDelete, refreshPostLists]);

    const deleteConfirmDialogProps = {
        isOpen: isDeleteConfirmOpen,
        isLoading: deleteStatus === 'loading',
        onConfirm: onDeletePost,
        onClose: closeDeleteConfirm,
    };

    return {
        deleteConfirmDialogProps,
        openDeleteConfirm,
    };
};
