import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const styleRoutes: Route = {
    path: '/admin/instance-style',
    legacyId: 'app.front.instance-style',
    appId: AppId.legacy,
};

/**
 * Retrieves the route for the instance style/theme admin.
 */
const style = (): Route => {
    return styleRoutes;
};

export { style };
