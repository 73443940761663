import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiCellphone } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { MOBILE_CONFIGURATION } from '../../keys';
import { adminMobileConfigurationRoute } from '../../routes';

interface MobileConfigurationSideNavigationProps {
    isAllowed: boolean;
}

export const MobileConfigurationSideNavigation: React.FC<MobileConfigurationSideNavigationProps> = ({ isAllowed }) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            icon={mdiCellphone}
            label={translateKey(MOBILE_CONFIGURATION.TITLE)}
            route={adminMobileConfigurationRoute}
        />
    );
};

MobileConfigurationSideNavigation.displayName = 'SideNavigationItem';
