import React from 'react';

import first from 'lodash/fp/first';
import property from 'lodash/fp/property';

import { classnames, padding } from '@lumapps/classnames';
import { getCommunityFeedKeys } from '@lumapps/communities/ducks/selectors';
import { GenericEntityPicker } from '@lumapps/pickers/components/GenericEntityPicker';
import { useGenericEntityPicker, UseGenericPickerProps } from '@lumapps/pickers/hooks/useGenericEntityPicker';
import { useSelector } from '@lumapps/redux/react';
import { list, cancelList } from '@lumapps/user/api';

import { Author, PostListFilterBlockProps } from '../../../types';

import './index.scss';

const CLASSNAME = 'post-author-filter';

export const PostAuthorFilterBlock: React.FC<PostListFilterBlockProps<Author>> = ({
    selectedValues,
    onSelect,
    theme,
    getChoiceName,
    label,
    filterId,
}) => {
    const feeds = useSelector(getCommunityFeedKeys) ?? [];

    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    const { displayedValue, reset, ...userPickerProps } = useGenericEntityPicker<Author>({
        onFetch: list as UseGenericPickerProps<Author>['onFetch'],
        onFetchCancel: cancelList,
        fetchParams: { feeds, fields: 'items(email,firstName,lastName),more,callId,cursor' },
        displayedValueSelector: getChoiceName,
        initialValue: selectedValues && first(selectedValues),
        onChange: (author: Author) => {
            onSelect(filterId, author ? [author] : []);
        },
    });

    return (
        <GenericEntityPicker<Author>
            label={label}
            theme={theme}
            className={classnames(CLASSNAME, padding('all', null))}
            scope="community-post-list-filters-author"
            choiceDisplayedValueSelector={getChoiceName}
            choiceKeySelector={property('email')}
            choiceSelectedSelector={(author: Author) =>
                !!first(selectedValues) && getChoiceName(author) === displayedValue
            }
            displayedValue={first(selectedValues) ? displayedValue : ''}
            {...userPickerProps}
        />
    );
};
