/* istanbul ignore file */
export const SCOPE = 'metadata-admin';
export const METADATA_TREE_MAX_LEVEL = 10;

export enum METADATA_SCOPES {
    site = 'site',
    platform = 'platform',
}

export enum FORM_FIELD {
    name = 'name',
    description = 'description',
    checkboxDisplayContent = 'checkboxDisplayContent',
    checkboxMulti = 'checkboxMulti',
    checkboxDisplaySearch = 'checkboxDisplaySearch',
    checkboxDisplayChildren = 'checkboxDisplayChildren',
    selectContentType = 'selectContentType',
}

export const DEFAULT_CATEGORY_VALUE = {
    [FORM_FIELD.name]: {},
    [FORM_FIELD.description]: {},
    [FORM_FIELD.checkboxDisplayChildren]: false,
    [FORM_FIELD.checkboxDisplayContent]: false,
    [FORM_FIELD.checkboxDisplaySearch]: false,
    [FORM_FIELD.checkboxMulti]: false,
    [FORM_FIELD.selectContentType]: [],
};

export const DEFAULT_METADATA_VALUE = {
    [FORM_FIELD.name]: {},
    [FORM_FIELD.description]: {},
};
export const MAX_CATEGORY_FETCH_RESULTS = '20';
export const MAX_METADATA_FETCH_RESULTS = '10';

export const FETCH_PARAMS = { emptyParent: true, more: true };

export const DEBOUNCE_DURATION = 1000;
