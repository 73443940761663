(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserListDialogController($injector, $scope, Instance, LumsitesBaseService, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The default parameters to use in the filterize method.
         *
         * @type {Object}
         */
        var _defaultUserFilter = {
            action: 'PAGE_READ',
            instance: Instance.getCurrentInstanceId(),
            showHidden: false,
            status: 'enabled',
        };

        /**
         * The current parameters being used to list the users.
         *
         * @type {Object}
         */
        var _requestParams = {
            params: _defaultUserFilter,
            projection: undefined,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the user list dialog.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.USER_LIST_DIALOG_ID = 'user-list-dialog';

        /**
         * The list key used for user list calls.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vm.USER_LIST_KEY = 'user-list-dialog-list-key';

        /**
         * The method/endpoint name to use to make the filterize.
         *
         * @type {string}
         */
        vm.methodName = undefined;

        /**
         * The service from which the user list will be fetched.
         *
         * @type {Service}
         */
        vm.service = {};

        /**
         * The title of the dialog.
         *
         * @type {string}
         */
        vm.title = '';

        /**
         * The text query to filter the users on.
         *
         * @type {string}
         */
        vm.userFilterQuery = undefined;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Filter users in the list.
         */
        function filterUsers() {
            LumsitesBaseService.proto.prototype.filterize.call(
                vm.service,
                angular.extend(
                    {
                        query: vm.userFilterQuery,
                    },
                    _requestParams.params,
                ),
                undefined,
                undefined,
                vm.USER_LIST_KEY,
                _requestParams.projection,
                vm.methodName,
            );
        }

        /////////////////////////////

        vm.filterUsers = filterUsers;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Trigger the fetching of the next page of results when reaching the bottom of the dialog.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering the scroll end event.
         */
        $scope.$on('lx-dialog__scroll-end', function onDialogScrollEnd(evt, dialogId) {
            if (vm.USER_LIST_DIALOG_ID === dialogId) {
                vm.service.nextPage(undefined, undefined, vm.USER_LIST_KEY, vm.methodName);
            }
        });

        /**
         * When the dialog is being opened, list users.
         *
         * @param {Event}  evt                  The original event triggering the opening of the dialog.
         * @param {Object} [params]             An object of parameters to send to the request.
         * @param {string} [title='USER_LIST']  A title for the dialog.
         * @param {string} [serviceName='User'] The name of the service from which the users will be fetched.
         * @param {string} [methodName]         The name of the method to use to fetch the list.
         * @param {Object} [projection]         The list of attributes we want to retrieve.
         */
        $scope.$on('user-list-dialog__filter', function onDialogOpen(
            evt,
            params,
            title,
            serviceName,
            methodName,
            projection,
        ) {
            serviceName = serviceName || 'User';
            vm.methodName = methodName;
            vm.title = title || Translation.translate('USER_LIST');

            vm.service = $injector.get(serviceName);
            vm.userFilterQuery = undefined;

            _requestParams = {
                params: serviceName === 'User' ? angular.extend(angular.fastCopy(_defaultUserFilter), params) : params,
                projection: projection,
            };

            vm.filterUsers();
        });
    }

    function UserListDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: UserListDialogController,
            controllerAs: 'vm',
            restrict: 'E',
            templateUrl: '/client/front-office/modules/user/views/user-list-dialog.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsUserListDialog', UserListDialogDirective);
})();
