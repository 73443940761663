import React from 'react';

import { cancelGetMainNavigationSubNavigation, getMainNavigationSubNavigation } from '@lumapps/navigation/api';
import { Navigation, NavigationItem } from '@lumapps/navigation/types';
import { getNavigationItemBySlug } from '@lumapps/navigation/utils/navigationMemStore';
import { FetchCallbackParams, useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

interface UseSubNavigationProps {
    /** The widget's uuid. Used to manage fetch canceling */
    uuid: string;
    /** url of the current page. Used to determine whether a path is in the URL and if we should use it */
    url?: string;
    /** User current language, or the language we want to search the subnav for */
    lang: string;
    /** User alternative language */
    alternativeLang: string;
    /** customer Id */
    customer: string;
    /** instance Id */
    instance: string;
    /* is the widget setting 'list' or 'pick' */
    listCurrentSub?: 'list' | 'pick';
    /** The content's id. Used if there is no/wrong URl so we can fetch the subnav by page Id */
    pageId?: string;
    /** The selected nav Id, given by the widget's properties */
    selectedNav?: string;
    /** Whether we should show hidden nodes, given by the widget's properties */
    showHiddenNodes?: boolean;
    /** Optional callback after fetch is done */
    fetchCallback?: (params: FetchCallbackParams<Navigation>) => void;
}

interface GetDefaultOpenNodesRet {
    parentNodeIsOpenByDefault: boolean;
    defaultOpenNodes: Record<string, boolean>;
}
interface UseSubNavigationApi {
    /** The returned sub navigation */
    subNavigation?: Navigation;
    /** The current loading status */
    status: BaseLoadingStatus;
    /** dictionary of all the nodes and whether they should be opened by default */
    defaultOpenNodes: GetDefaultOpenNodesRet['defaultOpenNodes'];
}

const USE_BREADCRUMB_FETCH_KEY = 'USE_BREADCRUMB_FETCH_KEY';

/**
 * SubNavigation widget hook to manage the fetch to backend.
 * Depending on whether the url path is available, it will either fetch the subNavigation of the corresponding node,
 * Or if no enough information is given, it will fetch the first node of the pageId found by the backend.
 */
export const useSubNavigation = ({
    uuid,
    url,
    lang,
    alternativeLang,
    customer,
    instance,
    listCurrentSub,
    pageId,
    selectedNav,
    showHiddenNodes,
    fetchCallback,
}: UseSubNavigationProps): UseSubNavigationApi => {
    const {
        response: fetchResponse,
        status: fetchStatus,
        fetch: fetchSubNavigation,
        cancelFetch: cancelFetchSubNavigation,
    } = useFetchWithStatus<Navigation>({
        onFetch: getMainNavigationSubNavigation,
        onFetchCancel: cancelGetMainNavigationSubNavigation,
    });

    React.useEffect(() => {
        // have a separate fetch key for each instance of widget so cancels won't interfere
        const subNavigationFetchKeyById = `${USE_BREADCRUMB_FETCH_KEY}_${uuid}`;

        // If we do not have the current content Id or a selectedNav id, the widget is wrongly configured; do not fetch
        if (!pageId || !selectedNav) {
            if (fetchCallback) {
                fetchCallback({ success: false });
            }
        } else {
            let nodeId: string | undefined;

            /**
             * If selectedNav is the same id as pageId, we are in `list children of current page` setting.
             * Same if listCurrentSub is "list".
             * We must find the subnav of the current content, depending on the navigation and the url.
             */
            if ((selectedNav === pageId && pageId) || listCurrentSub === 'list') {
                // if url is given, check whether we can find the specific node id in the visible navigation
                nodeId = getNavigationItemBySlug(pageId, url)?.id;
            } else {
                /**
                 * Else if selectedNav is different, then it's a hand picked node id, we are in
                 * `Display Navigation item children` setting. We simply pass this node id to the backend.
                 */
                nodeId = selectedNav;
            }

            const params = {
                // If a navigation node is found, fetch by node id
                nodeId,
                // If there is no nodeId, fetch by page id
                pageId: !nodeId ? pageId : undefined,
                showHiddenNodes,
                lang,
                alternativeLang,
                customer,
                instance,
            };
            fetchSubNavigation({
                params: [params, subNavigationFetchKeyById],
                callback: fetchCallback,
            });
        }

        // If page is unloaded, cancel any ongoing call
        return () => {
            cancelFetchSubNavigation({ params: [{ instance }, subNavigationFetchKeyById] });
        };
    }, [
        fetchSubNavigation,
        fetchCallback,
        cancelFetchSubNavigation,
        pageId,
        url,
        lang,
        alternativeLang,
        customer,
        instance,
        uuid,
        showHiddenNodes,
        selectedNav,
        listCurrentSub,
    ]);

    /** Function that will verify if the checked node is referencing the current content, and check recursively in its children. */
    const getDefaultOpenNodes = React.useCallback(
        (currentPageId: string, node: NavigationItem): GetDefaultOpenNodesRet => {
            /**
             * By default, set for the checked node to return parentNodeIsOpenByDefault = true if checked node references current content,
             * And checked node itself is opened by default if it is the current content
             */
            const nodeRet: GetDefaultOpenNodesRet = {
                parentNodeIsOpenByDefault: node.pageId === currentPageId,
                defaultOpenNodes: { [node.id]: node.pageId === currentPageId },
            };

            /**
             * If checked  node has children, recursively call this function on them, then merge their return values with the one of
             * the current checked node and return it:
             * - parentNodeIsOpenByDefault is true if already true or if one of the children has its parentNodeIsOpenByDefault set to true
             * - defaultOpenNodes is the merging of all the children entries, and the current checked node is opened if one of the children has
             *   its parentNodeIsOpenByDefault set to true.
             */
            if (node.children && node.children.length > 0) {
                const nodeAndChildrenRet = node.children
                    .map((child) => getDefaultOpenNodes(currentPageId, child))
                    .reduce(
                        (acc, nodeChildRet) => ({
                            parentNodeIsOpenByDefault:
                                acc.parentNodeIsOpenByDefault || nodeChildRet.parentNodeIsOpenByDefault,
                            defaultOpenNodes: {
                                ...acc.defaultOpenNodes,
                                ...nodeChildRet.defaultOpenNodes,
                                [node.id]: acc.defaultOpenNodes[node.id] || nodeChildRet.parentNodeIsOpenByDefault,
                            },
                        }),
                        nodeRet,
                    );

                return nodeAndChildrenRet;
            }
            return nodeRet;
        },
        [],
    );

    /** Create a dictionary of all the nodes and whether they should be opened by default */
    const defaultOpenNodes = React.useMemo(() => {
        // children of the response is an array of length 1 with the selected node of the widget (manually selected or current content)
        const selectedNode = fetchResponse?.children[0];

        if (!fetchResponse || !pageId || !selectedNode) {
            return {};
        }

        return getDefaultOpenNodes(pageId, selectedNode).defaultOpenNodes;
    }, [fetchResponse, getDefaultOpenNodes, pageId]);

    return {
        subNavigation: fetchResponse,
        status: fetchStatus,
        defaultOpenNodes,
    };
};
