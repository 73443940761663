import { ALIGNMENTS } from '@lumapps/wrex/constants';
import { Alignment } from '@lumapps/wrex/types/core';

export const IMAGE_GROUP = 'image-group';
export const ALIGNED_IMAGE_WRAPPER = 'aligned-image-wrapper';

export enum IMAGE_ALIGNMENTS {
    left = 'align-left',
    right = 'align-right',
}
export enum IMAGE_WIDTHS {
    fullWidth = '100%',
    halfWidth = '50%',
}

/** Available toggle features */
export enum IMAGE_FEATURES {
    /** Align left & right */
    alignment = 'alignment',
    /** Full width & half width */
    width = 'width',
    /** Image slideshow and image wall views */
    imageGallery = 'image-gallery',
    /** Wrap image in a link */
    link = 'link',
    /** Allow image to be generated with AI */
    generateWithAI = 'generate-with-ai',
}

export const CLASSNAME = 'wrex-content-enhanced-image';

export const imageAlignmentToTextAlignment: Record<any, Alignment> = {
    [IMAGE_ALIGNMENTS.left]: ALIGNMENTS.start,
    [IMAGE_WIDTHS.halfWidth]: ALIGNMENTS.center,
    [IMAGE_ALIGNMENTS.right]: ALIGNMENTS.end,
};

export const textAlignmentToImageAlignment: Record<Alignment, any> = {
    [ALIGNMENTS.start]: IMAGE_ALIGNMENTS.left,
    [ALIGNMENTS.center]: IMAGE_WIDTHS.halfWidth,
    [ALIGNMENTS.end]: IMAGE_ALIGNMENTS.right,
    [ALIGNMENTS.justify]: IMAGE_WIDTHS.fullWidth,
};
