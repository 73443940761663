export enum LEARNER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438648649
     */
    POINTS_EARNED = 'LEARNING.POINTS_EARNED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441790174
     */
    POINTS_EARNED_BREAK = 'LEARNING.POINTS_EARNED_BREAK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441785103
     */
    TIME = 'LEARNING.LEARNER_TIME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510220380
     */
    TIME_MINUTES_ONLY = 'LEARNING.TIME_MINUTES_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438649786
     */
    TIME_SPENT = 'LEARNING.TIME_SPENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441792364
     */
    TIME_SPENT_BREAK = 'LEARNING.TIME_SPENT_BREAK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438656723
     */
    COMPLETED_TRAINING_COURSES = 'LEARNING.COMPLETED_TRAINING_COURSES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=441792369
     */
    COMPLETED_TRAINING_COURSES_BREAK = 'LEARNING.COMPLETED_TRAINING_COURSES_BREAK',
}
