/* istanbul ignore file */
import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useEventListener } from '@lumapps/utils/hooks/useEventListener';

import {
    PixelPoint,
    ImageInfo,
    CropFocalPointPickerProps,
    State,
    DragPosition,
    Point,
    CropInfo,
    ViewMode,
} from '../../types';
import { ReactCropper } from '../ReactCropper/ReactCropper';

import './index.scss';

const CLASSNAME = 'focal-point-picker';

const isPointInRect = (point: PixelPoint, rect: ImageInfo & PixelPoint): boolean => {
    const xInRect = point.x >= rect.x && point.x <= rect.x + rect.width;
    const yInRect = point.y >= rect.y && point.y <= rect.y + rect.height;
    return xInRect && yInRect;
};

const DEFAULT_PROPS = {
    /** Define the automatic cropping area size (100%). */
    autoCropArea: 1,
    /** Set the focus point to the center. */
    focus: { x: 0, y: 0 },
    /** Restrict the crop box to not exceed the size of the canvas. */
    viewMode: 2 as ViewMode,
    crop: null,
    enabled: false,
};

const CropFocalPointPicker = React.forwardRef(
    (
        {
            src,
            focusData = DEFAULT_PROPS.focus,
            cropData = DEFAULT_PROPS.crop,
            enabled = DEFAULT_PROPS.enabled,
            viewMode = DEFAULT_PROPS.viewMode,
            autoCropArea = DEFAULT_PROPS.autoCropArea,
            className = '',
            style,
            aspectRatio,
            previewClass,
            guides,
            showFocus = true,
            zoomOnWheel = false,
            onCropChange,
            onFocusChange,
            onImageLoaded,
        }: CropFocalPointPickerProps,
        ref,
    ) => {
        const { block, element } = useClassnames(CLASSNAME);
        const focalPointPickerClassname = block([className]);
        const focusPoint = React.useRef<HTMLDivElement>(null);
        const internalState = React.useRef<State>({
            internalFocus: null,
            relativeFocus: focusData,
        });
        const lastDragPosition = React.useRef<DragPosition | null>(null);
        const offset = React.useRef({ x: 0, y: 0 });
        const cropper = React.useRef<Cropper>();

        /**
         * Crop dimensions are in natural image dimensions (ie: 2000px * 1600px)
         * Return dimensions according to container (ie: 500px * 200px because css)
         */
        const getScaledCrop = (): CropInfo => {
            const cropperData = (cropper.current as Cropper).getData();
            const imageData = (cropper.current as Cropper).getImageData();
            const scaleX = imageData.width / imageData.naturalWidth;
            const scaleY = imageData.height / imageData.naturalHeight;
            return {
                ...cropperData,
                x: cropperData.x * scaleX,
                y: cropperData.y * scaleY,
                width: cropperData.width * scaleX,
                height: cropperData.height * scaleY,
            };
        };

        const isValid = (focus: PixelPoint): boolean => {
            const cropperData = getScaledCrop();
            return isPointInRect(focus, cropperData);
        };

        /**
         * Get the focus coordinates into pixel from relative coordinates
         * @param relativeFocus The coordinates {x, y} between [-1, 1]
         */
        const getInternalFocus = (relativeFocus: Point): PixelPoint | null => {
            const cropScaledData = getScaledCrop();

            if (relativeFocus) {
                return {
                    x: (cropScaledData.width / 2) * (relativeFocus.x + 1) + cropScaledData.x,
                    y: -(cropScaledData.height / 2) * (relativeFocus.y - 1) + cropScaledData.y,
                };
            }
            return internalState.current.internalFocus;
        };

        const setFocusFromRelative = (relativeFocus: Point): void => {
            internalState.current = {
                ...internalState.current,
                internalFocus: getInternalFocus(relativeFocus),
                relativeFocus,
            };
        };

        const onImageReady = (cropperRef: Cropper): void => {
            cropper.current = cropperRef;
            const canvasData = cropper.current.getCanvasData();

            offset.current.x = canvasData.left;
            offset.current.y = canvasData.top;

            if (showFocus) {
                setFocusFromRelative(focusData);
            }

            onCropChange();

            onFocusChange({ crop: cropData, focus: internalState.current.relativeFocus });

            if (onImageLoaded) {
                onImageLoaded();
            }
        };

        const getRelativeFocus = ({ x, y }: Point): Point => {
            const cropScaledData = getScaledCrop();
            const percent = {
                x: (x - cropScaledData.x) / cropScaledData.width,
                y: (y - cropScaledData.y) / cropScaledData.height,
            };
            return {
                x: (percent.x - 0.5) / 0.5,
                y: -((percent.y - 0.5) / 0.5),
            };
        };

        const setFocus = (fp: PixelPoint): void => {
            internalState.current = {
                ...internalState.current,
                internalFocus: fp,
                relativeFocus: getRelativeFocus(fp),
            };
        };
        /**
         * Put Focus Point back according to the crop action direction
         * @param direction Go to the documentation to see directions availables
         */
        const setFocusAfterCrop = (): void => {
            const { x, y, width, height } = getScaledCrop();
            setFocus({
                ...internalState.current.internalFocus,
                x: x + width / 2,
                y: y + height / 2,
            });
        };

        const getFocusStyle = () => {
            if (!internalState.current.internalFocus) {
                return null;
            }

            const { x: focusX, y: focusY } = internalState.current.internalFocus;

            return {
                x: offset.current.x + focusX,
                y: offset.current.y + focusY,
            };
        };

        const getFocusPointStyle = (): React.CSSProperties => {
            const relativeFocus = getFocusStyle();
            if (relativeFocus) {
                return {
                    left: `${relativeFocus.x}px`,
                    top: `${relativeFocus.y}px`,
                    display: 'block',
                };
            }
            return { display: 'none' };
        };

        const onCropEnd = (): void => {
            if (!isValid(internalState.current.internalFocus as PixelPoint)) {
                setFocusAfterCrop();
            } else {
                setFocus(internalState.current.internalFocus as PixelPoint);
            }
            onCropChange();
            onFocusChange({ crop: cropData, focus: internalState.current.relativeFocus });
        };

        const resetFocus = () => {
            const newFocus = { x: 0, y: 0 };
            setFocusFromRelative(newFocus);
            onFocusChange({ crop: cropData, focus: internalState.current.relativeFocus });
        };

        const reset = () => {
            if (cropper && cropper.current) {
                cropper.current.enable();
                cropper.current.setAspectRatio(NaN);
                onCropChange();
                resetFocus();
                cropper.current.disable();
            }
        };

        const getData = () => {
            if (cropper && cropper.current) {
                return cropper.current.getData();
            }
            return null;
        };

        const getCroppedCanvas = () => {
            if (cropper && cropper.current) {
                return cropper.current.getCroppedCanvas();
            }
            return null;
        };

        React.useImperativeHandle(ref, () => ({
            resetFocus,
            reset,
            getData,
            getCroppedCanvas,
        }));

        /**
         * ADD LISTENER EVENT
         */

        /**
         * Called on drag movement.
         * We use 'as' for type because we know it's not null
         * @param event mouse position
         */
        const onFocalDrag = ({ clientX, clientY }: MouseEvent): void => {
            const { x, y } = lastDragPosition.current as DragPosition;
            const focus = {
                x: (internalState.current.internalFocus as PixelPoint).x + (clientX - x),
                y: (internalState.current.internalFocus as PixelPoint).y + (clientY - y),
            };
            if (isValid(focus)) {
                setFocus(focus);
                lastDragPosition.current = { x: clientX, y: clientY };
            }
            onFocusChange({ crop: cropData, focus: internalState.current.relativeFocus });
        };

        const onFocalDragEnd = (): void => {
            lastDragPosition.current = null;
            document.removeEventListener('mousemove', onFocalDrag);
            document.removeEventListener('mouseup', onFocalDragEnd);
        };

        const onFocalDragStart = (e: MouseEvent): void => {
            lastDragPosition.current = { x: e.clientX, y: e.clientY };
            document.addEventListener('mousemove', onFocalDrag);
            document.addEventListener('mouseup', onFocalDragEnd);
        };

        // Add event listener using our hook
        useEventListener('mousedown', onFocalDragStart, focusPoint);

        /**
         * END LISTENER EVENT
         */

        return (
            <div className={focalPointPickerClassname}>
                <div className={element('container')}>
                    <div ref={focusPoint} className={element('focal-point')} style={getFocusPointStyle()} />
                    <ReactCropper
                        src={src}
                        data={cropData || undefined}
                        autoCropArea={autoCropArea}
                        style={style}
                        crossOrigin="anonymous"
                        enable={enabled}
                        viewMode={viewMode}
                        // eslint-disable-next-line lumapps/no-classname-strings
                        className="fp-cropper"
                        initialAspectRatio={NaN}
                        aspectRatio={aspectRatio}
                        preview={previewClass}
                        guides={guides}
                        cropend={onCropEnd}
                        ready={onImageReady}
                        zoomOnWheel={zoomOnWheel}
                    />
                </div>
            </div>
        );
    },
);

export { CropFocalPointPicker, isPointInRect };
