import curry from 'lodash/fp/curry';

import { dateTimeFormat } from '@lumapps/utils/date/dateTimeFormat';

import { Day } from '../../types';

/**
 * Parse Date with locale
 * Extract Day object.
 * */
export const getDayFromDate = curry((locale: string, date: Date): Day => {
    return {
        date,
        numeric: new Date(date).getDate(),
        name: {
            long: dateTimeFormat(locale, { weekday: 'long' }, date),
            short: dateTimeFormat(locale, { weekday: 'short' }, date),
        },
    };
});
