/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';

export const translateApi = new BaseApi({
    path: 'translate',
});

interface TranslateParams {
    content: string;
    targetLang: string;
    sourceLang?: string;
    isContentHTML?: boolean;
}

/**
 * Use gTranslate to translate a content.
 */
export const gTranslate = async ({ content, targetLang, sourceLang, isContentHTML }: TranslateParams) => {
    const { data } = await translateApi.post<{ contentText: string }>('get', {
        contentText: content,
        sourceFormat: isContentHTML ? 'html' : 'text',
        targetLang,
        sourceLang,
    });
    return data.contentText;
};
