/* @ngInject */
function SharepointSiteFactory($resource, Config) {
    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/onedrive/site/:methodKey`,
        {},
        {
            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },
        },
    );
}

angular.module('Factories').factory('SharepointSiteFactory', SharepointSiteFactory);

export { SharepointSiteFactory };
