/* eslint-disable require-jsdoc-except/require-jsdoc */
/* eslint-disable no-use-before-define */
/* eslint-disable lumapps/file-format */
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import {
    getPlannerTeamPlans,
    getPlannerUserPlans,
    getPlannerBucketBySpecificPlans,
    getWorkspace,
    // eslint-disable-next-line import/extensions
} from 'components/components/tasks/api/TaskApi.js';

import { GOOGLE_PROVIDERS, MS_PROVIDERS } from 'front/modules/tasks/js/tasks_constant';

/////////////////////////////

function WidgetTasksSettingsController(Community, Content, Instance, LxNotificationService, Translation, User, Widget) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetTasksSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current widget instance.
     *
     * @type {Object}
     */

    let _currentWidgetInstance = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The endpoint name used to list communities MS.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    widgetTasksSettings.COMMUNITY_LIST_ENDPOINT = 'listByType';

    /**
     * The list key used to list communities MS.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    widgetTasksSettings.COMMUNITY_LIST_KEY = 'ms-community-list-key';

    /////////////////////////////

    widgetTasksSettings.Community = Community;

    /////////////////////////////

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Function that initialize the current provider.
     *
     * @return {string} The current provider tag.
     */
    function _getDefaultProvider() {
        const currentProvider = getProperty(['provider']);
        if (angular.isUndefinedOrEmpty(currentProvider)) {
            return 'lumapps';
        }

        return currentProvider;
    }

    /*
     * Function that set the default value for some properties.
     */
    function initFields() {
        widgetTasksSettings.providerList = User.isMicrosoft() ? MS_PROVIDERS : GOOGLE_PROVIDERS;

        const properties = get(_currentWidgetInstance, 'properties');

        if (angular.isUndefined(properties.isTeamScoped)) {
            updateWidget('isTeamScoped', Content.getCurrent().type === 'community');
        }
        if (angular.isUndefined(properties.alreadyDoneDay)) {
            updateWidget('alreadyDoneDay', 2);
        }
        if (angular.isUndefined(properties.viewMode)) {
            updateWidget('viewMode', 'list');
        }
    }

    /**
     * Function that retrieve a specific properties of the current widget.
     *
     * @param  {string} key The property's key.
     * @return {any}    The property's value.
     */
    function getProperty(key) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        if (currentWidget.uuid !== _currentWidgetInstance.uuid) {
            init();
        }

        return get(properties, key);
    }

    /**
     * Function that update a value of a properties of the current widget.
     *
     * @param {string} key   The property's key.
     * @param {string} value The property's value.
     */
    function updateWidget(key, value) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        if (currentWidget.uuid !== _currentWidgetInstance.uuid) {
            init();
        }

        set(properties, [key], value);
        currentWidget.properties = properties;
    }

    /////////////////////////////
    //                         //
    //    Public functions     //
    //                         //
    /////////////////////////////

    /*
     * Function that select a community.
     */
    function selectCommunity() {
        let currentCommunity = getProperty(['community']);
        if (
            angular.isUndefinedOrEmpty(currentCommunity) ||
            findIndex(widgetTasksSettings.communityList, ['id', currentCommunity.id]) === -1
        ) {
            updateWidget(
                'community',
                widgetTasksSettings.communityList.length > 0 ? widgetTasksSettings.communityList[0] : {},
            );
        }

        currentCommunity = getProperty(['community']);
        if (!angular.isUndefinedOrEmpty(currentCommunity)) {
            getWorkspace(currentCommunity.workspace).then((res) => {
                if (res.data.group && res.data.group.apiId) {
                    currentCommunity.officeId = get(res, 'data.group.apiId');
                    updateWidget('community', currentCommunity);
                    fetchPlannerPlans(true);
                }
            });
        }
    }

    /*
     * Fetch the planner buckets based on the current selected plan.
     */
    function fetchPlannerBuckets() {
        const plan = getProperty(['plan']);

        if (angular.isDefinedAndFilled([plan], 'every')) {
            getPlannerBucketBySpecificPlans(plan.id)
                .then((res) => {
                    const currentBucket = getProperty(['bucket']);
                    if (
                        angular.isDefinedAndFilled(currentBucket) &&
                        findIndex(res.data.items, ['id', currentBucket.id]) !== -1
                    ) {
                        updateWidget('bucket', currentBucket);
                    } else {
                        updateWidget('bucket', {});
                    }
                    widgetTasksSettings.bucketsList = res.data.items;
                })
                .catch(() => {
                    updateWidget('bucket', {});
                    widgetTasksSettings.bucketsList = [];
                    LxNotificationService.error(
                        Translation.translate('FRONT.WIDGET_TASKS.SETTINGS.ERROR.FETCH_BUCKETS_ERROR'),
                    );
                });
        } else {
            updateWidget('bucket', {});
            updateWidget('plan', {});
        }
    }

    /**
     * Fetch the planner plans for the current user or the current selected community.
     *
     * @param {boolean} isTeamScoped Define if we are in a context of a community or not.
     */
    function fetchPlannerPlans(isTeamScoped) {
        const func = isTeamScoped ? getPlannerTeamPlans : getPlannerUserPlans;

        func(isTeamScoped ? getProperty(['community', 'officeId']) : null)
            .then((res) => {
                const currentPlan = getProperty(['plan']);
                if (
                    angular.isDefinedAndFilled(currentPlan) &&
                    findIndex(res.data.items, ['id', currentPlan.id]) !== -1
                ) {
                    updateWidget('plan', currentPlan);
                } else {
                    updateWidget('plan', res.data.items && res.data.items[0] && isTeamScoped ? res.data.items[0] : {});
                    updateWidget('bucket', {});
                }
                widgetTasksSettings.plansList = res.data.items;
                fetchPlannerBuckets();
            })
            .catch(() => {
                updateWidget('plan', {});
                updateWidget('bucket', {});
                widgetTasksSettings.plansList = [];
                LxNotificationService.error(
                    Translation.translate('FRONT.WIDGET_TASKS.SETTINGS.ERROR.USER_RIGHTS_ERROR'),
                );
            });
    }

    /**
     * Switch widget view mode.
     *
     * @param {string} viewMode The wanted view mode.
     */
    function switchViewMode(viewMode) {
        updateWidget('viewMode', viewMode);
    }

    /**
     * Event Triggered when the community/user context change.
     */
    function onTeamScopedChange() {
        const currentisTeamScoped = getProperty(['isTeamScoped']);
        const currentProvider = getProperty(['provider']);

        if (currentProvider === 'planner') {
            currentisTeamScoped ? selectCommunity() : fetchPlannerPlans(false);
        }
    }

    /**
     * Function that determine if a current bucket is set or not.
     *
     * @return {boolean} return true if the current bucket is not defined.
     */
    function isBucketEmpty() {
        return isEmpty(getProperty(['bucket']));
    }

    /**
     * Function that determine if a current community is set or not.
     *
     * @return {boolean} return true if the current community is not defined.
     */
    function isCommunityEmpty() {
        return isEmpty(getProperty(['community']));
    }

    /**
     * Function that determine if a current plan is set or not.
     *
     * @return {boolean} return true if the current plan is not defined.
     */
    function isPlanEmpty() {
        return isEmpty(getProperty(['plan']));
    }

    /**
     * Function that change the current selected provider.
     *
     * @param {string} [provider='planner'] The provider to select.
     */
    function toggleProvider(provider = _getDefaultProvider()) {
        updateWidget('provider', provider);

        if (provider === 'planner') {
            const isTeamScoped = getProperty(['isTeamScoped']);
            isTeamScoped ? selectCommunity() : fetchPlannerPlans(false);
        } else {
            updateWidget('community', {});
            updateWidget('plan', {});
            updateWidget('bucket', {});
            updateWidget('isTeamScoped', false);
        }
    }

    /////////////////////////////

    widgetTasksSettings.toggleProvider = toggleProvider;
    widgetTasksSettings.onTeamScopedChange = onTeamScopedChange;
    widgetTasksSettings.fetchPlannerBuckets = fetchPlannerBuckets;
    widgetTasksSettings.isBucketEmpty = isBucketEmpty;
    widgetTasksSettings.isCommunityEmpty = isCommunityEmpty;
    widgetTasksSettings.isPlanEmpty = isPlanEmpty;
    widgetTasksSettings.selectCommunity = selectCommunity;
    widgetTasksSettings.switchViewMode = switchViewMode;

    /*
     * The Initialize function. It fetch the current selectable communities and set the default settings parameters.
     */
    function init() {
        widgetTasksSettings.currentLanguage = Translation.getInputLanguage();
        _currentWidgetInstance = Widget.getCurrent();

        const params = {
            communityType: 'microsoft',
            instance: Instance.getCurrentInstanceId(),
        };

        const projection = {
            items: {
                communityType: true,
                id: true,
                instance: true,
                slug: true,
                title: true,
                uid: true,
            },
        };

        Community.listCommunityByType(
            params,
            widgetTasksSettings.COMMUNITY_LIST_KEY,
            projection,
            widgetTasksSettings.COMMUNITY_LIST_ENDPOINT,
        ).then((response) => {
            widgetTasksSettings.communityList = response;
        }).finally(() => {
            initFields();
            widgetTasksSettings.toggleProvider(_getDefaultProvider());
        });
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetTasksSettingsController', WidgetTasksSettingsController);

/////////////////////////////

export { WidgetTasksSettingsController };
