import range from 'lodash/range';

import { Editor, Path, Transforms } from '@lumapps/wrex/slate';
import { getSibling } from '@lumapps/wrex/slate/utils/getSibling';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';
import type { Wrex } from '@lumapps/wrex/types';

import { isList } from '../../utils/isList';
import { isListItem } from '../../utils/isListItem';
import { Normalize } from './types';

/**
 * Normalize list children (not list item).
 */
const normalizeListChildren: Normalize = (editor, [originalNode, originalPath]) => {
    // Skip if at root path or if is list item.
    if (!originalPath.length || isListItem(originalNode)) {
        return false;
    }
    const [parent] = Editor.parent(editor, originalPath);
    // Skip if parent is not a list.
    if (!isList(parent)) {
        return false;
    }

    // Move nested list in their <li> parent (for GDoc support)
    if (isList(originalNode)) {
        const [before, beforePath] = getSibling(editor, originalPath, 'before') ?? [null, null];
        if (!before || !beforePath || !isElement(before)) {
            return false;
        }

        const indexInPrevious = before.children.length;
        Transforms.moveNodes(editor, { at: originalPath, to: [...beforePath, indexInPrevious] });
        return true;
    }

    // Remove list children that are not a list item.
    Transforms.removeNodes(editor, { at: originalPath });
    return true;
};

/**
 * Normalize list node (ordered or unordered).
 */
export const normalizeList: Normalize = (editor, [originalNode, originalPath]) => {
    if (normalizeListChildren(editor, [originalNode, originalPath])) {
        return true;
    }
    if (!isList(originalNode)) {
        return false;
    }
    const pathRef = Editor.pathRef(editor, originalPath);
    let node = Editor.node(editor, pathRef.current as Path)[0] as Wrex.Element;

    Editor.withoutNormalizing(editor, () => {
        const [before, beforePath] = getSibling(editor, pathRef.current as Path, 'before') ?? [null, null];
        if (isElement(before) && beforePath && before.type === node.type && before.children.length) {
            // Merge same type list before.
            const path = pathRef.current as Path;
            for (const index of range(0, before.children.length).reverse()) {
                Transforms.moveNodes(editor, {
                    at: [...beforePath, index],
                    to: [...path, 0],
                });
            }
            Transforms.removeNodes(editor, { at: beforePath });

            node = Editor.node(editor, pathRef.current as Path)[0] as Wrex.Element;
        }

        const [after, afterPath] = getSibling(editor, pathRef.current as Path, 'after') ?? [null, null];
        if (isElement(after) && afterPath && after.type === node.type && node.children.length) {
            // Merge same type list after.
            const path = pathRef.current as Path;
            for (const index of range(0, node.children.length).reverse()) {
                Transforms.moveNodes(editor, {
                    at: [...path, index],
                    to: [...afterPath, 0],
                });
            }
            node = Editor.node(editor, pathRef.current as Path)[0] as Wrex.Element;
        }

        if (!node.children.length || !node.children.every(isListItem)) {
            // Remove list if not every children is a list item.
            Transforms.removeNodes(editor, { at: pathRef.current as Path });
        }

        // if list is not a direct child of the editor
        // AND its parent is not a list item
        // Then we lift the list to the top level
        if (pathRef.current && pathRef.current.length > 1 && !isListItem(Editor.parent(editor, pathRef.current)[0])) {
            // Lift list node if not at first level, or within another list, a list should always be a direct child of the editor.
            Transforms.liftNodes(editor, {
                at: pathRef.current as Path,
                mode: 'highest',
            });
        }
    });

    pathRef.unref();
    return true;
};
