export function getImageSizeForObjectUrl(url: string) {
    const image = new Image();
    image.src = url;

    return new Promise<{ width: number; height: number }>((resolve, reject) => {
        image.onerror = reject;
        image.onload = () => {
            resolve({ width: image.naturalWidth, height: image.naturalHeight });
        };
    });
}

/** Load image from file and get image height & width. */
export function getImageSize(imageFile: File) {
    return getImageSizeForObjectUrl(URL.createObjectURL(imageFile));
}
