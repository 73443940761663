import { loadFroala } from 'common/froala/modules/froala_utils';

// eslint-disable-next-line require-jsdoc
function FroalaLoaderService($injector, $ocLazyLoad, Translation, Utils) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Load Froala, used by the React world.
     * @param  {AngularService} $injector   Angular's `$injector` service.
     * @param  {Service}        $ocLazyLoad The $ocLazyLoad service.
     * @param  {Service}        Translation The Translation service.
     * @param  {Service}        Utils       The Utils service.
     * @return {Promise}        The modules resolution promise.
     */
    function getFroala() {
        return loadFroala($injector, $ocLazyLoad, Translation, Utils);
    }

    /////////////////////////////

    service.getFroala = getFroala;
}

/////////////////////////////

angular.module('Services').service('FroalaLoader', FroalaLoaderService);

/////////////////////////////

export { FroalaLoaderService };
