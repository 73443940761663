import React from 'react';

import { isExtendedAnalyticsEnabled } from '@lumapps/analytics';
import { CommunityView } from '@lumapps/communities/constants';
import { SPACES } from '@lumapps/communities/keys';
import { communityEditLayout } from '@lumapps/communities/routes';
import { communityAnalytics } from '@lumapps/community-analytics/routes';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiChartLine, mdiMenu, mdiPencil, mdiSettings } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { SpaceViews } from '@lumapps/spaces/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';

interface SpacesSettingsMenuEntriesProps {
    scope: string;
    onSpaceEdit(): void;
    onSpaceNavigationEdit(): void;
}

export const SpacesSettingsMenuEntries: React.FC<SpacesSettingsMenuEntriesProps> = ({
    scope,
    onSpaceEdit,
    onSpaceNavigationEdit,
}) => {
    const { translateKey } = useTranslate();
    const { isSpace, isSpaceFeatureEnabled, id, canEdit, view } = useSpace();
    const extendedAnalyticsEnabled = useSelector(isExtendedAnalyticsEnabled);
    const { get } = useDataAttributes(scope);

    if (!isSpace || !isSpaceFeatureEnabled || !id || !canEdit) {
        return null;
    }

    return (
        <Menu.Section label={translateKey(GLOBAL.SPACE)} {...get({ element: 'space', action: 'edit' })}>
            {view === SpaceViews.home && (
                <Menu.Item
                    icon={mdiPencil}
                    as={RouterLink}
                    to={communityEditLayout(id, CommunityView.dashboard)}
                    {...get({ element: 'space-layout-edit', action: 'settings' })}
                >
                    {translateKey(SPACES.EDIT_PAGE)}
                </Menu.Item>
            )}

            <Menu.Item
                icon={mdiMenu}
                onClick={onSpaceNavigationEdit}
                {...get({ element: 'space-navigation-edit', action: 'edit-navigation' })}
            >
                {translateKey(SPACES.EDIT_NAVIGATION)}
            </Menu.Item>

            {extendedAnalyticsEnabled && (
                <Menu.Item
                    icon={mdiChartLine}
                    as={RouterLink}
                    to={communityAnalytics(id)}
                    {...get({ element: 'space-edit', action: 'analytics' })}
                >
                    {translateKey(GLOBAL.ANALYTICS)}
                </Menu.Item>
            )}
            <Menu.Item icon={mdiSettings} onClick={onSpaceEdit} {...get({ element: 'space-edit', action: 'settings' })}>
                {translateKey(GLOBAL.SETTINGS)}
            </Menu.Item>
        </Menu.Section>
    );
};
