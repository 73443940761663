import { Editor } from 'slate';

import type { Wrex } from '../types';
import { isElement } from './utils/isElement';
import { isText } from './utils/isText';

/**
 * Format a slate fragment.
 * Keeps text nodes at the start of the fragment but wrap other text nodes in the default block.
 */
export const formatFragment = (editor: Editor, nodes: Wrex.Nodes, defaultBlockType: string) => {
    const input = [...nodes];
    const output: { textChunks: Wrex.Nodes; nodes: Wrex.Element[] } = { textChunks: [], nodes: [] };
    let beforeFirstBlock = true;

    let node: Wrex.Node | undefined;

    // Keep text nodes before the first block.
    do {
        node = input.shift();
        if (isElement(node) && Editor.isBlock(editor, node)) {
            output.nodes.push(node);

            beforeFirstBlock = false;
            break;
        }
        if (node) {
            output.textChunks.push(node);
        }
    } while (beforeFirstBlock && node);

    let textChunk: Wrex.Text[] = [];
    do {
        node = input.shift();

        if (textChunk.length && (!node || (isElement(node) && Editor.isBlock(editor, node)))) {
            if (textChunk.every(({ text }) => !text.trim())) {
                // Trim white space only text node.
                textChunk = [{ text: '' }];
            }

            output.nodes.push({ type: defaultBlockType, children: textChunk });
            textChunk = [];
        }
        if (isText(node)) {
            textChunk.push(node);
        } else if (isElement(node)) {
            output.nodes.push(node);
        }
    } while (node);

    return output;
};
