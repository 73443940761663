import { getWysiwygToolbar } from '@lumapps/wrex-legacy-froala/constants';

/**
 * Load the Froala module asynchronously.
 *
 * @param  {AngularService} $injector   Angular's `$injector` service.
 * @param  {Service}        $ocLazyLoad The $ocLazyLoad service.
 * @param  {Service}        Translation The Translation service.
 * @param  {Service}        Utils       The Utils service.
 * @return {Promise}        The modules resolution promise.
 */
const loadFroala = async ($injector, $ocLazyLoad, Translation, Utils) => {
    // eslint-disable-next-line no-inline-comments
    const modules = await import(/* webpackChunkName: "froala", webpackPrefetch: true */ 'common/froala/app');

    try {
        await Promise.all([
            $ocLazyLoad.inject(modules.FROALA.name),
            $ocLazyLoad.inject('Services'),
            $ocLazyLoad.inject('Constants'),
        ]);

        $injector.get('FroalaService').init();

        let allPromises = Promise.resolve();
        if (!Translation.froalaLangsLoaded) {
            const promises = [];
            angular.forEach(Translation.allLangs, function forEachLangsToDownload(lang) {
                if (lang === Translation.DEFAULT_LANG) {
                    return;
                }

                const froalaLang = Translation.froalaLangs[lang] || lang;
                promises.push(
                    Utils.injectScript(
                        `langs/froala-editor/js/languages/${froalaLang}.js`,
                        `lang-froala-${lang}`,
                        'body',
                        'lang-script-front-office-en',
                        true,
                    ),
                );
            });

            allPromises = Promise.all(promises);
            allPromises.then(() => {
                Translation.froalaLangsLoaded = true;
            });
        }

        return allPromises;
    } catch (exception) {
        throw new Error(exception);
    }
};

/////////////////////////////

export { getWysiwygToolbar, loadFroala };
