export enum PREVIEW {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82276321
     */
    INSERT_LINK_DIALOG_PREVIEW_BLOCK_ERROR_NOT_FOUND_URL = 'FRONT.INSERT_LINK_DIALOG.PREVIEW_BLOCK.ERROR.NOT_FOUND_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82276339
     */
    INSERT_LINK_DIALOG_PREVIEW_BLOCK_ERROR_DEFAULT_ERROR = 'FRONT.INSERT_LINK_DIALOG.PREVIEW_BLOCK.ERROR.DEFAULT_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=177496585
     */
    EDIT_LINK_PREVIEW = 'GLOBAL.EDIT_LINK_PREVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82273279
     */
    INSERT_LINK_DIALOG_TITLE = 'FRONT.INSERT_LINK_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82273308
     */
    INSERT_LINK_DIALOG_URL_FIELD_LABEL = 'FRONT.INSERT_LINK_DIALOG.URL_FIELD.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82273317
     */
    INSERT_LINK_DIALOG_URL_FIELD_ERROR_BAD_FORMAT = 'FRONT.INSERT_LINK_DIALOG.URL_FIELD.ERROR.BAD_FORMAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901081
     */
    INSERT_VIDEO_DIALOG_URL_FIELD_ERROR_BAD_FORMAT = 'FRONT.INSERT_VIDEO_DIALOG.URL_FIELD.ERROR.BAD_FORMAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901097
     */
    INSERT_VIDEO_DIALOG_URL_FIELD_HELPER = 'FRONT.INSERT_VIDEO_DIALOG.URL_FIELD.HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901107
     */
    INSERT_VIDEO_DIALOG_URL_FIELD_LABEL = 'FRONT.INSERT_VIDEO_DIALOG.URL_FIELD.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901109
     */
    INSERT_VIDEO_DIALOG_TITLE = 'FRONT.INSERT_VIDEO_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170863493
     */
    EMBED_MAP = 'WREX_EMBEDDED_MAP.EMBED_MAP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214945226
     */
    INSERT_MAP_DIALOG_GMAP_URL_BAD_FORMAT = 'WREX_EMBEDDED_MAP.INSERT_MAP_DIALOG_GMAP_URL_BAD_FORMAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170863630
     */
    INSERT_MAP_DIALOG_INFO_MESSAGE = 'WREX_EMBEDDED_MAP.INSERT_MAP_DIALOG_INFO_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170861683
     */
    INSERT_MAP_DIALOG_URL_FIELD_ERROR_BAD_FORMAT = 'WREX_EMBEDDED_MAP.INSERT_MAP_DIALOG.URL_FIELD.ERROR_BAD_FORMAT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=516520070
     */
    LINK_PREVIEW_SWITCH_THUMBNAIL = 'LUMX.LINK_PREVIEW_SWITCH_THUMBNAIL',
}
