import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox } from '@lumapps/lumx/react';
import { getEmbedUrl } from '@lumapps/medias/utils';

export interface VideoPreviewProps {
    /** video title */
    title?: string;
    /** video URL */
    url: string;
    /** The prefix to use on every classname. */
    classNamePrefix: string;
}

export const VideoPreview: React.FC<VideoPreviewProps> = ({ title, url, classNamePrefix }) => {
    const { element } = useClassnames(classNamePrefix);

    return (
        <div className={element('preview-wrapper')}>
            <FlexBox className={element('video-wrapper')}>
                <iframe
                    className={element('video-iframe')}
                    title={title}
                    src={getEmbedUrl(url)}
                    frameBorder="0"
                    allowFullScreen
                />
            </FlexBox>
        </div>
    );
};
