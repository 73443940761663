(function IIFE() {
    'use strict';

    /////////////////////////////

    function StepperDialogService($rootScope, LxDialogService, Utils) {
        'ngInject';

        var service = {};

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The id of the dialog that is opened.
         *
         * @type {string}
         */
        var _dialogId;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The index of the currently active step.
         *
         * @type {number}
         */
        service.activeStep = 0;

        /**
         * Contains multiple statuses about the current step (loading, first, last, ...).
         *
         * @type {Object}
         */
        service.isStep = {
            first: true,
            last: false,
            loading: false,
        };

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Cancel the stepper.
         */
        function cancel() {
            $rootScope.$broadcast('ls-stepper__cancel', _dialogId);
        }

        /**
         * Close the given stepper dialog.
         *
         * @param {boolean} [canceled=false] Indicates if the stepper has been canceled.
         */
        function closeStepperDialog(canceled) {
            LxDialogService.close(_dialogId, canceled);
        }

        /**
         * Go to the next step.
         */
        function nextStep() {
            $rootScope.$broadcast('ls-stepper__submit-step', _dialogId, service.activeStep);
        }

        /**
         * Open the given stepper dialog.
         *
         * @param {string} dialogId The id of the stepper dialog to open.
         */
        function openStepperDialog(dialogId) {
            _dialogId = dialogId;

            Utils.waitForAndExecute('#' + _dialogId);
        }

        /**
         * Go to the previous step.
         */
        function previousStep() {
            $rootScope.$broadcast('ls-stepper__previous-step', _dialogId, service.activeStep);
        }

        /////////////////////////////

        service.cancel = cancel;
        service.close = closeStepperDialog;
        service.nextStep = nextStep;
        service.open = openStepperDialog;
        service.previousStep = previousStep;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the dialog closes, reset all the steps status.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering this method.
         */
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on('lx-dialog__close-end', function onStepperDialogScrollEnd(evt, dialogId) {
            if (_dialogId === dialogId) {
                _dialogId = undefined;
                service.activeStep = 0;
                service.isStep = {
                    first: true,
                    last: false,
                    loading: false,
                };
            }
        });

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('StepperDialog', StepperDialogService);
})();
