import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import * as types from '../../../types';
import { Style as StyleService } from '../../../../services/styles';

/**
 * Renders an admissible drop location for the icon.
 */
export class IconArea extends React.PureComponent {
    static propTypes = {
        /** The `<IconProxy />` element. */
        children: propTypes.node,
        name: types.property.isRequired,
        /** Called when set and when the mouse hovers this area. */
        onEnter: propTypes.func,
        /** Whether the area is hovered with a dragged icon or not. */
        value: propTypes.bool,
    };

    constructor(props) {
        super(props);
        this.onEnter = () => {
            const { onEnter } = this.props;
            if (!onEnter) {
                return;
            }
            onEnter(this.props.name);
        };
    }

    render() {
        const { value, children } = this.props;

        return (
            <div
                onMouseEnter={this.onEnter}
                className={classNames(
                    'widget-style-area__icon-drop',
                    !children && 'widget-style-area__icon-drop--free',
                    value && 'widget-style-area__icon-drop--over',
                )}
            >
                {children}
            </div>
        );
    }
}
