import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size, UserBlockSize } from '@lumapps/lumx/react';
import { UserBlock, UserBlockProps } from '@lumapps/user/components/UserBlock';

import { CLASSNAME } from '../../constants';
import { AttributeProps } from './BaseAttribute';

export type UserAttributeProps = Exclude<UserBlockProps, 'size'> &
    Partial<AttributeProps> & { size?: Extract<UserBlockSize, 's' | 'xs'> };

/**
 * Component to be used for displaying users while using the `GenericEntityBlock`
 * pattern.
 *
 * @param UserBlockProps
 * @family Blocks
 * @returns UserAttribute
 */
export const UserAttribute: React.FC<UserAttributeProps> = ({ className, size = Size.s, ...props }) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <UserBlock
            {...props}
            size={size}
            // Explicitely remove the email from the user block to avoid multiple lines
            email={undefined}
            className={element('user', [className])}
        />
    );
};
