import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Button, ButtonProps, Emphasis, ProgressCircular } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import './index.scss';

export interface LoadMoreButtonProps extends Partial<ButtonProps> {
    /** on load more click */
    onClick: () => void;
    /** label to be used instead of Load more */
    label?: string;
    /** whether the list is loading or not */
    isLoading?: boolean;
    /** custom button classname */
    className?: string;
    /** custom loading component */
    customLoading?: React.ReactNode;
}

const CLASSNAME = 'load-more';
/**
 * Component to be used for displaying a load more button usually displayed on pages where we
 * are displaying a list of results and there are more results to fetch.
 *
 * @family Buttons
 * @param LoadMoreProps
 * @returns LoadMore
 */
export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
    className,
    onClick,
    isLoading = false,
    label,
    customLoading,
    ...rest
}) => {
    const { translateKey } = useTranslate();
    const { block } = useClassnames(CLASSNAME);
    const loading = customLoading ? (
        <div className={block()}>{customLoading}</div>
    ) : (
        <ProgressCircular
            className={block({
                loading: !customLoading,
            })}
        />
    );

    if (isLoading) {
        return loading;
    }

    return (
        <Button onClick={onClick} emphasis={Emphasis.medium} hasBackground className={block([className])} {...rest}>
            {label || translateKey(GLOBAL.MORE_RESULTS)}
        </Button>
    );
};
