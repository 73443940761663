import React from 'react';

import { GenericEntityPicker, GenericEntityPickerProps } from '@lumapps/pickers/components/GenericEntityPicker';
import { useTranslate } from '@lumapps/translations';

import { DEFAULT_SCOPE } from '../../constants';
import { useMetadataPicker } from '../../hooks/useMetadataPicker';
import { PickerMetaData, MetadataPickerProps } from '../../types';

export interface MetadataPickerFieldProps
    extends MetadataPickerProps,
        Partial<GenericEntityPickerProps<PickerMetaData>> {
    /**
     * The id of the selected metadata
     */
    value?: PickerMetaData['id'];
    /**
     * Action on metadata select.
     * Gets the selected metadata as parameter.
     */
    onChange(values?: PickerMetaData): void;
}

/**
 * A picker to select a single metadata.
 * Checkout the package's README.md for more info on how it works.
 *
 * * Unfortunately, we can't use the lumx `SelectFieldSimple` component here as it doesn't manage search / loading / error states
 * * and we don't have time to implement these features at the moment of development.
 *
 * TODO: Harmonize the multiple / simple selects to have a coherent experience.
 */
export const MetadataPickerField = ({
    value,
    familyId,
    instanceId,
    onChange,
    defaultDictionary = {},
    extraRequestFields: requestFields,
    scope = DEFAULT_SCOPE,
    useInstanceMetadata,
    showMultipleLevels,
    ...selectProps
}: MetadataPickerFieldProps) => {
    const { translateObject } = useTranslate();

    const {
        status,
        isOpen,
        metadataDictionary,
        fetchMetadataFamily,
        searchText,
        setSearchText,
        filteredChoices,
        setIsOpen,
        handleSelect,
        getValueName,
    } = useMetadataPicker({
        familyId,
        instanceId,
        defaultDictionary,
        onChange,
        extraRequestFields: requestFields,
        useInstanceMetadata,
        showMultipleLevels,
    });

    return (
        <GenericEntityPicker<PickerMetaData>
            isOpen={isOpen}
            displayedValue={
                (value && metadataDictionary[value]
                    ? (translateObject(metadataDictionary[value].name) as string)
                    : value) || ''
            }
            onInputClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            onDropdownClose={() => setIsOpen(false)}
            onRetry={fetchMetadataFamily}
            status={status}
            choices={filteredChoices}
            choiceKeySelector={(metadata) => metadata?.id}
            choiceSelectedSelector={(metadata) => (value ? metadata?.id === value : false)}
            choiceDisplayedValueSelector={getValueName}
            onChange={handleSelect}
            searchValue={searchText}
            onChangeSearchValue={setSearchText}
            onClear={() => onChange(undefined)}
            scope={scope}
            {...selectProps}
        />
    );
};
