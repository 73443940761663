import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { MAX_ASIDE_SEARCH_RESULTS, ENGINES, SEARCH_API_TIMEOUT } from '../constants';
import { DriveSearchResult, SearchResults, SearchParams, SearchTab } from '../types';
import { createSearchEngineFilter, createSearchEngineMetadata } from '../utils';

const driveSearchApi = new BaseApi({ path: 'drive/files' });

export interface DriveSearchResults extends SearchResults {
    items: DriveSearchResult[];
}

/**
 * Returns a promise with the search results from the drive API.
 * @param params parameters that will be used to search on Google Drive
 */
const search = (params: SearchParams): Promise<DriveSearchResults> => {
    const { query, maxResults = MAX_ASIDE_SEARCH_RESULTS, timeout = SEARCH_API_TIMEOUT, cursor } = params;

    return driveSearchApi
        .getCacheFirst<DriveSearchResults>('search', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
            params: { maxResults, fullText: decodeURIComponentSafe(query), cursor },
            timeout,
        })
        .then((response) => {
            const { items = [], more, cursor: newCursor } = response.data;

            /**
             * Since google drive does not return the selected filters from the backend,
             * we need to add them to the response so that the UI shows them appropriately.
             *
             * So we add the necessary information (filters and metadata) so that the UI
             * can properly render the results.
             */
            const filters = [createSearchEngineFilter(ENGINES.DRIVE)];

            const metadata: Record<string, SearchTab> = {
                [ENGINES.DRIVE]: createSearchEngineMetadata(ENGINES.DRIVE),
            };
            return { items, more, filters, metadata, cursor: newCursor };
        });
};

export { driveSearchApi, search };
