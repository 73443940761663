import React from 'react';

import { COMMUNITIES } from '@lumapps/communities/keys';
import { COMMUNITY_ADMIN } from '@lumapps/community-admin/keys';
import { admin } from '@lumapps/community-admin/routes';
import { adminCommunityTemplate } from '@lumapps/community-templates/routes';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiGoogleCirclesExtended } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

export interface CommunitiesSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

export const CommunitiesSideNavigation = (props: Partial<CommunitiesSideNavigationProps>) => {
    const { isAllowed, isTemplatesAllowed } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(COMMUNITIES.NAV)} icon={mdiGoogleCirclesExtended} id="communities">
            <SideNavigationItem label={translateKey(COMMUNITIES.NAV)} route={admin()} />
            {isTemplatesAllowed && (
                <SideNavigationItem label={translateKey(COMMUNITY_ADMIN.TEMPLATES)} route={adminCommunityTemplate()} />
            )}
        </SideNavigationItem>
    );
};

CommunitiesSideNavigation.displayName = 'SideNavigationItem';
