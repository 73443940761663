import map from 'lodash/fp/map';

import { Answers, Choices, RawAnswers } from '../types';
import { gerPercentage } from './getPercentage';

// Format the api answer to a new format with quantity & percentage information
export const formatAnswers = (choices: Choices, answers: RawAnswers, answersCount: number): Answers => {
    return map((choice) => {
        const quantity = answers[choice.value] || 0;
        const percentage = gerPercentage(quantity, answersCount);
        return { ...choice, quantity, percentage };
    }, choices);
};
