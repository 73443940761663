(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSubNavCustomSettingsController($rootScope, $scope, $timeout, Content, MainNav, Translation, Widget) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The current language to use in the nav.
         *
         * @type {string}
         */
        var _lang;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.MainNav = MainNav;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Select a nav item.
         *
         * @param {string} id   The id of the nav item we want to select.
         * @param {string} lang The language of the nav item we want to select.
         */
        function _selectNavItem(id, lang) {
            if (angular.isUndefined(vm.selectedProperty.navSelected)) {
                vm.selectedProperty.navSelected = {};
            }

            vm.selectedProperty.navSelected[lang] = {
                slugFull: vm.property,
                uuid: id,
            };

            $timeout(function delayBroadcast() {
                $rootScope.$broadcast('widget-sub-nav-settings', Widget.getCurrent().uuid);
            });
        }

        /**
         * Sets the current language of the nav.
         */
        function _setCurrentLanguage() {
            _lang = Translation.inputLanguage;
        }

        /**
         * Figure out what the current selected property is.
         * Handles backward compatibility and multi language settings.
         */
        function _setSelectedProperty() {
            if (angular.isDefinedAndFilled(vm.property)) {
                vm.widget.properties[_lang][vm.property] = vm.widget.properties[_lang][vm.property] || {};
                vm.selectedProperty = vm.widget.properties[_lang][vm.property];

                if (vm.property === '__default') {
                    vm.selectedProperty.useDefaultSettings = false;
                    vm.selectedProperty.isDefault = true;
                }

                // Backward cleaning.
                delete vm.widget.properties.listCurrentSub;
                delete vm.widget.properties.navSelected;
                delete vm.widget.properties.showHidden;
                delete vm.widget.properties.showParent;
            } else {
                vm.selectedProperty = vm.widget.properties;
            }

            if (angular.isUndefinedOrEmpty(vm.selectedProperty.listCurrentSub)) {
                vm.selectedProperty.listCurrentSub = 'pick';
            }

            if (angular.isUndefined(vm.selectedProperty.useDefaultSettings)) {
                vm.selectedProperty.useDefaultSettings = true;
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Set current item children.
         */
        function setListCurrentSub() {
            if (_.get(vm.selectedProperty, 'listCurrentSub') === 'list') {
                _selectNavItem(Content.getCurrent().id, Translation.inputLanguage);
            }
        }

        /////////////////////////////

        vm.setListCurrentSub = setListCurrentSub;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the input language changes, re-initialize the directive.
         */
        $scope.$on('inputLanguage', function onLanguageChange() {
            vm.init();
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            _setCurrentLanguage();

            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            properties[_lang] = properties[_lang] || {};
            _setSelectedProperty();

            vm.navItemContentList = MainNav.findNavItemsById(Content.getCurrent().id);
        };

        vm.init();
    }

    /////////////////////////////

    function WidgetSubNavCustomSettingsDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: WidgetSubNavCustomSettingsController,
            controllerAs: 'vm',
            replace: true,
            require: ['widgetSubNavCustomSettings', '^widget'],
            restrict: 'E',
            scope: {
                property: '@',
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-sub-nav/views/partials/widget-sub-nav-custom-settings.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetSubNavCustomSettings', WidgetSubNavCustomSettingsDirective);
})();
