import { mdiClose, mdiMagnify } from '@lumapps/lumx/icons';

/////////////////////////////

function LsSearchFilterController(
    $element,
    $scope,
    $timeout,
    LxDropdownService,
    LxNotificationService,
    LxUtilsService,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The debounced autocomplete function.
     *
     * @type {Function}
     */
    let _debouncedAutocomplete;

    /**
     * The component input element.
     *
     * @type {Element}
     */
    let _input;

    /**
     * Whether a choice is slected or not.
     *
     * @type {boolean}
     */
    let _itemSelected = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The active choice index in the choices list.
     *
     * @type {number}
     */
    vm.activeChoiceIndex = -1;

    /**
     * The component dropdown id.
     *
     * @type {string}
     */
    vm.dropdownId = LxUtilsService.generateUUID();

    /**
     * The component icons.
     *
     * @type {Object}
     */
    vm.icons = {
        mdiClose,
        mdiMagnify,
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Returns a function, that, as long as it continues to be invoked, will not be triggered.
     *
     * @param  {Function} func      The function to debounce.
     * @param  {number}   wait      The function will be called after it stops being called for N milliseconds.
     * @param  {boolean}  immediate Whether to trigger the function on the leading edge.
     * @return {Function} The debounced function.
     */
    function _debounce(func, wait, immediate) {
        let args;
        let context;
        let result;
        let timeout;
        let timestamp;

        wait = wait || 500;

        const later = () => {
            const last = Date.now() - timestamp;

            if (last < wait && last >= 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;

                if (!immediate) {
                    result = func.apply(context, args);

                    if (!timeout) {
                        // eslint-disable-next-line no-multi-assign
                        context = args = null;
                    }
                }
            }
        };

        const debounced = () => {
            context = this;
            // eslint-disable-next-line prefer-rest-params
            args = arguments;
            timestamp = Date.now();

            const callNow = immediate && !timeout;

            if (!timeout) {
                timeout = setTimeout(later, wait);
            }

            if (callNow) {
                result = func.apply(context, args);
                // eslint-disable-next-line no-multi-assign
                context = args = null;
            }

            return result;
        };

        debounced.clear = () => {
            clearTimeout(timeout);
            // eslint-disable-next-line no-multi-assign
            timeout = context = args = null;
        };

        return debounced;
    }

    /**
     * Navigate through choices on down key down.
     */
    function _keyDown() {
        if (vm.autocompleteList.length > 0) {
            vm.activeChoiceIndex += 1;

            if (vm.activeChoiceIndex >= vm.autocompleteList.length) {
                vm.activeChoiceIndex = 0;
            }
        }
    }

    /**
     * Select a choice on enter key down.
     */
    function _keySelect() {
        if (!vm.autocompleteList || vm.activeChoiceIndex === -1) {
            return;
        }

        vm.selectItem(vm.autocompleteList[vm.activeChoiceIndex]);
    }

    /**
     * Navigate through choices on up key down.
     */
    function _keyUp() {
        if (vm.autocompleteList.length > 0) {
            vm.activeChoiceIndex -= 1;

            if (vm.activeChoiceIndex < 0) {
                vm.activeChoiceIndex = vm.autocompleteList.length - 1;
            }
        }
    }

    /**
     * Open the choices dropdown.
     */
    function _openDropdown() {
        LxDropdownService.open(vm.dropdownId, $element);
    }

    /**
     * Close the choices dropdown.
     */
    function _closeDropdown() {
        LxDropdownService.close(vm.dropdownId);
    }

    /**
     * Callback on automplete success.
     *
     * @param {Array} autocompleteList The list of choices.
     */
    function _onAutocompleteSuccess(autocompleteList) {
        vm.autocompleteList = autocompleteList;
        vm.autocompleteList.length > 0 ? _openDropdown() : _closeDropdown();
        vm.isLoading = false;
    }

    /**
     * Callback on automplete error.
     *
     * @param {string} err The error to display.
     */
    function _onAutocompleteError(err) {
        LxNotificationService.error(err);
        vm.isLoading = false;
    }

    /**
     * Update choices list from query.
     *
     * @param  {string}  newValue  The query.
     * @param  {boolean} immediate Whether to Update choices list immediatly or on debounce.
     * @return {string}  The query.
     */
    function _updateAutocomplete(newValue, immediate) {
        if ((newValue || (angular.isUndefined(newValue) && vm.searchOnFocus)) && !_itemSelected) {
            if (immediate) {
                vm.isLoading = true;
                vm.autocomplete()(newValue, _onAutocompleteSuccess, _onAutocompleteError);
            } else {
                _debouncedAutocomplete(newValue, _onAutocompleteSuccess, _onAutocompleteError);
            }
        } else {
            _debouncedAutocomplete.clear();
            _closeDropdown();
        }

        _itemSelected = false;

        return newValue;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close the component on input blur if needed.
     */
    function blurInput() {
        if (angular.isDefined(vm.closed) && vm.closed && !_input.val()) {
            $element.velocity(
                {
                    width: 40,
                },
                {
                    duration: 400,
                    easing: 'easeOutQuint',
                    queue: false,
                },
            );
        }

        if (!_input.val()) {
            $timeout(() => {
                vm.modelController.$setViewValue(undefined);
            }, 500);
        }
    }

    /**
     * Clear the input value.
     */
    function clearInput() {
        vm.modelController.$setViewValue(undefined);
        vm.modelController.$render();

        // Temporarily disabling search on focus since we never want to trigger it when clearing the input.
        const { searchOnFocus } = vm;
        vm.searchOnFocus = false;

        _input.focus();

        vm.searchOnFocus = searchOnFocus;
    }

    /**
     * Update choices list on input focus.
     */
    function focusInput() {
        if (!vm.searchOnFocus) {
            return;
        }

        _updateAutocomplete(vm.modelController.$viewValue, true);
    }

    /**
     * Get component classes.
     *
     * @return {Array} The list of classes.
     */
    function getClass() {
        const searchFilterClass = [];

        if (angular.isUndefined(vm.closed) || !vm.closed) {
            searchFilterClass.push('search-filter--opened-mode');
        }

        if (angular.isDefined(vm.closed) && vm.closed) {
            searchFilterClass.push('search-filter--closed-mode');
        }

        if (_input.val()) {
            searchFilterClass.push('search-filter--has-clear-button');
        }

        if (angular.isDefined(vm.color)) {
            searchFilterClass.push(`search-filter--${vm.color}`);
        }

        if (angular.isDefined(vm.theme) && vm.theme) {
            searchFilterClass.push(`search-filter--theme-${vm.theme}`);
        } else {
            searchFilterClass.push('search-filter--theme-light');
        }

        if (angular.isFunction(vm.autocomplete)) {
            searchFilterClass.push('search-filter--autocomplete');
        }

        if (LxDropdownService.isOpen(vm.dropdownId)) {
            searchFilterClass.push('search-filter--is-open');
        }

        return searchFilterClass;
    }

    /**
     * Handle key events.
     *
     * @param {Event} evt The key down event.
     */
    function keyEvent(evt) {
        if (!angular.isFunction(vm.autocomplete)) {
            return;
        }

        if (!LxDropdownService.isOpen(vm.dropdownId)) {
            vm.activeChoiceIndex = -1;
        }

        if (evt.keyCode === 13) {
            _keySelect();

            if (vm.activeChoiceIndex > -1) {
                evt.preventDefault();
            }
        } else if (evt.keyCode === 38) {
            _keyUp();
            evt.preventDefault();
        } else if (evt.keyCode === 40) {
            _keyDown();
            evt.preventDefault();
        }

        $scope.$apply();
    }

    /**
     * Open input.
     */
    function openInput() {
        if (angular.isDefined(vm.closed) && vm.closed) {
            $element.velocity(
                {
                    width: angular.isDefined(vm.width) ? parseInt(vm.width, 10) : 240,
                },
                {
                    complete() {
                        _input.focus();
                    },
                    duration: 400,
                    easing: 'easeOutQuint',
                    queue: false,
                },
            );
        } else {
            _input.focus();
        }
    }

    /**
     * Select choice from choices list.
     *
     * @param {string} item The choice to select.
     */
    function selectItem(item) {
        _itemSelected = true;

        _closeDropdown();

        vm.modelController.$setViewValue(item);
        vm.modelController.$render();

        if (angular.isFunction(vm.onSelect)) {
            vm.onSelect(item);
        }
    }

    /**
     * Set component input element.
     *
     * @param {Element} input The component input.
     */
    function setInput(input) {
        _input = input;
    }

    /**
     * Set model controller.
     *
     * @param {Object} modelController The input model controller.
     */
    function setModel(modelController) {
        vm.modelController = modelController;

        if (angular.isFunction(vm.autocomplete) && angular.isFunction(vm.autocomplete())) {
            _debouncedAutocomplete = _debounce(() => {
                vm.isLoading = true;

                const query = vm.modelController.$viewValue;

                // eslint-disable-next-line prefer-rest-params
                vm.autocomplete().apply(this, [query, _onAutocompleteSuccess, _onAutocompleteError]);
            }, 500);

            vm.modelController.$parsers.push(_updateAutocomplete);
        }
    }

    /////////////////////////////

    vm.blurInput = blurInput;
    vm.clearInput = clearInput;
    vm.focusInput = focusInput;
    vm.getClass = getClass;
    vm.keyEvent = keyEvent;
    vm.openInput = openInput;
    vm.selectItem = selectItem;
    vm.setInput = setInput;
    vm.setModel = setModel;
}

function LsSearchFilterDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrl, transclude) {
        let input;

        attrs.$observe('lxWidth', (newWidth) => {
            if (angular.isDefined(scope.vm.closed) && scope.vm.closed) {
                el.find('.search-filter__container').css('width', newWidth);
            }
        });

        transclude(() => {
            input = el.find('input');

            ctrl.setInput(input);
            ctrl.setModel(input.data('$ngModelController'));

            input.on('focus', ctrl.focusInput);
            input.on('blur', ctrl.blurInput);
            input.on('keydown', ctrl.keyEvent);
        });

        scope.$on('$destroy', () => {
            input.off();
        });

        if (angular.isDefined(scope.vm.onInit)) {
            scope.vm.onInit()(scope.vm.dropdownId);
        }
    }

    return {
        bindToController: true,
        controller: LsSearchFilterController,
        controllerAs: 'vm',
        link,
        replace: true,
        restrict: 'E',
        scope: {
            autocomplete: '&?lsAutocomplete',
            closed: '=?lsClosed',
            icon: '@?lsIcon',
            onInit: '&?lsOnInit',
            onSelect: '=?lsOnSelect',
            searchOnFocus: '=?lsSearchOnFocus',
            theme: '@?lsTheme',
            width: '@?lsWidth',
        },
        templateUrl: '/client/common/modules/search-filter/views/search-filter.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsSearchFilter', LsSearchFilterDirective);

/////////////////////////////

export { LsSearchFilterDirective };
