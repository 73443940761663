import { Moment } from 'moment';

import { getMoment } from '../time/getMoment';
import { formatAsCalendar } from './formatAsCalendar';
import { isDateAboveThreshold } from './isDateAboveThreshold';
import { isDateUnderThreshold } from './isDateUnderThreshold';

export interface Options {
    /** Date to format. */
    date: undefined | string | Moment;
    /** Full date format (see moment format). */
    dateStringFormat: string;
    /** Threshold of days under which we format date as the time from now and over which we display the full date. */
    thresholdDisplayFullDate: number;
    /** Threshold of days above which we format date as the time from now and over which we display the full date. */
    aboveThresholdDisplayFullDate?: number;
    /**
     * Object of formats to use as calendar.
     *
     * If undefined, the time from now will be used.
     *
     * (see moment calendar format)
     * @see moment.CalendarSpec
     */
    calendarFormat?: moment.CalendarSpec;
}

/**
 * Format a date either as time from now (ex: "an hour ago") or as a full date (ex: "November 18, 2020")
 *
 * @param options formatting options
 */
export const formatFromNow = (options: Options) => {
    const { date, thresholdDisplayFullDate, aboveThresholdDisplayFullDate, dateStringFormat, calendarFormat } = options;
    if (!date) {
        return '';
    }

    const formattedAsCalendarString = calendarFormat ? formatAsCalendar(date, calendarFormat) : undefined;

    if (formattedAsCalendarString) {
        return formattedAsCalendarString;
    }

    const moment = getMoment();
    const momentUTC = moment.utc(date);

    if (aboveThresholdDisplayFullDate !== undefined && isDateAboveThreshold(date, aboveThresholdDisplayFullDate)) {
        return momentUTC.local().format(dateStringFormat);
    }
    if (isDateUnderThreshold(date, thresholdDisplayFullDate)) {
        return momentUTC.local().fromNow();
    }
    return momentUTC.local().format(dateStringFormat);
};
