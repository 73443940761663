import { isElementVisible } from '../isElementVisible';

const TABBABLE_ELEMENT_SELECTOR = [
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'a[href]',
    'area[href]',
    'summary',
    'iframe',
    'object',
    'embed',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]',
    '[tabindex]:not([tabindex="-1"]):not([disabled])',
].join(':not([hidden]):not([tabindex="-1"]),');

/**
 * Create a [TreeWalker]{@link https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker}
 * that matches all tabbable elements.
 *
 * TODO: move this in the DS to replace the current way we list focusable elements in our focus trap
 */
export function getFocusableTreeWalker(root: Element, targetElement?: Element) {
    const walker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT, {
        acceptNode(node) {
            // Skip nodes inside the starting node.
            if (targetElement?.contains(node)) {
                return NodeFilter.FILTER_REJECT;
            }

            if (
                // Node is a tabbable element (focusable)
                (node as Element).matches(TABBABLE_ELEMENT_SELECTOR) &&
                // Node is visible
                isElementVisible(node as Element)
            ) {
                return NodeFilter.FILTER_ACCEPT;
            }

            return NodeFilter.FILTER_SKIP;
        },
    });

    if (targetElement) {
        // Init walker starting node.
        walker.currentNode = targetElement;
    }
    return walker;
}

/** Get last element in tree walker */
export function last(walker: TreeWalker) {
    let next: HTMLElement | undefined;
    let last: HTMLElement;
    do {
        // Get the last child of the current node
        last = walker.lastChild() as HTMLElement;
        if (last) {
            next = last;
        }
        // Loop until we are at the bottom of the DOM tree (to the very last, nested child)
    } while (last);
    return next;
}
