import { useMediaQuery } from 'react-responsive';

import { mobileQuery, wideQuery, desktopQuery, smallQuery, tabletQuery, DIMENSION } from '../constants';

const useResponsive = () => {
    const isMobile = useMediaQuery({
        query: mobileQuery,
    });

    const isWide = useMediaQuery({
        query: wideQuery,
    });

    const isDesktop = useMediaQuery({
        query: desktopQuery,
    });

    const isSmall = useMediaQuery({
        query: smallQuery,
    });

    const isTablet = useMediaQuery({
        query: tabletQuery,
    });

    let dimension = DIMENSION.DESKTOP;

    if (isSmall) {
        dimension = DIMENSION.SMALL;
    }

    if (isMobile) {
        dimension = DIMENSION.MOBILE;
    }

    if (isTablet) {
        dimension = DIMENSION.TABLET;
    }

    if (isWide) {
        dimension = DIMENSION.WIDE;
    }

    return { isMobile, isWide, isDesktop, isSmall, isTablet, dimension };
};

export { useResponsive };
