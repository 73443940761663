import React from 'react';

import { classnames } from '@lumapps/classnames';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { FILTERS_MODE } from '../../constants';
import { ExposedFacetFilter } from '../ExposedFacetFilter';
import { GroupedFilter } from '../GroupedFilter';
import { UnifiedFilterProps } from './types';

import './index.scss';

const CLASSNAME = 'filter';
/**
 * Component used in order to create an individual filter. It centralises all the different types
 * of filters in a single component. You should normally not use this component directly and just let the
 * `Filters` component manage everything. If you are using this component directly it means that you are
 * customising your filtering logic or that you are creating a new filter.
 * @family Filters
 * @param FilterProps
 * @returns Filter
 */
export const Filter: React.FC<UnifiedFilterProps> = ({ className, ...props }) => {
    const { id, mode } = props;

    const titleId = `${id}-title-${generateUUID()}`;

    if (mode === FILTERS_MODE.EXPOSED_FACETS) {
        return <ExposedFacetFilter {...props} titleId={titleId} className={classnames(CLASSNAME, className)} />;
    }

    return <GroupedFilter {...props} titleId={titleId} className={classnames(CLASSNAME, className)} />;
};
