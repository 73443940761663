import { STATUS } from '../constants';
import {
    SearchResult,
    SearchTab,
    SearchFilter,
    FacetFilter,
    SearchSuggestion,
    BaseSearchResult,
    SEARCH_CAUSES,
    FacetInteraction,
    SearchSort,
    ThirdPartyFiltersTemplate,
    BackendSearchSort,
} from '../types';

export interface LastInteractedFacet {
    facetField: FacetFilter['field'];
    facetId: FacetFilter['id'];
    /** This is the facet label */
    facetTitle: FacetFilter['label'];
    facetValue: string;
    /** Kind of interaction, SELECT or DESELECT */
    facetInteraction: FacetInteraction;
}
export interface SearchState {
    results: SearchResult[];
    totalResultsCount: 0;
    promotedResults: SearchResult[];
    /**
     * Metadata that holds specific information related to search filters and facets. Used to correctly
     * display filters, determine when to show them and their facets.
     */
    metadata: Record<string, SearchTab>;

    /** List of available filters */
    filters: SearchFilter[];
    /** currently selected filter */
    selectedFilter: SearchFilter | null;
    /** Filters currently selected by the user */
    selectedFilters: Record<string, string> | null;
    /**
     * string id of the currently selected filter. Useful when we do not have a state loaded since the user
     * has entered the search page via direct URL
     */
    filter: string;
    /** list of available facets */
    facets: FacetFilter[];
    /** The currently selected facet */
    selectedFacet: SearchFilter | null;
    /** the interacted facet */
    prevFacet: FacetFilter | null;

    /** list of available sorts */
    sorts: SearchSort[] | null;
    /** list of available sorts */
    sortOrders: BackendSearchSort[] | null;
    /** The currently selected sort */
    selectedSort: SearchSort | null;
    /**
     * string id of the currently selected sort. Useful when we do not have a state loaded since the user
     * has entered the search page via direct URL
     */
    sort: string | null;
    /** Query entered by the user */
    query: string;
    /** Current status of the search page */
    status: STATUS;
    /** Whether there are more results to display or not */
    hasMoreResults: boolean;
    /** The cursor to be used if we need to retrieve more results */
    cursor: string | null;
    /** The search engine used to performed the last search */
    engine: string | null;
    /** List of search suggestions */
    suggestions: SearchSuggestion[];
    /** list of result suggested */
    resultSuggestions: BaseSearchResult[];
    /** Current status of the suggestions */
    suggestionsStatus: STATUS;
    /** Suggested query if the user has misspelled their query */
    suggestedQuery?: string | null;
    /** why are we triggering search? */
    cause?: SEARCH_CAUSES;
    /** identifier of the query */
    queryUid: string;
    /** updated when a facet is selected or unselected */
    lastInteractedFacet?: LastInteractedFacet;
    splitTestRunName?: string;
    splitTestRunVersion?: string;

    templates?: ThirdPartyFiltersTemplate[];
    /** ID of the current extension used for fetching results */
    extensionId?: string;
    /**
     * Unique ID to identify each search.
     * This ID can be used to link multiple search queries together (ex: omnisearch and ask AI)
     */
    traceId: string;
}

export const initialState: SearchState = {
    query: '',
    queryUid: '',
    results: [],
    totalResultsCount: 0,
    promotedResults: [],
    filter: '',
    filters: [],
    selectedFilter: null,
    selectedFilters: null,

    facets: [],
    selectedFacet: null,
    prevFacet: null,

    sorts: [],
    sortOrders: [],
    selectedSort: null,
    sort: null,

    status: STATUS.BROWSING,
    metadata: {},

    suggestions: [],
    resultSuggestions: [],
    suggestionsStatus: STATUS.BROWSING,

    hasMoreResults: false,
    cursor: null,
    engine: null,
    suggestedQuery: null,
    cause: SEARCH_CAUSES.init,

    templates: [],
    extensionId: undefined,
    traceId: '',
};
