export const ENUMARABLE_TABS = [
    'all',
    'content',
    'articles',
    'user',
    'users',
    'post',
    'posts',
    'directoryentry',
    'directory_entries',
    'community',
    'communities',
    'media',
    'microsoft',
];

export const MAX_FILTER_VALUES_TO_DISPLAY = 4;
export const TAB_MAX_NAME_LENGTH = 20;

/** List of the different filter types that are available */
export enum FILTER_TYPES {
    /** External filters open a new tab with an external URL */
    EXTERNAL = 'external',
    /** Iframe filters remove the results list and display an iframe */
    IFRAME = 'iframe',
    LUMAPPS = 'lumapps',
    // For GCS
    THIRD_PARTY = 'third-party',
    GOOGLE = 'google',
    DRIVE = 'drive',
    ZENDESK = 'zendesk',
    SHAREPOINT = 'sharepoint',
    EXTENSION = 'extension',
    MICROSOFT = 'microsoft',
}
