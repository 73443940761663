import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Heading, Theme, Typography } from '@lumapps/lumx/react';

import './index.scss';

interface FilterSubheaderProps {
    theme: Theme;
    label: string | React.ReactNode;
}

const CLASSNAME = 'content-filter';

export const FilterSubheader: React.FC<FilterSubheaderProps> = ({ theme, label }) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <Heading
            className={element('subheader')}
            as="h2"
            typography={Typography.subtitle1}
            color={theme === 'light' ? 'dark' : 'light'}
        >
            {label}
        </Heading>
    );
};
