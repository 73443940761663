import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';
import uniq from 'lodash/uniq';

import { AnyAction } from '@lumapps/redux';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

import { UserListSuccessPayload, UserSlice } from '../types';

const initialState: UserSlice = {
    connectedUser: undefined,
    /**
     * `initialized` is here to avoid mapReduxToAngular to be triggered when another action is
     * dispatch while the user has not been set yet by the angular state,
     * the first __angular__/user/update will set this to `true`.
     */
    initialized: false,
    entities: {},
    userList: {
        ids: [],
        hasMore: true,
        isLoading: false,
        cursor: undefined,
    },
};

type ErrorMessage = string;

const { actions, reducer } = createSlice({
    domain: 'user',
    initialState,
    reducers: {
        '__angular__/user/update': (state: any, action: AnyAction) => {
            mergeObjectOnly(state, action.newState);
        },
        setConnectedUser(state: any, action: PayloadAction) {
            // eslint-disable-next-line no-param-reassign
            state.connectedUser = Object.assign(state.connectedUser || {}, action.payload);
        },
        acceptCookies: (state: UserSlice, action: PayloadAction<any>) => {
            const { hasAccepted = false } = action.payload;

            set(state, 'connectedUser.settings.hasAcceptedCookies', hasAccepted);
        },
        acceptTermsAndConditions: (state: UserSlice, action: PayloadAction<any>) => {
            set(state, 'connectedUser.settings.hasAcceptedTerms', action.payload.hasAccepted);
        },
        setToken: (state: UserSlice, action: PayloadAction<any>) => {
            set(state, 'connectedUser.token', action.payload);
            set(state, 'token', action.payload);
        },
        setUserListStatus: (state: UserSlice, action: PayloadAction<boolean>) => {
            set(state, ['userList', 'isLoading'], action.payload);
        },
        fetchUserListSuccess: (state: UserSlice, action: PayloadAction<UserListSuccessPayload>) => {
            const items = action.payload.items || [];
            const listByIds = keyBy(items, 'id');
            set(state, 'entities', { ...state.entities, ...listByIds });

            set(
                state,
                ['userList', 'ids'],
                action.payload.add
                    ? uniq([
                          ...(get(state, ['userList', 'ids'], {}) as UserSlice['userList']['ids']),
                          ...Object.keys(listByIds),
                      ])
                    : Object.keys(listByIds),
            );

            set(state, ['userList', 'isLoading'], false);
            set(state, ['userList', 'cursor'], action.payload.cursor);
            set(state, ['userList', 'hasMore'], action.payload.more);
        },
        fetchUserListError: (state: UserSlice, action: PayloadAction<ErrorMessage>) => {
            set(state, ['userList', 'ids'], initialState.userList.ids);
            set(state, ['userList', 'isLoading'], false);
            set(state, ['userList', 'errorMessage'], action.payload);
        },
    },
});

export { actions, reducer, initialState };
