import { useEffect, useMemo, useReducer } from 'react';

import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';
import pick from 'lodash/fp/pick';
import property from 'lodash/fp/property';

import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { getAuthor, isLoadingAuthor } from '../../ducks/PostListFilters/authorFilterSelectors';
import { initialState, reducer } from '../../ducks/PostListFilters/authorFilterSlice';
import { cancelFetchAuthor, fetchAuthor } from '../../ducks/PostListFilters/authorFilterThunks';
import { Author, PostListFilterBlockProps, PostListFilterHookProps } from '../../types';
import { fillAuthorFilterValuesMissingData } from '../../utils/PostListFilters/fillAuthorFilterValueMissingData';
import { formatName } from '../../utils/PostListFilters/formatName';
import { getFirstValueName } from '../../utils/PostListFilters/getFirstValueName';

export function usePostAuthorFilter({
    activeFiltersValues,
    filtersValues,
    handleValuesChange,
}: PostListFilterHookProps): Omit<PostListFilterBlockProps<Author>, 'theme'> {
    const filterId = 'authorEmail';

    const [authorState, dispatch] = useReducer(reducer, initialState);
    const isLoadingMissingAuthorData = isLoadingAuthor(authorState);
    const missingAuthorData = getAuthor(authorState);

    const { translateKey } = useTranslate();
    const currentLanguage = useSelector(currentLanguageSelector);

    const activeValues = useMemo(
        () => fillAuthorFilterValuesMissingData(property(filterId, activeFiltersValues), missingAuthorData),
        [activeFiltersValues, missingAuthorData],
    );

    const selectedValues = useMemo(
        () => fillAuthorFilterValuesMissingData(property(filterId, filtersValues), missingAuthorData),
        [filtersValues, missingAuthorData],
    );

    useEffect(() => {
        const { email, firstName, lastName } = flow(first, pick(['email', 'firstName', 'lastName']))(activeValues);

        if (email && !(firstName && lastName) && !isLoadingMissingAuthorData) {
            fetchAuthor(email)(dispatch);

            return () => cancelFetchAuthor(email)(dispatch);
        }

        return noop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getChoiceName, label, name } = useMemo(() => {
        const getChoiceName = (user: Author) => getUserFullName(user, currentLanguage);
        const label = translateKey(GLOBAL.AUTHOR);
        const firstActiveValueName = getFirstValueName(getChoiceName, activeValues);

        return {
            getChoiceName,
            label,
            name: formatName(label, firstActiveValueName, activeValues.length),
        };
    }, [activeValues, currentLanguage, translateKey]);

    return {
        filterId,
        getChoiceName,
        isActive: !isEmpty(activeValues),
        isLoading: isLoadingMissingAuthorData,
        label,
        name,
        onSelect: handleValuesChange,
        selectedValues,
    };
}
