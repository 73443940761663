import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { mdiInformationOutline } from '@lumapps/lumx/icons';
import { Icon, Text, Theme } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import * as type from '../../../types';

import './index.scss';

const CLASSNAME = 'block-no-results';

// BlockNoResults props when isFiltered is undefined or false
interface UnfilteredNoResults<TFiltered = boolean | undefined> extends type.BlockComponentProps<type.BlockNoResults> {
    isFiltered?: TFiltered;
    onClearFilters?(): void;
    searchValue?: undefined;
}

// BlockNoResults props when isFiltered is true
interface FilteredNoResults<TFiltered = boolean> extends type.BlockComponentProps<type.BlockNoResults> {
    isFiltered: TFiltered;
    onClearFilters(): void;
    searchValue?: string;
}

type BlockNoResultsProps<TFiltered = boolean> = TFiltered extends true
    ? FilteredNoResults<TFiltered>
    : UnfilteredNoResults<TFiltered>;

export const BlockNoResults: React.FC<BlockNoResultsProps> = (props) => {
    const { isFiltered, onClearFilters, placeholderText, theme = Theme.light, style, searchValue } = props;
    const { translateKey } = useTranslate();
    const { block } = useClassnames(CLASSNAME);

    return (
        <div className={block(`theme-${theme}`)} style={style}>
            {isFiltered === true ? (
                <NoResultsState
                    onClear={onClearFilters}
                    searchValue={searchValue}
                    emptyWithFiltersText={
                        searchValue ? undefined : placeholderText || translateKey(GLOBAL.NO_FILTERED_RESULTS)
                    }
                />
            ) : (
                <>
                    <Icon className={block('icon')} icon={mdiInformationOutline} />
                    <Text as="p" className={block('label')}>
                        {placeholderText || translateKey(GLOBAL.NO_RESULTS)}
                    </Text>
                </>
            )}
        </div>
    );
};
BlockNoResults.displayName = 'BlockNoResults';
