import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function InfiniteScrollDirective($compile, $document, $timeout, $window, Utils) {
        'ngInject';

        function InfiniteScrollLink(scope, el, attrs) {
            /////////////////////////////
            //                         //
            //    Private attributes   //
            //                         //
            /////////////////////////////

            /**
             * Contains a jQElement for the whole document.
             *
             * @type {jQElement}
             */
            var _DOCUMENT = angular.element($document);

            /**
             * An unique identifier for this infinite-scroll.
             *
             * @type {string}
             */
            var _ID = generateUUID();

            /**
             * Contains the HTML string for the 'Load next page' button.
             *
             * @type {string}
             */
            var _MORE_BUTTON = '';
            _MORE_BUTTON += '<div class="infinite-scroll-button" id="load-more-' + _ID + '" ng-show="more">';
            _MORE_BUTTON += '  <a ng-click="nextPage(true)">';
            _MORE_BUTTON += '    <span>{{ \'LOAD_NEXT_PAGE\' | trans }}</span>';
            _MORE_BUTTON += '    <lx-icon lx-id="arrow-down-drop-circle-outline"></lx-icon>';
            _MORE_BUTTON += '  </a>';
            _MORE_BUTTON += '</div>';

            /**
             * Contains the target of the infinite scroll.
             * This is this target we are watching the scroll.
             *
             * @type {jQElement}
             */
            // eslint-disable-next-line no-nested-ternary
            var _TARGET;
            if (attrs.lsInfiniteScrollTarget === 'true') {
                _TARGET = el;
            } else if (attrs.lsInfiniteScrollTarget === 'parent' || attrs.lsInfiniteScrollTarget === '<') {
                _TARGET = el.parent();
            } else if (_.startsWith(attrs.lsInfiniteScrollTarget, '<')) {
                _TARGET = el.parents(_.trim(attrs.lsInfiniteScrollTarget.replace('<', '')));
            } else if (angular.isDefinedAndFilled(attrs.lsInfiniteScrollTarget)) {
                _TARGET = angular.element(attrs.lsInfiniteScrollTarget);
            }

            /**
             * Contains a jQElement for the whole window.
             *
             * @type {jQElement}
             */
            var _WINDOW = angular.element($window);

            /**
             * Indicates if the scroll is currently active.
             *
             * @type {boolean}
             */
            var _scrollIsActive = true;

            /////////////////////////////
            //                         //
            //    Public attributes    //
            //                         //
            /////////////////////////////

            /**
             * Indicates if we want to display the button.
             *
             * @type {boolean}
             */
            scope.displayButton = false;

            /**
             * Indicates if there are more results available and thus if the infinite scroll should trigger.
             * It also indicates if we want to display the "Load next page" button.
             *
             * @type {boolean}
             */
            scope.more = false;

            /**
             * The offset from the bottom of the target (in pixel) where we want to trigger the infinite scroll
             * function.
             * The offset parameter takes the precedance over the percentage parameter.
             *
             * @type {number}
             */
            scope.offset = undefined;

            /**
             * The percentage of scroll of the target when we want to trigger the infinite scroll function.
             * The offset parameter takes the precedance over the percentage parameter.
             *
             * @type {number}
             */
            scope.percentage = 90;

            /////////////////////////////
            //                         //
            //    Private functions    //
            //                         //
            /////////////////////////////

            /**
             * Execute the infinite scroll.
             *
             * @param {boolean} fromButton Indicates if the call for the next page comes from the "Load next page"
             *                             button.
             */
            function _executeInfiniteScroll(fromButton) {
                fromButton = fromButton || false;

                _scrollIsActive = false;

                var functionToCall = attrs.lsInfiniteScroll.replace('lsInfiniteScrollFromButton', String(fromButton));
                if (angular.isDefined(scope.$root) &&
                    // eslint-disable-next-line angular/no-private-call
                    (scope.$root.$$phase === '$apply' || scope.$root.$$phase === '$digest')) {
                    scope.$eval(functionToCall);
                } else {
                    scope.$apply(functionToCall);
                }

                $timeout(function delayActivatingScroll() {
                    _scrollIsActive = true;
                }, 500);
            }

            /**
             * When a scroll is catched, execute the infinite scroll function.
             */
            function _scroll() {
                if (!_scrollIsActive) {
                    return;
                }

                var percentage = (angular.isDefined(scope.percentage)) ? scope.percentage : 90;
                percentage = (angular.isNumber(percentage)) ? percentage : parseInt(percentage, 10);

                var offset = (angular.isDefined(scope.offset)) ? scope.offset : undefined;
                offset = (angular.isNumber(offset) || angular.isUndefined(offset)) ? offset : parseInt(offset, 10);

                var usePercentage = !angular.isNumber(scope.offset);

                var execute = false;
                if (angular.isDefinedAndFilled(attrs.lsInfiniteScrollTarget)) {
                    var target = _TARGET['0'];

                    if (usePercentage) {
                        execute =
                            (target.offsetHeight + target.scrollTop) >= ((target.scrollHeight * percentage) / 100);
                    } else {
                        execute = (target.scrollHeight - target.scrollTop - target.offsetHeight) <= offset;
                    }
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (usePercentage) {
                        execute = ((_WINDOW.scrollTop() / (_DOCUMENT.height() - _WINDOW.height())) * 100) >= percentage;
                    } else {
                        execute = (_DOCUMENT.height() - _WINDOW.height() - _WINDOW.scrollTop()) <= offset;
                    }
                }

                if (execute) {
                    _executeInfiniteScroll();
                }
            }
            var _debouncedScroll = _.debounce(_scroll, 100);

            /**
             * Allow to infinite scroll even if we already are at the bottom of the scroll.
             * This case can happen if we have less elements that needed for fill the page or if we scroll to the
             * bottom of the page when a page was loading (and thus the infinite scroll temporary disabled).
             */
            function _mouseWheel() {
                if (!_scrollIsActive) {
                    return;
                }

                var loadNextPage = false;
                if (angular.isDefinedAndFilled(attrs.lsInfiniteScrollTarget)) {
                    var target = _TARGET['0'];
                    loadNextPage = (target.offsetHeight + target.scrollTop) >= ((target.scrollHeight * 100) / 100);
                } else {
                    // eslint-disable-next-line no-lonely-if
                    loadNextPage = (_DOCUMENT.height() === _WINDOW.height()) ||
                        ((_WINDOW.scrollTop() / (_DOCUMENT.height() - _WINDOW.height())) * 100) === 100;
                }

                if (loadNextPage) {
                    _executeInfiniteScroll();
                }
            }
            var _debouncedMouseWheel = _.debounce(_mouseWheel, 150);

            /**
             * Bind to scroll event of the right element (either window or the target).
             */
            function _bind() {
                if (angular.isDefinedAndFilled(attrs.lsInfiniteScrollTarget)) {
                    $timeout(function waitForDigestCycle() {
                        _TARGET.bind('scroll', _debouncedScroll);
                        _TARGET.bind('mousewheel', _debouncedMouseWheel);
                    });
                } else {
                    _WINDOW.bind('scroll', _debouncedScroll);
                    _WINDOW.bind('mousewheel', _debouncedMouseWheel);
                }

                $timeout(function delayAppendButton() {
                    if (!scope.displayButton) {
                        return;
                    }

                    angular.element('#load-more-' + _ID).remove();

                    var buttonTarget = el;
                    if (angular.isString(scope.displayButton)) {
                        buttonTarget = el.find(scope.displayButton);
                    }

                    buttonTarget.append($compile(_MORE_BUTTON)(scope));
                });
            }

            /**
             * Unbind all events and hide the "Load next page" button.
             */
            function _unbind() {
                if (angular.isDefinedAndFilled(attrs.lsInfiniteScrollTarget)) {
                    _TARGET.unbind('scroll', _debouncedScroll);
                    _TARGET.unbind('mousewheel', _debouncedMouseWheel);
                } else {
                    _WINDOW.unbind('scroll', _debouncedScroll);
                    _WINDOW.unbind('mousewheel', _debouncedMouseWheel);
                }

                if (scope.displayButton) {
                    $timeout(function delayRemoveButton() {
                        angular.element('#load-more-' + _ID).remove();
                    });
                }
            }

            /////////////////////////////
            //                         //
            //     Public functions    //
            //                         //
            /////////////////////////////

            /**
             * Load the next page of results.
             *
             * @param {boolean} fromButton Indicates if the call for the next page comes from the "Load next page"
             *                             button.
             */
            function nextPage(fromButton) {
                if (scope.more) {
                    _executeInfiniteScroll(fromButton);
                }
            }

            /////////////////////////////

            scope.nextPage = nextPage;

            /////////////////////////////
            //                         //
            //          Events         //
            //                         //
            /////////////////////////////

            /**
             * When the directive is destroy, unregister the scroll event listeners.
             */
            scope.$on('$destroy', _unbind);

            /////////////////////////////
            //                         //
            //        Watchers         //
            //                         //
            /////////////////////////////

            /**
             * Watch for an indicator that tells if we want to display the button or not.
             */
            attrs.$observe('lsInfiniteScrollDisplayButton', function displayButtonObserve() {
                var oldDisplayButton = scope.displayButton;

                if (angular.isUndefined(attrs.lsInfiniteScrollDisplayButton)) {
                    scope.displayButton = false;
                } else {
                    var displayButton = scope.$eval(attrs.lsInfiniteScrollDisplayButton);
                    scope.displayButton = displayButton;
                }

                if (scope.displayButton !== oldDisplayButton) {
                    _unbind();
                    _bind();
                }
            });

            /**
             * Watch for an indicator that tells if there are more element to load or not.
             */
            attrs.$observe('lsInfiniteScrollMore', function moreObserve() {
                var wasMore = scope.more;

                if (angular.isUndefined(attrs.lsInfiniteScrollMore)) {
                    scope.more = true;
                } else {
                    var hasMore = scope.$eval(attrs.lsInfiniteScrollMore);
                    scope.more = angular.isDefined(hasMore) &&
                        (hasMore === true || (hasMore !== false && hasMore !== 0));
                }

                if (scope.more && !wasMore) {
                    _bind();
                } else if (!scope.more) {
                    _unbind();
                }
            });

            /**
             * Watch for any changes on the offset attribute.
             */
            attrs.$observe('lsInfiniteScrollOffset', function offsetObserve() {
                scope.offset = parseInt(attrs.lsInfiniteScrollOffset, 10);
            });

            /**
             * Watch for any changes on the percentage attribute.
             */
            attrs.$observe('lsInfiniteScrollPercentage', function percentageObserve() {
                scope.percentage = parseInt(attrs.lsInfiniteScrollPercentage, 10);
            });

            /////////////////////////////

            /**
             * Initialize the controller.
             */
            function init() {
                _bind();
            }

            init();
        }

        /////////////////////////////

        return {
            link: InfiniteScrollLink,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsInfiniteScroll', InfiniteScrollDirective);
})();
