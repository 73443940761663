import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { FlexBox } from '@lumapps/lumx/react';

import './index.scss';

export interface ColorCircleProps {
    /* Color of the circle */
    color: string | null;
    /* Whether the color circle should display as selected or not. */
    isSelected: boolean;
    /* Label of the circle */
    label?: string;
    /* Custom classname */
    className?: string;
}

const CLASSNAME = 'color-circle';

/**
 *
 * @param ColorCircleProps
 * @returns ColorCircle
 */
export const ColorCircle: React.FC<ColorCircleProps> = ({ color, isSelected, label, className }) => {
    const { get } = useDataAttributes('color-circle');
    const { block } = useClassnames(CLASSNAME);

    return (
        <FlexBox
            style={{
                backgroundColor: color,
            }}
            className={block({ 'is-selected': isSelected }, [className])}
            aria-label={label}
            {...get({ element: 'option', action: 'color-circle' })}
        />
    );
};
