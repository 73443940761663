import { ROLES } from '@lumapps/roles/keys';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function RoleService($q, CustomContentType, InitialSettings, Instance, LumsitesBaseService, Metadata, RoleFactory,
        Translation) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(RoleFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            postGet: _postGet,
            // eslint-disable-next-line no-use-before-define
            postList: _postList,
            // eslint-disable-next-line no-use-before-define
            postSave: _postSave,
            // eslint-disable-next-line no-use-before-define
            preSave: _preSave,
        });

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The promise of the roles initialization.
         *
         * @type {Promise}
         */
        var _roleInitDeferred = $q.defer();

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * List of available actions to display.
         *
         * @type {Object}
         */
        var _actionList = {};

        /**
         * Contains various status about the service.
         *
         * @type {Object}
         */
        service.is = {
            initialized: false,
            initializing: false,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The default parameters of the role service.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /**
         * Contains various indicators about the state of the service.
         *
         * @type {Object}
         */
        service.is = {
            loading: {
                roles: false,
            },
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////


        /**
         * Get a custom translation key for the given action.
         *
         * Some actions may need a custom translation depending on its action type and name.
         *
         */
        function _getActionCustomTranslationKey(actionType, action) {
            switch (action.name) {
                case 'MENU':
                    // MENU_DROP action is now used for partial edition.
                    return actionType === 'DROP' ? ROLES.MENU_PARTIAL_EDIT_ACTION : '';

                default:
                    return undefined;
            }

        }

        /**
         * Format the server object to send the correct item to the client.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formatted object.
         */
        function _formatServerObjectToClient(obj) {
            angular.forEach(obj.authorizations, function forEachAuthorization(authorization) {
                var filteredMetadata = {};

                angular.forEach(Metadata.getRefactoredMetadata(true), function forEachMetadata(metadata) {
                    filteredMetadata[metadata.key] = [];

                    angular.forEach(metadata.items, function forEachMetadataItem(metadataItem) {
                        if (_.includes((authorization.metadata || []), metadataItem.key)) {
                            filteredMetadata[metadata.key].push(metadataItem.key);
                        }
                    });
                });

                authorization.metadata = filteredMetadata;

                /** Add custom translation if available */
                const actionsWithCustomTranslations = authorization.actions.map((action) => ({
                    ...action,
                    customTranslationKey: _getActionCustomTranslationKey(action.type, action)
                }));
                authorization.actions = actionsWithCustomTranslations;
            });

            return obj;
        }

        /**
         * Format the client object to send the correct item to the server.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formatted object.
         */
        function _formatClientObjectForServer(obj) {
            angular.forEach(obj.authorizations, function forEachAuthorization(authorization) {
                var actions = [];
                angular.forEach(authorization.actions, function forEachAuthorizationAction(action) {
                    if (angular.isDefinedAndFilled(action.value)) {
                        actions.push(action.value);
                    } else {
                        actions.push(action);
                    }
                });
                authorization.actions = actions;

                var feeds = [];
                angular.forEach(authorization.feeds, function forEachAuthorizationFeed(feed) {
                    if (angular.isDefinedAndFilled(feed.id)) {
                        feeds.push(feed.id);
                    } else {
                        feeds.push(feed);
                    }
                });
                authorization.feeds = feeds;

                var metadataList = [];
                angular.forEach(authorization.metadata, function forEachAuthorizationMetadata(metadata) {
                    if (angular.isDefinedAndFilled(metadata)) {
                        if (angular.isString(metadata)) {
                            metadataList.push(metadata);
                        } else {
                            metadataList = metadataList.concat(metadata);
                        }
                    }
                });
                authorization.metadata = metadataList;
            });

            return obj;
        }

        /**
         * Post Get function to override object.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formatted object.
         */
        function _postGet(obj) {
            return _formatServerObjectToClient(obj);
        }

        /**
         * Post List function to override object.
         *
         * @param  {Object} roleList The object list to format.
         * @return {Object} The formatted object.
         */
        function _postList(roleList) {
            var convertedRoles = [];

            angular.forEach(roleList, function forEachRole(role) {
                convertedRoles.push(_postGet(role));
            });

            return convertedRoles;
        }

        /**
         * Post Save function to override object.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formatted object.
         */
        function _postSave(obj) {
            return _formatServerObjectToClient(obj);
        }

        /**
         * Pre Save function to override object.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formatted object.
         */
        function _preSave(obj) {
            return _formatClientObjectForServer(angular.fastCopy(obj));
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the action list to create roles based on these.
         *
         * @return {Object} The list of actions per type (either CCT or authorization).
         */
        function getActionList() {
            return _actionList;
        }

        /////////////////////////////

        service.getActionList = getActionList;

        /////////////////////////////

        /**
         * Initialize the service.
         *
         * @return {Promise} The promise of the init that resolves with the list of available actions.
         */
        service.init = function init() {
            var currentInstanceId = Instance.getCurrentInstanceId();

            service.defaultParams = {
                instance: currentInstanceId,
            };

            var actions = [];
            var currentAndHeritableCCT;

            if (service.is.initializing) {
                return _roleInitDeferred.promise;
            }

            service.is.initializing = true;

            // Add authorization actions.
            var tempActionsList = {};
            angular.forEach(InitialSettings.AUTHORIZATION_ACTION, function forEachAuthorizationAction(action) {
                actions = [];

                angular.forEach(action.values, function forEachActionType(actionType) {
                    actions.push({
                        name: action.name,
                        type: actionType,
                        customTranslationKey: _getActionCustomTranslationKey(actionType, action),
                    });
                });

                tempActionsList[Translation.translate('ACTION_TYPE_' + action.name)] = actions;
            });

            _actionList = angular.fastCopy(tempActionsList);

            $q.all([CustomContentType.promiseList(), Instance.getSiblings(false)]).then(
                function onInstanceSiblingsCustomContentTypesGetSuccess(responses) {
                    var customContentTypeList = _.first(responses);

                    // Filter CCT for the current instance and heritable parent instance CCT if it's a child instance.
                    currentAndHeritableCCT = _.filter(customContentTypeList, {
                        instance: currentInstanceId,
                    });
                    // Whether its a child instance.
                    var parentInstanceId = _.get(Instance.getInstance(), 'parent');

                    if (angular.isDefinedAndFilled(parentInstanceId)) {
                        var heritableParentInstanceCCT = _.filter(customContentTypeList, {
                            heritable: true,
                            instance: parentInstanceId,
                        });

                        currentAndHeritableCCT = _.union(currentAndHeritableCCT, heritableParentInstanceCCT);
                    }

                    _actionList = {};
                    // Add custom content authorization actions by custom content types.
                    angular.forEach(currentAndHeritableCCT, function forEachCustomContentType(customContentType) {
                        actions = [];
                        var customContentTypesActionTypes = ['READ', 'EDIT', 'PUBLISH', 'DELETE', 'ARCHIVE'];
                        var cctId = customContentType.id;
                        var cctName = sanitizeHTML(Translation.translate(customContentType.name));

                        angular.forEach(customContentTypesActionTypes, function forEachActionType(actionType) {
                            // We will not publish if there is no workflow.
                            if (customContentType.isWorkflowEnabled || actionType !== 'PUBLISH') {
                                actions.push({
                                    customContentType: cctId,
                                    name: 'CUSTOM_CONTENT',
                                    type: actionType,
                                });
                            }
                        });

                        var instanceName = _.get(
                            Instance.instanceKeyToInstanceFromSiblings(customContentType.instance, true),
                            'name', ''
                        );

                        var cctNameInstance = cctName;
                        if ((customContentType.instance !== currentInstanceId)) {
                            cctNameInstance += ' (';
                            cctNameInstance += (angular.isDefinedAndFilled(instanceName)) ? instanceName :
                                Translation.translate('ADMIN_INSTANCE_PARENT');
                            cctNameInstance += ')';
                        }

                        if (angular.isDefined(_actionList[cctNameInstance])) {
                            return;
                        }

                        _actionList[cctNameInstance] = actions;
                    });

                    _actionList = angular.extend(_actionList, tempActionsList);

                    _roleInitDeferred.resolve(_actionList);
                }
            ).catch(_roleInitDeferred.reject);

            _roleInitDeferred.promise.finally(function onRoleInitializedFinally() {
                service.is.initialized = true;
                service.is.initializing = false;
            });

            return _roleInitDeferred.promise;
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Role', RoleService);
})();
