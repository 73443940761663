import findLastIndex from 'lodash/findLastIndex';

import { Dispatch } from '@lumapps/redux/types';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { Socket } from '@lumapps/websockets';
import { createSocket, Transport } from '@lumapps/websockets/api';

import { AssistantApiReponseStatus, assistantSocketApi, getFirstResourceData, getMessages } from '../api';
import { MessageHandles } from '../constants';
import {
    ConversationData,
    HistoryMessage,
    IncomingEvents,
    MessageReceivedFromBot,
    MessageToSendToBot,
    OutgoingEvents,
    UserAttachment,
    UserMessagePayload,
} from '../types';
import { formatHistoryMessage } from '../utils';
import { actions, ConversationState, ConversationStatusTypes } from './slice';

const sendMessageWithSocket = async (
    appID: string,
    socket: Socket<IncomingEvents, OutgoingEvents>,
    message: MessageToSendToBot,
) => {
    const messagePayload = {
        ...message,
        recipient: { id: appID },
    };

    socket.timeout(3000).emit('message', messagePayload);
};

const initSocketConnexionAndSendFirstMessage = (
    newMessage: MessageToSendToBot | undefined,
    state: ConversationState,
    dispatch: Dispatch,
    transports: Transport[] = ['polling', 'websocket'],
) => {
    let messageToSend = newMessage;

    const socket: Socket<IncomingEvents, OutgoingEvents> = createSocket({
        apiInstance: assistantSocketApi,
        socketPath: 'messages',
        transports,
        fatalConnectionErrorCallback: (err) => {
            // eslint-disable-next-line no-console
            console.error('[socket] fatalConnectionErrorCallback, err=', err);
            dispatch(actions.setTyping(false));
        },
    });

    socket.on('connect', async () => {
        dispatch(actions.setSocket(socket));

        if (state.appID && messageToSend) {
            await sendMessageWithSocket(state.appID, socket, messageToSend);
            messageToSend = undefined;
        }
    });

    socket.on('connect_error', async (err: any) => {
        if (err.type === 'TransportError' && err.description === 400) {
            socket.close();
            initSocketConnexionAndSendFirstMessage(messageToSend, state, dispatch, ['websocket']);
        }
    });

    socket.on('message', (val: MessageReceivedFromBot, callback: any) => {
        dispatch(actions.setTyping(false));
        const message = val;
        dispatch(actions.addMessage({ message }));
        if (message.conversationId) {
            dispatch(actions.setConversationId(message.conversationId));
        }
        if (typeof callback === 'function') {
            callback({ status: 'ok' });
        }
    });
};

/**
 * This is called every time the user sends a message (from the text input or from the UI)
 * Build the message (we will modify this in the future to add more message types)
 * Check if the respondent exists (if not, initialize conversation) and send the message
 */
const sendMessage = async (
    userMessage: UserMessagePayload,
    state: ConversationState,
    dispatch: Dispatch,
    actions: any,
) => {
    if (!userMessage) {
        return;
    }
    let newMessage = {} as MessageToSendToBot;
    newMessage = {
        resetUser: false,
        createdAt: new Date(Date.now()).toISOString(),
        lumappsTraceId: generateUUID(),
        conversationId: state.conversationId,
        ...userMessage,
    };
    if (
        userMessage.message.text !== MessageHandles.Reboot ||
        userMessage.message.quick_reply !== MessageHandles.FromUI
    ) {
        // fill up adaptive card message content with user infos
        if (userMessage.adaptiveCardResponse) {
            const lastAdaptiveCardMessageIndex = findLastIndex(state.messages, (message) => {
                return message?.attachment?.type === 'adaptivecards';
            });
            const lastAdaptiveCardMessage = state.messages[lastAdaptiveCardMessageIndex];
            dispatch(actions.removeMessage(lastAdaptiveCardMessage));
            dispatch(
                actions.addMessage({
                    message: {
                        ...lastAdaptiveCardMessage,
                        attachment: {
                            ...lastAdaptiveCardMessage.attachment,
                            payload: userMessage.adaptiveCardResponse,
                        },
                    },
                    index: lastAdaptiveCardMessageIndex,
                }),
            );
        }
        const userPayload = newMessage; // message to display for the user
        const { attachments, text } = userPayload.message;
        dispatch(actions.addMessage({ message: { ...userPayload, message: { text } } })); // Add the message to the state
        attachments?.forEach((a) => {
            const attachmentMessage: MessageToSendToBot = {
                resetUser: false,
                createdAt: new Date(Date.now()).toISOString(),
                lumappsTraceId: generateUUID(),
                message: {
                    attachments: [a],
                },
                siteId: userPayload.siteId,
            };
            dispatch(actions.addMessage({ message: attachmentMessage }));
        });
    } else {
        dispatch(actions.resetConversation()); // if reboot from ui reset the conversation
    }

    if (!state.socket) {
        initSocketConnexionAndSendFirstMessage(newMessage, state, dispatch);
    } else if (state.appID) {
        sendMessageWithSocket(state.appID, state.socket, newMessage);
    }
    if (!state.isTyping) {
        dispatch(actions.setTyping(true));
    }

    /** remove all attachments from the list when the message is sent */
    dispatch(actions.resetUserAttachments());
};

const receiveMessage = async (dispatch: Dispatch, message: MessageReceivedFromBot) => {
    dispatch(actions.addMessage({ message }));
};

/**
 * This function initializes the conversation before the user sends any message.
 * It adds the message history (previous user conversation) or the introduction resource in the conversation.
 * It updates the state with the application data which are responsible for all of the assistant configuration options.
 */
const startConversation = async (
    conversationData: { appId?: string; siteId?: string },
    options: { isHistoryHidden?: boolean; restartConversation?: boolean },
    dispatch: Dispatch,
) => {
    const initializeBody: ConversationData = {
        ...conversationData,
        options: {
            restart: options?.restartConversation,
        },
    };

    let firstMessageData: any;
    try {
        firstMessageData = await getFirstResourceData(initializeBody);
    } catch (err) {
        dispatch(actions.setStatus(ConversationStatusTypes.error));
        return null;
    }

    if (firstMessageData.status === AssistantApiReponseStatus.empty) {
        dispatch(actions.setStatus(ConversationStatusTypes.empty));
        return null;
    }

    const { application, messagesHistory, messages, envId, chatbotId, respondentId } = firstMessageData.data;

    if (!options.isHistoryHidden && messagesHistory?.items?.length > 0) {
        // If the user has already talked to the assistant, we want to retrieve the message history
        // and display the past conversation instead of the introduction messages.
        const messages = messagesHistory.items.map((item: HistoryMessage) => {
            // we need to adapt the messages following each model (user or chatbot) to display them correctly
            return formatHistoryMessage(item);
        });
        dispatch(actions.addMessagesToHistory(messages));
        dispatch(actions.setHasMoreMessages(messagesHistory.more));
        dispatch(actions.setHistoryCursor(messagesHistory.cursor));
        // TODO: Set conversationId once initialize-respondent has been updated to send it
    }
    if (!(messagesHistory?.items?.length > 0) || options.restartConversation || options.isHistoryHidden) {
        messages?.forEach((message: MessageReceivedFromBot) => {
            receiveMessage(dispatch, message);
        });
    }
    dispatch(actions.setApplication(application)); // Update the state with the application data.
    dispatch(actions.setAppID(envId));
    dispatch(actions.setChatbotID(chatbotId));
    dispatch(actions.setRespondentID(respondentId));
    dispatch(actions.setStatus(ConversationStatusTypes.live));
    return null;
};

const onSelectFiles = (files: Partial<UserAttachment>[], dispatch: Dispatch) => {
    files.forEach((f) => {
        if (f.blobUrl) {
            dispatch(actions.addUserAttachment(f));
        }
    });
};

const onMediaUploaded = (medias: UserAttachment[], dispatch: Dispatch) => {
    medias.forEach((file) => {
        dispatch(actions.replaceAttachmentWithUploadedFile({ file }));
    });
};

const loadMoreMessages = async (state: ConversationState, dispatch: Dispatch) => {
    const {
        respondentID: respondentId,
        appID: environmentId,
        chatbotID: chatbotId,
        messageHistoryCursor: cursor,
    } = state;
    if (respondentId && environmentId && chatbotId && cursor) {
        try {
            const {
                cursor: newCursor,
                items: messages,
                more,
            } = await getMessages({ respondentId, environmentId, chatbotId, cursor });
            const formattedMessages: HistoryMessage[] =
                messages?.map((m) => {
                    return formatHistoryMessage(m);
                }) || [];
            dispatch(actions.addMessagesToHistory(formattedMessages));
            dispatch(actions.setHistoryCursor(newCursor));
            dispatch(actions.setHasMoreMessages(more));
        } catch (err) {
            return null;
        }
    }
    return null;
};

export { loadMoreMessages, onMediaUploaded, onSelectFiles, sendMessage, startConversation };
