import { type Connector } from 'lumapps-sdk-js';

import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import type { InstalledExtensionAPIProps } from './legacy-types';

const platformExtensionsRoutes: Route = {
    path: '/admin/platform-extensions',
    legacyId: 'app.admin.platform-extensions',
    appId: AppId.backOffice,
};

const platformExtensionDetailRoute: Route = {
    appId: AppId.backOffice,
    path: `${platformExtensionsRoutes.path}/:extensionId`,
};

const platformExtensionNewConnectorRoute: Route = {
    appId: AppId.backOffice,
    path: `${platformExtensionsRoutes.path}/:extensionId/connectors/new`,
};

const platformExtensionEditConnectorRoute: Route = {
    appId: AppId.backOffice,
    path: `${platformExtensionsRoutes.path}/:extensionId/connectors/:connectorId`,
};

const marketplaceRoutes: Route = {
    path: '/admin/marketplace',
    legacyId: 'app.admin.marketplace',
    appId: AppId.backOffice,
};

const platformExtensions = (): Route => {
    return platformExtensionsRoutes;
};

const platformExtensionDetail = ({ extensionId, anchor }: { extensionId: string; anchor?: string }): Route => {
    return {
        ...platformExtensionDetailRoute,
        anchor,
        params: {
            extensionId,
        },
    };
};

const platformExtensionNewConnector = ({ extensionId }: { extensionId: string }): Route => {
    return {
        ...platformExtensionNewConnectorRoute,
        params: {
            extensionId,
        },
    };
};

export interface ExtensionConnectorEditParams {
    /** Connector id being edited */
    connectorId: Connector['id'];
    /** Extension id that own the connector */
    extensionId: InstalledExtensionAPIProps['id'];
}

const platformExtensionEditConnector = ({
    extensionId,
    connectorId,
    isPreset = false,
}: {
    extensionId: string;
    connectorId: string;
    isPreset?: boolean;
}): Route => {
    return {
        ...platformExtensionEditConnectorRoute,
        params: {
            extensionId,
            connectorId,
        },
        query: { ...(Boolean(isPreset) && { isPreset: 'true' }) },
    };
};

const marketplace = (): Route => {
    return marketplaceRoutes;
};

export {
    marketplace,
    marketplaceRoutes,
    platformExtensions,
    platformExtensionsRoutes,
    platformExtensionDetail,
    platformExtensionDetailRoute,
    platformExtensionNewConnector,
    platformExtensionNewConnectorRoute,
    platformExtensionEditConnector,
    platformExtensionEditConnectorRoute,
};
