import { useCallback, useEffect, useMemo, useRef } from 'react';

import debounce from 'lodash/debounce';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { getCurrentContentId, getCurrentContentType, isInDesignerMode } from '@lumapps/contents/ducks/selectors';
import { ContentType } from '@lumapps/contents/types';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { inputLanguageSelector } from '@lumapps/languages';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { getWidget } from '../../ducks/selectors';
import { actions } from '../../ducks/slice';
import { fetchWidgetBlocksByProperties } from '../../ducks/thunks/fetchWidgetBlocksByProperties';
import { WidgetState } from '../../ducks/type';
import { isSingleContainerBlock, LegacyWidget } from '../../types';

export const useLegacyWidget = ({
    legacyWidget,
    isWidgetEmpty,
    editingContent,
    canUseLangFallback,
    onError,
}: {
    legacyWidget: LegacyWidget;
    isWidgetEmpty?: (widget?: WidgetState) => boolean;
    editingContent?: boolean;
    canUseLangFallback?: boolean;
    /**
     * Callback called when the /blocks returns an error. (status error after some retries, or not migrated status)
     * */
    onError?: () => void;
}): {
    refetch: () => void;
    fetchMore?: () => void;
    widget: WidgetState;
} => {
    const widgetId = legacyWidget.uuid;
    const dispatch = useDispatch();

    // We need to debounce the dispatch, and not the thunk to handle multiple recomputed widgets.
    const debouncedDispatch = useMemo(() => debounce(dispatch, DEBOUNCE_DELAY), [dispatch]);

    const inputLang = useSelector(inputLanguageSelector);

    const siteId = useSelector(instanceIdSelector);
    const ownerResourceId = useSelector(getCurrentContentId);
    const ownerResourceType =
        useSelector(getCurrentContentType) === ContentType.community ? ContentType.community : ContentType.content;

    const fetchParams = useMemo(
        () => ({
            siteId,
            ownerResourceId,
            ownerResourceType,
        }),
        [ownerResourceId, ownerResourceType, siteId],
    );

    const widget = useSelector((state: FrontOfficeStore) => getWidget(state, { widgetId }));
    const status = widget?.state;
    const isInDesigner = Boolean(useSelector(isInDesignerMode));

    const setWidget = useCallback((newWidget?: WidgetState) => dispatch(actions.setWidget(newWidget)), [dispatch]);
    const setStatus = useCallback(
        (newStatus: WidgetState['state']) =>
            dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: newStatus } })),
        [dispatch, widgetId],
    );

    /** In case of error during a fetch, we automatically retry this fetch for a maximum of 3 attempts. */
    const MAX_FETCH_RETRY = 3;
    const countRetry = useRef(0);

    const filteredLegacyWidget: LegacyWidget = useMemo(() => {
        if (isInDesigner) {
            // We need to filter out global style id when in designer to handle
            // global style edition.
            // The style from global styles are copied into the properties.style so it
            // will be sent correctly to backend anyway.
            return { ...legacyWidget, style: undefined };
        }
        return legacyWidget;
    }, [isInDesigner, legacyWidget]);
    useEffect(() => {
        if (widget && !widget.body && (!status || (status === 'error' && countRetry.current < MAX_FETCH_RETRY))) {
            /**
             * Whether we fetch the widget or not is based on multiple factors:
             *     - The widget body is not set.
             *     - The state of the widget is either `undefined` (which means we haven't done anything yet) or
             *     in `'error'` (in which case we retry the fetch if we haven't already tried more than 3 times).
             */
            countRetry.current += 1;

            debouncedDispatch(
                fetchWidgetBlocksByProperties({
                    legacyWidget: filteredLegacyWidget,
                    params: { ...fetchParams, canUseLangFallback, forceDisplay: isInDesigner },
                }),
            );
        }

        // If the widget is failing to be fetched or is not migrated, then the fallback to v1 display is triggered.
        // The legacy designer uses this to fallback to the v1 version of the widget.
        if ((status === 'error' && countRetry.current >= MAX_FETCH_RETRY) || status === 'notmigrated') {
            onError?.();
        }
    }, [
        debouncedDispatch,
        isInDesigner,
        legacyWidget,
        status,
        widget,
        widgetId,
        fetchParams,
        onError,
        filteredLegacyWidget,
        canUseLangFallback,
    ]);

    // Reset everything if the input properties changed, and the widget is not being edited (useful for contribution widget).
    useEffect(() => {
        if (!editingContent) {
            countRetry.current = 0;
            setWidget({ widgetId, widgetType: legacyWidget.widgetType });
            setStatus(undefined);
        }
    }, [legacyWidget, setStatus, setWidget, widgetId, inputLang, editingContent]);

    useEffect(() => {
        const isListEmpty = widget?.body?.type === 'BlockNoResults';
        const isAugmentedListEmpty =
            widget?.body &&
            isSingleContainerBlock(widget?.body) &&
            widget?.body?.container.type === 'BlockNoResults' &&
            status === 'loaded';
        const isSimpleWidgetEmpty = widget && status === 'loaded' && isWidgetEmpty && isWidgetEmpty(widget);

        if (isListEmpty || isAugmentedListEmpty || isSimpleWidgetEmpty) {
            setStatus('empty');
        }
    }, [isWidgetEmpty, setStatus, status, widget, inputLang]);

    const fetchMore = useCallback(
        () =>
            dispatch(
                fetchWidgetBlocksByProperties({
                    params: { ...fetchParams, cursor: widget?.cursor, canUseLangFallback, forceDisplay: isInDesigner },
                    legacyWidget: filteredLegacyWidget,
                }),
            ),
        [dispatch, fetchParams, widget?.cursor, canUseLangFallback, isInDesigner, filteredLegacyWidget],
    );

    // Reset status on unmount.
    useEffect(() => {
        return () => {
            setStatus(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        refetch: fetchMore,
        fetchMore: widget?.more ? fetchMore : undefined,
        widget: { widgetType: legacyWidget.widgetType, widgetId: legacyWidget.uuid, ...(widget || {}), state: status },
    };
};
