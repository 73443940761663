declare const pendo: any;

interface TrackOptions {
    name: string;
    props: Record<string, any>;
}

export const track = (options: TrackOptions) => {
    if (typeof pendo !== 'undefined' && pendo && pendo.track) {
        pendo.track(options.name, options.props);
    }
};
