import loFind from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import get from 'lodash/get';
import map from 'lodash/map';

/////////////////////////////

function DirectoryService($rootScope, DirectoryFactory, InitialSettings, Instance, LumsitesBaseService) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(DirectoryFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default maximum number of results when listing instances.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_RESULTS = 10;

    /**
     * The list of all directories of the instance.
     *
     * @type {Array}
     */
    let _directories = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The default parameters for the service requests.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the listKey of an instance.
     *
     * @param  {string} instanceId The identifier of the instance to get the listKey of.
     * @return {string} The list key of the given instance.
     */
    function _getListKey(instanceId) {
        return Instance.getCurrentInstanceId() === instanceId ? 'default' : instanceId;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the id of the given directory.
     *
     * @param  {Object}   directory The directory to get the id of.
     * @param  {Function} [cb]      A callback function to execute with the directory id.
     * @return {string}   The identifier of a directory.
     */
    function directoryToId(directory, cb) {
        cb = cb || angular.noop;

        const id = get(directory, 'id');

        cb(id);

        return id;
    }

    /**
     * Get a directory based on its id.
     *
     * @param  {string} id The identifier of a directory to find.
     * @return {Object} A directory object.
     */
    function getById(id) {
        return loFind(_directories, {
            id,
        });
    }

    /**
     * Get the local list of directories.
     * This is initialy a copy of the jinja list but then may be changed by frontend actions.
     *
     * @return {Array} A list of directories.
     */
    function getLocalDirectories() {
        return _directories;
    }

    /**
     * Get a directory entry tag by uuid.
     *
     * @param  {string} uuid      The tag uuid to find in the directory tags.
     * @param  {Object} directory The directory to use to search through the tags.
     * @return {Object} A tag object.
     */
    function getTagByUuid(uuid, directory) {
        if (angular.isUndefinedOrEmpty([uuid, get(directory, 'tags')], 'some')) {
            return undefined;
        }

        return loFind(directory.tags, {
            uuid,
        });
    }

    /**
     * Get a directory based on its id.
     *
     * @param  {Object}   id   The identifier of a directory.
     * @param  {Function} [cb] A callback function to execute with the directory.
     * @return {Object}   A directory object.
     */
    function idToDirectory(id, cb) {
        cb = cb || angular.noop;

        if (angular.isUndefinedOrEmpty(id)) {
            cb();

            return undefined;
        }

        const list = service.getList();

        const directory = loFind(list, {
            id,
        });

        cb(directory);

        return directory;
    }

    /**
     * Get the uuid of a given directory tag.
     *
     * @param  {Object}   tag  The tag to get the uuid of.
     * @param  {Function} [cb] A callback function to execute with the tag uuid.
     * @return {string}   The identifier of a tag.
     */
    function tagToUuid(tag, cb) {
        cb = cb || angular.noop;

        const uuid = get(tag, 'uuid');

        cb(uuid);

        return uuid;
    }

    /**
     * Update or add an element to the local directory list.
     *
     * @param {Object} directory A directory object to update.
     * @param {Object} [content] A content object to add (since a directory is a content).
     */
    function updateLocalList(directory, content) {
        const existingDirectoryIndex = findIndex(_directories, {
            id: get(directory, 'id'),
        });

        if (existingDirectoryIndex > -1) {
            _directories[existingDirectoryIndex] = directory;

            return;
        }

        if (angular.isDefinedAndFilled(content)) {
            _directories.push({
                contentId: content.id,
                id: content.externalKey,
                instanceId: directory.instance,
                properties: content.properties,
                slug: content.slug,
                type: directory.type,
                uid: content.externalKey,
            });
        }

        $rootScope.$broadcast('directory-list-updated');
    }

    /**
     * Get a directory tag based on its uuid.
     *
     * @param  {Object}   uuid   The identifier of a directory tag.
     * @param  {Function} [cb]   A callback function to execute with the tag.
     * @param  {Array}    [tags] A list of directory tags to search through.
     *                           Defaults to the current directory tags.
     * @return {Object}   A tag object.
     */
    function uuidToTag(uuid, cb, tags) {
        cb = cb || angular.noop;

        if (angular.isUndefinedOrEmpty(uuid)) {
            cb();

            return undefined;
        }

        if (angular.isUndefined(tags) && angular.isDefined(service.getCurrent())) {
            tags = service.getCurrent().tags;
        }

        const tag = loFind(tags, {
            uuid,
        });

        cb(tag);

        return tag;
    }

    /**
     * Get a list of directories from one or multiple instances.
     *
     * @param {Array}    instanceIds     A list of instance identifiers to get the directories from.
     * @param {Function} [cb]            A callback function to execute when all directories have been listed.
     * @param {boolean}  [flatten=false] Indicates if we want to flatten the results in one array or keep an
     *                                   object keyed by instance name.
     */
    function getMultiInstanceDirectories(instanceIds, cb, flatten) {
        if (!angular.isArray(instanceIds)) {
            return;
        }

        flatten = flatten || false;
        cb = cb || angular.noop;

        let directoriesPerInstance = flatten ? [] : {};
        const instancesToProcess = instanceIds.length;
        let processedInstances = 0;
        let directoryIds = [];

        const _addDirectories = (directories) => {
            processedInstances++;
            directories = directories || [];
            directoriesPerInstance = directoriesPerInstance || [];

            Instance.getSiblings(false).then(() => {
                if (angular.isDefinedAndFilled(directories)) {
                    directoryIds = directoryIds.concat(map(directories, 'id'));

                    if (flatten) {
                        directoriesPerInstance = directoriesPerInstance.concat(directories);
                    } else {
                        const instance = Instance.instanceKeyToInstanceFromSiblings(first(directories).instance, true);

                        if (instance) {
                            directoriesPerInstance[instance.name] = directories;
                        }
                    }
                }

                if (processedInstances >= instancesToProcess) {
                    cb(directoriesPerInstance, directoryIds);
                }
            });
        };

        if (angular.isUndefinedOrEmpty(instanceIds)) {
            _addDirectories();

            return;
        }

        // For each instance, concat the directories into a global directory list.
        angular.forEach(instanceIds, (id) => {
            const listKey = _getListKey(id);

            // It's been fetched already, so concat right away.
            if (listKey in service._services) {
                _addDirectories(service._services[listKey]._list);
                // It has not been fetched yet, so get the list and concat it.
            } else {
                service.filterize(
                    {
                        instance: id,
                    },
                    _addDirectories,
                    undefined,
                    listKey,
                );
            }
        });
    }

    /////////////////////////////

    service.directoryToId = directoryToId;
    service.getById = getById;
    service.getLocalDirectories = getLocalDirectories;
    service.getMultiInstanceDirectories = getMultiInstanceDirectories;
    service.getTagByUuid = getTagByUuid;
    service.idToDirectory = idToDirectory;
    service.tagToUuid = tagToUuid;
    service.updateLocalList = updateLocalList;
    service.uuidToTag = uuidToTag;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = () => {
        service.defaultParams = {
            instance: Instance.getCurrentInstanceId(),
            maxResults: _MAX_RESULTS,
        };

        if (angular.isUndefinedOrEmpty(service.getCurrent())) {
            _directories = angular.fastCopy(InitialSettings.DIRECTORIES);
            service.initList('default', undefined, angular.fastCopy(_directories));
        }
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Directory', DirectoryService);

/////////////////////////////

export { DirectoryService };
