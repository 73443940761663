import React, { useCallback } from 'react';

import { Path, ReactEditor, useSelected, useSlateStatic } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import { UserMention } from '../../blocks/UserMention';

/**
 * Wrapper around user mention for the editor context.
 */
export const EditableUserMention: typeof UserMention = (props) => {
    const { element } = props;
    const editor = useSlateStatic() as ReactEditor;
    const isSelected = useSelected();

    // Select current element on focus.
    const onFocus = useCallback(() => {
        const path = ReactEditor.findPath(editor, element as any) as Path;
        const point = { path: [...path, 0], offset: 0 };
        focusAt(editor, point);
    }, [editor, element]);

    return <UserMention isEditing isSelected={isSelected} onFocus={onFocus} tabIndex={-1} {...props} />;
};
EditableUserMention.displayName = UserMention.displayName;
