import React, { useRef, useMemo } from 'react';

import { useCommunity } from '@lumapps/communities/hooks/useCommunity';
import { CommunityNavigationEditState } from '@lumapps/communities/types';
import { CommunitiesSettingsMenuEntries } from '@lumapps/community-settings/components/CommunitiesSettingsMenuEntries';
import { SpacesSettingsMenuEntries } from '@lumapps/community-settings/components/SpacesSettingsMenuEntries';
import {
    SettingsMenuCustomContentTypeProps,
    SettingsMenuCustomContentType,
} from '@lumapps/content-types/components/SettingsMenuCustomContentType';
import { ContentEditSettingsMenu } from '@lumapps/contents/components/ContentEditSettingsMenu';
import { useContent } from '@lumapps/contents/hooks/useContent';
import { PLACEMENT, Targets } from '@lumapps/customizations/api';
import { CustomizationComponent } from '@lumapps/customizations/api/components/CustomizationComponent';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiSettings } from '@lumapps/lumx/icons';
import { IconButton, Size, Emphasis, Placement, Offset, Theme } from '@lumapps/lumx/react';
import { useMetadataAdminRights } from '@lumapps/metadata/hooks/useMetadataAdminRights';
import {
    SettingsMenuSocialAdvocacyProps,
    SettingsMenuSocialAdvocacy,
} from '@lumapps/sa-navigation/components/SettingsMenuSocialAdvocacy';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { calculateThemeColor } from '@lumapps/utils/css/calculateThemeColor';

import {
    SettingsMenuContentStrategy,
    SettingsMenuContentStrategyProps,
} from './SettingsMenuContentStrategy/SettingsMenuContentStrategy';
import { SettingsMenuModules, SettingsMenuModulesProps } from './SettingsMenuModules/SettingsMenuModules';
import { SettingsMenuSiteProps, SettingsMenuSite } from './SettingsMenuSite/SettingsMenuSite';

import './index.scss';

export interface SettingsMenuProps
    extends SettingsMenuModulesProps,
        SettingsMenuCustomContentTypeProps,
        SettingsMenuSocialAdvocacyProps,
        SettingsMenuContentStrategyProps,
        SettingsMenuSiteProps {
    placement?: Placement;
    className?: string;
    offset?: Offset;
    isVisible: boolean;
    SAEnabled: boolean;
    openCommunityNavigation?: (payload: CommunityNavigationEditState) => void;
    isNewCommunityNavigationEnabled: boolean;
    canEditSpace: boolean;
    onCommunityEdit(): void;
    theme?: string;
}

const NAMESPACE = 'settings-menu';
export const SettingsMenu: React.FC<SettingsMenuProps> = ({
    placement = Placement.BOTTOM_END,
    className,
    offset = {},
    isVisible = true,
    // Community Section
    onCommunityEdit,
    openCommunityNavigation,
    isNewCommunityNavigationEnabled,
    // Space Section
    canEditSpace,

    // Custom Content Types Section
    fetchCustomContentTypes,
    isLoading = false,
    customContentTypes,
    parentCustomContentTypesIds,
    isSuperAdmin = false,
    allowedCustomContentTypes = {},
    isAllowedToAccessCCT,

    // Module Section
    canAccessModules,
    canAccessCommunities,
    canEditDirectories,
    directories,
    canEditNewsletter,

    // SA Section
    SAEnabled,
    userCanManagePlatformPrograms,
    userCanManagePlatformSocialNetworks,

    // Content Strategy Section
    canAccessBroadcast,
    canAccessJourneys,
    canAccessSegment,
    canAccessContentStream,

    // Site section
    canAccessSiteAnalytics,
    hasMainNavAdminAccess,
    canEditInstance,
    theme = Theme.light,
}) => {
    const iconRef = useRef(null);
    const { translateKey } = useTranslate();

    const { get } = useDataAttributes(NAMESPACE);

    const { canCurrentUserEditContent, currentContentId } = useContent();
    const { canEditCommunity, currentCommunityId, view, slug, canViewCommunityAnalytics, isCommunityPage } =
        useCommunity();
    const { isSpace } = useSpace();

    const { isAllowed: hasMetadataAdminRights } = useMetadataAdminRights();

    const handleCommunityNavigationEdit = () => {
        if (openCommunityNavigation) {
            openCommunityNavigation({ isOpen: true });
        }
    };

    const handleCommunityEdit = () => {
        if (onCommunityEdit) {
            onCommunityEdit();
        }
    };

    // If the user can access nothing, do not show the icon
    const canAccessMenu = useMemo(
        () =>
            isSuperAdmin ||
            canCurrentUserEditContent ||
            canEditCommunity ||
            canEditSpace ||
            canViewCommunityAnalytics ||
            isAllowedToAccessCCT ||
            canAccessCommunities ||
            canAccessModules ||
            canEditDirectories ||
            canEditNewsletter ||
            userCanManagePlatformPrograms ||
            userCanManagePlatformSocialNetworks ||
            canAccessSiteAnalytics ||
            hasMainNavAdminAccess ||
            canEditInstance ||
            canAccessBroadcast ||
            canAccessJourneys ||
            canAccessSegment ||
            hasMetadataAdminRights,

        [
            isSuperAdmin,
            canCurrentUserEditContent,
            canEditCommunity,
            canEditSpace,
            canViewCommunityAnalytics,
            isAllowedToAccessCCT,
            canAccessCommunities,
            canAccessModules,
            canEditDirectories,
            canEditNewsletter,
            userCanManagePlatformPrograms,
            userCanManagePlatformSocialNetworks,
            canAccessSiteAnalytics,
            hasMainNavAdminAccess,
            canEditInstance,
            canAccessBroadcast,
            canAccessJourneys,
            canAccessSegment,
            hasMetadataAdminRights,
        ],
    );

    if (!canAccessMenu || !isVisible) {
        return null;
    }

    return (
        <Menu.Trigger popoverProps={{ placement, offset }} popoverWidth={{ width: REAL_SIZE_FOR_LUMX_SIZE.xxl }}>
            <Menu.TriggerButton
                as={IconButton}
                icon={mdiSettings}
                size={Size.m}
                emphasis={Emphasis.low}
                className={className}
                ref={iconRef}
                color={calculateThemeColor(theme)}
                label={translateKey(GLOBAL.SETTINGS)}
                {...get({
                    element: 'button',
                    permissions: {
                        isAllowedToAccessCCT,
                        canCurrentUserEditContent,
                        canEditCommunity,
                        canViewCommunityAnalytics,
                        canAccessCommunities,
                        canEditDirectories,
                        canEditNewsletter,
                        userCanManagePlatformPrograms,
                        userCanManagePlatformSocialNetworks,
                        canAccessSiteAnalytics,
                        hasMainNavAdminAccess,
                        canEditInstance,
                        hasMetadataAdminRights,
                    },
                })}
            />

            <Menu className={NAMESPACE}>
                <CustomizationComponent target={Targets.SETTINGS} placement={PLACEMENT.ABOVE} />

                {!isSpace && (
                    <ContentEditSettingsMenu
                        canEditContent={canCurrentUserEditContent}
                        contentId={currentContentId}
                        scope={NAMESPACE}
                    />
                )}
                <SpacesSettingsMenuEntries
                    key="space"
                    scope={NAMESPACE}
                    onSpaceEdit={handleCommunityEdit}
                    onSpaceNavigationEdit={handleCommunityNavigationEdit}
                />
                {isCommunityPage && (
                    <CommunitiesSettingsMenuEntries
                        canEditCommunity={canEditCommunity}
                        canViewCommunityAnalytics={canViewCommunityAnalytics}
                        communityId={currentCommunityId}
                        communityTemplate={view}
                        communitySlug={slug}
                        scope={NAMESPACE}
                        onCommunityEdit={handleCommunityEdit}
                        onCommunityNavigationEdit={handleCommunityNavigationEdit}
                        isNewCommunityNavigationEnabled={isNewCommunityNavigationEnabled}
                    />
                )}
                <SettingsMenuCustomContentType
                    fetchCustomContentTypes={fetchCustomContentTypes}
                    isLoading={isLoading}
                    customContentTypes={customContentTypes}
                    parentCustomContentTypesIds={parentCustomContentTypesIds}
                    isSuperAdmin={isSuperAdmin}
                    allowedCustomContentTypes={allowedCustomContentTypes}
                    isAllowedToAccessCCT={isAllowedToAccessCCT}
                    scope={NAMESPACE}
                />
                <SettingsMenuModules
                    canAccessCommunities={canAccessCommunities}
                    canEditDirectories={canEditDirectories}
                    directories={directories}
                    canEditNewsletter={canEditNewsletter}
                    canAccessModules={canAccessModules}
                    scope={NAMESPACE}
                />
                <SettingsMenuContentStrategy
                    canAccessBroadcast={canAccessBroadcast}
                    canAccessJourneys={canAccessJourneys}
                    canAccessSegment={canAccessSegment}
                    canAccessContentStream={canAccessContentStream}
                    scope={NAMESPACE}
                />
                {SAEnabled && (
                    <SettingsMenuSocialAdvocacy
                        userCanManagePlatformPrograms={userCanManagePlatformPrograms}
                        userCanManagePlatformSocialNetworks={userCanManagePlatformSocialNetworks}
                        scope={NAMESPACE}
                    />
                )}
                <SettingsMenuSite
                    canAccessSiteAnalytics={canAccessSiteAnalytics}
                    hasMainNavAdminAccess={hasMainNavAdminAccess}
                    canEditInstance={canEditInstance}
                    hasMetadataAdminRights={hasMetadataAdminRights}
                    scope={NAMESPACE}
                />

                <CustomizationComponent target={Targets.SETTINGS} placement={PLACEMENT.UNDER} />
            </Menu>
        </Menu.Trigger>
    );
};

SettingsMenu.displayName = 'SettingsMenu';
