import { isFeatureEnabled } from '@lumapps/features';
import { JOURNEYS_FF } from '@lumapps/journeys/constants';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

export const isJourneysEnabled = isFeatureEnabled(JOURNEYS_FF);
export const canManageJourneys = isUserAllowed('canManageJourneys');

export const canAccessJourneysAdmin = createSelector(
    [isJourneysEnabled, canManageJourneys],
    (journeysEnabled, canManageJourneys) => journeysEnabled && canManageJourneys,
);
