import filter from 'lodash/filter';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import loFind from 'lodash/find';
import map from 'lodash/map';
import noop from 'lodash/noop';

import { getEnabledModes, MODES } from '@lumapps/customizations/modes';
import { USER_SPACE_FEATURE_TOKEN, USER_PROFILE_REACT_FEATURE_TOKEN } from '@lumapps/user-profile/constants';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { socialProfile } from '@lumapps/user-profile/routes';
import { angularApi } from '@lumapps/router/routers';
import { USER_PROFILE_VIEW } from '@lumapps/user-profile/types';

/////////////////////////////

function UserDirectoryService(
    Config,
    Content,
    Customer,
    Features,
    Feed,
    InitialSettings,
    Instance,
    LumsitesBaseService,
    Metadata,
    Translation,
    UserDirectoryFactory,
    User,
    Utils,
) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(UserDirectoryFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The id of the user directory details dialog.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.USER_DETAILS_DIALOG_ID = 'user-directory-details';

    /**
     * Id of the user displayed in the user details dialog.
     *
     * @type {string}
     */
    service.displayedUserId = undefined;

    /**
     * Id of the current user directory while displaying the user details dialog.
     *
     * @type {string}
     */
    service.displayedUserDirectoryId = undefined;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if an user directory need to have its fields initialized.
     * If the module is new and if there is no other component than widgets, then the user directory's fields need
     * to be initialized.
     *
     * @param  {Object}  userDirectory The userDirectory
     * @return {boolean} If the user directory's fields needs to be initialized.
     */
    function _doesDefaultFieldNeedToBeSet(userDirectory) {
        return (
            angular.isUndefinedOrEmpty(userDirectory.uid) &&
            angular.isUndefinedOrEmpty(
                loFind(get(userDirectory, 'template.components'), (item) => item.type !== 'widget'),
            )
        );
    }

    /**
     * Get an available api profile field by its name.
     *
     * @param  {string} fieldName The name of the available field to get the value of.
     * @return {*}      The value of the API profile field name we requested.
     */
    function _getAvailableApiProfileFieldByName(fieldName) {
        if (angular.isUndefinedOrEmpty(fieldName)) {
            return undefined;
        }

        return loFind(Config.AVAILABLE_API_PROFILE_FIELD, {
            name: fieldName,
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Generate the user directory basic fields.
     *
     * @param {Content} content The user directory content object.
     */
    function createDefaultUserDirectoryFields(content) {
        if (angular.isUndefinedOrEmpty(content)) {
            return;
        }

        content.template = content.template || {};

        const { template } = content;

        if (angular.isUndefined(template.components)) {
            content.template.components = [];
        }

        if (!_doesDefaultFieldNeedToBeSet(content) || angular.isUndefined(Config.USER_DIRECTORY_INIT_FIELD)) {
            return;
        }

        // Get the ALL feed id.
        const defaultFeeds = angular.isDefinedAndFilled(Feed.ALL) ? [Feed.ALL.id] : undefined;

        angular.forEach(Config.USER_DIRECTORY_INIT_FIELD, (field, index) => {
            const title = {};
            title[Translation.inputLanguage] = Translation.translate(field.name);

            const component = {
                properties: {
                    availableValues: [{}],
                    boundMap: _getAvailableApiProfileFieldByName(field.name),
                    displayInList: field.listDisplay || false,
                    editFeeds: [],
                    icon: field.icon,
                    index,
                    isBound: true,
                    seeFeeds: defaultFeeds,
                },
                title,
                type: 'inputText',
                uuid: generateUUID(),
                value: {},
            };

            template.components.push(component);
        });
    }

    /**
     * Return default user directory or first one of site, or default or first of parent.
     *
     * @param  {User}   user The connected user.
     * @return {Object} Default user directory.
     */
    function getMainUserDirectory(user) {
        if (angular.isDefinedAndFilled(Customer.getExternalDirectory())) {
            return {};
        }

        let userFeedIds = [Feed.PUBLIC.id];

        if (angular.isDefinedAndFilled(user)) {
            userFeedIds = map(user.subscriptions, 'feed');
        }

        const userDirForUser = filter(
            InitialSettings.USER_DIRECTORIES,
            (directory) =>
                includes(directory.feedKeys, Feed.ALL.id) || service.hasSubscription(directory.feedKeys, userFeedIds),
        );

        if (angular.isUndefinedOrEmpty(userDirForUser)) {
            const parentInstance = loFind(Instance.getSiblings(true), {
                id: get(Instance.getInstance(), 'parent'),
            });

            if (angular.isUndefinedOrEmpty([parentInstance, InitialSettings.PARENT_USER_DIRECTORIES], 'some')) {
                return {};
            }

            const parentUserDirForUser = filter(
                InitialSettings.PARENT_USER_DIRECTORIES,
                (directory) =>
                    includes(directory.feedKeys, Feed.ALL.id) ||
                    service.hasSubscription(directory.feedKeys, userFeedIds),
            );

            if (angular.isUndefinedOrEmpty(parentUserDirForUser)) {
                return {};
            }

            // Getting the default user directory defined in the parent instance.
            return (
                loFind(parentUserDirForUser, {
                    id: parentInstance.defaultUserDirectory,
                }) || first(parentUserDirForUser)
            );
        }

        // Getting the default user directory defined in the current instance.
        return (
            loFind(userDirForUser, {
                id: Instance.getInstance().defaultUserDirectory,
            }) || first(userDirForUser)
        );
    }

    /**
     * If in context of UserDirectory return it, or return main user directory.
     *
     * @param  {User}   user The connected user.
     * @return {Object} The good directory to work in for given site.
     */
    function getDefaultUserDirectory(user) {
        const content = Content.getCurrent();

        return get(content, 'type') === 'user_directory' ? content : service.getMainUserDirectory(user);
    }

    /**
     * Get the fields to be displayed in the user popover on mouseover.
     *
     * @param  {UserDirectory} userDirectory The user directory to get the fields from.
     * @param  {User}          user          The connected user.
     * @return {Array}         A list of user directory components objects.
     */
    function getPopoverFields(userDirectory, user) {
        const components = get(userDirectory, 'template.components');

        if (angular.isUndefinedOrEmpty(components)) {
            return undefined;
        }

        let userFeedIds = [Feed.PUBLIC.id];

        if (angular.isDefinedAndFilled(user)) {
            userFeedIds = map(user.subscriptions, 'feed');
        }

        // eslint-disable-next-line you-dont-need-lodash-underscore/filter
        return filter(
            components,
            (component) =>
                component.type !== 'widget' &&
                angular.isDefinedAndFilled(component.properties) &&
                Boolean(component.properties.displayInList) &&
                service.hasSubscription(component.properties.seeFeeds, userFeedIds),
        );
    }

    /**
     * Check if has at least one sub in feeds.
     *
     * @param  {Array}   [feeds=[]] Feed to compare.
     * @param  {Array}   [subs=[]]  User feed to compare with feeds.
     * @return {boolean} If one sub or not.
     */
    function hasSubscription(feeds = [], subs = []) {
        return angular.isDefinedAndFilled(intersection(feeds, subs));
    }

    /**
     * Model to selection for metadata that differ a bit for google apiProfile field.
     *
     * @param {Object}   data      Data of selected entry.
     * @param {Function} [cb=noop] The lxSelect callback function.
     * @param {Object}   component Selected component.
     */
    function metadataModelToSelection(data, cb = noop, component) {
        const { properties = {} } = component;
        const { isBound = false, metadataId } = properties;

        if (isBound && angular.isDefined(metadataId)) {
            // FIXME [Maxime]: ugly stuff, can't rely on it.
            const metadata = Metadata.getRefactoredMetadata(true);

            const filteredMetadata = filter(metadata, {
                key: metadataId,
            });

            let foundMatch = false;

            angular.forEach(filteredMetadata, (meta) => {
                angular.forEach(meta.items, (item) => {
                    if (data && Translation.translate(item.flatName).toLowerCase() === data.toLowerCase()) {
                        cb(item);
                        foundMatch = true;
                    }
                });
            });

            if (!foundMatch) {
                cb();
            }
        } else {
            Metadata.metadataKeysToMetadata(data, cb);
        }
    }

    /**
     * Metadata selection to model.
     *
     * @param {Object}   data            Data of selected entry.
     * @param {Function} [cb=noop]       The lxSelect callback function.
     * @param {boolean}  [isBound=false] If component is bound.
     */
    function metadataSelectionToModel(data, cb = noop, isBound = false) {
        if (isBound) {
            if (data.flatName) {
                cb(Translation.translate(data.flatName));
            }
        } else {
            Metadata.metadataToMetadataKeys(data, cb);
        }
    }

    /**
     * Show dialogs for selected user.
     *
     * @param {string}  userIdOrEmail The id (or email) of the user to display in the user details dialog.
     * @param {boolean} isEmail       Indicates if it's the user id or email that is given.
     */
    function setDisplayedUser(userIdOrEmail, isEmail) {
        service.displayedUserId = isEmail
            ? {
                  email: userIdOrEmail,
              }
            : userIdOrEmail;

        Utils.waitForAndExecute(`#${service.USER_DETAILS_DIALOG_ID}`);
    }

    /**
     * If user space is enabled and the view is migrated with corresponding FF enabled, redirect to new User Profile
     * @param {view} viewName The view name to check.
     */
    function shouldRedirectToV2Profile(viewName) {
        // if no view is set, check with page 'About'
        const viewToCheck = viewName || USER_PROFILE_VIEW.about;
        const modes = getEnabledModes(window.LUMAPPS_MODES ? window.LUMAPPS_MODES.split(',') : []);
        const isUserProfileReactModeActivated = modes[MODES.USER_PROFILE_REACT];
        return (
            // Obligatory User Space or User Profile React FF enabled
            (Features.hasFeature(USER_SPACE_FEATURE_TOKEN) ||
                Features.hasFeature(USER_PROFILE_REACT_FEATURE_TOKEN) ||
                isUserProfileReactModeActivated) &&
            // Target view must be in one of the new views OR history/activities as their name are different in V2 (= activity)
            [...Object.values(USER_PROFILE_VIEW), 'history', 'activities'].includes(viewToCheck)
        );
    }

    /**
     * Redirect to the new user space profile if FF enabled
     *
     * @param {string}  userId The id of the user to display.
     */
    function redirectToV2Profile(userId, view) {
        const connectedUser = User.getConnected();
        const isConnectedUser = userId === connectedUser.id;

        angularApi.redirect(
            socialProfile({
                // Set userId to "undefined" if it's the connected user to use default route. (/me)
                userId: isConnectedUser ? undefined : userId,
                // If we try to go to activities or history, redirect to activity page (the corresponding V2 name of the view)
                view: ['activities', 'history'].includes(view) ? USER_PROFILE_VIEW.activity : view,
                isUserSpaceEnabled: true,
            }),
        );
    }

    /**
     * Redirect to new user space or show the modal.
     *
     * @param {string}  userIdOrEmail The id (or email) of the user to display in the user details dialog or V2 Profile.
     * @param {boolean} isEmail       Indicates if it's the user id or email that is given.
     */
    function setDisplayedUserOrRedirect(userIdOrEmail, isEmail) {
        const modes = getEnabledModes(window.LUMAPPS_MODES ? window.LUMAPPS_MODES.split(',') : []);
        const isUserProfileReactModeActivated = modes[MODES.USER_PROFILE_REACT];
        if (Features.hasFeature(USER_PROFILE_REACT_FEATURE_TOKEN) || isUserProfileReactModeActivated) {
            if (isEmail) {
                // Get the user id and redirect to the new User Profile
                service.get({ email: userIdOrEmail }, (response) => {
                    const userId = response.id;
                    redirectToV2Profile(userId);
                });
            } else {
                // directly redirect as we already have the user id
                redirectToV2Profile(userIdOrEmail);
            }
            return;
        }
        setDisplayedUser(userIdOrEmail, isEmail);
    }

    /////////////////////////////

    service.createDefaultUserDirectoryFields = createDefaultUserDirectoryFields;
    service.getMainUserDirectory = getMainUserDirectory;
    service.getDefaultUserDirectory = getDefaultUserDirectory;
    service.getPopoverFields = getPopoverFields;
    service.hasSubscription = hasSubscription;
    service.metadataModelToSelection = metadataModelToSelection;
    service.metadataSelectionToModel = metadataSelectionToModel;
    service.shouldRedirectToV2Profile = shouldRedirectToV2Profile;
    service.redirectToV2Profile = redirectToV2Profile;
    service.setDisplayedUser = setDisplayedUser;
    service.setDisplayedUserOrRedirect = setDisplayedUserOrRedirect;

    /////////////////////////////

    return service;
}

angular.module('Services').service('UserDirectory', UserDirectoryService);

export { UserDirectoryService };
