/* eslint-disable react/no-multi-comp */

import React, { PureComponent } from 'react';
import { utc } from 'moment';
import isEqual from 'lodash/isEqual';

import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { Loader } from '../../ui/loader';
import { translate as t } from '../../../translations';
import { getBackgroundImage } from '../../../utils';
import { getFeed } from '../../../services/rss';
import { getWidgetTranslation } from '../../../services/widget';
import { widgetRSSType } from './types';
import { defaultAllowedAttributes, defaultAllowedTags } from './constants';

/**
 * The RSS feed max background width.
 *
 * @type {number}
 * @constant
 * @readonly
 */
const RSS_FEED_BACKGROUND_WIDTH = 128;

// Make sure link does not contain inline javascript
const getSanitizedLink = (link) => {
    const sanitizedLinkText = sanitizeHTML(`<a href="${link}" />`, { allowedTags : ['a'], allowedAttr : ['href'] }).trim();
    const sanitizedLinkTemplate = document.createElement('template');

    sanitizedLinkTemplate.innerHTML = sanitizedLinkText;

    return sanitizedLinkTemplate.content.firstChild.href;
};

/**
 * Render a feed item.
 *
 * @param  {Object}       feedItem Feed item.
 * @param  {number}       index    Feed index.
 * @return {ReactElement} Feed element.
 */
const renderFeedItem = (feedItem, index) => {
    const { date: timestamp, link, summary, title, image } = feedItem;
    const sanitizedLink = getSanitizedLink(link);
    const sanitizedTitle = sanitizeHTML(title);
    const sanitizedSummary = sanitizeHTML(summary, { allowedTags : defaultAllowedTags, allowedAttr : defaultAllowedAttributes });
    const htmlSummary = {
        __html: sanitizedSummary,
    };
    const dateFormat = t('DATE_FORMAT_COMPLETE');
    const date = utc(timestamp)
        .local()
        .format(dateFormat);

    return (
        <li key={index} className="widget-rss-feed">
            {image && (
                <div
                    className="widget-rss-feed__thumbnail"
                    style={getBackgroundImage(image, RSS_FEED_BACKGROUND_WIDTH, false, true)}
                />
            )}

            <div className="widget-rss-feed__wrapper">
                <span className="widget-rss-feed__date">{date}</span>

                <a className="widget-rss-feed__link" href={sanitizedLink} rel="noopener noreferrer" target="_blank">
                    <span className="widget-rss-feed__title">{sanitizedTitle}</span>
                </a>

                <div dangerouslySetInnerHTML={htmlSummary} className="widget-rss-feed__description" />
            </div>
        </li>
    );
};

/**
 * Displays the widget RSS.
 */
export class WidgetRss extends PureComponent {
    static propTypes = widgetRSSType;

    /**
     * Defines this widget as editable.
     *
     * @return {boolean} False.
     */
    static isEditable() {
        return false;
    }

    /**
     * Defines whether the widget is empty or not.
     *
     * @param  {Array}   value The current list of channel.
     * @return {boolean} Whether the RSS feed is empty or not.
     */
    static async isWidgetEmpty({ url, maxItems }) {
        const translatedUrl = url && getWidgetTranslation(url);

        if (!translatedUrl) {
            return true;
        }

        try {
            const items = await getFeed(translatedUrl, maxItems);

            return items.length === 0;
        } catch (exception) {
            return true;
        }
    }

    /**
     * Determines whether the widget is hidden or not.
     *
     * @param  {Object}  props The widget props.
     * @return {boolean} Whether the widget is hidden or not.
     */
    static isWidgetHidden(props) {
        return WidgetRss.isWidgetEmpty(props);
    }

    // eslint-disable-next-line react/state-in-constructor
    state = {
        /**
         * Defines whether the is a call in progress or not.
         *
         * @type {boolean}
         */
        isCallInProgress: false,

        /**
         * Contains all the feed items to display.
         *
         * @type {Array}
         */
        items: [],
    };

    componentDidMount() {
        this.fetchFeeds();
    }

    componentDidUpdate(prevProps) {
        if (isEqual(prevProps, this.props)) {
            return;
        }

        this.fetchFeeds();
    }

    /**
     * Fetch the RSS feed to display.
     */
    async fetchFeeds() {
        const { url, maxItems } = this.props;

        this.setState({
            isCallInProgress: true,
        });

        const items = await getFeed(getWidgetTranslation(url), maxItems);
        this.setState({ isCallInProgress: false, items });
    }

    render() {
        const { isCallInProgress, items } = this.state;

        return (
            <>
                {!isCallInProgress && <ul className="widget-rss__list">{items.map(renderFeedItem)}</ul>}
                {isCallInProgress && <Loader />}
            </>
        );
    }
}
