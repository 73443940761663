import difference from 'lodash/difference';
import every from 'lodash/every';
import get from 'lodash/get';
import has from 'lodash/has';
import loFind from 'lodash/find';

import { hexToRGBA } from '@lumapps/utils/color';

/////////////////////////////

function WidgetStandaloneQuestionController(
    $filter,
    $locale,
    $scope,
    $timeout,
    Charts,
    ConfigTheme,
    Community,
    Content,
    ContentTemplate,
    LxNotificationService,
    Question,
    Translation,
    User,
    UserAccess,
    UserContent,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay for the animation of the bar chart.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _ANIMATE_BAR_CHAR_DELAY = 100;

    /**
     * The number of decimals to display for floating point numbers.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DECIMAL_NUMBERS = 2;

    /**
     * The default height of the chart.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_CHART_HEIGHT = 200;

    /**
     * The maximum number to compute the statistics synchronously.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_NUMBER_OF_ANSWER_FOR_SYNC = 100;

    /**
     * Color range for charts.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _REPORT_COLORS = ConfigTheme.REPORT_COLORS
        ? ConfigTheme.REPORT_COLORS
        : difference(ConfigTheme.COLORS, ['#FFFFFF', '#000000']);

    /**
     * Doughnut chart background-color.
     *
     * @type {Array}
     */
    const _chartDoughnutBackgroundColor = [];

    /**
     * Doughnut chart hover background-color.
     *
     * @type {Array}
     */
    const _chartDoughnutHoverBackgroundColor = [];

    /**
     * Doughnut chart labels.
     *
     * @type {Array}
     */
    const _chartDoughnutLabels = [];

    /**
     * Doughnut chart values.
     *
     * @type {Array}
     */
    const _chartDoughnutValues = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     *  Chart bar range/scale.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    // eslint-disable-next-line no-magic-numbers
    vm.CHART_BAR_SCALES = [0, 20, 40, 60, 80, 100];

    /**
     * Enum of all available chart modes.
     *
     * @type {Object}
     */
    vm.CHART_TYPES = {
        bar: 'bar',
        doughnut: 'doughnut',
    };

    /**
     * Dialog popin identifier.
     *
     * @type {string}
     * @constant
     */
    vm.DIALOG_ID = `widget-standalone-question__report--${vm.widget.uuid}`;

    /**
     * Connected user answer.
     *
     * @type {Object}
     */
    vm.answer = {};

    /**
     * Map of choices and answers.
     *
     * @type {Object}
     */
    vm.choiceMapping = {};

    /**
     * If the statistic are computed asynchronously or directly.
     *
     * @type {boolean}
     */
    vm.isAsync = false;

    /**
     * Saving current state.
     *
     * @type {boolean}
     */
    vm.isSaving = false;

    /**
     * Date of the last statistic computation.
     *
     * @type {Date}
     */
    vm.lastComputationDate = undefined;

    /**
     * Question container.
     *
     * @type {Object}
     */
    vm.question = undefined;

    /**
     * Current chart type displayed.
     *
     * @type {string}
     */
    vm.reportChartType = vm.CHART_TYPES.doughnut;

    /**
     * Does the current/connected user already answered.
     *
     * @type {boolean}
     */
    vm.userAnswered = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Translation = Translation;
    vm.UserAccess = UserAccess;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the number of participants for a choice.
     *
     * @param  {string} uuid Question choice unique identifier.
     * @return {*}      Number of participant for the wanted choice.
     */
    function _getChoiceParticipantsNumber(uuid) {
        const answers = get(vm.widget, 'properties.stats.answers');

        if (angular.isUndefinedOrEmpty(answers)) {
            return 0;
        }

        // Due to bad design in some case vm.widget.properties.stats.answers[uuid] is a boolean.
        if (answers[uuid] === true) {
            answers[uuid] = 1;
        }

        return parseInt(answers[uuid], 10) || 0;
    }

    /**
     * Return userContent wanted widget.
     *
     * @param  {Object} userContent User answers.
     * @param  {string} uuid        The widget unique identifier.
     * @return {Object} The searched widget.
     */
    function _getUserContentWidgetByUuid(userContent, uuid) {
        return loFind(get(userContent, 'values.widgets'), function matchWidget(widget) {
            return widget.uuid === uuid;
        });
    }

    /**
     * Initialize doughnut chart options.
     */
    function _initChart() {
        // Build mapping.
        vm.choiceMapping = {};

        angular.forEach(vm.question.choices, function computeChoice(choice, idx) {
            const participants = get(vm.widget, 'properties.stats.count', 0);
            const choiceParticipants = _getChoiceParticipantsNumber(choice.identifier);
            const ratio = choiceParticipants && participants ? choiceParticipants / participants : 0;
            // eslint-disable-next-line no-magic-numbers
            const percent = $filter('number')(ratio * 100, _DECIMAL_NUMBERS).replace(
                new RegExp(Utils.escapeRegexp($locale.NUMBER_FORMATS.DECIMAL_SEP), 'g'),
                '.',
            );

            // Choice mapping.
            vm.choiceMapping[choice.identifier] = {
                participants: choiceParticipants,
                participationPercent: percent,
                text: choice.value,
            };

            const colorItem = Utils.getItemColor(idx, _REPORT_COLORS);

            // Doughnut chart data.
            _chartDoughnutValues.push(percent);

            // Label.
            _chartDoughnutLabels.push(Translation.translate(choice.value));

            // Background-color.
            _chartDoughnutBackgroundColor.push(colorItem);

            // Hover background-color.
            // eslint-disable-next-line no-magic-numbers
            _chartDoughnutHoverBackgroundColor.push(hexToRGBA(colorItem, 0.75));
        });
    }

    /**
     * Check if submitted answer is valid.
     *
     * @return {boolean} If the answer is valid.
     */
    function _isAnswerValid() {
        if (vm.question.isMultiple) {
            // Check if has at a least a value at true.
            return loFind(vm.answer.value, function answerValidator(value) {
                return value === true;
            });
        }

        return angular.isDefinedAndFilled(vm.answer.value);
    }

    /**
     * Check if answer and question are of the same kind (type and isMultiple matching).
     *
     * @param  {Object}  answer   The answer to check type from.
     * @param  {Object}  question The question to check type from.
     * @return {boolean} If the kind matches.
     */
    function _isSameKind(answer, question) {
        return answer && answer.type === question.getType() && answer.isMultiple === question.isMultiple;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Animate chart bar.
     */
    function animateBarChart() {
        $timeout(function addClass() {
            const chartBars = angular
                .element(`#${vm.DIALOG_ID}`)
                .find('.widget-standalone-question-chart-bar__bar--init');

            chartBars.removeClass('widget-standalone-question-chart-bar__bar--init');
        }, _ANIMATE_BAR_CHAR_DELAY);
    }

    /**
     * Get bar style.
     *
     * @param  {number} index      The color index.
     * @param  {string} identifier The Answer/question identifier.
     * @return {Object} Built style object.
     */
    function getBarStyle(index, identifier) {
        const percentage = get(vm.choiceMapping, `[${identifier}].participationPercent`, 0);

        return {
            'background-color': Utils.getItemColor(index, _REPORT_COLORS),
            // eslint-disable-next-line no-magic-numbers
            height: `${(_DEFAULT_CHART_HEIGHT * percentage) / 100}px`,
            // eslint-disable-next-line no-magic-numbers
            'margin-top': `${(_DEFAULT_CHART_HEIGHT * (100 - percentage)) / 100}px`,
        };
    }

    /**
     * Get the background color based on the index.
     *
     * @param  {number} index The wanted color index.
     * @return {Object} Built background style object.
     */
    function getLegendColor(index) {
        return {
            'background-color': Utils.getItemColor(index, _REPORT_COLORS),
        };
    }

    /**
     * Get widget class.
     *
     * @return {Array} A list of classes to apply on the widget.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        if (vm.userAnswered) {
            widgetClass.push('widget-standalone-question--answered');
        }

        return widgetClass;
    }

    /**
     * Check if the widget is empty.
     * The widget is empty when:
     * - There's no question settings.
     * - There's no question title.
     * - There's no anwser choice.
     * - There's only empty choices.
     *
     * @return {boolean} Widget emptyness status.
     */
    function isWidgetEmpty() {
        const { questionSettings } = vm.widget.properties;

        if (angular.isUndefinedOrEmpty(questionSettings)) {
            return true;
        }

        const isTitleEmpty =
            angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(questionSettings.title)) ||
            vm.parentCtrl.getWidgetTranslation(questionSettings.title) === '';

        if (isTitleEmpty) {
            return true;
        }

        const choices = get(questionSettings, 'choices', []);

        return every(choices, function areAllChoicesEmpty(choice) {
            return angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(choice.value));
        });
    }

    /**
     * Check if the widget is hidden in reading mode.
     * The widget is hidden when:
     * - The widget is empty and we're not in the designer.
     * - The user can save his anwser and the question is open.
     *
     * @return {boolean} The hidden state of the widget.
     */
    function isWidgetHidden() {
        vm.parentCtrl.isHidden = !vm.parentCtrl.designerMode() && vm.isWidgetEmpty();

        return vm.parentCtrl.isHidden || (!vm.UserAccess.canManageInstanceSettings && !vm.widget.properties.isOpen);
    }

    /**
     * Show report to update the answer.
     */
    function displayReport() {
        vm.updateAnswer = true;
    }

    /**
     * Check and save an answer.
     */
    function submitAnswer() {
        if (!_isAnswerValid()) {
            LxNotificationService.error(Translation.translate('WIDGET_TYPE_STANDALONE-QUESTION_NOT_ANSWERED'));

            return;
        }

        const currentContent = Content.getCurrent();

        vm.isSaving = true;

        const answer = {
            isMultiple: vm.question.isMultiple,
            type: vm.question.getType(),
            value: vm.answer.value,
        };

        UserContent.updateUserContentWidget(currentContent, vm.widget.uuid, {
            answer,
        });

        UserContent.save(
            {
                content: currentContent.id,
                user: User.getConnected().uid,
                values: angular.copy(currentContent.userContent.values),
            },
            function onSaveSuccess(response) {
                LxNotificationService.success(Translation.translate('WIDGET_TYPE_STANDALONE-QUESTION_ANSWER_SUCCESS'));
                vm.isSaving = false;

                const widget = _getUserContentWidgetByUuid(response, vm.widget.uuid);

                // If vm.isAsync is true we don't need to interpolate, 'cause stats are computed async at this point.
                if (!has(widget, 'answer')) {
                    return;
                }

                if (vm.userAnswered) {
                    /**
                     * If there is more than _MAX_NUMBER_OF_ANSWER_FOR_SYNC answers for the widget
                     * We do not refresh the widget to avoid loading a large amount of data.
                     * */
                    if (vm.isAsync) {
                        // Set updateAnswer at false, in order to show the report page
                        vm.updateAnswer = false;
                        return;
                    }

                    if (currentContent.type === 'community') {
                        Community.get(
                            {
                                uid: currentContent.id,
                            },
                            function onCommunityGetSuccess(community) {
                                const currentTemplate = loFind(community.templates, {
                                    functionalInnerId: currentContent.template.functionalInnerId,
                                });

                                community.template = currentTemplate;

                                // Find current widget in res widget list and update current widget settings.
                                const target = ContentTemplate.getElement(community, 'widget', 'uuid', vm.widget.uuid);

                                vm.widget.properties.stats = get(
                                    target,
                                    'properties.stats',
                                    vm.widget.properties.stats,
                                );

                                vm.init();
                            },
                            Utils.displayServerError,
                        );

                        return;
                    }

                    Content.get(
                        {
                            uid: currentContent.id,
                        },
                        function onContentGetSuccess(content) {
                            // Find current widget in res widget list and update current widget settings.
                            const target = ContentTemplate.getElement(content, 'widget', 'uuid', vm.widget.uuid);

                            vm.widget.properties.stats = get(target, 'properties.stats', vm.widget.properties.stats);
                        },
                        Utils.displayServerError,
                    );
                } else {
                    vm.userAnswered = true;

                    // Iter over response.values.widgets to find answer.value (iter over it and add element to stats).
                    const currentWidget = loFind(get(response, 'values.widgets'), {
                        uuid: vm.widget.uuid,
                    });

                    if (angular.isUndefinedOrEmpty(currentWidget)) {
                        return;
                    }

                    let userAnswers = get(currentWidget, 'answer.value');

                    // Patch, if userAnswers is a string and not an object.
                    if (!angular.isObject(userAnswers)) {
                        // Cast as object.
                        const tmp = {};
                        tmp[userAnswers] = true;
                        userAnswers = tmp;
                    }

                    const answers = get(vm.widget, 'properties.stats.answers', {});

                    angular.forEach(userAnswers, function addToStats(item, key) {
                        const numValue = item ? 1 : 0;

                        // If result is not in collection.
                        if (!has(answers, key)) {
                            answers[key] = 0;
                        }

                        // Increment stat (can be 1 or 0).
                        answers[key] += numValue;
                    });

                    // Update stats.answers.
                    vm.widget.properties.stats.answers = answers;

                    // Increment stats.count.
                    vm.widget.properties.stats.count = get(vm.widget, 'properties.stats.count', 0);
                    vm.widget.properties.stats.count += 1;
                }

                vm.init();
            },
            function OnSaveError(err) {
                Utils.displayServerError(err);
                vm.isSaving = false;
            },
        );
    }

    /**
     * Display result.
     */
    function openReport() {
        Utils.waitForAndExecute(`#${vm.DIALOG_ID}`);
    }

    /**
     * Switch chart type.
     *
     * @param {string} type The new type of chart.
     */
    function toggleChart(type) {
        if (angular.isDefinedAndFilled(vm.CHART_TYPES[type])) {
            vm.reportChartType = type;
        }
    }

    /////////////////////////////

    vm.animateBarChart = animateBarChart;
    vm.displayReport = displayReport;
    vm.getBarStyle = getBarStyle;
    vm.getLegendColor = getLegendColor;
    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.openReport = openReport;
    vm.submitAnswer = submitAnswer;
    vm.toggleChart = toggleChart;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the settings has updated, refresh the widget if it's necessary.
     *
     * @param {Event}  evt        The original event triggering this method.
     * @param {string} widgetUuid The widget uuid of the updated settings.
     */
    $scope.$on('widget-standalone-question-settings', function settingsClose(evt, widgetUuid) {
        if (vm.widget.uuid === widgetUuid && angular.isDefinedAndFilled(vm.widget.properties.questionSettings)) {
            vm.init();
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        vm.updateAnswer = false;

        const properties = get(vm.widget, 'properties', {});

        if (angular.isUndefinedOrEmpty(properties.isOpen)) {
            properties.isOpen = true;
        }

        if (angular.isUndefinedOrEmpty(properties.reportInPopin)) {
            properties.reportInPopin = false;
        }

        if (angular.isDefinedAndFilled(properties.questionSettings)) {
            vm.question = Question.buildQuestion(properties.questionSettings);
        }

        // Build user content.
        const UserActiveWidgetValues = UserContent.getCurrentWidget(Content.getCurrent(), vm.widget.uuid);

        // Check if user has an answer.
        const { answer } = UserActiveWidgetValues;

        // If the user has already answered to the question and he can update his anwser, we display result in first.
        vm.userAnswered = angular.isDefinedAndFilled(answer);

        // If number of answer is too big we do not fetch the new results.
        vm.isAsync = get(properties, 'stats.count', 0) > _MAX_NUMBER_OF_ANSWER_FOR_SYNC;

        // Store unix timestamp of last answer computation time.
        const lastComputationTimestamp = parseInt(properties.lastCompilation, 10);

        // Store formatted date.
        if (lastComputationTimestamp) {
            // Build a moment unix date.
            vm.lastComputationDate = moment.unix(lastComputationTimestamp);
        }

        // If same kind set user answer.
        if (_isSameKind(answer, vm.question)) {
            vm.answer.value = answer.value;
        }

        // Compute choices for graph display.
        if (angular.isDefinedAndFilled(vm.question) && vm.question.getType() === 'choices') {
            _initChart();
        }
    };

    /**
     * Set the parent controller.
     *
     * @param {Object} parentCtrl Parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        vm.init();
    };
}

/////////////////////////////

/**
 * The standalone question widget.
 * Display a closed question with unique or multiple choice.
 * Results are displayed by a horizontal bar chart.
 *
 * @param {Object} widget The widget configuration object.
 */

function WidgetStandaloneQuestionDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetStandaloneQuestionController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetStandaloneQuestion', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-standalone-question/views/widget-standalone-question.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetStandaloneQuestion', WidgetStandaloneQuestionDirective);

/////////////////////////////

export { WidgetStandaloneQuestionController, WidgetStandaloneQuestionDirective };
