/* istanbul ignore file */

/**
 * This module lists custom icons not available in `@lumapps/lumx/icons`.
 * These icons are preview-able & searchable on Storybook: https://storybook.lumapps.net/?path=/story/lumx-core-icons--icons
 *
 * How to optimize SVG path:
 * 1. Execute `npx codemod optimize-svg-path-string-declaration`
 *    (Download and execute a script searching and replacing all SVG path in the project)
 * 2. Only commit this file
 *    (Sorry I could not make it restrict to a folder or file)
 */

/**
 * Magnifying glass over three lines.
 */
export const explore =
    'M2 11.17h4.55q-.05-.39-.05-.78l.02-.56H2Zm0-3.34h5.03a6 6 0 01.79-1.33H2Zm0 5.34v1.33h6q-.51-.61-.86-1.33Zm10.89-6.51a3.73 3.73 0 010 7.46 3.73 3.73 0 110-7.46m0-1.66A5.4 5.4 0 009 6.66q-.5.51-.85 1.16l-.01.01a5.4 5.4 0 00.13 5.34q.32.5.72.93l.01.02a5.4 5.4 0 007.39.36l.22.22v.66l4.15 4.14L22 18.26l-4.14-4.15h-.66l-.22-.22A5.38 5.38 0 0012.89 5';

/**
 * Content page with header, text and image to the left.
 */
export const contentPage =
    'M4 4h16.84v1.68H4zm15.16 7.58H5.68V9.05h13.48zm0 3.37h-5.9v-1.69h5.9zm0 3.37h-5.9v-1.69h5.9zm-7.58 0h-5.9v-5.06h5.9zM4 7.37v10.95c0 .93.75 1.68 1.68 1.68h13.48c.93 0 1.68-.75 1.68-1.68V7.37z';

/**
 * Crossed out check for Mark as un-relevant comment.
 */
export const checkOffCircleOutline =
    'M19.96 18.04A10 10 0 0022 12c0-5.5-4.5-10-10-10-2.26 0-4.36.76-6.04 2.04l1.42 1.43a8.01 8.01 0 0111.15 11.15zm-5.5-5.5L18 9l-1.41-1.42-3.55 3.55zM2.54 3.46 1.29 4.71l2.15 2.14A10 10 0 002 12c0 5.5 4.5 10 10 10 1.88 0 3.64-.53 5.15-1.44l2.14 2.15 1.25-1.25zM4 12c0-1.33.33-2.58.9-3.68l5.48 5.47-.38.38-2.59-2.58L6 13l4 4 1.79-1.79 3.9 3.89A8 8 0 014 12';

/**
 * A speech bubble with three dots inside, used by the mobile to illustrate the digital assistant.
 */
export const digitalAssistant =
    'M8.98 14.94a4.16 4.16 0 01-4.23-4.05 4.2 4.2 0 014.23-4.04h5.95a4.14 4.14 0 014.23 4.04 4.14 4.14 0 01-4.23 4.05ZM9.21 4C5.29 4 2 7.15 2 10.89a7 7 0 005.15 6.61q.98.29 2.08.29h4.01v1.77c0 .35.41.57.73.35l4.53-3.08.09-.09A6.8 6.8 0 0022 10.87C21.95 7.09 18.73 4 14.75 4Zm6.15 8.1c-.69 0-1.25-.54-1.25-1.21s.56-1.2 1.25-1.2c.7 0 1.26.53 1.26 1.2s-.56 1.21-1.26 1.21m-1.94-1.21c0-.66-.57-1.2-1.26-1.2s-1.26.53-1.26 1.2.57 1.21 1.26 1.21 1.26-.54 1.26-1.21m-3.2 0c0-.66-.57-1.2-1.26-1.2s-1.26.53-1.26 1.2.57 1.21 1.26 1.21 1.26-.54 1.26-1.21';

/**
 * A generic person bust with a plus in the bottom right corner.
 */
export const membersOnlyContribution =
    'M21.25 16.95h-2.2v-2.2h-1.1v2.2h-2.2v1.1h2.2v2.2h1.1v-2.2h2.2ZM18.5 12a5.5 5.5 0 010 11 5.5 5.5 0 110-11m-10 .5c1.18 0 2.88.28 4.41.8a7 7 0 00-1.33 5.2H.5v-2c0-2.67 5.33-4 8-4m0-10a4 4 0 010 8 4 4 0 110-8';

/**
 * An email with marks on left to mark the movement.
 */
export const emailFast =
    'M22 5.5H9a2 2 0 00-2 2v9a2 2 0 002 2h13a2 2 0 002-2v-9a2 2 0 00-2-2m0 3.67-6.5 3.33L9 9.17V7.5l6.5 3.31L22 7.5zM5 16.5q.01.25.05.5H1c-.55 0-1-.45-1-1s.45-1 1-1h4zM3 7h2.05q-.04.25-.05.5V9H3c-.55 0-1-.45-1-1s.45-1 1-1m-2 5a1 1 0 011-1h3v2H2a1 1 0 01-1-1';

/**
 * A skeleton visualization of the positioning of a thumbnail, on the upper left corner
 */
export const thumbnailDisplaySmall =
    'M5 21.67h7s1.5 0 1.5 1.5 0 1.5-1.5 1.5H5s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m12 0h4s1.5 0 1.5 1.5 0 1.5-1.5 1.5h-4s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m10 0h6s1.5 0 1.5 1.5 0 1.5-1.5 1.5h-6s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m-10-9h16s1.5 0 1.5 1.5 0 1.5-1.5 1.5H17s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m41 0h2s1.5 0 1.5 1.5 0 1.5-1.5 1.5h-2s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m-52.5-2h6s2 0 2 2v3s0 2-2 2h-6s-2 0-2-2v-3s0-2 2-2';

/**
 * A skeleton visualization of the positioning of a thumbnail, on the whole header width
 */
export const thumbnailDisplayCover =
    'M5 26.67h7s1.5 0 1.5 1.5 0 1.5-1.5 1.5H5s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m12 0h4s1.5 0 1.5 1.5 0 1.5-1.5 1.5h-4s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m10 0h6s1.5 0 1.5 1.5 0 1.5-1.5 1.5h-6s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m-22-7h16s1.5 0 1.5 1.5 0 1.5-1.5 1.5H5s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m53 0h2s1.5 0 1.5 1.5 0 1.5-1.5 1.5h-2s-1.5 0-1.5-1.5 0-1.5 1.5-1.5m-52.5-14h54s2 0 2 2v6s0 2-2 2h-54s-2 0-2-2v-6s0-2 2-2';

/**
 * View mode grid: 4 cards in a grid
 */
export const viewModeGrid =
    'M13.9 19.5V13h-1.1v6.5zm6.6 0V13h-1.1v6.5zm0 1.5h-7.7v1h7.7zm0-2.5h-7.7v1h7.7zm0-5.5h-7.7v1h7.7zM4.6 19.5V13H3.5v6.5zm6.6 0V13h-1.1v6.5zm0 1.5H3.5v1h7.7zm0-2.5H3.5v1h7.7zm0-5.5H3.5v1h7.7zm2.7-4.5V2h-1.1v6.5zm6.6 0V2h-1.1v6.5zm0 1.5h-7.7v1h7.7zm0-2.5h-7.7v1h7.7zm0-5.5h-7.7v1h7.7zM4.6 8.5V2H3.5v6.5zm6.6 0V2h-1.1v6.5zm0 1.5H3.5v1h7.7zm0-2.5H3.5v1h7.7zm0-5.5H3.5v1h7.7z';

/**
 * View mode grid group: 4 cards in a grid framed in a box
 */
export const viewModeGridGroup =
    'M4.32 3.79h6.72v5.47H4.32zm.96.84v3.79h4.8V4.63zm-.96 5.48h6.72v.84H4.32zm8.64-6.32h6.72v5.47h-6.72zm.96.84v3.79h4.8V4.63zm-.96 5.48h6.72v.84h-6.72zm-8.64 2.94h6.72v5.48H4.32zm.96.84v3.79h4.8v-3.79zm-.96 5.48h6.72v.84H4.32zm8.64-6.32h6.72v5.48h-6.72zm.96.84v3.79h4.8v-3.79zm-.96 5.48h6.72v.84h-6.72zM1.92 1.68h20.16v20.64H1.92zm.96.85v18.94h18.24V2.53z';

/**
 * View mode grid ungroup: 4 cards individually framed in a box in a grid
 */
export const viewModeGridUngroup =
    'M4.14 3.31h5.38v4.55H4.14zm.82.83v2.9h3.73v-2.9zm-.82 4.55h5.38v.83H4.14zM2.48 1.66h8.69v9.51H2.48zm.83.82v7.86h7.03V2.48zm11.17.83h5.38v4.55h-5.38zm.83.83v2.9h3.73v-2.9zm-.83 4.55h5.38v.83h-5.38zm-1.65-7.03h8.69v9.51h-8.69zm.83.82v7.86h7.03V2.48zm-9.52 12h5.38v4.56H4.14zm.82.83v2.9h3.73v-2.9zm-.82 4.55h5.38v.83H4.14zm-1.66-7.03h8.69v9.51H2.48zm.83.83v7.86h7.03v-7.86zm11.17.82h5.38v4.56h-5.38zm.83.83v2.9h3.73v-2.9zm-.83 4.55h5.38v.83h-5.38zm-1.65-7.03h8.69v9.51h-8.69zm.83.83v7.86h7.03v-7.86z';

/**
 * View mode list: 2 cards listed vertically
 */
export const viewModeList =
    'M19 19.5h-6v-1h6zM19 8h-6V7h6zm3 9h-9v-1h9zm0-11.5h-9v-1h9zm-20 8h9V22H2zm1 1V21h7v-6.5zM2 2h9v8.5H2zm1 1v6.5h7V3z';

/**
 * View mode list group: 2 cards listed vertically in a box
 */
export const viewModeListGroup =
    'M16.8 17.31H12v-.79h4.8zm0-7.87H12v-.78h4.8zm2.4 5.9H12v-.78h7.2zm0-7.86H12v-.79h7.2zm-16 5.11h7.2v6.69H3.2zm.8.79v5.11h5.6v-5.11zm-.8-8.66h7.2v6.69H3.2zm.8.79v5.11h5.6V5.51zM1.2 2.75h21.6v18.5H1.2zm.8.79v16.92h20V3.54z';

/**
 * View mode list: 2 cards individually framed in a box in a vertical list
 */
export const viewModeListUngroup =
    'M1.13 2.63h20.25v8.25H1.13zm.75.75v6.75h18.75V3.38zm15.75 3h-6.75v-.75h6.75zM3 4.5h5.25V9H3zm.75.75v3H7.5v-3zM1.13 12h20.25v8.25H1.13zm.75.75v6.75h18.75v-6.75zm13.5 4.88h-4.5v-.75h4.5zm2.25-1.88h-6.75V15h6.75zM3 13.88h5.25v4.5H3zm.75.75v3H7.5v-3z';

/**
 * View mode slideshow: 1 main card with card pages listed below
 */
export const viewModeSlideshow =
    'M21.91 2v13H2.09V2Zm-1.04 1H3.13v11h17.74Zm1.04 14v5H16.7v-5Zm-1.04 1h-3.13v3h3.13Zm-6.26-1v5H9.39v-5Zm-1.04 1h-3.14v3h3.14ZM7.3 17v5H2.09v-5Zm-1.04 1H3.13v3h3.13Z';

/**
 * LumApps Broadcast Icon
 */
export const lumappsBroadcast =
    'M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m6 2c0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2.2 1.2 4.1 3 5.2l1-1.7a4 4 0 01-2-3.4c0-2.2 1.8-4 4-4s4 1.8 4 4c0 1.5-.8 2.8-2 3.4l1 1.7c1.8-1 3-3 3-5.2M12 2C6.5 2 2 6.5 2 12c0 3.7 2 6.9 5 8.6l1-1.7c-2.4-1.4-4-4-4-6.9 0-4.4 3.6-8 8-8s8 3.6 8 8c0 3-1.6 5.5-4 6.9l1 1.7c3-1.7 5-4.9 5-8.6 0-5.5-4.5-10-10-10';

/**
 * LumApps Campaign Icon
 */
export const lumappsCampaign =
    'M11.5 3v3.1h.5c3.7 0 6.6 3.4 5.8 7.3-.5 2.2-2.2 3.9-4.3 4.4-2.9.7-5.5-.7-6.7-3L4 16.2c1.7 3.3 5.4 5.4 9.5 4.7 3.7-.6 6.8-3.6 7.4-7.3A9 9 0 0011.5 3m-2 9a2.5 2.5 0 105 0 2.5 2.5 0 10-5 0';

/**
 * LumApps Journey Icon
 */
export const lumappsJourney =
    'M3 14.8c-1 0-1.8.8-1.9 1.9A1.9 1.9 90 003 18.6h9.9a1.9 1.9 0 100-3.8zm1.4-4.6c-1.1 0-1.9.8-1.9 1.9A1.9 1.9 90 004.4 14H19a1.9 1.9 0 100-3.8zm5.4-4.7c-1 0-1.8.8-1.8 1.9 0 1 .8 1.8 1.8 1.9H21a1.9 1.9 0 100-3.8z';

/**
 * LumApps MicroApp Icon
 */
export const lumappsMicroApp =
    'M11.92 2v7.63h7.93V2zM2 19.16h7.93v-7.63H2zM2 2v7.63h7.93V2zm15.48 9.22a1.9 1.9 0 00-2.63 0l-2.98 2.86c-.73.7-.73 1.83 0 2.53l2.98 2.87c.72.7 1.9.7 2.63 0l2.98-2.87c.72-.7.72-1.83 0-2.53z';

/**
 * Image centered alignment
 */
export const imageAlignmentCenter =
    'M21 6a1 1 0 00-1-1H4A1 1 0 003 6a1 1 0 001 1h16a1 1 0 001-1M8 10a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1H9a1 1 0 01-1-1zm-5 8a1 1 0 011-1h16a1 1 0 011 1 1 1 0 01-1 1H4a1 1 0 01-1-1';

/**
 * Image full width alignment
 */
export const imageAlignmentFull =
    'M3 20a1 1 0 001 1h16a1 1 0 001-1 1 1 0 00-1-1H4a1 1 0 00-1 1m0-4a1 1 0 001 1h16a1 1 0 001-1V8a1 1 0 00-1-1H4A1 1 0 003 8ZM21 4a1 1 0 01-1 1H4A1 1 0 013 4a1 1 0 011-1h16a1 1 0 011 1';

/**
 * Image left alignment
 */
export const imageAlignmentLeft =
    'M21 6a1 1 0 00-1-1H4A1 1 0 003 6a1 1 0 001 1h16a1 1 0 001-1M3 10a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1H4a1 1 0 01-1-1zm0 8a1 1 0 011-1h16a1 1 0 011 1 1 1 0 01-1 1H4a1 1 0 01-1-1m18-4a1 1 0 00-1-1h-6a1 1 0 00-1 1 1 1 0 001 1h6a1 1 0 001-1m-8-4a1 1 0 011-1h6a1 1 0 011 1 1 1 0 01-1 1h-6a1 1 0 01-1-1';

/**
 * Image right alignment
 */
export const imageAlignmentRight =
    'M21 6a1 1 0 00-1-1H4A1 1 0 003 6a1 1 0 001 1h16a1 1 0 001-1m-8 4a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1h-6a1 1 0 01-1-1zM3 18a1 1 0 011-1h16a1 1 0 011 1 1 1 0 01-1 1H4a1 1 0 01-1-1m8-4a1 1 0 00-1-1H4a1 1 0 00-1 1 1 1 0 001 1h6a1 1 0 001-1m-8-4a1 1 0 011-1h6a1 1 0 011 1 1 1 0 01-1 1H4a1 1 0 01-1-1';

/**
 * Icon for Learning
 */
export const learningHatImage =
    'M11.91 16.6q-.72 0-1.35-.39l-6.4-4.07A2.48 2.48 0 014.05 8l6.41-4.54a2.55 2.55 0 012.89-.02l6.57 4.54a2.48 2.48 0 01-.11 4.18l-6.57 4.07q-.61.37-1.33.37m-.19-11.37-6.4 4.53a.3.3 0 00-.14.28c0 .07.02.19.15.28l6.4 4.07q.18.09.36 0l6.57-4.07a.3.3 0 00.16-.28.3.3 0 00-.14-.28l-6.57-4.53a.4.4 0 00-.39 0m.22 14.87q-.64 0-1.23-.32l-5.99-3.34c-.53-.3-.71-.96-.42-1.48.3-.52.96-.71 1.49-.41l5.99 3.34q.16.09.33 0l5.99-3.34a1.08 1.08 0 111.06 1.89l-5.99 3.34q-.58.32-1.23.32';

/**
 * Filled icon for Learning
 */
export const learningFilledImage =
    'm19.92 8-6.57-4.55c-.87-.6-2.03-.6-2.9.02l-6.4 4.56a2.5 2.5 0 00.1 4.16l6.4 4.1a2.5 2.5 0 002.7 0l6.56-4.08a2.5 2.5 0 00.1-4.2zm-7.98 12.2q-.64 0-1.23-.32L4.72 16.5a1.09 1.09 0 111.07-1.9l5.99 3.36q.16.09.33 0l5.99-3.36a1.09 1.09 0 111.07 1.9l-6 3.36q-.58.32-1.23.32';

/**
 * Code block
 */
export const codeBlock =
    'M5.59 3.41 7 4.82 3.82 8 7 11.18 5.59 12.6 1 8zm5.82 0L16 8l-4.59 4.6L10 11.18 13.18 8 10 4.82zM22 6v12a2 2 0 01-2 2H4a2 2 0 01-2-2v-4h2v4h16V6h-2.97V4H20a2 2 0 012 2';

/**
 * Google calendar official icon
 */
export const googleCalendar =
    'M14.63 16.11h1.03V9.68h-.72l-1.89 1.37.53.79 1.05-.76Zm-5.89-.27a2.5 2.5 0 002.48.16 1.8 1.8 0 001.07-1.7q0-.55-.3-.96t-.77-.61v-.05q.35-.18.6-.54t.25-.86-.27-.9-.72-.62-1.05-.23a2.1 2.1 0 00-1.99 1.35l.95.4q.11-.32.36-.55a1 1 0 01.67-.23 1 1 0 01.71.23q.29.22.29.62 0 .42-.32.68-.32.25-.77.25h-.5v.95h.55a1.4 1.4 0 01.9.28q.35.29.35.74a.9.9 0 01-.34.73q-.34.28-.8.28t-.79-.27-.46-.77l-.95.4q.23.8.85 1.22m8.79-8.57H6.47v11.05H1.5V3.95c0-.92.74-1.66 1.66-1.66h14.37ZV2.29h3.31c.92 0 1.66.74 1.66 1.66v3.32ZM6.47 18.32v4.97H3.16c-.92 0-1.66-.74-1.66-1.65v-3.32Zm11.06 4.97H6.47v-4.97h11.06Zm4.97-4.97h-4.97V7.27h4.97Zm-4.97 0h4.97l-4.97 4.97Z';

/**
 * !FIXME: DELETE WHEN lumx-icons is up to date
 */
export const mdiCheckDecagramOutline =
    'm23 12-2.4-2.8.3-3.7-3.6-.8-1.9-3.2L12 3 8.6 1.5 6.7 4.7l-3.6.8.3 3.7L1 12l2.4 2.8-.3 3.7 3.6.8 1.9 3.2L12 21l3.4 1.5 1.9-3.2 3.6-.8-.3-3.7zm-4.3 4.9-2.7.6-1.4 2.4-2.6-1.1-2.6 1.1L8 17.5l-2.7-.6.2-2.8L3.7 12l1.8-2.1-.2-2.8L8 6.5l1.4-2.4L12 5.2l2.6-1.1L16 6.5l2.7.6-.2 2.8 1.8 2.1-1.8 2.1zm-2.1-9.3L18 9l-8 8-4-4 1.4-1.4 2.6 2.6z';

/**
 * Icon with setting and small play
 */
export const settingPlay =
    'M13.5 22.3H10c-.2 0-.5-.2-.5-.4l-.4-2.6c-.6-.3-1.1-.6-1.7-1l-2.4 1c-.3.1-.5 0-.7-.2l-2-3.5c-.1-.2 0-.5.2-.6l2.1-1.7c-.1-.3-.1-.6-.1-1 0-.3 0-.6.1-1L2.5 9.7c-.2-.2-.3-.4-.2-.6l2-3.5c.2-.2.4-.3.7-.2l2.4 1c.6-.4 1.1-.8 1.7-1l.4-2.7c0-.2.3-.4.5-.4h4c.3 0 .5.2.5.4l.4 2.7c.6.2 1.1.6 1.7 1l2.5-1c.2-.1.4 0 .6.2l2 3.5c.1.2 0 .4-.2.6l-2.1 1.6c.1.4.1.7.1 1v.2a5.8 5.8 0 00-4.1.4c.1-.2.1-.4.1-.6 0-1.9-1.6-3.5-3.5-3.5s-3.5 1.6-3.5 3.5c0 2 1.6 3.5 3.5 3.5h.6c-.4.7-.6 1.6-.6 2.5 0 1.6.6 3 1.5 4m2.5-7v6l5-3z';
