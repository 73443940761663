import get from 'lodash/get';
import { hasProtocol, isUrl } from '@lumapps/utils/string/isUrl';
import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { sendTrackingDirectoryEntryClickActionEvent } from '@lumapps/directories/api/analytics';

// ///////////////////////////

(function IIFE() {
    // ///////////////////////////

    function DirectoryEntryService(
        $state,
        $window,
        Analytics,
        Content,
        Directory,
        DirectoryEntryFactory,
        LumsitesBaseService,
        LxDialogService,
        Metadata,
        Translation,
        User,
        UserFavorite,
        UserFeedSubscriptions,
        Utils,
    ) {
        'ngInject';

        const service = LumsitesBaseService.createLumsitesBaseService(DirectoryEntryFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            /* eslint-disable no-use-before-define */
            postGet: _formatServerObjectToClient,
            postList: _formatServerObjectListToClientList,
            preSave: _preSave,
            /* eslint-disable no-use-before-define */
        });

        // ///////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        // ///////////////////////////

        /**
         * Format the server object to send the correct item to the client.
         *
         * @param  {Object} directoryEntry A directory entry to be formatted for the server.
         * @return {Object} The formatted directory entry.
         */
        function _formatServerObjectToClient(directoryEntry) {
            if (angular.isUndefinedOrEmpty(directoryEntry.metadata)) {
                directoryEntry.metadata = {};

                return service.updateEntryStatus(directoryEntry);
            }

            const allMetadata = {};

            angular.forEach(Metadata.getRefactoredMetadata(true), function forEachMetadata(metadata) {
                allMetadata[metadata.key] = [];

                const filteredMetadataItems = _.filter(metadata.items, function filterMetadataItems(item) {
                    return directoryEntry.metadata.indexOf(item.key) !== -1;
                });

                allMetadata[metadata.key] = _.map(filteredMetadataItems, 'key');
            });

            directoryEntry.metadata = allMetadata;

            return service.updateEntryStatus(directoryEntry);
        }

        /**
         * Format the server object to send the correct item to the client.
         *
         * @param  {Object} directoryEntries A list of directory entries to be formatted for the client.
         * @return {Array}  A list of formatted directory entries.
         */
        function _formatServerObjectListToClientList(directoryEntries) {
            if (angular.isUndefinedOrEmpty(directoryEntries)) {
                return [];
            }

            return _.map(_.compact(directoryEntries), function forEachServerListItem(item) {
                return service.updateEntryStatus(item);
            });
        }

        /**
         * Cleanup the payload before sending it to the server for a save.
         *
         * @param  {Object} directoryEntry The directory entry object to send to the backend.
         * @return {Object} The cleaned up directory entry.
         */
        function _preSave(directoryEntry) {
            return service.formatClientObjectForServer(angular.fastCopy(directoryEntry));
        }

        // ///////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        // ///////////////////////////

        /**
         * Add a local directory entry for the current user.
         */
        function addLocalEntry() {
            Analytics.handleTaggingMap('add-directory-entry', 'entry', {
                isHomePage: _.get(Content.getCurrent(), 'isHomepage', false),
            });

            service.setCurrent({
                directory: Directory.getCurrent().id,
                metadata: {},
                values: {},
            });

            LxDialogService.open('directory-entry-settings');
        }

        /**
         * Format the client object before sending it to the server.
         *
         * @param  {Object} directoryEntry A directory entry to format.
         * @return {Object} The formatted directory entry.
         */
        function formatClientObjectForServer(directoryEntry) {
            if (angular.isUndefinedOrEmpty(directoryEntry.metadata)) {
                return angular.extend(directoryEntry, {
                    metadata: [],
                });
            }

            let allMetadata = [];
            const nonEmptyMetadata = _.filter(directoryEntry.metadata, angular.isDefinedAndFilled);

            angular.forEach(nonEmptyMetadata, function forEachMetadata(metadata) {
                if (angular.isString(metadata)) {
                    allMetadata.push(metadata);
                } else if (angular.isArray(metadata)) {
                    allMetadata = allMetadata.concat(metadata);
                }
            });

            return angular.extend(directoryEntry, {
                metadata: allMetadata,
            });
        }

        /**
         * Get the entry value with the type wanted.
         *
         * @param  {Object} directory      The directory the entry belongs to.
         * @param  {Object} directoryEntry The directory entry to get the value of.
         * @param  {string} type           The type of component to get the value of.
         * @return {Object} The value of a component of a directory entry.
         */
        function getEntryValueByType(directory, directoryEntry, type) {
            if (angular.isUndefined([_.get(directory, 'template.components'), directoryEntry.values], 'some')) {
                return undefined;
            }

            const matchingComponent = _.find(directory.template.components, {
                type,
            });

            if (angular.isUndefinedOrEmpty(matchingComponent)) {
                return undefined;
            }

            return directoryEntry.values[matchingComponent.uuid];
        }

        /**
         * Get link and action to do for a given directory and directoryEntry.
         *
         * @param  {Object} directory      The directory the entry belongs to.
         * @param  {Object} directoryEntry The directory entry to get the value of.
         * @return {Object} Object with HREF (link) for accessibility in html, URL (link or i18n) and Action associated (reload, open, ...).
         */
        function getLinkAndActionforOpeningEntry(directory, directoryEntry) {
            // If link has no translation
            // Or is a URL that is not sanitized
            // Do not create a URL that is not safe
            if (
                !Translation.hasTranslations(directoryEntry.link) ||
                (isUrl(Translation.translate(directoryEntry.link)) &&
                    !sanitizeUrl(Translation.translate(directoryEntry.link)))
            ) {
                return null;
            }

            const translatedLink = Translation.translate(directoryEntry.link);
            const content = Content.getCurrent();

            if (angular.isDefined(content)) {
                // Reload the state if a directory entry links to the current page.
                const contentSlug = Translation.translate(content.slug);
                if (contentSlug === translatedLink) {
                    return { action: 'reload', href: undefined, url: undefined };
                }
            }

            if (isUrl(translatedLink)) {
                const prefix = hasProtocol(translatedLink) ? '' : 'http://';
                const urlToOpen = Utils.parseUrlPattern(prefix + translatedLink);
                return { action: 'open', href: urlToOpen, url: urlToOpen };
            }

            // Looks like a slug
            return {
                action: 'go',
                href: translatedLink,
                params: {
                    slug: translatedLink,
                },
                url: 'app.front.content-get',
            };
        }

        /**
         * Return link which could be displayed.
         * @param  {Object} directory      The directory the entry belongs to.
         * @param  {Object} directoryEntry A directory entry to open.
         * @return {Object} Object with `url` and `action` to do with this one.
         */
        function getLinkEntry(directory, directoryEntry) {
            return get(getLinkAndActionforOpeningEntry(directory, directoryEntry), 'href');
        }

        /**
         * Handle the click on a directory entry.
         *
         * @param {Object} directory      The directory the entry belongs to.
         * @param {Object} directoryEntry A directory entry to open.
         * @param {Object} eventLink      Event to prevent if click is on link.
         */
        function openEntry(directory, directoryEntry, eventLink) {
            const content = Content.getCurrent();
            Analytics.handleTaggingMap('open-directory-entry', 'directory-entry', {
                directory,
                isFavorite: UserFavorite.isFavorite(directoryEntry),
                isHomePage: angular.isDefined(content) ? content.isHomepage : false,
                item: directoryEntry,
            });

            if (eventLink) {
                eventLink.preventDefault();
            }
            const link = getLinkAndActionforOpeningEntry(directory, directoryEntry);

            if (link && link.action) {
                sendTrackingDirectoryEntryClickActionEvent({
                    targetId: directoryEntry.id,
                    url: link.href,
                    title: Translation.translate(directoryEntry.name),
                    thumbnail: directoryEntry.thumbnail,
                });

                if (link.action === 'open') {
                    $window.open(link.url, '_blank', 'noopener,noreferrer');
                } else if (link.action === 'go') {
                    $state.go(link.url, link.params);
                } else if (link.action === 'reload') {
                    $state.reload();
                }
            }
        }

        /**
         * Update the status of a given directory entry.
         *
         * @param  {Object} directoryEntry A directory entry to update.
         * @return {Object} The updated directory entry.
         */
        function updateEntryStatus(directoryEntry) {
            if (angular.isUndefined(directoryEntry) || !User.isConnected()) {
                return directoryEntry;
            }

            directoryEntry.isTogglable = true;
            directoryEntry.isUserFavorite = UserFavorite.isFavorite(directoryEntry);

            // Check if the directory has favorites.
            const directory = Directory.getById(directoryEntry.directory);
            if (angular.isDefinedAndFilled(directory) && !directory.favorites) {
                directoryEntry.isTogglable = false;
            }

            if (angular.isUndefinedOrEmpty(directoryEntry.isInFavoriteFeedKeys)) {
                return directoryEntry;
            }

            angular.forEach(directoryEntry.isInFavoriteFeedKeys, function forEachFavoriteFeedKey(feedKey) {
                if (UserFeedSubscriptions.hasSubscription(feedKey)) {
                    directoryEntry.isTogglable = directoryEntry.deletableFromFavorite;
                    directoryEntry.isMandatoryFavorite = true;
                } else {
                    directoryEntry.isMandatoryFavorite = false;
                }
            });

            return directoryEntry;
        }

        // ///////////////////////////

        service.addLocalEntry = addLocalEntry;
        service.formatClientObjectForServer = formatClientObjectForServer;
        service.formatServerObjectToClient = _formatServerObjectToClient;
        service.getEntryValueByType = getEntryValueByType;
        service.openEntry = openEntry;
        service.getLinkEntry = getLinkEntry;
        service.updateEntryStatus = updateEntryStatus;

        // ///////////////////////////

        return service;
    }

    // ///////////////////////////

    angular.module('Services').service('DirectoryEntry', DirectoryEntryService);
})();
