import get from 'lodash/get';

/////////////////////////////

function WidgetRssController() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /**
     * Initialize controller.
     */
    vm.init = () => {
        vm.widget.properties.maxItems = get(vm.widget, 'properties.maxItems', 5);
    };

    /**
     * Set the parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    vm.setParentController = (parentCtrl) => {
        vm.parentCtrl = parentCtrl;

        vm.init();
    };
}

/////////////////////////////

// eslint-disable-next-line require-jsdoc
function WidgetRssDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc
    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetRssController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetRss', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-rss/views/widget-rss.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetRss', WidgetRssDirective);

/////////////////////////////

export { WidgetRssDirective };
