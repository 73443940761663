import { ReactNode } from 'react';

/**
 * Look for strings wrapped between two strings and replace their wrapper elements by a react node.
 *
 * Returns an array of all elements.
 *
 * ex:
 *
 * replaceWrapperWithComponent(
 *      'translation with <a>element</a>',
 *      { start: '<a>', end: '</a>' },
 *      (value) => <Link href="#">{value}</Link>)
 * )
 *
 * will return
 *
 * ['translation with', <Link href="#">element</Link>]
 */
export const wrapStringGroupWithComponent = (
    /** The translation in which to replace a group of string */
    translation: string,
    /** The group delimiters to look for */
    groupDelimiters: {
        /** String that matches the start of a group */
        start: string;
        /** String that matches the end of a group */
        end: string;
    },
    /**
     * Callback to set the element to replace the group.
     * If it returns a react element, you will need to add a key prop to avoid errors.
     */
    replacement: (value: string, index: number) => ReactNode,
) => {
    const { start, end } = groupDelimiters;
    const wrapperRegex = new RegExp(`${start}(.*?)${end}`);
    /** Check if the translation has the string to replace. */
    const regexMatch = translation.match(wrapperRegex);
    if (!regexMatch || !start || !end) {
        return [translation];
    }

    /** Split the translation to isolate the string that should be wrapped with the link */
    const splitText = translation.split(wrapperRegex);
    const cleanSplitText = splitText.filter(Boolean);

    return cleanSplitText.map((s, index) => {
        if (s === regexMatch[1]) {
            return replacement(s, index);
        }

        return s;
    });
};
