/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-magic-numbers */
import {
    getActiveAmbassadors,
    getNewSocialPost,
    getTotalShares,
    getInteractionRate,
    getTotalLikes,
    getTotalComments,
    getTopTopicsByMetric,
    getTopPostsByMetric,
    getTopAmbassadorsByMetric,
} from 'components/components/social-advocacy/api';

import { limitFractionDigits } from 'components/utils/common_utils';

import { buildFilters } from './utils';

const fetchActiveAmbassadors = (filter) =>
    new Promise((resolve, reject) => {
        getActiveAmbassadors(buildFilters(filter))
            .then((response) => {
                if (response.status === 200) {
                    const { metrics } = response.data;
                    const value = metrics.sharer_rate * 100;
                    const infoDetails = [[metrics.sharer_count, metrics.user_count]];
                    resolve({ infoDetails, value });
                } else {
                    reject(response);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchNewSocialPosts = (filter) =>
    new Promise((resolve, reject) => {
        getNewSocialPost(buildFilters(filter))
            .then((response) => {
                if (response.status === 200) {
                    const { metrics } = response.data;
                    const value = metrics.shareable_content_created_count;
                    const infoDetails = [];
                    resolve({ infoDetails, value });
                } else {
                    reject(response);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchTotalShares = (filter) =>
    new Promise((resolve, reject) => {
        getTotalShares(buildFilters(filter))
            .then((response) => {
                if (response.status === 200) {
                    const { metrics } = response.data;
                    const value = metrics.share_created_count;
                    const infoDetails = [
                        [metrics.share_created_count],
                        [limitFractionDigits(metrics.share_created_count_by_creator, 2)],
                    ];
                    resolve({ infoDetails, value });
                } else {
                    reject(response);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchInteractionRate = (filter) =>
    new Promise((resolve, reject) => {
        getInteractionRate(buildFilters(filter))
            .then((response) => {
                if (response.status === 200) {
                    const { metrics } = response.data;
                    const value = metrics.share_interaction_rate * 100;
                    const infoDetails = [[metrics.share_interaction_count]];
                    resolve({ infoDetails, value });
                } else {
                    reject(response);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchTotalLikes = (filter) =>
    new Promise((resolve, reject) => {
        getTotalLikes(buildFilters(filter))
            .then((response) => {
                if (response.status === 200) {
                    const { metrics } = response.data;
                    const value = metrics.share_like_count;
                    const infoDetails = [
                        [limitFractionDigits(metrics.share_like_count_by_shareable_content, 2)],
                        [limitFractionDigits(metrics.share_like_count_by_creator, 2)],
                    ];
                    resolve({ infoDetails, value });
                } else {
                    reject(response);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchTotalComments = (filter) =>
    new Promise((resolve, reject) => {
        getTotalComments(buildFilters(filter))
            .then((response) => {
                if (response.status === 200) {
                    const { metrics } = response.data;
                    const value = metrics.share_comment_count;
                    const infoDetails = [
                        [limitFractionDigits(metrics.share_comment_count_by_shareable_content, 2)],
                        [limitFractionDigits(metrics.share_comment_count_by_creator, 2)],
                    ];
                    resolve({ infoDetails, value });
                } else {
                    reject(response);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchTopTopics = (filter, metric, multiplicator = 1, limit = 3) => {
    return new Promise((resolve, reject) => {
        getTopTopicsByMetric(buildFilters(filter), metric, limit)
            .then((response) => {
                const { items } = response.data;
                if (response.status !== 200) {
                    reject(response);
                } else if (items) {
                    const entries = items.map((el) => {
                        const { topic } = el.attributes;

                        return {
                            id: topic.id,
                            metric: el.metrics[metric] * multiplicator,
                            name: topic.name,
                            status: topic.status,
                        };
                    });
                    resolve(entries);
                } else {
                    reject(`TOP TOPICS -- NO ITEMS FOR ${metric}`);
                }
            })
            .catch((exception) => reject(exception));
    });
};

const fetchTopPosts = (filter, metric, multiplicator = 1) =>
    new Promise((resolve, reject) => {
        getTopPostsByMetric(buildFilters(filter), metric)
            .then((response) => {
                const { items } = response.data;
                if (response.status !== 200) {
                    reject(response);
                } else if (items) {
                    const entries = items.map((el) => {
                        return { ...el.attributes.shareable_content, metric: el.metrics[metric] * multiplicator };
                    });
                    resolve(entries);
                } else {
                    reject(`TOP POSTS -- NO ITEMS FOR ${metric}`);
                }
            })
            .catch((exception) => reject(exception));
    });

const fetchTopAmbassadors = (filter, metric, multiplicator = 1) =>
    new Promise((resolve, reject) => {
        getTopAmbassadorsByMetric(buildFilters(filter), metric)
            .then((response) => {
                const { items } = response.data;
                if (response.status !== 200) {
                    reject(response);
                } else if (items) {
                    const entries = items.map((el) => {
                        return { ...el.attributes.sharer, metric: el.metrics[metric] * multiplicator };
                    });
                    resolve(entries);
                } else {
                    reject(`TOP AMBASSADORS -- NO ITEMS FOR ${metric}`);
                }
            })
            .catch((exception) => reject(exception));
    });

export {
    fetchActiveAmbassadors,
    fetchInteractionRate,
    fetchNewSocialPosts,
    fetchTopAmbassadors,
    fetchTopPosts,
    fetchTopTopics,
    fetchTotalComments,
    fetchTotalLikes,
    fetchTotalShares,
};
