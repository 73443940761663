import React, { PureComponent } from 'react';
import { bool, func, string } from 'prop-types';

import uniqueId from 'lodash/uniqueId';

import { DocumentUpload } from '../../../services/DocumentUpload';

/**
 * Button that display system file picker to upload a file.
 */
class DocumentUploader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { id: uniqueId('document_upload_form_') };
        this.selectFiles = this.selectFiles.bind(this);
        this.upload = this.upload.bind(this);
    }

    componentDidMount() {
        const { onRef } = this.props;

        onRef(this);
    }

    componentWillUnmount() {
        const { onRef } = this.props;

        onRef(undefined);
    }

    /**
     * Start an upload.
     * Fetch upload config from API then start uploading.
     *
     * @async
     * @return {Promise} Promise resolved when all upload have been completed.
     */
    async upload() {
        const { files } = this.getInputElement();
        const { onChange, onStatusChange, parentPath } = this.props;
        const getUploadSettings = { parentPath };
        const uploader = new DocumentUpload({ files, getUploadSettings });

        this.clearFilesInput();

        onStatusChange({ status: 'PENDING' });
        const promise = await uploader.upload();
        onStatusChange({ status: 'DONE' });
        onChange();

        return promise;
    }

    /**
     * Remove files from input element.
     */
    clearFilesInput() {
        const input = this.getInputElement();

        input.value = '';
    }

    /**
     * Returns input element.
     *
     * @return {HTMLElement} The input element.
     */
    getInputElement() {
        const { id } = this.state;

        return document.getElementById(id);
    }

    /**
     * Generate a click on file input, to open system dialog.
     */
    selectFiles() {
        this.getInputElement().click();
    }

    render() {
        const { id } = this.state;
        const { multiple } = this.props;

        return (
            <form className="document-uploader__form" encType="multipart/form-data" method="post">
                <input id={id} multiple={multiple} name="files[]" type="file" onChange={this.upload} />
            </form>
        );
    }
}

/////////////////////////////

DocumentUploader.propTypes = {
    /** Can upload multiple docs. */
    // eslint-disable-next-line react/boolean-prop-naming
    multiple: bool,
    /** Called when the files have been uploaded. */
    onChange: func.isRequired,
    /** Ref assignation callback. */
    onRef: func.isRequired,
    /** Called when upload status change. */
    onStatusChange: func.isRequired,
    /** Parent folder path for creation. */
    parentPath: string.isRequired,
};

DocumentUploader.defaultProps = {
    multiple: false,
};

/////////////////////////////

export { DocumentUploader };
