/* eslint-disable no-param-reassign */
import uniq from 'lodash/uniq';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import createSlice, { PayloadAction } from '../createSlice';
import { ResultPage, ListState } from './types';

export const initialState: ListState = { ids: [], status: BaseLoadingStatus.loading, items: [] };

/**
 * Generic list slice handling state for a list:
 * - List of identifiers (entities should be stored in their own store)
 * - List cursor & more flag
 * - List status (none, loading, loading more)
 */
export const createListSlice = (options: { domain: string }) =>
    createSlice({
        initialState,
        domain: options.domain,
        reducers: {
            reset: () => initialState,
            updateResult(state: ListState, action: PayloadAction<ResultPage & { params: any; append?: boolean }>) {
                const { ids, append, cursor, hasMore, params, items } = action.payload;
                if (append) {
                    // Append page.
                    state.ids = uniq([...state.ids, ...ids]);

                    if (items) {
                        state.items = [...(state.items || []), ...items];
                    }
                } else {
                    // Replace page.
                    state.ids = ids;
                    state.items = items;
                }
                state.params = params;
                state.hasMore = hasMore;
                state.cursor = cursor;
                delete state.status;
            },
            setStatus(state: ListState, action: PayloadAction<ListState['status']>) {
                state.status = action.payload;
            },
        },
    });
