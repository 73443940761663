import React from 'react';

import { TemplateMenu } from '@lumapps/content-templates/components/TemplateMenu';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { CustomContentType } from '../../../types';

export interface ContentItemProps {
    /** The content type */
    contentType: CustomContentType;
    /** True if the default template option should not be displayed */
    defaultTemplateEnabled: boolean;
}

const SCOPE = 'contribution-content';

/**
 * Sub-menu of all available template to create content from.
 */
export const ContentTypeSubmenu: React.FC<ContentItemProps> = (props) => {
    const {
        contentType: { id, name, icon },
        defaultTemplateEnabled,
    } = props;
    const { get } = useDataAttributes(SCOPE);
    const { iconPath } = useDynamicIcon({ icon });
    const { translateObject } = useTranslate();

    return (
        <Menu.ItemSubmenu
            label={translateObject(standardizeTranslateObject(name))}
            icon={iconPath}
            // TODO: DSW-180 improve menu min width defaults & customization
            triggerProps={{ popoverWidth: { minWidth: REAL_SIZE_FOR_LUMX_SIZE.xxl } }}
            {...get({ element: id })}
        >
            <TemplateMenu contentTypeId={id} defaultTemplateEnabled={defaultTemplateEnabled} scope={SCOPE} />
        </Menu.ItemSubmenu>
    );
};
