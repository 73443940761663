/* eslint-disable no-param-reassign */
import { ReducerAction } from 'react';

import uniqueId from 'lodash/uniqueId';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { RegisteredComboboxOption } from '../types';
import { isComboboxAction, isComboboxValue } from '../utils';

export type ComboboxState = {
    /** Id of the combobox */
    comboboxId: string;
    /** Id of the listbox */
    listboxId: string;
    /** Current status of the combobox */
    status: BaseLoadingStatus;
    /**
     * Registered options dictionary.
     * This is useful to know how many options there are.
     */
    options: Record<string, RegisteredComboboxOption>;
    /** Whether the combobox is currently opened */
    isOpen: boolean;
    /** Whether all options should be displayed regardless of current value. */
    showAll: boolean;
    /** The current input value. */
    inputValue: string;
    /** The current option length */
    optionsLength: number;
    /**
     * Whether the combobox options should be managed as a listbox or a grid.
     * The options becomes a grid as soon as a secondary action is registered.
     */
    type: 'listbox' | 'grid';
    /** The default input value. */
    defaultInputValue?: string;
};

const comboboxId = `combobox-${uniqueId()}`;
export const initialState: ComboboxState = {
    comboboxId,
    listboxId: `${comboboxId}-popover`,
    status: BaseLoadingStatus.idle,
    isOpen: false,
    inputValue: '',
    showAll: true,
    options: {},
    type: 'listbox',
    optionsLength: 0,
};

export type ComboboxOpenActionPayload = { manual?: boolean } | undefined;

/** Reducer for the combobox component. */
export const reducers = {
    /** Actions when the combobox opens. */
    open: (state: ComboboxState, action: PayloadAction<ComboboxOpenActionPayload>) => {
        const { manual } = action.payload || {};
        // If the combobox was manually opened, show all suggestions
        state.showAll = Boolean(manual);
        state.isOpen = true;
    },
    /** Actions when the combobox closes */
    close: (state: ComboboxState) => {
        state.showAll = true;
        state.isOpen = false;
    },
    /** Actions on input update. */
    setInputValue: (state: ComboboxState, action: PayloadAction<string>) => {
        state.inputValue = action.payload;
        // When the user is changing the value, show only values that are related to the input value.
        state.showAll = false;
        if (!state.isOpen) {
            state.isOpen = true;
        }
    },
    /** Register an option to the state */
    addOption: (state: ComboboxState, action: PayloadAction<{ id: string; option: RegisteredComboboxOption }>) => {
        const { id, option } = action.payload;
        const { options } = state;

        if (!options[id]) {
            state.options[id] = option;

            if (isComboboxAction(option)) {
                state.type = 'grid';
            }
            if (isComboboxValue(option)) {
                state.optionsLength += 1;
            }
        }
    },
    /** Remove an option to the state */
    removeOption: (state: ComboboxState, action: PayloadAction<{ id: string }>) => {
        const { id } = action.payload;
        const { options } = state;
        const option = options[id];

        if (options[id]) {
            delete state.options[id];
            if (isComboboxValue(option)) {
                state.optionsLength -= 1;
            }
        }
    },
};

/** Slice for the Combobox component */
export const { actions, reducer } = createSlice({
    domain: 'combobox',
    initialState,
    reducers,
});

/** Available actions of the combobox component */
export type ComboboxActions = ReducerAction<typeof reducer>;
/** Dispatch for the combobox component */
export type ComboboxDispatch = React.Dispatch<ComboboxActions>;
