export interface ListInstanceParams {
    maxResults?: number;
    sortOrder?: string;
    userFavoritesOnly?: boolean;
    fields?: string;
    ids?: string[];
}

export interface GetInstanceParams {
    uid?: string;
    fields?: string;
    lang?: string;
}

export interface ListInstanceSiblingsParams {
    instanceId: string;
    fields?: string;
}

export interface SearchInstancesParams {
    maxResults: number;
    sortOrder: string;
    userFavoritesOnly: boolean;
    fields: string;
    cursor?: string;
    ids?: string[];
    emptyParent?: boolean;
    metadata?: string[];
}

export const SearchInstanceDefaultParams: SearchInstancesParams = {
    maxResults: 10,
    sortOrder: 'name',
    userFavoritesOnly: true,
    fields: 'items(id,name,properties,slug,title),more,callId,cursor',
};
