import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { MenuItemProps } from '@lumapps/lumx-menu/components/Menu/MenuItem';
import { mdiBookmark, mdiBookmarkOutline } from '@lumapps/lumx/icons';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useSaveButton, UseSaveButtonParams } from '../../hooks/useSaveButton';

/**
 * Provide a resource Id & type and eventually additionnal menu item props.
 */
export interface MenuItemSaveButtonProps
    extends Omit<MenuItemProps, 'children'>,
        Pick<UseSaveButtonParams, 'resourceId' | 'resourceType'> {}

/**
 * Save button to display in a menu.
 */
export const MenuItemSaveButton: React.FC<MenuItemSaveButtonProps> = ({ resourceId, resourceType, ...rest }) => {
    const { translateKey } = useTranslate();
    const {
        status = BaseLoadingStatus.idle,
        isSaved,
        isDisabled,
        onClick,
    } = useSaveButton({
        resourceId,
        resourceType,
        fetchOnMount: true,
    });

    if (status === BaseLoadingStatus.loading) {
        return <Menu.ItemSkeleton loadingMessage={translateKey(GLOBAL.LOADING)} hasIcon />;
    }

    if (status === BaseLoadingStatus.error) {
        return null;
    }

    return (
        <Menu.Item
            icon={isSaved ? mdiBookmark : mdiBookmarkOutline}
            isDisabled={isDisabled}
            {...rest}
            onClick={onClick}
        >
            {translateKey(isSaved ? GLOBAL.SAVED : GLOBAL.SAVE)}
        </Menu.Item>
    );
};
