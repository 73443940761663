import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../../types';
import * as tabTypes from '../../../ui/tabs/types';
import * as styleTypes from '../../types';
import { translate as t } from '../../../../translations';
import { Style as StyleService } from '../../../../services/styles';
import { withOnChangeProperty } from '../../../../utils';
import { ColorPalette, Tab, Tabs } from '../../../ui';
import { BackgroundImage } from './BackgroundImage';
import { ShadowSettings } from '../ShadowSettings';
import { ThemeSettings } from '../ThemeSettings';

/**
 * Renders a form that enables editing background/shadow/theme settings.
 */
export class BackgroundSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.backgroundStyle.isRequired,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Called when the current state style changes with `(state)`. */
        onChangeState: propTypes.func,
        /**  */
        propName: propTypes.string,
        /** Whether to enable shadow edition or not. */
        shadow: propTypes.bool,
        /** Current state style. */
        state: propTypes.string,
        /** Style states. */
        states: tabTypes.items,
        /** Whether to enable theme edition or not. */
        theme: propTypes.bool,
        /** Current background style. */
        value: styleTypes.backgroundStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const {
            value = {},
            name: key,
            onChange,
            shadow = false,
            theme = false,
            state,
            states,
            onChangeState,
            defaults,
            propName = 'backgroundColor',
        } = this.props;
        const style = StyleService.getCurrent('global').properties;

        return (
            <Tabs state={state} states={states} onChangeState={onChangeState}>
                <Tab id="color" label={t('STYLE.COLOR')}>
                    <ColorPalette
                        value={value.backgroundColor || defaults[propName]}
                        name="backgroundColor"
                        onChange={this.onChangeProperty}
                        hasClearChoice
                        choices={style.colors}
                        primary={style.primary}
                        accent={style.accent}
                    />
                </Tab>
                <Tab id="image" label={t('STYLE.IMAGE')}>
                    <BackgroundImage value={value} name={key} onChange={onChange} defaults={defaults}/>
                </Tab>
                {shadow && (
                    <Tab id="shadow" label={t('STYLE.SHADOW')}>
                        <ShadowSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                    </Tab>
                )}
                {theme && (
                    <Tab id="theme" label={t('STYLE.THEME')}>
                        <ThemeSettings value={value.theme} name="theme" onChange={this.onChangeProperty} />
                    </Tab>
                )}
            </Tabs>
        );
    }
}
