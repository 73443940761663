import { useCallback, useMemo, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';

import { getCurrentContentTitle } from '@lumapps/contents/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { ContainerType } from '@lumapps/medias/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { STRUCTURED_CONTENT_FORMAT } from '@lumapps/structured-content/types';
import { uploadFile } from '@lumapps/structured-content/utils/uploadFile';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { inputLocaleSelector } from '@lumapps/translations/selectors';
import { USE_RICH_TEXT_IN_CONTRIBUTION_TOKEN } from '@lumapps/wrex/constants';
import { document } from '@lumapps/wrex/document/structured-content';
import { fromDITA } from '@lumapps/wrex/serialization/dita/fromDITA/fromDITA';
import { toDITA } from '@lumapps/wrex/serialization/dita/toDITA/toDITA';
import { getFirstContentAsString } from '@lumapps/wrex/slate/utils/getFirstContentAsString';
import { createEmptyContent } from '@lumapps/wrex/utils/createEmptyContent';
import { normalizeForSlate } from '@lumapps/wrex/utils/normalizeForSlate';

import type { WidgetContributionWrapperProps } from '../components/WidgetContributionWrapper';

export const useEditableWidgetContribution = ({
    legacyWidget,
    switchToReadMode,
    saveTextualContentInWidgetProperties,
}: Pick<
    WidgetContributionWrapperProps,
    'legacyWidget' | 'switchToReadMode' | 'saveTextualContentInWidgetProperties'
>) => {
    const wrapperRef = useRef(null);
    const childrenRefs = useRef([wrapperRef]);
    const site = useSelector(getCurrentInstance);
    const siteId = site.id || site.uid;
    const contentTitle = useSelector(getCurrentContentTitle);
    const currentInputLang = useSelector(inputLocaleSelector);
    const shouldUseRichText = useSelector(isFeatureEnabled(USE_RICH_TEXT_IN_CONTRIBUTION_TOKEN));
    const widgetProperties = legacyWidget?.properties;

    const dispatch = useDispatch();
    const { translateObject } = useTranslate();
    const emptySlateContent = createEmptyContent();

    /** we use slate format to easily switch from read to edit without having to parse every time */
    const initialContent = useMemo(() => {
        // Convert the provided richtext to slate and return it
        if (shouldUseRichText && widgetProperties?.richText && !isEmpty(widgetProperties?.richText)) {
            return normalizeForSlate(widgetProperties.richText[currentInputLang]?.children);
        }

        // TODO: REMOVE THIS AT THE END OF KILL DITA
        if (widgetProperties?.dita && !isEmpty(widgetProperties?.dita)) {
            /** If a dita is provided, we transform it to slate */
            const { content: slateContent } = fromDITA(widgetProperties.dita[currentInputLang]);
            return slateContent;
        }
        /** if no dita / richText provided, we create an empty slate content */
        return emptySlateContent;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** on click outside the editor, we save the slate content in the widget properties and switch to read mode */
    const exitEditionMode = useCallback(
        (content) => {
            if (shouldUseRichText) {
                const newRichText = document.parse({
                    children: content,
                    title: translateObject(standardizeTranslateObject(contentTitle)) || '',
                    description: getFirstContentAsString(content),
                });

                saveTextualContentInWidgetProperties(
                    {
                        ...widgetProperties?.richText,
                        [currentInputLang]: newRichText,
                    },
                    STRUCTURED_CONTENT_FORMAT.RICH_TEXT,
                );
            } else {
                const newDita = toDITA({
                    content,
                    title: translateObject(standardizeTranslateObject(contentTitle)) || '',
                });

                /** saved as translatable object to manage multilang */
                saveTextualContentInWidgetProperties({
                    ...widgetProperties?.dita,
                    [currentInputLang]: newDita,
                });
            }

            switchToReadMode();
        },
        [
            contentTitle,
            currentInputLang,
            widgetProperties,
            saveTextualContentInWidgetProperties,
            shouldUseRichText,
            switchToReadMode,
            translateObject,
        ],
    );

    const onFileUpload = useCallback(
        (files: File[], errorMessage?: string) => {
            return uploadFile(siteId, ContainerType.SITE, files, errorMessage)(dispatch);
        },
        [siteId, dispatch],
    );

    return {
        wrapperRef,
        exitEditionMode,
        childrenRefs,
        onFileUpload,
        initialContent,
    };
};
