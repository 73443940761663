import { Editor, NodeEntry, Path, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { getSibling } from '@lumapps/wrex/slate/utils/getSibling';
import { normalizeLastBlock } from '@lumapps/wrex/slate/utils/normalizeLastBlock';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';
import type { Wrex } from '@lumapps/wrex/types';

import type { TableEditor, TableElement } from '../../types';
import { createTD } from '../../utils/createTD';
import { createTR } from '../../utils/createTR';
import { isTable } from '../../utils/isTable';
import { isTableCell } from '../../utils/isTableCell';
import { isTableRow } from '../../utils/isTableRow';

export const normalizeTable = ([node, path]: NodeEntry, editor: ReactEditor & TableEditor) => {
    if (!isTable(node)) {
        return false;
    }
    const tablePathRef = Editor.pathRef(editor, path);

    const nodeAsTable = node as TableElement;
    if (tablePathRef.current) {
        // If table is not at first level, unwrap it
        normalizeLiftToRootNode(editor, [node, tablePathRef.current], isTable, { mode: 'after' });
    }
    if (tablePathRef.current) {
        // Insert a paragraph element after table if there is not already one.
        normalizeLastBlock(editor as ReactEditor, [nodeAsTable, tablePathRef.current], isTable);
    }

    if (tablePathRef.current && nodeAsTable.children.length === 0) {
        // if table is empty, insert empty row
        Transforms.insertNodes(editor, createTR(), {
            at: [...tablePathRef.current, 0],
        });
    } else if (tablePathRef.current) {
        Editor.withoutNormalizing(editor, () => {
            nodeAsTable.children
                .map((child, i) => [child, Editor.pathRef(editor, [...(tablePathRef.current as Path), i])] as const)
                .forEach(([child, pathRef]) => {
                    if (pathRef.current && !isTableRow(child)) {
                        // Wrap every direct child in a tr
                        const childPath = pathRef.current as Path;
                        const [previousSibling, previousSiblingPath] = getSibling(editor, childPath, 'before') || [];
                        if (previousSiblingPath && previousSibling && isTableRow(previousSibling)) {
                            // If previous sibling is a tr, move the current node in this sibling tr
                            Transforms.moveNodes(editor, {
                                at: childPath,
                                to: [...previousSiblingPath, previousSibling.children.length],
                            });
                        } else {
                            // If previous sibling is not a tr, then wrap the current node into a tr
                            Transforms.wrapNodes(editor, createTR([{ text: '' }]), { at: childPath });
                        }
                        pathRef.unref();
                    }
                });
        });
    }

    // Check if the table structure is OK (table > tr*x > td*x)
    const isTableWellFormatted = nodeAsTable.children?.every((row) => {
        const rowIsWellFormatted = (row as Wrex.Element).children?.every((rowChild) => isTableCell(rowChild));
        return rowIsWellFormatted;
    });

    // The number of cells should be equal in every row of a table.
    // We run this normalization only when the table strucutre is OK, to simplify the treatment.
    if (isTableWellFormatted && tablePathRef.current) {
        const tableWidth = Math.max(...nodeAsTable.children.map((row) => (row as Wrex.Element).children.length));
        nodeAsTable.children.forEach((row, i) => {
            if ((row as Wrex.Element).children.length < tableWidth) {
                const cellsToAddCount = tableWidth - (row as Wrex.Element).children.length;
                for (let j = 0; j < cellsToAddCount; j++) {
                    Transforms.insertNodes(editor, createTD(), {
                        at: [...(tablePathRef.current as Path), i, (row as Wrex.Element).children.length],
                    });
                }
            }
        });
    }

    return false;
};
