import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { object, number, string } from 'prop-types';

import isUndefined from 'lodash/isUndefined';

import { PROGRESS_TYPE } from './constant';

/**
 * Get the progress value for circular loader.
 *
 * @param  {number} [value] The current progress value.
 * @return {Object} The style of the circle according with the current progression.
 */
const getCircularProgressValue = (value) => {
    if (isUndefined(value)) {
        return undefined;
    }

    return {
        strokeDasharray: `${parseInt(value, 10) * 1.26},200`,
    };
};

/**
 * Get the progress value for linear loader.
 *
 * @param  {number} [value] The current progress value.
 * @return {Object} The style of the bar according with the current progression.
 */
const getLinearProgressValue = (value) => {
    if (isUndefined(value)) {
        return undefined;
    }

    return {
        transform: `scale(${parseInt(value, 10) / 100}, 1)`,
    };
};

/**
 * Get circular loader style.
 *
 * @param  {number} diameter The value of the diameter in px.
 * @return {Object} The style of the circular loader.
 */
const getProgressDiameter = (diameter) => ({
    transform: `scale(${parseInt(diameter, 10) / 100})`,
});

/**
 * Display a Lumx progress.
 * The progress can be circular (by default) or linear.
 */
export class Progress extends PureComponent {
    static propTypes = {
        /** The custom class of the loader */
        classes: object,
        /** The color of the loader (primary per default). */
        color: string,
        /** The diameter of the circular loader in px (100 per default). */
        diameter: string,
        /** The type of the loader, can be circular (default) or linear. */
        type: string,
        /** The current loading value */
        value: number,
    };

    static defaultProps = {
        color: 'primary',
        diameter: '100',
        type: PROGRESS_TYPE.CIRCULAR,
    };

    render() {
        const { classes, color, diameter, type, value } = this.props;

        return (
            <div
                className={classNames(
                    `progress-container progress-container--${type}`,
                    `progress-container--${color}`,
                    value ? 'progress-container--determinate' : 'progress-container--indeterminate',
                    { classes: classes },
                )}
            >
                {type === PROGRESS_TYPE.CIRCULAR && (
                    <div className="progress-circular" style={getProgressDiameter(diameter)}>
                        <svg className="progress-circular__svg">
                            <g transform="translate(50 50)">
                                <g className="progress-circular__g">
                                    <circle
                                        className="progress-circular__path"
                                        cx="0"
                                        cy="0"
                                        r="20"
                                        fill="none"
                                        strokeWidth="4"
                                        strokeMiterlimit="10"
                                        style={getCircularProgressValue(value)}
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                )}

                {type === PROGRESS_TYPE.LINEAR && (
                    <div className="progress-linear">
                        <div className="progress-linear__background" />

                        <div
                            className="progress-linear__bar progress-linear__bar--first"
                            style={getLinearProgressValue(value)}
                        />

                        <div className="progress-linear__bar progress-linear__bar--second" />
                    </div>
                )}
            </div>
        );
    }
}
