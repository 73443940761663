(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityFormService(AbstractContentForm, Community) {
        'ngInject';

        // eslint-disable-next-line lumapps/file-format
        var service = AbstractContentForm.createAbstractContentFormService(Community);

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * A map of the community form display modes that have already been set.
         *
         * @type {Object}
         */
        var _modes = {};

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * A map of the available display modes of the form.
         *
         * @type {Object}
         */
        service.DISPLAY_MODE = {
            complete: 'complete',
            limited: 'limited',
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Override AbstractContentForm behaviour.
         *
         * @return {boolean} Don't bother checking feedKeys on the community form.
         */
        function _checkFeedKeys() {
            return false;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the display mode of a community form for a given listKey.
         *
         * Useful on the frontend, when a user edits the community, we want to hide some fields (i.e. url).
         * Complete is the default mode.
         *
         * @param  {string} [listKey='default'] The key to get the mode of (if it has been set already).
         * @return {string} The display mode for the given listKey.
         */
        function getDisplayMode(listKey) {
            listKey = listKey || 'default';

            return _modes[listKey] || service.DISPLAY_MODE.complete;
        }

        /**
         * Set the display mode of a community form for a given listKey.
         *
         * @param {string} [listKey='default'] The key of the community form to set the mode for.
         * @param {string} [mode='complete']   The mode value to use.
         */
        function setDisplayMode(listKey, mode) {
            listKey = listKey || 'default';

            _modes[listKey] = service.DISPLAY_MODE[mode] || service.DISPLAY_MODE.complete;
        }

        /////////////////////////////

        service._checkFeedKeys = _checkFeedKeys;
        service.getDisplayMode = getDisplayMode;
        service.setDisplayMode = setDisplayMode;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('CommunityForm', CommunityFormService);
})();
