import BaseApi from '@lumapps/base-api';

const documentProviderApi = new BaseApi({ path: 'document/provider' });

const list = ({ instanceId }) => {
    return documentProviderApi.get('list', {
        params: { instanceId },
    });
};

export { list };
