(function IIFE() {
    'use strict';

    /////////////////////////////

    function CustomerFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/customer/:methodKey', {}, {
            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            registrationLink: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'registrationLink',
                },
            },

            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
                url: Config.APPLICATION_HOST_PROCESS + '/_ah/api/lumsites/v1/customer/:methodKey',
            },

            status: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'status',
                },
            },
        });
    }

    angular.module('Factories').factory('CustomerFactory', CustomerFactory);

    /////////////////////////////

    function CustomerPropertiesFactory($resource, Config) {
        'ngInject';

        return $resource(Config.APPLICATION_HOST + '/_ah/api/lumsites/v1/customer/properties/:methodKey', {}, {
            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        });
    }

    angular.module('Factories').factory('CustomerPropertiesFactory', CustomerPropertiesFactory);
})();
