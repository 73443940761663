import { getCompletions } from '../api';
import { getSummaryPrompt } from './getSummaryPrompt';

export const generateSummary = async (text: string, textTitle?: string, targetLang?: string) => {
    const prompt = getSummaryPrompt(text, textTitle, targetLang);

    const response = await getCompletions({ prompt });

    return response;
};
