import { useMemo, useCallback } from 'react';

import { getDirectories } from '@lumapps/directories/ducks/selectors';
import { getCurrentInstance, getInstanceById } from '@lumapps/instance/ducks/selectors';
import { getLanguageHeader } from '@lumapps/languages';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { Route } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations/hooks/useTranslate';
import { isLayoutEnabled as isLayoutEnabledSelector } from '@lumapps/widget-layout/ducks/selectors';

import { getContentEntities } from '../ducks/selectors';
import { contentView } from '../routes/view';
import { ContentLinkRef } from '../types';
import { prepareFetchUpdateContentLinkRef } from '../utils/content-link/fetchUpdateContentLinkRef';
import { updateContentLinkFromState } from '../utils/content-link/updateContentLinkFromState';

/**
 * Hook that generate the correct Route for the given content.
 * An update function is provided to update (if needed) the v2 migration status, the content slug and instance slug
 * before using the route.
 *
 * Use `useContentLink` or <ContentLink> if you'd rather use a true link.
 */
export const useContentRoute = (
    contentLinkRef: ContentLinkRef,
): { route: Route; updateContentRoute: () => Promise<Route> } => {
    const isLayoutEnabled = useSelector(isLayoutEnabledSelector);
    const contentById = useSelector(getContentEntities) || {};
    const instanceById = useSelector(getInstanceById) || {};
    const directories = useSelector(getDirectories);
    const { id: currentInstanceId } = useSelector(getCurrentInstance);
    const { translateObject } = useTranslate();
    const languageHeader = useSelector(getLanguageHeader);
    const dispatch = useDispatch();

    const updatedRoute = useMemo(
        () => {
            // Update content link ref from redux state (if possible).
            const updatedContentLinkRef = updateContentLinkFromState(contentLinkRef, translateObject, {
                currentInstanceId,
                contentById,
                instanceById,
                directories,
            });
            // Generate route.
            return contentView({ isLayoutEnabled, to: updatedContentLinkRef });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            contentById,
            instanceById,
            isLayoutEnabled,
            translateObject,
            // We'll only update if the content id changes to prevent unnecessary re-render
            contentLinkRef.id,
        ],
    );

    const updateContentRoute = useCallback(async () => {
        // Update content link ref with API calls
        const updatedContentLinkRef = await prepareFetchUpdateContentLinkRef({
            to: updatedRoute,
            translateObject,
            currentInstanceId,
            dispatch,
            languageHeader,
        }).fetch();
        // Generate route.
        return contentView({ isLayoutEnabled, to: updatedContentLinkRef });
    }, [currentInstanceId, dispatch, isLayoutEnabled, languageHeader, translateObject, updatedRoute]);

    return { route: updatedRoute, updateContentRoute };
};
