import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiLibraryVideo, mdiPlayBoxMultiple } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    Badge,
    ColorPalette,
    Icon,
    InlineList,
    Thumbnail as LumxThumbnail,
    Typography,
} from '@lumapps/lumx/react';
import FallbackImage from '@lumapps/play/assets/images/fallback.svg';
import { useDimensions } from '@lumapps/responsive';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';
import { VideosCount } from '../VideosCount';

import './Thumbnail.scss';

interface ThumbnailProps {
    /** Image alternative text */
    alt?: string;
    /** Whether the thumbnail is interactive or not (add hover state and Play icon) */
    isInteractive?: boolean;
    /** Boolean used to display thumbnail badge */
    withBadge?: boolean;
    /** Boolean used to display caption in thumbnail */
    withCaption?: boolean;
    /** Boolean used to display icon into thumbnail */
    withIcon?: boolean;
}

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-thumbnail` as const;

const ICON_SIZES = { m: 0, l: 256, xl: 640 };

/**
 * Thumbnail that display the number of videos present in the playlist.
 * It can be interactive and display another state on hover.
 */
export const Thumbnail = ({ alt = '', isInteractive, withBadge, withCaption, withIcon }: ThumbnailProps) => {
    const { block, element } = useClassnames(CLASSNAME);

    const { canDisplay, playlist, theme } = useBlockPlaylistContext();

    const { ref, currentBreakpoint: iconSize } = useDimensions({ breakpoints: ICON_SIZES });

    if (!canDisplay('THUMBNAIL')) {
        return null;
    }

    return (
        <div className={block({ 'is-interactive': Boolean(isInteractive) })} ref={ref}>
            <LumxThumbnail
                alt={alt}
                aspectRatio={AspectRatio.original}
                badge={
                    withBadge && (
                        <Badge color={ColorPalette.grey}>
                            <Icon icon={mdiPlayBoxMultiple} />
                        </Badge>
                    )
                }
                className={element('image')}
                image={playlist?.thumbnail?.url || ''}
                fallback={<img alt="" className={element('fallback')} src={FallbackImage} />}
                theme={theme}
            />

            {withIcon && (
                <Icon className={element('icon')} color={ColorPalette.light} icon={mdiLibraryVideo} size={iconSize} />
            )}

            {withCaption && canDisplay('VIDEO_COUNT') && (
                <figcaption className={element('metadata')}>
                    <InlineList color={ColorPalette.light} typography={Typography.caption}>
                        <VideosCount truncate />
                    </InlineList>
                </figcaption>
            )}
        </div>
    );
};
