import { shape, number, bool, string, object } from 'prop-types';

import { widgetType } from '../types';

/** The prop types definition for the widget weather */
export const widgetWeatherType = {
    ...widgetType,
}

/** The prop type definition for the forecast block */
export const forecastType = {
    city: string,
    forecast: object,
    inputLang: string,
    isFirst: bool,
    unit: object,
}
