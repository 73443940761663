import { ATTACHMENTS } from '@lumapps/wrex-attachments/constants';
import { ATTACHMENTS_CONVERT } from '@lumapps/wrex-attachments/plugin/dita/serialize';
import { EMBEDDED_MAP } from '@lumapps/wrex-embedded-map/constants';
import { EMBEDDED_MAP_CONVERT } from '@lumapps/wrex-embedded-map/plugin/dita/serialize';
import { EMBEDDED_VIDEO } from '@lumapps/wrex-embedded-video/constants';
import { EMBEDDED_VIDEO_CONVERT } from '@lumapps/wrex-embedded-video/plugin/dita/serialize';
import { ALIGNED_IMAGE_WRAPPER, IMAGE_GROUP } from '@lumapps/wrex-enhanced-image/constants';
import { IMAGE_GROUP_CONVERT, ALIGNED_IMAGE_WRAPPER_CONVERT } from '@lumapps/wrex-enhanced-image/plugin/dita/serialize';
import { FILE } from '@lumapps/wrex-file/constants';
import { FILE_CONVERT } from '@lumapps/wrex-file/plugin/dita/serialize';
import { LINK_PREVIEW } from '@lumapps/wrex-link-preview/constants';
import { LINK_PREVIEW_CONVERT } from '@lumapps/wrex-link-preview/plugin/dita/serialize';
import { LINK } from '@lumapps/wrex-link/constants';
import { LINK_CONVERT } from '@lumapps/wrex-link/plugin/dita/serialize';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from '@lumapps/wrex-list/constants';
import { LI_CONVERT, OL_CONVERT, UL_CONVERT } from '@lumapps/wrex-list/plugin/dita/serialize';
import { PLAY_VIDEO } from '@lumapps/wrex-play-video/constants';
import { PLAY_VIDEO_CONVERT } from '@lumapps/wrex-play-video/plugin/dita/serialize';
import { QUOTE_BLOCK } from '@lumapps/wrex-quote/constants';
import { QUOTE_CONVERT } from '@lumapps/wrex-quote/plugin/dita/serialize';
import { TABLE, T_D, T_R } from '@lumapps/wrex-table/constants';
import { TABLE_CONVERT, TD_CONVERT, TR_CONVERT } from '@lumapps/wrex-table/plugin/dita/serialize';
import {
    BOLD,
    CODE_BLOCK,
    COLORED,
    INLINE_CODE,
    ITALIC,
    PARAGRAPH,
    STRIKETHROUGH,
    UNDERLINE,
} from '@lumapps/wrex-typography/constants';
import {
    PARAGRAPH_CONVERT,
    BOLD_CONVERT,
    CODE_BLOCK_CONVERT,
    CODE_CONVERT,
    COLORED_TEXT_CONVERT,
    ITALIC_CONVERT,
    UNDERLINE_CONVERT,
    STRIKETHROUGH_CONVERT,
} from '@lumapps/wrex-typography/plugin/dita/serialize';
import { USER_MENTION } from '@lumapps/wrex-user-mention/constants';
import { MENTION_CONVERT } from '@lumapps/wrex-user-mention/plugin/dita/serialize';

import { ConversionOptions } from './types';

export const OPTIONS: ConversionOptions = {
    marks: {
        [ITALIC]: ITALIC_CONVERT,
        [BOLD]: BOLD_CONVERT,
        [UNDERLINE]: UNDERLINE_CONVERT,
        [COLORED]: COLORED_TEXT_CONVERT,
        [STRIKETHROUGH]: STRIKETHROUGH_CONVERT,
    },

    elements: {
        [PARAGRAPH]: PARAGRAPH_CONVERT,
        [UNORDERED_LIST]: UL_CONVERT,
        [ORDERED_LIST]: OL_CONVERT,
        [LIST_ITEM]: LI_CONVERT,
        [IMAGE_GROUP]: IMAGE_GROUP_CONVERT,
        [ALIGNED_IMAGE_WRAPPER]: ALIGNED_IMAGE_WRAPPER_CONVERT,
        [USER_MENTION]: MENTION_CONVERT,
        [LINK]: LINK_CONVERT,
        [FILE]: FILE_CONVERT,
        [LINK_PREVIEW]: LINK_PREVIEW_CONVERT,
        [CODE_BLOCK]: CODE_BLOCK_CONVERT,
        [INLINE_CODE]: CODE_CONVERT,
        [QUOTE_BLOCK]: QUOTE_CONVERT,
        [TABLE]: TABLE_CONVERT,
        [T_R]: TR_CONVERT,
        [T_D]: TD_CONVERT,
        [EMBEDDED_VIDEO]: EMBEDDED_VIDEO_CONVERT,
        [PLAY_VIDEO]: PLAY_VIDEO_CONVERT,
        [EMBEDDED_MAP]: EMBEDDED_MAP_CONVERT,
        [ATTACHMENTS]: ATTACHMENTS_CONVERT,
    },
};
