import { WidgetState } from '../ducks/type';

/**
 * The effective widget type of a widget is
 * - The widget type for client computed widgets
 * - OR The widget v2 type
 */
export function getEffectiveWidgetType(widget: Partial<WidgetState>) {
    const { body, widgetType } = widget;
    return (body as any)?.widgetType || widgetType;
}
