import React from 'react';

import { hasTermsAndConditions, customerIdSelector, getAvailableLanguages } from '@lumapps/customer/ducks/selectors';
import { getDefaultInstanceLanguage } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BaseStore, Dispatch } from '@lumapps/redux/types';
import { getFirstAlternativeLanguage } from '@lumapps/translations';
import { hasUserAcceptedTermsAndConditions, isConnected } from '@lumapps/user/ducks/selectors';
import { actions } from '@lumapps/user/ducks/slice';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { TermsAndConditionsModalProps } from '../../types';

const TermsAndConditionsModal = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'terms-and-conditions-modal' */
            './Modal'
        ),
);

export interface ConnectedTermsAndConditionsProps extends TermsAndConditionsModalProps {
    acceptTermsAndConditions: () => void;
}

export const ConnectedTermsAndConditions: React.FC<ConnectedTermsAndConditionsProps> = ({
    onAccept,
    onRefuse,
    customerId,
    isOpen,
    currentUserLang,
    customerAvailableLanguages,
    defaultInstanceLang,
    userAlternativeLang,
    acceptTermsAndConditions,
}) => {
    const [isModalOpen, setIsModalOpen] = React.useState(isOpen);

    const onAcceptTerms = () => {
        setIsModalOpen(false);
        acceptTermsAndConditions();

        if (onAccept) {
            onAccept();
        }
    };

    const onRefuseTerms = () => {
        if (onRefuse) {
            onRefuse();
        }
    };

    return isOpen ? (
        <Suspense isDialog>
            <TermsAndConditionsModal
                isOpen={isModalOpen}
                onAccept={onAcceptTerms}
                onRefuse={onRefuseTerms}
                customerId={customerId}
                currentUserLang={currentUserLang}
                userAlternativeLang={userAlternativeLang}
                defaultInstanceLang={defaultInstanceLang}
                customerAvailableLanguages={customerAvailableLanguages}
            />
        </Suspense>
    ) : null;
};

export interface ConnectedTermsAndConditions {
    hasAccepted?: boolean;
    hasTerms?: boolean;
}

const mapStateToProps = (state: BaseStore, ownProps: ConnectedTermsAndConditions) => {
    const hasAcceptedTermsAndConditions = hasUserAcceptedTermsAndConditions(state) || ownProps.hasAccepted;
    const customerId = customerIdSelector(state);
    const hasTerms = hasTermsAndConditions(state) || ownProps.hasTerms;
    const currentUserLang = currentLanguageSelector(state);
    const defaultInstanceLang = getDefaultInstanceLanguage(state);
    const isUserConnected = !!isConnected(state);
    const userAlternativeLang = getFirstAlternativeLanguage();
    const customerAvailableLanguages = getAvailableLanguages(state);

    return {
        isOpen: Boolean(hasTerms && !hasAcceptedTermsAndConditions && isUserConnected),
        currentUserLang,
        customerAvailableLanguages,
        defaultInstanceLang,
        userAlternativeLang,
        customerId,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        acceptTermsAndConditions: () => {
            dispatch(actions.acceptTermsAndConditions({ hasAccepted: true }));
        },
    };
};

const connectedTermsAndConditions = connect(mapStateToProps, mapDispatchToProps)(ConnectedTermsAndConditions);

export { connectedTermsAndConditions as TermsAndConditions, mapStateToProps, mapDispatchToProps };
