import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiSpeedometer } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { PLAY_VIDEO_USAGE } from '../../keys';
import { admin } from '../../routes';

export interface VideoUsageSideNavigationProps {
    isAllowed: boolean;
}

const VideoUsageSideNavigation = ({ isAllowed }: VideoUsageSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(PLAY_VIDEO_USAGE.VIDEO_USAGE)} icon={mdiSpeedometer} route={admin()} />
    );
};

VideoUsageSideNavigation.displayName = 'SideNavigationItem';

export { VideoUsageSideNavigation };
