import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { Style as StyleService } from '../../../../services/styles';
import { MainSettings } from './MainSettings';

/**
 * Renders the main style editor (styling of the surrounding widget element).
 */
export class Main extends React.PureComponent {
    static propTypes = {
        /** Whether the editor should be visible or not. */
        active: propTypes.bool.isRequired,
        /** Default values. */
        defaults: styleTypes.mainStyle.isRequired,
        /** Define if the cell of the widget is in plain mode */
        isInPlainCell: propTypes.bool,
        name: types.property.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Current main style. */
        value: styleTypes.mainStyle,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { value, name: key, onChange, defaults, active, isInPlainCell } = this.props;

        return (
            <div className={classNames('widget-style-main', active && 'widget-style-main--is-active')}>
                <div className="widget-style-main__settings">
                    {active && (
                        <MainSettings
                            value={value}
                            name={key}
                            onChange={onChange}
                            defaults={defaults}
                            isInPlainCell={isInPlainCell}
                        />
                    )}
                </div>
            </div>
        );
    }
}
