/* istanbul ignore file */
import BaseApi, { fetchAll } from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { Metadata } from '../types';

/**
 * Instance metadata API.
 * https://api.lumapps.com/docs/instance/metadata/list
 */
const instanceMetadataApi = new BaseApi({ path: 'instance/metadata' });

export interface InstanceListMetadataRequest extends ServerListRequest {
    withInheritance?: boolean;
    rootOnly?: boolean;
    instance: string;
    familyId?: string;
}

export const listInstanceMetadata = (params: InstanceListMetadataRequest) =>
    instanceMetadataApi.getCacheFirst<ServerListResponse<Metadata>>('list', CACHE_TYPE.MEMORY, PRIORITY.LOW, {
        params,
    });

/**
 * Recursively call all instsance metadata from a family.
 *
 * Returns an object with the data to get closer to Axios response.
 */
export const getAllInstanceFamilyMetadata = async ({
    maxResults = '500',
    withInheritance = true,
    ...params
}: InstanceListMetadataRequest) => {
    const metadataFamilies = await fetchAll(
        listInstanceMetadata,
        { withInheritance, ...params },
        parseInt(maxResults, 10),
    );

    /**
     * Returns an object with the data to get closer to Axios response.
     */
    return {
        data: metadataFamilies,
    };
};
