import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    AspectRatio,
    FlexBox,
    Orientation,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
} from '@lumapps/lumx/react';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

import './Figure.scss';

const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-figure` as const;

type FigureProps = React.PropsWithChildren<{
    /** Size variant of the component. */
    size?: Extract<Size, 'l' | 'xl' | 'xxl'>;
}>;

/**
 * Component to use as visual element.
 */
export const Figure = ({ children, size = 'xxl' }: FigureProps) => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, orientation, theme } = useBlockPlaylistContext();

    if (!canDisplay('THUMBNAIL')) {
        return null;
    }

    if (isLoading) {
        return (
            <SkeletonRectangle
                aspectRatio={AspectRatio.wide}
                theme={theme}
                variant={SkeletonRectangleVariant.rounded}
                width={orientation === Orientation.horizontal ? size : undefined}
            />
        );
    }

    return (
        <FlexBox
            as="figure"
            className={block({ [`size-${size}`]: orientation === Orientation.horizontal })}
            noShrink
            orientation={Orientation.vertical}
        >
            {children}
        </FlexBox>
    );
};
