import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { get as getConstants } from '@lumapps/constants';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { MAX_ASIDE_SEARCH_RESULTS, ENGINES, SHARE_POINT_RESULT_TYPES, SEARCH_API_TIMEOUT } from '../constants';
import { DEFAULT_DOC_PATH } from '../constants/sharepoint';
import { SharePointSearchResult, SearchResults, SearchParams, SearchTab } from '../types';
import { createSearchEngineFilter, createSearchEngineMetadata } from '../utils';

const constants = getConstants();

const sharepointApi = new BaseApi({ path: 'document' });

/**
 * Parameters for the Share point search engine
 */
export interface SharePointSearchParams extends SearchParams {
    /** id of the current instance */
    instanceId?: string;
    /** id of the current customer */
    customerId?: string;
    /** where to search */
    docPath?: string;
}

export interface SharePointSearchResults extends SearchResults {
    items: SharePointSearchResult[];
}

/**
 * Returns a promise with the search results from the sharepoint API
 * @param params parameters that will be used to search on Sharepoint
 */
const search = (params: SharePointSearchParams): Promise<SharePointSearchResults> => {
    const {
        query,
        maxResults = MAX_ASIDE_SEARCH_RESULTS,
        features,
        customerId = constants.customerId,
        instanceId,
        timeout = SEARCH_API_TIMEOUT,
        cursor,
        docPath = DEFAULT_DOC_PATH,
        config = {},
        lang,
    } = params;

    /**
     * Since there are 2 endpoints for retrieving share point results, we need to make sure that we
     * call the correct API. If the sharepoint beta feature flag is enabled, we need to call the search endpoint
     */
    const endpoint = features && features.isSharePointBetaEnabled ? 'search' : 'list';

    /** Get the docPath configured */
    const finalDocPath = config.docPath ? config.docPath : docPath;

    return sharepointApi
        .postCacheFirst<SharePointSearchResults>(
            endpoint,
            {
                maxResults,
                currentInstance: instanceId,
                currentInstanceId: instanceId,
                customer: customerId,
                docPath: finalDocPath,
                mediaProvider: 'onedrive',
                searchText: decodeURIComponentSafe(query),
                searchRules: '',
                lang,
                cursor,
                searchTypes: [SHARE_POINT_RESULT_TYPES.IMAGE, SHARE_POINT_RESULT_TYPES.OTHER],
            },
            CACHE_TYPE.MEMORY,
            PRIORITY.HIGH,
            { timeout },
        )
        .then((response) => {
            const { items = [], more, cursor: newCursor } = response.data;

            const filters = [createSearchEngineFilter(ENGINES.SHAREPOINT)];

            const metadata: Record<string, SearchTab> = {
                [ENGINES.SHAREPOINT]: createSearchEngineMetadata(ENGINES.SHAREPOINT),
            };

            return { items, more, filters, metadata, cursor: newCursor };
        });
};

export { sharepointApi, search };
