/**
 * List of the keys that represent the default sort that a search engine has. Depending on the search engine
 * the sort's ID is different, so we need to have a list of IDs instead of a single one. This will be used to
 * check if we need to enable the decreasing/increasing arrow near the sort button. If the current sort is the
 * default one, this functionality is disabled.
 */
export const DEFAULT_SORT = ['_score', 'relevance'];

/**
 * Determines whether the provided sort is a default one.
 * @param sort - sort key
 */
export const isDefaultSort = (sort?: string) => Boolean(sort && DEFAULT_SORT.indexOf(sort) >= 0);
