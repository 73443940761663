(function IIFE() {
    'use strict';

    /////////////////////////////

    function MetadataSelectorController($scope, Metadata, Translation, UserAccess, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The maximum items number.
         *
         * @type {number}
         * @readonly
         */
        var _LIMIT = 500;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * A map matching a metadata parent id with a list of metadatas.
         *
         * @type {Object}
         */
        vm.metaMap = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Metadata = Metadata;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Filter metadata.
         * Checks whether the user has access to these meta data and gets translated values,
         * for current lang (minus empty ones).
         *
         * @param  {string} metaKey      The metaKey to filter on.
         * @param  {string} lxFilterText The lx-select text filter to filter metadata to.
         * @return {Array}  The filtered list of metadata.
         */
        function _filterMetadata(metaKey, lxFilterText) {
            // ToLowerCase because the filter is not case sensitive.
            const filterValue = lxFilterText ? lxFilterText.trim().toLowerCase() : null;

            if (angular.isUndefinedOrEmpty(metaKey)) {
                return [];
            }

            vm.metaMap[metaKey] = angular.fastCopy(Metadata.getFilteredMetadata(metaKey, true));

            var validator = UserAccess.checkIfMetadataIsAllowed(vm.contentType);
            var matching = 0;

            vm.metaMap[metaKey] = vm.metaMap[metaKey].filter(function filterMeta(metadata) {
                if (matching >= _LIMIT) {
                    return false;
                }

                // ToLowerCase because the filter is not case sensitive.
                var metaNameLang = Translation.translate(_.get(metadata, 'name')).toLowerCase();
                var metaPathLang = Translation.translate(_.get(metadata, 'path')).toLowerCase();

                var match = validator(metadata) && (!filterValue ||
                    _.includes(metaNameLang, filterValue) ||
                    _.includes(metaPathLang, filterValue));

                if (match) {
                    matching++;
                }

                return match;
            });

            return vm.metaMap[metaKey];
        }

        /**
         * Compute the metadata.
         * This will cache values in vm.metaMap rather than use metadataList directly in the select.
         *
         * @param {Array} metadataList The metadata list to compute.
         */
        function _computeMetadata(metadataList) {
            if (angular.isUndefinedOrEmpty(metadataList) || !angular.isArray(metadataList)) {
                return;
            }

            // Iter through metadata available and get values for each.
            angular.forEach(metadataList, function forEach(metadataItem) {
                vm.filter(metadataItem.key);
            });
        }

        /**
         * Iter over metadataList, check if they are multiple and find corresponding model.
         * 
         * @param {Array} metadataList The metadata list to compute.
         */
        function _formatModel(metadataList) {
            // Model should not be undefined.
            if (angular.isUndefinedOrEmpty(vm.model)) {
                // Model is an object, because multiple metadata (always).
                vm.model = {};
            }
            // Iter over main metadata (the parent).
            angular.forEach(metadataList, function forEach(metadataItem) {
                var key = metadataItem.key;
                var isModelKeyArray = angular.isArray(vm.model[key]);
                // Is the metadata Item allowed for multiple selection or override with vm.multiple.
                var isMultiple = Boolean(metadataItem.multiple) || Boolean(vm.multiple);

                if (isMultiple) {
                    if (!isModelKeyArray) {
                        // Cast as array and if model[key] is set add it.
                        vm.model[key] = (angular.isDefinedAndFilled(vm.model[key])) ? [vm.model[key]] : [];
                    }
                } else if (isModelKeyArray) {
                    // If model is an array take the first element.
                    vm.model[key] = _.first(vm.model[key]);
                }
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Create a class name if metadata has functionalInnerId.
         *
         * @param  {string} metaFunctionalInnerId The functionalInnerId to add on class name.
         * @return {string} Class name.
         */
        function getMetadataClass(metaFunctionalInnerId) {
            if (angular.isUndefinedOrEmpty(metaFunctionalInnerId)) {
                return '';
            }

            return 'metadata-selector--' + metaFunctionalInnerId;
        }

        /**
         * Filter metadata, return them with theirs childrens.
         *
         * @param {string} metaKey  The metaKey to filter on.
         * @param {string} newValue The filter value to filter metadata to.
         */
        function filter(metaKey, newValue = '') {
            Metadata.filterAllMetadata(metaKey, newValue).then(function onMetadataFiltered() {
                _filterMetadata(metaKey, newValue);
            });
        }

        /////////////////////////////

        vm.filter = filter;
        vm.getMetadataClass = getMetadataClass;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch the metadataList so we keep vm.metaMap up to date.
         *
         * @param {Object} newMetadataList The new newMetadataList object.
         * @param {Object} oldMetadataList The old oldMetadataList object.
         */
        // Watch the metadataList so we keep vm.metaMap up to date (since this is what is used in the selects).
        $scope.$watch('vm.metadataList', function metadataListWatcher(newMetadataList, oldMetadataList) {
            if (angular.isDefinedAndFilled(newMetadataList) && (newMetadataList !== oldMetadataList)) {
                // Check if metadata list format has changed and adapt model.
                _formatModel(newMetadataList);
                // This will compute the new metadataList if it hasn't been computed yet.
                _computeMetadata(newMetadataList);
            }
        }, true);

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Check if metadata list format has changed and adapt model.
            _formatModel(vm.metadataList);
            // Load the data matching a metadata parent id with a list of metadatas.
            _computeMetadata(vm.metadataList);
        }

        init();
    }

    /**
     * Metadata selector directive.
     * Display several list of metadata items.
     *
     * @param {string}   [contentType]        Indicates the content type for UserAccess validator.
     * @param {Function} [lsChange]           Function to be called when selected values change.
     * @param {Array}    metadataList         The metadata's list to display.
     * @param {Object}   model                The model to set for selected values.
     * @param {boolean}  [multiple]           Indicates if we want to allow multiple selection.
     *                                        This value override metadataList[key].multiple.
     */

    function MetadataSelectorDirective() {
        return {
            bindToController: true,
            controller: MetadataSelectorController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                contentType: '@?',
                lsChange: '&?',
                metadataList: '=',
                model: '=',
                multiple: '=?lsMultiple',
                ngDisabled: '=?ngDisabled',
            },
            templateUrl: '/client/common/modules/metadata-selector/views/metadata-selector.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('metadataSelector', MetadataSelectorDirective);
})();
