import React, { PureComponent } from 'react';
import { arrayOf, func, string } from 'prop-types';
import classNames from 'classnames';

import { modeTypes } from './types';
import * as types from '../../types';
import { translate as t } from '../../../translations';

/**
 * Renders two buttons that enable switching between `block` and `inline` modes.
 */
export class ModeSettings extends PureComponent {
    static propTypes = {
        /** The current mode. */
        current: string.isRequired,
        /** The list of possible modes. */
        modes: arrayOf(modeTypes).isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: func.isRequired,
        /** The property name to set to the widget. */
        property: types.properties.isRequired,
    };

    constructor(props) {
        super(props);

        this.setMode = this.setMode.bind(this);
    }

    /**
     * By clicking on a mode, change the parent value.
     *
     * @param {SyntheticEvent} evt The event triggering this method.
     */
    setMode(evt) {
        const { onChange, property } = this.props;

        onChange(evt.currentTarget.id, property, evt);
    }

    render() {
        const { current, modes } = this.props;

        return (
            <div flex-container="row">
                {modes.map(({ value, label, render }, index) => (
                    <div
                        key={value}
                        className={classNames(
                            `widget-style-mode widget-style-mode--${value}`,
                            current === value && 'widget-style-mode--is-active',
                        )}
                        flex-item="flex-item"
                        id={value}
                        role="button"
                        tabIndex={index}
                        onClick={this.setMode}
                        onKeyDown={this.setMode}
                    >
                        {render && render()}
                        <span className="widget-style-mode__type">{t(label)}</span>
                    </div>
                ))}
            </div>
        );
    }
}
