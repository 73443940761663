import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { Fig, FigAlignClass, Image, ImageLink, Media, Title } from '@lumapps/wrex/serialization/dita/types';

import { IMAGE_WIDTHS } from '../../constants';
import { AlignedImageWrapperElement, ImageGroupElement } from '../../types';

export const IMAGE_GROUP_CONVERT: Converter<ImageGroupElement> = (nd) => {
    const node = nd as ImageGroupElement;

    const title: Title = {
        type: 'element',
        name: 'title',
        children: [
            {
                type: 'text',
                value: node?.title || '',
            },
        ],
    };

    const imageNodes = node.images.reduce<Array<Image | Media | ImageLink>>((nodes, imageNode) => {
        const image: Image = {
            type: 'element',
            name: 'image',
            attributes: {
                href: imageNode.src,
                keyref: imageNode.id || '',
                height: imageNode.height ? `${imageNode.height}px` : undefined,
                width: imageNode.width ? `${imageNode.width}px` : undefined,
            },
            children: [
                {
                    type: 'element',
                    name: 'alt',
                    children: [
                        {
                            type: 'text',
                            value: imageNode.alt || '',
                        },
                    ],
                },
            ],
        };

        const images = [...nodes, image];

        if (imageNode.link) {
            const imageLink: ImageLink = {
                type: 'element',
                name: 'xref',
                attributes: {
                    href: imageNode.link,
                    scope: 'external',
                    type: 'fig',
                },
                children: [],
            };

            images.push(imageLink);
        }

        if (imageNode.focalPoint) {
            const media: Media = {
                type: 'element',
                name: 'data-about',
                attributes: {
                    type: 'lumapps:media',
                    keyref: imageNode.id as string,
                },
                children: [
                    {
                        type: 'element',
                        name: 'data',
                        attributes: {
                            name: 'focalPoint',
                        },
                        children: [
                            {
                                type: 'element',
                                name: 'data',
                                attributes: {
                                    name: 'focalPoint:x',
                                    value: imageNode.focalPoint.x || 0,
                                },
                                children: [],
                            },
                            {
                                type: 'element',
                                name: 'data',
                                attributes: {
                                    name: 'focalPoint:y',
                                    value: imageNode.focalPoint.y || 0,
                                },
                                children: [],
                            },
                        ],
                    },
                ],
            };

            images.push(media);
        }

        return images;
    }, []);

    const widthClass = node.width === IMAGE_WIDTHS.fullWidth ? FigAlignClass.alignFull : FigAlignClass.alignCenter;

    const ditaNode: Fig = {
        type: 'element',
        name: 'fig',
        children: [title, ...imageNodes],
        attributes: { outputclass: node.viewMode || (node.width ? widthClass : undefined) },
    };

    return ditaNode;
};

/**
 *
 * <bodydiv outputclass="image-wrapper--align-left|align-right">...</bodydiv>
 */
export const ALIGNED_IMAGE_WRAPPER_CONVERT: Converter<AlignedImageWrapperElement> = (nd, children = []) => ({
    type: 'element',
    name: 'bodydiv',
    children,
    attributes: {
        outputclass: `image-wrapper--${nd?.alignment}`,
    },
});
