import React from 'react';

import moment from 'moment';

import { currentLanguageSelector } from '@lumapps/languages';
import { Size, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { UserBlock } from '@lumapps/user/components/UserBlock';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockResourceCreationInfo as BlockResourceCreationInfoProps } from '../../type';

/**
 * The number of days to display the date of the post as a "from now" string (e.g. "1 hour ago").
 * Above this number of days, the date of the post will be displayed as a plain date.
 *
 * @type {number}
 * @constant
 * @readonly
 */
const DELTA_DAY_FROM_NOW = 1;

/**
 * Component to render author's informations in a post.
 * Display current community if has communityName props
 * Display categories if an array of tags is given
 */
export const BlockResourceCreationInfo: BlockComponent<BlockResourceCreationInfoProps> = ({
    author,
    createdAt,
    theme = Theme.light,
}) => {
    const lang = useSelector(currentLanguageSelector);
    const { userId } = author;
    const fullName = getUserFullName(author, lang);
    const { translateKey } = useTranslate();

    let displayedPublicationDate;
    if (createdAt) {
        displayedPublicationDate =
            moment().diff(moment(createdAt), 'days') < DELTA_DAY_FROM_NOW
                ? moment.utc(createdAt).local().fromNow()
                : moment.utc(createdAt).local().format('LL');
    } else {
        displayedPublicationDate = translateKey(GLOBAL.RIGHT_NOW);
    }

    return (
        <UserBlock
            userId={userId}
            fullName={fullName}
            size={Size.m}
            secondaryFields={[displayedPublicationDate]}
            theme={theme}
            scope="block-resource-creation-info"
        />
    );
};
