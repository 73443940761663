/* istanbul ignore file */
/**
 * Angular-injected implementation of the user service functions.
 */

import noop from 'lodash/noop';

import { UserAPI } from './interface';

let getConnected: any = noop;
let refreshToken: any = noop;
let redirectToProfile: any = noop;

/**
 * Binds functions to angular `User` service.
 */
function injectUserService(user: any) {
    getConnected = user.getConnected;
    refreshToken = user.refreshToken;
    redirectToProfile = user.redirectToProfile;
}

const angularApi: UserAPI = {
    getConnected: () => getConnected() ?? null,
    refreshToken: () =>
        // eslint-disable-next-line @typescript-eslint/ban-types
        new Promise((resolve: Function, reject: Function) =>
            // eslint-disable-next-line no-promise-executor-return
            refreshToken(
                (token: string) => resolve(token),
                (err: object) => reject(err),
            ),
        ) ?? null,
    getUserToken: () => {
        const user = angularApi.getConnected();

        if (user) {
            return user.token;
        }

        if (window.CONNECTED_USER) {
            return window.CONNECTED_USER.token;
        }

        return null;
    },
    redirectToProfile: (user) => {
        redirectToProfile(user);
    },
};

export { injectUserService, angularApi };
