(function IIFE() {
    'use strict';

    /////////////////////////////

    function ThumbnailController($element, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Utils = Utils;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.thumbnailHeight = vm.thumbnailHeight || 40;
            vm.thumbnailWidth = vm.thumbnailWidth || 40;

            $element.css({
                height: vm.thumbnailHeight + 'px',
                width: vm.thumbnailWidth + 'px',
            });
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive that displays a thumbnail image.
     *
     * @param {string} [thumbnailHeight=40] The height of the thumbnail to display in px.
     * @param {string} thumbnailSrc         The path to the source of the thubmnail image.
     * @param {string} [thumbnailWidth=40]  The width of the thumbnail to display in px.
     */

    function ThumbnailDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ThumbnailController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                thumbnailHeight: '@',
                thumbnailSrc: '@',
                thumbnailWidth: '@',
            },
            templateUrl: '/client/common/modules/thumbnail/views/thumbnail.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsThumbnail', ThumbnailDirective);
})();
