import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useCustomContentTypeSkeletonCount } from '../../hooks/useCustomContentTypeSkeletonCount';
import { CustomContentType } from '../../types';
import { ContentTypeSubmenu } from './ContentTypeSubmenu';

export interface ContentContributionMenuProps {
    /** Determines whether we are currently fetching the list or not */
    isLoadingCustomTypes?: boolean;
    /** the list of custom content types to display */
    parentCustomContentTypes?: CustomContentType[];
    /** True if the current user is super admin */
    isSuperAdmin?: boolean;
    /** True if the user is allowed to see the custom content types */
    isAllowed: boolean;
    /** Key/value object where the key is the id of the custom content type and the value is true/false */
    allowedCustomContentTypes: Record<string, boolean>;
    /** Key/value object where the key is the id of the custom content type and the value tells if the user can design this content type */
    canDesignAuthorizations: Record<string, boolean>;
    /** Whether the new contribution experience is enabled or not */
    isNewContributionExperienceEnabled?: boolean;
}

/**
 * Menu of all content type available to create content into.
 */
export const ContentContributionMenu: React.FC<ContentContributionMenuProps> = (props) => {
    const {
        isLoadingCustomTypes = false,
        parentCustomContentTypes,
        isSuperAdmin = false,
        isAllowed,
        allowedCustomContentTypes = {},
        canDesignAuthorizations,
        isNewContributionExperienceEnabled,
    } = props;
    const hasAccess = isAllowed || isSuperAdmin;
    const { translateKey } = useTranslate();

    const skeletonCount = useCustomContentTypeSkeletonCount();

    if (!hasAccess) {
        return null;
    }

    // Check user is super admin or has access to the custom content type.
    const customContentTypes = isSuperAdmin
        ? parentCustomContentTypes
        : parentCustomContentTypes?.filter((cct) => allowedCustomContentTypes[cct.id]);

    if (isLoadingCustomTypes || !customContentTypes) {
        return <Menu.ItemsSkeleton hasIcon itemCount={skeletonCount} loadingMessage={translateKey(GLOBAL.LOADING)} />;
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {customContentTypes?.map((contentType) => {
                // Design authorization should be checked only if New Contribution Experience is enabled.
                // Otherwise we only check the contentType settings.
                const hasDesignAuthorization = isNewContributionExperienceEnabled
                    ? Boolean(canDesignAuthorizations[contentType.id])
                    : true;
                const canCreateFromScratch =
                    isSuperAdmin || (!contentType.createFromScratchLocked && hasDesignAuthorization);

                return (
                    <ContentTypeSubmenu
                        key={contentType.id}
                        contentType={contentType}
                        defaultTemplateEnabled={canCreateFromScratch}
                    />
                );
            })}
        </>
    );
};
