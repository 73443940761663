export enum LEARNING {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485826828
     */
    LEARNING = 'ADMIN.NAV.LEARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551838224
     */
    LEARNING_CERTIFICATES = 'LEARNING.CERTIFICATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551987267
     */
    LEARNING_CERTIFICATES_PAGE_TITLE = 'LEARNING.CERTIFICATES_PAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551983847
     */
    LEARNING_ANALYTICS_PAGE_TITLE = 'LEARNING.ANALYTICS_PAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551836067
     */
    LEARNING_LEARNERS = 'LEARNING.LEARNERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551988711
     */
    LEARNING_LEARNERS_PAGE_TITLE = 'LEARNING.LEARNERS_PAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551988721
     */
    LEARNING_MANAGERS_PAGE_TITLE = 'LEARNING.MANAGERS_PAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485832546
     */
    MISSION_CENTER = 'LEARNING.MISSION_CENTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551983901
     */
    LEARNING_CATEGORIES_PAGE_TITLE = 'LEARNING.CATEGORIES_PAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=551994092
     */
    LEARNING_TRAINING_COURSES_PAGE_TITLE = 'LEARNING.TRAINING_COURSES_PAGE_TITLE',
}
