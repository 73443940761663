/**
 * An object holding various options related to posts.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const PostConstant = {
    /**
     * The events filters in the widget posts list.
     */
    EVENT_FILTERS: {
        ALL_EVENTS: 'ALL_EVENTS',
        FUTURE_EVENTS: 'FUTURE_EVENTS',
        ONGOING_EVENTS: 'ONGOING_EVENTS',
        PAST_EVENTS: 'PAST_EVENTS',
    },

    /**
     * List of possible merge statuses for posts.
     *
     * @type {Array}
     */
    MERGE_STATUS: [
        {
            key: 'YES',
            value: true,
        },
        {
            key: 'NO',
            value: false,
        },
        {
            key: 'PENDING',
            value: 'pending',
        },
    ],

    /**
     * List of available statuses for the comment relevancy.
     *
     * @type {Array}
     */
    RELEVANT_COMMENT: [
        {
            key: 'YES',
            value: true,
        },
        {
            key: 'NO',
            value: false,
        },
    ],
};

/////////////////////////////

angular.module('Constants').constant('PostConstant', PostConstant);

/////////////////////////////

export { PostConstant };
