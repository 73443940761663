import { useMemo } from 'react';

import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { useTranslate } from '@lumapps/translations';
import { DITA } from '@lumapps/wrex/serialization/dita/types';
import { featuredImageFromDita } from '@lumapps/wrex/serialization/dita/utils/featuredImageFromDita';

import { Article } from '../api/types';
import { articleView } from '../routes';
import { ArticlePreview } from '../types';

export const useArticlePreview = (article: Article | ArticlePreview, isLegacy?: boolean) => {
    const { translateObject } = useTranslate();

    /** Replace by react router link when the DS LinkPreview can be used with our router link */
    const articleRoute = useMemo(() => articleView(article.id), [article.id]);

    const imageInfos = () => {
        if (isLegacy) {
            const featuredImage = featuredImageFromDita(
                translateObject((article as Article).structuredContent.dita?.translations) as DITA,
            );

            return featuredImage
                ? {
                      href: articleRoute,
                      alt: featuredImage && featuredImage.alt,
                      url: featuredImage && (makeSecuredMediaURLRelative(featuredImage.url) as string),
                      focusPoint: featuredImage && featuredImage.focalPoint,
                  }
                : undefined;
        }
        return (article as ArticlePreview).image
            ? {
                  href: articleRoute,
                  url: (article as ArticlePreview).image,
                  focusPoint: (article as ArticlePreview).focalPoint,
              }
            : undefined;
    };

    const title = !isLegacy
        ? (article as ArticlePreview).title
        : translateObject((article as Article).structuredContent.title?.translations);

    const intro = !isLegacy
        ? (article as ArticlePreview).description
        : translateObject((article as Article).structuredContent.intro?.translations);

    const { author } = article;

    return {
        id: article.id,
        articleRoute,
        image: imageInfos(),
        title,
        intro,
        author,
    };
};
