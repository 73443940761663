/**
 * The file extension used for LumApp component files.
 */
export const COMPONENT_FILE_EXTENSION = '.js';

/**
 * The legacy file extension used for LumApp component files.
 */
export const COMPONENT_FILE_LEGACY_EXTENSION = '.lum';

/**
 * Extension topics.
 */
export const ExtensionTopics: Readonly<Record<string, string>> = {
    accounting_and_finance: 'accounting_and_finance',
    administration_and_management: 'administration_and_management',
    analytics: 'analytics',
    chat_and_communication: 'chat_and_communication',
    collaboration: 'collaboration',
    document_and_file_management: 'document_and_file_management',
    education_and_learnings: 'education_and_learnings',
    erp_and_logistics: 'erp_and_logistics',
    gamification: 'gamification',
    geolocation_and_map_services: 'geolocation_and_map_services',
    hr_and_legal: 'hr_and_legal',
    iam_services: 'iam_services',
    it_and_help_desk: 'it_and_help_desk',
    knowledge: 'knowledge',
    marketing: 'marketing',
    news_and_weather: 'news_and_weather',
    operations: 'operations',
    sales_and_crm: 'sales_and_crm',
    search_services: 'search_services',
    task_management: 'task_management',
    travel_and_expenses: 'travel_and_expenses',
    utilities: 'utilities',
} as const;

/**
 * Possible statuses for a LumApp extension.
 */
export enum ExtensionStatus {
    disabled = 'disabled',
    live = 'live',
    unavailable = 'unavailable',
}

/**
 * Possible statuses for a LumApp extension version.
 */
export const VersionStatus = {
    bundle_error: 'bundle_error',
    deployed: 'deployed',
    draft: 'draft',
    in_review: 'in_review',
    pending_bundle: 'pending_bundle',
    pending_validation: 'pending_validation',
    rejected: 'rejected',
    suspended: 'suspended',
    validated: 'validated',
} as const;
