/* eslint-disable react/self-closing-comp */
import React from 'react';

import { useClassnames, margin } from '@lumapps/classnames';
import { mdiChevronRight } from '@lumapps/lumx/icons';
import { Icon, Size, SkeletonTypography, Text, TypographyInterface, TextProps } from '@lumapps/lumx/react';

export interface HeaderParentTitleProps extends Partial<TextProps> {
    /** The parent element to show */
    parentTitle?: string | React.ReactNode | null;
    /** custom class name */
    CLASSNAME: string;
    /** whether the page is loading or not */
    isLoading?: boolean;
    /** typography to use */
    typography: TypographyInterface;
}

export const HeaderParentTitle: React.FC<HeaderParentTitleProps> = ({
    parentTitle,
    CLASSNAME,
    isLoading,
    typography,
    ...props
}) => {
    const { element } = useClassnames(CLASSNAME);
    const icon = (
        <Icon
            alt=""
            icon={mdiChevronRight}
            size={Size.xs}
            className={element('parent-icon', [margin('all', 'regular')])}
        />
    );

    if (isLoading) {
        return (
            <>
                <SkeletonTypography typography={typography} width="256px" />
                {icon}
            </>
        );
    }

    return (
        <>
            <Text {...props} as="h1" className={element('parent')}>
                {parentTitle}
            </Text>

            {icon}
        </>
    );
};
