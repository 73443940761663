/* eslint-disable no-param-reassign */
import keyBy from 'lodash/keyBy';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Group } from '../types';
import { FeedState } from './types';

const initialState: FeedState = {
    ALL: {},
    FEED_ALL_AND_PUBLIC: [],
    PUBLIC: {},
    entities: {},
};

export const { actions, reducer } = createSlice({
    domain: 'user/feed',
    initialState,
    reducers: {
        // eslint-disable-next-line func-names
        '__angular__/feed/update': function (state: FeedState, action: any) {
            const { ALL, FEED_ALL_AND_PUBLIC, PUBLIC } = action.newState;
            state.ALL = ALL;
            state.FEED_ALL_AND_PUBLIC = FEED_ALL_AND_PUBLIC;
            state.PUBLIC = PUBLIC;
        },

        updateFeeds(state: FeedState, action: PayloadAction<{ feeds: Group[] }>) {
            const { feeds } = action.payload;
            Object.assign(state.entities, keyBy(feeds, 'uid'));
        },
    },
});
