/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';

import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import lowerCase from 'lodash/fp/lowerCase';
import map from 'lodash/fp/map';
import property from 'lodash/fp/property';
import trim from 'lodash/fp/trim';

import { classnames, padding } from '@lumapps/classnames';
import { SelectFieldMultiple } from '@lumapps/combobox/components/SelectFieldMultiple';
import { renderChoiceWithCheckbox } from '@lumapps/combobox/components/SelectFieldMultiple/renderChoicesFactories';
import {
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    SkeletonTypography,
    Theme,
    Typography,
} from '@lumapps/lumx/react';

import { PostListFilterBlockProps } from '../../../types';

import './index.scss';

const CLASSNAME = 'post-select-multiple-filter';

export interface PostSelectMultipleFilterBlockProps<T> extends PostListFilterBlockProps<T> {
    getValueId(value: T): string;
    fetchChoices?(): void;
    isLoadingChoices?: boolean;
}

export const PostSelectMultipleFilterBlock = <T,>({
    theme = Theme.light,
    fetchChoices,
    isLoadingChoices = false,
    onSelect,
    choices = [],
    selectedValues = [],
    getChoiceName,
    getValueId,
    filterId,
    label,
}: PostSelectMultipleFilterBlockProps<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasInit, setHasInit] = useState(!fetchChoices || !isEmpty(choices));
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        if (!hasInit) {
            setHasInit(true);
            fetchChoices!();
        }
    }, [fetchChoices, hasInit]);

    const filteredChoices = React.useMemo(() => {
        if (!searchValue) {
            return choices;
        }

        return filter((choice) => {
            const translatedName = getChoiceName(property('value', choice));

            const comparable = flow(trim, lowerCase);

            return comparable(translatedName).includes(comparable(searchValue));
        }, choices);
    }, [choices, getChoiceName, searchValue]);

    const handleSelect = (nextSelectedValues: T[]) => {
        onSelect(filterId, nextSelectedValues);
    };

    if (!isEmpty(selectedValues) && isLoadingChoices) {
        return (
            <div className={CLASSNAME}>
                {Boolean(label) && <SkeletonTypography theme={theme} typography={Typography.body1} width="80px" />}
                <SkeletonRectangle theme={theme} height={Size.m} variant={SkeletonRectangleVariant.rounded} />
            </div>
        );
    }

    return (
        <SelectFieldMultiple
            className={classnames(CLASSNAME, padding('all', null))}
            label={label}
            theme={theme}
            value={selectedValues}
            onChange={handleSelect}
            isOpen={isOpen}
            isLoading={isLoadingChoices}
            setOpen={setIsOpen}
            choices={renderChoiceWithCheckbox({
                choices: map(property('value'), filteredChoices),
                getValueName: getChoiceName,
                getValueId,
            })}
            searchText={searchValue}
            onSearch={setSearchValue}
            getValueName={getChoiceName}
            getValueId={getValueId}
        />
    );
};
