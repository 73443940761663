/**
 * The MomentJS format to display a full date at midnight.
 *
 * @type {string}
 */
const FULL_DATE_MIDNIGHT = 'YYYY-MM-DD[T]00:00:00.000000';

/**
 * The MomentJS format to display a full date with 6 fractional seconds numbers.
 *
 * @type {string}
 */
const FULL_DATE_SIX_FRACTIONAL_SECONDS = 'YYYY-MM-DD[T]HH:mm:ss.SSSSSS';

export { FULL_DATE_MIDNIGHT, FULL_DATE_SIX_FRACTIONAL_SECONDS };
