import React, { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';

import { useClassnames } from '@lumapps/classnames';
import { SelectFieldSimple, SelectFieldSimpleProps } from '@lumapps/combobox/components/SelectFieldSimple';
import { ListItem } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { CATALOG } from '../../keys';
import { CategoryData } from '../../types';

import './index.scss';

const CLASSNAME = 'learning-catalog-select-field';

/**
 * Select from a list of learning catalog's
 * */
export const LearningCatalogSelectField: React.FC<SelectFieldSimpleProps> = (props) => {
    const { searchText, value, selectProps, ...selectFieldSimpleProps } = props;
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    /**
     * Test if the category is visible, based on the search text.
     * */
    const isVisible = useCallback(
        (choice: CategoryData) => {
            return !searchText || choice.title.match(new RegExp(searchText, 'i'));
        },
        [searchText],
    );

    /**
     * Render CategoryData listItem
     * */
    const renderItem: SelectFieldSimpleProps<CategoryData>['choiceRenderer'] = (...props) => {
        const [choice, , onSelect] = props;

        if (!isVisible(choice)) {
            return null;
        }

        return (
            <ListItem
                isSelected={Boolean(value?.code && value.code === choice.code)}
                key={choice.code}
                onItemSelected={onSelect(choice)}
            >
                {choice.title}
            </ListItem>
        );
    };

    /**
     * Render the CategoryData, with its children's; if they exist.
     * */
    const renderCategory: SelectFieldSimpleProps<CategoryData>['choiceRenderer'] = (...props) => {
        const [choice, ...options] = props;

        return !isEmpty(choice.children) ? (
            <React.Fragment key={choice.code}>
                {renderItem(choice, ...options)}
                {choice.children?.some(isVisible) && (
                    <ol key={`${choice.code}-childrens`} className={element('sublist')}>
                        {choice.children?.map((child: CategoryData) => renderCategory?.(child, ...options))}
                    </ol>
                )}
            </React.Fragment>
        ) : (
            renderItem(choice, ...options)
        );
    };

    return (
        <SelectFieldSimple<CategoryData>
            className={block()}
            choiceRenderer={renderCategory}
            getValueId={(catalog) => catalog.code}
            getValueName={(catalog) => catalog.title}
            selectProps={{ helper: translateKey(CATALOG.SELECT_CATEGORY_HELPER), ...selectProps }}
            searchText={searchText}
            value={value}
            {...selectFieldSimpleProps}
        />
    );
};
