export enum COMMUNITY_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12240404
     */
    TEMPLATES = 'ADMIN.NAV.COMMUNITY_TEMPLATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73208919
     */
    DELETE_ONE_WITH_TITLE = 'ADMIN.COMMUNITIES.DELETE_ONE_WITH_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73208921
     */
    DELETE_NB = 'ADMIN.COMMUNITIES.DELETE_NB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73208962
     */
    DELETE_NB_DESCRIPTION = 'ADMIN.COMMUNITIES.DELETE_NB.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12254214
     */
    DELETE_ONE = 'ADMIN.COMMUNITIES.DELETE_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12254221
     */
    DELETED_ONE = 'ADMIN.COMMUNITIES.DELETED_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12253240
     */
    DELETE_MULTIPLE = 'ADMIN.COMMUNITIES.DELETE_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12253342
     */
    DELETED_MULTIPLE = 'ADMIN.COMMUNITIES.DELETED_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=65836463
     */
    SAVE_AS_TEMPLATE = 'COMMUNITIES_ADMIN.SAVE_AS_TEMPLATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=65836464
     */
    NEW_COMMUNITY = 'COMMUNITIES_ADMIN.NEW_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83366681
     */
    COMMUNITY_WIZARD_NEW_COMMUNITY_TITLE = 'ADMIN.COMMUNITY_WIZARD.NEW_COMMUNITY.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83374961
     */
    COMMUNITY_WIZARD_CHOOSE_TEMPLATE_TITLE = 'ADMIN.COMMUNITY_WIZARD.CHOOSE_TEMPLATE.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=88824470
     */
    COMMUNITY_WIZARD_CREATE_COMMUNITY = 'ADMIN.COMMUNITY_WIZARD.CREATE_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=88926166
     */
    COMMUNITY_WIZARD_EMPTY_TEMPLATE = 'ADMIN.COMMUNITY_WIZARD.EMPTY_TEMPLATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162602377
     */
    COMMUNITY_WIZARD_HORIZONTAL_TEMPLATE = 'COMMUNITY_WIZARD.HORIZONTAL_TEMPLATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=51707497
     */
    SEARCH_NO_RESULTS = 'FRONT.COMMUNITY.SEARCH.NO_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=67237420
     */
    NO_COMMUNITIES_DESCRIPTION = 'ADMIN.COMMUNITIES.NO_COMMUNITIES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107653265
     */
    ADD_COMMUNITY_MANAGERS = 'ADD_COMMUNITY_MANAGERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=25903741
     */
    SETUP_SAVE_ERROR = 'ADMIN.COMMUNITY.SETUP.SAVE.ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=377143680
     */
    SPACE_MIGRATION_CONFIRMATION_TITLE = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_CONFIRMATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=377566606
     */
    SPACE_MIGRATION_SUCCESS_TITLE = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_SUCCESS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=397130661
     * TODO: SPLIT INTO TWO TRANSLATIONS
     * */
    SPACE_MIGRATION_CONFIRMATION_MESSAGE = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_CONFIRMATION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=377160302
     */
    SPACE_MIGRATION_SUCCESS = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=377228517
     */
    SPACE_MIGRATION_LINK = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=377822119
     */
    SPACE_MIGRATION_ERROR_TITLE = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_ERROR_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=380776743
     */
    SPACE_MIGRATION_ERROR_GENERIC = 'ADMIN.COMMUNITIES.SPACE_MIGRATION_ERROR_GENERIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=413573480
     */
    SPACE_FEATURE_NOT_AVAILABLE = 'ADMIN.COMMUNITIES.SPACE_FEATURE_NOT_AVAILABLE',
    /*
     * Bulk upgrade communities -> spaces
     *
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565934222
     */
    MIGRATE_COMMUNITIES_TO_SPACE = 'ADMIN.COMMUNITIES.MIGRATE_COMMUNITIES_TO_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565937053
     */
    SPACE_MIGRATIONS_CONFIRMATION_MESSAGE = 'ADMIN.COMMUNITIES.SPACE_MIGRATIONS_CONFIRMATION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565991489
     */
    SPACE_MIGRATIONS_SUCCESS = 'ADMIN.COMMUNITIES.SPACE_MIGRATIONS_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=566005968
     */
    SPACE_MIGRATIONS_SUCCESS_TITLE = 'ADMIN.COMMUNITIES.SPACE_MIGRATIONS_SUCCESS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=566666186
     */
    SPACE_MIGRATIONS_SUCCESS_INFO = 'ADMIN.COMMUNITIES.SPACE_MIGRATIONS_SUCCESS_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=566733847
     */
    SPACE_MIGRATIONS_ERROR_TITLE = 'ADMIN.COMMUNITIES.SPACE_MIGRATIONS_ERROR_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=566757131
     */
    SPACE_MIGRATIONS_ERROR_GENERIC = 'ADMIN.COMMUNITIES.SPACE_MIGRATIONS_ERROR_GENERIC',
}

enum SPACES_SPECIFIC_KEYS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242928352
     */
    DELETE_ONE_WITH_TITLE = 'SPACES.DELETE_ONE_WITH_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242930041
     */
    DELETE_NB = 'SPACES.DELETE_NB',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242935065
     */
    DELETE_NB_DESCRIPTION = 'SPACES.DELETE_NB.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242935127
     */
    DELETE_ONE = 'SPACES.DELETE_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242935185
     */
    DELETED_ONE = 'SPACES.DELETED_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242935291
     */
    DELETE_MULTIPLE = 'SPACES.DELETE_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242935738
     */
    DELETED_MULTIPLE = 'SPACES.DELETED_MULTIPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242935809
     */
    NEW_COMMUNITY = 'SPACES.NEW_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242938957
     */
    COMMUNITY_WIZARD_NEW_COMMUNITY_TITLE = 'SPACES.NEW_SPACE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242944273
     */
    COMMUNITY_WIZARD_CREATE_COMMUNITY = 'SPACES.CREATE_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242944405
     */
    SEARCH_NO_RESULTS = 'SPACES.SEARCH_NO_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=242944445
     */
    NO_COMMUNITIES_DESCRIPTION = 'SPACES.NO_SPACES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=251762769
     */
    ADD_COMMUNITY_MANAGERS = 'SPACES.ADD_MANAGERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=251989228
     */
    SETUP_SAVE_ERROR = 'SPACES.SAVED_ERROR_NOTIFICATION',
}

/** Getting spaces translate keys from space specific and community keys */
export const SPACES_ADMIN = { ...COMMUNITY_ADMIN, ...SPACES_SPECIFIC_KEYS };
