import React from 'react';

import { CommunityView, DEFAULT_VIEW } from '@lumapps/communities/constants';
import { COMMUNITIES } from '@lumapps/communities/keys';
import { communityEditLayout } from '@lumapps/communities/routes';
import { communityAnalytics } from '@lumapps/community-analytics/routes';
import { communitySettings } from '@lumapps/community-configuration/routes';
import { get as getConstants } from '@lumapps/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiPaletteAdvanced, mdiMenu, mdiChartLine, mdiSettings } from '@lumapps/lumx/icons';
import { Link as RouterLink, useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

const constants = getConstants();

export interface CommunitiesSettingsMenuEntriesProps {
    /** True if the user is feature flag is enabled */
    isNewCommunityNavigationEnabled: boolean;
    /** True if the user is allowed to edit the community */
    canEditCommunity: boolean;
    /** True if the user is allowed to see the community analytics */
    canViewCommunityAnalytics: boolean;
    communityId?: string;
    communitySlug?: string | null;
    communityTemplate?: CommunityView;
    onCommunityEdit: () => void;
    onCommunityNavigationEdit: () => void;
    /** whether this component is rendered in a legacy context or not */
    isLegacyContext?: boolean;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
}

export const CommunitiesSettingsMenuEntries: React.FC<CommunitiesSettingsMenuEntriesProps> = ({
    isNewCommunityNavigationEnabled,
    canEditCommunity,
    canViewCommunityAnalytics,
    communityId,
    communityTemplate = DEFAULT_VIEW,
    communitySlug,
    onCommunityEdit,
    onCommunityNavigationEdit,
    isLegacyContext = constants.isLegacyContext,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);
    const { redirect } = useRouter();

    if (!canEditCommunity || !communityId || !communitySlug) {
        return null;
    }

    const onEditNavigationClick = () => {
        if (!isLegacyContext) {
            // Can only edit on the legacy page
            redirect(communitySettings(communitySlug));
            return;
        }
        onCommunityNavigationEdit();
    };

    const onEditSettingsClick = () => {
        if (!isLegacyContext) {
            // Can only edit on the legacy page
            redirect(communitySettings(communitySlug));
            return;
        }
        onCommunityEdit();
    };

    return (
        <Menu.Section label={translateKey(GLOBAL.COMMUNITY)} {...get({ element: 'community', action: 'edit' })}>
            {canEditCommunity && (
                <Menu.Item
                    icon={mdiPaletteAdvanced}
                    as={RouterLink}
                    to={communityEditLayout(communityId, communityTemplate)}
                    {...get({ element: 'community-edit', action: 'layout' })}
                >
                    {translateKey(COMMUNITIES.LAYOUT)}
                </Menu.Item>
            )}
            {canEditCommunity && isNewCommunityNavigationEnabled && (
                <Menu.Item
                    icon={mdiMenu}
                    onClick={onEditNavigationClick}
                    {...get({ element: 'community-navigation-edit', action: 'edit-navigation' })}
                >
                    {translateKey(COMMUNITIES.EDIT_NAVIGATION)}
                </Menu.Item>
            )}
            {canViewCommunityAnalytics && (
                <Menu.Item
                    icon={mdiChartLine}
                    as={RouterLink}
                    to={communityAnalytics(communityId)}
                    {...get({ element: 'community-edit', action: 'analytics' })}
                >
                    {translateKey(GLOBAL.ANALYTICS)}
                </Menu.Item>
            )}
            {canEditCommunity && (
                <Menu.Item
                    icon={mdiSettings}
                    onClick={onEditSettingsClick}
                    {...get({ element: 'community-edit', action: 'settings' })}
                >
                    {translateKey(GLOBAL.SETTINGS)}
                </Menu.Item>
            )}
        </Menu.Section>
    );
};

CommunitiesSettingsMenuEntries.displayName = 'CommunitiesSettingsMenuEntries';
