import camelCase from 'lodash/camelCase';
import includes from 'lodash/includes';
import upperFirst from 'lodash/upperFirst';
import { ComponentTypes } from 'lumapps-sdk-js';

import { COMPONENT_FILE_EXTENSION, COMPONENT_FILE_LEGACY_EXTENSION } from '../constants';

const getBundleFileName = (componentType: ComponentTypes, legacyBundleName = false) =>
    `${upperFirst(camelCase(componentType))}${
        legacyBundleName ? COMPONENT_FILE_LEGACY_EXTENSION : COMPONENT_FILE_EXTENSION
    }`;

/**
 * Get the bundle identifier.
 *
 * @param componentType         Extension component type.
 * @param extensionId           Extension id.
 * @param useLegacyBundleName   Use legacy bundle extension (.lum over .js).
 */
export const getBundleIdentifier = (
    componentType: ComponentTypes,
    extensionId: string,
    useLegacyBundleName = false,
) => {
    let identifier = getBundleFileName(componentType, useLegacyBundleName);

    if (includes(extensionId, '.js') || includes(extensionId, '.mdr') || includes(extensionId, '.lum')) {
        identifier = `${extensionId}?uid=${Date.now()}`;
    }

    return identifier;
};
