import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { EVENTS } from '@lumapps/events/keys';
import { Heading, RadioButton, RadioGroup } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { DATA_SCOPE, FILTERING_VALUES } from '../../../constants';
import { WidgetEventListPropertiesType } from '../../../type';

export interface FilteringSectionProps {
    currentFiltering?: FILTERING_VALUES;
    onChange: (properties: Pick<WidgetEventListPropertiesType, 'eventDateFilter'>) => void;
}

const CLASSNAME = 'event-list-widget__settings__filtering-section';

export const FilteringSection: React.FC<FilteringSectionProps> = ({ currentFiltering, onChange }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(`${DATA_SCOPE}-filtering`);
    const { block } = useClassnames(CLASSNAME);
    const [value, setValue] = React.useState(currentFiltering || FILTERING_VALUES.ALL_EVENTS);

    const onValueChange = (newValue: FILTERING_VALUES) => {
        setValue(newValue);
        onChange({ eventDateFilter: newValue });
    };

    return (
        <section className={block([margin('vertical', 'huge')])}>
            <Heading as="h4" typography="subtitle1" className={margin('bottom', 'huge')}>
                {translateKey(GLOBAL.FILTERING)}
            </Heading>
            <RadioGroup>
                <RadioButton
                    isChecked={value === FILTERING_VALUES.ALL_EVENTS}
                    label={translateKey(EVENTS.FILTERING_ALL_EVENTS)}
                    value={FILTERING_VALUES.ALL_EVENTS}
                    onChange={onValueChange}
                    name="filtering-type"
                    {...get({ element: 'radio', action: 'all-events' })}
                />
                <RadioButton
                    isChecked={value === FILTERING_VALUES.PAST_ONLY}
                    label={translateKey(EVENTS.FILTERING_PAST_EVENTS)}
                    value={FILTERING_VALUES.PAST_ONLY}
                    onChange={onValueChange}
                    name="filtering-type"
                    {...get({ element: 'radio', action: 'past-events' })}
                />
                <RadioButton
                    isChecked={value === FILTERING_VALUES.PAST_AND_ONGOING}
                    label={translateKey(EVENTS.FILTERING_PAST_AND_ONGOING_EVENTS)}
                    value={FILTERING_VALUES.PAST_AND_ONGOING}
                    onChange={onValueChange}
                    name="filtering-type"
                    {...get({ element: 'radio', action: 'past-and-ongoing-events' })}
                />
                <RadioButton
                    isChecked={value === FILTERING_VALUES.ONGOING_ONLY}
                    label={translateKey(EVENTS.FILTERING_ONGOING_EVENTS)}
                    value={FILTERING_VALUES.ONGOING_ONLY}
                    onChange={onValueChange}
                    name="filtering-type"
                    {...get({ element: 'radio', action: 'ongoing-events' })}
                />
                <RadioButton
                    isChecked={value === FILTERING_VALUES.ONGOING_AND_FUTURE}
                    label={translateKey(EVENTS.FILTERING_ONGOING_AND_FUTURE_EVENTS)}
                    value={FILTERING_VALUES.ONGOING_AND_FUTURE}
                    onChange={onValueChange}
                    name="filtering-type"
                    {...get({ element: 'radio', action: 'ongoing-and-future-events' })}
                />
                <RadioButton
                    isChecked={value === FILTERING_VALUES.FUTURE_ONLY}
                    label={translateKey(EVENTS.FILTERING_FUTURE_EVENTS)}
                    value={FILTERING_VALUES.FUTURE_ONLY}
                    onChange={onValueChange}
                    name="filtering-type"
                    {...get({ element: 'radio', action: 'future-events' })}
                />
            </RadioGroup>
        </section>
    );
};
