export enum QUICK_SEARCH {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=117490686
     */
    HISTORY_ITEM = 'FRONT.QUICK_SEARCH.HISTORY_ITEM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=122717828
     */
    SEARCH_FOR = 'FRONT.QUICK_SEARCH.SEARCH_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90191072
     */
    HISTORY_SUGGEST = 'FRONT.QUICK_SEARCH.HISTORY_SUGGEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90191074
     */
    INTERACTION_SUGGEST = 'FRONT.QUICK_SEARCH.INTERACTION_SUGGEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205744144
     */
    RECOMMENDATIONS_SUGGEST = 'QUICK_SEARCH.RECOMMENDATIONS_SUGGEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90191075
     */
    OTHER_SUGGEST = 'FRONT.QUICK_SEARCH.OTHER_SUGGEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421393506
     */
    REMOVE_OPTION_FROM_SUGGESTIONS = 'QUICK_SEARCH.REMOVE_OPTION_FROM_SUGGESTIONS',
}
