import { CONTENT_STREAMS } from '@lumapps/content-streams/keys';
import { ContentStream, ViewMode, ViewModeEnum } from '@lumapps/content-streams/types';

import { CONTENT_STREAMS_ADMIN } from './keys';

/**
 * The default stream limit value (when creating a new stream).
 */
export const DEFAULT_STREAM_LIMIT = 3;

/**
 * The minimum stream limit value.
 */
export const MIN_STREAM_LIMIT = 1;

/**
 * The maximum stream limit value.
 */
export const MAX_STREAM_LIMIT = 10;

/**
 * Error translation keys.
 */
export const ERRORS = {
    INSTANCE_REQUIRED: CONTENT_STREAMS_ADMIN.INSTANCE_REQUIRED,
    MIN_MAX_CONTENT_DISPLAY_ERROR: CONTENT_STREAMS_ADMIN.MIN_MAX_CONTENT_DISPLAY_ERROR,
    NAME_REQUIRED: CONTENT_STREAMS_ADMIN.NAME_REQUIRED,
};

/** Prefix used in generated UID for new stream. */
export const NEW_STREAM_PREFIX = 'tmp-';

/**
 * Content stream view modes.
 */
export const VIEW_MODES: Record<ViewMode, { name: string; uid: string }> = {
    [ViewModeEnum.SPOTLIGHT]: {
        name: CONTENT_STREAMS.VIEW_MODE_SPOTLIGHT,
        uid: ViewModeEnum.SPOTLIGHT,
    },
    [ViewModeEnum.LIST]: {
        name: CONTENT_STREAMS.VIEW_MODE_LIST,
        uid: ViewModeEnum.LIST,
    },
};
export const VIEW_MODE_IDS: ViewMode[] = [ViewModeEnum.LIST, ViewModeEnum.SPOTLIGHT];

/**
 * Empty stream.
 */
export const DEFAULT_STREAM: ContentStream = {
    feedIds: [],
    filterFields: {
        categories: [],
        contentTypeIds: [],
        defaultInstanceRelatives: false,
        favoriteSitesOnly: false,
        featuredOnly: false,
        highlightedOnly: false,
        instanceIds: [],
        metadataIds: [],
        metadataList: [{}],
    },
    isViewLocked: false,
    label: {},
    limit: DEFAULT_STREAM_LIMIT,
    priority: 0,
    uid: '',
    viewMode: 'LIST',
};
