import React from 'react';

import { ArticlePickerDialog, ArticlePickerDialogProps } from '@lumapps/article-pickers/components/ArticlePickerDialog';
import { getArticles } from '@lumapps/articles/api/getArticles';
import { Article } from '@lumapps/articles/api/types';
import { ARTICLES } from '@lumapps/articles/keys';
import { mdiNewspaper } from '@lumapps/lumx/icons';
import { ColorPalette, ColorVariant, Text } from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { SelectionSetting } from '@lumapps/widget-settings/components/SelectionSetting';
import { SelectionSettingType } from '@lumapps/widget-settings/types';
import { DITA } from '@lumapps/wrex/serialization/dita/types';
import { featuredImageFromDita } from '@lumapps/wrex/serialization/dita/utils/featuredImageFromDita';

import { DATA_SCOPE } from '../../../constants';

export interface SelectionSectionProps {
    currentSelection?: SelectionSettingType;
    onChange: (properties: { selection: SelectionSettingType }) => void;
}

const CLASSNAME = 'selection-section';

export const SelectionSection: React.FC<SelectionSectionProps> = ({
    currentSelection: widgetCurrentSelection,
    onChange,
}) => {
    const { translateKey, translateObject } = useTranslate();
    const getArticleImage = (article: Article) => {
        const featuredImage = featuredImageFromDita(
            translateObject(article.structuredContent.dita?.translations) as DITA,
        );

        return makeSecuredMediaURLRelative(featuredImage?.url) || '';
    };

    const getArticleSubtitle = (article: Article) => {
        const subtitle = `${translateKey(GLOBAL.IN)} ${article.site.name} > ${article.communityReference?.name.value}`;

        return (
            <Text
                as="p"
                typography="body1"
                color={ColorPalette.dark}
                colorVariant={ColorVariant.L2}
                truncate
                title={subtitle}
            >
                {subtitle}
            </Text>
        );
    };

    const handleOnChange = (genericSelection: SelectionSettingType) => {
        onChange({
            selection: genericSelection,
        });
    };

    return (
        <SelectionSetting<Article, ArticlePickerDialogProps>
            entityBlock={{
                title: (article) => translateObject(article.structuredContent.title?.translations),
                subtitle: (article) => getArticleSubtitle(article),
                icon: mdiNewspaper,
                image: (article) => getArticleImage(article),
            }}
            entityPicker={{
                component: ArticlePickerDialog,
                props: { isMultipleSelect: true, context: 'platform', initParams: { communityIds: undefined } },
            }}
            manualButtonText={ARTICLES.SELECT_ARTICLES}
            onChange={handleOnChange}
            getEntities={getArticles}
            currentSelection={widgetCurrentSelection}
            dataScope={`${DATA_SCOPE}-selection`}
            className={CLASSNAME}
        />
    );
};
