import loFind from 'lodash/find';
import findKey from 'lodash/findKey';
import first from 'lodash/first';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import set from 'lodash/set';

import Plurals from 'make-plural';

import { standardizeTranslateObject } from '@lumapps/translations';

import { SUPPORTED_LANGS } from '../../../config';

// http://www.unicode.org/cldr/charts/latest/supplemental/language_plural_rules.html
/* eslint-disable sort-keys */
const PLURALIZE_RANGES = {
    ZERO: 'ZERO',
    ONE: 'ONE',
    TWO: 'TWO',
    FEW: 'FEW',
    MANY: 'MANY',
    OTHER: 'OTHER',
};
/* eslint-enable sort-keys */

/////////////////////////////

/* eslint-disable angular/on-watch */

/**
 * Translation Service.
 * Allow to translate a string using pascalprecht angular translate module or using an array of string.
 *
 * You must have angular-translate (pascalprecht.translate) module available to use this service.
 * Remember to declare your language in angular-translate:
 *      $translateProvider.translations('<langCode: en/fr/es, ...>',
 *      {
 *          // Your translations here.
 *      };
 *
 * Remember also to set a default, preferred and fallback language:
 *      // Set the preferred language the same that the browser language.
 *      ($translateProvider.preferredLanguage((navigator.language != null)) ?
 *          navigator.language : navigator.browserLanguage)
 *          .split("_")[0].split("-")[0]);
 *
 *      // Default language to display of any other is not available.
 *      $translateProvider.fallbackLanguage('en');
 *
 *
 * You can then use this service to translate:
 *     - an array containing languages: content['en'] = 'Test English'; content['fr'] = 'Test Français';
 *     - an angular-translate token ('SITE_TITLE');
 */

function TranslationService(ReduxStore, $injector, $location, $rootScope, $translate) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The type of the lang of the current customer.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.CUSTOMER_LANGUAGE_TYPE = 'inputCustomerLanguage';

    /**
     * The default lang of the application.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.DEFAULT_LANG = 'en';

    /**
     * The type of the lang of the current instance.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.INSTANCE_LANGUAGE_TYPE = 'inputLanguage';

    /**
     * Contains the list of all the langs supported by the currently running instance and user.
     *
     * @type {Array}
     */
    service.allLangs = [];

    /**
     *  The language we want to check a translatable object in.
     *
     * @type {string}
     */
    service.checkLanguage = undefined;

    /**
     * Mapping between LumSites lang code and Google Drive lang code.
     * This list only the differences.
     *
     * @type {Object}
     * @readonly
     */
    service.driveLangs = {
        /* eslint-disable camelcase */
        en: 'en',
        pt: 'pt-PT',
        pt_BR: 'pt-BR',
        pt_PT: 'pt-PT',
        pt_br: 'pt-BR',
        pt_pt: 'pt-PT',
        'pt-br': 'pt-BR',
        'pt-pt': 'pt-PT',
        zh: 'zh-CN',
        zh_CN: 'zh-CN',
        zh_TW: 'zh-TW',
        zh_cn: 'zh-CN',
        zh_tw: 'zh-TW',
        'zh-cn': 'zh-CN',
        'zh-tw': 'zh-TW',
        /* eslint-enable camelcase */
    };

    /**
     * Mapping between LumSites lang code and Froala lang code.
     * This list only the differences.
     *
     * @type {Object}
     * @readonly
     */
    service.froalaLangs = {
        /* eslint-disable camelcase */
        en: 'en_us',
        en_US: 'en_us',
        'en-US': 'en_us',
        'en-us': 'en_us',
        pt: 'pt_pt',
        pt_BR: 'pt_br',
        pt_PT: 'pt_pt',
        'pt-BR': 'pt_br',
        'pt-PT': 'pt_pt',
        'pt-br': 'pt_br',
        'pt-pt': 'pt_pt',
        zh: 'zh_cn',
        zh_CN: 'zh_cn',
        zh_TW: 'zh_tw',
        'zh-CN': 'zh_cn',
        'zh-TW': 'zh_tw',
        'zh-cn': 'zh_cn',
        'zh-tw': 'zh_tw',
        /* eslint-enable camelcase */
    };

    /**
     * The language in which we want to fill a content when inputting text.
     *
     * @type {string}
     */
    service.inputLanguage = undefined;

    /**
     * The language in which we want to fill a customer content when inputting text.
     *
     * @type {string}
     */
    service.inputCustomerLanguage = 'en';

    /* eslint-disable camelcase */
    /**
     * Mapping between LumSites lang code and MomentJS lang code.
     * This list only the differences.
     *
     * @type {Object}
     * @readonly
     */
    service.momentLangs = {
        en: 'en',
        pt_BR: 'pt-br',
        pt_PT: 'pt',
        pt_br: 'pt-br',
        pt_pt: 'pt',
        'pt-BR': 'pt-br',
        'pt-PT': 'pt',
        'pt-pt': 'pt',
        zh: 'zh-cn',
        zh_CN: 'zh-cn',
        zh_CT: 'zh-tw',
        zh_TW: 'zh-tw',
        zh_cn: 'zh-cn',
        zh_ct: 'zh-tw',
        zh_tw: 'zh-tw',
        'zh-CN': 'zh-cn',
        'zh-CT': 'zh-tw',
        'zh-TW': 'zh-tw',
        'zh-ct': 'zh-tw',
        'zh-tw': 'zh-tw',
    };
    /* eslint-enable camelcase */

    /**
     * Indicates if we want to synchronize all translations of a content at once.
     *
     * @type {boolean}
     */
    service.syncTranslations = false;

    /**
     * The translation table.
     * It contains all available statics translations.
     *
     * @type {Object}
     */
    service.translationsTable = {};

    /**
     * The preferred user's contribution languages to translate the contents'.
     */
    service.userContributionLanguages = [];

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Returns the different type of languages defined on the app, adapted for Google Drive.
     *
     * @param  {string} [type=current] The language type we want to get.
     *                                 Possible values are: 'current', 'preferred'/'default' or 'fallback'.
     * @return {string} The language in use.
     */
    function _getLangForDrive(type) {
        type = type || 'current';

        const lang = service.getLang(type);

        if (angular.isDefined(lang) && angular.isDefinedAndFilled(service.driveLangs[lang])) {
            return service.driveLangs[lang];
        } else if (SUPPORTED_LANGS.indexOf(lang) > -1) {
            return lang;
        }

        return service.driveLangs.en;
    }

    /**
     * Returns the different type of languages defined on the app, adapted for Froala.
     *
     * @param  {string} [type=current] The language type we want to get.
     *                                 Possible values are: 'current', 'preferred'/'default' or 'fallback'.
     * @return {string} The language in use.
     */
    function _getLangForFroala(type) {
        type = type || 'current';

        const lang = service.getLang(type);

        if (angular.isDefined(lang) && angular.isDefinedAndFilled(service.froalaLangs[lang])) {
            return service.froalaLangs[lang];
        } else if (SUPPORTED_LANGS.indexOf(lang) > -1) {
            return lang;
        }

        return service.froalaLangs.en;
    }

    /**
     * Returns the different type of languages defined on the app, adapted for MomentJS.
     *
     * @param  {string} [type=current] The language type we want to get.
     *                                 Possible values are: 'current', 'preferred'/'default' or 'fallback'.
     * @return {string} The language in use.
     */
    function _getLangForMoment(type) {
        type = type || 'current';

        const lang = service.getLang(type);

        if (angular.isDefined(lang) && angular.isDefinedAndFilled(service.momentLangs[lang])) {
            return service.momentLangs[lang];
        } else if (SUPPORTED_LANGS.indexOf(lang) > -1) {
            return lang;
        }

        return service.momentLangs.en;
    }

    /**
     * Check whether the given language is part of the instance languages or not.
     *
     * @param   {string} givenLanguage Given language.
     * @returns {boolean} Whether the given language is part of the instance languages or not.
     */
    function isLanguageInInstanceLanguages(givenLanguage) {
        const Instance = $injector.get('Instance');
        const instanceLanguages = Instance.getCurrentInstanceLangs();

        return angular.isDefinedAndFilled(instanceLanguages) && includes(instanceLanguages, givenLanguage);
    }

    /**
     * If translatedContent starts with 'LUMSITES_TRANSLATE_' it means that we want to use a
     * translation key placed afterwards. This is surely a trick used in a place I don't know unfortunately.
     * If it does not starts with that; simply return the value.
     *
     * @param   {string} translatedContent String we want to check.
     * @returns {string} Translation value returned.
     */
    function getTranslatedContent(translatedContent) {
        return angular.isString(translatedContent) && translatedContent.indexOf('LUMSITES_TRANSLATE_') === 0
            ? $translate.instant(translatedContent.replace('LUMSITES_TRANSLATE_', '').toUpperCase())
            : translatedContent;
    }

    /**
     * Find the translation for the wanted language in the content object.
     *
     * @param  {Object}        content                       The translatable content as a translatable object
     *                                                       ({ Locale: string })
     * @param  {Object|string} [defaultText]                 The default text in case we don't find any translation
     *                                                       in the given lang.
     * @param  {string}        [lang=current]                The lang to translate to.
     * @param  {boolean}       [forceFallback=false]         Indicate if you want to force the fallback language.
     * @param  {boolean}       [dontUseFirstAvailable=false] Indicate if you don't want to use the first translation
     *                                                       available if no other is available.
     * @param  {boolean}       [fallbackWithLang=false]      Indicates if you want to fallback even when providing a
     *                                                       lang.
     * @return {string}        The translated content.
     */
    function _translateFromObject(content, defaultText, lang, forceFallback, dontUseFirstAvailable, fallbackWithLang) {
        /**
         * Apply language standardisation on the TranslateObject
         * This is required when we're using translateObject with lang formated in V2 format.
         * @example: standardizeTranslateObject({`fr-CA`: 'text'}) return { `fr-CA: 'text', `fr_ca`: 'text' }
         * For all locals that have regions, we duplicate the key to have both V1 and V2 format available.
         * */
        content = standardizeTranslateObject(content);

        if (angular.isDefinedAndFilled(lang)) {
            const translation = content[lang];

            if (angular.isDefinedAndFilled(translation)) {
                return translation;
            }

            return fallbackWithLang
                ? _translateFromObject(
                      content,
                      defaultText,
                      undefined,
                      forceFallback,
                      dontUseFirstAvailable,
                      fallbackWithLang,
                  )
                : '';
        }

        const currentLang = angular.isDefinedAndFilled(lang) ? lang : $translate.use();
        const preferredLang = $translate.preferredLanguage();
        const fallbackLang = $translate.fallbackLanguage();
        if (isLanguageInInstanceLanguages(currentLang) && angular.isDefinedAndFilled(content[currentLang])) {
            return getTranslatedContent(content[currentLang]);
        }

        if ((angular.isUndefinedOrEmpty(lang) || forceFallback) && isLanguageInInstanceLanguages(preferredLang) && angular.isDefinedAndFilled(content[preferredLang])) {
            return getTranslatedContent(content[preferredLang]);
        }

        if ((angular.isUndefinedOrEmpty(lang) || forceFallback) && isLanguageInInstanceLanguages(fallbackLang) && angular.isDefinedAndFilled(content[fallbackLang])) {
            return getTranslatedContent(content[fallbackLang]);
        }

        if (!dontUseFirstAvailable) {
            // We were not able to find a proper translation in the users' preferred languages.
            // Try to use the default site language since it becomes the best choice for the current user.
            const Instance = $injector.get('Instance');
            const currentInstance = Instance.getInstance();
            if (angular.isDefinedAndFilled(currentInstance.defaultLang) && angular.isDefinedAndFilled(content[currentInstance.defaultLang])) {
                return getTranslatedContent(content[currentInstance.defaultLang]);
            }

            // Not even the default site language matches; get all locale for the current content
            // and use the first one that matches the current site's languages.
            for (const locale in content) {
                if (angular.isString(locale) && isLanguageInInstanceLanguages(locale) && angular.isDefinedAndFilled(content[locale])) {
                    return getTranslatedContent(content[locale]);
                }
            }

            // No current site language is available, before looking into all translations;
            // try with the default language.
            if (angular.isDefinedAndFilled(content[service.DEFAULT_LANG])) {
                return getTranslatedContent(content[service.DEFAULT_LANG]);
            }

            // Extreme last resort, return the first translation available if there is one working.
            for (const locale in content) {
                if (angular.isString(locale) && angular.isDefinedAndFilled(content[locale])) {
                    return getTranslatedContent(content[locale]);
                }
            }
        }

        if (angular.isDefinedAndFilled(defaultText)) {
            return service.translate(defaultText, '', lang);
        }

        // Return at least a string.
        return '';
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Find the language id in the content object.
     *
     * @param {Object} content The content as a translatable object.
     */
    function getLangIdForContent(content) {
        const currentLang = $translate.use();
        const preferredLang = $translate.preferredLanguage();
        const fallbackLang = $translate.fallbackLanguage();

        if (angular.isDefinedAndFilled(content[currentLang])) {
            return currentLang;
        }

        if (angular.isDefinedAndFilled(content[preferredLang])) {
            return preferredLang;
        }

        if (angular.isDefinedAndFilled(content[fallbackLang])) {
            return fallbackLang;
        }

        // Return other lang if there is one with a value.
        // eslint-disable-next-line no-restricted-syntax
        for (const locale in content) {
            if (angular.isString(locale) && angular.isDefinedAndFilled(content[locale])) {
                return locale;
            }
        }

        return undefined;
    }

    /**
     * Get the first language code that has a translation in a translatable.
     *
     * @param  {Object|string} translatable The translatable.
     * @return {string}        The first language that has a translation in the given translatable.
     */
    function getFirstLangWithValue(translatable) {
        if (angular.isUndefinedOrEmpty(translatable)) {
            return undefined;
        }

        if (angular.isString(translatable)) {
            return service.getLang('current');
        }

        return findKey(
            translatable,
            (translation, key) => key !== 'undefined' && angular.isDefinedAndFilled(translation),
        );
    }

    /**
     * Return the current input language.
     * Used by react components to display widget content in the right language depending on the lang defined in
     * designer.
     *
     * @return {string} The current input language.
     */
    function getInputLanguage() {
        return service.inputLanguage;
    }

    /**
     * Returns the different type of languages defined on the app.
     *
     * @param  {string}        [type='all']      The language type we want to get.
     *                                           Possible values are: "current", "preferred"/"default" or
     *                                           "fallback".
     * @param  {string}        [ctxt="lumsites"] The context in which the lang will be used since different
     *                                           libraries use different locales.
     *                                           Possible values are: "drive", "froala", "moment"/"momentjs" or
     *                                           "lumsites".
     * @return {Object|string} The language(s) in use.
     *                         Either a string (if a type is provided) or an array of all languages indexed by the
     *                         language type ("current", "preferred"/"default" or "fallback").
     */
    function getLang(type, ctxt) {
        if (angular.isDefinedAndFilled(ctxt)) {
            ctxt = ctxt.toLowerCase();
            if (
                ctxt === 'drive' ||
                ctxt === 'gdrive' ||
                ctxt === 'googledrive' ||
                ctxt === 'google-drive' ||
                ctxt === 'google drive'
            ) {
                return _getLangForDrive(type);
            }
            if (ctxt === 'froala') {
                return _getLangForFroala(type);
            }
            if (ctxt === 'moment' || ctxt === 'momentjs' || ctxt === 'moment-js' || ctxt === 'moment js') {
                return _getLangForMoment(type);
            }
        }

        type = type || 'all';
        type = type.toLowerCase();

        switch (type) {
            case 'current':
                return $translate.use() || $translate.fallbackLanguage();

            case 'preferred':
            case 'default':
                return $translate.preferredLanguage();

            case 'fallback':
                return $translate.fallbackLanguage();

            default: {
                const languages = {};
                languages.current = service.getLang('current');
                languages.default = service.getLang('preferred');
                languages.preferred = service.getLang('preferred');
                languages.fallback = service.getLang('fallback');

                return languages;
            }
        }
    }

    /**
     * Return all the languages available in the translate provider.
     *
     * @param  {Array} [exclude] A list of languages to exclude from the list.
     * @return {Array} All the available languages (except for the excluded ones).
     */
    function getLangs(exclude) {
        exclude = exclude || [];
        exclude = angular.isArray(exclude) ? exclude : [exclude];

        return $translate.getAvailableLanguageKeys().diff(exclude);
    }

    /**
     * Get the content matching locale.
     *
     * @param  {Object} content The content to get the locale from.
     * @return {string} The relevant locale.
     */
    function getRelevantLocale(content) {
        const userLangs = getLang('all');

        if (angular.isObject(content)) {
            const keys = Object.keys(content);

            // Check if content match any of user langs available and return matching locale.
            for (const key in userLangs) {
                if (userLangs.hasOwnProperty(key) && includes(keys, userLangs[key])) {
                    return userLangs[key];
                }
            }

            // In fallback return first available lang.
            return first(keys);
        }

        return userLangs.fallback;
    }

    /**
     * Get the translations table.
     *
     * @return {Object} The translations table.
     */
    function getTranslationsTable() {
        return service.translationsTable;
    }

    /**
     * Check if a content has a translation in a lang.
     *
     * @param  {Object|string} content        The content we want to check.
     *                                        This can be either a string or a translatable object ({ Locale: string }).
     * @param  {string}        [lang=current] The language we want to check.
     *                                        It's also possible to pass: 'current', 'preferred'/'default' or
     *                                        'fallback' to check in the corresponding lang.
     *                                        It's also possible to pass 'any' to check for a translatable object
     *                                        that there is at least one translation.
     * @return {boolean}       If the content has a translation or not.
     */
    function hasTranslation(content, lang) {
        if (angular.isUndefinedOrEmpty(content)) {
            return false;
        }

        lang = angular.isDefinedAndFilled(lang) ? lang.toLowerCase() : service.getLang('current');

        switch (lang) {
            case 'current':
            case 'preferred':
            case 'default':
            case 'fallback':
                lang = service.getLang(lang);

                break;

            case 'instance': {
                const Instance = $injector.get('Instance');
                lang = Instance.getCurrentInstanceLangs();

                break;
            }

            case 'any':
                lang = undefined;

                break;

            default:
                break;
        }
        lang = angular.isDefinedAndFilled(lang) && !angular.isArray(lang) ? [lang] : lang;
        const len = angular.isDefinedAndFilled(lang) ? lang.length : 0;
        lang = len === 0 ? undefined : lang;

        if (angular.isString(content)) {
            if (angular.isUndefinedOrEmpty(lang)) {
                return angular.isDefinedAndFilled(
                    loFind(service.getTranslationsTable(), (translations) =>
                        angular.isDefinedAndFilled(translations[content]),
                    ),
                );
            }

            for (let i = 0; i < len; i++) {
                if (angular.isDefinedAndFilled(get(service.getTranslationsTable(), `${lang[i]}.${content}`))) {
                    return true;
                }
            }

            return false;
        }

        if (angular.isObject(content)) {
            if (angular.isUndefinedOrEmpty(lang)) {
                for (const locale in content) {
                    if (angular.isDefinedAndFilled(content[locale])) {
                        return true;
                    }
                }
            } else {
                for (let j = 0; j < len; j++) {
                    if (angular.isDefinedAndFilled(content[lang[j]])) {
                        return true;
                    }
                }
            }

            return false;
        }

        return false;
    }

    /**
     * Check if a content has any translation.
     *
     * @param  {Object|string} content              The content we want to check.
     *                                              This can be either a string or a translatable object
     *                                              ({ Locale: string }).
     * @param  {boolean}       [instanceOnly=false] Indicates if we want to check only the languages allowed in the
     *                                              instance.
     * @return {boolean}       If the content has any translation or not.
     */
    function hasTranslations(content, instanceOnly) {
        instanceOnly = Boolean(instanceOnly);

        return service.hasTranslation(content, instanceOnly ? 'instance' : 'any');
    }

    /**
     * Given a language, check that this language match the current instance langs
     * and return the right language for the contribution.
     *
     * @param {string} givenLanguage The given language to check.
     * @param {Object} instance      The current instance.
     * @return {string}              The current input language.
     */
    function getInputLanguageValue(givenLanguage, instance) {
        if (angular.isUndefinedOrEmpty(get(instance, 'langs'))) {
            return givenLanguage;
        }
        const preferredLang = service.getLang('preferred');
        const fallbackLang = service.getLang('fallback');
        let inputLanguageValue;

        // Check if the current language exists.
        if (includes(instance.langs, givenLanguage)) {
            inputLanguageValue = givenLanguage;
            // Check if the preferred language exists.
        } else if (includes(instance.langs, preferredLang)) {
            inputLanguageValue = preferredLang;
            // Check if the default lang exists for the instance.
        } else if (angular.isDefinedAndFilled(instance.defaultLang) && includes(instance.langs, instance.defaultLang)) {
            inputLanguageValue = instance.defaultLang;
            // Check if the fallback language exists.
        } else if (includes(instance.langs, fallbackLang)) {
            inputLanguageValue = fallbackLang;
            // Else set the input language to the first element of the instance langs.
        } else {
            inputLanguageValue = instance.langs[0];
        }

        return inputLanguageValue;
    }

    /**
     * Contribution languages are calculated by the backend.
     * Given the user's interface language it checks whether user's languages are in the site or not.
     * If it isn't, it fallback to the site default language if defined or the site languages available.
     *
     * @returns {string[]} list of user's contribution language.
     */
    function getContributionLanguages() {
        if (!service.userContributionLanguages.length) {
            service.userContributionLanguages = window.USER_CONTRIBUTION_LANGS
                ? window.USER_CONTRIBUTION_LANGS.split(',')
                : [service.defaultLang];
        }

        return service.userContributionLanguages;
    }

    /**
     * Preferred contribution is the first contribution language available, given the contribution
     * languages calculated by the backend.
     *
     * @returns {string} preferred user's contribution language.
     */
    function getPreferredContributionLanguage() {
        return getContributionLanguages()[0] ?? service.defaultLang;
    }

    /**
     * Initialize the translation service languages for an instance.
     *
     * @param {Object} instance The instance we want to initialize the languages.
     */
    function initLanguages(instance) {
        if (angular.isUndefinedOrEmpty(get(instance, 'langs'))) {
            return;
        }
        const currentLang = service.getLang('current');

        // Set the inputLanguage (instance) and the inputCustomerLanguage.
        const intialInputLanguage = getInputLanguageValue(currentLang, instance);

        service.inputLanguage = angular.fastCopy(intialInputLanguage);
        service.inputCustomerLanguage = angular.fastCopy(intialInputLanguage);
    }

    /**
     * Return the placeholder for a translatable object.
     * If the translatable content has no translation, display a fixed placeholder.
     * Else display the value of the translatable content in the "checkLanguage".
     * Else, display the first available translation.
     *
     * @param  {string|Object} content            The translatable content.
     * @param  {string|Object} defaultPlaceholder The placeholder to use if the translatable content has no
     *                                            translations.
     * @param  {string}        [lang=current]     The lang to use.
     * @return {string}        The placeholder.
     */
    function placeholder(content, defaultPlaceholder, lang) {
        if (service.hasTranslations(content)) {
            return service.translate_(content, service.checkLanguage);
        }

        return service.translate_(defaultPlaceholder, lang);
    }

    /**
     * Replace the replacement token '%XXXX%' in a translatable content.
     *
     * @param  {Object|string}       content                       The translatable content.
     * @param  {string|Array}        tokens                        The tokens to replace in the translated content.
     * @param  {string|Object|Array} [replacements]                The replacement for the token.
     *                                                             Order is important, so use the same order as the
     *                                                             tokens.
     *                                                             The replacements can be translatable contents.
     *                                                             If none is given, tokens will simply be removed.
     * @param  {string}              [lang=current]                The lang in which translate the content and the
     *                                                             replacements.
     * @param  {boolean}             [forceFallback=false]         Indicates if you want to force the fallback
     *                                                             language.
     * @param  {boolean}             [dontUseFirstAvailable=false] Indicates if you don't want to use the first
     *                                                             translation available if no other is available.
     * @param  {boolean}             [leave=false]                 Indicates if we want to leave the token (without
     *                                                             the "%") instead of removing it when no
     *                                                             replacement is found. If the token is left, we
     *                                                             try to translate it in last resort.
     * @param  {boolean}             [fallBackWithLang=false]      Indicates if you want to fallback even when
     *                                                             providing a lang.
     * @return {string}              The translated and replaced content.
     */
    function replace(
        content,
        tokens,
        replacements,
        lang,
        forceFallback,
        dontUseFirstAvailable,
        leave,
        fallBackWithLang,
    ) {
        let translatedContent;

        if (angular.isUndefinedOrEmpty(content)) {
            return translatedContent;
        }

        translatedContent = service.translate_(content, lang, fallBackWithLang);

        if (angular.isUndefinedOrEmpty(tokens)) {
            return translatedContent;
        }

        tokens = angular.isArray(tokens) ? tokens : [tokens];

        replacements = replacements || [];

        if (!angular.isArray(replacements)) {
            const value = replacements;
            replacements = [];

            angular.forEach(tokens, (token, index) => {
                replacements[index] = value;
            });
        }

        angular.forEach(tokens, (token, index) => {
            token = token.toUpperCase();

            let replaceBy = replacements[index];
            if (angular.isUndefined(replaceBy)) {
                replaceBy = leave ? service.translate_(token, lang, fallBackWithLang) : '';
            }
            replaceBy = angular.isNumber(replaceBy) ? String(replaceBy) : replaceBy;

            translatedContent = translatedContent.replace(
                `%${token}%`,
                service.translate(replaceBy, undefined, lang, forceFallback, dontUseFirstAvailable, fallBackWithLang),
            );
        });

        return translatedContent;
    }

    /**
     * Set the language for the application.
     *
     * @param {string} [type=current] The language type we want to get.
     *                                Possible values are: 'current', 'preferred'/'default' or 'fallback'.
     * @param {string} lang           The lang to set.
     */
    function setLang(type, lang) {
        type = type || 'current';
        type = type.toLowerCase();

        switch (type) {
            case 'current':
                $translate.use(lang);
                if (lang === 'ja') {
                    moment.updateLocale('ja', {
                        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    });
                }
                moment.locale(_getLangForMoment('current'));

                $rootScope.$broadcast('translation:setLang', lang);

                break;

            case 'preferred':
            case 'default':
                $translate.preferredLanguage(lang);

                break;

            case 'fallback':
                $translate.fallbackLanguage(lang);

                break;

            default:
                break;
        }
    }

    /**
     * Set the translations table.
     *
     * @param {Object} table The translations table.
     */
    function setTranslationsTable(table) {
        service.translationsTable = table;
    }

    /**
     * Swap two translations in a translated content.
     *
     * @param {Object} target                               The translated content.
     * @param {string} destination                          The destination lang.
     * @param {string} [source=<Translation.inputLanguage>] The source lang.
     */
    function swap(target, destination, source) {
        source = source || service.inputLanguage;

        set(target, destination, angular.fastCopy(get(target, source)));
        delete target[source];
    }

    /**
     * Translates a content.
     * If the content is a string, try to translate it automatically with the $translate provider and the statics
     * translations table.
     * Else, check if any of the language (in use, preferred and fallback) exists in the object and use it for the
     * translation.
     * If there is no translation for any of these 3 languages, display a default text.
     *
     * @param  {Object|string} content                       The content to translate. Either a translatable object
     *                                                       ({ Locale: string }) or a string.
     * @param  {Object|string} [defaultText]                 The text to use in case of a missing translation.
     * @param  {string}        [lang=current]                The lang in which we want the translation.
     * @param  {boolean}       [forceFallback=false]         Indicate if you want to force the fallback language.
     * @param  {boolean}       [dontUseFirstAvailable=false] Indicate if you don't want to use the first translation
     *                                                       available if no other is available.
     * @param  {boolean}       [fallBackWithLang=false]      Indicates if you want to fallback even when providing a
     *                                                       lang.
     * @return {string}        The translated content.
     */
    function translate(content, defaultText, lang, forceFallback, dontUseFirstAvailable, fallBackWithLang) {
        let translation = '';

        if (angular.isDefinedAndFilled(content)) {
            if (!angular.isString(content)) {
                if (angular.isDefinedAndFilled(content.translations)) {
                    content = content.translations;
                }

                if (angular.isDefinedAndFilled(content)) {
                    return _translateFromObject(
                        content,
                        defaultText,
                        lang,
                        forceFallback,
                        dontUseFirstAvailable,
                        fallBackWithLang,
                    );
                }

                return '';
            }

            if (angular.isDefined($location.search().debugLang)) {
                return content;
            }

            const oldLang = $translate.use();
            const currentLang = angular.isDefinedAndFilled(lang) ? lang : oldLang;
            if (currentLang !== oldLang) {
                $translate.use(currentLang);
            }

            translation = $translate.instant(content);

            if (currentLang !== oldLang) {
                $translate.use(oldLang);
            }
        }

        return translation.trim();
    }

    /**
     * Quick shortcut to the translate function, without any superfluous parameters.
     *
     * @param  {Object|string} content                  The content to translate. Either a translatable object
     *                                                  ({ Locale: string }) or a string.
     * @param  {string}        [lang=current]           The lang in which we want the translation.
     * @param  {boolean}       [fallBackWithLang=false] Indicates if you want to fallback even when providing a
     *                                                  lang.
     * @return {string}        The translated content.
     */
    function translate_(content, lang, fallBackWithLang) {
        return service.translate(content, undefined, lang, false, false, fallBackWithLang);
    }

    /**
     * Translate and pluralize a key.
     *
     * @param  {string}        content          The translation key.
     * @param  {number|string} count            The number or the range to used to pluralize translation.
     * @param  {string}        lang             The lang in which we want the translation.
     * @param  {boolean}       fallBackWithLang Indicates if you want to fallback even when providing a
     *                                         lang.
     * @return {string}        The translated content.
     */
    const pluralize = (content, count = 1, lang = $translate.use(), fallBackWithLang = false) => {
        let translations = translate_(content, lang, fallBackWithLang);

        if ($location.search().debugLang) {
            return translations;
        }

        try {
            if (typeof translations === 'string') {
                translations = JSON.parse(translations);
            }

            let pluralLang = lang;
            // pt_br or zh_tw are not known by Plurals
            if (lang === 'pt_br') {
                pluralLang = 'br';
            } else if (lang === 'zh_tw') {
                pluralLang = 'zh';
            }
            const pluralsMethod = Plurals[pluralLang] ?? Plurals[service.DEFAULT_LANG];
            // check if count is either ONE, FEW or a real number like 10
            const range =
                typeof count === 'string' && has(PLURALIZE_RANGES, count.toUpperCase())
                    ? count.toUpperCase()
                    : pluralsMethod(Number.parseInt(count, 10));

            return translations[range] || translations.other;
        } catch (err) {
            return content;
        }
    };

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        return {
            current: service.getLang('current'),
            default: service.getLang('preferred'),
            fallback: service.getLang('fallback'),
            inputCustomerLanguage: service.inputCustomerLanguage || service.inputLanguage,
            inputLanguage: service.inputLanguage,
            contributionLanguages: getContributionLanguages(),
            preferred: service.getLang('preferred'),
        };
    }

    /**
     * Triggered when synced data is changed by redux.
     * It takes in the new state and should update the Angular service accordingly.
     * @param {reduxState} state The part of the state that this service is concerned about.
     */
    function mapReduxToAngular(state) {
        const { current, preferred, fallback, inputCustomerLanguage, inputLanguage } = state;
        if (
            angular.isUndefinedOrEmpty(current) &&
            angular.isUndefinedOrEmpty(preferred) &&
            angular.isUndefinedOrEmpty(fallback) &&
            angular.isUndefinedOrEmpty(inputCustomerLanguage) &&
            angular.isUndefinedOrEmpty(inputLanguage)
        ) {
            return;
        }
        service.setLang('current', state.current);
        service.setLang('default', state.preferred);
        service.setLang('preferred', state.preferred);
        service.setLang('fallback', state.fallback);
        service.inputCustomerLanguage = state.inputCustomerLanguage;
        service.inputLanguage = state.inputLanguage;
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'locale';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'locale/update';

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;
    service.mapReduxToAngular = mapReduxToAngular;

    /////////////////////////////

    service.getFirstLangWithValue = getFirstLangWithValue;
    service.getLang = getLang;
    service.getLangs = getLangs;
    service.getLangIdForContent = getLangIdForContent;
    service.getInputLanguage = getInputLanguage;
    service.getPreferredContributionLanguage = getPreferredContributionLanguage;
    service.getRelevantLocale = getRelevantLocale;
    service.getTranslationsTable = getTranslationsTable;
    service.hasTranslation = hasTranslation;
    service.hasTranslations = hasTranslations;
    service.initLanguages = initLanguages;
    service.placeholder = placeholder;
    service.replace = replace;
    service.setLang = setLang;
    service.setTranslationsTable = setTranslationsTable;
    service.swap = swap;
    service.translate = translate;
    service.translate_ = translate_;
    service.pluralize = pluralize;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes made to the used language in PascalPrecht translate provider.
     */
    $rootScope.$watch(
        () => $translate.use(),
        (newLang) => {
            if (angular.isUndefinedOrEmpty(newLang)) {
                return;
            }

            const Instance = $injector.get('Instance');
            service.inputLanguage = getInputLanguageValue(newLang, Instance.getInstance());
            service.checkLanguage = newLang;

            service.syncTranslations = false;
        },
    );

    /**
     * Initialize the service.
     */
    service.init = function init() {
        // Enable Redux sync.
        ReduxStore.subscribe(service, true);
    };
}

/////////////////////////////

angular.module('Services').service('Translation', TranslationService);

/////////////////////////////

function TransFilter(Translation) {
    'ngInject';

    /**
     * Translation Filter.
     *
     * @param  {string}        content                  The content to translate. Either a translatable object
     *                                                  ({ Locale: string }) or a string.
     * @param  {Object|string} [defaultText]            The text to use in case of a missing translation.
     * @param  {string}        [lang=current]           The lang to translate to.
     * @param  {boolean}       [fallBackWithLang=false] Indicates if you want to fallback even when providing a
     *                                                  lang.
     * @return {string}        The translated content.
     */
    return (content, defaultText, lang, fallBackWithLang) => {
        if (angular.isDefinedAndFilled(content)) {
            return Translation.translate(content, defaultText, lang, false, false, fallBackWithLang);
        }

        return angular.isString(content) ? content : '';
    };
}

angular.module('Filters').filter('trans', TransFilter);

/////////////////////////////

function TransRepFilter(Translation) {
    'ngInject';

    /**
     * Translation Replace Filter.
     *
     * @param  {string}              content                  The content to translate and replace.
     *                                                        Either a translatable object ({ Locale: string }) or a
     *                                                        string.
     * @param  {string|Array}        tokens                   The tokens to replace in the translated content.
     * @param  {string|Object|Array} [replacements]           The replacement for the token.
     *                                                        Order is important, so use the same order as the
     *                                                        tokens.
     *                                                        The replacements can be translatable contents.
     *                                                        If none is given, tokens will simply be removed.
     * @param  {string}              [lang=current]           The lang in which translate the content and the
     *                                                        replacements.
     * @param  {boolean}             [fallBackWithLang=false] Indicates if you want to fallback even when providing
     *                                                        a lang.
     * @return {string}              The translated and replaced content.
     */
    return (content, tokens, replacements, lang, fallBackWithLang) => {
        if (angular.isDefinedAndFilled(content)) {
            return Translation.replace(content, tokens, replacements, lang, false, false, false, fallBackWithLang);
        }

        return content;
    };
}

angular.module('Filters').filter('transRep', TransRepFilter);

/////////////////////////////

export { TransFilter, TransRepFilter, TranslationService };
