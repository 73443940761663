import React from 'react';

import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import { GenericBlock, Icon, Text } from '@lumapps/lumx/react';

import { ToolbarItem as ToolbarItemProps } from '../../types';

export const ToolbarItem = React.forwardRef<HTMLElement, ToolbarItemProps>((props, ref) => {
    const {
        type,
        label,
        tooltipLabel,
        itemKey: key,
        icon,
        isDisabled,
        'aria-disabled': ariaDisabled = false,
        otherProps,
        verticalModeProps: inMenuOverrideProps,
        inVerticalMode,
    } = props;
    const helper = props.helper || inMenuOverrideProps?.helper;

    if (type === 'divider') {
        return <Menubar.Divider {...otherProps} />;
    }

    if (type === 'option') {
        const { onClick, rowIndex } = props;
        const appliedLabel = (inVerticalMode && inMenuOverrideProps?.label) || label;

        if (inVerticalMode && helper) {
            return (
                <Menubar.Item
                    ref={ref}
                    key={key}
                    onClick={onClick}
                    isDisabled={isDisabled}
                    aria-disabled={ariaDisabled}
                    rovingTabIndexOptions={{ rowIndex }}
                    aria-label={!appliedLabel ? tooltipLabel : undefined}
                    tooltipLabel={undefined}
                    {...otherProps}
                >
                    <GenericBlock figure={icon && <Icon icon={icon} size="xs" />}>
                        <Text as="span" truncate title={appliedLabel}>
                            {appliedLabel}
                        </Text>
                        <Text as="span" color="dark" colorVariant="L2" truncate title={helper}>
                            {helper}
                        </Text>
                    </GenericBlock>
                </Menubar.Item>
            );
        }

        return (
            <Menubar.Item
                ref={ref}
                key={key}
                tooltipLabel={tooltipLabel}
                aria-label={!appliedLabel ? tooltipLabel : undefined}
                icon={icon}
                onClick={onClick}
                isDisabled={isDisabled}
                aria-disabled={ariaDisabled}
                rovingTabIndexOptions={{ rowIndex }}
                {...otherProps}
                {...(inVerticalMode ? inMenuOverrideProps : {})}
            >
                {appliedLabel}
            </Menubar.Item>
        );
    }

    if (type === 'toggle-option') {
        const { isChecked, onClick } = props;
        const appliedLabel = (inVerticalMode && inMenuOverrideProps?.label) || label;

        return (
            <Menubar.ItemCheckbox
                ref={ref}
                key={key}
                tooltipLabel={tooltipLabel}
                aria-label={!appliedLabel ? tooltipLabel : undefined}
                icon={icon}
                onClick={onClick}
                isDisabled={isDisabled}
                aria-disabled={ariaDisabled}
                isChecked={isChecked}
                {...otherProps}
                {...(inVerticalMode ? inMenuOverrideProps : {})}
            >
                {appliedLabel}
            </Menubar.ItemCheckbox>
        );
    }

    if (type === 'submenu') {
        const { childrenOptions } = props;
        const appliedLabel = (inVerticalMode && inMenuOverrideProps?.label) || label;

        return (
            <Menubar.ItemSubmenu
                ref={ref}
                key={`${label}-submenu`}
                label={appliedLabel}
                icon={icon}
                isDisabled={isDisabled}
                aria-disabled={ariaDisabled}
                tooltipLabel={tooltipLabel}
                aria-label={!appliedLabel ? tooltipLabel : undefined}
                {...otherProps}
                {...(inVerticalMode ? inMenuOverrideProps : {})}
            >
                {childrenOptions.map(({ itemKey: childKey, ...child }) => (
                    <ToolbarItem inVerticalMode key={childKey} itemKey={childKey} {...child} />
                ))}
            </Menubar.ItemSubmenu>
        );
    }

    if (type === 'section') {
        const { childrenOptions } = props;
        const { columns, ...forwardedOtherProps } = otherProps || {};

        const calculateRowIndex = (index: number) => {
            if (columns) {
                return Math.trunc(index / columns);
            }
            return undefined;
        };

        return (
            <Menubar.Section label={label} ref={ref} {...forwardedOtherProps}>
                {childrenOptions.map(({ itemKey: childKey, ...child }, i) => (
                    <ToolbarItem
                        inVerticalMode={inVerticalMode}
                        key={childKey}
                        itemKey={childKey}
                        rowIndex={calculateRowIndex(i)}
                        {...child}
                    />
                ))}
            </Menubar.Section>
        );
    }
    return null;
});
