/** Simple notification display time (ms) */
export const NOTIFICATION_TIMEOUT = 6000;

/** Duration (ms) of the show/hide animation */
export const NOTIFICATION_TRANSITION_TIME = 200;

/** Spacing (px) between notifs */
export const NOTIFICATION_CONTAINER_GUTTER = 8;

/** Limit of visible simple notif */
export const NOTIFICATION_SIMPLE_MAX_COUNT = 5;

/** Limit of visible extended notif */
export const NOTIFICATION_EXTENDED_MAX_COUNT = 2;

/** Starting Y position offset (in px) for the slide-in effect  */
export const NOTIFICATION_START_OFFSET = -52;

/** Package scope */
export const SCOPE = 'toast-notification';

/** Package scope */
export const DOMAIN = 'notifications';

/** Base notification ARIA props */
export const ARIA_PROPS = {
    role: 'alert',
    'aria-live': 'polite',
    // Do not announce again if content is updated (causes duplicate screen reader alert)
    'aria-atomic': 'false',
} as const;
