import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { SelectFieldMultiple } from '@lumapps/combobox/components/SelectFieldMultiple';
import { Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { usePaginatedFrontendSearch } from '@lumapps/utils/hooks/usePaginatedFrontendSearch';

import { useUpdateSites } from '../../hooks/useUpdateSites';
import { FormattedSiteFilter } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

interface SitesContentFilterProps {
    /** The list of currently selected sites */
    selectedSites?: FormattedSiteFilter[];
    /** The callback that will be trigger on each value change of the select values */
    handleSitesFilter: (sites: FormattedSiteFilter[]) => void;
    /** Whether the filter should be hidden if there is no available tags. */
    shouldBeHiddenIfEmpty?: boolean;
    /** The list of available site references to choose from in the filter. */
    siteReferences?: FormattedSiteFilter[];
    /** Number of items per page */
    itemsPerPage?: number;
    theme?: Theme;
    scope?: string;
    hideSubheader?: boolean;
}

/**
 * Component that displays a multi select filter that allow the selection of sites inherited from siteReferences.
 * @family Filters
 */
export const SitesContentFilter = ({
    handleSitesFilter,
    shouldBeHiddenIfEmpty,
    selectedSites = [],
    siteReferences = [],
    itemsPerPage = 20,
    theme = Theme.light,
    scope,
    hideSubheader,
}: SitesContentFilterProps) => {
    const { translateKey } = useTranslate();
    const [isOpen, toggleOpen] = useBooleanState(false);

    const getValueName = (site: FormattedSiteFilter) => site.name;
    const getValueId = (site: FormattedSiteFilter) => site.id;

    /** Pagination */
    const { getMoreItems, items, searchQuery, onSearch } = usePaginatedFrontendSearch<FormattedSiteFilter>({
        itemList: siteReferences,
        perPage: itemsPerPage,
        getItemName: (site: FormattedSiteFilter) => site.name,
    });

    /**
     * Update the default selected sites values.
     * Make sure that the sites exist and are full.
     * */
    useUpdateSites({ selectedSites, siteReferences, handleSitesFilter });

    /**
     * Hide the filter if it should not be displayed empty and if there is 0 or 1 reference.
     * */
    if (shouldBeHiddenIfEmpty && (isEmpty(siteReferences) || siteReferences.length === 1)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.SITE)} theme={theme} />}
            <SelectFieldMultiple<FormattedSiteFilter>
                theme={theme}
                scope={scope}
                value={selectedSites}
                isOpen={isOpen}
                searchText={searchQuery}
                searchFieldProps={{
                    isCompact: true,
                }}
                onInfiniteScroll={getMoreItems}
                onSearch={onSearch}
                onChange={handleSitesFilter}
                getValueId={getValueId}
                getValueName={getValueName}
                label={translateKey(GLOBAL.SITE)}
                setOpen={toggleOpen}
                choices={items}
            />
        </>
    );
};
