import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    ColorPalette,
    Text,
    SkeletonTypography,
    Theme,
    Typography,
    TypographyCustom,
    Orientation,
    ColorVariant,
} from '@lumapps/lumx/react';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-description` as const;

export const Description = () => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, video, theme, orientation } = useBlockVideoContext();

    if (!canDisplay('DESCRIPTION')) {
        return null;
    }

    if (isLoading) {
        const array = orientation === Orientation.vertical ? [1, 2] : [1];

        return (
            <div className={block('skeleton')}>
                {Array.from(array, (item) => (
                    <SkeletonTypography key={item} theme={theme} typography={Typography.body1} width="100%" />
                ))}
            </div>
        );
    }

    const description = typeof video.description === 'string' ? video.description : video.description?.value;

    if (!description) {
        return null;
    }

    return (
        <Text
            as="p"
            truncate={{ lines: orientation === Orientation.vertical ? 2 : 1 }}
            className={block()}
            color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
            colorVariant={ColorVariant.L2}
            typography={TypographyCustom.body}
        >
            {description}
        </Text>
    );
};
