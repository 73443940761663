export enum WIDGET_MICRO_APP {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464638526
     */
    CONFIGURATION_INSTRUCTIONS = 'WIDGET_MICRO_APP.CONFIGURATION_INSTRUCTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509180411
     */
    MICRO_APP_HEADER = 'WIDGET_MICRO_APP.MICRO_APP_HEADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509180360
     */
    MICRO_APP_SELECTION = 'WIDGET_MICRO_APP.MICRO_APP_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464935538
     */
    NO_MICRO_APP_YET = 'WIDGET_MICRO_APP.NO_MICRO_APP_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=509179986
     */
    REFRESH_BUTTON = 'WIDGET_MICRO_APP.REFRESH_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464936090
     */
    SELECTION_INSTRUCTIONS = 'WIDGET_MICRO_APP.SELECTION_INSTRUCTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464628530
     */
    WIDGET_NOT_CONFIGURED = 'WIDGET_MICRO_APP.WIDGET_NOT_CONFIGURED',
}
