import React from 'react';

/**
 * Custom hook to provide some utils
 * Default var from React.useState hook.
 * And a closure function to open / close the dialog when needed. Usefull on button click
 * @param isDefaultOpen is the dialog opened by default?
 * @returns array of boolean (is the dialog open?), function to set open state, closure to set the open state
 */
export const useDialogOpenState = (
    isDefaultOpen = false,
): [boolean, (value: boolean) => void, (value: boolean) => () => void] => {
    const [isOpen, setIsOpen] = React.useState(isDefaultOpen);
    const openDialog =
        (open = true) =>
        () =>
            setIsOpen(open);

    return [isOpen, setIsOpen, openDialog];
};
