/* eslint-disable import/no-mutable-exports */
import noop from 'lodash/noop';

let getAccountType = noop;
let getAvatarUrl = noop;
let getConnected = noop;
let getProfilePicture = noop;
let getToken = noop;
let getUserFullName = noop;
let isConnected = noop;
let isExternal = noop;
let isGoogle = noop;
let isGuest = noop;
let isMicrosoft = noop;
let redirectToProfile = noop;
let redirectToUserDirectoryProfile = noop;
let setConnectedUser = noop;

const setUser = (user) => {
    getAccountType = user.getAccountType;
    getAvatarUrl = user.getAvatarUrl;
    getConnected = user.getConnected;
    getProfilePicture = user.getProfilePicture;
    getToken = user.getToken;
    getUserFullName = user.getUserFullName;
    isConnected = user.isConnected;
    isExternal = user.isExternal;
    isGoogle = user.isGoogle;
    isGuest = user.isGuest;
    isMicrosoft = user.isMicrosoft;
    redirectToProfile = user.redirectToProfile;
    redirectToUserDirectoryProfile = user.redirectToUserDirectoryProfile;
    setConnectedUser = user.setConnectedUser;
};

export {
    getAccountType,
    getAvatarUrl,
    getConnected,
    getProfilePicture,
    getToken,
    getUserFullName,
    isConnected,
    isExternal,
    isGoogle,
    isGuest,
    isMicrosoft,
    redirectToProfile,
    redirectToUserDirectoryProfile,
    setConnectedUser,
    setUser,
};
