import debounce from 'lodash/debounce';

/////////////////////////////

function ZendeskService(LumsitesBaseService, ZendeskConstant, ZendeskFactory) {
    'ngInject';

    /**
     * The delay to wait for debounced functions.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEBOUNCE_DELAY = 500;

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(ZendeskFactory, {
        autoInit: false,
        objectIdentifier: 'id',
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Fetch the tags for Zendesk tickets. Used on the search results page for example.
     */
    function initTags() {
        service.filterize(
            {
                ignore403: true,
                type: 'tag',
            },
            undefined,
            undefined,
            ZendeskConstant.LIST_KEY.TAGS,
        );
    }

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    service.debouncedFilterize = debounce(service.filterize, _DEBOUNCE_DELAY);
    service.initTags = initTags;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Zendesk', ZendeskService);

/////////////////////////////

export { ZendeskService };
