/* eslint-disable */
import { programDashboardRoute } from '@lumapps/sa-programs/routes';
import { ShareableContentNotificationType } from '@lumapps/sa-shareable-contents/types';
import { EDIT_COMMUNITY_DIALOG_EVENT } from '@lumapps/community-configuration/constants';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function NotificationGroupController(
        $rootScope,
        $scope,
        $state,
        $window,
        Config,
        Customer,
        InitialSettings,
        Instance,
        Notification,
        NotificationSettings,
        Translation,
        User,
        Utils,
    ) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the list of children notification.
         * Note: this is only for custom notifications.
         *
         * @type {string}
         * @constant
         */
        vm.KEY_CHILDREN = 'custom-';

        /**
         * The description (and main body content) of the notification group.
         *
         * @type {string}
         */
        vm.description = '';

        /**
         * Indicates whether there are any user directories in the current instance.
         *
         * @type {boolean}
         */
        vm.hasUserDirectories = false;

        /**
         * Indicates if the notification group is a custom parent or not.
         *
         * @type {boolean}
         */
        vm.isCustomParent =
            _.get(vm.notificationGroup, 'notification.group') &&
            angular.isUndefinedOrEmpty(_.get(vm.notificationGroup, 'notification.parentId'));

        /**
         * Indicates if we are currently displaying the children notifications.
         * Note: This is only for custom notifications.
         *
         * @type {boolean}
         */
        vm.isDisplayingChildren = false;

        /**
         * The target of the link.
         *
         * @type {string}
         */
        vm.linkTarget = '';

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Notification = Notification;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Set the link to a social advocacy program page.
         *
         * @param {Object}  notification   The notification object related to the community / post we want to go to.
         */
        function _setSAProgramLink(notification) {
            var params = {};

            // params.instance = _.get(notification, 'instanceDetails.slug', _.get(Instance.getCurrent(), 'slug'));
            params.slug = _.get(notification, 'description.program_slug', {
                en: 'default',
            });
            params.view = 'feed';

            params.slug = Translation.translate(params.slug);

            vm.link = $state.href(programDashboardRoute.legacyId, params);
            vm.linkTarget = '_self';
        }

        /**
         * Set the link to a community page or a post within a community.
         *
         * @param {Object}  notification   The notification object related to the community / post we want to go to.
         * @param {boolean} [isPost]       Indicates if we want to go to a given post within a community rather than the
         *                                 community itself.
         * @param {boolean} [toComments]   Indicates if the user should go to the comments area of the related post.
         * @param {boolean} [toEditDialog] Indicates if the user should go to the comunity edition dialog.
         */
        function _setCommunityOrPostLink(notification, isPost, toComments, toEditDialog) {
            var params = {};

            isPost = isPost || false;

            params.instance = _.get(notification, 'instanceDetails.slug', _.get(Instance.getCurrent(), 'slug'));

            if (isPost) {
                params.identifier = notification.externalKey;
                params.slug = _.get(notification.parentContentDetails, 'slug');
                params.view = 'post';
            } else {
                params.slug = _.get(notification.contentDetails, 'slug');
                params.view = 'posts';
            }

            if (toComments) {
                params['#'] = 'comments';
            } else if (toEditDialog) {
                params['#'] = EDIT_COMMUNITY_DIALOG_EVENT;
            }

            params.slug = Translation.translate(params.slug);

            vm.link = $state.href('app.front.community', params);
        }

        /**
         * Set the link to a content page.
         *
         * @param {Object}  notification The notification object related to the content we want to go to.
         * @param {boolean} [toComments] Indicates if the user should go to the comments area of the related content.
         */
        function _setContentLink(notification, toComments) {
            if (Translation.hasTranslations(notification.link)) {
                vm.link = Translation.translate(notification.link);
                vm.linkTarget = '_blank';

                return;
            } else if (
                angular.isDefinedAndFilled([notification.url, notification.instance], 'every') &&
                notification.instance !== Instance.getCurrentInstanceId()
            ) {
                Instance.get(
                    {
                        uid: notification.instance,
                    },
                    function onInstanceGetSuccess(response) {
                        var gsiteUrl = _.find(response.properties, {
                            key: 'gsite-url',
                        });

                        var translatedUrl = Translation.translate(notification.url) + (toComments ? '#comments' : '');

                        if (angular.isDefinedAndFilled(gsiteUrl)) {
                            vm.link = gsiteUrl + '?s=' + translatedUrl;
                        } else {
                            vm.link =
                                InitialSettings.SERVER_BASE_URL +
                                Utils.buildInstanceUrl(response, Customer.getCustomerSlug()) +
                                translatedUrl;
                        }
                    },
                    undefined,
                    'getInstanceId',
                );

                return;
            }

            var params = {
                instance: _.get(notification, 'instanceDetails.slug', _.get(Instance.getCurrent(), 'slug')),
                slug: Translation.translate(notification.url),
            };

            if (toComments) {
                params['#'] = 'comments';
            }

            vm.link = $state.href('app.front.content-get', params);
        }

        /**
         * Set the description of the notification group.
         *
         * @param {Object} notification The notification object to get a title for.
         */
        function _setDescription(notification) {
            let key;
            var type = notification.type;
            var allTypes = Notification.getNotificationTypes();

            // Custom keys
            switch (type) {
                /**
                 * Notification sent when a new content has been made shareable
                 * in a program the user is a member of.
                 *
                 * The content can either be a link or an image and has
                 * a distinct notification for each type.
                 */
                case allTypes.SOCIAL_ADVOCACY_NEW_SHAREABLE_CONTENT: {
                    const shareableContentType = _.get(notification, 'description.content_type');

                    key =
                        shareableContentType === ShareableContentNotificationType.image
                            ? 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.NEW_SHAREABLE_IMAGE.DESCRIPTION'
                            : 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.NEW_SHAREABLE_CONTENT.DESCRIPTION';
                    break;
                }
                /**
                 * Notification sent when user is set as ambassador of a program
                 */
                case allTypes.SOCIAL_ADVOCACY_USER_PROMOTED_AMB:
                    key = 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.USER_PROMOTED.AMB.DESCRIPTION';
                    break;
                /**
                 * Notification sent when user is set as manager of a program
                 */
                case allTypes.SOCIAL_ADVOCACY_USER_PROMOTED_POM:
                    key = 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.USER_PROMOTED.POM.DESCRIPTION';
                    break;
                /**
                 * Notification of content expiring soon. If the endDate is in the past, we overwrite it with a
                 * content_expired type notification
                 */
                case allTypes.CONTENT_EXPIRATION:
                    var expirationDate = moment.utc(_.get(notification, 'contentDetails.endDate')).local();
                    type = expirationDate.isBefore() ? 'content_expired' : type;
                    key = 'NOTIFICATION.' + type.toUpperCase().replace(/_/g, '.') + '.DESCRIPTION';
                    break;
                /**
                 * Default key
                 */
                default:
                    key = 'NOTIFICATION.' + type.toUpperCase().replace(/_/g, '.') + '.DESCRIPTION';
                    break;
            }

            // Specific handling with Custom 'child' notification.
            if (
                type === allTypes.CUSTOM &&
                (angular.isDefinedAndFilled(notification.parentId) || !notification.group)
            ) {
                if (angular.isDefinedAndFilled(notification.title)) {
                    vm.description = '<strong>' + Translation.translate(notification.title) + '</strong><br>';
                }
                vm.description += Translation.translate(notification.description);
            } else {
                // Standard notification
                if (angular.isUndefinedOrEmpty(_.get(NotificationSettings, '[' + type + ']'))) {
                    return;
                }

                // These types of notifications have a post and a content variation.
                if (_.includes([allTypes.CONTENT_LIKE, allTypes.COMMENT_NEW, allTypes.COMMENT_LIKE, allTypes.REPLY_LIKE], type)) {
                    var isPost = _.get(notification.contentDetails, 'type') === Config.AVAILABLE_CONTENT_TYPES.POST;
                    type = isPost ? type + '_post' : type;
                }

                var settings = NotificationSettings[type];
                var plurals = _.get(settings, 'plurals');
                var count = 1;

                if (angular.isDefinedAndFilled(plurals)) {
                    if (angular.isDefinedAndFilled(vm.notificationGroup.actions)) {
                        count = notification.actionCount || 1;
                    } else {
                        count = notification.unreadNotificationCount || 1;
                    }

                    // These types of notifications have a titled post and a titleless post variation.
                    const hasTitleVariation =
                        _.includes(
                            [allTypes.COMMENT_REPLY, `${allTypes.COMMENT_NEW}_post`, `${allTypes.CONTENT_LIKE}_post`,
                            `${allTypes.COMMENT_LIKE}_post`, `${allTypes.REPLY_LIKE}_post`],
                            type,
                        ) && Translation.translate(_.get(notification, 'contentDetails.title'));

                    key =
                        'NOTIFICATION.' +
                        type.toUpperCase().replace(/_/g, '.') +
                        '.' +
                        (hasTitleVariation ? 'WITH.TITLE.' : '') +
                        (plurals['count' + count] || plurals.more) +
                        '.DESCRIPTION';
                }

                vm.description = Translation.replace(
                    key,
                    _.get(settings, 'tokens', []),
                    _.get(settings, 'getReplacements', angular.noop)(vm.notificationGroup, count, User.getUserFullName),
                );
            }
        }

        /**
         * When a child notification is marked as read, we want to decrease the unread count and close the parent if
         * there are no more children.
         *
         * @param {string} parentId          The identifier of the parent notification group the child is part of.
         * @param {number} unreadCountUpdate The value that we want to add to the unreadNotificationCount of the
         *                                   current parent notification group.
         *                                   Note: to decrease the count, just pass in a negative number.
         */
        function _onChildSetAsRead(parentId, unreadCountUpdate) {
            if (angular.isUndefinedOrEmpty(parentId) || parentId !== vm.notificationGroup.notification.uid) {
                return;
            }

            vm.notificationGroup.notification.unreadNotificationCount += unreadCountUpdate;

            // There are no more children to read so close the parent as well.
            if (vm.notificationGroup.notification.unreadNotificationCount <= 0) {
                Notification.setAsRead(vm.notificationGroup);
            } else {
                _setDescription(vm.notificationGroup.notification);
            }
        }

        /**
         * Generate the link to the target element (a community, post, content etc...) of the notification group.
         *
         * @param {Object} notification The notification object to get a link for.
         */
        function _setLink(notification) {
            if (angular.isUndefinedOrEmpty(notification)) {
                return;
            }

            var types = Notification.getNotificationTypes();

            var isPost = _.get(notification.contentDetails, 'type') === Config.AVAILABLE_CONTENT_TYPES.POST;

            switch (notification.type) {
                case types.COMMUNITY_NEW_ACCESS_REQUEST:
                    _setCommunityOrPostLink(notification, isPost, false, true);
                    break;
                case types.COMMUNITY_NEW_CREDENTIAL:
                case types.COMMUNITY_EXTENDED_SERVICE_MANAGEMENT:
                case types.POST_NEW:
                case types.POST_MERGED:
                case types.POST_MERGED_MASTER:
                case types.POST_MERGED_SLAVE:
                case types.POST_MERGE_PENDING:
                case types.POST_MODERATION_DECLINE:
                case types.POST_MODERATION_DELETE:
                case types.POST_MODERATION_DELETE_SPAMMER:
                case types.POST_STATUS_UPDATE:
                case types.POST_REPORT_NEW:
                case types.POST_UPDATE:
                    _setCommunityOrPostLink(notification, isPost);
                    break;

                case types.COMMENT_LIKE:
                case types.COMMENT_MENTION:
                case types.COMMENT_NEW:
                case types.COMMENT_REPLY:
                case types.POST_MENTION:
                case types.REPLY_LIKE:
                    if (isPost) {
                        _setCommunityOrPostLink(notification, isPost, true);
                    } else {
                        _setContentLink(notification, true);
                    }
                    break;

                case types.CONTENT_EXPIRATION:
                case types.CONTENT_REFUSED:
                case types.CONTENT_WORKFLOW:
                    vm.link = $state.href('app.front.content-edit', {
                        instance: _.get(notification, 'instanceDetails.slug', _.get(Instance.getCurrent(), 'slug')),
                        key: notification.externalKey,
                    });
                    break;

                case types.CONTENT_LIKE:
                    if (isPost) {
                        _setCommunityOrPostLink(notification, isPost);
                    } else {
                        _setContentLink(notification);
                    }
                    break;

                case types.CONTENT_NEW:
                case types.CONTENT_UPDATE:
                case types.CONTENT_VALIDATED:
                    _setContentLink(notification);
                    break;

                case types.CUSTOM:
                    // Child notification OR individual parent notification --> go to internal / external url.
                    if (angular.isDefinedAndFilled(notification.parentId) || !notification.group) {
                        _setContentLink(notification);
                    }
                    break;

                case types.SOCIAL_ADVOCACY_NEW_SHAREABLE_CONTENT:
                case types.SOCIAL_ADVOCACY_USER_PROMOTED_AMB:
                case types.SOCIAL_ADVOCACY_USER_PROMOTED_POM:
                    _setSAProgramLink(notification);
                    break;

                default:
                    break;
            }

            // For some custom notifications only.
            if (vm.isCustomChild && notification.isReadOnClick) {
                Notification.setAsRead(vm.notificationGroup, 'custom-' + notification.parentId);
            }
        }

        /**
         * Set the subheader name of the notification.
         *
         * @param {Object} notification The notification object used to determine the subheader name.
         */
        function _setSubheader(notification) {
            vm.displaySubheader = vm.displaySubheader || false;

            if (!vm.displaySubheader) {
                return;
            }

            vm.subheader = 'NOTIFICATION_PRIORITY_NAME_' + _.get(notification, 'priority', 0);
        }

        /**
         * When the first notification group of a given priority is marked as read, we need to recompute the next one
         * in the list otherwise the heading will disappear from the list.
         *
         * @param {string} uid The identifier of the notification to be recomputed: it becomes the first one of that
         *                     group.
         */
        function _onFirstSetAsRead(uid) {
            if (angular.isUndefinedOrEmpty(uid)) {
                return;
            }

            if (vm.notificationGroup.notification.uid === uid) {
                vm.displaySubheader = true;
                _setSubheader(vm.notificationGroup.notification);
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close the notificationGroup without closing the dropdown.
         *
         * @param {Event} evt The original click event triggering this method.
         */
        function closeNotificationGroup(evt) {
            evt.stopPropagation();
            Notification.setAsRead(
                vm.notificationGroup,
                vm.isCustomChild ? 'custom-' + vm.notificationGroup.notification.parentId : undefined,
                vm.displaySubheader,
            );
        }

        /**
         * Load the next page of results for the current list of custom notifications.
         *
         * @param {Event} evt The original click event triggering this method.
         */
        function loadMoreChildren(evt) {
            evt.stopPropagation();

            Notification.loadMoreNotifications(vm.KEY_CHILDREN);
        }

        /**
         * Handle the click on a notification item.
         *
         * @param {Event} evt The original click event triggering this method.
         */
        function onNotificationGroupClick(evt) {
            var notification = vm.notificationGroup.notification;
            var types = Notification.getNotificationTypes();
            var customParent = notification.type === types.CUSTOM && angular.isUndefinedOrEmpty(notification.parentId);

            switch (notification.type) {
                case types.SOCIAL_ADVOCACY_NEW_SHAREABLE_CONTENT:
                case types.SOCIAL_ADVOCACY_USER_PROMOTED_AMB:
                case types.SOCIAL_ADVOCACY_USER_PROMOTED_POM:
                    Notification.setAsRead(vm.notificationGroup);
                    break;

                default:
                    break;
            }

            // Parent notifications are used as a toggle to display children notifications but only when group is true.
            if (customParent && notification.group) {
                evt.stopPropagation();
            } else if (
                (customParent && !notification.group) ||
                notification.instanceId !== Instance.getCurrentInstanceId()
            ) {
                /* In this case, notification is not grouped, therefore it has no children and
                 * should be set as read like a "normal" notification.
                 */
                Notification.setAsRead(vm.notificationGroup);

                $window.open(
                    decodeURIComponent(vm.link),
                    angular.isUndefinedOrEmpty(vm.linkTarget) ? '_blank' : vm.linkTarget,
                );

                evt.stopPropagation();
                evt.preventDefault();
            }
        }

        /**
         * Toggle the children notifications (for custom notifications only).
         *
         * @param {Event} evt The original click event triggering this method.
         */
        function toggleCustomChildren(evt) {
            evt.stopPropagation();

            if (!vm.isDisplayingChildren && angular.isUndefinedOrEmpty(Notification.displayList(vm.KEY_CHILDREN))) {
                var params = angular.extend(angular.fastCopy(Notification.DEFAULT_LIST_PARAMS), {
                    maxResults: 5,
                    parentId: vm.notificationGroup.notification.uid,
                });

                Notification.filterize(
                    params,
                    function onNotificationListSuccess() {
                        $rootScope.$broadcast('notifications-loaded');
                    },
                    Utils.displayServerError,
                    vm.KEY_CHILDREN,
                );
            }

            vm.isDisplayingChildren = !vm.isDisplayingChildren;
        }

        /////////////////////////////

        vm.closeNotificationGroup = closeNotificationGroup;
        vm.loadMoreChildren = loadMoreChildren;
        vm.onNotificationGroupClick = onNotificationGroupClick;
        vm.toggleCustomChildren = toggleCustomChildren;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        if (vm.isCustomParent) {
            /**
             * @param {Event} evt The original event triggering this method.
             *
             * @see _onChildSetAsRead
             */
            $scope.$on('notifications__child-set-as-read', function onChildSetAsRead(evt, parentId, unreadCountUpdate) {
                _onChildSetAsRead(parentId, unreadCountUpdate);
            });
        }

        /**
         * @param {Event} evt The original event triggering this method.
         *
         * @see _onFirstSetAsRead
         */
        $scope.$on('notifications__first-set-as-read', function onFirstSetAsRead(evt, uid) {
            _onFirstSetAsRead(uid);
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var notification = vm.notificationGroup.notification;

            if (angular.isUndefinedOrEmpty(notification)) {
                return;
            }

            vm.KEY_CHILDREN += notification.uid;

            _setSubheader(notification);

            vm.hasUserDirectories = angular.isDefinedAndFilled(InitialSettings.USER_DIRECTORIES);
            vm.hasThumbnail = _.get(NotificationSettings, '[' + notification.type + '].hasThumbnail', false);

            if (angular.isFunction(vm.hasThumbnail)) {
                vm.hasThumbnail = vm.hasThumbnail(vm.notificationGroup);
            }

            vm.icon = Notification.getIcon(notification.type);

            vm.isCustomChild = notification.group && angular.isDefinedAndFilled(notification.parentId);

            _setDescription(notification);

            _setLink(notification);
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive that displays a notification group.
     * This is used in the notifications dropdown for example.
     *
     * @param {boolean}           [displaySubheader=false] Indicates if we should display the subheader for this item.
     * @param {string}            from                     The context in which the notification group is being displayed.
     *                                                     Possible values are 'header' or 'profile'.
     * @param {NotificationGroup} notificationGroup        The notification group to be displayed.
     */

    function NotificationGroupDirective(RecursionHelper) {
        'ngInject';

        /**
         * Compile the directive to a given element.
         *
         * @param  {HTMLElement} el The element to compile to.
         * @return {Function}    A template function to link scope and template together.
         */
        function compileContentNotification(el) {
            return RecursionHelper.compile(el);
        }

        return {
            bindToController: true,
            compile: compileContentNotification,
            controller: NotificationGroupController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                displaySubheader: '<?lsDisplaySubheader',
                from: '@lsFrom',
                notificationGroup: '=lsNotificationGroup',
            },
            templateUrl: '/client/front-office/modules/notification/views/notification-group.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsNotificationGroup', NotificationGroupDirective);
})();
