/* eslint-disable require-jsdoc-except/require-jsdoc */
/* eslint-disable no-use-before-define */
/* eslint-disable lumapps/file-format */
import get from 'lodash/get';
import set from 'lodash/set';

import { getSheetList, getChartList } from 'components/components/chart/api/ChartApi.js';

import { documentTypes, PROVIDERS } from 'front/modules/media/js/media_constant';

/////////////////////////////

function WidgetChartSettingsController(Document, LxNotificationService, ReusableMediaPicker, Translation, Widget) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetChartSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current widget instance.
     *
     * @type {Object}
     */
    let _currentWidgetInstance = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /** The fetched charts. */
    widgetChartSettings.chartList = [];
    /** The fetched sheets. */
    widgetChartSettings.sheetList = [];

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /*
     * Function that set the default value for some properties.
     */
    function _initFields() {
        const properties = get(_currentWidgetInstance, 'properties');

        if (angular.isDefinedAndFilled(properties.selectedFile) && properties.selectedFile.isValid) {
            fetchSheetList();
        }

        set(_currentWidgetInstance.properties, properties);
    }

    /**
     * Function that update a value of a properties of the current widget.
     *
     * @param {string} key   The property's key.
     * @param {string} value The property's value.
     */
    function _updateWidget(key, value) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        if (currentWidget.uuid !== _currentWidgetInstance.uuid) {
            init();
        }

        set(properties, [key], value);
        currentWidget.properties = properties;
    }

    /**
     * Function that retrieve a specific properties of the current widget.
     *
     * @param  {string} key The property's key.
     * @return {any}    The property's value.
     */
    function _getProperty(key) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        if (currentWidget.uuid !== _currentWidgetInstance.uuid) {
            init();
        }

        return get(properties, key);
    }

    /** Function that fetch the sheet list for the selected file. */
    function fetchSheetList() {
        const selectedFile = _getProperty('selectedFile');
        const selectedSheet = _getProperty('selectedSheet');

        if (angular.isUndefinedOrEmpty(selectedFile)) {
            return;
        }
        getSheetList(selectedFile.id).then((res) => {
            widgetChartSettings.sheetList = res.data.items;
            if (angular.isDefinedAndFilled(selectedSheet)) {
                fetchChartList();
            }
        }).catch(() => {
            LxNotificationService.error(
                Translation.translate('FRONT.WIDGET_CHART.SETTINGS.ERROR.FETCH_SHEETS'),
            );
        });
    }

    /** Function that fetch the chart list of the selected sheet. */
    function fetchChartList() {
        const selectedFile = _getProperty('selectedFile');
        const selectedSheet = _getProperty('selectedSheet');

        if (
            angular.isUndefinedOrEmpty(selectedFile) ||
            widgetChartSettings.sheetList.length === 0 ||
            angular.isUndefinedOrEmpty(selectedSheet)
        ) {
            return;
        }
        getChartList(selectedFile.id, selectedSheet.id).then((res) => {
            widgetChartSettings.chartList = res.data.items;
        }).catch(() => {
            LxNotificationService.error(
                Translation.translate('FRONT.WIDGET_CHART.SETTINGS.ERROR.FETCH_CHARTS'),
            );
        });
    }

    /////////////////////////////
    //                         //
    //    Public functions     //
    //                         //
    /////////////////////////////

    /**
     * Triggered when a file has been selected.
     *
     * @param {Object} doc    The file selected by the user.
     * @param {Object} target In this case is "picked" given by the API.
     */
    function drivePickerCallback(doc, target) {
        const { docPath } = doc;
        const id = doc.content[0].fileId;
        const { ext } = doc.content[0];
        const mediaContent = Document.getMediaContentByLang(doc, true);
        const { name: fileName } = mediaContent;

        if (angular.isDefinedAndFilled(doc) && angular.isDefinedAndFilled(target)) {
            _updateWidget('selectedFile', { docPath, fileName, id, isValid: ext === 'xlsx' });
            _updateWidget('selectedSheet', undefined);
            _updateWidget('selectedChart', undefined);
            widgetChartSettings.sheetList = [];
            widgetChartSettings.chartList = [];

            if (ext === 'xlsx') {
                fetchSheetList();
            }
        }
    }

    /**
     * Opens the media picker.
     */
    function openMediaPicker() {
        const onChange = (selection) => {
            drivePickerCallback(selection.object, 'picked');
        };
        const options = {
            allowedFileTypes: documentTypes.documents,
            allowedProviders: [PROVIDERS.microsoft],
            isMediaModel: false,
        };

        ReusableMediaPicker.open(undefined, onChange, options);
    }

    /**
     * Checks if a file/ file id is set else clear the folder property.
     *
     * @param  {Object}  target The file.
     * @return {boolean} Whether or not the file is selected.
     */
    function isFileSelected(target) {
        if (angular.isUndefined(target)) {
            return undefined;
        }

        const currentSelectedFile = _getProperty('selectedFile');

        if (angular.isDefined(currentSelectedFile) && angular.isDefined(currentSelectedFile.id)) {
            return true;
        }

        deleteSelectedFile();

        return false;
    }

    /**
     * Checks if a file is valid.
     *
     * @return {boolean} Whether or not the file is selected.
     */
    function isFileValid() {
        const currentSelectedFile = _getProperty('selectedFile');

        if (angular.isDefinedAndFilled(currentSelectedFile) && angular.isDefined(currentSelectedFile.isValid)) {
            return currentSelectedFile.isValid;
        }

        return true;
    }

    /** Deletes selected file by clearing all properties. */
    function deleteSelectedFile() {
        _updateWidget('selectedFile', {
            docPath: undefined,
            fileName: undefined,
            id: undefined,
            isFileValid: true,
        });
        _updateWidget('selectedSheet', undefined);
        _updateWidget('selectedChart', undefined);

        widgetChartSettings.sheetList = [];
        widgetChartSettings.chartList = [];
    }

    /**
     * Gets the file name, if no file name, return the file id.
     *
     * @return {string} The file name if there is one.
     */
    function getFileName() {
        const currentSelectedFile = _getProperty('selectedFile');

        if (
            angular.isUndefinedOrEmpty(currentSelectedFile) ||
            angular.isUndefinedOrEmpty(currentSelectedFile.fileName)
        ) {
            return undefined;
        }

        if (angular.isDefinedAndFilled(currentSelectedFile.fileName)) {
            return currentSelectedFile.fileName;
        }

        return currentSelectedFile.id;
    }

    /** Function callback when a user pick a sheet from the sheetList. */
    function selectSheet() {
        const selectedSheet = _getProperty('selectedSheet');
        widgetChartSettings.chartList = [];

        if (selectedSheet) {
            fetchChartList();
        }
    }

    /////////////////////////////

    widgetChartSettings.openMediaPicker = openMediaPicker;
    widgetChartSettings.isFileSelected = isFileSelected;
    widgetChartSettings.drivePickerCallback = drivePickerCallback;
    widgetChartSettings.deleteSelectedFile = deleteSelectedFile;
    widgetChartSettings.getFileName = getFileName;
    widgetChartSettings.selectSheet = selectSheet;
    widgetChartSettings.isFileValid = isFileValid;

    /*
     * The Initialize function.
     */
    function init() {
        _currentWidgetInstance = Widget.getCurrent();

        _initFields();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetChartSettingsController', WidgetChartSettingsController);

/////////////////////////////

export { WidgetChartSettingsController };
