import React from 'react';
import { useTranslate } from '@lumapps/translations';
import { Message, Kind } from '@lumapps/lumx/react';

export interface SocialNetworksErrorProps {
    message?: string;
    className?: string;
}

export const SocialNetworksError: React.FC<SocialNetworksErrorProps> = ({
    className,
    message = 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ERROR.SOCIAL_NETWORKS_UNAVAILABLE',
}) => {
    const { translateKey } = useTranslate();

    return (
        <Message className={className} hasBackground kind={Kind.error}>
            <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: translateKey(message) }}
            />
        </Message>
    );
};
