import { useEffect } from 'react';

import compact from 'lodash/compact';
import find from 'lodash/find';
import isEmpty from 'lodash/fp/isEmpty';
import isUndefined from 'lodash/fp/isUndefined';
import includes from 'lodash/includes';

import { useTranslate } from '@lumapps/translations';

import { FormattedSiteFilter, FormattedTagFilter, SiteFilteredBy, TagFilteredByReference } from '../types';
import { useAvailableTags } from './useAvailableTags';

interface UseUpdateContentTypeProps {
    /** Currently selected tags */
    selectedTags?: FormattedTagFilter[];
    /** Currently selected sites */
    sites?: FormattedSiteFilter[];
    /** The list of site references available for the filter. */
    siteReferences?: SiteFilteredBy[];
    /** Does siblings sites are included in the site */
    includeSiblingSites?: boolean;
    /** List of all available tags */
    tagReferences?: TagFilteredByReference[];
    handleTagFilter: (tags: FormattedTagFilter[]) => void;
    /** The hook is ready to be initiate once the filter-properties are loaded */
    ready: boolean;
}

/**
 * This hook is used to update the selected tags based on the currently available tags.
 * Tags are filtered to include only the ones that belong to one of the selected content type.
 * */
export const useUpdateContentTypeTags = ({
    selectedTags,
    sites,
    siteReferences,
    includeSiblingSites,
    tagReferences,
    handleTagFilter,
    ready,
}: UseUpdateContentTypeProps) => {
    const { translateObject } = useTranslate();

    const availableTags = useAvailableTags(tagReferences, sites, siteReferences, includeSiblingSites);

    useEffect(() => {
        if (ready && !isUndefined(availableTags) && !isUndefined(selectedTags)) {
            /** Get all tag ids that needs to be removed from the list, since they are not available in the selected custom content types */
            const idsToRemove = selectedTags
                .filter(({ id }) => !availableTags.some((tag) => tag.id === id))
                .map(({ id }) => id);

            /** Get all the tag ids that need to be updated (name is not defined) */
            const idsToUpdate = selectedTags.filter(({ name }) => isEmpty(name)).map(({ id }) => id);

            if (!isEmpty(idsToUpdate) || !isEmpty(idsToRemove)) {
                const updatedTags = selectedTags
                    .filter(({ id }) => !idsToRemove.includes(id))
                    .map((tag) =>
                        includes(idsToUpdate, tag.id) ? find(availableTags, ({ id }) => id === tag.id) : tag,
                    );

                handleTagFilter(compact(updatedTags));
            }
        }
    }, [availableTags, handleTagFilter, ready, selectedTags, tagReferences, translateObject]);
};
