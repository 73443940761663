import { Dispatch } from '@lumapps/redux';

import * as api from '../../api';
import { Program } from '../../types';
import { actions } from './slice';

export const fetchUserPrograms = () => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.fetchPrograms());
        const { data } = await api.fetchPrograms();

        dispatch(actions.fetchProgramsSuccess(data.items));
    } catch (exception) {
        dispatch(actions.fetchProgramsFailure());
    }
};

export const fetchProgramById = (programId: Program['id']) => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.fetchSingleProgram());
        const { data } = await api.fetchProgram(programId);

        dispatch(actions.fetchSingleProgramSuccess(data));
    } catch (exception) {
        dispatch(actions.fetchSingleProgramFailure());
    }
};

export const fetchProgramBySlug = (programSlug: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.fetchSingleProgram());
        const { data } = await api.fetchPrograms({ slug: programSlug });

        if (data.items.length === 0) {
            return dispatch(actions.fetchSingleProgramNotFound());
        }

        const program = data.items[0];

        return dispatch(actions.fetchSingleProgramSuccess(program));
    } catch (exception) {
        return dispatch(actions.fetchSingleProgramFailure());
    }
};
