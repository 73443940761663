import BaseApi from '@lumapps/base-api';

const workspaceApi = new BaseApi({ path: 'workspace' });

/**
 * Fetch the group from a workspace.
 *
 * @param  {string}  workspaceId The ID of the workspace.
 * @return {Promise} Returns.
 */
const fetchGroup = (workspaceId) => {
    return workspaceApi.get('get', {
        params: {
            workspaceId,
        },
    });
};

export { fetchGroup };
