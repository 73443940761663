import produce from 'immer';

import { INIT_STATE, SET_CURRENTLY_OPENED_POPOVER, SET_ISLOADING, SET_DOCUMENTS, SET_PEOPLE } from './delve_action';

const initialState = [];

const initialWidgetState = {
    currentlyOpenedPopover: '',
    documents: [],
    isLoading: false,
    people: [],
};

const NOT_FOUND = -1;

/**
 * The reducer for the delve actions.
 *
 * @param  {Object} state  The current store state.
 * @param  {string} action The current dispatched action.
 * @return {Object} The new state of the redux store.
 */
const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const widgetIndex = draft.findIndex((widget) => {
            if (action.payload && action.payload.uuid === undefined) {
                return NOT_FOUND;
            }

            return action.payload ? widget.uuid === action.payload.uuid : NOT_FOUND;
        });

        switch (action.type) {
            case INIT_STATE: {
                if (widgetIndex === NOT_FOUND) {
                    draft.push({
                        uuid: action.payload.uuid,
                        ...initialWidgetState,
                    });
                }

                break;
            }

            case SET_CURRENTLY_OPENED_POPOVER: {
                if (widgetIndex !== NOT_FOUND) {
                    let newPropValue;
                    if (action.payload.close) {
                        newPropValue =
                            draft[widgetIndex].currentlyOpenedPopover === action.payload.data
                                ? ''
                                : draft[widgetIndex].currentlyOpenedPopover;
                    } else {
                        newPropValue = action.payload.data;
                    }

                    Object.assign(draft[widgetIndex], { currentlyOpenedPopover: newPropValue });
                }

                break;
            }

            case SET_ISLOADING: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isLoading: action.payload.data });
                }

                break;
            }

            case SET_DOCUMENTS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { documents: action.payload.data });
                }

                break;
            }

            case SET_PEOPLE: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { people: action.payload.data });
                }

                break;
            }

            default:
                return state;
        }

        return draft;
    });

export { reducer };
