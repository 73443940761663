export enum CONTENT_TYPES {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452794
     */
    TYPES = 'CONTENT_TYPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244333
     */
    TEMPLATES = 'ADMIN.NAV.CUSTOM_CONTENT_TYPE_TEMPLATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244334
     */
    LISTS = 'ADMIN.NAV.CUSTOM_CONTENT_TYPE_LISTS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=127427873
     * @todo migrate key from legacy project and change id.
     */
    CONTENT_TYPES = 'SEARCH_PLUS_CONTENT_TYPES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96023458
     */
    CREATE_FROM_SCRATCH = 'ADMIN.CONTENTS.CREATE_FROM_SCRATCH',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451638
     */
    ENABLE_WORKFLOW = 'ADMIN_CUSTOM_CONTENT_TYPE_ENABLE_WORKFLOW',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451639
     */
    ENABLE_WORKFLOW_HELP = 'ADMIN_CUSTOM_CONTENT_TYPE_ENABLE_WORKFLOW_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451640
     */
    END_DATE_DELTA = 'ADMIN_CUSTOM_CONTENT_TYPE_END_DATE_DELTA',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451643
     */
    HERITABLE = 'ADMIN_CUSTOM_CONTENT_TYPE_HERITABLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451644
     */
    HERITABLE_HELP = 'ADMIN_CUSTOM_CONTENT_TYPE_HERITABLE_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451647
     */
    LOCK_CREATE_CONTENT_FROM_SCRATCH_HELP = 'ADMIN_CUSTOM_CONTENT_TYPE_LOCK_CREATE_CONTENT_FROM_SCRATCH_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451648
     */
    MANDATORY_END_DATE = 'ADMIN_CUSTOM_CONTENT_TYPE_MANDATORY_END_DATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451649
     */
    MANDATORY_END_DATE_HELP = 'ADMIN_CUSTOM_CONTENT_TYPE_MANDATORY_END_DATE_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451652
     */
    NOTIFY = 'ADMIN_CUSTOM_CONTENT_TYPE_NOTIFY',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451653
     */
    NOTIFY_HELP = 'ADMIN_CUSTOM_CONTENT_TYPE_NOTIFY_HELP',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451655
     */
    SETTINGS = 'ADMIN_CUSTOM_CONTENT_TYPE_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7181778
     */
    HERITABLE_LOCK_HELP = 'ADMIN.CUSTOM_CONTENT_TYPE_HERITABLE_LOCK_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95928291
     */
    LOCK_CREATE_CONTENT_FROM_SCRATCH = 'ADMIN.CUSTOM_CONTENT_TYPES.LOCK_CREATE_CONTENT_FROM_SCRATCH',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451635
     */
    DELETE = 'ADMIN_CUSTOM_CONTENT_TYPE_DELETE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451636
     */
    DELETE_DESCRIPTION = 'ADMIN_CUSTOM_CONTENT_TYPE_DELETE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451654
     */
    CONTENT_TYPE_SAVE_SUCCESS = 'ADMIN_CUSTOM_CONTENT_TYPE_SAVE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451637
     */
    CONTENT_TYPE_DELETE_SUCCESS = 'ADMIN_CUSTOM_CONTENT_TYPE_DELETE_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451658
     */
    CONTENT_TYPE_TAG_DELETE_SUCCESS = 'ADMIN_CUSTOM_CONTENT_TYPE_TAG_DELETE_SUCCESS',
}
