import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Heading, TextField } from '@lumapps/lumx/react';
import { VideoItemFieldOrder } from '@lumapps/play-video/types';
import { isPlayHideAuthor } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { GenericSwitchList } from '@lumapps/widget-base/components/WidgetSettings/GenericSwitchList';
import { Setting } from '@lumapps/widget-base/types/setting';

import { WIDGET_PLAYLIST } from '../../../keys';
import { WidgetPlaylistProperties } from '../../../types';

import './index.scss';

export type VideoMetadata = Pick<WidgetPlaylistProperties, 'videoItemFieldOrder' | 'maxNumber'>;

export interface VideoMetadataSelectionProps {
    className?: string;
    properties: VideoMetadata;
    isLoading?: boolean;
    onFieldChange: <K extends keyof WidgetPlaylistProperties>(fieldName: K) => (nextValue: VideoItemFieldOrder) => void;
}

const CLASSNAME = 'video-metadata-selection';

/**
 *
 * @param VideoMetadataSelectionProps
 * @returns VideoMetadataSelection
 */
export const VideoMetadataSelection = ({
    className,
    properties,
    isLoading,
    onFieldChange,
}: VideoMetadataSelectionProps) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const isPlayHideAuthorEnabled = useSelector(isPlayHideAuthor);

    const settings: Setting<VideoItemFieldOrder>[] = [
        {
            label: translateKey(GLOBAL.THUMBNAIL),
            name: 'thumbnail',
        },
        {
            label: translateKey(GLOBAL.TITLE),
            name: 'title',
        },
        {
            label: translateKey(GLOBAL.DESCRIPTION),
            name: 'description',
        },
        ...(isPlayHideAuthorEnabled
            ? []
            : [
                  {
                      label: translateKey(GLOBAL.AUTHOR),
                      name: 'author' as VideoItemFieldOrder,
                  },
              ]),
        {
            label: translateKey(GLOBAL.DATE),
            name: 'date',
        },
        {
            label: translateKey(GLOBAL.VIEWS),
            name: 'views',
        },
        {
            label: translateKey(GLOBAL.DURATION),
            name: 'duration',
        },
    ];

    return (
        <div className={block([className])}>
            <Heading as="h5" className={element('subtitle')}>
                {translateKey(WIDGET_PLAYLIST.LIST_OF_VIDEOS)}
            </Heading>
            <TextField
                className={element('displayed-video')}
                onChange={(value) => onFieldChange('maxNumber')(Number(value) as unknown as VideoItemFieldOrder)}
                label={translateKey(WIDGET_PLAYLIST.DISPLAYED_VIDEO_NUMBER)}
                value={properties?.maxNumber?.toString()} // TODO: (play) Figure out why DEFAULT_WIDGET_PROPERTIES was not taken into account
                type="number"
            />
            <GenericSwitchList<VideoItemFieldOrder>
                className={element('switch-list')}
                items={settings}
                order={properties.videoItemFieldOrder}
                onChange={onFieldChange('videoItemFieldOrder')}
                isLoading={isLoading}
            />
        </div>
    );
};
