(function IIFE() {
    'use strict';

    /////////////////////////////

    function DirectoryEntryBlockController($scope, $timeout, Content, Directory, DirectoryEntry, DirectoryEntryUser,
                                           LxNotificationService, Metadata, Style, Translation, UserFavorite, Utils, User) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains the list of directory entry metadata classes.
         *
         * @type {Array}
         */
        var _metadataClasses = [];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The directory entry block directory.
         *
         * @type {Object}
         */
        vm.directory = {};

        /**
         * Contains the list of directory entry metadata.
         *
         * @type {Array}
         */
        vm.metadata = [];

        /**
         * Check if there'is 1 metadata visible in front at least.
         *
         * @type {boolean}
         */
        vm.metadataIsVisibleInFront = false;

        /**
         * Contains the list of directory entry tags.
         *
         * @type {Array}
         */
        vm.tags = [];

        /**
         * Contains the tooltip for the favorite button.
         *
         * @type {Object}
         */
        vm.favoriteTooltip = {
            add: Translation.translate('FRONT.DIRECTORY_ENTRIES.FAVORITE_BUTTON.ARIA'),
            remove: Translation.translate('FRONT.DIRECTORY_ENTRIES.FAVORITE_BUTTON.ARIA'),
        };

        /**
         * Services and utilities.
         */
        vm.Directory = Directory;
        vm.DirectoryEntry = DirectoryEntry;
        vm.Metadata = Metadata;
        vm.UserFavorite = UserFavorite;
        vm.Translation = Translation;
        vm.User = User;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Set directory entry metatada.
         */
        function _initMetadata() {
            angular.forEach(vm.directoryEntry.metadata, function forEachMetadata(metadataId) {
                vm.metadata.push(Metadata.getMetadataFromKey(metadataId, false, true));
            });

            vm.metadataIsVisibleInFront = Boolean(_.find(vm.metadata, {
                isVisibleFront: true,
            }));
        }

        /**
         * Set directory entry tags.
         */
        function _initTags() {
            angular.forEach(vm.directoryEntry.tags, function forEachTags(tagId) {
                var tag = Directory.getTagByUuid(tagId, vm.directory);

                if (angular.isDefinedAndFilled(tag) && !_.includes(vm.tags, tag)) {
                    vm.tags.push(tag);
                }
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if user can manage local entry.
         *
         * @return {boolean} If the connected user can add local entries.
         */
        function canManageLocalEntry() {
            return vm.directoryEntry.user && vm.manageEntry;
        }

        /**
         * Delete local entry.
         */
        function deleteLocalEntry() {
            LxNotificationService.confirm(
                Translation.translate('ADMIN_DIRECTORY_ENTRY_DELETE'),
                Translation.translate('ADMIN_DIRECTORY_ENTRY_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function onDeleteLocalEntryConfirm(answer) {
                    if (answer) {
                        DirectoryEntryUser.del(vm.directoryEntry.id, function success() {
                                LxNotificationService.success(Translation.translate(
                                    'ADMIN_DIRECTORY_ENTRY_DELETE_SUCCESS'));

                                // Patch to update the DirectoryEntry list.
                                // Patch for local entries from a widget directory entry.
                                if (angular.isDefined(_.get($scope.$parent.vm, 'parentCtrl.directoryEntries'))) {
                                    var parentDirectoryEntries = $scope.$parent.vm.parentCtrl.directoryEntries;
                                    for (var idx = 0; idx < parentDirectoryEntries.length; idx++) {
                                        if (parentDirectoryEntries[idx].id === vm.directoryEntry.id) {
                                            parentDirectoryEntries.splice(idx, 1);

                                            return;
                                        }
                                    }
                                    // Path for local entries from a module directory.
                                } else {
                                    var listKey = 'directory-entry-' + Content.getCurrent().externalKey;
                                    for (var jdx = 0; jdx < DirectoryEntry.getList(listKey).length; jdx++) {
                                        if (DirectoryEntry.getList(listKey)[jdx].id === vm.directoryEntry.id) {
                                            DirectoryEntry.getList(listKey).splice(jdx, 1);
                                            break;
                                        }
                                    }
                                }
                            },
                            function error(err) {
                                Utils.displayServerError(err);
                            });
                    }
                });
        }

        /**
         * Edit local entry.
         */
        function editLocalEntry() {
            DirectoryEntry.setCurrent(DirectoryEntry.formatServerObjectToClient(angular.copy(vm.directoryEntry)));

            Utils.waitForAndExecute('#directory-entry-settings');
        }

        /**
         * Get directory entry classes.
         *
         * @return {Array} An array of directory entry block classes.
         */
        function getClasses() {
            var classes = [];

            if (angular.isDefinedAndFilled(vm.directoryEntry.thumbnail)) {
                classes.push('directory-entry-block--has-thumbnail');
            }

            if (vm.theme === 'dark') {
                classes.push('directory-entry-block--theme-dark');
            } else {
                classes.push('directory-entry-block--theme-light');
            }

            if (vm.viewMode === 'grid') {
                classes.push('directory-entry-block--view-mode-grid');
            } else {
                classes.push('directory-entry-block--view-mode-list');
            }

            if (vm.viewModeVariant === 'ungroup') {
                classes.push('directory-entry-block--view-mode-variant-ungroup');
            } else {
                classes.push('directory-entry-block--view-mode-variant-group');
            }

            if (_.get(vm.widgetProperties, 'itemsSeparator')) {
                classes.push('directory-entry-block--has-separator');
            } else {
                classes.push('directory-entry-block--no-separator');
            }

            classes.push(_metadataClasses);

            return classes;
        }

        /**
         * Gzt metadata class according to metadata functionnal inner id.
         *
         * @param  {string} metadataFonctionnalInnerId The meatadata functionnal inner id.
         * @return {string} The metadata class.
         */
        function getMetadataFunctionalInnerId(metadataFonctionnalInnerId) {
            if (angular.isUndefinedOrEmpty(metadataFonctionnalInnerId)) {
                return undefined;
            }

            return 'directory-entry-block-metadata__metadata--' + metadataFonctionnalInnerId;
        }

        /**
         * Get directory entry block style according to widget style properties in ungroup variant.
         *
         * @return {Object} The directory entry block style.
         */
        function getStyle() {
            if (angular.isUndefinedOrEmpty(vm.widgetProperties)) {
                return {};
            }

            return Style.adjustShadow(Style.getBlockStyle(vm.widgetProperties, vm.viewMode, vm.viewModeVariant));
        }

        /////////////////////////////

        vm.canManageLocalEntry = canManageLocalEntry;
        vm.deleteLocalEntry = deleteLocalEntry;
        vm.editLocalEntry = editLocalEntry;
        vm.getClasses = getClasses;
        vm.getMetadataFunctionalInnerId = getMetadataFunctionalInnerId;
        vm.getStyle = getStyle;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * If the current directory entry is updated, init again the block to update the tags and metadata.
         */
        $scope.$on('directory-entry-save-' + vm.directoryEntry.id, function onDirectoryEntrySave() {
            $timeout(function onDirectoryEntryIsUpdated() {
                vm.init();
            });
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            angular.forEach(Directory.getLocalDirectories(), function forEachLocalDirectories(localDirectory) {
                if (localDirectory.id === vm.directoryEntry.directory) {
                    vm.directory = localDirectory;

                    if (angular.isDefinedAndFilled(vm.directoryEntry.metadata)) {
                        _initMetadata();
                    }

                    if (angular.isDefinedAndFilled(vm.directoryEntry.tags)) {
                        _initTags();
                    }
                }
            });

            _metadataClasses = Metadata.getMetadataClasses(vm.directoryEntry.metadata,
                'directory-entry-block--metadata-');
        };

        /////////////////////////////

        vm.init();
    }

    /////////////////////////////

    /**
     * A directive that displays a directory entry within a list.
     *
     * @param {Object} directoryEntry     The directory entry object to display.
     * @param {string} [manageEntry]      Indicates if connected user can add local entries.
     * @param {string} [theme]            The name of the theme to apply to the post block.
     * @param {string} [viewMode]         The widget view mode.
     * @param {string} [viewModeVariant]  The widget view mode variant.
     * @param {Object} [widgetProperties] The widget properties.
     */

    function directoryEntryBlockDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: DirectoryEntryBlockController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                directoryEntry: '=',
                manageEntry: '=?',
                theme: '@?',
                viewMode: '@?',
                viewModeVariant: '@?',
                widgetProperties: '=?',
            },
            templateUrl: '/client/front-office/modules/module/modules/directory/views/directory-entry-block.html',
        };
    }

    //////////////////////

    angular.module('Directives').directive('directoryEntryBlock', directoryEntryBlockDirective);
})();
