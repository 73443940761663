import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { HtmlAllowedAttributes, HtmlAllowedTags } from '../constants';

/** Sanitise the content excerpt HTML for full content display */
export const sanitizeExcerpt = (html: string) => {
    return sanitizeHTML(html, {
        useProfiles: { svg: true, html: true },
        addTags: HtmlAllowedTags,
        addAttr: HtmlAllowedAttributes,
    });
};
