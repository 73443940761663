import { generateUUID } from '@lumapps/utils/string/generateUUID';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';

/* eslint-disable lumapps/file-format */
(function IIFE() {
    'use strict';

    function ExceptionHandler($injector, $log, InitialSettings) {
        'ngInject';

        return function exceptionReporter(exception, cause) {};
    }

    angular.module('Factories').factory('$exceptionHandler', ExceptionHandler);
})();
