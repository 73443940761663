export enum SEGMENT_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165859704
     */
    SEGMENT_SIDE_NAV_TITLE = 'SEGMENT.SIDE_NAV_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171811402
     */
    NO_SEGMENTS = 'SEGMENT.NO_SEGMENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171811530
     */
    NO_SEGMENTS_DESCRIPTION = 'SEGMENT.NO_SEGMENTS_DESCRIPTION',
    /**
     *  https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227615588
     * */
    ARCHIVE_CONFIRMATION_TITLE = 'SEGMENT.ARCHIVE_CONFIRMATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227615678
     */
    ARCHIVE_CONFIRMATION_DESCRIPTION = 'SEGMENT.ARCHIVE_CONFIRMATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=227611351
     */
    STATUS_LIVE = 'SEGMENT.STATUS_LIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=236097301
     */
    SIZE_UNKNOWN = 'SEGMENT.SIZE_UNKNOWN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=236097314
     */
    EXPORT_FILE_GENERATION = 'SEGMENT.EXPORT_FILE_GENERATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=236097334
     */
    EXPORT_TITLE = 'SEGMENT.EXPORT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=238911809
     */
    EXPORT_CSV_TOOLTIPS = 'SEGMENT.EXPORT_CSV_TOOLTIPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239580332
     */
    EMPTY_EXPORT = 'SEGMENT.EMPTY_EXPORT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=236550663
     */
    CREATE_TOOLTIPS = 'SEGMENT.HEADER_TOOLTIPS_CREATE_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=361403056
     */
    IS_GROUP_FILTER = 'SEGMENT.IS_GROUP_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=366233453
     */
    AVAILABLE_IN_CONTENT = 'SEGMENT.IS_GROUP_FILTER_CHECKED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=382853487
     */
    AVAILABLE_FOR = 'SEGMENT.AVAILABLE_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=388094428
     */
    AVAILABLE_IN_ANALYTICS = 'SEGMENT.AVAILABLE_IN_ANALYTICS',
}
