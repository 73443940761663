import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const ProvisioningDashboardRoute: Route = {
    path: '/admin/provisioning-dashboard',
    appId: AppId.backOffice,
};

/** Retrieves the route for the provisioning dashboard admin */
const adminProvisioningDashboard = (): Route => ProvisioningDashboardRoute;
export { adminProvisioningDashboard, ProvisioningDashboardRoute };
