/* eslint-disable react/self-closing-comp */
import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Orientation, SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';

import './index.scss';

export interface HeaderSubtitleProps {
    /** The title to show */
    fullTitle: React.ReactNode;
    /** The subtitle to show */
    subtitle?: string | React.ReactNode;
    /** id for the header subtitle */
    headerSubtitleId?: string;
    /** custom subtitle props */
    subtitleProps?: Partial<TextProps>;
    /** custom class name */
    CLASSNAME: string;
    /** whether the header is loading or not */
    isLoading?: boolean;
}

export const HeaderSubtitle: React.FC<HeaderSubtitleProps> = ({
    subtitle,
    headerSubtitleId,
    subtitleProps,
    fullTitle,
    isLoading = false,
    CLASSNAME,
}) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox orientation={Orientation.vertical}>
            {fullTitle}

            {!isLoading ? (
                <Text
                    as="h2"
                    typography={Typography.caption}
                    id={headerSubtitleId}
                    {...subtitleProps}
                    className={element('subtitle', [subtitleProps?.className])}
                >
                    {subtitle}
                </Text>
            ) : (
                <SkeletonTypography typography={Typography.caption} width="128px" />
            )}
        </FlexBox>
    );
};
