import { connect } from '@lumapps/redux/react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
    DOMAIN,
    saveTermsAndConditions,
    fetchTermsAndConditions,
    resetUsersApproval,
    updateTermsAndConditions,
} from 'components/components/terms-and-conditions/ducks/termsAndConditions_actions';
import { EMPTY_TERMS_AND_CONDITIONS } from 'components/components/terms-and-conditions/termsAndConditions_constants';

import { TermsAndConditionsAdmin } from './TermsAndConditionsAdmin';

const mapStateToProps = (state) => {
    const { loadingState, termsAndConditions } = state;

    const currentTermsAndConditions = isEmpty(termsAndConditions) ? EMPTY_TERMS_AND_CONDITIONS : termsAndConditions;
    const isLoading = get(loadingState, [DOMAIN, 'loading'], false);

    return {
        isLoading,
        termsAndConditions: currentTermsAndConditions,
    };
};

const connectedTermsAndConditionsAdmin = connect(
    mapStateToProps,
    {
        fetchTermsAndConditions,
        resetUsersApproval,
        saveTermsAndConditions,
        updateTermsAndConditions,
    },
)(TermsAndConditionsAdmin);

/////////////////////////////

export { connectedTermsAndConditionsAdmin as TermsAndConditionsAdmin };
