(function IIFE() {
    'use strict';

    /////////////////////////////

    function InstanceFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/instance/:methodKey', {}, {
            'copy': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'copy',
                },
            },

            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            'deleteMulti': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'deleteMulti',
                },
            },

            'get': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/instance/:methodKey'
            },

            'list': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'search',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/instance/:methodKey'
            },

            'listSiblings': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/instance/siblings/:methodKey',
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        });
    }

    angular.module('Factories').factory('InstanceFactory', InstanceFactory);
})();
