import React from 'react';

import debounce from 'lodash/debounce';

import { useClassnames } from '@lumapps/classnames';
import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { mdiMagnify } from '@lumapps/lumx/icons';
import {
    TextField,
    IconButton,
    FlexBox,
    FlexBoxProps,
    TextFieldProps,
    IconButtonProps,
    Orientation,
    Emphasis,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import './index.scss';

export interface SearchFieldProps {
    /** search field icon label */
    label: string;
    /** callback to be executed once the user searches */
    onSearch: (text: string) => void;
    /** text field props */
    textFieldProps?: Omit<TextFieldProps, 'onChange'>;
    /** search icon button props */
    iconButtonProps?: Omit<IconButtonProps, 'onClick'>;
    /** component wrapper props */
    wrapperProps?: FlexBoxProps;
    /** callback to be executed once the text field changes */
    onChange?(text: string): void;
    /** whether the field should be displayed in a compact (smaller) manner */
    isCompact?: boolean;
    /**
     * whether the onSearch callback should be debounced or not. If true,
     * onSearch will be executed in a debounced fashion when the user enters
     * a text in the text field. By default this is false, meaning that onSearch
     * is executed when the Enter key is pressed, or the search icon button is clicked
     */
    debounced?: boolean;
    /** default value for the text field */
    value?: string;
    /** whether the entire component is disabled or not */
    isDisabled?: boolean;
}

const CLASSNAME = 'lumx-search-field';
/**
 * Component that groups a Text field and an Icon button in order to create a search field.
 * @family Filters
 * @param SearchFieldProps
 * @returns SearchField
 */
export const SearchField: React.FC<SearchFieldProps> = ({
    textFieldProps,
    iconButtonProps,
    wrapperProps,
    onSearch,
    label,
    debounced = false,
    isCompact = false,
    onChange,
    value = '',
    isDisabled = false,
}) => {
    const [text, setText] = React.useState(value);
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    /**
     * If either the text field or the icon button are disabled, then the entire component
     * is disabled.
     */
    const isSearchFieldDisabled = isDisabled || textFieldProps?.isDisabled || iconButtonProps?.isDisabled;

    React.useEffect(() => {
        setText(value);
    }, [value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = React.useCallback(
        debounce((filter: string) => {
            onSearch(filter);
        }, DEBOUNCE_DELAY),
        [onSearch],
    );

    /**
     * Callback to be executed when the search field text changes.
     * @param searchedText - text searched
     */
    const onTextChanged = (searchedText: string) => {
        setText(searchedText);

        if (debounced) {
            debouncedSearch(searchedText);
        }

        if (onChange) {
            onChange(searchedText);
        }
    };

    return (
        <FlexBox
            {...wrapperProps}
            orientation={Orientation.horizontal}
            className={block({ compact: isCompact }, [wrapperProps?.className])}
            as="form"
            role="search"
            onSubmit={(event: any) => {
                if (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                onSearch(text);
            }}
            // Disable form auto validation and manage it ourselves with the slice
            noValidate
        >
            <TextField
                clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                placeholder={label}
                aria-label={label}
                role="searchbox"
                {...textFieldProps}
                onChange={onTextChanged}
                className={element('text-field', [textFieldProps?.className])}
                value={text}
                isDisabled={isSearchFieldDisabled}
            />
            <IconButton
                label={label}
                icon={mdiMagnify}
                emphasis={Emphasis.medium}
                {...iconButtonProps}
                className={element('icon-button', [iconButtonProps?.className])}
                type="submit"
                isDisabled={isSearchFieldDisabled}
            />
        </FlexBox>
    );
};
