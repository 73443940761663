function LsStepperController($scope) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default controls labels.
     *
     * @type {Object}
     */
    const _DEFAULT_LABELS = {
        back: 'Back',
        cancel: 'Cancel',
        continue: 'Continue',
        optional: 'Optional',
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The active step index.
     *
     * @type {number}
     */
    vm.activeIndex = 0;

    /**
     * The list of steps.
     *
     * @type {Array}
     */
    vm.steps = [];

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a given step to the stepper.
     *
     * @param {Object} step The step to add.
     */
    function addStep(step) {
        vm.steps.push(step);
    }

    /**
     * Get the component classes.
     *
     * @return {Array} The list of classes.
     */
    function getClasses() {
        const classes = [];

        classes.push(`ls-stepper--layout-${vm.layout}`);

        if (vm.isLinear) {
            classes.push('ls-stepper--is-linear');
        }

        const step = vm.steps[vm.activeIndex];
        if (angular.isDefined(step)) {
            if (step.feedback) {
                classes.push('ls-stepper--step-has-feedback');
            }

            if (step.isLoading) {
                classes.push('ls-stepper--step-is-loading');
            }
        }

        return classes;
    }

    /**
     * Go to the given step index.
     *
     * @param {number}  index  The step index to go.
     * @param {boolean} bypass Whether to bypass checks or not.
     */
    function goToStep(index, bypass) {
        /* Check if the wanted step previous steps are optionals.
           If so, check if the step before the last optional step is valid to allow going to the wanted step
           from the nav (only if linear stepper). */
        let stepBeforeLastOptionalStep;

        if (!bypass && vm.isLinear) {
            for (let i = index - 1; i >= 0; i--) {
                if (angular.isDefined(vm.steps[i]) && !vm.steps[i].isOptional) {
                    stepBeforeLastOptionalStep = vm.steps[i];

                    break;
                }
            }

            if (angular.isDefined(stepBeforeLastOptionalStep)) {
                // Check validity only if step is dirty and editable.
                if (
                    !stepBeforeLastOptionalStep.pristine &&
                    angular.isFunction(stepBeforeLastOptionalStep.validator) &&
                    stepBeforeLastOptionalStep.isEditable
                ) {
                    const validity = stepBeforeLastOptionalStep.validator();

                    if (validity === true) {
                        stepBeforeLastOptionalStep.isValid = true;
                    } else {
                        stepBeforeLastOptionalStep.isValid = false;
                        stepBeforeLastOptionalStep.errorMessage = validity;
                    }
                }

                if (stepBeforeLastOptionalStep.isValid === true) {
                    bypass = true;
                }
            }
        }

        /* Check if the wanted step previous step is not valid to disallow going to the wanted step
           from the nav (only if linear stepper). */
        if (
            !bypass &&
            vm.isLinear &&
            angular.isDefined(vm.steps[index - 1]) &&
            (angular.isUndefined(vm.steps[index - 1].isValid) || vm.steps[index - 1].isValid === false)
        ) {
            return;
        }

        if (index < vm.steps.length) {
            vm.activeIndex = parseInt(index, 10);
            vm.steps[vm.activeIndex].pristine = false;
            $scope.$emit('ls-stepper__step', vm.id, index, index === 0, index === vm.steps.length - 1);
        }
    }

    /**
     * Check if the stepper is complete.
     *
     * @return {boolean} Whether the stepper is complete or not.
     */
    function isComplete() {
        let countMandatory = 0;
        let countValid = 0;

        for (let i = 0, len = vm.steps.length; i < len; i++) {
            if (!vm.steps[i].isOptional) {
                countMandatory++;

                if (vm.steps[i].isValid === true) {
                    countValid++;
                }
            }
        }

        if (countValid === countMandatory) {
            vm.complete();

            return true;
        }

        return false;
    }

    /**
     * Initialize the controller.
     */
    function init() {
        vm.controls = angular.isDefined(vm.controls) ? vm.controls : true;
        vm.isLinear = angular.isDefined(vm.isLinear) ? vm.isLinear : true;
        vm.labels = angular.isDefined(vm.labels) ? vm.labels : _DEFAULT_LABELS;
        vm.layout = angular.isDefined(vm.layout) ? vm.layout : 'horizontal';
    }

    /**
     * Update step data.
     *
     * @param {Object} step The step to update.
     */
    function updateStep(step) {
        for (let i = 0, len = vm.steps.length; i < len; i++) {
            if (vm.steps[i].uuid === step.uuid) {
                vm.steps[i].index = step.index;
                vm.steps[i].label = step.label;

                return;
            }
        }
    }

    /////////////////////////////

    vm.addStep = addStep;
    vm.getClasses = getClasses;
    vm.goToStep = goToStep;
    vm.isComplete = isComplete;
    vm.init = init;
    vm.updateStep = updateStep;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $scope.$on('ls-stepper__go-to-step', (evt, id, stepIndex, bypass) => {
        if (angular.isDefined(id) && id !== vm.id) {
            return;
        }

        goToStep(stepIndex, bypass);
    });

    $scope.$on('ls-stepper__cancel', (evt, id) => {
        if ((angular.isDefined(id) && id !== vm.id) || !angular.isFunction(vm.cancel)) {
            return;
        }

        vm.cancel();
    });
}

function LsStepperDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrl) {
        ctrl.init();
    }

    return {
        bindToController: true,
        controller: LsStepperController,
        controllerAs: 'vm',
        link,
        restrict: 'E',
        scope: {
            cancel: '&?lsCancel',
            complete: '&lsComplete',
            controls: '=?lsShowControls',
            id: '@?lsId',
            isLinear: '=?lsIsLinear',
            labels: '=?lsLabels',
            layout: '@?lsLayout',
        },
        templateUrl: '/client/common/modules/stepper/views/stepper.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsStepper', LsStepperDirective);

/////////////////////////////

export { LsStepperDirective };
