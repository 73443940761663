import React, { useCallback } from 'react';

import isEqual from 'lodash/isEqual';

import { BaseApiError } from '@lumapps/base-api';
import { requestMembership } from '@lumapps/communities/api';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Button, Emphasis, Size, Theme } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_COMMUNITY_LIST_KEYS } from '../../keys';
import { RequestStatus } from '../../types';

interface AccessRequestProps {
    scope: string;
    theme: Theme;
    requestId: string;
    requestInitialStatus?: RequestStatus;
}

enum RequestMembershipErrorMsg {
    alreadyRequested = 'COMMUNITY_REQUEST_ALREADY_EXISTS',
    alreadyAuthorized = 'COMMUNITY_REQUEST_ALREADY_AUTHORIZED',
}

export const AccessRequest: React.FC<AccessRequestProps> = ({ requestInitialStatus, requestId, theme, scope }) => {
    const { translateKey } = useTranslate();
    const { error } = useNotification();
    const { get } = useDataAttributes(scope);

    const [status, setStatus] = React.useState<RequestStatus | undefined>(requestInitialStatus);
    const [isLoading, setIsLoading] = React.useState(false);

    const onRequestAccess = useCallback(async () => {
        setIsLoading(true);

        try {
            setStatus('pending');

            const {
                data: { requestStatus },
            } = await requestMembership({ communityKey: requestId });

            setStatus(requestStatus as RequestStatus);
        } catch (err) {
            if (err instanceof BaseApiError && isEqual(400, err?.response?.status)) {
                const { message } = err?.response?.data?.error ?? {};

                switch (message) {
                    case RequestMembershipErrorMsg.alreadyRequested:
                        error({ translate: WIDGET_COMMUNITY_LIST_KEYS.REQUEST_ALREADY_EXISTS });
                        setStatus('pending');
                        break;
                    case RequestMembershipErrorMsg.alreadyAuthorized:
                        error({ translate: WIDGET_COMMUNITY_LIST_KEYS.REQUEST_ALREADY_AUTHORIZED });
                        setStatus('accepted');
                        break;
                    default:
                        error({ translate: GLOBAL.GENERIC_ERROR });
                        break;
                }
            } else {
                error({ translate: GLOBAL.GENERIC_ERROR });
            }
        } finally {
            setIsLoading(false);
        }
    }, [error, requestId]);

    if (status === 'accepted') {
        return null;
    }

    return (
        <Button
            theme={theme}
            onClick={onRequestAccess}
            emphasis={Emphasis.medium}
            size={Size.s}
            isDisabled={isLoading || status === 'pending'}
            {...get({ element: 'button', action: 'request-access' })}
        >
            {translateKey(
                status === 'pending'
                    ? WIDGET_COMMUNITY_LIST_KEYS.REQUEST_ACCESS_PENDING
                    : WIDGET_COMMUNITY_LIST_KEYS.REQUEST_ACCESS,
            )}
        </Button>
    );
};
