/* istanbul ignore file */

import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

/**
 * Article view page path.
 *
 * Same as content view page path but with `type` replaced with `article`.
 */
export const articleViewPath = `/${URL_PREFIX}/article/id/:id`;

export const articleViewRoute: Route = {
    path: articleViewPath,
    appId: AppId.frontOffice,
};

export const articleView = (articleId: string, anchor?: string, instanceSlug?: string): Route => {
    const mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined = {
        id: articleId,
        type: 'article',
    };

    return {
        ...articleViewRoute,
        params: { id: articleId },
        instanceSlug,
        anchor,
        mobilePageDescriptor,
    };
};
