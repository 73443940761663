import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ColorPalette, Link } from '@lumapps/lumx/react';

import './index.scss';

export interface SkipLinkProps {
    /** Id of the element to redirect to, (without the #) */
    anchorId: string;
    /** a classname to set on the link */
    className?: string;
    /**
     * Whether the link should be "floating"
     * Will apply an "absolute" position and position the link to the top left of
     * the parent element.
     */
    floating?: boolean;
    /**
     * The text of the skip link.
     * It should either describe where the link will skip to (ex: skip to content).
     * or what is skipped (ex: skip carousel)
     */
    children: string;
}

const CLASSNAME = 'skip-link';

/**
 * Invisible link that is only displayed once focused.
 * These links should be used to allow to skip the next section directly to another section
 *
 * @family Links
 * @param SkipLinkProps
 * @returns SkipLink
 */
export const SkipLink: React.FC<SkipLinkProps> = ({ anchorId: anchor, children, floating, className }) => {
    const { block } = useClassnames(CLASSNAME);

    return (
        <Link
            className={block({ floating: Boolean(floating) }, className)}
            href={`#${anchor}`}
            color={ColorPalette.light}
        >
            {children}
        </Link>
    );
};
