import filter from 'lodash/filter';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import isArray from 'lodash/isArray';
import map from 'lodash/map';

import { loadFroala } from 'common/froala/modules/froala_utils';
import { addBlob } from '@lumapps/lumx-images/components/BlobThumbnail/store';

/////////////////////////////

function MediaPickerController(
    $injector,
    $ocLazyLoad,
    $scope,
    $timeout,
    Document,
    Layout,
    LxDialogService,
    LxNotificationService,
    Media,
    MediaConstant,
    Translation,
    Upload,
    User,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const vmPicker = this;
    // eslint-disable-next-line consistent-this
    let vm;

    let documentProvider;

    if (includes($scope.vm.allowedProviders, 'community')) {
        documentProvider = $injector.get('CommunityDocumentProvider');
    } else {
        documentProvider = $injector.get($scope.vm.providerService || 'DocumentProvider');
    }

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default delay to indicate the folder is beeing saved.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _FOLDER_SAVING_DELAY = 500;

    /**
     * The timeout before reloading the content of the trash.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _TRASH_RELOAD_TIMEOUT = 200;

    /**
     * The unselectable media type in folder picker mode.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _UNSELECTABLE_TYPES = ['ONEDRIVE_SITE'];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The folder dialog identifier.
     *
     * @type {string}
     * @constant
     * @readOnly
     */
    vmPicker.FOLDER_DIALOG_ID = 'folder-dialog';

    /**
     * Can user manage from this category.
     *
     * @type {boolean}
     */
    vmPicker.canManage = false;

    /**
     * Can user pick from this category.
     *
     * @type {boolean}
     */
    vmPicker.canPick = false;

    /**
     * List of enabled documents providers.
     *
     * @type {Array}
     */
    vmPicker.enabledDocumentsProviders = [];

    /**
     * The list of providers/categories icons.
     *
     * @type {Object}
     */
    vmPicker.icons = {
        COMMUNITY_COMMUNITY: 'google-circles-extended',
        DRIVE: 'google-drive',
        DRIVE_MINE: 'google-drive',
        DRIVE_RECENT: 'clock',
        DRIVE_SHARED: 'account-multiple',
        DRIVE_STARRED: 'star',
        DRIVE_TEAM: 'account-box',
        LOCAL_LIBRARY: 'folder',
        LOCAL_MINE: 'account',
        LOCAL_TRASH: 'delete',
        ONEDRIVE: 'onedrive',
        ONEDRIVE_MINE: 'onedrive',
        ONEDRIVE_SITES: 'sitemap',
    };

    /**
     * Contains some indicators about the controller status.
     *
     * @type {Object}
     */
    vmPicker.is = {
        saving: {
            document: false,
        },
    };

    /**
     * Is current provider category a trash.
     *
     * @type {boolean}
     */
    vmPicker.isTrash = false;

    /**
     * Is user writer on current folder.
     *
     * @type {boolean}
     */
    vmPicker.isWriterOnCurrentFolder = false;

    /**
     * The available actions for one item.
     *
     * @type {Object}
     */
    vmPicker.itemActions = {
        primary: [
            // Download.
            {
                action(item) {
                    Document.download(item, true);
                },
                icon: 'download',
                label: Translation.translate('FRONT.MEDIA_PICKER.DOWNLOAD'),
                visibility(item) {
                    // Check if the item is drillable (only files are not drillable) Linked to LUM-9191
                    const isDrillable = get(item, 'properties.drillable', true);

                    return !vmPicker.isTrash && !isDrillable;
                },
            },
            // Restore.
            {
                action(item) {
                    vmPicker.restoreMedia(item);
                },
                icon: 'restore',
                label: Translation.translate('RESTORE'),
                visibility() {
                    return vmPicker.isTrash;
                },
            },
            // Delete.
            {
                action(item) {
                    vmPicker.deleteMedia(item);
                },
                icon: 'delete',
                label: Translation.translate('GLOBAL.DELETE'),
                visibility() {
                    return vmPicker.isTrash;
                },
            },
        ],
        secondary: [
            // Quick view.
            {
                action(item) {
                    vm.toggleDetail(item);
                },
                icon: 'information-outline',
                label: Translation.translate('GLOBAL.MORE_DETAILS'),
                visibility() {
                    return angular.isUndefinedOrEmpty(vm.selectedDetailObject);
                },
            },
            // Get link.
            {
                action(item) {
                    vmPicker.getMediaLink(item);
                },
                icon: 'link',
                label: Translation.translate('FRONT.MEDIA_PICKER.GET_LINK'),
                visibility(item) {
                    const itemKind = get(item, 'kind', MediaConstant.KIND.MEDIA);

                    return (
                        !vmPicker.isTrash &&
                        (itemKind === MediaConstant.KIND.MEDIA ||
                            (itemKind === MediaConstant.KIND.DRIVE && !item.isFolder))
                    );
                },
            },
            // Move to trash.
            {
                action(item) {
                    vmPicker.trashMedia(item);
                },
                icon: 'delete',
                label: Translation.translate('FRONT.MEDIA_PICKER.TRASH'),
                visibility(item) {
                    const itemKind = get(item, 'kind', MediaConstant.KIND.MEDIA);
                    const itemType = get(item, ['properties', 'type']);

                    const isGoogleSharedDrive = itemType === 'GOOGLE_TEAMDRIVE';
                    const isSharepointSite = itemType === 'ONEDRIVE_SITE';

                    return (
                        !isGoogleSharedDrive &&
                        !isSharepointSite &&
                        !vm.is.selectionShown &&
                        !vmPicker.isTrash &&
                        vmPicker.userCanEdit() &&
                        includes(
                            [MediaConstant.KIND.MEDIA, MediaConstant.KIND.FOLDER, MediaConstant.KIND.DRIVE],
                            itemKind,
                        )
                    );
                },
            },
        ],
    };

    /**
     * Contains the information of the new folder the user wants to create.
     * Used in the folder admin dialog.
     *
     * @type {Object}
     */
    vmPicker.newFolder = {};

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vmPicker.Document = Document;
    vmPicker.MediaConstant = MediaConstant;
    vmPicker.Translation = Translation;
    vmPicker.Upload = Upload;
    vmPicker.User = User;
    vmPicker.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get current category id.
     *
     * @return {string} Current category id.
     */
    function _getCurrentCategoryId() {
        return vm.currentFilter.categoryId;
    }

    /**
     * Get current provider id.
     *
     * @return {string} Current provider id.
     */
    function _getCurrentProviderId() {
        return vm.currentFilter.providerId;
    }

    /**
     * Get current category.
     *
     * @return {Promise} The current category.
     */
    function _getCurrentProvider() {
        return documentProvider.getProvider(_getCurrentProviderId());
    }

    /**
     * Get current category.
     *
     * @return {Promise} Current category request promise.
     */
    function _getCurrentCategory() {
        return documentProvider.getProviderCategory(_getCurrentProviderId(), _getCurrentCategoryId());
    }

    /**
     * Get current folder docPath.
     *
     * @return {Promise} Current folder docPath.
     */
    async function _getCurrentFolderPath() {
        if (vm.current.objectPathMap.length > 0) {
            return last(vm.current.objectPathMap).docPath;
        }

        // eslint-disable-next-line no-use-before-define
        const { rootPath } = await _getCurrentCategory();

        return rootPath;
    }

    /**
     * Get current folder permissions.
     *
     * @return {Promise} User permissions on current folder.
     */
    async function _getCurrentFolderPermissions() {
        // eslint-disable-next-line no-use-before-define
        const category = await _getCurrentCategory();
        const searchParameters = get(category, 'properties', {});
        const docPath = await _getCurrentFolderPath();

        return Document.getPermissions({ docPath, searchParameters });
    }

    /**
     * Get current path docId.
     *
     * @return {string} Current path docId.
     */
    async function _getCurrentPathDocId() {
        if (vm.current.objectPathMap.length > 0) {
            return get(last(vm.current.objectPathMap), 'docPath');
        }

        // eslint-disable-next-line no-use-before-define
        const category = await _getCurrentCategory();

        return category.rootPath;
    }

    /**
     * Get default document provider.
     *
     * @return {Object} Default document provider.
     */
    function _getDefaultProvider() {
        if (vmPicker.enabledDocumentsProviders.length === 0) {
            throw new Error('No enabled documents providers');
        }

        return first(vmPicker.enabledDocumentsProviders);
    }

    /**
     * Get default provider category.
     *
     * @param  {Object} provider Provider to get default category from.
     * @return {Object} Default provider category.
     */
    function _getDefaultProviderCategory(provider) {
        return first(provider.categories);
    }

    /**
     * Set canManage boolean to true if category permit it.
     *
     * @return {Promise} Set promise.
     */
    async function _initCurrentCategoryCanManage() {
        // eslint-disable-next-line no-use-before-define
        const category = await _getCurrentCategory();

        vmPicker.canManage = get(category, 'canManage', false);
    }

    /**
     * Set canPick boolean to true if category permit it.
     *
     * @return {Promise} Set promise.
     */
    async function _initCurrentCategoryCanPick() {
        // eslint-disable-next-line no-use-before-define
        const category = await _getCurrentCategory();

        vmPicker.canPick = get(category, 'canPick', false);
    }

    /**
     * Set isTrash boolean if provider category is one.
     *
     * @return {Promise} Set promise.
     */
    async function _initCurrentCategoryIsTrash() {
        // eslint-disable-next-line no-use-before-define
        const category = await _getCurrentCategory();
        const categoryId = get(category, 'id');

        vmPicker.isTrash = categoryId === 'TRASH';
    }

    /**
     * Init sortOrder param with current category value.
     *
     * @return {Promise} Init promise.
     */
    async function _initCurrentCategorySortOrder() {
        // eslint-disable-next-line no-use-before-define
        const category = await _getCurrentCategory();
        const sortOrder = get(category, 'sortOrder');

        if (angular.isDefinedAndFilled(sortOrder)) {
            vm.currentFilter.sortOrder = angular.fastCopy(sortOrder[0]);
        } else {
            delete vm.currentFilter.sortOrder;
        }
    }

    /**
     * Redirect user to the next community folder if the comunity Drive is not available.
     */
    function fallbackCommunityDriveAccess() {
        // Current provider is unavailable.
        const currentProviderId = _getCurrentProviderId();
        // eslint-disable-next-line array-callback-return
        vmPicker.enabledDocumentsProviders.map((provider) => {
            if (provider.id === currentProviderId) {
                provider.unavailable = true;
            }
        });
        // Avoid infinite loop on the default folder.
        if (currentProviderId !== 'local') {
            // Static fallback on the LumApps private drive. This storage should always be available for every user.
            vmPicker.changeProviderAndCategory({ id: 'local' }, { id: 'MINE' });
        }
    }

    /**
     * Get and store current folder permissions.
     */
    async function _initCurrentFolderPermissions() {
        const permission = await _getCurrentFolderPermissions();
        if (permission.unavailable) {
            fallbackCommunityDriveAccess();
        } else {
            // Value comes from a promise so we have to force digest cycle update.
            $scope.$apply(() => {
                vmPicker.isWriterOnCurrentFolder = permission.isWriter;
            });
        }
    }

    /**
     * Display a notification to the user for trashing an element media or folder.
     *
     * @param {Array}    selectedMedias  A list of media elements to trash.
     * @param {Array}    selectedFolders A list of folder elements to trash.
     * @param {string}   headerKey       The header message to display.
     * @param {string}   descKey         The description message to display.
     * @param {string}   successKey      The success message to display.
     * @param {Function} cb              The callback success function.
     */
    function _lxNotificationTrashSelectedElement(selectedMedias, selectedFolders, headerKey, descKey, successKey, cb) {
        if (angular.isUndefinedOrEmpty([selectedMedias, selectedFolders], 'every')) {
            return;
        }

        LxNotificationService.confirm(
            Translation.translate(headerKey),
            Translation.translate(descKey),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (!answer) {
                    return;
                }

                const documentsToTrash = map(selectedMedias, 'docPath').concat(map(selectedFolders, 'docPath'));

                angular.forEach(documentsToTrash, (doc) => {
                    Document.trash(doc, vm.pickerId).then(() => {
                        LxNotificationService.success(Translation.translate(successKey));

                        return cb();
                    }, Utils.displayServerError);
                });
            },
        );
    }

    /**
     * Reload picker content, using new filters.
     *
     * @return {Promise} Reload promise.
     */
    function _reloadContent() {
        return vm.executeFilter();
    }

    /**
     * Reset user permissions on current folder.
     */
    function _resetCurrentFolderPermissions() {
        vmPicker.isWriterOnCurrentFolder = false;
    }

    /**
     * Reset search parameters.
     */
    function _resetSearchParameters() {
        vm.searchParameters = {};
    }

    /**
     * Set enabled documents providers.
     */
    async function _setEnabledDocumentsProviders() {
        let providers = await documentProvider.getList();

        if (angular.isDefinedAndFilled(vm.allowedProviders)) {
            providers = filter(providers, (provider) => includes(vm.allowedProviders, provider.id));
        }

        vmPicker.enabledDocumentsProviders = angular.fastCopy(providers);
    }

    /**
     * Set the document provider to use.
     *
     * @param {Object} provider The provider to use.
     */
    async function _setProvider({ id }) {
        if (angular.isUndefinedOrEmpty(id)) {
            return;
        }

        vm.currentFilter.providerId = id;
        // Note: currentProviderCategories is used to prevent function calls in template.
        vm.currentProviderCategories = await documentProvider.getProviderCategories(id);
    }

    /**
     * Set the provider category id to use.
     *
     * @param {Object} category The category to use.
     */
    async function _setProviderCategory({ id }) {
        vm.currentFilter.categoryId = id;
        await _initCurrentCategorySortOrder();
        await _initCurrentCategoryIsTrash();
        await _initCurrentCategoryCanPick();
        await _initCurrentCategoryCanManage();
    }

    /**
     * Set default provider and it's default category.
     */
    async function _setDefaultProviderAndCategory() {
        const provider = _getDefaultProvider();
        const category = _getDefaultProviderCategory(provider);

        vm.resetPath();
        await _setProvider(provider);
        await _setProviderCategory(category);
    }

    /**
     * Reset picker.
     */
    async function _resetPicker() {
        await _setEnabledDocumentsProviders();

        if (angular.isUndefinedOrEmpty(vm.currentFilter.providerId)) {
            await _setDefaultProviderAndCategory();
        } else {
            await _setProviderCategory({ id: vm.currentFilter.categoryId });
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Cancel the folder creation.
     */
    function cancelFolder() {
        vmPicker.newFolder = {};
    }

    /**
     * Set Provider Category and execute changes.
     *
     * @param  {Object}  provider Provider to switch on.
     * @param  {Object}  category Category to switch on.
     * @return {Promise} Request promise.
     */
    async function changeProviderAndCategory(provider, category) {
        if (provider.unavailable) {
            return;
        }
        vm.initFilterAndOrder();
        _resetSearchParameters();

        await _setProvider(provider);
        await _setProviderCategory(category);

        _resetCurrentFolderPermissions();

        vm.executeFilter(true).then(_initCurrentFolderPermissions);
    }

    /**
     * Delete a given media.
     *
     * @param {Object} media The media to delete.
     */
    function deleteMedia(media) {
        if (angular.isUndefinedOrEmpty(get(media, 'id'))) {
            return;
        }

        LxNotificationService.confirm(
            Translation.translate('MEDIA_PICKER_DELETE'),
            Translation.translate('MEDIA_PICKER_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (!answer) {
                    return;
                }
                Media.del(
                    media.id,
                    () => {
                        if (vm.currentFilter.providerId === 'local') {
                            const index = vm.currentObjectList.findIndex((med) => med.id === media.id);
                            vm.currentObjectList.splice(index, 1);
                        } else {
                            vm.executeFilter();
                        }

                        LxNotificationService.success(Translation.translate('MEDIA_PICKER_DELETE_SUCCESS'));
                    },
                    Utils.displayServerError,
                    vm.pickerId,
                );
            },
        );
    }

    /**
     * Empty the trash.
     */
    function emptyTrash() {
        if (!User.isAdmin()) {
            return;
        }

        LxNotificationService.confirm(
            Translation.translate('FRONT.MEDIA_PICKER.TRASH_EMPTY'),
            Translation.translate('FRONT.MEDIA_PICKER.TRASH_EMPTY_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            async (answer) => {
                if (!answer) {
                    return;
                }
                const { rootPath } = await _getCurrentCategory();

                Document.emptyTrash(rootPath, vm.pickerId).then(() => {
                    LxNotificationService.success(Translation.translate('FRONT.MEDIA_PICKER.TRASH_EMPTY_SUCCESS'));
                    $timeout(() => {
                        _reloadContent();
                    }, _TRASH_RELOAD_TIMEOUT);
                }, Utils.displayServerError);
            },
        );
    }

    /**
     * Add media from multiple uploaded files.
     *
     * @return {Promise} Reload promise.
     */
    function finishFileUpload(results, scope, files) {
        Upload.setProgressValue(0);


        if (vm.currentFilter.providerId === 'local') {
            results.forEach((result, index) => {
                const media = Document.getImageThumbnailForMedia(result.items[0]);
                const file = files[index];
    
                addBlob({ ...media, blobUrl: URL.createObjectURL(file.file) }, true);
    
                vm.currentObjectList.unshift(result.items[0]);
            });
        } else {
            vm.currentFilter.sortOrder = vm.currentFilter.providerId === 'drive' ? '-createdAt' : undefined;
            vm.searchParameters = {};

            return vm.executeFilter();
        }
    }

    /**
     * Get current folder name.
     *
     * @return {string} The current folder name or the category name.
     */
    function getCurrentFolderName() {
        if (vm.current.objectPathMap.length > 0) {
            return last(vm.current.objectPathMap).name;
        } else if (
            angular.isDefinedAndFilled(vm.currentFilter.providerId) &&
            angular.isDefinedAndFilled(vm.currentFilter.categoryId)
        ) {
            return `FRONT.WIDGET_FILE_LIST.SETTINGS.FOLDER.${vm.currentFilter.providerId.toUpperCase()}.${vm.currentFilter.categoryId.toUpperCase()}`;
        }

        return undefined;
    }

    /**
     * Generate the link of the given media.
     *
     * @param {Object} media The media to display the link of.
     */
    function getMediaLink(media) {
        const mediaContent = Document.getMediaContentByLang(media, true);

        LxDialogService.alert({
            buttons: {
                ok: Translation.translate('GLOBAL.CLOSE'),
            },
            text: `<span style="word-break: break-all">${mediaContent.downloadUrl}</span>`,
            title: Translation.translate('FRONT.MEDIA_PICKER.MEDIA_LINK'),
        });
    }

    /**
     * Get sort order on given field.
     *
     * @param  {string} field The field to check the order of.
     * @return {string} The field sort order.
     */
    function getSortOrder(field) {
        if (vm.currentFilter.sortOrder === field) {
            return 'asc';
        } else if (vm.currentFilter.sortOrder === `-${field}`) {
            return 'desc';
        }

        return '';
    }

    /**
     * Return uploaded files.
     *
     * @return {Array} An array of files that have been uploaded.
     */
    function getUploadedFiles() {
        return Upload.files;
    }

    /**
     * Get upload url request parameters.
     *
     * @return {Object} Request parameters.
     */
    async function getUploadUrlRequestParameters() {
        const parentPath = await _getCurrentPathDocId();
        const shared = get(await _getCurrentCategory(), 'properties.shared', false);

        return {
            parentPath,
            shared,
        };
    }

    /**
     * Defines if media has a thumbnail.
     *
     * @param  {Object}  media The media to check.
     * @return {boolean} Whether the media has a thumbnail or not.
     */
    function hasThumbnail(media) {
        if (media.properties && media.properties.type) {
            const { type } = media.properties;
            return ['GOOGLE_FILE', 'LUMDRIVE_FILE', 'ONEDRIVE_FILE'].includes(type);
        }
        return true;
    }

    /**
     * Check if an object can be navigated into.
     *
     * @param  {Object}  media The media to check.
     * @return {boolean} If we can go inside of the object.
     */
    function isNavigable(media) {
        return media.properties.drillable;
    }

    /**
     * Whether the media is selectable or not.
     *
     * @param  {Object}  media The media to check.
     * @return {boolean} Whether the media is selectable or not.
     */
    function isSelectable(media) {
        if (!vmPicker.canPick || vm.embedded || includes(_UNSELECTABLE_TYPES, media.properties.type)) {
            return false;
        }

        return (
            (angular.isDefinedAndFilled(vm.allowFolderPick) && vm.allowFolderPick && vmPicker.isNavigable(media)) ||
            ((angular.isUndefinedOrEmpty(vm.allowFolderPick) || !vm.allowFolderPick) && !vmPicker.isNavigable(media))
        );
    }

    /**
     * Navigate inside of a site, drive or folder.
     *
     * @param  {Object}  media The media we want to navigate inside of.
     * @return {Promise} Navigation promise.
     */
    function navigatePath(media) {
        _resetCurrentFolderPermissions();

        return vm.navigatePath(media).then(_initCurrentFolderPermissions);
    }

    /**
     * Navigate back to the parent Folder.
     *
     * @return {Promise} Navigation promise.
     */
    function navigateToParentFolder() {
        _resetCurrentFolderPermissions();

        return vm.navigateToParentPath().then(_initCurrentFolderPermissions);
    }

    /**
     * Navigate, toggle details or toggle selection on media click.
     *
     * @param {Object} media The clicked media.
     */
    function onMediaClick(media) {
        if (vmPicker.isSelectable(media)) {
            vmPicker.toggleMediaSelection(media);
        }

        if (Layout.breakpoint !== 'desk') {
            vmPicker.onMediaDblClick(media);
        }
    }

    /**
     * Navigate on media double click.
     *
     * @param {Object} media The clicked media.
     */
    function onMediaDblClick(media) {
        if (vmPicker.isNavigable(media)) {
            vmPicker.navigatePath(media);
        }
    }

    /**
     * Open folder dialog creation.
     *
     * @return {Promise} Open folder dialog promise.
     */
    function openFolderDialog() {
        return Utils.waitForAndExecute(`#${vmPicker.FOLDER_DIALOG_ID}`);
    }

    /**
     * Open media uploader.
     */
    function openMediaUploader() {
        $timeout(() => {
            angular
                .element(`#${vm.pickerId} .media-picker-uploader`)
                .find('.uploader__placeholder')
                .click();
        });
    }

    /**
     * Restore a given media from trash.
     *
     * @param {Object} media The media to restore.
     */
    function restoreMedia(media) {
        if (angular.isUndefinedOrEmpty(media)) {
            return;
        }

        LxNotificationService.confirm(
            Translation.translate('FRONT.MEDIA_PICKER.RESTORE'),
            Translation.translate('FRONT.MEDIA_PICKER.RESTORE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (!answer) {
                    return;
                }

                Document.restore(media.docPath, vm.pickerId)
                    .then(() => {
                        vm.executeFilter();
                        LxNotificationService.success(Translation.translate('FRONT.MEDIA_PICKER.RESTORE_SUCCESS'));
                    })
                    .catch(Utils.displayServerError);
            },
        );
    }

    /**
     * Save current folder.
     *
     * @return {Promise} Save promise.
     */
    async function saveFolder() {
        if (vmPicker.is.saving.document) {
            return Promise.resolve();
        }

        vmPicker.is.saving.document = true;

        if (vm.current.objectPathMap.length > 0) {
            const media = last(vm.current.objectPathMap);

            vmPicker.newFolder.parentFolder = vm.objectService.getMediaContentByLang(media, true, 'default').docPath;
        }

        return Document.createFolder({
            listKey: vm.pickerId,
            names: vmPicker.newFolder.name,
            parentPath: await _getCurrentPathDocId(),
            shared: get(await _getCurrentCategory(), 'properties.shared', false),
        }).then(
            () => {
                vmPicker.cancelFolder();

                LxDialogService.close(vmPicker.FOLDER_DIALOG_ID);

                LxNotificationService.success(Translation.translate('FRONT.MEDIA_PICKER.FOLDER_CREATE_SUCCESS'));

                return _reloadContent().then(() => {
                    $timeout(() => {
                        vmPicker.is.saving.document = false;
                    }, _FOLDER_SAVING_DELAY);
                });
            },
            (err) => {
                Utils.displayServerError(err);
                vmPicker.is.saving.document = false;
            },
        );
    }

    /**
     * Toggle media selection.
     *
     * @param {Object} media Media to toggle selection.
     */
    function toggleMediaSelection(media) {
        const modelObject = {
            categoryId: _getCurrentCategoryId(),
            id: media.id,
            object: media,
            providerId: _getCurrentProviderId(),
            blobImage: Document.getImageThumbnailForMedia(media, true, 500, undefined, (vm.is.selectionShown) ? media.override : {})
        };

        vm.toggleSelection(modelObject);
    }

    function generateBlobImageForMedias(images) {
        if (isArray(images)) {
            return images.map((m) => {
                Object.assign(m, { blobImage: vm.objectService.getImageThumbnailForMedia(m.object, true, 500, undefined, (vm.is.selectionShown) ? m.override : {}) });
                return m;
            });
        }

        return {
            ...images,
            blobImage: vm.objectService.getImageThumbnailForMedia(images.object, true, 500, undefined, (vm.is.selectionShown) ? images.override : {}),
        };
    }

    /**
     * Toggle the selection sort order.
     *
     * @param  {string}  sortOrder The sort order to be toggled.
     * @return {Promise} Request promise.
     */
    async function toggleSortOrder(sortOrder) {
        if (vm.currentFilter.providerId === vmPicker.MediaConstant.PROVIDERS.microsoft) {
            return;
        }

        const { sortableFields } = await _getCurrentProvider();

        if (!includes(sortableFields, sortOrder)) {
            return Promise.resolve();
        }

        // Call the 'parent' function in the AbstractPicker.
        return vm.toggleSortOrder(sortOrder);
    }

    /**
     * Trash the given media.
     *
     * @param {Object} media The media to trash.
     */
    function trashMedia(media) {
        const kind = get(media, 'kind', MediaConstant.KIND.MEDIA);

        if (kind === MediaConstant.KIND.FOLDER) {
            _lxNotificationTrashSelectedElement(
                undefined,
                [media],
                'FRONT.MEDIA_PICKER.TRASH_FOLDER',
                'FRONT.MEDIA_PICKER.TRASH_FOLDER_DESCRIPTION',
                'FRONT.MEDIA_PICKER.TRASH_FOLDER_SUCCESS',
                () => {
                    const index = vm.currentObjectList.findIndex((med) => med.id === media.id);
                    vm.currentObjectList.splice(index, 1);
                },
            );
        } else {
            _lxNotificationTrashSelectedElement(
                [media],
                undefined,
                'FRONT.MEDIA_PICKER.TRASH_MEDIA',
                'FRONT.MEDIA_PICKER.TRASH_MEDIA_DESCRIPTION',
                'FRONT.MEDIA_PICKER.TRASH_MEDIA_SUCCESS',
                () => {
                    // Remove the element from selection.
                    vm.removeFromSelection(media);

                    const index = vm.currentObjectList.findIndex((med) => med.id === media.id);
                    vm.currentObjectList.splice(index, 1);
                },
            );
        }
    }

    /**
     * Whether current user can edit media or not.
     *
     * @return {boolean} Whether current user can edit media or not.
     */
    function userCanEdit() {
        return (
            User.isAdmin() ||
            vm.currentFilter.providerId !== MediaConstant.PROVIDERS.lumapps ||
            vm.currentFilter.categoryId === MediaConstant.CATEGORIES.mine
        );
    }

    /////////////////////////////

    vmPicker.cancelFolder = cancelFolder;
    vmPicker.changeProviderAndCategory = changeProviderAndCategory;
    vmPicker.deleteMedia = deleteMedia;
    vmPicker.emptyTrash = emptyTrash;
    vmPicker.finishFileUpload = finishFileUpload;
    vmPicker.getCurrentFolderName = getCurrentFolderName;
    vmPicker.getMediaLink = getMediaLink;
    vmPicker.getSortOrder = getSortOrder;
    vmPicker.getUploadedFiles = getUploadedFiles;
    vmPicker.getUploadUrlRequestParameters = getUploadUrlRequestParameters;
    vmPicker.hasThumbnail = hasThumbnail;
    vmPicker.generateBlobImageForMedias = generateBlobImageForMedias;
    vmPicker.isNavigable = isNavigable;
    vmPicker.isSelectable = isSelectable;
    vmPicker.navigatePath = navigatePath;
    vmPicker.navigateToParentFolder = navigateToParentFolder;
    vmPicker.onMediaClick = onMediaClick;
    vmPicker.onMediaDblClick = onMediaDblClick;
    vmPicker.openFolderDialog = openFolderDialog;
    vmPicker.openMediaUploader = openMediaUploader;
    vmPicker.restoreMedia = restoreMedia;
    vmPicker.saveFolder = saveFolder;
    vmPicker.toggleMediaSelection = toggleMediaSelection;
    vmPicker.toggleSortOrder = toggleSortOrder;
    vmPicker.trashMedia = trashMedia;
    vmPicker.userCanEdit = userCanEdit;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Load the model and the views when the abstract picker opens.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} pickerId The id of the abstract picker being opened.
     * @param {string} [view]   The view to display when the picker opens.
     */
    $scope.$on('abstract-picker__open-end', async (evt, pickerId) => {
        if (vm.pickerId !== pickerId) {
            return;
        }

        vm.is.open = true;
        vm.resetPicker();
        await _resetPicker();
        await _initCurrentCategoryCanManage();
        await vm.executeFilter();
        await _initCurrentFolderPermissions();
        vm.ngModel = generateBlobImageForMedias(vm.ngModel);
    });

    /**
     * Remove selection and open filters when the abstract picker is closed.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} pickerId The id of the abstract picker being opened.
     */
    $scope.$on('abstract-picker__close-end', (evt, pickerId) => {
        if (vm.pickerId !== pickerId) {
            return;
        }

        vm.is.open = false;
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    async function init() {
        // eslint-disable-next-line consistent-this
        vm = $scope.$parent.vm;

        await loadFroala($injector, $ocLazyLoad, Translation, Utils);
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('MediaPickerController', MediaPickerController);

/////////////////////////////

export { MediaPickerController };
