import React from 'react';

import { Dispatch } from '@lumapps/redux/types';

import { ConversationState } from './ducks/slice';
import { ApplicationDesign } from './types';

export const AssistantDesignContext = React.createContext<ApplicationDesign | undefined>(undefined);

export interface AssistantContextProperties {
    state: ConversationState;
    dispatch: Dispatch;
}

export const AssistantContext = React.createContext<{ state: ConversationState; dispatch: Dispatch } | undefined>(
    undefined,
);
