import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Notification } from '@lumapps/lumx/react';
import { useDispatch } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { ARIA_PROPS, SCOPE } from '../../../constants';
import { actions } from '../../../ducks/slice';
import { NotificationInStore } from '../../../ducks/types';
import { ExtendedNotification } from '../../ExtendedNotification';
import { useHandleHideTimeout } from './useHandleHideTimeout';
import { useHandleRemoveTimeout } from './useHandleRemoveTimeout';

interface ManagedNotificationProps {
    notification: NotificationInStore;
    offset: number;
    pausedAt?: Date;
    pause?: () => void;
    unpause?: () => void;
}

/**
 * Render simple or extended notification with correct positioning, show, hide & remove.
 *
 * Notification Lifecycle
 * - Added (visibility=undefined)
 * - First render => set rendered height & visibility=true
 * - Visible => set timeout timer THEN hide
 * - Hiding (visibility=false) => set hide transition timer THEN remove
 */
export const ManagedNotification: React.FC<ManagedNotificationProps> = (props) => {
    const dispatch = useDispatch();
    const { notification, offset, pausedAt, pause, unpause } = props;
    const { id, visible, height, dataAttributes } = notification;

    const wasVisible = React.useRef() as React.MutableRefObject<boolean>;

    const { translate } = useTranslate();

    // Handle hide on timeout, handle pause and unpause
    useHandleHideTimeout({ notification, wasVisible, pausedAt });

    // Handle removing the notification when fully hidden
    useHandleRemoveTimeout({ notification, wasVisible });

    React.useEffect(() => {
        // Update for next render (MUST BE DONE AFTER USING wasVisible)
        wasVisible.current = !!visible;
    }, [visible]);

    // After DOM render => set visibility to true and store rendered height
    const onDOMRender = React.useCallback(
        (el: HTMLDivElement | null) => {
            if (!el || typeof height === 'number') {
                return;
            }
            const rect = el.getBoundingClientRect();
            dispatch(actions.show({ id, height: rect.height }));
        },
        [dispatch, height, id],
    );

    const baseProps = {
        ref: onDOMRender,
        style: {
            opacity: visible ? 1 : 0,
            transform: `translateY(${-offset}px)`,
        },
    };

    const { get } = useDataAttributes(SCOPE);
    const validatedDataAttributes = dataAttributes ? get(dataAttributes) : {};
    const content = notification.translate ? translate(notification.translate) : notification.content;

    if (notification.type === 'extended') {
        return (
            <ExtendedNotification
                {...baseProps}
                figure={notification.figure}
                title={notification.title}
                titleProps={notification.titleProps}
                content={content}
                contentProps={ARIA_PROPS}
                actions={notification.actions}
                onClose={() => {
                    notification.onClose?.();
                    dispatch(actions.hide({ id }));
                }}
                closeProps={notification.closeProps}
                {...validatedDataAttributes}
            />
        );
    }

    return (
        <Notification
            {...baseProps}
            {...ARIA_PROPS}
            isOpen
            usePortal={false}
            onMouseEnter={pause}
            onMouseLeave={unpause}
            type={notification.type}
            content={content}
            actionLabel={(notification.actionLabel && translate(notification.actionLabel)) || undefined}
            onActionClick={notification.onActionClick}
            zIndex={notification.zIndex}
            onClick={notification.onClick}
            {...validatedDataAttributes}
        />
    );
};
