import React from 'react';

import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { useEditableWidget } from '@lumapps/widget-base/hooks/useEditableWidget';
import type { EditableWidgetProps } from '@lumapps/widget-base/types/designer';

import type { LegacyWidgetSummaryProps } from '../../types';
import { EditableWidgetSummary, EditableWidgetSummaryProps } from '../EditableWidgetSummary';

import './index.scss';

export interface WidgetSummaryWrapperProps extends EditableWidgetProps<LegacyWidgetSummaryProps> {}

export interface EditableWidgetBlockProperties {
    text?: string;
}

export const WidgetSummaryWrapper: React.FC<WidgetSummaryWrapperProps> = ({
    legacyWidget: initLegacyWidget,
    switchToReadMode,
    saveTextInWidgetProperties,
    isEditingContent,
    isWidgetEmpty,
    contentTitle,
    ...props
}: WidgetSummaryWrapperProps) => {
    const getTradObject = React.useCallback((properties?: LegacyWidgetSummaryProps) => properties?.text, []);

    const { legacyWidget, content, editableWidgetProps } = useEditableWidget<LegacyWidgetSummaryProps>(
        initLegacyWidget,
        switchToReadMode,
        saveTextInWidgetProperties,
        getTradObject,
    );

    return (
        <BackendRecomputedWidget<LegacyWidgetSummaryProps, EditableWidgetBlockProperties, EditableWidgetSummaryProps>
            legacyWidget={legacyWidget}
            editableWidgetRenderer={EditableWidgetSummary}
            editableWidgetProps={{
                ...editableWidgetProps,
                contentTitle,
            }}
            isWidgetEmpty={isWidgetEmpty}
            blockProperties={{ text: content }}
            editingContent={isEditingContent}
            canUseLangFallback={false}
            {...props}
        />
    );
};
