import { generateUUID } from '@lumapps/utils/string/generateUUID';

function ReusableMediaPickerController(
    $rootScope,
    $scope,
    AbstractPicker,
    Config,
    Features,
    MediaConstant,
    Utils,
    ReusableMediaPicker,
) {
    'ngInject';

    const reusableMediaPicker = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Reusable media picker UUID.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    reusableMediaPicker.id = generateUUID();

    /**
     * Options provided to the directive.
     *
     * @type {Object}
     */
    reusableMediaPicker.options = {};

    /**
     * Whether the should be displayed or not.
     *
     * @type {boolean}
     */
    reusableMediaPicker.isOpen = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    reusableMediaPicker.Config = Config;
    reusableMediaPicker.MediaConstant = MediaConstant;
    reusableMediaPicker.Utils = Utils;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Opens a media picker.
     *
     * @param {Object}   value    The currently selected media.
     * @param {Function} onChange Called whenever a new media is selected, with `(value)`.
     * @param {Function} options  Extra options provided to the media picker.
     */
    function openMediaPicker(value, onChange, options) {
        reusableMediaPicker.options = {
            allowFolderPick: false,
            allowedFileTypes: MediaConstant.DOCUMENT_TYPES.imagesAndFolders,
            allowedProviders: [MediaConstant.PROVIDERS.lumapps],
            isMediaModel: true,
            multi: false,
            showFoldersOnly: false,
            ...options,
        };

        if (angular.isDefinedAndFilled(value)) {
            value = angular.isArray(value) ? value : [value];
        }

        const originalValue = angular.isArray(value) ? Array.from(value) : value;
        reusableMediaPicker.value = originalValue;
        reusableMediaPicker.isOpen = true;

        const unsubscribe = $rootScope.$on('abstract-picker__close-end', (evt, id) => {
            if (id !== reusableMediaPicker.id) {
                return;
            }

            unsubscribe();

            if (reusableMediaPicker.value !== originalValue) {
                onChange(reusableMediaPicker.value);
            }

            reusableMediaPicker.isOpen = false;
        });

        Utils.waitForAndExecute(`#${reusableMediaPicker.id}`, AbstractPicker);
    }

    /////////////////////////////

    reusableMediaPicker.open = openMediaPicker;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        ReusableMediaPicker.set(reusableMediaPicker);
    }

    init();
}

/////////////////////////////

function ReusableMediaPickerDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: ReusableMediaPickerController,
        controllerAs: 'reusableMediaPicker',
        restrict: 'E',
        templateUrl: '/client/common/modules/reusable-media-picker/views/reusable-media-picker.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('reusableMediaPicker', ReusableMediaPickerDirective);

export { ReusableMediaPickerController, ReusableMediaPickerDirective };
