import React from 'react';

import { useLazyLoadingProgress } from '../../hooks/useLazyLoadingProgress';

/**
 * Fallback component for the React suspense
 *
 * Will simply call useLazyLoadingProgress to show the app's linear progress bar when fallback mounts
 * and remove progress when fallback unmounts (aka, when component is fully loaded)
 */
export const LinearProgressSuspenseFallback: React.FC = () => {
    useLazyLoadingProgress(true);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};
