import React from 'react';

import { GetDefaultContentListsResponse, getDefaultContentLists } from '@lumapps/content-lists/api/content-lists';
import { DefaultContentList } from '@lumapps/content-lists/types';
import { oneCallAssurance } from '@lumapps/utils/function/oneCallAssurance';

export type UseMetadataContentListUrlResolverProps = {
    metadataId: string;
    currentSiteId: string;
    contentTypeId: string;
};

/**
 * Fetch default content list of the site and will try to return the one associated
 * If no default list is found, no link can be generated.
 * @param param0
 * @returns content list or null
 */
export const useMetadataContentListResolver = ({
    contentTypeId,
    currentSiteId,
}: UseMetadataContentListUrlResolverProps): DefaultContentList | null => {
    const [items, setItems] = React.useState<DefaultContentList[] | null>(null);

    // Call the endpoint to get content list of the current site.
    React.useEffect(() => {
        const callList = async () => {
            if (contentTypeId) {
                const contentLists = await oneCallAssurance<GetDefaultContentListsResponse>(
                    `DefaultContentList${currentSiteId}`,
                    () => {
                        return getDefaultContentLists({
                            siteIds: currentSiteId,
                        });
                    },
                );

                setItems(contentLists.items);
            }
        };

        callList();
    }, [contentTypeId, currentSiteId]);

    return React.useMemo(() => {
        if (!items) {
            return null;
        }

        const item = items.find((v) => v.contentTypeId === contentTypeId);

        return item || null;
    }, [items, contentTypeId]);
};
