import isEmpty from 'lodash/isEmpty';

import { useChildStyles } from './useStyles';

export interface UseNavStylesProps {
    /** background color for the top bar */
    backgroundColor: string | null;
    /** determines whether the style is the default one or not */
    isDefault?: boolean;
}

/**
 * Retrieves the customizations implemented to the current instance that are related to the navigation bar
 */
const useChildNavStyles = (): UseNavStylesProps => {
    /**
     * Here you will find several fallbacks. The logic is the following:
     * - If there are styles in the store (coming from useStyles), use those
     * - If there are no styles, but we have the styles of the instance coming from the variables injected into the HTML, use them
     * - Then default to the base theme
     */
    const { styles } = useChildStyles({ property: 'mainNav' }) || {};

    return {
        ...styles,
        backgroundColor: styles.backgroundColor,
        isDefault: isEmpty(styles),
    };
};

export { useChildNavStyles };
