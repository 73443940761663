/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

const reviewPageRoute: Route = {
    path: `/${URL_PREFIX}/reports`,
    appId: AppId.frontOffice,
};

const reviews = (): Route => ({
    ...reviewPageRoute,
    params: {},
});

export { reviews, reviewPageRoute };
