import React, { useState } from 'react';
import { TextField } from '@lumapps/lumx/react';
import ReCAPTCHA from 'react-google-recaptcha';

import isUndefined from 'lodash/isUndefined';

import { Config } from 'components/constants';
import { sendEmail } from 'components/services/email';
// eslint-disable-next-line id-blacklist
import { error, success } from 'components/services/notification';
import { getConnected, isConnected } from 'components/services/user';
import { translate as t } from 'components/translations';
import { isEmailValid } from 'components/utils';

import { widgetContactTypes } from './types';

/**
 * Default state for fields in the form.
 */
const DEFAULT_FIELD_STATE = { isPristine: true, value: '' };

/**
 * Indicates if a field is valid or not.
 * A field is valid if he has not been edited yet and is not empty.
 *
 * @param  {Object}  field The field we want the test the validity.
 * @return {boolean} Whether the field is valid or not.
 */
const isFieldValid = (field) => {
    return field.isPristine || (field.value !== undefined && field.value.trim() !== '');
};

/**
 * Displays the widget contact form.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The delve react element.
 */
const WidgetContact = ({ properties: props, theme, contentId, widgetId }) => {
    const { to } = props;

    const [captcha, setCaptcha] = useState('');
    const [message, setMessage] = useState(DEFAULT_FIELD_STATE);
    const [sender, setSender] = useState(DEFAULT_FIELD_STATE);
    const [subject, setSubject] = useState(DEFAULT_FIELD_STATE);

    const isSenderValid = isFieldValid(sender);
    const isSubjectValid = isFieldValid(subject);
    const isMessageValid = isFieldValid(message);

    /**
     * Trigger when the captcha change.
     *
     * @param {string} value The response of the captcha.
     */
    const onCaptchaChange = (value) => {
        setCaptcha(value);
    };

    /**
     * Trigger when the message field is edited.
     *
     * @param {string|Event} value The field value.
     */
    const onMessageChange = (value) => {
        value = value && value.target ? value.target.value : value;
        setMessage({ isPristine: false, value });
    };

    /**
     * Trigger when the sender field is edited.
     *
     * @param {string|Event} value The field value.
     */
    const onSenderChange = (value) => {
        value = value && value.target ? value.target.value : value;
        setSender({ isPristine: false, value });
    };

    /**
     * Trigger when the subject field is edited.
     *
     * @param {string|Event} value The field value.
     */
    const onSubjectChange = (value) => {
        value = value && value.target ? value.target.value : value;
        setSubject({ isPristine: false, value });
    };

    /**
     * Indicates if the submit button is disabled or not.
     *
     * @return {boolean} Whether the button is disabled or not.
     */
    const isSubmitButtonDisabled = () => {
        return (
            subject.value === '' ||
            message.value === '' ||
            message.value === '' ||
            (!isConnected() && (!isEmailValid(sender.value) || captcha === undefined))
        );
    };

    /**
     * Send the message to the given email adress.
     *
     * @param {Event} evt The submit event that trigger this function.
     */
    const sendMessage = async (evt) => {
        evt.preventDefault();

        if (to === undefined || to === '') {
            error(t('WIDGET_TYPE_CONTACT_SEND_ERROR_NO_EMAIL'));

            return;
        }

        const senderEmail = isConnected() ? getConnected().email : sender.value;

        if (senderEmail === undefined || senderEmail === '') {
            error(t('WIDGET_TYPE_CONTACT_SEND_ERROR'));

            return;
        }

        const content = `${senderEmail} <br/> ${message.value}`;

        try {
            await sendEmail(content, captcha, senderEmail, subject.value, to, contentId, widgetId);
        } catch (exception) {
            error(t('WIDGET_TYPE_CONTACT_SEND_ERROR'));

            return;
        }

        // Reset states.
        setCaptcha(undefined);
        setMessage(DEFAULT_FIELD_STATE);
        setSender(DEFAULT_FIELD_STATE);
        setSubject(DEFAULT_FIELD_STATE);

        success(t('WIDGET_TYPE_CONTACT_SEND_SUCCESS'));
    };

    return (
        <form name="widgetContact.form" onSubmit={sendMessage}>
            {!isConnected() && (
                <div className="widget-contact__sender">
                    <TextField
                        hasError={!isSenderValid || (!isEmailValid(sender.value) && !sender.isPristine)}
                        label={t('WIDGET_TYPE_CONTACT_EMAIL')}
                        theme={theme}
                        value={sender.value}
                        onChange={onSenderChange}
                    />

                    {(!isSenderValid || (!isEmailValid(sender.value) && !sender.isPristine)) && (
                        <span className="widget-contact__error">{t('SERVER_ERROR_INVALID_EMAIL')}</span>
                    )}
                </div>
            )}
            <div className="widget-contact__subject">
                <TextField
                    hasError={!isSubjectValid}
                    label={t('WIDGET_TYPE_CONTACT_SUBJECT')}
                    theme={theme}
                    value={subject.value}
                    onChange={onSubjectChange}
                />

                {!isSubjectValid && <span className="widget-contact__error">{t('ERROR_MANDATORY_FIELD')}</span>}
            </div>

            <div className="widget-contact__message">
                <TextField
                    multiline
                    useCustomColors
                    hasError={!isMessageValid}
                    label={t('WIDGET_TYPE_CONTACT_MESSAGE')}
                    theme={theme}
                    value={message.value}
                    onChange={onMessageChange}
                />

                {!isMessageValid && <span className="widget-contact__error">{t('ERROR_MANDATORY_FIELD')}</span>}
            </div>

            {!isConnected() && (
                <div className="widget-contact__captcha">
                    <ReCAPTCHA sitekey={Config.G_RECAPTCHA_KEY} onChange={onCaptchaChange} />
                </div>
            )}

            <button className="widget-contact__submit" disabled={isSubmitButtonDisabled()} type="submit">
                {t('GLOBAL.SEND')}
            </button>
        </form>
    );
};

WidgetContact.propTypes = widgetContactTypes;

/**
 * Defines this widget as non editable.
 *
 * @return {boolean} False.
 */
WidgetContact.isEditable = () => false;

/**
 * Defines whether the widget is empty or not.
 *
 * @param  {string}  params The widget props.
 * @return {boolean} Whether the recipient email address is empty or not.
 */
WidgetContact.isWidgetEmpty = (params) => {
    const { value } = params;
    return isUndefined(value) || value === '';
};

/**
 * Determines whether the widget is hidden or not.
 *
 * @param  {Object}  params The widget props.
 * @return {boolean} Whether the widget is hidden or not.
 */
WidgetContact.isWidgetHidden = (params) => {
    return WidgetContact.isWidgetEmpty(params);
};

export { WidgetContact };
