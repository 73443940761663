(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserOrganizationChartFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/user/organizationchart/:methodKey', {}, {
            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('UserOrganizationChartFactory', UserOrganizationChartFactory);
})();
