/* istanbul ignore file */

import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const saSocialNetworksRoutes: Route = {
    path: '/admin/social-advocacy/sa-social-network',
    legacyId: 'app.admin.social-advocacy.social-network',
    appId: AppId.backOffice,
};

const saSocialNetworks = (): Route => {
    return saSocialNetworksRoutes;
};

export { saSocialNetworks, saSocialNetworksRoutes };
