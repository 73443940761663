/* eslint-disable no-use-before-define */
import loFind from 'lodash/find';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';

import { get } from '@lumapps/constants';
import {
    mdiFileDocument,
    mdiFileWord,
    mdiFilePowerpoint,
    mdiFileExcel,
    mdiPdfBox,
    mdiPlayCircle,
    mdiFile,
    mdiImage,
    mdiFilePresentationBox,
    mdiVolumeHigh,
    mdiGoogleSpreadsheet,
    mdiDrawingBox,
    mdiZipBox,
    mdiPresentation,
    mdiFileTable,
    mdiFolder,
} from '@lumapps/lumx/icons';
import { ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { getPathnameFromUrl } from '@lumapps/router/utils';
import { TranslateObject } from '@lumapps/translations/types';

import { HAUSSMANN_URL_REGEX_STR, LUMDRIVE_URL_REGEX_STR, PROTOCOL_REGEX, URL_PATTERNS } from '../constants';
import { Media, MediaContent, MediaIcon, VideoWebsites } from '../types';

const Config = get();
const organizationId = Config.customerId;

/**
 * Get the Dailymotion video player embed code for a given videoId.
 *
 * @param  {string} videoId The id of the video.
 * @return {string} The embed code of the video player for the given video.
 */
const getEmbedDailymotionUrl = (videoId: string): string | undefined => {
    if (!videoId) {
        return undefined;
    }

    return `//www.dailymotion.com/embed/video/${videoId}`;
};

/**
 * Get the Vimeo video player embed code for a given videoId.
 *
 * @param  {string} videoId The id of the video.
 * @return {string} The embed code of the video player for the given video.
 */
const getEmbedVimeoUrl = (videoId: string): string | undefined => {
    if (!videoId) {
        return undefined;
    }

    return `https://player.vimeo.com/video/${videoId}`;
};

/**
 * Get the YouTube video player embed code for a given videoId.
 *
 * @param  {string} videoId The id of the video.
 * @return {string} The embed code of the video player for the given video.
 */
const getEmbedYouTubeUrl = (videoId: string, urlParams?: string): string | undefined => {
    if (!videoId) {
        return undefined;
    }
    const params = new URLSearchParams(urlParams);
    let paramsToAdd;

    if (params.has('t')) {
        paramsToAdd = `?start=${params.get('t')}`;
    }

    return paramsToAdd
        ? `https://www.youtube.com/embed/${videoId}${paramsToAdd}`
        : `https://www.youtube.com/embed/${videoId}`;
};

/**
 * Get a videoId based on a given URL and website name.
 *
 * @param  {string} urlToParse  The video URL to parse.
 * @param  {string} websiteName The website name the video comes from.
 *                              Possible values are: 'dailymotion', 'vimeo' or 'youtube (more will be supported
 *                              later).
 * @return {string} The videoId or undefined if none found.
 */
const getVideoId = (urlToParse?: string, websiteName?: VideoWebsites): string | undefined => {
    if (!urlToParse || !websiteName) {
        return undefined;
    }

    // Protocol and www neutral.
    let cleaned = urlToParse.replace(PROTOCOL_REGEX, '');
    const paramsMatch = cleaned.match('.*[?](.+)');
    const urlParams = paramsMatch ? new URLSearchParams(paramsMatch[1]) : undefined;

    if (urlParams) {
        cleaned = cleaned.slice(0, cleaned.indexOf('?'));

        if (urlParams.has('v')) {
            // Put the video id at the first param (useful in url with start time or other params)
            cleaned = `${cleaned}?v=${urlParams.get('v')}`;
        }
    }

    // Find which pattern matches our URL exactly.
    const matchingPattern = loFind(URL_PATTERNS[websiteName], (pattern) => cleaned.indexOf(pattern) === 0);

    return (matchingPattern && cleaned.substring(matchingPattern.length || 0)) || undefined;
};

/**
 * Returns the website name of a video based on its URL.
 * E.g. if we detect the URL as being one of the matching youtube patterns, it will then returns "youtube".
 * Possible return values are: 'dailymotion', 'vimeo' or 'youtube' (more will be supported later).
 *
 * @param  {string} urlToCheck The URL to check our patterns against.
 * @return {string} The website name of the video or false if none found.
 */
const getVideoUrlWebsiteName = (urlToCheck?: string): string | undefined => {
    if (!urlToCheck) {
        return undefined;
    }

    return Object.keys(URL_PATTERNS).find((websiteName) => {
        return URL_PATTERNS[websiteName as VideoWebsites].find((pattern: string) => urlToCheck.indexOf(pattern) > -1);
    });
};

/**
 * Get the embed code for the video player of a given videoId and website name.
 *
 * @param  {string}         videoId     The id of the video to embed in the player.
 * @param  {string|boolean} websiteName The name of the website the video comes from.
 * @return {string}         The embed code string for the video player.
 */
const getEmbedUrl = (videoUrl: string): string | undefined => {
    if (!videoUrl) {
        return undefined;
    }
    const urlToCheck = videoUrl.replace(PROTOCOL_REGEX, '');
    const websiteName = getVideoUrlWebsiteName(urlToCheck);
    const urlParams = videoUrl.match('.*[?](.+)');
    const videoId = websiteName ? getVideoId(urlToCheck, websiteName as VideoWebsites) : undefined;

    if (!videoId) {
        return undefined;
    }
    switch (websiteName) {
        case 'dailymotion':
            return getEmbedDailymotionUrl(videoId);

        case 'vimeo':
            return getEmbedVimeoUrl(videoId);

        case 'youtube':
            return getEmbedYouTubeUrl(videoId, urlParams ? urlParams[1] : undefined);

        default:
            return undefined;
    }
};

type GetMediaContentType = (
    media: Media,
    translate: (mediaContent: TranslateObject) => any,
    lang?: string,
) => Partial<MediaContent & { source: MediaSource }>;
/**
 * Get the media content in a given language.
 *
 * @param  {Object}   media     The media object to get the content of.
 * @param  {Function} translate The translation function to use.
 * @param  {string}   [lang]    The language code in which to get the translation.
 * @return {Object}             The content of the media in the wanted language.
 */
// eslint-disable-next-line default-param-last
const getMediaContent: GetMediaContentType = (media, translate = (it) => it, lang) => {
    if (!media || isEmpty(media)) {
        return {};
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    let content: MediaContent | {} | undefined = {};
    const language = lang;
    if (media.hasCroppedContent) {
        content = media.croppedContent?.find((cnt) => cnt.lang === language);
    }

    if (!content || isEmpty(content)) {
        const translatableMediaContents = (media.content || []).reduce<TranslateObject>(
            (mCs, mC) => ({ ...mCs, [mC.lang]: mC }),
            {},
        );
        content = translate(translatableMediaContents);
    }

    if (media.override && content) {
        if (media.override.description) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            content.description = translate(media.override.description);
        }

        if (media.override.name) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            content.name = translate(media.override.name);
        }
    }

    return { ...content, source: media.source || media.from };
};

/**
 * Get an image url from the MediaContent object.
 *
 * Since we have many fields possible for an image url, this function is here to handle fallbacks,
 * you should always use it when you need an image url.
 */
const getImageUrlFromContent = (content: Partial<MediaContent & { source: MediaSource }>) => {
    return content.servingUrl || content.url;
};

/**
 * Get an image url from the Media object.
 *
 * Since we have many fields possible for an image url, this function is here to handle fallbacks,
 * you should always use it when you need an image url.
 */
const getImageUrlFromMedia = flow<
    Parameters<GetMediaContentType>,
    Partial<MediaContent & { source: MediaSource }>,
    string | undefined
>(getMediaContent, getImageUrlFromContent);

/**
 * Get an image key from the MediaContent object.
 *
 */
const getImageKeyFromContent = (content: Partial<MediaContent & { source: MediaSource }>) => {
    return content.value;
};

/**
 * Get an image blob key from the Media object.
 */
const getImageBlobKeyFromMedia = flow<
    Parameters<GetMediaContentType>,
    Partial<MediaContent & { source: MediaSource }>,
    string | undefined
>(getMediaContent, getImageKeyFromContent);

/**
 * Resize an Haussmann image.
 *
 * @param {string}  imageUrl         The url of the image to be resized.
 * @param {number}  width            The width of the picture.
 * @param {number}  height           The height of the picture.
 */
const resizeHaussmannImage = (imageUrl: string | undefined, width = 0, height = 0) => {
    if (!imageUrl) {
        return '';
    }
    const thumbnailPrefix = `https://thumbnails.stag.lumapps.com/unsafe/${width}x${height}/`;
    const encodedUrl = window.encodeURIComponent(imageUrl);

    return `${thumbnailPrefix}${encodedUrl}`;
};

/**
 * Indicates if a image url is sized.
 * You should always use it when you need an image url.
 *
 * @param {string}  imageUrl    The url of the image to check.
 */
const isImageUrlResized = (imageUrl: string) => {
    const sizingPattern = /(=s[0-9]+)$/;
    return sizingPattern.test(imageUrl);
};

const FILE_TYPES_ICONS: Record<string, MediaIcon> = {
    doc: { icon: mdiFileDocument, color: ColorPalette.red },
    word: { icon: mdiFileWord, color: ColorPalette.blue },
    powerpoint: { icon: mdiFilePowerpoint, color: ColorPalette.red, colorVariant: ColorVariant.D2 },
    excel: { icon: mdiFileExcel, color: ColorPalette.green },
    pdf: { icon: mdiPdfBox, color: ColorPalette.red },
    video: { icon: mdiPlayCircle, color: ColorPalette.dark, colorVariant: ColorVariant.L4 },
    file: { icon: mdiFile, color: ColorPalette.dark, colorVariant: ColorVariant.L4 },
    photo: { icon: mdiImage, color: ColorPalette.blue, colorVariant: ColorVariant.L2 },
    slide: { icon: mdiFilePresentationBox, color: ColorPalette.yellow },
    audio: { icon: mdiVolumeHigh, color: ColorPalette.red },
    sheet: { icon: mdiGoogleSpreadsheet, color: ColorPalette.green },
    drawing: { icon: mdiDrawingBox, color: ColorPalette.red },
    archive: { icon: mdiZipBox, color: ColorPalette.dark, colorVariant: ColorVariant.L3 },
    // Presentation icon use for presentation file that are not Google or Microsoft
    presentation: { icon: mdiPresentation, color: ColorPalette.yellow },
    // Spreadsheet icon use for spreadsheet file that are not Google or Microsoft
    spreadsheet: { icon: mdiFileTable, color: ColorPalette.green },
    folder: { icon: mdiFolder, color: ColorPalette.dark, colorVariant: ColorVariant.L4 },
};

/**
 * Returns an object containing informations on how to display the icon for a given media based on its mimetype.
 *
 * @param mediaContent The mediaContent you want an icon for.
 */
const getMediaIcon = (mediaContent?: Partial<MediaContent>): MediaIcon => {
    switch (mediaContent?.mimeType?.toLowerCase()) {
        case 'application/pdf':
            return FILE_TYPES_ICONS.pdf;

        case 'application/vnd.google-apps.audio':
        case 'audio/mpeg':
        case 'audio/aac':
        case 'audio/ogg':
            return FILE_TYPES_ICONS.audio;

        case 'application/vnd.google-apps.document':
        case 'application/vnd.oasis.opendocument.text':
        case 'application/rtf':
            return FILE_TYPES_ICONS.doc;

        case 'application/vnd.google-apps.drawing':
            return FILE_TYPES_ICONS.drawing;

        case 'application/vnd.google-apps.file':
            return FILE_TYPES_ICONS.file;

        case 'application/vnd.google-apps.photo':
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/png':
        case 'image/webp':
            return FILE_TYPES_ICONS.photo;

        case 'application/vnd.google-apps.presentation':
            return FILE_TYPES_ICONS.slide;

        case 'application/vnd.oasis.opendocument.presentation':
            return FILE_TYPES_ICONS.presentation;

        case 'application/vnd.google-apps.spreadsheet':
            return FILE_TYPES_ICONS.sheet;

        case 'application/vnd.oasis.opendocument.spreadsheet':
            return FILE_TYPES_ICONS.spreadsheet;

        case 'application/vnd.google-apps.video':
        case 'video/avi':
        case 'video/mov':
        case 'video/mp4':
        case 'video/mpeg':
        case 'video/quicktime':
            return FILE_TYPES_ICONS.video;

        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'application/vnd.ms-word.document.macroenabled.12':
        case 'application/vnd.ms-word.template.macroenabled.12':
            return FILE_TYPES_ICONS.word;

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
        case 'application/vnd.ms-excel.sheet.macroenabled.12':
        case 'application/vnd.ms-excel.template.macroenabled.12':
        case 'application/vnd.ms-excel.addin.macroenabled.12':
        case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
            return FILE_TYPES_ICONS.excel;

        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
        case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
        case 'application/vnd.ms-powerpoint.addin.macroenabled.12':
        case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
        case 'application/vnd.ms-powerpoint.template.macroenabled.12':
        case 'application/vnd.ms-powerpoint.slideshow.macroenabled.12':
            return FILE_TYPES_ICONS.powerpoint;

        case 'application/x-rar':
        case 'application/zip':
            return FILE_TYPES_ICONS.archive;

        default:
            return FILE_TYPES_ICONS.file;
    }
};

const getDownloadUrlFromPermalink = (url: string) => {
    return `${url}?dl=True`;
};

const getMediaUrl = (mediaId: string) => {
    return `/_ah/api/lumsites/v2/organizations/${organizationId}/medias/${mediaId}/permalink`;
};

const isMediaURLFromHaussmann = (url: string) => {
    const regex = new RegExp(`^${HAUSSMANN_URL_REGEX_STR}$`, 'g');

    return regex.test(url);
};

const isMediaURLFromLumdrive = (url: string) => {
    const regex = new RegExp(`^${LUMDRIVE_URL_REGEX_STR}$`, 'g');

    return regex.test(url);
};

/**
 *  Haussmann Media or /serve/XXXX medias are using current user cookie to authenticate the user and display the image.
 *  Using a relative URL allows us to always forward the cookie to the service, no matter the absolute URL of the media.
 *  This method aims to parse a HTML string and make secured media URL relative.
 *  If the URL is not an haussmann media URL or not a Lumdrive one, the URL is unchanged.
 */
const applyRelativeURLForSecuredImagesInHTML = (htmlStr: string | undefined) => {
    const lumdriveFilesRegex = new RegExp(`(src=")${LUMDRIVE_URL_REGEX_STR}(")`, 'gi');
    const HaussmannFilesRegex = new RegExp(`(src=")${HAUSSMANN_URL_REGEX_STR}(")`, 'gi');

    return htmlStr && !isEmpty(htmlStr)
        ? htmlStr.replace(lumdriveFilesRegex, '$1$3$4').replace(HaussmannFilesRegex, '$1$3$4$5$6$7')
        : htmlStr;
};

/**
 *  Haussmann Media or /serve/XXXX medias are using current user cookie to authenticate the user and display the image.
 *  Using a relative URL allows us to always forward the cookie to the service, no matter the absolute URL of the media.
 *  Here, we are making secured media URL relative to the current host (ex: /_ah/api.../permalink).
 *  If the URL is not an haussmann media URL or not a Lumdrive one, the URL is returned unchanged.
 */
const makeSecuredMediaURLRelative = (url: string | undefined) => {
    return url && !isEmpty(url) && (isMediaURLFromHaussmann(url) || isMediaURLFromLumdrive(url))
        ? getPathnameFromUrl(url)
        : url;
};

/**
 * Returns the blob key for the given media URL.
 * @param thumbnail - Media URL
 * @returns key
 */
const retrieveKeyFromServeImageUrl = (thumbnail?: string) => {
    try {
        /**
         * The backend sends over the entire URL and not the key. So to retrieve it
         * we need to do some hacks unfortunately, we split by 'serve', which is the base path
         * for all images, and then we remove any resizing that could have been added to the
         * image by doing a split by '='
         */
        const key = thumbnail ? thumbnail.split('/serve/')[1].split('/')[0].split('=')[0] : undefined;

        return key;
        // eslint-disable-next-line no-empty
    } catch (excp) {}

    return undefined;
};

export {
    applyRelativeURLForSecuredImagesInHTML,
    getEmbedUrl,
    getEmbedDailymotionUrl,
    getEmbedVimeoUrl,
    getEmbedYouTubeUrl,
    getMediaContent,
    getImageUrlFromContent,
    getImageUrlFromMedia,
    getVideoId,
    getVideoUrlWebsiteName,
    isImageUrlResized,
    isMediaURLFromHaussmann,
    isMediaURLFromLumdrive,
    resizeHaussmannImage,
    getMediaIcon,
    getDownloadUrlFromPermalink,
    getMediaUrl,
    makeSecuredMediaURLRelative,
    FILE_TYPES_ICONS,
    retrieveKeyFromServeImageUrl,
    getImageKeyFromContent,
    getImageBlobKeyFromMedia,
};
