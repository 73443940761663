export enum SA_NAVIGATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15947033
     */
    SOCIAL_ADVOCACY = 'ADMIN.NAV.SOCIAL_ADVOCACY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=18155066
     */
    SOCIAL_NETWORKS = 'ADMIN.NAV.SOCIAL_ADVOCACY.SOCIAL_NETWORKS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19482444
     */
    DASHBOARD_ICON_TOOLTIP = 'FRONT.SOCIAL_ADVOCACY.DASHBOARD_ICON_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39308433
     */
    PROGRAMS = 'ADMIN.NAV.SOCIAL_ADVOCACY.PROGRAMS',
}
