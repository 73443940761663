import { ReactNode } from 'react';

import { CommunityLinkRef, RenderingType } from '@lumapps/communities/types';
import { TranslatableObject, TranslateObject } from '@lumapps/translations/types';
import {
    BlockUser,
    BlockReactions,
    SiteReference,
    BlockImage,
    MetadataReference,
    Block,
    BlockTagReferenceList,
} from '@lumapps/widget-base/types';

export enum BlockPagePreviewVariant {
    vertical = 'VERTICAL',
    horizontal = 'HORIZONTAL',
    cover = 'COVER',
    side = 'SIDE',
}

export enum BlockPagePreviewContentType {
    community = 'community',
    page = 'page',
}

export enum OrderElement {
    publicationInfo = 'publicationInfo',
    image = 'image',
    author = 'author',
    publishedAt = 'publishedAt',
    siteName = 'site_name',
    tags = 'tags',
    title = 'title',
    excerpt = 'excerpt',
    metadata = 'metadata',
    reactions = 'reactions',
}

export interface BlockPagePreview extends Block {
    type: 'BlockPagePreview';
    /** The content id */
    pageId: string;
    /** The content type details */
    contentType?: {
        id: string;
        icon: string;
        name: TranslatableObject;
    };
    /** The content type id */
    contentTypeId?: string;
    /** The content type */
    pageType: BlockPagePreviewContentType;
    /** Rendering type in case of pageType community */
    renderingType?: RenderingType;
    /** The content slug */
    slug: string;
    /** The content publication date */
    publishedAt?: string;
    /** The content author */
    author?: BlockUser;
    /** The content title */
    title?: string;
    /** The content description, basically a small part of it */
    excerpt?: string;
    /** The content featured image */
    image?: BlockImage;
    /** The content display variant */
    variant: BlockPagePreviewVariant;
    /** The content site */
    site: SiteReference;
    /** The content tags */
    tags?: BlockTagReferenceList;
    /** The content metadata */
    metadata?: MetadataReference[];
    /** The reaction block of the content, with like and comments informations */
    reactions?: BlockReactions;
    /** The order of the displayed properties, if a prop is not in this array, then it should not be displayed */
    order: OrderElement[];
    /** The URL to an external source */
    externalUrl?: string;
    /** Whether or not the blockPagePreview is previewOnly (disable block reactions if true) */
    isPreviewOnly?: boolean;
    /** The full html content  */
    richTextPreview?: TranslateObject<string>;
}

export interface BlockPagePreviewLink {
    children: ReactNode;
    className?: string;
    linkData?: Partial<CommunityLinkRef>;
    pageType?: BlockPagePreviewContentType;
    externalUrl?: BlockPagePreview['externalUrl'];
    tabIndex?: number;
}
