import first from 'lodash/fp/first';
import pick from 'lodash/fp/pick';
import property from 'lodash/fp/property';

import { Author } from '../../types';

export const fillAuthorFilterValuesMissingData = (values?: Partial<Author>[], missingAuthorData?: Author): Author[] => {
    const value = first(values);

    if (!value) {
        return [] as Author[];
    }

    const { email, firstName, lastName } = pick(['email', 'firstName', 'lastName'], value);

    if (email && !(firstName && lastName) && email === property('email', missingAuthorData)) {
        return [
            {
                email,
                ...pick(['firstName', 'lastName'], missingAuthorData),
            },
        ] as Author[];
    }

    return values as Author[];
};
