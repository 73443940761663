import BaseApi from '@lumapps/base-api';

const socialNetworksApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy/social-network' });

const fetchSocialNetworksSettings = () => {
    return socialNetworksApi.get('/settings');
};

export const getAllSocialNetworkCapabilities = () => socialNetworksApi.get('/capabilities');

export const getSocialNetworkData = (id: string) => socialNetworksApi.get(`/configurations/${id}`);

export const updateSocialNetworkData = (
    id: string,
    active = false,
    useCustomApplication = false,
    clientId = null,
    clientSecret = null,
) =>
    socialNetworksApi.put(`/configurations/${id}`, {
        active,
        clientId,
        clientSecret,
        useCustomApplication,
    });

/**
 * Patch the social platform with some updates.
 *
 * @param  {string}                socialNetworkId The name of the SN.
 * @param  {boolean}               activationState  The status of the connection.
 * @param  {string}                clientId         The credentials.
 * @param  {string}                clientSecret     The credentials.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const updateSocialPlatformSettings = (
    socialNetworkId: string,
    activationState: boolean,
    clientId: string,
    clientSecret: string,
) => {
    const body: any = {};
    if (activationState !== undefined) {
        body.active = activationState;
    }
    if (clientId) {
        body.clientId = clientId;
    }
    if (clientSecret) {
        body.clientSecret = clientSecret;
    }

    return socialNetworksApi.put(`/configurations/${socialNetworkId}`, body);
};

export { socialNetworksApi, fetchSocialNetworksSettings };
