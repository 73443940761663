import qs from 'qs';

/** Wrapper around the `qs.stringify`. */
export const paramsSerializer = (parameters: any, options?: qs.IStringifyOptions) => {
    return qs.stringify(parameters, options);
};

/** Function creating a `paramsSerializer` for axios using `qs.stringify` (options available).   */
export const makeParamsSerializer = (options?: qs.IStringifyOptions) => (parameters: any) =>
    paramsSerializer(parameters, options);
