import React, { useMemo } from 'react';

import compact from 'lodash/compact';

import { CommunityView } from '@lumapps/communities/constants';
import { isCurrentCommunityEditableByUser } from '@lumapps/communities/ducks/selectors';
import { communityView } from '@lumapps/communities/routes';
import { RenderingType } from '@lumapps/communities/types';
import { communityEvents } from '@lumapps/community-events/routes';
import { useSelector } from '@lumapps/redux/react';

import { COMMUNITY_SERVICE_RECORD } from '../../constants';
import { CommunityNavigation as CommunityNavigationProps } from '../../types';
import { CommunityNavigation } from '../CommunityNavigation';

interface LegacyCommunityNavigationProps extends CommunityNavigationProps<CommunityView> {
    views: Record<CommunityView, string>;
    communitySlug: string;
}

/**
 * Component to be used in the Legacy context of the communities.
 * Get some custom properties from the Legacy APP, and map them to fit the React component
 * */
export const LegacyCommunityNavigation: React.FC<LegacyCommunityNavigationProps> = ({
    views,
    communitySlug,
    ...props
}) => {
    const isManager = useSelector(isCurrentCommunityEditableByUser);
    /**
     * Get the list of staticViews to display in the navigation.
     * Ordered by the COMMUNITY_SERVICE_RECORD_LIST
     * Filtered by the `views` generated in the Legacy APP
     * */
    const staticItems = useMemo(
        () =>
            compact(
                Object.entries(COMMUNITY_SERVICE_RECORD)
                    .filter(([key]) => Object.values(views).includes(key))
                    .map(([view, data]) => {
                        switch (view) {
                            case CommunityView.dashboard:
                            case CommunityView.posts:
                            case CommunityView.media:
                            case CommunityView.calendar:
                                return {
                                    ...data,
                                    to: communityView({ slug: communitySlug, view }),
                                };

                            case CommunityView.events:
                                return {
                                    ...data,
                                    to: communityEvents(communitySlug),
                                };
                            /* istanbul ignore next */
                            default:
                                return undefined;
                        }
                    }),
            ),
        [communitySlug, views],
    );

    return (
        <CommunityNavigation
            {...props}
            isManager={isManager}
            minLength={2}
            staticItems={staticItems}
            scope={RenderingType.community}
        />
    );
};
