import React from 'react';

import { mdiCheck } from '@lumapps/lumx/icons';
import { Icon } from '@lumapps/lumx/react';

import { MenuContext } from '../context';
import { MenuItem, MenuItemProps } from '../MenuItem';
import { MenuSectionRadioGroupContext } from '../MenuSectionRadioGroup';

export interface MenuItemRadioProps extends Omit<MenuItemProps, 'href'> {
    value: string;
}

/**
 * Menu radio item (ARIA `menuitemradio`).
 * A specialised version of `Menu.Item` with a radio state.
 * Needs to be wrapped in a `Menu.SectionRadioGroup`
 *
 * @family Menus
 */
export const MenuItemRadio = React.forwardRef<HTMLElement, MenuItemRadioProps>((props, ref) => {
    const { children, onClick, value, isDisabled, ...forwardedProps } = props;
    const { setValue, value: groupValue } = React.useContext(MenuSectionRadioGroupContext);

    const isChecked = groupValue && groupValue === value;

    const handleClick = React.useCallback(
        (event: React.MouseEvent) => {
            if (isDisabled) {
                return;
            }
            onClick?.(event);
            setValue?.(value);
        },
        [isDisabled, onClick, setValue, value],
    );

    const { menubarVariant } = React.useContext(MenuContext) || {};
    const afterCheckedIndicator = menubarVariant ? undefined : <Icon icon={isChecked ? mdiCheck : ''} size="xs" />;

    if (!setValue && !groupValue) {
        throw new Error(
            'Undefined context, the `Menu.ItemRadio` component should be wrapped in a `Menu.SectionRadioGroup` and it needs a onChange.',
        );
    }

    return (
        <MenuItem
            ref={ref}
            {...forwardedProps}
            isDisabled={isDisabled}
            role="menuitemradio"
            aria-checked={Boolean(isChecked)}
            onClick={handleClick}
            after={afterCheckedIndicator}
        >
            {children}
        </MenuItem>
    );
});
MenuItemRadio.displayName = 'MenuItemRadio';
