import React from 'react';

import { TranslationsContext } from '../context/TranslationsContext';

const useTranslationsContext = () => {
    /** TODO[nounou]: find a way to pass tests */
    // if (context === undefined) {
    //     throw new Error('useTranslationsContext must be used within a TranslationsProvider');
    // }

    return React.useContext(TranslationsContext);
};

export { useTranslationsContext };
