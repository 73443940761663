/* eslint-disable */
(function IIFE() {
    'use strict';

    /////////////////////////////

    function CascadingGridController($element, $scope, $timeout, InitialSettings, Layout) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * An array containing columns HTML elements.
         * Usefull to not refetching elements via $element.find() nor angular.element().
         *
         * @type {Array}
         */
        var _columsElements = [];

        /**
         * The current item to add index.
         *
         * @type {number}
         */
        var _currentIndex;

        /**
         * If we should keep adding next items.
         *
         * @type {boolean}
         */
        var _keepAdding;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The column list.
         *
         * @type {Array}
         */
        vm.columns = [];

        /**
         * An object with all the available post types we can create.
         *
         * @type {Object}
         */
        vm.postTypes = InitialSettings.POST_TYPES;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the index of the column the next post must be added to.
         * The index refers to the column with the smallest height.
         *
         * @return {number} The index of the smallest column.
         */
        function _getLowerColumnIndex() {
            var lowerColumnHeight = 0;
            var lowerColumnIndex = 0;

            for (var i = 0; i < vm.columns.length; i++) {
                /*
                 * When on small screen, getting the latest column index when adding more posts in order to
                 * keep the list being redable up to down.
                 */
                if (_currentIndex > (vm.columns.length - 1) && Layout.breakpoint !== 'desk') {
                    lowerColumnIndex = vm.columns.length - 1;
                    break;
                }

                _columsElements[i] = _columsElements[i] || $element.find('.ls-cascading-grid__column').eq(i);

                var columnElementHeight = _columsElements[i].outerHeight();

                if (i === 0 || columnElementHeight < lowerColumnHeight) {
                    lowerColumnHeight = columnElementHeight;
                    lowerColumnIndex = i;
                }
            }

            return lowerColumnIndex;
        }

        /**
         * Add current item to the correct column.
         *
         * @param {number}  currentIndex The current item index.
         * @param {boolean} prepend      If we should prepend the items.
         * @param {boolean} keepAdding   If we should keep adding next items.
         */
        function _addItem(currentIndex, prepend, keepAdding) {
            var lowerColumnIndex = (prepend) ? 0 : _getLowerColumnIndex();

            _currentIndex = currentIndex;
            _keepAdding = keepAdding;

            if (prepend) {
                vm.columns[0].unshift(vm.source[currentIndex]);
            } else {
                vm.columns[lowerColumnIndex].push(vm.source[currentIndex]);
            }
        }

        /**
         * Remove current from the correct column.
         *
         * @param {Object} deletedItem The deleted item.
         */
        function _removeItem(deletedItem) {
            for (var idx = 0; idx < vm.columns.length; idx++) {
                if (vm.columns[idx].indexOf(deletedItem) > -1) {
                    vm.columns[idx].splice(vm.columns[idx].indexOf(deletedItem), 1);
                    break;
                }
            }
        }

        /**
         * Reset list.
         */
        function _resetList() {
            for (var idx = 0; idx < vm.columnCount; idx++) {
                vm.columns[idx] = [];
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the flex item value to organize the grid.
         *
         * @param  {number} index The index of the column in the grid.
         * @return {number} The flex item value.
         */
        function getFlexItem(index) {
            return Math.floor((12 / vm.columnCount) + ((12 % vm.columnCount > index) ? 1 : 0));
        }

        /////////////////////////////

        vm.getFlexItem = getFlexItem;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        $scope.$on('post-block-loaded', function onPostBlockLoaded(ev, postUid) {
            var nextIndex = _currentIndex + 1;

            $timeout(function waitBeforeAddingNext() {
                if (angular.isDefinedAndFilled(vm.source[_currentIndex]) &&
                    postUid === vm.source[_currentIndex].uid &&
                    nextIndex < vm.source.length &&
                    _keepAdding) {
                    _addItem(nextIndex, false, true);
                }
            });
        });

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch for any changes in the source.
         */
        $scope.$watchCollection(function watchSource() {
            return vm.source;
        }, function onSourceChange(newValue, oldValue) {
            if (newValue.length === oldValue.length && angular.isDefinedAndFilled(newValue)) {
                return;
            }

            if (angular.isUndefinedOrEmpty(newValue)) {
                _resetList();
            } else if (newValue.length - oldValue.length === 1) {
                var newItemIdx = -1;

                for (var idx = 0; idx < newValue.length; idx++) {
                    var foundAddedItem = _.find(oldValue, {
                        uid: newValue[idx].uid,
                    });

                    if (!foundAddedItem) {
                        newItemIdx = idx;
                        break;
                    }
                }

                if (newItemIdx > -1) {
                    var prepend = newItemIdx === 0;
                    _addItem(newItemIdx, prepend, false);
                }
            } else if (oldValue.length - newValue.length === 1) {
                var deletedItem;

                for (var jdx = 0; jdx < oldValue.length; jdx++) {
                    var foundDeletedItem = _.find(newValue, {
                        uid: oldValue[jdx].uid,
                    });

                    if (!foundDeletedItem) {
                        deletedItem = oldValue[jdx];
                        break;
                    }
                }

                if (angular.isDefinedAndFilled(deletedItem)) {
                    _removeItem(deletedItem);
                }
            } else if (newValue.length - oldValue.length > 1) {
                _addItem(oldValue.length, false, true);
            }
        });

        /**
         * Watch for any changes in the column count.
         */
        $scope.$watch(function watchSource() {
            return vm.columnCount;
        }, function onColumnCountChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.columns = [];
                _columsElements = [];
                _resetList();
            }
        });
    }

    /**
     * Cascading Grid directive.
     */

    function CascadingGridDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: CascadingGridController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                columnCount: '@?lsColumnCount',
                isCommunityContext: '<?',
                source: '<lsSource',
                isLoading: '<lsIsLoading',
                widgetProperties: '<?lsWidgetProperties',
            },
            templateUrl: '/client/common/modules/cascading-grid/views/cascading-grid.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsCascadingGrid', CascadingGridDirective);
})();
