import { Dispatch } from '@lumapps/redux/types';

import { getOrFetchInstanceSlug } from '../ducks/thunks';
import { Instance } from '../types';

export interface UpdateInstanceParams {
    to: { instance?: Partial<Pick<Instance, 'id' | 'slug'>>; hasStaleData?: boolean };
    currentInstanceId?: string;
    dispatch: Dispatch;
}

/** (if needed) Fetch instance to update the content link ref. */
export async function updateInstance({ to, currentInstanceId, dispatch }: UpdateInstanceParams) {
    // Skip if id is not available or slug is known (and up to date) or if it's actually the current instance.
    if (!to.instance?.id || (to.instance?.slug && !to.hasStaleData) || to.instance.id === currentInstanceId) {
        return undefined;
    }
    // Cross-instance link => Get or fetch instance slug
    const updatedInstanceSlug = await dispatch(getOrFetchInstanceSlug(to.instance.id));
    // Update instance slug.
    return { instance: { id: to.instance.id, slug: updatedInstanceSlug } };
}
