import { Path, ReactEditor, Transforms, Location } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { getNode } from '@lumapps/wrex/slate/utils/getNode';
import { getSibling } from '@lumapps/wrex/slate/utils/getSibling';
import { ElementAttributes } from '@lumapps/wrex/types/core';

import { IMAGE_ALIGNMENTS } from '../constants';
import { AlignedImageWrapperElement, ImageEditor, ImageGroupElement } from '../types';
import { createAlignedImageWrapper } from './createAlignedImageWrapper';
import { isAlignedImageWrapper } from './isAlignedImageWrapper';
import { isAllowedInAlignedWrapper } from './isAllowedInAlignedWrapper';
import { isImageGroup } from './isImageGroup';

/**
 * Change the alignment for the image
 */
export const changeImageAlignment = (editor: ReactEditor & ImageEditor, path: Path, alignment?: IMAGE_ALIGNMENTS) => {
    if (!isImageGroup(getNode(editor, path)?.[0])) {
        return;
    }

    const parentAlignWrapperPath = findParent(editor, path, isAlignedImageWrapper)?.[1];

    if (!alignment) {
        // Remove the parent alignment wrapper
        if (parentAlignWrapperPath) {
            editor.withoutNormalizing(() => {
                // Before unwrapping, move back the image before its siblings
                editor.moveNodes({
                    at: path,
                    to: [...parentAlignWrapperPath, 0],
                });
                Transforms.unwrapNodes(editor, { at: parentAlignWrapperPath });
            });
        }
        return;
    }

    // Remove width attribute
    const imageGroupAttributes: Partial<ImageGroupElement> = { width: undefined };
    editor.setNodes(imageGroupAttributes, { at: path });

    const wrapperProps: ElementAttributes<AlignedImageWrapperElement> = { alignment };

    // Update the parent alignment wrapper
    if (parentAlignWrapperPath) {
        editor.setNodes(wrapperProps, { at: parentAlignWrapperPath, match: isAlignedImageWrapper });
        return;
    }

    // Wrap the image and compatible sibling in the image alignment wrapper
    const [blockAfterImage, blockAfterPath] = getSibling(editor, path, 'after') || [];

    const wrapper = createAlignedImageWrapper(wrapperProps, []);

    let at: Location;
    if (blockAfterPath && isAllowedInAlignedWrapper(blockAfterImage)) {
        at = {
            focus: { path, offset: 0 },
            anchor: { offset: 0, path: blockAfterPath },
        };
    } else {
        at = path;
    }
    Transforms.wrapNodes(editor, wrapper, { at });
};
