// Utils to get the gap between the previous and next choice.
export const getUserChoicesGap = (prevChoice: boolean, newChoice: boolean) => {
    if (prevChoice === newChoice) {
        return 0;
    }
    if (prevChoice === false && newChoice === true) {
        return 1;
    }
    if (prevChoice === true && newChoice === false) {
        return -1;
    }
    return 0;
};
