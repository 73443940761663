import React from 'react';

import { usePlayerClassnames } from '../../hooks';
import { Iframe } from '../Iframe';
import { Facade } from '../Player.Facade';
import { Metadata } from '../Player.Metadata';
import { Thumbnail } from '../Player.Thumbnail';
import { PlayerContextProvider } from './PlayerContext';

import './Player.scss';

export interface PlayerProps extends React.ComponentPropsWithoutRef<typeof Iframe> {
    /** which HTML element to render for displaying the component. */
    as?: React.ElementType;
    /** figcaption content */
    caption?: React.ReactElement;
    /** Player custom classname */
    className?: string;
    /** Component children */
    children?: React.ReactNode;
    /** Play video id */
    videoId: string;
}

const Player = React.forwardRef<HTMLIFrameElement, PlayerProps>(function Player(
    { as: Player = 'figure', caption, children, className, videoId, ...iframeProps },
    ref,
) {
    const { block } = usePlayerClassnames();

    return (
        <Player className={block([className])}>
            <PlayerContextProvider videoId={videoId}>
                {children}

                <Iframe ref={ref} {...iframeProps} />
            </PlayerContextProvider>

            {caption && <figcaption>{caption}</figcaption>}
        </Player>
    );
});

export const CompoundPlayer = Object.assign(Player, { Facade, Metadata, Thumbnail });
