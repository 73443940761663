import { CACHE_TYPE } from '../types';
import { memoryCache } from './memoryStore';
import { persistentCache } from './persistentStore';

/**
 * Stores the given value on the given key for the specific cache type.
 * By default it will store on memory cache
 */
export const store = (key: string, value: any, type = CACHE_TYPE.MEMORY) => {
    switch (type) {
        case CACHE_TYPE.STORAGE:
            persistentCache.store(key, value);
            break;
        case CACHE_TYPE.MEMORY:
            memoryCache.store(key, value);
            break;
        default:
            memoryCache.store(key, value);
            break;
    }
};

/**
 * Retrieves the given key for the specific cache type.
 * By default it will retrieve from memory cache
 */
export const retrieve = (key: string, type = CACHE_TYPE.MEMORY): any => {
    switch (type) {
        case CACHE_TYPE.STORAGE:
            return persistentCache.retrieve(key);
        case CACHE_TYPE.MEMORY:
            return memoryCache.retrieve(key);
        default:
            return memoryCache.retrieve(key);
    }
};

/**
 * Clears all values from the given cache.
 * @param type
 */
export const clearAll = (type = CACHE_TYPE.MEMORY): void => {
    switch (type) {
        case CACHE_TYPE.STORAGE:
            return persistentCache.clearAll();
        case CACHE_TYPE.MEMORY:
            return memoryCache.clearAll();
        default:
            return memoryCache.clearAll();
    }
};

const cache = {
    store,
    retrieve,
    clearAll,
};

export { cache };
