const objectToArrayWithLabel = <T extends object>(obj: T) => {
    return Object.keys(obj).map((val, index) => {
        return {
            label: Object.values(obj)[index],
            value: val as unknown as T,
        };
    });
};

export { objectToArrayWithLabel };
