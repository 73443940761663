import { Image } from '@lumapps/lumx-images/types';
import { ImageEditor, ImageEditorOptions, ImageGroupElement } from '@lumapps/wrex-enhanced-image/types';
import { PathRef, ReactEditor, Transforms } from '@lumapps/wrex/slate';

import { uploadImageGallery } from './uploadImageGallery';

/**
 * Update an image gallery node at a given pathref.
 */
export async function updateImageGalleryNode(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    attributes: Partial<ImageGroupElement>,
    nodePathRef: PathRef,
    images?: Image[],
) {
    const at = nodePathRef.current;

    if (at) {
        if (images) {
            const imageElements = await uploadImageGallery(options, editor, images);
            Transforms.setNodes(editor, { ...attributes, images: imageElements }, { at });
        } else {
            Transforms.setNodes(editor, { ...attributes }, { at });
        }
    }
}
