import React from 'react';

import { useFetchUniqueVideo } from '@lumapps/play';

import { usePlayerClassnames } from '../../hooks';
import type { Video } from '../../types';
import { usePlayerContext } from '../Player';
import { Skeleton } from '../Skeleton';

import './Facade.scss';

export type Api = 'cms' | 'monolite' | 'stream';
interface MonoliteFacadeProps {
    api: 'monolite';
    children: React.ReactNode;
}

interface CmsFacadeProps {
    api: 'cms';
    children: React.ReactNode;
}

interface StreamFacadeProps {
    api: 'stream';
    children: React.ReactNode;
    fields: (keyof Video)[];
}

interface WithoutAPIFacadeProps {
    children: React.ReactNode;
    video: Video;
}

export interface FacadeProps {
    /** Api to use to fetch video details */
    api?: Api;
    /** Component children */
    children: React.ReactNode;
    /** The video when fetched from the parent component */
    video?: Video;
    /** Video Fields to retrieve when using the Stream api */
    fields?: (keyof Video)[];
}

/**
 These are Typescript overloads.
 *
 * @see https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
 */
export function Facade({ api, children }: MonoliteFacadeProps): JSX.Element | null;
export function Facade({ api, children }: CmsFacadeProps): JSX.Element | null;
export function Facade({ api, children, fields }: StreamFacadeProps): JSX.Element | null;
export function Facade({ children, video }: WithoutAPIFacadeProps): JSX.Element | null;

export function Facade({ api, children, video, fields }: FacadeProps) {
    const {
        state: { isFacadeVisible, videoId },
        dispatch,
    } = usePlayerContext();

    const { element } = usePlayerClassnames();

    const { video: fetchedVideo, videoLoading } = useFetchUniqueVideo(
        { videoId, fields },
        { enabled: api ? api !== 'monolite' : false },
    );

    React.useEffect(() => {
        if (video) {
            dispatch({ type: 'SET_VIDEO', video });
            return;
        }
        if (fetchedVideo) {
            dispatch({ type: 'SET_VIDEO', video: fetchedVideo });
        }
    }, [dispatch, fetchedVideo, video]);

    if (!isFacadeVisible) {
        return null;
    }

    if (api && api !== 'monolite' && videoLoading) {
        return <Skeleton />;
    }

    return <div className={element('facade')}>{video || api === 'monolite' ? children : fetchedVideo && children}</div>;
}
