import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ElementRender } from '@lumapps/wrex/types';

import { ALIGNED_IMAGE_WRAPPER, IMAGE_ALIGNMENTS } from '../../../constants';
import { AlignedImageWrapperElement } from '../../../types';
import { isImageGroup } from '../../../utils/isImageGroup';

import './index.scss';

export const CLASSNAME = 'wrex-content-aligned-image-wrapper';

export const AlignedImageWrapper: ElementRender<AlignedImageWrapperElement, HTMLDivElement> = (props) => {
    const {
        className,
        elementRef,
        children,
        element: { alignment, children: elementChildren } = {},
        ...forwardedProps
    } = props;
    const { block, element } = useClassnames(CLASSNAME);

    const imageIndex = elementChildren?.findIndex(isImageGroup) as number;
    const childrenArray = React.Children.toArray(children);
    const image = childrenArray[imageIndex];
    const others = childrenArray.filter((_, i) => i !== imageIndex);

    return (
        <div className={block([className])} {...forwardedProps} ref={elementRef}>
            {alignment === IMAGE_ALIGNMENTS.left && image}
            <div className={element('text-wrapper')}>{others}</div>
            {alignment === IMAGE_ALIGNMENTS.right && image}
        </div>
    );
};
AlignedImageWrapper.displayName = ALIGNED_IMAGE_WRAPPER;
