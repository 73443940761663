import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { AlertDialog, Kind, Text, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SOCIAL_NETWORKS } from '../../keys';

export interface ConfirmDisconnectDialogProps {
    confirmDisconnectSocialNetwork: any;
    toggleConfirmDisconnect: any;
    disconnect: any;
    className: string;
}

export const ConfirmDisconnectDialog: React.FC<ConfirmDisconnectDialogProps> = ({
    confirmDisconnectSocialNetwork,
    toggleConfirmDisconnect,
    disconnect,
    className,
}) => {
    const { element } = useClassnames(className);
    const { translateAndReplaceList, translateKey } = useTranslate();

    return confirmDisconnectSocialNetwork.socialNetwork ? (
        <AlertDialog
            className={element('dialog')}
            isOpen={confirmDisconnectSocialNetwork.isConfirmOpen}
            id="disconnect-social-network-confirm-dialog"
            kind={Kind.warning}
            title={translateAndReplaceList(
                SOCIAL_NETWORKS.DISCONNECT_CONFIRM_TITLE,
                ['PLATFORM'],
                [confirmDisconnectSocialNetwork.socialNetwork.socialNetworkId],
            )}
            confirmProps={{
                onClick: () => {
                    if (confirmDisconnectSocialNetwork.socialNetwork) {
                        disconnect(confirmDisconnectSocialNetwork.socialNetwork);
                    }
                },
                label: translateKey(GLOBAL.CONFIRM),
            }}
            cancelProps={{
                onClick: () => {
                    toggleConfirmDisconnect({
                        socialNetwork: undefined,
                        isConfirmOpen: false,
                    });
                },
                label: translateKey(GLOBAL.CANCEL),
            }}
        >
            <Text as="p" typography={Typography.body1}>
                {translateAndReplaceList(
                    SOCIAL_NETWORKS.DISCONNECT_CONFIRM_DESCRIPTION,
                    ['PLATFORM'],
                    [confirmDisconnectSocialNetwork.socialNetwork.socialNetworkId],
                )}
            </Text>
        </AlertDialog>
    ) : null;
};
