function sharepointSiteBlockController($scope, Config, Fsitems, Instance) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the default classes to apply to the block.
     * Can be overridden by directly passing a vm.classes attribute to the directive.
     *
     * @return {Array} A list of css classes to apply to the block.
     */
    function _getDefaultClasses() {
        const defaultClasses = [];

        if (vm.theme === 'dark') {
            defaultClasses.push('sharepoint-site-block--theme-dark');
        } else {
            defaultClasses.push('sharepoint-site-block--theme-light');
        }

        return defaultClasses;
    }

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        if (angular.isUndefinedOrEmpty(vm.classes)) {
            vm.classes = _getDefaultClasses();

            /**
             * Watch any changes to the theme to recompute the classes.
             */
            $scope.$watch('vm.theme', () => {
                vm.classes = _getDefaultClasses();
            });
        }

        const defaultFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.SHAREPOINT_SITE_FIELDS);

        vm.fieldsToDisplay = vm.fieldsToDisplay || defaultFields;
        vm.onClick = vm.onClick || angular.noop;
        vm.style = vm.style || {};
        vm.theme = vm.theme || 'light';
    }

    init();
}

/////////////////////////////

/**
 * The Sharepoint site block directive.
 * Displays a single site item within a list.
 *
 * @param {Array}    [classes]               A list of css classes to apply to the block.
 * @param {Object}   [fieldsToDisplay=<all>] A map of fields and their visibility in the block.
 * @param {Object}   site                    The site to display in the block.
 * @param {Function} [onClick]               A function to execute when the site block is clicked.
 * @param {Object}   [style]                 Style properties to be applied to the site block.
 * @param {string}   [theme="light"]         The theme to use to display the site block.
 *                                           Possible values are "light" or "dark".
 */

function LsSharepointSiteBlockDirective() {
    return {
        bindToController: true,
        controller: sharepointSiteBlockController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            classes: '<?lsClasses',
            favorites: '<?lsFavorites',
            fieldsToDisplay: '<?lsFieldsToDisplay',
            onClick: '&?lsOnClick',
            reloadFn: '&lsReloadFn',
            site: '<lsSite',
            style: '<?lsStyle',
            theme: '@?lsTheme',
        },
        templateUrl: '/client/common/modules/sharepoint-site/views/sharepoint-site-block.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsSharepointSiteBlock', LsSharepointSiteBlockDirective);
