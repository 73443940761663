import BaseApi from '@lumapps/base-api';
import { logException } from '@lumapps/utils/log/logException';

import { QuickSearchResultsQueryParams, QuickSearchResults, QuickSearchQuery, QuickSearchEntity } from '../types';

const quickSearchApi = new BaseApi({
    path: 'quick-search',
    version: BaseApi.versions.v2,
});

export const fetchAll = (params: QuickSearchResultsQueryParams): Promise<QuickSearchResults> => {
    return quickSearchApi
        .get('', {
            params: {
                siteId: params.siteId,
            },
        })
        .then(({ data }) => ({
            entities: data.entities || [],
            queries: data.queries || [],
        }));
};

export const saveQuery = (query: QuickSearchQuery) => {
    return quickSearchApi.post('queries', {
        // eslint-disable-next-line radix
        siteId: parseInt(query.siteId, 0),
        query: query.query,
        lastUsed: query.lastUsed,
    });
};

export const deleteQuery = (query: Pick<QuickSearchQuery, 'siteId' | 'query'>) => {
    return quickSearchApi.delete('queries', {
        params: {
            // eslint-disable-next-line radix
            siteId: parseInt(query.siteId, 0),
            query: query.query,
        },
    });
};

export const saveEntityInteraction = async (item: QuickSearchEntity, query: string) => {
    try {
        return await quickSearchApi.post('entities', {
            ...item,
            query,
            // eslint-disable-next-line radix
            siteId: parseInt(item.siteId, 0),
            originSiteId: `${item.originSiteId}`,
        });
    } catch (error) {
        return logException(error);
    }
};

export const deleteEntityInteraction = (item: QuickSearchEntity) => {
    return quickSearchApi.delete('entities', {
        params: {
            // eslint-disable-next-line radix
            siteId: parseInt(item.siteId, 0),
            entityId: item.entityId,
            entityType: item.entityType,
        },
    });
};
