import BaseApi from '@lumapps/base-api';

import { ShareableContentStatuses } from '../types';

export const saApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy' });

export const fetchContentActions = (contentId: string) => {
    return saApi.get(`/utils/content-actions/${contentId}`);
};

export const fetchShareableContents = (
    params: {
        programId?: string;
        statuses?: Array<ShareableContentStatuses>;
        contentInternalId?: string;
        linkUrl?: string;
        sortField?: 'createdAt' | null;
        sortDir?: '-1' | '1';
        maxResults?: string;
    } = {},
) => {
    return saApi.get('/shareable-contents', { params });
};
