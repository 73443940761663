import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';

import { NAMESPACE } from '../../constants';
import { MainTopNavigationItemsSkeletons } from './MainTopNavigationItemsSkeletons';

const constants = get();

/**
 * Display the navigation with all link items in loading state mode
 * @param MainTopNavigationProps props
 */
const MainTopNavigationLoadingState: React.FC<any> = ({
    navStyles = { backgroundColor: constants.instanceNavBackgroundColor },
}) => {
    const { block, element } = useClassnames(NAMESPACE);
    return (
        <nav role="navigation" style={{ backgroundColor: navStyles.backgroundColor }} className={block('is-loading')}>
            <div className={element('wrapper')}>
                <ul className={element('root')}>
                    <MainTopNavigationItemsSkeletons />
                </ul>
            </div>
        </nav>
    );
};

export { MainTopNavigationLoadingState, NAMESPACE };
