/* eslint-disable lumapps/no-manual-bems */
/* eslint-disable lumapps/no-classname-strings */
import React, { ReactNode } from 'react';

import { classnames } from '@lumapps/classnames';
import { useHomeRoute } from '@lumapps/contents/hooks/useHomeRoute';
import { useCustomizations, MODES } from '@lumapps/customizations';
import { Targets, CustomizationComponent, PLACEMENT } from '@lumapps/customizations/api';
import { mdiMenu } from '@lumapps/lumx/icons';
import { Tooltip, Placement, IconButton, Emphasis } from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';
import { createUrl } from '@lumapps/router/utils';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { HEADER } from '../../keys';
import { InstanceLogo } from '../InstanceLogo';
import { HeaderActions } from './HeaderActions';
import { HeaderSkipLinks } from './HeaderSkipLinks';

export interface TopBarProps {
    /**
     * Children to display inside the header
     */
    children?: ReactNode;
    /**
     * Styles to be applied to the top section of the bar
     */
    topStyles?: Record<string, any>;
    /**
     * Whether the user is connected or not
     */
    isConnected?: boolean;
    /**
     * current instance id
     */
    instanceId: string;
    /**
     * current language
     */
    currentLanguage: string;
    /**
     * name of the current instance
     */
    instanceName?: string;
    /**
     * List of FFs and whether they are activated or not.
     */
    features: Record<string, boolean>;
    /**
     * Whether the header should be sticky or not.
     */
    isStickyEnabled?: boolean;
    /**
     * ref to the header
     */
    headerRef: React.RefObject<HTMLDivElement>;
    /**
     * callback to open the side navigation
     */
    setIsSideNavOpen: (isOpen: boolean) => void;
    /**
     * whether the side nav is open
     */
    isSideNavOpen: boolean;
    /**
     * Disables the top bar
     */
    isDisabled?: boolean;
}

const CLASSNAME = 'header-top';

export const TopBar: React.FC<TopBarProps> = ({
    children,
    topStyles = {},
    isConnected = false,
    instanceId,
    instanceName,
    features,
    currentLanguage,
    isStickyEnabled = true,
    headerRef,
    setIsSideNavOpen,
    isSideNavOpen,
    isDisabled,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const homeRef = React.useRef<HTMLAnchorElement>(null);
    const { isMobile } = useResponsive();

    const homeUrl = createUrl(useHomeRoute());

    const headerTopClassname = classnames(CLASSNAME, {
        [`${CLASSNAME}-not-connected`]: !isConnected,
        [`${CLASSNAME}--is-disabled`]: isDisabled,
        sticky: isStickyEnabled,
    });
    const logoTitle = instanceName
        ? translateAndReplace(GLOBAL.HOME_PAGE, { site: instanceName })
        : translateKey(GLOBAL.GO_TO_HOMEPAGE);

    const { modes } = useCustomizations();

    /**
     * Disable mouse & focus.
     * We must type it as `any` because `inert` is not yet in React types.
     */
    const inertProp: any = { inert: isDisabled ? 'true' : undefined };

    /**
     * when the no top bar mode is active, in desktop we simply display nothing.
     * In responsive, we need to display the hamburguer menu so that the user
     * can at least go to the navigation.
     */
    if (modes[MODES.NO_TOP_BAR]) {
        if (isMobile && isConnected) {
            /**
             * These lines of code are in a separate condition than the main component
             * in order to maintain independance. We really do not want to mix these components with
             * the main top bar, we should not add anything new here.
             */
            return (
                <div
                    className={headerTopClassname}
                    style={{ backgroundColor: topStyles.backgroundColor }}
                    ref={headerRef}
                >
                    {!isDisabled ? <HeaderSkipLinks /> : null}

                    <div className={`${CLASSNAME}__wrapper`} {...inertProp}>
                        <div className={`${CLASSNAME}__mobile-side-nav`}>
                            <IconButton
                                icon={mdiMenu}
                                emphasis={Emphasis.low}
                                onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                                color={topStyles.themeColor}
                                label={translateKey(HEADER.OPEN_NAVIGATION)}
                                aria-expanded={isSideNavOpen}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    /**
     * HEAD'S UP!
     * If you are going to add any components to the header, you will need to update the component
     * LoadingHeader and add a skeleton for the one you added. Also, you will need to go to the
     * /apps/front-office/public/index.html and change the HTML markup to add that skeleton as well.
     *
     * That markup is the one displayed between the moment the browser has downloaded the HTML of the
     * page, but the javascript has not yet loaded. Since we do not have React to render something, that is why
     * the code is directly as an HTML.
     */
    return (
        <div className={headerTopClassname} style={{ backgroundColor: topStyles.backgroundColor }} ref={headerRef}>
            {!isDisabled ? <HeaderSkipLinks /> : null}

            <div className={`${CLASSNAME}__wrapper`} {...inertProp}>
                {isMobile && isConnected ? (
                    <div className={`${CLASSNAME}__mobile-side-nav`}>
                        <IconButton
                            icon={mdiMenu}
                            emphasis={Emphasis.low}
                            onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                            color={topStyles.themeColor}
                            label={translateKey(HEADER.OPEN_NAVIGATION)}
                            aria-expanded={isSideNavOpen}
                        />
                    </div>
                ) : null}

                <CustomizationComponent target={Targets.LOGO} placement={PLACEMENT.LEFT} />

                <div className={`${CLASSNAME}__logo`}>
                    <Tooltip placement={Placement.BOTTOM} label={logoTitle}>
                        <InstanceLogo homeLinkUrl={homeUrl} ref={homeRef} title={logoTitle} />
                    </Tooltip>
                </div>

                <CustomizationComponent target={Targets.LOGO} placement={PLACEMENT.RIGHT} />

                <HeaderActions
                    className={CLASSNAME}
                    isConnected={isConnected}
                    topStyles={topStyles}
                    instanceId={instanceId}
                    features={features}
                    theme={topStyles.theme}
                    currentLanguage={currentLanguage}
                >
                    {children}
                </HeaderActions>
            </div>
        </div>
    );
};
