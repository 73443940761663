import React from 'react';

import { useDispatch } from '@lumapps/redux/react';

import { actions } from '../ducks/slice';

/**
 * Hook that returns a dispatch function to update
 * the page's global live region.
 */
export const useDispatchGlobalLiveMessage = () => {
    const dispatch = useDispatch();

    /** Callback to update the live message */
    const updateA11YLiveMessage = React.useCallback(
        (newMessage: string) => {
            dispatch(actions.setA11YMessage(newMessage));
        },
        [dispatch],
    );

    return updateA11YLiveMessage;
};
