import flow from 'lodash/flow';
import flatMap from 'lodash/fp/flatMap';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';

import { isAlignedImageWrapper } from '@lumapps/wrex-enhanced-image/utils/isAlignedImageWrapper';
import { Editor, Element, Node, NodeEntry, Path, ReactEditor, Text, Transforms } from '@lumapps/wrex/slate';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';
import { normalizeLastBlock } from '@lumapps/wrex/slate/utils/normalizeLastBlock';
import { replaceParentNodeByChild } from '@lumapps/wrex/slate/utils/replaceParentNodeByChild';

import { createParagraph } from '../../utils/createParagraph';
import { isCodeBlock } from '../../utils/isCodeBlock';
import { isHeadline } from '../../utils/isHeadline';
import { isParagraph } from '../../utils/isParagraph';
import { isSubtitle } from '../../utils/isSubtitle';
import { isTitle } from '../../utils/isTitle';

export interface NormalizeElementOptions {
    /** Whether marks should only be allowed in paragraphs or not. */
    allowedMarkParents?: string[];
    /** Whether inlines should only be allowed in paragraphs or not. */
    allowInlinesOnlyInParagraph?: boolean;
    /** Whether alignment should only be allowed in the given block type or not. */
    allowedAlignmentParents?: string[];
}

export function normalizeElement(editor: Editor, [node, path]: NodeEntry, options?: NormalizeElementOptions) {
    if (!isElement(node)) {
        return false;
    }

    // Prevent marks outside paragraphs.
    if (options?.allowedMarkParents && !options?.allowedMarkParents.includes(node.type)) {
        const searchTextNodesOptions = { at: path, mode: 'lowest', match: Text.isText, voids: true } as const;
        const textNodes = node.children.filter(Text.isText);
        // Distinct list of marks in text nodes.
        const marks = flow([flatMap((textNode: Text) => Object.keys(omit(textNode, 'text'))), uniq])(textNodes);
        if (marks.length) {
            Transforms.unsetNodes(editor, marks, searchTextNodesOptions);
            return true;
        }
    }

    if (!Editor.isBlock(editor, node)) {
        return false;
    }

    let normalized = false;

    // Prevent inline elements outside paragraphs.
    if (options?.allowInlinesOnlyInParagraph && !isParagraph(node)) {
        normalized = true;
        Transforms.unwrapNodes(editor, {
            at: path,
            mode: 'lowest',
            voids: true,
            match: (n, p) => Element.isElement(n) && Editor.isInline(editor, n) && p.length === path.length + 1,
        });
    }

    // Prevent heading or title inside paragraph
    if (
        isParagraph(node) &&
        (isHeadline(node.children[0]) || isTitle(node.children[0]) || isSubtitle(node.children[0]))
    ) {
        return replaceParentNodeByChild(editor, path);
    }

    /**
     * Prevent aligned image wrapper inside paragraph node
     * And move all text and paragraph node next to the aligned image wrapper
     * into the children of the aligned image wrapper node
     */
    if (isParagraph(node) && node.children.find((item) => isAlignedImageWrapper(item))) {
        const childrenNodes = Array.from(Node.children(editor, path));
        const childParagraphNodes: Node[] = [];
        const alignedImageWrapperNodes: NodeEntry[] = [];
        let paragraphText = '';
        let parentPath = path;

        if (childrenNodes.length > 1) {
            childrenNodes.forEach(([elementNode, elementPath]: [elementNode: any, elementPath: Path]) => {
                if (isParagraph(elementNode)) {
                    childParagraphNodes.push(elementNode);
                }
                if (isAlignedImageWrapper(elementNode)) {
                    alignedImageWrapperNodes.push([elementNode, elementPath]);
                }
                // Text from HTML
                else if (elementNode.text) {
                    paragraphText += elementNode.text;
                }
            });
        }

        let childIndex = 0;

        if (alignedImageWrapperNodes.length === 1) {
            const imageWrapper = childrenNodes.find((element) => isAlignedImageWrapper(element[0]));

            // Insert the text on the image wrapper node children
            if (imageWrapper) {
                const [, nodeIndex] = imageWrapper[1];
                childIndex = nodeIndex;
                const [childNodeEntry] = Node.children(editor, imageWrapper[1]);

                Transforms.insertNodes(
                    editor,
                    paragraphText ? [createParagraph([{ text: paragraphText }])] : childParagraphNodes,
                    {
                        at: childNodeEntry[1],
                    },
                );

                return replaceParentNodeByChild(editor, path, childIndex);
            }

            return false;
        }

        // Normalize images next to each others on pasting (supported in Word)
        alignedImageWrapperNodes.forEach((element, index) => {
            // Insert the paragraph in the aligned image wrapper node
            if (index === alignedImageWrapperNodes.length - 1) {
                const afterPath = getSiblingPath(parentPath, 'after') || [];
                const parentNodeChilds = Array.from(Node.children(editor, afterPath));
                const reversedArray = parentNodeChilds.reverse();
                const lastImageWrapper = reversedArray.find((element) => isAlignedImageWrapper(element[0]));

                parentPath = afterPath;

                // Insert the text on the image wrapper node children
                if (lastImageWrapper) {
                    const [, nodeIndex] = lastImageWrapper[1];
                    childIndex = nodeIndex;
                    const [childNodeEntry] = Node.children(editor, lastImageWrapper[1]);

                    Transforms.insertNodes(
                        editor,
                        paragraphText ? [createParagraph([{ text: paragraphText }])] : childParagraphNodes,
                        {
                            at: childNodeEntry[1],
                        },
                    );
                }
            } else {
                Transforms.insertNodes(editor, [element[0]], { at: parentPath || [] });

                // Change the path for the next aligned image wrapper
                if (alignedImageWrapperNodes.length > 2) {
                    const afterPath = getSiblingPath(path, 'after') || [];
                    parentPath = afterPath;
                }
            }
        });

        return replaceParentNodeByChild(editor, parentPath, childIndex);
    }

    // Insert a paragraph element after and before code block.
    normalizeLastBlock(editor as ReactEditor, [node, path], isCodeBlock);

    return normalized;
}
