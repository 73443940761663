import { saApi } from './constants';

/**
 * Get all the shareable contents with performance insights.
 *
 * @param  {string}                [filter='1']             Filter parameter used to get either
 *                                                          non archived ( = 1) / archived ( = 2 ) /
 *                                                          all content ( = undefined ).
 * @param  {string}                limit                    The body limit.
 * @param  {string}                [sortDir='-1']           The direction of the sort.
 * @param  {string}                cursor                   The cursor used by the API paging feature.
 * @param  {string}                [sortField='createdAt'] The field to sort on.
 *                                                          Could be either '-1' for desc or '1' for 'asc'.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getShareableContentsWithAnalytics = (
    filter = {},
    limit,
    sortDir = -1,
    cursor,
    sortField = 'createdAt',
) => {
    const body = {
        filters: { ...filter },
        sortDir,
        sortField,
    };
    if (limit) {
        body.limit = limit;
    }
    if (cursor) {
        body.cursor = cursor;
    }

    return saApi.post('dashboard', body);
};
