(function IIFE() {
    'use strict';

    /////////////////////////////

    function PostIdeaService($rootScope, Post, PostVoteFactory) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Loading store.
         *
         * @type {Object}
         */
        var _loading = {};

        /**
         *
         *
         * @type {Object}
         */
        var _voteType = {
            down: 'down',
            up: 'up',
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Status dialog identifier.
         *
         * @type {string}
         */
        service.statusDialogIdPrefix = 'idea-status-dialog-id-';

        /**
         * Use to store the pending status (edition mode).
         *
         * @type {string}
         */
        // TODO [max/gcc]: set a pendingPost ? and use it for category ?
        service.pendingStatus = undefined;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Submit a vote for an idea.
         *
         * @param {Object} post The post to vote to.
         * @param {string} type The type of vote.
         */
        function _vote(post, type) {
            if (angular.isDefined(post) && post.postType.toLowerCase() === 'idea') {
                _loading[post.uid] = true;
                var payload = {
                    uid: post.uid,
                    vote: type,
                };

                PostVoteFactory.vote(payload, undefined, function onVoteSuccess(response) {
                    _loading[post.uid] = false;

                    $rootScope.$broadcast('post__vote-done', response);
                }, function onVoteError() {
                    _loading[post.uid] = false;
                });
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close the idea status dialog.
         *
         * @param {Object}   post    The edited post reference.
         * @param {boolean}  refSave If only the reference is update.
         * @param {Function} [cb]    The success callback.
         * @param {Function} [errCb] The error callback.
         */
        function saveStatus(post, refSave, cb, errCb) {
            if (angular.isUndefinedOrEmpty(service.pendingStatus)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            // Copy post, if the save fail the status is not modified in the post reference.
            var payload = angular.fastCopy(post);
            payload.postStatus = service.pendingStatus;

            if (refSave) {
                post.postStatus = service.pendingStatus;
                post.postStatusDetails = _.find(_.get(post.parentContentDetails, 'postStatuses'), {
                    uuid: service.pendingStatus,
                });
                service.pendingStatus = undefined;
            } else {
                Post.save(payload, function onSaveSuccess(response) {
                    post.postStatus = response.postStatus;
                    post.postStatusDetails = response.postStatusDetails;
                    post.version = response.version;
                    service.pendingStatus = undefined;

                    cb();
                }, errCb, post.id);
            }
        }

        /**
         * Decrement the idea score.
         *
         * @param {Object} post The post to interact with.
         */
        function downVote(post) {
            _vote(post, _voteType.down);
        }

        /**
         * Check if a post is loading, it's an alias.
         *
         * @param  {string}  postId The identifier of the post.
         * @return {boolean} The loading status.
         */
        function isLoading(postId) {
            return _loading[postId];
        }

        /**
         * Increment the idea score.
         *
         * @param {Object} post The post to interact with.
         */
        function upVote(post) {
            _vote(post, _voteType.up);
        }

        /////////////////////////////

        service.downVote = downVote;
        service.isLoading = isLoading;
        service.saveStatus = saveStatus;
        service.upVote = upVote;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('PostIdea', PostIdeaService);
})();
