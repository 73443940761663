import * as React from 'react';
import * as propTypes from 'prop-types';

import { Style as StyleService } from '../../../../services/styles';
import { offset } from '../../../../utils';

/**
 * Renders a draggable icon that proxies the icon of a header/footer.
 */
export class IconProxy extends React.PureComponent {
    static propTypes = {
        /** Called when the icon dragging ends. */
        onDrop: propTypes.func.isRequired,
        /** Called when the icon dragging starts. */
        onPick: propTypes.func.isRequired,
        /** Current icon location. Changing it triggers necessary internal state updates. */
        value: propTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            left: 0,
            node: null,
            originX: 0,
            originY: 0,
            top: 0,
            x: 0,
            y: 0,
        };

        /**
         * Store node.
         *
         * @param {Object} node The icon DOM element.
         */
        this.setNode = (node) => {
            if (!node) {
                return;
            }
            const bounds = offset(node);
            this.setState({
                left: bounds.left,
                node,
                top: bounds.top,
            });
        };

        /**
         * When picking the icon, store current mouse origin and enable dragging mode.
         *
         * @param {Object} occurrence The event object.
         */
        this.onPick = (occurrence) => {
            occurrence.preventDefault();
            occurrence.stopPropagation();
            const bounds = offset(this.state.node);
            this.setState(
                {
                    dragging: true,
                    left: bounds.left,
                    originX: occurrence.pageX,
                    originY: occurrence.pageY,
                    top: bounds.top,
                    x: occurrence.pageX,
                    y: occurrence.pageY,
                },
                () => this.props.onPick(occurrence),
            );
        };

        /**
         * When moving the mouse, update the dragged icon location.
         *
         * @param {Object} occurrence The event object.
         */
        this.onDrag = (occurrence) => {
            if (!this.state.dragging) {
                return;
            }
            occurrence.preventDefault();
            occurrence.stopPropagation();
            this.setState({
                x: occurrence.pageX,
                y: occurrence.pageY,
            });
        };

        /**
         * When releasing the mouse button, stop dragging the icon and
         * trigger `onDrop(event)`.
         *
         * @param {Object} occurrence The event object.
         */
        this.onDrop = (occurrence) => {
            if (!this.state.dragging) {
                return;
            }
            occurrence.preventDefault();
            occurrence.stopPropagation();
            this.setState({ dragging: false }, () => this.props.onDrop(occurrence));
        };
    }

    /**
     * When the icon changes location, recompute its bounds and update the `origin`.
     *
     * @param {Object} prevProps The previous properties.
     * @param {Object} prevState The previous state.
     */
    componentDidUpdate(prevProps) {
        const { state } = this;
        if (!state.node || prevProps.value === this.props.value) {
            return;
        }
        const bounds = offset(state.node);
        if (state.left === bounds.left && state.top === bounds.top) {
            return;
        }
        this.setState({
            left: bounds.left,
            originX: state.originX + (bounds.left - state.left),
            originY: state.originY + (bounds.top - state.top),
            top: bounds.top,
        });
    }

    /**
     * On mount, listen for global mouse moves to follow the mouse and button up
     * to end the drag.
     */
    componentDidMount() {
        global.document.addEventListener('mousemove', this.onDrag, true);
        global.document.addEventListener('mouseup', this.onDrop, true);
    }

    /**
     * On unmount, remove global mouse listeners.
     */
    componentWillUnmount() {
        global.document.removeEventListener('mousemove', this.onDrag, true);
        global.document.removeEventListener('mouseup', this.onDrop, true);
    }

    render() {
        const { state } = this;

        return (
            <div
                className="widget-style-area__icon"
                style={{ position: 'relative' }}
                onMouseDown={this.onPick}
                ref={this.setNode}
            >
                {state.dragging && (
                    <div
                        className="widget-style-area__icon"
                        style={{
                            left: state.x - state.originX,
                            marginBottom: 0,
                            marginLeft: -2,
                            marginRight: 0,
                            marginTop: -2,
                            opacity: 0.5,
                            pointerEvents: 'none',
                            position: 'absolute',
                            top: state.y - state.originY,
                            zIndex: 100,
                        }}
                    />
                )}
            </div>
        );
    }
}
