import * as React from 'react';

import { Select } from '../menus/Select';

/**
 * Used by `<ColorSelect />` to render the current value.
 */
class ValueComponent extends React.PureComponent {
    static propTypes = {
        value: Select.propTypes.value,
    };

    render() {
        const { value } = this.props;

        return <div className="custom-select__selection-color" style={{ backgroundColor: value }} />;
    }
}

/**
 * Used by `<ColorSelect />` to render a choice value.
 */
class ChoiceComponent extends React.PureComponent {
    static propTypes = {
        value: Select.propTypes.value,
    };

    render() {
        const { value } = this.props;

        return <div className="custom-select__choice-color" style={{ backgroundColor: value }} />;
    }
}

/**
 * Renders a color selector.
 */
export class ColorSelect extends React.PureComponent {
    static propTypes = Select.propTypes;

    render() {
        return (
            <Select
                ValueComponent={ValueComponent}
                ChoiceComponent={ChoiceComponent}
                className="custom-select--color-selector"
                {...this.props}
            />
        );
    }
}
