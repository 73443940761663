import pick from 'lodash/fp/pick';

import { isFeatureEnabled } from '@lumapps/features';
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/stores/base';

import { SpaceServices, SpaceVisibility } from '../types';
import { SpaceState } from './slice';

const selectSpace = ({ space }: BaseStore & { space?: SpaceState }) => space;

/**
 * loading status for the space default data
 * */
export const selectSpaceLoadingStatus = createSelector(
    selectSpace,
    pick(['hasInit', 'isLoading', 'hasError', 'errorStatus']),
);

/**
 * Get data for the current space.
 * */
export const selectSpaceData = createSelector(selectSpace, (spaceState) => spaceState?.data);

/**
 * Get the enabled services for the current space.
 * */
export const selectSpaceServices = createSelector(selectSpaceData, (spaceData) => spaceData?.enabledServices);

/**
 * Get permissions for the current space.
 * */
export const selectSpacePermissions = createSelector(selectSpaceData, (data) => data?.myPermissions);

/**
 * Determine if the current user is member of the current space.
 * */
export const selectIsMemberOfSpace = createSelector(
    selectSpacePermissions,
    (permissions) => !!permissions?.canContribute,
);

/**
 * Determine if the membership request is loading
 */
export const selectIsMembershipRequestLoading = createSelector(
    selectSpace,
    (spaceState) => spaceState?.isMembershipRequestLoading,
);

/**
 * Get current space ID
 * */
export const selectSpaceId = createSelector(selectSpace, (spaceState) => spaceState?.id);

/**
 * Determine if the current user can edit the Space
 * */
export const isSpaceEditableByUser = createSelector(
    isInstanceSuperAdmin,
    selectSpacePermissions,
    (superAdmin, permissions) => superAdmin || !!permissions?.canEdit,
);

/**
 * Determine if the current user can see the Space pages depending of the space visibility
 * */
export const selectIsSpacePagesViewableByUser = createSelector(
    isInstanceSuperAdmin,
    selectSpacePermissions,
    selectIsMemberOfSpace,
    selectSpaceData,
    (superAdmin, userPermissions, isMemberOfSpace, data) => {
        // Pages always viewable by superAdmin and space manager
        if (superAdmin || (userPermissions?.canEdit && userPermissions?.canDelete)) {
            return true;
        }

        // Pages always viewable by member of space
        if (isMemberOfSpace) {
            return true;
        }

        // Pages always viewable on open and read only spaces
        return [SpaceVisibility.open, SpaceVisibility.read_only].includes(data?.visibility as SpaceVisibility);
    },
);

/**
 * Get if the Article service is currently enabled.
 * */
export const isArticleServiceEnabled = createSelector(
    selectSpaceServices,
    (enabledServices) => !!enabledServices?.includes(SpaceServices.article),
);

/** Social Alpha FF */
export const isSocialAlphaFeatureEnabled = isFeatureEnabled('social-alpha');

/** Ideas & Questions in space */
export const isSpaceForumFeatureEnabled = isFeatureEnabled('space-forum');
