import BaseApi from '@lumapps/base-api';

/**
 * Drive token API.
 */
const providerAPI = new BaseApi({
    version: BaseApi.versions.v1,
});

export interface GetDriveTokenParameters {
    /** Key used to cancell API call */
    fetchKey: string;
    /** User identification token */
    token?: string;
}

/**
 * Function that use the identification token to get the access token from Google
 * @param {string} fetchKey Key used to cancell API call
 * @param {string} token User identification token
 * @returns The accessToken needed to open user's Google Drive Picker
 */
export const getDriveToken = async (parameters: GetDriveTokenParameters): Promise<string> => {
    const { fetchKey, token } = parameters;
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const { data } = await providerAPI.get('/drive/getToken', { headers, timeout: 10000 }, true, true, fetchKey);
    return data.accessToken;
};

/** Function to cancel the GET authentification token xhr */
export function cancelGetDriveToken(fetchKey?: string) {
    return providerAPI.cancel('', undefined, fetchKey);
}
