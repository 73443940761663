import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { UserProfile } from '@lumapps/user/types';

import { GetUserProfileParams, GetOrgChartParams, OrganizationChartUserType, UserProfilePermissions } from '../types';

const userProfileApiV2Params = {
    path: 'users',
    version: BaseApi.versions.v2,
};

const userProfileApi = new BaseApi(userProfileApiV2Params);

/**
 * User profile api calling directly the haussman services,
 * without passing through the monolithe.
 * Some endpoints (like organization chart) only have all information when calling
 * directly the haussman services.
 */
const userProfileApiHaussman = new BaseApi({
    ...userProfileApiV2Params,
    bypassMonolith: true,
});

/**
 * UserProfile parameterized APIs
 * - `profile` allows to retrieve a single user profile given the userId
 */
const paths = {
    profile: (userId: string) => `${userId}/profile`,
    organizationChart: (userId: string) => `${userId}/organization-chart`,
};

const getUserProfile = ({ userId, getCacheFirst = true, clearCacheFirst = false }: GetUserProfileParams) => {
    if (clearCacheFirst) {
        userProfileApiHaussman.clearCache(paths.profile(userId), CACHE_TYPE.MEMORY, { includeOrgChart: false });
    }
    if (getCacheFirst) {
        return userProfileApiHaussman.getStaleWhileRevalidate<UserProfile>(paths.profile(userId), CACHE_TYPE.MEMORY, {
            params: { includeOrgChart: false },
        });
    }

    return userProfileApiHaussman.get<UserProfile>(paths.profile(userId), { params: { includeOrgChart: false } });
};

const getOrganizationChart = ({ userId, getCacheFirst = true, clearCacheFirst = false }: GetOrgChartParams) => {
    if (clearCacheFirst) {
        userProfileApiHaussman.clearCache(paths.organizationChart(userId), CACHE_TYPE.MEMORY);
    }

    if (getCacheFirst) {
        return userProfileApiHaussman.getStaleWhileRevalidate(
            paths.organizationChart(userId),
            CACHE_TYPE.MEMORY,
            undefined,
            PRIORITY.LOW,
        );
    }

    return userProfileApiHaussman.get<OrganizationChartUserType>(paths.organizationChart(userId));
};

/**
 * Returns the user permission/role (whether he's global/site/community admin or not)
 * for now, only "isCommunityAdmin" is managed in back-end, other permissions will next be implemented and this desc may evolve indeed
 */
const getUserPermissions = () => {
    return userProfileApi.getCacheFirst<UserProfilePermissions>('/me/is-admin');
};

export { userProfileApi, userProfileApiHaussman, paths, getUserProfile, getOrganizationChart, getUserPermissions };
