import { combineReducers } from '@lumapps/redux';

import * as ProgramMembershipSlice from '../ProgramMembershipForm/slice';
import * as socialNetworksSlice from '../programSocialNetworksForm/slice';
import * as currentStepSlice from './currentStepSlice';
import * as informationFormSlice from './formSlice';

export interface ProgramFormState {
    informationForm: informationFormSlice.ProgramInformationFormState;
    socialNetworksForm: socialNetworksSlice.ProgramSocialNetworksFormState;
    programMembershipForm: ProgramMembershipSlice.ProgramMembershipState;
    currentStep: currentStepSlice.CurrentStepState;
}

const reducer = combineReducers({
    informationForm: informationFormSlice.reducer,
    socialNetworksForm: socialNetworksSlice.reducer,
    programMembershipForm: ProgramMembershipSlice.reducer,
    currentStep: currentStepSlice.reducer,
});

const actions = {
    ...informationFormSlice.actions,
    ...socialNetworksSlice.actions,
    ...currentStepSlice.actions,
};

const initialState: ProgramFormState = {
    informationForm: informationFormSlice.initialState,
    socialNetworksForm: socialNetworksSlice.initialState,
    programMembershipForm: ProgramMembershipSlice.initialState,
    currentStep: currentStepSlice.initialState,
};

export { actions, reducer, initialState };
