export const EDIT_COMMUNITY_DIALOG_EVENT = 'edit-community-dialog';

export const SPACE_CONFIGURATION_SCOPE = 'space-configuration-dialog';
export const COMMUNITY_CONFIGURATION_SCOPE = 'community-configuration-dialog';

/**
 * Create the base scope value of the configuration dialog for space or community
 * @param isSpace
 * @returns scope meant to be used as data-id
 */
export const getBaseScope = (isSpace: boolean) => {
    return `${isSpace ? 'space' : 'community'}-configuration-dialog`;
};
