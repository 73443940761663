import React, { ReactNode } from 'react';

import { FlexBox, FlexBoxProps, Orientation, Size } from '@lumapps/lumx/react';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { AriaLabelType } from '@lumapps/utils/types/AriaLabel';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { DEFAULT_FEED_SENSITIVITY } from '../../constants';
import { FeedInfiniteScrollProvider } from '../../context';
import { useFeed } from '../../hooks/useFeed';

export type FeedWithProvidersProps = AriaLabelType &
    FlexBoxProps & {
        /**
         * The children to display.
         * Should exclusively have Feed.Article as direct descendants OR components using the `useFeedArticle` hook.
         */
        children: ReactNode;
        /**
         * Whether there are more items to be loaded in the feed.
         * If set at true, `onLoadMore` callback will be called when the end of the feed is almost reached.
         */
        hasMore?: boolean;
        /**
         * The current status of the feed.
         * A loading status will set the feed element as "aria-busy=true"
         */
        status: BaseLoadingStatus;
        /**
         * Callback when more items must be fetched.
         * The feed will automatically check the current "status" to not fetch
         * if a loading is already in progress
         */
        onLoadMore?(): void;
        /**
         * The sensitivity to which the infinite scroll will trigger.
         * The number must be between 1 and the length of articles.
         * A higher number means more sensitive as the infinite scroll will trigger
         * when the Xth article from the bottom is reached.
         *
         * Ex: with 20 articles currently displayed, a sensitivity of '4' will trigger
         * the infinite scroll when the 16th article is reached.
         */
        infiniteScrollSensitivity?: number;
    };
/**
 * Base Feed wrapper (ARIA `feed`)
 *
 * @family Feed
 */
export const FeedWithProviders: React.FC<FeedWithProvidersProps> = React.forwardRef<
    HTMLDivElement,
    FeedWithProvidersProps
>(
    (
        {
            infiniteScrollSensitivity = DEFAULT_FEED_SENSITIVITY,
            status,
            onLoadMore,
            hasMore,
            children,
            ...forwardedProps
        },
        ref,
    ) => {
        const { handleMore, feedProps } = useFeed({ status, onLoadMore });
        const { ref: feedRef, ...otherFeedProps } = feedProps;

        return (
            <FeedInfiniteScrollProvider sensitivity={infiniteScrollSensitivity} callback={handleMore} hasMore={hasMore}>
                <FlexBox
                    ref={mergeRefs([feedRef, ref])}
                    orientation={Orientation.vertical}
                    gap={Size.big}
                    {...otherFeedProps}
                    {...forwardedProps}
                >
                    {children}
                </FlexBox>
            </FeedInfiniteScrollProvider>
        );
    },
);

export { FeedWithProviders as Feed };
