import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';

import { TabProvider, Tab, TabList, TabPanel, TabListLayout, Icon, Size } from '@lumapps/lumx/react';

import classNames from 'classnames';

import { translate as t } from 'components/translations';

import { SAShareableContentPreview } from 'components/components/social-advocacy/SAShareableContentPreview';
import { SAContextHelpBar } from 'components/components/social-advocacy/SAContextHelpBar';

import { SOCIAL_PLATFORMS_CONFIG } from 'components/components/social-advocacy/constants';

import { SHAREABLE_CONTENT_TYPES } from '../../ducks/shareableContents/constants';

const SAShareableWorkflowPreview = ({
    form,
    contentType,
    contentPreview,
    currentPreviewTab,
    setPreviewTab,
    socialNetworks,
    contentValidityBySocialNetwork,
}) => {
    const sameMessage = get(form, 'fields.sameMessage.value', true);
    const customizedMessages = get(form, 'fields.customizedMessages.value');
    const commonMessage = get(form, 'fields.commonMessage.value');
    const contentValidityBySocialNetworkList = values(contentValidityBySocialNetwork);

    const previewContent =
        contentType === SHAREABLE_CONTENT_TYPES.images
            ? {
                  images: get(form, 'fields.images.value', []),
              }
            : {
                  description: contentPreview.description,
                  images: [{ url: contentPreview.imageUrl }],
                  title: contentPreview.title,
                  url: contentPreview.url,
              };

    // Only manage first image for now;
    const firstValidSocialIndex = contentValidityBySocialNetworkList.findIndex((isValid) => isValid);
    const currentTabIsValid = contentValidityBySocialNetworkList[currentPreviewTab];

    // Go to first available tab if currentTab is disabled.
    React.useEffect(() => {
        if (!currentTabIsValid) {
            setPreviewTab(firstValidSocialIndex);
        }
    }, [currentTabIsValid, setPreviewTab, firstValidSocialIndex]);

    return (
        !isEmpty(socialNetworks) > 0 && (
            <TabProvider activeTabIndex={currentPreviewTab} onChange={setPreviewTab}>
                <TabList layout={TabListLayout.clustered}>
                    {socialNetworks.map((socialNetwork) => {
                        const socialNetworkConfig = SOCIAL_PLATFORMS_CONFIG[socialNetwork];
                        return (
                            <Tab
                                key={socialNetwork}
                                className={classNames(
                                    'sa-shareable-workflow-preview__tab',
                                    `sa-shareable-workflow-preview__tab--${socialNetwork}`,
                                )}
                                isDisabled={!contentValidityBySocialNetwork[socialNetwork]}
                                label={
                                    <>
                                        <Icon icon={socialNetworkConfig.iconBox} size={Size.s} />
                                        {socialNetwork}
                                    </>
                                }
                            />
                        );
                    })}
                </TabList>

                {socialNetworks.map((socialNetwork) => {
                    const socialNetworkConfig = SOCIAL_PLATFORMS_CONFIG[socialNetwork];
                    return (
                        <TabPanel key={socialNetwork} className="mt+">
                            {socialNetworkConfig?.helperMessage && (
                                <SAContextHelpBar message={t(socialNetworkConfig.helperMessage)} />
                            )}
                            <SAShareableContentPreview
                                socialPlatform={socialNetwork}
                                suggestedMessage={sameMessage ? commonMessage : customizedMessages[socialNetwork]}
                                type={contentType}
                                {...previewContent}
                            />
                        </TabPanel>
                    );
                })}
            </TabProvider>
        )
    );
};

SAShareableWorkflowPreview.defaultProps = {
    socialNetworks: [],
};

export { SAShareableWorkflowPreview };
