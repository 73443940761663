/* istanbul ignore file */
import { GetBackOfficeState, Dispatch } from '@lumapps/redux/types';

import * as api from '../../api';
import { makeContentActionsByContentIdSelector } from './selectors';
import { actions, ContentActionStatus } from './slice';

export const fetchContentActions = (contentId: string) => async (dispatch: Dispatch, getState: GetBackOfficeState) => {
    try {
        const state = getState();
        const contentActionsSelector = makeContentActionsByContentIdSelector(state);
        const contentActions = contentActionsSelector(contentId);

        // If the current content is already loading, return early to avoid double call.
        if (contentActions.status === ContentActionStatus.loading) {
            return;
        }

        dispatch(actions.fetchContentActions({ contentId }));

        const { data } = await api.fetchContentActions(contentId);

        dispatch(actions.fetchContentActionsSuccess({ contentId, contentActions: data }));
    } catch (error) {
        dispatch(actions.fetchContentActionsFailure({ contentId }));
    }
};
