import React, { PureComponent } from 'react';

import { listJoined } from 'components/services/channel';

import { SOURCE_TYPES } from 'front/modules/content/modules/widget/modules/widget-channel-list/js/widget-channel-list_constants';

import { isConnected, isExternal, isMicrosoft } from 'components/services/user';

import { widgetChannelListType } from './types';

import { ChannelBlock } from './ChannelBlock';
import { Loader } from '../../ui/loader';

/**
 * Displays the widget channel list.
 */
export class WidgetChannelList extends PureComponent {
    static propTypes = widgetChannelListType;

    /**
     * Defines this widget as editable.
     *
     * @return {boolean} True.
     */
    static isEditable() {
        return true;
    }

    /**
     * Determines whether the widget is hidden or not.
     *
     * @param  {Object}  params The widget props.
     * @return {boolean} Whether the widget is hidden or not.
     */
    static isWidgetHidden(params) {
        return !isConnected() || isExternal() || !isMicrosoft() || WidgetChannelList.isWidgetEmpty(params);
    }

    /**
     * Defines whether the widget is empty onr not.
     *
     * @param  {Array}   value The current list of channel.
     * @return {boolean} Whether the list of channel is empty or not.
     */
    static isWidgetEmpty({ component }) {
        const { properties } = component;
        const { selectedChannels, sourceType } = properties;

        return sourceType === SOURCE_TYPES.CUSTOM && selectedChannels.length === 0;
    }

    constructor(props) {
        super(props);

        this.state = {
            channels: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        if (isConnected() && !isExternal() && isMicrosoft()) {
            this.initChannels();
        }
    }

    /**
     * Init channels list to display.
     */
    initChannels() {
        const { value, properties } = this.props;
        const { limitConversation, numberConversations, sourceType } = properties;

        if (sourceType === SOURCE_TYPES.CUSTOM) {
            this.setState({ channels: value });

            return;
        }

        const maxResults = limitConversation ? numberConversations : -1;

        this.setState({ isLoading: true });
        listJoined({ maxResults, provider: 'teams' }).then(({ items: channels }) => {
            this.setState({ channels, isLoading: false });
        });
    }

    render() {
        const { properties } = this.props;
        const { channels, isLoading } = this.state;

        return (
            <div className="widget-channel-list__list">
                {channels &&
                    channels.map((channel) => (
                        <ChannelBlock key={channel.id} channel={channel} properties={properties} />
                    ))}

                {isLoading && <Loader isLoading={isLoading} />}
            </div>
        );
    }
}
