import { JOURNEY_VIEWS, type JourneyPageParams, journeyPageRoute, journeysBaseRoute } from '@lumapps/journeys/routes';
import type { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const journeysGlobalSettingsRoute: Route = {
    ...journeysBaseRoute,
    path: `${journeysBaseRoute.path}/settings`,
    legacyId: `${journeysBaseRoute.legacyId}-settings`,
};

export const journeysListRoute: Route = {
    ...journeysBaseRoute,
    path: `${journeysBaseRoute.path}/list`,
    legacyId: `${journeysBaseRoute.legacyId}-list`,
};

export interface JourneyListParams {
    title?: string;
}

export const journeyList = (query?: JourneyListParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    return {
        ...journeysListRoute,
        query: queryParams,
    };
};

export const journeysCreateRoute: Route = {
    path: `${journeysBaseRoute.path}/create`,
    appId: AppId.backOffice,
};

export const journeyViewRoute: Route = {
    ...journeyPageRoute,
    params: {
        view: JOURNEY_VIEWS.VIEW,
    },
};

export const journeyView = (params: Omit<JourneyPageParams, 'view'>): Route => {
    return {
        ...journeyViewRoute,
        params: {
            ...journeyViewRoute.params,
            id: params.id,
        },
    };
};

export const journeyEditRoute: Route = {
    ...journeyPageRoute,
    params: {
        view: JOURNEY_VIEWS.EDIT,
    },
};

export const journeyEdit = (params: Omit<JourneyPageParams, 'view'>): Route => {
    return {
        ...journeyEditRoute,
        params: {
            ...journeyEditRoute.params,
            id: params.id,
        },
    };
};
