/* eslint-disable no-bitwise */

/**
 * Decode the Open Street Map shorten url to get the coordinates.
 *
 * @param  {string} mapShortLink  The map URL shorten url like https://osm.org/go/0EEQjE==
 * @return {Coordinates} The decoded coordinates.
 */
export const decodeOpenStreetMapUrl = (mapShortLink: string) => {
    const openMapPlaceCoords = mapShortLink.match('(.*[go]/)([0-9A-Za-z.~]+)');

    if (!openMapPlaceCoords) {
        return null;
    }
    const quadtileAddress = openMapPlaceCoords[2];
    const charArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_~';
    let i = 0;
    let x = 0;
    let y = 0;

    for (i = 0; i < quadtileAddress.length; i++) {
        const ch = quadtileAddress.charAt(i);
        const digit = charArray.indexOf(ch);

        if (digit === -1) {
            break;
        }

        // Distribute 6 bits into x and y
        x <<= 3;
        y <<= 3;

        for (let j = 2; j >= 0; j--) {
            x |= (digit & (1 << (j + j + 1))) === 0 ? 0 : 1 << j;
            y |= (digit & (1 << (j + j))) === 0 ? 0 : 1 << j;
        }
    }

    x = x * 2 ** (2 - 3 * i) * 90 - 180;
    y = y * 2 ** (2 - 3 * i) * 45 - 90;

    return { lat: x, lon: y };
};
