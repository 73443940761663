/**
 * Generate a unique identifier.
 * We can add optional prefixes and suffixes (for instance to distinguish temporary Ids from actual Ids)
 */
export function generateUUID(prefix = '', suffix = '') {
    /* eslint-disable no-bitwise, no-magic-numbers */
    let time = new Date().getTime();

    return `${prefix}${'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
        const random = (time + Math.random() * 16) % 16 | 0;
        time = Math.floor(time / 16);

        return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16);
    })}${suffix}`;
    /* eslint-enable no-bitwise, no-magic-numbers */
}
