import React from 'react';

import { useContent } from '@lumapps/contents/hooks/useContent';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { getDefaultInstanceLanguage } from '@lumapps/instance/ducks/selectors';
import { useInstance } from '@lumapps/instance/hooks/useInstance';
import { currentLanguageSelector } from '@lumapps/languages';
import { NavigationSkeleton } from '@lumapps/lumx-navigation/components/NavigationSkeleton';
import { Navigation } from '@lumapps/navigation/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { toCompatibleLanguage } from '@lumapps/translations';
import { FetchCallbackParams } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { useSubNavigation } from '../../hooks/useSubNavigation';
import { SubNavigation } from '../SubNavigation';

const CLIENT_COMPUTED_SUB_NAVIGATION_NAME = 'ClientComputedSubNavigation';

/** Specific properties of the Sub navigation widget */
interface ClientComputedSubNavigationProperties {
    // Each lang has its own set of properties
    [lang: string]: {
        // For the V2 widget, we only take into account the default properties
        __default: {
            showHidden: boolean;
            showParent: boolean;
            listCurrentSub: 'list' | 'pick';
            // The list of selected nav for each lang (redundant with the lang above but that's how the backend returns it)
            navSelected: {
                [lang: string]: {
                    /**
                     * This id is either:
                     * - the current content id (= "list current content children" mode), or
                     * - a specific node id (= "Display navigation item children" mode)
                     */
                    uuid: string;
                };
            };
        };
    };
}

interface ClientComputedSubNavigationProps extends ClientComputedProps {
    properties?: ClientComputedSubNavigationProperties;
}

/**
 * Get the correct widget param lang to use and associated widget property
 * The fallback language order follow the widget v1's one.
 * V2 widget only takes into account properties coming from the "Default" setting of the current or fallback language.
 */
const getLangAndWidgetProperty = ({
    currentLanguage,
    alternativeLang,
    properties,
}: {
    currentLanguage: string;
    alternativeLang: string;
    properties?: ClientComputedSubNavigationProperties;
}) => {
    // First, try to get the property from currentLanguage
    let widgetParamLang = currentLanguage;

    // If no property is found, try to get from instance's default lang
    if (!properties?.[widgetParamLang]) {
        widgetParamLang = alternativeLang;

        // If still no property is found, try to get from 'en' as last fallback
        if (!properties?.[widgetParamLang]) {
            widgetParamLang = 'en';
        }
    }

    return {
        widgetParamLang,
        // eslint-disable-next-line dot-notation
        currentWidgetProperty: properties?.[widgetParamLang]?.['__default'],
    };
};

/**
 * Client Computed Sub Navigation Widget
 * @param ClientComputedSubNavigationProps
 * @returns ClientComputedSubNavigation
 */
export const ClientComputedSubNavigation: React.FC<ClientComputedSubNavigationProps> = ({
    properties,
    uuid,
    theme,
}) => {
    const customer = useSelector(customerIdSelector);
    const { id: instance } = useInstance();

    // langs must be transformed into v1 format (when applicable e.g. pt-BR => pt_br) to be used with the widget properties
    const currentLanguage = toCompatibleLanguage(useSelector(currentLanguageSelector));
    const alternativeLang = toCompatibleLanguage(useSelector(getDefaultInstanceLanguage));

    const { currentContentId, currentContentSlug, legacyUrl, isLegacyContentPage } = useContent();

    const dispatch = useDispatch();

    const { widgetParamLang, currentWidgetProperty } = getLangAndWidgetProperty({
        currentLanguage,
        alternativeLang,
        properties,
    });

    // Is the Widget set as "List current content children" or 'Display navigation item children'
    const listCurrentSub = currentWidgetProperty?.listCurrentSub;
    // Whether the search and children should include nodes with `isHidden` or `areChildrenHidden` set to true
    const showHiddenNodes = currentWidgetProperty?.showHidden;
    // Whether the widget should display the current content as the parent
    const showParent = Boolean(currentWidgetProperty?.showParent);

    // The selected Nav. It is either the current content id or a node id, depending on widget setting.
    // The fallback order follows the same as widget v1.
    const selectedNav =
        currentWidgetProperty?.navSelected[widgetParamLang]?.uuid ||
        currentWidgetProperty?.navSelected[alternativeLang]?.uuid ||
        currentWidgetProperty?.navSelected.en?.uuid;

    /**
     * Full path to the content, if given. If the URL has a legacy format, we need to use an alternative URL in order
     * to make the routing work.
     */
    const url = isLegacyContentPage ? legacyUrl : currentContentSlug;

    const fetchCallback = React.useCallback(
        ({ success, response }: FetchCallbackParams<Navigation>) => {
            // if an error occurred or if widget is empty, hide the widget
            if (!success || !response?.children[0]?.children) {
                dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
            }
        },
        [dispatch, uuid],
    );

    const { subNavigation, status, defaultOpenNodes } = useSubNavigation({
        uuid,
        url,
        lang: currentLanguage,
        alternativeLang,
        customer,
        instance,
        listCurrentSub,
        pageId: currentContentId,
        selectedNav,
        showHiddenNodes,
        fetchCallback,
    });

    if (status === BaseLoadingStatus.loading) {
        return <NavigationSkeleton theme={theme} hideIcon />;
    }

    if (status === BaseLoadingStatus.idle && subNavigation) {
        return (
            <SubNavigation
                theme={theme}
                subNavigation={subNavigation}
                showParent={showParent}
                currentContentId={currentContentId}
                defaultOpenNodes={defaultOpenNodes}
            />
        );
    }

    return null;
};

ClientComputedSubNavigation.displayName = CLIENT_COMPUTED_SUB_NAVIGATION_NAME;
