import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { onKey } from '@lumapps/utils/event/onKey';
import { and } from '@lumapps/utils/function/predicate/and';
import { or } from '@lumapps/utils/function/predicate/or';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { Transforms } from '@lumapps/wrex/slate';
import { isEnteringInline } from '@lumapps/wrex/slate/utils/isEnteringInline';
import { isLeavingInline } from '@lumapps/wrex/slate/utils/isLeavingInline';
import { isSelectionCollapsed } from '@lumapps/wrex/slate/utils/isSelectionCollapsed';
import { WrexEditor } from '@lumapps/wrex/types';

import { LINK } from '../../constants';
import { LinkEditor } from '../../types';
import { isLink } from '../../utils/isLink';

/**
 * Initialize key event handler for the link editor.
 * *
 * @param editor          The editor
 */
export const getKeyHandler = (editor: WrexEditor<LinkEditor>) =>
    onKey(
        [
            // When entering or leaving a link, we want to move of one offset instead of a character (default unit).
            // It allows the user to place the cursor just out of the link or just at the beginning/end of it.
            {
                match: and(
                    isHotKey('ArrowRight'),
                    () => isSelectionCollapsed(editor),
                    or(isEnteringInline(editor, isLink), isLeavingInline(editor, isLink)),
                ),
                handler: () => {
                    Transforms.move(editor, { unit: 'offset' });
                },
            },
            // When entering or leaving a link, we want to move of one offset instead of a character (default unit).
            // It allows the user to place the cursor just out of the link or just at the beginning/end of it.
            {
                match: and(
                    isHotKey('ArrowLeft'),
                    () => isSelectionCollapsed(editor),
                    or(isEnteringInline(editor, isLink), isLeavingInline(editor, isLink)),
                ),
                handler: () => {
                    Transforms.move(editor, { unit: 'offset', reverse: true });
                },
            },
            // Shortcut to toggle link
            {
                match: and(isHotKey(PLUGIN_SHORTCUTS[LINK].hotkey), () => editor.isLinkAllowed()),
                handler: () => {
                    editor.wrapLink(editor, '');
                },
            },
        ],
        { preventDefault: true },
    );
