import React from 'react';

import { useSelector, useDispatch } from '@lumapps/redux/react';

import { routerDataSelector } from '../ducks/selectors';
import { actions as routerActions } from '../ducks/slice';
import { RouterData, ROUTER_DATA_NAMESPACES } from '../ducks/types';

/**
 * Retrieves the data for a given namespace from the store. To use you need to first:
 * 1. execute a redirect(route(), { id: ROUTER_DATA_NAMESPACES.ID, data: { value: '1' } });
 * 2. you can then retrieve the data using const data = useRouterData(ROUTER_DATA_NAMESPACES.ID);
 *
 * Be careful to:
 * - Not abuse this mechanism since adding more and more data to the reducer makes our application grow
 * in memory
 * - This will not work if the page gets refreshed or in Angular JS context. It will only work between
 * 2 full react pages.
 * @param id - namespace to be used for retrieving data from the router store. Should be the same id
 * as the one passed in the redirect function
 */
const useRouterData = <T = any>(id: ROUTER_DATA_NAMESPACES): T => {
    const routerData = useSelector(routerDataSelector);

    return routerData ? routerData[id] : undefined;
};

/**
 * Saves data in the router so that it can be used for navigation purposes.
 * Be careful to:
 * - Not abuse this mechanism since adding more and more data to the reducer makes our application grow
 * in memory
 * - This will not work if the page gets refreshed or in Angular JS context. It will only work between
 * 2 full react pages.
 * @returns function to dispatch the data
 */
const useDispatchRouterData = (options?: { routerData?: RouterData; shouldDispatch?: boolean }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (options && options.routerData && options.shouldDispatch) {
            dispatch(routerActions.setData(options.routerData));
        }
    }, [dispatch, options]);

    return {
        dispatch: (data: RouterData) => dispatch(routerActions.setData(data)),
    };
};

export { useRouterData, useDispatchRouterData };
