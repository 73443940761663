import noop from 'lodash/noop';
import { getFunctionalOverride, Targets } from '@lumapps/customizations/api';

/**
 * HEAD'S UP. THIS IS A QUICK HACK IN ORDER TO LOAD IFRAMES IN A LAZILY WAY
 * BY NO MEANS SHOULD THIS CODE BE MIGRATED AS IS TO THE NEW REACT APPLICATION AS IS.
 * MIGRATE THIS PROPERLY OR SUFFER THE CONSEQUENCES.
 */
const LAZY_LOADABLE_CLASS = 'lazy-loadable';
const LAZY_LOADED_CLASS = 'lazy-loaded';

const widgetIframeFunctionalOverride = getFunctionalOverride(Targets.WIDGET_IFRAME);

const iframeOnLoad = widgetIframeFunctionalOverride ? widgetIframeFunctionalOverride.onload : noop;


/**
 * From a given target, it takes the `data-src` attribute and changes it to a `src`.
 * This will trigger the download of the iframe content.
 */
const loadTarget = (target) => {
    if (target.hasAttribute('data-lazy')) {
      const datasrc = target.getAttribute('data-lazy');
      const currentSrc = target.getAttribute('src');

      target.onload = () => iframeOnLoad(target);

      if (currentSrc !== datasrc) {
          target.setAttribute('src', datasrc);
      }
    }

    target.classList.remove(LAZY_LOADABLE_CLASS);
    target.classList.add(LAZY_LOADED_CLASS);
}

/** Retrieves the list of lazy loadable targets in the page */
const getLazyLoadableTargets = () => [].slice.call(document.querySelectorAll(`.${LAZY_LOADABLE_CLASS}`));

const getLazyLoadedTargets = () => [].slice.call(document.querySelectorAll(`.${LAZY_LOADED_CLASS}`));

/**
 * Creates an intersection observer and stores it globally. When we have a callback from the IntersectionObserver
 * we check that the entry is intersecting with the viewport, and if it is, we load that target and remove it
 * from observable list. If the IntersectionObserver API is not available, we go ahead and load all targets.
 */
const init = () => {
    if ('IntersectionObserver' in window) {
      const lazyTargetObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            const lazyTarget = entry.target;
            loadTarget(lazyTarget);
            lazyTargetObserver.unobserve(lazyTarget);
          }
        });
      }, { rootMargin: '100px', threshold: 0.10 });

      window.lazyTargetObserver = lazyTargetObserver;
    } else {
      const lazyTargets = getLazyLoadableTargets();

      lazyTargets.forEach(function(lazyTarget) {
          loadTarget(lazyTarget);
      });
    }
}

const syncLazyAttr = function (mutationsList, observer) {
    // Use traditional 'for loops' for IE 11
    for(let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-lazy' ) {
            mutation.target.setAttribute('src', mutation.target.getAttribute('data-lazy'));
        }
    }
};

/**
 * Function that retrieves the current lazy loadable targets in the DOM at a given moment
 * and adds them to the observable list.
 */
const observeTargets = (forceLoad = false) => {
    if (forceLoad) {
        if (!window.mutationObserver) {
            window.mutationObserver = new MutationObserver(syncLazyAttr);
        }

        const lazyTargets = getLazyLoadedTargets();

        lazyTargets.forEach(function(lazyTarget) {
            loadTarget(lazyTarget);
            window.mutationObserver.observe(lazyTarget, { attributes: true });
        });

        return;
    }

    if (!window.lazyTargetObserver) {
      init();
    }

    const lazyTargets = getLazyLoadableTargets();

    if (lazyTargets && lazyTargets.length > 0) {
      lazyTargets.forEach(function(lazyTarget) {
        window.lazyTargetObserver.observe(lazyTarget);
      });
    } else if (lazyTargets && lazyTargets.length === 0) {
      setTimeout(() => {
        observeTargets(forceLoad);
      }, 200);
    }
}

export { observeTargets, LAZY_LOADABLE_CLASS, LAZY_LOADED_CLASS };
