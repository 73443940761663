import { or } from '@lumapps/utils/function/predicate/or';
import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchNode } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { TABLE, T_D, T_R } from '../../../constants';
import { createTable } from '../../../utils/createTable';
import { createTD } from '../../../utils/createTD';
import { createTR } from '../../../utils/createTR';

type ElementTypes = typeof TABLE | typeof T_R | typeof T_D;

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    table: {
        test: matchNode({ tagName: 'table' }),

        type: TABLE,
        transform: (node, children) => createTable({}, children),
    },
    tr: {
        test: matchNode({ tagName: 'tr' }),
        type: T_R,
        transform: (node, children) => createTR(children),
    },
    td: {
        test: or(
            matchNode({ tagName: 'td' }),
            // <th> are not supported for now, so we have to convert it into a td
            matchNode({ tagName: 'th' }),
        ),
        type: T_D,
        transform: (node, children) => createTD(children),
    },
};
