export enum WREX_LIST {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=64588132
     */
    ORDERED_LIST_BUTTON = 'FRONT.TEXT_EDITOR.FORMAT.ORDERED_LIST_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=472252555
     */
    ORDERED_LIST_WITH_SHORTCUT = 'WREX_LIST.ORDERED_LIST_WITH_SHORTCUT',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40496612
     */
    UNORDERED_LIST_BUTTON = 'FRONT.TEXT_EDITOR.FORMAT.LIST_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470893978
     */
    UNORDERED_LIST_WITH_SHORTCUT = 'WREX_LIST.UNORDERED_LIST_WITH_SHORTCUT',
}
