export enum WIDGET_CONTRIBUTION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=224877754
     */
    EDITOR_PLACEHOLDER = 'WIDGET_CONTRIBUTION.EDITOR_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=224896814
     */
    SETTINGS_DOUBLE_CLICK_INFO = 'WIDGET_CONTRIBUTION.SETTINGS_DOUBLE_CLICK_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228627963
     */
    EMPTY_WIDGET = 'WIDGET_CONTRIBUTION.EMPTY_WIDGET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=228667349
     */
    EMPTY_WIDGET_HELPER = 'WIDGET_CONTRIBUTION.EMPTY_WIDGET_HELPER',
}
