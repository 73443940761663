import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';

import { DEFAULT_MAX_RECOMMENDATIONS } from '../constants';
import { FetchRecommendationsParams, FetchRecommendationsResponse } from '../types';

export const recommendationsApi = new BaseApi({ version: BaseApi.versions.v2, path: 'search' });

export const fetchRecommendations = ({
    maxResults = DEFAULT_MAX_RECOMMENDATIONS,
    lang,
}: FetchRecommendationsParams) => {
    return recommendationsApi.getCacheFirst<FetchRecommendationsResponse>(
        'recommendations',
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            params: {
                maxResults,
                lang,
            },
        },
    );
};
