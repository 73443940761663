import { NOTIFICATION_CONTAINER_GUTTER, NOTIFICATION_START_OFFSET } from '../../constants';
import type { State } from '../../ducks/types';

/** Calculate height offset of a notification within the list (required for animation) */
export function calculateOffset(index: number, notifications: State['notifications']) {
    if (index === 0 && notifications[index]?.visible === undefined) {
        // Before showing, we place it at a negative offset so that produces a slide-in effect when `visible === true`.
        return NOTIFICATION_START_OFFSET;
    }

    let offset = 0;

    for (let i = notifications.length - 1; i >= 0; i--) {
        if (i === index) {
            break;
        }
        const { visible, height = 0 } = notifications[i];
        if (visible) {
            // Cumulate offset with gutter + height of all visible notifications before
            offset += NOTIFICATION_CONTAINER_GUTTER + height;
        }
    }
    return offset;
}
