import get from 'lodash/get';
import includes from 'lodash/includes';
import loUnescape from 'lodash/unescape';

/////////////////////////////

function RssService($q, RssFactory) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * A map for rss feeds promises.
     *
     * @type {Object}
     */
    const _rssFeedPromises = {};

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Build a simplified entry object by picking out a few selected fields from the fullEntry.
     *
     * @param  {Object} fullEntry The full entry object parsed from the response of the call to the RSS url.
     * @return {Object} A simplified entry object.
     */
    function _buildEntry(fullEntry) {
        if (angular.isUndefinedOrEmpty(fullEntry)) {
            return undefined;
        }

        const entry = {
            summary: loUnescape(fullEntry.summary) || loUnescape(fullEntry.description),
            title: loUnescape(fullEntry.title),
        };

        if (angular.isDefinedAndFilled(fullEntry.link)) {
            entry.link = fullEntry.link;
        }

        if (angular.isDefinedAndFilled(fullEntry.published_parsed)) {
            const parsedDate = fullEntry.published_parsed;
            entry.date = Date.UTC(
                parsedDate[0],
                parsedDate[1] - 1,
                parsedDate[2],
                parsedDate[3],
                parsedDate[4],
                parsedDate[5],
            );
        }

        angular.forEach(fullEntry.links, (link) => {
            if (link.rel === 'enclosure' && angular.isDefinedAndFilled(link.type) && includes(link.type, 'image')) {
                entry.image = link.href;
            }
        });

        return entry;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get a given number of items from an external url RSS feed.
     *
     * @param  {string}  url          The url of the feed to retrieve.
     * @param  {number}  [maxItems=5] The maximum number of items to get from the feed.
     * @return {Promise} The request promise.
     */
    function getFeed(url, maxItems = 5) {
        if (angular.isUndefinedOrEmpty(url)) {
            return $q.reject();
        }

        if (get(_rssFeedPromises, [url, maxItems])) {
            return _rssFeedPromises[url][maxItems];
        }

        _rssFeedPromises[url] = _rssFeedPromises[url] || {};
        _rssFeedPromises[url][maxItems] = $q((resolve) => {
            RssFactory.getRssContent(
                {
                    url,
                },
                (response) => {
                    let entries = [];

                    if (angular.isDefinedAndFilled(response.entries)) {
                        entries = angular.fromJson(response.entries).slice(0, maxItems);
                    }

                    const items = entries.map(_buildEntry);

                    resolve(items);
                },
            );
        });

        return _rssFeedPromises[url][maxItems];
    }

    /////////////////////////////

    service.getFeed = getFeed;
}

/////////////////////////////

angular.module('Services').service('Rss', RssService);

/////////////////////////////

export { RssService };
