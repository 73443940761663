import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';
import { createUrl } from '@lumapps/router/utils';
import { socialProfile } from '@lumapps/user-profile/routes';

import { getPictureUrl, hasUserProfile, isConnected } from '../../ducks/selectors';
import { UserProfileDropdown } from './UserProfileDropdown';

const mapStateToProps = (state: BaseStore) => {
    const isSocialEnabled = hasUserProfile(state);
    const avatarImageSrc = getPictureUrl(state);
    const userProfileUrl = isSocialEnabled ? createUrl(socialProfile()) : undefined;
    const isUserConnected = isConnected(state);

    return {
        avatarImageSrc,
        userProfileUrl,
        isConnected: isUserConnected,
    };
};

const ConnectedUserProfileDropdown = connect(mapStateToProps)(UserProfileDropdown);

export { ConnectedUserProfileDropdown as UserProfileDropdown };
