import differenceWith from 'lodash/differenceWith';
import first from 'lodash/first';

import { Editor, Path, ReactEditor, Transforms } from '@lumapps/wrex/slate';

import type { TableEditor, TDElement } from '../types';
import { getAllCellsPath } from './getAllCellsPath';
import { isTableCell } from './isTableCell';

export const updateCellSelection = (editor: ReactEditor & TableEditor, tablePath: Path) => {
    const { selection } = editor;
    if (!selection) {
        return;
    }

    // Get selection edges
    const [startPoint, endPoint] = Editor.edges(editor, selection);
    // Get the cell at selection start
    const startCellEntry = first(
        Array.from(
            Editor.nodes(editor, {
                at: startPoint,
                match: isTableCell,
            }),
        ),
    );

    // Get the cell at selection end
    const endCellEntry = first(
        Array.from(
            Editor.nodes(editor, {
                at: endPoint,
                match: isTableCell,
            }),
        ),
    );

    const startCellParentTable = startCellEntry && startCellEntry[1].slice(0, -2);
    const endCellParentTable = endCellEntry && endCellEntry[1].slice(0, -2);
    const isSelectionInOneTable =
        startCellParentTable && endCellParentTable && Path.compare(startCellParentTable, endCellParentTable) === 0;

    const isSelectionInOneCell =
        startCellEntry && endCellEntry && Path.compare(startCellEntry[1], endCellEntry[1]) === 0;

    if (isSelectionInOneTable && !isSelectionInOneCell && startCellEntry && endCellEntry) {
        // If start and end of selection are not in the same cell
        // We need to set the cellSelection prop which will have priority over the slate selection.
        // Get the start and end cells coordinates ([row, column])
        const startCellCoordinates = startCellEntry[1].slice(-2);
        const endCellCoordinates = endCellEntry[1].slice(-2);

        // Get the first row of the selection
        const startRow = Math.min(startCellCoordinates[0], endCellCoordinates[0]);
        // Get the last row of the selection
        const endRow = Math.max(startCellCoordinates[0], endCellCoordinates[0]);
        // Get the first column of the selection
        const startCol = Math.min(startCellCoordinates[1], endCellCoordinates[1]);
        // Get the last column of the selection
        const endCol = Math.max(startCellCoordinates[1], endCellCoordinates[1]);

        let newSelectedCells: Path[] = [];
        // Fill the newSelectedCells array with the path of the cells that are between the start and end cells
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
            for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
                const cellPath = [...tablePath, rowIndex, colIndex];

                newSelectedCells = [...newSelectedCells, cellPath];
            }
        }

        // Set isInCellSelection to true for all the cells in the new selection
        newSelectedCells.forEach((cellPath) => {
            Transforms.setNodes(
                editor,
                {
                    isInCellSelection: true,
                } as Partial<TDElement>,
                {
                    at: cellPath,
                },
            );
        });

        const allCellsFromTable = getAllCellsPath(editor, tablePath);
        // Get the diff between previous and new selection, to unset the cells that are not in the selection
        // anymore.
        const cellsToUnselect = differenceWith(
            allCellsFromTable,
            newSelectedCells,
            (prevPath, newPath) => Path.compare(prevPath, newPath) === 0,
        );

        // Set isInCellSelection to false for all the cells that are not in the selection anymore
        cellsToUnselect.forEach((cellPath) => {
            try {
                if (Editor.node(editor, cellPath)) {
                    Transforms.setNodes(
                        editor,
                        {
                            isInCellSelection: false,
                        } as Partial<TDElement>,
                        {
                            at: cellPath,
                        },
                    );
                }
            } catch (error) {
                //
            }
        });

        // Update the cellSelection with the new selection
        editor.setCellSelection(newSelectedCells);
        editor.setCurrentlySelectedTable(tablePath);
    } else {
        // If start and end of selection not in the same cell (single cell selection)
        // we need to reset the cell selection and let Slate handle the text selection
        const allCellsFromTable = getAllCellsPath(editor, tablePath);

        // Reset the selected state of all cells previously in the selection.
        allCellsFromTable.forEach((cellPath) => {
            try {
                if (Editor.node(editor, cellPath)) {
                    Transforms.setNodes(
                        editor,
                        {
                            isInCellSelection: false,
                        } as Partial<TDElement>,
                        {
                            at: cellPath,
                        },
                    );
                }
            } catch (error) {
                //
            }
        });

        // Empty the cell selection
        editor.setCellSelection([]);
        editor.setCurrentlySelectedTable([]);
    }
};
