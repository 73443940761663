import type { ElementRender, Wrex } from '../../types';
import { isElement } from './isElement';

/**
 * Check whether a node is of a specific type.
 */
export const isElementType =
    <E extends Wrex.Element>(type: string | ElementRender<E, any>) =>
    (node: any): node is E => {
        if (!isElement(node)) {
            return false;
        }
        if (typeof type === 'string') {
            return node.type === type;
        }
        return node.type === type.displayName;
    };
