/* istanbul ignore file */
import { getAllFeed } from '@lumapps/groups/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { ContentNotificationAlertDialog } from './ContentNotificationAlertDialog';

const mapStateToProps = (state: BackOfficeStore) => {
    const allFeed = getAllFeed(state);

    return {
        allFeedId: allFeed?.id,
    };
};

const mapDispatchToProps = {};

const ConnectedContentNotificationAlertDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContentNotificationAlertDialog);

export { ConnectedContentNotificationAlertDialog as ContentNotificationAlertDialog };
