import { ATTACHMENTS_CONVERT } from '@lumapps/wrex-attachments/plugin/dita/deserialize';
import { EMBEDDED_MAP_CONVERT } from '@lumapps/wrex-embedded-map/plugin/dita/deserialize';
import { EMBEDDED_VIDEO_CONVERT } from '@lumapps/wrex-embedded-video/plugin/dita/deserialize';
import {
    ALIGNED_IMAGE_WRAPPER_CONVERT,
    IMAGE_GROUP_CONVERT,
} from '@lumapps/wrex-enhanced-image/plugin/dita/deserialize';
import { FILE_CONVERT } from '@lumapps/wrex-file/plugin/dita/deserialize';
import { LINK_PREVIEW_CONVERT } from '@lumapps/wrex-link-preview/plugin/dita/deserialize';
import { LINK_CONVERT } from '@lumapps/wrex-link/plugin/dita/deserialize';
import {
    LIST_ITEM_CONVERT,
    UNORDERED_LIST_CONVERT,
    ORDERED_LIST_CONVERT,
} from '@lumapps/wrex-list/plugin/dita/deserialize';
import { PLAY_VIDEO_CONVERT } from '@lumapps/wrex-play-video/plugin/dita/deserialize';
import { QUOTE_CONVERT } from '@lumapps/wrex-quote/plugin/dita//deserialize';
import { SIMPLETTABLE_CONVERT, STENTRY_CONVERT, STROW_CONVERT } from '@lumapps/wrex-table/plugin/dita/deserialize';
import {
    PARAGRAPH_CONVERT,
    BOLD_CONVERT,
    HEADLINE_CONVERT,
    ITALIC_CONVERT,
    TITLE_CONVERT,
    UNDERLINE_CONVERT,
    CODE_BLOCK_CONVERT,
    CODE_CONVERT,
    COLORED_TEXT_CONVERT,
    SUBTITLE_CONVERT,
    STRIKETHROUGH_CONVERT,
} from '@lumapps/wrex-typography/plugin/dita/deserialize';
import { MENTION_CONVERT } from '@lumapps/wrex-user-mention/plugin/dita/deserialize';

import { DitaConvertOptions } from './types';

export const OPTIONS: DitaConvertOptions = {
    elements: [
        PARAGRAPH_CONVERT,
        HEADLINE_CONVERT,
        SUBTITLE_CONVERT,
        TITLE_CONVERT,
        LIST_ITEM_CONVERT,
        UNORDERED_LIST_CONVERT,
        ORDERED_LIST_CONVERT,
        IMAGE_GROUP_CONVERT,
        ALIGNED_IMAGE_WRAPPER_CONVERT,
        LINK_CONVERT,
        MENTION_CONVERT,
        FILE_CONVERT,
        LINK_PREVIEW_CONVERT,
        CODE_BLOCK_CONVERT,
        CODE_CONVERT,
        QUOTE_CONVERT,
        SIMPLETTABLE_CONVERT,
        STROW_CONVERT,
        STENTRY_CONVERT,
        EMBEDDED_VIDEO_CONVERT,
        EMBEDDED_MAP_CONVERT,
        PLAY_VIDEO_CONVERT,
        ATTACHMENTS_CONVERT,
    ],
    marks: [BOLD_CONVERT, ITALIC_CONVERT, UNDERLINE_CONVERT, COLORED_TEXT_CONVERT, STRIKETHROUGH_CONVERT],
};
