import { MutableRefObject, useCallback } from 'react';

import isElement from 'lodash/isElement';

import { focusAfter } from '@lumapps/utils/elements/focus/focusAfter';
import { focusPrevious } from '@lumapps/utils/elements/focus/focusPrevious';
import { useEventListener } from '@lumapps/utils/hooks/useEventListener';
import { useFocusWithin } from '@lumapps/utils/hooks/useFocusWithin';

/** Check whether the given even target is an element. */
const targetIsElement = (target: EventTarget): target is Element => isElement(target);

interface ManageFeedKeyboardEventsOptions {
    /** Callback triggered the user uses a shortcut that moves the focus out of the feed */
    onFocusOut: () => void;
}

/**
 * Keyboard events for the `Feed` pattern.
 * Based on the Wai ARIA guide https://www.w3.org/WAI/ARIA/apg/patterns/feed/examples/feed/
 *
 * @family Feed
 */
const manageFeedKeyboardEvents = (
    /** The element that contains the whole feed. */
    feedElement: HTMLDivElement,
    /** The keyboard event triggered */
    event: KeyboardEvent,
    /** Options to hook in events  */
    options: ManageFeedKeyboardEventsOptions,
) => {
    const { key, target } = event;

    /**
     * Keys to listen to.
     * If the event triggered doesn't concern one of these keys, nothing will be done.
     */
    const managedKeys = ['PageDown', 'PageUp', 'Home', 'End'];

    if (!feedElement || !key || !target || !targetIsElement(target) || !managedKeys.includes(key)) {
        return;
    }

    /** Avoid event bubbling and default behavior */
    event.stopPropagation();
    event.preventDefault();

    /** Retrieve all direct items. We need to only get the direct children to avoid confusions in case of nested feeds. */
    const feedItems = feedElement?.querySelectorAll<HTMLElement>(':scope > [aria-posinset]');
    /** The article the user is currently focused in. */
    const activeArticle = target.closest('[aria-posinset]');
    /** The position of the article the user is currently focused in */
    const articlePosInset = activeArticle ? activeArticle.getAttribute('aria-posinset') : undefined;

    if (!activeArticle || !articlePosInset) {
        return;
    }

    /**
     * Convert position to number to use as index
     * We also need to subtract by 1 because posInset is 1-indexed
     */
    const focusedIndex = +articlePosInset - 1;
    const previousIndex = focusedIndex - 1;
    const nextIndex = focusedIndex + 1;

    switch (key) {
        // Move the focus to the previous article
        case 'PageUp':
            if (feedItems && previousIndex >= 0) {
                feedItems[previousIndex].focus();
            }
            break;
        // Move the focus to the next article
        case 'PageDown':
            if (feedItems && feedItems.length > nextIndex) {
                feedItems[nextIndex].focus();
            }
            break;
        // Set focus on first focusable element before the feed
        case 'Home':
            if (event.ctrlKey) {
                options.onFocusOut();
                focusPrevious(document.body, feedElement);
            }
            break;
        // Set focus on first focusable element after the feed
        case 'End':
            if (event.ctrlKey) {
                options.onFocusOut();
                focusAfter(document.body, feedElement);
            }
            break;
        default:
            break;
    }
};

interface UseFeedKeyboardNavigationProps {
    /** The reference of the feed element */
    feedRef: MutableRefObject<HTMLDivElement | null>;
    /** Callback when the focus is set back in the feed */
    onFocusIn(): void;
    /** Callback when the keyboard navigation moves the focus out of the feed */
    onFocusOut(): void;
}

/**
 * Hook to manage the keyboard navigation for the Feed pattern
 *
 * @family Feed
 */
export const useFeedKeyboardNavigation = ({ feedRef, onFocusIn, onFocusOut }: UseFeedKeyboardNavigationProps) => {
    useFocusWithin({
        elementRef: feedRef,
        onFocusIn,
    });

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (feedRef.current) {
                manageFeedKeyboardEvents(feedRef.current, event, { onFocusOut });
            }
        },
        [feedRef, onFocusOut],
    );
    useEventListener('keydown', handleKeyDown, feedRef);
};
