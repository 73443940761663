(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserAuthFactory($resource) {
        'ngInject';

        return $resource('/service/user/:key', {}, {
            getConnected: {
                isArray: false,
                method: 'POST',
                params: {
                    key: 'getConnected',
                },
            },

            getCustomerAdminToken: {
                isArray: false,
                method: 'POST',
                params: {
                    key: 'customerToken',
                },
            },

            refreshToken: {
                isArray: false,
                method: 'POST',
                params: {
                    key: 'token',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('UserAuthFactory', UserAuthFactory);
})();
