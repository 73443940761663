import React from 'react';

import { padding, useClassnames } from '@lumapps/classnames';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Heading, InputHelper, List, ProgressLinear, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { SOCIAL_NETWORKS } from '../../keys';
import { SocialNetworksWithAccess } from '../../types';
import { SocialPlatformEntry } from '../SocialPlatformEntry';
import { SkeletonSocialProfile } from './SkeletonSocialProfile';

import './index.scss';

export interface SocialProfilesProps {
    /** loading status for the networks */
    status: BaseLoadingStatus;
    /** social networks to display */
    socialNetworks: SocialNetworksWithAccess[];
    /** callback to be executed once the social network is clicked */
    onSocialNetworkClick: (socialNetwork: SocialNetworksWithAccess) => void;
    /** callback to be executed if the retrieval of social networks fail */
    onRetry: () => void;
    /** determines whether there is an async operation in process, in order to disable the UI */
    isOperationInProcess?: boolean;
    /** top level classname  */
    className: string;
}

export const SocialProfiles: React.FC<SocialProfilesProps> = ({
    status,
    socialNetworks,
    onSocialNetworkClick,
    onRetry,
    isOperationInProcess,
    className,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(className);
    const isLoading = status !== BaseLoadingStatus.idle && status !== BaseLoadingStatus.error;
    const hasResults = status === BaseLoadingStatus.idle && socialNetworks.length > 0;
    const hasNoResults = status === BaseLoadingStatus.idle && socialNetworks.length === 0;
    const hasError = status === BaseLoadingStatus.error;

    return (
        <div className={className}>
            <Heading as="h2" className={element('title')}>
                {translateKey(SOCIAL_NETWORKS.SOCIAL_PROFILE_TITLE)}
            </Heading>

            <InputHelper theme="light">{translateKey(SOCIAL_NETWORKS.SOCIAL_PROFILE_DESCRIPTION)}</InputHelper>

            <div className={padding('top', 'huge')}>
                {isLoading ? <SkeletonSocialProfile /> : null}

                {isOperationInProcess ? <ProgressLinear /> : null}

                {hasResults ? (
                    <List>
                        {socialNetworks.map((socialNetwork) => {
                            return (
                                <SocialPlatformEntry
                                    key={socialNetwork.socialNetworkId}
                                    id={socialNetwork.socialNetworkId}
                                    isActive={Boolean(socialNetwork.isConnected)}
                                    onClick={() => onSocialNetworkClick(socialNetwork)}
                                    isEnabled={!isOperationInProcess}
                                />
                            );
                        })}
                    </List>
                ) : null}

                {hasNoResults ? (
                    <Text as="span" typography="body2" className={element('empty')}>
                        {translateKey(SOCIAL_NETWORKS.NO_ACTIVE_NETWORK)}
                    </Text>
                ) : null}

                {hasError ? <ServiceNotAvailableState onRetry={onRetry} /> : null}
            </div>
        </div>
    );
};
