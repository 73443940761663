/* eslint-disable no-use-before-define */
import { CSSProperties } from 'react';

import { ServerListRequest, ServerListResponse } from '@lumapps/base-api/types';
import { ChatProviderId } from '@lumapps/chat/types';
import { Template } from '@lumapps/community-templates/types';
import { ContentTypes } from '@lumapps/content-types/types';
import { Content } from '@lumapps/contents/types';
import { Tag } from '@lumapps/folksonomy/types';
import { Instance } from '@lumapps/instance/types';
import { Theme } from '@lumapps/lumx/react';
import { PostStatus, PostType, PostTypeV2 } from '@lumapps/posts/types';
import { Tag as PostCategory } from '@lumapps/tags/types';
import { TranslateObject } from '@lumapps/translations';
import { User } from '@lumapps/user/types';
import { OneOrMany } from '@lumapps/utils/types/OneOrMany';
import { LinkAttachment } from '@lumapps/widget-base/types';

export enum RenderingType {
    community = 'community',
    space = 'space',
}

/** The payload when a community search request is to be sent.
 * - isLoading defines whether a request is being sent in order to display the
 * related components in a loading state.
 * - isLoadingMore defines whether this community search request is sent to get
 * additional pages in the context of pagination.
 * The UI differs whether the loading is for an initial request or a pagination request.
 */
export interface CommunitySearchInitiatedPayload {
    query?: string;
    isLoading: boolean;
    isLoadingMore: boolean;
    sortOrder?: string;
    renderingTypes?: RenderingType[];
}

export interface CommunitySearchSuccessPayload extends ServerListResponse<Community | Content> {
    add?: boolean;
}

export interface CommunitiesRequestParams extends ServerListRequest {
    more?: boolean;
    lang?: OneOrMany<string>;
    add?: boolean;
    addCurrentCommunity?: boolean;
    currentCommunityId?: string;
    instance?: string;
}

export enum SearchSortOrder {
    title = 'title',
    reverseTitle = '-title',
    visibility = 'privacy',
    reverseVisibility = '-privacy',
    createdAt = 'createdAt',
    reverseCreatedAt = '-createdAt',
    updatedBy = 'updatedBy',
    reverseUpdatedBy = '-updatedBy',
    updatedAt = 'updatedAt',
    reverseUpdatedAt = '-updatedAt',
}

interface CommunitiesFetchCommonParams {
    renderingTypes?: RenderingType[];
    lang: OneOrMany<string>;
}

export interface CommunitiesListRequestParams extends CommunitiesFetchCommonParams, ServerListRequest {
    ids?: string[];
    instanceId?: string[];
    customerId?: string;
    isCommunityAdmin?: boolean;
}

export interface CommunitiesSearchForPostingRequestParams extends CommunitiesFetchCommonParams, ServerListRequest {
    followingOnly?: boolean;
    instanceId?: string[];
    privacy?: string;
}

export interface CommunitiesSearchForSharingRequestParams extends CommunitiesFetchCommonParams, ServerListRequest {
    postId: string;
    followingOnly?: boolean;
}

export interface CommunitiesSearchForMovingRequestParams extends CommunitiesFetchCommonParams, ServerListRequest {
    postId: string;
    followingOnly?: boolean;
    instanceId?: string;
}

export enum SearchCommunityState {
    initial = 'initial',
    loading = 'loading',
    loadingMore = 'loadingMore',
    loadingNewSortOrder = 'loadingNewSortOrder',
    noCommunities = 'noCommunities',
    success = 'success',
    failure = 'failure',
}

export interface SearchState {
    query: string;
    cursor: string;
    ids: string[];
    hasMore: boolean;
    followingOnly?: boolean;
    sortOrder?: string;
    status: SearchCommunityState;
}

export interface CommunityNavigationEditState {
    isOpen: boolean;
    /** The ID of the Node or NavigationItemV2 (section) we want to have the add item dialog pre-opened */
    addItemTargetId?: string;
}

export interface GetCommunityParams {
    customerId?: string;
    instance?: string;
    lang?: string;
    uid: string;
    slug?: string;
    fields?: string;
}

export interface CommunityGetSuccessPayload {
    community: Community;
}

export interface CommunityMetadata {
    postTypes: PostTypeV2[];
    enabledServices: string[];
    title: string;
    id: string;
    myPermissions: {
        canContribute: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canFollow: boolean;
        canPin: boolean;
        canReadPosts: boolean;
    };
}

export interface FrontofficeState {
    entities: { [key: string]: Community };
    metadata: CommunityMetadata | null;
    search: SearchState;
    selectedCommunityId: string;
    selectedCommunity: Community;
    currentCommunity: Community;
    isLoadingForPostDialog: boolean;
    isConfigurationWizardOpen: boolean;
    communityNavigationEdit: CommunityNavigationEditState;
}

export enum CommunityType {
    google = 'google',
    microsoft = 'microsoft',
    // email is when an external user creates a community
    email = 'email',
    okta = 'okta',
}

export enum CommunityPrivacy {
    secret = 'secret',
    restricted = 'restricted',
    readOnly = 'read_only',
    open = 'open',
    descriptionOnly = 'description_only',
}

export type VisibilityOptionType = {
    // The privacy id cannot be CommunityPrivacy.secret since we don't manage it anymore.
    id: Exclude<CommunityPrivacy, CommunityPrivacy.secret>;
    title: string;
    description: string;
    icon: string;
};

export enum CommunitySearchScopes {
    FOLLOWING = 'following',
}

export type CommunityUpdatedByDetails = Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'customer'>;

export interface CommunityProperties {
    hasDashboard?: boolean;
    hasLearningEnabled?: boolean;
    followAndNotify?: boolean;
}

export enum CommunityDriveFolderType {
    shared = 'drive#teamDrive',
    folder = 'drive#folder',
}

/** Define how the Space header should handle the image
 * 'small' = as a thumbnail
 * 'cover' = as a banner
 * */
export enum CommunityThumbnailDisplayType {
    small = 'small',
    cover = 'cover',
}

export interface CommunityDriveFolder {
    docPath?: string;
    folderId?: string;
    providerId?: string;
    kind?: CommunityDriveFolderType;
    name?: string;
    source?: string;
    webUrl?: string;
}
// Props common to all community chat
export interface CommunityChat {
    // The provider used
    chatProvider: ChatProviderId;
    // The userspace id
    chatId: string;
    // The chan id (only if selecting an existing channel)
    chanId?: string;
    // The channel name (only if creating a new channel)
    chatName?: string;
    // Whether actions to manage the chat are required by user
    chatActionsManaged?: string;
}

export interface LearningSettings {
    isServiceEnabled: boolean;
    catalogCategoryId?: string;
}

export interface Community extends Content {
    /** Content type = community. */
    type: typeof ContentTypes.COMMUNITY;
    /** Array containing all the users manually picked as community manager */
    adminKeys: string[];
    /** whether the user can contribute to that community or not */
    canContribute: boolean;
    /** whether the user can edit this community or not */
    canEdit: boolean;
    /** whether the user ca delete this community or not */
    canDelete: boolean;
    /** canonical url of the community */
    canonicalUrl: { translations: Record<string, never> };
    /** The community chat object */
    chat?: CommunityChat | null;
    /** feed key refering to manually picked managers/contributors */
    communityFeedKey: string;
    /** is a google, microsoft or email community */
    communityType: CommunityType;
    /** used in payload to notify backend to create the Google Drive Folder */
    createDrive?: boolean;
    /** community's description */
    description: Record<string, string>;
    /** Array containing all the groups manually picked as community manager */
    editors: string[];
    /** the feedKeys array contains the `community.communityFeedKey` which is the feed key refering to picked managers & contributors groups */
    /** it is returned by the backend and should not be manually changed when saving (use editor/publisher array instead) */
    feedKeys: string[];
    /** Use this as a fallback, since it is the old id field. */
    id: string;
    /** community's instance */
    instance: string;
    /** has a dedicated calendar. */
    hasCalendarId: boolean;
    /** the dedicated calendar's id. */
    calendarId?: string;
    /** has a dedicated chat. */
    hasChat: boolean;
    /** has articles allowed to contributors */
    hasArticlesEnabled: boolean;
    /** has events allowed to contributors */
    hasEventsEnabled: boolean;
    /** has manage media library enabled */
    hasManagedMediaLibraryEnabled?: boolean;
    /** has feed enabled, for space only */
    hasSpaceFeedEnabled?: boolean;
    /** has posts enabled, for space only */
    hasPostsEnabled?: boolean;
    /** has the community an dedicated drive folder. */
    hasDriveFolder: boolean;
    /** The selected drive folder . */
    driveFolder?: CommunityDriveFolder;
    /** number of members of the community */
    membersCount?: number;
    /** List of all availables post types for the given community. */
    postTypes: PostType[];
    /** community kind of privacy */
    privacy: CommunityPrivacy;
    /** community properties */
    properties: CommunityProperties;
    /** array containing all the groups manually picked as contributors */
    publishers: string[];
    /** @deprecated post's medias are accessible outside the community or not (we should use the FF "security at community level" instead) */
    securedRepository: boolean;
    /** List of feeds which are forced to follow */
    subscribedFeedKeys?: string[];
    /** The slug of the community. */
    slug: TranslateObject;
    /** community's thumbnail */
    thumbnail?: string;
    /** community's thumbnail display mode */
    thumbnailDisplayType: CommunityThumbnailDisplayType;
    /** title of this community */
    title: TranslateObject;
    /** The uid of the community. */
    uid: string;
    /** The date this community has been last updated */
    updatedAt?: string;
    /** The details of the user that updated the community */
    updatedByDetails?: CommunityUpdatedByDetails;
    /** Array containing all user manually picked as contributors */
    userKeys: string[];
    userDetails: User[];
    workspace: string;
    /** template used by the community */
    template?: Template;
    /** The linked tagz (folksonomy) */
    tagz?: Tag[];
    /** The community tags (categories) details */
    tagsDetails?: Array<PostCategory>;
    /** The community tags (categories) ids */
    tags?: Array<string>;
    /** The community post statuses (ideas) */
    postStatuses?: Array<PostStatus>;
    /** Whether we are dealing with a space or a legacy community */
    renderingType?: RenderingType;
    /** Configuration object for the learning service */
    learningSettings?: LearningSettings;
}

export interface PostCategorySave extends Partial<PostCategory> {
    $tempId?: string;
    status?: string;
}

export interface CommunitySavePayload extends Omit<Community, 'postStatuses' | 'tagsDetails'> {
    /** Should a calendar be created or not */
    createCalendar?: boolean;
    /** Should a drive be created or not */
    createDrive?: boolean;
    /** Should a shared drive be created or not */
    createTeamDrive?: boolean;
    /** If the chat is used or not */
    useChat?: boolean;
    /** The community tags (categories) in their "save" format */
    tagsDetails?: Array<PostCategorySave>;
    /** The community post statuses (ideas) in their "save" format */
    postStatuses?: Array<PostStatus>;
    /** The groupName of the community connected group, in case of synced Microsoft group. */
    groupName?: string;
    /** The groupId of the community connected group, in case of synced Microsoft group. */
    groupId?: string;
}

export enum CommunityCreationStatus {
    initial = 'initial',
    loadingTemplates = 'loadingTemplates',
    loadingMoreTemplates = 'loadingMoreTemplates',
    templatesSuccess = 'templatesSuccess',
    templatesFailure = 'templatesFailure',
    noTemplates = 'noTemplates',
    settings = 'settings',
    saving = 'saving',
}

/** Members Requests management */

// A Community Request object
export interface CommunityRequest {
    uid: string;
    customerKey: string;
    communityKey: string;
    id: string;
    userKey: string;
    userDetails: User;
    requestStatus: string;
    instanceKey: string;
    adminMessage: string;
    userMessage: string;
}

// Params for requesting a membership in a community
export interface CommunitiesMembershipRequestParams {
    // The ID of the community
    communityKey: string;
}

// Params for listing the membership requests of a community
export interface CommunitiesMembersListRequestParams extends Pick<ServerListRequest, 'cursor'> {
    // The ID of the community
    communityId: string;
}

// Params for accepting or refusing an user into a community
export interface CommunitiesMembersApproveOrRejectRequestParams {
    // The unique identifier of the request (not the user id).
    uid: string;
}

export interface CommunityLinkRef {
    /** Community id. */
    id?: string;
    /** Community slug. */
    slug: string;
    /** Community view. */
    view?: string;
    /** Community identifier. */
    identifier?: string;
    /**
     * Community instance.
     * Used to generate links cross instance.
     */
    instance?: Partial<Pick<Instance, 'id' | 'slug'>>;
    /**
     * Whether the Community link ref has stale data or not (slug could have changed, instance slug could have changed, etc.)
     */
    hasStaleData?: boolean;
    /**
     * Whether we are dealing with a space or a legacy community
     */
    renderingType?: RenderingType;
    /**
     * Error status provided from the api call while getting community link additional information
     */
    errorStatus?: number;
    /** HTML `#` anchor */
    anchor?: string;
}

export interface CommunityReference {
    /** The community ID */
    communityId: string;
    /** The community full name */
    name: TranslateObject;
    /** The community slug */
    slug: TranslateObject;
    /** The community rendering type */
    renderingType?: RenderingType;
}

export interface ContributionFieldProps {
    /** Is the article dialog is opened or not */
    isArticleDialogOpen?: boolean;
    /** Is the FF 'articles' enabled */
    isArticleEnabled?: boolean;
    /** Is the FF 'events' enabled */
    isEventEnabled?: boolean;
    /** Is the post service enabled (only for spaces) */
    isPostEnabled?: boolean;
    /** CSS class. */
    className?: string;
    /** Current user avatar picture. */
    userProfilePicture: string;
    /** Current user full name. */
    userFullName: string;
    /** Available post type in community. */
    availablePostTypes?: PostType[];
    /** Forwarded style. */
    style?: CSSProperties;
    /** Light/Dark theme. */
    theme?: Theme;
    /** Callback to upload selected files or images . */
    onSelectFiles(
        files: File[],
        openPostDialog: (options: { postType?: PostType; containerId: string }) => Promise<void>,
    ): void;
    /** Callback to attach a link to the post. */
    onInsertLink(
        link: LinkAttachment,
        openPostDialog: (options: { postType?: PostType; containerId: string }) => Promise<void>,
    ): void;
    /** Callback to open article dialog . */
    openArticleDialog(): void;
    /** The default post type to use */
    defaultPostType: PostType;
    /** Is the current community a space or a legacy community */
    isSpace?: boolean;
}

export interface ContributionFieldButtonsToDisplay {
    article?: { onSelect: () => void };
    event?: { onSelect: () => void };
    file?: { onSelect: () => void };
    image?: { onSelect: () => void };
    link?: { onSelect: () => void };
}
