import produce from 'immer';

import { APPEND_POSTS, INIT_STATE, SET_POSTS, SET_NEXTPAGE } from './wall_action';

const initialState = [];

const initialWidgetState = {
    hasNextPage: false,
    isLoading: false,
    nextPageCursor: null,
    posts: [],
};

const NOT_FOUND = -1;

/**
 * The reducer for the wall actions.
 *
 * @param  {Object} state  The current store state.
 * @param  {string} action The current dispatched action.
 * @return {Object} The new state of the redux store.
 */
const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const widgetIndex = draft.findIndex((widget) => {
            if (action.payload && action.payload.uuid === undefined) {
                return NOT_FOUND;
            }

            return action.payload ? widget.uuid === action.payload.uuid : NOT_FOUND;
        });

        switch (action.type) {
            case INIT_STATE: {
                if (widgetIndex === NOT_FOUND) {
                    draft.push({
                        uuid: action.payload.uuid,
                        ...initialWidgetState,
                    });
                    Object.assign(draft[draft.length - 1], { isInit: true });
                }

                break;
            }

            case SET_NEXTPAGE: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], {
                        hasNextPage: action.payload.data.more,
                        nextPageCursor: action.payload.data.cursor || null,
                    });
                }

                break;
            }

            case APPEND_POSTS: {
                if (widgetIndex !== NOT_FOUND) {
                    const stateCopy = Object.assign(draft[widgetIndex].posts, {});
                    stateCopy.push(...action.payload.data);
                    Object.assign(draft[widgetIndex], {
                        posts: stateCopy,
                    });
                }

                break;
            }

            case SET_POSTS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { posts: action.payload.data });
                }

                break;
            }

            default:
                return state;
        }

        return draft;
    });

export { reducer };
