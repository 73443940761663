import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Pagination } from '@lumapps/lumx-layouts/components/Pagination';
import { PaginationMode } from '@lumapps/lumx-layouts/types';
import { mdiCheckboxMarkedCircleOutline, mdiSparkles, mdiSubdirectoryArrowLeft } from '@lumapps/lumx/icons';
import {
    Button,
    ColorPalette,
    Emphasis,
    FlexBox,
    Icon,
    Orientation,
    Placement,
    PopoverDialog,
    ProgressCircular,
    Size,
    Text,
    TextField,
    Typography,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ContentView } from '@lumapps/wrex/components/ContentView';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';
import { BaseSelection } from '@lumapps/wrex/slate';

import { WREX_MARKDOWN_MARKS, WREX_MARKDOWN_ELEMENTS } from '../../constants';
import { UseRefineContentOutput } from '../../hooks/useRefineContentDialog';
import { WREX_GENERATED_CONTENT } from '../../keys';

import './index.scss';

const CLASSNAME = 'refine-content-dialog';

export interface RefineContentDialogProps {
    anchorRef: React.RefObject<HTMLElement>;
    hasCurrentRange: boolean;
    isRefiningContent: UseRefineContentOutput['isRefiningContent'];
    newSuggestions?: UseRefineContentOutput['newSuggestions'];
    slateSelection: BaseSelection;
    popoverStyle?: object;
    onCancelRefinement: UseRefineContentOutput['onCancelRefinement'];
    onInsertNewContent: UseRefineContentOutput['onInsertNewContent'];
    onRefineContent: UseRefineContentOutput['onRefineContent'];
    onClose: () => void;
    setNewSuggestions: UseRefineContentOutput['setNewSuggestions'];
}

export const RefineContentDialog = ({
    anchorRef,
    hasCurrentRange,
    isRefiningContent,
    newSuggestions,
    slateSelection,
    onCancelRefinement,
    onInsertNewContent,
    onRefineContent,
    onClose,
    setNewSuggestions,
}: RefineContentDialogProps) => {
    const { translateKey, pluralize } = useTranslate();
    const { get } = useDataAttributes('ai-adapt-selection-ask-ai');
    const { block, element } = useClassnames(CLASSNAME);

    const [detailedPrompt, setDetailedPrompt] = React.useState('');
    const [promptList, setPromptList] = React.useState<string[]>([]);
    const [index, setIndex] = React.useState(newSuggestions ? newSuggestions.length : 0);

    const styledSuggestion = React.useMemo(() => {
        if (newSuggestions?.[index]) {
            const slateContent = fromMarkdown(newSuggestions[index]);
            return (
                <ContentView
                    theme="light"
                    content={slateContent}
                    elements={WREX_MARKDOWN_ELEMENTS}
                    marks={WREX_MARKDOWN_MARKS}
                />
            );
        }
        return undefined;
    }, [index, newSuggestions]);

    // Ask AI again using the text input
    const onDetailPrompt = () => {
        if (newSuggestions && !isEmpty(newSuggestions)) {
            setNewSuggestions(newSuggestions);
            onRefineContent(detailedPrompt, newSuggestions[index]);
            setPromptList([...promptList, detailedPrompt]);
            setDetailedPrompt('');
        }
    };

    // Press Enter to submit a new prompt
    const onKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === 'Enter') {
            onDetailPrompt();
        }
    };

    // Handle suggestions pagination
    const onPreviousClick = () => {
        setIndex((i) => i - 1);
    };
    const onNextClick = () => {
        setIndex((i) => i + 1);
    };

    // When suggestions are updated, display the last one
    React.useEffect(() => {
        if (newSuggestions) {
            setIndex(newSuggestions.length >= 1 ? newSuggestions.length - 1 : 0);
        }
    }, [newSuggestions]);

    // Focus textfield after generation
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (!isRefiningContent && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isRefiningContent]);

    return (
        <PopoverDialog
            anchorRef={anchorRef}
            isOpen={hasCurrentRange}
            placement={Placement.TOP}
            className={block()}
            closeOnClickAway
            onClose={onClose}
            aria-label={pluralize(translateKey(GLOBAL.SUGGESTION), 1)}
            // We set usePortal to false to have an inline dialog
            usePortal={false}
        >
            <FlexBox orientation={Orientation.vertical}>
                <FlexBox
                    className={margin('bottom', 'big')}
                    orientation={Orientation.horizontal}
                    hAlign="bottom"
                    gap={Size.tiny}
                >
                    <Icon icon={mdiSparkles} color={ColorPalette.primary} size={Size.xxs} />
                    <Text as="p" color={ColorPalette.primary} typography={Typography.overline}>
                        {isRefiningContent
                            ? translateKey(GLOBAL.CURRENTLY_GENERATING)
                            : pluralize(translateKey(GLOBAL.SUGGESTION), 1)}
                    </Text>
                </FlexBox>

                {styledSuggestion && !isRefiningContent && (
                    <FlexBox orientation={Orientation.vertical} gap={Size.big} className={element('generated-content')}>
                        <div className={element('suggestion')}>{styledSuggestion}</div>
                        <FlexBox orientation={Orientation.horizontal} gap={Size.regular} noShrink>
                            <Button
                                onClick={onCancelRefinement}
                                emphasis={Emphasis.medium}
                                size="s"
                                isDisabled={isRefiningContent}
                                {...get({ element: 'ignore' })}
                            >
                                {translateKey(WREX_GENERATED_CONTENT.IGNORE_SUGGESTION)}
                            </Button>
                            <Button
                                onClick={() => onInsertNewContent(slateSelection, index)}
                                leftIcon={mdiCheckboxMarkedCircleOutline}
                                size="s"
                                isDisabled={isRefiningContent}
                                {...get({ element: 'replace' })}
                            >
                                {translateKey(WREX_GENERATED_CONTENT.REPLACE_SELECTION)}
                            </Button>
                        </FlexBox>
                    </FlexBox>
                )}
                {isRefiningContent && <ProgressCircular className={element('loading')} />}

                {newSuggestions && newSuggestions.length > 0 && (
                    <FlexBox
                        className={margin('top', 'huge')}
                        orientation={Orientation.horizontal}
                        gap={Size.regular}
                        vAlign="center"
                    >
                        <TextField
                            inputRef={inputRef}
                            icon={mdiSparkles}
                            placeholder={promptList[index] || translateKey(WREX_GENERATED_CONTENT.OR_ASK_ANYTHING)}
                            className={element('detailed-prompt')}
                            onChange={(prompt) => setDetailedPrompt(prompt)}
                            afterElement={
                                !isEmpty(detailedPrompt) && (
                                    <Icon icon={mdiSubdirectoryArrowLeft} size={Size.xxs} onClick={onDetailPrompt} />
                                )
                            }
                            isDisabled={isRefiningContent}
                            value={detailedPrompt}
                            onKeyDown={onKeyDown}
                        />
                        <Pagination
                            nbOfPages={newSuggestions.length}
                            currentPage={index}
                            onNextClick={onNextClick}
                            onPreviousClick={onPreviousClick}
                            isFullWidth={false}
                            mode={PaginationMode.compact}
                        />
                    </FlexBox>
                )}
            </FlexBox>
        </PopoverDialog>
    );
};
