import { useEffect } from 'react';

import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { PENDO_API_KEY, get } from '@lumapps/constants';
import { requestOnIdleCallback } from '@lumapps/utils/function/requestIdleCallback';

import { PendoExtraProperties, UsePendoProps } from '../../types';
import { insertPendoScript } from './pendoSnippet';
import { sanitizeUrl } from './sanitizeUrl';

declare const window: any;
declare const pendo: any;

const Config = get();

/**
 * Methods to check that essential dependencies are available in the store before initializing Pendo.
 */
const isStoreReadyToSetUpPendo = (props: UsePendoProps) => {
    const { customer, instance, user } = props;

    /**
     * Customer and instance should at least have an id and a slug for the URLs to be properly sanitized.
     */
    if (!customer || !customer.id || !customer.slug || !instance || !instance.id || !instance.slug) {
        return false;
    }

    /**
     * If the user object is empty, it means the state is not initialized.
     *     If we check that the user.id is available, we would miss guests.
     *     If the user is a guest, the user object will contains `isGuest` and thus will not be empty either.
     */
    if (!user || isEmpty(user)) {
        return false;
    }

    return true;
};

/**
 * Get the pendo config
 */
const getPendoConfig = (props: UsePendoProps) => {
    const { customer, instance, user, customVisitorAttributes = {}, currentLanguage } = props;

    /**
     * If the user is not connected, we count him as a guest and return early.
     */
    if (user.isGuest) {
        return {
            visitor: {
                id: 'VISITOR-UNIQUE-ID',
                lang: navigator.language.split('-')[0],
            },
            sanitizeUrl: sanitizeUrl(props),
            account: {
                id: customer.id,
                name: customer.name,
                feature_flags: customer.enabledFeatures,
            },
        };
    }

    /**
     * If the user is connected, we retrieve relevant information.
     * We don't use dedicated selector from permissions or languages packages because at that
     *     time, these slices are not filled yet. We don't want to wait for these slices to be
     *     filled when we have the information available inside the user.
     */

    /** Compute user role. */
    let role = 'basic';
    if (user.isSuperAdmin) {
        role = 'platform_admin';
    } else if (includes(user.instancesSuperAdmin, instance.id)) {
        role = 'site_admin';
    }

    /** Compute user department and location. */
    let organization: { department?: string; location?: string } = {};
    if (user.apiProfile) {
        organization = find(user.apiProfile?.organizations, (org) => org?.primary);
    }
    const { department, location } = organization || {};

    const pendoExtraProperties: PendoExtraProperties = Config.pendoExtraProperties || {};

    /** Return Pendo config */
    return {
        ...pendoExtraProperties,
        visitor: {
            ...(pendoExtraProperties.visitor || {}),
            ...customVisitorAttributes,
            id: `${customer.id}_${user.id}`,
            account_type: user?.accountType,
            role,
            department,
            location,
            lang: currentLanguage,
        },
        sanitizeUrl: sanitizeUrl(props),
        account: {
            ...(pendoExtraProperties.account || {}),
            id: customer.id,
            name: customer.name,
            feature_flags: customer.enabledFeatures,
        },
        preventUnloadListener: true,
    };
};

const usePendo = ({ isPendoEnabled, hasAcceptedCookies, ...rest }: UsePendoProps) => {
    useEffect(() => {
        requestOnIdleCallback(() => {
            /** Insert pendo script only once. */
            if (isPendoEnabled && hasAcceptedCookies && !window.pendoScriptInjected) {
                window.pendoScriptInjected = true;
                insertPendoScript(PENDO_API_KEY);
            }
        });
    }, [isPendoEnabled, hasAcceptedCookies]);

    useEffect(() => {
        requestOnIdleCallback(() => {
            /**
             * To initialize Pendo, pendo should be enabled, obviously.
             * Pendo should also be inserted and available.
             * It should not have been initialized yet.
             * But also, some essential information should be available in the store.
             */
            if (
                isPendoEnabled &&
                window.pendoScriptInjected &&
                pendo &&
                !window.pendoInitialized &&
                isStoreReadyToSetUpPendo({ ...rest, hasAcceptedCookies })
            ) {
                window.pendoInitialized = true;
                const config = getPendoConfig({ ...rest, hasAcceptedCookies });
                pendo.initialize(config);
            }
        });
    }, [rest, isPendoEnabled, hasAcceptedCookies]);
};

export { usePendo, getPendoConfig };
