/* istanbul ignore file */
export enum MOBILE_CONFIGURATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179605949
     */
    ACCESS_VIDEO_GALLERIES = 'MOBILE.ACCESS_VIDEO_GALLERIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105834997.
     */
    ADD_CONFIGURATION_URL = 'MOBILE.CONFIGURATION_ADD_CONFIGURATION_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232439602
     */
    ALL_FIELDS_COMPLETED = 'MOBILE.CONFIGURATION_ALL_FIELDS_COMPLETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109138173
     */
    APP_DIRECTORY = 'ADMIN.FEATURES.APP_DIRECTORY_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109138186.
     */
    APP_DIRECTORY_NAME_DESCRIPTION = 'MOBILE.CONFIGURATION_APP_DIRECTORY_NAME_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104044436.
     */
    APP_LOGO_EDITING_LABEL = 'MOBILE.CONFIGURATION_APP_LOGO_EDITING_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107538865.
     */
    APP_LOGO_HELPER = 'MOBILE.CONFIGURATION_APP_LOGO_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104016741.
     */
    APP_LOGO_LABEL = 'MOBILE.CONFIGURATION_APP_LOGO_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=379081291
     */
    ASSOCIATED_BRANDED_APP = 'MOBILE.CONFIGURATION_ASSOCIATED_BRANDED_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=379085937
     */
    ASSOCIATED_BRANDED_APP_HELPER = 'MOBILE.CONFIGURATION_ASSOCIATED_BRANDED_APP_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107414901.
     */
    BACKGROUND_SWITCH_CONTENT = 'MOBILE.CONFIGURATION_BACKGROUND_SWITCH_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105834189.
     */
    CHAT_CONFIGURE_DESCRIPTION = 'MOBILE.CONFIGURATION_CHAT_CONFIGURE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835035.
     */
    CHAT_DESCRIPTION = 'MOBILE.CONFIGURATION_CHAT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107414877.
     */
    COLOR_THEME_ERROR_HELPER = 'MOBILE.CONFIGURATION_COLOR_THEME_ERROR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103085555.
     */
    COLOR_THEME_HELPER = 'MOBILE.CONFIGURATION_COLOR_THEME_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107414891.
     */
    COLOR_THEME_LABEL = 'MOBILE.CONFIGURATION_COLOR_THEME_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107414911.
     */
    COLOR_THEME_PLACEHOLDER = 'MOBILE.CONFIGURATION_COLOR_THEME_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108271270.
     */
    COLOR_THEME_TITLE = 'MOBILE.CONFIGURATION_COLOR_THEME_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=118573235.
     */
    COLOR_THEME_WHITE_ERROR_HELPER = 'MOBILE.CONFIGURATION_COLOR_THEME_WHITE_ERROR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835045.
     */
    COMMUNITIES_DESCRIPTION = 'MOBILE.CONFIGURATION_COMMUNITIES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102824427.
     */
    CONFIRM_DIALOG_DESCRIPTION = 'MOBILE.CONFIGURATION_CONFIRM_DIALOG_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102823700.
     */
    CONFIRM_DIALOG_TITLE = 'MOBILE.CONFIGURATION_CONFIRM_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835092.
     */
    CONTENT_STREAM_DESCRIPTION = 'MOBILE.CONFIGURATION_CONTENT_STREAM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102355832.
     */
    CREATE = 'MOBILE.CONFIGURATION_CREATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239326158
     */
    DELAY_CONFIRMATION = 'MOBILE.CONFIGURATION_DELAY_CONFIRMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104831602.
     */
    DELETE_NB_DESCRIPTION = 'MOBILE.CONFIGURATION_DELETE_NB_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104835962.
     */
    DELETE_NB_TITLE = 'MOBILE.CONFIGURATION_DELETE_NB_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104835920.
     */
    DELETE_ONE_DESCRIPTION = 'MOBILE.CONFIGURATION_DELETE_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104836055.
     */
    DELETE_ONE_TITLE = 'MOBILE.CONFIGURATION_DELETE_ONE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109120758.
     */
    DELETE_SUCCESS_NOTIFICATION = 'MOBILE.CONFIGURATION_DELETE_SUCCESS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830725.
     */
    DESIGNER_SUBTITLE = 'MOBILE.CONFIGURATION_DESIGNER_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830618.
     */
    DESIGNER_TITLE = 'MOBILE.CONFIGURATION_DESIGNER_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108850869.
     */
    DROP_FEATURE_HERE = 'MOBILE.CONFIGURATION_DROP_FEATURE_HERE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=110798299.
     */
    EDIT_CONFIRM_DIALOG_DESCRIPTION = 'MOBILE.CONFIGURATION_EDIT_CONFIRM_DIALOG_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=110798210.
     */
    EDIT_CONFIRM_DIALOG_TITLE = 'MOBILE.CONFIGURATION_EDIT_CONFIRM_DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830753.
     */
    EXPLORE_MODULES_CAPTION = 'MOBILE.CONFIGURATION_EXPLORE_MODULES_CAPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830744.
     */
    EXPLORE_MODULES_TITLE = 'MOBILE.CONFIGURATION_EXPLORE_MODULES_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=112767067.
     */
    EXPLORE_PAGE_HEADER_ALERT = 'MOBILE.CONFIGURATION_EXPLORE_PAGE_HEADER_ALERT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108837414.
     */
    EXPLORE_PAGE_HEADER_WARNING = 'MOBILE.CONFIGURATION_EXPLORE_PAGE_HEADER_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108837542.
     */
    EXPLORE_TAB_BAR_ALERT = 'MOBILE.CONFIGURATION_EXPLORE_TAB_BAR_ALERT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835016.
     */
    FEATURES_DESCRIPTION = 'MOBILE.CONFIGURATION_FEATURES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113746679.
     */
    FEATURES_EMPTY_ERROR = 'MOBILE.CONFIGURATION_FEATURES_EMPTY_ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=113765565.
     */
    FEATURES_ONLY_NAVIGATION_ERROR = 'MOBILE.CONFIGURATION_FEATURES_ONLY_NAVIGATION_SELECTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835005.
     */
    FEATURES_TITLE = 'MOBILE.CONFIGURATION_FEATURES_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=106307685.
     */
    GROUPS_ADDED_MANY = 'MOBILE.CONFIGURATION_GROUPS_ADDED_MANY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=106307671.
     */
    GROUPS_ADDED_ONE = 'MOBILE.CONFIGURATION_GROUPS_ADDED_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=106306084.
     */
    GROUPS_DESCRIPTION = 'MOBILE.CONFIGURATION_GROUPS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108327323.
     */
    GROUPS_REMOVE = 'MOBILE.CONFIGURATION_GROUPS_REMOVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=15926588
     */
    GROUP_REQUIRED = 'SERVER_ERROR_ROLE_USER_OR_FEED_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=464958178
     */
    HOME = 'ADMIN.MOBILE.HOME.FEATURE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470923505
     */
    HOME_DESCRIPTION = 'ADMIN.MOBILE.HOME.FEATURE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502320218
     */
    HOME_PAGE_ABSENCE = 'MOBILE.HOME_PAGE_ABSENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=514207793
     */
    HOME_PAGE_ERROR = 'MOBILE.HOME_PAGE_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=503134479
     */
    HOME_PAGE_MAX_ENTRIES_REACHED = 'MOBILE.HOME_PAGE_MAX_ENTRIES_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502327253
     */
    HOME_PAGE_ADD = 'MOBILE.HOME_PAGE_ADD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502320250
     */
    HOME_PAGE_ADD_MESSAGE = 'MOBILE.HOME_PAGE_ADD_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502554648
     */
    HOME_PAGE_SECTION_TITLE = 'MOBILE.HOME_PAGE_SECTION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502483547
     */
    HOME_PAGE_SECTION_GREETINGS_TITLE = 'MOBILE.HOME_PAGE_SECTION_GREETINGS_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502483554
     */
    HOME_PAGE_SECTION_GREETINGS_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_GREETINGS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502483746
     */
    HOME_PAGE_SECTION_FEED_TITLE = 'MOBILE.HOME_PAGE_SECTION_FEED_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502484722
     */
    HOME_PAGE_SECTION_FEED_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_FEED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502487319
     */
    HOME_PAGE_SECTION_ALERT_TITLE = 'MOBILE.HOME_PAGE_SECTION_ALERT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502487365
     */
    HOME_PAGE_SECTION_ALERT_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_ALERT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505175232
     */
    HOME_PAGE_SECTION_ALERT_EDIT_TITLE = 'MOBILE.HOME_PAGE_SECTION_ALERT_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505175249,
     */
    HOME_PAGE_SECTION_ALERT_EDIT_MESSAGE = 'MOBILE.HOME_PAGE_SECTION_ALERT_EDIT_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502487442
     */
    HOME_PAGE_SECTION_SHORTCUT_TITLE = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502487481
     */
    HOME_PAGE_SECTION_SHORTCUT_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505190655
     */
    HOME_PAGE_SECTION_SHORTCUT_EDIT_TITLE = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505191027
     */
    HOME_PAGE_SECTION_SHORTCUT_EDIT_MESSAGE = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_EDIT_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=521548368
     */
    HOME_PAGE_SECTION_SHORTCUT_PICK = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_PICK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=521552748
     */
    HOME_PAGE_SECTION_SHORTCUT_FEATURE = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_FEATURE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=521552752
     */
    HOME_PAGE_SECTION_SHORTCUT_DIRECTORY = 'MOBILE.HOME_PAGE_SECTION_SHORTCUT_DIRECTORY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502492934
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_TITLE = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502493095
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505279380
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_EDIT_TITLE = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505279441
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_EDIT_MESSAGE = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_EDIT_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510516674
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_COLOR_FIELD_HELPER = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_COLOR_FIELD_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510577022
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_IMAGE_PICKER_HELPER = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_IMAGE_PICKER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=513536356
     */
    HOME_PAGE_SECTION_CALL_TO_ACTION_IMAGE_PICKER_ERROR = 'MOBILE.HOME_PAGE_SECTION_CALL_TO_ACTION_IMAGE_PICKER_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=502493238
     */
    HOME_PAGE_SECTION_CONTENT_STREAM_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_CONTENT_STREAM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505279643
     */
    HOME_PAGE_SECTION_CONTENT_STREAM_EDIT_TITLE = 'MOBILE.HOME_PAGE_SECTION_CONTENT_STREAM_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=505279697
     */
    HOME_PAGE_SECTION_CONTENT_STREAM_EDIT_MESSAGE = 'MOBILE.HOME_PAGE_SECTION_CONTENT_STREAM_EDIT_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=513623587
     */
    HOME_PAGE_SECTION_CONTENT_STREAM_PICK = 'MOBILE.HOME_PAGE_SECTION_CONTENT_STREAM_PICK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546131955
     */
    HOME_PAGE_SECTION_LEARNING_TITLE = 'WIDGET_TYPE_LEARNING-TRAINING-COURSES-IN-PROGRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=546146945
     */
    HOME_PAGE_SECTION_LEARNING_DESCRIPTION = 'MOBILE.HOME_PAGE_SECTION_LEARNING_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232450638
     */
    INVALID_NAME = 'MOBILE.CONFIGURATION_INVALID_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470928630
     */
    LEARNING_DESCRIPTION = 'ADMIN.MOBILE.LEARNING.FEATURE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232450395
     */
    LIMIT_CHARACTER_NUMBER = 'MOBILE.CONFIGURATION_LIMIT_CHARACTER_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830736.
     */
    MAIN_MODULES_CAPTION = 'MOBILE.CONFIGURATION_MAIN_MODULES_CAPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830729.
     */
    MAIN_MODULES_TITLE = 'MOBILE.CONFIGURATION_MAIN_MODULES_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11503482
     */
    MOBILE_SETTINGS_CONTENT_STREAMS = 'ADMIN.NAV.MOBILE_SETTINGS_CONTENT_STREAMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102813149.
     */
    MODAL_CREATE_TITLE = 'MOBILE.CONFIGURATION_MODAL_CREATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109525633.
     */
    MODAL_EDIT_TITLE = 'MOBILE.CONFIGURATION_MODAL_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109138285.
     */
    NAVIGATION_DESCRIPTION = 'MOBILE.CONFIGURATION_NAVIGATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830778.
     */
    NAVIGATION_MODULE_SUBTITLE = 'MOBILE.CONFIGURATION_NAVIGATION_MODULE_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108830756.
     */
    NAVIGATION_MODULE_TITLE = 'MOBILE.CONFIGURATION_NAVIGATION_MODULE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=344040143
     */
    ONLY_HTTPS_MESSAGE = 'MOBILE.ONLY_HTTPS_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=21648724
     */
    PEOPLE = 'BOOKMARKED_MS_APPS_PEOPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835065.
     */
    PEOPLE_DESCRIPTION = 'MOBILE.CONFIGURATION_PEOPLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=349839527
     */
    PERSONAL_FEED_DESCRIPTION = 'MOBILE_CONFIGURATION.PERSONAL_FEED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103143880.
     */
    PRIORITY_HELPER = 'MOBILE.CONFIGURATION_PRIORITY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109857708.
     */
    SAVE_SUCCESS_NOTIFICATION = 'MOBILE.CONFIGURATION_SAVE_SUCCESS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105835067.
     */
    SOCIAL_ADVOCACY_DESCRIPTION = 'MOBILE.CONFIGURATION_SOCIAL_ADVOCACY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102355656.
     */
    TITLE = 'MOBILE.CONFIGURATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103148805.
     */
    TITLE_PLACEHOLDER = 'MOBILE.CONFIGURATION_TITLE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232450584
     */
    RESET_DEFAULT_NAMES = 'MOBILE.CONFIGURATION_RESET_DEFAULT_NAMES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=333721427
     */
    WEB_PAGE_ADD = 'MOBILE.ADD_WEB_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=335883225
     */
    WEB_PAGES = 'MOBILE.WEB_PAGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=342463344
     */
    WEB_PAGE_CREATE_AT_LEAST_ONE = 'MOBILE.WEB_PAGE_CREATE_AT_LEAST_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=342467304
     */
    WEB_PAGE_ABSENCE = 'MOBILE.WEB_PAGE_ABSENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=342467470
     */
    WEB_PAGE_DESCRIPTION = 'MOBILE.WEB_PAGE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=342467511
     */
    WEB_PAGE_ADD_MESSAGE = 'MOBILE.WEB_PAGE_ADD_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11191291
     */
    SELECT_SOURCE_SITE_LABEL = 'ADMIN.CUSTOMER.STREAMS.INSTANCE_LABEL',
}

export enum MOBILE_CONFIGURATION_GALLERIES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179609337
     */
    ADD_GALLERY = 'MOBILE.ADD_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179722631
     */
    GALLERIES_NO_LONGER_AVAILABLE = 'MOBILE.GALLERIES_NO_LONGER_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179724260
     */
    GALLERY_NO_LONGER_AVAILABLE = 'MOBILE.GALLERY_NO_LONGER_AVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179720158
     */
    NO_CONFIGURATION_REQUIRED = 'MOBILE.NO_CONFIGURATION_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179721741
     */
    NO_GALLERY_TITLE = 'MOBILE.NO_GALLERIES_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179722141
     */
    NO_GALLERY_DESCRIPTION = 'MOBILE.NO_GALLERIES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181306014
     */
    PICK_GALLERY = 'MOBILE.PICK_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181838902
     */
    PICK_GALLERY_DIALOG_ERROR = 'MOBILE.PICK_GALLERY_DIALOG_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=181838925
     */
    PICK_GALLERY_ERROR_MESSAGE = 'MOBILE.PICK_GALLERY_ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179613576
     */
    SELECT_A_GALLERY = 'MOBILE.SELECT_GALLERY',
}

export enum MOBILE_CONFIGURATION_SITES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504721664
     */
    ADD_SITE = 'MOBILE.ADD_SITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504716478
     */
    PICK_SITE = 'MOBILE.PICK_SITE',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504722841
     */
    SITES_NO_LONGER_AVAILABLE = 'MOBILE.SITES_NO_LONGER_AVAILABLE',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504723217
     */
    NO_SITES_TITLE = 'MOBILE.NO_SITES_TITLE',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=504733394
     */
    NO_SITES_DESCRIPTION = 'MOBILE.NO_SITES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=507604919
     */
    PICK_SITE_ERROR_MESSAGE = 'MOBILE.PICK_SITE_ERROR_MESSAGE',
}
