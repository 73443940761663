import React, { useMemo } from 'react';

import noop from 'lodash/noop';

import { SearchDocumentType } from '@lumapps/analytics-tracking/types';
import { canUseAskAiFeature } from '@lumapps/ask-ai/ducks/selectors';
import { ASK_AI } from '@lumapps/ask-ai/keys';
import { classnames, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiMagnify } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, IconButton, Theme } from '@lumapps/lumx/react';
import { QuickSearchEntity } from '@lumapps/quick-search/types';
import { addInteractionSuggestion } from '@lumapps/quick-search/utils/suggestionsUtils';
import { useSelector } from '@lumapps/redux/react';
import { sendSearchClickBatchedEvents } from '@lumapps/search/api/analytics';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { MAX_QUERY_LENGTH } from '@lumapps/search/constants/query';
import { useSearch } from '@lumapps/search/hooks/useSearch';
import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';
import { isSearchQueryValid } from '@lumapps/search/utils';
import { useTopStyles } from '@lumapps/style/hooks/useTopStyles';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getDateNowISO } from '@lumapps/utils/date/getDateNowISO';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { SearchBoxCombobox } from '../SearchBoxCombobox';

/**
 * The general namespace for this component.
 *
 * @type {string}
 * @constant
 */
const NAMESPACE = 'searchbox';
const SEARCH_BOX_ID = 'main-search-box';

/**
 * The initial state for the searchbox. It defaults to empty
 * but in case we want to change it in the future, we can pass it as a prop.
 *
 * @type {string}
 * @constant
 */
const INITIAL_SEARCH_QUERY = '';

export interface SearchBoxProps {
    instanceId: string;
    /** True if the autocomplete should be displayed */
    autocompleteEnabled?: boolean;
    /** True if the suggestions are still being loaded. */
    isLoading?: boolean;
    /** The callback to be executed once the user changes the query string. */
    onQueryChange?: (s: string, autocompleteEnabled: boolean) => void;
    /** The callback to be executed once the user wants to perform a search. */
    onSelect?: (suggestion: SearchSuggestion) => void;
    /** String that will be used to initialize the text field. */
    searchQuery?: string;
    /** List of suggestions to be displayed. */
    suggestions?: SearchSuggestion[];
    /** Custom? className */
    className?: string;
    /** should the focus on the searchbox input be forced */
    forceFocus?: boolean;
    /** theme override */
    theme?: Theme;
    /** Custom placeholder */
    placeholder?: string;
    /** should we display the suggestions list? */
    showSuggestions?: boolean;
    /** organisation id */
    isUserConnected: boolean;
    /** are we in mobile display? */
    isMobile?: boolean;
    /** Callback that will be triggered when a delete button is clicked upon. */
    onDeleteSuggestion?: (suggestion: SearchSuggestion) => void;
    /** Callback when the box is focused */
    onFocus?: (isFocus: boolean) => void;
    /** callback when an interaction item is clicked */
    addQuickSearchInteraction?: (item: QuickSearchEntity, searchQuery: string, shouldSendQuery: boolean) => void;
}

/**
 * Renders the Searchbox component for the main navigation.
 */
const SearchBox: React.FC<SearchBoxProps> = ({
    onSelect = noop,
    searchQuery = INITIAL_SEARCH_QUERY,
    onQueryChange = noop,
    suggestions = [],
    isLoading = false,
    className = '',
    forceFocus = false,
    theme,
    placeholder,
    showSuggestions = false,
    onDeleteSuggestion,
    onFocus = noop,
    isUserConnected,
    isMobile = false,
    addQuickSearchInteraction = addInteractionSuggestion,
}) => {
    const { get } = useDataAttributes('search');
    const searchBoxId = useMemo(() => generateUUID(SEARCH_BOX_ID), []);
    /**
     * Basic setup for the searchbox component.
     * - suggestion visibility: manages whether the suggestions should be displayed or not.
     * - was search triggered: allows to control when a search was triggered and trigger searches on certain events.
     * We could have this state managed by Redux, but we will define it here since it is more of
     * a UI related state, and not related to data. Hence, it made more sense to leave it here.
     */
    const { translateKey } = useTranslate();
    const inputRef = React.useRef(null);
    const topStyles = useTopStyles();
    const canUseAskAi = useSelector(canUseAskAiFeature);
    const { hasContextualSearchBox } = useSearch();

    /**
     * Check that the user has the permissions to use AskAi search feature
     * If so, change the default placeholder
     */
    const defaultPlaceholder = canUseAskAi ? translateKey(ASK_AI.ASK_ANYTHING) : translateKey(GLOBAL.SEARCH);

    React.useEffect(() => {
        window.SEARCH_INPUT = inputRef;

        if (forceFocus) {
            setTimeout(() => {
                if (inputRef && inputRef.current) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [forceFocus]);

    /**
     * Upon closing the Autocomplete (a click outside can trigger this for example),
     * we need to close the dropdown, and we can do that by calling `onFocus` and pass `false`.
     */
    const onClose = () => {
        onFocus(false);
    };

    /**
     * This function triggers a search on the site. It sets the search query to the one
     * provided and hides the suggestions (since we have already decided for something to search). It also
     * triggers the callback `onSelect`
     * @param {string} query Search term.
     */
    const triggerSearchQuery = (query: string) => {
        if (isSearchQueryValid(query)) {
            onQueryChange(query);
            onFocus(false);

            /**
             * We scroll to the top of the page since the user could be at the bottom of the page and
             * if search query changes, they wont be able to see the new results, they will need to
             * manually scroll to the top.
             */
            window.scrollTo(0, 0);

            if (onSelect) {
                onSelect({ query });
            }
        }
    };

    /**
     * Function triggered when the value on the text field is changed. It will trigger the `onQueryChange`
     * and enable/disable the suggestions visibility accordingly.
     * @param {string} value Value changed from the text field.
     */
    const onChange = (value: string) => {
        const limitedValue = value.length > MAX_QUERY_LENGTH ? value.slice(0, MAX_QUERY_LENGTH) : value;

        onQueryChange(limitedValue);
        if (value && !showSuggestions) {
            onFocus(true);
        }
    };

    /** Function triggered when a suggestion is selected
     * it will call the tracking endpoint and send an event of type click_action
     * TODO: add search_click and search events in the sent batched events
     * */

    const trackSuggestionsClick = (suggestion: SearchSuggestion) => {
        const { label, item } = suggestion;

        if (item?.url && item?.entityId && item?.entityType === SEARCH_RESULT_TYPES.DIRECTORY_ENTRY) {
            sendSearchClickBatchedEvents({
                clickAction: {
                    targetId: item.entityId,
                    targetType: SearchDocumentType.DIRECTORY_ENTRY,
                    url: item.url,
                    thumbnail: item.thumbnail,
                    title: label,
                },
            });
        }
    };

    /**
     * Function triggered when a suggestion is selected from the list of suggestions. This function will also be called
     * when the ENTER key is pressed while navigating an item
     * @param {Object} suggestion       Suggestion presented on the autocomplete.
     * @param {string} suggestion.query Suggestion text presented on the autocomplete.
     */
    const onSuggestionSelected = (suggestion: SearchSuggestion) => {
        if (
            (suggestion.type === SearchSuggestionType.SUGGESTION ||
                suggestion.type === SearchSuggestionType.INTERACTION) &&
            suggestion.item
        ) {
            trackSuggestionsClick(suggestion);
        }

        if (SearchSuggestionType.INTERACTION === suggestion.type) {
            if (suggestion.item) {
                const { item } = suggestion;

                // Store the item
                addQuickSearchInteraction(
                    {
                        ...item,
                        originSiteId: item.originSiteId || item.siteId,
                        lastUsed: getDateNowISO(),
                    },
                    searchQuery,
                    isUserConnected,
                );
            }
            onFocus(false);
            return;
        }

        if (
            suggestion.type === SearchSuggestionType.HISTORY ||
            /**
             * If a suggestion has an item, the option will redirect to the item
             * itself and we don't want to trigger a search.
             * So we only trigger search if no item is set.
             * */
            (suggestion.type === SearchSuggestionType.SUGGESTION && !suggestion.item) ||
            !suggestion.type
        ) {
            triggerSearchQuery(suggestion.query);
        }
    };

    const autocompleteClassName = classnames(NAMESPACE, className, {
        [`${NAMESPACE}--theme-${theme || topStyles.theme}`]: Boolean(theme) || Boolean(topStyles.theme),
        [`${NAMESPACE}--is-mobile`]: isMobile,
        [`${NAMESPACE}--has-contextual-searchbox`]: hasContextualSearchBox,
    });

    /**
     * Triggered when the form is submitted
     * @param {Object} evt Form submit event
     */
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        triggerSearchQuery(searchQuery);
    };

    return (
        <FlexBox
            as="form"
            role="search"
            onSubmit={handleSubmit}
            orientation="horizontal"
            // eslint-disable-next-line lumapps/no-classname-strings
            className={`${NAMESPACE}__wrapper`}
        >
            <label className={visuallyHidden()} htmlFor={searchBoxId}>
                {placeholder || defaultPlaceholder}
            </label>
            <SearchBoxCombobox
                inputRef={inputRef}
                suggestions={suggestions}
                onDeleteSuggestion={onDeleteSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                isLoading={isLoading}
                triggerSearchQuery={triggerSearchQuery}
                hasContextualSearchBox={hasContextualSearchBox}
                onClose={onClose}
                theme={theme}
                className={autocompleteClassName}
                id={searchBoxId}
                searchQuery={searchQuery || ''}
                placeholder={placeholder || defaultPlaceholder}
                onQueryChange={onChange}
                onFocus={onFocus}
                onOpen={() => {
                    if (!showSuggestions) {
                        onFocus(true);
                    }
                }}
            />
            <IconButton
                theme={theme}
                label={placeholder || defaultPlaceholder}
                emphasis={Emphasis.medium}
                icon={mdiMagnify}
                className={classnames(`${NAMESPACE}__submit-btn`, {
                    [`${NAMESPACE}__submit-btn--is-mobile`]: isMobile,
                })}
                type="submit"
                {...get({ element: 'submit' })}
            />
        </FlexBox>
    );
};

export { SearchBox };
