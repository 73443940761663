import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { VIEW_MODE_ICONS } from '@lumapps/widget-settings/constants';
import { ViewModeType } from '@lumapps/widget-settings/types';

/**
 * Define all global constants for the package
 * */
export const WIDGET_TRAINING_COURSES_IN_PROGRESS_TYPE = 'learning-training-courses-in-progress';

export const DATA_SCOPE = 'learning-training-courses-in-progress';

export enum VIEW_MODE_VARIANTS_VALUES {
    GROUPED = 'grouped',
    UNGROUPED = 'ungrouped',
}

export const VIEW_MODE_LIST_VARIANTS: ViewModeType<string>[] = [
    {
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_GROUPED,
        value: VIEW_MODE_VARIANTS_VALUES.GROUPED,
        icon: VIEW_MODE_ICONS.LIST_GROUP,
    },
    {
        label: WIDGET_BASE.SETTINGS_VIEW_MODE_UNGROUPED,
        value: VIEW_MODE_VARIANTS_VALUES.UNGROUPED,
        icon: VIEW_MODE_ICONS.LIST_UNGROUP,
    },
];
