import { Wrex } from '@lumapps/wrex/types';

import { ALIGNED_IMAGE_WRAPPER } from '../constants';
import { AlignedImageWrapperElement } from '../types';

/** Create a flex container element */
export function createAlignedImageWrapper(
    attributes: Wrex.ElementAttributes<AlignedImageWrapperElement>,
    children: AlignedImageWrapperElement['children'],
): AlignedImageWrapperElement {
    return {
        type: ALIGNED_IMAGE_WRAPPER,
        ...attributes,
        children,
    };
}
