export enum WIDGET_PLAY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162600890
     */
    DISPLAY_METADATA = 'PLAY.DISPLAY_METADATA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162601470
     */
    DISPLAY_INFORMATION = 'PLAY.DISPLAY_INFORMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339600292
     */
    DISPLAY_VIDEO = 'PLAY.DISPLAY_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19639851
     */
    ERROR_WHILE_FETCHING_DATA = 'SA_ANALYTICS_FETCH_DATA_RETRY_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119722998
     */
    MOST_VIEWED = 'PLAY.MOST_VIEWED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164112351
     */
    OVER_THE_PLAYER = 'PLAY.OVER_THE_PLAYER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121486499
     */
    PICKER_DIALOG_CONFIRM = 'WIDGET_PLAY.SETTINGS_PICKER_DIALOG_CONFIRM', // Pick video
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134862475
     */
    PICKER_DIALOG_SEARCH_PLACEHOLDER = 'PLAY.SEARCH_VIDEOS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121482817
     */
    PICKER_DIALOG_TITLE = 'WIDGET_PLAY.SETTINGS_PICKER_DIALOG_TITLE', // Pick a video
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169164968
     */
    PICK_VIDEO_FROM_GALLERY = 'PLAY.PICK_VIDEO_FROM_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339582040
     */
    PUBLISHED_VIDEO_IN_GALLERY = 'PLAY.PUBLISHED_VIDEO_IN_INSTANCE_VIDEO_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=254944146
     */
    PUBLISHED_VIDEO_ONLY_WARNING = 'PLAY.PUBLISHED_VIDEO_ONLY_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108826514
     */
    REPLACE_VIDEO = 'PLAY.VIDEO_UPLOAD_NEW_VERSION_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165294252
     */
    SELECT_VIDEO = 'PLAY.SELECT_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=117690607
     */
    SETTING = 'WIDGET_PLAY.SETTINGS_SETTING', // Pick a video
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13586913
     */
    STARRED = 'FRONT.WIDGET_FILE_LIST.SETTINGS.FOLDER.DRIVE.STARRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121478395
     */
    SUBTITLE = 'WIDGET_PLAY.SETTINGS_SUBTITLE', // Source
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162601499
     */
    UNDER_THE_PLAYER = 'PLAY.UNDER_THE_PLAYER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121482610
     */
    UPLOADER_LABEL = 'WIDGET_PLAY.SETTINGS_UPLOADER_LABEL', // Pick a video
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239339326
     */
    UPLOAD_VIDEO_FROM_COMPUTER = 'PLAY.UPLOAD_VIDEO_FROM_COMPUTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326094822
     */
    UPLOAD_VIDEO_FROM_DRIVE = 'PLAY.UPLOAD_VIDEO_FROM_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164357393
     */
    UNAVAILABLE_LINK = 'PLAY.USERS_UNABLE_ACCESS_VIDEO_DETAIL_PAGE_WARNING',
}
