import { isFeatureEnabled } from '@lumapps/features';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BackOfficeStore } from '@lumapps/redux/types';

import { GROUPS_PERMISSIONS } from '../permissions';
import { Group } from '../types';
import { FeedState } from './types';

export const getFeed = (state: BackOfficeStore) => state.feed || {};

/**
 * Index of user feed.
 */
export const getFeedById = (state: BackOfficeStore) => state.feed?.entities;

/**
 * Get the default "All" group.
 * @deprecated Use `getGroupAll` from `packages/customer/core/ducks/selectors.ts` instead.
 */
export const getAllFeed = createSelector(getFeed, (feed: FeedState) => {
    if (feed?.ALL) {
        const feedAll = feed?.ALL as Group;

        return feedAll;
    }

    return null;
});

/**
 * Get the default "Public" group.
 * @deprecated Use `getGroupPublic` from `packages/customer/core/ducks/selectors.ts` instead.
 */
export const getPublicFeed = createSelector(getFeed, (feed: FeedState) => {
    if (feed?.PUBLIC) {
        const feedPublic = feed?.PUBLIC as Group;

        return feedPublic;
    }

    return null;
});

/** Get an array with the default "All" and "Public" feed ids. */
export const getAllAndPublicFeedIds = createSelector(
    getFeed,
    getAllFeed,
    getPublicFeed,
    (feed, feedAll, feedPublic): Array<string> => {
        if (feed?.FEED_ALL_AND_PUBLIC && feed?.FEED_ALL_AND_PUBLIC.length > 0) {
            return feed?.FEED_ALL_AND_PUBLIC || [];
        }

        const feeds = [];

        if (feedAll) {
            feeds.push(feedAll.id);
        }

        if (feedPublic) {
            feeds.push(feedPublic.id);
        }

        return feeds;
    },
);

export const isGroupsAllowed = isUserAllowed(GROUPS_PERMISSIONS.INSTANCE_EDIT);

export const isDynamicGroupByUserAttributesEnabled = isFeatureEnabled('dynamic-group-by-user-attributes');
