import drop from 'lodash/drop';
import isEmpty from 'lodash/isEmpty';

import { FULL_DATE_SIX_FRACTIONAL_SECONDS, get } from '@lumapps/constants';
import { addLocationOriginToUrl } from '@lumapps/router/utils';
import { TranslationAPI } from '@lumapps/translations';
import type { User } from '@lumapps/user/types';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { getMoment } from '@lumapps/utils/time/getMoment';
import type { UserReference } from '@lumapps/widget-base/types';
import type { BlockEventMetadata } from '@lumapps/widget-event-metadata/type';

import { linkLabelOptions, ORIGINAL_LINK } from './constants';
import type { LinkOption } from './types';

const config = get();

const URL_MAX_LENGTH_TO_DISPLAY = 25;

export function toUser({ userId, fullName }: UserReference): User {
    return { id: userId, fullName, customer: config.customerId } as any;
}

export function toUserReference(user: User): UserReference {
    return {
        email: user.email,
        userId: user.id,
        fullName: getUserFullName(user, config.userLang),
        pictureUrl: getUserProfilePictureUrl(user),
    };
}

export const formatDate = (date: string) => {
    return getMoment()(date).utc().format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
};

export const formatDateToCalendar = (date?: string) => {
    const icalFormat = 'YYYYMMDD[T]HHmmss[Z]';

    // Return current date time if date not provided
    return getMoment()(date).utc().format(icalFormat);
};

export const makeIcalFile = (
    event: Pick<
        BlockEventMetadata,
        | 'attendees'
        | 'intro'
        | 'title'
        | 'eventId'
        | 'startsAt'
        | 'endsAt'
        | 'location'
        | 'organizers'
        | 'externalUrl'
        | 'duration'
    >,
) => {
    const { attendees, intro, title, eventId, startsAt, endsAt, location, organizers, externalUrl } = event;
    const currentUrl = addLocationOriginToUrl(window.location.pathname);

    const iCalText = [
        'BEGIN:VCALENDAR',
        'CALSCALE:GREGORIAN',
        `PRODID:-//${config.instanceName}//${config.userLang.toUpperCase()}`,
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `UID:${eventId}`,
        `DTSTAMP:${formatDateToCalendar()}`,
        `DTSTART:${formatDateToCalendar(startsAt)}`,
        `DTEND:${formatDateToCalendar(endsAt)}`,
        `SUMMARY:${title}`,
        `DESCRIPTION:${intro}`,
        ...(externalUrl && [`CONFERENCE;VALUE=URI:${externalUrl}`]),
        `URL:${currentUrl}`,
        ...(location && [`LOCATION:${location}`]),
        organizers.map((organizer) => {
            return `ORGANIZER;CN=${organizer.fullName}:MAILTO:${organizer.email}`;
        }),
        ...(attendees &&
            attendees.map((attendee) => {
                return `ATTENDEE;ROLE=OPT-PARTICIPANT;PARTSTAT=ACCEPTED;CN=${attendee.fullName}:MAILTO:${attendee.email}`;
            })),
        'END:VEVENT',
        'END:VCALENDAR',
    ].join('\r\n');

    const icalFile = new File([iCalText], 'text/plain');

    const icalFileLink = URL.createObjectURL(icalFile);

    return { icalFile, icalFileLink };
};

/**
 * this function return the list of strings to use in Select component as values (exept "original-link" value).
 */
export const getLinkLabelValues = () => {
    return drop(linkLabelOptions.map((link: LinkOption) => link.value));
};

/**
 * this function return the translatable label to display for a value
 */
export const getLinkLabelAsTranslatable = (value: string) => {
    return linkLabelOptions.filter((link: LinkOption) => link.value === value && link.label)[0].label;
};

/**
 * this function return the icon to disply for a value
 */
export const getLinkLabelIcon = (value: string) => {
    return linkLabelOptions.find((link: LinkOption) => link.value === value && link.icon)?.icon || '';
};

/**
 * this function return a well formated link to display depending on the length of the url in select options
 */
export const getOriginalLinkLabel = (url: string) => {
    if (isEmpty(url)) {
        return '';
    }
    if (url.length > URL_MAX_LENGTH_TO_DISPLAY) {
        return `(${url.slice(0, URL_MAX_LENGTH_TO_DISPLAY)}...)`;
    }
    return `(${url})`;
};

/**
 * this function return the formated (or not) value to display as selected value in Select component.
 */
export const getSelectValueToDisplay = (value: string, url: string, translateKey: TranslationAPI['translateKey']) => {
    if (isEmpty(value)) {
        return value;
    }
    if (value === ORIGINAL_LINK) {
        return `${translateKey(getLinkLabelAsTranslatable(value))} ${getOriginalLinkLabel(url)}`;
    }
    return translateKey(getLinkLabelAsTranslatable(value));
};

/**
 * This function return whether the given userId is in the organizers list.
 */
export const getIsUserOrganizer = (organizers: UserReference[], userId: string) => {
    return !organizers ? false : Boolean(organizers.find((organizer) => organizer.userId === userId));
};
