import { sharePost, unsharePost } from '../../communities/api/communityApi';


/**
 * Triggers an API call to share a post in several communities
 */
export const sharePostInCommunitiesTask = (postId, communities) => new Promise((res, rej) => {
    sharePost(postId, communities)
    .then(response => res(response.data), err =>rej(err))
    .catch(err => rej(err))
})

/**
 * Triggers an API call to unhare a post from a community
 */
export const unsharePostFromCommunityTask = (postId, communityId) => new Promise((res, rej) => {
    unsharePost(postId, communityId)
    .then(response => res(response.data), err => rej(err))
    .catch(err => rej(err))
})