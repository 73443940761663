import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiAutorenew, mdiClose, mdiPencil } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, IconButton, Orientation, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { CLASSNAME } from '../../blocks/LinkPreview';

export interface ActionButtonsProps {
    /** ref to forward to make keyboard navigation easy */
    actionRef?: React.RefObject<any>;
    /** whether we should be able to edit the resource */
    hasEditButton?: boolean;
    /** whether we should be able to replace the resource */
    hasReplaceButton?: boolean;
    /** callback to be triggered when clicking on delete button */
    onDelete: (event: any) => void;
    /** callback to be triggered when clicking on edit button */
    onEdit: () => void;
    /** callback to be triggered when clicking on replace button */
    onReplace: () => void;
}

/**
 * Available actions for LinkPreview in editor
 * @param ActionButtonsProps
 * @returns ActionButtons
 */
export const ActionButtons: React.FC<ActionButtonsProps> = ({
    actionRef,
    hasEditButton = false,
    hasReplaceButton = false,
    onDelete,
    onEdit,
    onReplace,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox orientation={Orientation.horizontal} gap={Size.tiny} className={element('actions')}>
            {hasEditButton && (
                <IconButton
                    emphasis={Emphasis.low}
                    hasBackground
                    onClick={onEdit}
                    ref={actionRef}
                    icon={mdiPencil}
                    label={translateKey(GLOBAL.EDIT)}
                />
            )}

            {hasReplaceButton && (
                <IconButton
                    emphasis={Emphasis.low}
                    hasBackground
                    onClick={onReplace}
                    ref={actionRef}
                    icon={mdiAutorenew}
                    label={translateKey(GLOBAL.REPLACE)}
                />
            )}

            <IconButton
                icon={mdiClose}
                emphasis={Emphasis.low}
                hasBackground
                onClick={onDelete}
                label={translateKey(GLOBAL.DELETE)}
                ref={!hasEditButton ? actionRef : null}
            />
        </FlexBox>
    );
};
