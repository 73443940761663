import { TranslateObject } from '@lumapps/translations';

export enum ViewModeEnum {
    LIST = 'LIST',
    SPOTLIGHT = 'SPOTLIGHT',
}

export type ViewMode = keyof typeof ViewModeEnum;

/**
 * https://api.lumapps.com/docs/output/_schemas/streamconf.html
 * https://api.lumapps.com/docs/output/_schemas/filterfields.html
 */
export interface ContentStream {
    id?: string;
    uid: string;
    label: TranslateObject;
    description?: string;
    filterFields: {
        favoriteSitesOnly: boolean;
        defaultInstanceRelatives: boolean;
        contentTypeIds: string[];
        highlightedOnly: boolean;
        featuredOnly: boolean;
        instanceIds: string[];
        metadataList: [Record<string, string[]>];
        metadataIds: string[];
        categories: string[];
    };
    priority: number;
    isViewLocked: boolean;
    viewMode: ViewMode;
    limit: number;
    feedIds: string[];
    createdAt?: string;
}
