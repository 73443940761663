(function IIFE() {
    'use strict';

    /////////////////////////////

    function FormValidationService(Utils) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if both params match and $pristine.
         *
         * @param  {Object}  srcEl The original element.
         * @param  {Object}  reEl  The repeat of the original element.
         * @return {boolean} If both params match and  $pristine.
         */
        function isElementMatch(srcEl, reEl) {
            var isMatch = false;

            // srcEl and reEl are form elements.
            if (srcEl && reEl) {
                isMatch = (srcEl.$pristine && reEl.$pristine) || srcEl.$modelValue === reEl.$modelValue;
            }

            return isMatch;
        }

        /**
         * Check if form element is pristine and is valid.
         *
         * @param  {Object}  formEl The form element we want to check.
         * @return {boolean} If the form element is pristine or valid.
         */
        function isFormElementValid(formEl) {
            return angular.isDefined(formEl) && (formEl.$pristine || formEl.$valid);
        }

        /**
         * Check if a form element is an URL.
         *
         * @param  {Object}  el A form element.
         * @return {boolean} Is element looks like an URL.
         */
        function isFormElementUrl(el) {
            if (angular.isUndefined(el)) {
                return false;
            }

            if (el.$pristine) {
                return true;
            }

            return angular.isDefined(el.$modelValue) && Utils.findUrlsInString(el.$modelValue).length >= 0;
        }

        /**
         * Checks if form element is a valid email.
         *
         * @param  {Object}  el A form element
         * @return {boolean} If the value is an email.
         */
        function isEmailValid(el) {
            if (angular.isUndefined(el)) {
                return false;
            }

            return el.$pristine || Utils.isEmailValid(el.$modelValue);
        }

        /**
         * Check if form element is a valid phone.
         *
         * @param  {Object}  el A form element.
         * @return {boolean} If value looks like a phone number.
         */
        function isPhoneValid(el) {
            if (angular.isUndefined(el)) {
                return true;
            }

            var phoneRegex = /^\+?[0-9][0-9 ]*$/;

            return el.$pristine || phoneRegex.test(el.$modelValue);
        }

        /**
         * Checks an input value and returns whether it is valid or not.
         *
         * @param  {Object}  el A form element
         * @return {boolean} If value is valid.
         */
        function isInputValid(el) {
            return el && (el.$pristine || el.$valid);
        }

        /**
         * Set form elements to a dirty state.
         *
         * @param {Object} form The form to put in a dirty state.
         */
        function setFormDirty(form) {
            if (angular.isUndefined(form)) {
                return;
            }

            angular.forEach(form, function setItemFormDirty(formItem, propertyName) {
                // If has prop and if property is not a angular property.
                if (propertyName.charAt(0) !== '$') {
                    if (angular.isFunction(formItem.$setDirty)) {
                        formItem.$setDirty();
                    }
                }
            });

            form.$setSubmitted(true);
        }

        /////////////////////////////

        service.isElementMatch = isElementMatch;
        service.isEmailValid = isEmailValid;
        service.isFormElementUrl = isFormElementUrl;
        service.isFormElementValid = isFormElementValid;
        service.isInputValid = isInputValid;
        service.isPhoneValid = isPhoneValid;
        service.setFormDirty = setFormDirty;
    }

    /////////////////////////////

    angular.module('Services').service('FormValidation', FormValidationService);
})();
