import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';

import { CalendarEvent } from '@lumapps/lumx-calendar/types';

import { LegacyCalendar, LegacyCalendarEvent } from '../types';
import { mapLegacyEvent } from './mapLegacyEvent';

/**
 * Extract all events from the legacy calendar API call's
 * then map the events to the new CalendarEvent format.
 * */
export const concatEvents = (
    responses: LegacyCalendarEvent[],
    selectedCalendars: LegacyCalendar[],
    userCalendarColor: string,
    hideConferenceLink: boolean = false,
): CalendarEvent[] =>
    flow(flatten, compact, map(mapLegacyEvent(selectedCalendars, userCalendarColor, hideConferenceLink)))(responses);
