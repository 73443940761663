/* istanbul ignore file */
import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiBullhorn } from '@lumapps/lumx/icons';
import { programsAdminRoute } from '@lumapps/sa-programs/routes';
import { useTranslate } from '@lumapps/translations';

import { SA_NAVIGATION } from '../../../keys';

export interface ProgramsSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const ProgramsSideNavigation = (props: Partial<ProgramsSideNavigationProps>) => {
    const { isAllowed } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(SA_NAVIGATION.PROGRAMS)}
            route={programsAdminRoute}
            icon={mdiBullhorn}
        />
    );
};

ProgramsSideNavigation.displayName = 'SideNavigationItem';

export { ProgramsSideNavigation };
