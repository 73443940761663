import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Tooltip, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import emailDark from '../../assets/images/email-dark.svg';
import emailLight from '../../assets/images/email-light.svg';
import faceBookDark from '../../assets/images/facebook-box-dark.svg';
import faceBookLight from '../../assets/images/facebook-box-light.svg';
import googleCurrentsDark from '../../assets/images/google-currents-dark.svg';
import googleCurrentsLight from '../../assets/images/google-currents-light.svg';
import linkedinDark from '../../assets/images/linkedin-box-dark.svg';
import linkedinLight from '../../assets/images/linkedin-box-light.svg';
import twitterDark from '../../assets/images/twitter-box-dark.svg';
import twitterLight from '../../assets/images/twitter-box-light.svg';
import { WIDGET_SHARE } from '../../keys';
import { ShareLinkProps } from '../../types';

export interface ShareItem {
    linkPrefix: string;
    tooltipKey: string;
    icon?: string;
    image?: { light: string; dark: string };
}

const itemParams: Record<string, ShareItem> = {
    email: {
        linkPrefix: 'mailto:?body=',
        tooltipKey: WIDGET_SHARE.SHARE_ON_EMAIL,
        image: {
            light: emailLight,
            dark: emailDark,
        },
    },

    facebook: {
        linkPrefix: 'https://www.facebook.com/sharer/sharer.php?u=',
        tooltipKey: WIDGET_SHARE.SHARE_FACEBOOK_BTN_TITLE,
        image: {
            light: faceBookLight,
            dark: faceBookDark,
        },
    },

    'google-currents': {
        linkPrefix: 'https://currents.google.com/share?url=',
        tooltipKey: WIDGET_SHARE.SHARE_ON_GOOGLE_CURRENTS,
        image: {
            light: googleCurrentsLight,
            dark: googleCurrentsDark,
        },
    },

    linkedin: {
        linkPrefix: 'https://www.linkedin.com/shareArticle?mini=true&url=',
        tooltipKey: WIDGET_SHARE.SHARE_MODAL_LINKEDIN,
        image: {
            light: linkedinLight,
            dark: linkedinDark,
        },
    },

    twitter: {
        linkPrefix: 'https://twitter.com/home?status=',
        tooltipKey: WIDGET_SHARE.SHARE_MODAL_TWITTER,
        image: {
            light: twitterLight,
            dark: twitterDark,
        },
    },
};

export const CLASSNAME = 'share-link';

export const ShareLink: React.FC<ShareLinkProps> = ({ theme, id }) => {
    const { translateKey } = useTranslate();

    const currentUrl = window.location.href;

    const item = itemParams[id];

    if (item === undefined) {
        return null;
    }

    return (
        <Tooltip label={translateKey(item.tooltipKey)}>
            <a href={`${item.linkPrefix}${currentUrl}`} target="_blank" rel="noreferrer">
                {item.image && (
                    <img
                        // eslint-disable-next-line lumapps/no-manual-bems
                        className={classnames(`${CLASSNAME}--${theme}`, `${CLASSNAME}--${id}`)}
                        style={{ display: 'block' }}
                        width="24px"
                        height="24px"
                        src={item.image[theme || Theme.light]}
                        alt=""
                    />
                )}
            </a>
        </Tooltip>
    );
};
