import React from 'react';

import { FlexBox, SkeletonRectangle, Theme } from '@lumapps/lumx/react';

/**
 *  Press tab to update CLASSNAME, then tab again to fill in Component description
 */
export const FollowButtonSkeleton: React.FC<{ theme?: Theme }> = ({ theme }) => {
    return (
        <FlexBox orientation="horizontal">
            <SkeletonRectangle theme={theme} variant="rounded" width="l" height="m" />
        </FlexBox>
    );
};
