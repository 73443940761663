import React from 'react';

import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { VideoUsageSideNavigation as VideoUsageComponent } from './VideoUsageSideNavigation';

const VideoUsageSideNavigation: React.FC = () => {
    const isAllowed = useSelector(isPlayVideoEnabled);

    return <VideoUsageComponent isAllowed={isAllowed} />;
};

export default VideoUsageSideNavigation;
