import React from 'react';

import { CommunitySearchScopes } from '@lumapps/communities/types';
import { Instance } from '@lumapps/instance/types';
import { BasicFilterOption } from '@lumapps/lumx-filter-and-sort/components/Filters/types';
import { Sorters } from '@lumapps/lumx-filter-and-sort/components/Sorters';
import { Alignment, FlexBox } from '@lumapps/lumx/react';

import {
    CommunityPickerDialogFilters,
    CommunityPickerDialogFiltersProps,
} from '../components/CommunityPickerDialogFilters';

export interface UseCommunityPickerFiltersOptions extends Omit<CommunityPickerDialogFiltersProps, 'setFilters'> {
    /** whether the filters are enabled or not */
    areFiltersEnabled?: boolean;
    /** whether sorting is enabled or not */
    isSortingEnabled?: boolean;
    /** whether searching on multiple sites is enabled or not */
    enableMultipleInstancesSearch?: boolean;
    /** default sorting */
    defaultSort?: string;
}

const ENABLED_FILTERS = ['scope', 'instances'];
const SCOPES: CommunitySearchScopes[] = [];

export interface UseCommunityPickerFilters {
    filters: {
        privacy?: string | undefined;
        instanceId?: string[] | undefined;
        followingOnly?: boolean;
    };
    sortOrder: string[] | undefined;
    component: React.ReactNode;
    resetFilters: () => void;
}

/**
 * Hook to be used in order to configure the Community Picker so that it can display
 * filters and sorting options.
 *
 * @param UseCommunityPickerFiltersOptions
 * @returns UseCommunityPickerFilters
 */
export const useCommunityPickerFilters = ({
    areFiltersEnabled = true,
    isSortingEnabled = true,
    enableMultipleInstancesSearch = true,
    enabledFilters = ENABLED_FILTERS,
    defaultSort,
    scopes = SCOPES,
}: UseCommunityPickerFiltersOptions): UseCommunityPickerFilters => {
    const initialFilters = React.useMemo(
        () => ({
            scope: enabledFilters.includes('scope') ? scopes?.map((s) => ({ id: s })) : undefined,
        }),
        [enabledFilters, scopes],
    );

    const [filters, setFilters] = React.useState<Record<string, any>>(initialFilters);

    const [selectedSort, setSelectedSort] = React.useState(defaultSort);

    return React.useMemo(() => {
        const appliedFilters = areFiltersEnabled
            ? {
                  privacy: filters && filters.privacy ? filters.privacy.id : undefined,
                  instanceId: filters && filters.instances ? filters.instances.map((s: Instance) => s.id) : undefined,
                  followingOnly:
                      filters &&
                      filters.scope &&
                      Boolean(filters.scope.find((s: BasicFilterOption) => s.id === CommunitySearchScopes.FOLLOWING)),
              }
            : {};

        return {
            resetFilters: () => {
                setFilters(initialFilters);
                setSelectedSort(defaultSort);
            },
            filters: appliedFilters,
            sortOrder: isSortingEnabled && selectedSort ? [selectedSort] : undefined,
            component: (
                <>
                    {areFiltersEnabled ? (
                        <CommunityPickerDialogFilters
                            setFilters={setFilters}
                            enabledFilters={
                                !enableMultipleInstancesSearch
                                    ? enabledFilters.filter((filter) => filter !== 'instances')
                                    : enabledFilters
                            }
                            scopes={scopes}
                        />
                    ) : null}

                    {isSortingEnabled ? (
                        <FlexBox marginAuto={Alignment.left}>
                            <Sorters
                                onChange={(sort) => setSelectedSort(sort)}
                                sort={selectedSort}
                                isChangingOrderEnabled={false}
                            />
                        </FlexBox>
                    ) : null}
                </>
            ),
        };
    }, [
        areFiltersEnabled,
        defaultSort,
        enableMultipleInstancesSearch,
        enabledFilters,
        filters,
        initialFilters,
        isSortingEnabled,
        scopes,
        selectedSort,
    ]);
};
