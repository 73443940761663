import { getDitaTextToArray } from '@lumapps/wrex/serialization/dita/utils/getDitaTextToArray';

import { WidgetState } from '../ducks/type';
import { Widget } from '../types';
import { isBlockHtml, isBlockIntro, isBlockStructuredContent, isBlockTitle } from './blockPredicates';

export const getWidgetTranslatableData = (widget: Widget): Partial<WidgetState> => {
    const { header, body, widgetType } = widget;
    const widgetTranslatedData: Partial<WidgetState> = {};

    if (header && header.label) {
        widgetTranslatedData.header = {
            label: header.label,
        };
    }

    if (body && isBlockHtml(body, widgetType) && body.html) {
        widgetTranslatedData.body = { html: body.html } as any;
    }

    if (body && (isBlockTitle(body, widgetType) || isBlockIntro(body, widgetType)) && body.text) {
        widgetTranslatedData.body = { text: body.text } as any;
    }

    if (body && isBlockStructuredContent(body, widgetType) && body.dita) {
        /** To remove after DITA->Slate migration */
        const ditaTextArray = getDitaTextToArray(body.dita);

        const htmlObject = document.createElement('div');

        ditaTextArray?.forEach((value) => {
            const p = document.createElement('p');
            p.textContent = value;

            htmlObject.append(p);
        });
        /** End: To remove after DITA->Slate migration */

        const slateHtml = document.querySelector(
            `[data-id=widget-contribution-${widget.widgetId}] .block-structured-content`,
        )?.innerHTML;

        widgetTranslatedData.body = {
            ditaHtmlValues: htmlObject.innerHTML,
            slateHtml,
            translatedRichText: undefined, // Reset the translation
            ...body,
        } as any;
    }

    return widgetTranslatedData;
};
