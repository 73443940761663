/**
 * @param object - An object of { key: boolean } as { title: true, author: false, views: true }
 * @returns the list of keys that are set to true ==> ['title', 'views']
 */
export const arrayFromObjectOfEnabledValues = <U extends object>(object: U) => {
    return Object.keys(object).filter((item) => object[item as keyof U]) as Array<keyof U>;
};

/**
 * @param {string[]} list - the full list to sort
 * @param {string[]} order - the order
 * @returns the list with the chosen values ordered as specified to the top
 * @example
 * const list = ['en', 'gb', 'ja', 'fr']
 * const order = ['ja', 'en']
 * const result = sortArrayWithSpecifiedOrder(list, order);
 * // returns ['ja', 'en', 'gb', 'fr']
 */
export const sortArrayWithSpecifiedOrder = (list: string[], order: string[]) => {
    const sortedList = order.reduce((all, curr) => {
        if (list?.includes(curr)) {
            all.push(curr);
        }
        return all;
    }, [] as string[]);

    const rest = list?.filter((l) => !order.includes(l));

    return [...sortedList, ...rest];
};
