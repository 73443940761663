import BaseApi from '@lumapps/base-api';
import { learningApi } from '@lumapps/learning/constants';

import { NUMER_OF_CERTIFICATES_TO_QUERY } from '../constants';
import { CertificatesDataType } from '../types';

const certificatesApi = () =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'certificates',
        baseURL: learningApi.URL,
    });

const paths = {
    certificates: '/learning-analytics/v2/certificates',
    downloadCertificate: (certificateIdentifier: string) =>
        `learning-analytics/v2/certificates/${certificateIdentifier}/pdf`,
};

export async function downloadCertificate(
    certificateIdentifier: string,
    certificationDate: string,
    certificationTitle: string,
    firstName: string,
) {
    const response = await certificatesApi().get(paths.downloadCertificate(certificateIdentifier), {
        responseType: 'blob',
    });

    const { data } = response;
    const url = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${certificationTitle} - ${firstName} - ${certificationDate}.pdf`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export async function getCertificates(
    userIdentifier: string,
    offset = 0,
    limit = NUMER_OF_CERTIFICATES_TO_QUERY,
): Promise<CertificatesDataType> {
    const { data } = await certificatesApi().get<CertificatesDataType>(paths.certificates, {
        params: { userIdentifier, offset, limit },
    });

    return data;
}
