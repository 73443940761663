import { ZENDESK_TICKET_STATUSES, ZENDESK_RESULT_TYPES } from '@lumapps/search/constants';
import { ZendeskSearchResult } from '@lumapps/search/types';

import { getMetadataTimestampLabel } from '../ContentMetadata/props';

const props = (result: ZendeskSearchResult, baseProps: any, options: any) => {
    const { translate } = options;
    const { onQuickViewClick } = baseProps;
    const metadataLabel = getMetadataTimestampLabel(
        {
            updateTime: result.updatedAt,
        },
        { useUpdateTime: true, translate },
    );

    return {
        title: result.type === ZENDESK_RESULT_TYPES.TICKET ? `#${result.id} ${result.subject}` : result.subject,
        url: result.url,
        zendeskType: result.type,
        status: result.status as ZENDESK_TICKET_STATUSES,
        metadata: {
            metadata: [metadataLabel],
        },
        position: result.position,
        onQuickViewClick,
    };
};

export { props };
