import React, { Fragment } from 'react';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';
import { SHARE } from '@lumapps/share/keys';
import { Message, Switch, TextField } from '@lumapps/lumx/react';
import { mdiHumanGreeting, mdiLink } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import get from 'lodash/get';

import classNames from 'classnames';

import { customizableSocialNetworksFilter } from '../../../utils';
import { MAX_TWITTER_MESSAGE_LENGTH, SOCIAL_PLATFORMS_CONFIG } from '../../../constants';

export interface SocialNetworksMessagesProps {
    availableSocialNetworks: Array<SocialNetworks>;
    selectedSocialNetworks: Record<SocialNetworks, boolean>;
    useSameMessage: boolean;
    commonMessage: any;
    customizedMessages: any;
    onUseSameMessageChange(value: boolean): void;
    onCustomizedMessagesChange(values: any): void;
    onCommonMessageChange(value: any): void;
}

export const SocialNetworksMessages: React.FC<SocialNetworksMessagesProps> = ({
    availableSocialNetworks,
    selectedSocialNetworks,
    useSameMessage,
    onUseSameMessageChange,
    commonMessage,
    onCustomizedMessagesChange,
    onCommonMessageChange,
    customizedMessages,
}) => {
    const { translateKey } = useTranslate();

    const customizableSocialNetworks = availableSocialNetworks.filter((socialNetwork) => {
        return customizableSocialNetworksFilter(socialNetwork) && selectedSocialNetworks[socialNetwork];
    });

    /**
     * Set sameMessage to false if there is only one or less customizable social network.
     */
    React.useEffect(() => {
        if (customizableSocialNetworks.length <= 1) {
            onUseSameMessageChange(false);
        }
    }, [customizableSocialNetworks.length, onUseSameMessageChange]);

    /**
     * When setting 'useSameMessage' ON
     * set commonMessage with the first customized socialNetwork if empty
     * */
    const handleToggleSameMessage = (newValue: boolean) => {
        if (newValue) {
            const firstCustomizedMessage = get(customizedMessages, `value.${customizableSocialNetworks[0]}`);

            if (!commonMessage.value && firstCustomizedMessage) {
                onCommonMessageChange(firstCustomizedMessage);
            }
            /**
             * When setting 'useSameMessage' OFF
             * set all empty customizable social networks with common message
             * */
        } else if (
            commonMessage.value &&
            customizableSocialNetworks?.some((socialNetwork) => !get(customizedMessages, `value.${socialNetwork}`))
        ) {
            onCustomizedMessagesChange(
                customizableSocialNetworks.reduce(
                    (acc, socialNetwork) => ({
                        ...acc,
                        [socialNetwork]: get(customizedMessages, `value.${socialNetwork}`) || commonMessage.value,
                    }),
                    {},
                ),
            );
        }
        onUseSameMessageChange(newValue);
    };

    return (
        <>
            {customizableSocialNetworks.length > 1 && (
                <Switch
                    checked={useSameMessage}
                    className="shareable-workflow-form__suggested-message-switch"
                    onChange={handleToggleSameMessage}
                >
                    {translateKey('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_SUGGESTED_MESSAGE.TITLE')}
                </Switch>
            )}

            {useSameMessage ? (
                <>
                    <TextField
                        hasError={Boolean(commonMessage.hasError)}
                        icon={mdiHumanGreeting}
                        label={translateKey(
                            'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_SUGGESTED_MESSAGE.PLACEHOLDER',
                        )}
                        value={get(commonMessage, 'value', '')}
                        onChange={(newValue) => onCommonMessageChange(newValue.slice(0, MAX_TWITTER_MESSAGE_LENGTH))}
                    />
                    <div className="shareable-workflow-form__helper mb">
                        {customizableSocialNetworks.includes(SocialNetworks.twitter) && (
                            <p>{translateKey(SHARE.TWITTER_MENTION_LIMITATION)}</p>
                        )}
                        <span className="shareable-workflow-form__counter">
                            {`${get(commonMessage, 'value.length', 0)} / ${MAX_TWITTER_MESSAGE_LENGTH}`}
                        </span>
                        {commonMessage.hasError && commonMessage.errorMessage && (
                            <span className="text-field-error">{translateKey(commonMessage.errorMessage)}</span>
                        )}
                    </div>
                </>
            ) : (
                customizableSocialNetworks.map((socialNetworkId) => {
                    const value = get(customizedMessages, `value.${socialNetworkId}`, '');
                    const socialConfig = SOCIAL_PLATFORMS_CONFIG[socialNetworkId];

                    const onChange = (newValue: string) => {
                        onCustomizedMessagesChange({
                            ...customizedMessages.value,
                            [socialNetworkId]: socialConfig.textLimit
                                ? newValue.slice(0, socialConfig.textLimit)
                                : newValue,
                        });
                    };

                    return (
                        <Fragment key={socialNetworkId}>
                            <TextField
                                className={classNames(
                                    'shareable-workflow-form__text-field',
                                    `shareable-workflow-form__text-field--${socialNetworkId}`,
                                )}
                                hasError={Boolean(customizedMessages.hasError)}
                                icon={socialConfig?.iconBox || mdiLink}
                                label={translateKey(
                                    `FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_SUGGESTED_MESSAGE.PLACEHOLDER_${socialNetworkId.toUpperCase()}`,
                                )}
                                value={value}
                                onChange={onChange}
                            />
                            <div className="shareable-workflow-form__helper">
                                {socialNetworkId === SocialNetworks.twitter && (
                                    <p>{translateKey(SHARE.TWITTER_MENTION_LIMITATION)}</p>
                                )}

                                {socialConfig.textLimit && (
                                    <div className="shareable-workflow-form__counter">
                                        {`${value.length} / ${socialConfig.textLimit}`}
                                    </div>
                                )}
                                {customizedMessages.hasError && customizedMessages.errorMessage && (
                                    <span className="text-field-error">
                                        {translateKey(customizedMessages.errorMessage)}
                                    </span>
                                )}
                            </div>
                        </Fragment>
                    );
                })
            )}
        </>
    );
};
