import { sanitizeHTMLWithDebugScriptsAndStyles } from '@lumapps/utils/hooks/useInjectHTMLWithJS';
import { SCOPE_INFO, ENABLE_ANGULAR_DEBUG_INFO } from '../../../../../config';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function FooterController($scope, Analytics, Style, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the scope info have been added to the element.
         *
         * @type {boolean}
         */
        var _scopeInfoAdded = false;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        vm.getFooterHTML = function() {
            let html = '';
            if (vm.Utils.isDesignerMode()) {
                html = vm.Style.getCurrent(vm.LIST_KEY).properties.footer[vm.Translation.inputCustomerLanguage];
            } else {
                html = vm.Translation.translate(vm.Style.getCurrent('global').properties.footer);
            }
            if (vm.Utils.isSafeModeEnabled()) {
                html = sanitizeHTMLWithDebugScriptsAndStyles(html);
            }
            return html;
        }

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Analytics = Analytics;
        vm.Style = Style;
        vm.Translation = Translation;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Add the scope information to the element when asked for.
         *
         * @param {Event}  evt  The scope info request event
         * @param {string} type The name of the scope info wanted.
         */
        $scope.$on('add-scope-info', function onAddScopeInfoRequest(evt, type) {
            if (type === 'footer' && !_scopeInfoAdded && !ENABLE_ANGULAR_DEBUG_INFO) {
                Utils.addScopeInfo(type, $scope, false, false);
                _scopeInfoAdded = true;
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var scopeInfo = _.get(SCOPE_INFO, 'footer');
            if (!_scopeInfoAdded && !ENABLE_ANGULAR_DEBUG_INFO && angular.isDefinedAndFilled(scopeInfo)) {
                var selector = (angular.isString(scopeInfo)) ? scopeInfo : 'footer';

                Utils.addScopeInfo(selector, $scope, false, false);
                _scopeInfoAdded = true;
            }
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('FooterController', FooterController);
})();
