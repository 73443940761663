(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityMovePostController($rootScope, $scope, Community, LxDialogService, LxNotificationService, Post,
        PostMoveFactory, Translation, Utils) {
        'ngInject';

        var cmp = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the move post dialog.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        cmp.DIALOG_ID = 'community-move-post-dialog';

        /**
         * The community block fields used when picking a community.
         *
         * @type {Array}
         */
        cmp.FIELDS = [
            'members',
            'title',
            'content',
        ];

        /**
         * Indicates if we must consider the user as a community admin in the community selector.
         *
         * @type {boolean}
         * @constant
         * @readonly
         */
        cmp.WHERE_ADMIN = true;

        /**
         * The list key for the list of communities.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        cmp.LIST_KEY = 'community-move-list-key';

        /**
         * The label for the submit button of the community selector.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        cmp.COMMUNITY_SELECTOR_SUBMIT_LABEL = Translation.translate('SUBMIT');

        /**
         * The current community, or the moved post community.
         *
         * @type {Object}
         */
        cmp.currentCommunity = undefined;

        /**
         * Contains the filters for the view.
         *
         * @type {Object}
         */
        cmp.filters = {
            community: undefined,
        };

        /**
         * Contains various indicators regarding the move post controller.
         *
         * @type {Object}
         */
        cmp.is = {
            moving: false,
            searchLoading: false,
        };

        /**
         * The list of communities displayed in the selector.
         *
         * @type {Array}
         */
        cmp.listCommunities = [];

        /**
         * The selected community which will be sent on submit.
         *
         * @type {Object}
         */
        cmp.pendingCommunity = undefined;

        /**
         * The post beeing moved.
         *
         * @type {Object}
         */
        cmp.post = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        cmp.Community = Community;
        cmp.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Move the post to the selected community.
         */
        function _movePost() {
            PostMoveFactory.move({
                communityId: cmp.pendingCommunity.id,
                uid: cmp.post.uid,
            }, undefined, function onMovePostSuccess(response) {
                Post.setCurrent(undefined);

                $rootScope.$broadcast('community__post-moved', response);
                LxNotificationService.success(Translation.translate('POST_MOVE_SUCCESS'));
                LxDialogService.close(cmp.DIALOG_ID);
            }, function onMovePostError(err) {
                Utils.displayServerError(err);
                cmp.is.moving = false;
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Select a community from the list, get it and set it as the community where to post.
         *
         * @param {string} id The id of the community.
         */
        function selectCommunity(id) {
            if (cmp.is.moving) {
                return;
            }

            cmp.is.moving = true;

            Community.get({
                uid: id,
            }, function onCommunityGetSuccess(response) {
                cmp.pendingCommunity = response;
                _movePost();
            }, function onCommunityGetError(err) {
                Utils.displayServerError(err);
                cmp.is.moving = false;
            }, undefined, undefined, false);
        }

        /////////////////////////////

        cmp.debouncedCommunitySearch = Community.debouncedListAvailable;
        cmp.selectCommunity = selectCommunity;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Reset the controller.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The dialog identifier.
         */
        $scope.$on('lx-dialog__close-end', function onMovePostDialogCloseEnd(evt, dialogId) {
            if (dialogId !== cmp.DIALOG_ID) {
                return;
            }

            cmp.is.moving = false;
        });

        /**
         * Launch the initilization of the controller.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The dialog identifier.
         */
        $scope.$on('lx-dialog__open-start', function onMovePostDialogOpenStart(evt, dialogId) {
            if (dialogId !== cmp.DIALOG_ID) {
                return;
            }

            cmp.init();
        });

        /**
         * Initialize the controller.
         */
        cmp.init = function init() {
            cmp.is.moving = false;

            cmp.post = Post.movedPost;
            cmp.currentCommunity = Community.getCurrent() || cmp.post.parentContentDetails;
            cmp.pendingCommunity = undefined;

            Community.listAvailable({
                isCommunityAdmin: cmp.WHERE_ADMIN,
                query: cmp.filters.community,
            }, undefined, undefined, cmp.LIST_KEY);
        };
    }

    /////////////////////////////

    angular.module('Controllers').controller('CommunityMovePostController', CommunityMovePostController);
})();
