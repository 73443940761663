import React from 'react';
import { useSelector } from '@lumapps/redux/react';
import { isSAEnabled } from '@lumapps/sa-connected-user/ducks';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';
import { FlexBox, Orientation } from '@lumapps/lumx/react';

import { ShareContent } from '@lumapps/widget-legacy-meta-data';

import { SAMakeShareableFromContent } from '../SAMakeShareableFromContent';

export interface SAMetaWidgetProps {
    content: any;
}

/**
 * The SA part the meta data widget.
 * TODO : Move to `@lumapps/widget-legacy-meta-data` once the SaMakeShareableFromContent is migrated.
 */
export const SAMetaWidget: React.FC<SAMetaWidgetProps> = ({ content }) => {
    const saEnabled = useSelector(isSAEnabled);

    return (
        <FlexBox orientation={Orientation.horizontal}>
            <ErrorBoundary>
                <ShareContent content={content} />
            </ErrorBoundary>
            {saEnabled && (
                <ErrorBoundary>
                    <SAMakeShareableFromContent contentId={content.id} />
                </ErrorBoundary>
            )}
        </FlexBox>
    );
};
