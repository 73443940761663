export enum LEARNING_EVENTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=558217005
     */
    EMPTY_STATE_HELPER = 'LEARNING_EVENTS.EMPTY_STATE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=558200751
     */
    NO_EVENTS_YET = 'LEARNING_EVENTS.NO_EVENTS_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=558233601
     */
    NUMBER_EVENTS_OUT_OF_MANY = 'LEARNING_EVENTS.NUMBER_EVENTS_OUT_OF_MANY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563447
     */
    PICK_SPACE = 'LEARNING.PICK_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563409
     */
    PICK_TRAINING = 'LEARNING.PICK_TRAINING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563322
     */
    RELATED_TRAINING = 'LEARNING.RELATED_TRAINING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563350
     */
    SCHEDULE_EVENT = 'LEARNING.SCHEDULE_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563260
     */
    SELECT_TRAINING = 'LEARNING.SELECT_TRAINING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563429
     */
    SELECT_TRAINING_ASSOCIATED_TO_EVENT = 'LEARNING.SELECT_TRAINING_ASSOCIATED_TO_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563493
     */
    SELECT_SPACE_TO_HOST_EVENT = 'LEARNING.SELECT_SPACE_TO_HOST_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565563440
     */
    SPACE_HOST = 'LEARNING.SPACE_HOST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=558229142
     */
    TITLE = 'LEARNING_EVENTS.TITLE',
}
