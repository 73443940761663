import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { useViews } from '@lumapps/play/hooks/useViews';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-views` as const;

type ViewsProps = Omit<TextProps, 'as'>;

/**
 * Displays the videos count of a views
 */
export const Views = ({ typography = Typography.body1, props }: ViewsProps) => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, video, theme } = useBlockVideoContext();
    const videoViews = useViews({ views: video?.views || 0 });

    if (!canDisplay('VIEWS')) {
        return null;
    }

    if (isLoading) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="10%" />;
    }

    return (
        <Text {...props} as="span" noWrap>
            {videoViews}
        </Text>
    );
};
