import { IMAGE_ELEMENTS } from '@lumapps/wrex-image/plugin/markdown/serialize';
import { LINK_ELEMENTS } from '@lumapps/wrex-link/plugin/markdown/serialize';
import { LIST_ELEMENTS } from '@lumapps/wrex-list/plugin/markdown/serialize';
import { TABLE_ELEMENTS } from '@lumapps/wrex-table/plugin/markdown/serialize';
import { TYPOGRAPHY_ELEMENTS, TYPOGRAPHY_MARKS } from '@lumapps/wrex-typography/plugin/markdown/serialize';
import { USER_MENTION_ELEMENTS, USER_MENTION_MD_HANDLER } from '@lumapps/wrex-user-mention/plugin/markdown/serialize';

import { SerializeOptions } from './types';

/**
 * Centralized serialization options for each slate plugin.
 */
export const OPTIONS: SerializeOptions = {
    marks: {
        ...TYPOGRAPHY_MARKS,
    },
    elements: {
        ...TYPOGRAPHY_ELEMENTS,
        ...IMAGE_ELEMENTS,
        ...LINK_ELEMENTS,
        ...LIST_ELEMENTS,
        ...TABLE_ELEMENTS,
        ...USER_MENTION_ELEMENTS,
    },
    customElements: {
        ...USER_MENTION_MD_HANDLER,
    },
};
