import React from 'react';

import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';

import { getUserPermissions } from '../api/index';

/**
 * Hook to fetch user permissions (for now only isCommunityAdmin is implemented on back end but other permissions will come).
 */
export const useUserPermissions = (shouldFetchPermissions?: boolean) => {
    /**
     * Permissions fetching management.
     */
    const fetchData = useFetchWithStatus({
        onFetch: getUserPermissions,
    });

    const { fetch, response, status } = fetchData;

    const fetchUserPermissions = React.useCallback(() => {
        fetch();
    }, [fetch]);

    React.useEffect(() => {
        if (shouldFetchPermissions) {
            fetchUserPermissions();
        }
    }, [fetchUserPermissions, shouldFetchPermissions]);

    return {
        fetchUserPermissions,
        getUserPermisionsStatus: status,
        userPermissions: response,
        isCommunityAdmin: Boolean(response?.isCommunityAdmin),
    };
};
