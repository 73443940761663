import partial from 'lodash/partial';

import { Editor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';
import { selectElementOnDelete } from '@lumapps/wrex/slate/utils/selectElementOnDelete';
import { WrexEditor } from '@lumapps/wrex/types';

import { EditableFile } from '../components/editableBlocks/EditableFile';
import { FileEditor, FileEditorOptions } from '../types';
import { insertDriveFile } from '../utils/insertDriveFile';
import { insertFile } from '../utils/insertFile';
import { isFile } from '../utils/isFile';
import { updateFileNode } from '../utils/updateFileNode';

const createPluginEditor =
    (options: FileEditorOptions): CreatePluginEditor<FileEditor, WrexEditor<FileEditor>> =>
    (editor) => {
        const { isVoid, insertData, normalizeNode, deleteBackward, deleteForward } = editor;

        return {
            insertData: (data: DataTransfer) => {
                if (data.files) {
                    const filesArray = Array.from(data.files);

                    // When the dropped files are not all images, insert them as files
                    if (filesArray.some((file) => !file.type.includes('image'))) {
                        for (const file of filesArray) {
                            insertFile(options, editor, {}, file);
                        }
                    }
                }
                insertData(data);
            },

            normalizeNode([node, path]) {
                const pathRef = Editor.pathRef(editor, path);
                normalizeLiftToRootNode(editor, [node, path], isFile);
                const changedPath = pathRef.unref();
                if (changedPath) {
                    normalizeNode([node, changedPath]);
                }
            },

            isVoid: (element) => isFile(element) || isVoid(element),

            deleteBackward(unit) {
                if (unit === 'character' && selectElementOnDelete(editor, false, isFile)) {
                    return;
                }
                deleteBackward(unit);
            },

            deleteForward(unit) {
                if (unit === 'character' && selectElementOnDelete(editor, true, isFile)) {
                    return;
                }
                deleteForward(unit);
            },

            insertFile: partial(insertFile, options, editor),

            insertDriveFile: partial(insertDriveFile, editor),

            updateFileNode: partial(updateFileNode, editor),
        };
    };

export const withFile = (options: FileEditorOptions) =>
    createPlugin({
        createPluginEditor: createPluginEditor(options),
        elements: [EditableFile],
    });
