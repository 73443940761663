import React from 'react';

import { Group } from '@lumapps/groups/types';
import { SegmentDetailsPopover } from '@lumapps/segment/components/SegmentDetailsPopover/SegmentDetailsPopover';
import { SEGMENT_IN_GROUP_PICKER_IDENTIFIER } from '@lumapps/segment/constants';

/**
 * Function determining if an element is a group or segment
 * If it's a segment, will return a tooltip after-element
 * Else, will return null
 */
export const getGroupAfter = (group: Group) => {
    const { functionalInnerId: id } = group;
    if (id?.startsWith(SEGMENT_IN_GROUP_PICKER_IDENTIFIER)) {
        return (
            <SegmentDetailsPopover scope="designer" segmentId={id.slice(SEGMENT_IN_GROUP_PICKER_IDENTIFIER.length)} />
        );
    }
    return null;
};
