/* istanbul ignore file */
import { isReseller } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';
import { isGodSelector } from '@lumapps/user/ducks/selectors';

import { ResellerSideNavigation } from './ResellerSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isGodSelector(state) && isReseller(state),
});

const ConnectedResellerSideNavigation = connect(mapStateToProps)(ResellerSideNavigation);

export { ConnectedResellerSideNavigation as ResellerSideNavigation };
