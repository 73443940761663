import React from 'react';

import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import { mdiPencil, mdiTrashCan } from '@lumapps/lumx/icons';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { Link } from '../../types';
import { LinkDialog } from '../LinkDialog';

export interface LinkActionsProps {
    /** link to edit or delete */
    link: Link;
    /** force default language to a specific one */
    lang?: string;
    /** position of the link in the list of links */
    index: number;
    /** callback for editing a link */
    onEdit: (link: Link, index: number) => void;
    /** callback on deleting a link */
    onDelete: (index: number) => void;
}

/**
 * Displays an actions dropdown in order to either edit or delete the given link.
 * @param LinkActionsProps
 * @returns LinkActions
 */
export const LinkActions: React.FC<LinkActionsProps> = ({ onEdit, link, onDelete, index, lang }) => {
    const { translateKey } = useTranslate();
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <ActionMenu label={translateKey(GLOBAL.ACTIONS)}>
                <ActionMenu.Item icon={mdiPencil} onClick={() => setIsOpen(true)}>
                    {translateKey(GLOBAL.EDIT)}
                </ActionMenu.Item>
                <ActionMenu.Divider />
                <ActionMenu.Item icon={mdiTrashCan} onClick={() => onDelete(index)}>
                    {translateKey(GLOBAL.DELETE)}
                </ActionMenu.Item>
            </ActionMenu>

            <LinkDialog
                lang={lang}
                link={link}
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                onSubmit={(editedLink: Link) => {
                    onEdit(editedLink, index);
                    setIsOpen(false);
                }}
            />
        </>
    );
};
