import React from 'react';

interface Params {
    /** Delay (in ms) when setting the value to `true`  */
    trueDelay?: number;
    /** Delay (in ms) when setting the value to `false`  */
    falseDelay?: number;
}

type Output = [
    /** The boolean value */
    value: boolean,
    /** Set boolean value (delayed) */
    setDelayedValue: (newValue: boolean, callback?: () => void) => void,
    /** Set boolean value (instant, clear all existing timers) */
    setValue: (newValue: boolean) => void,
];

/**
 * Utility hook to create a delayed boolean state
 *
 * @example
 *      const [isHovered, setDelayedHovered] = useDelayedBooleanState(false, { trueDelay: 100, falseDelay: 0 });
 *      // Calling `setDelayedHovered(true)` will set `isHovered` to `true` after the given `trueDelay`.
 *      // Calling `setDelayedHovered(false)` will set `isHovered` to `false` after the given `falseDelay`.
 */
export const useDelayedBooleanState = (
    defaultValue: boolean,
    { trueDelay = 0, falseDelay = 0 }: Params = {},
): Output => {
    const [value, setValue] = React.useState(defaultValue);

    // Index of delays by value
    const delaysRef = React.useRef<Map<boolean, number>>();
    React.useEffect(() => {
        delaysRef.current = new Map([
            [true, trueDelay],
            [false, falseDelay],
        ]);
    }, [falseDelay, trueDelay]);

    const { setDelayedValue, setInstantValue } = React.useMemo(() => {
        // Index of timers by value
        const timers = new Map<boolean, number>();

        return {
            setDelayedValue(newValue: boolean, callback?: () => void) {
                // If timer for the complementary value is running => clear timer
                const compValue = !newValue;
                const otherTimer = timers.get(compValue);
                if (otherTimer) {
                    clearTimeout(otherTimer);
                    timers.delete(compValue);
                }

                // Skip if we already are waiting
                if (timers.has(newValue)) {
                    return;
                }

                // Set value after a delay
                const delay = delaysRef.current?.get(newValue);
                const timer = setTimeout(() => {
                    setValue(newValue);
                    timers.delete(newValue);
                    callback?.();
                }, delay);
                timers.set(newValue, timer);
            },
            setInstantValue(newValue: boolean) {
                // Clear all timers
                for (const [timerKey, timer] of timers.entries()) {
                    clearTimeout(timer);
                    timers.delete(timerKey);
                }
                // Set value instantly
                setValue(newValue);
            },
        };
    }, []);

    return [value, setDelayedValue, setInstantValue];
};
