import produce from 'immer';

import {
    MANAGE_FOLLOWING_LOADING,
    MANAGE_FOLLOWING_SUCCESS,
    MANAGE_FOLLOWING_ERROR,
} from '../actions/community_actions';

// Default state.
const defaultState = { errorCode: undefined, followerFeedKey: undefined, isLoading: false };

const communityReducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case MANAGE_FOLLOWING_LOADING:
                draft.isLoading = true;

                break;
            case MANAGE_FOLLOWING_SUCCESS:
                draft.isLoading = false;
                draft.isFollowing = action.payload.followerFeedKey;

                break;
            case MANAGE_FOLLOWING_ERROR:
                draft.errorCode = action.payload.errorCode;
                draft.isLoading = false;
                draft.errorMessage = action.payload.errorMessage;

                break;

            default:
        }

        return draft;
    });

export { communityReducer };
