import first from 'lodash/first';
import includes from 'lodash/includes';
import map from 'lodash/map';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

function UploaderController($element, $scope, Config, Upload, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the progress of the uploading of the file.
     *
     * @type {Object}
     */
    vm.progress = {
        current: 0,
        max: 100,
    };

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.Upload = Upload;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the file types are allowed in the uploader.
     *
     * @param  {Array}   fileTypes The file types we want to check.
     * @return {boolean} Whether the file types are allowed or not.
     */
    function areAllowedFileType(fileTypes) {
        return angular.equals(vm.allowedFileTypes, fileTypes);
    }

    /**
     * Get the file icon according to allowed file types.
     *
     * @return {string} The icon name (to use with material design icons).
     */
    function getIcon() {
        if (angular.isDefinedAndFilled(vm.icon)) {
            return vm.icon;
        }

        if (angular.isUndefinedOrEmpty(vm.allowedFileTypes)) {
            return 'cloud-upload';
        }

        if (
            vm.areAllowedFileType(Config.FILE_TYPES.IMAGE) ||
            includes(first(vm.allowedFileTypes), first(Config.FILE_TYPES.IMAGE))
        ) {
            return 'file-image';
        }

        if (vm.areAllowedFileType(Config.FILE_TYPES.DOCUMENT) || vm.areAllowedFileType(Config.FILE_TYPES.XML)) {
            return 'file-document-box';
        }

        return '';
    }

    /**
     * Get accepted file format according to allowed file types.
     *
     * @return {string} The accepted type.
     */
    function getAcceptedType() {
        if (angular.isUndefinedOrEmpty(vm.allowedFileTypes)) {
            return '';
        }

        if (vm.areAllowedFileType(Config.FILE_TYPES.IMAGE)) {
            return 'image/*';
        }

        if (vm.allowedFileTypes.length === 1) {
            return first(vm.allowedFileTypes);
        }

        return '';
    }

    /**
     * Check if it is a document type uploader.
     *
     * @return {boolean} Whether or not we upload using document endpoint.
     */
    function isDocumentType() {
        return vm.type === Upload.DOCUMENT_TYPE;
    }

    /**
     * Launch the input file picker to update the content thumbnail.
     */
    function openInputFile() {
        if (vm.ngDisabled) {
            return;
        }

        $element.find('.uploader__file-input')                
            .on('click', (evt) => {
                // Avoid infinite loop on featured image widget in basic mode.
                evt.stopPropagation();
            })
            .trigger('click');

    }

    /////////////////////////////

    vm.areAllowedFileType = areAllowedFileType;
    vm.getAcceptedType = getAcceptedType;
    vm.getIcon = getIcon;
    vm.isDocumentType = isDocumentType;
    vm.openInputFile = openInputFile;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.multiple = angular.isUndefined(vm.multiple) ? false : vm.multiple;

        if (angular.isUndefinedOrEmpty(vm.uuid)) {
            vm.uuid = `uploader-${generateUUID()}`;
        }

        if (vm.isDocumentType()) {
            return;
        }
        Upload.loadUploadUrl();
    }

    init();
}

/**
 * A directive to allow a simple upload of a file (opens the system file browser).
 *
 * @param {Array}    [allowedFileTypes] Contains the type of files we want to allow to be uploaded.
 * @param {Function} callback           A function to execute when the upload of the file(s) is successful.
 * @param {boolean}  [isUploading]      Indicates if the upload is still in progress or not.
 * @param {string}   label              The text to display inside the directive.
 * @param {boolean}  [multiple=false]   Indicates if we want to upload one or more files.
 * @param {boolean}  [ngDisabled]       Indicates if the uploader is disabled or not.
 * @param {string}   [size]             The size to apply to the uploaded image in the backend.
 * @param {string}   uuid               The identifier of the uploader.
 */

function UploaderDirective($q, $timeout, Translation, Upload, Utils) {
    'ngInject';

    // eslint-disable-next-line require-jsdoc
    function link(scope, el) {
        /**
         * Get uploader input element.
         * @return {Element} Input element.
         */
        function _getInput() {
            return el.find('.uploader__file-input');
        }

        /**
         * Execute an upload.
         * @param {Array}  files  List of files to upload.
         * @param {Object} params Request parameters.
         */
        function _executeUpload(files, params) {
            Upload.uploadMultiple(
                files,
                params,
                (result) => {
                    scope.vm.callback()(result, scope.vm.uuid, files);
                    scope.vm.isUploading = false;
                },
                (err) => {
                    scope.vm.isUploading = false;
                    const input = _getInput();
                    angular.element(input).val(undefined);

                    Utils.displayServerError(err);
                },
                scope.vm.uuid,
                !scope.vm.isDocumentType(),
            );
        }

        /**
         * Execute upload on multiple endpoints.
         *
         * @param  {Array}   files List of files to upload.
         * @return {Promise} Upload promise.
         */
        function _executeMultipleEndpointsUpload(files) {
            return Upload.uploadMultipleEndpoints(files, scope.vm.uuid).then(
                (result) => {
                    scope.vm.callback()(result, scope.vm.uuid, files);
                    scope.vm.isUploading = false;
                },
                (err) => {
                    scope.vm.isUploading = false;
                    const input = _getInput();

                    angular.element(input).val(undefined);
                    Utils.displayServerError(err);
                },
            );
        }

        /**
         * Get file upload url.
         *
         * @param  {File}    file File to get upload url.
         * @return {Promise} Request promise.
         */
        async function _getFileUploadUrl(file) {
            const deferred = $q.defer();
            const uploadUrlParameters = {
                fileName: file.name,
                lang: scope.vm.language ? Translation.getLang(scope.vm.language) : Translation.inputLanguage,
                ...(await scope.vm.uploadUrlRequestParameters()),
            };

            Upload.loadUploadUrl(uploadUrlParameters, Upload.DOCUMENT_TYPE, (params) => {
                params.file = file;
                deferred.resolve(params);
            });

            return deferred.promise;
        }

        /**
         * Execute a document upload.
         *
         * @param  {Array}   files List of files to upload.
         * @return {Promise} Documents upload promise.
         */
        function _executeDocumentsUpload(files) {
            return $q.all(map(files, _getFileUploadUrl)).then(_executeMultipleEndpointsUpload);
        }

        $timeout(() => {
            const input = _getInput();

            input.bind('change', (evt) => {
                /*
                 * Don't use `angular.isDefined[AndFilled]` here as this may have unexpected behaviour with files
                 * from file input.
                 */
                if (!evt.target || !evt.target.files || evt.target.files.length === 0) {
                    return;
                }

                $timeout(() => {
                    const params = {};
                    const uploader = scope.vm.isDocumentType() ? _executeDocumentsUpload : _executeUpload;

                    if (angular.isDefinedAndFilled(scope.vm.size)) {
                        params.size = scope.vm.size;
                    }

                    scope.vm.isUploading = true;
                    uploader(evt.target.files, params);
                });
            });
        });
    }

    return {
        bindToController: true,
        controller: UploaderController,
        controllerAs: 'vm',
        link,
        restrict: 'E',
        scope: {
            allowedFileTypes: '<lsAllowedFileTypes',
            // eslint-disable-next-line id-blacklist
            callback: '&lsCallback',
            icon: '@?lsIcon',
            isUploading: '=?lsIsUploading',
            label: '@lsLabel',
            language: '@?lsLanguage', // For the Abstract Picker.
            multiple: '=?lsMultiple',
            ngDisabled: '<?',
            size: '@?lsSize',
            type: '<?lsType',
            uploadUrlRequestParameters: '=?lsUploadUrlRequestParameters',
            uuid: '@lsUuid',
        },
        templateUrl: '/client/common/modules/uploader/views/uploader.html',
    };
}

angular.module('Directives').directive('lsUploader', UploaderDirective);

export { UploaderController, UploaderDirective };
