import { get } from '@lumapps/constants';

const Config = get();

/**
 * Token expiration threshold, determined by the backend
 */
export const TOKEN_EXPIRATION_THRESHOLD = 600000; // 10 minutes in seconds

/**
 * Status code for unauthorized access
 */
export const HTTP_STATUS_UNAUTHORIZED = 401;

/**
 * Default lumapps services base path
 */
export const DEFAULT_BASE_PATH = `${Config.applicationHost}/_ah/api/lumsites`;
