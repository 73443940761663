import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function() {
    /* global angular */
    'use strict'; // jshint ignore:line

    angular
        .module('Widgets')
        .directive('widgetFileManagement', widgetFileManagement);

    function widgetFileManagement()
    {
        'ngInject';

        return {
            restrict: 'E',
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-file-management/views/widget-file-management.html',
            require: ['widgetFileManagement', '^widget'],
            scope:
            {
                widget: '='
            },
            link: link,
            controller: widgetFileManagementController,
            controllerAs: 'vm',
            bindToController: true,
            replace: true
        };

        function link(scope, element, attrs, ctrls)
        {
            ctrls[0].setParentController(ctrls[1]);
        }
    }

    widgetFileManagementController.$inject = ['$scope', '$window', '$timeout', 'DriveFileService', 'DrivePicker',
        'LxNotificationService', 'Translation', 'LxDialogService', 'FormValidation', 'Config', 'Content', 'Utils', 'InitialSettings'];

    function widgetFileManagementController($scope, $window, $timeout, FileApi, DrivePicker, LxNotificationService,
    Translation, LxDialogService, FormValidation, Config, Content, Utils, InitialSettings)
    {
        'ngInject';

        // Private members
        /* jshint validthis:true */
        var vm = this;
        var defaultFolder;
        var defaultMaxResults = 25;
        var trashFolderId; // set to undefined if classic trash

        // Public members
        vm.widgetStyle = {};
        vm.order = {
            reverse: false,
            predicate: 'title'
        };
        vm.form = {};
        vm.isMainFolderExisting = true;
        vm.nameModal = undefined;
        vm.nameModalConfig = {};
        vm.isDrivePickerLoading = false;

        // Keys
        vm.listKey = angular.isDefined(Content.getCurrent()) && angular.isDefined(Content.getCurrent().id) ? 'widget-' + vm.widget.uuid + '-' + Content.getCurrent().id : 'widget-' + vm.widget.uuid + '-' + generateUUID();
        vm.nameFormId = 'generic-name-dialog-' + vm.widget.uuid;

        // Services
        vm.FileApi = FileApi;
        vm.DrivePicker = DrivePicker;
        vm.FormValidation = FormValidation;
        vm.Config = Config;
        vm.Translation = Translation;
        vm.moment = moment;

        //
        // PUBLIC METHODS
        //

        // widget method
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.refreshWidget = refreshWidget;
        vm.setParentController = setParentController;

        // common
        vm.registerForm = registerForm;
        vm.isEmpty = isEmpty;
        vm.onFileClick = onFileClick;
        vm.onFileDoubleClick = onFileDoubleClick;
        vm.orderFiles = orderFiles;
        vm.isOrderActive = isOrderActive;
        vm.seeCurrentFile = seeCurrentFile;
        vm.deleteCurrentFile = deleteCurrentFile;
        vm.downloadSelectedFile = downloadSelectedFile;

        // Form & modal
        vm.openNewFolderConfig = openNewFolderConfig;
        vm.openNewFileConfig = openNewFileConfig;
        vm.openFileCopyConfig = openFileCopyConfig;
        vm.openRenameConfig = openRenameConfig;
        vm.saveNameForm = saveNameForm;

        //
        // WATCHERS
        //

        // Watch dialog close to reset current item
        $scope.$on('lx-dialog__close-end', function()
        {
            vm.nameModal = undefined;
            vm.nameModalConfig = {};
        });

        $scope.$on('widget-file-management-settings', refreshWidget);

        //
        // PRIVATE METHODS
        //

        /**
         * Initialize the controller
         *
         * @param reset
         * @private
         */
        function _init(reset)
        {
            var config;
            var mainFolderId;
            var communityContext = Content.getCurrent() && Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

            if (angular.isDefined(vm.widget.properties))
            {
                mainFolderId = vm.widget.properties.isRootFolder ? 'root' : defaultFolder;

                vm.widgetStyle = {
                    'max-height': vm.widget.properties.maxHeight,
                    'min-height': vm.widget.properties.minHeight,
                };

                // Use community drive folder.
                if (communityContext && vm.widget.properties.currentCommunityDrive) {
                    mainFolderId = _.get(Content.getCurrent(), 'driveFolder.folderId');
                } else {
                    mainFolderId = vm.widget.properties.folderId || mainFolderId;
                }

                trashFolderId = vm.widget.properties.deleteId || undefined;
                config = {
                    initMainFolderErrorCb: _initMainFolderErrorCb,
                    kind: vm.widget.properties.kind || '',
                    maxResults: vm.widget.properties.maxResults || defaultMaxResults,
                    title: vm.widget.properties.folderName,
                    trashFolderId: trashFolderId,
                };
            }
            else
            {
                vm.widget.properties = { classicTrash: true };
            }

                var method = (reset) ? FileApi.reset : FileApi.init;
                method(vm.listKey, mainFolderId, config);
        }

        /**
         * The error callback when failing to initMainFolder
         */
        function _initMainFolderErrorCb()
        {
            vm.isMainFolderExisting = false;
        }

        /**
         * Reset current widget instance
         *
         * @private
         */
        function _reset()
        {
            _init(true);
        }

        /**
         * Open a file even if it's a folder.
         *
         * @private
         * @param {Object} file The file to open
         */
        function _openFile(file)
        {
            if (angular.isDefined(file.link))
            {
                $window.open(file.link, '_blank');
            }
        }

        /**
         * Delete a file by trash or move
         * @param fileId id of the file to delete
         * @private
         */
        function _deleteFile(fileId)
        {
            if (angular.isDefined(fileId))
            {
                var success = function()
                {
                    LxNotificationService.success(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_DELETE_SUCCESS'));
                };
                var error = function ()
                {
                    LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_DELETE_ERROR'));
                };

                if (angular.isUndefined(trashFolderId))
                {
                    FileApi.trash(vm.listKey, fileId, success, error);
                }
                else
                {
                    FileApi.move(vm.listKey, fileId, trashFolderId, success, error);
                }
            }
        }

        /**
         * Insert a new file into the current folder
         *
         * @param name the new file name
         * @param mimeType a google mime-type
         * @private
         */
        function _insertNewFile(name, mimeType)
        {
            if (angular.isDefined(name) && angular.isDefined(mimeType))
            {
                FileApi.insert(vm.listKey, name, mimeType, undefined, function() {}, function()
                {
                    LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_INSERT_FILE_ERROR'));
                });
            }
        }

        /**
         * Create a new folder
         *
         * @param folderName the folder name
         * @param destination new folder parents if not set the current folder is the destination
         * @private
         */
        function _createFolder(folderName, destination)
        {
            FileApi.createFolder(vm.listKey, folderName, destination, function()
            {
                LxNotificationService.success(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_FOLDER_CREATION_SUCCESS'));
            }, function ()
            {
                LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_FOLDER_CREATION_ERROR'));
            });
        }

        /**
         * Duplicate the current file to the current folder
         *
         * @param newTitle the new file title
         * @private
         */
        function _duplicateCurrentFile(newTitle)
        {
            FileApi.duplicate(vm.listKey, FileApi.getSelectedFile(vm.listKey), newTitle, function()
            {
                LxNotificationService.success(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_COPY_SUCCESS'));
            }, function()
            {
                LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_COPY_ERROR'));
            });
        }

        /**
         * Rename the current file
         * @param name
         * @private
         */
        function _renameCurrentFile(name)
        {
            if (angular.isDefined(name))
            {
                FileApi.rename(vm.listKey, FileApi.getSelectedFile(vm.listKey).id, name, function()
                {
                    LxNotificationService.success(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_RENAME_SUCCESS'));
                }, function()
                {
                    LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_RENAME_ERROR'));
                });
            }
        }

        //
        // PUBLIC METHODS
        //

        /**
         * Get widget class
         */
        function getWidgetClass()
        {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (isWidgetEmpty())
            {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode
         * @returns {boolean}
         */
        function isWidgetEmpty()
        {
            return false;
        }

        /**
         * Is widget hidden in reading mode
         * @returns {boolean}
         */
        function isWidgetHidden()
        {
            return false;
        }

        /**
         * Refresh the widget
         *
         * @param {Event}  evt        The original event triggering this method
         * @param {String} widgetUuid The uuid of the widget we want to refresh
         */
        function refreshWidget(evt, widgetUuid)
        {
            if (vm.widget.uuid === widgetUuid)
            {
                _reset();
            }
        }

        /**
         * Set parent controller
         * @param {object} _parentCtrl
         */
        function setParentController(_parentCtrl)
        {
            vm.parentCtrl = _parentCtrl;

            _init();
        }

        /**
         * Register a new form at form init
         * @param scope
         * @param formName
         */
        function registerForm(scope, formName)
        {
            $timeout(function()
            {
                vm.form[formName] = scope && scope[formName] ? scope[formName] : undefined;
            });
        }

        /**
         * Widget is empty in editing mode
         * @returns {Boolean}
         */
        function isEmpty()
        {
            return vm.editingMode();
        }

        /**
         * called when a file is clicked on
         * @param file
         * @returns {Boolean}
         */
        function onFileClick(file)
        {
            FileApi.setSelectedFile(vm.listKey, file);
        }

        /**
         * Called when a file is double clicked on
         * @param file
         */
        function onFileDoubleClick(file)
        {
            if (angular.isDefined(file))
            {
                if (file.isFolder)
                {
                    FileApi.changeFolder(vm.listKey, file.id);
                }
                else
                {
                    _openFile(file);
                }
            }
        }



        /**
         * Change the display order
         * @param predicate the new field to order on
         */
        function orderFiles(predicate)
        {
            if (predicate === vm.order.predicate)
            {
                vm.order.reverse = !vm.order.reverse;
            }
            else
            {
                vm.order.predicate = predicate;
                vm.order.reverse = false;
            }
        }

        /**
         * Check if the order field (predicate) is active
         * @param predicate the name of the field
         * @param reverse if the field should be reverse or not
         * @returns {boolean|*}
         */
        function isOrderActive(predicate, reverse)
        {
            return vm.order.predicate === predicate && reverse;
        }

        /**
         * Open selected file url even if it's a folder.
         */
        function seeCurrentFile()
        {
            _openFile(FileApi.getSelectedFile(vm.listKey));
        }

        /**
         * Move current file to the trash folder or to the specified folder.
         */
        function deleteCurrentFile()
        {
            LxNotificationService.confirm(
                Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_DELETE_CONFIRM'),
                Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_DELETE_CONFIRM_DESCRIPTION'),
                {cancel: Translation.translate('CANCEL'), ok: Translation.translate('OK')},
                function(answer)
                {
                    if (answer)
                    {
                        if (FileApi.isFileSelected(vm.listKey))
                        {
                            _deleteFile(FileApi.getSelectedFile(vm.listKey).id);
                        }
                    }
                });
        }

        /**
         * Download the selected file
         */
        function downloadSelectedFile()
        {
            if (angular.isDefined(FileApi.getSelectedFile(vm.listKey).webContentLink))
            {
                $window.location.href = FileApi.getSelectedFile(vm.listKey).webContentLink;
            }
        }

        /**
         * Called when file are uploaded with the drive picker
         * @param items the uploaded files
         * @param action the user action
         */
        $scope.pickerUploadCallback = function(items, action)
        {
            if (action !== 'loaded')
            {
                $scope.$apply(function()
                {
                    FileApi.setIsPerformingAction(vm.listKey, false);

                    if (angular.isArray(items) && action === 'picked')
                    {
                        FileApi.filter(vm.listKey, FileApi.getCurrentFolderId(vm.listKey));
                    }
                });
            }
        };

        /**
         * Open dialog to create a new folder
         */
        function openNewFolderConfig()
        {
            vm.nameModalConfig.callback = function (modalValues)
            {
                _createFolder(modalValues.name);
            };

            Utils.waitForAndExecute('#' + vm.nameFormId);
        }

        /**
         * Open dialog to create a new file
         * @param fileInfo new file info belong a google mime-type
         */
        function openNewFileConfig(fileInfo)
        {
            vm.nameModal = {
                mimeType: fileInfo.mimeType
            };

            vm.nameModalConfig.callback = function (modalValues)
            {
                _insertNewFile(modalValues.name, modalValues.mimeType);
            };

            Utils.waitForAndExecute('#' + vm.nameFormId);
        }

        /**
         * Open dialog to copy a file
         */
        function openFileCopyConfig()
        {
            var namePrefix = Translation.translate(Config.WIDGET_DRIVE_DEFAULT.copyName) + ' ';
            vm.nameModalConfig.callback = function (modalValues)
            {
                _duplicateCurrentFile(modalValues.name);
            };

            vm.nameModal = {
                name : namePrefix + FileApi.getSelectedFile(vm.listKey).title
            };

            Utils.waitForAndExecute('#' + vm.nameFormId);
        }

        /**
         * Open rename dialog
         */
        function openRenameConfig()
        {
            vm.nameModalConfig.callback = function (modalValues)
            {
                _renameCurrentFile(modalValues.name);
            };

            vm.nameModal = {
                name : FileApi.getSelectedFile(vm.listKey).title
            };

            Utils.waitForAndExecute('#' + vm.nameFormId);
        }

        /**
         * Generic name modal ( use in copy/rename/insert/new folder ... )
         * @param form
         */
        function saveNameForm(form)
        {
            form = form || vm.form.nameForm;

            if (form.$valid)
            {
                LxDialogService.close(vm.nameFormId);
                (vm.nameModalConfig.callback || angular.noop)(vm.nameModal);
            }
            else
            {
                FormValidation.setFormDirty(form);
                LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_NAME_FORM_ERROR'));
            }
        }

        //
        // INITIALIZATION
        //

        _init();
    }
})();
