/* istanbul ignore file */
import { isSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { PlatformGroupsSideNavigation } from './PlatformGroupsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isSuperAdmin(state),
});

const ConnectedPlatformGroupsSideNavigation = connect(mapStateToProps)(PlatformGroupsSideNavigation);

export { ConnectedPlatformGroupsSideNavigation as PlatformGroupsSideNavigation };
