(function IIFE() {
    'use strict';

    /////////////////////////////

    /**
     * An object holding various options related to a user.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    var UserConstant = {
        /**
         * An enum of list identifiers used in the application.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        LIST_KEY: {
            ZENDESK_ASSIGNEE: 'zendesk-assignee',
        },

        /**
         * An enum of projections used throughout the application.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        PROJECTION: {
            /**
             * Default projection when getting the author/writer details.
             */
            AUTHOR_PROJECTION: {
                apiProfile: true,
                customProfile: true,
                email: true,
                firstName: true,
                fullName: true,
                gamification: false,
                id: true,
                lastName: true,
                primaryEmail: true,
                profilePicture: true,
                profilePictureUrl: true,
                profileStatus: true,
                properties: {
                    followAndNotify: true,
                },
                subscription: true,
                uid: true,
            },
            LIST_SIMPLE: {
                items: {
                    email: true,
                    firstName: true,
                    lastName: true,
                },
            },
        },
    };

    /////////////////////////////

    angular.module('Constants').constant('UserConstant', UserConstant);
})();
