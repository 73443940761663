/* eslint-disable lines-around-directive */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetArticleListController(WidgetSettingsConstant, Content, InitialSettings, ReduxStore) {
        'ngInject';

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        /** Whether the widget has no articles results*/
        vm.hasNoResults = false;

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            const widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);
            if (vm.widget.properties.viewModeVariant === 'ungrouped') {
                widgetClass.push('widget--view-mode-variant-ungroup ');
            }
            return widgetClass;
        }

        /**
         * The widget is empty when there is no articles matching the
         * current setup of the widget.
         */
        function isEmpty() {
            const state = ReduxStore.store.getState();
            const widgetStatus = state.widget.entities[vm.widget.uuid].state;

            vm.hasNoResults = widgetStatus === 'empty' ? true : false;

            return vm.hasNoResults;
        }

        /**
         * In designer mode, when the widget is empty, it is visible and displays
         * the widget type.
         * Used by the designer mode.
         */
        function isWidgetEmpty() {
            return vm.parentCtrl.designerMode() && isEmpty();
        }

        /**
         * In reader mode, when the widget is empty, it is not displayed at all
         */
        function isWidgetHidden() {
            return !vm.parentCtrl.designerMode() && isEmpty();
        }

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /**
         * Initialize the widget properties and the controller parameters.
         */
        function _initProperties() {
            const properties = vm.widget.properties;
            const currentContent = Content.getCurrent();
            const isInCommunity = currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY;

            // If there is no display type, we set 'list' or 'current_community' (if in community) by default.
            const defaultDisplayType = isInCommunity
                ? WidgetSettingsConstant.SELECTION_TYPES.CURRENT_COMMUNITY
                : WidgetSettingsConstant.SELECTION_TYPES.LIST;

            properties.displayType = properties.displayType || defaultDisplayType;

            // If there are no community ids set and we are in a community context, we want to set display type on "current community".
            // Meaning the communtyIds array should only contain the current community id.
            properties.communityIds = properties.communityIds || (isInCommunity ? [currentContent.uid] : []);
        }

        function init() {
            _initProperties();
        }

        init();
    }

    function widgetArticleListDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetArticleListController,
            controllerAs: 'vm',
            replace: true,
            require: ['widgetArticleList', '^widget'],
            restrict: 'E',
            link,
            scope: {
                widget: '=',
            },
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-article-list/views/widget-article-list.html',
        };
    }

    window.angular.module('Widgets').directive('widgetArticleList', widgetArticleListDirective);
})();
