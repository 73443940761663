import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiMessageAlertOutline } from '@lumapps/lumx/icons';
import { Button, Dialog, FlexBox, Icon, Size, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import './index.scss';

export interface ThanksDialogProps {
    isOpen: boolean;
    message: string;
    closeModal: () => void;
}

const CLASSNAME = 'thanks-dialog';

/**
 * Dialog that displays a message thanking the user for a specific action.
 *
 * @family Dialog
 * @param ThanksDialogProps
 * @returns ThanksDialog
 */
export const ThanksDialog: React.FC<ThanksDialogProps> = ({ isOpen, message, closeModal }) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <Dialog className={block()} size="tiny" isOpen={isOpen}>
            <FlexBox orientation="vertical" className={element('content-wrapper')} gap="big">
                <Icon icon={mdiMessageAlertOutline} size={Size.xl} hasShape />
                <Text as="p" typography="subtitle1">
                    {message}
                </Text>
                <Button onClick={closeModal}>{translateKey(GLOBAL.OK)}</Button>
            </FlexBox>
        </Dialog>
    );
};
