import React from 'react';

import { FlexBox, Text, ProgressCircular } from '@lumapps/lumx/react';

import type { LinkPreviewMetadata } from '../../types';
import { DefaultPreview, DefaultPreviewProps } from '../DefaultPreview';
import { PreviewError, PreviewErrorProps } from './PreviewError';
import { VideoPreview } from './VideoPreview';
import '../index.scss';
/* eslint-disable import/order */
import { EmbedMapPreview } from './EmbedMapPreview';
import { margin } from '@lumapps/classnames';
/* eslint-enable import/order */

export enum SUPPORTED_PREVIEWS {
    VIDEO = 'video',
    MAP = 'map',
}

export interface LinkPreviewProps {
    /** The preview object. */
    preview: Partial<LinkPreviewMetadata>;
    /** Whether the url preview is loading or not. */
    isPreviewLoading: boolean;
    /** Icon to display on the top left of the thumbnail. If set, onPreviewThumbnailSwitch() is ignored */
    previewThumbnailIcon?: string;
    /** Callback called when the thumbnail switch button is clicked. */
    onPreviewThumbnailSwitch?: () => void;
    /** callback to be executed when the link preview is being edited */
    onEdit?: () => void;
    /** callback to be executed when the preview is being removed */
    onRemove?: () => void;
    /** The error status code of the preview fetch call. */
    previewError?: number;
    /** Function to fetch the preview, used for the retry button. */
    fetchPreview?: () => void;
    /** Function to clear the url on preview delete. */
    onDelete?: () => void;
    /** The prefix to use on every classname. */
    classNamePrefix?: string;
    /** list of types of previews to render in the component */
    supportedPreviews?: SUPPORTED_PREVIEWS[];
    /** original url that will be displayed in case of error */
    originalUrl?: string;
    /** additional preview error props */
    previewErrorProps?: Partial<PreviewErrorProps>;
    /** props to be passed in into the image displayed for the default preview */
    imgProps?: DefaultPreviewProps['imgProps'];
}

export const getLinkPreview = (props: LinkPreviewProps) => {
    const {
        preview,
        previewThumbnailIcon,
        onPreviewThumbnailSwitch,
        classNamePrefix = 'link-preview',
        supportedPreviews = [SUPPORTED_PREVIEWS.VIDEO, SUPPORTED_PREVIEWS.MAP],
        previewError,
        originalUrl,
        onDelete,
        onEdit,
        onRemove,
        imgProps,
    } = props;

    if (previewError && originalUrl) {
        return (
            <Text as="p" typography="body1" className={margin('bottom', 'regular')}>
                {originalUrl}
            </Text>
        );
    }

    if (preview.url && preview.videoId && supportedPreviews.includes(SUPPORTED_PREVIEWS.VIDEO)) {
        return <VideoPreview title={preview.title} url={preview.url} classNamePrefix={classNamePrefix} />;
    }

    if (preview.url && preview.embedUrl && supportedPreviews.includes(SUPPORTED_PREVIEWS.MAP)) {
        return (
            <EmbedMapPreview
                title={preview.title}
                embedUrl={preview.embedUrl}
                classNamePrefix={classNamePrefix}
                onDelete={onDelete}
            />
        );
    }

    if (preview.url) {
        return (
            <DefaultPreview
                preview={preview}
                classNamePrefix={classNamePrefix}
                previewThumbnailIcon={previewThumbnailIcon}
                onPreviewThumbnailSwitch={onPreviewThumbnailSwitch}
                onEdit={onEdit}
                onRemove={onRemove}
                imgProps={imgProps}
            />
        );
    }

    return null;
};

/**
 * Reusable component for displaying a preview of a provided link.
 *
 * @family Blocks
 * @param props LinkPreviewProps
 * @returns LinkPreview
 */
export const LinkPreview: React.FC<LinkPreviewProps> = (props) => {
    const { isPreviewLoading, previewError, fetchPreview, previewErrorProps = {} } = props;

    if (isPreviewLoading) {
        return (
            <FlexBox vAlign="center">
                <ProgressCircular />
            </FlexBox>
        );
    }

    return (
        <>
            {getLinkPreview(props)}

            {previewError ? <PreviewError error={previewError} onRetry={fetchPreview} {...previewErrorProps} /> : null}
        </>
    );
};
