import { TranslationAPI } from '@lumapps/translations';

import { Notification } from '../types';

export const getEventTitle = (translation: TranslationAPI, notification?: Notification): string => {
    if (notification?.properties?.event_title) {
        return translation.translateObject(notification.properties.event_title) || '';
    }

    return '';
};
