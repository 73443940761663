import { BackgroundStyles, BorderStyles, InnerSpacingStyles } from '@lumapps/widget-styles/types';

import { BLOCK_DEFAULT_MIN_WIDTH, BLOCK_ITEM_MIN_WIDTH } from '../components/Block/constants';
import { ContainerBlockVariant } from '../types';

/**
 * Get block min width
 *
 * Adapts to the block container variant and paddings
 */
export function getBlockMinWidth({
    itemMinWidth,
    blockType,
    variant,
    contentStyles,
    defaultPadding = 0,
}: {
    itemMinWidth?: number;
    blockType?: string;
    variant?: string;
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;
    defaultPadding?: number;
}) {
    let out = itemMinWidth;
    if (!out && blockType) {
        out = BLOCK_ITEM_MIN_WIDTH[blockType];
    }
    if (!out) {
        out = BLOCK_DEFAULT_MIN_WIDTH;
    }

    if (variant === ContainerBlockVariant.ungrouped) {
        const { paddingLeft = defaultPadding, paddingRight = defaultPadding } = contentStyles || {};

        out += paddingLeft + paddingRight;
    }
    return out;
}
