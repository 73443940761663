import { RefreshPosts } from '@lumapps/posts/types';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { getWidgetsOfType } from '@lumapps/widget-base/ducks/selectors';
import { fetchWidgetBlocksById } from '@lumapps/widget-base/ducks/thunks/fetchWidgetBlocksById';
import { fetchWidgetBlocksByProperties } from '@lumapps/widget-base/ducks/thunks/fetchWidgetBlocksByProperties';

export function usePostListsRefresh(): () => void;
export function usePostListsRefresh(refreshOverride: RefreshPosts | undefined): RefreshPosts;
export function usePostListsRefresh(refreshOverride?: RefreshPosts) {
    const dispatch = useDispatch();
    const widgets = useSelector(getWidgetsOfType('community-post-list')) || [];

    if (refreshOverride) {
        return refreshOverride;
    }

    return () => {
        for (const { widgetId, widgetType, legacyWidget, blockResolutionInfo, isMainWidget } of widgets) {
            if (legacyWidget) {
                dispatch(
                    fetchWidgetBlocksByProperties({
                        legacyWidget,
                        params: blockResolutionInfo,
                        isMainWidget: Boolean(isMainWidget),
                    }),
                );
            } else {
                dispatch(
                    fetchWidgetBlocksById({
                        widgetId,
                        widgetType,
                        params: blockResolutionInfo,
                        isMainWidget: Boolean(isMainWidget),
                    }),
                );
            }
        }
    };
}
