import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiMenu } from '@lumapps/lumx/icons';
import { adminMainNavigation } from '@lumapps/navigation-admin/routes';
import { useTranslate } from '@lumapps/translations';

import { NAVIGATION } from '../../keys';

export interface MainNavSideNavigationProps extends Partial<SideNavigationItemProps> {
    canAccessMainNavAdmin: boolean;
}

const MainNavSideNavigation = (props: Partial<MainNavSideNavigationProps>) => {
    const { canAccessMainNavAdmin, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!canAccessMainNavAdmin) {
        return null;
    }

    return (
        <SideNavigationItem
            route={adminMainNavigation()}
            label={translateKey(NAVIGATION.TITLE)}
            icon={mdiMenu}
            {...restOfProps}
        />
    );
};

MainNavSideNavigation.displayName = 'SideNavigationItem';

export { MainNavSideNavigation };
