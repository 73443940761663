import * as React from 'react';
import * as propTypes from 'prop-types';

import compact from 'lodash/compact';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import lodashFind from 'lodash/find';
import map from 'lodash/map';

import * as tabTypes from './types';
import { Navigation } from './Navigation';

/**
 * Renders tabs enabling users to switch between panels.
 *
 * Example:
 *
 *     <Tabs>
 *         <Tab id="a" label="Tab A">
 *             <p>This is the content of "Tab A".</p>
 *         </Tab>
 *         <Tab id="b" label="Tab B">
 *             <p>This is the content of "Tab B".</p>
 *         </Tab>
 *         <Tab id="c" label="Tab C">
 *             <p>This is the content of "Tab C".</p>
 *         </Tab>
 *     </Tabs>
 *
 * Example with states:
 *
 *     export class Component extends React.PureComponent {
 *         constructor(props) {
 *             super(props);
 *             this.state = {
 *                 // Set default active state
 *                 state: props.state || 1,
 *             };
 *             this.onChangeState = (state) => this.setState({ state });
 *         }
 *         render() {
 *             const { state } = this.props;
 *
 *             return (
 *                 <Tabs
 *                     states={[{ label: '1', value: 1 }, { label: '2', value: 2 }]}
 *                     state={state}
 *                     onChangeState={this.onChangeState}
 *                 >
 *                     <Tab id="a" label="Tab A">
 *                         <p>This is the content of "Tab A".</p>
 *                     </Tab>
 *                 </Tabs>
 *             );
 *         }
 *     }
 */
export class Tabs extends React.PureComponent {
    static propTypes = {
        /** List of `<Tab />`. */
        children: propTypes.arrayOf(propTypes.node),
        /** Called when the state changes with `(state)`. */
        onChangeState: propTypes.func,
        /** Currently active state id. */
        state: propTypes.string,
        /** State items. */
        states: tabTypes.items,
        /** Currently active tab id. */
        value: propTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || get(compact(props.children), '0.props.id'),
        };
        this.onChange = (value) => this.setState({ value });
    }

    static getDerivedStateFromProps(props, state) {
        if (!lodashFind(compact(props.children), (child) => child.props.id === state.value)) {
            return {
                value: get(compact(props.children), '0.props.id')
            };
        }

        return null;
    }

    render() {
        const { children, states, state } = this.props;
        const { value } = this.state;
        const content = lodashFind(compact(children), (child) => child.props.id === value);

        return (
            <div className="custom-tabs">
                <div className="custom-tabs__links">
                    <Navigation
                        value={value}
                        onChange={this.onChange}
                        items={map(filter(children), 'props')}
                        className="custom-tabs__tabs"
                        classNameItem="custom-tabs__tab"
                        classNameItemActive="custom-tabs__tab--is-active"
                    />
                    {!isEmpty(states) && (
                        <Navigation
                            value={state}
                            onChange={this.props.onChangeState}
                            items={states}
                            className="custom-tabs__states"
                            classNameItem="custom-tabs__state"
                            classNameItemActive="custom-tabs__state--is-active"
                        />
                    )}
                </div>
                <div className="custom-tabs__panes">
                    {content && React.cloneElement(content, { key: content.props.id })}
                </div>
            </div>
        );
    }
}
