import { fetchTopics } from './tasks';

const DOMAIN = 'SA/TOPICS';

const FETCH_ALL = `${DOMAIN}/FETCH_ALL`;

const FETCH_ALL_SUCCESS = `${DOMAIN}/FETCH_ALL_SUCCESS`;

const FETCH_ALL_ERROR = `${DOMAIN}/FETCH_ALL_ERROR`;

const fetchAllTopics = (programId) => async (dispatch) => {
    dispatch({ type: FETCH_ALL });

    try {
        const topics = await fetchTopics(programId);
        if (topics) {
            dispatch({
                topics,
                type: FETCH_ALL_SUCCESS,
            });
        } else {
            dispatch({
                type: FETCH_ALL_ERROR,
            });
        }
    } catch (exception) {
        dispatch({
            type: FETCH_ALL_ERROR,
        });
    }
};

export { fetchAllTopics, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR, FETCH_ALL };
