export enum COMMUNITIES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=205876213
     */
    ENCODING_MESSAGE = 'PLAY.VIDEO_UPLOAD_PROGRESS_ENCODING_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901109
     */
    INSERT_VIDEO = 'FRONT.INSERT_VIDEO_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169164968
     */
    PICK_FROM_VIDEO_GALLERY = 'PLAY.PICK_VIDEO_FROM_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=246495186
     */
    READY_MESSAGE = 'PLAY.INSERT_VIDEO_READY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326094822
     */
    UPLOAD_VIDEO_FROM_DRIVE = 'PLAY.UPLOAD_VIDEO_FROM_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239339326
     */
    UPLOAD_VIDEO_FROM_COMPUTER = 'PLAY.UPLOAD_VIDEO_FROM_COMPUTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=239333538
     */
    UPLOAD_VIDEO_FROM_DEVICE = 'PLAY.UPLOAD_VIDEO_FROM_DEVICE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111389310
     */
    VIDEO_UPLOADED = 'PLAY.VIDEO_UPLOAD_PROGRESS_UPLOADED',
}
