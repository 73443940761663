import React from 'react';

import { PaginationMode } from '../types';

export type UsePaginationProps = {
    /** total number of pages */
    nbOfPages: number;
    /** the current selected page. Starts at 1 */
    currentPage: number;
    /** number of pages button to display */
    maxNbOfPagesDisplayed: number;
    /** max page value to display */
    maxPage?: number;
    /** either we should display last page or not default false */
    shoudlDisplayLastPage: boolean;
    /** the pagination mode use on the pagination
     * full mode: < 1 2 3 ... 9 > or compact mode: < 1 on 3 > */
    mode?: PaginationMode;
};

/**
 * A page has a label and a value
 */
export type Page = {
    value: number;
    label: string;
};

export const getPagination = ({
    nbOfPages,
    maxNbOfPagesDisplayed,
    currentPage,
    maxPage,
    shoudlDisplayLastPage,
    mode,
}: UsePaginationProps) => {
    if (nbOfPages <= maxNbOfPagesDisplayed || mode === PaginationMode.compact) {
        return [...new Array(nbOfPages)].map((_, index) => ({ value: index, label: `${index + 1}` }));
    }

    const maxToUse = maxPage && maxPage < nbOfPages ? maxPage : nbOfPages;

    // if in middle, should display
    if (currentPage > 1) {
        const nb = Math.round(maxNbOfPagesDisplayed / 2 - 1);
        const accPages: Page[] = [];

        // If we are not on the first page
        if (currentPage - 1 > 1) {
            accPages.push({ value: 0, label: '1' });
        }

        // add separator
        if (currentPage - 1 > 2) {
            accPages.push({ value: -2, label: '...' });
        }
        // add before
        const before = [...new Array(nb)].reduce((acc: Page[], currentValue, index) => {
            acc.unshift({
                value: currentPage - index - 1,
                label: `${currentPage - index}`,
            });

            return acc;
        }, []);
        accPages.push(...before);

        // current + after
        const afterNb = nb + currentPage > maxToUse ? maxToUse - currentPage : nb;
        const after = [...new Array(afterNb)].reduce((acc: Page[], currentValue, index) => {
            const value = currentPage + index;
            if (!maxPage || value < maxPage) {
                acc.push({
                    value,
                    label: `${currentPage + index + 1}`,
                });
            }

            return acc;
        }, []);
        accPages.push(...after);

        /**
         * Should we add an ellipse between the previous item and the last one?
         * ex: 1, 2, 3, ..., 200
         */
        if (nb + currentPage + 1 < maxToUse) {
            accPages.push({ value: -1, label: '...' });
        }

        /**
         * Should add the last page if we are not on it and if it's lower thant the given maxPage
         */
        if (shoudlDisplayLastPage && nb + currentPage + 1 <= maxToUse) {
            accPages.push({ value: maxToUse - 1, label: `${maxToUse}` });
        }

        return accPages;
    }

    const allPages: Page[] = [...new Array(maxNbOfPagesDisplayed - 1)].map((_, index) => ({
        value: index,
        label: `${index + 1}`,
    }));
    allPages.push({ value: -1, label: '...' });

    if (shoudlDisplayLastPage) {
        allPages.push({ value: maxToUse - 1, label: `${maxToUse}` });
    }

    return allPages;
};

export const usePagination = (params: UsePaginationProps) => {
    return React.useMemo(() => {
        return getPagination(params);
    }, [params]);
};
