import { Editor, Node, NodeEntry } from '@lumapps/wrex/slate';

import { isMention } from '../../utils/isMention';
import { unwrapMention } from '../../utils/unwrapMention';

/**
 * Unwrap user mention if its text has been modified.
 */
export function normalizeUserMention(editor: Editor, [node, path]: NodeEntry): boolean {
    if (isMention(node) && node.user?.fullName !== Node.string(node)) {
        unwrapMention(editor, path);
        return true;
    }
    return false;
}
