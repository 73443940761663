import { WidgetPlaylistProperties } from './types';

export const WIDGET_PLAYLIST_TYPE = 'playlist';
export const MAX_VIDEOS_PER_PLAYLIST = 9;

export const DEFAULT_WIDGET_PROPERTIES: WidgetPlaylistProperties = {
    playlist: {
        id: '',
    },
    playlistFieldOrder: ['title'],
    videoItemFieldOrder: ['duration', 'thumbnail', 'title'],
    maxNumber: MAX_VIDEOS_PER_PLAYLIST,
    viewModeVariant: 'grouped',
    itemsPerLine: 3,
    viewMode: 'grid',
    slideshowEnabled: false,
};
