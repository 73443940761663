import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchNode } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { QUOTE_BLOCK } from '../../../constants';
import { createQuote } from '../../../utils/createQuote';

type ElementTypes = typeof QUOTE_BLOCK;

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    [QUOTE_BLOCK]: {
        test: matchNode({ tagName: 'blockquote' }),
        type: QUOTE_BLOCK,
        transform: (_, children: any) => {
            return createQuote(children);
        },
    },
};
