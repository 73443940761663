import { useTopStyles, useChildTopStyles } from './useTopStyles';

export interface UseHeaderStylesProps {
    /** parent instance id */
    parentInstance?: string;
    /** whether the nav inheritance FF is enabled or not */
    isMainNavInheritanceEnabled?: boolean;
}

const useComputedTopStyles = ({ parentInstance, isMainNavInheritanceEnabled }: UseHeaderStylesProps) => {
    /**
     * When the current site has a parent and the main nav inheritance is disabled,
     * the actual style that we need to use is the child styles, not the parent one.
     *
     * Unfortunately this only applies to the new apps env, since the legacy application does not distinguish
     * between child and parent styles.
     *
     * This behaviour is also present in the following files, so keep in mind to change them
     * as well if you change something here:
     * - packages/navigation/components/MainNavigation/index.tsx
     */
    let topStyles = useTopStyles();
    const childTopStyles = useChildTopStyles();

    if (parentInstance && !isMainNavInheritanceEnabled && !childTopStyles.isDefault) {
        topStyles = childTopStyles;
    }

    return topStyles;
};

export { useComputedTopStyles };
