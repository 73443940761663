(function IIFE() {
    'use strict';

    /////////////////////////////

    function RequestButtonController(Community, CommunityRequest, User) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.User = User;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Determines if a user has already requested access to a given community.
         *
         * @param {Object} userId        The identifier of the user to get the request access of.
         * @param {Object} [communityId] The identifier of the community to filter the requests access on.
         */
        function _hasPendingRequest(userId, communityId) {
            communityId = communityId || _.get(Community.getCurrent(), 'uid');

            if (angular.isUndefinedOrEmpty([communityId, userId], 'some')) {
                vm.isRequestPending = false;

                return;
            }

            CommunityRequest.get({
                communityId: communityId,
                requestStatus: "pending",
            }, function onRequestGetSuccess() {
                vm.isRequestPending = true;
            }, undefined, vm.listKey);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Request access to the current community.
         */
        function requestCommunityAccess() {
            var communityId = _.get(vm.entity, 'uid');

            // Do it straight away so we don't have to wait for backend call to finish.
            vm.isRequestPending = true;

            CommunityRequest.createRequest(communityId, undefined, function onSendAccessRequestError() {
                vm.isRequestPending = false;
            });
        }

        /////////////////////////////

        vm.requestCommunityAccess = requestCommunityAccess;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var userId = User.getConnected().uid;
            var communityId = _.get(vm.entity, 'uid');

            vm.listKey = 'community-request-list-' + userId;

            _hasPendingRequest(userId, communityId);
        }

        init();
    }

    function RequestButtonDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: RequestButtonController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                entity: '=lsEntity',
                size: '@?lsSize',
            },
            templateUrl: '/client/front-office/modules/communities/community/views/request-button.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsRequestButton', RequestButtonDirective);
})();
