import React from 'react';

import { Theme } from '@lumapps/lumx/react';
import { BlockPost } from '@lumapps/widget-post-list/components/BlockPost';
import { useBlockPost } from '@lumapps/widget-post-list/hooks/useBlockPost';
import { OrderString } from '@lumapps/widget-post-list/types';

import { Post, RefreshPosts } from '../../../types';

export interface FromLegacyBlockPostProps {
    post: Post;
    className?: string;
    isPreviewOnly?: boolean;
    isPinStatusDisplayed?: boolean;
    isDetailed?: boolean;
    isAlreadySemantic?: boolean;
    theme?: Theme;
    /** The number of lines to show in the preview. */
    visibleLines?: number;
    refreshPosts?: RefreshPosts;
    order?: OrderString[];
}

export const FromLegacyBlockPost = ({
    post,
    className,
    isPreviewOnly,
    isPinStatusDisplayed,
    isDetailed,
    isAlreadySemantic,
    theme,
    visibleLines,
    refreshPosts,
    order,
}: FromLegacyBlockPostProps) => {
    const propsOverrides = order ? { order } : {};

    // Map the necessary info for the new blockPost
    const blockPostProps = useBlockPost(post, propsOverrides);

    const enrichedBlockPostProps = {
        ...blockPostProps,
        refreshLegacyPosts: refreshPosts,
        className,
        isPreviewOnly,
        isPinStatusDisplayed,
        isDetailed,
        isAlreadySemantic,
        visibleLines,
        theme,
    };
    return <BlockPost {...enrichedBlockPostProps} />;
};
