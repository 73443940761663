/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { any, bool, func, string, arrayOf } from 'prop-types';

import { translate as t } from '../../../../../translations';

import TaskItem from '../taskItem/TaskItem';

/**
 * Display the checkedItems section of the MyTasks component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The checkedItems section react element of the MyTasks component.
 */
const CheckedItems = ({ todoItems, toggleDisplayCheckedItems, displayCheckedItems, checkItem, removeTodo, uuid }) => {
    return (
        <div
            className={
                todoItems.filter((item) => !item.isChecked).length > 0
                    ? 'widget-todo__checked-items widget-todo__checked-items--is-pushed'
                    : 'widget-todo__checked-items'
            }
        >
            <a
                className="widget-todo__checked-items-toggle"
                role="button"
                tabIndex={0}
                onClick={() => toggleDisplayCheckedItems()}
                onKeyDown={() => toggleDisplayCheckedItems()}
            >
                {displayCheckedItems && (
                    <>
                        <span>{t('FRONT.TODO.HIDE_COMPLETED_TASKS')}</span>
                        <i className="mdi mdi-chevron-up" />
                    </>
                )}

                {!displayCheckedItems && (
                    <>
                        <span>{t('FRONT.TODO.DISPLAY_COMPLETED_TASKS')}</span>
                        <i className="mdi mdi-chevron-down" />
                    </>
                )}
            </a>

            {displayCheckedItems && (
                <div className="widget-todo__task">
                    {todoItems
                        .filter((item) => item.isChecked)
                        .map((task) => {
                            return (
                                <TaskItem
                                    key={task.id}
                                    checkItem={checkItem}
                                    removeTodo={removeTodo}
                                    task={task}
                                    uuid={uuid}
                                />
                            );
                        })}
                </div>
            )}
        </div>
    );
};

CheckedItems.propTypes = {
    checkItem: func.isRequired,
    displayCheckedItems: bool.isRequired,
    removeTodo: func.isRequired,
    todoItems: arrayOf(any).isRequired,
    toggleDisplayCheckedItems: func.isRequired,
    uuid: string.isRequired,
};

export default CheckedItems;
