import { removeExtension as removeFileExtension } from '@lumapps/utils/file/removeExtension';

/**
 * Override from core/utils function to manage GDCallbacDoc
 * @param {{name: string} | undefined} file
 * @returns {string} file name without extension
 * @example
 *  const file = new File([], 'Chuck norris.mp4');
 *  removeExtension(file); // 'Chuck norris'
 */
export const removeExtension = (file?: { name: string }) => {
    if (!file) {
        return '';
    }
    return removeFileExtension(file as File);
};
