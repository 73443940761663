import { REGULAR_SPACE, JAPANESE_SPACE } from './constants';

/**
 * Trim then smash multiple spaces into one in string.
 *
 * @param  {string} str The string to clean.
 * @return {string} The cleaned string.
 */
export const cleanSpaces = (str = ''): string => {
    return str.trim().replace(/( {2,})/g, ' ');
};

/**
 * Smash multiple regular spaces into one in string.
 *
 * @param  {string} string
 * @return {string} The cleaned string.
 */
export const smashRegularSpaces = (string: string): string => {
    return string.replace(RegExp(`${REGULAR_SPACE}{2,}`, 'g'), REGULAR_SPACE);
};

/**
 * Smash multiple japanese spaces into one in string.
 *
 * @param  {string} string
 * @return {string} The cleaned string.
 */
export const smashJapaneseSpaces = (string: string): string => {
    return string.replace(RegExp(`${JAPANESE_SPACE}{2,}`, 'g'), JAPANESE_SPACE);
};

/**
 * Smash multiple regular spaces into one in string then
 * smash multiple japanese spaces into one in string.
 *
 * @param  {string} string
 * @return {string} The cleaned string.
 */
export const smashAllSpaces = (string: string): string => {
    return smashJapaneseSpaces(smashRegularSpaces(string));
};
