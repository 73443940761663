import { getClosestColorFromPalette } from '@lumapps/utils/color/getClosestColorFromPalette';
import { parseHex } from '@lumapps/utils/color/parseHex';
import { parseRgb } from '@lumapps/utils/color/parseRgb';
import { parseInlineCSS } from '@lumapps/utils/css/parseInlineCSS';
import { and } from '@lumapps/utils/function/predicate/and';
import { not } from '@lumapps/utils/function/predicate/not';
import { or } from '@lumapps/utils/function/predicate/or';
import { HTMLMarkConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchAttribute, matchNode, matchStyle } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { STRIKETHROUGH_CLASS } from '../../../components/blocks/Mark';
import { BOLD, COLORED, ITALIC, STRIKETHROUGH, UNDERLINE } from '../../../constants';
import { isDefaultColor } from '../../../utils/isDefaultColor';

export type MarkTypes = typeof BOLD | typeof ITALIC | typeof STRIKETHROUGH | typeof UNDERLINE | typeof COLORED;

/**
 * Mark convert options.
 *
 * Warning: the order of these options does matter!
 */
export const MARKS: { [Mark in MarkTypes]: HTMLMarkConvertOption<Mark> } = {
    /**
     * Convert HTML bold (in various form) into slate bold mark.
     */
    bold: {
        test: and(
            not(matchAttribute('style', /font-weight\s*:\s*normal/)),
            or(
                matchNode({ tagName: 'b' }),
                matchNode({ tagName: 'strong' }),
                matchAttribute('style', /font-weight\s*:\s*(bold|bolder)/),
                matchAttribute('style', /font-weight\s*:\s*(\d+)/, ([, weight]) => Number(weight) >= 700),
            ),
        ),
        mark: BOLD,
    },

    /**
     * Convert HTML italic (in various form) into slate italic mark.
     */
    italic: {
        test: and(
            not(matchAttribute('style', /font-style\s*:\s*normal/)),
            or(
                matchNode({ tagName: 'i' }),
                matchNode({ tagName: 'em' }),
                matchAttribute('style', /font-style\s*:\s*italic/),
            ),
        ),
        mark: ITALIC,
    },

    /**
     * Convert HTML color (in various form) into slate color mark.
     */
    color: {
        test: matchStyle(
            'color',
            (color: string) => (!!parseHex(color) || !!parseRgb(color, true)) && !isDefaultColor(color),
        ),
        value: ({ style }, { colorPalette }) => {
            const styleObject = parseInlineCSS(style);
            const styleValue = styleObject?.color;

            if (!styleValue) {
                return undefined;
            }

            const textColor = getClosestColorFromPalette(styleValue, colorPalette);

            if (textColor) {
                return textColor;
            }

            return undefined;
        },
        mark: COLORED,
    },

    /**
     * Convert HTML strikethrough (in various form) into slate underline mark.
     */
    strikethrough: {
        test: or(
            matchNode({ tagName: 's' }),
            matchNode({ tagName: 'del' }),
            matchNode({ tagName: 'strike' }),
            matchAttribute('style', /text-decoration\s*:(.*?)line-through/),
            matchAttribute('className', new RegExp(STRIKETHROUGH_CLASS)),
        ),
        mark: STRIKETHROUGH,
    },

    /**
     * Convert HTML underline (in various form) into slate underline mark.
     */
    underline: {
        test: or(
            matchNode({ tagName: 'u' }),
            matchNode({ tagName: 'ins' }),
            matchAttribute('style', /text-decoration\s*:(.*?)underline/),
        ),
        mark: UNDERLINE,
    },
};

/**
 * Get HTML to slate conversion options for the requested marks.
 */
export function getMarkConvertOptions(marks: Array<MarkTypes>) {
    // Pick marks from the constant.
    // Their order should be the same as in the constant above.
    return Object.entries(MARKS)
        .map(([mark, option]) => (marks.includes(mark as any) ? option : undefined))
        .filter(Boolean) as HTMLMarkConvertOption[];
}
