import React, { MouseEvent } from 'react';

import { ChipGroup, Theme } from '@lumapps/lumx/react';

import * as types from '../../types';
import { getSearchTagRoute } from '../../utils/getSearchTagRoute';
import { Tag } from '../Tag';

export interface TagsListProps {
    /** list of tags to display */
    tags: types.Tag[];
    /** Whether the tags are editable or not */
    isEditable?: boolean;
    /** Wheter the tags are clickable or not */
    isClickable?: boolean;
    /** current theme to apply */
    theme?: Theme;
    /** Current filter (tab) id */
    filter?: string;
    /** Callback to be executed when the tag was cleared. */
    onClearTag?: (event: React.MouseEvent, tag: types.Tag) => void;
    /** Callback to be executed when the tag was clicked. */
    onClickTag?: (event: React.MouseEvent, tag: types.Tag) => void;
}

/**
 * Displays a list of tagz
 * */
export const TagList: React.FC<TagsListProps> = ({
    tags,
    isEditable = false,
    isClickable = true,
    onClearTag,
    onClickTag,
    theme,
    filter,
}) => {
    if (!tags || (tags && tags.length === 0)) {
        return null;
    }

    /**
     * Function triggered when one of the selected tags is clicked.
     * @param {Object} evt Event that triggered the function
     * @param {Object} tag Tag clicked.
     */
    const onClick = (evt: MouseEvent, tag: types.Tag) => {
        if (onClickTag) {
            onClickTag(evt, tag);
        }
    };

    return (
        <ChipGroup>
            {tags.map((tag, index) => {
                if (tag && tag.id) {
                    return (
                        <Tag
                            key={tag.id || index}
                            to={
                                isClickable && !isEditable && !onClickTag
                                    ? getSearchTagRoute(tag.id, filter)
                                    : undefined
                            }
                            isEditable={isEditable}
                            tag={{ ...tag, index }}
                            position={index + 1}
                            onClear={onClearTag}
                            onClick={isClickable ? onClick : undefined}
                            theme={theme}
                        />
                    );
                }

                return null;
            })}
        </ChipGroup>
    );
};
