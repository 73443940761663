import { useMemo, useState } from 'react';

import isInteger from 'lodash/isInteger';

import { CalendarConfig, CalendarViews } from '@lumapps/lumx-calendar/types';
import { getDayFromDate } from '@lumapps/utils/date/getDayFromDate';

import { CALENDAR_VIEW } from '../constants';
import { LegacyCalendar, LegacyCalendarProperties, ListEventParams } from '../types';
import { getFetchingDates } from '../utils/getFetchingDates';
import { getFetchParamsList } from '../utils/getFetchParamsList';
import { useQueryCalendarEvents } from './useQueryCalendarEvents';

export const useCalendarWidget = (
    calendarConfig: CalendarConfig,
    legacyProperties: LegacyCalendarProperties,
    selectedCalendars: LegacyCalendar[],
) => {
    const { visualizationMode, hideConferenceLink, userCalendarColor, number } = legacyProperties;
    const [date, setDate] = useState(getDayFromDate(new Date(Date.now())));
    const [view, setView] = useState((visualizationMode && CALENDAR_VIEW[visualizationMode]) ?? CalendarViews.week);

    /**
     * Define the maximum number of events to be pulled by each request.
     * By default, we're pulling 30 in planning view and 500 for the other
     * If the "number" property is filled, we're using it.
     * */
    const maxResults = useMemo(
        () => (view === CalendarViews.schedule ? (isInteger(number) && number) || 30 : 500),
        [number, view],
    );

    /**
     * Callback when the view has been changed from within the Calendar component.
     * In the `CalendarViews.schedule` case, we want to reset the date to the current day.
     * */
    const handleViewChange = async (newView: CalendarViews) => {
        const today = getDayFromDate(new Date(Date.now()));
        if (newView === CalendarViews.schedule && date !== today) {
            setDate(today);
        }
        setView(newView);
    };

    /**
     * Extract the fetching params for each available calendar's.
     * Those params will then be used in the `useQueries` hook for fetching events.
     * */
    const fetchParams: ListEventParams[] = useMemo(() => {
        /**
         * Get the date range from which we want to extract the events.
         * */
        const dateRange = getFetchingDates(date, view, calendarConfig);

        return getFetchParamsList(selectedCalendars, dateRange, maxResults);
    }, [calendarConfig, date, maxResults, selectedCalendars, view]);

    /**
     * Fetch calendar events
     * */
    const { isLoading, isFetchingNextPage, loadMore, data } = useQueryCalendarEvents(
        fetchParams,
        selectedCalendars,
        view,
        userCalendarColor,
        maxResults,
        hideConferenceLink,
    );

    return {
        date,
        view,
        events: data,
        handleViewChange,
        handleDateChange: setDate,
        isLoading,
        isFetchingNextPage,
        loadMore,
    };
};
