import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { LastNotification, NotificationCenter } from '../types';

const notificationCenterSelector = (state: FrontOfficeStore): NotificationCenter => state.notificationCenter || {};

const notificationGroupsSelector = createSelector(notificationCenterSelector, (notificationCenter: any) => {
    return notificationCenter.notificationGroups || [];
});

const unreadNotificationsCountSelector = createSelector(notificationCenterSelector, (notificationCenter) => {
    return notificationCenter.unreadNotificationsCount;
});

const hasNotificationsSelector = createSelector(notificationCenterSelector, (notificationCenter) => {
    return Boolean(notificationCenter?.notificationGroups?.length > 0);
});

const lastNotificationSelector = createSelector(notificationCenterSelector, (notificationCenter): LastNotification => {
    return notificationCenter.lastNotification;
});

export {
    notificationCenterSelector,
    notificationGroupsSelector,
    unreadNotificationsCountSelector,
    hasNotificationsSelector,
    lastNotificationSelector,
};
