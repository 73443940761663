/* eslint-disable id-blacklist */
/* eslint-disable import/unambiguous */
import { error } from 'components/services/notification';
import { translate as t } from 'components/translations';

import {
    getCommunitiesTask,
    getPeopleTask,
    getSharedDocumentsTask,
    getTrendingDocumentsTask,
    getUsedDocumentsTask,
} from './delve_tasks';

const INIT_STATE = 'delve/INIT_STATE';
const SET_DOCUMENTS = 'delve/SET_DOCUMENTS';
const SET_ISLOADING = 'delve/SET_ISLOADING';
const SET_PEOPLE = 'delve/SET_PEOPLE';
const SET_CURRENTLY_OPENED_POPOVER = 'delve/SET_CURRENTLY_OPENED_POPOVER';

/**
 * Task action for retrieving the most relevant communities.
 *
 * @param  {number}   maxResults          The maximum result of the api request.
 * @param  {number}   maxResultsToDisplay The maximum items to display.
 * @param  {string}   uuid                The current widget uuid.
 * @return {Function} The task logic.
 */
const getCommunities = (maxResults, maxResultsToDisplay, uuid) => (dispatch) => {
    dispatch({
        payload: {
            data: true,
            uuid,
        },
        type: SET_ISLOADING,
    });

    dispatch({
        payload: {
            data: [],
            uuid,
        },
        type: SET_DOCUMENTS,
    });

    return getCommunitiesTask(maxResults, maxResultsToDisplay)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_PEOPLE,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_DELVE.ERROR.FETCH_COMMUNITIES_ERROR')))
        .finally(() => {
            dispatch({
                payload: {
                    data: false,
                    uuid,
                },
                type: SET_ISLOADING,
            });
        });
};

/**
 * Task action for retrieving the most relevant people.
 *
 * @param  {number}   maxResults          The maximum result of the api request.
 * @param  {number}   maxResultsToDisplay The maximum items to display.
 * @param  {string}   uuid                The current widget uuid.
 * @return {Function} The task logic.
 */
const getPeople = (maxResults, maxResultsToDisplay, uuid) => (dispatch) => {
    dispatch({
        payload: {
            data: true,
            uuid,
        },
        type: SET_ISLOADING,
    });

    dispatch({
        payload: {
            data: [],
            uuid,
        },
        type: SET_DOCUMENTS,
    });

    return getPeopleTask(maxResults, maxResultsToDisplay)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_PEOPLE,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_DELVE.ERROR.FETCH_PEOPLE_ERROR')))
        .finally(() => {
            dispatch({
                payload: {
                    data: false,
                    uuid,
                },
                type: SET_ISLOADING,
            });
        });
};

/**
 * Task action for retrieving most Shared documents.
 *
 * @param  {number}   maxResultsToDisplay The maximum items to display.
 * @param  {string}   uuid                The current widget uuid.
 * @return {Function} The task logic.
 */
const getSharedDocuments = (maxResultsToDisplay, uuid) => (dispatch) => {
    dispatch({
        payload: {
            data: true,
            uuid,
        },
        type: SET_ISLOADING,
    });

    dispatch({
        payload: {
            data: [],
            uuid,
        },
        type: SET_PEOPLE,
    });

    return getSharedDocumentsTask(maxResultsToDisplay)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_DOCUMENTS,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_DELVE.ERROR.FETCH_DOCUMENTS_ERROR')))
        .finally(() => {
            dispatch({
                payload: {
                    data: false,
                    uuid,
                },
                type: SET_ISLOADING,
            });
        });
};

/**
 * Task action for retrieving most trending documents.
 *
 * @param  {number}   maxResultsToDisplay The maximum items to display.
 * @param  {string}   uuid                The current widget uuid.
 * @return {Function} The task logic.
 */
const getTrendingDocuments = (maxResultsToDisplay, uuid) => (dispatch) => {
    dispatch({
        payload: {
            data: true,
            uuid,
        },
        type: SET_ISLOADING,
    });

    dispatch({
        payload: {
            data: [],
            uuid,
        },
        type: SET_PEOPLE,
    });

    return getTrendingDocumentsTask(maxResultsToDisplay)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_DOCUMENTS,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_DELVE.ERROR.FETCH_DOCUMENTS_ERROR')))
        .finally(() => {
            dispatch({
                payload: {
                    data: false,
                    uuid,
                },
                type: SET_ISLOADING,
            });
        });
};

/**
 * Task action for retrieving most used documents.
 *
 * @param  {number}   maxResultsToDisplay The maximum items to display.
 * @param  {string}   uuid                The current widget uuid.
 * @return {Function} The task logic.
 */
const getUsedDocuments = (maxResultsToDisplay, uuid) => (dispatch) => {
    dispatch({
        payload: {
            data: true,
            uuid,
        },
        type: SET_ISLOADING,
    });

    dispatch({
        payload: {
            data: [],
            uuid,
        },
        type: SET_PEOPLE,
    });

    return getUsedDocumentsTask(maxResultsToDisplay)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_DOCUMENTS,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_DELVE.ERROR.FETCH_DOCUMENTS_ERROR')))
        .finally(() => {
            dispatch({
                payload: {
                    data: false,
                    uuid,
                },
                type: SET_ISLOADING,
            });
        });
};

/**
 * Dispatch the INIT_STATE action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const initState = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: INIT_STATE,
        });
    })();
};

/**
 * Dispatch the SET_CURRENTLY_OPENED_POPOVER action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setCurrentlyOpenedPopover = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_CURRENTLY_OPENED_POPOVER,
        });
    })();
};

export {
    INIT_STATE,
    SET_CURRENTLY_OPENED_POPOVER,
    SET_ISLOADING,
    SET_DOCUMENTS,
    SET_PEOPLE,
    getCommunities,
    getPeople,
    getSharedDocuments,
    getTrendingDocuments,
    getUsedDocuments,
    initState,
    setCurrentlyOpenedPopover,
};
