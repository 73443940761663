import { BlockStructuredContent } from '@lumapps/widget-contribution/type';
import { BlockHtml } from '@lumapps/widget-html/type';
import { BlockIntro } from '@lumapps/widget-intro/type';
import { BlockTitle } from '@lumapps/widget-title/type';

import { Blocks } from '../types';

export const isBlockHtml = (body: Blocks, type: string): body is BlockHtml => {
    return type === 'html';
};

export const isBlockTitle = (body: Blocks, type: string): body is BlockTitle => {
    return type === 'title';
};

export const isBlockIntro = (body: Blocks, type: string): body is BlockIntro => {
    return type === 'intro';
};

export const isBlockStructuredContent = (body: Blocks, type: string): body is BlockStructuredContent => {
    return type === 'contribution';
};
