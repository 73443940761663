import produce from 'immer';

import { ANGULAR_ACTION_PREFIX } from 'common/modules/redux-store/redux-store-constants';
import { UPDATE_ACTION_TYPE } from 'common/froala/modules/froala_service';

import { LOAD_FROALA_SUCCESS } from './froala_actions';

/**
 * The angular froala update action.
 *
 * @type {string}
 * @constant
 * @readonly
 */
const ANGULAR_FROALA_UPDATE = `${ANGULAR_ACTION_PREFIX}/${UPDATE_ACTION_TYPE}`;

// Default state.
const defaultState = { isLoaded: false, isLoading: false };

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case ANGULAR_FROALA_UPDATE:
        case LOAD_FROALA_SUCCESS:
            return produce(state, (draft) => {
                /** In the reduxStore service, the param that holds the payload is called newState. */
                const payload = action.payload || action.newState;
                const { isLoaded, isLoading } = payload;
                draft.isLoaded = isLoaded;
                draft.isLoading = isLoading;

                return draft;
            });

        default:
            return state;
    }
};

export { reducer };
