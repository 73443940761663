export const ERRORS = {
    ALREADY_EXISTS: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_CONTENT_URL.ALREADY_EXISTS',
    INVALID_URL: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_CONTENT_URL.INVALID_URL',
    MISSING_URL: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_CONTENT_URL.MISSING_URL',
    URL_NOT_FOUND: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ADD_CONTENT_URL.URL_NOT_FOUND',
};

export const NOTIFICATIONS = {
    SUBMIT_ERROR: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.SUBMIT.ERROR',
    VALIDATION_ERROR: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.VALIDATION.ERROR',
    SUBMIT_SUCCESS: 'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.SUBMIT.SUCCESS',
};

export const AUTHORIZED_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
export const MAX_PHOTOS = 4;
export const MAX_IMAGE_SIZE = 5000000;

export enum FETCH_SN_STATUSES {
    initial = 'initial',
    loading = 'loading',
    success = 'success',
    failure = 'failure',
}
