import React, { useEffect } from 'react';

import lofind from 'lodash/find';

import { useClassnames } from '@lumapps/classnames';
import { List, ListItem, Select, Size } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { CHAT } from '../../keys';
import { UserSpaces } from '../../types';
import { SlackLoginButton } from '../SlackLoginButton';

import './index.scss';

export interface SlackSettingsProps {
    userSpaces: UserSpaces;
    isSlackDefaultAppEnabled: boolean;
}

const CLASSNAME = 'slack-settings';
const SlackSettings: React.FC<SlackSettingsProps> = ({ userSpaces, isSlackDefaultAppEnabled }) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const PLACEHOLDER = translateKey(CHAT.SELECT_USER_SPACE);
    const LABEL = translateKey(CHAT.SELECT_USER_SPACE);

    const [selectedValue, setSelectedValue] = React.useState<string>('');
    const [isOpen, setIsOpen] = React.useState(false);
    /**
     * In the case there is only one userSpace, select it by default.
     */
    useEffect(() => {
        if (!userSpaces) {
            return;
        }

        if (userSpaces.length === 1) {
            setSelectedValue(userSpaces[0].id);
        }
    }, [userSpaces]);

    const clearSelectedvalues = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setSelectedValue('');
    };

    const closeSelect = () => {
        setIsOpen(false);
    };

    const openSelect = () => {
        setIsOpen(!isOpen);
    };

    const onItemSelectedHandler = (item: string) => {
        closeSelect();
        if (selectedValue === item) {
            return;
        }
        setSelectedValue(item);
    };

    const renderSelectedValue = () => {
        const current = lofind(userSpaces, { id: selectedValue });

        if (!current) {
            return <div>{translateKey(CHAT.NO_USER_SPACE_SELECTED)}</div>;
        }

        return <div>{current.name}</div>;
    };

    return (
        <>
            {!isSlackDefaultAppEnabled && (
                <Select
                    id={element('select')}
                    isOpen={isOpen}
                    value={selectedValue}
                    onClear={clearSelectedvalues}
                    label={LABEL}
                    placeholder={PLACEHOLDER}
                    onDropdownClose={closeSelect}
                    onInputClick={openSelect}
                    selectedValueRender={renderSelectedValue}
                    className={element('select')}
                >
                    <List isClickable={isOpen}>
                        {userSpaces.length > 0 &&
                            userSpaces.map((choice: any) => (
                                <ListItem
                                    className={element('select-item')}
                                    isSelected={selectedValue === choice.id}
                                    key={choice.id}
                                    onItemSelected={() => onItemSelectedHandler(choice.id)}
                                    size={Size.tiny}
                                >
                                    {choice.name}
                                </ListItem>
                            ))}
                        {userSpaces.length === 0 && [
                            <ListItem key={0} size={Size.tiny}>
                                {translateKey(CHAT.NO_USER_SPACE_FOUND)}
                            </ListItem>,
                        ]}
                    </List>
                </Select>
            )}

            <SlackLoginButton spaceId={selectedValue} />
        </>
    );
};

export { SlackSettings };
