export enum SA_PROGRAMS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19639851
     */
    FETCH_DATA_RETRY_DESC = 'SA_ANALYTICS_FETCH_DATA_RETRY_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38771214
     */
    NEW_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAMS.NEW_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38771328
     */
    PROGRAM_CREATION_FIELD_SLUG = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.FIELD.SLUG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38771344
     */
    CREATE_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.CREATE_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38774391
     */
    PROGRAM_CREATION_ERROR_DUPLICATE_SLUG = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.ERROR.DUPLICATE.SLUG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38774841
     */
    PROGRAM_CREATION_ERROR_CHECKING_SLUG = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.ERROR.CHECKING.SLUG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39308433
     */
    PROGRAMS = 'ADMIN.NAV.SOCIAL_ADVOCACY.PROGRAMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39308444
     */
    NO_PROGRAMS = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_LIST.NO_PROGRAMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=39308449
     */
    CREATE_PROGRAM_CTA = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_LIST.CREATE_PROGRAM_CTA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40256102
     */
    UNAVAILABLE = 'FRONT.SOCIAL_ADVOCACY.ERROR.UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40527271
     */
    PROGRAM_CREATION_ERROR_CHECKING_NAME = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.ERROR.CHECKING.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40527485
     */
    PROGRAM_CREATION_ERROR_DUPLICATE_NAME = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.ERROR.DUPLICATE.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40866875
     */
    SORTED_BY = 'FRONT.SOCIAL_ADVOCACY.MEMBERS.SORTED_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40866877
     */
    SORT_NEW_MEMBERS = 'FRONT.SOCIAL_ADVOCACY.MEMBERS.SORT.NEW_MEMBERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45424572
     */
    EDIT_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAMS.EDIT_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=47987604
     */
    REMOVE_FROM_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_MEMBERSHIP.REMOVE_FROM_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=48351173
     */
    AT_LEAST_ONE_MANAGER = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_MEMBERSHIP.AT_LEAST_ONE_MANAGER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=48755217
     */
    SELECT_A_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_SELECTION.SELECT_A_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=48755218
     */
    SELECT_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_SELECTION.SELECT_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=48988845
     */
    PROGRAM_SELECTION_UNAVAILABLE = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_SELECTION.UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50294580
     */
    DELETE_PROGRAM = 'FRONT.SOCIAL_ADVOCACY.PROGRAM.DELETE_PROGRAM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50298178
     */
    PROGRAM_DELETE_EXPLANATION = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_DELETE.EXPLANATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=51439896
     */
    SEARCH_BY_NAME = 'FRONT.SOCIAL_ADVOCACY.MEMBERS.SORT.SEARCH_BY_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=55260753
     */
    LIMIT_REACHED = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_MEMBERSHIPS.LIMIT_REACHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50305165
     */
    PROGRAM_DELETE_SUCCESS = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_DELETE.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=50305166
     */
    PROGRAM_DELETE_ERROR = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_DELETE.ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=174838101
     */
    ERROR_FETCHING_PROGRAM_INFO = 'SA_PROGRAMS.ERROR_FETCHING_PROGRAM_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38775879
     */
    PROGRAM_CREATION_SUCCESS = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46477018
     */
    PROGRAM_EDITION_SUCCESS = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_EDITION.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46477068
     */
    PROGRAM_EDITION_FAILURE = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_EDITION.FAILURE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46477088
     */
    PROGRAM_CREATION_FAILURE = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.FAILURE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45424575
     */
    PROGRAM_CREATION_STEP_INFORMATION_LABEL = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.STEP.INFORMATION.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45857727
     */
    PROGRAM_CREATION_STEP_MEMBERS_LABEL = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.STEP.MEMBERS.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45857219
     */
    PROGRAM_CREATION_STEP_TOPICS_LABEL = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.STEP.TOPICS.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45857729
     */
    PROGRAM_CREATION_STEP_SOCIAL_NETWORKS_LABEL = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.STEP.SOCIAL_NETWORKS.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38771331
     */
    PROGRAM_CREATION_ERROR_REQUIRED_NAME = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.ERROR.REQUIRED.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=38771340
     */
    PROGRAM_CREATION_ERROR_REQUIRED_SLUG = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.ERROR.REQUIRED.SLUG',
}

export const getSortByRoleKey = (role: string) => `FRONT.SOCIAL_ADVOCACY.MEMBERS.FILTER.${role}`;

export enum PROGRAM_CREATION_ERROR_DUPLICATE {
    SLUG = SA_PROGRAMS.PROGRAM_CREATION_ERROR_DUPLICATE_SLUG,
    NAME = SA_PROGRAMS.PROGRAM_CREATION_ERROR_DUPLICATE_NAME,
}

export enum PROGRAM_CREATION_ERROR_CHECKING {
    SLUG = SA_PROGRAMS.PROGRAM_CREATION_ERROR_CHECKING_SLUG,
    NAME = SA_PROGRAMS.PROGRAM_CREATION_ERROR_CHECKING_NAME,
}
