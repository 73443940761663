/* eslint-disable no-param-reassign */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { SpaceAccessRequestStatus, SpaceData } from '../types';

export const domain = 'space';

export interface SpaceState {
    hasInit: boolean;
    isLoading: boolean;
    isMembershipRequestLoading: boolean;
    hasError: boolean;
    errorStatus?: number;
    data: SpaceData | null;
    id: string | null;
}

export const initialState: SpaceState = {
    hasInit: false,
    isLoading: false,
    isMembershipRequestLoading: false,
    hasError: false,
    data: null,
    id: null,
};

export const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        reset: () => initialState,
        request: (state: SpaceState) => {
            state.hasInit = true;
            state.isLoading = true;
        },
        success: (state: SpaceState, action: PayloadAction<{ data: SpaceData; id: string }>) => {
            state.isLoading = false;
            state.data = action.payload.data;
            state.id = action.payload.id;
            state.hasError = false;
            state.errorStatus = undefined;
        },
        failure: (state: SpaceState, action: PayloadAction<number | undefined>) => {
            state.isLoading = false;
            state.hasError = true;
            state.errorStatus = action.payload;
        },
        requestAccessRequest: (state: SpaceState) => {
            state.isMembershipRequestLoading = true;
            set(state, 'data.myPermissions.canRequestMembership', false);
        },
        requestAccessSuccess: (state: SpaceState) => {
            state.isMembershipRequestLoading = false;
            set(state, 'data.myMembership.requestDetails', { status: SpaceAccessRequestStatus.pending });
        },
        requestAccessFailure: (state: SpaceState) => {
            set(state, 'data.myPermissions.canRequestMembership', true);
            state.isMembershipRequestLoading = false;
        },
    },
});
