(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileUserFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/social/user/:methodKey', {}, {
            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('SocialProfileUserFactory', SocialProfileUserFactory);
})();
