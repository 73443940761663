import { MediaSourceV2 } from '@lumapps/medias/types';
import { Wrex } from '@lumapps/wrex/types';

import { FileElement } from '../types';
import { isDriveFile } from './isDriveFile';

/**
 * Get the drive files from the slate content
 */
export const getDriveFilesFromSlate = (
    content?: Wrex.Nodes,
    userProvider?: MediaSourceV2.GOOGLE_DRIVE | MediaSourceV2.MICROSOFT | undefined,
) => {
    if (!content || !userProvider) {
        return { driveFiles: undefined };
    }

    const driveFiles = content.filter(
        (node) => node.type === 'file' && isDriveFile(node as FileElement, userProvider),
    ) as FileElement[] | undefined;

    if (!driveFiles?.length) {
        return { driveFiles: undefined };
    }

    return { driveFiles };
};
