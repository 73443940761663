import { connect } from '@lumapps/redux/react';

import loFind from 'lodash/find';

import { WidgetImageGallery } from './WidgetImageGallery';
import { addEntity, fetchImages, fetchNextPage, removeEntity } from '../ducks/entities';

const mapStateToProps = (state, { uuid }) => {
    const currentWidget =
        loFind(state.widgets.entities, {
            uuid,
        }) || {};

    return { documentList: currentWidget.documentList || [], isLoading: currentWidget.isLoading || false };
};

const ConnectedWidgetImageGallery = connect(
    mapStateToProps,
    { addEntity, fetchImages, fetchNextPage, removeEntity },
)(WidgetImageGallery);

export * from './settings';

export { ConnectedWidgetImageGallery as WidgetImageGallery };
