import { error } from '@lumapps/notifications';
import { GLOBAL } from '@lumapps/translations';

import { getUserReport as getUserReportApi } from '../../api';
import { ReportedRequestParams } from '../../types';

export const fetchUserReport = async (params: Pick<ReportedRequestParams, 'resourceId' | 'resourceType'>) => {
    try {
        return await getUserReportApi(params);
    } catch (err) {
        error({ translate: GLOBAL.GENERIC_ERROR });
        return undefined;
    }
};
