/**
 * Small shortcut to get authorDetails fields either for the banner or the piece of content itself
 */
const authorDetailsFields = 'id,firstName,lastName,fullName,profilePictureUrl';

/**
 * The contentFields that are required to show the content
 * Note: this projection does not works like the others, there is some backend magic where you need extra projection
 * fields that are not returned by the endpoint to make the expected projection work.
 */
const contentsAndPostsHiddenProjection = `uid,id,authorDetails(apiProfile,${authorDetailsFields}),customer,comments,content,customContentTypeDetails,customContentTypeTags,excerpt,eventStartDate,eventEndDate,hasCommentWidget,hasRelevantComment,instance,liked,likes,links,link,metadata,parentContentDetails(id,instance,type,slug,title,adminKeys),postStatus,postStatusDetails(title),postType,publicationDate,relevantComment,score,slug,title,updatedAt,tagz,title,thumbnail,type,status,userVote,writerDetails,images,files,canEdit,canDelete,canMove,canShare,visibleInCommunitiesDetails`;
const contentFields = `contentDetails,contents(${contentsAndPostsHiddenProjection}),posts(${contentsAndPostsHiddenProjection})`;

/**
 * The fields below are used to get the banner details (xx wrote, xx commented...) necessary information, except for
 * the content details which will be inferred from the contentDetails field directly
 */
const contentAndPostChildrenFields = `count,usersCount,items(id,contentKey,action,createdAt,createdByDetails(${authorDetailsFields}))`;
const profileFields = `count,items(id,action,createdAt,description,createdByDetails(${authorDetailsFields}))`;

/**
 * Result default fields used to project the /social/activity/list endpoint
 * WARNING: this projection is very complicated and should be always used to improve performances, contact the code owners if you need to change it
 */
export const ACTIVITY_LIST_DEFAULT_FIELDS = `cursor,more,items(id,${contentFields},profile(${profileFields}),${[
    'comment',
    'create',
    'like',
    'update',
]
    .map((field) => `${field}(${contentAndPostChildrenFields})`)
    .join(',')})`;

/**
 * The Max results for the social/activity/list endpoint
 */
export const ACTIVITY_LIST_MAX_RESULTS = 5;
