import React from 'react';

// Svg Assets from options.
import viewModeGridSvg from 'assets/img/widget-settings/view-mode-grid-light.svg';
import slideshowThumbnailSvg from 'assets/img/widget-settings/slideshow-thumbnails.svg';
import slideshowBulletslSvg from 'assets/img/widget-settings/slideshow-bullets.svg';
import viewModeVariantGroupSvg from 'assets/img/widget-settings/view-mode-variant-grid-group.svg';
import viewModeVariantUngroupSvg from 'assets/img/widget-settings/view-mode-variant-grid-ungroup.svg';
import captionBelowSvg from 'assets/img/widget-settings/caption-below.svg';
import captionCoverSvg from 'assets/img/widget-settings/caption-cover.svg';

import { NAVIGATION_TYPES_VALUES, VIEW_MODES_VALUES, VIEW_MODE_VARIANTS_VALUES } from '../constants';

/**
 * Defines all caption positions.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const CAPTION_POSITIONS = {
    BELOW: 'below',
    OVER: 'over',
};

/**
 * Where to display captions on media blocks.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const CAPTION_POSITIONS_MODES = [
    {
        label: 'FRONT.SETTINGS.CAPTION.BELOW',
        render() {
            return <img alt="Below" src={captionBelowSvg} />;
        },
        value: CAPTION_POSITIONS.BELOW,
    },
    {
        label: 'FRONT.SETTINGS.CAPTION.COVER',
        render() {
            return <img alt="Cover" src={captionCoverSvg} />;
        },
        value: CAPTION_POSITIONS.OVER,
    },
];

/**
 * Navigation type selection when slideshow mode is activated.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const NAVIGATION_TYPES = [
    {
        label: 'FRONT.SETTINGS.NAVIGATION_CONTROLS.BULLETS',
        render() {
            return <img alt="Bullets" src={slideshowBulletslSvg} />;
        },
        value: NAVIGATION_TYPES_VALUES.BULLETS,
    },
    {
        label: 'GLOBAL.THUMBNAILS',
        render() {
            return <img alt="Thumbnails" src={slideshowThumbnailSvg} />;
        },
        value: NAVIGATION_TYPES_VALUES.THUMBNAILS,
    },
];

/**
 * In which view mode the widget should be displayed.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const VIEW_MODES = [
    {
        availableOptions: ['captionPosition', 'viewModeVariant', 'columnCount', 'orientation', 'main'],
        label: 'FRONT.SETTINGS.VIEW_MODE.GRID',
        render() {
            return <img alt="Grid" src={viewModeGridSvg} />;
        },
        value: VIEW_MODES_VALUES.GRID,
    },
    {
        availableOptions: ['captionPosition', 'hasAutoplay', 'interval', 'navigationControls', 'orientation'],
        label: 'FRONT.SETTINGS.VIEW_MODE.SLIDESHOW',
        render() {
            return <img alt="Slideshow" src={slideshowThumbnailSvg} />;
        },
        value: VIEW_MODES_VALUES.SLIDESHOW,
    },
];

/**
 * In grid mode, in which variants media blocks should be listed.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const VIEW_MODE_VARIANTS = [
    {
        label: 'FRONT.SETTINGS.VIEW_MODE_GROUP',
        render() {
            return <img alt="Group" src={viewModeVariantGroupSvg} />;
        },
        value: VIEW_MODE_VARIANTS_VALUES.GROUP,
    },
    {
        label: 'FRONT.SETTINGS.VIEW_MODE_UNGROUP',
        render() {
            return <img alt="Ungroup" src={viewModeVariantUngroupSvg} />;
        },
        value: VIEW_MODE_VARIANTS_VALUES.UNGROUP,
    },
];

/**
 * Defines the values for each orientation mode.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const ORIENTATIONS_VALUES = {
    HORIZONTAL: 'horizontal',
    ORIGINAL: 'original',
    VERTICAL: 'vertical',
};

/**
 * Available images orientations.
 *
 * @type {Object[]}
 * @constant
 * @readonly
 */
const ORIENTATIONS = [
    {
        label: 'FRONT.SETTINGS.ORIENTATION.LANDSCAPE',
        value: ORIENTATIONS_VALUES.HORIZONTAL,
    },
    {
        label: 'FRONT.SETTINGS.ORIENTATION.PORTRAIT',
        value: ORIENTATIONS_VALUES.VERTICAL,
    },
    {
        label: 'FRONT.SETTINGS.ORIENTATION.NATURAL',
        value: ORIENTATIONS_VALUES.ORIGINAL,
    },
];

export {
    CAPTION_POSITIONS,
    CAPTION_POSITIONS_MODES,
    ORIENTATIONS,
    ORIENTATIONS_VALUES,
    NAVIGATION_TYPES,
    VIEW_MODES,
    VIEW_MODE_VARIANTS,
};
