import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const siteAnalyticsBaseRoute = 'analytics/site';
const siteAnalyticsRouteMatcher = `/${siteAnalyticsBaseRoute}/*`;
const siteAnalyticsRoute: Route = createPageRoute({
    slug: `${siteAnalyticsBaseRoute}/:tab?`,
    id: 'site-analytics',
    appId: AppId.backOffice,
});

const siteAnalytics = (tab?: string): Route => {
    return {
        ...siteAnalyticsRoute,
        params: tab
            ? {
                  tab,
              }
            : undefined,
    };
};

const getAnalyticsForSite = (instanceSlug?: string): Route => {
    return {
        ...siteAnalyticsRoute,
        instanceSlug,
    };
};

export { siteAnalytics, siteAnalyticsRoute, getAnalyticsForSite, siteAnalyticsRouteMatcher };
