import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const resellerRoute: Route = {
    path: '/admin/reseller',
    legacyId: 'app.admin.reseller',
    appId: AppId.backOffice,
};

const reseller = (): Route => resellerRoute;

export { reseller, resellerRoute };
