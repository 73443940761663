import { PATH } from '@lumapps/digital-assistant/api';
import { organizationExtensionApi } from '@lumapps/marketplace/api';

import { ListMicroAppsParams, MicroAppProps, RunMicroAppAPI, SubmitRunMicroAppParams } from '../types';

const LONG_TIMEOUT = 60000;

/**
 * Run the given micro-app.
 * No runId is provided so a new micro-app is created.
 */
export const runMicroApp = async (extensionId: string, signal?: AbortSignal) => {
    const { data } = await organizationExtensionApi.post<RunMicroAppAPI>(
        `/${extensionId}/microapp/runs`,
        { signal },
        // adding long timeout to axios request is necessary here because some APIs are slow
        { timeout: LONG_TIMEOUT },
    );

    return data;
};

/**
 * Submit the given micro-app
 * A runId is provided so an existing micro-app is used.
 */
export const submitRunMicroApp = async ({ action, microAppId: extensionId, runId }: SubmitRunMicroAppParams) => {
    const { data: inputs, id: submittedActionId, actionType } = action;

    const { data } = await organizationExtensionApi.post<RunMicroAppAPI>(
        `/${extensionId}/microapp/runs`,
        {
            [actionType]: {
                inputs,
                submittedActionId,
            },
            runId,
        },
        // adding long timeout to axios request is necessary here because some APIs are slow
        { timeout: LONG_TIMEOUT },
    );

    return data;
};

/** Keys for all micro app queries */
export const microAppsQueryKeys = {
    all: [PATH, 'micro-apps'] as const,
    listMicroApps: (params: ListMicroAppsParams) => ['installed-extensions', params] as const,
    runMicroApp: (microAppId?: MicroAppProps['id'], runInstanceId?: MicroAppProps['runInstanceId']) =>
        ['extensions', microAppId, 'microapp', 'runs', runInstanceId] as const,
};
