import React, { useMemo } from 'react';

import { classnames } from '@lumapps/classnames';
import { ImageLightbox, useImageLightbox } from '@lumapps/lumx-images/components/ImageLightbox';
import { Progress, Thumbnail } from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import type { ElementRender, Wrex } from '@lumapps/wrex/types';

import { IMAGE } from '../../../constants';

import './index.scss';

export interface ImageElement extends Wrex.Element {
    type: typeof IMAGE;
    src: string;
    tempSrc?: string;
    alt?: string;
    title?: string;
    isLoading?: boolean;
    deleteCallback?(): void;
}

export interface ImageProps {
    isInEditionMode?: boolean;
}

export const Image: ElementRender<ImageElement, HTMLDivElement, ImageProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, elementRef, element, isInEditionMode, children, ...forwardedProps } = props;
    const { src = '', tempSrc, alt, title, isLoading } = element ?? {};

    const thumbnail = useMemo(
        () => ({
            image: tempSrc || makeSecuredMediaURLRelative(src) || '',
            alt: alt || title || '',
            title,
        }),
        [alt, src, tempSrc, title],
    );
    const { getTriggerProps, imageLightboxProps } = useImageLightbox([thumbnail]);
    const triggerProps = !isInEditionMode ? getTriggerProps() : undefined;

    return (
        <div {...forwardedProps} ref={elementRef} className={classnames(className, 'wrex-content-image')}>
            {isLoading ? (
                <div>
                    <Progress />
                </div>
            ) : (
                <Thumbnail {...thumbnail} contentEditable={false} {...triggerProps} />
            )}
            {children}
            <ImageLightbox {...imageLightboxProps} />
        </div>
    );
};
Image.displayName = IMAGE;
