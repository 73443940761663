import { combineReducers } from '@lumapps/redux';

import { reducer as confirmBoxReducer } from 'components/components/ducks/confirmBox';

import { reducer as userReducer } from './users';
import { reducer as exportReducer } from './export';
import { reducer as topicReducer } from './topics';
import { reducer as analyticsReducer } from './analytics';
import { reducer as shareableContentReducer } from './shareableContents';
import { reducer as shareableWorkflowReducer } from './shareableWorkflow';

const rootReducer = combineReducers({
    confirmBox: confirmBoxReducer,
    export: exportReducer,
    users: userReducer,
    topics: topicReducer,
    saAnalytics: analyticsReducer,
    shareableContents: shareableContentReducer,
    shareableWorkflow: shareableWorkflowReducer,
});

export default rootReducer;
