/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { MicroAppPickerDialogProps } from '../../types';

const LazyMicroAppPicker = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'micro-app-picker-dialog' */
            './MicroAppPickerDialog'
        ),
);

const MicroAppPickerDialog: React.FC<MicroAppPickerDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyMicroAppPicker {...props} />
        </DelayedSuspense>
    );
};

export { MicroAppPickerDialog };
