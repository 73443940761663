import { Alignment } from '@lumapps/wrex/types/core';

import { HEADLINE } from '../constants';
import type { HeadlineElement } from '../types';

/** Create headline element */
export function createHeadline(
    children: HeadlineElement['children'] = [{ text: '' }],
    alignment: Alignment | undefined = undefined,
): HeadlineElement {
    return { type: HEADLINE, children, ...(alignment ? { alignment } : undefined) };
}
