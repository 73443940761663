import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { fromHTML } from '@lumapps/wrex/serialization/html/fromHTML/fromHTML';
import { Editor, Location, Transforms } from '@lumapps/wrex/slate';
import { formatFragment } from '@lumapps/wrex/slate/formatFragment';
import { createPlugin } from '@lumapps/wrex/slate/plugin';
import { isText } from '@lumapps/wrex/slate/utils/isText';
import { WrexEditor } from '@lumapps/wrex/types';

import { HTMLPasteEditor, HTMLPasteOptions } from '../types';

const getBeforeOrEndEditorPoint = (editor: Editor, at?: Location | null) => {
    return at ? Editor.before(editor, at) : Editor.end(editor, []);
};

/**
 * Interprets pasted text/html content as HTML.
 */
export const withHTMLPaste = (options: HTMLPasteOptions) => {
    return createPlugin<HTMLPasteEditor, WrexEditor<HTMLPasteEditor>>((editor) => {
        const { insertData } = editor;

        return {
            insertData(data: DataTransfer) {
                const fragment = data.getData('application/x-slate-fragment');
                if (fragment) {
                    insertData(data);
                    return;
                }
                const html = data.getData('text/html');
                if (!html) {
                    insertData(data);
                    return;
                }

                const nodesBefore = fromHTML(html, options);
                // Parse HTML into slate nodes (slate fragment).
                const { textChunks, nodes } = formatFragment(editor, nodesBefore, options.defaultBlockType);

                // First, insert slate fragment at editor selection or at the end of the document.
                const chunksInsertLocation = editor.selection || undefined;
                const afterChunks = chunksInsertLocation && Editor.after(editor, chunksInsertLocation);
                const afterChunksRef = afterChunks && Editor.pointRef(editor, afterChunks);
                if (textChunks.length) {
                    Transforms.insertNodes(editor, textChunks, { at: chunksInsertLocation });
                }
                // Put cursor at the end of the inserted chunks
                Transforms.select(editor, getBeforeOrEndEditorPoint(editor, afterChunksRef?.unref()) || []);

                // Then, insert slate nodes at editor selection or at the end of the document
                const nodesInsertLocation = editor.selection || undefined;
                const afterNodes = nodesInsertLocation && Editor.after(editor, nodesInsertLocation);
                const afterNodesRef = afterNodes && Editor.pointRef(editor, afterNodes);
                const currentNode = nodesInsertLocation && Editor.node(editor, nodesInsertLocation)[0];

                if (nodes.length) {
                    /*
                     * If chunks have been inserted or the current line is not a paragraph and node to insert is a text
                     * insertFragment inserts nodes in the current line
                     * insertNodes inserts nodes in a new line
                     */
                    if (
                        textChunks.length ||
                        (!isParagraph(nodes[0]) && isText(currentNode) && currentNode.text.length > 0)
                    ) {
                        Transforms.insertNodes(editor, nodes, { at: nodesInsertLocation });
                    } else {
                        Transforms.insertFragment(editor, nodes, { at: nodesInsertLocation });
                    }
                }
                // Put cursor at the end of the inserted chunks
                Transforms.select(editor, getBeforeOrEndEditorPoint(editor, afterNodesRef?.unref()) || []);
            },
        };
    });
};
