(function IIFE() {
    'use strict';

    /////////////////////////////

    function MetadataFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/metadata/:methodKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            'list': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            'listAvailable': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/instance/metadata/:methodKey',
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            'saveMulti': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'saveMulti',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('MetadataFactory', MetadataFactory);
})();
