import BaseApi from '@lumapps/base-api';

const groupsApi = new BaseApi({ path: 'groups/applications' });

/**
 * Fetch the list of available apps for a group.
 *
 * @param  {string}  groupIdentifier The ID of the group.
 * @return {Promise} Returns.
 */
const fetchGroupApplications = (groupIdentifier) => {
    return groupsApi.get('get', {
        params: {
            groupIdentifier,
        },
    });
};

export { fetchGroupApplications };
