import React from 'react';

import intersection from 'lodash/intersection';

import { Orientation, Theme } from '@lumapps/lumx/react';

import type { Video, VisibleElement } from './types';

type BaseBlockVideoContextProviderProps = {
    /** Component children */
    children: React.ReactNode;

    /** Orientation of the component. */
    orientation: Orientation;

    /** UI theme */
    theme?: Theme;

    /** Video */
    video?: Video;

    /** Elements to display */
    visibleElements?: VisibleElement[];
};

type ReadyBlockVideoContextProviderProps = BaseBlockVideoContextProviderProps & { isLoading?: false; video: Video };

type LoadingBlockVideoContextProviderProps = BaseBlockVideoContextProviderProps & {
    isLoading: true;
    video?: undefined;
};

export type BlockVideoContextProviderProps =
    | ReadyBlockVideoContextProviderProps
    | LoadingBlockVideoContextProviderProps;

interface BaseState {
    /**
     * Predicate function used to know if at least one of the elements is visible or not
     *
     * @example
     * canDisplay('title', 'description') returns true if 'title' OR 'description' is visible
     */
    canDisplay: (...elements: VisibleElement[]) => boolean;
}

type ReadyState = BaseState & Omit<ReadyBlockVideoContextProviderProps, 'children'>;

type LoadingState = BaseState & Omit<LoadingBlockVideoContextProviderProps, 'children'>;

type State = ReadyState | LoadingState;

const BlockVideoContext = React.createContext<State | undefined>(undefined);

const BlockVideoContextProvider = ({
    children,
    isLoading,
    orientation,
    theme,
    video,
    visibleElements,
}: BlockVideoContextProviderProps) => {
    const canDisplay = React.useCallback(
        (...elements: VisibleElement[]) => {
            return !visibleElements || !!intersection(elements, visibleElements).length;
        },
        [visibleElements],
    );

    const value: State = React.useMemo(() => {
        return {
            canDisplay,
            orientation,
            theme,
            ...(isLoading ? { isLoading } : { video }),
        };
    }, [canDisplay, isLoading, video, theme, orientation]);

    return <BlockVideoContext.Provider value={value}>{children}</BlockVideoContext.Provider>;
};

const useBlockVideoContext = () => {
    const context = React.useContext(BlockVideoContext);

    if (!context) {
        throw new Error('Child components of BlockVideo cannot be rendered outside the BlockVideo component!');
    }

    return context;
};

export { BlockVideoContextProvider, useBlockVideoContext };
