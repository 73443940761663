import { fetchAppsTask } from '../community_tasks';

const FETCH_APPS_LOADING = 'community/FETCH_APPS_LOADING';
const FETCH_APPS_SUCCESS = 'community/FETCH_APPS_SUCCESS';
const FETCH_APPS_ERROR = 'community/FETCH_APPS_ERROR';

/**
 * Dispatcher methods.
 * @param  {string} groupId Conversation group Id
 * @return {void}   Returns.
 */
const dispatchFetchApps = (groupId) => (dispatch) => {
    dispatch({
        type: FETCH_APPS_LOADING,
    });

    return fetchAppsTask({ groupId })
        .then(({ payload } = {}) => {
            dispatch({
                payload,
                type: FETCH_APPS_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: FETCH_APPS_ERROR,
            });
        });
};

export { dispatchFetchApps, FETCH_APPS_ERROR, FETCH_APPS_LOADING, FETCH_APPS_SUCCESS };
