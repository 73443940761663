import React from 'react';

import { Divider } from '@lumapps/lumx/react';

import { DEFAULT_WIDGET_PROPERTIES } from '../../constants';
import { WidgetPlaylistProperties } from '../../types';
import { PlaylistOptions } from './PlaylistOptions';
import { PlaylistVariants } from './PlaylistVariants';
import './index.scss';

export interface WidgetPlaylistStyleProps {
    properties: WidgetPlaylistProperties;
    onChange: (properties: WidgetPlaylistProperties) => void;
}

const CLASSNAME = 'widget-playlist-style';

/**
 *
 * @param WidgetPlaylistStyleProps
 * @returns WidgetPlaylistStyle
 */
export const WidgetPlaylistStyle = ({ properties, onChange }: WidgetPlaylistStyleProps) => {
    const widgetProperties: WidgetPlaylistProperties = { ...DEFAULT_WIDGET_PROPERTIES, ...properties };
    const { viewModeVariant, slideshowEnabled, itemsPerLine } = widgetProperties;
    return (
        <div className={CLASSNAME}>
            <PlaylistVariants
                properties={{ viewModeVariant }}
                onChange={(variant) => onChange({ ...widgetProperties, ...variant })}
            />
            <Divider />
            <PlaylistOptions
                properties={{ slideshowEnabled, itemsPerLine }}
                onChange={(options) => onChange({ ...widgetProperties, ...options })}
            />
        </div>
    );
};
