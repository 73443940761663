/* istanbul ignore file */
/** tested with integration tests */
import { useContentTapper } from './useContentTapper';
import { useNotificationsTapper } from './useNotificationsTapper';
import { useRefreshTokenTapper } from './useRefreshTokenTapper';
import { useRouterTapper } from './useRouterTapper';

/**
 * Installs the different tappers that we have available in our application
 */
const useTappers = () => {
    window.lumapps = window.lumapps || {};

    useRouterTapper();
    useContentTapper();
    useRefreshTokenTapper();
    useNotificationsTapper();
};

export { useTappers };
