import React, { useMemo, useState } from 'react';

import { get } from '@lumapps/constants';
import { contentAnalytics } from '@lumapps/content-analytics/routes';
import { doesCustomizationExist, PLACEMENT, Targets } from '@lumapps/customizations/api';
import { DistributeResourceDialog } from '@lumapps/distribute/components/DistributeResourceDialog';
import { useDistributeDialog } from '@lumapps/distribute/hooks/useDistributeDialog';
import { DISTRIBUTE } from '@lumapps/distribute/keys';
import type { DistributeResourceType } from '@lumapps/distribute/types';
import { ContextualActions } from '@lumapps/lumx-front-office/components/ContextualActions';
import { mdiChartLine, mdiMessageAlertOutline, mdiPencil, mdiSend, mdiTranslate } from '@lumapps/lumx/icons';
import { ReportTunnel } from '@lumapps/reports/components/ReportTunnel/ReportTunnel';
import { useReport } from '@lumapps/reports/hooks/useReport';
import { REPORT_ALREADY_REPORTED, REPORTS } from '@lumapps/reports/keys';
import { useResponsive } from '@lumapps/responsive';
import { useQueryParams } from '@lumapps/router';
import { getRouteAsLinkProps } from '@lumapps/router/utils';
import { getKeyForLanguage, GLOBAL, useTranslate } from '@lumapps/translations';
import { SAVED_ITEMS_RESOURCE_TYPES } from '@lumapps/user-saved-items/constants';
import { useContextualSave } from '@lumapps/user-saved-items/hooks/useContextualSave';

import { useContent } from '../../hooks/useContent';
import { CONTENTS } from '../../keys';
import { contentEdit } from '../../routes';

export interface ContentContextualActionsProps {
    /** Indicates if the component should be displayed in the current content */
    isEnabled: boolean;
    /** Current content id */
    contentId: string;
    /** Indicates if the user can access analytics and if the content is live */
    canAccessContentAnalytics: boolean;
    /** Indicates if the user can access reports and if the content is live */
    canAccessReports: boolean;
    /** Current content type id */
    contentTypeId?: string;
    /** Is the user in design mode */
    isInDesignerMode?: boolean;
    /** Whether En User Translation is enabled or not */
    isTranslationFeatureEnabled?: boolean;
    /** callback to be executed when the translation button is triggered */
    onTranslation?: (isTranslationActive: boolean) => void;
    /** Default language defined in the user profile if set */
    userLanguage?: string;
    /** Current language retrieved from the site */
    currentLanguage: string;
    /** Whether the content is a home page */
    isHomePage?: boolean;
}

const DEFAULT_LANG = get().defaultLanguage;

/**
 * This component provide a contextual menu in the product which regroups a list of options related to a content.
 */
export const ContentContextualActions: React.FC<ContentContextualActionsProps> = ({
    isEnabled,
    canAccessContentAnalytics,
    canAccessReports,
    onTranslation,
    contentId,
    contentTypeId,
    isInDesignerMode,
    isTranslationFeatureEnabled,
    userLanguage,
    currentLanguage,
    isHomePage,
}) => {
    const { translateKey } = useTranslate();
    const [isTranslationActive, setIsTranslationActive] = useState(false);
    const { canCurrentUserEditContent, currentContentId } = useContent();
    const { isMobile } = useResponsive();
    const isTranslateActionVisible = isTranslationFeatureEnabled && onTranslation;
    const isEditActionVisible = !isMobile && canCurrentUserEditContent && contentId;
    const isAnalyticsActionVisible = isEditActionVisible && !isMobile && canAccessContentAnalytics && contentTypeId;
    const isReportsActionVisible = !isHomePage && canAccessReports && contentTypeId;
    const ReportParentElement = React.useRef(null);
    const distributeRef = React.useRef(null);

    const translateLanguage = userLanguage || currentLanguage || DEFAULT_LANG;
    const translateLanguageLabel = useMemo(
        () => translateKey(getKeyForLanguage(translateLanguage)),
        [translateLanguage, translateKey],
    );
    const translationLabel = useMemo(
        () =>
            isTranslationActive
                ? `${translateKey(CONTENTS.CONTEXTUAL_ACTIONS_TRANSLATION_ON_ACTION)} ${translateLanguageLabel}`
                : `${translateKey(CONTENTS.CONTEXTUAL_ACTIONS_TRANSLATION_OFF_ACTION)} ${translateLanguageLabel}`,
        [translateLanguageLabel, isTranslationActive, translateKey],
    );

    const { ReportTunnelProps, onReportButtonClick, isResourceAlreadyReported } = useReport({
        resourceId: contentId,
        resourceType: 'content',
        reportBtnRef: ReportParentElement,
    });

    const { distribute } = useQueryParams();
    const {
        formDialogProps: distributeResourceProps,
        isDistributeResourceEnabled,
        openFormDialog: openDistributeDialog,
        isMounted: isDistributeDialogMounted,
    } = useDistributeDialog({
        resource: {
            type: 'content' as DistributeResourceType,
            id: currentContentId || '',
        },
    });
    const isDistributeActionVisible = !isMobile && Boolean(isDistributeResourceEnabled);

    React.useEffect(() => {
        if (distribute === 'true' && isDistributeActionVisible && !isInDesignerMode) {
            openDistributeDialog();
        }
    }, [distribute, isDistributeActionVisible, isInDesignerMode, openDistributeDialog]);

    const toggleTranslationState = () => {
        if (onTranslation) {
            onTranslation(!isTranslationActive);
        }

        setIsTranslationActive(!isTranslationActive);
    };

    const isContextualActionsCustomized =
        doesCustomizationExist({ target: Targets.CONTEXTUAL_ACTIONS, placement: PLACEMENT.ABOVE }) ||
        doesCustomizationExist({ target: Targets.CONTEXTUAL_ACTIONS, placement: PLACEMENT.UNDER });

    const { hasUserSavedItemsFeature, action: saveAction } = useContextualSave({
        resourceId: contentId,
        resourceType: SAVED_ITEMS_RESOURCE_TYPES.content,
    });

    const doesNotHaveAnyPermission =
        !isEditActionVisible &&
        !isAnalyticsActionVisible &&
        !isTranslateActionVisible &&
        !isReportsActionVisible &&
        !hasUserSavedItemsFeature &&
        !isDistributeActionVisible;

    // If the user does not have access to any of the options, don't show the Contextual Action component
    if (!isEnabled || isInDesignerMode || (!isContextualActionsCustomized && doesNotHaveAnyPermission)) {
        return null;
    }

    return (
        <>
            <ContextualActions
                actions={[
                    saveAction,
                    isTranslateActionVisible && {
                        dataAttributes: {
                            element: 'content-translate',
                            action: 'translate',
                        },
                        isSelected: isTranslationActive,
                        iconSvgPath: mdiTranslate,
                        labelKey: translationLabel,
                        action: toggleTranslationState,
                    },
                    isReportsActionVisible && {
                        dataAttributes: {
                            element: 'content-report',
                            action: contentId,
                        },
                        isDisabled: isResourceAlreadyReported,
                        iconSvgPath: mdiMessageAlertOutline,
                        labelKey: REPORTS.REPORT,
                        action: onReportButtonClick,
                        tooltipLabelKey: isResourceAlreadyReported ? REPORT_ALREADY_REPORTED.content : undefined,
                    },
                    isEditActionVisible && {
                        dataAttributes: {
                            element: 'content-edit',
                            action: contentId,
                        },
                        iconSvgPath: mdiPencil,
                        labelKey: GLOBAL.EDIT,
                        action: getRouteAsLinkProps(contentEdit(contentId)),
                    },
                    isAnalyticsActionVisible && {
                        dataAttributes: {
                            element: 'content-analytics',
                            action: contentId,
                        },
                        iconSvgPath: mdiChartLine,
                        labelKey: GLOBAL.ANALYTICS,
                        action: getRouteAsLinkProps(contentAnalytics(contentTypeId as string, contentId)),
                    },
                    isDistributeActionVisible && {
                        dataAttributes: {
                            element: 'content-distribute',
                            action: contentId,
                        },
                        iconSvgPath: mdiSend,
                        labelKey: DISTRIBUTE.DISTRIBUTE,
                        action: openDistributeDialog,
                        buttonRef: distributeRef,
                    },
                ]}
            />
            <ReportTunnel {...ReportTunnelProps} />
            {isDistributeDialogMounted && (
                <DistributeResourceDialog parentElement={distributeRef} {...distributeResourceProps} />
            )}
        </>
    );
};
ContentContextualActions.displayName = 'ContentContextualActions';
