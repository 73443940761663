import React, { useEffect } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiPencil, mdiSwapHorizontal, mdiTrashCanOutline } from '@lumapps/lumx/icons';
import {
    Alignment,
    Emphasis,
    Icon,
    IconButton,
    IconButtonProps,
    LinkPreview as LP,
    LinkPreviewProps,
    Size,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { isUrl } from '@lumapps/utils/string/isUrl';

import { PREVIEW } from '../../keys';
import type { LinkPreviewMetadata } from '../../types';

import '../index.scss';

export type CustomLink<E extends React.ElementType = React.ElementType> = { linkAs: E } & React.ComponentProps<E>;

export interface DefaultPreviewProps {
    /** The preview object. */
    preview: Partial<LinkPreviewMetadata>;
    /** Inject a custom react link element */
    customLink?: CustomLink;
    /** Icon to display on the top left of the thumbnail. If set, onPreviewThumbnailSwitch() is ignored */
    previewThumbnailIcon?: string;
    /** Callback called when the thumbnail switch button is clicked. is ignored if previewThumbnailIcon is set */
    onPreviewThumbnailSwitch?: () => void;
    /** The prefix to use on every classname. */
    classNamePrefix?: string;
    /** callback to be executed when the link preview is being edited */
    onEdit?: () => void;
    /** Icon to display for the onRemove button to override the default */
    onRemoveIcon?: string;
    /** callback to be executed when the preview is being removed */
    onRemove?: () => void;
    /** Icon to display for the onEdit button to override the default */
    onEditIcon?: string;
    /** Label to display for the onEdit button to override the default */
    onEditLabel?: string;
    /** Props to pass to the remove button */
    removeButtonProps?: Partial<IconButtonProps>;
    /** additional props passed into the thumbnail */
    imgProps?: Partial<LinkPreviewProps['imgProps']>;
    /** ref to forward to make keyboard navigation easy */
    actionRef?: React.RefObject<any>;
    /** custom classname for the component */
    className?: string;
}

/**
 * Block used as the default preview for a given link.
 *
 * @param DefaultPreviewProps
 * @returns DefaultPreview
 */
export const DefaultPreview: React.FC<DefaultPreviewProps> = (props) => {
    const {
        customLink,
        preview,
        previewThumbnailIcon,
        onPreviewThumbnailSwitch,
        classNamePrefix = 'link-preview',
        onEdit,
        onEditIcon,
        onEditLabel,
        onRemove,
        onRemoveIcon,
        removeButtonProps,
        imgProps = {},
        actionRef,
        className,
    } = props;
    const { get } = useDataAttributes(classNamePrefix);
    const { element } = useClassnames(classNamePrefix);
    const { translateKey } = useTranslate();
    const image = preview.images?.[preview.thumbnailIndex as any] || imgProps?.image;

    const { linkAs, ...linkProps } = customLink || {};

    // Take preview.url as default link
    const [link, setLink] = React.useState(isUrl(preview.url) ? (preview.url as string) : '');

    useEffect(() => {
        setLink(isUrl(preview.url) ? (preview.url as string) : '');
    }, [preview.url]);

    // Dynamic link: if link was not defined in preview.url, we copy the actual HTML href into the LinkPreview `link` prop
    const linkRef = React.useCallback(
        (linkElement: HTMLAnchorElement) => {
            if (!linkElement || link) {
                return;
            }
            setLink(linkElement.href);
        },
        [link],
    );

    return (
        <div className={element('preview-wrapper', [className])}>
            <LP
                linkAs={linkAs}
                linkProps={{
                    ...linkProps,
                    ref: !onEdit && !onRemove && actionRef ? mergeRefs([linkRef, actionRef]) : linkRef,
                }}
                link={link}
                description={preview.description}
                title={preview.title}
                thumbnailProps={
                    image || imgProps?.fallback
                        ? {
                              image: image || '',
                              alt: preview.title || '',
                              align: Alignment.center,
                              ...imgProps,
                          }
                        : undefined
                }
            />
            {previewThumbnailIcon ? (
                <Icon
                    hasShape
                    size="s"
                    icon={previewThumbnailIcon}
                    className={element('preview-thumbnail-icon')}
                    {...get({ element: 'button-preview-thumbnail-icon' })}
                />
            ) : null}
            {!previewThumbnailIcon && onPreviewThumbnailSwitch && preview.images && preview.images.length > 1 ? (
                <IconButton
                    size={Size.s}
                    className={element('preview-thumbnail-switch-btn')}
                    onClick={onPreviewThumbnailSwitch}
                    label={translateKey(PREVIEW.LINK_PREVIEW_SWITCH_THUMBNAIL)}
                    emphasis={Emphasis.low}
                    hasBackground
                    icon={mdiSwapHorizontal}
                    {...get({ element: 'button', action: 'switch-preview-thumbnail' })}
                />
            ) : null}

            <div className={element('preview-edit-wrapper')}>
                {onEdit ? (
                    <IconButton
                        size={Size.s}
                        className={element('preview-thumbnail-edit-btn')}
                        onClick={onEdit}
                        label={onEditLabel || translateKey(GLOBAL.EDIT)}
                        emphasis={Emphasis.medium}
                        hasBackground
                        icon={onEditIcon || mdiPencil}
                        ref={actionRef}
                        {...get({ element: 'button', action: 'edit-preview-thumbnail' })}
                    />
                ) : null}
                {onRemove ? (
                    <IconButton
                        size={Size.s}
                        label={translateKey(GLOBAL.REMOVE)}
                        emphasis={Emphasis.medium}
                        hasBackground
                        icon={onRemoveIcon || mdiTrashCanOutline}
                        {...removeButtonProps}
                        className={element('preview-thumbnail-remove-btn')}
                        onClick={onRemove}
                        ref={!onEdit ? actionRef : null}
                        {...get({ element: 'button', action: 'edit-preview-thumbnail' })}
                    />
                ) : null}
            </div>
        </div>
    );
};
