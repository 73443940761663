import { Editor, Range } from '@lumapps/wrex/slate';

import { isAutocompleteAllowed } from './isAutocompleteAllowed';

/**
 * Detect if the `text` provided should insert an autocomplete search at current selection.
 */
export function shouldInsertAutocompleteSearch(editor: Editor, text: string, triggerCharacter: string) {
    const { selection } = editor;
    // Editor has collapsed selection and insert text starts with the provided trigger character
    if (!text.startsWith(triggerCharacter) || !selection || !isAutocompleteAllowed(editor)) {
        return false;
    }
    const end = Range.end(selection);

    // Check that the character before is a space or empty
    const before = Editor.before(editor, end, { distance: 1, unit: 'character' });
    const previousChar = before && Editor.string(editor, { focus: end, anchor: before })?.trim();
    if (previousChar) {
        return false;
    }
    return true;
}
