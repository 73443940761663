import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { CustomizationComponent, PLACEMENT, Targets } from '@lumapps/customizations/api';
import {
    ErrorPageState as BaseErrorPage,
    ErrorPageStateProps as BaseErrorPageProps,
} from '@lumapps/lumx-states/components/ErrorPageState';
import { mdiImageBrokenVariant } from '@lumapps/lumx/icons';
import { Emphasis } from '@lumapps/lumx/react';
import { getBaseUrl } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { Page } from '../Page';

import './index.scss';

const CLASSNAME = 'front-office-error-page';

export interface ErrorPageProps extends BaseErrorPageProps {
    /** Forwarded className */
    className?: string;
}

/**
 * Page that the user will be redirected when there is an unexpected error in a navigation on the
 * front office page. This should not be used as a reusable component.
 * @family Front office
 * @param ErrorPageProps
 * @returns ErrorPage
 */
export const ErrorPage: React.FC<ErrorPageProps> = ({
    className: propClassName,
    code = 404,
    shouldShowIcon,
    shouldShowAction,
    icon = mdiImageBrokenVariant,
    buttonProps = {},
    title,
    description,
}) => {
    const { block } = useClassnames(CLASSNAME);
    const className = block(`${code}`, [propClassName]);
    const { translateKey } = useTranslate();
    const pageTitle = translateKey(title || `${code}_TITLE`);
    const buttonHref = buttonProps.href ? buttonProps.href : getBaseUrl(window.location.pathname);
    const redirectButtonProps = {
        emphasis: Emphasis.medium,
        href: buttonHref,
        children: translateKey(GLOBAL.GO_TO_HOMEPAGE),
        ...buttonProps,
    };
    const customComponent = CustomizationComponent({
        target: code === 404 ? Targets.NOT_FOUND_PAGE : Targets.ERROR_PAGE,
        placement: PLACEMENT.REPLACE,
        // need to set left and right icons to undefined otherwise any call to retrieve the path will generate an error
        context: { button: { ...redirectButtonProps, leftIcon: undefined, rightIcon: undefined } },
    });

    const hasCustomization = customComponent !== null;

    return (
        <Page title={pageTitle} id={`error-page-${code}`} className={className} hasBackground={false}>
            {hasCustomization ? (
                customComponent
            ) : (
                <BaseErrorPage
                    code={code}
                    shouldShowIcon={shouldShowIcon}
                    shouldShowAction={shouldShowAction}
                    icon={icon}
                    buttonProps={{ ...redirectButtonProps }}
                    title={title}
                    description={description}
                />
            )}
        </Page>
    );
};
