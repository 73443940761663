(function IIFE() {
    /////////////////////////////

    function WidgetCommunityNavigationSettingsController(Widget) {
        'ngInject';

        const widgetCommunityNavigationSettings = this;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetCommunityNavigationSettings.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Switch to a given viewMode
         *
         * @param {(horizontal|vertical)} viewMode The view mode.
         */
        function switchViewMode(viewMode) {
            if (Widget.getCurrent().properties.viewMode === viewMode) {
                return;
            }

            Widget.getCurrent().properties.viewMode = viewMode;
        }

        /////////////////////////////

        widgetCommunityNavigationSettings.switchViewMode = switchViewMode;
    }

    /////////////////////////////

    angular
        .module('Controllers')
        .controller('WidgetCommunityNavigationSettingsController', WidgetCommunityNavigationSettingsController);
})();
