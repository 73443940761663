import React from 'react';

import { ImportOnVisibility } from '@lumapps/utils/components/ImportOnVisibility';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

import { ClientComputedMicroAppProps } from '../../types';

const ClientComputedMicroApp = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-micro-app' */
            './ClientComputedMicroApp'
        ),
);

const LazyClientComputedMicroApp: React.FC<ClientComputedMicroAppProps> = (props) => {
    return (
        <ImportOnVisibility loadingFallback={<WidgetSkeletonRectangle />}>
            <ClientComputedMicroApp {...props} />
        </ImportOnVisibility>
    );
};

export { LazyClientComputedMicroApp as ClientComputedMicroApp };
