import { useMemo } from 'react';

import { BatchGoogleDriveFilesRequestParam as GDriveRequestParam } from '@lumapps/google-drive-pickers/api';
import { useGetGoogleDriveFiles } from '@lumapps/google-drive-pickers/hooks/useGetGoogleDriveFiles';
import { GDFile } from '@lumapps/google-drive-pickers/types';
import { BatchOneDriveFilesRequestParam as OneDriveRequestParam } from '@lumapps/one-drive-pickers/api';
import { useGetOneDriveFiles } from '@lumapps/one-drive-pickers/hooks/useGetOneDriveFiles';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';
import { useSelector } from '@lumapps/redux/react';
import { connectedUserSelector, isGoogleAccount, isMicrosoftAccount } from '@lumapps/user/ducks/selectors';

/**
 * Hook that will get the updated informations of drive files
 * Should return an array of google drive files or one drive files
 * */
export const useUpdateDriveFiles = <T,>(
    config: {
        gDrive?: { requestParam: GDriveRequestParam };
        oneDrive?: { requestParam: OneDriveRequestParam };
    },
    mapFunctions?: { gDrive?: (files: GDFile[]) => T; oneDrive?: (files: OneDriveDocument[]) => T },
) => {
    const currentUser = useSelector(connectedUserSelector);
    const token = currentUser?.token;
    const isGoogle = useSelector(isGoogleAccount);
    const isMicrosoft = useSelector(isMicrosoftAccount);

    const {
        data: googleDriveData,
        isFetching: googleDriveIsFetching,
        isError: googleDriveIsError,
    } = useGetGoogleDriveFiles(config.gDrive?.requestParam ? { ...config.gDrive.requestParam, token } : undefined);

    const {
        data: oneDriveData,
        isFetching: oneDriveIsFetching,
        isError: oneDriveIsError,
    } = useGetOneDriveFiles(config.oneDrive?.requestParam);

    return useMemo(() => {
        if (isGoogle) {
            return {
                data:
                    googleDriveData && !!mapFunctions?.gDrive ? mapFunctions?.gDrive(googleDriveData) : googleDriveData,
                isFetching: googleDriveIsFetching,
                isError: googleDriveIsError,
            };
        }
        if (isMicrosoft) {
            return {
                data: oneDriveData && !!mapFunctions?.oneDrive ? mapFunctions?.oneDrive(oneDriveData) : oneDriveData,
                isFetching: oneDriveIsFetching,
                isError: oneDriveIsError,
            };
        }

        return {};
    }, [
        googleDriveData,
        googleDriveIsError,
        googleDriveIsFetching,
        isGoogle,
        isMicrosoft,
        mapFunctions,
        oneDriveData,
        oneDriveIsError,
        oneDriveIsFetching,
    ]);
};
