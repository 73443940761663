import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

export const designerContentPreviewRoute: Route = {
    appId: AppId.designer,
    path: `/${URL_PREFIX}/designer/content/preview/:id`,
};

export const designerContentPreview = (id: string): Route => ({
    ...designerContentPreviewRoute,
    params: { id },
});
