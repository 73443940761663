import { Location, Range, Point } from 'slate';

export const toRange = (location: Location): Range => {
    if (Range.isRange(location)) {
        return location;
    }
    if (Point.isPoint(location)) {
        return { anchor: location, focus: location };
    }
    return toRange({ path: location, offset: 0 });
};
