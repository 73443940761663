import React from 'react';

import { mdiArrowLeft } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SHORTCUTS } from '../../keys';
import { KeyboardKey } from '../KeyboardKey';

/** Display the "Left" arrow key with an accessible label. */
export const KeyLeftArrow = () => {
    const { translateKey } = useTranslate();
    return <KeyboardKey label={translateKey(SHORTCUTS.LEFT_ARROW_LABEL)} icon={mdiArrowLeft} />;
};
