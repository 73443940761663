(function IIFE() {
    'use strict';

    /////////////////////////////

    function ContentForm(AbstractContentForm, Content) {
        'ngInject';

        return AbstractContentForm.createAbstractContentFormService(Content);
    }

    /////////////////////////////

    angular.module('Services').service('ContentForm', ContentForm);
})();
