import dropRight from 'lodash/dropRight';

import { Editor, Range, NodeEntry, Path, Element, Transforms } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';

import { isList } from './isList';
import { isListItem } from './isListItem';

export const splitListItem = (editor: Editor) => {
    const { selection } = editor;
    if (!selection) {
        return;
    }
    const parentListItem = findParent(editor, Range.start(selection).path, isListItem);
    if (!parentListItem) {
        return;
    }
    Editor.withoutNormalizing(editor, () => {
        // The block node at selection end path
        const endBlock = findParent(
            editor,
            Range.end(editor.selection as Range).path,
            (n) => Element.isElement(n) && Editor.isBlock(editor, n),
        ) as NodeEntry<Element>;

        // Get the direct sibling's path of the block node at selection end path.
        const nextSiblingPath = getSiblingPath(endBlock[1], 'after');
        // We keep track of the next sibling path to merge it when the split has been applied.
        const nextSiblingPathRef = Editor.pathRef(editor, nextSiblingPath as Path);

        Transforms.splitNodes(editor, { at: editor.selection as Range, always: true });

        if (Range.isCollapsed(editor.selection as Range)) {
            const parentBlock = findParent(
                editor,
                Range.start(editor.selection as Range).path,
                (node) => Element.isElement(node) && Editor.isBlock(editor, node),
            ) as NodeEntry<Element>;
            // The first split doesn't split the li, it only splits the p so we need to split the li
            Transforms.splitNodes(editor, { at: parentBlock[1] });
        } else if (nextSiblingPathRef.current && isList(Editor.node(editor, nextSiblingPathRef.current)[0])) {
            // When the next sibling of the endBlock is a list, the split puts this list in another li
            // So we need to merge this wrapping li with the parent li of the endBlock, to get a correct format.
            Transforms.mergeNodes(editor, {
                at: dropRight(nextSiblingPathRef.current) as Path,
            });
        }
        nextSiblingPathRef.unref();
    });
};
