import * as React from 'react';
import classNames from 'classnames';
import * as propTypes from 'prop-types';

import * as uiTypes from '../types';
import { Icon } from '../icons/Icon';

/**
 * Renders a color item for the `ColorPalette`.
 */
export class ColorPaletteItem extends React.PureComponent {
    static propTypes = {
        /** Whether the `value` is the the accent color used throughout the UI theme. */
        accent: propTypes.bool,
        /** Called when clicking on this color item. */
        onClick: propTypes.func,
        /** Whether the `value` is the the primary color used throughout the UI theme. */
        primary: propTypes.bool,
        /** Whether this color item is selected. */
        selected: propTypes.bool,
        /** Color to display. */
        value: uiTypes.color,
    };

    render() {
        const { value, selected, primary, accent, onClick } = this.props;

        return (
            <div
                id={value}
                className={classNames('color-palette__color', selected && 'color-palette__color--is-selected')}
                onClick={onClick}
            >
                <div className="color-palette__color-wrapper">
                    {primary && (
                        <div className="color-palette__color-indicator">
                            <Icon value="numeric-1-box" size="xs" color="white" type="flat" />
                        </div>
                    )}
                    {accent && (
                        <div className="color-palette__color-indicator">
                            <Icon value="numeric-2-box" size="xs" color="white" type="flat" />
                        </div>
                    )}
                    <div className="color-palette__color-background" style={{ backgroundColor: value }} />
                    <span className="color-palette__color-label">{value}</span>
                </div>
            </div>
        );
    }
}
