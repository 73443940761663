import React from 'react';

import { Text } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { LabelWithHighlightedQuery } from '@lumapps/search/components/LabelWithHighlightedQuery';
import { getSearchQuery } from '@lumapps/search/ducks/selectors';

export type SearchBoxOptionLabelProps = {
    label: string;
};

export const SearchBoxOptionLabel: React.FC<SearchBoxOptionLabelProps> = ({ label }) => {
    const searchQuery = useSelector(getSearchQuery);

    return (
        <Text as="span" truncate>
            <LabelWithHighlightedQuery label={label} searchQuery={searchQuery} />
        </Text>
    );
};
