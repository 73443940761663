import { useMemo } from 'react';

import { mdiLinkVariant } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { TypographyEditor } from '@lumapps/wrex-typography/types';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { Editor, Range, ReactEditor, useSlate } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { LINK } from '../../../constants';
import { WREX_LINK } from '../../../keys';
import { LinkEditor } from '../../../types';

export const useLinkButton = (otherProps?: any) => {
    const editor = useSlate() as ReactEditor & LinkEditor & TypographyEditor;
    const { translateAndReplace, translateKey } = useTranslate();

    const { selection } = editor;
    const currentLink = useMemo(() => {
        return selection ? Editor.parent(editor, Range.start(selection)) : undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);
    const isSelected = editor.isLinkActive(currentLink);
    const isDisabled = !editor.isLinkAllowed();

    const linkButton = useMemo(() => {
        const onClick = () => {
            if (!editor.selection) {
                initSelection(editor, 'inline');
            }
            if (editor.isLinkActive(currentLink)) {
                const currentLinkUrl = currentLink && currentLink[0] ? currentLink[0].href : '';
                editor.wrapLink(editor, currentLinkUrl as string, currentLink?.[1], true);
            } else {
                editor.wrapLink(editor, '');
            }
            focusAt(editor, editor.selection);
        };

        const linkButtonProps: ToolbarItem = {
            type: 'toggle-option',
            itemKey: 'link',
            icon: mdiLinkVariant,
            tooltipLabel: translateAndReplace(WREX_LINK.LINK_BUTTON_WITH_SHORTCUT, {
                shortcut: PLUGIN_SHORTCUTS[LINK].shortcut,
            }),
            verticalModeProps: {
                label: translateKey(WREX_LINK.LINK_BUTTON_LABEL),
                tooltipLabel: translateAndReplace(WREX_LINK.LINK_BUTTON_WITH_SHORTCUT, {
                    shortcut: PLUGIN_SHORTCUTS[LINK].shortcut,
                }),
            },
            onClick,
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            otherProps,
        };
        return linkButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLink, isDisabled, isSelected, translateAndReplace, otherProps?.isDisabled]);

    return linkButton;
};
