import React, { HTMLAttributes } from 'react';

import { FeedArticle } from '@lumapps/lumx-feed/components/FeedArticle';

import { WidgetState } from '../../../ducks/type';

export interface BlockListItemProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * The pagination type to use.
     * If "infinite" is set, the item will expect to be set within a Feed.
     */
    paginationType: WidgetState['paginationType'];
    /** The current index of the element within the visible list */
    index: number;
    /** The total of visible children. */
    totalChildren: number;
}

/**
 * Wrapper of a single item within the BlockList.
 * Depending on the pagination type, the rendered component can either
 * be a "Feed" article or a simple div.
 */
export const BlockListItem: React.FC<BlockListItemProps> = ({
    paginationType,
    children,
    index,
    totalChildren,
    ...wrapperProps
}) => {
    if (paginationType === 'infinite') {
        return (
            <FeedArticle {...wrapperProps} index={index} total={totalChildren}>
                {children}
            </FeedArticle>
        );
    }

    return <div {...wrapperProps}>{children}</div>;
};
