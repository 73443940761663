import { get } from '@lumapps/constants';

const Config = get();
const orgId = Config.customerId;

export const learningApi = {
    URL: `${Config.haussmannCell}/v2/organizations/${orgId}/learning/centaurus/api`,
};
export const mcLearningApi = {
    URL: `${Config.haussmannCell}/v2/organizations/${orgId}/learning/mc/jwtlogin?redirect_page=`,
};
/** Feature flag used for under development features */
export const LEARNING_ALPHA_FF = 'learning-alpha';

/** Feature flag used for beta features */
export const LEARNING_BETA_FF = 'learning-beta';

/**  Feature flag used for learning specific features */
export const LEARNING_FF = 'learning';

export enum STATUSES_LEARNING_ENTITIES {
    TODO = 'todo',
    STARTED = 'started',
    COMPLETED = 'completed',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
}
