export enum INSTANCE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244401
     */
    INSTANCE_SETTINGS = 'ADMIN.NAV.INSTANCE_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244437
     */
    INSTANCES = 'ADMIN.NAV.INSTANCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=53170296
     */
    FAVORITES_NO_ENTRIES = 'FRONT.FAVORITES.NO_ENTRIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?view=multi&search=key::ADMIN.NAV.INSTANCES.MANAGEMENT
     */
    MANAGEMENT = 'ADMIN.NAV.INSTANCES.MANAGEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?view=multi&search=key::ADMIN.NAV.INSTANCES.TITLE
     */
    INSTANCES_TITLE = 'ADMIN.NAV.INSTANCES.TITLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452107
     */
    LOGO = 'ADMIN_INSTANCE_SETTINGS_LOGO',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452103
     */
    FAVICON = 'ADMIN_INSTANCE_SETTINGS_FAVICON',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452105
     */
    UPLOAD_FAVICON = 'ADMIN_INSTANCE_SETTINGS_FAVICON_UPLOAD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452108
     */
    UPLOAD_LOGO = 'ADMIN_INSTANCE_SETTINGS_LOGO_UPLOAD',
}
