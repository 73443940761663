import { Orientation } from '@lumapps/lumx/react';

/** Invert LumX orientation */
export function invertOrientation(orientation?: Orientation) {
    if (orientation === Orientation.vertical) {
        return Orientation.horizontal;
    }
    if (orientation === Orientation.horizontal) {
        return Orientation.vertical;
    }
    return undefined;
}
