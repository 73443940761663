import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function HeaderSettingsController($rootScope, $state, $timeout, AbstractPicker, Config, ConfigTheme, Content,
        Features, Header, Instance, LxNotificationService, MediaConstant, Translation, User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if we are picking media for the first time.
         * When picking for the first time, create a new custom header.
         * When finishing the picking, if nothing has been picked, reset to the default header.
         * Also, don't display the settings during the initial picking.
         *
         * @type {boolean}
         */
        vm.isInitialPicking = false;

        /**
         * Indicates if we're currently editing the instance style header or not.
         *
         * @type {boolean}
         */
        vm.isInstanceStyle = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Config = Config;
        vm.ConfigTheme = ConfigTheme;
        vm.Content = Content;
        vm.Header = Header;
        vm.MediaConstant = MediaConstant;
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the css styles to apply to the selected media image.
         *
         * @param  {Object} media A media object.
         * @return {Object} A css properties object.
         */
        function getMediaStyles(media) {
            return {
                'background-image': 'url(' + Utils.resizeImage(media.content[0].value) + ')',
                'background-size': 'cover',
                'height': '80px',
            };
        }

        /**
         * Initialize the css selectors for a given mediaId.
         *
         * @param {string} mediaId The identifier of the media to initialize the css class of.
         */
        function initMediaClass(mediaId) {
            var properties = _.get(Header.getCurrent(), 'properties', {});
            Header.getCurrent().properties = properties;

            var classNames = properties.mediaClass || {};
            Header.getCurrent().properties.mediaClass = classNames;

            if (angular.isDefinedAndFilled(classNames[mediaId])) {
                vm.mediaClass[mediaId] = classNames[mediaId].split(', ');
            } else {
                vm.mediaClass[mediaId] = [];
            }
        }

        /**
         * Check if the current header is the default one.
         *
         * @return {boolean} If the current header is the default one or not.
         */
        function isDefaultHeader() {
            var currentHeader = Header.getCurrent();

            return currentHeader.name === 'default' || currentHeader.id === Header.getDefaultHeader().id;
        }

        /**
         * Create a new header if needed and open the media picker.
         */
        function manageSlideshowMedia() {
            $timeout(function delayOpenMediaPicker() {
                Utils.waitForAndExecute('#media-picker-header', AbstractPicker);
            });
        }

        /**
         * Override the default header.
         */
        function overrideHeader() {
            if (!vm.isDefaultHeader() || vm.isInstanceStyle) {
                return;
            }

            var newHeader = angular.fastCopy(Header.getCurrent() || {});

            newHeader = _.omit(newHeader, ['createdAt', 'id', 'uid', 'updatedAt', 'updatedBy']);

            angular.extend(newHeader, {
                author: User.getConnected().email,
                instance: Instance.getCurrentInstanceId(),
                name: generateUUID(),
            });

            Header.setCurrent(newHeader);
        }

        /**
         * Revert to the default header.
         */
        function revertToDefault() {
            if (vm.isDefaultHeader()) {
                return;
            }

            LxNotificationService.confirm(
                Translation.translate('HEADER_SLIDESHOW_REVERT_CONFIRM'),
                Translation.translate('HEADER_SLIDESHOW_REVERT_CONFIRM_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function onHeaderRevertConfirm(answer) {
                    if (answer) {
                        Header.setCurrent(Header.getDefaultHeader());
                        $rootScope.$broadcast('header-init');
                    }
                });
        }

        /**
         * Update the css class to apply to the media elements in the slideshow.
         *
         * @param {string} mediaId The identifier of the media to update the css class of.
         */
        function updateMediaClass(mediaId) {
            Header.getCurrent().properties.mediaClass[mediaId] = (vm.mediaClass[mediaId] || []).join(', ');
        }

        /**
         * Reload the header.
         *
         * @param {boolean} [hardReset=false] Indicates if we want to hard reset it or simply refresh it with the new
         *                                    config.
         */
        function reloadHeader(hardReset) {
            hardReset = hardReset || false;
            $rootScope.$broadcast('template__reload-header', hardReset);
        }

        /////////////////////////////

        vm.getMediaStyles = getMediaStyles;
        vm.initMediaClass = initMediaClass;
        vm.isDefaultHeader = isDefaultHeader;
        vm.manageSlideshowMedia = manageSlideshowMedia;
        vm.overrideHeader = overrideHeader;
        vm.reloadHeader = reloadHeader;
        vm.revertToDefault = revertToDefault;
        vm.updateMediaClass = updateMediaClass;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var contentId = Content.getCurrent().id;
            vm.mediaClass = {};
            vm.isInstanceStyle = $state.current.name === 'app.front.instance-style';

            vm.mediaPickerUuid = (!vm.isInstanceStyle && angular.isDefinedAndFilled(contentId)) ?
                ('media-picker-header-' + contentId) : 'media-picker-header';
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('HeaderSettingsController', HeaderSettingsController);
})();
