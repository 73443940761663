import React, { useEffect } from 'react';

import { useDispatch } from '@lumapps/redux/react';
import { standardizeTranslateObject, GLOBAL, TranslateObject, useTranslate } from '@lumapps/translations';
import { BlockList } from '@lumapps/widget-base/components/Block/BlockList/BlockList';
import { BlockNoResults } from '@lumapps/widget-base/components/Block/BlockNoResults';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { BlockLink as BlockLinkProp, ClientComputedLinksProps, LegacyLinksProperties } from '../../types';
import { BlockLink } from '../BlockLink/BlockLink';

export const getBlockLinks = (
    properties: LegacyLinksProperties,
    translate: (obj?: TranslateObject) => string | undefined | null,
) => {
    const { items, style, hasThumbnail = true } = properties;

    return items
        ?.map(
            (item) =>
                ({
                    ...item,
                    description: translate(item.description),
                    title: translate(item.title),
                    link: translate(item.link),
                    type: 'BlockLink',
                    theme: style?.content?.theme,
                    hasThumbnail,
                }) as BlockLinkProp,
        )
        .filter((blockLink) => blockLink.title && blockLink.link);
};

export const ClientComputedLinks: React.FC<ClientComputedLinksProps> = ({ properties = {}, uuid }) => {
    const dispatch = useDispatch();
    const { style, noResults, noFallback } = properties;

    const { translateObject, translateKey } = useTranslate();

    const translate = (obj?: TranslateObject) =>
        translateObject(standardizeTranslateObject(obj), undefined, noFallback);

    const blockLinks: BlockLinkProp[] | undefined = getBlockLinks(properties, translate);

    const isEmptyWidget = !blockLinks?.length;

    useEffect(() => {
        if (!noResults && isEmptyWidget) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [isEmptyWidget, dispatch, noResults, uuid]);

    if (isEmptyWidget) {
        return noResults ? (
            <BlockNoResults theme={style?.content?.theme} placeholderText={translateKey(GLOBAL.NO_RESULTS) as string} />
        ) : null;
    }

    return (
        <BlockList loadingState="loaded" theme={style?.content?.theme}>
            {blockLinks?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <BlockLink key={index} {...item} />
            ))}
        </BlockList>
    );
};
