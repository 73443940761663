import { getCurrentContentId, isInDesignerMode } from '@lumapps/contents/ducks/selectors';
import { error as notifyError } from '@lumapps/notifications';
import { connect } from '@lumapps/redux/react';
import { Dispatch, BaseStore } from '@lumapps/redux/types';
import { inputLocaleSelector } from '@lumapps/translations/selectors';

import { MandatoryReadWithEffect, MandatoryReadProps } from './MandatoryReadWithEffect';

export const mapStateToProps = (state: BaseStore): Pick<MandatoryReadProps, 'contentId' | 'inputLang'> => {
    // We define an input language only for designer mode, because we want this language to be
    // reference one in this context.
    const inputLang = isInDesignerMode(state) ? inputLocaleSelector(state) : undefined;

    return {
        contentId: getCurrentContentId(state),
        inputLang,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        displayError: (message: string) => {
            dispatch(
                notifyError({
                    translate: message,
                }),
            );
        },
    };
};
const ConnectedMandatoryRead = connect(mapStateToProps, mapDispatchToProps)(MandatoryReadWithEffect);

export { ConnectedMandatoryRead as ClientComputedMandatoryRead };
