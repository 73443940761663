import BaseApi from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';
import { uploadFiles } from '@lumapps/base-api/utils/files';
import { redundantFetchApi } from '@lumapps/base-api/utils/redundantFetchApi';
import { get } from '@lumapps/constants';

import { ContainerType, LibraryType, UploadURL, UploadedMedia } from '../types';

const constants = get();

export const mediaApi = new BaseApi({ path: 'medias', version: BaseApi.versions.v2 });

/**
 * API /DELETE to delete the specific media
 */
export const deleteMediav2 = async (docId: string) => {
    await mediaApi.delete(docId);
};

export const getMedia = (id: string) => {
    return mediaApi.get<UploadedMedia>(id);
};

export const editMedia = (mediaId: string, name: string) => {
    return mediaApi.put<UploadedMedia>(mediaId, { name });
};

export interface MoveMediaProps {
    containerId: string;
    containerType: string;
    mediaId: string;
}

export const moveMedia = (params: MoveMediaProps[]) => {
    return mediaApi.put<string>('/containers', params);
};

export interface UploadMediaOptions {
    /** The files to upload */
    files: File[] | FileList;
    /** The id of the container */
    containerId: string;
    /** The type of the container */
    containerType: ContainerType;
    /** Whether uploaded medias should be available publicly or not */
    arePublic?: boolean;
    /** Whether the promise should resolve before or after medias are uploaded */
    waitForMediasToBeUploaded?: boolean;
    /** The type of the library (api will make it default to "default" if undefined) */
    libraryType?: LibraryType;
}

export const getMediaUploadUrl = ({ files, ...rest }: UploadMediaOptions) => {
    const fileNames = Array.from(files).map((file) => file.name);

    return mediaApi.post<ServerListResponse<UploadURL>>('', {
        fileNames,
        ...rest,
    });
};

const MAX_TRY = 10;
const DELAY_RETRY = 1000;

/**
 * Uploads a list of files, returning the corresponding ids and URLs for each of them
 * @param UploadMediaOptions
 * @returns List of uploaded medias
 */
export const uploadMedias = async (options: UploadMediaOptions) => {
    const { files, waitForMediasToBeUploaded = true } = options;

    /**
     * There are several steps involved into uploading a media.
     * 1. We retrieve the URL where we are going to upload our image and we
     * take the opportunity to save each of the media's ids for future reference.
     */
    const response = await getMediaUploadUrl(options);

    /**
     * 2. We upload the files to the provided URLs.
     */
    await uploadFiles({
        files,
        uploadFilesConfiguration: response.data,
    });

    if (waitForMediasToBeUploaded) {
        const mediaIds = response.data.items.map((media) => media.mediaId);

        /**
         * 3. Unfortunately, the only way we have of figuring out if the image was uploaded or not
         * is to spam the GET <media-id> until the API says that the media was uploaded.
         */
        const uploadPromises = Promise.all(
            mediaIds.map((mediaId: string) => {
                return redundantFetchApi(
                    () =>
                        getMedia(mediaId).then((resp) => {
                            /**
                             * The API does not return an error when the image is still uploading
                             * but it returns a message. If this happens, we throw an error so that the
                             * redundantFetchApi can try again. This is silly, I know, don't get me started.
                             * But it is unfortunately how it works.
                             */
                            if (resp.data.message) {
                                throw new Error('not finished uploading');
                            }

                            return resp.data;
                        }),
                    MAX_TRY,
                    DELAY_RETRY,
                );
            }),
        );

        /**
         * Once all images are uploaded, we retrieve the responses and send them as the return.
         */
        const uploadedMedias = await uploadPromises;

        return uploadedMedias as UploadedMedia[];
    }

    const pendingMedias: UploadedMedia[] = response.data.items.map((media) => {
        return {
            mediaId: media.mediaId,
            containerId: options.containerId,
            containerType: options.containerType,
            organizationId: constants.customerId,
        };
    });

    return pendingMedias;
};
