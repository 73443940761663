export const QUICK_SEARCH_SUGGESTION_SKELETON_NUMBER = 3;
/**
 * The general namespace for the search box component.
 *
 * @type {string}
 * @constant
 */
export const NAMESPACE = 'searchbox';
export const SEARCH_BOX_ID = 'main-search-box';
export const DATA_NAMESPACE = 'search';

/**
 * The initial state for the searchbox. It defaults to empty
 * but in case we want to change it in the future, we can pass it as a prop.
 *
 * @type {string}
 * @constant
 */
export const INITIAL_SEARCH_QUERY = '';

/**
 * Id to set on the searchbox's footer to link it to the input.
 */
export const SEARCHBOX_FOOTER_ID = 'searchbox-footer';
