(function IIFE() {
    'use strict';

    /////////////////////////////

    function ModuleFrontService() {
        'ngInject';

        var service = {};

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the CSS class for a module.
         *
         * @param  {string} classParam The class parameter of the module.
         * @return {string} The module CSS class.
         */
        function getModuleClass(classParam) {
            return (angular.isDefinedAndFilled(classParam)) ? 'module--' + classParam : '';
        }

        /////////////////////////////

        service.getModuleClass = getModuleClass;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('ModuleFront', ModuleFrontService);
})();
