/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { adminDirectoryTags } from '@lumapps/directories-tags/routes';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiBookMultiple } from '@lumapps/lumx/icons';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { DEFAULT_DIRECTORY_ICON } from '../../constants';
import { DIRECTORIES } from '../../keys';
import { directoryEntriesRoute } from '../../routes';
import { Directory } from '../../types';

export interface DirectoriesSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** the list of directories to display */
    directories?: Directory[] | undefined;
    /** True if the current user is super admin */
    isSuperAdmin: boolean;
    /** True if the current user has access to the directories feature or not */
    isAllowed: boolean;
}

const DirectoriesSideNavigation = (props: DirectoriesSideNavigationProps) => {
    const { directories = [], isSuperAdmin = false, isAllowed = false } = props;
    const { translateKey, translateObject } = useTranslate();

    /** If there are no directories, we will render a blank section */
    if (directories.length === 0) {
        return null;
    }

    /**
     * If the user is superAdmin, we will render all directories and allow the user
     * to edit the content as well as the tags for the directory
     */
    if (isSuperAdmin) {
        return (
            <>
                {directories.map((directory: Directory) => (
                    <SideNavigationItem
                        label={translateObject(directory.name) || ''}
                        key={directory.id}
                        id="directories"
                        {...(directory.properties.icon
                            ? {
                                  dynamicIcon: directory.properties.icon,
                              }
                            : {
                                  icon: mdiBookMultiple,
                              })}
                    >
                        <SideNavigationItem
                            label={translateKey(DIRECTORIES.CONTENTS)}
                            route={directoryEntriesRoute(directory.id)}
                        />
                        <SideNavigationItem
                            label={translateKey(GLOBAL.TAGS)}
                            route={adminDirectoryTags(directory.id)}
                        />
                    </SideNavigationItem>
                ))}
            </>
        );
    }

    /** Finally, if the user has access to the directories, we just let them see the content section */
    return (
        <>
            {isAllowed &&
                directories.map((directory: Directory) => (
                    <SideNavigationItem
                        label={translateObject(directory.name) || ''}
                        key={directory.id}
                        {...(directory.properties.icon
                            ? {
                                  dynamicIcon: directory.properties.icon,
                              }
                            : {
                                  icon: DEFAULT_DIRECTORY_ICON,
                              })}
                        route={directoryEntriesRoute(directory.id)}
                    />
                ))}
        </>
    );
};

DirectoriesSideNavigation.displayName = 'SideNavigationItem';

export { DirectoriesSideNavigation };
