import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiTextBoxMultipleOutline } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { isContentBulkAllowed } from '../../ducks/selectors';
import { CONTENT_ADMIN } from '../../keys';
import { adminContentBulkRoute } from '../../routes';

export interface ContentBulkSideNavigationProps extends Partial<SideNavigationItemProps> {}

const ContentBulkSideNavigation = (props: Partial<ContentBulkSideNavigationProps>) => {
    const isAllowed = useSelector(isContentBulkAllowed);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={adminContentBulkRoute()}
            label={translateKey(CONTENT_ADMIN.ALL_CONTENT)}
            icon={mdiTextBoxMultipleOutline}
            {...props}
        />
    );
};

ContentBulkSideNavigation.displayName = 'SideNavigationItem';

export { ContentBulkSideNavigation };
