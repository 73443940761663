import type { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { logException } from '@lumapps/utils/log/logException';

import { FetchBlockParams, getWidgetBlocksByProperties } from '../../api';
import { LegacyWidget } from '../../types';
import { getFormattedWidgetFilterFetchParams } from '../../utils/getFormattedWidgetFilterFetchParams';
import { getOwnerResourceInfo } from '../selectors';
import { fetchWidgetBlocks } from './fetchWidgetBlocks';

export const fetchWidgetBlocksByProperties =
    ({
        params,
        legacyWidget,
        isMainWidget,
    }: {
        params?: FetchBlockParams;
        legacyWidget: LegacyWidget;
        isMainWidget?: boolean;
    }) =>
    (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        const { uuid: widgetId, widgetType } = legacyWidget;
        const currentState = getState();
        const filterFetchParams = getFormattedWidgetFilterFetchParams(currentState, params, widgetId);
        const fetchParams = { ...params, ...filterFetchParams };
        const ownerResourceInfo = getOwnerResourceInfo(currentState);

        dispatch(
            fetchWidgetBlocks(
                widgetId,
                (acceptLanguageHeader) =>
                    getWidgetBlocksByProperties(legacyWidget, fetchParams, acceptLanguageHeader, ownerResourceInfo),
                fetchParams,
                isMainWidget,
                widgetType,
            ),
        ).catch(logException);
    };
