import qs from '@lumapps/base-api/qs';

import { eventApi, paths } from '.';
import { RegistrationStatusTypes } from '../types';

/** Register the connected user to the event. */
export const registerToEvent = async (eventId: string, registrationStatus: RegistrationStatusTypes) => {
    const { data } = await eventApi.post(paths.eventSelfRegistration(eventId), null, {
        params: { registrationStatus },
        paramsSerializer: (params: any) => {
            return qs.stringify(params, { encode: false });
        },
    });
    return data;
};
