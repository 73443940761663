import { isMobileAllowed } from '@lumapps/mobile/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { ContentStreamsSideNavigation } from './ContentStreamsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isMobileAllowed(state);

    return {
        isAllowed,
    };
};

const ConnectedContentStreamsSideNavigation = connect(mapStateToProps)(ContentStreamsSideNavigation);

export { ConnectedContentStreamsSideNavigation as ContentStreamsSideNavigation };
