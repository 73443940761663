/* istanbul ignore file */
import { isTagzEnabled, isTagzAllowed } from '@lumapps/folksonomy/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { TagzSideNavigation } from './TagzSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isTagzEnabled(state) && isTagzAllowed(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedSideNavigation = connect(mapStateToProps, mapDispatchToProps)(TagzSideNavigation);

export { ConnectedSideNavigation as TagzSideNavigation };
