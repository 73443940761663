import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

import { getDirectories } from '@lumapps/directories/ducks/selectors';
import { Directory } from '@lumapps/directories/types';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { isConnected } from '@lumapps/user/ducks/selectors';

export const getFavoriteDirectories = createSelector(
    isConnected,
    getDirectories,
    instanceIdSelector,
    (isUserConnected, directories, instanceId): Directory[] => {
        if (!isUserConnected) {
            return [];
        }
        // TODO: order by name
        return orderBy(
            filter(directories, (directory) =>
                Boolean(directory.favorites && (directory.instance === instanceId || directory.inherited)),
            ),
            ['inherited'],
            ['desc'],
        );
    },
);
