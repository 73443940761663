import { Offset } from '@lumapps/lumx/react';

/** Base menu classname (reused on all sub components). */
export const BASE_CLASSNAME = 'lumx-menu';

/** Offset required (in pixel) so that items between submenus can be aligned. */
export const SUBMENU_OFFSET: Required<Offset> = { along: -8, away: 0 };

/** Delay before displaying loading alert message. */
export const LOADING_ALERT_DELAY = 300;

/** Delay (ms) before opening a submenu on mouse over */
export const FLYOUT_DELAY_ENTER = 50;

/** Delay (ms) before closing a submenu on mouse leave */
export const FLYOUT_DELAY_LEAVE = 1000;
