/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const directoryRoute: Route = {
    path: '/:slug',
    legacyId: 'app.front.content-get',
    appId: AppId.legacy,
};

const moduleRoute: Route = {
    path: '/admin/module',
    legacyId: 'app.admin.module',
    appId: AppId.legacy,
};

const directoryEntriesRoutes: Route = {
    path: '/admin/directory/:id',
    legacyId: 'app.admin.new.directory',
    appId: AppId.backOffice,
};

const directoryEntriesRoute = (id: string): Route => {
    const params = {
        id,
    };

    return {
        ...directoryEntriesRoutes,
        params,
    };
};

/**
 * Retrieves the route for module's admin.
 */
const admin = (): Route => {
    return moduleRoute;
};

const directory = ({ slug, instanceSlug }: { slug: string; instanceSlug?: string }): Route => {
    const params = {
        slug,
    };

    return {
        ...directoryRoute,
        params,
        instanceSlug,
    };
};

const baseAdminDirectory = (id: string, route: Route): Route => {
    const params = {
        id,
    };

    return {
        ...route,
        params,
    };
};

export { admin, moduleRoute, directory, baseAdminDirectory, directoryEntriesRoute, directoryEntriesRoutes };
