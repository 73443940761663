import BaseApi from '@lumapps/base-api';
import { ServerListResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { CustomContentType } from '@lumapps/content-types/types';

import { Template } from '../types';

const MAX_RESULTS = 100;

export const templateApi = new BaseApi({ path: 'template' });

export interface ListAPIParams {
    customContentType: CustomContentType['id'];
    instance: string;
    lang?: string;
    maxResults?: number;
    cursor?: string;
    showTemplatesWithInheritedOnlyParam?: boolean;
    fields?: string;
    requestKey?: string;
}

export const list = async ({
    cursor,
    customContentType,
    instance,
    lang,
    maxResults = MAX_RESULTS,
    showTemplatesWithInheritedOnlyParam = true,
    fields,
    requestKey,
}: ListAPIParams) => {
    return templateApi.getCacheFirst<ServerListResponse<Template>>(
        'list',
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            params: {
                fields,
                cursor,
                customContentType,
                instance,
                lang,
                maxResults,
                ...(!showTemplatesWithInheritedOnlyParam && { visibleInheritedOnly: false }),
            },
        },
        true,
        requestKey !== undefined,
        requestKey,
    );
};

/**
 * Cancels the list endpoint
 */
export const listCancel = (requestKey: string) => {
    return templateApi.cancel('/list', undefined, requestKey);
};

export interface DeleteTemplateAPIParams {
    uid: string[];
}

export const remove = async (params: DeleteTemplateAPIParams = { uid: [] }) => {
    return templateApi.delete('deleteMulti', { params });
};
