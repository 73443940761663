import React from 'react';

import { TextField, TextFieldProps } from '@lumapps/lumx/react';

import { FILTERS_MODE } from '../../constants';
import { UseTextFilter } from '../../hooks/useTextFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

import './index.scss';

export interface TextFilterProps extends FilterProps {
    /** list of options to display as chips */
    selection: string;
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelected: UseTextFilter['onSelected'];
    /** additional text field props */
    textFieldProps?: Partial<TextFieldProps>;
}

const CLASSNAME = 'text-field-filter';
/**
 * Filter that renders a text field as a filter.
 * @family Filters
 * @param TextFilterProps
 * @returns TextFilter
 */
export const TextFilter = ({ selection, onSelected, textFieldProps = {}, mode, ...props }: TextFilterProps) => {
    return (
        <Filter {...props} hideTitle mode={mode}>
            <TextField
                className={CLASSNAME}
                value={selection}
                onChange={onSelected}
                {...textFieldProps}
                label={mode === FILTERS_MODE.EXPOSED_FACETS ? undefined : props.title}
                {...textFieldProps}
            />
        </Filter>
    );
};
