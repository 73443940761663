import { isText } from '../../../slate/utils/isText';
import type { Wrex } from '../../../types';
import * as dita from '../types';
import { OPTIONS } from './options';

/**
 * Convert mark
 */
const convertMark = (markName: string, markValue: any): dita.Element | null => {
    const mdMark = OPTIONS.marks[markName];
    if (!markValue || !mdMark) {
        return null;
    }
    return mdMark(markValue);
};

/**
 * Convert text node.
 */
export const convertTextNode = ({ text, ...marks }: Wrex.Text) => {
    const textDita: dita.Text = {
        type: 'text',
        value: text,
    };

    return Object.entries(marks).reduce((node: dita.Node, [markName, markValue]) => {
        const converted = convertMark(markName, markValue);
        if (!converted) {
            return node;
        }
        converted.children.push(node);
        return converted;
    }, textDita);
};

/**
 * Convert node.
 */
export const convertNode = (node: Wrex.Node, parentDita?: any): any => {
    if (isText(node)) {
        return convertTextNode(node);
    }

    const elementConverter = OPTIONS.elements[(node as any).type];

    if (!elementConverter) {
        const children = node.children.map((n) => convertNode(n, parentDita));
        parentDita.children.push(...children);
        return parentDita;
    }

    const children = node.children.map((n) => convertNode(n));
    const converted = elementConverter(node, children);
    if (parentDita) {
        parentDita.children.push(converted);
    }
    return converted;
};
