import { notifyCustomizationSubscribers } from '@lumapps/customizations/ducks/thunks';
import { Dispatch } from '@lumapps/redux/types';

import { normalisePageId } from '../utils/normalisePageId';
import { actions } from './slice';

/**
 * Will update the redux store with the pageId each time it change
 * @param id identifier of the page
 * @param shouldDispatchToCusto should we dispatch the event to customization api?
 */
export const setCurrentPage = (id: string, shouldDispatchToCusto: boolean) => (dispatch: Dispatch) => {
    const pageId = normalisePageId(id);
    dispatch(actions.setCurrentPage(pageId));

    if (shouldDispatchToCusto) {
        dispatch(notifyCustomizationSubscribers(pageId));
    }
};
