import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';
import get from 'lodash/get';

/////////////////////////////

function WidgetBreadcrumbController($location, $state, Content, Customer, Features, Instance, MainNav, Translation, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////
    
    /**
     * Whether the widget should be displayed in NGI or not.
     */
    vm.isNGICompatible = Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2');

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.MainNav = MainNav;
    vm.Translation = Translation;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get back to the parent content.
     */
    function back() {
        const breadcrumb = Content.getBreadcrumb();
        const parentContent = breadcrumb[breadcrumb.length - 2];

        if (angular.isDefinedAndFilled(get(parentContent, 'slugFull'))) {
            $state.go('app.front.content-get', {
                slug: Translation.translate(parentContent.slugFull),
            });
        }
    }

    /**
     * Get the widget's CSS classes.
     *
     * @return {Array} The widget's CSS classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        return widgetClass;
    }

    /**
     * Go to the home page.
     */
    function goToHome() {
        let path = '';

        if ($location.path().indexOf('/a/') === 0) {
            path = `/a/${Customer.getCustomerSlug()}`;
        }

        path += `/${Instance.getInstance().slug}`;

        $location.path(path);
    }

    /**
     * Check if the widget is empty.
     * The widget is empty if the menu is not loading and there is no element in the breadcrumbs.
     *
     * Used by the designer mode.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        // NGI will handle the empty state.
        if(_shouldDisplayAsNGI()) {
            return false
        }

        return !_shouldDisplayAsNGI() && !MainNav.isCallInProgress() && angular.isUndefinedOrEmpty(Content.getBreadcrumb());
    }

    /**
     * Check if the widget is hidden.
     * The widget is hidden if we are in read mode, the menu is not loading and there is no element in the
     * breadcrumb.
     *
     * Used by the read mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        if (
            !vm.parentCtrl.designerMode() &&
            !vm.widget.properties.noResults &&
            !MainNav.isCallInProgress() &&
            angular.isUndefinedOrEmpty(Content.getBreadcrumb())
        ) {
            vm.parentCtrl.isHidden = true;
        } else {
            vm.parentCtrl.isHidden = false;
        }

        return vm.parentCtrl.isHidden;
    }

    function _shouldDisplayAsNGI() {
        return vm.isNGICompatible;
    }

    function setAsNonNgiCompatible() {
        vm.isNGICompatible = false;
    }

    function setAsNgiCompatible() {
        const isInDesignerContext = vm.parentCtrl.designerMode();
        // Prevent the switch to true if the required FF are not enabled.
        if(isInDesignerContext && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2')) {
            vm.isNGICompatible = true;
        }
    }

    /////////////////////////////

    vm.back = back;
    vm.getWidgetClass = getWidgetClass;
    vm.goToHome = goToHome;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.shouldDisplayAsNGI = _shouldDisplayAsNGI;
    vm.setAsNonNgiCompatible = setAsNonNgiCompatible;
    vm.setAsNgiCompatible = setAsNgiCompatible;

    /////////////////////////////

    /**
     * Set parent controller.
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        const isInDesignerContext = vm.parentCtrl.designerMode();
        
        // If we are not in the designer context or the content is not v2 compatible
        // we fallback to legacy display (we consider unsaved content as v2 compatible).
        // Here we are trying to guess which display will be the correct one in view mode.
        // This is not perfect, but we want to avoid too much logic in the frontend to know if a content is
        // compatible v2 or not (widget types presents on the content + some specific settings on some widgets)
        if ((!isInDesignerContext || (Content.getAction() !== 'create' && Content.getCurrent()?.template.isV2Compatible === false))) {
            setAsNonNgiCompatible();
        }
    };
}

/////////////////////////////

/**
 * The breadcrumb widget.
 * Displays a breadcrumb.
 *
 * @param  {Object} widget The widget configuration object.
 * @return {Widget} The widget.
 */
function widgetBreadcrumbDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc
    function WidgetBreadcrumbLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetBreadcrumbController,
        controllerAs: 'vm',
        link: WidgetBreadcrumbLink,
        replace: true,
        require: ['widgetBreadcrumb', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-breadcrumb/views/widget-breadcrumb.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetBreadcrumb', widgetBreadcrumbDirective);

/////////////////////////////

export { widgetBreadcrumbDirective };
