import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import { mdiCheckAll, mdiDeleteForever, mdiSettings } from '@lumapps/lumx/icons';
import { Link } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getUserSettingsRoute, USER_SETTINGS_VIEWS } from '@lumapps/user-settings/routes';

import { DATA_NAMESPACE } from '../../constants';
import { NOTIFICATIONS_CENTER } from '../../keys';

export interface NotificationListHeaderContextMenuProps {
    onSetAllAsRead: () => void;
    onDeleteAll: () => void;
    onSettingsClick: (evt: React.MouseEvent) => void;
}

export const NotificationListHeaderContextMenu: React.FC<NotificationListHeaderContextMenuProps> = ({
    onSetAllAsRead,
    onDeleteAll,
    onSettingsClick,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(DATA_NAMESPACE);

    return (
        <ActionMenu label={translateKey(GLOBAL.OPTIONS)} {...get({ element: 'button', action: 'more' })}>
            <ActionMenu.Item
                key={NOTIFICATIONS_CENTER.MARK_ALL_AS_READ}
                onClick={onSetAllAsRead}
                icon={mdiCheckAll}
                {...get({ element: 'mark-all-as-read' })}
            >
                {translateKey(NOTIFICATIONS_CENTER.MARK_ALL_AS_READ)}
            </ActionMenu.Item>

            <ActionMenu.Item
                key={GLOBAL.DELETE_ALL}
                onClick={onDeleteAll}
                icon={mdiDeleteForever}
                color="red"
                {...get({ element: 'delete-all' })}
            >
                {translateKey(GLOBAL.DELETE_ALL)}
            </ActionMenu.Item>

            <ActionMenu.Divider />

            <ActionMenu.Item
                key={NOTIFICATIONS_CENTER.NOTIFICATIONS_SETTINGS}
                onClick={onSettingsClick}
                icon={mdiSettings}
                {...get({ element: 'settings' })}
                as={Link}
                to={getUserSettingsRoute(USER_SETTINGS_VIEWS.NOTIFICATIONS)}
            >
                {translateKey(NOTIFICATIONS_CENTER.NOTIFICATIONS_SETTINGS)}
            </ActionMenu.Item>
        </ActionMenu>
    );
};
