import {
    CUSTOM_CONTENT_TYPE_CONTRIBUTION_MODES,
    CUSTOM_CONTENT_TYPE_PERMISSIONS,
} from '@lumapps/content-types/permissions';

import { PermissionsState } from '../ducks/types';
import { getPermissionKey } from '../utils';

const computePermissions = (initState: any = {}) => {
    const { myPermissions = {}, user, userRoles = [] } = initState;
    const configuredAuthorizations: Record<string, boolean> = {};
    const canEditOnlyOwnContentAuthorizations: Record<string, boolean> = {};
    const canDesignContentTypeAuthorizations: Record<string, boolean> = {};

    /**
     * User roles determine what sections of the application we can access to if you have that
     * role assigned. Each role has a list of authorizations, where each authorization has a list
     * of actions that the user can perform.
     *
     * In order to avoid iterating through these roles each time we want to know the
     * permissions that a user has, we run it once on startup, and we create a simplified list
     * of actions using a Record<string, boolean> where the key is the action and the value is
     * if the user is allowed to execute the action or not. This should lower the computation
     * time from O(nˆ3) to O(1) while evaluating each action. We process this information on startup,
     * but during the course of the application, it is more efficient.
     *
     * TODO: we will want to migrate this code to the backend.
     */
    if (userRoles) {
        userRoles.forEach((userRole: any) => {
            const { authorizations = [] } = userRole;

            authorizations.forEach((authorization: any) => {
                const { actions = [], canEditOnlyOwnContent, contributionMode } = authorization;

                actions.forEach((action: any) => {
                    const { name, type, customContentType } = action;
                    const fullActionStr = getPermissionKey(name, type, customContentType);

                    configuredAuthorizations[fullActionStr] = true;

                    if (canEditOnlyOwnContent) {
                        canEditOnlyOwnContentAuthorizations[fullActionStr] = canEditOnlyOwnContent;
                    }

                    if (
                        fullActionStr.startsWith(CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT) &&
                        contributionMode === CUSTOM_CONTENT_TYPE_CONTRIBUTION_MODES.DESIGNER
                    ) {
                        // If at least one EDIT authorization allows to design, then the user is considered a designer.
                        canDesignContentTypeAuthorizations[customContentType] = true;
                    }
                });
            });
        });
    }

    const permissions: PermissionsState = {
        isSuperAdmin: user?.isSuperAdmin,
        instancesSuperAdmin: user ? user.instancesSuperAdmin : [],
        authorizations: { ...configuredAuthorizations, ...myPermissions },
        canEditOnlyOwnContentAuthorizations,
        canDesignContentTypeAuthorizations,
    };

    Object.assign(initState, {
        permissions,
    });

    return initState;
};

export { computePermissions };
