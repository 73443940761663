(function IIFE() {
    'use strict';

    /////////////////////////////

    function FeedBlockController() {
        'ngInject';

        // Nothing here yet
    }

    function FeedBlockDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: FeedBlockController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                feed: '=',
                viewMode: '@?',
            },
            templateUrl: '/client/front-office/modules/feed/views/feed-block.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('feedBlock', FeedBlockDirective);
})();
