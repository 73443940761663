/* istanbul ignore file */
export const MAX_NOTIFICATION_NUMBER = 10;
export const MAX_CHILD_NOTIFICATION_NUMBER = 5;
export const DATA_NAMESPACE = 'notifications';
/** TODO: check fields and remove unused */
export const NOTIFICATION_FIELDS =
    'items(actions(senderDetails(apiProfile(profilePicture,thumbnail,thumbnailPhotoUrl),firstName,lastName,fullName,uid,profilePictureUrl)),digest,notification(actionCount,contentDetails(endDate,postStatusDetails(title),slug,title,type),customerId,description,externalKey,group,groupName,instanceDetails(slug),instanceId,link,parentContentDetails(slug,title),parentId,priority,properties,thumbnail,title,type,uid,unreadNotificationCount,updatedAt,url,isRead)),more,callId,cursor';

export const NOTIFICATION_PARAMS = {
    fields: NOTIFICATION_FIELDS,
    maxResults: MAX_NOTIFICATION_NUMBER,
    sortOrder: '-priority',
};
