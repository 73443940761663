import loFind from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import trimStart from 'lodash/trimStart';

import { setInitialState } from '@lumapps/instance/ducks/reducer';
import keyBy from 'lodash/keyBy';

// ///////////////////////////

function InstanceService(
    $injector,
    $location,
    $q,
    $rootScope,
    Config,
    Customer,
    InitialSettings,
    InstanceFactory,
    LocalStorage,
    LumsitesBaseService,
    ReduxStore,
    Translation,
    Utils,
) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(InstanceFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
        postSave: function servicePostSave(obj) {
            return service.formatObjectForClient(angular.fastCopy(obj));
        },
        preSave: function servicePreSave(obj) {
            return service.formatObjectForServer(angular.fastCopy(obj));
        },
    });

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The current instance.
     *
     * @type {Object}
     */
    let _instance;

    /**
     * The promise of the load of siblings instances.
     *
     * @type {Promise}
     */
    // eslint-disable-next-line angular/deferred
    const _siblingsLoadingDeferred = $q.defer();

    /**
     * Contains the list of all siblings instances.
     * This is the equivalent of the previous `INSTANCES_SIBLINGS` Jinja variable.
     *
     * @type {Array}
     */
    let _siblings = [];

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The current action.
     *
     * @type {string}
     */
    service.currentAction = 'create';

    /**
     * The default parameters for the service requests.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     * Contains various status about the service.
     *
     * @type {Object}
     */
    service.is = {
        initialized: false,
        initializing: false,
        loading: {
            siblings: false,
        },
    };

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Copy an instance.
     *
     * @param {string}   instance The params of the instance to copy.
     * @param {Function} [cb]     The success callback.
     * @param {Function} [errCb]  The error callback.
     */
    function copy(instance, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        InstanceFactory.copy(instance, cb, errCb);
    }

    /**
     * Display a list of instances that don't have any parent.
     *
     * @param  {string} listKey  The listKey identifier.
     * @param  {string} ignoreId The id of the instance we want to ignore.
     * @return {Array}  The list of parentless instances.
     */
    function displayParentlessInstances(listKey, ignoreId) {
        const instances = service.displayList(listKey);

        if (!angular.isArray(instances)) {
            return [];
        }

        const orphans = [];
        angular.forEach(instances, function forEachInstances(instance) {
            if (ignoreId !== instance.id && angular.isUndefinedOrEmpty(instance.parent)) {
                orphans.push(instance);
            }
        });

        return orphans;
    }

    /**
     * Format server instance to fit with the client.
     *
     * @param  {Object} instance The server formatted instance.
     * @return {Object} The client formatted instance.
     */
    function formatObjectForClient(instance) {
        const logos = {};
        angular.forEach(instance.logo, function forEachLogos(logo) {
            logos[logo.lang] = logo.logo;
        });
        instance.logo = logos;

        if (angular.isUndefinedOrEmpty(instance.menuType)) {
            instance.menuType = 'horizontal';
        }

        return instance;
    }

    /**
     * Format client instance to fit with the server.
     *
     * @param  {Object} instance The client formatted instance.
     * @return {Object} The server formatted instance.
     */
    function formatObjectForServer(instance) {
        const logos = [];
        angular.forEach(instance.logo, function forEachLogo(logo, index) {
            logos.push({
                lang: index,
                logo,
            });
        });
        instance.logo = logos;

        return instance;
    }

    /**
     * Get a property from the current instance.
     *
     * @param  {string}  key           The Property name.
     * @param  {*}       [fallback]    The fallback value if nothing found.
     * @param  {boolean} [force=false] Act as overridden property, for to get customer property.
     * @return {*}       The wanted property if found.
     */
    function getAdminProperty(key, fallback, force) {
        const defaultValue = Customer.getAdminProperty(key, fallback);

        return Utils.getAdminProperty(key, service.getInstance(), defaultValue, fallback, force);
    }

    /**
     * Retrieve all the customer instances.
     *
     * @param  {Object}  [options] The options to apply to the filterize
     * @return {Promise} The promise of retrieving all the instances.
     */
    function getAllCustomerInstances(options) {
        const params = {
            maxResults: 999,
        };

        return $q(function onResolve(resolve) {
            service.filterize(
                angular.extend(params, options.params),
                resolve,
                Utils.displayServerError,
                options.listKey,
                options.projection,
            );
        });
    }

    /**
     * Get the current instance id.
     *
     * @return {string} The current instance id.
     */
    function getCurrentInstanceId() {
        return get(service.getInstance(), 'uid', get(InitialSettings, 'INSTANCE.uid'));
    }

    /**
     * Return the current instance available languages.
     *
     * @return {Array} The list of available languages for the current instance.
     */
    function getCurrentInstanceLangs() {
        const currentInstance = service.getInstance();

        return angular.isArray(get(currentInstance, 'langs')) ? currentInstance.langs : [];
    }

    /**
     * Get the current instance parent instance.
     *
     * @param  {boolean} [setCurrent=true] Set the instance get current.
     * @return {Object}  The parent instance.
     */
    function getCurrentInstanceParent(setCurrent) {
        setCurrent = angular.isDefined(setCurrent) ? setCurrent : true;

        const parent = setCurrent ? service.getCurrent().parent : service.getInstance().parent;

        return $q(function resolveParentInstance(resolve, reject) {
            service
                .getSiblings()
                .then(function onSiblingsListSuccess(siblings) {
                    if (angular.isUndefinedOrEmpty([siblings, parent], 'some')) {
                        reject();

                        return;
                    }

                    // Look for the parent instance in the instance siblings.
                    const parentInstance = loFind(siblings, {
                        instance: parent,
                    });

                    if (angular.isDefinedAndFilled(parentInstance)) {
                        resolve(parentInstance);

                        return;
                    }

                    // If it is not in the instance siblings, get the parent instance.
                    const parentInstancePromise = service.get(
                        {
                            uid: parent,
                        },
                        undefined,
                        undefined,
                        parent,
                        setCurrent,
                    ).$promise;

                    parentInstancePromise.then(resolve).catch(reject);
                })
                .catch(reject);
        });
    }

    /**
     * Get the customer slug from the URL.
     *
     * @return {string} The current customer slug.
     */
    function getCurrentInstanceSlug() {
        const instance = service.getInstance();
        if (angular.isDefinedAndFilled(get(instance, 'slug'))) {
            return instance.slug;
        }

        const isCustomDomain = !includes($location.url(), '/a/');

        const splittedPath = $location.path().split('/') || (isCustomDomain ? ['', '', ''] : ['', 'a', '', '']);

        const currentInstanceSlug = isCustomDomain ? splittedPath[1] : splittedPath[3];

        // Exception for GSite support.
        if (currentInstanceSlug === 'gsite' && angular.isDefinedAndFilled($location.search().parent)) {
            // The parent path is "https://sites.google.com/a/customer/slug/...".
            let splittedParentPath = decodeURIComponent($location.search().parent).split('/');

            if (splittedParentPath.length < 6) {
                return currentInstanceSlug;
            }
            splittedParentPath = splittedParentPath[5];

            // Remove eventual param .
            splittedParentPath = first(splittedParentPath.split('?'));

            // Remove eventual anchor.
            return first(splittedParentPath.split('#'));
        }

        /*
         * Fallback to computing the slug with the URL.
         * There is two cases:
         *  - the URL contains a '/a/' part, which mean that the customer slug is the next part enclosed by '/';
         *  - the URL doesn't contains a '/a/' part (it usually is the case for custom domains), in which case the
         *    "slug" (in fact, the part that can be used to identify the customer) is the domain of the URL;
         */
        return currentInstanceSlug;
    }

    /**
     * Get the current instance.
     *
     * @return {Object} The current instance.
     */
    function getInstance() {
        return _instance || {};
    }

    /**
     * Fetch an instance.
     *
     * @param {string}   instanceId The id of the instance to fetch.
     * @param {Function} [cb]       The success callback.
     * @param {Function} [errCb]    The error callback.
     */
    function getInstanceById(instanceId, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        service.getSiblings(false).then(function onSiblingsListSuccess(siblings) {
            const instance = loFind(siblings, {
                id: instanceId,
            });

            if (angular.isDefinedAndFilled(instance)) {
                cb(instance);

                return;
            }

            InstanceFactory.get(
                {
                    uid: instanceId,
                },
                cb,
                errCb,
            );
        });
    }

    /**
     * Get the instance logo.
     *
     * @param  {number} [size] The width of the logo.
     * @return {string} The instance logo.
     */
    function getLogo(size) {
        const currentInstance = service.getInstance();

        if (angular.isUndefinedOrEmpty(currentInstance)) {
            return '';
        }

        let instanceLogo = '';

        const translatedLogoUrl = Translation.translate(currentInstance.logoUrl);

        if (angular.isDefinedAndFilled(currentInstance.logo)) {
            const logoObject = {};
            angular.forEach(currentInstance.logo, function forEachLogoTranslations(logo) {
                logoObject[logo.lang] = logo;
            });

            const translatedLogo = Translation.translate(logoObject);
            if (angular.isDefinedAndFilled(translatedLogo)) {
                /**
                 * translatedLogo.url looks like: lh3.googleusercontent.xxxx
                 * translatedLogo.logo looks like: /serve/xxxx
                 * Url served with /serve/ are managed by the backend to correctly redirect the user
                 * to the right lh3.googleusercontent.xxxx and to avoid traffic on our own application.
                 * For chinese people, the backend serve images directly with /serve/ and do not redirect
                 * to avoid being blocked by the governement.
                 * This explains why "logo" is more important than "url".
                 */
                instanceLogo = angular.isDefinedAndFilled(translatedLogo.logo)
                    ? translatedLogo.logo
                    : translatedLogo.url;
                instanceLogo = Utils.resizeImage(instanceLogo, size);
            }
        } else if (angular.isDefinedAndFilled(translatedLogoUrl)) {
            instanceLogo = Utils.resizeImage(translatedLogoUrl, size);
        } else {
            instanceLogo = Utils.getMediaUrl(InitialSettings.PUBLIC_PATH_PREFIX + trimStart(Config.DEFAULT_LOGO, '/'));
        }

        return instanceLogo;
    }

    /**
     * Get a property from instance.properties else fallback in different configuration services.
     *
     * @param  {string} key The config key identifier.
     * @return {*}      The wanted property.
     */
    function getProperty(key) {
        const value = Utils.getProperty(key, service.getInstance());
        if (angular.isDefined(value)) {
            return value;
        }

        // Return the requested property from the customer properties.
        return Customer.getProperty(key);
    }

    /**
     * Get the instance siblings.
     *
     * @param  {boolean}       [sync=false] Indicates if we want to have the currently available value
     *                                      (directly, without promise) or wait to be sure that the siblings
     *                                      instances has been loaded (and thus, returning a promise).
     * @return {Promise|Array} The siblings instances in sync mode, or a promise that resolve with the list of
     *                         siblings instances.
     */
    function getSiblings(sync) {
        if (sync) {
            return _siblings || [];
        }

        return $q(function resolveInstancesSiblings(resolve, reject) {
            _siblingsLoadingDeferred.promise
                .then(function onSiblingsListSuccess() {
                    resolve(_siblings || []);
                })
                .catch(reject);
        });
    }

    /**
     * Get the instance siblings identifiers.
     *
     * @param  {boolean}       [sync=false] Indicates if we want to use the currently available list if instances
     *                                      siblings (directly, without promise) or wait to be sure that the
     *                                      siblings instances has been loaded (and thus, returning a promise).
     * @return {Promise|Array} The siblings instances ids in sync mode, or a promise that resolve with the list of
     *                         siblings instances ids.
     */
    function getSiblingsIds(sync) {
        if (sync) {
            return map(_siblings, 'id') || [];
        }

        return $q(function defer(resolve, reject) {
            service
                .getSiblings(false)
                .then(function onSiblingsListSuccess(siblings) {
                    resolve(map(siblings, 'id') || []);
                })
                .catch(reject);
        });
    }

    /**
     * Get the URL of an instance.
     *
     * @param  {string} [instance=<current instance>] The instance we want to get the URL of.
     * @return {string} The URL of the insance.
     */
    function getUrl(instance) {
        instance = instance || service.getCurrent();

        const instanceSlug = get(instance, 'slug');
        if (angular.isUndefinedOrEmpty(instanceSlug)) {
            return '';
        }

        let instanceUrl = '';

        const customerSlug = Customer.getCustomerSlug();
        if (angular.isDefinedAndFilled(customerSlug)) {
            instanceUrl = `/a/${Customer.getCustomerSlug()}`;
        }
        instanceUrl += `/${instanceSlug}`;

        return instanceUrl;
    }

    /**
     * Check if the current instance has children or not.
     *
     * @param  {string}  instanceId The identifier of the instance to check.
     * @return {boolean} If the instance has children.
     */
    function hasChildren(instanceId) {
        return angular.isDefinedAndFilled(
            loFind(service.displayList() || [], {
                parent: instanceId,
            }),
        );
    }

    /**
     * Get an instance from its instance key.
     * Used for the lxSelect modelToSelection.
     *
     * @param  {string}   instanceKey The instance key we want to transform to an instance.
     * @param  {string}   listKey     The list key.
     * @param  {Function} [cb]        The lxSelect callback function.
     * @return {Object}   The instance matching the instanceKey.
     */
    function instanceKeyToInstance(instanceKey, listKey, cb) {
        cb = cb || angular.noop;

        if (angular.isUndefinedOrEmpty(instanceKey)) {
            return undefined;
        }

        const instance = loFind(service.displayList(listKey), {
            id: instanceKey,
        });

        if (angular.isDefinedAndFilled(instance)) {
            cb(instance);
        }

        return instance;
    }

    /**
     * Get an instance from its siblings.
     * Used for the lxSelect modelToSelection.
     *
     * @param  {string}           instanceId   The instance id we want to transform to an instance.
     * @param  {Function|boolean} cb           The lxSelect callback function. If true is given then this function
     *                                         will return synchronously the matching sibling instead of passing it
     *                                         to a callback.
     * @param  {boolean}          [sync=false] Indicates if we want to have the currently available value
     *                                         (directly, without promise) or wait to be sure that the siblings
     *                                         instances has been loaded (and thus, returning a promise).
     * @return {Object}           In synchronous version, return the matching instance sibling.
     */
    function instanceKeyToInstanceFromSiblings(instanceId, cb, sync) {
        let sibling;

        if (cb === true || sync) {
            sibling = loFind(service.getSiblings(true), {
                id: instanceId,
            });

            if (angular.isFunction(cb)) {
                cb(sibling);
            }

            return sibling;
        }

        if (!angular.isFunction(cb) || angular.isUndefinedOrEmpty(instanceId)) {
            return undefined;
        }

        return service
            .getSiblings(false)
            .then(function onSiblingsListSuccess(siblings) {
                if (!angular.isArray(siblings)) {
                    cb();

                    return;
                }

                sibling = loFind(siblings, {
                    id: instanceId,
                });

                cb(sibling);
            })
            .catch(cb);
    }

    /**
     * Get an instance key from an instance.
     * Used for the lxSelect selectionToModel.
     *
     * @param {Object}   instance The instance we want to get the instance key.
     * @param {Function} [cb]     The lxSelect callback function.
     */
    function instanceToInstanceKey(instance, cb) {
        if (angular.isDefinedAndFilled(instance.id)) {
            cb(instance.id);
        }
    }

    /**
     * Check if the default instance is selected.
     *
     * @param  {Array}   selectedInstances The list of selected instances.
     * @return {boolean} If the default instance is selected.
     */
    function isDefaultInstanceSelected(selectedInstances) {
        selectedInstances = selectedInstances || [];

        return angular.isDefinedAndFilled(
            loFind(selectedInstances, {
                isDefaultInstance: true,
            }),
        );
    }

    /**
     * If the instance siblings model doesn't fit the current instance parameters.
     *
     * @param  {Array}   instanceSiblingsModel The instance siblings model we want to check.
     * @return {boolean} True if the select is misconfigurated.
     */
    function isInstanceSiblingsModelOutdated(instanceSiblingsModel) {
        const isOnlyOneGoodInstance =
            angular.isDefinedAndFilled(instanceSiblingsModel) &&
            instanceSiblingsModel.length === 1 &&
            instanceSiblingsModel[0] === service.getCurrentInstanceId();
        const cantDisplaySiblingsSelector = service.getSiblings(true).length <= 1;
        const isSiblingsAlreadySelected = angular.isDefinedAndFilled(instanceSiblingsModel);

        return !isOnlyOneGoodInstance && cantDisplaySiblingsSelector && isSiblingsAlreadySelected;
    }

    /**
     * Set the current instance and load the instance siblings.
     *
     * @param  {Object}  newInstance The instance to set as current.
     * @return {Promise} The promise of the loading of instance siblings.
     */
    function setInstance(newInstance) {
        const firstInit = _instance === undefined;
        _instance = newInstance;

        if (firstInit) {
            // Set instance reducer initial state.
            setInitialState(service.mapStateToRedux());
        }

        // Unquote the custom head tag.
        if (angular.isDefinedAndFilled(get(_instance, 'head'))) {
            try {
                _instance.head = decodeURIComponent(_instance.head);
            } catch (exception) {
                // Nothing to do here.
            }
        }

        const customerId = Customer.getCustomerId();
        LocalStorage.put(`instance-${customerId}-${_instance.slug}`, _instance);
        LocalStorage.put(`instance-${customerId}-${_instance.uid}`, _instance);

        if (service.is.loading.siblings) {
            return _siblingsLoadingDeferred.promise;
        }

        _siblings = [_instance];

        // If user is not connected, return the current instance since they're not allowed to list instances.
        if (!$injector.get('User').isConnected()) {
            return _siblingsLoadingDeferred.resolve(_siblings);
        }

        service.is.loading.siblings = true;

        InstanceFactory.listSiblings({
            fields:
                'items(defaultUserDirectory,id,instance,isInFavoriteFeedKeys,logo,logoUrl,name,parent,slug,style,uid)',
            instanceId: service.getCurrentInstanceId(),
        })
            .$promise.then(_siblingsLoadingDeferred.resolve)
            .catch(_siblingsLoadingDeferred.reject);

        _siblingsLoadingDeferred.promise
            .then(function onSiblingsListSuccess(response) {
                _siblings = response.items;

                $rootScope.$broadcast('instances-siblings-list-updated');
            })
            .finally(function onSiblingsCctsListFinally() {
                service.is.loading.siblings = false;
            });

        return _siblingsLoadingDeferred.promise;
    }

    // ///////////////////////////

    service.copy = copy;
    service.displayParentlessInstances = displayParentlessInstances;
    service.formatObjectForClient = formatObjectForClient;
    service.formatObjectForServer = formatObjectForServer;
    service.getAdminProperty = getAdminProperty;
    service.getAllCustomerInstances = getAllCustomerInstances;
    service.getCurrentInstanceId = getCurrentInstanceId;
    service.getCurrentInstanceLangs = getCurrentInstanceLangs;
    service.getCurrentInstanceParent = getCurrentInstanceParent;
    service.getCurrentInstanceSlug = getCurrentInstanceSlug;
    service.getInstance = getInstance;
    service.getInstanceById = getInstanceById;
    service.getLogo = getLogo;
    service.getProperty = getProperty;
    service.getSiblings = getSiblings;
    service.getSiblingsIds = getSiblingsIds;
    service.getUrl = getUrl;
    service.hasChildren = hasChildren;
    service.instanceKeyToInstance = instanceKeyToInstance;
    service.instanceKeyToInstanceFromSiblings = instanceKeyToInstanceFromSiblings;
    service.instanceToInstanceKey = instanceToInstanceKey;
    service.isDefaultInstanceSelected = isDefaultInstanceSelected;
    service.isInstanceSiblingsModelOutdated = isInstanceSiblingsModelOutdated;
    service.setInstance = setInstance;

    // ///////////////////////////
    //                         //
    //          Redux          //
    //                         //
    // ///////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        const id = service.getCurrentInstanceId();
        const instance = service.getInstance();
        const siblings = getSiblings(true);

        return {
            entities: {
                ...keyBy(siblings, 'id'),
                [id]: instance,
            },
            id,
            logo: service.getLogo(),
            parent: instance.parent,
            slug: instance.slug,
        };
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'instance';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'instance/update';

    // Expose the appropriate functions.
    service.mapStateToRedux = mapStateToRedux;

    // ///////////////////////////
    /**
     * Initialize the main nav redux store.
     */
    service.initReduxStore = function initReduxStore() {
        // Enable Redux sync.
        ReduxStore.subscribe(service, true);
    };

    // ///////////////////////////

    return service;
}

// ///////////////////////////

angular.module('Services').service('Instance', InstanceService);

// ///////////////////////////

export { InstanceService };
