import { MenuDivider } from '../Menu/MenuDivider';
import { MenuItem } from '../Menu/MenuItem';
import { MenuItemCheckbox } from '../Menu/MenuItemCheckbox';
import { MenuItemRadio } from '../Menu/MenuItemRadio';
import { MenuItemSubmenu } from '../Menu/MenuItemSubmenu';
import { MenuSection } from '../Menu/MenuSection';
import { MenuSectionRadioGroup } from '../Menu/MenuSectionRadioGroup';
import { Menubar as BaseComponent } from './Menubar';

// Copy some of the menu component to make them easily usable with `Menubar.<SubComponent>`.
const SUB_COMPONENTS = {
    /**
     * Menubar vertical divider.
     */
    Divider: MenuDivider,
    /**
     * Menubar item.
     *
     * Visually similar to DS Button
     */
    Item: MenuItem,
    /**
     * Menubar checkbox item.
     *
     * Selectable item using the ARIA `menuitemcheckbox` pattern.
     */
    ItemCheckbox: MenuItemCheckbox,
    /**
     * Menubar checkbox item.
     *
     * Selectable item using the ARIA `menuitemradio` pattern.
     * **Warning**: Must be wrapped in a `SectionRadioGroup`
     */
    ItemRadio: MenuItemRadio,
    /**
     * Menubar submenu item.
     *
     * Nest dropdown submenus
     */
    ItemSubmenu: MenuItemSubmenu,
    /**
     * Menubar section.
     *
     * Grouping menubar items; Automatic dividers appears between sections
     */
    Section: MenuSection,
    /**
     * Menubar section radio group.
     *
     * Grouping menubar radio items and handling value/onChange
     */
    SectionRadioGroup: MenuSectionRadioGroup,
} as const;

/**
 * Menu bar component.
 *
 * Display an horizontal menu (ARIA pattern `menubar`) in which you can add items, section and submenus
 */
export const Menubar = Object.assign(BaseComponent, SUB_COMPONENTS);
