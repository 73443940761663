import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Heading, Typography } from '@lumapps/lumx/react';

import './index.scss';

export interface SettingSectionProps {
    children: React.ReactNode;
    title: string;
    titleId?: string;
    className?: string;
}

const CLASSNAME = 'widget-setting-section';

/**
 * Generic component that displays a title and has all the styles of a setting's section
 * Used for widget settings
 */
export const SettingSection: React.FC<SettingSectionProps> = ({ title, titleId, children, className }) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <section className={block([className])}>
            <Heading id={titleId} className={element('title')} typography={Typography.subtitle1} as="h4">
                {title}
            </Heading>
            {children}
        </section>
    );
};
