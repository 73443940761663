import React from 'react';

import { mdiCheck } from '@lumapps/lumx/icons';
import { Icon } from '@lumapps/lumx/react';

import { MenuContext } from '../context';
import { MenuItem, MenuItemProps } from '../MenuItem';

export type MenuItemCheckboxProps = Omit<MenuItemProps, 'href'> & {
    isChecked?: boolean;
    onChange?(isChecked: boolean): void;
};

/**
 * Menu toggleable item (ARIA `menuitemcheckbox`).
 * A specialised version of `Menu.Item` with a toggleable checked state.
 *
 * To use inside a `Menu` or `Menubar`.
 *
 * @family Menus
 */
export const MenuItemCheckbox = React.forwardRef<HTMLElement, MenuItemCheckboxProps>((props, ref) => {
    const { children, isChecked, onClick, onChange, isDisabled, ...forwardedProps } = props;
    const handleClick = React.useCallback(
        (event: React.MouseEvent) => {
            if (isDisabled) {
                return;
            }
            onClick?.(event);
            onChange?.(!isChecked);
        },
        [isChecked, isDisabled, onChange, onClick],
    );

    const { menubarVariant } = React.useContext(MenuContext) || {};
    const afterCheckedIndicator = menubarVariant ? undefined : <Icon icon={isChecked ? mdiCheck : ''} size="xs" />;

    return (
        <MenuItem
            ref={ref}
            after={afterCheckedIndicator}
            {...forwardedProps}
            isDisabled={isDisabled}
            role="menuitemcheckbox"
            aria-checked={Boolean(isChecked)}
            onClick={handleClick}
        >
            {children}
        </MenuItem>
    );
});
MenuItemCheckbox.displayName = 'MenuItemCheckbox';
