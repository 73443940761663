/* @ngInject */
function EmailService(Customer, EmailFactory, LumsitesBaseService) {
    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(EmailService, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Send the message to the dedicated mail address.
     *
     * @param  {string}  content   The content message to send.
     * @param  {string}  captcha   The captcha response.
     * @param  {string}  sender    The mail address of the sender.
     * @param  {string}  subject   The subject of the mail to send.
     * @param  {string}  recipient The mail address to send the mail to.
     * @param  {string}  contentId The id of the content containing the widget.
     * @param  {string}  widgetId  The widget used to send the email.
     * @return {Promise} The promise of sending the mail.
     */
    function sendEmail(content, captcha, sender, subject, recipient, contentId, widgetId) {
        return EmailFactory.send({
            body: content,
            captcha,
            customerId: Customer.getCustomerId(),
            replyTo: sender,
            subject,
            to: recipient,
            contentId,
            widgetId,
        }).$promise;
    }

    /////////////////////////////

    service.sendEmail = sendEmail;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Email', EmailService);

/////////////////////////////

export { EmailService };
