import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Heading, Switch } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';

import { DATA_SCOPE, INFORMATION_DISPLAYED } from '../../../constants';
import { WIDGET_EVENT_LIST } from '../../../keys';
import { WidgetEventListPropertiesType } from '../../../type';

export interface InformationDisplayedSectionProps {
    currentInformationDisplayedSetting?: string[];
    onChange: (properties: Pick<WidgetEventListPropertiesType, 'fields'>) => void;
}

const CLASSNAME = 'informations-displayed-section';

export const InformationDisplayedSection: React.FC<InformationDisplayedSectionProps> = ({
    currentInformationDisplayedSetting,
    onChange,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(`${DATA_SCOPE}-information-displayed`);
    const { block } = useClassnames(CLASSNAME);
    const [isDescriptionChecked, setIsDescriptionChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.INTRO)
            : true,
    );
    const [isLocationChecked, setIsLocationChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.LOCATION)
            : true,
    );
    const [isGuestsChecked, setIsGuestsChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.GUESTS)
            : true,
    );
    const [isLinkChecked, setIsLinkChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.LINK)
            : true,
    );

    React.useEffect(() => {
        const fields = [];

        if (isDescriptionChecked) {
            fields.push(INFORMATION_DISPLAYED.INTRO);
        }
        if (isLocationChecked) {
            fields.push(INFORMATION_DISPLAYED.LOCATION);
        }
        if (isGuestsChecked) {
            fields.push(INFORMATION_DISPLAYED.GUESTS);
        }
        if (isLinkChecked) {
            fields.push(INFORMATION_DISPLAYED.LINK);
        }
        onChange({
            fields,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDescriptionChecked, isLocationChecked, isGuestsChecked, isLinkChecked]);

    return (
        <section className={block([margin('vertical', 'huge')])}>
            <Heading as="h4" typography="subtitle1" className={margin('bottom', 'huge')}>
                {translateKey(WIDGET_BASE.SETTINGS_INFORMATION_DISPLAYED_TITLE)}
            </Heading>
            <Switch
                isChecked={isDescriptionChecked}
                onChange={setIsDescriptionChecked}
                className={margin('bottom', 'big')}
                {...get({ element: 'switch', action: 'toggle-description' })}
            >
                {translateKey(WIDGET_BASE.SETTINGS_SHOW_DESCRIPTION)}
            </Switch>
            <Switch
                isChecked={isLocationChecked}
                onChange={setIsLocationChecked}
                className={margin('bottom', 'big')}
                {...get({ element: 'switch', action: 'toggle-location' })}
            >
                {translateKey(WIDGET_EVENT_LIST.SETTINGS_SHOW_LOCATION)}
            </Switch>
            <Switch
                isChecked={isGuestsChecked}
                onChange={setIsGuestsChecked}
                className={margin('bottom', 'big')}
                {...get({ element: 'switch', action: 'toggle-guests' })}
            >
                {translateKey(WIDGET_EVENT_LIST.SETTINGS_SHOW_ATTENDEES)}
            </Switch>
            <Switch
                isChecked={isLinkChecked}
                onChange={setIsLinkChecked}
                className={margin('bottom', 'big')}
                {...get({ element: 'switch', action: 'toggle-link' })}
            >
                {translateKey(WIDGET_EVENT_LIST.SETTINGS_SHOW_LINK)}
            </Switch>
        </section>
    );
};
