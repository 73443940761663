/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { PostAnalyticsDialogProps } from './PostAnalyticsDialog';

const LazyPostAnalyticsDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'post-analytics-dialog' */
            './PostAnalyticsDialog'
        ),
);

export const PostAnalyticsDialog = (props: PostAnalyticsDialogProps) => {
    const { isOpen, ...otherProps } = props;

    return (
        <DelayedSuspense shouldLoad={isOpen}>
            {(setShouldMount) => (
                <LazyPostAnalyticsDialog isOpen={isOpen} onVisibilityChange={setShouldMount} {...otherProps} />
            )}
        </DelayedSuspense>
    );
};
