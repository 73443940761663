import {
    Route,
    PAGE_MIGRATION_URL_PREFIX,
    AppId,
    createPageRoute,
    createEntityManagementRoutes,
} from '@lumapps/router';

const legacyRoleRoute: Route = createPageRoute({
    slug: 'admin/role',
    legacyId: 'app.admin.role',
    appId: AppId.legacy,
});

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    appId: AppId.backOffice,
    id: 'roles',
    slug: `${PAGE_MIGRATION_URL_PREFIX}/roles`,
});

export {
    baseRoute as rolesListRoute,
    createRoute as rolesCreateRoute,
    editRoute as rolesEditRoute,
    getEditRoute,
    legacyRoleRoute,
};
