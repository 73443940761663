import { POUND } from '@lumapps/constants/specialCharacters';

export const TAGZ_FEATURE_CODE = 'tagz';
export const TAGZ_CONTEXT_NAME = 'tagz';
export const TAGZ_NUMBER_TAGZ_PER_CARD = 10;
export const TAGZ_NUMBER_TAGZ_FOR_SECTION = 30;
export const TAGZ_NUMBER_TAGZ_FOR_ALL_TAGS_SECTION = 50;
export const TAGZ_NUMBER_TAGZ_FOR_RELATED_TAGS = 5;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SKELETON_TEXT_WIDTH = '60px';
export const TAG_RECENT_SORT = 'CREATION_DATE';
export const TAGZ_MAX_LENGTH = 50;
export const TAGZ_FORBIDDEN_CHARACTERS = '#＃';
/**
 * This may seem like a regular `#` symbol, but it's not.
 * It's actually a special character.
 * The reason is to add some extra space between the intitial pound and the actual tag.
 * Screen readers also read it as "hashtag".
 */
export const SPECIAL_POUND_CARACTER = POUND;
export const TAGZ_NB_MAX_SUGGESTIONS = 3;
