import { Editor, Range, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import { deleteTable } from './deleteTable';
import { getCurrentCellsInRow } from './getCurrentCellsInRow';
import { getCurrentRowsInTable } from './getCurrentRowsInTable';
import { getCurrentSelectionInfos } from './getCurrentSelectionInfos';

export const deleteColumn = (editor: any) => {
    /* get selection details (null if no selection or if selection not in table) */
    const selectionInfos = getCurrentSelectionInfos(editor);

    if (!selectionInfos) {
        return undefined;
    }

    const { startRow, startCol, selectedCols } = selectionInfos;

    const tablePath = [Range.start(editor.selection as Range).path[0]];

    /* get the rows in current table */
    const { rowsInTable } = getCurrentRowsInTable(editor, tablePath);
    /* get the number of cells in current row */
    const { cellsLength } = getCurrentCellsInRow(editor, [...tablePath, startRow]);

    if (cellsLength === 1 || selectedCols.length === cellsLength) {
        /* if there is only one column remaining in the table, we remove the table */
        const newCursorPosition = deleteTable(editor);
        return newCursorPosition;
    }

    setTimeout(() => {
        focusAt(editor, {
            anchor: {
                path: [...tablePath, 0, 0, 0, 0],
                offset: 0,
            },
            focus: {
                path: [...tablePath, 0, 0, 0, 0],
                offset: 0,
            },
        });
    }, 1);

    /* we skip the normalization here to avoid cells insertion fixes during the column deletion processus */
    Editor.withoutNormalizing(editor, () => {
        /* repeat the operation for each selected col (reverse to be sure to not try to delete an already deleted col */
        selectedCols.reverse().forEach((col) => {
            rowsInTable.forEach((_, index) => {
                /* define the position of each cell to remove */
                const pathToDelete = [...tablePath, index, col];
                /* remove the cell in each row */
                Transforms.removeNodes(editor, { at: pathToDelete });
            });
        });
    });

    const newCellsLength = cellsLength - selectedCols.length;
    /* position the cursor at the same position except if the deleted column was the last on, the cursor is moved to the new last column */
    const newCursorPosition = {
        anchor: {
            path: [...tablePath, 0, Math.min(startCol, newCellsLength - 1), 0, 0],
            offset: 0,
        },
        focus: {
            path: [...tablePath, 0, Math.min(startCol, newCellsLength - 1), 0, 0],
            offset: 0,
        },
    };

    // For some reason, the focusAt doesn't work properly in the table context if we do not delay it.
    setTimeout(() => {
        focusAt(editor, newCursorPosition);
    }, 1);

    return newCursorPosition;
};
