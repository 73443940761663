import { useSelector } from '@lumapps/redux/react';
import { getResultSuggestions } from '@lumapps/search/ducks/selectors';
import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';

import { useResultToSuggestionConverter } from './useResultToSuggestionConverter';

/**
 * Get result suggestions from the store
 * and convert them to "suggestion" type search suggestions.
 * @param instanceId the current instance id
 */
export type UseResultSuggestionsParams = {
    instanceId: string;
};
export const useResultSuggestions = (params: UseResultSuggestionsParams): SearchSuggestion[] => {
    const rawResultsSuggestions = useSelector(getResultSuggestions);
    const resultsSuggestions = useResultToSuggestionConverter(rawResultsSuggestions || [], {
        instanceId: params.instanceId,
        type: SearchSuggestionType.SUGGESTION,
    });

    return resultsSuggestions;
};
