import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { NannyFrontOfficeState } from './types';

export interface NannyAlertAction {
    violations: NannyFrontOfficeState['violations'];
    more: NannyFrontOfficeState['more'];
}

export const initialState: NannyFrontOfficeState = {
    isAlertDisplayed: false,
    violations: [],
    more: false,
    error: undefined,
};

const slice = createSlice({
    domain: 'nanny',
    initialState,
    reducers: {
        displayNannyAlert: (state: any, action: PayloadAction<NannyAlertAction>): void => {
            set(state, 'violations', action.payload.violations);
            set(state, 'more', action.payload.more);
            set(state, 'isAlertDisplayed', true);
        },
        hideNannyAlert: (state: any): void => {
            set(state, 'violations', initialState.violations);
            set(state, 'more', initialState.more);
            set(state, 'isAlertDisplayed', false);
        },
        setError: (state: any, action: PayloadAction<string>): void => {
            set(state, 'error', action.payload);
        },
        resetError: (state: any): void => {
            set(state, 'error', initialState.error);
        },
    },
});

const { actions, reducer } = slice;

export { actions, reducer };
