import { BaseApiResponse } from '@lumapps/base-api';
import { get as getCommunity } from '@lumapps/communities/api';
import { GetCommunityParams } from '@lumapps/communities/types';
import { error as notifyError } from '@lumapps/notifications';
import { batch } from '@lumapps/redux/react';
import { Dispatch } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

import { get, GetContentAPIParams } from '../../api/contents';
import { ContentType } from '../../types';
import { contentSelector } from '../selectors';
import { actions } from '../slice';
/**
 * Get a content.
 * Can be useful to get fields that are not retrieved during the initial request.
 *
 * @param params Standard get parameters. Use `fields` from the `params` argument.
 * @param fetchId Identifier of fetch to handle the loading status based on the context in which it has been done. Prevent multiple calls.
 * @param useCache Whether the cache should be used to retrieve data.
 */
export const getContent =
    (params: GetContentAPIParams, fetchId = 'default', useCache = true, headers = {}) =>
    async (dispatch: Dispatch, getState: () => any) => {
        const currentContent = contentSelector(getState());
        if (currentContent.actionState?.fetch?.[fetchId] === 'loading') {
            return;
        }
        dispatch(actions.setFetchState({ id: fetchId, status: 'loading' }));
        try {
            let response: BaseApiResponse;
            if (currentContent.type === ContentType.community) {
                response = await getCommunity(params as GetCommunityParams, useCache, headers);
            } else {
                response = await get(params, useCache, headers);
            }
            batch(() => {
                dispatch(actions.setContent(response.data));
                dispatch(actions.setFetchState({ id: fetchId, status: 'loaded' }));
            });
        } catch (e) {
            batch(() => {
                dispatch(actions.setFetchState({ id: fetchId, status: 'error' }));
                dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
            });
        }
    };
