import { useCallback, useState } from 'react';

import noop from 'lodash/noop';
import { ComponentTypes, ExtensionCategories } from 'lumapps-sdk-js';

import { fetchExtensions } from '../api';
import { ExtensionStatus } from '../constants';
import { ComponentErrors } from '../types';
import { getBundleIdentifier } from './helpers';

export const getBundleProxyURL = (extensionId: string, fileName: string) => `proxy://${extensionId}/${fileName}`;
export type FileType = 'content' | 'settings';

export interface UseLumdrianProps {
    bundleURL?: string;
    componentType?: ComponentTypes;
    extensionId: string;
}
export const useLumdrian = ({ bundleURL, componentType = ComponentTypes.content, extensionId }: UseLumdrianProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [component, setComponent] = useState<Record<string, any>>();
    const [error, setError] = useState<ComponentErrors>();

    // Save current state, so we can detect changes
    const [currentExtensionId, setCurrentExtensionId] = useState<string>();
    const [currentBundleURL, setCurrentBundleURL] = useState<string>();
    const [currentComponentType, setCurrentComponentType] = useState<ComponentTypes>(ComponentTypes.content);

    const load = useCallback(async () => {
        if (!currentExtensionId) {
            return noop;
        }
        const { getLumdrian } = await import(
            /* webpackChunkName: 'lumdrian-platform' */
            './lumdrian-platform'
        );
        const lumdrian = getLumdrian();
        setIsLoading(true);

        lumdrian.addEndpoint({
            extensionId: currentExtensionId,
            bundleURL: currentBundleURL ?? getBundleProxyURL(currentExtensionId, currentComponentType),
        });

        const loadComponent = async (fileName: string) =>
            lumdrian
                .load({
                    extensionId: currentExtensionId,
                    fileName,
                })
                .then((extensionComponent) => {
                    setComponent(extensionComponent);
                    setIsLoading(false);
                });

        return loadComponent(getBundleIdentifier(currentComponentType, currentExtensionId)).catch(() => {
            // If bundle not found, try legacy extension
            return loadComponent(getBundleIdentifier(currentComponentType, currentExtensionId, true)).catch(
                (err: import('@lumapps/marketplace/types').ComponentErrors) => {
                    setError(err);
                    setIsLoading(false);
                },
            );
        });
    }, [currentBundleURL, currentComponentType, currentExtensionId]);

    if (
        !isLoading &&
        (currentExtensionId !== extensionId || currentBundleURL !== bundleURL || currentComponentType !== componentType)
    ) {
        setComponent(undefined);
        setError(undefined);
        setCurrentExtensionId(extensionId);
        setCurrentBundleURL(bundleURL);
        setCurrentComponentType(componentType);
    }

    return { component, error, load, loading: isLoading };
};

export const useShareToExtensionList = () => {
    const [extensions, setExtensions] = useState<import('../legacy-types').ExtensionProps[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
    const [error, setError] = useState<unknown | undefined>(undefined);
    const loadExtensions = async () => {
        setIsLoading(true);

        try {
            const { data } = await fetchExtensions({
                categories: [ExtensionCategories.ShareTo],
                // UI is not adapted for more than a very few anyway
                maxResults: 100,
                statuses: [ExtensionStatus.live],
            });
            setExtensions(data.items);
        } catch (err) {
            setError(err);
            setExtensions([]);
        } finally {
            setIsLoading(false);
        }
    };

    if (!error && !isLoading && extensions === undefined) {
        loadExtensions();
    }

    return {
        error,
        extensions,
        isLoading,
    };
};
