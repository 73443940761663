/* eslint-disable no-underscore-dangle */
import debounce from 'lodash/debounce';
import loFind from 'lodash/find';
import get from 'lodash/get';
import set from 'lodash/set';

/////////////////////////////

/**
 * Default max query results number.
 *
 * @type {number}
 * @constant
 * @readonly
 */
const DEFAULT_MAX_RESULTS = 10;

function WidgetSharepointSiteListController(
    $scope,
    $timeout,
    $window,
    Config,
    Content,
    InitialSettings,
    Instance,
    SharepointSite,
    Translation,
    Utils,
    Widget,
    WidgetSettingsConstant,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * A map of fields and their visibility.
     *
     * @type {Object}
     */
    vm.fieldsToDisplay = {};

    /**
     * Contains information about the status of the controller.
     *
     * @type {Object}
     */
    vm.is = {
        loading: {
            sites: false,
        },
    };

    /**
     * The value of the search input.
     *
     * @type {string}
     */
    vm.searchQuery = '';

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.SharepointSite = SharepointSite;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get widget properties.
     *
     * @return {Object} Widget properties.
     */
    function _getWidgetProperties() {
        return get(vm.widget, 'properties');
    }

    /**
     * List sites.
     *
     * @return {Promise} Request promise.
     */
    function _listSites() {
        vm.is.loading.sites = true;

        const properties = _getWidgetProperties();

        return SharepointSite.promiseFilterize(
            {
                maxResults: properties.maxNumber,
                search: vm.searchQuery,
                onlyFavorites: properties.onlyFavorites,
            },
            vm.widgetListCtrl.getListKey(),
        )
            .then(() => {
                vm.widgetListCtrl.updateListItems();
            })
            .finally(() => {
                $timeout(() => {
                    vm.is.loading.sites = false;
                });
            });
    }

    /**
     * Reset the list of sites displayed in the widget.
     */
    function _resetSitesList() {
        SharepointSite.initList(vm.widgetListCtrl.getListKey(), undefined, [], true);
    }

    /**
     * Initialize the visible fields in the list.
     */
    function _initFields() {
        const properties = _getWidgetProperties();
        const defaultFileFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.SHAREPOINT_SITE_FIELDS);
        let firstFieldsInit = false;

        if (angular.isUndefinedOrEmpty(properties.fields)) {
            properties.fields = [];
            firstFieldsInit = true;
        }

        angular.forEach(defaultFileFields, (field) => {
            if (angular.isString(field)) {
                field = {
                    enable: firstFieldsInit,
                    name: field,
                };
            }

            const findInDefaultFields = loFind(properties.fields, {
                name: field.name,
            });

            if (firstFieldsInit || angular.isUndefinedOrEmpty(findInDefaultFields)) {
                properties.fields.push(field);
            }
        });
    }

    /**
     * Set classes for the block elements in the list. This is so each block doesn't have to compute these
     * themselves.
     */
    function _setBlockClasses() {
        const properties = _getWidgetProperties();

        vm.sharepointSiteBlockClasses = [];

        const theme = get(properties, 'style.content.theme');
        vm.sharepointSiteBlockClasses.push(`sharepoint-site-block--theme-${theme}`);

        if (properties.itemsSeparator) {
            vm.sharepointSiteBlockClasses.push('sharepoint-site-block--has-separator');
        }
    }

    /**
     * Get block style according to widget style properties.
     *
     * @return {Object} The block style.
     */
    function _setBlockStyle() {
        const properties = _getWidgetProperties();

        vm.blockStyle = {};

        const margin = properties.itemsMargin;

        if (angular.isDefinedAndFilled(margin)) {
            vm.blockStyle.marginBottom = `${margin}px`;
        }

        return vm.blockStyle;
    }

    /**
     * Set the fields to be displayed on Sharepoint site blocks.
     *
     * @return {Object} A map of field names and their visibility.
     */
    function _setFieldsToDisplay() {
        const properties = _getWidgetProperties();

        vm.fieldsToDisplay = {};

        angular.forEach(properties.fields, (field) => {
            vm.fieldsToDisplay[field.name] = field.enable;
        });

        return vm.fieldsToDisplay;
    }

    /**
     * Set the lx-color for the lumx elements (buttons).
     */
    function _setThemeColours() {
        const properties = _getWidgetProperties();

        vm.lxColorTheme = get(properties, 'style.content.theme') === 'dark' ? 'white' : 'grey';
    }

    /**
     * List Sharepoint sites.
     * Used for all list calls (whenever a filter changes etc...).
     *
     * @return {Promise} List sites request promise.
     */
    function _initList() {
        // The first time we list Sharepoint sites, initialize the list.
        if (!vm.widgetListCtrl.initialized) {
            _resetSitesList();
        }

        return _listSites();
    }

    /**
     * Initialize the widget properties and the controller parameters.
     */
    function _initProperties() {
        const properties = _getWidgetProperties();
        const themePropertyPath = 'style.content.theme';
        const theme = get(properties, themePropertyPath, 'light');

        set(properties, themePropertyPath, theme);

        properties.viewMode = 'vertical';
        properties.listOrder = properties.listOrder || 'name';
        properties.listOrderDir = properties.listOrderDir || WidgetSettingsConstant.ORDER_DIRECTION.ASCENDING;

        if (angular.isUndefinedOrEmpty(properties.itemsSeparator)) {
            properties.itemsSeparator = true;
        }

        if (angular.isString(properties.maxNumber)) {
            const number = parseInt(properties.maxNumber, 10);
            properties.maxNumber = angular.isNumber(number) ? number : DEFAULT_MAX_RESULTS;
        }
        properties.maxNumber = properties.maxNumber || DEFAULT_MAX_RESULTS;

        _initFields();
        _setFieldsToDisplay();
        _setBlockStyle();
        _setBlockClasses();
        _setThemeColours();
    }

    /**
     * An extra condition specific to this type of widget to determine if the widget should be considered empty.
     *
     * @return {boolean} Whether the widget should be empty or not.
     */
    function _isWidgetEmpty() {
        return false;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Called when a component request status change.
     *
     * @param {Object} params Object containing new component request status.
     */
    function onStatusChange(params) {
        $scope.$apply(() => {
            vm.is.loading.sites = params.status === 'PENDING';
        });
    }

    /**
     * Open site in a new tab.
     *
     * @param {Object} site The site to open.
     */
    function openSiteInExternalTab(site) {
        const { webUrl } = site;

        $window.open(decodeURIComponent(webUrl), '_blank');
    }

    /**
     * Reload sites list.
     *
     * @return {Promise} Reload promise.
     */
    function reloadContent() {
        _resetSitesList();

        return _listSites();
    }

    /////////////////////////////

    vm.onStatusChange = onStatusChange;
    vm.openSiteInExternalTab = openSiteInExternalTab;
    vm.reloadContent = reloadContent;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the settings change, reload the widget.
     *
     * @param {Event}  evt        The event.
     * @param {string} widgetUuid The id of the widget whose settings changed.
     */
    $scope.$on('widget-site-list-settings', async (evt, widgetUuid) => {
        if (Widget.getCurrent().uuid !== widgetUuid) {
            return;
        }

        await reloadContent();
    });

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * When the theme of the widget changes, update the theme of blocks.
     */
    $scope.$watch('vm.widget.properties.style.content.theme', () => {
        _setBlockClasses();
        _setThemeColours();
    });

    /**
     * When the style of the widget changes, update the theme of blocks.
     */
    $scope.$watch('vm.widget.properties.itemsSeparator', () => {
        _setBlockClasses();
    });

    /**
     * When the margins of the widget changes, update the theme of blocks.
     */
    $scope.$watch('vm.widget.properties.itemsMargin', () => {
        _setBlockStyle();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     *
     * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
     */
    vm.init = (saveListKey) => {
        vm.widgetListCtrl.init(saveListKey);
    };

    /**
     * Set parent controller.
     *
     * @param {Object} widgetListCtrl The parent controller.
     */
    this.setParentController = (widgetListCtrl) => {
        vm.widgetListCtrl = widgetListCtrl;

        vm.widgetListCtrl.widgetListChildCtrl = {
            initList: _initList,
            initProperties: _initProperties,
            isWidgetEmpty: _isWidgetEmpty,
        };

        vm.init(true);
    };
}

/////////////////////////////

/*
 * The Sharepoint site list widget.
 * Displays a list of Sharepoint sites.
 *
 * @param {Object} widget The widget configuration object.
 */
function WidgetSharepointSiteListDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc-except/require-jsdoc
    function WidgetSharepointSiteListLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetSharepointSiteListController,
        controllerAs: 'vm',
        link: WidgetSharepointSiteListLink,
        replace: true,
        require: ['widgetSharepointSiteList', '^widgetList'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-sharepoint-site-list/views/widget-sharepoint-site-list.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetSharepointSiteList', WidgetSharepointSiteListDirective);

/////////////////////////////

export { WidgetSharepointSiteListDirective };
