import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { HeaderLabel } from '@lumapps/lumx-texts/components/HeaderLabel';
import { Link } from '@lumapps/router/components/Link';
import { useTranslate } from '@lumapps/translations';

import { ROLES } from '../../keys';
import { rolesListRoute } from '../../routes';

/**
 *
 * @param LegacyRolesHeaderLabelProps
 * @returns LegacyRolesHeaderLabel
 */
export const LegacyRolesHeaderLabel: React.FC = () => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('legacy-roles');

    return (
        <HeaderLabel
            headerTitleId="roles"
            title={translateKey(ROLES.ROLES)}
            subtitle={
                <Link to={rolesListRoute} {...get({ element: 'try-the-beta' })}>
                    {translateKey(ROLES.TRY_NEW_PAGE)}
                </Link>
            }
        />
    );
};
