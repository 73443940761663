import { shouldDisplayField } from '@lumapps/user-directory/utils/shouldDisplayField';
import { USER_SPACE_FEATURE_TOKEN } from '@lumapps/user-profile/constants';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserBlockController(
        $scope,
        Content,
        Community,
        Customer,
        Features,
        InitialSettings,
        Instance,
        Translation,
        User,
        UserDirectory,
        UserDirectoryTools,
        Utils,
    ) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * If there's at least one directory.
         *
         * @type {boolean}
         * @constant
         */
        vm.HAS_DIRECTORY = false;

        /**
         * An object holding the ng-style background image property of the user.
         *
         * @type {Object}
         */
        vm.backgroundImage = {};

        /**
         * The full list of CSS classes of the user block.
         *
         * @type {Array}
         */
        vm.classes = [];

        /**
         * Indicates if gamification is enabled.
         *
         * @type {boolean}
         */
        vm.hasGamification = false;

        /**
         * Check if a user has spam reports.
         *
         * @type {boolean}
         */
        vm.hasReports = false;

        /**
         * The secondary field displayed under user name.
         *
         * @type {string}
         */
        vm.secondaryField = undefined;

        /**
         * Whether we need to show moderation details about this user.
         * Moderations details are shown in a community context, when the current user is a
         * community moderator.
         *
         * @type {boolean}
         */
        vm.showModerationDetails = false;

        /**
         * The user fields from default user directory.
         *
         * @type {Array}
         */
        vm.userFields = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Features = Features;
        vm.Translation = Translation;
        vm.User = User;
        vm.UserDirectory = UserDirectory;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Build the user fields object from the list of fields given to the user block.
         */
        function _buildUserFields() {
            if (angular.isUndefinedOrEmpty(vm.fields)) {
                return;
            }

            var type = _.get(Content.getCurrent(), 'type');

            Utils.empty(vm.userFields);

            var secondaryFieldName = User.getSecondaryFieldName(type);

            angular.forEach(vm.fields, function forEachField(field) {
                const fieldId = _.get(field.properties, 'boundMap.text');
                // Avoid duplicates between secondary field and custom fields.
                if (fieldId !== secondaryFieldName) {
                    // should not display the email if the FF hide email addresses is on
                    if (shouldDisplayField(fieldId, Features.hasFeature('hide_email_addresses'))) {
                        vm.userFields.push({
                            icon: field.properties.icon,
                            uuid: field.uuid,
                            value: UserDirectoryTools.getComponentValue(
                                {
                                    apiProfile: _.get(vm.user, 'apiProfile', {}),
                                    customProfile: _.get(vm.user, 'customProfile', {}),
                                },
                                field,
                            ),
                        });
                    }
                }
            });
        }

        /**
         * Update the user block whenever we need to do it.
         *
         * @param {Event}  evt  The original event triggering this method.
         * @param {Object} user The full object user that just got updated.
         */
        function _resetBlock(evt, user) {
            if (_.get(user, 'id') !== vm.user.id) {
                return;
            }

            // Force secondaryField recomputation in the init method.
            vm.secondaryField = undefined;
            vm.user = user;
            vm.init();
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get user block classes.
         *
         * @return {Array} The user block classes.
         */
        function getClasses() {
            Utils.empty(vm.classes);

            if (angular.isDefinedAndFilled(vm.theme)) {
                vm.classes.push('user-block--theme-' + vm.theme);
            } else {
                vm.classes.push('user-block--theme-light');
            }

            if (angular.isDefinedAndFilled(vm.viewMode)) {
                vm.classes.push('user-block--view-mode-' + vm.viewMode);
            } else {
                vm.classes.push('user-block--view-mode-basic');
            }

            if (angular.isDefinedAndFilled(vm.size)) {
                vm.classes.push('user-block--size-' + vm.size);
            } else if (vm.viewMode === 'thumbnail') {
                vm.classes.push('user-block--size-m');
            } else if (vm.viewMode === 'extended') {
                vm.classes.push('user-block--size-l');
            } else {
                vm.classes.push('user-block--size-m');
            }

            if (angular.isDefinedAndFilled(vm.userFields)) {
                vm.classes.push('user-block--has-advanced-fields');
            }

            return vm.classes;
        }

        /////////////////////////////

        vm.getClasses = getClasses;

        /////////////////////////////

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch any changes on the fields given to the user block.
         * When they change, rebuild the user fields object.
         */
        $scope.$watch(
            function fieldsWatch() {
                return vm.fields;
            },
            function fieldsWatcher() {
                _buildUserFields();
            },
            true,
        );

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Listen to an update of the content-list theme.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} widgetId The identifier of the widget that triggered the theme update.
         * @param {string} theme    The new theme to use.
         */
        $scope.$on('widget-content-list-theme-update', function onWidgetContentListThemeUpdate(evt, widgetId, theme) {
            if (angular.isUndefinedOrEmpty(theme)) {
                return;
            }

            vm.theme = theme;
        });

        /**
         * When a user directory details is saved, reset the user block.
         *
         * @see _resetBlock
         */
        $scope.$on('user-directory__user-save-success', _resetBlock);

        /**
         * When we need to reset a user block.
         *
         * @see _resetBlock
         */
        $scope.$on('user-block__reset', _resetBlock);

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            vm.HAS_DIRECTORY =
                angular.isDefinedAndFilled(
                    [InitialSettings.USER_DIRECTORIES, InitialSettings.PARENT_USER_DIRECTORIES],
                    'some',
                ) || angular.isDefinedAndFilled(Customer.getExternalDirectory());

            vm.displaySecondaryField = angular.isUndefined(vm.displaySecondaryField) ? true : vm.displaySecondaryField;
            // Whether the user name should be a <h1 /> tag, defaults to false
            vm.useAsHeading = vm.useAsHeading || false;

            var type = _.get(Content.getCurrent(), 'type');

            if (angular.isUndefinedOrEmpty(vm.user)) {
                vm.user = {};
                vm.user.firstName = Translation.translate('UNKNOWN_USER');
                vm.user.lastName = '';

                vm.secondaryField = '';
            } else {
                const backgroundImageSize = ['l', 'xl'].includes(vm.size) ? 256 : 80;
                vm.backgroundImage = Utils.getBackgroundImage(User.getProfilePicture(vm.user), backgroundImageSize, false);

                // Build secondary field.
                if (angular.isUndefinedOrEmpty(vm.secondaryField) && angular.isDefinedAndFilled(vm.user)) {
                    var apiProfile = User.getApiProfile(vm.user);

                    vm.secondaryField = User.getSecondaryField(apiProfile, type);
                }
            }

            if (type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
                vm.showModerationDetails = Community.isEditableBy(User.getConnected(), Community.getCurrent());
            }

            _buildUserFields();
        };

        vm.init();
    }

    /////////////////////////////

    function UserBlockDirective() {
        function link(scope) {
            scope.$emit('user-block-loaded');
        }

        return {
            bindToController: true,
            controller: UserBlockController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            restrict: 'E',
            scope: {
                bullet: '@?',
                displaySecondaryField: '<?displaySecondaryField',
                fields: '<?',
                isAdmin: '<?',
                useAsHeading: '<',
                noLink: '<?',
                secondaryField: '@?',
                size: '@?',
                theme: '@?',
                user: '<',
                viewMode: '@?',
            },
            templateUrl: '/client/front-office/modules/user/views/user-block.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('userBlock', UserBlockDirective);
})();
