import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

export enum ProgramSelectionModalStatus {
    initial = 'initial',
    loading = 'loading',
    loaded = 'loaded',
    error = 'error',
}

export interface ProgramIdsByName {
    [name: string]: string;
}

export interface ProgramSelectionModalState {
    value: string;
    searchValue: string;
    modalStatus: ProgramSelectionModalStatus;
    programIdsByName: ProgramIdsByName;
}

export const programSelectionModalInitialState = {
    programIdsByName: {},
    value: '',
    searchValue: '',
    modalStatus: ProgramSelectionModalStatus.initial,
};

const { actions, reducer } = createSlice({
    domain: 'ProgramForm/ProgramSelectionModal',
    initialState: programSelectionModalInitialState,
    reducers: {
        setValue: (state: ProgramSelectionModalState, action: PayloadAction<string>) => {
            set(state, 'value', action.payload);
        },
        setSearchValue: (state: ProgramSelectionModalState, action: PayloadAction<string>) => {
            set(state, 'searchValue', action.payload);
        },
        setProgramIdsByName: (state: ProgramSelectionModalState, action: PayloadAction<ProgramIdsByName>) => {
            set(state, 'programIdsByName', action.payload);
        },
        setModalStatus: (state: ProgramSelectionModalState, action: PayloadAction<ProgramSelectionModalStatus>) => {
            set(state, 'modalStatus', action.payload);
        },
    },
});

export { actions, reducer };
