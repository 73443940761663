/* eslint-disable no-undef */
function DrivePickerController(
    $window,
    Config,
    Customer,
    DrivePicker,
    DrivePickerBuilder,
    LxNotificationService,
    Translation,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The class applied to the drive picker dropdown link.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _DROPDOWN_LINK_CLASS = 'dropdown-link--is-header';

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check the authorization result and generate a new drive picker if a valid one is found.
     *
     * @param {Object} authResult The result of the authentication from the Drive api.
     */
    function _handleAuthResult(authResult) {
        if (angular.isUndefined(authResult) || authResult.error) {
            LxNotificationService.error(Translation.translate('ERROR_GENERIC'));

            return;
        }

        vm.createPicker(authResult.access_token);
    }

    /**
     * The callback method called when the user picks an item (or items), or cancels.
     *
     * @param {Object} response A callback object.
     */
    function _pickerCallback(response) {
        if (angular.isUndefined(vm.callback) || response.action === 'loaded') {
            return;
        }

        if (response[$window.google.picker.Response.ACTION] === $window.google.picker.Action.PICKED) {
            if (vm.type === 'uploader' || Boolean(vm.selectMultiple)) {
                vm.callback()(response[$window.google.picker.Response.DOCUMENTS], response.action);
            } else {
                vm.callback()(response[$window.google.picker.Response.DOCUMENTS][0], response.action);
            }
        } else {
            vm.callback()(undefined, response.action);
        }
    }

    /**
     * Toggle the state of the elements on which the picker directive is used.
     *
     * @param {boolean} enabled Whether to enable or disable the elements.
     */
    function _toggleElement(enabled) {
        const elements = angular.element('[drive-picker]');

        if (enabled) {
            elements.removeAttr('disabled');
            elements.css('pointer-events', 'auto');
            elements.removeClass(_DROPDOWN_LINK_CLASS);
        } else {
            elements.attr('disabled', 'disabled');
            elements.css('pointer-events', 'none');
            elements.addClass(_DROPDOWN_LINK_CLASS);
        }
    }

    /**
     * Set the isPicking attribute of the DrivePicker service.
     *
     * @param {boolean} isPicking Is the user picking ?
     */
    function _setIsPicking(isPicking) {
        DrivePicker.isPicking = isPicking;
    }

    /**
     * Display the docs picker modal.
     *
     * @param {Object} picker The picker to display.
     */
    function _displayPicker(picker) {
        picker.setVisible(true);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Request an authorization to use the Drive picker via the Google API.
     */
    function authorize() {
        $window.gapi.auth.authorize(
            {
                // eslint-disable-next-line camelcase
                client_id: Config.DRIVE.clientId,
                immediate: false,
                scope: Config.DRIVE.scope,
            },
            _handleAuthResult,
        );
    }

    /**
     * Creates the drive picker regarding configuration.
     *
     * @param {string} token The current user auth token.
     */
    function createPicker(token) {
        const params = {
            destination: vm.destination,
            includeFolders: vm.includeFolders,
            selectMultiple: vm.selectMultiple,
            showType: vm.showType,
            teamDrives: vm.teamDrives,
            type: vm.type,
            viewMode: vm.viewMode,
        };
        const picker = DrivePickerBuilder.createPicker(token, params, _pickerCallback);

        _displayPicker(picker);
        _setIsPicking(false);
    }

    /////////////////////////////

    vm.authorize = authorize;
    vm.createPicker = createPicker;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        DrivePicker.alwaysFetchToken = true;

        vm.includeFolders = angular.isUndefined(vm.includeFolders) ? false : vm.includeFolders;

        // If not a google customer don't even bother trying to get a token.
        if (!Customer.isGoogleCustomer()) {
            return;
        }

        _toggleElement(false);

        // First fetch avoiding the popup to get block.
        DrivePicker.getToken(
            function onGetDrivePickerTokenSuccess() {
                _toggleElement(true);
            },
            function onGetDrivePickerTokenError() {
                // The service doesn't ask permission to the server but to gapi now.
                DrivePicker.alwaysFetchToken = false;
                _toggleElement(true);
            },
        );
    }

    /** Google API is required for Drive Picker to be used. */
    const gapiScript = document.createElement('script');
    gapiScript.setAttribute('src', 'https://apis.google.com/js/api.js');
    gapiScript.onload = () => {
        gapi.load('auth');
        gapi.load('picker');

        // Drive Picker is initialized only after Google API deps have been loaded.
        init();
    };
    document.body.appendChild(gapiScript);

}

/////////////////////////////

/**
 * A directive that will initiate a Google Drive picker.
 *
 * @param {Function} [callback]       A function to call when the user picks items, or cancels.
 * @param {string}   [destination]    The folder id of where to upload the files.
 * @param {boolean}  [includeFolders] Allows the user to select a folder in Google Drive to upload to.
 *                                    Files will still be displayed as well as folders.
 * @param {boolean}  [selectMultiple] Allow user to choose more than one item.
 * @param {string}   [showType]       Indicates the type of elements to display.
 *                                    Possible values are: 'folder' and 'drawio'.
 * @param {string}   [type]           Whether we want to upload or select files directly.
 *                                    Possible values are 'uploader' or 'full'. Defaults to view.
 * @param {boolean}  [teamDrives]     Whether we want to enable team drive tab.
 * @param {string}   [viewMode]       View mode of the drive picker.Use to define the view for selection files.
 *                                    The only available value is 'video'.
 */
function DrivePickerDirective(Customer, DrivePicker) {
    'ngInject';

    function link(scope, el, attrs, ctrl) {
        el.on('click', function onDrivePickerElementClick(evt) {
            evt.preventDefault();

            // If not a google customer don't even bother trying to get a token.
            if (!Customer.isGoogleCustomer()) {
                ctrl.authorize();

                return;
            }

            DrivePicker.isPicking = true;

            DrivePicker.getToken(function onGetDriveTokenSuccess(token, success) {
                if (success) {
                    // Everything went fine, create the picker.
                    ctrl.createPicker(token);
                } else {
                    ctrl.authorize();
                }
            }, ctrl.authorize);
        });
    }

    return {
        bindToController: true,
        controller: DrivePickerController,
        controllerAs: 'vm',
        link,
        restrict: 'AE',
        scope: {
            // eslint-disable-next-line id-blacklist
            callback: '&?',
            destination: '@?',
            includeFolders: '=?',
            selectMultiple: '=?',
            showType: '@?',
            teamDrives: '<?',
            type: '@?',
            viewMode: '@?',
        },
    };
}

/////////////////////////////

angular.module('Directives').directive('drivePicker', DrivePickerDirective);

/////////////////////////////

export { DrivePickerController, DrivePickerDirective };
