import BaseApi from '@lumapps/base-api';

const WallApi = new BaseApi({ path: 'chat' });

/**
 * Fetch the list of available wall providers.
 *
 * @return {Promise} Returns the list of available wall providers.
 */
const listProviders = () => {
    return WallApi.get('wall/providers/list');
};

/**
 * Get posts.
 *
 * @param  {string}  provider    The provider used.
 * @param  {string}  spaceId     The provider group.
 * @param  {number}  maxResults  The maximum number of results returned by the request.
 * @param  {string}  messageType The type of post.
 * @param  {string}  cursor      The next page cursor.
 * @param  {string}  channelId   The id of the channel
 * @param  {string}  orderBy     Key to order to.
 * @param  {boolean} orderAsc    The order direction.
 * @return {Promise} The API Call promise.
 */
const getPosts = (provider, spaceId, maxResults, messageType, cursor, channelId, orderBy, orderAsc) => {
    const params = {
        channelId,
        maxResults,
        orderAsc,
        orderBy,
        provider,
        spaceId,
    };

    if (cursor) {
        params.cursor = cursor;
    }

    if (messageType) {
        params.messageType = messageType;
    }

    return WallApi.get('/wall/messages', {
        params,
    });
};

/**
 * Get post by ID.
 *
 * @param  {string}  provider  The provider used.
 * @param  {string}  spaceId   The provider group.
 * @param  {string}  channelId The targeted channel id.
 * @param  {string}  messageId The targeted post id.
 * @return {Promise} The API Call promise.
 */
const getPostByPostId = (provider, spaceId, channelId, messageId) => {
    return WallApi.get('/messages/wallpost', {
        params: {
            channelId,
            messageId,
            provider,
            spaceId,
        },
    });
};

export { getPosts, getPostByPostId, listProviders };
