import { sharePostInCommunitiesTask, unsharePostFromCommunityTask } from './post_tasks'

const DOMAIN = "POST";

export const MULTI_COMMUNITY_SHARE = `${DOMAIN}/MULTI_COMMUNITY_SHARE`;
export const MULTI_COMMUNITY_SHARE_SUCCESS = `${DOMAIN}/MULTI_COMMUNITY_SHARE_SUCCESS`;
export const MULTI_COMMUNITY_SHARE_ERROR = `${DOMAIN}/MULTI_COMMUNITY_SHARE_ERROR`;

export const MULTI_COMMUNITY_UNSHARE_SUCCESS = `${DOMAIN}/MULTI_COMMUNITY_UNSHARE_SUCCESS`;
export const MULTI_COMMUNITY_UNSHARE_ERROR = `${DOMAIN}/MULTI_COMMUNITY_UNSHARE_ERROR`;

export const MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD = `${DOMAIN}/__angular__/MULTI_COMMUNITY_SHARE_CLEAR_PAYLOAD`;

/**
 * Request a post share to some communities
 * @param {string} postId Post id to share
 * @param {array} communities Community target
 */
export const sharePostInCommunities = (postId, communities) => (dispatch) => {
    sharePostInCommunitiesTask(postId, communities).then(
        (post) => {
            dispatch({type:MULTI_COMMUNITY_SHARE_SUCCESS, multiSharePayload:{postId, post}})
        }
    )
    .catch((err) =>{
            console.log(err);
            dispatch({type:MULTI_COMMUNITY_SHARE_ERROR})
        }
    )
}

/**
 * Request an unshare from a community
 * 
 * @param {string} postId Post id to unshare
 * @param {string} communityId The community id the post should be unshared from
 */
export const unsharePostFromCommunity = (postId, communityId, listkey) => (dispatch) => {
    unsharePostFromCommunityTask(postId, communityId).then(
        (post) => {
            dispatch({type:MULTI_COMMUNITY_UNSHARE_SUCCESS, multiSharePayload:{postId, post, isUnshare:true, listkey}})
        }
    )
    .catch((err) => {
            console.log(err);
            dispatch({type:MULTI_COMMUNITY_UNSHARE_ERROR})
        }
    )
}


