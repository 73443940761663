import React from 'react';

import { SearchBoxDefaultOption } from './SearchBoxDefaultOption';
import { SearchBoxInteractionOption } from './SearchBoxInteractionOption';
import { SearchBoxOptionTypeProps } from './types';

/**
 * Base component for all "suggestion" type options.
 */
export const SearchBoxSuggestionOption = ({ suggestion, ...forwardedProps }: SearchBoxOptionTypeProps) => {
    const { item } = suggestion;

    const Component = item ? SearchBoxInteractionOption : SearchBoxDefaultOption;

    return <Component suggestion={suggestion} {...forwardedProps} />;
};
