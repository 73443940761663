import { ContentStream } from '@lumapps/content-streams/types';
import { TranslateObject } from '@lumapps/translations';

export enum Status {
    loading = 'loading',
    loaded = 'loaded',
}

export interface ValueObject {
    uid: string;
    name: TranslateObject;
}

export interface GroupedValueObject {
    group: any;
    children: ValueObject[];
}

// eslint-disable-next-line no-use-before-define
export type Fields = keyof Form['fields'];

export interface Form {
    uid: string;
    isConfirmDeleteOpen?: boolean;
    isOpen?: boolean;
    status?: Status;
    wasTouched?: boolean;
    hasError?: boolean;
    fields: {
        label: {
            value: Record<string, string>;
            errorMessage?: string;
            wasTouched?: boolean;
        };
        feeds: {
            value: string[];
            choices?: string[];
            searchText?: string;
            isLoaded?: boolean;
            isOpen?: boolean;
        };
        viewMode: {
            value: string;
            choices?: string[];
            isOpen?: boolean;
        };
        featuredOnly: { value: boolean };
        highlightedOnly: { value: boolean };
        favoriteSitesOnly: { value: boolean };
        defaultInstanceRelatives: { value: boolean };
        limit: {
            value: number;
            errorMessage?: string;
            wasTouched?: boolean;
        };
        instances: {
            value: string[];
            choices?: string[];
            isLoaded?: boolean;
            errorMessage?: string;
            searchText?: string;
            isOpen?: boolean;
            wasTouched?: boolean;
        };
        contentTypes: {
            value: string[];
            choices?: Array<{ group: TranslateObject; children: string[] }>;
            searchText?: string;
            isLoaded?: boolean;
            isOpen?: boolean;
        };
        categories: {
            value: string[];
            choices?: Array<{ group: TranslateObject; children: string[] }>;
            searchText?: string;
            isOpen?: boolean;
        };
        metadata: {
            /** Selected Metadata IDs by Family ID. */
            value: Record<string, string[]>;
            /** List of family IDs loaded. */
            loadedFamilies?: string[];
            choices?: Array<{ group: string; children: string[] }>;
            searchText?: string;
            isOpen?: boolean;
            openedSubField?: string;
        };
    };
}

export interface ContentStreamAdminState {
    /**
     * Content stream by UID.
     */
    entities: Record<string, ContentStream>;
    /**
     * Content stream forms by UID.
     */
    forms: Record<string, Form>;
    /**
     * Content stream UID list.
     */
    streamList: string[];
    /**
     * Content stream admin status.
     */
    status?: Status;
}
