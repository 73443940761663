import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsSettingsOptionController(Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.id = vm.id || generateUUID();
            vm.theme = vm.theme || 'light';
        }

        init();
    }

    /////////////////////////////

    /**
     * Display a setting option to enable and set or to disable.
     *
     * @param {boolean}  [lsFullWidth=false] Indicates if we want the setting option to take the full width of its
     *                                       container.
     * @param {string}   [help]              An help text to describe the role of the setting option.
     * @param {string}   [id]                The id of the setting option.
     * @param {string}   label               The text to display as the label of the bloc option.
     * @param {Function} [ngChange]          A function to execute when the status of the option changes.
     * @param {boolean}  [ngDisabled]        Indicates if we want to disable the setting option.
     * @param {string}   ngModel             The selected icon name.
     * @param {string}   [theme='light']     The theme of the setting option.
     * @param {boolean}  [toggleDisabled]    Indicates if we want to disable the toggle of the setting option.
     */

    function LsSettingsOptionDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LsSettingsOptionController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                fullWidth: '<?lsFullWidth',
                help: '@?lsHelp',
                id: '@?lsId',
                label: '@lsLabel',
                ngChange: '&?',
                ngDisabled: '<?',
                ngModel: '=',
                theme: '@?lsTheme',
                toggleDisabled: '<?lsToggleDisabled',
            },
            templateUrl: '/client/common/modules/settings-option/views/settings-option.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsSettingsOption', LsSettingsOptionDirective);
})();
