import React from 'react';

import isObject from 'lodash/isObject';

import { SearchDocumentType, TrackingEventType } from '@lumapps/analytics-tracking/types';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { getActionsHistory } from '@lumapps/quick-search/utils/cacheUtils';
import { addInteractionSuggestion } from '@lumapps/quick-search/utils/suggestionsUtils';
import { useSelector } from '@lumapps/redux/react';
import { UseLinkProps } from '@lumapps/router/hooks/useLinkProps';
import { isExternalUrl, addFrontVersionToUrl } from '@lumapps/router/utils';
import { searchClickSendEvents, sendSearchClickBatchedEvents } from '@lumapps/search/api/analytics';
import { outOfCORS } from '@lumapps/search/api/gcs';
import { getFlag } from '@lumapps/search/constants/flag';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants/types';
import {
    getSearchQuery,
    getCurrentFeatures,
    getSearchQueryUid,
    searchContextSelector,
    getCoveoSearchInfo,
    getTotalResultsCount,
    getSelectedFilter,
    getExtensionId,
    getSearchEngine,
    getSearchTraceId,
} from '@lumapps/search/ducks/selectors';
import {
    BaseSearchResult,
    DOCUMENT_CAUSES,
    FeaturesStatus,
    SearchClickEvent,
    SearchFilter,
    SearchFilterExtension,
    SearchItem,
} from '@lumapps/search/types';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { getDateNowISO } from '@lumapps/utils/date/getDateNowISO';
import { getExtensionFromName } from '@lumapps/utils/file/getExtensionFromName';

import { getIconFromType } from '../../helpers';

export const getProps = (
    result: BaseSearchResult,
    instanceId: string,
    searchQuery: string,
    searchQueryUid: string,
    currentLanguage: string,
    features: FeaturesStatus,
    searchEngine: string | null,
    traceId: string,
    userUrl?: UseLinkProps,
    totalCount?: number,
    selectedTab?: SearchFilter | SearchFilterExtension | undefined,
    extensionId?: string,
    isUserConnected = false,
    context: Record<string, any> = {},
    additionalInformation: Record<string, string> = {},
) => {
    const flag = getFlag(result);
    const isCoveoAnalytics = features.areAnalyticsEnabled;
    const sourceUid = result.metadata?.sourceUid as SearchDocumentType;
    const resultType = Object.values(SearchDocumentType).includes(sourceUid) ? sourceUid : SearchDocumentType.EXTENSION;

    const metadataInfo = {
        rankingModifier: result?.metadata?.rankingModifier,
        contentIDKey: result?.metadata?.contentIDKey,
        contentIDValue: result?.metadata?.contentIDValue,
        sourceName: result?.metadata?.sourceName,
        documentUri: result?.metadata?.documentUri,
        documentUriHash: result?.metadata?.documentUriHash,
        collectionName: result?.metadata?.collectionName,
    };
    const analyticsPayload: Omit<SearchClickEvent, 'documentTitle' | 'type' | 'actionCause'> = {
        position: result.position,
        isPromoted: result.isPromoted,
        query: searchQuery,
        // If target type does not match with any of the lumapps types,
        // we consider the document to be coming from an extension (Drive, SharePoint, extension)
        targetType: resultType,
        // We don't have the targetId for some types of results (ie ThirdPartyResults for GCS)
        // In that case we fallback on the url
        targetId: result.id || result.url,
        // we use -1 as totalCount if it is not defined - it can happen for GCS
        resultCount: totalCount ?? -1,
        tabId: selectedTab?.value,
        additionalInformation,
        documentUrl: resultType === SearchDocumentType.USER ? userUrl?.href ?? '' : result.url,
        language: currentLanguage,
        searchQueryUid,
        ...metadataInfo,
        context,
        actionsHistory: getActionsHistory(instanceId),
        thumbnail: result.thumbnail,
        searchEngine,
        extensionId,
        traceId,
    };

    const iconName = getIconFromType(result.metadata?.mimeType, getExtensionFromName(result.title));

    return {
        ...additionalInformation,
        url: addFrontVersionToUrl(result.url),
        name: result.title,
        attachments: result.attachments,
        snippet: isObject(result.snippet) ? result.snippet?.snippet : result.snippet,
        mimeType: result.metadata?.mimeType,
        sourceUid: result.metadata?.sourceUid,
        id: result.id,
        iconName,
        shouldOpenInNewTab: isExternalUrl(result.url),
        flag,
        position: result.position,
        quickViewUrl: result.quickViewUrl,
        tagz: features.isTagzEnabled ? result.tagz : undefined,
        thumbnail: result.thumbnail,
        originSiteId: instanceId,
        onQuickViewClick: (title: string) => {
            searchClickSendEvents(
                {
                    ...analyticsPayload,
                    documentTitle: title,
                    type: TrackingEventType.SEARCH_CLICK_PREVIEW,
                    actionCause: DOCUMENT_CAUSES.quickView,
                },
                isCoveoAnalytics,
            );
        },
        // TODO: use a hook instead of this
        onClick: (item: SearchItem) => {
            const itemTitle = item.title || result.title;

            // Store the item
            addInteractionSuggestion(
                {
                    entityId: item.id || result.url,
                    entityType: item.type || SEARCH_RESULT_TYPES.GENERIC,
                    siteId: instanceId,
                    originSiteId: item.siteId || instanceId,
                    title: itemTitle,
                    url: item.url || result.url,
                    icon: item.icon || iconName,
                    thumbnail: item.thumbnail,
                    lastUsed: getDateNowISO(),
                    counterClick: 1,
                },
                searchQuery,
                isUserConnected,
            );

            sendSearchClickBatchedEvents({
                searchClick: {
                    event: {
                        ...analyticsPayload,
                        documentTitle: itemTitle,
                        type: TrackingEventType.SEARCH_CLICK,
                        actionCause: DOCUMENT_CAUSES.click,
                    },
                    isCoveoAnalytics,
                },
                clickAction: {
                    targetId: item.id || '',
                    targetType: resultType,
                    url: item.url,
                    thumbnail: item.thumbnail,
                    title: itemTitle,
                },
            });

            if (features.isCSEnabled) {
                outOfCORS(result.url, features.isCSEnabled);
            }
        },
    };
};

export const useProps = (result: BaseSearchResult, features?: FeaturesStatus) => {
    const instanceId = useSelector(instanceIdSelector);
    const isUserConnected = useSelector(isConnected);
    const searchQuery = useSelector(getSearchQuery);
    const searchQueryUid = useSelector(getSearchQueryUid);
    const coveoFields = useSelector(getCoveoSearchInfo);
    const currentLanguage = useSelector(currentLanguageSelector);
    const totalCount = useSelector(getTotalResultsCount);
    const selectedTab = useSelector(getSelectedFilter);
    const context = useSelector(searchContextSelector);
    const commonFeatures = useSelector(getCurrentFeatures);
    const extensionId = useSelector(getExtensionId);
    const searchEngine = useSelector(getSearchEngine);
    const traceId = useSelector(getSearchTraceId);

    const { linkProps: userUrl } = useSocialProfileRoute({
        routeParams: { userId: result.id },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mergedFeatures = { ...commonFeatures, ...features };

    return React.useMemo(() => {
        return getProps(
            result,
            instanceId,
            searchQuery,
            searchQueryUid,
            currentLanguage,
            mergedFeatures,
            searchEngine,
            traceId,
            userUrl,
            totalCount,
            selectedTab,
            extensionId,
            isUserConnected,
            context,
            coveoFields,
        );
    }, [
        result,
        instanceId,
        searchQuery,
        searchQueryUid,
        currentLanguage,
        mergedFeatures,
        totalCount,
        selectedTab,
        isUserConnected,
        context,
        coveoFields,
        userUrl,
        extensionId,
        searchEngine,
        traceId,
    ]);
};
