import React from 'react';

import times from 'lodash/times';

import { classnames, margin } from '@lumapps/classnames';
import { SkeletonRectangle } from '@lumapps/lumx/react';

interface SkeletonFilterProps {
    quantity?: number;
}

export const SkeletonFilter = ({ quantity }: SkeletonFilterProps) => (
    <>
        {times(quantity || 1, (item) => (
            <div key={item}>
                <SkeletonRectangle
                    height="xs"
                    width="xl"
                    className={classnames(margin('top', 'huge'), margin('bottom', 'tiny'))}
                />
                <SkeletonRectangle height="m" className={margin('bottom', 'big')} />
            </div>
        ))}
    </>
);
