/* istanbul ignore file */
import difference from 'lodash/difference';
import flatten from 'lodash/flatten';

import { fetchAll } from '@lumapps/base-api';
import { cache, CACHE_TYPE } from '@lumapps/cache';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { error as notifyError, success } from '@lumapps/notifications';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import * as api from '../api';
import { CONTENT_TYPES } from '../keys';
import { CustomContentType, CustomContentTypeTag, CustomContentTypeCreationPayload } from '../types';
import { getCustomContentTypes, getCustomContentTypesCacheKey } from './selectors';
import { actions } from './slice';

export const fetchCustomContentTypes =
    (invalidateCache = false) =>
    async (dispatch: Dispatch, getState: GetBaseState) => {
        dispatch(actions.setLoadingStatusCustomContentTypes(BaseLoadingStatus.loading));
        const currentState = getState();
        const instance = instanceIdSelector(currentState);
        const lang = currentLanguageSelector(currentState);
        const customContentTypeCacheKey = getCustomContentTypesCacheKey(currentState);

        try {
            const response = await api.list({ instance, lang, invalidateCache });
            const { items = [] } = response.data;

            dispatch(actions.setCustomContentTypes(items));
            dispatch(actions.setParentCustomContentTypeIds(items));

            const currentCachedValues = cache.retrieve(customContentTypeCacheKey, CACHE_TYPE.STORAGE) || {};
            const cachedInstances = Object.keys(currentCachedValues);

            /**
             * Since a customer can have A LOT of instances, we add a limit up to 5 instances saved. If we already
             * have 5 of them in our cache, we remove the first one.
             */
            if (cachedInstances.length > 4) {
                delete currentCachedValues[cachedInstances[0]];
            }

            cache.store(
                customContentTypeCacheKey,
                { ...currentCachedValues, [instance]: items.length },
                CACHE_TYPE.STORAGE,
            );
        } catch (error) {
            dispatch(actions.setCustomContentTypes([]));
            dispatch(actions.setParentCustomContentTypeIds([]));
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        } finally {
            dispatch(actions.setLoadingStatusCustomContentTypes(BaseLoadingStatus.idle));
        }
    };

/**
 * Fetch custom content types by IDs.
 */
export const fetchCustomContentTypesByIds = (params: api.GetMultiParams) => async (dispatch: Dispatch) => {
    const contentTypes = await fetchAll(api.getMulti, params);
    await dispatch(actions.updateContentTypes(contentTypes));
};

/**
 * Fetch custom content types by IDs only if not in store.
 */
export const getOrFetchCustomContentTypesByIds =
    ({ uids, ...params }: api.GetMultiParams) =>
    async (dispatch: Dispatch, getState: GetBaseState) => {
        const entities = getCustomContentTypes(getState());
        const existingIds = entities ? Object.keys(entities) : [];
        // Only request ids not in store.
        const requestedIds = difference(uids, existingIds);

        if (requestedIds.length === 0) {
            return;
        }

        try {
            dispatch(actions.setLoadingStatusCustomContentTypes(BaseLoadingStatus.loading));

            await dispatch(fetchCustomContentTypesByIds({ uids: requestedIds, ...params }));
        } catch (error) {
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        } finally {
            dispatch(actions.setLoadingStatusCustomContentTypes(BaseLoadingStatus.idle));
        }
    };

export const getCustomContentTypesByInstances =
    (instanceIds: string[], includeInstanceSiblings?: boolean) => async (dispatch: Dispatch) => {
        const requests = instanceIds.map((instance) => fetchAll(api.list, { instance, includeInstanceSiblings }));
        const contentTypes = flatten(await Promise.all(requests));

        dispatch(actions.updateContentTypes(contentTypes));
    };

export const setCurrentContentTypeId = (contentTypeId: CustomContentType['id']) => (dispatch: Dispatch) => {
    dispatch(actions.setCurrentContentTypeId(contentTypeId));
};

export const updateCustomContentType =
    (updatedContentType: CustomContentType, successMessage?: string) => async (dispatch: Dispatch) => {
        try {
            const response = await api.save(updatedContentType);
            const { data } = response;

            dispatch(actions.updateContentType(data));

            if (successMessage) {
                dispatch(success({ translate: successMessage }));
            }
        } catch (error) {
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        }
    };

export const addCustomContentType =
    (newContentType: CustomContentTypeCreationPayload) => async (dispatch: Dispatch) => {
        try {
            const response = await api.save(newContentType);
            const { data } = response;

            dispatch(actions.updateContentType(data));

            dispatch(success({ translate: CONTENT_TYPES.CONTENT_TYPE_SAVE_SUCCESS }));
        } catch (error) {
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        }
    };

export const deleteCustomContentTypes = (types: string[]) => async (dispatch: Dispatch) => {
    try {
        await api.deleteMulti({ uid: types });

        dispatch(actions.deleteContentTypes(types));

        dispatch(success({ translate: CONTENT_TYPES.CONTENT_TYPE_DELETE_SUCCESS }));
    } catch (error) {
        dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
    }
};

export const addTagToCustomContentType =
    (newTag: Partial<CustomContentTypeTag>, customContentTypeId: CustomContentType['id']) =>
    async (dispatch: Dispatch, getState: GetBaseState) => {
        const state = getState();
        const ccts = getCustomContentTypes(state);

        const cct = ccts ? ccts[customContentTypeId] : null;

        if (cct) {
            if (cct.tags) {
                cct.tags.push(newTag as CustomContentTypeTag);
            } else {
                cct.tags = [newTag as CustomContentTypeTag];
            }

            await updateCustomContentType(cct)(dispatch);
        }

        return null;
    };

export const updateTagInCustomContentType =
    (updatedTag: Partial<CustomContentTypeTag>, customContentTypeId: CustomContentType['id']) =>
    async (dispatch: Dispatch, getState: GetBaseState) => {
        const state = getState();
        const ccts = getCustomContentTypes(state);

        const cct = ccts ? ccts[customContentTypeId] : null;

        if (cct) {
            cct.tags.forEach((tag: CustomContentTypeTag) => {
                if (tag.id === updatedTag.id) {
                    Object.assign(tag, updatedTag);
                }
            });

            await updateCustomContentType(cct)(dispatch);
        }

        return null;
    };

export const deleteTagInCustomContentType =
    (deletedTags: string[], customContentTypeId: CustomContentType['id']) =>
    async (dispatch: Dispatch, getState: GetBaseState) => {
        const state = getState();
        const ccts = getCustomContentTypes(state);

        const cct = ccts ? ccts[customContentTypeId] : null;

        if (cct) {
            const updatedCCT = { ...cct };
            const updatedTags: CustomContentTypeTag[] = [];

            updatedCCT.tags.forEach((tag: CustomContentTypeTag) => {
                if (deletedTags.indexOf(tag.id) < 0) {
                    updatedTags.push(tag);
                }
            });

            updatedCCT.tags = updatedTags;

            await updateCustomContentType(updatedCCT, CONTENT_TYPES.CONTENT_TYPE_TAG_DELETE_SUCCESS)(dispatch);
        }

        return null;
    };
