import last from 'lodash/last';

import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { NodeEntry, ReactEditor } from '@lumapps/wrex/slate';

import { IMAGE_ALIGNMENTS } from '../../constants';
import { ImageEditor } from '../../types';
import { isAlignedImageWrapper } from '../../utils/isAlignedImageWrapper';
import { isAllowedInAlignedWrapper } from '../../utils/isAllowedInAlignedWrapper';
import { isImageGroup } from '../../utils/isImageGroup';

export const normalizeAlignedImageWrapper = (editor: ReactEditor & ImageEditor, [node, path]: NodeEntry) => {
    if (isAlignedImageWrapper(node)) {
        const wrapperAlignment = node.alignment;
        const images = Array.from(editor.nodes({ at: path, match: isImageGroup }));

        // If there is no image in the wrapper, we unwrap.
        if (!images.length) {
            editor.unwrapNodes({ at: path });
            return false;
        }

        if (images.length > 1) {
            // Index of the image we kept
            const keptImageIndex = wrapperAlignment === IMAGE_ALIGNMENTS.left ? 0 : images.length - 1;

            // Move all other images outside, after the image alignment wrapper
            images.forEach(([, imgPath], index) => {
                if (index !== keptImageIndex) {
                    editor.moveNodes({ at: imgPath, to: [path[0] + 1] });
                }
            });
        }

        // If there is no compatible block other than the image in the wrapper, we add an empty paragraph.
        if (!Array.from(editor.nodes({ at: path, match: isAllowedInAlignedWrapper, mode: 'lowest' })).length) {
            if (wrapperAlignment === IMAGE_ALIGNMENTS.right) {
                editor.insertNodes(createParagraph([{ text: '' }]), { at: [...path, 0], mode: 'lowest' });
            } else {
                editor.insertNodes(createParagraph([{ text: '' }]), {
                    at: [...path, node.children.length],
                    mode: 'lowest',
                });
            }
        }

        // If the image is aligned on left, the image should always be the first element of the wrapper
        if (wrapperAlignment === IMAGE_ALIGNMENTS.left) {
            const [[, imagePath]] = images;

            const imagePosition = last(imagePath);

            if (imagePosition !== 0) {
                editor.moveNodes({ at: imagePath, to: [...path, 0] });
            }
        } else {
            // If the image is aligned on right, the image should always be the last element of the wrapper
            const [[, imagePath]] = images;

            const imagePosition = last(imagePath);

            if (imagePosition !== node.children.length - 1) {
                editor.moveNodes({ at: imagePath, to: [...path, node.children.length - 1] });
            }
        }
    }

    return false;
};
