import React from 'react';

import { GridColumn } from '@lumapps/lumx/react';

import { MIN_WIDTH_CATALOG_CATEGORY, DEFAULT_MAX_NUMBER_COLUMNS_CATALOG_GRID } from '../../constants';
import { Category as CategoryType } from '../../types';
import { Category } from '../Category';
import { LoadingCategoryItem } from '../Category/LoadingCategoryItem';

export interface CategoryListProps {
    isLoading: boolean;
    categories: CategoryType[];
    maxColumns?: number;
    minWidth?: number;
    onSelectCategory: (code: string) => void;
}
/**
 * Grid List of the learning categories that our user has access to.
 * @param categoryListProps
 * @returns categoryList
 */
export const CategoryList: React.FC<CategoryListProps> = ({
    categories,
    maxColumns = DEFAULT_MAX_NUMBER_COLUMNS_CATALOG_GRID,
    minWidth = MIN_WIDTH_CATALOG_CATEGORY,
    onSelectCategory,
    isLoading = false,
}) => {
    return (
        <GridColumn maxColumns={maxColumns} gap="huge" itemMinWidth={minWidth}>
            {!isLoading
                ? categories.map((category) => (
                      <Category key={category.code} category={category} onSelectCategory={onSelectCategory} />
                  ))
                : Array.from({ length: maxColumns }, (key, index) => <LoadingCategoryItem key={index} />)}
        </GridColumn>
    );
};
