/* eslint-disable require-jsdoc-except/require-jsdoc */
/* eslint-disable no-use-before-define */
/* eslint-disable lumapps/file-format */
import get from 'lodash/get';
import set from 'lodash/set';

import { DOCUMENT_TYPE_LIST } from '../../../../../../../delve/js/tasks_constant';

/////////////////////////////

function WidgetDelveSettingsController(Widget) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetDelveSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current widget instance.
     *
     * @type {Object}
     */
    let _currentWidgetInstance = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The id of the user picker in the settings sidebar.
     *
     * This part of code in commentary will be useful in future version of this widget
     * when the user will be able to select a targeted user from the user picker instead of just himself.
     *
     * @type {string}
     * @constant...
     */
    // WidgetDelveSettings.PICKER_ID = 'widget-user-list-picker-';

    /**
     * Contains the list of picked users id.
     *
     * This part of code in commentary will be useful in future version of this widget
     * when the user will be able to select a targeted user from the user picker instead of just himself.
     *
     * @type {Array}
     */
    // WidgetDelveSettings.pickerUsers = [];

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /*
     * Function that set the default value for some properties.
     */
    function _initFields() {
        widgetDelveSettings.documentTypeList = DOCUMENT_TYPE_LIST;

        const properties = get(_currentWidgetInstance, 'properties');

        if (angular.isUndefinedOrEmpty(properties.userType)) {
            properties.userType = 'current';
        }

        if (angular.isUndefinedOrEmpty(properties.user)) {
            selectUserType('current');
        }

        if (angular.isUndefinedOrEmpty(properties.orderBy)) {
            properties.orderBy = 'MOST_USED';
        }

        if (angular.isUndefinedOrEmpty(properties.maxResults)) {
            properties.maxResults = 10;
        }

        if (angular.isUndefinedOrEmpty(properties.contentType)) {
            properties.contentType = 'INSIGHT_PEOPLE';
        }

        if (angular.isUndefinedOrEmpty(properties.isTargetedUserDisplayed)) {
            properties.isTargetedUserDisplayed = true;
        }

        set(_currentWidgetInstance.properties, properties);
    }

    /**
     * Function that update a value of a properties of the current widget.
     *
     * @param {string} key   The property's key.
     * @param {string} value The property's value.
     */
    function _updateWidget(key, value) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        if (currentWidget.uuid !== _currentWidgetInstance.uuid) {
            init();
        }

        set(properties, [key], value);
        currentWidget.properties = properties;
    }

    /////////////////////////////
    //                         //
    //    Public functions     //
    //                         //
    /////////////////////////////

    /**
     * Open the user picker.
     *
     * This part of code in commentary will be useful in future version of this widget
     * when the user will be able to select a targeted user from the user picker instead of just himself.
     *
     */
    /* Function openUserPicker() {
        Utils.waitForAndExecute(`#${widgetDelveSettings.PICKER_ID}`, UserPicker);
    }. */

    /**
     * Function that change the targeted user.
     *
     * @param {string} userType The type of the user.
     */
    function selectUserType(userType) {
        if (userType === 'current') {
            widgetDelveSettings.pickerUsers = [];
            _updateWidget('user', null);
        }
        /*
        This part of code in commentary will be useful in future version of this widget
        when the user will be able to select a targeted user from the user picker instead of just himself.
        *
        /* Else if (userType === 'pick') {
            updateWidget('user', undefined);
        }. */
    }

    /////////////////////////////

    widgetDelveSettings.selectUserType = selectUserType;
    /*
    This part of code in commentary will be useful in future version of this widget
    when the user will be able to select a targeted user from the user picker instead of just himself.
    *
    widgetDelveSettings.openUserPicker = openUserPicker;
    */

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the user picker closes, update the widget's picked users.
     *
     * This part of code in commentary will be useful in future version of this widget
     * when the user will be able to select a targeted user from the user picker instead of just himself.
     *
     * @param {Event}  evt      The picker close event.
     * @param {string} pickerId The id of the picker beeing closed.
     */
    /* $scope.$on('user-picker__close-start', function onUserPickerCloseStart(evt, pickerId) {
        if (widgetDelveSettings.PICKER_ID === pickerId) {
            // Widget.getCurrent().properties.usersIds = _.map(widgetUserListSettings.userList, 'id');

            if (angular.isDefinedAndFilled(widgetDelveSettings.pickerUsers)) {
                updateWidget('user', widgetDelveSettings.pickerUsers[0]);
            }
        }
    }); */

    /////////////////////////////

    /*
     * The Initialize function.
     */
    function init() {
        _currentWidgetInstance = Widget.getCurrent();

        _initFields();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetDelveSettingsController', WidgetDelveSettingsController);

/////////////////////////////

export { WidgetDelveSettingsController };
