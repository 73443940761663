import uniqueId from 'lodash/uniqueId';

import BaseApi, { BaseApiProgressEvent, makeParamsSerializer } from '@lumapps/base-api';
import { UploadProgress } from '@lumapps/play-video-library/types';
import { withApiv1Languages } from '@lumapps/translations';

import { getLanguageHeader } from '../utils';
import {
    CreateAssetResult,
    CreateThumbnailResult,
    CreateUploadObject,
    CreateVideoBody,
    CreateVideoResult,
    DownloadVideoResponse,
    GetVideoParams,
    GetVideoResult,
    UpdateVideoBody,
    Video,
    VideoListQuery,
    VideoListResult,
    VideosFetchTypes,
    VideoWithCustomChapters,
} from './types';
/**
 * Play API.
 */
export const playApi = new BaseApi({
    path: 'videos',
    version: BaseApi.versions.v2,
});

export const playVideoKeys = {
    all: (params?: VideoListQuery) => ['videos', params] as const,
};

const cleanData = (video: CreateVideoBody | UpdateVideoBody) => {
    const translations = Object.entries(video.description?.translations || {})
        .filter(([, value]) => value?.length)
        .reduce((acc, [key, value]) => ({ [key]: value, ...acc }), {});

    return {
        ...video,
        ...(video.description && {
            description: {
                ...video.description,
                translations,
            },
        }),
    };
};

/**
 * PLAY CMS API
 */
export async function getVideos(params?: VideoListQuery, signal?: AbortSignal) {
    const { data: listVideoResponse } = await playApi.get<VideoListResult>('', {
        params,
        signal,
        headers: { 'Accept-Language': getLanguageHeader() },
    });

    return {
        ...listVideoResponse,
        items: listVideoResponse.items.map(withApiv1Languages),
    };
}

export async function getVideo(
    videoId: GetVideoParams,
    fields?: (keyof Video)[],
    query?: Pick<VideosFetchTypes, 'thumbnailSizes'>,
) {
    const url = fields && fields.length > 0 ? `/${videoId}/embed` : `/${videoId}`;
    const { data: video } = await playApi.get<GetVideoResult>(url, {
        headers: { 'Accept-Language': getLanguageHeader() },
        params: {
            fields,
            ...(query?.thumbnailSizes && { ...query.thumbnailSizes }),
        },
        paramsSerializer: makeParamsSerializer({ arrayFormat: 'comma', encode: false }),
    });

    if (typeof video === 'undefined') {
        return undefined;
    }

    if (!video.chapters) {
        return withApiv1Languages(video);
    }

    /**
     * Since API doesn't return any key (ID or something), we have to handle it front side.
     * So this interface aims to handle this case. We have to generate a unique ID that is temporary, so that we can
     * manipulate chapters efficiently.
     */
    const videoWithCustomChapters: VideoWithCustomChapters = {
        ...video,
        chapters: video.chapters.map((chapter) => ({
            ...chapter,
            key: uniqueId('api-chapter'),
        })),
    };

    return withApiv1Languages(videoWithCustomChapters);
}

export async function createVideo(data: CreateVideoBody, contentLength?: number) {
    const cleanedData = cleanData(data);
    const { data: createVideoResult } = await playApi.post<CreateVideoResult>('', {
        ...cleanedData,
        videoContentLength: contentLength,
    });

    return createVideoResult;
}

export function uploadVideo(
    uploadObject: CreateUploadObject,
    file: File,
    onUploadProgress: ({ progress, remainingTime }: UploadProgress) => void,
) {
    const { headers, url } = uploadObject;
    const startDate = Date.now();

    return playApi.put(
        url,
        file,
        {
            headers: {
                'Content-Type': file.type,
                ...headers,
            },
            /**
             * "transformRequest" allows changes to the request data before it is sent to the server.
             * This is only applicable for request methods "PUT", "POST", "PATCH" and "DELETE".
             *
             * @see https://github.com/axios/axios#request-config
             */
            transformRequest: (data, requestHeaders) => {
                const theHeaders = requestHeaders;

                // We need to remove the Authorization headers for this request
                if (theHeaders) {
                    delete theHeaders.Authorization;
                }

                return data;
            },
            onUploadProgress: ({ loaded, total: progressTotal }: BaseApiProgressEvent) => {
                const total = progressTotal || 0;
                const progress = Math.round(((loaded * 100) / total) * 100) / 100;

                const timeElapsedInSeconds = (Date.now() - startDate) / 1000;
                const uploadSpeed = loaded / timeElapsedInSeconds;
                const remainingBytes = total - loaded;
                const remainingTime = Math.floor(remainingBytes / uploadSpeed);

                onUploadProgress({ progress, remainingTime });
            },
        },
        true,
        url,
    );
}

export function cancelUploadVideo(url: string) {
    playApi.cancel(url, {}, url);
}

export async function updateVideo(videoId: string, data: UpdateVideoBody) {
    const cleanedData = cleanData(data);
    await playApi.patch(`/${videoId}`, cleanedData);
}

export async function deleteVideo(videoId: string) {
    await playApi.delete(`/${videoId}`);
}

export async function createThumbnail(videoId: string, file: File) {
    const formData = new FormData();
    formData.append('payload', new Blob([file], { type: file.type }), file.name);

    const { data } = await playApi.post<CreateThumbnailResult>(`/${videoId}/thumbnail`, formData);

    return data;
}

export async function createAsset(videoId: string, contentLength?: number, loop?: string) {
    const { data } = await playApi.post<CreateAssetResult>(`/${videoId}/assets`, {
        ...(loop && { loop: { templateId: loop } }),
        videoContentLength: contentLength,
    });
    return data;
}

export const downloadVideo = async (videoId: string) => {
    const { data } = await playApi.get<DownloadVideoResponse>(`/${videoId}/download`);

    const link = document.createElement('a');
    link.href = data.downloadUrl;
    link.setAttribute('download', videoId);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
