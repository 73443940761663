import partial from 'lodash/partial';

import { Editor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';
import { selectElementOnDelete } from '@lumapps/wrex/slate/utils/selectElementOnDelete';
import { WrexEditor } from '@lumapps/wrex/types';

import { EditableEmbeddedMap } from '../components/editableBlocks/EditableEmbeddedMap';
import { EmbeddedMapEditor } from '../types';
import { insertEmbeddedMap } from '../utils/insertEmbeddedMap';
import { isEmbeddedMap } from '../utils/isEmbeddedMap';

const createPluginEditor = (): CreatePluginEditor<EmbeddedMapEditor, WrexEditor<EmbeddedMapEditor>> => (editor) => {
    const { isVoid, normalizeNode, deleteBackward, deleteForward } = editor;

    return {
        normalizeNode([node, path]) {
            const pathRef = Editor.pathRef(editor, path);
            normalizeLiftToRootNode(editor, [node, path], isEmbeddedMap);
            const changedPath = pathRef.unref();
            if (changedPath) {
                normalizeNode([node, changedPath]);
            }
        },

        isVoid: (element) => isEmbeddedMap(element) || isVoid(element),

        deleteBackward(unit) {
            if (unit === 'character' && selectElementOnDelete(editor, false, isEmbeddedMap)) {
                return;
            }
            deleteBackward(unit);
        },

        deleteForward(unit) {
            if (unit === 'character' && selectElementOnDelete(editor, true, isEmbeddedMap)) {
                return;
            }
            deleteForward(unit);
        },

        insertEmbeddedMap: partial(insertEmbeddedMap, editor),
    };
};
export const withEmbeddedMap = () =>
    createPlugin({
        createPluginEditor: createPluginEditor(),
        elements: [EditableEmbeddedMap],
    });
