(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetTitleController($element, $scope, $state, $timeout, Content, ContentForm, Features, InitialSettings,
        Translation, Widget) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * A DOM element that darkens the rest of the page when editing the content.
         *
         * @type {Element}
         */
        var _appFilter = angular.element('<div/>', {
            'class': 'app-filter app-filter--no-transition',
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if we are editing the content.
         *
         * @type {boolean}
         */
        vm.editingContent = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Content = Content;
        vm.ContentForm = ContentForm;
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Toggle drag and drop.
         */
        function _toggleDragAndDrop() {
            Widget.isDragDisabled(!Widget.isDragDisabled());
            $element.parents('.component-cell').toggleClass('component-cell--hover-disabled', Widget.isDragDisabled());
        }

        /**
         * Edit the content.
         */
        function _editContent() {
            _toggleDragAndDrop();

            vm.editingContent = true;

            if (Content.getViewMode() !== 'basic') {
                _appFilter.appendTo('.app-content');
            }

            $timeout(function delayFilterDisplay() {
                if (Content.getViewMode() !== 'basic') {
                    _appFilter.addClass('app-filter--is-shown');
                }

                $element.find('.widget-title__input').focus();
            }, 100);
        }

        /**
         * Read the content.
         */
        function _readContent() {
            vm.editingContent = false;

            var isNotBasicMode = (Content.getViewMode() !== 'basic');
            if (isNotBasicMode) {
                _appFilter.removeClass('app-filter--is-shown');
            }

            $timeout(function delayFilterRemove() {
                if (isNotBasicMode) {
                    _appFilter.remove();
                }

                _toggleDragAndDrop();
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Edit the content in basic mode.
         */
        function editContentBasic() {
            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                return;
            }
            
            if (Content.getAction() !== 'get' && Content.getViewMode() === 'basic' && !vm.editingContent &&
                ($state.params.contentType !== InitialSettings.CONTENT_TYPES.COMMUNITY)) {
                _editContent();
            }
        }

        /**
         * Edit the content in default or simple mode.
         */
        function editContentDefaultOrSimple() {
            var viewMode = Content.getViewMode();

            if (Content.getAction() !== 'get' && !vm.editingContent &&
                (viewMode === 'default' || viewMode === 'simple') &&
                ($state.params.contentType !== InitialSettings.CONTENT_TYPES.COMMUNITY)) {
                _editContent();
            }
        }

        /**
         * Get widget classes.
         *
         * @return {Array} The widget class.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            widgetClass.push('widget-editable');

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            if (vm.editingContent) {
                widgetClass.push('widget-title--is-editing');
                widgetClass.push('widget-editable--is-editing');
            }

            if ($state.current.name === 'app.front.community') {
                widgetClass.push('widget-title--in-community');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            var title = Content.getCurrent().title;

            return !vm.editingContent &&
                (angular.isUndefined(title) || angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(title)));
        }

        /**
         * Is widget hidden in reading mode.
         *
         * @return {boolean} If the widget is hidden or not.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = false;

            return vm.parentCtrl.isHidden;
        }

        /**
         * Close edit mode.
         */
        function readContent() {
            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                return;
            }

            _readContent();
        }

        /////////////////////////////

        vm.editContentDefaultOrSimple = editContentDefaultOrSimple;
        vm.editContentBasic = editContentBasic;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.readContent = readContent;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        $scope.$on('contribution-mode', (evt, contributionMode) => {
            if (contributionMode === 'writer') {
                _editContent();
            } else {
                _readContent();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                _editContent();
            }
        }

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            init();
        };
    }

    /////////////////////////////

    function WidgetTitleDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetTitleController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetTitle', '^widget'],
            restrict: 'E',
            scope: {
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-title/views/widget-title.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetTitle', WidgetTitleDirective);
})();
