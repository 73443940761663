/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore, Dispatch } from '@lumapps/redux/types';
import { isConnected } from '@lumapps/user/ducks/selectors';

import {
    notificationCenterSelector,
    unreadNotificationsCountSelector,
    hasNotificationsSelector,
    notificationGroupsSelector,
} from '../../ducks/selectors';
import {
    listAllNotifications,
    listUnreadNotifications,
    setAsRead,
    setAsUnread,
    setAllAsRead,
    deleteAll,
    deleteNotification,
    refreshNotifications,
    countUnread,
} from '../../ducks/thunks';
import { NotificationStatus } from '../../types';
import { NotificationMenu } from './NotificationMenu';

const mapStateToProps = (state: FrontOfficeStore) => {
    const { isLoading, hasError, more, cursor } = notificationCenterSelector(state);
    const notificationGroups = notificationGroupsSelector(state);
    const unreadNotificationsCount = unreadNotificationsCountSelector(state);
    const hasNotifications = hasNotificationsSelector(state);

    return {
        isLoading,
        hasNotifications,
        notifications: notificationGroups,
        unreadNotificationsCount,
        hasError,
        more,
        cursor,
        isVisible: isConnected(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        countUnread: () => dispatch(countUnread()),
        listNotifications: (notificationStatus?: NotificationStatus, cursor?: string) =>
            dispatch(
                notificationStatus === NotificationStatus.unread
                    ? listUnreadNotifications(cursor)
                    : listAllNotifications(cursor),
            ),
        setAsRead: (notificationId: string) => dispatch(setAsRead(notificationId)),
        setAsUnread: (notificationId: string) => dispatch(setAsUnread(notificationId)),
        setAllAsRead: () => dispatch(setAllAsRead()),
        deleteNotification: (notificationId: string, isNotificationRead: boolean) =>
            dispatch(deleteNotification(notificationId, isNotificationRead)),
        deleteAll: () => dispatch(deleteAll()),
        refreshNotifications: (notificationStatus?: NotificationStatus, forceRefresh?: boolean) =>
            dispatch(refreshNotifications(notificationStatus, forceRefresh)),
    };
};

const ConnectedNotificationMenu = connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);

export { ConnectedNotificationMenu as NotificationMenu };
