import React from 'react';

import throttle from 'lodash/throttle';

import { useQuery } from '@lumapps/base-api/react-query';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiMenuDown } from '@lumapps/lumx/icons';
import { IconButton, PopoverProps, Text, Theme, ButtonProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { UserLink } from '@lumapps/user/components/UserLink';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { FetchLikeUserList, reactions } from '../../../api/query';
import { ResourceRef } from '../../../api/types';
import { REACTIONS } from '../../../keys';

import './index.scss';

export interface LikeUserListMenuProps {
    resourceRef: ResourceRef<string>;
    menuButtonRef?: React.Ref<any>;
    popoverAnchorRef?: PopoverProps['anchorRef'];
    theme: Theme;
    onSeeAll: () => void;
    fetchLikeUserList: FetchLikeUserList;
    hoverRef?: React.RefCallback<any>;
    shouldOpen?: boolean;
    size: ButtonProps['size'];
}

const CLASSNAME_MENU = 'like-reaction-button-menu';
const CLASSNAME_LIST = 'like-button-like-list';

const DEFAULT_WIDTH = REAL_SIZE_FOR_LUMX_SIZE.xl;
const MAX_RESULTS = '10';

/**
 * Like user list menu
 */
export const LikeUserListMenu = (props: LikeUserListMenuProps) => {
    const {
        shouldOpen,
        resourceRef,
        menuButtonRef,
        popoverAnchorRef,
        theme,
        onSeeAll,
        fetchLikeUserList,
        hoverRef,
        size,
    } = props;
    const { translateKey } = useTranslate();

    const [enabled, setEnabled] = React.useState(false);
    const { isLoading, data } = useQuery({
        queryKey: reactions.listLikesQueryKeys(resourceRef),
        queryFn: () => fetchLikeUserList(resourceRef, { maxResults: MAX_RESULTS }),
        enabled,
    });

    // Make `isOpen` update indirectly so it can also be updated with the menu `onToggle`
    const [isOpen, setIsOpen] = React.useState(shouldOpen);
    React.useEffect(() => {
        setIsOpen(shouldOpen);
    }, [shouldOpen]);

    const onToggle = React.useMemo(
        () =>
            throttle((opening: boolean) => {
                // Update the menu local state
                setIsOpen(opening);
                if (!opening) {
                    return;
                }
                // Enable query on first open
                setEnabled(true);
            }),
        [],
    );

    return (
        <Menu.Trigger
            isOpen={isOpen}
            onToggle={onToggle}
            loadingMessage={translateKey(GLOBAL.LOADING)}
            // Override the popover anchor to be the button group
            popoverProps={{ anchorRef: popoverAnchorRef, className: CLASSNAME_LIST, fitToAnchorWidth: false }}
        >
            <Menu.TriggerButton
                ref={mergeRefs([menuButtonRef, hoverRef])}
                as={IconButton}
                className={CLASSNAME_MENU}
                emphasis="low"
                icon={mdiMenuDown}
                theme={theme}
                size={size}
                label={translateKey(REACTIONS.USER_LIST_LIKES)}
            />

            {isLoading ? (
                <Menu.Skeleton ref={hoverRef} width={DEFAULT_WIDTH}>
                    <Menu.ItemSkeleton />
                    <Menu.ItemSkeleton label={{ width: '80%' }} />
                </Menu.Skeleton>
            ) : (
                <Menu ref={hoverRef}>
                    {data?.items?.map(({ id, fullName }) => (
                        <Menu.Item as={UserLink} user={{ id, fullName }} showCardOnHover={false} key={id}>
                            {fullName}
                        </Menu.Item>
                    ))}
                    <Menu.Item onClick={onSeeAll}>
                        <Text as="span" color="blue">
                            {translateKey(GLOBAL.SEE_ALL)}
                        </Text>
                    </Menu.Item>
                </Menu>
            )}
        </Menu.Trigger>
    );
};
