import BaseApi from '@lumapps/base-api';

const workspaceApi = new BaseApi({ path: 'workspace' });

const getDefaultParams = () => ({});

/**
 * Get a workspace.
 *
 * @param  {string}  workspaceId Additional request params.
 * @return {Promise} The request promise.
 */
const get = ({ workspaceId } = {}) => {
    return workspaceApi.get('get', {
        ...getDefaultParams(),
        params: { workspaceId },
    });
};

/**
 * Fetch the chat informations from a workspace.
 *
 * @param  {string}  id Community id
 * @return {Promise} Returns the list of user spaces depending on the provider.
 */
const getChat = ({ id }) => {
    return workspaceApi.get('chat', {
        ...getDefaultParams(),
        params: { id },
    });
};

export { get, getChat };
