import React, { ReactNode, Fragment } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { ContentTypes } from '@lumapps/content-types/types';
import { ColorPalette, Link, Text, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { TagReference } from '@lumapps/widget-base/types';

import { getPostPublicationTranslationKey } from '../../../keys';
import { BlockPostIdeaStatus, PostShare } from '../../../types';
import { PostIdeaStatusLink } from '../PostIdeaStatusLink';

import './index.scss';

export interface PostPublicationDescriptionProps {
    /** Post id */
    postId: string;
    /** Post share information */
    postShare?: PostShare;
    /** Categories of the tag. */
    tags?: TagReference[];
    /** Idea post status */
    ideaStatus?: BlockPostIdeaStatus;
    /** User can edit the post. */
    canEdit?: boolean;
    /** Id of the current community */
    currentCommunityId?: string;
    /** HTML id to set on the description */
    id?: string;
    /** Theme */
    theme?: Theme;
}

const CLASSNAME = 'post-publication-description';

/**
 * Component to render author's information in a post.
 * Display current community if has communityName props
 * Display categories if an array of tags is given
 */
export const PostPublicationDescription: React.FC<PostPublicationDescriptionProps> = ({
    currentCommunityId,
    postShare,
    tags,
    postId,
    ideaStatus,
    canEdit,
    id,
    theme = Theme.light,
}) => {
    const { translateAndReplaceWithComponents } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const isPostCommunityPage = currentCommunityId === postShare?.sharedTo?.communityId;

    // Block displayed as location
    let locationBlock: ReactNode;

    // In community page => show the post tags.
    if (isPostCommunityPage && tags && tags.length > 0) {
        locationBlock = tags.map((tag, idx) => (
            <Fragment key={tag.tagId}>
                {idx !== 0 && ' - '}
                <Text as="span" className={element('tags')}>
                    {tag.name}
                </Text>
            </Fragment>
        ));
    }
    // Outside the community => show the community link.
    if (!isPostCommunityPage && postShare?.sharedTo) {
        locationBlock = (
            <Link
                className={element('community')}
                color={theme === Theme.dark ? ColorPalette.light : undefined}
                linkAs={CommunityLink}
                to={{
                    id: postShare.sharedTo.communityId,
                    slug: postShare.sharedTo.slug,
                    renderingType: postShare.sharedTo.renderingType,
                    type: ContentTypes.COMMUNITY,
                    instance: { id: postShare.site?.siteId, slug: postShare.site?.slug },
                }}
            >
                {postShare.sharedTo.name}
            </Link>
        );
    }

    return (
        <Text as="span" id={id}>
            {translateAndReplaceWithComponents(
                getPostPublicationTranslationKey({
                    hasLocation: Boolean(locationBlock),
                    hasStatus: Boolean(ideaStatus),
                }),
                {
                    LOCATION: <React.Fragment key="location">{locationBlock}</React.Fragment>,
                    STATUS: (
                        <PostIdeaStatusLink
                            key="post-idea-status"
                            className={element('tags')}
                            canEdit={canEdit}
                            postId={postId}
                            ideaStatus={ideaStatus}
                        />
                    ),
                },
            )}
        </Text>
    );
};
