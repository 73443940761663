(function IIFE() {
    'use strict';

    /////////////////////////////

    function SettingsListController($scope, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        vm.init = function init() {
            vm.button = vm.button || Translation.translate('GLOBAL.ADD');
            vm.icon = vm.icon || 'file';
            vm.text = vm.text || Translation.translate('GLOBAL.NOTHING_SELECTED');

            if (!angular.isObject(vm.button)) {
                vm.button = {
                    empty: vm.button,
                    filled: vm.button,
                };
            }

            if (!angular.isObject(vm.action)) {
                vm.action = {
                    empty: vm.action,
                    filled: vm.action,
                };
            }
        };

        vm.init();
    }

    /**
     * Settings list directive.
     * Display a settings sidebar block for managing a list.
     *
     * @param {Function|Object} action                           The action to execute when the button is clicked.
     *                                                           Can either be a function to use in both case (empty
     *                                                           list and filled list) or an object with an 'empty'
     *                                                           property which represents the action to execute when
     *                                                           the list is empty and a 'filled' property which
     *                                                           represents the action to execute when there is at least
     *                                                           one item in the list.
     * @param {string|Object}   [button="GLOBAL.ADD"]            The label of the button.
     *                                                           Can either be a string to use in both case (empty list
     *                                                           and filled list) or an object with an 'empty' property
     *                                                           which represents the label of the button when the list
     *                                                           is empty and a 'filled' property which represents the
     *                                                           label of the button when there is at least one item in
     *                                                           the list.
     * @param {string}          [icon="file"]                    The icon to display when there is no item in the list.
     * @param {number}          length                           The length of the list.
     * @param {string}          [text="GLOBAL.NOTHING_SELECTED"] The text to display when there is nothing in the list.
     * @param {string}          title                            The title of the settings block.
     */

    function SettingsListDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: SettingsListController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                action: '<lsAction',
                button: '<?lsButton',
                icon: '<?lsIcon',
                length: '<lsLength',
                text: '@?lsText',
                title: '@lsTitle',
            },
            templateUrl: '/client/common/modules/settings-list/views/settings-list.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsSettingsList', SettingsListDirective);
})();
