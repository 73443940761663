import { GLOBAL } from '@lumapps/translations';

export enum SEARCH_SOURCE_TYPES {
    ALL = 'SEARCH_PLUS_ALL_TAB',
    CONTENT = GLOBAL.CONTENT,
    COMMUNITY = 'SEARCH_PLUS_COMMUNITY_TAB',
    DIRECTORY = 'SEARCH_PLUS_DIRECTORY_TAB',
    MEDIA = 'SEARCH_PLUS_MEDIA_TAB',
    POST = 'SEARCH_PLUS_POST_TAB',
    USER = 'SEARCH_PLUS_USERS_TAB',
    DRIVE = 'SEARCH_PLUS_DRIVE_TAB',
    LUMWORK = 'SEARCH_PLUS_LUMWORK_TAB',
    ZENDESK = 'SEARCH_PLUS_ZENDESK_TAB',
    SHAREPOINT = 'SEARCH_PLUS_SHAREPOINT_TAB',
}
