/* eslint-disable no-magic-numbers */
/* eslint-disable id-blacklist */
import { batch } from '@lumapps/redux/react';

import {
    fetchTopAmbassadors as fetchTopAmbassadorsTask,
    fetchTopPosts as fetchTopPostsTask,
    fetchTopTopics as fetchTopTopicsTask,
    fetchTotalComments as fetchTotalCommentsTask,
    fetchTotalLikes as fetchTotalLikesTask,
    fetchInteractionRate as fetchInteractionRateTask,
    fetchTotalShares as fetchTotalSharesTask,
    fetchNewSocialPosts as fetchNewSocialPostsTask,
    fetchActiveAmbassadors as fetchActiveAmbassadorsTask,
} from './tasks';

const DOMAIN = 'SA/SA_ANALYTICS';

const UPDATE_ANALYTICS_FILTER = `${DOMAIN}/UPDATE_ANALYTICS_FILTER`;

const UPDATE_ALL_ANALYTICS_FILTER = `${DOMAIN}/UPDATE_ALL_ANALYTICS_FILTER`;

const SINGLE_FETCH_STARTED = `${DOMAIN}/SINGLE_FETCH_STARTED`;

const FETCH_ALL_STARTED = `${DOMAIN}/FETCH_ALL_STARTED`;

const FETCH_ALL_TOPS_STARTED = `${DOMAIN}/FETCH_ALL_TOPS_STARTED`;

const ON_GLOBAL_ACTIVE_AMB_RESULTS = `${DOMAIN}/ON_GLOBAL_ACTIVE_AMB_RESULTS`;

const ON_GLOBAL_NEW_POSTS_RESULTS = `${DOMAIN}/ON_GLOBAL_NEW_POSTS_RESULTS`;

const ON_GLOBAL_TOTAL_SHARES_RESULTS = `${DOMAIN}/ON_GLOBAL_TOTAL_SHARES_RESULTS`;

const ON_INTERACTIONS_RATE_RESULT = `${DOMAIN}/ON_INTERACTIONS_RATE_RESULT`;

const ON_INTERACTIONS_TOTAL_LIKES_RESULT = `${DOMAIN}/ON_INTERACTIONS_TOTAL_LIKES_RESULT`;

const ON_INTERACTIONS_TOTAL_COMMENTS_RESULT = `${DOMAIN}/ON_INTERACTIONS_TOTAL_COMMENTS_RESULT`;

const ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT = `${DOMAIN}/ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT`;

const ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT = `${DOMAIN}/ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT`;

const ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT = `${DOMAIN}/ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT`;

const ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT = `${DOMAIN}/ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT`;

const ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT = `${DOMAIN}/ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT`;

const ON_FETCH_TOP_POSTS_BY_LIKES_RESULT = `${DOMAIN}/ON_FETCH_TOP_POSTS_BY_LIKES_RESULT`;

const ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT = `${DOMAIN}/ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT`;

const ON_FETCH_TOP_POSTS_BY_SHARES_RESULT = `${DOMAIN}/ON_FETCH_TOP_POSTS_BY_SHARES_RESULT`;

const ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT = `${DOMAIN}/ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT`;

const ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT = `${DOMAIN}/ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT`;

const ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT = `${DOMAIN}/ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT`;

const ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT = `${DOMAIN}/ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT`;

const POST_CREATED_COUNT = 'share_created_count';

const POST_INTERACTION_RATE = 'share_interaction_rate';

const POST_LIKE_COUNT = 'share_like_count';

const POST_COMMENT_COUNT = 'share_comment_count';

const SHAREABLE_CONTENT_CREATED_COUNT = 'shareable_content_created_count';

// FILTERS.

const setFilters = (values) => {
    return {
        type: UPDATE_ALL_ANALYTICS_FILTER,
        values,
    };
};

const updateFilter = (key, value) => {
    return {
        key,
        type: UPDATE_ANALYTICS_FILTER,
        value,
    };
};

// LOADERS.

const showFetchAllLoaders = () => {
    return {
        type: FETCH_ALL_STARTED,
    };
};

const showAllTopsLoaders = () => {
    return {
        type: FETCH_ALL_TOPS_STARTED,
    };
};

const startFetchingLoader = (target) => {
    return {
        target,
        type: SINGLE_FETCH_STARTED,
    };
};

// TOPS.
const fetchTopAmbassadorWithMetric = (metric) => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    switch (metric) {
        case POST_CREATED_COUNT:
            fetchTopAmbassadorsTask(currentSAFilters, POST_CREATED_COUNT)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT,
                    });
                });

            break;

        case POST_INTERACTION_RATE:
            fetchTopAmbassadorsTask(currentSAFilters, POST_INTERACTION_RATE)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT,
                    });
                });

            break;

        case POST_LIKE_COUNT:
            fetchTopAmbassadorsTask(currentSAFilters, POST_LIKE_COUNT)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT,
                    });
                });

            break;

        case POST_COMMENT_COUNT:
            fetchTopAmbassadorsTask(currentSAFilters, POST_COMMENT_COUNT)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT,
                    });
                });

            break;

        default:
            break;
    }
};

const getTopAmbassadorsWithMetric = (metric, target) => (dispatch) => {
    dispatch(startFetchingLoader(target));
    dispatch(fetchTopAmbassadorWithMetric(metric));
};

const fetchTopPostWithMetric = (metric) => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    switch (metric) {
        case POST_CREATED_COUNT:
            fetchTopPostsTask(currentSAFilters, POST_CREATED_COUNT)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_POSTS_BY_SHARES_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_POSTS_BY_SHARES_RESULT,
                    });
                });

            break;

        case POST_INTERACTION_RATE:
            fetchTopPostsTask(currentSAFilters, POST_INTERACTION_RATE)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT,
                    });
                });

            break;

        case POST_LIKE_COUNT:
            fetchTopPostsTask(currentSAFilters, POST_LIKE_COUNT)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_POSTS_BY_LIKES_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_POSTS_BY_LIKES_RESULT,
                    });
                });

            break;

        case POST_COMMENT_COUNT:
            fetchTopPostsTask(currentSAFilters, POST_COMMENT_COUNT)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT,
                    });
                });

            break;

        default:
            break;
    }
};

const getTopPostsWithMetric = (metric, target) => (dispatch) => {
    dispatch(startFetchingLoader(target));
    dispatch(fetchTopPostWithMetric(metric));
};

const fetchTopTopicsWithMetrics = (metric, customFilters, limit = 3) => (dispatch, getState) => {
    const state = getState();
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = state;

    const filters = customFilters || currentSAFilters;

    switch (metric) {
        case POST_CREATED_COUNT:
            fetchTopTopicsTask(filters, POST_CREATED_COUNT, 1, limit)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT,
                    });
                });

            break;

        case POST_INTERACTION_RATE:
            fetchTopTopicsTask(filters, POST_INTERACTION_RATE, 100, limit)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT,
                    });
                });

            break;

        case POST_LIKE_COUNT:
            fetchTopTopicsTask(filters, POST_LIKE_COUNT, 1, limit)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT,
                    });
                });

            break;

        case SHAREABLE_CONTENT_CREATED_COUNT:
            fetchTopTopicsTask(filters, SHAREABLE_CONTENT_CREATED_COUNT, 1, limit)
                .then((entries) => {
                    dispatch({
                        entries,
                        type: ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT,
                    });
                })
                .catch(() => {
                    dispatch({
                        error: true,
                        type: ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT,
                    });
                });

            break;

        default:
            break;
    }
};

const getTopTopicsWithMetric = (metric, target, customFilters, limit) => (dispatch) => {
    dispatch(startFetchingLoader(target));
    dispatch(fetchTopTopicsWithMetrics(metric, customFilters, limit));
};

const fetchAllTopTopics = () => (dispatch) => {
    const metrics = [POST_CREATED_COUNT];

    metrics.forEach((metric) => {
        dispatch(fetchTopTopicsWithMetrics(metric));
    });
};

const fetchAllTopPosts = () => (dispatch) => {
    const metrics = [POST_CREATED_COUNT];

    metrics.forEach((metric) => {
        dispatch(fetchTopPostWithMetric(metric));
    });
};

const fetchAllTopAmbassadors = () => (dispatch) => {
    const metrics = [POST_CREATED_COUNT];

    metrics.forEach((metric) => {
        dispatch(fetchTopAmbassadorWithMetric(metric));
    });
};

// KPIs.
const fetchTotalComments = () => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    fetchTotalCommentsTask(currentSAFilters)
        .then((data) => {
            dispatch({
                type: ON_INTERACTIONS_TOTAL_COMMENTS_RESULT,
                ...data,
            });
        })
        .catch(() => {
            dispatch({
                error: true,
                type: ON_INTERACTIONS_TOTAL_COMMENTS_RESULT,
            });
        });
};

const getTotalComments = () => (dispatch) => {
    dispatch(startFetchingLoader('totalComments'));
    dispatch(fetchTotalComments());
};

const fetchTotalLikes = () => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    fetchTotalLikesTask(currentSAFilters)
        .then((data) => {
            dispatch({
                type: ON_INTERACTIONS_TOTAL_LIKES_RESULT,
                ...data,
            });
        })
        .catch(() => {
            dispatch({
                error: true,
                type: ON_INTERACTIONS_TOTAL_LIKES_RESULT,
            });
        });
};

const getTotalLikes = () => (dispatch) => {
    dispatch(startFetchingLoader('totalLikes'));
    dispatch(fetchTotalLikes());
};

const fetchInteractionRate = () => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    fetchInteractionRateTask(currentSAFilters)
        .then((data) => {
            dispatch({
                type: ON_INTERACTIONS_RATE_RESULT,
                ...data,
            });
        })
        .catch(() => {
            dispatch({
                error: true,
                type: ON_INTERACTIONS_RATE_RESULT,
            });
        });
};

const getInteractionRate = () => (dispatch) => {
    dispatch(startFetchingLoader('interactionRate'));
    dispatch(fetchInteractionRate());
};

const fetchTotalShares = () => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    fetchTotalSharesTask(currentSAFilters)
        .then((data) => {
            dispatch({
                type: ON_GLOBAL_TOTAL_SHARES_RESULTS,
                ...data,
            });
        })
        .catch(() => {
            dispatch({
                error: true,
                type: ON_GLOBAL_TOTAL_SHARES_RESULTS,
            });
        });
};

const getTotalShares = () => (dispatch) => {
    dispatch(startFetchingLoader('totalShares'));
    dispatch(fetchTotalShares());
};

const fetchNewSocialPosts = () => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    fetchNewSocialPostsTask(currentSAFilters)
        .then((data) => {
            dispatch({
                type: ON_GLOBAL_NEW_POSTS_RESULTS,
                ...data,
            });
        })
        .catch(() => {
            dispatch({
                error: true,
                type: ON_GLOBAL_NEW_POSTS_RESULTS,
            });
        });
};

const getNewSocialPosts = () => (dispatch) => {
    dispatch(startFetchingLoader('newSocialPost'));
    dispatch(fetchNewSocialPosts());
};

const fetchActiveAmbassadors = () => (dispatch, getState) => {
    const {
        socialAdvocacy: {
            saAnalytics: { currentSAFilters },
        },
    } = getState();

    fetchActiveAmbassadorsTask(currentSAFilters)
        .then((data) => {
            dispatch({
                type: ON_GLOBAL_ACTIVE_AMB_RESULTS,
                ...data,
            });
        })
        .catch(() => {
            dispatch({
                error: true,
                type: ON_GLOBAL_ACTIVE_AMB_RESULTS,
            });
        });
};

const getActiveAmbassadors = () => (dispatch) => {
    dispatch(startFetchingLoader('activeAmbassadors'));
    dispatch(fetchActiveAmbassadors());
};

const fetchAll = (fetchProgramAnalytics) => (dispatch) => {
    batch(() => {
        // Request a call to the Social Advocacy API to feed all the indicators.
        dispatch(fetchTotalShares());
        // Get All top topics.
        dispatch(fetchAllTopTopics());
        // Get All top posts.
        dispatch(fetchAllTopPosts());
        // Get All Ambassadors.
        dispatch(fetchAllTopAmbassadors());
        // Fetch program analytics if needed
        if (fetchProgramAnalytics) {
            dispatch(fetchActiveAmbassadors());
            dispatch(fetchNewSocialPosts());
        }
    });
};

export const initSAAnalyticsDashboard = (programId, fetchProgramAnalytics) => (dispatch) => {
    // set the given programId as filter
    dispatch(setFilters({ programId }));
    batch(() => {
        dispatch(showFetchAllLoaders());
        dispatch(fetchAll(fetchProgramAnalytics));
    });
};

export {
    fetchAll,
    fetchAllTopTopics,
    fetchAllTopPosts,
    fetchAllTopAmbassadors,
    updateFilter,
    setFilters,
    showFetchAllLoaders,
    showAllTopsLoaders,
    startFetchingLoader,
    getTopAmbassadorsWithMetric,
    getTopPostsWithMetric,
    getTopTopicsWithMetric,
    getTotalComments,
    getTotalLikes,
    getInteractionRate,
    getTotalShares,
    getNewSocialPosts,
    getActiveAmbassadors,
    fetchTotalShares,
    ON_GLOBAL_ACTIVE_AMB_RESULTS,
    ON_GLOBAL_NEW_POSTS_RESULTS,
    ON_GLOBAL_TOTAL_SHARES_RESULTS,
    ON_INTERACTIONS_RATE_RESULT,
    ON_INTERACTIONS_TOTAL_COMMENTS_RESULT,
    ON_INTERACTIONS_TOTAL_LIKES_RESULT,
    UPDATE_ANALYTICS_FILTER,
    UPDATE_ALL_ANALYTICS_FILTER,
    FETCH_ALL_STARTED,
    ON_FETCH_TOP_TOPICS_BY_SHARE_RESULT,
    ON_FETCH_TOP_TOPICS_BY_INTERACTION_RATE_RESULT,
    ON_FETCH_TOP_TOPICS_BY_LIKE_RESULT,
    ON_FETCH_TOP_TOPICS_BY_SHAREABLE_CONTENT_RESULT,
    FETCH_ALL_TOPS_STARTED,
    ON_FETCH_TOP_POSTS_BY_COMMENTS_RESULT,
    ON_FETCH_TOP_POSTS_BY_LIKES_RESULT,
    ON_FETCH_TOP_POSTS_BY_INTERACTIONS_RESULT,
    ON_FETCH_TOP_POSTS_BY_SHARES_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_SHARES_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_INTERACTIONS_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_LIKES_RESULT,
    ON_FETCH_TOP_AMBASSADORS_BY_COMMENTS_RESULT,
    SINGLE_FETCH_STARTED,
};
