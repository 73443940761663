import { error as notifyError } from '@lumapps/notifications';
import { Dispatch } from '@lumapps/redux/types';

import * as api from '../api';
import { BOOKMARKS } from '../keys';
import { actions } from './slice';

const loadRemoteBookmarks = () => async (dispatch: Dispatch) => {
    try {
        const {
            data: { bookmarks },
        } = await api.getBookmarks();

        dispatch(actions.setBookmarks(bookmarks));
    } catch (error) {
        dispatch(notifyError({ translate: BOOKMARKS.LOAD_ERROR }));
    }
};

export { loadRemoteBookmarks };
