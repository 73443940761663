import BaseAPI from '@lumapps/base-api';

import { ConversationData, GetMessagesResponse } from '../types';

export const PATH = 'assistant';
export const assistantApi = new BaseAPI({ path: PATH, version: BaseAPI.versions.v2 });

export enum AssistantApiReponseStatus {
    success = 200,
    empty = 204,
}

export const assistantSocketApi = new BaseAPI({ path: 'websockets/assistant', version: BaseAPI.versions.v1 });

export const getFirstResourceData = async (conversationData: ConversationData) => {
    return assistantApi.post('initialize-respondent', conversationData);
};

export interface GetMessagesParams {
    chatbotId: string;
    environmentId: string;
    respondentId: string;
    cursor: string;
}
export const getMessages = async ({ chatbotId, environmentId, respondentId, cursor }: GetMessagesParams) => {
    const messages = await assistantApi.get<GetMessagesResponse>(
        `chatbots/${chatbotId}/chatbot-environments/${environmentId}/respondents/${respondentId}/messages`,
        {
            params: { cursor },
            headers: {
                'Lumapps-Assistant-Requester': 'chatbot',
            },
        },
    );
    return messages.data;
};
