import React from 'react';

import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { margin } from '@lumapps/classnames';
import { Divider, Theme } from '@lumapps/lumx/react';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { ENGINES } from '@lumapps/search/constants';
import { getSuggestionEngine } from '@lumapps/search/ducks/selectors';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';
import {
    isNGIContentFiltersGlobalSearchFFEnabled as isNGIContentFiltersGlobalSearchFFEnabledSelector,
    isNGIContentFiltersFFEnabled as isNGIContentFiltersFFEnabledSelector,
} from '@lumapps/widget-base/ducks/selectors';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';
import { WIDGET_CONTENT_LIST_TYPE } from '@lumapps/widget-content-list/constants';

import { CONTENT_FILTER_SUPPORTED_WIDGETS } from '../../constants';
import { useContentFilters } from '../../hooks/useContentFilters';
import { useGetSearchURL } from '../../hooks/useGetSearchURL';
import { LegacyContentFilterProperties } from '../../types';
import { ContentFilterFooter } from '../ContentFilterFooter';
import { ContentFilterForm } from '../ContentFilterForm';
import { ContentFilterResponsiveWrapper } from '../ContentFilterResponsiveWrapper';
import { FollowThisInterest } from '../FollowThisInterest';
import { SkeletonFilter } from '../SkeletonFilter';

interface ClientComputedContentFilterProps extends ClientComputedProps {
    properties?: LegacyContentFilterProperties;
}

export const SCOPE = 'content-filter';
const LOADING_SKELETON_QUANTITY = 5;

/**
 * The Content Filter widget. Used to filter a list widget flagged as main widget.
 * Will be displayed only with ngi-content-filter FF.
 */
export const ClientComputedContentFilter: React.FC<ClientComputedContentFilterProps> = ({ properties, uuid }) => {
    const dispatch = useDispatch();

    /**
     * Pixel perfect - responsive display
     * */
    const { isMobile } = useResponsive();
    const isMobileAppWebview = useDetectMobileAppWebView();
    const [isOpen, , onClose, onOpen] = useBooleanState(false);

    /**
     * Feature Flags
     */

    /** Advanced search link is only displayed for native search */
    const isNativeSearch = useSelector(getSuggestionEngine) === ENGINES.LUMAPPS;
    /** Content filter feature flag */
    const isNGIContentFiltersFFEnabled = useSelector(isNGIContentFiltersFFEnabledSelector);
    /** Global search feature flag */
    const isNGIContentFiltersGlobalSearchFFEnabled = useSelector(isNGIContentFiltersGlobalSearchFFEnabledSelector);

    /**
     * Props
     */

    const { filters, hideSubheader = false } = properties || {};
    const theme = properties?.style?.content?.theme || Theme.light;

    /**
     * Filter logic
     */

    const {
        availableFilters,
        filtersCurrentValues,
        mainWidgetType,
        filtersFromWidgetProperties,
        handleFilterChange,
        handleApply,
        handleClear,
    } = useContentFilters(uuid, { rawFilters: filters });

    /** Generate global search URL from displayed filters (not necessarily active) and prefilters */
    const advancedSearchURL = useGetSearchURL({ ...filtersFromWidgetProperties, ...filtersCurrentValues });

    /**
     * Features availability
     */

    /** Check if main widget is supported by the content filter */
    const isMainWidgetSupported = mainWidgetType && CONTENT_FILTER_SUPPORTED_WIDGETS.includes(mainWidgetType);
    /** Check if the follow button should be displayed */
    const enableFollowThisInterest = !properties?.disableFollowButton && mainWidgetType === WIDGET_CONTENT_LIST_TYPE;
    /** Check if the global search should be displayed */
    const enableGlobalSearchLink =
        isNativeSearch && isNGIContentFiltersGlobalSearchFFEnabled && mainWidgetType === WIDGET_CONTENT_LIST_TYPE;

    /**
     * Hide widget if not applicable
     */

    React.useEffect(() => {
        if (
            !isMainWidgetSupported ||
            (isEmpty(availableFilters) && !enableFollowThisInterest) ||
            !isNGIContentFiltersFFEnabled
        ) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [
        availableFilters,
        dispatch,
        enableFollowThisInterest,
        isMainWidgetSupported,
        isNGIContentFiltersFFEnabled,
        uuid,
    ]);

    const Form = !isEmpty(availableFilters) ? (
        <ContentFilterForm
            mainWidgetType={mainWidgetType}
            theme={!isMobile ? theme : Theme.light}
            scope={SCOPE}
            advancedSearchURL={
                !isMobileAppWebview && enableGlobalSearchLink && advancedSearchURL ? advancedSearchURL : undefined
            }
            availableFilters={availableFilters}
            filtersCurrentValues={filtersCurrentValues}
            filtersFromWidgetProperties={filtersFromWidgetProperties}
            handleFilterChange={handleFilterChange}
            handleApply={flow(handleApply, onClose)}
            hideSubheader={hideSubheader}
        />
    ) : undefined;

    const Footer = !isEmpty(availableFilters) ? (
        <ContentFilterFooter
            theme={!isMobile ? theme : Theme.light}
            scope={SCOPE}
            handleClear={flow(handleClear, onClose)}
        />
    ) : undefined;

    const Follow = enableFollowThisInterest ? (
        <>
            {!isEmpty(availableFilters) && <Divider theme={theme} className={margin('vertical', 'huge')} />}
            <FollowThisInterest
                filtersValues={{ ...filtersFromWidgetProperties, ...filtersCurrentValues }}
                theme={theme}
                scope={`${SCOPE}-follow-interest`}
            />
        </>
    ) : undefined;

    if (isNil(filtersFromWidgetProperties)) {
        return <SkeletonFilter quantity={LOADING_SKELETON_QUANTITY} />;
    }

    /**
     * Display follow the interest alone if there is no available filters
     * */
    if (isEmpty(availableFilters) && Follow) {
        return Follow;
    }

    /**
     * When in a mobile use case, we display the filters within a responsive wrapper
     * */
    if (isMobile) {
        return (
            <>
                <ContentFilterResponsiveWrapper
                    filtersCurrentValues={filtersCurrentValues}
                    siteReferences={filtersFromWidgetProperties?.site}
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    theme={theme}
                    scope={SCOPE}
                    footer={Footer}
                >
                    {Form}
                </ContentFilterResponsiveWrapper>
                {Follow}
            </>
        );
    }

    return (
        <>
            {Form}
            {Footer}
            {Follow}
        </>
    );
};
