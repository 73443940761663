import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    Alignment,
    FlexBox,
    Orientation,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    SkeletonTypography,
    Typography,
} from '@lumapps/lumx/react';
import { BlockVideo, BlockVideoVisibleElement } from '@lumapps/play-video';

import './index.scss';

const CLASSNAME = 'playlist-skeleton';

interface PlaylistSkeletonProps {
    /** Custom class name */
    className?: string;
    /** The elements to display in each video block */
    videoVisibleElements?: BlockVideoVisibleElement[];
}

export const PlaylistSkeleton = ({ className, videoVisibleElements }: PlaylistSkeletonProps) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <FlexBox orientation={Orientation.vertical} className={block([className])} gap={Size.huge}>
            <FlexBox orientation={Orientation.horizontal} vAlign={Alignment.spaceBetween}>
                <SkeletonTypography typography={Typography.display1} width="35%" />

                <SkeletonRectangle
                    height={Size.m}
                    typography={Typography.title}
                    variant={SkeletonRectangleVariant.rounded}
                    width={Size.xl}
                />
            </FlexBox>

            <FlexBox orientation={Orientation.horizontal} className={element('videos')} gap={Size.huge}>
                {[1, 2, 3, 4].map((item) => (
                    <BlockVideo
                        key={item}
                        isLoading
                        orientation={Orientation.vertical}
                        visibleElements={videoVisibleElements}
                    >
                        <BlockVideo.Figure />

                        <BlockVideo.Content gap={Size.tiny}>
                            <BlockVideo.Title typography={Typography.subtitle1} />

                            <BlockVideo.Description />

                            <BlockVideo.Metadata>
                                <BlockVideo.Author />

                                <BlockVideo.Date />

                                <BlockVideo.Views />
                            </BlockVideo.Metadata>
                        </BlockVideo.Content>
                    </BlockVideo>
                ))}
            </FlexBox>
        </FlexBox>
    );
};
