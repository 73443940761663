import get from 'lodash/get';
import includes from 'lodash/includes';

function WidgetListController($injector, $q, $scope, ContentTemplate, Content, Layout, Translation, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Contains the previously used additional filters.
     *
     * @type {Object}
     */
    let _additionalFilter = {};

    /**
     * Indicates if there are more items available for the list.
     *
     * @type {boolean}
     */
    let _hasMore = false;

    /**
     * Indicates if a call is in progress.
     * When a call is in progress, the widget is never considered as empty nor is hidden.
     *
     * @type {boolean}
     */
    let _isCallInProgress = true;

    /**
     * The original list key set at the initialization of the list widget.
     *
     * @type {string}
     */
    let _originalListKey;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The types of list that can be handled by this directive.
     *
     * @type {Object}
     */
    vm.LIST_TYPES = {
        community: 'community-list',
        content: 'content-list',
        directory: 'directory-entry-list',
        file: 'file-list',
        instance: 'instance-list',
        post: 'post-list',
        sharepointSite: 'sharepoint-site-list',
        user: 'user-list',
    };

    /**
     * Enum of list item selection types.
     *
     * @type {Object}
     */
    vm.SELECTION_TYPES = {
        communityFolder: 'community-folder',
        list: 'list',
        pick: 'pick',
        select: 'select',
    };

    /**
     * The services associated with each type of list. Used to determine vm.service.
     *
     * @type {Object}
     */
    vm.SERVICES = {
        'community-list': 'Community',
        'content-list': 'Content',
        'directory-entry-list': 'DirectoryEntry',
        'file-list': 'Document',
        'instance-list': 'Instance',
        'media-list': 'Media',
        'post-list': 'Post',
        'sharepoint-site-list': 'SharepointSite',
        'user-list': 'User',
    };

    /**
     * The full list of CSS classes of the widget.
     *
     * @type {Array}
     */
    vm.classes = [];

    /**
     * Indicates if the widget has been initialized.
     *
     * @type {boolean}
     */
    vm.initialized = false;

    /**
     * Indicates if the infinite scroll is enabled.
     * For the infinite scroll to be enabled, the controller must have been initialized, the widget must be a main
     * widget and there must be more content available (and thus, no call beeing in progress).
     *
     * @type {boolean}
     */
    vm.isInfiniteScrollEnabled = false;

    /**
     * Indicates if the widget is empty.
     * The widget is never considered empty if it's a main widget, if the tabs (or the tabs selector) should be
     * displayed or if a call is in progress.
     * Else, there must be at least one element to display to make the widget not empty.
     *
     * @type {boolean}
     */
    vm.isWidgetEmpty = false;

    /**
     * Indicates if the widget must be hidden.
     * A widget is hidden only when it's empty and we are not in the designer.
     *
     * @type {boolean}
     */
    vm.isWidgetHidden = false;

    /**
     * Contains the list of items to be displayed in the widget list.
     *
     * @type {Array}
     */
    vm.items = [];

    /**
     * Contains the projected fields for the list request.
     *
     * @type {Object}
     */
    vm.projection = {};

    /**
     * Indicates if we should display the tabs selector (for small screen).
     *
     * @type {boolean}
     */
    vm.shouldDisplayTabsSelector = false;

    /**
     * Indicates if we should display the tabs (for large screen).
     *
     * @type {boolean}
     */
    vm.shouldDisplayTabs = false;

    /**
     * Contains the translation to the 'all' tab and a list of the currently selected tab items (can be communities
     * or tags).
     *
     * @type {Object}
     */
    vm.tabs = {
        all: Translation.translate('ALL'),
        items: [],
    };

    /**
     * The default tab object for the All tab.
     *
     * @type {Object}
     */
    vm.tabDefault = {
        name: vm.tabs.all,
        uid: 'all',
    };

    /**
     * An object holding the identifier and name of the tab to select when displaying tabs.
     * Can be either 'all' or the uid and name of a tag or community (for post list).
     *
     * @type {Object}
     */
    vm.tabSelected = {
        name: vm.tabs.all,
        uid: 'all',
    };

    /**
     * The controller of the child widget list (e.g. the user list directive controller).
     *
     * @type {Object}
     */
    vm.widgetListChildCtrl = {};

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the infinite scroll is enabled.
     *
     * @return {boolean} If infinite scroll is enabled.
     */
    function _isInfiniteScrollEnabled() {
        // Hide the load more button if displayed as NGI, the button will be rendered by NGI.
        const isDisplayedAsNGI = executeChildFunction('shouldDisplayAsNGI') || false;

        vm.isInfiniteScrollEnabled = vm.initialized && vm.widget.isMainWidget && !_isCallInProgress && _hasMore && !isDisplayedAsNGI;

        return vm.isInfiniteScrollEnabled;
    }

    /**
     * Check if the widget must be hidden.
     * To be hidden, the widget must be empty.
     * It's never hidden in designer mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function _isWidgetHidden() {
        vm.widgetCtrl.isHidden =
            vm.initialized && !vm.widgetCtrl.designerMode() && !vm.widget.properties.noResults && vm.isWidgetEmpty;

        return vm.widgetCtrl.isHidden;
    }

    /**
     * Check if the widget is empty.
     * To be empty, the widget should:
     *     - not be the main widget;
     *     - not have tabs enabled (displayed as tabs or tabs selector);
     *     - have no items listed (and the call must have ended, so, not in progress).
     *
     * @return {boolean} If the widget is empty or not.
     */
    function _isWidgetEmpty() {
        const isWidgetEmptyChild = vm.executeChildFunction('isWidgetEmpty');

        vm.isWidgetEmpty =
            vm.initialized &&
            // ContributionField should always be visible, even when not a main widget, and there's no posts
            ((!vm.widget.isMainWidget && !vm.widget.properties.isContributionFieldDisplayed) ||
                vm.widgetCtrl.designerMode()) &&
            !vm.shouldDisplayTabs &&
            !vm.shouldDisplayTabsSelector &&
            !_isCallInProgress &&
            angular.isUndefinedOrEmpty(vm.items) &&
            (angular.isDefined(isWidgetEmptyChild) ? isWidgetEmptyChild : true);

        if (!vm.widgetCtrl.designerMode()) {
            _isWidgetHidden();
        }

        return vm.isWidgetEmpty;
    }

    /**
     * Check if tabs should be displayed in the widget.
     *
     * @return {boolean} If tabs should be displayed (as tabs or tabs selected).
     */
    function _shouldDisplayTabsOrSelector() {
        // No tabs for those types of widget (yet).
        if (
            includes(
                [vm.LIST_TYPES.community, vm.LIST_TYPES.file, vm.LIST_TYPES.media, vm.LIST_TYPES.user],
                vm.backwardCompatibleType,
            )
        ) {
            return false;
        }

        const { properties } = vm.widget;
        const shouldDisplayTabs = properties.tabsEnabled && (vm.executeChildFunction('shouldDisplayListTabs') || false);
        const breakpoint = Layout.breakpoint || 'desk';

        vm.shouldDisplayTabs = shouldDisplayTabs && breakpoint === 'desk';
        vm.shouldDisplayTabsSelector = shouldDisplayTabs && breakpoint !== 'desk';

        if (shouldDisplayTabs) {
            vm.isWidgetEmpty = false;
        } else {
            _isWidgetEmpty();
        }

        vm.computeClasses();

        return shouldDisplayTabs;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Compute the widget's CSS classes.
     *
     * @return {Array} The widget's CSS classes.
     */
    function computeClasses() {
        const { properties } = vm.widget;

        Utils.empty(vm.classes);

        vm.classes.push(`widget-${vm.backwardCompatibleType}`);

        vm.widgetCtrl.getWidgetClass(vm.classes);

        // Todo [Arnaud]: refactor these property names when we work on the widget settings (select === pick).
        if (
            includes(
                [vm.SELECTION_TYPES.pick, vm.SELECTION_TYPES.select],
                properties.typeDisplay || properties.displayType || properties.type,
            ) ||
            Content.getViewMode() !== 'basic' ||
            Content.getAction() === 'get'
        ) {
            vm.classes.push('widget-editable');
        }

        if (vm.isWidgetEmpty) {
            vm.classes.push('widget--is-empty');
        }

        if (angular.isDefinedAndFilled(properties.viewMode)) {
            vm.classes.push(`widget-${vm.backwardCompatibleType}--view-mode-${properties.viewMode}`);
        }

        if (angular.isDefinedAndFilled(properties.viewModeVariant)) {
            vm.classes.push(`widget-${vm.backwardCompatibleType}--view-mode-variant-${properties.viewModeVariant}`);
        }

        vm.classes = vm.classes.concat(vm.executeChildFunction('computeClasses') || []);

        return vm.classes;
    }

    /**
     * Execute a function in the child widget list (e.g. in widget-user-list).
     *
     * @param  {string} functionName The name of the function within the child widget to execute.
     * @param  {Array}  [params]     An array of parameters to pass to the function to be executed.
     * @return {*}      The result of the child function execution.
     */
    function executeChildFunction(functionName, params) {
        const childFunction = vm.widgetListChildCtrl[functionName];

        if (!angular.isFunction(childFunction)) {
            return undefined;
        }

        return childFunction.apply(vm, params);
    }

    /**
     * Get the original list key (which is set on init).
     *
     * @return {string} The list key.
     */
    function getOriginalListKey() {
        return _originalListKey;
    }

    /**
     * Handle the triggering of the infinite scroll.
     *
     * @param {boolean} fromButton Indicates if the infinite scroll call come in fact from the 'Load next page'
     *                             button generated by the directive.
     */
    function handleInfiniteScroll(fromButton) {
        if (angular.isUndefined(vm.widgetCtrl)) {
            return;
        }

        if ((fromButton || vm.widget.isMainWidget) && _hasMore) {
            vm.service.nextPage(vm.updateListItems, vm.updateListItems, vm.getListKey());
        }
    }

    /**
     * List items.
     *
     * @param {Object}  [additionalFilters]       The additional filters provided by the widget content filter.
     * @param {boolean} [resetSelectedTabs=false] Indicates if we want to reset the selected tab.
     * @param {boolean} [fromInit=false]          Indicates if we are initializing the list from the init of the
     *                                            widget.
     */
    function initList(additionalFilters, resetSelectedTabs, fromInit) {
        additionalFilters = additionalFilters || {};
        resetSelectedTabs = resetSelectedTabs || false;

        vm.items = [];

        vm.projection = vm.executeChildFunction('getProjection') || {};
        vm.executeChildFunction('initList', [additionalFilters, resetSelectedTabs, fromInit]);
    }

    /**
     * Do something whenever the list of items is clicked.
     */
    function onWidgetListClick() {
        if (_isCallInProgress) {
            return;
        }

        vm.executeChildFunction('onWidgetListClick');
    }

    /**
     * Select a specific tab and mark it as active when items are grouped by tab.
     *
     * @param {string}  [tab]                 The tab object we want to select.
     * @param {boolean} [initializeList=true] Indicates if we want to initialize the list after selecting the tab.
     */
    function selectTab(tab, initializeList) {
        $scope.$broadcast('reset-slideshow-index');

        tab = tab || vm.tabDefault;
        initializeList = angular.isUndefined(initializeList) ? true : initializeList;

        const originalKey = vm.getOriginalListKey();

        if (angular.isUndefinedOrEmpty(tab)) {
            vm.setListKey(originalKey);
        } else {
            vm.setListKey(`${originalKey}-${tab.uid}`);
        }

        vm.tabSelected = {
            name: tab.name,
            uid: tab.uid,
        };

        if (initializeList) {
            initList(_additionalFilter);
        }

        vm.executeChildFunction('selectTab', [tab, initializeList]);
    }

    /**
     * Set the service to be used for all the list calls.
     * We're exposing it so it can be changed on the fly in the widget-file-list for example where we need to switch
     * between two services in the same widget.
     * This is also used for the infinite scroll, load next page behaviour for example.
     *
     * @param {string} serviceName The name of the service to set.
     */
    function setService(serviceName) {
        vm.service = $injector.get(serviceName);
    }

    /**
     * Flag the widget has having been initialized and set the list of items.
     */
    function updateListItems() {
        vm.initialized = true;

        const listKey = vm.getListKey();

        vm.items = vm.service.displayList(listKey, vm.projection);

        _hasMore = vm.service.hasMore(listKey);

        _isWidgetEmpty();

        vm.computeClasses();

        _isInfiniteScrollEnabled();

        vm.executeChildFunction('onListItemsUpdated');
    }

    /////////////////////////////

    vm.computeClasses = computeClasses;
    vm.executeChildFunction = executeChildFunction;
    vm.getOriginalListKey = getOriginalListKey;
    vm.handleInfiniteScroll = handleInfiniteScroll;
    vm.initList = initList;
    vm.onWidgetListClick = onWidgetListClick;
    vm.selectTab = selectTab;
    vm.setService = setService;
    vm.updateListItems = updateListItems;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the breakpoint of the layout goes to or from 'desk', toggle tabs and tabs selector.
     *
     * @param {Event}  evt           The layout breakpoint change event.
     * @param {string} newBreakpoint The new computed breakpoint.
     * @param {string} oldBreakpoint The previous breakpoint.
     */
    $scope.$on('layout-breakpoint', (evt, newBreakpoint, oldBreakpoint) => {
        if (newBreakpoint === 'desk' || angular.isUndefinedOrEmpty(oldBreakpoint) || oldBreakpoint === 'desk') {
            _shouldDisplayTabsOrSelector();
        }
    });

    /**
     * When the content filters triggers a search, initialize the list with the wanted filters.
     *
     * @param {Event}  evt               The search event.
     * @param {string} contentFilterUuid The identifier of the content filter widget.
     */
    $scope.$on('widget-content-filter-search', (evt, contentFilterUuid) => {
        if (!vm.widget.isMainWidget) {
            return;
        }

        const widgetContentFilter = ContentTemplate.getElement(
            Content.getCurrent(),
            'widget',
            'uuid',
            contentFilterUuid,
        );

        if (angular.isDefinedAndFilled(widgetContentFilter)) {
            _additionalFilter = angular.fastCopy(widgetContentFilter.properties);

            initList(_additionalFilter);
        }
    });

    /**
     * When the widget is toggled as main widget.
     * If the widget is set as main widget, then it will never be empty.
     *
     * @param {Event}  evt        The 'toggle as main' event.
     * @param {string} widgetUuid The identifier of the widget that has been toggled as main.
     */
    $scope.$on('widget-is-main-toggled', (evt, widgetUuid, isMainWidget) => {
        if (vm.widget.uuid !== widgetUuid) {
            return;
        }

        if (isMainWidget) {
            vm.isWidgetEmpty = false;
        } else {
            _isWidgetEmpty();
        }

        vm.computeClasses();

        _isInfiniteScrollEnabled();
    });

    /**
     * When widget size are updated, re-compute classes.
     */
    $scope.$on('widget-size-update-in-row', () => {
        const previousWidgetSize = vm.widgetCtrl.widgetSize;

        vm.widgetCtrl.setWidgetSize();

        vm.executeChildFunction('widgetSizeUpdate', [previousWidgetSize]);

        // Only update the content blocks if the widget size has actually changed.
        if (previousWidgetSize !== vm.widgetCtrl.widgetSize) {
            vm.computeClasses();
        }
    });

    /**
     * When the style of the user list widget changes, re-compute the classes.
     *
     * @param {Event}  evt        The original event triggering this method.
     * @param {string} widgetUuid The identifier of the widget targeted by the event.
     */
    $scope.$on('widget-style-settings', (evt, widgetUuid) => {
        if (vm.widget.uuid !== widgetUuid) {
            return;
        }

        vm.computeClasses();
    });

    /**
     * Reinitialize the list of items when the item list widget settings change.
     *
     * @param {Event}  evt        The original event triggering this function.
     * @param {string} widgetUuid The uuid of the widget that just got changed.
     */
    $scope.$on(`widget-${vm.backwardCompatibleType}-settings`, (evt, widgetUuid) => {
        if (vm.widget.uuid !== widgetUuid) {
            return;
        }

        vm.init(false);
    });

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any call in progress for the given list key.
     * When a call is in progress, the widget should never be considered as empty nor hidden.
     */
    $scope.$watch(
        () => angular.isDefined(vm.service) && vm.service.isCallInProgress(vm.getListKey()),
        (isCallInProgress) => {
            _isCallInProgress = isCallInProgress;

            if (isCallInProgress) {
                vm.isWidgetEmpty = false;
            } else {
                _isWidgetEmpty();
            }

            vm.computeClasses();

            _isInfiniteScrollEnabled();
        },
        true,
    );

    /////////////////////////////

    /**
     * Initialize the controller.
     *
     * @param {boolean} saveListKey Indicates if we want to save the list key as the original one.
     */
    vm.init = (saveListKey) => {
        const widgetService = vm.SERVICES[vm.backwardCompatibleType];

        vm.initialized = false;
        vm.widgetListChildCtrl = vm.widgetListChildCtrl || {};
        vm.widget.isMainWidget = vm.widget.isMainWidget || false;

        vm.setService(angular.isFunction(widgetService) ? widgetService() : widgetService);

        saveListKey = angular.isUndefined(saveListKey) ? true : saveListKey;

        if (saveListKey) {
            _originalListKey = vm.getListKey();
        }

        vm.widgetCtrl.setWidgetSize();

        // On every change, we reset the widget as NGI compatible (to load the NGI version again and
        // check if the new setup is compatible with NGI or not).
        // NB: This function won't be executed if the setAsNgiCompatible function is not defined for the child (ie. the widget type directive).
        if(Content.getCurrent().template.isV2Compatible !== false) {
            vm.executeChildFunction('setAsNgiCompatible');
        }

        vm.executeChildFunction('initProperties');

        vm.executeChildFunction('handleBackwardCompatibility');

        _shouldDisplayTabsOrSelector();

        vm.computeClasses();

        _isInfiniteScrollEnabled();

        if (get(vm.widget, 'properties.tabsEnabled', false)) {
            // Wait for the promise to resolve, if the function doesn't return a promise, wrap it in one.
            $q.when(vm.executeChildFunction('computeTabsNames')).then(() => {
                initList(undefined, true, true);
            });
        } else {
            initList(undefined, true, true);
        }
    };

    /**
     * Set parent controller.
     *
     * @param {Object} widgetCtrl The controller object of the widget directive (which is the parent of this one).
     */
    this.setParentController = (widgetCtrl) => {
        vm.widgetCtrl = widgetCtrl;

        // Expose some methods from the widget directive.
        vm.getListKey = vm.widgetCtrl.getListKey;
        vm.setListKey = vm.widgetCtrl.setListKey;
    };
}

/////////////////////////////

// eslint-disable-next-line require-jsdoc
function WidgetListDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc-except/require-jsdoc
    function WidgetListLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetListController,
        controllerAs: 'vm',
        link: WidgetListLink,
        replace: true,
        require: ['widgetList', '^widget'],
        restrict: 'E',
        scope: {
            backwardCompatibleType: '@',
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-list/views/widget-list.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetList', WidgetListDirective);

export { WidgetListDirective as WidgetList };
