import { createSelector } from '@lumapps/redux/reselect';

import get from 'lodash/get';

const saAnalyticsSelector = (state) => state.socialAdvocacy.saAnalytics;

const topTopicsSelector = createSelector(saAnalyticsSelector, (saAnalytics) => saAnalytics.topTopics);

const getTopTopicsByShareable = createSelector(topTopicsSelector, (topTopics) =>
    get(topTopics, 'byShareable.entries', []),
);

export { getTopTopicsByShareable };
