/* istanbul ignore file */
import entries from 'lodash/entries';
import Plurals from 'make-plural';

import { ContextTranslationAPI, PluralTypes, PluralObject } from '../types';
import {
    getTranslationLanguage,
    isTranslateKey,
    isTranslateObject,
    isTranslateAndReplace,
    isAvailableInSitesLanguages,
    getFirstAlternativeLanguage,
    getDefaultLanguage,
    translateToMakePluralFormat,
} from '../utils';

const api: ContextTranslationAPI = {
    pluralize: (context) => (key: string, count: number) => {
        const { preferredLanguage } = context;
        const lang = getTranslationLanguage(preferredLanguage);
        const translations = api.translateKey(context)(key) || '';

        try {
            const translationsObj = JSON.parse(translations);
            const range = Plurals[translateToMakePluralFormat(lang)](count);

            return translationsObj[range] || translationsObj.other;
        } catch (error) {
            // TODO: do somethin?
        }

        return '';
    },
    pluralizeObject: (context) => (translationsObj: PluralObject, count: number) => {
        const { preferredLanguage } = context;
        const lang = getTranslationLanguage(preferredLanguage);
        const range = Plurals[lang](count, true) as PluralTypes;

        return translationsObj[range] || translationsObj.other || '';
    },
    translateKey: (context) => (key, overrideLanguage) => {
        const { preferredLanguage, translations } = context;
        const language = getTranslationLanguage(overrideLanguage || preferredLanguage);
        const defaultLanguage = getDefaultLanguage();

        return translations?.[language]?.[key] ?? translations?.[defaultLanguage]?.[key] ?? key;
    },
    translateObject: (context) => (obj, overrideLanguage, ignoreFallbackLanguageWhenTranslating) => {
        if (!obj) {
            return null;
        }

        const { preferredLanguage, instanceLanguages, instanceDefaultLanguage } = context;
        const language = getTranslationLanguage(preferredLanguage);
        const alternativeLanguage = getFirstAlternativeLanguage();
        const defaultLanguage = getDefaultLanguage();

        // Try to translate with the override language if defined and available in the site's languages
        if (
            overrideLanguage &&
            obj[overrideLanguage] &&
            isAvailableInSitesLanguages(overrideLanguage, instanceLanguages)
        ) {
            return obj[overrideLanguage];
        }
        // Then, try to translate with the user's preferred language if defined in the site's languages
        if (obj[language] && isAvailableInSitesLanguages(language, instanceLanguages)) {
            return obj[language];
        }
        // If none of those are working and we want to ignorefallback, return a null value
        if (ignoreFallbackLanguageWhenTranslating) {
            return null;
        }
        // Try the user's alternative language as a fallback
        if (obj[alternativeLanguage] && isAvailableInSitesLanguages(alternativeLanguage, instanceLanguages)) {
            return obj[alternativeLanguage];
        }
        // No user's preferred language are matching, try the site's default language
        if (instanceDefaultLanguage && obj[instanceDefaultLanguage]) {
            return obj[instanceDefaultLanguage];
        }

        if (instanceLanguages) {
            // There is still no translation possible, the next translation possible would be
            // the first site's language that could match a locale in the object
            for (const instanceLang of instanceLanguages) {
                if (obj[instanceLang]) {
                    return obj[instanceLang];
                }
            }
        }
        // Still no results, try the default config language
        if (obj[defaultLanguage]) {
            return obj[defaultLanguage];
        }
        // Extreme last resort, if no translation has been found, get the first value available
        return Object.values(obj).find((value) => {
            return value !== '';
        });
    },

    translateAndReplace:
        (context) => (key: string, replacements: Record<string, string | number>, overrideLanguage?: string) => {
            const translatedKey = api.translateKey(context)(key, overrideLanguage);

            return entries(replacements).reduce(
                (acc, [k, v]) => acc.replace(new RegExp(`%${k.toUpperCase()}%`, 'g'), `${v}`),
                translatedKey,
            );
        },

    translateAndReplaceList:
        (context) => (key: string, keys: string[], replacements: string[], overrideLanguage?: string) => {
            let translatedKey = api.translateKey(context)(key, overrideLanguage);

            keys.forEach((k, index) => {
                const toReplace = replacements[index];

                translatedKey = translatedKey.replace(new RegExp(`%${k.toUpperCase()}%`, 'g'), `${toReplace}`);
            });

            return translatedKey;
        },

    translateAndReplaceWithComponents:
        (context) =>
        (key: string, replacements: Record<string, string | number | JSX.Element>, overrideLanguage?: string) => {
            // Split components replacements from string/number ones to simplify
            const filteredReplacements = entries(replacements).reduce(
                (acc, [k, v], index) => {
                    if (typeof v === 'string' || typeof v === 'number') {
                        return {
                            ...acc,
                            otherReplacements: {
                                ...acc.otherReplacements,
                                [k]: v,
                            },
                        };
                    }

                    return {
                        ...acc,
                        componentReplacements: {
                            ...acc.componentReplacements,
                            /**
                             * We need to provide components with keys so that React
                             * doesn't display the warning message "Each child in a list should have a unique "key" prop"
                             * when we make multiple replacements in a key.
                             *
                             * ℹ️ Here, we let developers override components keys if they need to.
                             * ℹ️ Here we are passing an index as a key. This is fine because, words will never be reordered afterwards.
                             */
                            [k]: { ...v, key: v.key ?? String(index) },
                        },
                    };
                },
                {
                    otherReplacements: {} as Record<string, string | number>,
                    componentReplacements: {} as Record<string, JSX.Element>,
                },
            );

            const translatedKey = api.translateAndReplace(context)(
                key,
                filteredReplacements.otherReplacements,
                overrideLanguage,
            );

            return entries(filteredReplacements.componentReplacements).reduce(
                (acc: (string | JSX.Element)[], [k, v]) => {
                    const keyRegexp = new RegExp(`(%${k.toUpperCase()}%)`, 'g');
                    const indexContainingKey = acc.findIndex((part) => {
                        return typeof part === 'string' && part.match(keyRegexp);
                    });

                    if (indexContainingKey !== -1) {
                        const splittedOnKey = (acc[indexContainingKey] as string).split(keyRegexp);

                        const withReplacement = splittedOnKey.reduce(
                            (replacement, cur) => (keyRegexp.test(cur) ? [...replacement, v] : [...replacement, cur]),
                            [] as (string | JSX.Element)[],
                        );

                        const newAcc = acc;
                        newAcc.splice(indexContainingKey, 1, ...withReplacement);
                        return newAcc;
                    }
                    return acc;
                },
                [translatedKey],
            );
        },

    translate: (context) => (toTranslate: any, overrideLanguage?: string) => {
        if (isTranslateKey(toTranslate)) {
            return api.translateKey(context)(toTranslate, overrideLanguage);
        }

        if (isTranslateObject(toTranslate)) {
            return api.translateObject(context)(toTranslate, overrideLanguage);
        }

        if (isTranslateAndReplace(toTranslate)) {
            return api.translateAndReplace(context)(toTranslate.key, toTranslate.replacements, overrideLanguage);
        }

        return null;
    },
};

export { api };
