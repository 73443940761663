//! PLEASE keep this list in alphabetical order for a better readability
export enum DISTRIBUTE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=475333891
     */
    DISTRIBUTE = 'BROADCASTS.DISTRIBUTE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510233070
     */
    DISTRIBUTE_CONTENT = 'ADMIN.FEATURES.BROADCAST_DISTRIBUTE_CONTENT.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=496630687
     */
    DISTRIBUTED = 'CONTENTS.DISTRIBUTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=496630703
     */
    DISTRIBUTION_SCHEDULED = 'CONTENTS.DISTRIBUTION_SCHEDULED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493733904
     */
    NOTIFICATION_DISTRIBUTE_CONTENT_DESCRIPTION = 'CONTENTS.CONTENT_PUBLISHED_NOTIFICATION_DESCRIPTION',
}
