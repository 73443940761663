import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';
import set from 'lodash/set';
import without from 'lodash/without';

/////////////////////////////

function WidgetHtmlController(
    $element,
    $injector,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $window,
    Content,
    Features,
    InitialSettings,
    LxNotificationService,
    Translation,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay to show the edition filter.
     * In milliseconds.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _FILTER_SHOW_DELAY = 100;

    /**
     * The class selector of the html wrapper.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _HTML_PARENT_SELECTOR = '.widget-html__content';

    /**
     * A DOM element that darkens the rest of the page when the HTML widget is in edit mode.
     *
     * @type {Element}
     */
    const _appFilter = angular.element('<div/>', {
        class: 'app-filter app-filter--no-transition',
    });

    /**
     * Prevents the multiple event listeners on the same element due to lazy digest cycle.
     *
     * @type {Boolean}
     */
    let _isListenerOnLinks = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The content types of this instance.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    vm.CONTENT_TYPES = InitialSettings.CONTENT_TYPES;

    /**
     * Contains the options of the Froala editor for the HTML widget in edit mode.
     * The toolbar is actually slightly different here with video or popin buttons for example.
     *
     * @type {Object}
     * @constant
     */
    vm.FROALA_OPTIONS = {};

    /**
     * Contains the options of the Froala editor in popin edition mode.
     *
     * @type {Object}
     * @constant
     */
    vm.FROALA_POPIN_OPTIONS = {};

    /**
     * Indicates if we are currently editing the content of the HTML widget.
     *
     * @type {boolean}
     */
    vm.editingContent = false;

    /**
     * Indicates if the widget is empty.
     * The widget is never considered empty if a call is in progress.
     * Else, there must be at least one element to display to make the widget not empty.
     *
     * @type {boolean}
     */
    vm.isWidgetEmpty = false;

    /**
     * Indicates if the widget must be hidden.
     * A widget is hidden only when it's empty and we are not in the designer.
     *
     * @type {boolean}
     */
    vm.isWidgetHidden = false;

    /**
     * Whether the widget should be displayed in NGI or not.
     */
    vm.isNGICompatible = Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2');

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.href = $state.href;
    vm.Translation = Translation;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Caught error during image drag and drop.
     *
     * @param  {string}  message The message.
     * @return {boolean} If we want to propagate the error.
     */
    function _onDragAndDropError(message) {
        if (!angular.isString(message) || message.indexOf('HierarchyRequestError') === -1) {
            return false;
        }
        $timeout(function delayUndoAndNotification() {
            // eslint-disable-next-line angular/angularelement
            $('.froala').froalaEditor('undo.run');
            LxNotificationService.error(Translation.translate('CANT_DROP_HERE'));
        });

        return true;
    }

    /**
     * Add an event listener in edit mode to make sure we are ready to catch drag and drop errors.
     */
    function _attachErrorEventOnWindow() {
        $window.addEventListener('error', _onDragAndDropError);
    }

    /**
     * Removes the attached error event listener when exiting edit mode.
     */
    function _detachErrorEventOnWindow() {
        $window.removeEventListener('error', _onDragAndDropError);
    }

    /**
     * Define the options of the Froala editor in popin edition mode.
     */
    function _initPopinOptions() {
        vm.FROALA_POPIN_OPTIONS = angular.extend(angular.fastCopy(vm.FROALA_OPTIONS), {
            isPopin: true,
            toolbarButtons: without(vm.FROALA_OPTIONS.toolbarButtons, 'insertPopin'),
        });
    }

    /**
     * Check if the widget must be hidden.
     * To be hidden, the widget must be empty.
     * It's never hidden in designer mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function _isWidgetHidden() {
        vm.parentCtrl.isHidden = !vm.widget.properties.noResults && vm.isWidgetEmpty;

        return vm.parentCtrl.isHidden;
    }

    /**
     * Check if the widget is empty.
     * To be empty, the widget should:
     *     - have no HTML content (and not be in edition).
     *
     * @return {boolean} If the widget is empty or not.
     */
    function _isWidgetEmpty() {
        if (shouldDisplayAsNGI()) {
            return false;
        }

        vm.isWidgetEmpty =
            !vm.editingContent &&
            (angular.isUndefinedOrEmpty(vm.widget.properties.content) ||
                angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content)));

        if (!vm.parentCtrl.designerMode()) {
            _isWidgetHidden();
        }

        return vm.isWidgetEmpty;
    }

    function isWidgetV2Empty(widget) {
        const isBlockEmpty = !widget.body?.html;
        const isCurrentLangEmpty =
            angular.isUndefinedOrEmpty(vm.widget.properties.content) ||
            angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content));
        
        return isBlockEmpty || isCurrentLangEmpty;
    }

    /**
     * Display the content of the HTML widget.
     */
    function _refreshHtmlContent() {
        const translatedContent = vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content);
        const popinTranslatedContent = vm.parentCtrl.getWidgetTranslation(vm.widget.properties.popin);

        const htmlContent = Utils.initHtmlContent(
            vm.widget.uuid,
            translatedContent,
            _HTML_PARENT_SELECTOR,
            popinTranslatedContent,
            _isListenerOnLinks,
        );

        vm.htmlContent = htmlContent.mainContent;
        vm.popinContent = htmlContent.popinContent;

        if (_isWidgetEmpty()) {
            vm.htmlContent = '';
            vm.popinContent = '';
        }
    }

    /**
     * Toggle drag and drop allowed for the HTML widget.
     * When it is in edit mode, disable drag'n'drop.
     */
    function _toggleDragAndDrop() {
        if (Widget.isDragDisabled()) {
            Widget.isDragDisabled(false);

            $element.parents('.component-cell').removeClass('component-cell--hover-disabled');
        } else {
            Widget.isDragDisabled(true);

            $element.parents('.component-cell').addClass('component-cell--hover-disabled');

            // [Fix IE/Edge]: prevent cell drag & drop to take over from the text selection.
            const stopPropagation = (evt) => evt.stopPropagation();
            Utils.waitForAndExecute('.widget-html__wysiwyg .fr-element', (el) => {
                el.on('dragstart', stopPropagation).on('selectstart', stopPropagation);
            });
        }
    }

    /**
     * When the Froala WYSIWYG editor closes, read the content to update the displayed content.
     */
    function _readContent() {
        _detachErrorEventOnWindow();

        // eslint-disable-next-line angular/angularelement
        const froalaElement = $('.froala');
        if (froalaElement.froalaEditor('codeView.isActive')) {
            froalaElement.froalaEditor('codeView.toggle');
        }

        $timeout(function delayUpdatingDesigner() {
            vm.editingContent = false;

            $rootScope.$broadcast('editing-html', false);

            _refreshHtmlContent();

            _appFilter.removeClass('app-filter--is-shown').remove();

            _toggleDragAndDrop();
        });
    }

    /**
     * Edit the content of the HTML widget.
     */
    function _editContent() {
        _attachErrorEventOnWindow();

        // Reset editingPopin flag to be sure to edit the widget HTML content and not the popin.
        set(vm.FroalaService, 'editingPopin', false);

        vm.editingContent = true;

        $rootScope.$broadcast('editing-html', true);

        _toggleDragAndDrop();

        _appFilter.appendTo('.app-content').bind('click', function onAppContentClick(evt) {
            evt.stopPropagation();

            $scope.$apply(function applyReadContentOnClick() {
                _readContent();
            });
        });

        $timeout(function delayShowFilter() {
            _appFilter.addClass('app-filter--is-shown');
        }, _FILTER_SHOW_DELAY);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Edit the content of the HTML widget in basic mode.
     */
    function editContentBasic() {
        if (Content.getAction() !== 'get' && Content.getViewMode() === 'basic' && !vm.editingContent) {
            _editContent();

            _isWidgetEmpty();
        }
    }

    /**
     * Edit the content of the HTML widget in default or simple mode.
     */
    function editContentDefaultOrSimple() {
        const viewMode = Content.getViewMode();

        if (Content.getAction() !== 'get' && (viewMode === 'default' || viewMode === 'simple') && !vm.editingContent) {
            _editContent();

            _isWidgetEmpty();
        }
    }

    /**
     * Get the widget classes.
     *
     * @return {Array} The widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        widgetClass.push('widget-editable');

        if (vm.isWidgetEmpty) {
            widgetClass.push('widget--is-empty');
        }

        if (vm.editingContent) {
            widgetClass.push('widget-html--is-editing');
            widgetClass.push('widget-editable--is-editing');
        }

        if (angular.isDefinedAndFilled(vm.widget.properties.isIntro)) {
            widgetClass.push('widget--is-intro');
        }

        return widgetClass;
    }

    /**
     * Init Froala.
     */
    function initFroala() {
        vm.widget.properties = vm.widget.properties || {};
        const { properties } = vm.widget;
        const { inputLanguage } = Translation;

        properties.content = properties.content || {};
        properties.content[inputLanguage] = properties.content[inputLanguage] || '';

        properties.popin = properties.popin || {};
        properties.popin[inputLanguage] = properties.popin[inputLanguage] || '';

        vm.FroalaService.widgetUuid = vm.widget.uuid;
    }

    function shouldDisplayAsNGI() {
        return vm.isNGICompatible && !vm.editingContent;
    }

    function setAsNonNgiCompatible() {
        vm.isNGICompatible = false;
    }

    function setAsNgiCompatible() {
        const isInDesignerContext = vm.parentCtrl.designerMode();
        // Prevent the switch to true if the required FF are not enabled.
        if (isInDesignerContext && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2')) {
            vm.isNGICompatible = true;
        }
    }

    /////////////////////////////

    vm.editContentBasic = editContentBasic;
    vm.editContentDefaultOrSimple = editContentDefaultOrSimple;
    vm.getWidgetClass = getWidgetClass;
    vm.initFroala = initFroala;
    vm.shouldDisplayAsNGI = shouldDisplayAsNGI;
    vm.setAsNonNgiCompatible = setAsNonNgiCompatible;
    vm.setAsNgiCompatible = setAsNgiCompatible;
    vm.isWidgetV2Empty = isWidgetV2Empty;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * The controller is destroyed, cancel the event listeners.
     */
    $scope.$on('$destroy', _detachErrorEventOnWindow);

    /**
     * When the input language changes, update the content of the HTML widget.
     */
    $scope.$on('inputLanguage', _refreshHtmlContent);
    $scope.$on('content_refresh', _refreshHtmlContent);

    /**
     * When the links are listned, update the flag.
     */
    $scope.$on('listenerOnLinks', function whenLinksAreListened(widgetUUID) {
        if (widgetUUID === vm.widget.uuid) {
            _isListenerOnLinks = true;
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        if (Utils.isDesignerMode()) {
            // If we are not in the designer context or the content is not v2 compatible
            // we fallback to legacy display (we consider unsaved content as v2 compatible).
            // Here we are trying to guess which display will be the correct one in view mode.
            // This is not perfect, but we want to avoid too much logic in the frontend to know if a content is
            // compatible v2 or not (widget types presents on the content + some specific settings on some widgets)
            if (Content.getAction() !== 'create' && Content.getCurrent()?.template.isV2Compatible === false) {
                setAsNonNgiCompatible();
            }

            const FroalaService = $injector.get('FroalaService');
            vm.FroalaService = FroalaService;

            // Get Froala toolbar, in this widget the toolbar is customizable.
            const toolbarConfig = FroalaService.getHTMLToolbar();
            // Set Froala options.
            let froalaToolbarOffset = 108;
            if (Content.isDesignerInNewSimpleMode()) {
                froalaToolbarOffset = 68;
            }
            froalaToolbarOffset += 24;
            vm.FROALA_OPTIONS = FroalaService.getOptions(true, true, true, true, toolbarConfig, froalaToolbarOffset);
        } else {
            vm.FroalaService = {
                openContent: Utils.openContent,
                openPopin: Utils.openPopin,
            };
        }

        _initPopinOptions();

        _refreshHtmlContent();
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        init();
    };
}

/////////////////////////////

/**
 * Display the widget HTML.
 * The widget Tip uses a wysiwyg to set a tip.
 *
 * @param {Object} widget The widget object, with properties.
 */

function WidgetHtmlDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetHtmlController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetHtml', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-html/views/widget-html.html',
    };
}

/////////////////////////////

// eslint-disable-next-line angular/directive-name
angular.module('Widgets').directive('widgetHtml', WidgetHtmlDirective);

/////////////////////////////

export { WidgetHtmlController, WidgetHtmlDirective };
