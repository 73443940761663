import React from 'react';

import get from 'lodash/fp/get';
import uniqueId from 'lodash/uniqueId';

import { classnames } from '@lumapps/classnames';
import { CustomRadioList } from '@lumapps/widget-base/components/WidgetSettings/CustomRadioList';
import { ViewModeBlock } from '@lumapps/widget-base/components/WidgetSettings/ViewModeBlock';

import { VIEW_MODES } from '../../constants';
import { ViewModeType } from '../../types';

import './index.scss';

export interface ViewModeListProps<VM> {
    className?: string;
    dataScope?: string;
    viewModes?: ViewModeType<VM | string>[];
    currentViewMode: ViewModeType<VM>;
    onViewModeChange: (viewMode: ViewModeType<VM>) => void;
}

const CLASSNAME = 'widget-setting-view-mode-list';

/**
 * Component to display a list of view modes (list, grid, slideshow) using the CustomRadioList component
 */
export function ViewModeList<VM>({
    className,
    currentViewMode,
    dataScope,
    viewModes = VIEW_MODES as ViewModeType<VM>[],
    onViewModeChange,
}: ViewModeListProps<VM>) {
    const viewModeLabelId = React.useMemo(() => uniqueId(`${CLASSNAME}-view-mode-label-`), []);

    return (
        <CustomRadioList
            className={classnames(CLASSNAME, className)}
            aria-labelledby={viewModeLabelId}
            items={viewModes.map((viewMode) => {
                return { viewMode };
            })}
            dataScope={dataScope}
            itemRenderer={ViewModeBlock}
            itemKeySelector={get('viewMode.label')}
            selectedItem={{ viewMode: currentViewMode }}
            setSelectedItem={(item: { viewMode: ViewModeType<VM> }) => onViewModeChange(item.viewMode)}
        />
    );
}
