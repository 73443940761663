/* eslint-disable react/forbid-elements */
/* eslint-disable lumapps/no-classname-strings */
import React, { RefObject, useCallback, useMemo } from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import {
    mdiDotsHorizontal,
    mdiFormatHeader1,
    mdiFormatHeader2,
    mdiFormatHeader3,
    mdiFormatLetterCase,
    mdiFormatParagraph,
    mdiPlus,
} from '@lumapps/lumx/icons';
import { Theme } from '@lumapps/lumx/react';
import { MediaSource } from '@lumapps/medias/types';
import { isDriveAllowed } from '@lumapps/medias/utils/isDriveAllowed';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { accountTypeSelector } from '@lumapps/user/ducks/selectors';
import { useEmbeddedMapButton } from '@lumapps/wrex-embedded-map/components/buttons/EmbeddedMapButton/useEmbeddedMapButton';
import { useEmbeddedVideoDropdown } from '@lumapps/wrex-embedded-video/components/buttons/EmbeddedVideoButton/useEmbeddedVideoDropdown';
import { WREX_EMBEDDED_VIDEO } from '@lumapps/wrex-embedded-video/keys';
import { useEmojiButton } from '@lumapps/wrex-emoji/components/buttons/EmojiButton/useEmojiButton';
import { useInsertEnhancedImageButton } from '@lumapps/wrex-enhanced-image/components/buttons/InsertEnhancedImageButton/useInsertEnhancedImageButton';
import { ImageEditor } from '@lumapps/wrex-enhanced-image/types';
import { useFileInsertionButton } from '@lumapps/wrex-file/components/buttons/InsertFileButton/useFileInsertionButton';
import { useInsertImageGalleryButton } from '@lumapps/wrex-image-gallery/components/buttons/InsertImageGalleryButton/useInsertImageGalleryButton';
import { useLinkPreviewButton } from '@lumapps/wrex-link-preview/components/buttons/LinkPreviewButton/useLinkPreviewButton';
import { LinkPreviewEditor } from '@lumapps/wrex-link-preview/types';
import { useLinkButton } from '@lumapps/wrex-link/components/buttons/LinkButton/useLinkButton';
import { useListButton } from '@lumapps/wrex-list/components/buttons/ListButton/useListButton';
import { ORDERED_LIST, UNORDERED_LIST } from '@lumapps/wrex-list/constants';
import { useQuoteBlockButton } from '@lumapps/wrex-quote/components/buttons/QuoteBlockButton/useQuoteBlockButton';
import { useTableButton } from '@lumapps/wrex-table/components/buttons/TableButton/useTableButton';
import {
    ICONS as ALIGN_ICONS,
    useAlignButton,
} from '@lumapps/wrex-typography/components/buttons/AlignButton/useAlignButton';
import {
    BlockTypeOption,
    useBlockTypeSelector,
} from '@lumapps/wrex-typography/components/buttons/BlockTypeSelector/useBlockTypeSelector';
import { useCodeBlockButton } from '@lumapps/wrex-typography/components/buttons/CodeBlockButton/useCodeBlockButton';
import { useColorSelector } from '@lumapps/wrex-typography/components/buttons/ColorButton/useColorSelector';
import { useHistoryButton } from '@lumapps/wrex-typography/components/buttons/HistoryButton/useHistoryButton';
import { useInlineCodeButton } from '@lumapps/wrex-typography/components/buttons/InlineCodeButton/useInlineCodeButton';
import { useMarkButton } from '@lumapps/wrex-typography/components/buttons/MarkButton/useMarkButton';
import {
    BOLD,
    HEADLINE,
    ITALIC,
    PARAGRAPH,
    REDO,
    SUBTITLE,
    TITLE,
    UNDERLINE,
    STRIKETHROUGH,
    UNDO,
} from '@lumapps/wrex-typography/constants';
import { WREX_TYPOGRAPHY } from '@lumapps/wrex-typography/keys';
import { TypographyEditor } from '@lumapps/wrex-typography/types';
import { ALIGNMENTS } from '@lumapps/wrex/constants';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

export type StructuredContentToolbarBreakpoints =
    | 'too-small'
    | 'group-insert'
    | 'formatting-submenu'
    | 'no-color'
    | 'no-image-align'
    | 'no-video-list'
    | 'no-insert-label'
    | 'no-blocktype-label'
    | 'full';

export const useStructuredContentToolbar = ({
    theme,
    isReadOnly,
    currentBreakpoint,
}: {
    theme?: Theme;
    parentDialogRef?: RefObject<HTMLDivElement>;
    isReadOnly?: boolean;
    currentBreakpoint: StructuredContentToolbarBreakpoints;
}) => {
    const { pluralize, translateKey } = useTranslate();
    const scope = 'structured-content-toolbar';
    const { get } = useDataAttributes('structured-content-toolbar');
    const editor = useSlateStatic() as ReactEditor & ImageEditor & TypographyEditor & LinkPreviewEditor;

    const onQuickUploadImage = useCallback(
        (files: File[]) => {
            editor.insertImage({}, files);
        },
        [editor],
    );

    const accountType = useSelector(accountTypeSelector);

    const filesAllowedProviders = [MediaSource.GOOGLE_DRIVE, MediaSource.MICROSOFT];

    /** BREAKPOINTS */
    const blockytpeLabelBreakpoint = React.useMemo(() => {
        return currentBreakpoint === 'full';
    }, [currentBreakpoint]);
    const insertLabelBreakpoint = React.useMemo(() => {
        return blockytpeLabelBreakpoint || currentBreakpoint === 'no-blocktype-label';
    }, [blockytpeLabelBreakpoint, currentBreakpoint]);
    const videoListBreakpoint = React.useMemo(() => {
        return insertLabelBreakpoint || currentBreakpoint === 'no-insert-label';
    }, [currentBreakpoint, insertLabelBreakpoint]);
    const imageAlignBreakpoint = React.useMemo(() => {
        return videoListBreakpoint || currentBreakpoint === 'no-video-list';
    }, [currentBreakpoint, videoListBreakpoint]);
    const colorBreakpoint = React.useMemo(() => {
        return imageAlignBreakpoint || currentBreakpoint === 'no-image-align';
    }, [currentBreakpoint, imageAlignBreakpoint]);
    const formattingSubmenuBreakpoint = React.useMemo(() => {
        return colorBreakpoint || currentBreakpoint === 'no-color';
    }, [colorBreakpoint, currentBreakpoint]);
    const groupInsertBreakpoint = React.useMemo(() => {
        return formattingSubmenuBreakpoint || currentBreakpoint === 'formatting-submenu';
    }, [currentBreakpoint, formattingSubmenuBreakpoint]);

    /** all button props retrieved from each button hook */
    const undoButtonProps = useHistoryButton(UNDO, {
        ...get({ element: 'button', action: 'undo' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const redoButtonProps = useHistoryButton(REDO, {
        ...get({ element: 'button', action: 'redo' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const boldButtonProps = useMarkButton(BOLD, {
        ...get({ element: 'button', action: 'mark-bold' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const italicButtonProps = useMarkButton(ITALIC, {
        ...get({ element: 'button', action: 'mark-italic' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const underlineButtonProps = useMarkButton(UNDERLINE, {
        ...get({ element: 'button', action: 'mark-underline' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const strikeButtonProps = useMarkButton(STRIKETHROUGH, {
        ...get({ element: 'button', action: 'mark-strike' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const alignStartButtonProps = useAlignButton(ALIGNMENTS.start, {
        ...get({ element: 'button', action: 'mark-align-start' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const alignCenterButtonProps = useAlignButton(ALIGNMENTS.center, {
        ...get({ element: 'button', action: 'mark-align-center' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const alignEndButtonProps = useAlignButton(ALIGNMENTS.end, {
        ...get({ element: 'button', action: 'mark-align-end' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const inlineCodeButtonProps = useInlineCodeButton({
        ...get({ element: 'button', action: 'inline-code' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const codeBlockButtonProps = useCodeBlockButton({
        ...get({ element: 'button', action: 'code-block' }),
    });
    const linkButtonProps = useLinkButton({
        ...get({ element: 'button', action: 'link' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const unorderedListButtonProps = useListButton(UNORDERED_LIST, {
        ...get({ element: 'button', action: UNORDERED_LIST }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const orderedListButtonProps = useListButton(ORDERED_LIST, {
        ...get({ element: 'button', action: ORDERED_LIST }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const quoteButtonProps = useQuoteBlockButton({
        ...get({ element: 'button', action: 'quote-block' }),
        ...(isReadOnly && { isDisabled: true }),
    });

    const {
        hiddenInput: insertImageHiddenInput,
        insertImageButtonProps,
        uploadImageDialog,
    } = useInsertEnhancedImageButton({
        onQuickUpload: onQuickUploadImage,
        scope,
        otherProps: {
            className: 'menu-item--no-chevron',
            ...(isReadOnly && { isDisabled: true }),
        },
    });
    const imageGalleryButtonHook = useInsertImageGalleryButton({
        otherProps: {
            ...get({ element: 'button', action: 'image-gallery' }),
            className: 'menu-item--no-chevron',
            ...(isReadOnly && { isDisabled: true }),
        },
    });
    const { insertEmojiPicker, emojiButtonProps } = useEmojiButton({
        ...get({ element: 'button', action: 'emoji' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const {
        hiddenInput: insertFileHiddenInput,
        fileInsertionButton: fileInsertionButtonProps,
        oneDrivePicker,
    } = useFileInsertionButton({
        isDriveAllowed: isDriveAllowed(filesAllowedProviders, accountType, true),
        isPickerV2: true,
        otherProps: {
            ...get({ element: 'button', action: 'file' }),
            ...(isReadOnly && { isDisabled: true }),
        },
    });
    const { insertLinkDialog, linkPreviewButtonProps } = useLinkPreviewButton(
        (link) => {
            editor.insertLinkPreview({
                url: link.url,
                title: link.title,
                thumbnailUrl: link?.images && link.images[link.thumbnailIndex || 0],
                description: link.description,
            });
        },
        {
            ...get({ element: 'button', action: 'link-preview' }),
            ...(isReadOnly && { isDisabled: true }),
        },
    );
    const tableButtonProps = useTableButton({
        ...get({ element: 'button', action: 'table' }),
        ...(isReadOnly && { isDisabled: true }),
    });
    const { insertEmbeddedMapDialog, embeddedMapButtonProps } = useEmbeddedMapButton({
        ...get({ element: 'button', action: 'embedded-map' }),
        ...(isReadOnly && { isDisabled: true }),
    });

    const {
        GoogleUploadDialog,
        embeddedVideoButton: videoButtonProps,
        insertLinkVideoDialog,
        insertPlayVideoDialog,
        videoUploadDialog,
        videoUploadInput,
    } = useEmbeddedVideoDropdown({
        otherProps: {
            ...get({ element: 'button', action: 'embedded-video' }),
            className: 'menu-item--no-chevron',
            ...(isReadOnly && { isDisabled: true }),
        },
    });

    /** options that will appears in blockTypes */
    const blockTypeOptions: BlockTypeOption[] = useMemo(
        () =>
            [
                {
                    type: PARAGRAPH,
                    label: translateKey(WREX_TYPOGRAPHY.NORMAL_TEXT_LABEL),
                    renderer: ({ label }) => <span className="wrex-content-body">{label}</span>,
                    default: true,
                    icon: mdiFormatParagraph,
                } as BlockTypeOption,
                {
                    type: HEADLINE,
                    label: translateKey(WREX_TYPOGRAPHY.HEADING1_LABEL),
                    renderer: ({ label }) => <span className="wrex-content-headline">{label}</span>,
                    icon: mdiFormatHeader1,
                } as BlockTypeOption,
                {
                    type: TITLE,
                    label: translateKey(WREX_TYPOGRAPHY.HEADING2_LABEL),
                    renderer: ({ label }) => <span className="wrex-content-title">{label}</span>,
                    icon: mdiFormatHeader2,
                } as BlockTypeOption,
                {
                    type: SUBTITLE,
                    label: translateKey(WREX_TYPOGRAPHY.HEADING3_LABEL),
                    renderer: ({ label }) => <span className="wrex-content-subtitle">{label}</span>,
                    icon: mdiFormatHeader3,
                } as BlockTypeOption,
            ].filter((option) => option !== undefined),
        [translateKey],
    );

    const blockTypeSelector = useBlockTypeSelector({
        blockTypeOptions,
        withIcon: true,
        withVerticalIcon: currentBreakpoint !== 'group-insert',
        withChildIcon: true,
        iconOnly: !blockytpeLabelBreakpoint && formattingSubmenuBreakpoint,
        tooltipLabel: pluralize(GLOBAL.STYLES, 2),
        ...(isReadOnly && { otherProps: { isDisabled: true } }),
    });
    const colorSelector = useColorSelector(theme, isReadOnly, colorBreakpoint);
    const alignSubmenu = useMemo(
        () => ({
            type: 'submenu',
            label: '',
            tooltipLabel: translateKey(WREX_TYPOGRAPHY.ALIGN),
            itemKey: 'align-submenu',
            icon: ALIGN_ICONS[editor.getTextAlignment() || ALIGNMENTS.start],
            'aria-disabled': alignStartButtonProps['aria-disabled'],
            verticalModeProps: {
                label: translateKey(WREX_TYPOGRAPHY.ALIGN),
                tooltipLabel: '',
            },
            childrenOptions: [alignStartButtonProps, alignCenterButtonProps, alignEndButtonProps],
        }),
        [alignCenterButtonProps, alignEndButtonProps, alignStartButtonProps, editor, translateKey],
    );
    const toolbarButtons: (ToolbarItem | false)[] = useMemo(() => {
        return [
            {
                type: 'section',
                label: '',
                itemKey: 'history-section',
                otherProps: {
                    gap: 2,
                },
                childrenOptions: [undoButtonProps, redoButtonProps],
            },
            formattingSubmenuBreakpoint && {
                label: '',
                itemKey: 'blocktype-section',
                type: 'section',
                childrenOptions: [blockTypeSelector],
            },
            {
                type: 'section',
                label: '',
                itemKey: 'formatting-section',
                otherProps: {
                    gap: 2,
                },
                childrenOptions: [
                    formattingSubmenuBreakpoint ? boldButtonProps : undefined,
                    formattingSubmenuBreakpoint ? italicButtonProps : undefined,
                    colorBreakpoint ? colorSelector : undefined,
                    imageAlignBreakpoint ? alignSubmenu : undefined,
                    videoListBreakpoint ? unorderedListButtonProps : undefined,
                    {
                        type: 'submenu',
                        label: '',
                        tooltipLabel: translateKey(GLOBAL.MORE_OPTIONS),
                        itemKey: 'more-formatting-submenu',
                        icon: formattingSubmenuBreakpoint ? mdiDotsHorizontal : mdiFormatLetterCase,
                        otherProps: {
                            ...(formattingSubmenuBreakpoint
                                ? {
                                      className: 'menu-item--no-chevron',
                                  }
                                : undefined),
                        },
                        childrenOptions: [
                            !formattingSubmenuBreakpoint ? blockTypeSelector : undefined,
                            !colorBreakpoint ? colorSelector : undefined,
                            !formattingSubmenuBreakpoint ? boldButtonProps : undefined,
                            !formattingSubmenuBreakpoint ? italicButtonProps : undefined,
                            underlineButtonProps,
                            strikeButtonProps,
                            inlineCodeButtonProps,
                            !imageAlignBreakpoint ? alignSubmenu : undefined,
                            !videoListBreakpoint ? unorderedListButtonProps : undefined,
                            orderedListButtonProps,
                            quoteButtonProps,
                            codeBlockButtonProps,
                        ].filter((child) => !!child) as ToolbarItem[],
                    },
                ].filter((child) => !!child) as ToolbarItem[],
            },
            {
                type: 'section',
                label: translateKey(GLOBAL.INSERT),
                itemKey: 'insert-section',
                otherProps: {
                    className: 'lumx-menu-section--no-divider-after',
                    gap: 2,
                },
                childrenOptions: [
                    groupInsertBreakpoint ? linkButtonProps : undefined,
                    groupInsertBreakpoint ? emojiButtonProps : undefined,
                    imageAlignBreakpoint ? insertImageButtonProps : undefined,
                    videoListBreakpoint
                        ? {
                              ...videoButtonProps,
                              tooltipLabel: translateKey(WREX_EMBEDDED_VIDEO.INSERTION_BUTTON_TOOLTIP),
                          }
                        : undefined,
                    {
                        type: 'submenu',
                        label: insertLabelBreakpoint ? translateKey(GLOBAL.INSERT) : undefined,
                        tooltipLabel: translateKey(WREX_TYPOGRAPHY.MORE_INSERT_OPTIONS),
                        itemKey: 'insert-submenu',
                        icon: mdiPlus,
                        otherProps: {
                            'data-testid': get({ element: 'button', action: 'insert-more' })['data-id'],
                        },
                        childrenOptions: [
                            !groupInsertBreakpoint ? linkButtonProps : undefined,
                            !groupInsertBreakpoint ? emojiButtonProps : undefined,
                            !imageAlignBreakpoint ? insertImageButtonProps : undefined,
                            ...(imageGalleryButtonHook?.insertImageGalleryButtonProps
                                ? ([imageGalleryButtonHook.insertImageGalleryButtonProps] as ToolbarItem[])
                                : []),
                            !videoListBreakpoint ? videoButtonProps : undefined,
                            linkPreviewButtonProps,
                            tableButtonProps,
                            embeddedMapButtonProps,
                            fileInsertionButtonProps,
                        ].filter((child) => !!child) as ToolbarItem[],
                    },
                ].filter((child) => !!child) as ToolbarItem[],
            },
        ];
    }, [
        undoButtonProps,
        redoButtonProps,
        formattingSubmenuBreakpoint,
        blockTypeSelector,
        boldButtonProps,
        italicButtonProps,
        colorBreakpoint,
        colorSelector,
        imageAlignBreakpoint,
        alignSubmenu,
        videoListBreakpoint,
        unorderedListButtonProps,
        translateKey,
        underlineButtonProps,
        strikeButtonProps,
        inlineCodeButtonProps,
        orderedListButtonProps,
        quoteButtonProps,
        codeBlockButtonProps,
        groupInsertBreakpoint,
        linkButtonProps,
        emojiButtonProps,
        insertImageButtonProps,
        videoButtonProps,
        insertLabelBreakpoint,
        get,
        imageGalleryButtonHook,
        linkPreviewButtonProps,
        tableButtonProps,
        embeddedMapButtonProps,
        fileInsertionButtonProps,
    ]);

    return {
        oneDrivePicker,
        blockTypeOptions,
        GoogleUploadDialog,
        toolbarButtons,
        insertFileHiddenInput,
        insertImageGalleryHiddenInput: imageGalleryButtonHook?.hiddenInput,
        insertImageHiddenInput,
        uploadImageDialog,
        insertEmojiPicker,
        insertLinkDialog,
        insertEmbeddedMapDialog,
        insertImageGalleryDialog: imageGalleryButtonHook?.insertImageGalleryDialog,
        insertPlayVideoDialog,
        insertLinkVideoDialog,
        videoUploadDialog,
        videoUploadInput,
    };
};
