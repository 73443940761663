import memoize from 'lodash/memoize';
import union from 'lodash/union';
import values from 'lodash/values';

import { createSelector } from '@lumapps/redux/reselect';

import { SaShareableContentsSelector } from '../selectors';
import { contentInitialState, SingleContentActionsState } from './slice';

export const contentActionsSelector = createSelector(
    [SaShareableContentsSelector],
    (saShareableContent) => saShareableContent.contentActionsById,
);

/**
 * Creates a contentActions selector by contentId
 *
 * ex :
 * const contentActionsSelector = makeContentActionsByContentIdSelector(state);
 * const firstContentActions =  contentActionsSelector("1");
 * const secondContentActions =  contentActionsSelector("2");
 *
 * @param state The app's state
 * @return {Function} The selector function
 *
 */
export const makeContentActionsByContentIdSelector = createSelector([contentActionsSelector], (contentActions) =>
    memoize((contentId: string): SingleContentActionsState => contentActions[contentId] || contentInitialState),
);

/**
 * Content actions selectors
 * These selectors take a SingleContentActionState object as attribute
 * */

/**
 * The content action status
 * @param singleContentActions The content action
 * @return {string} The status
 */
export const contentActionsStatusSelector = (singleContentActions: SingleContentActionsState) =>
    singleContentActions.status;

/**
 * Returns the programs on which the content can be made shared on
 * @param singleContentActions The content actions object
 * @return {Array} An array of program id's the user can make the content shareable on
 */
export const contentActionsCanMakeShareableOnSelector = (singleContentActions: SingleContentActionsState) =>
    singleContentActions.canMakeShareableOn;

/**
 * Returns the programs on which the content can be shared on, sorted by social networks.
 *
 * @param singleContentActions The content actions object.
 */
export const contentActionsCanBeSharedOnSelector = (singleContentActions: SingleContentActionsState) =>
    singleContentActions.canShareTo;

/**
 * Returns whether the content can be made shareable on any program or not
 * @param singleContentActions The content actions object.
 * @return {Boolean} whether the content can be made shareable on any program or not.
 */
export const contentCanBeMadeShareableSelector = createSelector(
    contentActionsCanMakeShareableOnSelector,
    (canMakeShareableOn): boolean => canMakeShareableOn.length > 0,
);

/**
 * Returns an array of all the programs the content can be shared on, regardless of the social network.
 *
 * For example, if a content has
 * canShareTo: {
 *      facebook: ['1', '2'],
 *      twitter: ['2', '3'],
 * }
 *
 * it will return ['1', '2', '3']
 * @param singleContentActions The content actions object.
 * @return {Array} An array containing all the programs the content can be shared on.
 */
export const allProgramsShareableOn = createSelector(
    contentActionsCanBeSharedOnSelector,
    (canBeSharedOn): Array<string> => union(...values(canBeSharedOn)),
);
