export const LINK_PREVIEW = 'link-preview';

/**
 * Types of resources that can be included in the rich text editor
 *
 * /!\ when updating this enum, you HAVE to update also NotificationTargetType in analytics/analytics-tracking package as well
 * since it's a copy of this one (in order to avoid circular dependencies)
 */
export enum LINK_PREVIEW_RESOURCE_TYPES {
    ARTICLE = 'article',
    COMMUNITY = 'community',
    CONTENT = 'content',
    EVENT = 'event',
    LEARNING_PATH = 'learning_path',
    MICRO_APP = 'micro_app',
    PLAY_VIDEO = 'play_video',
    PLAY_VIDEO_PLAYLIST = 'play_video_playlist',
    POST = 'post',
    TRAINING_COURSE = 'training_course',
}
