import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useTranslate } from '@lumapps/translations';

import { INSTANCE_COPY } from '../../keys';
import { instancesCopy } from '../../routes';

const InstancesCopySideNavigation = () => {
    const { translateKey } = useTranslate();

    return <SideNavigationItem label={translateKey(INSTANCE_COPY.COPY)} route={instancesCopy()} />;
};

InstancesCopySideNavigation.displayName = 'SideNavigationItem';

export { InstancesCopySideNavigation };
