(function IIFE() {
    'use strict';

    /////////////////////////////

    function NavigationPickerService($rootScope, $timeout, LxDepthService) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The delay between the actual closing/opening of the picker and the sending of the event.
         *
         * @type {number}
         */
        var _EVENT_DELAY = 600;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close a navigation picker.
         *
         * @param {string} pickerId The id of the navigation picker.
         */

        function closePicker(pickerId) {
            if (angular.isUndefinedOrEmpty(pickerId)) {
                return;
            }

            $rootScope.$broadcast('navigation-picker__close-start', pickerId);

            angular.element('body').css({
                overflow: 'visible',
            });

            angular.element('#' + pickerId).removeClass('item-picker--is-shown');

            $timeout(function delayCloseEvent() {
                $rootScope.$broadcast('navigation-picker__close-end', pickerId);
            }, _EVENT_DELAY);
        }

        /**
         * Open a navigation picker.
         *
         * @param {string} pickerId The id of the navigation picker.
         */
        function openPicker(pickerId) {
            if (angular.isUndefinedOrEmpty(pickerId)) {
                return;
            }

            $rootScope.$broadcast('navigation-picker__open-start', pickerId);
            LxDepthService.register();

            angular.element('body').css({
                overflow: 'hidden',
            });

            $timeout(function delayPickerShow() {
                angular.element('#' + pickerId)
                    .css('z-index', LxDepthService.getDepth())
                    .addClass('item-picker--is-shown');
            });

            $timeout(function delayOpenEvent() {
                $rootScope.$broadcast('navigation-picker__open-end', pickerId);
            }, _EVENT_DELAY);
        }

        /////////////////////////////

        service.close = closePicker;
        service.open = openPicker;
    }

    /////////////////////////////

    angular.module('Services').service('NavigationPicker', NavigationPickerService);
})();
