import React, { useCallback } from 'react';

import debounce from 'lodash/debounce';

import { useDispatchGlobalLiveMessage } from './useDispatchGlobalLiveMessage';

interface UseUpdateGlobalLiveMessageOptions {
    /** Whether the message should be read or not */
    enabled?: boolean;
    /**
     * Debounce timer to set before reading out the message.
     * This can be useful if you have many updates one after the other but you
     * only want to last one to be read
     */
    debounceTimeout?: number;
}

const DEFAULT_DEBOUNCE_TIMEOUT = 0;

/**
 * Hook automatically dispatch the given message to the aria live redux store
 * so that it can be read to screen readers.
 */
export const useUpdateGlobalLiveMessage = (message?: string, options: UseUpdateGlobalLiveMessageOptions = {}) => {
    const { enabled = true, debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT } = options;
    const updateA11YLiveMessage = useDispatchGlobalLiveMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceLiveMessage = useCallback(debounce(updateA11YLiveMessage, debounceTimeout), []);

    React.useEffect(() => {
        if (enabled && message) {
            debounceLiveMessage(message);
            return () => debounceLiveMessage('');
        }
        return undefined;
    }, [debounceLiveMessage, enabled, message]);
};
