import { FETCH_ALL_ERROR, FETCH_ALL_SUCCESS, FETCH_ALL } from './actions';

const defaultState = {
    isFetching: true,
    list: [],
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_ALL:
            return { ...state, isFetching: true };

        case FETCH_ALL_SUCCESS: {
            return { ...state, isFetching: false, list: [...action.topics] };
        }

        case FETCH_ALL_ERROR:
            return { ...state, isFetching: false, list: [] };

        default:
            return state;
    }
};
