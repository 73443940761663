/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';
import { canAccessSegmentAdmin } from '@lumapps/segment/ducks/slice/selectors';

import { SegmentSideNavigation } from './SegmentSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: canAccessSegmentAdmin(state),
});

const ConnectedSegmentSideNavigation = connect(mapStateToProps)(SegmentSideNavigation);

export { ConnectedSegmentSideNavigation as SegmentSideNavigation };
