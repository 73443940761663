import * as React from 'react';
import * as propTypes from 'prop-types';

import { Input } from './Input';

/**
 * Renders a positive number input.
 */
export class PositiveNumberInput extends React.PureComponent {
    static propTypes = {
        ...Input.propTypes,
        value: propTypes.number,
    };

    constructor(props) {
        super(props);
    }
    render() {
        return <Input {...this.props} type="number" min={0} />;
    }
}
