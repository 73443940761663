import * as tasks from './cct_tasks';

const FETCH_CCT_SUCCESS = 'customContentType/FETCH_CCT_SUCCESS';

/**
 * Fetch custom content types for instance.
 *
 * @param  {string}   instanceId Instance id.
 * @return {Function} thunk.
 */
const fetchCustomContentTypes = (instanceId) => async (dispatch) => {
    const customContentTypes = await tasks.getCustomContentTypes(instanceId);

    dispatch({
        payload: { customContentTypes },
        type: FETCH_CCT_SUCCESS,
    });
};

export { FETCH_CCT_SUCCESS, fetchCustomContentTypes };
