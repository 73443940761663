import React, { PureComponent } from 'react';
import { bool, func, string } from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';

import { InputTextFieldDialog } from '../../ui/dialogs/input-text-field';

import { createFolder } from '../../../services/document';
// eslint-disable-next-line id-blacklist
import { error as displayError } from '../../../services/notification';
import { getInputLanguage, translate as t } from '../../../translations';

const _INPUT_NAME = 'name';

/**
 * Button that display a dialog to create a folder.
 */
class CreateFolderDialog extends PureComponent {
    constructor(props) {
        super(props);

        this._INITIAL_STATE = {
            folderName: undefined,
            inputLanguage: getInputLanguage(),
            loading: false,
        };
        this.state = cloneDeep(this._INITIAL_STATE);

        this.handleClose = this.handleClose.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
    }

    /**
     * Create a new folder.
     *
     * @param  {string}  name New folder name.
     * @return {Promise} Folder creation request promise.
     */
    createFolder({ folderName }) {
        const { parentPath, isShared, onValidate } = this.props;
        const folder = {
            isShared,
            names: { [getInputLanguage()]: folderName },
            parentPath,
        };

        this.setState({ loading: true });

        return createFolder(folder).then(
            () => {
                onValidate();
            },
            () => {
                displayError(t('GLOBAL.ERROR.GENERIC'));
            },
        );
    }

    /**
     * Reset state and call onClose props method.
     */
    handleClose() {
        const { onClose } = this.props;

        this.setState(cloneDeep(this._INITIAL_STATE));
        onClose();
    }

    /**
     * Makes create folder request, reset state and call onValidate props method.
     *
     * @param {string} folderName Folder name.
     */
    async handleValidate({ [_INPUT_NAME]: folderName }) {
        const { onValidate } = this.props;

        await this.createFolder({ folderName });
        this.setState(cloneDeep(this._INITIAL_STATE));
        onValidate();
    }

    render() {
        const { folderName, loading } = this.state;
        const { canChangeLanguage } = this.props;

        return (
            <InputTextFieldDialog
                fullWidth
                canChangeLanguage={canChangeLanguage}
                label="GLOBAL.NAME"
                loading={loading}
                min={1}
                name={_INPUT_NAME}
                title="FRONT.MEDIA_PICKER.HEADER_CREATE_FOLDER"
                value={folderName}
                onClose={this.handleClose}
                onValidate={this.handleValidate}
            />
        );
    }
}

/////////////////////////////

CreateFolderDialog.propTypes = {
    /** Display input language selector. */
    canChangeLanguage: bool,
    isShared: bool,
    /** Called when closing dialog. */
    onClose: func.isRequired,
    /** Called after the folder was created. */
    onValidate: func.isRequired,
    /** Parent folder docPath for creation. */
    parentPath: string.isRequired,
};

CreateFolderDialog.defaultProps = {
    canChangeLanguage: false,
    isShared: false,
};

/////////////////////////////

export { CreateFolderDialog };
