import { oneOf, string, arrayOf, node } from 'prop-types';

export const mode = oneOf(['grid']);

export const color = string;

export const colors = arrayOf(color);

export const child = node;

export const size = oneOf(['xs', 's', 'm', 'l', 'xl']);

export const theme = oneOf(['light', 'dark']);

export const position = oneOf(['left', 'right']);
