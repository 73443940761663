export enum ASK_AI_RULES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481944708
     */
    RULES = 'ASK_AI_RULES.RULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487259591
     */
    ANSWER = 'ASK_AI_RULES.ANSWER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481942851
     */
    ANSWER_IN_SOURCE = 'ASK_AI_RULES.RULE_ANSWER_IN_SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481951965
     */
    APPLICATION_GROUPS_INFO = 'ASK_AI_RULES.APPLICATION_GROUPS_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481952369
     */
    DELETE_RULE = 'ASK_AI_RULES.DELETE_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481956600
     */
    CONFIRM_DELETE_RULE = 'ASK_AI_RULES.CONFIRM_DELETE_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481996203
     */
    NEW_RULE = 'ASK_AI_RULES.NEW_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481996238
     */
    NO_RULES = 'ASK_AI_RULES.NO_RULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481996305
     */
    EMPTY_STATE_MESSAGE = 'ASK_AI_RULES.EMPTY_STATE_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=483698154
     */
    CUSTOM_ANSWER_TITLE = 'ASK_AI_RULES.CUSTOM_ANSWER_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484533897
     */
    EDIT_RULE = 'ASK_AI_RULES.EDIT_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484534209
     */
    RULE_INFO_BLOCK_DESCRIPTION = 'ASK_AI_RULES.RULE_INFO_BLOCK_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484536973
     */
    GROUPS_SECTION_DESCRIPTION = 'ASK_AI_RULES.GROUPS_SECTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484537286
     */
    PARAMETERS_SECTION_DESCRIPTION = 'ASK_AI_RULES.PARAMETERS_SECTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484537373
     */
    CONCEPTS_BLOCK_TITLE = 'ASK_AI_RULES.CONCEPTS_BLOCK_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484544361
     */
    ANSWER_FROM_SOURCES_LABEL = 'ASK_AI_RULES.ANSWER_FROM_SOURCES_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484551899
     */
    ANSWER_FROM_SOURCES_HELPER = 'ASK_AI_RULES.ANSWER_FROM_SOURCES_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488322720
     */
    CUSTOM_ANSWER_HELPER = 'ASK_AI_RULES.CUSTOM_ANSWER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484577594
     */
    MAXIMUM_SOURCES = 'ASK_AI_RULES.MAXIMUM_SOURCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=484754225
     */
    MAXIMUM_SOURCES_ERROR = 'ASK_AI_RULES.MAXIMUM_SOURCES_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485408928
     */
    NO_ACTIONABLE_ITEM_TITLE = 'ASK_AI_RULES.NO_ACTIONABLE_ITEM_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485422203
     */
    NO_ACTIONABLE_ITEM_DESC = 'ASK_AI_RULES.NO_ACTIONABLE_ITEM_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485422315
     */
    INSERT_ACTIONABLE_ITEM_BUTTON_LABEL = 'ASK_AI_RULES.INSERT_ACTIONABLE_ITEM_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=485422349
     */
    INSERT_SUBHEADER = 'ASK_AI_RULES.INSERT_SUBHEADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493400873
     */
    CUSTOM_ANSWER = 'ASK_AI_RULES.CUSTOM_ANSWER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493401788
     */
    CUSTOM_ANSWER_DESCRIPTION = 'ASK_AI_RULES.CUSTOM_ANSWER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=490563024
     */
    MAXIMUM_CONCEPTS_ERROR = 'ASK_AI_RULES.MAXIMUM_CONCEPTS_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498362297
     */
    ADD_ANSWER = 'ASK_AI_RULES.ADD_ANSWER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498839544
     */
    EDIT_ANSWER = 'ASK_AI_RULES.EDIT_ANSWER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501106251
     */
    RULES_UPDATED_NOTIFICATION = 'ASK_AI_RULES.RULES_UPDATED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501106284
     */
    RULES_CREATED_NOTIFICATION = 'ASK_AI_RULES.RULES_CREATED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501106288
     */
    RULES_DELETED_NOTIFICATION = 'ASK_AI_RULES.RULES_DELETED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498469248
     */
    CUSTOM_RULE_USER_CARD_LABEL = 'ASK_AI_RULES.CUSTOM_RULE_USER_CARD_LABEL',
}
