import React, { useEffect } from 'react';

import noop from 'lodash/noop';

import { currentArticleSelector } from '@lumapps/articles/ducks/selectors';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { currentEventPageSelector } from '@lumapps/events/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';
import { Comments as ReactionComments } from '@lumapps/widget-commentsv2/components/Comments';

import { LegacyCommentsProperties } from '../../types';
import { BlockComments } from '../BlockComments';

import './index.scss';

export interface ClientComputedCommentsProps extends ClientComputedProps {
    properties?: LegacyCommentsProperties;
}

export const ClientComputedComments: React.FC<ClientComputedCommentsProps> = (props) => {
    const dispatch = useDispatch();
    const { properties, uuid } = props;
    const currentContent = useSelector(
        contentSelector,
        // Never update even if the store updates
        // (updating `currentContent` overrides the comment list that might have been edited)
        () => true,
    );
    const currentEvent = useSelector(currentEventPageSelector);
    const currentArticle = useSelector(currentArticleSelector);
    const currentResource = currentArticle || currentEvent || currentContent;
    const resourceId = currentResource.id;
    let resourceType;

    let commentCount;

    if (currentEvent?.id) {
        resourceType = 'events';
        commentCount = currentEvent.commentCount;
    } else if (currentArticle?.id) {
        resourceType = 'articles';
        commentCount = currentArticle.commentCount;
    } else {
        resourceType = 'contents';
        commentCount = currentContent.comments;
    }

    const isEmptyWidget = !properties?.isEnabled && commentCount === 0;

    useEffect(() => {
        if (isEmptyWidget) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, isEmptyWidget, uuid]);

    if (isEmptyWidget) {
        return null;
    }

    if (properties?.commentVersion === 2) {
        return (
            <ReactionComments
                resourceId={resourceId}
                resourceType={resourceType}
                theme={properties.style?.content?.theme}
                canComment={properties.isEnabled}
                displayNumber={properties.displayNumber}
                onLike={noop}
                onLikeListOpen={noop}
                openReportDialog={noop}
            />
        );
    }

    return (
        <BlockComments
            theme={properties?.style?.content?.theme}
            contentId={currentContent.id}
            hasComments
            canComment={properties?.isEnabled}
            displayNumber={properties?.displayNumber}
            totalComments={currentContent.comments}
            relevantCommentId={currentContent.relevantComment}
        />
    );
};
