const DEFAULT_VALUE = 'DEFAULT';

/**
 * Describes possible background size values.
 */
const BACKGROUND_SIZE_CHOICES = [
    {
        label: 'STYLE.BACKGROUND.FILL',
        value: 'cover',
    },
    {
        label: 'STYLE.BACKGROUND.FIT',
        value: 'contain',
    },
    {
        label: 'STYLE.BACKGROUND.AUTO',
        value: 'auto',
    },
];

/**
 * Combination of all possible background positions.
 * Presented into an array to fasten comparison (e.g., between `'top left'` and `'left top'`).
 */
const BACKGROUND_POSITIONS = [
    ['top', 'left'],
    ['top', 'center'],
    ['top', 'right'],
    ['center', 'left'],
    ['center', 'center'],
    ['center', 'right'],
    ['bottom', 'left'],
    ['bottom', 'center'],
    ['bottom', 'right'],
];

const SIZE_N = 0;
const SIZE_XXS = 8;
const SIZE_XS = 12;
const SIZE_S = 16;
const SIZE_M = 24;
const SIZE_L = 32;
const SIZE_XL = 48;
const SIZE_XXL = 96;

const POSITIVE_SIZE_CHOICES = [
    {
        label: `N (${SIZE_N}px)`,
        value: SIZE_N,
    },
    {
        label: `XXS (${SIZE_XXS}px)`,
        value: SIZE_XXS,
    },
    {
        label: `XS (${SIZE_XS}px)`,
        value: SIZE_XS,
    },
    {
        label: `S (${SIZE_S}px)`,
        value: SIZE_S,
    },
    {
        label: `M (${SIZE_M}px)`,
        value: SIZE_M,
    },
    {
        label: `L (${SIZE_L}px)`,
        value: SIZE_L,
    },
    {
        label: `XL (${SIZE_XL}px)`,
        value: SIZE_XL,
    },
    {
        label: `XXL (${SIZE_XXL}px)`,
        value: SIZE_XXL,
    },
];

/**
 * Combination of both negative and positive values which can be set
 * for margin and padding.
 */
const SIZE_CHOICES = [
    ...POSITIVE_SIZE_CHOICES,
    {
        label: `-XXS (-${SIZE_XXS}px)`,
        value: -SIZE_XXS,
    },
    {
        label: `-XS (-${SIZE_XS}px)`,
        value: -SIZE_XS,
    },
    {
        label: `-S (-${SIZE_S}px)`,
        value: -SIZE_S,
    },
    {
        label: `-M (-${SIZE_M}px)`,
        value: -SIZE_M,
    },
    {
        label: `-L (-${SIZE_L}px)`,
        value: -SIZE_L,
    },
    {
        label: `-XL (-${SIZE_XL}px)`,
        value: -SIZE_XL,
    },
    {
        label: `-XXL (-${SIZE_XXL}px)`,
        value: -SIZE_XXL,
    },
];

/**
 * Available shadow elevation values.
 */
const SHADOW_ELEVATION_CHOICES = [
    {
        label: '0',
        value: 0,
    },
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
    {
        label: '5',
        value: 5,
    },
];

/**
 * Font weight available for widget header and footer labels.
 */
const FONT_WEIGHT_CHOICES = [
    {
        label: 'STYLE.FONT.LIGHT',
        value: 300,
    },
    {
        label: 'STYLE.FONT.REGULAR',
        value: 400,
    },
    {
        label: 'STYLE.FONT.MEDIUM',
        value: 500,
    },
    {
        label: 'STYLE.FONT.BOLD',
        value: 700,
    },
];

const MARGIN_FIELDS = [
    { label: 'STYLE.TOP', name: 'marginTop' },
    { label: 'STYLE.LEFT', name: 'marginLeft' },
    { label: 'STYLE.BOTTOM', name: 'marginBottom' },
    { label: 'STYLE.RIGHT', name: 'marginRight' },
];

const PADDING_FIELDS = [
    { label: 'STYLE.TOP', name: 'paddingTop' },
    { label: 'STYLE.LEFT', name: 'paddingLeft' },
    { label: 'STYLE.BOTTOM', name: 'paddingBottom' },
    { label: 'STYLE.RIGHT', name: 'paddingRight' },
];

const BORDER_WIDTH_FIELDS = [
    { label: 'STYLE.TOP', name: 'borderTopWidth' },
    { label: 'STYLE.LEFT', name: 'borderLeftWidth' },
    { label: 'STYLE.BOTTOM', name: 'borderBottomWidth' },
    { label: 'STYLE.RIGHT', name: 'borderRightWidth' },
];

const BORDER_RADIUS_FIELDS = [
    { label: 'STYLE.TOP_LEFT', name: 'borderTopLeftRadius' },
    { label: 'STYLE.BOTTOM_LEFT', name: 'borderBottomLeftRadius' },
    { label: 'STYLE.TOP_RIGHT', name: 'borderTopRightRadius' },
    { label: 'STYLE.BOTTOM_RIGHT', name: 'borderBottomRightRadius' },
];

const BORDER_COLOR_FIELDS = [
    { label: 'STYLE.TOP', name: 'borderTopColor' },
    { label: 'STYLE.LEFT', name: 'borderLeftColor' },
    { label: 'STYLE.BOTTOM', name: 'borderBottomColor' },
    { label: 'STYLE.RIGHT', name: 'borderRightColor' },
];

const FONT_CHOICES = [
    {
        label: 'Roboto',
        value: 'Roboto, sans-serif',
    },
    {
        label: 'Roboto Slab',
        value: 'Roboto Slab, sans-serif',
    },
];

const DEFAULT_WIDGET_BORDER_COLOR = '#E0E0E0';
const DEFAULT_ROW_CELL_BORDER_COLOR = '#000000';
const DEFAULT_FONT_COLOR = '#212121';

const DEFAULT_WIDGET_STYLE = {
    content: {
        backgroundColor: 'transparent',
        backgroundPosition: 'center center',
        /** Due to backward compatibility, the default background size need to be 'auto'. */
        backgroundSize: 'auto',
        borderBottomColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomWidth: 0,
        borderLeftColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderLeftWidth: 0,
        borderRightColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderRightWidth: 0,
        borderTopColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTopWidth: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: DEFAULT_VALUE,
        paddingLeft: DEFAULT_VALUE,
        paddingRight: DEFAULT_VALUE,
        paddingTop: DEFAULT_VALUE,
        shadowElevation: 0,
        shadowOpacity: 14,
    },
    footer: {
        icon: {
            color: DEFAULT_FONT_COLOR,
            fontSize: 18,
        },
        label: {
            color: DEFAULT_FONT_COLOR,
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
            fontWeight: 500,
        },
        main: {
            backgroundColor: 'transparent',
            backgroundPosition: 'center center',
            backgroundSize: 'auto',
            display: 'block',
            justifyContent: 'flex-end',
            shadowElevation: 0,
            shadowOpacity: 14,
        },
        wrapper: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            backgroundPosition: 'center center',
            backgroundSize: 'auto',
            borderBottomColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomWidth: 0,
            borderLeftColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderLeftWidth: 0,
            borderRightColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderRightWidth: 0,
            borderTopColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTopWidth: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: 0,
            marginLeft: 24,
            marginRight: 24,
            marginTop: 0,
            paddingBottom: 12,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 12,
            shadowElevation: 0,
            shadowOpacity: 14,
        },
    },
    header: {
        icon: {
            color: DEFAULT_FONT_COLOR,
            fontSize: 18,
        },
        label: {
            color: DEFAULT_FONT_COLOR,
            fontFamily: 'Roboto, sans-serif',
            fontSize: 16,
            fontWeight: 500,
        },
        main: {
            backgroundColor: 'transparent',
            backgroundPosition: 'center center',
            backgroundSize: 'auto',
            display: 'block',
            justifyContent: 'flex-start',
            shadowElevation: 0,
            shadowOpacity: 14,
        },
        wrapper: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            backgroundPosition: 'center center',
            backgroundSize: 'auto',
            borderBottomColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomWidth: 1,
            borderLeftColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderLeftWidth: 0,
            borderRightColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderRightWidth: 0,
            borderTopColor: DEFAULT_WIDGET_BORDER_COLOR,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTopWidth: 0,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 0,
            marginLeft: 24,
            marginRight: 24,
            marginTop: 0,
            paddingBottom: 12,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 12,
            shadowElevation: 0,
            shadowOpacity: 14,
        },
    },
    main: {
        backgroundColor: '#FFFFFF',
        backgroundPlainColor: 'transparent',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        borderBottomColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        borderBottomWidth: 0,
        borderLeftColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderLeftWidth: 0,
        borderRightColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderRightWidth: 0,
        borderTopColor: DEFAULT_WIDGET_BORDER_COLOR,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderTopWidth: 0,
        marginBottom: 12,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 12,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        shadowElevation: 1,
        shadowOpacity: 14,
    },
};

const DEFAULT_ROW_STYLE = {
    backgroundColor: 'transparent',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    borderBottomColor: DEFAULT_ROW_CELL_BORDER_COLOR,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomWidth: 0,
    borderLeftColor: DEFAULT_ROW_CELL_BORDER_COLOR,
    borderLeftWidth: 0,
    borderRightColor: DEFAULT_ROW_CELL_BORDER_COLOR,
    borderRightWidth: 0,
    borderTopColor: DEFAULT_ROW_CELL_BORDER_COLOR,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
};

const DEFAULT_CELL_STYLE = {
    components: {
        backgroundColor: 'transparent',
        backgroundPlainColor: '#FFFFFF',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        borderBottomColor: DEFAULT_ROW_CELL_BORDER_COLOR,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        borderBottomWidth: 0,
        borderLeftColor: DEFAULT_ROW_CELL_BORDER_COLOR,
        borderLeftWidth: 0,
        borderRightColor: DEFAULT_ROW_CELL_BORDER_COLOR,
        borderRightWidth: 0,
        borderTopColor: DEFAULT_ROW_CELL_BORDER_COLOR,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderTopWidth: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: 0,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 0,
        shadowElevation: 0,
        shadowOpacity: 14,
    },
};

const DEFAULT_FLUID_ROW = {
    paddingLeft: 12,
    paddingRight: 12,
};

const DEFAULT_NOT_FLUID_ROW = {
    paddingLeft: 0,
    paddingRight: 0,
};

const DEFAULT_PLAIN_CELL_STYLE = {
    components: {
        backgroundColor: '#FFFFFF',
        marginBottom: 12,
        marginLeft: 12,
        marginRight: 12,
        marginTop: 12,
        paddingBottom: 24,
        paddingLeft: 48,
        paddingRight: 48,
        paddingTop: 24,
        shadowElevation: 1,
    },
};

const DEFAULT_NOT_PLAIN_CELL_STYLE = {
    components: {
        backgroundColor: 'transparent',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: 0,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 0,
        shadowElevation: 0,
    },
};

const DEFAULT_PLAIN_CELL_WIDGET_STYLE = {
    footer: {
        wrapper: {
            borderBottomWidth: 1,
            marginLeft: 0,
            marginRight: 0,
        },
    },
    header: {
        wrapper: {
            borderTopWidth: 1,
            marginLeft: 0,
            marginRight: 0,
        },
    },
    main: {
        shadowElevation: 0,
    },
};

const DEFAULT_CELL_WIDGET_STYLE = {
    footer: {
        wrapper: {
            borderBottomWidth: 0,
            marginLeft: 24,
            marginRight: 24,
        },
    },
    header: {
        wrapper: {
            borderTopWidth: 0,
            marginLeft: 24,
            marginRight: 24,
        },
    },
    main: {
        shadowElevation: 1,
    },
};

const PADDING_PROPERTY_NAMES = ['padding', 'paddingBottom', 'paddingLeft', 'paddingRight', 'paddingTop'];

const STYLE_TYPES = {
    CUSTOMER: 'globalCustomer',
    INSTANCE: 'global',
    PARENT: 'parent',
    WIDGET: 'widget',
};

export {
    BACKGROUND_POSITIONS,
    BACKGROUND_SIZE_CHOICES,
    BORDER_COLOR_FIELDS,
    BORDER_RADIUS_FIELDS,
    BORDER_WIDTH_FIELDS,
    DEFAULT_CELL_STYLE,
    DEFAULT_CELL_WIDGET_STYLE,
    DEFAULT_FLUID_ROW,
    DEFAULT_NOT_FLUID_ROW,
    DEFAULT_NOT_PLAIN_CELL_STYLE,
    DEFAULT_PLAIN_CELL_STYLE,
    DEFAULT_PLAIN_CELL_WIDGET_STYLE,
    DEFAULT_ROW_STYLE,
    DEFAULT_VALUE,
    DEFAULT_WIDGET_STYLE,
    FONT_CHOICES,
    FONT_WEIGHT_CHOICES,
    MARGIN_FIELDS,
    PADDING_FIELDS,
    PADDING_PROPERTY_NAMES,
    POSITIVE_SIZE_CHOICES,
    SHADOW_ELEVATION_CHOICES,
    SIZE_CHOICES,
    SIZE_L,
    SIZE_M,
    SIZE_N,
    SIZE_S,
    SIZE_XL,
    SIZE_XS,
    SIZE_XXL,
    SIZE_XXS,
    STYLE_TYPES,
};
