import React, { isValidElement } from 'react';

import { BlockPost } from '@lumapps/widget-post-list/types';

import { useResourcePropsOverride } from '../../../hooks/useResourcePropsOverride';
import { BlockStreamItemByResource } from '../../../types';

export type PostStreamItemProps = BlockStreamItemByResource<'post'>;

/** Generates the props for post blocks in a stream */
const postBlockPropsOverride = (props: BlockPost) => ({
    /** Remove all user actions (edit / move / delete / pin) */
    canEdit: false,
    canMove: false,
    canDelete: false,
    shares: props.shares.map((s) => ({ ...s, canPin: false, canUnshare: false })),
    // force post block share banner to be hidden so we can put the stream's one
    isSharedByBlockAllowed: false,
    // force post block to use a div instead of an article tag since the stream items are already articles
    isAlreadySemantic: true,
});

/**
 * Component to display posts in a stream
 * As they have the correct display we only need to override a few unwanted props and display it.
 */
export const PostStreamItem: React.FC<PostStreamItemProps> = ({ children }) => {
    const postChild = useResourcePropsOverride(children, postBlockPropsOverride);

    return isValidElement(postChild) ? postChild : null;
};
