/* istanbul ignore file */
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { ShareableContent } from '@lumapps/sa-shareable-contents/types';

export enum ShareContentDialogStatuses {
    initial = 'initial',
    loading = 'loading',
    success = 'success',
    failure = 'failure',
}

export interface ShareContentDialogState {
    fetchShareableContentsStatus: ShareContentDialogStatuses;
    shareableContents: { [id: string]: ShareableContent };
    selectedShareableContent: ShareableContent['id'];
}

export const initialState: ShareContentDialogState = {
    fetchShareableContentsStatus: ShareContentDialogStatuses.initial,
    shareableContents: {},
    selectedShareableContent: '',
};

export const { actions, reducer } = createSlice({
    domain: 'share/ShareContentDialog',
    initialState,
    reducers: {
        fetchShareableContents: (state: ShareContentDialogState) => {
            set(state, 'fetchShareableContentsStatus', ShareContentDialogStatuses.loading);
        },
        fetchShareableContentsSuccess: (
            state: ShareContentDialogState,
            action: PayloadAction<Array<ShareableContent>>,
        ) => {
            const shareableContents = action.payload;
            const firstShareableContent = shareableContents[0];

            set(state, 'fetchShareableContentsStatus', ShareContentDialogStatuses.success);
            set(state, 'shareableContents', keyBy(shareableContents, 'id'));
            set(state, 'selectedShareableContent', firstShareableContent.id);
        },
        fetchShareableContentsFailure: () => ({
            ...initialState,
            fetchShareableContentsStatus: ShareContentDialogStatuses.failure,
        }),
        selectShareableContent: (state: ShareContentDialogState, action: PayloadAction<ShareableContent['id']>) => {
            set(state, 'selectedShareableContent', action.payload);
        },
    },
});
