import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiEmail } from '@lumapps/lumx/icons';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { NEWSLETTER } from '../../keys';
import { newsletterRoute } from '../../routes';

export interface NewsletterSettingsMenuProps {
    isAllowed: boolean;
    scope: string;
}

export const NewsletterSettingsMenu: React.FC<NewsletterSettingsMenuProps> = ({ isAllowed, scope }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item icon={mdiEmail} as={RouterLink} to={newsletterRoute} {...get({ element: 'newsletter' })}>
            {translateKey(NEWSLETTER.NEWSLETTER_KEY)}
        </Menu.Item>
    );
};

NewsletterSettingsMenu.displayName = 'NewsletterSettingsMenu';
