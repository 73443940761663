/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';

import { get as getConstants } from '@lumapps/constants';

import { removeBaseSlug } from '../utils';
import { useLocation } from './useLocation';

const constants = getConstants();

/**
 * Use this hook to trigger an action when the route changes.
 * Works with both the legacy angular app and the new react one.
 */
export const useOnNavigation = (callback: () => void, deps?: React.DependencyList) => {
    const { pathname } = useLocation();
    const previousPathname = useRef(pathname);

    const checkPathnameChange = React.useCallback(
        (currentPathName: string) => {
            if (previousPathname.current !== currentPathName) {
                previousPathname.current = currentPathName;
                callback();
            }
        },
        [callback],
    );

    React.useEffect(() => {
        checkPathnameChange(pathname);

        /**
         * When in legacy context, because of the way it is rendered, the call is done too early which makes the check
         * wrong (it still thinks we are in the last url). For this case we do a second check a bit later to make sure
         * it is correctly done with the updated location pathname.
         */
        if (constants.isLegacyContext) {
            setTimeout(() => {
                checkPathnameChange(removeBaseSlug(window.location.pathname));
            }, 50);
        }
    }, [callback, pathname, ...(deps || [])]);
};
