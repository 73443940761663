import { Node } from 'slate';

/**
 * Get the first node which has content and return it as string
 */
export const getFirstContentAsString = (nodes: Node[]) => {
    for (let i = 0; i < nodes.length; i++) {
        const text = Node.string(nodes[i]).trim();
        if (text) {
            return text;
        }
    }
    return '';
};
