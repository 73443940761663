/* eslint-disable no-undef */
import { getAttributes } from '@lumapps/data-attributes';

function UiLanguageController($scope, Translation) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * All the available languages with their tranlated name.
     *
     * @type {Object}
     * @constant
     */
    vm.AVAILABLE_LANGUAGES = {};

    /**
     * The list of all the selectable languages.
     *
     * @type {Array}
     */
    vm.selectableLanguages = [];

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Sort the selectable languages array by alphabetical order.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _alphabetizeSelectableLanguagues() {
        vm.selectableLanguages = _.sortBy(vm.selectableLanguages, (lang) => {
            if (!(vm.AVAILABLE_LANGUAGES[lang] && vm.AVAILABLE_LANGUAGES[lang].name)) {
                vm.AVAILABLE_LANGUAGES[lang] = { name: Translation.translate(`LANG_${lang.toUpperCase()}`) };
            }

            return vm.AVAILABLE_LANGUAGES[lang].name;
        });
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Filter the available languages in the instance lang selector.
     *
     * @param {string} [textFilter] The text filter.
     */
    function filterLangs(textFilter) {
        vm.selectableLanguages = angular.fastCopy(vm.choices);

        if (angular.isDefinedAndFilled(textFilter)) {
            // eslint-disable-next-line no-param-reassign
            textFilter = textFilter.toLowerCase();

            vm.selectableLanguages = vm.selectableLanguages.filter(function filterAvailableLangs(lang) {
                return (
                    _.includes(lang.toLowerCase(), textFilter) ||
                    _.includes(
                        ((vm.AVAILABLE_LANGUAGES[lang] && vm.AVAILABLE_LANGUAGES[lang].name) || '').toLowerCase(),
                        textFilter,
                    )
                );
            });
        }

        _alphabetizeSelectableLanguagues();
    }

    // ///////////////////////////

    vm.filterLangs = filterLangs;
    vm.getLanguageSelectAttributes = getAttributes(vm.dataScope || 'language-select');
    vm.selectAttributes = vm.getLanguageSelectAttributes({ element: 'select' });

    // ///////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    // ///////////////////////////

    /**
     * Watch the choice array to update the selectable languagues accordingly.
     */
    $scope.$watch(
        'vm.choices',
        (newValue, oldValue) => {
            if (newValue === oldValue) {
                return;
            }

            vm.filterLangs();
        },
        true,
    );

    /**
     * When the value is emptied or changed.
     * Fix bad handling of onChange (onChange is called before value is changed...).
     */
    $scope.$watch(
        'vm.model',
        (newValue, oldValue) => {
            if (newValue === oldValue) {
                return;
            }

            vm.ngChange();
        },
        true,
    );

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // Init the selectable languages by get all the available languages of the current instance.
        vm.selectableLanguages = angular.fastCopy(vm.choices);

        // Sort the available languages by alphabetical order.
        _alphabetizeSelectableLanguagues();

        vm.ngChange = vm.ngChange || angular.noop;

        if (angular.isUndefined(vm.allowClear)) {
            vm.allowClear = true;
        }
    }

    init();
}

// ///////////////////////////

/**
 * Select a UI language.
 *
 * @param {boolean}  [allowClear=true] If we can clear the input. True by default.
 * @param {Array}    choices           The available choices.
 * @param {Function} [disabled]        The function which return if the select is disabled or not.
 * @param {string}   [label]           The label of the select.
 * @param {*}        model             The ng-model whe to store the selected language(s).
 * @param {boolean}  [multiple]        Indication if we allow to select multiple values.
 * @param {Function} [ngChange]        The function to excecute after an update of the model.
 */

function UiLanguageDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: UiLanguageController,
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            allowClear: '@?lsAllowClear',
            dataScope: '@lsDataScope',
            choices: '=lsChoices',
            disabled: '=?ngDisabled',
            error: '@?lsError',
            hasError: '=?lsHasError',
            isValid: '=?lsIsValid',
            label: '@lsLabel',
            model: '=ngModel',
            multiple: '@?lsMultiple',
            ngChange: '&?',
        },
        templateUrl: '/client/common/modules/ui-language/views/ui-language.html',
    };
}

// ///////////////////////////

angular.module('Directives').directive('lsUiLanguage', UiLanguageDirective);
