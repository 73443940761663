import { createPlugin } from '@lumapps/wrex/slate/plugin';
import { WrexEditor } from '@lumapps/wrex/types';

import { EmojiEditor } from '../types';
import { isEmojiAllowed } from '../utils/isEmojiAllowed';

export const withEmoji = () =>
    createPlugin<EmojiEditor, WrexEditor<EmojiEditor>>({
        createPluginEditor: (editor) => {
            return {
                isEmojiAllowed: isEmojiAllowed(editor),
            };
        },
    });
