import React from 'react';

import capitalize from 'lodash/capitalize';

import { InlineList, Text, ColorPalette, ColorVariant, Typography, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface InlineReactionsProps {
    /* Number of likes */
    likes: number;
    /* Number of comments */
    comments: number;
    /* Class name for InLine list component */
    className?: string;
    /* Typography for InLine list component */
    typography?: Typography;
    /* Color for InLine list component */
    color?: ColorPalette;
    /* Color variant for InLine list component */
    colorVariant?: ColorVariant;
    /* Current theme */
    theme?: Theme;
}

/**
 * This is not interactive component display quantity likes and comments in line
 * @param InlineReactionsProps
 * @returns InlineReactions
 */
export const InlineReactions: React.FC<InlineReactionsProps> = ({
    theme,
    likes,
    comments,
    className,
    typography = Typography.caption,
    color = theme === Theme.light ? ColorPalette.dark : ColorPalette.light,
    colorVariant = ColorVariant.L2,
    ...props
}) => {
    const { translateKey } = useTranslate();

    const likesInline = `${likes} ${capitalize(translateKey(likes <= 1 ? GLOBAL.LIKE : GLOBAL.LIKES))}`;
    const commentsInline = `${comments} ${capitalize(translateKey(comments <= 1 ? GLOBAL.COMMENT : GLOBAL.COMMENTS))}`;

    return likesInline !== '' && commentsInline !== '' ? (
        <InlineList className={className} typography={typography} color={color} colorVariant={colorVariant} {...props}>
            <Text as="span">{likesInline}</Text>
            <Text as="span">{commentsInline}</Text>
        </InlineList>
    ) : null;
};
