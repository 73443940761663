import React from 'react';

import range from 'lodash/range';

import { MenuItemSkeleton } from './MenuItemSkeleton';
import { BaseSkeletonProps } from './types';

export interface MenuItemsSkeletonProps extends BaseSkeletonProps {
    /**
     * Add icon skeleton to the item skeleton
     */
    hasIcon?: boolean;
    /**
     * Number of item skeletons to display (defaults to 3)
     */
    itemCount?: number;
}

const LENGTHS = ['95%', '85%', '100%'];

/**
 * Wrapper component on `MenuItemSkeleton` to produce 3 item skeleton.
 *
 * @family Menus
 */
export const MenuItemsSkeleton: React.FC<MenuItemsSkeletonProps> = ({ hasIcon, itemCount = 3, loadingMessage }) => (
    <>
        {range(itemCount).map((key) => {
            const width = LENGTHS[key % LENGTHS.length];
            return <MenuItemSkeleton key={key} hasIcon={hasIcon} label={{ width }} loadingMessage={loadingMessage} />;
        })}
    </>
);
