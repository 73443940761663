import React from 'react';

import { FilterHookApi, FilterHookOptions } from '../types';

export type UseSingleSelectFilterOptions<T> = FilterHookOptions<T>;

export type UseSingleSelectFilter<T> = FilterHookApi<T>;

const defaultGetId = <T>(t1: T) => t1;

export const useSingleSelectFilter = <T>(options?: UseSingleSelectFilterOptions<T>): UseSingleSelectFilter<T> => {
    const getId = (options && options.getId) || defaultGetId;

    const [selected, setSelected] = React.useState<T | undefined>(options && options.defaultSelected);
    const [selection, setSelection] = React.useState<T | undefined>(selected);

    const { defaultSelected, hasDynamicDefaultSelected } = options || {};

    /**
     * If the default values change, which could happen if the hook is displayed in a different
     * context, we need to reset the selected values on the internal state.
     */
    React.useEffect(() => {
        if (defaultSelected && hasDynamicDefaultSelected) {
            setSelected(defaultSelected);
        }
    }, [defaultSelected, hasDynamicDefaultSelected]);

    const isInSelection = (t: T) => {
        return selection ? getId(selection) === getId(t) : false;
    };

    const onSelected = (sel: T) => {
        setSelection(sel);
    };

    const onFilter = () => {
        setSelected(selection);

        return selection;
    };

    const onClearSelection = () => {
        setSelection(selected);
    };

    const onClearSelected = () => {
        setSelected(undefined);
        setSelection(undefined);
    };

    const onResetFilters = () => {
        if (options?.defaultSelected) {
            setSelected(options?.defaultSelected);
            setSelection(options?.defaultSelected);
        } else {
            onClearSelected();
        }
    };

    const isSelected = (t: T) => {
        return selected ? getId(selected) === getId(t) : false;
    };

    return {
        onSelected,
        onFilter,
        selected,
        selection,
        onClearSelection,
        isInSelection,
        isSelected,
        onClearSelected,
        onResetFilters,
    };
};
