import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { NOTIFICATION_PARAMS } from '../constants';
import { LastNotification, NotificationGroup, NotificationQuery } from '../types';

const notificationApi = new BaseApi({ path: 'notification' });

const list = (params: NotificationQuery = {}) => {
    return notificationApi.postWithPriority('list', { ...NOTIFICATION_PARAMS, ...params }, PRIORITY.LOW);
};

const getChildNotifications = async (params: NotificationQuery): Promise<NotificationGroup[]> => {
    const response = await notificationApi.postCacheFirst(
        'list',
        { ...NOTIFICATION_PARAMS, ...params },
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
    );

    const { items = [] } = response.data;

    return items;
};

const setAsRead = (notificationId: string) => {
    return notificationApi.post('setRead', { uid: notificationId });
};

const setAllAsRead = () => {
    return notificationApi.post('setAllAsRead', {});
};

const setAsUnread = (notificationId: string) => {
    return notificationApi.post('setUnread', { uid: notificationId });
};

const deleteNotification = (notificationId: string) => {
    return notificationApi.delete('delete', { params: { uid: notificationId } });
};

const deleteAll = () => {
    return notificationApi.delete('deleteAll', {});
};

const checkLast = () => {
    return notificationApi.getWithPriority<LastNotification>('checkLast', PRIORITY.LOW);
};

const countUnread = async (): Promise<number> => {
    const { data } = await notificationApi.getWithPriority<{ unreadNotificationsCount: string }>(
        'countUnread',
        PRIORITY.LOW,
    );
    return parseInt(data.unreadNotificationsCount, 10);
};

export {
    notificationApi,
    list,
    setAsRead,
    setAsUnread,
    setAllAsRead,
    deleteNotification,
    deleteAll,
    getChildNotifications,
    checkLast,
    countUnread,
};
