/* istanbul ignore file */
import { User } from '@lumapps/user/types';
import { getCookie } from '@lumapps/utils/cookies/cookie_utils';

import { ALLOW_CONNECTED_AS_ANALYTICS, CLIENT_DATA_LAYER_NAME, GA4_TAG_NAME, GA_TAG_NAME } from '../../constants';

export function scriptLoaded(scriptSrc: string) {
    const scripts: NodeListOf<HTMLScriptElement> = document.querySelectorAll('script[src]');
    let foundScript = false;
    scripts.forEach((s) => {
        if (s.src === scriptSrc) {
            foundScript = true;
        }
    });
    return foundScript;
}

export const initializeGA3 = () => {
    const src = 'https://www.google-analytics.com/analytics.js';

    // Inject ga3 script
    if (!scriptLoaded(src)) {
        /* eslint-disable */
        (function ga3(i: any, s: any, o: any, g: any, r: any, a?: any, m?: any) {
            i['GoogleAnalyticsObject'] = r;
            (i[r] =
                i[r] ||
                function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
                // @ts-ignore
                (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', src, GA_TAG_NAME);
        /* eslint-enable */
    }
};

export const initializeGA4 = (id: string) => {
    const dataLayerName = CLIENT_DATA_LAYER_NAME;
    const gtagName = GA4_TAG_NAME;

    const src = `https://www.googletagmanager.com/gtag/js?id=${id}&l=${dataLayerName}`;

    // Inject gtag js
    if (!scriptLoaded(src)) {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        document.body.appendChild(script);
    }

    /* Set gtag and datalayer */
    if (!window[dataLayerName]) {
        window[dataLayerName] = window[dataLayerName] || [];
    }
    if (!window[gtagName]) {
        window[gtagName] = function gtag() {
            // eslint-disable-next-line prefer-rest-params
            window[dataLayerName].push(arguments);
        };
    }
    window[gtagName]('js', new Date());
    window[gtagName]('config', id, { send_page_view: false });
};

export const initializeGTM = (id: string, dataLayerName: string) => {
    const src = `https://www.googletagmanager.com/gtm.js?id=${id}&l=${dataLayerName}`;

    // inject GA4 script
    if (!scriptLoaded(src)) {
        /* eslint-disable */
        (function (w: any, d: any, s: any, l: any, i: any) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0];
            var j = d.createElement(s);
            j.async = true;
            j.src = src;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', dataLayerName, id);
        /* eslint-enable */
    }
};

const loadGAScripts = ({ userId, instanceGA }: { userId: string; instanceGA?: string }) => {
    /*
     *  Google analytics
     */
    const isInstanceGAConfigured = Boolean(instanceGA);
    const instanceGAIsGA4 = Boolean(instanceGA?.startsWith('G-'));
    if (instanceGAIsGA4 && instanceGA) {
        initializeGA4(instanceGA);
    } else {
        if (!window[GA_TAG_NAME] && isInstanceGAConfigured) {
            initializeGA3();
        }
        if (window[GA_TAG_NAME]) {
            const params = { userId };

            if (isInstanceGAConfigured) {
                window[GA_TAG_NAME]('create', instanceGA, 'auto', undefined, params);
            }
        }
    }
};

const loadGTMScripts = ({ instanceGTM }: { instanceGTM?: string }) => {
    /*
     *  Google Tag Manager
     */
    const isInstanceGTMConfigured = instanceGTM && Boolean(instanceGTM);
    if (isInstanceGTMConfigured) {
        initializeGTM(instanceGTM || '', CLIENT_DATA_LAYER_NAME);
    }
};

/**
 * Load Google Tag Manager script for Lumapps statistics if enabled (IS_LUMAPPS_INTERNAL_GA_ENABLED flag).
 *   Also load Google Tag Manager script for client if they have configured
 *   it on the current site.
 * Load Google Analytics script for Lumapps statistics if enabled (IS_LUMAPPS_INTERNAL_GA_ENABLED flag).
 *   Also load Google Analytics script for client if they have configured
 *   it on the current site and it's not already loaded.
 */
export const loadGAAndGTMScripts = ({
    user,
    instanceGTM,
    instanceGA,
    isUsingAnalyticsMocks = false,
}: {
    user?: User;
    instanceGTM: string;
    instanceGA: string;
    isUsingAnalyticsMocks?: boolean;
}) => {
    if (isUsingAnalyticsMocks) {
        window[CLIENT_DATA_LAYER_NAME] = window[CLIENT_DATA_LAYER_NAME] || [];
        return;
    }

    /**
     * We do not check the hasAcceptedCookies value here,
     * as it's already done in the useGoogleAnalyticsAndTagManager hook
     */
    if (user && (!user.isConnectedAs || getCookie(ALLOW_CONNECTED_AS_ANALYTICS))) {
        const userId = user?.id;

        loadGTMScripts({ instanceGTM });

        loadGAScripts({ userId, instanceGA });
    }
};
