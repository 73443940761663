import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL } from '@lumapps/translations';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';
import { BaseSelection, Range, ReactEditor, Transforms, useSlate } from '@lumapps/wrex/slate';
import type { Wrex } from '@lumapps/wrex/types';

import { getCompletions } from '../api';

export interface UseRefineContentOutput {
    newSuggestions?: string[];
    isRefiningContent: boolean;
    isPopoverOpen: boolean;
    onRefineContent: (refinement: string, selectedText?: string) => Promise<void>;
    onInsertNewContent: (currentSelection?: BaseSelection, index?: number) => void;
    onCancelRefinement: () => void;
    setNewSuggestions: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setInitialContent: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const useRefineContentDialog = (): UseRefineContentOutput => {
    const editor = useSlate() as ReactEditor;

    const [isRefiningContent, setIsRefiningContent] = React.useState(false);
    const [newSuggestions, setNewSuggestions] = React.useState<string[] | undefined>(undefined);
    const [initialContent, setInitialContent] = React.useState<string | undefined>(undefined);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const { error } = useNotification();

    const onRefineContent = async (refinement: string, selectedText?: string) => {
        if (!selectedText) {
            error({ translate: GLOBAL.GENERIC_ERROR });
            return;
        }

        if (!initialContent) {
            setInitialContent(selectedText);
        }

        setIsRefiningContent(true);
        setIsPopoverOpen(true);

        // Compute new prompt
        const newPrompt = () => {
            /**
             * Prompt format:
             * <General instructions>
             * Initial content:
             * <Initial content>
             * Previous refinements:
             * ...
             * - <refinement 3>
             * - <refinement 2>
             * - <refinement 1>
             * Additional instructions: <refinement>
             * Text to refine:
             * <selectedText>
             */

            return `Help the user to rewrite his text, and return only the refined result, no explanation.
You are also provided with the initial text and the previous refinements.
Don't change the language of the text, keep it in the initial language.
Answer with markdown format.
If you see @[] in the sentence, you can move his place in the sentence but you have to keep the structure (e.g. @[John Doe:10]).
If there is not enough text to refine, just output the given text.

${initialContent ? "\n######## First version, before refining (user's initial content)" : ''}
${initialContent || ''}

${
    newSuggestions && newSuggestions.length > 1
        ? newSuggestions
              .slice(0, -1)
              .reverse()
              .map((suggestion, index) => `######## Refinement ${newSuggestions.length - index - 1}\n${suggestion}`)
              .join('\n')
        : ''
}

######## Additional instructions: ${refinement}

######## Text to refine
${selectedText}
`;
        };

        try {
            const { data } = await getCompletions({ prompt: newPrompt() });
            setNewSuggestions((prev) => (prev ? [...prev, data.completions] : [data.completions]));
        } catch (e) {
            error({ translate: GLOBAL.GENERIC_ERROR });
            setIsPopoverOpen(false);
        }

        setIsRefiningContent(false);
    };

    const onInsertNewContent = (currentSelection?: BaseSelection, index?: number) => {
        if (newSuggestions && !isEmpty(newSuggestions) && currentSelection && index !== undefined) {
            Transforms.insertNodes(editor, fromMarkdown(newSuggestions[index]), {
                at: currentSelection,
                select: true,
            });

            // Remove inserted empty paragraph
            Transforms.removeNodes(editor, {
                at: Range.start(currentSelection),
                match: (node) => {
                    if (isParagraph(node)) {
                        return isEmpty((node.children[0] as Wrex.Text)?.text);
                    }
                    return false;
                },
            });

            // Re-focus in the editor to select content
            ReactEditor.focus(editor);

            if (editor.selection) {
                // Starting point of the selection depending on which comes first in the document order
                const startPoint = Range.start(currentSelection);
                const endPoint = Range.end(editor?.selection);

                // Force select the new inserted paragraphs
                Transforms.select(editor, {
                    anchor: startPoint,
                    focus: endPoint,
                });
            }
        } else {
            error({ translate: GLOBAL.GENERIC_ERROR });
        }

        setIsPopoverOpen(false);
    };

    const onCancelRefinement = () => {
        setNewSuggestions(undefined);
        setIsPopoverOpen(false);
        setInitialContent(undefined);
    };

    return {
        newSuggestions,
        isRefiningContent,
        isPopoverOpen,
        onRefineContent,
        onInsertNewContent,
        onCancelRefinement,
        setNewSuggestions,
        setIsPopoverOpen,
        setInitialContent,
    };
};
