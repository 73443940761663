import React, { AnchorHTMLAttributes } from 'react';

import { RouterData } from '../../ducks/types';
import { UseLinkOptions, useLinkProps } from '../../hooks/useLinkProps';
import { Route } from '../../types';

export interface LinkProps {
    /**
     * Target route.
     */
    to: Route;
    /**
     * <a> target attribute.
     */
    target?: AnchorHTMLAttributes<any>['target'];
    /** callback on click */
    onRedirect?: UseLinkOptions['onRedirect'];
    /** Data to set in the router to be used for navigation purpose  */
    routerData?: RouterData;
    /**
     * By default, focus will be restored at the top of the page after a redirection.
     * If you with the focus to remain on the link, set this at true.
     */
    keepFocus?: boolean;
    [key: string]: any;
}

/**
 * Similar to react router Link component but it can be used in both legacy and new app context.
 *
 * Can be used with LumX component using the `linkAs` prop.
 * @example
 *  <IconButton icon={mdiPlayOutline} linkAs={Link} to={playRoute} />
 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { to, target, children, onRedirect, routerData, onClick, keepFocus, ...forwardedProps } = props;
    const linkProps = useLinkProps({
        route: to,
        target,
        onClick,
        openInNewTab: target === '_blank',
        onRedirect,
        routerData,
        keepFocus,
    });

    return (
        <a ref={ref} {...forwardedProps} {...linkProps}>
            {children}
        </a>
    );
});
Link.displayName = 'Link';
