/* eslint-disable lumapps/file-format */
import loFind from 'lodash/find';

// eslint-disable-next-line valid-jsdoc
/**
 * Service to get document providers.
 */
function DocumentProviderService(DocumentProviderFactory, Instance, LumsitesBaseService) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(DocumentProviderFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    let _initPromise, _list;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Fetch providers list.
     *
     * @return {Promise} Providers list fetch promise.
     */
    function _fetchProvidersList() {
        return DocumentProviderFactory.list({
            instanceId: Instance.getCurrentInstanceId(),
        }).$promise;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Fetch and return a copy of document providers list.
     *
     * @return {Promise} Promise resolved when the list has been fetch.
     */
    async function getList() {
        if (!angular.isDefinedAndFilled(_list)) {
            if (!_initPromise) {
                _initPromise = _fetchProvidersList();
            }

            const { providers } = await _initPromise;
            _list = providers;
        }

        return angular.fastCopy(_list);
    }

    /**
     * Get a provider from it's id.
     *
     * @param  {string} providerId Provider id.
     * @return {Object} Provider.
     */
    async function getProvider(providerId) {
        return loFind(await this.getList(), { id: providerId });
    }

    /**
     * Get categories of a provider.
     *
     * @param  {string} providerId Provider id.
     * @return {Array}  Provider categories.
     */
    async function getProviderCategories(providerId) {
        const provider = await this.getProvider(providerId);

        if (!provider) {
            return undefined;
        }

        return provider.categories;
    }

    /**
     * Get Provider category from id.
     *
     * @param  {string}  providerId Provider id to select category from.
     * @param  {string}  categoryId Category id to get.
     * @return {Promise} Category.
     */
    async function getProviderCategory(providerId, categoryId) {
        const categories = await this.getProviderCategories(providerId);

        if (!categories) {
            return undefined;
        }

        return loFind(categories, { id: categoryId });
    }

    /**
     * Get provider authorized actions (create folder, upload file, create spreadsheets, etc.).
     *
     * @param  {string}  providerId Provider id.
     * @return {Promise} Provider actions.
     */
    async function getProviderAuthorizedActions(providerId) {
        const provider = await this.getProvider(providerId);

        if (!provider) {
            return undefined;
        }

        return provider.driveResourceAuthorized;
    }

    /////////////////////////////

    service.getList = getList;
    service.getProvider = getProvider;
    service.getProviderCategories = getProviderCategories;
    service.getProviderCategory = getProviderCategory;
    service.getProviderAuthorizedActions = getProviderAuthorizedActions;

    /////////////////////////////

    return service;
}

angular.module('Services').service('DocumentProvider', DocumentProviderService);

export { DocumentProviderService };
