/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { UploadImageDialogProps } from './UploadImageDialog';

const LazyUploadImageDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'upload-image-dialog' */
            './UploadImageDialog'
        ),
);

export const UploadImageDialog: React.FC<UploadImageDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyUploadImageDialog {...props} />
        </DelayedSuspense>
    );
};
