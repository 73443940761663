import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

export const useListWidgetSelection = (
    currentSelectedItemsIds: string[] | undefined,
    shouldFetchItems: boolean,
    fetchAPI: any,
) => {
    const [itemListStatus, setItemListStatus] = useState(BaseLoadingStatus.initial);
    const [selectedItemsIds, setSelectedItemsIds] = useState(currentSelectedItemsIds || []);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const lang = useSelector(currentLanguageSelector);
    const onItemRemove = (itemId: string) => {
        setSelectedItems(selectedItems?.filter((item) => item.id !== itemId));
        setSelectedItemsIds(selectedItemsIds?.filter((id) => id !== itemId));
    };

    const onItemsSelectionConfirm = (items: any[]) => {
        setSelectedItems([...selectedItems, ...items]);
        setSelectedItemsIds([...selectedItemsIds, ...items.map((item) => item.id)]);
    };

    const fetchSelectedItems = React.useCallback(async () => {
        setItemListStatus(BaseLoadingStatus.loading);
        try {
            const response = await fetchAPI({
                ids: selectedItemsIds,
                lang,
            });
            const items = response.items || response.data.items;

            setSelectedItems(items);
            setItemListStatus(BaseLoadingStatus.idle);
        } catch (error) {
            setItemListStatus(BaseLoadingStatus.error);
        }
    }, [fetchAPI, lang, selectedItemsIds]);

    React.useEffect(() => {
        if (
            !isEmpty(selectedItemsIds) &&
            shouldFetchItems &&
            !isEqual(
                selectedItemsIds,
                selectedItems.map((item) => item.id),
            )
        ) {
            fetchSelectedItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchSelectedItems, selectedItemsIds, shouldFetchItems]);

    return { itemListStatus, selectedItemsIds, selectedItems, onItemRemove, onItemsSelectionConfirm };
};
