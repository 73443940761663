import type { VideoKind } from '@lumapps/play/api/types';

export const UPLOADER_ACCEPT_IMAGE_TYPES = 'image/*';
export const UPLOADER_ACCEPT_MIME_TYPES = 'video/*';
export const UPLOADER_ACCEPT_VIDEO_EXTENSIONS = ['MP4', 'MPG', 'AVI', 'MOV', 'WMV', 'MKV'];
export const VIDEO_MAX_DESCRIPTION_LENGTH = 20000;
export const VIDEO_MAX_TITLE_LENGTH = 100;
export const VIDEO_DEFAULT_KINDS: VideoKind[] = ['standard'];

export const VIDEO_SIDEBAR_ITEMS = {
    general: {
        details: 'details',
        permissions: 'permissions',
    },
    interactions: {
        subtitles: 'subtitles',
        chapters: 'chapters',
        cuePoints: 'cuePoints',
    },
    analytics: {},
};
