import { useContext } from 'react';

import { AriaIdsContext } from '../context/AriaIdsContext';
import { formatAriaIds } from '../utils/formatAriaIds';

/**
 * Retrieve the currently stored ids to use for aria properties.
 */
export const useAriaIds = () => {
    const ariaIds = useContext(AriaIdsContext) || {};
    if (!ariaIds.label && ariaIds.description) {
        return undefined;
    }

    return {
        'aria-labelledby': formatAriaIds(ariaIds.label),
        'aria-describedby': formatAriaIds(ariaIds.description),
    };
};
