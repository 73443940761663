import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { SEARCH_SOURCE_TYPES } from '@lumapps/search/keys/sourceTypes';
import { BaseSearchResult, CommunitySearchResult } from '@lumapps/search/types';
import { addBaseSlugToSearchUrl } from '@lumapps/search/utils';

import { CommunityResultProps } from './types';

const props = (result: BaseSearchResult, baseProps: any, options: any): CommunityResultProps => {
    const { instanceId, translateKey } = options;
    const { name, snippet, onClick, flag, quickViewUrl, onQuickViewClick, tagz } = baseProps;
    const { community } = result as { community: CommunitySearchResult };
    const { instanceDetails } = community;

    return {
        name,
        snippet,
        flag,
        quickViewUrl,
        onQuickViewClick,
        tagz,
        instanceDetails,
        thumbnail: community.thumbnail,
        shouldOpenInNewTab: instanceId !== instanceDetails.id,
        url: addBaseSlugToSearchUrl(community.url),
        onClick,
        position: result.position,
        id: community.uid,
        iconName: 'google-circles-extended',
        kind: translateKey(SEARCH_SOURCE_TYPES.COMMUNITY),
        privacy: community.privacy,
        type: SEARCH_RESULT_TYPES.COMMUNITY,
    };
};

export { props };
