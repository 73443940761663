import React from 'react';

import { Combobox } from '@lumapps/combobox/components/Combobox';
import { ComboboxInputProps } from '@lumapps/combobox/components/ComboboxInput';
import { useCombobox } from '@lumapps/combobox/hooks/useCombobox';

import { SEARCHBOX_FOOTER_ID } from '../../constant';

/**
 * The input element of the search box.
 */
export const SearchboxInput = (props: ComboboxInputProps) => {
    const { isOpen, optionsLength } = useCombobox();
    /** Link the input to the searchbox footer so it can be read on display. */
    return (
        <Combobox.Input
            {...props}
            hasClearButton
            aria-describedby={isOpen && optionsLength > 0 && SEARCHBOX_FOOTER_ID}
        />
    );
};
