import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { htmlDecode } from '@lumapps/utils/string/htmlDecode';

/////////////////////////////

function CapitalizeFilter() {
    'ngInject';

    /**
     * Remove the dashes representing nesting level from a string.
     *
     * @param  {string} string The string we want to escape.
     * @return {string} The cleaned up string.
     */
    return (string) => capitalize(string);
}

angular.module('Filters').filter('capitalize', CapitalizeFilter);

/////////////////////////////

function CleanupLevelFilter() {
    'ngInject';

    /**
     * Remove the dashes representing nesting level from a string.
     *
     * @param  {string} string The string we want to escape.
     * @return {string} The cleaned up string.
     */
    return function cleanupLevel(string) {
        return string.replace(/^[-]{2,} /, '');
    };
}

angular.module('Filters').filter('cleanupLevel', CleanupLevelFilter);

/////////////////////////////

function DecodeHTMLEntitiesFilter() {
    'ngInject';

    /**
     * Transform HTML entities to normal chars.
     *
     * @param  {string} string The string we want to decode.
     * @return {string} The decoded string.
     */
    return function decodeHTMLEntities(string) {
        const txt = document.createElement('textarea');
        txt.innerHTML = string;

        return txt.value;
    };
}

angular.module('Filters').filter('decodeHTMLEntities', DecodeHTMLEntitiesFilter);

/////////////////////////////

function EscapeScriptFilter(Utils) {
    'ngInject';

    /**
     * EscapeScript Filter.
     * Escape the "</script>" tag from an HTML string.
     *
     * @param  {string} string The HTML string in which to escape the "</script>" tag.
     * @return {string} The escaped HTML string.
     */
    return function escapeScript(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string;
        }

        return Utils.escapeHtmlTags(string, 'script');
    };
}

angular.module('Filters').filter('escapeScript', EscapeScriptFilter);

/////////////////////////////

function EscapeTagsFilter(Utils) {
    'ngInject';

    /**
     * EscapeTags Filter.
     * Escape the HTML tags from an HTML string.
     *
     * @param  {string} string The HTML string in which to escape the tags.
     * @return {string} The escaped HTML string.
     */
    return function escapeTags(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string;
        }

        return Utils.escapeHtmlTags(string);
    };
}

angular.module('Filters').filter('escapeTags', EscapeTagsFilter);

/////////////////////////////

function FilterObjectByFilter(Translation, Utils) {
    'ngInject';

    /**
     * FilterObjectBy Filter.
     * Advanced filtering for objects with translation, customisable case sensitivity and RegExp support.
     *
     * @param  {Object}         items                 The array of object to filter.
     * @param  {Array|string}   properties            An array (or a string if only one property) of properties upon
     *                                                which we're filtering.
     * @param  {Array|string}   values                An array (or string if only one value) to which to filters the
     *                                                corresponding property.
     *                                                Order of properties and values are important: property[2] will
     *                                                be filtered with value[2].
     *                                                If only one value is given, the same value will be used for
     *                                                every properties.
     * @param  {boolean}        [exact=false]         Indicates if we want to use a RegExp or an exact match for
     *                                                string value.
     * @param  {boolean}        [caseSensitive=false] Indicates if we want the comparaison of string to be case
     *                                                sensitive or not.
     * @param  {boolean|string} [translated=false]    Indicates if we want to translate the content or not or a
     *                                                a token to prepend the value to test with.
     * @param  {string}         [join='AND']          The type of join between each condition.
     *                                                Possible values are "AND" or "OR".
     * @return {Array}          The filtered array of objects.
     */
    return function filterObjectBy(items, properties, values, exact, caseSensitive, translated, join) {
        if (angular.isUndefinedOrEmpty(properties) || angular.isUndefinedOrEmpty(values)) {
            return items;
        }
        properties = angular.isArray(properties) ? properties : [properties];

        if (!angular.isArray(values)) {
            const globalValue = angular.copy(values);

            values = [];
            // eslint-disable-next-line lumapps/angular-foreach
            for (let i = 0, propertiesLen = properties.length; i < propertiesLen; i++) {
                values[i] = globalValue;
            }
        }

        join = angular.isUndefined(join) || (join !== 'AND' && join !== 'OR') ? 'AND' : join;

        const filtered = [];
        angular.forEach(items, function filterObjectByForEachItems(item) {
            let pushIt = join !== 'OR';

            for (let j = 0, len = properties.length; j < len; j++) {
                const property = properties[j];

                if (angular.isDefinedAndFilled(property) && angular.isString(property)) {
                    let value = angular.isString(values[j]) && !caseSensitive ? values[j].toLowerCase() : values[j];
                    value = Utils.removeAccentFromString(value);

                    if (angular.isDefined(value)) {
                        let valueToTest = angular.copy(item[property]);

                        const translationToken =
                            angular.isDefined(translated) && angular.isString(translated)
                                ? translated + valueToTest
                                : valueToTest;

                        valueToTest =
                            translated && angular.isDefined(translationToken) && angular.isString(translationToken)
                                ? Translation.translate(translationToken.toUpperCase())
                                : valueToTest;
                        valueToTest =
                            angular.isString(valueToTest) && !caseSensitive ? valueToTest.toLowerCase() : valueToTest;

                        valueToTest = Utils.removeAccentFromString(valueToTest);

                        if (angular.isDefined(valueToTest)) {
                            const flags = caseSensitive ? 'g' : 'gi';
                            let regexp;

                            try {
                                regexp = new RegExp(value, flags);
                            } catch (exception) {
                                // Nothing to do here.
                            }

                            if (join === 'OR') {
                                pushIt =
                                    exact ||
                                    !angular.isString(valueToTest) ||
                                    angular.isUndefinedOrEmpty(valueToTest) ||
                                    angular.isUndefined(regexp)
                                        ? valueToTest === value
                                        : valueToTest.match(regexp);
                            } else {
                                pushIt =
                                    exact ||
                                    !angular.isString(valueToTest) ||
                                    angular.isUndefinedOrEmpty(valueToTest) ||
                                    angular.isUndefined(regexp)
                                        ? pushIt && valueToTest === value
                                        : pushIt && valueToTest.match(regexp);
                            }
                        }

                        if ((!pushIt && join === 'AND') || (pushIt && join === 'OR')) {
                            break;
                        }
                    }
                }
            }

            if (pushIt) {
                filtered.push(item);
            }
        });

        return filtered;
    };
}

angular.module('Filters').filter('filterObjectBy', FilterObjectByFilter);

/////////////////////////////

function HumanizeFilter() {
    'ngInject';

    /**
     * Humanize Filter.
     * Only capitalize the first word of a sentence.
     * `Foo Bar` will become `Foo bar`.
     *
     * @param  {string} string The text we want to humanize.
     * @return {string} The humanized text.
     */
    return function humanize(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string;
        }

        return capitalize(
            snakeCase(string)
                .replace(/_id$/, '')
                .replace(/_/g, ' ')
                .trim(),
        );
    };
}

angular.module('Filters').filter('humanize', HumanizeFilter);

/////////////////////////////

function NewLineToBrFilter() {
    'ngInject';

    /**
     * NewLineToBr Filter.
     * Replace new line characters by <br/> tag.
     *
     * @param  {string} string The text where we want to replace new line characters.
     * @return {string} The converted text.
     */
    return function nl2br(string) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string;
        }

        return string.replace(/\n/g, '<br>');
    };
}

angular.module('Filters').filter('nl2br', NewLineToBrFilter);

/////////////////////////////

function RangeFilter() {
    'ngInject';

    /**
     * Range Filter.
     * Create a range, an array of incrementing number (starting from 0) of a given size.
     *
     * @param  {Array}  input The array in which create the range.
     * @param  {number} total The size of the range.
     * @return {Array}  The range.
     */
    return function range(input, total) {
        if (angular.isUndefined(input)) {
            input = [];
        }

        total = parseInt(total, 10);

        for (let i = 0; i < total; i++) {
            input.push(i);
        }

        return input;
    };
}

angular.module('Filters').filter('range', RangeFilter);

/////////////////////////////

function SliceFilter() {
    'ngInject';

    /**
     * Slice Filter.
     * Get a slice of the given array.
     *
     * @param  {Array}  array The array we want to slice.
     * @param  {number} start The beginning of the slice.
     * @param  {number} [end] The end of the slice
     * @return {Array}  The sliced array.
     */
    return function slice(array, start, end) {
        return (array || []).slice(start, end);
    };
}

angular.module('Filters').filter('slice', SliceFilter);

/////////////////////////////

function SortLatinFilter(Translation, Utils) {
    'ngInject';

    /**
     * FilterAccentAlpha Filter.
     * Sort an array of objects on a property without bothering about accent.
     *
     * @param  {Array}   items              The array of objects to sort.
     * @param  {string}  property           The property to use to sort.
     * @param  {boolean} [translated=false] Indicates if we want to translate the value of the property before
     *                                      sorting or not.
     * @param  {boolean} [doFilter=true]    Indicates of we want to skip sorting or not.
     * @return {Array}   The sorted array of objects.
     */
    return function sortLatin(items, property, translated, doFilter) {
        if (angular.isUndefinedOrEmpty(property) || (angular.isDefined(doFilter) && !doFilter)) {
            return items;
        }

        return sortBy(items, (item) => {
            if (angular.isUndefined(item) || angular.isUndefinedOrEmpty(item[property])) {
                return '';
            }

            let valueToTest = angular.copy(item[property]);

            const translationToken =
                angular.isDefined(translated) && angular.isString(translated) ? translated + valueToTest : valueToTest;

            valueToTest = translated ? Translation.translate(translationToken) : valueToTest;

            return Utils.removeAccentFromString(valueToTest);
        });
    };
}

angular.module('Filters').filter('sortLatin', SortLatinFilter);

/////////////////////////////

function SafeAngularFilter() {
    'ngInject';

    /**
     * SafeAngular Filter.
     * Remove double curly brace (`{{ }}`) to avoid unexpected interpolation.
     *
     * @param  {string}  string The string to clean from AngularJS interpolation.
     * @param  {boolean} remove Indicates if you want to remove the double curly braces instead of converting them
     *                          to HTML entities.
     * @return {string}  The cleaned string.
     */
    return function safeAngular(string, remove) {
        if (angular.isUndefinedOrEmpty(string) || !angular.isString(string)) {
            return string || '';
        }

        return string
            .replace(/\{\{/, remove ? '' : '&lbrace;&lbrace;')
            .replace(/\}\}/, remove ? '' : '&rbrace;&rbrace;');
    };
}

angular.module('Filters').filter('safeAngular', SafeAngularFilter);

/////////////////////////////

function StripTagsFilter(Utils) {
    'ngInject';

    /**
     * StripTags Filter.
     * Remove HTML tags from a string.
     *
     * @param  {string}  string               The string to strip from HTML tags.
     * @param  {boolean} [stripComments=true] Indicates if we also want to strip the comments.
     * @param  {boolean} [br2nl=true]         Indicates if we want to replace <br> tags by newlines.
     * @param  {boolean} [p2nl=true]          Indicates if we want to replace closing </p> tags by newlines.
     * @return {string}  The stripped string.
     */
    return function stripTags(string, stripComments, br2nl, p2nl) {
        if (angular.isUndefinedOrEmpty(string)) {
            return string || '';
        }

        return Utils.stripTags(string, stripComments, br2nl, p2nl);
    };
}

angular.module('Filters').filter('stripTags', StripTagsFilter);

/////////////////////////////

function StripMarkdownFilter(LsTextEditorMarkdown) {
    'ngInject';

    /**
     * StripMarkdown Filter.
     * Remove markdown tags from a string.
     *
     * @param  {string}  string The string to strip from markdown tags.
     * @param  {boolean} enable Indicates if we want to enable the filter or not.
     * @return {string}  The stripped string.
     */
    return function stripMarkdown(string, enable) {
        if (angular.isUndefinedOrEmpty(string) || (angular.isDefined(enable) && !enable)) {
            return string || '';
        }

        return LsTextEditorMarkdown.stripMarkdown(string);
    };
}

angular.module('Filters').filter('stripMarkdown', StripMarkdownFilter);

/////////////////////////////

function TruncateFilter() {
    'ngInject';

    /**
     * Truncate Filter.
     * Truncate a string to a given length.
     *
     * @param  {string}  string                 The string to truncate.
     * @param  {number}  [len=200]              The truncation length.
     * @param  {string}  [truncation="..."]     The string to add to indicate that the string has been truncated.
     * @param  {boolean} [useWordBoundary=true] Indicate if we want to remove the truncated word.
     * @return {string}  The truncated string.
     */
    return function truncate(string, len, truncation, useWordBoundary) {
        if (angular.isDefinedAndFilled(string)) {
            if (parseInt(len, 10) === 0) {
                return string;
            }

            // eslint-disable-next-line no-magic-numbers
            len = len || 200;

            truncation = angular.isDefined(truncation) ? truncation : '...';
            useWordBoundary = angular.isUndefined(useWordBoundary) ? true : useWordBoundary;

            // Decode string for foreign languages e.g. encoded chinese
            string = htmlDecode(string);

            if (string.length <= len) {
                return string;
            }

            let truncatedString = string.substr(0, len).replace(/\n/g, ' ');

            // if we use word boundary, find the last index of space to truncate.
            // If no space is found (e.g. in chinese text), simply return the truncated string
            if (useWordBoundary) {
                const lastSpace = truncatedString.lastIndexOf(' ');
                if (lastSpace > 0) {
                    truncatedString = truncatedString.substr(0, lastSpace);
                }
            }

            return truncatedString + truncation;
        }

        return '';
    };
}

angular.module('Filters').filter('truncate', TruncateFilter);

/////////////////////////////

function TrustFilter($sce) {
    'ngInject';

    /**
     * Trust Filter.
     * Allow to display any HTML (<script>, <a>, ...) via Angular (ng-bind, ...).
     * Be cautious: it's quite unsafe!
     *
     * @param  {string} string The HTML string to trust.
     * @return {string} The trusted HTML.
     */
    return function trust(string) {
        return angular.isString(string) && angular.isDefinedAndFilled(string) ? $sce.trustAsHtml(string) : string;
    };
}

angular.module('Filters').filter('trust', TrustFilter);

/////////////////////////////

function TrustSrcFilter($sce) {
    'ngInject';

    /**
     * TrustSrc Filter.
     * Allow to display any images via Angular.
     * Be cautious: it's quite unsafe !
     *
     * @param  {string} src The image source to trust.
     * @return {string} The trusted source.
     */
    return function trustSrc(src) {
        return $sce.trustAsResourceUrl(src);
    };
}

angular.module('Filters').filter('trustSrc', TrustSrcFilter);

/////////////////////////////

// eslint-disable-next-line require-jsdoc-except/require-jsdoc
function Reverse() {
    'ngInject';

    /**
     * Reverse Filter.
     * Reverse the passed list.
     *
     * @param  {Array} items The list to reverse.
     * @return {Array} The reversed list.
     */
    return function reverse(items) {
        // Reverse modifies the array instead of creating a new one, so use slice instead.
        return items.slice().reverse();
    };
}

angular.module('Filters').filter('reverse', Reverse);

/////////////////////////////

function SearchWidgetFilter(Translation, Utils, InitialSettings) {
    'ngInject';

    /**
     * Add static tag translations for some widgets.
     * The tags are used in order to improve the search.
     * */
    const widgetTagsTranslations = {
        [InitialSettings.WIDGET_TYPES.IMAGE_GALLERY]: {
            en: 'image gallery,medias',
            fr: 'galerie d\'images,médias'
        },
        [InitialSettings.WIDGET_TYPES.FILE_LIST]: {
            en: 'drive,file list,files list,file management,files management,medias',
            fr: 'drive,gestion de fichiers,médias,liste de fichiers,listes de fichiers'
        }
    }

    /**
     * SearchWidget Filter.
     * Filter to search widgets (widget selection screen) using their tags instead of types.
     *
     * @param  {Object} widgets     The array of object to filter.
     * @param  {string} searchValue Value searched by user.
     * @return {Array}  The filtered array of objects.
     */
    return (widgets, searchValue) => {
        if (angular.isUndefinedOrEmpty(searchValue)) {
            return widgets;
        }

        searchValue = Utils.removeAccentFromString(searchValue.toLowerCase());

        return widgets.reduce((acc, widget) => {
            // Here we are using the partnerId property as a marker to identify a Marketplace extension.
            if (widget.partnerId) {
                const name = Utils.removeAccentFromString(Translation.translate(widget.name).toLowerCase());
                const isSearchValueInNameOfMarketplaceExtension = name.includes(searchValue);

                const desc = Utils.removeAccentFromString(Translation.translate(widget.description).toLowerCase());
                const isSearchValueInDescOfMarketplaceExtension = desc.includes(searchValue);

                if (isSearchValueInNameOfMarketplaceExtension || isSearchValueInDescOfMarketplaceExtension) {
                    return [...acc, widget];
                }

                return acc
            }

            // Get widget properties.
            const widgetType = widget.isGlobal ? widget.widgetType : widget.type;
            const widgetTitle =
                widget.isGlobal && get(widget, 'properties.global.title')
                    ? Utils.removeAccentFromString(Translation.translate(widget.properties.global.title).toLowerCase())
                    : undefined;


            /**
             * Get translated tags for the current widget
             * */
            const widgetTags = widgetTagsTranslations[widgetType];
            const translatedTags = Translation.translate(widgetTags);
            const formattedTranslatedTags =
                translatedTags ? translatedTags.toLowerCase().split(',') : undefined;

            // Fetch WIDGET_TYPE on lokalise.
            const typeTokenLokalise = widgetType.toUpperCase() === 'TITLE' ? 'GLOBAL.TITLE' : `WIDGET_TYPE_${widgetType.toUpperCase()}`;
            const translatedType = Translation.translate(typeTokenLokalise);
            const formattedTranslatedType =
                translatedType === typeTokenLokalise
                    ? undefined
                    : Utils.removeAccentFromString(translatedType.toLowerCase());

            // Test search value against tags, widget type and global widget title.
            const isSearchValueInTags =
                formattedTranslatedTags &&
                formattedTranslatedTags.some((tag) => Utils.removeAccentFromString(tag).indexOf(searchValue) > -1);
            const isSearchValueInWidgetType =
                formattedTranslatedType && formattedTranslatedType.indexOf(searchValue) > -1;
            const isSearchValueInGlobalWidgetTitle = widgetTitle && widgetTitle.indexOf(searchValue) > -1;

            if (isSearchValueInTags || isSearchValueInWidgetType || isSearchValueInGlobalWidgetTitle) {
                return [...acc, widget];
            }

            return [...acc];
        }, []);
    };
}

angular.module('Filters').filter('searchWidget', SearchWidgetFilter);

/////////////////////////////

// eslint-disable-next-line require-jsdoc-except/require-jsdoc
function Trim() {
    'ngInject';

    /**
     * Trim Filter.
     * Trim the passed string.
     *
     * @param  {string} str The string to trim.
     * @return {string} The trimmed string.
     */
    return function trim(str) {
        return angular.isDefinedAndFilled(str) ? str.trim() : str;
    };
}

angular.module('Filters').filter('trim', Trim);

/////////////////////////////

function UniqueFilter() {
    'ngInject';

    /**
     * Unique Filter.
     * Remove duplicates element from an array.
     * Base the duplication detection on a given property of the objects in the array.
     * Usefull when using a `ng-repeat` with `track by` property.
     *
     * @param  {Array}  items    The items to deduplicate.
     * @param  {string} filterOn The name of the property of the objects in the array on which compare for
     *                           duplication detection.
     * @return {Array}  The array with only unique element.
     */
    return function unique(items, filterOn) {
        return uniqBy(
            items,
            angular.isDefinedAndFilled(filterOn)
                ? function uniq(item) {
                      return angular.isObject(item) ? item[filterOn] : item;
                  }
                : undefined,
        );
    };
}

angular.module('Filters').filter('unique', UniqueFilter);

export {
    CleanupLevelFilter,
    DecodeHTMLEntitiesFilter,
    EscapeScriptFilter,
    EscapeTagsFilter,
    FilterObjectByFilter,
    HumanizeFilter,
    NewLineToBrFilter,
    RangeFilter,
    Reverse,
    SafeAngularFilter,
    SliceFilter,
    SortLatinFilter,
    StripMarkdownFilter,
    StripTagsFilter,
    Trim,
    TruncateFilter,
    TrustFilter,
    TrustSrcFilter,
    UniqueFilter,
};
