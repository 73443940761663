(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/widget/:methodKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },
            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('WidgetFactory', WidgetFactory);
})();
