export enum WIDGET_CALENDAR {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453742
     * */
    USER_CALENDAR = 'USER_CALENDAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=384756026
     * */
    ACTIVE_CALENDAR = 'WIDGET_CALENDAR.ACTIVE_CALENDAR',
}
