/* istanbul ignore file */
/** tested with integration tests */
import { getBaseUrl } from '@lumapps/router/utils';

import { useInjectTapper } from './useInjectTapper';

/**
 * Creates the necessary functions in order to allow customizations and hooks
 * into our router.
 */
const useRouterTapper = () => {
    useInjectTapper({
        getInternalUrl: (slug: string) => {
            const baseUrl = getBaseUrl(window.location.pathname);

            return `${baseUrl}${slug}`;
        },
    });
};

export { useRouterTapper };
