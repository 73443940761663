import React, { FC } from 'react';

import { mdiPlay } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Size, IconButtonProps } from '@lumapps/lumx/react';
import { PLAY_GALLERY } from '@lumapps/play-gallery/keys';
import { playGalleryRoute } from '@lumapps/play-gallery/routes';
import { useLinkProps } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

export interface PlayVideoMenuProps {
    /** custom styles passed down to the notifications icon */
    styles?: Record<string, any>;
}

export const PlayVideoMenu: FC<PlayVideoMenuProps> = ({ styles }) => {
    const linkProps = useLinkProps({ route: playGalleryRoute });
    const { translateKey } = useTranslate();

    return (
        <IconButton
            emphasis={Emphasis.low}
            icon={mdiPlay}
            label={translateKey(PLAY_GALLERY.VIDEO_GALLERY_TITLE)}
            size={Size.m}
            color={styles?.themeColor}
            {...linkProps}
            target={linkProps.target as IconButtonProps['target']}
        />
    );
};
