import set from 'lodash/set';

import { PayloadAction } from '@lumapps/redux/createSlice';

export enum MemberModalStates {
    open = 'open',
    closed = 'closed',
}

export interface MemberModalState {
    state: MemberModalStates;
    totalMembers?: number;
    shareableContentId?: string;
}

export const initialState: MemberModalState = {
    state: MemberModalStates.closed,
};

export const memberModalReducers = {
    open: (state: MemberModalState, action: PayloadAction<MemberModalState['shareableContentId']>): void => {
        set(state, 'state', MemberModalStates.open);
        if (action?.payload) {
            set(state, 'shareableContentId', action.payload);
        }
    },
    close: (state: MemberModalState): void => {
        set(state, 'state', MemberModalStates.closed);
    },
    setTotalMembers: (state: MemberModalState, action: PayloadAction<MemberModalState['totalMembers']>): void => {
        set(state, 'totalMembers', action.payload);
    },
};
