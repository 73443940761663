import noop from 'lodash/noop';

// eslint-disable-next-line import/no-mutable-exports
let save = noop;
const setUserSettings = (userSettings) => {
    // Bind is used to prevent having 'this' undefined in LumBaseService.
    save = userSettings.save.bind(userSettings);
};

export { save, setUserSettings };
