import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import get from 'lodash/get';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { Icon } from '../../../ui';
import { stoppedEvent, setPath } from '../../../../utils';

/**
 * Renders a button that changes the alignment for header/footer labels.
 */
export class JustifyButton extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.areaStyle,
        /** The current display style mode. */
        mode: styleTypes.areaModes,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /**
         * The direction the icon is moved when triggering the button.
         * If the icon cannot be moved further (e.g., it is at the far left and the `position` is `'left'`),
         * the button is not rendered.
         */
        position: propTypes.oneOf(['left', 'right']),
        /** Area style. */
        value: styleTypes.areaStyle,
    };

    constructor(props) {
        super(props);

        /**
         * On click, update the alignment setting of the label according to
         * its current setting and the `position` prop.
         *
         * @param  {Object} occurrence The event object.
         */
        this.onClick = (occurrence) => {
            const { onChange, value, defaults, mode, position, name: key } = this.props;
            const targetName = mode === 'block' ? 'wrapper' : 'main';
            const justifyContent = get(value, [targetName, 'justifyContent'], defaults[targetName].justifyContent);
            const flexDirection =
                targetName === 'main' ? 'row' : get(value, 'wrapper.flexDirection', defaults[targetName].flexDirection);

            return onChange(
                setPath(
                    value,
                    [targetName, 'justifyContent'],
                    position === 'left'
                        ? flexDirection === 'row-reverse'
                            ? justifyContent === 'flex-start'
                                ? 'center'
                                : 'flex-end'
                            : justifyContent === 'flex-end'
                                ? 'center'
                                : 'flex-start'
                        : flexDirection === 'row-reverse'
                            ? justifyContent === 'flex-end'
                                ? 'center'
                                : 'flex-start'
                            : justifyContent === 'flex-start'
                                ? 'center'
                                : 'flex-end',
                ),
                key,
                stoppedEvent(occurrence),
            );
        };
    }

    render() {
        const {
            defaults: { main: defaultMain, wrapper: defaultWrapper },
            value: { main = defaultMain, wrapper = defaultWrapper } = {},
            position,
            mode,
        } = this.props;

        // Todo[David]: JSDoc.
        if (mode !== (main.display ? main.display : defaultMain.display)) {
            return null;
        }

        const {
            alignItems = defaultWrapper.alignItems,
            flexDirection = defaultWrapper.flexDirection,
            justifyContent = defaultWrapper.justifyContent,
        } = wrapper;
        if (
            (mode === 'block' &&
                (flexDirection === 'column' ||
                    flexDirection === 'column-reverse' ||
                    ((position === 'left' &&
                        ((flexDirection === 'row' && justifyContent === 'flex-start') ||
                            (flexDirection === 'row-reverse' && justifyContent === 'flex-end'))) ||
                        (position === 'right' &&
                            ((flexDirection === 'row' && justifyContent === 'flex-end') ||
                                (flexDirection === 'row-reverse' && justifyContent === 'flex-start') ||
                                ((flexDirection === 'column' || flexDirection === 'column-reverse') &&
                                    alignItems === 'flex-end')))))) ||
            (mode === 'flex' &&
                ((position === 'left' && (!main.justifyContent || main.justifyContent === 'flex-start')) ||
                    (position === 'right' && main.justifyContent === 'flex-end')))
        ) {
            return null;
        }

        return (
            <div className={classNames(`widget-style-area__position`, `widget-style-area__position--${position}`)}>
                <button onMouseUp={stoppedEvent} onClick={this.onClick}>
                    <Icon value={`arrow-${position}-thick`} />
                </button>
            </div>
        );
    }
}
