/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

const LazyBlockEventPreview = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-event-list-block-event-preview' */
            './ConnectedBlockEventPreview'
        ),
);

type LazyBlockEventPreviewProps = React.ComponentProps<typeof LazyBlockEventPreview> & {
    loadingFallback?: React.ReactNode;
};

export const BlockEventPreview: React.FC<LazyBlockEventPreviewProps> = ({ loadingFallback, ...props }) => (
    <Suspense loadingFallback={loadingFallback || <WidgetSkeletonRectangle />}>
        <LazyBlockEventPreview {...props} />
    </Suspense>
);
