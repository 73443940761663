import React from 'react';

import { SelectFieldSimple, SelectFieldSimpleProps } from '@lumapps/combobox/components/SelectFieldSimple';
import { SelectVariant } from '@lumapps/lumx/react';

import { FILTERS_MODE } from '../../constants';
import { UseSingleSelectFilter } from '../../hooks/useSingleSelectFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

import './index.scss';

export interface SingleSelectFilterProps<T> extends FilterProps {
    /** list of options to display as list items */
    options: T[];
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelected: UseSingleSelectFilter<T>['onSelected'];
    /** onFilter callback, generated from the `useListFilter` hook */
    onFilter: UseSingleSelectFilter<T>['onFilter'];
    /** onClearSelected callback, generated from the `useListFilter` hook */
    onClearSelected: UseSingleSelectFilter<T>['onClearSelected'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    selection: UseSingleSelectFilter<T>['selection'];
    /** custom props for the Select field simple component */
    selectFieldSimpleProps?: Partial<SelectFieldSimpleProps>;
    /** callback when the select is open */
    onOpen?: () => void;
    /** callback when the select is closed */
    onSelectClose?: () => void;
}

const CLASSNAME = 'single-select-filter';

/**
 * Filter that renders the provided options as a list with a single option.
 * @family Filters
 * @param SingleSelectFilterProps
 * @returns SingleSelectFilter
 */
export const SingleSelectFilter = <T,>({
    options,
    onSelected,
    selection,
    selectFieldSimpleProps = {},
    mode,
    shouldDisplayOptionsDirectly,
    onOpen,
    onSelectClose,
    ...props
}: SingleSelectFilterProps<T>) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const onSelectOpen = (open: boolean) => {
        setIsOpen(open);

        if (open && onOpen) {
            onOpen();
        }

        if (!open && onSelectClose) {
            onSelectClose();
        }
    };

    let Select = (
        <SelectFieldSimple<T>
            label={mode === FILTERS_MODE.EXPOSED_FACETS ? undefined : props.title}
            choices={options}
            onChange={onSelected}
            isOpen={isOpen}
            value={selection}
            className={CLASSNAME}
            setOpen={onSelectOpen}
            {...selectFieldSimpleProps}
        />
    );

    if (shouldDisplayOptionsDirectly && mode === FILTERS_MODE.EXPOSED_FACETS) {
        Select = (
            <SelectFieldSimple<T>
                label={props.title}
                choices={options}
                onChange={(sel: T) => {
                    onSelected(sel);
                    props.onFilter();
                }}
                isOpen={isOpen}
                setOpen={onSelectOpen}
                value={selection}
                className={CLASSNAME}
                {...selectFieldSimpleProps}
                selectVariant={SelectVariant.chip}
                onSelectClear={props.onClearSelected}
            />
        );
    }

    return (
        <Filter {...props} hideTitle mode={mode} shouldDisplayOptionsDirectly={shouldDisplayOptionsDirectly}>
            {Select}
        </Filter>
    );
};
