import { BaseApiError } from '@lumapps/base-api';
import { UseMutationOptions, useMutation, useQueryClient } from '@lumapps/base-api/react-query';

import { microAppsQueryKeys, submitRunMicroApp } from '../api';
import { RunMicroAppAPI, SubmitRunMicroAppParams } from '../types';

/**
 * Hook to manage submitting a micro app.
 * Returns a react-query mutation object.
 */
export const useSubmitRunMicroApp = (
    options?: UseMutationOptions<
        Awaited<ReturnType<typeof submitRunMicroApp>>,
        BaseApiError,
        SubmitRunMicroAppParams,
        RunMicroAppAPI
    >,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: submitRunMicroApp,
        ...options,
        onSuccess: async (data, variables, context) => {
            /**
             * Once the micro app has successfully been updated, set the new value in the queryClient.
             */
            queryClient.setQueryData(
                microAppsQueryKeys.runMicroApp(variables.microAppId, variables.runInstanceId),
                data,
            );

            /**
             * We let developers add more behaviours on success if needed (display a notification, navigate to other pages etc…)
             */
            if (options?.onSuccess) {
                options?.onSuccess(data, variables, context);
            }
        },
    });
};
