import { Route } from '@lumapps/router';
import { URL_PREFIX, AppId } from '@lumapps/router/constants';

const feedRoute: Route = {
    path: `/${URL_PREFIX}/feed`,
    legacyId: 'app.front.feed',
    appId: AppId.frontOffice,
    // When in webview mode for the mobile, this makes the mobile app redirect to the native personal feed
    mobilePageDescriptor: {
        type: 'personal-feed',
    },
};

const feed = (): Route => ({
    ...feedRoute,
    params: {},
});

export { feed, feedRoute };
