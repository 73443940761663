/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

import { padding } from '@lumapps/classnames';
import { Group } from '@lumapps/groups/types';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Button, Divider, Emphasis, FlexBox, List, ListItem, ListSubheader } from '@lumapps/lumx/react';
import { useParams } from '@lumapps/router';
import { getSpaceData } from '@lumapps/spaces/api';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus, isError, isLoading } from '@lumapps/utils/types/BaseLoadingStatus';

import { getLeaderboard } from '../../api';
import {
    DEFAULT_MAX_NUMBER_ELEMENTS_ON_LEADERBOARD_LIST,
    SIZE_INITIAL_RANKING_LIST,
    INITIAL_LEADERBOARD_OFFSET,
    DEFAULT_DISPLAY_TYPE,
    PICK_DISPLAY_TYPE,
} from '../../constants';
import { LEADERBOARD } from '../../keys';
import { RankingData, userTypes, SpacesId, LearningLeaderboardDisplayType } from '../../types';
import { Podium } from '../Podium';
import { PodiumSkeleton } from '../Podium/PodiumSkeleton';
import { RankingListItem } from '../RankingListItem';
import { RankingListItemSkeleton } from '../RankingListItem/RankingListItemSkeleton';

export interface LeaderboardProps {
    inSystemPage?: boolean;
    groups?: Group[];
    displayType?: LearningLeaderboardDisplayType;
    spaceId?: string;
    isSpaceDetectedFromLegacyApp?: boolean;
}

export const Leaderboard: React.FC<LeaderboardProps> = ({
    inSystemPage = false,
    groups,
    displayType = DEFAULT_DISPLAY_TYPE,
    spaceId = '',
    isSpaceDetectedFromLegacyApp = false,
}) => {
    const groupId = groups?.map((group) => group.id) || [];
    const [currentUserData, setCurrentUserData] = React.useState<RankingData>();
    const [rankingList, setRankingList] = React.useState<RankingData[]>([]);
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    const { id: spaceIdentifier } = useParams<SpacesId>();
    const { isSpace: isSpaceDetectedFromNGI } = useSpace();
    const isFetching = useRef(false);

    const [status, setStatus] = React.useState(BaseLoadingStatus.initial);
    const { translateKey } = useTranslate();

    const fetchLeaderboardData = async (offset: number, groupsToLeaderboard: string[]) => {
        try {
            setStatus(BaseLoadingStatus.loading);
            const response = await getLeaderboard(offset, SIZE_INITIAL_RANKING_LIST, groupsToLeaderboard);

            if (offset === 0) {
                setRankingList(response.rankingList?.items || []);
            } else {
                setRankingList((prevList) => [...prevList, ...(response.rankingList?.items || [])]);
            }
            setHasMore(response.rankingList.more);
            if (response?.currentUser) {
                setCurrentUserData(response.currentUser);
            }
            setStatus(BaseLoadingStatus.idle);
        } catch (e) {
            setStatus(BaseLoadingStatus.error);
        }
    };

    const getSpacesGroupIds = React.useCallback(async () => {
        const { data } = await getSpaceData(spaceIdentifier || spaceId);

        const allGroupIds = [
            data.spaceGroups.technicalGroupId,
            ...data.spaceGroups.adminGroupIds,
            ...data.spaceGroups.memberGroupIds,
        ];
        const spacesGroupIds = new Set(allGroupIds);

        return Array.from(spacesGroupIds);
    }, [spaceId]);

    useEffect(() => {
        if (isFetching.current) {
            return;
        }
        const fetchData = async () => {
            isFetching.current = true;
            if (isSpaceDetectedFromNGI || isSpaceDetectedFromLegacyApp) {
                setRankingList([]);
                if (displayType === PICK_DISPLAY_TYPE) {
                    fetchLeaderboardData(0, groupId);
                } else {
                    const spacesGroupIds = await getSpacesGroupIds();
                    fetchLeaderboardData(0, spacesGroupIds);
                }
            } else {
                setRankingList([]);
                fetchLeaderboardData(0, groupId);
            }
            isFetching.current = false;
        };

        fetchData();
    }, [groups, displayType]);

    const handleLoadMore = async () => {
        if (isSpaceDetectedFromNGI || isSpaceDetectedFromLegacyApp) {
            if (displayType === PICK_DISPLAY_TYPE) {
                fetchLeaderboardData(rankingList.length || 0, groupId);
            } else {
                const spacesGroupIds = await getSpacesGroupIds();
                fetchLeaderboardData(rankingList.length || 0, spacesGroupIds);
            }
        } else {
            fetchLeaderboardData(rankingList.length || 0, groupId);
        }
    };
    const checkUserType = (ranking: RankingData) => {
        if (rankingList && rankingList?.length > 1) {
            if (currentUserData?.identifier === ranking.identifier) {
                return userTypes.currentUserInTotalRanking;
            }
            return userTypes.someUser;
        }
        return userTypes.currentUserRanking;
    };

    const shouldShowAverageScore = (rankingList: RankingData[], index: number) => {
        const currentPoints = rankingList[index]?.totalPoints;
        const nextPoints = rankingList[index + 1]?.totalPoints;
        const prevPoints = rankingList[index - 1]?.totalPoints;

        if (
            (currentPoints !== undefined && currentPoints === nextPoints) ||
            (currentPoints !== undefined && currentPoints === prevPoints)
        ) {
            return true;
        }
        return false;
    };
    return (
        <FlexBox className={inSystemPage ? undefined : padding('all', 'huge')}>
            {isError(status) && (
                <ServiceNotAvailableState
                    shouldShowIcon
                    onRetry={() => getLeaderboard(INITIAL_LEADERBOARD_OFFSET, SIZE_INITIAL_RANKING_LIST)}
                />
            )}

            {isLoading(status) && rankingList.length === 0 && (
                <>
                    <PodiumSkeleton />
                    <List>
                        <ListSubheader> {translateKey(LEADERBOARD.USER_RANKING)}</ListSubheader>
                        <ListItem>
                            <RankingListItemSkeleton />
                        </ListItem>
                    </List>
                    <List>
                        <ListSubheader> {translateKey(LEADERBOARD.FULL_RANKING)}</ListSubheader>
                        {Array.from({ length: DEFAULT_MAX_NUMBER_ELEMENTS_ON_LEADERBOARD_LIST }, (key, index) => {
                            return (
                                <ListItem key={index}>
                                    <RankingListItemSkeleton />
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}

            {currentUserData && (
                <>
                    {rankingList.length > 0 && <Podium rankingListData={rankingList} />}
                    <Divider />
                    <List>
                        <ListSubheader> {translateKey(LEADERBOARD.USER_RANKING)}</ListSubheader>
                        <ListItem>
                            <RankingListItem
                                key={currentUserData.identifier}
                                ranking={currentUserData}
                                userType={userTypes.currentUserRanking}
                            />
                        </ListItem>
                    </List>
                    {rankingList.length > 0 && (
                        <List>
                            <ListSubheader> {translateKey(LEADERBOARD.FULL_RANKING)}</ListSubheader>

                            {rankingList?.slice(3).map((ranking, index) => {
                                const showAverageScore = shouldShowAverageScore(rankingList, index);
                                const userType = checkUserType(ranking);
                                return (
                                    <ListItem key={ranking.identifier}>
                                        <RankingListItem
                                            key={ranking?.ranking}
                                            ranking={ranking}
                                            userType={userType}
                                            showAverageScore={showAverageScore}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    {isLoading(status) && rankingList.length !== 0 && (
                        <List>
                            {Array.from({ length: DEFAULT_MAX_NUMBER_ELEMENTS_ON_LEADERBOARD_LIST }, (key, index) => {
                                return (
                                    <ListItem key={index}>
                                        <RankingListItemSkeleton />
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    {rankingList.length > 0 && hasMore && (
                        <Button fullWidth emphasis={Emphasis.medium} onClick={handleLoadMore}>
                            {translateKey(GLOBAL.NEXT)}
                        </Button>
                    )}
                </>
            )}
        </FlexBox>
    );
};
