(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetContentFilterSettingsController(
        $rootScope,
        $timeout,
        Config,
        Content,
        ContentPicker,
        ContentTemplate,
        Features,
        InitialSettings,
        Utils,
        Widget,
    ) {
        'ngInject';

        var widgetContentFilterSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Current filter widget.
         *
         * @type {Object}
         * @constant
         */
        var _CURRENT_WIDGET;

        /**
         * Main content widget.
         *
         * @type {Object}
         * @constant
         */
        var _MAIN_WIDGET;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The identifier of the content picker.
         *
         * @type {string}
         * @constant
         */
        widgetContentFilterSettings.PICKER_ID = 'widget-content-filter-';

        /**
         * Indicates if the content filter can be linked to a widget (a widget needs to be defined as main for this).
         *
         * @type {boolean}
         */
        widgetContentFilterSettings.couldBeLinkedToALocalWidget = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetContentFilterSettings.Config = Config;
        widgetContentFilterSettings.Utils = Utils;
        widgetContentFilterSettings.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the content filter can be followed or not.
         *
         * @return {boolean} If the filter is followable or not.
         */
        function isFollowable() {
            var linkedWidget = _MAIN_WIDGET || _CURRENT_WIDGET.properties.linkedWidget;
            var isWidgetEmpty =
                (angular.isUndefined(_CURRENT_WIDGET.properties.customList) ||
                    angular.isUndefinedOrEmpty(_CURRENT_WIDGET.properties.customList)) &&
                !_CURRENT_WIDGET.properties.linkedToLocalWidget;

            return (
                !isWidgetEmpty &&
                angular.isDefinedAndFilled(linkedWidget) &&
                    linkedWidget.widgetType === InitialSettings.WIDGET_TYPES.CONTENT_LIST &&
                    Features.hasFeature('social')
            );
        }

        /**
         * Link this widget to the main content list of the template.
         */
        function linkToLocalWidget() {
            $timeout(function timedOutBroadcast() {
                _CURRENT_WIDGET = Widget.getCurrent();

                if (angular.isDefinedAndFilled(_.get(_CURRENT_WIDGET, 'properties.linkedToLocalWidget'))) {
                    _CURRENT_WIDGET.properties.customList = undefined;
                }

                $rootScope.$broadcast('widget-content-filter-settings', _CURRENT_WIDGET.uuid);
            });
        }

        /**
         * Open content picker.
         */
        function openContentPicker() {
            Utils.waitForAndExecute('#' + widgetContentFilterSettings.PICKER_ID, ContentPicker);
        }

        /**
         * Link this widget to the main content list of the template.
         */
        function updateSettings() {
            $timeout(function timedOutBroadcast() {
                _CURRENT_WIDGET = Widget.getCurrent();

                $rootScope.$broadcast('widget-content-filter-settings-update', _CURRENT_WIDGET.uuid);
            });
        }

        /////////////////////////////

        widgetContentFilterSettings.isFollowable = isFollowable;
        widgetContentFilterSettings.linkToLocalWidget = linkToLocalWidget;
        widgetContentFilterSettings.openContentPicker = openContentPicker;
        widgetContentFilterSettings.updateSettings = updateSettings;

        /////////////////////////////

        /**
         * Inititalize the controller.
         */
        function init() {
            _CURRENT_WIDGET = Widget.getCurrent();
            _CURRENT_WIDGET.properties = _CURRENT_WIDGET.properties || {};
            var properties = _CURRENT_WIDGET.properties;

            widgetContentFilterSettings.PICKER_ID += _.get(_CURRENT_WIDGET, 'uuid');

            if (angular.isUndefined(properties.customList)) {
                _CURRENT_WIDGET.properties.customList = {};
            }

            _MAIN_WIDGET = ContentTemplate.getElement(Content.getCurrent(), 'widget', 'isMainWidget', true);

            if (angular.isDefined(_MAIN_WIDGET)) {
                widgetContentFilterSettings.couldBeLinkedToALocalWidget = true;
            }
        }

        init();
    }

    /////////////////////////////

    angular
        .module('Controllers')
        .controller('WidgetContentFilterSettingsController', WidgetContentFilterSettingsController);
})();
