import React from 'react';

import { Size } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';

import { FORM_SUBSECTION_VERTICAL_ORIENTATION_SIZES } from '../../constants';
import { FormSubSection as UI, FormSubSectionProps as UIProps } from './FormSubSection';

const breakpoints: Record<string, number> = {
    [Size.xxs]: 100,
    [Size.xs]: 400,
    [Size.s]: 600,
    [Size.m]: 800,
    [Size.l]: 1024,
    [Size.xl]: 1152,
    [Size.xxl]: 1280,
};

export type FormSubSectionProps = Omit<UIProps, 'containerRef'>;

/**
 * A simple component to be used in a form as a sub section, with a title and description on the left side
 * and given children (mostly form inputs) on the right side
 *
 * @family Forms
 * @param FormSubSectionProps
 * @returns FormSubSection
 */
export const FormSubSection: React.FC<FormSubSectionProps> = ({ ...props }) => {
    const { ref, currentBreakpoint } = useDimensions({
        breakpoints,
    });

    return (
        <UI
            orientation={
                FORM_SUBSECTION_VERTICAL_ORIENTATION_SIZES.includes(currentBreakpoint as Size)
                    ? 'vertical'
                    : 'horizontal'
            }
            size={currentBreakpoint as Size}
            {...props}
            containerRef={ref}
        />
    );
};
