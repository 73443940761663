import get from 'lodash/get';
import remove from 'lodash/remove';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { Member, MemberRoles, MemberType } from '@lumapps/sa-members/types';

import { SearchGroupResult, ProgramMembershipPayload } from '../../types';

// The status of groups searching
export enum SearchGroupsStatus {
    initial = 'initial',
    loading = 'loading',
    loadingMore = 'loadingMore',
    loaded = 'loaded',
    error = 'error',
}

// The status of members fetching
export enum FetchMembershipStatus {
    initial = 'initial',
    loading = 'loading',
    loadingMore = 'loadingMore',
    loaded = 'loaded',
    error = 'error',
}

// Used in the dictionary to keep track if the member is new, deleted, updated etc
export enum ProgramMembershipStatus {
    initial = 'initial',
    added = 'added',
    updated = 'updated',
    deleted = 'deleted',
}

/**
 * The object that represent a member of program, whether it is an user or a group.
 * Used to show members list in the ProgramMembershipForm component and check if it has changed from initial.
 */
export interface ProgramMembership extends Pick<Member, 'id' | 'role' | 'memberType' | 'user' | 'group'> {
    initialRole?: MemberRoles;
    status: ProgramMembershipStatus;
}

export interface ProgramMembershipFormGroupsState {
    searchGroupsStatus: SearchGroupsStatus;
    searchGroupsResult: SearchGroupResult;
}
export interface ProgramMembershipState {
    // The 3 arays that will be sent to backend
    added: ProgramMembershipPayload[];
    updated: ProgramMembershipPayload[];
    deleted: Omit<ProgramMembershipPayload, 'role'>[];

    // Status of the loading of existing memberships, for edit program
    fetchMembershipStatus: FetchMembershipStatus;
    // Array of ids for keeping the visible members in the order we want to show them
    orderedVisibleMemberIds: string[];
    // Dictionnary of Users so we can efficiently craft the array of Users
    membershipDictionary: {
        [id: string]: ProgramMembership;
    };
    // If there is more members to fetch from backned
    more: boolean;
    // The cursor to fetch more members
    cursor?: string;
    // For edit program, the total number of saved managers (used for checking form validity)
    savedManagersCount: number;
    // The state used for groups fetching
    groups: ProgramMembershipFormGroupsState;
}

const initialState: ProgramMembershipState = {
    added: [],
    updated: [],
    deleted: [],
    orderedVisibleMemberIds: [],
    membershipDictionary: {},
    fetchMembershipStatus: FetchMembershipStatus.initial,
    more: false,
    cursor: '',
    savedManagersCount: 0,
    groups: {
        searchGroupsStatus: SearchGroupsStatus.initial,
        searchGroupsResult: {
            cursor: '',
            items: [],
            more: false,
        },
    },
};

/**
 * Small utils function to generate a programMembershipPayload that will be added to arrays passed to the back
 *
 * @param member The member from which the payload is crafted
 * @param setRole Whether we set the role on the payload (for deleted array, there is no role)
 * @returns
 */
const createMembershipPayload = (
    member: ProgramMembership | Omit<ProgramMembership, 'id' | 'status'>,
    setRole = true,
) => {
    const newMembership = {
        memberType: member.memberType || MemberType.user,
    };

    if (setRole) {
        (newMembership as ProgramMembershipPayload).role = member.role || MemberRoles.ambassador;
    }

    if (member.memberType === MemberType.group) {
        (newMembership as ProgramMembershipPayload).groupId = member.group?.id;
    } else {
        (newMembership as ProgramMembershipPayload).userId = member.user?.id;
    }
    return newMembership as ProgramMembershipPayload;
};

const { actions, reducer } = createSlice({
    domain: 'ProgramForm/ProgramMembershipForm',
    initialState,
    reducers: {
        setExistingMembership: (
            state: ProgramMembershipState,
            action: PayloadAction<ProgramMembershipState['membershipDictionary']>,
        ) => {
            set(state, 'membershipDictionary', action.payload);
        },
        setFetchMembershipStatus: (state: ProgramMembershipState, action: PayloadAction<FetchMembershipStatus>) => {
            set(state, 'fetchMembershipStatus', action.payload);
        },
        setSavedManagersCount: (state: ProgramMembershipState, action: PayloadAction<number>) => {
            set(state, 'savedManagersCount', action.payload);
        },
        setPaginationInfo: (
            state: ProgramMembershipState,
            action: PayloadAction<{ more: ProgramMembershipState['more']; cursor: ProgramMembershipState['cursor'] }>,
        ) => {
            set(state, 'more', action.payload.more);
            set(state, 'cursor', action.payload.cursor);
        },
        /**
         * Function called when an user or a group is added from the picker.
         *   A) If the item does not exist in the membership dictionary, it's a whole new one, we add it. Default role is Ambassador.
         *     A') In the case of the first user in creation mode, the payload will already have its role set from the thunks.
         *   B) If item was already a member, got deleted and added back, remove from deleted array
         *     B1) If the initial role was already manager, set the item back to its initial state and also remove from updated array
         *     B2) If not, we put it in the updated list
         */
        addMembership: (
            state: ProgramMembershipState,
            action: PayloadAction<Omit<ProgramMembership, 'id' | 'status'>>,
        ) => {
            const memberToAdd = action.payload;

            // entityId is either userId or groupId, depending on the memberType
            const entityId = memberToAdd.user?.id.toString() || memberToAdd.group?.id.toString();

            if (entityId) {
                const membership = state.membershipDictionary[entityId];
                const defaultRole = MemberRoles.ambassador;

                // A)
                if (membership === undefined) {
                    set(state, 'membershipDictionary', {
                        ...state.membershipDictionary,
                        [entityId]: {
                            id: entityId,
                            role: memberToAdd.role || defaultRole,
                            memberType: memberToAdd.memberType,
                            status: ProgramMembershipStatus.added,
                            user: memberToAdd.user, // is set if we add an user, or else undefined
                            group: memberToAdd.group, // is set we add a group, or else undefined,
                        },
                    });

                    const newAdded: ProgramMembershipPayload = createMembershipPayload(memberToAdd);

                    // Add the new item to the added array payload and remove duplicates
                    set(
                        state,
                        'added',
                        uniqBy(
                            [...state.added, newAdded],
                            (addedMember: ProgramMembershipPayload) => addedMember.userId || addedMember.groupId,
                        ),
                    );
                }
                // B)
                else if (membership.status === ProgramMembershipStatus.deleted) {
                    remove(
                        state.deleted,
                        (deletedMember) => entityId === deletedMember.userId || entityId === deletedMember.groupId,
                    );

                    membership.role = defaultRole;
                    // B1)
                    if (membership.initialRole === membership.role) {
                        membership.status = ProgramMembershipStatus.initial;
                        remove(
                            state.updated,
                            (updatedMember: ProgramMembershipPayload) =>
                                entityId === updatedMember.userId || entityId === updatedMember.groupId,
                        );
                    }
                    // B2)
                    else {
                        membership.status = ProgramMembershipStatus.updated;

                        const newUpdated = createMembershipPayload(memberToAdd);

                        if (memberToAdd.memberType === MemberType.user) {
                            newUpdated.userId = entityId;
                        } else {
                            newUpdated.groupId = entityId;
                        }

                        set(state, 'updated', [...state.updated, newUpdated]);
                    }
                }
            }
        },
        /**
         * Function called when an user or a group role has changed.
         *   A) If the item to Edit is in the newly added item, update it with new role
         *   B) If the item is an initial exiting member and role changed, add it to the updated items array
         *   C) If the item is an already updated existing member, and got changed back to its original status, remove it from the updated
         */
        editMembership: (state: ProgramMembershipState, action: PayloadAction<ProgramMembershipPayload>) => {
            const { userId, groupId, role } = action.payload;
            const entityId = userId || groupId;
            if (entityId) {
                const member = state.membershipDictionary[entityId];
                // A)
                if (member.status === ProgramMembershipStatus.added) {
                    member.role = role;
                    const addedMember = state.added.find(
                        (membership: ProgramMembershipPayload) =>
                            membership.userId === entityId || membership.groupId === entityId,
                    );
                    if (addedMember) {
                        addedMember.role = role;
                    }
                }
                // B)
                else if (member.status === ProgramMembershipStatus.initial && member.initialRole !== role) {
                    member.status = ProgramMembershipStatus.updated;
                    member.role = role;

                    const newUpdated = createMembershipPayload(member);

                    set(state, 'updated', [...state.updated, newUpdated]);
                }
                // C)
                else if (member.status === ProgramMembershipStatus.updated && member.initialRole === role) {
                    member.status = ProgramMembershipStatus.initial;
                    member.role = role;
                    remove(
                        state.updated,
                        (updatedMember: ProgramMembershipPayload) =>
                            updatedMember.userId === entityId || updatedMember.groupId === entityId,
                    );
                }
            }
        },
        /**
         * Function called when a member is removed from the selected.
         *   A) If the member to Delete is in the newly added members, remove it
         *   B) If the member is in the updated or initial state, put it in the deleted array.
         */
        deleteMembership: (state: ProgramMembershipState, action: PayloadAction<ProgramMembershipPayload>) => {
            const entityId = action.payload.userId || action.payload.groupId;

            if (entityId) {
                const member = state.membershipDictionary[entityId];

                // A)
                if (member.status === ProgramMembershipStatus.added) {
                    // eslint-disable-next-line no-param-reassign
                    delete state.membershipDictionary[entityId];
                    remove(
                        state.added,
                        (addedMember: ProgramMembershipPayload) =>
                            addedMember.userId === entityId || addedMember.groupId === entityId,
                    );
                }
                // B)
                else if (
                    member.status === ProgramMembershipStatus.updated ||
                    member.status === ProgramMembershipStatus.initial
                ) {
                    if (member.status === ProgramMembershipStatus.updated) {
                        remove(
                            state.updated,
                            (updatedMember: ProgramMembershipPayload) =>
                                updatedMember.userId === entityId || updatedMember.groupId === entityId,
                        );
                    }
                    member.status = ProgramMembershipStatus.deleted;

                    const newDeleted = createMembershipPayload(member, false);
                    set(state, 'deleted', [newDeleted, ...state.deleted]);
                }

                // Remove member from visible member ids list
                set(
                    state,
                    'orderedVisibleMemberIds',
                    state.orderedVisibleMemberIds.filter((memberId) => memberId !== entityId),
                );
            }
        },
        setOrderedVisibleMemberIds: (state: ProgramMembershipState, action: PayloadAction<string[]>) => {
            set(state, 'orderedVisibleMemberIds', action.payload);
        },
        addOrderedVisibleMemberIds: (state: ProgramMembershipState, action: PayloadAction<string>) => {
            set(state, 'orderedVisibleMemberIds', uniq([action.payload, ...state.orderedVisibleMemberIds]));
        },
        setSearchGroupsStatus: (state: ProgramMembershipState, action: PayloadAction<SearchGroupsStatus>) => {
            set(state, 'groups.searchGroupsStatus', action.payload);
        },
        setSearchGroupsResult: (state: ProgramMembershipState, action: PayloadAction<SearchGroupResult>) => {
            set(state, 'groups.searchGroupsResult', action.payload);
        },
        addSearchGroupsResult: (state: ProgramMembershipState, action: PayloadAction<SearchGroupResult>) => {
            set(state, 'groups.searchGroupsResult', {
                cursor: action.payload.cursor,
                more: action.payload.more,
                items: [...get(state, 'groups.searchGroupsResult.items', []), ...action.payload.items],
            });
        },
        reset: () => {
            return initialState;
        },
    },
});

export { actions, reducer, initialState };
