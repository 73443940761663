/* istanbul ignore file */
// Ignoring coverage for this file since it is just re-exports and definitions
// eslint-disable-next-line lumapps/do-not-import-react-query
import {
    QueryClient as BaseQueryClient,
    type QueryClientConfig,
    type UseQueryOptions as BaseUseQueryOptions,
    type UseInfiniteQueryOptions as BaseUseInfiniteQueryOptions,
    type UseMutationOptions as BaseUseMutationOptions,
    type QueryKey as BaseQueryKey,
} from '@tanstack/react-query';

/**
 * Create a default React Query client to ensure setup consistencies everywhere throughout apps and packages.
 *
 * ℹ️ We make sure `refetchOnWindowFocus`is disabled (can be overriden)
 * ℹ️ We make sure retries are disabled on both queries and mutations (can be overriden)
 */
export const createQueryClient = (config: QueryClientConfig | undefined = {}) =>
    new BaseQueryClient({
        ...config,
        defaultOptions: {
            ...config.defaultOptions,
            mutations: {
                retry: false,
                ...config.defaultOptions?.mutations,
            },
            queries: {
                retry: false,
                refetchOnWindowFocus: false,
                ...config.defaultOptions?.queries,
            },
        },
    });

/**
 * Make sure no one can initialize a query client without using our factory
 *
 * ℹ️ This export takes precedence over the `export * from '@tanstack/react-query'` statement below.
 */
export const QueryClient = undefined;

/**
 * Abstract the `UseQueryOptions` type in order to omit `queryKey` and `queryFn` keys because
 * they should never be overriden
 *
 * 💡 This type can be used to type the `options` object of your custom react-query hook
 * ℹ️ This export takes precedence over the `export * from '@tanstack/react-query'` statement below.
 */
export type UseQueryOptions<
    TQueryFnData,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
> = Omit<BaseUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;

/**
 * Abstract the `UseInfiniteQueryOptions` type in order to omit `queryKey` and `queryFn` keys because
 * they should never be overriden
 *
 * 💡 This type can be used to type the `options` object of your custom react-query hook
 * ℹ️ This export takes precedence over the `export * from '@tanstack/react-query'` statement below.
 */
export type UseInfiniteQueryOptions<
    TQueryFnData,
    TError = unknown,
    TData = TQueryFnData,
    TQueryData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
> = Omit<
    BaseUseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>,
    'getNextPageParam' | 'queryKey' | 'queryFn'
>;

/**
 * Abstract the `UseMutationOptions` type in order to omit `mutationKey` and `mutationFn` keys because
 * they should never be overriden
 *
 * 💡 This type can be used to type the `options` object of your custom react-query hook
 * ℹ️ This export takes precedence over the `export * from '@tanstack/react-query'` statement below.
 */
export type UseMutationOptions<TData = unknown, TError = unknown, TVariables = void, TContext = unknown> = Omit<
    BaseUseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
>;

export * from '@tanstack/react-query';
export * from '@tanstack/react-query-devtools';

export { useInfiniteQuery } from './useInfiniteQuery';
