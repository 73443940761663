/* eslint-disable lines-around-directive */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetPlaylistController() {
        'ngInject';

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            const widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.widget.properties.viewModeVariant === 'ungrouped') {
                widgetClass.push('widget--view-mode-variant-ungroup');
            }

            return widgetClass;
        }

        /**
         * The widget is empty when there is no "playlist" in the properties.
         */
        function isEmpty() {
            return !vm.widget.properties.playlist || !vm.widget.properties.playlist.id;
        }

        /**
         * Function that conditions the rendering of the widget content.
         * 
         * @see widget-playlist.html
         */
        function isWidgetEmpty() {
            return isEmpty();
        }

        /**
         * In designer mode, when the widget is empty, it is visible and displays the widget type `PLAYLIST - LUMAPPS PLAY`
         * 
         * In reader mode, when the widget is empty, it is not displayed at all
         * 
         * @see widget-playlist.html
         */
        function isWidgetHidden() {
            return !vm.parentCtrl.designerMode() && isEmpty();
        }

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
    }

    function widgetPlaylistDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetPlaylistController,
            controllerAs: 'vm',
            replace: true,
            require: ['widgetPlaylist', '^widget'],
            restrict: 'E',
            link,
            scope: {
                widget: '=',
            },
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-playlist/views/widget-playlist.html',
        };
    }

    window.angular.module('Widgets').directive('widgetPlaylist', widgetPlaylistDirective);
})();
