import { SortersType } from '@lumapps/lumx-filter-and-sort/types';
import { GLOBAL } from '@lumapps/translations';

import { ARTICLES_SORTING } from './types';

export const ARTICLES_FF = 'articles';

export const SORT_FIELDS: SortersType[] = [
    { id: ARTICLES_SORTING.CREATED_DESC, label: GLOBAL.SORT_NEWEST },
    { id: ARTICLES_SORTING.CREATED_ASC, label: GLOBAL.SORT_OLDEST },
];
