import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Skeleton } from '@lumapps/play-player';
import { ImportOnVisibility } from '@lumapps/utils/components/ImportOnVisibility';
import type { ElementRender } from '@lumapps/wrex/types';

import { PLAY_VIDEO } from '../../../constants';
import type { PlayVideoElement } from '../../../types';

import './PlayVideo.scss';

const VideoPlayerWrapper = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'player-monolite-facade' */
            '@lumapps/play-player/components/PlayerMonoliteFacade'
        ),
);

export const CLASSNAME = 'wrex-play-video';

export const PlayVideo: ElementRender<PlayVideoElement, HTMLElement> = ({
    element,
    elementRef,
    children,
    className,
    ...forwardedProps
}) => {
    const { block } = useClassnames(CLASSNAME);
    const { id: videoId } = element ?? {};

    return (
        <article className={block(undefined, className)} ref={elementRef} {...forwardedProps}>
            {children}

            {videoId && (
                <ImportOnVisibility
                    options={{
                        rootMargin: '200px', // Loads the component early for smoother UX
                    }}
                    loadingFallback={<Skeleton />}
                    rootProps={{ contentEditable: false }}
                >
                    <VideoPlayerWrapper videoId={videoId} />
                </ImportOnVisibility>
            )}
        </article>
    );
};

/** The display name should be equal to the PlayVideoElement's type */
PlayVideo.displayName = PLAY_VIDEO;
