import { getTemplatePath, provideTemplate } from './utils/states-utils';

/////////////////////////////
//                         //
//     Public functions    //
//                         //
/////////////////////////////

/**
 * Define the states for the app.
 *
 * @param {Provider} $stateProvider     The state provider.
 * @param {Provider} $translateProvider The translation provider.
 */
const setupApp = ($stateProvider, $translateProvider) => {
    $stateProvider.state('app', {
        resolve: {
            // eslint-disable-next-line sort-keys
            resolveInit($stateParams) {
                'ngInject';

                try {
                    if (angular.isDefined($stateParams.ngStats) && angular.isFunction(window.showAngularStats)) {
                        window.showAngularStats({
                            logDigest: $stateParams.ngStats === 'log',
                            logWatches: $stateParams.ngStats === 'log',
                            position: 'topright',
                        });
                    }
                } catch (exception) {
                    // Nothing to do here.
                }

                return true;
            },
            resolveInitialSetting($q, Settings, resolveInit) {
                'ngInject';

                // This is here to trick the linter into thinking that the blocking resolve injection is used.
                angular.noop(resolveInit);

                if (Settings.is.initialized) {
                    return true;
                }

                return $q(function resolveAllInitialSetting(resolve, reject) {
                    Settings.setup('app')
                        .then(resolve)
                        .catch(reject);
                });
            },
            // eslint-disable-next-line sort-keys
            resolveTranslations(Translation, resolveInitialSetting) {
                'ngInject';

                // This is here to trick the linter into thinking that the blocking resolve injection is used.
                angular.noop(resolveInitialSetting);

                Translation.setTranslationsTable($translateProvider.translations());

                return true;
            },
        },
        url:
            '{customer:customerPath}/{instance:(?!login|gsite)[^/]+}?user-profile&as&debug&debugInfo&xhrQueue&safe&prod&ngStats',
        views: {
            app: {
                controller: 'AppController',
                controllerAs: 'app',
                templateUrl: '/client/front-office/modules/layout/modules/app/views/app.html',
            },
        },
    });
};

/**
 * Define the states for the Login and Accounts choice.
 *
 * @param {Provider} $stateProvider     The state provider.
 * @param {Provider} $translateProvider The translation provider.
 */
const setupLogin = ($stateProvider, $translateProvider) => {
    $stateProvider
        .state('login', {
            resolve: {
                resolveInitialSetting($q, Settings) {
                    'ngInject';

                    return $q(function resolveAllInitialSetting(resolve, reject) {
                        Settings.setup('login')
                            .then(resolve)
                            .catch(reject);
                    });
                },
                resolveTranslations(Translation, resolveInitialSetting) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveInitialSetting);

                    Translation.setTranslationsTable($translateProvider.translations());

                    return true;
                },
                // eslint-disable-next-line sort-keys
                resolveLoading(Settings, resolveInitialSetting) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveInitialSetting);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '{customer:customerPath}{instance:(?:/[^/]+)*}/login?r&forgotten-password',
            views: {
                login: {
                    controller: 'LoginController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, ConfigTheme, Instance) {
                        'ngInject';

                        const templatePath = getTemplatePath(
                            Instance,
                            'login/views/login.html',
                            ConfigTheme.HAS_CUSTOM_LOGIN,
                        );

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
            },
        })
        .state('accountChoice', {
            resolve: {
                resolveInitialSetting($q, Settings) {
                    'ngInject';

                    return $q(function resolveAllInitialSetting(resolve, reject) {
                        Settings.setup('accountChoice')
                            .then(resolve)
                            .catch(reject);
                    });
                },
                resolveTranslations(Translation, resolveInitialSetting) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveInitialSetting);

                    Translation.setTranslationsTable($translateProvider.translations());

                    return true;
                },
                // eslint-disable-next-line sort-keys
                resolveLoading(Settings, resolveInitialSetting) {
                    'ngInject';

                    // This is here to trick the linter into thinking that the blocking resolve injection is used.
                    angular.noop(resolveInitialSetting);

                    Settings.endLoading();

                    return true;
                },
            },
            url: '/login/account',
            views: {
                login: {
                    controller: 'LoginChoiceController',
                    controllerAs: 'vm',
                    templateProvider($http, $templateCache, ConfigTheme, Instance) {
                        'ngInject';

                        const templatePath = getTemplatePath(
                            Instance,
                            'login/views/login-choice.html',
                            ConfigTheme.HAS_CUSTOM_LOGIN,
                        );

                        return provideTemplate($http, $templateCache, templatePath);
                    },
                },
            },
        });
};

/////////////////////////////

export { setupApp, setupLogin };
