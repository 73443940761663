import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import lodashFind from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import range from 'lodash/range';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import values from 'lodash/values';

import { FULL_DATE_MIDNIGHT } from 'common/constants';

/////////////////////////////

function WidgetCalendarController(
    $scope,
    $window,
    Calendar,
    CalendarRequestFactory,
    ConfigTheme,
    Content,
    EventFactory,
    EventService,
    InitialSettings,
    Instance,
    LsDatePickerService,
    Style,
    Translation,
    User,
    Utils,
    WorkspaceFactory,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The number of decimal we want to display.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DECIMAL_NUMBER = 2;

    /**
     * The number of hours in a day.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _HOURS_IN_A_DAY = 24;

    /**
     * The matching between iso weekday and named day (in english).
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _ISO_WEEKDAYS = {
        /* eslint-disable sort-keys, no-magic-numbers */
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
        /* eslint-enable sort-keys, no-magic-numbers */
    };

    /**
     * The MomentJS format to display a localized date with Full month name-Day of the month-Year.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _LOCALIZED_MONTH_LONG_NAME_DAY_YEAR = 'LL';

    /**
     * The max number of events to retrieve in week and month view mode.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_RESULTS_FOR_WEEK_AND_MONTH = 500;


    /**
     * The max number of repetion of an event in planning view mode.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    // eslint-disable-next-line no-underscore-dangle
    const _MAX_EVENT_REPETITION_PLANNING = 500;

    /**
     * The number of minutes in an hour.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MINUTES_IN_HOUR = 60;

    /**
     * The MomentJS format to display Month-Day of the month-Year.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _MONTH_DAY_YEAR = 'MM-DD-YYYY';

    /**
     * The MomentJS format to display the Full month name.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _MONTH_LONG_NAME = 'MMMM-YYYY';

    /**
     * The MomentJS format to display the non-zero-padded Hour of the day.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _NON_PADDED_HOUR = 'H';

    /**
     * The MomentJS format to display the zero-padded Minutes of the hour.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _PADDED_MINUTES = 'mm';

    /**
     * The MomentJS format to display the zero-padded Week of the year.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _PADDED_WEEK = 'ww-YYYY';

    /**
     * The MomentJS format to display the Year-Month-Day.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _YEAR_MONTH_DAY = 'YYYY-MM-DD';

    /**
     * The MomentJS format to display the Year-Full month name.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _YEAR_MONTH_LONG_NAME = 'YYYY-MMMM';

    /**
     * The momentJS format to display Year-Week of the year.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _YEAR_WEEK = 'GGGG-WW';

    /**
     * The momentJS format to display Year-Week (ISO format) of the year.
     * Used when weeks start on sunday.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _YEAR_WEEK_ISO = 'YYYY-ww';

    /**
     * Indicates if the calendar widget is within a community or another type of content.
     *
     * @type {boolean}
     */
    const _isCommunityContext = Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

    /**
     * The current community calendar id, used by a community.
     *
     * @type {string}
     */
    let _communityCalendarId;

    /**
     * The current workspace id, used by a community.
     *
     * @type {string}
     */
    let _workspaceId;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * A map of the available calendars.
     *
     * @type {Object}
     */
    vm.calendars = {};

    /**
     * The list of events.
     *
     * @type {Object}
     */
    vm.events = {};

    /**
     * Contains the cached events.
     *
     * @type {Object}
     */
    vm.cachedEvents = {};

    /**
     * Indicates if the sidebar is opened.
     *
     * @type {boolean}
     */
    vm.sidebarIsOpen = false;

    /**
     * The id of the date picker.
     *
     * @type {string}
     */
    vm.datePickerId = 'date-picker';

    /**
     * Indicates if we have more events to load for each view type.
     *
     * @type {Object}
     */
    vm.more = {};

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Colors = get(Style.getCurrent('global'), 'properties.colors', ConfigTheme.COLORS_WIDGET);
    vm.EventService = EventService;
    vm.Translation = Translation;
    vm.Utils = Utils;
    vm.moment = moment;
    vm.range = range;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Compute the number of days an event is spread over.
     * The duration is rounded up (an event of 23h is one day long, an event of 49h is 3 days long);
     *
     * @param  {Object} calendarEvent The calendar event to compute the duration
     * @return {number} The event duration (in days, at least one, even if less than 24h).
     */
    function _computeEventDuration(calendarEvent) {
        const calendarEventStart = moment(calendarEvent.start).startOf('day');

        let endDate = calendarEvent.end;

        if (!includes(endDate, 'T')) {
            endDate = moment(calendarEvent.end)
                .subtract(1, 'days')
                .format(_YEAR_MONTH_DAY);
        }
        const calendarEventEnd = moment(endDate).startOf('day');

        let calendarEventDuration = moment
            .duration(calendarEventEnd.diff(calendarEventStart))
            .asHours()
            .toFixed(0);
        calendarEventDuration = parseInt(calendarEventDuration, 10);
        calendarEventDuration = Math.floor(calendarEventDuration / _HOURS_IN_A_DAY) + 1;

        return calendarEventDuration || 1;
    }

    /**
     * Compute overlapping of events.
     *
     * @param {Array} calendarEvents The events we want to check for overlapping.
     */
    function _computeOverlapping(calendarEvents) {
        angular.forEach(calendarEvents, function forEachEvents(calendarEventsToFlatten) {
            // Store hours in an array but keeping information of being a start or an end date.
            let hourList = [];
            const flattenedCalendarEvents = flattenDeep(values(calendarEventsToFlatten));

            angular.forEach(flattenedCalendarEvents, function forEachFlattenedEvents(calendarEvent) {
                const eventStart = moment(calendarEvent.start);
                const eventEnd = moment(calendarEvent.end);
                const eventDuration = moment
                    .duration(eventEnd.diff(eventStart))
                    .asHours()
                    .toFixed(_DECIMAL_NUMBER);

                hourList = hourList.concat([
                    {
                        duration: Number(eventDuration),
                        hour: calendarEvent.start,
                        htmlLink: calendarEvent.htmlLink,
                        name: calendarEvent.summary,
                        type: 1,
                    },
                    {
                        duration: Number(eventDuration),
                        hour: calendarEvent.end,
                        htmlLink: calendarEvent.htmlLink,
                        name: calendarEvent.summary,
                        type: -1,
                    },
                ]);
            });

            hourList = sortBy(
                sortBy(hourList, (hour) => hour.duration * -1),
                (hour) =>
                    Number(
                        // eslint-disable-next-line no-magic-numbers
                        (moment.duration(moment(hour.hour).diff(moment())).asHours() + hour.type / 100).toFixed(
                            _DECIMAL_NUMBER,
                        ),
                    ),
            );

            // For each hour, store the maximum number of overlapping calculated at this time.
            let counter = 0;
            let maxCount = 0;
            let order = 0;
            const seatTaken = {};
            angular.forEach(hourList, function forEachHour(hour) {
                // Compute max overlapping.
                if (counter === 0) {
                    maxCount = counter;
                    order = 0;
                }
                counter += hour.type;

                if (counter > maxCount) {
                    maxCount = counter;
                }
                hour.count = maxCount;

                // Compute overlapping order.
                order += hour.type;
                if (hour.type === 1) {
                    if (!seatTaken[order]) {
                        hour.order = order;
                        seatTaken[order] = true;
                    } else if (seatTaken[order]) {
                        for (let i = 0; true; i++) {
                            if (!seatTaken[i]) {
                                hour.order = i;
                                seatTaken[i] = true;

                                break;
                            }
                        }
                    }
                } else if (hour.type === -1) {
                    const matchingHour = lodashFind(hourList, function findMatchingHour(hourInList) {
                        return hour.htmlLink === hourInList.htmlLink && hourInList.type === 1;
                    });
                    seatTaken[matchingHour.order] = false;
                }
            });

            // For each event, retrieve the number of overlapping stored for the corresponding end date.
            angular.forEach(flattenedCalendarEvents, function forEachFlattenedEvents(calendarEvent) {
                const endHour = lodashFind(hourList, function findEndHour(hour) {
                    return hour.hour === calendarEvent.end && hour.htmlLink === calendarEvent.htmlLink;
                });
                calendarEvent.overlappingNumber = endHour ? endHour.count - 1 : 0;

                const startHour = lodashFind(hourList, function findStartHour(hour) {
                    return hour.hour === calendarEvent.start && hour.htmlLink === calendarEvent.htmlLink;
                });
                calendarEvent.overlappingOrder = startHour ? startHour.order : 0;
            });
        });
    }

    /**
     * Format the events.
     *
     * @param {Array}   calendarEvents The events to format.
     * @param {boolean} more           Indicates if there are more events to load in any calendar.
     */
    function _formatEvents(calendarEvents, more) {
        vm.more[vm.widget.properties.visualizationMode] = angular.isDefined(more)
            ? more
            : vm.more[vm.widget.properties.visualizationMode];

        vm.widget.properties.visualizationMode = vm.widget.properties.visualizationMode.toLowerCase();

        const startDayOnSunday = vm.widget.properties.displayWeekend && vm.widget.properties.startDayOnSunday;
        moment.updateLocale(moment().locale(), {
            month: {
                dow: startDayOnSunday ? 0 : 1,
            },
            week: {
                dow: startDayOnSunday ? 0 : 1,
            },
        });

        const functionName = `format${capitalize(vm.widget.properties.visualizationMode)}Events`;
        vm[functionName](calendarEvents);
    }

    /**
     * Handle the widget's backward compatibility at initialization.
     */
    function _handleBackwardCompatibility() {
        if (angular.isUndefinedOrEmpty(vm.widget.properties)) {
            return;
        }

        vm.widget.properties = vm.widget.properties || {};
        vm.widget.properties.calendarsSelected = [];

        if (
            angular.isDefinedAndFilled(vm.widget.properties.calendarIdType) &&
            vm.widget.properties.calendarIdType === 'default'
        ) {
            vm.widget.properties.userCalendarChecked = true;
        }

        if (
            (angular.isDefinedAndFilled(vm.widget.properties.calendarIdType) &&
                vm.widget.properties.calendarIdType === 'select') ||
            vm.widget.properties.myCalendarsChecked
        ) {
            vm.widget.properties.othersCalendarsChecked = true;

            // Old properties "myCalendarsSelected" and "calendar" contains selected userCalendars.
            if (angular.isDefinedAndFilled(vm.widget.properties.myCalendarsSelected)) {
                vm.widget.properties.calendarsSelected = vm.widget.properties.calendarsSelected.concat(
                    vm.widget.properties.myCalendarsSelected,
                );
            }
        }

        if (
            (angular.isDefinedAndFilled(vm.widget.properties.calendarIdType) &&
                vm.widget.properties.calendarIdType === 'text') ||
            vm.widget.properties.publicCalendarsChecked
        ) {
            vm.widget.properties.othersCalendarsChecked = true;

            if (angular.isDefinedAndFilled(vm.widget.properties.publicCalendarsSelected)) {
                angular.forEach(
                    vm.widget.properties.publicCalendarsSelected.split(','),
                    function getPublicCalendarSummary(calendarId) {
                        const calendarQueryParams = {
                            uid: calendarId,
                        };

                        vm.widget.properties.calendarsSelected.push({
                            id: calendarId,
                            summary: calendarId,
                        });

                        const index = vm.widget.properties.calendarsSelected.length - 1;
                        Calendar.get(calendarQueryParams, function onCalendarGetSuccess(response) {
                            vm.widget.properties.calendarsSelected[index].summary = get(
                                response,
                                'calendar.summary',
                                calendarId,
                            );
                        });
                    },
                );
            }
        }

        vm.widget.properties.calendarsSelected = uniq(vm.widget.properties.calendarsSelected);

        delete vm.widget.properties.calendarIdType;
        delete vm.widget.properties.publicCalendarsChecked;
        delete vm.widget.properties.publicCalendarsSelected;
        delete vm.widget.properties.myCalendarsChecked;
        delete vm.widget.properties.myCalendarsSelected;
        delete vm.widget.properties.calendars;
    }

    /**
     * Check if the current user is in the guests and has declined the invitation.
     * The user is considered as non attending also if the event has no start date.
     *
     * @param  {Object}  calendarEvent The calendar event we want to check whether the user is attending.
     * @return {boolean} If the user is attending the event or not.
     */
    function _isAttending(calendarEvent) {
        if (angular.isUndefined(calendarEvent) || angular.isUndefined(calendarEvent.start)) {
            return false;
        }

        if (angular.isUndefinedOrEmpty(calendarEvent.guests)) {
            return true;
        }

        const calendarEmail =
            calendarEvent.calendarId === 'primary' ? User.getConnected().email : calendarEvent.calendarId;

        const filteredGuests = calendarEvent.guests.filter(function filterAttendingEvents(guest) {
            return guest.email === calendarEmail && guest.responseStatus === 'declined';
        });

        // If the current user hasn't declined the invitation to the event, then format it.
        return angular.isUndefinedOrEmpty(filteredGuests);
    }

    /**
     * Load the next or previous events, according to the given function name.
     *
     * @param {string} functionName The moment function name to add or subtract 1 day/month/week of the currently
     *                              visualized day/month/week.
     *                              Possible values are: 'add' or 'subtract'.
     */
    function _loadNextOrPreviousEvents(functionName) {
        vm.widget.properties.visualizationMode = vm.widget.properties.visualizationMode.toLowerCase();

        let visualized;
        switch (vm.widget.properties.visualizationMode) {
            case 'month':
                vm.visualizedMonth = moment(vm.visualizedMonth)
                [functionName](1, 'month')
                    .toDate();
                visualized = vm.visualizedMonth;

                break;

            case 'planning':
                vm.visualizedDay = moment(vm.visualizedDay)
                [functionName](1, 'day')
                    .toDate();
                visualized = vm.visualizedDay;

                break;

            case 'week':
                vm.visualizedWeek = moment(vm.visualizedWeek)
                [functionName](1, 'week')
                    .toDate();
                visualized = vm.visualizedWeek;

                break;

            default:
                break;
        }
        vm.events = angular.fastCopy(vm.cachedEvents[visualized]);

        vm.loadEvents();
    }

    /**
     * Recompute the map between calendar id and calendar configuration.
     *
     * @param {string} [idCalendar] The id of the calendar we want to update in the map.
     */
    function _recomputeCalendarsMap(idCalendar) {
        const properties = get(vm.widget, 'properties', {});

        if (
            (angular.isUndefinedOrEmpty(properties.calendarsSelected) &&
                !properties.userCalendarChecked &&
                !properties.currentCommunityCalendar) ||
            (idCalendar || '').toLowerCase() === 'none'
        ) {
            return;
        }

        if (angular.isUndefinedOrEmpty(idCalendar)) {
            angular.forEach(properties.calendarsSelected, function forEachCalendars(calendar) {
                vm.calendars[calendar.id] = calendar;
            });
        } else {
            const selectedCalendar = lodashFind(properties.calendarsSelected, {
                id: idCalendar,
            });

            if (angular.isDefinedAndFilled(selectedCalendar)) {
                vm.calendars[idCalendar] = selectedCalendar;
            } else if (idCalendar === 'primary' && properties.userCalendarChecked) {
                set(vm.calendars, `['${idCalendar}'].color`, properties.userCalendarColor);
            } else if (properties.currentCommunityCalendar) {
                set(vm.calendars, `['${idCalendar}'].color`, properties.currentCommunityCalendarColor);
            }
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close the sidebar.
     */
    function closeSidebar() {
        vm.sidebarIsOpen = false;
    }

    /**
     * The callback executed when a date has been picked in the date picker.
     *
     * @param {string} newDate The picked date.
     */
    function datePickerCallback(newDate) {
        vm.widget.properties.visualizationMode = vm.widget.properties.visualizationMode.toLowerCase();

        let visualized;
        switch (vm.widget.properties.visualizationMode) {
            case 'month':
                vm.visualizedMonth = moment(newDate).toDate();
                visualized = vm.visualizedMonth;

                break;

            case 'planning':
                vm.visualizedDay = moment(newDate).toDate();
                visualized = vm.visualizedDay;

                break;

            case 'week':
                vm.visualizedWeek = moment(newDate).toDate();
                visualized = vm.visualizedWeek;

                break;

            default:
                break;
        }
        vm.events = angular.fastCopy(vm.cachedEvents[visualized]);

        vm.loadEvents();
    }

    /**
     * Format events for the month view.
     *
     * @param {Array} calendarEvents The events to format.
     */
    function formatMonthEvents(calendarEvents) {
        vm.events = {};

        // Initialize to the first day of the month.
        const currentDayOfMonth = moment
            .utc(vm.visualizedMonth)
            .local()
            .startOf('month')
            .startOf('week');
        const lastDayOfMonth = moment
            .utc(vm.visualizedMonth)
            .local()
            .endOf('month')
            .endOf('week');

        const YEAR_WEEK_FORMAT = vm.widget.properties.startDayOnSunday ? _YEAR_WEEK_ISO : _YEAR_WEEK;

        do {
            const weekKey = currentDayOfMonth.format(YEAR_WEEK_FORMAT);

            if (angular.isUndefined(vm.events[weekKey])) {
                vm.events[weekKey] = {};
            }

            if (vm.widget.properties.displayWeekend || currentDayOfMonth.isoWeekday() < _ISO_WEEKDAYS.saturday) {
                const dayEvents = {};
                dayEvents[currentDayOfMonth.clone().format(_MONTH_DAY_YEAR)] = [];
                angular.extend(vm.events[weekKey], dayEvents);
            }
            // Loop through the other days until the last day of the month.
        } while (currentDayOfMonth.add(1, 'days').diff(lastDayOfMonth) < 0);

        angular.forEach(calendarEvents, function forEachEvents(calendarEvent) {
            if (!_isAttending(calendarEvent)) {
                return;
            }

            // We have this info for Microsoft only.
            if (calendarEvent.isAllDay) {
                calendarEvent.start = calendarEvent.start.substring(0, calendarEvent.start.indexOf('T'));
                calendarEvent.end = calendarEvent.end.substring(0, calendarEvent.end.indexOf('T'));
            } else if (includes(calendarEvent.htmlLink, 'outlook')) {
                calendarEvent.start = moment
                    .utc(calendarEvent.start)
                    .local()
                    .format();
                calendarEvent.end = moment
                    .utc(calendarEvent.end)
                    .local()
                    .format();
            }

            let calendarEventDuration = _computeEventDuration(calendarEvent);

            while (calendarEventDuration > 0) {
                let calendarEventDate;
                if (includes(calendarEvent.start, 'T')) {
                    calendarEventDate = moment
                        .utc(calendarEvent.start)
                        .local()
                        .add(calendarEventDuration - 1, 'days');
                } else {
                    calendarEventDate = moment(calendarEvent.start).add(calendarEventDuration - 1, 'days');
                }

                const week = calendarEventDate.format(YEAR_WEEK_FORMAT);
                const day = calendarEventDate.format(_MONTH_DAY_YEAR);

                if (angular.isDefined(get(vm.events, [week, day]))) {
                    let eventToAdd = angular.fastCopy(calendarEvent);

                    eventToAdd.start =
                        calendarEvent.isAllDay || !calendarEvent.start.includes('T')
                            ? calendarEvent.start
                            : moment
                                .utc(calendarEvent.start)
                                .local()
                                .format();

                    eventToAdd.end =
                        calendarEvent.isAllDay || !calendarEvent.end.includes('T')
                            ? calendarEvent.end
                            : moment
                                .utc(calendarEvent.end)
                                .local()
                                .format();

                    if (calendarEventDuration > 1) {
                        eventToAdd = angular.copy(calendarEvent);
                        eventToAdd.start = moment
                            .utc(calendarEvent.start)
                            .local()
                            .format(_YEAR_MONTH_DAY);
                    }

                    vm.events[week][day].push(eventToAdd);
                }

                calendarEventDuration--;
            }
        });

        vm.cachedEvents[
            moment
                .utc(vm.visualizedMonth)
                .local()
                .format(_MONTH_LONG_NAME)
        ] = angular.copy(vm.events);
    }

    /**
     * Format events for the planning view.
     *
     * @param {Array} calendarEvents The events to format.
     */
    function formatPlanningEvents(calendarEvents) {
        vm.events = {};

        angular.forEach(calendarEvents, function forEachEvents(calendarEvent) {
            if (!_isAttending(calendarEvent)) {
                return;
            }

            // We have this info for Microsoft only.
            if (calendarEvent.isAllDay) {
                calendarEvent.start = calendarEvent.start.substring(0, calendarEvent.start.indexOf('T'));
                calendarEvent.end = calendarEvent.end.substring(0, calendarEvent.end.indexOf('T'));
            } else if (includes(calendarEvent.htmlLink, 'outlook')) {
                calendarEvent.start = moment.utc(calendarEvent.start).format();
                calendarEvent.end = moment.utc(calendarEvent.end).format();
            }

            let month;
            if (includes(calendarEvent.start, 'T')) {
                month = moment
                    .utc(calendarEvent.start)
                    .local()
                    .format(_YEAR_MONTH_LONG_NAME);
            } else {
                month = moment(calendarEvent.start).format(_YEAR_MONTH_LONG_NAME);
            }

            if (!angular.isArray(vm.events[month])) {
                vm.events[month] = [];
            }

            const eventDuration = _computeEventDuration(calendarEvent);

            let calendarEventDuration;
            if (eventDuration > _MAX_EVENT_REPETITION_PLANNING && moment().diff(calendarEvent.start, 'days') > 0) {
                calendarEventDuration = Math.min(
                    _MAX_EVENT_REPETITION_PLANNING + moment().diff(calendarEvent.start, 'days'),
                    _computeEventDuration(calendarEvent),
                );
            } else {
                calendarEventDuration = Math.min(_MAX_EVENT_REPETITION_PLANNING, _computeEventDuration(calendarEvent));
            }

            for (let i = 0; i < calendarEventDuration; i++) {
                const eventToAdd = angular.copy(calendarEvent);

                eventToAdd.start = eventToAdd.start.includes('T')
                    ? moment
                        .utc(calendarEvent.start)
                        .local()
                        .format()
                    : calendarEvent.start;

                if (i > 0) {
                    eventToAdd.start = moment
                        .utc(calendarEvent.start)
                        .add(i, 'days')
                        .format(_YEAR_MONTH_DAY);
                }

                eventToAdd.end =
                    calendarEventDuration > 1 && i < calendarEventDuration - 1
                        ? moment
                            .utc(calendarEvent.end)
                            .local()
                            .format(_YEAR_MONTH_DAY)
                        : moment
                            .utc(calendarEvent.end)
                            .local()
                            .format();

                eventToAdd.end = calendarEvent.end.includes('T') ? eventToAdd.end : calendarEvent.end;

                if (
                    moment
                        .utc(eventToAdd.start)
                        .local()
                        .startOf('day')
                        .isSameOrAfter(moment().startOf('day'))
                ) {
                    vm.events[month].push(eventToAdd);
                }
            }

            vm.events[month] = vm.events[month].sort(function sortEvents(event1, event2) {
                const event1StartMilliseconds = moment(event1.start).valueOf();
                const event2StartMilliseconds = moment(event2.start).valueOf();

                const event1EndMilliseconds = moment(event1.end).valueOf();
                const event2EndMilliseconds = moment(event2.end).valueOf();

                if (event1StartMilliseconds < event2StartMilliseconds) {
                    return -1;
                }

                if (event1StartMilliseconds > event2StartMilliseconds) {
                    return 1;
                }

                if (event1EndMilliseconds < event2EndMilliseconds) {
                    return -1;
                }

                if (event1EndMilliseconds > event2EndMilliseconds) {
                    return 1;
                }

                return 0;
            });
        });

        vm.cachedEvents[
            moment
                .utc(vm.visualizedDay)
                .local()
                .format(_LOCALIZED_MONTH_LONG_NAME_DAY_YEAR)
        ] = angular.fastCopy(vm.events);
    }

    /**
     * Format events for the week view.
     *
     * @param {Array} calendarEvents The events to format.
     */
    function formatWeekEvents(calendarEvents) {
        vm.events = {};

        let eventStart = moment(vm.visualizedWeek).startOf('week');
        let eventEnd = moment(vm.visualizedWeek).endOf('week');
        if (!vm.widget.properties.displayWeekend) {
            if (vm.widget.properties.startDayOnSunday) {
                eventStart = eventStart.add(1, 'day');
                eventEnd = eventEnd.subtract(1, 'day');
            } else {
                // eslint-disable-next-line no-magic-numbers
                eventEnd = eventEnd.subtract(2, 'day');
            }
        }

        const hours = {};

        if (vm.widget.properties.startTime < 0) {
            vm.widget.properties.startTime = 0;
        }
        if (vm.widget.properties.endTime > _HOURS_IN_A_DAY) {
            vm.widget.properties.endTime = _HOURS_IN_A_DAY;
        }

        for (let i = vm.widget.properties.startTime; i < vm.widget.properties.endTime; i++) {
            hours[i] = [];
        }

        vm.events = { ...vm.events, [eventStart.clone().format(_MONTH_DAY_YEAR)]: cloneDeep(hours) };

        while (eventStart.add(1, 'days').diff(eventEnd) < 0) {
            vm.events = { ...vm.events, [eventStart.clone().format(_MONTH_DAY_YEAR)]: cloneDeep(hours) };
        }

        angular.forEach(calendarEvents, function forEachEvents(calendarEvent) {
            if (!_isAttending(calendarEvent)) {
                return;
            }

            // We have this info for Microsoft only.
            if (calendarEvent.isAllDay) {
                calendarEvent.start = calendarEvent.start.substring(0, calendarEvent.start.indexOf('T'));
                calendarEvent.end = calendarEvent.end.substring(0, calendarEvent.end.indexOf('T'));
            } else if (includes(calendarEvent.htmlLink, 'outlook')) {
                calendarEvent.start = moment
                    .utc(calendarEvent.start)
                    .local()
                    .format();
                calendarEvent.end = moment
                    .utc(calendarEvent.end)
                    .local()
                    .format();
            }

            const calendarEventDuration = _computeEventDuration(calendarEvent);
            for (let j = 0; j < calendarEventDuration; j++) {
                const eventToAdd = angular.fastCopy(calendarEvent);

                if (calendarEvent.isAllDay || !calendarEvent.start.includes('T')) {
                    eventToAdd.start = moment(calendarEvent.start).format();
                    eventToAdd.end = moment(calendarEvent.end).format();
                } else {
                    eventToAdd.start = moment
                        .utc(calendarEvent.start)
                        .local()
                        .format();
                    eventToAdd.end = moment
                        .utc(calendarEvent.end)
                        .local()
                        .format();
                }

                if (j === calendarEventDuration - 1 && j > 0) {
                    eventToAdd.end = moment
                        .utc(calendarEvent.end)
                        .local()
                        .format();
                }

                const newStartDate = moment
                    .utc(eventToAdd.start)
                    .local()
                    .add(j, 'days');
                const day = newStartDate.format(_MONTH_DAY_YEAR);
                let hour = 0;
                if (j === 0) {
                    hour = moment
                        .utc(eventToAdd.start)
                        .local()
                        .format(_NON_PADDED_HOUR);
                    hour = parseInt(hour, 10);
                } else {
                    eventToAdd.start = newStartDate.format(_YEAR_MONTH_DAY);
                }

                // Event starts before the beginning of the current day.
                if (hour < vm.widget.properties.startTime) {
                    const calendarStartDate = moment(`${day} ${vm.widget.properties.startTime}:00:00`);

                    /**
                     * Event is partly within the startTime / endTime bracket defined in the widget.
                     * It may start before the current day & widget startTime or end after the current day and
                     * widget endTime.
                     */
                    if (moment(eventToAdd.end).isAfter(calendarStartDate)) {
                        hour = vm.widget.properties.startTime;
                        eventToAdd.start = calendarStartDate;
                    }
                }

                if (angular.isDefined(get(vm.events, [day, hour]))) {
                    vm.events[day][hour].push(eventToAdd);
                }
            }
        });

        _computeOverlapping(vm.events);

        vm.cachedEvents[`WEEK_${moment(vm.visualizedWeek).format(_PADDED_WEEK)}`] = angular.fastCopy(vm.events);
    }

    /**
     * Get the background colour of an event based on the calendar it belongs to.
     *
     * @param  {Object} calendarEvent The calendar event to check the colour of.
     * @return {Object} The style object to apply to the event wrapper.
     */
    function getEventBackgroundColor(calendarEvent) {
        return {
            'background-color': get(vm.calendars, `['${calendarEvent.calendarId}'].color`),
        };
    }

    /**
     * Get the position of the event.
     *
     * @param  {Object} calendarEvent The calendar event.
     * @return {Object} The event position.
     */
    function getEventPosition(calendarEvent) {
        const hourHeight = 60;

        const eventStart = moment(calendarEvent.start);
        const eventEnd = moment(calendarEvent.end);

        return {
            height: moment.duration(eventEnd.diff(eventStart)).asHours() * hourHeight,
            top: `${(hourHeight / _MINUTES_IN_HOUR) *
                parseInt(moment(calendarEvent.start).format(_PADDED_MINUTES), 10)}px`,
        };
    }

    /**
     * Get the classes for the widget.
     *
     * @return {Array} The widget class.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        return widgetClass;
    }

    /**
     * Check if the event is past.
     * Used to apply an opacity on the event.
     *
     * @param  {Object}  currentEvent The event we want to check if its past or not.
     * @return {boolean} If the event is in the past.
     */
    function isPastEvent(currentEvent) {
        return moment(currentEvent.end).isBefore(moment());
    }

    /**
     * Check if the widget is empty.
     * The widget is empty if the toolbar is hidden, there is no call in progress and no events.
     *
     * Used by the designer mode.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        return (
            !vm.widget.properties.displayToolbar &&
            !vm.requestFactory.isCallInProgress() &&
            vm.widget.properties.visualizationMode === 'planning' &&
            angular.isUndefinedOrEmpty(vm.events)
        );
    }

    /**
     * Check if the widget is hidden.
     * The widget is hidden if we are in read mode, the toolbar is hidden, there is no call in progress and no
     * events.
     *
     * Used by the read mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        vm.parentCtrl.isHidden = !vm.parentCtrl.designerMode() && vm.isWidgetEmpty() && !vm.widget.properties.noResults;

        return vm.parentCtrl.isHidden;
    }

    /**
     * Load the events of the selected calendars.
     *
     * @param {string}  [visualizationMode] The visualization mode of the events.
     *                                      Possible values are: 'planning', 'week' or 'month'.
     * @param {boolean} [more]              Indicates if there is more events.
     */
    function loadEvents(visualizationMode, more) {
        vm.datePickerDate = undefined;

        if (angular.isDefinedAndFilled(visualizationMode)) {
            vm.widget.properties.visualizationMode = visualizationMode;
        }
        vm.widget.properties.visualizationMode = vm.widget.properties.visualizationMode.toLowerCase();

        const formatted = {
            day: moment(vm.visualizedDay).format(_LOCALIZED_MONTH_LONG_NAME_DAY_YEAR),
            month: moment(vm.visualizedMonth).format(_MONTH_LONG_NAME),
            week: moment(vm.visualizedWeek).format(_PADDED_WEEK),
        };

        let cachedEvent;
        switch (vm.widget.properties.visualizationMode) {
            case 'month':
                cachedEvent = vm.cachedEvents[formatted.month];

                break;

            case 'planning':
                cachedEvent = vm.cachedEvents[formatted.day];

                break;

            case 'week':
                cachedEvent = vm.cachedEvents[`WEEK_${formatted.week}`];

                break;

            default:
                break;
        }
        vm.events = angular.fastCopy(cachedEvent);

        if (angular.isUndefinedOrEmpty(vm.events) || more) {
            if (vm.widget.properties.visualizationMode !== 'planning') {
                /*
                 * Calling it once right away so we have the table printed in the page already and not just an empty
                 * area.
                 */
                _formatEvents();
            }

            vm.requestFactory = new CalendarRequestFactory(function sortCalendarEvents(calendarEvent) {
                if (angular.isUndefined(get(calendarEvent, 'start'))) {
                    return undefined;
                }

                const eventStart = moment(calendarEvent.start);
                const now = moment();

                return moment.duration(eventStart.diff(now)).asHours();
            }, _formatEvents);

            if (angular.isDefinedAndFilled(vm.calendars)) {
                vm.widget.properties.visualizationMode = vm.widget.properties.visualizationMode.toLowerCase();

                angular.forEach(vm.calendars, function forEachCalendars(calendar) {
                    if (!vm.checkedCalendars[calendar.id]) {
                        return;
                    }

                    let request;

                    switch (vm.widget.properties.visualizationMode) {
                        case 'month':
                            request = {
                                calendarId: calendar.id,
                                endDate: moment(vm.visualizedMonth)
                                    .endOf('month')
                                    .endOf('week')
                                    .format(FULL_DATE_MIDNIGHT),
                                maxResults: _MAX_RESULTS_FOR_WEEK_AND_MONTH,
                                startDate: moment(vm.visualizedMonth)
                                    .startOf('month')
                                    .startOf('week')
                                    .format(FULL_DATE_MIDNIGHT),
                            };

                            break;

                        case 'planning':
                            request = {
                                calendarId: calendar.id,
                                maxResults: vm.numberOfEvents,
                                startDate: moment(vm.visualizedDay).format(FULL_DATE_MIDNIGHT),
                            };

                            break;

                        case 'week':
                            request = {
                                calendarId: calendar.id,
                                endDate: moment(vm.visualizedWeek)
                                    .endOf('week')
                                    .add(1, 'day')
                                    .format(FULL_DATE_MIDNIGHT),
                                maxResults: _MAX_RESULTS_FOR_WEEK_AND_MONTH,
                                startDate: moment(vm.visualizedWeek)
                                    .startOf('week')
                                    .format(FULL_DATE_MIDNIGHT),
                            };

                            break;

                        default:
                            break;
                    }

                    if (angular.isDefinedAndFilled(request)) {
                        if (
                            _isCommunityContext &&
                            angular.isDefinedAndFilled(_workspaceId) &&
                            calendar.id === _communityCalendarId
                        ) {
                            request.id = _workspaceId;

                            vm.requestFactory.add(WorkspaceFactory.list, request);
                        } else {
                            vm.requestFactory.add(EventFactory.list, request);
                        }
                    }
                });
            }

            vm.requestFactory.exec();
        }
    }

    /**
     * Load more events.
     */
    function loadMoreEvents() {
        vm.numberOfEvents = angular.isNumber(vm.widget.properties.number)
            ? vm.widget.properties.number + (vm.numberOfEvents || 0)
            : undefined;

        vm.loadEvents('planning', true);
    }

    /**
     * Load the next events.
     */
    function loadNextEvents() {
        _loadNextOrPreviousEvents('add');
    }

    /**
     * Load previous events.
     */
    function loadPreviousEvents() {
        _loadNextOrPreviousEvents('subtract');
    }

    /**
     * Load today's events.
     */
    function loadTodayEvents() {
        vm.datePickerDate = undefined;

        vm.visualizedDay = moment().toDate();
        vm.visualizedWeek = moment().toDate();
        vm.visualizedMonth = moment().toDate();

        vm.loadEvents(vm.widget.properties.visualizationMode);
    }

    /**
     * Open the date picker.
     *
     * @param {string} idPicker The id of the date picker to open.
     */
    function openDatePicker(idPicker) {
        Utils.waitForAndExecute(`#${idPicker}`, LsDatePickerService);
    }

    /**
     * Open the event in a new window.
     * @param {string} eventLink The event link.
     */
    function openEvent(eventLink) {
        $window.open(eventLink, '_blank');
    }

    /**
     * Open sidebar.
     */
    function openSidebar() {
        vm.sidebarIsOpen = true;
    }

    /**
     * Update calendar selection.
     */
    function updateCalendarSelection() {
        vm.events = {};
        vm.cachedEvents = {};

        vm.loadEvents();
    }

    /////////////////////////////

    vm.closeSidebar = closeSidebar;
    vm.datePickerCallback = datePickerCallback;
    vm.formatMonthEvents = formatMonthEvents;
    vm.formatPlanningEvents = formatPlanningEvents;
    vm.formatWeekEvents = formatWeekEvents;
    vm.getEventBackgroundColor = getEventBackgroundColor;
    vm.getEventPosition = getEventPosition;
    vm.getWidgetClass = getWidgetClass;
    vm.isPastEvent = isPastEvent;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;
    vm.loadEvents = loadEvents;
    vm.loadMoreEvents = loadMoreEvents;
    vm.loadNextEvents = loadNextEvents;
    vm.loadPreviousEvents = loadPreviousEvents;
    vm.loadTodayEvents = loadTodayEvents;
    vm.openDatePicker = openDatePicker;
    vm.openEvent = openEvent;
    vm.openSidebar = openSidebar;
    vm.updateCalendarSelection = updateCalendarSelection;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the settings are update, empty all events, rebuild the calendars and reload the events.
     *
     * @param {Event}  evt          The event.
     * @param {string} [idWidget]   The id of the widget whose settings are updated.
     * @param {string} [changeType] The type of change that trigger the update of the widget.
     *                              Possible value is: 'display'.
     *                              When triggering a 'display' change, don't reload the events of the widget.
     * @param {string} [idCalendar] The id of the calendar whose display config has been updated.
     */
    $scope.$on('widget-calendar-settings', function onWidgetCalendarSettings(evt, idWidget, changeType, idCalendar) {
        if (vm.widget.uuid === idWidget || angular.isUndefinedOrEmpty(idWidget)) {
            changeType = (changeType || '').toLowerCase();

            if (changeType === 'display') {
                _recomputeCalendarsMap(idCalendar);
            } else {
                vm.events = {};
                vm.cachedEvents = {};

                vm.init();
            }
        }
    });

    /////////////////////////////

    /**
     * Initialize controller.
     */
    vm.init = function init() {
        vm.widget.properties = vm.widget.properties || {};
        const { properties } = vm.widget;

        properties.visualizationMode = get(properties, 'visualizationMode', '').toLowerCase();

        if (angular.isUndefined(properties.userCalendarChecked) && !_isCommunityContext) {
            properties.userCalendarChecked = true;
        }

        if (angular.isUndefined(properties.calendarsSelected)) {
            _handleBackwardCompatibility();
        }

        vm.checkedCalendars = {};
        vm.calendars = {};

        let indexOffset = 0;
        if (properties.userCalendarChecked) {
            const userCalendarColor = angular.isDefinedAndFilled(properties.userCalendarColor)
                ? properties.userCalendarColor
                : Utils.getItemColor(indexOffset);
            properties.userCalendarColor = userCalendarColor;

            vm.calendars.primary = {
                color: properties.userCalendarColor,
                id: 'primary',
                summary: Translation.translate('USER_CALENDAR'),
            };

            vm.checkedCalendars.primary = true;

            indexOffset++;
        }

        const currentContent = Content.getCurrent();

        // If in community context and has the current community set in properties, get the community calendar.
        if (_isCommunityContext && properties.currentCommunityCalendar) {
            /**
             * We need to keep the community calendar if we are in a community.
             * Because a widget calendar in a community can display several calendars and a MS community calendar
             * has its own endpoint.
             */

            _communityCalendarId = currentContent.calendarId;

            const calendar = {
                color: properties.currentCommunityCalendarColor || Utils.getItemColor(indexOffset),
                id: _communityCalendarId,
                summary: Translation.translate('CURRENT_COMMUNITY_CALENDAR'),
            };

            vm.calendars[calendar.id] = calendar;
            vm.checkedCalendars[calendar.id] = true;
            indexOffset++;

            _workspaceId = currentContent.workspace;
        }

        if (properties.othersCalendarsChecked) {
            angular.forEach(properties.calendarsSelected, function forEachCalendars(selectedCalendar, idx) {
                if (angular.isUndefinedOrEmpty(selectedCalendar.color)) {
                    selectedCalendar.color = Utils.getItemColor(idx + indexOffset);
                }
                vm.calendars[selectedCalendar.id] = selectedCalendar;
                vm.checkedCalendars[selectedCalendar.id] = true;
            });
        }

        // Check if transparent is in colors and remove it.
        if (vm.Colors.indexOf('transparent') !== -1) {
            vm.Colors.splice(vm.Colors.indexOf('transparent'), 1);
        }

        vm.originalCalendars = angular.fastCopy(vm.calendars);

        properties.visualizationMode = properties.visualizationMode || 'planning';
        vm.numberOfEvents = angular.isNumber(properties.number) ? properties.number : undefined;

        properties.startTime = properties.startTime || 0;
        properties.endTime = properties.endTime || _HOURS_IN_A_DAY;

        vm.visualizedDay = moment().toDate();
        vm.visualizedWeek = moment().toDate();
        vm.visualizedMonth = moment().toDate();

        loadEvents();

        if (angular.isUndefinedOrEmpty(properties.more)) {
            properties.more = {
                label: {},
                link: {},
            };

            angular.forEach(Instance.getInstance().langs, function forEachInstanceLang(lang) {
                properties.more.link[lang] = 'https://calendar.google.com';
            });
        }
    };

    /**
     * Set the parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        vm.init();
    };
}

/////////////////////////////

/**
 * The widget calendar directive.
 * @return {Object} The directive object.
 */
function widgetCalendarDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc
    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetCalendarController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetCalendar', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-calendar/views/widget-calendar.html',
    };
}

/////////////////////////////

// eslint-disable-next-line angular/directive-name, angular/directive-restrict
angular.module('Widgets').directive('widgetCalendar', widgetCalendarDirective);

/////////////////////////////

export { widgetCalendarDirective };
