import produce from 'immer';

import {
    INIT_STATE,
    SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED,
    SET_FILTEREDTASKS,
    SET_ISFILTEREDBYLATETASKS,
    SET_ISFILTEREDBYREMINDERTASKS,
    SET_ISFILTEREDBYTODAYTASKS,
    SET_ISLOADING,
    SET_ISORDEREDBYDUEDATEDESC,
    SET_MYTASKS_NEWTODO,
    SET_MYTASKS_TODOITEMS,
    SET_MYTASKS_USERCONTENTWIDGET,
    SET_SELECTEDTASKS,
    SET_TASKS,
    UPDATE_TASK,
} from './tasks_action';

const initialState = [];

const initialWidgetState = {
    filteredTasks: [],
    isFilteredByLateTasks: false,
    isFilteredByReminderTasks: false,
    isFilteredByTodayTasks: false,
    isInit: false,
    isLoading: false,
    isMyTasksCheckedItemsDisplayed: false,
    isOrderByDueDateDesc: true,
    myTasksNewTodo: {},
    myTasksTodoItems: [],
    myTasksUserContentWidget: {},
    selectedTasks: [],
    tasks: [],
};

const NOT_FOUND = -1;

/**
 * The reducer for the tasks actions.
 *
 * @param  {Object} state  The current store state.
 * @param  {string} action The current dispatched action.
 * @return {Object} The new state of the redux store.
 */
const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const widgetIndex = draft.findIndex((widget) => {
            if (action.payload && action.payload.uuid === undefined) {
                return NOT_FOUND;
            }

            return action.payload ? widget.uuid === action.payload.uuid : NOT_FOUND;
        });

        switch (action.type) {
            case INIT_STATE: {
                if (widgetIndex === NOT_FOUND) {
                    draft.push({
                        uuid: action.payload.uuid,
                        ...initialWidgetState,
                    });
                    Object.assign(draft[draft.length - 1], { isInit: true });
                }

                break;
            }

            case SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isMyTasksCheckedItemsDisplayed: action.payload.data });
                }

                break;
            }

            case SET_FILTEREDTASKS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { filteredTasks: action.payload.data });
                }

                break;
            }

            case SET_ISFILTEREDBYLATETASKS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isFilteredByLateTasks: action.payload.data });
                }

                break;
            }

            case SET_ISFILTEREDBYREMINDERTASKS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isFilteredByReminderTasks: action.payload.data });
                }

                break;
            }

            case SET_ISFILTEREDBYTODAYTASKS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isFilteredByTodayTasks: action.payload.data });
                }

                break;
            }

            case SET_ISLOADING: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isLoading: action.payload.data });
                }

                break;
            }

            case SET_ISORDEREDBYDUEDATEDESC: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isOrderedByDueDateDesc: action.payload.data });
                }

                break;
            }

            case SET_MYTASKS_NEWTODO: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { myTasksNewTodo: action.payload.data });
                }

                break;
            }

            case SET_MYTASKS_TODOITEMS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { myTasksTodoItems: action.payload.data });
                }

                break;
            }

            case SET_MYTASKS_USERCONTENTWIDGET: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { myTasksUserContentWidget: action.payload.data });
                }

                break;
            }

            case SET_SELECTEDTASKS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { selectedTasks: action.payload.data });
                }

                break;
            }

            case SET_TASKS: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { tasks: action.payload.data });
                }

                break;
            }

            case UPDATE_TASK: {
                if (widgetIndex !== NOT_FOUND) {
                    const taskIndex = draft[widgetIndex].tasks.findIndex(
                        (_task) => _task.id === action.payload.data.id,
                    );

                    Object.assign(draft[widgetIndex].tasks[taskIndex], action.payload.data);
                }

                break;
            }

            default:
                return state;
        }

        return draft;
    });

export { reducer };
