import React from 'react';

import { margin } from '@lumapps/classnames';
import { mdiFormatTitle, mdiPlaylistEdit, mdiPlusBox } from '@lumapps/lumx/icons';
import {
    Button,
    Dialog,
    DialogProps,
    Divider,
    Emphasis,
    FlexBox,
    Heading,
    Orientation,
    Size,
    TextField,
    TextFieldProps,
    Toolbar,
} from '@lumapps/lumx/react';
import { UploadMedia, UploadMediaProps } from '@lumapps/medias/components/UploadMedia';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus, isLoadingStrict } from '@lumapps/utils/types/BaseLoadingStatus';
import type { Resource } from '@lumapps/wrex-link-preview/types';

import { useEditLinkPreviewDialog } from '../../hooks/useEditLinkPreviewDialog';
import { PREVIEW } from '../../keys';
import { CustomLink, DefaultPreview, DefaultPreviewProps } from '../DefaultPreview';

export interface EditLinkPreviewDialogProps extends DialogProps {
    /** custom classname */
    className?: string;
    /** link preview to edit */
    preview: DefaultPreviewProps['preview'] & { resource?: Resource };
    /** callback to be executed once the preview has been changed */
    onChange: (preview: DefaultPreviewProps['preview'] & { resource?: Resource }) => void;
    /** Inject a custom react link element */
    customLink?: CustomLink;
    /** whether the link preview image is editable */
    editImageOptions?: Partial<UploadMediaProps>;
    /** props to be passed into the image preview */
    previewImgProps?: DefaultPreviewProps['imgProps'];
    /** additional props passed in to the description text field */
    descriptionFieldProps?: Partial<TextFieldProps>;
    /** dialog title to override. The dialog default title is 'Edit link preview" */
    title?: string;
    /** additional props passed in to the title text field */
    titleFieldProps?: Partial<TextFieldProps>;
}

/**
 * Modal window that displays a UI in order to edit a preview link type (link or resources), by changing
 * the title, description and image displayed.
 * @param EditLinkPreviewDialogProps
 * @returns EditLinkPreviewDialog
 */
export const EditLinkPreviewDialog: React.FC<EditLinkPreviewDialogProps> = ({
    preview: originalPreview,
    onChange,
    editImageOptions,
    previewImgProps,
    descriptionFieldProps,
    title,
    titleFieldProps,
    customLink,
    selectedMedia,
    ...props
}) => {
    const { onClose } = props;
    const { onMediaUploaded, onPreviewChange, onPreviewThumbnailSwitch, preview, setPreview } =
        useEditLinkPreviewDialog({ preview: originalPreview, onChange, onClose });
    const [loadingStatus, setLoadingStatus] = React.useState(BaseLoadingStatus.initial);
    const { translateKey } = useTranslate();
    const titlePlaceholder = 'resource' in originalPreview ? originalPreview.resource?.title : originalPreview.title;
    const descriptionPlaceholder =
        'resource' in originalPreview ? originalPreview.resource?.description : originalPreview.description;

    return (
        <Dialog {...props}>
            <header>
                <Toolbar
                    label={
                        <Heading as="h2" typography="title">
                            {title || translateKey(PREVIEW.EDIT_LINK_PREVIEW)}
                        </Heading>
                    }
                />
            </header>

            <FlexBox orientation={Orientation.vertical} className={margin('all', 'huge')}>
                <DefaultPreview
                    preview={{
                        ...preview,
                        title: preview.title !== '' ? preview.title : titlePlaceholder,
                        description: preview.description !== '' ? preview.description : descriptionPlaceholder,
                    }}
                    onPreviewThumbnailSwitch={onPreviewThumbnailSwitch}
                    customLink={customLink}
                    imgProps={previewImgProps}
                />

                <Divider className={margin('top', 'huge')} />

                {editImageOptions ? (
                    <UploadMedia
                        uploaderLabel={translateKey(GLOBAL.UPLOAD_AN_IMAGE)}
                        icon={mdiPlusBox}
                        {...editImageOptions}
                        onMediaUploaded={(media) => {
                            if (media) {
                                onMediaUploaded([media]);
                            } else {
                                onMediaUploaded();
                            }
                        }}
                        setLoadingStatus={setLoadingStatus}
                        selectedMedia={selectedMedia}
                    />
                ) : null}

                <TextField
                    label={translateKey(GLOBAL.TITLE)}
                    placeholder={titlePlaceholder}
                    icon={mdiFormatTitle}
                    {...titleFieldProps}
                    isDisabled={isLoadingStrict(loadingStatus)}
                    onChange={(title) => {
                        setPreview({
                            ...preview,
                            title,
                        });
                    }}
                    value={preview?.title}
                />

                <TextField
                    label={translateKey(GLOBAL.DESCRIPTION)}
                    placeholder={descriptionPlaceholder}
                    icon={mdiPlaylistEdit}
                    multiline
                    {...descriptionFieldProps}
                    isDisabled={isLoadingStrict(loadingStatus)}
                    onChange={(description) => {
                        setPreview({
                            ...preview,
                            description,
                        });
                    }}
                    value={preview?.description}
                />
            </FlexBox>

            <footer>
                <Toolbar
                    after={
                        <>
                            <Button emphasis={Emphasis.medium} onClick={onClose}>
                                {translateKey(GLOBAL.DISCARD)}
                            </Button>
                            <Button
                                className={margin('left', Size.regular)}
                                onClick={onPreviewChange}
                                isDisabled={isLoadingStrict(loadingStatus)}
                            >
                                {translateKey(GLOBAL.SAVE)}
                            </Button>
                        </>
                    }
                />
            </footer>
        </Dialog>
    );
};
