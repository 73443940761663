// eslint-disable-next-line require-jsdoc
function WorkspaceFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/workspace/calendar/event/:methodKey`,
        {},
        {
            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },
        },
    );
}

/////////////////////////////

angular.module('Factories').factory('WorkspaceFactory', WorkspaceFactory);

/////////////////////////////

export { WorkspaceFactory };
