import { lumappsCampaign } from '@lumapps/lumx/custom-icons';
import { mdiArchiveOutline } from '@lumapps/lumx/icons';
import { ColorPalette } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations';

import { CAMPAIGN_PROGRESSION, CAMPAIGN_STATUS } from './types';

export const SCOPE = 'campaign';

export const CAMPAIGN_FF = 'journey-campaigns';

export const CAMPAIGN_ICON = lumappsCampaign;

export enum CAMPAIGN_ACTIONS {
    EDIT = 'EDIT',
    ARCHIVE = 'ARCHIVE',
    UNARCHIVE = 'UNARCHIVE',
}

export const PROGRESSION_CONFIG = {
    labels: {
        [CAMPAIGN_PROGRESSION.WIP]: GLOBAL.WIP,
        [CAMPAIGN_PROGRESSION.PLANNED]: GLOBAL.PLANNED,
        [CAMPAIGN_PROGRESSION.ONGOING]: GLOBAL.ONGOING,
        [CAMPAIGN_PROGRESSION.FINISHED]: GLOBAL.FINISHED,
    },
    colors: {
        [CAMPAIGN_PROGRESSION.WIP]: ColorPalette.yellow,
        [CAMPAIGN_PROGRESSION.PLANNED]: ColorPalette.dark,
        [CAMPAIGN_PROGRESSION.ONGOING]: ColorPalette.blue,
        [CAMPAIGN_PROGRESSION.FINISHED]: ColorPalette.dark,
    },
};

/**
 * Configuration to be used for displaying status with their associated
 * labels.
 */
export const STATUS_CONFIG = {
    labels: {
        [CAMPAIGN_STATUS.LIVE]: GLOBAL.LIVE,
        [CAMPAIGN_STATUS.ARCHIVED]: GLOBAL.SHOW_ARCHIVED,
    },
    actions: {
        [CAMPAIGN_STATUS.LIVE]: {
            [CAMPAIGN_ACTIONS.EDIT]: true,
            [CAMPAIGN_ACTIONS.ARCHIVE]: true,
            [CAMPAIGN_ACTIONS.UNARCHIVE]: false,
        },
        [CAMPAIGN_STATUS.ARCHIVED]: {
            [CAMPAIGN_ACTIONS.EDIT]: false,
            [CAMPAIGN_ACTIONS.ARCHIVE]: false,
            [CAMPAIGN_ACTIONS.UNARCHIVE]: true,
        },
    },
    icons: {
        [CAMPAIGN_STATUS.ARCHIVED]: mdiArchiveOutline,
    },
    statusForAction: {
        [CAMPAIGN_ACTIONS.ARCHIVE]: CAMPAIGN_STATUS.ARCHIVED,
        [CAMPAIGN_ACTIONS.UNARCHIVE]: CAMPAIGN_STATUS.LIVE,
        [CAMPAIGN_ACTIONS.EDIT]: CAMPAIGN_STATUS.LIVE || CAMPAIGN_STATUS.ARCHIVED,
    },
};
