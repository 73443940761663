import {
    isCommunitiesAllowed,
    isCommunityTemplatesAllowed,
    isCommunitiesFeatureEnabled,
} from '@lumapps/communities/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { CommunitiesSideNavigation } from './CommunitiesSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isCommunitiesAllowed(state);
    const isTemplatesAllowed = isCommunityTemplatesAllowed(state);
    const isFeatureEnabled = isCommunitiesFeatureEnabled(state);

    return {
        isAllowed: isAllowed && isFeatureEnabled,
        isTemplatesAllowed: isTemplatesAllowed && isFeatureEnabled,
    };
};

const ConnectedCommunitiesSideNavigation = connect(mapStateToProps)(CommunitiesSideNavigation);

export { ConnectedCommunitiesSideNavigation as CommunitiesSideNavigation };
