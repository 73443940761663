import React from 'react';

import { useFilePicker, UseFilePickerProps } from '@lumapps/utils/hooks/useFilePicker';

import { reducer, initialState, actions, SelectedFilesState, SelectedFile } from '../ducks/slice';

export interface UseUploadFileProps {
    /** options for the file picker */
    filePickerOptions?: Partial<UseFilePickerProps>;
    /** input id for accessibility purposes */
    inputId: string;
}

export interface UseUploadFile {
    /** callback to be executed once the media is deleted */
    onDelete: () => void;
    /** callback to be executed once the media wants to be edited */
    onEdit: () => void;
    /** function that opens up the file picker */
    openPicker: () => void;
    /** input to be added to the DOM in order to display the picker */
    hiddenInput: JSX.Element;
    /** upload media state */
    state: SelectedFilesState;
}

/**
 * Hook that centralises the actions for uploading a file.
 * @param UseUploadFileProps
 */
export const useUploadFile = ({ filePickerOptions, inputId }: UseUploadFileProps): UseUploadFile => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    /** After the file select, we update the internal state of the hook.
     * This function is used by the useFilePicker.
     */
    const onSelectFiles = async (files: File[]) => {
        if (files.length) {
            const selectedFiles: SelectedFile[] = [];
            files.forEach((file) => {
                const blobUrl = URL.createObjectURL(file);
                selectedFiles.push({
                    blobUrl,
                    file,
                });
            });

            dispatch(actions.setFiles(selectedFiles));
        }
    };

    const { hiddenInput, openPicker } = useFilePicker({
        ...filePickerOptions,
        onSelectFiles,
        inputProps: {
            id: inputId,
            ...filePickerOptions?.inputProps,
        },
    });

    /**
     * When choosing another file we open up the picker once more and let the normal flow go by
     */
    const onEdit = () => {
        openPicker();
    };

    /**
     * When deleting the file, we reset the internal state of the component and
     * we execute the callback with an undefined value, letting the parent component know
     * that nothing was added.
     */
    const onDelete = React.useCallback(() => {
        dispatch(actions.resetFiles());
    }, []);

    return {
        onDelete,
        onEdit,
        openPicker,
        hiddenInput,
        state,
    };
};
