import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiMinus, mdiOpenInNew, mdiPlus } from '@lumapps/lumx/icons';
import { ColorPalette, Emphasis, FlexBox, IconButton, Orientation } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isFirefox } from '@lumapps/utils/browser/isFirefox';
import { isSafari } from '@lumapps/utils/browser/isSafari';

import '../components/EmbeddedPdf/index.scss';

export interface UsePdfToolbarProps {
    defaultZoom?: number;
    zoomIncrement?: number;
    zoomInLimit?: number;
    zoomOutLimit?: number;
    mediaUrl: string;
    /** Callback on zoom in or zoom out click */
    onZoomClick?: () => void;
}

const CLASSNAME = 'embedded-pdf';

/**
 * Displays a zooming toolbar for embedded previews
 *
 * @family Files
 * @param UsePdfToolbarProps
 * @returns PdfToolBar, currentZoom
 */
export const usePdfToolbar = ({
    defaultZoom = 80,
    zoomIncrement = 10,
    zoomInLimit = 500,
    zoomOutLimit = 10,
    mediaUrl,
    onZoomClick,
}: UsePdfToolbarProps) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const [currentZoom, setCurrentZoom] = React.useState(defaultZoom);

    const isZoomInDisabled = React.useMemo(() => {
        return currentZoom >= zoomInLimit;
    }, [currentZoom, zoomInLimit]);

    const isZoomOutDisabled = React.useMemo(() => {
        return currentZoom <= zoomOutLimit;
    }, [currentZoom, zoomOutLimit]);

    const zoomIn = () => {
        if (onZoomClick) {
            onZoomClick();
        }

        setCurrentZoom(currentZoom + zoomIncrement);
    };

    const zoomOut = () => {
        if (onZoomClick) {
            onZoomClick();
        }

        setCurrentZoom(currentZoom - zoomIncrement);
    };

    const getOpenButton = (isAbsolute: boolean) => (
        <IconButton
            className={element('open-button', { 'is-absolute': isAbsolute })}
            label={translateKey(GLOBAL.OPEN)}
            href={mediaUrl}
            target="_blank"
            icon={mdiOpenInNew}
            color={isAbsolute ? ColorPalette.dark : ColorPalette.light}
            emphasis={isAbsolute ? Emphasis.medium : Emphasis.low}
        />
    );

    const PdfToolBar = () => {
        return isSafari() ? (
            getOpenButton(true)
        ) : (
            <FlexBox className={element('toolbar')} gap="huge" orientation={Orientation.horizontal}>
                {!isFirefox() && (
                    <>
                        <IconButton
                            label={translateKey(GLOBAL.ZOOM_OUT)}
                            emphasis={Emphasis.low}
                            icon={mdiMinus}
                            onClick={zoomOut}
                            color={ColorPalette.light}
                            isDisabled={isZoomOutDisabled}
                        />
                        <IconButton
                            label={translateKey(GLOBAL.ZOOM_IN)}
                            emphasis={Emphasis.low}
                            icon={mdiPlus}
                            onClick={zoomIn}
                            color={ColorPalette.light}
                            isDisabled={isZoomInDisabled}
                        />
                    </>
                )}
                {getOpenButton(false)}
            </FlexBox>
        );
    };

    return { currentZoom, isZoomInDisabled, isZoomOutDisabled, zoomIn, zoomOut, PdfToolBar };
};
