import React from 'react';

import { mdiSettingsOutline, mdiLocationExit } from '@lumapps/lumx/icons';
import { createRelativeUrl } from '@lumapps/router/utils';
import { GLOBAL } from '@lumapps/translations';
import { getUserSettingsRoute, USER_SETTINGS_VIEWS } from '@lumapps/user-settings/routes';

import { USER } from '../keys';
import { logout } from '../routes';
import { UserMenuLink, USER_MENU_LINK_TYPE } from '../types';

export interface UseBaseUserMenuLinks {
    baseUserMenuLinks: UserMenuLink[];
}

/**
 * Returns the links to be displayed on the user menu profile dropdown on the top bar.
 * @returns - links to use on the user menu dropdown
 */
export const useBaseUserMenuLinks = (): UseBaseUserMenuLinks => {
    const baseUserMenuLinks = React.useMemo(() => {
        return [
            {
                key: 'settings',
                label: GLOBAL.SETTINGS,
                icon: mdiSettingsOutline,
                type: USER_MENU_LINK_TYPE.LINK,
                route: getUserSettingsRoute(USER_SETTINGS_VIEWS.GENERAL),
                target: '_self',
            },
            {
                key: 'logout',
                label: USER.USER_SETTINGS_LOGOUT,
                icon: mdiLocationExit,
                // We are using createRelativeUrl to avoid to add baseUrl to the created url
                url: createRelativeUrl(logout()),
                target: '_self',
                type: USER_MENU_LINK_TYPE.LINK,
            },
        ];
    }, []);

    return { baseUserMenuLinks };
};
