import { articleView } from '@lumapps/articles/routes';
import { eventView } from '@lumapps/events/routes';
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';
import { notFoundRoute } from '@lumapps/router/routes';
import { normalizeSlug } from '@lumapps/router/utils';

import { CommunityView, DEFAULT_VIEW } from './constants';

/* Paths */

const COMMUNITY_BASE_URL = '/ls/community';
export const COMMUNITY_PAGES_PATH = `${COMMUNITY_BASE_URL}/:slug/:view?/:identifier?`;
export const COMMUNITY_HOME_PATH = `${COMMUNITY_BASE_URL}/:slug`;
export const COMMUNITY_POSTS_PATH = `${COMMUNITY_BASE_URL}/:slug/${CommunityView.posts}`;
export const COMMUNITY_POST_PATH = `${COMMUNITY_BASE_URL}/:slug/${CommunityView.post}/:identifier`;

export const COMMUNITY_LEGACY_ID = 'app.front.community';

/* View routes */

export const communityPagesRoute: Route = {
    path: COMMUNITY_PAGES_PATH,
    legacyId: COMMUNITY_LEGACY_ID,
    appId: AppId.frontOffice,
};

export const communityLegacyPagesRoute: Route = {
    path: COMMUNITY_PAGES_PATH,
    legacyId: COMMUNITY_LEGACY_ID,
    appId: AppId.legacy,
};

export const communityPostsListRoute: Route = {
    path: COMMUNITY_POSTS_PATH,
    legacyId: COMMUNITY_LEGACY_ID,
    appId: AppId.legacy,
    params: {
        /** Not used in the route path but required for angular router redirection */
        view: CommunityView.posts,
    },
};

export const communityPostDetailsRoute: Route = {
    path: COMMUNITY_POST_PATH,
    legacyId: COMMUNITY_LEGACY_ID,
    appId: AppId.legacy,
    params: {
        /** Not used in the route path but required for angular router redirection */
        view: CommunityView.post,
    },
};

export const communityHomeRoute: Route = {
    path: COMMUNITY_HOME_PATH,
    legacyId: COMMUNITY_LEGACY_ID,
    appId: AppId.legacy,
};

/* Edit routes */

export const communityEditLayoutRoute: Route = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: '/community/edit/:key/:subTemplate',
    params: {
        contentType: 'community',
        // The community id.
        key: undefined,
        // The community view (dashboard, posts, etc.)
        subTemplate: undefined,
    },
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

export const communityTemplateCreateRoute: Route = {
    path: '/community/create/template/posts',
    legacyId: 'app.front.content-create-without-custom-type',
    legacyForceReload: true,
    appId: AppId.legacy,
};

/* Parametrized routes */

export const isCommunityUrl = (url: string) => {
    return url.indexOf(COMMUNITY_BASE_URL) >= 0;
};

export const communityCreateTemplates = ({ fromCommunity = 'false' }: { fromCommunity: 'true' | 'false' }): Route => {
    return { ...communityTemplateCreateRoute, query: { fromCommunity } };
};

export const communityView = (params: {
    id?: string;
    slug: string;
    view?: string;
    identifier?: string;
    anchor?: string;
    instanceSlug?: string;
    query?: Route['query'];
}): Route => {
    const { slug, id, anchor, instanceSlug, query, view, identifier } = params;

    if (identifier && view === 'event') {
        return eventView(identifier, anchor, instanceSlug);
    }
    if (identifier && view === 'article') {
        return articleView(identifier, anchor, instanceSlug);
    }

    // Slug is required
    if (!slug) {
        return notFoundRoute;
    }

    /**
     * Avoid any wrong slugs, which should not start with a / since it will be added
     * during the URL creation. This avoids 404 for valid community links.
     */
    const normalizedSlug = normalizeSlug(slug);

    let mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined;
    if (identifier) {
        mobilePageDescriptor = {
            id: identifier,
            type: 'post',
            section: anchor?.match(/#?comments/) ? 'comments' : undefined,
        };
    } else if (id) {
        mobilePageDescriptor = {
            id,
            type: 'community',
        };
    }

    return {
        ...communityLegacyPagesRoute,
        params: {
            slug: normalizedSlug,
            view,
            identifier,
        },
        anchor,
        query,
        instanceSlug,
        // Add mobile webview page descriptor when possible.
        mobilePageDescriptor,
    };
};

export const communityPostView = (params: {
    slug: string;
    postId: string;
    anchor?: string;
    instanceSlug?: string;
}): Route => {
    const { postId: identifier, ...to } = params;

    return communityView({ ...to, view: CommunityView.post, identifier });
};

export const communityPostsView = (params: { slug: string; anchor?: string; instanceSlug?: string }): Route => {
    return communityView({ ...params, view: CommunityView.posts });
};

export const communityDashboardView = (params: { slug: string; anchor?: string; instanceSlug?: string }): Route => {
    return communityView({ ...params, view: CommunityView.dashboard });
};

export const communityMediaView = (params: { slug: string; anchor?: string; instanceSlug?: string }): Route => {
    return communityView({ ...params, view: CommunityView.media });
};

export const communityCalendarView = (params: { slug: string; anchor?: string; instanceSlug?: string }): Route => {
    return communityView({ ...params, view: CommunityView.calendar });
};
export const communityEditLayout = (communityId: string, view?: CommunityView): Route => {
    return {
        ...communityEditLayoutRoute,
        params: {
            ...communityEditLayoutRoute.params,
            key: communityId,
            subTemplate: view || DEFAULT_VIEW,
        },
    };
};
