import { isCommunitiesAllowed, isCommunitiesFeatureEnabled } from '@lumapps/communities/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { CommunitiesModuleSettingsMenuEntry } from './CommunitiesModuleSettingsMenuEntry';

const mapStateToProps = (state: FrontOfficeStore) => {
    const isAllowed = isCommunitiesAllowed(state);
    const isFeatureEnabled = isCommunitiesFeatureEnabled(state);

    return {
        isAllowed: isAllowed && isFeatureEnabled,
    };
};

const Connected = connect(mapStateToProps)(CommunitiesModuleSettingsMenuEntry);

export { Connected as CommunitiesModuleSettingsMenuEntry };
