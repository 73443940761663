// ///////////////////////////

function WidgetMandatoryReadController($scope, Content, Translation, Widget) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * Indicates if we are editing the content.
     *
     * @type {boolean}
     */
    vm.editingContent = false;

    /**
     * Is widget empty in designer mode.
     */
    vm.isWidgetEmpty = false;

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    vm.Widget = Widget;
    vm.Translation = Translation;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Get widget classes.
     *
     * @return {Array} The widget class.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        widgetClass.push('widget-editable');

        return widgetClass;
    }

    /**
     * Is widget hidden in reading mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        vm.parentCtrl.isHidden = false;

        return vm.parentCtrl.isHidden;
    }

    // ///////////////////////////

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetHidden = isWidgetHidden;

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };
}

// ///////////////////////////

function WidgetMandatoryReadDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetMandatoryReadController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetMandatoryRead', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-mandatory-read/views/widget-mandatory-read.html',
    };
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular.module('Widgets').directive('widgetMandatoryRead', WidgetMandatoryReadDirective);

export { WidgetMandatoryReadDirective, WidgetMandatoryReadController };
