import React from 'react';

const SelectableCardContext = React.createContext<{
    isHover: boolean;
    setIsHover: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export const SelectableCardProvider = SelectableCardContext.Provider;

export const useSelectableCardContext = (componentName: string) => {
    const context = React.useContext(SelectableCardContext);

    if (!context) {
        throw new Error(`${componentName} cannot be rendered outside SelectableCard`);
    }

    return context;
};
