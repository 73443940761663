/* istanbul ignore file */
/** tested with integration tests */
/* eslint-disable no-param-reassign */
import React from 'react';

import { ENVS } from '@lumapps/constants';

/**
 * Depending on the environment, this hook enables or disables the stylesheets for the current customer.
 * - Development: since the page will be rendered by default without these customizations,
 * once the mode is enabled it will add those instance stylesheets to the page
 * - Production: by default, this customization is already present on the page, so if the mode
 * is disabled, we retrieve the styles added to the page (previously tagged by the backend)
 * and disable them.
 */
const useInstanceStylesheets = ({ isEnabled = true, env = 'development' }) => {
    React.useLayoutEffect(() => {
        if (isEnabled) {
            if (env === ENVS.DEVELOPMENT) {
                const instanceStylesheets = window.STYLESHEETS;
                const head = document.head || document.getElementsByTagName('head')[0];

                if (instanceStylesheets) {
                    const stylesheets = instanceStylesheets.split('#$&');

                    stylesheets.forEach((stylesheet) => {
                        const link = document.createElement('link');

                        link.rel = 'stylesheet';
                        link.type = 'text/css';
                        link.href = stylesheet;

                        head.appendChild(link);
                    });
                }
            }
        } else if (env === ENVS.PRODUCTION) {
            document.querySelectorAll('#instance-stylesheet').forEach((headStyle) => {
                if (headStyle) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    headStyle.disabled = true;
                }
            });
        }
    });
};

export { useInstanceStylesheets };
