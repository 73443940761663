import { shape, string, bool } from 'prop-types';

import { widgetType } from '../types';

/** The prop types definition for the widget links. */
const widgetLinksTypes = {
    ...widgetType,
};

/** The prop types definition for a link. */
const linkType = {
    link: shape({
        description: string,
        link: string.isRequired,
        targetBlank: bool,
        thumbnail: string,
        title: string.isRequired,
    }),
};

export { linkType, widgetLinksTypes };
