import React from 'react';

import { classnames } from '@lumapps/classnames';
import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { NavigationItem, NavigationItemProps } from '@lumapps/lumx-navigation/components/NavigationItem';
import { NavigationSection } from '@lumapps/lumx-navigation/components/NavigationSection';
import { NavigationSectionLink } from '@lumapps/lumx-navigation/components/NavigationSectionLink';
import { Theme } from '@lumapps/lumx/react';
import { NavigationItemType, NavigationItem as TNavigationItem } from '@lumapps/navigation/types';
import { getSystemPageDefaultTitle, getSystemPageUrl } from '@lumapps/navigation/utils/systemPageUtils';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import './SubNavigationElement.scss';

export interface SubNavigationElementProps {
    node: TNavigationItem;
    currentContentId: string;
    theme?: Theme;
    isParent?: boolean;
    defaultOpenNodes: Record<string, boolean>;
}

const CLASSNAME = 'sub-navigation-element';

/**
 * A Single Sub Navigation Element. Display the current node depending on its type and render its children recursively.
 */
export const SubNavigationElement: React.FC<SubNavigationElementProps> = ({
    currentContentId,
    node,
    theme,
    isParent,
    defaultOpenNodes,
}) => {
    const { translate, translateObject } = useTranslate();

    // If node.title is undefined, node is a system page without title override; in that case, get its defaultTitle
    const nodeTitle =
        translateObject(standardizeTranslateObject(node.title)) ||
        getSystemPageDefaultTitle(translate, node.systemPageReference?.type);

    const { iconPath } = useDynamicIcon({ icon: node.icon || '' });

    // Most of the clickable nodes are contents. If the node is of another type, update the props
    let linkAs: NavigationItemProps['linkAs'] = ContentLink;
    let linkProps: NavigationItemProps['linkProps'] = {
        to: {
            id: node.pageId,
            // Remove any leading slash (this can happen if parent slug doesn't exist in current language)
            slug: translateObject(standardizeTranslateObject(node.slug))?.replace(/^\//, ''),
            keepParentPath: true,
        },
    };

    // eslint-disable-next-line lumapps/no-manual-bems
    const className = classnames(CLASSNAME, { [`${CLASSNAME}__parent`]: isParent });

    switch (node.type) {
        case NavigationItemType.LINK:
        case NavigationItemType.PAGE:
            if (node.url) {
                linkAs = undefined;
                linkProps = {
                    href: node.url,
                };
            } else if (node.pageType === NavigationItemType.COMMUNITY) {
                linkAs = CommunityLink;
                /**
                 * Community slug need to be parsed since they contain the URL from their
                 * parent item in the navigation. In order to fix that, we just retrieve the last
                 * part of the slug and use it as the communities slug.
                 */
                linkProps.to.slug = translateObject(standardizeTranslateObject(node.slug))?.split('/').pop();
            }

            // If node.newTab is set, take its value into account
            if (node.newTab !== undefined) {
                linkProps.target = node.newTab ? '_blank' : '_self';
            }
            break;
        case NavigationItemType.SYSTEM_PAGE:
            if (node.systemPageReference?.type) {
                linkAs = undefined;
                linkProps = {
                    href: getSystemPageUrl(node.systemPageReference.type),
                    target: node.newTab ? '_blank' : '_self',
                };
            }
            break;
        default:
            break;
    }

    return (
        <>
            {node.type === NavigationItemType.SECTION &&
                // Section
                (node.children && node.children.length > 0 ? (
                    <NavigationSection
                        className={className}
                        key={node.id}
                        label={nodeTitle}
                        icon={node.icon ? iconPath : undefined}
                        theme={theme}
                        openOnMount={defaultOpenNodes[node.id]}
                    >
                        {node.children.map((nodeChild) => (
                            <SubNavigationElement
                                key={nodeChild.id}
                                node={nodeChild}
                                currentContentId={currentContentId}
                                theme={theme}
                                defaultOpenNodes={defaultOpenNodes}
                            />
                        ))}
                    </NavigationSection>
                ) : (
                    // Section without children: use NavigationItem with no link props
                    <NavigationItem
                        className={className}
                        key={node.id}
                        label={nodeTitle}
                        icon={node.icon ? iconPath : undefined}
                        linkAs="div"
                        theme={theme}
                    />
                ))}

            {node.type !== NavigationItemType.SECTION &&
                // Navigation item with children
                (node.children && node.children.length > 0 ? (
                    <NavigationSectionLink
                        className={className}
                        key={node.id}
                        label={nodeTitle}
                        icon={node.icon ? iconPath : undefined}
                        linkAs={linkAs}
                        linkProps={linkProps}
                        isSelected={currentContentId === node.pageId}
                        theme={theme}
                        openOnMount={defaultOpenNodes[node.id]}
                    >
                        {node.children.map((nodeChild) => (
                            <SubNavigationElement
                                key={nodeChild.id}
                                node={nodeChild}
                                currentContentId={currentContentId}
                                theme={theme}
                                defaultOpenNodes={defaultOpenNodes}
                            />
                        ))}
                    </NavigationSectionLink>
                ) : (
                    // Navigation item without children
                    <NavigationItem
                        className={className}
                        key={node.id}
                        label={nodeTitle}
                        icon={node.icon ? iconPath : undefined}
                        linkAs={linkAs}
                        linkProps={linkProps}
                        isSelected={currentContentId === node.pageId}
                        theme={theme}
                    />
                ))}
        </>
    );
};

SubNavigationElement.displayName = 'SubNavigationElement';
