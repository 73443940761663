import convert from 'unist-util-is/convert';

import { Predicate } from '@lumapps/utils/types/Predicate';

import { Element, Node } from '../types';
import { isElement } from '../utils/test';

export type NodeMatcher = (node: Node, index?: number, parent?: Element) => boolean;

export const matchNode = convert;

/**
 * Match a DITA element on its attribute value.
 *
 * @param attributeName    Name of the attribute to match on.
 * @param attributeValueRX RegExp used to validate the attribute value.
 * @param isMatchValid     Predicate function that validate the RegExp match.
 * @return Predicate function on DITA node.
 */
export const matchAttribute =
    (attributeName: string, attributeValueRX: RegExp, isMatchValid?: Predicate<RegExpMatchArray>) =>
    (node: Node): boolean => {
        const attributeValue = isElement(node) && node.attributes?.[attributeName];
        if (!attributeValue) {
            return false;
        }
        const match = String(attributeValue).match(attributeValueRX);
        if (!isMatchValid || !match) {
            return !!match;
        }
        return isMatchValid(match);
    };

/**
 * Check that the node has an attribute.
 */
export const hasAttribute =
    (attributeName: string) =>
    (node: Node): boolean => {
        return isElement(node) && Boolean(node.attributes?.[attributeName]);
    };

/**
 * Check DITA nodes array length.
 */
export const hasLength = (length: number) => (arr: Node[]) => arr.length === length;

export const matchOutputClass =
    (outputClassRX: RegExp, isMatchValid?: Predicate<RegExpMatchArray>) =>
    (node: Node): boolean => {
        const outPutClassValue = isElement(node) && node.outputclass;
        if (!outPutClassValue) {
            return false;
        }
        const match = String(outPutClassValue).match(outputClassRX);
        if (!isMatchValid || !match) {
            return !!match;
        }
        return isMatchValid(match);
    };
