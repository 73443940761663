import React, { useEffect } from 'react';

import { useDispatch } from '@lumapps/redux/react';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { BlockNoResults } from '@lumapps/widget-base/components/Block/BlockNoResults';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { ClientComputedIframeProps } from '../../types';
import { BlockIframe } from '../BlockIframe';

export const ClientComputedIframe: React.FC<ClientComputedIframeProps> = (props) => {
    const dispatch = useDispatch();
    const { uuid, properties } = props;
    const { translateObject } = useTranslate();
    const translatedUrl = translateObject(standardizeTranslateObject(properties?.url));
    const noResults = properties?.noResults;
    const isEmptyWidget = !translatedUrl;

    useEffect(() => {
        if (isEmptyWidget && !noResults) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, isEmptyWidget, noResults, properties, translatedUrl, uuid]);

    if (isEmptyWidget) {
        // only for Client computed widget because will be managed by backend later
        return noResults ? <BlockNoResults /> : null;
    }

    return <BlockIframe properties={properties} id={uuid} />;
};
