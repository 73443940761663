import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { GenericBlock, Size, SkeletonRectangle, SkeletonTypography, Theme } from '@lumapps/lumx/react';

import { FILE_PREVIEW_BLOCK_CLASSNAME as CLASSNAME } from '../../constants';

export const FileBlockSkeleton: React.FC<{
    theme: Theme;
    dateElement: boolean;
    providerElement: boolean;
    actions: ReactNode;
}> = ({ theme, dateElement, providerElement, actions, ...forwardedProps }) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <GenericBlock className={block({ 'is-loading': true, [`theme-${theme}`]: Boolean(theme) })} {...forwardedProps}>
            <GenericBlock.Figure>
                <SkeletonRectangle
                    className={element('icon')}
                    theme={theme}
                    size={Size.m}
                    width={Size.m}
                    height={Size.m}
                />
            </GenericBlock.Figure>
            <GenericBlock.Content>
                <SkeletonTypography theme={theme} typography="body1" width="40%" />
                {(dateElement || providerElement) && (
                    <SkeletonTypography theme={theme} typography="body1" width="20%" />
                )}
            </GenericBlock.Content>
            {actions && (
                <GenericBlock.Actions>
                    <SkeletonRectangle theme={theme} size={Size.m} width={Size.m} height={Size.m} />
                </GenericBlock.Actions>
            )}
        </GenericBlock>
    );
};
