/* eslint-disable no-param-reassign */

import { InstanceState } from '@lumapps/instance/ducks/types';
import { produce } from '@lumapps/redux/immer';
import { TranslationAPI } from '@lumapps/translations';

import { CommunityLinkRef } from '../../types';

export const updateCommunityLinkFromState = (
    communityLinkRef: CommunityLinkRef,
    translateObject: TranslationAPI['translateObject'],
    state: {
        currentInstanceId: string;
        instanceById: InstanceState['entities'];
    },
) =>
    produce(communityLinkRef, (draft) => {
        const instanceId = communityLinkRef.instance ? communityLinkRef.instance.id : state.currentInstanceId;
        const instance = instanceId && state.instanceById[instanceId];

        // Instance is considered up to date if there is none or if it's the current instance.
        let instanceUpToDate = !communityLinkRef.instance?.id;

        if (instanceId === state.currentInstanceId) {
            // The content link ref should not provide the instance if it's the current instance.
            delete draft.instance;
            instanceUpToDate = true;
        } else if (instance) {
            // Update content instance id and slug.
            draft.instance = { id: instance.id, slug: instance.slug };
            // Flag the instance has been updated.
            instanceUpToDate = Boolean(instance.id && instance.slug);
        }

        // Content link ref was marked as stale but the instance are now up to date => remove stale data flag.
        if (draft.hasStaleData && instanceUpToDate) {
            delete draft.hasStaleData;
        }

        return draft;
    });
