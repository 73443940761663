import React from 'react';

import type { MenuContextOptions } from '../Menu/context';
import { MenubarProps } from './types';

/**
 * Handle menubar open/close state and focus behavior.
 *
 * When getting focus on a menubar submenu => if a submenu was open, then open the newly focused submenu
 * Only focused submenu should appear open.
 */
export function useMenubar({ size, theme }: MenubarProps) {
    const orientation = 'horizontal';
    const menuContext: MenuContextOptions = React.useMemo(
        () => ({
            orientation,
            menubarVariant: { size, theme },
        }),
        [size, theme],
    );

    const [focusedMenu, setFocusedMenu] = React.useState<string | undefined>();
    const [openedSubMenu, setOpenedSubMenuState] = React.useState<string | undefined>();
    const setOpenedSubMenu = React.useCallback<React.Dispatch<React.SetStateAction<string | undefined>>>((newId) => {
        setOpenedSubMenuState((prevId) => {
            const id = typeof newId === 'function' ? newId(prevId) : newId;
            if (id) {
                setFocusedMenu(id);
            }
            return id;
        });
    }, []);

    const menuOpenState = React.useMemo(
        () => ({
            openedSubMenu: openedSubMenu === focusedMenu ? openedSubMenu : undefined,
            setOpenedSubMenu,
        }),
        [focusedMenu, openedSubMenu, setOpenedSubMenu],
    );

    const onFocus = React.useCallback(
        (event: React.FocusEvent<HTMLElement>) => {
            const { target } = event;
            const isTriggerElement = target.hasAttribute('aria-haspopup');
            const isItemInMenubar = !!target.closest('[role=menubar] [role^=menuitem]');

            if (event.isPropagationStopped() || !isItemInMenubar) {
                return;
            }

            if (isTriggerElement) {
                // Focus moved to a menu trigger
                const { menuId } = target.dataset;
                setOpenedSubMenu((prevId) => {
                    // If a submenu was previously open => open the current menu
                    if (prevId) {
                        return menuId;
                    }
                    // Else close
                    return undefined;
                });
            } else {
                setFocusedMenu(undefined);
            }
        },
        [setOpenedSubMenu],
    );

    return { orientation, menuContext, menuOpenState, onFocus } as const;
}
