import loFind from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';

/////////////////////////////

function TagService(BaseService, Instance, TagFactory) {
    'ngInject';

    /* eslint-disable consistent-this */
    const service = BaseService.createListKeyService(TagFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the default parameters for the base service.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if a list key exists in the base service.
     *
     * @param  {string}  listKey The list key to check.
     * @return {boolean} If the list key exists in the base service or not.
     */
    function _doesListKeyExist(listKey) {
        return includes(Object.keys(service._services), listKey);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Search for a tag with its id in multiple lists.
     *
     * @param {string}   id The id of the tag we are looking for.
     * @param {Function} cb The function to execute when we found the tag.
     */
    function customerKeyToTag(id, cb) {
        if (!angular.isFunction(cb)) {
            return;
        }

        Instance.getSiblings(false).then(function onSiblingsListSuccess(siblings) {
            for (let i = 0, len = siblings.length; i < len; ++i) {
                const sibling = siblings[i];

                if (!_doesListKeyExist(sibling.id)) {
                    continue;
                }

                const list = service._services[sibling.id]._list;

                for (let j = 0, listLen = list.length; j < listLen; j++) {
                    const item = list[j];

                    if (item.uid === id) {
                        cb(item);

                        return;
                    }
                }
            }

            cb();
        });
    }

    /**
     * Get all the tags of multipls instances.
     *
     * @param {string}   kind         The kind of tags to get.
     * @param {Array}    instanceList The list of instance identifier.
     * @param {Function} cb           The function to execute when the list of tags has been retrieved.
     */
    function getMultiInstanceTags(kind, instanceList, cb) {
        if (!angular.isFunction(cb)) {
            return;
        }

        if (angular.isUndefinedOrEmpty(instanceList) || !angular.isArray(instanceList)) {
            cb();

            return;
        }

        let target = [];
        const instanceCount = instanceList.length;
        let triggerCount = 0;

        // eslint-disable-next-line func-style
        const concatTags = (newTags) => {
            // Poling to return result when all promise or direct call are fulfilled.
            triggerCount++;

            target = target.concat(newTags || []);

            if (triggerCount === instanceCount) {
                return cb(target);
            }

            return undefined;
        };

        angular.forEach(instanceList, function forEachInstance(instance) {
            if (_doesListKeyExist(instance)) {
                concatTags(service.getList(undefined, undefined, instance));

                return;
            }

            service.filterize(
                {
                    instance,
                    kind,
                },
                concatTags,
                function onTagsListError() {
                    concatTags();
                },
                instance,
            );
        });
    }

    /**
     * Get a tag from its id.
     *
     * @param  {string}   [listKey='default'] The list key of the list where to search for the tag.
     * @param  {string}   id                  The id of the tag.
     * @param  {Function} [cb]                The function to execute when the tag has been found.
     * @return {Object}   The tag matching the id.
     */
    function keyToTag(listKey, id, cb) {
        listKey = listKey || 'default';
        cb = cb || angular.noop;

        if (angular.isUndefinedOrEmpty(id)) {
            cb();

            return undefined;
        }

        const list = get(service._services, `[${listKey}]._list`, []);

        const tag = loFind(list, {
            uid: id,
        });

        cb(tag);

        return tag;
    }

    /**
     * Save a tag.
     *
     * @param {Object}   properties          The properties of the tag.
     * @param {Function} [cb]                The function to execute the save succeeded.
     * @param {Function} [errCb]             The function to execute if the save failed.
     * @param {string}   [listKey='default'] The list key in which to save the tag.
     */
    function saveTag(properties, cb, errCb, listKey) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;
        listKey = listKey || 'default';

        service.save(
            { ...properties, instance: Instance.getCurrentInstanceId() },
            function onTagSaveSuccess(response) {
                if (!angular.isArray(get(service._services, `${listKey}._list`))) {
                    cb(response);

                    return;
                }

                let found = false;
                angular.forEach(service._services[listKey]._list, function forEachTagd(tag, index) {
                    if (tag.uid === response.uid) {
                        found = true;
                        service._services[listKey]._list[index] = response;
                    }
                });

                if (!found) {
                    service._services[listKey]._list.push(response);
                }

                cb(response);
            },
            errCb,
        );
    }

    /**
     * Get the id of the given tag.
     *
     * @param {Object}   tag  The tag to get the id of.
     * @param {Function} [cb] The function to execute with the id of the tag.
     */
    function tagToKey(tag, cb) {
        cb = cb || angular.noop;

        cb(get(tag, 'uid'));
    }

    /////////////////////////////

    service.customerKeyToTag = customerKeyToTag;
    service.getMultiInstanceTags = getMultiInstanceTags;
    service.keyToTag = keyToTag;
    service.saveTag = saveTag;
    service.tagToKey = tagToKey;

    /////////////////////////////

    /**
     * Initialize the service.
     *
     * @param {string}   kind                The kind of tags to manage.
     * @param {string}   [listKey='default'] The list key.
     * @param {Function} [cb]                The function to execute when the list of tags suceeded.
     * @param {Function} [errCb]             The function to execute when the list of tags failed.
     */
    service.init = function init(kind, listKey, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        service.defaultParams = {
            instance: Instance.getCurrentInstanceId(),
        };

        const params = angular.fastCopy(service.defaultParams);
        params.kind = kind;

        service.filterize(params, cb, errCb, listKey);
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Tag', TagService);

/////////////////////////////

export { TagService };
