import { IMAGE_ELEMENTS } from '@lumapps/wrex-image/plugin/markdown/deserialize';
import { LINK_ELEMENTS } from '@lumapps/wrex-link/plugin/markdown/deserialize';
import { LIST_ELEMENTS } from '@lumapps/wrex-list/plugin/markdown/deserialize';
import { TABLE_ELEMENTS } from '@lumapps/wrex-table/plugin/markdown/deserialize';
import { TYPOGRAPHY_ELEMENTS, TYPOGRAPHY_MARKS } from '@lumapps/wrex-typography/plugin/markdown/deserialize';
import { USER_MENTION_CUSTOM_ELEMENTS } from '@lumapps/wrex-user-mention/plugin/markdown/deserialize';

import { DeserializeOptions } from './types';

/**
 * Centralized deserialization options for each slate plugin.
 */
export const OPTIONS: DeserializeOptions = {
    /** Mark converters */
    marks: {
        ...TYPOGRAPHY_MARKS,
    },
    /** Element converters */
    elements: {
        ...TYPOGRAPHY_ELEMENTS,
        ...TABLE_ELEMENTS,
        ...LIST_ELEMENTS,
        ...LINK_ELEMENTS,
        ...IMAGE_ELEMENTS,
    },
    /** Custom element (non standard MD element) parser. */
    customElements: {
        ...USER_MENTION_CUSTOM_ELEMENTS,
    },
};
