import { produce } from 'immer';

import { UPDATE_WIDGET_CONTENT } from './widget_actions';

let initialState = {
    currentWidget: {},
};

/**
 * Set initial state from instance service (set at init).
 * @param {Object} state The initial state.
 */
function setInitialState(state) {
    initialState = state;
}

const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case '__angular__/widget/update': {
                const { currentWidget } = action.newState;
                draft.currentWidget = currentWidget;

                return draft;
            }

            case UPDATE_WIDGET_CONTENT: {
                if (action.payload.lang) {
                    draft.currentWidget.properties.content[action.payload.lang] = action.payload.content;
                }

                return draft;
            }

            default:
                return draft;
        }
    });
};

export { reducer, setInitialState };
