import { connect } from '@lumapps/redux/react';

import { createStructuredSelector } from '@lumapps/redux/reselect';

import { loadingStateSelector } from 'components/components/ducks/loadingStates/selectors';

import {
    setFormFieldValue,
    setSelectOpen,
    setSelectSearch,
    setContentUrlValue,
    setImages,
    removeImage,
    fetchProgramTopics,
} from 'components/components/social-advocacy/ducks/shareableWorkflow/actions';

import {
    getMostUsedTopics,
    getRecentTopics,
    getFormIsValid,
    getFilteredTopics,
    getShareableWorkflowIncompatibleNetworks,
    getShareableWorkflowShareableContent,
    getShareableWorkflowContentId,
    getShareableWorkflowContentPreview,
    getShareableworkflowForm,
    getAvailableSocialNetworks,
    getShareableWorkflowProgramId,
} from 'components/components/social-advocacy/ducks/shareableWorkflow/selectors';

import { getTopicsList } from 'components/components/social-advocacy/ducks/topics/selectors';

import { SAShareableWorkflowForm } from './SAShareableWorkflowForm';

const mapStateToProps = createStructuredSelector({
    programId: getShareableWorkflowProgramId,
    contentId: getShareableWorkflowContentId,
    contentPreview: getShareableWorkflowContentPreview,
    filteredTopics: getFilteredTopics,
    form: getShareableworkflowForm,
    formIsValid: getFormIsValid,
    incompatibleSocialNetworks: getShareableWorkflowIncompatibleNetworks,
    isLoadingPreview: (state) => loadingStateSelector(state, 'GET_CONTENT_PREVIEW'),
    mostUsedTopics: getMostUsedTopics,
    recentTopics: getRecentTopics,
    shareableContent: getShareableWorkflowShareableContent,
    socialNetworks: getAvailableSocialNetworks,
    topics: getTopicsList,
});

const mapDispatchToProps = {
    removeImage,
    setContentUrlValue,
    setFormFieldValue,
    setImages,
    setSelectOpen,
    setSelectSearch,
    fetchProgramTopics,
};

const ConnectedSAShareableWorkflowForm = connect(mapStateToProps, mapDispatchToProps)(SAShareableWorkflowForm);

export { ConnectedSAShareableWorkflowForm as SAShareableWorkflowForm };
