import findKey from 'lodash/findKey';
import get from 'lodash/get';
import { utc } from 'moment';

import { BaseSearchResult, Field, ThirdPartyFiltersTemplate, ThirdPartyCustomField } from '@lumapps/search/types';
import { GLOBAL } from '@lumapps/translations';
import { TranslationAPI } from '@lumapps/translations/types';

import { props as contentMetadataProps } from '../../ContentMetadata/props';

export interface ThirdPartyPropsOptions {
    templates?: ThirdPartyFiltersTemplate[];
    translate: TranslationAPI['translate'];
}

const props = (result: BaseSearchResult, baseProps: any, options: any) => {
    const { url, name, snippet, onClick, flag, quickViewUrl, onQuickViewClick } = baseProps;
    const bProps = {
        url,
        name,
        snippet,
        quickViewUrl,
        onQuickViewClick,
    };

    const { templates, translate } = options;

    const { metadata } = result;
    const { sourceUid, fields } = metadata;
    const resultMetadata: ThirdPartyFiltersTemplate = templates?.find(
        (t: ThirdPartyFiltersTemplate) => t.id === sourceUid,
    );

    const fieldsMetadata = resultMetadata ? resultMetadata.fields : undefined;

    const formattedFields: { name: string; value: string; layout: number }[] = [];

    /**
     * TODO: we really need to talk to the backend guys about this code, we are doing so much
     * formatting and sorting on the frontend that takes really a lot of precious time.
     * - fields should be already sorted from the backend
     * - there should not be `textValues.values` and `integerValues.values`, just values.
     */

    if (fields) {
        fields.forEach((field: Field) => {
            /**
             * For each field, we need to determine the type of field that we need to render,
             * and the name that it has. Each field has a `name`, that allows us to determine the metadata
             * that it has, which comes from the `tabs` field in the backend's response.
             */
            const { name: fieldName, lokalizedName, dateValues, ...restOfValues } = field;

            const fieldMetadata = fieldsMetadata?.find((f: ThirdPartyCustomField) => translate(f.path) === fieldName);

            if (fieldMetadata) {
                const { layout, localizedName } = fieldMetadata;

                let value = '';

                /**
                 * If the values to display are of type date, we need to parse it using moment and render it.
                 */
                if (dateValues) {
                    const dateValue = dateValues.values[0];
                    const date = [dateValue.day, dateValue.month, dateValue.year].join('-');

                    value = utc(date)
                        .local()
                        .format(translate(GLOBAL.D_M_YYYY) as string);
                } else {
                    /**
                     * If not, instead of doing a check for every single type of value that we can render,
                     * we go through the keys and find the ones that end in `Values`. We then use that to render
                     * the values.
                     *
                     * TODO: this needs to go ASAP, it is really a bad practice and something that we really have no need to
                     * do since we will render any value as a list of values concatenated by `,`
                     */
                    const valuesKey = findKey(restOfValues, (v, key) => key.endsWith('Values'));

                    const values = get(field, [valuesKey as string, 'values'], []);
                    value = values.join(', ');
                }

                if ((localizedName || fieldName) && value !== '') {
                    formattedFields.push({
                        name: (localizedName ? translate(localizedName) : fieldName) as string,
                        value,
                        layout,
                    });
                }
            } else if (field.textValues && (field.textValues?.values?.length || 0) > 0) {
                const value = field.textValues.values.join(', ');

                if (value) {
                    formattedFields.push({
                        name: lokalizedName ? translate(lokalizedName) : fieldName,
                        value,
                        layout: 1,
                    });
                }
            } else if (field.integerValues && (field.integerValues?.values?.length || 0) > 0) {
                const value = field.integerValues.values.join(', ');

                if (value) {
                    formattedFields.push({
                        name: lokalizedName ? translate(lokalizedName) : fieldName,
                        value,
                        layout: 1,
                    });
                }
            }
        });
    }

    return {
        ...bProps,
        id: `${sourceUid}-${encodeURIComponent(name)}`,
        metadata: contentMetadataProps(result, { ...options, useUpdateTime: true }),
        image: metadata.thumbnailUrl,
        alt: name,
        fields: formattedFields,
        title: name,
        isMetadataVisible: resultMetadata?.isMetadataVisible,
        isSnippetVisible: resultMetadata?.isSnippetVisible,
        position: result.position,
        onClick,
        flag,
    };
};

export { props };
