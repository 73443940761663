import partial from 'lodash/partial';

import { insertImageGallery } from '@lumapps/wrex-image-gallery/utils/insertImageGallery';
import { updateImageGalleryNode } from '@lumapps/wrex-image-gallery/utils/updateImageGalleryNode';
import { Editor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { getNodesFromSlateData } from '@lumapps/wrex/slate/utils/getNodesFromSlateData';
import { selectElementOnDelete } from '@lumapps/wrex/slate/utils/selectElementOnDelete';
import { WrexEditor } from '@lumapps/wrex/types';

import { AlignedImageWrapper } from '../components/blocks/AlignedImageWrapper';
import { EditableImageGroup } from '../components/editableBlocks/EditableImageGroup';
import { IMAGE_FEATURES, IMAGE_GROUP } from '../constants';
import { ImageEditor, ImageEditorOptions } from '../types';
import { changeImageAlignment } from '../utils/changeImageAlignment';
import { changeImageWidth } from '../utils/changeImageWidth';
import { insertImage } from '../utils/insertImage';
import { isImageGallery } from '../utils/isImageGallery';
import { isImageGroup } from '../utils/isImageGroup';
import { updateImageNode } from '../utils/updateImageNode';
import { getKeyHandler } from './event/keyboard';
import { normalizeAlignedImageWrapper } from './normalize/normalizeAlignedImageWrapper';
import { normalizeImage } from './normalize/normalizeImage';

const createPluginEditor =
    (options: ImageEditorOptions): CreatePluginEditor<ImageEditor, WrexEditor<ImageEditor>> =>
    (editor) => {
        const { isVoid, deleteBackward, deleteForward, normalizeNode, insertData, insertNode } = editor;

        return {
            insertData: (data: DataTransfer) => {
                /*
                 * we check if data is not HTML for avoiding formating text in image
                 * when copying from Microsoft Word native app
                 * */
                if (data.getData('text/html')) {
                    insertData(data);
                    return;
                }
                if (data.files && data.files.length > 0) {
                    const files = Array.from(data.files);

                    if (files.every((f) => f.type.startsWith('image/'))) {
                        if (editor.isImageFeatureEnabled(IMAGE_FEATURES.imageGallery) && files.length > 1) {
                            const imageFiles = files.map((file) => ({
                                file,
                            }));

                            for (let i = 0; i < imageFiles.length; i++) {
                                // insert a new image gallery each time we reach the limit (15 images per image gallery)
                                if (i % 15 === 0 || i === 0) {
                                    const slicedImages = imageFiles.slice(i, i + 15);
                                    editor.insertImageGallery(slicedImages);
                                }
                            }
                        } else {
                            files.forEach((image) => {
                                insertImage(options, editor, {}, [image]);
                            });
                        }

                        return;
                    }
                    insertData(data);
                } else {
                    // Paste image gallery nodes as inline image when the feature is disabled
                    if (!options.enabledFeatures?.includes(IMAGE_FEATURES.imageGallery)) {
                        const nodes = getNodesFromSlateData(data);
                        if (nodes) {
                            nodes.forEach((element) => {
                                if (isImageGroup(element) && element.viewMode) {
                                    // Insert an image inline for each images in the image gallery
                                    element.images.forEach((image) => {
                                        insertImage(options, editor, {
                                            type: IMAGE_GROUP,
                                            isLoading: false,
                                            images: [image],
                                        });
                                    });
                                } else if (isImageGroup(element)) {
                                    insertImage(options, editor, { ...element, isLoading: false });
                                } else {
                                    insertNode(element as any);
                                }
                            });
                        }
                        // Continue with editor insertData if no slate are on the data
                        else {
                            insertData(data);
                        }

                        return;
                    }

                    insertData(data);
                }
            },

            isVoid: (element) => isImageGroup(element) || isVoid(element),

            normalizeNode([node, path]) {
                const pathRef = Editor.pathRef(editor, path);
                if (normalizeImage(options, editor, [node, path])) {
                    return;
                }

                if (normalizeAlignedImageWrapper(editor, [node, path])) {
                    return;
                }
                const changedPath = pathRef.unref();
                if (changedPath) {
                    normalizeNode([node, changedPath]);
                }
            },

            isImageFeatureEnabled: (feature) => Boolean(options.enabledFeatures?.includes(feature)),

            isImageGallery: partial(
                isImageGallery,
                Boolean(options.enabledFeatures?.includes(IMAGE_FEATURES.imageGallery)),
            ),

            deleteBackward(unit) {
                if (unit === 'character' && selectElementOnDelete(editor, false, isImageGroup)) {
                    return;
                }
                deleteBackward(unit);
            },

            deleteForward(unit) {
                if (unit === 'character' && selectElementOnDelete(editor, true, isImageGroup)) {
                    return;
                }
                deleteForward(unit);
            },

            insertImage: partial(insertImage, options, editor),

            updateImageNode: partial(updateImageNode, editor),

            updateImageGalleryNode: partial(updateImageGalleryNode, options, editor),

            changeImageAlignment: partial(changeImageAlignment, editor),

            changeImageWidth: partial(changeImageWidth, editor),

            insertImageGallery: partial(insertImageGallery, options, editor),
        };
    };

/**
 * Plugin rendering elements:
 * - Images
 */
export const withEnhancedImage = (options: ImageEditorOptions) =>
    createPlugin({
        createPluginEditor: createPluginEditor(options),
        elements:
            options.enabledFeatures && options.enabledFeatures.includes(IMAGE_FEATURES.alignment)
                ? [AlignedImageWrapper, EditableImageGroup]
                : [EditableImageGroup],
        getKeyHandler,
    });
