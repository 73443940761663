import React from 'react';

import { SPACES } from '@lumapps/communities/keys';
import { spacesAdminRoute } from '@lumapps/community-admin/routes';
import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiCubeUnfolded } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

interface SpacesSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}
export const SpacesSideNavigation = ({ isAllowed }: SpacesSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(SPACES.NAV)}
            icon={mdiCubeUnfolded}
            route={spacesAdminRoute}
            id="spaces"
        />
    );
};
