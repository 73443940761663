/**
 * Custom select field choice renderers.
 */

import React from 'react';

import repeat from 'lodash/repeat';

import { ListItem, ListSubheader, Size, Checkbox } from '@lumapps/lumx/react';

import { ChoiceRenderer } from './types';

/**
 * Render choices with indentation levels.
 */
export function defaultChoiceRender<C>({
    choices = [],
    getValueName,
    getValueId = String,
    canSelectionBeEmpty = true,
}: {
    choices?: C[];
    getValueName: (value: C) => React.ReactNode;
    getValueId?: (value: C) => string;
    canSelectionBeEmpty: boolean;
}): ChoiceRenderer<C> {
    return (value, onSelect) =>
        choices.map((choice) => {
            const id = getValueId(choice);
            const isSelected = !!value.find((v) => getValueId(v) === id);
            const shouldBeUnableToRemove = !canSelectionBeEmpty && value.length === 1;
            const canBeClicked = isSelected ? !shouldBeUnableToRemove : true;

            const onItemSelected = () => {
                if (canBeClicked) {
                    onSelect(choice, isSelected)();
                }
            };

            return (
                <ListItem
                    key={getValueId(choice)}
                    size={Size.tiny}
                    isSelected={isSelected}
                    onItemSelected={onItemSelected}
                >
                    {getValueName(choice)}
                </ListItem>
            );
        });
}

/**
 * Render choices with indentation levels.
 */
export function renderChoiceWithLevels<C>({
    choices,
    getValueName = String,
    getValueId = String,
    getValueLevel,
}: {
    choices: C[];
    getValueId?: (value: C) => string;
    getValueName?: (value: C) => React.ReactNode;
    getValueLevel: (value: C) => number | undefined;
}): ChoiceRenderer<C> {
    return (value, onSelect) =>
        choices.map((choice) => {
            const level = getValueLevel(choice);
            const id = getValueId(choice);
            const isSelected = !!value.find((v) => getValueId(v) === id);
            return (
                <ListItem
                    key={getValueId(choice)}
                    size={Size.tiny}
                    isSelected={isSelected}
                    onItemSelected={onSelect(choice, isSelected)}
                >
                    {repeat('—', level ?? 0)}
                    {getValueName(choice)}
                </ListItem>
            );
        });
}

/**
 * Render choices in groups.
 */
// eslint-disable-next-line no-use-before-define
export function renderGroupedChoice<G extends { group: GO; children: C[] }, C, GO>({
    groups = [],
    getGroupName,
    getValueName,
    getValueId = String,
}: {
    groups?: G[];
    getGroupName: (group: GO) => string;
    getValueName: (value: C) => React.ReactNode;
    getValueId?: (value: C) => string;
}): ChoiceRenderer<C> {
    return (value, onSelect) => {
        return groups.map(({ group, children }) => {
            const groupName = getGroupName(group);
            return (
                <React.Fragment key={groupName}>
                    <ListSubheader>{groupName}</ListSubheader>
                    {children.map((child) => {
                        const isSelected = !!value.find((v) => getValueId(v) === getValueId(child));
                        return (
                            <ListItem
                                key={getValueId(child)}
                                size={Size.tiny}
                                isSelected={isSelected}
                                onItemSelected={onSelect(child, isSelected)}
                            >
                                {getValueName(child)}
                            </ListItem>
                        );
                    })}
                </React.Fragment>
            );
        });
    };
}

/**
 * Render choices with checkboxes.
 */
export function renderChoiceWithCheckbox<C>({
    choices = [],
    getValueName,
    getValueId = String,
}: {
    choices?: C[];
    getValueName: (value: C) => React.ReactNode;
    getValueId?: (value: C) => string;
}): ChoiceRenderer<C> {
    return (value, onSelect) =>
        choices.map((choice) => {
            const id = getValueId(choice);
            const isSelected = !!value.find((v) => getValueId(v) === id);
            return (
                <ListItem
                    key={getValueId(choice)}
                    size={Size.tiny}
                    isSelected={isSelected}
                    onItemSelected={onSelect(choice, isSelected)}
                    before={<Checkbox inputProps={{ tabIndex: -1 }} isChecked={isSelected} />}
                >
                    {getValueName(choice)}
                </ListItem>
            );
        });
}
