/* eslint-disable lumapps/no-classname-strings */
/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import shuffle from 'lodash/shuffle';

import { classnames } from '@lumapps/classnames';
import { MAX_IMAGE_SIZE } from '@lumapps/constants';
import { ContentHeaderDisplayType } from '@lumapps/contents/types';
import {
    Slides,
    SlideshowItem,
    AspectRatio,
    Thumbnail,
    SlideshowControls,
    Theme,
    FlexBoxProps,
} from '@lumapps/lumx/react';
import { Media } from '@lumapps/medias/types';
import { getMediaContent, getImageUrlFromContent, makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { resizeImage } from '@lumapps/medias/utils/resizeImage';
import { useTranslate } from '@lumapps/translations';
import { useFocusWithin } from '@lumapps/utils/hooks/useFocusWithin';
import { useSlideshowControlLabels } from '@lumapps/utils/hooks/useSlideshowControlLabels';
import { useIsAutoTestEnabled } from '@lumapps/utils/test/useIsAutoTestEnabled';

import { ContentHeaderOverlay } from '../ContentHeaderOverlay/ContentHeaderOverlay';

import './index.scss';

export interface ContentHeaderSlideshowProps extends FlexBoxProps {
    displayType?: ContentHeaderDisplayType;
    hasAutoplay?: boolean;
    hasRandom?: boolean;
    autoplayInterval?: number;
    mediaList: Media[];
    wrapperHeight?: number;
    slideshowHeight?: number;
    currentLanguage: string;
    mediaClass?: Record<string, string>;
}

/**
 * Unfortunately the backend returns the image description with a <p> surrounding it. In order to
 * use this description as the alt text of an image, it needs some cleanup in order to avoid
 * the <p></p> while the user is using a screen reader.
 * @param description - media description
 */
const cleanUpDescription = (description?: string) => {
    if (description) {
        return description.replace('<p>', '').replace('</p>', '');
    }

    return '';
};

export const CLASSNAME = 'header-slideshow';

const ContentHeaderSlideshow = ({
    displayType,
    hasAutoplay,
    hasRandom,
    autoplayInterval,
    mediaList = [],
    mediaClass = {},
    wrapperHeight,
    slideshowHeight,
    currentLanguage,
    ...rest
}: ContentHeaderSlideshowProps) => {
    const { className, ...wrapperProps } = rest;
    const { translateObject } = useTranslate();
    const parentRef = React.useRef(null);
    const orderedMediaList = React.useMemo(() => (hasRandom ? shuffle(mediaList) : mediaList), [hasRandom, mediaList]);
    const isAutoTestQueryParamPresent = useIsAutoTestEnabled();
    const isAutoPlayEnabled = hasAutoplay && !isAutoTestQueryParamPresent;

    const {
        activeIndex,
        slideshowId,
        setSlideshow,
        isAutoPlaying,
        slideshowSlidesId,
        toggleAutoPlay,
        toggleForcePause,
        onNextClick,
        onPaginationClick,
        onPreviousClick,
        stopAutoPlay,
        startAutoPlay,
        slideshow,
    } = SlideshowControls.useSlideshowControls({
        itemsCount: orderedMediaList.length,
        autoPlay: isAutoPlayEnabled,
        interval: autoplayInterval ? autoplayInterval * 1000 : undefined,
    });

    const labels = useSlideshowControlLabels({ isAutoPlaying });

    useFocusWithin({
        element: slideshow,
        onFocusIn: stopAutoPlay,
        onFocusOut: startAutoPlay,
        shouldFocus: isAutoPlayEnabled,
    });

    const currentSlide = orderedMediaList[activeIndex];
    const maxIndex = orderedMediaList.length - 1;

    return (
        <div
            className={classnames(CLASSNAME, className, { [`${CLASSNAME}--${displayType}`]: displayType })}
            {...wrapperProps}
        >
            <Slides
                fillHeight
                style={{ height: slideshowHeight }}
                activeIndex={activeIndex}
                id={slideshowId}
                ref={setSlideshow}
                isAutoPlaying={isAutoPlaying}
                autoPlay
                slidesId={slideshowSlidesId}
                toggleAutoPlay={toggleAutoPlay}
                groupBy={1}
                slideshow={slideshow}
                slideGroupLabel={labels.slideGroup}
            >
                {orderedMediaList.map((media) => {
                    const mediaContent = getMediaContent(media, translateObject, currentLanguage);
                    const mediaUrl = makeSecuredMediaURLRelative(getImageUrlFromContent(mediaContent));
                    return mediaUrl ? (
                        <SlideshowItem
                            key={media.uid}
                            className={mediaClass[media.uid] ? `slide--${mediaClass[media.uid]}` : undefined}
                        >
                            <Thumbnail
                                fillHeight
                                aspectRatio={AspectRatio.free}
                                image={resizeImage(mediaUrl, MAX_IMAGE_SIZE)}
                                alt={cleanUpDescription(mediaContent.description)}
                            />
                        </SlideshowItem>
                    ) : null;
                })}
            </Slides>

            <ContentHeaderOverlay
                className={`${CLASSNAME}__overlay`}
                displayType={displayType}
                title={currentSlide?.override?.name && (translateObject(currentSlide.override.name) as string)}
                description={
                    currentSlide?.override?.description &&
                    (translateObject(currentSlide.override.description) as string)
                }
                wrapperHeight={wrapperHeight}
            >
                {maxIndex > 0 && (
                    <SlideshowControls
                        className={`${CLASSNAME}__controls`}
                        parentRef={parentRef}
                        slidesCount={orderedMediaList.length}
                        activeIndex={activeIndex}
                        onNextClick={onNextClick}
                        onPreviousClick={onPreviousClick}
                        onPaginationClick={onPaginationClick}
                        isAutoPlaying={isAutoPlaying}
                        theme={Theme.dark}
                        nextButtonProps={{ label: labels.next }}
                        previousButtonProps={{ label: labels.previous }}
                        playButtonProps={
                            isAutoPlayEnabled
                                ? {
                                      label: labels.play,
                                      'aria-controls': slideshowSlidesId,
                                      onClick: toggleForcePause,
                                  }
                                : undefined
                        }
                        paginationItemProps={(index) => ({ label: labels.paginationItem(index) })}
                    />
                )}
            </ContentHeaderOverlay>
        </div>
    );
};

export { ContentHeaderSlideshow };
