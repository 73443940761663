/* istanbul ignore file */
/** tested with integration tests */
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { tokenApi } from '@lumapps/user/api/token';
import { actions } from '@lumapps/user/ducks/slice';

import { useInjectTapper } from './useInjectTapper';

/**
 * Give access to a function to refresh the lumapps token from a global variable
 * Eg. renew the token from external extensions with the`lumapps-sdk-js` library
 */
const useRefreshTokenTapper = () => {
    const dispatch = useDispatch();
    const customerId = useSelector(customerIdSelector);
    useInjectTapper({
        refreshToken: () => {
            return tokenApi.post('token', { customerId }).then((response) => {
                if (response.status === 200 && response.data.status) {
                    dispatch(actions.setToken(response.data.token));
                }
                return response;
            });
        },
    });
};

export { useRefreshTokenTapper };
