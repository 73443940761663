import React, { useRef, useState } from 'react';

import { Link, Text } from '@lumapps/lumx/react';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { BlockPostIdeaStatus } from '../../../types';
import { PostIdeaStatusDialog } from './PostIdeaStatusDialog';

interface PostIdeaStatusLink {
    /** User can edit post. */
    className?: string;
    /** User can edit post. */
    canEdit?: boolean;
    /** Post identifier. */
    postId: string;
    /** Post idea status. */
    ideaStatus?: BlockPostIdeaStatus;
}

/**
 * A link displaying the post idea status and displays an idea status selection dialog on click.
 */
export const PostIdeaStatusLink: React.FC<PostIdeaStatusLink> = (props) => {
    const { className, canEdit, postId, ideaStatus } = props;

    const linkRef = useRef() as React.RefObject<HTMLAnchorElement>;
    const [currentIdeaStatus, setCurrentIdeaStatus] = useState(ideaStatus);
    const [isPostIdeaDialogOpen, togglePostIdeaDialogOpen] = useBooleanState(false);

    if (!currentIdeaStatus) {
        return null;
    }

    if (!canEdit) {
        // Idea status displayed but not editable.
        return (
            <Text as="span" className={className}>
                {currentIdeaStatus.label}
            </Text>
        );
    }

    // Idea status displayed and editable in a dialog.
    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className={className} ref={linkRef} onClick={togglePostIdeaDialogOpen}>
                {currentIdeaStatus.label}
            </Link>

            <PostIdeaStatusDialog
                postId={postId}
                isOpen={isPostIdeaDialogOpen}
                onClose={togglePostIdeaDialogOpen}
                onSave={setCurrentIdeaStatus}
                currentIdeaStatus={currentIdeaStatus}
                parentElement={linkRef}
            />
        </>
    );
};
