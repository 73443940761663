(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserDirectoryController(
        $location,
        $stateParams,
        Config,
        Content,
        Customer,
        Features,
        Feed,
        Metadata,
        ModuleFront,
        Translation,
        User,
        UserDirectory,
        UserOrganizationChart,
        Utils,
    ) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The files types allowed to be uploaded as a user thumbnail.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        vm.ALLOWED_FILE_TYPE = Config.FILE_TYPES.IMAGE;

        /**
         * Contains the components usable as filters of the user directory.
         *
         * @type {Array}
         */
        vm.filterComponents = [];

        /**
         * Contains the values of the filter.
         *
         * @type {Object}
         */
        vm.filterModel = {};

        /**
         * Indicates if the filters panel is opened.
         *
         * @type {boolean}
         */
        vm.filterOpen = false;

        /**
         * Contains the secondary field displayed under the name of the displayed user.
         *
         * @type {Object}
         */
        vm.secondaryField = {};

        /**
         * The tip widget to be displayed on top of the user directory list.
         *
         * @type {Object}
         */
        vm.tipWidget = {};

        /**
         * Contains all the components that are dsplayed in the user directory list.
         *
         * @type {Array}
         */
        vm.userListComponents = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Content = Content;
        vm.Features = Features;
        vm.Feed = Feed;
        vm.Metadata = Metadata;
        vm.ModuleFront = ModuleFront;
        vm.Translation = Translation;
        vm.User = User;
        vm.UserDirectory = UserDirectory;
        vm.UserOrganizationChart = UserOrganizationChart;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Build the list of the components that can be used as filters in the user directory list.
         */
        function _buildFilterComponents() {
            vm.filterComponents = _.get(Content.getCurrent(), 'template.components', []).filter(
                function filterComponents(component) {
                    return _.get(component, 'properties.displayInFilter');
                },
            );
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the user can edit other user names or not.
         *
         * @return {boolean} Whether or not the current user can edit names.
         */
        function canEditOthersName() {
            return _.get(User.getConnected(), 'isSuperAdmin', false);
        }

        /**
         * Filter the list of users in the directory according to the filters.
         *
         * @param {boolean} isInit Indicates if it's the first list done at the initialization.
         */
        function filterUsers(isInit) {
            var user = User.getConnected();

            var criteria = {};
            angular.forEach(vm.filterModel, function forEachFilterValue(filterValue, key) {
                var customFilter = _.find(vm.filterComponents, {
                    uuid: key,
                });

                // If it's a custom filter.
                if (angular.isDefinedAndFilled(customFilter)) {
                    var properties = _.get(customFilter, 'properties', {});

                    var pattern = key;
                    if (properties.isBound) {
                        pattern = _.get(properties, 'boundMap.text', '');
                    }

                    criteria[pattern] = filterValue;
                } else {
                    // All other standard filters (feeds, first name, ...).
                    criteria[key] = filterValue;
                }
            });

            var currentContent = Content.getCurrent();

            var params = {
                contentId: currentContent.id,
                profileCriteria: criteria,
            };

            if (angular.isDefinedAndFilled(vm.filterModel.feeds)) {
                params.feeds = vm.filterModel.feeds;
            }

            if (angular.isUndefinedOrEmpty([user, params.contentId], 'every')) {
                params.customerId = Customer.getCustomerId();
            }

            if (isInit) {
                params.maxResults = 12;

                var email = $location.search().email;
                var contentProperties = _.get(currentContent, 'properties', {});
                if (angular.isDefinedAndFilled(email)) {
                    UserDirectory.setDisplayedUserOrRedirect(email, true);
                } else if (contentProperties.displayUserProfile && angular.isDefinedAndFilled(user)) {
                    UserDirectory.setDisplayedUserOrRedirect(user.id);
                }
            }

            UserDirectory.filterize(params);
        }

        /**
         * Reset all the filters.
         */
        function resetFilter() {
            vm.filterModel = {};

            vm.filterUsers();
        }

        /**
         * Toggle filter.
         */
        function toggleFilter() {
            vm.filterOpen = !vm.filterOpen;
        }

        /////////////////////////////

        vm.canEditOthersName = canEditOthersName;
        vm.filterUsers = filterUsers;
        vm.resetFilter = resetFilter;
        vm.toggleFilter = toggleFilter;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentContent = Content.getCurrent();

            vm.tipWidget = _.find(_.get(currentContent, 'template.components', []), {
                type: 'widget',
            });

            var properties = _.get(currentContent, 'properties', {});

            vm.userListComponents = UserDirectory.getPopoverFields(currentContent, User.getConnected());

            _buildFilterComponents();

            if (properties.displayFilters) {
                vm.filterOpen = true;
            }

            vm.filterModel = {};

            var groups = $location.search().group;
            if (angular.isDefinedAndFilled(groups)) {
                vm.filterModel.feeds = angular.isArray(groups) ? groups : [groups];
            }

            if (angular.isDefinedAndFilled([$stateParams.customData, vm.filterModel.feeds], 'some')) {
                vm.filterOpen = true;

                vm.filterModel = angular.extend(vm.filterModel, $stateParams.customData || {});
            }

            vm.filterUsers(true);
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('UserDirectoryController', UserDirectoryController);
})();
