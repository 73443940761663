import loGet from 'lodash/get';

/////////////////////////////

function SocialSubscriptionService(
    $cacheFactory,
    $injector,
    $rootScope,
    Instance,
    LumsitesBaseService,
    SocialSubscriptionFactory,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(SocialSubscriptionFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Contains the mapping between the follower types and the properties to use.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _FOLLOWER_MAPPING = {
        feed: 'followerFeedKey',
        user: 'followerUserKey',
    };

    /**
     * Contains the mapping between the following types and the properties to use.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _FOLLOWING_MAPPING = {
        content: 'followingContentKey',
        search: 'followingSearchQuery',
        user: 'followingUserKey',
    };

    /**
     * A cache object filled with objects holding entities / subscriptions. The current user is following.
     * The elements are only added when do a get for now (not list/save).
     *
     * @type {Cache}
     */
    const _followingCache = $cacheFactory('followingCache');

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the possible types of following.
     *
     * @type {Object}
     * @readonly
     */
    service.FOLLOWING_TYPES = {
        content: 'content',
        interest: 'interest',
        search: 'search',
        user: 'user',
    };

    /**
     * Contains the possible types of subscription.
     *
     * @type {Object}
     * @readonly
     */
    service.SUBSCRIPTION_TYPES = {
        FEED_CONTENT: 'FEED_CONTENT',
        USER_CONTENT: 'USER_CONTENT',
    };

    /**
     * Indicates what the service is doing.
     *
     * @type {Object}
     */
    service.is = {
        following: {},
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Remove a given entity from the cache list.
     *
     * @param {string} entityId The identifier of the entity to remove.
     * @param {string} kind     The kind of entity to be removed.
     */
    function _removeFollowing(entityId, kind) {
        if (angular.isUndefinedOrEmpty([kind, entityId], 'some') || !service.isFollowing(entityId, kind)) {
            return;
        }

        _followingCache.remove(`${entityId}${kind}`.toLowerCase());
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add an entity to the cached _following object list for the current user.
     *
     * @param  {string} entityId     The identifier of the entity to be added.
     * @param  {string} kind         The kind of entity to be added.
     * @param  {Object} subscription The subscription object related to the entity.
     * @return {Object} The object being added to the cache list.
     */
    function addFollowing(entityId, kind, subscription) {
        if (angular.isUndefinedOrEmpty([kind, entityId, loGet(subscription, 'id')], 'some')) {
            return undefined;
        }

        const valueToBeCached = {
            entityId,
            subscription,
            type: kind,
        };

        const key = `${entityId}${kind}`.toLowerCase();

        /**
         * To update an existing item in the cache list, we need to remove it completely first.
         * In theory we only want to update the entity property but we may as well update it all.
         */
        if (service.isFollowing(subscription.id, kind)) {
            _followingCache.remove(key);
        }

        _followingCache.put(key, valueToBeCached);

        return valueToBeCached;
    }

    /**
     * Follow a subscription.
     *
     * @param {string}   entityId   The id of the entity we want to follow.
     * @param {string}   kind       The kind of the entity to follow.
     * @param {string}   followerId The id of the user that want to follow the entify.
     * @param {boolean}  [notify]   Indicates if we want to set notifications as well when following the entity.
     * @param {Function} [cb]       The callback to execute when the follow is successful.
     * @param {Function} [errCb]    The callback to execute when the follow is in error.
     * @param {string}   listKey    The list key.
     */
    function follow(entityId, kind, followerId, notify, cb, errCb, listKey) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        if (angular.isUndefinedOrEmpty([kind, entityId, followerId], 'some')) {
            return;
        }

        if (service.is.following[listKey]) {
            return;
        }

        service.is.following[listKey] = true;

        const query = {
            id: entityId,
        };

        // eslint-disable-next-line func-style
        const onFollowSuccess = function onFollowSuccess(response) {
            // Add following to cache.
            service.addFollowing(entityId, kind, response);

            service.is.following[listKey] = false;

            cb(response);
        };

        // eslint-disable-next-line func-style
        const onFollowError = function onFollowError(err) {
            service.is.following[listKey] = false;

            errCb(err);
        };

        const lcKind = kind.toLowerCase();
        if (lcKind === service.FOLLOWING_TYPES.content || lcKind === service.FOLLOWING_TYPES.user) {
            let Api;
            switch (lcKind) {
                case service.FOLLOWING_TYPES.content:
                    Api = $injector.get('ContentFactory');

                    break;

                case service.FOLLOWING_TYPES.user:
                    Api = $injector.get('UserFactory');

                    break;

                default:
                    break;
            }

            if (angular.isUndefinedOrEmpty(Api)) {
                return;
            }

            Api.follow(
                query,
                {
                    notify,
                },
                onFollowSuccess,
                onFollowError,
            );
        } else {
            query.instanceKey = Instance.getCurrentInstanceId();
            query[_FOLLOWER_MAPPING.user] = followerId;
            query[_FOLLOWING_MAPPING[lcKind]] = entityId;
            service.save(query, onFollowSuccess, onFollowError, listKey);
        }
    }

    /**
     * Override the get method so we can remove the instance param under certain conditions.
     *
     * @param  {Object}              params       The parameters of the query.
     * @param  {Function}            [cb]         A callback to execute on success.
     * @param  {Function}            [errCb]      A callback to execute on error.
     * @param  {string}              [listKey]    The list key to reference the result of the call.
     * @param  {string|Object|Array} [projection] The field we want to get in the result object.
     * @param  {string}              [kind]       The kind of entity to get.
     * @return {Object}              The result object if it already exists.
     *
     * @override
     */
    function get(params, cb, errCb, listKey, projection, kind) {
        // No instanceKey for users as user subscriptions are platform wide.
        if (
            angular.isDefinedAndFilled([kind, loGet(params, 'instanceKey')], 'every') &&
            kind.toLowerCase() === service.FOLLOWING_TYPES.user.toLowerCase()
        ) {
            delete params.instanceKey;
        }

        // eslint-disable-next-line no-invalid-this
        return LumsitesBaseService.proto.prototype.get.call(this, params, cb, errCb, listKey, projection);
    }

    /**
     * Get an entry from the cached list based on its identifier.
     *
     * @param  {string} entityId The identifier of subscription.
     * @param  {string} kind     The kind of the entity to retrieve from the cache.
     * @return {Object} The entity from the cached list.
     */
    function getFollowing(entityId, kind) {
        if (angular.isUndefinedOrEmpty([entityId, kind], 'some')) {
            return undefined;
        }

        return _followingCache.get(`${entityId}${kind}`.toLowerCase());
    }

    /**
     * Check if current user is following a given entity.
     *
     * @param  {string} entityId The identifier of the entity to be checked.
     * @param  {string} kind     The kind of entity to be checked.
     * @return {Object} If the entity is followed, the cached value, else undefined.
     */
    function isFollowing(entityId, kind) {
        return _followingCache.get(`${entityId}${kind}`.toLowerCase());
    }

    /**
     * Delete a subscription.
     * This is an alias of the `delete` function.
     *
     * @param {string}   id        The identifier of the subscription to be deleted.
     * @param {string}   entityId  The identifier of the entity to be unfollowed.
     * @param {string}   kind      The kind of the entity of the subscription to unfollow.
     * @param {Function} [cb]      The callback to execute when the unfollow is successful.
     * @param {Function} [errCb]   The callback to execute when the unfollow is in error.
     * @param {string}   [listKey] The list key.
     */
    function unfollow(id, entityId, kind, cb, errCb, listKey) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        if (angular.isUndefinedOrEmpty([id, entityId, kind], 'some')) {
            return;
        }

        let query = {
            id: entityId,
        };

        let Api, endpoint;
        switch (kind.toLowerCase()) {
            case service.FOLLOWING_TYPES.content:
                Api = $injector.get('ContentFactory');
                endpoint = 'unfollow';

                break;

            case service.FOLLOWING_TYPES.user:
                Api = $injector.get('UserFactory');
                endpoint = 'unfollow';

                break;

            default:
                Api = service;
                endpoint = 'del';
                query = id;

                break;
        }

        Api[endpoint](
            query,
            function onSubscriptionDeleteSuccess() {
                if (kind.toLowerCase() === service.FOLLOWING_TYPES.user.toLowerCase()) {
                    $rootScope.$broadcast('user-unfollow-success', {
                        userId: entityId,
                    });
                }

                _removeFollowing(entityId, kind);

                cb();
            },
            errCb,
            listKey,
        );
    }

    /////////////////////////////

    service.addFollowing = addFollowing;
    service.follow = follow;
    service.get = get;
    service.getFollowing = getFollowing;
    service.isFollowing = isFollowing;
    service.unfollow = unfollow;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('SocialSubscription', SocialSubscriptionService);

/////////////////////////////

export { SocialSubscriptionService };
