(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetIframeSettingsController($rootScope, $timeout, $window, Features, Translation, Widget) {
        'ngInject';

        var widgetIframeSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Whether to display a warning regarding mixed https & http content.
         *
         * @type {boolean}
         */
        widgetIframeSettings.displayHttpsWarning = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetIframeSettings.Features = Features;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Format the iframe url.
         */
        function formatUrl() {
            var url = Widget.getCurrent().properties.url[Translation.inputLanguage];

	    // CMS-1153: Fixed latent bug, as the form field was not updated properly
            if (angular.isDefinedAndFilled(url) && !url.toLowerCase().startsWith('http')) {
		url = 'http://' + url
                Widget.getCurrent().properties.url[Translation.inputLanguage] = url;
            }

            widgetIframeSettings.updateWidget();
        }

        /**
         * Update widget.
         */
        function updateWidget() {
	    var url = Widget.getCurrent().properties.url[Translation.inputLanguage];

            // CMS-1153: Prevent immediate self DOM-XSS via javascript URL
            if (url.toLowerCase().startsWith('http')) {
                $timeout(function timedOutIframeSettingsBroadcast() {
                    $rootScope.$broadcast('widget-iframe-settings', Widget.getCurrent().uuid);
                });
	    }
        }

        /////////////////////////////

        widgetIframeSettings.formatUrl = formatUrl;
        widgetIframeSettings.updateWidget = updateWidget;
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetIframeSettingsController', WidgetIframeSettingsController);
})();
