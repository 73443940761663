import React from 'react';

import { mdiKeyboardReturn } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SHORTCUTS } from '../../keys';
import { KeyboardKey } from '../KeyboardKey';

/** Display the "Enter" key with an accessible label. */
export const KeyEnter = () => {
    const { translateKey } = useTranslate();
    return <KeyboardKey label={translateKey(SHORTCUTS.ENTER_LABEL)} icon={mdiKeyboardReturn} />;
};
