import React from 'react';

import { TextField } from '@lumapps/lumx/react';

import { any, func, objectOf } from 'prop-types';

import { translate as t, getLang } from '../../../../../translations';

/**
 * Display the form to add a new task.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The form react element.
 */
const NewTaskForm = ({ addTodo, theme, newTodo, updateTodo }) => {
    return (
        <form
            className="widget-todo__new-task"
            onSubmit={(submitEvent) => {
                submitEvent.preventDefault();
                addTodo();
            }}
        >
            <div className="widget-todo__new-task-field">
                <TextField
                    label={t('FRONT.TODO.ADD_TASK')}
                    theme={theme}
                    value={newTodo && newTodo[getLang('current')] ? newTodo[getLang('current')] : ''}
                    onChange={(value) => updateTodo(value)}
                />
            </div>

            <div className="widget-todo__new-task-add">
                <button type="button" onClick={() => addTodo()}>
                    {t('GLOBAL.SUBMIT')}
                </button>
            </div>

            <input style={{ opacity: 0 }} type="submit" />
        </form>
    );
};

NewTaskForm.defaultProps = {
    theme: undefined,
};

NewTaskForm.propTypes = {
    addTodo: func.isRequired,
    newTodo: objectOf(any).isRequired,
    theme: objectOf(any),
    updateTodo: func.isRequired,
};

export default NewTaskForm;
