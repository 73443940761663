/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import { combineReducers } from '@lumapps/redux';

import * as MemberBarSlice from './MemberBar/slice';
import * as MemberListDialogSlice from './MemberListDialog';

export const reducer = combineReducers({
    memberBar: MemberBarSlice.reducer,
    programMemberListDialog: MemberListDialogSlice.programMemberListDialogReducer,
    sharerListDialog: MemberListDialogSlice.sharerListDialogReducer,
});

export const initialState = {
    memberBar: MemberBarSlice.initialState,
    programMemberListDialog: MemberListDialogSlice.initialState,
    sharerListDialog: MemberListDialogSlice.initialState,
};

export { programMemberListDialogActions, sharerListDialogActions } from './MemberListDialog';
export const MemberBarActions = MemberBarSlice.actions;
