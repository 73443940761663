import first from 'lodash/first';
import get from 'lodash/get';
import last from 'lodash/last';
import loFind from 'lodash/find';
import memoize from 'lodash/memoize';
import startsWith from 'lodash/startsWith';

function DocumentService(
    $injector,
    $q,
    $window,
    DocumentFactory,
    LumsitesBaseService,
    Media,
    MediaConstant,
    Translation,
    Utils,
    User,
) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(DocumentFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    // eslint-disable-next-line no-underscore-dangle
    function _getThumbnailFromMedia(media, lang) {
        // media.thumbnail could be an object or a string
        // eslint-disable-next-line no-undef
        if (angular.isObject(media.thumbnail)) {
            return media.thumbnail[lang] || '';
        }
        return media.thumbnail;
    }

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The service's default request parameters.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Create a folder.
     *
     * @param  {Object}  names      Folder names by languages.
     * @param  {string}  parentPath New folder parent path.
     * @param  {string}  listKey    The identifier of the list that is linked to the display of the loader.
     * @param  {boolean} shared     Provider category specific param.
     * @return {Promise} The create params promise.
     */
    function createFolder({ names, parentPath, listKey, shared = false }) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(names)) {
            return $q.reject();
        }

        const deferred = $q.defer();
        // eslint-disable-next-line no-shadow
        const name = names[Translation.inputLanguage];

        DocumentFactory.createFolder({ name, parentPath, shared }, deferred.resolve, deferred.reject, listKey);

        return deferred.promise;
    }

    /**
     * Download a document.
     *
     * @param {Object}  media                Document to download.
     * @param {boolean} [useFallback=true]   Download fallback document if unable to find one for specified lang.
     * @param {string}  [lang=inputLanguage] Requested media language.
     */
    function download(media, useFallback = true, lang = Translation.inputLanguage) {
        const mediaContent = service.getMediaContentByLang(media, useFallback, lang);
        if (
            // eslint-disable-next-line no-undef
            angular.isUndefinedOrEmpty(mediaContent) &&
            // eslint-disable-next-line no-undef
            angular.isUndefinedOrEmpty([mediaContent.downloadUrl, mediaContent.url], 'some')
        ) {
            return;
        }
        const mediaUrl = mediaContent.downloadUrl || mediaContent.url;

        $window.open(mediaUrl, '_blank');
    }

    /**
     * Empty the trash.
     *
     * @param  {string}  docPath The provider rootPath to use.
     * @param  {string}  listKey The identifier of the list that is linked to the display of the loader.
     * @return {Promise} Empty trash request promise.
     */
    function emptyTrash(docPath, listKey) {
        const deferred = $q.defer();

        DocumentFactory.emptyTrash(
            {
                docPath,
            },
            deferred.resolve,
            deferred.reject,
            listKey,
        );

        return deferred.promise;
    }

    /**
     * Fetch document's preview (url).
     *
     * @param  {string}  docPath   DocPath of document to preview.
     * @param  {string}  [listKey] The list key identifier.
     * @return {Promise} Promise resolved with endpoint response.
     */
    function fetchPreview(docPath, listKey) {
        const deferred = $q.defer();

        DocumentFactory.preview(
            {
                docPath,
            },
            deferred.resolve,
            deferred.reject,
            listKey,
        );

        return deferred.promise;
    }

    /**
     * Get sort order parameters that should be added to all list requests.
     *
     * @return {Array} Mandatory sort order parameters.
     */
    function getMandatorySortOrders() {
        return ['-isFolder'];
    }

    /**
     * Get media thumbnail image as a background image.
     *
     * @param  {Object}  media               Media to get thumbnail from.
     * @param  {boolean} [useFallback=false] Should fallback on default language.
     * @param  {number}  [size=-1]           The size you want the image of the background in.
     * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
     * @param  {Object}  [overrides]         The possible overrides of the media content.
     * @return {string}  The background image css property.
     */
    function getBackgroundImage(media, useFallback, size = -1, lang, overrides) {
        const mediaContent = service.getMediaContentByLang(media, useFallback, lang);
        const thumbnailUrl = service.getThumbnail(mediaContent, overrides, lang);

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(thumbnailUrl)) {
            return Utils.getBackgroundImage(thumbnailUrl, size);
        }

        return undefined;
    }

    /**
     * Indicates if the media is from google drive or not.
     *
     * @param  {Media}   media Media to test
     * @return {boolean} Wether the media is from google drive or not.
     */
    function isMediaFromGoogle(media) {
        const source = Media.getSource(media);
        const isFromGoogle = source && source.toLowerCase() === MediaConstant.PROVIDERS.google;

        return isFromGoogle;
    }

    /**
     * Indicates if the media is from microsoft or not.
     *
     * @param  {Media}   media Media to test
     * @return {boolean} Wether the media is from microsoft or not.
     */
    function isMediaFromMicrosoft(media) {
        const source = Media.getSource(media);
        const isFromMicrosoft = source && source.toLowerCase() === MediaConstant.PROVIDERS.microsoft;

        return isFromMicrosoft;
    }

    function getMediaCrossOriginSetting(media) {
        if (!isMediaFromGoogle(media) && !isMediaFromMicrosoft(media)) return undefined;
        return 'anonymous';
    }

    /**
     * Get thumbnail for a given Content.
     * If it's a document which come fron GDrive, get preview
     * If it's a image, get url
     * If not, return undefined;
     *
     * @param  {Content} content                 The content to get thumbnail from.
     * @param  {boolean} isFromGoogleOrMicrosoft Wether the media comes from Google or Microsoft
     * @param  {number}  [size]                  The size you want the image.
     * @param  {string}  [lang]                  The lang we want to use to get the image from the media content.
     * @param  {Object}  [overrides]             The possible overrides of the media content.
     * @return {string}  The url of the thumbnail
     */
    function getCroppedThumbnailFromContent(content, shouldUsePreviewUrl, size, lang, overrides) {
        if (shouldUsePreviewUrl) {
            return content.fileId ? Media.getDrivePreviewUrl(content.fileId) : undefined;
        }

        const url =
            service.getProperty(content, overrides, 'servingUrl', lang) ||
            service.getProperty(content, overrides, 'url', lang);

        const newSize = ['image/gif', 'image/svg+xml'].includes(content.mimeType) ? -1 : size;

        return Utils.resizeImage(url, newSize);
    }

    /**
     * Get media thumbnail image cropped URL from Media.
     * If exists, cropped picture should be recovered from media.croppedContent.
     * If not, we get thumbnail the original picture.
     * Test all properties to ensure compatibility with previous image.
     *
     * @param  {Object}  media               Media to get thumbnail from.
     * @param  {boolean} [useFallback=false] Should fallback on default language.
     * @param  {number}  [size=-1]           The size you want the image.
     * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
     * @param  {Object}  [overrides]         The possible overrides of the media content.
     * @return {string}  The url of the cropped image.
     */
    function getCroppedThumbnailFromMedia(media, useFallback, size, lang, overrides) {
        const content = service.getMediaContentByLang(media, useFallback, lang);
        const isFromGoogle = service.isMediaFromGoogle(media);
        const isImage = Media.isImage(content.type);

        // only google and pictures url can be displayed into <img>
        if (!isFromGoogle && !isImage) {
            return '';
        }

        let thumbnail = service.getCroppedThumbnailFromContent(content, isFromGoogle, size, lang, overrides);

        if (!thumbnail) {
            // Fallback but never should happen with new structure
            thumbnail = _getThumbnailFromMedia(media, lang);
        }

        return thumbnail;
    }

    function getImageThumbnailForMedia(media, useFallback, size, lang, overrides){
        const thumbnail = getCroppedThumbnailFromMedia(media, useFallback, size, lang || Translation.getLang('current'), overrides);
        const content = service.getMediaContentByLang(media, useFallback, lang || Translation.getLang('current'));

        if (thumbnail && content) {
            return {
                url: thumbnail,
                id: content.value,
            };
        }

        return undefined;
    }

    /**
     * Get media thumbnail image cropped URL from root Content (which one contains mediaThumbnail).
     * Test all properties to ensure compatibility with previous image.
     *
     * @param  {Content}  rootContent        Content to get thumbnail from.
     * @param  {boolean} [useFallback=false] Should fallback on default language.
     * @param  {number}  [size=-1]           The size you want the image.
     * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
     * @param  {Object}  [overrides]         The possible overrides of the media content.
     * @return {string}  The url of the cropped image.
     */
    function getCroppedThumbnailFromRootContent(rootContent, useFallback, size = -1, lang, overrides) {
        let url = '';
        if (rootContent.mediaThumbnail) {
            url = service.getCroppedThumbnailFromMedia(rootContent.mediaThumbnail, useFallback, size, lang, overrides);
        }

        if (!url && rootContent.thumbnail) {
            url = size !== -1 ? Utils.resizeImage(rootContent.thumbnail, size) : rootContent.thumbnail;
        }

        // If we get there, data might me corrupted / have changed...
        return url;
    }

    /**
     * Get thumbnail's focal point coordinates.
     * If exists, focal point coordinates should be recovered from media.croppedContent.
     * If not, we get coordinates from the original content.
     *
     * @param  {Object}  media               Media to get thumbnail from.
     * @param  {boolean} [useFallback=false] Should fallback on default language.
     * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
     * @param  {Object}  [overrides]         The possible overrides of the media content.
     * @return {string}  The cropped image's url.
     */
    function getFocusPointFromMedia(media, useFallback, lang) {
        if (media) {
            const mediaContent = service.getMediaContentByLang(media, useFallback, lang);
            return mediaContent.focalPoint || undefined;
        }
        return undefined;
    }

    /**
     * Get the description of a media from it content and some possible overrides.
     *
     * @param  {Object} mediaContent The media content to get the description from.
     * @param  {Object} overrides    The possible override of the media content.
     * @param  {string} [lang]       The language to translate the description to.
     * @return {string} The description of the media.
     */
    function getDescription(mediaContent, overrides, lang) {
        return service.getProperty(mediaContent, overrides, 'description', lang);
    }

    /**
     * Get document properties for specified language.
     *
     * @param  {Object}  media                            Media to get properties.
     * @param  {boolean} [useFallback=false]              Should fallback to default language.
     * @param  {string}  [lang=Translation.inputLanguage] Language used to get properties.
     * @param  {boolean} [originalOnly=false]             Indicates if we want to only use the original information
     *                                                    of the media content and not the media overrides.
     * @param  {string}  forceTarget                      If we want explicity cropped content or original content ('content' || 'croppedContent')
     * @return {Object}  Document properties.
     */
    function getMediaContentByLang(media, useFallback, lang, originalOnly, forceTarget) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(media)) {
            return {};
        }

        // eslint-disable-next-line no-param-reassign
        lang = lang || Translation.inputLanguage;
        // eslint-disable-next-line no-param-reassign
        useFallback = Boolean(useFallback);

        const mediaContentTarget =
            media[forceTarget] ||
            (!angular.isUndefinedOrEmpty(media.croppedContent) ? media.croppedContent : media.content);

        let mediaContent = (useFallback ? angular.fastCopy(first(mediaContentTarget)) : {}) || {};

        // Get media for current language
        let mediaContentForInputLanguage = angular.fastCopy(
            loFind(mediaContentTarget, {
                lang,
            }),
        );
        // Fallback to english
        if (!mediaContentForInputLanguage) {
            mediaContentForInputLanguage = angular.fastCopy(
                loFind(mediaContentTarget, {
                    lang: 'en',
                }),
            );
        }
        // Fallback to available
        if (!mediaContentForInputLanguage && mediaContentTarget.length > 0) {
            mediaContentForInputLanguage = angular.fastCopy(first(mediaContentTarget));
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(mediaContentForInputLanguage)) {
            mediaContent = { ...mediaContent, ...mediaContentForInputLanguage };
        }

        if (!originalOnly) {
            // eslint-disable-next-line no-underscore-dangle
            const changedName = Translation.translate_(media.name, lang, useFallback);
            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(changedName)) {
                mediaContent.name = changedName;
            }

            // eslint-disable-next-line no-underscore-dangle
            const changedDescription = Translation.translate_(media.description, lang, useFallback);
            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(changedDescription)) {
                mediaContent.description = changedDescription;
            }

            // eslint-disable-next-line no-underscore-dangle
            const changedThumbnail = Translation.translate_(media.thumbnail, lang, useFallback);

            // this should not be done anymore. Indeed, thumbnail exists in its Media parent.
            // Get it from here from now

            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(changedThumbnail)) {
                mediaContent.thumbnail = changedThumbnail;
            }
        }

        if (get(media, 'properties.type') === 'ONEDRIVE_SITE') {
            mediaContent.name = media.properties.displayName;
        }

        mediaContent.owner = mediaContent.owner || get(first(media.content || []), 'owner');

        if (mediaContent.owner === null) {
            mediaContent.owner = User.getConnected();
        }

        return mediaContent;
    }

    /**
     * Get media icon depending on the media object.
     *
     * @param  {Object}  media                            The media object to get the media icon of.
     * @param  {boolean} [useFallback=false]              Whether or not to use a fallback value if none found.
     * @param  {string}  [lang=Translation.inputLanguage] Language used to get properties.
     * @return {string}  The icon class name.
     */
    function getMediaIcon(media, useFallback = false, lang) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(media)) {
            return '';
        }

        const mediaContent = service.getMediaContentByLang(media, useFallback, lang);
        const mediaType = get(media, 'properties.type');

        if (mediaType === 'ONEDRIVE_SITE') {
            return 'mdi-web';
        }

        if (mediaType === 'ONEDRIVE_DRIVE') {
            return 'mdi-onedrive';
        }

        if (mediaType === 'GOOGLE_TEAMDRIVE') {
            return 'mdi-google-drive';
        }

        if (media.isFolder) {
            return 'mdi-folder';
        }

        // eslint-disable-next-line no-undef
        const hasNoType = angular.isUndefinedOrEmpty(
            [get(mediaContent, 'mimeType'), get(mediaContent, 'mimeType')],
            'every',
        );

        if (hasNoType) {
            return 'mdi-close';
        }

        const mediaMimeType = mediaContent.mimeType;
        const mediaContentType = mediaContent.type;

        if (Media.isImageMimeType(mediaMimeType) || Media.isImage(mediaContentType)) {
            return 'mdi-image';
        }

        if (Media.isVideoMimeType(mediaMimeType) || Media.isVideo(mediaContentType)) {
            return 'mdi-video';
        }

        if (Media.isDocumentMimeType(mediaMimeType) || Media.isDocument(mediaContentType)) {
            return 'mdi-file-document-box';
        }

        return 'mdi-file-document-box';
    }

    /**
     * Get multiple documents from theirs doc ids.
     *
     * @param  {Array}   docPaths The docPath of the documents we want to get.
     * @return {Promise} The promise of the get multi.
     */
    function getMulti(docPaths) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(docPaths)) {
            return $q.resolve([]);
        }

        return DocumentFactory.getMulti({ docPaths }).$promise;
    }

    /**
     * Get the name of a media from it content and some possible overrides.
     *
     * @param  {Object} mediaContent The media content to get the name from.
     * @param  {Object} overrides    The possible override of the media content.
     * @param  {string} [lang]       The language to translate the name to.
     * @return {string} The name of the media.
     */
    function getName(mediaContent, overrides, lang) {
        return service.getProperty(mediaContent, overrides, 'name', lang);
    }

    /**
     * Get first content file id.
     *
     * @param  {Object} file File to get id.
     * @return {string} file id.
     */
    function getObjectId(file) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(file)) {
            return undefined;
        }

        if (startsWith(get(file, 'type', ''), 'ONEDRIVE_')) {
            return file.uid;
        }

        const mediaContent = service.getMediaContentByLang(file, true);

        return get(mediaContent, 'fileId');
    }

    /**
     * Return object kind.
     *
     * @param  {Object} object  Object to return kind.
     * @param  {Object} filters Filters.
     *
     * @return {string} The kind of document.
     */
    function getObjectKind(object, filters) {
        const providerType = filters.providerId;

        let docKind = MediaConstant.KIND.MEDIA;

        if (object.isFolder) {
            docKind = MediaConstant.KIND.FOLDER;
        } else if (
            providerType === MediaConstant.PROVIDERS.google ||
            providerType === MediaConstant.PROVIDERS.microsoft
        ) {
            docKind = MediaConstant.KIND.DRIVE;
        }

        return docKind;
    }

    /**
     * Get user permissions on document.
     *
     * @param  {string}  docPath          Document to get permission on.
     * @param  {Object}  searchParameters Document provider category search parameters.
     * @param  {string}  listKey          List key.
     * @return {Promise} Request promise.
     */
    function getPermissions({ docPath, searchParameters, listKey }) {
        const deferred = $q.defer();

        DocumentFactory.getPermissions(
            {
                docPath,
                searchParameters,
            },
            deferred.resolve,
            deferred.reject,
            listKey,
        );

        return deferred.promise;
    }

    /**
     * Return a filter regarding allowTypes and pathId attributes for Media service.
     *
     * @param  {Object}  filters                                      Abstract picker filters.
     * @param  {Array}   objectPathMap                                Map of the object path.
     * @param  {boolean} [foldersOnly=false]                          Indicates if you want to get only the folders.
     * @param  {string}  [documentProviderService='DocumentProvider'] DocumentsProvider service name.
     * @return {Object}  The rewritten filter for media service.
     */
    async function getPickerListRequestFilters(
        filters,
        objectPathMap,
        foldersOnly,
        documentProviderService = 'DocumentProvider',
    ) {
        const documentProvider = $injector.get(documentProviderService);
        const { categoryId, providerId } = filters;
        const category = await documentProvider.getProviderCategory(providerId, categoryId);
        const docPath = objectPathMap.length === 0 ? category.rootPath : last(objectPathMap).docPath;
        const searchParameters = category.properties;

        const params = {
            docPath,
            searchParameters,
        };

        const searchTypes = foldersOnly ? MediaConstant.DOCUMENT_TYPES.folders : [];

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(searchTypes)) {
            params.searchTypes = searchTypes;
        }

        return params;
    }

    /**
     * Get the property of a media from it content and some possible overrides.
     *
     * @param  {Object} mediaContent The media content to get the property from.
     * @param  {Object} overrides    The possible override of the media content.
     * @param  {string} property     The property to get in the media.
     * @param  {string} [lang]       The language to translate the property to.
     * @return {string} The value of the property of the media.
     */
    function getProperty(mediaContent, overrides, property, lang) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(mediaContent)) {
            return '';
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(overrides) && Translation.hasTranslations(overrides[property])) {
            // eslint-disable-next-line no-underscore-dangle
            return Translation.translate_(overrides[property], lang, true);
        }

        // eslint-disable-next-line no-underscore-dangle
        return Translation.translate_(mediaContent[property], lang, true);
    }

    /**
     * Get the thumbnail of a media from it content and some possible overrides.
     *
     * @param  {Object} mediaContent The media content to get the description from.
     * @param  {Object} overrides    The possible override of the media content.
     * @param  {string} [lang]       The language to translate the description to.
     * @return {string} The description of the media.
     */
    function getThumbnail(mediaContent, overrides, lang) {
        return service.getProperty(mediaContent, overrides, 'thumbnail', lang);
    }

    /**
     * Removes a cropped content for a given lang and media item.
     *
     * @param {Object} media The media item the cropped content is associated with.
     * @param {string} lang  The language code of the cropped content to remove.
     */
    function removeCrop(media, lang) {
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(media.croppedContent)) {
            for (let i = 0, len = media.croppedContent.length; i < len; i++) {
                if (media.croppedContent[i].lang === lang) {
                    media.croppedContent.splice(i, 1);

                    break;
                }
            }
        }
    }

    /**
     * Restore a document from trash.
     *
     * @param  {string}  docPath   DocPath of document to restore.
     * @param  {string}  [listKey] The list key identifier.
     * @return {Promise} Restore request promise.
     */
    function restore(docPath, listKey) {
        const deferred = $q.defer();

        DocumentFactory.restore(
            {
                docPath,
            },
            deferred.resolve,
            deferred.reject,
            listKey,
        );

        return deferred.promise;
    }

    /**
     * Move a document to trash.
     *
     * @param  {string}  docPath DocPath of document to trash.
     * @param  {string}  listKey The list key identifier.
     * @return {Promise} Trash request promise.
     */
    function trash(docPath, listKey) {
        const deferred = $q.defer();

        DocumentFactory.trash(
            {
                docPath,
            },
            deferred.resolve,
            deferred.reject,
            listKey,
        );

        return deferred.promise;
    }

    /**
     * Update a document.
     *
     * @param  {Object}  doc Document to update.
     * @return {Promise} Promise of the update request.
     */
    function update(doc) {
        return DocumentFactory.update(doc).$promise;
    }

    // ///////////////////////////

    service.createFolder = createFolder;
    service.download = download;
    service.emptyTrash = emptyTrash;
    service.fetchPreview = fetchPreview;
    service.getBackgroundImage = getBackgroundImage;
    service.getCroppedThumbnailFromContent = getCroppedThumbnailFromContent;
    service.getCroppedThumbnailFromMedia = getCroppedThumbnailFromMedia;
    service.getImageThumbnailForMedia = memoize(getImageThumbnailForMedia, (media, useFallback, size, lang) => {
        const content = service.getMediaContentByLang(media, useFallback, lang || Translation.getLang('current'));

        return content.value;
    });
    service.getCroppedThumbnailFromRootContent = getCroppedThumbnailFromRootContent;
    service.getDescription = getDescription;
    service.getFocusPointFromMedia = getFocusPointFromMedia;
    service.getMandatorySortOrders = getMandatorySortOrders;
    service.getMediaContentByLang = getMediaContentByLang;
    service.getMediaIcon = getMediaIcon;
    service.getMulti = getMulti;
    service.getName = getName;
    service.getObjectId = getObjectId;
    service.getObjectKind = getObjectKind;
    service.getPermissions = getPermissions;
    service.getPickerListRequestFilters = getPickerListRequestFilters;
    service.getProperty = getProperty;
    service.getThumbnail = getThumbnail;
    service.isMediaFromGoogle = isMediaFromGoogle;
    service.isMediaFromMicrosoft = isMediaFromMicrosoft;
    service.getMediaCrossOriginSetting = getMediaCrossOriginSetting;
    service.removeCrop = removeCrop;
    service.restore = restore;
    service.trash = trash;
    service.update = update;

    // ///////////////////////////

    /**
     * Initialize the service.
     */
    service.init = () => {
        service.defaultParams = {
            lang: Translation.getPreferredContributionLanguage(),
        };
    };

    // ///////////////////////////

    return service;
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular.module('Services').service('Document', DocumentService);

// ///////////////////////////

export { DocumentService };
