import { BROADCAST_STATUS } from '@lumapps/broadcasts/types';
import { GLOBAL } from '@lumapps/translations';
import { User } from '@lumapps/user/types';

/**
 * Statuses that the campaign can have.
 */
export enum CAMPAIGN_STATUS {
    LIVE = 'live',
    ARCHIVED = 'archived',
}

/**
 * Baseline date
 */
export interface BaselineDateType {
    id: string;
    title: string;
    date: string;
}

/**
 * Baseline date change types
 */
export enum BASELINE_DATE_CHANGES_TYPE {
    NONE = 'none',
    REMOVED = 'removed',
    UPDATED = 'updated',
}

/**
 * Progression the campaign can have.
 */
export enum CAMPAIGN_PROGRESSION {
    WIP = 'work_in_progress',
    ONGOING = 'ongoing',
    PLANNED = 'planned',
    FINISHED = 'finished',
}

/**
 * Campaign entity type. There might be some other properties
 * in the Campaign definition returned by the backend, but here
 * we are just listing the ones that are interesting for the frontend.
 */
export interface Campaign {
    id: string;
    title: string;
    description?: string;
    site?: {
        id: string;
        name: string;
    };
    baselineDates?: Array<BaselineDateType>;
    siteId: string;
    status: CAMPAIGN_STATUS;
    createdAt: string;
    updatedAt: string;
    updatedBy?: Partial<User>;
    createdBy?: Partial<User>;
    broadcastCountByStatus?: { [key in BROADCAST_STATUS]?: string };
    progression?: CAMPAIGN_PROGRESSION;
}

export type EditableCampaign = Partial<Omit<Campaign, 'baselineDates'>> & {
    id: Campaign['id'];
    baselineDates?: Array<Partial<BaselineDateType>>;
};

export interface CampaignListFilters {
    cursor?: string;
    title?: string;
    siteIds?: string[];
    statuses?: CAMPAIGN_STATUS[];
    progressions?: CAMPAIGN_PROGRESSION[];
}

export const LABELS_KEYS_FOR_CAMPAIGN_SORT: Record<string, string> = {
    creationDate: GLOBAL.CREATION_DATE,
};

export type CampaignFieldMode = 'view' | 'edit';

export interface CampaignField {
    campaign?: EditableCampaign;
    mode: CampaignFieldMode;
    isSaving?: boolean;
}

export type ChangeCampaignStatusType = (campaign: Campaign, newStatus: CAMPAIGN_STATUS) => Promise<void>;
