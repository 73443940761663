import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { Avatar, Size, Button, Emphasis, ButtonProps, AvatarSize } from '@lumapps/lumx/react';

import './index.scss';

export interface UserAvatarProps extends ButtonProps {
    /** The avatar of the user */
    avatarImageSrc?: string;
    /** Url? where to redirect */
    avatarLinkUrl?: string;
    /** Alternative text set on the avatar */
    avatarAlt?: string;
    /** Custom? className */
    className?: string;
    /** Action when the link is clicked */
    onClick?(): void;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
    /** Avatar size. */
    avatarSize?: AvatarSize;
}

export const CLASSNAME = 'user-avatar';

export const UserAvatar = React.forwardRef<HTMLButtonElement, UserAvatarProps>(
    (
        {
            avatarImageSrc,
            avatarLinkUrl,
            onClick,
            className,
            scope,
            avatarSize = Size.m,
            avatarAlt = '',
            ...forwardedProps
        },
        ref,
    ) => {
        const { get } = useDataAttributes(scope);
        const imgProps = {
            width: REAL_SIZE_FOR_LUMX_SIZE[avatarSize],
            height: REAL_SIZE_FOR_LUMX_SIZE[avatarSize],
        };

        if (!avatarImageSrc) {
            return null;
        }

        return (
            <Button
                ref={ref}
                className={classnames(className, CLASSNAME)}
                onClick={onClick}
                role={avatarLinkUrl ? 'link' : 'button'}
                emphasis={Emphasis.low}
                target="_self"
                // Transforms the button into a proper <a> link with the LumX button style.
                href={avatarLinkUrl}
                {...forwardedProps}
                {...get({ element: 'avatar' })}
            >
                <Avatar image={avatarImageSrc} size={avatarSize} alt={avatarAlt} thumbnailProps={{ imgProps }} />
            </Button>
        );
    },
);
UserAvatar.displayName = 'UserAvatar';
