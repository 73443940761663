/* istanbul ignore file */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { CustomizableSocialNetworks } from '@lumapps/sa-social-networks/types';

export enum ShareToSocialNetworkDialogStatus {
    initial = 'initial',
    loading = 'loading',
}

export interface ShareToSocialNetworkDialogState {
    status: ShareToSocialNetworkDialogStatus;
    socialNetwork?: CustomizableSocialNetworks;
    comment: string;
}

export const initialState: ShareToSocialNetworkDialogState = {
    socialNetwork: undefined,
    comment: '',
    status: ShareToSocialNetworkDialogStatus.initial,
};

export const { actions, reducer } = createSlice({
    domain: 'share/shareToSocialNetworkDialog',
    initialState,
    reducers: {
        setStatus: (
            state: ShareToSocialNetworkDialogState,
            action: PayloadAction<ShareToSocialNetworkDialogStatus>,
        ) => {
            set(state, 'status', action.payload);
        },
        setComment: (state: ShareToSocialNetworkDialogState, action: PayloadAction<string>) => {
            set(state, 'comment', action.payload);
        },
        setSocialNetwork: (
            state: ShareToSocialNetworkDialogState,
            action: PayloadAction<CustomizableSocialNetworks>,
        ) => {
            set(state, 'socialNetwork', action.payload);
        },
    },
});
