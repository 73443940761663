import { getMediaUrl, makeSecuredMediaURLRelative } from '@lumapps/medias/utils';

import { MediaPreview } from '../../../types';

export const getImageUrl = (image: Partial<MediaPreview>, isThumbnail?: boolean) => {
    if ((image.source === 'haussmann_media' || image.isSecure) && image.mediaId) {
        return getMediaUrl(image.mediaId);
    }
    if (isThumbnail) {
        return makeSecuredMediaURLRelative(image.thumbnail?.url || image.url) || '';
    }
    return makeSecuredMediaURLRelative(image.url);
};

/**
 * Make sure the alt won't be the fileName
 * @param image
 * @returns an alt for the image
 */
export const getImageAlt = (image: Partial<MediaPreview>): string => {
    const { fileName, description, title } = image;
    if (description !== fileName) {
        return description || '';
    }
    if (title !== fileName) {
        return title || '';
    }
    return '';
};
