interface GetFileSizeProps {
    /* Unit you want to convert your file */
    unit: 'Byte' | 'KB' | 'MB' | 'GB';
    /* Size of the file in Bytes */
    fileSize?: number;
}

export const getFileSize = ({ unit, fileSize }: GetFileSizeProps): number | null => {
    if (!fileSize) {
        return null;
    }

    const conversionRates = {
        Byte: 1,
        KB: 1024,
        MB: 1024 * 1024,
        GB: 1024 * 1024 * 1024,
    };

    return fileSize / conversionRates[unit];
};
