import { EmbeddedMap } from '@lumapps/wrex-embedded-map/components/blocks/EmbeddedMap';
import { EmbeddedVideo } from '@lumapps/wrex-embedded-video/components/blocks/EmbeddedVideo';
import { AlignedImageWrapper } from '@lumapps/wrex-enhanced-image/components/blocks/AlignedImageWrapper';
import { ImageGroup } from '@lumapps/wrex-enhanced-image/components/blocks/ImageGroup';
import { IMAGE_FEATURES } from '@lumapps/wrex-enhanced-image/constants';
import { File } from '@lumapps/wrex-file/components/blocks/File';
import { LinkPreview } from '@lumapps/wrex-link-preview/components/blocks/LinkPreview';
import { Link } from '@lumapps/wrex-link/components/blocks/Link';
import { ListItem } from '@lumapps/wrex-list/components/blocks/ListItem';
import { OrderedList } from '@lumapps/wrex-list/components/blocks/OrderedList';
import { UnorderedList } from '@lumapps/wrex-list/components/blocks/UnorderedList';
import { LIST_FEATURES } from '@lumapps/wrex-list/constants';
import { PlayVideo } from '@lumapps/wrex-play-video/components/blocks/PlayVideo';
import { QuoteBlock } from '@lumapps/wrex-quote/components/blocks/QuoteBlock';
import { Table, TD, TR } from '@lumapps/wrex-table/components/blocks';
import { CodeBlock } from '@lumapps/wrex-typography/components/blocks/CodeBlock';
import { Headline } from '@lumapps/wrex-typography/components/blocks/Headline';
import { InlineCode } from '@lumapps/wrex-typography/components/blocks/InlineCode';
import { Bold, Colored, Italic, Strikethrough, Underline } from '@lumapps/wrex-typography/components/blocks/Mark';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';
import { Subtitle } from '@lumapps/wrex-typography/components/blocks/Subtitle';
import { Title } from '@lumapps/wrex-typography/components/blocks/Title';
import { TYPOGRAPHY_FEATURES } from '@lumapps/wrex-typography/constants';
import { UserMention } from '@lumapps/wrex-user-mention/components/blocks/UserMention';

/**
 * Exhaustive list of rendered elements in structured content.
 */
export const ELEMENTS = [
    Paragraph,
    Headline,
    CodeBlock,
    Title,
    Subtitle,
    Link,
    LinkPreview,
    File,
    ImageGroup,
    AlignedImageWrapper,
    UnorderedList,
    OrderedList,
    ListItem,
    UserMention,
    InlineCode,
    QuoteBlock,
    Table,
    TR,
    TD,
    EmbeddedMap,
    EmbeddedVideo,
    PlayVideo,
];

/**
 * Exhaustive list of rendered mark in structured content
 */
export const MARKS = [Bold, Italic, Underline, Colored, Strikethrough];

/**
 * Defines the structured content toolbar breakpoints
 */
export const STRUCTURED_CONTENT_TOOLBAR_BREAKPOINTS = {
    /** Warning message + Undo, Redo, Formatting options, More insert options */
    'too-small': 0,
    /** Undo, Redo, Formatting options, More insert options */
    'group-insert': 199,
    /** Undo, Redo, Formatting options, Link, Emoji, More insert options */
    'formatting-submenu': 235, // Using 235 instead of the too small 230 of the mockup
    /** Undo, Redo, BlockType selector, Bold, Italic, More options, Link, Emoji, More insert options */
    'no-color': 325, // Using 325 instead of the too small 300 of the mockup
    /** Undo, Redo, BlockType selector, Bold, Italic, Color, More options, Link, Emoji, More insert options */
    'no-image-align': 370, // Using 370 instead of the to small 350 of the mockup
    /** Undo, Redo, BlockType selector, Bold, Italic, Color, Align selector, More options, Link, Emoji, Image, More insert options */
    'no-video-list': 435,
    /** Undo, Redo, BlockType selector, Bold, Italic, Color, Align selector, Bulleted list, More options, Link, Emoji, Image, Video, More insert options */
    'no-insert-label': 500,
    /** Undo, Redo, BlockType selector, Bold, Italic, Color, Align selector, Bulleted list, More options, Link, Emoji, Image, Video, More insert options */
    'no-blocktype-label': 600,
    /** Undo, Redo, BlockType selector, Bold, Italic, Color, Align selector, Bulleted list, More options, Link, Emoji, Image, Video, More insert options */
    full: 700,
};

/** List of the plugins enabled features for the structured content editor */
export const structuredContentEditorEnabledFeatures = {
    typography: [
        TYPOGRAPHY_FEATURES.bold,
        TYPOGRAPHY_FEATURES.italic,
        TYPOGRAPHY_FEATURES.underline,
        TYPOGRAPHY_FEATURES.color,
        TYPOGRAPHY_FEATURES.strikethrough,
        TYPOGRAPHY_FEATURES.headline,
        TYPOGRAPHY_FEATURES.title,
        TYPOGRAPHY_FEATURES.subtitle,
        TYPOGRAPHY_FEATURES.inlineCode,
        TYPOGRAPHY_FEATURES.codeBlock,
        TYPOGRAPHY_FEATURES.alignment,
    ],
    list: [LIST_FEATURES.unorderedList, LIST_FEATURES.orderedList],
    enhancedImage: [
        IMAGE_FEATURES.alignment,
        IMAGE_FEATURES.width,
        IMAGE_FEATURES.imageGallery,
        IMAGE_FEATURES.generateWithAI,
        IMAGE_FEATURES.link,
    ],
};
