import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

// TODO: LUM-14358 rename the path to /admin/communities when switching definitely to the reworked page
export const communitiesAdminRoute: Route = {
    path: '/admin/community',
    legacyId: 'app.admin.community',
    appId: AppId.backOffice,
};

export const spacesAdminRoute: Route = {
    path: '/admin/spaces',
    legacyId: 'app.admin.spaces',
    appId: AppId.backOffice,
};

/**
 * Retrieves the route for module's admin.
 */
export const admin = (): Route => {
    return communitiesAdminRoute;
};
