import React from 'react';

import { Directory, DirectoryTemplateComponentType } from '@lumapps/directories/types';
import { FormSelect } from '@lumapps/lumx-forms/components/FormSelect';
import { FormSelectMultiple } from '@lumapps/lumx-forms/components/FormSelectMultiple';
import { FormTextField } from '@lumapps/lumx-forms/components/FormTextField';
import { TranslateObject, useTranslate } from '@lumapps/translations';

import { TEMPLATE_PREFIX } from '../../constants';

export interface DirectoryEntriesTemplateFieldsetProps {
    directory: Directory;
}

/**
 * Generates the Template Fieldset for creating a directory entry.
 * @param DirectoryEntriesTemplateFieldsetProps
 * @returns DirectoryEntriesTemplateFieldset
 */
export const DirectoryEntriesTemplateFieldset = ({ directory }: DirectoryEntriesTemplateFieldsetProps) => {
    const { translateObject } = useTranslate();
    /**
     * Since this is legacy data, this is pretty defensive just in case there is something there that is not defined.
     */
    const hasComponents =
        directory.template && directory.template.components && directory.template.components.length > 0;

    if (!hasComponents) {
        return null;
    }

    /**
     * For each of the components of the directory, we need to render a Form Input, and
     * depending on the type of input, we render either a FormTextField, or a FormSelect.
     */
    const Components = directory.template?.components.map((component) => {
        const { title, type, uuid, properties } = component;
        const name = `${TEMPLATE_PREFIX}${uuid}`;

        /**
         * Avoid rendering items that do not have either properties, or a type or a title.
         */
        if (properties && type && title) {
            const { mandatory, textarea } = properties;

            if (type === DirectoryTemplateComponentType.inputText) {
                return (
                    <FormTextField
                        label={translateObject(title) || ''}
                        name={name}
                        key={uuid}
                        isRequired={mandatory}
                        multiline={textarea}
                        isMultiLanguage
                    />
                );
            }
            if (type === DirectoryTemplateComponentType.inputSelect) {
                const { availableValues = [], multiple } = properties;
                const choices = availableValues;
                const getValueId = (val: TranslateObject) => translateObject(val) || uuid;
                const getValueName = (val: TranslateObject) => translateObject(val) || '';

                const props = {
                    label: translateObject(title) || '',
                    name,
                    key: uuid,
                    isRequired: mandatory,
                    choices,
                };

                if (multiple) {
                    return (
                        <FormSelectMultiple
                            {...props}
                            isMultiLanguage
                            getValueId={getValueId}
                            getValueName={getValueName}
                        />
                    );
                }

                return (
                    <FormSelect
                        {...props}
                        isClearable
                        isMultiLanguage
                        getValueId={getValueId}
                        getValueName={getValueName}
                    />
                );
            }
        }

        return null;
    });

    /**
     * Both the linter and TypeScript are not happy that we render a list of components without a wrapping element.
     * And in order to avoid an empty div, a Fragment is used. But using the Fragment breaks another eslint rule.
     * Since it causes no harm on this, we ignore the rule.
     */
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{Components}</>;
};
