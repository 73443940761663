export function useResolveHTMLPopin(ref: any, openPopin: any) {
    const element = ref && ref.current;

    if (!element) {
        return;
    }

    Array.from(element.querySelectorAll('a[href="popin"]')).forEach((popin: any) => {
        popin.addEventListener('click', openPopin);
        popin.removeAttribute('ng-click');
        popin.setAttribute('href', '#popin');
    });
}
