import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface LoadMoreFocusHelperProps {
    /** The container of your helper, default is div, but if you are in a ul, you want to use a li for example. */
    containerAs?: React.ElementType;
    /** The index of the item you want to compare with. */
    itemIndex: number;
    /** The number of items per page. */
    itemsPerPage?: number;
    /** The number of items displayed. */
    itemsDisplayedLength: number;
    /** Overrides the default message read by screen readers when a new page is loaded. DEFAULT: "X new items loaded" */
    loadedMessage?: string;
}
/**
 * This component should be used in lists with a load more button.
 * It should be put above every item of your list.
 * You must be able to pass it the item index in order to determine if it should display the helper or not.
 * The focus helper will be displayed before the first new item, and the focus will be automatically
 * caught by the helper, allowing the user to navigate to the newly loaded item with a tab.
 */
export const LoadMoreFocusHelper = ({
    containerAs: Component = 'p',
    itemIndex,
    itemsPerPage,
    itemsDisplayedLength,
    loadedMessage,
}: LoadMoreFocusHelperProps) => {
    const a11yIndex = itemsPerPage && (Math.ceil(itemsDisplayedLength / itemsPerPage) - 1) * itemsPerPage;
    const { translateAndReplace } = useTranslate();

    const focusOnMount = React.useCallback((element: any) => {
        if (element instanceof HTMLElement) {
            element.focus({ preventScroll: true });
        }
    }, []);

    // If first index or not the first page index
    if (!a11yIndex || itemIndex !== a11yIndex) {
        return null;
    }

    const message =
        loadedMessage ||
        translateAndReplace(GLOBAL.NEW_ITEMS_LOADED_MESSAGE, {
            NB_OF_ITEMS: String(itemsPerPage),
        });

    return (
        <Component ref={focusOnMount} tabIndex={-1} className={visuallyHidden()} role="alert" aria-live="polite">
            {message}
        </Component>
    );
};
