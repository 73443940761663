import React, { PureComponent } from 'react';
import classNames from 'classnames';
import toUpper from 'lodash/toUpper';

import { forecastType } from './types';
import { translate as t } from '../../../translations';

/**
 * Displays the forecast information from widget weather.
 */
export class ForecastBlock extends PureComponent {
    static propTypes = forecastType;

    render() {
        const { forecast, isFirst, city, unit, inputLang } = this.props;

        return (
            <div className={classNames('widget-weather__item', { 'widget-weather__item--first': isFirst })}>
                <div className="widget-weather__date">{forecast.formattedDate}</div>

                <div className="widget-weather__city">{city}</div>

                <div className="widget-weather__temp">
                    <span className="widget-weather-temp__min">
                        {forecast.low + ' °' + toUpper(t(unit, undefined, inputLang))}
                    </span>
                    <span className="widget-weather-temp__slash"> / </span>
                    <span className="widget-weather-temp__max">
                        {forecast.high + ' °' + toUpper(t(unit, undefined, inputLang))}
                    </span>
                </div>

                <div className={classNames('widget-weather__image', `widget-weather__image--${forecast.code}`)} />
            </div>
        );
    }
}
