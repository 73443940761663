import React from 'react';

import { useRunMicroApp } from '../../hooks/useRunMicroApp';
import { useSubmitRunMicroApp } from '../../hooks/useSubmitRunMicroApp';
import { MicroAppProps, SubmitMicroAppAction } from '../../types';
import { MicroAppCard } from '../MicroAppCard';

/**
 * Sets <MicroAppCard /> to display the given micro app.
 * The micro-app is fetched using the provided micro app id
 *
 * @param MicroAppProps
 * @returns MicroApp
 */
export const MicroApp: React.FC<MicroAppProps> = ({ className, id: microAppId, setIsLoaded, runInstanceId }) => {
    const {
        data: microApp,
        isFetching,
        isError: fetchingHasFailed,
        error: fetchingError,
    } = useRunMicroApp(microAppId, runInstanceId, { enabled: Boolean(microAppId) });

    const { mutate: submitRunMicroApp, isLoading: isSubmitting, isError: submitHasFailed } = useSubmitRunMicroApp();

    const handleSubmit = (action: SubmitMicroAppAction) => {
        if (!microApp || !microApp.runId) {
            return;
        }
        submitRunMicroApp({
            action,
            microAppId,
            runId: microApp.runId,
            runInstanceId,
        });
    };

    const showLoadingState = isFetching || isSubmitting;

    const showNotAuthorizedState = fetchingHasFailed && fetchingError?.response?.status === 403;

    const showErrorState = fetchingHasFailed || submitHasFailed;

    return (
        <MicroAppCard
            adaptiveCard={microApp?.adaptiveCard}
            className={className}
            hasError={showErrorState}
            id={microAppId}
            isLoading={showLoadingState}
            isNotAuthorized={showNotAuthorizedState}
            lumappsComponent={microApp?.lumappsComponent}
            onSubmit={handleSubmit}
            setIsLoaded={setIsLoaded}
        />
    );
};

MicroApp.displayName = 'MicroApp';
