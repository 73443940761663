import isEmpty from 'lodash/isEmpty';

import type { FrontOfficeStore } from '@lumapps/redux/stores/frontOffice';
import { WIDGET_CONTENT_FILTER_TYPE } from '@lumapps/widget-content-filter/constants';

import { FetchBlockParams } from '../api';
import { getClientComputedWidgetsOfType, getWidget } from '../ducks/selectors';
import { formatWidgetFiltersForApi } from './formatWidgetFiltersForApi';

/**
 * Combine filters from the store and from the params and format them for V2 APIs params
 *
 * @param currentState  Redux state
 * @param params        Widget fetch params
 * @param widgetId      Widget id
 */
export function getFormattedWidgetFilterFetchParams(
    currentState: FrontOfficeStore,
    params: FetchBlockParams | undefined,
    widgetId: string,
): FetchBlockParams {
    const widget = getWidget(currentState, { widgetId });
    const filtersFromStore = widget?.filters ? formatWidgetFiltersForApi(widget.widgetType, widget.filters) : undefined;
    const filtersFromParams =
        params?.filters && widget ? formatWidgetFiltersForApi(widget.widgetType, params?.filters) : undefined;

    const hasContentFilterWidget = !isEmpty(getClientComputedWidgetsOfType(WIDGET_CONTENT_FILTER_TYPE)(currentState));

    const filterFetchParams: FetchBlockParams = {
        /** Filters from params must take precedence over those from the store */
        filters: { ...filtersFromStore, ...filtersFromParams },
    };
    /** Forcing block no result if we are filtering a main widget with a content filter */
    if (widget?.isMainWidget && hasContentFilterWidget) {
        filterFetchParams.forceDisplay = true;
    }
    return filterFetchParams;
}
