import React from 'react';

import { padding, classnames } from '@lumapps/classnames';
import { Switch, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_CONTENT_FILTER } from '../../keys';

interface HighlightedContentFilterProps {
    isChecked: boolean;
    onChange: (value: boolean) => void;
    theme: Theme;
    scope: string;
}

export const HighlightedContentFilter = ({ isChecked, theme, scope, onChange }: HighlightedContentFilterProps) => {
    const { translateKey } = useTranslate();
    return (
        <Switch
            className={classnames(padding('top', 'big'), padding('bottom', 'regular'))}
            theme={theme}
            scope={scope}
            isChecked={isChecked}
            onChange={onChange}
        >
            {translateKey(WIDGET_CONTENT_FILTER.HIGHLIGHTED_FOR_ME)}
        </Switch>
    );
};
