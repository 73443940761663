import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { SHORTCUTS } from '../../keys';
import { KeyDownArrow } from './KeyDownArrow';
import { KeyLeftArrow } from './KeyLeftArrow';
import { KeyRightArrow } from './KeyRightArrow';
import { KeyUpArrow } from './KeyUpArrow';

/**
 * Display all arrow keys but only read `Arrow keys` string to screen readers.
 */
export const ArrowKeys: React.FC = () => {
    const { translateKey } = useTranslate();
    return (
        <>
            <Text as="span" className={visuallyHidden()}>
                {translateKey(SHORTCUTS.ARROW_KEYS)}
            </Text>
            <Text as="span" aria-hidden>
                <KeyUpArrow /> <KeyDownArrow /> <KeyLeftArrow /> <KeyRightArrow />
            </Text>
        </>
    );
};
