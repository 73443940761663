/* istanbul ignore file */
import React from 'react';

import { useCustomContentTypes } from '../../hooks/useCustomContentTypes';
import {
    CustomContentTypesSideNavigation,
    CustomContentTypesSideNavigationProps,
} from './CustomContentTypesSideNavigation';

const ConnectedCustomContentTypesSideNavigation: React.FC<Partial<CustomContentTypesSideNavigationProps>> = ({
    onFinishedLoading,
    ...props
}) => {
    const { allowedCustomContentTypes, parentCustomContentTypes, isAllowed, isLoading, isSuperAdmin } =
        useCustomContentTypes({ onFinishedLoading, invalidateCache: true });

    return (
        <CustomContentTypesSideNavigation
            allowedCustomContentTypes={allowedCustomContentTypes}
            isAllowed={isAllowed}
            isSuperAdmin={isSuperAdmin}
            parentCustomContentTypes={parentCustomContentTypes}
            isLoading={isLoading}
            {...props}
        />
    );
};

export { ConnectedCustomContentTypesSideNavigation as CustomContentTypesSideNavigation };
