/* eslint-disable id-blacklist */
/* eslint-disable import/unambiguous */
import { error } from 'components/services/notification';
import { translate as t } from 'components/translations';

import { getChartImageTask } from './chart_tasks';

const INIT_STATE = 'chart/INIT_STATE';
const RESET_STATE = 'chart/RESET_STATE';
const SET_CHARTIMAGEURL = 'chart/SET_CHARTIMAGEURL';
const SET_WIDGETWIDTH = 'chart/SET_WIDGETWIDTH';
const SET_ISLOADING = 'chart/SET_ISLOADING';

/**
 * Task action for retrieving the selected file chart.
 *
 * @param  {string}   chartId     The selected chart id.
 * @param  {string}   driveId     The drive id.
 * @param  {string}   fileId      The file id.
 * @param  {string}   uuid        The widget uuid.
 * @param  {number}   chartWidth  The request chart width.
 * @param  {number}   chartHeight The request chart height.
 * @return {Function} The task logic.
 */
const getChart = (chartId, driveId, fileId, chartWidth, chartHeight, uuid) => (dispatch) => {
    dispatch({
        payload: {
            data: true,
            uuid,
        },
        type: SET_ISLOADING,
    });

    return getChartImageTask(chartId, driveId, fileId, chartWidth, chartHeight)
        .then(({ payload } = {}) => {
            dispatch({
                payload: {
                    data: payload,
                    uuid,
                },
                type: SET_CHARTIMAGEURL,
            });
        })
        .catch(() => error(t('FRONT.WIDGET_CHART.ERROR.GET_CHARTIMAGE_ERROR')))
        .finally(() => {
            dispatch({
                payload: {
                    data: false,
                    uuid,
                },
                type: SET_ISLOADING,
            });
        });
};

/**
 * Dispatch the INIT_STATE action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const initState = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: INIT_STATE,
        });
    })();
};

/**
 * Dispatch the RESET_STATE action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const resetState = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: RESET_STATE,
        });
    })();
};

/**
 * Dispatch the SET_WIDGETWIDTH action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setWidgetWidth = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_WIDGETWIDTH,
        });
    })();
};

export {
    INIT_STATE,
    RESET_STATE,
    SET_ISLOADING,
    SET_CHARTIMAGEURL,
    SET_WIDGETWIDTH,
    initState,
    getChart,
    resetState,
    setWidgetWidth,
};
