import { sendWebviewMobileMessage } from '@lumapps/mobile-webview/message-emit';
import { formatDate } from '@lumapps/utils/date/formatDate';
import { isToday } from '@lumapps/utils/date/isToday';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import {
    ApplicationDesign,
    MessageToSendToBot,
    MessageReceivedFromBot,
    CompoundMessageType,
    HistoryMessage,
} from '../types';

export const widgetSize = (design?: ApplicationDesign): 's' | 'm' | 'l' => {
    const pictoSize = design?.widgetPictoSize;
    if (pictoSize === 'vz-big') {
        return 'l';
    }
    if (pictoSize === 'vz-small') {
        return 's';
    }
    return 'm';
};

export const widgetStyle = (design?: ApplicationDesign): 'square' | 'round' => {
    const pictoStyle = design?.widgetPictoShape;
    switch (pictoStyle) {
        case 'vz-round':
            return 'round';
        default:
            return 'square';
    }
};

export const isMessageFromUser = (message?: MessageToSendToBot | MessageReceivedFromBot): boolean | null => {
    if (!message) {
        return null;
    }
    return 'resetUser' in message;
};

/**
 * Group messages by consecutive source (assistant/user)
 * @param {Array<CompoundMessageType>} messages - list of messages
 * @returns list of groups of messages
 */
export const groupMessagesByOrigin = (messages: CompoundMessageType[]): Array<Array<CompoundMessageType>> => {
    // reducer to iterate over all messages
    const result = messages.reduce((messageGroups: Array<Array<CompoundMessageType>>, message) => {
        // if the current message's source is the same as the first message of the last group
        // (i.e. all messages in the group should have the same source)
        // then add the message to the group
        const lastGroup = messageGroups[messageGroups.length - 1];
        if (messageGroups.length && isMessageFromUser(lastGroup[0]) === isMessageFromUser(message)) {
            lastGroup.push(message);
        } else {
            // otherwise create another message group with the current message
            // and append it to the list of message groups
            messageGroups.push([message]);
        }
        return messageGroups;
    }, []);
    return result;
};

export const messageDisplayDate = (date: string) => {
    if (isToday(new Date(date))) {
        return formatDate(date, 'LT');
    }
    return formatDate(date, 'LL');
};

export const sanitizeAssistantMessage = (message: string) => {
    if (!message) {
        return null;
    }
    return sanitizeHTML(message, { useProfiles: { html: true } });
};

const fillUpAdaptiveCardValue = (element: any, key: string, value: unknown) => {
    if (element?.id === key) {
        // eslint-disable-next-line no-param-reassign
        element.value = value;
        return element;
    }

    if (element?.body) {
        for (const el of element.body) {
            fillUpAdaptiveCardValue(el, key, value);
        }
    } else if (element?.columns) {
        for (const el of element.columns) {
            fillUpAdaptiveCardValue(el, key, value);
        }
    } else if (element?.items) {
        for (const el of element.items) {
            fillUpAdaptiveCardValue(el, key, value);
        }
    }
    return element;
};

/**
 * Fill up an adaptive card schema with the user values retrieved on submit
 * @param {Record<string, unknown>} card - adaptive card schema to fill up
 * @param {Record<string, unknown>} fields - fields to fill up (key: field id, value: field value)
 */

export const fillUpAdaptiveCard = (card: Record<string, unknown>, fields: Record<string, unknown>, id?: string) => {
    let newCard = { ...card, id };
    Object.keys(fields).forEach((key: string) => {
        if (key !== 'mappingMessage') {
            newCard = fillUpAdaptiveCardValue(newCard, key, fields[key]) || newCard;
        }
    });
    return newCard;
};

export const formatHistoryMessage = (message: HistoryMessage) => {
    if (message?.isUser) {
        return {
            ...message,
            createdAt: message.createdAt,
            message: {
                text: message.text,
            },
            resetUser: false,
        };
    }
    return message;
};

/**
 * Before following any url from the assistant, we want to check if we are in the mobile app, and if so we want to intercept the link in order to open it with the mobile application.
 * this method is temporary, until we have a way to pick lumapps content in the assistant admin and thus send a real content id to the mobile app.
 * so TODO => rework this method AND its implementations with a content id.
 */

export const openLink = (url: string) => {
    if (url?.length > 0 && (url.startsWith('http:') || url.startsWith('https:'))) {
        if ((window as any).webkit?.messageHandlers?.lumappsMessageHandler || (window as any)?.lumappsMessageHandler) {
            sendWebviewMobileMessage({
                type: 'redirect',
                payload: {
                    type: 'unknown',
                    url,
                },
            });
        } else {
            window.open(url, '_blank');
        }
    }
};

export const lastTextMessage = (messages: CompoundMessageType[]) =>
    messages
        .slice()
        .reverse()
        .find((m) => m.text?.length && m.text.length > 0);
