import { createSelector } from '@lumapps/redux/reselect';
import type { BaseStore } from '@lumapps/redux/types';

import { type Customer, PROPERTIES_DEFAULT_VALUES } from '../types';

/**
 * Returns the customer for the current session
 * @param {BaseStore} state
 * @returns {Customer}
 */
export const customerSelector = (state: BaseStore) => state?.customer || {};

/**
 * Returns the customer id for the current session
 */
export const customerIdSelector = createSelector(customerSelector, (customer: Customer) => customer.id);

/**
 * Returns the customer name for the current session
 */
export const customerNameSelector = createSelector(customerSelector, (customer: Customer) => customer.name);

/**
 * Returns the specific group ALL associated to the current customer
 */
export const getGroupAll = createSelector(customerSelector, (customer) => customer.feedAll);

/**
 * Returns the specific group PUBLIC associated to the current customer
 */
export const getGroupPublic = createSelector(customerSelector, (customer) => customer.feedPublic);

/**
 * Returns the list of features flags that are enabled for the current customer
 */
export const customerFeaturesSelector = createSelector(
    customerSelector,
    (customer: Customer) => customer.enabledFeatures,
);

/**
 * Returns the list of the available languages configured for the customer.
 */

export const getAvailableLanguages = createSelector(
    customerSelector,
    (customer: Customer) => customer.availableLanguages || [],
);

/**
 * Returns the properties associated to the current customer
 */
export const getCustomerProperties = createSelector(
    customerSelector,
    (customer: Customer) => customer.properties || PROPERTIES_DEFAULT_VALUES,
);

/**
 * Returns whether SSO is enabled for the current customer
 */
export const isSsoEnabled = createSelector(customerSelector, (customer: Customer) =>
    Boolean(customer.ssoEnabled && customer.googleCustomer),
);

/**
 * Returns the slug of the customer.
 */
export const getSlug = createSelector(customerSelector, (customer: Customer) => customer.slug || '');

/**
 * Returns whether the current customer has terms and conditions associated to it or not.
 */
export const hasTermsAndConditions = createSelector(
    customerSelector,
    (customer: Customer) => customer.hasTermsAndConditions,
);

/**
 * Returns the mobile login configuration for the current customer.
 */
export const getMobileLoginConfiguration = createSelector(
    customerSelector,
    (customer: Customer) => customer.mobileLoginConfiguration || {},
);

/**
 * Returns params for Mobile Customization.
 */
export const getMobileCustomization = createSelector(
    customerSelector,
    (customer: Customer) => customer.mobileCustomization || {},
);

/**
 * Retrieve legacy customization options for the site settings page. These options should not
 * be used outside of that scope!
 */
export const getLegacyCustomizationOptions = createSelector(customerSelector, (customer: Customer) => {
    // eslint-disable-next-line deprecation/deprecation
    const { themes = [], configurations = [], overrides = [] } = customer;

    return {
        themes,
        configurations,
        overrides,
    };
});

/**
 * Returns a summarized version of the current customer. This is mainly used in our customizations
 * api in order to avoid exposing all the customer's fields to that API.
 */
export const getSummarizedCustomer = createSelector(customerSelector, (customer: Customer) => {
    const { id, enabledFeatures, name, slug } = customer;

    return {
        id,
        enabledFeatures,
        name,
        slug,
    };
});

/**
 * Returns the list of login methods.
 */
export const getLoginMethods = createSelector(customerSelector, (customer: Customer) => customer.loginMethods || []);

/**
 * Returns whether the current customer can display public content or not
 */
export const isPublicContentAuthorized = createSelector(customerSelector, (customer: Customer) =>
    Boolean(customer.publicContentAuthorized),
);

/**
 * Returns the list of SAML providers.
 */
export const getSamlProviders = createSelector(customerSelector, (customer: Customer) => customer.samlProviders || []);

export const getMobileInstanceKeys = createSelector(
    customerSelector,
    (customer: Customer) => customer.mobileInstanceKeys || [],
);

export const getMobileSearchInstanceKeys = createSelector(
    customerSelector,
    (customer: Customer) => customer.mobileSearchInstanceKeys || [],
);
