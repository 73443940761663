export enum MOBILE_LOGIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=87740116
     */
    LOGIN_HELP_URL = 'ADMIN.MOBILE.LOGIN_HELP_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=87740124
     */
    LOGIN_HELP_URL_HELPER = 'ADMIN.MOBILE.LOGIN_HELP_URL_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150651429
     */
    LOGIN_CUSTOM_LINK = 'ADMIN.MOBILE.CUSTOM_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=87740129
     */
    PASSWORD_RECOVER_URL = 'ADMIN.MOBILE.PASSWORD_RECOVER_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=87740137
     */
    PASSWORD_RECOVER_URL_HELPER = 'ADMIN.MOBILE.PASSWORD_RECOVER_URL_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=87740151
     */
    MOBILE_SETTINGS_LOGIN = 'ADMIN.NAV.MOBILE_SETTINGS_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109579
     */
    QR_CODE_ACCESS = 'MOBILE_LOGIN.QR_CODE_ACCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109575
     */
    LOGIN_HELP_URL_PAGE_NAME = 'MOBILE_LOGIN.QR_HELP_URL_PAGE_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109590
     */
    QR_CODE_ACCESS_HELPER = 'MOBILE_LOGIN.QR_CODE_ACCESS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109598
     */
    ADD_NEW_RULE = 'MOBILE_LOGIN.ADD_NEW_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109602
     */
    ACCESS_GIVER = 'MOBILE_LOGIN.ACCESS_GIVER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109612
     */
    ACCESS_RECEIVER = 'MOBILE_LOGIN.ACCESS_RECEIVER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109605
     */
    ACCESS_GIVER_HELPER = 'MOBILE_LOGIN.ACCESS_GIVER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109626
     */
    ACCESS_RECEIVER_HELPER = 'MOBILE_LOGIN.ACCESS_RECEIVER_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109629
     */
    MANAGE_ACCESS_FOR = 'MOBILE_LOGIN.MANAGE_ACCESS_FOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=214109639
     */
    NO_AUTHORIZED_DUPLICATION_RULE = 'MOBILE_LOGIN.NO_AUTHORIZED_DUPLICATION_RULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232225398
     */
    DUPLICATED_RULE = 'MOBILE_LOGIN.DUPLICATED_RULE',
}
