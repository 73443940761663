(function IIFE() {
    'use strict';

    /////////////////////////////

    /**
     * Contains the list of the specifc widgets configs.
     * This will be filled by the `Settings` service when the `WIDGET_TYPES` will be loaded from the backend at init.
     *
     * @type {Array}
     */
    var WidgetConstant = [];

    /////////////////////////////

    angular.module('Constants').constant('WidgetConstant', WidgetConstant);
})();
