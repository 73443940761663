/**
 * Contains the list of supported providers.
 */

const ORDER_BY = ['CREATED', 'GROUPNAME', 'AUTHOR'];

const POST_TYPES = ['ALL', 'NORMAL', 'ANNOUNCEMENT', 'POLL', 'QUESTION', 'PRAISE'];

const FB_POST_TYPES = ['ALL', 'ATTACHMENTS', 'TEXT'];

const PROVIDERS = ['yammer', 'fbworkplace'];


export { ORDER_BY, POST_TYPES, PROVIDERS, FB_POST_TYPES };
