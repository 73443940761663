import { ServerListRequest, ServerListResponse } from '@lumapps/base-api';
import { CommunityDriveFolder } from '@lumapps/communities/types';
import { Group } from '@lumapps/groups/types';
import { DialogProps } from '@lumapps/lumx/react';
import { SpaceUserRole } from '@lumapps/spaces/types';
import { LoginProvider, UserAccountType, User } from '@lumapps/user/types';

export enum ConfigurationDialogSteps {
    information = 'information',
    visibility = 'visibility',
    members = 'members',
    services = 'services',
    spaceServices = 'spaceServices',
    posts = 'posts',
    options = 'options',
}

/** Types related to Members Step */

export enum CommunityMemberType {
    REQUESTS = 'requests',
    USERS = 'users',
    GROUPS = 'groups',
    GROUPS_FOLLOWING = 'groupsFollowing',
}

export enum CommmunityMemberRole {
    MANAGER = 'manager',
    MEMBER = 'member',
}
export interface CommunityUserMember extends Partial<User> {
    customer?: string;
    id: string;
    fullName: string;
    jobTitle?: string;
    role: CommmunityMemberRole;
    profilePictureUrl?: string;
    isBeingDeleted?: boolean;
}

export type PickedUsersApiResponse = ServerListResponse<{
    id: string;
    fullName?: string;
    profilePrimaryFieldValues?: string[];
    profilePictureUrl?: string;
    role: SpaceUserRole;
}>;

export interface PickedUsersApiOptions extends Partial<Pick<ServerListRequest, 'maxResults' | 'cursor' | 'query'>> {
    role?: SpaceUserRole;
}

export interface CommunityGroupMember extends Group {
    role: CommmunityMemberRole;
}

export type CommunityMember = CommunityUserMember | CommunityGroupMember;

export enum FetchMembersStatus {
    initial = 'initial',
    loading = 'loading',
    loadingMore = 'loadingMore',
    loaded = 'loaded',
    error = 'error',
}

export interface CommunityConfigurationDialogProps extends DialogProps {
    // The step that should be displayed.
    displayedSteps: ConfigurationDialogSteps[];
    // The initial step to display as active.
    initialStep?: ConfigurationDialogSteps;
    // The community id to fetch.
    communityId: string;
    // The customer id the community comes from.
    customerId: string;
    // The instance id the community comes from.
    instanceId: string;
    // The current language.
    userLanguage: string;
    // The current user account type.
    userAccountType: UserAccountType;
    // The current user login provider.
    userLoginProvider?: LoginProvider;
    // The currently selected drive folder in the legacy drive picker.
    legacySelectedDriveFolder?: CommunityDriveFolder;
    // Whether the legacy drive picker is currently opened.
    legacyDrivePickerIsOpen?: boolean;
    // Action to trigger the legacy drive picker to open.
    openLegacyDrivePicker(): void;
    // Action to update the legacy drive picker value.
    updateLegacyDrivePicker(drive: CommunityDriveFolder): void;
    // Action to close the dalog.
    onClose(): void;
}
