import debounce from 'lodash/debounce';
import extend from 'lodash/extend';
import get from 'lodash/get';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function LsFeedSelectorController($q, $scope, Features, Feed, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay for debouncing the filter function.
     * In milliseconds.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEBOUNCE_DELAY = 250;

    /**
     * The current value for list filtering.
     *
     * @type {string}
     */
    let _currentFilterText;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The default restrict list key.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.RESTRICT_LIST_KEY = 'feed-list-';

    /**
     * Contains various status of the directive.
     *
     * @type {Object}
     */
    vm.is = {
        initialized: false,
        loading: false,
    };

    /**
     * Indicates if the feed selector is empty.
     *
     * @type {boolean}
     */
    vm.isEmpty = false;

    /**
     * Indicates if the feed selector for 'Feed._KEY_OTHERS' is empty.
     *
     * @type {boolean}
     */
    vm.isEmptyFeedOthers = false;

    /**
     * Array of listKeys which are allowed to list groups from segments
     *
     * @type {Array}
     */

    vm.includeSegmentsListkeys = ['visible-by', 'highlighted-for'];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Feed = Feed;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Compute the parameters of the feed search query.
     *
     * @return {Object} The parameters for the feed search query.
     */
    function _computeParams(listKey) {
        return extend(vm.params, {
            except: angular.fastCopy(vm.except),
            hasGroup: vm.googleGroupsOnly ? Boolean(vm.googleGroupsOnly) : undefined,
            restrictTo: angular.fastCopy(vm.restrictTo),
            excludeSegments: !vm.includeSegmentsListkeys.includes(listKey),
        });
    }

    /**
     * Gets the filtered list of feeds.
     *
     * @param {string} [filterValue] The value of the text filter of the feed selector.
     * @param {string} [listKey]     The key of the list of feeds linked to the feed selector directive.
     */
    function _filterFeeds(filterValue, listKey) {
        vm.is.loading = true;

        vm.isEmptyFeedOthers = false;
        vm.isEmpty = false;

        _currentFilterText = angular.fastCopy(filterValue);

        Feed.search(filterValue, _computeParams(listKey), listKey)
            .then((feeds) => {
                vm.isEmpty = angular.isUndefinedOrEmpty(feeds);
                vm.isEmptyFeedOthers = angular.isUndefinedOrEmpty(feeds[Feed.getKeyOthers()]);
            })
            .finally(() => {
                vm.is.loading = false;
                vm.is.initialized = true;
            });
    }

    /**
     * When the `except` parameter is updated, reload the list of filtered feeds and remove from model feed ids that
     * are excepted.
     *
     * @param {Array} [newExcept] The new list of restricted feeds.
     * @param {Array} [oldExcept] The previous list of restricted feeds.
     */
    function _exceptUpdate(newExcept, oldExcept) {
        if (Utils.equals(newExcept, oldExcept)) {
            return;
        }

        // Remove feed ids from model which are not in the source feed list ids anymore.
        vm.ngModel = Feed.removeFeedIdsInSourceFeedArray(newExcept, vm.ngModel);

        // Get new filteredFeedList because the source feed list ids has changed.
        _filterFeeds(_currentFilterText, vm.listKey);
    }

    const _debouncedExceptUpdate = debounce(_exceptUpdate, _DEBOUNCE_DELAY * 2);

    /**
     * When the `restrictTo` parameter is updated, reload the list of filtered feeds and remove from  model feed ids
     * that are not in the restriction list.
     *
     * @param {Array} [newRestricTo]  The new list of restricted feeds.
     * @param {Array} [oldRestrictTo] The previous list of restricted feeds.
     */
    function _restrictToUpdate(newRestricTo, oldRestrictTo) {
        if (
            Utils.equals(newRestricTo, oldRestrictTo) ||
            angular.isUndefinedOrEmpty([newRestricTo, oldRestrictTo], 'every')
        ) {
            return;
        }

        // Remove feed ids from model which are not in the source feed list ids anymore.
        vm.ngModel = Feed.removeFeedIdsNotInSourceFeedArray(newRestricTo, vm.ngModel);

        // Get new filteredFeedList because the source feed list ids has changed.
        _filterFeeds(_currentFilterText, vm.listKey);
    }

    const _debouncedRestrictToUpdate = debounce(_restrictToUpdate, _DEBOUNCE_DELAY * 2);

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Load another page of feeds and concat these with the already populated list key.
     *
     * @return {Promise} promise of loaded feeds.
     */
    function loadNextFeed() {
        if (!vm.infiniteScroll || !vm.Feed.hasMore(vm.listKey)) {
            return Promise.resolve();
        }

        vm.is.loading = true;

        return Feed.search(_currentFilterText, _computeParams(vm.listKey), vm.listKey, true).finally(() => {
            vm.is.loading = false;
        });
    }

    /////////////////////////////

    vm.debouncedFilterFeeds = debounce(_filterFeeds, _DEBOUNCE_DELAY);
    vm.get = get;
    vm.loadNextFeed = loadNextFeed;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * When the `restrictTo` parameter is updated, reload the list of filtered feeds and remove from model feed ids
     * that are not in the restriction list.
     */
    $scope.$watch('vm.restrictTo', _debouncedRestrictToUpdate, true);

    /**
     * When the `except` parameter is updated, reload the list of filtered feeds and remove from model feed ids
     * that are not in the restriction list.
     */
    $scope.$watch('vm.except', _debouncedExceptUpdate, true);

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.is.initialized = false;
        vm.is.loading = true;
        vm.listKey = vm.listKey || `${vm.RESTRICT_LIST_KEY}${generateUUID()}`;
        // Default value for infinite scroll is 'true'
        if (vm.infiniteScroll === undefined) {
            vm.infiniteScroll = true;
        }

        Feed.initFeedList(_computeParams(vm.listKey), vm.listKey)
            .then(() => {
                vm.is.initialized = true;
            })
            .finally(() => {
                vm.is.loading = false;
            });
    }

    init();
}

/////////////////////////////

/**
 * Feed Selector Directive.
 * Display a list of feed depending on restriction.
 *
 * @param {Array}    [except]                 The feeds to exclude from feed list.
 * @param {boolean}  [googleGroupsOnly=false] Whether the feed list is limited only with feed linked with a google
 *                                            group.
 * @param {boolean}  [infiniteScroll="true"]  Whether it has infinite scroll activated.
 * @param {string}   [listKey="default"]      The identifier of the list that is linked to the display of the
 *                                            loader.
 * @param {Function} [ngChange]               The function to be called when selection changes.
 * @param {boolean}  [ngDisabled=false]       Whether the selector is disabled.
 * @param {Object}   ngModel                  The model where to store the selected feeds.
 * @param {Object}   [params]                 The parameters when getting the list of feeds.
 * @param {Array}    [restrictTo]             A list of feeds we want to restrict the selector to.
 * @param {string}   [selectError]            The selected error to display.
 * @param {string}   [selectPlaceholder]      The placeholder to display if there is not item selected.
 * @param {boolean}  [single=false]           Whether its single selection.
 */

function LsFeedSelectorDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: LsFeedSelectorController,
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            except: '<?lsExcept',
            params: '<?lsParams',
            googleGroupsOnly: '<?lsGoogleGroupsOnly',
            infiniteScroll: '<?lsInfiniteScroll',
            listKey: '<?lsListKey',
            ngChange: '&?',
            ngDisabled: '=?',
            ngModel: '=',
            restrictTo: '<?lsRestrictTo',
            selectError: '&lsError',
            selectPlaceholder: '@lsPlaceholder',
            single: '<?lsSingle',
        },
        templateUrl: '/client/common/modules/feed-selector/views/feed-selector.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsFeedSelector', LsFeedSelectorDirective);

/////////////////////////////

export { LsFeedSelectorDirective };
