import sortBy from 'lodash/sortBy';

import { ICON } from 'front/modules/search/js/search_constant';
import { isLumApps, isThirdParty } from '@lumapps/search/utils';

/////////////////////////////

function CloudSearchDataSourceConfigService(CloudSearchDataSourceConfigFactory, LumsitesBaseService) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(CloudSearchDataSourceConfigFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
        /* eslint-disable-next-line no-use-before-define */
        postGet: _formatServerObjectToClient,
    });

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Make sure the customFields are sorted by order.
     *
     * @param  {Object} datasourceConfig The config coming from the REST get endpoint.
     * @return {Object} The formatted datasourceConfig object for the client.
     */
    function _formatServerObjectToClient(datasourceConfig) {
        angular.forEach(datasourceConfig.items, (datasource) => {
            if (angular.isUndefinedOrEmpty(datasource.customFields) || !isThirdParty(datasource.kind)) {
                return;
            }
            datasource.customFields = sortBy(datasource.customFields, 'order');
        });

        return datasourceConfig;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the MDI classname of the icon for a given CloudSearch datasource.
     *
     * @param  {Object} datasource The datasource to get the icon settings for.
     * @return {string} The mdi classname for the datasource.
     */
    function getDataSourceMDIName(datasource) {
        if (isThirdParty(datasource.kind)) {
            return '';
        }

        if (isLumApps(datasource.kind)) {
            return 'lumapps';
        }

        switch (datasource.uid) {
            case 'calendar':
                return ICON.GOOGLE_CALENDAR;

            case 'drive':
                return ICON.GOOGLE_DRIVE;

            case 'groups':
                return ICON.GOOGLE_GROUPS;

            case 'keep':
                return ICON.GOOGLE_KEEP;

            case 'mail':
                return ICON.GOOGLE_MAIL;

            case 'sites':
                return ICON.GOOGLE_SITES;

            default:
                return '';
        }
    }

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    service.getDataSourceMDIName = getDataSourceMDIName;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('CloudSearchDataSourceConfig', CloudSearchDataSourceConfigService);

export { CloudSearchDataSourceConfigService };
