import React from 'react';

import set from 'lodash/set';

import { background, classnames, padding, useClassnames } from '@lumapps/classnames';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { mdiMenuDown, mdiMenuUp } from '@lumapps/lumx/icons';
import { Button, ListItem, Size, Emphasis } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { getChildNotifications as fetchNotifications } from '../../api';
import { MAX_CHILD_NOTIFICATION_NUMBER } from '../../constants';
import { NotificationGroup, NotificationQuery } from '../../types';
import { NotificationListItemCommon, NotificationListItemCommonProps } from './NotificationListItemCommon';
import { NotificationListItemSkeleton } from './NotificationListItemSkeleton';

export type NotificationListItemGroupProps = NotificationListItemCommonProps & {
    getNotifications?(params: NotificationQuery): Promise<NotificationGroup[]>;
    isLoading?: boolean;
    shouldShowItems?: boolean;
};

const CLASSNAME = 'notification-center-list';

export const NotificationListItemGroup: React.FC<NotificationListItemGroupProps> = ({
    notification,
    getNotifications = fetchNotifications,
    className,
    setNotificationRead,
    deleteNotification,
    closeNotificationMenu,
    isLoading = false,
    shouldShowItems = false,
    ...props
}) => {
    const [shouldShowChildren, setShouldShowChildren] = React.useState(shouldShowItems);
    const [isLoadingChildren, setIsLoadingChildren] = React.useState(isLoading);
    const [notifications, setNotifications] = React.useState<NotificationGroup[]>([]);
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const currentLanguage = useSelector(currentLanguageSelector);

    React.useEffect(() => {
        const fetchChildNotifications = async () => {
            setIsLoadingChildren(true);

            // If items were not displayed previously
            if (shouldShowChildren) {
                const res = await getNotifications({
                    parentId: notification.uid,
                    maxResults: MAX_CHILD_NOTIFICATION_NUMBER,
                    lang: currentLanguage,
                });
                setNotifications(res);
            }

            setIsLoadingChildren(isLoading);
        };

        fetchChildNotifications();
    }, [currentLanguage, getNotifications, notification.uid, shouldShowChildren, isLoading]);

    if (!notification.group || notification.unreadNotificationCount <= 1) {
        return null;
    }

    const displayChildren = shouldShowChildren && notifications && notifications.length > 0;
    const loadChildNotifications = async (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();

        setShouldShowChildren(!shouldShowChildren);
    };

    /** Child notification are not in the store. So we need to manually set them as read */
    const handleSetChildRead = (notificationId: string, isRead: boolean) => {
        const notif = notifications.find((notificationGroup) => notificationGroup.notification.uid === notificationId);

        if (notif && notif.notification) {
            set(notif.notification, 'isRead', isRead);
        }

        if (setNotificationRead) {
            setNotificationRead(notificationId, isRead);
        }
    };

    /** Child notification are not in the store. So we need to manually delete them */
    const handleDeleteChild = (notificationId: string, isNotificationRead: boolean) => {
        if (deleteNotification) {
            deleteNotification(notificationId, isNotificationRead);
        }

        const notifIndex = notifications.findIndex(
            (group: NotificationGroup) => group.notification?.uid === notificationId,
        );

        if (notifIndex >= 0) {
            const newNotif = [...notifications];
            newNotif.splice(notifIndex, 1);
            setNotifications(newNotif);
        }
    };

    return (
        <>
            <ListItem size={Size.tiny} className={padding('horizontal', 'big')}>
                <Button
                    emphasis={Emphasis.low}
                    rightIcon={shouldShowChildren ? mdiMenuUp : mdiMenuDown}
                    size={Size.s}
                    onClick={loadChildNotifications}
                    aria-expanded={shouldShowChildren}
                    aria-busy={isLoadingChildren ? 'true' : undefined}
                >
                    {translateKey(shouldShowChildren ? 'HIDE' : 'SHOW')}
                </Button>
            </ListItem>
            {!displayChildren &&
                isLoadingChildren &&
                [...Array(notification.unreadNotificationCount)].map((data, index) => (
                    <NotificationListItemSkeleton
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={element('skeleton', [background('dark', 'L6')])}
                    />
                ))}
            {displayChildren &&
                notifications.map(({ notification: notif }) => (
                    <NotificationListItemCommon
                        className={classnames(className, [background('dark', 'L6')])}
                        key={notif.uid}
                        notification={notif}
                        setNotificationRead={handleSetChildRead}
                        deleteNotification={handleDeleteChild}
                        closeNotificationMenu={closeNotificationMenu}
                        {...props}
                    />
                ))}
        </>
    );
};
