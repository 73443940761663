import type { VisibleMetadata } from '../../types';

export interface UseVideoMetadataVisibilityProps {
    metadata: VisibleMetadata;
}

export function useVideoMetadataVisibility({ metadata }: UseVideoMetadataVisibilityProps) {
    const author = metadata.includes('author');
    const description = metadata.includes('description');
    const duration = metadata.includes('duration');
    const publicationDate = metadata.includes('publicationDate');
    const title = metadata.includes('title');
    const views = metadata.includes('views');

    const details = [publicationDate, views].some(Boolean);

    return {
        author,
        description,
        details,
        duration,
        publicationDate,
        title,
        views,
    };
}
