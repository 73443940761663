/* eslint-disable import/no-mutable-exports */

import noop from 'lodash/noop';

let getCurrentInstanceId = noop;
let getCurrentInstanceLangs = noop;
let getInstanceById = noop;
let getInstance = noop;
let getProperty = noop;

/**
 * Blinds functions to angular `Instance` service.
 *
 * @param {Instance} instance The angular Instance service.
 */
function setInstance(instance) {
    getCurrentInstanceId = instance.getCurrentInstanceId;
    getCurrentInstanceLangs = instance.getCurrentInstanceLangs;
    getInstance = instance.getInstance;
    getInstanceById = instance.getInstanceById;
    getProperty = instance.getProperty;
}

/////////////////////////////

export { getCurrentInstanceId, getCurrentInstanceLangs, setInstance, getInstance, getInstanceById, getProperty };
