import { shape, string } from 'prop-types';

import { styles } from '../styles/types';

export const widgetType = {
    /** The component object retrieved from the backend. */
    component: shape({
        properties: shape({
            style: styles,
        }).isRequired,
        widgetType: string.isRequired,
    }),
};
