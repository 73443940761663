import { BaseApiError } from '@lumapps/base-api';
import { getFileSize } from '@lumapps/play-video-library/utils/getFileSize';
import { Video } from '@lumapps/play/api/types';

/** This util returns the current asset of a video */
export const getCurrentAsset = (video?: Pick<Video, 'assetId' | 'assets'>) => {
    return video?.assets.find((asset) => asset.id === video.assetId);
};

/** Check if a video was uploaded to China */
export const isOptimizedForChina = (video?: Pick<Video, 'assetId' | 'assets'>) => {
    return Boolean(getCurrentAsset(video)?.r13n?.china);
};

/** `true` if the user tries to upload a video he is not allowed to download */
export const isVideoNotDownloadableError = (error: unknown) => {
    return error instanceof BaseApiError && error.response?.data.code === 'PLAY_NOT_DOWNLOADABLE';
};

/** Check the file size to not exceed the maximum value */
export const isVideoSizeError = (videoFileSize?: number, maxUploadSize?: number) => {
    const fileSizeInGB = getFileSize({ unit: 'GB', fileSize: videoFileSize });

    // Let the backend decide if maxUploadSize is undefined
    return maxUploadSize && fileSizeInGB && fileSizeInGB > maxUploadSize;
};
