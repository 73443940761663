const { withApiv1Languages } = require("@lumapps/translations");

(function IIFE() {
    'use strict';

    /////////////////////////////

    function MainNavFactory($resource, Config) {
        'ngInject';

        return $resource(
            Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/menu/:methodKey',
            {},
            {
                get: {
                    isArray: false,
                    method: 'GET',
                    params: {
                        methodKey: 'get',
                    },
                    url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/menu/:methodKey',
                    interceptor: {
                        response: function (response) {
                            // Get the navigation from the response object
                            var navigation = response.resource;

                            /**
                             * The navigation service can return langs in v2 format (e.g. pt-BR). We intercept the response
                             * So we can store a copy of the response that has all the langs adapted to v1 format (e.g. pt_br)
                             */
                            return withApiv1Languages(navigation);
                        },
                    },
                },

                save: {
                    isArray: false,
                    method: 'POST',
                    params: {
                        methodKey: 'save',
                    },
                },
            },
        );
    }

    /////////////////////////////

    angular.module('Factories').factory('MainNavFactory', MainNavFactory);
})();
