/* istanbul ignore file */
export enum SHARE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30485496
     */
    SHARE_KEY = 'FRONT.COMMUNITY.POST.ACTION.SHARE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=44874823
     */
    SHARE_POSTS_TEAMS = 'FRONT.SHARE_POSTS_TEAMS.DIALOG.HEADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=44874829
     */
    SELECT_TEAM = 'FRONT.SHARE_POSTS_TEAMS.SELECT_TEAM.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=44917297
     */
    DIALOG_SHARE_BUTTON = 'FRONT.SHARE_POSTS_TEAMS.DIALOG.SHARE_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45134872
     */
    MESSAGE_PLACEHOLDER = 'FRONT.SHARE_POSTS_TEAMS.DIALOG.MESSAGE.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49092272
     */
    SHARE_BUTTON = 'FRONT.SHARE.SHARE_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49092273
     */
    SHARE_WITH_COMMUNITY = 'FRONT.SHARE.SHARE_WITH_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49092295
     */
    SHARE_VIA_TEAMS = 'FRONT.SHARE.SHARE_VIA_TEAMS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=49092297
     */
    COPY_LINK = 'FRONT.SHARE.COPY_LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68817054
     */
    SHARE_VIA_SLACK = 'FRONT.SHARE.SHARE_VIA_SLACK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68866548
     */
    SELECT_WORKSPACE = 'FRONT.SHARE_VIA_SLACK.DIALOG.SELECT_WORKSPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68869473
     */
    CHANGE_WORKSPACE = 'FRONT.SHARE_VIA_SLACK.DIALOG.CHANGE_WORKSPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68869743
     */
    CHOOSE_WORKSPACE = 'FRONT.SHARE_VIA_SLACK.DIALOG.CHOOSE_WORKSPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68975299
     */
    SHARE_WITH = 'FRONT.SHARE.SHARE_WITH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=71112522
     */
    NO_WORKSPACE = 'FRONT.SHARE_VIA_SLACK.DIALOG.WORKSPACES.ERROR.NO_WORKSPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=102766589
     */
    SHARE_VIA = 'FRONT.SHARE.SHARE_VIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32307181
     */
    POST_SHARED_SUCCESS = 'FRONT.COMMUNITY.POST.SHARED.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68866381
     */
    SHARE_VIA_SLACK_NOTIFICATION_SUCCESS = 'FRONT.SHARE_VIA_SLACK.NOTIFICATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45134920
     */
    SHARE_POSTS_TEAMS_NOTIFICATION_SUCCESS = 'FRONT.SHARE_POSTS_TEAMS.NOTIFICATION_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=257206106
     */
    TWITTER_MENTION_LIMITATION = 'SHARE.TWITTER_MENTION_LIMITATION',
}

export const getShareViaKey = (network: string) => `FRONT.SOCIAL_ADVOCACY.SHARE_VIA.${network.toUpperCase()}`;
