import React, { isValidElement, ReactElement, ReactNode } from 'react';

/**
 * Get the given children first child and clone it with the given
 * overridden properties.
 *
 * We do this to be able to display the resources as needed in the feed.
 */
export const useResourcePropsOverride = (children: ReactNode, override: (props: any) => any) => {
    const overriddenChildren = React.useMemo(() => {
        const childrenArray = React.Children.toArray(children);
        const child = childrenArray[0];

        if (isValidElement(child)) {
            const { props } = child;
            return React.cloneElement(child as ReactElement, override(props));
        }

        return children;
    }, [children, override]);

    return overriddenChildren;
};
