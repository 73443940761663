/* istanbul ignore file */
/** tested with integration tests */
/* eslint-disable no-param-reassign */
import React from 'react';

import { ENVS } from '@lumapps/constants';

/**
 * Depending on the environment, this hook enables or disables the code added to the page
 * via instance head.
 * - Development: since the page will be rendered by default without these customizations,
 * once the mode is enabled it will add the code to the instance head
 * - Production: by default, this customization is already present on the page, so if the mode
 * is disabled, we retrieve the styles and scripts added to the page (previously tagged by the backend)
 * and disable them.
 */
const useInstanceHead = ({ isEnabled = true, env = 'development', prefix = 'instance-head' }) => {
    React.useLayoutEffect(() => {
        if (isEnabled) {
            if (env === ENVS.DEVELOPMENT) {
                const instanceHead = window.INSTANCE_HEAD;
                const head = document.head || document.getElementsByTagName('head')[0];

                head.insertAdjacentHTML('beforeend', instanceHead || '');
            }
        } else if (env === ENVS.PRODUCTION) {
            document.querySelectorAll(`${prefix}-style`).forEach((headStyle) => {
                if (headStyle) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    headStyle.disabled = true;
                }
            });

            document.querySelectorAll(`${prefix}-script`).forEach((headScript) => {
                if (headScript) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    headScript.disabled = true;
                }
            });

            document.querySelectorAll(`${prefix}-link`).forEach((headStyle) => {
                if (headStyle) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    headStyle.disabled = true;
                }
            });
        }
    });
};

export { useInstanceHead };
