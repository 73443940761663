import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, Typography } from '@lumapps/lumx/react';

import { VideoPublisher, VideoPublisherProps } from '../../VideoPublisher';
import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-author` as const;

interface AuthorProps extends Omit<VideoPublisherProps, 'user' | 'id' | 'theme'> {
    /** Whether to display the author using the user block component or not */
    asUserBlock?: boolean;
}

/**
 * Displays the author of a video
 */
export const Author = ({ asUserBlock, ...videoPublisherProps }: AuthorProps) => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, theme, video } = useBlockVideoContext();

    if (!canDisplay('AUTHOR')) {
        return null;
    }

    if (isLoading) {
        return (
            <SkeletonTypography className={block('skeleton')} theme={theme} typography={Typography.body1} width="20%" />
        );
    }

    if (!video?.createdByDetails) {
        return null;
    }

    if (asUserBlock && video?.createdById) {
        return (
            <VideoPublisher
                {...videoPublisherProps}
                className={block()}
                id={video.createdById}
                theme={theme}
                user={video.createdByDetails}
            />
        );
    }

    return <Text as="span">{video.createdByDetails.fullName}</Text>;
};
