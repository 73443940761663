import { WIDGET_COMMUNITY_LIST_TYPE } from '@lumapps/widget-community-list/constants';
import { WIDGET_CONTENT_LIST_TYPE } from '@lumapps/widget-content-list/constants';
import { WIDGET_DIRECTORY_ENTRIES_TYPE } from '@lumapps/widget-directory-entries/constants';

import { LegacyFilterId, NGIFilterId } from './types';

export const CLASSNAME = 'widget-content-filter';

export const WIDGET_CONTENT_FILTER_FORM_ID = 'content-filter-id';

export const WIDGET_CONTENT_FILTER_TYPE = 'content-filter';

export const CONTENT_FILTER_SUPPORTED_WIDGETS = [
    WIDGET_CONTENT_LIST_TYPE,
    WIDGET_DIRECTORY_ENTRIES_TYPE,
    WIDGET_COMMUNITY_LIST_TYPE,
];

export const LEGACY_TO_NGI_FILTERS_MAP = {
    [LegacyFilterId.query]: NGIFilterId.searchQuery,
    [LegacyFilterId.metadata]: NGIFilterId.metadata,
    [LegacyFilterId.tags]: NGIFilterId.tags,
    [LegacyFilterId.featureFeeds]: NGIFilterId.onlyHighlighted,
    [LegacyFilterId.directoryTags]: NGIFilterId.tags,
    [LegacyFilterId.dates]: NGIFilterId.publicationDates,
    [LegacyFilterId.author]: NGIFilterId.author,
    [LegacyFilterId.instance]: NGIFilterId.site,
};

export const FILTERS_WITH_POTENTIAL_DEFAULT_VALUES = [
    NGIFilterId.metadata,
    NGIFilterId.tags,
    NGIFilterId.onlyHighlighted,
    NGIFilterId.author,
    NGIFilterId.site,
];

export const FILTERS_THAT_SHOULD_NOT_BE_CLEARED = ['tab'];
