import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { ANGULAR_SHOW_LINEAR_LOADING_EVENT } from '@lumapps/router/constants';
import { resetPageFocusAndScroll } from '@lumapps/router/utils/resetPageFocusAndScroll';

/////////////////////////////

function LayoutService(
    $anchorScroll,
    $compile,
    $interval,
    $timeout,
    $location,
    $rootScope,
    $state,
    $window,
    Community,
    Config,
    Content,
    Instance,
    Translation,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay between two check for the anchor link.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _ANCHOR_INTERVAL = 100;

    /**
     * The delay for debounced functions.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEBOUNCE_DELAY = 250;

    /**
     * The minimum screen width to consider we are on a desktop computer.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DESKTOP_WIDTH = 1024;

    /**
     * The maximum number of time we wait for the anchor link to be ready.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_ANCHOR_INTERVAL_REPEAT = 100;

    /**
     * The maximum screen width to consider we are on a palm device.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _PALM_WIDTH = 480;

    /**
     * The window element.
     *
     * @type {jQElement}
     * @constant
     * @readonly
     */
    const _WINDOW = angular.element($window);

    /**
     * An element containing a progress indicator.
     *
     * @type {jQElement}
     */
    let _appProgress;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The last computed breakpoint.
     * A breakpoint represent the width of the window.
     *
     * Possible values are:
     *     - palm: for width under 480px (included);
     *     - lap: for width between 481px and 1023px (included);
     *     - desk: for width over 1024px (included);
     *
     * @type {string}
     */
    service.breakpoint = undefined;

    /**
     * Indicate the latest state we came from.
     *
     * @type {Object}
     */
    service.fromState = {};

    /**
     * Indicates if the customization of a content is allowed or not.
     *
     * @type {boolean}
     */
    service.isCustomizationLocked = true;

    /**
     * Indicates if the linear loader is shown or not.
     *
     * @type {boolean}
     */
    service.isLinearLoaderShown = false;

    /**
     * Indicates if the mobile sidebar is opened or not.
     *
     * @type {boolean}
     */
    service.isMobileSidebarOpened = false;

    /**
     * Indicates if we are currently scrolling the window.
     *
     * @type {boolean}
     */
    service.isScrolling = false;

    /**
     * Indicates if the sidebar is opened or not.
     *
     * @type {boolean}
     */
    service.isSidebarOpened = false;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Computes the breakpoint according to the width of the window.
     * A breakpoint represent the width of the window.
     *
     * Possible values are:
     *     - palm: for width under 480px (included);
     *     - lap: for width between 481px and 1023px (included);
     *     - desk: for width over 1024px (included);
     *
     * @return {string} The breakpoint.
     */
    function _computeBreakpoint() {
        const width = _WINDOW.outerWidth();

        if (width <= _PALM_WIDTH) {
            return 'palm';
        }

        if (width > _PALM_WIDTH && width <= _DESKTOP_WIDTH - 1) {
            return 'lap';
        }

        return 'desk';
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close the mobile sidebar.
     */
    function closeMobileSidebar() {
        service.isMobileSidebarOpened = false;
    }

    /**
     * Close the sidebar.
     */
    function closeSidebar() {
        service.isSidebarOpened = false;
    }

    /**
     * Compute the status of "isScrolling".
     * We are "scrolling" if the scrolling element is not at the top.
     *
     * This function can be called either on load/scroll of the page or at each state change.
     */
    function computeIsScrolling() {
        // eslint-disable-next-line angular/document-service
        if (_WINDOW.scrollTop() > 0) {
            if (!service.isScrolling) {
                service.isScrolling = true;
            }
        } else if (service.isScrolling) {
            service.isScrolling = false;
        }
    }

    /**
     * Get the title of the page.
     * The one that should be place in the '<head><title>' tag.
     *
     * @return {string} The title of the page.
     */
    function getHeadTitle() {
        const headTitle = Instance.getInstance().name || '';

        const currentContent = Content.getCurrent();
        if (angular.isUndefinedOrEmpty(currentContent)) {
            return headTitle;
        }

        // For community posts, try to use the community name as they don't really have a title.
        const currentCommunity = Community.getCurrent();
        if (
            angular.isDefinedAndFilled(currentCommunity) &&
            currentContent.type === get(Config, 'AVAILABLE_CONTENT_TYPES.POST') &&
            Translation.hasTranslations(currentCommunity.title)
        ) {
            return `${Translation.translate(currentCommunity.title)} - ${headTitle}`;
        }

        if (currentContent.type === 'template' && Translation.hasTranslations(get(currentContent.template, 'name'))) {
            return `${Translation.translate(currentContent.template.name)} - ${headTitle}`;
        }

        if (Translation.hasTranslations(currentContent.title)) {
            return `${Translation.translate(currentContent.title)} - ${headTitle}`;
        }

        return headTitle;
    }

    /**
     * Open the mobile sidebar.
     */
    function openMobileSidebar() {
        service.isMobileSidebarOpened = true;
    }

    /**
     * Open the sidebar.
     */
    function openSidebar() {
        service.isSidebarOpened = true;
    }

    /**
     * Scrolls the page to the anchor link position.
     */
    function scrollToAnchor() {
        const hashValue = $location.hash();

        if (angular.isUndefinedOrEmpty(hashValue)) {
            return;
        }

        /**
         * We will check if the element we want is in the page every XXXms because it may not be in the DOM
         * the first time around. Also, only check for a few seconds as after that we can assume the element
         * just doesn't exist in the DOM at all.
         */
        service.anchorLinkInterval = $interval(
            function delayedScrollToAnchorLink(iterationCount) {
                const idElement = angular.element(`#${hashValue}`);
                const foundElement = angular.isDefinedAndFilled(idElement);

                if (foundElement) {
                    $anchorScroll(hashValue);
                }

                if (
                    (iterationCount === _MAX_ANCHOR_INTERVAL_REPEAT - 1 || foundElement) &&
                    angular.isDefinedAndFilled(service.anchorLinkInterval)
                ) {
                    $interval.cancel(service.anchorLinkInterval);
                    delete service.anchorLinkInterval;
                    $anchorScroll.yOffset = undefined;
                }
            },
            _ANCHOR_INTERVAL,
            _MAX_ANCHOR_INTERVAL_REPEAT,
        );
    }

    /**
     * When a state begin to change.
     *
     * @param {Event}  evt       The state change start event.
     * @param {Object} toState   The state we are going to.
     * @param {Object} toParams  The parameters of the state we are going to.
     * @param {Object} fromState The state we are coming from.
     */
    function stateChangeStart(evt, toState, toParams, fromState) {
        service.fromState = fromState;

        if (fromState.name) {
            service.isLinearLoaderShown = true;
        }
    }

    /**
     * When a state has successfully changed.
     *
     * @param {Event}  evt       The state change success event.
     * @param {Object} toState   The state we have been going to.
     * @param {Object} toParams  The parameters of the state we have been going to.
     * @param {Object} fromState The state we came from.
     */
    function stateChangeSuccess(evt, toState, toParams, fromState) {
        if (fromState.name) {
            service.isLinearLoaderShown = false;
        }

        service.isSidebarOpened = get($state.current, 'data.designer', false);

        service.updateCustomizationLockState(true);

        resetPageFocusAndScroll();

        service.scrollToAnchor();
    }

    /**
     * Toggle the mobile sidebar.
     */
    function toggleMobileSidebar() {
        service.isMobileSidebarOpened = !service.isMobileSidebarOpened;
    }

    /**
     * Toggle the sidebar.
     */
    function toggleSidebar() {
        service.isSidebarOpened = !service.isSidebarOpened;
    }

    /**
     * Change the customization status of the content.
     *
     * @param {boolean} [state=<Toggle status>] The new customization status.
     */
    function updateCustomizationLockState(state) {
        service.isCustomizationLocked = angular.isDefined(state) ? state : !service.isCustomizationLocked;
    }

    /////////////////////////////

    service.closeMobileSidebar = closeMobileSidebar;
    service.closeSidebar = closeSidebar;
    service.computeIsScrolling = computeIsScrolling;
    service.getHeadTitle = getHeadTitle;
    service.openMobileSidebar = openMobileSidebar;
    service.openSidebar = openSidebar;
    service.scrollToAnchor = scrollToAnchor;
    service.stateChangeStart = stateChangeStart;
    service.stateChangeSuccess = stateChangeSuccess;
    service.toggleMobileSidebar = toggleMobileSidebar;
    service.toggleSidebar = toggleSidebar;
    service.updateCustomizationLockState = updateCustomizationLockState;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Each time the window is loaded or scrolled, toggle an flag that indicates that we are scrolling.
     */
    _WINDOW.bind('scroll load', function onWindowScrollOrLoad() {
        // Do not reduce this to a one liner or it will not work!
        $rootScope.$apply(function applyIsScrolling() {
            service.computeIsScrolling();
        });
    });

    /**
     * Each time the window is resized, recompute the breakpoint.
     * If the breakpoint changes, send an event to the application.
     */
    _WINDOW.bind(
        'resize',
        debounce(function onWindowResize() {
            const newBreakPoint = _computeBreakpoint();

            if (newBreakPoint !== service.breakpoint) {
                $rootScope.$apply(function applyBreakpoint() {
                    const previousBreakpoint = service.breakpoint;
                    service.breakpoint = newBreakPoint;

                    $rootScope.$broadcast('layout-breakpoint', service.breakpoint, previousBreakpoint);
                });
            }
        }, _DEBOUNCE_DELAY),
    );

    /**
     * Event when a component asks to show the loading event.
     */
    $rootScope.$on(ANGULAR_SHOW_LINEAR_LOADING_EVENT, function onStateChangeStart(evt, shown = true) {
        /**
         * Angular didn't seem to always update when the broadcast was done from a
         * react component.
         * Using $timeout fixed the issue.
         */
        $timeout(() => {
            service.isLinearLoaderShown = Boolean(shown);
        }, 0);
    });

    /////////////////////////////

    /**
     * Initialize the service.
     */
    function init() {
        service.breakpoint = _computeBreakpoint();

        // Create a loader element to be added/removed from the main element of the DOM.
        _appProgress = angular.element('<div></div>', {
            class: 'app-progress',
        });

        // Create circular loader that will be append to the loader element.
        const circularProgress = $compile('<lx-progress lx-variant="circular"></lx-progress>')($rootScope.$new());

        // Add the circular loader to the loader element.
        _appProgress.append(circularProgress);
    }

    init();
}

/////////////////////////////

angular.module('Services').service('Layout', LayoutService);

/////////////////////////////

export { LayoutService };
