import React, { useCallback } from 'react';

import { classnames } from '@lumapps/classnames';
import { ColorPalette, Link as LumxLink, Theme, Tooltip } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import { doesUrlContainJavascript } from '@lumapps/utils/string/doesUrlContainJavascript';
import type { ElementRender } from '@lumapps/wrex/types';

import { LINK } from '../../../constants';
import { WREX_LINK } from '../../../keys';
import type { LinkElement } from '../../../types';

import './index.scss';

const LINK_REL = 'noopener noreferrer';

export const Link: ElementRender<LinkElement, HTMLAnchorElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, className, elementRef, element, onClick, ...forwardedProps } = props;
    const theme = useThemeContext();
    const { href } = element || {};
    const { translateKey } = useTranslate();
    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
        (evt) => {
            // eslint-disable-next-line no-unused-expressions
            onClick?.(evt);
            if (!evt.defaultPrevented && (evt.ctrlKey || evt.metaKey)) {
                evt.preventDefault();
                window.open(href, '_blank', LINK_REL);
            }
        },
        [onClick, href],
    );

    const isDisabled = Boolean(href && doesUrlContainJavascript(href));

    const linkProps = {
        ...forwardedProps,
        className: classnames(className, 'wrex-content-link', {
            'lumx-link--color-light': theme === Theme.dark,
        }),
        href,
        onClick: handleClick,
        ref: elementRef,
        target: '_blank',
        isDisabled,
        rel: LINK_REL,
        color: theme === Theme.dark ? ColorPalette.light : ColorPalette.primary,
    };

    return isDisabled ? (
        <Tooltip label={translateKey(WREX_LINK.UNAVAILABLE_LINK)} placement="top">
            <LumxLink {...linkProps}>{children}</LumxLink>
        </Tooltip>
    ) : (
        <LumxLink {...linkProps}>{children}</LumxLink>
    );
};
Link.displayName = LINK;
