import React from 'react';

import { classnames } from '@lumapps/classnames';
import { ALIGNMENTS, ALIGNMENT_CLASSES } from '@lumapps/wrex/constants';
import type { ElementRender } from '@lumapps/wrex/types';

import { SUBTITLE } from '../../../constants';
import type { SubtitleElement } from '../../../types';

import './index.scss';

export const Subtitle: ElementRender<SubtitleElement, HTMLHeadingElement> = (props) => {
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const alignment = element?.alignment ? element.alignment : ALIGNMENTS.start;
    return (
        // eslint-disable-next-line react/forbid-elements
        <h3
            {...forwardedProps}
            className={classnames(className, 'wrex-content-subtitle', ALIGNMENT_CLASSES[alignment])}
            ref={elementRef}
        >
            {children}
        </h3>
    );
};
Subtitle.displayName = SUBTITLE;
