import React, { useEffect } from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { EmptyState } from '@lumapps/lumx-states/components/EmptyState';
import { mdiClose, mdiPlayBoxMultiple } from '@lumapps/lumx/icons';
import {
    Alignment,
    AspectRatio,
    Emphasis,
    FlexBox,
    Heading,
    IconButton,
    Kind,
    Message,
    Orientation,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    Typography,
} from '@lumapps/lumx/react';
import { PlaylistPickerDialog } from '@lumapps/play-pickers/components/PlaylistPickerDialog';
import { PLAY_PICKERS } from '@lumapps/play-pickers/keys';
import { BlockPlaylist, useFetchPlaylist } from '@lumapps/play-playlists';
import { Playlist } from '@lumapps/play-playlists/types';
import { usePlayDataAttributes } from '@lumapps/play/hooks/usePlayDataAttributes';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { WIDGET_PLAYLIST } from '../../../keys';
import { WidgetPlaylistProperties } from '../../../types';

import './index.scss';

type PlaylistProperty = Pick<WidgetPlaylistProperties, 'playlist'>;

export interface PlaylistUploaderProps {
    properties: PlaylistProperty;
    hasError?: boolean;
    onChange: (properties: PlaylistProperty) => void;
    onPlaylistRemove: () => void;
}

const CLASSNAME = 'playlist-uploader';
const fetchKey = CLASSNAME;

/**
 *
 * @param PlaylistUploaderProps
 * @returns PlaylistUploader
 */
export const PlaylistUploader = ({
    properties,
    hasError = false,
    onChange,
    onPlaylistRemove,
}: PlaylistUploaderProps) => {
    const { translateKey } = useTranslate();
    const { get } = usePlayDataAttributes();
    const [isPickerOpen, togglePicker, closePicker] = useBooleanState(false);
    const { block, element } = useClassnames(CLASSNAME);
    const { fetchPlaylist, playlist, status } = useFetchPlaylist({ fetchKey });
    const playlistId = properties?.playlist.id;

    useEffect(() => {
        if (playlistId) {
            fetchPlaylist({
                fields: ['title', 'thumbnail'],
                id: playlistId,
            });
        }
    }, [fetchPlaylist, playlistId]);

    const handlePickPlaylist = (playlists: Playlist[]) => {
        onChange({ ...properties, playlist: { id: playlists[0].id } });
        closePicker();
    };

    // Needed to display or not the PlaylistBlock
    const hasPlaylist = properties.playlist.id;

    return (
        <div className={block()}>
            <FlexBox className={element('title')} orientation={Orientation.horizontal}>
                <Heading as="h4" typography="subtitle1" className={element('subtitle')}>
                    {translateKey(WIDGET_PLAYLIST.SUBTITLE)}
                </Heading>
            </FlexBox>
            {status === BaseLoadingStatus.loading ? (
                <SkeletonRectangle aspectRatio={AspectRatio.wide} variant={SkeletonRectangleVariant.rounded} />
            ) : (
                <>
                    {hasError && (
                        <Message className={element('error-message')} kind={Kind.error} hasBackground>
                            {translateKey(WIDGET_PLAYLIST.PLAYLIST_NO_LONGER_AVAILABLE)}
                        </Message>
                    )}
                    {hasPlaylist ? (
                        <FlexBox
                            className={element('selected-playlist')}
                            orientation={Orientation.horizontal}
                            hAlign={Alignment.center}
                        >
                            {playlist && (
                                <BlockPlaylist
                                    playlist={playlist}
                                    orientation={Orientation.horizontal}
                                    alignment={Alignment.center}
                                >
                                    <BlockPlaylist.Figure size={Size.l}>
                                        <BlockPlaylist.Thumbnail withBadge />
                                    </BlockPlaylist.Figure>

                                    <BlockPlaylist.Content>
                                        <BlockPlaylist.Title as="span" truncate typography={Typography.body1} />
                                    </BlockPlaylist.Content>
                                </BlockPlaylist>
                            )}
                            <IconButton
                                label={translateKey(GLOBAL.REMOVE)}
                                className={element('remove-icon')}
                                size={Size.m}
                                emphasis={Emphasis.low}
                                icon={mdiClose}
                                onClick={onPlaylistRemove}
                                {...get({
                                    element: 'widget-remove-playlist-button',
                                    action: 'remove-playlist',
                                })}
                            />
                        </FlexBox>
                    ) : (
                        // This component is used as an uploader, we just need his style to fit the Figma
                        <EmptyState
                            wrapperProps={{
                                className: classnames(CLASSNAME, element('input')),
                            }}
                            iconProps={{
                                icon: mdiPlayBoxMultiple,
                                size: Size.m,
                            }}
                            message={translateKey(WIDGET_PLAYLIST.NO_PLAYLIST_SELECTED)}
                            buttonProps={{
                                emphasis: Emphasis.medium,
                                onClick: togglePicker,
                                children: translateKey(PLAY_PICKERS.SELECT_PLAYLIST),
                                ...get({
                                    element: 'widget-select-playlist-button',
                                    action: 'create',
                                }),
                            }}
                        />
                    )}
                </>
            )}

            <PlaylistPickerDialog
                isOpen={isPickerOpen}
                onSelectionConfirm={handlePickPlaylist}
                onSelectionCancel={closePicker}
            />
        </div>
    );
};
