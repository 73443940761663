import React from 'react';

import { useLocation } from './useLocation';

export type UseHashApi = [
    // Current hash
    string,
    // Set new hash
    (newHash: string) => void,
];
/**
 * Hook to manage current url hash.
 */
export const useHash = (): UseHashApi => {
    const { hash: currentHash } = useLocation();
    const [hash, setHash] = React.useState<string>(currentHash);

    React.useEffect(() => {
        // Listen to hash change and set hash if different from stored.
        const hashListener = (event: HashChangeEvent) => {
            const eventWindow = event?.currentTarget as Window;
            if (hash !== eventWindow.location.hash) {
                setHash(eventWindow.location.hash);
            }
        };
        window.addEventListener('hashchange', hashListener);
        return () => {
            window.removeEventListener('hashchange', hashListener);
        };
    }, [hash]);
    /**
     * Update url hash and inner state.
     * Will automatically add the # to the stored hash.
     */
    const changeHash = React.useCallback((newHash: string): void => {
        window.location.hash = newHash;
        setHash(`#${newHash}`);
    }, []);

    return [hash || currentHash, changeHash];
};
