import { isExternalUrl } from '@lumapps/router/utils';

export const isExternalResultUrl = (url: string) => {
    if (!isExternalUrl(url)) {
        return false;
    }

    const base = new URL(url).origin;
    const currentBase = window.location.origin;

    return base !== currentBase;
};
