/* istanbul ignore file */
import { isMobileAllowed } from '@lumapps/mobile/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { MobileCustomizationSideNavigation } from './MobileCustomizationSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isMobileAllowed(state);

    return {
        isAllowed,
    };
};

const ConnectedMobileCustomizationSettingsSideNavigation = connect(mapStateToProps)(MobileCustomizationSideNavigation);

export { ConnectedMobileCustomizationSettingsSideNavigation as MobileCustomizationSideNavigation };
