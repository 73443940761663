import { DITA, FeaturedImageMedia } from '../types';
import { formatFocalPoint } from './formatFocalPoint';
import { isFeaturedImage, isProlog } from './test';

export const featuredImageFromDita = (dita: DITA): FeaturedImageMedia | undefined => {
    const prologNode = dita?.children?.find(isProlog);
    const featuredImageNode = prologNode?.children?.find(isFeaturedImage);
    const altNode = featuredImageNode?.children?.find((el) => el.attributes?.name === 'alt');
    const focalPointNode = featuredImageNode?.children?.find((el) => el.attributes?.name === 'focalPoint');
    const { href, keyref } = featuredImageNode?.attributes || {};
    const focalPoint = formatFocalPoint(
        focalPointNode?.children?.find((el) => el.attributes?.name === 'focalPoint:x')?.attributes?.value,
        focalPointNode?.children?.find((el) => el.attributes?.name === 'focalPoint:y')?.attributes?.value,
    );

    return href
        ? {
              id: keyref,
              url: href,
              focalPoint,
              alt: altNode?.attributes?.value,
          }
        : undefined;
};
