import { membersOnlyContribution } from '@lumapps/lumx/custom-icons';
import { mdiEarth, mdiLock, mdiEyeOff } from '@lumapps/lumx/icons';

export const DEFAULT_VIEW = 'posts';

export const VISIBILITY_ICONS: Record<string, string> = {
    open: mdiEarth,
    restricted: mdiEyeOff,
    secret: mdiEyeOff,
    'read only': membersOnlyContribution,
    read_only: membersOnlyContribution,
    'description only': mdiLock,
    description_only: mdiLock,
};
