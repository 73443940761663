import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { FormSteps } from '../../types';

export type CurrentStepState = FormSteps;

const initialState = FormSteps.informationForm;

const { actions, reducer } = createSlice({
    domain: 'ProgramForm/currentStep',
    initialState,
    reducers: {
        setCurrentStep: (state: FormSteps, action: PayloadAction<FormSteps>) => {
            return action.payload;
        },
    },
});

export { actions, reducer, initialState };
