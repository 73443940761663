import React from 'react';

import { classnames } from '@lumapps/classnames';
import { GenericProps } from '@lumapps/lumx/react';

import { BASE_CLASSNAME as CLASSNAME } from '../constants';

export interface MenuProps extends GenericProps {
    /** HTML id */
    id?: string;
    /** Menu content */
    children: React.ReactNode;
    /** The width the menu must take. */
    width?: React.CSSProperties['width'];
}

/**
 * Base menu list (ARIA `menu`)
 * Use Menu.Trigger & Menu.TriggerButton to attach it to a trigger button in a popup.
 *
 * @family Menus
 */
export const Menu = React.forwardRef((props: MenuProps, ref: React.Ref<HTMLElement>) => {
    const { id, className, children, role = 'menu', ...forwardedProps } = props;

    return (
        <ul
            ref={ref as React.Ref<HTMLUListElement>}
            role={role}
            className={classnames(className, CLASSNAME, 'lumx-list lumx-list--item-padding-big')}
            id={id}
            aria-orientation="vertical"
            {...forwardedProps}
        >
            {children}
        </ul>
    );
});
Menu.displayName = 'Menu';
