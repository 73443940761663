import includes from 'lodash/includes';

/////////////////////////////

function DriveService($window, DriveFilesFactory, LumsitesBaseService) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(DriveFilesFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if a drive search is in progress or not.
     *
     * @type {boolean}
     */
    service.isSearchInProgress = false;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Dedupe list elements by Google Drive id (because the backend gets documents both from the user drive and
     * the global one).
     *
     * @param  {Array} list A list of google drive elements.
     * @return {Array} A list of google drive elements.
     */
    function _dedupeItems(list) {
        const filtered = [];
        const ids = [];

        if (angular.isUndefinedOrEmpty(list)) {
            return filtered;
        }

        angular.forEach(list, (item) => {
            if (!includes(ids, item.id)) {
                filtered.push(item);
                ids.push(item.id);
            }
        });

        return filtered;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * List items in Google Drive.
     *
     * @param {Array}    params  The parameters of the list call.
     * @param {Function} [cb]    A function to execute when the list succeeds.
     * @param {Function} [errCb] A function to execute when the list fails.
     * @param {string}   listKey The identifier of the list.
     */
    function filterize(params, cb, errCb, listKey) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        const key = service._getListKey(listKey);

        service._services[key]._filterize(
            params,
            (response) => {
                cb(response);

                service._services[listKey]._list = _dedupeItems(service._services[listKey]._list);
            },
            errCb,
        );
    }

    /**
     * Get the next page of drive search results.
     *
     * @param  {Function} [cb]         A function to execute when the next page call succeeds.
     * @param  {Function} [errCb]      A function to execute when the next page call fails.
     * @param  {string}   listKey      The identifier of the list of results.
     * @param  {string}   endpointName The name of the endpoint we want to use.
     * @return {Promise}  The promise of the call to the server.
     */
    function nextSearchPage(cb = angular.noop, errCb = angular.noop, listKey, endpointName) {
        service.isSearchInProgress = true;

        return this.nextPage(
            () => {
                service.isSearchInProgress = false;
                cb();
            },
            () => {
                service.isSearchInProgress = false;
                errCb();
            },
            listKey,
            endpointName,
        );
    }

    /**
     * Open the selected file in drive.
     *
     * @param {Object} file The file or folder to open.
     */
    function openFile({ file }) {
        if (angular.isUndefinedOrEmpty(file)) {
            return;
        }

        // FIXME: ugly patch while Google Drive returns a link that does not work.
        if (includes(file.link, 'http://drive.google.com/views/app.html')) {
            $window.open(`https://drive.google.com/open?id=${file.id}`, '_blank');
        } else {
            $window.open(file.link, '_blank');
        }
    }

    /**
     * Execute a search query on drive.
     *
     * @param {Object}   params    The parameters of the search.
     * @param {Function} [cb]      The function to execute when we retrieved the search results.
     * @param {Function} [errCb]   The function to execute if the search is in error.
     * @param {string}   [listKey] The identifier of the list of results.
     */
    function search(params, cb, errCb, listKey = 'default') {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        service.isSearchInProgress = true;

        DriveFilesFactory.search(
            params,
            ({ cursor, items = [], more } = {}) => {
                service.isSearchInProgress = false;
                // Somehow the api sometimes returns duplicate documents.
                const uniqueItems = _dedupeItems(items);

                if (!service._services[listKey]) {
                    service._services[listKey] = {};
                }

                // Update current list but keep the existing reference.
                service._services[listKey]._cursor = cursor;
                service._services[listKey]._list = uniqueItems;
                service._services[listKey]._params = params;
                service._services[listKey]._more = more;

                cb(uniqueItems);
            },
            (response) => {
                service.isSearchInProgress = false;
                errCb(response);
            },
            listKey,
        );
    }

    /////////////////////////////

    service.filterize = filterize;
    service.nextSearchPage = nextSearchPage;
    service.openFile = openFile;
    service.search = search;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Drive', DriveService);

/////////////////////////////

export { DriveService };
