import { get } from '@lumapps/constants';
import { UserAccountType } from '@lumapps/user/types';

import { MediaSource } from '../types';

// Check that Google Drive/OneDrive medias are allowed in the given context (allowedProviders + user provider + Full layout page)
export const isDriveAllowed = (
    allowedProviders: MediaSource[],
    accountType: UserAccountType | undefined,
    isPickerV2?: boolean,
) => {
    const config = get();

    return (
        allowedProviders.includes(MediaSource.GOOGLE_DRIVE || MediaSource.MICROSOFT) &&
        (accountType === UserAccountType.GOOGLE || accountType === UserAccountType.MICROSOFT) &&
        (isPickerV2 || config.isLegacyContext)
    );
};
