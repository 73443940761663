import React, { useEffect } from 'react';

import { get as getConstants } from '@lumapps/constants';
import { useResponsive } from '@lumapps/responsive';
import { useWindowScroll } from '@lumapps/utils/hooks/useWindowScroll';

import { NAVIGATION_INHERITANCE_BODY_CLASSNAME, STICKY_DISABLED_BODY_CLASSNAME } from '../constants';

const constants = getConstants();

export interface UseChildNavigationProps {
    /** whether the main nav inheritance feature is enabled */
    isMainNavInheritanceEnabled?: boolean;
    /** current instance id */
    instanceId: string;
    /** current parent instance id */
    parentInstanceId?: string;
    /** whether sticky header is enabled or not */
    isStickyEnabled?: boolean;
}

type StickyNavState = {
    goingUp: boolean;
    isChildNavSticky: boolean;
};

const stickyNavDefaultState: StickyNavState = {
    goingUp: true,
    isChildNavSticky: false,
};

/**
 * Legacy and the new application have a different markup, and the layout of the page is entirely different. in order to apply
 * specific css rules to each scenario, we use several classes in order to determine in which context we are. If we are in the
 * new application, we will use the NAVIGATION_INHERITANCE_BODY_CLASSNAME variable to move the entire body up or down in order
 * to compensate for the child header being sticky. The same thing applies for the else if on the next lines, it is just the
 * reversed scenario
 */
const updateBodyClasses = (isChildNavSticky: boolean) => {
    if (isChildNavSticky) {
        if (!constants.isLegacyContext) {
            document.body.classList.remove(NAVIGATION_INHERITANCE_BODY_CLASSNAME);
        } else {
            document.body.classList.add(`${NAVIGATION_INHERITANCE_BODY_CLASSNAME}--child-sticky`);
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if (!constants.isLegacyContext) {
            document.body.classList.add(NAVIGATION_INHERITANCE_BODY_CLASSNAME);
        } else {
            document.body.classList.remove(`${NAVIGATION_INHERITANCE_BODY_CLASSNAME}--child-sticky`);
        }
    }
};

const calculateStickyNavigation = ({
    headerRef,
    navRef,
    parentHeaderRef,
    shouldShowChildNavigation,
    setIsChildNavSticky,
    scrollTop,
}: {
    headerRef: React.RefObject<HTMLDivElement>;
    navRef: React.RefObject<HTMLDivElement>;
    parentHeaderRef: React.RefObject<HTMLDivElement>;
    shouldShowChildNavigation: boolean;
    scrollTop: number;
    setIsChildNavSticky: (value: React.SetStateAction<StickyNavState>) => void;
}) => {
    if (
        shouldShowChildNavigation &&
        headerRef &&
        headerRef.current &&
        navRef &&
        navRef.current &&
        parentHeaderRef &&
        parentHeaderRef.current
    ) {
        /**
         * If we are at the top, we need to reset the state to its default value and update the body
         */
        if (scrollTop === 0) {
            setIsChildNavSticky(stickyNavDefaultState);
            updateBodyClasses(false);
        } else {
            /**
             * The logic here is separated into 2 steps:
             * - If the user is scrolling down the page, we need to set the child navigation to sticky once that
             * navigation has reached the top bar of the page. So if the child navigation is behind the header (meaning it has just passed or
             * it has already passed it), we flag the navigation to be sticky.
             * - If the user is scrolling up, the moment the entire header passes both the sticky navigation and the top bar, it means that we need to
             * unflag the navigation as sticky and allow the navigation to scroll with the entire header.
             */
            const { height } = headerRef.current.getBoundingClientRect();
            const { y, height: navHeight } = navRef.current.getBoundingClientRect();
            const { height: parentHeight, top: parentTop } = parentHeaderRef.current.getBoundingClientRect();

            /** If the navigation is behind the header, it means that the y position is smaller than the height of the header */
            const isChildNavigationBehindHeader = y <= height;
            /**
             * We can check that the header is above the sticky navigation if the height of the header and their position are greater than the
             * height of both the navigation and the top bar combined.
             */
            const isHeaderAboveStickyNavigation = parentHeight + parentTop > height + navHeight;

            if (isChildNavigationBehindHeader && !isHeaderAboveStickyNavigation) {
                setIsChildNavSticky({
                    goingUp: false,
                    isChildNavSticky: true,
                });

                updateBodyClasses(true);
            } else if (isHeaderAboveStickyNavigation) {
                setIsChildNavSticky({
                    goingUp: true,
                    isChildNavSticky: false,
                });

                updateBodyClasses(false);
            }
        }
    }
};

const formatHookResponse = ({
    headerRef,
    navRef,
    parentHeaderRef,
    shouldShowChildNavigation,
    isSmall,
    instanceId,
    parentInstanceId,
    isChildNavSticky,
    isStickyEnabled = true,
}: {
    headerRef: React.RefObject<HTMLDivElement>;
    navRef: React.RefObject<HTMLDivElement>;
    parentHeaderRef: React.RefObject<HTMLDivElement>;
    shouldShowChildNavigation: boolean;
    isChildNavSticky: boolean;
    isStickyEnabled?: boolean;
    isSmall: boolean;
    parentInstanceId?: string;
    instanceId: string;
}) => {
    return {
        headerRef,
        parentHeaderRef,
        navRef,
        /**
         * If we are in the new application, we want to display the child navigation only if the scroll evaluations say so.
         * We also want to make the child navigation visible only if the feature flag is enabled and we are not in small screens.
         */
        isChildNavigationVisible: shouldShowChildNavigation && !isSmall,
        /**
         * If the navigation inheritance is enabled and we are not in mobile, we change the current instance for the parent one,
         * so we can show the parent's navigation on the first navigation displayed.
         */
        topNavigationInstanceId: shouldShowChildNavigation && !isSmall ? (parentInstanceId as string) : instanceId,
        /**
         * We want to make the parent nav sticky only if navigation inheritance flag is enabled and sticky is enabled, no matter the context.
         */
        isParentNavSticky: isStickyEnabled && !shouldShowChildNavigation,
        /**
         * The child navigation should only be sticky if sticky is enabled and the scroll evaluations determines that it needs to be sticky
         */
        isChildNavSticky: isStickyEnabled && isChildNavSticky,
    };
};

/**
 * Hook that centralizes the logic for displaying a parent and child navigation on the same page.
 * It essentially manages which navigation to display depending on the context, the feature flags enabled
 * and whether the current user is scrolling on the page or not. This hook should only be used for the main
 * navigation and child navigation on the header. There should not be any there uses cases for it.
 */
const useStickyChildNavigation = ({
    isMainNavInheritanceEnabled,
    instanceId,
    parentInstanceId,
    isStickyEnabled = true,
}: UseChildNavigationProps) => {
    const headerRef = React.useRef<HTMLDivElement>(null);
    const shouldShowChildNavigation = Boolean(isMainNavInheritanceEnabled) && Boolean(parentInstanceId);
    const parentHeaderRef = React.useRef<HTMLDivElement>(null);
    const navRef = React.useRef<HTMLDivElement>(null);
    const { isSmall } = useResponsive();
    const [sticky, setIsChildNavSticky] = React.useState<StickyNavState>(stickyNavDefaultState);

    /**
     * If the feature is enabled, we need to adjust the padding of the entire page in order to avoid displacing
     * the entire page as many pixels as the top bar and the main navigation, but rather just the top bar.
     */
    useEffect(() => {
        if (shouldShowChildNavigation) {
            document.body.classList.add(NAVIGATION_INHERITANCE_BODY_CLASSNAME);
        }

        if (!isStickyEnabled) {
            document.body.classList.add(STICKY_DISABLED_BODY_CLASSNAME);
        }
    }, [shouldShowChildNavigation, isStickyEnabled]);

    useWindowScroll(() => {
        calculateStickyNavigation({
            setIsChildNavSticky,
            headerRef,
            shouldShowChildNavigation,
            parentHeaderRef,
            navRef,
            scrollTop: document.documentElement.scrollTop,
        });
    }, shouldShowChildNavigation && isStickyEnabled);

    return formatHookResponse({
        isSmall,
        instanceId,
        parentInstanceId,
        shouldShowChildNavigation,
        parentHeaderRef,
        headerRef,
        navRef,
        isChildNavSticky: sticky.isChildNavSticky,
        isStickyEnabled,
    });
};

export { useStickyChildNavigation, formatHookResponse, calculateStickyNavigation };
