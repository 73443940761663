import { isAutoTestModeEnabled } from 'components/utils';

/////////////////////////////

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSlideshowController($scope, $element, $interval, $timeout, Layout) {
        'ngInject';

        var widgetSlideshow = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The slideshow interval in ms.
         *
         * @type {number}
         */
        var _slideshowInterval;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The active slide index.
         *
         * @type {number}
         */
        widgetSlideshow.activeIndex = 0;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get groupBy property according to breakpoint.
         *
         * @return {number} The groupBy property.
         */
        function _getGroupBy() {
            var groupBy;

            if (Layout.breakpoint === 'palm') {
                groupBy = 1;
            } else if (Layout.breakpoint === 'lap') {
                groupBy = (widgetSlideshow.groupBy === '1') ? 1 : 2;
            } else if (Layout.breakpoint === 'desk') {
                groupBy = (angular.isDefinedAndFilled(widgetSlideshow.groupBy)) ? widgetSlideshow.groupBy : 1;
            }

            return groupBy;
        }

        /**
         * Reset slideshow index.
         *
         */
        function _resetIndex() {
            widgetSlideshow.activeIndex = 0;

            // eslint-disable-next-line no-use-before-define
            animateSlide(0);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Go to slide according to its index.
         *
         * @param {number} index The slide index.
         */
        function animateSlide(index) {
            $element.find('.widget-slideshow__wrapper')
                .css({
                    '-moz-transform': 'translate3d(' + index + '%, 0, 0)',
                    '-ms-transform': 'translate3d(' + index + '%, 0, 0)',
                    '-o-transform': 'translate3d(' + index + '%, 0, 0)',
                    '-webkit-transform': 'translate3d(' + index + '%, 0, 0)',
                    'transform': 'translate3d(' + index + '%, 0, 0)',
                });
        }

        /**
         * Cancel slideshow autoplay.
         */
        function cancelAutoplay() {
            $interval.cancel(_slideshowInterval);
            _slideshowInterval = undefined;
        }

        /**
         * Count slideshow slides.
         *
         * @return {number} the number of slides.
         */
        function countSlides() {
            return Math.ceil(widgetSlideshow.listLength / _getGroupBy());
        }

        /**
         * Check if controls has to be shown.
         *
         * @return {bolean} If the controls should be displayed.
         */
        function displayControls() {
            return widgetSlideshow.enableSlideshow && angular.isDefinedAndFilled(_getGroupBy()) &&
                   widgetSlideshow.listLength / _getGroupBy() > 1;
        }

        /**
         * Get slideshow classes.
         *
         * @return {Array} The slideshow classes.
         */
        function getSlideshowClasses() {
            var slideshowClasses = [];

            if (angular.isDefinedAndFilled(_getGroupBy())) {
                slideshowClasses.push('widget-slideshow--group-by-' + _getGroupBy());
            }

            if (widgetSlideshow.enableSlideshow) {
                slideshowClasses.push('widget-slideshow--slideshow');
            } else {
                slideshowClasses.push('widget-slideshow--no-slideshow');
            }

            if (angular.isUndefinedOrEmpty(widgetSlideshow.theme)) {
                slideshowClasses.push('widget-slideshow--theme-light');
            } else {
                slideshowClasses.push('widget-slideshow--theme-' + widgetSlideshow.theme);
            }

            if (displayControls()) {
                slideshowClasses.push('widget-slideshow--controls');
            } else {
                slideshowClasses.push('widget-slideshow--no-controls');
            }

            return slideshowClasses;
        }

        /**
         * Navigate to a specific slide.
         *
         * @param {number} index The slide index.
         */
        function goToSlide(index) {
            if (widgetSlideshow.autoPlay) {
                cancelAutoplay();
            }

            if (index < widgetSlideshow.activeIndex || index > widgetSlideshow.activeIndex) {
                animateSlide(-100 * parseInt(index, 10));
            }

            widgetSlideshow.activeIndex = index;
        }

        /**
         * Display next slide in slideshow.
         *
         * @param {boolean} keepAutoplay If keep autoplay.
         */
        function next(keepAutoplay) {
            if (widgetSlideshow.autoPlay && !keepAutoplay) {
                cancelAutoplay();
            }

            if ((widgetSlideshow.activeIndex + 1) * _getGroupBy() >= widgetSlideshow.listLength) {
                widgetSlideshow.activeIndex = 0;
            } else {
                widgetSlideshow.activeIndex++;
            }

            animateSlide(100 * (widgetSlideshow.activeIndex * -1));
        }

        /**
         * Display previous slide in slideshow.
         */
        function previous() {
            if (widgetSlideshow.autoPlay) {
                cancelAutoplay();
            }

            if (widgetSlideshow.activeIndex) {
                widgetSlideshow.activeIndex--;
            } else {
                widgetSlideshow.activeIndex = countSlides() - 1;
            }

            animateSlide(100 * (widgetSlideshow.activeIndex * -1));
        }

        /////////////////////////////

        widgetSlideshow.countSlides = countSlides;
        widgetSlideshow.displayControls = displayControls;
        widgetSlideshow.getSlideshowClasses = getSlideshowClasses;
        widgetSlideshow.goToSlide = goToSlide;
        widgetSlideshow.next = next;
        widgetSlideshow.previous = previous;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * If widget parent has tabs, reset slideshow index when change tab.
         *
         * @param {Event}  evt        The event.
         * @param {string} [widgetId] Widget-slideshow uuid.
         */
        $scope.$on('reset-slideshow-index', function onWidgetContentListChangeTab(evt, widgetId) {
            if (angular.isUndefinedOrEmpty(widgetId) || widgetId === widgetSlideshow.widget.uuid) {
                _resetIndex();
            }
        });

        /**
         * Initialize the controller.
         */
        function init() {
            widgetSlideshow.theme = widgetSlideshow.theme || 'light';

            if (angular.isUndefined(widgetSlideshow.slideInterval) || widgetSlideshow.slideInterval <= 0) {
                widgetSlideshow.slideInterval = 3;
            }

            if (widgetSlideshow.enableSlideshow && widgetSlideshow.autoPlay && !isAutoTestModeEnabled()) {
                _slideshowInterval = $interval(function goToNextSlide() {
                    next(true);
                }, widgetSlideshow.slideInterval * 1000);
            }
        }

        init();
    }

    /////////////////////////////

    function WidgetSlideshowDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: WidgetSlideshowController,
            controllerAs: 'widgetSlideshow',
            replace: true,
            require: ['widgetSlideshow', '^widget'],
            restrict: 'E',
            scope: {
                autoPlay: '=',
                enableSlideshow: '=',
                groupBy: '@',
                listLength: '@',
                slideInterval: '@?',
                theme: '@?',
            },
            templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-slideshow.html',
            transclude: true,
        };
    }

    /////////////////////////////

    function WidgetSlideshowItemDirective() {
        'ngInject';

        return {
            replace: true,
            require: '^widgetSlideshow',
            restrict: 'E',
            template: '<div class="widget-slideshow__block" ng-transclude></div>',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives')
        .directive('widgetSlideshowItem', WidgetSlideshowItemDirective)
        .directive('widgetSlideshow', WidgetSlideshowDirective);
})();
