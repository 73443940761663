import dropRight from 'lodash/dropRight';
import isEqual from 'lodash/isEqual';
import { Path } from 'slate';

export function isGrandParent(testedPath: Path, supposedGrandParentPath: Path) {
    return (
        testedPath.length - 2 === supposedGrandParentPath.length &&
        isEqual(dropRight(testedPath, 2), supposedGrandParentPath)
    );
}
