import { dangerousAxiosInstanceUseOnlyIfNeeded } from '@lumapps/base-api';
import { DEV_CDN } from '@lumapps/constants';

import { VersionMetadata } from '../types';

export interface GetMetadataForFrontendVersionOptions {
    version: string;
}

export const getMetadataForFrontendVersion = ({ version }: GetMetadataForFrontendVersionOptions) => {
    return dangerousAxiosInstanceUseOnlyIfNeeded.get<VersionMetadata>(
        `${DEV_CDN}${version}/statics/deploy-metadata.json`,
    );
};
