import { shape } from 'prop-types';

import { widgetType } from '../types';

/** The prop types definition for the widget RSS */
export const widgetRSSType = {
    ...widgetType,
    component: shape({
        ...widgetType.component,
        properties: shape({
            ...widgetType.component.properties,
        }),
    }),
};
