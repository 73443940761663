import React from 'react';

import { Chip, Icon, Size } from '@lumapps/lumx/react';
import { mdiClose, mdiGoogleCirclesExtended } from '@lumapps/lumx/icons';

import { translate as t } from 'components/translations';

/**
 * Displays a list of communities as Chips.
 *
 * @return {JSX.Element | null} communities as Chips.
 */
export const CommunityChipList = ({ pendingCommunitiesMap, onToggle }) => {
    /**
     * Called when a Community Chip's cross is clicked on.
     *
     * @param {Object} community Community which Chip has been clicked on.
     */
    const handleToogle = (community) => {
        onToggle(community);
    };

    return (
        <div className="community-list">
            <div className="community-list__title">{t('FRONT.COMMUNITY.COMMUNITIES_SELECTION')}</div>
            <div className="community-list__chips-container">
                {Object.keys(pendingCommunitiesMap).map((key) => (

                        <Chip
                            after={<Icon icon={mdiClose} />}
                            before={<Icon icon={mdiGoogleCirclesExtended} />}
                            className="community-list__community-chip"
                            key={`commChip-${key}`}
                            size={Size.s}
                            onAfterClick={() => {
                                handleToogle(pendingCommunitiesMap[key]);
                            }}
                        >
                            {t(pendingCommunitiesMap[key].title)}
                        </Chip>
                ))}
            </div>
        </div>
    );
};
