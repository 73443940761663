/* eslint-disable no-param-reassign */
import produce from 'immer';

import get from 'lodash/get';

import { FETCH_USERS_SUCCESS, ADD_ROLE, DELETE_ROLE } from './actions';

const defaultState = {
    current: null,
    entities: {},
};

export const reducer = produce((draft, action) => {
    switch (action.type) {
        case FETCH_USERS_SUCCESS:
            action.users.forEach((user) => {
                const existingUser = get(draft.entities, user.id, {});

                draft.entities[user.id] = { ...existingUser, ...user };
            });

            break;

        case ADD_ROLE:
            action.users.forEach((user) => {
                draft.entities[user.id] = user;
            });

            break;

        case DELETE_ROLE:
            action.users.forEach((user) => {
                draft.entities[user.id] = undefined;
            });

            break;

        default:
            return draft;
    }

    return draft;
}, defaultState);
