import React from 'react';

import { Button, ButtonProps, IconButton, IconButtonProps, Icon, IconProps } from '@lumapps/lumx/react';
import { useDynamicIcon, DEFAULT_SVG } from '@lumapps/utils/hooks/useDynamicIcon';

interface CustomizationButtonProps extends ButtonProps {
    leftIcon?: string;
    rightIcon?: string;
}

/**
 * Component that wraps the DS button so that it retrieves the icons from our CDN. If we do not
 * do this, we would need to include in the JS bundle all the icons that are available in DS, and
 * that would have a heavy toll in our performance. In order to avoid add, we allow the customizations
 * API to use buttons but specify which icons they want to use as strings rather that importing the icon
 * directly.
 * @param props - CustomizationButtonProps
 */
export const CustomizationButton: React.FC<CustomizationButtonProps> = (props) => {
    const { leftIcon, rightIcon } = props;

    const { iconPath: leftIconPath } = useDynamicIcon({ icon: leftIcon || '' });
    const { iconPath: rightIconPath } = useDynamicIcon({ icon: rightIcon || '' });

    return (
        <Button
            {...props}
            leftIcon={leftIconPath !== DEFAULT_SVG ? leftIconPath : undefined}
            rightIcon={rightIconPath !== DEFAULT_SVG ? rightIconPath : undefined}
        />
    );
};

CustomizationButton.displayName = 'Button';

interface CustomizationIconButtonProps extends IconButtonProps {
    icon: string;
}

/**
 * Component that wraps the DS icon button so that it retrieves the icons from our CDN. If we do not
 * do this, we would need to include in the JS bundle all the icons that are available in DS, and
 * that would have a heavy toll in our performance. In order to avoid add, we allow the customizations
 * API to use icon buttons but specify which icons they want to use as strings rather that importing the icon
 * directly.
 * @param props - CustomizationIconButtonProps
 */
export const CustomizationIconButton: React.FC<CustomizationIconButtonProps> = (props) => {
    const { icon } = props;

    const { iconPath } = useDynamicIcon({ icon });

    return <IconButton {...props} icon={iconPath} />;
};

CustomizationIconButton.displayName = 'IconButton';

interface CustomizationIconProps extends IconProps {
    icon: string;
}

/**
 * Component that wraps the DS icon so that it retrieves the icons from our CDN. If we do not
 * do this, we would need to include in the JS bundle all the icons that are available in DS, and
 * that would have a heavy toll in our performance. In order to avoid add, we allow the customizations
 * API to use icons but specify which icons they want to use as strings rather that importing the icon
 * directly.
 * @param props - CustomizationIconProps
 */
export const CustomizationIcon: React.FC<CustomizationIconProps> = (props) => {
    const { icon } = props;

    const { iconPath } = useDynamicIcon({ icon });

    return <Icon {...props} icon={iconPath} />;
};

CustomizationIcon.displayName = 'Icon';
