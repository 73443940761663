import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { LEARNING_EVENTS } from '@lumapps/learning-events/keys';
import { learningEventsRoute } from '@lumapps/learning-events/routes';
import { isLearningAlphaEnabled, isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import {
    SideNavigationSection as Section,
    SideNavigationItem,
} from '@lumapps/lumx-back-office/components/SideNavigation';
import { learningHatImage } from '@lumapps/lumx/custom-icons';
import { mdiChartLine, mdiSettings } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useLearningRoles } from '../../hooks/useLearningRoles';
import { LEARNING } from '../../keys';
import {
    learningAnalyticsRoute,
    learningCategoriesRoute,
    learningCertificatesRoute,
    learningLearnersRoute,
    learningManagersRoute,
    learningTrainingCoursesRoute,
} from '../../routes';
import { MissionCenterPermitionRoles } from '../../types';

export const LearningAdminSideNavigation: React.FC = () => {
    const { translateKey } = useTranslate();
    const { roles, route } = useLearningRoles();
    const learningEnabled = useSelector(isLearningEnabled);
    const learningAlphaEnabled = useSelector(isLearningAlphaEnabled);

    if (isEmpty(roles) || (!learningEnabled && isEmpty(route))) {
        return null;
    }

    return (
        <Section header={translateKey(LEARNING.LEARNING)} id="learning">
            {roles?.includes(MissionCenterPermitionRoles.Analyst) && learningEnabled && (
                <SideNavigationItem
                    icon={mdiChartLine}
                    label={translateKey(GLOBAL.ANALYTICS)}
                    route={learningAnalyticsRoute}
                    id="learning-analytics"
                />
            )}
            {(roles?.includes(MissionCenterPermitionRoles.CatalogManager) ||
                roles?.includes(MissionCenterPermitionRoles.TrainingManager) ||
                roles?.includes(MissionCenterPermitionRoles.TrainingPublisher)) &&
                learningEnabled && (
                    <SideNavigationItem
                        label={translateKey(GLOBAL.CATALOG)}
                        icon={learningHatImage}
                        id="learning-Catalog"
                    >
                        {roles.includes(MissionCenterPermitionRoles.CatalogManager) && (
                            <SideNavigationItem
                                label={translateKey(GLOBAL.CATEGORIES)}
                                route={learningCategoriesRoute}
                                id="learning-categories"
                            />
                        )}
                        <SideNavigationItem
                            label={translateKey(GLOBAL.TRAINING_COURSES)}
                            route={learningTrainingCoursesRoute}
                            id="learning-training-courses"
                        />
                        {roles.includes(MissionCenterPermitionRoles.EventsManager) && learningAlphaEnabled && (
                            <SideNavigationItem
                                label={translateKey(LEARNING_EVENTS.TITLE)}
                                route={learningEventsRoute}
                                id="learning-events"
                            />
                        )}
                    </SideNavigationItem>
                )}
            {(roles?.includes(MissionCenterPermitionRoles.LearnerManager) ||
                roles?.includes(MissionCenterPermitionRoles.AdminManager) ||
                roles?.includes(MissionCenterPermitionRoles.SuperAdmin)) &&
                learningEnabled && (
                    <SideNavigationItem
                        label={translateKey(GLOBAL.ROLE_ADMINISTRATOR)}
                        icon={mdiSettings}
                        id="learning-Administration"
                    >
                        {roles.includes(MissionCenterPermitionRoles.CatalogManager) && learningEnabled && (
                            <SideNavigationItem
                                label={translateKey(LEARNING.LEARNING_LEARNERS)}
                                route={learningLearnersRoute}
                                id="learning-learners"
                            />
                        )}
                        {roles.includes(MissionCenterPermitionRoles.AdminManager) && learningEnabled && (
                            <SideNavigationItem
                                label={translateKey(GLOBAL.MANAGERS)}
                                route={learningManagersRoute}
                                id="learning-managers"
                            />
                        )}
                        {roles.includes(MissionCenterPermitionRoles.SuperAdmin) && learningEnabled && (
                            <SideNavigationItem
                                label={translateKey(LEARNING.LEARNING_CERTIFICATES)}
                                route={learningCertificatesRoute}
                                id="learning-certificates"
                            />
                        )}
                    </SideNavigationItem>
                )}
        </Section>
    );
};

LearningAdminSideNavigation.displayName = 'SideNavigationItem';
