import { useSelector } from '@lumapps/redux/react';

import { getStyleProperties, getChildStyleProperties } from '../ducks/selectors';

export interface UseStylesApi {
    /** global styles property to retrieve  */
    property: string;
    selector?: (state: any) => any;
}

/**
 * Retrieves the global styles configured for the given property
 */
const useStyles = ({ property, selector = getStyleProperties }: UseStylesApi) => {
    /**
     * Here you will find several fallbacks. The logic is the following:
     * - If there are styles in the store (coming from useStyles), use those
     * - If there are no styles, but we have the styles of the instance coming from the variables injected into the HTML, use them
     * - Then default to the base theme
     */
    const properties = useSelector(selector) || {};
    const styles = properties[property] || {};

    return { styles };
};

const useChildStyles = ({ property, selector = getChildStyleProperties }: UseStylesApi) => {
    return useStyles({ property, selector });
};

export { useStyles, useChildStyles };
