import { LumworkSearchResult } from '@lumapps/search/types';
import { TranslationAPI } from '@lumapps/translations';

import { getMetadataTimestampLabel } from '../ContentMetadata/props';

export interface LumWorkResultPropsOptions {
    translate: TranslationAPI['translate'];
}

const LUMWORK_URL = 'https://work.lumapps.com/';

const props = (result: LumworkSearchResult, baseProps: any, options: LumWorkResultPropsOptions) => {
    const { translate } = options;
    const { quickViewUrl, onQuickViewClick } = baseProps;
    const metadataLabel = getMetadataTimestampLabel(
        {
            updateTime: result.lastUpdate,
            createTime: result.lastUpdate,
        },
        { useUpdateTime: true, translate: options.translate },
    );

    return {
        id: result.id,
        title: translate(result.name) as string,
        url: `${LUMWORK_URL}${result.spaceId}/${result.id}/?idVersion=${result.version}`,
        metadata: {
            metadata: [metadataLabel],
        },
        position: result.position,
        quickViewUrl,
        onQuickViewClick,
    };
};

export { props };
