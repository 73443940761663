export enum SEARCH_RESULT_TYPES {
    GENERIC = 'generic',
    ARTICLE = 'article',
    COMMUNITY = 'community',
    DIRECTORY_ENTRY = 'directory-entry',
    CALENDAR = 'calendar',
    GDEFAULT = 'google-default',
    THIRD_PARTY = 'third-party',
    ZENDESK = 'zendesk',
    LUMWORK = 'lumwork',
    MEDIA = 'media',
    POST = 'post',
    USER = 'user',
    GENERIC_DOCUMENT = 'generic-document',
}
