import { EDIT_COMMUNITY_DIALOG_EVENT } from '@lumapps/community-configuration/constants';
import { AppId } from '@lumapps/constants/app';
import { MobilePageDescriptor } from '@lumapps/mobile-webview/message-emit/redirect/types';
import { Route } from '@lumapps/router';

import { SpaceViews } from './types';

/**
 * Base URL for the Space
 * URL params ${id} & ${slug} are required
 * */
const SPACE_BASE_URL = '/ls/space/:id/:slug';

/**
 * All the views should be listed in the SpaceViews enum
 * It's used to generate the default route for the Space, with only available views.
 * */
export const SPACE_PAGES_PATH = `${SPACE_BASE_URL}/:view(${Object.keys(SpaceViews).join('|')})?/:identifier?`;

export const SPACE_VIEW_DEFAULT_PATH = `${SPACE_BASE_URL}/`;
export const SPACE_VIEW_ARTICLE_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.article})/:identifier`;
export const SPACE_VIEW_ARTICLE_LIST_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.articles})`;
export const SPACE_VIEW_CALENDAR_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.calendar})`;
export const SPACE_VIEW_DOCUMENTS_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.documents})`;
export const SPACE_VIEW_DRIVE_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.drive})`;
export const SPACE_VIEW_EVENT_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.event})/:identifier`;
export const SPACE_VIEW_EVENT_LIST_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.events})`;
export const SPACE_VIEW_FEED_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.feed})`;
export const SPACE_VIEW_HOME_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.home})`;
export const SPACE_VIEW_LEARNING_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.learning})`;
export const SPACE_VIEW_POST_LIST_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.posts})`;
export const SPACE_VIEW_POST_PATH = `${SPACE_BASE_URL}/:view(${SpaceViews.post})/:identifier`;

export const spaceViewRoute: Route = {
    path: SPACE_PAGES_PATH,
    appId: AppId.frontOffice,
};

export const spaceDefaultViewRoute: Route = {
    path: SPACE_VIEW_DEFAULT_PATH,
    appId: AppId.frontOffice,
};

export const spaceArticleViewRoute: Route = {
    path: SPACE_VIEW_ARTICLE_PATH,
    appId: AppId.frontOffice,
};

export const spaceArticleListViewRoute: Route = {
    path: SPACE_VIEW_ARTICLE_LIST_PATH,
    appId: AppId.frontOffice,
};

export const spaceCalendarViewRoute: Route = {
    path: SPACE_VIEW_CALENDAR_PATH,
    appId: AppId.frontOffice,
};

export const spaceDocumentsViewRoute: Route = {
    path: SPACE_VIEW_DOCUMENTS_PATH,
    appId: AppId.frontOffice,
};

export const spaceDriveViewRoute: Route = {
    path: SPACE_VIEW_DRIVE_PATH,
    appId: AppId.frontOffice,
};

export const spaceEventViewRoute: Route = {
    path: SPACE_VIEW_EVENT_PATH,
    appId: AppId.frontOffice,
};

export const spaceEventListViewRoute: Route = {
    path: SPACE_VIEW_EVENT_LIST_PATH,
    appId: AppId.frontOffice,
};

export const spaceFeedViewRoute: Route = {
    path: SPACE_VIEW_FEED_PATH,
    appId: AppId.frontOffice,
};

export const spaceHomeViewRoute: Route = {
    path: SPACE_VIEW_HOME_PATH,
    appId: AppId.frontOffice,
};

export const spaceLearningViewRoute: Route = {
    path: SPACE_VIEW_LEARNING_PATH,
    appId: AppId.frontOffice,
};

export const spacePostListViewRoute: Route = {
    path: SPACE_VIEW_POST_LIST_PATH,
    appId: AppId.frontOffice,
};

export const spacePostViewRoute: Route = {
    path: SPACE_VIEW_POST_PATH,
    appId: AppId.frontOffice,
};

interface SpaceViewParams extends Record<string, string | undefined> {
    view?: SpaceViews;
    // Space id
    id: string;
    // Space slug
    slug: string;
    // Id of the view detail (post, event or article)
    identifier?: string;
}

export const spaceView = (route: Partial<Route> & { params: SpaceViewParams; anchor?: string }): Route => {
    const { id: spaceId, view, identifier } = route.params;
    const isDetailView = view && identifier && [SpaceViews.article, SpaceViews.event, SpaceViews.post].includes(view);

    const mobilePageDescriptor: MobilePageDescriptor = isDetailView
        ? {
              type: view as SpaceViews.article | SpaceViews.event | SpaceViews.post,
              id: identifier,
          }
        : {
              type: 'space',
              id: spaceId,
              view,
          };

    return {
        ...spaceViewRoute,
        ...route,
        mobilePageDescriptor,
    };
};

export const spaceSettingsView = (params: SpaceViewParams): Route => {
    return spaceView({ params, anchor: EDIT_COMMUNITY_DIALOG_EVENT });
};
