import React from 'react';

import { Editor, Path } from '@lumapps/wrex/slate';
import { withoutHistory } from '@lumapps/wrex/slate/utils/withoutHistory';

import { CLASSNAME, IMAGE_FEATURES, IMAGE_WIDTHS } from '../../../constants';
import { ImageEditor, ImageGroupElement } from '../../../types';

/** Hook on the image load event to update the image group width */
export const useAutoWidth = (options: {
    editor: ImageEditor & Editor;
    isAlignedImage: boolean;
    element?: ImageGroupElement;
    path: Path;
}) => {
    const { element, isAlignedImage, editor, path } = options;
    const imgRef = React.useCallback(
        (img: HTMLImageElement | null) => {
            if (!img || !editor.isImageFeatureEnabled(IMAGE_FEATURES.width)) {
                return;
            }
            img.addEventListener('load', () => {
                // Compute parent width including the margins
                const parent = img.closest(`.${CLASSNAME}`) as HTMLElement;
                const parentComputedStyle = window.getComputedStyle(parent);
                const parentWidth =
                    parent.offsetWidth +
                    parseInt(parentComputedStyle.marginLeft, 10) +
                    parseInt(parentComputedStyle.marginRight, 10);

                // If the image is bigger than the parent show in full width, else show in half width
                const newWidth = parentWidth < img.naturalWidth ? IMAGE_WIDTHS.fullWidth : IMAGE_WIDTHS.halfWidth;

                // Update the image width if necessary
                withoutHistory(editor, () => editor.updateImageNode({ width: newWidth }, path));
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [path],
    );
    // Skip if element.width is defined or auto width feature is not activated or the image is in an alignment wrapper
    if (isAlignedImage || element?.width) {
        return undefined;
    }
    return imgRef;
};
