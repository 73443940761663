import { and } from '@lumapps/utils/function/predicate/and';
import { IMAGE_GALLERY_VIEW_MODE } from '@lumapps/wrex-image-gallery/constants';
import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute, matchNode } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { Fig, FigAlignClass, Image, ImageLink, Media, Title } from '@lumapps/wrex/serialization/dita/types';
import { formatFocalPoint } from '@lumapps/wrex/serialization/dita/utils/formatFocalPoint';

import { IMAGE_GROUP, IMAGE_WIDTHS } from '../../constants';
import { AlignedImageWrapperElement, ImageElement, ImageGroupElement } from '../../types';
import { createAlignedImageWrapper } from '../../utils/createAlignedImageWrapper';

const parsePixelSize = (size: string | undefined): number | undefined => {
    const match = size && size.match(/(\d+)px/);
    if (match) {
        return parseInt(match[1], 10) || undefined;
    }
    return undefined;
};

/**
 * Convert Dita Image into slate ImageGroup element.
 */
export const IMAGE_GROUP_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'fig' }),
    transform: (nd): ImageGroupElement => {
        const node = nd as Fig;
        const title = node.children.find(matchNode({ name: 'title' })) as Title;
        const widthRegex = node.attributes?.outputclass?.match(/^align-([full|center|right|left])*$/);
        const imageGalleryRegex = node.attributes?.outputclass?.match(/^view-mode-([mosaic|slideshow])*$/);

        const images = node.children.filter(matchNode({ name: 'image' })) as Image[];
        const linkElement = node.children.find(matchNode({ name: 'xref' })) as ImageLink;
        const imageElements: ImageElement[] = images.map((image) => {
            let link: string | undefined;

            if (linkElement && images.length === 1) {
                link = linkElement.attributes.href;
            }
            const alt = image.children.find(matchNode({ name: 'alt' }));
            const { href, keyref, height, width } = image.attributes;

            const mediaNode = node.children?.find(
                and(matchAttribute('type', /lumapps:media/), matchAttribute('keyref', RegExp(keyref))),
            ) as Media | undefined;

            const focalPointNode = mediaNode?.children?.find(matchAttribute('name', /focalPoint/));
            const focalPoint = formatFocalPoint(
                focalPointNode?.children?.find((el) => el.attributes?.name === 'focalPoint:x')?.attributes.value,
                focalPointNode?.children?.find((el) => el.attributes?.name === 'focalPoint:y')?.attributes.value,
            );

            return {
                alt: alt?.children[0].value,
                height: parsePixelSize(height),
                width: parsePixelSize(width),
                src: href,
                id: keyref,
                link,
                focalPoint,
            };
        });

        let viewMode: ImageGroupElement['viewMode'];
        if (imageGalleryRegex) {
            viewMode = imageGalleryRegex[0] as IMAGE_GALLERY_VIEW_MODE;
        }

        let width: IMAGE_WIDTHS | undefined;
        if (!viewMode && widthRegex) {
            width = widthRegex[0] === FigAlignClass.alignFull ? IMAGE_WIDTHS.fullWidth : IMAGE_WIDTHS.halfWidth;
        }

        return {
            type: IMAGE_GROUP,
            children: [{ text: '' }],
            images: imageElements,
            title: title.children[0].value,
            width,
            viewMode,
        };
    },
};

export const ALIGNED_IMAGE_WRAPPER_CONVERT: ElementConvertOption = {
    test: and(matchNode({ name: 'bodydiv' }), matchAttribute('outputclass', /^image-wrapper/g)),
    transform: (node, children): AlignedImageWrapperElement => {
        const alignmentRegex = node.attributes?.outputclass?.match(/align-(right|left)*$/);

        const alignment = alignmentRegex[0] as AlignedImageWrapperElement['alignment'];
        return createAlignedImageWrapper(
            {
                alignment,
            },
            children,
        );
    },
};
