import * as React from 'react';
import * as propTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import * as types from '../types';
import * as styleTypes from './types';
import { translate as t } from '../../translations';
import { DEFAULT_ROW_STYLE, DEFAULT_FLUID_ROW, DEFAULT_NOT_FLUID_ROW } from './constants';
import { AccordionBox } from '../ui';
import { SpacingSettings } from './settings/SpacingSettings';
import { BorderSettings } from './settings/BorderSettings';
import { BackgroundSettings } from './settings/background/BackgroundSettings';

/**
 * Renders a row style editor.
 */
export class RowStyle extends React.PureComponent {
    static propTypes = {
        name: types.properties,
        /** Define if the cell is in plain mode */
        isFullWidth: propTypes.bool,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Current row style. */
        value: styleTypes.rowStyle,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { value = {}, name: key = 'rowStyle', onChange, isFullWidth } = this.props;
        let defaults = cloneDeep(DEFAULT_ROW_STYLE);

        // Manage the modifications  when the row is in full width mode.
        defaults = merge(defaults, isFullWidth ? cloneDeep(DEFAULT_FLUID_ROW) : cloneDeep(DEFAULT_NOT_FLUID_ROW));

        return (
            <div className="widget-style-main__settings-wrapper">
                <AccordionBox label={t('STYLE.SPACINGS')} variant="simple">
                    <SpacingSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BORDERS')} variant="simple">
                    <BorderSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BACKGROUND')} variant="simple">
                    <BackgroundSettings value={value} name={key} onChange={onChange} defaults={defaults} />
                </AccordionBox>
            </div>
        );
    }
}
