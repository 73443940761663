import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { Text } from '@lumapps/wrex/serialization/dita/types';

import type { UserMentionElement } from '../../types';

/** Convert slate mention element to DITA xref. */
export const MENTION_CONVERT: Converter<UserMentionElement> = (node) => ({
    type: 'element',
    name: 'xref',
    attributes: {
        scope: 'local',
        type: 'lumapps:user',
        keyref: node?.user.id || '',
    },
    children: [{ type: 'text', value: node?.user.fullName } as Text],
});
