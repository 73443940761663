/* eslint-disable import/no-mutable-exports */

import noop from 'lodash/noop';

let getFroala = noop;

/**
 * Expose the methods from the AngularJS service to the React world.
 *
 * @param {Service} FroalaLoader The AngularJS Froala Loading service.
 */
function setFroalaLoader(FroalaLoader) {
    ({ getFroala } = FroalaLoader);
}

/////////////////////////////

export { getFroala, setFroalaLoader };
