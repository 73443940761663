import { connect } from '@lumapps/redux/react';
import { SAMakeShareableFromContent } from './SAMakeShareableFromContent';
import { toggleOpenWorkflow } from '../ducks/shareableWorkflow/actions';

const mapDispatchToProps = {
    onProgramSelected: (programId: string, contentId: string) => (dispatch: any) => {
        dispatch(toggleOpenWorkflow(true, { programId, contentId }));
    },
};

const ConnectedSAMakeShareableFromContent = connect(null, mapDispatchToProps)(SAMakeShareableFromContent);

export { ConnectedSAMakeShareableFromContent as SAMakeShareableFromContent };
