import { notFoundRoute, Route } from '@lumapps/router';
import { RouterData, ROUTER_DATA_NAMESPACES } from '@lumapps/router/ducks/types';
import { spaceView } from '@lumapps/spaces/routes';
import { SpaceViews } from '@lumapps/spaces/types';

import { communityView } from '../../routes';
import { CommunityLinkRef, RenderingType } from '../../types';

export const getCommunityRoute = (
    params: CommunityLinkRef,
    options: { isSpaceFeatureEnabled: boolean; homeRoute?: Route },
): {
    route: Route<string>;
    routerData?: RouterData | undefined;
} => {
    const { id, identifier, slug, instance, view, renderingType, errorStatus, anchor } = params || {};
    const { isSpaceFeatureEnabled, homeRoute } = options;
    const routerData = {
        id: ROUTER_DATA_NAMESPACES.COMMUNITY_PAGE,
        data: {
            communityId: id,
        },
    };

    // If the API call to get information resulted in an error we must redirect depending on the error code
    // Not authorized -> Homepage
    // Any other errors -> Not Found
    if (errorStatus) {
        return { route: errorStatus === 403 && homeRoute ? homeRoute : notFoundRoute };
    }

    // We need at least an id to route a space
    if (renderingType === RenderingType.space && id && slug) {
        // if the space FF is not enabled we get the Not Found route
        if (isSpaceFeatureEnabled) {
            let mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined;

            if (view === SpaceViews.article || view === SpaceViews.event) {
                mobilePageDescriptor = {
                    id,
                    type: view,
                };
            }

            const route = spaceView({
                params: { slug, id, view: view as SpaceViews, identifier },
                instanceSlug: instance?.slug,
                anchor,
                mobilePageDescriptor,
            });
            return { route, routerData };
        }
        return { route: notFoundRoute };
    }
    // Fallback to community
    const route = communityView({
        ...params,
        instanceSlug: instance?.slug,
        anchor,
    });
    return { route, routerData };
};
