(function IIFE() {
    'use strict';

    /////////////////////////////

    function ClassSelectorController() {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Array containing all custom classes selected by user.
         *
         * @type {Array}
         */
        vm.itemClasses = [];

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Compute the class array based on the class string property.
         * Called during lx-select init.
         */
        function setProperties() {
            var classProperty = _.get(vm.model, 'properties.class');
            vm.itemClasses = [];

            if (angular.isDefinedAndFilled(classProperty) && angular.isString(classProperty)) {
                vm.itemClasses = classProperty.replace(/\s+/g, '').split(',');
            }
        }

        /**
         * Convert the class array into the class string property.
         * Called during lx-select update.
         */
        function updateProperties() {
            var properties = _.get(vm.model, 'properties', {});

            properties.class = (vm.itemClasses || []).join(', ');

            if (angular.isFunction(vm.ngChange)) {
                vm.ngChange();
            }
        }

        /////////////////////////////

        vm.setProperties = setProperties;
        vm.updateProperties = updateProperties;
    }

    /////////////////////////////

    /**
     * Class selector directive.
     * Allows a user to select classes for widgets when multiple classes are defined in the config file.
     *
     * @param {Array}    availableChoices List of available classes.
     * @param {boolean}  [ngDisabled]     Whether the directive is disabled.
     * @param {string}   [help]           The helper text for this directive.
     * @param {string}   [label]          The label of the input field if there is no available choices.
     * @param {Object}   model            The model this directive is working with.
     * @param {Function} [ngChange]       The function to execute when the model is updated.
     */

    function ClassSelectorDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ClassSelectorController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                availableChoices: '=lsAvailableChoices',
                disabled: '=?ngDisabled',
                help: '@?lsHelp',
                label: '@?lsLabel',
                model: '=lsModel',
                ngChange: '&?lsChange',
            },
            templateUrl: '/client/common/modules/class-selector/views/class-selector.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsClassSelector', ClassSelectorDirective);
})();
