import React from 'react';

import { spacings } from '@lumapps/classnames';
import { Checkbox, CheckboxProps } from '@lumapps/lumx/react';

import { UseListFilter } from '../../hooks/useListFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

export interface CheckboxFilterProps<T> extends FilterProps {
    /** list of options to display as checkbox */
    options: T[];
    /** determines how to render the react node for the checkbox */
    renderCheckbox: (entity: T) => React.ReactNode;
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelected: UseListFilter<T>['onSelected'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    isInSelection: UseListFilter<T>['isInSelection'];
    /** custom props for the rendered checkbox */
    checkboxProps?: (entity: T) => Partial<CheckboxProps>;
    /** Callback to get the id for each filter.  */
    getId?: (entity: T) => string;
}

/**
 * Component that renders a checkbox filter, used in the `@lumapps/lumx-filter-and-sort` pattern.
 * Commonly used for showing boolean filters, such as whether an entity is active or not.
 *
 * @family Filters
 * @param CheckboxFilterProps
 * @returns CheckboxFilter
 */
export const CheckboxFilter = <T,>({
    options,
    renderCheckbox,
    onSelected,
    isInSelection,
    checkboxProps = () => ({}),
    getId,
    ...props
}: CheckboxFilterProps<T>) => {
    return (
        <Filter {...props} isFieldset>
            {options.map((option, index) => (
                <Checkbox
                    key={getId ? getId(option) : index}
                    className={spacings([
                        { type: 'margin', direction: 'right' },
                        { type: 'margin', direction: 'bottom' },
                    ])}
                    // Use onChange instead of onClick to allow label to be clickable
                    onChange={() => onSelected(option)}
                    isChecked={isInSelection(option)}
                    label={renderCheckbox(option)}
                    {...checkboxProps(option)}
                />
            ))}
        </Filter>
    );
};
