import { getAskAiAdminPath } from '@lumapps/ask-ai/utils';
import { Route, AppId, createEntityManagementRoutes } from '@lumapps/router';

import { AskAiRule } from './types';

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    slug: getAskAiAdminPath('rules'),
    id: 'ai-search-rules',
    appId: AppId.backOffice,
});

export const askAiEditRule = (rule: Pick<AskAiRule, 'id'>): Route => getEditRoute(rule.id);

export interface RuleParams {
    id: string;
}

export { baseRoute as askAiBaseRulesAdminRoute, createRoute as askAiCreateRuleRoute, editRoute as askAiEditRuleRoute };
