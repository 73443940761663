import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import trimStart from 'lodash/trimStart';
import { NANNY_FEATURE_CODE } from '@lumapps/nanny/constants';

/////////////////////////////

function AppController(
    $location,
    $rootScope,
    $scope,
    $state,
    $timeout,
    ActivePost,
    Config,
    ConfigTheme,
    Content,
    Customer,
    Features,
    Header,
    InitialSettings,
    Instance,
    Layout,
    Lightbox,
    LxDialogService,
    MainNav,
    Media,
    Style,
    Translation,
    Tutorial,
    User,
    UserDirectory,
    Utils,
) {
    'ngInject';

    const app = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The parent instance.
     *
     * @type {Object}
     * @constant
     */
    let _INSTANCE_PARENT;

    /**
     * The identifier of the user list dialog.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _USER_LIST_DIALOG_ID = 'user-list-dialog';

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The full list of CSS classes of the app.
     *
     * @type {Array}
     */
    app.classes = [];

    /**
     * Indicates if we want to use the mobile sidebar.
     * The mobile sidebar is used when we are in the 'palm' OR 'lap' breakpoint.
     *
     * @type {boolean}
     */
    app.displayMobileSidebar = Layout.breakpoint !== 'desk';

    /**
     * Indicates if the current instance displays the parent navigation,
     * in mobile we just display a link to the parent.
     *
     * @type {boolean}
     */
    app.hasMainNavInheritance = false;

    /**
     * Indicates if the user list dialog is currently being displayed and is in the DOM.
     *
     * @type {boolean}
     */
    app.isUserListDialogDisplayed = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    app.ActivePost = ActivePost;
    app.ConfigTheme = ConfigTheme;
    app.Content = Content;
    app.Customer = Customer;
    app.Features = Features;
    app.InitialSettings = InitialSettings;
    app.Instance = Instance;
    app.Layout = Layout;
    app.Lightbox = Lightbox;
    app.Translation = Translation;
    app.Tutorial = Tutorial;
    app.User = User;
    app.UserDirectory = UserDirectory;
    app.hasNannyFeature = Features.hasFeature(NANNY_FEATURE_CODE);
    app.isLayoutEnabled = Features.hasFeature('layout-v2');

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the instance parent logo.
     *
     * @return {string} The instance logo.
     */
    function _getParentInstanceLogo() {
        if (angular.isUndefinedOrEmpty(_INSTANCE_PARENT)) {
            return '';
        }

        let instanceLogo = '';

        const translatedLogoUrl = Translation.translate(_INSTANCE_PARENT.logoUrl);

        if (angular.isDefinedAndFilled(_INSTANCE_PARENT.logo)) {
            const logoObject = {};
            angular.forEach(_INSTANCE_PARENT.logo, function forEachLogoTranslations(logo) {
                logoObject[logo.lang] = logo;
            });

            const translatedLogo = Translation.translate(logoObject);

            if (angular.isUndefinedOrEmpty(translatedLogo)) {
                return '';
            }

            instanceLogo = angular.isDefinedAndFilled(translatedLogo.url) ? translatedLogo.url : translatedLogo.logo;

            instanceLogo = Utils.resizeImage(instanceLogo);
        } else if (angular.isDefinedAndFilled(translatedLogoUrl)) {
            instanceLogo = Utils.resizeImage(translatedLogoUrl);
        } else {
            instanceLogo = Utils.getMediaUrl(InitialSettings.PUBLIC_PATH_PREFIX + trimStart(Config.DEFAULT_LOGO, '/'));
        }

        return instanceLogo;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the app's CSS classes.
     *
     * @return {Array} The list of app's CSS classes.
     */
    function getAppClass() {
        Utils.empty(app.classes);

        const currentContent = Content.getCurrent();
        const viewMode = Content.getViewMode();
        if (
            Layout.isSidebarOpened &&
            Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER')
        ) {
            app.classes.push('app-content--is-pushed');
        }
        
        if (viewMode && viewMode !== 'get') {
            app.classes.push('app-content--edit-mode');
        }   

        if (
            Content.isCurrentDesignerMode('SIMPLE_LEGACY') ||
            Content.isDesignerInNewSimpleMode()
        ) {
            app.classes.push('app-content--basic-designer-mode');
        } else if (viewMode === 'simple') {
            app.classes.push('app-content--simple-designer-mode');
        } else if (viewMode === 'default') {
            app.classes.push('app-content--designer-mode');
        }

        const currentInstance = Instance.getInstance();

        if (angular.isDefinedAndFilled(currentInstance)) {
            if (angular.isUndefinedOrEmpty(currentInstance.parent)) {
                app.classes.push('app-content--is-main-instance');
            } else {
                app.classes.push('app-content--is-sub-instance');
            }
        }

        if (angular.isDefinedAndFilled(currentContent)) {
            if (currentContent.isHomepage) {
                app.classes.push('app-content--is-homepage');
            }

            const customClasses = get(
                currentContent,
                'properties.class',
                get(currentContent, 'template.properties.class'),
            );
            if (angular.isDefinedAndFilled(customClasses)) {
                Array.prototype.push.apply(app.classes, Utils.getCustomClass(customClasses, 'app-content--module-'));
            }
        } else if (app.isAdminView()) {
            app.classes.push('app-content--is-admin');
        }

        if ($state.is('app.front.search')) {
            app.classes.push('app-content--module-search');
        }

        if (Content.isDesignerInNewSimpleMode()) {
            app.classes.push('app-content--new-simple-template-experience');
        }

        return app.classes;
    }

    /**
     * Get the app's identifier.
     *
     * @return {string} The app's identifier.
     */
    function getAppId() {
        const currentContent = Content.getCurrent();

        return get(currentContent, 'properties.id', get(currentContent, 'template.properties.id'));
    }

    /**
     * Get the background of the mobile sidebar.
     * This is the first carousel media background-image.
     *
     * @return {Object} The mobile sidebar background.
     */
    function getMobileSidebarBackground() {
        const currentHeader = Header.getCurrent();

        const medias = get(currentHeader, 'properties.media');

        if (angular.isUndefinedOrEmpty(medias)) {
            return undefined;
        }

        const firstCarouselMedia = Translation.translate(Media.getMediaContent(currentHeader.properties.media[0]));
        if (angular.isDefinedAndFilled(get(firstCarouselMedia, 'value'))) {
            return Utils.getBackgroundImage(firstCarouselMedia.value);
        }

        return undefined;
    }

    /**
     * Check if the current is the admin.
     *
     * @return {boolean} Whether the current view is the admin or not.
     */
    function isAdminView() {
        return startsWith($state.current.name, 'app.admin');
    }

    /**
     * Get the current pathname.
     *
     * @return {string} current window.location.pathname
     */
    function getPathname() {
        return $location.path();
    }

    /////////////////////////////

    app.getAppClass = getAppClass;
    app.getAppId = getAppId;
    app.getPathname = getPathname;
    app.getMobileSidebarBackground = getMobileSidebarBackground;
    app.isAdminView = isAdminView;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the breakpoint of the layout goes to or from 'desk', display the mobile sidebar.
     *
     * @param {Event}  evt           The layout breakpoint change event.
     * @param {string} newBreakpoint The new computed breakpoint.
     * @param {string} oldBreakpoint The previous breakpoint.
     */
    $scope.$on('layout-breakpoint', (evt, newBreakpoint, oldBreakpoint) => {
        if (newBreakpoint === 'desk' || angular.isUndefinedOrEmpty(oldBreakpoint) || oldBreakpoint === 'desk') {
            app.displayMobileSidebar = true;
        }
    });

    /**
     * When the user list dialog is closed, remove it from the DOM.
     */
    $scope.$on('user-list-dialog__closed', () => {
        app.isUserListDialogDisplayed = false;
    });

    /**
     * When the user list dialog is opened, add it to the DOM and open it.
     *
     * @param {Event}  evt           The original event triggering the opening of the user list dialog.
     * @param {Object} [params]      An object of parameters to send to the request.
     * @param {string} [title]       A title for the dialog.
     * @param {string} [serviceName] The name of the service from which the users will be fetched.
     * @param {string} [methodName]  The name of the method to use to fetch the list.
     * @param {Object} [projection]  The list of attributes we want to retrieve.
     */
    $scope.$on('user-list-dialog__open', (evt, { params, title, serviceName, methodName, projection }) => {
        app.isUserListDialogDisplayed = true;

        $timeout(() => {
            Utils.waitForAndExecute(`#${_USER_LIST_DIALOG_ID}`, () => {
                LxDialogService.open(_USER_LIST_DIALOG_ID);
                $rootScope.$broadcast('user-list-dialog__filter', params, title, serviceName, methodName, projection);
            });
        });
    });

    /**
     * When the current language changes, reload the main nav.
     *
     * @param {Event}  evt     The current lang change event.
     * @param {string} newLang The new current language.
     */
    $scope.$on('user-settings-current-lang', (evt, newLang) => {
        MainNav.init();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const instance = Instance.getInstance();

        // Init languages before everything.
        Translation.initLanguages(instance);

        // Get the instance parent logo and link if the current instance inherites the parent navigation.
        app.hasMainNavInheritance = app.Features.hasMainNavInheritance();

        if (!app.hasMainNavInheritance) {
            return;
        }

        // Get the instance parent and its style.
        app.Instance.getCurrentInstanceParent(false).then((parentInstance) => {
            _INSTANCE_PARENT = parentInstance;

            app.instanceParentLogo = _getParentInstanceLogo();
            app.instanceParentLink = Instance.getUrl(_INSTANCE_PARENT);
            app.instanceParentStyle = Style.getInstanceParentStyle();
        });
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('AppController', AppController);

/////////////////////////////

export { AppController };
