import { createContext } from 'react';

export interface LumxFormContextOptions<T = any> {
    /** form default values */
    defaultFormValues?: T;
    /** form scope, for tracking purposes */
    scope?: string;
}

export const defaultContext: LumxFormContextOptions = {};

export const LumxFormContext = createContext<LumxFormContextOptions>(defaultContext);
