import { useCallback } from 'react';

import isObject from 'lodash/fp/isObject';

import { useSetUserContent } from '@lumapps/content-usercontent/hooks/useSetUserContent';
import { UserWidgetData } from '@lumapps/content-usercontent/types';

import { Values } from '../types';

export function useSetUserSurveyData(legacyWidgetId: string, onSuccess: () => void, onError: () => void) {
    const { mutate } = useSetUserContent(legacyWidgetId, onSuccess, onError);

    const saveUserSurveyData = useCallback(
        (value: string | Values) => {
            const userWidgetData: UserWidgetData = {
                answer: {
                    isMultiple: isObject(value),
                    type: 'choices',
                    value,
                },
                uuid: legacyWidgetId,
            };

            mutate(userWidgetData);
        },
        [legacyWidgetId, mutate],
    );

    return { saveUserSurveyData };
}
