import curryRight from 'lodash/fp/curryRight';

import { Day, WeekDay } from '../../types';
import { getDayFromDate } from '../days/getDayFromDate';

/**
 * Loop through the day of the week starting by the {startDay} and moving forward or backward based on the {direction} prop
 * until reaching the specified weekday {limit}.
 * Return all day's encountered
 * */
const getAllDaysUntilWeekday = (startDate: Date, limit: WeekDay, locale: string, direction: boolean) => {
    const currentDay = new Date(startDate);
    const days: Day[] = [];
    /**
     * Move backward on the Date object until reaching the first day of the week
     * Each date encounter should be added to the list, and returned.
     * */
    while (currentDay.getDay() !== limit) {
        currentDay.setDate(currentDay.getDate() + (direction ? +1 : -1));
        days.push(getDayFromDate(locale, new Date(currentDay)));
    }

    return days;
};

export const prevDaysUntilWeekday = curryRight(getAllDaysUntilWeekday)(false);
export const nextDaysUntilWeekday = curryRight(getAllDaysUntilWeekday)(true);
