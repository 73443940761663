(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetUserDirectorySearchController($scope, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Query object for the filters.
         *
         * @type {Object}
         */
        vm.queryFilter = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */

        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get widget class.
         *
         * @return {Array} An array containing all the classes to apply on the widget.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            return widgetClass;
        }

        /**
         * Check if the widget is empty.
         * The widget is empty until we select a user directory.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            return angular.isUndefinedOrEmpty(vm.widget.properties.directory);
        }

        /**
         * Check if the widget is hidden.
         * The widget is hidden if we aren't in the designer and there's no user directory selected.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetHidden() {
            if (!vm.parentCtrl.designerMode() && !vm.widget.properties.noResults && vm.isWidgetEmpty()) {
                vm.parentCtrl.isHidden = true;

                return true;
            }

            vm.parentCtrl.isHidden = false;

            return false;
        }

        /////////////////////////////

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.widget.properties = _.get(vm.widget, 'properties', {});
        }

        init();

        /**
         * Set widget parent controller.
         *
         * @param {Object} parentCtrl The controller to set as parent.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    function WidgetUserDirectorySearchDirective() {
        'ngInject';

        function widgetUserDirectorySearchLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetUserDirectorySearchController,
            controllerAs: 'vm',
            link: widgetUserDirectorySearchLink,
            replace: true,
            require: ['widgetUserDirectorySearch', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-user-directory-search/views/widget-user-directory-search.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetUserDirectorySearch', WidgetUserDirectorySearchDirective);
})();
