import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const campaignBaseRoute: Route = {
    path: '/admin/campaigns',
    appId: AppId.backOffice,
    legacyId: 'app.admin.campaigns',
};

export const campaignGlobalSettingsRoute: Route = {
    ...campaignBaseRoute,
    path: `${campaignBaseRoute.path}/settings`,
    legacyId: `${campaignBaseRoute.legacyId}-settings`,
};

export const campaignListRoute: Route = {
    ...campaignBaseRoute,
    path: `${campaignBaseRoute.path}/list`,
    legacyId: `${campaignBaseRoute.legacyId}-list`,
};

export interface CampaignListParams {
    title?: string;
    statuses?: string[];
    progressions?: string[];
}

export const campaignList = (query?: CampaignListParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    if (query?.statuses?.length) {
        queryParams.statuses = query.statuses.join(',');
    }

    if (query?.progressions?.length) {
        queryParams.progressions = query.progressions.join(',');
    }

    return {
        ...campaignListRoute,
        query: queryParams,
    };
};

export const campaignCreateRoute: Route = {
    ...campaignBaseRoute,
    path: `${campaignBaseRoute.path}/create`,
};

export enum CampaignViews {
    edit = 'edit',
    view = 'view',
    broadcasts = 'broadcasts',
}

export interface CampaignPageParams {
    id: string;
    view?: CampaignViews;
}

export const campaignPageRoute: Route = {
    ...campaignBaseRoute,
    path: `${campaignBaseRoute.path}/:id/:view?`,
};

export const campaignPage = (params: CampaignPageParams): Route => {
    return {
        ...campaignPageRoute,
        params: {
            id: params.id,
            view: params.view || CampaignViews.view,
        },
    };
};

export const campaignViewRoute: Route = {
    ...campaignPageRoute,
    params: {
        view: CampaignViews.view,
    },
};

export const campaignView = (params: Omit<CampaignPageParams, 'view'>): Route => {
    return {
        ...campaignViewRoute,
        params: {
            ...campaignViewRoute.params,
            id: params.id,
        },
    };
};

export const campaignEditRoute: Route = {
    ...campaignPageRoute,
    params: {
        view: CampaignViews.edit,
    },
};

export const campaignEdit = (params: Omit<CampaignPageParams, 'view'>): Route => {
    return {
        ...campaignEditRoute,
        params: {
            ...campaignEditRoute.params,
            id: params.id,
        },
    };
};

export const campaignBroadcastsRoute: Route = {
    ...campaignPageRoute,
    params: {
        view: CampaignViews.broadcasts,
    },
};

export const campaignBroadcasts = (params: Omit<CampaignPageParams, 'view'>): Route => {
    return {
        ...campaignBroadcastsRoute,
        params: {
            ...campaignBroadcastsRoute.params,
            id: params.id,
        },
    };
};
