import { getPosts, getPostByPostId } from '../api/WallApi';

const setContentIfContentUrlIsImage = (attm) => {
    if (!attm.contentUrl) {
        return attm.previewUrl;
    }

    const decodedUrl = new URL(attm.contentUrl);
    const ext = decodedUrl.pathname.split('.').pop();
    if (!ext) {
        return attm.previewUrl;
    }

    if (['bmp', 'gif', 'jpg', 'jpeg', 'png'].includes(ext)) {
        return attm.contentUrl;
    }

    return attm.previewUrl;
};

const setThumbnailImage = (item, url) => {
    item.attachments.hasImages = true;
    item.thumbnail = url;
};

const refactorPostAttachments = (item) => {
    if (!item.attachments) {
        return item;
    }

    item.attachments = { hasFiles: false, hasImages: false, items: item.attachments };

    for (let i = 0; i < item.attachments.items.length; ++i) {
        const attm = item.attachments.items[i];
        if (attm.previewUrl || attm.thumbnailUrl) {
            let url = item.thumbnail;

            if (!item.thumbnail) {
                if (attm.previewUrl) {
                    url = setContentIfContentUrlIsImage(attm);
                } else if (attm.thumbnailUrl) {
                    url = attm.thumbnailUrl;
                }
            }
            setThumbnailImage(item, url);
            break;
        }
    }

    return item;
};

const getPostsTask = (provider, spaceId, maxResults, messageType, nextPageCursor, channelId, orderBy, orderAsc) => {
    return getPosts(provider, spaceId, maxResults, messageType, nextPageCursor, channelId, orderBy, orderAsc).then(
        (response) => {
            if (response.data.items) {
                response.data.items = response.data.items.map((item) => refactorPostAttachments(item));
            } else {
                response.data.items = [];
            }

            return { payload: response.data };
        },
    );
};

const getPostByIdTask = (provider, spaceId, channelId, postId) => {
    return getPostByPostId(provider, spaceId, channelId, postId).then((response) => {
        if (response.data.item) {
            response.data.item = refactorPostAttachments(response.data.item);
        } else {
            response.data.item = {};
        }

        return { payload: response.data };
    });
};

export { getPostsTask, getPostByIdTask };
