/* istanbul ignore file */
import { Point } from './types';

/**
 * Get a canvas and return a promise with Blob object or null
 */
export const convertCanvasToBlob = async (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => canvas.toBlob(resolve));
};

export const areFocusPointDifferent = (focus1: Point, focus2: Point) => {
    return focus1.x !== focus2.x || focus1.y !== focus2.y;
};
