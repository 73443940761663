import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { isMetadataEditAllowed } from '../ducks/selectors';

export const useMetadataAdminRights = () => {
    const isGlobalAdmin = useSelector(isSuperAdmin);
    const isSiteAdmin = useSelector(isInstanceSuperAdmin);
    const hasMetadataEditRole = useSelector(isMetadataEditAllowed);

    const isAllowed: boolean = isGlobalAdmin || isSiteAdmin || hasMetadataEditRole;

    return { isAllowed };
};
