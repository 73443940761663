import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiDelete } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    FlexBox,
    Kind,
    Message,
    Orientation,
    Size,
    SkeletonRectangle,
    SkeletonTypography,
    Text,
    Theme,
    Typography,
} from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { ARTICLES } from '../../keys';
import { ArticlePreview as ArticlePreviewTypes } from '../../types';
import { ArticlePreview } from './ArticlePreview';

import './index.scss';

export interface ArticlePreviewWrapperProps {
    id: string;
    hasImage: boolean;
    hasDescription: boolean;
    isLoading: boolean;
    theme?: Theme;
    article: ArticlePreviewTypes;
    pushBatchIdArticle(articleId: string): void;
    fetchArticles(containerId?: string): void;
    isLegacy?: boolean;
    containerId?: string;
}

const CLASSNAME = 'article-preview';

export const ArticlePreviewWrapper = ({
    id,
    hasImage,
    hasDescription,
    isLoading = true,
    theme,
    article,
    pushBatchIdArticle,
    fetchArticles,
    containerId,
    isLegacy,
}: ArticlePreviewWrapperProps) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(CLASSNAME);

    useMemo(() => {
        if (isLegacy) {
            pushBatchIdArticle(id);
            fetchArticles(containerId);
        }
    }, [isLegacy, pushBatchIdArticle, id, fetchArticles, containerId]);

    if (isLoading) {
        return (
            <div className={block(`theme-${theme}`)}>
                {hasImage && (
                    <div className={element('thumbnail')}>
                        <SkeletonRectangle theme={theme} aspectRatio={AspectRatio.wide} />
                    </div>
                )}

                <FlexBox orientation={Orientation.vertical} className={element('container')}>
                    <SkeletonTypography theme={theme} typography={Typography.body2} />
                    <SkeletonTypography theme={theme} typography={Typography.subtitle2} />
                    {hasDescription && <SkeletonTypography theme={theme} typography={Typography.body1} />}
                    <div className={element('footer')}>
                        <SkeletonRectangle height={Size.xs} width={Size.xl} theme={theme} />
                    </div>
                </FlexBox>
            </div>
        );
    }

    if (!article || (article && article.status === 'deleted')) {
        return (
            <Message
                kind={Kind.info}
                hasBackground
                icon={mdiDelete}
                className={element('delete-info', theme)}
                {...get({ element: 'delete-info' })}
            >
                <Text as="p">{translateKey(ARTICLES.DELETED)}</Text>
            </Message>
        );
    }
    return <ArticlePreview theme={theme} article={article} isLegacy={isLegacy} />;
};
