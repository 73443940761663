import React from 'react';

import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { CommunityLinkRef } from '@lumapps/communities/types';
import { ColorPalette, Typography, Link } from '@lumapps/lumx/react';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { SharedByUser } from '@lumapps/widget-post-list/components/BlockPost/SharedByBlock/SharedByUser';

import { BlockStreamItemByResource } from '../../../types';

/**
 * ContextBanner for when a post has been shared. Reuses the SharedByUser block with additional info on community
 * when not in the context of a Space
 */
export const PostShareStreamContextBanner: React.FC<BlockStreamItemByResource<'post'>> = ({ userAction, theme }) => {
    const { user, actionDate, community, site } = userAction;

    const { isSpace } = useSpace();

    if (!community || !user || !site) {
        return null;
    }

    const communityLinkRef: CommunityLinkRef = {
        id: community.communityId,
        slug: community.slug,
        instance: {
            id: site.siteId,
            slug: site.slug,
        },
        renderingType: community.renderingType,
    };

    // If we're in space, do not display the destination (since the destination is the current space no need to display it)
    const sharedIn = isSpace ? undefined : (
        <Link
            to={communityLinkRef}
            key={community.name}
            linkAs={CommunityLink}
            color={ColorPalette.dark}
            typography={Typography.subtitle1}
            target="_blank"
        >
            {community.name}
        </Link>
    );

    return (
        <SharedByUser
            userAvatar={user.pictureUrl}
            userId={user.userId}
            fullName={user.fullName}
            sharedAt={actionDate}
            sharedIn={sharedIn}
            theme={theme}
        />
    );
};
