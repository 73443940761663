import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

/**
 * State of the A11Y live message reducer.
 */
export interface A11YLiveMessageState {
    /** The message to be read */
    message: string;
}

const initialState: A11YLiveMessageState = {
    message: '',
};

/**
 * The slice to manage the A11Y live state.
 */
const { actions, reducer } = createSlice({
    domain: 'a11y-message',
    initialState,
    reducers: {
        setA11YMessage: (state: A11YLiveMessageState, action: PayloadAction<string>) => {
            set(state, 'message', action.payload);
        },
    },
});

export { actions, reducer };
