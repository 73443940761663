import { Link } from '@lumapps/wrex-link/components/blocks/Link';
import { ListItem } from '@lumapps/wrex-list/components/blocks/ListItem';
import { OrderedList } from '@lumapps/wrex-list/components/blocks/OrderedList';
import { UnorderedList } from '@lumapps/wrex-list/components/blocks/UnorderedList';
import { LIST_FEATURES } from '@lumapps/wrex-list/constants';
import { CodeBlock } from '@lumapps/wrex-typography/components/blocks/CodeBlock';
import { Bold, Italic, Strikethrough } from '@lumapps/wrex-typography/components/blocks/Mark';
import { Paragraph } from '@lumapps/wrex-typography/components/blocks/Paragraph';
import { TYPOGRAPHY_FEATURES } from '@lumapps/wrex-typography/constants';
import { UserMention } from '@lumapps/wrex-user-mention/components/blocks/UserMention';

export const WIDGET_COMMENTS_TYPE = 'comments';

/** List of the plugins enabled features for the comment v1 editor */
export const commentV1EditorEnabledFeatures = {
    typography: [TYPOGRAPHY_FEATURES.bold, TYPOGRAPHY_FEATURES.italic],
    list: [LIST_FEATURES.unorderedList],
};

/** List of comment Wrex elements */
export const COMMENT_WREX_ELEMENTS = [Paragraph, Link, UnorderedList, OrderedList, ListItem, UserMention, CodeBlock];

/** List of comment Wrex marks */
export const COMMENT_WREX_MARKS = [Bold, Italic, Strikethrough];
