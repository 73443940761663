import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Link } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { Menu } from '../Menu';
import { MenuOptionBasic, MenuOption } from './types';

export interface MenuItemsProps {
    option: MenuOptionBasic;
    submenuRef: React.MutableRefObject<null>;
    className: string;
    onToggleSubMenuItemClick: (evt: React.MouseEvent, currentIndex: number) => void;
    handleSelect: (evt: React.MouseEvent) => void;
    renderMenuItems(menuOptionsArray: Array<MenuOption>, className?: string): React.ReactNode;
}

/**
 *
 * @param MenuItemsProps
 * @returns MenuItems
 */
export const MenuItems: React.FC<MenuItemsProps> = ({
    option,
    submenuRef,
    className,
    onToggleSubMenuItemClick,
    handleSelect,
    renderMenuItems,
}) => {
    const { icon } = option;
    const { iconPath } = useDynamicIcon({ icon });
    const { translateKey } = useTranslate();
    const { element } = useClassnames(className);

    // Option with children
    if (Boolean(option.otherProps?.isDisabled) === false && Boolean(option.isDisabled) === false && !!option.children) {
        return (
            <Menu.ItemSubmenu
                key={option.labelKey}
                ref={submenuRef}
                className={element('dropdown-item', 'has-children')}
                icon={icon === undefined ? option.iconStatic || option.icon : iconPath}
                isDisabled={option.isDisabled}
                label={translateKey(option.labelKey)}
                onClick={onToggleSubMenuItemClick}
                tooltipLabel={option.tooltipLabelKey && translateKey(option.tooltipLabelKey)}
                triggerProps={{
                    popoverProps: {
                        placement: 'bottom-end',
                    },
                }}
                {...option.otherProps}
            >
                {renderMenuItems(option.children, element('dropdown-item', 'is-children'))}
            </Menu.ItemSubmenu>
        );
    }

    // Regular options
    return option.isSelected !== undefined ? (
        <Menu.ItemCheckbox
            key={option.labelKey}
            className={className}
            icon={icon === undefined ? option.iconStatic || option.icon : iconPath}
            isDisabled={option.isDisabled}
            isChecked={option.isSelected}
            tooltipLabel={option.tooltipLabelKey && translateKey(option.tooltipLabelKey)}
            onClick={handleSelect}
            {...option.otherProps}
        >
            {translateKey(option.labelKey)}
        </Menu.ItemCheckbox>
    ) : (
        <Menu.Item
            key={option.labelKey}
            className={className}
            icon={icon === undefined ? option.iconStatic || option.icon : iconPath}
            as={option.route && Link}
            to={option.route}
            isDisabled={option.isDisabled}
            tooltipLabel={option.tooltipLabelKey && translateKey(option.tooltipLabelKey)}
            // Apply handleSelect even if route is set to allow stop propagation and close menu.
            onClick={handleSelect}
            {...option.otherProps}
        >
            {translateKey(option.labelKey)}
        </Menu.Item>
    );
};
