import React from 'react';

import { SelectFieldMultiple, SelectFieldMultipleProps } from '@lumapps/combobox/components/SelectFieldMultiple';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { DEFAULT_SCOPE_MULTIPLE } from '../../constants';
import { useMetadataPicker } from '../../hooks/useMetadataPicker';
import { PickerMetaData, MetadataPickerProps } from '../../types';

export interface MetadataPickerFieldMultipleProps extends MetadataPickerProps, Partial<SelectFieldMultipleProps> {
    /** The array of selected values */
    value?: Array<PickerMetaData['id']>;
    /**
     * Action on metadata select.
     * Returns an array of metadata object.
     */
    onChange(values?: Array<PickerMetaData>): void;
}

/**
 * A picker to select a multiple metadata.
 * Checkout the package's README.md for more info on how it works.
 *
 * * Unfortunately, we can't use the `GenericEntityPicker` here as it doesn't manage multiple values yet
 * * and we don't have time to do so at the moment of development.
 *
 * TODO: Harmonize the multiple / simple selects to have a coherent experience.
 */
export const MetadataPickerFieldMultiple = ({
    value,
    familyId,
    instanceId,
    onChange,
    defaultDictionary = {},
    extraRequestFields,
    scope = DEFAULT_SCOPE_MULTIPLE,
    useInstanceMetadata,
    showMultipleLevels,
    ...selectProps
}: MetadataPickerFieldMultipleProps) => {
    const {
        status,
        isOpen,
        metadataDictionary,
        searchText,
        setSearchText,
        filteredChoices,
        setIsOpen,
        handleSelect,
        getValueName,
        fetchMetadataFamily,
    } = useMetadataPicker({
        familyId,
        instanceId,
        defaultDictionary,
        onChange,
        multiple: true,
        extraRequestFields,
        useInstanceMetadata,
        showMultipleLevels,
    });

    const selectedIds = value && value.length > 0 ? value.map((id) => metadataDictionary[id]) : [];
    const getValueId = (metadata: PickerMetaData) => metadata?.id;
    const isLoading = status === BaseLoadingStatus.loading;

    return (
        <SelectFieldMultiple<PickerMetaData>
            value={selectedIds}
            onChange={handleSelect}
            isOpen={isOpen}
            setOpen={setIsOpen}
            searchText={searchText}
            onSearch={setSearchText}
            getValueId={getValueId}
            getValueName={getValueName}
            choices={filteredChoices}
            isLoading={isLoading}
            hasLoadingError={status === BaseLoadingStatus.error}
            onRetryLoading={fetchMetadataFamily}
            scope={scope}
            {...selectProps}
        />
    );
};
