(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserPickerService($rootScope, $timeout, LxDepthService) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The delay between the actual closing/opening of the picker and the sending of the event.
         *
         * @type {number}
         */
        var _EVENT_DELAY = 600;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close a user picker.
         *
         * @param {string} pickerId        The id of the user picker.
         * @param {Array}  [selectedUsers] The list of full users that have been picked.
         */
        function closePicker(pickerId, selectedUsers) {
            if (angular.isUndefinedOrEmpty(pickerId)) {
                return;
            }

            $rootScope.$broadcast('user-picker__close-start', pickerId, selectedUsers);

            angular.element('body').css({
                overflow: 'visible',
            });

            angular.element('#' + pickerId).removeClass('user-picker--is-shown');

            $timeout(function delayCloseEvent() {
                $rootScope.$broadcast('user-picker__close-end', pickerId, selectedUsers);
            }, _EVENT_DELAY);
        }

        /**
         * Open a user picker.
         *
         * @param {string} pickerId The id of the user picker.
         */
        function openPicker(pickerId) {
            if (angular.isUndefinedOrEmpty(pickerId)) {
                return;
            }

            LxDepthService.register();
            $rootScope.$broadcast('user-picker__open-start', pickerId);

            angular.element('body').css({
                overflow: 'hidden',
            });

            $timeout(function delayPickerShow() {
                angular.element('#' + pickerId)
                    .css('z-index', LxDepthService.getDepth())
                    .addClass('user-picker--is-shown');
            });

            $timeout(function delayOpenEvent() {
                $rootScope.$broadcast('user-picker__open-end', pickerId);
            }, _EVENT_DELAY);
        }

        /////////////////////////////

        service.close = closePicker;
        service.open = openPicker;
    }

    /////////////////////////////

    angular.module('Services').service('UserPicker', UserPickerService);
})();
