import React, { useMemo } from 'react';

import { mdiEmoticonOutline } from '@lumapps/lumx/icons';
import { EmojiPickerDialog } from '@lumapps/pickers/components/EmojiPickerDialog';
import { EmojiDetails } from '@lumapps/pickers/types';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { TypographyEditor } from '@lumapps/wrex-typography/types';
import { ReactEditor, Transforms, useSlate } from '@lumapps/wrex/slate';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { WREX_EMOJI } from '../../../keys';
import { EmojiEditor } from '../../../types';

export const useEmojiButton = (otherProps?: any) => {
    const editor = useSlate() as ReactEditor & EmojiEditor & TypographyEditor;
    const { translateKey } = useTranslate();

    const [isEmojiPickerOpen, , closeEmojiPicker, openEmojiPicker] = useBooleanState(false);
    const [currentAnchorRef, setCurrentAnchorRef] = React.useState<HTMLElement | null>(null);
    const isDisabled = !editor.isEmojiAllowed();

    const emojiButton = useMemo(() => {
        const onInsertEmoji = (emojiObject: EmojiDetails) => {
            Transforms.insertText(editor, emojiObject.unicode || emojiObject.emoji.unicode);
            ReactEditor.focus(editor);
            closeEmojiPicker();
        };

        const onEmojiButtonSelect = () => {
            if (!editor.selection) {
                initSelection(editor, 'inline');
            }
            setCurrentAnchorRef(
                ReactEditor.toDOMRange(editor, editor.selection || initSelection(editor, 'inline')).startContainer
                    .parentElement,
            );
            openEmojiPicker();
        };

        const onCancelEmojiPicker = () => {
            ReactEditor.focus(editor);
            closeEmojiPicker();
        };

        const insertEmojiPicker = (
            <EmojiPickerDialog
                isOpen={isEmojiPickerOpen}
                onEmojiClick={onInsertEmoji}
                onClose={onCancelEmojiPicker}
                anchorRef={{
                    current: currentAnchorRef,
                }}
            />
        );

        const emojiButtonProps: ToolbarItem = {
            itemKey: 'emoji',
            type: 'option',
            icon: mdiEmoticonOutline,
            tooltipLabel: translateKey(WREX_EMOJI.EMOJI_TOOLTIP),
            'aria-disabled': isDisabled,
            onClick: onEmojiButtonSelect,
            otherProps,
            verticalModeProps: {
                label: translateKey(WREX_EMOJI.EMOJI_BUTTON),
                tooltipLabel: undefined,
            },
        };

        return { insertEmojiPicker, emojiButtonProps };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeEmojiPicker, isEmojiPickerOpen, openEmojiPicker, isDisabled, translateKey, otherProps?.isDisabled]);

    return emojiButton;
};
