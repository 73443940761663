import debounce from 'lodash/debounce';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { Dispatch } from '@lumapps/redux';
import { stringify } from '@lumapps/utils/object/parse';

import * as api from '../api';
import { removePoundFromLabel } from '../components/utils';
import { TAGZ_NB_MAX_SUGGESTIONS } from '../constants';
import { Tag } from '../types';
import { actions } from './slice';

const fetchSuggestions = async (params: api.SuggestAPIParams, dispatch: Dispatch) => {
    try {
        const suggestions = await api.suggestTag(params);

        dispatch(actions.fetchTagSuggestions(suggestions));
    } catch (error) {
        dispatch(actions.fetchTagSuggestionsError());
    }
};

const debouncedFetchSuggestions = debounce(fetchSuggestions, DEBOUNCE_DELAY);

const changeSearchTagName = (query: string, tagz: Tag[]) => (dispatch: Dispatch) => {
    dispatch(actions.loadSuggestions(true));

    const cleanedQuery = removePoundFromLabel(query);

    // only get id of tag
    const tagzIds = tagz.map((tag) => tag.id);
    // even if the query is empty, we should fetch tagz to get trending tags
    debouncedFetchSuggestions(
        // Need to stringify the Array to have query param as ?tagz=["tag1", "tag2"] instead of ?tagz=tag1&tagz=tag2
        { query: cleanedQuery, params: { tagz: stringify(tagzIds, '[]'), limit: TAGZ_NB_MAX_SUGGESTIONS } },
        dispatch,
    );
};

export { changeSearchTagName, fetchSuggestions };
