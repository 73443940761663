import mdast from 'mdast';

import { DeserializeOptions } from '@lumapps/wrex/serialization/markdown/fromMarkdown/types';

import { createImage } from '../../utils/createImage';

export const IMAGE_ELEMENTS: DeserializeOptions['elements'] = {
    image: {
        convert: ({ url, alt, title }: mdast.Image) => createImage({ src: url, alt, title }),
        isStrictlyRootElement: true,
    },
};
