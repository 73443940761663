import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { DraggableList } from '@lumapps/lumx-lists/components/DraggableList';
import { mdiDrag } from '@lumapps/lumx/icons';
import { Badge, ColorPalette, FlexBox, Icon, Size, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WREX_IMAGE_GALLERY } from '../../keys';
import { ImageWithKey } from '../ImageGalleryDialog/type';

import './index.scss';

export interface ImageDraggableGridProps {
    /** The images array state */
    images: ImageWithKey[];
    /** Callback when an image is focused */
    onResourceFocus?: (index: number) => void;
    /** Callback when typing enter on an image */
    onResourceEnter?: () => void;
    /** Set the images array state (used to reorder the images) */
    setImages: React.Dispatch<React.SetStateAction<ImageWithKey[]>>;
    /** The images array as JSX elements (used to display the grid) */
    resourcesItems: JSX.Element[];
    /** The width of the grid */
    horizontalSize?: number;
    /** Set the images array as JSX elements (used when reordering the images) */
    setResourcesItems?: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
    /** Wether the first image should be focused on mount */
    focusFirstItemOnMount?: boolean;

    firstElementRef?: React.RefObject<HTMLElement>;
}

const CLASSNAME = 'image-draggable-grid';

/**
 * Component that displays a draggable grid of images.
 * The grid is used in the image gallery dialog to allow the user to change the order of the images.
 *
 * @param ImageDraggableGridProps
 * @returns ImageDraggableGrid
 */
export const ImageDraggableGrid: React.FC<ImageDraggableGridProps> = ({
    images,
    onResourceFocus,
    onResourceEnter,
    setImages,
    resourcesItems,
    horizontalSize,
    setResourcesItems,
    focusFirstItemOnMount,
    firstElementRef,
}) => {
    const { translateAndReplace } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    const onMove = (newItems: JSX.Element[], srcIndex: number, destIndex: number) => {
        setResourcesItems?.(newItems);
        // Reorder images
        const newImages = [...images];
        const [removed] = newImages.splice(srcIndex, 1);
        newImages.splice(destIndex, 0, removed);
        setImages(newImages);
        // Focus the moved image
        onResourceFocus?.(destIndex);
    };

    const onFocus = (index: number) => {
        onResourceFocus?.(index);
    };

    const itemRenderer = (
        item: JSX.Element,
        dragHandleProps: any,
        index: number,
        ref?: React.RefObject<HTMLElement> | ((value: HTMLElement | null) => void),
        onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void,
        isDragging?: boolean,
    ) => {
        return (
            <div
                {...dragHandleProps}
                role="button"
                className={element('image-wrapper', { 'is-dragging': !!isDragging })}
                tabIndex="0"
                ref={ref}
                onMouseDown={(event) => {
                    event.preventDefault();
                    event.currentTarget.focus();
                    onResourceFocus?.(index);
                }}
                onTouchStart={(event) => {
                    event.currentTarget.focus();
                    onResourceFocus?.(index);
                }}
                onFocus={(event) => {
                    event.preventDefault();
                    onResourceFocus?.(index);
                }}
                onKeyPress={(event) => {
                    event.preventDefault();
                    if (event.key === 'Enter') {
                        onResourceEnter?.();
                    }
                }}
                onKeyDown={(event) => {
                    onKeyDown?.(event);
                }}
                aria-describedby={null}
                aria-label={translateAndReplace(WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_IMAGE_ARIA_LABEL, {
                    INDEX: index + 1,
                })}
            >
                {isDragging ? (
                    <FlexBox className={element('image-dark-rectangle')} hAlign="center" vAlign="center">
                        <Icon
                            // eslint-disable-next-line lumapps/no-classname-strings
                            className="image-dark-rectangle__icon"
                            icon={mdiDrag}
                            size={Size.l}
                            color={ColorPalette.light}
                        />
                    </FlexBox>
                ) : (
                    <Badge className={element('badge')} color={ColorPalette.light}>
                        <Text as="span">{index + 1}</Text>
                    </Badge>
                )}
                {item}
            </div>
        );
    };

    return (
        <DraggableList
            className={block()}
            display={{ mode: 'grid', size: horizontalSize, itemsWidth: 200, itemsHeight: 200 }}
            keyPropName="key"
            droppableId="simple"
            items={resourcesItems}
            itemRenderer={itemRenderer}
            move={onMove}
            onFocus={onFocus}
            shouldEnsureIdUniqueness
            focusFirstItemOnMount={focusFirstItemOnMount}
            firstElementRef={firstElementRef}
        />
    );
};
