(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetMetaSettingsController($rootScope, $timeout, Widget) {
        'ngInject';

        var widgetMetaSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////


        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Update widget on settings change.
         */
        function updateWidget() {
            $timeout(function broadcastWidgetMetaSettings() {
                $rootScope.$broadcast('widget-meta-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetMetaSettings.updateWidget = updateWidget;
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetMetaSettingsController', WidgetMetaSettingsController);
})();
