import { PARAGRAPH } from '@lumapps/wrex-typography/constants';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { isHeadline } from '@lumapps/wrex-typography/utils/isHeadline';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { NodeEntry, Transforms, ReactEditor, isEditor } from '@lumapps/wrex/slate';
import { Wrex } from '@lumapps/wrex/types';

import { QuoteEditor, QuoteEditorOptions } from '../../types';
import { createQuote } from '../../utils/createQuote';
import { isQuoteBlock } from '../../utils/isQuoteBlock';

export const normalizeQuote = (
    [node, path]: NodeEntry,
    editor: ReactEditor & QuoteEditor,
    pluginOptions: QuoteEditorOptions,
) => {
    if (!isQuoteBlock(node)) {
        return false;
    }

    const { parentMatch = isEditor } = pluginOptions;
    const parentNode = editor.parent(path)[0] as Wrex.Element;

    // If quote block is not at root level and has not an allowed parent, then unwrap it.
    if (path.length > 1) {
        if (!parentMatch(parentNode)) {
            Transforms.unwrapNodes(editor, { at: path });
        }
    }

    if (node.children?.length === 0) {
        // Insert empty paragraph in quote block if empty.
        // A quote block should never be empty.
        Transforms.insertNodes(editor, createParagraph([]), { at: [...path, 0] });
    }

    // Build children
    const newChildren: Wrex.Nodes = [];
    let tempChildren: Wrex.Nodes = [];
    let hasBeenTransformed = false;
    // Iterate through quote block children
    // If we meet a non paragraph children, we want to wrap it in a paragraph.
    // If we met an heading, we will want to convert it into a paragraph, for now we ignore it
    node.children.forEach((child, i) => {
        if (isParagraph(child) || isHeadline(child) || child.type === 'title') {
            // if the current child is a paragraph
            if (tempChildren.length > 0) {
                // and tempChildren is not empty
                // then we wrap all temporary children in a paragraph
                const wrappedTempChildren = createParagraph(tempChildren);
                // and push this new paragraph in the new children of the quote block
                newChildren.push(wrappedTempChildren);
                // we also need to reset tempChildren
                tempChildren = [];
            }
            // In any case we push that child directly in the new children array
            newChildren.push(child);
        } else {
            // If we meet a non-paragraph child, then we set hasBeenTransformed to true
            // to tell that we must transform the quote block children
            hasBeenTransformed = true;
            // We push the current child in tempChildren, it will be added to a paragraph in a
            // future loop run.
            tempChildren.push(child);
            if (i === node.children.length - 1) {
                // if the current child is the last one of the quote block,
                // then we need to wrap all tempChildren in a paragraph and push
                // that paragraph into newChildren
                const wrappedTempChildren = createParagraph(tempChildren);
                newChildren.push(wrappedTempChildren);
            }
        }
    });

    if (hasBeenTransformed) {
        // If Quoteblock children had been transformed, ie. there was
        // a non-paragraph children, then we replace the old children by the
        // calculated ones.
        Transforms.removeNodes(editor, { at: path });
        Transforms.insertNodes(editor, createQuote(newChildren), { at: path });
    }

    // // Insert a paragraph element after quote block if there is not already one.
    // normalizeLastBlock(editor as ReactEditor, [node, path], (n) => {
    //     return isQuoteBlock(n) && parentMatch(parentNode);
    // });

    // Convert headings to paragraph
    Transforms.setNodes(editor, { type: PARAGRAPH } as Partial<Wrex.Element>, {
        at: path,
        match: (node) => isHeadline(node) || (node as Wrex.Element).type === 'title',
    });

    return false;
};
