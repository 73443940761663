/* eslint-disable default-case,padding-line-between-statements,sort-keys */
import produce from 'immer';

import { ADD_USERS, FETCH_USERS_ERROR, FETCH_USERS_LOADING, FETCH_USERS_SUCCESS } from './users_actions';

// Default state.
const defaultState = {
    list: {},
    loading: false,
};

const reducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        const { payload } = action;

        switch (action.type) {
            case FETCH_USERS_ERROR:
                draft.loading = false;

                break;
            case FETCH_USERS_LOADING:
                draft.loading = true;

                break;
            case ADD_USERS:
            case FETCH_USERS_SUCCESS: {
                const list = payload.list || [];

                draft.loading = false;
                list.forEach((user) => {
                    draft.list[user.id] = user;
                });
                break;
            }
        }
    });

export { reducer };
