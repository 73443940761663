/* eslint-disable angular/on-watch, lumapps/file-format */
(function IIFE() {
    'use strict';

    /////////////////////////////

    function LumsitesBaseServiceService($injector, $q, $state, BaseService, Translation) {
        'ngInject';

        // eslint-disable-next-line func-style
        var LumsitesBaseService = function LumsitesBaseService(Api, options) {
            BaseService.ListKeyService.call(this, Api, options);

            var service = this;
            if (angular.isDefined(service.defaultParams)) {
                service.defaultParams.lang = Translation.getPreferredContributionLanguage();
            }
        };
        var filtered = {};

        LumsitesBaseService.prototype = Object.create(BaseService.ListKeyService.prototype);

        /**
         * Get a list from the server by using a cache.
         *
         * @param  {Object}              [params]            The parameters of the query.
         * @param  {Function}            [cb]                The callback to execute at the successful end of the list.
         * @param  {Function}            [errCb]             The callback to call when the list is in error.
         * @param  {string}              [listKey='default'] The list key.
         * @param  {string|Object|Array} [projection]        The field we want to get in the result object.
         * @param  {boolean}             [wipeCache=false]   Indicates if you want to wipe the cache for this request.
         * @return {Promise}             The promise of the call to the server if any is done.
         */
        LumsitesBaseService.prototype.cacheFilterize = function cacheFilterize(
            params,
            cb,
            errCb,
            listKey,
            projection,
            wipeCache,
        ) {
            cb = cb || angular.noop;
            var layout = _.get($injector.get('Layout'), 'fromState');

            var list;
            if (!wipeCache) {
                list = this.getList(undefined, undefined, listKey, projection);
            }

            if (
                _.get($state, 'current.data.designer', false) ||
                _.get(layout, 'data.designer', false) ||
                _.includes(layout.name, 'app.admin') ||
                angular.isUndefinedOrEmpty(list)
            ) {
                return this.filterize(params, cb, errCb, listKey, projection);
            } else if (angular.isDefined(list)) {
                cb(list);
            }

            return undefined;
        };

        /**
         * Assigns computeProjection methods from SimpleService to LumsiteBaseService prototype. The binding
         * is mandatory, because this function calls other functions from the same SimpleService.
         */
        LumsitesBaseService.prototype.computeProjection = BaseService.SimpleService.prototype._computeProjection.bind(
            BaseService.SimpleService.prototype,
        );

        /**
         * Display a list.
         *
         * @param  {string}              listKey      The list key.
         * @param  {string|Array|Object} [projection] The projected fields.
         * @return {Promise}             The promise of the call to the server.
         */
        LumsitesBaseService.prototype.displayList = function displayList(listKey, projection) {
            return this.getList(undefined, undefined, listKey, projection);
        };

        /**
         * Display the plucked list.
         *
         * @param  {string} key  The list key identifier
         * @param  {string} attr The attribute to match in the object.
         * @return {Array}  The filtered/plucked list.
         */
        LumsitesBaseService.prototype.displayAttributeFromList = function displayAttributeFromList(key, attr) {
            key = key || 'default';

            if (angular.isArray(filtered[key])) {
                filtered[key].length = 0;
            } else {
                filtered[key] = [];
            }

            angular.forEach(this.displayList(key), function pluck(item) {
                // If item don't have attr, push undefined, so be careful with [ngRepeat:dupes].
                filtered[key].push(item[attr]);
            });

            return filtered[key];
        };

        /**
         * Display the next page.
         *
         * @param  {string}  listKey               The list key.
         * @param  {string}  [endpointName="list"] The name of the endpoint we want to use. This allows, for example,
         *                                         to use this function with the "search" endpoint instead of the
         *                                         default "list" one.
         * @param {Function} callback              The optional callback to execute once next page has displayed.
         */
        LumsitesBaseService.prototype.displayNextPage = function displayNextPage(listKey, endpointName, callback) {
            return this.nextPage(callback, undefined, listKey, endpointName);
        };

        /**
         * Get a list of items and be sure to have at least the minimum amount of wanted results by getting next page if
         * necessary.
         *
         * @param {Object}   [params]              The parameters of the `list` call.
         * @param {Function} [pageCb]              The callback to execute after each page has been retrieved. This
         *                                         callback will receive the response of the `list` endpoint for the
         *                                         current page.
         * @param {Function} [checkMinResults]     A function that will check if we reached the minimum wanted results.
         *                                         If no function is given, will compare the size of the received list
         *                                         with the `maxResults` parameters.
         * @param {Function} [allCb]               The callback to execute when all page have been retrieved.
         * @param {Function} [errCb]               The callback to execute whenever an error occurs.
         * @param {string}   [listKey]             The list key to pass to the service used as API.
         * @param {string}   [endpointName="list"] THe name of the endpoint we want to use. This allow, for example, to
         *                                         use this function with the "search" endpoint instead of the default
         *                                         "list" one.
         * @param {boolean}  [nextPage=false]      Indicates if we are loading another page than the first one.
         */
        LumsitesBaseService.prototype.getMinResults = function getMinResults(
            params,
            pageCb,
            checkMinResults,
            allCb,
            errCb,
            listKey,
            endpointName,
            nextPage,
        ) {
            var service = this;

            checkMinResults =
                checkMinResults ||
                function defaultCheckMinResults(response) {
                    var resultsNumber = _.get(response, 'length', 0);

                    return resultsNumber >= (params.originalMaxResults || params.maxResults);
                };
            allCb = allCb || angular.noop;
            errCb = errCb || angular.noop;

            var endpoint = nextPage ? 'nextPage' : 'filterize';

            // eslint-disable-next-line func-style
            var cb = function onListSuccess(response) {
                pageCb(response.splice(0, params.maxResults), !nextPage);

                if (!_.get(service, '_services[' + service._getListKey(listKey) + ']._more', false)) {
                    allCb();

                    return;
                }

                var missingResults = checkMinResults(response);
                if (missingResults <= 0) {
                    allCb();

                    return;
                }

                var newParams = angular.extend(angular.fastCopy(params), {
                    maxResults: missingResults,
                    originalMaxResults: nextPage ? params.maxResults : params.originalMaxResults,
                });
                service.getMinResults(newParams, pageCb, checkMinResults, allCb, errCb, listKey, endpointName, true);
            };

            if (nextPage) {
                service[endpoint](cb, errCb, listKey, endpointName);
            } else {
                service[endpoint](params, cb, errCb, listKey, undefined, endpointName);
            }
        };

        /**
         * Call a `list` (`filterize`) endpoint in loop until we have loaded all the pages.
         *
         * @param {Object}              [params]            The parameters of the `list` call.
         * @param {Function}            [pageCb]            The callback to execute after each page has been retrieved.
         *                                                  This callback will receive the response of the `list`
         *                                                  endpoint for the current page.
         * @param {Function}            [allCb]             The callback to execute when all page have been retrieved.
         * @param {Function}            [errCb]             The callback to execute whenever an error occurs.
         * @param {string}              [listKey]           The list key to pass to the service used as API.
         * @param {string|Object|Array} [projection]        The field we want to get in the result object.
         * @param {boolean}             [nextPage=false]    Indicates if we are loading another page than the first one.
         * @param {boolean}             [endpointName=list] Use another endpoint name than the default "list" one.
         */
        LumsitesBaseService.prototype.getAllPages = function getAllPages(
            params,
            pageCb,
            allCb,
            errCb,
            listKey,
            projection,
            nextPage,
            endpointName,
        ) {
            var service = this;

            pageCb = pageCb || angular.noop;
            allCb = allCb || angular.noop;
            errCb = errCb || angular.noop;

            var endpoint = nextPage ? 'nextPage' : 'filterize';

            // eslint-disable-next-line func-style
            var cb = function onListSuccess(allItems, currentPage) {
                pageCb(_.get(currentPage, 'items', []), allItems);

                if (_.get(service, '_services[' + service._getListKey(listKey) + ']._more')) {
                    service.getAllPages(params, pageCb, allCb, errCb, listKey, projection, true, endpointName);
                } else {
                    allCb(allItems);
                }
            };

            if (nextPage) {
                service[endpoint](cb, errCb, listKey, endpointName);
            } else {
                service[endpoint](params, cb, errCb, listKey, projection, endpointName);
            }
        };

        /**
         * Wrap the Base Service's `filterize` function inside of a Promise.
         *
         * @param  {Object}              params                The parameters of the filterize request.
         * @param  {string}              [listKey='default']   The list key of the request.
         * @param  {string|Array|Object} [projection]          The projection (see Base Service for more information).
         * @param  {string}              [endpointName='list'] The name of the endpoint you want to use with the
         *                                                     `filterize` call.
         * @return {Promise}             The promise of the filterize.
         */
        LumsitesBaseService.prototype.promiseFilterize = function promiseFilterize(
            params,
            listKey,
            projection,
            endpointName,
        ) {
            var service = this;

            return $q(function promisifyFilterize(resolve, reject) {
                service.filterize(params, resolve, reject, listKey, projection, endpointName);
            });
        };

        /**
         * Wrap the Base Service's `get` function inside of a Promise.
         *
         * @param  {Object}              params              The parameters of the get request.
         * @param  {string}              [listKey='default'] The list key of the request.
         * @param  {string|Array|Object} [projection]        The projection (see Base Service for more information).
         * @param  {boolean}             [setCurrent=false]  Indicates if you want to set the item as the current item
         *                                                   of the service (accessible via `getCurrent`).
         * @return {Promise}             The promise of the filterize.
         */
        LumsitesBaseService.prototype.promiseGet = function promiseGet(params, listKey, projection, setCurrent) {
            var service = this;

            return $q(function promisifyGet(resolve, reject) {
                service.get(params, resolve, reject, listKey, projection, setCurrent);
            });
        };

        function createLumsitesBaseService(Api, options) {
            return new LumsitesBaseService(Api, options);
        }

        return {
            createLumsitesBaseService,
            proto: LumsitesBaseService,
        };
    }

    /////////////////////////////

    angular.module('Services').service('LumsitesBaseService', LumsitesBaseServiceService);
})();
