/* istanbul ignore file */
import { Offset, Size } from '@lumx/react';

export const SIDENAV_ANIMATION_DELAY = 500;

/**
 * if this is changed, please modify the file in packages/lumx/core/scss/variables.scss as well
 */
export const ADMIN_SIDE_NAVIGATION_WIDTH = 280;

export const MAX_INLINE_ACTIONS = 3;

export const RIGHT_DROPDOWN_OFFSET: Offset = { along: -16, away: -16 };

export const REAL_SIZE_FOR_LUMX_SIZE = {
    [Size.xxs]: 14,
    [Size.xs]: 20,
    [Size.s]: 24,
    [Size.m]: 36,
    [Size.l]: 64,
    [Size.xl]: 128,
    [Size.xxl]: 256,
};
