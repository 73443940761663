import React, { ReactNode } from 'react';

import { ContainerBlockVariant } from '../types';

/**
 * Extract the ContainerBlockVariant from the widget children properties
 * */

const getVariant = ({ properties, variant }: { properties: any; variant: any }) => {
    /**
     * For the ClientComputed widgets, the variant is extracted from the "properties" key
     * */
    if (properties?.viewModeVariant) {
        return properties.viewModeVariant === 'ungroup'
            ? ContainerBlockVariant.ungrouped
            : ContainerBlockVariant.grouped;
    }

    return variant as string;
};

export const getBlockMeta = (children: ReactNode) => {
    if (React.isValidElement(children)) {
        return {
            variant: getVariant(children.props),
            type: children.props.type as string,
        };
    }
    return undefined;
};
