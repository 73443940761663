import React from 'react';

import { Image } from '@lumapps/lumx-images/types';
import { UploadDocument, UploadDocumentProps } from '@lumapps/medias-document/components/UploadDocument';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export type FormUploadDocumentFieldProps = Omit<UploadDocumentProps, 'onUploaded' | 'selected'> & BaseInput;

/**
 * Component that displays a field for uploading/editing an image inside a form context.
 * @family Forms
 * @param FormUploadDocumentFieldProps
 * @returns FormUploadDocumentField
 */
export const FormUploadDocumentField: React.FC<FormUploadDocumentFieldProps> = ({
    name,
    isRequired,
    isMultiLanguage,
    controllerProps,
    languagesFormat,
    onDocumentUploaded,
    onDocumentSelected,
    label,
    ...props
}) => {
    const { field, valueToUse, isFormLoading } = useFormField<Image>({
        name,
        controllerProps,
        isMultiLanguage,
        isRequired,
        languagesFormat,
        label,
    });

    const handleDocumentUploaded = (document?: Image) => {
        if (document && onDocumentUploaded) {
            onDocumentUploaded(document);
        }

        field.onChange(document ?? null);
    };

    return (
        <UploadDocument
            onDocumentUploaded={handleDocumentUploaded}
            onDocumentSelected={onDocumentSelected}
            selectedDocument={valueToUse}
            areActionsDisabled={isFormLoading}
            label={label}
            {...props}
        />
    );
};
