import { Route, AppId, createPageRoute } from '@lumapps/router';

export const instanceSettingsRoute: Route = createPageRoute({
    slug: 'site/global-settings',
    id: 'instance-settings',
    appId: AppId.backOffice,
});

export const instanceAdvancedSettingsRoute: Route = createPageRoute({
    slug: 'site/advanced-settings',
    id: 'instance-advanced-settings',
    appId: AppId.backOffice,
});
