/* istanbul ignore file */
import noop from 'lodash/noop';

import { get } from '@lumapps/constants';

import { RouterApi } from '../api';
import { createUrl } from '../utils';

let go: any = noop;

export function injectRouter(options: any) {
    go = options.go;
}

const { applicationId } = get();

export const angularApi: RouterApi = {
    getUrl: createUrl,
    redirect: (route, routerData, openInNewTab) => {
        const { appId, legacyId, params = {}, anchor, legacyForceReload, instanceSlug } = route;

        if (openInNewTab) {
            // Open in new tab.
            window.open(createUrl(route), undefined, 'noopener noreferrer');
            return;
        }

        if (anchor) {
            params['#'] = anchor;
        }

        if (appId === applicationId && !instanceSlug) {
            go(legacyId, params, { reload: legacyForceReload });
        } else {
            window.location.href = createUrl(route);
        }
    },
};
