import { useState, useEffect } from 'react';

const detectMobileOS = (): string => {
    const { userAgent } = navigator;

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
    }

    if (/webOS/.test(userAgent)) {
        return 'webOS';
    }

    if (/Opera Mini/.test(userAgent)) {
        return 'Opera Mini';
    }

    return '';
};

/**
 * This hook returns a string according to the mobileOS detected on the current device the app is executed on,
 *   - 'Android' if on an Android mobile
 *   - 'iOS' if on an Apple mobile device
 *   - empty string otherwise
 * to do this it relies on the userAgent exposed by the browser
 *
 * @return string the OS name if the current device is an Android or Apple mobile device, an empty string otherwise
 */
export const useDetectMobileOS = (): string => {
    const [mobileOS, setMobileOS] = useState('');

    useEffect(() => {
        const detectedOS = detectMobileOS();

        setMobileOS(detectedOS);
    }, []);

    return mobileOS;
};
