/* istanbul ignore file */
import { combineReducers } from '@lumapps/redux';

import * as ContentActionsSlice from './ContentActions/slice';
import { SAShareableContentsState } from './types';

export const reducer = combineReducers({
    contentActionsById: ContentActionsSlice.reducer,
});

export const initialState: SAShareableContentsState = {
    contentActionsById: ContentActionsSlice.initialState,
};

export const contentActionsActions = ContentActionsSlice.actions;
