import React from 'react';

import { mdiImage } from '@lumapps/lumx/icons';
import { UploadDocument, UploadDocumentProps } from '@lumapps/medias-document/components/UploadDocument';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { TUTORIALS_ADMIN } from '../../keys';

export interface SlideImageProps extends Partial<UploadDocumentProps> {
    /** callback to be executed once the image has been uploaded */
    onUploaded?: UploadDocumentProps['onDocumentUploaded'];
    /** already selected image */
    selected?: UploadDocumentProps['selectedDocument'];
}

/**
 * Component that manages the upload of a tutorial slide image
 *
 * @param SlideImageImageProps
 * @returns SlideImageImage
 */
export const SlideImageUpload: React.FC<SlideImageProps> = ({ onUploaded, selected, ...props }) => {
    const { translateKey, pluralize } = useTranslate();

    return (
        <UploadDocument
            {...props}
            thumbnailProps={{
                size: 'xxl',
                aspectRatio: 'horizontal',
            }}
            icon={mdiImage}
            size="xxl"
            onDocumentUploaded={onUploaded}
            selectedDocument={selected}
            label={pluralize(GLOBAL.IMAGE, 1)}
            uploaderLabel={translateKey(TUTORIALS_ADMIN.SLIDE_UPLOAD)}
            useBlobLocalStorage
        />
    );
};
