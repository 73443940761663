(function IIFE() {
    'use strict';

    /////////////////////////////

    function MediaFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/media/:methodKey/:actionKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            'emptyTrash': {
                isArray: false,
                method: 'DELETE',
                params: {
                    actionKey: 'empty',
                    methodKey: 'trash',
                },
            },

            'list': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            'saveMulti': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'saveMulti',
                },
            },

            'untrash': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'untrash',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('MediaFactory', MediaFactory);
})();
