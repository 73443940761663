import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const mediaTagsRoutes: Route = {
    path: '/admin/media-tags',
    legacyId: 'app.admin.media_tags',
    appId: AppId.backOffice,
};

/**
 * Retrieves the route for custom content type's admin.
 */

const adminTags = (): Route => {
    return mediaTagsRoutes;
};

export { adminTags, mediaTagsRoutes };
