import { useCallback } from 'react';

import { useSetUserContent } from '@lumapps/content-usercontent/hooks/useSetUserContent';
import { UserWidgetData } from '@lumapps/content-usercontent/types';

export function useSetDeleted(
    isDeletable: boolean | undefined,
    { legacyWidgetId, lastUuidEdit }: { legacyWidgetId: string; lastUuidEdit?: any },
) {
    const { mutate } = useSetUserContent(legacyWidgetId);

    const onDelete = useCallback(() => {
        const userWidgetData: UserWidgetData = {
            uuid: legacyWidgetId,
            active: false,
            params: { lastUuidEdit },
        };

        mutate(userWidgetData);
    }, [lastUuidEdit, legacyWidgetId, mutate]);

    return isDeletable ? onDelete : undefined;
}
