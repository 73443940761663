/** istanbul ignore file */
// TODO: ADD UNIT TESTS AND REMOVE IGNORE
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { BaseLoadingStatus } from '../types/BaseLoadingStatus';

/** The state of the hook  */
export interface FetchWithStatusState {
    // The current status.
    status: BaseLoadingStatus;
    // The params used for the last fetch.
    fetchParams: any | any[];
    // The stored response.
    response?: any;
    // The stored response in case of error.
    errorResponse?: any;
}

/** The hook's initial state */
const initialState: FetchWithStatusState = {
    status: BaseLoadingStatus.initial,
    fetchParams: [],
    response: undefined,
    errorResponse: undefined,
};

export const reducers = {
    /** Action to set the current status. */
    setStatus: (state: FetchWithStatusState, action: PayloadAction<BaseLoadingStatus>) => {
        set(state, 'status', action.payload);
    },
    fetch: (state: FetchWithStatusState, action: PayloadAction<any | any[]>) => {
        if (!action.payload.backgroundFetch) {
            set(state, 'status', BaseLoadingStatus.loading);
        }
        set(state, 'response', undefined);
        set(state, 'errorResponse', undefined);
        set(state, 'fetchParams', action.payload);
    },
    fetchMore: (state: FetchWithStatusState, action: PayloadAction<any | any[]>) => {
        if (!action.payload.backgroundFetch) {
            set(state, 'status', BaseLoadingStatus.loadingMore);
        }
        set(state, 'fetchParams', action.payload);
    },
    /**
     * Action when the fetch is successfull.
     *
     * Will store the response and set the status to idle.
     */
    onFetchSuccess: (state: FetchWithStatusState, action: PayloadAction<any>) => {
        set(state, 'response', action.payload);
        set(state, 'status', BaseLoadingStatus.idle);
    },
    updateResponse: (state: FetchWithStatusState, action: PayloadAction<any>) => {
        set(state, 'response', action.payload);
    },
    /**
     * Action on fetch failure.
     * Will set the status as error.
     */
    onFetchFailure: (state: FetchWithStatusState, action?: PayloadAction<any>) => {
        set(state, 'status', BaseLoadingStatus.error);
        set(state, 'errorResponse', action?.payload);
    },
    reset: () => initialState,
};

const { actions, reducer, domain } = createSlice({
    domain: 'FetchWithStatus',
    initialState,
    reducers,
});

export { actions, reducer, domain, initialState };
