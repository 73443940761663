export enum DIRECTORIES_TAGS {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451724
     */
    TAGS_SETTINGS = 'ADMIN_DIRECTORY_TAGS_SETTINGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451723
     */
    TAGS_NO_NAME = 'ADMIN_DIRECTORY_TAGS_NO_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244471
     */
    TAGS = 'ADMIN.NAV.DIRECTORY_TAGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451720
     */
    DIRECTORY_TAGS = 'ADMIN_DIRECTORY_TAGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452351
     */
    SELECTED_ITEMS = 'ADMIN_TAG_SELECTED_ITEMS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452352
     */
    SELECTED_ITEMS_DESCRIPTION = 'ADMIN_TAG_SELECTED_ITEMS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452353
     */
    SELECTED_ITEMS_SUCCESS = 'ADMIN_TAG_SELECTED_ITEMS_SUCCESS',
}
