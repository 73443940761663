/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import { classnames } from '@lumapps/classnames';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import {
    ThumbnailProps as ImageProps,
    Icon,
    IconProps,
    Size,
    Badge,
    IconSizes,
    Thumbnail as ThumbnailUI,
    ThumbnailSize,
    ThumbnailVariant,
    ColorPalette,
    AspectRatio,
    Tooltip,
} from '@lumapps/lumx/react';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import './index.scss';

export interface ThumbnailWithIconProps extends Partial<ImageProps>, Partial<IconProps> {
    /** custom classname for the thumbnail */
    className?: string;
    /** className to display when the thumbnail is displayed */
    imageClassname?: string;
    /** className to display only when the icon is displayed */
    iconClassName?: string;
    /** fallback icon that will be showed in case of error */
    fallbackIcon?: IconProps['icon'];
    /** whether the provided icon is dynamic or not */
    isDynamicIcon?: boolean;
    /** image to be displayed */
    image?: ImageProps['image'];
    /** size of the image or icon to be displayed */
    size?: ImageProps['size'];
    /** is fully rounded? */
    isRounded?: boolean;
}

const CLASSNAME = 'thumbnail-with-icon';

/**
 * Component that displays a thumbnail, usually representing an entity, with a small icon on the
 * right bottom side of it. Used for representing entities that have a thumbnail, while adding
 * an icon showing what type of entity it is.
 *
 * @family Images
 * @param props ThumbnailWithIconProps
 * @returns ThumbnailWithIcon
 */
export const ThumbnailWithIcon: React.FC<ThumbnailWithIconProps> = ({
    icon,
    image,
    size = Size.m,
    hasShape = true,
    alt,
    aspectRatio = AspectRatio.square,
    isRounded = false,
    imageClassName: propsImageClassName,
    iconClassName: propsIconClassName,
    isDynamicIcon = false,
    className,
    ...restOfProps
}) => {
    const { iconPath } = useDynamicIcon({ icon: isDynamicIcon ? icon : undefined });

    if (!icon && !image) {
        return null;
    }

    const iconPathToUse = isDynamicIcon ? iconPath : icon;

    const defaultClassNames = classnames(CLASSNAME, className, `${CLASSNAME}--${size}`);

    const imageClassName = classnames(defaultClassNames, `${CLASSNAME}--img`, propsImageClassName, {
        [`${CLASSNAME}--img-rounded`]: isRounded,
    });

    const iconClassName = classnames(defaultClassNames, `${CLASSNAME}--icon`, propsIconClassName);

    return (
        <Tooltip label={alt}>
            {image ? (
                <ThumbnailUI
                    image={image}
                    size={size as ThumbnailSize}
                    className={imageClassName}
                    alt={alt || ''}
                    variant={ThumbnailVariant.rounded}
                    aspectRatio={aspectRatio}
                    badge={
                        iconPathToUse && (
                            <Badge color={ColorPalette.grey}>
                                <Icon icon={iconPathToUse} />
                            </Badge>
                        )
                    }
                    imgProps={{
                        width: REAL_SIZE_FOR_LUMX_SIZE[size],
                        height: REAL_SIZE_FOR_LUMX_SIZE[size],
                    }}
                    {...restOfProps}
                />
            ) : (
                <Icon
                    className={iconClassName}
                    icon={iconPathToUse as string}
                    hasShape={hasShape}
                    size={size as IconSizes}
                    {...restOfProps}
                />
            )}
        </Tooltip>
    );
};
