import get from 'lodash/get';

import { ImageGroupElement } from '@lumapps/wrex-enhanced-image/types';
import { isImageGallery } from '@lumapps/wrex-enhanced-image/utils/isImageGallery';
import { Editor, Element, NodeEntry } from '@lumapps/wrex/slate';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';
import { WrexEditor } from '@lumapps/wrex/types';

import { TYPOGRAPHY_FEATURES } from '../constants';
import { TypographyEditor, TypographyEditorOptions } from '../types';

export const isTextAlignmentAllowed = (
    editor: WrexEditor<TypographyEditor>,
    options: TypographyEditorOptions,
): boolean => {
    if (!editor.selection) {
        return true;
    }

    if (!editor.isTypographyFeatureEnabled(TYPOGRAPHY_FEATURES.alignment)) {
        return false;
    }

    const entries = Array.from(
        Editor.nodes(editor, {
            match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
            mode: 'highest',
        }),
    );

    // Get type from first not entry.
    const firstNodeEntry = get(entries, [0]) as NodeEntry;

    if (
        !firstNodeEntry ||
        !entries.every(([node]) => isElement(node) && options.allowedAlignmentParents?.includes(node.type)) ||
        isImageGallery(true, firstNodeEntry[0] as ImageGroupElement)
    ) {
        // Heterogeneous block types.
        return false;
    }

    return true;
};
