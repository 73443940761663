import { Size, Typography } from '@lumapps/lumx/react';

export const ATTRIBUTE_TYPOGRAPHY = Typography.body1;
export const TITLE_TYPOGRAPHY = Typography.subtitle2;
export const EXCERPT_TYPOGRAPHY = Typography.body1;
export const THUMBNAIL_DEFAULT_SIZE = Size.l;
export const CLASSNAME = 'lumx-generic-entity-block';
export enum IMAGE_SHAPE {
    RECTANGLE = 'rectangle',
    CIRCLE = 'circle',
}
