/* istanbul ignore file */
import { Content } from '@lumapps/contents/types';
import { Post } from '@lumapps/posts/types';
import { TranslateObject } from '@lumapps/translations';
import { User } from '@lumapps/user/types';

/** The types of activity the v1 endpoint can return */
export enum LEGACY_ACTIVITY_ACTION_TYPE {
    create = 'create',
    update = 'update',
    comment = 'comment',
    like = 'like',
    profile = 'profile',
}

/** The content type of an activity, if applicable */
export enum ACTIVITY_CONTENT_TYPE {
    page = 'page',
    news = 'news',
    custom = 'custom',
    customList = 'custom_list',
    community = 'community',
    post = 'post',
    directory = 'directory',
}

interface ContentOrPostLegacyActivity {
    /** The number of activities */
    count: number;
    /** The number of user doing the same activity */
    userCount: number;
    /** The array of activities */
    items: Array<{
        id: string;
        contentKey: string;
        action: LEGACY_ACTIVITY_ACTION_TYPE;
        createdAt: string;
        createdByDetails: {
            id: string;
            firstName: string;
            lastName: string;
            fullName: string;
            profilePictureUrl: string;
        };
    }>;
}

type ContentDetails = Partial<Post | Content> & {
    excerpt: TranslateObject;
    id: string;
    thumbnail?: string;
    writerDetails: User;
    instance: string;
    updatedAt: string;
};

/** The Interface of the Activity Item returned by the v1 activity endpoint */
export interface LegacyActivity {
    id: string;
    contentDetails: ContentDetails;
    like: ContentOrPostLegacyActivity;
    create: ContentOrPostLegacyActivity;
    update: ContentOrPostLegacyActivity;
    comment: ContentOrPostLegacyActivity;
    profile: {
        count: number;
        items: Array<{
            id: string;
            action: LEGACY_ACTIVITY_ACTION_TYPE;
            createdAt: string;
            description: TranslateObject;
            createdByDetails: {
                id: string;
                firstName: string;
                lastName: string;
                fullName: string;
                profilePictureUrl: string;
            };
        }>;
    };
}
