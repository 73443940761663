import { Moment } from 'moment';

import { getMoment } from '../time/getMoment';

/**
 * Checks if the given date is under the given days threshold.
 */
export const isDateUnderThreshold = (date: string | Date | Moment, threshold: number) => {
    const moment = getMoment();

    const deltaOfDays = moment().diff(moment(date), 'days');

    return deltaOfDays < threshold;
};
