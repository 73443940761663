import { getAskAiAdminPath } from '@lumapps/ask-ai/utils';
import { Route, AppId, createEntityManagementRoutes } from '@lumapps/router';

import { AskAiConfiguration } from './types';

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    slug: getAskAiAdminPath('configurations'),
    id: 'ai-search-configurations',
    appId: AppId.backOffice,
});

export const askAiEditConfiguration = (configuration: Pick<AskAiConfiguration, 'id'>): Route =>
    getEditRoute(configuration.id);

export {
    baseRoute as askAiConfigurationsAdminRoute,
    editRoute as askAiEditConfigurationRoute,
    createRoute as askAiCreateConfigurationRoute,
};
