import React from 'react';

import { useListFilter, UseListFilterOptions } from '../../hooks/useListFilter';
import { useObjectFilter, UseObjectOptions } from '../../hooks/useObjectFilter';
import { useSingleSelectFilter, UseSingleSelectFilterOptions } from '../../hooks/useSingleSelectFilter';
import { useTextFilter, UseTextFilterOptions } from '../../hooks/useTextFilter';
import { FilterHookApi, FilterHookOptions } from '../../types';
import { CheckboxFilter, CheckboxFilterProps } from '../CheckboxFilter';
import { ChipFilter, ChipFilterProps } from '../ChipFilter';
import { DateFilter, DateFilterProps } from '../DateFilter';
import { MultipleSelectFilter, MultipleSelectFilterProps } from '../MultipleSelectFilter';
import { SingleChipFilter, SingleChipFilterProps } from '../SingleChipFilter';
import { SingleSelectFilter, SingleSelectFilterProps } from '../SingleSelectFilter';
import { TextFilter, TextFilterProps } from '../TextFilter';

export enum FILTER_TYPES {
    DATE = 'DATE',
    CHIP = 'CHIP',
    SINGLE_CHIP = 'SINGLE_CHIP',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    TEXT = 'TEXT',
    CHECKBOX = 'CHECKBOX',
}

export type Configs<T> =
    | Partial<ChipFilterProps<T>>
    | Partial<SingleChipFilterProps<T>>
    | Partial<DateFilterProps>
    | Partial<SingleSelectFilterProps<T>>
    | Partial<MultipleSelectFilterProps<T>>
    | Partial<CheckboxFilterProps<T>>
    | Partial<TextFilterProps>;

export type Defaults<T> =
    | UseListFilterOptions<T>
    | UseTextFilterOptions
    | UseObjectOptions<T>
    | UseSingleSelectFilterOptions<T>;

/**
 * Default structure of a filter configuration
 */
export interface FilterConfiguration {
    /**
     * type of component to use
     * Using `any` for now, still need to figure out how to use types properly here
     */
    Component: React.FC<any>;
    /**
     * hook associated to the component.
     */
    hook: (props: any) => any;
}

/** Interface for the configuration of the built-in filters  */
export interface DefaultFilterConfiguration extends FilterConfiguration {
    hook: (options?: FilterHookOptions) => FilterHookApi;
}

export const FILTERS: Record<FILTER_TYPES, DefaultFilterConfiguration> = {
    [FILTER_TYPES.DATE]: {
        Component: DateFilter,
        hook: useObjectFilter,
    },
    [FILTER_TYPES.CHIP]: {
        Component: ChipFilter,
        hook: useListFilter,
    },
    [FILTER_TYPES.SINGLE_CHIP]: {
        Component: SingleChipFilter,
        hook: useSingleSelectFilter,
    },
    [FILTER_TYPES.SINGLE_SELECT]: {
        Component: SingleSelectFilter,
        hook: useSingleSelectFilter,
    },
    [FILTER_TYPES.MULTIPLE_SELECT]: {
        Component: MultipleSelectFilter,
        hook: useListFilter,
    },
    [FILTER_TYPES.TEXT]: {
        Component: TextFilter,
        hook: useTextFilter,
    },
    [FILTER_TYPES.CHECKBOX]: {
        Component: CheckboxFilter,
        hook: useListFilter,
    },
};
