(function IIFE() {
    'use strict';

    /////////////////////////////

    function FloatingToolbarController($timeout, ConfigTheme) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains the promise of the timeout that will collapse the toolbar when the mouse leaves it.
         *
         * @type {Promise}
         */
        var _collapseTimeout;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the toolbar is collapsed.
         *
         * @type {boolean}
         */
        vm.collapsed = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.ConfigTheme = ConfigTheme;
        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Collapse the toolbar.
         * I.e. hide the labels of the buttons.
         */
        function collapse() {
            if (!angular.isNumber(vm.collapsable)) {
                return;
            }

            if (vm.collapsable === 0) {
                vm.collapsed = true;

                return;
            }

            _collapseTimeout = $timeout(function onMouseLeft() {
                vm.collapsed = true;
            }, vm.collapsable * 1000);
        }

        /**
         * Expand the toolbar.
         * I.e. display the labels of the buttons.
         */
        function expand() {
            vm.collapsed = false;

            $timeout.cancel(_collapseTimeout);
        }

        /////////////////////////////

        vm.collapse = collapse;
        vm.expand = expand;

        /////////////////////////////

        /**
         * Initialize controller.
         */
        function init() {
            vm.theme = vm.theme || 'dark';
            vm.collapsable = (angular.isUndefined(vm.collapsable)) ? 2 : vm.collapsable;
            vm.collapsable = (vm.collapsable === true) ? 2 : vm.collapsable;

            vm.collapse();
        }

        init();
    }

    /////////////////////////////

    /**
     * Displays the list of users for a community.
     *
     * @param {Array}          buttons         The buttons to display in the floating toolbar.
     *                                         This is an array of array. Each inner array representing a row of
     *                                         buttons.
     * @param {number|boolean} [collapsable=2] If true or a number, the time in second to wait before collapsing the
     *                                         toolbar when the mouse leaves it (instantly if 0).
     *                                         If anything else, then the toolbar will never collapse.
     * @param {string}         [theme="dark"]  The theme of the toolbar.
     *                                         Possible values are: "dark" or "light".
     */

    function FloatingToolbarDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: FloatingToolbarController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                buttons: '<lsButtons',
                collapsable: '<?lsCollapsable',
                theme: '@?lsTheme',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/common/modules/floating-toolbar/views/floating-toolbar.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsFloatingToolbar', FloatingToolbarDirective);
})();
