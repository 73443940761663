import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import type { Attributes } from '@lumapps/data-attributes';
import { Text, type TextProps, Theme } from '@lumapps/lumx/react';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';
import type { Playlist } from '../types';

import './Button.scss';

const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-button` as const;

interface ButtonProps {
    /** Data attributes for button actions */
    attributes?: Attributes;
    /** Component children */
    children?: React.ReactNode;
    /** Function called when the button is clicked */
    onClick: (playlist: Playlist) => void;
    /**
     * Control whether the text should truncate or not.
     * Setting as `true` will make the text truncate on a single line.
     * Setting as `{ lines: number }` will make the text truncate on a multiple lines.
     */
    truncate?: TextProps['truncate'];
}

/**
 * This component is a vanilla button with no style applied.
 * It is useful to bind other components and make them interactive.
 */
export const Button = ({ attributes, children, onClick, truncate }: ButtonProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { isLoading, playlist, theme } = useBlockPlaylistContext();

    /**
     * ✅ We don't want to return a button until the component has loaded
     * We simply return children which should take care of their skeletons
     */
    if (isLoading || !playlist) {
        return <Text as="span">{children}</Text>;
    }

    const title = typeof playlist.title === 'string' ? playlist.title : playlist.title.value;

    return (
        // eslint-disable-next-line react/forbid-elements
        <button
            {...attributes}
            className={block({
                dark: theme === Theme.light,
                light: theme === Theme.dark,
                'is-truncated': Boolean(truncate),
            })}
            type="button"
            onClick={() => onClick?.(playlist)}
        >
            <Text as="span" truncate={truncate}>
                {children
                    ? React.Children.map(
                          children,
                          (child) =>
                              React.isValidElement<{ isInteractive: boolean }>(child) &&
                              React.cloneElement(child, { isInteractive: true }),
                      )
                    : title}
            </Text>
        </button>
    );
};
