import { Attributes, DataAttributesOptions } from '../types';
import { stringifyPermissions } from './stringifyPermissions';

/**
 * Retrieves the different data attributes to be added to a certain HTML node
 * @param scope scope of the feature. This is usually the name of the feature or the package you are going to call this function from
 */
export const getAttributes =
    (scope: string) =>
    (options: DataAttributesOptions): Attributes => {
        const attributes: Attributes = {};

        if (options.element) {
            attributes['data-id'] = `${scope}-${options.element}`;

            if (options.action) {
                attributes['data-id'] = `${attributes['data-id']}-${options.action}`;
            }

            attributes['data-id'] = attributes['data-id'].toLowerCase();
        }

        if (options.isMobile) {
            attributes['data-device'] = 'responsive';
        } else if (options.isMobile === false) {
            attributes['data-device'] = 'desktop';
        }

        if (options.type) {
            attributes['data-type'] = options.type;
        }

        if (options.permissions) {
            attributes['data-permissions'] = stringifyPermissions(options.permissions);
        }

        if (options.position) {
            attributes['data-position'] = `${options.position}`;
        }

        if (options.count) {
            attributes['data-count'] = `${options.count}`;
        }

        return attributes;
    };

export { getAttributes as get };
