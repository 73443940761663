import { useEffect, useRef } from 'react';
/* eslint-disable @typescript-eslint/camelcase */
import { retrieveKeyFromServeImageUrl } from '@lumapps/medias/utils';
import { getImageUrl } from '@lumapps/lumx-images/components/BlobThumbnail';

import { PUBLIC_MODE_PATH_PREFIX } from './constants';

/**
 * Check if publicMode query string is present in the URL.
 * @return {boolean} Wether or not the string is present.
 */
const publicModeEnabled = () => {
    const { pathname } = window.location;

    return pathname.includes(PUBLIC_MODE_PATH_PREFIX);
};

/**
 * React hook to save previous state in a ref.
 * Usefull to compare props/state in between renders.
 * @param  {*}         value The value to be saved.
 * @return {React.ref} The saved ref.
 */
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

const getShareableImageUrl = (image) => {
    const imageKey = retrieveKeyFromServeImageUrl(image.url);
    const imgUrl = getImageUrl({
        id: imageKey,
        url: image.url,
    });

    return imgUrl;
};

/**
 * Clean an URL by removing the scheme, and resource path.
 *
 * @param  {string} url The url to clean.
 * @return {string} The cleaned url.
 */
const cleanURL = (url) => {
    try {
        const urlObject = new URL(url);
        if (urlObject.host && urlObject.host.length > 0) {
            return urlObject.host;
        }
    } catch (exception) {
        return url;
    }

    return url;
};

/**
 * Condition to return the customizable social networks.
 *
 * @param  {Object}  socialNetwork The social network to filer.
 * @return {boolean} Whether the item should be returned.
 */
const customizableSocialNetworksFilter = (socialNetworkId) => socialNetworkId !== 'facebook';

export { publicModeEnabled, usePrevious, cleanURL, customizableSocialNetworksFilter, getShareableImageUrl };
