/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { getMediaUrl } from '@lumapps/medias/utils';

(function IIFE() {
    function MediaBlockController(
        $scope,
        $window,
        Analytics,
        Community,
        Content,
        Document,
        Fsitems,
        InitialSettings,
        Lightbox,
        Media,
        MediaConstant,
        ModuleAdmin,
        Translation,
        Tag,
    ) {
        'ngInject';

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        // ///////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        // ///////////////////////////

        /**
         * Contains the media content corresponding to the media to display in the block.
         *
         * @type {Object}
         */
        let _mediaContent;

        // ///////////////////////////
        //                          //
        //    Public attributes     //
        //                          //
        // ///////////////////////////

        // ///////////////////////////

        /**
         * Services and utilities.
         */
        vm.Document = Document;
        vm.Media = Media;
        vm.MediaConstant = MediaConstant;

        // ///////////////////////////
        //                          //
        //    Private functions     //
        //                          //
        // ///////////////////////////

        /**
         * Download document.
         */
        function _downloadDocument(currentCommunity, source) {
            const currentContent = Content.getCurrent();

            Analytics.handleTaggingMap('download-media', 'download-media', {
                isHomePage: _.get(currentContent, 'isHomepage', false),
                item: Media.getMediaName(vm.media),
            });

            if (currentCommunity && source === MediaConstant.PROVIDERS.haussmann) {
                $window.open(getMediaUrl(vm.media.uuid), '_blank');
            } else {
                $window.open(vm.getMediaLink(), '_blank');
            }
        }

        /**
         * Only keep the tags that have a translated name in the current language.
         */
        function _initTags() {
            if (!vm.fieldsToDisplay.tags) {
                vm.tags = [];

                return;
            }

            const fullTags = _.map(vm.media.tags, function getFullTagForUid(uid) {
                return Tag.keyToTag('tag-admin', uid);
            });

            vm.tags = _.filter(fullTags, function filterNonTranslatedTagNames(tag) {
                return Translation.hasTranslation(_.get(tag, 'name'));
            });
        }

        /**
         * Open the lightbox.
         */
        function _openLightBox() {
            if (angular.isUndefinedOrEmpty(vm.imageList)) {
                Lightbox.open(vm.media);
                return;
            }

            const index = _.findIndex(vm.imageList, {
                id: vm.media.id,
            });

            if (index > -1) {
                Lightbox.open(vm.imageList, index);
            } else {
                Lightbox.open(vm.media);
            }
        }

        // ///////////////////////////
        //                          //
        //     Public functions     //
        //                          //
        // ///////////////////////////

        /**
         * Get the media icon according to its MimeType or extension.
         *
         * @return {string} The media icon.
         */
        function getMediaIcon() {
            if (vm.media.isFolder) {
                return 'folder';
            }
            // eslint-disable-next-line no-undef
            if (Media.getSource(vm.media) === 'drive' && angular.isDefinedAndFilled(vm.media.content)) {
                const firstMimeType = _.get(vm.media.content, '[0].mimeType');
                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(firstMimeType)) {
                    return Media.getIcon(firstMimeType);
                }

                let mediaExt = _.get(vm.media.content, '[0].ext', '');
                // eslint-disable-next-line no-undef
                if (angular.isUndefinedOrEmpty(mediaExt)) {
                    const mediaFromDriveSplitPath = _.get(vm.media.content, '[0].name', '').split('.');
                    mediaExt = _.last(mediaFromDriveSplitPath);
                }

                return Media.getMediaIconFromExt(mediaExt);
            }
            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(_.get(_mediaContent, 'mimeType'))) {
                return Media.getIcon(_mediaContent.mimeType);
            }
            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(_.get(_mediaContent, 'ext'))) {
                return Media.getMediaIconFromExt(_mediaContent.ext);
            }

            const url = _.get(vm.media.content, '[0].url', '');
            if (url) {
                const decodedUrl = new URL(url);
                const ext = decodedUrl.pathname.split('.').pop();
                return Media.getMediaIconFromExt(ext);
            }

            return 'default';
        }

        /**
         * Gets the media link.
         *
         * @return {Function|string} The media link.
         */
        function getMediaLink() {
            const mediaLink = _mediaContent.downloadUrl || _mediaContent.url || _mediaContent.servingUrl;
            // eslint-disable-next-line no-undef
            if (angular.isUndefinedOrEmpty(mediaLink)) {
                return undefined;
            }

            return mediaLink;
        }

        /**
         * Open the media.
         */
        function openMedia() {
            const url = vm.getMediaLink();
            Analytics.handleTaggingMap('open-media', 'open-media', {
                item: vm.media,
                name: Media.getMediaName(vm.media),
                url,
            });

            let source = Media.getSource(vm.media);
            const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());
            // eslint-disable-next-line no-undef
            if (angular.isUndefinedOrEmpty(source)) {
                source = MediaConstant.PROVIDERS.lumapps;
            }
            if (
                // eslint-disable-next-line no-undef
                angular.isDefinedAndFilled(_mediaContent) &&
                Media.isImage(_mediaContent.type) &&
                (source === MediaConstant.PROVIDERS.lumapps || source === MediaConstant.PROVIDERS.haussmann)
            ) {
                _openLightBox();
            } else if (
                // eslint-disable-next-line no-undef
                angular.isDefinedAndFilled(_mediaContent) &&
                Media.isDocument(_mediaContent.type) &&
                (source === MediaConstant.PROVIDERS.lumapps || source === MediaConstant.PROVIDERS.haussmann)
            ) {
                _downloadDocument(currentCommunity, source);
            } else if (source === MediaConstant.PROVIDERS.google || source === MediaConstant.PROVIDERS.microsoft) {
                const driveUrl =
                    _.get(_mediaContent, 'url') ||
                    _.get(_mediaContent, 'downloadUrl') ||
                    _.get(_mediaContent, 'servingUrl');
                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(driveUrl) && angular.isString(driveUrl)) {
                    $window.open(driveUrl, '_blank');
                }
            } else if (
                // eslint-disable-next-line no-undef
                angular.isDefinedAndFilled(_mediaContent) &&
                // eslint-disable-next-line no-undef
                angular.isDefinedAndFilled(url)
            ) {
                $window.open(url, '_blank');
            }
        }

        // ///////////////////////////

        vm.getMediaIcon = getMediaIcon;
        vm.getMediaLink = getMediaLink;
        vm.openMedia = openMedia;

        // ///////////////////////////
        //                          //
        //          Events          //
        //                          //
        // ///////////////////////////

        /**
         * When the user changes its lang, re-initialize the media block.
         *
         * Todo [Arnaud]: do we really need this since we reload the page when we save?
         */
        $scope.$on('user-settings-current-lang', function onUserSettingsCurrentLangChanges() {
            vm.init();
        });

        // ///////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            // Todo [Arnaud]: refactor this to be more like the folder block so we can compute classes in the parent.
            // eslint-disable-next-line no-undef
            vm.hasSeparator = angular.isDefined(vm.classes)
                ? _.includes(vm.classes, 'media-block--has-separator')
                : true;
            vm.style = vm.style || {};

            _mediaContent = Media.getMediaContent(vm.media, Translation.inputLanguage, !vm.noFallback);

            const resize = vm.viewMode === 'block' ? InitialSettings.MAX_IMAGE_SIZE : undefined;

            vm.mediaImage = Document.getCroppedThumbnailFromMedia(vm.media, true, resize, Translation.inputLanguage);

            vm.focusPoint = Document.getFocusPointFromMedia(vm.media, false, Translation.inputLanguage);

            vm.isFromDrive = Document.isMediaFromGoogle(vm.media) || Document.isMediaFromMicrosoft(vm.media);

            vm.fieldsToDisplay = vm.fieldsToDisplay || Fsitems.getDefaultFieldsToDisplay();

            _initTags();
        };

        vm.init();
    }

    // ///////////////////////////

    /**
     * The media block.
     * Displays a media.
     *
     * @param {Array}   [classes]               A list of css classes to apply to the block.
     *                                          Note: currently, this is only used to determine if we should display a
     *                                          separator between items or not.
     * @param {Object}  [fieldsToDisplay=<all>] A map of fields and their visibility in the block.
     * @param {string}  [height]                The media height useful when using img tag.
     * @param {Array}   imageList               Contains the list the media to display belongs to (to use with the
     *                                          lightbox).
     * @param {Object}  media                   The media to display in the block.
     * @param {boolean} [noFallback=false]      Indicates if we don't want to use a fallback language or not.
     * @param {Object}  [overrides]             Contains the overrides for the media.
     * @param {Object}  [style]                 Style properties to be applied to the folder block.
     * @param {string}  [theme="light"]         The theme to use to display the media block.
     *                                          Possible values are "light" or "dark".
     * @param {string}  [useImgTag=false]       Indicates if we want to display the media in a `src` HTML tag or in a
     *                                          `div` with CSS.
     * @param {string}  [viewMode="list"]       The view mode to use to display the media block.
     *                                          Possible values are "list" or "block".
     */

    function MediaBlockDirective() {
        return {
            bindToController: true,
            controller: MediaBlockController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                canManage: '<?lsCanManage',
                classes: '<?lsClasses',
                fieldsToDisplay: '<?',
                shouldLimitSize: '@?',
                height: '@?',
                imageList: '<',
                media: '<',
                noFallback: '<?',
                overrides: '<?',
                reloadFn: '&lsReloadFn',
                style: '<?lsStyle',
                theme: '@?',
                useImgTag: '=?',
                viewMode: '@?',
            },
            templateUrl: '/client/front-office/modules/media/views/media-block.html',
        };
    }

    // ///////////////////////////
    // eslint-disable-next-line no-undef
    angular.module('Directives').directive('mediaBlock', MediaBlockDirective);
})();
