export enum ARTICLES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107548925
     */
    DIALOG_REQUIRED_FIELD = 'ARTICLES.DIALOG_REQUIRED_FIELDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96740005
     */
    EDIT_ARTICLE = 'FRONT.ARTICLES.DIALOG.EDIT_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=374428153
     */
    DELETE_ARTICLE = 'FRONT.ARTICLES.DIALOG.DELETE_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96739984
     */
    NEW_ARTICLE = 'FRONT.ARTICLES.DIALOG.NEW_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86667895
     */
    CONTENT_PLACEHOLDER = 'FRONT.ARTICLES.DIALOG.CONTENT_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86666578
     */
    TITLE_PLACEHOLDER = 'FRONT.ARTICLES.DIALOG.TITLE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95927119
     */
    PUBLISH_CHANGES = 'FRONT.ARTICLES.DIALOG.PUBLISH_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94537262
     */
    DELETE_CONFIRM_DIALOG_TITLE = 'FRONT.ARTICLES.DELETE.CONFIRM_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104430602
     */
    DELETE_CONFIRM_DIALOG_CANCEL = 'FRONT.ARTICLES.DELETE.CONFIRM_DIALOG.CANCEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104430687
     */
    DELETE_CONFIRM_DIALOG_OK = 'FRONT.ARTICLES.DELETE.CONFIRM_DIALOG.OK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94537383
     */
    DELETE_CONFIRM_DIALOG_DESCRIPTION = 'FRONT.ARTICLES.DELETE.CONFIRM_DIALOG.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=94967052
     */
    DELETE_SUCCESS_NOTIFICATION = 'FRONT.ARTICLES.DELETE.SUCCESS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300756750
     */
    PUBLISHED_AN_ARTICLE = 'STREAMS.PUBLISHED_AN_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=349855093
     */
    PUBLISHED_AN_ARTICLE_IN_CONTAINER = 'STREAMS.PUBLISHED_AN_ARTICLE_IN_CONTAINER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183770581
     */
    ARTICLE_PICKER_TITLE = 'ARTICLE.ARTICLE_PICKER_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=171996342
     */
    DELETED = 'ARTICLES.DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355281374
     */
    SELECT_ARTICLES = 'ARTICLES.SELECT_ARTICLES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=357755024
     */
    SELECTED_ARTICLES_COUNT = 'ARTICLES.SELECTED_ARTICLES_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389091212
     */
    ARTICLE_ANALYTICS = 'ARTICLES.ARTICLE_ANALYTICS',
}
