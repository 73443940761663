import { useEffect, useMemo } from 'react';

import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { FormattedSiteFilter, SiteFilteredBy } from '../types';

interface UseUpdateSitesProps {
    siteReferences: FormattedSiteFilter[];
    selectedSites: Partial<SiteFilteredBy>[];
    handleSitesFilter: (site: FormattedSiteFilter[]) => void;
}

/**
 * This hook is used to update the selected sites
 * - Remove all sites that are no longer available in the siteReferences
 * - Consolidate data for sites coming from the URL (with ID only)
 * */
export const useUpdateSites = ({ siteReferences, selectedSites, handleSitesFilter }: UseUpdateSitesProps) => {
    /**
     * We need to perform an update if :
     * - The "id" is undefined (should remove the site)
     * - The "name" is undefined (should update the site)
     * - The "id" is not present in the site references (should remove the outdated site)
     * */
    const hasSiteToUpdate = useMemo(
        () =>
            selectedSites.some(
                ({ name, id }) =>
                    isEmpty(name) ||
                    isEmpty(id) ||
                    !siteReferences.map(({ id: refId }) => refId).includes(id as string),
            ),
        [selectedSites, siteReferences],
    );

    useEffect(() => {
        if (hasSiteToUpdate) {
            handleSitesFilter(
                compact(
                    selectedSites.map(({ id }) => {
                        const updateSite = siteReferences.find((site) => site.id === id);
                        return updateSite ? { ...updateSite } : undefined;
                    }),
                ),
            );
        }
    }, [handleSitesFilter, hasSiteToUpdate, selectedSites, siteReferences]);
};
