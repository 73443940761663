import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { CommunityLinkRef } from '@lumapps/communities/types';
import type { Instance } from '@lumapps/instance/types';
import { Link as RouterLink } from '@lumapps/router';
import { CommunityInfo } from '@lumapps/widget-base/types';

import { eventView } from '../routes';

/** Get link component & props to link to the event view page */
export const getEventLink = (
    eventId: string,
    communityInfo?: CommunityInfo,
    anchor?: string,
    instance?: Partial<Pick<Instance, 'id' | 'slug'>>,
) => {
    if (communityInfo) {
        const to: CommunityLinkRef = {
            id: communityInfo.communityId,
            slug: communityInfo.slug,
            renderingType: communityInfo.renderingType,
            view: 'event',
            identifier: eventId,
            anchor,
            instance,
        };
        return { linkAs: CommunityLink, to };
    }
    return { linkAs: RouterLink, to: eventView(eventId, anchor, instance?.slug) };
};
