/* istanbul ignore file */

import { ManagedStreamResources, StreamActions } from '../../../types';
import { GenericStreamContextBanner } from './GenericStreamContextBanner';
import { PostShareStreamContextBanner } from './PostShareStreamContextBanner';

export const streamContextBannerBlockByResourceTypeAndAction: {
    [Resource in ManagedStreamResources]?: {
        [userAction in StreamActions]?: React.FC<any>;
    };
} = {
    content: {
        new_comment: GenericStreamContextBanner,
    },
    post: {
        new_share: PostShareStreamContextBanner,
        new_comment: GenericStreamContextBanner,
    },
    community: {
        new_follow: GenericStreamContextBanner,
    },
    event: {
        new_comment: GenericStreamContextBanner,
        new_attendee: GenericStreamContextBanner,
    },
    article: {
        new_comment: GenericStreamContextBanner,
    },
    learning: {
        new_enrollment: GenericStreamContextBanner,
    },
};
