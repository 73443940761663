import { getMoment } from '../time/getMoment';

const moment = getMoment();
/**
 * Returns the raw date in a formatted fashion. By default, it converts
 * the date to Month XX, XXXX, but it can be changed using the `format` parameter.
 * You can also prevent from changing the date to a local version of it to avoid returning
 * a wrong date in US (for example) when giving an ISO date (ie YYYY-MM-DD)
 * by giving `false` to the optional `isLocal` third parameter.
 * This way, you keep the exact same date and only format it.
 * @param date
 * @param format
 * @param isLocal
 * @returns string with the date formatted
 */
export const formatDate = (date: string | Date, format = 'LL', isLocal = true) => {
    return isLocal ? moment.utc(date).local().format(format) : moment.utc(date).format(format);
};
