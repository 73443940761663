import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { CAMPAIGN_ICON } from '../../constants';
import { canAccessCampaign } from '../../ducks/selectors';
import { CAMPAIGNS } from '../../keys';
import { campaignListRoute } from '../../routes';

const CampaignSideNavigation = (props: Partial<SideNavigationItemProps>) => {
    const isAllowed = useSelector(canAccessCampaign);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            icon={CAMPAIGN_ICON}
            label={translateKey(CAMPAIGNS.TITLE)}
            route={campaignListRoute}
            {...props}
        />
    );
};

CampaignSideNavigation.displayName = 'SideNavigationItem';

export { CampaignSideNavigation };
