import React from 'react';

import { isSecuredMediaEnabled } from '@lumapps/communities/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiGoogleDrive, mdiImageMultiple, mdiLaptop, mdiMicrosoftSharepoint } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { accountTypeSelector } from '@lumapps/user/ducks/selectors';
import { UserAccountType } from '@lumapps/user/types';
import { WREX_IMAGE } from '@lumapps/wrex-image/keys';
import { ToolbarItem } from '@lumapps/wrex/types';

// eslint-disable-next-line lumapps/do-not-import-legacy-code, import/no-relative-packages
import { open as openMediaPicker } from '../../../../apps/legacy/client/components/services/media-picker';
import { MEDIAS } from '../keys';
import { Media, MediaPickerParams, MediaPickerSources, MediaSource } from '../types';

interface UseMediaPickerOptionsProps {
    /** Files that will already be selected on the media picker */
    currentFiles?: Media[];
    /** Whether Drive is allowed in the media picker. */
    isDriveAllowed?: boolean;
    // Whether we are in a community context or not.
    isInCommunityContext?: boolean;
    /** Whether Lumdrive is allowed in the media picker. */
    isLumdriveAllowed?: boolean;
    /** Params of the media picker */
    mediaPickerParams?: MediaPickerParams;
    /** On media change callback */
    onMediaChange?(medias: Media[] | Media): void;
    /** On upload from computer callback. */
    onUploadFromComputer(): void;
}

const DRIVE_ITEM_INFOS = {
    [UserAccountType.GOOGLE]: {
        icon: mdiGoogleDrive,
        key: MEDIAS.FROM_GOOGLE_DRIVE,
    },
    [UserAccountType.MICROSOFT]: {
        icon: mdiMicrosoftSharepoint,
        key: MEDIAS.FROM_MICROSOFT_SHAREPOINT,
    },
};

/**
 * List media picker options depending on the current context and options.
 */
export const useMediaPickerOptions = ({
    currentFiles,
    isDriveAllowed,
    isInCommunityContext,
    isLumdriveAllowed,
    mediaPickerParams,
    onMediaChange,
    onUploadFromComputer,
}: UseMediaPickerOptionsProps) => {
    const { get } = useDataAttributes('media-picker-dropdown');
    const { translateKey } = useTranslate();

    const accountType = useSelector(accountTypeSelector);
    const isSecuredStorageEnabled = useSelector(isSecuredMediaEnabled);

    return React.useMemo(() => {
        function onOpenFilePicker(source: MediaPickerSources) {
            if (isSecuredStorageEnabled && isInCommunityContext) {
                let allowedProviders = mediaPickerParams?.allowedProviders;
                if (allowedProviders) {
                    if (source === MediaSource.LUMDRIVE) {
                        allowedProviders = allowedProviders.filter(
                            (item: MediaSource) => item !== MediaSource.GOOGLE_DRIVE && item !== MediaSource.MICROSOFT,
                        );
                    } else if (source === MediaSource.GOOGLE_DRIVE) {
                        allowedProviders = allowedProviders.filter(
                            (item: MediaSource) => item !== MediaSource.LUMDRIVE && item !== MediaSource.MICROSOFT,
                        );
                    } else if (source === MediaSource.MICROSOFT) {
                        allowedProviders = allowedProviders.filter(
                            (item: MediaSource) => item !== MediaSource.LUMDRIVE && item !== MediaSource.GOOGLE_DRIVE,
                        );
                    }
                }
                const params = { ...mediaPickerParams, allowedProviders };
                openMediaPicker(currentFiles, onMediaChange, params);
            } else {
                openMediaPicker(currentFiles, onMediaChange, mediaPickerParams);
            }
        }

        const options: ToolbarItem[] = [
            /**
             * Upload from computer
             */
            {
                type: 'option',
                itemKey: 'media-from-computer',
                onClick: onUploadFromComputer,
                icon: mdiLaptop,
                label: translateKey(WREX_IMAGE.FROM_COMPUTER),
                otherProps: { ...get({ element: 'upload-from-computer', action: 'open-computer-file-picker' }) },
            },
        ];

        /**
         * Pick from LumDrive
         */
        if (openMediaPicker && isLumdriveAllowed) {
            options.push({
                type: 'option',
                itemKey: 'lumdrive-pick',
                onClick: () => onOpenFilePicker(MediaSource.LUMDRIVE),
                icon: mdiImageMultiple,
                label: translateKey(WREX_IMAGE.FROM_MEDIA_LIBRARY),
                otherProps: { ...get({ element: 'upload-from-lumdrive', action: 'open-media-picker' }) },
            });
        }

        /**
         * Pick from Google or Microsoft drive
         */
        if (
            onOpenFilePicker &&
            isSecuredStorageEnabled &&
            isDriveAllowed &&
            (accountType === UserAccountType.GOOGLE || accountType === UserAccountType.MICROSOFT) &&
            isInCommunityContext
        ) {
            options.push({
                type: 'option',
                itemKey: 'drive-media-pick',
                onClick: () =>
                    onOpenFilePicker(
                        accountType === UserAccountType.GOOGLE ? MediaSource.GOOGLE_DRIVE : MediaSource.MICROSOFT,
                    ),
                label: translateKey(DRIVE_ITEM_INFOS[accountType].key),
                icon: DRIVE_ITEM_INFOS[accountType].icon,
                otherProps: { ...get({ element: 'upload-from-drive', action: 'open-media-picker' }) },
            });
        }

        return options;
    }, [
        accountType,
        currentFiles,
        get,
        isDriveAllowed,
        isInCommunityContext,
        isLumdriveAllowed,
        isSecuredStorageEnabled,
        mediaPickerParams,
        onMediaChange,
        onUploadFromComputer,
        translateKey,
    ]);
};
