import React from 'react';

import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Icon, IconProps, Placement, Size, Text, Theme, Tooltip } from '@lumapps/lumx/react';

import { ItemVariant, ViewMode } from '../../constants';
import { useTooltipLabel } from '../../hooks/useTooltipLabel';

import './index.scss';

export interface NavigationItemProps {
    /** Classname that will be used for the nav wrapping element */
    className?: string;
    /** the element used to create data attributes */
    element?: string;
    /** Icon (SVG path). */
    icon?: string;
    /** Additional props to pass to the icon */
    iconProps?: Omit<IconProps, 'icon'>;
    /** Whether the component is active or not. */
    isSelected?: boolean;
    /** Label content. */
    label: string | React.ReactNode;
    /** Custom react component for the link (can be used to inject react router Link). */
    linkAs?: 'a' | React.ElementType;
    /** Props to pass to the link (minus those already set by the SideNavigationItem props). */
    linkProps?:
        | React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
        | React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
        | Record<string, any>;
    /** On click callback. */
    onClick?(evt: React.MouseEvent): void;
    /** ARIA role of the item */
    role?: string;
    /** The scope used to create data attributes */
    scope?: string;
    /** Theme that will be applied to the element, either Theme.dark or Theme.light */
    theme?: Theme;
    /** The variant used to determine whether to display text and/or icon. Default displays both */
    variant?: ItemVariant;
    /** The view mode used to display the nav, either 'horizontal' or 'vertical'. Defaults to 'vertical' */
    viewMode?: ViewMode;
}

export const CLASSNAME = 'navigation-item';

const NavigationItem = React.forwardRef<HTMLLIElement, NavigationItemProps>((props, ref) => {
    const {
        className,
        element,
        icon,
        iconProps,
        isSelected,
        label,
        linkAs,
        linkProps,
        onClick,
        scope,
        theme,
        variant,
        viewMode,
        ...forwardedProps
    } = props;
    const { block: cssBlock, element: cssElement } = useClassnames(CLASSNAME);

    const { labelRef, tooltipLabel } = useTooltipLabel({ label, variant });

    /* istanbul ignore next */
    const { get } = useDataAttributes(scope ?? '');
    const dataAttributes = scope && element ? get({ element }) : {};

    const isHorizontalViewMode = viewMode === ViewMode.horizontal;

    const isLink = (linkProps && 'href' in linkProps && linkProps?.href) || linkAs;

    const Element: 'a' | 'button' | React.ElementType = isLink ? linkAs || 'a' : 'button';

    return (
        <li
            className={cssBlock({ horizontal: isHorizontalViewMode }, [className])}
            ref={ref}
            {...dataAttributes}
            {...forwardedProps}
        >
            <Tooltip label={tooltipLabel} placement={Placement.TOP}>
                <Element
                    className={cssElement('link', {
                        'is-selected': Boolean(isSelected),
                        dark: theme === Theme.dark,
                    })}
                    {...linkProps}
                    onClick={onClick}
                    tabIndex={0}
                >
                    {!!icon && variant !== ItemVariant.labelOnly && (
                        <Icon className={cssElement('icon')} icon={icon} size={Size.xs} theme={theme} {...iconProps} />
                    )}

                    <Text
                        as="span"
                        className={cssElement(
                            'label',
                            variant === ItemVariant.iconOnly ? [visuallyHidden()] : undefined,
                        )}
                        ref={labelRef}
                    >
                        {label}
                    </Text>
                </Element>
            </Tooltip>
        </li>
    );
});

NavigationItem.displayName = 'NavigationItem';

export { NavigationItem };
