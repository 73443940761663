import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Text, Theme, Typography } from '@lumapps/lumx/react';
import { BlockComponent } from '@lumapps/widget-base/types';

import { CLASSNAME } from '../../constants';
import { BlockSummary as BlockSummaryProps } from '../../types';

/**
 * Block that allows rendering summary on a page.
 */
export const BlockSummary: BlockComponent<BlockSummaryProps> = (props) => {
    const { text, style, theme } = props;
    const currentTheme = theme || style?.theme || Theme.light;
    const { block } = useClassnames(CLASSNAME);

    return (
        <div className={block(`theme-${currentTheme}`)} dir="auto">
            <Text as="p" typography={Typography.custom.intro}>
                {text}
            </Text>
        </div>
    );
};

BlockSummary.displayName = 'BlockSummary';

export default BlockSummary;
