import React from 'react';

import first from 'lodash/first';
import property from 'lodash/fp/property';

import { padding } from '@lumapps/classnames';
import { FlexBox, RadioButton, RadioGroup, Text, Theme } from '@lumapps/lumx/react';

import { PostListFilterBlockProps } from '../../../types';

export const PostRadioFilterBlock = <T extends string>({
    theme,
    onSelect,
    selectedValues,
    filterId,
    choices = [],
    getChoiceName,
    label,
}: PostListFilterBlockProps<T>) => {
    const selectedValue = first(selectedValues);

    const handleChange = (value: T) => {
        onSelect(filterId, [value]);
    };

    return (
        <FlexBox orientation="vertical" gap="regular">
            {label && (
                <Text
                    className={padding('bottom', 'regular')}
                    typography="subtitle1"
                    as="span"
                    color={theme === Theme.dark ? 'light' : 'dark'}
                    colorVariant="N"
                >
                    {label}
                </Text>
            )}
            <RadioGroup id={filterId}>
                {choices.map((choice) => (
                    <RadioButton
                        key={property('value', choice)}
                        isChecked={selectedValue === property('value', choice)}
                        label={getChoiceName(property('value', choice))}
                        name={property('name', choice)}
                        theme={theme}
                        value={property('value', choice)}
                        onChange={handleChange}
                    />
                ))}
            </RadioGroup>
        </FlexBox>
    );
};
