/** istanbul ignore file */
import React, { RefObject } from 'react';

import { boundInputSelection, BoundInputSelectionOptionsType } from '../input/boundInputSelection';

/**
 * Hook to bound the possible character selection in an
 * input or textarea.
 */
export const useBoundInputSelection = (
    // The ref of the input to add the bound to.
    ref: RefObject<HTMLInputElement> | null,
    /** The options of the boundary. (minimum and maximum position) */
    options: BoundInputSelectionOptionsType,
) => {
    React.useEffect(() => {
        if (!ref || !ref.current) {
            return undefined;
        }

        const { current: currentElement } = ref;

        /**
         * On click, simply apply the boundary of the selection.
         */
        const handleClick = () => {
            boundInputSelection(currentElement, options);
        };

        /**
         * Keydown is handled to make sure the user can't
         * go below or above the given boundary.
         *
         * As the keydown is executed before the cursor has changed,
         * we have to anticipate what the user is trying to do and adapt
         * the boundaries.
         */
        const handleKeyDown = ({ key }: KeyboardEvent) => {
            if (!options?.min && !options?.max) {
                return;
            }

            const adaptedOptions = { ...options };

            switch (key) {
                case 'ArrowLeft':
                    adaptedOptions.min = options.min ? options.min + 1 : undefined;
                    break;
                case 'ArrowRight':
                    adaptedOptions.max = options.max ? options.max - 1 : undefined;
                    break;
                default:
                    break;
            }

            boundInputSelection(currentElement, adaptedOptions);
        };

        currentElement.addEventListener('click', handleClick);
        currentElement.addEventListener('keydown', handleKeyDown);

        return () => {
            currentElement.removeEventListener('click', handleClick);
            currentElement.removeEventListener('keydown', handleKeyDown);
        };
    }, [ref, options]);
};
