import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';

import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { onKey } from '@lumapps/utils/event/onKey';
import { not } from '@lumapps/utils/function/predicate/not';
import { ParagraphElement } from '@lumapps/wrex-typography/types';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { Editor, Transforms, ReactEditor, Range } from '@lumapps/wrex/slate';

import { AlignedImageWrapperElement, ImageEditor } from '../../types';
import { isAlignedImageWrapper } from '../../utils/isAlignedImageWrapper';
import { isImageGroup } from '../../utils/isImageGroup';

/**
 * Return true if Enter is pressed when the cursor is:
 * - In a quote block
 * - On the last paragraph of this codeblock
 * - And this paragraph is empty
 *
 * @param editor
 */
const isEnterPressedOnLastEmptyParagraphInAlignedImageWrapper =
    (editor: Editor & ImageEditor) => (event: KeyboardEvent) => {
        if (!editor.selection) {
            return false;
        }

        if (!isHotKey('Enter')(event)) {
            return false;
        }

        const [currentBlockAtFirstLevel] = Editor.node(editor, editor.selection, {
            depth: 1,
        });

        if (!isAlignedImageWrapper(currentBlockAtFirstLevel)) {
            return false;
        }

        const [currentParagraph] = Editor.node(editor, editor.selection, {
            depth: 2,
        });

        if (
            last((currentBlockAtFirstLevel as AlignedImageWrapperElement).children.filter(not(isImageGroup))) !==
            currentParagraph
        ) {
            return false;
        }

        return Editor.isEmpty(editor, currentParagraph as ParagraphElement);
    };

/**
 * Return true if ArrowUp is pressed when the cursor is:
 * - In a quote block
 * - At the top of the document
 * - Is at the start of a paragraph and first paragraph inside the quote block
 */
const isExitingAlignedImageWrapperFromTop = (editor: ReactEditor & ImageEditor) => (event: KeyboardEvent) => {
    if (!editor.selection) {
        return false;
    }

    const isArrowUpPressed = isHotKey('ArrowUp')(event);
    if (!isArrowUpPressed) {
        return false;
    }

    const [currentBlockAtFirstLevel, quoteBlockPath] = Editor.node(editor, editor.selection, {
        depth: 1,
    });

    if (!isAlignedImageWrapper(currentBlockAtFirstLevel)) {
        return false;
    }

    if (!isEqual(quoteBlockPath, [0])) {
        return false;
    }

    const [currentParagraph] = Editor.node(editor, editor.selection, {
        depth: 2,
    });

    if (first((currentBlockAtFirstLevel as AlignedImageWrapperElement).children) !== currentParagraph) {
        return false;
    }

    const currentNodeAsDOM = ReactEditor.toDOMNode(editor as ReactEditor, currentParagraph);
    const selectionRangeAsDOM = ReactEditor.toDOMRange(editor as ReactEditor, editor.selection);
    const isOnFirstLineOfBlock =
        currentNodeAsDOM.getBoundingClientRect().y === selectionRangeAsDOM.getBoundingClientRect().y - 2;

    return isOnFirstLineOfBlock;
};

/**
 * Initialize key event handler for the quote editor.
 *
 * When pressing 'Enter' in a quote block, on an empty paragraph, this paragraph should be removed,
 * and the cursor should be out of the quote block.
 *
 * @param editor          The editor
 */
export const getKeyHandler = (editor: ReactEditor & ImageEditor) =>
    onKey(
        [
            {
                match: isExitingAlignedImageWrapperFromTop(editor),
                handler: () => {
                    // Insert a paragraph above the Quoteblock
                    Transforms.insertNodes(editor, createParagraph(), { at: [0] });
                    // Put the cursor on this paragraph
                    Transforms.select(editor, [0, 0]);
                },
            },
            {
                match: isEnterPressedOnLastEmptyParagraphInAlignedImageWrapper(editor),
                handler: () => {
                    const [, currentBlockPath] = editor.node(editor.selection as Range);
                    // Move the last empty paragraph
                    Transforms.moveNodes(editor, {
                        to: [currentBlockPath[0] + 1],
                    });
                },
            },
        ],
        { preventDefault: true },
    );
