import { Body, DataAbout, Element, FeaturedImage, Node, Prolog, Text } from '../types';

export const isText = (node?: Node): node is Text => node?.type === 'text';
export const isElement = (node?: Node): node is Element => node?.type === 'element';
export const isBody = (node?: Node): node is Body => isElement(node) && node?.name === 'body';
export const isParagraph = (node?: Node): node is Body => isElement(node) && node?.name === 'p';
export const isProlog = (node?: Node): node is Prolog => isElement(node) && node?.name === 'prolog';
export const isDataAbout = (node?: Node): node is DataAbout => isElement(node) && node?.name === 'data-about';
export const isFeaturedImage = (node?: Node): node is FeaturedImage =>
    isDataAbout(node) && node.attributes?.type === 'lumapps:featured-image';
