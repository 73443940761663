import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { mdiPlayCircleOutline } from '@lumapps/lumx/icons';
import { Icon, Text } from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'block-video__video-placeholder';

interface VideoPlaceholderProps {
    /** Video page URL */
    url: string;
    /** Video name */
    name: string;
}

/**
 * A simple video placeholder displaying a black back box with a play button in the middle.
 */
export const VideoPlaceholder: React.FC<VideoPlaceholderProps> = ({ url, name }) => (
    <a className={CLASSNAME} href={url} target="_blank" rel="noreferrer">
        <Text as="span" className={visuallyHidden()}>
            {name}
        </Text>
        <Icon icon={mdiPlayCircleOutline} color="light" colorVariant="L2" size="l" />
    </a>
);
