import React from 'react';

import { DATA_LISTING_SEPARATOR } from '@lumapps/constants/specialCharacters';
import { FlexBox, Badge, ColorPalette, Icon, Avatar, Size, Typography, Theme, Text } from '@lumapps/lumx/react';

export interface BlockContextBannerProps {
    /** className if any */
    className?: string;
    /* Icon in string format, if any */
    icon?: string;
    /* User picture url, if any */
    pictureUrl?: string;
    /* The banner text or JSX component */
    contextBannerText: string | JSX.Element | (string | number | JSX.Element)[];
    /* The date that we want to display after the text, if any */
    actionDate?: string;
    /* Dark of light Theme. Defaults to light */
    theme?: Theme;
}

/**
 * Basic Block Context Banner that can be put on top of other blocks. It shares a common structure for homogeneity.
 * Used in SharedByBlock for posts and StreamContextBanners for Personal Feed.
 */
export const BlockContextBanner: React.FC<BlockContextBannerProps> = ({
    className,
    icon,
    pictureUrl,
    contextBannerText,
    actionDate,
    theme = Theme.light,
}) => {
    return (
        <FlexBox orientation="horizontal" hAlign="center" gap="big" className={className}>
            {(icon || pictureUrl) && (
                <FlexBox as="div" orientation="horizontal">
                    {icon && (
                        <Badge color={ColorPalette.light}>
                            <Icon icon={icon} size="xxs" colorVariant="L1" alt="" color={ColorPalette.dark} />
                        </Badge>
                    )}

                    {pictureUrl && <Avatar alt="" size={Size.xs} image={pictureUrl} />}
                </FlexBox>
            )}
            <Text
                as="p"
                typography={Typography.body1}
                color={theme === Theme.dark ? Theme.light : Theme.dark}
                colorVariant="L2"
            >
                <Text
                    as="span"
                    typography={Typography.body1}
                    color={theme === Theme.dark ? Theme.light : Theme.dark}
                    colorVariant="N"
                >
                    {contextBannerText}
                </Text>
                {actionDate && (
                    <>
                        <Text typography="body1" as="span">
                            {DATA_LISTING_SEPARATOR}
                        </Text>
                        <Text as="span" typography={Typography.body1}>
                            {actionDate}
                        </Text>
                    </>
                )}
            </Text>
        </FlexBox>
    );
};

BlockContextBanner.displayName = 'BlockContextBanner';
