import React from 'react';

import { useTranslate } from '@lumapps/translations';

import { SearchInstancesParams } from '../api/types';
import { Instance } from '../types';
import { useFetchSites } from './useFetchSites/useFetchSites';

const MAX_RESULTS = 10;

/**
 * Hook that returns the different options needed for displaying a list of instances
 * on a multiple select.
 * @param params
 * @param instancesToExclude
 */
export const useInstanceSelectMultiple = (params?: Partial<SearchInstancesParams>, instancesToExclude?: string[]) => {
    const [searchValue, setSearchValue] = React.useState<string | undefined>();
    const [isPristine, setIsPristine] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const { sites, fetch, loading, isLoadingMore, fetchMore, error, hasMore } = useFetchSites({
        userFavoritesOnly: false,
        maxResults: MAX_RESULTS,
        ...params,
    });

    const { translate } = useTranslate();

    const onInfiniteScroll = () => {
        if (!loading && !isLoadingMore) {
            fetchMore({
                name: searchValue,
            });
        }
    };

    const onSearch = (searchText: string) => {
        const value = searchText === '' ? undefined : searchText;

        fetch({
            name: value,
            cursor: undefined,
        });

        setSearchValue(value);
    };

    const onOpen = async () => {
        if (isPristine) {
            await fetch({});
            setIsPristine(false);
        }
    };

    /**
     * Remove the ids from the instances passed in in `instancesToExclude` and return the actually filtered list.
     */
    const filteredSites = React.useMemo(() => {
        return instancesToExclude && instancesToExclude.length > 0
            ? sites.filter((site) => !instancesToExclude.includes(site.id))
            : sites;
    }, [instancesToExclude, sites]);

    return {
        isLoading: loading,
        getValueName: (instance: Instance) => instance.name || translate(instance.title),
        getValueId: (instance: Instance) => instance.id,
        hasLoadingError: error,
        onSearch,
        isLoadingMore,
        isOpen,
        setIsOpen,
        searchFieldProps: {
            isCompact: true,
            textFieldProps: {
                isDisabled: isPristine,
            },
        },
        onInfiniteScroll: () => {
            if (hasMore) {
                onInfiniteScroll();
            }
        },
        searchText: searchValue,
        onOpen,
        sites: filteredSites,
        maxResults: MAX_RESULTS,
    };
};
