import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Player as PlayPlayer } from '@lumapps/play-player';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-player` as const;

export const Player = () => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, video } = useBlockVideoContext();

    if (!video || !canDisplay('FIGURE')) {
        return null;
    }

    /**
     * TODO: We should update the player Video types to accept undefined metadata.
     * This way, the `<Player />` component would be more reusable
     */
    const playerVideo = {
        ...video,
        createdAt: video.date || '',
        createdById: video.createdById || '',
        duration: video.duration || null,
        title: video.title || '',
        views: video.views || 0,
    };

    return (
        <PlayPlayer className={block()} videoId={video.id} autoplay>
            <PlayPlayer.Facade video={playerVideo}>
                <PlayPlayer.Metadata visibleMetadata={canDisplay('DURATION') ? ['duration'] : []} />

                <PlayPlayer.Thumbnail />
            </PlayPlayer.Facade>
        </PlayPlayer>
    );
};
