import get from 'lodash/get';
import includes from 'lodash/includes';
import without from 'lodash/without';

function ModuleCommunityAdminDialogController(
    $scope,
    $timeout,
    AbstractPicker,
    Community,
    ConfigTheme,
    Features,
    Feed,
    Instance,
    Document,
    LxNotificationService,
    ModuleAdmin,
    MediaConstant,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    const vm = this;

    /**
     * Identifier of the drive folder picker.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.FOLDER_PICKER_ID = 'community-folder-picker';

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The identifier of the community dialog.
     *
     * @type {string}
     */
    vm.dialogId = ModuleAdmin.dialogKeys.community;

    vm.dialogWarningId = `${ModuleAdmin.dialogKeys.community}-warning`;

    /**
     * The list of available providers.
     * Only Micorsoft OneDrive provider is allowed for the moment.
     *
     * TODO [Greg] : need to be rework when Google users will use the new Media picker.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.ALLOWED_PROVIDERS = [MediaConstant.PROVIDERS.microsoft, MediaConstant.PROVIDERS.google];

    /**
     * The identifier of the MS Calendar share dialog.
     *
     * @type {string}
     */
    vm.dialogIdMsShare = ModuleAdmin.dialogKeys.communityMsShare;

    /**
     * Indicates if the connected user can see the 'Enable Follow for' selector and 'Enable notification on follow'
     * switch.
     *
     * @type {boolean}
     */
    vm.canSeeFollowSettings = false;

    /**
     * Contains some settings for the "Enable follow for" feeds selector.
     * This settings is updated each time the first tab ("Information") is enabled.
     *
     * @type {Object}
     */
    vm.followFor = {
        disabled: true,
        exceptions: [],
    };

    /**
     * The list of subscribed feeds.
     *
     * @type {Object}
     */
    vm.subscribedFeeds = [];

    /**
     * The currently selected drive folder
     * from the media picker linked to the
     * react community configuration dialog.
     *
     * Used as model for the media picker
     *
     * @type {Object}
     */
    vm.legacyDriveFolder = {};

    /**
     * The currently selected drive folder
     * formatted for the community configuration dialog.
     *
     * @type {Object}
     */
    vm.formattedLegacyDriveFolder = {};

    /**
     * Whether the folder picker is open or not.
     *
     * @type {Boolean}
     */
    vm.driveFolderPickerIsOpen = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.ConfigTheme = ConfigTheme;
    vm.Feed = Feed;
    vm.Instance = Instance;
    vm.Translation = Translation;

    /**
     * Open the media picker.
     */
    function openMediaPicker() {
        /**
         * Set the drive picker at true before opening it to
         * load the component.
         *
         * It will be set to false once closed.
         * */
        vm.driveFolderPickerIsOpen = true;
        Utils.waitForAndExecute(`#${vm.FOLDER_PICKER_ID}`, AbstractPicker);
    }


    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the subscribed feeds from the list of subscribed feeds ids.
     */
    function _getSubscribedFeeds() {
        vm.subscribedFeeds = [];

        const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());
        if (angular.isUndefinedOrEmpty(get(currentCommunity, 'subscribedFeedKeys'))) {
            return;
        }

        angular.forEach(currentCommunity.subscribedFeedKeys, (subscribedFeedId) => {
            Feed.getFeedById(subscribedFeedId).then((feed) => {
                vm.subscribedFeeds.push(feed);
            });
        });
    }

    /**
     * Initialize the settings used by the "Enable follow for" feeds selector.
     */
    function _initFollowForSelector() {
        const currentCommunity = Community.getCurrent(ModuleAdmin.getListKey());
        if (angular.isUndefinedOrEmpty(currentCommunity)) {
            vm.followFor.disabled = true;
            vm.followFor.exceptions = [];

            return;
        }

        vm.followFor.disabled =
            currentCommunity.privacy !== 'open' && angular.isUndefinedOrEmpty(currentCommunity.publishers);

        vm.followFor.exceptions = angular.fastCopy(currentCommunity.subscribedFeedKeys) || [];

        if (currentCommunity.privacy === 'open') {
            return;
        }

        if (!includes(currentCommunity.publishers, Feed.ALL.id)) {
            vm.followFor.exceptions.push(Feed.ALL.id);
        }
        if (!includes(currentCommunity.publishers, Feed.PUBLIC.id)) {
            vm.followFor.exceptions.push(Feed.PUBLIC.id);
        }
    }

    /**
     * Check if a document has a title in the current lang.
     *
     * @param  {Object}  doc The document to check for a title.
     * @return {boolean} If the document has title in the current lang or not.
     */
    function _hasFolderTitle(doc) {
        const mediaContent = Document.getMediaContentByLang(doc, true);

        return angular.isDefinedAndFilled(get(mediaContent, 'title'));
    }

    /**
     * Return the title of the folder it's name if title is not defined.
     *
     * @param  {Object} folder The folder we want to retrieve the title or name.
     * @return {string} The displayable name of the folder;
     */
    function _getFolderName(folder) {
        const mediaContent = Document.getMediaContentByLang(folder, true);

        return _hasFolderTitle(folder) ? get(mediaContent, 'title') : get(mediaContent, 'name');
    }

    /**
     *
     * Format a folder selected from the media picker to an object useable
     * by the community.
     *
     * @param {Object} folder The drive folder to format
     * @returns {Object} An object containing the document path, document kind and name.
     */
        function formatDriveFolder(folder) {
            if (angular.isUndefinedOrEmpty(folder)) {
                return {};
            }
    
            const { docPath, properties } = folder;
            const driverKind = properties && properties.type === 'GOOGLE_TEAMDRIVE' ? 'drive#teamDrive' : 'drive#folder';
    
            return {
                docPath,
                kind: driverKind,
                name: _getFolderName(folder),
            };
        }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Initialize the controller.
     * Everything is already set in ModuleAdmin _addCommunity.
     */
    function initChild() {
        vm.canSeeFollowSettings =
            (User.getConnected().isSuperAdmin || User.isInstanceAdmin()) && Features.hasFeature('social');

        // Super, call parent init.
        vm.init();

        // Register watchers with current scope.
        vm.registerWatchers($scope);

        _getSubscribedFeeds();
        _initFollowForSelector();
    }

    /**
     * Open the community edition dialog for Google communities.
     */
    function openEditCommunityDialog() {
        Utils.waitForAndExecute('#module-community-admin-dialog');
    }

    function changeLegacyDriveFolder(driveFolder) {
        $timeout(() => {
            vm.legacyDriveFolder = driveFolder;
        }, 0);
    }

    /////////////////////////////

    vm.initChild = initChild;
    vm.openEditCommunityDialog = openEditCommunityDialog;
    vm.changeLegacyDriveFolder = changeLegacyDriveFolder;
    vm.openMediaPicker = openMediaPicker;
    vm.formatDriveFolder = formatDriveFolder;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Initialize the community when we open the dialog.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The identifier of the dialog triggering the open start event.
     */
    $scope.$on('lx-dialog__open-end', (evt, dialogId) => {
        if (dialogId === vm.dialogId && angular.isDefinedAndFilled(Community.getCurrent(ModuleAdmin.getListKey()))) {
            vm.initChild();
        }
    });

    /**
     * Set driveFolderPickerIsOpen to true when drive picker is opened.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The identifier of the picker triggering the open start event.
     */
    $scope.$on('abstract-picker__open-end', function onMediaPickerCloseStart(evt, pickerId) {
        if (pickerId === vm.FOLDER_PICKER_ID) {
            vm.driveFolderPickerIsOpen = true;
        }
    });

    /**
     * Set driveFolderPickerIsOpen to false when drive picker is closed.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The identifier of the picker triggering the open start event.
     */
    $scope.$on('abstract-picker__close-end', function onMediaPickerCloseStart(evt, pickerId) {
        if (pickerId === vm.FOLDER_PICKER_ID) {
            vm.driveFolderPickerIsOpen = false;
        }
    });

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * When switching to the first tab, recompute all settings of the "Enable follow for" feeds selector.
     *
     * @param {number} newIndex The new tab index.
     */
    $scope.$watch(
        'vm.activeTabIndex',
        (newIndex) => {
            if (newIndex !== 0) {
                return;
            }

            _initFollowForSelector();
        },
        true,
    );

    /**
     * When the drive picker model changes, update the formatted variable.
     *
     * @param {Object} newFolder The new folder.
     */
    $scope.$watch(
        'vm.legacyDriveFolder',
        (newFolder) => {
            vm.formattedLegacyDriveFolder = vm.formatDriveFolder(newFolder);
        },
        true,
    );
}

/////////////////////////////

angular.module('Controllers').controller('ModuleCommunityAdminDialogController', ModuleCommunityAdminDialogController);

/////////////////////////////

export { ModuleCommunityAdminDialogController };
