import { saApi } from './constants';

/**
 * Get all shareable content.
 *
 * @param  {string}                url             The URL.
 * @param  {number}                contentInternalId The content core id.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getUrlInfo = ({ url, contentInternalId = null, programId = null }) =>
    saApi.post('utils/content-shareable-status', { contentInternalId, url, programId });
