import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { InlineDialog } from '@lumapps/lumx-dialogs/components/InlineDialog';
import { FormProvider } from '@lumapps/lumx-forms';
import { FormActions } from '@lumapps/lumx-forms/components/FormActions';
import { FormURLTextField } from '@lumapps/lumx-forms/components/FormURLTextField';
import { useForm } from '@lumapps/lumx-forms/hooks/useForm';
import { Placement, Emphasis } from '@lumapps/lumx/react';
import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { useFocus } from '@lumapps/utils/hooks/useFocus';
import { isUrl } from '@lumapps/utils/string/isUrl';

import './index.scss';

const CLASSNAME = 'wrex-enhanced-image-link-inline-dialog';
const FIELD_NAME = 'link';

export interface LinkInlineDialogProps {
    /** Value of the current link */
    currentLink: string;
    /** Input label */
    inputLabel?: string;
    /** Input placeholder */
    inputPlaceholder?: string;
    /** Whether the dialog is open or not */
    isOpen: boolean;
    /** Callback when submit button is pressed */
    onChange(link?: string): void;
    /** Callback to set the dialog open state */
    setIsOpen(isOpen: boolean): void;
    /** Anchor ref */
    anchorRef: React.RefObject<HTMLElement>;
}

export const LinkInlineDialog = ({
    currentLink,
    inputLabel = GLOBAL.URL,
    inputPlaceholder,
    isOpen,
    onChange,
    setIsOpen,
    anchorRef,
}: LinkInlineDialogProps) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { get } = useDataAttributes(CLASSNAME);
    const urlFormatError = translateKey(GLOBAL.URL_ERROR);

    const defaultValues = React.useMemo(() => ({ link: currentLink }), [currentLink]);

    const { methods, onSubmit, status } = useForm({
        onSubmit: (data: any) => {
            onChange(sanitizeUrl(data.link, true));
        },
        form: { defaultValues },
    });
    const link = methods.watch(FIELD_NAME);

    const onChangeOpenState = React.useCallback(
        (state: boolean) => {
            // Reset form on close
            if (state === false) {
                methods.reset();
                return setIsOpen(false);
            }
            return setIsOpen(state);
        },
        [methods, setIsOpen],
    );

    const urlValidator = (value: string | undefined): boolean => {
        if (!value || value === '') {
            return true;
        }
        return isUrl(value);
    };

    // Focus on open
    useFocus(inputRef, isOpen);

    return (
        <FormProvider {...methods}>
            <InlineDialog
                title=""
                isOpen={isOpen}
                className={block()}
                setIsOpen={onChangeOpenState}
                anchorRef={anchorRef}
                popoverProps={{
                    placement: Placement.BOTTOM,
                    offset: {
                        away: 8,
                    },
                    usePortal: true,
                }}
                headerProps={{
                    className: element('header'),
                    after: null,
                }}
                wrapperProps={{
                    className: element('wrapper'),
                    gap: undefined,
                }}
                footerProps={{
                    after: (
                        <FormActions
                            onSubmit={onSubmit}
                            onCancel={() => onChangeOpenState(false)}
                            saveProps={{
                                emphasis: Emphasis.medium,
                                children: translateKey(GLOBAL.APPLY),
                                ...get({ element: 'button', action: 'apply' }),
                            }}
                            cancelProps={{
                                emphasis: Emphasis.low,
                                ...get({ element: 'button', action: 'cancel' }),
                            }}
                            status={status}
                        />
                    ),
                }}
            >
                <FormURLTextField
                    placeholder={inputPlaceholder}
                    label={translateKey(inputLabel)}
                    name={FIELD_NAME}
                    helper={translateKey(GLOBAL.URL_HELPER)}
                    error={
                        link !== ''
                            ? (methods.formState.errors && methods.getFieldState(FIELD_NAME).error?.message) ||
                              urlFormatError
                            : translateKey(GLOBAL.FIELD_IS_REQUIRED)
                    }
                    isRequired
                    clearButtonProps={{
                        label: translateKey(GLOBAL.CLEAR),
                        onClick: () => {
                            methods.reset();
                        },
                    }}
                    urlValidator={urlValidator}
                    controllerProps={{
                        rules: {
                            validate: (value: string) => {
                                return urlValidator(value) ? true : urlFormatError;
                            },
                            required: translateKey(GLOBAL.FIELD_IS_REQUIRED),
                        },
                    }}
                    {...get({ element: 'input' })}
                />
            </InlineDialog>
        </FormProvider>
    );
};
