import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiInvertColors } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { ADVANCED_STYLES } from '../../keys';
import { style } from '../../routes';

export interface StyleSideNavigationProps {
    isAllowed: boolean;
}

const StyleSideNavigation = ({ isAllowed }: StyleSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(ADVANCED_STYLES.INSTANCE_STYLE)}
            icon={mdiInvertColors}
            route={style()}
        />
    );
};

StyleSideNavigation.displayName = 'SideNavigationItem';

export { StyleSideNavigation };
