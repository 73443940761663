import React from 'react';

import { ImageLightboxProps } from '@lumapps/lumx/react';

import { ImageLightboxContext } from '../components/ImageLightboxProvider';
import { UseImageLightbox } from './useImageLightbox';

type UseImageLightboxProvider = {
    /** Get props for an image lightbox trigger element */
    getTriggerProps?: UseImageLightbox['getTriggerProps'];
};

/**
 * Register images in the parent ImageLightboxProvider and get props for an image lightbox trigger element
 */
export function useImageLightboxProvider(localImages: ImageLightboxProps['images']): UseImageLightboxProvider {
    const context = React.useContext(ImageLightboxContext);

    const [output, setOutput] = React.useState<UseImageLightboxProvider>({});

    // Register the local images to the global slideshow in the parent context
    React.useEffect(() => {
        if (!context) {
            return undefined;
        }

        // Register on mount
        const { index, length, getTriggerProps } = context.register(localImages);
        setOutput({ getTriggerProps });

        return () => {
            // Un-register on unmount
            context.unregister(index, length);
        };
    }, [context, localImages]);

    return output;
}
