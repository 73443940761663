import React, { PureComponent } from 'react';
import { array, bool, func, node, number, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import includes from 'lodash/includes';
import map from 'lodash/map';

import { getLabel } from '../utils';
import { translate as t } from '../../../translations';
import * as inputTypes from './types';
import * as uiTypes from '../types';
import * as types from '../../types';

import { Choice } from './Choice';
import { DropDown } from './DropDown';
import { Icon } from '../icons';

import { DEFAULT_VALUE } from '../../styles/constants';

/////////////////////////////

/**
 * Renders a checkbox to be prepended to a Choice label.
 *
 * @param  {boolean}   isSelected Indicates if the checkbox is currently selected or not.
 * @return {Component} The CheckboxChoiceItem component.
 */
const CheckboxChoiceItem = ({ isSelected }) => {
    return <Icon value={isSelected ? 'check' : 'checkbox-blank-outline'} />;
};

/////////////////////////////

/**
 * Renders a dropdown selection menu, similar to `<select />`.
 */
class Select extends PureComponent {
    constructor(props) {
        super(props);

        this.onClear = this.onClear.bind(this);
        this.onClickChoice = this.onClickChoice.bind(this);
    }

    /**
     * Sets the value to `undefined`. Used if `hasClearChoice` is `true`.
     *
     * @param  {Event} evt The clear event.
     * @return {any}   The result of `onChange` (usually discarded).
     */
    onClear(evt) {
        const { name: key, onChange } = this.props;

        return onChange(undefined, key, evt);
    }

    /**
     * Triggered whenever a choice is clicked. Used mostly to propagate to the `onChange` prop.
     *
     * @param  {Event} evt The click event.
     * @return {any}   The result of `onChange`.
     */
    onClickChoice(evt) {
        const { name: key, choices, onChange } = this.props;

        return onChange(choices[evt.currentTarget.id].value, key, evt);
    }

    /**
     * Method that checks if a given value is selected or not.
     *
     * @param  {Object}       value            The choice value to check.
     * @param  {string|Array} currentSelection The currently selected value(s).
     *
     * @return {boolean}      Whether the choice is selected or not.
     */
    isChoiceSelected(value, currentSelection) {
        const { isMulti } = this.props;

        if (isMulti) {
            return includes(currentSelection, value);
        }

        return value === currentSelection;
    }

    render() {
        const {
            ChoiceComponent,
            ToggleComponent,
            ValueComponent,
            align,
            choices,
            className,
            hasBackground,
            hasClearChoice,
            isMulti,
            label,
            placeholder,
            suffix,
            value,
        } = this.props;

        const defaultValue = value === DEFAULT_VALUE ? 'GLOBAL.INPUT.CLEAR_LABEL' : placeholder;
        const toggleLabel = getLabel({ choices, isMulti, placeholder: defaultValue, suffix, value });

        return (
            <div
                className={classNames('custom-select', className, {
                    'custom-select--no-background': !hasBackground,
                })}
            >
                {label && <span className="custom-select__label">{label}</span>}
                <div className="custom-select__input">
                    <DropDown
                        align={align}
                        hasPreventAutoClose={isMulti === true}
                        toggle={
                            ToggleComponent || (
                                <div className="custom-select__selection custom-select-selection">
                                    {ValueComponent && <ValueComponent value={value} />}
                                    <span
                                        className={classNames('custom-select-selection__label', {
                                            'custom-select__placeholder':
                                                value === null ||
                                                value === undefined ||
                                                (isMulti && value.length === 0),
                                        })}
                                    >
                                        {t(
                                            `${toggleLabel.text}${
                                                toggleLabel.counter ? ` +${toggleLabel.counter}` : ''
                                            }`,
                                        )}
                                    </span>
                                </div>
                            )
                        }
                    >
                        <ul className="custom-select__choice-list">
                            {hasClearChoice && (
                                <Choice
                                    key="undefined"
                                    ChoiceComponent={isMulti && !ChoiceComponent ? CheckboxChoiceItem : ChoiceComponent}
                                    id={-1}
                                    isSelected={undefined === value}
                                    value={{
                                        label: 'GLOBAL.INPUT.CLEAR_LABEL',
                                        value: undefined,
                                    }}
                                    onClick={this.onClear}
                                />
                            )}
                            {map(choices, (choice, index) => (
                                <Choice
                                    key={index}
                                    ChoiceComponent={isMulti && !ChoiceComponent ? CheckboxChoiceItem : ChoiceComponent}
                                    hasSelectedStyle={isMulti !== true}
                                    id={index}
                                    isError={choice.isError}
                                    isSelected={this.isChoiceSelected(choice.value, value)}
                                    value={choice}
                                    onClick={this.onClickChoice}
                                />
                            ))}
                        </ul>
                    </DropDown>
                </div>
            </div>
        );
    }
}

Select.propTypes = {
    /** Choice render component that receives `{ value, label }` props from `choices[i]`. */
    ChoiceComponent: func,
    /** The component that is clicked on to trigger the Select choices and display the currently selected value. */
    ToggleComponent: node,
    /** A component that is places before the actual currently selected label. Receives the `value` prop. */
    ValueComponent: func,
    /** Alignment of the menu dropdown. See `<Dropdown />`. */
    align: uiTypes.position,
    /** Available choices. Translated. */
    choices: inputTypes.choices.isRequired,
    /** A custom css selector to apply to the wrapper of the component. */
    className: string,
    /** Whether the component should have a background or not. */
    hasBackground: bool,
    /** Whether the first choice in the list clears clears the selected `value` or not. */
    hasClearChoice: bool,
    /** Indicates if the select allows to select multiple values or not. */
    isMulti: bool,
    /** Label. */
    label: string,
    name: types.properties.isRequired,
    /** Called when `value` changes with `(value, name, occurence)`. */
    onChange: func.isRequired,
    /** Displayed when the `value` is `undefined`. Translated. */
    placeholder: node,
    /** String to display next to the selected value (usually a unit such as `px`). */
    suffix: string,
    /** Current value to display. */
    value: oneOfType([array, number, string]),
};

Select.defaultProps = {
    ChoiceComponent: null,
    ToggleComponent: null,
    ValueComponent: null,
    align: 'left',
    className: null,
    hasBackground: true,
    hasClearChoice: false,
    isMulti: false,
    label: null,
    placeholder: 'GLOBAL.INPUT.SELECT_VALUE',
    suffix: null,
    value: null,
};

export { Select };
