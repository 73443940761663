import * as React from 'react';
import * as propTypes from 'prop-types';

import map from 'lodash/map';
import includes from 'lodash/includes';

import * as types from '../../types';
import * as uiTypes from '../types';
import { ColorPaletteItem } from './ColorPaletteItem';

/**
 * Renders a chooser in a form of grid of colors.
 */
export class ColorPalette extends React.PureComponent {
    static propTypes = {
        /** The `accent` color used throughout the UI theme. */
        accent: uiTypes.color,
        /** The color choices. */
        choices: uiTypes.colors,
        /** Whether the color can be set to `transparent` or not. */
        hasClearChoice: propTypes.bool,
        /** Used when calling `onChange` to identify the changed value. */
        name: types.properties,
        /** Called when the color choice `value` changed with `(value, name, event)`. */
        onChange: propTypes.func,
        /** The `primary` color used throughout the UI theme. */
        primary: uiTypes.color,
        /** The currently selected color. */
        value: uiTypes.color,
    };

    constructor(props) {
        super(props);
        this.state = this.constructor.getDerivedStateFromProps(props);
        this.toggleColor = (occurence) => {
            const value = occurence.currentTarget.id;
            this.props.onChange(value === this.props.value ? undefined : value, this.props.name, occurence);
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state && state.choices === props.choices) {
            return null;
        }

        return {
            choices: props.choices,
            hasTransparent: includes(props.choices, 'transparent'),
        };
    }

    render() {
        const { value, choices, onChange, accent, primary, hasClearChoice = true } = this.props;
        const { hasTransparent } = this.state;
        const toggleColor = onChange ? this.toggleColor : null;

        return (
            <div className="color-palette p">
                {hasClearChoice &&
                    !hasTransparent && (
                        <ColorPaletteItem
                            key={'transparent'}
                            value={'transparent'}
                            selected={value === 'transparent'}
                            primary={primary === 'transparent'}
                            accent={accent === 'transparent'}
                            onClick={toggleColor}
                        />
                    )}
                {map(choices, (color) => (
                    <ColorPaletteItem
                        key={color}
                        value={color}
                        selected={color === value}
                        primary={color === primary}
                        accent={color === accent}
                        onClick={toggleColor}
                    />
                ))}
            </div>
        );
    }
}
