import { currentLanguageSelector } from '@lumapps/languages';
import { useSelector } from '@lumapps/redux/react';
import { TranslateObject } from '@lumapps/translations';
import { isGoogleAccount } from '@lumapps/user/ducks/selectors';

import { LegacyVideoProperties, BlockVideoSource } from '../types';

export const useDriveVideoUrl = (properties: LegacyVideoProperties): TranslateObject<string> | null => {
    const currentLanguage = useSelector(currentLanguageSelector);
    const isGoogleUser = useSelector(isGoogleAccount);

    const { embedUrl, encoded, pickedId, source } = properties;

    if (source !== BlockVideoSource.drive || !pickedId || (!encoded && !isGoogleUser)) {
        return null;
    }

    /* TODO once we develop designer v2, there will be a case when we have a pickedId but encoded is false and there
         is no embedUrl, it means the file is still uploading. In this case we have to call an endpoint to get the URL
         once the upload is over. To do this user must have access to the vide (hence the isGoogleUser minimal check)
         In the interval we should display a loading state.
     */

    return embedUrl ? { [currentLanguage]: embedUrl } : null;
};
