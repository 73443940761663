import { uploadDocumentv2 } from '@lumapps/medias-document/api/v2';
import { ContainerType } from '@lumapps/medias/types';
import { error as notifyError } from '@lumapps/notifications';
import { Dispatch } from '@lumapps/redux/types';
import { addLocationOriginToUrl } from '@lumapps/router/utils';
import { GLOBAL } from '@lumapps/translations';
import { isUrl } from '@lumapps/utils/string/isUrl';

const makeURLAbsolute = (link: string) => {
    return isUrl(link) ? link : addLocationOriginToUrl(link);
};
/**
 * Upload file to Document V2 API fixing the permalink and notifying on error.
 */
export const uploadFile =
    (containerId: string, containerType: ContainerType, files: File[], errorMessage?: string) =>
    async (dispatch: Dispatch): Promise<{ id: string; url: string }[]> => {
        try {
            const documents = await uploadDocumentv2({
                container: { id: containerId || '', type: containerType },
                files,
                withRedundantGet: false,
            });
            const formattedDocs = documents.map((doc) => ({ id: doc.docId, url: makeURLAbsolute(doc.permalink) }));
            return formattedDocs;
        } catch (e) {
            dispatch(notifyError({ translate: errorMessage || GLOBAL.UPLOAD_FAILED }));
            // Forward error.
            throw e;
        }
    };
