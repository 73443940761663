import { shape, string } from 'prop-types';

import { widgetType } from '../types';

/**
 * The prop types definition for the widget contact.
 */
export const widgetContactTypes = {
    ...widgetType,
    component: shape({
        ...widgetType.component,
        properties: shape({
            ...widgetType.component.properties,
            to: string,
        }),
        theme: string,
    }),
};
