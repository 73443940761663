/* istanbul ignore file */

import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { AddToMainNavigationDialogProps } from './ConnectedAddToMainNavigationDialog';

const LazyAddToMainNavigationDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'add-to-navigation-dialog' */
            './ConnectedAddToMainNavigationDialog'
        ),
);

export const AddToMainNavigationDialog = (props: AddToMainNavigationDialogProps) => {
    const { isOpen } = props;
    return (
        <DelayedSuspense shouldLoad={isOpen}>
            <LazyAddToMainNavigationDialog {...props} />
        </DelayedSuspense>
    );
};
