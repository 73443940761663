(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserDirectoryFieldController(Metadata, UserDirectory, UserDirectoryTools, User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * List of available views for the directive.
         *
         * @type {Object}
         */
        var _templateList = {
            edit: 'user-directory-field-edit',
            read: 'user-directory-field-read',
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Check if the icon should be displayed or not.
         *
         * @type {boolean}
         */
        vm.displayIcon = undefined;

        /**
         * The template to display for the field.
         *
         * @type {string}
         */
        vm.templateUrl = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Metadata = Metadata;
        vm.User = User;
        vm.UserDirectory = UserDirectory;
        vm.UserDirectoryTools = UserDirectoryTools;
        vm.Utils = Utils;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.displayIcon = (angular.isUndefined(vm.displayIcon)) ? true : Boolean(vm.displayIcon);
            vm.templateUrl = (vm.editing) ? _templateList.edit : _templateList.read;
        }

        init();
    }

    /////////////////////////////

    /**
     * The fields in the user details dialog.
     *
     * @param {Object}  component   The component to display.
     * @param {boolean} displayIcon Defines if the icon should be displayed or not.
     * @param {boolean} editing     Defines if the component is on editing state.
     */

    function UserDirectoryFieldDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: UserDirectoryFieldController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                component: '=',
                displayIcon: '=?',
                editing: '=',
            },
            templateUrl:
                '/client/front-office/modules/module/modules/user-directory/views/partials/user-directory-field.html',
        };
    }

    angular.module('Directives').directive('userDirectoryField', UserDirectoryFieldDirective);
})();
