const LOADING_NAMESPACE = 'SHAREABLE_CONTENT_LIST';
const LOADING_NAMESPACE_CONTENT_LIST_REFRESH = 'SHAREABLE_CONTENT_LIST_REFRESH';
const LOADING_NAMESPACE_CONTENT_LIST_LOAD_MORE = 'SHAREABLE_CONTENT_LIST_LOAD_MORE';

const SHAREABLE_CONTENT_TYPES = {
    images: 'IMAGES',
    link: 'LINK',
};

export {
    LOADING_NAMESPACE,
    LOADING_NAMESPACE_CONTENT_LIST_REFRESH,
    LOADING_NAMESPACE_CONTENT_LIST_LOAD_MORE,
    SHAREABLE_CONTENT_TYPES,
};
