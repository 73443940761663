import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiSettings } from '@lumapps/lumx/icons';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { CUSTOMER } from '../../keys';
import { customerAdvancedSettingsRoute, termsAndConditionsRoute } from '../../routes';

export interface CustomerSettingsSideNavigationProps extends Partial<SideNavigationItemProps> {
    /** whether the user is allowed to view the side navigation or not */
    isAllowed: boolean;
}

/**
 * Component that will be displayed on the side navigation on the back office in order to
 * show the link towards the page Platform Settings aka /admin/platform-settings
 * @param props CustomerSettingsSideNavigationProps
 * @returns CustomerSettingsSideNavigation
 */
export const CustomerSettingsSideNavigation = (props: Partial<CustomerSettingsSideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            id="platform-settings"
            label={translateKey(CUSTOMER.CUSTOMER_SETTINGS)}
            icon={mdiSettings}
            {...restOfProps}
        >
            <SideNavigationItem label={translateKey(GLOBAL.ADVANCED_SETTINGS)} route={customerAdvancedSettingsRoute} />

            <SideNavigationItem label={translateKey(GLOBAL.TERMS_AND_CONDITIONS)} route={termsAndConditionsRoute} />
        </SideNavigationItem>
    );
};

CustomerSettingsSideNavigation.displayName = 'SideNavigationItem';
