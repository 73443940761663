import React from 'react';

import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { getInstanceLogoProps } from '../ducks/selectors';
import { getCurrentInstanceLogoUrl } from '../utils/getCurrentInstanceLogoUrl';

const useInstanceLogo = () => {
    const { homeImageSrc, fallbackHomeImageSrc } = useSelector(getInstanceLogoProps);
    const { translateObject } = useTranslate();

    // Get the proper image url
    const imageSrc = React.useMemo(() => {
        return getCurrentInstanceLogoUrl({ homeImageSrc, translateObject, fallbackHomeImageSrc });
    }, [homeImageSrc, translateObject, fallbackHomeImageSrc]);

    return imageSrc;
};

export { useInstanceLogo };
