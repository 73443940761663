import {
    MAX_SEARCH_HISTORY_STORED_SUGGEST_RESULTS,
    MAX_SEARCH_INTERACTIONS_STORED_SUGGEST_RESULTS,
} from '@lumapps/search/constants';
import { SearchSuggestionType } from '@lumapps/search/types';
import { escapeRegExp } from '@lumapps/utils/string/escapeRegExp';

import { saveQuery, deleteQuery, saveEntityInteraction, deleteEntityInteraction } from '../api';
import { QuickSearchEntity, QuickSearchQuery } from '../types';
import {
    getInteractionsSuggestions,
    getSearchSuggestions,
    storeSearchSuggestions,
    storeInteractionsSuggestions,
} from './cacheUtils';

/**
 * Add an entry to the search history
 * @param query the user query
 */
export const addSearchHistorySuggestion = (item: QuickSearchQuery, shouldSendQuery: boolean) => {
    if (!item.query) {
        return;
    }

    // update the backend
    if (shouldSendQuery) {
        saveQuery(item);
    }
    const history = getSearchSuggestions(item.siteId);

    const previousIndex = history.findIndex((h) => h.query.toLowerCase() === item.query.toLowerCase());
    const previousItem = previousIndex >= 0 ? history[previousIndex] : null;
    if (previousIndex >= 0) {
        history.splice(previousIndex, 1);
    }

    history.unshift({
        query: item.query,
        label: item.query,
        type: SearchSuggestionType.HISTORY,
        siteId: item.siteId,
        counterClick: previousItem?.counterClick ? previousItem.counterClick + 1 : 1,
    });

    // Handle max results by deleting the excess
    if (history.length > MAX_SEARCH_HISTORY_STORED_SUGGEST_RESULTS) {
        history.splice(MAX_SEARCH_HISTORY_STORED_SUGGEST_RESULTS);
    }

    // Remove old items that does not have a siteId
    const filteredHistory = history.filter((h) => h.siteId);

    storeSearchSuggestions(filteredHistory, item.siteId);
};

/**
 * Remove a search query from the history
 * @param suggestion Remove an history entry
 */
export const removeSearchHistorySuggestion = (
    item: Pick<QuickSearchQuery, 'siteId' | 'query'>,
    shouldSendQuery: boolean,
) => {
    const history = getSearchSuggestions(item.siteId);
    const index = history.findIndex((query) => query.query === item.query);

    if (index >= 0) {
        history.splice(index, 1);
        if (shouldSendQuery) {
            deleteQuery(item);
        }
        storeSearchSuggestions(history, item.siteId);
    }
};

/**
 * Add an entry to the search history
 * @param item item that the user interact with
 */
export const addInteractionSuggestion = (item: QuickSearchEntity, searchQuery: string, shouldSendQuery: boolean) => {
    if (shouldSendQuery) {
        saveEntityInteraction(item, searchQuery);
    }

    // Do not store empty links
    if (!item.url || item.url === '') {
        return;
    }

    const { interactions: history } = getInteractionsSuggestions(item.siteId);
    const previousIndex = history.findIndex((h) => {
        if (h.item?.entityId) {
            return item.entityId === h.item.entityId;
        }

        return item.entityType === h.item?.entityType && item.url === h.item?.url;
    });
    const previousItem = previousIndex >= 0 ? history[previousIndex] : null;

    if (previousIndex >= 0) {
        history.splice(previousIndex, 1);
    }

    const query = escapeRegExp(item.title);
    history.unshift({
        query,
        label: item.title,
        item,
        type: SearchSuggestionType.INTERACTION,
        siteId: item.siteId,
        counterClick: previousItem?.counterClick ? previousItem.counterClick + 1 : 1,
    });

    // Handle max results by deleting the excess
    if (history.length > MAX_SEARCH_INTERACTIONS_STORED_SUGGEST_RESULTS) {
        history.splice(MAX_SEARCH_INTERACTIONS_STORED_SUGGEST_RESULTS);
    }

    // Remove old items that does not have a siteId
    const filteredHistory = history.filter((h) => h.siteId);

    storeInteractionsSuggestions(filteredHistory, item.siteId);
};

/**
 * Remove a search query from the history
 * @param suggestion Remove an history entry
 */
export const removeInteractionSuggestion = (item: QuickSearchEntity, shouldSendQuery: boolean) => {
    // delete item from the server
    if (shouldSendQuery) {
        deleteEntityInteraction(item);
    }
    const { interactions: history } = getInteractionsSuggestions(item.siteId);
    const index = history.findIndex((h) => item.entityType === h.item?.entityType && item.url === h.item?.url);

    if (index >= 0) {
        history.splice(index, 1);
    }

    storeInteractionsSuggestions(history, item.siteId);
};
