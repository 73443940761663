import curry from 'lodash/curry';

/**
 * Format a Date into a more readable string
 * Using a locale and a DateTimeFormatOptions object
 * @example: dateTimeFormat('en', { weekday: 'short', month: 'short', day: '2-digit' }, new Date(0)) -> Thu, Jan 01
 * */
export const dateTimeFormat = curry((locale: string, formatOptions: Intl.DateTimeFormatOptions, date: Date): string =>
    new Intl.DateTimeFormat([locale], formatOptions).format(date),
);
