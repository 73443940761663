import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiBullhorn, mdiThumbUp } from '@lumapps/lumx/icons';
import { Link as RouterLink } from '@lumapps/router';
import { programsAdmin } from '@lumapps/sa-programs/routes';
import { useTranslate } from '@lumapps/translations';

import { SA_NAVIGATION } from '../../keys';
import { saSocialNetworks } from '../../routes';

export interface SettingsMenuSocialAdvocacyProps {
    userCanManagePlatformPrograms: boolean;
    userCanManagePlatformSocialNetworks: boolean;
    scope: string;
}

export const SettingsMenuSocialAdvocacy: React.FC<SettingsMenuSocialAdvocacyProps> = ({
    userCanManagePlatformPrograms,
    userCanManagePlatformSocialNetworks,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!userCanManagePlatformPrograms && !userCanManagePlatformSocialNetworks) {
        return null;
    }

    return (
        <Menu.Section label={translateKey(SA_NAVIGATION.SOCIAL_ADVOCACY)} {...get({ element: 'social-advocacy' })}>
            {userCanManagePlatformPrograms && (
                <Menu.Item icon={mdiBullhorn} as={RouterLink} to={programsAdmin()}>
                    {translateKey(SA_NAVIGATION.PROGRAMS) || ''}
                </Menu.Item>
            )}
            {userCanManagePlatformSocialNetworks && (
                <Menu.Item icon={mdiThumbUp} as={RouterLink} to={saSocialNetworks()}>
                    {translateKey(SA_NAVIGATION.SOCIAL_NETWORKS) || ''}
                </Menu.Item>
            )}
        </Menu.Section>
    );
};

SettingsMenuSocialAdvocacy.displayName = 'SettingsMenuSocialAdvocacy';
