import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import get from 'lodash/get';

import reject from 'lodash/reject';

import { any, bool, string, objectOf, func, arrayOf } from 'prop-types';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { translate as t, getLang, hasTranslations } from '../../../../translations';

import { confirm as lxConfirm } from '../../../../services/notification';

import { getConnected } from '../../../../services/user';

import UncheckedItems from './uncheckedItems/UncheckedItems';

import CheckedItems from './checkedItems/CheckedItems';

import NewTaskForm from './newTaskForm/NewTaskForm';

/**
 * Display the widget MyTasks component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The MyTasks react element.
 */
const MyTasks = ({
    content,
    isCheckedItemsDisplayed,
    newTodo,
    setIsCheckedItemsDisplayed,
    setNewTodo,
    setTodoItems,
    setUserContentWidget,
    theme,
    todoItems,
    userContentService,
    userContentWidget,
    uuid,
}) => {
    /**
     * Bind the items to the scope.
     */
    const bindTodoItems = () => {
        setTodoItems({ data: get(userContentWidget, 'params.todo', []), uuid });
    };

    /**
     * Toggle item state.
     */
    const toggleDisplayCheckedItems = () => {
        setIsCheckedItemsDisplayed({ data: !isCheckedItemsDisplayed, uuid });
    };

    /**
     * Save check or uncheck item state.
     *
     * @param {string} taskId The taskId.
     */
    const checkItem = (taskId) => {
        const currentContent = content.getCurrent();

        const userContentWidgetCopy = {};
        Object.assign(userContentWidgetCopy, userContentWidget);

        const taskIndex = userContentWidgetCopy.params.todo.findIndex((task) => task.id === taskId);

        if (taskIndex === -1) {
            return;
        }

        userContentWidgetCopy.params.todo[taskIndex].isChecked = !userContentWidgetCopy.params.todo[taskIndex]
            .isChecked;

        userContentService.save({
            content: currentContent.id,
            user: getConnected().uid,
            values: currentContent.userContent.values,
        });

        setUserContentWidget({ data: userContentWidgetCopy, uuid });
    };

    /**
     * Remove an item from the to do list.
     *
     * @param {Object} todoItem The item of the to do list which we want to remove.
     */
    const removeTodo = (todoItem) => {
        lxConfirm(
            t('WIDGET_TYPE_TODO_DELETE'),
            t('WIDGET_TYPE_TODO_DELETE_DESCRIPTION'),
            {
                cancel: t('CANCEL'),
                ok: t('OK'),
            },
            function onDeleteConfim(answer) {
                if (
                    !answer ||
                    angular.isUndefinedOrEmpty(userContentWidget) ||
                    angular.isUndefinedOrEmpty(get(userContentWidget, 'params.todo'))
                ) {
                    return;
                }

                const currentContent = content.getCurrent();

                // Find current item and remove it.
                const userContentWidgetCopy = {};
                Object.assign(userContentWidgetCopy, userContentWidget);

                userContentWidgetCopy.params.todo = reject(userContentWidgetCopy.params.todo, {
                    id: todoItem.id,
                });

                userContentService.save(
                    {
                        content: currentContent.id,
                        user: getConnected().uid,
                        values: currentContent.userContent.values,
                    },
                    () => {
                        setUserContentWidget({ data: userContentWidgetCopy, uuid });
                    },
                );
            },
        );
    };

    /**
     * Add an item to the to do list.
     */
    const addTodo = () => {
        const currentContent = content.getCurrent();
        let { userContent } = currentContent;

        if (newTodo !== undefined && hasTranslations(newTodo, true)) {
            if (!userContent) {
                userContent = {
                    userContent: currentContent.id,
                    values: {},
                };

                currentContent.userContent = userContent;
            }

            userContent.values = userContent.values || {};

            userContent.values.widgets = userContent.values.widgets || [];

            if (userContentWidget === undefined || isEmpty(userContentWidget)) {
                userContent.values.widgets.push({
                    active: true,
                    params: {
                        todo: [],
                    },
                    uuid,
                });

                setUserContentWidget({ data: userContentService.getUserContentWidget(currentContent, uuid), uuid });

                return;
            }

            const userContentWidgetCopy = {};
            Object.assign(userContentWidgetCopy, userContentWidget);
            Object.assign(userContentWidgetCopy, { params: userContentWidgetCopy.params || {} });
            Object.assign(userContentWidgetCopy, { params: { todo: userContentWidgetCopy.params.todo || [] } });

            userContentWidgetCopy.params.todo.push({
                id: generateUUID(),
                isChecked: false,
                value: newTodo,
            });

            userContentService.save({
                content: currentContent.id,
                user: getConnected().uid,
                values: userContent.values,
            });

            setNewTodo({ data: {}, uuid });

            setUserContentWidget({ data: userContentWidgetCopy, uuid });
        }
    };

    /**
     * Update the current todo label.
     *
     * @param {string} value The input value.
     */
    const updateTodo = (value) => {
        const newTodoCopy = {};
        Object.assign(newTodoCopy, newTodo);
        newTodoCopy[getLang('current')] = value;
        setNewTodo({ data: newTodoCopy, uuid });
    };

    /**
     * UseEffect that get the userContentWidget when the component initialize.
     */
    useEffect(() => {
        setUserContentWidget({ data: userContentService.getUserContentWidget(content.getCurrent(), uuid), uuid });
    }, [content, userContentService, uuid]);

    /**
     * UseEffect that fetch the last todos each time the userContentWidget is updated.
     */
    useEffect(() => {
        bindTodoItems();
        addTodo();
    }, [userContentWidget]);

    return (
        <>
            {todoItems.length > 0 && (
                <div className="widget-todo__list">
                    {todoItems.filter((item) => !item.isChecked).length > 0 && (
                        <UncheckedItems
                            checkItem={checkItem}
                            removeTodo={removeTodo}
                            todoItems={todoItems}
                            uuid={uuid}
                        />
                    )}

                    {todoItems.filter((item) => item.isChecked).length > 0 && (
                        <CheckedItems
                            checkItem={checkItem}
                            displayCheckedItems={isCheckedItemsDisplayed}
                            removeTodo={removeTodo}
                            todoItems={todoItems}
                            toggleDisplayCheckedItems={toggleDisplayCheckedItems}
                            uuid={uuid}
                        />
                    )}
                </div>
            )}
            <NewTaskForm addTodo={addTodo} newTodo={newTodo} theme={theme} updateTodo={updateTodo} />
        </>
    );
};

MyTasks.defaultProps = {
    /* The lumx theme. */
    theme: undefined,
};

MyTasks.propTypes = {
    content: objectOf(any).isRequired,
    isCheckedItemsDisplayed: bool.isRequired,
    newTodo: objectOf(any).isRequired,
    setIsCheckedItemsDisplayed: func.isRequired,
    setNewTodo: func.isRequired,
    setTodoItems: func.isRequired,
    setUserContentWidget: func.isRequired,
    theme: objectOf(any),
    todoItems: arrayOf(any).isRequired,
    userContentService: objectOf(any).isRequired,
    userContentWidget: objectOf(any).isRequired,
    uuid: string.isRequired,
};

export default MyTasks;
