import React from 'react';

import { EditableImageGallery } from '@lumapps/wrex-image-gallery/components/editableBlocks/EditableImageGallery';
import { ReactEditor, useSlateStatic } from '@lumapps/wrex/slate';
import { ElementRender } from '@lumapps/wrex/types';

import { IMAGE_GROUP } from '../../../constants';
import { ImageEditor, ImageGroupElement } from '../../../types';
import { EditableEnhancedImage } from '../EditableEnhancedImage';

/**
 * Editable component for enhanced image and image gallery
 */
export const EditableImageGroup: ElementRender<ImageGroupElement, HTMLDivElement> = (props) => {
    const { element = {} as ImageGroupElement } = props;

    const editor = useSlateStatic() as ReactEditor & ImageEditor;

    return editor.isImageGallery(element) ? <EditableImageGallery {...props} /> : <EditableEnhancedImage {...props} />;
};
EditableImageGroup.displayName = IMAGE_GROUP;
