import { CLIENT_COMPUTED_WIDGET_TYPE } from '../constants';
import { isClientComputed } from './isClientComputed';

/**
 * Get backend widget type for a given legacy widget type.
 *
 * Client computed are replaced by `client-computed`.
 * All other stay unchanged.
 */
export function getBackendWidgetType(widgetType: string) {
    if (isClientComputed(widgetType)) {
        return CLIENT_COMPUTED_WIDGET_TYPE;
    }
    return widgetType;
}
