import React from 'react';

import { Icon, IconProps } from '@lumapps/lumx/react';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

export interface DynamicIconProps extends Omit<IconProps, 'icon'> {
    /** The mdi icon class to fetch. */
    icon: string;
}

/**
 * Icon that will dynamically fetch the mdi icon from given icon class.
 *
 * @family Buttons
 * @param DynamicIconButtonProps
 * @returns DynamicIconButton
 */
export const DynamicIcon: React.FC<DynamicIconProps> = ({ icon, ...forwardedProps }) => {
    const { iconPath } = useDynamicIcon({ icon });

    return <Icon icon={iconPath} {...forwardedProps} />;
};
