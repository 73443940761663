import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';

import { getInputLanguage, translate as t } from '../translations';
// eslint-disable-next-line id-blacklist
import { error } from './notification';
import { post } from '../components/widgets/file-list/FetchHelper';

/**
 * Class to upload files using document API.
 */
export class DocumentUpload {
    /**
     * Constructor.
     *
     * @param {File|FileList|Array} [files]             File(s) to upload.
     * @param {Object}              [getUploadSettings] Get upload settings request Params.
     */
    constructor({ files = [], getUploadSettings = {} }) {
        this._files = [];
        this._getUploadSettings = getUploadSettings;

        this.addFiles(files);
    }

    /**
     * Request file upload configuration.
     *
     * @param  {string}  name Name of the file to upload.
     * @async
     * @return {Promise} File upload configuration promise.
     */
    async _getUploadConfig({ name: fileName }) {
        const payload = cloneDeep(this._getUploadSettings);

        Object.assign(payload, {
            fileName,
            lang: getInputLanguage(),
            success: '/upload',
        });

        const requestParams = {
            payload,
            url: '/_ah/api/lumsites/v1/document/uploadUrl/get',
        };

        try {
            return await post(requestParams);
        } catch (exception) {
            error(t('FILE_UPLOAD_ERROR'));
            throw exception;
        }
    }

    /**
     * Start file uploading.
     *
     * @param  {File}    file     The file to upload.
     * @param  {string}  url      Upload url.
     * @param  {string}  [method] Request HTTP method.
     * @async
     * @return {Promise} Upload request promise.
     */
    static async _startUpload({ file, url, method = 'POST' }) {
        const requestParams = {
            config: {
                headers: {
                    'Content-Range': `bytes 0-${file.size - 1}/${file.size}`,
                    'Content-Type': file.type,
                },
                method,
            },
            payload: file,
            url,
        };

        try {
            return await post(requestParams);
        } catch (exception) {
            error(t('FILE_UPLOAD_ERROR'));
            throw exception;
        }
    }

    /**
     * Add one or several files to upload list.
     *
     * @param {File|FileList|Array} files File(s) to upload.
     */
    addFiles(files) {
        if (files instanceof FileList) {
            // eslint-disable-next-line unicorn/prefer-spread
            this._files.push(...Array.from(files));
        } else if (Array.isArray(files)) {
            this._files.push(...files);
        } else {
            this._files.push(files);
        }
    }

    /**
     * Set the "get upload settings" request params.
     *
     * @param {Object} settings The "get upload settings" request params.
     */
    setGetUploadSettings(settings) {
        this._getUploadSettings = settings;
    }

    /**
     * Request upload config then upload files.
     *
     * @async
     * @return {Promise} Upload requests promise.
     */
    upload() {
        return Promise.all(
            map(this._files, async (file) => {
                const { httpMethod: method, uploadUrl: url } = await this._getUploadConfig(file);

                return DocumentUpload._startUpload({ file, method, url });
            }),
        );
    }
}
