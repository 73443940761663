import { fetchMembersTask } from '../community_tasks';

/**
 * Actions types.
 */
const FETCH_MEMBERS_LOADING = 'community/FETCH_MEMBERS_LOADING';
const FETCH_MEMBERS_SUCCESS = 'community/FETCH_MEMBERS_SUCCESS';
const FETCH_MEMBERS_ERROR = 'community/FETCH_MEMBERS_ERROR';

/**
 * Dispatcher methods.
 */
const dispatchFetchMembers = ({ page, maxResults, uid }) => (dispatch) => {
    dispatch({
        type: FETCH_MEMBERS_LOADING,
    });

    return fetchMembersTask({ page, maxResults, uid })
        .then(({ payload } = {}) => {
            dispatch({
                payload,
                type: FETCH_MEMBERS_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: FETCH_MEMBERS_ERROR,
            });
        });
};
/**
 * Exports.
 */

export { dispatchFetchMembers, FETCH_MEMBERS_ERROR, FETCH_MEMBERS_LOADING, FETCH_MEMBERS_SUCCESS };
