import { isMobileAllowed } from '@lumapps/mobile/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { MobileNavigationAdministrationSideNavigation } from './MobileNavigationAdministrationSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isMobileAllowed(state);

    return {
        isAllowed,
    };
};

const ConnectedMobileNavigationAdministrationSideNavigation = connect(mapStateToProps)(
    MobileNavigationAdministrationSideNavigation,
);

export { ConnectedMobileNavigationAdministrationSideNavigation as MobileNavigationAdministrationSideNavigation };
