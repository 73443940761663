/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';
import { canManagePlatformSocialNetworks } from '@lumapps/sa-connected-user/ducks/selectors';

import { SocialNetworksSideNavigation } from './SocialNetworksSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: canManagePlatformSocialNetworks(state),
});

const mapDispatchToProps = {};

const ConnectedSocialNetworksSideNavigation = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SocialNetworksSideNavigation);

export { ConnectedSocialNetworksSideNavigation as SocialNetworksSideNavigation };
