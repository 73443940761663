import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Program } from '../../types';

export enum UserProgramsStatuses {
    initial = 'initial',
    loading = 'loading',
    noPrograms = 'noPrograms',
    withPrograms = 'withPrograms',
    failure = 'failure',
}

export enum FetchSingleProgramStatuses {
    initial = 'initial',
    loading = 'loading',
    success = 'success',
    notFound = 'notFound',
    failure = 'failure',
}

export interface UserProgramsState {
    userProgramsStatus: UserProgramsStatuses;
    fetchSingleProgramStatus: FetchSingleProgramStatuses;
    entities: Program[];
}

const initialState: UserProgramsState = {
    userProgramsStatus: UserProgramsStatuses.initial,
    fetchSingleProgramStatus: FetchSingleProgramStatuses.initial,
    entities: [],
};

export const userProgramsReducer = {
    fetchPrograms: (state: UserProgramsState): void => {
        set(state, 'userProgramsStatus', UserProgramsStatuses.loading);
    },
    fetchProgramsSuccess: (state: UserProgramsState, action: PayloadAction<Program[]>): void => {
        set(
            state,
            'userProgramsStatus',
            action.payload?.length > 0 ? UserProgramsStatuses.withPrograms : UserProgramsStatuses.noPrograms,
        );
        set(state, 'entities', action.payload || []);
    },
    fetchProgramsFailure: (state: UserProgramsState): void => {
        set(state, 'userProgramsStatus', UserProgramsStatuses.failure);
    },
    fetchSingleProgram: (state: UserProgramsState): void => {
        set(state, 'fetchSingleProgramStatus', UserProgramsStatuses.loading);
    },
    fetchSingleProgramSuccess: (state: UserProgramsState, action: PayloadAction<Program>): void => {
        set(state, 'fetchSingleProgramStatus', FetchSingleProgramStatuses.success);
        const fetchedProgramIndex = state.entities.findIndex((program) => program.id === action.payload.id);

        if (fetchedProgramIndex !== -1) {
            set(state, `entities.${fetchedProgramIndex}`, action.payload);
        } else {
            set(state, 'entities', [...state.entities, action.payload]);
        }
    },
    fetchSingleProgramNotFound: (state: UserProgramsState): void => {
        set(state, 'fetchSingleProgramStatus', FetchSingleProgramStatuses.notFound);
    },
    fetchSingleProgramFailure: (state: UserProgramsState): void => {
        set(state, 'fetchSingleProgramStatus', FetchSingleProgramStatuses.failure);
    },
    resetFetchSingleProgram: (state: UserProgramsState): void => {
        set(state, 'fetchSingleProgramStatus', FetchSingleProgramStatuses.initial);
    },
    setProgram: (state: UserProgramsState, action: PayloadAction<Program>): void => {
        set(state, 'fetchSingleProgramStatus', FetchSingleProgramStatuses.success);
        set(state, 'entities', [action.payload]);
    },
};

const { actions, reducer } = createSlice({
    domain: 'userPrograms',
    initialState,
    reducers: userProgramsReducer,
});

export { actions, reducer, initialState };
