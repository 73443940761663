import { get } from '@lumapps/constants';
import { TOPICS, queueEvent, Event, queueBatchedEvents } from '@lumapps/metric/analytics';
import { desktopMinSize } from '@lumapps/responsive/constants';
import { getCurrentTimezone } from '@lumapps/utils/timezones/getCurrentTimezone';

import { sendTracking } from './api';
import {
    LumappsTrackEventPayload,
    LumappsTrackingActionType,
    LumappsTrackingEvent,
    LumappsTrackingEventContext,
    TrackingAppType,
} from './types';
import { getSessionId, handleSessionId } from './utils';

const LumappsConfig = get();

export const eventHandler = (events: Event[]) => {
    for (const event of events) {
        const data = event.payload as LumappsTrackingEvent;
        sendTracking(data);
    }
};

const fillAndSendLumappsTrackingEvent = (
    eventActions: LumappsTrackingActionType[],
    overrideContext: Partial<Pick<LumappsTrackingEventContext, 'locale' | 'referrer'>> = {},
    isBatched: boolean = false,
) => {
    // update the session id before doing anything else
    handleSessionId();

    const sessionId = getSessionId();

    const data: Record<string, LumappsTrackingEvent[]> = {
        events: eventActions.map((eventAction) => ({
            action: eventAction,
            sessionId: sessionId.id,
            performedAt: new Date().toISOString(),
            context: {
                app: {
                    version: LumappsConfig.frontendVersion,
                    type: window.innerWidth < desktopMinSize ? TrackingAppType.RESPONSIVE : TrackingAppType.DESKTOP,
                },
                screen: {
                    width: window.innerWidth.toString(),
                    height: window.innerHeight.toString(),
                    density: window.devicePixelRatio.toString(),
                },
                page: {
                    search: window.location.search,
                    path: window.location.pathname,
                    title: document.title,
                    url: window.location.href,
                    referrer: document.referrer,
                },
                timezone: getCurrentTimezone(),
                siteId: LumappsConfig.instanceId,
                ...overrideContext,
            },
        })),
    };

    if (isBatched) {
        queueBatchedEvents({ payload: data, topic: TOPICS.TRACKING_FRONT }, eventHandler);
    } else {
        queueEvent({ payload: data, topic: TOPICS.TRACKING_FRONT }, eventHandler);
    }
};

export const lumappsAnalyticsPlugin = {
    /* Everything else below this is optional depending on your plugin requirements */
    track: ({ payload }: { payload: LumappsTrackEventPayload | LumappsTrackEventPayload[] }) => {
        const actions = Array.isArray(payload) ? payload.map((p) => p.action) : [payload.action];
        fillAndSendLumappsTrackingEvent(actions);
    },
    trackAndBatch: ({ payload }: { payload: LumappsTrackEventPayload }) => {
        const actions = Array.isArray(payload) ? payload.map((p) => p.action) : [payload.action];
        fillAndSendLumappsTrackingEvent(actions, {}, true);
    },
};
