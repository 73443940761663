import { SEARCH } from '../keys';

/**
 * Types of results that could come from Zendesk and that are supported.
 */
export enum ZENDESK_RESULT_TYPES {
    ARTICLE = 'article',
    TICKET = 'ticket',
}

/**
 * Translations that will be used to show the different type of result types.
 */
export const ZENDESK_RESULT_TYPES_TRANSLATIONS = {
    [ZENDESK_RESULT_TYPES.ARTICLE]: SEARCH.ZENDESK_TYPE_ARTICLE,
    [ZENDESK_RESULT_TYPES.TICKET]: SEARCH.ZENDESK_TYPE_TICKET,
};

/**
 * The different statuses that the zendesk tickets can have.
 */
export enum ZENDESK_TICKET_STATUSES {
    CLOSED = 'closed',
    HOLD = 'hold',
    OPEN = 'open',
    NEW = 'new',
    PENDING = 'pending',
    SOLVED = 'solved',
}

/**
 * Translations mapped to each zendesk ticket status
 */
export const ZENDESK_TICKET_TRANSLATIONS = {
    [ZENDESK_TICKET_STATUSES.CLOSED]: SEARCH.ZENDESK_TICKET_STATUSES_CLOSED,
    [ZENDESK_TICKET_STATUSES.HOLD]: SEARCH.ZENDESK_TICKET_STATUSES_HOLD,
    [ZENDESK_TICKET_STATUSES.OPEN]: SEARCH.ZENDESK_TICKET_STATUSES_OPEN,
    [ZENDESK_TICKET_STATUSES.PENDING]: SEARCH.ZENDESK_TICKET_STATUSES_PENDING,
    [ZENDESK_TICKET_STATUSES.SOLVED]: SEARCH.ZENDESK_TICKET_STATUSES_SOLVED,
    [ZENDESK_TICKET_STATUSES.NEW]: SEARCH.ZENDESK_TICKET_STATUSES_NEW,
};
