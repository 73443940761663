import keys from 'lodash/keys';

/**
 * Converts a list of permissions into a string that can be then added as a data attribute
 * @param permissions
 * @returns string
 */
export const stringifyPermissions = (permissions: Record<string, boolean>): string => {
    const permissionsList: string[] = [];

    keys(permissions).forEach((permission) => {
        if (permissions[permission]) {
            permissionsList.push(permission);
        }
    });

    return permissionsList.join(',');
};
