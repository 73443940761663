import { SearchDocumentType, TargetType } from '@lumapps/analytics-tracking/types';

export const searchDocumentTypeAnalytics = {
    [SearchDocumentType.PLAY_VIDEO]: TargetType.PLAY_VIDEO,
    [SearchDocumentType.PLAY_PLAYLIST]: TargetType.PLAYLIST,
    [SearchDocumentType.ARTICLE]: TargetType.ARTICLE,
    [SearchDocumentType.COMMUNITY]: TargetType.COMMUNITY,
    [SearchDocumentType.CONTENT]: TargetType.CONTENT,
    [SearchDocumentType.DIRECTORY_ENTRY]: TargetType.DIRECTORY_ENTRY,
    [SearchDocumentType.EVENT]: TargetType.EVENT,
    [SearchDocumentType.MEDIA]: TargetType.MEDIA,
    [SearchDocumentType.POST]: TargetType.POST,
    [SearchDocumentType.USER]: TargetType.USER,
    [SearchDocumentType.EXTENSION]: TargetType.SEARCH_EXTENSION,
    [SearchDocumentType.GCS_ARTICLE]: TargetType.ARTICLE,
    [SearchDocumentType.GCS_COMMUNITY]: TargetType.COMMUNITY,
    [SearchDocumentType.GCS_DIRECTORY_ENTRY]: TargetType.DIRECTORY_ENTRY,
    [SearchDocumentType.GCS_POST]: TargetType.POST,
    [SearchDocumentType.GCS_USER]: TargetType.USER,
};

/** Converts search docutment types to analytics document type. As this field is mandatory for analytics,
 * we consider that result type not matching a lumapps result type to be a search exetension type
 */
export const convertDocumentTypeForAnalytics = (documentType?: SearchDocumentType) => {
    if (documentType && documentType in searchDocumentTypeAnalytics) {
        return searchDocumentTypeAnalytics[documentType];
    }
    return TargetType.SEARCH_EXTENSION;
};
