import set from 'lodash/set';

import { Post } from '@lumapps/posts/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

export interface PostDetailsState {
    hasInit: boolean;
    isLoading: boolean;
    post: Post | null;
    hasError: boolean;
}

export const domain = 'postDetails';

const initialState: PostDetailsState = {
    hasInit: false,
    isLoading: false,
    post: null,
    hasError: false,
};

const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        fetchPostDetailsRequest: (state: PostDetailsState) => {
            set(state, 'hasInit', true);
            set(state, 'isLoading', true);
        },
        fetchPostDetailsSuccess: (state: PostDetailsState, action: PayloadAction<{ post: Post }>) => {
            set(state, 'isLoading', false);
            set(state, 'post', action.payload.post);
        },
        fetchPostDetailsFailure: (state: PostDetailsState) => {
            set(state, 'isLoading', false);
            set(state, 'hasError', true);
        },
        setPost: (state: PostDetailsState, action: PayloadAction<{ post: Post }>) => {
            set(state, 'post', action.payload.post);
        },
        updatePost: (state: PostDetailsState, action: PayloadAction<{ updates: Partial<Post> }>) => {
            if (state.post) {
                mergeObjectOnly(state.post, action.payload.updates);
            }
        },
    },
});

export { actions, initialState, reducer };
