import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import {
    CreatedAt,
    EditedAt,
    FileProvider,
    FileStatus,
    File as FileType,
    DriveFile as DriveFileType,
    MimeType,
    Name,
} from '@lumapps/wrex/serialization/dita/types';

import { FileElement } from '../../types';
import { createFile } from '../../utils/createFile';

/**
 * Convert Dita File into slate File element.
 */
export const FILE_CONVERT: ElementConvertOption = {
    test: matchAttribute('type', /lumapps:file/),
    transform: (nd): FileElement => {
        const node = nd as FileType | DriveFileType;
        const titleNode = node.children.find(matchAttribute('name', /name/)) as Name;
        const mimeTypeNode = node.children.find(matchAttribute('name', /mimeType/)) as MimeType;
        const createdAtNode = node.children.find(matchAttribute('name', /createdAt/)) as CreatedAt;
        const driveIdNode = node.children.find(matchAttribute('name', /driveId/)) as FileStatus;
        const providerNode = node.children.find(matchAttribute('name', /provider/)) as FileProvider;
        const editedAtNode = node.children.find(matchAttribute('name', /editedAt/)) as EditedAt;
        const statusNode = node.children.find(matchAttribute('name', /status/)) as FileStatus;

        const { href, keyref } = node?.attributes || {};

        return createFile({
            name: titleNode?.attributes.value,
            mimeType: mimeTypeNode?.attributes.value,
            createdAt: createdAtNode?.attributes.value,
            editedAt: editedAtNode?.attributes.value,
            driveId: driveIdNode?.attributes.value,
            provider: providerNode?.attributes.value,
            status: statusNode?.attributes.value,
            src: href,
            id: keyref,
        });
    },
};
