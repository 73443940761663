import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ColorPalette, Icon, Text, Theme } from '@lumapps/lumx/react';

import { FILE_PREVIEW_BLOCK_CLASSNAME as CLASSNAME } from '../../constants';

import './index.scss';

export const FilePreviewProviderElement: React.FC<{
    label?: string;
    icon?: string;
    theme?: Theme;
}> = ({ label, icon, theme }) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <Text
            as="p"
            className={element('file-provider')}
            color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
            noWrap
            truncate
        >
            {icon && <Icon icon={icon} />}
            <Text as="span" title={label}>
                {label}
            </Text>
        </Text>
    );
};
