import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import some from 'lodash/some';

/////////////////////////////

function DropZoneDirectiveController($scope, $timeout, Upload) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The drag-leave delay to remove the placeohlder.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _REMOVE_PLACEHOLDER_DELAY = 250;

    /**
     * The element the events will be attach to (in the form of a jQElement).
     *
     * @type {jQElement}
     */
    let _el;

    /**
     * Contains the promise of the timeout when we leave the drag zone.
     *
     * @type {$timeout}
     */
    let _leaveTimeout;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the placeholder has been placed in the DOM.
     *
     * @type {boolean}
     */
    vm.isPlaceholderPlaced = false;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Prevent any default processing and propagation of an event.
     *
     * @param {Event} evt The event to block.
     */
    function _blockEvent(evt) {
        if (angular.isUndefinedOrEmpty(evt)) {
            return;
        }

        evt.preventDefault();
        evt.stopPropagation();
    }

    /**
     * Add a placeholder for the element when it can be dropped.
     *
     * @param {Event} evt The dragenter or dragover event.
     */
    function _appendPlaceholder(evt) {
        _blockEvent(evt);

        if (!vm.isPlaceholderPlaced) {
            _el.addClass(vm.dropZonePlaceholderClass);
            vm.isPlaceholderPlaced = true;
        }
    }

    /**
     * Remove the placeholder when the element is not droppable anymore.
     *
     * @param {Event} [evt] The dragend, dragexit, dragleave or drop event.
     */
    function _removePlaceholder(evt) {
        if (evt) {
            _blockEvent(evt);
        }

        if (vm.isPlaceholderPlaced) {
            _el.removeClass(vm.dropZonePlaceholderClass);
            vm.isPlaceholderPlaced = false;
        }
    }

    /**
     * Let the `ls-uploader` directive handle the files upload.
     *
     * @param {FileList} files The file list to attach to the uploader.
     */
    function _handleUploader(files) {
        const fileInput = angular.element(`${vm.uploaderSelector} input[type="file"]`);
        let filesToUpload;

        if (angular.isDefinedAndFilled(vm.allowedFileTypes)) {
            /**
             * To make sure the `files` attribute to provide to the `input[type="file"]` element,
             * we have to pass through a `DataTransfer` object to be able to mutate the original
             * `FileList` provided by the `drop` event.
             */
            const dataTransfer = new ClipboardEvent('').clipboardData || new DataTransfer();

            angular.forEach(files, (file) => {
                if (some(vm.allowedFileTypes, (type) => includes(file.type, type))) {
                    dataTransfer.items.add(file);
                }
            });

            filesToUpload = dataTransfer.files;
        } else {
            filesToUpload = files;
        }

        if (filesToUpload.length > 0) {
            first(fileInput).files = filesToUpload;
            fileInput.triggerHandler('change');
        }

        _removePlaceholder();
    }

    /**
     * When the element is dragged over a valid dropzone, cancel the leave timeout and add the placeholder for
     * the element in the drop zone.
     *
     * @param {Event} evt The dragover event.
     */
    function _onDragOver(evt) {
        $timeout.cancel(_leaveTimeout);
        _appendPlaceholder(evt);
    }

    /**
     * When the dragged element leave a valid drop zone, remove its placeholder in the said drop zone after a
     * small delay.
     *
     * @param {Event} evt The dragleave event.
     */
    function _onDragLeave(evt) {
        _leaveTimeout = $timeout(() => {
            _removePlaceholder(evt);
        }, _REMOVE_PLACEHOLDER_DELAY);
    }

    /**
     * When the element is dropped in a valid dropzone, upload the file and remove the placeholder.
     *
     * @param {Event} evt The drop event.
     */
    function _onDrop(evt) {
        _blockEvent(evt);

        const files = get(evt, 'originalEvent.dataTransfer.files', []);

        if (files.length === 0) {
            return;
        }

        if (angular.isDefinedAndFilled(vm.uploaderSelector)) {
            _handleUploader(files);

            return;
        }

        $scope.$apply(() => {
            Upload.uploadMultiple(
                evt.originalEvent.dataTransfer.files,
                {},
                (response) => {
                    if (vm.dropZoneCallback) {
                        vm.dropZoneCallback()(response);
                    }

                    _removePlaceholder(evt);
                },
                undefined,
                vm.uuid,
            );
        });
    }

    /**
     * Attach all events to the drop-zone element.
     */
    function _attachEvents() {
        _el.on('dragenter', _appendPlaceholder);
        _el.on('dragend', _removePlaceholder);
        _el.on('dragexit', _removePlaceholder);
        _el.on('dragover', _onDragOver);
        _el.on('dragleave', _onDragLeave);
        _el.on('drop', _onDrop);
    }

    /**
     * Attach all events to the drop-zone element.
     */
    function _detachEvents() {
        _el.off('dragenter', _appendPlaceholder);
        _el.off('dragend', _removePlaceholder);
        _el.off('dragexit', _removePlaceholder);
        _el.off('dragover', _onDragOver);
        _el.off('dragleave', _onDragLeave);
        _el.off('drop', _onDrop);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Attached the directive element provided by the link function to the closure.
     *
     * @param {JQElement} el The dropzone jquery element to set.
     */
    function setElement(el) {
        _el = el;
        _attachEvents();
    }

    /////////////////////////////

    vm.setElement = setElement;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * The controller is destroyed, cancel the watchers.
     */
    $scope.$on('$destroy', () => {
        _detachEvents();
    });
}

/**
 * Provides a "drop-zone" area. By dropping files to this drop-zone, users are able to upload
 * files.
 *
 * @param  {Array}             [allowedFileTypes]         The allowed file types for upload.
 * @param  {Function}          [dropZoneCallback]         The callback to call on drop.
 * @param  {string}            [dropZonePlaceholderClass] The class to apply to the div containing the drop-zone.
 * @param  {string}            [dropZoneSelector]         The selector we want to attach the drag events to.
 * @param  {string}            [uploaderSelector]         The `ls-uploader` selector if any.
 * @return {DropZoneDirective} The directive.
 */
function DropZoneDirective() {
    'ngInject';

    function DropZoneDirectiveLink(scope, el, attrs, ctrl) {
        ctrl.setElement(angular.element(attrs.lsDropZoneSelector || el));
    }

    return {
        bindToController: true,
        controller: DropZoneDirectiveController,
        link: DropZoneDirectiveLink,
        scope: {
            allowedFileTypes: '<?lsAllowedFileTypes',
            dropZoneCallback: '&?lsDropZoneCallback',
            dropZonePlaceholderClass: '@?lsDropZonePlaceholderClass',
            dropZoneSelector: '@?lsDropZoneSelector',
            uploaderSelector: '@?lsUploaderSelector',
        },
    };
}

/////////////////////////////

angular.module('Directives').directive('lsDropZone', DropZoneDirective);

/////////////////////////////

export { DropZoneDirective, DropZoneDirectiveController };
