/* istanbul ignore file */
import { reducer as boReducer } from '@lumapps/community-admin/ducks/backOfficeSlice';

import { reducer as foReducer } from './frontOfficeSlice';

export * from './types';

export const reducers = {
    frontOffice: foReducer,
    backOffice: boReducer,
};
