import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

const localeSelector = (state: BaseStore) => state?.locale;

/**
 * Select the main language chosen by the user. For public sites, it will be the value of the language selector.
 */
const userMainLocaleSelector = createSelector(localeSelector, (locales) => locales?.current);

/**
 * Select the user's alternative language. If not defined, set to the main language.
 */
const userAlternativeLocaleSelector = createSelector(localeSelector, (locales) => locales?.default);

/**
 * Select the browser locale.
 */
const browserLocaleSelector = createSelector(localeSelector, (locales) => locales.fallback);
const inputCustomerLocaleSelector = createSelector(localeSelector, (locales) => locales.inputCustomerLanguage);
const inputLocaleSelector = createSelector(localeSelector, (locales) => locales.inputLanguage);

export {
    userMainLocaleSelector,
    userAlternativeLocaleSelector,
    browserLocaleSelector,
    inputCustomerLocaleSelector,
    inputLocaleSelector,
    localeSelector,
};
