export enum PLAY {
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=266224700 */
    ALLOW_TO_DOWNLOAD_VIDEO = 'PLAY.ALLOW_TO_DOWNLOAD_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178450800 */
    AVAILABILITY_SEARCH_TITLE = 'PLAY.AVAILABILITY_SEARCH_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=67227571 */
    BACK_TO_HOME = 'GLOBAL.BACK_TO_HOME',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=387867776 */
    CHANGE_AUTHORS = 'PLAY.CHANGE_AUTHORS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164112256 */
    DOWNLOAD_VIDEO = 'PLAY.DOWNLOAD_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389778276 */
    EDIT_VIDEO_AUTHOR = 'PLAY.EDIT_VIDEO_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178455421 */
    INTEGRATION_IN_SITE_DESCRIPTION = 'PLAY.INTEGRATION_IN_SITE_DESCRIPTION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=178455083 */
    INTEGRATION_IN_SITE_TITLE = 'PLAY.INTEGRATION_IN_SITE_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=157236294 */
    MAX_FILE_SIZE = 'PLAY.MAX_FILE_SIZE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119722998 */
    MOST_VIEWED = 'PLAY.MOST_VIEWED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124899734 */
    NO_AVAILABLE_VIDEOS = 'PLAY.NO_AVAILABLE_VIDEOS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191596904 */
    NO_VIDEO = 'PLAY.NO_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300893780 */
    PLAY_ALL = 'PLAY.PLAY_ALL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109484388 */
    PLAY_VIDEO = 'PLAY.PLAY_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=525108333 */
    PUBLICATION = 'PLAY.PUBLICATION',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194287967 */
    RESTRICTED_VIDEO = 'PLAY.RESTRICTED_VIDEO',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=134862475 */
    SEARCH_VIDEOS = 'PLAY.SEARCH_VIDEOS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389690338 */
    SELECTED_NB_VIDEO_FROM_NB_AUTHOR = 'PLAY.SELECTED_NB_VIDEO_FROM_NB_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389702873 */
    SELECTED_VIDEO_FROM_AUTHOR = 'PLAY.SELECTED_VIDEO_FROM_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=157236244 */
    SUPPORTED_VIDEO_FORMATS = 'PLAY.SUPPORTED_VIDEO_FORMATS',
    /** https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452686 */
    UNPUBLISH = 'CONTENT_SET_TO_DRAFT',
    /** https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451625 */
    ARCHIVE = 'ADMIN_CONTENT_STATUS_PAGE_ARCHIVE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=162519288 */
    UPLOAD_TO_LIBRARY = 'PLAY.UPLOAD_TO_LIBRARY',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=322555797 */
    UPLOAD_DIALOG_CONSUMPTION_WARNING = 'PLAY.UPLOAD_DIALOG_CONSUMPTION_WARNING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389703731 */
    VIDEO_AUTHOR_HELPER = 'PLAY.VIDEO_AUTHOR_HELPER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389702541 */
    VIDEO_AUTHORS_CHANGED = 'PLAY.VIDEO_AUTHORS_CHANGED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199794051 */
    VIDEO_BEING_ENCODED = 'PLAY.VIDEO_BEING_ENCODED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=126060989 */
    VIDEO_DATE_PUBLISHED = 'PLAY.VIDEO_DATE_PUBLISHED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199793747 */
    VIDEO_DELETED = 'PLAY.VIDEO_DELETED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=120686514 */
    VIDEO_GALLERY_TITLE = 'PLAY.VIDEO_GALLERY_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199794012 */
    VIDEO_HAS_RESTRICTED_ACCESS = 'PLAY.VIDEO_HAS_RESTRICTED_ACCESS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=124751434 */
    VIDEO_LIBRARY_EDIT_ROLES_CTA = 'PLAY.VIDEO_LIBRARY_EDIT_ROLES_CTA',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109471001 */
    VIDEO_LIBRARY_SEARCH_PLACEHOLDER = 'PLAY.VIDEO_LIBRARY_SEARCH_PLACEHOLDER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109470521 */
    VIDEO_LIBRARY_TITLE = 'PLAY.VIDEO_LIBRARY_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199793839 */
    VIDEO_NO_LONGER_EXISTS = 'PLAY.VIDEO_NO_LONGER_EXISTS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=112146767 */
    VIDEO_PLAYER_TITLE = 'PLAY.VIDEO_PLAYER_TITLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111255270 */
    VIDEO_STATUS_ARCHIVED = 'PLAY.VIDEO_STATUS_ARCHIVED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=529738768 */
    VIDEO_STATUS_ARCHIVED_ON = 'PLAY.VIDEO_STATUS_ARCHIVED_ON',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111255273 */
    VIDEO_STATUS_DELETED = 'PLAY.VIDEO_STATUS_DELETED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111210100 */
    VIDEO_STATUS_DRAFT = 'PLAY.VIDEO_STATUS_DRAFT',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=126390992 */
    VIDEO_STATUS_ENCODING = 'PLAY.VIDEO_STATUS_ENCODING',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=529827450 */
    ENCODING_ERROR = 'PLAY.ENCODING_ERROR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=126391611 */
    VIDEO_STATUS_ERROR = 'PLAY.VIDEO_STATUS_ERROR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111253592 */
    VIDEO_STATUS_PUBLISHED = 'PLAY.VIDEO_STATUS_PUBLISHED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111253774 */
    VIDEO_STATUS_UNPUBLISHED = 'PLAY.VIDEO_STATUS_UNPUBLISHED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=517366102  */
    VIDEO_STATUS_EXPIRED = 'PLAY.VIDEO_STATUS_EXPIRED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=529738502  */
    VIDEO_STATUS_EXPIRED_ON = 'PLAY.VIDEO_STATUS_EXPIRED_ON',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530137061  */
    VIDEO_STATUS_UPDATED = 'PLAY.STATUS_UPDATED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530168663 */
    VIDEO_STATUS_UNSCHEDULE = 'PLAY.VIDEO_STATUS_UNSCHEDULE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109484676 */
    VIDEO_THUMBNAIL = 'PLAY.VIDEO_THUMBNAIL',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199791694 */
    VIDEO_UNAVAILABLE = 'PLAY.VIDEO_UNAVAILABLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199791992 */
    VIDEO_UNAVAILABLE_DESCRIPTION = 'PLAY.VIDEO_UNAVAILABLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=276382214
     */
    VIDEO_USAGE = 'PLAY.VIDEO_USAGE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109573055 */
    VIDEO_VIEWS = 'PLAY.VIDEO_LIST_ITEM_VIEWS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158141260 */
    VISIT_VIDEO_PAGE = 'PLAY.VISIT_VIDEO_PAGE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=389703248 */
    WHO_CAN_BE_VIDEO_AUTHOR = 'PLAY.WHO_CAN_BE_VIDEO_AUTHOR',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107592211 */
    WRONG_FORMAT_FILE = 'GLOBAL.WRONG_FORMAT_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=571005680
     */
    INTEGRATION_VIDEO_IN_SITE_DESCRIPTION = 'PLAY.INTEGRATION_VIDEO_IN_SITE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=571025616
     */
    INTEGRATION_PLAYLIST_IN_SITE_DESCRIPTION = 'PLAY.INTEGRATION_PLAYLIST_IN_SITE_DESCRIPTION',
}
