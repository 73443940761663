import React from 'react';

import { ImageGallery } from '@lumapps/wrex-image-gallery/components/blocks/ImageGallery';
import { ElementRender } from '@lumapps/wrex/types';

import { IMAGE_GROUP } from '../../../constants';
import { ImageGroupElement } from '../../../types';
import { EnhancedImage } from '../EnhancedImage';

export const ImageGroup: ElementRender<ImageGroupElement, HTMLDivElement> = (props) => {
    const { element } = props;

    return element?.viewMode ? <ImageGallery {...props} /> : <EnhancedImage {...props} />;
};
ImageGroup.displayName = IMAGE_GROUP;
