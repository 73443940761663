(function IIFE() {
    'use strict';

    /////////////////////////////

    function SharePostButtonController(Content, InitialSettings, Instance, Post) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        

        /////////////////////////////

        /**
        * Services and utilities.
        */
        
        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
           
        }

        init();
    }

    function SharePostButtonDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: SharePostButtonController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                content: '<',
                disabled: '<?',
                isExtended: '<?',
                link: '<?',
                target: '<?',
                theme: '@?',
            },
            templateUrl: '/client/front-office/modules/social/views/share-post-button.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('sharePostButton', SharePostButtonDirective);
})();
