import React from 'react';

import isArray from 'lodash/isArray';

import { useClassnames } from '@lumapps/classnames';
import { Size, TextProps, Text, Icon, ColorPalette, ColorVariant, Chip, FlexBox, IconProps } from '@lumapps/lumx/react';

import { ATTRIBUTE_TYPOGRAPHY, CLASSNAME } from '../../constants';

export interface AttributeProps extends Partial<TextProps> {
    /** icon to be displayed to the left hand side of the text */
    icon?: string;
    /** key or id associated to the attribute */
    key: string;
    /** value for the attribute */
    value?: string | string[];
    /** if multiple values, this will be used for separating them */
    separator?: string;
    /** whether to display the value or values as chips */
    asChip?: boolean;
    /** if we would like, pass icon props to a text attribute component */
    iconProps?: Partial<IconProps>;
}

const TextAttribute: React.FC<AttributeProps> = ({ icon, asChip, value, iconProps, ...props }) => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <Text
            as="span"
            typography={!asChip ? ATTRIBUTE_TYPOGRAPHY : 'caption'}
            color={!asChip ? ColorPalette.dark : undefined}
            colorVariant={!asChip ? ColorVariant.L2 : undefined}
            noWrap
            {...props}
            className={element('attribute', [props.className])}
        >
            {icon ? <Icon size={Size.xxs} icon={icon} {...iconProps} /> : null}
            {value}
        </Text>
    );
};

/**
 * Component to be used for displaying an entity with attributes associated to the entity.
 * This will display an image on the left hand side, the entity's title, as well as metadata
 * associated to it.
 *
 * @family Blocks
 * @param AttributeProps
 * @returns Attribute
 */
export const BaseAttribute: React.FC<AttributeProps> = ({
    icon,
    value,
    separator = ', ',
    asChip = false,
    ...props
}) => {
    let valueToDisplay = value;

    if (isArray(value)) {
        valueToDisplay = value.join(separator);
    }

    if (asChip) {
        if (isArray(value)) {
            return (
                <FlexBox orientation="horizontal" gap="tiny">
                    {value.map((val) => (
                        <Chip key={val} size="s">
                            <TextAttribute value={val} icon={icon} asChip {...props} />
                        </Chip>
                    ))}
                </FlexBox>
            );
        }

        return (
            <Chip size="s">
                <TextAttribute value={value} icon={icon} asChip {...props} />
            </Chip>
        );
    }

    return <TextAttribute value={valueToDisplay} icon={icon} asChip={asChip} {...props} />;
};
