import React, { useCallback, useState } from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { FormActionsProps } from '@lumapps/lumx-forms/components/FormActions';
import { FormDialog } from '@lumapps/lumx-forms/components/FormDialog';
import { FormURLTextField } from '@lumapps/lumx-forms/components/FormURLTextField';
import { mdiLinkBoxVariantOutline, mdiOpenInNew } from '@lumapps/lumx/icons';
import { FlexBox, Link, Text } from '@lumapps/lumx/react';
import { FilePreviewBlock } from '@lumapps/medias/components/FilePreviewBlock';
import { MediaSourceV2 } from '@lumapps/medias/types';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getConnectedMSUserSharepointUrl } from '@lumapps/user/ducks/selectors';
import { UserMicrosoftToken } from '@lumapps/user/interface';
import { isURLValid } from '@lumapps/utils/string/isUrl';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { ONEDRIVE_PICKER_KEYS } from '../../../keys';
import { OneDriveDocument, OneDrivePickerConfiguration } from '../../../types';
import { fetchDocument } from '../../../utils/fetchDocument';

import './index.scss';

const CLASSNAME = 'file-one-drive-picker';

interface OneDrivePickerProps {
    /** User MS token */
    token?: UserMicrosoftToken | Promise<UserMicrosoftToken | undefined>;
    /** OneDrivePicker configuration object */
    pickerConfig: OneDrivePickerConfiguration;
    /** whether the picker is open or not */
    isOpen: boolean;
    /** props to pass to the form dialog */
    formActionProps?: Partial<FormActionsProps>;
    /** callback on close */
    onClose(): void;
    /** callback on selection */
    onSelect(files: OneDriveDocument): void;
}

/**
 * OneDrivePicker display allowing only file url and displaying a file library on url insertion
 * */
export const OneDriveFilePicker: React.FC<OneDrivePickerProps> = ({
    isOpen,
    onClose,
    token,
    onSelect,
    pickerConfig,
    formActionProps,
}) => {
    const { translateKey } = useTranslate();
    const { error } = useNotification();

    const sharePointUrl = useSelector(getConnectedMSUserSharepointUrl);
    const [baseLoadingStatus, setBaseLoadingStatus] = useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const [msDocument, setMSDocument] = useState<OneDriveDocument | undefined>();
    const [urlErrorMessage, setUrlErrorMessage] = useState<string | null | undefined>(null);

    const submitForm = useCallback(() => {
        if (msDocument) {
            onSelect(msDocument);
        }
    }, [msDocument, onSelect]);

    const getMSDocument = React.useCallback(
        async (url: string) => {
            try {
                setBaseLoadingStatus(BaseLoadingStatus.loading);

                const document = await fetchDocument({ url, token, pickerConfig });

                setMSDocument(document);
                setUrlErrorMessage(undefined);
            } catch (e) {
                setMSDocument(undefined);

                // Display given custom error on specific cases like giving a folder link when we accept only files
                if (e instanceof Error) {
                    setUrlErrorMessage(translateKey(e.message));
                } else {
                    error({ translate: GLOBAL.GENERIC_ERROR });
                }
            } finally {
                setBaseLoadingStatus(BaseLoadingStatus.idle);
            }
        },
        [error, pickerConfig, token, translateKey],
    );

    const validateUrl = React.useCallback(
        async (url: string) => {
            if (msDocument) {
                setMSDocument(undefined);
            }

            // Clear error message when clearing the url input
            if (isEmpty(url)) {
                setUrlErrorMessage(null);
            }
            // Check if string is an url
            else if (!url.startsWith('https://') && !isURLValid(url)) {
                setUrlErrorMessage(translateKey(GLOBAL.URL_FIELD_ERROR_MESSAGE));
            }
            // Check if string is a sharepoint url
            else if (!url.includes('sharepoint.com/')) {
                setUrlErrorMessage(translateKey(ONEDRIVE_PICKER_KEYS.NOT_SHAREPOINT_URL_ERROR));
            } else {
                await getMSDocument(url);
            }
        },
        [getMSDocument, msDocument, translateKey],
    );

    const debouncedValidateUrl = debounce(validateUrl, DEBOUNCE_DELAY);

    const controllerProps = {
        name: 'url',
        onValueChanged: (url: string) => debouncedValidateUrl(url),
    };

    React.useEffect(() => {
        if (isOpen) {
            // Reset state values on open
            setUrlErrorMessage(null);
            setMSDocument(undefined);
            setBaseLoadingStatus(BaseLoadingStatus.initial);
        }
    }, [isOpen]);

    return (
        <FormDialog
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={submitForm}
            formActionProps={
                formActionProps
                    ? { ...formActionProps, isDisabled: !msDocument }
                    : {
                          isDisabled: !msDocument,
                          saveProps: {
                              children: translateKey(GLOBAL.ADD),
                          },
                      }
            }
            heading={
                <Text as="h3" typography="title">
                    {translateKey(ONEDRIVE_PICKER_KEYS.INSERT_FILE)}
                </Text>
            }
            scope={CLASSNAME}
            className={CLASSNAME}
        >
            <FormURLTextField
                name="url"
                label={translateKey(GLOBAL.URL)}
                placeholder={translateKey(ONEDRIVE_PICKER_KEYS.FILE_PLACEHOLDER)}
                isRequired
                icon={mdiLinkBoxVariantOutline}
                helper={
                    <FlexBox orientation="horizontal" gap="tiny" hAlign="center" as="span">
                        <Text as="span" typography="caption">
                            {translateKey(ONEDRIVE_PICKER_KEYS.FILE_HELPER)}
                        </Text>
                        <Link
                            leftIcon={mdiOpenInNew}
                            href={sharePointUrl || 'https://www.office.com/search/sites?auth=2&q=sharepoint'}
                            target="_blank"
                            typography="caption"
                        >
                            {translateKey(ONEDRIVE_PICKER_KEYS.SEARCH_IN_SHAREPOINT)}
                        </Link>
                    </FlexBox>
                }
                isValid={urlErrorMessage === null ? undefined : !urlErrorMessage}
                hasError={urlErrorMessage === null ? undefined : !!urlErrorMessage}
                error={urlErrorMessage}
                clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                controllerProps={controllerProps}
            />
            {baseLoadingStatus === BaseLoadingStatus.loading && !msDocument && (
                <FilePreviewBlock isLoading provider={MediaSourceV2.MICROSOFT} />
            )}
            {msDocument && (
                <FilePreviewBlock
                    id={msDocument.id}
                    name={msDocument.name}
                    url={msDocument.webUrl}
                    mimeType={msDocument.file?.mimeType}
                    createdAt={msDocument.createdDateTime}
                    editedAt={msDocument.lastModifiedDateTime}
                    isLoading={false}
                    provider={MediaSourceV2.MICROSOFT}
                />
            )}
        </FormDialog>
    );
};
