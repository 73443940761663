function WidgetRemoteController($scope, ExtensionWidget, Content, Features) {
    'ngInject';

    const vm = this;

    vm.properties = {};

    vm.Content = Content;
    vm.Features = Features;

    function onChange(field, value) {
        _.set(vm.widget, field, value);
    }

    vm.onChange = onChange;
    /**
     * Initialize controller.
     */
    vm.init = async () => {
        // Some old widgets are missing this property
        vm.widget.widgetType = 'remote';

        const { id } = vm.widget.properties.remoteExtension;
        const extension = await ExtensionWidget.getEnabledExtension(id);

        if (!extension) {
            vm.properties.extensionEnabled = false;

            return;
        }

        vm.properties.extensionEnabled = extension.enabled;
        vm.properties.globalProperties = extension.globalSettings;
    };

    /**
     * Set the parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    vm.setParentController = (parentCtrl) => {
        vm.parentCtrl = parentCtrl;

        vm.init();
    };

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the settings are update, empty all events, rebuild the remote widget.
     *
     * @param {Event}  evt        The event.
     * @param {string} [idWidget] The id of the widget whose settings are updated.
     */
    $scope.$on('widget-remote-settings', function onWidgetRemoteSettings(evt, idWidget) {
        if (vm.widget.uuid === idWidget || angular.isUndefinedOrEmpty(idWidget)) {
            vm.init();
        }
    });
}

/////////////////////////////

// eslint-disable-next-line require-jsdoc
function WidgetRemoteDirective() {
    'ngInject';

    // eslint-disable-next-line require-jsdoc
    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetRemoteController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetRemote', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-remote/views/widget-remote.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetRemote', WidgetRemoteDirective);

/////////////////////////////

export { WidgetRemoteController, WidgetRemoteDirective };
