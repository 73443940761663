import type { DataAttributesOptions } from '@lumapps/data-attributes';
import { Kind, NotificationProps } from '@lumapps/lumx/react';
import type { TranslateTypes } from '@lumapps/translations';

import type { ExtendedNotificationProps } from '../components/ExtendedNotification';

/** Attributes compatible with any kind of notification */
interface GeneralNotificationProps {
    /** Data attributes used for tracking purposes */
    dataAttributes?: DataAttributesOptions;
    /** Callback to be executed once the notification is hidden */
    onNotificationHidden?: () => void;
    /** Translation options for the notification */
    translate?: TranslateTypes;
    /** Time (ms) that it will take to hide the notification */
    timeout?: number;
}

export type NotificationsAPI = NotificationProps & GeneralNotificationProps;

export type ExtendedNotificationAPI = ExtendedNotificationProps & Omit<GeneralNotificationProps, 'timeout'>;

/** Notification API (differentiated based on the notification type) */
export type NotificationAPIv2 = (NotificationsAPI & { type: Kind }) | (ExtendedNotificationAPI & { type: 'extended' });

/** Notification with additional properties required in the state */
export type NotificationInStore = NotificationAPIv2 & {
    /** Notification id */
    id: string;
    /** Notification visibility state */
    visible?: boolean;
    /** Notification rendered height */
    height?: number;
};

export type State = {
    notifications: Array<NotificationInStore>;
};

export const isSimpleNotification = (notif: NotificationAPIv2) => notif && notif.type !== 'extended';
