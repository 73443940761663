import { useCallback, useRef } from 'react';

import { createNewPost } from '@lumapps/communities/ducks/thunks/createNewPost';
import { RenderingType } from '@lumapps/communities/types';
import { useDispatch } from '@lumapps/redux/react';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { PostType } from '../types';

/** Hook to get the event dialog open state and toggle it. */
export const usePostDialog = ({ openEventDialog }: { openEventDialog?: () => void }) => {
    const [isOpen, , closePostDialog, openDialog] = useBooleanState(false);
    const dialogTriggerRef = useRef<HTMLButtonElement>(null);

    const dispatch = useDispatch();

    const createPost = useCallback(
        async (options: {
            postType?: PostType;
            // The community or space id to create a post in
            containerId: string;
            // The rendering type (whether it's a community or space)
            renderingType?: RenderingType;
        }) => {
            const { postType, containerId, renderingType } = options;
            await dispatch(
                createNewPost({
                    params: { uid: containerId },
                    postType,
                    renderingType,
                    openPostDialog: openDialog,
                    openEventDialog,
                }),
            );
        },
        [dispatch, openDialog, openEventDialog],
    );

    return {
        isOpen,
        createPost,
        openPostDialog: openDialog,
        closePostDialog,
        dialogTriggerRef,
    };
};
