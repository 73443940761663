/* eslint-disable no-param-reassign */
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { EventsState, EditedEvent, EventPage } from '../types';

export const initialState: EventsState = {
    notificationDialog: {
        isOpen: false,
        isLoading: false,
    },
};

export const { actions, reducer } = createSlice({
    domain: 'event',
    initialState,
    reducers: {
        openDialog: (state: EventsState) => {
            state.isEventDialogOpen = true;
        },
        closeDialog: (state: EventsState) => {
            delete state.isEventDialogOpen;
            delete state.editedEvent;
        },
        setCurrentEvent: (state: EventsState, action: PayloadAction<EventPage>) => {
            state.current = action.payload;
        },
        updateCurrentEventCommentCount: (state: EventsState, action: PayloadAction) => {
            if (state.current) {
                state.current.commentCount += action.payload;
            }
        },
        setEditedEvent: (state: EventsState, action: PayloadAction<Partial<EditedEvent>>) => {
            state.editedEvent = { ...state.editedEvent, ...action.payload } as EditedEvent;
        },
        clearCurrentEvent: (state: EventsState) => {
            delete state.current;
        },
        setIsPublishingEvent: (state: EventsState, action: PayloadAction<boolean>) => {
            if (!action.payload) {
                delete state.isEventDialogPublishing;
            } else {
                state.isEventDialogPublishing = action.payload;
            }
        },
        openNotificationDialog: (state: EventsState, action: PayloadAction<string>) => {
            state.notificationDialog.isOpen = true;
            state.notificationDialog.eventId = action.payload;
        },
        closeNotificationDialog: (state: EventsState) => {
            state.notificationDialog.isOpen = false;
            delete state.notificationDialog.eventId;
        },
        setNotificationDialogStatus: (state: EventsState, action: PayloadAction<boolean>) => {
            state.notificationDialog.isLoading = action.payload;
        },
    },
});
