import { isContentWithContextualAction, isUserDirectory } from '@lumapps/content-types/utils';
import { isFeatureEnabled } from '@lumapps/features';
import { authorizations, canEditOnlyOwnContentAuthorizations, isInstanceSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import { getSubscriptions, isConnected, userIdSelector } from '@lumapps/user/ducks/selectors';

import { Content } from '../types';
import { isUserEditorOfContent } from '../utils/isUserEditorOfContent';

export const contentSelector = (state: BaseStore) => state.content || {};

export const getContentEntities = createSelector(contentSelector, (content) => content.entities);

export const getCurrentContentId = createSelector(contentSelector, (content) => content.id);

export const getCurrentContentType = createSelector(contentSelector, (content) => content.type);

export const getCurrentCustomContentTypeId = createSelector(contentSelector, (content) => content.customContentType);

export const getCurrentContentExcerpt = createSelector(contentSelector, (content) => content.excerpt);

export const getCurrentContentMediaThumbnail = createSelector(contentSelector, (content) => content.mediaThumbnail);

export const getCurrentContentSlug = createSelector(contentSelector, (content) => content.slug);

export const getCurrentContentStatus = createSelector(contentSelector, (content) => content.status);

export const getCurrentContentTitle = createSelector(contentSelector, (content) => content.title);

export const getCurrentContentProperties = createSelector(contentSelector, (content) => content.properties || {});

export const getCurrentContentHeader = createSelector(contentSelector, (content) => content.headerDetails);

export const getCurrentContentHasCommentWidget = createSelector(contentSelector, (content) => content.hasCommentWidget);

export const isContentCurrentlyDisplayed = createSelector(contentSelector, (content) => {
    return Boolean(content) && Boolean(content.id);
});

/**
 * Determines whether the current user has access to the given content.
 * It takes into account the permissions that the user has, evaluating if the user has access
 * to the edition of the custom content type of the content, or if the user has access
 * as an editor to the content.
 */
export const isEditorOfContent = createSelector(
    getSubscriptions,
    authorizations,
    canEditOnlyOwnContentAuthorizations,
    userIdSelector,
    isInstanceSuperAdmin,
    (subscriptions, auths, canEditOnlyOwnContentAuths, currentUserId, isAdmin) => {
        return (content: Content) => {
            return isUserEditorOfContent({
                content,
                subscriptions,
                auths,
                canEditOnlyOwnContentAuths,
                userId: currentUserId,
                isInstanceSuperAdmin: isAdmin,
            });
        };
    },
);

/**
 * Determines whether the current user has access to the current content displayed.
 * It takes into account the permissions that the user has, evaluating if the user has access
 * to the edition of the custom content type of the current content, or if the user has access
 * as an editor to the current content.
 */
export const isEditorOfCurrentContent = createSelector(
    contentSelector,
    getSubscriptions,
    authorizations,
    canEditOnlyOwnContentAuthorizations,
    userIdSelector,
    isInstanceSuperAdmin,
    (content, subscriptions, auths, canEditOnlyOwnContentAuths, currentUserId, isAdmin) => {
        return isUserEditorOfContent({
            content,
            subscriptions,
            auths,
            canEditOnlyOwnContentAuths,
            userId: currentUserId,
            isInstanceSuperAdmin: isAdmin,
        });
    },
);

export const canUserEditCurrentContent = createSelector(
    contentSelector,
    isContentCurrentlyDisplayed,
    isInstanceSuperAdmin,
    isConnected,
    isEditorOfCurrentContent,
    (content, isContentDisplayed, isAdmin, connected, isEditor) => {
        /**
         * If there is no user connected or we do not have any content in our store, the user
         * is not allowed to edit the content since there isn't one.
         */
        if (!connected || !isContentDisplayed) {
            return false;
        }

        const { type } = content;

        /**
         * User Directories are not editable
         */
        if (isUserDirectory(type)) {
            return false;
        }

        /**
         * if the user is super admin (of this instance or in general), they are always able to edit
         * any content.
         */
        if (isAdmin) {
            return true;
        }

        return content.canEdit || isEditor;
    },
);

export const isInDesignerMode = createSelector(contentSelector, (content) => content.isDesignerMode);

export const isContextualActionsEnabled = isFeatureEnabled('contextual-actions');

export const contentHasContextualActions = createSelector(contentSelector, (content) =>
    isContentWithContextualAction(content.type),
);

/** Contribution Alpha FF */
export const isContributionAlphaFeatureEnabled = isFeatureEnabled('contribution-alpha');

/** New simple template mode enabled */
export const isNewSimpleTemplateFeatureEnabled = isFeatureEnabled('new-simple-template-experience');

/** New contribution experience enabled */
export const isNewContributionExperienceEnabled = isFeatureEnabled('new-contribution-experience');
