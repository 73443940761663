import { makeSecuredMediaURLRelative, retrieveKeyFromServeImageUrl } from '@lumapps/medias/utils';

import { Image } from './types';

export const getImageForBlobKey = (key?: string) => {
    return {
        url: makeSecuredMediaURLRelative(`/serve/${key}`),
        id: key,
    };
};

export const getImageForThumbnail = (thumbnail?: string): Image | undefined => {
    if (thumbnail) {
        const key = retrieveKeyFromServeImageUrl(thumbnail);

        return getImageForBlobKey(key);
    }

    return undefined;
};
