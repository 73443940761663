import React from 'react';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import {
    FormUploadDocumentField,
    FormUploadDocumentFieldProps,
} from '@lumapps/lumx-forms/components/FormUploadDocumentField';
import { mdiImage } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_LINKS } from '../../keys';

interface LinkUploadImageProps {
    onImageSelected?: FormUploadDocumentFieldProps['onDocumentSelected'];
    onImageUploaded?: FormUploadDocumentFieldProps['onDocumentUploaded'];
}
/**
 * Component that manages the image of a widget link entry.
 * @param LinkUploadImageProps
 * @returns LinkUploadImage
 */
export const LinkUploadImage: React.FC<LinkUploadImageProps> = ({ onImageSelected, onImageUploaded }) => {
    const currentLanguage = useSelector(currentLanguageSelector);
    const instanceId = useSelector(instanceIdSelector);
    const { translateKey } = useTranslate();

    return (
        <FormUploadDocumentField
            name="image"
            onDocumentSelected={onImageSelected}
            onDocumentUploaded={onImageUploaded}
            uploadDocumentOptions={{
                lang: currentLanguage,
                shared: false,
                parentPath: `provider=local/site=${instanceId}`,
            }}
            thumbnailProps={{
                size: 'xxl',
            }}
            icon={mdiImage}
            size="xxl"
            useBlobLocalStorage
            uploaderLabel={translateKey(WIDGET_LINKS.LINKS_THUMBNAIL_UPLOAD)}
            label={translateKey(GLOBAL.THUMBNAIL)}
        />
    );
};
