import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { VideoPickerDialogProps } from './VideoPickerDialog';

const LazyVideoPickerDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: "video-picker" */
            './VideoPickerDialog'
        ),
);

const VideoPickerDialog = (props: VideoPickerDialogProps) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            {(setShouldMount) => <LazyVideoPickerDialog onVisibilityChange={setShouldMount} {...props} />}
        </DelayedSuspense>
    );
};

export { VideoPickerDialog };
export type { VideoPickerDialogProps };
