import * as React from 'react';

import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { Style as StyleService } from '../../../services/styles';
import { ColorSelect, Input, TextField } from '../../ui';
import { withOnChangeProperty } from '../../../utils';
import { stringsToChoices } from '../../ui/inputs/utils';

/**
 * Renders a form that enables editing icon settings.
 */
export class IconSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.fontStyle.isRequired,
        /** Current font style. */
        value: styleTypes.fontStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { value = {}, defaults } = this.props;

        return (
            <div className="p+">
                <div flex-container="row" flex-gutter="16">
                    <div flex-item="flex-item">
                        <TextField label={t('STYLE.SIZE')} suffix="px">
                            <Input
                                value={value.fontSize}
                                name="fontSize"
                                onChange={this.onChangeProperty}
                                type="number"
                                placeholder={defaults.fontSize}
                            />
                        </TextField>
                    </div>
                    <div flex-item="flex-item">
                        <ColorSelect
                            label={t('STYLE.COLOR')}
                            value={value.color}
                            name="color"
                            onChange={this.onChangeProperty}
                            choices={stringsToChoices(StyleService.getCurrent('global').properties.colors)}
                            align="right"
                            placeholder={defaults.color}
                            hasClearChoice
                        />
                    </div>
                </div>
            </div>
        );
    }
}
