import { createUrl, getRelativeRouteFromUrl } from '@lumapps/router/utils';
import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { useTranslate } from '@lumapps/translations';
import { isTranslateObject } from '@lumapps/translations/utils';
import { isUrl } from '@lumapps/utils/string/isUrl';

export const useEntryLink = (entryLink?: Record<string, string> | string, instanceSlug?: string) => {
    const { translate } = useTranslate();
    const link = translate(entryLink) || '';
    const relativeRoute = getRelativeRouteFromUrl(link);

    if (!entryLink || !isTranslateObject(entryLink) || !relativeRoute) {
        return null;
    }

    if (isUrl(link) || link.startsWith('/')) {
        return sanitizeUrl(link);
    }

    // Looks like a slug
    return createUrl(relativeRoute, null, instanceSlug);
};
