import React, { lazy } from 'react';

import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { LoadingState } from '@lumapps/micro-apps/components/AdaptiveCard/LoadingState';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { UploadMediaIntegratedProps } from './UploadMediaIntegrated';

const LazyUploadMediaIntegrated = lazy(
    () =>
        import(
            /* webpackChunkName: 'upload-media-lumapps-component' */
            './UploadMediaIntegrated'
        ),
);

export const UploadMediaIntegrated: React.FC<UploadMediaIntegratedProps> = (props) => {
    return (
        <Suspense loadingFallback={<LoadingState />} errorFallback={<ServiceNotAvailableState />}>
            <LazyUploadMediaIntegrated {...props} />
        </Suspense>
    );
};
