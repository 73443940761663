import BaseApi from '@lumapps/base-api';

export const emailApi = new BaseApi({ path: 'email' });

interface SendEmailParams {
    body: string;
    captcha: string;
    customerId: string;
    replyTo: string;
    subject: string;
    to: string;
    contentId: string;
    widgetId: string;
}

export const sendEmail = (params: SendEmailParams) => emailApi.post('send', params);
