import React from 'react';

import { FromLegacyBlockPost } from '@lumapps/posts/components/PostBlock/BlockPost/FromLegacyBlockPost';
import { WidgetSkeleton } from '@lumapps/widget-base/components/WidgetSkeleton';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { usePostDetails } from '../hooks/usePostDetails';

export const ClientComputedPostDetails: React.FC<ClientComputedProps> = ({ uuid: widgetId, widgetType, theme }) => {
    const { hasInit, isLoading, post, refreshPost } = usePostDetails(widgetId);

    if (!hasInit || isLoading) {
        return <WidgetSkeleton widgetId={widgetId} widgetType={widgetType} />;
    }

    if (!post) {
        return null;
    }

    return (
        <FromLegacyBlockPost
            post={post}
            refreshPosts={refreshPost}
            isDetailed
            isPinStatusDisplayed
            isAlreadySemantic
            theme={theme}
        />
    );
};
