import { ORDERED_LIST, UNORDERED_LIST } from '@lumapps/wrex-list/constants';
import { ListEditor } from '@lumapps/wrex-list/types';
import { Editor, ReactEditor } from '@lumapps/wrex/slate';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';

import { isCodeBlock } from './isCodeBlock';
import { isParagraph } from './isParagraph';

function haveListEnabled(editor: (Editor & ListEditor) | ReactEditor): editor is Editor & ListEditor {
    return 'isListActive' in editor;
}

export const isCodeBlockAllowed = (editor: (Editor & ListEditor) | ReactEditor): boolean => {
    // Editor cursor should be available.
    if (!editor.selection) {
        return true;
    }

    // If the editor also have list support
    const isInList = haveListEnabled(editor)
        ? editor.isListActive(UNORDERED_LIST) || editor.isListActive(ORDERED_LIST)
        : false;

    const elements = Array.from(Editor.nodes(editor, { at: editor.selection, match: isElement, mode: 'lowest' }));

    return elements.every(([node]) => isParagraph(node) || isCodeBlock(node)) && !isInList;
};
