import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import last from 'lodash/fp/last';
import over from 'lodash/fp/over';

import { CalendarConfig, CalendarViews } from '@lumapps/lumx-calendar/types';
import { getVisibleDays } from '@lumapps/lumx-calendar/utils/days/getVisibleDays';
import { nextDay } from '@lumapps/utils/date/getDayRelativeToDate';

/**
 * Extract the range of date from which we want to get the events.
 * Based on the current selected date and the view.
 * - In Month and Week view, we take the first date, and last date next day (needed for the API to return the whole list of events)
 * - In scheduled view, we only return the current date, since there is no range to apply.
 * */
export const getFetchingDates = (date: Date, view: CalendarViews, calendarConfig: CalendarConfig) => {
    const days = getVisibleDays(calendarConfig, date, view);

    const dates: [Date, Date?] =
        CalendarViews.schedule === view
            ? [date]
            : (flow(over([flow(first, get('date')), flow(last, get('date'), nextDay)]))(days) as [Date, Date]);

    return dates;
};
