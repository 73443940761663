import React, { useEffect } from 'react';

import { LearnerStatsList } from '@lumapps/learning-learner-kpi/components/LearnerStatsList';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { UserKpiProps } from '../../types';

export const ClientComputedUserKpi: React.FC<UserKpiProps> = ({ uuid }) => {
    const dispatch = useDispatch();
    const learningEnabled = useSelector(isLearningEnabled);

    useEffect(() => {
        if (!learningEnabled) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [learningEnabled, dispatch, uuid]);

    return <LearnerStatsList />;
};
