import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { Element } from '@lumapps/wrex/serialization/dita/types';

/** Convert slate table element to DITA simpletable. */
export const TABLE_CONVERT: Converter = (node, children) => ({
    type: 'element',
    name: 'simpletable',
    children: children as Element[],
});

/** Convert slate table row element to DITA strow */
export const TR_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'strow',
    children,
});

/** Convert slate table cell element to DITA stentry */
export const TD_CONVERT: Converter = (node, children = []) => ({
    type: 'element',
    name: 'stentry',
    children,
});
