import React from 'react';

import { SkipLinksNav } from '@lumapps/a11y/components/SkipLinksNav';
import { MAIN_CONTENT_ID } from '@lumapps/lumx-front-office/constants';
import { MAIN_NAV_ID } from '@lumapps/navigation/constants';
import { useIsNavigationDisabled } from '@lumapps/navigation/hooks/useIsNavigationDisabled';
import { useResponsive } from '@lumapps/responsive';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface HeaderSkipLinksProps {}

/**
 *  Skip links to set on the front office header component.
 */
export const HeaderSkipLinks: React.FC<HeaderSkipLinksProps> = () => {
    const { translateKey } = useTranslate();
    const { isMobile } = useResponsive();
    const { isNavigationUiDisabled } = useIsNavigationDisabled();

    const showSkipToNavigation = !isMobile && !isNavigationUiDisabled;

    return (
        <SkipLinksNav>
            {showSkipToNavigation && (
                <SkipLinksNav.Link anchorId={MAIN_NAV_ID}>{translateKey(GLOBAL.SKIP_TO_NAVIGATION)}</SkipLinksNav.Link>
            )}

            <SkipLinksNav.Link anchorId={MAIN_CONTENT_ID}>{translateKey(GLOBAL.SKIP_TO_CONTENT)}</SkipLinksNav.Link>
        </SkipLinksNav>
    );
};
