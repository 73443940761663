import React from 'react';

import { Text } from '@lumapps/lumx/react';

import './index.scss';

export interface SideNavigationHeaderProps {
    /** Content to be displayed for the Navigation Header */
    children?: React.ReactNode;
    className?: string;
}

/**
 * Component that will display the Header for each side navigation section
 */
const SideNavigationHeader: React.FC<SideNavigationHeaderProps> = ({ children, className }) => {
    return (
        <Text as="span" className={className}>
            {children}
        </Text>
    );
};

/**
 * We change the displayName to SideNavigationItem in order to allow DS to render this component
 * inside a @lumapps/lumx/react/SideNavigation component. If the component does not have a displayName
 * of `SideNavigationItem`, DS won't render it.
 */
SideNavigationHeader.displayName = 'SideNavigationItem';

export { SideNavigationHeader };
