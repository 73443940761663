export enum SETTINGS_MENU {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500022
     */
    NAVIGATION = 'ADMIN.MOBILE.NAVIGATION.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244383
     */
    SUB_MODULES = 'ADMIN.NAV.SUB_MODULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244399
     */
    SUB_INSTANCE = 'ADMIN.NAV.SUB_INSTANCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165869871
     */
    CONTENT_STRATEGY = 'ADMIN.NAV.SUB_CONTENT_STRATEGY',
}
