import React, { forwardRef } from 'react';

import { classnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { useCommunityLink } from '../../hooks/useCommunityLink';
import { CommunityLinkRef } from '../../types';

export interface CommunityLinkProps {
    to: CommunityLinkRef;
    /** Set this flag to open cross instance content in a new tab. */
    crossInstanceInNewTab?: boolean;
    theme?: Theme;
    // Forwarded props.
    [key: string]: any;
}

const CLASSNAME = 'community-link';

export const CommunityLink = forwardRef<HTMLAnchorElement, CommunityLinkProps>(
    ({ to, theme, children, className, crossInstanceInNewTab, ...forwardedProps }, ref) => {
        const communityLink = useCommunityLink(to);

        // The prop target takes priority over the default content link target.
        let target = forwardedProps.target || communityLink.props.target;

        // Force open in new tab if link is from another instance.
        if (crossInstanceInNewTab && communityLink.contentLinkRef.instance) {
            target = '_blank';
        }

        return (
            <a
                {...forwardedProps}
                href={communityLink.props.href}
                target={target}
                rel={target === '_blank' ? 'noreferrer noopener' : undefined}
                className={classnames(
                    className,
                    CLASSNAME,
                    theme === Theme.dark ? 'lumx-link--color-light' : undefined,
                )}
                ref={mergeRefs([ref, communityLink.ref])}
            >
                {children}
            </a>
        );
    },
);
