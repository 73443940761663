import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function UserBlockWrapperController($scope, $timeout, Instance, LxDropdownService, User, UserDirectory) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The dropdown toggle timeout in ms.
     *
     * @type {number}
     */
    const _DROPDOWN_TOGGLE_TIMEOUT = 500;

    /**
     * Contains the promise of the timeout when we enter the dropdown.
     *
     * @type {$timeout}
     */
    let _enterTimeout;

    /**
     * Whether the mouse is on dropdown or not.
     *
     * @type {boolean}
     */
    let _isMouseOnDropdown = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The dropdown id displaying the extended user block.
     *
     * @type {string}
     */
    vm.DROPDOWN_ID = `extended-user-block-${generateUUID()}`;

    /**
     * The user block id.
     *
     * @type {string}
     */
    vm.USER_BLOCK_ID = `user-block-${generateUUID()}`;

    /**
     * A list of extra fields to display in the user popover.
     *
     * @type {Array}
     */
    vm.popoverFields = undefined;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Update the fields of the popover when the instances siblings are loaded.
     */
    function _updateFields() {
        Instance.getSiblings(false).then(function onSiblingsListSuccess() {
            vm.popoverFields = UserDirectory.getPopoverFields(
                UserDirectory.getMainUserDirectory(),
                User.getConnected(),
            );
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close dropdown on mouse leave.
     *
     * @param {string} target From which target the function has been called.
     */
    function closeDropdown(target) {
        if (target === 'dropdown') {
            _isMouseOnDropdown = false;
        }

        if (target === 'source' && !_isMouseOnDropdown) {
            $timeout.cancel(_enterTimeout);
        }

        $timeout(() => {
            if (target === 'source' && _isMouseOnDropdown) {
                return;
            }

            LxDropdownService.close(vm.DROPDOWN_ID);
        }, _DROPDOWN_TOGGLE_TIMEOUT);
    }

    /**
     * Set mouse on dropdown to true.
     */
    function isOnDropdown() {
        _isMouseOnDropdown = true;
    }

    /**
     * Open dropdown on mouse enter.
     *
     * @param {string} target From which target the function has been called.
     */
    function openDropdown() {
        _enterTimeout = $timeout(() => {
            LxDropdownService.open(vm.DROPDOWN_ID, {
                target: `#${vm.USER_BLOCK_ID}`,
            });
        }, _DROPDOWN_TOGGLE_TIMEOUT);
    }

    /**
     * Determines whether user personal information should be displayed to the end user or not.
     *
     * @return {boolean} whether user personal info should be displayed or not.
     */
    function displayUserPersonalInfo() {
        return User.isConnected();
    }

    /////////////////////////////

    vm.closeDropdown = closeDropdown;
    vm.isOnDropdown = isOnDropdown;
    vm.openDropdown = openDropdown;
    vm.displayUserPersonalInfo = displayUserPersonalInfo;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the list of instances siblings is updated, recompute the popover fields for the user.
     */
    $scope.$on('instances-siblings-list-updated', function onInstancesSiblingsUpdated() {
        _updateFields();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _updateFields();

        vm.theme = vm.theme || 'light';
        vm.viewMode = vm.viewMode || 'basic';
        vm.displaySecondaryField = angular.isUndefined(vm.displaySecondaryField) ? true : vm.displaySecondaryField;
    }

    init();
}

/**
 * User block wrapper directive.
 *
 * @param {string}  [bullet]                     The text you want to display as a small bullet at the bottom right
 *                                               of the user block.
 *                                               Keep in mind that this should be a very short text (1 or 2 chars).
 * @param {boolean} [displaySecondaryField=true] Indicates if you want to display the secondary field (the field
 *                                               below the user name).
 * @param {Array}   [fields]                     An array of objects to display in the user-block.
 * @param {boolean} [isAdmin=false]              If the user is a community admin or not.
 * @param {string}  [size]                       The size to apply to the user-block directive.
 * @param {string}  [theme='light']              The theme to apply to the directive.
 *                                               Can be either 'light' or 'dark'.
 * @param {Object}  user                         The user object to get the details of to display.
 * @param {string}  [viewMode='basic']           The name of the view mode to use.
 *                                               Can be 'basic', 'extended', 'name' or 'thumbnail'.
 */

function UserBlockWrapperDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: UserBlockWrapperController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            bullet: '@?lsBullet',
            displaySecondaryField: '<?lsDisplaySecondaryField',
            fields: '=?lsFields',
            isAdmin: '<?lsIsAdmin',
            size: '@?lsSize',
            theme: '@?lsTheme',
            user: '=lsUser',
            viewMode: '@?lsViewMode',
        },
        templateUrl: '/client/front-office/modules/user/views/user-block-wrapper.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsUserBlockWrapper', UserBlockWrapperDirective);

/////////////////////////////

export { UserBlockWrapperDirective };
