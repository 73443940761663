import noop from 'lodash/noop';

/* eslint-disable import/no-mutable-exports */
let updateList = noop;

/**
 * Expose the methods from the AngularJS service to the React world.
 *
 * @param {Service} ModuleAdmin The angular's `ModuleAdminService`.
 */
function setModuleAdmin(ModuleAdmin) {
    ({ updateList } = ModuleAdmin);
}

/////////////////////////////

export { setModuleAdmin, updateList };
