(function IIFE() {
    'use strict';

    /////////////////////////////

    function CrawlerFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/misc/:methodKey', {}, {
            urlinfo: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'urlinfo',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('CrawlerFactory', CrawlerFactory);
})();
