import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Alignment, FlexBox, ProgressCircular, Switch } from '@lumapps/lumx/react';

import { Setting } from '../../../types/setting';

import './index.scss';

export interface GenericSwitchListProps<T> {
    // Override className
    className?: string;
    // List of all displayable settings
    items: Array<Setting<T>>;
    // List of displayed settings
    order?: Array<string>;
    // Update the widget properties with the selected metadata
    onChange: (name: T) => void;
    // isLoading status
    isLoading?: boolean;
}

const CLASSNAME = 'generic-switch-list';

/**
 *
 * @param GenericSwitchListProps
 * @returns GenericSwitchList
 */
export const GenericSwitchList = <T,>({
    className,
    items,
    order = [],
    onChange,
    isLoading = false,
}: GenericSwitchListProps<T>) => {
    const { element, block } = useClassnames(CLASSNAME);

    return isLoading ? (
        <FlexBox vAlign={Alignment.center} className={element('loader')}>
            <ProgressCircular data-testid={element('progress')} />
        </FlexBox>
    ) : (
        <ol className={block([className])}>
            {items.map((setting: Setting<T>) => {
                return (
                    <li key={setting.label} className={element('list-item')}>
                        <Switch
                            isChecked={typeof setting.name === 'string' && order?.includes(setting.name)}
                            onChange={() => onChange(setting.name)}
                            helper={setting.helper}
                        >
                            {setting.label}
                        </Switch>
                    </li>
                );
            })}
        </ol>
    );
};
