import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiChevronLeft, mdiChevronRight } from '@lumapps/lumx/icons';
import { Button, ButtonProps, Emphasis, FlexBox, FlexBoxProps, IconButton, IconButtonProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { generateRandomWithSeed } from '@lumapps/utils/string/generateRandomWithSeed';

import { usePagination } from '../../hooks/usePagination';
import { PaginationMode } from '../../types';

import './index.scss';

export const FIRST_PAGE = 0;

export type PaginationProps = FlexBoxProps & {
    /** total number of pages */
    nbOfPages: number;
    /** the current selected page. Starts at 1 */
    currentPage: number;
    /** button size */
    size?: ButtonProps['size'];
    /** number of pages button to display */
    maxNbOfPagesDisplayed?: number;
    /** the maximum page number to display */
    maxPage?: number;
    /** either we should display last page or not default false */
    shoudlDisplayLastPage?: boolean;
    /** emphasis of buttons */
    emphasis?: IconButtonProps['emphasis'];
    /** custom className */
    className?: string;
    /** if the component is displayed on full page width */
    isFullWidth?: boolean;
    /** number mode: < 1 2 3 > or on mode: < 1 on 3 > */
    mode?: PaginationMode;
    /** callback when the user click on a page */
    onPageClick?: (page: number) => void;
    /** callback when the user click on next button */
    onNextClick: () => void;
    /** callback when the user click on previous button */
    onPreviousClick: () => void;
};

export const CLASSNAME = 'pagination';
/**
 * Display many buttons use to navigation from page to another
 * @family Layouts
 * @param param0 PaginationProps
 * @returns Pagination component or null if no pages
 */
export const Pagination: React.FC<PaginationProps> = ({
    nbOfPages,
    currentPage = 0,
    size = 's',
    maxNbOfPagesDisplayed = 6,
    maxPage,
    shoudlDisplayLastPage = false,
    emphasis = Emphasis.low,
    className,
    isFullWidth = true,
    mode = PaginationMode.full,
    onPageClick,
    onNextClick,
    onPreviousClick,
    ...rest
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { get } = useDataAttributes('pagination');

    const { block } = useClassnames(CLASSNAME);

    const onClick = (page: number) => () => {
        if (page !== currentPage && onPageClick) {
            onPageClick(page);
        }
    };

    const lastIndex = maxPage && maxPage < nbOfPages ? maxPage - 1 : nbOfPages - 1;

    const pages = usePagination({
        maxNbOfPagesDisplayed,
        currentPage,
        nbOfPages,
        maxPage,
        shoudlDisplayLastPage,
        mode,
    });

    if (nbOfPages <= 1) {
        return null;
    }

    return (
        <FlexBox
            gap={mode === PaginationMode.full ? 'regular' : 'tiny'}
            className={block({ 'is-full-width': isFullWidth }, [className])}
            {...rest}
        >
            <IconButton
                icon={mdiChevronLeft}
                isDisabled={currentPage === 0}
                onClick={onPreviousClick}
                label={translateKey(GLOBAL.PREVIOUS)}
                size={size}
                emphasis={emphasis}
                {...get({ element: 'previous' })}
            />

            {mode === PaginationMode.compact
                ? translateAndReplace(GLOBAL.PAGINATION_ON, {
                      CURRENT_PAGE: pages[currentPage]?.label,
                      LAST_PAGE: pages[pages.length - 1]?.label,
                  })
                : pages.map(({ value, label }) => {
                      const isCurrentPage = value === currentPage;
                      const a11yProps = isCurrentPage ? { 'aria-current': 'page' } : {};

                      return (
                          <Button
                              key={generateRandomWithSeed(value)}
                              isSelected={isCurrentPage}
                              isDisabled={value < 0}
                              variant="outline"
                              size={size}
                              onClick={value < 0 ? null : onClick(value)}
                              emphasis={emphasis}
                              {...get({ element: 'page', action: `${value}` })}
                              {...a11yProps}
                          >
                              {label}
                          </Button>
                      );
                  })}

            <IconButton
                icon={mdiChevronRight}
                isDisabled={currentPage === lastIndex}
                onClick={onNextClick}
                label={translateKey(GLOBAL.NEXT)}
                size={size}
                emphasis={emphasis}
                {...get({ element: 'next' })}
            />
        </FlexBox>
    );
};
