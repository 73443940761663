/**
 * Google Drive Picker types
 * @link https://developers.google.com/drive/picker/reference?hl=fr#feature
 */

/**
 * View types.
 * We did not add obsolete views.
 *
 * @links https://developers.google.com/drive/picker/reference/picker.viewid?hl=fr
 */
export enum GDCustomDocViewType {
    DEFAULT_VIEW = 'MyDrive',
    SHARED_WITH_ME_VIEW = 'SharedWithMe',
    STARRED_VIEW = 'Starred',
    SHARED_DRIVE_VIEW = 'SharedDrive',
    FULL_VIEW = 'Full', // All the views at once
}

export type GDViewIdOptions =
    | 'DOCS'
    | 'DOCS_IMAGES'
    | 'DOCS_IMAGES_AND_VIDEOS'
    | 'DOCS_VIDEOS'
    | 'DOCUMENTS'
    | 'DRAWINGS'
    | 'FOLDERS'
    | 'FORMS'
    | 'PDFS'
    | 'SPREADSHEETS';

export interface GDCustomViewConfig {
    customDocViews: GDCustomDocViewType[];
    viewId: GDViewIdOptions;
    setIncludeFolders?: boolean;
    setSelectFolderEnabled?: boolean;
    starredTranslation?: string;
}

export interface GDPickerConfiguration {
    clientId: string;
    developerKey: string;
    viewId?: GDViewIdOptions;
    viewMimeTypes?: string[];
    selectableMimeTypes?: string[];
    setIncludeFolders?: boolean;
    setSelectFolderEnabled?: boolean;
    token: string;
    multiselect?: boolean;
    disabled?: boolean;
    appId?: string;
    supportDrives?: boolean;
    showUploadView?: boolean;
    showUploadFolders?: boolean;
    setParentFolder?: string;
    customDocViews?: GDCustomDocViewType[];
    locale?: string;
    starredTranslation?: string;
    customScopes?: string[];
}

export interface GDAuthResult {
    access_token: string;
    authuser: string;
    client_id: string;
    cookie_policy: string;
    expires_at: string;
    expires_in: string;
    issued_at: string;
    login_hint: string;
    response_type: string | undefined;
    scope: string;
    session_state: null;
    status: { signed_in: boolean; method: string; google_logged_in: boolean };
    token_type: string;
    error: boolean | undefined;
}

/**
 * https://developers.google.com/picker/docs/reference#document
 */
export type GDDocument = google.picker.DocumentObject;

/**
 * This list is non exhaustive, the full available properties are listed on the link below
 * https://developers.google.com/drive/api/reference/rest/v3/files#File
 */
export interface GDFile {
    /** The ID of the file. */
    id: string;
    /** The MIME type of the file. */
    mimeType: string;
    /** A link for downloading the content of the file in a browser. */
    webContentLink: string;
    /** A link for opening the file in a relevant Google editor or viewer in a browser. */
    webViewLink: string;
    /** The name of the file. */
    name: string;
    /** ID of the shared drive the file resides in. Only populated for items in shared drives. */
    driveId: string;
    /** The time at which the file was created (RFC 3339 date-time). */
    createdTime: string;
    /** The last time the file was modified (RFC 3339 date-time). */
    modifiedTime: string;
    /** Whether the file has been trashed, either explicitly or from a trashed parent folder. */
    trashed: boolean;
    /** Whether the file has been explicitly trashed, as opposed to recursively trashed from a parent folder. */
    explicitlyTrashed: boolean;
    /** The thumnail url: A short-lived link to the file's thumbnail, if available. Typically lasts on the order of hours. */
    thumbnailLink: string;
}
