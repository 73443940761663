import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { GenerativeContentDialogProps } from './GenerativeContentDialog';

const LazyGenerativeContentDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'insert-generative-content-dialog' */
            './GenerativeContentDialog'
        ),
);

export const GenerativeContentDialog: React.FC<GenerativeContentDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyGenerativeContentDialog {...props} />
        </DelayedSuspense>
    );
};
