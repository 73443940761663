/* istanbul ignore file */
import React, { useCallback, useMemo } from 'react';

import debounce from 'lodash/debounce';
import keys from 'lodash/keys';
import values from 'lodash/values';

import { useClassnames } from '@lumapps/classnames';
import { ButtonSelect } from '@lumapps/combobox/components/ButtonSelect';
import { DEBOUNCE_DELAY, FULL_DATE_SIX_FRACTIONAL_SECONDS } from '@lumapps/constants';
import { EVENTS } from '@lumapps/events/keys';
import { currentLanguageSelector } from '@lumapps/languages';
import { mdiMagnify, mdiSort } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, TextField } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getMoment } from '@lumapps/utils/time/getMoment';
import { FilterComponentProps } from '@lumapps/widget-base/types';
import { getFilterByName } from '@lumapps/widget-base/utils/getFilterByName';

import { DatePickerChip } from '../DatePickerChip';

import './index.scss';

const CLASSNAME = 'event-list-filters';

interface EventListFiltersType {
    searchTerm?: string;
    fromDate?: string;
    toDate?: string;
    listOrderDir?: 'asc' | 'desc';
}

export const EventListFilters: React.FC<FilterComponentProps<EventListFiltersType>> = ({
    onFilterChange,
    onApply,
    activeFilters,
    availableFilters,
    theme,
}) => {
    const language = useSelector(currentLanguageSelector);
    const { element } = useClassnames(CLASSNAME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedApply = useCallback(
        debounce(() => {
            onApply();
        }, DEBOUNCE_DELAY),
        [onApply],
    );

    const debouncedOnChange = useCallback(
        (filterValue: any, filterName: keyof EventListFiltersType) => {
            onFilterChange({ [filterName]: filterValue });
            debouncedApply();
        },
        [onFilterChange, debouncedApply],
    );

    const { translateKey } = useTranslate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sortChoices = {
        asc: { id: 'asc', value: GLOBAL.OLDEST },
        desc: { id: 'desc', value: GLOBAL.LATEST },
    };

    const currentSort = useMemo(() => {
        const activeSortValue = activeFilters?.listOrderDir;
        if (activeSortValue && sortChoices[activeSortValue]) {
            return sortChoices[activeSortValue];
        }
        const initialSortValue = getFilterByName('LIST_ORDER_DIR', availableFilters)?.defaultValue;
        if (initialSortValue && keys(sortChoices).includes(initialSortValue)) {
            return sortChoices[initialSortValue as keyof typeof sortChoices];
        }

        return sortChoices.asc;
    }, [activeFilters, availableFilters, sortChoices]);

    return (
        <FlexBox fillSpace orientation="horizontal" hAlign="center" gap="big" className={CLASSNAME}>
            {getFilterByName('SEARCH_TERM', availableFilters) && (
                <TextField
                    className={element('filter')}
                    onChange={(value) => debouncedOnChange(value, 'searchTerm')}
                    name="searchTerm"
                    value={activeFilters?.searchTerm || ''}
                    clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                    placeholder={translateKey(EVENTS.SEARCH_FOR_EVENT)}
                    icon={mdiMagnify}
                    theme={theme}
                />
            )}

            {getFilterByName('FROM_DATE', availableFilters) && (
                <DatePickerChip
                    className={element('filter')}
                    value={activeFilters?.fromDate}
                    label={translateKey(GLOBAL.FROM)}
                    name="fromDate"
                    theme={theme}
                    onChange={(value, name: 'fromDate') =>
                        debouncedOnChange(
                            getMoment()(value)
                                .hours(0)
                                .minutes(0)
                                .seconds(0)
                                .milliseconds(0)
                                .utc()
                                .format(FULL_DATE_SIX_FRACTIONAL_SECONDS),
                            name,
                        )
                    }
                    onClear={activeFilters?.fromDate ? () => debouncedOnChange(null, 'fromDate') : undefined}
                    locale={language}
                    valueDisplay={(date) => getMoment()(`${date}Z`).local().format('l')}
                    datePickerProps={{
                        maxDate: activeFilters?.toDate ? new Date(activeFilters?.toDate) : undefined,
                    }}
                />
            )}
            {getFilterByName('TO_DATE', availableFilters) && (
                <DatePickerChip
                    className={element('filter')}
                    value={activeFilters?.toDate}
                    label={translateKey(GLOBAL.TO)}
                    name="toDate"
                    theme={theme}
                    onChange={(value, name: 'toDate') =>
                        debouncedOnChange(
                            getMoment()(value)
                                .hours(23)
                                .minutes(59)
                                .seconds(59)
                                .milliseconds(999)
                                .utc()
                                .format(FULL_DATE_SIX_FRACTIONAL_SECONDS),
                            name,
                        )
                    }
                    onClear={activeFilters?.toDate ? () => debouncedOnChange(null, 'toDate') : undefined}
                    locale={language}
                    valueDisplay={(date) => getMoment()(`${date}Z`).utc().local().format('l')}
                    datePickerProps={{
                        minDate: activeFilters?.fromDate ? new Date(activeFilters?.fromDate) : undefined,
                    }}
                />
            )}

            {getFilterByName('LIST_ORDER_DIR', availableFilters) ? (
                <FlexBox marginAuto="left">
                    <ButtonSelect
                        emphasis={Emphasis.low}
                        leftIcon={mdiSort}
                        label={translateKey(GLOBAL.SORT_BY)}
                        options={values(sortChoices)}
                        valueRenderer={(option) => translateKey(option.value)}
                        className={element('filter')}
                        value={currentSort}
                        onChange={({ id }) => debouncedOnChange(id, 'listOrderDir')}
                        theme={theme}
                    />
                </FlexBox>
            ) : undefined}
        </FlexBox>
    );
};
