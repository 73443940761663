import get from 'lodash/fp/get';

import { requestMembership } from '@lumapps/communities/api';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { sleep } from '@lumapps/utils/time/sleep';

import { getSpaceData } from '../api';
import { SpaceData } from '../types';
import { selectSpaceData, selectSpaceId } from './selectors';
import { actions } from './slice';

/** the delay to wait before refreshing space data */
const REFRESH_DELAY = 1500;

export const fetchSpaceData = (id: string, slug: string) => async (dispatch: Dispatch) => {
    dispatch(actions.request());

    try {
        const { data } = await getSpaceData(id);

        dispatch(actions.success({ data: { ...data, slug }, id }));
    } catch (error) {
        dispatch(actions.failure(get('response.status', error)));
    }
};

export const refreshSpaceData = async (dispatch: Dispatch, getState: GetBaseState) => {
    const state = getState();

    const { slug } = selectSpaceData(state) as SpaceData;
    const id = selectSpaceId(state) as string;

    dispatch(actions.request());

    // since the API can't guarantee the data will be updated in a reasonable timeframe, we have to wait
    await sleep(REFRESH_DELAY);

    try {
        const { data } = await getSpaceData(id);

        dispatch(actions.success({ data: { ...data, slug }, id }));
    } catch (error) {
        dispatch(actions.failure(get('response.status', error)));
    }
};

export const requestAccess = async (dispatch: Dispatch, getState: GetBaseState) => {
    const id = selectSpaceId(getState()) as string;

    dispatch(actions.requestAccessRequest());

    try {
        await requestMembership({ communityKey: id });

        dispatch(actions.requestAccessSuccess());
    } catch (error) {
        dispatch(actions.requestAccessFailure());
    }
};
