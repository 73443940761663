import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { isAttachmentsTopic } from '@lumapps/wrex/serialization/dita/types';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';

import { AttachmentElement } from '../../types';
import { createAttachments } from '../../utils/createAttachments';

/**
 * Convert Dita attachments topic into slate attachment element.
 */
export const ATTACHMENTS_CONVERT: ElementConvertOption = {
    test: isAttachmentsTopic,
    transform(node, children): AttachmentElement {
        // Create a slate attachments elements keeping only element nodes
        const elements = children.filter(isElement);
        return createAttachments(elements.length > 0 ? elements : undefined);
    },
};
