/* eslint-disable import/no-mutable-exports */
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import uslug from 'uslug';

import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import kebabCase from 'lodash/kebabCase';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';

import { FORBIDDEN_SLUG_CHARACTERS } from 'common/utils/states-utils';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/* eslint-disable import/no-mutable-exports */
let findUrlsInString = noop;
let getApiProfileFieldFromMap = noop;
let getBackgroundImage = noop;
let getImageURL = noop;
let isDesignerMode = noop;
let isEmailValid = noop;
let isGAValid = noop;
let redirectTo = noop;
let resizeImage = noop;
let shouldOpenInNewWindow = noop;

/**
 * Expose the methods from the AngularJS service to the React world.
 *
 * @param {Service} Utils The angular's `UtilsService`.
 */
function setUtils(Utils) {
    ({
        getApiProfileFieldFromMap,
        getBackgroundImage,
        getImageURL,
        isDesignerMode,
        isEmailValid,
        isGAValid,
        redirectTo,
        resizeImage,
        shouldOpenInNewWindow,
        findUrlsInString,
    } = Utils);
}

/**
 * Returns the offset `{ width, height, left, top }` of the DOM `element`.
 *
 * @param  {Object} el The DOM element.
 * @return {Object} The offset of the DOM `element` in function of the <body> position.
 */
function offset(el) {
    const bounds = el.getBoundingClientRect();
    const { body } = global.document;

    return {
        height: bounds.height,
        left: bounds.left + body.scrollLeft,
        top: bounds.top + body.scrollTop,
        width: bounds.width,
    };
}

/**
 * Returns the width of the DOM `element`.
 *
 * @param  {Object}  el The DOM element.
 * @return {boolean} Width of the DOM `element`.
 */
function width(el) {
    const { clientWidth } = el;

    return clientWidth === null || clientWidth === undefined ? offset(el).height : clientWidth;
}

/**
 * Returns the height of the DOM `element`.
 *
 * @param  {Object}  el The DOM element.
 * @return {boolean} Height of the DOM `element`.
 */
function height(el) {
    const { clientHeight } = el;

    return clientHeight === null || clientHeight === undefined ? offset(el).height : clientHeight;
}

/**
 * Checks whether a DOM `node` is contained by a DOM `parentNode`.
 *
 * @param  {Object}  parentNode The parent DOM node.
 * @param  {Object}  node       The DOM node.
 * @return {boolean} Whether the `parentNode` contains `node` or not.
 */
function containsNode(parentNode, node) {
    if (node === parentNode) {
        return true;
    }
    let current = node;
    while (current) {
        if (current === parentNode) {
            return true;
        }
        current = current.parentNode;
    }

    return false;
}

/**
 * Validate slug value.
 *
 * @param  {string|Object} slug The slug or the translatable slug.
 * @return {boolean}       If the slug is valid or not.
 */
function isSlugValid(slug) {
    const regExp = new RegExp(`^[^${FORBIDDEN_SLUG_CHARACTERS.join('\\')}]+$`);

    if (!slug) {
        return false;
    }

    if (isString(slug)) {
        return Boolean(slug.match(regExp));
    }

    let valid = 0;
    let langs = 0;

    angular.forEach(slug, (translatedSlug) => {
        langs++;

        if ((translatedSlug && translatedSlug.match(regExp)) || (translatedSlug && translatedSlug === '')) {
            valid++;
        }
    });

    return valid === langs;
}

/**
 * Returns a new object with property found at `path` set to `value`.
 * If `value` is `undefined`, the property is removed from `object`.
 *
 * @param  {Object} object The object to update.
 * @param  {Array}  path   Array of property names.
 * @param  {any}    value  The value to set.
 * @param  {number} index  The index of `path` to take the `key` from.
 * @return {Object} The updated object.
 */
function setPath(object = {}, path, value, index = 0) {
    if (index >= path.length) {
        return value;
    }
    const key = path[index];
    const propertyValue = setPath(object[key], path, value, index + 1);

    return propertyValue === undefined
        ? omit(object, key)
        : {
              ...object,
              [key]: propertyValue,
          };
}

/**
 * Decorator: add a "onChangeProperty" to a React element.
 *
 * @param  {ReactElement} el         The React element on which to inject `onChangeProperty`.
 * @param  {Function}     [onChange] If set, uses the return value as the new value.
 * @return {ReactElement} The same React element.
 */
function withOnChangeProperty(el, onChange) {
    el.onChangeProperty = (propertyValue, propertyName, payload) => {
        const { props } = el;
        const value = props.value === null || props.value === undefined ? {} : props.value;

        let newValue;

        if (angular.isDefined(propertyName)) {
            if (isArray(propertyName)) {
                newValue = setPath(value, propertyName, propertyValue);
            } else if (isUndefined(propertyValue)) {
                newValue = omit(value, propertyName);
            } else {
                newValue = { ...value, [propertyName]: propertyValue };
            }
        } else {
            newValue = propertyValue;
        }

        return props.onChange(
            onChange ? onChange(newValue, propertyValue, propertyName, payload) : newValue,
            props.name,
            payload,
        );
    };

    return el;
}

/**
 * Injects `onClear(payload)` method bound to `element`, which calls
 * `onChange(undefined, props.name, payload)` when triggered.
 * This enables quickly clearing values when needed.
 *
 * @param  {ReactElement} el                The React element on which to inject `onClear`.
 * @param  {Function}     [onClearCallback] If set, called after the `onChange` call.
 * @return {ReactElement} The same React element.
 */
function onClear(el, onClearCallback) {
    el.onClear = (payload) => {
        const { props } = el;
        const result = props.onChange(undefined, props.name, payload);
        if (onClearCallback) {
            onClearCallback(props.value, props.name, payload);
        }

        return result;
    };

    return el;
}

/**
 * Decorator: Injects a randomly generated `uuid` property if no `id` prop is set.
 *
 * @param {ReactElement} el The React element on which to inject `uuid`.
 */
function withUUID(el) {
    el.uuid = el.props.id === null || el.props.id === undefined ? generateUUID() : el.props.id;
}

/**
 * Calls `event.preventDefault()` and returns `event`.
 *
 * @param  {Object} evt The event object.
 * @return {Object} The defaultPrevent-ed event object.
 */
const preventedEvent = (evt) => {
    evt.preventDefault();

    return evt;
};

/**
 * Calls `event.stopPropagation()` and returns `event`.
 *
 * @param  {Object} evt The event object.
 * @return {Object} The stopPropagation-ed event object.
 */
const stoppedEvent = (evt) => {
    evt.stopPropagation();

    return evt;
};

/**
 * Escape a regex.
 *
 * @param  {string} pattern The regex pattern.
 * @return {string} The escaped pattern.
 */
function escapeRegex(pattern) {
    const REGEX_CHARS_PATTERN = /[.?*+^$[\]\\(){}|-]/g;

    return String(pattern).replace(REGEX_CHARS_PATTERN, '\\$&');
}

/**
 * Replaces variables in the provided `string` by their value in the provided `values` dict.
 *
 * @param  {string} string The string in which to replace tokens.
 * @param  {Object} values Mapping of `token: value`.
 * @return {string} The string with the tokens replaced...........
 */
const replaceTokens = (string, values) => {
    /**  Example:
     * replaceTokens('%a% + %b% = %result%', {
     * a: 1,
     * b: 2,
     * result: 3,
     * }) === '1 + 2 = 3';
     * */

    return reduce(
        keys(values),
        (subString, variable) => subString.replace(new RegExp(escapeRegex(`%${variable}%`, 'g'), values[variable])),
        string,
    );
};

/**
 * Transform a number so it is display with a metric suffix.
 * E.G. 123456 is transformed to 123.4K.
 *
 * @param  {number} number        The number to format.
 * @param  {number} fractionDigit The number of fraction digits to display.
 * @return {string} Human readable formatted string.
 */
const KMFormatter = (number, fractionDigit = 0) => {
    const n = Number(number);

    if (isNaN(n)) {
        return number;
    }

    let divider = 1;
    let unit = '';

    /* eslint-disable no-magic-numbers */
    if (n > 999999) {
        divider = 1000000;
        unit = 'M';
    } else if (n > 9999) {
        divider = 1000;
        unit = 'K';
    }
    /* eslint-enable no-magic-numbers */

    return divider > 1 ? (n / divider).toFixed(fractionDigit) + unit : n;
};

/**
 * Limit the fraction digits of an input.
 *
 * @param  {*}      number        The input to transform.
 * @param  {number} fractionDigit The number of fraction digits to display.
 * @return {number} The limited number.
 */
const limitFractionDigits = (number, fractionDigit = 0) => {
    const n = Number(number);

    if (isNaN(n)) {
        return number;
    }

    return n % 1 === 0 ? n : n.toFixed(fractionDigit);
};

/**
 * Programmatically get the media query for detecting mobile mode.
 *
 * @return {boolean} Whether the mobile mode is enabled or not.
 */
const checkIfMobile = () => {
    return !window.matchMedia('all and (min-width: 780px)').matches;
};

/**
 * Return whether the current value is an sanitized HTML entity.
 *
 * @param  {any}     value The value tu check.
 * @return {boolean} Whether it is a sanitized html entity.
 */
function isHtmlEntity(value) {
    return isObject(value) && isString(value.__html);
}

/**
 * Removes html tags for a string.
 *
 * @param  {string} value The value to strip the html tags from.
 * @return {string} The string without html tags.
 */
function stripHtmlTags(value) {
    if (!isString(value)) {
        throw new Error('You are trying to remove hmtl tags from a non string entity');
    }

    const htmlTagsRegexp = /(<([^>]+)>)/gi;

    return sanitizeHTML(value).replace(htmlTagsRegexp, '');
}

/**
 * Slugify a text.
 * It means, remove any special characters (spaces, accents, ...).
 * Only "-" and lower case character are allowed.
 *
 * @param  {string} value The text to slugify.
 * @return {string} The slugified text.
 */
function slugify(value) {
    if (angular.isUndefinedOrEmpty(value) || !angular.isString(value)) {
        return '';
    }

    return kebabCase(uslug(value));
}

/**
 * Check if the "autotest" url param exists in the current URL.
 *
 * @return {boolean} If auto test mode is enabled or not.
 */
function isAutoTestModeEnabled() {
    return /[?&]autotest/.test(location.search);
}

/////////////////////////////

export {
    checkIfMobile,
    containsNode,
    escapeRegex,
    findUrlsInString,
    getApiProfileFieldFromMap,
    getBackgroundImage,
    getImageURL,
    height,
    isDesignerMode,
    isEmailValid,
    isGAValid,
    isHtmlEntity,
    isSlugValid,
    isAutoTestModeEnabled,
    KMFormatter,
    limitFractionDigits,
    offset,
    onClear,
    preventedEvent,
    redirectTo,
    replaceTokens,
    resizeImage,
    setPath,
    setUtils,
    slugify,
    shouldOpenInNewWindow,
    stoppedEvent,
    stripHtmlTags,
    width,
    withOnChangeProperty,
    withUUID,
};
