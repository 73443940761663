export enum PENDO_SCOPES {
    SIDE_NAV = 'side-nav',
    HEADER = 'header',
}

export const ALLOW_CONNECTED_AS_ANALYTICS = 'allow-connected-as-analytics';

/* Google Analytics and Tag manager */
export const CLIENT_DATA_LAYER_NAME = 'dataLayerCustom';
export const GA_TAG_NAME = 'ga';
export const GA4_TAG_NAME = 'ga4';
export const GTM_EVENT_NAME = 'LumAppsGTM';
export const GA4_USER_PROP_PREFIX = 'la_user';
export const GA4_MAX_ATTR_NAME_SIZE = 40;
