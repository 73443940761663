import React from 'react';

import { spacings, useClassnames } from '@lumapps/classnames';
import { Header as HeaderWrapper } from '@lumapps/lumx-front-office/components/Header';
import { Size, SkeletonCircle, SkeletonRectangle, SkeletonRectangleVariant } from '@lumapps/lumx/react';

import { InstanceLogo } from '../InstanceLogo/InstanceLogo';

const CLASSNAME = 'header';
const CLASSNAME_TOP = 'header-top';

const MobileLoadingHeader: React.FC<any> = ({ topStyles, instanceLogoSrc, fallbackHomeImageSrc }) => {
    const { element, block } = useClassnames(CLASSNAME_TOP);
    const iconClasses = spacings([
        { type: 'margin', direction: 'left', size: 'regular' },
        { type: 'margin', direction: 'right', size: 'regular' },
    ]);

    return (
        <HeaderWrapper className={CLASSNAME} hasNavigation>
            <div className={block(['sticky'])} style={{ backgroundColor: topStyles.backgroundColor }}>
                <div className={element('wrapper')}>
                    <div className={element('mobile-side-nav')}>
                        <SkeletonCircle size={Size.xs} className={iconClasses} />
                    </div>

                    <div className={element('logo')}>
                        <InstanceLogo
                            className={element('logo')}
                            homeImageSrc={instanceLogoSrc as string}
                            fallbackHomeImageSrc={fallbackHomeImageSrc}
                            isLoading
                        />
                    </div>

                    <div className={element('actions')}>
                        <SkeletonCircle size={Size.xs} className={iconClasses} />
                        <SkeletonRectangle
                            variant={SkeletonRectangleVariant.squared}
                            width={Size.xs}
                            height={Size.xs}
                            className={iconClasses}
                        />
                        <SkeletonCircle size={Size.xs} className={iconClasses} />
                    </div>
                </div>
            </div>
        </HeaderWrapper>
    );
};

export { MobileLoadingHeader };
