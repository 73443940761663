import React from 'react';

import { STREAMS } from '@lumapps/streams/keys';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';
import { BlockPublicationInfo } from '@lumapps/widget-base/components/Block/BlockPublication/BlockPublicationInfo';

import { BlockPagePreview } from '../../type';
import { PagePreviewActions } from './PagePreviewActions';

export type BlockPagePreviewPublicationInfoProps = Pick<
    BlockPagePreview,
    'author' | 'pageId' | 'slug' | 'contentType' | 'publishedAt' | 'theme' | 'site'
>;

/**
 * The Block Publication Info for a BlockPage Preview.
 */
export const BlockPagePreviewPublicationInfo: React.FC<BlockPagePreviewPublicationInfoProps> = ({
    pageId,
    author,
    slug,
    contentType,
    publishedAt,
    theme,
    site,
}) => {
    const { iconPath } = useDynamicIcon({ icon: contentType?.icon });
    const { translateKey, translateAndReplace } = useTranslate();

    return (
        <BlockPublicationInfo
            author={{
                email: author?.email,
                userId: author?.userId || '',
                fullName: author?.fullName || '',
                pictureUrl: author?.picture,
                profilePrimaryFieldValues: author?.profilePrimaryFieldValues,
            }}
            avatarBadge={
                contentType?.icon
                    ? {
                          icon: iconPath,
                          alt: '',
                      }
                    : undefined
            }
            publicationDate={publishedAt}
            publicationDescription={
                contentType?.name?.value
                    ? translateAndReplace(GLOBAL.PUBLISHED_IN_LOCATION, {
                          location: contentType.name.value,
                      })
                    : translateKey(STREAMS.PUBLISHED_A_PIECE_OF_CONTENT)
            }
            action={<PagePreviewActions pageId={pageId} slug={slug} site={site} theme={theme} />}
            theme={theme}
        />
    );
};
