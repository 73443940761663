import { useMemo } from 'react';

import { mdiTable } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlate } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { ToolbarItem } from '@lumapps/wrex/types';

import { TABLE } from '../../../constants';
import { WREX_TABLE } from '../../../keys';
import { TableEditor } from '../../../types';

export const useTableButton = (otherProps?: any) => {
    const { translateKey } = useTranslate();
    const editor = useSlate() as ReactEditor & TableEditor;

    const tableButton = useMemo(() => {
        const onClick = () => {
            editor.insertTable({});
            focusAt(editor, editor.selection);
        };

        const tableButtonProps: ToolbarItem = {
            type: 'option',
            itemKey: TABLE,
            icon: mdiTable,
            label: translateKey(WREX_TABLE.TABLE_BUTTON),
            otherProps,
            onClick,
        };

        return tableButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translateKey, otherProps?.isDisabled]);
    return tableButton;
};
