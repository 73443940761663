const bullhornEdit =
    'M18.153 13.773l2.078 2.077L14.088 22H12v-2.087l6.153-6.14zM20.15 12a.54.54 0 0 1 .385.172l1.298 1.297a.538.538 0 0 1 0 .78l-1.014 1.013-2.078-2.077 1.014-1.013A.563.563 0 0 1 20.15 12zm-3.77-1.836a8.002 8.002 0 0 0-4.814 3.08L11.253 13H8.177v4c0 .552-.459 1-1.025 1h-2.05a1.013 1.013 0 0 1-1.026-1v-4H3.051C1.918 13 1 12.105 1 11V7c0-1.105.918-2 2.05-2h8.203l5.127-4v9.164z';

const bullhornMute =
    'M17.385 16.799v.171l1.586 1.586-1.415 1.415-3.802-3.803L12.256 15H9.18v4c0 .552-.459 1-1.025 1H6.103a1.013 1.013 0 0 1-1.026-1v-4H4.05C2.918 15 2 14.105 2 13V9c0-1.105.918-2 2.051-2h.535L2 4.414 3.414 3l4 4h.172l9.799 9.799zm0-2.829L10.415 7h1.841l5.129-4v10.97zM22 11c0 1.71-.985 3.26-2.564 4V7C21.006 7.75 22 9.3 22 11z';

const bullhornPlus =
    'M16.38 10.164a8.002 8.002 0 0 0-4.814 3.08L11.253 13H8.177v4c0 .552-.459 1-1.025 1h-2.05a1.013 1.013 0 0 1-1.026-1v-4H3.051C1.918 13 1 12.105 1 11V7c0-1.105.918-2 2.05-2h8.203l5.127-4v9.164zM19 17h4v2h-4v4h-2v-4h-4v-2h4v-4h2v4z';

export { bullhornEdit, bullhornMute, bullhornPlus };
