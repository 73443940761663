import React from 'react';

import range from 'lodash/range';

import { classnames, color, margin } from '@lumapps/classnames';
import {
    Alignment,
    FlexBox,
    GlobalSize,
    ListItem,
    Orientation,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
} from '@lumapps/lumx/react';

const skeletonSizes: GlobalSize[] = [Size.l, Size.xl, Size.l];

export interface GroupPickerFieldSkeletonsProps {
    /** The number of skeletons to display. Default at 1; */
    number?: number;
}

export const GroupPickerFieldSkeletons: React.FC<GroupPickerFieldSkeletonsProps> = ({ number = 1 }) => {
    return (
        <>
            {range(number).map((_, index) => {
                const skelSize = skeletonSizes[index % skeletonSizes.length];
                return (
                    <ListItem
                        size={Size.regular}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                    >
                        <FlexBox orientation={Orientation.horizontal} hAlign={Alignment.center}>
                            <SkeletonRectangle
                                className={classnames(color('background', 'dark', 'L5'), margin('left', 'big'))}
                                width={skelSize || Size.xl}
                                height={Size.xxs}
                                variant={SkeletonRectangleVariant.pill}
                            />
                        </FlexBox>
                    </ListItem>
                );
            })}
        </>
    );
};
