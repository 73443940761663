import { useQuery } from '@lumapps/base-api/react-query';
import { getCurrentContentId } from '@lumapps/contents/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { selectSpaceId } from '@lumapps/spaces/ducks/selectors';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';

import { get, queryKeys } from '../api';

export function useGetUserContent(enabled = true) {
    const currentContentId = useSelector(getCurrentContentId);
    const spaceId = useSelector(selectSpaceId);
    const { isSpace } = useSpace();

    // Getting content of space id
    const contentId = isSpace && spaceId ? spaceId : currentContentId;

    // Fetch user content metadata
    return useQuery({
        enabled: enabled && !!contentId,
        queryKey: queryKeys.get(contentId),
        queryFn: () => get(contentId),
    });
}
