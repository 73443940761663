/* istanbul ignore file */
import BaseApi, { fetchAll } from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse, PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { AdminMetadata, AdminMetadataPositionUpdate, NewMetadataPayload } from '@lumapps/metadata-admin/type';

import { FETCH_ALL_METADATA_MAX_RESULTS } from '../constants';
import { Metadata } from '../types';

/**
 * Metadata API.
 * https://api.lumapps.com/docs/metadata
 */
export const metadataApi = new BaseApi({ path: 'metadata', bypassMonolith: true });

interface ListMetadataRequest extends Omit<ServerListRequest, 'maxResults'> {
    emptyParent?: boolean;
    name?: string;
    parent?: string;
    instance?: string;
    familyId?: string;
    fields?: string;
    maxResults?: number;
    more?: boolean;
    displayInFilter?: boolean;
    customContentTypeIds?: string[];
    withoutCustomContentTypes?: boolean;
}

export const listMetadata = (params: ListMetadataRequest) =>
    metadataApi.get<ServerListResponse<Metadata>>('list', { params });

export const getMetadata = (uid: string, fields?: string) =>
    metadataApi.getCacheFirst<Metadata>('get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params: { uid, fields } });

/** Get multiple metadata at once */
export const getMultipleMetadata = (ids: string[], fields?: string) =>
    metadataApi.getCacheFirst<ServerListResponse<Metadata>>('getMulti', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
        params: { ids, fields },
    });

/**
 * Recursively call all metadata from a family.
 *
 * Returns an object with the data to get closer to Axios response.
 */
export const getAllFamilyMetadata = async ({
    maxResults = FETCH_ALL_METADATA_MAX_RESULTS,
    ...params
}: ListMetadataRequest) => {
    const metadataFamilies = await fetchAll(listMetadata, { ...params }, maxResults);

    /**
     * Returns an object with the data to get closer to Axios response.
     */
    return {
        data: metadataFamilies,
    };
};

/**
 * Create metadata category. Can be used for both instance and customer
 * @param params metadata
 */
export const createMetadata = (metadata: AdminMetadata | NewMetadataPayload) => {
    return metadataApi.post('save', { ...metadata });
};

/**
 * Update metadata category. Can be used for both instance and customer
 * @param params metadata
 */
export const updateMetadata = (metadata: AdminMetadata | AdminMetadataPositionUpdate) => {
    return metadataApi.post('save', { ...metadata });
};

/**
 * get list of all metadata for the admin.
 * @param params ListMetadataRequest
 * @param getCacheFirst Boolean
 * @returns AdminMetadata array
 */
export const listAdminMetadata = (params: ListMetadataRequest, getCacheFirst = false) =>
    getCacheFirst
        ? metadataApi.getCacheFirst<ServerListResponse<AdminMetadata>>('list', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
              params,
          })
        : metadataApi.get<ServerListResponse<AdminMetadata>>('list', { params });

/**
 * delete metadata category. Can be used for both instance and customer
 * @param params metadata
 */
export const deleteMetadata = (metadataId: string) => {
    return metadataApi.delete('delete', { params: { uid: metadataId } });
};
