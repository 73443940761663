import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Theme, Typography } from '@lumapps/lumx/react';

import { MetadataLinkListProps } from '../../types';
import { MetadataLink } from '../MetadataLink/MetadataLink';

import './index.scss';

export const CLASSNAME = 'metadata-link-list';

export const MetadataLinkList: React.FC<MetadataLinkListProps> = ({
    metadataList,
    contentTypeId,
    theme = Theme.light,
    loading = false,
}) => {
    const { block } = useClassnames(CLASSNAME);

    if (loading) {
        return (
            <SkeletonTypography
                theme={theme}
                typography={Typography.body1}
                style={{ maxWidth: '192px', width: '100%' }}
            />
        );
    }

    if (!metadataList?.length) {
        return null;
    }

    return (
        <ul className={block(`theme-${theme}`)}>
            {metadataList?.map(({ name, rootName, id }) => (
                <li key={id}>
                    <MetadataLink metadata={{ name, rootName, id }} theme={theme} contentTypeId={contentTypeId} />
                </li>
            ))}
        </ul>
    );
};
