(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetMetadataSettingsController(Content, Metadata) {
        'ngInject';

        var widgetMetadataSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetMetadataSettings.Content = Content;
        widgetMetadataSettings.Metadata = Metadata;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        function init() {
            widgetMetadataSettings.metadata = Metadata.getRefactoredMetadata(true);
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetMetadataSettingsController', WidgetMetadataSettingsController);
})();
