export let error;
export let success;
export let confirm;

export function setNotification(notification) {
    confirm = notification.confirm;
    error = notification.error;
    success = notification.success;
    confirm = notification.confirm;
}
