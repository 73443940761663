import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { getInstance } from '../api';
import { GetInstanceParams } from '../api/types';
import { instanceIdSelector, getCurrentInstanceName } from '../ducks/selectors';

/**
 * Retrieves the basic data for the current instance, usually to be displayed
 * as the title page or the browser's tab name.
 * @returns Partial<Instance>
 */
export const useInstance = () => {
    const { translateKey, translateAndReplace } = useTranslate();
    const instanceId = useSelector(instanceIdSelector);
    const instanceName = useSelector(getCurrentInstanceName);

    const homeTitle = instanceName
        ? translateAndReplace(GLOBAL.HOME_PAGE, { site: instanceName })
        : translateKey(GLOBAL.GO_TO_HOMEPAGE);

    return {
        id: instanceId,
        name: instanceName,
        homeTitle,
    };
};

/**
 * Hook that retrieves a given instance from the backend and retrieves
 * the data, with a loading state
 * @param params
 */
export const useInstanceFetch = (params: GetInstanceParams, fetchOnMount = true, useCache = true) => {
    const fetchWithStatus = useFetchWithStatus({
        initialFetchParams: { params },
        onFetch: (params: GetInstanceParams) => {
            return getInstance(params, useCache);
        },
        fetchOnMount,
    });

    const isLoading = fetchWithStatus.status === BaseLoadingStatus.loading;
    const hasError = fetchWithStatus.status === BaseLoadingStatus.error;

    return {
        ...fetchWithStatus,
        isLoading,
        instance: fetchWithStatus ? fetchWithStatus.response : null,
        hasLoaded: !isLoading && !hasError && fetchWithStatus.status === 'idle',
    };
};
