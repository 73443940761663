import React from 'react';

import { useWindowScroll } from './useWindowScroll';

function getStickyHeaders() {
    return document.querySelectorAll('header .sticky, .sidebar-simple');
}

export function getFixedHeaderHeight() {
    const headers = getStickyHeaders();
    if (!headers.length) {
        return 0;
    }
    // Combine height of all children
    let height = 0;
    for (const child of Array.from(headers)) {
        height += child.getBoundingClientRect().height;
    }
    return height;
}

/**
 * Utility hook returning the current fixed header height in pixel.
 */
export function useHeaderFixedHeight() {
    const [height, setHeight] = React.useState(0);
    const [needUpdatingOnScroll, setNeedUpdatingOnScroll] = React.useState(true);

    const update = React.useCallback(() => {
        const headerHeight = getFixedHeaderHeight();
        if (headerHeight) {
            setHeight(headerHeight);
            // No need to update anymore
            setNeedUpdatingOnScroll(false);
        }
    }, []);

    // Update on scroll only once
    useWindowScroll(update, needUpdatingOnScroll);

    return height;
}
