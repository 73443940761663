import { PRIORITY } from '@lumapps/base-api';
import { isInDesignerMode as isInDesignerModeSelector } from '@lumapps/contents/ducks/selectors';
import { mdiBookmark, mdiBookmarkOutline } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { GetUserBookmarkParams } from '@lumapps/user-bookmarks/api';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useSaveButton } from './useSaveButton';

export type UseContextualSaveParams = Pick<GetUserBookmarkParams, 'resourceId' | 'resourceType'>;

export const useContextualSave = ({ resourceId, resourceType }: UseContextualSaveParams) => {
    const userCanSaveItem = useSelector(isConnected);
    const isInDesignerMode = useSelector(isInDesignerModeSelector);

    const { fetchSavedItem, isDisabled, isSaved, onClick, status } = useSaveButton({
        resourceId,
        resourceType,
        fetchOnMount: !isInDesignerMode && userCanSaveItem,
        priority: PRIORITY.LOW,
    });

    return {
        hasUserSavedItemsFeature: userCanSaveItem,
        fetchSavedItem,
        // action returns false if feature is disabled
        action: userCanSaveItem && {
            dataAttributes: {
                element: `${resourceType}-save-item`,
                action: 'save-item',
            },
            isSelected: isSaved,
            isDisabled: isDisabled || status === BaseLoadingStatus.error,
            isLoading: status === BaseLoadingStatus.loading,
            iconSvgPath: isSaved ? mdiBookmark : mdiBookmarkOutline,
            labelKey: isSaved ? GLOBAL.SAVED : GLOBAL.SAVE,
            action: onClick,
            className: 'save-button no-background-color',
        },
    };
};
