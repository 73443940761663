const savedCalls: Record<string, unknown> = {};
const pendingCalls: Record<string, Promise<unknown>> = {};

/**
 * Make sure the call is only made once. In the entire app
 * Difference from the cache system is that we are ensuring that many parrallele request cannot be performed.
 * @param key Identifier
 * @param fct Function to call if not already done
 * @returns respond from fct
 */
export const oneCallAssurance = async <T = unknown,>(key: string, fct: () => Promise<T>): Promise<T> => {
    if (savedCalls[key]) {
        return savedCalls[key] as Promise<T>;
    }

    if (!pendingCalls[key]) {
        // call the fct to get results
        pendingCalls[key] = fct();
    }

    const result = await pendingCalls[key];
    savedCalls[key] = result;
    delete pendingCalls[key];

    return result as T;
};
