import BaseApi, { cleanParams, type ServerListResponse } from '@lumapps/base-api';
import type { User } from '@lumapps/user/types';

import { type CUSTOMER_KIND } from '../constants';
import type { Customer } from '../types';
import { type GetCustomerListParams } from './sharedAPI';

/**
 * Base API for the /customer service.
 */
export const customerApi = new BaseApi({ path: 'customer' });

/**
 * Base API for the /customer service.
 */
export const customerSharedApi = new BaseApi({ path: 'services/organization/admin', useShared: true });

export interface GetCustomerParams {
    /** customer id */
    id: string;
    /** list of fields (separated by a comma) */
    fields?: string;
}

/**
 * Fetches a specific customer by id.
 * @param params GetCustomerParams
 * @returns Promise<Customer>
 */
export const getCustomer = (params: GetCustomerParams) => customerApi.get<Customer>('get', { params });

/**
 * Saves a given customer.
 * @param params Customer
 * @returns Promise<Customer>
 */
export const saveCustomer = (customer: Customer) => customerApi.post('save', customer);

export interface GetCustomerCrossCells {
    /** customer id */
    id: string;
}

/**
 * Get the customer via admin endpoint
 * This call is cross cells
 *
 * @param {string} id - Can be a customer id, a slug or a host
 * @returns {Promise<Customer>}
 */
export const getCustomerCrossCells = (params: Pick<GetCustomerParams, 'id'>) => {
    const urlParams = { params: { organizationIdentifier: params.id } };
    return customerSharedApi.get<Customer>('organizations', urlParams);
};

/**
 * Saves a given set of properties for the current customer.
 * @param properties
 * @returns Promise<Customer>
 */
export const saveCustomerProperties = (properties: Customer['properties']) =>
    customerApi.post<Customer>('/properties/save', { properties });

export interface GetAdminListParams {
    /** cursor for pagination */
    cursor?: string;
    /** specific instance id */
    instanceId: string;
    /** total results to retrieve */
    maxResults?: number;
    /** whether there are more results or not */
    more?: boolean;
    /** sort field for the results */
    sortOrder?: string;
    /** status */
    status?: string;
    /** query for searching admins */
    query?: string;
}

/**
 * Retrieves a list of admins for a given instance and customer.
 * @param GetAdminListParams
 * @returns Promise<GetAdminListResponse>
 */
export const getAdminList = ({
    instanceId,
    maxResults = 30,
    more,
    sortOrder = 'firstName',
    status = 'enabled',
    cursor,
    query,
}: GetAdminListParams) => {
    return customerApi.get<ServerListResponse<User>>('admin/list', {
        params: {
            maxResults,
            more,
            sortOrder,
            status,
            cursor,
            uid: instanceId,
            query,
            returnCompleteUsers: true,
        },
    });
};

/**
 * Adds a given user as a global admin of the current customer
 * @param selectedUser
 * @returns Promise
 */
export const addGlobalAdmin = (selectedUser: User) => {
    return customerApi.post('admin/add', {
        userIds: [selectedUser.id],
    });
};

export interface DeleteGlobalAdminParams {
    /** global admin to delete */
    email: string;
    /** current instance id */
    instanceId: string;
}

/**
 * Deletes a given user as a global admin of the current customer
 * @param DeleteGlobalAdminParams
 * @returns Promise
 */
export const deleteGlobalAdmin = ({ email, instanceId }: DeleteGlobalAdminParams) => {
    return customerApi.delete('admin/delete', {
        params: {
            email,
            uid: instanceId,
        },
    });
};

export interface GetRegistrationLinkParams {
    kind: string;
}

/**
 * Retrieves a registration link for the given customer. Used on the reseller console.
 * @param params GetRegistrationLinkParams
 * @returns Promise
 */
export const getRegistrationLink = (params: GetRegistrationLinkParams) =>
    customerApi.get('registrationLink', { params });

/**
 * Retrieves a registration link for the given customer
 *
 * @param {CUSTOMER_KIND} kind
 * @param {AbortSignal | undefined} signal
 * @returns {Promise<string>}
 */
export const getRegistrationLinks = async (kind: CUSTOMER_KIND, signal?: AbortSignal): Promise<string> => {
    const { data: registrationLink } = await customerApi.get<{ url: string }>('registrationLink', {
        params: cleanParams({ kind }),
        signal,
    });

    return registrationLink.url;
};

/**
 * Query keys object used to manage query keys
 *
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const customerQueryKeys = {
    all: () => ['customer'] as const,
    registrationLink: (kind: CUSTOMER_KIND) => [...customerQueryKeys.all(), 'registrationLink', kind] as const,
};

/**
 * Query keys object used to manage query keys
 *
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const organizationQueryKeys = {
    all: () => ['organization'] as const,
    customers: (params?: GetCustomerListParams) => [...organizationQueryKeys.all(), 'organizations', params] as const,
    customer: (customerId: Customer['id']) => [...organizationQueryKeys.customers(), customerId] as const,
};
