import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { isConnected } from '@lumapps/user/ducks/selectors';

import { bookmarksSelector } from '../../ducks/selectors';
import { loadRemoteBookmarks } from '../../ducks/thunks';
import { BookmarksMenu } from './BookmarksMenu';

const mapStateToProps = (state: FrontOfficeStore) => {
    return {
        bookmarks: bookmarksSelector(state),
        show: isConnected(state),
    };
};

const mapDispatchToProps = {
    loadRemoteBookmarks,
};

const ConnectedBookmarksMenu = connect(mapStateToProps, mapDispatchToProps)(BookmarksMenu);

export { ConnectedBookmarksMenu as BookmarksMenu };
