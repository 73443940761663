(function IIFE() {
    'use strict';

    /////////////////////////////

    /**
     * The various forms of plural we accept.
     *
     * @type {Object}
     */
    var _PLURALS = {
        count1: 'ONE',
        count2: 'TWO',
        more: 'MORE',
    };

    /**
     * An object with settings for each type of notification.
     * Note: some notifications are instead set or override these configs in settings_service.js 
     *
     * @type {Object}
     * @readonly
     */
    var NotificationSettings = {
        // eslint-disable-next-line camelcase
        comment_like: {
            getReplacements: function getCommentMentionReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'heart',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_MENTIONS', 'CONTENT_TITLE'],
        },
        // Note: this is not a 'real' notification type, it overwrites comment_like when the content is a post.
        // eslint-disable-next-line camelcase
        comment_like_post: {
            getReplacements: function getCommentNewPostReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'parentContentDetails.title'),
                    _.get(notificationGroup.notification, 'title'),
                ];
            },
            hasThumbnail: true,
            icon: 'heart',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_MENTIONS', 'COMMUNITY_NAME', 'POST_TITLE'],
        },
        // eslint-disable-next-line camelcase
        comment_mention: {
            getReplacements: function getCommentMentionReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                ];
            },
            hasThumbnail: true,
            icon: 'comment-multiple-outline',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_MENTIONS'],
        },
        // eslint-disable-next-line camelcase
        comment_new: {
            getReplacements: function getCommentNewReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'comment-multiple-outline',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_COMMENTS', 'CONTENT_TITLE'],
        },
        // Note: this is not a 'real' notification type, it overwrites comment_new when the content is a post.
        // eslint-disable-next-line camelcase
        comment_new_post: {
            getReplacements: function getCommentNewPostReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'parentContentDetails.title'),
                    _.get(notificationGroup.notification, 'title'),
                ];
            },
            hasThumbnail: true,
            icon: 'comment-multiple-outline',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_COMMENTS', 'COMMUNITY_TITLE', 'POST_TITLE'],
        },
        // eslint-disable-next-line camelcase
        comment_reply: {
            getReplacements: function getCommentReplyReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'title'),
                ];
            },
            hasThumbnail: true,
            icon: 'comment-multiple-outline',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_REPLIES', 'POST_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_expiration: {
            getReplacements: function getContentExpirationReplacements(notificationGroup) {
                return [
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                    moment.utc(_.get(notificationGroup.notification, 'contentDetails.endDate')).local().fromNow(true),
                ];
            },
            icon: 'timer',
            tokens: ['CONTENT_TITLE', 'EXPIRATION_DATE'],
        },
        // Note: this is not a 'real' notification type, it overwrites content_expiration when date is in the past.
        // eslint-disable-next-line camelcase
        content_expired: {
            getReplacements: function getContentExpiredReplacements(notificationGroup) {
                return [
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            icon: 'timer',
            tokens: ['CONTENT_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_like: {
            getReplacements: function getContentLikeReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'heart',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_LIKES', 'CONTENT_TITLE'],
        },
        // Note: this is not a 'real' notification type, it overwrites content_like when the content is a post.
        // eslint-disable-next-line camelcase
        content_like_post: {
            getReplacements: function getContentLikePostReplacements(notificationGroup, actionCount,
                fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'parentContentDetails.title'),
                    _.get(notificationGroup.notification, 'title'),
                ];
            },
            hasThumbnail: true,
            icon: 'heart',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_LIKES', 'COMMUNITY_TITLE', 'POST_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_new: {
            getReplacements: function getContentNewReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'pencil',
            tokens: ['AUTHOR_NAME', 'CONTENT_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_refused: {
            getReplacements: function getContentRefusedReplacements(notificationGroup) {
                return [
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            icon: 'close-circle',
            tokens: ['CONTENT_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_update: {
            getReplacements: function getContentUpdateReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'pencil',
            tokens: ['AUTHOR_NAME', 'CONTENT_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_validated: {
            getReplacements: function getContentValidatedReplacements(notificationGroup) {
                return [
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            icon: 'check-circle',
            tokens: ['CONTENT_TITLE'],
        },
        // eslint-disable-next-line camelcase
        content_workflow: {
            getReplacements: function getContentWorkflowReplacements(notificationGroup) {
                return [
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            icon: 'help-circle',
            tokens: ['CONTENT_TITLE'],
        },
        custom: {
            getReplacements: function getCustomReplacements(notificationGroup) {
                if (angular.isDefinedAndFilled(_.get(notificationGroup.notification, 'parentId'))) {
                    return [];
                }

                return [
                    _.get(notificationGroup.notification, 'unreadNotificationCount'),
                    _.get(notificationGroup.notification, 'groupName'),
                ];
            },
            hasThumbnail: function hasThumbnail(notificationGroup) {
                return angular.isDefinedAndFilled(_.get(notificationGroup.notification, 'thumbnail'));
            },
            icon: 'alert-circle-outline',
            plurals: {
                count1: 'ONE',
                more: 'MORE',
            },
            tokens: ['NB_UNREAD_NOTIFICATIONS', 'GROUP_NAME'],
        },
        // eslint-disable-next-line camelcase
        reply_like: {
            getReplacements: function getCommentMentionReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'contentDetails.title'),
                ];
            },
            hasThumbnail: true,
            icon: 'heart',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_MENTIONS', 'CONTENT_TITLE'],
        },
        // Note: this is not a 'real' notification type, it overwrites reply_like when the content is a post.
        // eslint-disable-next-line camelcase
        reply_like_post: {
            getReplacements: function getCommentNewPostReplacements(notificationGroup, actionCount, fnGetUserFullName) {
                var author = _.get(_.first(notificationGroup.actions), 'senderDetails');

                return [
                    (fnGetUserFullName(author)),
                    (actionCount > 1) ? (actionCount - 1) : '',
                    _.get(notificationGroup.notification, 'parentContentDetails.title'),
                    _.get(notificationGroup.notification, 'title'),
                ];
            },
            hasThumbnail: true,
            icon: 'heart',
            plurals: _PLURALS,
            tokens: ['AUTHOR_NAME', 'NB_OTHER_MENTIONS', 'COMMUNITY_NAME', 'POST_TITLE'],
        },
    };

    /////////////////////////////

    angular.module('Constants').constant('NotificationSettings', NotificationSettings);
})();
