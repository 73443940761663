import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import equals from 'lodash/fp/equals';
import identity from 'lodash/fp/identity';
import map from 'lodash/fp/map';
import stubTrue from 'lodash/fp/stubTrue';

import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { CommunityMetadata } from '@lumapps/communities/types';
import evolve from '@lumapps/utils/function/evolve';
import { Layout } from '@lumapps/widget-layout/types';

import {
    SpaceData,
    SpaceLayoutParameters,
    SpaceMembersApiOptions,
    SpaceMembersApiResponse,
    SpaceViews,
} from '../types';

/**
 * Default API for all new space features.
 * */
const spaceApi = new BaseApi({ path: 'spaces', version: BaseApi.versions.v2 });

export const getSpaceData = async (id: string) => {
    const response = await spaceApi.get<SpaceData>(`${id}`);

    const data = evolve(
        {
            enabledServices: map(
                cond([
                    [equals('managed_media_library'), constant('documents')],
                    [stubTrue, identity],
                ]),
            ),
        },
        response.data,
    ) as SpaceData;

    return { ...response, data };
};

export const getSpaceMembers = async (id: string, options: SpaceMembersApiOptions = {}) => {
    const { data } = await spaceApi.get<SpaceMembersApiResponse>(`${id}/members`, {
        params: options,
    });

    return data;
};
/**
 * Layout API used to render Community / Space layouts.
 * */
export const layoutCommunityApi = new BaseApi({
    version: BaseApi.versions.v2,
    path: 'communities',
});

/** Cancel an API called based on query params */
export const communityLayoutById = (communityId: string, communityView?: string) => {
    switch (communityView) {
        case SpaceViews.posts:
            return `${communityId}/legacy-pages/post-list`;
        case SpaceViews.post:
            return `${communityId}/legacy-pages/post-details`;
        case SpaceViews.home:
            return `${communityId}/legacy-pages/homepage`;
        case SpaceViews.drive:
            return `${communityId}/legacy-pages/drive`;
        case SpaceViews.calendar:
            return `${communityId}/legacy-pages/calendar`;
        case SpaceViews.learning:
            return `${communityId}/legacy-pages/learning`;
        default:
            return null;
    }
};

/** Cancel an API called based on query params */
export const cancelFetch = (spaceParams: SpaceLayoutParameters) => {
    const { id, view } = spaceParams;

    const url = communityLayoutById(id, view);

    if (url) {
        layoutCommunityApi.cancel(url);
    }
};

/** Get community layout by organization & content id */
export async function getCommunityLayoutById(spaceParams: SpaceLayoutParameters, languages: string): Promise<Layout> {
    const { id, view } = spaceParams;
    const path = communityLayoutById(id, view);

    /**
     * If there is no available endpoint for the view, reject.
     * */
    if (!path) {
        return Promise.reject();
    }

    const { data } = await layoutCommunityApi.getCacheFirst<{ layout: Layout; metadata: CommunityMetadata }>(
        path,
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            headers: {
                'Accept-Language': languages,
                'LumApps-Use-Space-Template': true /* needed to get blockResolutionInfo from the backend */,
            },
        },
        true /* applyBaseConfig */,
        true /* cancelable */,
    );

    const { layout } = data;

    return layout;
}
