(function IIFE() {
    'use strict';

    /////////////////////////////

    /* @ngInject */
    function WidgetLinksController($scope, Translation) {
        var vm = this;

        /////////////////////////////
        //                         //
        //     Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The current input language.
         * Used by react components to display widget content in the right language depending on the lang defined in
         * designer.
         * @type {string}
         */
        vm.inputLang = Translation.getInputLanguage();

        /////////////////////////////

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Reinitialize the list of links when current language has changed.
         * Update the inputLang to trigger a new render of the Widget
         */
        $scope.$on('inputLanguage', function onLanguageChange(evt, inputLang) {
            var currentProperties = _.get(vm.widget, 'properties');
            currentProperties.inputLang = inputLang.lang;
            vm.inputLang = inputLang;
        });

        vm.init = () => {
            if (angular.isUndefined(vm.widget.properties.hasThumbnail)) {
                vm.widget.properties.hasThumbnail = true;
            }
        }

        /////////////////////////////

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
            vm.init();
        };
    }

    /////////////////////////////

    /**
     * The links widget.
     * Display a list of external/internal links in the current language.
     * Each link can have a thumbnail, a title, a description and a target.
     *
     * @param {Object} widget The widget configuration object.
     */

    function widgetLinksDirective() {
        'ngInject';

        function widgetLinksLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetLinksController,
            controllerAs: 'vm',
            link: widgetLinksLink,
            replace: true,
            require: ['widgetLinks', '^widget'],
            restrict: 'E',
            scope: {
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-links/views/widget-links.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetLinks', widgetLinksDirective);
})();
