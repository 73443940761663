import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { isFeatureEnabled } from '@lumapps/features';
import { ProgressCircular, TextField, Theme, Typography } from '@lumapps/lumx/react';
import { ClickAwayProvider } from '@lumapps/lumx/react/utils';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, TranslateObject, useTranslate } from '@lumapps/translations';
import { inputLocaleSelector } from '@lumapps/translations/selectors';
import { GENERATIVE_AI_FEATURE_TOKEN } from '@lumapps/wrex-generative-content/constants';
import { generateSummary } from '@lumapps/wrex-generative-content/utils/generateSummary';
import { fromMarkdownToText } from '@lumapps/wrex/utils/fromMarkdownToText';

import { CLASSNAME } from '../../constants';
import { WIDGET_SUMMARY } from '../../keys';
import { ButtonGenerate } from '../ButtonGenerate';

import './index.scss';

export interface EditableWidgetSummaryProps {
    wrapperRef: React.MutableRefObject<null>;
    childrenRefs: React.MutableRefObject<React.MutableRefObject<null>[]>;
    exitEditionMode: (content: string) => void;
    content: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
    theme?: Theme;
    contentTitle?: TranslateObject<string>;
}

export const EditableWidgetSummary: React.FC<EditableWidgetSummaryProps> = ({
    wrapperRef,
    childrenRefs,
    exitEditionMode,
    content,
    onChange,
    theme = Theme.light,
    contentTitle,
}: EditableWidgetSummaryProps) => {
    const { translateKey } = useTranslate();
    const { block } = useClassnames(CLASSNAME);
    const isAIFeatureEnabled = useSelector(isFeatureEnabled(GENERATIVE_AI_FEATURE_TOKEN));
    const currentInputLang = useSelector(inputLocaleSelector);
    const currentContentTitle = contentTitle?.[currentInputLang] || contentTitle?.en || '';

    const { error } = useNotification();

    const [isGeneratingSummary, setIsGeneratingSummary] = React.useState(false);
    const [hasTextBeenGenerated, setHasTextBeenGenerated] = React.useState(false);

    const onGenerate = async (textContent: string) => {
        setIsGeneratingSummary(true);

        try {
            const { data, status } = await generateSummary(textContent, currentContentTitle, currentInputLang);

            setIsGeneratingSummary(false);
            if (status !== 200) {
                error({ translate: GLOBAL.GENERIC_ERROR });
            } else {
                setHasTextBeenGenerated(true);
                const aiSummary = fromMarkdownToText(data.completions);
                onChange(aiSummary);
            }
        } catch (e) {
            error({ translate: GLOBAL.GENERIC_ERROR });
            setIsGeneratingSummary(false);
        }
    };

    return (
        <ClickAwayProvider callback={() => exitEditionMode(content)} childrenRefs={childrenRefs}>
            <div className={block('editable-wrapper')}>
                {isGeneratingSummary && <ProgressCircular className={block('editable-progress')} theme={theme} />}
                <TextField
                    typography={Typography.custom.intro}
                    className={block('editable')}
                    value={content}
                    placeholder={translateKey(WIDGET_SUMMARY.INPUT_PLACEHOLDER)}
                    onChange={onChange}
                    isDisabled={isGeneratingSummary}
                    theme={theme}
                    multiline
                    ref={wrapperRef}
                    afterElement={
                        isAIFeatureEnabled && (
                            <ButtonGenerate
                                onGenerate={onGenerate}
                                isGeneratingSummary={isGeneratingSummary}
                                hasTextBeenGenerated={hasTextBeenGenerated}
                                theme={theme}
                            />
                        )
                    }
                    autoFocus
                />
            </div>
        </ClickAwayProvider>
    );
};
