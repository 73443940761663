import React from 'react';

import { Size, ButtonProps } from '@lumapps/lumx/react';
import { FollowButton } from '@lumapps/subscriptions/components/FollowButton';
import { UseSubscriptionStatuses } from '@lumapps/subscriptions/types';

import { FollowUserButtonSkeleton } from './FollowUserButtonSkeleton';

export interface FollowUserButtonProps extends ButtonProps {
    /**
     * The status to display the button on.
     * Default at idle.
     * */
    status?: UseSubscriptionStatuses;
    /**
     * Whether the user should be displayed as being subscribed to.
     */
    isSubscribed?: boolean;
}

/**
 * The button to follow the user.
 */
export const FollowUserButton: React.FC<FollowUserButtonProps> = ({
    status = UseSubscriptionStatuses.idle,
    isSubscribed,
    className,
    size,
    ...buttonProps
}) => {
    switch (status) {
        case UseSubscriptionStatuses.loading:
            /* Show the button when waiting for action or during state change. */
            return <FollowUserButtonSkeleton className={className} size={size} />;
        case UseSubscriptionStatuses.idle:
        case UseSubscriptionStatuses.changing: {
            return (
                <FollowButton
                    className={className}
                    isSelected={isSubscribed}
                    disabled={status === UseSubscriptionStatuses.changing}
                    size={size || Size.s}
                    {...buttonProps}
                />
            );
        }

        /** Unmanaged states, show nothing */
        case UseSubscriptionStatuses.error:
        case UseSubscriptionStatuses.initial:
        default:
            return null;
    }
};
