import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { Tooltip, Placement, Offset } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useTopBarUserMenuLinks } from '../../hooks/useTopBarUserMenuLinks';
import { USER } from '../../keys';
import { loginRoute } from '../../routes';
import { UserAvatar, UserAvatarProps } from '../UserAvatar/UserAvatar';
import { UserMenu } from '../UserMenu';

import './index.scss';

export type UserProfileDropdownProps = {
    /** The avatar of the user */
    avatarImageSrc?: string;
    /** The dropdown placement */
    placement?: Placement;
    /** The dropdown offset */
    offset?: Offset;
    /** whether this component is visible or not */
    isVisible?: boolean;
    /** whether the user is connected or not */
    isConnected: boolean;
};

const CLASSNAME = 'user-profile-dropdown';
const SCOPE = 'user-profile';

const UserAvatarButton = React.forwardRef<HTMLButtonElement, Partial<UserAvatarProps> & { tooltipLabel: string }>(
    ({ avatarImageSrc, tooltipLabel, className, ...forwardedProps }, ref) => {
        const { block } = useClassnames(CLASSNAME);
        const classnames = block([className]);

        return (
            <Tooltip placement={Placement.BOTTOM} label={tooltipLabel}>
                <UserAvatar
                    ref={ref}
                    avatarImageSrc={avatarImageSrc}
                    className={classnames}
                    scope={SCOPE}
                    {...forwardedProps}
                />
            </Tooltip>
        );
    },
);

export const UserProfileDropdown: React.FC<UserProfileDropdownProps> = ({
    avatarImageSrc,
    placement,
    offset = {},
    isVisible = true,
    isConnected = false,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    /** Should fetch user permissions only if dropdown is open */
    const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
    const { topBarUserMenuLinks } = useTopBarUserMenuLinks(isUserMenuOpen);

    if (!isVisible) {
        return null;
    }

    if (!isConnected) {
        return (
            <UserAvatarButton
                tooltipLabel={translateKey(USER.LOGIN_CLASSIC_SIGN_IN)}
                avatarLinkUrl={loginRoute.path}
                avatarImageSrc={avatarImageSrc}
                avatarAlt={translateKey(USER.LOGIN_CLASSIC_SIGN_IN)}
            />
        );
    }

    // Hide the tooltip if the menu is open
    const tooltipLabel = isUserMenuOpen ? null : translateKey(GLOBAL.VIEW_USER_PROFILE);

    return (
        <Menu.Trigger
            onToggle={setIsUserMenuOpen}
            popoverProps={{ placement, offset }}
            popoverWidth={{ minWidth: REAL_SIZE_FOR_LUMX_SIZE.xxl }}
        >
            <Menu.TriggerButton
                as={UserAvatarButton}
                tooltipLabel={tooltipLabel}
                avatarImageSrc={avatarImageSrc}
                avatarAlt={translateKey(GLOBAL.VIEW_USER_PROFILE)}
            />

            <Menu className={element('menu-content')}>
                <UserMenu links={topBarUserMenuLinks} scope={SCOPE} />
            </Menu>
        </Menu.Trigger>
    );
};
