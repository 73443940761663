import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';

/**
 * Filter object entries on the values. (similar to lodash/mapValues)
 */
export const filterValues = <A>(predicate: (value: A, key: string) => boolean, object: Record<string, A>) =>
    flow(
        toPairs,
        filter(([key, value]) => predicate(value, key)),
        fromPairs,
    )(object);
