export const WIDGET_CONTRIBUTION_TYPE = 'contribution';

export const BLOCK_STRUCTURED_CONTENT_CLASSNAME = 'block-structured-content';

// From https://github.com/lumapps/core/blob/master/core/common/use_cases/i18n.py
export const LANGUAGE_CODES_MAPPING: Record<string, string> = {
    br: 'pt-BR',
    fr_ca: 'fr-CA',
    pt_br: 'pt-BR',
    zh_hk: 'zh-HK',
    zh_tw: 'zh-TW',
    jp: 'ja',
    iw: 'he',
};
