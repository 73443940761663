import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Theme } from '@lumapps/lumx/react';
import { PersonalFeedListEmptyState } from '@lumapps/user-feed/components/PersonalFeedList/PersonalFeedListEmptyState';
import { ContainerBlockVariant } from '@lumapps/widget-base/types';

import type { BlockPersonalFeedPreview as BlockPersonalFeedPreviewProps } from '../../types';
import { BlockPersonalFeedPreviewFooter } from '../BlockPersonalFeedPreviewFooter';
import { BlockPersonalFeedPreviewHeader } from '../BlockPersonalFeedPreviewHeader';

import './index.scss';

const CLASSNAME = 'block-personal-feed-preview';

/**
 * @param children React.ReactNode
 * @param feedFooter FeedFooter
 * @param feedHeader FeedHeader | undefined
 * @param theme Theme
 * @param variant ContainerBlockVariant
 * @returns BlockPersonalFeedPreview
 */
export const BlockPersonalFeedPreview: React.FC<BlockPersonalFeedPreviewProps> = ({
    children,
    feedFooter,
    feedHeader,
    theme = Theme.light,
    contentStyles,
    variant = ContainerBlockVariant.grouped,
}) => {
    const { block } = useClassnames(CLASSNAME);

    /**
     * Check if the children container is BlockNoResults. If so, replace it with the FeedList Empty state component.
     * If not, use the children props, and pass down the contentStyles to it.
     */
    const childToRender =
        children.props.type === 'BlockNoResults' ? (
            <PersonalFeedListEmptyState />
        ) : (
            React.isValidElement(children) && React.cloneElement(children, { contentStyles })
        );

    return (
        <FlexBox
            className={block(variant.toLowerCase())}
            gap={variant === ContainerBlockVariant.ungrouped ? 'huge' : undefined}
            orientation="vertical"
        >
            {feedHeader && (
                <BlockPersonalFeedPreviewHeader
                    theme={theme}
                    user={feedHeader.user}
                    isFeedButtonDisplayed={feedHeader.isFeedButtonDisplayed}
                    variant={variant}
                    contentStyles={contentStyles}
                />
            )}

            {childToRender}

            {feedFooter && (
                <BlockPersonalFeedPreviewFooter theme={theme} variant={variant} contentStyles={contentStyles} />
            )}
        </FlexBox>
    );
};
