(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetStockExchangeController($scope, Content, StockExchangeFactory, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Currency Symbol - Code matching table.
         *
         * @type {Object}
         */
        var _currencyTable = {
            AED: 'د.إ.‏',
            AFN: '؋',
            ALL: 'Lek',
            AMD: 'դր.',
            ARS: '$',
            AUD: '$',
            AZN: 'ман.',
            BAM: 'KM',
            BDT: '৳',
            BGN: 'лв.',
            BHD: 'د.ب.‏',
            BIF: 'FBu',
            BND: '$',
            BOB: 'Bs',
            BRL: 'R$',
            BWP: 'P',
            BYR: 'BYR',
            BZD: '$',
            CAD: '$',
            CDF: 'FrCD',
            CHF: 'CHF',
            CLP: '$',
            CNY: 'CN¥',
            COP: '$',
            CRC: '₡',
            CVE: 'CV$',
            CZK: 'Kč',
            DJF: 'Fdj',
            DKK: 'kr',
            DOP: 'RD$',
            DZD: 'د.ج.‏',
            EEK: 'kr',
            EGP: 'ج.م.‏',
            ERN: 'Nfk',
            ETB: 'Br',
            EUR: '€',
            GBP: '£',
            GEL: 'GEL',
            GHS: 'GH₵',
            GNF: 'FG',
            GTQ: 'Q',
            HKD: '$',
            HNL: 'L',
            HRK: 'kn',
            HUF: 'Ft',
            IDR: 'Rp',
            ILS: '₪',
            INR: 'টকা',
            IQD: 'د.ع.‏',
            IRR: '﷼',
            ISK: 'kr',
            JMD: '$',
            JOD: 'د.أ.‏',
            JPY: '￥',
            KES: 'Ksh',
            KHR: '៛',
            KMF: 'FC',
            KRW: '₩',
            KWD: 'د.ك.‏',
            KZT: 'тңг.',
            LBP: 'ل.ل.‏',
            LKR: 'SL Re',
            LTL: 'Lt',
            LVL: 'Ls',
            LYD: 'د.ل.‏',
            MAD: 'د.م.‏',
            MDL: 'MDL',
            MGA: 'MGA',
            MKD: 'MKD',
            MMK: 'K',
            MOP: 'MOP$',
            MUR: 'MURs',
            MXN: '$',
            MYR: 'RM',
            MZN: 'MTn',
            NAD: 'N$',
            NGN: '₦',
            NIO: 'C$',
            NOK: 'kr',
            NPR: 'नेरू',
            NZD: '$',
            OMR: 'ر.ع.‏',
            PAB: 'B/.',
            PEN: 'S/.',
            PHP: '₱',
            PKR: '₨',
            PLN: 'zł',
            PYG: '₲',
            QAR: 'ر.ق.‏',
            RON: 'RON',
            RSD: 'дин.',
            RUB: 'руб.',
            RWF: 'FR',
            SAR: 'ر.س.‏',
            SDG: 'SDG',
            SEK: 'kr',
            SGD: '$',
            SOS: 'Ssh',
            SYP: 'ل.س.‏',
            THB: '฿',
            TND: 'د.ت.‏',
            TOP: 'T$',
            TRY: 'TL',
            TTD: '$',
            TWD: 'NT$',
            TZS: 'TSh',
            UAH: '₴',
            UGX: 'USh',
            USD: '$',
            UYU: '$',
            UZS: 'UZS',
            VEF: 'Bs.F.',
            VND: '₫',
            XAF: 'FCFA',
            XOF: 'CFA',
            YER: 'ر.ي.‏',
            ZAR: 'R',
            ZMK: 'ZK',
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check fluctuation to return fluctuation class.
         *
         * @return {string} The fluctuation element class.
         */
        function _getFluctuationClass() {
            var valueChange = _.get(vm.stockExchangeValue, 'values.change');

            if (angular.isUndefinedOrEmpty(valueChange)) {
                return false;
            }

            var fluctuation = 'equal';

            if (_.includes(valueChange, '+')) {
                fluctuation = 'positive';
            } else if (_.includes(valueChange, '-')) {
                fluctuation = 'negative';
            }

            return 'widget-stock-exchange__fluctuation--' + fluctuation;
        }

        /**
         * Refresh currency.
         */
        function _refreshCurrency() {
            var currencyCode = _.get(vm.stockExchangeValue, 'values.currency');

            if (angular.isDefinedAndFilled(currencyCode)) {
                vm.currency = (vm.widget.properties.displayCurrencyCode) ? currencyCode : _currencyTable[currencyCode];
            }

            var currencyPosition = (vm.widget.properties.currencyReverse) ? 'left' : 'right';
            vm.curencyPosition = 'widget-stock-exchange__value--unit-' + currencyPosition;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get value label.
         *
         * @return {string} Stock option value label.
         */
        function getValueLabel() {
            return vm.parentCtrl.getWidgetTranslation(vm.widget.properties.label) ||
                vm.stockExchangeValue.name.toLowerCase();
        }

        /////////////////////////////

        /**
         * Get widget class.
         *
         * @return {Array} Widget classes.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            return (angular.isUndefined(vm.stockExchangeValue) || !vm.stockExchangeValue) &&
                Content.getViewMode() !== 'basic';
        }

        /**
         * Is widget hidden in reading mode.
         *
         * @return {boolean} If the widget is hidden or not.
         */
        function isWidgetHidden() {
            if (!vm.parentCtrl.designerMode() &&
                (angular.isUndefinedOrEmpty(_.get(vm.stockExchangeValue, 'values')) ||
                vm.stockExchangeValue.values.current === 'N/A')) {
                vm.parentCtrl.isHidden = true;

                return true;
            }

            vm.parentCtrl.isHidden = false;

            return false;
        }

        /////////////////////////////

        vm.getValueLabel = getValueLabel;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Watch currency update.
         */
        $scope.$on('widget-stock-exchange-settings-currency', function onWidgetSettingsChange(evt, widgetUuid) {
            if (vm.widget.uuid === widgetUuid) {
                _refreshCurrency();
            }
        });

        /**
         * Watch widget setting update.
         */
        $scope.$on('widget-stock-exchange-settings', function onWidgetSettingsChange(evt, widgetUuid) {
            if (vm.widget.uuid === widgetUuid) {
                vm.init();
            }
        });

        /////////////////////////////

        /**
         * Initialize controller.
         */
        vm.init = function init() {
            var properties = vm.widget.properties;

            // Check decimal separator.
            if (!angular.isObject(properties.decimalSeparator)) {
                properties.decimalSeparator = {};
                properties.decimalSeparator[Translation.getLang('current')] = '.';
            }

            // Stock exchange query.
            if (angular.isUndefinedOrEmpty(properties.symbol)) {
                return;
            }

            StockExchangeFactory.get({
                symbol: properties.symbol,
            }, function onStockExchangeGetSuccess(response) {
                vm.stockExchangeValue = angular.copy(response);

                // Define currency.
                _refreshCurrency();

                // Get fluctuation class.
                vm.fluctuationClass = _getFluctuationClass();

                // Convert "." to another char.
                var decimalSeparator = properties.decimalSeparator;
                var currentLanguage = Translation.getLang('current');

                if (!Translation.hasTranslation(decimalSeparator, currentLanguage) ||
                    vm.parentCtrl.getWidgetTranslation(decimalSeparator) === '.') {
                    return;
                }

                angular.forEach(vm.stockExchangeValue.values, function replaceDecimalSeparator(value, key) {
                    if (key !== 'date') {
                        vm.stockExchangeValue.values[key] = value.replace(/\./g, decimalSeparator[currentLanguage]);
                    }
                });
            }, Utils.displayServerError);
        };

        /**
         * Set parent controller
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    function WidgetStockExchangeDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetStockExchangeController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetStockExchange', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-stock-exchange/views/widget-stock-exchange.html',
        };
    }

    angular.module('Widgets').directive('widgetStockExchange', WidgetStockExchangeDirective);
})();
