import { useCallback } from 'react';

import omitBy from 'lodash/fp/omitBy';

import { useDispatch, useSelector } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { FetchBlockParams } from '../../../api';
import { getWidget } from '../../../ducks/selectors';
import { actions } from '../../../ducks/slice';
import { fetchWidgetBlocksById } from '../../../ducks/thunks/fetchWidgetBlocksById';
import { fetchWidgetBlocksByProperties } from '../../../ducks/thunks/fetchWidgetBlocksByProperties';
import { LegacyWidget } from '../../../types';

export const useBlockFilters = ({
    widgetType,
    widgetId,
    blockResolutionInfo,
    isMainWidget,
    legacyWidget,
}: {
    widgetType?: string;
    widgetId: string;
    legacyWidget?: LegacyWidget;
    isMainWidget?: boolean;
    /** Additional information to resolve block */
    blockResolutionInfo?: Partial<FetchBlockParams> | undefined;
}) => {
    const dispatch = useDispatch();

    const { filters: activeFilters } = useSelector((state: FrontOfficeStore) => getWidget(state, { widgetId })) || {};

    const onFilterChange = useCallback(
        (newFilters: Record<string, any>) => {
            return dispatch(
                actions.setWidgetFilters({
                    widgetId,
                    filters: {
                        ...activeFilters,
                        ...newFilters,
                    },
                }),
            );
        },
        [activeFilters, dispatch, widgetId],
    );

    const onFilterReplace = useCallback(
        (newFilters: Record<string, any>) => {
            return dispatch(
                actions.setWidgetFilters({
                    widgetId,
                    filters: newFilters,
                }),
            );
        },
        [dispatch, widgetId],
    );

    const onApply = useCallback(() => {
        if (legacyWidget) {
            dispatch(
                fetchWidgetBlocksByProperties({
                    legacyWidget,
                    params: blockResolutionInfo,
                    isMainWidget: Boolean(isMainWidget),
                }),
            );
        } else if (widgetType) {
            dispatch(
                fetchWidgetBlocksById({
                    widgetId,
                    widgetType,
                    params: blockResolutionInfo,
                    isMainWidget: Boolean(isMainWidget),
                }),
            );
        }
    }, [legacyWidget, widgetType, dispatch, widgetId, blockResolutionInfo, isMainWidget]);

    const onReset = useCallback(
        (filtersToResetKeys?: string[]) => {
            if (legacyWidget || widgetType) {
                // we reset every filter which key is on the list, if no list or an empty list is provided we reset all filters
                const nextActiveFilters = filtersToResetKeys?.length
                    ? omitBy((value, key) => filtersToResetKeys.includes(key), activeFilters)
                    : {};

                dispatch(actions.setWidgetFilters({ widgetId, filters: nextActiveFilters }));
            }
            if (legacyWidget) {
                dispatch(
                    actions.setWidgetProperties({
                        widgetId,
                        widgetProperties: {
                            state: 'loadingcontent',
                        },
                    }),
                );

                dispatch(
                    fetchWidgetBlocksByProperties({
                        legacyWidget,
                        params: blockResolutionInfo,
                        isMainWidget: Boolean(isMainWidget),
                    }),
                );
            } else if (widgetType) {
                dispatch(
                    fetchWidgetBlocksById({
                        widgetId,
                        widgetType,
                        params: blockResolutionInfo,
                        isMainWidget: Boolean(isMainWidget),
                    }),
                );
            }
        },
        [legacyWidget, widgetType, activeFilters, dispatch, widgetId, blockResolutionInfo, isMainWidget],
    );

    if (!widgetType) {
        return {};
    }

    return {
        onApply,
        onReset,
        onFilterChange,
        onFilterReplace,
        activeFilters,
    };
};
