import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { Post } from '@lumapps/posts/types';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { User } from '@lumapps/user/types';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';
import {
    BlockTagReferenceList,
    LinkResourceType,
    MediaPreview,
    TagReference,
    UserReference,
} from '@lumapps/widget-base/types';

import { BlockPostProps } from '../components/BlockPost/BlockPost';
import { PostShare } from '../types';

// The omitted props are the ones that are managed by the connected BlockPost.
type UseBlockPostResults = Omit<
    BlockPostProps,
    'isTagzEnabled' | 'userLanguage' | 'isInCommunityContext' | 'onPostTranslate' | 'onPostVote'
>;

/**
 * Hook to adapt the Post object from backend payload into props for the BlockPost.
 * Use this hook in conjunction with the connected/lazy BlockPost, for example:
 *
 * ```
 * const blockPostProps = useBlockPost(post, { canShare: false });
 *
 * return <BlockPost {...blockPostProps} />;
 * ```
 *
 * @param post The post to user
 * @param propsOverrides The props overrides if any
 */
export const useBlockPost = (post: Post, propsOverrides: Partial<UseBlockPostResults> = {}): UseBlockPostResults => {
    const { translateObject } = useTranslate();
    const currentLanguage = useSelector(currentLanguageSelector);

    // Shortcuts for const that will be reused multiple times
    const contentAuthor = post.authorDetails;
    const community = post.parentContentDetails;

    const communityId = community?.id || '';
    const communityTitle = translateObject(community?.title) || '';
    const communitySlug = translateObject(community?.slug) || '';
    const renderingType = community?.renderingType;

    const postId = post.id ?? post.uid ?? '';

    const content = translateObject(post.content) || '';
    const contentTitle = translateObject(post.title) || '';

    const site = { siteId: post.instance || '' };

    // Folksonomy tagz array
    const tagz: TagReference[] =
        post.tagz?.map((tag) => ({
            tagId: tag.id,
            name: tag.label,
            type: 'FOLKSONOMY',
        })) ?? [];

    // Categories array for community posts where categories exist
    const categories: TagReference[] =
        post.tagsDetails?.reduce((acc, tag) => {
            const tagId = tag.id;
            const name = translateObject(tag.name);

            return tagId && name ? [...acc, { tagId, name, type: 'LEGACY' }] : acc;
        }, [] as TagReference[]) ?? [];

    // Tagz and Categories are concat into a tags array, only type differentiates them
    const tags: BlockTagReferenceList | undefined =
        tagz.length || categories.length
            ? {
                  items: [...tagz, ...categories],
              }
            : undefined;

    // The images, if defined
    const images: MediaPreview[] | undefined = post.images?.map((image) => {
        const imageInfo = image.content?.[0];
        return {
            mediaId: (image.source === 'haussmann_media' || image.isSecure) && image.uuid ? image.uuid : image.id,
            title: translateObject(image.name) || '',
            description: translateObject(image.description) || '',
            fileName: imageInfo?.name || '',
            url: imageInfo?.url || '',
            mimeType: imageInfo?.mimeType,
            height: imageInfo?.height || image.height,
            thumbnail: image.thumbnail,
            source: image.source,
            uuid: image.uuid,
        };
    });

    // The files, if defined, for Attached files
    const files: MediaPreview[] | undefined = post.files?.map((file) => {
        const fileContent = file.content?.[0];
        const fileName = translateObject(file.name) || fileContent?.name || '';
        return {
            mediaId: fileContent?.fileId,
            title: fileName,
            description: translateObject(file.description) || '',
            fileName,
            url: fileContent?.url,
            mimeType: fileContent?.mimeType,
            thumbnail: file.thumbnail,
            source: file.source,
            uuid: file.uuid,
            createdAt: file.createdAt,
        };
    });

    const communityPinsList = post.pinnedInCommunitiesDetails?.map((pinDetails) => pinDetails.pinnedIn.id) ?? [];

    const shares = (
        post.visibleInCommunitiesDetails && post.visibleInCommunitiesDetails.length
            ? post.visibleInCommunitiesDetails.map((share) => ({
                  sharedBy: {
                      userId: share.postedBy.id,
                      fullName: getUserFullName(share.postedBy, currentLanguage),
                      pictureUrl: getUserProfilePictureUrl({ id: share.postedBy.id, customer: post.customer }),
                  },
                  sharedAt: share.postedAt,
                  sharedTo: {
                      communityId: share.postedTo.id,
                      name: translateObject(share.postedTo.title),
                      slug: translateObject(share.postedTo.slug),
                      renderingType: share.postedTo.renderingType,
                  },
                  site: { siteId: share.postedTo.instance },
                  canPin: !!share?.canPin,
                  canUnshare: !!share?.canUnshare,
                  isPinned: communityPinsList.includes(share.postedTo.id),
              }))
            : [
                  {
                      // This is used to have the post creation date and community under the author name by default
                      sharedBy: { userId: contentAuthor?.id },
                      sharedAt: post.publicationDate || '',
                      sharedTo: {
                          communityId,
                          name: communityTitle,
                          slug: communitySlug,
                          renderingType,
                      },
                      site,
                      canPin: false,
                      canUnshare: false,
                      isPinned: communityPinsList.includes(communityId),
                  },
              ]
    ) as PostShare[];

    // The article, event or play video previews. Only one can be set at a time.
    const link = post.links?.[0];
    const previewProps = {
        id: link?.resourceId || '',
        title: link?.title || '',
        image: link?.images?.[0] || '',
        description: link?.description || '',
        shares: [
            {
                container: {
                    containerId: communityId,
                },
            },
        ],
        isFromLegacy: true,
    };
    const articlePreview = link?.resourceType === LinkResourceType.ARTICLE ? previewProps : undefined;
    const eventPreview = link?.resourceType === LinkResourceType.EVENT ? previewProps : undefined;
    const playVideoPreview = link?.resourceType === LinkResourceType.PLAY_VIDEO ? previewProps : undefined;

    // Post idea status.
    const ideaStatus = post.postStatusDetails && {
        id: (post.postStatus || post.postStatusDetails.uuid) as string,
        label: translateObject(post.postStatusDetails.title) as string,
    };

    return {
        // Basic props
        type: 'BlockPost',
        site,
        order: ['PUBLICATION_INFO', 'CONTENT', 'EVENT_DATES', 'TITLE', 'ATTACHMENTS', 'SHARES', 'REACTIONS'],
        title: contentTitle,
        community: {
            communityId,
            slug: communitySlug,
            name: communityTitle,
            hasSecureStorage: false,
            renderingType,
        },
        postId,
        content,
        visibleLines: 4,

        // Props directly from the Post object payload
        postType: post.postType,
        canEdit: post.canEdit,
        canDelete: post.canDelete,
        canMove: post.canMove,
        canShare: post.canShare,
        eventStartsAt: post.eventStartDate,
        eventEndsAt: post.eventEndDate || '',
        isAnswered: post.hasRelevantComment || !!post.relevantComment,
        userMentions: (post.mentionsDetails as UserReference[]) || [],
        relevantCommentId: post.relevantComment,
        links: post.links,

        // Props built with the Post info
        images,
        tags,
        files,
        articlePreview,
        eventPreview,
        playVideoPreview,
        author: {
            email: contentAuthor?.email || contentAuthor?.apiProfile?.primaryEmail,
            userId: contentAuthor?.id || '',
            fullName: getUserFullName(contentAuthor, currentLanguage),
            pictureUrl:
                contentAuthor?.profilePictureUrl ||
                getUserProfilePictureUrl({
                    id: contentAuthor?.id,
                    customer: post.customer,
                } as User),
        },
        shares,
        reactions: {
            type: 'BlockReactions',
            resourceId: postId,
            resourceType: 'POST',
            likes: post.likes,
            isLiked: post.liked,
            comments: post.comments,
        },
        votes: post.score && {
            type: 'BlockVotes',
            resourceId: postId,
            resourceType: 'POST',
            userVote: post.userVote as string,
            upVotes: post.score.votesUp,
            downVotes: post.score.votesDown,
        },

        // Todo: other props to manage, that currently are not needed
        reports: undefined,
        widget: undefined,

        ideaStatus,

        ...propsOverrides,
    };
};
