/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import { combineReducers } from '@lumapps/redux';

import {
    reducer as shareContentDialogReducer,
    initialState as shareContentDialogInitialState,
    actions as shareContentDialogActions,
} from './ShareContentDialog/slice';
import {
    reducer as shareToExtensionReducer,
    initialState as shareToExtensionInitialState,
    actions as shareToExtensionActions,
} from './ShareToExtension/slice';
import {
    reducer as shareToSlackReducer,
    initialState as shareToSlackInitialState,
    actions as shareToSlackActions,
} from './ShareToSlack/slice';
import {
    reducer as shareToSocialNetworkDialogReducer,
    initialState as shareToSocialNetworkDialogInitialState,
    actions as shareToSocialNetworkDialogActions,
} from './ShareToSocialNetworkDialog/slice';
import {
    reducer as shareToTeamsReducer,
    initialState as shareToTeamsInitialState,
    actions as shareToTeamsActions,
} from './ShareToTeams/slice';

export const reducer = combineReducers({
    shareToSocialNetworkDialog: shareToSocialNetworkDialogReducer,
    shareToTeams: shareToTeamsReducer,
    shareToSlack: shareToSlackReducer,
    shareContentDialog: shareContentDialogReducer,
    shareToExtension: shareToExtensionReducer,
});

export const initialState = {
    shareToSocialNetworkDialog: shareToSocialNetworkDialogInitialState,
    shareToTeams: shareToTeamsInitialState,
    shareToSlack: shareToSlackInitialState,
    shareContentDialog: shareContentDialogInitialState,
    shareToExtension: shareToExtensionInitialState,
};

export {
    shareToSocialNetworkDialogActions,
    shareToTeamsActions,
    shareToSlackActions,
    shareContentDialogActions,
    shareToExtensionActions,
};
