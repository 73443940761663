import React, { useRef } from 'react';

import { Button, ColorPalette, Emphasis, Orientation, Placement, Popover, Size, UserBlock } from '@lumapps/lumx/react';
import { get } from '@lumapps/constants';

import { any, arrayOf, func, string } from 'prop-types';

import { getIcon } from 'components/services/media';

import { getBackgroundImage } from 'components/utils';

const DEFAULT_AVATAR = get().defaultUserAvatar;

/*
 * The code below will be needed when tu back-end will
 * return a lumapps user id for microsoft user.
 */
/* Import { redirectToUserDirectoryProfile } from 'components/services/user';

import { translate as t } from 'components/translations'; */

/**
 * Display the widget WidgetDelveContent component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The WidgetDelveContent react element.
 */
const WidgetDelveContent = ({ contentType, currentlyOpenedPopover, items, setCurrentlyOpenedPopover, theme, uuid }) => {
    /**
     * Function that retrieive the appropriate thumbnail based on the file type.
     *
     * @param  {Task}    item The file item.
     * @return {Element} The JSX of the thumbnail.
     */
    const fileThumbnail = (item) => {
        if (
            item.visualization.resourceType === 'Word' ||
            (item.visualization.resourceType === 'PowerPoint' && item.visualization.containerType === 'Site')
        ) {
            const divStyle = {
                backgroundImage: getBackgroundImage(item.visualization.previewImageUrl, -1)['background-image'],
            };

            return <div className="media-block__icon" style={divStyle} />;
        }

        return <div className={`media-block__icon media-block__icon--${getIcon(item.visualization.mediaType)}`} />;
    };

    return (
        <div className="widget-delve_content">
            {items.map((item) => {
                if (contentType === 'people') {
                    return (
                        <div key={item.id}>
                            <UserItem
                                currentlyOpenedPopover={currentlyOpenedPopover}
                                item={item}
                                setCurrentlyOpenedPopover={setCurrentlyOpenedPopover}
                                theme={theme}
                                uuid={uuid}
                            />
                        </div>
                    );
                }
                if (contentType === 'document') {
                    return (
                        <div key={item.id}>
                            <Button
                                className="widget-delve_document__item"
                                color={ColorPalette.dark}
                                emphasis={Emphasis.low}
                                theme={theme}
                                onClick={() => window.open(item.reference.webUrl, '_blank')}
                            >
                                <div className="media-block">
                                    {fileThumbnail(item)}
                                    <div className="media-block__label">
                                        <div className="media-block__label--name">{item.visualization.title}</div>
                                        <div className="media-block__label--type">
                                            {`${item.visualization.resourceType} file`}
                                        </div>
                                    </div>
                                </div>
                            </Button>
                        </div>
                    );
                }

                return null;
            })}
        </div>
    );
};

/**
 * Display the widget UserItem component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The UserItem react element.
 */
const UserItem = ({ currentlyOpenedPopover, item, setCurrentlyOpenedPopover, theme, uuid }) => {
    const anchorRef = useRef(null);

    /**
     * Function that build fields array of the user.
     *
     * @param  {Task}  item The file item.
     * @return {Array} The fields array.
     */
    const userFields = () => {
        const fieldsArray = [];

        if (item.jobTitle) {
            fieldsArray.push(item.jobTitle);
        }
        if (item.officeLocation) {
            fieldsArray.push(item.officeLocation);
        }

        return fieldsArray;
    };

    return (
        <>
            <UserBlock
                avatarProps={
                    item.personType === 'Group'
                        ? undefined
                        : {
                              image: item.pictureUrl ? item.pictureUrl : DEFAULT_AVATAR,
                              alt: item.fullName,
                          }
                }
                fields={userFields(item)}
                name={item.fullName}
                orientation={Orientation.horizontal}
                size={Size.m}
                theme={theme}
                ref={anchorRef}
                onMouseEnter={() => setCurrentlyOpenedPopover({ close: false, data: item.id + uuid, uuid })}
                onMouseLeave={() => setCurrentlyOpenedPopover({ close: true, data: item.id + uuid, uuid })}
            />
            {item.personType === 'Person' && (
                <Popover
                    anchorRef={anchorRef}
                    placement={Placement.TOP_START}
                    isOpen={currentlyOpenedPopover === item.id + uuid}
                    offset={{ away: 20 }}
                >
                    <div className="popover-content-container">
                        <UserBlock
                            avatarProps={
                                item.personType === 'Group'
                                    ? undefined
                                    : {
                                          image: item.pictureUrl ? item.pictureUrl : DEFAULT_AVATAR,
                                          alt: item.fullName,
                                      }
                            }
                            fields={userFields(item)}
                            name={item.fullName}
                            orientation={Orientation.vertical}
                            /*
                             * The code below will be needed when tu back-end will
                             * return a lumapps user id for microsoft user.
                             */
                            /* SimpleAction={
                                <div className="user-block__more-info">
                                    <Button
                                        color={ColorPalette.blue}
                                        emphasis={Emphasis.low}
                                        size={Size.s}
                                        theme={theme}
                                        onClick={() => redirectToUserDirectoryProfile(item)}
                                    >
                                        {t('FRONT.USER.PROFILE.MORE_INFO.LINK')}
                                    </Button>
                                </div>
                            }. */
                            theme={theme}
                        />
                    </div>
                </Popover>
            )}
        </>
    );
};

WidgetDelveContent.defaultProps = {
    theme: undefined,
};

WidgetDelveContent.propTypes = {
    /* The string that represent the content type of the content displayed. */
    contentType: string.isRequired,
    /* The id of the current opened popover of the user block. */
    currentlyOpenedPopover: string.isRequired,
    /* The items to displayed. */
    items: arrayOf(any).isRequired,
    /* A function for setting the value of the "currentlyOpenedPopover" prop. */
    setCurrentlyOpenedPopover: func.isRequired,
    /* The lumx theme. */
    theme: string,
    /* The current widget uuid. */
    uuid: string.isRequired,
};

export default WidgetDelveContent;
