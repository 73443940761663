import React from 'react';

import { AspectRatio, ProgressCircular, SkeletonRectangle, SkeletonRectangleVariant } from '@lumapps/lumx/react';

import { usePlayerClassnames } from '../../hooks';

import './Skeleton.scss';

interface SkeletonProps {
    /** Custom class name */
    className?: string;
}
export function Skeleton({ className }: SkeletonProps) {
    const { element } = usePlayerClassnames();

    return (
        <div className={element('skeleton', [className])}>
            <SkeletonRectangle
                aspectRatio={AspectRatio.wide}
                className={element('skeleton__rectangle')}
                variant={SkeletonRectangleVariant.rounded}
            />

            <ProgressCircular className={element('skeleton__progress')} />
        </div>
    );
}
