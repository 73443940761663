import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { EmbeddedType, OgDescription, OgImage, OgTitle } from '@lumapps/wrex/serialization/dita/types';

import { EmbeddedMapElement } from '../../types';
import { createEmbeddedMap } from '../../utils/createEmbeddedMap';

/**
 * Convert Dita Embed Map into slate Embed Map element.
 */
export const EMBEDDED_MAP_CONVERT: ElementConvertOption = {
    test: matchAttribute('type', /lumapps:embedded/),
    transform: (nd): EmbeddedMapElement => {
        const node = nd as EmbeddedType;
        const ogTitleNode = node.children.find(matchAttribute('name', /og:title/)) as OgTitle;
        const ogDescriptionNode = node.children.find(matchAttribute('name', /og:description/)) as OgDescription;
        const ogImageNode = node.children.find(matchAttribute('name', /og:image/)) as OgImage;

        const { href } = node?.attributes || {};

        return createEmbeddedMap({
            title: ogTitleNode?.attributes.value,
            thumbnailUrl: ogImageNode?.attributes.value,
            description: ogDescriptionNode?.attributes.value,
            url: href,
        });
    },
};
