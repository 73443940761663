import { GLOBAL } from '@lumapps/translations';

import { FILTER_TYPES } from './filters';

/**
 * The different types of Search engines that we support. If there is a new search engine that wants
 * to be integrated into the frontend, it needs to go through here.
 */
export enum ENGINES {
    LUMAPPS = 'lumapps',
    DRIVE = 'drive',
    LUMWORK = 'lumwork',
    ZENDESK = 'zendesk',
    SHAREPOINT = 'sharepoint',
    CLOUDSEARCH = 'cloudsearch',
    COVEO = 'coveo',
    MICROSOFT = 'microsoft',
}

/**
 * Since Typescript does not recognize an enum as a Record<string, string>, we need to have something that
 * translates from a raw string to an ENGINES enum
 * @param filter - the filter applied to the current search
 */
export const getEngineForFilter = (filter?: string): ENGINES => {
    switch (filter) {
        case ENGINES.LUMWORK:
            return ENGINES.LUMWORK;
        case ENGINES.DRIVE:
            return ENGINES.DRIVE;
        case ENGINES.ZENDESK:
            return ENGINES.ZENDESK;
        case ENGINES.SHAREPOINT:
            return ENGINES.SHAREPOINT;
        case ENGINES.MICROSOFT:
            return ENGINES.MICROSOFT;
        default:
            return ENGINES.LUMAPPS;
    }
};

export const ENGINES_TRANSLATIONS: Record<ENGINES, string> = {
    [ENGINES.DRIVE]: GLOBAL.DRIVE,
    [ENGINES.LUMWORK]: 'SEARCH_PLUS_LUMWORK_TAB',
    [ENGINES.ZENDESK]: 'SEARCH_PLUS_ZENDESK_TAB',
    [ENGINES.SHAREPOINT]: GLOBAL.ONE_DRIVE,
    [ENGINES.LUMAPPS]: GLOBAL.LUMAPPS,
    [ENGINES.CLOUDSEARCH]: GLOBAL.CLOUDSEARCH,
    [ENGINES.COVEO]: GLOBAL.COVEO,
    [ENGINES.MICROSOFT]: GLOBAL.MICROSOFT,
};

export const isExternalEngine = (value: FILTER_TYPES) => {
    return [
        FILTER_TYPES.DRIVE,
        FILTER_TYPES.ZENDESK,
        FILTER_TYPES.THIRD_PARTY,
        FILTER_TYPES.SHAREPOINT,
        FILTER_TYPES.IFRAME,
        FILTER_TYPES.GOOGLE,
        FILTER_TYPES.MICROSOFT,
    ].includes(value);
};
