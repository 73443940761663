/* eslint-disable react/jsx-fragments */
import React from 'react';

import classNames from 'classnames';

import { utc } from 'moment';

import { Checkbox, List, ListItem, Progress } from '@lumapps/lumx/react';

import { translate as t } from 'components/translations';

/**
 * Display the widget tasks list component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The tasks list react element.
 */
const TaskList = ({ items, selectedTasks, theme, toggleTask }) => {
    /**
     * A function that return a className based on the task status.
     *
     * @param  {string} status      The task status.
     * @param  {Date}   dueDateTime The task due date.
     * @return {string} The className.
     */
    const getTaskStateClass = (status, dueDateTime) => {
        const baseClass = 'date-txt';
        const taskDueDate = utc(dueDateTime);
        const todayDate = utc();

        switch (status) {
            case 'completed':
                return `${baseClass}--closed`;
            case 'inProgress':
            case 'notStarted':
                if (dueDateTime && taskDueDate.diff(todayDate, 'day') <= 2) {
                    return `${baseClass}--late`;
                } else if (
                    dueDateTime &&
                    (taskDueDate.isAfter(todayDate, 'day') || taskDueDate.isSame(todayDate, 'day')) &&
                    taskDueDate.diff(todayDate, 'week') < 1
                ) {
                    return `${baseClass}--risky`;
                } else if (
                    dueDateTime &&
                    (taskDueDate.isAfter(todayDate, 'day') || taskDueDate.isSame(todayDate, 'day')) &&
                    taskDueDate.diff(todayDate, 'week') >= 1
                ) {
                    return `${baseClass}--in-progress`;
                }

                return baseClass;

            default:
                return baseClass;
        }
    };

    return (
        <>
            <List className="tasks-list" theme={theme}>
                {items.map((task) => {
                    return (
                        <div key={task.id}>
                            <ListItem
                                before={
                                    task.isLoading ? (
                                        <Progress />
                                    ) : (
                                        <Checkbox
                                            className={classNames(
                                                {
                                                    checked: !selectedTasks.find((_task) => _task === task.id),
                                                },
                                                'tasks-list-checkbox',
                                            )}
                                            tabIndex={-1}
                                            theme={theme}
                                            checked={selectedTasks.find((_task) => _task === task.id) !== undefined}
                                        />
                                    )
                                }
                                className="tasks-list-item"
                                theme={theme}
                                onItemSelected={() => toggleTask(task)}
                            >
                                <div className="task-item__content">
                                    <div
                                        className={classNames({
                                            // eslint-disable-next-line camelcase
                                            'task-item__title': !selectedTasks.find((_task) => _task === task.id),
                                            'task-item__title--closed': selectedTasks.find(
                                                (_task) => _task === task.id,
                                            ),
                                        })}
                                    >
                                        {task.title}
                                    </div>

                                    {(task.dueDateTime || task.completedDateTime) && (
                                        <div className="task-item__due-date">
                                            <i className="mdi mdi-calendar-today" />

                                            <span
                                                className={
                                                    selectedTasks.find((_task) => _task === task.id) === undefined
                                                        ? getTaskStateClass(task.status, task.dueDateTime)
                                                        : 'date-txt--closed'
                                                }
                                            >
                                                {task.status === 'completed'
                                                    ? utc(task.completedDateTime)
                                                          .local()
                                                          .format(t('SIMPLE_DATE_FORMAT_NUMBER'))
                                                    : utc(task.dueDateTime)
                                                          .local()
                                                          .format(t('SIMPLE_DATE_FORMAT_NUMBER'))}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </ListItem>
                        </div>
                    );
                })}
            </List>
        </>
    );
};

export default TaskList;
