/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isContentTypesAllowed } from '../../ducks/selectors';
import { ContentTypesSideNavigation } from './ContentTypesSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isContentTypesAllowed(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedContentTypesSideNavigation = connect(mapStateToProps, mapDispatchToProps)(ContentTypesSideNavigation);

export { ConnectedContentTypesSideNavigation as ContentTypesSideNavigation };
