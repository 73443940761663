import { MapUrlPatterns } from './types';

/**
 * Contains some common URL pattern for the map websites we handle.
 *
 * @type {Object}
 */
export const MAP_URL_PATTERNS: MapUrlPatterns = {
    googleMap: [
        'goo.gl/maps/',
        'google.com/maps/',
        'google.fr/maps/',
        'google.co.jp/maps/',
        'google.co.uk/maps/',
        'maps.app.goo.gl',
    ],
    openStreetMap: ['openstreetmap.org', 'osm.org/go/'],
};

export enum OPEN_STREET_MAP_LAYERS {
    C = 'cyclemap',
    H = 'hot',
    O = 'opnvkarte',
    T = 'transportmap',
    Y = 'cyclosm',
}
