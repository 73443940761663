import { AppId } from '@lumapps/constants/app';
import { ContentTypes } from '@lumapps/content-types/types';
import { contentViewPath } from '@lumapps/contents/routes';
import { ContentLinkRef } from '@lumapps/contents/types';
import { Route } from '@lumapps/router';
import { createRelativeUrl } from '@lumapps/router/utils';

export const directoryView = (params: { isLayoutEnabled: boolean; to: ContentLinkRef }): Route => {
    const { id, slug, title, externalKey, anchor, keepParentPath = false, instance, query, isV2Compatible } = params.to;

    const instanceSlug = instance?.slug;
    const correctedSlug = (keepParentPath ? slug : slug?.split('/').pop()) || '';

    /**
     * Implement the MobilePageDescriptor
     * Enable the webview routing
     * */
    const mobilePageDescriptor: Route['mobilePageDescriptor'] | undefined =
        externalKey && title
            ? {
                  id: externalKey,
                  title,
                  type: ContentTypes.DIRECTORY,
              }
            : undefined;

    if (id && params.isLayoutEnabled && isV2Compatible) {
        return {
            appId: AppId.frontOffice,
            // We calculate the path here and not in the router to handle the wildcard slug path (`0` => `*` in the regexp)
            path: createRelativeUrl({ path: contentViewPath, params: { id, '0': correctedSlug } }),
            anchor,
            query,
            instanceSlug,
            mobilePageDescriptor,
        };
    }

    return {
        legacyId: 'app.front.content-get',
        appId: AppId.legacy,
        path: `/${correctedSlug}`,
        // Slug param is needed for the angular router
        params: { slug: correctedSlug, id },
        anchor,
        query,
        instanceSlug,
        mobilePageDescriptor,
    };
};
