import { Route, AppId, createPageRoute } from '@lumapps/router';

export const customerAdvancedSettingsRoute: Route = createPageRoute({
    slug: 'platform/advanced-settings',
    appId: AppId.backOffice,
});

export const termsAndConditionsRoute: Route = createPageRoute({
    // TODO: remove `admin` prefix when migrating this page to React.
    slug: 'admin/platform-settings/terms-and-conditions',
    id: 'terms-and-conditions',
    appId: AppId.legacy,
});

export const globalAdminsRoute: Route = createPageRoute({
    slug: 'platform/global-administrators',
    id: 'super-admin',
    appId: AppId.backOffice,
});
