import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import isUndefined from 'lodash/fp/isUndefined';
import map from 'lodash/fp/map';
import reject from 'lodash/fp/reject';

export function getFirstValueName<T>(getChoiceName: (value: T) => string, values: T[]): string | undefined {
    const valuesNames = flow(map(getChoiceName), reject(isUndefined))(values) as string[];

    return !isEmpty(valuesNames) ? first(valuesNames) : undefined;
}
