import React from 'react';

import { useDispatch } from '@lumapps/redux/react';

import { success, info, warning, error, extended, updateExtended, remove } from '../ducks/thunks';
import { ExtendedNotificationAPI, NotificationsAPI } from '../ducks/types';

/**
 * Toast notification dispatch actions
 */
export const useNotification = () => {
    const dispatch = useDispatch();
    return React.useMemo(
        () => ({
            success: (options: NotificationsAPI) => {
                dispatch(success(options));
            },
            info: (options: NotificationsAPI) => {
                dispatch(info(options));
            },
            warning: (options: NotificationsAPI) => {
                dispatch(warning(options));
            },
            error: (options: NotificationsAPI) => {
                dispatch(error(options));
            },
            extended: (options: ExtendedNotificationAPI) => {
                dispatch(extended(options));
            },
            updateExtended: (options: { id: string; notification: Partial<ExtendedNotificationAPI> }) => {
                dispatch(updateExtended(options));
            },
            remove: (id: string) => {
                dispatch(remove(id));
            },
        }),
        [dispatch],
    );
};
