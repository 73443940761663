/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';

import { SAShareableWorkflowDialog } from './SAShareableWorkflowDialog';
import { SAShareableWorkflowForm } from './SAShareableWorkflowForm';
import { SAShareableWorkflowPreview } from './SAShareableWorkflowPreview';

const SAShareableWorkflow = ({
    isWorkflowOpen,
    form,
    isLoading,
    isPreviewOpen,
    createShareableContent,
    shareableContent,
    resetWorkflowForm,
    onClose,
    toggleWorkflowPreview,
    contentId,
    programId,
    setContentUrlValue,
    canSaveContent,
    canPreviewContent,
}) => {
    /**
     * Resets the form and closes the modal.
     *
     */
    const handleClose = () => {
        resetWorkflowForm();
        if (onClose) {
            onClose();
        }
    };

    /**
     * Action on form submit. Dispatches redux action.
     *
     * @param {Event} evt The event that trigger this function.
     */
    const onSubmit = () => {
        createShareableContent(form.fields, programId, contentId, onClose);
    };

    /**
     * If contentId is set and there is no shareableContent, set the content url value.
     */
    useEffect(() => {
        if (isWorkflowOpen && contentId && !shareableContent) {
            setContentUrlValue(window.location.href, contentId);
        }
    }, [contentId, isWorkflowOpen, setContentUrlValue, shareableContent]);

    return (
        <SAShareableWorkflowDialog
            canCancel={!isLoading}
            canPreview={!isLoading && canPreviewContent}
            canSave={!isLoading && canSaveContent}
            isEdit={!isEmpty(shareableContent)}
            isLoading={isLoading}
            isOpen={isWorkflowOpen}
            isPreviewOpen={isPreviewOpen}
            toggleWorkflowPreview={toggleWorkflowPreview}
            onCancel={handleClose}
            onSubmit={onSubmit}
        >
            <div className="shareable-workflow">
                {isPreviewOpen ? <SAShareableWorkflowPreview /> : <SAShareableWorkflowForm />}
            </div>
        </SAShareableWorkflowDialog>
    );
};

export { SAShareableWorkflow };
