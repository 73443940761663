import React from 'react';

/**
 * Event handler for navigation in a grid that implement the a11y standard
 * https://www.w3.org/WAI/ARIA/apg/patterns/grid/
 *
 * Take some informations about the grid and the event. Call the callback
 * function with the newIndex, depending on the start position and the event
 * Useful for navigation in a grid (with a focus callback)
 */
export function gridNavigationEventHandler<T>({
    callback,
    currentIndex,
    event,
    horizontalSize,
    itemsCount,
    listOfItems,
}: {
    callback: (index: number) => void;
    currentIndex: number;
    event: React.KeyboardEvent<T>;
    horizontalSize: number;
    itemsCount: number;
    listOfItems: any[][];
}): boolean {
    // On shift + tab, focus the previous item
    if (event.key === 'Tab' && event.shiftKey) {
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            event.preventDefault();
            callback(previousIndex);
        }
    }
    // On tab, focus the next item
    else if (event.key === 'Tab') {
        const nextIndex = currentIndex + 1;
        if (nextIndex < itemsCount) {
            event.preventDefault();
            callback(nextIndex);
        }
    }
    // On ArrowLeft, focus the previous item
    else if (event.key === 'ArrowLeft') {
        event.preventDefault();
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            callback(previousIndex);
        }
    }
    // On ArrowRight, focus the next item
    else if (event.key === 'ArrowRight') {
        event.preventDefault();
        const nextIndex = currentIndex + 1;
        if (nextIndex < itemsCount) {
            callback(nextIndex);
        }
    }
    // On ArrowUp, focus the item above
    else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const previousIndex = currentIndex - horizontalSize;
        if (previousIndex >= 0) {
            callback(previousIndex);
        }
    }
    // On ArrowDown, focus the item below
    else if (event.key === 'ArrowDown') {
        event.preventDefault();
        const nextIndex = currentIndex + horizontalSize;
        if (nextIndex < itemsCount) {
            callback(nextIndex);
        }
    }
    // On PageDown, focus the last item of the current column
    else if (event.key === 'PageDown') {
        event.preventDefault();
        const rowCount = Math.ceil(itemsCount / horizontalSize);
        const currentRow = currentIndex % horizontalSize;
        let lastRowIndex = rowCount - 1;
        if (listOfItems[lastRowIndex].length <= currentRow) {
            lastRowIndex -= 1;
        }
        if (lastRowIndex >= 0) {
            const bottomIndex = lastRowIndex * horizontalSize + currentRow;
            if (bottomIndex < itemsCount && bottomIndex !== currentIndex) {
                callback(bottomIndex);
            }
        }
    }
    // On PageUp, focus the first item of the current column
    else if (event.key === 'PageUp') {
        event.preventDefault();
        const currentColumn = currentIndex % horizontalSize;
        if (currentColumn !== currentIndex) {
            callback(currentColumn);
        }
    }
    // On Ctrl + Home, focus the first item of the first row
    else if (event.key === 'Home' && event.ctrlKey) {
        event.preventDefault();
        if (currentIndex !== 0) {
            callback(0);
        }
    }
    // On Ctrl + End, focus the last item of the last row
    else if (event.key === 'End' && event.ctrlKey) {
        event.preventDefault();
        if (currentIndex !== itemsCount - 1) {
            callback(itemsCount - 1);
        }
    }
    // On Home, focus the first item of the current row
    else if (event.key === 'Home') {
        event.preventDefault();
        const nextIndex = currentIndex - (currentIndex % horizontalSize);
        if (nextIndex >= 0 && currentIndex !== nextIndex) {
            callback(nextIndex);
        }
    }
    // On End, focus the last item of the current row
    else if (event.key === 'End') {
        event.preventDefault();
        const currentRow = Math.floor(currentIndex / horizontalSize);
        const lastIndex = currentRow * horizontalSize + listOfItems[currentRow].length - 1;
        if (lastIndex < itemsCount && lastIndex !== currentIndex) {
            callback(lastIndex);
        }
    } else {
        return false;
    }
    return true;
}
