import map from 'lodash/map';
import filter from 'lodash/filter';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';

function LsTextEditorService(Instance, LsTextEditorMarkdown, Translation, User) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The fields to use in the projection for the mention list.
     *
     * @type {Object}
     */
    const _MENTION_PROJECTION = {
        items: {
            apiProfile: {
                primaryEmail: true,
                profilePicture: true,
                thumbnail: true,
                thumbnailPhotoUrl: true,
            },
            firstName: true,
            id: true,
            lastName: true,
        },
    };

    /**
     * The number of maximum results to display for users listing.
     *
     * @type {number}
     */
    const _maxResults = 10;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * List key for the User service when searching mentionned user.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    service.MENTION_EDITOR_LIST_LEY = 'mention-search';

    /**
     * A list of characters that will identify the beginning of a mention.
     *
     * @type {Array}
     */
    service.defaultIdentifierChar = ['@', '+'];

    /**
     * The pattern for encoded mentions (this is how they're stored).
     * Capturing 2 groups: user name / feed title AND id.
     *
     * @type {string}
     */
    service.encodedMentionPattern = /@\[(?:feed:)?([^:\]]+):(\d+)]/g;

    /**
     * Markdown image pattern.
     *
     * @type {RegExp}
     */
    service.imagePattern = /!\[([^\]]*?)]\(([^)]+?)\)/g;

    /**
     * List of editor available modes.
     *
     * @type {Object}
     */
    service.availableModes = {
        preview: 'preview',
        write: 'write',
    };

    /**
     * The currently edited content.
     *
     * @type {Object}
     */
    service.currentTextarea = undefined;

    /**
     * The keywords that will trigger a notification to 'all' users for a given feed key.
     *
     * @type {Array}
     * @readonly
     */
    service.mentionAllKeywords = [];
    service.scopeFeedVarPrefix = 'feed';
    service.scopeUserVarPrefix = 'user';

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get all the keywords to mention all users.
     *
     * @return {Array} An array of strings that can be used to mention all users.
     */
    function _getMentionAllKeywords() {
        // Get all values for the 'ALL' key for the languages allowed in the current Instance.
        // Remove all the undefined / falsy values.
        const valuesForSiteLangs = compact(
            map(
                filter(Translation.getTranslationsTable(), (translations, lang) =>
                    includes(Instance.getInstance().langs, lang),
                ),
                'ALL',
            ),
        );

        // Lowercase the values.
        const lowerCaseLangs = valuesForSiteLangs.map((k) => k.toLowerCase());

        // Sort them by alphabetical order.
        // Reverse the array order (because they're unshifted in the choices array).
        return sortBy(lowerCaseLangs, (k) => -k).reverse();
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Encode choice to make it unique and easily findable in search / replace.
     *
     * @param  {Object} choice A choice in the list of suggested results.
     * @return {string} The encoded choice.
     */
    function encode(choice) {
        if (choice.type === 'image') {
            // Feed.
            return `![${choice.imageName}](${choice.url})`;
        }

        if (choice.type === 'feed') {
            // Feed.
            return `@[feed:${choice.name}:${choice.id}]`;
        }

        // User.
        return `@[${choice.firstName}-${choice.lastName}:${choice.id}]`;
    }

    /**
     * Build a directive tag to insert in HTML, assume the user attribute value are in the right scope.
     *
     * @param  {string} choiceId                            The id of the choice we want to build a user block for
     *                                                      (can be a user id or a feed id).
     * @param  {string} [theme=light]                       The theme used.
     * @param  {string} [prefix=service.scopeUserVarPrefix] The prefix used by the scope to reference the choiceId
     *                                                      (can be user of feed for now).
     * @return {string} The directive used to render the user choice.
     */
    function encodeToDirective(choiceId, theme, prefix) {
        theme = theme || 'light';

        prefix = prefix || service.scopeUserVarPrefix;

        if (prefix === service.scopeUserVarPrefix) {
            return `<ls-user-block-wrapper ls-view-mode="name" ls-theme="${theme}" ls-user="${prefix}${choiceId}"></ls-user-block-wrapper>`;
        }

        // For feeds we don't want a popover with user details.
        return `<feed-block feed="${prefix}${choiceId}" view-mode="name"></feed-block>`;
    }

    /**
     * Trigger the markdown action.
     *
     * @param {string} link The link url.
     */
    function externalLinkTrigger(link) {
        const config = LsTextEditorMarkdown.MARKDOWN_CONFIG.link;
        // Replace url by the one entered.
        config.value = config.value.replace('url', link);

        service.currentTextarea.triggerAction('link', config);
    }

    /**
     * Go through a list of feed keys and find the associated feeds.
     * Note: this is because we do not store any details about the feeds for now.
     * (unlike for users with mentionDetails).
     *
     * @param  {string} text     The text to search feed keys in.
     * @param  {Array}  feedKeys An array of feed keys to get feeds from.
     * @return {Array}  An array of feed objects.
     */
    function getFeedMentionedFromFeedKeys(text, feedKeys) {
        const feedMentioned = [];
        let match;

        angular.forEach(feedKeys, (feedKey) => {
            // We currently do not store the details about the feeds.
            // So, we need to find its name by hand in the content.
            const feedPattern = new RegExp(`@\\[(?:feed:)([^:\\]]+):${feedKey}\\]`, 'g');

            match = feedPattern.exec(text);
            if (match !== null) {
                // Replace the content with the matched feed we just found.
                text = text.replace(match[0], match[1]);

                feedMentioned.push({
                    id: feedKey,
                    name: match[1].charAt(0).toUpperCase() + match[1].slice(1),
                    type: 'feed',
                });
            }
        });

        return feedMentioned;
    }

    /**
     * The HTML wrapper of the mentioned user.
     *
     * @param  {Object} choice The choice we want to highlight.
     * @return {string} The mentioned highlighted html text.
     */
    function highlight(choice) {
        return `<span class="ls-rich-text-editor__highlight">${service.label(choice)}</span>`;
    }

    /**
     * Format a choice / mention / user to be displayed or searched properly.
     *
     * @param  {Object} choice A choice in the list of suggested results.
     * @return {string} The formated choice.
     */
    function label(choice) {
        if (choice.type === 'image') {
            return `🖼️ ${choice.imageName}`;
        }

        if (choice.type === 'feed') {
            return choice.name;
        }

        return User.getUserFullName(choice);
    }

    /**
     * Replace search by labelled mention.
     *
     * @param  {Object} choice         The choice in the list of suggested results.
     * @param  {Array}  match          The results that match our pattern search.
     * @param  {string} text           The full textarea content.
     * @param  {string} identifierChar The char used to trigger mention.
     * @return {string} The reformatted text.
     */
    function replace(choice, match, text, identifierChar) {
        return `${text.substr(0, match.index + match[0].indexOf(identifierChar)) + service.label(choice)} ${text.substr(
            match.index + match[0].length,
        )}`;
    }

    /**
     * Return the entity matching the query (so potential entity).
     *
     * @param {string}   query The entity partial name to search for.
     * @param {Function} cb    Success callback.
     * @param {Function} errCb Error callback.
     */
    function search(query, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        User.filterize(
            {
                instance: Instance.getCurrentInstanceId(),
                maxResults: _maxResults,
                query,
                queryFields: ['fullName', 'partialSearch', 'emails'],
                showHidden: false,
                status: 'enabled',
            },
            cb,
            errCb,
            service.MENTION_EDITOR_LIST_LEY,
            _MENTION_PROJECTION,
        );
    }

    /////////////////////////////

    service.encode = encode;
    service.encodeToDirective = encodeToDirective;
    service.externalLinkTrigger = externalLinkTrigger;
    service.getFeedMentionedFromFeedKeys = getFeedMentionedFromFeedKeys;
    service.highlight = highlight;
    service.label = label;
    service.replace = replace;
    service.search = search;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    function init() {
        service.mentionAllKeywords = _getMentionAllKeywords();
    }

    init();
}

/////////////////////////////

angular.module('Services').service('LsTextEditor', LsTextEditorService);
