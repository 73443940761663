import { ACTIVITY_LIST_DEFAULT_FIELDS } from '@lumapps/streams/constants';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileActivitiesController($scope, $timeout, SocialActivity, SocialProfile, Utils, Features) {
        'ngInject';

        var vmActivities = this;
        // eslint-disable-next-line lumapps/file-format
        var vm = $scope.$parent.vm;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Delay of the auto-refresh of the activities, in seconds.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _AUTO_REFRESH = 10;

        /**
         * Delay of the end of the auto-refresh, in minutes.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _END_AUTO_REFRESH = 10;

        /**
         * Contains the timeout of the auto-refresh.
         *
         * @type {TimeoutPromise}
         */
        var _activitiesTimer;

        /**
         * Contains the timeout to stop the auto-refresh.
         *
         * @type {TimeoutPromise}
         */
        var _globalTimeout;

        /**
         * Contains the params for the request of the activity feed.
         *
         * @type {Object}
         */
        var _params = {
            maxResults: 5,
            fields: ACTIVITY_LIST_DEFAULT_FIELDS,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The idenfitier of the list of activities.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        vmActivities.LIST_KEY = 'all-activities';

        /////////////////////////////
        /**
         * Services and utilities.
         */
        vmActivities.SocialActivity = SocialActivity;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Stop and clear all the timeouts.
         */
        function _clearTimer() {
            if (angular.isObject(_activitiesTimer)) {
                $timeout.cancel(_activitiesTimer);
                _activitiesTimer = undefined;
            }

            if (angular.isObject(_globalTimeout)) {
                $timeout.cancel(_globalTimeout);
                _globalTimeout = undefined;
            }
        }

        /**
         * Load the new activites and preprend them to the list of activities.
         *
         * @param {boolean} [reset] Indicates if we want to reset the list or simply preprend new activities.
         */
        function _loadActivities(reset) {
            if (!Features.hasFeature('social')) {
                return;
            }

            if (reset) {
                Utils.empty(SocialActivity._services[vmActivities.LIST_KEY]._list);
            }

            SocialActivity.filterize(_params, function onSocialActivitiesListSuccess(response) {
                if (reset) {
                    SocialActivity._services[vmActivities.LIST_KEY]._list = response || [];

                    return;
                }

                var newActivities = (response || []).filter(function filterResponse(activity) {
                    return !_.find(SocialActivity.displayList(vmActivities.LIST_KEY), {
                        id: activity.id,
                    });
                });

                if (angular.isDefinedAndFilled(newActivities)) {
                    SocialActivity._services[vmActivities.LIST_KEY]._list =
                        newActivities.concat(SocialActivity._services[vmActivities.LIST_KEY]._list);
                }

                if (angular.isDefined(_globalTimeout)) {
                    // Auto-refresh the activities in 10 seconds.
                    _activitiesTimer = $timeout(_loadActivities.bind(vmActivities, false), _AUTO_REFRESH * 1000);
                }
            }, function onSocialActivitiesListError() {
                if (angular.isDefined(_globalTimeout)) {
                    // Auto-refresh the activities in 10 seconds.
                    _activitiesTimer = $timeout(_loadActivities.bind(vmActivities, false), _AUTO_REFRESH * 1000);
                }
            }, 'prepend-activities');
        }

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When we toggle the scope of the activities (instance/plateform), re-filter the activities.
         */
        $scope.$on('scope-toggle', function onScopeToggle() {
            _clearTimer();
            vm.init();
        });

        /**
         * When the scope is destroyed, stop and clear all the timeouts.
         */
        $scope.$on('$destroy', _clearTimer);

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            if (!Features.hasFeature('social')) {
                return;
            }

            SocialProfile.addGlobalParams(_params);

            SocialActivity.filterize(_params, function onFirstFilterizeSuccess() {
                $timeout(_loadActivities.bind(vmActivities, false), _AUTO_REFRESH * 1000);
            }, Utils.displayServerError, vmActivities.LIST_KEY);

            // Kill the auto-refresh timer after 10 minutes on this page.
            _globalTimeout = $timeout(_clearTimer, _END_AUTO_REFRESH * 60 * 1000);
        };

        vm.init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('SocialProfileActivitiesController', SocialProfileActivitiesController);
})();
