import isUndefined from 'lodash/isUndefined';

import { getRGBValues } from '.';

/**
 * Find the color in the given array that is the closest to the given color.
 *
 * @param  color        The color to compare to (hex or rgb format).
 * @param  colorPalette The list of color to find a color into.
 * @param  maxDistance  The maximum distance between two colors to be considered "close", default to 90
 *
 * @return The color or undefined if no color was found respecting the maxDistance.
 *
 * Calculation source : https://nesin.io/blog/find-closest-color-javascript
 */
export const getClosestColorFromPalette = (
    color: string,
    colorPalette?: string[],
    maxDistance: number = 90,
): string | undefined => {
    if (!colorPalette) {
        return undefined;
    }

    let closestColor: string | undefined;
    let closestDistanceFound: number | undefined;

    const targetRgbColor = getRGBValues(color);

    if (targetRgbColor) {
        // Loop through the color palette
        for (const colorPaletteValue of colorPalette) {
            if (colorPaletteValue.toUpperCase() === color.toUpperCase()) {
                closestColor = colorPaletteValue;
                closestDistanceFound = 0;
                break;
            }

            // Convert current color from hex string to RGB values if needed
            const paletteColor = getRGBValues(colorPaletteValue);

            if (paletteColor) {
                // Calculate the Euclidean distance between the target color and current color
                const distance = Math.sqrt(
                    (targetRgbColor.red - paletteColor.red) ** 2 +
                        (targetRgbColor.green - paletteColor.green) ** 2 +
                        (targetRgbColor.blue - paletteColor.blue) ** 2,
                );

                // Set the first distance found
                if (!closestDistanceFound) {
                    closestDistanceFound = distance;
                    closestColor = colorPaletteValue;
                }
                // Update the closest color and distance if the current distance is smaller than the closest distance
                else if (distance < closestDistanceFound) {
                    closestDistanceFound = distance;
                    closestColor = colorPaletteValue;
                }
            }
        }
    }

    return !isUndefined(closestDistanceFound) && closestDistanceFound <= maxDistance ? closestColor : undefined;
};
