/**
 * The document types.
 * @readonly
 * @constant
 */
const documentTypes = {
    all: ['FOLDER', 'IMAGE', 'OTHER'],
    allExceptFolders: ['IMAGE', 'OTHER'],
    documents: ['OTHER'],
    documentsAndFolders: ['FOLDER', 'OTHER'],
    folders: ['FOLDER'],
    images: ['IMAGE'],
    imagesAndFolders: ['FOLDER', 'IMAGE'],
};

const mediaTypes = {
    GOOGLE: {
        FILE: 'GOOGLE_FILE',
    },
    LOCAL: {
        FILE: 'LUMDRIVE_FILE',
    },
    ONEDRIVE: {
        FILE: 'ONEDRIVE_FILE',
    },
};

/**
 * Contains the list of supported providers.
 */
const PROVIDERS = {
    community: 'community',
    google: 'drive',
    lumapps: 'local',
    microsoft: 'onedrive',
    haussmann: 'haussmann_media',
};

/**
 * The media constants.
 *
 * @type {string}
 * @readonly
 * @constant
 */
const MediaConstant = {
    CATEGORIES: {
        library: 'LIBRARY',
        mine: 'MINE',
    },

    DOCUMENT_TYPES: documentTypes,

    /**
     * The kind of selected items in media-picker.
     */
    KIND: {
        DRIVE: 'Drive',
        FOLDER: 'Folder',
        MEDIA: 'Media',
    },

    PROVIDERS,

    /**
     * The default sort order.
     */
    SORT_ORDER: 'name',
};

/////////////////////////////

angular.module('Constants').constant('MediaConstant', MediaConstant);

export { MediaConstant, documentTypes, mediaTypes, PROVIDERS };
