import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Message, Kind, FlexBox, ProgressCircular, Alignment } from '@lumapps/lumx/react';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';
import { useTranslate } from '@lumapps/translations';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { FETCH_SN_STATUSES } from '../../../ducks/shareableWorkflow/constants';
import { SocialNetworksSelect } from '../SocialNetworksSelect';
import { SocialNetworksMessages } from '../SocialNetworksMessages';
import { SocialNetworksError } from './SocialNetworksError';

export interface SocialNetworksManagementProps {
    status: FETCH_SN_STATUSES;
    programId: string;
    initSocialNetworks(programId: string): void;
    availableSocialNetworks: Array<SocialNetworks>;
}

export const SocialNetworksManagement: React.FC<SocialNetworksManagementProps> = ({
    status,
    programId,
    initSocialNetworks,
    availableSocialNetworks,
}) => {
    const { translateKey } = useTranslate();

    React.useEffect(() => {
        if (initSocialNetworks && status === FETCH_SN_STATUSES.initial && programId) {
            initSocialNetworks(programId);
        }
    }, [status, initSocialNetworks, programId]);

    switch (status) {
        case FETCH_SN_STATUSES.loading:
            return (
                <FlexBox className="lumx-spacing-padding-huge" vAlign={Alignment.center} hAlign={Alignment.center}>
                    <ProgressCircular />
                </FlexBox>
            );
        case FETCH_SN_STATUSES.failure:
            return <SocialNetworksError className="lumx-spacing-margin-top-huge" />;
        case FETCH_SN_STATUSES.success: {
            return isEmpty(availableSocialNetworks) ? (
                <Message className="lumx-spacing-margin-top-huge" hasBackground kind={Kind.warning}>
                    {translateKey(
                        'FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.ERROR.NO_SOCIAL_NETWORK_ENABLED_ON_PROGRAM',
                    )}
                </Message>
            ) : (
                <ErrorBoundary fallback={<SocialNetworksError className="lumx-spacing-margin-top-huge" />}>
                    <SocialNetworksSelect />
                    <SocialNetworksMessages />
                </ErrorBoundary>
            );
        }
        default:
            return null;
    }
};
