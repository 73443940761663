/* istanbul ignore file */
import { isSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { InstancesSideNavigation } from './InstancesSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isSuperAdmin(state);

    return { isAllowed };
};

const mapDispatchToProps = {};

const ConnectedInstancesSideNavigation = connect(mapStateToProps, mapDispatchToProps)(InstancesSideNavigation);

export { ConnectedInstancesSideNavigation as InstancesSideNavigation };
