import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { isFeatureEnabled } from '@lumapps/features';
import { UploadImageDialog } from '@lumapps/lumx-images/components/UploadImageDialog';
import { UPLOAD_IMAGE_OPTIONS } from '@lumapps/lumx-images/types';
import { mdiImageOutline } from '@lumapps/lumx/icons';
import { useMediaPickerDropdown } from '@lumapps/medias/hooks/useMediaPickerDropdown';
import { Media, MediaPickerParams } from '@lumapps/medias/types';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useFilePicker } from '@lumapps/utils/hooks/useFilePicker';
import { useSlateStatic } from '@lumapps/wrex/slate';
import type { ToolbarItem, WrexEditor } from '@lumapps/wrex/types';

import { IMAGE_FEATURES, IMAGE_GROUP } from '../../../constants';
import { WREX_ENHANCED_IMAGE } from '../../../keys';
import type { ImageEditor } from '../../../types';

export interface UseInsertEnhancedImageButtonProps {
    /** Whether Drive is allowed in the media picker. */
    isDriveAllowed?: boolean;
    // Whether we are in a community context or not.
    isInCommunityContext?: boolean;
    /** Whether Lumdrive is allowed in the media picker. */
    isLumdriveAllowed?: boolean;
    /** Params of the media picker */
    mediaPickerParams?: MediaPickerParams;
    /** Function to upload from computer. */
    onQuickUpload: (files: File[]) => void;
    /** On media change callback */
    onMediaChange?(media: Media[]): void;
    /** Files that will already be selected on the media picker */
    currentFiles?: Media[];
    /** Feature scope for tracking */
    scope: string;
    /** Other props passed to the ToolbarItem */
    otherProps?: any;
}

export const useInsertEnhancedImageButton = ({
    onQuickUpload,
    currentFiles,
    isDriveAllowed,
    isLumdriveAllowed,
    isInCommunityContext,
    mediaPickerParams,
    onMediaChange,
    scope,
    otherProps,
}: UseInsertEnhancedImageButtonProps) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);
    const editor = useSlateStatic() as WrexEditor<ImageEditor>;
    const isGenAIFeatureEnabled = useSelector(isFeatureEnabled('generative-ai'));
    const shouldUseUploadDialog = isGenAIFeatureEnabled && editor.isImageFeatureEnabled(IMAGE_FEATURES.generateWithAI);

    const { hiddenInput, openPicker } = useFilePicker({
        onSelectFiles: (files) => {
            if (files) {
                onQuickUpload(files);
            }
        },
        accept: 'image/*',
        inputOrigin: 'image-insertion-button',
    });

    const { mediaPickerDropdownProps } = useMediaPickerDropdown({
        onUploadFromComputer: openPicker,
        currentFiles,
        isDriveAllowed,
        isInCommunityContext,
        isLumdriveAllowed,
        mediaPickerParams,
        onMediaChange,
        subMenuProps: {
            itemKey: IMAGE_GROUP,
            icon: mdiImageOutline,
            otherProps,
            tooltipLabel: translateKey(WREX_ENHANCED_IMAGE.INSERT_IMAGE),
            verticalModeProps: {
                label: translateKey(WREX_ENHANCED_IMAGE.IMAGE_BUTTON_LABEL),
                tooltipLabel: undefined,
            },
        },
    });

    const [isDialogOpen, , closeDialog, openDialog] = useBooleanState(false);

    const onUploadConfirm = (file: File) => {
        onQuickUpload([file]);
        closeDialog();
    };

    const uploadImageDialog = shouldUseUploadDialog ? (
        <UploadImageDialog
            isOpen={isDialogOpen}
            onClose={closeDialog}
            dialogTitle={translateKey(WREX_ENHANCED_IMAGE.INSERT_IMAGE)}
            options={[
                {
                    type: UPLOAD_IMAGE_OPTIONS.FROM_COMPUTER,
                    onConfirm: onUploadConfirm,
                },
                {
                    type: UPLOAD_IMAGE_OPTIONS.WITH_AI,
                    onConfirm: onUploadConfirm,
                },
            ]}
            scope={scope}
        />
    ) : null;

    const insertImageButtonProps: ToolbarItem = mediaPickerDropdownProps || {
        type: 'option',
        itemKey: IMAGE_GROUP,
        icon: mdiImageOutline,
        onClick: shouldUseUploadDialog ? openDialog : openPicker,
        otherProps: {
            ...get({ element: 'button', action: 'image' }),
            ...otherProps,
        },
        tooltipLabel: translateKey(WREX_ENHANCED_IMAGE.INSERT_IMAGE),
        verticalModeProps: {
            label: translateKey(WREX_ENHANCED_IMAGE.IMAGE_BUTTON_LABEL),
            tooltipLabel: undefined,
        },
    };

    return { hiddenInput: shouldUseUploadDialog ? null : hiddenInput, insertImageButtonProps, uploadImageDialog };
};
