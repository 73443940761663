import { produce } from 'immer';
import { keyBy } from 'lodash';

import { FETCH_CCT_SUCCESS } from './cct_actions';

const DEFAULT_STATE = {
    entities: {},
};

const reducer = produce((draft = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_CCT_SUCCESS: {
            const { customContentTypes } = action.payload;
            Object.assign(draft.entities, keyBy(customContentTypes, 'uid'));

            return draft;
        }

        default:
            return draft;
    }
});

export { reducer };
