import React from 'react';

import { CONNECTORS } from '@lumapps/connectors/keys';
import { platformConnectors } from '@lumapps/connectors/routes';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiPuzzle } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { MARKETPLACE } from '../../keys';
import { platformExtensions, marketplace } from '../../routes';

export interface PlatformExtensionsSideNavigationProps {
    isAllowed: boolean;
}

const PlatformExtensionsSideNavigation = ({ isAllowed }: PlatformExtensionsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(MARKETPLACE.PLATFORM_EXTENSIONS)} icon={mdiPuzzle} id="mp">
            <SideNavigationItem label={translateKey(MARKETPLACE.EXTENSIONS_LIBRARY)} route={marketplace()} />;
            <SideNavigationItem label={translateKey(MARKETPLACE.INSTALLED_EXTENSIONS)} route={platformExtensions()} />
            <SideNavigationItem
                label={translateKey(CONNECTORS.ADMIN_SIDE_NAVIGATION)}
                id="connectors"
                route={platformConnectors()}
            />
        </SideNavigationItem>
    );
};

PlatformExtensionsSideNavigation.displayName = 'SideNavigationItem';

export { PlatformExtensionsSideNavigation };
