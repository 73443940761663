/* eslint-disable no-invalid-this */
(function IIFE() {
    'use strict';

    /////////////////////////////

    function AbstractContentFormService(Instance, LxNotificationService, Translation, Utils) {
        'ngInject';

        var AbstractContentForm = (function AbstractContentFormIIFE() {
            /////////////////////////////
            //                         //
            //    Private functions    //
            //                         //
            /////////////////////////////

            /**
             * Check the validity of the feeds of a content.
             * The feeds are valid if they contains at least one feed.
             *
             * @param  {Object}  content The content we want to check the validity of feeds of.
             * @return {boolean} If the feeds are valid or not.
             */
            function _checkFeedKeys(content) {
                return angular.isUndefinedOrEmpty(content.feedKeys);
            }

            /**
             * Check the validity of the slug of a content.
             * A slug is valid if it has at least on translation in one language.
             *
             * @param  {string}  slug The slug we want to check the validity of.
             * @return {boolean} If the slug is valid or not.
             */
            function _checkSlug(slug) {
                return !Translation.hasTranslations(slug);
            }

            /**
             * Check the validity of the title of a content.
             * A title is valid if it has at least one translation in one language.
             *
             * @param  {Object}  content The content we want to check the validity of title of.
             * @return {boolean} If the title is valid (false) or not (true).
             */
            function _checkTitle(content) {
                return !Translation.hasTranslations(content.title);
            }

            /**
             * Check the validity of the title of a content.
             * A title is valid if it has at least one translation in one language.
             *
             * @param  {Object}  content The content we want to check the validity of title of.
             * @return {boolean} If the title is valid (false) or not (true).
             */
            function _checkExternalLinks(content) {
                return Translation.hasTranslations(content.link) && (Object.values(content.link).some(link => link.match(/javascript:/g)));
            }

            /////////////////////////////

            /**
             * Create a new abstract content form.
             *
             * @param {Object} service The current content service used.
             */
            // eslint-disable-next-line no-shadow
            function AbstractContentForm(service) {
                /////////////////////////////
                //                         //
                //    Public attributes    //
                //                         //
                /////////////////////////////

                /**
                 * The temporaries slugs defined by language.
                 *
                 * @type {Object}
                 */
                this.tmpSlug = {};

                /**
                 * Used to determine if the slug is empty for a certain language.
                 *
                 * @type {Object}
                 */
                this.wasEmpty = {};

                /**
                 * The current list key.
                 *
                 * @type {string}
                 */
                this.activeListKey = undefined;

                /**
                 * The current content service used.
                 *
                 * @type {Object}
                 */
                this.service = service;
            }

            /////////////////////////////

            AbstractContentForm.prototype._checkFeedKeys = _checkFeedKeys;
            AbstractContentForm.prototype._checkSlug = _checkSlug;
            AbstractContentForm.prototype._checkTitle = _checkTitle;
            AbstractContentForm.prototype._checkExternalLinks = _checkExternalLinks;

            /////////////////////////////
            //                         //
            //     Public functions    //
            //                         //
            /////////////////////////////

            /**
             * Check the validity of the content.
             * A content is valid if the title, the slug, the feeds and the notified feeds are valid.
             *
             * @param  {boolean} checkTitle Indicates if we want to check the title of the content/community/....
             * @param  {boolean} checkSlug  Indicates if we want to check the slug of the content/community/....
             * @return {boolean} If the content is valid or not.
             */
            function checkContent(checkTitle, checkSlug) {
                checkTitle = (angular.isUndefined(checkTitle)) ? true : checkTitle;
                checkSlug = (angular.isUndefined(checkSlug)) ? true : checkSlug;

                var abstractService = this;
                var currentContent = abstractService.service.getCurrent(abstractService.activeListKey);

                if (angular.isUndefinedOrEmpty(currentContent)) {
                    return false;
                }

                // FIXME [Maxime]: rework this part, LxNotificationService must be in the controller.
                var langs = Instance.getCurrentInstanceLangs();

                if (checkTitle && abstractService._checkTitle(currentContent)) {
                    LxNotificationService.error(Translation.translate('ERROR_MANDATORY_FIELDS_TITLE'));

                    return false;
                } else if (checkSlug && abstractService._checkSlug(abstractService.tmpSlug)) {
                    LxNotificationService.error(Translation.translate('ERROR_MANDATORY_FIELDS_URL'));

                    return false;
                } else if (abstractService._checkFeedKeys(currentContent)) {
                    LxNotificationService.error(Translation.translate('FEED_REQUIRED'));

                    return false;
                } else if (abstractService._checkExternalLinks(currentContent)) {
                    LxNotificationService.error(Translation.translate('ERROR_LINK_BAD_FORMAT'));

                    return false;
                }
                
                if (!checkTitle && !checkSlug) {
                    return true;
                }

                var error = false;
                angular.forEach(langs, function forEachLangs(lang) {
                    var titleState = (checkTitle) ? Boolean(currentContent.title[lang]) : true;
                    var slugState = (checkSlug) ? Boolean(_.get(abstractService, 'tmpSlug[' + lang + ']')) : true;
                    var langErrorMsg = [];
                    var invalid = false;

                    // Add error title.
                    langErrorMsg.push(Translation.translate('LANG_ERROR') + ' ( ' + lang + ' ) :');

                    // If the two booleans are in the same state.
                    if (titleState === slugState) {
                        // If slug for lang is invalid.
                        if (angular.isDefinedAndFilled(_.get(abstractService, 'tmpSlug[' + lang + ']')) &&
                            !Utils.isSlugValid(abstractService.tmpSlug[lang])) {
                            invalid = true;
                            langErrorMsg.push('- ' + Translation.translate('ERROR_URL_BAD_FORMAT'));
                        }
                    } else {
                        invalid = true;

                        // If no title.
                        if (!titleState) {
                            langErrorMsg.push('- ' + Translation.translate('ERROR_MANDATORY_FIELDS_TITLE'));
                        }

                        // If no slug.
                        if (!slugState) {
                            langErrorMsg.push('- ' + Translation.translate('ERROR_MANDATORY_FIELDS_URL'));
                        }
                    }

                    if (invalid) {
                        error = true;
                        LxNotificationService.error(langErrorMsg.join('<br/>'));
                    }
                });

                return !error;
            }

            /**
             * Check the validity of the content feeds for form validation.
             * The feeds are valid if they contains at least one feed.
             * Use to set the feeds field in error state.
             *
             * @param  {Object}  form The form we want to check the validity of feeds of.
             * @return {boolean} If the feeds are valid or not.
             */
            function checkContentFeeds(form) {
                var currentContent = this.service.getCurrent(this.activeListKey);

                if (angular.isUndefinedOrEmpty(currentContent)) {
                    return true;
                }

                return form.$submitted && angular.isUndefinedOrEmpty(currentContent.feedKeys);
            }

            /**
             * Check the validity of the content slug for form validation.
             * A slug is valid if it has at least one translation in one language.
             * Use to set the slug field in error state.
             *
             * @param  {Object}  form The form we want to the check the validity of slug of.
             * @return {boolean} If the slug is valid or not.
             */
            function checkContentSlug(form) {
                if (angular.isUndefinedOrEmpty(_.get(form, 'slug'))) {
                    return true;
                }

                if (form.slug.$pristine) {
                    return false;
                }

                return form.slug.$invalid ||
                    !Utils.isSlugValid(_.get(this, 'tmpSlug[' + Translation.inputLanguage + ']'));
            }

            /**
             * Check the validity of the content title for form validation.
             * A title is valid if it has at least one translation in on language.
             * Use to set the title field in error state.
             *
             * @param  {Object}  form The form we want to the check the validity of title of.
             * @return {boolean} If the title is valid or not.
             */
            function checkContentTitle(form) {
                if (angular.isUndefinedOrEmpty(_.get(form, 'title'))) {
                    return true;
                }

                if (form.title.$pristine) {
                    return false;
                }

                return form.title.$invalid;
            }

            /**
             * Check the validity of the content external url for form validation.
             * A external link is valid if it is undefined, or defined and doesn't include a javascript script.
             * Use to set the external link field in error state.
             *
             * @param  {Object}  form The form we want to the check the validity of external link of.
             * @return {boolean} If the external link is valid or not.
             */
            function checkContentExternalLink(form) {
                if (angular.isUndefinedOrEmpty(_.get(form, 'link.$modelValue'))) {
                    return false;
                } 
                

                return Boolean(form.link.$modelValue.match(/^javascript:/g));
            }

            /**
             * Check the validity of the template feeds for form validation.
             * The feeds are valid if the template is in a basic mode and if they contains at least one feed.
             * Use to set the feeds field in error state.
             *
             * @return {boolean} If we are not in a template and the fields are valid.
             */
            function checkTemplateContentFeeds() {
                var currentContent = this.service.getCurrent(this.activeListKey);

                if (angular.isUndefinedOrEmpty(_.get(currentContent, 'template'))) {
                    return true;
                }

                return currentContent.template.basicMode &&
                    angular.isUndefinedOrEmpty(currentContent.template.feedKeys);
            }

            /**
             * Get the temporary slug. In edit mode, get already defined slug.
             *
             * @param  {string} listKey The listKey of the current content form.
             * @return {string} The temporary slug.
             */
            function getTmpSlug(listKey) {
                var currentContent = this.service.getCurrent(listKey);
                if (angular.isUndefinedOrEmpty(this.activeListKey)) {
                    this.setActiveListKey(listKey);
                }

                if (angular.isDefinedAndFilled(_.get(currentContent, 'slug')) &&
                    angular.isUndefinedOrEmpty(this.tmpSlug)) {
                    this.tmpSlug = angular.fastCopy(currentContent.slug);
                }

                return this.tmpSlug;
            }

            /**
             * Resets the params of the content form.
             */
            function resetContentForm() {
                var abstractService = this;

                abstractService.tmpSlug = {};
                abstractService.wasEmpty = {};
                abstractService.activeListKey = undefined;
            }

            /**
             * Save the content.
             *
             * @param {Function} [cb]    The callback function to call when save succeed.
             * @param {Function} [errCb] The callback function to call when save failed or content is not valid.
             */
            function saveContent(cb, errCb) {
                cb = cb || angular.noop;
                errCb = errCb || angular.noop;

                if (!this.checkContent()) {
                    errCb();
                    
                    return;
                }

                var keysToOmitInCopy = ['$promise', '$resolved'];
                var currentContent = this.service.getCurrent(this.activeListKey);
                currentContent.slug = this.tmpSlug;
                this.service.save(angular.fastCopy(_.omit(currentContent, keysToOmitInCopy)), cb, errCb,
                    this.activeListKey);
            }

            /**
             * Set the temporary slug.
             * The slug contains the slug definition by language.
             *
             * @param {Object} slug The slug we want to set.
             */
            function setTmpSlug(slug) {
                this.tmpSlug = slug;
            }

            /**
             * Sets the active listKey.
             *
             * @param {string} listKey The current list key.
             */
            function setActiveListKey(listKey) {
                this.activeListKey = listKey;
            }

            /**
             * Function triggered by an ng-change on a title field from creation form.
             * Updates the slug according to title.
             *
             * @param {string} title                   The changed title.
             * @param {string} [lang=<Input language>] The lang we want to get the slug in.
             */
            function setSlugFromTitle(title, lang) {
                lang = lang || Translation.inputLanguage;

                var abstractService = this;

                // Gets the already defined slug. If already one, dont update it automatically.
                var currentSlug = _.get(abstractService.service.getCurrent(abstractService.activeListKey),
                    'slug[' + lang + ']');

                if (angular.isUndefined(abstractService.tmpSlug)) {
                    abstractService.tmpSlug = {};
                }

                /*
                 * Keep track of slugs that were empty at some point between two saves.
                 * When the user empty the slug, it must be calculated automatically again.
                 */
                if (angular.isUndefinedOrEmpty(abstractService.tmpSlug[lang])) {
                    abstractService.wasEmpty[lang] = true;
                }

                if (angular.isDefinedAndFilled(currentSlug) && !abstractService.wasEmpty[lang]) {
                    return;
                }

                var newSlug = Utils.slugify(title);

                if (newSlug !== abstractService.tmpSlug[lang] &&
                    abstractService.wasEmpty[lang]) {
                    abstractService.tmpSlug[lang] = newSlug;
                }
            }

            /////////////////////////////

            AbstractContentForm.prototype.checkContent = checkContent;
            AbstractContentForm.prototype.checkContentFeeds = checkContentFeeds;
            AbstractContentForm.prototype.checkContentSlug = checkContentSlug;
            AbstractContentForm.prototype.checkContentTitle = checkContentTitle;
            AbstractContentForm.prototype.checkContentExternalLink = checkContentExternalLink;
            AbstractContentForm.prototype.checkTemplateContentFeeds = checkTemplateContentFeeds;
            AbstractContentForm.prototype.getTmpSlug = getTmpSlug;
            AbstractContentForm.prototype.resetContentForm = resetContentForm;
            AbstractContentForm.prototype.saveContent = saveContent;
            AbstractContentForm.prototype.setActiveListKey = setActiveListKey;
            AbstractContentForm.prototype.setSlugFromTitle = setSlugFromTitle;
            AbstractContentForm.prototype.setTmpSlug = setTmpSlug;

            /////////////////////////////

            return AbstractContentForm;
        })();

        /////////////////////////////

        /**
         * Instantiate an abstract content form service.
         *
         * @param  {Object}              content The current content service.
         * @return {AbstractContentForm} The newly created abstract content form service.
         */
        function createAbstractContentFormService(content) {
            return new AbstractContentForm(content);
        }

        /////////////////////////////

        return {
            createAbstractContentFormService: createAbstractContentFormService,
        };
    }

    /////////////////////////////

    angular.module('Services').service('AbstractContentForm', AbstractContentFormService);
})();
