import { Path, Editor, ReactEditor } from '@lumapps/wrex/slate';
import { getNode } from '@lumapps/wrex/slate/utils/getNode';

import { ImageEditor, ImageGroupElement } from '../types';
import { changeImageAlignment } from './changeImageAlignment';
import { isImageGroup } from './isImageGroup';

/**
 * Change the widget for the image group
 */
export const changeImageWidth = (editor: ReactEditor & ImageEditor, path: Path, width: ImageGroupElement['width']) => {
    if (!width || !isImageGroup(getNode(editor, path)?.[0])) {
        return;
    }
    const pathRef = Editor.pathRef(editor, path);

    // Remove the alignment (if any)
    changeImageAlignment(editor, path, undefined);

    // Set width attribute
    const imageGroupAttributes: Partial<ImageGroupElement> = { width };
    editor.setNodes(imageGroupAttributes, { at: pathRef.unref() as Path });
};
