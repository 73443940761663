import React from 'react';

import { Button, Emphasis, AlertDialog, Kind, ButtonProps, AlertDialogProps, Text } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

export interface DiscardAlertDialogProps
    extends Omit<AlertDialogProps, 'isOpen' | 'parentElement' | 'cancelProps' | 'onClose'> {
    confirmProps?: Omit<AlertDialogProps['confirmProps'], 'onClick'>;
    cancelProps?: Omit<AlertDialogProps['cancelProps'], 'onClick'>;
    children?: React.ReactNode;
}

export interface DiscardButtonProps extends ButtonProps {
    /* Discard button callback */
    onDiscard?: () => void;
    /** Props to forward to the dialog */
    dialogProps?: DiscardAlertDialogProps;
}

/**
 * Reusable component that combines a Button and an AlertDialog in order to display a button
 * that allows the user to discard an action, demanding confirmation before doing so.
 * @family Buttons
 * @param DiscardButtonProps
 * @returns DiscardButton
 */
export const DiscardButton: React.FC<DiscardButtonProps> = React.forwardRef(
    ({ onDiscard, dialogProps = {}, children, ...buttonProps }, ref) => {
        const { translateKey } = useTranslate();
        const discardRef = React.useRef(null);
        const [isDiscardModalOpen, setIsDiscardModalOpen] = React.useState(false);

        const { cancelProps, confirmProps, children: dialogChildren, ...otherDialogProps } = dialogProps;

        const onDiscardConfirmed = () => {
            if (onDiscard) {
                onDiscard();
            }

            setIsDiscardModalOpen(false);
        };

        const isChildrenStringOrEmpty = !dialogChildren || typeof dialogChildren === 'string';

        return (
            <>
                <Button
                    emphasis={Emphasis.low}
                    onClick={() => setIsDiscardModalOpen(true)}
                    ref={mergeRefs([discardRef, ref])}
                    {...buttonProps}
                >
                    {children || translateKey(GLOBAL.DISCARD)}
                </Button>

                <AlertDialog
                    title={translateKey(GLOBAL.DISCARD_CHANGES_CONFIRM)}
                    kind={Kind.warning}
                    {...otherDialogProps}
                    isOpen={isDiscardModalOpen}
                    parentElement={discardRef}
                    confirmProps={{
                        label: translateKey(GLOBAL.DISCARD),
                        ...confirmProps,
                        onClick: onDiscardConfirmed,
                    }}
                    cancelProps={{
                        label: translateKey(GLOBAL.KEEP_EDITING),
                        ...cancelProps,
                        onClick: () => setIsDiscardModalOpen(false),
                    }}
                    onClose={() => setIsDiscardModalOpen(false)}
                >
                    {isChildrenStringOrEmpty ? (
                        <Text as="p" typography="body1">
                            {dialogChildren || translateKey(GLOBAL.WARNING_UNSAVED_CHANGES)}
                        </Text>
                    ) : (
                        dialogChildren
                    )}
                </AlertDialog>
            </>
        );
    },
);
