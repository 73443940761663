import React from 'react';

import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiClose } from '@lumapps/lumx/icons';
import { Chip, ChipGroup, Icon, Text, Tooltip } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useId } from '@lumapps/utils/hooks/useId';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { COMBOBOX } from '../../keys';
import { BaseSelectProps, MultipleSelection } from '../../types';
import { getWithSelector } from '../../utils/getWithSelector';

import './index.scss';

type SelectionProps = Pick<MultipleSelection, 'onChange' | 'value'>;
type Selectors = Pick<BaseSelectProps, 'getOptionId' | 'getOptionName'>;

type SelectionChipsProps = SelectionProps &
    Selectors & {
        /**
         * Ref of the last chip
         */
        lastChipRef?: React.Ref<HTMLElement>;
        /**
         * Input ref to restore focus
         */
        inputRef?: React.RefObject<HTMLInputElement>;
        /**
         * Input label, used to generate the chip group aria description
         */
        inputLabel?: string;
        /**
         * Props applied to each chip.
         */
        chipProps?: MultipleSelection['chipProps'];
        /** scope for tracking purposes */
        scope?: string;
    };
const CLASSNAME = 'selection-chip-group';

/**
 * Selection chip group listing currenlty selected options in a chip list.
 *
 * Remove value on:
 * - Activated (click or Enter pressed)
 * - Backspace pressed (also moves focus on previous chip or the input)
 */
export const SelectionChipGroup: React.FC<SelectionChipsProps> = ({
    onChange,
    value,
    getOptionId,
    getOptionName,
    lastChipRef,
    inputRef,
    inputLabel,
    chipProps,
    scope = CLASSNAME,
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope);
    const chipRefs = React.useRef<React.RefObject<HTMLElement>[]>([]);
    const chipGroupTitle = useId();

    if (!value) {
        // We must render something or else the DS TextField changes the DOM layout and replaces the input which can
        // break some of our logic
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
    return (
        <div className={block()}>
            <Text as="p" id={chipGroupTitle} className={visuallyHidden()}>
                {translateAndReplace(COMBOBOX.SELECTED_VALUES_LIST_LABEL, {
                    SELECT_LABEL: inputLabel || translateKey(GLOBAL.VALUES),
                })}
            </Text>
            <ChipGroup role="list" aria-describedby={chipGroupTitle} className={element('chip-group')}>
                {value.map((v, index) => {
                    const name = getWithSelector(getOptionName, v);
                    const id = getWithSelector(getOptionId, v);
                    const onClick = () => {
                        const newValue = [...value];
                        const existingIndex = value.findIndex((v) => getWithSelector(getOptionId, v) === id);
                        if (existingIndex === -1) {
                            return;
                        }
                        // Remove value
                        newValue.splice(existingIndex, 1);

                        onChange?.(newValue);
                    };
                    const onKeyDown = (evt: React.KeyboardEvent) => {
                        if (evt.key !== 'Backspace') {
                            return;
                        }
                        // Activate (remove value) on Backspace pressed
                        onClick();

                        const previousChip = chipRefs.current[index - 1]?.current;
                        const input = inputRef?.current;
                        // Focus the previous chip or the input
                        (previousChip || input)?.focus();
                    };

                    if (!chipRefs.current[index]) {
                        chipRefs.current[index] = React.createRef<HTMLElement>();
                    }
                    let ref: React.Ref<HTMLElement> | undefined = chipRefs.current[index];

                    if (index === value.length - 1) {
                        // Set ref for the last chip
                        ref = mergeRefs([ref, lastChipRef]);
                    }

                    const calculatedChipProps = typeof chipProps === 'function' ? chipProps({ name, id }) : chipProps;

                    return (
                        <Tooltip key={id} label={`${name} - ${translateKey(GLOBAL.REMOVE)}`}>
                            <Chip
                                size="s"
                                after={<Icon icon={mdiClose} />}
                                className={element('chip')}
                                {...get({ element: 'chip' })}
                                {...calculatedChipProps}
                                ref={ref as any}
                                onClick={onClick}
                                onKeyDown={onKeyDown}
                            >
                                {name}{' '}
                                <Text as="span" className={visuallyHidden()}>
                                    - {translateKey(GLOBAL.REMOVE)}
                                </Text>
                            </Chip>
                        </Tooltip>
                    );
                })}
            </ChipGroup>
        </div>
    );
};
