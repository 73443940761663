(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserFavorite(Analytics, Content, Instance, LumsitesBaseService, LxNotificationService, Translation, User,
        UserFavoriteFactory) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(UserFavoriteFactory, {
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            postSave: _postSave,
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Default parameters for the user favorite service.
         * Define the current instance and the user language.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Delete user favorite, call del from base service but update user local list.
         *
         * @param {string}   key     Element key to delete.
         * @param {Function} [cb]    Function called when delete succeed.
         * @param {Function} [errCb] Function called when delete failed.
         * @param {string}   listKey The list key identifier.
         */
        function _delete(key, cb, errCb, listKey) {
            var user = User.getConnected();
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            LumsitesBaseService.proto.prototype.del.call(service, key, function deleteFavorite() {
                // Apply same logic as in backend.
                var favIdx = _.get(user, 'directoryFavorites', []).indexOf(key);

                if (favIdx >= 0) {
                    // Remove from hidden list.
                    user.directoryFavorites.splice(favIdx, 1);
                } else if (!_.includes(user.hiddenDirectoryFavorites, key)) {
                    user.hiddenDirectoryFavorites.push(key);
                }

                cb(key);
            }, errCb, listKey);
        }

        /**
         * Update connected user with favorite directory entry.
         *
         * @param  {Object} directoryEntry The entry updated from server.
         * @return {Object} The updated directory entry.
         */
        function _postSave(directoryEntry) {
            var user = User.getConnected();
            var hiddenIdx = _.get(user, 'hiddenDirectoryFavorites', []).indexOf(directoryEntry.id);

            if (hiddenIdx >= 0) {
                // Remove from hidden list.
                user.hiddenDirectoryFavorites.splice(hiddenIdx, 1);
            } else if (!_.includes(user.directoryFavorites, directoryEntry.id)) {
                if (angular.isUndefined(user.directoryFavorites)) {
                    user.directoryFavorites = [];
                }
                user.directoryFavorites.push(directoryEntry.id);
            }

            return directoryEntry;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if a directory entry is in user's favorites.
         *
         * @param  {Object}  entry A directory entry to be checked.
         * @return {boolean} Whether the directory entry is in the user favorites or not.
         */
        function isFavorite(entry) {
            var user = User.getConnected();

            if (angular.isUndefinedOrEmpty([entry, user], 'some')) {
                return false;
            }

            var entryIsInFavoriteFeedKeys = false;
            var feedIds = _.map(user.subscriptions, 'feed');

            if (angular.isDefinedAndFilled(_.intersection(entry.isInFavoriteFeedKeys || [], feedIds))) {
                entryIsInFavoriteFeedKeys = true;
            }

            return ((entryIsInFavoriteFeedKeys && !_.includes(user.hiddenDirectoryFavorites, entry.id)) ||
                _.includes(user.directoryFavorites, entry.id));
        }

        /**
         * Toggle user favorite, Add or remove the directory entry from user favorites.
         *
         * @param {Object}   entry     The directory entry we try to add or remove from favorites.
         * @param {Object}   directory The directory containing the entry.
         * @param {Function} [cb]      The function call if toogle succeed
         * @param {Function} [errCb]   The function called if toggle failed.
         * @param {string}   listKey   The identifer of the entry list.
         */
        function toggleFavorite(entry, directory, cb, errCb, listKey) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            // TODO[Maxime]: remove Content from service and pass it through params.
            listKey = listKey || entry.directory;

            var favorite = service.isFavorite(entry);
            var action = (favorite) ? 'remove' : 'add';

            Analytics.handleTaggingMap(action + '-favorite', 'directory-entry', {
                directory: entry.directory,
                isHomePage: (Content.getCurrent()) ? Boolean(Content.getCurrent().isHomepage) : false,
                item: entry,
            });

            if (favorite) {
                _delete(entry.id, function deleteFavorite() {
                    // FIXME[Elie]: remove LxNotificationService from service.
                    LxNotificationService.info(Translation.translate('USER_FAVORITES_DELETE'));
                }, errCb, listKey);
            } else {
                // Call service save.
                service.save({
                    uid: entry.id,
                }, cb, errCb, listKey);
            }
        }

        /////////////////////////////

        service.isFavorite = isFavorite;
        service.toggleFavorite = toggleFavorite;

        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                instance: Instance.getCurrentInstanceId(),
                lang: Translation.getLang('current'),
            };
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('UserFavorite', UserFavorite);
})();
