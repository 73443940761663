/* eslint-disable import/order */
// Google
import googleCalendar from '@lumapps/assets/google-apps/google_icon_calendar.svg';
import googleChat from '@lumapps/assets/google-apps/google_icon_chat.svg';
import googleContacts from '@lumapps/assets/google-apps/google_icon_contacts.svg';
import googleCurrents from '@lumapps/assets/google-apps/google_icon_currents.svg';
import googleDocs from '@lumapps/assets/google-apps/google_icon_docs.svg';
import googleDrive from '@lumapps/assets/google-apps/google_icon_drive.svg';
import googleGmail from '@lumapps/assets/google-apps/google_icon_gmail.svg';
import googleMeet from '@lumapps/assets/google-apps/google_icon_meet.svg';
import googleSearch from '@lumapps/assets/google-apps/google_icon_search.svg';
import googleSheet from '@lumapps/assets/google-apps/google_icon_sheets.svg';
import googleSites from '@lumapps/assets/google-apps/google_icon_sites.svg';
import googleSlides from '@lumapps/assets/google-apps/google_icon_slides.svg';

// Microsoft
import msCalendar from '@lumapps/assets/microsoft-apps/ms_icon_calendar.svg';
import msDelve from '@lumapps/assets/microsoft-apps/ms_icon_delve.svg';
import msExcel from '@lumapps/assets/microsoft-apps/ms_icon_excel.svg';
import msForms from '@lumapps/assets/microsoft-apps/ms_icon_forms.svg';
import msOneDrive from '@lumapps/assets/microsoft-apps/ms_icon_onedrive.svg';
import msOneNote from '@lumapps/assets/microsoft-apps/ms_icon_onenote.svg';
import msOutlook from '@lumapps/assets/microsoft-apps/ms_icon_outlook.svg';
import msPeople from '@lumapps/assets/microsoft-apps/ms_icon_people.svg';
import msPlanner from '@lumapps/assets/microsoft-apps/ms_icon_planner.svg';
import msPowerpoint from '@lumapps/assets/microsoft-apps/ms_icon_powerpoint.svg';
import msSharePoint from '@lumapps/assets/microsoft-apps/ms_icon_sharepoint.svg';
import msTeams from '@lumapps/assets/microsoft-apps/ms_icon_teams.svg';
import msTodo from '@lumapps/assets/microsoft-apps/ms_icon_todo.svg';
import msWord from '@lumapps/assets/microsoft-apps/ms_icon_word.svg';
import msYammer from '@lumapps/assets/microsoft-apps/ms_icon_yammer.svg';

export const BOOKMARKS_GOOGLE_ICONS = {
    googleCalendar,
    googleChat,
    googleContacts,
    googleDocs,
    googleDrive,
    googleGmail,
    googleMeet,
    googleCurrents,
    googleSearch,
    googleSheet,
    googleSites,
    googleSlides,
};

export const BOOKMARKS_MS_ICONS = {
    msCalendar,
    msDelve,
    msExcel,
    msForms,
    msOneNote,
    msOutlook,
    msPeople,
    msPlanner,
    msPowerpoint,
    msTeams,
    msTodo,
    msWord,
    msYammer,
    msOneDrive,
    msSharePoint,
};

export const BOOKMARKS_ICONS = {
    ...BOOKMARKS_GOOGLE_ICONS,
    ...BOOKMARKS_MS_ICONS,
};
