/* global angular */
import get from 'lodash/get';
import set from 'lodash/set';

import { get as getDocument } from 'components/components/document/api/documentApi.js';

import { documentTypes, PROVIDERS } from 'front/modules/media/js/media_constant';

import { getAttributes } from '@lumapps/data-attributes';

function WidgetDriveFilePreviewSettingsController(
    $rootScope,
    $timeout,
    $window,
    Document,
    ReusableMediaPicker,
    Translation,
    Widget,
) {
    'ngInject';

    const widgetDriveFilePreviewSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Flag to reset radio buttons when deleting a file.
     *
     * @type {boolean}
     */
    let _onDelete = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Whether to display a warning regarding mixed https & http content.
     *
     * @type {boolean}
     */
    widgetDriveFilePreviewSettings.displayHttpsWarning = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    widgetDriveFilePreviewSettings.Translation = Translation;
    widgetDriveFilePreviewSettings.Widget = Widget;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Updates widget when url or height is changed.
     */
    function updateWidget() {
        $timeout(() => {
            $rootScope.$broadcast('widget-iframe-settings', Widget.getCurrent().uuid);
        });
    }

    /**
     * Deletes selected file by clearing all properties.
     *
     * @param {Object} target The file to delete.
     */
    function deleteSelectedFile(target) {
        const currentWidget = Widget.getCurrent();
        const properties = get(currentWidget, 'properties');

        if (angular.isUndefined(properties) || angular.isUndefinedOrEmpty(target)) {
            return;
        }

        properties[`${target}Id`] = undefined;
        properties[`${target}Name`] = undefined;
        properties.docPath = undefined;
        properties.height = undefined;

        if (properties.titleType === 'useFile') {
            set(currentWidget, `title[${Translation.inputLanguage}]`, undefined);
        }
        if (properties.linkType === 'useFileLink') {
            set(properties, `more.link[${Translation.inputLanguage}]`, undefined);
        }

        _onDelete = true;
        widgetDriveFilePreviewSettings.updateWidget();
        widgetDriveFilePreviewSettings.init();
        _onDelete = false;
    }

    /**
     * Triggered when a file has been selected.
     *
     * @param {Object} doc    The file selected by the user.
     * @param {Object} target In this case is "picked" given by the API.
     */
    function drivePickerCallback(doc, target) {
        const { docPath, id } = doc;
        const mediaContent = Document.getMediaContentByLang(doc, true);
        const { name: fileName } = mediaContent;

        if (angular.isDefinedAndFilled(doc) && angular.isDefinedAndFilled(target)) {
            const currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};

            const { properties } = currentWidget;

            getDocument({docPath}).then((document) => {
                const content = document.data.content[0];
                properties.pickedUrl = content.servingUrl;
            });

            properties[`${target}Id`] = id;
            properties.docPath = docPath;
            properties[`${target}Name`] = fileName;
        }

        widgetDriveFilePreviewSettings.updateWidget();
    }

    /**
     * Sets file name as title of the widget.
     */
    function fileAsTitle() {
        const currentWidget = Widget.getCurrent();

        set(currentWidget, `title.${Translation.inputLanguage}`, currentWidget.properties.pickedName);
        widgetDriveFilePreviewSettings.updateWidget();
    }

    /**
     * Sets link to file as link of the widget.
     */
    function fileAsLink() {
        const currentWidgetProperties = Widget.getCurrent().properties;

        if (!currentWidgetProperties.pickedUrl) {
            getDocument({docPath: currentWidgetProperties.docPath}).then((document) => {
                const content = document.data.content[0];
                currentWidgetProperties.pickedUrl = content.servingUrl;
                set(currentWidgetProperties, `more.link.${Translation.inputLanguage}`, currentWidgetProperties.pickedUrl);
                widgetDriveFilePreviewSettings.updateWidget();
            });
        } else {
            set(currentWidgetProperties, `more.link.${Translation.inputLanguage}`, currentWidgetProperties.pickedUrl);
            widgetDriveFilePreviewSettings.updateWidget();
        }
    }

    /**
     * Retrieves the id of the file.
     *
     * @param  {Object} target The file we want to get the id of.
     * @return {string} File id if there is one.
     */
    function getFileId(target) {
        if (angular.isUndefinedOrEmpty(target)) {
            return undefined;
        }

        return Widget.getCurrent().properties[`${target}Id`];
    }

    /**
     * Gets the file name, if no file name, return the file id.
     *
     * @param  {Object} target The file we want to get the name of.
     * @return {string} The file name if there is one.
     */
    function getFileName(target) {
        if (angular.isUndefinedOrEmpty(target)) {
            return undefined;
        }

        const currentWidget = Widget.getCurrent();
        const currentWidgetProperties = get(Widget.getCurrent(), 'properties', {});

        if (angular.isDefinedAndFilled(currentWidgetProperties[`${target}Name`])) {
            currentWidgetProperties.title = currentWidgetProperties[`${target}Name`];

            return currentWidgetProperties[`${target}Name`];
        }

        return currentWidget.properties[`${target}Id`];
    }

    /**
     * Retrieves the file url.
     *
     * @param  {Object} target The file.
     * @return {string} The file id if there is one.
     */
    function getFileUrl(target) {
        if (angular.isUndefinedOrEmpty(target)) {
            return undefined;
        }

        const currentWidgetProperties = Widget.getCurrent().properties;
        if (angular.isDefinedAndFilled(currentWidgetProperties[`${target}Url`])) {
            currentWidgetProperties.url = {};
            currentWidgetProperties.url[Translation.inputLanguage] = currentWidgetProperties[`${target}Url`];

            return currentWidgetProperties[`${target}Url`];
        }

        return currentWidgetProperties[`${target}Url`];
    }

    /**
     * Open selected document in a new tab.
     */
    async function openDocumentInNewTab() {
        const { docPath } = Widget.getCurrent().properties;

        if (!docPath) {
            return;
        }
        const doc = await getDocument({ docPath });
        const content = doc.data.content[0];

        $window.open(content.servingUrl, '_blank');
    }

    /**
     * Opens the media picker.
     */
    function openMediaPicker() {
        const onChange = (selection) => {
            drivePickerCallback(selection.object, 'picked');
        };
        const options = {
            allowedFileTypes: documentTypes.all,
            allowedProviders: [PROVIDERS.microsoft, PROVIDERS.google],
            isMediaModel: false,
        };

        ReusableMediaPicker.open(undefined, onChange, options);
    }

    /**
     * Checks if a file/ file id is set else clear the folder property.
     *
     * @param  {Object}  target The file.
     * @return {boolean} Whether or not the file is selected.
     */
    function isFileSelected(target) {
        if (angular.isUndefined(target)) {
            return undefined;
        }

        const currentWidget = Widget.getCurrent();
        currentWidget.properties = currentWidget.properties || {};

        const currentWidgetProperties = currentWidget.properties;

        if (angular.isDefined(currentWidgetProperties[`${target}Id`])) {
            return true;
        }

        currentWidgetProperties[`${target}Id`] = undefined;
        currentWidgetProperties[`${target}Name`] = undefined;

        return false;
    }

    /**
     * Check if the current widget has a title.
     *
     * @return {boolean} Whether or not current widget has a title.
     */
    function hasCurrentWidgetTitle() {
        return Widget.shouldDisplayWidgetHeader(Widget.getCurrent().title);
    }

    /////////////////////////////

    const getWidgetAttributes = getAttributes('widget');
    widgetDriveFilePreviewSettings.collapsedByDefaultActionAttributes = getWidgetAttributes({
        element: 'settings',
        action: 'collapsed-default-checkbox',
    });
    widgetDriveFilePreviewSettings.enableCollapseActionAttributes = getWidgetAttributes({
        element: 'settings',
        action: 'collapsible-checkbox',
    });

    /////////////////////////////

    widgetDriveFilePreviewSettings.deleteSelectedFile = deleteSelectedFile;
    widgetDriveFilePreviewSettings.fileAsLink = fileAsLink;
    widgetDriveFilePreviewSettings.fileAsTitle = fileAsTitle;
    widgetDriveFilePreviewSettings.getFileId = getFileId;
    widgetDriveFilePreviewSettings.getFileName = getFileName;
    widgetDriveFilePreviewSettings.getFileUrl = getFileUrl;
    widgetDriveFilePreviewSettings.isFileSelected = isFileSelected;
    widgetDriveFilePreviewSettings.openDocumentInNewTab = openDocumentInNewTab;
    widgetDriveFilePreviewSettings.openMediaPicker = openMediaPicker;
    widgetDriveFilePreviewSettings.updateWidget = updateWidget;
    widgetDriveFilePreviewSettings.hasCurrentWidgetTitle = hasCurrentWidgetTitle;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    widgetDriveFilePreviewSettings.init = () => {
        const currentWidget = Widget.getCurrent();
        currentWidget.properties = currentWidget.properties || {};

        const currentWidgetProperties = currentWidget.properties;

        if (_onDelete === true) {
            currentWidgetProperties.titleType = 'addOwnTitle';
            currentWidgetProperties.linkType = 'addOtherLink';
        } else {
            currentWidgetProperties.titleType = currentWidgetProperties.titleType || 'addOwnTitle';
            currentWidgetProperties.linkType = currentWidgetProperties.linkType || 'addOtherLink';
        }
    };

    widgetDriveFilePreviewSettings.init();
}

/////////////////////////////

angular
    .module('Controllers')
    .controller('WidgetDriveFilePreviewSettingsController', WidgetDriveFilePreviewSettingsController);

export { WidgetDriveFilePreviewSettingsController };
