import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Member } from '../../types';

export enum MemberBarStates {
    intitial = 'intitial',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface MemberBarState {
    state: MemberBarStates.loading;
    members: Member[];
    totalMembers: number;
}

const initialState: MemberBarState = {
    state: MemberBarStates.loading,
    members: [],
    totalMembers: 0,
};

type FetchPayload = {
    members: Member[];
    totalMembers: number;
};

export const membersReducers = {
    fetch: (state: MemberBarState): void => {
        set(state, 'state', MemberBarStates.loading);
    },
    fetchSuccess: (state: MemberBarState, action: PayloadAction<FetchPayload>): void => {
        set(state, 'members', action.payload.members);
        set(state, 'totalMembers', action.payload.totalMembers);
        set(state, 'state', MemberBarStates.success);
    },
    fetchFailure: (state: MemberBarState): void => {
        set(state, 'state', MemberBarStates.error);
    },
};

const { actions, reducer } = createSlice({
    domain: 'SAMembers/MemberBar',
    initialState,
    reducers: membersReducers,
});

export { actions, reducer, initialState };
