import { VIEW_MODES_VALUES, VIEW_MODE_VARIANTS_VALUES } from '@lumapps/widget-base/constants';
import {
    BaseSingleContainerBlock,
    BlockGrid,
    BlockList,
    BlockNoResults,
    ContainerBlockVariant,
    LegacyWidgetProperties,
    UserReference,
} from '@lumapps/widget-base/types';
import type { BackgroundStyles, BorderStyles, InnerSpacingStyles } from '@lumapps/widget-styles/types';

export interface User extends UserReference {
    firstName: string;
}

export interface FeedHeader {
    type: 'BlockPersonalFeedPreviewHeader';
    user: User;
    isFeedButtonDisplayed: boolean;
}

export interface FeedFooter {
    type: 'BlockPersonalFeedPreviewFooter';
}

export interface BlockPersonalFeedPreview extends BaseSingleContainerBlock {
    // We need to specify the children type so we can inject props to it in React.cloneElement()
    children: React.ReactElement<BlockList | BlockGrid | BlockNoResults>;
    feedFooter?: FeedFooter;
    feedHeader?: FeedHeader;
    type: 'BlockPersonalFeedPreview';
    variant: ContainerBlockVariant;
    /** contentStyles computed and passed from component WidgetContent down to this Block via renderBlockTree */
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;
}

/** Possibles values for the `fields` property of the widget personal feed preview */
export type WidgetPersonalFeedPreviewFields = 'contextual_banner' | 'title' | 'excerpt' | 'reactions';

/** Specific properties of the widget personal feed preview */
export type WidgetPersonalFeedPreviewPropertiesType = LegacyWidgetProperties & {
    siteId?: string;
    maxNumber: number;
    displayHeader: boolean;
    fields: Array<WidgetPersonalFeedPreviewFields>;
    viewMode: 'list' | 'grid';
    viewModeVariant: 'grouped' | 'ungrouped';
};

/**
 * Available view modes for the widget in the style settings. Used to create the associated type as well
 * as a non const version of the array
 */
const WIDGET_PERSONAL_FEED_PREVIEW_LIST_VIEW_MODES_AS_CONST = [VIEW_MODES_VALUES.LIST, VIEW_MODES_VALUES.GRID] as const;

/** Type of the possible viewModes for the widget personal feed preview styles settings */
export type WidgetPersonalFeedPreviewListViewModeType =
    (typeof WIDGET_PERSONAL_FEED_PREVIEW_LIST_VIEW_MODES_AS_CONST)[number];

/** Array (not readonly) const to be passed as a props to ListWidgetStyleSetting */
export const WIDGET_PERSONAL_FEED_PREVIEW_LIST_VIEW_MODES = [...WIDGET_PERSONAL_FEED_PREVIEW_LIST_VIEW_MODES_AS_CONST];

/** Available view mode variants for the widget in the style settings */
export type WidgetPersonalFeedPreviewListViewModeVariantType =
    | VIEW_MODE_VARIANTS_VALUES.GROUPED
    | VIEW_MODE_VARIANTS_VALUES.UNGROUPED;
