/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { Library, STATUS, useInjectLibrary, UseInjectLibraryOptions } from './useInjectLibrary';

/**
 * List of available libraries that we currently have.
 */
export enum EXTERNAL_LIBRARIES {
    GOOGLE_API = 'google',
}

const libraries: Record<EXTERNAL_LIBRARIES, { url: string; status: STATUS }> = {
    [EXTERNAL_LIBRARIES.GOOGLE_API]: {
        url: 'https://apis.google.com/js/api.js',
        status: STATUS.INITIAL,
    },
};

export interface UseExternalLibraryOptions extends Omit<UseInjectLibraryOptions, 'library' | 'shouldImportCss'> {
    /**
     * Library to download.
     */
    lib: EXTERNAL_LIBRARIES;
}

/**
 * Hook that retrieves a specific library from internet in order to lazy load it into our frontend app.
 * The idea behind this is to avoid installing, importing and bundle manage these libraries, knowing that
 * we can just easily download them from their own CDN and use them as any other library.
 * @param options - use external lib options.
 */
export const useExternalLibrary = (options: UseExternalLibraryOptions) => {
    const { lib, onLoad, shouldLoadLibrary = true, onError } = options;
    const library: Library = { url: libraries[lib].url, status: libraries[lib].status };
    useInjectLibrary({
        onLoad,
        shouldLoadLibrary,
        onError,
        library,
    });
};

/**
 * ExternalLibrary component that allows to use the useExternalLibrary in a react-element context.
 * @param options - UseExternalLibraryOptions
 * @returns empty component.
 */
export const ExternalLibrary: React.FC<UseExternalLibraryOptions> = (options) => {
    useExternalLibrary(options);

    return null;
};
