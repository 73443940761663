/* eslint-disable prettier/prettier */
import React, { ReactNode } from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { Text } from '@lumapps/lumx/react';

interface ShortcutDescription {
    shortcuts: ReactNode;
    description: ReactNode;
}

export const ShortcutDescription = ({ shortcuts, description }: ShortcutDescription) => {
    return (
        <Text as="p" typography="body1">
            {/* Add a hidden colon to let screen readers mark a pause before reading the description  */}
            {shortcuts} <Text as="span" className={visuallyHidden()}>:</Text> {description}
        </Text>
    );
};
