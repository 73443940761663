import React, { useContext } from 'react';

import { AriaIdsRegisterContext } from '../context/AriaIdsRegisterContext';
import { AriaIdType, RegisterAriaIdPayload } from '../ducks/reducer';

/**
 * Register the given id or array of ids to the context in the corresponding aria type array.
 * Returns the register functions to allow asynchronous registering / un-registering
 *
 * Inspired by https://medium.com/gronda/managing-aria-attributes-with-react-hooks-a7470d572683
 */
export const useRegisterAriaId = (ariaType: AriaIdType, value?: RegisterAriaIdPayload['value']) => {
    const register = useContext(AriaIdsRegisterContext);
    /** Register the given options */
    React.useEffect(() => {
        const payload = { ariaType, value };
        if (value && register) {
            register.add(payload);
        }

        // Unregister ids if/when the component unmounts or if option changes
        return () => {
            if (value && register) {
                register.remove(payload);
            }
        };
    }, [ariaType, register, value]);

    return register;
};
