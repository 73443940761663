import produce from 'immer';

import {
    FETCH_CHAT_ERROR,
    FETCH_CHAT_LOADING,
    FETCH_CHAT_SUCCESS,
    FETCH_WORKSPACE_SUCCESS,
} from './workspaces_actions';

// Default state.
const defaultState = {
    list: {},
};

const reducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        const { meta, payload } = action;

        switch (action.type) {
            case FETCH_WORKSPACE_SUCCESS: {
                const { workspace } = payload;
                draft.list[workspace.id] = workspace;

                break;
            }
            case FETCH_CHAT_LOADING: {
                const workspace = draft.list[meta.id] || {};

                workspace.chat = undefined;
                draft.list[meta.id] = workspace;

                break;
            }
            case FETCH_CHAT_SUCCESS: {
                draft.list[meta.id] = {
                    chat: {
                        ...payload,
                    },
                };

                break;
            }
            case FETCH_CHAT_ERROR:
                break;

            default:
                break;
        }
    });

export { reducer };
