(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetFileManagementSettingsController($rootScope, $timeout, Content, InitialSettings, Widget) {
        'ngInject';

        var widgetSettingsCtrl = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The default folder when opening the widget.
         *
         * @type {string}
         */
        var _DEFAULT_FOLDER = 'root';

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The types of selection allowed for folders.
         *
         * @type {Object}
         */
        widgetSettingsCtrl.SELECTION_TYPE = {
            connect: 'connectFolder',
            currentCommunity: 'currentCommunity',
            select: 'selectFolder',
        };

        /**
         * If the widget is in a community context.
         */
        widgetSettingsCtrl.isCommunityContext = Content.getCurrent().type === InitialSettings.CONTENT_TYPES.COMMUNITY;

        /**
         * The string associated to the folder selection radio buttons.
         *
         * @type {string}
         */
        widgetSettingsCtrl.folderType = widgetSettingsCtrl.SELECTION_TYPE.select;

        /**
         * The string associated to the trash selection radio buttons.
         *
         * @type {string}
         */
        widgetSettingsCtrl.trashType = widgetSettingsCtrl.SELECTION_TYPE.select;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * The function to execute when an action (delete, main folder, ...) has been done in the drive picker.
         *
         * @param {Object} folderInfo The information of the folder on which the action has been done.
         * @param {string} action     The action that has been executed.
         *                            Possible values are: 'delete' (deletion of a folder) or 'folder' (selection of a
         *                            folder as the widget's main folder).
         */
        function _drivePickerCallback(folderInfo, action) {
            if (angular.isUndefined(Widget.getCurrent().properties)) {
                Widget.getCurrent().properties = {};
            }

            if (angular.isDefined(folderInfo) && angular.isDefined(action)) {
                // FIXME [elie]: remove when the TD picker is available.
                if (angular.isDefinedAndFilled(folderInfo.id)) {
                    Widget.getCurrent().properties[action + 'Id'] = folderInfo.id;
                    Widget.getCurrent().properties[action + 'Name'] = folderInfo.name;
                } else if (angular.isDefinedAndFilled(folderInfo.url)) {
                    Widget.getCurrent().properties[action + 'Id'] = folderInfo.url.split('/').pop();
                }
            }

            widgetSettingsCtrl.updateWidget();
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Clears the currently selected folder.
         */
        function clearFolder() {
            widgetSettingsCtrl.isFolderSelected('');
        }

        /**
         * Reset the selected folder.
         *
         * @param {string} target The name of the target folder to delete.
         */
        function deleteSelectedFolder(target) {
            var currentWidget = Widget.getCurrent();

            if (angular.isUndefinedOrEmpty(target) || angular.isUndefined(_.get(currentWidget, 'properties'))) {
                return;
            }

            _.set(currentWidget, 'properties.' + target + 'Id', undefined);
            _.set(currentWidget, 'properties.' + target + 'Name', undefined);
        }

        /**
         * The function to execute when a delete action has been done in the drive picker.
         *
         * @param {Object} folderInfo The information of the deleted folder.
         */
        function drivePickerCallbackDelete(folderInfo) {
            _drivePickerCallback(folderInfo, 'delete');
        }

        /**
         * The function to execute when a a folder has been picked in the drive picker as the widget's main folder.
         *
         * @param {Object} folderInfo The information of the main folder.
         */
        function drivePickerCallbackMain(folderInfo) {
            _drivePickerCallback(folderInfo, 'folder');
        }

        /**
         * Get the id of a folder.
         *
         * @param  {string} target The target folder we want the id of.
         * @return {string} The id of the folder.
         */
        function getFolderId(target) {
            if (angular.isUndefinedOrEmpty(target)) {
                return undefined;
            }

            return _.get(Widget.getCurrent(), 'properties.' + target + 'Id', undefined);
        }

        /**
         * Get the name of a folder.
         * If this folder has no name, then get its id.
         *
         * @param  {string} target The target folder we want the name of.
         * @return {string} The name or the id of the folder.
         */
        function getFolderName(target) {
            if (angular.isUndefinedOrEmpty(target)) {
                return undefined;
            }

            var properties = _.get(Widget.getCurrent(), 'properties', {});

            if (angular.isDefinedAndFilled(properties[target + 'Name'])) {
                return properties[target + 'Name'];
            }

            return widgetSettingsCtrl.getFolderId(target);
        }

        /**
         * Check if a folder's id is set (i.e. it has been selected), else clear this folder from the widget's
         * properties (i.e. remove it from selection).
         *
         * @param  {string}  target The target folder we want to check/clear.
         * @return {boolean} If the folder is selected or not.
         */
        function isFolderSelected(target) {
            if (angular.isDefinedAndFilled(widgetSettingsCtrl.getFolderId(target))) {
                return true;
            }

            var properties = _.get(Widget.getCurrent(), 'properties');

            if (angular.isDefined(properties)) {
                properties[target + 'Id'] = undefined;
                properties[target + 'Name'] = undefined;
            }

            return false;
        }

        /**
         * Set the widget's default folder.
         * If necessary, reset it to the default folder.
         *
         * @param {string} radio The value of the selected radio button.
         */
        function setFolder(radio) {
            var currentWidget = Widget.getCurrent();

            if (!_.get(currentWidget, 'properties.isRootFolder') && radio !== 'drive') {
                return;
            }

            _.set(currentWidget, 'properties.folderId', _DEFAULT_FOLDER);
            _.set(currentWidget, 'properties.folderName', undefined);

            widgetSettingsCtrl.updateWidget();
        }

        /**
         * Set the widget's trash folder.
         *
         * @param {string} radio The value of the selected radio button.
         */
        function setTrash(radio) {
            var currentWidget = Widget.getCurrent();

            if (!_.get(currentWidget, 'properties.classicTrash') && radio !== 'drive') {
                return;
            }

            _.set(currentWidget, 'properties.deleteId', undefined);
            _.set(currentWidget, 'properties.deleteName', undefined);

            widgetSettingsCtrl.updateWidget();
        }

        /**
         * Update the widget when the settings change.
         */
        function updateWidget() {
            $timeout(function delayUpdateEvent() {
                $rootScope.$broadcast('widget-file-management-settings', Widget.getCurrent().uuid);
            });
        }

        /**
         * On model change.
         * Set currentCommunityDrive to the right value, the attribute prevail on any other type in the widget.
         */
        function onChange() {
            var isCommunityDrive = widgetSettingsCtrl.folderType === widgetSettingsCtrl.SELECTION_TYPE.currentCommunity;

            if (isCommunityDrive) {
                updateWidget();
            }

            _.set(Widget.getCurrent(), 'properties.currentCommunityDrive', isCommunityDrive);
        }

        /**
         * Initialize the settings controller.
         */
        function init() {
            // If in a community and display current Community drive folder, set folderType as community.
            if (widgetSettingsCtrl.isCommunityContext &&
                _.get(Widget.getCurrent(), 'properties.currentCommunityDrive')) {
                widgetSettingsCtrl.folderType = widgetSettingsCtrl.SELECTION_TYPE.currentCommunity;
            }
        }

        init();

        /////////////////////////////

        widgetSettingsCtrl.clearFolder = clearFolder;
        widgetSettingsCtrl.deleteSelectedFolder = deleteSelectedFolder;
        widgetSettingsCtrl.drivePickerCallbackDelete = drivePickerCallbackDelete;
        widgetSettingsCtrl.drivePickerCallbackMain = drivePickerCallbackMain;
        widgetSettingsCtrl.getFolderName = getFolderName;
        widgetSettingsCtrl.getFolderId = getFolderId;
        widgetSettingsCtrl.isFolderSelected = isFolderSelected;
        widgetSettingsCtrl.setFolder = setFolder;
        widgetSettingsCtrl.setTrash = setTrash;
        widgetSettingsCtrl.updateWidget = updateWidget;
        widgetSettingsCtrl.onChange = onChange;
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetFileManagementSettingsController',
        WidgetFileManagementSettingsController);
})();
