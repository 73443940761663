export enum WIDGET_CONTACT_FORM {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172246371
     */
    SEND_ERROR = 'WIDGET_CONTACT_FORM.SEND_ERROR',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=172246784
     */
    SEND_SUCCESS = 'WIDGET_CONTACT_FORM.SEND_SUCCESS',
}
