import { CalendarConfig, CalendarViews, WeekDay } from '@lumapps/lumx-calendar/types';

import { LegacyCalendarProperties } from '../types';

/**
 * Extract the base configuration from the widget properties
 * and generate the Calendar component configuration Object.
 * */
export const getCalendarConfig = (
    { startTime, endTime, displayWeekend, startDayOnSunday, displayToolbar }: Partial<LegacyCalendarProperties>,
    locale: string,
): CalendarConfig => ({
    hours: {
        start: startTime ?? 0,
        end: endTime ?? 24,
        display: 'quarter',
    },
    week: {
        showWeekend: displayWeekend ?? false,
        firstDay: startDayOnSunday ? WeekDay.sunday : WeekDay.monday,
        weekend: new Set([WeekDay.saturday, WeekDay.sunday]),
    },
    locale,
    availableViews: Object.values(CalendarViews),
    hasToolbar: displayToolbar ?? false,
});
