import { GLOBAL, getServerError } from '@lumapps/translations';

import { ServiceServerError, LegacyServerError } from '../types';

/**
 * Translate server error into into lokalize keys.
 */
export function getLegacyServerError(err?: LegacyServerError) {
    if (!err || !err.data || !err.data.error) {
        return GLOBAL.GENERIC_ERROR;
    }

    if (err.data.error.message && err.data.error.message.match('^([A-Z_]+)$')) {
        return getServerError(err.data.error.message);
    }

    if (err.data.error.code === 403) {
        return GLOBAL.SERVER_ERROR_NOT_AUTHORIZED;
    }

    return GLOBAL.GENERIC_ERROR;
}

/**
 * Translate new services error into lokalize keys.
 */
export function getServiceServerError(err?: ServiceServerError) {
    if (!err || !err.data || !err.data.errors) {
        return GLOBAL.GENERIC_ERROR;
    }

    const error = err.data.errors && err.data.errors[0];

    if (error?.status === '400' && error?.code) {
        return error.code;
    }

    if (error?.status === '400' && error?.detail) {
        return error.detail;
    }

    if (error?.status === '403') {
        return GLOBAL.SERVER_ERROR_NOT_AUTHORIZED;
    }

    return GLOBAL.GENERIC_ERROR;
}

/**
 * Get data returned by new services error.
 */
export function getServerErrorData(err?: ServiceServerError) {
    if (!err || !err.data || !err.data.errors) {
        return undefined;
    }

    const error = err.data.errors && err.data.errors[0];

    return error;
}
