import { manageFollowingTask } from '../community_tasks';

/**
 * Actions types.
 */
const MANAGE_FOLLOWING_LOADING = 'community/MANAGE_FOLLOWING_LOADING';
const MANAGE_FOLLOWING_SUCCESS = 'community/MANAGE_FOLLOWING_SUCCESS';
const MANAGE_FOLLOWING_ERROR = 'community/MANAGE_FOLLOWING_ERROR';

/**
 * Dispatcher methods.
 * @param  {boolean} notify Sets true to follow, false to unfollow.
 * @param  {string}  uid    Community UID.
 * @return {void}    Returns.
 */
const dispatchManageFollowing = (notify, uid) => (dispatch) => {
    dispatch({ type: MANAGE_FOLLOWING_LOADING });

    return manageFollowingTask({ notify, uid })
        .then(({ payload } = {}) => {
            dispatch({
                payload,
                type: MANAGE_FOLLOWING_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: MANAGE_FOLLOWING_ERROR,
            });
        });
};

export { MANAGE_FOLLOWING_ERROR, MANAGE_FOLLOWING_LOADING, MANAGE_FOLLOWING_SUCCESS, dispatchManageFollowing };
