import React from 'react';

import isArray from 'lodash/isArray';

import { FILTERS, FILTER_TYPES } from '../components/Filters/config';
import { FiltersProps } from '../components/Filters/Filters';
import { ConnectedFiltersProps } from '../components/Filters/types';
import { FILTERS_MODE } from '../constants';
import { FilterHookApi } from '../types';

/**
 * Hook to control the Filters component.
 *
 * IMPORTANT: Only use this hook if `filters` is static.
 * @family Filters
 * @param ConnectedFiltersProps
 * @returns FiltersProps
 */
export const useFilters = ({
    isOpen = false,
    filters,
    onFilter,
    customFilters = {},
    mode = FILTERS_MODE.GROUPED,
    shouldTriggerFilterOnClearAll = true,
    onClearAllFilters,
}: ConnectedFiltersProps): FiltersProps => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(isOpen);

    React.useEffect(() => {
        setIsDropdownOpen(isOpen);
    }, [isOpen]);

    /**
     * Define a list of rendered filters that will be displayed inside the `FiltersDropdown`
     */
    const filtersToRender: React.ReactNode[] = [];
    /**
     * This is basically the result of each hook associated to each filter.
     */
    const FiltersHooks: FilterHookApi[] = [];

    /**
     * For each of the filters provided, we need to render the desired component as well as
     * executing the necessary setup for initialising it.
     */
    filters.forEach((filter) => {
        const FilterType = FILTERS[filter.type as FILTER_TYPES] || customFilters[filter.type];

        if (FilterType) {
            /**
             * Here we are iterating each filter and execute hooks in a for each. This is normally
             * forbidden since it can tamper with React re-renders. That is why the `filters` prop
             * needs to be static.
             */
            const filterHook = FilterType.hook(filter.defaults);

            filtersToRender.push(
                <FilterType.Component
                    key={filter.id}
                    {...(filter.config || {})}
                    {...filterHook}
                    id={filter.id}
                    mode={mode}
                />,
            );
            FiltersHooks.push(filterHook);
        }
    });

    const renderSelectedFilter = (hook: any, index: number) => {
        /**
         * For each of the filters, we need to render the selected values.
         * Each of them can be different, so what we do is use the `renderSelected`
         * function configured for each of them.
         */
        const { renderSelected = (entity) => entity } = filters[index];

        if (hook.selected) {
            if (isArray(hook.selected)) {
                return hook.selected ? hook.selected.map(renderSelected) : [];
            }

            return hook.selected ? [renderSelected(hook.selected)] : [];
        }

        return [];
    };

    const onFiltering = (clearedId?: string) => {
        /**
         * When filtering, we need to retrieve the selected values for each of
         * the created filters, and create an object in order to return it to
         * parent component. Using `any` since it could be pretty much anything.
         */
        const selection: Record<string, any> = {};

        FiltersHooks.forEach((hook, index) => {
            const { id } = filters[index];

            if (clearedId === id) {
                hook.onClearSelected();
                selection[id] = undefined;
            } else {
                selection[id] = hook.onFilter();
            }
        });

        if (onFilter) {
            onFilter(selection);
        }
    };

    const onClearAll = (closeDropdown = false) => {
        FiltersHooks.forEach((hook) => {
            hook.onClearSelected();
        });

        if (onFilter && shouldTriggerFilterOnClearAll) {
            onFilter({});
        }

        if (closeDropdown) {
            setIsDropdownOpen(false);
        }
    };

    return {
        mode,
        isDropdownOpen,
        onFiltering,
        FiltersHooks,
        renderSelectedFilter,
        setIsDropdownOpen,
        onClearAll,
        onClearAllFilters,
        filtersToRender,
    };
};
