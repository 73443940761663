import { Editor } from 'slate';
import { HistoryEditor } from 'slate-history';

/**
 * Run callback without slate history if history plugin is installed or simply run the callback otherwise.
 */
export function withoutHistory(editor: Editor, callback: () => void) {
    if (HistoryEditor.isHistoryEditor(editor)) {
        HistoryEditor.withoutSaving(editor as HistoryEditor, callback);
    } else {
        callback();
    }
}
