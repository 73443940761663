import { isFeatureEnabled } from '@lumapps/features';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { TagzState } from './types';

const folksonomySelector = (state: FrontOfficeStore): TagzState => state.folksonomy;

const isLoadingSuggestionsSelector = createSelector(
    folksonomySelector,
    (folksonomy) => folksonomy.isLoadingSearchTagSuggestions,
);

const searchTagSuggestionsSelector = createSelector(
    folksonomySelector,
    (folksonomy) => folksonomy.searchTagSuggestions,
);

const isTagzEnabled = isFeatureEnabled('tagz');
const isTagzAllowed = isUserAllowed();

// eslint-disable-next-line import/prefer-default-export
export { isLoadingSuggestionsSelector, searchTagSuggestionsSelector, isTagzEnabled, isTagzAllowed };
