import React from 'react';

import range from 'lodash/range';

import { ListItem, SkeletonRectangle, Size, SkeletonRectangleVariant } from '@lumapps/lumx/react';
import { getRandomValue } from '@lumapps/utils/array/getRandomValue';

export interface GenericEntityPickerSkeletonProps {
    /** The number of skeletons to display */
    number?: number;
}
/**
 * The skeletons displayed by default for the choices of the GenericEntityPicker.
 * TODO: Make it possible to add custom skeletons.
 */
export const GenericEntityPickerSkeletons: React.FC<GenericEntityPickerSkeletonProps> = ({ number = 1 }) => {
    return (
        <>
            {range(number).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index} size={Size.tiny}>
                    <SkeletonRectangle
                        variant={SkeletonRectangleVariant.pill}
                        width={getRandomValue([Size.xl, Size.xxl, Size.l], index.toString())}
                        height={Size.xs}
                    />
                </ListItem>
            ))}
        </>
    );
};
