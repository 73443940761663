import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';

import { MetadataFilter } from '@lumapps/metadata-pickers/types';

import { FormattedSiteFilter, FormattedTagFilter, GenericListFilterType, NGIFilterId } from '../types';

/**
 * Format an object of filters to make it compatible with the /block API
 * @param filters
 * @returns an object that will be translated as parameters for the block API call
 */
export const formatGenericFiltersForApi = (filters?: Partial<GenericListFilterType>) => {
    if (!isEmpty(filters)) {
        const {
            searchQuery,
            metadata,
            ignoredDefaultFilters,
            tab,
            tags,
            site,
            onlyHighlighted,
            publicationDates,
            author,
        } = filters as Partial<GenericListFilterType>;

        const metadataIds = metadata?.map((metadata: MetadataFilter) => metadata.id);
        /** Sanitizing search query to avoid injecting filter through the keyword filter */
        const searchQuerySanitized = searchQuery?.replace(/[?&:=[\]{}<>]/g, '');
        const tagsIds = tags?.map((tag: FormattedTagFilter) => tag.id);
        const siteIds = site?.map((site: FormattedSiteFilter) => site.id);
        const formatedIgnoredDefaultFilters = ignoredDefaultFilters
            ?.filter((filterId) => {
                switch (filterId) {
                    /**
                     * We cannot ignore default filters, for the site. It would trigger a search in ALL the instances.
                     * */
                    case NGIFilterId.site:
                        return !isEmpty(filters?.[filterId]);
                    default:
                        return true;
                }
            })
            .map(flow([snakeCase, toUpper]));
        const { endDate, startDate } = publicationDates || {};

        return omitBy(
            {
                searchQuery: searchQuerySanitized,
                metadataIds,
                tab,
                ignoredDefaultFilters: formatedIgnoredDefaultFilters,
                onlyHighlighted,
                tagsIds,
                siteIds,
                endDate,
                startDate,
                author: author?.email,
            },
            isNil,
        );
    }
    return {};
};
