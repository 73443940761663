import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';
import { URL_PREFIX } from '@lumapps/router/constants';

// Create a Route object with the following data
const learningRoute: Route = {
    path: `/${URL_PREFIX}/learning`,
    legacyId: 'learning',
    appId: AppId.frontOffice,
};

const learningManagerRoute: Route = {
    path: `/${URL_PREFIX}/learning-manager`,
    legacyId: 'learning-manager',
    appId: AppId.frontOffice,
};

export const webviewLearningRoute: Route = {
    path: `/${URL_PREFIX}/webview/learning`,
    legacyId: 'learning',
    appId: AppId.webview,
};

export const webviewLearningManagerRoute: Route = {
    path: `/${URL_PREFIX}/webview/learning-manager`,
    legacyId: 'learning-manager',
    appId: AppId.webview,
};

const learningView = (contentType: string): Route => ({
    ...learningRoute,
    params: {
        type: contentType,
    },

    mobilePageDescriptor: {
        type: 'learning',
        id: contentType,
    },
});

const learningManagerView = (contentType: string): Route => ({
    ...learningManagerRoute,
    params: {
        type: contentType,
    },

    mobilePageDescriptor: {
        type: 'learning-manager',
        id: contentType,
    },
});

const webviewLearningManagerView = (contentType: string): Route => ({
    ...webviewLearningManagerRoute,
    params: {
        type: contentType,
    },

    mobilePageDescriptor: {
        type: 'learning-manager',
        id: contentType,
    },
});

export { learningView, learningRoute, learningManagerView, learningManagerRoute, webviewLearningManagerView };
