import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { PlayStatusFlag } from '@lumapps/play/components/PlayStatusFlag';

import { useBlockVideoContext } from '../BlockVideoContext';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-status` as const;

/**
 * Displays the Status of a video
 */
export const Status = () => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, video } = useBlockVideoContext();

    if (!canDisplay('STATUS')) {
        return null;
    }

    const status = video?.assetDetails?.status !== 'ready' ? video?.assetDetails?.status : video?.status;

    return <PlayStatusFlag className={block()} status={status} />;
};
