/* istanbul ignore file */

/**
 * Match CSS query to check if the browser has a pointer that supports hovering over elements.
 *
 * aside: can't really be tested with unit test.
 */
export function browserHasPointerHover(): boolean {
    return window.matchMedia?.('(hover: hover)')?.matches;
}
