import { get } from '@lumapps/constants';

export const CONFIG = get();
export const DIGITAL_ASSISTANT_FEATURE_TOKEN = 'digital-assistant';

export const UPLOADER_ACCEPT_IMAGE_TYPES = '.jpg, .png, .gif, .tiff, .jpeg';
export const UPLOADER_ACCEPT_DOCUMENT_TYPES = '.pdf, .docx, .csv, .txt';

/**
 * Special user message values to use as handles for specific actions
 */
export enum MessageHandles {
    Reboot = '/reboot', // use this as a messages value to reboot the conversation
    FromUI = 'fromUI', // use this as a quickReply value to indicate that the handle has been called from the UI
}
