import React from 'react';

import range from 'lodash/range';

import { padding } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Link } from '@lumapps/router';
import { UserProgramsStatuses } from '@lumapps/sa-programs/ducks/userPrograms';
import { programDashboard } from '@lumapps/sa-programs/routes';
import { Program } from '@lumapps/sa-programs/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SOCIAL_ADVOCACY_NAVIGATION_SCOPE } from '../../constant';
import { SA_NAVIGATION } from '../../keys';

export interface SAMenuProgramsProps {
    programs: Program[];
    fetchProgramsStatus: UserProgramsStatuses;
    fetchUserPrograms(): void;
}

const SAMenuPrograms: React.FC<SAMenuProgramsProps> = ({ programs, fetchUserPrograms, fetchProgramsStatus }) => {
    const { translateKey, translateObject } = useTranslate();
    const { get } = useDataAttributes(SOCIAL_ADVOCACY_NAVIGATION_SCOPE);

    return (
        <ActionMenu.Section label={translateKey(SA_NAVIGATION.PROGRAMS)} {...get({ element: 'programs-menu' })}>
            {fetchProgramsStatus === UserProgramsStatuses.loading && (
                <ActionMenu.Skeleton loadingMessage={GLOBAL.LOADING}>
                    {range(3).map((i) => (
                        <ActionMenu.ItemSkeleton
                            key={i}
                            loadingMessage={translateKey(GLOBAL.LOADING)}
                            label={{ width: 120 - (i + 1) * 20 }}
                        />
                    ))}
                </ActionMenu.Skeleton>
            )}
            {fetchProgramsStatus === UserProgramsStatuses.failure && (
                <ServiceNotAvailableState className={padding('all', 'huge')} onRetry={() => fetchUserPrograms()} />
            )}
            {fetchProgramsStatus === UserProgramsStatuses.withPrograms &&
                programs.map((program) => {
                    const programSlug = translateObject(program.slug.translations);
                    return (
                        programSlug && (
                            <ActionMenu.Item
                                key={program.id}
                                as={Link}
                                to={programDashboard(programSlug)}
                                {...get({
                                    element: 'program-menu',
                                    action: program.id,
                                    permissions: {
                                        canEdit: program.canEdit,
                                    },
                                })}
                            >
                                {translateObject(program.name.translations) || ''}
                            </ActionMenu.Item>
                        )
                    );
                })}
        </ActionMenu.Section>
    );
};

export { SAMenuPrograms };
