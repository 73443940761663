/* istanbul ignore file */
import React from 'react';

const useNoSideNavBo = ({ isEnabled = true }) => {
    React.useLayoutEffect(() => {
        if (isEnabled) {
            const { body } = document;

            if (!body.classList.contains('no-side-nav-bo')) {
                body.classList.add('no-side-nav-bo');
            }
        }
    });
};

export { useNoSideNavBo };
