import { search } from '@lumapps/search/routes';

/**
 * Return the proper route to search a tag
 * @param tagId the id of the tag
 * @param modes enabled modes
 * @returns tag route
 */
export const getSearchTagRoute = (tagId: string, filter?: string) => {
    // entire search query is encoded in the route function
    return search(`tag:"${tagId}"`, filter);
};
