/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

const LazyBlockRemote = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-remote-block-remote' */
            './BlockRemote'
        ),
);
export const BlockRemote = (props: import('../../type').BlockRemote) => (
    <Suspense loadingFallback={<WidgetSkeletonRectangle />}>
        <LazyBlockRemote {...props} />
    </Suspense>
);
