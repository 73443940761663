import React, { useCallback, useRef } from 'react';

import { margin, classnames, useClassnames, padding } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Dialog, DialogProps, Toolbar, IconButton, Emphasis, Button, ButtonProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

interface DialogActionProps extends ButtonProps {
    label: string;
}

interface DialogActionsProps {
    cancel?: DialogActionProps;
    accept?: DialogActionProps;
    onClose?: () => void;
}

interface CustomizationDialogProps extends DialogProps, DialogActionsProps {
    body: React.ReactNode;
    isOpen: boolean;
}

const shouldDisplayButton = (button?: DialogActionProps): boolean => {
    return button ? Boolean(button.label) : false;
};

export const DialogActions: React.FC<DialogActionsProps> = ({ cancel, accept, onClose }) => {
    const shouldDisplayCancelButton = shouldDisplayButton(cancel);
    const shouldDisplayAcceptButton = shouldDisplayButton(accept);

    return (
        <>
            {shouldDisplayCancelButton && cancel ? (
                <Button
                    {...cancel}
                    onClick={() => {
                        if (cancel.onClick) {
                            cancel.onClick();
                        }

                        if (onClose) {
                            onClose();
                        }
                    }}
                >
                    {cancel.label}
                </Button>
            ) : null}
            {shouldDisplayAcceptButton && accept ? (
                <Button
                    {...accept}
                    className={classnames(margin('left', 'regular'), accept.className)}
                    onClick={() => {
                        if (accept.onClick) {
                            accept.onClick();
                        }

                        if (onClose) {
                            onClose();
                        }
                    }}
                >
                    {accept.label}
                </Button>
            ) : null}
        </>
    );
};

const CLASSNAME = 'customization-dialog';
export const CustomizationDialog: React.FC<CustomizationDialogProps> = ({
    header,
    body,
    isOpen: isDialogOpen,
    cancel,
    accept,
    onClose,
    ...props
}) => {
    const [isOpen, setOpen] = React.useState(isDialogOpen);
    const { element } = useClassnames(CLASSNAME);
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setOpen(false);
    }, [onClose]);

    const ref = useRef(null);
    const { translateKey } = useTranslate();
    const shouldDisplayCancelButton = shouldDisplayButton(cancel);
    const shouldDisplayAcceptButton = shouldDisplayButton(accept);
    const shouldDisplayFooter = shouldDisplayAcceptButton || shouldDisplayCancelButton;

    const dialogFooter = <Toolbar after={<DialogActions cancel={cancel} accept={accept} onClose={close} />} />;

    const dialogHeader = (
        <Toolbar
            label={header}
            after={
                <IconButton
                    label={translateKey(GLOBAL.CLOSE)}
                    icon={mdiClose}
                    onClick={close}
                    emphasis={Emphasis.low}
                />
            }
        />
    );

    return (
        <div ref={ref}>
            <Dialog
                {...props}
                isOpen={isOpen}
                parentElement={ref}
                onClose={close}
                footer={shouldDisplayFooter ? dialogFooter : null}
                header={dialogHeader}
            >
                <div className={element('body', [padding('horizontal', 'huge')])}>{body}</div>
            </Dialog>
        </div>
    );
};

CustomizationDialog.displayName = 'Dialog';
