import { combineReducers } from '@lumapps/redux';

import * as programAdminSlice from './programAdmin/slice';
import * as programFormSlice from './programForm/slice';
import * as programFormModalSlice from './programFormModal/slice';
import * as programSelectionModalSlice from './ProgramSelectionModal/slice';
import { SAProgramState } from './types';
import * as userProgramsSlice from './userPrograms/slice';

export const reducer = combineReducers({
    userPrograms: userProgramsSlice.reducer,
    backOffice: programAdminSlice.reducer,
    programFormModal: programFormModalSlice.reducer,
    programForm: programFormSlice.reducer,
    programSelectionModal: programSelectionModalSlice.reducer,
});

export const initialState: SAProgramState = {
    userPrograms: userProgramsSlice.initialState,
    backOffice: programAdminSlice.initialState,
    programFormModal: programFormModalSlice.initialState,
    programForm: programFormSlice.initialState,
    programSelectionModal: programSelectionModalSlice.programSelectionModalInitialState,
};

export const userProgramsActions = userProgramsSlice.actions;
export const programsAdminActions = programAdminSlice.actions;
export const programFormActions = programFormSlice.actions;
export const programFormModalActions = programFormModalSlice.actions;
export const programSelectionModalActions = programSelectionModalSlice.actions;
