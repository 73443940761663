import React from 'react';

import { useSelector } from '@lumapps/redux/react';
import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';

import { isGDPRBannerEnabled } from './ducks/selectors';
import { useGdprBanner } from './hooks/useGdprBanner';

const LazyGdprBanner = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'gdpr-banner' */
            './components/GdprBanner'
        ),
);

export const GdprBanner = () => {
    const isGDPRBannerFeatureEnabled = useSelector(isGDPRBannerEnabled);

    // Should disable the cookie banner on webviews
    const isMobileAppWebView = useDetectMobileAppWebView();

    const { hasUserClosedBanner } = useGdprBanner();

    const showGdprBanner = !isMobileAppWebView && !hasUserClosedBanner && isGDPRBannerFeatureEnabled;

    return (
        <DelayedSuspense shouldLoad={showGdprBanner}>
            <LazyGdprBanner />
        </DelayedSuspense>
    );
};
