export enum WREX_EMBEDDED_VIDEO {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355120445
     */
    INSERTION_BUTTON_TOOLTIP = 'WREX_EMBEDDED_VIDEO.BUTTON_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=355121546
     */
    ATTACH_VIDEO_BUTTON_TOOLTIP = 'WREX_ATTACHMENTS.VIDEO_BUTTON_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=169165744
     */
    ENTER_URL = 'FRONT.ENTER_VIDEO_URL',
}
