import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiMagnify, mdiSettings } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SEARCH_SETTINGS } from '../../keys';
import { searchSettings, coveoSearchSettings, gcssearchSettings } from '../../routes';

export type SearchSettingsSideNavigationProps = {
    id: string;
    isOpen?: boolean;
    canAdminCoveo: boolean;
    canAdminGCS: boolean;
    canAdminNS: boolean;
};

export const SearchSettingsSideNavigation: React.FC<SearchSettingsSideNavigationProps> = ({
    id,
    isOpen = false,
    canAdminCoveo,
    canAdminGCS,
    canAdminNS,
}) => {
    const { translateKey } = useTranslate();

    // Coveo does not need to have Admin NS entry
    if (canAdminCoveo) {
        return (
            <SideNavigationItem
                route={coveoSearchSettings()}
                label={translateKey(SEARCH_SETTINGS.TITLE)}
                icon={mdiMagnify}
                id={`${id}--coveo`}
            />
        );
    }

    if (canAdminGCS) {
        return (
            <SideNavigationItem label={translateKey(SEARCH_SETTINGS.TITLE)} icon={mdiSettings} id={id} isOpen={isOpen}>
                <SideNavigationItem
                    route={gcssearchSettings()}
                    label={translateKey(SEARCH_SETTINGS.CLOUDSEARCH_SETTINGS)}
                    id={`${id}--gcs`}
                />
                <SideNavigationItem
                    route={searchSettings()}
                    label={translateKey(SEARCH_SETTINGS.NATIVE_SEARCH)}
                    id={`${id}--ns`}
                />
            </SideNavigationItem>
        );
    }

    if (canAdminNS) {
        return (
            <SideNavigationItem label={translateKey(SEARCH_SETTINGS.TITLE)} icon={mdiSettings} id={id} isOpen={isOpen}>
                <SideNavigationItem
                    route={searchSettings()}
                    label={translateKey(SEARCH_SETTINGS.RESULTS_SETTINGS)}
                    id={`${id}--ns`}
                />
            </SideNavigationItem>
        );
    }

    return null;
};
