import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ImportOnVisibility } from '@lumapps/utils/components/ImportOnVisibility';

import { PlaylistProps } from './Playlist';
import { PlaylistSkeleton } from './PlaylistSkeleton';

const CLASSNAME = 'playlist-on-visibility';

const LazyPlaylist = React.lazy(() => import(/* webpackChunkName: 'lazy-playlist' */ './Playlist'));

export const Playlist = (props: PlaylistProps) => {
    const { element } = useClassnames(CLASSNAME);

    const { className, videoVisibleElements } = props;

    return (
        <ImportOnVisibility
            options={{
                rootMargin: '220px', // Load each playlist early so that it feels smooth
            }}
            loadingFallback={
                <PlaylistSkeleton
                    // eslint-disable-next-line lumapps/no-manual-bems
                    className={element('lazy-loader', [`${className}__lazy-loader`])}
                    videoVisibleElements={videoVisibleElements}
                />
            }
        >
            <LazyPlaylist {...props} />
        </ImportOnVisibility>
    );
};
