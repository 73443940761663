import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiDatabaseRefresh } from '@lumapps/lumx/icons';
import { PROVISIONING_DASHBOARD } from '@lumapps/providers-dashboard-admin/keys';
import { adminProvisioningDashboard } from '@lumapps/providers-dashboard-admin/routes';
import { useTranslate } from '@lumapps/translations';

import { PROVIDERS } from '../../keys';
import { identityProvider, serviceProvider } from '../../routes';

export interface ProvidersSideNavigationProps {
    isAllowed: boolean;
}

const ProvidersSideNavigation = ({ isAllowed }: ProvidersSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(PROVIDERS.PROVIDER)} icon={mdiDatabaseRefresh} id="providers">
            <SideNavigationItem
                label={translateKey(PROVISIONING_DASHBOARD.DASHBOARD)}
                route={adminProvisioningDashboard()}
            />
            <SideNavigationItem label={translateKey(PROVIDERS.IDP)} route={identityProvider()} />
            <SideNavigationItem label={translateKey(PROVIDERS.SERVICE)} route={serviceProvider()} />
        </SideNavigationItem>
    );
};

ProvidersSideNavigation.displayName = 'SideNavigationItem';

export { ProvidersSideNavigation };
