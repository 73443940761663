import { isFeatureEnabled } from '@lumapps/features';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { singlePostSelector } from '@lumapps/posts/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore, Dispatch } from '@lumapps/redux/types';

import { translatePost } from '../../../ducks/thunks/translatePost';
import { updatePostVote } from '../../../ducks/thunks/updatePostVote';
import { PostBlockContent as DumbPostBlockContent, PostBlockContentProps } from './PostBlockContent';

const mapStateToProps = (state: FrontOfficeStore, props: any) => {
    const post = singlePostSelector(props.postId)(state);
    const currentLang = currentLanguageSelector(state);
    const isTagzEnabled = isFeatureEnabled('tagz')(state);
    const isAutoTranslationEnabled = isFeatureEnabled('translation')(state);
    const isEndUserTranslationEnabled = isFeatureEnabled('translation-end-user')(state);
    const containerId = post.parentContentDetails?.id;
    return {
        currentLang,
        isTagzEnabled,
        isAutoTranslationEnabled,
        isEndUserTranslationEnabled,
        post,
        containerId,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): Pick<PostBlockContentProps, 'onVote' | 'onTranslate'> => {
    return {
        onVote: (vote, postId) => {
            dispatch(updatePostVote(vote, postId));
        },
        onTranslate: (postId: string, post: string) => {
            dispatch(translatePost(postId, post));
        },
    };
};

const ConnectedPostBlockContent = connect(mapStateToProps, mapDispatchToProps)(DumbPostBlockContent);

export { ConnectedPostBlockContent as PostBlockContent };
