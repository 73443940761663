import capitalize from 'lodash/capitalize';

/////////////////////////////

function ZendeskBlockController(Translation, ZendeskConstant) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.ZendeskConstant = ZendeskConstant;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const itemDate = moment.utc(vm.item.updatedAt).local();
        const now = moment()
            .utc()
            .local();

        if (now.diff(itemDate, 'hours') > 24) {
            vm.formattedDate = itemDate.format(Translation.translate('SIMPLE_DATE_FORMAT_COMPLETE'));
        } else {
            vm.formattedDate = itemDate.fromNow();
        }

        vm.labelText =
            capitalize(vm.item.type) + (vm.item.type === ZendeskConstant.RESULT_TYPE_TICKET ? ` #${vm.item.id}` : '');
    }

    init();
}

/////////////////////////////

/**
 * The Zendesk block directive.
 * Displays a single item within a list of zendesk search results.
 *
 * @param {Object} item The Zendesk item to display in the block.
 */

function LsZendeskBlockDirective() {
    return {
        bindToController: true,
        controller: ZendeskBlockController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            item: '<lsItem',
        },
        templateUrl: '/client/front-office/modules/zendesk/views/zendesk-block.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsZendeskBlock', LsZendeskBlockDirective);

/////////////////////////////

export { LsZendeskBlockDirective, ZendeskBlockController };
