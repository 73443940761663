import { useCallback, useEffect, useReducer } from 'react';

import first from 'lodash/first';
import isBoolean from 'lodash/isBoolean';
import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';

import { createInterest, deleteInterest } from '@lumapps/content-interest/api';
import { Interest } from '@lumapps/content-interest/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { querySubscription, saveSubscription, subscribe, unsubscribe } from '../api';
import { SubscriptionsTypes, SubscriptionType, UseSubscriptionStatuses } from '../types';

interface UserSubscriptionState {
    status: UseSubscriptionStatuses;
    isSubscribed: boolean;
    isNotified: boolean;
    subscription?: SubscriptionType;
    interestData?: Pick<Interest, 'uid' | 'customContentType' | 'customContentTypeTags' | 'orMetadata'>;
}

const initialState: UserSubscriptionState = {
    status: UseSubscriptionStatuses.initial,
    isSubscribed: false,
    isNotified: false,
};

const { actions, reducer } = createSlice({
    domain: 'useSubscriptions',
    initialState,
    reducers: {
        setStatus: (state: UserSubscriptionState, action: PayloadAction<UseSubscriptionStatuses>) => {
            set(state, 'status', action.payload);
        },
        updateInterestUid: (state: UserSubscriptionState, action: PayloadAction<string>) => {
            set(state.interestData || {}, 'uid', action.payload);
        },
        /**
         * Store the current subscription
         * Can take a boolean to store minimal information or the full object
         */
        setSubscription: (
            state: UserSubscriptionState,
            action: PayloadAction<SubscriptionType | boolean | undefined>,
        ) => {
            if (isBoolean(action.payload)) {
                set(state, 'isSubscribed', action.payload);
            } else {
                set(state, 'subscription', action.payload);
                set(state, 'isNotified', !!action.payload?.notify);
                set(state, 'isSubscribed', !!action.payload);
            }

            set(state, 'status', UseSubscriptionStatuses.idle);
        },
    },
});

export interface UseSubscriptionType {
    /** Type of the resource (user, content, interest, ...) */
    resourceType?: SubscriptionsTypes;
    /** ID of the resource */
    resourceId: string;
    /** Whether the */
    autoFetch?: boolean;
    /** Whether the user has subscribed to this resource  */
    isSubscribed?: boolean;
    /** Data used in subscriptions of type interest used when the user resubscribes */
    interestData?: Pick<Interest, 'uid' | 'customContentType' | 'customContentTypeTags' | 'orMetadata'>;
    /** Enable the notification feature, to allow user to register for notifications when the subscription is active */
    enableNotification?: boolean;
    /** Whether the user will receive notifications for this resource's updates (requires enableNotification to be true) */
    isNotified?: boolean;
    /** Whether the notifications are activated along with the subscription */
    areNotificationsEnabledOnSubscription?: boolean;
}

/**
 * Use this hook to fetch the current subscription to a resource (content or user)
 * and toggle the state.
 */
export const useSubscription = ({
    resourceType = SubscriptionsTypes.content,
    resourceId,
    autoFetch,
    isSubscribed,
    isNotified = false,
    interestData,
    enableNotification = false,
    areNotificationsEnabledOnSubscription = false,
}: UseSubscriptionType) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        status: isSubscribed !== undefined ? UseSubscriptionStatuses.idle : initialState.status,
        isSubscribed: Boolean(isSubscribed),
        isNotified,
        interestData,
    });

    /*
     * Fetch the resource subscription status from the api
     * If the notification flag is enabled, store the subscription in the state.
     */
    const fetchResourceSubscription = useCallback(async () => {
        if (resourceId && resourceType !== SubscriptionsTypes.interest) {
            try {
                dispatch(actions.setStatus(UseSubscriptionStatuses.loading));

                const response = await querySubscription({ resourceType, resourceId });

                if (response.data && response.data.items) {
                    const subscription = first(response.data.items);
                    dispatch(actions.setSubscription(enableNotification ? subscription : !!subscription));
                } else {
                    dispatch(actions.setSubscription(enableNotification ? undefined : false));
                }
            } catch (error) {
                dispatch(actions.setStatus(UseSubscriptionStatuses.error));
            }
        }
    }, [resourceId, resourceType, enableNotification]);

    /*
     * Add or remove subscription depending on current state.
     * Call the API and set the local status.
     * If the resourceType is an interest, delete and create interests instead
     */
    const toggleSubscription = useCallback(async () => {
        if (state.status !== UseSubscriptionStatuses.changing) {
            dispatch(actions.setStatus(UseSubscriptionStatuses.changing));
            try {
                if (state.isSubscribed) {
                    if (resourceType === SubscriptionsTypes.interest && state.interestData?.uid) {
                        await deleteInterest({ uid: state.interestData.uid });
                    } else {
                        await unsubscribe({ resourceId, resourceType });
                    }
                    dispatch(actions.setSubscription(enableNotification ? undefined : false));
                } else if (resourceType === SubscriptionsTypes.interest && state.interestData?.customContentType) {
                    const { data } = await createInterest(state.interestData);
                    dispatch(actions.updateInterestUid(data.uid));
                    dispatch(actions.setSubscription(true));
                } else {
                    const subscription = await subscribe({
                        resourceId,
                        resourceType,
                        notify: areNotificationsEnabledOnSubscription,
                    });

                    dispatch(actions.setSubscription(enableNotification ? subscription.data : true));
                }
            } catch (error) {
                dispatch(actions.setStatus(UseSubscriptionStatuses.idle));
            }
        }
    }, [
        areNotificationsEnabledOnSubscription,
        enableNotification,
        resourceId,
        resourceType,
        state.interestData,
        state.isSubscribed,
        state.status,
    ]);

    /*
     * Add or remove notification from a subscription, depending on current state.
     * Call the API, update the subscription and set the local status.
     */
    const toggleNotification = useCallback(async () => {
        if (state.status !== UseSubscriptionStatuses.changing) {
            let subscription: SubscriptionType | undefined = state.subscription ? { ...state.subscription } : undefined;

            // if subscription info is missing but we have the id and a correct type, we fetch it first
            if (isUndefined(subscription) && resourceId && resourceType !== SubscriptionsTypes.interest) {
                dispatch(actions.setStatus(UseSubscriptionStatuses.loading));

                const response = await querySubscription({ resourceType, resourceId });

                if (response?.data?.items) {
                    subscription = first(response.data.items) as SubscriptionType;
                }
            }

            if (subscription) {
                dispatch(actions.setStatus(UseSubscriptionStatuses.changing));
                try {
                    const newSubscription = await saveSubscription({
                        ...subscription,
                        notify: !subscription.notify,
                    });

                    dispatch(actions.setSubscription(newSubscription.data));
                } catch (error) {
                    dispatch(actions.setStatus(UseSubscriptionStatuses.idle));
                }
            }
        }
    }, [resourceId, resourceType, state.status, state.subscription]);

    /**
     * If autoFetch === true and resourceId changes, fetch subscription
     */
    useEffect(() => {
        if (autoFetch && resourceId) {
            fetchResourceSubscription();
        }
    }, [fetchResourceSubscription, autoFetch, resourceId]);

    return {
        status: state.status,
        isSubscribed: state.isSubscribed,
        isNotified: state.isNotified,
        toggleSubscription,
        toggleNotification: enableNotification ? toggleNotification : undefined,
        fetchResourceSubscription,
    };
};
