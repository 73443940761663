import { ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { reviews } from '@lumapps/reports/routes';
import { Link } from '@lumapps/router';
import { getPluralType, PluralTypes } from '@lumapps/translations';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta } from '../../types';
import { getContentTitle, isTheContentAPost, getParentContentTitle } from '../contentUtils';
import { getCommentLink, getEmptyRoute } from '../linksUtils';

const notificationTypeComment: Record<string, NotificationMeta> = {
    [NotificationTypes.CommentLike]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.red,
            icon: 'heart',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const translationKeys = {
                content: {
                    [PluralTypes.ONE]: 'NOTIFICATION.COMMENT.LIKE.ONE.DESCRIPTION',
                    [PluralTypes.TWO]: 'NOTIFICATION.COMMENT.LIKE.TWO.DESCRIPTION',
                    [PluralTypes.MORE]: 'NOTIFICATION.COMMENT.LIKE.MORE.DESCRIPTION',
                },
                postWithoutTitle: {
                    [PluralTypes.ONE]: 'NOTIFICATION.COMMENT.LIKE.POST.ONE.DESCRIPTION',
                    [PluralTypes.TWO]: 'NOTIFICATION.COMMENT.LIKE.POST.TWO.DESCRIPTION',
                    [PluralTypes.MORE]: 'NOTIFICATION.COMMENT.LIKE.POST.MORE.DESCRIPTION',
                },
                postWithTitle: {
                    [PluralTypes.ONE]: 'NOTIFICATION.COMMENT.LIKE.POST.WITH.TITLE.ONE.DESCRIPTION',
                    [PluralTypes.TWO]: 'NOTIFICATION.COMMENT.LIKE.POST.WITH.TITLE.TWO.DESCRIPTION',
                    [PluralTypes.MORE]: 'NOTIFICATION.COMMENT.LIKE.POST.WITH.TITLE.MORE.DESCRIPTION',
                },
            };

            let key: string;

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? translationKeys.postWithTitle[getPluralType(count)]
                    : translationKeys.postWithoutTitle[getPluralType(count)];
            } else {
                key = translationKeys.content[getPluralType(count)];
            }

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHER_MENTIONS: count - 1,
                CONTENT_TITLE: contentTitle,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.CommentMention]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'comment-multiple-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            let key;
            const contentTitle = getContentTitle(translation, notification);

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? 'NOTIFICATION.COMMENT.POST.MENTION.DESCRIPTION'
                    : 'NOTIFICATION.COMMENT.POST.MENTION.WITHOUT.TITLE.DESCRIPTION';
            } else {
                key = 'NOTIFICATION.COMMENT.MENTION.DESCRIPTION';
            }

            if (count > 1) {
                key = translation.pluralize(`${key}_OTHERS`, count - 1);
            }

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHERS: count - 1,
                CONTENT_TITLE: contentTitle,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.CommentMentionOnArticle]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'comment-multiple-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key =
                count > 1
                    ? translation.pluralize(NOTIFICATIONS_CENTER.COMMENT_MENTION_MULTIPLE, count - 1)
                    : NOTIFICATIONS_CENTER.COMMENT_MENTION;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHERS: count - 1,
                CONTENT_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.CommentMentionOnEvent]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'comment-multiple-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key =
                count > 1
                    ? translation.pluralize(NOTIFICATIONS_CENTER.COMMENT_MENTION_MULTIPLE, count - 1)
                    : NOTIFICATIONS_CENTER.COMMENT_MENTION;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHERS: count - 1,
                CONTENT_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.CommentNew]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'comment-multiple-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            let key;
            const contentTitle = getContentTitle(translation, notification);

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? 'NOTIFICATION.COMMENT.POST.NEW.DESCRIPTION'
                    : 'NOTIFICATION.COMMENT.POST.NEW.WITHOUT.TITLE.DESCRIPTION';
            } else {
                key = 'NOTIFICATION.COMMENT.NEW.DESCRIPTION';
            }

            if (count > 1) {
                key = translation.pluralize(`${key}_OTHERS`, count - 1);
            }

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHERS: count - 1,
                CONTENT_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.CommentReply]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'comment-multiple-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            let key;
            const contentTitle = getContentTitle(translation, notification);

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? 'NOTIFICATION.COMMENT.POST.REPLY.DESCRIPTION'
                    : 'NOTIFICATION.COMMENT.POST.REPLY.WITHOUT.TITLE.DESCRIPTION';
            } else {
                key = 'NOTIFICATION.COMMENT.REPLY.DESCRIPTION';
            }

            if (count > 1) {
                key = translation.pluralize(`${key}_OTHERS`, count - 1);
            }

            return translation.translateAndReplace(key, {
                CONTENT_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHERS: count - 1,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.ReplyLike]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.red,
            icon: 'heart',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const translationKeys = {
                content: {
                    [PluralTypes.ONE]: 'NOTIFICATION.REPLY.LIKE.ONE.DESCRIPTION',
                    [PluralTypes.TWO]: 'NOTIFICATION.REPLY.LIKE.TWO.DESCRIPTION',
                    [PluralTypes.MORE]: 'NOTIFICATION.REPLY.LIKE.MORE.DESCRIPTION',
                },
                postWithoutTitle: {
                    [PluralTypes.ONE]: 'NOTIFICATION.REPLY.LIKE.POST.ONE.DESCRIPTION',
                    [PluralTypes.TWO]: 'NOTIFICATION.REPLY.LIKE.POST.TWO.DESCRIPTION',
                    [PluralTypes.MORE]: 'NOTIFICATION.REPLY.LIKE.POST.MORE.DESCRIPTION',
                },
                postWithTitle: {
                    [PluralTypes.ONE]: 'NOTIFICATION.REPLY.LIKE.POST.WITH.TITLE.ONE.DESCRIPTION',
                    [PluralTypes.TWO]: 'NOTIFICATION.REPLY.LIKE.POST.WITH.TITLE.TWO.DESCRIPTION',
                    [PluralTypes.MORE]: 'NOTIFICATION.REPLY.LIKE.POST.WITH.TITLE.MORE.DESCRIPTION',
                },
            };

            let key;

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? translationKeys.postWithTitle[getPluralType(count)]
                    : translationKeys.postWithoutTitle[getPluralType(count)];
            } else {
                key = translationKeys.content[getPluralType(count)];
            }

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHER_MENTIONS: count - 1,
                CONTENT_TITLE: contentTitle,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },

    [NotificationTypes.CommentReportCreated]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const translationKeys = {
                content: {
                    [PluralTypes.ONE]: NOTIFICATIONS_CENTER.COMMENT_REPORT_CREATED_ONE_DESCRIPTION,
                    [PluralTypes.MORE]: NOTIFICATIONS_CENTER.COMMENT_REPORT_CREATED_MORE_DESCRIPTION,
                },
                postWithoutTitle: {
                    [PluralTypes.ONE]: NOTIFICATIONS_CENTER.COMMENT_REPORT_CREATED_POST_ONE_DESCRIPTION,
                    [PluralTypes.MORE]: NOTIFICATIONS_CENTER.COMMENT_REPORT_CREATED_POST_MORE_DESCRIPTION,
                },
                postWithTitle: {
                    [PluralTypes.ONE]: NOTIFICATIONS_CENTER.COMMENT_REPORT_CREATED_POST_WITH_TITLE_ONE_DESCRIPTION,
                    [PluralTypes.MORE]: NOTIFICATIONS_CENTER.COMMENT_REPORT_CREATED_POST_WITH_TITLE_MORE_DESCRIPTION,
                },
            };

            let key: string;

            const PluralType = count === 1 ? PluralTypes.ONE : PluralTypes.MORE;

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? translationKeys.postWithTitle[PluralType]
                    : translationKeys.postWithoutTitle[PluralType];
            } else {
                key = translationKeys.content[PluralType];
            }

            return translation.translateAndReplace(key, {
                LAST_REPORTER_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHER_REPORTS: count - 1,
                CONTENT_TITLE: contentTitle,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: () => reviews(),
        getLinkAs: () => ({ linkAs: Link, to: reviews() }),
    },
    [NotificationTypes.CommentReportReviewed]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, translation }) => {
            const contentTitle = getContentTitle(translation, notification);

            let key: string;

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? NOTIFICATIONS_CENTER.COMMENT_REPORT_REVIEWED_POST_WITH_TITLE_DESCRIPTION
                    : NOTIFICATIONS_CENTER.COMMENT_REPORT_REVIEWED_POST_DESCRIPTION;
            } else {
                key = NOTIFICATIONS_CENTER.COMMENT_REPORT_REVIEWED_DESCRIPTION;
            }

            return translation.translateAndReplace(key, {
                CONTENT_TITLE: contentTitle,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },
    [NotificationTypes.CommentReportAccepted]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, translation }) => {
            const contentTitle = getContentTitle(translation, notification);

            let key: string;

            if (isTheContentAPost(notification)) {
                key = contentTitle
                    ? NOTIFICATIONS_CENTER.COMMENT_REPORT_ACCEPTED_POST_WITH_TITLE_DESCRIPTION
                    : NOTIFICATIONS_CENTER.COMMENT_REPORT_ACCEPTED_POST_DESCRIPTION;
            } else {
                key = NOTIFICATIONS_CENTER.COMMENT_REPORT_ACCEPTED_DESCRIPTION;
            }

            return translation.translateAndReplace(key, {
                CONTENT_TITLE: contentTitle,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },
};

export { notificationTypeComment };
