(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityRequestService(Community, CommunityRequestFactory, LumsitesBaseService, Utils) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(CommunityRequestFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The different types of statuses for a community request.
         *
         * @type {Object}
         */
        service.STATUS_TYPES = {
            ACCEPTED: 'accepted',
            PENDING: 'pending',
            REJECTED: 'rejected',
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Remove a given request from a list of requests.
         *
         * @param {string} requestId The identifier of the request to be removed.
         * @param {string} [listKey] An identifier for the list of requests to update.
         */
        function _removeRequestFromList(requestId, listKey) {
            if (angular.isUndefinedOrEmpty(requestId)) {
                return;
            }

            var list = service.displayList(listKey);
            var itemIndex = _.findIndex(list, {
                uid: requestId,
            });

            if (itemIndex > -1) {
                list.splice(itemIndex, 1);
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Approve a given community request.
         *
         * @param {string}   requestId The identifier of the request to approve.
         * @param {Function} [cb]      A callback function to execute when the approve call is successfull.
         * @param {Function} [errCb]   A callback function to execute when the approve call errors.
         * @param {string}   [listKey] An identifier for the list of requests to update.
         */
        function approveRequest(requestId, cb, errCb, listKey) {
            if (angular.isUndefinedOrEmpty(requestId) && !service.isApproving && !service.isRefusing) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || Utils.displayServerError;

            service.isApproving = true;

            service.Api.approve({
                uid: requestId,
            }, function onApproveSuccess(response) {
                service.isApproving = false;

                _removeRequestFromList(requestId, listKey);
                cb(response);
            }, function onApproveError(err) {
                service.isApproving = false;

                errCb(err);
            });
        }

        /**
         * Create a new community access request for a given user and community.
         *
         * @param {Object}   [communityId] The identifier of the community to create the requests access for.
         * @param {Function} [cb]          A callback function to execute on success of the save call.
         * @param {Function} [errCb]       A callback function to execute on error of the save call.
         */
        function createRequest(communityId, cb, errCb) {
            communityId = communityId || Community.getCurrent().uid;

            if (angular.isUndefinedOrEmpty(communityId)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || Utils.displayServerError;

            service.save({
                communityKey: communityId,
            }, cb, errCb);
        }

        /**
         * Reject a given community request.
         *
         * @param {string}   requestId The identifier of the request to reject.
         * @param {Function} [cb]      A callback function to execute when the reject call is successfull.
         * @param {Function} [errCb]   A callback function to execute when the reject call errors.
         * @param {string}   [listKey] An identifier for the list of requests to update.
         */
        function rejectRequest(requestId, cb, errCb, listKey) {
            if (angular.isUndefinedOrEmpty(requestId) && !service.isRefusing && !service.isApproving) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || Utils.displayServerError;

            service.isRefusing = true;

            service.Api.reject({
                uid: requestId,
            }, function onRejectSuccess(response) {
                service.isRefusing = false;

                _removeRequestFromList(requestId, listKey);
                cb(response);
            }, function onRejectError(err) {
                service.isRefusing = false;

                errCb(err);
            });
        }

        /////////////////////////////

        service.approveRequest = approveRequest;
        service.createRequest = createRequest;
        service.rejectRequest = rejectRequest;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('CommunityRequest', CommunityRequestService);
})();
