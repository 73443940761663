/* eslint-disable */
function OrgChartItemController($scope, UserDirectory, UserOrganizationChart, Instance) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.UserDirectory = UserDirectory;
    vm.UserOrganizationChart = UserOrganizationChart;
    vm.Instance = Instance;

    function orgChartIsLoading() {
        return (
            vm.UserOrganizationChart.isCallInProgress() ||
            vm.UserDirectory.isCallInProgress() ||
            vm.Instance.is.loading.siblings
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Update the user profile by displaying the current user.
     */
    function displayUser() {
        if (vm.orgChartIsLoading()) {
            return;
        }
        if (vm.user.id === UserDirectory.displayedUserId) {
            return;
        }

        $scope.$emit('display-user-org-chart', vm.user.id);

        vm.updateOrgChart(true);
    }

    /**
     * Check if the user has a parent and children.
     *
     * @return {boolean} Whether the user has a parent and children.
     */
    function hasParentAndChildren() {
        return angular.isDefinedAndFilled(vm.parent) && angular.isDefinedAndFilled(vm.user.orgChartProps.children);
    }

    /**
     * Check if the user is its own manager.
     *
     * @return {boolean} Whether the user has children or not.
     */
    function isSelfManager() {
        // If parent is defined, check if uid is same as itself.
        if (angular.isDefinedAndFilled(vm.parent)) {
            return vm.parent.uid === vm.user.uid;
        }
        // If not, check if user has "manager" type relation
        if (angular.isDefinedAndFilled(vm.user.apiProfile.relations)) {
            const managerRelation = vm.user.apiProfile.relations.find((relation) => relation.type === 'manager');
            return managerRelation && managerRelation.value === vm.user.email;
        }
        return false;
    }

    /**
     * Check if the user has children or not.
     * Need a request from this user.
     *
     * @return {boolean} Whether the user has children or not.
     */
    function noChildren() {
        return angular.isUndefinedOrEmpty(vm.user.orgChartProps.children) && vm.user.orgChartProps.didRequest;
    }

    /**
     * Check if the user has a parent or not.
     * Need a request from this user.
     *
     * @return {boolean} Whether the user has a parent or not.
     */
    function noParent() {
        return angular.isUndefinedOrEmpty(vm.parent) && vm.user.orgChartProps.didRequest;
    }

    /**
     * Update org chart on click.
     *
     * @param {boolean} onDisplay Whether the update is done by clicking on display button or not.
     */
    function updateOrgChart(onDisplay) {
        if (vm.orgChartIsLoading()) {
            return;
        }

        if (onDisplay) {
            vm.user.orgChartProps.isOpen = true;
        } else {
            vm.user.orgChartProps.isOpen = !vm.user.orgChartProps.isOpen;
        }

        if (vm.hasParentAndChildren() || vm.noChildren() || vm.noParent()) {
            return;
        }

        UserOrganizationChart.updateOrgChart(vm.user, vm.root);
    }

    /////////////////////////////

    vm.displayUser = displayUser;
    vm.hasParentAndChildren = hasParentAndChildren;
    vm.noChildren = noChildren;
    vm.isSelfManager = isSelfManager;
    vm.noParent = noParent;
    vm.updateOrgChart = updateOrgChart;
    vm.orgChartIsLoading = orgChartIsLoading;

    /**
     * Initialize the controller.
     */
    function init() {
        if (angular.isUndefinedOrEmpty(vm.user.orgChartProps)) {
            vm.user.orgChartProps = {};
        }

        if (angular.isUndefinedOrEmpty(vm.user.orgChartProps.isOpen)) {
            vm.user.orgChartProps.isOpen = angular.isDefinedAndFilled(vm.user.orgChartProps.children);
        }
    }

    init();
}

/**
 * Organisation chart user containing its children.
 * @param {Object}  [parent] The user parent user in the org chart tree.
 * @param {boolean} [root]   Wether the item is the root item in the org chart or not.
 * @param {Object}  user     User with its children.
 */

function OrgChartItemDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: OrgChartItemController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            parent: '<?',
            root: '<?',
            user: '=',
        },
        templateUrl:
            '/client/front-office/modules/module/modules/user-directory/views/partials/user-directory-org-chart-item.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsOrgChartItem', OrgChartItemDirective);

/////////////////////////////

export { OrgChartItemDirective };
