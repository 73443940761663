const _preprocessDate = (datetime) => {
    if (!isNaN(parseFloat(datetime)) && isFinite(datetime)) {
        // Milliseconds since the epoch.
        return moment(parseInt(datetime, 10));
    }

    // Else just returns the value as-is.
    return moment(datetime);
};

/////////////////////////////

function DateFormatFilter() {
    'ngInject';

    /**
     * Format a date according to the given format.
     *
     * @param  {Date|string} datetime The datetime to format.
     * @param  {string}      format   The format to use to convert the datetime.
     * @return {Date}        The local datetime.
     */
    return function dateFormat(datetime, format) {
        if (angular.isUndefinedOrEmpty(datetime)) {
            return '';
        }

        const date = _preprocessDate(datetime);
        if (!date.isValid()) {
            return '';
        }

        return date.format(format);
    };
}

angular.module('Filters').filter('dateFormat', DateFormatFilter);

/////////////////////////////

function FromNowFilter() {
    'ngInject';

    /**
     * Display a date using a "from now" format (e.g. "1 minute ago").
     *
     * @param  {Date|string} datetime The datetime to format.
     * @param  {string}      suffix   The suffix to append at the end of the "from now" format.
     * @param  {string}      from     The date to compute from.
     * @return {Date}        The "from now" datetime.
     */
    return function dateFormat(datetime, suffix, from) {
        if (angular.isUndefinedOrEmpty(datetime)) {
            return '';
        }

        datetime = _preprocessDate(datetime);
        const date = moment(datetime);
        if (!date.isValid()) {
            return '';
        }

        const dateFrom = moment(from);
        if (angular.isDefinedAndFilled(from) && dateFrom.isValid()) {
            return date.from(dateFrom, suffix);
        }

        return date.fromNow(suffix);
    };
}

angular.module('Filters').filter('fromNow', FromNowFilter);

/////////////////////////////

function LocalTimeFilter() {
    'ngInject';

    /**
     * Convert a datetime to it's local time counterpart.
     *
     * @param  {Date|string} datetime The datetime to convert.
     * @return {Date}        The local datetime.
     */
    return function localTime(datetime) {
        return moment.isMoment(datetime) ? datetime.local() : undefined;
    };
}

angular.module('Filters').filter('localTime', LocalTimeFilter);

/////////////////////////////

function UTCTimeFilter() {
    'ngInject';

    /**
     * Convert a datetime to it's UTC counterpart.
     *
     * @param  {Date|string} datetime The datetime to convert.
     * @return {Date}        The UTC datetime.
     */
    return function UTCTime(datetime) {
        return moment.utc(datetime);
    };
}

angular.module('Filters').filter('utcTime', UTCTimeFilter);

/////////////////////////////

export { DateFormatFilter, FromNowFilter, LocalTimeFilter, UTCTimeFilter };
