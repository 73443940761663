import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { BlockIframe } from '@lumapps/widget-iframe';
import { PlayerAspectRatio } from '@lumapps/widget-iframe/types';

import { URL_PATTERNS } from '../../constants';
import { useDriveVideoUrl } from '../../hooks/useDriveVideoUrl';
import { useEmbedVideoHTML } from '../../hooks/useEmbedVideoHTML';
import { LegacyVideoProperties } from '../../types';
import { computeIFrameRatio } from '../../utils/computeIFrameRatio';
import { VideoPlaceholder } from './VideoPlaceholder';

import './index.scss';

interface BlockVideoProps {
    /** Legacy widget properties */
    properties?: LegacyVideoProperties;
    /** Sandbox the video */
    needSandbox?: boolean;
}

export const CLASSNAME = 'block-video';

/* istanbul ignore next */
export const BlockVideo: React.FC<BlockVideoProps> = ({ properties = {}, needSandbox }) => {
    const rawEmbedVideoHTML = useEmbedVideoHTML(properties);
    const driveVideoUrl = useDriveVideoUrl(properties);
    const embedVideoHTML = !isEmpty(rawEmbedVideoHTML?.trim()) ? rawEmbedVideoHTML : undefined;
    const { translateObject } = useTranslate();

    if (driveVideoUrl && getConstants().applicationId === AppId.webview) {
        // Private google drive video cannot work in the mobile webview
        // We show a clickable placeholder instead
        return <VideoPlaceholder url={properties.pickedUrl as string} name={properties.pickedName as string} />;
    }

    if (embedVideoHTML && getConstants().applicationId === AppId.webview) {
        const srcMatch = embedVideoHTML.match(/src=["](.*?)[""]/);
        const titleMatch = embedVideoHTML.match(/title=["](.*?)[""]/);
        const src = srcMatch && srcMatch[1];
        const title = titleMatch && titleMatch[1];

        // Like google drive video, sharepoint and microsoftstream videos needs to display a placeholder on webview
        if (src && URL_PATTERNS.microsoft.find((pattern: string) => src.indexOf(pattern) > -1)) {
            return <VideoPlaceholder url={src} name={title || ''} />;
        }
    }

    if (driveVideoUrl) {
        const { pickedId } = properties;

        const blockIFrameProperties = {
            url: driveVideoUrl,
            ...(properties?.style ? { style: properties?.style } : {}),
        };

        return (
            <BlockIframe
                id={pickedId as string}
                properties={blockIFrameProperties}
                aspectRatio={PlayerAspectRatio.wide}
                defaultHeight="auto"
            />
        );
    }

    if (embedVideoHTML) {
        const embedVideoRatio = computeIFrameRatio(embedVideoHTML);

        return (
            <div
                // eslint-disable-next-line lumapps/no-classname-strings, lumapps/no-manual-bems
                className={`${CLASSNAME}--embed`}
                style={{ paddingBottom: `${embedVideoRatio}%` }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: embedVideoHTML }}
            />
        );
    }

    if (needSandbox) {
        const sandboxSrc = translateObject(standardizeTranslateObject(properties.embed));
        if (!sandboxSrc) {
            return null;
        }
        const embedVideoRatio = computeIFrameRatio(sandboxSrc);
        return (
            // eslint-disable-next-line react/jsx-no-comment-textnodes, lumapps/no-classname-strings, lumapps/no-manual-bems
            <div className={`${CLASSNAME}--embed`} style={{ paddingBottom: `${embedVideoRatio}%` }}>
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    sandbox="allow-scripts allow-same-origin"
                    title=""
                    srcDoc={sandboxSrc}
                />
            </div>
        );
    }

    return null;
};
