import React from 'react';

import { UploadDocument, UploadDocumentProps } from '@lumapps/medias-document/components/UploadDocument';

import { useDirectoryEntryImageProps } from '../../hooks/useDirectoryEntryImageProps';

export interface DirectoryEntryImageProps {
    /** callback to be executed once the image has been uploaded */
    onUploaded?: UploadDocumentProps['onDocumentUploaded'];
    /** already selected image */
    selected?: UploadDocumentProps['selectedDocument'];
}

/**
 * Component that manages the image of a directory entry.
 * @param DirectoryEntryImageProps
 * @returns DirectoryEntryImage
 */
export const DirectoryEntryImage: React.FC<DirectoryEntryImageProps> = ({ onUploaded, selected }) => {
    const props = useDirectoryEntryImageProps({ onUploaded, selected });

    return <UploadDocument {...props} />;
};
