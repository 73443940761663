import { FULL_DATE_SIX_FRACTIONAL_SECONDS } from 'common/constants';

import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { NGI_WIDGETS_IN_DESIGNER_FF_TOKEN } from '@lumapps/widget-base/constants';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetContentListController($location, $q, $scope, $state, $timeout, Config, Content, ContentPicker,
        ContentTemplate, CustomContentType, Features, Instance, Layout, MainNav, Notification, Translation, User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Content picker modal identifier - only for basic mode.
         *
         * @type {string}
         */
        var _CONTENT_PICKER_ID = 'widget-content-picker-';

        /**
         * The time to add to the end date in the content list query.
         *
         * @type {string}
         * @readonly
         */
        var _END_TIME_FORMAT = 'T23:59:59.999999';

        /**
         * The time to add to the start date in the content list query.
         *
         * @type {string}
         * @readonly
         */
        var _START_TIME_FORMAT = 'T00:00:00.000000';

        /**
         * Contains the default content list parameters.
         * This default parameters are then derived at each call to customize the call to our needs.
         *
         * @type {Object}
         */
        var _defaultParams = {
            action: 'PAGE_READ',
            onlyFeatureFeeds: false,
            status: [
                Config.CONTENT_STATUS.LIVE.value,
            ],
            type: [
                Config.AVAILABLE_CONTENT_TYPES.PAGE,
                Config.AVAILABLE_CONTENT_TYPES.NEWS,
                Config.AVAILABLE_CONTENT_TYPES.CUSTOM,
            ],
        };

        /**
         * Indicates if it's the first time we get the list of content from the server.
         *
         * @type {boolean}
         */
        var _isFirstFilterize = true;

        /**
         * The parameters of the last called content list.
         *
         * @type {Object}
         */
        var _params;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The index of the currently active item when in highlighted mode.
         *
         * @type {number}
         */
        vm.activeHighlightIndex = 0;

        /**
         * A list of notifications that are related to the content items in this widget list.
         *
         * @type {Array}
         */
        vm.notificationsList = [];

        /**
         * Whether the widget should be displayed in NGI or not.
         */
        vm.isNGICompatible = Utils.isDesignerMode() && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2');

        /////////////////////////////

        vm.Content = Content;
        vm.CustomContentType = CustomContentType;
        vm.Notification = Notification;
        vm.Translation = Translation;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the content list contains unread content.
         */
        function _checkNotifications() {
            if (!vm.widget.properties.notifications ||
                angular.isUndefinedOrEmpty(Notification.displayList(Notification.KEY_UNREAD))) {
                return;
            }

            Utils.empty(vm.notificationsList);

            angular.forEach(vm.widgetListCtrl.items, function forEachContents(content) {
                if (angular.isUndefinedOrEmpty(content) || !Notification.hasUnreadNotifications(content.uid)) {
                    return;
                }

                vm.notificationsList.push(content.uid);
            });
        }

        /**
         * Compute the CSS classes that are specific to the widget content list.
         *
         * @return {Array} A list of CSS classes.
         */
        function _computeClasses() {
            var classes = [];
            var properties = vm.widget.properties;

            if (angular.isDefinedAndFilled(properties.viewMode) && properties.viewMode === 'cover') {
                classes.push('widget-content-list--' + properties.thumbnailPosition + '-thumbnail');
            }

            if (angular.isDefined(properties.perLine) && properties.perLine > 0) {
                classes.push('widget-content-list--group-by-' + properties.perLine);
            }

            if (angular.isDefinedAndFilled(properties.slideshow) && properties.viewMode !== 'vertical') {
                classes.push('widget-content-list--slideshow');
            } else {
                classes.push('widget-content-list--no-slideshow');
            }

            if (Content.isCallInProgress(vm.widgetListCtrl.getListKey())) {
                classes.push('widget-content-list--is-loading');
            }

            if (properties.fullExcerpt) {
                classes.push('widget-content-list--has-full-excerpt');
            }
            
            classes.push('widget-editable');

            return classes;
        }

        /**
         * Compute the theme to use in cover mode.
         * When the widget is larger than small, always use Dark theme so that text is readable in front of the image.
         * When the widget is small, compute the contrast color with the selected background color to use light or
         * dark theme accordingly.
         *
         * @return {string} The new cover theme.
         */
        function _computeCoverTheme() {
            var properties = vm.widget.properties;

            if (properties.viewMode !== 'cover' || properties.thumbnailPosition !== 'background') {
                return undefined;
            }

            properties.coverTheme = (vm.widgetListCtrl.widgetCtrl.widgetSize === 's') ?
                _.get(properties, 'style.content.theme', 'light') : 'dark';

            return properties.coverTheme;
        }

        /**
         * Compute the tabs name for each custom content type tags.
         *
         * @return {Promise} The promise of instance siblings CCTs request.
         */
        function _computeTabsNames() {
            var properties = vm.widget.properties;

            var ccts = (angular.isArray(properties.customContentType)) ?
                properties.customContentType : [properties.customContentType];

            Utils.empty(vm.widgetListCtrl.tabs.items);

            var promises = [];
            var firstTab;
            var tagsById = {};
            angular.forEach(ccts, function forEachCcts(cct) {
                promises.push(CustomContentType.getCustomContentTypesTags(cct).then(
                    function onSiblingsCustomContentTypesTagsGetSuccess(tags) {
                        if (angular.isUndefinedOrEmpty(tags)) {
                            return;
                        }

                        angular.extend(tagsById, _.zipObject(_.map(tags, 'uuid'), tags));
                    }
                ));
            });

            return $q.all(promises).then(function onAllCustomContentTypesTagsSuccess() {
                angular.forEach(properties.customContentTypeTags, function forEachTags(tagUuid) {
                    if (angular.isUndefinedOrEmpty(tagUuid)) {
                        return;
                    }

                    var tag = tagsById[tagUuid];
                    if (angular.isUndefinedOrEmpty(tag) || !Translation.hasTranslations(tag.name)) {
                        return;
                    }

                    var tagObject = {
                        name: Translation.translate(tag.name),
                        uid: tag.uuid,
                    };

                    var tagIndex = properties.customContentTypeTags.indexOf(tag.uuid);
                    if (tagIndex === -1) {
                        vm.widgetListCtrl.tabs.items.push(tagObject);
                    } else {
                        vm.widgetListCtrl.tabs.items[tagIndex] = tagObject;
                    }

                    if (angular.isUndefined(firstTab)) {
                        firstTab = angular.fastCopy(_.last(vm.widgetListCtrl.tabs.items));
                    }
                });

                vm.widgetListCtrl.tabs.items = _.compact(vm.widgetListCtrl.tabs.items);
                vm.widgetListCtrl.selectTab((properties.allTabEnabled) ? undefined : firstTab, false);
            });
        }

        /**
         * List the children of the current content in the main nav.
         */
        function _handleSubList() {
            if (vm.widget.properties.type !== 'subList') {
                return;
            }

            // When listing the children of the current content, our list key is also based on slug.
            var listKey = vm.widgetListCtrl.getListKey() + '-' + $state.params.slug;
            vm.widgetListCtrl.setListKey(listKey);

            var navItem = {};

            // When in designer mode, use the first instance of the current content we can find in the MainNav.
            if (vm.widgetListCtrl.widgetCtrl.designerMode() && angular.isDefinedAndFilled($state.params.key)) {
                navItem = MainNav.findNavItemById($state.params.key) || {};
                // Otherwise, just find the instance of the current content that matches the current slug.
            } else {
                navItem = MainNav.findNavItemBySlug($state.params.slug) || {};
            }

            _params.ids = [];
            angular.forEach(_.get(navItem, 'children', []), function forEachChildren(child) {
                var id = _.get(child, 'uid', _.get(child, 'id'));

                if (angular.isDefinedAndFilled(id)) {
                    _params.ids.push(id);
                }
            });
            _params.queryType = 'getContentByIds';

            Content.cacheFilterize(_params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                listKey, vm.widgetListCtrl.projection);
        }

        /**
         * Initialize the needed projections according to the parameters of the content list.
         *
         * @return {Object} A projection object with the fields to project for each content item.
         */
        function _getProjection() {
            var projection = {
                items: {
                    feedKeys: true,
                    id: true,
                    instance: true,
                    link: true,
                    properties: true,
                    slug: true,
                    type: true,
                    uid: true,
                    renderingType: true,
                },
            };

            var properties = vm.widget.properties;

            if (properties.fullExcerpt) {
                projection.items.template = true;
            }

            if (!properties.hideThumbnail) {
                projection.items.thumbnail = true;
                projection.items.mediaThumbnail = true;
                projection.items.thumbnailBlobkey = true;
            }

            if (angular.isDefinedAndFilled(properties.fields)) {
                angular.forEach(properties.fields, function forEachFields(field) {
                    if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name) || !field.enable) {
                        return;
                    }

                    var fieldName = field.name.toLowerCase();
                    switch (fieldName) {
                        case 'author':
                            projection.items.author = true;
                            projection.items.authorDetails = User.getAuthorProjection();
                            projection.items.writer = true;
                            projection.items.writerDetails = User.getAuthorProjection();
                            break;

                        case 'publication-date':
                            if (Instance.getInstance().googleAnalytics && properties.displayAnalyticsViews) {
                                projection.items.analyticsViewSum = true;
                            }
                            projection.items.publicationDate = true;
                            break;

                        case 'tags':
                            projection.items.customContentType = true;
                            projection.items.customContentTypeDetails = true;
                            projection.items.customContentTypeTags = true;
                            break;

                        case 'title':
                            projection.items.title = true;
                            break;

                        case 'excerpt':
                            projection.items.excerpt = true;
                            break;

                        case 'metadata':
                            projection.items.customContentType = true;
                            projection.items.metadata = true;
                            break;

                        case 'social':
                            projection.items.comments = true;
                            projection.items.liked = true;
                            projection.items.likes = true;
                            projection.items.hasCommentWidget = true;
                            break;

                        default:
                            break;
                    }
                });
            } else {
                if (Instance.getInstance().googleAnalytics && properties.displayAnalyticsViews) {
                    projection.items.analyticsViewSum = true;
                }

                projection.items.author = true;
                projection.items.authorDetails = User.getAuthorProjection();
                projection.items.comments = true;
                projection.items.customContentTypeDetails = true;
                projection.items.customContentTypeTags = true;
                projection.items.excerpt = true;
                projection.items.hasCommentWidget = true;
                projection.items.liked = true;
                projection.items.likes = true;
                projection.items.metadata = true;
                projection.items.publicationDate = true;
                projection.items.title = true;
                projection.items.writer = true;
                projection.items.writerDetails = User.getAuthorProjection();
            }

            return projection;
        }

        /**
         * Initialize the content list.
         * Load the contents from the server.
         *
         * @param {Object}  [additionalFilters]       The additional filters.
         * @param {boolean} [resetSelectedTabs=false] Indicates if we want to reset the selected tab.
         * @param {boolean} [fromInit=false]          Indicates if we are initializing the list from the init of the
         *                                            widget.
         */
        function _initList(additionalFilters, resetSelectedTabs, fromInit) {
            additionalFilters = additionalFilters || {};
            var currentInstanceId = Instance.getCurrentInstanceId();
            var properties = vm.widget.properties;

            if (properties.notifications) {
                Notification.init();
            }

            _params = angular.fastCopy(_defaultParams);

            _params.instanceId = [currentInstanceId];

            // Only Breaking news.
            if (properties.featuredOnly) {
                _params.isFeatured = true;
            }

            _params.onlyFeatureFeeds = Boolean(properties.onlyFeatureFeeds);

            if (angular.isUndefinedOrEmpty(properties.type)) {
                vm.widgetListCtrl.initialized = true;

                return;
            }

            // Skip initial list calls if displayed in NGI
            if(_shouldDisplayAsNGI()) {
                return;
            }

            if (properties.type === 'pick') {
                _params.ids = properties.contents;
                _params.queryType = 'getContentByIds';

                Content.cacheFilterize(_params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                    vm.widgetListCtrl.getListKey(), vm.widgetListCtrl.projection, fromInit);
            } else if (properties.type === 'list' && angular.isDefined(properties.customContentType)) {
                var sortDirection;

                Instance.getSiblings(false).then(function onSiblingsListSuccess(siblings) {
                    if (siblings.length > 1) {
                        if (_.get(properties, 'isAllInstancesSiblings', false)) {
                            _params.instanceId = _.map(siblings, 'id');
                            _params.currentInstanceId = currentInstanceId;
                        } else if (angular.isDefinedAndFilled(properties.instance)) {
                            _params.instanceId = (angular.isArray(properties.instance)) ?
                                properties.instance : [properties.instance];
                            delete _params.currentInstanceId;
                        }
                    }

                    if (_.get(properties, 'isAllInstancesFavorites', false)) {
                        _params.userFavoritesOnly = true;
                    }

                    _params.customContentType = properties.customContentType;

                    if (angular.isDefinedAndFilled(properties.listOrderDir)) {
                        sortDirection = ((properties.listOrderDir.toLowerCase() === 'asc') ? '' : '-');
                    }

                    if (angular.isDefinedAndFilled(properties.listOrder)) {
                        _params.sortOrder = [
                            (angular.isDefined(sortDirection)) ?
                                sortDirection + properties.listOrder : properties.listOrder,
                        ];
                    } else {
                        _params.sortOrder = ['-publicationDate'];
                    }

                    if (angular.isDefinedAndFilled(properties.author)) {
                        _params.author = properties.author;
                    }

                    if (angular.isNumber(properties.maxNumber)) {
                        _params.maxResults = properties.maxNumber;
                    }

                    if (angular.isDefinedAndFilled(additionalFilters.tags)) {
                        _params.customContentTypeTags = additionalFilters.tags;
                    } else if (angular.isDefinedAndFilled(properties.customContentTypeTags)) {
                        if (properties.tabsEnabled && resetSelectedTabs) {
                            vm.widgetListCtrl.tabSelected = (properties.allTabEnabled) ?
                                vm.widgetListCtrl.tabDefault : vm.widgetListCtrl.tabs.items[0];
                        }

                        if (angular.isDefinedAndFilled(vm.widgetListCtrl.tabSelected)) {
                            _params.customContentTypeTags = (vm.widgetListCtrl.tabSelected.uid === 'all') ?
                                properties.customContentTypeTags : vm.widgetListCtrl.tabSelected.uid;
                        }
                    }

                    if (angular.isDefinedAndFilled(properties.metadata) ||
                        angular.isDefinedAndFilled(additionalFilters.metadata)) {
                        var metadata = angular.fastCopy(properties.metadata) || {};

                        if (angular.isDefinedAndFilled(additionalFilters.metadata)) {
                            angular.forEach(additionalFilters.metadata,
                                function forEachAdditionalMetadata(additionalMetadata, metadataId) {
                                    metadata[metadataId] = angular.fastCopy(additionalMetadata);
                                }
                            );
                        }

                        _params.combinedMetadata = [];

                        angular.forEach(metadata, function forEachMetadata(metadataKey) {
                            if ((angular.isString(metadataKey) || angular.isArray(metadataKey)) &&
                                angular.isDefinedAndFilled(metadataKey)) {
                                _params.combinedMetadata.push({
                                    metadata: metadataKey,
                                });
                            }
                        });
                    }

                    if (angular.isDefinedAndFilled(additionalFilters)) {
                        _params.query = additionalFilters.query;
                        _params.onlyFeatureFeeds = (angular.isDefined(additionalFilters.onlyFeatureFeeds)) ?
                            Boolean(additionalFilters.onlyFeatureFeeds) : _params.onlyFeatureFeeds;

                        if (angular.isDefined(additionalFilters.startDate)) {
                            _params.startDate =
                                moment(additionalFilters.startDate).utc().format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
                        }

                        if (angular.isDefined(additionalFilters.endDate)) {
                            _params.endDate =
                                moment(additionalFilters.endDate).utc().format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
                        }

                        if (angular.isDefinedAndFilled(additionalFilters.customContentType)) {
                            _params.customContentType = (angular.isArray(additionalFilters.customContentType)) ?
                                additionalFilters.customContentType : [additionalFilters.customContentType];
                        }

                        if (angular.isDefinedAndFilled(additionalFilters.instance)) {
                            _params.instanceId = (angular.isArray(additionalFilters.instance)) ?
                                additionalFilters.instance : [additionalFilters.instance];
                            delete _params.currentInstanceId;
                        }

                        if (angular.isDefinedAndFilled(additionalFilters.author)) {
                            _params.author = (angular.isDefinedAndFilled(additionalFilters.author.email)) ?
                                additionalFilters.author.email : undefined;
                        }

                        Content.filterize(_params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                            vm.widgetListCtrl.getListKey(), vm.widgetListCtrl.projection);
                    } else if (_isFirstFilterize && vm.widget.isMainWidget &&
                        angular.isDefinedAndFilled($location.search().filters)) {
                        var urlParams = Utils.getSimpleUrlParams($location.search().filters);

                        _params.query = urlParams.query;
                        _params.customContentTypeTags = urlParams.tags;

                        if (angular.isDefinedAndFilled(urlParams.customContentType)) {
                            _params.customContentType = (angular.isArray(urlParams.customContentType)) ?
                                urlParams.customContentType : [urlParams.customContentType];
                        }

                        if (angular.isDefinedAndFilled(urlParams.instance)) {
                            _params.instanceId = (angular.isArray(urlParams.instance)) ?
                                urlParams.instance : [urlParams.instance];
                            delete _params.currentInstanceId;
                        }

                        if (angular.isDefinedAndFilled(urlParams.onlyFeatureFeeds)) {
                            // Check for string because of this param came from an url.
                            _params.onlyFeatureFeeds = (urlParams.onlyFeatureFeeds === 'true');
                        }

                        if (angular.isDefinedAndFilled(urlParams.startDate)) {
                            _params.startDate =
                                moment(urlParams.startDate + _START_TIME_FORMAT).utc()
                                    .format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
                        }

                        if (angular.isDefinedAndFilled(urlParams.endDate)) {
                            _params.endDate = moment(urlParams.endDate + _END_TIME_FORMAT).utc()
                                .format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
                        }

                        if (angular.isDefinedAndFilled(urlParams.metadata)) {
                            urlParams.metadata = (angular.isArray(urlParams.metadata)) ?
                                urlParams.metadata : [urlParams.metadata];

                            _params.combinedMetadata = _params.combinedMetadata || [];
                            angular.forEach(urlParams.metadata, function forEachPropertiesMetadata(metadataKeys) {
                                if (angular.isUndefinedOrEmpty(metadataKeys)) {
                                    return;
                                }

                                // Might contains multiple comma separated values.
                                var values = metadataKeys.split(',');
                                angular.forEach(values, function forEachMetadataKeys(metadataKey) {
                                    if ((angular.isString(metadataKey) || angular.isArray(metadataKey)) &&
                                        angular.isDefinedAndFilled(metadataKey)) {
                                        _params.combinedMetadata.push({
                                            metadata: metadataKey,
                                        });
                                    }
                                });
                            });
                        }

                        Content.filterize(_params, vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.updateListItems,
                            vm.widgetListCtrl.getListKey(), vm.widgetListCtrl.projection);
                    } else {
                        Content.cacheFilterize(_params, vm.widgetListCtrl.updateListItems,
                            vm.widgetListCtrl.updateListItems, vm.widgetListCtrl.getListKey(),
                            vm.widgetListCtrl.projection, fromInit);
                    }

                    _isFirstFilterize = false;
                });
            }

            _handleSubList();
        }

        /**
         * A list of tasks to do when list items is updated.
         */
        function _onListItemsUpdated() {
            _checkNotifications();
        }

        /**
         * Open content picker (only when widget selection type is pick and in basic mode).
         */
        function _openContentPicker() {
            if (vm.widget.properties.type !== vm.widgetListCtrl.SELECTION_TYPES.pick ||
                Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER') 
                || Content.isDesignerInNewSimpleMode()
                || Content.getAction() === 'get') {
                return;
            }
            Utils.waitForAndExecute('#' + _CONTENT_PICKER_ID, function onContentPickerDialogReady() {
                ContentPicker.open(_CONTENT_PICKER_ID);
            });
        }

        /**
         * Reset the index of the active item in the content list in highlight mode.
         */
        function _resetActiveHighlightIndex() {
            vm.activeHighlightIndex = 0;
        }

        /**
         * Indicates if the tabs should be displayed in the widget or not.
         *
         * @return {boolean} Whether the tabs should be displayed or not.
         */
        function _shouldDisplayListTabs() {
            var properties = vm.widget.properties;

            return properties.viewMode !== 'cover' && angular.isDefined(properties.customContentTypeTags) &&
                properties.customContentTypeTags.length > 1;
        }

        /**
         * Initialize the widget properties and the controller parameters.
         *
         * Todo [Arnaud]: there's some backwardCompat stuff in there that need extracting out / removing in the future.
         */
        function _initProperties() {
            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            /*
             * Randomize the key to prevent cache in content list pages.
             * For instance: search "A", click on a result, click on back,
             * the filter is unset so the cache must be voided.
             * TODO [Élie]: remove when filters are handled properly in the url.
             */
            if (vm.widget.isMainWidget) {
                vm.widgetListCtrl.setListKey(vm.widgetListCtrl.getListKey() + generateUUID());
            }

            properties.customContentType = properties.customContentType || [];
            if (!angular.isArray(properties.customContentType)) {
                properties.customContentType = [properties.customContentType];
            }

            // ONLY FOR BACKWARD: convert cct to id. The back will do a moulinette soon.
            angular.forEach(properties.customContentType, function forEachCustomContentType(cct, index) {
                if (angular.isObject(cct) && angular.isDefinedAndFilled([cct.uid, cct.id], 'some')) {
                    properties.customContentType[index] = cct.uid || cct.id;
                }
            });

            // Number max of content.
            if (angular.isDefinedAndFilled(properties.maxNumber) && !angular.isNumber(properties.maxNumber)) {
                properties.maxNumber = parseInt(properties.maxNumber, 10);
            }

            // How many content per line (if slideshow or horizontal display).
            if (angular.isDefinedAndFilled(properties.perLine) && !angular.isNumber(properties.perLine)) {
                properties.perLine = parseInt(properties.perLine, 10);
            }

            properties.contents = properties.contents || [];

            properties.type = properties.type || 'pick';

            properties.instance = properties.type === 'pick' ? [Instance.getCurrentInstanceId()] : properties.instance

            if (angular.isDefinedAndFilled(properties.truncate) && !angular.isNumber(properties.truncate)) {
                properties.truncate = parseInt(properties.truncate, 10);
            }
            if (!angular.isNumber(properties.truncate)) {
                properties.truncate = Instance.getProperty(Config.INSTANCE_PROPERTIES.CONTENT_LIST_EXCERPT);
            }

            // Display uncompressed thumbnail.
            properties.uncompressedThumbnail = (angular.isUndefined(properties.uncompressedThumbnail)) ?
                false : properties.uncompressedThumbnail;

            // Display full content in excerpt.
            properties.fullExcerpt = (angular.isUndefined(properties.fullExcerpt)) ? false : properties.fullExcerpt;

            // Fields manager.
            var defaultContentFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.CONTENT_FIELDS);

            if (angular.isUndefinedOrEmpty(properties.fields)) {
                if (angular.isString(defaultContentFields[0])) {
                    properties.fields = [];

                    angular.forEach(defaultContentFields, function forEachContentFields(contentField) {
                        properties.fields.push({
                            enable: true,
                            name: contentField,
                        });
                    });
                } else {
                    properties.fields = angular.copy(defaultContentFields);
                }
            } else if (angular.isString(properties.fields[0])) {
                var fields = [];

                angular.forEach(properties.fields, function forEachFields(field) {
                    fields.push({
                        enable: true,
                        name: field,
                    });
                });

                angular.forEach(defaultContentFields, function forEachContentField(contentField) {
                    if (angular.isObject(contentField)) {
                        contentField = contentField.name;
                    }

                    if (!_.includes(properties.fields, contentField)) {
                        fields.push({
                            enable: false,
                            name: contentField,
                        });
                    }
                });

                properties.fields = fields;
            }

            if (angular.isUndefinedOrEmpty(properties.viewMode)) {
                properties.viewMode = 'vertical';
            }

            if (angular.isUndefinedOrEmpty(properties.thumbnailPosition)) {
                properties.thumbnailPosition = 'background';
            }

            // If view mode is 'cover' we force slideshow and disable tabs.
            if (properties.viewMode === 'cover') {
                properties.slideshow = true;
                properties.tabsEnabled = false;

                _computeCoverTheme();
            }

            // Clean the tags (and thus tabs) in case some have been deleted.
            // Todo [Arnaud]: this should really be done by the backend because we're doing lots of unecessary calls.
            if (angular.isDefinedAndFilled(properties.customContentTypeTags)) {
                // This will get tags for properties.customContentType and its children CCTs.
                CustomContentType.getTagsForHeaderSelect(
                    properties.customContentType,
                    function onTagListSuccess(tagsWithHeader) {
                        var allTagsUuid = [];

                        angular.forEach(tagsWithHeader, function forEachCCT(values) {
                            allTagsUuid = allTagsUuid.concat(_.map(values, 'uuid'));
                        });

                        properties.customContentTypeTags = _.intersection(properties.customContentTypeTags,
                            allTagsUuid);
                    }, undefined, properties.instance || [Instance.getCurrentInstanceId()]);
            }
        }

        /**
         * When widget size change, content block size change too, update cover theme.
         *
         * @param {Array} params ExecuteChildFunction parameters: previous widget size.
         */
        function _widgetSizeUpdate(params) {
            var currentWidgetSize = vm.widgetListCtrl.widgetCtrl.widgetSize;
            var previousWidgetSize = params[0];

            // Only update theme, size and the content blocks if the widget size has actually changed.
            if (angular.isUndefinedOrEmpty(previousWidgetSize) || previousWidgetSize === currentWidgetSize) {
                return;
            }

            var properties = vm.widget.properties;
            var oldCoverTheme = properties.coverTheme;

            // Update coverTheme.
            var newCoverTheme = _computeCoverTheme();
            var coverTheme = (angular.isDefinedAndFilled(newCoverTheme)) ?
                newCoverTheme : properties.style.content.theme;

            // Update content block and user block theme if theme has changed.
            if (oldCoverTheme !== coverTheme) {
                $scope.$broadcast('widget-content-list-theme-update', vm.widget.uuid, coverTheme);
                vm.widgetListCtrl.computeClasses();
            }

            // Update content block size.
            $scope.$broadcast('widget-content-list-size-update', vm.widget.uuid, currentWidgetSize);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Select an highlighted content.
         *
         * @param {number} [index=0] The index of the content to select.
         */
        function selectHighlight(index) {
            index = (angular.isUndefined(index) || index < 0) ? 0 : index;

            var numberOfResults = vm.widgetListCtrl.items.length;
            index = (index >= numberOfResults) ? numberOfResults : index;

            vm.activeHighlightIndex = index;
        }

      
        function _shouldDisplayAsNGI() {
            return vm.isNGICompatible;
        }

        function setAsNonNgiCompatible() {
            const isNGICompatiblePreviousStatus = vm.isNGICompatible;
            vm.isNGICompatible = false;

            // Set the widget as non NGI compatible will fallback to the 
            // legacy display. So we need to re-init the content list.
            if (isNGICompatiblePreviousStatus) {
                _initList();
            }
        }

        function setAsNgiCompatible() {
            const isInDesignerContext = vm.widgetListCtrl.widgetCtrl.designerMode();
            // Prevent the switch to true if the required FF are not enabled.
            if(isInDesignerContext && Features.hasFeature(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN) && Features.hasFeature('layout-v2')) {
                vm.isNGICompatible = true;
            }
        }

        /////////////////////////////

        vm.selectHighlight = selectHighlight;
        vm.shouldDisplayAsNGI = _shouldDisplayAsNGI;
        vm.setAsNonNgiCompatible = setAsNonNgiCompatible;
        vm.setAsNgiCompatible = setAsNgiCompatible;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the main nav has been loaded, handle sub list.
         */
        $scope.$on('main-nav-loaded', _handleSubList);

        /**
         * When the notifications has been loaded, check them.
         */
        $scope.$on('notifications-loaded', _checkNotifications);

        /**
         * When the style of the content list widget changes, re-compute the cover theme.
         *
         * @param {Event}  evt        The original event triggering this method.
         * @param {string} widgetUuid The identifier of the widget targeted by the event.
         */
        $scope.$on('widget-style-settings', function onWidgetStyleSettings(evt, widgetUuid) {
            if (vm.widget.uuid !== widgetUuid) {
                return;
            }

            var properties = vm.widget.properties;
            var oldCoverTheme = properties.coverTheme;

            // Update coverTheme.
            var newCoverTheme = _computeCoverTheme();

            // Update widget classes and content block even if coverTheme wasn't changed, the size is changed anyway.
            if (angular.isDefinedAndFilled(newCoverTheme) && oldCoverTheme !== newCoverTheme) {
                $scope.$broadcast('widget-content-list-theme-update', vm.widget.uuid, properties.coverTheme);
            }

            $scope.$broadcast('widget-content-list-size-update', vm.widget.uuid);
            vm.widgetListCtrl.computeClasses();
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         *
         * @param {boolean} saveListKey Indicates if we want to save the list key as original.
         */
        vm.init = function init(saveListKey) {
            _CONTENT_PICKER_ID += vm.widget.uuid;
            
            vm.widgetListCtrl.init(saveListKey);

            const isInDesignerContext = vm.widgetListCtrl.widgetCtrl.designerMode();
            // If we are not in the designer context or the content is not v2 compatible
            // we fallback to legacy display (we consider unsaved content as v2 compatible).
            // Here we are trying to guess which display will be the correct one in view mode.
            // This is not perfect, but we want to avoid too much logic in the frontend to know if a content is
            // compatible v2 or not (widget types presents on the content + some specific settings on some widgets)
            if (!isInDesignerContext || (Content.getAction() !== 'create' && Content.getCurrent()?.template.isV2Compatible === false)) {
                setAsNonNgiCompatible();
            }
        };

        /**
         * Set parent controller.
         * @param {Object} widgetListCtrl The parent controller.
         */
        this.setParentController = function setParentController(widgetListCtrl) {
            vm.widgetListCtrl = widgetListCtrl;

            vm.widgetListCtrl.widgetListChildCtrl = {
                computeClasses: _computeClasses,
                computeTabsNames: _computeTabsNames,
                getProjection: _getProjection,
                initList: _initList,
                initProperties: _initProperties,
                notificationsList: vm.notificationsList,
                onListItemsUpdated: _onListItemsUpdated,
                onWidgetListClick: _openContentPicker,
                selectTab: _resetActiveHighlightIndex,
                shouldDisplayListTabs: _shouldDisplayListTabs,
                shouldDisplayAsNGI: _shouldDisplayAsNGI,
                setAsNgiCompatible: setAsNgiCompatible,
                setAsNonNgiCompatible: setAsNonNgiCompatible,
                widgetSizeUpdate: _widgetSizeUpdate,
            };

            vm.init(true);
        };
    }

    /////////////////////////////

    /**
     * The content list widget.
     * Displays a list of content.
     *
     * @param {Object} widget The widget configuration object.
     */

    function WidgetContentListDirective() {
        'ngInject';

        function WidgetContentListLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetContentListController,
            controllerAs: 'vm',
            link: WidgetContentListLink,
            replace: true,
            require: ['widgetContentList', '^widgetList'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-content-list/views/widget-content-list.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetContentList', WidgetContentListDirective);
})();
