export enum WIDGET_COMMUNITY_LIST_KEYS {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452598
     * */
    REQUEST_ACCESS = 'COMMUNITY_REQUEST_ACCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452599
     * */
    REQUEST_ACCESS_PENDING = 'COMMUNITY_REQUEST_ACCESS_PENDING',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452601
     * */
    REQUEST_ALREADY_EXISTS = 'COMMUNITY_REQUEST_ALREADY_EXISTS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452600
     * */
    REQUEST_ALREADY_AUTHORIZED = 'COMMUNITY_REQUEST_ALREADY_AUTHORIZED',
}
