import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ColorPalette, SkeletonTypography, Text, Theme, Typography, ColorVariant } from '@lumapps/lumx/react';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-description` as const;

/**
 * Displays the description of a playlist
 */
export const Description = () => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, playlist, theme } = useBlockPlaylistContext();

    if (!canDisplay('DESCRIPTION')) {
        return null;
    }

    if (isLoading) {
        return (
            <div className={block('skeleton')}>
                {Array.from([1, 2], (item) => (
                    <SkeletonTypography key={item} theme={theme} typography={Typography.body1} width="100%" />
                ))}
            </div>
        );
    }

    const description = typeof playlist.description === 'string' ? playlist.description : playlist.description?.value;

    if (!description) {
        return null;
    }

    return (
        <Text
            as="p"
            className={block()}
            color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
            colorVariant={theme === Theme.light ? ColorVariant.N : ColorVariant.L2}
            truncate={{ lines: 2 }}
            typography={Typography.body1}
        >
            {description}
        </Text>
    );
};
