import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { margin, padding } from '@lumapps/classnames';
import { Alignment, Button, Emphasis, FlexBox, Orientation, TextField, Toolbar } from '@lumapps/lumx/react';
import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { onKey } from '@lumapps/utils/event/onKey';
import { useClickAway } from '@lumapps/utils/hooks/useClickAway';
import { useFocus } from '@lumapps/utils/hooks/useFocus';
import { isUrl } from '@lumapps/utils/string/isUrl';
import { Editor, NodeEntry, ReactEditor } from '@lumapps/wrex/slate';

import { LinkEditor, LinkElement } from '../../../../types';

interface Props {
    editor: ReactEditor & LinkEditor;
    link: LinkElement;
    close(): void;
    className?: string;
}

const isEscape = isHotKey('Escape');

/**
 * Init form using link element and submit edited link.
 */
function useLinkEditForm(editor: LinkEditor, [link, path]: NodeEntry<LinkElement>, close: Props['close']) {
    const initialLabel = Editor.string(editor, path);
    const initUrl = link.href;

    const [label, setLabel] = useState(initialLabel);

    const [url, setUrl] = useState(link.href ?? '');
    const [urlErrorCode, setURLErrorCode] = useState<string>();

    const submit = useCallback(
        (event: SyntheticEvent) => {
            event.preventDefault();

            // Validate form.
            setURLErrorCode(undefined);

            const sanitizedUrl = isUrl(url) && sanitizeUrl(url);
            if (!sanitizedUrl) {
                setURLErrorCode(GLOBAL.ERROR_URL_BAD_FORMAT);
                return;
            }

            editor.wrapLink(editor, sanitizedUrl, path, true, label === initialLabel && !label ? url : label);
            close();
        },
        [url, editor, path, label, initialLabel, close],
    );

    const cancel = useCallback(() => {
        close();

        // Unwrap link when opening the link edit on a paragraph and canceling the edition
        if (isEmpty(initUrl) && !isEmpty(initialLabel)) {
            editor.unwrapLink(editor, path);
        }
    }, [close, editor, initUrl, initialLabel, path]);

    return {
        label: { value: label, onChange: setLabel },
        url: { value: url, onChange: setUrl, error: urlErrorCode },
        submit,
        cancel,
    };
}

export const LinkEdit: React.FC<Props> = ({ editor, link, close, className }) => {
    const { translateKey, translate } = useTranslate();
    const elementPath = ReactEditor.findPath(editor, link as LinkElement);

    const form = useLinkEditForm(editor, [link, elementPath], close);

    // Focus link URL input field.
    const inputRef = useRef<HTMLInputElement>(null);
    useFocus(inputRef);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onEscape = useCallback(
        onKey([
            // Cancel on escape.
            { match: isEscape, handler: form.cancel },
        ]),
        [form.cancel],
    );

    // Cancel on click away.
    const divRef = useRef<HTMLFormElement>(null);
    useClickAway(form.cancel, divRef);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.submit(e);
            }}
            ref={divRef}
            data-testid="wrex-link-edition-popover"
            onKeyDown={onEscape}
            className={className}
        >
            <FlexBox
                className={padding('horizontal', 'big')}
                orientation={Orientation.vertical}
                hAlign={Alignment.center}
            >
                <TextField value={form.label.value} onChange={form.label.onChange} label={translateKey(GLOBAL.TEXT)} />

                <TextField
                    clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                    inputRef={inputRef}
                    value={form.url.value}
                    onChange={form.url.onChange}
                    hasError={Boolean(form.url.error)}
                    error={form.url.error && (translate(form.url.error) as string)}
                    label={translateKey(GLOBAL.LINK)}
                />
            </FlexBox>

            <Toolbar
                after={
                    <>
                        <Button className={margin('left')} onClick={form.cancel} emphasis={Emphasis.low}>
                            {translateKey(GLOBAL.CANCEL)}
                        </Button>
                        <Button className={margin('left')} type="submit" emphasis={Emphasis.low}>
                            {translateKey(GLOBAL.INSERT)}
                        </Button>
                    </>
                }
            />
        </form>
    );
};
LinkEdit.displayName = 'LinkEdit';
