/* istanbul ignore file */
import { isMobileAllowed } from '@lumapps/mobile/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { MobileConfigurationSideNavigation } from './MobileConfigurationSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isMobileAllowed(state);

    return {
        isAllowed,
    };
};

const ConnectedMobileConfigurationSideNavigation = connect(mapStateToProps)(MobileConfigurationSideNavigation);

export { ConnectedMobileConfigurationSideNavigation as MobileConfigurationSideNavigation };
