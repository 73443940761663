import hast from 'hast';
import isEmpty from 'lodash/isEmpty';

import { and } from '@lumapps/utils/function/predicate/and';
import { not } from '@lumapps/utils/function/predicate/not';
import { or } from '@lumapps/utils/function/predicate/or';
import { isUrl } from '@lumapps/utils/string/isUrl';
import { IMAGE_GALLERY_VIEW_MODE, CLASSNAME as IMAGE_GALLERY_CLASSNAME } from '@lumapps/wrex-image-gallery/constants';
import { transform } from '@lumapps/wrex/serialization/html/fromHTML/rehype2slate';
import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchAttribute, matchNode } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { CLASSNAME as ALIGNED_IMAGE_WRAPPER_CLASSNAME } from '../../../components/blocks/AlignedImageWrapper';
import {
    ALIGNED_IMAGE_WRAPPER,
    IMAGE_ALIGNMENTS,
    IMAGE_GROUP,
    IMAGE_WIDTHS,
    CLASSNAME as ENHANCED_IMAGE_CLASSNAME,
} from '../../../constants';
import { AlignedImageWrapperElement, ImageElement, ImageGroupElement } from '../../../types';
import { createAlignedImageWrapper } from '../../../utils/createAlignedImageWrapper';
import { createImageGroup } from '../../../utils/createImageGroup';

type ElementTypes = typeof IMAGE_GROUP;
type ParentInheritedProperties = Partial<{
    alignment: AlignedImageWrapperElement['alignment'];
    width: ImageGroupElement['width'];
    link: ImageElement['link'];
}>;

/**
 * If it's a Lumapps Aligned Image Wrapper, we need to split the image group and
 * the side children, and take the alignment information
 * to continue the transformation with the image group only.
 */
const getAlignmentFromHTML = (isAlignedImageWrapper: boolean, children: hast.Parent['children'], attributes: any) => {
    let imageChildren = children;
    let sideChildren: hast.Parent['children'] = [];

    let alignment: IMAGE_ALIGNMENTS | undefined = attributes?.alignment;

    if (isAlignedImageWrapper) {
        const isLeftAligned = children[0]?.tagName === 'figure';
        const lastIndex = children.length - 1;

        alignment = isLeftAligned ? IMAGE_ALIGNMENTS.left : IMAGE_ALIGNMENTS.right;
        imageChildren = isLeftAligned ? children.slice(0, lastIndex) : children.slice(1);
        sideChildren = isLeftAligned ? children.slice(1) : children.slice(0, lastIndex);
    }

    return {
        alignment,
        imageChildren,
        sideChildren,
    };
};

/**
 * If it's a Lumapps Image Gallery, we need to determine the view mode
 */
const getImageGalleryViewModeFromHTML = (isImageGallery: boolean, imageChildren: hast.Parent['children']) => {
    if (!isImageGallery || imageChildren.length === 0) {
        return undefined;
    }

    if ((imageChildren[0]?.properties as any)?.className.includes('slideshow-view')) {
        return IMAGE_GALLERY_VIEW_MODE.slideshow;
    }

    return IMAGE_GALLERY_VIEW_MODE.mosaic;
};

/**
 * Extract image width from HTML
 * From classname for Lumapps Images and from attributes otherwise
 */
const getImageWidthFromHTML = (isImageGroup: boolean, attributes: any, className: string[]) => {
    if (isImageGroup) {
        /**
         * If it's a Lumapps Image Group, use classname to determine the width
         */
        if (className.includes(`${ENHANCED_IMAGE_CLASSNAME}--half-width`)) {
            return IMAGE_WIDTHS.halfWidth;
        }
        if (className.includes(`${ENHANCED_IMAGE_CLASSNAME}--full-width`)) {
            return IMAGE_WIDTHS.fullWidth;
        }
    }

    // Otherwise, if it's a classic image, we take the width from the attributes
    return attributes?.width;
};

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element | typeof ALIGNED_IMAGE_WRAPPER> } =
    {
        [IMAGE_GROUP]: {
            type: IMAGE_GROUP,
            test: or(
                // We make it for a case when src is undefined (Word desktop)
                and(matchAttribute('src', /./), matchNode({ tagName: 'img' })),
                // If it's a Lumapps Image Group
                and(
                    matchNode({ tagName: 'figure' }),
                    matchAttribute('className', new RegExp(ENHANCED_IMAGE_CLASSNAME)),
                ),
                // If it's a Lumapps Image Gallery
                and(matchNode({ tagName: 'figure' }), matchAttribute('className', new RegExp(IMAGE_GALLERY_CLASSNAME))),
                // If it's a Lumapps Aligned Image Wrapper
                and(
                    matchAttribute('className', new RegExp(ALIGNED_IMAGE_WRAPPER_CLASSNAME)),
                    not(matchAttribute('className', new RegExp(`${ALIGNED_IMAGE_WRAPPER_CLASSNAME}__text-wrapper`))),
                ),
            ),
            testParent: or(
                /**
                 * From Microsoft Word
                 */
                // Image aligned with text
                and(
                    matchNode({ tagName: 'span' }),
                    or(matchAttribute('style', /float\s*:\s*right/), matchAttribute('style', /float\s*:\s*left/)),
                ),
                // Image aligned without text
                and(
                    matchNode({ tagName: 'p' }),
                    or(
                        matchAttribute('style', /text-align\s*:\s*right/),
                        matchAttribute('style', /text-align\s*:\s*left/),
                        matchAttribute('style', /text-align\s*:\s*center/),
                    ),
                ),
                /**
                 * From Google Docs
                 */
                // Image with link
                matchNode({ tagName: 'a' }),
            ),
            getAttributesFromParent: (node: any): ParentInheritedProperties | undefined => {
                const parentInheritedProperties: ParentInheritedProperties = {};

                const style = node.properties?.style;
                if (style) {
                    // Alignment
                    if (style.includes('text-align: right') || style.includes('float: right')) {
                        parentInheritedProperties.alignment = IMAGE_ALIGNMENTS.right;
                    }
                    if (style.includes('text-align: left') || style.includes('float: left')) {
                        parentInheritedProperties.alignment = IMAGE_ALIGNMENTS.left;
                    }
                    // Width
                    if (style.includes('text-align: center')) {
                        parentInheritedProperties.width = IMAGE_WIDTHS.halfWidth;
                    }
                }

                // Link
                if (node.tagName === 'a') {
                    parentInheritedProperties.link = node.properties.href;
                }

                if (!isEmpty(parentInheritedProperties)) {
                    return parentInheritedProperties;
                }

                return undefined;
            },
            transform: (
                { tagName, properties: { alt, src, className }, attributes, children }: any,
                slateChildren,
                settings,
                pasteOptions,
            ) => {
                let images: ImageElement[] = [];
                let hasSrcUrl = false;
                let title = '';

                const isImageGroup = tagName === 'figure' && !!className.includes(ENHANCED_IMAGE_CLASSNAME);
                const isImageGallery = tagName === 'figure' && !!className.includes(IMAGE_GALLERY_CLASSNAME);
                const isAlignedImageWrapper = tagName === 'div' && className.includes(ALIGNED_IMAGE_WRAPPER_CLASSNAME);

                const { alignment, imageChildren, sideChildren } = getAlignmentFromHTML(
                    isAlignedImageWrapper,
                    children,
                    attributes,
                );
                const imageGalleryViewMode = getImageGalleryViewModeFromHTML(isImageGallery, imageChildren);
                const imageWidth = getImageWidthFromHTML(isImageGroup, attributes, className);
                let imageLink = attributes?.link;

                if (isImageGroup || isImageGallery || isAlignedImageWrapper) {
                    /**
                     * If it's from Lumapps render HTML
                     */
                    let tagName = '';
                    JSON.stringify(imageChildren, (key, value) => {
                        /**
                         * Extract images from nested childrens
                         */
                        if (key === 'tagName') {
                            tagName = value;
                            return value;
                        }

                        if (key === 'properties' && tagName === 'img') {
                            const isSrcUrl = isUrl(value.src);
                            if (isSrcUrl) {
                                hasSrcUrl = true;
                            }
                            images.push(
                                isSrcUrl
                                    ? { alt: value.alt, src: value.src }
                                    : { alt: value.alt, tempSrc: value.src, src: '' },
                            );
                        }

                        /**
                         * Extract title from figcaption
                         */
                        if (key === 'value' && tagName === 'figcaption') {
                            title = value;
                        }

                        /**
                         * Extract link from <a>
                         */
                        if (key === 'href' && tagName === 'a') {
                            imageLink = value;
                        }

                        return value;
                    });
                } else {
                    /**
                     * Classic html image
                     */
                    const isSrcUrl = isUrl(src);
                    if (isSrcUrl) {
                        hasSrcUrl = true;
                    }
                    images.push(isSrcUrl ? { alt, src } : { alt, tempSrc: src, src: '' });
                }

                // Loop in every image to add the link if it exists
                if (imageLink) {
                    images = images.map((image) => ({ ...image, link: imageLink }));
                }

                const imageGroup = createImageGroup({
                    // Do not upload external images until we make the uploadExternalImage call async
                    images,
                    external: true,
                    isLoading: !hasSrcUrl,
                    width: imageWidth,
                    title,
                    viewMode: imageGalleryViewMode,
                });

                if (alignment) {
                    const tranformedSideChildren = transform(pasteOptions, sideChildren[0]);

                    return createAlignedImageWrapper(
                        {
                            alignment,
                        },
                        [
                            // If right aligned, add children first (on the left)
                            ...(alignment === IMAGE_ALIGNMENTS.right ? tranformedSideChildren : []),
                            imageGroup,
                            // If left aligned, add children last (on the right)
                            ...(alignment === IMAGE_ALIGNMENTS.left ? tranformedSideChildren : []),
                        ],
                    );
                }

                return imageGroup;
            },
        },
    };
