export enum WIDGET_SURVEY {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454543
     */
    ANSWER_EMPTY = 'WIDGET_TYPE_STANDALONE-QUESTION_NOT_ANSWERED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454539
     */
    ANSWER_SAVED = 'WIDGET_TYPE_STANDALONE-QUESTION_ANSWER_SUCCESS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454555
     */
    ANSWER_UPDATE = 'WIDGET_TYPE_STANDALONE-QUESTION_UPDATE_ANWSER',
}
