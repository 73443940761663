import createSlice from '@lumapps/redux/createSlice';
import { BaseStore } from '@lumapps/redux/types';

import { computePermissions } from '../transforms';
import { PermissionsState } from './types';

export const domain = 'permissions';

let initialState: PermissionsState = {
    instancesSuperAdmin: [],
    isSuperAdmin: false,
    authorizations: {},
    canEditOnlyOwnContentAuthorizations: {},
    canDesignContentTypeAuthorizations: {},
};

/**
 * Since the Legacy application has a lot of complexities, in order to initialize this state,
 * we need to inject the state when we receive the response from the backend, and save it here.
 *
 * Once the redux store begins setup, we hook into one of the angular specific dispatches and update
 * the state with the one processed.
 *
 * TODO: remove this code once we have migrated to React.
 */
function setInitialState(state: any) {
    const permissionsState: any = {
        user: state.user,
        userRoles: state.userRoles,
        myPermissions: state.myPermissions,
        permissions: {},
    };

    computePermissions(permissionsState);

    initialState = permissionsState.permissions;
}

const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        '__angular__/instance/update': (state: BaseStore): void => {
            Object.assign(state, initialState);
        },
    },
});

export { actions, reducer, setInitialState };
