import React from 'react';

import { useForm as useReactHookForm, UseFormProps, UseFormReturn, FieldValues } from 'react-hook-form';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

export interface UseFormOptions {
    /** callback on form submit */
    onSubmit: (data: any, methods?: UseFormReturn) => void;
    /** data related to the form setup and configuration */
    form?: UseFormProps & {
        /**
         * These are the values that are used for the `Default Values Checkbox` feature. These are
         * essentially values that will be added to the form when the user checks on the checkbox
         * `Use Default Values`.
         */
        defaultFormValues?: FieldValues;
        /**
         * These are the values that will be used for initialising the form. Basically, the data that you
         * will want to edit. So if you are creating a new entity, you will pass in the default data
         * for each field. If you are editing an entity, you will pass in the entity with the preloaded data.
         */
        defaultValues?: FieldValues;
        /**
         * IMPORTANT: we advise not to use this property and encourage devs to use the `defaultValues` one.
         *
         * This property should be used when the values to be used on the form are loaded dynamically. We
         * advise not to use this since it will trigger unnecessary re-renders if the `defaultValues` props
         * is already present. We encourage devs to use loading states before showing the form and avoid
         * using this property.
         */
        values?: FieldValues;
    };
    /** status for the current form */
    status?: BaseLoadingStatus;
}

export interface UseFormMethods {
    /** callback to be executed on form submit */
    onSubmit: (data: any) => void;
    /** use form methods */
    methods: UseFormReturn;
    /** default values to be used in the form */
    defaultValues?: FieldValues;
    /** form default values to be used for the default value checkbox feature */
    defaultFormValues?: FieldValues;
    /** status for the current form */
    status?: BaseLoadingStatus;
}

/**
 * Custom hook that wraps react-hook-form
 * @family Forms
 * @param UseForm
 * @returns useForm
 */
export const useForm = ({
    onSubmit,
    form = {},
    status = BaseLoadingStatus.initial,
}: UseFormOptions): UseFormMethods => {
    const defaultValues = {
        ...form.defaultFormValues,
        ...form.defaultValues,
        formLoadingStatus: status,
    };

    const methods = useReactHookForm({
        ...form,
        defaultValues,
    });

    const onFormSubmit = (data: any, e?: React.BaseSyntheticEvent) => {
        if (e) {
            e.preventDefault();
        }

        if (status !== BaseLoadingStatus.loading && data) {
            // Remove `formLoadingStatus` since it should not be in the data
            // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
            const { formLoadingStatus, ...restOfData } = data;

            onSubmit(restOfData, methods);

            /**
             * On submitting the form, react-hook-form does not update the `isDirty`
             */
            methods.reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false });
        }
    };

    /**
     * When the status changes from the outside, we should update the internal values so that
     * it reflects on the different fields that depend on it.
     */
    React.useEffect(() => {
        if (status && status !== BaseLoadingStatus.initial) {
            methods.setValue('formLoadingStatus', status);
        }
    }, [status, methods]);

    return {
        methods,
        onSubmit: methods.handleSubmit(onFormSubmit),
        defaultValues: form ? form.defaultValues || {} : {},
        defaultFormValues: form ? form.defaultFormValues || {} : {},
        status,
    };
};
