import { PLACEMENT, Targets } from '../types';
import { CustomizationComponent } from './components/CustomizationComponent';
import store from './store';
import { useSession } from './useSession';
import { getComponent, computeCustomizationTargets } from './utils';

const {
    isComponentDisabled,
    getTextOverride,
    doesCustomizationExist,
    useIsComponentDisabled,
    disableComponent,
    reset,
    getFunctionalOverride,
    setFunctionalOverride,
    notifySubscribers,
} = store;

export * from './useApi';

export {
    CustomizationComponent,
    PLACEMENT,
    Targets,
    getComponent,
    useSession,
    isComponentDisabled,
    getTextOverride,
    doesCustomizationExist,
    useIsComponentDisabled,
    disableComponent,
    reset,
    computeCustomizationTargets,
    getFunctionalOverride,
    setFunctionalOverride,
    notifySubscribers,
};
