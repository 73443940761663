import React from 'react';

import { Text } from '@lumapps/lumx/react';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { findQueryInText } from '../utils/findQueryInText';

const Content = ({ label, searchQuery }: { label: string; searchQuery?: string }) => {
    if (!label) {
        return null;
    }

    if (!searchQuery) {
        return (
            <Text as="span" key="label">
                {label}
            </Text>
        );
    }

    const textArray = findQueryInText(label, searchQuery);

    return (
        <Text as="span" key="wrappedQuery">
            {/* {label} */}
            {textArray.map(({ index, str, matches }) => {
                const Element = matches ? 'strong' : 'span';
                // eslint-disable-next-line react/no-array-index-key
                return <Element key={index}>{str}</Element>;
            })}
        </Text>
    );
};

/**
 * Display queries within the given label in bold.
 * To avoid errors with browsers' translators, all elements are wrapped in `span` by default
 */
export const LabelWithHighlightedQuery: typeof Content = (props) => {
    const { label } = props;
    return (
        <ErrorBoundary fallback={<Text as="span">{label}</Text>}>
            <Content {...props} />
        </ErrorBoundary>
    );
};
