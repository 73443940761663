/* istanbul ignore file */

import { isDigitalAssistantEnabled } from '@lumapps/digital-assistant/ducks/selectors';
import { isLearningEnabled } from '@lumapps/learning/ducks/selectors';
import { getBackofficeNavigationHeader } from '@lumapps/navigation/ducks/selectors';
import { isSuperAdmin } from '@lumapps/permissions';
import { isPlayVideoEnabled } from '@lumapps/play/ducks/selectors';
import { connect } from '@lumapps/redux/react';

import { SideNavigation } from './SideNavigation';

const mapStateToProps = (state: any) => {
    return {
        navHeaderProps: getBackofficeNavigationHeader(state),
        isSuperAdmin: isSuperAdmin(state),
        enabledFeatures: {
            digitalAssistant: isDigitalAssistantEnabled(state),
            play: isPlayVideoEnabled(state),
            learning: isLearningEnabled(state),
        },
    };
};

const ConnectedSideNavigation = connect(mapStateToProps)(SideNavigation);

export default ConnectedSideNavigation;
