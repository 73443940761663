/* istanbul ignore file */

import { Route } from '@lumapps/router';
import { URL_PREFIX, AppId } from '@lumapps/router/constants';

export const webviewCommunityHomepageByIdPath = `/${URL_PREFIX}/webview/community/home/id/:id`;
export const webviewSpaceHomepageByIdPath = `/${URL_PREFIX}/webview/space/home/id/:id`;

export const webviewCommunityHomepageViewRoute: Route = {
    path: webviewCommunityHomepageByIdPath,
    appId: AppId.webview,
};
export const webviewSpaceHomepageViewRoute: Route = {
    path: webviewSpaceHomepageByIdPath,
    appId: AppId.webview,
};
