import find from 'lodash/find';

import { hexToRGBA } from '@lumapps/utils/color';
import { REFRESH_EVENTS } from '@lumapps/posts/constants';

import { unsharePostFromCommunity } from 'components/components/post/ducks/post_actions';

(function IIFE() {
    // ///////////////////////////

    function PostBlockController(
        $q,
        $element,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $timeout,
        ActivePost,
        Community,
        Config,
        Content,
        Features,
        InitialSettings,
        Instance,
        LsTextEditor,
        Post,
        PostIdea,
        ReduxStore,
        Style,
        Translation,
        User,
        UserAccess,
        Utils,
    ) {
        'ngInject';

        const vm = this;

        // ///////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        // ///////////////////////////

        /**
         * Defines the report list key.
         *
         * @type {string}
         * @constant
         */
        let _REPORT_LIST_KEY = 'report-list-key-';

        /**
         * The current community.
         *
         * @type {Object}
         */
        let _community;

        /**
         * List of views allowing post expand.
         *
         * @type {Array}
         */
        const _EXPANDABLE_VIEWS = ['activities', 'dashboard', 'history', 'posts'];

        /**
         * The base of the moderation details translation key.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        const _MODERATION_DETAILS_BASE_KEY = 'FRONT.COMMUNITY.MODERATION_REPORT';

        /**
         * The possible types of plurals the report details can admit.
         * This is based on how notification works.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        const _MODERATION_PLURALS = {
            MORE: 'MORE',
            ONE: 'ONE',
            TWO: 'TWO',
        };

        /**
         * The base of the 'reported as'  translation key.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        const _REPORTED_AS_BASE_KEY = 'FRONT.COMMUNITY.REPORTED_AS';

        /**
         * Maps legacy fields to fields v2 in posts.
         */
        const LEGACY_FIELDS_TO_V2 = {
            sharedInCommunities: 'SHARES',
            metadata: 'PUBLICATION_INFO',
            title: 'TITLE',
            dates: 'EVENT_DATES',
            content: 'CONTENT',
            attachments: 'ATTACHMENTS',
            comments: 'REACTIONS',
        };

        // ///////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        // ///////////////////////////

        /**
         * The identifier of the merge posts dialog.
         *
         * @type {string}
         * @constant
         */
        vm.MERGE_DIALOG_ID = 'merge-posts-dialog-';

        /**
         * The identifier of the moderate post dialog.
         *
         * @type {string}
         * @constant
         */
        vm.MODERATE_DIALOG_ID = 'moderate-post-dialog-';

        /**
         * The identifier of the report post dialog.
         *
         * @type {string}
         * @constant
         */
        vm.REPORT_DIALOG_ID = 'report-post-dialog-';

        /**
         * A map of actions the user can do (or not) on a post.
         *
         * @type {Object}
         */
        vm.actions = {
            display: false,
            edit: false,
            merge: false,
            mergeAdmin: false,
            pin: false,
            remove: false,
        };

        /**
         * Indicates if the user can unshare the post
         *
         * @type {boolean}
         */
        vm.cansUnshare = false;

        /**
         * A made up commentWidget to allow comments on the post.
         *
         * @type {Object}
         */
        vm.commentWidget = {
            widgetType: InitialSettings.WIDGET_TYPES.COMMENTS,
            shouldDisplayTitle: true,
            isInPostList:
                $state.current.name !== 'app.front.profile' &&
                !($state.current.name === 'app.front.community' && angular.isDefinedAndFilled($stateParams.identifier)),
        };

        /**
         * Check if post is in a community.
         *
         * @type {boolean}
         */
        vm.communityContext = $state.current.name === 'app.front.community';

        /**
         * Go through a list of feed keys and find the associated feeds.
         *
         * @type {Array}
         */
        vm.feedMentioned = [];

        /**
         * The list of fields to display in the post block.
         * Each field has a boolean value indicating if we want to enable it or not.
         *
         * @type {Object}
         */
        vm.fieldsToDisplay = {};

        /**
         * The list of fields to display in the NGI post block.
         * Mapped from the fieldsToDisplay object.
         *
         * @type {Array}
         */
        vm.order = [];

        /**
         * If content is higher than content div when not focus.
         *
         * @type {boolean}
         */
        vm.hasMoreButton = false;

        /**
         * Indicates if the post is being delete after a moderation action.
         *
         * @type {boolean}
         */
        vm.isBeingDeleted = false;

        /**
         * State of the post focus.
         *
         * @type {boolean}
         */
        vm.isFocus = false;

        /**
         * Is the FF to use full react post-block activated
         *
         * @type {boolean}
         */
        vm.isFullReactFeatureEnabled = Features.hasFeature('post-block-full-react');

        /**
         * Indicates if the merge dialog is open or not.
         *
         * @type {boolean}
         */
        vm.isMergeDialogOpen = false;

        /**
         * Indicates if the moderate dialog is open or not.
         *
         * @type {boolean}
         */
        vm.isModerateDialogOpen = false;

        /**
         * Indicates if the report dialog is open or not.
         *
         * @type {boolean}
         */
        vm.isReportDialogOpen = false;

        /**
         * The key used for calls to the Post api.
         *
         * @type {string}
         */
        vm.listKey = undefined;

        /**
         * The message displayed in the moderation alert element.
         * This message contains information about the report made by one or more users.
         *
         * @type {string}
         */
        vm.moderationAlertMessage = '';

        /**
         * The dialog identifier for the moving post dialog.
         *
         * @type {string}
         */
        vm.movePostDialogId = 'community-move-post-dialog';

        /**
         * The dialog identifier for displaying the list of communities the post is visibile in.
         *
         * @type {string}
         */
        vm.postVisibilityDialogId = 'post-visibility-dialog';

        /**
         * The more button background color and gradient.
         * According to widget background color.
         *
         * @type {Object}
         */
        vm.moreButtonStyle = {};

        /**
         * The pinned posts list key.
         *
         * @type {string}
         */
        vm.pinnedListKey = undefined;

        /**
         * The post publication date.
         * If less than 2 days, from now, else, full date.
         *
         * @type {string}
         */
        vm.postDate = undefined;

        /**
         * An object with all the available post types we can create.
         *
         * @type {Object}
         */
        vm.postTypes = InitialSettings.POST_TYPES;

        /**
         * The post publication date.
         * If less than 2 days, from now, else, full date.
         *
         * @type {string}
         */
        vm.sharedDate = undefined;

        /**
         * Sharer = a user if it is not the current connected user
         *
         * @type {string}
         */
        vm.sharer = null;

        /**
         * List of all the possible post view.
         *
         * @type {Object}
         */
        vm.AVAILABLE_VIEW = {
            list: 'posts',
            post: 'post',
        };

        /**
         * The post view (Post alone or List).
         */
        vm.view = $stateParams.view || vm.AVAILABLE_VIEW.list;

        /**
         * Check if the post's community is in the current instance
         */
        vm.belongsToCurentInstance = undefined;

        /**
         * The link object of the post.
         */
        vm.postLink = {
            dataId: null,
            href: null,
            target: null,
        };

        /** Icons to display depending on the post type */
        vm.postTypeIcons = {
            [vm.postTypes.EVENT]: 'calendar' ,
            [vm.postTypes.QUESTION]: 'help',
            [vm.postTypes.IDEA]: 'lightbulb-outline',
            [vm.postTypes.DEFAULT]: 'message-text-outline',
        }

        // ///////////////////////////

        /**
         * Services and utilities.
         */
        vm.ActivePost = ActivePost;
        vm.Community = Community;
        vm.Features = Features;
        vm.Instance = Instance;
        vm.Post = Post;
        vm.PostIdea = PostIdea;
        vm.Translation = Translation;
        vm.User = User;
        vm.Utils = Utils;

        // ///////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        // ///////////////////////////

        /**
         * Initialize the fields to display in the post block.
         */
        function _initFields() {
            let newFields = angular.isUndefined(vm.fields)
                ? Instance.getProperty(Config.INSTANCE_PROPERTIES.POST_FIELDS)
                : vm.fields;

            if (angular.isObject(newFields) && !angular.isArray(newFields)) {
                const formattedFields = [];

                angular.forEach(newFields, function forEachBadlyFormattedFields(enabled, field) {
                    formattedFields.push({
                        enable: enabled,
                        name: field,
                    });
                });

                newFields = formattedFields;
            }

            vm.fieldsToDisplay = {};
            angular.forEach(newFields, function forEachFields(field) {
                if (angular.isUndefinedOrEmpty(field)) {
                    return;
                }

                let newField = field;
                if (!angular.isObject(field)) {
                    newField = {
                        enable: true,
                        name: field,
                    };
                }

                vm.fieldsToDisplay[newField.name] = newField.enable;
            });

            // For now there's only a setting for comments so likes and score are always hidden otherwise.
            vm.fieldsToDisplay.likes = vm.fieldsToDisplay.comments;
            vm.fieldsToDisplay.ideaScore = true;

            // ----- share -- multiposting
            let multipostingEnable = true;

            // check if the admin enabled/disabled the multiposting from the global settings
            const _mpGlobalSetting = find(
                Instance.getProperty(Config.INSTANCE_PROPERTIES.SOCIAL_SETTINGS),
                (item) => item.name === 'multiposting_allowed',
            );
            if (_mpGlobalSetting) {
                multipostingEnable = _mpGlobalSetting.enable;
            }
            vm.fieldsToDisplay.sharePost =
                vm.post.canShare &&
                vm.post.postType !== InitialSettings.POST_TYPES.EVENT &&
                multipostingEnable &&
                vm.fieldsToDisplay.comments;

            /**
             * Map the fieldsToDisplay to the NGI order list.
             * */
            vm.order = Object.keys(vm.fieldsToDisplay)
                .map((key) => vm.fieldsToDisplay[key] && LEGACY_FIELDS_TO_V2[key])
                .filter(Boolean);
        }

        /**
         * Compile more button style according to the parent background color.
         */
        function _setMoreButtonStyle() {
            const backgroundColor = _.get(vm.widgetProperties, 'style.content.backgroundColor', '#ffffff');

            /**
             * TODO [Greg]: Use theme to define the background color (Black or White) when widget background color is
             * transparent.
             */
            const widgetBackgroundColor = backgroundColor === 'transparent' ? '#ffffff' : backgroundColor;

            vm.moreButtonStyle.solidBackground = {
                backgroundColor: widgetBackgroundColor,
            };

            vm.moreButtonStyle.gradientBackground = {
                background: `linear-gradient(to right, ${hexToRGBA(
                    widgetBackgroundColor,
                    0,
                )}, ${widgetBackgroundColor} 100%)`,
            };
        }

        /**
         * Sets the post actions available to the current user.
         */
        function _setPostActions() {
            if (!vm.displayActions) {
                return;
            }

            const user = User.getConnected();
            const isSharedPost = vm.post.visibleInCommunitiesCount && vm.post.visibleInCommunitiesCount > 1;
            const isPostDetails =
                $state.current.name === 'app.front.community' && angular.isDefinedAndFilled($stateParams.identifier);
            const userIsAuthor =
                angular.isDefinedAndFilled(_.get(vm.post, 'authorDetails.id')) &&
                _.get(user, 'id') === vm.post.authorDetails.id;
            const userIsAdmin = Community.isUserAdmin(_community, user);
            const userIsPrimaryCommunityAdmin = Community.isUserAdmin(vm.post.parentContentDetails, user);
            const isInstanceAdmin = User.isInstanceAdmin();
            const hasAdvancedCommunity = Features.hasFeature('advanced_community');
            const canContribute =
                Community.isWriteable(_community) ||
                User.hasSubscriptions(_community.publishers) ||
                _.includes(_community.userKeys, user.id) ||
                _community.privacy === 'open';

            const moveAllowed = !isSharedPost;

            vm.actions = {
                display: !isPostDetails,
                edit: userIsAuthor || UserAccess.isUserAllowed('COMMUNITY_POST_EDIT') || userIsPrimaryCommunityAdmin,
                move: (user.isSuperAdmin || isInstanceAdmin || userIsAdmin) && moveAllowed,
                pin:
                    hasAdvancedCommunity &&
                    vm.communityContext &&
                    (UserAccess.isUserAllowed('COMMUNITY_POST_EDIT') || userIsAdmin),
                remove:
                    user.isSuperAdmin ||
                    isInstanceAdmin ||
                    userIsAuthor ||
                    UserAccess.isUserAllowed('COMMUNITY_POST_DELETE') ||
                    userIsPrimaryCommunityAdmin,
                report:
                    vm.communityContext &&
                    !userIsAuthor &&
                    (user.isSuperAdmin || isInstanceAdmin || userIsAdmin || canContribute),
                /* eslint-disable lumapps/comments-sentences */
                // merge: vm.communityContext && Community.isWriteable(),
                // mergeAdmin: vm.communityContext && userIsAdmin,
                /* eslint-enable lumapps/comments-sentences */
            };

            // Todo [Philippe]: temporarily disabling merge management.
            vm.actions.merge = false;
        }

        /** Init the props required for the post publication description component */
        function _initPostPublicationDescription(){
            vm.postPublicationProps = {
                postShare: {
                    sharedTo: vm.post.parentContentDetails ? {
                        communityId: vm.post.parentContentDetails.id,
                        slug: vm.post.parentContentDetails.slug,
                    } : undefined,
                    siteId: vm.post.instance,
                },
                tags: vm.post.tagsDetails?.map((tag) => ({ tagId: tag.id, name: vm.Translation.translate(tag.name) })),
                ideaStatus: vm.post.postStatusDetails ? {
                    id: vm.post.postStatusDetails.uuid,
                    label: vm.Translation.translate(vm.post.postStatusDetails.title)
                } : undefined
            }
        }

        // ///////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        // ///////////////////////////

        /**
         * Edit the current post.
         */
        function editPost() {
            $rootScope.$broadcast('create-new-post__open', vm.post, _community);
        }

        /**
         * Get post block classes.
         *
         * @return {Array} An array of post block classes.
         */
        function getClasses() {
            const classes = [];

            if (_.some(_.values(vm.actions))) {
                classes.push('post-block--has-actions');
            }

            if (vm.isFocus) {
                classes.push('post-block--is-focus');
            } else {
                classes.push('post-block--is-blur');
            }

            if (vm.theme === 'dark') {
                classes.push('post-block--theme-dark');
            } else {
                classes.push('post-block--theme-light');
            }

            if (vm.viewMode === 'grid') {
                classes.push('post-block--view-mode-grid');
            } else if (vm.viewMode === 'cascade') {
                classes.push('post-block--view-mode-cascade');
            } else {
                classes.push('post-block--view-mode-list');
            }

            if (vm.viewModeVariant === 'ungroup') {
                classes.push('post-block--view-mode-variant-ungroup');
            } else {
                classes.push('post-block--view-mode-variant-group');
            }

            if (vm.post.$hidden) {
                classes.push('post-block--real-time-append');
            }

            return classes;
        }

        /**
         * Get post block style according to widget style properties in ungroup variant.
         *
         * @return {Object} The post block style.
         *
         * Todo [Arnaud]: this should be computed in the higher level list component
         * (computed once for all blocks rather than once PER block).
         */
        function getStyle() {
            if (angular.isUndefinedOrEmpty(vm.widgetProperties)) {
                return {};
            }

            return Style.adjustShadow(Style.getBlockStyle(vm.widgetProperties, vm.viewMode, vm.viewModeVariant));
        }

        /**
         * Go to the post community.
         */
        function goToCommunity() {
            const parent = vm.post.parentContentDetails;

            if (angular.isUndefinedOrEmpty(parent)) {
                return;
            }

            Community.goTo(parent);
        }

        /**
         * Move the post in another community.
         */
        function movePost() {
            Post.setMovedPost(vm.post);

            Utils.waitForAndExecute(`#${vm.movePostDialogId}`);

            $rootScope.$broadcast('move-post__open', vm.post);
        }

        /**
         * Open the merge posts dialog.
         *
         * @param {boolean} isReadMode    Indicates if we want to open the dialog in read mode or manage mode.
         * @param {boolean} [isNewReport] Indicates if we want to create a new merge report.
         */
        function openMergeReport(isReadMode, isNewReport) {
            if (vm.disableMergeLink || (isNewReport && Post.isMergePending(vm.post))) {
                return;
            }

            vm.isMergeDialogOpen = true;

            Utils.waitForAndExecute(`#${vm.MERGE_DIALOG_ID}`, undefined, undefined, undefined, undefined, {
                isReadMode,
            });
        }

        /**
         * When a post is deleted successfully, redirect to community.
         */
        function onRemovePostSuccess() {
            const isPostDetails =
                $state.current.name === 'app.front.community' && angular.isDefinedAndFilled($stateParams.identifier);

            if (isPostDetails) {
                vm.goToCommunity();
            } else {
                $rootScope.$broadcast('refresh-posts', vm.pinnedListKey, false);
                $rootScope.$broadcast('community__post-deleted', vm.post.uid);
            }
        }

        /**
         * On post unpin success.
         */
        function onUnpinSuccess() {
            const postList = _.get(Post._services[vm.pinnedListKey], '_list');

            if (angular.isDefinedAndFilled(postList)) {
                Utils.reject(postList, {
                    uid: vm.post.uid,
                });

                if (angular.isUndefinedOrEmpty(postList)) {
                    $rootScope.$broadcast('refresh-posts', vm.pinnedListKey, false);
                }
            }

            if (Content.getCurrent().type === 'community') {
                $rootScope.$broadcast('reset-slideshow-index');
            }

            $rootScope.$broadcast('post-unpinned', vm.post.uid);
        }

        function onPinSuccess() {
            const postList = _.get(Post._services[vm.pinnedListKey], '_list');
            const wasEmpty = angular.isUndefinedOrEmpty(postList);

            // Go through all the cached lists and add the post we just pinned.
            if (angular.isDefined(postList)) {
                postList.unshift(vm.post);
            }

            if (wasEmpty) {
                $rootScope.$broadcast('refresh-posts', vm.pinnedListKey, false);
            }
        }

        function onDeleteSuccess() {
            if (vm.communityContext) {
                const isPostDetails = angular.isDefinedAndFilled($stateParams.identifier);

                if (isPostDetails) {
                    vm.goToCommunity();
                } else {
                    const postList = _.get(Post._services[vm.listKey], '_list');
                    const pinnedPostList = _.get(Post._services[vm.pinnedListKey], '_list');
                    Utils.reject(postList, {
                        uid: vm.post.uid,
                    });

                    Utils.reject(pinnedPostList, {
                        uid: vm.post.uid,
                    });
                }
            } else {
                $rootScope.$broadcast('community__post-deleted', vm.post.uid);
            }

            $scope.$apply();
        }

        function onMoveSuccess() {
            if (vm.communityContext) {
                const postList = _.get(Post._services[vm.listKey], '_list');
                const pinnedPostList = _.get(Post._services[vm.pinnedListKey], '_list');
                Utils.reject(postList, {
                    uid: vm.post.uid,
                });
                Utils.reject(pinnedPostList, {
                    uid: vm.post.uid,
                });
            } else {
                $rootScope.$broadcast('community__post-moved', vm.post);
            }

            $scope.$apply();
        }

        function onShareSuccess() {
            if (vm.communityContext) {
                $rootScope.$broadcast('refresh-posts', vm.listKey, false);

                if (vm.isPostPinned()) {
                    $rootScope.$broadcast('refresh-posts', vm.pinnedListKey, false);
                }
            }

            $scope.$apply();
        }

        function onUnshareSuccess() {
            if (vm.communityContext) {
                const postList = _.get(Post._services[vm.listKey], '_list');
                Utils.reject(postList, {
                    uid: vm.post.uid,
                });
                $rootScope.$broadcast('refresh-posts', vm.listKey, false);

                if (vm.isPostPinned()) {
                    const pinnedPostList = _.get(Post._services[vm.pinnedListKey], '_list');
                    Utils.reject(pinnedPostList, {
                        uid: vm.post.uid,
                    });

                    if (angular.isUndefinedOrEmpty(pinnedPostList)) {
                        $rootScope.$broadcast('refresh-posts', vm.pinnedListKey, false);
                    }
                }
            }
            $scope.$apply();
        }

        function onCommentHideSuccess() {
            vm.post.comments -= 1;

            $rootScope.$broadcast('post__update', vm.post);
        }

        function onCommentAddSuccess() {
            vm.post.comments += 1;

            $rootScope.$broadcast('post__update', vm.post);
        }

        function onCommentMarkRelevantSuccess(params) {
            vm.post.relevantComment = params?.relevantCommentId;

            $rootScope.$broadcast('post__update', vm.post);
        }

        function onCommentUnmarkRelevantSuccess() {
            vm.post.relevantComment = undefined;

            $rootScope.$broadcast('post__update', vm.post);
        }

        /**
         * Pin the post.
         */
        function pinPost() {
            Post.pin(vm.post, _community.uid, vm.post.title, onPinSuccess, undefined);
        }

        /**
         * Unpin the post
         */
        function unpinPost() {
            Post.unpin(vm.post, _community.uid, vm.onUnpinSuccess);
        }

        /**
         * Opens the report post dialog.
         */
        function reportPost() {
            if (vm.post.alreadyReported) {
                return;
            }

            vm.isReportDialogOpen = true;

            Utils.waitForAndExecute(`#${vm.REPORT_DIALOG_ID}`);
        }

        /**
         * Switch current focus state.
         *
         * @param {Event}   [evt]         The original event triggering this method.
         * @param {boolean} [showComment] Indicates if we need to fucus the comment field.
         */
        function switchFocus(evt, showComment) {
            if (window.getSelection && window.getSelection().toString() != '') {
                return;
            }

            if (
                (angular.isDefinedAndFilled(evt) && evt.target.tagName.toLowerCase() === 'a') ||
                !_.includes(_EXPANDABLE_VIEWS, vm.view)
            ) {
                return;
            }

            vm.isFocus = !vm.isFocus;

            vm.commentWidget.properties.preloadedOnly = vm.displayPreloaded && !vm.isFocus;
            vm.commentWidget.properties.relevantOnly = vm.displayRelevant && !vm.isFocus;

            $timeout(function setFocusOnCommentArea() {
                const elementToScrollTo = $element.find('.comment-field__rich-text-editor textarea');
                if (angular.isDefinedAndFilled(elementToScrollTo) && showComment) {
                    elementToScrollTo.focus();
                }
            }, 250);
        }

        /**
         * The function called by the React post actions when an update is performed.
         * It allows the update to be shared between all the post lists on the page.
         * @param {*} evt The type of event (pin, unpin ...)
         * @param {*} response The response of the call performed, should be a post object.
         */
        function refreshPosts(evt, response) {
            const refresh = {
                [REFRESH_EVENTS.POST_UNPIN]: onUnpinSuccess,
                [REFRESH_EVENTS.POST_PIN]: onPinSuccess,
                [REFRESH_EVENTS.POST_DELETE]: onDeleteSuccess,
                [REFRESH_EVENTS.POST_MOVE]: onMoveSuccess,
                [REFRESH_EVENTS.POST_SHARE]: onShareSuccess,
                [REFRESH_EVENTS.POST_UNSHARE]: onUnshareSuccess,
                [REFRESH_EVENTS.COMMENT_HIDE]: onCommentHideSuccess,
                [REFRESH_EVENTS.COMMENT_ADD]: onCommentAddSuccess,
                [REFRESH_EVENTS.COMMENT_MARK_RELEVANT]: () => onCommentMarkRelevantSuccess(response),
                [REFRESH_EVENTS.COMMENT_UNMARK_RELEVANT]: onCommentUnmarkRelevantSuccess,
            }[evt];

            if (refresh) {
                refresh();
            }

            // We need to perform the post update through a broadcast
            // in order to target all posts with the same id on the page
            // in different post list widgets (useful to target pinned post widget).
            if (response) {
                $rootScope.$broadcast('react-post-update', { evt, post: response });
            }
        }

        $scope.$on('react-post-update', (event, { evt, post }) => {
            if (vm.widgetProperties.pinnedOnly && evt === REFRESH_EVENTS.POST_UNPIN) {
                return;
            }
            if (post.uid === vm.post.uid) {
                angular.extend(vm.post, post);
                if (post.pinnedInCommunitiesDetails === undefined) {
                    delete vm.post.pinnedInCommunitiesDetails;
                }
                $scope.$apply();
            }
        });

        // --------- SHARE / Multi post -----------
        function requestSharePost() {
            if (vm.post.canShare) {
                $rootScope.$broadcast('community-multi-select__open', { post: vm.post });
            }
        }

        vm.unsharePost = () => {
            ReduxStore.store.dispatch(unsharePostFromCommunity(vm.post.uid, _community.uid, vm.listKey));
        };

        /**
         * Indicates if we should display the sharedBy block (sBb) in the post.
         *
         * the conditions are:
         *     - the current context community is not the primary community of the post
         *     - the post has been shared with at least one community
         *     - the current context community is one of the community this post has been shared with
         *
         * @return {boolean}
         */
        vm.shouldDisplaySharedByInfo = () =>
            _community.uid !== vm.post.externalKey &&
            vm.post.visibleInCommunitiesCount > 1 &&
            vm.post.visibleInCommunitiesDetails &&
            vm.post.visibleInCommunitiesDetails.some((elt, index) => index > 0 && elt.postedTo.uid === _community.uid);

        /**
         * Indicates if this post is shared with one or several communities hidden to the current user.
         */
        vm.isSharedInPrivateCommunities = () =>
            angular.isDefined(vm.post.visibleInCommunitiesDetails)
                ? vm.post.visibleInCommunitiesDetails.length < vm.post.visibleInCommunitiesCount
                : false;

        /**
         * Returns the list of communities this post is shared with.
         * If in the context of a community, the current community is filtered out.
         * Else filter out the primary community since it will be handled by the `in xxxx` feature
         */
        vm.getPostVisibilityCommunityList = () =>
            vm.communityContext
                ? vm.post.visibleInCommunitiesDetails.filter((community) => _community.uid !== community.postedTo.uid)
                : vm.post.visibleInCommunitiesDetails.filter(
                      (community) => community.postedTo.uid !== vm.post.externalKey,
                  );

        /**
         * Returns the number of communities this post is shared with that are private to the current user.
         */
        vm.getPrivateCommunitiesCount = () =>
            angular.isDefined(vm.post.visibleInCommunitiesDetails)
                ? vm.post.visibleInCommunitiesCount - vm.post.visibleInCommunitiesDetails.length
                : 0;

        /**
         * Opens the community blocks list dialog showing the visibility of the post.
         *
         */
        vm.openPostVisibilityDialog = () => {
            $rootScope.$broadcast(`${vm.postVisibilityDialogId}__open`, {
                post: vm.post,
                currentContextCommunityId: vm.communityContext ? _community.uid : undefined,
                isSharedInPrivateCommunities: vm.isSharedInPrivateCommunities(),
            });
        };

        /**
         * Returns details about the user who shared the post
         */
        function _setSharerDetails() {
            if (vm.post.visibleInCommunitiesDetails) {
                const _share = find(
                    vm.post.visibleInCommunitiesDetails,
                    (aShare) => _community.uid === aShare.postedTo.uid,
                );
                if (_share) {
                    // actually it shouldn't/can't be undefined...but just in case
                    vm.canUnshare = _share.canUnshare || false;
                    vm.sharer = _share.postedBy;
                    vm.sharerAvatar = User.getAvatarUrl(_share.postedBy.id);
                    vm.sharerName = User.getUserFullName(_share.postedBy);
                    vm.sharedDate = _share.postedAt;
                }
            }
        }

        /**
         * check if the post is pinned in the current community
         */
        function isPostPinned() {
            return (
                find(vm.post.pinnedInCommunitiesDetails, (pinEntry) => _community.uid === pinEntry.pinnedIn.uid) !=
                undefined
            );
        }

        /**
         * initialize vm.postLink - used to be done only if the post had a title as the title was a link
         * to the post. Now we can also share the post or copy the post link so we need to initialize vm.PostLink whether
         * there is a title or not.
         */
        function initPostLink() {
            if (!vm.belongsToCurentInstance && angular.isUndefinedOrEmpty(vm.postLink.href)) {
                Post.getDestinationAsPromise(vm.post).then((result) => {
                    vm.postLink = {
                        ...vm.postLink,
                        instanceId: vm.post.instance,
                        href: result.destination,
                        target: '_blank',
                    };
                });
            } else {
                vm.postLink = {
                    ...vm.postLink,
                    instanceId: vm.post.instance,
                    dataId: vm.Post.displayPostTitleAttributes['data-id'],
                    href: $state.href('app.front.community', {
                        identifier: vm.post.uid,
                        slug: vm.Translation.translate(vm.post.parentContentDetails.slug),
                        view: 'post',
                    }),
                };
            }
        }

        // ///////////////////////////
        vm.editPost = editPost;
        vm.getClasses = getClasses;
        vm.getStyle = getStyle;
        vm.goToCommunity = goToCommunity;
        vm.isPostPinned = isPostPinned;
        vm.movePost = movePost;
        vm.onRemovePostSuccess = onRemovePostSuccess;
        vm.onUnpinSuccess = onUnpinSuccess;
        vm.openMergeReport = openMergeReport;
        vm.pinPost = pinPost;
        vm.requestSharePost = requestSharePost;
        vm.reportPost = reportPost;
        vm.switchFocus = switchFocus;
        vm.unpinPost = unpinPost;
        vm.refreshPosts = refreshPosts;

        // ///////////////////////////
        //                         //
        //          Events         //
        //                         //
        // ///////////////////////////

        /**
         * Update the post object once it is saved.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {Object} response The response from the post.save api call.
         */
        $scope.$on('community__post-saved', function onPostSaved(evt, response) {
            if (response.uid !== vm.post.uid) {
                return;
            }

            vm.post = response;
            vm.init();
        });

        /**
         * Update the post score after a vote.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {Object} response The post updated with the new score.
         */
        $scope.$on('post__vote-done', function onVoteDone(evt, response) {
            if (response.uid !== vm.post.uid) {
                return;
            }

            vm.post.score = response.score;
            vm.post.userVote = response.userVote;

            vm.init();
        });

        /**
         * Update the post object once it is moved.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {Object} response The post updated after moving community.
         */
        $scope.$on('community__post-moved', function onPostSaved(evt, response) {
            if (response.uid !== vm.post.uid) {
                return;
            }

            if (vm.communityContext) {
                $rootScope.$broadcast('refresh-posts', vm.pinnedListKey, false);
            } else {
                vm.post = response;
                vm.init();
            }
        });

        /**
         * Synchronise the number of likes between the pinned post view and the post view (i.e. regular community list).
         *
         * @param {Event}  evt  The original event triggering this method.
         * @param {Object} post The post object that just got liked / unliked.
         */
        $scope.$on('post__update', function onPostUpdateLike(evt, post) {
            if (!vm.communityContext || vm.post.uid !== post.uid) {
                return;
            }

            vm.post.relevantComment = post.relevantComment;
            vm.post.comments = post.comments;
            vm.post.liked = post.liked || false;
            vm.post.likes = post.likes;
        });

        /**
         * When a post has been moderated, remove the moderation banner message.
         *
         * @param {Event}  evt    The original event triggering this method.
         * @param {string} postId The id of the post post.
         */
        $scope.$on('post-moderated', function onPostModerated(evt, postId, deleted) {
            if (vm.post.uid !== postId) {
                return;
            }

            if (deleted) {
                vm.isBeingDeleted = true;
            } else {
                delete vm.post.reportStatus;
                vm.post.alreadyReported = false;
            }
        });

        /**
         * Reset the status of the merge posts dialog when it's being closed.
         *
         * @param {Event}  evt      The event triggering this method
         * @param {string} dialogId The identifier of the dialog that triggered this function.
         */
        $scope.$on('lx-dialog__close-end', function onDialogClose(evt, dialogId) {
            if (dialogId === vm.MERGE_DIALOG_ID) {
                vm.isMergeDialogOpen = false;
            } else if (dialogId === vm.REPORT_DIALOG_ID) {
                vm.isReportDialogOpen = false;
            }
        });

        // ///////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            _community = Community.getCurrent() || _.get(vm.post, 'parentContentDetails');
            vm.community = _community;
            const regex = new RegExp('[a-z0-9]{8}-[a-z0-9]{4}-4[a-z0-9]{3}-[a-z0-9]{4}-[a-z0-9]{12}');

            if (angular.isDefinedAndFilled(vm.post.title)) {
                angular.forEach(vm.post.title, function forEachTitleTransations(translation, index) {
                    if (
                        angular.isDefinedAndFilled(translation) &&
                        (translation.match(regex) || translation === 'USELESS TITLE')
                    ) {
                        vm.post.title[index] = '';
                    }
                });
            }

            // Set focus, if post view focus is obviously true and the state cannot be changed.
            if (vm.view === vm.AVAILABLE_VIEW.post) {
                vm.isFocus = true;
            }

            const postId = _.get(vm.post, 'uid', '');

            vm.MERGE_DIALOG_ID += _.get(_community, 'uid', '');
            vm.REPORT_DIALOG_ID += postId;
            vm.MODERATE_DIALOG_ID += postId;

            // Init list keys.
            if (vm.communityContext) {
                vm.listKey = `community-${_community.uid}`;
                vm.pinnedListKey = Object.keys(vm.Post._services).filter(
                    (entry) => entry.indexOf(`community-pinned-${_community.uid}`) === 0,
                )[0];

                if (_.get(vm.post, 'parentContentDetails.id') === _community.uid) {
                    vm.post.parentContentDetails.canMarkRelevant = _community.canMarkRelevant;
                }

                // Remove tag that doesn't exist in the community anymore (in case of community edit).
                if (angular.isArray(vm.post.tagsDetails)) {
                    const availableTagsId = _.map(_community.tagsDetails, 'uid');

                    vm.post.tagsDetails = vm.post.tagsDetails.filter(function isAvailable(tag) {
                        return _.includes(availableTagsId, tag.uid);
                    });

                    // Reset tags list.
                    vm.post.tags = _.map(vm.post.tagsDetails, 'uid');
                }

                // Check if status still exist (after community edition).
                const currentStatus = _.filter(_community.postStatuses, {
                    uid: vm.post.postStatus,
                });
                if (angular.isUndefined(currentStatus)) {
                    vm.post.postStatus = undefined;
                }
            }

            vm.belongsToCurentInstance = vm.post.instance === Instance.getCurrentInstanceId();

            /* Init tagz because react-watch from react-element-directive is buggy. If the value is undefined, it inits with an empty object...  */
            if (!angular.isDefinedAndFilled(vm.post.tagz)) {
                vm.post.tagz = [];
            }

            // Init feed mentioned.
            if (angular.isDefinedAndFilled(vm.post.content)) {
                vm.feedMentioned = LsTextEditor.getFeedMentionedFromFeedKeys(
                    Translation.translate(vm.post.content),
                    vm.post.mentionsFeedKeys,
                );
            }

            // Init fields.
            _initFields();

            // Init post link
            initPostLink();

            // Init post date.
            if (moment().diff(moment(vm.post.publicationDate), 'days') < 2) {
                vm.postDate = moment
                    .utc(vm.post.publicationDate)
                    .local()
                    .fromNow();
            } else {
                vm.postDate = moment
                    .utc(vm.post.publicationDate)
                    .local()
                    .format('LL');
            }

            // Init lines limit.
            if (angular.isUndefinedOrEmpty(vm.linesLimit)) {
                vm.linesLimit = 10;
            }

            vm.displayActions = angular.isUndefinedOrEmpty(vm.displayActions) ? true : vm.displayActions;

            // Set post actions.
            _setPostActions();
            // Set post publication description
            _initPostPublicationDescription();
            // Get post instance details.
            if (vm.displayInstance || vm.post.instance !== Instance.getCurrentInstanceId()) {
                Instance.getInstanceById(vm.post.instance, function onInstanceGet(response) {
                    vm.instanceDetails = response;
                });
            }

            vm.disableMergeLink = angular.isUndefinedOrEmpty(vm.disableMergeLink) ? false : vm.disableMergeLink;

            // Widget comment.
            // TODO: Set style v3 structure.
            _.set(vm.commentWidget, 'properties.style.global', {});
            _.set(vm.commentWidget, 'properties.style.content', {});

            vm.commentWidget.uuid = vm.post.uid;

            vm.commentWidget.properties.preloadedOnly = vm.displayPreloaded && !vm.isFocus;
            vm.commentWidget.properties.relevantOnly = vm.displayRelevant && !vm.isFocus;

            vm.commentWidget.properties.style.global.borderRadius = 0;
            vm.commentWidget.properties.style.global.boxShadow = 0;
            vm.commentWidget.properties.style.content.paddingTop = 0;
            vm.commentWidget.properties.style.content.paddingRight = 0;
            vm.commentWidget.properties.style.content.paddingBottom = 0;
            vm.commentWidget.properties.style.content.paddingLeft = 0;
            vm.commentWidget.properties.style.content.backgroundColor = 'transparent';
            vm.commentWidget.properties.style.content.theme = vm.theme;

            // Check content height.
            $timeout(function checkContentHeight() {
                if ($element.find('.ls-rich-text').outerHeight() > vm.linesLimit * 20) {
                    vm.hasMoreButton = true;
                } else {
                    vm.hasMoreButton = false;
                }
            }, 500);

            // Look for parent background color for the more button.
            _setMoreButtonStyle();

            // Share details
            _setSharerDetails();
            const { $promise, ...post } = vm.post;
            ReduxStore.store.dispatch({
                type: 'post/UPDATE_POST',
                payload: { postId: vm.post.uid, post },
            });
        };

        // ///////////////////////////

        vm.init();
    }

    // ///////////////////////////

    /**
     * A directive that displays a post within a list. Note, this is also used for the post 'details' page.
     *
     * @param {Function} [back]                A function to execute when clicking on the 'back to community' link.
     * @param {boolean}  [disableMergeLink]    Indicates if the duplicate links open the merge dialog or not.
     * @param {boolean}  [displayActions=true] Indicates if the post actions should be displayed or not.
     * @param {boolean}  [displayCommunity]    Indicates if the community the post belongs to should be displayed.
     * @param {boolean}  [displayInstance]     Indicates if the instance the post belongs to should be displayed or not.
     * @param {boolean}  [displayPreloaded]    Indicates if the preloaded comment should be displayed or not when not
     *                                         focused.
     * @param {boolean}  [displayRelevant]     Indicates if the relevant comment should be displayed when not focused.
     * @param {Object}   [fields]              The fields required when displaying the post.
     * @param {boolean}  [hidePinIndicator]    Indicates if the pinned indicator should be hidden or not.
     * @param {integer}  [linesLimit=10]       The number of lines to display when not focus.
     * @param {Object}   post                  The post object to display.
     * @param {string}   [theme]               The name of the theme to apply to the post block.
     * @param {string}   [viewMode]            The widget view mode.
     * @param {string}   [viewModeVariant]     The widget view mode variant.
     * @param {Object}   [widgetProperties]    The widget properties.
     */

    function PostBlockDirective() {
        'ngInject';

        function link(scope) {
            const postUid = scope.vm.post.uid;
            const actionName = 'post-block-loaded';
            /**
             * If the metadata field is enabled, we should wait for the user-block
             * to be loaded before setting the post-block as loaded.
             *
             * If not, set it directly to loaded.
             *
             * We should also set it directly to loaded when react BlockPost
             * is used via the data adapting component FromLegacyBlockPost
             */
            if (!scope.vm.isFullReactFeatureEnabled && scope.vm.fieldsToDisplay.metadata) {
                scope.$on('user-block-loaded', function onUserBlockLoaded() {
                    scope.$emit(actionName, postUid);
                });
            } else {
                scope.$emit(actionName, postUid);
            }
        }

        return {
            bindToController: true,
            controller: PostBlockController,
            controllerAs: 'vm',
            link,
            replace: true,
            restrict: 'E',
            scope: {
                back: '&?',
                commentsListKey: '@?',
                disableMergeLink: '<?',
                displayActions: '<?',
                displayCommunity: '<?',
                displayInstance: '<?',
                displayPreloaded: '<?',
                displayRelevant: '<?',
                fields: '<?',
                hidePinIndicator: '<?',
                linesLimit: '<?',
                post: '<',
                theme: '@?',
                viewMode: '@?',
                viewModeVariant: '@?',
                widgetProperties: '=?',
            },
            templateUrl: '/client/front-office/modules/communities/post/views/post-block.html',
        };
    }

    // ////////////////////

    angular.module('Directives').directive('postBlock', PostBlockDirective);
})();
