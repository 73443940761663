(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetStandAloneQuestionSettingsController($rootScope, $timeout, Question, Widget) {
        'ngInject';

        var widgetQuestionSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Determine if users already answer the question.
         *
         * @type {boolean}
         */
        widgetQuestionSettings.hasStateCount = false;

        /**
         * Settings of the question.
         *
         * @type {Object}
         */
        widgetQuestionSettings.questionSettings = {};

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a choice to a multiple question.
         */
        function addEmptyChoice() {
            Widget.getCurrent().properties.questionSettings.setChoice({
                value: {},
            });
        }

        /**
         * Change class type by 'downcasting'.
         */
        function changeQuestionType() {
            var props = Widget.getCurrent().properties;

            // Down cast.
            props.questionSettings = Question.buildQuestion(props.questionSettings);

            // Reset stats.
            props.stats = {};
        }

        /**
         * Save previous question state (if open or close).
         *
         * @param {boolean} newState The new state of the question.
         */
        function updateState(newState) {
            Widget.getCurrent().properties.lastCloseState = !newState;
        }

        /**
         * Update widget on settings change.
         */
        function updateWidget() {
            $timeout(function sendWidgetSettingsUpdateBroadcast() {
                $rootScope.$broadcast('widget-standalone-question-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetQuestionSettings.addEmptyChoice = addEmptyChoice;
        widgetQuestionSettings.changeQuestionType = changeQuestionType;
        widgetQuestionSettings.updateWidget = updateWidget;
        widgetQuestionSettings.updateState = updateState;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var widgetProperties = Widget.getCurrent().properties;

            widgetQuestionSettings.hasStateCount = _.get(widgetProperties, 'stats.count', 0) > 0;

            if (angular.isDefinedAndFilled(widgetProperties.questionSettings)) {
                widgetProperties.questionSettings = Question.buildQuestion(widgetProperties.questionSettings);
            } else {
                // /!\ open question are disabled so we build a new choices question.
                widgetProperties.questionSettings = new Question.ChoicesQuestion();
            }

            widgetProperties.stats = widgetProperties.stats || {};
        }

        init();
    }

    angular.module('Controllers').controller('WidgetStandAloneQuestionSettingsController',
        WidgetStandAloneQuestionSettingsController);
})();
