import { Program } from '@lumapps/sa-programs/types';
import { TranslatableObject } from '@lumapps/translations';

export enum TopicStatuses {
    live = 'live',
    disabled = 'disabled',
    deleted = 'deleted',
}

export enum FetchInitialTopicsStatuses {
    initial = 'initial',
    loading = 'loading',
    failure = 'failure',
    success = 'success',
}

export interface Topic {
    id: string;
    createdAt: string;
    updatedAt: string;
    program: Program;
    status: TopicStatuses;
    name: TranslatableObject;
}

export interface TopicsEdition<T> {
    added: Array<{ name: T }>;
    updated: Array<{ id: string; name: T }>;
    deleted: Array<{ id: string }>;
}

export type TopicListItem = {
    id: string;
    name: string;
    isNew?: boolean;
};

export type TopicList = {
    count: number;
    maxResults: number;
    items: TopicListItem;
};
