export const WIDGET_EVENT_LIST_TYPE = 'event-list';

export const DATA_SCOPE = 'widget-event-list-settings';

export enum FILTERING_VALUES {
    ALL_EVENTS = 'all',
    PAST_ONLY = 'past',
    PAST_AND_ONGOING = 'past_and_ongoing',
    ONGOING_ONLY = 'ongoing',
    ONGOING_AND_FUTURE = 'ongoing_and_future',
    FUTURE_ONLY = 'future',
}

export enum SORTING_VALUES {
    OLDEST_TO_LATEST = 'asc',
    LATEST_TO_OLDEST = 'desc',
}

export enum INFORMATION_DISPLAYED {
    INTRO = 'intro',
    LOCATION = 'location',
    GUESTS = 'guests',
    LINK = 'link',
}
