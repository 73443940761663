export const WIDGET_HTML_TYPE = 'html';

/** Classname of an html widget 'block' in legacy (used in the widget summary for generation)
 *
 * **WARNING**: When the widget html will use the react BlockHtml, an update of the classname
 * needs to be done on the widget summary
 */
export const LEGACY_BLOCK_HTML_CLASSNAME = 'widget-html__content';

/** Classname of an html widget in edition in legacy (used in the widget summary for generation)
 *
 * **WARNING**: When the widget html will use a react EditableWidgetHtml, an update of the classname
 * needs to be done on the widget summary
 */
export const LEGACY_EDITION_HTML_CLASSNAME = 'widget-html__wysiwyg';

export const BLOCK_HTML_CLASSNAME = 'block-html';
