(function IIFE() {
    'use strict';

    /////////////////////////////

    function MediaPickerFilterController($scope, Config, Instance, LxDropdownService, Tag, Utils) {
        'ngInject';

        // Private member.
        var vmFilter = this;
        var vm;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The dropdown filter identifier.
         *
         * @type {string}
         * @constant
         * @readonly;
         */
        vmFilter.DROPDOWN_ID = 'media-picker-filter-dropdown';

        /**
         * The dropdown filter opening target.
         *
         * @type {string}
         * @constant
         * @readonly;
         */
        vmFilter.DROPDOWN_TARGET = 'media-picker-filter-dropdown-target';

        /**
         * The list key of the tag list.
         *
         * @type {string}
         * @constant
         */
        vmFilter.TAG_LIST_KEY = '';

        /**
         * The list of available media types.
         *
         * @type {Array}
         * @readonly
         */
        vmFilter.AVAILABLE_MEDIA_TYPES = [{
            value: 'IMAGE',
        }, {
            value: 'OTHER',
        }];

        /**
         * The object filter.
         *
         * @type {Object}
         */
        vmFilter.filter = {};

        /**
         * Determine if the main filter has to stay open.
         *
         * @type {Object}
         */
        vmFilter.hasMainFilterToBeOpen = false;

        /**
         * The header filter close status.
         *
         * @type {boolean}
         */
        vmFilter.searchFilterClosed = {
            main: true,
            secondary: true,
        };

        /////////////////////////////

        /**
         * Services and utilities.
         */

        vmFilter.Tag = Tag;
        vmFilter.Instance = Instance;
        vmFilter.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Retrieve tag list for the media filter.
         */
        function _filterTags() {
            Tag.filterize({
                instance: vm.current.instanceId,
                kind: Config.TAG_TYPE.MEDIA,
                maxResults: 100,
            }, function onTagFilterizeSuccess(response) {
                vmFilter.mediaTags = response;
            }, Utils.displayServerError, vmFilter.TAG_LIST_KEY);
        }

        /**
         * Reset filters model.
         */
        function _resetFiltersModel() {
            angular.forEach(vmFilter.AVAILABLE_MEDIA_TYPES, function forEachType(type) {
                delete type.checked;
            });

            angular.forEach(vmFilter.mediaTags, function forEachTag(tag) {
                delete tag.checked;
            });

            vmFilter.filter = {
                query: undefined,
                tags: [],
                type: [],
            };
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Open or Close the filter select.
         */
        function actionFilterShow() {
            LxDropdownService.open(vmFilter.DROPDOWN_ID, '#' + vmFilter.DROPDOWN_TARGET);
        }

        /*
         * Close advanced filters dropdown.
         *
         * @param {Event} evt The click event.
         */
        function closeDropdown(evt) {
            if (angular.isDefinedAndFilled(evt)) {
                evt.stopPropagation();
            }

            LxDropdownService.close(vmFilter.DROPDOWN_ID);
        }

        /**
         * Execute a debounced "execute filter".
         */
        function onFilterChange() {
            vm.debouncedChangeSearchParameters(vmFilter.filter);
        }

        /**
         *  Add or remove media type in filter depending of the status of the checkbox.
         *
         * @param {Object} mediaType The media type.
         */
        function onMediaTypeFilterChange(mediaType) {
            var types = vmFilter.filter.type;

            if (mediaType.checked) {
                types.push(mediaType.value);
            } else {
                types.splice(types.indexOf(mediaType.value), 1);
            }
        }

        /**
         * Add or remove media tag from filters depending of the status of the checkbox.
         *
         * @param {Object} mediaTag The tag to add or remove from filters.
         */
        function onMediaTagFilterChange(mediaTag) {
            var tags = vmFilter.filter.tags;

            if (mediaTag.checked) {
                tags.push(mediaTag.uid);
            } else {
                tags.splice(tags.indexOf(mediaTag.uid), 1);
            }

            onFilterChange();
        }

        /**
         * Reset the filters.
         */
        function resetFilters() {
            _resetFiltersModel();
            onFilterChange();
        }

        /**
         * Turns the lx-select selection into a file type string.
         *
         * @param {string}   selectedType The value of the selected type.
         * @param {Function} cb           A callback function to execute.
         */
        function selectionToFileType(selectedType, cb) {
            cb = cb || angular.noop;

            var queryType;

            if (selectedType === 'IMAGE') {
                queryType = Config.FILE_TYPES.IMAGE;
            } else if (selectedType === 'DOCUMENT') {
                queryType = Config.FILE_TYPES.DOCUMENT;
            }

            cb(queryType);
        }

        /////////////////////////////

        vmFilter.actionFilterShow = actionFilterShow;
        vmFilter.closeDropdown = closeDropdown;
        vmFilter.onFilterChange = onFilterChange;
        vmFilter.onMediaTypeFilterChange = onMediaTypeFilterChange;
        vmFilter.onMediaTagFilterChange = onMediaTagFilterChange;
        vmFilter.resetFilters = resetFilters;
        vmFilter.selectionToFileType = selectionToFileType;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch for search parameter reset and clear own values.
         */
        $scope.$watch('vm.searchParameters', function watchForSearchParametersReset(newSearchParams) {
            if (angular.isUndefinedOrEmpty(newSearchParams)) {
                _resetFiltersModel();
            }
        }, true);

        /**
        * Initialize the controller.
        */
        function init() {
            vm = $scope.$parent.$parent.vm;

            // Set the tag list key.
            vmFilter.TAG_LIST_KEY = 'filter-tag-' + vm.current.instanceId;

            // Get Tags to display.
            _filterTags();

            vmFilter.filter = {
                query: undefined,
                tags: [],
                type: [],
            };
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('MediaPickerFilterController', MediaPickerFilterController);
})();
