/* eslint-disable lumapps/do-not-import-redux, lumapps/do-not-import-redux-thunk, no-underscore-dangle */

import { compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

declare const window: any;

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

// Configure the store
export function createEnhancer() {
    return composeEnhancers(applyMiddleware(thunk));
}

export * from 'redux-thunk';
