import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get as getConfig } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiHeart, mdiHeartOutline } from '@lumapps/lumx/icons';
import { ButtonProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { browserHasPointerHover } from '@lumapps/utils/browser/browserHasPointerHover';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useHover } from '@lumapps/utils/hooks/useHover';
import { useLongPress } from '@lumapps/utils/hooks/useLongPress';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { FetchLikeUserList, FetchToggleLike } from '../../api/query';
import { ResourceRef } from '../../api/types';
import { REACTIONS_SCOPE } from '../../constants';
import { REACTIONS } from '../../keys';
import { ReactionButton } from '../ReactionButton';
import { LikeUserListDialog } from './LikeUserListDialog';
import { LikeUserListMenu } from './LikeUserListMenu';
import { useResourceLike } from './useResourceLike';

import './index.scss';

export interface GenericLikeButtonProps extends Omit<ButtonProps, 'children'> {
    /** Like count */
    likeCount: number;
    /** Is liked */
    isLiked: boolean;
    /** Show "like"/"likes" label */
    showLabel?: boolean;
    /** XHR toggling like/unlike */
    fetchToggleLike: FetchToggleLike;
    /** XHR listing all users who liked */
    fetchLikeUserList: FetchLikeUserList;
    /** Resource reference (type & id) */
    resourceRef: ResourceRef<string>;
}

const CLASSNAME_GROUP = 'like-reaction-button-group';
const CLASSNAME = 'like-reaction-button';

const HOVER_LEAVE_DELAY = 250;

const appId = getConfig().applicationId;

/**
 * Generic like button with:
 * - on click: toggle like/unlike
 * - on hover: preview like user list
 * - on click see all: open like user list dialog
 */
export const GenericLikeButton = React.forwardRef<HTMLButtonElement, GenericLikeButtonProps>((props, ref) => {
    const {
        resourceRef,
        className,
        showLabel,
        fetchToggleLike,
        fetchLikeUserList,
        isLiked: initiallyLiked = false,
        likeCount: initialLikeCount = 0,
        isDisabled: initiallyDisabled,
        theme,
        size,
        ...forwardedProps
    } = props;
    const { translateAndReplace, translateKey } = useTranslate();
    const { get } = useDataAttributes(REACTIONS_SCOPE);
    const { block } = useClassnames(CLASSNAME);
    const buttonRef = React.useRef(null);
    const menuButtonRef = React.useRef(null);
    const popoverAnchorRef = React.useRef(null);

    // Handle like state
    const { toggleLike, ...likeState } = useResourceLike({
        resourceRef,
        initiallyLiked,
        initialLikeCount,
        fetchToggleLike,
    });
    const isDisabled = initiallyDisabled || likeState.isLoading;

    // Handle hover or long press (based on browser capabilities)
    const hasHover = browserHasPointerHover();
    const hover = useHover({ disable: !hasHover, leaveDelay: HOVER_LEAVE_DELAY });
    const longPress = useLongPress({ disable: hasHover });

    // Like user list dialog
    const [isDialogOpen, , closeDialog, openDialog] = useBooleanState(false);

    // Do not show the like list menu when there is no like or on the webview app
    const showLikeList = likeState.likeCount > 0 && appId !== AppId.webview;

    return (
        <>
            <div className={CLASSNAME_GROUP} ref={popoverAnchorRef}>
                <ReactionButton
                    ref={React.useMemo(
                        () => mergeRefs([ref, buttonRef, hover.registerElement, longPress.registerElement]),
                        [buttonRef, ref, hover.registerElement, longPress.registerElement],
                    )}
                    className={block({ 'is-liked': likeState.isLiked }, className)}
                    leftIcon={likeState.isLiked ? mdiHeart : mdiHeartOutline}
                    onClick={toggleLike}
                    isDisabled={isDisabled}
                    aria-label={translateAndReplace(REACTIONS.LIKE_BUTTON_ARIA, { NB: likeState.likeCount })}
                    aria-pressed={likeState.isLiked}
                    theme={theme}
                    size={size}
                    {...get({ element: 'button', action: 'like' })}
                    {...forwardedProps}
                >
                    {String(likeState.likeCount)}{' '}
                    {showLabel && translateKey(likeState.likeCount <= 1 ? GLOBAL.LIKE : GLOBAL.LIKES)}
                </ReactionButton>

                {showLikeList ? (
                    <LikeUserListMenu
                        resourceRef={resourceRef}
                        menuButtonRef={menuButtonRef}
                        popoverAnchorRef={popoverAnchorRef}
                        theme={theme}
                        onSeeAll={openDialog}
                        shouldOpen={(hover.isHovered || longPress.isLongPressed) && !isDisabled}
                        fetchLikeUserList={fetchLikeUserList}
                        hoverRef={hover.registerElement}
                        size={size}
                    />
                ) : null}
            </div>

            <LikeUserListDialog
                resourceRef={resourceRef}
                isOpen={isDialogOpen}
                onClose={closeDialog}
                fetchLikeUserList={fetchLikeUserList}
                likeCount={likeState.likeCount}
                parentElement={menuButtonRef}
            />
        </>
    );
});
GenericLikeButton.displayName = 'GenericLikeButton';
