import React from 'react';

import { classnames } from '@lumapps/classnames';
import { mdiRefresh, mdiAlertCircleOutline } from '@lumapps/lumx/icons';
import { Emphasis, ButtonProps, ColorVariant, Size, IconSizes, Theme, HeadingElement } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { BaseState } from '../BaseState';

import './index.scss';

export interface ServiceNotAvailableStateProps {
    /** callback on click on retry button */
    onRetry?: () => void;
    /** custom classname */
    className?: string;
    /** whether retrying is disabled or not */
    isRetryDisabled?: boolean;
    /** whether the icon should be displayed or not */
    shouldShowIcon?: boolean;
    /** title to display */
    title?: string;
    /** override for the html to use for rendering the node */
    titleAs?: HeadingElement;
    /** custom description to display */
    description?: string;
    /** props to be passed in to the component wrapper */
    wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
    /** props for the retry button */
    buttonProps?: Partial<ButtonProps>;
    /** whether this component is vertically centered on the page or not */
    isVerticallyCentered?: boolean;
    /** icon size to display - default to xxl */
    iconSize?: IconSizes;
    /** whether the description shoud be displayed or not */
    shouldShowDescription?: boolean;
    /** theme */
    theme?: Theme;
}

const LEGACY_CLASSNAME = 'service-not-available';
const CLASSNAME = 'lumx-service-not-available-state';
/**
 * Reusable component in order to display that an executed action against a given service
 * did not work. This is usually used for displaying error states.
 *
 * @family States
 * @param props ServiceNotAvailableStateProps
 * @returns ServiceNotAvailableState
 */
export const ServiceNotAvailableState: React.FC<ServiceNotAvailableStateProps> = ({
    onRetry,
    className,
    isRetryDisabled = false,
    shouldShowIcon = false,
    isVerticallyCentered = false,
    title = GLOBAL.SERVICE_UNAVAILABLE,
    description = GLOBAL.TRY_RELOAD,
    wrapperProps,
    buttonProps,
    iconSize = Size.xxl,
    shouldShowDescription = true,
    titleAs = 'h1',
    theme,
}) => {
    const { translateKey } = useTranslate();

    return (
        <BaseState
            className={classnames(CLASSNAME, className, LEGACY_CLASSNAME)}
            iconProps={shouldShowIcon ? { icon: mdiAlertCircleOutline, size: iconSize } : undefined}
            title={translateKey(title)}
            titleAs={titleAs}
            theme={theme}
            buttonProps={
                onRetry
                    ? {
                          emphasis: Emphasis.medium,
                          children: translateKey(GLOBAL.RELOAD),
                          leftIcon: mdiRefresh,
                          onClick: onRetry,
                          isDisabled: isRetryDisabled,
                          ...buttonProps,
                      }
                    : undefined
            }
            message={shouldShowDescription ? translateKey(description) : undefined}
            messageProps={{
                colorVariant: ColorVariant.N,
            }}
            wrapperProps={wrapperProps}
            fillSpace={isVerticallyCentered}
        />
    );
};
