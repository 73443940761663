import zipObject from 'lodash/zipObject';

/////////////////////////////

function SocialProfileSubscriptionsController(
    $scope,
    $stateParams,
    SocialProfile,
    SocialSubscription,
    UserFollower,
    UserFollowing,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this, angular/controller-as-vm
    const vmSubscriptions = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The key of the currently selected tab.
     *
     * @type {string}
     */
    vmSubscriptions.currentTabKey = undefined;

    /**
     * A config object holding the settings for each subscriptions tab.
     *
     * @type {Object}
     */
    vmSubscriptions.tabs = SocialProfile.quickApiConfig;

    /**
     * The list of available tabs.
     *
     * @type {Object}
     */
    vmSubscriptions.tabsAvailable = undefined;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vmSubscriptions.SocialSubscription = SocialSubscription;
    vmSubscriptions.UserFollower = UserFollower;
    vmSubscriptions.UserFollowing = UserFollowing;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Filter subscriptions for the current tab.
     */
    function _filterSubscriptions() {
        SocialProfile.quickFilter(vmSubscriptions.currentTabKey, vmSubscriptions.currentTabKey);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Switch to a given subscription type tab based on its key.
     *
     * @param {string} wantedTab The key of the tab to switch to.
     */
    function switchTab(wantedTab) {
        if (angular.isUndefinedOrEmpty(vmSubscriptions.tabs[wantedTab])) {
            return;
        }

        vmSubscriptions.currentTabKey = wantedTab;

        if (
            angular.isUndefinedOrEmpty(
                vmSubscriptions.tabs[vmSubscriptions.currentTabKey].filtered[vmSubscriptions.currentTabKey],
            )
        ) {
            _filterSubscriptions();
        }
    }

    /////////////////////////////

    vmSubscriptions.switchTab = switchTab;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Filter the subscriptions again if we change the scope of the subscriptions we want to display (platform or
     * not).
     */
    $scope.$on('scope-toggle', _filterSubscriptions);

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vmSubscriptions.tabsAvailable = zipObject(Object.keys(vmSubscriptions.tabs), Object.keys(vmSubscriptions.tabs));
        $stateParams.subscriptionType = $stateParams.subscriptionType || vmSubscriptions.tabsAvailable.communities;
        vmSubscriptions.currentTabKey = vmSubscriptions.tabsAvailable[$stateParams.subscriptionType];

        _filterSubscriptions();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('SocialProfileSubscriptionsController', SocialProfileSubscriptionsController);

/////////////////////////////

export { SocialProfileSubscriptionsController };
