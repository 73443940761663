import { makeParamsSerializer } from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';

import { articleApi, paths } from '.';
import { GetArticlesParams } from '../types';
import { Article } from './types';

/** Get articles. */
export async function getArticles(params: GetArticlesParams): Promise<ServerListResponse<Article>> {
    const { data } = await articleApi.get<ServerListResponse<Article>>(paths.articles, {
        params,
        paramsSerializer: makeParamsSerializer({ arrayFormat: 'comma', encode: false }),
        headers: {
            'Accept-Language': '*',
        },
    });

    return data;
}
