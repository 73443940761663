function FavoriteService(FavoriteFactory, LumsitesBaseService, LxNotificationService, Translation) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(FavoriteFactory, {
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Delete the entity (Directory entry, Instance) from user favorites.
     *
     * @param {Object}   entity  The entity to delete from favorites.
     * @param {string}   kind    The kind of entity (Directory Entry, Instance).
     * @param {string}   listKey The list key identifier.
     * @param {Function} [cb]    The function to call after delete succeed.
     * @param {Function} [errCb] The function to call if delete failed.
     */
    function del(entity, kind, listKey, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        FavoriteFactory.delete(
            {
                kind,
                uid: entity.uid,
            },
            () => {
                LxNotificationService.info(Translation.translate('USER_FAVORITES_DELETE'));
                entity.markedAsFavorite = false;
            },
            errCb,
            listKey,
        );
    }

    /**
     * Add the entity (Directory entry, Instance) to user favorites.
     *
     * @param {Object}   entity  The entity to add in favorites.
     * @param {string}   kind    The kind of entity (Directory Entry, Instance)
     * @param {string}   listKey The list key identifier.
     * @param {Function} [cb]    The function to call after save succeed.
     * @param {Function} [errCb] The function to call if save failed.
     */
    function save(entity, kind, listKey, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        LumsitesBaseService.proto.prototype.save.call(
            service,
            {
                target: {
                    kind,
                    uid: entity.uid,
                },
            },
            (favorite) => {
                LxNotificationService.info(Translation.translate('USER_FAVORITES_ADD'));
                entity.markedAsFavorite = true;
                cb(favorite);
            },
            errCb,
            listKey,
        );
    }

    /////////////////////////////

    service.del = del;
    service.save = save;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Favorite', FavoriteService);

/////////////////////////////

export { FavoriteService };
