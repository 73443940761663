/**
 * Check if current browser is legacy Edge (before v79 = not based on chromium).
 */
export function isLegacyEdge() {
    const matches = navigator.userAgent.match(/Edge?\/(\d+)/i);
    if (matches?.[1]) {
        const majorVersion = parseInt(matches[1], 10);
        return majorVersion && majorVersion < 79;
    }
    return false;
}
