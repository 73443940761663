export enum AUTO_TRANSLATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=311905438
     */
    NOT_LOGGED_RATE_LIMIT_EXCEEDED = 'AUTO_TRANSLATION.NOT_LOGGED_RATE_LIMIT_EXCEEDED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346958221
     */
    CONTEXTUAL_ACTIONS_TRANSLATION_ON_ACTION = 'LUMX.CONTEXTUAL_ACTIONS_TRANSLATION_ON_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=346958094
     */
    CONTEXTUAL_ACTIONS_TRANSLATION_OFF_ACTION = 'LUMX.CONTEXTUAL_ACTIONS_TRANSLATION_OFF_ACTION',
}
