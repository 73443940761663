/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

const LazyBlockArticlePreview = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-article-list-block-article-preview' */
            './BlockArticlePreview'
        ),
);

type LazyBlockArticlePreviewProps = React.ComponentProps<typeof LazyBlockArticlePreview> & {
    loadingFallback?: React.ReactNode;
};

export const BlockArticlePreview: React.FC<LazyBlockArticlePreviewProps> = ({ loadingFallback, ...props }) => (
    <Suspense loadingFallback={loadingFallback || <WidgetSkeletonRectangle />}>
        <LazyBlockArticlePreview {...props} />
    </Suspense>
);
