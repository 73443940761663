import React from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { mdiCalendar } from '@lumapps/lumx/icons';
import { DatePickerField, DatePickerFieldProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

export type DateFilterValues = {
    from: Date;
    to: Date;
};

export interface DateFilterProps extends FilterProps {
    /** Language of the site */
    language: string;
    /** The values of the filters */
    selected: DateFilterValues;
    /** Changes the values of the time filters (but it doesn't filter yet) */
    onSelected(values: DateFilterValues): void;
    /** Date from picker field additional props */
    fromProps?: Partial<DatePickerFieldProps>;
    /** Date to picker field additional props */
    toProps?: Partial<DatePickerFieldProps>;
}

/**
 * Filter that displays 2 date pickers in order to filter between dates.
 *
 * @family Filters
 * @param DateFilterProps
 * @returns DateFilter
 */
export const DateFilter: React.FC<DateFilterProps> = ({
    language,
    selected,
    onSelected,
    fromProps = {},
    toProps = {},
    ...props
}) => {
    const { translateKey } = useTranslate();

    const [dates, setDates] = React.useState<DateFilterValues>(selected || {});

    React.useEffect(() => {
        setDates(selected);
    }, [selected]);

    const onChange = (date: Date | undefined, key: string) => {
        if (date) {
            // date must be set at start of day
            date.setHours(0, 0, 0);
        }

        onSelected({
            ...dates,
            [key]: date,
        });

        setDates({
            ...dates,
            [key]: date,
        });
    };

    return (
        <Filter {...props} isFieldset>
            <DatePickerField
                name="from"
                locale={language}
                value={dates?.from}
                onChange={(date) => onChange(date, 'from')}
                clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                nextButtonProps={{ label: translateKey(GLOBAL.NEXT_MONTH) }}
                previousButtonProps={{ label: translateKey(GLOBAL.PREVIOUS_MONTH) }}
                label={translateKey(GLOBAL.DATE_FROM)}
                icon={mdiCalendar}
                maxDate={dates?.to}
                {...toProps}
                className={classnames(toProps.className, padding('top', null))}
            />
            <DatePickerField
                name="to"
                locale={language}
                value={dates?.to}
                onChange={(date) => onChange(date, 'to')}
                clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                nextButtonProps={{ label: translateKey(GLOBAL.NEXT_MONTH) }}
                previousButtonProps={{ label: translateKey(GLOBAL.PREVIOUS_MONTH) }}
                label={translateKey(GLOBAL.DATE_TO)}
                icon={mdiCalendar}
                minDate={dates?.from}
                {...fromProps}
            />
        </Filter>
    );
};
