import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsI18nChipController($scope, LxDialogService, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The new chip that will be added.
         *
         * @type {string}
         */
        vm.pendingChip = undefined;

        /**
         * The pending chip ng-model index position.
         *
         * @type {number}
         */
        vm.currentIndex = undefined;

        /**
         * Copy of the chip that is edited.
         *
         * @type {Object}
         */
        vm.editingChip = undefined;

        /**
         * Reference to the chip that is edited.
         *
         * @type {Object}
         */
        vm.originChip = undefined;

        /**
         * The dialog identifier.
         *
         * @type {string}
         */
        vm.dialogId = `i18n-chip-editor-${generateUUID()}`;

        /////////////////////////////

        /**
         * Services and utilities.
         */

        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Create a new chip.
         */
        function create() {
            var chip = angular.isFunction(vm.builder) ? vm.builder() : {};

            if (vm.attr) {
                chip[vm.attr][Translation.inputLanguage] = vm.pendingChip;
            } else {
                chip[Translation.inputLanguage] = vm.pendingChip;
            }

            vm.ngModel.push(chip);
            vm.pendingChip = undefined;
            vm.currentIndex = undefined;
        }

        /**
         * Triggered every time a key is pressed inside the text input.
         *
         * @param {Object} keyEvent The key press event.
         */
        function onKeyPress(keyEvent) {
            // On enter.
            if (vm.pendingChip && keyEvent.which === 13) {
                vm.create();
            }
        }

        /**
         * Open the edition dialog, keep a reference to the origin chip and make a copy of that chip for edition.
         * The copy is used to avoid having live modification on the chip or have no modification in case of cancel.
         *
         * @param {Object} chip  The chip to edit.
         * @param {number} index The index of the chip in the ng-model array.
         */
        function openEditor(chip, index) {
            chip = vm.attr ? chip[vm.attr] : chip;

            vm.originChip = chip;
            vm.editingChip = angular.copy(chip);
            vm.currentIndex = index;

            LxDialogService.open(vm.dialogId);
        }

        /**
         * Delete the pending chip.
         */
        function remove() {
            if (angular.isNumber(vm.currentIndex) && angular.isDefinedAndFilled(vm.ngModel)) {
                vm.ngModel.splice(vm.currentIndex, 1);

                LxDialogService.close(vm.dialogId);
            }
        }

        /**
         * Save the editing chip.
         */
        function save() {
            angular.extend(vm.originChip, vm.editingChip);

            LxDialogService.close(vm.dialogId);
        }

        /////////////////////////////

        vm.create = create;
        vm.onKeyPress = onKeyPress;
        vm.openEditor = openEditor;
        vm.remove = remove;
        vm.save = save;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the editing dialog close, reset needed element.
         */
        $scope.$on('lx-dialog__close-end', function onDialogCloseEnd(_event, _dialogId) {
            if (vm.dialogId === _dialogId) {
                vm.editingChip = undefined;
                vm.originChip = undefined;
            }
        });

        /////////////////////////////

        /**
         * Initialize the directive.
         */
        function init() {
            if (angular.isUndefined(vm.ngModel)) {
                vm.ngModel = [];
            }
        }

        init();
    }

    /////////////////////////////

    /**
     * Allows to select an icon in the given list.
     */

    function LsI18nChipDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LsI18nChipController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                attr: '@?lsAttr',
                builder: '&?lsBuilder',
                ngModel: '=',
                placeholder: '@?lsPlaceholder',
            },
            templateUrl: '/client/common/modules/i18n-chip/views/i18n-chip.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsI18nChip', LsI18nChipDirective);
})();
