import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface EmbedMapProps {
    /** map title */
    title?: string;
    /** embed map URL */
    embedUrl: string;
    /** The prefix to use on every classname. */
    classNamePrefix: string;
    /** Function to clear the url on preview delete. */
    onDelete?: () => void;
}

export const EmbedMapPreview: React.FC<EmbedMapProps> = ({ title, embedUrl, classNamePrefix, onDelete }) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(classNamePrefix);

    return (
        <div className={element('preview-wrapper')}>
            <FlexBox className={element('map-wrapper')}>
                <iframe
                    className={element('map-iframe')}
                    title={title}
                    src={embedUrl}
                    frameBorder="0"
                    allowFullScreen
                />
                <div className={element('preview-thumbnail-remove-btn')}>
                    <IconButton
                        icon={mdiClose}
                        emphasis={Emphasis.low}
                        hasBackground
                        onClick={onDelete}
                        label={translateKey(GLOBAL.REMOVE)}
                    />
                </div>
            </FlexBox>
        </div>
    );
};
