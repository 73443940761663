import { isFeatureEnabled } from '@lumapps/features';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { SlackSettings } from './SlackSettings';

const mapStateToProps = (state: FrontOfficeStore) => {
    const isSlackDefaultAppEnabled = isFeatureEnabled('slack-default-app')(state);
    return { isSlackDefaultAppEnabled };
};

const ConnectedSlackSettings = connect(mapStateToProps)(SlackSettings);

export { ConnectedSlackSettings as SlackSettings };
