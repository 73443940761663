import { JWT } from '../types';

function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
        atob(str).replace(/(.)/g, (m, p) => {
            let code = p.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
                code = `0${code}`;
            }

            return `%${code}`;
        }),
    );
}

/**
 * Decodes a given string as a jwt
 * @param token
 * @returns JWT
 */
export const decodeToken = (token?: string): JWT | undefined => {
    if (!token) {
        return undefined;
    }

    try {
        return JSON.parse(b64DecodeUnicode(token.split('.')[1]));
    } catch (e) {
        return undefined;
    }
};
