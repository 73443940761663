import { communityView } from '@lumapps/communities/routes';
import { createUrl } from '@lumapps/router/utils';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { SEARCH_SOURCE_TYPES } from '@lumapps/search/keys/sourceTypes';
import { BaseSearchResult, ParentContentDetails } from '@lumapps/search/types';
import { addBaseSlugToSearchUrl } from '@lumapps/search/utils';
import { TranslationAPI } from '@lumapps/translations';

import { props as breadcrumbProps } from '../Breadcrumb/props';
import { Level } from '../Breadcrumb/types';
import { props as contentMetadataProps } from '../ContentMetadata/props';

const formatPostContent =
    (instanceSlug?: string) => (content: ParentContentDetails, translate: TranslationAPI['translate']) => {
        const translatedTitle = content.title ? translate(content.title) : '';
        const postContent: Level = {
            url: createUrl(communityView({ slug: content.slug }), null, instanceSlug),
            label: translatedTitle as string,
        };

        return postContent;
    };

const props = (result: BaseSearchResult, baseProps: any, options: any) => {
    const { url, name, snippet, onClick, flag, quickViewUrl, onQuickViewClick, attachments } = baseProps;

    const bProps = {
        url,
        name,
        snippet,
        flag,
        quickViewUrl,
        onQuickViewClick,
        attachments,
    };

    const { post } = result;

    if (!post) {
        return bProps;
    }

    const { translate, isTagzEnable } = options;
    const breadcrumbLevels = breadcrumbProps({
        tags: post?.tagsDetails,
        content: post?.parentContentDetails,
        translate,
        formatContent: formatPostContent(post?.instanceDetails.slug),
    });
    const metadata = contentMetadataProps(result, options);

    return {
        ...bProps,
        instanceDetails: post?.instanceDetails,
        breadcrumbLevels,
        metadata,
        image: post.thumbnail,
        url: addBaseSlugToSearchUrl(post.url),
        tagz: isTagzEnable ? post.tagz : undefined,
        onClick,
        position: result.position,
        id: post.uid,
        iconName: 'message-text-outline',
        kind: translate(SEARCH_SOURCE_TYPES.POST),
        type: SEARCH_RESULT_TYPES.POST,
    };
};

export { props };
