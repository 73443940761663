import React from 'react';

import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

import { useTranslate } from '@lumapps/translations';

import type { LanguageSelectorProps } from '../components/LanguageSelector/LanguageSelector';
import { translateAndSortLanguages } from '../utils';

type ExtendedLanguageSelectorProps =
    | 'translateLanguages'
    | 'languages'
    | 'onChange'
    | 'keepLanguageOrder'
    | 'isSearchable'
    | 'separatorPosition'
    | 'autofocus';

interface UseLanguageSelectorProps extends Pick<LanguageSelectorProps, ExtendedLanguageSelectorProps> {
    /** Whether the select must close once a value is selected */
    closeOnSelect?: boolean;
    /** The default open state */
    defaultOpen?: boolean;
}

/**
 * Hook to manage the state of the LanguageSelector component
 * The hook manages the language key to translation conversion, filtering, open state, etc...
 *  */
export const useLanguageSelector = ({
    closeOnSelect,
    defaultOpen = false,
    translateLanguages,
    languages,
    onChange,
    keepLanguageOrder,
    isSearchable,
    separatorPosition,
    autofocus,
}: UseLanguageSelectorProps) => {
    const [isOpen, setIsOpen] = React.useState(defaultOpen);
    const [filterValue, setFilterValue] = React.useState('');

    const { translateKey } = useTranslate();

    /** Callback to be executed once the select is clicked */
    const onClick = React.useCallback(() => {
        setIsOpen(!isOpen);
        setFilterValue('');
    }, [isOpen]);

    /** Callback to be executed once the dropdown is closed */
    const onDropdownClose = () => {
        setIsOpen(false);
        setFilterValue('');
    };

    /** Callback to be executed once a language is selected */
    const onLanguageSelected = (language: string) => {
        if (closeOnSelect) {
            setIsOpen(false);
            setFilterValue('');
        }
        onChange(language);
    };

    /** In very specific scenarios, the language code list can be translated via the custom
     * 'translateLanguages' function.
     *
     * In every other case though, this is handled via the default 'translateAndSortLanguages' function.
     */
    const translatedLanguages = React.useMemo(() => {
        if (translateLanguages) {
            return translateLanguages(languages);
        }
        return translateAndSortLanguages({ languages, translateKey, keepLanguageOrder });
    }, [languages, translateKey, keepLanguageOrder, translateLanguages]);

    const filteredLanguages = React.useMemo(() => {
        // Return the full list when the searchbar is not used
        if (filterValue === '') {
            return translatedLanguages;
        }
        // Else, return the list without duplicated values
        const uniqLanguages = uniqWith(translatedLanguages, isEqual);
        return isSearchable
            ? uniqLanguages.filter((choice) =>
                  choice.label
                      ? choice.label.replace(' ', '').toLowerCase().includes(filterValue.replace(' ', '').toLowerCase())
                      : false,
              )
            : translatedLanguages;
    }, [filterValue, isSearchable, translatedLanguages]);

    const getShouldDisplaySeparator = (index: number) =>
        Boolean(separatorPosition && filteredLanguages.length > separatorPosition && separatorPosition === index);

    // Auto-focus the search input when opening the dropdown
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        let inputId: ReturnType<typeof setTimeout>;
        if (autofocus && isOpen && inputRef.current) {
            inputId = setTimeout(() => {
                // eslint-disable-next-line
                inputRef?.current?.focus();
            }, 0);
        }
        return () => clearTimeout(inputId);
    }, [autofocus, isOpen]);

    React.useEffect(() => {
        setIsOpen(defaultOpen);
    }, [defaultOpen]);

    return {
        inputRef,
        getShouldDisplaySeparator,
        filteredLanguages,
        onLanguageSelected,
        onDropdownClose,
        onClick,
        isOpen,
        filterValue,
        setFilterValue,
    };
};
