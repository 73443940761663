import { reject } from 'common/modules/services/utils_service';

/**
 * Return a list of methods on Channel List widgets.
 *
 * @param  {Object} widget Channel list widget to use.
 * @return {Object} Channel list functions.
 */
export function getChannelListUtils(widget) {
    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get current widget properties.
     *
     * @return {Object} Current widget properties.
     */
    function getWidgetProperties() {
        return widget.properties;
    }

    /**
     * Get selected channels list.
     *
     * @return {Array} List of selected channels.
     */
    function getSelectedChannels() {
        const { selectedChannels } = getWidgetProperties();

        return selectedChannels;
    }

    /**
     * Add selected channels to the channel list.
     *
     * @param {Array} channels The selected channels to add.
     */
    function addSelectedChannel(channels) {
        if (angular.isUndefinedOrEmpty(channels)) {
            return;
        }

        const properties = getWidgetProperties();

        properties.selectedChannels.push(...channels);
    }

    /**
     * Remove the channel form the selected channels list.
     *
     * @param {string} channelId The identifier of the channel to remove from selected channels list.
     */
    function deleteSelectedChannel(channelId) {
        const properties = getWidgetProperties();

        reject(properties.selectedChannels, { id: channelId });
    }

    /**
     * Clear selected channels list.
     */
    function clearSelectedChannels() {
        const { selectedChannels } = getWidgetProperties();

        selectedChannels.length = 0;
    }

    /**
     * Get source type.
     *
     * @return {string} Source type.
     */
    function getSourceType() {
        const { sourceType } = getWidgetProperties();

        return sourceType;
    }

    /**
     * Set source type.
     *
     * @param {string} sourceType Source type.
     */
    function setSourceType(sourceType) {
        const properties = getWidgetProperties();

        properties.sourceType = sourceType;
    }

    /**
     * Set source type and reset linked properties.
     *
     * @param {string} sourceType Source type.
     */
    function changeSourceType(sourceType) {
        if (sourceType === getSourceType()) {
            return;
        }

        setSourceType(sourceType);
        clearSelectedChannels();
    }

    /**
     * Get the current provider.
     *
     * @return {Object} Current provider.
     */
    function getProvider() {
        const { provider } = getWidgetProperties() || {};

        return provider;
    }

    /**
     * Define the provider id to use in the widget.
     *
     * @param {string} providerId The provider id identifier.
     */
    function setProviderId(providerId) {
        const properties = getWidgetProperties();

        properties.provider = providerId;
    }

    return {
        addSelectedChannel,
        changeSourceType,
        clearSelectedChannels,
        deleteSelectedChannel,
        getProvider,
        getSelectedChannels,
        getSourceType,
        getWidgetProperties,
        setProviderId,
        setSourceType,
    };
}
