/* istanbul ignore file */
/**
 * Angular-injected implementation of the translation functions.
 */
import entries from 'lodash/entries';
import noop from 'lodash/noop';

import { get as getConstants } from '@lumapps/constants';

import { TranslationAPI } from '../types';
import { isTranslateKey, isTranslateObject, isTranslateAndReplace } from '../utils';

const { userLang } = getConstants();

let pluralize: any = noop;
let replace: any = noop;
let translate: any = noop;

/**
 * Binds functions to angular `Translation` service.
 */
function injectTranslationService(translations: any) {
    pluralize = translations.pluralize;
    translate = translations.translate;
    replace = translations.replace;
}

const angularApi: TranslationAPI = {
    pluralize: (obj, count) => pluralize(obj, count) ?? null,
    pluralizeObject: (obj, count) => pluralize(obj, count) ?? null,
    translateKey: (key, overrideLanguage) => translate(key, '', overrideLanguage) ?? key,
    translateObject: (obj, overrideLanguage) => translate(obj, '', overrideLanguage) ?? null,
    translateAndReplace: (key, replacements, overrideLanguage = userLang) =>
        replace(key, Object.keys(replacements), Object.values(replacements), overrideLanguage) ?? key,
    translateAndReplaceList: (key, keys, replacements, overrideLanguage = userLang) =>
        replace(key, keys, replacements, overrideLanguage) ?? key,
    translateAndReplaceWithComponents: (key, replacements, overrideLanguage = userLang) => {
        // Split components replacements from string/number ones to simplify
        const filteredReplacements = entries(replacements).reduce(
            (acc, [k, v]) => {
                if (typeof v === 'string' || typeof v === 'number') {
                    return {
                        ...acc,
                        otherReplacements: {
                            ...acc.otherReplacements,
                            [k]: v,
                        },
                    };
                }
                return {
                    ...acc,
                    componentReplacements: {
                        ...acc.componentReplacements,
                        [k]: v,
                    },
                };
            },
            {
                otherReplacements: {} as Record<string, string | number>,
                componentReplacements: {} as Record<string, JSX.Element>,
            },
        );

        const translatedKey = angularApi.translateAndReplace(
            key,
            filteredReplacements.otherReplacements,
            overrideLanguage,
        );

        return entries(filteredReplacements.componentReplacements).reduce(
            (acc: (string | JSX.Element)[], [k, v]) => {
                const keyRegexp = new RegExp(`(%${k.toUpperCase()}%)`, 'g');
                const indexContainingKey = acc.findIndex((part) => {
                    return typeof part === 'string' && part.match(keyRegexp);
                });

                if (indexContainingKey !== -1) {
                    const splittedOnKey = (acc[indexContainingKey] as string).split(keyRegexp);

                    const withReplacement = splittedOnKey.reduce(
                        (replacement, cur) => (keyRegexp.test(cur) ? [...replacement, v] : [...replacement, cur]),
                        [] as (string | JSX.Element)[],
                    );

                    const newAcc = acc;
                    newAcc.splice(indexContainingKey, 1, ...withReplacement);
                    return newAcc;
                }
                return acc;
            },
            [translatedKey],
        );
    },
    translate: (toTranslate: any, overrideLanguage) => {
        if (isTranslateKey(toTranslate)) {
            return angularApi.translateKey(toTranslate, overrideLanguage);
        }

        if (isTranslateObject(toTranslate)) {
            return angularApi.translateObject(toTranslate, overrideLanguage);
        }

        if (isTranslateAndReplace(toTranslate)) {
            return angularApi.translateAndReplace(toTranslate.key, toTranslate.replacements, overrideLanguage);
        }

        return null;
    },
};

export { injectTranslationService, angularApi };
