(function IIFE() {
    'use strict';

    /////////////////////////////

    function NotificationsController(Analytics, Content, Features, LxDialogService, LxNotificationService, Notification,
        NotificationSettings, Translation, UserSettings, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Features = Features;
        vm.LxDialogService = LxDialogService;
        vm.Notification = Notification;
        vm.NotificationSettings = NotificationSettings;
        vm.UserSettings = UserSettings;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Set all notifications as read, but don't delete them from notification number.
         */
        function clearNotifications() {
            if (Notification.unreadNotificationCount === 0) {
                return;
            }

            LxNotificationService.confirm(
                Translation.translate('ALERT_NOTIFICATION_CLOSE_ALL'),
                Translation.translate('ALERT_NOTIFICATION_CLOSE_ALL_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function onClearNotificationsConfirmation(answer) {
                    if (answer) {
                        Notification.setAllAsRead();
                    }
                });
        }

        /**
         * Indicates if the sub-header should be displayed or not.
         *
         * @param  {Object}  notificationGroup The notification group to be displayed.
         * @param  {number}  index             The index of the notification group in the list.
         * @return {boolean} Whether the subheader should be displayed or not.
         */
        function shouldDisplaySubheader(notificationGroup, index) {
            if (angular.isUndefinedOrEmpty(notificationGroup)) {
                return false;
            }

            var currentPriority = _.get(notificationGroup, 'notification.priority', 0);
            var unreadItems = Notification.displayList(Notification.KEY_UNREAD);

            return (index === 0 && currentPriority !== 0) ||
                (index !== 0 && currentPriority !== _.get(unreadItems[index - 1], 'notification.priority'));
        }

        /**
         * Trigger an analytics event when notifications are open.
         */
        function trackNotificationsOpen() {
            Analytics.handleTaggingMap('open-notifications', 'notifications', {
                isHomePage: _.get(Content.getCurrent(), 'isHomepage', false),
            });
        }

        /**
         * Return the notification bar's width: at most 400px, less if screen is narrower
         * When <400px, we adapt the width to make the whole notification bar visible.
         * Since the notification bar has a right css of 24, we set the width to (screen width - 48px)
         * to keep the notification bar centered.
         */
        function getNotificationWidth() {
            return Math.min(400, window.screen.width - 48);
        }

        /////////////////////////////

        vm.clearNotifications = clearNotifications;
        vm.shouldDisplaySubheader = shouldDisplaySubheader;
        vm.trackNotificationsOpen = trackNotificationsOpen;
        vm.getNotificationWidth = getNotificationWidth;
    }

    /////////////////////////////

    angular.module('Controllers').controller('NotificationsController', NotificationsController);
})();
