import React from 'react';

import { useDispatch } from '@lumapps/redux/react';

import { setCurrentPage } from '../ducks/thunks';

/**
 * Will update the redux store with the pageId each time it change
 * @param id identifier of the page
 * @param shouldDispatchToCusto should we dispatch the event to customization api?
 */
export const useCurrentPageEffect = (id: string, shouldDispatchToCusto: boolean) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setCurrentPage(id, shouldDispatchToCusto));
    }, [id, dispatch, shouldDispatchToCusto]);
};
