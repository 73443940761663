(function IIFE() {
    'use strict';

    /////////////////////////////

    function DirectoryEntryPickerService($rootScope, $timeout, LxDepthService) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close a directory entry picker.
         *
         * @param {string} pickerId The id of the directory picker.
         */
        function closePicker(pickerId) {
            $rootScope.$broadcast('directory-entry-picker__close-start', pickerId);

            angular.element('body').css({
                overflow: 'visible',
            });

            angular.element('#' + pickerId).removeClass('item-picker--is-shown');

            $timeout(function delayCloseEvent() {
                $rootScope.$broadcast('directory-entry__close-end', pickerId);
            }, 600);
        }

        /**
         * Open a directory entry picker.
         *
         * @param {string} pickerId The id of the directory picker.
         */
        function openPicker(pickerId) {
            LxDepthService.register();
            $rootScope.$broadcast('directory-entry__open-start', pickerId);

            angular.element('body').css({
                overflow: 'hidden',
            });

            $timeout(function delayPickerShow() {
                angular.element('#' + pickerId)
                    .css('z-index', LxDepthService.getDepth())
                    .addClass('item-picker--is-shown');
            });

            $timeout(function delayOpenEvent() {
                $rootScope.$broadcast('directory-entry__open-end', pickerId);
            }, 600);
        }

        /////////////////////////////

        service.close = closePicker;
        service.open = openPicker;
    }

    /////////////////////////////

    angular.module('Services').service('DirectoryEntryPicker', DirectoryEntryPickerService);
})();
