/* eslint-disable import/unambiguous */

function WidgetChartController() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };
}

/////////////////////////////

function WidgetChartDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetChartController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetChart', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-chart/views/widget-chart.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetChart', WidgetChartDirective);

/////////////////////////////

export { WidgetChartController, WidgetChartDirective };
