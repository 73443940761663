(function IIFE() {
    'use strict';

    /////////////////////////////

    function DefaultDialogController($scope) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Listen to the end of the scroll in this dialog.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog triggering this method.
         */
        $scope.$on('lx-dialog__scroll-end', function onDefaultDialogScrollEnd(evt, dialogId) {
            if (vm.dialogId === dialogId && angular.isDefined(vm.onscrollend) && angular.isFunction(vm.onscrollend)) {
                vm.onscrollend();
            }
        });

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            vm.autoClose = (angular.isUndefined(vm.autoClose)) ? true : vm.autoClose;
            vm.displayCancelButton = (angular.isUndefined(vm.displayCancelButton)) ? true : vm.displayCancelButton;
            vm.displayConfirmButton = (angular.isUndefined(vm.displayConfirmButton)) ? true : vm.displayConfirmButton;
            vm.inputLanguage = vm.inputLanguage || false;
        }

        init();
    }

    /**
     * Default dialog directive. Used for all basic dialogs within the application with no complex custom logic.
     *
     * @param {boolean}  [autoClose=true]            Indicates if the dialog should be closed when clicking outside of
     *                                               the dialog wrapper.
     *                                               Defaults to true.
     * @param {string}   [cancelLabel]               The text to use for the cancel button.
     *                                               Defaults to the translation of 'CANCEL'.
     * @param {string}   [confirmLabel]              The text to use for the confirm button.
     *                                               Defaults to the translation of 'OK'.
     * @param {string}   deleteLabel                 The text to use for the delete button.
     *                                               Defaults to the translation of 'DELETE'.
     * @param {string}   dialogId                    The identifier of the dialog.
     * @param {string}   [dialogTitle]               The text to use as title of the dialog.
     * @param {boolean}  [displayCancelButton=true]  Indicates if we should display the cancel button or not.
     * @param {boolean}  [displayConfirmButton=true] Indicates if we should display the confirm button or not.
     * @param {boolean}  [displayDeleteButton=false] Indicates if we should display the delete button or not.
     * @param {boolean}  [inputLanguage=false]       Indicates if we should display the language switch or not.
     * @param {boolean}  [isCancelDisabled]          Indicates if the cancel button should be disabled or not.
     * @param {boolean}  [isConfirmDisabled]         Indicates if the confirm button should be disabled or not.
     * @param {boolean}  [isDeleteDisabled]          Indicates if the delete button should be disabled or not.
     * @param {boolean}  [isDisabled]                Indicates if all the action buttons should be disabled or not.
     * @param {boolean}  [isLoading]                 Indicates if the dialog is loading or not.
     * @param {Function} [oncancel]                  A function to execute when the cancel button is pressed.
     * @param {Function} [onconfirm]                 A function to execute when the confirm button is pressed.
     * @param {Function} [ondelete]                  A function to execute when the delete button is pressed.
     * @param {Function} [onscrollend]               A function to execute when the user is done scrolling the dialog.
     * @param {string}   [size]                      Specify the size of the dialog.
     *                                               Can be: 'l', 'm' or 's'.
     */

    function DefaultDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: DefaultDialogController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                autoClose: '=?lsAutoClose',
                cancelLabel: '@?lsCancelLabel',
                confirmLabel: '@?lsConfirmLabel',
                dialogId: '@lsDialogId',
                dialogTitle: '@?lsDialogTitle',
                displayCancelButton: '=?lsDisplayCancelButton',
                displayConfirmButton: '=?lsDisplayConfirmButton',
                displayDeleteButton: '=?lsDisplayDeleteButton',
                inputLanguage: '=?lsInputLanguage',
                isCancelDisabled: '<?lsIsCancelDisabled',
                isConfirmDisabled: '<?lsIsConfirmDisabled',
                isDeleteDisabled: '<?lsIsDeleteDisabled',
                isDisabled: '=?lsIsDisabled',
                isLoading: '=?lsIsLoading',
                oncancel: '&?lsOnCancel',
                onconfirm: '&?lsOnConfirm',
                ondelete: '&?lsOnDelete',
                onscrollend: '&?lsOnScrollEnd',
                size: '@?lsSize',
            },
            templateUrl: '/client/common/modules/default-dialog/views/default-dialog.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsDefaultDialog', DefaultDialogDirective);
})();
