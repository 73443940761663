import identity from 'lodash/identity';
import includes from 'lodash/includes';

import { preventJSInHTMLLinks } from 'components/utils/string_utils';

/////////////////////////////

function LsRichTextController($compile, $element, $filter, $scope, GTranslate, LsTextEditor, Translation, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Indicates in which markdown block (or inline block) we want to enable emojis.
     * All other block will keep the text emojis.
     *
     * @type {Array}
     */
    const _MARKDOWN_ENABLE_EMOJIS = {
        blockquote: true,
        br: false,
        code: false,
        codespan: false,
        del: true,
        em: false,
        heading: true,
        hr: false,
        html: true,
        image: false,
        link: false,
        list: false,
        listitem: true,
        paragraph: true,
        strong: false,
        table: true,
        tablecell: true,
        tablerow: true,
    };

    /**
     * The list of mentioned feed ids based on the feedMentioned attribute passed to the directive.
     *
     * @type {Array}
     */
    const _feedIds = [];

    /**
     * The list of mentioned users ids based on the userMentioned attribute passed to the directive.
     *
     * @type {Array}
     */
    const _userIds = [];

    /**
     *
     *
     * @type {string}
     */
    const _originTextContainerId = '#ls-rich-text-origin';

    /**
     *
     * @type {string}
     */
    const _translatedTextContainerId = '#ls-rich-text-translated';

    /**
     * Function used to interprete markdown.
     *
     * @type {Function}
     */
    let _mardownInterpretor;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The rich text HTML content to render.
     *
     * @type {string}
     */
    vm.html = '';

    /**
     * Translation loading state.
     *
     * @type {boolean}
     */
    vm.translationLoading = false;

    /**
     * If the text is already translated or not.
     *
     * @type {boolean}
     */
    vm.isTranslated = false;

    /**
     * State of the translation, if text lang is not in the current user lang.
     *
     * @type {boolean}
     */
    vm.isTranslatable = false;

    /**
     * Visibility state for translation.
     *
     * @type {boolean}
     */
    vm.isTranslationVisible = false;

    /**
     * Services and utilities.
     */
    vm.GTranslate = GTranslate;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Match encode pattern and replace it by user / feed.
     */
    function _patternReplace() {
        let match = LsTextEditor.encodedMentionPattern.exec(vm.html);
        while (match !== null) {
            if (match.index === LsTextEditor.encodedMentionPattern.lastIndex) {
                LsTextEditor.encodedMentionPattern.lastIndex++;
            }

            // Replace html by directive or remove match if nothing found.
            let sub = '';

            // User mentions.
            if (includes(_userIds, match[2])) {
                sub = LsTextEditor.encodeToDirective(match[2], vm.theme);
                // Feed mentions.
            } else if (includes(_feedIds, match[2])) {
                sub = LsTextEditor.encodeToDirective(match[2], vm.theme, LsTextEditor.scopeFeedVarPrefix + match[1]);
            }

            vm.html = vm.html.replace(match[0], sub);

            match = LsTextEditor.encodedMentionPattern.exec(vm.html);
        }
    }

    /**
     * Transform text emoji to image emojis.
     *
     * @param  {string} str The string to convert emojis in.
     * @return {string} The emojified string.
     */
    function _renderEmojis(str) {
        return $filter('emoji')(str);
    }

    /**
     * Render the rich text HTML.
     * This will transform the text emojis (:xxx:) to emoticons and compile all directives.
     *
     * @param {Object} node   The html node to compile.
     * @param {string} markup Target html to filter.
     */
    function _renderHtml(node, markup) {
        if (node) {
            // Add HTML to the rich text component.
            node.html($filter('safeAngular')(markup, true));

            // Compile all directive of the added HTML.
            $compile(node.contents())($scope);
        }
    }

    /**
     * Check if the current text is translatable.
     *
     * @return {boolean} If the current text is translatable or not.
     */
    function _isTranslatable() {
        return (
            vm.allowTranslation &&
            vm.html.length < GTranslate.MAX_CHAR_TO_TRANSLATE &&
            vm.textLang &&
            Translation.getLang('current') !== vm.textLang
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get rich text classes.
     *
     * @return {Array} An array of rich text classes.
     */
    function getClasses() {
        const classes = [];

        if (vm.theme === 'dark') {
            classes.push('ls-rich-text--theme-dark');
        } else {
            classes.push('ls-rich-text--theme-light');
        }

        return classes;
    }

    /**
     * Translate html in user current lang.
     */
    function translate() {
        vm.translationLoading = true;

        GTranslate.translate(
            vm.html,
            Translation.getLang('current'),
            undefined,
            (response) => {
                _renderHtml($element.find(_translatedTextContainerId), response.contentText);

                vm.translationLoading = false;
                vm.isTranslated = true;
                vm.isTranslationVisible = true;
            },
            (err) => {
                vm.translationLoading = false;
                Utils.displayServerError(err);
            },
        );
    }

    /**
     * Switch translation visibility state.
     */
    function switchTranslationVisibility() {
        vm.isTranslationVisible = !vm.isTranslationVisible;
    }

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch any changes that occurs to the text.
     * Re-initialize the rich text component.
     *
     * @param {Object} newValue The new text value.
     * @param {Object} oldValue The old text value.
     */
    $scope.$watch('vm.text', (newValue, oldValue) => {
        if (oldValue !== newValue) {
            vm.init();
        }
    });

    /**
     * Watch any theme changes.
     * Re-initialize the rich text component when theme is updated.
     *
     * @param {Object} newValue The new theme value.
     * @param {Object} oldValue The old theme value.
     */
    $scope.$watch('vm.theme', (newValue, oldValue) => {
        if (oldValue !== newValue) {
            vm.init();
        }
    });

    /////////////////////////////

    vm.getClasses = getClasses;
    vm.translate = translate;
    vm.switchTranslationVisibility = switchTranslationVisibility;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        vm.allowTranslation = angular.isDefined(vm.allowTranslation) ? vm.allowTranslation : true;
        vm.markdown = Boolean(vm.markdown);

        _mardownInterpretor = vm.markdown ? marked : identity;

        if (vm.markdown) {
            const defaultRenderer = new marked.Renderer();

            const renderer = new marked.Renderer();
            angular.forEach(_MARKDOWN_ENABLE_EMOJIS, (enabled, blockName) => {
                if (enabled) {
                    renderer[blockName] = (markdownString, options, cb) => {
                        return defaultRenderer[blockName](
                            _renderEmojis(markdownString),
                            options,
                            cb,
                            blockName === 'heading' ? new marked.Slugger() : null,
                        );
                    };
                }
            });

            /*
             * Sets the link to target="_blank by default".
             * For more info : https://github.com/chjj/marked/issues/655#issuecomment-143456762.
             */
            renderer.link = (href, title, text) => {
                if (angular.isDefinedAndFilled(title)) {
                    return `<a target="_blank" href="${href}" title="${title}">${text}</a>`;
                }

                return `<a target="_blank" href="${href}">${text}</a>`;
            };

            vm.html = _mardownInterpretor(vm.text, {
                breaks: true,
                renderer,
                sanitize: !vm.allowHtml,
                smartypants: true,
            });

            const replacements = {
                '&amp;gt;': '&gt;',
                '&amp;lt;': '&lt;',
            };

            vm.html = Utils.replaceTokens(vm.html, false, replacements);
        } else {
            vm.html = $filter('nl2br')(_renderEmojis(vm.text));
        }

        // Add each mentioned user to the scope.
        angular.forEach(vm.userMentioned, (user) => {
            $scope[LsTextEditor.scopeUserVarPrefix + user.id] = user;
            _userIds.push(user.id);
        });

        // Add each mentioned feed to the scope.
        angular.forEach(vm.feedMentioned, (feed) => {
            $scope[LsTextEditor.scopeFeedVarPrefix + feed.name.toLowerCase() + feed.id] = feed;
            _feedIds.push(feed.id);
        });

        // Replace encoded mentions by user / feed directives.
        _patternReplace();

        _renderHtml(
            $element.find(_originTextContainerId),
            Utils.escapeHtmlTags(preventJSInHTMLLinks(vm.html), 'script'),
        );

        vm.isTranslatable = _isTranslatable();
    };

    /////////////////////////////

    vm.init();
}

/////////////////////////////

function LsRichTextDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: LsRichTextController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'AE',
        scope: {
            allowHtml: '<?lsAllowHtml',
            allowTranslation: '<?',
            feedMentioned: '=?',
            markdown: '=?',
            showLess: '<?',
            showMore: '<?',
            text: '=',
            textLang: '@?lang',
            theme: '@?',
            userMentioned: '=?',
        },
        templateUrl: '/client/common/modules/rich-text/views/ls-rich-text.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsRichText', LsRichTextDirective);

/////////////////////////////

export { LsRichTextController, LsRichTextDirective };
