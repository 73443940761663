import React from 'react';

import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';

import { useFeedInfiniteScroll } from '../../context';
import { articleShouldTriggerInfiniteScroll } from '../../utils';

/** Adds an infinite scroll trigger if the current index matches the configured sensitivity */
export const FeedInfiniteTrigger: React.FC<{ index: number; total: number }> = ({ index, total }) => {
    const { hasMore, sensitivity, callback } = useFeedInfiniteScroll();

    if (
        !callback ||
        !hasMore ||
        !articleShouldTriggerInfiniteScroll({
            infiniteScrollSensitivity: sensitivity,
            articlePosition: index + 1,
            totalArticles: total,
        })
    ) {
        return null;
    }

    return <InfiniteScroll callback={callback} />;
};
