import React from 'react';

import { INSTANCE } from '@lumapps/instance/keys';
import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiSettings } from '@lumapps/lumx/icons';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { instanceSettingsRoute, instanceAdvancedSettingsRoute } from '../../routes';

export interface InstanceSettingsSideNavigationProps {
    isAllowed: boolean;
}

const InstanceSettingsSideNavigation = ({ isAllowed }: InstanceSettingsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem label={translateKey(INSTANCE.INSTANCE_SETTINGS)} icon={mdiSettings} id="instance-settings">
            <SideNavigationItem label={translateKey(GLOBAL.GLOBAL_SETTINGS)} route={instanceSettingsRoute} />
            <SideNavigationItem label={translateKey(GLOBAL.ADVANCED_SETTINGS)} route={instanceAdvancedSettingsRoute} />
        </SideNavigationItem>
    );
};

InstanceSettingsSideNavigation.displayName = 'SideNavigationItem';

export { InstanceSettingsSideNavigation };
