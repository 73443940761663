import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { EmbeddedType } from '@lumapps/wrex/serialization/dita/types';

import { EmbeddedMapElement } from '../../types';

/** Convert slate Embed Map to DITA Embed Map. */
export const EMBEDDED_MAP_CONVERT: Converter<EmbeddedMapElement> = (node) => {
    const embeddedMapChildren: EmbeddedType['children'] = [
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:type',
                value: 'website',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:title',
                value: node?.title || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:description',
                value: node?.description || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:image',
                value: node?.thumbnailUrl || '',
            },
            children: [],
        },
    ];

    const ditaNode: EmbeddedType = {
        type: 'element',
        name: 'data-about',
        attributes: {
            href: node?.url || '',
            scope: 'external',
            type: 'lumapps:embedded',
        },
        children: embeddedMapChildren,
    };
    return ditaNode;
};
