import React from 'react';

import { background, padding, useClassnames } from '@lumapps/classnames';
import { ArrowKeys } from '@lumapps/lumx-shortcuts/components/Keys/ArrowKeys';
import { KeyEnter } from '@lumapps/lumx-shortcuts/components/Keys/KeyEnter';
import { ShortcutDescription } from '@lumapps/lumx-shortcuts/components/ShortcutDescription';
import { FlexBox } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SEARCHBOX_FOOTER_ID } from '../../constant';

const CLASSNAME = 'searchbox-shortcuts-footer';

interface SearchBoxShortcutsProps {
    className?: string;
}

/**
 * Shortcuts banner to be displayed below the search box.
 */
export const SearchBoxShortcuts = ({ className }: SearchBoxShortcutsProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    return (
        <FlexBox
            id={SEARCHBOX_FOOTER_ID}
            className={block(undefined, [className, padding('all', 'big'), background('dark', 'L6')])}
            orientation="horizontal"
            gap="big"
            wrap
        >
            <ShortcutDescription shortcuts={<ArrowKeys />} description={translateKey(GLOBAL.NAVIGATE)} />
            <ShortcutDescription shortcuts={<KeyEnter />} description={translateKey(GLOBAL.SELECT)} />
        </FlexBox>
    );
};
