export enum MOBILE_NAVIGATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500022
     */
    TITLE = 'ADMIN.MOBILE.NAVIGATION.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500032
     */
    EMPTY_LIST_TITLE = 'ADMIN.MOBILE.NAVIGATION.EMPTY_LIST_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500035
     */
    EMPTY_LIST_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.EMPTY_LIST_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500039
     */
    NAVIGATION_BUTTON = 'ADMIN.MOBILE.NAVIGATION.NAVIGATION_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500042
     */
    ELEMENT_BUTTON = 'ADMIN.MOBILE.NAVIGATION.ELEMENT_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500138
     */
    ADD_ELEMENT = 'ADMIN.MOBILE.NAVIGATION.ADD_ELEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500143
     */
    PICK_CONTENT_TITLE = 'ADMIN.MOBILE.NAVIGATION.PICK_CONTENT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500146
     */
    PICK_CONTENT_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_CONTENT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500241
     */
    PICK_COMMUNITY_TITLE = 'ADMIN.MOBILE.NAVIGATION.PICK_COMMUNITY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500251
     */
    PICK_COMMUNITY_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_COMMUNITY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500621
     */
    PICK_EXTERNAL_CONTENT_TITLE = 'ADMIN.MOBILE.NAVIGATION.PICK_EXTERNAL_CONTENT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500646
     */
    PICK_EXTERNAL_CONTENT_DESCRIPTION = 'ADMIN.MOBILE.NAVIGATION.PICK_EXTERNAL_CONTENT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78329562
     */
    PICK_CONTENT = 'ADMIN.MOBILE.NAVIGATION.PICK_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78330242
     */
    PICK_COMMUNITY = 'ADMIN.MOBILE.NAVIGATION.PICK_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=78816176
     */
    SECTION_DIALOG_BODY = 'ADMIN.MOBILE.NAVIGATION.SECTION.DIALOG_BODY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130114
     */
    EXTERNAL_LINK_DIALOG_TITLE = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.DIALOG_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130157
     */
    URL_FIELD_LABEL = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.URL_FIELD.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79130166
     */
    URL_FIELD_ERROR_MESSAGE = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.URL_FIELD.ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79188634
     */
    TITLE_FIELD_LABEL = 'ADMIN.MOBILE.NAVIGATION.EXTERNAL_LINK.TITLE_FIELD.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80129678
     */
    DEPTH_LIMIT = 'ADMIN.MOBILE.NAVIGATION.DEPTH_LIMIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80129919
     */
    WIDTH_LIMIT = 'ADMIN.MOBILE.NAVIGATION.WIDTH_LIMIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80636944
     */
    SECTION_DIALOG_TITLE = 'ADMIN.MOBILE.NAVIGATION.SECTION.DIALOG_TITLE',
}
