import get from 'lodash/get';
import { Path, Point, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import type { Wrex } from '../../types';
import { focusAt } from './focusAt';
import { getSiblingPath } from './getSibling';
import { isSelectionEmpty } from './isSelectionEmpty';

/**
 * Insert a given void block and return his path.
 */
export const insertVoidBlock = (editor: ReactEditor, node: Wrex.Element, point?: Point): Path => {
    const at = point || editor.selection?.focus;
    const after = getSiblingPath([get(at, ['path', 0]) as number], 'after');
    const canInsertOnSelection = at && isSelectionEmpty(editor, at.path);

    Transforms.insertNodes(editor, node, {
        at: canInsertOnSelection ? [at.path[0]] : after ?? [editor.children.length],
    });

    const path = canInsertOnSelection ? [at.path[0]] : after ?? [editor.children.length - 2];

    focusAt(editor, path);

    return path;
};
