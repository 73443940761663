import React from 'react';

import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';

import { padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Form, FormProps } from '@lumapps/lumx-forms/components/Form';
import { FormCheckboxField } from '@lumapps/lumx-forms/components/FormCheckboxField';
import { FormRadioButton } from '@lumapps/lumx-forms/components/FormRadioButton';
import { FormSubSection } from '@lumapps/lumx-forms/components/FormSubSection';
import { UseFormOptions } from '@lumapps/lumx-forms/hooks/useForm';
import { ColorPalette, ColorVariant, Divider, RadioGroup, Text, Theme, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { CLASSNAME, SCOPE } from '../../constants';
import { SurveyBlockProps, Values } from '../../types';
import { SurveyResults } from './SurveyResults';
import { SurveySkeleton } from './SurveySkeleton';

import './index.scss';

export const SurveyBlock: React.FC<SurveyBlockProps> = ({
    answersCount,
    answers,
    areResultsHidden,
    choices,
    isAnswersCountHidden,
    isAnswerUpdateDisabled,
    isLoading,
    isMultiple,
    isOpen,
    onSave,
    onClear,
    question,
    surveyId,
    theme = Theme.light,
    userChoices,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { pluralize, translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    if (isLoading) {
        return <SurveySkeleton isAnswersCountHidden={isAnswersCountHidden} theme={theme} />;
    }

    const surveyKey = `survey-${surveyId}`;

    const onSubmit = (formValues: Record<string, string> | Values) =>
        onSave(isMultiple ? (mapValues(Boolean, formValues) as Values) : (formValues[surveyKey] as string));

    const formActionProps: FormProps['formActionProps'] = {
        saveProps: { children: translateKey(GLOBAL.SUBMIT) },
        toolbarProps: { className: element('form-toolbar') },
        wrapperProps: { className: element('form-toolbar-after') },
    };

    const form: UseFormOptions['form'] = {
        defaultValues: isMultiple ? (userChoices as Values) : { [surveyKey]: userChoices as string },
    };

    return (
        <div className={block()} {...get({ element: 'container' })}>
            {!isAnswersCountHidden && (
                <>
                    <Text
                        as="p"
                        color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                        colorVariant={theme === Theme.dark ? ColorVariant.D2 : ColorVariant.L2}
                        typography={Typography.body1}
                        className={element('answers-count')}
                        {...get({ element: 'answers-count' })}
                    >
                        {answersCount}&nbsp;{pluralize(GLOBAL.ANSWER, answersCount)}
                    </Text>
                    <Divider theme={theme} />
                </>
            )}
            {(isEmpty(userChoices) || areResultsHidden) && isOpen ? (
                <Form
                    onSubmit={onSubmit}
                    formActionProps={formActionProps}
                    form={form}
                    scope={SCOPE}
                    theme={theme}
                    useExternalActions={!isOpen}
                >
                    <FormSubSection
                        title={question}
                        className={element('question', [padding('all', null)])}
                        theme={theme}
                        orientation="vertical"
                        withSeparator={false}
                        scope={SCOPE}
                        titleProps={{
                            typography: 'subtitle2',
                        }}
                    >
                        {isMultiple ? (
                            map(
                                ({ label, value }) => (
                                    <FormCheckboxField
                                        name={value}
                                        label={label}
                                        key={`${surveyKey}-choice-${value}`}
                                        className={element('choice')}
                                        theme={theme}
                                    />
                                ),
                                choices,
                            )
                        ) : (
                            <RadioGroup className={element('choices')}>
                                {map(
                                    ({ label, value }) => (
                                        <FormRadioButton
                                            name={surveyKey}
                                            label={label}
                                            value={value}
                                            key={`${surveyKey}-choice-${value}`}
                                            className={element('choice')}
                                            theme={theme}
                                        />
                                    ),
                                    choices,
                                )}
                            </RadioGroup>
                        )}
                    </FormSubSection>
                    {isOpen && <Divider theme={theme} />}
                </Form>
            ) : (
                <FormSubSection
                    title={question}
                    className={element('question', [padding('all', null)])}
                    theme={theme}
                    orientation="vertical"
                    withSeparator={false}
                    scope={SCOPE}
                    titleProps={{
                        typography: 'subtitle2',
                    }}
                >
                    <SurveyResults
                        answers={answers}
                        isAnswerUpdateDisabled={isAnswerUpdateDisabled || !isOpen}
                        onClear={onClear}
                        theme={theme}
                    />
                </FormSubSection>
            )}
        </div>
    );
};

SurveyBlock.displayName = 'SurveyBlock';
