import get from 'lodash/get';
import set from 'lodash/set';

import { ServerListResponse } from '@lumapps/base-api/types';
import { Group } from '@lumapps/groups/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

// The status of groups searching
export enum SearchGroupsStatus {
    initial = 'initial',
    loading = 'loading',
    loadingMore = 'loadingMore',
    loaded = 'loaded',
    error = 'error',
}

export interface GroupPickerState {
    searchTerm: string;
    showSuggestions: boolean;
    searchGroupsStatus: SearchGroupsStatus;
    searchGroupsResults: ServerListResponse<Group>;
}

export const initialState: GroupPickerState = {
    searchTerm: '',
    showSuggestions: false,
    searchGroupsStatus: SearchGroupsStatus.initial,
    searchGroupsResults: {
        cursor: '',
        items: [],
        more: false,
    },
};

// This reducer is used directly in the GroupPicker component's React.useReducer and not stored in redux
const { actions, reducer } = createSlice({
    domain: 'user/groupPicker',
    initialState,
    reducers: {
        setsearchTerm: (state: GroupPickerState, action: PayloadAction<string>) => {
            set(state, 'searchTerm', action.payload);
        },
        setShowSuggestions: (state: GroupPickerState, action: PayloadAction<boolean>) => {
            set(state, 'showSuggestions', action.payload);
        },
        setSearchGroupsStatus: (state: GroupPickerState, action: PayloadAction<SearchGroupsStatus>) => {
            set(state, 'searchGroupsStatus', action.payload);
        },
        setSearchGroupsResult: (state: GroupPickerState, action: PayloadAction<ServerListResponse<Group>>) => {
            set(state, 'searchGroupsResults', action.payload);
        },
        addSearchGroupsResult: (state: GroupPickerState, action: PayloadAction<ServerListResponse<Group>>) => {
            set(state, 'searchGroupsResults', {
                cursor: action.payload.cursor,
                more: action.payload.more,
                items: [...get(state, 'searchGroupsResults.items', []), ...action.payload.items],
            });
        },
        reset: () => initialState,
    },
});

export { actions, reducer };
