export interface RankingData {
    ranking: number;
    identifier: string;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    totalPoints: number;
    averageScore: number;
}

export interface RankingListData {
    offset: number;
    limit: number;
    more: boolean;
    items: RankingData[];
}

export interface LeaderboardDataType {
    currentUser: RankingData;
    groupName: string;
    rankingList: RankingListData;
}

export const enum userTypes {
    someUser = 'some-user',
    currentUserRanking = 'current-user-ranking',
    currentUserInTotalRanking = 'current-user-in-total-ranking',
}

export const enum LearningLeaderboardDisplayType {
    CURRENT_COMMUNITY = 'current_community',
    PICK = 'pick',
}

export interface SpacesId {
    id: string;
}
