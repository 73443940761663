import React from 'react';

import { font } from '@lumapps/classnames';
import { Text, Theme } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { getMoment } from '@lumapps/utils/time/getMoment';

import { POSTS } from '../../../keys';

import './index.scss';

interface PostEventDatesProps {
    startDate?: string;
    endDate?: string;
    legacyMoment?: any;
    theme?: Theme;
}

const CLASSNAME = 'block-post-event-dates';

const PostEventDates: React.FC<PostEventDatesProps> = ({ startDate, endDate, legacyMoment, theme }) => {
    const themeColor = theme === Theme.light ? font('dark', 'L2') : font('light', 'N');
    const moment = legacyMoment || getMoment();
    const { translateKey } = useTranslate();

    return (
        <div className={CLASSNAME}>
            <Text as="span" className={themeColor}>
                {translateKey(POSTS.EVENT_FROM_DATE)}{' '}
            </Text>
            <Text as="span" className={themeColor}>
                {moment.utc(startDate).local().format(translateKey(GLOBAL.SIMPLE_DATE_FORMAT_COMPLETE))}
            </Text>
            <Text as="span" className={themeColor}>
                {' '}
                {translateKey(POSTS.EVENT_TO_DATE)}{' '}
            </Text>
            <Text as="span" className={themeColor}>
                {moment.utc(endDate).local().format(translateKey(GLOBAL.SIMPLE_DATE_FORMAT_COMPLETE))}
            </Text>
        </div>
    );
};

export { PostEventDates };
