import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import { Checkbox, CheckboxProps, FlexBox } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

export interface DefaultValueWrapperProps {
    isChecked?: boolean;
    isFeatureEnabled?: boolean;
    children: React.ReactElement;
    onChange: (isChecked: boolean) => void;
    checkboxProps?: Partial<CheckboxProps>;
}

/**
 * Component that wraps a given component with a checkbox in order to manage default values
 * @param DefaultValueWrapperProps
 * @returns DefaultValueWrapper
 */
export const DefaultValueWrapper: React.FC<DefaultValueWrapperProps> = ({
    children,
    isChecked = true,
    isFeatureEnabled = false,
    onChange,
    checkboxProps = {},
}) => {
    const { translateKey } = useTranslate();
    const [isCurrentlyChecked, setIsCurrentlyChecked] = React.useState(isChecked);

    React.useEffect(() => {
        setIsCurrentlyChecked(isChecked);
    }, [isChecked]);

    if (!isFeatureEnabled) {
        return children;
    }

    return (
        <FlexBox orientation="vertical">
            {children}

            <Checkbox
                className={classnames(margin('top', 'regular'), checkboxProps.className)}
                label={translateKey(GLOBAL.USE_DEFAULT_VALUES)}
                isChecked={isCurrentlyChecked}
                {...checkboxProps}
                onChange={(checked) => {
                    onChange(checked);
                    setIsCurrentlyChecked(checked);
                }}
            />
        </FlexBox>
    );
};
