import compact from 'lodash/compact';

import { MediaPreview } from '@lumapps/widget-base/types';

export const getMediasToUpdate = (communityId: string, files?: MediaPreview[], images?: MediaPreview[]) => {
    const filesToUpdate = files
        ? files.map(
              (file) =>
                  file.source === 'haussmann_media' && {
                      containerId: communityId,
                      containerType: 'community',
                      mediaId: file.uuid,
                  },
          )
        : [];

    const imagesToUpdate = images
        ? images.map(
              (image) =>
                  image.source === 'haussmann_media' && {
                      containerId: communityId,
                      containerType: 'community',
                      mediaId: image.uuid,
                  },
          )
        : [];
    return compact([...filesToUpdate, ...imagesToUpdate]);
};
