import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SkipLinksListItem } from '../SkipLinkListItem';

import './index.scss';

export interface SkipLinksProps {
    /** The name to set as aria label of the skip list group. */
    name?: string;
    /**
     * The skip links to display.
     * Should either be SkipLinksList.Link or SkipLinkListItem components
     */
    children: ReactNode;
}

const CLASSNAME = 'skip-links-nav';

/**
 * Display multiple skip links on the top left side of the parent element.
 * The parent element must be relatively positioned.
 *
 * If you only need to display a single skip link, you can use a single `SkipLink` component without
 * this component.
 *
 * @family Layouts
 * @param SkipLinksProps
 * @returns SkipLinksNav
 */
export const SkipLinksNav: React.FC<SkipLinksProps> & {
    Link: typeof SkipLinksListItem;
} = ({ children, name }) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    return (
        <nav aria-label={name || translateKey(GLOBAL.SKIP_LINKS)} className={block()}>
            <ul>{children}</ul>
        </nav>
    );
};

SkipLinksNav.Link = SkipLinksListItem;
