import { CommunityReference } from '@lumapps/communities/types';
import { FocusPoint } from '@lumapps/lumx/react';
import { WithComments, WithReactions } from '@lumapps/reactions/api/v2/types';
import { StructuredContent } from '@lumapps/structured-content/types';
import { TranslatableObject } from '@lumapps/translations';
import { UserReference } from '@lumapps/widget-base/types';
import { Layout } from '@lumapps/widget-layout/types/layout';

export type EventStatus = 'draft' | 'published' | 'unpublished' | 'deleted';

export enum RegistrationStatuses {
    going = 'going',
    notGoing = 'not_going',
    maybe = 'maybe',
    unanswered = 'unanswered',
}

export type RegistrationStatusTypes = 'going' | 'not_going' | 'maybe' | 'unanswered';

export enum ASSOCIATED_RESOURCE_TYPE {
    TRAINING = 'training',
}

export interface AssociatedResourceReference {
    id: string;
    name: TranslatableObject;
    type: ASSOCIATED_RESOURCE_TYPE;
}

/** @see https://lumapps-api.stoplight.io/docs/lumapps-private-api/92917cd06d465-event */
export interface Event extends WithReactions, WithComments {
    /** Event identifier. */
    id: string;
    /** Event structured content. */
    structuredContent: StructuredContent;
    /** Event author. */
    author: UserReference;
    /** Event creation date. */
    createdAt: string;
    /** Event updated date. */
    updatedAt?: string;
    /** Event start date. */
    startsAt: string;
    /** Event end date. */
    endsAt: string;
    /** Event location. */
    location: string;
    /** Event external url. */
    externalUrl: string;
    /** Label for Event external url. */
    externalUrlLabel?: { type: string };
    /** Whether the event author has decided to show the guests list to all or not. */
    isGuestsListVisible?: boolean;
    /** Event organizer */
    organizers: UserReference[];
    /** Whether the current user can edit/delete the event. */
    canEdit: boolean;
    /** Whether the user is registered to the event. */
    isUserRegistered: boolean;
    /** precise the status of the user registered registration. */
    registrationStatus: RegistrationStatusTypes;
    /** Whether the event is deleted or accessible. */
    status: EventStatus;
    /** Event shares. */
    shares: Array<{
        eventId: string;
        container: {
            containerType: 'SITE' | 'COMMUNITY';
            containerId: string;
        };
    }>;
    templateId: string;
    /** Whether the event has a limited number of guests */
    hasLimitedCapacity: boolean;
    /** The maximum number of users that can go to the event (have a registration status to 'going'). */
    capacity?: number;
    /** The minimum number of users that can go to the event (have a registration status to 'going'). */
    minCapacity?: number;
    /** The number of spots left for the event */
    remainingSpots?: number;
    /** Whether some users are invited to the event */
    hasGuests?: boolean;
    /** reference to the community the event belongs to */
    communityReference?: CommunityReference;
    /** The event's attendees count */
    attendeesCount?: number;
    /** The resource linked to the event */
    associatedResourceReference?: AssociatedResourceReference;
    /** The resource id linked to the event */
    associatedResourceId?: string;
    /** Event duration in minutes */
    duration?: number;
    /** Associated resource type */
    associatedResourceType?: string;
    /** Whether users can register to the event If false, overrides isGuestsListVisible to false */
    hasRegistrationEnabled?: boolean;
}

/**
 * The minimal info needed to display an event in a page.
 * What is returned by /v2/organizations/{organizationId}/events/{eventId}/layout
 * @see https://lumapps-api.stoplight.io/docs/lumapps-private-api/6dbfc18e5add2-event-page
 */
export interface EventPage extends WithReactions, WithComments {
    /** Whether the user doing the request can edit the event */
    canEdit: boolean;
    /** Whether the user doing the request can delete the event */
    canDelete: boolean;
    /** Whether the user doing the request can invite other users to the event */
    canInvite: boolean;
    /** The event's title translated to the requested lang */
    title: string;
    /** Event page layout. */
    layout: Layout;
    /** Event id */
    id?: string;
    /** Event shares */
    shares: Event['shares'];
}

export interface Guest extends UserReference {
    registrationStatus?: RegistrationStatusTypes;
    profilePrimaryFieldValues?: string[];
}

export interface EventGuests {
    guests: Guest[];
    cursor?: string;
    more?: boolean;
}

export interface LayoutEvent extends WithReactions, WithComments, Pick<Event, 'hasRegistrationEnabled'> {
    description: string;
    externalUrl: string;
    externalUrlLabel: {
        type: string;
    };
    id: string;
    isUserRegistered: boolean;
    registrationStatus: RegistrationStatusTypes;
    location: string;
    startsAt: string;
    endsAt: string;
    title: string;
    image: string;
    focalPoint: FocusPoint;
    status: EventStatus;
    organizers: UserReference[];
    /** The number of spots left for the event */
    remainingSpots?: number;
    hasLimitedCapacity: boolean;
    duration: number;
    associatedResourceId: string;
    associatedResourceType: string;
}
