import curryRight from 'lodash/fp/curryRight';

/**
 * Base on a Date and increment value
 * Get a Date object corresponding to the seeked day
 * */
export const getDayRelativeToDate = (date: Date, increment: number) => {
    const newDay = new Date(date);
    newDay.setDate(newDay.getDate() + increment);

    return newDay;
};

export const prevDay = curryRight(getDayRelativeToDate)(-1);
export const nextDay = curryRight(getDayRelativeToDate)(1);
