import React from 'react';

import { useClassnames, margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { MultiProgression } from '@lumapps/lumx-progression/components/MultiProgression';
import { Button, ColorPalette, ColorVariant, Divider, FlexBox, Size, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { CLASSNAME, SCOPE } from '../../constants';
import { WIDGET_SURVEY } from '../../keys';
import { SurveyBlockProps } from '../../types';

type SurveyResultsProps = Pick<SurveyBlockProps, 'answers' | 'onClear' | 'isAnswerUpdateDisabled' | 'theme'>;

export const SurveyResults = ({ answers, isAnswerUpdateDisabled, onClear, theme }: SurveyResultsProps) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey, pluralize } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    return (
        <>
            <FlexBox orientation="vertical" gap={Size.huge}>
                {answers?.map(({ label, value, percentage, quantity }) => (
                    <MultiProgression
                        items={[
                            {
                                percentage,
                                tooltipMessage: `${quantity} ${pluralize(GLOBAL.ANSWER, quantity || 0)}`,
                                colorPalette: theme === Theme.dark ? ColorPalette.light : ColorPalette.primary,
                                colorVariant: theme === Theme.dark ? ColorVariant.D1 : ColorVariant.L1,
                            },
                        ]}
                        key={value}
                        className={element('answer')}
                        title={label}
                        total={percentage}
                        maxValue={100}
                        ariaLabel={label}
                        showPercentage
                        theme={theme}
                        scope={SCOPE}
                    />
                ))}
            </FlexBox>
            {!isAnswerUpdateDisabled && (
                <div>
                    <Divider theme={theme} />
                    <Button
                        size={Size.m}
                        onClick={onClear}
                        className={margin('top', 'big')}
                        theme={theme}
                        {...get({ element: 'update' })}
                    >
                        {translateKey(WIDGET_SURVEY.ANSWER_UPDATE)}
                    </Button>
                </div>
            )}
        </>
    );
};
