import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { shape, string, func, bool } from 'prop-types';
import loGet from 'lodash/get';

import { translate as t, getInputLanguage } from 'components/translations';
import { areaStyle } from 'components/components/styles/types';
import { Icon } from 'components/components/ui/icons/Icon';
import { adjustStyle } from 'components/services/styles';
import { isDesignerMode } from 'components/utils';

/**
 * Display the widget header.
 */
export class WidgetHeader extends PureComponent {
    static propTypes = {
        /* * The icon name. */
        icon: string,
        /* * The widget header style. */
        style: areaStyle,
        /* * The title of the widget. */
        title: shape(),
        /* * Function that toggle the widget content. */
        toggleWidgetContent: func,
        /* * Whether we are in Writer mode or not. */
        isBasicDesigner: bool,
        /* * Function to update the edition status of the title */
        setHeaderEditionStatus: func,
    };

    render() {
        const { icon, title, style, toggleWidgetContent, isBasicDesigner, component, onChange, setHeaderEditionStatus, parentStyle, isStandalone } = this.props;
        const flexDirection = loGet(style.wrapper, 'flexDirection') || 'row';

        const mainStyle = { ...adjustStyle(style.main || {}, true) };
        const mainDisplay = mainStyle?.display || 'block';
        if (mainDisplay === 'block') {
            if (parentStyle?.borderTopRightRadius) {
                mainStyle.borderTopRightRadius = parentStyle.borderTopRightRadius;
            }
            if (parentStyle?.borderTopLeftRadius) {
                mainStyle.borderTopLeftRadius = parentStyle.borderTopLeftRadius;
            }
            if (isStandalone) {
                if (parentStyle?.borderBottomRightRadius) {
                    mainStyle.borderBottomRightRadius = parentStyle.borderBottomRightRadius;
                }
                if (parentStyle?.borderBottomLeftRadius) {
                    mainStyle.borderBottomLeftRadius = parentStyle.borderBottomLeftRadius;
                }
            }
        }

        return (
            <div className="widget-header" style={mainStyle} onClick={toggleWidgetContent}>
                <div
                    className={classNames('widget-wrapper widget-header__wrapper', {
                        'widget-header__wrapper--layout-bottom': flexDirection === 'column-reverse',
                        'widget-header__wrapper--layout-left': flexDirection === 'row',
                        'widget-header__wrapper--layout-right': flexDirection === 'row-reverse',
                        'widget-header__wrapper--layout-top': flexDirection === 'column',
                    })}
                    style={adjustStyle(style.wrapper || {})}
                    dir="auto"
                >
                    {icon !== undefined && (
                        <Icon
                            className="widget-header__icon"
                            style={adjustStyle(style.icon || {}, false)}
                            value={icon}
                        />
                    )}
                    {
                        // The input will be displayed only in writer mode for widgets that has the prop (only remote for now)
                        isBasicDesigner && onChange ?
                        <input
                            onBlur={() => setHeaderEditionStatus(false)}
                            onFocus={() => setHeaderEditionStatus(true)}
                            value={component.title[getInputLanguage()]}
                            className="widget-header__title widget-header__input"
                            style={adjustStyle(style.label || {}, false)}
                            onChange={(e) => onChange(`title.${getInputLanguage()}`, e.target.value)}
                        /> :
                        <h2 className="widget-header__title" style={adjustStyle(style.label || {}, false)}>
                            {isDesignerMode() ? t(title, undefined, getInputLanguage(), true) : t(title)}
                        </h2>
                    }

                </div>
            </div>
        );
    }
}
