import { useMemo } from 'react';

import { getSummarizedCustomer } from '@lumapps/customer/ducks/selectors';
import { useInstanceLogo } from '@lumapps/instance/hooks/useInstanceLogo';
import { getNavigationPromise, getParentNavigationPromise } from '@lumapps/navigation/utils/navigationMemStore';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';

import { getSession } from '../ducks/selectors';

/**
 * Hook that will retrieve basic information about the application
 */
const useSession = () => {
    const currentSession = useSelector(getSession);
    const device = useResponsive();
    const instanceLogo = useInstanceLogo();
    const { instance } = currentSession;
    const organization = useSelector(getSummarizedCustomer);

    const session = useMemo(() => {
        return {
            ...currentSession,
            instance: {
                ...instance,
                logo: instanceLogo,
            },
            device,
            organization,
            navigations: {
                getCurrent: getNavigationPromise,
                getParent: instance.parent ? getParentNavigationPromise : null,
            },
        };
    }, [currentSession, device, instance, instanceLogo, organization]);

    return session;
};

export { useSession };
