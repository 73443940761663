import includes from 'lodash/includes';

function WidgetRemoteSettingsController($rootScope, $timeout, ExtensionWidget, Widget) {
    'ngInject';

    const widgetRemoteSettings = this;
    widgetRemoteSettings.globalProperties = {};
    widgetRemoteSettings.useConnectorSelector = false;
    widgetRemoteSettings.isLoading = true;
    let uuid;

    function getExtensionId() {
        return Widget.getCurrent().properties.remoteExtension.id;
    }

    function getExtension() {
        const extensionId = getExtensionId();

        return ExtensionWidget.extensions.find((extension) => extension.id === extensionId);
    }

    /**
     * Determine whether the remote widget has a settings component to display or not.
     *
     * @return {boolean} Whether the widget has a settings component.
     */
    function hasSettings() {
        const extension = getExtension();

        if (!extension) {
            widgetRemoteSettings.extensionEnabled = false;
            return false;
        }
        const { useConnectorSelector, installed } = extension;

        widgetRemoteSettings.extensionEnabled = true;

        widgetRemoteSettings.useConnectorSelector = Boolean(!!installed?.providerTypes?.[0] && useConnectorSelector);

        return includes(extension?.installed?.components, 'settings');
    }

    /**
     * Update the widget.
     *
     * @param {Object} properties The widget properties.
     */
    function updateWidget(properties) {
        const currentWidget = Widget.getCurrent();

        if (
            currentWidget.uuid === uuid &&
            properties.remoteExtension.id === currentWidget.properties.remoteExtension.id
        ) {
            currentWidget.properties = properties;
        }

        uuid = currentWidget.uuid;

        $timeout(() => {
            $rootScope.$broadcast('widget-remote-settings', currentWidget.uuid);
        });
    }

    async function getGlobalSettings() {
        widgetRemoteSettings.isLoading = true;
        const extension = await ExtensionWidget.getEnabledExtension(Widget.getCurrent().properties.remoteExtension.id);
        widgetRemoteSettings.isLoading = false;
        if (!extension) {
            return;
        }

        const { globalSettings } = extension;

        widgetRemoteSettings.globalProperties = globalSettings;
        // force state update
        $rootScope.$apply();
    }

    function init() {
        getGlobalSettings();
    }

    widgetRemoteSettings.hasSettings = hasSettings;
    widgetRemoteSettings.updateWidget = updateWidget;

    init();
}

angular.module('Controllers').controller('WidgetRemoteSettingsController', WidgetRemoteSettingsController);

export { WidgetRemoteSettingsController };
