import { EDIT_USER_SETTINGS_DIALOG_EVENT } from '@lumapps/user/constants';
import { getUserSettingsRoute, USER_SETTINGS_VIEWS } from '@lumapps/user-settings/routes';
import { angularApi } from '@lumapps/router/routers';

(function IIFE() {
    // ///////////////////////////

    function UserSettingsService(LumsitesBaseService, UserSettingsFactory, Utils, $rootScope, ReduxStore, $location) {
        'ngInject';

        const service = LumsitesBaseService.createLumsitesBaseService(UserSettingsFactory, {
            objectIdentifier: 'id',
        });

        // ///////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        // ///////////////////////////

        /**
         * The default maximum number of results when listing user settings.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        const _MAX_RESULTS = 25;
        const dialogId = 'user-settings-dialog';

        // ///////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        // ///////////////////////////

        /**
         * Contains the default parameters for the base service.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        // ///////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        // ///////////////////////////

        /**
         * Open user settings dialog.
         *
         * @param {number} [activeTabIndex=0] The index of the tab to activate in the user settings dialog.
         */
        function openUserSettings(activeTabIndex) {
            /**
             * legacy backwards compatibility with new user settings page.
             * Integrations is repeated since it was splitted into two tabs in the previous dialog
             * */
            const USER_SETTINGS_TABS_ORDER = [
                USER_SETTINGS_VIEWS.GENERAL,
                USER_SETTINGS_VIEWS.NOTIFICATIONS,
                USER_SETTINGS_VIEWS.INTEGRATIONS,
                USER_SETTINGS_VIEWS.INTEGRATIONS,
            ];

            angularApi.redirect(getUserSettingsRoute(USER_SETTINGS_TABS_ORDER[activeTabIndex || 0]));
        }

        // ///////////////////////////

        service.openUserSettings = openUserSettings;

        /**
         * Event that triggers the display of the user settings modal. Currently used from the new
         * user profile menu on the new architecture side
         */
        $rootScope.$on(EDIT_USER_SETTINGS_DIALOG_EVENT, (evt, activeTabIndex = 0) => {
            openUserSettings(activeTabIndex);
        });

        // ///////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                maxResults: _MAX_RESULTS,
            };
        };

        // ///////////////////////////

        return service;
    }

    // ///////////////////////////

    angular.module('Services').service('UserSettings', UserSettingsService);
})();
