import { getChartImage } from '../api/ChartApi';

const getChartImageTask = (chartId, driveId, fileId, chartWidth, chartHeight) => {
    return getChartImage(chartId, driveId, fileId, chartWidth, chartHeight).then((res) => ({
        payload: res.data.content || ''
    }));
};



export { getChartImageTask };
