export enum FOLKSONOMY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=26506143
     */
    ADD = 'FRONT.TAGZ.ADD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=84150038
     */
    NEW_TAG = 'FRONT.TAGZ.CREATE.NEW_TAG',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=117068039
     */
    ALREADY_ADDED = 'FRONT.TAGZ.ALREADY_ADDED',
    SUGGESTED_TAGS = 'FRONT.TAGZ.SUGGESTED_TAGS.TITLE',
}
