export enum WIDGET_LINKS {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454457
     */
    LINKS_THUMBNAIL_UPLOAD = 'WIDGET_TYPE_LINKS_THUMBNAIL_UPLOAD',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454454
     */
    DIALOG_TITLE = 'WIDGET_TYPE_LINKS_MODAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454446
     */
    ADD_TARGET_BLANK = 'WIDGET_TYPE_LINKS_ADD_TARGET_BLANK',
}
