// eslint-disable-next-line lumapps/do-not-import-immer
import { produce } from 'immer';
import startsWith from 'lodash/startsWith';
// eslint-disable-next-line lumapps/do-not-import-redux
import { Action } from 'redux';

const isAngularActionType = (type: string) => startsWith(type, '__angular__');

/**
 * Returns the action type from a domain and an actionKey.
 * Example : getActionType('user', 'fetchUserInfo') => 'user/FETCH_USER_INFO'
 *
 * @param domain    The domain name.
 * @param actionKey The action key name.
 * @return {string} The action type generated.
 */
const getActionType = (domain: string, actionKey: string): string => {
    return isAngularActionType(actionKey)
        ? actionKey
        : `${domain}/${actionKey
              .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
                  return `_${y}`;
              })
              .replace(/^_/, '')
              .toUpperCase()}`;
};

export interface PayloadAction<P = any> extends Action {
    payload: P;
}

export interface AngularAction<S = any> extends Action {
    newState: S;
}

export interface EmptyActionMaker {
    (): Action;
    type?: string;
}
export interface ActionMaker<P = any> {
    (payload?: P): PayloadAction<P>;
    type?: string;
}

export interface AngularActionMaker<S = any> {
    (newState?: S): AngularAction<S>;
    type?: string;
}

export interface CaseReducer<P = any> {
    (state: any, action: PayloadAction<P>): void;
}

export interface AngularCaseReducer<S = any> {
    (state: any, action: AngularAction<S>): void;
}

/**
 * Record of case reducers.
 */
type Reducers = Record<string, CaseReducer | AngularCaseReducer>;

interface CreateSliceOptions<CR extends Reducers, State> {
    /** Your reducer name */
    domain: string;
    /** The initial state of your reducer. */
    initialState: State;
    /** An object with actions creator's name as keys and case reducer as values. */
    reducers: CR;
}

type ActionCreatorForCaseReducer<CR> = CR extends (state: any, action: infer Action) => any
    ? Action extends {
          payload: infer P;
      }
        ? ActionMaker<P>
        : Action extends {
              newState: infer S;
          }
        ? AngularActionMaker<S>
        : EmptyActionMaker
    : EmptyActionMaker;

type CaseReducerActions<CaseReducers> = {
    [Type in keyof CaseReducers]: ActionCreatorForCaseReducer<CaseReducers[Type]>;
};

interface Slice<CR, State> {
    /** The name of the reducer. */
    domain: string;
    /** The action creators generated. */
    actions: CaseReducerActions<CR>;
    /** The reducer to inject into the main reducer. */
    reducer: (base: State, action: Action<keyof CR>) => State;
}

/**
 * Creates a Slice that contains the reducer itself and all actions associated.
 */
const createSlice = <CR extends Reducers, State>({
    initialState,
    domain,
    reducers,
}: CreateSliceOptions<CR, State>): Slice<CR, State> => {
    const actions = {} as CaseReducerActions<CR>;
    const actionsByType: Reducers = {};
    const reducerNames: Array<keyof CR> = Object.keys(reducers);

    reducerNames.forEach((reducerName) => {
        const actionType = getActionType(domain, reducerName as string);
        const actionReducer = reducers[reducerName];

        actions[reducerName] = ((payload) => {
            if (isAngularActionType(actionType)) {
                return { newState: payload, type: actionType };
            }
            return { payload, type: actionType };
        }) as ActionCreatorForCaseReducer<CR[keyof CR]>;

        actions[reducerName].type = actionType;

        actionsByType[actionType] = actionReducer;
    });

    // eslint-disable-next-line default-param-last
    const reducer = produce((draft = initialState, action) => {
        const caseReducer = actionsByType[action?.type] as any;

        return caseReducer ? caseReducer(draft, action) : draft;
    });

    return {
        actions,
        domain,
        reducer,
    };
};

export default createSlice;
