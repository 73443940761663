import React from 'react';

import { AddToMainNavigationDialog } from '@lumapps/navigation-admin/components/AddToMainNavigationDialog';
import { NavigationItemType } from '@lumapps/navigation/types';
import { useSelector } from '@lumapps/redux/react';

import { contentSelector } from '../../ducks/selectors';

export type AddContentToMainNavigationDialogProps = {
    isOpen?: boolean;
    onClose?: () => void;
};

/**
 * Dialog to add the current content to the main navigation.
 */
export const AddContentToMainNavigationDialog: React.FC<AddContentToMainNavigationDialogProps> = ({
    ...dialogProps
}) => {
    const content = useSelector(contentSelector);

    return <AddToMainNavigationDialog type={NavigationItemType.CONTENT} entity={content} {...dialogProps} />;
};
