import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, FlexBox } from '@lumapps/lumx/react';

import './index.scss';

export interface RemoveButtonProps {
    label: string;
    onClick(): void;
    className?: string;
}

const CLASSNAME = 'remove-button';

/** Button for remove attachments in the post
 *
 * @param RemoveButtonProps
 * @returns RemoveButton
 */
export const RemoveButton: React.FC<RemoveButtonProps> = ({ label, onClick, className }) => {
    const { block } = useClassnames(CLASSNAME);

    return (
        <FlexBox className={classnames(block(), className)}>
            <IconButton icon={mdiClose} emphasis={Emphasis.low} hasBackground onClick={onClick} label={label} />
        </FlexBox>
    );
};
