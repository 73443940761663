import React from 'react';

import { classnames } from '@lumapps/classnames';
import { ChipGroup, Theme } from '@lumapps/lumx/react';

import * as types from '../../types';
import { TagList, TagsListProps } from '../TagList';
import { TagsAutocomplete } from '../TagsAutocomplete';
import { TagsAutocompleteProps } from '../TagsAutocomplete/TagsAutocomplete';

export interface TagzProps {
    className?: string;
    /** True if the suggestions are on inline mode, meaning that they will not show the form label and the underline decoration. */
    isInline?: boolean;
    /** Whether the functionality is enabled or not */
    isEnabled?: boolean;
    /** Callback executed when the list of selected tags changes. */
    onChange?: (tags: types.Tag[]) => void;
    /** List of suggested tags. */
    values: types.Tag[];
    /** current theme to apply */
    theme?: Theme;
    /** additional props for the autocomplete tags component */
    autocompleteProps?: Partial<TagsAutocompleteProps>;
    /** additional props for the tag list component, displayed when the onChange function is not provided */
    tagListProps?: Partial<TagsListProps>;
    /** Current filter (tab) id */
    filter?: string;
}

/**
 * Display list of tagz and tagz selector.
 * @return {ReactElement} A Tagz component.
 * */
const Tagz: React.FC<TagzProps> = ({
    className,
    isInline,
    onChange,
    values,
    isEnabled = false,
    theme,
    autocompleteProps,
    tagListProps,
    filter,
}) => {
    if (!isEnabled) {
        return null;
    }

    return (
        <ChipGroup className={classnames('tagz', className)}>
            {!onChange ? (
                <TagList theme={theme} tags={values} {...tagListProps} filter={filter} />
            ) : (
                <TagsAutocomplete
                    theme={theme}
                    isInline={isInline}
                    tags={values}
                    onTagsChange={onChange}
                    {...autocompleteProps}
                />
            )}
        </ChipGroup>
    );
};

export { Tagz };
