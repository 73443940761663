import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { CAMPAIGN_ICON } from '../../constants';
import { canAccessCampaign } from '../../ducks/selectors';
import { CAMPAIGNS } from '../../keys';
import { campaignListRoute } from '../../routes';

export interface CampaignSettingMenuProps {
    scope: string;
}

/**
 * The Campaigns entry in the front-office cogwheel (settings)
 *
 * @param CampaignSettingMenuProps
 * @returns CampaignSettingMenu
 */
export const CampaignSettingMenu: React.FC<CampaignSettingMenuProps> = ({ scope }) => {
    const { get } = useDataAttributes(scope);
    const isAllowed = useSelector(canAccessCampaign);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item
            icon={CAMPAIGN_ICON}
            as={RouterLink}
            to={campaignListRoute}
            {...get({ element: 'access-campaigns' })}
        >
            {translateKey(CAMPAIGNS.TITLE)}
        </Menu.Item>
    );
};

CampaignSettingMenu.displayName = 'CampaignSettingMenu';
