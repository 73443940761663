import isEmpty from 'lodash/isEmpty';

import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX, MIGRATION_URL_PREFIX } from '@lumapps/router/constants';

import { CURRENT_USER_ID_ROUTE_PLACEHOLDER } from './constants';
import { USER_PROFILE_VIEW } from './types';

export const legacyUserProfilePrefix = `/${URL_PREFIX}/profile/`;

export const userProfilePrefix = `/${MIGRATION_URL_PREFIX}/profile/`;

interface UserProfilePathParams {
    /** The profile view to go to. */
    view?: string;
    /** If the react profile route should be used. */
    v2?: boolean;
}

/** Get the user profile route path. */
export const getUserProfilePath = ({ view, v2 }: UserProfilePathParams = {}) => {
    const prefix = v2 ? userProfilePrefix : legacyUserProfilePrefix;
    const defaultView = view || ':view?';

    return `${prefix}:id/${defaultView}`;
};

export const legacyUserRoute: Route = {
    path: getUserProfilePath(),
    legacyId: 'app.front.profile',
    appId: AppId.legacy,
};

export const userProfileRoute: Route = {
    path: getUserProfilePath({ v2: true }),
    legacyId: 'app.front.profile.new',
    appId: AppId.frontOffice,
};

/**
 * Parameters for redirecting to a user profile
 */
export interface SocialProfileRouteParams {
    /**
     * The user Id of the user to redirect to.
     * If undefined, will redirect to "me"
     */
    userId?: string;
    /**
     * The view to redirect to.
     */
    view?: USER_PROFILE_VIEW;
    /**
     * Whether the user space FF is enabled, to maybe redirect to new user profile if applicable.
     */
    isUserSpaceEnabled?: boolean;
    /**
     * Query to add to the url.
     */
    query?: Record<string, any>;
}

/**
 * Social profile entry point.
 * If working in React context, please use the useSocialProfile() hook instead,
 * that automatically manages the FF-related mechanism to set v2
 *
 * @param userId the user id
 * @param view profile view
 * @param query URL queryParams to trigger specific behaviours
 * @param isUserSpaceEnabled Whether the user space is enabled.
 */
export const socialProfile = ({
    userId,
    view = undefined,
    query,
    isUserSpaceEnabled = false,
}: SocialProfileRouteParams = {}): Route => {
    const { details: detailsQuery, ...otherQuery } = query || {};

    // If user space is enabled, check if we want to go to a V2 page or not. The rules are:
    const routeToV2 =
        // Only if FF is enabled AND
        isUserSpaceEnabled &&
        // View is undefined or in migrated view AND
        (!view || Object.values(USER_PROFILE_VIEW).includes(view as USER_PROFILE_VIEW)) &&
        // There are no query params (we want to go back to legacy in these cases so the query can trigger a dialog)
        // OR query params === details (except in this specific case where the dialog is now replaced by /about)
        (!otherQuery || isEmpty(otherQuery) || detailsQuery);

    const params = {
        // if userId is not defined, use /me/
        id: userId || CURRENT_USER_ID_ROUTE_PLACEHOLDER,
        // userIdentifier is the legacy param used by the angularRouter instead of id
        userIdentifier: routeToV2 ? undefined : userId || CURRENT_USER_ID_ROUTE_PLACEHOLDER,
        // if we use the new route and view is not defined, use default /about page
        view: routeToV2 && !view ? USER_PROFILE_VIEW.about : view,
    };

    // Add mobile webview page descriptor when possible.
    const mobilePageDescriptor: Route['mobilePageDescriptor'] = userId
        ? { type: 'user-profile', id: userId }
        : undefined;

    return {
        ...(routeToV2 ? userProfileRoute : legacyUserRoute),
        params,
        query: routeToV2 ? otherQuery : query,
        mobilePageDescriptor,
    };
};

/**
 * Test the url to check if it matches the profile url.
 */
export const isUserProfileUrl = (url: string) => {
    return url.indexOf(legacyUserProfilePrefix) >= 0 || url.indexOf(userProfilePrefix) >= 0;
};
