import React from 'react';

import { FilterHookApi, FilterHookOptions } from '../types';

export type UseTextFilterOptions = Omit<FilterHookOptions<string>, 'getId'>;

export type UseTextFilter = FilterHookApi<string>;

export const useTextFilter = (options?: UseTextFilterOptions): UseTextFilter => {
    const [selected, setSelected] = React.useState<string | undefined>((options && options.defaultSelected) || '');
    const [selection, setSelection] = React.useState<string | undefined>(selected);

    const { defaultSelected, hasDynamicDefaultSelected } = options || {};

    /**
     * If the default values change, which could happen if the hook is displayed in a different
     * context, we need to reset the selected values on the internal state.
     */
    React.useEffect(() => {
        if (defaultSelected && hasDynamicDefaultSelected) {
            setSelected(defaultSelected);
        }
    }, [defaultSelected, hasDynamicDefaultSelected]);

    const onSelected = (sel: string | undefined) => {
        setSelection(sel);
    };

    const onFilter = () => {
        setSelected(selection);

        return selection;
    };

    const onClearSelection = () => {
        setSelection(selected);
    };

    const onClearSelected = () => {
        setSelected('');
        setSelection('');
    };

    const onResetFilters = () => {
        if (options?.defaultSelected) {
            setSelected(options?.defaultSelected);
            setSelection(options?.defaultSelected);
        } else {
            onClearSelected();
        }
    };

    return {
        onSelected,
        onFilter,
        selected,
        selection,
        onClearSelection,
        onClearSelected,
        onResetFilters,
    };
};
