import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';

import { ReactEditor, Editor, Node, NodeEntry, Transforms, Element } from '..';
import { MatchFn } from './matchFn';

/**
 * This normalization function ensure there is a block node AFTER the current node, and adds a
 * paragraph block otherwise.
 * This is useful for block nodes, to allow the user to leave the block with keyboard or mouse.
 * @param editor The editor
 * @param param1 The node entry of the evaluated node
 * @param match  The match function to define if we should or not normalize
 */
export function normalizeLastBlock<EdType = ReactEditor>(
    editor: ReactEditor & EdType,
    [node, path]: NodeEntry,
    match: MatchFn<Node>,
) {
    // If at first level, the parent will be the editor.
    const [parentNode, parentPath] = editor.parent(path);
    if (Element.isElement(node) && Editor.isBlock(editor, node) && match(node)) {
        const positionInParent = parentNode ? path[parentPath.length] : path[0];

        const isLastBlockOfParent = positionInParent === parentNode.children.length - 1;

        if (isLastBlockOfParent) {
            Transforms.insertNodes(editor, createParagraph(), {
                at: [...parentPath, parentNode.children.length],
            });
            return true;
        }
    }
    return false;
}
