import { hashCode } from '../string/hashCode';

/**
 * Returns a random value from a list. If a seed is provided, it will return the same random value
 * if it is executed multiple times with the same value.
 * @param values - list of possible values
 * @param seed  - seed that ensures the same random value for a given str
 */
export const getRandomValue = <T>(values: T[], seed?: string) => {
    return values[seed ? Math.abs(hashCode(seed) % values.length) : Math.random() * (values.length - 1)];
};
