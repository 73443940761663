import React from 'react';

import { margin } from '@lumapps/classnames';
import { Message, Kind, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_POST_LIST } from '../../keys';

/**
 * Component for a display message in legacy
 */

export const WidgetPostListWarningMessageSlideshowView = () => {
    const { translateKey } = useTranslate();

    return (
        <Message className={margin('top', 'big')} kind={Kind.warning} hasBackground>
            <Text as="p">{translateKey(WIDGET_POST_LIST.WARNING_MESSAGE_SLIDESHOW_VIEW)}</Text>
        </Message>
    );
};
