/*
 * Service to get Sharepoint sites.
 */
function SharepointSiteService(LumsitesBaseService, SharepointSiteFactory) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(SharepointSiteFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /////////////////////////////

    return service;
}

angular.module('Services').service('SharepointSite', SharepointSiteService);

export { SharepointSiteService };
