import React from 'react';

import LegacyAppBlockRemote from '@lumapps/marketplace/components/LegacyAppBlockRemote';
import { ComponentTypes } from '@lumapps/marketplace/types';

export type WidgetSettingsProps = {
    useConnectorSelector: boolean;
    component: { properties: Record<string, unknown>; uuid: string };
    contributionMode: string;
    extensionId: string;
    globalValue?: Record<string, unknown>;
    properties?: Record<string, unknown>;
    remoteUrl: string;
    exportProp(props: Record<string, unknown>): void;
};

/**
 * React Widgets Settings skeleton.
 */
export const WidgetSettings: React.FC<WidgetSettingsProps> = (props) => {
    const {
        component,
        exportProp,
        globalValue = {},
        extensionId,
        useConnectorSelector,
        properties = {},
        contributionMode,
    } = props;

    return (
        <div className="remote-component">
            <LegacyAppBlockRemote
                componentType={ComponentTypes.settings}
                contributionMode={contributionMode}
                extensionId={extensionId}
                extensionProperties={properties}
                globalProperties={globalValue}
                uuid={component.uuid}
                exportProp={exportProp}
                useConnectorSelector={useConnectorSelector}
            />
        </div>
    );
};
