import * as React from 'react';
import * as propTypes from 'prop-types';

import noop from 'lodash/noop';

import * as types from '../../../types';
import { getImageURL } from '../../../../utils';
import { getImage } from '../../../../services/media';
import { open as openMediaPicker } from '../../../../services/media-picker';
import { translate as t } from '../../../../translations';
import { Icon } from '../../icons/Icon';

/**
 * Renders a single image thumbnail that can be updated by clicking on it.
 *
 * Requires the `<reusable-media-picker />` directive to be present in the DOM somewhere.
 */
export class ImageChooser extends React.PureComponent {
    static propTypes = {
        name: types.properties.isRequired,
        /** Called when a new image is choosen, with `(value, name)`. */
        onChange: propTypes.func.isRequired,
        /** Custom styling applied to the image preview `<div />`. */
        style: propTypes.object,
        /** Image media. */
        value: propTypes.object,
    };

    constructor(props) {
        super(props);
        const onChange = (value) => this.props.onChange(value, this.props.name);
        this.openMediaPicker = () => openMediaPicker(this.props.value, onChange);
        this.deleteImage = (evt) => {
            evt.stopPropagation();
            onChange(undefined);
        };
    }

    render() {
        const { value, style = {} } = this.props;

        return (
            <div className="ls-media-manager image-chooser" onClick={() => (value ? noop() : this.openMediaPicker())}>
                <div className="uploader">
                    {value ? (
                        <div className="editable-media editable-media--edit-hover">
                            <div
                                className="editable-media__img editable-media__img--is-thumbnail"
                                style={{
                                    backgroundImage: getImageURL(getImage(value)),
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    ...style,
                                }}
                            />

                            <div className="editable-media__bar">
                                <div className="editable-media__label">
                                    <span>{t('STYLE.IMAGE')}</span>
                                </div>

                                <div className="editable-media__actions">
                                    <Icon value="pencil" onClick={this.openMediaPicker} />
                                    <Icon value="delete" onClick={this.deleteImage} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="uploader__placeholder">
                            <Icon value="image" />
                            <span>{t('SELECT_IMAGE')}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
