import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { Editor, ReactEditor, Transforms } from '@lumapps/wrex/slate';

import { INLINE_AUTOCOMPLETE_SEARCH } from '../constants';
import { isAutocompleteAllowed } from './isAutocompleteAllowed';

/** Insert an autocomplete search */
export function insertAutocompleteSearch(editor: Editor, triggerCharacter: string) {
    // We need to focus the editor to avoid the automatic close of the search.
    ReactEditor.focus(editor as ReactEditor);

    // Insert point (current selection start or at the end of the document).
    const point = editor.selection?.anchor || Editor.end(editor, []);

    // Insert a paragraph if needed
    if (!isAutocompleteAllowed(editor, point)) {
        Transforms.insertNodes(editor, createParagraph(), { at: point, mode: 'highest', select: true });
    }

    // Insert a space before if needed
    const newPoint = editor.selection?.anchor || point;
    if (newPoint) {
        const pointBefore = Editor.before(editor, newPoint, { distance: 1, unit: 'character' });
        const charBefore = pointBefore && Editor.string(editor, { anchor: pointBefore, focus: newPoint });
        if (charBefore?.trim()) {
            editor.insertText(' ');
        }
    }
    // Insert inline autocomplete search
    Transforms.insertNodes(editor, { text: triggerCharacter, [INLINE_AUTOCOMPLETE_SEARCH]: true } as any, {
        select: true,
    });
}
