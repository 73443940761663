import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { SLACK_PROVIDER } from '../../constants';
import { chatSlackTokenStatusSelector, isSlackDefaultAppEnabled } from '../../ducks/selectors';
import { disconnectUserToken, fetchAuthorizeLink, fetchUserTokenStatus } from '../../ducks/thunks';
import { SlackLoginButton } from './SlackLoginButton';

const mapDispatchToProps = {
    disconnectUserToken,
    fetchAuthorizeLink,
    fetchUserTokenStatus,
};

const mapStateToProps = (state: FrontOfficeStore, ownProps: any) => {
    const { spaceId, loggedInCallback, shareToSlack } = ownProps;

    const userTokenStatus = chatSlackTokenStatusSelector(state);

    const isEnabled = isSlackDefaultAppEnabled(state);

    return {
        spaceId,
        loggedInCallback,
        shareToSlack,
        slackProvider: SLACK_PROVIDER,
        userTokenStatus,
        isSlackDefaultAppEnabled: isEnabled,
    };
};

const ConnectedSlackLoginButton = connect(mapStateToProps, mapDispatchToProps)(SlackLoginButton);

export { ConnectedSlackLoginButton as SlackLoginButton };
