import { SerializeOptions } from '@lumapps/wrex/serialization/markdown/toMarkdown/types';

import type { UserMentionElement } from '../../types';

type MDASTUserMention = { type: 'user-mention'; attributes: { user: { id: string; fullName: string } } };

export const USER_MENTION_ELEMENTS: SerializeOptions['elements'] = {
    'user-mention': ({ user }: UserMentionElement) => {
        return {
            type: 'user-mention',
            attributes: {
                user: {
                    id: user.id,
                    fullName: user.fullName,
                },
            },
        } as MDASTUserMention;
    },
};

export const USER_MENTION_MD_HANDLER = {
    'user-mention': ({ attributes: { user } }: MDASTUserMention) => {
        return `@[${user.fullName.replace(/ /g, '-')}:${user.id}]`;
    },
};
