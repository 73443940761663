import last from 'lodash/last';
import { Editor } from 'slate';

/**
 * Checks if the editor selection focus is at the start of a text node and itself at the start of it's parent element.
 */
export function isAtStartOfTextNode(editor: Editor) {
    if (!editor.selection) {
        return false;
    }
    const { offset, path } = editor.selection.focus;
    if (offset !== 0) {
        return false;
    }
    return last(path) === 0;
}
