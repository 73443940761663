import React from 'react';

import { Size, TypographyCustom } from '@lumapps/lumx/react';
import { BlockVideo } from '@lumapps/play-video';
import { Suspense } from '@lumapps/utils/components/Suspense';

const LazyBlockPlayVideo = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'block-play-video' */
            './BlockPlayVideo'
        ),
);

export const BlockPlayVideo: React.FC<React.ComponentPropsWithoutRef<typeof LazyBlockPlayVideo>> = (props) => {
    const { author, description, duration, createdAt, orientation, playVideoId, thumbnail, title, views } = props;

    return (
        <Suspense
            loadingFallback={
                <BlockVideo
                    isLoading
                    video={{
                        date: createdAt,
                        createdById: author?.userId,
                        description,
                        duration,
                        id: playVideoId,
                        thumbnail,
                        title,
                        views,
                    }}
                    orientation={orientation.toLowerCase() as Lowercase<typeof orientation>}
                >
                    <BlockVideo.Figure>
                        <BlockVideo.Thumbnail />
                    </BlockVideo.Figure>

                    <BlockVideo.Content gap={Size.regular}>
                        <BlockVideo.Title typography={TypographyCustom.title4} />

                        <BlockVideo.Description />

                        <BlockVideo.Metadata>
                            <BlockVideo.Date />

                            <BlockVideo.Views />
                        </BlockVideo.Metadata>
                    </BlockVideo.Content>
                </BlockVideo>
            }
        >
            <LazyBlockPlayVideo {...props} />
        </Suspense>
    );
};
