import { isText } from '../slate/utils/isText';
import { Wrex } from '../types';
import { createEmptyContent } from './createEmptyContent';

function normalizeNode(node: Wrex.Node): Wrex.Node {
    if (isText(node)) {
        return node;
    }
    if (!node?.children?.length) {
        // Init empty children
        return { ...node, children: [{ text: '' }] };
    }
    // Normalize children
    return { ...node, children: node.children.map(normalizeNode) };
}

/**
 * Perform generic normalization of slate-like document to follow the basic rules of slate
 */
export function normalizeForSlate(content: Wrex.Nodes) {
    if (!content?.length) {
        // Init empty content
        return createEmptyContent();
    }
    // Normalize children
    return content.map(normalizeNode);
}
