export enum ViewMode {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

export enum ItemVariant {
    default = 'default',
    iconOnly = 'icon-only',
    labelOnly = 'label-only',
}
