import set from 'lodash/set';

/////////////////////////////

function WidgetWeatherSettingsController($rootScope, $timeout, Widget) {
    'ngInject';

    const widgetWeatherSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Defines the `maxNumber` property limits.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _WEATHER_MAX_NUMBER_LIMITS = {
        DEFAULT: 4,
        MAX: 7,
        MIN: 1,
    };

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Update the widget on settings change.
     */
    function updateWidget() {
        const currentWidget = Widget.getCurrent();
        const { maxNumber } = currentWidget.properties;

        if (maxNumber > _WEATHER_MAX_NUMBER_LIMITS.MAX) {
            set(currentWidget, ['properties', 'maxNumber'], _WEATHER_MAX_NUMBER_LIMITS.MAX);
        } else if (angular.isDefinedAndFilled(maxNumber) && maxNumber < _WEATHER_MAX_NUMBER_LIMITS.MIN) {
            set(currentWidget, ['properties', 'maxNumber'], _WEATHER_MAX_NUMBER_LIMITS.MIN);
        } else if (angular.isUndefinedOrEmpty(maxNumber)) {
            set(currentWidget, ['properties', 'maxNumber'], _WEATHER_MAX_NUMBER_LIMITS.DEFAULT);
        }

        $timeout(() => {
            $rootScope.$broadcast('widget-weather-settings', Widget.getCurrent().uuid);
        });
    }

    /////////////////////////////

    widgetWeatherSettings.updateWidget = updateWidget;
}

/////////////////////////////

angular.module('Controllers').controller('WidgetWeatherSettingsController', WidgetWeatherSettingsController);

/////////////////////////////

export { WidgetWeatherSettingsController };
