import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import range from 'lodash/range';

import { ReactEditor, Editor, Range } from '@lumapps/wrex/slate';

import { TableEditor } from '../types';
import { getCurrentCellsInRow } from './getCurrentCellsInRow';
import { getCurrentRowsInTable } from './getCurrentRowsInTable';
import { isTable } from './isTable';

export const isOnlyOneRowFullySelected = (
    editor: ReactEditor & TableEditor,
    startRow: number,
    endRow: number,
    endCol: number,
) => {
    const tablePath = [Range.start(editor.selection as Range).path[0]];
    const cellsInRow = getCurrentCellsInRow(editor, [...tablePath, 0]);
    if (startRow === endRow && cellsInRow.cellsLength - 1 === endCol) {
        return true;
    }
    return false;
};
export const isOnlyOneColFullySelected = (
    editor: ReactEditor & TableEditor,
    endRow: number,
    startCol: number,
    endCol: number,
) => {
    const tablePath = [Range.start(editor.selection as Range).path[0]];
    const rowsInTable = getCurrentRowsInTable(editor, tablePath);
    if (startCol === endCol && rowsInTable.rowsLength - 1 === endRow) {
        return true;
    }
    return false;
};
export const isAllTableSelected = (
    editor: ReactEditor & TableEditor,
    startRow: number,
    endRow: number,
    startCol: number,
    endCol: number,
    selectedCols: number[],
    selectedRows: number[],
) => {
    const tablePath = [Range.start(editor.selection as Range).path[0]];
    const cellsInRow = getCurrentCellsInRow(editor, [...tablePath, 0]);
    const rowsInTable = getCurrentRowsInTable(editor, tablePath);
    if (
        startCol !== endCol &&
        startRow !== endRow &&
        cellsInRow.cellsLength === selectedCols.length &&
        rowsInTable.rowsLength === selectedRows.length
    ) {
        return true;
    }
    return false;
};

export const getCurrentSelectionInfos = (editor: ReactEditor & TableEditor) => {
    const { selection } = editor;

    /* first check that we actually have a selection */
    if (!selection) {
        return null;
    }

    /* then check that we're in a table */
    const currentEntriesInTable = Array.from(
        Editor.nodes(editor, { match: isTable, mode: 'highest', at: editor.selection?.anchor.path }),
    );
    if (!currentEntriesInTable.length) {
        return null;
    }
    const tablePath = [Range.start(editor.selection as Range).path[0]];
    const selectionPaths = editor.getCellSelection();

    /** If selection is collapsed, return the cell infos */
    if (isEmpty(selectionPaths)) {
        return {
            tablePath,
            isSelectionInOneCell: true,
            startRow: selection.anchor.path[1],
            endRow: selection.anchor.path[1],
            startCol: selection.anchor.path[2],
            endCol: selection.anchor.path[2],
            selectedRows: [selection.anchor.path[1]],
            selectedCols: [selection.anchor.path[2]],
            oneRowFullySelected: false,
            oneColFullySelected: false,
            allTableSelected: false,
        };
    }
    /** If selection is not collapsed, return the selection infos */
    if (!isEmpty(selectionPaths)) {
        const firstCellCordinates = head(selectionPaths) || [...tablePath, 0, 0];
        const lastCellCoordinates = last(selectionPaths) || [...tablePath, 0, 0];
        // Get the first row of the selection
        const startRow = firstCellCordinates[1];
        // Get the last row of the selection
        const endRow = lastCellCoordinates[1];
        // Get the first column of the selection
        const startCol = firstCellCordinates[2];
        // Get the last column of the selection
        const endCol = lastCellCoordinates[2];
        // get the list of the selected columns
        const selectedCols = range(startCol, endCol + 1, 1);
        // get the list of the selected rows
        const selectedRows = range(startRow, endRow + 1, 1);

        const oneRowFullySelected = isOnlyOneRowFullySelected(editor, startRow, endRow, endCol);
        const oneColFullySelected = isOnlyOneColFullySelected(editor, endRow, startCol, endCol);
        const allTableSelected = isAllTableSelected(
            editor,
            startRow,
            endRow,
            startCol,
            endCol,
            selectedCols,
            selectedRows,
        );

        return {
            tablePath,
            isSelectionInOneCell: false,
            oneRowFullySelected,
            oneColFullySelected,
            allTableSelected,
            startRow,
            endRow,
            startCol,
            endCol,
            selectedRows,
            selectedCols,
        };
    }
    return null;
};
