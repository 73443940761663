(function IIFE() {
    'use strict';

    /////////////////////////////

    function StockExchangeFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/stockexchange/:methodKey', {}, {
            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('StockExchangeFactory', StockExchangeFactory);
})();
