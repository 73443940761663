import { SearchSuggestionType, SearchSuggestion } from '@lumapps/search/types';

import { QuickSearchQuery } from '../types';

export const queriesToSuggestions = (items?: QuickSearchQuery[]): SearchSuggestion[] => {
    if (!items) {
        return [];
    }

    return items.map((item) => {
        return {
            label: item.query,
            siteId: `${item.siteId}`, // Backend is sending number
            query: item.query,
            type: SearchSuggestionType.HISTORY,
            counterClick: item.counterClick,
        };
    });
};
