interface ArticleShouldTriggerInfiniteScrollProps {
    infiniteScrollSensitivity: number;
    articlePosition: number;
    totalArticles: number;
}

/**
 * Check whether the given article position should trigger an infinite scroll
 * based on sensitivity.
 */
export const articleShouldTriggerInfiniteScroll = ({
    infiniteScrollSensitivity,
    articlePosition,
    totalArticles,
}: ArticleShouldTriggerInfiniteScrollProps) => {
    const aimedPosition = totalArticles - infiniteScrollSensitivity;
    // Make sure we don't go below 1 as it would trigger
    const positionToTrigger = aimedPosition > 1 ? aimedPosition : 1;

    return articlePosition >= positionToTrigger;
};
