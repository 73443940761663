(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserFollowingService(LumsitesBaseService, UserFollowingFactory) {
        'ngInject';

        return LumsitesBaseService.createLumsitesBaseService(UserFollowingFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });
    }

    /////////////////////////////

    angular.module('Services').service('UserFollowing', UserFollowingService);
})();
