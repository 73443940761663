import { arrayOf, bool, number, objectOf, oneOf, oneOfType, shape, string } from 'prop-types';

export const documentType = shape({
    /** The date the document has been created. */
    createdAt: string.isRequired,
    /** The document id. */
    id: string.isRequired,
    /** The document instance id. */
    instanceId: string,
    /** Whether the current document is a folder or not. */
    isFolder: bool,
    /** Whether the document is shared or not. */
    isShared: bool,
    /** Whether the document is trashed or not. */
    isTrashed: bool,
    /** The kind of media. */
    kind: oneOf(['Drive', 'Folder', 'Media']),
    /** The document name. */
    name: oneOfType([string, objectOf(string)]),
    /** The content creator details. TODO[Marco]: userType. */
    owner: objectOf(oneOfType([string, number, objectOf(string)])).isRequired,
    /** The last time a document has been updated. */
    updatedAt: string,
    /** The document uuid. */
    uuid: string.isRequired,
});

export const mediaContentType = shape({
    /** The current content language. */
    lang: string,
    /** The media mime-type. */
    mimeType: string,
    /** The absolute serving url the media is accessible at. */
    servingUrl: string,
});

export const mediaType = shape({
    ...documentType,
    content: arrayOf(mediaContentType).isRequired,
    croppedContent: arrayOf(mediaContentType),
});
