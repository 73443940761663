import mdast from 'mdast';

import { SerializeOptions } from '@lumapps/wrex/serialization/markdown/toMarkdown/types';

import { ImageElement } from '../../components/blocks/Image';

export const IMAGE_ELEMENTS: SerializeOptions['elements'] = {
    image: (image: ImageElement) =>
        ({
            type: 'image',
            alt: image.alt,
            title: image.title,
            url: image.src,
        }) as mdast.Image,
};
