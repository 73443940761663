(function IIFE() {
    /////////////////////////////

    function CommunityPostVisibilityListController($scope, Utils) {
        'ngInject';

        const cpvl = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the move post dialog.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        cpvl.DIALOG_ID = 'post-visibility-dialog';

        /**
         * The community block fields used displaying a community.
         *
         * @type {Array}
         */
        cpvl.FIELDS = ['members', 'title', 'content', 'follow'];

        /**
         * The post the is shared in the communities listed in this dialog.
         *
         * @type {Object}
         */
        cpvl.post = undefined;

        /**
         * The communities the post is shared with.
         * Only the communities that should be displayed are listed in this filtered list.
         * If in the context of a community the current community is filtered out from the list
         * of communities this post is shared with.
         *
         * @type {Object}
         */
        cpvl.postVisibilityCommunitiesList = [];

        /////////////////////////////
        //                         //
        //     Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the list of communities to display
         * if the post is displayed in the context of a community, that community is filtered out.
         * 
         * @return {Array} the list of communities to display in the dialog.
         */
        const _getCommunitiesToDisplay = () => {
            return cpvl.post.visibleInCommunitiesDetails
                .map((community) => community.postedTo)
                .filter((community) => {
                    return cpvl.currentContextCommunityId ? community.uid !== cpvl.currentContextCommunityId : true;
                });
        };

        /**
         * Return the number of communities the post is shared with
         * if the post is displayed in the context of a community, that community is not taken
         * into consideration in the count.
         * 
         * @return {number} the number of communities the post is shared with.
         */
        const _getCommunitiesCount = () => {
            return cpvl.currentContextCommunityId
                ? cpvl.post.visibleInCommunitiesCount - 1
                : cpvl.post.visibleInCommunitiesCount;
        };

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Called when the dialog is opened
         * initializes the attributes of the controller depending on the arguments attached to the event.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The dialog identifier.
         */
        $scope.$on('post-visibility-dialog__open', (event, args) => {
            cpvl.post = args.post;
            cpvl.currentContextCommunityId = args.currentContextCommunityId;
            cpvl.isSharedInPrivateCommunities = args.isSharedInPrivateCommunities;
            Utils.waitForAndExecute(`#${cpvl.DIALOG_ID}`);
        });

        /**
         * Reset the controller.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The dialog identifier.
         */
        $scope.$on('lx-dialog__close-end', function onMovePostDialogCloseEnd(evt, dialogId) {
            if (dialogId !== cpvl.DIALOG_ID) {
                cpvl.postVisibilityCommunitiesList = [];
                cpvl.postVisibilityCommunitiesCount = 0;
            }
        });

        /**
         * Launch the initilization of the controller.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The dialog identifier.
         */
        $scope.$on('lx-dialog__open-start', (evt, dialogId) => {
            if (dialogId === cpvl.DIALOG_ID) {
                cpvl.init();
            }
        });

        /**
         * Initialize the controller.
         */
        cpvl.init = function init() {
            cpvl.postVisibilityCommunitiesList = _getCommunitiesToDisplay();
            cpvl.postVisibilityCommunitiesCount = _getCommunitiesCount();
        };
    }

    /////////////////////////////

    angular
        .module('Controllers')
        .controller('CommunityPostVisibilityListController', CommunityPostVisibilityListController);
})();
