/* istanbul ignore file */
import join from 'lodash/join';

import BaseApi from '@lumapps/base-api';

import {
    ReportReason,
    ReportedResourceType,
    ReportedResources,
    ReportsfromResource,
    ReportedRequestParams,
    ReportedResourcesFetchParams,
} from '../types';

const NB_REPORTS_BY_PAGE = 10;

const reportsApi = new BaseApi({
    path: '',
    version: BaseApi.versions.v2,
});

const get = async ({ resourceType, cursor }: ReportedResourcesFetchParams) => {
    const resourceTypeParam = join(resourceType, ',');
    const params = { resourceTypes: resourceTypeParam, cursor };

    const { data } = await reportsApi.get<ReportedResources>('/reported-resources', {
        params: {
            maxResults: NB_REPORTS_BY_PAGE,
            ...params,
        },
    });
    return data;
};

const getReportsFromResource = async (resourceId: string, resourceType: ReportedResourceType) => {
    const { data } = await reportsApi.get<ReportsfromResource>('/reports', {
        params: {
            resourceId,
            resourceType,
        },
    });
    return data;
};

const getUserReport = async (params: Pick<ReportedRequestParams, 'resourceId' | 'resourceType'>) => {
    const { data } = await reportsApi.get<ReportedResources>('/reports/mine', { params });
    return data;
};

const report = async (
    resourceId: string,
    resourceType: ReportedResourceType,
    reportReason: ReportReason,
    comment: string | undefined,
) => {
    const { data } = await reportsApi.post('/reports', {
        resourceId,
        resourceType,
        reportReason,
        comment,
    });
    return data;
};

const dismiss = async (resourceId: string, resourceType: ReportedResourceType) => {
    const { data } = await reportsApi.post('/reports/dismiss', {
        resourceId,
        resourceType,
    });
    return data;
};
const accept = async (resourceId: string, resourceType: ReportedResourceType) => {
    const { data } = await reportsApi.post('/reports/accept', {
        resourceId,
        resourceType,
    });
    return data;
};

export { accept, dismiss, get, getUserReport, reportsApi, report, getReportsFromResource };
