export enum WREX_IMAGE_GALLERY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412197547
     */
    BUTTON_LABEL = 'WREX_IMAGE_GALLERY.BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412197442
     */
    BUTTON_HELPER = 'WREX_IMAGE_GALLERY.BUTTON_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=432383189
     */
    BUTTON_TOOLTIP = 'WREX_IMAGE_GALLERY.BUTTON_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=415185984
     */
    DELETE_GALLERY = 'WREX_IMAGE_GALLERY.DELETE_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=414673851
     */
    DELETE_GALLERY_TITLE_CONFIRMATION = 'WREX_IMAGE_GALLERY.DELETE_GALLERY_TITLE_CONFIRMATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=414811633
     */
    EDIT_GALLERY = 'WREX_IMAGE_GALLERY.EDIT_GALLERY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423317937
     */
    IMAGE_GALLERY_DIALOG_DETAILS_TOOLBAR_LABEL = 'WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_DETAILS_TOOLBAR_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=413369782
     */
    IMAGE_GALLERY_DIALOG_EDIT_TITLE = 'WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=419208698
     */
    IMAGE_GALLERY_DIALOG_EMPTY_TITLE = 'WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_EMPTY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=419208713
     */
    IMAGE_GALLERY_DIALOG_EMPTY_DESCRIPTION = 'WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_EMPTY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=413369834
     */
    IMAGE_GALLERY_DIALOG_MESSAGE = 'WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=415355569
     */
    TOOLBAR = 'WREX_IMAGE_GALLERY.TOOLBAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422509297
     */
    IMAGE_GALLERY_DIALOG_IMAGE_ARIA_LABEL = 'WREX_IMAGE_GALLERY.IMAGE_GALLERY_DIALOG_IMAGE_ARIA_LABEL',
}
