import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const newsletterRoute: Route = {
    path: '/admin/newsletters/list',
    legacyId: 'app.admin.newsletter',
    appId: AppId.backOffice,
};

export const createNewsletterRoute: Route = {
    path: '/admin/newsletters/create',
    legacyId: 'app.admin.newsletter-create',
    appId: AppId.legacy,
};

export const editNewsletterRoute: Route = {
    path: '/admin/newsletters/edit/:newsletterId',
    legacyId: 'app.admin.newsletter-edit',
    appId: AppId.legacy,
};

/**
 * Retrieves the route for main nav's admin.
 */
export const admin = (): Route => {
    return newsletterRoute;
};

export const create = (): Route => {
    return createNewsletterRoute;
};

export const edit = ({ id }: { id: string }): Route => {
    return {
        ...editNewsletterRoute,
        params: {
            newsletterId: id,
        },
    };
};
