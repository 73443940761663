import { useMemo } from 'react';

import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import { GLOBAL, useTranslate } from '@lumapps/translations';
import evolve from '@lumapps/utils/function/evolve';

import { WIDGET_POST_LIST } from '../../keys';
import {
    PostListFilterChoice,
    HasRelevantComment,
    PostListFilterBlockProps,
    PostListFilterHookProps,
} from '../../types';
import { formatName } from '../../utils/PostListFilters/formatName';
import { createGetChoiceName } from '../../utils/PostListFilters/getChoiceName';
import { getFirstValueName } from '../../utils/PostListFilters/getFirstValueName';

export const HAS_RELEVANT_COMMENT_FILTER_CHOICES = [
    {
        name: GLOBAL.ALL as string,
        value: HasRelevantComment.all,
    },
    {
        name: WIDGET_POST_LIST.FILTERS_AS_RELEVANT as string,
        value: HasRelevantComment.only,
    },
    {
        name: WIDGET_POST_LIST.FILTERS_NOT_AS_RELEVANT as string,
        value: HasRelevantComment.exclude,
    },
];

export function useCommentRelevancyFilter({
    activeFiltersValues,
    filtersValues,
    handleValuesChange,
}: PostListFilterHookProps): Omit<PostListFilterBlockProps<HasRelevantComment>, 'theme'> {
    const filterId = 'hasRelevantComment';

    const { translateKey } = useTranslate();

    const activeValues = useMemo(
        () => getOr([] as HasRelevantComment[], filterId, activeFiltersValues),
        [activeFiltersValues],
    );
    const selectedValues = getOr([] as HasRelevantComment[], filterId, filtersValues);

    const choices = useMemo(
        () => map(evolve({ name: translateKey }), HAS_RELEVANT_COMMENT_FILTER_CHOICES),
        [translateKey],
    ) as PostListFilterChoice<HasRelevantComment>[];

    const { getChoiceName, label, name } = useMemo(() => {
        const getChoiceName = createGetChoiceName(choices);
        const label = translateKey(WIDGET_POST_LIST.FILTERS_COMMENTS_RELEVANCY);
        const firstActiveValueName = getFirstValueName(getChoiceName, activeValues);

        return {
            getChoiceName,
            label,
            name: formatName(label, firstActiveValueName, activeValues.length),
        };
    }, [activeValues, choices, translateKey]);

    return {
        choices,
        name,
        filterId,
        getChoiceName,
        isActive: !isEmpty(activeValues),
        label,
        onSelect: handleValuesChange,
        selectedValues,
    };
}
