export enum CATALOG {
    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=437022776
    NO_CATALOG = 'LEARNING.NO_CATALOG_COURSES',

    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=437022909
    ACCESS_TO_CATALOG = 'LEARNING.THIS_PAGE_WILL_GIVE_ACCESS_TO_CATALOG',

    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=446514564
    SEARCH = 'LEARNING.SEARCH_CATALOG',

    // https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447287395
    SELECT_CATEGORY_HELPER = 'LEARNING.WIDGET_TYPE_CATALOG_SELECT_CATEGORY_HELP',
}
