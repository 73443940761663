const { angular } = global;

const Constants = {
    AdvancedSettingsConstant: {},
    CloudSearchConstant: {},
    CommunityConstant: {},
    CommunityWizardConfiguration: {},
    Config: {},
    ConfigInstance: {},
    ConfigTheme: {},
    FroalaSettings: {},
    InitialSettings: {},
    InstanceConstant: {},
    NotificationSettings: {},
    PostConstant: {},
    RewardKinds: {},
    UserConstant: {},
    WidgetConstant: {},
    WidgetFileListConstant: {},
    WidgetSettingsConstant: {},
    ZendeskConstant: {},
};

export const {
    AdvancedSettingsConstant,
    CloudSearchConstant,
    CommunityConstant,
    CommunityWizardConfiguration,
    Config,
    ConfigInstance,
    ConfigTheme,
    FroalaSettings,
    InitialSettings,
    InstanceConstant,
    NotificationSettings,
    PostConstant,
    RewardKinds,
    UserConstant,
    WidgetConstant,
    WidgetFileListConstant,
    WidgetSettingsConstant,
    ZendeskConstant,
} = Constants;

function capture(moduleName, setterName) {
    const Module = angular.module(moduleName);
    const set = Module[setterName];
    // eslint-disable-next-line angular/window-service
    Module[setterName] = function setModule(key, value) {
        set.call(
            Module,
            key,
            key in Constants && angular && angular.extend
                ? angular.extend(Constants[key], value)
                : (Constants[key] = value),
        );
    };
}

capture('Constants', 'constant');
capture('Values', 'value');
