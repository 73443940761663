/* istanbul ignore file */
export enum NAVIGATION_PERMISSIONS {
    /** The role that allows an user to access and edit main navigation */
    MENU_EDIT = 'MENU_EDIT',
    /** The role that allows an user to access and partially edit main navigation (ONLY ON REACT MAIN NAV) */
    MENU_DROP = 'MENU_DROP',
    /** The role that allows an user to access, add, move and delete nodes of main navigation */
    MENU_DELETE = 'MENU_DELETE',
    /** Legacy role, should not be used anymore */
    MENU_MOVE = 'MENU_MOVE',
}
