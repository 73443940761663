import type { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchAttribute } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { CLASSNAME } from '../../../components/blocks/LinkPreview';
import { LINK_PREVIEW } from '../../../constants';
import { createLinkPreview } from '../../../utils/createLinkPreview';

type ElementTypes = typeof LINK_PREVIEW;

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    [LINK_PREVIEW]: {
        type: LINK_PREVIEW,
        test: matchAttribute('className', new RegExp(CLASSNAME)),
        transform: ({ children }: any) => {
            // Lumapps Link Preview
            const previewWrapper = children[0].children[0];
            const thumbnailWrapper = previewWrapper.children[0];
            const contentWrapper = previewWrapper.children[1];

            const url = contentWrapper.children[0].children[0].properties.href;
            const title = contentWrapper.children[0].children[0].children[0].children[0].value;
            const description = contentWrapper.children[1].children[0].value;
            const thumbnailUrl = thumbnailWrapper.children[0].children[0].children[0].properties.src;

            return createLinkPreview({ url, title, description, thumbnailUrl });
        },
    },
};
