import { useEffect, useReducer } from 'react';

import { initialState, reducer, SpaceState } from '../ducks/slice';
import { fetchSpaceData } from '../ducks/thunks';

interface UseHeaderLegacyPreviewProps {
    spaceId: string;
    spaceSlug: string;
}

export const useHeaderLegacyPreview = ({ spaceId, spaceSlug }: UseHeaderLegacyPreviewProps): SpaceState => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (spaceId) {
            fetchSpaceData(spaceId, spaceSlug)(dispatch);
        }
    }, [spaceId, spaceSlug]);

    return { ...state };
};
