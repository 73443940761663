import React from 'react';

import { SideNavigation as Navigation } from '@lumapps/lumx/react';

export interface SideNavigationProps {
    /**
     * Content of the navigation. These components should be of type SideNavigationItem or at
     * least have the displayName property set to SideNavigationItem. If they do not, they won't be rendered
     * */
    children?: React.ReactNode;
    /**
     * Classname that will be used for the nav wrapping element
     */
    className?: string;
}

const CLASSNAME = 'side-navigation';
/**
 * Displays a navigation that will be shown on the side navigation in the back office
 *
 * @family Back office
 * @param SideNavigationProps
 * @returns SideNavigation
 */
export const SideNavigation: React.FC<SideNavigationProps> = ({ children, className }) => {
    return (
        <nav className={className}>
            <Navigation className={CLASSNAME}>{children}</Navigation>
        </nav>
    );
};
