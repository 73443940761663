/**
 *
 * @param {File} file
 * @returns {string} file name without extension
 * @example
 *  const file = new File([], 'Chuck norris.mp4');
 *  removeExtension(file); // 'Chuck norris'
 */
export const removeExtension = (file: File) => {
    const regex = /^(?<fileName>.*)\.(?<fileExt>.{2,4})$/gs;

    return regex.exec(file.name)?.groups?.fileName ?? file.name;
};
