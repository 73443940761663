(function IIFE() {
    'use strict';

    /////////////////////////////

    function InstanceBlockController($scope, $window, Config, Customer, Features, Instance, Metadata, Style,
        Translation, User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Used to indicates if the metadata filedsis enable in widget settings.
         *
         * @type {boolean}
         */
        var _hasMetadataEnabled = false;

        /**
         * The list of CSS classes depending on metadatas attached to the instance.
         *
         * @type {Array}
         */
        var _metadataClasses = [];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * An object holding th ng-style background image property of the instance.
         *
         * @type {Object}
         */
        vm.backgroundImage = {};

        /**
         * The full list of CSS classes of the instance block.
         *
         * @type {Array}
         */
        vm.classes = [];

        /**
         * The list of fields to display in the content block.
         * Each field is represented by an object.
         *
         * @type {Array}
         */
        vm.fieldsToDisplay = [];

        /**
         * The secondary field displayed under the instance name.
         *
         * @type {string}
         */
        vm.secondaryField = undefined;

        /**
         * Contains the tooltip for the favorite button.
         *
         * @type {Object}
         */
        vm.tooltip = {
            add: Translation.translate('FRONT.INSTANCE.ADD_TO_MY_SITES'),
            remove: Translation.translate('FRONT.INSTANCE.REMOVE_FROM_MY_SITES'),
        };

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Customer = Customer;
        vm.Features = Features;
        vm.Instance = Instance;
        vm.Metadata = Metadata;
        vm.Translation = Translation;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the fieeeeelds visibility for the instance block.
         */
        function _initFields() {
            var newFields = (angular.isUndefinedOrEmpty(vm.fields)) ?
                Instance.getProperty(Config.INSTANCE_PROPERTIES.INSTANCE_FIELDS) : vm.fields;

            vm.fieldsToDisplay = [];
            angular.forEach(newFields, function forEachFields(field) {
                if (angular.isUndefinedOrEmpty(field)) {
                    return;
                }

                var newField = field;
                if (!angular.isObject(field)) {
                    newField = {
                        enable: true,
                        name: field,
                    };
                }

                if (newField.enable) {
                    vm.fieldsToDisplay.push(newField);
                }

                if (newField.name === 'metadata' && newField.enable) {
                    _hasMetadataEnabled = true;
                }
            });
        }

        /**
         * Set the visible metadata for the instances.
         */
        function _initMetadata() {
            vm.metadata = [];

            Metadata.getRefactoredMetadata(false).then(function onMetadataListSuccess() {
                angular.forEach(vm.instance.metadata, function forEachMetadata(metadataKey) {
                    vm.metadata.push(Metadata.getMetadataFromKey(metadataKey, false, true));
                });

                // Check if there'is almost 1 metadata visible in front.
                vm.metadataIsVisibleInFront = angular.isDefinedAndFilled(_.find(vm.metadata, {
                    isVisibleFront: true,
                }));
            });
        }

        /**
         * Check if the instance is togglable.
         *
         * @return {boolean} If the user can toggle the instance from his favorite.
         */
        function _isInstanceTogglable() {
            var instanceId = _.get(vm, 'instance.uid');

            if (angular.isUndefinedOrEmpty(instanceId) || !User.isConnected()) {
                return false;
            }

            var instanceFavoriteFeeds = [];
            // Check if the instance is a feed favorite for one of user's feed.
            if (instanceId === Instance.getCurrentInstanceId()) {
                // If the instance is the current instance, we get its isInFavoriteFeedKeys value.
                instanceFavoriteFeeds = _.get(Instance.getInstance(), 'isInFavoriteFeedKeys', []);
            } else {
                // If the instance is a sibbling instance, we use getInstanceById to get isInFavoriteFeedKeys.
                Instance.getInstanceById(instanceId, function onGetInstanceSuccess(instance) {
                    instanceFavoriteFeeds = _.get(instance, 'isInFavoriteFeedKeys', []);
                });
            }
            var instanceIsUserFeedFavorite = User.hasSubscriptions(instanceFavoriteFeeds);

            return (instanceIsUserFeedFavorite && vm.instance.deletableFromFavorite) || !instanceIsUserFeedFavorite;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get instance classes.
         *
         * @return {Array} An array of instance block classes.
         */
        function getClasses() {
            var classes = [];

            if (vm.theme === 'dark') {
                classes.push('instance-block--theme-dark');
            } else {
                classes.push('instance-block--theme-light');
            }

            if (vm.viewMode === 'grid') {
                classes.push('instance-block--view-mode-grid');
            } else {
                classes.push('instance-block--view-mode-list');
            }

            if (vm.viewModeVariant === 'ungroup') {
                classes.push('instance-block--view-mode-variant-ungroup');
            } else {
                classes.push('instance-block--view-mode-variant-group');
            }

            if (angular.isDefinedAndFilled(_.get(vm.widgetProperties, 'style.content.shadowElevation')) &&
                vm.viewModeVariant === 'ungroup') {
                classes.push('instance-block--z-depth-' + vm.widgetProperties.style.content.shadowElevation);
            }

            if (vm.widgetProperties.itemsSeparator) {
                classes.push('instance-block--has-separator');
            } else {
                classes.push('instance-block--no-separator');
            }

            classes.push(_metadataClasses);

            return classes;
        }

        /**
         * Build the link the the instance.
         *
         * @return {string} The link of the instance or the Google site.
         */
        function getLink() {
            var gsiteUrl = Translation.translate(
                _.get(vm.instance, 'properties.gsite-url', '')
            ).toLowerCase();

            if (_.includes(gsiteUrl, 'sites.google.com')) {
                return gsiteUrl;
            }

            return $window.location.origin + Utils.buildInstanceUrl(vm.instance, Customer.getCustomerSlug());
        }

        /**
         * Get instance block style according to widget style properties in ungroup variant.
         *
         * @return {Object} The instance block style.
         */
        function getStyle() {
            if (angular.isUndefinedOrEmpty(vm.widgetProperties)) {
                return {};
            }

            var instanceBlockStyle = {};

            if (vm.viewMode === 'list') {
                var margin = vm.widgetProperties.itemsMargin;
                if (angular.isDefinedAndFilled(margin)) {
                    instanceBlockStyle.marginBottom = margin + 'px';

                    if (vm.viewModeVariant === 'group') {
                        instanceBlockStyle.paddingBottom = margin + 'px';
                    }
                }
            }

            if (vm.viewModeVariant === 'ungroup') {
                _.assign(instanceBlockStyle, _.pick(
                    vm.widgetProperties.style.content,
                    [
                        'padding',
                        'paddingTop',
                        'paddingBottom',
                        'paddingLeft',
                        'paddingRight',
                        'backgroundColor',
                    ]
                ));

                _.assign(instanceBlockStyle, _.pick(
                    vm.widgetProperties.style.content,
                    [
                        'borderRadius',
                        'borderTopLeftRadius',
                        'borderTopRightRadius',
                        'borderBottomLeftRadius',
                        'borderBottomRightRadius',
                    ]
                ));

                _.assign(instanceBlockStyle, _.pick(
                    vm.widgetProperties.style.content,
                    [
                        'borderWidth',
                        'borderTopWidth',
                        'borderRightWidth',
                        'borderBottomWidth',
                        'borderLeftWidth',

                    ]
                ));

                _.assign(instanceBlockStyle, _.pick(
                    vm.widgetProperties.style.content,
                    [
                        'borderColor',
                        'borderBottomColor',
                        'borderLeftColor',
                        'borderRightColor',
                        'borderTopColor',

                    ]
                ));

                if (vm.viewModeVariant === 'ungroup') {
                    _.assign(instanceBlockStyle, _.pick(
                        vm.widgetProperties.style.content,
                        [
                            'shadowElevation',
                            'shadowOpacity',
                        ]
                    ));
                }
            }

            return Style.adjustShadow(instanceBlockStyle);
        }

        /**
         * Add metadata class according to metadata fonctionnal inner id.
         * @param  {string} metadataFonctionnalInnerId The functionnal inner id of the metadata.
         * @return {string} The metadata class name.
         */
        function getMetadataFunctionalInnerId(metadataFonctionnalInnerId) {
            if (metadataFonctionnalInnerId !== '') {
                return 'instance-block-metadata__metadata--' + metadataFonctionnalInnerId;
            }

            return '';
        }

        /**
         * Redirect to the home page of the instance.
         */
        function redirectToHomepage() {
            $window.open(Utils.buildInstanceUrl(vm.instance, Customer.getCustomerSlug()), '_blank');
        }

        /////////////////////////////

        vm.getClasses = getClasses;
        vm.getLink = getLink;
        vm.getStyle = getStyle;
        vm.getMetadataFunctionalInnerId = getMetadataFunctionalInnerId;
        vm.redirectToHomepage = redirectToHomepage;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Listen to an update of the content-list theme.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} widgetId The identifier of the widget that triggered the theme update.
         * @param {string} theme    The new theme to use.
         */
        $scope.$on('widget-content-list-theme-update', function onWidgetContentListThemeUpdate(evt, widgetId,
            theme) {
            if (angular.isUndefinedOrEmpty(theme)) {
                return;
            }

            vm.theme = theme;
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            if (angular.isUndefinedOrEmpty(vm.instance)) {
                vm.instance = {};

                vm.secondaryField = '';
            }

            // Init fields;
            _initFields();

            // Init metadata if we need.
            if (_hasMetadataEnabled && angular.isDefinedAndFilled(vm.instance.metadata)) {
                _initMetadata();
            }

            vm.instance.isTogglable = _isInstanceTogglable();
            _metadataClasses = Metadata.getMetadataClasses(vm.instance.metadata, 'instance-block--metadata-');
        };

        vm.init();
    }

    /////////////////////////////

    /**
     * A directive that displays a directory entry within a list.
     *
     * @param {Object}  [fields]           The fields object of the block.
     * @param {boolean} [hideFavorite]     Indicates if the favorite button is hidden.
     * @param {Object}  instance           The instance to display.
     * @param {string}  [theme]            The theme to use.
     * @param {string}  [viewMode]         The widget view mode.
     * @param {string}  [viewModeVariant]  The widget view mode variant.
     * @param {Object}  [widgetProperties] The widget properties.
     */

    function InstanceBlockDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: InstanceBlockController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                fields: '<?',
                hideFavorite: '<?',
                instance: '<',
                theme: '@?',
                viewMode: '@?',
                viewModeVariant: '@?',
                widgetProperties: '=?',
            },
            templateUrl: '/client/front-office/modules/instance/views/instance-block.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('instanceBlock', InstanceBlockDirective);
})();
