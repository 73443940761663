import { Instance } from '@lumapps/instance/types';
import { Order } from '@lumapps/lumx-filter-and-sort/constants';
import { Orientation, Theme } from '@lumapps/lumx/react';
import { ListState } from '@lumapps/redux/genericListDucks';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { LegacyFieldsType, LegacyWidgetProperties } from '@lumapps/widget-base/types';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

export interface SiteBlockProps {
    theme?: Theme;
    /** custom sort order, like title, subtitle */
    orderedInfo: string[];
    /** The Site title */
    title: string | null;
    /** The Site name */
    name: string | null;
    /** The Site subtitle */
    subtitle: string | null;
    /** The site's slug. */
    slug: string;
    /** Define the orientation of the content */
    orientation: Orientation;
    /** Whether the current user has added the entry to his favorites. */
    isFavorite: boolean;
    isFavoriteDisabled: boolean;
    /** Whether the favorite icon is visible. */
    isFavoriteIconVisible: boolean;
    /** adds the item to favorites  */
    favorite(id: string): Promise<void>;
    /** removes the item from favorites  */
    unfavorite(id: string): Promise<void>;
}

export interface SiteListField {
    enable: boolean;
    name: string;
}

/** Rendering modes for the widget */
export enum SiteListType {
    pick = 'pick',
    favorite = 'favorites',
    all = 'all',
}

export interface LegacySiteListProperties {
    items: Instance[];
    fields?: LegacyFieldsType;
    style?: LegacyWidgetProperties['style'];
    /** The sites view mode. */
    viewMode?: 'grid' | 'list';
    viewModeVariant?: 'group' | 'ungroup';
    /** Custom rendering modes for the widget */
    type: SiteListType;
    /** Custom list of instances in rendering modes 'pick' */
    instancesIds: string[];
    /** Custom size for the margins */
    itemsMargin?: number;
    /** Custom number of items per line in mode 'grid' */
    itemsPerLine?: number;
    /** Separator visibility */
    itemsSeparator?: boolean;
    /** Is the SiteList loading? */
    listOrderDir?: Order;
    listOrder?: string;
    isFavoriteButtonHidden: false;
    maxNumber?: number;
    slideshowEnabled?: boolean;
    slideshowAutoplay?: boolean;
    slideshowInterval?: number;
}

export interface ClientComputedSiteListProps extends ClientComputedProps {
    properties?: LegacySiteListProperties;
}

/** Internal state: widget and site list */
export interface SiteListState {
    // eslint-disable-next-line no-use-before-define
    widget: SiteListWidgetState;
    list: ListState;
}

/** Internal state for the widget */
export interface SiteListWidgetState {
    favoritesStatus: Record<string, BaseLoadingStatus>;
    unFavoritesStatus: Record<string, BaseLoadingStatus>;
}
