(function IIFE() {
    'use strict';

    /////////////////////////////

    /* eslint-disable lumapps/comments-sentences */
    /*
     * UserContent is used to store some user specific values against a given widget within a content.
     *
     * This is used for example in standalone question widgets, where we will store the answer of a question for a given
     * user.
     *
     * A user can only have ONE userContent entry per widget on a specific content.
     *
     * This means that, when getting the content, there will be a content.userContent object property that will hold
     * values for each widget:
     *
     * content: {
     *     uid: 123456789,
     *     userContent: {
     *         values: {
     *             widgets: [
     *                 {
     *                     someProp: {},
     *                     uuid: widget-1-uuid,
     *                 },
     *                 {
     *                     someProp: {},
     *                     uuid: widget-2-uuid,
     *                 },
     *             ]
     *         }
     *     }
     * }
     */
    /* eslint-enable lumapps/comments-sentences */

    function UserContentService(BaseService, UserContentFactory) {
        'ngInject';

        var service = BaseService.createService(UserContentFactory);

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get all the userContent widgets for a given content for the current user.
         *
         * @param  {Object} content The content to get the userContent widgets from.
         * @return {Object} The userContent widgets of the current user for the given content.
         */
        function _getAllUserContentWidgets(content) {
            _.set(content, 'userContent.values.widgets', _.get(content, 'userContent.values.widgets', []));

            return content.userContent.values.widgets;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the current user widget within content.userContent (if any) or a new widget object.
         *
         * @param  {Object} content The content to search into / associate the widget with.
         * @param  {string} uuid    The identifier of the widget to look for in the userContent widgets.
         * @return {Object} The current user widget or a new widget object.
         */
        function getCurrentWidget(content, uuid) {
            if (angular.isUndefinedOrEmpty(content)) {
                return undefined;
            }

            var widgets = _getAllUserContentWidgets(content);
            var currentUserWidget = service.getUserContentWidget(content, uuid);

            if (angular.isDefinedAndFilled(currentUserWidget)) {
                return currentUserWidget;
            }

            currentUserWidget = {
                uuid: uuid,
            };

            widgets.push(currentUserWidget);

            return currentUserWidget;
        }

        /**
         * Looks for a specific widget in the userContent widgets.
         *
         * @param  {Object} content The content to search into.
         * @param  {string} uuid    The uuid of the widget to look for in the userContent widgets.
         * @return {Object} The userContent widget (if any).
         */
        function getUserContentWidget(content, uuid) {
            if (angular.isUndefinedOrEmpty(_.get(content, 'userContent.values.widgets'))) {
                return undefined;
            }

            return _.find(content.userContent.values.widgets, {
                uuid: uuid,
            });
        }

        /**
         * Update the value of a widget for the current user within a given content.
         *
         * @param {Object} content The content to search the widget into.
         * @param {string} uuid    The identifier of the widget to look for in the userContent widgets.
         * @param {Object} value   The new value of the widget.
         */
        function updateUserContentWidget(content, uuid, value) {
            var widget = service.getUserContentWidget(content, uuid);

            if (angular.isUndefinedOrEmpty(widget)) {
                return;
            }

            angular.extend(widget, value || {});
        }

        /////////////////////////////

        service.getCurrentWidget = getCurrentWidget;
        service.getUserContentWidget = getUserContentWidget;
        service.updateUserContentWidget = updateUserContentWidget;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('UserContent', UserContentService);
})();
