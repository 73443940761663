/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BaseStore, FrontOfficeStore } from '@lumapps/redux/types';
import { getSelectedFilterId } from '@lumapps/search/ducks/selectors';

import { isTagzEnabled } from '../../ducks/selectors';
import { Tagz } from './Tagz';

import './index.scss';

const mapStateToProps = (state: BaseStore) => {
    const isEnabled = isTagzEnabled(state);
    const filter = getSelectedFilterId(state as FrontOfficeStore);

    return {
        isEnabled,
        filter,
    };
};

const mapDispatchToProps = {};

const ConnectedTagz = connect(mapStateToProps, mapDispatchToProps)(Tagz);

export { ConnectedTagz as Tagz };
