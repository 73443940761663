import { useMemo, useState } from 'react';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import take from 'lodash/fp/take';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { ServerListResponse } from '@lumapps/base-api';
import { useInfiniteQuery, InfiniteData } from '@lumapps/base-api/react-query';
import { CalendarEvent, CalendarViews } from '@lumapps/lumx-calendar/types';

import { eventsQueryKeys, fetchCalendarsEventsMulti } from '../api';
import { QUERY_STALE_TIME } from '../constants';
import { LegacyCalendar, LegacyCalendarEvent, ListEventParams } from '../types';
import { concatEvents } from '../utils/concatEvents';

export const useQueryCalendarEvents = (
    fetchParams: ListEventParams[],
    selectedCalendars: LegacyCalendar[],
    view: CalendarViews,
    userCalendarColor: string,
    maxResults: number,
    hideConferenceLink: boolean = false,
) => {
    /**
     * Page count for the local load more feature.
     * Updated once all the calendars events have been fetched. Then the local load more is enabled.
     * Each page display a new batch of event based on the maxResults configuration.
     * */
    const [localPage, setLocalPage] = useState(0);

    const [unavailableCalendars, setUnavailableCalendars] = useState<string[]>([]);

    const results = useInfiniteQuery({
        queryKey: eventsQueryKeys.list(fetchParams),
        enabled: !isEmpty(fetchParams),
        queryFn: async ({ pageParam }) => {
            /**
             * Fetch batch of events for all available calendar's
             * When pageParam is defined, we're attempting to fetch a new page for the current calendar's.
             * */
            const filteredFetchParams = flow(
                /** Remove all calendar that are not available for the current user */
                /** If there is some pageParam, we're trying to fetch events from calendar's with cursors. Remove all */
                filter(
                    (params: ListEventParams) =>
                        (!unavailableCalendars.includes(params.calendarId) && !pageParam) ||
                        !!find(['callId', params.calendarId], pageParam),
                ),
                /** Add the cursor if needed */
                map((params) => ({
                    ...params,
                    cursor: find(['callId', params.calendarId], pageParam)?.cursor,
                })),
            )(fetchParams);

            /** Fetch batch of events for all available calendars */
            const events = await fetchCalendarsEventsMulti(filteredFetchParams);

            /**
             * Extract and group responses based on the status of the call.
             * Some API call might be rejected since the current user does not possess the appropriate access right or account provider to see the calendar.
             * */
            const { fulfilled = [], rejected = [] } = groupBy('status', events) as {
                fulfilled: PromiseFulfilledResult<ServerListResponse<LegacyCalendarEvent>>[];
                rejected: PromiseRejectedResult[];
            };

            /**
             * Filter out all the calendar for which the API call has been rejected.
             * Avoid re-triggering API calls for unavailable calendar's.
             * */
            if (!isEmpty(rejected)) {
                setUnavailableCalendars([
                    ...unavailableCalendars,
                    ...rejected.map(({ reason }) => reason.config.params.calendarId),
                ]);
            }

            return fulfilled.map((response) => ({
                ...response.value,
                /** Map legacy events to the new Calendar event interface */
                items: concatEvents(response.value.items, selectedCalendars, userCalendarColor, hideConferenceLink),
            }));
        },
        staleTime: QUERY_STALE_TIME,
        getNextPageParam: (lastPage) => {
            /** Return all the calendar's that need to load more event (in schedule view) */
            const next = view === CalendarViews.schedule && filter('cursor', lastPage);
            return isEmpty(next) ? undefined : next;
        },
        select: (data: InfiniteData<ServerListResponse<CalendarEvent>[]>) => {
            return {
                pages: data.pages,
                pageParams: data.pageParams,
            };
        },
    });

    const { visibleEvents, canShowMore } = useMemo(() => {
        const { data: { pages = [] } = {} } = results;

        /**
         * Extract all the events from the infinite query results
         * */
        const events = flow(
            /** 1. flatten the pages in order to extract all the ServerListResponses */
            flatten,
            /** 2. map to extract all the events from the ServerListResponse items */
            map('items'),
            /** 3. flatten again in order to have a CalendarEvent[] */
            flatten,
            /** 4. sortBy events by starting date */
            sortBy('start'),
        )(pages);

        /**
         * Get the list of currently visible events
         * Filter the event list based on the current view, the maxResults property and the number of pages.
         * */
        const visibleEvents =
            !isUndefined(maxResults) && view === CalendarViews.schedule
                ? take(maxResults * (pages.length + localPage), events)
                : events;

        return { visibleEvents, canShowMore: visibleEvents.length < events.length };
    }, [maxResults, localPage, results, view]);

    /**
     * Define if the load more option is available.
     * If there is a next page to load, we're loading the new events
     * If some events are loaded but not yet displayed we're showing a new batch of events.
     * The local load more is only available once all the events have been fetched.
     * */
    const loadMore = useMemo(() => {
        if (results.hasNextPage && results.fetchNextPage) {
            /** If there is a nextPage to fetch, we disable the local load more */
            setLocalPage(0);
            return results.fetchNextPage;
        }
        if (canShowMore) {
            /** Display a new batch of events from the already loaded list */
            return () => setLocalPage(localPage + 1);
        }
        /** If there is no more event to fetch or to show, return undefined */
        return undefined;
    }, [canShowMore, localPage, results.fetchNextPage, results.hasNextPage]);

    return useMemo(() => {
        return {
            isLoading: results.isLoading,
            isFetchingNextPage: results.isFetchingNextPage,
            data: visibleEvents,
            loadMore,
        };
    }, [loadMore, results, visibleEvents]);
};
