import loFind from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import set from 'lodash/set';
import { USER_SPACE_FEATURE_TOKEN } from '@lumapps/user-profile/constants';
import { shouldDisplayField } from '@lumapps/user-directory/utils/shouldDisplayField';
import { CHAT_PROVIDER_VALUES } from '@lumapps/chat/constants';

import { getChannelLinkToUser, listJoinedUserSpaces, listProviders } from '@lumapps/chat/api/legacyApi';

/////////////////////////////

// eslint-disable-next-line require-jsdoc
function UserDetailsController(
    $location,
    $log,
    $rootScope,
    $state,
    $stateParams,
    $scope,
    Content,
    Features,
    LxDialogService,
    LxNotificationService,
    Config,
    InitialSettings,
    Instance,
    Metadata,
    Translation,
    UploaderAction,
    Utils,
    User,
    UserDirectory,
    UserOrganizationChart,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The standard date format.
     *
     * @type {string}
     */
    const _DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSSSS';

    /**
     * Current user directory to use.
     *
     * @type {Object}
     */
    let _currentUserDirectory = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the current user can edit the displayed user.
     *
     * @type {boolean}
     */
    vm.canEdit = false;

    /**
     * The user being displayed in the user details dialog.
     *
     * @type {Object}
     */
    vm.displayedUser = undefined;

    /**
     * The user being modified in the user details dialog.
     *
     * @type {Object}
     */
    vm.displayedUserEdit = undefined;

    /**
     * Contains the organization organigram.
     *
     * @type {Object}
     */
    vm.orgChart = {};

    /**
     * Indicates if the organization organigram is displayed or not.
     *
     * @type {boolean}
     */
    vm.isOrgChartViewDisplayed = false;

    /**
     * Contains all the components that are displayed in the user details dialog.
     *
     * @type {Array}
     */
    vm.userDetailsComponents = [];

    /**
     * Flags that indicates if we sould display the activity feed button.
     *
     * @type {boolean}
     */
    vm.showActivityLink = false;

    /**
     * Urls of the Slack channel to IM the user
     *
     * @type {string}
     */
    vm.selectedUserSlackLinks = {};

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.Instance = Instance;
    vm.Features = Features;
    vm.Metadata = Metadata;
    vm.Translation = Translation;
    vm.User = User;
    vm.UserDirectory = UserDirectory;
    vm.UserOrganizationChart = UserOrganizationChart;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Format a string into a date format.
     *
     * @param  {string} stringToFormat The string to format in a date format.
     * @return {string} The formatted string.
     */
    function _getFormattedDate(stringToFormat) {
        return angular.isDefinedAndFilled(stringToFormat)
            ? moment(stringToFormat, _DATE_FORMAT).format(_DATE_FORMAT)
            : undefined;
    }

    /**
     * Check if the current user is allowed to edit his own profile.
     *
     * @return {boolean} If the current user is allowed to update his own profile or not.
     */
    function _isAllowedToUpdateProfile() {
        return get(_currentUserDirectory, 'properties.allowProfileUpdate', false);
    }

    /**
     * Build the org chart tree according to the updated org chart.
     */
    function _buildOrgChart() {
        const properties = get(_currentUserDirectory, 'properties', {});

        if (!properties.displayOrganizationChart) {
            return;
        }

        if (Features.hasFeature('org_chart')) {
            UserOrganizationChart.initOrgChart(vm.displayedUser);
        } else {
            const orgChartPromise = UserOrganizationChart.initLegacyOrgChart(vm.displayedUser);

            orgChartPromise.then(function onOrganizationChartGetSuccess(orgChart) {
                vm.orgChart = orgChart;
            });
        }
    }

    /**
     * Fill in the user custom profile with the fields values.
     *
     * @param  {Array}  fields The values of the fields to use to build the user custom profile.
     * @return {Object} The user with the modified custom profile.
     */
    function _buildUserCustomProfile(fields) {
        const modifiedUser = angular.fastCopy(vm.displayedUser);

        angular.forEach(fields, function forEachUserFields(field) {
            // Allows to save an empty select where lumx is putting undefined.
            const value = get(field, 'type') === 'inputSelect' && angular.isUndefined(field.value) ? '' : field.value;

            modifiedUser.customProfile[field.uuid] = value;
        });

        return modifiedUser;
    }

    /**
     * Check if the user can edit the user he is looking at.
     *
     * @return {boolean} If the current user can edit the user he is looking at.
     */
    function _canEditUser() {
        return (
            get(User.getConnected(), 'isSuperAdmin', false) ||
            (vm.isDisplayedUserCurrentUser() && _isAllowedToUpdateProfile())
        );
    }

    /**
     * Get the secondary field component.
     *
     * @param  {Array}  components The list of components in the user details dialog.
     * @return {Object} The secondary field component.
     */
    function _getSecondaryFieldComponent(components) {
        const secondaryField =
            Instance.getProperty(Config.INSTANCE_PROPERTIES.USER_BLOCK_DEFAULT_FIELD) || 'primaryEmail';

        if (!shouldDisplayField(secondaryField, Features.hasFeature('hide_email_addresses'))) {
            return undefined;
        }

        return loFind(components, function findSecondaryFieldComponent(component) {
            const properties = get(component, 'properties', {});

            return properties.isBound && get(properties, 'boundMap.text', '') === secondaryField;
        });
    }

    /**
     * Check if the component is readable.
     *
     * @param  {Object}  component The component to check if readable.
     * @return {boolean} If component is readable or not.
     */
    function _isComponentReadable(component) {
        return component.$canSee && ((!component.$canEdit && vm.editMode) || (component.$canSee && !vm.editMode));
    }

    /**
     * When the user details dialog closes, cleanup the controller.
     */
    function _onUserDetailsClose() {
        // Keep user for redirection.
        const displayedUserId = UserDirectory.displayedUserId;

        vm.displayedUser = undefined;
        vm.displayedUserEdit = undefined;
        UserDirectory.displayedUserId = undefined;
        UserDirectory.displayedUserDirectoryId = undefined;
        UserOrganizationChart.orgChart = [];
        vm.selectedUserSlackLinks = {};

        if (Features.hasFeature(USER_SPACE_FEATURE_TOKEN)) {
            UserDirectory.redirectToV2Profile(displayedUserId);
        }
    }

    /**
     * Validate each fields in the user details dialog.
     * Check if a field has a regexp validation and validate it upon.
     *
     * @return {boolean} If the fields are all valid.
     */
    function _patternFieldVerification() {
        let inputValidated = true;

        angular.forEach(get(vm.displayedUserEdit, 'userDetailsComponents', []), function forEachComponents(component) {
            component.$isInvalid = false;

            const regexp = get(component, 'properties.validationRegex');
            if (angular.isUndefinedOrEmpty([regexp, component.value], 'some') || !angular.isString(regexp)) {
                return;
            }

            try {
                // Capture regexp options, if not option use the set regexp.
                const match = regexp.match(new RegExp('^/(.*?)/([gimy]*)/?$'));
                const re = match ? new RegExp(match[1], match[2]) : new RegExp(regexp);

                if (re.test(component.value)) {
                    component.$isInvalid = false;
                } else {
                    throw new Error('value or pattern invalid');
                }
            } catch (exception) {
                // In this case pattern is either wrong or component.value does not match pattern.
                // Do not break the loop to verify other component with regex validation.
                inputValidated = false;
                component.$isInvalid = true;
            }
        });

        return inputValidated;
    }

    /**
     * Check if the current user can edit the given custom field of the user being displayed in the user details
     * dialog.
     *
     * @param  {Object}  properties The properties of the custom field component to check.
     * @return {boolean} If the current user can edit the custom field of the displayed user.
     */
    function _userCanEditCustomField(properties) {
        const user = User.getConnected();

        if (angular.isUndefinedOrEmpty(user)) {
            return false;
        }

        if (
            user.isSuperAdmin ||
            (vm.isDisplayedUserCurrentUser() && _isAllowedToUpdateProfile() && get(properties, 'selfEdit'))
        ) {
            return true;
        }

        const editFeeds = get(properties, 'editFeeds');

        // Else check if current user is allowed to edit.
        return angular.isDefinedAndFilled(editFeeds) && User.hasSubscriptions(editFeeds);
    }

    /**
     * Check if the current user can see the given custom field of the user being displayed in the user details
     * dialog.
     *
     * @param  {Object}  properties The properties of the custom field component to check.
     * @return {boolean} If the current user can see the custom field of the displayed user.
     */
    function _userCanSeeCustomField(properties) {
        const boundMap = get(properties, 'boundMap', {});
        if (angular.isDefinedAndFilled(boundMap) && includes(Config.USER_DIRECTORY_HIDDEN_FIELD, boundMap.text)) {
            return false;
        }

        if (get(User.getConnected(), 'isSuperAdmin', false)) {
            return true;
        }

        if (angular.isUndefinedOrEmpty(properties)) {
            return false;
        }

        // Else check if user is allowed to see (if not in seeFeed but in editFeed user is allow to see).
        properties.editFeeds = properties.editFeeds || [];
        properties.seeFeeds = properties.seeFeeds || [];

        return User.hasSubscriptions(properties.seeFeeds.concat(properties.editFeeds));
    }

    /**
     * Filter the components to be displayed in the user details dialog and add value for these component from the
     * user profile.
     */
    function _filterAndBuild() {
        if (angular.isUndefinedOrEmpty(vm.displayedUser)) {
            return;
        }

        if (angular.isUndefinedOrEmpty(vm.displayedUser.apiProfile)) {
            vm.displayedUser.apiProfile = {};
        }

        if (angular.isUndefinedOrEmpty(vm.displayedUser.customProfile)) {
            vm.displayedUser.customProfile = {};
        }

        if (angular.isUndefinedOrEmpty(get(_currentUserDirectory, 'template.components'))) {
            return;
        }

        vm.userDetailsComponents = _currentUserDirectory.template.components.filter(function filterComponents(
            component,
        ) {
            if (angular.isUndefined(component)) {
                return false;
            }

            if (component.widgetType === InitialSettings.WIDGET_TYPES.TIP) {
                return false;
            }

            component.$ignored = false;
            component.$isInvalid = false;

            const properties = get(component, 'properties', {});

            if (angular.isDefinedAndFilled(properties) && properties.isBound) {
                const boundText = get(properties, 'boundMap.text', component.uuid);
                // Do not display email if the FF hide_email_addresses is enabled
                if (!shouldDisplayField(boundText, Features.hasFeature('hide_email_addresses'))) {
                    return false;
                }
                component.value = Utils.getApiProfileFieldFromMap(boundText, vm.displayedUser);

                component.$ignored =
                    angular.isDefinedAndFilled(boundText) &&
                    includes(get(Config, 'USER_DIRECTORY_IGNORED_FIELD', []), boundText);
            } else {
                component.value = get(vm.displayedUser.customProfile, component.uuid);
            }

            // Handle metadata multiple change.
            if (component.type === 'metadata') {
                const isMultiple = Metadata.isMultipleMetadata(properties.metadataId);

                if (component.value === null) {
                    component.value = undefined;
                }

                // If multiple value but metadata is not multiple anymore.
                if (angular.isArray(component.value) && !isMultiple) {
                    component.value = first(component.value);
                    // Or if unique value but metadata is now multiple.
                } else if (angular.isString(component.value) && isMultiple) {
                    component.value = [component.value];
                }
            }

            component.$canEdit = _userCanEditCustomField(component.properties);
            component.$canSee = _userCanSeeCustomField(component.properties);

            if (component.$canEdit) {
                vm.canEdit = true;
            }

            return true;
        });

        vm.secondaryField = _getSecondaryFieldComponent(vm.userDetailsComponents);
    }

    /**
     * Get the user link to its own channel.
     */
    function getUserChatLink(provider, userSpace, userId) {
        getChannelLinkToUser({ provider, spaceId: userSpace.id, userId })
            .then((response) => {
                $scope.$apply(() => {
                    vm.selectedUserSlackLinks[userSpace.name] = get(response, 'data.link', undefined);
                });
            })
            .catch((exception) => {
                $log.debug(exception);
            });
    }

    /**
     * Get the user spaces and get the link.
     */
    function getUserChatUserSpace(provider, userId) {
        listJoinedUserSpaces({ provider })
            .then((response) => {
                const userSpaces = get(response, 'data.items', []);
                userSpaces.map((userSpace) => {
                    getUserChatLink(provider, userSpace, userId);
                    return userSpace;
                });
            })
            .catch((exception) => {
                $log.debug(exception);
            });
    }

    /**
     * Get the user chat and check which chat can be printed.
     */
    function getUserChat(userId) {
        const user = User.getConnected();

        vm.selectedUserSlackLinks = {};

        if (!user || get(user, 'id') === userId) {
            return;
        }

        listProviders({ userId })
            .then((response) => {
                const providers = get(response, 'data.providers', []);
                providers.map((provider) => {
                    if (provider.name === CHAT_PROVIDER_VALUES.SLACK.id) {
                        getUserChatUserSpace(provider.name, userId);
                    }

                    return provider;
                });
            })
            .catch((exception) => {
                $log.debug(exception);
            });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Open the file selection dialog for the user profile picture.
     */
    function editProfilePicture() {
        UploaderAction.openSelection('profile-picture');
    }

    /**
     * Check if a component should be displayed in the user details dialog.
     *
     * @param  {Object}  component The component to check.
     * @return {boolean} If the component should be displayed in the user details dialog.
     */
    function isComponentDisplayed(component) {
        if (
            angular.isUndefinedOrEmpty(component) ||
            (component.type === 'metadata' && Metadata.isMetadataDeleted(component.properties.metadataId))
        ) {
            return false;
        }

        if (!vm.editMode) {
            return (
                (angular.isDefinedAndFilled(component.value) || (_canEditUser() && component.$canEdit)) &&
                _isComponentReadable(component)
            );
        }

        return component.$canEdit;
    }

    /**
     * Check if the given user is the user currently being displayed in the user details dialog.
     *
     * @param  {Object}  user The user to check.
     * @return {boolean} If the given user is the currently displayed user.
     */
    function isCurrentUser(user) {
        if (angular.isUndefinedOrEmpty([vm.displayedUser, user], 'some')) {
            return false;
        }

        return get(vm.displayedUser, 'id') === get(user, 'id');
    }

    /**
     * Check if the organization chart is displayable for current user-directory.
     *
     * @return {boolean} Whether or not the chart is displayable.
     */
    function isDisplayableOrganizationChart() {
        return (
            get(_currentUserDirectory, 'properties.displayOrganizationChart', false) &&
            !Features.hasFeature(USER_SPACE_FEATURE_TOKEN)
        );
    }

    /**
     * Check if the connected user is the user currently being displayed in the user details dialog.
     *
     * @return {boolean} Whether or the connected user is the displayed user.
     */
    function isDisplayedUserCurrentUser() {
        return vm.isCurrentUser(User.getConnected());
    }

    /**
     * Open the user details dialog for the given user.
     *
     * @param {string|Object} userId            The id (or email) of the user to display in the user details dialog.
     * @param {boolean}       skipOrgChartBuild Whether to skip the or chart building or not.
     */
    function displayUser(userId, skipOrgChartBuild) {
        const contentId = UserDirectory.getDefaultUserDirectory(User.getConnected()).id;
        if (typeof userId === 'string') {
            userId = { uid: userId };
        }
        UserDirectory.get({ ...userId, contentId }, function onUserGetSuccess(response) {
            vm.displayedUser = response;

            getUserChat(response.id);

            let id = undefined;

            if (angular.isDefinedAndFilled(response, 'apiProfile.id')) {
                // Check if the apiProfile.id is filled
                if (response.apiProfile.id) {
                    id = response.apiProfile.id;
                }
                // Fallback on the profileId
                else {
                    if (angular.isDefinedAndFilled(response, 'profileId')) {
                        id = response.profileId;
                    }
                }
            }

            if (id !== undefined) {
                vm.displayedUserHangout = [
                    {
                        id: id,
                        // eslint-disable-next-line camelcase
                        invite_type: 'PROFILE',
                    },
                ];
            } else {
                vm.displayedUserHangout = [
                    {
                        id: response.email,
                        // eslint-disable-next-line camelcase
                        invite_type: 'EMAIL',
                    },
                ];
            }

            vm.editMode = false;

            vm.canEdit = _canEditUser();
            _filterAndBuild();

            if (angular.isUndefinedOrEmpty(skipOrgChartBuild) || !skipOrgChartBuild) {
                _buildOrgChart();
            } else {
                UserDirectory.displayedUserId = userId;
            }
            vm.showActivityLink = shouldActivityLinkDisplayed(get(response, 'settings.social.isActivityVisible'));

            // display EditMode if editDetails is in the params.
            const { editDetails } = $location.search();
            if (editDetails && vm.canEdit) {
                vm.toggleEditMode();
            }
        });
    }

    /**
     * Check if the Activity link button should be display in the modal.
     */
    function shouldActivityLinkDisplayed(hasActivityVibility) {
        if (!Features.hasFeature('social')) {
            return false;
        }
        //the button must be hidden if we are on the profile page of the user we want to see details.
        let isNotOnTargetProfile =
            ($state.current.name === 'app.front.profile' && $stateParams.userIdentifier !== vm.displayedUser.id) ||
            $state.current.name !== 'app.front.profile';

        //Check if we are already on the current conected user profile page
        if (
            $state.current.name === 'app.front.profile' &&
            $stateParams.userIdentifier === 'me' &&
            isDisplayedUserCurrentUser()
        ) {
            isNotOnTargetProfile = false;
        }

        return (hasActivityVibility || isDisplayedUserCurrentUser()) && isNotOnTargetProfile;
    }

    /**
     * Save the currently displayed user profile.
     */
    function saveProfile() {
        if (angular.isUndefinedOrEmpty(vm.displayedUserEdit)) {
            return;
        }

        const editUser = _buildUserCustomProfile(vm.displayedUserEdit.userDetailsComponents);
        const inputValidated = _patternFieldVerification();

        if (!inputValidated) {
            LxNotificationService.error(Translation.translate('USER_DIRECTORY_EDIT_PATTERN_ERROR'));

            return;
        }

        angular.extend(editUser, {
            contentId: _currentUserDirectory.id,
            createdAt: _getFormattedDate(editUser.createdAt),
            firstName: vm.displayedUserEdit.firstName,
            lastName: vm.displayedUserEdit.lastName,
            lastSynchronization: _getFormattedDate(editUser.lastSynchronization),
            updatedAt: _getFormattedDate(editUser.updatedAt),
        });

        if (!includes(vm.displayedUserEdit.profilePictureUrl, 'data:image')) {
            editUser.profilePicture = vm.displayedUserEdit.profilePictureUrl;
        }

        UserDirectory.save(
            editUser,
            function onUserSaveSuccess(response) {
                LxNotificationService.success(Translation.translate('USER_DIRECTORY_EDIT_SUCCESS'));

                vm.displayUser(response.id);

                $rootScope.$broadcast('user-directory__user-save-success', response);

                const user = User.getConnected();

                const isConnectedUser = user.id === editUser.id;

                // if new user space is activated, redirect to new profile.
                if (Features.hasFeature(USER_SPACE_FEATURE_TOKEN)) {
                    UserDirectory.redirectToV2Profile(editUser.id);
                    // If connected user edit its own profile, update values.
                } else if (isConnectedUser) {
                    user.firstName = editUser.firstName;
                    user.lastName = editUser.lastName;
                    User.setProfilePicture(editUser.profilePicture);
                }
            },
            Utils.displayServerError,
        );
    }

    /**
     * Toggle edit mode.
     */
    function toggleEditMode() {
        if (angular.isUndefinedOrEmpty(vm.displayedUser)) {
            return;
        }

        vm.editMode = !vm.editMode;

        if (!vm.editMode) {
            return;
        }

        vm.displayedUserEdit = {
            firstName: angular.fastCopy(vm.displayedUser.firstName),
            lastName: angular.fastCopy(vm.displayedUser.lastName),
            profilePictureUrl: angular.fastCopy(User.getProfilePicture(vm.displayedUser)),
            secondaryField: angular.fastCopy(vm.secondaryField),
            userDetailsComponents: angular.fastCopy(vm.userDetailsComponents),
        };
    }

    /**
     * Toggle Org Chart view.
     */
    function toggleOrgChartView() {
        vm.isOrgChartViewDisplayed = !vm.isOrgChartViewDisplayed;
    }

    /**
     * When a file as been selected as user profile picture, update the profile.
     *
     * @param {Object} file The file selected as user profile picture.
     */
    function uploadProfilePicture(file) {
        if (angular.isUndefinedOrEmpty(get(file, 'blobKey'))) {
            return;
        }
        set(vm.displayedUserEdit, 'profilePictureUrl', file.blobKey);
    }

    /**
     * Try to navigato the User Social Profile
     */
    function navigateToSocialProfile(user) {
        User.redirectToSocialProfile(user);
    }

    function selectedUserHasOneSlack() {
        return Object.keys(vm.selectedUserSlackLinks).length === 1;
    }

    function selectedUserHasMultipleSlack() {
        return Object.keys(vm.selectedUserSlackLinks).length > 1;
    }

    function getSelectedUserSlackLinkAt(pos) {
        if (pos < 0 || pos >= Object.values(vm.selectedUserSlackLinks).length) {
            return undefined;
        }

        return Object.values(vm.selectedUserSlackLinks)[pos];
    }

    function getHangoutUserId() {
        return vm.displayedUserHangout?.[0]?.id;
    }

    /////////////////////////////

    vm.isComponentDisplayed = isComponentDisplayed;
    vm.displayUser = displayUser;
    vm.editProfilePicture = editProfilePicture;
    vm.isCurrentUser = isCurrentUser;
    vm.isDisplayableOrganizationChart = isDisplayableOrganizationChart;
    vm.isDisplayedUserCurrentUser = isDisplayedUserCurrentUser;
    vm.saveProfile = saveProfile;
    vm.toggleEditMode = toggleEditMode;
    vm.toggleOrgChartView = toggleOrgChartView;
    vm.uploadProfilePicture = uploadProfilePicture;
    vm.navigateToSocialProfile = navigateToSocialProfile;
    vm.selectedUserHasOneSlack = selectedUserHasOneSlack;
    vm.selectedUserHasMultipleSlack = selectedUserHasMultipleSlack;
    vm.getSelectedUserSlackLinkAt = getSelectedUserSlackLinkAt;
    vm.getHangoutUserId = getHangoutUserId;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the user details dialog opens, init controller.
     *
     * @param {Event}  evt      The dialog event event.
     * @param {string} dialogId The id of the dialog that opens.
     */
    $scope.$on('lx-dialog__open-start', function onDialogCloseEnd(evt, dialogId) {
        if (UserDirectory.USER_DETAILS_DIALOG_ID === dialogId) {
            vm.init();
        }
    });

    /**
     * When the user details dialog closes, cleanup the controller.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('lx-dialog__close-end', function onDialogCloseEnd(evt, dialogId) {
        if (UserDirectory.USER_DETAILS_DIALOG_ID === dialogId) {
            _onUserDetailsClose();
        }
    });

    /**
     * When asked to redirect to the user profile, display the user who's e-mail address is in the URL query param
     * "email".
     */
    $scope.$on('redirect-to-user-profile', function onRedirectToUserProfile() {
        const { email } = $location.search();

        if (angular.isDefinedAndFilled(email)) {
            vm.displayUser(email, true);
        }
    });

    /**
     * Update user profile with the given user without rebuilding the org chart.
     *
     * @param {Event}  evt    The emit event.
     * @param {Object} userId The identifier of the user to display.
     */
    $scope.$on('display-user-org-chart', function onUserDisplayFromOrgChart(evt, userId) {
        vm.displayUser(userId, true);
    });

    /**
     * When the user directory is left (and the controller destroyed), ensure that user details dialog is closed.
     */
    $scope.$on('$destroy', function onDestroy() {
        LxDialogService.close(UserDirectory.USER_DETAILS_DIALOG_ID);
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        Instance.getSiblings(false).then(function onSiblingsListSuccess() {
            _currentUserDirectory = UserDirectory.getDefaultUserDirectory(User.getConnected());

            UserDirectory.displayedUserDirectoryId = _currentUserDirectory.id;

            if (angular.isUndefinedOrEmpty(get(_currentUserDirectory, 'template.components'))) {
                _onUserDetailsClose();
                LxNotificationService.error(Translation.translate('ERROR_NOT_AUTHORIZED'));

                return;
            }

            vm.userId = UserDirectory.displayedUserId;

            if (angular.isUndefinedOrEmpty(vm.userId)) {
                _onUserDetailsClose();

                return;
            }

            vm.displayUser(vm.userId);
        });
    };
}

/**
 * User details dialog working on global context.
 *
 * @param {string} [userId] Id of user to show in details dialog.
 */

function UserDetailsDirective(Features) {
    'ngInject';

    return {
        bindToController: true,
        controller: UserDetailsController,
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            userId: '<?',
        },
        templateUrl: function getTemplateUrl() {
            /* eslint-disable max-len */
            if (Features.hasFeature('org_chart')) {
                return '/client/front-office/modules/module/modules/user-directory/views/partials/user-directory-details-v2.html';
            }

            return '/client/front-office/modules/module/modules/user-directory/views/partials/user-directory-details.html';
        },
    };
}

/////////////////////////////

angular.module('Directives').directive('userDetails', UserDetailsDirective);

/////////////////////////////

export { UserDetailsDirective };
