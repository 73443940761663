(function IIFE() {
    'use strict';

    /////////////////////////////

    function SearchUserFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/user/:methodKey', {}, {
            searchUsers: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'search',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('SearchUserFactory', SearchUserFactory);
})();
