import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';

interface CustomizationDropdownSectionProps {
    header: string;
    children: React.ReactNode;
}

/**
 * Wrapper for the Menu.Section in order to allow the customizations API to use
 * menus as easily as possible.
 *
 * @param CustomizationDropdownSectionProps
 */
export const CustomizationDropdownSection: React.FC<CustomizationDropdownSectionProps> = ({ header, children }) => {
    return <Menu.Section label={header}>{children}</Menu.Section>;
};

CustomizationDropdownSection.displayName = 'DropdownSection';
