import React, { PureComponent, Fragment } from 'react';
import { object } from 'prop-types';

import concat from 'lodash/concat';

import { channelType } from './types';
import { AccordionBox } from '../../ui/accordions/AccordionBox';
import { WidgetChannelListAccordionHeader } from './WidgetChannelListAccordionHeader';
import { getListConversations } from '../../../services/channel';
import { ConversationBlock } from './ConversationBlock';
import { translate as t } from '../../../translations';
import { Loader } from '../../ui/loader/Loader';

/**
 * Displays the widget channel list.
 */
export class ChannelBlock extends PureComponent {
    static propTypes = {
        /** The current channel to display. */
        channel: channelType.isRequired,
        /** The widget properties. */
        properties: object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            hasMore: false,
            isConversationsLoading: false,
            listConversations: [],
            page: 1,
            showMore: false,
        };

        this.onOpenChannel = this.onOpenChannel.bind(this);
        this.showMoreConversations = this.showMoreConversations.bind(this);
    }

    /**
     * Retrieve the list of conversations of a channel.
     * Trigger when a channel is open.
     */
    async onOpenChannel() {
        const { properties = {}, channel } = this.props;
        const { listConversations } = this.state;

        if (listConversations.length > 0) {
            return;
        }

        this.setState({ isConversationsLoading: true });

        const response = await getListConversations(properties.provider, channel.id, properties.numberConversations);

        this.setState({ hasMore: response.more, isConversationsLoading: false, listConversations: response.items });
    }

    /**
     * Increase the page number and load next conversations.
     */
    async showMoreConversations() {
        const { properties = {}, channel } = this.props;
        let { page, listConversations } = this.state;

        page += 1;

        this.setState({ isConversationsLoading: true });

        const response = await getListConversations(
            properties.provider,
            channel.id,
            properties.numberConversations,
            page,
        );

        listConversations = concat(listConversations, response.items);

        this.setState({
            hasMore: response.more,
            isConversationsLoading: false,
            listConversations,
            page,
        });
    }

    render() {
        const { channel } = this.props;
        const { listConversations, isConversationsLoading, hasMore } = this.state;

        return (
            <div key={channel.id} className="channel-block">
                <AccordionBox
                    border={false}
                    customHeader={<WidgetChannelListAccordionHeader channel={channel} />}
                    customParams={channel}
                    customToggleAction={this.onOpenChannel}
                    label={channel.name}
                >
                    {isConversationsLoading ? (
                        <Loader isLoading={isConversationsLoading} />
                    ) : (
                        <Fragment>
                            {listConversations.map((conversation) => (
                                <ConversationBlock key={conversation.id} conversation={conversation} />
                            ))}

                            {hasMore && (
                                <div
                                    className="channel-block__show-more show-more-channel"
                                    onClick={this.showMoreConversations}
                                >
                                    <div className="show-more-channel__button">{t('GLOBAL.MORE')}</div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </AccordionBox>
            </div>
        );
    }
}
