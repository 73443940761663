/* eslint-disable import/no-mutable-exports */

import noop from 'lodash/noop';

// eslint-disable-next-line no-shadow
let localStorage = noop;
let reset = noop;

/**
 * Binds functions to angular `LocalStorage` service.
 *
 * @param {LocalStorage} LocalStorage The angular LocalStorage service.
 */
function setLocalStorage(LocalStorage) {
    localStorage = LocalStorage;
    reset = LocalStorage.reset;
}

/////////////////////////////

export { localStorage, reset, setLocalStorage };
