/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
    FETCH_ALL_SUCCESS,
    FETCH_BY_ID_SUCCESS,
    RESET_CURSOR,
    GET_SHAREABLE_CONTENT_WITH_STATS_SUCCESS,
    ARCHIVED_CONTENT_SUCCESS,
    TOGGLE_SHAREABLE_CONTENT_SORT_DIRECTION,
    OPEN_SHAREABLE_CONTENT_GALLERY_DIALOG,
    CLOSE_SHAREABLE_CONTENT_GALLERY_DIALOG,
} from './actions';

const defaultState = {
    cursor: null,
    contentList: [],
    current: null,
    entities: {},
    galleryDialog: {
        title: '',
        images: [],
        isOpen: false,
    },
    hasMore: true,
    /* How cards are sorted in the dashboard (1 : asc, -1 : desc). */
    shareableContentSortDir: -1,
};

export const reducer = produce((draft, action) => {
    switch (action.type) {
        case FETCH_ALL_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const newList = action.isNextPage ? [...draft.contentList] : [];
            action.shareableContents.forEach((content) => {
                draft.entities[content.shareableContent.id] = content;
                if (!newList.includes(content.shareableContent.id)) {
                    newList.push(content.shareableContent.id);
                }
            });
            draft.contentList = newList;
            // Only update the cursor when new data is received.
            if (action.shareableContents.length > 0) {
                draft.cursor = action.cursor;
            }
            draft.hasMore = action.hasMore;

            break;

        case FETCH_BY_ID_SUCCESS:
            draft.current = action.shareableContent.id;

            if (draft.entities[action.shareableContent.id]) {
                draft.entities[action.shareableContent.id] = {
                    firstSharers: draft.entities[action.shareableContent.id].firstSharers,
                    shareableContent: action.shareableContent,
                    sharedByCurrentUser: draft.entities[action.shareableContent.id].sharedByCurrentUser,
                    stats: draft.entities[action.shareableContent.id].stats,
                };
            } else {
                draft.entities[action.shareableContent.id] = {
                    shareableContent: action.shareableContent,
                };
            }

            if (!draft.contentList.includes(action.shareableContent.id)) {
                draft.contentList.unshift(action.shareableContent.id);
            }

            break;

        case RESET_CURSOR:
            draft.cursor = null;

            break;

        case GET_SHAREABLE_CONTENT_WITH_STATS_SUCCESS:
            draft.entities[action.shareableContent.shareableContent.id] = action.shareableContent;

            break;

        case ARCHIVED_CONTENT_SUCCESS:
            delete draft.entities[action.contentId];
            draft.contentList = draft.contentList.filter((id) => id !== action.contentId);

            break;

        case TOGGLE_SHAREABLE_CONTENT_SORT_DIRECTION:
            draft.cursor = null;
            draft.contentList = [];
            draft.shareableContentSortDir = action.requestedSortDir;

            break;

        case OPEN_SHAREABLE_CONTENT_GALLERY_DIALOG:
            draft.galleryDialog.isOpen = true;
            if (action.images) {
                draft.galleryDialog.title = action.title;
                draft.galleryDialog.images = action.images;
                draft.galleryDialog.initialIndex = action.initialIndex;
            }

            break;

        case CLOSE_SHAREABLE_CONTENT_GALLERY_DIALOG:
            draft.galleryDialog.isOpen = false;

            break;

        default:
            return draft;
    }

    return draft;
}, defaultState);
