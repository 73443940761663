export enum DOMAINS_MANAGEMENT {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=436977062
     */
    ACTIVATE_DEACTIVATE_DOMAIN_LOGIN_MIGRATION_MESSAGE = 'DOMAINS_MANAGEMENT.ACTIVATE_DEACTIVATE_DOMAIN_LOGIN_MIGRATION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=436977104
     */
    ACTIVATE_DEACTIVATE_DOMAIN_LOGIN_MIGRATION_TITLE = 'DOMAINS_MANAGEMENT.ACTIVATE_DEACTIVATE_DOMAIN_LOGIN_MIGRATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449812211
     */
    ACTIVATE_REDIRECTION = 'DOMAINS_MANAGEMENT.ACTIVATE_REDIRECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422217403
     */
    BUTTONS_NEW_LOGIN_EXPERIENCE = 'DOMAINS_MANAGEMENT.BUTTONS_NEW_LOGIN_EXPERIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=325511780
     */
    CERTIFICATE_DEPLOYED = 'DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=325511785
     */
    CERTIFICATE_DEPLOYING = 'DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=325037929
     */
    CERTIFICATE_DEPLOYMENT_TITLE = 'DOMAINS_MANAGEMENT.CERTIFICATE_DEPLOYMENT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327361274
     */
    CERTIFICATE_GENERATION_TITLE = 'DOMAINS_MANAGEMENT.CERTIFICATE_GENERATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=325511776
     */
    CUSTOM_CERTIFICATE_DEPLOYING = 'DOMAINS_MANAGEMENT.CUSTOM_CERTIFICATE_DEPLOYING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327439015
     */
    DOMAIN_CONFIGURATION_TITLE = 'DOMAINS_MANAGEMENT.DOMAIN_CONFIGURATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327439336
     */
    DOMAIN_READY_MIGRATION = 'DOMAINS_MANAGEMENT.DOMAIN_READY_MIGRATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327439371
     */
    DOMAIN_SUCCESSFULLY_MIGRATED = 'DOMAINS_MANAGEMENT.DOMAIN_SUCCESSFULLY_MIGRATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423759390
     */
    ERROR_ACTIVATION_DOMAIN_TOOLTIP = 'DOMAINS_MANAGEMENT.ERROR_ACTIVATION_DOMAIN_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423496964
     */
    ERROR_ACTIVATION_MIGRATION = 'DOMAINS_MANAGEMENT.ERROR_ACTIVATION_MIGRATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421938004
     */
    LOGIN_EXPERIENCE_SUBTITLE_STEP_ONE = 'DOMAINS_MANAGEMENT.LOGIN_EXPERIENCE_SUBTITLE_STEP_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=437320889
     */
    LOGIN_EXPERIENCE_SUBTITLE_STEP_ONE_NO_VANITY = 'DOMAINS_MANAGEMENT.LOGIN_EXPERIENCE_SUBTITLE_STEP_ONE_NO_VANITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421921216
     */
    LOGIN_MIGRATION_STEPS = 'DOMAINS_MANAGEMENT.LOGIN_MIGRATION_STEPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423529559
     */
    LOG_IN_STEP_SUBTITLE = 'DOMAINS_MANAGEMENT.LOG_IN_STEP_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=437321489
     */
    LOG_IN_STEP_SUBTITLE_NO_VANITY = 'DOMAINS_MANAGEMENT.LOG_IN_STEP_SUBTITLE_NO_VANITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=423529429
     */
    LOG_IN_STEP_TITLE = 'DOMAINS_MANAGEMENT.LOG_IN_STEP_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=420751991
     */
    NEW_LOGIN_EXPERIENCE = 'DOMAINS_MANAGEMENT.NEW_LOGIN_EXPERIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326481333
     */
    NO_VANITY_URL = 'DOMAINS_MANAGEMENT.NO_VANITY_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327439033
     */
    PENDING_FIRST_STEP = 'DOMAINS_MANAGEMENT.PENDING_FIRST_STEP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444611739
     */
    PLATFORM_DEPLOYMENT_ERROR_MESSAGE_BLOCKED = 'DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_BLOCKED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444611812
     */
    PLATFORM_DEPLOYMENT_ERROR_MESSAGE_NO_MS = 'DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_NO_MS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444611786
     */
    PLATFORM_DEPLOYMENT_ERROR_MESSAGE_SAML_V1 = 'DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_SAML_V1',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=444611822
     */
    PLATFORM_DEPLOYMENT_ERROR_MESSAGE_SAML_JIT = 'DOMAINS_MANAGEMENT.PLATFORM_DEPLOYMENT_ERROR_MESSAGE_SAML_JIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327273940
     */
    RECORD_INFO = 'DOMAINS_MANAGEMENT.RECORD_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327119270
     */
    RECORD_NAME = 'DOMAINS_MANAGEMENT.RECORD_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327119249
     */
    RECORD_TYPE = 'DOMAINS_MANAGEMENT.RECORD_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327185782
     */
    RECORD_VALIDITY_DATE = 'DOMAINS_MANAGEMENT.RECORD_VALIDITY_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327193286
     */
    RECORD_VALIDITY_DATE_INFO = 'DOMAINS_MANAGEMENT.RECORD_VALIDITY_DATE_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327119297
     */
    RECORD_VALUE = 'DOMAINS_MANAGEMENT.RECORD_VALUE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487658014
     */
    REDIRECT_ACTIVATION_STEP_SUBTITLE = 'DOMAINS_MANAGEMENT.REDIRECT_ACTIVATION_STEP_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=487657966
     */
    REDIRECT_ACTIVATION_STEP_TITLE = 'DOMAINS_MANAGEMENT.REDIRECT_ACTIVATION_STEP_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488223859
     */
    REDIRECTION_ACTIVATION_CANCEL = 'DOMAINS_MANAGEMENT.REDIRECTION_ACTIVATION_CANCEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=488222458
     */
    REDIRECTION_ACTIVATION_WARNING = 'DOMAINS_MANAGEMENT.REDIRECTION_ACTIVATION_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=359722820
     */
    ROOT_APEX_DOMAIN = 'DOMAINS_MANAGEMENT.ROOT_APEX_DOMAIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422688932
     */
    STEP_THREE_ACTIVATE_LOGIN = 'DOMAINS_MANAGEMENT.STEP_THREE_ACTIVATE_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=320908098
     */
    SUBTITLE = 'DOMAINS_MANAGEMENT.SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=319944527
     */
    SUPPORT_MAIL = 'DOMAINS_MANAGEMENT.SUPPORT_MAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326657891
     */
    SUPPORT_QUESTIONS = 'DOMAINS_MANAGEMENT.SUPPORT_QUESTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449296013
     * TODO: Split into two components
     */
    SUPPORT_QUESTIONS_V2 = 'DOMAINS_MANAGEMENT.SUPPORT_QUESTIONS_V2',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422160563
     */
    TEST_LOGIN_EXPERIENCE = 'DOMAINS_MANAGEMENT.TEST_LOGIN_EXPERIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=319927908
     */
    THE_HIVE = 'DOMAINS_MANAGEMENT.THE_HIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=318880072
     */
    TITLE = 'DOMAINS_MANAGEMENT.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=319927592
     */
    UPDATE_DOMAIN_CERTIFICATES = 'DOMAINS_MANAGEMENT.UPDATE_DOMAIN_CERTIFICATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=449287867
     */
    UPDATE_DOMAIN_CERTIFICATES_V2 = 'DOMAINS_MANAGEMENT.UPDATE_DOMAIN_CERTIFICATES_V2',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=326481740
     */
    VANITY_URL_CUSTOM = 'DOMAINS_MANAGEMENT.VANITY_URL_CUSTOM',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=421936846
     */
    VERIFY_THE_LOGIN_EXPERIENCE = 'DOMAINS_MANAGEMENT.VERIFY_THE_LOGIN_EXPERIENCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=422160509
     */
    VIEW_THE_LOGIN_PAGE = 'DOMAINS_MANAGEMENT.VIEW_THE_LOGIN_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327368939
     */
    WAITING_LUMAPPS_INFO = 'DOMAINS_MANAGEMENT.WAITING_LUMAPPS_INFO',
}
