import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, Typography } from '@lumapps/lumx/react';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as PLAYLIST_VIDEO_CLASSNAME } from '../constants';

export const CLASSNAME = `${PLAYLIST_VIDEO_CLASSNAME}-author` as const;

/**
 * Displays the author of a playlist
 */
export const Author = () => {
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, theme, playlist } = useBlockPlaylistContext();

    if (!canDisplay('AUTHOR')) {
        return null;
    }

    if (isLoading) {
        return (
            <SkeletonTypography className={block('skeleton')} theme={theme} typography={Typography.body1} width="20%" />
        );
    }

    if (!playlist?.createdByDetails) {
        return null;
    }

    return <Text as="span">{playlist.createdByDetails.fullName}</Text>;
};
