import React from 'react';

import { DialogProps } from '@lumapps/lumx/react';
import { UploadedMedia } from '@lumapps/medias/types';
import type { Resource } from '@lumapps/wrex-link-preview/types';

import { LinkPreviewMetadata } from '../types';

export interface UseEditLinkPreviewDialogProps {
    /** link preview to edit */
    preview: Partial<LinkPreviewMetadata> & { resource?: Resource };
    /** callback to be executed once the preview has been changed */
    onChange: (preview: Partial<LinkPreviewMetadata> & { resource?: Resource }) => void;
    /** callback to be executed when the dialog closes */
    onClose?: DialogProps['onClose'];
}

export const useEditLinkPreviewDialog = ({
    preview: originalPreview,
    onChange,
    onClose,
}: UseEditLinkPreviewDialogProps) => {
    const [preview, setPreview] = React.useState<Partial<LinkPreviewMetadata>>(originalPreview);
    /**
     * If there is a different amount of images in the preview than there was in the original preview
     * it means that images were edited.
     */
    const wasThumbnailEdited = preview.images?.length !== originalPreview.images?.length;

    /**
     * This hook keeps in sync the internal state of the modal with the passed in props
     */
    React.useEffect(() => {
        setPreview(originalPreview);
    }, [originalPreview]);

    const onPreviewChange = () => {
        onChange(preview);

        if (onClose) {
            onClose();
        }
    };

    const onPreviewThumbnailSwitch = React.useCallback(() => {
        if (preview?.images) {
            const thumbnailIndex = preview?.thumbnailIndex || 0;

            setPreview({
                ...preview,
                /**
                 * Switching thumbnails means that we move to the next one if there is a next image, and if
                 * we have reached the end of the images array, we start from 0 again.
                 */
                thumbnailIndex: thumbnailIndex + 1 >= preview.images.length ? 0 : thumbnailIndex + 1,
            });
        }
    }, [preview]);

    const onMediaUploaded = (medias?: UploadedMedia[]) => {
        /**
         * When a media is uploaded, we have to:
         * - If the medias return is defined, it means that there was a media uploaded, and therefore
         * we can use it for our preview. Since there is just a single image for the preview, we go ahead
         * and retrieve the first media that the parameter gives and we add it to the list of images.
         * - If the medias parameter is not defined, it means that the image was removed, so we reset everything.
         */
        if (medias) {
            const [media] = medias;

            setPreview({
                ...preview,
                images: [media.url as string, ...(preview.images || [])],
                thumbnailIndex: 0,
            });
        } else {
            setPreview({
                ...preview,
                images: originalPreview.resource?.thumbnailUrl ? [originalPreview.resource?.thumbnailUrl] : [],
                thumbnailIndex: originalPreview.thumbnailIndex,
            });
        }
    };

    return {
        preview,
        setPreview,
        wasThumbnailEdited,
        onPreviewChange,
        onPreviewThumbnailSwitch: !wasThumbnailEdited ? onPreviewThumbnailSwitch : undefined,
        onMediaUploaded,
    };
};
