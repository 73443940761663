import { setAutoFreeze } from 'immer';
import { createStore, applyMiddleware, compose, combineReducers } from '@lumapps/redux';
import BaseApi from '@lumapps/base-api';
import { createEnhancer } from '@lumapps/redux/thunks';

import { ANGULAR_ACTION_PREFIX } from './redux-store-constants';

import reactReducers from '../../../components/components/rootReducer';

/**
 *  With recent updates of immer, the `produce()` function used in all of or reducers return a frozen object.
 *  https://immerjs.github.io/immer/freezing/
 *
 *  Frozen redux store is a very good idea in theory, but in practice we sometimes do not treat the state as immutable
 *  or use shallow copy instead of deep copy before doing data transformations.
 *
 *  For now, we'll disable the auto freezing as the impact is almost impossible to evaluate.
 */
setAutoFreeze(false);

// This is a local reference to the redux store.
// eslint-disable-next-line import/no-mutable-exports
let store;

function ReduxStoreService($rootScope) {
    'ngInject';

    // Old habits die hard.
    const service = {};

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * Keep a queue of all services that should be kept in sync with redux state.
     *
     * @type {AngularService[]}
     */
    const _syncedServices = [];

    /**
     * This is the root reducer for all mapped services.
     *
     * @type {{[string]: (state, action) => state}}
     */
    const _serviceReducer = {};

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The redux store instance.
     *
     * @type {{[string]: Object}}
     */
    service.store = store;

    // ///////////////////////////
    //                         //
    //     Private functions   //
    //                         //
    // ///////////////////////////

    /**
     * Update Angular services data and run $digest when an action is dispatched from redux.
     */
    function _onReduxStoreUpdate() {
        const newState = store.getState();
        const { lastActionType } = newState;

        // We only want to update Angular state when action are coming from redux.
        if (lastActionType.includes(ANGULAR_ACTION_PREFIX)) {
            return;
        }

        /*
         * For each "synched" service, get its current state from redux then
         * re-map it back to the service.
         */
        _syncedServices.forEach((theSyncedService) => {
            const { mapReduxToAngular, reduxReducerName } = theSyncedService;
            if (mapReduxToAngular) {
                const newReducerState = newState[reduxReducerName] || {};

                mapReduxToAngular(newReducerState);
            }
        });

        // No need to redigest, if already digesting.
        if (!$rootScope.$root.$$phase) {
            $rootScope.$digest();
        }
    }

    /**
     * Make a default reducer for this service.
     *
     * @param  {string}     nameSpace       The key underwitch this data will be found in the redux store.
     * @param  {Function}   mapStateToRedux Function that cherry-picks part of the data that needs to be exposed.
     * @return {ReduxState} The new Redux State.
     */
    const makeDefaultReduxReducer = (nameSpace, mapStateToRedux) => (state = mapStateToRedux(), action) => {
        switch (action.type) {
            case `${ANGULAR_ACTION_PREFIX}/${nameSpace}`:
                return action.newState;

            default:
                return state;
        }
    };

    // ///////////////////////////

    /**
     * Expose a subscribe method to all angular service to map themselves to the redux store.
     *
     * @param {ReduxStoreService<Service>} serviceToWatch     Service that wants to be synced in Redux.
     * @param {boolean}                    skipDefaultReducer Skip the automatically generated defaultReducer.
     *                                                        This can be useful when you need 2 way interactions with
     *                                                        the store, from both the AngularJS and React sides.
     *
     */
    service.subscribe = (serviceToWatch, skipDefaultReducer = false) => {
        const {
            mapStateToRedux,
            mapReduxToAngular,
            reduxReducer,
            reduxReducerName,
            reduxUpdateActionType,
        } = serviceToWatch;

        _syncedServices.push(serviceToWatch);

        // Either use a custome reducer provided by the service or make a default one.
        if (!skipDefaultReducer) {
            _serviceReducer[reduxReducerName] =
                reduxReducer || makeDefaultReduxReducer(reduxUpdateActionType, mapStateToRedux);
        }

        const dispatchRedux = () => {
            if (store) {
                store.dispatch({
                    newState: mapStateToRedux(),

                    // Actions types from Angular are prefixed.
                    type: `${ANGULAR_ACTION_PREFIX}/${reduxUpdateActionType}`,
                });
            } else {
                setTimeout(dispatchRedux, 300);
            }
        };

        // Let AngularJS notify redux via an action when $digesting.

        $rootScope.$watch(
            mapStateToRedux,
            // eslint-disable-next-line no-unused-vars
            (newVal, oldVal) => {
                dispatchRedux();
            },
            true,
        );
    };

    /**
     * Initialize the service.
     */
    service.init = function init() {
        const enhancer = createEnhancer();

        // Root reducer, it combines angular services reducers and the reducers from the "ducks" in react side.
        const rootReducer = combineReducers({ ...reactReducers, ..._serviceReducer });

        // Create store.
        store = createStore(rootReducer, enhancer);

        BaseApi.store = store;

        // Subscribe to store.
        store.subscribe(_onReduxStoreUpdate);
        service.store = store;
    };

    // ///////////////////////////

    service.ANGULAR_ACTION_PREFIX = ANGULAR_ACTION_PREFIX;

    // ///////////////////////////
    return service;
}

angular.module('Services').service('ReduxStore', ReduxStoreService);

// ///////////////////////////

export { ReduxStoreService, store };
