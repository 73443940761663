import { DOMAIN } from '../constants';

const initDirectoryEntries = (initState: any = {}) => {
    Object.assign(initState, {
        [DOMAIN]: {
            directories: initState.directories || [],
        },
    });

    return initState;
};

export { initDirectoryEntries };
