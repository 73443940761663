import { SpaceServices } from '@lumapps/spaces/types';

import { SPACE_NAVIGATION } from './keys';

/** List of all the static entries of the space navigation v1 */
export const SPACE_NAV_V1_STATIC_ITEMS = [
    SpaceServices.homepage,
    SpaceServices.feed,
    SpaceServices.posts,
    SpaceServices.article,
    SpaceServices.event,
    SpaceServices.learning,
    SpaceServices.documents,
    SpaceServices.drive,
    SpaceServices.calendar,
];

/** List of all the static entries of the Explore menu in the space navigation v2 */
export const SPACE_NAV_V2_STATIC_ITEMS = [SpaceServices.homepage, SpaceServices.feed];

/** List of all the sections and their items of the Explore menu in the space navigation v2 */
export const SPACE_NAV_V2_EXPLORE_ITEMS = [
    {
        label: SPACE_NAVIGATION.EXPLORE_SECTION_SOCIAL,
        services: [SpaceServices.posts, SpaceServices.event],
    },
    {
        label: SPACE_NAVIGATION.EXPLORE_SECTION_RESOURCES,
        services: [SpaceServices.article, SpaceServices.documents, SpaceServices.learning],
    },
    {
        label: SPACE_NAVIGATION.EXPLORE_SECTION_ASSOCIATED,
        services: [SpaceServices.drive, SpaceServices.calendar],
    },
];
