import debounce from 'lodash/debounce';

import { Dispatch } from '../types';

/**
 * Debounce a thunk (async or not) and forward eventual exceptions (uses lodash/debounce).
 *
 * @param thunk  The thunk function to debounce.
 * @param delay  The delay applied to the debounce.
 * @return an async thunks debounced.
 */
export const debounceThunk = (thunk: any, delay: number) => {
    const debounced = debounce((thunkArguments, dispatch, resolve, reject) => {
        let result;
        try {
            result = dispatch(thunk(...thunkArguments));
            if (result instanceof Promise) {
                result.then(resolve, reject);
            } else {
                resolve(result);
            }
        } catch (err) {
            reject(err);
        }
    }, delay);
    return (...thunkArguments: any) =>
        (dispatch: Dispatch) =>
            new Promise((resolve, reject) => {
                debounced(thunkArguments, dispatch, resolve, reject);
            });
};
