import { Editor, PathRef, Transforms } from '@lumapps/wrex/slate';

import { FileAttributes } from '../types';

/**
 * Update a file node at a given pathref.
 */
export function updateFileNode(editor: Editor, attributes: FileAttributes, nodePathRef: PathRef) {
    const pathRef = nodePathRef.unref();

    if (pathRef) {
        Transforms.setNodes(editor, attributes as any, { at: pathRef });
    }
}
