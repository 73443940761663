import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

export const learningBaseRoute: Route = {
    path: '/admin/learning',
    appId: AppId.backOffice,
    legacyId: 'app.admin.learning',
};

const learningAnalyticsRoute: Route = {
    path: '/admin/learning/analytics',
    legacyId: 'app.admin.analytics',
    appId: AppId.backOffice,
};
const learningCategoriesRoute: Route = {
    path: '/admin/learning/catalog/categories',
    legacyId: 'app.admin.categories',
    appId: AppId.backOffice,
};
const learningTrainingCoursesRoute: Route = {
    path: '/admin/learning/catalog/training-courses',
    legacyId: 'app.admin.training-courses',
    appId: AppId.backOffice,
};
const learningLearnersRoute: Route = {
    path: '/admin/learning/administration/learners',
    legacyId: 'app.admin.learners',
    appId: AppId.backOffice,
};
const learningManagersRoute: Route = {
    path: '/admin/learning/administration/managers',
    legacyId: 'app.admin.managers',
    appId: AppId.backOffice,
};
const learningCertificatesRoute: Route = {
    path: '/admin/learning/administration/certificates',
    legacyId: 'app.admin.certificates',
    appId: AppId.backOffice,
};

export {
    learningAnalyticsRoute,
    learningCategoriesRoute,
    learningCertificatesRoute,
    learningManagersRoute,
    learningTrainingCoursesRoute,
    learningLearnersRoute,
};
