import React from 'react';

import { classnames, padding } from '@lumapps/classnames';
import { FlexBox } from '@lumapps/lumx/react';

const CLASSNAME = 'reactions-block';

export interface ReactionsBlockProps {
    className?: string;
}

/** Block to but LikeButton and CommentButton inside */
export const ReactionsBlock: React.FC<ReactionsBlockProps> = ({ children, className = padding('all', 'tiny') }) => (
    <FlexBox className={classnames(CLASSNAME, className)} orientation="horizontal">
        {children}
    </FlexBox>
);
ReactionsBlock.displayName = 'ReactionsBlock';
