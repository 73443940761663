import React from 'react';

import { classnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { useDataAttributes } from '@lumapps/data-attributes';
import { getCurrentInstanceLogoUrl } from '@lumapps/instance/utils/getCurrentInstanceLogoUrl';
import { useTranslate } from '@lumapps/translations';

import './index.scss';

const { isDev, defautlLogoUrl } = get();

export interface InstanceLogoProps {
    /** The fallback image if there is an error with the homeImageSrc */
    fallbackHomeImageSrc: string;
    /** The image source of the company logo */
    homeImageSrc: string | Record<string, any>;
    /** The url where to redirect for the logo */
    homeLinkUrl?: string;
    /** The link title */
    title?: string;
    /** custom class name */
    className?: string;
    /** whether this component is rendered in a loading context */
    isLoading?: boolean;
    /** home page content Id */
    homePageId?: string;
}

const CLASSNAME = 'instance-logo';
const SCOPE = 'instance';

export const InstanceLogo = React.forwardRef<HTMLAnchorElement, InstanceLogoProps>(
    ({ fallbackHomeImageSrc, homeImageSrc, homeLinkUrl, className, title, isLoading = false, homePageId }, ref) => {
        const { translateObject } = useTranslate();
        const logoClassName = classnames(CLASSNAME, className);
        const { get } = useDataAttributes(SCOPE);
        // Get the proper image url
        const imageSrc = React.useMemo(() => {
            /**
             * While in development, display the default logo since the backend cannot manage the localhost
             * env correctly and we do not want to display a broken image all the time while development
             */
            if (isDev) {
                return defautlLogoUrl;
            }

            return getCurrentInstanceLogoUrl({ homeImageSrc, translateObject, fallbackHomeImageSrc });
        }, [homeImageSrc, translateObject, fallbackHomeImageSrc]);

        if (isLoading) {
            return (
                <a href={homeLinkUrl} className={logoClassName} ref={ref} {...get({ element: 'logo' })}>
                    <img src={imageSrc} alt={title} />
                </a>
            );
        }

        return (
            <ContentLink
                className={logoClassName}
                ref={ref}
                {...get({ element: 'logo' })}
                to={{ id: homePageId, isHomePage: true }}
            >
                <img src={imageSrc} alt={title} />
            </ContentLink>
        );
    },
);
InstanceLogo.displayName = 'InstanceLogo';
