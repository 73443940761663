import { useMemo } from 'react';

import { useTranslate } from '@lumapps/translations';

import { BaseVideo } from '../api/types';
import { PLAY } from '../keys';

export interface UseViewsProps {
    views: BaseVideo['views'];
}

export const useViews = ({ views }: UseViewsProps) => {
    const { pluralize, translateAndReplace } = useTranslate();

    const viewsTranslation = useMemo(() => {
        return translateAndReplace(pluralize(PLAY.VIDEO_VIEWS, views), {
            VIEWS: views,
        });
    }, [pluralize, translateAndReplace, views]);

    return viewsTranslation;
};
