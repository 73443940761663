import { ColorPalette } from '@lumapps/lumx/react';
import { PluralTypes, getPluralType } from '@lumapps/translations';

import { Notification, NotificationTypes, NotificationMeta } from '../../types';
import { getContentRoute } from '../linksUtils';

const isGenericNotification = (notification?: Notification) => (notification?.unreadNotificationCount || 1) > 1;

const notificationTypeCustom: Record<string, NotificationMeta> = {
    [NotificationTypes.Custom]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'bell',
        },
        getTitle: ({ notification, translation }) => {
            // Only sub notification have a title
            if (!isGenericNotification(notification)) {
                return translation.translateObject(notification?.title || {}) || '';
            }

            return null;
        },
        getDescription: ({ notification, translation }) => {
            const unreadCount = notification?.unreadNotificationCount || 1;
            const groupName = notification?.groupName ? translation.translate(notification?.groupName) : '';

            // If there is only one notification, display it instead of the group
            if (!isGenericNotification(notification)) {
                return translation.translateObject(notification?.description || {}) || '';
            }

            // The type ONE should not appear here since it's handle above
            const translationKeys = {
                [PluralTypes.ONE]: 'NOTIFICATION_CUSTOM_ONE_DESCRIPTION',
                [PluralTypes.MORE]: 'NOTIFICATION_CUSTOM_MORE_DESCRIPTION',
                [PluralTypes.TWO]: 'NOTIFICATION_CUSTOM_MORE_DESCRIPTION',
            };

            return translation.translateAndReplace(translationKeys[getPluralType(unreadCount)], {
                GROUP_NAME: groupName || '',
                NB_UNREAD_NOTIFICATIONS: unreadCount,
            });
        },
        getRoute: (params) => {
            // The parent notification is not clickable and does not return to anything
            if (isGenericNotification(params.notification)) {
                return null;
            }

            return getContentRoute(params);
        },
    },
};

export { notificationTypeCustom };
