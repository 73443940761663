/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { CropperDialogProps } from '../../types';

const LazyCropperDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'cropper-dialog' */
            './CropperDialog'
        ),
);

const CropperDialog: React.FC<CropperDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            {(setShouldMount) => <LazyCropperDialog onVisibilityChange={setShouldMount} {...props} />}
        </DelayedSuspense>
    );
};

export { CropperDialog };
