(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSettingsFooterController($scope, ContentPicker, InitialSettings, Translation, Widget) {
        'ngInject';

        var widgetSettingsFooter = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the content picker.
         *
         * @type {string}
         * @constant
         */
        widgetSettingsFooter.CONTENT_PICKER_ID = 'widget-settings-more-';

        /**
         * Content that is selected in the content picker.
         *
         * @type {Object}
         */
        widgetSettingsFooter.selectedContent = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetSettingsFooter.Translation = Translation;
        widgetSettingsFooter.Widget = Widget;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Update link following content select.
         *
         * @param {Event}  evt             An event that occurs.
         * @param {string} contentPickerId The content picker id.
         */
        function _updateLink(evt, contentPickerId) {
            if (widgetSettingsFooter.CONTENT_PICKER_ID !== contentPickerId ||
                angular.isUndefinedOrEmpty(widgetSettingsFooter.selectedContent)) {
                return;
            }

            // Since we use the slug as the href, we need the full community slug with 'ls/community'.
            if (widgetSettingsFooter.selectedContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
                // `slug` and `link` are translation object (ex: {fr: ..., en: ...}).
                // We map from one to the other.
                Widget.getCurrent().properties.more.link = _.mapValues(
                    widgetSettingsFooter.selectedContent.slug,
                    (slug) => `ls/community/${slug}`,
                );
            } else {
                Widget.getCurrent().properties.more.link = widgetSettingsFooter.selectedContent.slug;
            }
            Widget.getCurrent().properties.more.linkInstance = widgetSettingsFooter.selectedContent.instance;

            widgetSettingsFooter.selectedContent = {};
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the current widget has a title.
         *
         * @return {boolean} Whether or not current widget has a title.
         */
        function hasCurrentWidgetTitle() {
            return Widget.shouldDisplayWidgetHeader(Widget.getCurrent().title);
        }

        /**
         * Opens the content picker to select the link to that particular content.
         */
        function openContentPicker() {
            ContentPicker.open(widgetSettingsFooter.CONTENT_PICKER_ID);
        }

        /////////////////////////////

        widgetSettingsFooter.hasCurrentWidgetTitle = hasCurrentWidgetTitle;
        widgetSettingsFooter.openContentPicker = openContentPicker;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Updates the widget link when dialog closes.
         */
        $scope.$on('content-picker__close-start', _updateLink);

        /////////////////////////////

        /**
         * Initialise the controller.
         */
        function init() {
            widgetSettingsFooter.CONTENT_PICKER_ID += Widget.getCurrent().uuid;
        }

        init();
    }

    /////////////////////////////

    function WidgetSettingsFooterDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: WidgetSettingsFooterController,
            controllerAs: 'widgetSettingsFooter',
            require: ['widgetSettingsFooter', '^widgetSettings'],
            restrict: 'E',
            templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-settings-footer.html',
            transclude: true,
        };
    }

    angular.module('Directives').directive('widgetSettingsFooter', WidgetSettingsFooterDirective);
})();
