import React from 'react';

import { ListSubheader } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

export type NotificationListSubHeaderProps = {
    priority: number;
};

const CLASSNAME = 'notification-center-list__sub-header';

const NotificationListSubHeader: React.FC<NotificationListSubHeaderProps> = ({ priority }) => {
    const { translateKey } = useTranslate();

    return (
        <ListSubheader className={CLASSNAME}>
            {translateKey(`NOTIFICATION_PRIORITY_NAME_${priority || 0}`)}
        </ListSubheader>
    );
};

export { NotificationListSubHeader };
