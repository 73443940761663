import React, { useState, useCallback, useRef } from 'react';

import { margin, paddings } from '@lumapps/classnames';
import { mdiSettings } from '@lumapps/lumx/icons';
import { Button, Emphasis, Toolbar, Dialog, Message, Kind, Text } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { useThirdPartyLibrary, THIRD_PARTY_LIBRARIES } from '@lumapps/utils/libs/useThirdPartyLibrary';

import { useAdvancedStyling } from '../../hooks/useAdvancedStyling';
import { ADVANCED_STYLES } from '../../keys';
import { AdvancedStylingFields } from '../AdvancedStylingFields';

export interface AdvancedStylingOptions {
    /** id of the style to edit */
    styleId: string;
    /** instance id related to the style to edit */
    instanceId: string;
    /** callback to be executed when the Save button is clicked */
    onSave: (options: { cssRoot?: string; cssCustom?: string; instanceHead?: string }) => void;
    /** whether the modal window is open by default or not */
    isModalOpen?: boolean;
    /** whether an Unsaved modifications message should be displayed or not */
    shouldShowUnsavedMessage?: boolean;
    /** whether root styles field should be displayed */
    shouldShowRootStyles?: boolean;
}

const CLASSNAME = 'advanced-styling';
export const AdvancedStyling: React.FC<AdvancedStylingOptions> = ({
    styleId,
    instanceId,
    onSave,
    isModalOpen = false,
    shouldShowUnsavedMessage = false,
    shouldShowRootStyles = false,
}) => {
    const [isOpen, setOpen] = useState(isModalOpen);
    const onModalClose = useCallback(() => setOpen(false), []);
    const { translateKey } = useTranslate();
    const buttonRef = useRef(null);
    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);
    const [hasLibLoaded, setHasLibLoaded] = React.useState(false);

    useThirdPartyLibrary({
        lib: THIRD_PARTY_LIBRARIES.CODE_MIRROR,
        shouldImportCss: false,
        shouldLoadLibrary: true,
        onLoad: () => {
            if (!hasLibLoaded) {
                setHasLibLoaded(true);
            }
        },
    });

    const { isLoaded, cssRoot, cssCustom, instanceHead, setStyle, setInstanceHead } = useAdvancedStyling({
        styleId,
        instanceId,
        shouldFetchData: isOpen,
    });

    /**
     * When the modal is saved, we want to close the modal and execute the callback passed in onto the component.
     */
    const onModalSave = () => {
        onModalClose();

        onSave({
            cssRoot,
            cssCustom,
            instanceHead,
        });
    };

    return (
        <>
            <Button
                className={CLASSNAME}
                leftIcon={mdiSettings}
                emphasis={Emphasis.medium}
                onClick={toggle}
                isDisabled={!hasLibLoaded}
            >
                {translateKey(ADVANCED_STYLES.CUSTOM_CODE)}
            </Button>
            <Dialog isOpen={isOpen} parentElement={buttonRef} onClose={onModalClose}>
                <header>
                    <Toolbar
                        label={
                            <Text as="span" typography="title">
                                {translateKey(ADVANCED_STYLES.CUSTOM_CODE)}
                            </Text>
                        }
                    />
                </header>
                <div
                    className={paddings([
                        { direction: 'right', size: 'huge' },
                        { direction: 'left', size: 'huge' },
                        { direction: 'bottom', size: 'huge' },
                    ])}
                >
                    <Message kind={Kind.info} hasBackground>
                        {translateKey(ADVANCED_STYLES.ADVANCED_WARNING)}
                    </Message>
                    {isOpen ? (
                        <AdvancedStylingFields
                            areFieldsDisplayed={isOpen && hasLibLoaded}
                            isLoaded={isLoaded}
                            cssRoot={cssRoot}
                            cssCustom={cssCustom}
                            instanceHead={instanceHead}
                            setStyle={setStyle}
                            setInstanceHead={setInstanceHead}
                            shouldShowRootStyles={shouldShowRootStyles}
                        />
                    ) : null}
                </div>

                <footer>
                    <Toolbar
                        after={
                            <>
                                <Button emphasis={Emphasis.medium} onClick={onModalClose}>
                                    {translateKey(GLOBAL.DISCARD)}
                                </Button>
                                <Button className={margin('left', 'regular')} onClick={onModalSave}>
                                    {translateKey(GLOBAL.APPLY)}
                                </Button>
                            </>
                        }
                    />
                </footer>
            </Dialog>

            {shouldShowUnsavedMessage ? (
                <Message kind={Kind.warning} hasBackground className={margin('top', 'huge')}>
                    {translateKey(ADVANCED_STYLES.UNSAVED_CHANGES)}
                </Message>
            ) : null}
        </>
    );
};
