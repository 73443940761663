import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import toStr from 'lodash/toString';
import values from 'lodash/values';

import { GTM_EVENT_NAME } from '@lumapps/metric/constants';
import { sendGAPageView } from '@lumapps/metric/hooks/useGoogleAnalyticsAndTagManager';

/////////////////////////////

function AnalyticsService(
    $location,
    $log,
    $q,
    Community,
    Content,
    CustomContentType,
    Customer,
    Instance,
    Metadata,
    Post,
    Translation,
    User,
    UserDirectory,
    Utils,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The attributes of the GTM event.
     *
     * @type {Object}
     */
    let _attributes = {};

    /**
     * Contains the Promise of the service initialization.
     *
     * @type {Promise}
     */
    const _initializationDeferred = $q.defer();

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Handle analytics tagging map.
     * Note: this will be overwritten in the client override analyticsservice.
     */
    function handleTaggingMap() {
        // Nothing here for now.
    }
    /**
     * Send a Google Tag Manager event.
     *
     * @param  {string}  state The state we currently are.
     * @return {Promise} The promise of the event sending.
     */
    function sendGTMEvent(state) {
        return $q(function defer(resolve) {
            _initializationDeferred.promise.then(function onServiceInitialized() {
                const currentInstance = Instance.getInstance() || {};

                const attributes = {};
                const promises = [];

                let content;
                if (state === 'app.front.content-edit' || state === 'app.front.content-get') {
                    content = Content.getCurrent() || {};

                    /* eslint-disable camelcase */
                    attributes.lumapps_content_internal_type = content.type;

                    attributes.lumapps_content_type_id = content.customContentType;
                    attributes.lumapps_content_type_name = Translation.translate(
                        get(content, 'customContentTypeDetails.name'),
                        undefined,
                        currentInstance.defaultLang,
                        false,
                        true,
                        true,
                    );
                    if (angular.isUndefinedOrEmpty(attributes.lumapps_content_type_name)) {
                        attributes.lumapps_content_type_name = undefined;
                    }

                    attributes.lumapps_content_publication_date = content.publicationDate;
                    if (angular.isUndefinedOrEmpty(attributes.lumapps_content_publication_date)) {
                        attributes.lumapps_content_publication_date = undefined;
                    }

                    const metadataList = flattenDeep(values(content.metadata)) || [];
                    attributes.lumapps_content_metadata_ids = metadataList.join(';');
                    if (angular.isDefinedAndFilled(attributes.lumapps_content_metadata_ids)) {
                        attributes.lumapps_content_metadata = '';
                        angular.forEach(metadataList, function forEachMetadataIds(metadataId) {
                            const metadata = Metadata.getMetadataFromKey(metadataId, false, true);

                            const metadataName = Translation.translate(
                                get(metadata, 'name'),
                                undefined,
                                currentInstance.defaultLang,
                                false,
                                true,
                                true,
                            );

                            if (angular.isDefinedAndFilled(metadataName)) {
                                attributes.lumapps_content_metadata += angular.isDefinedAndFilled(
                                    attributes.lumapps_content_metadata,
                                )
                                    ? ';'
                                    : '';
                                attributes.lumapps_content_metadata += metadataName;
                            }
                        });
                    } else {
                        attributes.lumapps_content_metadata_ids = undefined;
                        attributes.lumapps_content_metadata = undefined;
                    }

                    const tagsList = content.customContentTypeTags || [];
                    attributes.lumapps_content_tags_ids = tagsList.join(';');
                    if (angular.isDefinedAndFilled(attributes.lumapps_content_tags_ids)) {
                        attributes.lumapps_content_tags = '';
                        angular.forEach(tagsList, function forEachTagsIds(tagId) {
                            const promise = $q(function tagsDefer(tagsResolve) {
                                CustomContentType.getCustomContentTypesTags(content.customContentType)
                                    .then(function onCctTagsListSuccess(tags) {
                                        const tag = CustomContentType.getTagByUuid(tagId, tags);

                                        const tagName = Translation.translate(
                                            get(tag, 'name'),
                                            undefined,
                                            currentInstance.defaultLang,
                                            false,
                                            true,
                                            true,
                                        );

                                        if (angular.isDefinedAndFilled(tagName)) {
                                            attributes.lumapps_content_tags += angular.isDefinedAndFilled(
                                                attributes.lumapps_content_tags,
                                            )
                                                ? ';'
                                                : '';
                                            attributes.lumapps_content_tags += tagName;
                                        }

                                        tagsResolve();
                                    })
                                    .catch(tagsResolve);
                            });

                            promises.push(promise);
                        });
                    } else {
                        attributes.lumapps_content_tags_ids = undefined;
                        attributes.lumapps_content_tags = undefined;
                    }

                    attributes.lumapps_content_likes = content.likes || 0;
                    attributes.lumapps_content_comments = content.comments || 0;
                    /* eslint-enable camelcase */
                } else {
                    /* eslint-disable camelcase */
                    attributes.lumapps_content_internal_type = undefined;
                    attributes.lumapps_content_type_id = undefined;
                    attributes.lumapps_content_type_name = undefined;
                    attributes.lumapps_content_publication_date = undefined;
                    attributes.lumapps_content_metadata_ids = undefined;
                    attributes.lumapps_content_metadata = undefined;
                    attributes.lumapps_content_tags_ids = undefined;
                    attributes.lumapps_content_tags = undefined;
                    attributes.lumapps_content_likes = undefined;
                    attributes.lumapps_content_comments = undefined;
                    /* eslint-enable camelcase */

                    if (state === 'app.front.community') {
                        if (includes($location.path, '/post/')) {
                            content = Post.getCurrent();
                        } else {
                            content = Community.getCurrent();
                        }
                    }
                }

                if (angular.isDefinedAndFilled(content)) {
                    /* eslint-disable camelcase */
                    attributes.lumapps_content_id = content.uid || content.id;
                    attributes.lumapps_content_internal_type = content.type;
                    attributes.lumapps_content_title = Translation.translate(
                        content.title,
                        undefined,
                        currentInstance.defaultLang,
                        false,
                        true,
                        true,
                    );
                    attributes.lumapps_content_slug = Translation.translate(
                        content.slug,
                        undefined,
                        currentInstance.defaultLang,
                        false,
                        true,
                        true,
                    );

                    /**
                     * Fix for SUP-1287: replace undefined or empty string by the first value found.
                     * As translate funtion is a bit cumbersome, it has been decided to use this pattern instead
                     */
                    if (angular.isUndefinedOrEmpty(attributes.lumapps_content_title)) {
                        attributes.lumapps_content_title =
                            content.title && Object.keys(content.title).length > 0
                                ? content.title[Object.keys(content.title)[0]]
                                : undefined;
                    }

                    if (angular.isUndefinedOrEmpty(attributes.lumapps_content_slug)) {
                        attributes.lumapps_content_slug =
                            content.slug && Object.keys(content.slug).length > 0
                                ? content.slug[Object.keys(content.slug)[0]]
                                : undefined;
                    }

                    attributes.lumapps_content_is_homepage = toStr(content.isHomepage || false);
                    /* eslint-enable camelcase */
                } else {
                    /* eslint-disable camelcase */
                    attributes.lumapps_content_id = undefined;
                    attributes.lumapps_content_internal_type = undefined;
                    attributes.lumapps_content_title = undefined;
                    attributes.lumapps_content_slug = undefined;
                    attributes.lumapps_content_is_homepage = undefined;
                    /* eslint-enable camelcase */
                }

                /* eslint-disable camelcase */
                attributes.lumapps_route = $location.path();
                attributes.page_location = $location.path();
                /* eslint-enable camelcase */

                $q.all(promises).then(function onAllPromisesResolved() {
                    angular.extend(attributes, _attributes);
                    if (typeof dataLayerCustom !== 'undefined') {
                        window.dataLayerCustom.push({
                            attributes,
                            event: GTM_EVENT_NAME,
                        });
                    }

                    // Manualy send page view
                    sendGAPageView(attributes)

                    resolve();
                });
            });
        });
    }

    /////////////////////////////

    service.handleTaggingMap = handleTaggingMap;
    service.sendGTMEvent = sendGTMEvent;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = function init() {
        try {
            const currentCustomer = Customer.getCustomer() || {};
            const currentInstance = Instance.getInstance() || {};

            _attributes = {
                /* eslint-disable camelcase */
                lumapps_customer_id: Customer.getCustomerId(),
                lumapps_customer_name: currentCustomer.name,
                lumapps_site_default_lang: currentInstance.defaultLang,
                lumapps_site_ga: currentInstance.googleAnalytics,
                lumapps_site_id: Instance.getCurrentInstanceId(),
                lumapps_site_name: currentInstance.name,
                lumapps_site_slug: Instance.getCurrentInstanceSlug(),
            };

            if (angular.isUndefinedOrEmpty(_attributes.lumapps_site_default_lang)) {
                // eslint-disable-next-line camelcase
                _attributes.lumapps_site_default_lang = undefined;
            }
            if (angular.isUndefinedOrEmpty(_attributes.lumapps_site_ga)) {
                // eslint-disable-next-line camelcase
                _attributes.lumapps_site_ga = undefined;
            }
            if (angular.isUndefinedOrEmpty(_attributes.lumapps_site_name)) {
                // eslint-disable-next-line camelcase
                _attributes.lumapps_site_name = undefined;
            }
            /* eslint-enable camelcase */

            const connectedUser = User.getConnected();

            const defaultUserDirectory = UserDirectory.getDefaultUserDirectory(connectedUser);
            if (angular.isDefinedAndFilled(defaultUserDirectory)) {
                angular.forEach(
                    get(defaultUserDirectory, 'template.components', []),
                    function forEachUserDirectoryComponents(component) {
                        if (angular.isUndefinedOrEmpty(get(component, 'properties.analyticsCode'))) {
                            return;
                        }

                        _attributes[`lumapps_user_dir_${component.properties.analyticsCode}`] =
                            Utils.getApiProfileFieldFromMap(
                                get(component.properties, 'boundMap.text', component.uuid),
                                connectedUser,
                            );
                    },
                );
            }

            /* eslint-disable camelcase */
            // GTM needs to have boolean values in string.
            _attributes.lumapps_user_is_admin = toStr(connectedUser.isSuperAdmin || User.isInstanceAdmin());
            _attributes.lumapps_user_unique_id = connectedUser.uid || connectedUser.id;

            _attributes.lumapps_user_primary_language = connectedUser.lang;
            _attributes.lumapps_user_lang_1 = connectedUser.lang;
            if (angular.isUndefinedOrEmpty(_attributes.lumapps_user_primary_language)) {
                _attributes.lumapps_user_primary_language = undefined;
                _attributes.lumapps_user_lang_1 = undefined;
            }

            _attributes.lumapps_user_secondary_language = (connectedUser.langs || []).join(';');
            _attributes.lumapps_user_lang_2 = (connectedUser.langs || []).join(';');
            if (angular.isUndefinedOrEmpty(_attributes.lumapps_user_secondary_language)) {
                _attributes.lumapps_user_secondary_language = undefined;
                _attributes.lumapps_user_lang_2 = undefined;
            }
            /* eslint-enable camelcase */
            _initializationDeferred.resolve();
        } catch (exception) {
            $log.error(exception);

            _initializationDeferred.reject();
        }
    };
}

/////////////////////////////

angular.module('Services').service('Analytics', AnalyticsService);

/////////////////////////////

export { AnalyticsService };
