export enum STRUCTURED_CONTENT {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36700691
     */
    CROPPER_DIALOG_TITLE = 'FRONT.CROPPER_DIALOG.TITLE',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=469629387
     */
    WARNING_MESSAGE_SMALL_DISPLAY = 'STRUCTURED_CONTENT.WARNING_MESSAGE_SMALL_DISPLAY',
}
