import type { PathRef, ReactEditor } from '@lumapps/wrex/slate';

import type { LinkPreviewEditor, LinkPreviewElement } from '../types';

/**
 * Update link preview node at a given pathref.
 */
export function updateLinkPreview(
    editor: ReactEditor & LinkPreviewEditor,
    link: Partial<LinkPreviewElement>,
    nodePathRef: PathRef,
) {
    const at = nodePathRef.current;
    if (at) {
        editor.setNodes(link, { at });
    }
}
