import loFind from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import { ENABLE_ANGULAR_DEBUG_INFO, SCOPE_INFO } from 'common/config';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import * as ReactWidgets from '../../../../../../../components/components/widgets';

/////////////////////////////

function WidgetController(
    $element,
    $rootScope,
    $scope,
    $timeout,
    Cell,
    ConfigTheme,
    Content,
    ContentTemplate,
    Features,
    InitialSettings,
    Instance,
    Layout,
    Media,
    Row,
    Style,
    Translation,
    User,
    Utils,
    Widget,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetRoot = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The default shadow elevation of the main area of the widget.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_SHADOW_ELEVATION = 1;

    /**
     * Contains the list of widgets that can use the lang detection feature.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _LANG_DETECTION_ENABLED_WIDGETS = [
        InitialSettings.WIDGET_TYPES.HTML,
        InitialSettings.WIDGET_TYPES.INTRO,
        InitialSettings.WIDGET_TYPES.TIP,
    ];

     /**
     * Widget header parts subject to styles.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _WIDGET_HEADER_PART_LIST = ['icon', 'main', 'wrapper', 'label'];

    /**
     * Margin properties used for migrations and styles.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _MAIN_MARGIN_PROPERTIES = ['margin', 'marginTop', 'marginRight', 'marginBottom', 'marginLeft'];

    /**
     * The maximum size of a widget to consider it a 'm' (medium) size.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_M_WIDGET_SIZE = 700;

    /**
     * The maximum size of a widget to consider it a 's' (small) size.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _MAX_S_WIDGET_SIZE = 400;

    /**
     * The current style used by the widget.
     *
     * @type {Object}
     */
    let _currentStyle;

    /**
     * Indicates if the scope info have been added to the element.
     *
     * @type {boolean}
     */
    let _scopeInfoAdded = false;

    /**
     * The widget parent cell.
     *
     * @type {Object}
     */
    let _widgetCell,
        /**
         * The widget parent components.
         *
         * @type {Array}
         */
        _widgetGlobalStyleName,
        /**
         * The widget global style name.
         *
         * @type {string}
         */
        _widgetParents;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Handle backward compatibility - community-post-list directive has been renamed post-list.
     *
     * @type {string}
     */
    widgetRoot.backwardCompatWidgetType = Widget.getBackwardCompatibleWidgetType(widgetRoot.widget.widgetType);

    /**
     * The widget listKey built depending of the location of the widget.
     *
     * @type {string}
     */
    widgetRoot.listkey = undefined;

    /**
     * The widget size according to its parent cell width.
     *
     * @type {string}
     */
    widgetRoot.widgetSize = undefined;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    widgetRoot.ConfigTheme = ConfigTheme;
    widgetRoot.Content = Content;
    widgetRoot.Layout = Layout;
    widgetRoot.Widget = Widget;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Remove for each area style property (global, header, content, footer) properties which are undefined or null.
     *
     * @param  {Object} objectStyle The style object to clean.
     * @return {Object} The cleaned object without undefined or null property values for each area style property.
     */
    function _cleanAllFalsyStyleProperties(objectStyle) {
        angular.forEach(objectStyle, function forEachProperty(keyValue, key) {
            objectStyle[key] = omit(objectStyle[key], function omitUndefinedOrNullProperties(value) {
                return angular.isUndefinedOrEmpty(value);
            });
        });

        return objectStyle;
    }

    /**
     * Apply given style to the widget.
     *
     * @param {Object} newStyle The new style.
     */
    function _applyStyle(newStyle) {
        // Set widget global style name.
        _widgetGlobalStyleName = get(newStyle, 'name', '');

        const widgetStyle = widgetRoot.widget.properties.style;

        const mainStyle = get(widgetRoot.widget.properties.style, 'main');
        if (newStyle.id && newStyle.id === widgetRoot.widget.style) {
            let mainMargins = {};

            // Backward compatibility : Keep main margins to re-apply them after applying global style.
            if (angular.isDefinedAndFilled(mainStyle)) {
                mainMargins = {
                    margin: mainStyle.margin,
                    marginBottom: mainStyle.marginBottom,
                    marginLeft: mainStyle.marginLeft,
                    marginRight: mainStyle.marginRight,
                    marginTop: mainStyle.marginTop,
                };
            }

            widgetRoot.widget.properties.style = angular.fastCopy(newStyle.properties) || {};

            /** 
             * We make sure to only retain only applicable syle.
             * This is needed to avoid to have outdated styles interfer with the correct ones.
             * */
            widgetRoot.widget.properties.style.header = pick(widgetRoot.widget.properties.style.header, _WIDGET_HEADER_PART_LIST);

            if (angular.isDefinedAndFilled(mainMargins)) {
                widgetRoot.widget.properties.style.main = widgetRoot.widget.properties.style.main || {};
                angular.extend(widgetRoot.widget.properties.style.main, mainMargins);
            }
            _currentStyle = angular.fastCopy(newStyle);
        } else if (
            newStyle.isDefault &&
            (angular.isUndefinedOrEmpty(widgetStyle) ||
                angular.isUndefinedOrEmpty(
                    [widgetStyle.global, widgetStyle.header, widgetStyle.content, widgetStyle.footer],
                    'every',
                ))
        ) {
            widgetRoot.widget.style = newStyle.id;
            widgetRoot.widget.properties.style = angular.fastCopy(newStyle.properties);

            _currentStyle = angular.fastCopy(newStyle);
        }

        Widget.initStyleParams(widgetRoot.widget, get(widgetRoot.cell, 'properties.plain'));
    }

    /**
     * Check if the widget is collapsible.
     * If the option is undefined, it's an old widget and we accept it can be collapsed.
     *
     * @return {boolean} Wether the widget is collapsible or not.
     */
    function _shouldBeCollapsed() {
        return get(widgetRoot, 'widget.properties.isCollapsible', true);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if content is in designer mode.
     *
     * @return {boolean} If content is in designer mode or not.
     */
    function designerMode() {
        // TODO [Matthias]: refactor into isDesignerMode.
        return angular.isDefined(Content.getAction()) && Content.getAction() !== 'get';
    }

    /**
     * Get list key.
     *
     * @return {string} The list key.
     */
    function getListKey() {
        let listKey = widgetRoot.listkey;

        if (angular.isDefinedAndFilled(widgetRoot.listKeySuffix)) {
            listKey += widgetRoot.listKeySuffix;
        }

        return listKey;
    }

    /**
     * Get widget classes according to its properties.
     *
     * @param {Array} childWidgetClasses The specific widget classes to append to common classes.
     */
    function getWidgetClass(childWidgetClasses) {
        const properties = get(widgetRoot, 'widget.properties', {});

        // Widget size.
        if (angular.isDefinedAndFilled(widgetRoot.widgetSize)) {
            childWidgetClasses.push(`widget--${widgetRoot.widgetSize}`);
        }

        if (angular.isDefined(properties.fullHeight) && properties.fullHeight) {
            childWidgetClasses.push('widget--is-full-height');
        }

        // Widget can be collapsed.
        if (_shouldBeCollapsed() && widgetRoot.shouldDisplayWidgetHeader()) {
            childWidgetClasses.push('widget--is-collapsible');
        }

        // Widget closed.
        if (
            widgetRoot.shouldDisplayWidgetHeader() &&
            get(properties, 'isClosed') &&
            _shouldBeCollapsed() &&
            !Utils.isDesignerMode()
        ) {
            childWidgetClasses.push('widget--is-folded');
        }

        // Widget classes.
        if (angular.isDefinedAndFilled(properties.class)) {
            const widgetClasses = properties.class.split(',');

            angular.forEach(widgetClasses, function forEachWidgetClasses(widgetclass) {
                if (angular.isDefinedAndFilled(widgetclass)) {
                    childWidgetClasses.push(`widget--${widgetclass.trim()}`);
                }
            });
        }

        // Widget title.
        if (widgetRoot.getWidgetTranslation(widgetRoot.widget.title)) {
            childWidgetClasses.push('widget--has-header');
        }

        const moreProperties = get(properties, 'more', {});
        // Widget link.
        if (
            angular.isDefinedAndFilled(moreProperties) &&
            widgetRoot.getWidgetTranslation(moreProperties.label) &&
            widgetRoot.getWidgetTranslation(moreProperties.link)
        ) {
            childWidgetClasses.push('widget--has-footer');
        }

        const globalStyles = get(properties, 'style.main', {});
        // Widget border top.
        if (angular.isDefinedAndFilled(globalStyles.borderTopWidth)) {
            childWidgetClasses.push('widget--has-border-top');
        }

        // Widget border bottom.
        if (angular.isDefinedAndFilled(globalStyles.borderBottomWidth)) {
            childWidgetClasses.push('widget--has-border-bottom');
        }

        // Widget theme.
        const contentStyles = get(properties, 'style.content', {});
        if (angular.isDefinedAndFilled(contentStyles.theme)) {
            childWidgetClasses.push(`widget--theme-${contentStyles.theme}`);
        } else {
            childWidgetClasses.push('widget--theme-light');
        }

        // Widget global style.
        if (angular.isDefinedAndFilled(_widgetGlobalStyleName)) {
            childWidgetClasses.push(`widget--shared-${Utils.slugify(_widgetGlobalStyleName)}`);
        }
    }

    /**
     * Return the override path of a widget to implement an "extend like" method.
     *
     * @return {string} The widget custom path.
     */
    function getWidgetCustomPath() {
        return `/client/front-office/specifics/overrides/${
            Instance.getInstance().override
        }/modules/content/modules/widget/modules/widget-${widgetRoot.backwardCompatWidgetType}/views/widget-${
            widgetRoot.backwardCompatWidgetType
        }-custom-section.html`;
    }

    /**
     * Get widget global style according to widget properties.
     *
     * @return {Array} The widget style.
     */
    function getWidgetStyle() {
        const mainStyle = get(widgetRoot.widget.properties.style, 'main', {});

        if (
            !get(widgetRoot.cell, 'properties.plain', false) &&
            angular.isUndefinedOrEmpty(mainStyle.shadowElevation) &&
            angular.isDefinedAndFilled(mainStyle.shadowOpacity)
        ) {
            mainStyle.defaultShadowElevation = _DEFAULT_SHADOW_ELEVATION;
        } else {
            delete mainStyle.defaultShadowElevation;
        }

        return Style.adjustShadow(Media.adjustBackgroundImage(omit(mainStyle, _MAIN_MARGIN_PROPERTIES)));
    }

    /**
     * Get widget margin according to its properties.
     *
     * @return {Object} The widget margin.
     */
    function getWidgetMargin() {
        if (Layout.breakpoint !== 'desk') {
            return {};
        }

        return pick(get(widgetRoot, 'widget.properties.style.main', {}), _MAIN_MARGIN_PROPERTIES);
    }

    /**
     * Get lang according to context and no fallback param translate content in input language in edit mode
     * translate content according to preferred / fallback user langs or according to current language if no
     * fallback param is defined.
     *
     * @param  {string} stringToCheck The string to check.
     * @return {string} The translated string.
     */
    function getWidgetTranslation(stringToCheck) {
        const properties = get(widgetRoot.widget, 'properties', {});

        return Widget.getWidgetTranslation(stringToCheck, properties.noFallback);
    }

    /**
     * Set focus and active states to widget parent components on settings open.
     */
    function openWidgetSettings() {
        Row.setCurrent(_widgetParents[0]);
        Row.isFocused = true;
        Row.isActive = false;

        Cell.setCurrent(_widgetCell);
        Cell.isFocused = true;
        Cell.isActive = false;

        const currentWidget = Widget.getCurrent();
        Widget.setCurrent(widgetRoot.widget);
        Widget.isFocused = false;

        if (currentWidget === widgetRoot.widget) {
            Widget.isActive = true;
        } else {
            Widget.isActive = false;
            $timeout(function timedOut() {
                Widget.isActive = true;
            });
        }

        Style.setCurrent(_currentStyle, 'widget');

        $rootScope.$broadcast('open-widget-settings', Widget.getCurrent().uuid);
    }

    /**
     * Set listkey.
     *
     * @param {string} listKey The list key.
     */
    function setListKey(listKey) {
        widgetRoot.listkey = listKey;
    }

    /**
     * Set widget size according to its parent cell width.
     *
     * @param {number} widgetWidth The widget width.
     */
    function setWidgetSize(widgetWidth) {
        widgetWidth = angular.isUndefinedOrEmpty(widgetWidth) ? $element.outerWidth() : widgetWidth;

        const oldWidgetSize = widgetRoot.widgetSize;

        if (widgetWidth <= _MAX_S_WIDGET_SIZE && oldWidgetSize !== 's') {
            widgetRoot.widgetSize = 's';
        } else if (widgetWidth > _MAX_S_WIDGET_SIZE && widgetWidth <= _MAX_M_WIDGET_SIZE && oldWidgetSize !== 'm') {
            widgetRoot.widgetSize = 'm';
        } else if (widgetWidth > _MAX_M_WIDGET_SIZE && oldWidgetSize !== 'l') {
            widgetRoot.widgetSize = 'l';
        }
    }

    /**
     * Check if we should display the widget footer.
     * The footer is displayed only if there is a `more` (label and link).
     *
     * @return {boolean} If the widget footer should be displayed.
     */
    function shouldDisplayWidgetFooter() {
        const more = get(widgetRoot.widget, 'properties.more');
        if (angular.isUndefinedOrEmpty(more) || angular.isUndefinedOrEmpty([more.label, more.link], 'some')) {
            return false;
        }

        return angular.isDefinedAndFilled(
            [widgetRoot.getWidgetTranslation(more.label), widgetRoot.getWidgetTranslation(more.link)],
            'every',
        );
    }

    /**
     * Check if we should display the widget header.
     * The header is displayed only if there is a `title`.
     *
     * @return {boolean} If the widget header should be displayed.
     */
    function shouldDisplayWidgetHeader() {
        const title = get(widgetRoot.widget, 'title');

        return Widget.shouldDisplayWidgetHeader(title);
    }

    /**
     * Toggle the widget visibility for the connected user.
     */
    function updateWidgetVisiblityForUser() {
        if (angular.isUndefined(widgetRoot.widget.properties.isHiddenByUser)) {
            widgetRoot.widget.properties.isHiddenByUser = true;
        } else {
            widgetRoot.widget.properties.isHiddenByUser = !widgetRoot.widget.properties.isHiddenByUser;
        }

        if (Content.getAction() === 'get') {
            ContentTemplate.saveUserTemplate(Content.getCurrent(), User.getConnected());
        }
    }

    /////////////////////////////

    widgetRoot.designerMode = designerMode;
    widgetRoot.getListKey = getListKey;
    widgetRoot.getWidgetClass = getWidgetClass;
    widgetRoot.getWidgetCustomPath = getWidgetCustomPath;
    widgetRoot.getWidgetMargin = getWidgetMargin;
    widgetRoot.getWidgetStyle = getWidgetStyle;
    widgetRoot.getWidgetTranslation = getWidgetTranslation;
    widgetRoot.openWidgetSettings = openWidgetSettings;
    widgetRoot.setListKey = setListKey;
    widgetRoot.setWidgetSize = setWidgetSize;
    widgetRoot.shouldDisplayWidgetFooter = shouldDisplayWidgetFooter;
    widgetRoot.shouldDisplayWidgetHeader = shouldDisplayWidgetHeader;
    widgetRoot.updateWidgetVisiblityForUser = updateWidgetVisiblityForUser;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Add the scope information to the element when asked for.
     *
     * @param {Event}  evt  The scope info request event
     * @param {string} type The name of the scope info wanted.
     */
    $scope.$on('add-scope-info', function onAddScopeInfoRequest(evt, type) {
        if (type === `widgets.${get(widgetRoot, 'widget.uuid')}` && !_scopeInfoAdded && !ENABLE_ANGULAR_DEBUG_INFO) {
            Utils.addScopeInfo(type, $scope, false, false);
            _scopeInfoAdded = true;
        }
    });

    /**
     * Recompile the widget style when needed.
     *
     * @param {Event}  evt      The widget style event.
     * @param {Object} newStyle The style to apply.
     */
    $scope.$on('widget-style', function applyStyle(evt, widgetId, newStyle) {
        if (widgetId === widgetRoot.widget.uuid) {
            _applyStyle(newStyle);
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        widgetRoot.isHidden = widgetRoot.isHidden || false;

        if(!includes(Object.values(window.WIDGET_TYPES), widgetRoot.widget.widgetType)) {
            widgetRoot.isHidden = true;
        }

        const { widget } = widgetRoot;

        const scopeInfo = get(SCOPE_INFO, `widgets.${widget.uuid}`);
        if (!_scopeInfoAdded && !ENABLE_ANGULAR_DEBUG_INFO && angular.isDefinedAndFilled(scopeInfo)) {
            const selector = angular.isString(scopeInfo) ? scopeInfo : `.widget-${widget.uuid}`;

            Utils.addScopeInfo(selector, $scope, false, false);
            _scopeInfoAdded = true;
        }

        // Reset current style.
        Style.setCurrent(undefined, 'widget');

        // Initialize list key.
        const relatedContent = Content.getCurrent() || widgetRoot.content;
        const listKeyPrefix = `widget-${widget.uuid || widgetRoot.content.id}-`;

        widgetRoot.setListKey(
            listKeyPrefix + (angular.isDefined(get(relatedContent, 'id')) ? relatedContent.id : generateUUID()),
        );

        // Initialize widget parents.
        if (angular.isDefined(Content.getCurrent())) {
            _widgetParents = Widget.getParents(Content.getCurrent().template, widget);
        }

        // Initialize widget cell.
        if (angular.isDefinedAndFilled(_widgetParents)) {
            _widgetCell = _widgetParents[_widgetParents.length - 1];
        }

        // Initialize widget properties.
        if (angular.isUndefined(widget.properties)) {
            widget.properties = {};
            widget.properties.isCollapsible = false;
        }

        // Backward to keep the old widget collapsible by default (before the introduction of 'widget.properties.isCollapsible').
        if (angular.isUndefined(get(widget, 'properties.isCollapsible'))) {
            widget.properties.isCollapsible = true;
        }

        if (angular.isDefinedAndFilled(get(widget, 'properties.style'))) {
            // Remove all style properties with undefined or null value.
            widget.properties.style = _cleanAllFalsyStyleProperties(widget.properties.style);
        }

        if (angular.isDefinedAndFilled(Content.getCurrent())) {
            const style = angular.isDefinedAndFilled(widget.style)
                ? Style.get(widget.style, undefined, undefined, 'widget')
                : undefined;

            if (angular.isDefinedAndFilled(style)) {
                // Apply global style properties to the widget.
                _applyStyle(Style.cleanWidgetGlobalStyle(style));
            } else {
                // Get default style and apply it if widget has no style.
                const defaultStyle = loFind(Style.displayList('widget'), {
                    isDefault: true,
                });
                if (angular.isDefinedAndFilled(defaultStyle)) {
                    _applyStyle(defaultStyle);
                } else {
                    Widget.initStyleParams(widgetRoot.widget, get(widgetRoot.cell, 'properties.plain'));
                }
            }
        } else {
            Widget.initStyleParams(widgetRoot.widget, get(widgetRoot.cell, 'properties.plain'));
        }
    }

    init();
}

/////////////////////////////

function WidgetDirective($compile, $timeout, $window, Content) {
    'ngInject';

    /**
     * The list of widget type names that support the abstract widget list.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _WIDGET_LIST_TYPES = [
        'community-list',
        'content-list',
        'directory-entry-list',
        'file-list',
        'instance-list',
        'post-list',
        'sharepoint-site-list',
        'user-list',
    ];

    /**
     * The list of widget types that need to listen to size changes.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _WIDGET_LISTENING_SIZE = _WIDGET_LIST_TYPES.concat([]);

    /////////////////////////////

    /**
     * Make the widget compute its size.
     *
     * @param {Scope}      scope      The scope of the widget directive.
     * @param {jQElement}  el         The widget element.
     * @param {Controller} ctrl       The widget directive controller.
     * @param {string}     widgetType The type of the widget.
     */
    function _computeWidgetSize(scope, el, ctrl, widgetType) {
        if (includes(_WIDGET_LISTENING_SIZE, widgetType)) {
            scope.$emit('widget-size-update');

            return;
        }

        ctrl.setWidgetSize(el.outerWidth());
    }

    /////////////////////////////

    function link(scope, el, attrs, ctrl) {
        const widgetCompatType = scope.widgetRoot.backwardCompatWidgetType;
        let widgetType = widgetCompatType;
        let classes = 'widget';
        let backwardCompatAttr = '';
        const widgetPascalName = upperFirst(camelCase(widgetType));

        if (includes(Object.keys(ReactWidgets), `Widget${widgetPascalName}`)) {
            classes = '';
        } else if (includes(_WIDGET_LIST_TYPES, widgetType)) {
            classes += ' widget-list';
            backwardCompatAttr = ` backward-compatible-type="${widgetType}"`;
            widgetType = 'list';
        } else {
            classes += ` widget-${widgetType} widget-${scope.widgetRoot.widget.uuid}`;
        }

        const compiledWidget = $compile(
            `<widget-${widgetType} widget="widgetRoot.widget"${backwardCompatAttr} class="${classes}"></widget-${widgetType}>`,
        )(scope);

        const timers = [];

        el.append(compiledWidget).on('click', function onWidgetClick(clickEvent) {
            if (Content.getAction() && Content.getAction() !== 'get' && Content.getViewMode() !== 'locked') {
                if(Content.getViewMode() !== 'basic') {
                    clickEvent.stopPropagation();
                }
                scope.$apply(ctrl.openWidgetSettings);
            }
        });

        // eslint-disable-next-line func-style
        const delayComputeWidgetSize = function delayComputeWidgetSize() {
            _computeWidgetSize(scope, el, ctrl, widgetCompatType);
        };

        scope.$on('cell-add', function onCellAdd() {
            timers.push($timeout(delayComputeWidgetSize));
        });

        scope.$on('cell-resize', function onCellResize() {
            timers.push($timeout(delayComputeWidgetSize));
        });

        scope.$on('designer-component__delete', function onComponentDelete() {
            timers.push($timeout(delayComputeWidgetSize));
        });

        angular.element($window).on('resize', function onWindowResize() {
            timers.push($timeout(delayComputeWidgetSize));
        });

        timers.push($timeout(delayComputeWidgetSize));

        scope.$on('$destroy', function onDestroy() {
            el.off();

            angular.forEach(timers, function forEachTimer(timer) {
                $timeout.cancel(timer);
            });
        });
    }

    return {
        bindToController: true,
        controller: WidgetController,
        controllerAs: 'widgetRoot',
        link,
        replace: true,
        restrict: 'E',
        scope: {
            cell: '=',
            content: '=?',
            customizationDisabled: '=',
            isHidden: '=?',
            listKeySuffix: '@?',
            widget: '=',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('widget', WidgetDirective);

/////////////////////////////

export { WidgetController, WidgetDirective };
