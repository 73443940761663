import { FeedArticle } from '../FeedArticle';
import { FeedInfiniteTrigger } from '../FeedInfiniteTrigger';
import { Feed as BaseFeed } from './Feed';

/**
 * Component to build a "Feed" type pattern.
 * A feed is a section of a page that automatically loads new sections of content as the user scrolls.
 *
 * This component is based on the WAI ARIA Feed pattern and includes the correct keyboard shortcuts.
 * https://www.w3.org/WAI/ARIA/apg/patterns/feed/
 *
 * The Feed component family includes:
 * - `Feed`: The overall Feed wrapper. (ARIA `feed`). This will get all the information relative to the
 * asynchronous state and actions.
 * - `Feed.Article`: An individual article within a feed. Can be overridden with any component. (ARIA `article`)
 *
 * @family Feed
 */
export const Feed = Object.assign(BaseFeed, {
    /**
     * Article within a Feed.
     * Defaults to an `article` html element, but it can be customized with the `as` prop.
     *
     * @family Feed
     */
    Article: FeedArticle,
    /** Adds an infinite scroll trigger if the current index matches the configured sensitivity */
    InfiniteTrigger: FeedInfiniteTrigger,
});
