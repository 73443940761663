import BaseApi from '@lumapps/base-api';

export const acknowledgeReadsApi = (contentId: string) =>
    new BaseApi({
        path: `contents/${contentId}/acknowledge-reads`,
        version: BaseApi.versions.v2,
    });

export const fetchContentReadState = (contentId: string) => acknowledgeReadsApi(contentId).get('/me');

export const markContentAsRead = (contentId: string) => acknowledgeReadsApi(contentId).put('/me', {});

export const exportAcknowledgeReadsCSV = (contentId: string) => acknowledgeReadsApi(contentId).get('/export', {});
