/* istanbul ignore file */
import React, { useEffect } from 'react';

import { SideNavigationSection as Section } from '@lumapps/lumx-back-office/components/SideNavigation';
import { useTranslate } from '@lumapps/translations';

import { SA_NAVIGATION } from '../../keys';
import { ProgramsSideNavigation as Programs } from './ProgramsSideNavigation';
import { SocialNetworksSideNavigation as SocialNetworks } from './SocialNetworksSideNavigation';

export interface AdminSideNavigationProps {
    isSAEnabled: boolean;
    getUser: any;
    shouldGetUser: boolean;
}

const AdminSideNavigation = (props: Partial<AdminSideNavigationProps>) => {
    const { isSAEnabled, getUser, shouldGetUser } = props;

    const { translateKey } = useTranslate();

    useEffect(() => {
        if (isSAEnabled && shouldGetUser) {
            getUser();
        }
    }, [isSAEnabled, shouldGetUser, getUser]);

    if (!isSAEnabled) {
        return null;
    }

    return (
        <Section header={translateKey(SA_NAVIGATION.SOCIAL_ADVOCACY)} id="social-advocacy">
            <Programs />
            <SocialNetworks />
        </Section>
    );
};

AdminSideNavigation.displayName = 'SideNavigationItem';

export { AdminSideNavigation };
