import BaseApi from '@lumapps/base-api';
import { get } from '@lumapps/constants';

const Config = get();

const tokenApi = new BaseApi({ path: '', baseURL: `${Config.applicationHost}/service/user` });

/**
 * Refresh user token.
 *
 * @return {BaseApiPromise} The request promise.
 */
const refreshToken = (customerId: string) => {
    return tokenApi.post('token', { customerId }).then((response) => {
        if (response.status === 200 && response.data.status) {
            return response.data.token;
        }

        return response;
    });
};

export { refreshToken, tokenApi };
