// https://github.com/lumapps/core/blob/master/specs/bookmark_api_v1.yaml

import {
    createUserBookmark,
    GetUserBookmarkParams,
    listUserBookmarks,
    ListUserBookmarksParams,
    getUserBookmark,
    deleteBookmarkItem,
    CreateUserBookmarkParams,
    DeleteUserBookmarkItemParams,
} from '@lumapps/user-bookmarks/api';
import { userProfileApi } from '@lumapps/user-profile/api';

const listSavedItems = (params: ListUserBookmarksParams) => listUserBookmarks('saved-items', params);
const getSavedItem = (params: GetUserBookmarkParams) => getUserBookmark('saved-items', params);
const createSavedItem = (params: CreateUserBookmarkParams) => createUserBookmark('saved-items', params);
const deleteSavedItem = (params: DeleteUserBookmarkItemParams) => deleteBookmarkItem('saved-items', params);

export { userProfileApi as savedItemsApi, listSavedItems, getSavedItem, createSavedItem, deleteSavedItem };
