import React from 'react';

import {
    FormUploadDocumentField,
    FormUploadDocumentFieldProps,
} from '@lumapps/lumx-forms/components/FormUploadDocumentField';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { useDirectoryEntryImageProps } from '../../hooks/useDirectoryEntryImageProps';

export const FormDirectoryEntryImage: React.FC<Omit<FormUploadDocumentFieldProps, 'name' | 'label'>> = (props) => {
    const directoryEntryImageProps = useDirectoryEntryImageProps(props);
    const { translateKey } = useTranslate();

    return (
        <FormUploadDocumentField
            {...directoryEntryImageProps}
            name="thumbnail"
            label={translateKey(GLOBAL.THUMBNAIL)}
        />
    );
};
