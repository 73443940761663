/* istanbul ignore file */
import unionBy from 'lodash/unionBy';

import { success } from '@lumapps/notifications';

import {
    getAllSocialNetworkCapabilities as getAllSocialNetworkCapabilitiesApiCall,
    fetchSocialNetworksSettings as getAllSocialNetworkConfigurationsApiCall,
    getSocialNetworkData as getSocialNetworkDataApiCall,
    updateSocialNetworkData as updateSocialNetworkDataApiCall,
} from '../api';
import { SOCIAL_NETWORKS } from '../keys';

const DOMAIN = 'SA/SOCIAL_NETWORKS';

const GET_ALL_SOCIAL_NETWORKS = `${DOMAIN}/GET_ALL_SOCIAL_NETWORKS`;

const TOGGLE_OPEN_CUSTOM_SETTINGS = `${DOMAIN}/TOGGLE_OPEN_CUSTOM_SETTINGS`;

const UPDATE_SOCIAL_NETWORK = `${DOMAIN}/UPDATE_SOCIAL_NETWORK`;

const SET_FACEBOOK_SDK_READY = `${DOMAIN}/SET_FACEBOOK_SDK_READY`;

const TOGGLE_ADVANCED_OPTIONS_DROPDOWN = `${DOMAIN}/TOGGLE_ADVANCED_OPTIONS_DROPDOWN`;

const UPDATE_FORM = `${DOMAIN}/UPDATE_FORM`;

const SET_FORM_FIELD_ERROR = `${DOMAIN}/SET_FORM_FIELD_ERROR`;

const getAllSocialNetworks = () => (dispatch) => {
    Promise.all([getAllSocialNetworkConfigurationsApiCall(), getAllSocialNetworkCapabilitiesApiCall()]).then(
        (result) => {
            const [{ data: socialNetworks }, { data: capabilities }] = result;

            dispatch({
                socialNetworks: unionBy(socialNetworks || [], capabilities || [], 'socialNetworkId'),
                type: GET_ALL_SOCIAL_NETWORKS,
            });
        },
    );
};

const getSocialNetworkData = (id) => (dispatch) => {
    getSocialNetworkDataApiCall(id).then(({ data }) => {
        dispatch({
            socialNetwork: { socialNetworkId: id, ...data },
            type: UPDATE_SOCIAL_NETWORK,
        });
    });
};

const toggleOpenCustomSettings = (id) => (dispatch) => {
    dispatch({ current: id, type: TOGGLE_OPEN_CUSTOM_SETTINGS });
};

const activateSocialNetwork = (id) => (dispatch) => {
    updateSocialNetworkDataApiCall(id, true, false).then(({ data }) => {
        dispatch({
            socialNetwork: { socialNetworkId: id, ...data },
            type: UPDATE_SOCIAL_NETWORK,
        });

        dispatch(
            success({
                translate: {
                    key: SOCIAL_NETWORKS.ACTIVATION_SUCCESS,
                    replacements: {
                        PLATFORM: id,
                    },
                },
            }),
        );
    });
};

const activateSocialNetworkUsingCustomApp = (id, clientID, clientStatus) => (dispatch) => {
    updateSocialNetworkDataApiCall(id, true, true, clientID, clientStatus).then(({ data }) => {
        dispatch({
            socialNetwork: { socialNetworkId: id, ...data },
            type: UPDATE_SOCIAL_NETWORK,
        });

        dispatch(
            success({
                translate: {
                    key: SOCIAL_NETWORKS.ACTIVATION_SUCCESS,
                    replacements: {
                        PLATFORM: id,
                    },
                },
            }),
        );
    });
};

const deactivateSocialNetwork = (id) => (dispatch) => {
    updateSocialNetworkDataApiCall(id, false, false).then(({ data }) => {
        dispatch({
            socialNetwork: { socialNetworkId: id, ...data },
            type: UPDATE_SOCIAL_NETWORK,
        });

        dispatch(
            success({
                translate: {
                    key: SOCIAL_NETWORKS.DEACTIVATION_SUCCESS,
                    replacements: {
                        PLATFORM: id,
                    },
                },
            }),
        );
    });
};

const toggleAdvancedOptions = (id, isOpen) => {
    return {
        id,
        isOpen,
        type: TOGGLE_ADVANCED_OPTIONS_DROPDOWN,
    };
};

/**
 * Set form field value.
 *
 * @param  {string}   fieldId Field id.
 * @param  {Object}   field   Field object.
 * @return {Function} Dispatch actions.
 */
const setFormFieldValue = (fieldId, field) => (dispatch) => {
    const validatedForm = {
        fields: {
            [fieldId]: {
                ...field,
                wasTouched: true,
            },
        },
        wasTouched: true,
    };

    return dispatch({
        payload: { form: validatedForm },
        type: UPDATE_FORM,
    });
};
/**
 * Set form field error
 * @param  {string}   fieldId      Field id.
 * @param  {string}   errorMessage Error message.
 * @return {Function} Dispatch actions.
 */
const setFormFieldError = (fieldId, errorMessage) => (dispatch) =>
    dispatch({
        payload: { errorMessage, fieldId },
        type: SET_FORM_FIELD_ERROR,
    });

export {
    getAllSocialNetworks,
    getSocialNetworkData,
    toggleOpenCustomSettings,
    activateSocialNetwork,
    activateSocialNetworkUsingCustomApp,
    deactivateSocialNetwork,
    toggleAdvancedOptions,
    setFormFieldValue,
    setFormFieldError,
    GET_ALL_SOCIAL_NETWORKS,
    TOGGLE_OPEN_CUSTOM_SETTINGS,
    UPDATE_SOCIAL_NETWORK,
    SET_FACEBOOK_SDK_READY,
    TOGGLE_ADVANCED_OPTIONS_DROPDOWN,
    UPDATE_FORM,
    SET_FORM_FIELD_ERROR,
};
