(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileActivityController(Config) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The action object holding the details of the activity action.
         *
         * @type {Object}
         */
        vm.action = undefined;

        /**
         * The name of the type of the social profile activity action.
         *
         * @type {string}
         */
        vm.actionType = undefined;

        /**
         * The type of content being featured in the activity.
         *
         * @type {string}
         */
        vm.contentType = undefined;

        /**
         * Indicates if we should keep styles intact for activities related to posts.
         *
         * @type {boolean}
         */
        vm.keepStyle = true;

        /**
         * The post fields.
         *
         * @type {Object}
         */
        vm.postFields = {
            attachments: true,
            comments: false,
            content: true,
            dates: true,
            metadata: true,
            title: true,
        };

        /**
         * Indicates the truncation length for a post.
         *
         * @type {number}
         */
        vm.truncate = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Config = Config;

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            if (vm.activity.create.count > 0) {
                vm.action = vm.activity.create;
                vm.actionType = 'create';
                vm.contentType = vm.activity.contentDetails.type;
            } else if (vm.activity.update.count > 0) {
                vm.action = vm.activity.update;
                vm.actionType = 'update';
                vm.contentType = vm.activity.contentDetails.type;
            } else if (vm.activity.comment.count > 0) {
                vm.action = vm.activity.comment;
                vm.actionType = 'comment';
                vm.contentType = vm.activity.contentDetails.type;

                vm.activity.contentDetails.latestComments = [];
                angular.forEach(_.get(vm.activity, 'comment.items', []), function forEachCommentItems(commentItem) {
                    if (angular.isUndefinedOrEmpty(commentItem.commentDetails)) {
                        return;
                    }

                    vm.activity.contentDetails.latestComments.unshift(commentItem.commentDetails);
                });
            } else if (vm.activity.like.count > 0) {
                vm.action = vm.activity.like;
                vm.actionType = 'like';
                vm.contentType = vm.activity.contentDetails.type;
            } else if (vm.activity.profile.count > 0) {
                vm.action = vm.activity.profile;
                vm.actionType = 'profile';
                vm.activity.contentDetails = undefined;
            }
        }

        init();
    }

    function SocialProfileActivityDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: SocialProfileActivityController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                activity: '<',
                displayInstance: '<?',
            },
            templateUrl: '/client/front-office/modules/social/profile/views/social-profile-activity.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('socialProfileActivity', SocialProfileActivityDirective);
})();
