export enum MICROAPP {
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382408835
     */
    ACTION_ICON_THUMBNAIL = 'PAGE.MICROAPP_DIALOG.ACTION_ICON_THUMBNAIL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410069064
     */
    AC_CONTEXT_DESC = 'PAGE.MICROAPP.AC_CONTEXT_DESC',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410068785
     */
    AC_CONTEXT_TITLE = 'PAGE.MICROAPP.AC_CONTEXT_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410069161
     */
    AC_CREATE_BUTTON = 'PAGE.MICROAPP.AC_CREATE_BUTTON',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410068316
     */
    AC_CREATION_TITLE = 'PAGE.MICROAPP.AC_CREATION_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410068599
     */
    AC_DESIGN_DESC = 'PAGE.MICROAPP.AC_DESIGN_DESC',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410068476
     */
    AC_DESIGN_TITLE = 'PAGE.MICROAPP.AC_DESIGN_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415545491
     */
    AC_EDITION_TITLE = 'PAGE.MICROAPP.AC_EDITION_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543179
     */
    AC_EXECUTION_CONTEXT_DESCRIPTION = 'PAGE.MICROAPP.AC_EXECUTION_CONTEXT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543167
     */
    AC_EXECUTION_CONTEXT_TITLE = 'PAGE.MICROAPP.AC_EXECUTION_CONTEXT_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=410068379
     */
    AC_NAME_HELPER = 'PAGE.MICROAPP.AC_NAME_HELPER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543261
     * */
    AC_PREVIEW_DESCRIPTION = 'PAGE.MICROAPP.AC_PREVIEW_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415545469
     */
    AC_PREVIEW_ERROR_MESSAGE = 'PAGE.MICROAPP.AC_PREVIEW_ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543268
     */
    AC_PREVIEW_NOT_AVAILABLE = 'PAGE.MICROAPP.AC_PREVIEW_NOT_AVAILABLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415545454
     */
    AC_PREVIEW_NOT_AVAILABLE_MESSAGE = 'PAGE.MICROAPP.AC_PREVIEW_NOT_AVAILABLE_MESSAGE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543185
     */
    AC_PREVIEW_TITLE = 'PAGE.MICROAPP.AC_PREVIEW_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543143
     */
    AC_STRUCTURE_DESCRIPTION = 'PAGE.MICROAPP.AC_STRUCTURE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543065
     */
    AC_STRUCTURE_TITLE = 'PAGE.MICROAPP.AC_STRUCTURE_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=417983047
     */
    ADAPTIVE_CARD_CREATED = 'MICROAPP.ADAPTIVE_CARD_CREATED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=413252595
     */
    ADAPTIVE_CARD_RENAMED = 'PAGE.MICROAPP.RENAME_ACTION_DIALOG.ADAPTIVE_CARD_RENAMED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=417983049
     */
    ADAPTIVE_CARD_SAVED = 'MICROAPP.ADAPTIVE_CARD_SAVED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=413252598
     */
    API_CALL_RENAMED = 'PAGE.MICROAPP.RENAME_ACTION_DIALOG.API_CALL_RENAMED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=417983051
     */
    API_CALL_CREATED = 'MICROAPP.API_CALL_CREATED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=417983056
     */
    API_CALL_SAVED = 'MICROAPP.API_CALL_SAVED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382646983
     */
    ADAPTIVE_CARD_DESCRIPTION = 'PAGE.MICROAPP_DIALOG.ADAPTIVE_CARD_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=415543159
     */
    ADAPTIVE_CARDS_DESIGNER = 'PAGE.MICROAPP.ADAPTIVE_CARDS_DESIGNER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382408865
     */
    ADD_ADAPTIVE_CARD_ACTION = 'PAGE.MICROAPP_DIALOG.ADD_ADAPTIVE_CARD_ACTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=424480692
     */
    ADD_FILTER = 'MICROAPP.ADD_FILTER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382408872
     */
    ADD_API_ACTION = 'PAGE.MICROAPP_DIALOG.ADD_API_ACTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513597177
     */
    ADD_INPUT_VARIABLE = 'PAGE.MICROAPP_ACTION_DIALOG.ADD_INPUT_VARIABLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382409098
     */
    ADD_ROUTER_ACTION = 'PAGE.MICROAPP_DIALOG.ADD_ROUTER_ACTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400114404
     */
    ADD_VALUE = 'PAGE.MICROAPP_ACTION_DIALOG.ADD_VALUE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382662002
     */
    API_ACTION_DESCRIPTION = 'PAGE.MICROAPP_DIALOG.API_ACTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=433180774
     */
    API_CALL_FAILED = 'MICROAPP.ACTION.API_CALL_FAILED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=433180766
     */
    API_CALL_SUCCESS = 'MICROAPP.ACTION.API_CALL_SUCCESS',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400094888
     */
    BODY = 'PAGE.MICROAPP_ACTION_DIALOG.BODY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563298065
     */
    CONFIRMATION_IMPORT_HELPER = 'MICROAPP.CONFIRMATION_IMPORT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563302714
     */
    CONFIRMATION_IMPORT_TITLE = 'MICROAPP.CONFIRMATION_IMPORT_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400285759
     */
    CREATE_API_ACTION = 'PAGE.MICROAPP_ACTION_DIALOG.CREATE_API_ACTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=444492790
     */
    CREATE_CONNECTOR = 'PAGE.MICROAPP_ACTION_DIALOG.CREATE_CONNECTOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565954733
     */
    CREATE_FUNCTION = 'MICROAPP.CREATE_FUNCTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596941
     */
    DEFAULT_VALUE_HELPER = 'PAGE.MICROAPP_ACTION_DIALOG.DEFAULT_VALUE_HELPER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596905
     */
    DEFAULT_VALUE_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.DEFAULT_VALUE_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596914
     */
    DEFAULT_VALUE_PLACEHOLDER = 'PAGE.MICROAPP_ACTION_DIALOG.DEFAULT_VALUE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596970
     */
    DESCRIPTION_HELPER = 'PAGE.MICROAPP_ACTION_DIALOG.DESCRIPTION_HELPER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596955
     */
    DESCRIPTION_PLACEHOLDER = 'PAGE.MICROAPP_ACTION_DIALOG.DESCRIPTION_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=416122318
     */
    DELETE_ADAPTIVE_CARD = 'PAGE.MICROAPP.DELETE_ADAPTIVE_CARD_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=416122323
     */
    DELETE_ADAPTIVE_CARD_MESSAGE = 'PAGE.MICROAPP.DELETE_ADAPTIVE_CARD_DIALOG.MESSAGE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=416122112
     */
    DELETE_API_CALL = 'PAGE.MICROAPP.DELETE_API_ACTION_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=416122309
     */
    DELETE_API_CALL_MESSAGE = 'PAGE.MICROAPP.DELETE_API_ACTION_DIALOG.MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565986801
     */
    DELETE_FUNCTION = 'MICROAPP.DELETE_FUNCTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565986802
     */
    DELETE_FUNCTION_MESSAGE = 'MICROAPP.DELETE_FUNCTION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567294756
     */
    DELETE_LUMAPPS_COMPONENT = 'MICROAPP.DELETE_LUMAPPS_COMPONENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567304396
     */
    DELETE_LUMAPPS_COMPONENT_MESSAGE = 'MICROAPP.DELETE_LUMAPPS_COMPONENT_MESSAGE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=418299966
     */
    DESIGN_MICROAPP = 'PAGE.EXTENSION_MODAL.DESIGN_MICROAPP',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=412197722
     */
    DISCARD_CHANGES = 'PAGE.MICROAPP_ACTION_DIALOG.DISCARD_CHANGES',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=412197647
     */
    DISCARD_MESSAGE = 'PAGE.MICROAPP_ACTION_DIALOG.DISCARD_MESSAGE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=412197552
     */
    DISCARD_TITLE = 'PAGE.MICROAPP_ACTION_DIALOG.DISCARD_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=563248252
     */
    DUPLICATE_IDENTIFIER = 'MICROAPP.DUPLICATE_INPUT_VARIABLE_IDENTIFIER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=403878445
     */
    EDIT_API_ACTION = 'PAGE.MICROAPP_ACTION_DIALOG.EDIT_API_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=566786821
     */
    EDIT_FUNCTION = 'MICROAPP.EDIT_FUNCTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=496156149
     */
    EDIT_MICROAPP = 'PAGE.MICROAPP.EDIT_MICROAPP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=511784263
     */
    EDIT_START_INPUTS = 'PAGE.MICROAPP.EDIT_START_INPUTS',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596026
     */
    EDIT_TRIGGER_ACTION = 'PAGE.MICROAPP_ACTION_DIALOG.EDIT_TRIGGER_ACTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596030
     */
    EDIT_TRIGGER_ACTION_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.EDIT_TRIGGER_ACTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400089467
     */
    EMPTY_RESPONSE_BUTTON = 'PAGE.MICROAPP_ACTION_DIALOG.EMPTY_RESPONSE_BUTTON',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400089456
     */
    EMPTY_RESPONSE_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.EMPTY_RESPONSE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400089462
     */
    EMPTY_RESPONSE_TITLE = 'PAGE.MICROAPP_ACTION_DIALOG.EMPTY_RESPONSE_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=463690698
     */
    ENDPOINT_PATH_HELPER = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_PATH_HELPER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=463690808
     */
    ENDPOINT_PATH_INVALID = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_PATH_INVALID',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=463690824
     */
    ENDPOINT_PATH_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_PATH_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=463690845
     */
    ENDPOINT_PATH_REQUIRED = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_PATH_REQUIRED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=455627025
     */
    ENDPOINT_URL_HELPER = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_URL_HELPER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=455627049
     */
    ENDPOINT_URL_INVALID = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_URL_INVALID',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400077885
     */
    ENDPOINT_URL_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_URL_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=455627038
     */
    ENDPOINT_URL_REQUIRED = 'PAGE.MICROAPP_ACTION_DIALOG.ENDPOINT_URL_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563303063
     */
    EXPORT_MICRO_APP = 'MICROAPP.EXPORT_MICRO_APP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563303271
     */
    EXTENDED_NOTIFICATION_CONTENT = 'MICROAPP.EXTENDED_NOTIFICATION_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563303721
     */
    EXTENDED_NOTIFICATION_TITLE = 'MICROAPP.EXTENDED_NOTIFICATION_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=537623379
     */
    FUNCTION_ACTION_DESCRIPTION = 'PAGE.MICROAPP_DIALOG.FUNCTION_ACTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=526388311
     */
    FUNCTION_TITLE = 'PAGE.MICROAPP_ACTION_DIALOG.FUNCTION_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=421367724
     */
    HEADER_NOT_ALLOWED = 'PAGE.MICROAPP_ACTION_DIALOG.HEADER_NOT_ALLOWED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382409101
     */
    HISTORY = 'PAGE.MICROAPP_DIALOG.HISTORY',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596867
     */
    IDENTIFIER_HELPER = 'PAGE.MICROAPP_ACTION_DIALOG.IDENTIFIER_HELPER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596478
     */
    IDENTIFIER_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.IDENTIFIER_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596857
     */
    IDENTIFIER_PLACEHOLDER = 'PAGE.MICROAPP_ACTION_DIALOG.IDENTIFIER_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563303740
     */
    IMPORT_FAILED_HELPER = 'MICROAPP.IMPORT_FAILED_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563304408
     */
    IMPORT_MICRO_APPS = 'MICROAPP.IMPORT_MICRO_APPS',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=513596470
     */
    INPUT_VARIABLE_TITLE = 'PAGE.MICROAPP_ACTION_DIALOG.INPUT_VARIABLE_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=514859320
     */
    INVALID_CHARACTER = 'PAGE.MICROAPP_ACTION_DIALOG.INVALID_CHARACTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565954544
     */
    FUNCTION_RENAMED = 'MICROAPP.FUNCTION_RENAMED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=409793711
     */
    LAST_SAVED_ON = 'PAGE.MICROAPP_DIALOG.LAST_SAVED_ON',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=414898103
     */
    LINK = 'PAGE.MICROAPP.LINK',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400092660
     */
    METHOD_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.METHOD_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563304443
     */
    MICRO_APP_EXPORTED = 'MICROAPP.MICRO_APP_EXPORTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563304459
     */
    MICRO_APP_IMPORTED = 'MICROAPP.MICRO_APP_IMPORTED',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400093520
     */
    NAME_HINT = 'PAGE.MICROAPP_ACTION_DIALOG.NAME_HINT',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=424647842
     */
    NEW_FILTER = 'MICROAPP.NEW_FILTER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=418923056
     */
    NEW_VERSION_MESSAGE = 'PAGE.NEW_MICROAPP_VERSION.MESSAGE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=413252701
     */
    NEXT = 'PAGE.MICROAPP_ACTION_DIALOG.NEXT',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=526388319
     */
    NO_TEST_RESULT_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.NO_TEST_RESULT_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=526388317
     */
    NO_TEST_RESULT_TITLE = 'PAGE.MICROAPP_ACTION_DIALOG.NO_TEST_RESULT_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400094886
     */
    PARAMETERS = 'PAGE.MICROAPP_ACTION_DIALOG.PARAMETERS',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400103385
     */
    HEADERS = 'PAGE.MICROAPP_ACTION_DIALOG.HEADERS',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400286784
     */
    PUBLIC_API = 'PAGE.MICROAPP_ACTION_DIALOG.PUBLIC_API',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=434901366
     */
    SELECT_NEW_PROVIDER_TEXT = 'PAGE.MICROAPP_ACTION_DIALOG.SELECT_NEW_PROVIDER_TEXT',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=434901466
     */
    NEW_PROVIDER_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.NEW_PROVIDER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=434913187
     */
    SELECT_PROVIDER_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.SELECT_PROVIDER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=434914707
     */
    NEW_PROVIDER_TITLE = 'PAGE.MICROAPP_ACTION_DIALOG.NEW_PROVIDER_TITLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=437665274
     */
    ROUTER_ACTION = 'PAGE.MICROAPP.CREATE_FILTER.OPERATOR',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400103356
     */
    QUERY_PARAMETERS = 'PAGE.MICROAPP_ACTION_DIALOG.QUERY_PARAMETERS',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=413252506
     */
    RENAME_ADAPTIVE_CARD = 'PAGE.MICROAPP.RENAME_ACTION_DIALOG.ADAPTIVE_CARD',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=413252450
     */
    RENAME_API_CALL = 'PAGE.MICROAPP.RENAME_ACTION_DIALOG.API_CALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565953996
     */
    RENAME_FUNCTION = 'MICROAPP.RENAME_FUNCTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=567294567
     */
    RENAME_LUMAPPS_COMPONENT = 'MICROAPP.RENAME_LUMAPPS_COMPONENT',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400088249
     */
    REQUEST_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.REQUEST_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=400088290
     */
    RESPONSE_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.RESPONSE_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382662826
     */
    ROUTER_ACTION_LABEL = 'PAGE.MICROAPP_DIALOG.ROUTER_ACTION_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=437665334
     */
    SELECT_VALUE = 'PAGE.MICROAPP.CREATE_FILTER.SELECT_VALUE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=437665331
     */
    SELECT_VARIABLE = 'PAGE.MICROAPP.CREATE_FILTER.SELECT_VARIABLE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=443599124
     */
    SET_AS_FALLBACK = 'PAGE.MICROAPP.LINK.SET_AS_FALLBACK',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=443670703
     */
    TEST_CONNECTOR_DROPDOWN_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.TEST_CONNECTOR_DROPDOWN_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=443721964
     */
    TEST_CONNECTOR_DROPDOWN_PLACEHOLDER = 'PAGE.MICROAPP_ACTION_DIALOG.TEST_CONNECTOR_DROPDOWN_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=443646121
     */
    TEST_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.TEST_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=526388327
     */
    TEST_FUNCTION_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.TEST_FUNCTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=443638724
     */
    TEST_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.TEST_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=382662829
     */
    TRIGGER_ACTION_LABEL = 'PAGE.MICROAPP_DIALOG.TRIGGER_ACTION_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=551432694
     */
    TRIGGER_ACTION_DESCRIPTION = 'PAGE.MICROAPP_DIALOG.TRIGGER_ACTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563304866
     */
    UPLOAD_FAILED = 'MICROAPP.UPLOAD_FAILED',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563304899
     */
    UPLOAD_HELPER = 'MICROAPP.UPLOAD_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563304916
     */
    UPLOAD_JSON_FILE = 'MICROAPP.UPLOAD_JSON_FILE',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=451082970
     */
    VALUES_DESCRIPTION = 'PAGE.MICROAPP_ACTION_DIALOG.VALUES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=451082954
     */
    VALUES_LABEL = 'PAGE.MICROAPP_ACTION_DIALOG.VALUES_LABEL',
    /**
     * https://app.lokalise.com/project/908197736419bbee2c4cd0.60810052/?k=437662974
     */
    VARIABLE = 'PAGE.MICROAPP.CREATE_FILTER.VARIABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560014702
     */
    LUMAPPS_COMPONENT = 'MICROAPP.LUMAPPS_COMPONENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560024902
     */
    SELECT_LUMAPPS_COMPONENT_TYPE = 'MICROAPP.SELECT_LUMAPPS_COMPONENT_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560026668
     */
    FILE_UPLOAD_COMPONENT = 'MICROAPP.FILE_UPLOAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560030879
     */
    FILE_UPLOAD_COMPONENT_DESCRIPTION = 'MICROAPP.FILE_UPLOAD_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560035810
     */
    CREATE_FILE_UPLOAD_COMPONENT = 'MICROAPP.CREATE_FILE_UPLOAD_COMPONENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=564719585
     */
    EDIT_FILE_UPLOAD_COMPONENT = 'MICROAPP.EDIT_FILE_UPLOAD_COMPONENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560046561
     */
    MANDATORY_FILE_UPLOAD_OPTION_TITLE = 'MICROAPP.MANDATORY_FILE_UPLOAD_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560046704
     */
    MANDATORY_FILE_UPLOAD_OPTION_DESCRIPTION = 'MICROAPP.MANDATORY_FILE_UPLOAD_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560047052
     */
    ALLOWED_FILE_TYPE_LABEL = 'MICROAPP.ALLOWED_FILE_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560047214
     */
    ALLOWED_FILE_TYPE_PLACEHOLDER = 'MICROAPP.ALLOWED_FILE_TYPE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560048854
     */
    LUMAPPS_COMPONENT_CREATED = 'MICROAPP.LUMAPPS_COMPONENT_CREATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=564719867
     */
    LUMAPPS_COMPONENT_SAVED = 'MICROAPP.LUMAPPS_COMPONENT_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560048948
     */
    MAXIMUM_FILE_SIZE_LABEL = 'MICROAPP.MAXIMUM_FILE_SIZE_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=560049185
     */
    MAXIMUM_FILE_SIZE_PLACEHOLDER = 'MICROAPP.MAXIMUM_FILE_SIZE_PLACEHOLDER',
}
