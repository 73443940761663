import React from 'react';

import { LumappsComponentProps } from '../../types';
import { components } from './components';
import { getLumappsComponent } from './LumappsComponent';

/**
 * Parent component for all Micro Apps Lumapps Components. Picks and renders an integrated component from a list of supported components we manually maintain.
 * @returns LumappsComponent
 */
export const LumappsComponent: React.FC<LumappsComponentProps> = ({ component, onSubmit }) => {
    const handleSubmit = (data?: Record<string, unknown>) => {
        onSubmit({ id: 'submit', data });
    };

    const handleSkip = () => {
        onSubmit({ id: 'skip' });
    };

    const Component = getLumappsComponent({ component, choices: components });
    return <Component onSubmit={handleSubmit} onSkip={handleSkip} component={component} />;
};
