import { useCallback, useState } from 'react';

import { error as notifyError } from '@lumapps/notifications';
import { useDispatch } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';

import * as postApi from '../../../api';
import { IdeaVote } from '../../../types';

export const usePostVote = ({
    initialVotes,
    postId,
}: {
    initialVotes: {
        downVotes: number;
        upVotes: number;
        userVote?: string;
    };
    postId: string;
}) => {
    const dispatch = useDispatch();
    const [votes, setVotes] = useState(initialVotes);
    const [isVoteLoading, setIsVoteLoading] = useState(false);

    const onVote = useCallback(
        async (vote: IdeaVote) => {
            setIsVoteLoading(true);
            try {
                const { data } = await postApi.vote(vote, postId);

                setVotes({
                    upVotes: data.score.votesUp,
                    downVotes: data.score.votesDown,
                    userVote: data.userVote,
                });

                setIsVoteLoading(false);
            } catch (error) {
                setIsVoteLoading(false);
                dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
            }
        },
        [dispatch, postId],
    );

    const onDeleteVote = useCallback(async () => {
        setIsVoteLoading(true);
        try {
            const { data } = await postApi.deleteVote(postId);

            setVotes({
                upVotes: data.score.votesUp,
                downVotes: data.score.votesDown,
                userVote: data.userVote,
            });

            setIsVoteLoading(false);
        } catch (error) {
            setIsVoteLoading(false);
            dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        }
    }, [dispatch, postId]);

    return {
        onVote,
        onDeleteVote,
        votes,
        isVoteLoading,
    };
};
