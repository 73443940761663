import React, { useCallback } from 'react';

import debounce from 'lodash/debounce';

import { useQueryClient } from '@lumapps/base-api/react-query';
import { error as notifyError } from '@lumapps/notifications';
import { useDispatch } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';

import { FetchToggleLike, reactions } from '../../api/query';
import { LikeState, ResourceRef } from '../../api/types';

interface State extends LikeState {
    isLoading?: boolean;
}

/** Handle async toggle like/unlike on V1 & V2 resource. */
export const useResourceLike = ({
    resourceRef,
    initiallyLiked,
    initialLikeCount,
    fetchToggleLike,
}: {
    resourceRef: ResourceRef<string>;
    initiallyLiked: boolean;
    initialLikeCount: number;
    fetchToggleLike: FetchToggleLike;
}) => {
    const dispatch = useDispatch();
    const [state, setState] = React.useState<State>({
        likeCount: initialLikeCount,
        isLiked: initiallyLiked,
    });

    React.useEffect(() => {
        setState({ ...state, isLiked: initiallyLiked });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiallyLiked]);

    const queryClient = useQueryClient();

    const makeRequest = useCallback(
        async (isLiked: boolean) => {
            try {
                await fetchToggleLike(resourceRef, isLiked).then(setState);
            } catch (e) {
                // Generic error notification
                dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));

                setState((state) => ({
                    // Rollback optimistic update
                    isLiked: !state.isLiked,
                    likeCount: state.likeCount + (state.isLiked ? -1 : 1),
                }));
            }

            // Invalidate like list query
            const queryKey = reactions.listLikesQueryKeys(resourceRef).filter(Boolean);
            queryClient.invalidateQueries({ queryKey });
        },
        [dispatch, fetchToggleLike, resourceRef, queryClient],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const launchRequest = useCallback(
        debounce((isLiked: boolean) => makeRequest(isLiked), 300),
        [],
    );

    const toggleLike = React.useCallback(async () => {
        setState((state) => ({
            // Optimistic update
            isLiked: !state.isLiked,
            likeCount: state.likeCount + (state.isLiked ? -1 : 1),
        }));
        await launchRequest(state.isLiked);
    }, [launchRequest, state.isLiked]);

    return { ...state, toggleLike };
};
