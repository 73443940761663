import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { Route } from '@lumapps/router';

import { CommunityLinkRef } from '../../types';
import { getCommunityRoute } from './getCommunityRoute';

const { isLegacyContext } = getConstants();

export function getCommunityLinkProps(
    to: CommunityLinkRef,
    options: { getUrl: (route: Route) => string; isSpaceFeatureEnabled: boolean; homeRoute: Route },
) {
    const { getUrl, isSpaceFeatureEnabled, homeRoute } = options;
    const { route } = getCommunityRoute(to, { isSpaceFeatureEnabled, homeRoute });

    const href = getUrl(route);
    const { appId, instanceSlug } = route;

    // If link is external (or on another instance) => target _self to force reload on legacy app.
    const target =
        (appId !== AppId.legacy && isLegacyContext) || instanceSlug || appId === AppId.external ? '_self' : undefined;

    return { href, target };
}
