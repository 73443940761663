import React, { useEffect } from 'react';

const EVENT_TYPES: string[] = ['mousedown', 'touchstart'];

/**
 * Listen to clicks away from a given element and callback the passed in function.
 *
 * @param  callback  A callback function to call when the user clicks away from the ref element.
 * @param  refs      Element references considered 'inside the element'.
 */
export function useClickAway(callback: EventListener, ...refs: Array<React.RefObject<HTMLElement> | HTMLElement>) {
    useEffect(
        () => {
            if (!callback || !refs.length) {
                return undefined;
            }

            const listener: EventListener = (evt) => {
                const isClickInside = refs.some((ref) => {
                    const element = ref instanceof HTMLElement ? ref : ref?.current;
                    return element && element.contains(evt.target as Node);
                });
                if (isClickInside) {
                    return;
                }

                // Is click away.
                callback(evt);
            };

            EVENT_TYPES.forEach((evtType) => document.addEventListener(evtType, listener));
            return () => {
                EVENT_TYPES.forEach((evtType) => document.removeEventListener(evtType, listener));
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [callback, ...refs],
    );
}
