import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiEmail } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { NEWSLETTER } from '../../keys';
import { admin } from '../../routes';

export interface NewsletterSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const NewsletterSideNavigation = (props: Partial<NewsletterSideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={admin()}
            label={translateKey(NEWSLETTER.NEWSLETTER_KEY)}
            icon={mdiEmail}
            {...restOfProps}
        />
    );
};

NewsletterSideNavigation.displayName = 'SideNavigationItem';

export { NewsletterSideNavigation };
