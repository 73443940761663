/* istanbul ignore file */

import { get as getConstants } from '@lumapps/constants';
import { canAccessContentAnalytics } from '@lumapps/content-analytics/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { isReportFeatureEnabled } from '@lumapps/reports/ducks/selectors';
import { AppId } from '@lumapps/router/constants';
import { getConnectedUserLanguage } from '@lumapps/user/ducks/selectors';
import { getTranslatableWidgets } from '@lumapps/widget-base/ducks/selectors';

import {
    getCurrentContentId,
    getCurrentContentStatus,
    getCurrentCustomContentTypeId,
    contentHasContextualActions,
    isContextualActionsEnabled,
    isInDesignerMode,
} from '../../ducks/selectors';
import { translateCurrentContentWithErrorNotification } from '../../ducks/thunks/translateCurrentContent';
import { ContentStatus } from '../../types';
import { ContentContextualActions, ContentContextualActionsProps } from './ContentContextualActions';

const constants = getConstants();

const mapStateToProps = (state: FrontOfficeStore) => {
    const currentStatus = getCurrentContentStatus(state);

    const props: ContentContextualActionsProps = {
        isEnabled:
            // Disabled on webview app
            constants.applicationId !== AppId.webview &&
            // FF enabled
            isContextualActionsEnabled(state) &&
            // Content type compatible
            contentHasContextualActions(state),
        canAccessContentAnalytics: canAccessContentAnalytics(state) && currentStatus === ContentStatus.live,
        canAccessReports: isReportFeatureEnabled(state) && currentStatus === ContentStatus.live,
        contentId: getCurrentContentId(state),
        contentTypeId: getCurrentCustomContentTypeId(state),
        isInDesignerMode: isInDesignerMode(state),
        userLanguage: getConnectedUserLanguage(state),
        currentLanguage: currentLanguageSelector(state),
        isHomePage: state.content.isHomepage,
    };

    /** In legacy app, the translation feature visibility is set by angular. */
    if (!constants.isLegacyContext) {
        props.isTranslationFeatureEnabled =
            isFeatureEnabled('translation-end-user')(state) && !!getTranslatableWidgets(state);
    }

    return props;
};

const mapDispatchToProps = {
    onTranslation: !constants.isLegacyContext && translateCurrentContentWithErrorNotification,
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(ContentContextualActions);

export { Connected as ContentContextualActions };
