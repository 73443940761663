import round from 'lodash/round';

import { encodeURIComponentSafe } from '../string/uriComponent';
import { OPEN_STREET_MAP_LAYERS } from './constants';
import { decodeOpenStreetMapUrl } from './decodeOpenStreetMapUrl';
import { getMapDirectionPlaces } from './getMapDirectionPlaces';
import { getMapWebsiteName } from './getMapWebsiteName';
import { Coordinates, OpenStreetMapLayersType } from './types';

/**
 * Get the coordinates based on a given map URL.
 *
 * @param  {string} mapUrl  The map URL.
 * @return {Coordinates} The found coordinates or undefined if none found.
 * Note: The coordiantes returned are not the real coordinates but the ones of the center of the map .
 */
export const getMapUrlCoordinates = (mapUrl: string): Coordinates | undefined => {
    const websiteName = getMapWebsiteName(mapUrl);

    // OpenStreetMap
    const openMapMatchedCoords = mapUrl.match('#map=[0-9]+/([-0-9.]+)/([-0-9.]+)');
    const openMapMatchedLayer = mapUrl.match('layers=([a-zA-Z])');
    const openMapPlaceCoords = decodeOpenStreetMapUrl(mapUrl);

    const gMapMatchedCoords = mapUrl.match('([-0-9.]+),([-0-9.]+)');

    // Google Map Place
    const gMapPlaceData = mapUrl.match('!1s([^!]+)!');
    const gMapPlaceName = mapUrl.match('(/place/)(.[^/]*)(?=/)');

    // Google Map Directions
    const gMapDirectionCoords = Array.from(mapUrl.matchAll(/!1d([-0-9.]+)!2d([-0-9.]+)/g), (m) => {
        return { lon: parseFloat(m[1]), lat: parseFloat(m[2]) };
    });
    const gMapDirectionNamesString = mapUrl.match('/[A-Z].+/(?=@)');
    const gMapDirectionNames = gMapDirectionNamesString
        ? Array.from(gMapDirectionNamesString[0].matchAll(/[^/][A-Za-z+.,0-9-]+/g), (m) => {
              return { name: encodeURIComponentSafe(m[0]) };
          })
        : undefined;
    const gMapDirectionData = Array.from(mapUrl.matchAll(/!1s([-0-9A-Za-z:.]+)(?=!2m2)/g), (m) => {
        return {
            data: encodeURIComponentSafe(m[1]),
        };
    });

    // Google Map Search
    const gMapSearchName = mapUrl.match('(/search/)(.[^/]*)(?=/)');

    // Get the coordinates depending of the website given
    switch (websiteName) {
        case 'googleMap':
            if (gMapMatchedCoords) {
                if (mapUrl.match('https://www.google.com/maps/place') && gMapPlaceData && gMapPlaceName) {
                    return {
                        lon: parseFloat(gMapMatchedCoords[1]),
                        lat: parseFloat(gMapMatchedCoords[2]),
                        place: {
                            data: encodeURIComponentSafe(gMapPlaceData[1]),
                            name: encodeURIComponentSafe(gMapPlaceName[2]),
                        },
                    };
                }
                if (
                    mapUrl.match('https://www.google.com/maps/dir') &&
                    gMapDirectionCoords &&
                    gMapDirectionData &&
                    gMapDirectionNames
                ) {
                    return {
                        lon: parseFloat(gMapMatchedCoords[1]),
                        lat: parseFloat(gMapMatchedCoords[2]),
                        place: getMapDirectionPlaces(gMapDirectionData, gMapDirectionNames, gMapDirectionCoords),
                    };
                }
                if (mapUrl.match('https://www.google.com/maps/search') && gMapSearchName) {
                    return {
                        lon: parseFloat(gMapMatchedCoords[1]),
                        lat: parseFloat(gMapMatchedCoords[2]),
                        place: {
                            name: encodeURIComponentSafe(gMapSearchName[2]),
                        },
                    };
                }
                return { lon: parseFloat(gMapMatchedCoords[1]), lat: parseFloat(gMapMatchedCoords[2]) };
            }
            return undefined;
        case 'openStreetMap':
            // Use the coordinates of a short link
            if (openMapPlaceCoords) {
                return {
                    lon: round(openMapPlaceCoords.lon, 5),
                    lat: round(openMapPlaceCoords.lat, 5),
                    layer: openMapMatchedLayer
                        ? OPEN_STREET_MAP_LAYERS[openMapMatchedLayer[1] as OpenStreetMapLayersType]
                        : 'mapnik',
                    place: openMapPlaceCoords,
                };
            }
            // Use the coordinates of a normal link
            if (openMapMatchedCoords) {
                return {
                    lon: parseFloat(openMapMatchedCoords[1]),
                    lat: parseFloat(openMapMatchedCoords[2]),
                    layer: openMapMatchedLayer
                        ? OPEN_STREET_MAP_LAYERS[openMapMatchedLayer[1] as OpenStreetMapLayersType]
                        : 'mapnik',
                };
            }
            return undefined;
        default:
            return undefined;
    }
};
