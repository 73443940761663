(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityTemplates($location, $log, Community, CommunityTemplatesFactory, CommunityWizardConfiguration,
        Content, Customer, Instance, LumsitesBaseService, LxNotificationService, Translation) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(CommunityTemplatesFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            postGet: _overwriteOriginalCommunityTemplate,
            // eslint-disable-next-line no-use-before-define
            postSave: _overwriteOriginalCommunityTemplate,
        });

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains an empty community template.
         *
         * @type {Object}
         * @constant
         */
        var _EMPTY_COMMUNITY_TEMPLATE = {
            customerId: undefined,
            instanceId: undefined,
            name: '',
            postStatuses: [],
            postTypes: CommunityWizardConfiguration.DEFAULT_POST_TYPES,
            privacy: Community.privacyTypes.restricted,
            tagsDetails: [],
            templates: [],
            uid: undefined,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The service's default request parameters.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /**
         * Contains the original loaded community template before any modification.
         *
         * @type {CommunityTemplate}
         */
        service.originalCommunityTemplate = undefined;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Clean anything that needs to be cleaned in the community template when checking for changes:
         *     - remove any empty array;
         *     - remove any empty object;
         *     - remove any "null" or "undefined";
         *     - remove any empty string;
         *     - remove any "false" object;
         *     - remove any empty metadata;
         *     - remove any weather attached to a widget weather;
         *
         * It is highly recommanded to pass a copy (angular.fastCopy) of the real content and to use these copies to
         * compare changes as the clean function will mutate your community template.
         *
         * @param {Object|Array} communityTemplate The community template to clean.
         */
        function _cleanForHasChanges(communityTemplate) {
            if (angular.isUndefinedOrEmpty(communityTemplate)) {
                return;
            }

            var propertiesToDelete = [
                'authorDetails',
                'createdAt',
                'endDateInput',
                'key',
                'startDateInput',
                'template',
                'updatedAt',
                'updatedByDetails',
                'userContent',
                'weather',
            ];

            angular.forEach(communityTemplate, function forEachCommunityTemplateProperties(value, property) {
                if (_.includes(propertiesToDelete, property) ||
                    // This will remove all the $XXX properties (usually angular related stuff).
                    (angular.isString(property) && property.charAt(0) === '$') ||
                    (angular.isObject(value) && value.type === 'widget' && value.isGlobal === true)) {
                    delete communityTemplate[property];

                    return;
                }

                if (angular.isObject(value) && angular.isDefinedAndFilled(value)) {
                    if (_.get(value, 'type') === 'cell' && _.get(value, 'properties.sticky') === true) {
                        delete value.properties.plain;
                    }

                    _cleanForHasChanges(value);
                }

                if (angular.isUndefinedOrEmpty(value) || value === false) {
                    delete communityTemplate[property];
                }
            });
        }

        /**
         * Overwrite the original community template.
         * Used after save and get.
         *
         * @param  {Object} communityTemplate The saved community template.
         * @return {Object} The saved community template.
         */
        function _overwriteOriginalCommunityTemplate(communityTemplate) {
            service.originalCommunityTemplate = angular.fastCopy(communityTemplate);

            return communityTemplate;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check the validity of the community template name for form validation.
         * A name is valid if it has at least one translation in one language.
         * Use to set the name field in error state.
         *
         * @param  {Object}  form The form we want to the check the validity of name of.
         * @return {boolean} If the name is valid or not.
         */
        function checkName(form) {
            if (angular.isUndefinedOrEmpty(_.get(form, 'name'))) {
                return true;
            }

            if (form.name.$pristine) {
                return false;
            }

            return form.name.$invalid;
        }

        /**
         * Check the validity of the whole community template.
         *
         * @return {booleab} If the community template is valid or not.
         */
        function checkCommunityTemplate() {
            var communityTemplate = service.getCurrent();

            if (angular.isUndefinedOrEmpty(communityTemplate)) {
                return false;
            }

            if (angular.isUndefinedOrEmpty(communityTemplate.name)) {
                LxNotificationService.error(Translation.translate('ERROR_NAME_MANDATORY'));

                return false;
            }

            return true;
        }

        /**
         * Create a new community template.
         */
        function createNew() {
            service.setCurrent(angular.fastCopy(_EMPTY_COMMUNITY_TEMPLATE));

            var currentCommunityTemplate = service.getCurrent();
            currentCommunityTemplate.customerId = Customer.getCustomerId();
            currentCommunityTemplate.instanceId = Instance.getCurrentInstanceId();
        }

        /**
         * Check if the community template has been changed.
         *
         * @return {boolean} If the community template has changed since it has been loaded.
         */
        function hasChanges() {
            var currentCommunityTemplate = service.getCurrent();

            var currentCommunityTemplateToCheck = angular.fastCopy(currentCommunityTemplate, undefined, true);

            if (angular.isDefinedAndFilled(currentCommunityTemplateToCheck)) {
                _cleanForHasChanges(currentCommunityTemplateToCheck);

                var originalCommunityTemplateToCheck =
                    angular.fastCopy(service.originalCommunityTemplate, undefined, true);

                _cleanForHasChanges(originalCommunityTemplateToCheck);

                var areEquals = angular.equals(originalCommunityTemplateToCheck, currentCommunityTemplateToCheck);
                if (!areEquals && $location.search().debug) {
                    console.group('Community template changes');
                    $log.debug(
                        'Original community template:',
                        originalCommunityTemplateToCheck, service.originalCommunityTemplate
                    );
                    $log.debug(
                        'Current community template:',
                        currentCommunityTemplateToCheck, currentCommunityTemplate
                    );
                    console.groupEnd();
                }

                return !areEquals;
            }

            return false;
        }

        /**
         * Check if the user has rights to modify the community template and its properties according to the community
         * template permissions.
         *
         * @param  {Object}  [communityTemplate] The community template object.
         * @return {boolean} If the community template is editable or not.
         */
        function isEditable(communityTemplate) {
            communityTemplate = communityTemplate || service.getCurrent();

            return angular.isDefinedAndFilled(communityTemplate) &&
                communityTemplate.instanceId === Instance.getCurrentInstanceId();
        }

        /**
         * Check if the user has rights to modify the community template and its properties according to the community
         * template permissions.
         *
         * @param  {Object}  community           The community object.
         * @param  {Object}  [communityTemplate] The community template object.
         * @return {boolean} If the community is an editable community template or not.
         */
        function isEditableForContent(community, communityTemplate) {
            communityTemplate = communityTemplate || service.getCurrent();

            return angular.isDefinedAndFilled(community) &&
                (community.type !== 'template' || Content.getAction() === 'create' ||
                (community.type === 'template' &&
                    (angular.isUndefinedOrEmpty(communityTemplate) || service.isEditable(communityTemplate))));
        }

        /////////////////////////////

        service.checkName = checkName;
        service.checkCommunityTemplate = checkCommunityTemplate;
        service.createNew = createNew;
        service.hasChanges = hasChanges;
        service.isEditable = isEditable;
        service.isEditableForContent = isEditableForContent;

        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                instanceId: Instance.getCurrentInstanceId(),
            };
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('CommunityTemplates', CommunityTemplates);
})();
