/**
 * RegExp matching EMAIL strings.
 */
export const EMAIL_REGEXP_WITH_PROTOCOL =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

/**
 * Returns `true` if the given string is an EMAIL.
 */
export const isValidEmail = (string: string | null | undefined) =>
    Boolean(string && Boolean(string.match(EMAIL_REGEXP_WITH_PROTOCOL)));
