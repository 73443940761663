import { useCallback, useMemo, useState } from 'react';

import { favorite } from '@lumapps/directories-favorites/api';
import { getDirectoryById } from '@lumapps/directories/ducks/selectors';
import { getEntry, saveUserEntry } from '@lumapps/directory-entries/api';
import { DirectoryEntryCreationDialogProps } from '@lumapps/directory-entries/components/DirectoryEntryCreationDialog';
import { DirectoryEntry } from '@lumapps/directory-entries/types';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { WidgetState } from '@lumapps/widget-base/ducks/type';
import { useResetBlock } from '@lumapps/widget-base/hooks/useResetBlock';

import { WIDGET_DIRECTORY_ENTRIES } from '../keys';

/**
 * Hook used to display the Directory entry creation dialog
 * Within the widget directory entry widget for both editing and creating new entries.
 * */
export const useSaveUserDirectoryEntry = (widget: WidgetState, directoryId: string) => {
    const { error, success } = useNotification();
    const refresh = useResetBlock(widget);

    const directory = useSelector(getDirectoryById(directoryId));
    const [isOpen, , onClose, onOpen] = useBooleanState(false);
    const [status, setStatus] = useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const [selectedEntry, setSelectedEntry] = useState<DirectoryEntry | undefined>(undefined);

    const onSubmit = useCallback(
        async (directoryEntry: Partial<DirectoryEntry>) => {
            try {
                setStatus(BaseLoadingStatus.loading);
                const { data: entry } = await saveUserEntry(directoryEntry);

                /**
                 * Set the entry as favorite if:
                 * it's a new entry, the ID is not yet defined.
                 * the favorites option is enabled in the directory
                 * */
                if (!directoryEntry.id && directory?.favorites) {
                    await favorite(entry.id);
                }

                onClose();
                refresh();
                success({ translate: WIDGET_DIRECTORY_ENTRIES.ENTRY_SAVED });
            } catch (err) {
                error({ translate: GLOBAL.GENERIC_ERROR });
            } finally {
                setStatus(BaseLoadingStatus.idle);
            }
        },
        [directory?.favorites, error, onClose, refresh, success],
    );

    const openSaveDialog = useCallback(
        async (directoryEntryId?: string) => {
            onOpen();
            setStatus(BaseLoadingStatus.loading);

            try {
                if (directoryEntryId) {
                    const { data: entry } = await getEntry(directoryEntryId);
                    setSelectedEntry(entry);
                }
            } catch (err) {
                error({ translate: GLOBAL.GENERIC_ERROR });
                onClose();
            }

            setStatus(BaseLoadingStatus.idle);
        },
        [error, onClose, onOpen],
    );

    const creationDialogProps: DirectoryEntryCreationDialogProps = useMemo(
        () => ({
            onSubmit,
            onClose,
            isOpen,
            entry: selectedEntry,
            status,
            dialogProps: {
                onVisibilityChange: (isVisible: boolean) => {
                    if (isVisible) {
                        setSelectedEntry(undefined);
                    }
                },
            },
            directoryId,
            directoryTags: directory?.tags,
            canSetMetadata: directory?.entriesHasMetadata,
            hideVisibility: true,
        }),
        [directory?.entriesHasMetadata, directory?.tags, directoryId, isOpen, onClose, onSubmit, selectedEntry, status],
    );

    return {
        openSaveDialog,
        creationDialogProps,
    };
};
