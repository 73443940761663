/**
 * Splits a given array into multiple ones with a given size.
 * @param array origin items
 * @param size - size of each resulting individual array
 * @returns list of arrays
 */
export const splitArray = (array: any[], size: number) => {
    if (array.length <= size) {
        return [array];
    }

    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }

    return result;
};
