(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetVideoController($rootScope, $sce, $timeout, DriveFilesFactory, Translation, User, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get embed code.
         *
         * @return {string} The embed code of the video.
         */
        function getEmbedCode() {
            return $sce.trustAsHtml(vm.parentCtrl.getWidgetTranslation(_.get(vm.widget, 'properties.embed')));
        }

        /**
         * Get video ratio.
         *
         * @return {Object} The dimensions of the video.
         */
        function getVideoRatio() {
            var html = vm.parentCtrl.getWidgetTranslation(_.get(vm.widget, 'properties.embed'));

            if (angular.isUndefinedOrEmpty(html)) {
                return {};
            }

            var regEx = /(width|height)=["']([^"']*)["']/gi;
            var sizes = {};
            var ratio = 56.25;

            html.replace(regEx, function replaceRegEx(all, type, value) {
                /** Filter out the wrongly formatted values for width & height attribute, that should be numbers */
                if (Number(value)) {
                    sizes[type] = value;
                }
            });

            if (angular.isDefinedAndFilled(sizes.width) && angular.isDefinedAndFilled(sizes.height)) {
                /** Make sure not to divide by 0, to avoid incoherent value for ratio */
                ratio = sizes.width ? sizes.height / sizes.width * 100 : 0;
            }

            return {
                'padding-bottom': ratio + '%',
            };
        }

        /**
         * Get widget class.
         *
         * @return {string} The widget class to set.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            widgetClass.push('widget-editable');

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode.
         *
         * @return {boolean} Is widget empty.
         */
        function isWidgetEmpty() {
            var properties = vm.widget.properties || {};

            return (properties.source === 'embed' &&
                angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(properties.embed))) ||
                (properties.source === 'drive' && (angular.isUndefinedOrEmpty(properties.pickedName) ||
                properties.error));
        }

        /**
         * Is widget hidden in reading mode.
         *
         * @return {boolean} Is widget hidden.
         */
        function isWidgetHidden() {
            var properties = vm.widget.properties || {};

            if (!vm.parentCtrl.designerMode() && !properties.noResults && vm.isWidgetEmpty()) {
                vm.parentCtrl.isHidden = true;

                return true;
            }
            vm.parentCtrl.isHidden = false;

            return false;
        }

        /////////////////////////////

        vm.getEmbedCode = getEmbedCode;
        vm.getVideoRatio = getVideoRatio;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////

        /**
         * Initialize controller.
         */
        vm.init = function init() {
            vm.widget.properties = vm.widget.properties || {};
            var properties = vm.widget.properties;

            properties.source = properties.source || 'embed';

            if (angular.isUndefined(properties.embed)) {
                properties.embed = {};
            }

            // Backward compatibility: set the existing string value in an object.
            if (angular.isString(properties.embed)) {
                var value = properties.embed;

                properties.embed = {};
                properties.embed[Translation.inputLanguage] = value;
            }

            if (properties.source !== 'drive' || angular.isUndefinedOrEmpty(properties.pickedId) ||
                properties.encoded || !User.isGoogle(User.getConnected())) {
                return;
            }

            DriveFilesFactory.get({
                fileId: properties.pickedId,
            }, function onGetDriveFileSuccess(data) {
                if (angular.isDefinedAndFilled(data.videoMediaMetadata)) {
                    properties.encoded = true;
                    properties.embedUrl = data.embedLink;
                }
            }, function onGetDriveFileError(error) {
                Utils.displayServerError(error);

                if (error.status === 404) {
                    properties.error = true;
                }
            });
        };

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    function WidgetVideoDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetVideoController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetVideo', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-video/views/widget-video.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetVideo', WidgetVideoDirective);
})();
