import BaseApi from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse } from '@lumapps/base-api/types';

import { Interest } from '../types';

// https://apiv1.lumapps.com/#tag/Content-Interest

const interestApi = new BaseApi({
    path: 'content/interest',
});

/** Create a new interest */
const createInterest = (params: Pick<Interest, 'customContentType' | 'customContentTypeTags' | 'orMetadata'>) => {
    return interestApi.post<Interest>('save', params);
};

interface ListInterestsRequestParams extends Omit<ServerListRequest, 'fields'> {
    instanceKey: string;
    lang: string;
}

/** Retrieve a list of interests. */
const listInterests = (params: ListInterestsRequestParams, listKey?: string) => {
    return interestApi.post<ServerListResponse>('list', params, undefined, listKey !== undefined, listKey);
};

/** Cancel the listing of interest */
const cancelListInterests = (listKey: string) => {
    return interestApi.cancel('list', listKey);
};

interface DeleteInterestParams {
    fields?: string;
    uid: string;
}

/** Delete an interest. */
const deleteInterest = (params: DeleteInterestParams) => {
    return interestApi.delete('delete', { params });
};

export { interestApi, createInterest, listInterests, cancelListInterests, deleteInterest };
