import isEmpty from 'lodash/isEmpty';

import { fetchProvidersTask } from './providers_tasks';
import { getCurrentInstanceId } from '../../../../services/instance';

const FETCH_PROVIDERS_ERROR = 'document/providers/FETCH_PROVIDERS_ERROR';

const FETCH_PROVIDERS_SUCCESS = 'document/providers/FETCH_PROVIDERS_SUCCESS';

/**
 * Get provider list for instance.
 *
 * @param  {string}   instanceId The instanceId we want the providers from.
 * @return {Function} The actions creator's thunk.
 */
const fetchProviders = ({ instanceId = getCurrentInstanceId() } = {}) => (dispatch, getState) => {
    const { providers } = getState().document;

    // No need to fetch providers if already fetched.
    if (!isEmpty(providers)) {
        return Promise.resolve(providers);
    }

    return fetchProvidersTask({ instanceId })
        .then((response) => {
            dispatch({
                payload: response.data.providers,
                type: FETCH_PROVIDERS_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: FETCH_PROVIDERS_ERROR,
            });
        });
};

export { FETCH_PROVIDERS_ERROR, FETCH_PROVIDERS_SUCCESS, fetchProviders };
