import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size, AspectRatio, SkeletonRectangle, SkeletonRectangleVariant } from '@lumapps/lumx/react';

import '../AssistantWidget/index.scss';
import './index.scss';

export const AssistantWidgetLoading: React.FC = () => {
    const { block } = useClassnames('assistant-widget');
    return (
        <SkeletonRectangle
            className={block()}
            variant={SkeletonRectangleVariant.rounded}
            width={Size.m}
            aspectRatio={AspectRatio.square}
        />
    );
};
