import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiApps } from '@lumapps/lumx/icons';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { DIRECTORIES } from '../../keys';
import { admin } from '../../routes';

export interface ModulesSettingsMenuProps {
    isAllowed: boolean;
    scope: string;
}

export const ModulesSettingsMenu: React.FC<ModulesSettingsMenuProps> = ({ isAllowed, scope }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item key="modules" icon={mdiApps} as={RouterLink} to={admin()} {...get({ element: 'modules' })}>
            {translateKey(DIRECTORIES.MODULES)}
        </Menu.Item>
    );
};

ModulesSettingsMenu.displayName = 'ModulesSettingsMenu';
