import React from 'react';

import { margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Button, Emphasis, FlexBox, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_CONTENT_FILTER_FORM_ID } from '../../constants';

interface FooterProps {
    /** Widget Props */
    theme: Theme;
    scope: string;
    /** Handlers */
    handleClear(): void;
}

export const ContentFilterFooter: React.FC<FooterProps> = ({ theme, scope, handleClear }) => {
    const { get } = useDataAttributes(scope);
    const { translateKey } = useTranslate();

    return (
        <FlexBox gap="regular" hAlign="center" vAlign="space-between" wrap>
            <div>
                <Button
                    theme={theme}
                    className={margin('right', 'regular')}
                    type="submit"
                    form={WIDGET_CONTENT_FILTER_FORM_ID}
                    {...get({ element: 'button', action: 'submit' })}
                >
                    {translateKey(GLOBAL.APPLY)}
                </Button>
                <Button
                    theme={theme}
                    onClick={handleClear}
                    emphasis={Emphasis.low}
                    type="reset"
                    {...get({ element: 'button', action: 'reset' })}
                >
                    {translateKey(GLOBAL.RESET)}
                </Button>
            </div>
        </FlexBox>
    );
};
