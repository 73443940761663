/* istanbul ignore file */
import { BaseApiError } from '@lumapps/base-api';
import { GLOBAL } from '@lumapps/translations';

import { exportAcknowledgeReadsCSV, fetchContentReadState, markContentAsRead } from '../api';
import { WIDGET_MANDATORY_READ } from '../keys';

/**
 * @param {string} content content of the file
 * @param {string} filename Name of the file
 */
const downloadCSVFile = (csvContent: string, filename: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const fileNameWithExt = `${filename}.csv`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (navigator.msSaveBlob) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigator.msSaveBlob(blob, fileNameWithExt);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileNameWithExt);
            link.style.visibility = 'hidden';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const fetchReadState = async ({
    contentId,
    onSuccess,
    onError,
}: {
    contentId: string;
    onSuccess({ isRead, confirmedAt }: { isRead: boolean; confirmedAt?: string }): void;
    onError(messageKey: string): void;
}) => {
    try {
        const result = await fetchContentReadState(contentId);
        onSuccess({
            isRead: true,
            confirmedAt: result.data.confirmedAt,
        });
    } catch (e) {
        if ((e as BaseApiError)?.response?.status === 404) {
            onSuccess({
                isRead: false,
            });
        } else {
            onError(GLOBAL.GENERIC_ERROR);
        }
    }
};

export const acknowledgeContent = async ({
    contentId,
    onSuccess,
    onError,
}: {
    contentId: string;
    onSuccess({ isRead, confirmedAt }: { isRead: boolean; confirmedAt?: string }): void;
    onError(messageKey: string): void;
}) => {
    try {
        const result = await markContentAsRead(contentId);
        onSuccess({
            isRead: true,
            confirmedAt: result.data.confirmedAt,
        });
    } catch (e) {
        if ((e as BaseApiError)?.response?.status === 403) {
            onError(WIDGET_MANDATORY_READ.ALREADY_ACKNOWLEDGED_ERROR);
            fetchReadState({ contentId, onSuccess, onError });
        } else {
            onError(GLOBAL.GENERIC_ERROR);
        }
    }
};

export const downloadExportCSV = async ({ contentId, fileName }: { contentId: string; fileName: string }) => {
    const response = await exportAcknowledgeReadsCSV(contentId);
    return downloadCSVFile(response.data, fileName);
};
