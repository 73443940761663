import partial from 'lodash/partial';

import { createPlugin } from '@lumapps/wrex/slate/plugin';
import { WrexEditor } from '@lumapps/wrex/types';

import { EditableUserMention } from '../components/editableBlocks/EditableUserMention';
import { UserMentionEditor } from '../types';
import { isMention } from '../utils/isMention';
import { isMentionAllowed } from '../utils/isMentionAllowed';
import { unwrapMention } from '../utils/unwrapMention';
import { normalizeUserMention } from './normalize/normalizeUserMention';

/**
 * A plugin made to handle user mentions.
 */
export const withUserMention = () => {
    return createPlugin<UserMentionEditor, WrexEditor<UserMentionEditor>>({
        createPluginEditor: (editor) => {
            const { isVoid, isInline, normalizeNode } = editor;

            return {
                // Slate methods:
                isInline: (element) => isMention(element) || isInline(element),
                isVoid: (element) => isMention(element) || isVoid(element),
                normalizeNode(entry) {
                    if (normalizeUserMention(editor, entry)) {
                        return;
                    }
                    normalizeNode(entry);
                },

                // Custom methods:
                isMentionAllowed: partial(isMentionAllowed, editor),
                unwrapMention: partial(unwrapMention, editor),
            };
        },
        elements: [EditableUserMention],
    });
};
