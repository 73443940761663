import React, { Children, ReactElement, ReactNode, useCallback, useMemo } from 'react';

import map from 'lodash/fp/map';
import property from 'lodash/fp/property';
import some from 'lodash/fp/some';

import { padding, useClassnames } from '@lumapps/classnames';
import { mdiMenuDown, mdiFilterVariant, mdiClose } from '@lumapps/lumx/icons';
import {
    Icon,
    Chip,
    Size,
    Theme,
    Placement,
    Button,
    FlexBox,
    Orientation,
    Emphasis,
    IconButton,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    PopoverDialog,
    Heading,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PostListFilterBlockProps } from '../../../types';
import './index.scss';

interface PostListFilterItemProps {
    label?: string;
    name?: string;
    theme: Theme;
    children: ReactNode;
    className?: string;
    hasFilterIcon?: boolean;
    hasArrowDownIcon?: boolean;
    isLoading?: boolean;
    isFilterOpen: boolean;
    onApply(filterIds: string[]): void;
    onClose(filterIds: string[]): void;
    onReset(filterIds: string[]): void;
    onToggle(filterIds: string[]): void;
}

export const CLASSNAME = 'post-list-filter-item';

export const PostListFilterItem = ({
    hasFilterIcon = false,
    hasArrowDownIcon = true,
    label,
    name,
    onApply,
    onClose,
    onReset,
    onToggle,
    theme,
    children,
    className,
    isFilterOpen,
    isLoading = false,
    ...rest
}: PostListFilterItemProps) => {
    const ref = React.useRef<HTMLAnchorElement>(null);
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);
    const isDarkTheme = theme === Theme.dark;

    const filters = useMemo(() => {
        const childrenArray = Children.toArray(children).filter((child) => React.isValidElement(child));

        return map(
            (child) => ({
                filterId: child.props.filterId,
                isActive: child.props.isActive,
            }),
            childrenArray as ReactElement<PostListFilterBlockProps>[],
        );
    }, [children]);

    const isActive = useMemo(() => some(property('isActive'), filters), [filters]);

    // when the user applies the filter(s)
    const handleApply = useCallback(() => {
        const filterIds = map(property('filterId'), filters);

        onApply(filterIds);
        // Secure the focus on the filter button once the filter is applied
        ref.current?.focus();
    }, [filters, onApply]);

    // when the user closes the modal without applying or clearing
    const handleClose = useCallback(() => {
        const filterIds = map(property('filterId'), filters);
        onClose(filterIds);
        // Secure the focus on the filter button once the dropdown is closed
        ref.current?.focus();
    }, [filters, onClose]);

    // when the user clears the filter(s)
    const handleReset = useCallback(() => {
        const filterIds = map(property('filterId'), filters);

        onReset(filterIds);
    }, [filters, onReset]);

    // when the user clicks on the filter(s) chip
    const handleToggle = useCallback(
        (event) => {
            event.preventDefault();
            const filterIds = map(property('filterId'), filters);
            onToggle(filterIds);
        },
        [filters, onToggle],
    );

    if (isLoading) {
        return (
            <li className={block([className])} {...rest}>
                <SkeletonRectangle
                    theme={theme}
                    width={Size.l}
                    height={Size.m}
                    variant={SkeletonRectangleVariant.pill}
                />
            </li>
        );
    }

    return (
        <li className={block([className])} {...rest}>
            <Chip
                theme={theme}
                before={hasFilterIcon && <Icon icon={mdiFilterVariant} size={Size.xs} />}
                after={hasArrowDownIcon && <Icon icon={mdiMenuDown} size={Size.xs} />}
                isSelected={isActive}
                onClick={handleToggle}
                ref={ref}
                className={element('chip', { 'no-label': !label })}
            >
                {name ?? label ?? translateKey(GLOBAL.FILTER)}
            </Chip>
            <PopoverDialog
                isOpen={isFilterOpen}
                focusAnchorOnClose
                closeOnEscape={false}
                closeOnClickAway={false}
                anchorRef={ref}
                withFocusTrap
                aria-labelledby={translateKey(GLOBAL.FILTERS)}
                placement={Placement.BOTTOM_END}
                className={element('dropdown', {
                    'theme-dark': isDarkTheme,
                })}
            >
                <div
                    className={element('container', {
                        'theme-dark': isDarkTheme,
                    })}
                >
                    <FlexBox className={element('flex-between')} hAlign="center">
                        <Heading
                            as="h4"
                            className={element('container__title', {
                                'theme-dark': isDarkTheme,
                            })}
                        >
                            {label || translateKey(GLOBAL.FILTERS)}
                        </Heading>
                        <IconButton
                            label={translateKey(GLOBAL.CLOSE)}
                            icon={mdiClose}
                            onClick={handleClose}
                            emphasis={Emphasis.low}
                            theme={theme}
                        />
                    </FlexBox>
                    <FlexBox orientation={Orientation.vertical} gap="huge" className={padding('vertical', 'regular')}>
                        {React.Children.map(children, (child: React.ReactNode) => {
                            if (React.isValidElement(child)) {
                                return <child.type {...child.props} theme={theme} />;
                            }
                            return null;
                        })}
                    </FlexBox>
                    <FlexBox orientation={Orientation.horizontal} gap="huge" className={element('flex-between')}>
                        <Button emphasis={Emphasis.low} onClick={handleReset} theme={theme} isDisabled={!isActive}>
                            {translateKey(GLOBAL.CLEAR)}
                        </Button>
                        <Button onClick={handleApply} theme={theme}>
                            {translateKey(GLOBAL.APPLY)}
                        </Button>
                    </FlexBox>
                </div>
            </PopoverDialog>
        </li>
    );
};
