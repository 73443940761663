import React from 'react';

import { useGenerateAriaId } from '@lumapps/aria-ids/hooks/useGenerateAriaId';
import { useClassnames } from '@lumapps/classnames';
import {
    ColorPalette,
    SkeletonTypography,
    Theme,
    Typography,
    TypographyCustom,
    Text,
    TextProps,
    TextElement,
} from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { PLAY_VIDEO } from '../../../keys';
import { useBlockVideoContext } from '../BlockVideoContext';
import { Button } from '../Button';
import { CLASSNAME as BLOCK_VIDEO_CLASSNAME } from '../constants';
import { Link } from '../Link';

import './Title.scss';

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
const { custom, ...typographyInterface } = Typography;

// Map used to be sure that Heading and SkeletonTypography typography types match
const SKELETON_TYPOGRAPHY_MAP = {
    ...typographyInterface,
    [TypographyCustom.body]: Typography.body1,
    [TypographyCustom.title1]: Typography.title,
    [TypographyCustom.title2]: Typography.title,
    [TypographyCustom.title3]: Typography.title,
    [TypographyCustom.title4]: Typography.title,
    [TypographyCustom.title5]: Typography.title,
    [TypographyCustom.title6]: Typography.title,
};

interface TitleProps extends Pick<TextProps, 'truncate'> {
    /** Custom component to render the text. */
    as?: TextElement;
    /** Component children */
    children?: React.ReactNode;
    /** Custom className */
    className?: string;
    /** Typography variant */
    typography: keyof typeof SKELETON_TYPOGRAPHY_MAP;
}

export const CLASSNAME = `${BLOCK_VIDEO_CLASSNAME}-title` as const;

export const Title = ({ as = 'h2', children, className, truncate, typography }: TitleProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { canDisplay, isLoading, video, theme } = useBlockVideoContext();
    const titleId = useGenerateAriaId('label');

    if (!canDisplay('TITLE')) {
        return null;
    }

    if (isLoading) {
        return (
            <SkeletonTypography
                className={block('skeleton')}
                theme={theme}
                typography={SKELETON_TYPOGRAPHY_MAP[typography]}
                width="50%"
            />
        );
    }

    const hasInteractiveChild = React.Children.toArray(children).some(
        (child) => React.isValidElement(child) && (child.type === Link || child.type === Button),
    );

    const videoTitle = typeof video.title === 'string' ? video.title : video.title?.value;

    const title = video.restricted ? translateKey(PLAY_VIDEO.RESTRICTED_VIDEO) : videoTitle;

    return (
        <Text
            id={titleId}
            as={as}
            className={block({ 'is-interactive': hasInteractiveChild }, className)}
            color={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
            truncate={truncate}
            typography={typography}
        >
            {children || title}
        </Text>
    );
};
