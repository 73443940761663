import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { first } from '@lumapps/utils/iterable/first';
import { isUrl } from '@lumapps/utils/string/isUrl';
import { isValidEmail } from '@lumapps/utils/string/isValidEmail';
import { Editor, NodeEntry, Point, Transforms } from '@lumapps/wrex/slate';
import { createPlugin } from '@lumapps/wrex/slate/plugin';
import { WrexEditor } from '@lumapps/wrex/types';

import { EditableLink } from '../components/editableBlocks/EditableLink';
import { LINK_FEATURES } from '../constants';
import type { LinkEditorProps, LinkElement, LinkOptions } from '../types';
import { isLink } from '../utils/isLink';
import { isLinkAllowed } from '../utils/isLinkAllowed';
import { containsLinksOrEmails, splitOnLinksAndEmails } from '../utils/splitOnLinksAndEmails';
import { unwrapLink } from '../utils/unwrapLink';
import { wrapLink } from '../utils/wrapLink';
import { getKeyHandler } from './event/keyboard';

/**
 * A plugin made to handle links.
 */
export const withLink = (options: LinkOptions = {}) =>
    createPlugin<LinkEditorProps, WrexEditor<LinkEditorProps>>({
        createPluginEditor: (editor) => {
            const { isInline, insertText, insertData, insertBreak, deleteBackward, normalizeNode, deleteFragment } =
                editor;

            return {
                normalizeNode: ([node, path]) => {
                    if (isLink(node)) {
                        const { isInEditMode } = node;
                        const hasEmptyText = path && Editor.string(editor, path).trim() === '';
                        if (hasEmptyText && !isInEditMode) {
                            Transforms.removeNodes(editor, { at: path, match: isLink });
                            return;
                        }
                    }

                    normalizeNode([node, path]);
                },
                isInline: (element) => {
                    return isLink(element) ? true : isInline(element);
                },
                deleteBackward: (unit) => {
                    deleteBackward(unit);
                    if (editor.selection) {
                        const [linkNode] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isLink }));
                        if (linkNode && Editor.string(editor, linkNode[1]).length === 0) {
                            Transforms.removeNodes(editor, { at: linkNode[1] });
                        }
                    }
                },
                deleteFragment: () => {
                    deleteFragment();
                    if (editor.selection) {
                        const [linkNode] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isLink }));
                        if (linkNode && Editor.string(editor, linkNode[1]).length === 0) {
                            Transforms.removeNodes(editor, { at: linkNode[1] });
                        }
                    }
                },
                insertBreak: () => {
                    insertBreak();

                    if (editor.selection) {
                        const [linkNode] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isLink }));
                        if (linkNode) {
                            const text = Editor.string(editor, linkNode[1]);
                            if (!text) {
                                Transforms.removeNodes(editor, { at: linkNode[1] });
                                const beforeTextPath = [...linkNode[1]];
                                beforeTextPath[beforeTextPath.length - 1] -= 1;
                                Transforms.removeNodes(editor, { at: beforeTextPath });
                                Transforms.move(editor, {
                                    distance: 1,
                                    unit: 'character',
                                });
                            }
                        }
                    }
                },
                insertText: (text) => {
                    if (text && isUrl(text)) {
                        wrapLink(editor, text);
                    } else {
                        // if text is [Space] and we are at the end of a link, we move out of it.
                        if (text === ' ') {
                            if (editor.selection) {
                                const linkNode = first(Editor.nodes(editor, { at: editor.selection, match: isLink }));

                                if (linkNode) {
                                    const linkText = Editor.string(editor, linkNode[1]);
                                    if (linkText) {
                                        const atTheEnd = Point.equals(
                                            editor.selection.focus,
                                            Editor.end(editor, linkNode[1]),
                                        );
                                        if (atTheEnd) {
                                            Transforms.move(editor, {
                                                distance: 1,
                                                unit: 'offset',
                                            });
                                        }
                                    }
                                }
                            }
                        }

                        insertText(text);
                    }
                },
                insertData: (data) => {
                    const text = data.getData('text/plain');
                    const html = data.getData('text/html');
                    if (text && !html && containsLinksOrEmails(text)) {
                        const parts = splitOnLinksAndEmails(text);
                        for (const [index, part] of parts.entries()) {
                            if (isValidEmail(part)) {
                                wrapLink(editor, `mailto:${part}`, undefined, undefined, part);
                            } else if (isUrl(part)) {
                                const cleanedUrl = sanitizeUrl(part) || '';
                                wrapLink(editor, cleanedUrl, undefined, undefined, part);

                                // Convert the last pasted link to link preview if necessary
                                if (
                                    index === parts.length - 1 &&
                                    options.enabledFeatures?.includes(LINK_FEATURES.linkPreviewConversion) &&
                                    !!options.onLinkConversion
                                ) {
                                    options.onLinkConversion(cleanedUrl);
                                }
                            } else {
                                insertText(part);
                            }
                        }
                    } else {
                        insertData(data);
                    }
                },
                setLinkEditionStatus: (path, value) => {
                    Transforms.setNodes(
                        editor,
                        { isInEditMode: value },
                        {
                            at: path,
                            match: isLink,
                        },
                    );
                },
                wrapLink,
                unwrapLink,
                isLinkActive: (node): node is NodeEntry<LinkElement> => !!node && isLink(node[0]),
                isLinkAllowed: isLinkAllowed(editor, options),
            };
        },
        getKeyHandler,
        elements: [EditableLink],
    });
