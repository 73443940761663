import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { getContentMetadata } from '@lumapps/contents/ducks/thunks/getContentMetadata';
import { connect } from '@lumapps/redux/react';
import { Dispatch, FrontOfficeStore } from '@lumapps/redux/types';

import { ClientComputedMetadataSelection } from './ClientComputedMetadataSelection';

export const mapStateToProps = (state: FrontOfficeStore) => ({
    currentContent: contentSelector(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchContentMetadata: (currentContentId: string) => dispatch(getContentMetadata(currentContentId)),
});

const ConnectedClientComputedMetadataSelection = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClientComputedMetadataSelection);

export { ConnectedClientComputedMetadataSelection as ClientComputedMetadataSelection };
