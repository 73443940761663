import BaseApi, { formatLanguageHeader } from '@lumapps/base-api';
import { BaseApiPromise, PRIORITY, REVALIDATED_ON_TYPE } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { MenuQuery, Navigation, NavigationItem } from '../types';

const navigationApi = new BaseApi({ version: BaseApi.versions.v2 });

/**
 * Retrieve the navigation menu items from the Navigation service
 * Use a cache system to avoid to do a new query (which is quite long)
 * @param params Query params like the user lang
 */
const getNavigationItems = async (params: MenuQuery): Promise<NavigationItem[]> => {
    const { lang, alternativeLang, instance } = params;
    const response = await navigationApi.getStaleWhileRevalidate(
        `/sites/${instance}/map/navigations/main-navigation`,
        CACHE_TYPE.STORAGE,
        {
            params: {
                lang,
                alternativeLang,
            },
            headers: {
                'Accept-Language': formatLanguageHeader([lang, alternativeLang]),
            },
        },
        PRIORITY.HIGH,
        REVALIDATED_ON_TYPE.ONCE,
    );

    const { children = [] } = response.data;

    return children;
};

/**
 * Get the Breadcrumb path down to the current pageId (= content Id) or nodeId.
 */
const getMainNavigationBreadCrumb = (
    params: MenuQuery & ({ pageId: string; nodeId?: never } | { pageId?: never; nodeId: string }),
    fetchKey?: string,
): BaseApiPromise<Navigation> => {
    const { pageId, nodeId, lang, alternativeLang, instance } = params;

    return navigationApi.get<Navigation>(
        `/sites/${instance}/map/navigations/breadcrumbs`,
        {
            params: {
                pageId,
                nodeId,
            },
            headers: {
                'Accept-Language': formatLanguageHeader([lang, alternativeLang]),
            },
        },
        undefined,
        fetchKey !== undefined,
        fetchKey,
    );
};

const cancelGetMainNavigationBreadcrumb = ({ instance }: { instance: string }, fetchKey: string) => {
    return navigationApi.cancel(`/sites/${instance}/map/navigations/breadcrumbs`, undefined, fetchKey);
};

type GetMainNavigationSubNavigationParams = MenuQuery &
    ({ pageId: string; nodeId?: never } | { pageId?: never; nodeId: string }) & {
        /**
         * Whether the search and children should include nodes with `isHidden` or `areChildrenHidden` set to true.
         * False if undefined.
         */
        showHiddenNodes?: boolean;
    };

/**
 * Get the Subnav of the current pageId (= content Id) or nodeId.
 */
const getMainNavigationSubNavigation = (
    params: GetMainNavigationSubNavigationParams,
    fetchKey?: string,
): BaseApiPromise<Navigation> => {
    const { pageId, nodeId, lang, alternativeLang, instance, showHiddenNodes } = params;

    return navigationApi.get<Navigation>(
        `/sites/${instance}/map/navigations/sub-navigations`,
        {
            params: {
                pageId,
                nodeId,
                showHiddenNodes,
            },
            headers: {
                'Accept-Language': formatLanguageHeader([lang, alternativeLang]),
            },
        },
        undefined,
        fetchKey !== undefined,
        fetchKey,
    );
};

const cancelGetMainNavigationSubNavigation = ({ instance }: { instance: string }, fetchKey: string) => {
    return navigationApi.cancel(`/sites/${instance}/map/navigations/sub-navigations`, undefined, fetchKey);
};

export {
    navigationApi,
    getNavigationItems,
    getMainNavigationBreadCrumb,
    cancelGetMainNavigationBreadcrumb,
    getMainNavigationSubNavigation,
    cancelGetMainNavigationSubNavigation,
};
