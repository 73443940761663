import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function EditableMediaController($scope, $timeout, AbstractPicker, Features, LxNotificationService, Media,
        MediaConstant, Translation, Upload, UploaderAction, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains the list of allowed format.
         *
         * @type {Array}
         */
        var _ALLOWED_FORMATS = ['url', 'media'];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if we want to show the media picker in the DOM.
         *
         * @type {boolean}
         */
        vm.showMediaPicker = false;

        /**
         * The ngModel to use for the media picker.
         *
         * @type {Object}
         */
        vm.tempNgModel = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Media = Media;
        vm.MediaConstant = MediaConstant;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Delete the selected media.
         */
        function deleteMedia() {
            if (!vm.allowDelete || vm.ngDisabled) {
                return;
            }

            LxNotificationService.confirm(
                Translation.translate('EDITABLE_MEDIA_DELETE_CONFIRM'),
                Translation.translate('EDITABLE_MEDIA_DELETE_CONFIRM_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onMediaDeleteConfirm(answer) {
                    if (answer) {
                        vm.ngModel = undefined;

                        if (angular.isFunction(vm.ngChange)) {
                            vm.ngChange();
                        }
                    }
                });
        }

        /**
         * Edit the selected media.
         */
        function edit() {
            if (!vm.allowEdit || vm.ngDisabled) {
                return;
            }

            if (vm.simple) {
                UploaderAction.openSelection(vm.id);
            } else {
                vm.openMediaPicker();
            }
        }

        /**
         * Open the media picker to edit the media.
         */
        function openMediaPicker() {
            if (vm.simple || vm.ngDisabled) {
                return;
            }

            vm.showMediaPicker = true;
            Utils.waitForAndExecute(`#${vm.id}`, AbstractPicker);
        }

        /**
         * Upload a media.
         *
         * @param {Object} file The file to upload.
         */
        function upload(file) {
            if (vm.ngDisabled) {
                return;
            }

            if (angular.isDefinedAndFilled(_.get(file, 'blobKey'))) {
                vm.ngModel = file.blobKey;
            }

            if (angular.isDefinedAndFilled(_.get(file, 'url'))) {
                vm.ngModel = file.url;
            }

            if (angular.isFunction(vm.ngChange)) {
                vm.ngChange();
            }
        }

        /////////////////////////////

        vm.delete = deleteMedia;
        vm.edit = edit;
        vm.openMediaPicker = openMediaPicker;
        vm.upload = upload;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the media picker closes, remove it from the DOM.
         *
         * @param {Event}  evt           The media picker close event.
         * @param {string} mediaPickerId The id of the media picker that closes.
         */
        $scope.$on('abstract-picker__close-end', function onMediaPickerClose(evt, mediaPickerId) {
            if (mediaPickerId === vm.id) {
                $timeout(function delayRemovingFromDom() {
                    vm.showMediaPicker = false;
                });

                if (angular.isFunction(vm.ngChange)) {
                    vm.ngChange();
                }
            }
        });

        /**
         * When the media picker starts to close, execute the ng-change function.
         *
         * @param {Event}  evt           The media picker close start event.
         * @param {string} mediaPickerId The id of the media picker that closes.
         */
        $scope.$on('abstract-picker__close-end', function onMediaPickerClose(evt, mediaPickerId) {
            if (mediaPickerId === vm.id) {
                vm.ngModel = (vm.format === 'media') ?
                    vm.tempNgModel : Media.getMediaImage(vm.tempNgModel, undefined, true, -1);

                if (angular.isFunction(vm.ngChange)) {
                    vm.ngChange();
                }
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.allowDelete = (angular.isUndefined(vm.allowDelete)) ? true : vm.allowDelete;
            vm.allowEdit = (angular.isUndefined(vm.allowEdit)) ? true : vm.allowEdit;

            vm.id = vm.id || generateUUID();
            vm.uploaderLabel = vm.uploaderLabel || Translation.translate('PICK_MEDIA');
            vm.uploaderIcon = vm.uploaderIcon || 'cloud-upload';

            vm.showPreview = (angular.isUndefined(vm.showPreview)) ? true : vm.showPreview;

            if (!_.includes(_ALLOWED_FORMATS, vm.format)) {
                vm.format = (vm.simple) ? 'url' : 'media';
            }
            vm.format = (!vm.simple && vm.format !== 'url') ? 'url' : vm.format;
        }

        init();
    }

    /**
     * Editable Media directive.
     * Used to display a media that the user can edit or delete.
     *
     * @param {boolean} [allowDelete=true]             Indicates if we want to allow the user to delete the selected
     *                                                 media.
     * @param {boolean} [allowEdit=true]               Indicates if we want to allow the user to edit (change) the
     *                                                 selected media.
     * @param {Array}   [allowedFilesTypes]            Contains the type of files we want to allow to be uploaded or
     *                                                 picked.
     * @param {string}  [barLabel]                     The label to display in the overlay bar when an media is
     *                                                 selected.
     * @param {string}  [format='media']               Indicates which information you want to store in the model.
     *                                                 Possible values are: 'media', 'url'.
     *                                                 Note thath 'media' is not possible in simple mode. Thus, in
     *                                                 simple mode, format will default to 'url'.
     * @param {string}  [id=<Generated UUID>]          The id of the editable-media picker.
     * @param {boolean} [ngDisabled=false]             Indicates if we want to disable the picker (no upload/edit/delete
     *                                                 allowed).
     * @param {*}       ngModel                        The destination of the uploaded media.
     *                                                 In simple mode, this will contain the URL of the uploaded media.
     *                                                 In advanced mode, this will contain a media object.
     * @param {boolean} [showPreview=true]             Indicates if we want to show a preview of the picked media.
     * @param {boolean} [simple=false]                 Indicates if we are in simple (uploader) or advanced (media
     *                                                 picker) mode.
     * @param {number}  [size]                         The size of the uploader.
     *                                                 Will take all the place available if none given.
     * @param {string}  [uploaderIcon='cloud-upload']  The icon to display for the uploader/media-picker trigger.
     * @param {string}  [uploaderLabel='Pick a media'] The label to display for the uploader/media-picker trigger.
     */

    function EditableMediaDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: EditableMediaController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                allowDelete: '<?lsAllowDelete',
                allowEdit: '<?lsAllowEdit',
                allowedFilesTypes: '@?lsAllowedFilesTypes',
                barLabel: '@?lsBarLabel',
                format: '@?lsFormat',
                id: '@?lsId',
                ngChange: '&?',
                ngDisabled: '<?',
                ngModel: '=?',
                showPreview: '<?lsShowPreview',
                simple: '<?lsSimple',
                size: '<?lsSize',
                uploaderIcon: '@?lsUploaderIcon',
                uploaderLabel: '@?lsUploaderLabel',
            },
            templateUrl: '/client/common/modules/editable-media/views/editable-media.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsEditableMedia', EditableMediaDirective);
})();
