import { Alignment, ImageBlock, Lightbox, Mosaic, Slideshow, SlideshowItem } from '@lumapps/lumx/react';
import { isImageUrlResized } from '@lumapps/medias/utils';
import React, { useRef } from 'react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { useSlideshowControlLabels } from '@lumapps/utils/hooks/useSlideshowControlLabels';

const MosaicWrapper = ({ thumbnails, theme, activeIndex, onClose, ...props }) => {
    const lightBoxParent = useRef(null);
    const { translateKey } = useTranslate();
    const slideshowLabels = useSlideshowControlLabels();

    const getFullUrl = (url) => url.split('=s')[0];

    return (
        <>
            <Mosaic
                {...props}
                theme={theme}
                thumbnails={thumbnails.map((thumbnail) => ({ ...thumbnail, image: thumbnail.url }))}
            />

            <Lightbox
                isOpen={activeIndex !== undefined && activeIndex !== null}
                parentElement={lightBoxParent}
                onClose={onClose}
                closeButtonProps={{ label: translateKey(GLOBAL.CLOSE) }}
            >
                <Slideshow
                    activeIndex={activeIndex}
                    slideshowControlsProps={{
                        nextButtonProps: { label: slideshowLabels.next },
                        previousButtonProps: { label: slideshowLabels.previous },
                        paginationItemProps: (index) => ({
                            label: slideshowLabels.paginationItem(index),
                        }),
                    }}
                    slideGroupLabel={slideshowLabels.slideGroup}
                    fillHeight
                    theme={theme}
                >
                    {thumbnails.map((th, idx) => {
                        return (
                            <SlideshowItem key={`${th.url}-${idx}`}>
                                <ImageBlock
                                    image={isImageUrlResized(th.url) ? getFullUrl(th.url) : th.url}
                                    align={Alignment.center}
                                    fillHeight
                                    theme={theme}
                                    alt=""
                                />
                            </SlideshowItem>
                        );
                    })}
                </Slideshow>
            </Lightbox>
        </>
    );
};

export { MosaicWrapper };
