import { getFocusableTreeWalker } from './getFocusableTreeWalker';

/**
 * Sets the window focus on the next available interactive element after the
 * given element.
 */
export function focusAfter(
    /** The element within which to look for focusable elements */
    root: Element,
    /** The element after which the focus must be set. Must be within `root`. */
    after: Element,
) {
    const walker = getFocusableTreeWalker(root, after);
    const nextNode = walker.nextNode() as HTMLElement;

    if (nextNode) {
        nextNode.focus();
    }
    return nextNode;
}
