import { useContext } from 'react';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';

import { homePageViewRoute } from '@lumapps/contents/routes';

import { AppId } from '../constants';
import { Context, RouterProps } from '../routers/router-context';
import { OptionalAppRoute } from '../types';

const doesRouteMatch = (currentContext: RouterProps, match: any, route?: OptionalAppRoute<any>) => {
    /** If the route contains an external url, return directly. */
    if (route?.appId === AppId.external) {
        return false;
    }

    /**
     * For the routing on the legacy side, we have a context that provides the current view,
     * and we reuse it in order to determine the current view.
     *
     * TODO: remove this once we have migrated to full react
     */
    if (currentContext && currentContext.currentView) {
        const { currentView, params } = currentContext;

        const doesMatchLegacyId = currentView === route?.legacyId;

        /**
         * For the homepage, we need to test the slug to differentiate the home from another content,
         * since all contents have the same legacyId.
         */
        if (isEqual(route, homePageViewRoute)) {
            return doesMatchLegacyId && !params.slug;
        }

        if (doesMatchLegacyId && route?.params) {
            const onlyProvidedParams = pick(params, Object.keys(route.params));
            const doesHaveTheSameParams = isEqual(
                onlyProvidedParams,
                pick(route.params, Object.keys(onlyProvidedParams)),
            );

            return doesHaveTheSameParams;
        }

        return doesMatchLegacyId;
    }

    /**
     * If we are already in full React, we need to check the match against the provided route. If the match has parameters,
     * we need to check those parameters as well. Since in the route.params object we could have other parameters that are needed
     * to match these routes on the Angular JS side, we only check the routes parameters from the match against the routes provided.
     */
    const doesCurrentLocationMatchRoute = Boolean(match?.isExact);

    if (route?.params) {
        const onlyMatchedParams = pick(match?.params, Object.keys(route.params));
        const onlyMatchedParamsKeys = Object.keys(onlyMatchedParams);
        /**
         * We only check that the parameters match when the match tells us that there are parameters detected.
         * If there are no parameters on the match, but the route specificed some parameters, there is definitely something
         * that does not match and we return false right away.
         */
        if (onlyMatchedParamsKeys.length > 0) {
            const doesHaveTheSameParams = isEqual(onlyMatchedParams, pick(route.params, onlyMatchedParamsKeys));
            return doesHaveTheSameParams && doesCurrentLocationMatchRoute;
        }

        return false;
    }

    return doesCurrentLocationMatchRoute;
};

/**
 * Returns true if the current location matches the provided route
 * @param route - route to match against
 */
const useRouteMatching = (route?: OptionalAppRoute<any>, pathMatchForHighlighting?: string): boolean => {
    const { pathname } = useLocation();

    let match = useRouteMatch(route?.path || '');
    // The below is used for highlighting the entry in side nav when using params on top of the base route
    // (for tabs for instance). Need to use match.isExact as match by itself is always valid in this case.
    if (pathMatchForHighlighting && !match?.isExact) {
        match = matchPath(pathname, {
            path: pathMatchForHighlighting,
        });
    }
    const currentContext = useContext(Context);

    return doesRouteMatch(currentContext, match, route);
};

export { useRouteMatching, doesRouteMatch };
