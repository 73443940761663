/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
    FETCH_MEMBERS_ERROR,
    FETCH_MEMBERS_LOADING,
    FETCH_MEMBERS_SUCCESS,
} from '../actions/community_members_actions';

// Default state.
const defaultState = { page: 0, errorCode: undefined, errorMessage: '', items: [], memberIsLoading: false };

const membersReducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        const { errorCode, errorMessage, items = [], page: pageStr = '1', more } = action.payload || {};
        const page = parseInt(pageStr, 10);

        switch (action.type) {
            case FETCH_MEMBERS_LOADING:
                draft.memberIsLoading = true;

                break;
            case FETCH_MEMBERS_SUCCESS:
                if (page > 1) {
                    draft.items.push(...items);
                } else {
                    draft.items = items;
                }
                draft.page = page;
                draft.more = more;
                draft.memberIsLoading = false;

                break;
            case FETCH_MEMBERS_ERROR:
                draft.errorCode = errorCode;
                draft.errorMessage = errorMessage;
                draft.memberIsLoading = false;

                break;

            default:
        }

        return draft;
    });

export { membersReducer };
