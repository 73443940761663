import { FILTER_TYPES } from '../constants';
import { SearchFilter } from '../types';

export const resetLumappsTabsCount = (filters: SearchFilter[]) => {
    return filters.map((filter) => {
        if (filter.type === FILTER_TYPES.LUMAPPS) {
            return {
                ...filter,
                count: undefined,
                totalCount: undefined,
            };
        }

        return filter;
    });
};
