import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiStar } from '@lumapps/lumx/icons';
import { Alignment, ColorPalette, FlexBox, Icon, Link, Orientation, Text, Thumbnail } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';
import { DEFAULT_AVATAR } from '@lumapps/user/constants';

import { SCOPE } from '../../constants';
import { RankingData } from '../../types';

import './index.scss';

export interface PodiumProps {
    rankingListData: RankingData[];
}
/**
 *  Podium component that will be on the leaderboard widget
 * @param PodiumProps
 * @returns Example
 */

const CLASSNAME = 'learning-leaderboard-podium';

export const Podium: React.FC<PodiumProps> = ({ rankingListData }) => {
    const { element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(SCOPE);

    const hexToIntUserId = (hexId: string) => {
        if (!hexId) {
            return '';
        }
        const cleanHexId = hexId.replace(/-/g, '');
        const bigintId = BigInt(`0x${cleanHexId}`);
        return bigintId.toString();
    };

    const { route: userProfileRouteSecondPosition } = useSocialProfileRoute({
        routeParams: {
            userId: hexToIntUserId(rankingListData[1]?.identifier),
        },
        userData: {
            id: hexToIntUserId(rankingListData[1]?.identifier),
        },
    });
    const { route: userProfileRouteFirstPosition } = useSocialProfileRoute({
        routeParams: {
            userId: hexToIntUserId(rankingListData[0]?.identifier),
        },
        userData: {
            id: hexToIntUserId(rankingListData[0]?.identifier),
        },
    });
    const { route: userProfileRouteThirdPosition } = useSocialProfileRoute({
        routeParams: {
            userId: hexToIntUserId(rankingListData[2]?.identifier),
        },
        userData: {
            id: hexToIntUserId(rankingListData[2]?.identifier),
        },
    });

    return (
        <FlexBox
            orientation={Orientation.horizontal}
            vAlign="space-around"
            hAlign={Alignment.bottom}
            className={margin('bottom', 'huge')}
        >
            {rankingListData[1]?.ranking === 2 && (
                <Link
                    className={element('link')}
                    linkAs={RouterLink}
                    to={userProfileRouteSecondPosition}
                    {...get({ element: 'list-element', action: 'open-learners-Ranking-profile' })}
                >
                    <FlexBox className={element('wrapper')}>
                        <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center} hAlign={Alignment.bottom}>
                            <Thumbnail
                                variant="rounded"
                                className={element('second-avatar')}
                                alt="profile picture"
                                image={rankingListData[1]?.avatar || DEFAULT_AVATAR}
                            />
                            <div className={element('second-position')}>2</div>
                        </FlexBox>
                        <FlexBox className={element('text-wrapper')}>
                            <Text
                                as="p"
                                typography="subtitle1"
                                color="dark"
                                colorVariant="N"
                                className={element('text')}
                                truncate={{ lines: 2 }}
                            >
                                {`${rankingListData[1]?.firstName} \n ${rankingListData[1]?.lastName} \u0008`}
                            </Text>
                            <FlexBox
                                className={element('score-wrapper')}
                                orientation={Orientation.horizontal}
                                vAlign="center"
                                hAlign="center"
                                gap="tiny"
                            >
                                <Text as="p" typography="custom-body" color="dark" colorVariant="L2">
                                    {rankingListData[1]?.totalPoints}
                                </Text>
                                <Icon icon={mdiStar} color={ColorPalette.yellow} />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Link>
            )}
            {rankingListData[0]?.ranking === 1 && (
                <Link
                    className={element('link')}
                    linkAs={RouterLink}
                    to={userProfileRouteFirstPosition}
                    {...get({ element: 'list-element', action: 'open-learners-Ranking-profile' })}
                >
                    <FlexBox className={element('wrapper')}>
                        <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center} hAlign={Alignment.bottom}>
                            <Thumbnail
                                variant="rounded"
                                className={element('first-avatar')}
                                alt="profile picture"
                                image={rankingListData[0]?.avatar || DEFAULT_AVATAR}
                            />
                            <div className={element('first_position')}>1</div>
                        </FlexBox>
                        <FlexBox className={element('text-wrapper')}>
                            <Text
                                as="p"
                                typography="subtitle1"
                                color="dark"
                                colorVariant="N"
                                className={element('text')}
                                truncate={{ lines: 2 }}
                            >
                                {`${rankingListData[0]?.firstName} \n ${rankingListData[0]?.lastName} \u0008`}
                            </Text>
                            <FlexBox
                                className={element('score-wrapper')}
                                orientation={Orientation.horizontal}
                                vAlign="center"
                                hAlign="center"
                                gap="tiny"
                            >
                                <Text as="p" typography="custom-body" color="dark" colorVariant="L2">
                                    {rankingListData[0]?.totalPoints}
                                </Text>
                                <Icon icon={mdiStar} color={ColorPalette.yellow} />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Link>
            )}
            {rankingListData[2]?.ranking === 3 && (
                <Link
                    className={element('link')}
                    linkAs={RouterLink}
                    to={userProfileRouteThirdPosition}
                    {...get({ element: 'list-element', action: 'open-learners-Ranking-profile' })}
                >
                    <FlexBox className={element('wrapper')}>
                        <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center} hAlign={Alignment.bottom}>
                            <Thumbnail
                                variant="rounded"
                                className={element('third-avatar')}
                                alt="profile picture"
                                image={rankingListData[2]?.avatar || DEFAULT_AVATAR}
                            />
                            <div className={element('third-position')}>3</div>
                        </FlexBox>
                        <FlexBox className={element('text-wrapper')}>
                            <Text
                                as="p"
                                typography="subtitle1"
                                color="dark"
                                colorVariant="N"
                                className={element('text')}
                                truncate={{ lines: 2 }}
                            >
                                {`${rankingListData[2]?.firstName} \n ${rankingListData[2]?.lastName} \u0008`}
                            </Text>
                            <FlexBox
                                className={element('score-wrapper')}
                                orientation={Orientation.horizontal}
                                vAlign="center"
                                hAlign="center"
                                gap="tiny"
                            >
                                <Text as="p" typography="custom-body" color="dark" colorVariant="L2">
                                    {rankingListData[2]?.totalPoints}
                                </Text>
                                <Icon icon={mdiStar} color={ColorPalette.yellow} />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Link>
            )}
        </FlexBox>
    );
};
