import React, { useCallback } from 'react';

import set from 'lodash/set';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { useSelector } from '@lumapps/redux/react';
import { getSocialNetworkAccesses, deleteSocialNetworkAccess } from '@lumapps/sa-connected-user/api';
import {
    isSAEnabled,
    hasLoadedSelector,
    canAccessSocialAdvocacyBasicFeatures,
} from '@lumapps/sa-connected-user/ducks/selectors';
import { GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { fetchSocialNetworksSettings } from '../api';
import { SocialNetworkSettings, UserSocialNetworkAccess, SocialNetworksWithAccess } from '../types';
import { connectSocialNetwork } from '../utils/connect';

export interface UseSocialNetworksWithAccessState {
    /** current status for the retrieval of social networks */
    status: BaseLoadingStatus;
    /** retrieved social networks */
    socialNetworks: SocialNetworksWithAccess[];
    /** status of the disconnect action */
    disconnectingStatus: BaseLoadingStatus;
    /** status of the connect action */
    connectingStatus: BaseLoadingStatus;
    /** disconnect confirmation dialog state */
    confirmDisconnectSocialNetwork: {
        socialNetwork?: SocialNetworksWithAccess;
        isConfirmOpen: boolean;
    };
}

const initialState: UseSocialNetworksWithAccessState = {
    socialNetworks: [],
    status: BaseLoadingStatus.initial,
    disconnectingStatus: BaseLoadingStatus.initial,
    connectingStatus: BaseLoadingStatus.initial,
    confirmDisconnectSocialNetwork: {
        socialNetwork: undefined,
        isConfirmOpen: false,
    },
};

export const { actions, reducer } = createSlice({
    domain: 'social-networks',
    initialState,
    reducers: {
        setSocialNetworks: (state: UseSocialNetworksWithAccessState, action: PayloadAction<any>): void => {
            set(state, 'socialNetworks', action.payload);
        },
        setLoadingStatus: (state: UseSocialNetworksWithAccessState, action: PayloadAction<BaseLoadingStatus>) => {
            set(state, 'status', action.payload);
        },
        setDisconnectingLoadingStatus: (
            state: UseSocialNetworksWithAccessState,
            action: PayloadAction<BaseLoadingStatus>,
        ) => {
            set(state, 'disconnectingStatus', action.payload);
        },
        setConnectingLoadingStatus: (
            state: UseSocialNetworksWithAccessState,
            action: PayloadAction<BaseLoadingStatus>,
        ) => {
            set(state, 'connectingStatus', action.payload);
        },
        setConfirmDisconnectSocialNetwork: (
            state: UseSocialNetworksWithAccessState,
            action: PayloadAction<UseSocialNetworksWithAccessState['confirmDisconnectSocialNetwork']>,
        ) => {
            set(state, 'confirmDisconnectSocialNetwork', action.payload);
        },
    },
});

export interface UseSocialNetworksWithAccess extends UseSocialNetworksWithAccessState {
    /** retrieves the social networks without updating internal loading states. Should be used for quickly refreshing the data */
    fetch: () => void;
    /** retrieves the social networks while managing the internal loading states.   */
    fetchWithStatusChange: (force?: boolean) => void;
    /** function to be executed once we want to connect a user to a social network */
    connect: (socialNetwork: SocialNetworksWithAccess) => void;
    /** function to be executed once the user clicks on a social network's action */
    onSocialNetworkClick: (socialNetwork: SocialNetworksWithAccess) => void;
    /** function to be executed once we want to disconnect a user to a social network */
    disconnect: (socialNetwork: SocialNetworksWithAccess) => void;
    /** toggles the confirmation dialog for disconnecting a user from a social network */
    toggleConfirmDisconnect: (options: UseSocialNetworksWithAccessState['confirmDisconnectSocialNetwork']) => void;
    /** whether there are any social networks or not */
    hasSocialNetworks: boolean;
    /** whether SA has loaded or not */
    hasSALoaded: boolean;
    /** whether the user can access SA basic features */
    canAccessSABasicFeatures: boolean;
    /** is SA enabled */
    isSAOn: boolean;
    /** whether social networks call is in progress or not */
    isLoading: boolean;
    /** whether social networks call is idle or not */
    isIdle: boolean;
    /** whether social networks call returned an error or not */
    hasError: boolean;
}

export const useSocialNetworksWithAccess = (): UseSocialNetworksWithAccess => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { error } = useNotification();
    const isSAOn = useSelector(isSAEnabled);
    const hasSALoaded = useSelector(hasLoadedSelector);
    const canAccessSABasicFeatures = useSelector(canAccessSocialAdvocacyBasicFeatures);

    const fetch = React.useCallback(() => {
        /**
         * In order to display the social networks that the user is connected to, we need to:
         * - Retrieve the social networks configured for the platform
         * - Retrieve whether the current user is logged in to which social networks.
         *
         * After we get that information, we need to:
         * - Create a new list of the social networks with a status `isConnected` which determines whether the
         * current user is connected to the social network or not
         * - Filter out the ones that are not active.
         * - Push the new list to the internal state.
         */
        return Promise.all([getSocialNetworkAccesses(false), fetchSocialNetworksSettings()]).then(
            ([socialNetworkAccessesResponse, socialNetworkSettingsResponse]) => {
                const socialNetworksWithAccess: SocialNetworksWithAccess[] = [];
                const { data: socialNetworkAccesses = [] } = socialNetworkAccessesResponse;
                const { data: socialNetworkSettings = [] } = socialNetworkSettingsResponse;
                /*
                 * Until backend is done we need this part to filter facebook
                 */
                const socialNetworkSettingsWithoutFb = socialNetworkSettings.filter(
                    ({ socialNetworkId }: any) => socialNetworkId !== 'facebook',
                );

                /**
                 * For each social network setting we need to figure out if the current user is logged in
                 * to that network or not. We do this by searching the social network from the list of
                 * known accesses that the user has configured. If there is a hit, it means that the user
                 * is connected to that social network. Therefore => isConnected = true.
                 */
                (socialNetworkSettingsWithoutFb as SocialNetworkSettings[]).forEach((settings) => {
                    const socialNetworkAccess = (socialNetworkAccesses as UserSocialNetworkAccess[]).find(
                        (access) => access.socialNetworkId === settings.socialNetworkId,
                    );

                    const networkWithAccess: SocialNetworksWithAccess = {
                        ...settings,
                        userName: socialNetworkAccess?.userName,
                        isConnected: Boolean(socialNetworkAccess),
                    };

                    if (networkWithAccess.active) {
                        socialNetworksWithAccess.push(networkWithAccess);
                    }
                });

                dispatch(actions.setSocialNetworks(socialNetworksWithAccess));
            },
        );
    }, []);

    /**
     * This allows to fetch the data while managing the internal status for loading.
     */
    const fetchWithStatusChange = useCallback(
        (force = false) => {
            if (state.status === BaseLoadingStatus.initial || force) {
                dispatch(actions.setLoadingStatus(BaseLoadingStatus.loading));

                fetch()
                    .then(() => {
                        dispatch(actions.setLoadingStatus(BaseLoadingStatus.idle));
                    })
                    .catch(() => {
                        dispatch(actions.setLoadingStatus(BaseLoadingStatus.error));
                    });
            }
        },
        [fetch, state.status],
    );

    /**
     * Dispatchs the action for either displaying or hiding the confirm dialog for disconnecting a social network
     * @param options
     */
    const toggleConfirmDisconnect = (options: UseSocialNetworksWithAccessState['confirmDisconnectSocialNetwork']) => {
        dispatch(actions.setConfirmDisconnectSocialNetwork(options));
    };

    /**
     * Connects the user to a given social network by displaying a new modal browser window which guides the user
     * in the given social network.
     * @param socialNetwork
     */
    const connect = (socialNetwork: SocialNetworksWithAccess) => {
        dispatch(actions.setConnectingLoadingStatus(BaseLoadingStatus.loading));

        connectSocialNetwork(socialNetwork.socialNetworkId, () => {
            fetch();

            dispatch(actions.setConnectingLoadingStatus(BaseLoadingStatus.idle));
        }).catch(() => {
            dispatch(actions.setConnectingLoadingStatus(BaseLoadingStatus.error));

            error({
                translate: GLOBAL.GENERIC_ERROR,
            });
        });
    };

    /**
     * Disconnects the user from the given social network.
     * @param socialNetwork.
     */
    const disconnect = (socialNetwork: SocialNetworksWithAccess) => {
        /**
         * We first set the loading state to "loading" and hide the confirm dialog.
         */
        dispatch(actions.setDisconnectingLoadingStatus(BaseLoadingStatus.loading));

        toggleConfirmDisconnect({
            socialNetwork,
            isConfirmOpen: false,
        });

        /**
         * We then execute a request in order to delete the social network from the backend.
         */
        deleteSocialNetworkAccess(socialNetwork.socialNetworkId)
            .then(() => {
                /**
                 * If everything works as expected we:
                 * - Retrieve the updated data from the backend, which refreshes the UI
                 * - Change the loading state back to idle
                 * - Update the internal state with an empty social network
                 */
                fetch();

                dispatch(actions.setDisconnectingLoadingStatus(BaseLoadingStatus.idle));

                toggleConfirmDisconnect({
                    socialNetwork: undefined,
                    isConfirmOpen: false,
                });
            })
            .catch(() => {
                /**
                 * In the case of an error, we want to:
                 * - Change the status to error
                 * - Display a notification saying that there was an error
                 * - Reset the internal state.
                 */
                dispatch(actions.setDisconnectingLoadingStatus(BaseLoadingStatus.error));

                error({
                    translate: GLOBAL.GENERIC_ERROR,
                });

                toggleConfirmDisconnect({
                    socialNetwork: undefined,
                    isConfirmOpen: false,
                });
            });
    };

    /**
     * Callback to be executed once a social network is clicked. If the current user is not connected
     * to the provided social network, we just connect the user, which will open up the registration flow
     * for that specific social network. If the user is connected, we first show a modal window for confirmation,
     * and we then disconnect the user.
     * @param socialNetwork
     */
    const onSocialNetworkClick = (socialNetwork: SocialNetworksWithAccess) => {
        if (!socialNetwork.isConnected) {
            connect(socialNetwork);
        } else {
            toggleConfirmDisconnect({
                socialNetwork,
                isConfirmOpen: true,
            });
        }
    };

    React.useEffect(() => {
        fetchWithStatusChange();
    }, [fetchWithStatusChange, state.status]);

    return {
        ...state,
        fetch,
        fetchWithStatusChange,
        connect,
        disconnect,
        toggleConfirmDisconnect,
        onSocialNetworkClick,
        hasSocialNetworks: state.socialNetworks && state.socialNetworks.length === 0,
        isLoading: state.status === BaseLoadingStatus.loading,
        isIdle: state.status === BaseLoadingStatus.idle,
        hasError: state.status === BaseLoadingStatus.error,
        isSAOn,
        hasSALoaded,
        canAccessSABasicFeatures,
    };
};
