(function IIFE() {
    'use strict';

    /////////////////////////////

    function LoaderController() {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.type = vm.type || 'circular';
        }

        init();
    }

    /////////////////////////////

    /**
     * A directive that displays a loader under given conditions.
     *
     * @param {boolean} [hasBackground] Indicates if we want to force a background colour on the loader.
     * @param {string}  [listKey]       The identifier of the list that is linked to the display of the loader.
     * @param {string}  [method]        The name of the method that toggles the display of the loader.
     * @param {boolean} [noResults]     Indicates if we should display a no result message instead of the loader.
     * @param {Service} [service]       The service to listen to toggle the display of the loader.
     * @param {string}  [theme]         The progress theme.
     * @param {string}  [type]          The type of loader to display.
     *                                  Possible values: 'circular' or 'linear'.
     */

    function LoaderDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LoaderController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                hasBackground: '<?',
                listKey: '@?',
                method: '@?',
                noResults: '<?',
                service: '<?',
                theme: '@?',
                type: '@?',
            },
            templateUrl: '/client/common/modules/loader/views/loader.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('loader', LoaderDirective);
})();
