/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';
import { canManagePlaftormPrograms } from '@lumapps/sa-connected-user/ducks/selectors';

import { ProgramsSideNavigation } from './ProgramsSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: canManagePlaftormPrograms(state),
});

const mapDispatchToProps = {};

const ConnectedProgramsSideNavigation = connect(mapStateToProps, mapDispatchToProps)(ProgramsSideNavigation);

export { ConnectedProgramsSideNavigation as ProgramsSideNavigation };
