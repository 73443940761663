function LsStepController($q, $scope, LxNotificationService, LxUtilsService) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The next step index.
     *
     * @type {number}
     */
    let _nextStepIndex;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The current step data.
     *
     * @type {Object}
     */
    vm.step = {
        errorMessage: undefined,
        feedback: undefined,
        index: undefined,
        isEditable: false,
        isLoading: false,
        isOptional: false,
        isValid: vm.isValid,
        label: undefined,
        pristine: true,
        uuid: LxUtilsService.generateUUID(),
        validator: undefined,
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Update current step on parent controller.
     */
    function _updateParentStep() {
        vm.parent.updateStep(vm.step);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the component classes.
     *
     * @return {Array} The list of classes.
     */
    function getClasses() {
        const classes = [];

        if (vm.step.index === vm.parent.activeIndex) {
            classes.push('ls-step--is-active');
        }

        return classes;
    }

    /**
     * Initialize the controller.
     *
     * @param {Object} parent The parent controller.
     * @param {number} index  The current step index.
     */
    function init(parent, index) {
        vm.parent = parent;
        vm.step.index = index;
        vm.step.validator = vm.validate;

        vm.parent.addStep(vm.step);
    }

    /**
     * Go to previous step.
     */
    function previousStep() {
        if (vm.step.index > 0) {
            vm.parent.goToStep(vm.step.index - 1);
        }
    }

    /**
     * Set step feedback.
     *
     * @param {Object} feedback The step feedback.
     */
    function setFeedback(feedback) {
        vm.step.feedback = feedback;
        _updateParentStep();
    }

    /**
     * Set step label.
     *
     * @param {Object} label The step label.
     */
    function setLabel(label) {
        vm.step.label = label;
        _updateParentStep();
    }

    /**
     * Set step is editable.
     *
     * @param {boolean} isEditable Whether the step is editable or not.
     */
    function setIsEditable(isEditable) {
        vm.step.isEditable = isEditable;
        _updateParentStep();
    }

    /**
     * Set step is optional.
     *
     * @param {boolean} isOptional Whether the step is optional or not.
     */
    function setIsOptional(isOptional) {
        vm.step.isOptional = isOptional;
        _updateParentStep();
    }

    /**
     * Submit the current step.
     */
    function submitStep() {
        if (vm.step.isValid === true && !vm.step.isEditable) {
            vm.parent.goToStep(_nextStepIndex, true);

            return;
        }

        const validateFunction = vm.validate;
        let validity = true;

        if (angular.isFunction(validateFunction)) {
            validity = validateFunction();
        }

        if (validity === true) {
            $scope.$emit('ls-stepper__step-loading', vm.parent.id, vm.step.index);

            vm.step.isLoading = true;
            _updateParentStep();

            let submitFunction = vm.submit;

            if (!angular.isFunction(submitFunction)) {
                submitFunction = function() {
                    return $q((resolve) => {
                        resolve();
                    });
                };
            }

            const promise = submitFunction();

            promise
                .then((nextStepIndex) => {
                    vm.step.isValid = true;
                    _updateParentStep();

                    const isComplete = vm.parent.isComplete();

                    if (isComplete) {
                        $scope.$emit('ls-stepper__completed', vm.parent.id);
                    } else {
                        _nextStepIndex =
                            angular.isDefined(nextStepIndex) &&
                            nextStepIndex > vm.parent.activeIndex &&
                            (!vm.parent.isLinear ||
                                (vm.parent.isLinear && vm.parent.steps[nextStepIndex - 1].isOptional))
                                ? nextStepIndex
                                : vm.step.index + 1;

                        vm.parent.goToStep(_nextStepIndex, true);
                    }
                })
                .catch((exception) => {
                    LxNotificationService.error(exception);
                })
                .finally(() => {
                    $scope.$emit('ls-stepper__step-loaded', vm.parent.id, vm.step.index);
                    vm.step.isLoading = false;
                    _updateParentStep();
                });
        } else {
            vm.step.isValid = false;
            vm.step.errorMessage = validity;
            _updateParentStep();
        }
    }

    /////////////////////////////

    vm.getClasses = getClasses;
    vm.init = init;
    vm.previousStep = previousStep;
    vm.setFeedback = setFeedback;
    vm.setLabel = setLabel;
    vm.setIsEditable = setIsEditable;
    vm.setIsOptional = setIsOptional;
    vm.submitStep = submitStep;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $scope.$on('ls-stepper__submit-step', (evt, id, index) => {
        if ((angular.isDefined(id) && id !== vm.parent.id) || index !== vm.step.index) {
            return;
        }

        submitStep();
    });

    $scope.$on('ls-stepper__previous-step', (evt, id, index) => {
        if ((angular.isDefined(id) && id !== vm.parent.id) || index !== vm.step.index) {
            return;
        }

        previousStep();
    });
}

function LsStepDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].init(ctrls[1], el.index());

        attrs.$observe('lsFeedback', (feedback) => {
            ctrls[0].setFeedback(feedback);
        });

        attrs.$observe('lsLabel', (label) => {
            ctrls[0].setLabel(label);
        });

        attrs.$observe('lsIsEditable', (isEditable) => {
            ctrls[0].setIsEditable(isEditable);
        });

        attrs.$observe('lsIsOptional', (isOptional) => {
            ctrls[0].setIsOptional(isOptional);
        });
    }

    return {
        bindToController: true,
        controller: LsStepController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['lsStep', '^lsStepper'],
        restrict: 'E',
        scope: {
            feedback: '@?lsFeedback',
            isEditable: '=?lsIsEditable',
            isOptional: '=?lsIsOptional',
            isValid: '=?lsIsValid',
            label: '@lsLabel',
            submit: '&?lsSubmit',
            validate: '&?lsValidate',
        },
        templateUrl: '/client/common/modules/stepper/views/step.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsStep', LsStepDirective);

/////////////////////////////

export { LsStepDirective };
