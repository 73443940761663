/* istanbul ignore file */
export enum SPACE_KEYS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=25277234.
     */
    ABOUT_DIALOG_TITLE = 'FRONT.COMMUNITY.ABOUT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7263679.
     */
    ABOUT_TAB_DESCRIPTION = 'GLOBAL.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86406130.
     */
    ABOUT_TAB_MEMBERS = 'FRONT.COMMUNITY.CONFIGURATION_DIALOG.TAB.MEMBERS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=304566305
     */
    ACCESS_REQUEST = 'SPACES.ACCESS_REQUEST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=304566358
     */
    ACCESS_REQUEST_PENDING = 'SPACES.ACCESS_REQUEST_PENDING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=304019043
     */
    COMMUNITY_VISIBILITY_RESTRICTED = 'COMMUNITIES.COMMUNITY_VISIBILITY_RESTRICTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294931537
     */
    GO_TO_ARTICLE_LIST = 'SPACES.GO_TO_ARTICLE_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=299733456
     */
    GO_TO_EVENT_LIST = 'SPACES.GO_TO_EVENT_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=298565600
     */
    NO_MEMBERS = 'SPACES.NO_MEMBERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=365167021
     */
    GOOGLE_DRIVE_FOLDER = 'SPACES.CONFIGURATION_GOOGLE_DRIVE_FOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=365170996
     */
    ONEDRIVE_FOLDER = 'SPACES.CONFIGURATION_ONEDRIVE_FOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=365171061
     * */
    DRIVE_ACCESS_WARNING = 'SPACES.CONFIGURATION_DRIVE_ACCESS_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=365184768
     * */
    THIRD_PARTY_SERVICES = 'SPACES.CONFIGURATION_THIRD_PARTY_SERVICES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=365184772
     * */
    THIRD_PARTY_INTEGRATION = 'SPACES.CONFIGURATION_THIRD_PARTY_INTEGRATION',
}
