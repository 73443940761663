/* istanbul ignore file */
import { isSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { ProvidersSideNavigation } from './ProvidersSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isSuperAdmin(state),
});

const ConnectedProvidersSideNavigation = connect(mapStateToProps)(ProvidersSideNavigation);

export { ConnectedProvidersSideNavigation as ProvidersSideNavigation };
