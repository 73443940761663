import BaseApi from '@lumapps/base-api';

export const favoriteApi = new BaseApi({ path: 'favorite' });

export interface Target {
    kind: string;
    uid: string;
}

export const markFavorite = (params: Target) => favoriteApi.post('mark', { target: params });

export const markUnFavorite = (params: Target) => favoriteApi.delete('having_target', { params });
