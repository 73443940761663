import React from 'react';

import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { SkeletonTypography, SkeletonTypographyProps, SkeletonCircle, Text } from '@lumapps/lumx/react';

import { BASE_CLASSNAME } from '../constants';
import { MenuContext } from '../context';
import { MenuItem } from '../MenuItem';
import { BaseSkeletonProps } from './types';

export interface MenuItemSkeletonProps extends BaseSkeletonProps {
    /**
     * Activate the icon skeleton (before section)
     */
    hasIcon?: boolean;
    /**
     * Customize the default label skeleton.
     */
    label?: Partial<SkeletonTypographyProps>;
    /**
     * Replace the default label skeleton with custom skeleton.
     */
    children?: React.ReactNode;
}

export const CLASSNAME = `${BASE_CLASSNAME}-item-skeleton`;

/**
 * Menu item skeleton.
 *
 * @family Menus
 */
export const MenuItemSkeleton = React.forwardRef<HTMLElement, MenuItemSkeletonProps>(
    ({ hasIcon, label, children, loadingMessage }, ref) => {
        const before = hasIcon && <SkeletonCircle size="xs" />;
        const content = children || <SkeletonTypography typography="body1" width="90%" {...label} />;
        const { block, element } = useClassnames(CLASSNAME);

        const context = React.useContext(MenuContext);
        // In a real menu
        if (context && !context.isLoading) {
            if (!loadingMessage) {
                throw new Error(
                    'Prop `loadingMessage` must be filled to announce the loading state to screen readers.',
                );
            }
            return (
                <MenuItem as="span" before={before} aria-busy="true">
                    {content}
                    <Text as="span" className={visuallyHidden()}>
                        {loadingMessage}
                    </Text>
                </MenuItem>
            );
        }

        return (
            <li ref={ref as React.Ref<HTMLLIElement>} aria-hidden="true" className={block()}>
                {before && <div className={element('before')}>{before}</div>}
                <div className={element('content')}>{content}</div>
            </li>
        );
    },
);
