import React from 'react';

// Properties setting
export enum ListWidgetProperties {
    filters = 'filters',
    maxNumber = 'maxNumber',
    loadMore = 'loadMore',
}

export interface ListWidgetPropertiesSettingType {
    maxNumber?: number;
    hasFilters: boolean;
    canLoadMore: boolean;
}

// Selection setting
export enum SELECTION_TYPE_VALUES {
    ALL_COMMUNITIES = 'all communities',
    CURRENT_COMMUNITY = 'current community',
    DYNAMIC = 'dynamic',
    MANUAL = 'manual',
    MANUAL_COMMUNITY = 'manual community',
}

export interface SelectionSettingType {
    selectedOption?: SELECTION_TYPE_VALUES;
    selectedSubOption?: SELECTION_TYPE_VALUES;
    selectedCommunitiesIds?: string[];
    selectedEntitiesIds?: string[];
}

export interface EntityObject {
    picker: { entityPicker: React.FC; entityPickerProps: React.FC };
    block: { entityBlock: React.FC; entityBlockProps: React.FC; entityBlockSkeleton: React.FC };
    manualButtonTitle?: string;
}

// Style setting
export interface StyleSettingType<VM, VMV> {
    viewMode: VM;
    viewModeVariant: VMV;
    hasSeparator: boolean;
    itemsPerLine: number;
}

export enum AVAILABLE_OPTIONS {
    CAPTION_POSITION = 'captionPosition',
    COLUMN_COUNT = 'columnCount',
    AUTO_PLAY = 'hasAutoPlay',
    INTERVAL = 'interval',
    NAVIGATION_CONTROLS = 'navigationControls',
    ORIENTATION = 'orientation',
    VARIANT = 'viewModeVariant',
    MAIN = 'main',
    SEPARATOR = 'separator',
}

export interface ViewModeType<T> {
    availableOptions?: AVAILABLE_OPTIONS[];
    label: string;
    icon: string;
    value: T;
}
