import React from 'react';

import { SkeletonTypography } from '@lumapps/lumx/react';

import { BASE_CLASSNAME } from '../constants';
import { MenuSection } from '../MenuSection';
import { MenuItemsSkeleton, MenuItemsSkeletonProps } from './MenuItemsSkeleton';

export interface MenuSectionSkeletonProps extends MenuItemsSkeletonProps {
    /**
     * Customize the label skeleton.
     * - use `false` to disable the label skeleton
     * - use any other falsy value (or no value) to activate the default label skeleton
     * - or provide your own custom skeleton.
     */
    label?: React.ReactNode;
    /**
     * Section content.
     */
    children?: React.ReactNode;
}

export const CLASSNAME = `${BASE_CLASSNAME}-section-skeleton`;

/**
 * Menu section skeleton.
 *
 * @family Menus
 */
export const MenuSectionSkeleton = React.forwardRef<HTMLElement, MenuSectionSkeletonProps>(
    ({ label, hasIcon, children, itemCount, loadingMessage }, ref) => {
        let labelSkeleton: React.ReactNode;
        if (label && (React.isValidElement(label) || typeof label === 'string')) {
            // Custom label
            labelSkeleton = label;
        } else if (label === false) {
            // Explicitly disable the label skeleton
            labelSkeleton = undefined;
        } else {
            // Default label skeleton
            labelSkeleton = <SkeletonTypography typography="overline" width="30%" />;
        }

        return (
            <MenuSection ref={ref} className={CLASSNAME} aria-hidden="true" label={labelSkeleton}>
                {children || (
                    <MenuItemsSkeleton hasIcon={hasIcon} itemCount={itemCount} loadingMessage={loadingMessage} />
                )}
            </MenuSection>
        );
    },
);
