import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { TrainingCoursesViewModeVariantType } from '@lumapps/learning-trainings/types';
import { useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { SettingSection } from '@lumapps/widget-settings/components/SettingSection';
import { ViewModeList } from '@lumapps/widget-settings/components/ViewModeList';
import { VIEW_MODE_LIST_VARIANTS } from '@lumapps/widget-settings/constants';
import { ViewModeType } from '@lumapps/widget-settings/types';

import { DATA_SCOPE } from '../../constants';
import { TrainingCoursesInProgressStyleProps } from '../../types';

const CLASSNAME = 'style-setting';

export const TrainingCoursesInProgressStyle: React.FC<TrainingCoursesInProgressStyleProps> = ({
    onChange,
    properties,
}) => {
    const { translateKey } = useTranslate();
    const viewVariantLabelId = React.useMemo(() => uniqueId(`${CLASSNAME}-view-variant-label-`), []);
    const initViewModeVariant =
        VIEW_MODE_LIST_VARIANTS.find((view) => view.value === properties?.viewModeVariant) ||
        VIEW_MODE_LIST_VARIANTS[0];
    const [selectedViewModeVariant, setSelectedViewModeVariant] = React.useState(
        initViewModeVariant as ViewModeType<TrainingCoursesViewModeVariantType>,
    );
    const onViewModeChange = (viewMode: ViewModeType<TrainingCoursesViewModeVariantType>) => {
        const isViewModeVariant = VIEW_MODE_LIST_VARIANTS.includes(viewMode);

        if (isViewModeVariant) {
            setSelectedViewModeVariant(viewMode);
        }
    };
    React.useEffect(() => {
        onChange({
            viewModeVariant: selectedViewModeVariant.value,
        });
    }, [selectedViewModeVariant, onChange]);
    return (
        <SettingSection title={translateKey(WIDGET_BASE.SETTINGS_VARIANTS)} titleId={viewVariantLabelId}>
            <ViewModeList<TrainingCoursesViewModeVariantType>
                currentViewMode={selectedViewModeVariant}
                onViewModeChange={onViewModeChange}
                dataScope={DATA_SCOPE}
                viewModes={VIEW_MODE_LIST_VARIANTS}
            />
        </SettingSection>
    );
};
