import React, { Dispatch } from 'react';

import { parseJson, stringify } from '@lumapps/utils/object/parse';

import { findInPersistentStore, storePersistent } from '../api/persistentStore';

/**
 * Act like the React useState. Instead of storing the data in memory, it will store it in the localStorage.
 * If the localStorage is updated (using the cache api provided in the cache package) this hook will be notified
 * and will trigger change.
 * With this mecanism, the data of the component and the data stored in the localStorage are synced
 * @param key the key of the item in the persistent store
 * @param defaultValue the value to retrieve if the item was not found
 */
export const usePersistentStore = <T extends object>(key: string, defaultValue?: T): [T, Dispatch<T>] => {
    const storageItem = findInPersistentStore(key);
    const [storedValue, setValue] = React.useState(storageItem ? parseJson(storageItem, defaultValue) : defaultValue);

    const setLocalItem = React.useCallback(() => {
        /** local storage update is not that fast */
        /** it makes sure that we are getting the new value  */
        setTimeout(() => {
            const itemValueFromStorage = findInPersistentStore(key);
            const value = itemValueFromStorage && parseJson(itemValueFromStorage);
            setValue(value || defaultValue);
        }, 50);
    }, [key, defaultValue]);

    const setStoredValue = (value: T) => {
        const parsedValue = typeof value === 'object' ? stringify(value) : value;
        if (parsedValue) {
            storePersistent(key, parsedValue as string);
        }
    };

    React.useEffect(() => {
        document.addEventListener('cacheChange', setLocalItem, false);

        return () => document.removeEventListener('cacheChange', setLocalItem);
    }, [setLocalItem]);

    return [storedValue, setStoredValue];
};
