import React from 'react';

import { mdiClose, mdiMenuDown } from '@lumapps/lumx/icons';
import {
    Chip,
    ChipProps,
    DatePicker,
    DatePickerProps,
    Icon,
    PopoverDialog,
    PopoverProps,
    Size,
    Theme,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { focusElement } from '@lumapps/utils/hooks/useFocus';
import { getMoment } from '@lumapps/utils/time/getMoment';

export interface DatePickerChipProps {
    /** custom classname that will be applied to the component wrapper */
    className?: string;
    /** name that will be used for the DatePicker name prop  */
    name: string;
    /** label to be displayed on the chip when value is undefined */
    label: string;
    /** local to use */
    locale: string;
    /** theme */
    theme?: Theme;
    /** selected value */
    value?: string;
    /** function that will be executed in order to format the displayed value */
    valueDisplay?(date: string): string;
    /** additional props for the date picker */
    datePickerProps?: Partial<DatePickerProps>;
    /** additional props for the popover component */
    popoverProps?: Partial<PopoverProps>;
    /** additional props for the chip component */
    chipProps?: Partial<ChipProps>;
    /** on clear callback */
    onClear?(): void;
    /** function that will be executed when a date is selected. */
    onChange(value: Date | null | undefined, name: string): void;
}

/**
 * Date picker chip only to be used on this package, since it is not what we usually use across our product.
 */
export const DatePickerChip: React.FC<DatePickerChipProps> = ({
    className,
    value,
    valueDisplay,
    onClear,
    onChange,
    name,
    label,
    popoverProps,
    datePickerProps,
    locale,
    chipProps,
    theme,
}: DatePickerChipProps) => {
    const [isPickerOpen, togglePicker, closePicker] = useBooleanState(false);
    const chipRef = React.useRef(null);
    const popoverRef = React.useRef(null);
    const todayOrSelectedDateRef = React.useRef(null);

    const onDatePickerChange = (newDate: Date | null | undefined) => {
        if (isPickerOpen) {
            onChange(newDate, name);
        }

        closePicker();
        focusElement(chipRef);
    };

    const handleChipClick = (event: Event) => {
        event.preventDefault();
        togglePicker();
    };

    const handleOnAfterClick = () => {
        if (onClear) {
            onClear();
            // Close picker when the popover is open on user clear click
            closePicker();
        }
        // Open/Close picker, using the mdiMenuDown button, if date not selected
        if (!onClear) {
            togglePicker();
        }
    };

    const { translateKey } = useTranslate();

    const displayedValue = valueDisplay && value ? valueDisplay(value) : value;

    return (
        <div className={className}>
            <Chip
                ref={chipRef}
                isClickable
                onClick={handleChipClick}
                isSelected={Boolean(value)}
                after={
                    Boolean(value) && onClear ? <Icon icon={mdiClose} size={Size.xs} /> : <Icon icon={mdiMenuDown} />
                }
                onAfterClick={handleOnAfterClick}
                theme={theme}
                aria-expanded={isPickerOpen}
                aria-haspopup
                {...chipProps}
            >
                {displayedValue || label}
            </Chip>
            <PopoverDialog
                isOpen={isPickerOpen}
                anchorRef={chipRef}
                closeOnClickAway
                onClose={closePicker}
                closeOnEscape
                focusAnchorOnClose
                placement="bottom-start"
                aria-label={translateKey(GLOBAL.DATE_PICKER)}
                focusElement={value ? todayOrSelectedDateRef : undefined}
                ref={popoverRef}
                {...popoverProps}
            >
                <DatePicker
                    locale={locale}
                    onChange={onDatePickerChange}
                    name={name}
                    nextButtonProps={{
                        label: translateKey(GLOBAL.NEXT_MONTH),
                    }}
                    value={value ? getMoment()(`${value}Z`).toDate() : undefined}
                    previousButtonProps={{
                        label: translateKey(GLOBAL.PREVIOUS_MONTH),
                    }}
                    todayOrSelectedDateRef={todayOrSelectedDateRef}
                    {...datePickerProps}
                />
            </PopoverDialog>
        </div>
    );
};
