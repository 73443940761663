import React, { useCallback, useMemo, useState } from 'react';

import { getUserMsToken } from '@lumapps/user/api';
import { UserMicrosoftToken } from '@lumapps/user/interface';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { OneDrivePicker as Picker } from '../components/OneDrivePicker/OneDrivePicker';
import { OneDriveDocument, OneDrivePickerConfiguration } from '../types';

/**
 * Provide an interface to fetch document from microsoft OneDrive provider.
 * Expose both a Picker component and a callback method to open it.
 * */
export const useOneDrivePicker = (
    pickerConfig: OneDrivePickerConfiguration,
    onFilesSelected: (files: OneDriveDocument) => void,
    userToken?: UserMicrosoftToken,
) => {
    const [isOpen, , close, open] = useBooleanState(false);
    const [token, setToken] = useState<UserMicrosoftToken | Promise<UserMicrosoftToken | undefined> | undefined>(
        userToken,
    );

    const openOneDrivePicker = async () => {
        const userToken =
            token ??
            getUserMsToken()
                .then(({ data }) => data)
                .catch(() => undefined);

        setToken(userToken);
        open();
    };

    const onSelect = useCallback(
        (files: OneDriveDocument) => {
            onFilesSelected(files);
            close();
        },
        [close, onFilesSelected],
    );

    const OneDrivePicker = useMemo(() => {
        return <Picker token={token} pickerConfig={pickerConfig} isOpen={isOpen} onClose={close} onSelect={onSelect} />;
    }, [close, isOpen, onSelect, pickerConfig, token]);

    return { openOneDrivePicker, OneDrivePicker };
};
