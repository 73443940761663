import { createContext } from 'react';

import { RegisterAriaIdPayload } from '../ducks/reducer';

export type AriaIdsRegisterContextOptions = {
    /** The id to add to the list */
    add: (payload: RegisterAriaIdPayload) => void;
    /** The id to remove from the list */
    remove: (payload: RegisterAriaIdPayload) => void;
};

/** Context to store a callback to update the stored ids.  */
export const AriaIdsRegisterContext = createContext<AriaIdsRegisterContextOptions>({
    add: () => null,
    remove: () => null,
});
