export enum ONEDRIVE_PICKER_KEYS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457977152
     * */
    FILE_HELPER = 'ONEDRIVE_PICKER.FILE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457982274
     * */
    FILE_PLACEHOLDER = 'ONEDRIVE_PICKER.FILE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457977216
     * */
    INSERT_FILE = 'ONEDRIVE_PICKER.INSERT_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=361216597
     * */
    TITLE = 'ONEDRIVE_PICKER.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=361216618
     * */
    TEXT_FIELD = 'ONEDRIVE_PICKER.TEXT_FIELD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=361216623
     * */
    TEXT_FIELD_PLACEHOLDER = 'ONEDRIVE_PICKER.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=364846788
     * */
    MESSAGE_TITLE = 'ONEDRIVE_PICKER.MESSAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=364846858
     * */
    MESSAGE_STEP_ONE = 'ONEDRIVE_PICKER.MESSAGE_STEP_ONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=364846866
     * */
    MESSAGE_STEP_TWO = 'ONEDRIVE_PICKER.MESSAGE_STEP_TWO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=364846918
     * */
    MESSAGE_STEP_THREE = 'ONEDRIVE_PICKER.MESSAGE_STEP_THREE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=361934145
     * */
    NO_ACCESS = 'ONEDRIVE_PICKER.NO_ACCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457976812
     * */
    NOT_SHAREPOINT_URL_ERROR = 'ONEDRIVE_PICKER.NOT_SHAREPOINT_URL_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457976306
     * */
    SEARCH_IN_SHAREPOINT = 'ONEDRIVE_PICKER.SEARCH_IN_SHAREPOINT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=364857126
     * */
    WRONG_FORMAT = 'ONEDRIVE_PICKER.WRONG_FORMAT',
}
