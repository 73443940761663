import React from 'react';

import includes from 'lodash/includes';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Theme } from '@lumapps/lumx/react';
import { MetadataPickerFieldMultiple } from '@lumapps/metadata-pickers/components/MetadataPickerFieldMultiple';
import { PickerMetaData, MetadataFilter } from '@lumapps/metadata-pickers/types';
import { useSelector } from '@lumapps/redux/react';

interface MultiSelectMetadataFilterProps {
    /** Ids of selected metadata */
    values: string[];
    /** Label */
    label?: string;
    /** Family id of metadata */
    rootId: string;
    /** Metadata initial database */
    defaultDictionary: Record<string, MetadataFilter>;
    /** Handler select */
    handleSelectMetadata: (values?: MetadataFilter[]) => void;
    /** Handler remove */
    handleRemoveMetadata: (metadataId?: string) => void;
    /** Scope */
    scope?: string;
    /** Theme */
    theme: Theme;
}

/**
 * Component that displays a multi select filter that allow the selection of metadata inherited from the rootId provided.
 * This component is potentially used several times inside one main componenet, that is why we are needing two handlers:
 * - handleRemoveMetadata that will take the removed metadata id as param
 * - handleSelectMetadatathat that will take the whole list of metadata selected in this select as params
 * @family Filters
 * @param MultiSelectMetadataFilterProps
 * @returns MultiSelectMetadataFilter
 */
export const MultiSelectMetadataFilter = ({
    values,
    label,
    rootId,
    defaultDictionary,
    handleRemoveMetadata,
    handleSelectMetadata,
    scope,
    theme,
}: MultiSelectMetadataFilterProps) => {
    const instanceId = useSelector(instanceIdSelector);
    const handleSelect = (newValues: PickerMetaData[] | undefined = []) => {
        if (newValues?.length < values?.length) {
            /**
             * Metadata clicked
             * handleRemoveMetadata callback is triggered with the clicked metadata id
             * */
            const newMetadataIds = newValues?.map(({ id }) => id);
            const clickedMetadata = values?.find((id) => !includes(newMetadataIds, id));
            handleRemoveMetadata(clickedMetadata);
        } else {
            /**
             * Metadata selected
             * handleSelectMetadata callback is triggered with all the selected metadata
             * */
            const metadata = newValues?.map(({ name, id }) => ({ name, id, rootId }));
            handleSelectMetadata(metadata);
        }
    };

    return (
        <MetadataPickerFieldMultiple
            scope={scope}
            theme={theme}
            label={label}
            onChange={handleSelect}
            familyId={rootId}
            instanceId={instanceId}
            value={values}
            defaultDictionary={defaultDictionary}
            showMultipleLevels
            useInstanceMetadata
        />
    );
};
