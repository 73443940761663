import React, { useCallback, useEffect, useState } from 'react';
import { boolean, number, shape, string, oneOfType } from 'prop-types';

import { ImageBlock, Lightbox, Slideshow, SlideshowItem } from '@lumapps/lumx/react';
import { doesBlobExist, getBlob } from '@lumapps/lumx-images/components/BlobThumbnail/store';

import loFind from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { GridView, Loader } from 'components/components/ui';
import { translate as t } from 'components/translations';
import { getWidgetContentStyle } from 'components/services/styles';
import { isAutoTestModeEnabled, stripHtmlTags } from 'components/utils';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useSlideshowControlLabels } from '@lumapps/utils/hooks/useSlideshowControlLabels';
import { getMediaContentByLang, getCroppedThumbnailFromContent } from 'components/components/document/utils';
import { PADDING_PROPERTY_NAMES } from 'components/components/styles/constants';
import { InitialSettings } from 'common/modules/constants/initial-settings_constants';

import { NAVIGATION_TYPES_VALUES, VIEW_MODES_VALUES, VIEW_MODE_VARIANTS_VALUES } from '../constants';
import { ORIENTATIONS_VALUES, CAPTION_POSITIONS } from './constants';
import { widgetType as widgetPropTypes } from '../types';
import { WidgetContent } from '../WidgetContent';
import {
    captionPositionTypes,
    navigationControlsTypes,
    orientationTypes,
    viewModeTypes,
    viewModeVariantTypes,
} from './types';

const { MAX_IMAGE_SIZE } = InitialSettings;

/**
 * The default padding to apply to the image block caption if there is none.
 *
 * @type {number}
 * @constant
 */
const IMAGE_BLOCK_DEFAULT_PADDING = 16;

/**
 * Used to multiply seconds to milliseconds.
 *
 * @type {number}
 * @constant
 */
const MILLISECONDS_TO_SECONDS = 1000;

/**
 * Displays the widget image gallery component.
 *
 * @return {Element} The image gallery react element.
 */
const WidgetImageGallery = ({
    documentList,
    isLoading,
    isMainWidget,
    properties,
    fetchImages,
    fetchNextPage,
    uuid,
    widgetType,
}) => {
    const { translateKey } = useTranslate();
    const slideshowLabels = useSlideshowControlLabels();
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    properties = { ...WidgetImageGallery.defaultProps.properties, ...properties };

    const {
        captionPosition,
        columnCount = WidgetImageGallery.defaultProps.properties.columnCount,
        fields,
        orientation,
        overrides,
        style,
        viewMode,
        viewModeVariant = VIEW_MODE_VARIANTS_VALUES.GROUP,
    } = properties;

    const theme = get(style, ['content', 'theme'], 'light');

    /**
     * Defines all query parameters to send to the document API.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const requestParams = {
        displayType: properties.displayType,
        docPath: properties.docPath,
        files: properties.files,
        mediaIds: properties.mediaIds,
        folder: properties.folder,
        searchTags: properties.folderTags,
        maxNumber: properties.maxNumber,
        uuid,
    };

    /**
     * The current stringified request parameters. The value is used as dependency
     * by the `uesEffect` hook just below and is stringified to avoid
     * side effects and inifinite loops by making a static check on a simple string.
     *
     * @type {string}
     * @constant
     */
    const stringifiedParams = JSON.stringify(requestParams);

    /**
     * On used when `requestParams` changes.
     */
    useEffect(
        () => {
            documentList.items = [];
            fetchImages(requestParams);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchImages, stringifiedParams],
    );

    const { items = [] } = documentList;

    /**
     * Renders the display components depending on the `viewMode` property value.
     *
     * @param  {Element[]} list The array of elements to display.
     * @return {Element}   The proper components to display.
     */
    const renderDisplayComponent = (list) => {
        const {
            hasAutoplay,
            interval = WidgetImageGallery.defaultProps.properties.interval,
            navigationControls,
        } = properties;

        switch (viewMode) {
            case VIEW_MODES_VALUES.SLIDESHOW:
                return (
                    <Slideshow
                        autoPlay={hasAutoplay && !isAutoTestModeEnabled()}
                        fillHeight={
                            (viewMode === VIEW_MODES_VALUES.SLIDESHOW &&
                                orientation === ORIENTATIONS_VALUES.ORIGINAL) ||
                            get(style, ['content', 'height'], 0) > 0
                        }
                        slideshowControlsProps={
                            navigationControls
                                ? {
                                      nextButtonProps: { label: slideshowLabels.next },
                                      previousButtonProps: { label: slideshowLabels.previous },
                                      paginationItemProps: (index) => ({
                                          label: slideshowLabels.paginationItem(index),
                                      }),
                                  }
                                : undefined
                        }
                        slideGroupLabel={slideshowLabels.slideGroup}
                        interval={parseInt(interval, 10) * MILLISECONDS_TO_SECONDS}
                        theme={theme}
                    >
                        {list.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SlideshowItem key={index}>{item}</SlideshowItem>
                        ))}
                    </Slideshow>
                );
            case VIEW_MODES_VALUES.GRID:
            // eslint-disable-next-line no-fallthrough, padding-line-between-statements
            default:
                return (
                    <GridView columnCount={parseInt(columnCount, 10)} list={list} parentWidgetProperties={properties} />
                );
        }
    };

    /**
     * Gets a document property by name.
     *
     * @param  {string} id           The document's id.
     * @param  {string} propertyName The name of the property to get.
     * @return {string} The property value.
     */
    const getDocumentProperty = ({ id, propertyName }) => {
        if (!overrides || !overrides[id]) {
            return undefined;
        }

        return !isEmpty(t(overrides[id][propertyName])) && translateKey(overrides[id][propertyName]);
    };

    /**
     * On image click, display a lighbox containing the slideshow with all images.
     *
     * @param {Event}  evt   The event triggering this method.
     * @param {number} index The index to go to.
     */
    const handleOnClick = (evt, index) => {
        if (viewMode !== VIEW_MODES_VALUES.GRID) {
            return;
        }

        setCurrentIndex(index);
        setIsLightboxOpen(true);
    };

    const hasFieldEnabled = (fieldName) => {
        const field = loFind(fields, {
            name: fieldName,
        });

        return field ? field.enable : false;
    };

    /**
     * On lightbox close, set it's opened value to false to hide it.
     */
    const onLightboxClose = useCallback(() => {
        setIsLightboxOpen(false);
    }, []);

    /**
     * Renders an `Imageblock`.
     *
     * @param  {Object}       doc           The document to display in the image block.
     * @param  {number}       index         The current image index.
     * @param  {Object}       propOverrides The props to overrides if needed.
     * @return {ReactElement} The correctly formatted image block.
     */
    const renderImageBlock = ({ doc, index, propOverrides = {}, size = MAX_IMAGE_SIZE }) => {
        const content = getMediaContentByLang({ media: doc, useFallback: true });
        const description =
            getDocumentProperty({
                id: content.fileId,
                propertyName: 'description',
            }) || translateKey(doc.description);
        const title = hasFieldEnabled('name')
            ? getDocumentProperty({
                  id: content.fileId,
                  propertyName: 'name',
              }) || translateKey(doc.name || content.name)
            : undefined;

        let image = getCroppedThumbnailFromContent(content, size);

        if (doesBlobExist(content.value)) {
            image = getBlob(content.value);
        }

        const props = mergeObjectOnly(
            {
                align: 'center',
                alt: description,
                thumbnailProps: {
                    aspectRatio: orientation,
                    focusPoint: content.focalPoint,
                    onClick: viewMode === VIEW_MODES_VALUES.SLIDESHOW ? null : (evt) => handleOnClick(evt, index),
                    style: { width: '100%' },
                },
                captionPosition,
                captionStyle:
                    viewModeVariant === 'ungroup'
                        ? {
                              padding: IMAGE_BLOCK_DEFAULT_PADDING,
                              ...pick(getWidgetContentStyle(properties), PADDING_PROPERTY_NAMES),
                          }
                        : null,
                className: 'widget-image-gallery_item',
                description:
                    hasFieldEnabled('description') && !isEmpty(description) && stripHtmlTags(description)
                        ? {
                              __html: stripHtmlTags(description),
                          }
                        : undefined,
                fillHeight:
                    viewMode === VIEW_MODES_VALUES.SLIDESHOW &&
                    (orientation === ORIENTATIONS_VALUES.ORIGINAL || get(style, ['content', 'height'], 0) > 0),
                image,
                key: doc.id,
                theme,
                title,
            },
            propOverrides,
        );

        return <ImageBlock {...props} />;
    };

    /**
     * Whether the widget is empty or not.
     *
     * @type {boolean}
     */
    const isWidgetEmpty = !isLoading && isEmpty(items);

    /**
     * In other modes than slideshow, crop the image regarding `columnCount` value.
     *
     * @type {number}
     * @constant
     */
    const imageSizeForColumnCount =
        viewMode === VIEW_MODES_VALUES.SLIDESHOW ? MAX_IMAGE_SIZE : Math.round(MAX_IMAGE_SIZE / columnCount);

    return (
        <WidgetContent isWidgetEmpty={isWidgetEmpty} properties={properties} widgetType={widgetType}>
            {!isEmpty(items) &&
                renderDisplayComponent(
                    items.map((doc, index) => renderImageBlock({ doc, index, size: imageSizeForColumnCount })),
                )}
            {isMainWidget && !isEmpty(items) && <InfiniteScroll callback={() => fetchNextPage(requestParams)} />}
            {isLoading && <Loader isLoading={isLoading} />}
            {properties.viewMode === VIEW_MODES_VALUES.GRID && (
                <Lightbox
                    isOpen={isLightboxOpen}
                    onClose={onLightboxClose}
                    closeButtonProps={{ label: translateKey(GLOBAL.CLOSE) }}
                >
                    <Slideshow
                        fillHeight
                        slideshowControlsProps={{
                            nextButtonProps: { label: slideshowLabels.next },
                            previousButtonProps: { label: slideshowLabels.previous },
                            paginationItemProps: (index) => ({
                                label: slideshowLabels.paginationItem(index),
                            }),
                        }}
                        slideGroupLabel={slideshowLabels.slideGroup}
                        activeIndex={currentIndex}
                        theme="dark"
                    >
                        {items.map((doc, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <SlideshowItem key={`${index}${doc.id}`}>
                                {renderImageBlock({
                                    doc,
                                    propOverrides: {
                                        thumbnailProps: {
                                            aspectRatio: 'original',
                                            onClick: undefined,
                                        },
                                        captionPosition: CAPTION_POSITIONS.BELOW,
                                        captionStyle: undefined,
                                        fillHeight: true,
                                        theme: 'dark',
                                    },
                                })}
                            </SlideshowItem>
                        ))}
                    </Slideshow>
                </Lightbox>
            )}
        </WidgetContent>
    );
};

WidgetImageGallery.propTypes = {
    ...widgetPropTypes,
    properties: shape({
        ...widgetPropTypes.properties,
        /** The image caption positions. */
        captionPosition: captionPositionTypes,
        /** The number of columns in grid mode. */
        columnCount: oneOfType([number, string]),
        /** Whether the autoplay is activated for slideshow. */
        hasAutoplay: boolean,
        /** The interval between slides in autoplayed slideshow mode. */
        interval: number,
        /** The number of items we want to display, or we want to fetch in pagination. */
        maxNumber: number,
        /** Whether we want to display the navigation controls in slideshow. */
        navigationControls: boolean,
        /** The navigation type if navigation controls are enabled. */
        navigationType: navigationControlsTypes,
        /** The image orientations. */
        orientation: orientationTypes,
        /** The gallery view mode. */
        viewMode: viewModeTypes,
        /** The view mode variants if there is some. */
        viewModeVariant: viewModeVariantTypes,
    }),
};

WidgetImageGallery.defaultProps = {
    properties: {
        captionPosition: CAPTION_POSITIONS.BELOW,
        columnCount: 3,
        hasAutoplay: true,
        interval: 5,
        maxNumber: 30,
        navigationControls: true,
        navigationType: NAVIGATION_TYPES_VALUES.BULLETS,
        orientation: ORIENTATIONS_VALUES.HORIZONTAL,
        viewMode: VIEW_MODES_VALUES.GRID,
        viewModeVariant: VIEW_MODE_VARIANTS_VALUES.GROUP,
    },
};

WidgetImageGallery.isEditable = () => false;
WidgetImageGallery.isWidgetEmpty = (props) => {
    const widget = props.widgetEntities.find((widget)=> widget.uuid === props.component.uuid)
    const isEmpty = widget?.documentList?.items ? !widget.documentList.items.length : true
    return (
        !props.component.properties?.noResults && isEmpty
    );
};

export { WidgetImageGallery };
