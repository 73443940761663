function CommunitySearchFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/community/:methodKey`,
        {},
        {
            searchCommunitiesForSharing: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'searchForSharing',
                },
            },
        },
    );
}

/////////////////////////////

angular.module('Factories').factory('CommunitySearchFactory', CommunitySearchFactory);

/////////////////////////////

export { CommunitySearchFactory };
