(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetUserDirectorySearchSettingsController(Config, Content) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Filter list key.
         *
         * @type {string}
         */
        vm.filterListKey = 'userDirectorySearch';

        /**
         * Users directories targetted.
         *
         * @type {Array}
         */
        vm.userDirectories = [];

        /////////////////////////////

        /**
        * Services and utilities.
        */
        vm.Content = Content;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
        * Initialize the controller.
        */

        function init() {
            Content.filterize({
                type: Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY,
            }, undefined, undefined, vm.filterListKey);
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetUserDirectorySearchSettingsController',
        WidgetUserDirectorySearchSettingsController);
})();
