import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetCommunityListSettingsController($rootScope, $scope, $timeout, Config, Content, ContentPicker,
        ContentTemplate, CustomContentType, Features, Instance, Translation, Utils, Widget) {
        'ngInject';

        var widgetCommunityListSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the content picker.
         *
         * @type {string}
         * @constant
         */
        widgetCommunityListSettings.CONTENT_PICKER_ID = 'widget-community-list-settings-content-picker-';

        /**
         * The traduction key to use as content picker title prefix.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        widgetCommunityListSettings.PICKER_TITLE_KEY = 'GLOBAL.COMMUNITY';

        /**
         * The list key of the widget.
         *
         * @type {string}
         */
        widgetCommunityListSettings.listkey = '';

        /**
         * Whether to dipslay the filters in the settings or not. Based on the feature flags.
         *
         * @type {boolean}
         */
        widgetCommunityListSettings.showFilters = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetCommunityListSettings.Config = Config;
        widgetCommunityListSettings.Content = Content;
        widgetCommunityListSettings.CustomContentType = CustomContentType;
        widgetCommunityListSettings.Instance = Instance;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get fields filtered.
         *
         * @param  {Array}   fields           The currentWidget properties fields.
         * @param  {boolean} hasSocialFeature Whether the customer has Social Feature.
         * @return {Array}   Filtered list.
        */
        function _getFieldsFiltered(fields, hasSocialFeature) {
            if (hasSocialFeature) {
                return fields;
            }

            return _.filter(fields, function filterField(field) {
                return field.name !== 'follow';
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Defines if the instance name option should be displayed or not.
         * The option is shown when :
         *  - All communities from all instances are displayed
         *  - When multiple communities are selected in dynamic selection
         *  - When we are in manual selection. Since there is no way to know if multiple communities
         *    from multiple instances are manually picked, we can give the choice to the user to display the
         *    the instance name or not.
         *
         * @return {boolean} Whether the option is available or not.
         */
        function isInstanceNameOption() {
            var properties = Widget.getCurrent().properties || {};

            return properties.isAllInstancesSiblings || _.get(properties, 'instance', []).length > 1 ||
                properties.displayType === 'pick';
        }

        /**
         * Open the content picker to select what community we want to list.
         */
        function openContentPicker() {
            Utils.waitForAndExecute('#' + widgetCommunityListSettings.CONTENT_PICKER_ID, ContentPicker);
        }

        /**
         * If this widget is now a "Main Widget", remove the isMainWidget attribute to the other widgets.
         * Todo [Arnaud]: move this to widget list directive.
         */
        function setAsMainWidget() {
            var currentWidget = Widget.getCurrent();
            if (currentWidget.isMainWidget) {
                var widgetList = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isMainWidget', true);
                angular.forEach(widgetList, function forEachWidget(widget) {
                    if (widget.uuid !== currentWidget.uuid) {
                        widget.isMainWidget = false;
                    }
                });
            }

            $rootScope.$broadcast('widget-is-main-toggled', currentWidget.uuid, currentWidget.isMainWidget);
        }

        /**
         * Switch widget view mode.
         *
         * @param {string} viewMode The wanted view mode.
         */
        function switchViewMode(viewMode) {
            if (Widget.getCurrent().properties.viewMode === viewMode) {
                return;
            }

            Widget.getCurrent().properties.viewMode = viewMode;
            Widget.getCurrent().properties.viewModeVariant = 'group';

            widgetCommunityListSettings.updateWidget();
        }

        /**
         * Switch widget view mode variant.
         *
         * @param {string} viewModeVariant The wanted view mode variant.
         */
        function switchViewModeVariant(viewModeVariant) {
            if (Widget.getCurrent().properties.viewModeVariant === viewModeVariant) {
                return;
            }

            Widget.getCurrent().properties.viewModeVariant = viewModeVariant;

            widgetCommunityListSettings.updateWidget();
        }

        /**
         * When a field is toggled.
         *
         * @param {Object} field The field that is toggled.
         */
        function toggleField(field) {
            if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
                return;
            }

            field.enable = !field.enable;

            widgetCommunityListSettings.updateWidget();
        }

        function getFieldLabel(field) {
            if (angular.isUndefined(field) || angular.isUndefinedOrEmpty(field.name)) {
                return;
            }

            const fieldName = field.name.toUpperCase();

            // Title is a generic key, we add an exception to optimize our lokalise keys use.
            if (fieldName === 'TITLE') {
                return Translation.translate('GLOBAL.TITLE')
            }
            
            return Translation.translate(`WIDGET_TYPE_COMMUNITY-LIST_${fieldName}`);
        }

        /**
         * Toggles the 'isInstanceNameDisplayed' property on current widget.
         */
        function toggleInstanceNameOption() {
            var properties = Widget.getCurrent().properties;
            properties.isInstanceNameDisplayed = !properties.isInstanceNameDisplayed;

            widgetCommunityListSettings.updateWidget();
        }

        /**
         * Updates the instance name display property value according to `isAllInstancesSiblings` property value.
         */
        function updateInstanceNameProperty() {
            var properties = Widget.getCurrent().properties || {};

            if (properties.isAllInstancesSiblings) {
                properties.isInstanceNameDisplayed = true;
            }

            widgetCommunityListSettings.updateWidget();
        }

        /**
         * Update widget on settings change.
         */
        function updateWidget() {
            var currentWidget = Widget.getCurrent();
            var properties = currentWidget.properties || {};

            if (!properties.isAllInstancesSiblings && properties.instance.length === 1 &&
                !properties.displayType === 'pick') {
                properties.isInstanceNameDisplayed = false;
            }

            $timeout(function timeout() {
                $rootScope.$broadcast('widget-community-list-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetCommunityListSettings.isInstanceNameOption = isInstanceNameOption;
        widgetCommunityListSettings.openContentPicker = openContentPicker;
        widgetCommunityListSettings.setAsMainWidget = setAsMainWidget;
        widgetCommunityListSettings.switchViewMode = switchViewMode;
        widgetCommunityListSettings.switchViewModeVariant = switchViewModeVariant;
        widgetCommunityListSettings.toggleField = toggleField;
        widgetCommunityListSettings.getFieldLabel = getFieldLabel;
        widgetCommunityListSettings.toggleInstanceNameOption = toggleInstanceNameOption;
        widgetCommunityListSettings.updateInstanceNameProperty = updateInstanceNameProperty;
        widgetCommunityListSettings.updateWidget = updateWidget;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the content picker closes.
         * Update the widget to display the new community list.
         */
        $scope.$on('content-picker__close-start', widgetCommunityListSettings.updateWidget);

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentWidget = Widget.getCurrent();
            var idCurrentWidget = _.get(currentWidget, 'uuid', '');
            var idCurrentContent = _.get(Content.getCurrent(), 'id');
            var listKeyPrefix = 'widget-' + idCurrentWidget + '-';
            var hasSocialFeature = Features.hasFeature('social');

            widgetCommunityListSettings.CONTENT_PICKER_ID += idCurrentWidget;

            currentWidget.properties.fields = _getFieldsFiltered(
                _.get(currentWidget, 'properties.fields'), hasSocialFeature);

            widgetCommunityListSettings.listkey = (angular.isDefinedAndFilled(idCurrentContent)) ?
                listKeyPrefix + idCurrentContent :
                listKeyPrefix + generateUUID();

            widgetCommunityListSettings.showFilters = hasSocialFeature;

            currentWidget.properties.communityIds = currentWidget.properties.communityIds || [];

            // By default, list all communities a user has access to.
            if (angular.isUndefinedOrEmpty(currentWidget.properties.displayType)) {
                currentWidget.properties.displayType = 'list';
            }
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetCommunityListSettingsController',
        WidgetCommunityListSettingsController);
})();
