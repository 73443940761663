import React from 'react';

/**
 * Check if a ReactNode was created by a Component.
 *
 * @example
 *      isComponentType(Component1, <Component1 />) // => true
 *      isComponentType(Component1, <Component2 />) // => false
 */
export const isComponentType = (
    type: React.ReactElement['type'],
    node: React.ReactNode,
): node is React.ReactElement => {
    return React.isValidElement(node) && node.type === type;
};
