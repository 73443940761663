import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { CustomizationComponent } from '@lumapps/customizations/api';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { Size, Icon, IconProps, Tooltip, Text } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router/components/Link';
import { useTranslate } from '@lumapps/translations';

import { UserMenuLink, USER_MENU_LINK_TYPE } from '../../types';

export interface UserMenuItemProps extends UserMenuLink {
    scope: string;
    id: string;
    linkProps: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
    isLoading?: boolean;
    icon?: string;
    iconProps?: Omit<IconProps, 'icon'>;
}

const CLASSNAME = 'user-menu-item';

export const UserMenuItem = ({
    label,
    linkProps,
    type,
    customizationProps,
    onClick,
    scope,
    id,
    route,
    icon,
    iconProps,
    isDisabled,
    tooltipLabel,
}: UserMenuItemProps) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);
    const { element } = useClassnames(CLASSNAME);

    const routingProps = route ? { as: RouterLink, to: route } : { as: 'a', ...(linkProps as any) };

    const labelWithTooltip = useMemo(
        () => (
            <Tooltip label={!isDisabled ? tooltipLabel : undefined}>
                <Text as="span">{translateKey(label || '')}</Text>
            </Tooltip>
        ),
        [isDisabled, label, tooltipLabel, translateKey],
    );

    switch (type) {
        case USER_MENU_LINK_TYPE.DIVIDER:
            return <Menu.Divider role="separator" />;
        case USER_MENU_LINK_TYPE.CUSTOMIZATION:
            return customizationProps ? <CustomizationComponent {...customizationProps} /> : null;
        case USER_MENU_LINK_TYPE.BUTTON:
            return (
                <Menu.Item
                    className={element('btn')}
                    before={icon && <Icon icon={icon} size={Size.xs} alt="" {...iconProps} />}
                    onClick={onClick}
                    isDisabled={isDisabled}
                    /**
                     * When the listItem is disabled, the Tooltip isn't triggered.
                     * We can't set the Tooltip element around the ListItem as it would break the
                     * semantics of the list.
                     * In that case, we use the default title attribute.
                     */
                    title={isDisabled ? tooltipLabel : undefined}
                    {...get({ element: id })}
                >
                    {labelWithTooltip}
                </Menu.Item>
            );
        default:
            return (
                <Menu.Item
                    {...routingProps}
                    before={icon && <Icon icon={icon} size={Size.xs} alt="" {...iconProps} />}
                    onClick={onClick}
                    isDisabled={isDisabled}
                    /** See explanation on the 'BUTTON' type element on why this attribute is set */
                    title={isDisabled ? tooltipLabel : undefined}
                    {...get({ element: id })}
                >
                    {labelWithTooltip}
                </Menu.Item>
            );
    }
};
