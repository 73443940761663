import type { Point, ReactEditor } from '@lumapps/wrex/slate';
import { insertVoidBlock } from '@lumapps/wrex/slate/utils/insertVoidBlock';

import type { LinkPreviewAttributes, LinkPreviewEditor } from '../types';
import { createLinkPreview } from './createLinkPreview';

/**
 * Insert file after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertLinkPreview(
    editor: ReactEditor & LinkPreviewEditor,
    link: LinkPreviewAttributes,
    point?: Point | undefined,
) {
    const node = createLinkPreview({
        title: link.title,
        description: link.description,
        thumbnailUrl: link.thumbnailUrl,
        url: link.url || '',
        resource: link.resource,
    });

    return insertVoidBlock(editor, node, point);
}
