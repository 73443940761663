import { Editor, Node, Path, Transforms, Element } from '..';
import { getSiblingPath } from './getSibling';

/**
 * Replace the parent node at a given path with his first child or the child at the given index
 * @returns {boolean} true if the replacement worked
 */
export const replaceParentNodeByChild = (editor: Editor, parentNodePath: Path, childIndex?: number): boolean => {
    const childsNodeEntry = Array.from(Node.children(editor, parentNodePath));
    const childNodeEntry = childIndex ? childsNodeEntry[childIndex] : childsNodeEntry[0];
    const childNode = childNodeEntry[0] as Element;
    const childNodePath = childNodeEntry[1];

    if (parentNodePath && childNodePath && !!childNode.children) {
        Transforms.moveNodes(editor, { to: getSiblingPath(parentNodePath, 'after') as Path, at: childNodePath });
        Transforms.removeNodes(editor, { at: parentNodePath });

        return true;
    }

    return false;
};
