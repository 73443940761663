import { useEffect, useReducer, useCallback } from 'react';

import isUndefined from 'lodash/isUndefined';
import set from 'lodash/set';

import { getInstance } from '@lumapps/instance/api';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { getStyle } from '../api';
import { STYLESHEET_KIND } from '../types';

enum STATUS {
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error',
    INITIAL = 'initial',
}

export interface UseAdvancedStylingOptions {
    /** style id to retrieve */
    styleId: string;
    /** instance id associated to that style id */
    instanceId: string;
    /** whether the data API call should be triggered or not. Useful if you want to use this hook conditionally */
    shouldFetchData?: boolean;
}

interface UseAdvancedStylingState {
    /** whether the stylesheets are loaded */
    status: STATUS;
    /** root styles for the given instance and style id */
    cssRoot: string;
    /** custom styles for the given instance and style id */
    cssCustom: string;
    /** instance head for the given instance and style id */
    instanceHead: string;
    /** whether an error occurred while loading this hook */
    errorOccurred: boolean;
    /** loaded style id */
    styleId: string;
}

const initialState: UseAdvancedStylingState = {
    status: STATUS.INITIAL,
    cssRoot: '',
    cssCustom: '',
    instanceHead: '',
    errorOccurred: false,
    styleId: '',
};

/**
 * Internal reducer used to manage advance styling data. As of today, there is no reason to move this to ./ducks/slices
 * since this is a reducer that we are going to use internally, and the entrypoint for this should only be this hook.
 * That is why this reducer is here, it sits beside the hook and it is not exported.
 */
const { actions, reducer } = createSlice({
    domain: 'style/advancedStyling',
    initialState,
    reducers: {
        setStyles: (
            state: UseAdvancedStylingState,
            action: PayloadAction<{ styleId?: string; root?: string; custom?: string; status?: STATUS; head?: string }>,
        ) => {
            if (!isUndefined(action.payload.root)) {
                set(state, 'cssRoot', action.payload.root);
            }

            if (!isUndefined(action.payload.custom)) {
                set(state, 'cssCustom', action.payload.custom);
            }

            if (!isUndefined(action.payload.head)) {
                set(state, 'instanceHead', action.payload.head);
            }

            if (!isUndefined(action.payload.status)) {
                set(state, 'status', action.payload.status);
            }

            if (!isUndefined(action.payload.styleId)) {
                set(state, 'styleId', action.payload.styleId);
            }
        },
        setErrorOccurred: (state: UseAdvancedStylingState, action: PayloadAction<boolean>) => {
            set(state, 'errorOccurred', action.payload);

            /** If something went wrong, we reset everything to default */
            if (action.payload) {
                set(state, 'status', initialState.status);
                set(state, 'cssRoot', initialState.cssRoot);
                set(state, 'cssCustom', initialState.cssCustom);
                set(state, 'instanceHead', initialState.instanceHead);
            }
        },
    },
});

export interface UseAdvancedStyling extends Partial<UseAdvancedStylingState> {
    setStyle: (kind: STYLESHEET_KIND, value: string) => void;
    setInstanceHead: (value: string) => void;
    isLoaded: boolean;
}

/**
 * This hook sets up the library and the data needed for displaying the advanced styling section
 * @param options - UseAdvancedStylingOptions
 * @returns UseAdvancedStylingState
 */
export const useAdvancedStyling = ({
    styleId = '',
    instanceId,
    shouldFetchData = false,
}: UseAdvancedStylingOptions): UseAdvancedStyling => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const shouldFetch =
        shouldFetchData &&
        ((state.status !== STATUS.LOADED && state.status !== STATUS.LOADING) || styleId !== state.styleId);

    useEffect(() => {
        /**
         * We should only retrieve the styles if shouldFetchData is true and if the styles were not already
         * loaded or are still loading.
         */
        if (shouldFetch) {
            dispatch(actions.setStyles({ status: STATUS.LOADING }));

            const promises: Promise<any>[] = [getInstance({ uid: instanceId, fields: 'head' }, true)];

            if (styleId) {
                promises.push(getStyle({ uid: styleId }));
            }

            Promise.all(promises)
                .then(([instanceResponse, stylesheetResponse]) => {
                    /**
                     * When retreiving a style, we will receive an array of stylesheets associated to it.
                     * We need to go through each of those stylesheets and determine their type. If they match
                     * either root or custom, we update the state with those values.
                     */
                    const styles: Record<STYLESHEET_KIND, string> = {
                        [STYLESHEET_KIND.ROOT]: '',
                        [STYLESHEET_KIND.CUSTOM]: '',
                    };

                    if (stylesheetResponse) {
                        const { stylesheets } = stylesheetResponse;

                        stylesheets.forEach((sheet: any) => {
                            if (sheet.kind === STYLESHEET_KIND.ROOT) {
                                styles[STYLESHEET_KIND.ROOT] = sheet.content;
                            } else if (sheet.kind === STYLESHEET_KIND.CUSTOM) {
                                styles[STYLESHEET_KIND.CUSTOM] = sheet.content;
                            }
                        });
                    }

                    const { data } = instanceResponse;
                    dispatch(actions.setStyles({ ...styles, head: data.head || '', status: STATUS.LOADED, styleId }));
                })
                .catch(() => {
                    dispatch(actions.setErrorOccurred(true));
                });
        }
    }, [styleId, instanceId, shouldFetch]);

    return {
        cssCustom: state.cssCustom,
        cssRoot: state.cssRoot,
        instanceHead: state.instanceHead,
        isLoaded: state.status === STATUS.LOADED,
        setInstanceHead: useCallback((value: string) => {
            dispatch(
                actions.setStyles({
                    head: value,
                }),
            );
        }, []),
        setStyle: useCallback((kind: STYLESHEET_KIND, value: string) => {
            dispatch(
                actions.setStyles({
                    [kind]: value,
                }),
            );
        }, []),
    };
};
