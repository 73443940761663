import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';

import get from 'lodash/get';

import { Button, Emphasis, ColorPalette, TextField, Theme, Switch } from '@lumapps/lumx/react';
import { mdiRestore } from '@lumapps/lumx/icons';
import { LegacyFroalaEditor } from '@lumapps/wrex-legacy-froala/components/LegacyFroalaEditor';
import { GLOBAL } from '@lumapps/translations';

import { ConfirmDialog, Loader } from 'components/components/ui';
import { translate as t } from 'components/translations';
import { isUrl } from '@lumapps/utils/string/isUrl';

import {
    FROALA_CONFIG,
    TERMS_AND_CONDITIONS_FIELDS,
    TERMS_AND_CONDITIONS_STATUS,
} from '../termsAndConditions_constants';

const TermsAndConditionsAdmin = ({
    fetchTermsAndConditions,
    inputLanguage,
    isLoading,
    resetUsersApproval,
    termsAndConditions,
    updateTermsAndConditions,
}) => {
    /** Fetch the terms and conditions. */
    useEffect(() => {
        fetchTermsAndConditions();
    }, [fetchTermsAndConditions]);

    /** Hook used to toogle the terms and conditions. */
    const isEnabled = termsAndConditions.status === TERMS_AND_CONDITIONS_STATUS.ENABLED;

    /** Hook used to toogle the reset dialog. */
    const [isDisplayResetDialog, setIsDisplayResetDialog] = useState(false);

    /** Hook used by validation of the redirect URL. */
    const [isURLValid, updateURLValidation] = useState(true);

    /**
     * Update the termsAndConditions status.
     *
     * @param {boolean} newValue The terms and conditions new status.
     */
    const handleSwitchChange = (newValue) => {
        updateTermsAndConditions({
            fieldName: 'status',
            value: newValue ? TERMS_AND_CONDITIONS_STATUS.ENABLED : TERMS_AND_CONDITIONS_STATUS.DISABLED,
        });
    };

    /**
     * Update the description.
     *
     * @param {string} value The description value in the current language.
     */
    const handleUpdateDescription = (value) => {
        updateTermsAndConditions({ fieldName: TERMS_AND_CONDITIONS_FIELDS.DESCRIPTION, value });
    };

    /**
     * Update the redirect URL and check if it's valid.
     *
     * @param {string} value The description value in the current language.
     */
    const handleUpdateUrl = (value) => {
        updateURLValidation(value === '' || isUrl(value));
        updateTermsAndConditions({ fieldName: TERMS_AND_CONDITIONS_FIELDS.REDIRECT_URL, value });
    };

    /**
     * Confirm the reset of all the approval. Call the reset backend fonction and close the confirm dialog.
     */
    const confirmReset = () => {
        resetUsersApproval();
        setIsDisplayResetDialog(false);
    };

    return (
        <div
            key={inputLanguage}
        >
            <p className="admin-subtitle">{t('GLOBAL.TERMS_AND_CONDITIONS')}</p>

            {isLoading && <Loader isLoading={isLoading} type="circular" />}

            {!isLoading && (
                <>
                    <Switch
                        checked={isEnabled}
                        helper={t('ADMIN.TERMS_AND_CONDITIONS.SWITCH.HELPER')}
                        theme={Theme.light}
                        onChange={() => handleSwitchChange(!isEnabled)}
                    >
                        {t('ADMIN.TERMS_AND_CONDITIONS.SWITCH.LABEL')}
                    </Switch>

                    {isEnabled && (
                        <div className="admin-section__switch-section">
                            <div className="pv">
                                <LegacyFroalaEditor
                                    config={FROALA_CONFIG}
                                    model={get(
                                        termsAndConditions,
                                        [TERMS_AND_CONDITIONS_FIELDS.DESCRIPTION, inputLanguage],
                                        '',
                                    )}
                                    onModelChange={(data) => handleUpdateDescription(data)}
                                />
                            </div>

                            <div className="pv">
                                <TextField
                                    hasError={!isURLValid}
                                    helper={t('ADMIN.TERMS_AND_CONDITIONS.REDIRECT_URL.HELPER')}
                                    label={t('ADMIN.TERMS_AND_CONDITIONS.REDIRECT_URL.LABEL')}
                                    placeholder={t(GLOBAL.ENTER_A_URL)}
                                    theme={Theme.light}
                                    value={get(
                                        termsAndConditions,
                                        [TERMS_AND_CONDITIONS_FIELDS.REDIRECT_URL, inputLanguage],
                                        '',
                                    )}
                                    onChange={(data) => handleUpdateUrl(data)}
                                />
                            </div>

                            <div className="pv">
                                <Button
                                    color={ColorPalette.red}
                                    emphasis={Emphasis.medium}
                                    leftIcon={mdiRestore}
                                    onClick={() => setIsDisplayResetDialog(true)}
                                >
                                    {t('ADMIN.TERMS_AND_CONDITIONS.RESET.LABEL')}
                                </Button>
                                <p className="lumx-typography-body1 lumx-color-font-dark-L2">
                                    {t('ADMIN.TERMS_AND_CONDITIONS.RESET.HELPER')}
                                </p>
                            </div>
                        </div>
                    )}

                    <ConfirmDialog
                        description="ADMIN.TERMS_AND_CONDITIONS.RESET.DIALOG.DESCRIPTION"
                        isOpen={isDisplayResetDialog}
                        title="ADMIN.TERMS_AND_CONDITIONS.RESET.DIALOG.TITLE"
                        onClose={() => setIsDisplayResetDialog(false)}
                        onValidate={() => confirmReset()}
                    />
                </>
            )}
        </div>
    );
};

/////////////////////////////

TermsAndConditionsAdmin.propTypes = {
    /** The redux action to call to fetch the terms and conditions. */
    fetchTermsAndConditions: func.isRequired,
    /** The input language, notice that it's a customer language, not an instance one. */
    inputLanguage: string.isRequired,
    /** Wether the terms and conditions is loading. */
    isLoading: bool.isRequired,
    /** The function called to reset all users terms and conditions approuvment. */
    resetUsersApproval: func.isRequired,
    /** The termsAndConditions object. */
    termsAndConditions: shape().isRequired,
    /** The termsAndConditions update function. */
    updateTermsAndConditions: func.isRequired,
};

/////////////////////////////

export { TermsAndConditionsAdmin };
