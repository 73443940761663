import first from 'lodash/first';
import last from 'lodash/last';

import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { Editor, Range, Transforms, Element, isEditor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { Wrex, WrexEditor } from '@lumapps/wrex/types';

import { QuoteBlock } from '../components/blocks/QuoteBlock';
import { QuoteEditor, QuoteEditorOptions } from '../types';
import { createQuote } from '../utils/createQuote';
import { isQuoteBlock } from '../utils/isQuoteBlock';
import { getKeyHandler } from './event/keyboard';
import { normalizeQuote } from './normalize';

const createPluginEditor =
    (options: QuoteEditorOptions): CreatePluginEditor<QuoteEditor, WrexEditor<QuoteEditor>> =>
    (editor) => {
        const { normalizeNode } = editor;
        const { parentMatch = isEditor } = options;

        return {
            normalizeNode: (nodeEntry) => {
                if (normalizeQuote(nodeEntry, editor, options)) {
                    return;
                }
                // Find the node again in the editor, because the original one
                // have been removed - reinserted
                normalizeNode([Editor.node(editor, nodeEntry[1])[0], nodeEntry[1]]);
            },

            toggleQuote: (at = editor.selection) => {
                if (!at) {
                    return;
                }

                if (editor.isQuoteActive(at)) {
                    Transforms.unwrapNodes(editor, { at, mode: 'highest', match: isQuoteBlock });
                } else {
                    // Wrap the higher paragraph we found at selection
                    Transforms.wrapNodes(editor, createQuote([]), { at, match: isParagraph, mode: 'highest' });

                    focusAt(editor, editor.selection);
                }
            },

            isQuoteActive: (at = editor.selection) => {
                if (!at) {
                    return false;
                }

                const entries = Array.from(
                    Editor.nodes(editor, {
                        match: (node) =>
                            Element.isElement(node) &&
                            Editor.isBlock(editor, node) &&
                            !parentMatch(node as Wrex.Element),
                        at,
                        mode: 'highest',
                    }),
                );

                const nonQuoteBlockNodes = entries.filter(([node]) => !isQuoteBlock(node));
                const hasQuoteBlocks = entries.length !== nonQuoteBlockNodes.length;

                // Don't consider the first non quote block in selection if selection starts at the end of it.
                const firstEntry = first(entries);
                // We check if the firstEntry matches is also a non quote block.
                if (hasQuoteBlocks && firstEntry && first(nonQuoteBlockNodes) === firstEntry) {
                    // starting point of the selection
                    const atStart = Range.isRange(at) && Range.start(at);
                    // If the start of the selection matches the very end of the first non quote block then we can ignore it.
                    if (atStart && Editor.isEnd(editor, atStart, firstEntry[1])) {
                        entries.shift();
                    }
                }

                // Don't consider the last non quote block in selection if selection ends at the start of it.
                const lastEntry = last(entries);
                if (hasQuoteBlocks && entries.length && lastEntry && last(nonQuoteBlockNodes) === lastEntry) {
                    const atEnd = Range.isRange(at) && Range.end(at);
                    // If the end of the selection matches the very start of the last non quote block then we can ignore it.
                    if (atEnd && Editor.isStart(editor, atEnd, lastEntry[1])) {
                        entries.pop();
                    }
                }

                return entries.every(([node]) => isQuoteBlock(node));
            },
            isQuoteAllowed: () => {
                // Editor cursor should be available.
                if (!editor.selection) {
                    return true;
                }

                const elements = Array.from(
                    Editor.nodes(editor, {
                        at: editor.selection,
                        match: (node) => {
                            return Element.isElement(node) && Editor.isBlock(editor, node);
                        },
                    }),
                );

                return elements.every(
                    ([node]) => isParagraph(node) || isQuoteBlock(node) || parentMatch(node as Wrex.Node),
                );
            },
        };
    };

/**
 * Plugin rendering common elements:
 * - QuoteBlock
 */
export const withQuote = (options: QuoteEditorOptions = {}) =>
    createPlugin<QuoteEditor, WrexEditor<QuoteEditor>>({
        createPluginEditor: createPluginEditor(options),
        getKeyHandler,
        elements: [QuoteBlock],
    });
