import React, { useCallback, useEffect } from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { ChipFilter } from '@lumapps/lumx-filter-and-sort/components/ChipFilter';
import { FiltersDropdown } from '@lumapps/lumx-filter-and-sort/components/FiltersDropdown';
import { useListFilter } from '@lumapps/lumx-filter-and-sort/hooks/useListFilter';
import { Alignment, ColorPalette, ColorVariant, FlexBox, Orientation, Text, Typography } from '@lumapps/lumx/react';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { FilterComponentProps } from '@lumapps/widget-base/types';

import { ManagedStreamResources } from '../../types';
import { filterAndSortManagedStreamResources } from '../../utils/filterAndSortManagedStreamResources';
import { getResourceTranslation } from '../../utils/getResourceTranslation';

interface StreamFiltersType {
    /** The resource type to allow filtering by */
    resourceTypes?: Array<ManagedStreamResources>;
}

export type StreamFiltersProps = FilterComponentProps<StreamFiltersType>;

const SCOPE = 'stream-filters';

/** Filters for the Stream Widget */
export const StreamFilters: React.FC<StreamFiltersProps> = ({
    availableFilters,
    onApply,
    onFilterChange,
    activeFilters,
}) => {
    const { isSpace } = useSpace();
    const { get } = useDataAttributes(isSpace ? 'space-feed-filters' : SCOPE);
    const { translateKey, translateAndReplace } = useTranslate();

    const resourceTypeFilters = filterAndSortManagedStreamResources(
        availableFilters?.find(({ type }) => type === 'RESOURCE_TYPES')?.predefinedValues,
    );

    const renderResourceTranslation = useCallback(
        (resource: ManagedStreamResources) => translateKey(getResourceTranslation(resource)),
        [translateKey],
    );

    const { onFilter, onSelected, isInSelection, onClearSelection, onSelectedMultiple, selection } = useListFilter({
        getId: (resource: ManagedStreamResources) => resource,
    });

    const handleSelected = (selected: StreamFiltersType) => {
        // Change the widget's saved filters
        onFilterChange({
            resourceTypes: selected.resourceTypes || [],
        });
        // Change the filter's inner state
        onFilter();
        // Apply the filter to the widget to trigger a fetch
        onApply();
    };

    const handleClearSelected = () => {
        handleSelected({ resourceTypes: [] });
    };

    /**
     * As the active filters can be modified outside of this component,
     * such as the widget's empty state, we have to synchronize the filter's state
     * when they do.
     */
    useEffect(() => {
        onSelectedMultiple(activeFilters?.resourceTypes || []);
    }, [activeFilters, onSelectedMultiple]);

    const hasFilters = resourceTypeFilters.length > 1;

    return (
        <FlexBox
            orientation={Orientation.horizontal}
            vAlign={hasFilters ? Alignment.spaceBetween : Alignment.right}
            hAlign={Alignment.center}
        >
            {hasFilters ? (
                <FiltersDropdown
                    onFilter={() => handleSelected({ resourceTypes: selection as Array<ManagedStreamResources> })}
                    selectedFilter={activeFilters?.resourceTypes?.map(renderResourceTranslation)}
                    onClose={onClearSelection}
                    onClearClick={handleClearSelected}
                    filterProps={get({ element: 'button', action: 'filter' })}
                    triggerProps={get({ element: 'dropdown' })}
                    label={translateKey(GLOBAL.PUBLICATION_TYPES)}
                    title={translateKey(GLOBAL.PUBLICATION_TYPES)}
                >
                    <ChipFilter
                        title={translateKey(GLOBAL.PUBLICATION_TYPES)}
                        hideTitle
                        onSelected={onSelected}
                        isInSelection={isInSelection}
                        options={resourceTypeFilters}
                        renderChip={renderResourceTranslation}
                        id="publicationTypes"
                        chipProps={(resource) => get({ element: 'filter', action: resource })}
                    />
                </FiltersDropdown>
            ) : null}

            {/* Sort order */}
            <Text as="p" typography={Typography.body1} color={ColorPalette.dark} colorVariant={ColorVariant.L2}>
                {translateAndReplace(GLOBAL.SORTED_BY, {
                    SORT: translateKey(GLOBAL.MOST_RECENT),
                })}
            </Text>
        </FlexBox>
    );
};
