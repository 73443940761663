// istanbul ignore file

import { Messages } from './types';

/**
 * Send a message to the host native lumapps mobile app (iOS and Android).
 *
 * @param message Message to send.
 */
export const sendWebviewMobileMessage = (message: Messages) => {
    try {
        if ((window as any).webkit) {
            // Send to iOS mobile app.
            (window as any).webkit.messageHandlers.lumappsMessageHandler.postMessage(message);
        } else {
            // Send to Android mobile app.
            (window as any).lumappsMessageHandler.postMessage(JSON.stringify(message));
        }
    } catch (ignored) {
        // Fallback to `console.log` (usefully when testing on desktop).
        // eslint-disable-next-line no-console
        console.debug('Webview message', JSON.stringify(message, null, 2));
    }
};
