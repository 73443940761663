import BaseApi, { BaseApiPromise } from '@lumapps/base-api';
import { learningApi } from '@lumapps/learning/constants';

import { LearnerStatsType } from '../types';

/**
 * learner API.
 */
const learnerStatsApi = () =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL: learningApi.URL,
    });

/**
 * learner kpis parametrized APIs
 * - `learner/stats allows to retrieve current user's stats
 */
const paths = {
    identity: 'identity/v2/users/profile/summary',
};

export const getLearnerStats = (): BaseApiPromise<LearnerStatsType> => {
    return learnerStatsApi().get<LearnerStatsType>(paths.identity);
};

export { learnerStatsApi, paths };
