import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import set from 'lodash/set';

/////////////////////////////

function WidgetSettingsStyleController(
    $rootScope,
    $scope,
    $timeout,
    Cell,
    ConfigTheme,
    Content,
    Features,
    Style,
    Translation,
    Utils,
    Widget,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const widgetSettingsStyle = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The empty widget style properties object.
     *
     * @type {Object}
     */
    const _emptyStyleProperties = {
        content: {},
        footer: {},
        global: {},
        header: {},
    };

    /**
     * The original widget style properties to keep for comparison.
     *
     * @type {Object}
     */
    let _orginalStyleProperties;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the possible values for the Flexbox `align-items` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetSettingsStyle.AVAILABE_ALIGN_ITEMS = ['stretch', 'center', 'flex-start', 'flex-end', 'baseline'];

    /**
     * Contains the possible values for the `background-position` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetSettingsStyle.AVAILABLE_BACKGROUND_POSITIONS = [
        'left top',
        'left center',
        'left bottom',
        'right top',
        'right center',
        'right bottom',
        'center top',
        'center center',
        'center bottom',
    ];

    /**
     * Contains the possible values for the `background-size` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetSettingsStyle.AVAILABLE_BACKGROUND_SIZES = ['auto', 'cover', 'contain'];

    /**
     * Contains the possible values for the Flexbox `direction` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetSettingsStyle.AVAILABLE_FLEX_DIRECTIONS = ['row', 'column'];

    /**
     * Contains the possible values for the `font-weight` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetSettingsStyle.AVAILABLE_FONT_WEIGHTS = ['light', 'normal', 'semibold', 'bold'];

    /**
     * Contains the possible values for the Flexbox `justify-content` property.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetSettingsStyle.AVAILABLE_JUSTIFY_CONTENT = [
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
    ];

    /**
     * Contains the list of available (not deleted) global styles.
     *
     * @type {Object[]}
     */
    widgetSettingsStyle.availableStylesList = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    widgetSettingsStyle.Cell = Cell;
    widgetSettingsStyle.ConfigTheme = ConfigTheme;
    widgetSettingsStyle.Content = Content;
    widgetSettingsStyle.Features = Features;
    widgetSettingsStyle.Style = Style;
    widgetSettingsStyle.Translation = Translation;
    widgetSettingsStyle.Widget = Widget;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Filter the list of available (not deleted) global styles.
     *
     * @return {Object[]} Array of objects.
     */
    function _filterAvailableListStyle() {
        return (widgetSettingsStyle.availableStylesList = filter(Style.displayList('widget'), { status: 'LIVE' }));
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if current widget style is different than the original widget style.
     *
     * @return {boolean} If style has changed or not.
     */
    function hasStyleChanged() {
        return (
            angular.isDefinedAndFilled(get(Widget.getCurrent(), 'style')) &&
            !Utils.equals(Widget.getCurrent().properties.style, _orginalStyleProperties, ['null'])
        );
    }

    /**
     * Model to selection.
     *
     * @param {string}   currentData The current model.
     * @param {Function} cb          The callback to call.
     */
    function modelToSelection(currentData, cb) {
        if (angular.isDefinedAndFilled(currentData)) {
            Style.get(
                currentData,
                (style) => {
                    cb(style);
                },
                undefined,
                'widget',
            );
        } else {
            cb(undefined);
        }
    }

    /**
     * Restore style changes.
     */
    function restoreStyle() {
        Widget.getCurrent().properties.style = angular.fastCopy(_orginalStyleProperties);
    }

    /**
     * Selection to model.
     *
     * @param {Object}   currentData The currently selected data.
     * @param {Function} cb          The callback.
     */
    function selectionToModel(currentData, cb) {
        if (angular.isDefined(currentData.id)) {
            cb(currentData.id);
        } else {
            cb(undefined);
        }
    }

    /**
     * Set widget style.
     */
    function setWidgetStyle() {
        let mainMargins = {};
        const currentWidget = Widget.getCurrent();

        const mainStyle = get(currentWidget.properties.style, 'main');

        // Backward compatibility : Keep main margins to reapply them after applying global style.
        if (angular.isDefinedAndFilled(currentWidget.properties.style.main)) {
            mainMargins = {
                margin: mainStyle.margin,
                marginBottom: mainStyle.marginBottom,
                marginLeft: mainStyle.marginLeft,
                marginRight: mainStyle.marginRight,
                marginTop: mainStyle.marginTop,
            };
        }

        if (angular.isDefinedAndFilled(Widget.getCurrent().style)) {
            Style.get(
                Widget.getCurrent().style,
                (style) => {
                    const cleanStyle = Style.cleanWidgetGlobalStyle(style);
                    currentWidget.properties.style = angular.fastCopy(cleanStyle.properties);
                    _orginalStyleProperties = angular.fastCopy(cleanStyle.properties) || _orginalStyleProperties;

                    if (angular.isDefinedAndFilled(mainMargins)) {
                        _orginalStyleProperties.main = _orginalStyleProperties.main || {};
                        angular.extend(_orginalStyleProperties.main, mainMargins);
                        currentWidget.properties.style = _orginalStyleProperties;
                    }
                },
                undefined,
                'widget',
            );
        } else {
            Style.setCurrent(undefined, 'widget');

            _orginalStyleProperties = _orginalStyleProperties || angular.fastCopy(_emptyStyleProperties);

            if (angular.isDefinedAndFilled(mainMargins)) {
                _orginalStyleProperties.main = _orginalStyleProperties.main || {};
                angular.extend(_orginalStyleProperties.main, mainMargins);
            }

            currentWidget.properties.style = angular.fastCopy(_orginalStyleProperties);
        }

        widgetSettingsStyle.value = currentWidget.properties.style;

        $rootScope.$broadcast('widget-style', currentWidget.uuid, currentWidget.properties.style);
    }

    /**
     * Update the widget.
     */
    function updateWidgetStyle() {
        $timeout(() => {
            $rootScope.$broadcast('widget-style-settings', get(Widget.getCurrent(), 'uuid'));
        });
    }

    /**
     * Changes the widget style.
     *
     * @param {Object} value The new style.
     */
    function onChange(value) {
        const previousValue = widgetSettingsStyle.value;
        const { properties } = Widget.getCurrent();

        properties.isBackwardedStyle = false;

        if (previousValue.content.height !== value.content.height) {
            properties.height = value.content.height;
        }
        if (previousValue.content.fullHeight !== value.content.fullHeight) {
            properties.fullHeight = value.content.fullHeight;
        }

        const headerAndFooterAreaKey = ['header', 'footer'];

        angular.forEach(headerAndFooterAreaKey, (area) => {
            const displayMode = get(value, [area, 'main', 'display'], 'block');
            const previousDisplayMode = get(previousValue, [area, 'main', 'display'], 'block');

            if (displayMode !== previousDisplayMode) {
                const propertyToSwapTo = displayMode === 'flex' ? 'wrapper' : 'main';
                const propertyToSwapFrom = displayMode === 'flex' ? 'main' : 'wrapper';

                if (
                    angular.isDefinedAndFilled(value[area][propertyToSwapFrom]) &&
                    angular.isDefinedAndFilled(value[area][propertyToSwapTo])
                ) {
                    value[area][propertyToSwapTo] = angular.extend(
                        value[area][propertyToSwapTo],
                        pick(value[area][propertyToSwapFrom], Widget._WIDGET_HEADER_FOOTER_SWITCHABLE_PROPERTIES),
                    );

                    value[area][propertyToSwapFrom] = omit(
                        value[area][propertyToSwapFrom],
                        Widget._WIDGET_HEADER_FOOTER_SWITCHABLE_PROPERTIES,
                    );
                }
            }
        });

        properties.style = value;
        widgetSettingsStyle.value = value;
    }

    /**
     * Stores state changes.
     *
     * @param {string} state The new state to set to the widget.
     */
    function onChangeState(state) {
        const widget = Widget.getCurrent();
        widget.state = state;
    }

    /////////////////////////////

    widgetSettingsStyle.hasStyleChanged = hasStyleChanged;
    widgetSettingsStyle.modelToSelection = modelToSelection;
    widgetSettingsStyle.restoreStyle = restoreStyle;
    widgetSettingsStyle.selectionToModel = selectionToModel;
    widgetSettingsStyle.setWidgetStyle = setWidgetStyle;
    widgetSettingsStyle.updateWidgetStyle = updateWidgetStyle;
    widgetSettingsStyle.onChange = onChange;
    widgetSettingsStyle.onChangeState = onChangeState;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the widget style is updated, backup the original styles and
     * update the list of available styles.
     *
     * @param {Event}   evt       The event.
     * @param {string}  widgetId  The UUID of the updated widget.
     * @param {Object}  newStyle  The updated styles of the widget.
     * @param {boolean} isDeleted Whether the event is called when style is marked as deleted.
     */
    $scope.$on('widget-style', (evt, widgetId, newStyle, isDeleted) => {
        if (isDeleted) {
            widgetSettingsStyle.Widget.getCurrent().style = undefined;
        }

        if (widgetId === Widget.getCurrent().uuid && newStyle.properties) {
            _orginalStyleProperties = angular.fastCopy(newStyle.properties);
        }

        widgetSettingsStyle.availableStylesList = _filterAvailableListStyle();
    });

    /**
     * When the widget settings are closed, reset the unsaved styles of the widget.
     */
    $scope.$on('close-component-settings', () => {
        if (widgetSettingsStyle.hasStyleChanged()) {
            const widget = Widget.getCurrent();
            widget.properties.style = angular.fastCopy(_orginalStyleProperties);
            widget.state = undefined;
        }
    });

    /////////////////////////////

    /**
     * Initialize controller.
     */
    function init() {
        const currentWidget = Widget.getCurrent();

        _orginalStyleProperties = angular.fastCopy(currentWidget.properties.style);
        const properties = currentWidget.properties || {};

        if (angular.isDefinedAndFilled(properties.height)) {
            set(properties, 'style.content.height', properties.height);
        }

        if (angular.isDefinedAndFilled(properties.fullHeight)) {
            set(properties, 'style.content.fullHeight', properties.fullHeight);
        }

        widgetSettingsStyle.value = currentWidget.properties.style;

        // Remove deleted stlyles from the list that will be displayed.
        widgetSettingsStyle.availableStylesList = _filterAvailableListStyle();

        // Delete style reference id in current widget if this style is marked as deleted.
        Style.get(
            currentWidget.style,
            (style) => {
                if (style.status === 'DELETED') {
                    currentWidget.style = undefined;
                }
            },
            undefined,
            'widget',
        );
    }

    init();

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        widgetSettingsStyle.parentCtrl = parentCtrl;
    };
}

/////////////////////////////

function WidgetSettingsStyleDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetSettingsStyleController,
        controllerAs: 'widgetSettingsStyle',
        link,
        require: ['lsWidgetSettingsStyle', '^widgetSettings'],
        restrict: 'E',
        scope: {
            hideMoreLink: '<?',
            hideTitle: '<?',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-settings-style.html',
        transclude: true,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsWidgetSettingsStyle', WidgetSettingsStyleDirective);

/////////////////////////////

export { WidgetSettingsStyleDirective };
