import React from 'react';

import set from 'lodash/set';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { Image } from '@lumapps/lumx-images/types';
import { Media } from '@lumapps/medias/types';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { uploadDocument, UploadDocumentOptions } from '../api';

export interface UploadDocumentState {
    document?: Image;
    status: BaseLoadingStatus;
}

export const initialState: UploadDocumentState = {
    status: BaseLoadingStatus.initial,
};

export const reducers = {
    setStatus: (state: UploadDocumentState, action: PayloadAction<BaseLoadingStatus>) => {
        set(state, 'status', action.payload);
    },
    setDocument: (state: UploadDocumentState, action: PayloadAction<Image>) => {
        set(state, 'document', action.payload);
    },
    reset: (state: UploadDocumentState) => {
        set(state, 'document', initialState.document);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'upload-document',
    initialState,
    reducers,
});

export interface UseUploadDocumentProps {
    uploadDocumentOptions?: Omit<UploadDocumentOptions, 'file'>;
    onDocumentUploaded?: (document?: Image) => void;
    selectedDocument?: Image;
}

export interface UseUploadDocument {
    /** whether the uploaded document is still loading or not */
    isDocumentLoading: boolean;
    /** on document delete */
    onDelete: () => void;
    /** current document upload state */
    state: UploadDocumentState;
    /** callback on selecting a document */
    onSelectDocument: (document: Image) => void;
}

/**
 * Hook that centralises the uploading document functionality
 * @param UseUploadDocumentProps
 * @returns UseUploadDocument
 */
export const useUploadDocument = ({
    uploadDocumentOptions,
    onDocumentUploaded,
    selectedDocument,
}: UseUploadDocumentProps): UseUploadDocument => {
    const [state, dispatch] = React.useReducer(reducer, { ...initialState, document: selectedDocument });
    const currentLanguage = useSelector(currentLanguageSelector);
    const instanceId = useSelector(instanceIdSelector);
    const { error } = useNotification();
    const { status } = state;

    React.useEffect(() => {
        dispatch(actions.setDocument(selectedDocument));
    }, [selectedDocument]);

    const onSelectDocument = async (document: Image) => {
        dispatch(actions.setStatus(BaseLoadingStatus.loading));
        dispatch(actions.setDocument(document));

        try {
            const response = await uploadDocument({
                file: document.file as File,
                ...(uploadDocumentOptions || {
                    lang: currentLanguage,
                    shared: false,
                    parentPath: `provider=local/site=${instanceId}`,
                }),
            });

            const confirmedUploadedDocument = response.data.items[0] as Media;

            const documentWithId = {
                ...document,
                id: confirmedUploadedDocument.content ? confirmedUploadedDocument.content[0].value : '',
                rawMedia: confirmedUploadedDocument,
            };

            dispatch(actions.setDocument(documentWithId));
            dispatch(actions.setStatus(BaseLoadingStatus.idle));

            if (onDocumentUploaded) {
                onDocumentUploaded(documentWithId);
            }
        } catch (excp) {
            dispatch(actions.reset());
            dispatch(actions.setStatus(BaseLoadingStatus.error));

            error({ translate: GLOBAL.ERROR_OCCURRED_TRY_AGAIN });
        }
    };

    /**
     * When deleting the image, we reset the internal state of the component and
     * we execute the callback with an undefined value, letting the parent component know
     * that nothing was added.
     */
    const onDelete = () => {
        dispatch(actions.reset());

        if (onDocumentUploaded) {
            onDocumentUploaded();
        }
    };

    return {
        isDocumentLoading: status === BaseLoadingStatus.loading,
        onDelete,
        state,
        onSelectDocument,
    };
};
