import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Image } from '@lumapps/lumx-images/types';
import { viewModeSlideshow } from '@lumapps/lumx/custom-icons';
import { mdiPencil, mdiTrashCanOutline, mdiViewDashboard } from '@lumapps/lumx/icons';
import { AlertDialog, ColorPalette, Kind, Text, Typography } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';
import { useDetectMobileOS } from '@lumapps/utils/hooks/useDetectMobileOS';
import { IMAGE_GROUP } from '@lumapps/wrex-enhanced-image/constants';
import { ImageEditor, ImageGroupElement } from '@lumapps/wrex-enhanced-image/types';
import { ElementToolbar } from '@lumapps/wrex/components/ElementToolbar';
import { Editor, Path, ReactEditor, useSelected, useSlateStatic } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import { ElementRender, ToolbarItem } from '@lumapps/wrex/types';

import { IMAGE_GALLERY_VIEW_MODE } from '../../../constants';
import { WREX_IMAGE_GALLERY } from '../../../keys';
import { ImageGallery } from '../../blocks/ImageGallery';
import { ImageGalleryDialog } from '../../ImageGalleryDialog';

import './index.scss';

const CLASSNAME = 'wrex-content-image-gallery';

/**
 * Wraps the Image Gallery content to add edition-related behaviors.
 */
export const EditableImageGallery: ElementRender<ImageGroupElement, HTMLDivElement> = (props) => {
    const { children, element = {} as ImageGroupElement, elementRef, ...forwardedProps } = props;

    const { element: elementClass } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(CLASSNAME);
    const selected = useSelected();
    const { translateKey } = useTranslate();
    const { currentBreakpoint, ref: dimRef } = useDimensions({ breakpoints: { reduced: 0, full: 400 } });
    const isMobileAppWebView = useDetectMobileAppWebView();
    const mobileOS = useDetectMobileOS();
    const editor = useSlateStatic() as ReactEditor & ImageEditor;
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
    const closeConfirmDialog = () => setIsConfirmDialogOpen(false);
    const openConfirmDialog = () => setIsConfirmDialogOpen(true);
    const [isImageGalleryDialogOpen, openImageGalleryDialog, closeImageGalleryDialog] = useBooleanState(false);
    const [shouldDisplayToolbar, setShouldDisplayToolbar] = React.useState(false);

    const { onDelete } = useInlineVoid(editor, element);
    const handleDelete = () => openConfirmDialog();

    const path = ReactEditor.findPath(editor, element) as Path;
    const pathRef = Editor.pathRef(editor, path);

    const handleChangeViewMode = (viewMode: IMAGE_GALLERY_VIEW_MODE) => {
        editor.updateImageGalleryNode({ ...element, viewMode }, pathRef);
    };

    const viewModeGalleryMosaic = element.viewMode === IMAGE_GALLERY_VIEW_MODE.mosaic;
    const viewModeGallerySlideshow = element.viewMode === IMAGE_GALLERY_VIEW_MODE.slideshow;

    const viewModeButtons: ToolbarItem[] = [
        {
            type: 'toggle-option',
            isChecked: viewModeGalleryMosaic,
            label: translateKey(GLOBAL.MOSAIC),
            icon: mdiViewDashboard,
            itemKey: 'view-mode-mosaic',
            onClick: () => handleChangeViewMode(IMAGE_GALLERY_VIEW_MODE.mosaic),
            otherProps: {
                ...get({ element: 'button', action: 'view-mode-mosaic' }),
            },
        },
        {
            type: 'toggle-option',
            isChecked: viewModeGallerySlideshow,
            label: translateKey(GLOBAL.SLIDESHOW),
            icon: viewModeSlideshow,
            itemKey: 'view-mode-slideshow',
            onClick: () => handleChangeViewMode(IMAGE_GALLERY_VIEW_MODE.slideshow),
            otherProps: {
                ...get({ element: 'button', action: 'view-mode-slideshow' }),
            },
        },
    ];

    const toolbarOptions: ToolbarItem[] =
        isMobileAppWebView || !!mobileOS
            ? []
            : [
                  {
                      type: 'section',
                      itemKey: 'section-key',
                      childrenOptions: [
                          {
                              type: 'submenu',
                              childrenOptions: viewModeButtons,
                              label: viewModeGalleryMosaic
                                  ? translateKey(GLOBAL.MOSAIC)
                                  : translateKey(GLOBAL.SLIDESHOW),
                              icon: viewModeGalleryMosaic ? mdiViewDashboard : viewModeSlideshow,
                              itemKey: 'submenu-key',
                          },
                          { type: 'divider', itemKey: 'divider-buttons-key' } as ToolbarItem,
                          {
                              type: 'option',
                              label: translateKey(WREX_IMAGE_GALLERY.EDIT_GALLERY),
                              icon: mdiPencil,
                              itemKey: 'edit-image-gallery',
                              onClick: openImageGalleryDialog,
                              otherProps: { ...get({ element: 'button', action: 'edit-image-gallery' }) },
                          },
                      ],
                  },
              ];

    const staticOptions: ToolbarItem[] = [
        {
            type: 'option',
            icon: mdiTrashCanOutline,
            itemKey: 'delete-image-gallery',
            tooltipLabel: translateKey(WREX_IMAGE_GALLERY.DELETE_GALLERY),
            onClick: handleDelete,
            otherProps: {
                color: ColorPalette.red,
                ...get({ element: 'button', action: 'delete-image-gallery' }),
            },
        },
    ];

    const formattedImages = element.images?.map((image) => {
        return {
            alt: image?.alt || '',
            url: image?.src,
            blobUrl: image?.tempSrc,
            width: image?.width,
            height: image?.height,
        };
    });

    const handleOnEdit = async (images: Image[]) => {
        await editor.updateImageGalleryNode(element, pathRef, images);
        closeImageGalleryDialog();
    };

    React.useEffect(() => {
        setShouldDisplayToolbar(!!selected);
    }, [selected]);

    return (
        <div className={elementClass('main-wrapper', { editable: true })} ref={elementRef} {...forwardedProps}>
            <ImageGallery
                element={element}
                className={classnames(
                    'wrex-content-image-gallery--editable',
                    selected && 'wrex-content-image-gallery--selected',
                )}
                elementRef={dimRef}
                isEditable
                onMouseEnter={() => {
                    if (!selected) {
                        setShouldDisplayToolbar(true);
                    }
                }}
                onMouseLeave={() => {
                    if (!selected) {
                        setShouldDisplayToolbar(false);
                    }
                }}
            >
                {shouldDisplayToolbar && (
                    <ElementToolbar
                        toolbarOptions={toolbarOptions}
                        dataScope={CLASSNAME}
                        toolbarAriaLabel={translateKey(WREX_IMAGE_GALLERY.TOOLBAR)}
                        currentBreakpoint={currentBreakpoint}
                        staticOptions={staticOptions}
                    />
                )}
                {children}
            </ImageGallery>
            {isImageGalleryDialogOpen && (
                <ImageGalleryDialog
                    isOpen={isImageGalleryDialogOpen}
                    images={formattedImages}
                    onClose={closeImageGalleryDialog}
                    onSave={handleOnEdit}
                    isEdit
                />
            )}
            <AlertDialog
                kind={Kind.error}
                isOpen={isConfirmDialogOpen}
                title={translateKey(WREX_IMAGE_GALLERY.DELETE_GALLERY_TITLE_CONFIRMATION)}
                confirmProps={{
                    onClick: onDelete,
                    label: translateKey(GLOBAL.DELETE),
                    ...get({ element: 'button', action: 'confirm-delete-image-gallery' }),
                }}
                cancelProps={{
                    onClick: closeConfirmDialog,
                    label: translateKey(GLOBAL.CANCEL),
                    ...get({ element: 'button', action: 'cancel-delete-image-gallery' }),
                }}
            >
                <Text as="p" typography={Typography.body1}>
                    {translateKey(GLOBAL.WARNING_DELETE)}
                </Text>
            </AlertDialog>
        </div>
    );
};
EditableImageGallery.displayName = IMAGE_GROUP;
