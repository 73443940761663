export enum PLAY_PICKERS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=109471001
     */
    SEARCH_PLACEHOLDER = 'PLAY.VIDEO_LIBRARY_SEARCH_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211255857
     */
    SELECT_PLAYLIST = 'PLAY.SELECT_PLAYLIST',
}
