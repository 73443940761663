import { UrlPatterns } from './types';

/**
 * Used to match the protocol and sub-domain.
 *
 * @type {Regex}
 */
export const PROTOCOL_REGEX = /^(https?:)?\/\/(www\.)?/;

/**
 * Used to match a lumdrive file URL.
 *
 * @type {string}
 */
export const LUMDRIVE_URL_REGEX_STR = '(https?://[^/]+)?(/serve/.*?)';

/**
 * Used to match a haussmann file URL.
 *
 * @type {string}
 */
export const HAUSSMANN_URL_REGEX_STR =
    '(https?://[^/]+)?(/_ah/api/lumsites/v2/organizations/[0-9]+/)(medias|documents)(/[a-zA-Z0-9-=]+/permalink)([^/]+)?';

/**
 * Contains some common URL pattern for the video website we handle.
 *
 * @type {Object}
 */
export const URL_PATTERNS: UrlPatterns = {
    dailymotion: ['dailymotion.com/video/', 'dai.ly/', 'dailymotion.com/embed/video/'],
    vimeo: ['vimeo.com/', 'player.vimeo.com/video/'],
    youtube: [
        'youtube.com/watch?v=',
        'youtu.be/',
        'youtube.com/embed/',
        'youtube.com/live/',
        'youtube.googleapis.com/v/',
        'youtube.com/',
    ],
};

/** Classname for File preview block components */
export const FILE_PREVIEW_BLOCK_CLASSNAME = 'file-preview-block';
