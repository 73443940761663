import { WidgetPlaylistProperties } from '../../types';

type SwitchPropertiesOptionsParams<T> = {
    properties: WidgetPlaylistProperties;
    prop: T[];
    fieldName: keyof WidgetPlaylistProperties;
    callback(properties: WidgetPlaylistProperties): void;
    nextValue: T;
};

export const switchPropertiesOptions = <T>({
    properties,
    prop,
    fieldName,
    callback,
    nextValue,
}: SwitchPropertiesOptionsParams<T>) => {
    const filteredList = prop.filter((item) => item !== nextValue);
    const nextOrder = prop.includes(nextValue) ? filteredList : [...prop, nextValue];
    callback({
        ...properties,
        [fieldName]: nextOrder,
    });
};
