import BaseApi from '@lumapps/base-api';

const termsAndConditionsApi = new BaseApi({ path: 'termsandconditions' });

const getTermsAndConditions = (params: { customerId: string }) =>
    termsAndConditionsApi.get('get', {
        params,
    });

const resetTermsAndConditions = () => termsAndConditionsApi.get('reset');

const saveTermsAndConditions = (params: any) => termsAndConditionsApi.post('save', params);

export { getTermsAndConditions, resetTermsAndConditions, saveTermsAndConditions, termsAndConditionsApi };
