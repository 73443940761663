import BaseApi, { API_VERSION, ServerListResponse } from '@lumapps/base-api';

import { LikeListParams } from '../types';
import { ReactionDetails, ReactionResource, ResourceReaction, UserReactions } from './types';

const api = new BaseApi({ path: '', version: API_VERSION.v2 });

/** Add a reaction on a resource */
export const addReaction = async ({ resourceType, resourceId, reactionType }: ResourceReaction) => {
    const { data } = await api.put<ReactionDetails>(`${resourceType}/${resourceId}/reactions/${reactionType}`);
    return data;
};

/** Delete a reaction on a resource */
export const deleteReaction = async ({ resourceType, resourceId, reactionType }: ResourceReaction) => {
    const { data } = await api.delete<ReactionDetails>(`${resourceType}/${resourceId}/reactions/${reactionType}`);
    return data;
};

/** List reactions by users */
export const listUserReactions = async ({ resourceType, resourceId }: ReactionResource, params: LikeListParams) => {
    const { data } = await api.get<ServerListResponse<UserReactions>>(`${resourceType}/${resourceId}/reactions/users`, {
        params,
    });
    return data;
};
