import { canAccessBroadcast } from '@lumapps/broadcasts/ducks/selectors';
import { doesCurrentUserHaveAccessToCustomContentTypes } from '@lumapps/content-types/ducks/selectors';
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { ContributionMenu } from './ContributionMenu';

const mapStateToProps = (state: FrontOfficeStore) => {
    const doesCurrentUserHaveAccessToCCT = doesCurrentUserHaveAccessToCustomContentTypes(state);
    const doesHaveAccessToCCT = isInstanceSuperAdmin(state) || doesCurrentUserHaveAccessToCCT;
    const isCreateBroadcastEnabled = canAccessBroadcast(state);
    return {
        doesHaveAccessToCCT,
        isCreateBroadcastEnabled,
    };
};

const ConnectedContributionMenu = connect(mapStateToProps)(ContributionMenu);

export { ConnectedContributionMenu as ContributionMenu };
