/* istanbul ignore file */
import { GLOBAL } from '@lumapps/translations';

export const USER_DIRECTORY_REACT = 'user-directory-react';
export const USERS_DIRECTORY_MAX_RESULTS = 12;

export const MIN_ITEM_WIDTH = 248;
export const BLOCK_SKELETON_HEIGHT = 296;
export const FIELDS_KEYS = {
    first_name: GLOBAL.FIRST_NAME,
    last_name: GLOBAL.LAST_NAME,
};
export const EMAIL_FIELD_TYPE = 'email';
export const USER_FIELD_TYPE = 'user';
