import React from 'react';

import { RelativeDateWithTranslation } from '@lumapps/lumx-texts/components/RelativeDateWithTranslation';
import { BaseSearchResult } from '@lumapps/search/types';
import { GLOBAL } from '@lumapps/translations';

import { AttachmentsCount } from '../AttachmentsCount';
import { ContentMetadataPropsOptions } from './types';

/** retrieves the score to be displayed from the Base result with the corresponding translation */
export const scoreGenerator = (result: BaseSearchResult, options: ContentMetadataPropsOptions) => {
    const { translate } = options;
    const { article, post } = result;
    const { score } = article || post || {};

    if (score && score.total > 0) {
        return `${translate(GLOBAL.SCORE)}: ${score.total}`;
    }

    return null;
};

/** Retrieves the timestamp translation for the desired time */
export const getMetadataTimestampLabel = (
    /** possible update and creation times as string */
    times: { updateTime: string; createTime?: string },
    options: ContentMetadataPropsOptions,
) => {
    const { useUpdateTime = false, useReceivedOnTime = false } = options;

    if (useUpdateTime) {
        return (
            <RelativeDateWithTranslation
                key="updateTime"
                date={times.updateTime}
                translations={{
                    underThreshold: {
                        translationKey: GLOBAL.RELATIVE_LAST_MODIFIED_DATE,
                        dateReplacementKey: 'RELATIVE_DATE',
                    },
                    overThreshold: {
                        translationKey: GLOBAL.LAST_MODIFIED_ON,
                        dateReplacementKey: 'DATE',
                    },
                }}
            />
        );
    }

    if (useReceivedOnTime) {
        return (
            <RelativeDateWithTranslation
                key="receivedOnTime"
                date={times.updateTime}
                translations={{
                    underThreshold: {
                        translationKey: GLOBAL.RELATIVE_RECEIVED_DATE,
                        dateReplacementKey: 'RELATIVE_DATE',
                    },
                    overThreshold: {
                        translationKey: GLOBAL.RECEIVED_ON,
                        dateReplacementKey: 'DATE',
                    },
                }}
            />
        );
    }

    if (times.createTime) {
        return (
            <RelativeDateWithTranslation
                key="createTime"
                date={times.createTime}
                translations={{
                    underThreshold: {
                        translationKey: GLOBAL.RELATIVE_PUBLISHED_DATE,
                        dateReplacementKey: 'RELATIVE_DATE',
                    },
                    overThreshold: {
                        translationKey: GLOBAL.PUBLISHED_ON_DATE,
                        dateReplacementKey: 'DATE',
                    },
                }}
            />
        );
    }
    return null;
};

export const timestampGenerator = (result: BaseSearchResult, options: ContentMetadataPropsOptions) => {
    const { metadata } = result;

    if (metadata) {
        return getMetadataTimestampLabel({ createTime: metadata.createTime, updateTime: metadata.updateTime }, options);
    }

    return null;
};

/** Retrieves the label to display for the result's likes with the corresponding translation */
export const likesGenerator = (result: BaseSearchResult, options: ContentMetadataPropsOptions) => {
    const { translate } = options;
    const { article, post } = result;
    const { likes } = article || post || {};

    if (likes) {
        return `${likes} ${likes === 1 ? translate(GLOBAL.LIKE) : translate(GLOBAL.LIKES)}`;
    }

    return null;
};

/** Retrieves the label to display for the result's comments with the corresponding translations */
export const commentsGenerator = (result: BaseSearchResult, options: ContentMetadataPropsOptions) => {
    const { translate } = options;
    const { article, post } = result;
    const { comments } = article || post || {};

    if (comments) {
        return `${comments} ${comments === 1 ? translate(GLOBAL.COMMENT) : translate(GLOBAL.COMMENTS)}`;
    }
    return null;
};

export const attachmentsGenerator = (result: BaseSearchResult) => {
    const { article, post } = result;
    const { attachmentsCount } = article || post || {};

    if (attachmentsCount && attachmentsCount > 0) {
        return <AttachmentsCount key="attachments" count={attachmentsCount} />;
    }

    return null;
};
