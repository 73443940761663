/**
 * An enum of search engine technologies that power the search.
 *
 * @type {Object}
 */
const ENGINE_TYPE = {
    CLOUDSEARCH: 'cloudsearch',
    ELASTICSEARCH: 'elasticsearch',
};

/**
 * An enum of icon settings for CloudSearch results and datasources.
 *
 * @type {Object}
 */
const ICON = {
    AUDIO: 'volume-high',
    FILE: 'file',
    FOLDER: 'folder',
    GOOGLE_CALENDAR: 'calendar',
    GOOGLE_DOCS: 'file-document-box',
    GOOGLE_DRAWING: 'drawing-box',
    GOOGLE_DRIVE: 'google-drive',
    GOOGLE_FORMS: 'lumapps-google-forms',
    GOOGLE_GROUPS: 'account-multiple',
    GOOGLE_KEEP: 'google-keep',
    GOOGLE_MAIL: 'email',
    GOOGLE_SHEETS: 'lumapps-google-sheets',
    GOOGLE_SITES: 'lumapps-google-sites',
    GOOGLE_SLIDES: 'file-presentation-box',
    IMAGE: 'image',
    LUMAPPS: 'lumapps',
    MICROSOFT_EXCEL: 'file-excel-box',
    MICROSOFT_POWERPOINT: 'file-powerpoint-box',
    MICROSOFT_WORD: 'file-word-box',
    PDF: 'file-pdf-box',
    VIDEO: 'movie',
};

/**
 * An enum of lumapps search result types.
 *
 * @type {Object}
 */
const LUMAPPS_TYPE = {
    ARTICLE: 'article',
    COMMUNITY: 'community',
    DIRECTORY_ENTRY: 'directoryEntry',
    MEDIA: 'media',
    POST: 'post',
    USER: 'user',
};

/**
 * An enum of search result types.
 *
 * @type {Object}
 */
const RESULT_TYPE = {
    GOOGLE: 'google',
    LUMAPPS: 'lumapps',
    THIRD_PARTY: 'third-party',
};

/**
 * The settings of the All tab for the search results page.
 *
 * @type {Object}
 */
const TAB_ALL = {
    uid: 'all',
};

/**
 * An object holding various options related to the Search (CloudSearch or ElasticSearch).
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const SearchConstant = {
    ENGINE_TYPE,
    ICON,
    LUMAPPS_TYPE,
    RESULT_TYPE,
    TAB_ALL,
};

/////////////////////////////

angular.module('Constants').constant('SearchConstant', SearchConstant);

/////////////////////////////

export { SearchConstant, ENGINE_TYPE, ICON, LUMAPPS_TYPE, RESULT_TYPE, TAB_ALL };
