import { useCallback, useEffect, useReducer } from 'react';

import { BaseApiError } from '@lumapps/base-api';
import { communityView } from '@lumapps/communities/routes';
import { error } from '@lumapps/notifications';
import { getById as fetchPost } from '@lumapps/posts/api';
import { REFRESH_EVENTS } from '@lumapps/posts/constants';
import { actions as postActions } from '@lumapps/posts/ducks/slice';
import { Post, RefreshPosts } from '@lumapps/posts/types';
import { useDispatch } from '@lumapps/redux/react';
import { notFoundRoute, useParams, useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { actions as widgetActions } from '@lumapps/widget-base/ducks/slice';

import { actions, initialState, reducer } from '../ducks/slice';

export const usePostDetails = (widgetId: string) => {
    const reduxDispatch = useDispatch();
    const { redirect } = useRouter();
    const { translateObject } = useTranslate();

    const [state, localDispatch] = useReducer(reducer, initialState);

    const { identifier: postId } = useParams<{ identifier: string }>();

    useEffect(() => {
        const fetchData = async () => {
            // We fetch only if we have a postId,
            // if there is no ongoing call and
            // if the postId changed since last fetch.
            if (postId && !state.isLoading && postId !== state.post?.id && !state.hasError) {
                try {
                    localDispatch(actions.fetchPostDetailsRequest());

                    const response = await fetchPost(postId);

                    localDispatch(actions.fetchPostDetailsSuccess({ post: response.data }));
                    reduxDispatch(postActions.updatePost({ postId, post: response.data }));
                } catch (err) {
                    localDispatch(actions.fetchPostDetailsFailure());

                    if ((err as BaseApiError)?.response?.status === 404) {
                        redirect(notFoundRoute);
                    } else {
                        reduxDispatch(error({ translate: GLOBAL.GENERIC_ERROR }));
                    }
                }
            }
        };

        fetchData();

        // We don't want the fetch to be triggered on loading or error status update.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId, redirect, reduxDispatch, state.post?.id]);

    useEffect(() => {
        /**
         * Cleaning redux state when leaving the post detail page
         */
        return () => {
            reduxDispatch(postActions.resetUpdatedPost({ postId }));
        };
    }, [postId, reduxDispatch]);

    useEffect(() => {
        if (!postId || (state.hasInit && !state.isLoading && !state.post)) {
            reduxDispatch(widgetActions.setWidgetProperties({ widgetId, widgetProperties: { state: 'empty' } }));
        }
    }, [postId, reduxDispatch, state.hasInit, state.isLoading, state.post, widgetId]);

    const refreshPost = useCallback<RefreshPosts>(
        (event, response) => {
            // eslint-disable-next-line default-case
            switch (event) {
                case REFRESH_EVENTS.POST_PIN:
                case REFRESH_EVENTS.POST_UNPIN:
                case REFRESH_EVENTS.POST_SHARE:
                    localDispatch(actions.setPost({ post: response }));
                    break;
                case REFRESH_EVENTS.POST_DELETE:
                    redirect(
                        communityView({
                            slug: translateObject(state.post?.parentContentDetails?.slug) || '',
                        }),
                    );
                    break;
                case REFRESH_EVENTS.COMMENT_HIDE:
                    /* TODO this computation is wrong, and can lead to display a false count for admins, we'll need
                        to fix it once the responses of a comment are updated to status HIDE when it passes to HIDE */
                    localDispatch(
                        actions.updatePost({
                            updates: { comments: (state.post as Post).comments - (1 + (response?.answersNumber ?? 0)) },
                        }),
                    );
                    break;
                case REFRESH_EVENTS.COMMENT_ADD:
                    /* TODO this computation is wrong, and can lead to display a false count for admins, we'll need
                        to fix it once the responses of a comment are updated to status LIVE when it passes to LIVE */
                    localDispatch(
                        actions.updatePost({
                            updates: { comments: (state.post as Post).comments + (1 + (response?.answersNumber ?? 0)) },
                        }),
                    );
                    break;
                case REFRESH_EVENTS.COMMENT_MARK_RELEVANT:
                    localDispatch(actions.updatePost({ updates: { relevantComment: response.relevantCommentId } }));
                    break;
                case REFRESH_EVENTS.COMMENT_UNMARK_RELEVANT:
                    localDispatch(actions.updatePost({ updates: { relevantComment: undefined } }));
                    break;
            }
        },
        [redirect, state.post, translateObject],
    );

    return { ...state, refreshPost };
};
