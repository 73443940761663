import { CalendarViews } from '@lumapps/lumx-calendar/types';

import { LegacyCalendarProperties } from './types';

export const WIDGET_CALENDAR_TYPE = 'calendar';

export const CALENDAR_VIEW: Record<LegacyCalendarProperties['visualizationMode'], CalendarViews> = {
    week: CalendarViews.week,
    month: CalendarViews.month,
    planning: CalendarViews.schedule,
};

/**
 * Queries should become stale after 30s.
 * Avoid re-fetching
 * */
export const QUERY_STALE_TIME = 30000;
