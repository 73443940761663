import { ENGINES } from '../constants';
import { SearchParams, SearchSuggestionParams, SearchSuggestionResponse } from '../types';
import { search as driveSearch } from './drive';
import { suggest as gcsSuggest } from './gcs';
import { search as lumappsSearch, suggest as lumappsSuggest } from './lumapps';
import { search as lumworkSearch } from './lumwork';
import { search as sharepointSearch } from './sharepoint';
import { search as zendeskSearch } from './zendesk';

const searchEngines = {
    [ENGINES.DRIVE]: driveSearch,
    [ENGINES.LUMAPPS]: lumappsSearch,
    [ENGINES.LUMWORK]: lumworkSearch,
    [ENGINES.ZENDESK]: zendeskSearch,
    [ENGINES.SHAREPOINT]: sharepointSearch,
    [ENGINES.CLOUDSEARCH]: lumappsSearch,
    [ENGINES.COVEO]: lumappsSearch,
    [ENGINES.MICROSOFT]: lumappsSearch,
};

/**
 * Determines the search engine to be used from the provided parameters
 * @param searchParams - the list of parameters that will be passed to the search engine.
 */
const determineSearchEngine = (searchParams: Partial<SearchParams>) => {
    const { features, filter } = searchParams;
    const filterEngine = filter as ENGINES;

    /**
     * We then need to check a specific scenario when we are using Google Cloud Search and the
     * current filter is Google Drive. In this specific scenario, we need to call the LumApps
     * search engine rather than Google Drive, that is why if the are in that scenario, we use
     * LumApps's search engine
     */
    if (features && features.isCSEnabled && filterEngine === ENGINES.DRIVE) {
        return ENGINES.LUMAPPS;
    }

    /**
     * If we did not still find a search engine, we check against the available search engines using
     * the filter applied. This applies for Zendesk, LumWork, Drive, Sharepoint, etc.
     */
    if (searchEngines[filterEngine]) {
        return filterEngine;
    }

    /** Worst case scenario, we always use LumApps search engine */
    return ENGINES.LUMAPPS;
};

/**
 * This function wraps all the different search engines that we have on LumApps. It will forward
 * the search request to the correct search engine and process the request.
 * @param searchParams - the list of parameters that will be passed to the search engine.
 * @param engine - override the search engine to use a specific one.
 */
const search = (searchParams: SearchParams, traceId: string, engine?: ENGINES) => {
    /**
     * If we want to use a specific engine, the parameter engine allows to explicitly
     * use that search engine. if it is present, we just go ahead and use it.
     */
    if (engine) {
        return searchEngines[engine](searchParams, undefined, undefined, undefined, traceId);
    }

    const searchEngine = determineSearchEngine(searchParams);
    return searchEngines[searchEngine](searchParams, undefined, undefined, undefined, traceId);
};

const suggestEngines: Record<string, (params: SearchSuggestionParams) => Promise<SearchSuggestionResponse>> = {
    [ENGINES.CLOUDSEARCH]: gcsSuggest,
    [ENGINES.COVEO]: lumappsSuggest,
    [ENGINES.LUMAPPS]: lumappsSuggest,
};

const suggest = (params: SearchSuggestionParams, engine: ENGINES) => {
    const suggestToUse = suggestEngines[engine];
    if (suggestToUse) {
        return suggestToUse(params);
    }
    return Promise.reject();
};

export { search, suggest, determineSearchEngine };
