/* istanbul ignore file */
import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiThumbUp } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SA_NAVIGATION } from '../../../keys';
import { saSocialNetworks } from '../../../routes';

export interface SocialNetworksSideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const SocialNetworksSideNavigation = (props: Partial<SocialNetworksSideNavigationProps>) => {
    const { isAllowed } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(SA_NAVIGATION.SOCIAL_NETWORKS)}
            route={saSocialNetworks()}
            icon={mdiThumbUp}
        />
    );
};

SocialNetworksSideNavigation.displayName = 'SideNavigationItem';

export { SocialNetworksSideNavigation };
