import React, { useEffect } from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Heading, Placement, Size, Switch, Tooltip } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { WIDGET_SETTINGS } from '@lumapps/widget-settings/keys';
import { SELECTION_TYPE_VALUES, SelectionSettingType } from '@lumapps/widget-settings/types';

import { DATA_SCOPE } from '../../../constants';
import { WIDGET_ARTICLE_LIST } from '../../../keys';
import { WidgetArticleListPropertiesType } from '../../../types';

type Restrictions = Pick<WidgetArticleListPropertiesType, 'isFollowedCommunityFilterActive'>;

export interface RestrictionSectionProps {
    onChange: (properties: Restrictions) => void;
    currentRestrictions?: Restrictions;
    currentSelectionType: SelectionSettingType;
}

const CLASSNAME = 'article-list-widget-settings__restriction-section';

/**
 *
 * @param RestrictionSectionProps
 * @returns RestrictionSection
 */
export const RestrictionSection: React.FC<RestrictionSectionProps> = ({
    onChange,
    currentRestrictions = {},
    currentSelectionType,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(`${DATA_SCOPE}-restrictions`);
    const { block } = useClassnames(CLASSNAME);

    useEffect(() => {
        if (
            currentRestrictions.isFollowedCommunityFilterActive &&
            currentSelectionType.selectedOption === SELECTION_TYPE_VALUES.CURRENT_COMMUNITY
        ) {
            onChange({ isFollowedCommunityFilterActive: false });
        }
    }, [currentRestrictions.isFollowedCommunityFilterActive, currentSelectionType.selectedOption, onChange]);
    const isDisabled = currentSelectionType.selectedOption === SELECTION_TYPE_VALUES.CURRENT_COMMUNITY;

    return (
        <section className={block([margin('vertical', Size.huge)])}>
            <Heading as="h4" typography="subtitle1" className={margin('bottom', 'huge')}>
                {translateKey(WIDGET_SETTINGS.RESTRICTIONS_SECTION_TITLE)}
            </Heading>

            <Tooltip
                placement={Placement.TOP}
                label={
                    isDisabled ? translateKey(WIDGET_SETTINGS.DISABLED_FOLLOWED_COMMUNITIES_FILTER_HELPER) : undefined
                }
            >
                <Switch
                    isDisabled={isDisabled}
                    isChecked={Boolean(currentRestrictions.isFollowedCommunityFilterActive)}
                    onChange={(value) => onChange({ isFollowedCommunityFilterActive: value })}
                    className={margin('bottom', 'big')}
                    helper={translateKey(WIDGET_ARTICLE_LIST.SETTINGS_FOLLOWED_COMMUNITIES_FILTER_HELPER)}
                    {...get({ element: 'switch', action: 'toggle-followed-communities' })}
                >
                    {translateKey(WIDGET_SETTINGS.FOLLOWED_COMMUNITIES_ONLY)}
                </Switch>
            </Tooltip>
        </section>
    );
};
