import debounce from 'lodash/debounce';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import remove from 'lodash/remove';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { sharePostInCommunities } from 'components/components/post/ducks/post_actions';

/////////////////////////////

function MultiSelectCommunitySelectorController(
    $scope,
    Community,
    CommunityConstant,
    Instance,
    CommunitySearch,
    LxDialogService,
    ReduxStore,
    Translation,
    Utils,
) {
    'ngInject';

    /**
     * The lang to apply to the community search call.
     *
     * @type {string|Array}
     */
    let _SEARCH_LANG;

    // eslint-disable-next-line consistent-this, angular/controller-as-vm
    const mscs = this;

    /**
     * The post we are working on
     *
     * @type {Object}
     */
    mscs.post = {};

    /**
     * A map of various loading states.
     * - searchLoading: whether the community search is currently loading or not.
     * - locked: a share API call is in progress
     *
     * @type {Object}
     */
    mscs.is = {
        searchLoading: false,
        locked: false
    };

     /**
     * The pending community IDs store.
     *
     * @type {Object}
     */
    mscs.pendingCommunities = [];

    /**
     * A locked community IDs store.
     *
     * @type {Object}
     */
    mscs.lockedCommunities = [];

    /**
     * The pending community map.
     *
     * @type {Object}
     */
    mscs.pendingCommunitiesMap = {};

    /**
     * The list key for the list of communities.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    mscs.LIST_KEY = 'community-list-key';

    /**
     * The community block fields used when picking a community.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    mscs.FIELDS = ['members', 'title', 'content'];

    /**
     * Contains miscellaneous status about the controller.
     *
     * @type {Object}
     */
    mscs.is = {
        saving: false,
    };

    /**
     * List of classes applied to new post dialog.
     *
     * @type {array}
     */
    mscs.classes = [];

     /**
     * The post current community.
     */
    mscs.community = undefined;

    mscs.multiSelectCommunityId = 'multi-select-community-dialog';

    /**
     * Contains the filters for the view.
     *
     * @type {Object}
     */
    mscs.filters = {
        community: undefined,
    };

    /**
     * The scope of the community search.
     *
     * @type {boolean} true to search only within the communities followed by the current user.
     */
    mscs.filterFollowingOnly = true;

    /**
     * The delay to use to debounce the search communities function.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _SEARCH_COMMUNITIES_DEBOUNCE_DELAY = 500;

     /**
     * Trigger the fetching of the next page of results when reaching the bottom of the dialog.
     */
    function onDialogScrollEnd() {
        if ((Community._services[mscs.LIST_KEY]._list && Community._services[mscs.LIST_KEY]._list.length > 0)
            && mscs.is.searchLoading === false) {
            mscs.is.searchLoading = true;
            CommunitySearch.nextPage(
                mscs.LIST_KEY,
                'communityList',

                ({ items = [] } = {}) => {
                    Community._services[mscs.LIST_KEY]._list = Community._services[mscs.LIST_KEY]._list.concat(items);
                    mscs.is.searchLoading = false;
                },

                () => {
                    mscs.is.searchLoading = false;
                },
            );
        }
    }

    $scope.$on('community-multi-select__open', function pickCommunities(event, args) {
        _SEARCH_LANG = angular.isDefinedAndFilled(Instance.getInstance().langs)
            ? Instance.getInstance().langs
            : Translation.getLang('current');

        mscs.is.locked = false;
        mscs.is.searchLoading = false;

        mscs.pendingCommunitiesMap = {};
        mscs.pendingCommunities = [];
        mscs.lockedCommunities = [];
        mscs.post = args.post;

        /*
         * Make some communities not selectable, i.e. where the post is already shared to
         */
       if(mscs.post.visibleInCommunitiesDetails){
            mscs.post.visibleInCommunitiesDetails.forEach(aShare => {
                mscs.lockedCommunities.push(aShare.postedTo.uid);
            })
       }


        Utils.waitForAndExecute(`#${mscs.multiSelectCommunityId}`);
    }
    )

    $scope.$on('community-multi-share__ended',  (event, {success} = args) => {
        if(success){
            LxDialogService.close(mscs.multiSelectCommunityId);
        }
        else{
            mscs.is.locked = false;
        }
    })

    /**
     * Return the classes applied to the new post dialog.
     *
     * @return {Array} The list of classes to apply.
     */
    function getClasses() {
        return mscs.classes;
    }




    $scope.$on('lx-dialog__open-start', function onNewPostDialogOpen(evt, dialogId) {
        if (mscs.multiSelectCommunityId === dialogId ) {
            _communitySearch({
                query: mscs.filters.community,
                postId: mscs.post.uid,
                followingOnly: mscs.filterFollowingOnly
            },
            undefined,
            undefined,
            mscs.LIST_KEY);
        }
    });


    /**
     * Search for a community.
     * Note: this is different from the "search" in the move post workflow, which uses a community.list because the
     * community.list has a parameter isCommunityAdmin that the search endpoint does not handle so we need to keep the
     * two separate for now.
     *
     * @param {Object}   params  Parameters to pass to the API endpoint.
     * @param {Function} cb      A function to execute on success.
     * @param {Function} errCb   A function to execute on error.
     * @param {string}   listKey The list identifier.
     */
    function _communitySearch({ query, postId, followingOnly }, cb = noop, errCb = noop, listKey) {
        mscs.is.searchLoading = true;
        if(postId === undefined){ // since we are using a shared (with post creation) component for the input, postid may be undefined
            postId = mscs.post.uid;
        }

        CommunitySearch.search(
            listKey,
            'communityList',
            { lang: Translation.getLang('current') || 'en', postId, query, followingOnly },


            ({ items = [] } = {}) => {
                Community._services[listKey]._list = items;
                mscs.is.searchLoading = false;
                cb();
            },



            () => {
                mscs.is.searchLoading = false;
                errCb();
            },
            CommunityConstant.PROJECTION.PICKER,
        );
    }

    mscs.getClasses = getClasses;

    mscs.onInfiniteScroll = onDialogScrollEnd;

    mscs.requestComm = () => Community.displayList(mscs.LIST_KEY);

    mscs.selectCommunity = () => {
        mscs.is.locked = true;
        ReduxStore.store.dispatch(sharePostInCommunities(mscs.post.uid, mscs.pendingCommunities));
    }


    /**
     * Select or unselect a community from the list
     */
    mscs.toggleCommunity = community => {
        const removed = remove(mscs.pendingCommunities, communityId => communityId === community.uid);
        if( removed.length === 0 ){
            mscs.pendingCommunities.push(community.uid);
            mscs.pendingCommunitiesMap[community.uid] = community;
        }
        else{
            delete mscs.pendingCommunitiesMap[removed[0]];
        }
    }

    /**
     * Select or unselect a community from the list
     */
    mscs.onSelectSearchScope = (isFollowedCommunitiesOnly) => {
        if (mscs.filterFollowingOnly !== isFollowedCommunitiesOnly){
            mscs.filterFollowingOnly = isFollowedCommunitiesOnly;
            _communitySearch(
                {
                    query: mscs.filters.community,
                    postId: mscs.post.uid,
                    followingOnly: mscs.filterFollowingOnly,
                },
                undefined,
                undefined,
                mscs.LIST_KEY,
            );
        }
    }

    mscs.debouncedCommunitySearch = debounce( _communitySearch, _SEARCH_COMMUNITIES_DEBOUNCE_DELAY);

}

/////////////////////////////

angular.module('Controllers').controller('MultiSelectCommunitySelectorController', MultiSelectCommunitySelectorController);

/////////////////////////////

export { MultiSelectCommunitySelectorController };
