/**
 * Get nodes from the DataTransfer of a copy / paste
 * Get the nodes according to the insertFragmentData function of the slate repo
 * https://github.com/ianstormtaylor/slate/blob/cee8e7e421eaaf24567532507dfdd5f343a04f2b/packages/slate-react/src/plugin/with-react.ts#L314
 */
export const getNodesFromSlateData = (data: DataTransfer, slateFormatKey = 'x-slate-fragment'): Node[] | undefined => {
    const fragment = data.getData(`application/${slateFormatKey}`);

    if (!fragment) {
        return undefined;
    }

    const decoded = decodeURIComponent(window.atob(fragment));
    const nodes = JSON.parse(decoded) as Node[];

    return nodes;
};
