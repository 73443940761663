import { connect } from '@lumapps/redux/react';

import { mapStateToProps } from '../../mapStateToProps';
import { BroadcastSettingMenu } from './BroadcastSettingMenu';

const mapDispatchToProps = {};

const Connected = connect(mapStateToProps, mapDispatchToProps)(BroadcastSettingMenu);

export { Connected as BroadcastSettingMenu };
