/**
 * Move a given item in a list to a position in the same list.
 *
 * @param  array      A list of items.
 * @param  fromIndex  The position of the item to move.
 * @param  toIndex    The position to move the item to.
 * @return The list with the item moved in its new position.
 *
 */
export function move(array: any[], fromIndex: number, toIndex: number) {
    const newArray = [...array];

    const lastIndex = newArray.length - 1;
    if (newArray.length === 0 || fromIndex > lastIndex || fromIndex < 0) {
        return newArray;
    }
    // Remove the item at fromIndex from the array.
    const [itemToMove] = newArray.splice(fromIndex, 1);
    // Insert it in its new position.
    newArray.splice(Math.min(toIndex, lastIndex), 0, itemToMove);

    return newArray;
}
