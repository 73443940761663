import { RenderingType } from '@lumapps/communities/types';
import { AuthorDetails } from '@lumapps/contents/types';
import { Tag } from '@lumapps/folksonomy/types';
import { Tag as PostCategory } from '@lumapps/tags/types';
import { TranslateObject } from '@lumapps/translations';
import { User } from '@lumapps/user/types';
import { LinkAttachment } from '@lumapps/widget-base/types';
import { UseAttachmentsButtonProps } from '@lumapps/wrex-attachments/components/buttons/useAttachmentsButtonProps';
import { UseInsertImageButtonProps } from '@lumapps/wrex-image/components/buttons/InsertImageButton/useInsertImageButton';
import type { Wrex } from '@lumapps/wrex/types';

export interface PostsState {
    // eslint-disable-next-line no-use-before-define
    editedPost: Partial<WrexPost>;
    entities: {
        // eslint-disable-next-line no-use-before-define
        [key: string]: Post;
    };
    saving: boolean;
    isPostDialogOpen: boolean;
}

export interface PostStatus {
    /** Id of the status */
    uuid: string;
    /** Name of the status. */
    title: TranslateObject;
}

/**
 * Legacy format for the postTypes (uppercase)
 */
export enum PostType {
    DEFAULT = 'DEFAULT',
    EVENT = 'EVENT',
    QUESTION = 'QUESTION',
    IDEA = 'IDEA',
}

/**
 * New format for the postTypes (lowercase)
 */
export enum PostTypeV2 {
    DEFAULT = 'default',
    EVENT = 'event',
    QUESTION = 'question',
    IDEA = 'idea',
}

/**
 * This aims at describing what v1 endpoints return to describe a
 * community where a post was pinned or shared
 */
export interface LegacyCommunityRef {
    id: string; // community id
    uid?: string; // community id
    slug: TranslateObject<string>; // community slug
    title: TranslateObject<string>; // community name
    instance: string; // instance id
    renderingType?: RenderingType; // community rendering type
}

/**
 * This aims at describing what v1 endpoints return an array of in
 * the visibleInCommunitiesDetails key. It conveys similar info to
 * what is now called "shares"
 */
export interface LegacyCommunityShare {
    canPin: boolean;
    canUnshare: boolean;
    postedAt: string;
    postedBy: { id: string; firstName: string; lastName: string };
    postedTo: LegacyCommunityRef;
}

/**
 * This aims at describing what v1 endpoints return an array of in
 * the pinnedInCommunitiesDetails key. It conveys similar info to
 * what is now a simple boolean: "isPinned" (in fact we don't need
 * to know when it was pinned and by whom)
 */
export interface LegacyCommunityPin {
    pinnedAt: string;
    pinnedBy: { id: string };
    pinnedIn: LegacyCommunityRef;
}

export interface Post {
    /** Only for EVENT post type, the start date of the event. */
    eventStartDate?: string;
    /** Only for EVENT post type, the end date of the event. */
    eventEndDate?: string;
    /** Creation date of the post. */
    createdAt: string;
    /** The number of current likes */
    likes?: number;
    /** Whether the post is liked or not by the currentUser. */
    liked: boolean;
    /** The uid of the post. */
    uid: string;
    /** The publication date of the post in ISOString format. */
    publicationDate: string;
    /** Whether the post is pinned. */
    isPinned: boolean;
    /** The title of the post, wrapped in a translation object. */
    title: TranslateObject;
    /** Whether the post can be shared between communities (multiposting). */
    canShare: boolean;
    /** The number of comments for the post. */
    comments: number;
    /** The content of the post, wrapped in a translation object. */
    content: TranslateObject;
    /** The excerpt of the post, wrapped in a translation object. (might be plain text while content is markdown) */
    excerpt: TranslateObject;
    /** The type of post. */
    postType: PostType;
    /** The type of content, always "post" here. */
    type: 'post';
    /** Images as attachments of the post */
    images?: Array<any>;
    /** Files as attachments of the post */
    files?: Array<any>;
    /** Links as attachments of the post */
    links?: Array<LinkAttachment>;
    /** Informations about the author of the post. */
    authorDetails?: AuthorDetails;
    /** Status of the post. Used only for IDEA posts. */
    postStatusDetails?: PostStatus;
    /** Identifier of the status of the post. Used only for IDEA posts. */
    postStatus?: string;
    /** Categories (tags) of the post. */
    tagsDetails?: PostCategory[];
    /** Informations about the post's parent content (can be a community or a content). */
    parentContentDetails?: {
        /** The community Id */
        id?: string;
        /** List of all availables post types for the given community. */
        postTypes: PostType[];
        /** List of availables post statuses for the given community. */
        postStatuses?: PostStatus[];
        /** The title of the parent content. */
        title?: TranslateObject;
        /** The slug of the parent content. */
        slug: TranslateObject;
        /** Type of content */
        type: string;
        /** Canonical url */
        canonicalUrl: TranslateObject;
        /** Whether the option 'Resctricted storage' has been activated */
        securedRepository?: boolean;
        /** RenderingType of the community */
        renderingType?: RenderingType;
    };
    /** List of communities in which the post is shared. */
    visibleInCommunitiesDetails?: LegacyCommunityShare[];
    /** Number of communities in which the post is shared. */
    visibleInCommunitiesCount?: number;
    /** List of communities in which the post is pinned */
    pinnedInCommunitiesDetails?: LegacyCommunityPin[];
    /** The content or community id related to the post. */
    externalKey: string;
    /** The vote of the current user for the current post. */
    // eslint-disable-next-line no-use-before-define
    userVote?: IdeaVote;
    /** List of tagz of the post, linked to the Folksonomy feature flag. */
    tagz?: Tag[];
    /** Every vote informations for IDEA posts. */
    score?: {
        total: number;
        votesDown: number;
        votesUp: number;
    };
    /** Whether the attachments are being uploading */
    isAttachmentsLoading?: boolean;
    /** Wether the image attachments are being uploading */
    isImageAttachmentsLoading?: boolean;
    /** Whether the file attachments are being uploading */
    isFileAttachmentsLoading?: boolean;
    /** Whether the inline image are being uploading */
    isImageUploading?: boolean;
    /** Whether the vote is being saved or not. */
    isVoteLoading?: boolean;
    /** Details about mentionned users. */
    mentionsDetails?: Partial<User>[];
    /** For posts of type question, whether a comment has been set as relevant */
    hasRelevantComment?: boolean;
    /** For posts of type question, the relevant comment id if any */
    relevantComment?: string;
    /** The post instance id */
    instance?: string;
    /** The post id */
    id?: string;
    /** The post customer id */
    customer?: string;
    /** Whether the user can edit the current post */
    canEdit?: boolean;
    /** Whether the user can delete the current post */
    canDelete?: boolean;
    /** Whether the user can move the current post */
    canMove?: boolean;
}

export enum IdeaVote {
    UP = 'up',
    DOWN = 'down',
}

export type WrexPost = Omit<Post, 'content' | 'title'> & { content: Wrex.Nodes; title: string; [k: string]: any };
export type EditedPost = Partial<WrexPost>;

export type RefreshEvent =
    | 'unpin'
    | 'pin'
    | 'delete'
    | 'move'
    | 'share'
    | 'unshare'
    | 'add'
    | 'hide'
    | 'mark'
    | 'unmark';

export type RefreshPosts = (event: RefreshEvent, response?: any) => void;

export interface PostEditorToolbarPluginsProps {
    isInCommunityContext: boolean;
    inlineImagePlugin: UseInsertImageButtonProps;
    videoPlugin: {
        onVideoSubmit: (video: LinkAttachment) => void;
    } & { isDisabled: boolean };
    filePlugin: UseAttachmentsButtonProps & { isDisabled: boolean };
    imagePlugin: UseAttachmentsButtonProps & { isDisabled: boolean };
    linkPreviewPlugin: {
        onInsertLink: (link: LinkAttachment) => void;
    } & { isDisabled: boolean };
}

export type AttachmentTypes = 'files' | 'images' | 'links' | 'video';
