import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import loFind from 'lodash/find';

import { PROVIDERS } from 'front/modules/media/js/media_constant';
import { translate as t, getInputLanguage } from 'components/translations';

import { InitialSettings } from 'common/modules/constants/initial-settings_constants';

/**
 * Get document properties for specified language.
 *
 * @param  {Object}  media                            Media to get properties.
 * @param  {boolean} [useFallback=false]              Should fallback to default language.
 * @param  {string}  [lang=Translation.inputLanguage] Language used to get properties.
 * @param  {boolean} [originalOnly=false]             Indicates if we want to only use the original information
 *                                                    of the media content and not the media overrides.
 * @param  {string}  forceTarget                      If we want explicity cropped content or original content ('content' || 'croppedContent')
 * @return {Object}  Document properties.
 */
const getMediaContentByLang = ({ media, useFallback, lang, originalOnly, forceTarget }) => {
    if (isEmpty(media)) {
        return {};
    }

    lang = lang || getInputLanguage();
    useFallback = Boolean(useFallback);

    const mediaContentTarget = media[forceTarget] || (media.hasCroppedContent ? media.croppedContent : media.content);

    let mediaContent = (useFallback ? angular.fastCopy(first(mediaContentTarget)) : {}) || {};

    const mediaContentForInputLanguage = angular.fastCopy(
        loFind(mediaContentTarget, {
            lang,
        }),
    );

    if (!isEmpty(mediaContentForInputLanguage)) {
        mediaContent = { ...mediaContent, ...mediaContentForInputLanguage };
    }

    if (!originalOnly) {
        const changedName = t(media.name, lang, useFallback);
        if (!isEmpty(changedName)) {
            mediaContent.name = changedName;
        }

        const changedDescription = t(media.description, lang, useFallback);
        if (!isEmpty(changedDescription)) {
            mediaContent.description = changedDescription;
        }

        const changedThumbnail = t(media.thumbnail, lang, useFallback);

        if (!isEmpty(changedThumbnail)) {
            mediaContent.thumbnail = changedThumbnail;
        }
    }

    mediaContent.owner = mediaContent.owner || get(first(media.content || []), 'owner');

    return mediaContent;
};

/**
 * Resizes an image regarding the image source :
 * - Google and Local (lumapps): adds or replaces the `=s` parameter at the end of its url.
 * - Other: Simply returns the url.
 *
 * To add a method for a specific provider, make sure to add an entry in the switch statement below.
 *
 * @param  {string} url    The url of the image to resize.
 * @param  {string} number The width to apply.
 * @return {string} The resized image string.
 */
const resizeImage = ({ url, size, source }) => {
    const resizeRegexGoogleLocal = /^(.*?)(=s[\d]*)?$/;
    const { MAX_IMAGE_SIZE } = InitialSettings;

    switch (source) {
        case PROVIDERS.lumapps:
        case PROVIDERS.google:
            return url.replace(resizeRegexGoogleLocal, `$1=s${size || MAX_IMAGE_SIZE}`);

        default:
            return url;
    }
};

function getCroppedThumbnailFromContent(content, size) {
    const url = content.servingUrl || content.url || content.thumbnail;

    return url
        ? resizeImage({
              url,
              size,
              source: content.source,
          })
        : undefined;
}

/**
 * Get media thumbnail image cropped URL from Media.
 * If exists, cropped picture should be recovered from media.croppedContent.
 * If not, we get thumbnail the original picture.
 * Test all properties to ensure compatibility with previous image.
 *
 * @param  {Object}  media               Media to get thumbnail from.
 * @param  {boolean} [useFallback=false] Should fallback on default language.
 * @param  {number}  [size=-1]           The size you want the image.
 * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
 * @return {string}  The cropped image's url.
 */
function getCroppedThumbnailFromMedia(media, useFallback, size = -1, lang) {
    const content = getMediaContentByLang({
        media,
        useFallback,
        lang,
    });
    if (content && (content.url || content.servingUrl || content.thumbnail)) {
        return getCroppedThumbnailFromContent(content, size);
    }
    // media.thumbnail could be an object or a string
    if (isPlainObject(media.thumbnail)) {
        return media.thumbnail[lang] || '';
    }
    return media.thumbnail;
}

/**
 * Get media thumbnail image cropped URL from root Content (which one contains mediaThumbnail).
 * Test all properties to ensure compatibility with previous image.
 *
 * @param  {Object}  content             Content to get thumbnail from.
 * @param  {boolean} [useFallback=false] Should fallback on default language.
 * @param  {number}  [size=-1]           The size you want the image.
 * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
 * @param  {Object}  [overrides]         The possible overrides of the media content.
 * @return {string}  The cropped image's url.
 */
function getCroppedThumbnailFromRootContent(rootContent, useFallback, size = -1, lang) {
    if (rootContent.mediaThumbnail) {
        return getCroppedThumbnailFromMedia(rootContent.mediaThumbnail, useFallback, size, lang);
    }
    if (size !== -1) {
        return resizeImage(rootContent.thumbnail, size);
    }
    return rootContent.thumbnail;
}

/**
 * Get thumbnail's focal point coordinates.
 * If exists, focal point coordinates should be recovered from media.croppedContent.
 * If not, we get coordinates from the original content.
 *
 * @param  {Object}  media               Media to get thumbnail from.
 * @param  {boolean} [useFallback=false] Should fallback on default language.
 * @param  {string}  [lang]              The lang we want to use to get the image from the media content.
 * @param  {Object}  [overrides]         The possible overrides of the media content.
 * @return {string}  The cropped image's url.
 */
function getFocusPointFromMedia({ media, useFallback, lang }) {
    if (media) {
        const mediaContent = getMediaContentByLang(media, useFallback, lang);
        return mediaContent.focalPoint || undefined;
    }
    return undefined;
}

export {
    resizeImage,
    getMediaContentByLang,
    getFocusPointFromMedia,
    getCroppedThumbnailFromMedia,
    getCroppedThumbnailFromContent,
    getCroppedThumbnailFromRootContent,
};
