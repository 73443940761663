import assign from 'lodash/assign';
import lodashFind from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import set from 'lodash/set';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function CellController(
    $document,
    $element,
    $filter,
    $scope,
    $timeout,
    $window,
    Cell,
    ConfigTheme,
    Community,
    Content,
    ContentTemplate,
    Features,
    InitialSettings,
    Layout,
    LxDialogService,
    LxNotificationService,
    Media,
    Row,
    Style,
    Translation,
    User,
    UserAccess,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The ratio to trigger a resize of the cell.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _CELL_RATIO_TRIGGER = 3;

    /**
     * The default shadow elevation of the plain cell.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_SHADOW_ELEVATION = 1;

    /**
     * The default z-index for sticky cells.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_Z_INDEX = 100;

    /**
     * The size of the grid where the cells are.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _GRID_SIZE = 12;

    /**
     * The key code of '?' key (in a key press event).
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _INTERROGATION_MARK_KEY = 63;

    /**
     * The offset of the bottom padding of a sticky cell.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _STICKY_CELL_PADDING_BOTTOM_OFFSET = 12;

    /**
     * The list of parents of the current cell.
     *
     * @type {Array}
     */
    let _cellParents = [];

    /**
     * The row in which the current cell is.
     *
     * @type {Object}
     */
    let _cellRow = {};

    /**
     * The parent of the row in which the current cell is, i.e The grand-parent of the current cell.
     *
     * @type {Object}
     */
    let _cellRowParent = {};

    /**
     * The full list of CSS classes of the cell.
     *
     * @type {Array}
     */
    const _classes = [];

    /**
     * The initial height of the cell when it's sticky.
     *
     * @type {number}
     */
    let _stickyCellInitialHeight;

    /**
     * Indicates if an HTML widget is in edition in the cell.
     *
     * @type {boolean}
     */
    let _isEditingHtml = false;

    /**
     * The cell placeholder if sticky.
     *
     * @type {Element}
     */
    let _placeholderCell;

    /**
     * The position of the new widget(s).
     * Possible values are "top" or "bottom".
     *
     * @type {string}
     */
    let _widgetPosition;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The id of the widget picker dialog.
     *
     * @type {string}
     * @readonly
     */
    vm.DIALOG_ID = 'cell-widgets-';

    /**
     * Contains the visibility status of each widget in the cell.
     * The visibility is indexed by the widget's UUID.
     */
    vm.isHidden = {};

    /**
     * The values of the filters.
     *
     * @type {Object}
     */
    vm.searchFilter = {
        closed: true,
        sortAlpha: false,
        widget: '',
    };

    /**
     * Active tab of the widget picker.
     */
    vm.widgetPickerActiveTab = 0;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.Content = Content;
    vm.Features = Features;
    vm.Layout = Layout;
    vm.Translation = Translation;
    vm.UserAccess = UserAccess;
    vm.Widget = Widget;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the current cell has only rows inside it.
     * In this case, display placeholders to avoid errors.
     *
     * @return {boolean} If the current cell has only rows inside it.
     */
    function _cellHasOnlyRows() {
        if (angular.isUndefinedOrEmpty(vm.cell.components)) {
            return false;
        }

        for (let i = 0, len = vm.cell.components.length; i < len; i++) {
            if (vm.cell.components[i].type !== 'row') {
                return false;
            }
        }

        if (Content.getAction() !== 'get' && Content.getViewMode() === 'default') {
            return true;
        }

        return false;
    }

    /**
     * When a key is pressed, if this key is not a special key (ctrl, shift, command, ...) and not the '?' key
     * (that is used for shortcuts cheatsheet), then focus the widget selection filter field and open it.
     *
     * @param {Event} evt The keypress event.
     */
    function _onKeyPress(evt) {
        if (
            (evt.target.id === 'widget-search' ||
                (evt.target.nodeName.toLowerCase() !== 'input' && evt.target.nodeName.toLowerCase() !== 'textarea')) &&
            evt.keyCode !== _INTERROGATION_MARK_KEY
        ) {
            vm.searchFilter.closed = false;
            angular.element('#widget-search').focus();
        }
    }

    /**
     * Resize cell on mouse move.
     *
     * @param {Object} evt The mouse move event.
     */
    function _onMouseMove(evt) {
        const row = $element.parent('.component-row__grid');

        // Mouse position in row (from 0 to row width).
        const mousePosition = evt.pageX - row.offset().left;

        // Cell position relative to row.
        const cellPosition = row.offset().left - $element.offset().left;

        // Size of a cell inside the row.
        const cellWidth = Math.round(row.outerWidth() / _GRID_SIZE);

        // Check total ratio for cells before active cell.
        const cellPositionRatio = Math.round(cellPosition / cellWidth);

        // Get active cell ratio.
        const cellRatio = Math.round(mousePosition / cellWidth + cellPositionRatio);

        // Get the next cell ratio.
        const nextCellRatio = get(vm.nextCell, 'width', 0) + (vm.cell.width - cellRatio);

        if (cellRatio >= _CELL_RATIO_TRIGGER && nextCellRatio >= _CELL_RATIO_TRIGGER) {
            $scope.$apply(function applyNextCellRation() {
                vm.cell.width = cellRatio;
                set(vm.nextCell, 'width', nextCellRatio);
            });
        }

        $scope.$broadcast('cell-resize');
    }

    /**
     * Stop cell resize when mouse button is released.
     */
    function _onMouseUp() {
        $element.removeClass('component-cell--is-resizing');

        $document.off('mousemove', _onMouseMove);
        $document.off('mouseup', _onMouseUp);
    }

    /**
     * Called on window resize.
     */
    function _onWindowResize() {
        if (angular.isUndefinedOrEmpty(_placeholderCell)) {
            return;
        }

        $element.css({
            left: _placeholderCell.offset().left,
            width: _placeholderCell.outerWidth(),
        });
    }

    /**
     * When the window is scrolled.
     */
    function _onWindowScroll() {
        if (!vm.cell.properties.sticky || Content.getAction() !== 'get') {
            return;
        }

        // Window informations.
        const windowHeight = angular.element($window).outerHeight();

        // Cell component informations.
        const cellElement = $element.find('.component-cell__components');
        const cellComponentsHeight = cellElement.outerHeight();
        const cellComponentsPosition = cellElement.position();
        // Add cell components bottom position.
        angular.extend(cellComponentsPosition, {
            bottom: cellComponentsPosition.top - cellComponentsHeight,
        });

        // Set stickyCellInitialHeight.
        _stickyCellInitialHeight = _stickyCellInitialHeight || cellComponentsHeight;

        // Cell dimensions.
        const cellWidth = $element.outerWidth();
        const cellHeight = $element.outerHeight();
        // Other variables.
        const scrollOffset = ConfigTheme.HEADER_FRONT_OFFSET - cellComponentsPosition.top;
        const isStickyCellHigherThanWindow = _stickyCellInitialHeight >= windowHeight - scrollOffset;
        let cellOffset;

        // Default CSS values.
        const bottomValue = isStickyCellHigherThanWindow
            ? parseInt($element.css('padding-bottom'), 10) * -1 - _STICKY_CELL_PADDING_BOTTOM_OFFSET
            : 'initial';

        // Footer information.
        const footerPosition = angular.element('.footer').get(0).getBoundingClientRect();
        const isFooterVisible = footerPosition.top + bottomValue < windowHeight;

        if (angular.isUndefinedOrEmpty(_placeholderCell)) {
            cellOffset = $element.offset();
        } else {
            cellOffset = _placeholderCell.offset();
        }

        if (angular.element($window).scrollTop() + scrollOffset > cellOffset.top && Layout.breakpoint === 'desk') {
            if (angular.isUndefinedOrEmpty(_placeholderCell) && cellWidth > 0) {
                // Create a clone placeholder, of the same kind(tag) as element.
                _placeholderCell = $element.clone();

                // Replace inner html with nothing to avoid to collapse ids.
                _placeholderCell[0].innerHTML = '';
                _placeholderCell.insertBefore($element).css({
                    height: cellHeight,
                    visibility: 'hidden',
                });

                // Make origin element sticky.
                $element.css({
                    left: cellOffset.left,
                    overflow: 'hidden',
                    position: 'fixed',
                    top: `${scrollOffset}px`,
                    width: `${cellWidth}px`,
                    zIndex: _DEFAULT_Z_INDEX,
                });

                if (isStickyCellHigherThanWindow) {
                    $element.css({
                        bottom: bottomValue,
                    });

                    // Reset top rule only when we need to avoid reset scroll.
                    if (parseInt($element.css('top'), 10) !== scrollOffset) {
                        $element.css({
                            top: scrollOffset,
                        });
                    }
                }

                $element.addClass('component-cell--is-sticky').appendTo('body');
            } else {
                if (angular.isDefinedAndFilled(_placeholderCell)) {
                    $element.css({
                        left: _placeholderCell.offset().left,
                        width: _placeholderCell.outerWidth(),
                    });
                }

                if (isStickyCellHigherThanWindow && !isFooterVisible) {
                    $element.css({
                        bottom: bottomValue,
                    });

                    // Reset top rule only when we need to avoid reset scroll.
                    if (parseInt($element.css('top'), 10) !== scrollOffset) {
                        $element.css({
                            top: scrollOffset,
                        });
                    }
                } else if (isFooterVisible && footerPosition.top < scrollOffset + _stickyCellInitialHeight) {
                    $element.css({
                        bottom: windowHeight - footerPosition.top - bottomValue,
                        top: 'initial',
                    });
                } else {
                    // Reset top rule only when we need to avoid reset scroll.
                    if (parseInt($element.css('top'), 10) !== scrollOffset) {
                        $element.css({
                            top: scrollOffset,
                        });
                    }

                    // Reset bottom rule only when we need to avoid reset scroll.
                    if (parseInt($element.css('bottom'), 10) !== bottomValue) {
                        $element.css({
                            bottom: bottomValue,
                        });
                    }
                }
            }
        } else if (angular.isDefinedAndFilled(_placeholderCell)) {
            // Replace placeholder with sticky element and remove sticky css.
            _placeholderCell.replaceWith($element);

            $element.removeAttr('style').removeClass('component-cell--is-sticky');

            _placeholderCell = undefined;
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a new cell.
     *
     * @param {string} position The position of the new cell in the row.
     */
    function addCell(position) {
        let index = findIndex(_cellRow.cells, { uuid: vm.cell.uuid });

        if (position === 'right') {
            index++;
        }

        // eslint-disable-next-line no-magic-numbers
        if (_cellRow.cells.length <= _GRID_SIZE / 4) {
            const cellToAdd = {
                components: [],
                type: 'cell',
                uuid: generateUUID(),
                // eslint-disable-next-line no-magic-numbers
                width: _GRID_SIZE / 4,
            };

            _cellRow.cells.splice(index, 0, cellToAdd);

            angular.forEach(_cellRow.cells, function forEachCells(existingCell) {
                existingCell.width = _GRID_SIZE / _cellRow.cells.length;
            });

            $scope.$broadcast('cell-add');
        } else {
            LxNotificationService.error(Translation.translate('CELL_ERROR_MAX_CELLS'));
        }
    }

    /**
     * Add the first available widget on the list.
     * This is used when the <Enter> key is pressed in the search field.
     */
    function addFirstWidget() {
        // Here we check if we are displaying the Marketplace extensions tab.
        // We currently have no better way to know this.
        const widgetList = vm.widgetPickerActiveTab === 2 ? vm.Widget.enabledExtensionWidgets : vm.Widget.availableWidgets;
        let widgets = $filter('searchWidget')(widgetList, vm.searchFilter.widget);

        if (vm.widgetPickerActiveTab !== 2) {
            widgets = $filter('sortLatin')(widgets, 'type', 'WIDGET_TYPE_', vm.searchFilter.sortAlpha);
        }

        if (angular.isDefinedAndFilled(widgets)) {
            vm.toggleWidget(widgets[0], true);
        }
    }

    /**
     * Add a new row.
     *
     * @param {string} [position='top'] The position of the new row.
     *                                  Possible values are "top" or "bottom".
     * @param {string} [loc='in']       The location of the new row.
     *                                  Possible values are "in" (inside the current row) or "out" (outside of the
     *                                  current row).
     */
    function addRow(position, loc) {
        let index;
        let rowToAdd;

        position = position || 'top';
        position = position.toLowerCase();

        loc = loc || 'in';
        loc = loc.toLowerCase();

        const currentContent = Content.getCurrent() || {};

        const isSpace = Community.isSpace(currentContent);

        switch (loc) {
            case 'in':
            case 'inside':
                rowToAdd = {
                    cells: [
                        {
                            components: [],
                            type: 'cell',
                            uuid: generateUUID(),
                            // eslint-disable-next-line no-magic-numbers
                            width: _GRID_SIZE / 2,
                        },
                        {
                            components: [],
                            type: 'cell',
                            uuid: generateUUID(),
                            // eslint-disable-next-line no-magic-numbers
                            width: _GRID_SIZE / 2,
                        },
                    ],
                    type: 'row',
                    uuid: generateUUID(),
                };

                switch (position) {
                    case 'top':
                    case 'over':
                        vm.cell.components.splice(0, 0, rowToAdd);

                        break;

                    case 'bottom':
                    case 'under':
                        vm.cell.components.push(rowToAdd);

                        break;

                    default:
                        break;
                }

                break;

            case 'out':
            case 'outside':
                rowToAdd = {
                    cells: [
                        {
                            components: [],
                            type: 'cell',
                            uuid: generateUUID(),
                            width: _GRID_SIZE,
                        },
                    ],
                    type: 'row',
                    uuid: generateUUID(),
                };

                if (angular.isDefinedAndFilled(_cellRowParent)) {
                    index = findIndex(_cellRowParent.components, { uuid: _cellRow.uuid });
                } else {
                    index = findIndex(currentContent.template.components, { uuid: _cellRow.uuid });
                }

                if (position === 'bottom') {
                    index++;
                }

                if (angular.isUndefined(_cellRowParent) && isSpace) {
                    rowToAdd.properties = { sizeVariant: 'compact' };
                }

                if (angular.isDefined(_cellRowParent)) {
                    _cellRowParent.components.splice(index, 0, rowToAdd);
                } else {
                    currentContent.template.components.splice(index, 0, rowToAdd);
                }

                break;

            default:
                break;
        }
    }

    /**
     * Add the selected widget(s) to the cell.
     */
    function addSelectedWidgets() {
        let index = 0;
        let firstWidgetSelected;
        let widgetToAdd;
        const widgetsToAdd = [];

        angular.forEach(Widget.availableWidgets, (availableWidget) => {
            if (!availableWidget.selected) {
                return;
            }

            widgetToAdd = {
                isOverride: Boolean(availableWidget.isOverride),
                type: 'widget',
                uuid: generateUUID(),
                widgetType: availableWidget.type,
            };

            if (availableWidget.type === 'question') {
                widgetToAdd.properties = {
                    isStandAlone: true,
                };
            }

            widgetsToAdd.push(widgetToAdd);
            availableWidget.selected = false;
        });

        angular.forEach(Widget.getGlobalWidgets(), (globalWidget) => {
            if (globalWidget.selected) {
                delete globalWidget.selected;

                widgetToAdd = angular.fastCopy(globalWidget);
                widgetToAdd.uuid = generateUUID();

                widgetsToAdd.push(widgetToAdd);
            }
        });

        angular.forEach(Widget.enabledExtensionWidgets, (remoteWidget) => {
            if (!remoteWidget.selected) {
                return;
            }

            delete remoteWidget.selected;

            widgetsToAdd.push({
                properties: {
                    remoteExtension: { id: remoteWidget.id },
                },
                type: 'widget',
                uuid: generateUUID(),
                widgetType: 'remote',
            });
        });

        angular.forEach(widgetsToAdd, (widget) => {
            switch (_widgetPosition) {
                case 'top':
                case 'over':
                    vm.cell.components.splice(0, 0, widget);

                    break;

                case 'bottom':
                case 'under':
                    vm.cell.components.push(widget);

                    break;

                default:
                    break;
            }

            if (index === 0) {
                firstWidgetSelected = widget;
            }
            index++;
        });

        _widgetPosition = undefined;

        LxDialogService.close(vm.DIALOG_ID);

        if (angular.isDefinedAndFilled(firstWidgetSelected)) {
            const row = get(_cellParents, 0);
            if (angular.isDefinedAndFilled(row)) {
                Row.setCurrent(row);
                Row.isFocused = true;
                Row.isActive = false;
            }

            Cell.setCurrent(vm.cell);
            Cell.focus();
            Cell.deactivate();

            Widget.setCurrent(firstWidgetSelected);
            Widget.isFocused = false;
            Widget.isActive = true;
        }
    }

    /**
     * When double-clicking on an empty cell, display the widget selector.
     */
    function addWidgetFromEmptyCell() {
        if (angular.isUndefinedOrEmpty(vm.cell.components)) {
            vm.displayAvailableWidgets('top');
        }
    }

    /**
     * Check if the user can display layout related actions.
     *
     * @return {boolean} If the user can display layout related actions.
     */
    function canDisplayLayoutActions() {
        const currentContent = Content.getCurrent();

        return (
            !currentContent.template.fixedLayout ||
            currentContent.type === 'template' ||
            (!Content.isDesignerInNewSimpleMode() &&
                UserAccess.canManageInstanceSettings())
        );
    }

    /**
     * Delete a global widget.
     *
     * @param {Object} globalWidget The global widget to delete.
     */
    function deleteGlobalWidget(globalWidget) {
        LxNotificationService.confirm(
            Translation.translate('CELL_DELETE_GLOBAL_WIDGET_CONFIRM'),
            Translation.translate('CELL_DELETE_GLOBAL_WIDGET_CONFIRM_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onDeleteGlobalWidgetConfirm(answer) {
                if (!answer) {
                    return;
                }

                const globalWidgets = Widget.getGlobalWidgets();

                Widget.del(
                    globalWidget.id,
                    function onGlobalWidgetDeleteSuccess() {
                        const index = findIndex(globalWidgets, {
                            id: globalWidget.id,
                        });
                        if (index > -1) {
                            globalWidgets.splice(index, 1);

                            // Remove the global widget properties from current content.
                            Content._fixGlobalWidgets(Content.getCurrent());
                        }

                        LxNotificationService.success(Translation.translate('CELL_DELETE_GLOBAL_WIDGET_SUCCESS'));
                    },
                    Utils.displayServerError,
                );
            },
        );
    }

    /**
     * Display the widget selector.
     *
     * @param {string} [wantedWidgetPosition='top'] The position we want to add the widget.
     *                                              Possible values are "top" or "bottom".
     */
    function displayAvailableWidgets(wantedWidgetPosition) {
        _widgetPosition = wantedWidgetPosition || 'top';
        _widgetPosition = _widgetPosition.toLowerCase();

        Utils.waitForAndExecute(`#${vm.DIALOG_ID}`);
    }

    /**
     * When the dragging end, cleanup the content styles.
     */
    function dragEnd() {
        Widget.isDragging = false;

        angular.element('.content').removeClass('content--is-dragging');
        if (Content.getAction() === 'get') {
            ContentTemplate.saveUserTemplate(Content.getCurrent(), User.getConnected());
        }

        if (angular.isUndefinedOrEmpty(Widget.getCurrent())) {
            vm.openWidgetSettings(undefined, undefined, vm.dragged);
            vm.dragged = undefined;
        }
    }

    /**
     * When the dragging start, set content styles.
     *
     * @param {Object} component The current dragged widget.
     */
    function dragStart(component) {
        Row.resetRow();
        Cell.resetCell();
        Widget.resetWidget();
        vm.dragged = component;
        Widget.isDragging = component.uuid;

        angular.element('.content').addClass('content--is-dragging');
    }

    /**
     * Get the cell CSS class.
     *
     * @return {Array} The cell CSS class.
     */
    function getCellClass() {
        Utils.empty(_classes);

        _classes.push(`component-cell--w-${vm.cell.width}`);

        const properties = get(vm.cell, 'properties', {});

        if (properties.plain) {
            _classes.push('component-cell--is-plain');
        }

        if (vm.cell.width === _GRID_SIZE) {
            _classes.push('component-cell--is-full');
        }

        if (angular.isUndefinedOrEmpty(vm.cell.components) || !angular.isArray(vm.cell.components)) {
            _classes.push('component-cell--is-empty');
        }

        const isCurrentCell = get(Cell.getCurrent(), 'uuid') === vm.cell.uuid;

        if (isCurrentCell && Cell.isFocused) {
            _classes.push('component-cell--is-focused');
        }

        if (isCurrentCell && Cell.isActive) {
            _classes.push('component-cell--is-active');
        }

        if (_cellHasOnlyRows()) {
            _classes.push('component-cell--has-only-rows');
        }

        if (angular.isDefinedAndFilled(properties.class)) {
            Array.prototype.push.apply(_classes, Utils.getCustomClass(properties.class, 'component-cell--'));
        }

        return _classes;
    }

    /**
     * Returns the cell components CSS style.
     *
     * @return {Object} The cell component CSS styles.
     */
    function getCellComponentsStyle() {
        let { properties } = vm.cell;
        properties = properties || {};
        properties.style = properties.style || {};

        const style = Style.adjustSpacings(properties.style.components);

        if (
            vm.cell.properties.plain &&
            angular.isUndefinedOrEmpty(style.shadowElevation) &&
            angular.isDefinedAndFilled(style.shadowOpacity)
        ) {
            style.defaultShadowElevation = _DEFAULT_SHADOW_ELEVATION;
        } else {
            delete style.defaultShadowElevation;
        }

        return Style.adjustShadow(Media.adjustBackgroundImage(assign({}, style, properties.style.components)));
    }

    /**
     * Get the icon corresponding to the widget type.
     *
     * @param  {Object} widget The widget we want the icon of.
     * @return {string} The widget icon.
     */
    function getWidgetIcon(widget) {
        const widgetMatch = lodashFind(Widget.availableWidgets, {
            type: widget.widgetType,
        });

        return get(widgetMatch, 'icon');
    }

    /**
     * Check if a component has the "full height" attribute.
     *
     * @param  {Object}  component The component to check.
     * @return {boolean} If the component has the "full height" attribute or not.
     */
    function isComponentFullHeight(component) {
        if (get(component, 'type') !== 'widget') {
            return false;
        }

        return !vm.isHidden[component.uuid] && get(component, 'properties.fullHeight', false);
    }

    /**
     * Check if the widget's drag and drop is enabled for a component.
     *
     * @param  {Object}  component The component to check.
     * @return {boolean} If widget's drag and drop is enabled or not.
     */
    function isWidgetDragEnabled(component) {
        return (
            ((Content.getAction() !== 'get' && Content.getViewMode() === 'default') ||
                (Content.getAction() === 'get' &&
                    Content.getCurrent().isCustomizableLayout &&
                    !Layout.isCustomizationLocked &&
                    !vm.cell.properties.customizationDisabled &&
                    !vm.customizationDisabled)) &&
            get(component, 'type') === 'widget' &&
            !Widget.isDragDisabled()
        );
    }

    /**
     * Check if the widget is loadable on the page.
     *
     * @param  {Object}  component The component to check.
     * @return {boolean} If widget can be loaded.
     */
    function isWidgetLoadable(component) {
        const contentAction = Content.getAction();
        let currentContent = Content.getCurrent();
        currentContent = Content.getCurrent() || {};
        const lazyForCurrentContent =
            currentContent &&
            currentContent.properties &&
            currentContent.properties.class &&
            currentContent.properties.class.indexOf('lazy-loading') > -1;

        let displayWidget = true;

        // The widget should be lazy loaded only if it has a CSS class 'lazy-loading' and the page has a CSS class 'lazy-loading'.
        // After some time, Content.displayAllWidgets is set to true and all remaining widgets should be loaded.
        if (
            lazyForCurrentContent &&
            contentAction === 'get' &&
            !vm.Content.displayAllWidgets &&
            component.properties &&
            component.properties.class &&
            component.properties.class.indexOf('lazy-loading') > -1
        ) {
            displayWidget = false;
        }
        return displayWidget;
    }

    /**
     * Move a component from it's parent to where it has been dropped.
     *
     * @param {number} index The index of the component to delete from parent.
     */
    function moveComponent(index) {
        if (!_isEditingHtml) {
            vm.cell.components.splice(index, 1);
        }
    }

    /**
     * Open cell settings.
     */
    function openCellSettings() {
        const row = get(_cellParents, 0);
        if (angular.isDefinedAndFilled(row)) {
            Row.setCurrent(row);
            Row.isFocused = true;
            Row.isActive = false;
        }
        const currentCell = Cell.getCurrent();
        Cell.setCurrent(vm.cell);
        Cell.blur();

        if (currentCell === vm.cell) {
            Cell.activate();
        } else {
            Cell.isActive = false;
            $timeout(function timedOut() {
                Cell.activate();
            });
        }

        if (Utils.hasChild(Cell.getCurrent(), Widget.getCurrent())) {
            Widget.isFocused = true;
            Widget.isActive = false;
        } else {
            Widget.resetWidget();
        }
    }

    /**
     * Open widget settings.
     *
     * @param  {Object} evt   The click event.
     * @param  {number} index The index of the widget we want to open the settings.
     * @param  {Object} item  The widget we want to open the settings.
     * @return {Object} The widget we are editing.
     */
    function openWidgetSettings(evt, index, item) {
        if (get(item, 'type') !== 'widget') {
            return undefined;
        }

        const row = get(_cellParents, 0);
        if (angular.isDefinedAndFilled(row)) {
            Row.setCurrent(row);
            Row.isFocused = true;
            Row.isActive = false;
        }

        Cell.setCurrent(vm.cell);
        Cell.focus();
        Cell.deactivate();

        Widget.setCurrent(item);
        Widget.isFocused = false;
        Widget.isActive = true;

        return item;
    }

    /**
     * Initiate the resizing of the cell.
     *
     * @param {Object} evt The click event.
     */
    function resizeCell(evt) {
        evt.preventDefault();

        $element.addClass('component-cell--is-resizing');

        $document.on('mousemove', _onMouseMove);
        $document.on('mouseup', _onMouseUp);
    }

    /**
     * Toggle widget selection.
     *
     * @param {Object}  widget The widget to toggle.
     * @param {boolean} add    Indicates if we want to directly add this widget.
     */
    function toggleWidget(widget, add = false) {
        if (
            widget.isDisabled &&
            widget.isDisabled() &&
            (widget.type === InitialSettings.WIDGET_TYPES.CONTRIBUTION ||
                widget.type === InitialSettings.WIDGET_TYPES.SUMMARY ||
                widget.type === InitialSettings.WIDGET_TYPES.COMMUNITY_NAVIGATION)
        )
            return;

        widget.selected = !widget.selected || add;

        if (add) {
            vm.addSelectedWidgets();
        }
    }

    /////////////////////////////

    vm.addCell = addCell;
    vm.addFirstWidget = addFirstWidget;
    vm.addRow = addRow;
    vm.addSelectedWidgets = addSelectedWidgets;
    vm.addWidgetFromEmptyCell = addWidgetFromEmptyCell;
    vm.canDisplayLayoutActions = canDisplayLayoutActions;
    vm.deleteGlobalWidget = deleteGlobalWidget;
    vm.displayAvailableWidgets = displayAvailableWidgets;
    vm.dragEnd = dragEnd;
    vm.dragStart = dragStart;
    vm.getCellClass = getCellClass;
    vm.getCellComponentsStyle = getCellComponentsStyle;
    vm.getWidgetIcon = getWidgetIcon;
    vm.isComponentFullHeight = isComponentFullHeight;
    vm.isWidgetDragEnabled = isWidgetDragEnabled;
    vm.isWidgetLoadable = isWidgetLoadable;
    vm.moveComponent = moveComponent;
    vm.openCellSettings = openCellSettings;
    vm.openWidgetSettings = openWidgetSettings;
    vm.resizeCell = resizeCell;
    vm.toggleWidget = toggleWidget;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When we enter/leave the edition of an HTML widget, set a flag to the cell.
     *
     * @param {Event}   evt           The HTML edition event.
     * @param {boolean} isEditingHtml Indicates if we are editing an HTML widget or not.
     */
    $scope.$on('editing-html', function onEditingHtml(evt, isEditingHtml) {
        _isEditingHtml = isEditingHtml;
    });

    /**
     * When the widget selection dialog is closed, reset all filters, close the filter field and unbind the keypress
     * listener.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('lx-dialog__close-end', function onWidgetSelectDialogClose(evt, dialogId) {
        if (vm.DIALOG_ID === dialogId) {
            vm.searchFilter.closed = true;
            vm.searchFilter.widget = '';

            angular.element($window).off('keypress', _onKeyPress);
        }
    });

    /**
     * When the widget selection dialog is opened, bind a keypress listener that automatically open and focus the
     * filter field when the user input some text.
     *
     * @param {Event}  evt      The dialog open event.
     * @param {string} dialogId The id of the dialog that opens.
     */
    $scope.$on('lx-dialog__open-start', function onWidgetSelectDialogOpen(evt, dialogId) {
        if (vm.DIALOG_ID === dialogId) {
            angular.element($window).on('keypress', _onKeyPress);
        }
    });

    /**
     * When a child widget is resized, recalculate all the sticky cell css properties accordingly.
     */
    $scope.$on('widget-resized', () => {
        if (!get(vm.cell, 'properties.sticky', false) || Utils.isDesignerMode()) {
            return;
        }

        _stickyCellInitialHeight = undefined;

        _onWindowScroll();
    });

    /**
     * Remove cloned element on destroy.
     */
    $scope.$on('$destroy', function onCellDestroy() {
        if (!get(vm.cell, 'properties.sticky', false) && Content.getAction() !== 'get') {
            return;
        }

        angular.element($window).unbind('scroll', _onWindowScroll);
        angular.element($window).unbind('resize', _onWindowResize);

        if (angular.isDefinedAndFilled(_placeholderCell)) {
            $element.remove();

            _placeholderCell.remove();
            _placeholderCell = undefined;
        }
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.DIALOG_ID += vm.cell.uuid;

        const properties = get(vm.cell, 'properties', {});
        // Filtering decommissioned widget Chat
        // TO BE DELETED IN MONO-1055
        vm.cell.components = vm.cell.components.filter(component => component.widgetType !== WIDGET_TYPES.CHAT);

        if (get(properties, 'sticky')) {
            // Force cell plain when the cell is sticky to display a possible scrollbar.
            properties.plain = true;

            if (Content.getAction() === 'get') {
                // Make the cell sticky if window scroll offset up to cell offset and if cell is sticky.
                angular.element($window).bind('scroll', _onWindowScroll);

                // Update the sticky cell offsets on window resize.
                angular.element($window).bind('resize', _onWindowResize);
            }
        }

        // Migrate styles from version 1 to 3.
        Utils.copyProperties(
            ['backgroundColor', 'backgroundImage', 'backgroundPosition', 'backgroundSize'],
            'style',
            'style.components',
            properties,
            true,
        );

        Utils.copyProperties(
            [
                'marginBottom',
                'marginLeft',
                'marginRight',
                'marginTop',
                'paddingBottom',
                'paddingLeft',
                'paddingRight',
                'paddingTop',
            ],
            '',
            'style.components',
            properties,
            true,
        );

        // Backward compatibility : Delete background color frommain style as we move this property in components.
        if (angular.isDefinedAndFilled(get(properties.style, 'main.backgroundColor'))) {
            delete properties.style.main.backgroundColor;
        }

        vm.cell.properties = properties;
        _cellParents = Cell.getParents(Content.getCurrent().template, vm.cell);
        _cellRow = _cellParents[_cellParents.length - 1];
        // eslint-disable-next-line no-magic-numbers
        _cellRowParent = _cellParents[_cellParents.length - 2];

        angular.forEach(vm.cell.components, function forEachComponents(component) {
            vm.isHidden[component.uuid] = false;
        });
    }

    init();
}

/////////////////////////////

function LsCellDirective(Content, Features, RecursionHelper) {
    'ngInject';

    function compile(el) {
        return RecursionHelper.compile(el, function recursiveCompile(scope, recursiveElement, attrs, ctrl) {
            recursiveElement.on('mouseenter', function onRecursiveElementMouseEnter() {
                if (
                    Content.getAction() !== 'get' &&
                    (
                        Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER') ||
                        Content.isCurrentDesignerMode('NEW_SIMPLE_EXPERT_MODE')
                    )
                ) {
                    recursiveElement.parents('.component-cell').addClass('component-cell--hover-disabled');
                }
            });

            recursiveElement.on('mouseleave', function onRecursiveElementMouseLeave() {
                if (
                    Content.getAction() !== 'get' &&
                    (
                        Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER') ||
                        Content.isCurrentDesignerMode('NEW_SIMPLE_EXPERT_MODE')
                    )
                ) {
                    recursiveElement.parents('.component-cell').removeClass('component-cell--hover-disabled');
                }
            });

            recursiveElement.on('click', function onRecursiveElementClick(evt) {
                if (
                    Content.getAction() !== 'get' &&
                    (
                        Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER') ||
                        Content.isCurrentDesignerMode('NEW_SIMPLE_EXPERT_MODE')
                    )
                ) {
                    if (
                        !angular.element(evt.target).hasClass('component-cell__component') &&
                        !angular.element(evt.target.parentNode).hasClass('component-cell')
                    ) {
                        return;
                    }

                    evt.stopPropagation();

                    scope.$apply(function applyCellSettingsOpening() {
                        ctrl.openCellSettings();
                    });
                }
            });

            scope.$on('$destroy', function onDestroy() {
                recursiveElement.off();
            });
        });
    }

    return {
        bindToController: true,
        compile,
        controller: CellController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            cell: '=lsCell',
            customizationDisabled: '=lsCustomizationDisabled',
            last: '=lsLast',
            nextCell: '=lsNextCell',
        },
        templateUrl: '/client/front-office/modules/content/modules/cell/views/cell.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsCell', LsCellDirective);

/////////////////////////////

export { CellController, LsCellDirective };
