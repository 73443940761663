import React from 'react';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { GLOBAL } from '@lumapps/translations';
import { saveCurrentUserSettings } from '@lumapps/user/api';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { actions } from '@lumapps/user/ducks/slice';
import { User } from '@lumapps/user/types';
import { getCookie } from '@lumapps/utils/cookies/cookie_utils';

import { HAS_ACCEPTED_COOKIES } from '../constants';
import { setAnalyticsCookies } from '../utils';

export interface UseGdprBannerOutput {
    isBannerHidden: boolean;
    isOptionsManagementDialogOpen: boolean;
    hasAcceptedCookies: boolean;
    hasUserClosedBanner: boolean;
    onManageOptions: () => void;
    setIsOptionsManagementDialogOpen: (isOpen: boolean) => void;
    onChooseCookiesOptions: (choice: boolean) => void;
    toggleAcceptCookies: (choice: boolean) => void;
    onCancel: () => void;
}

export const useGdprBanner = (): UseGdprBannerOutput => {
    const connectedUser = useSelector(connectedUserSelector);
    const { error } = useNotification();

    const userId = connectedUser?.id;

    const dispatch = useDispatch();

    const hasUserClosedBanner = React.useMemo(
        () => getCookie(userId ? `${HAS_ACCEPTED_COOKIES}.${userId}` : HAS_ACCEPTED_COOKIES) !== undefined,
        [userId],
    );

    const [isBannerHidden, setIsBannerHidden] = React.useState(hasUserClosedBanner);
    const [isOptionsManagementDialogOpen, setIsOptionsManagementDialogOpen] = React.useState(false);

    const initialHasAcceptedCookies = getCookie('hasAcceptedCookies') === 'true';

    const [hasAcceptedCookies, setHasAcceptedCookies] = React.useState(initialHasAcceptedCookies);

    /**
     * Save user settings and reload
     */
    const saveUserPreferences = React.useCallback(
        async (choice: boolean, user: User) => {
            try {
                await saveCurrentUserSettings(
                    {
                        settings: {
                            hasAcceptedCookies: choice,
                        },
                    },
                    user,
                );

                window.location.reload();
            } catch (e) {
                error({ translate: GLOBAL.GENERIC_ERROR });
            }
        },
        [error],
    );

    /**
     * Save that the user closed the GDPR banner in a cookie and if user is connected, save his choice.
     */
    const rememberUserChoice = React.useCallback(
        (choice: boolean, user?: User) => {
            setAnalyticsCookies(choice, userId);

            if (user && userId) {
                saveUserPreferences(choice, user);
                dispatch(actions.acceptCookies({ hasAccepted: choice }));
            }
            window.location.reload();
        },
        [userId, saveUserPreferences, dispatch],
    );

    /**
     * On click event, save the user has accepted or declined the use of cookies.
     */
    const onChooseCookiesOptions = React.useCallback(
        (hasAccepted: boolean) => {
            setHasAcceptedCookies(hasAccepted);
            rememberUserChoice(hasAccepted, connectedUser);
            setIsBannerHidden(true);
        },
        [rememberUserChoice, connectedUser],
    );

    /**
     * On click event, open the CookiesOptionsManagement dialog
     */
    const onManageOptions = React.useCallback(() => {
        setIsOptionsManagementDialogOpen(true);
    }, []);

    /**
     * Cancel dialog opnening and reset modifications
     */
    const onCancel = React.useCallback(() => {
        setHasAcceptedCookies(initialHasAcceptedCookies);
        setIsOptionsManagementDialogOpen(false);
    }, [initialHasAcceptedCookies]);

    return {
        onManageOptions,
        isBannerHidden,
        isOptionsManagementDialogOpen,
        setIsOptionsManagementDialogOpen,
        hasAcceptedCookies,
        onChooseCookiesOptions,
        toggleAcceptCookies: setHasAcceptedCookies,
        hasUserClosedBanner,
        onCancel,
    };
};
