import set from 'lodash/set';

/////////////////////////////

function WidgetTipController(
    $element,
    $injector,
    $sce,
    $scope,
    $state,
    $timeout,
    $window,
    Analytics,
    Community,
    ConfigTheme,
    Content,
    LxNotificationService,
    Translation,
    User,
    UserContent,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay before showing the app filter.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _APP_FILTER_SHOW_DELAY = 100;

    /**
     * The class selector of the html wrapper.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _HTML_PARENT_SELECTOR = '.widget-tip__content';

    /**
     * A DOM element that darkens the rest of the page when the HTML widget is in edit mode.
     *
     * @type {Element}
     */
    const _appFilter = angular.element('<div/>', {
        class: 'app-filter app-filter--no-transition',
    });

    /**
     * Prevents the multiple event listeners on the same element due to lazy digest cycle.
     *
     * @type {Boolean}
     */
    let _isListenerOnLinks = false;

    /**
     * The content of the tip.
     *
     * @type {Object}
     */
    let _tipContent,
        /**
         * The widget to display the tip.
         *
         * @type {Object}
         */
        _tipWidget;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the content is active.
     *
     * @type {boolean}
     */
    vm.activeContent = false;

    /**
     * Indicates if we are currently editing the content of the HTML widget.
     *
     * @type {boolean}
     */
    vm.editingContent = false;

    /**
     * Contains the options of the Froala editor for the Tip widget in edit mode.
     *
     * @type {Object}
     */
    vm.FROALA_OPTIONS = {};

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Translation = Translation;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Format the content of the Tip widget.
     */
    function _displayHtmlContent() {
        const translatedContent = vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content);
        const popinTranslatedContent = vm.parentCtrl.getWidgetTranslation(vm.widget.properties.popin);

        const htmlContent = Utils.initHtmlContent(
            vm.widget.uuid,
            translatedContent,
            _HTML_PARENT_SELECTOR,
            popinTranslatedContent,
            _isListenerOnLinks,
        );

        vm.htmlContent = htmlContent.mainContent;
        vm.popinContent = htmlContent.popinContent;
    }

    /**
     * Check if the tip is already deleted by the current user.
     *
     * @return {boolean} If the tip has been deleted.
     */
    function _isDeletedByUser() {
        if (!vm.widget.properties.isDeletable || angular.isUndefinedOrEmpty(_tipWidget)) {
            return false;
        }

        if (vm.widget.uuid === _tipWidget.uuid && !_tipWidget.active) {
            return !(
                angular.isDefined(vm.widget.properties.lastUuidEdit) &&
                (angular.isUndefined(_tipWidget.params) ||
                    (angular.isUndefined(_tipWidget.params.lastUuidEdit) ||
                        (angular.isDefined(_tipWidget.params.lastUuidEdit) &&
                            _tipWidget.params.lastUuidEdit !== vm.widget.properties.lastUuidEdit)))
            );
        }

        return false;
    }

    /**
     * Toggle drag and drop allowed for the HTML widget.
     * When it is in edit mode, disable drag'n'drop.
     */
    function _toggleDragAndDrop() {
        Widget.isDragDisabled(!Widget.isDragDisabled());
        $element.parents('.component-cell').toggleClass('component-cell--hover-disabled', Widget.isDragDisabled());
    }

    /**
     * When the Froala WYSIWYG editor closes, read the content to update the displayed content.
     */
    function _readContent() {
        /*
         * We have to use jQuery function to access `froalaEditor` element. Otherwise it will return
         * `undefined`, and nobody wants that.
         * See: https://github.com/froala/angular-froala/issues/134.
         */
        // eslint-disable-next-line angular/angularelement
        const froalaElement = $('.froala');
        if (froalaElement.froalaEditor('codeView.isActive')) {
            froalaElement.froalaEditor('codeView.toggle');
        }

        $timeout(function delayUpdateDesigner() {
            vm.editingContent = false;

            _displayHtmlContent();

            _appFilter.removeClass('app-filter--is-shown').remove();

            _toggleDragAndDrop();
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Disable the tip for the current user.
     */
    function disableWidgetForUser() {
        if (
            angular.isDefinedAndFilled(vm.widget.properties.lastUuidEdit) &&
            angular.isDefined(_tipWidget) &&
            _tipWidget.uuid === vm.widget.uuid
        ) {
            _tipWidget.params.lastUuidEdit = vm.widget.properties.lastUuidEdit;
        }

        const currentContent =
            $state.current.name === 'app.front.community' ? Community.getCurrent() : Content.getCurrent();

        if (angular.isUndefinedOrEmpty(currentContent.userContent)) {
            currentContent.userContent = {
                userContent: currentContent.id,
                values: {},
            };
        }

        if (angular.isUndefined(currentContent.userContent.values.widgets)) {
            currentContent.userContent.values.widgets = [];
        }

        if (angular.isUndefinedOrEmpty(_tipWidget)) {
            currentContent.userContent.values.widgets.push({
                active: false,
                params: {},
                uuid: vm.widget.uuid,
            });

            _tipWidget = UserContent.getUserContentWidget(currentContent, vm.widget.uuid);

            disableWidgetForUser();

            return;
        }

        _tipWidget.active = false;

        Analytics.handleTaggingMap('close-tip', 'tip', {
            isHomePage: angular.isDefined(currentContent) ? currentContent.isHomepage : false,
            item: vm.widget,
        });

        UserContent.save(
            {
                content: currentContent.id,
                user: User.getConnected().uid,
                values: currentContent.userContent.values,
            },
            function onUserSaveSuccess() {
                LxNotificationService.success(Translation.translate('FRONT.TIP.DELETED'));
            },
            function onUserSaveError(err) {
                if (angular.isDefined(_tipWidget) && !_tipWidget.active) {
                    _tipWidget.active = true;
                }

                Utils.displayServerError(err);
            },
        );
    }

    /**
     * Edit the content of the Tip widget.
     */
    function editContent() {
        const viewMode = Content.getViewMode();

        if (Content.getAction() !== 'get' && (viewMode === 'default' || viewMode === 'simple') && !vm.editingContent) {
            $element
                .find('.widget-content__transclude')
                .css('height', $element.find('.widget-content__transclude').outerHeight());

            _toggleDragAndDrop();

            vm.editingContent = true;

            if (viewMode === 'basic') {
                _appFilter.addClass('app-filter--is-disabled');
            }

            _appFilter.appendTo('.app-content').bind('click', function onAppContentClick(evt) {
                evt.stopPropagation();

                $scope.$apply(function applyReadContentOnClick() {
                    _readContent();
                });
            });

            $timeout(function delayShowFilter() {
                _appFilter.addClass('app-filter--is-shown');

                $element.find('.widget-content__transclude').removeAttr('style');
            }, _APP_FILTER_SHOW_DELAY);
        }
    }

    /**
     * Get the widget classes.
     *
     * @return {Array} The widget classes.
     */
    function getWidgetClass() {
        const widgetClass = [];

        vm.parentCtrl.getWidgetClass(widgetClass);

        widgetClass.push('widget-editable');

        if (vm.isWidgetEmpty()) {
            widgetClass.push('widget--is-empty');
        }

        if (vm.editingContent) {
            widgetClass.push('widget-tip--is-editing');
            widgetClass.push('widget-editable--is-editing');
        }

        return widgetClass;
    }

    /**
     * Init Froala.
     */
    function initFroala() {
        vm.widget.properties.content = vm.widget.properties.content || {};
        vm.widget.properties.content[vm.Translation.inputLanguage] =
            vm.widget.properties.content[vm.Translation.inputLanguage] || '';

        set(vm.FroalaService, 'widgetUuid', vm.widget.uuid);
    }

    /**
     * Check if the widget is empty.
     * This is used in the designer.
     *
     * @return {boolean} If the widget is empty or not.
     */
    function isWidgetEmpty() {
        return (
            !vm.editingContent &&
            (angular.isUndefined(vm.widget.properties.content) ||
                !vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content))
        );
    }

    /**
     * Check if the widget is hidden.
     * This is used in read mode.
     *
     * @return {boolean} If the widget is hidden or not.
     */
    function isWidgetHidden() {
        if (
            !vm.parentCtrl.designerMode() &&
            (_isDeletedByUser() ||
                angular.isUndefined(vm.widget.properties.content) ||
                !vm.parentCtrl.getWidgetTranslation(vm.widget.properties.content))
        ) {
            vm.parentCtrl.isHidden = true;
        } else {
            vm.parentCtrl.isHidden = false;
        }

        return vm.parentCtrl.isHidden;
    }

    /////////////////////////////

    vm.disableWidgetForUser = disableWidgetForUser;
    vm.editContent = editContent;
    vm.getWidgetClass = getWidgetClass;
    vm.initFroala = initFroala;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the input language changes, update the content of the HTML widget.
     */
    $scope.$on('inputLanguage', _displayHtmlContent);

    /**
     * When the links are listned, update the flag.
     */
    $scope.$on('listenerOnLinks', function whenLinksAreListened(widgetUUID) {
        if (widgetUUID === vm.widget.uuid) {
            _isListenerOnLinks = true;
        }
    });

    /////////////////////////////
    /**
     * Initialize the controller.
     */
    function init() {
        if (Utils.isDesignerMode()) {
            const FroalaService = $injector.get('FroalaService');
            vm.FroalaService = FroalaService;

            // Get Froala toolbar, in this widget the toolbar is customizable.
            const toolbarConfig = FroalaService.getHTMLToolbar();

            // Set Froala options.
            vm.FROALA_OPTIONS = FroalaService.getOptions(true, true, true, true, toolbarConfig);
        } else {
            vm.FroalaService = {
                openContent: Utils.openContent,
                openPopin: Utils.openPopin,
            };
        }

        _tipContent = $state.current.name === 'app.front.community' ? Community.getCurrent() : Content.getCurrent();

        _tipWidget = UserContent.getUserContentWidget(_tipContent, vm.widget.uuid);

        _displayHtmlContent();
    }

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;

        init();
    };
}

/////////////////////////////

/**
 * Display the widget Tip.
 * The widget Tip uses a wysiwyg to set a tip.
 *
 * @param {Object} widget The widget object, with properties.
 */

function WidgetTipDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetTipController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetTip', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-tip/views/widget-tip.html',
    };
}

/////////////////////////////

// eslint-disable-next-line angular/directive-name
angular.module('Widgets').directive('widgetTip', WidgetTipDirective);

/////////////////////////////

export { WidgetTipController, WidgetTipDirective };
