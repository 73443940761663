function DocumentFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/document/:methodKey`,
        {},
        {
            createFolder: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/document/folder/:methodKey`,
            },

            createUpload: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'get',
                },
                url: `${Config.APPLICATION_HOST}/_ah/api/lumsites/v1/document/uploadUrl/:methodKey`,
            },

            emptyTrash: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'empty',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/document/trash/:methodKey`,
            },

            get: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'get',
                },
            },

            getMulti: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'getMulti',
                },
            },

            getPermissions: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'getPermissions',
                },
            },

            list: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
            },

            search: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'search',
                },
            },

            preview: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'preview',
                },
            },

            restore: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'restore',
                },
            },

            trash: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'trash',
                },
            },

            update: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'update',
                },
            },
        },
    );
}

angular.module('Factories').factory('DocumentFactory', DocumentFactory);

export { DocumentFactory };
