import React from 'react';

import { canAccessBroadcast } from '@lumapps/broadcasts/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { removeParamFromUrl } from '@lumapps/router/utils';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import type { DistributeResourceDialogProps } from '../components/DistributeResourceDialog';
import { isDistributeContentEnabled } from '../ducks/selectors';
import type { DistributeResource } from '../types';

export interface UseDistributeDialog {
    /** The distribute dialog props */
    formDialogProps: DistributeResourceDialogProps;
    /** Open the distribute dialog */
    openFormDialog: () => void;
    /** Whether the contextual button should be displayed */
    isDistributeResourceEnabled: boolean;
    /** Whether the component is mounted */
    isMounted: boolean;
}

export interface UseDistributeDialogOptions {
    /** The resource to distribute */
    resource: DistributeResource;
}

/**
 * Opens dialog with distribute form in iframe
 */
export const useDistributeDialog = ({ resource }: UseDistributeDialogOptions): UseDistributeDialog => {
    const isDistributeActionEnabled = useSelector(isDistributeContentEnabled);
    const canUserAccessBroadcast = useSelector(canAccessBroadcast);

    const isDistributeResourceEnabled = isDistributeActionEnabled && Boolean(canUserAccessBroadcast);
    const [isMounted, setIsMounted] = React.useState(false);
    const [isFormDialogOpen, , closeFormDialog, openFormDialog] = useBooleanState(false);

    const distributeResourceDialogCreateProps: DistributeResourceDialogProps = {
        isOpen: isFormDialogOpen,
        onClose: () => {
            closeFormDialog();
            window.history.replaceState(null, '', removeParamFromUrl('distribute', window.location.href));
        },
        resource,
        onVisibilityChange: setIsMounted,
    };

    React.useEffect(() => {
        if (isFormDialogOpen) {
            setIsMounted(true);
        }
    }, [isFormDialogOpen]);

    return {
        formDialogProps: distributeResourceDialogCreateProps,
        openFormDialog,
        isDistributeResourceEnabled,
        isMounted,
    };
};
