import { combineReducers } from '@lumapps/redux';

import { reducer as wallReducer } from '../../wall/_ducks/wall_reducer';

import { reducer as tasksReducer } from '../../tasks/_ducks/tasks_reducer';

import { reducer as entitiesReducer } from './entities';

import { reducer as delveReducer } from '../../delve/_ducks/delve_reducer';

import { reducer as chartReducer } from '../../chart/_ducks/chart_reducer';

const rootReducer = combineReducers({
    delve: delveReducer,
    entities: entitiesReducer,
    tasks: tasksReducer,
    chart: chartReducer,
    wall: wallReducer,
});

export default rootReducer;
