(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetIframeDirective() {
        'ngInject';

        function WidgetIframeLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: 'WidgetIframeController',
            controllerAs: 'vm',
            link: WidgetIframeLink,
            replace: true,
            require: ['widgetIframe', '^widget'],
            restrict: 'E',
            scope: {
                embed: '<?',
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-iframe/views/widget-lazy-iframe.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetIframe', WidgetIframeDirective);
})();
