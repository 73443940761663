import { Editor, NodeEntry, Path, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { getSibling } from '@lumapps/wrex/slate/utils/getSibling';

import type { TableEditor, TRElement } from '../../types';
import { createTable } from '../../utils/createTable';
import { createTD } from '../../utils/createTD';
import { isTable } from '../../utils/isTable';
import { isTableCell } from '../../utils/isTableCell';
import { isTableRow } from '../../utils/isTableRow';

export const normalizeTableRow = ([node, path]: NodeEntry, editor: ReactEditor & TableEditor) => {
    if (!isTableRow(node)) {
        return false;
    }

    const nodeAsTableRow = node as TRElement;
    const rowPathRef = Editor.pathRef(editor, path);

    // A row should always be in a table
    if (rowPathRef.current && !isTable(Editor.parent(editor, rowPathRef.current)[0])) {
        Transforms.wrapNodes(editor, createTable({}, []), {
            at: rowPathRef.current,
        });
    }
    // A row accepts only cells
    if (rowPathRef.current && nodeAsTableRow.children.length === 0) {
        // if row empty, insert empty td
        Transforms.insertNodes(editor, createTD(), {
            at: [...rowPathRef.current, 0],
        });
    } else if (rowPathRef.current) {
        Editor.withoutNormalizing(editor, () => {
            nodeAsTableRow.children
                .map((child, i) => [child, Editor.pathRef(editor, [...(rowPathRef.current as Path), i])] as const)
                .forEach(([child, pathRef]) => {
                    if (pathRef.current && !isTableCell(child)) {
                        // Wrap every direct child in a td
                        const childPath = pathRef.current as Path;
                        const [previousSibling, previousSiblingPath] = getSibling(editor, childPath, 'before') || [];
                        if (previousSiblingPath && previousSibling && isTableCell(previousSibling)) {
                            // If previous sibling is a td, move the current node in this sibling td
                            Transforms.moveNodes(editor, {
                                at: childPath,
                                to: [...previousSiblingPath, previousSibling.children.length],
                            });
                        } else {
                            // If previous sibling is not a td, then wrap the current node into a td
                            Transforms.wrapNodes(editor, createTD([]), {
                                at: childPath,
                            });
                            pathRef.unref();
                        }
                    }
                });
        });
    }

    return false;
};
