import { fetchExtensions } from '@lumapps/marketplace/api';
import { ExtensionStatus } from '@lumapps/marketplace/constants';

/////////////////////////////

const TIMEOUT_INTERVAL = 100;

function ExtensionWidgetService($timeout, LumsitesBaseService, WidgetFactory) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(WidgetFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    service.initialized = false;
    service.initializing = false;
    service.extensions = [];

    /**
     * Retrieve only the extensions that has been enabled by admin.
     */
    async function getEnabledExtensions() {
        if (service.initialized) {
            return service.extensions;
        }
        service.initializing = true;

        const { data } = await fetchExtensions({
            maxResults: 100,
            categories: ['widget'],
            statuses: [ExtensionStatus.live],
        });

        service.extensions = data.items;
        service.initialized = true;
        service.initializing = false;

        return data.items;
    }

    function getEnabledExtension(id) {
        return new Promise(async (resolve) => {
            const enabledExtensions = await getEnabledExtensions();
            const getExtension = () => enabledExtensions.find((extension) => extension.id === id);

            const intervalRef = setInterval(async () => {
                if (service.initializing) {
                    return;
                }

                clearInterval(intervalRef);
                resolve(getExtension());
            }, TIMEOUT_INTERVAL);
        });
    }

    /////////////////////////////

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    service.getEnabledExtension = getEnabledExtension;
    service.getEnabledExtensions = getEnabledExtensions;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = function init(cb) {
        $timeout(async () => {
            await getEnabledExtensions();
            cb();
        });
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('ExtensionWidget', ExtensionWidgetService);

/////////////////////////////

export { ExtensionWidgetService };
