import React, { Fragment, RefObject } from 'react';

import uniqueId from 'lodash/uniqueId';

import { SearchField } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { List, ListSubheader, Size, ListDivider, ListItem } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import type { LanguageSelectorProps } from './LanguageSelector';

export interface LanguageSelectorListProps {
    /** The value of the search field */
    filterValue: string;
    /** Callback to change the value of the search field */
    setFilterValue(newValue: string): void;
    /** Ref to set on the search input */
    searchInputRef: RefObject<HTMLInputElement>;
    /** Languages to display in the list */
    languages: Array<{ key: string; label: string | null }>;
    /** Callback to define whether an item has been selected or not. */
    getShouldDisplayAsSelected(langKey: string): boolean;
    /** Callback to define whether to show a separator. */
    getShouldDisplaySeparator(index: number): boolean;
    /** Callback when a language is selected */
    onLanguageSelected(langKey: string): void;
    /** Whether the search field should be displayed */
    isSearchable?: LanguageSelectorProps['isSearchable'];
    /** Props to forward to the search input component */
    textFieldProps?: LanguageSelectorProps['textFieldProps'];
}

/**
 * List element of the LanguageSelector component
 * This component manages the display of the LanguageSelector options.
 * */
export const LanguageSelectorList: React.FC<LanguageSelectorListProps> = ({
    isSearchable,
    filterValue,
    setFilterValue,
    searchInputRef: inputRef,
    textFieldProps,
    languages,
    getShouldDisplayAsSelected: selectedValueSelector,
    getShouldDisplaySeparator,
    onLanguageSelected,
}) => {
    const { translateKey } = useTranslate();

    return (
        <List>
            {isSearchable ? (
                <>
                    <ListSubheader>
                        <SearchField
                            value={filterValue}
                            onSearch={setFilterValue}
                            onChange={setFilterValue}
                            textFieldProps={{
                                ...textFieldProps,
                                inputRef,
                            }}
                            label={translateKey(GLOBAL.SEARCH)}
                        />
                    </ListSubheader>
                    <ListDivider />
                </>
            ) : null}
            {languages.length > 0 ? (
                languages.map((lang, index) => (
                    <Fragment key={uniqueId('language')}>
                        <ListItem
                            key={lang.key}
                            isSelected={selectedValueSelector(lang.key)}
                            size={Size.tiny}
                            onItemSelected={() => onLanguageSelected(lang.key)}
                        >
                            {lang.label}
                        </ListItem>
                        {getShouldDisplaySeparator(index) && <ListDivider />}
                    </Fragment>
                ))
            ) : (
                <NoResultsState />
            )}
        </List>
    );
};
