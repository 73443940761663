/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { learningApi } from '@lumapps/learning/constants';

import { CatalogData, CategoryData } from '../types';

const catalogApi = () =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL: learningApi.URL,
    });
const paths = {
    catalog: 'catalog/v2/categories/all',
    categories: 'catalog/v2/categories/titles',
};

export async function getCatalog(language: string): Promise<CatalogData> {
    const { data } = await catalogApi().get<CatalogData>(paths.catalog, { params: { language } });

    return data;
}

export async function getCategories(language: string): Promise<CategoryData[]> {
    const { data } = await catalogApi().get<CategoryData[]>(paths.categories, { params: { language } });

    return data;
}

export const learningCatalogQueryKeys = (locale: string) => ['learning', 'catalog', locale];
