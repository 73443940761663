import { useCallback, useEffect, useState } from 'react';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useRouter } from '@lumapps/router';
import { GLOBAL } from '@lumapps/translations';
import { FetchCallbackParams, useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { cancelGetSegment, getSegment, GetSegmentRequest, getSegmentWithCache } from '../api';
import { segmentBaseRoute } from '../routes';
import { SegmentWithContext } from '../types';

interface UseSegmentProps {
    fetchOnMount?: boolean;
    segmentId?: string;
    withCache?: boolean;
}
export const useSegment = ({ fetchOnMount = false, segmentId, withCache = false }: UseSegmentProps = {}) => {
    const [segment, setSegment] = useState<SegmentWithContext | undefined>(undefined);
    const { fetch, status, cancelFetch } = useFetchWithStatus({
        onFetch: withCache ? getSegmentWithCache : getSegment,
        onFetchCancel: cancelGetSegment,
    });

    const { error: errorNotif } = useNotification();

    const { redirect } = useRouter();

    const cancelCurrentFetch = () => {
        if (segmentId) {
            cancelFetch({ params: segmentId });
        }
    };

    const handleFetchCallback = useCallback(
        (withRedirectOnFetchError = false) =>
            ({ success, response: callbackResponse }: FetchCallbackParams<SegmentWithContext>) => {
                if (success) {
                    setSegment(callbackResponse || undefined);
                    return;
                }

                errorNotif({ translate: GLOBAL.GENERIC_ERROR });
                if (withRedirectOnFetchError) {
                    redirect(segmentBaseRoute);
                }
            },
        [errorNotif, redirect],
    );

    const fetchSegment = useCallback(
        (params: GetSegmentRequest) => {
            fetch({ callback: handleFetchCallback(false), params });
        },
        [fetch, handleFetchCallback],
    );

    const fetchSegmentWithRedirectOnError = useCallback(
        (params: GetSegmentRequest) => {
            fetch({ callback: handleFetchCallback(true), params });
        },
        [fetch, handleFetchCallback],
    );

    useEffect(() => {
        if (fetchOnMount && segmentId) {
            fetchSegment({ segmentId });
        }
    }, [fetchOnMount, segmentId, fetchSegment]);

    const isLoading = status === BaseLoadingStatus.loading;
    return {
        segment,
        fetchSegment,
        cancelFetch: cancelCurrentFetch,
        fetchSegmentWithRedirectOnError,
        isLoading,
    };
};
