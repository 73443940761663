import { useMemo } from 'react';

import { useAriaIds } from '@lumapps/aria-ids/hooks/useAriaIds';

export interface UseFeedArticleProps {
    /** The current index of the feed article within its feed. */
    index: number;
    /** The total number of articles within the feed. */
    total: number;
}

/**
 * Hook to manage a single Feed article.
 *
 * The hook returns the necessary props to set on a single article within a feed.
 *
 * @family Feed
 */
export const useFeedArticle = ({ index, total }: UseFeedArticleProps) => {
    const ariaIdAttributes = useAriaIds();

    const response = useMemo(
        () =>
            ({
                role: 'article',
                tabIndex: 0,
                'aria-posinset': index + 1,
                'aria-setsize': total,
                ...ariaIdAttributes,
            }) as const,
        [ariaIdAttributes, index, total],
    );

    return response;
};
