import { useResultsGenericItemsConverter } from '@lumapps/search-results/hooks/useResultsGenericItemsConverter';
import { BaseSearchResult, SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';

import { mapGenericItemsToSearchSuggestion } from '../utils/mapGenericItemsToSearchSuggestion';

/**
 * Convert the given search results suggestion and map them to
 * and convert them search suggestions with the given type.
 * @param instanceId the current instance id
 * @param type the type of search suggestion that must be mapped to each item (recommendation / suggestion)
 */
export type UseResultToSuggestionConverterParams = {
    instanceId: string;
    type: SearchSuggestionType;
};
export const useResultToSuggestionConverter = (
    items: Array<BaseSearchResult>,
    params: UseResultToSuggestionConverterParams,
): SearchSuggestion[] => {
    const genericResultsSuggestions = useResultsGenericItemsConverter(items || [], []);
    const resultsSuggestions = mapGenericItemsToSearchSuggestion(genericResultsSuggestions, {
        instanceId: params.instanceId,
        type: params.type,
    });

    return resultsSuggestions;
};
