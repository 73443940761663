import BaseApi from '@lumapps/base-api';

import { Style } from '../types';

const styleApi = new BaseApi({
    path: 'style',
});

export interface GetStyleOptions {
    uid: string;
    fields?: string;
}

export const getStyle = (options: GetStyleOptions) => {
    return styleApi.get<Style>('get', { params: options }).then((response) => {
        return response.data;
    });
};
