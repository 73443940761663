/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';

/**
 * Article API.
 *
 * @see https://github.com/lumapps/core/blob/master/specs/contribution_api_v1.yaml
 */
const articleApi = new BaseApi({ version: BaseApi.versions.v2 });

/**
 * Articles parametrized APIs
 * - `articles` allows to retrieve an article given its id
 * - `articles-in-container` not only allows to create an article, but it also creates a structured
 * content and shares the article in a container.
 */
const paths = {
    article: (articleId: string) => `articles/${articleId}`,
    articles: 'articles',
    articleLayout: (articleId: string) => `${paths.article(articleId)}/layout`,
    articlesInContainer: 'articles-in-container',
    updateArticlesInContainer: (articleId: string) => `articles-in-container/${articleId}`,
};

export { articleApi, paths };
