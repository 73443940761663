/* eslint-disable lines-around-directive */
(function IIFE() {
    // eslint-disable-next-line strict
    'use strict';

    function WidgetPlayController() {
        'ngInject';

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl The parent controller object.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };

        /**
         * Get widget classes.
         *
         * @return {Array} An array of widget css classes.
         */
        function getWidgetClass() {
            const widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            return widgetClass;
        }

        /**
         * The widget is empty when there is no "video" in the properties.
         * We also check the existence of the old 'videoId' pattern until we're sure that
         * all videos were updated with the new 'video' pattern
         */
        function isEmpty() {
            return !vm.widget.properties.video && !vm.widget.properties.videoId;
        }

        /**
         * In designer mode, when the widget is empty, it is visible and displays
         * the widget type (play video)
         * Used by the designer mode.
         */
        function isWidgetEmpty() {
            return vm.parentCtrl.designerMode() && isEmpty();
        }

        /**
         * In reader mode, when the widget is empty, it is not displayed at all
         */
        function isWidgetHidden() {
            return !vm.parentCtrl.designerMode() && isEmpty();
        }

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
    }

    function widgetPlayDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetPlayController,
            controllerAs: 'vm',
            replace: true,
            require: ['widgetPlay', '^widget'],
            restrict: 'E',
            link,
            scope: {
                widget: '=',
            },
            templateUrl:
                '/client/front-office/modules/content/modules/widget/modules/widget-play/views/widget-play.html',
        };
    }

    window.angular.module('Widgets').directive('widgetPlay', widgetPlayDirective);
})();
