import React from 'react';

import { ListItem, Size, SkeletonCircle, SkeletonTypography, Typography } from '@lumapps/lumx/react';

export type NotificationListItemKeletonProps = {
    className?: string;
};

/**
 * A skeleton representing a NotificationListItem
 * Displayed when loading
 */
const NotificationListItemSkeleton: React.FC<NotificationListItemKeletonProps> = ({ className }) => (
    <ListItem
        role="presentation"
        className={className}
        before={<SkeletonCircle size={Size.m} />}
        after={<SkeletonCircle size={Size.m} />}
    >
        <SkeletonTypography typography={Typography.subtitle2} width="200px" />
        <SkeletonTypography typography={Typography.subtitle2} width="176px" />
        <SkeletonTypography typography={Typography.subtitle2} width="88px" />
    </ListItem>
);

export { NotificationListItemSkeleton };
