import camelCase from 'lodash/camelCase';

import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { getRawText, matchAttribute } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { USER_ID_ATTRIBUTE, USER_MENTION } from '../../../constants';
import { createUserMention } from '../../../utils/createUserMention';

type ElementTypes = typeof USER_MENTION;

const idAttributeName = camelCase(USER_ID_ATTRIBUTE);

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    [USER_MENTION]: {
        test: matchAttribute(idAttributeName, /.*/),
        type: USER_MENTION,
        transform: (node: any) => {
            const id = node.properties?.[idAttributeName];
            const fullName = getRawText(node).trim();
            return createUserMention({ id, fullName });
        },
    },
};
