function LoginFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.APPLICATION_HOST}/service/login/:key`,
        {},
        {
            checkToken: {
                isArray: false,
                method: 'GET',
                params: {
                    key: 'token',
                },
            },

            newPassword: {
                isArray: false,
                method: 'POST',
                params: {
                    key: 'forgottenPassword',
                },
            },

            reset: {
                isArray: false,
                method: 'POST',
                params: {
                    key: 'reset',
                },
            },
        },
    );
}

/////////////////////////////

angular.module('Factories').factory('LoginFactory', LoginFactory);

export { LoginFactory };
