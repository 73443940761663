import createSlice from '@lumapps/redux/createSlice';

let initialState = {};

/**
 * Since the Legacy application has a lot of complexities, in order to initialize this state,
 * we need to inject the state when we receive the response from the backend, and save it here.
 *
 * Once the redux store begins setup, we hook into one of the angular specific dispatches and update
 * the state with the one processed.
 *
 * TODO: remove this code once we have migrated to React.
 */
function setInitialState(state: any) {
    initialState = state.header;
}

const reducers = {
    '__angular__/instance/update': (state: any): void => {
        Object.assign(state, initialState);
    },
};

const { actions, reducer } = createSlice({
    domain: 'header',
    initialState,
    reducers,
});

export { actions, reducer, setInitialState, reducers };
