import {
    isCommunitiesAllowed,
    isCommunitiesFeatureEnabled,
    isSpacesFeatureEnabled,
} from '@lumapps/communities/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/stores/frontOffice';

import { SpacesModuleSettingsMenuEntry } from './SpacesModuleSettingsMenuEntry';

const mapStateToProps = (state: FrontOfficeStore) => {
    const isAllowed = isCommunitiesAllowed(state);
    const isSpaceFeatureEnable = isSpacesFeatureEnabled(state);
    const isCommunitiesFeatureEnable = isCommunitiesFeatureEnabled(state);

    return {
        isAllowed: isAllowed && isSpaceFeatureEnable && isCommunitiesFeatureEnable,
    };
};

const Connected = connect(mapStateToProps)(SpacesModuleSettingsMenuEntry);

export { Connected as SpacesModuleSettingsMenuEntry };
