import React from 'react';

import { ColorBubbleLetter } from '@lumapps/lumx-texts/components/ColorBubbleLetter';

import { channelType } from './types';
import { Icon } from '../../ui/icons/Icon';

/**
 * Display a custom header in the accordion box that display the list of channels.
 *
 * @param  {props} props The component properties.
 * @return {node}  The custom header.
 */
export const WidgetChannelListAccordionHeader = (props) => {
    let { channel } = props;

    return (
        <div className="channel-block__header">
            <div className="channel-block__thumbnail">
                <ColorBubbleLetter size="s" text={channel.name} />
            </div>

            <div className="channel-block__name">{channel.name}</div>

            <Icon value="chevron-down" className="channel-block__action custom-accordion-box__chevron" />
        </div>
    );
};

WidgetChannelListAccordionHeader.propTypes = {
    /** The current channel to display */
    channel: channelType,
};
