import React from 'react';

import { classnames } from '@lumapps/classnames';
import type { ElementRender } from '@lumapps/wrex/types';

import { T_R } from '../../../constants';
import type { TRElement } from '../../../types';

export const TR: ElementRender<TRElement, HTMLTableRowElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;

    return (
        <tr {...forwardedProps} className={classnames(className, 'wrex-content-tr')} ref={elementRef}>
            {children}
        </tr>
    );
};
TR.displayName = T_R;
