/**
 * Reset page focus and scroll.
 */
export function resetPageFocusAndScroll() {
    const originalTabIndex = document.body.getAttribute('tabindex');
    if (!originalTabIndex) {
        // Temporarly make the body focusable
        // (keeping focusable makes the browser focus the body when clicking on any scrollbars which breaks menus &
        //  combobox)
        document.body.setAttribute('tabindex', '-1');
    }

    // Reset focus
    document.body.focus();

    // Remove tabindex if it wasn't originaly present
    if (!originalTabIndex) {
        document.body.removeAttribute('tabindex');
    }

    // Reset scroll position
    window.scrollTo(0, 0);
}
