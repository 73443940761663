import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { SLACK_PROVIDER } from '../../constants';
import { isSlackEnabled, chatUserSpacesSelector, chatUserTokenStatusSelector } from '../../ducks/selectors';
import { fetchUserSpaces } from '../../ducks/thunks';
import { NativeIntegrations } from './NativeIntegrations';

const mapStateToProps = (state: FrontOfficeStore) => {
    const hasSlackFeature = isSlackEnabled(state);
    const userSpaces = chatUserSpacesSelector(state);
    const userTokenStatus = chatUserTokenStatusSelector(state);

    return {
        hasSlackFeature,
        slackProvider: SLACK_PROVIDER,
        userTokenStatus,
        userSpaces,
    };
};

const mapDispatchToProps = {
    fetchUserSpaces,
};

const ConnectedNativeIntegrations = connect(mapStateToProps, mapDispatchToProps)(NativeIntegrations);

export { ConnectedNativeIntegrations as NativeIntegrations };
