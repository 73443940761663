function AbstractPickerService($rootScope, $timeout, LxDepthService) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay between the actual closing/opening of the picker and the sending of the event.
     *
     * @type {number}
     */
    // Todo: REMOVE THIS **** MAGIC DELAY FOR EVENTS BROADCASTING, THIS IS NON-SENS.
    const _EVENT_DELAY = 50;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Close a abstract picker.
     *
     * @param {string}  pickerId      The id of the abstract picker to close.
     * @param {boolean} hasDataToSave Determine whether picker has to save data or not.
     */
    function closeAbstractPicker(pickerId, hasDataToSave = false) {
        if (angular.isUndefinedOrEmpty(pickerId)) {
            return;
        }

        $rootScope.$broadcast('abstract-picker__close-start', pickerId, hasDataToSave);

        angular.element('body').css({ overflow: 'visible' });
        angular.element(`#${pickerId}`).removeClass('abstract-picker--is-shown');

        $timeout(() => {
            $rootScope.$broadcast('abstract-picker__close-end', pickerId);
        }, _EVENT_DELAY);
    }

    /**
     * Open an abstract picker.
     *
     * @param {string}  pickerId   The id of the abstract picker to open.
     * @param {boolean} isEmbedded Determine whether the media picker is embedded or not.
     */
    function openAbstractPicker(pickerId, isEmbedded) {
        if (angular.isUndefinedOrEmpty(pickerId)) {
            return;
        }

        LxDepthService.register();
        $rootScope.$broadcast('abstract-picker__open-start', pickerId);

        angular
            .element(`#${pickerId}`)
            .css('z-index', LxDepthService.getDepth())
            .addClass('abstract-picker--is-shown');

        $timeout(() => {
            $rootScope.$broadcast('abstract-picker__open-end', pickerId);
        }, _EVENT_DELAY);

        if (!isEmbedded) {
            angular.element('body').css({
                overflow: 'hidden',
            });
        }
    }

    /////////////////////////////

    service.close = closeAbstractPicker;
    service.open = openAbstractPicker;
}

/////////////////////////////

angular.module('Services').service('AbstractPicker', AbstractPickerService);

export { AbstractPickerService };
