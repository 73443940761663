/* istanbul ignore file */

import BaseApi, { ServerListResponse } from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { MAX_DIRECTORY_ENTRIES, ENTRIES_FIELDS } from '../constants';
import { DirectoryEndpointQuery, DirectoryEntry } from '../types';

export const directoryApi = new BaseApi({
    path: 'directory',
});

export const getEntries = ({
    fields = ENTRIES_FIELDS,
    maxResults = MAX_DIRECTORY_ENTRIES,
    sortOrder = 'name',
    ...params
}: DirectoryEndpointQuery) => {
    return directoryApi.post<ServerListResponse<DirectoryEntry>>('entry/list', {
        fields,
        maxResults,
        sortOrder,
        ...params,
    });
};

export const getFavoriteEntries = async ({ id, language }: { id: string; language: string }) => {
    const response = await directoryApi.postCacheFirst<ServerListResponse<DirectoryEntry>>(
        'entry/list',
        {
            directory: id,
            fields: ENTRIES_FIELDS,
            lang: language,
            maxResults: MAX_DIRECTORY_ENTRIES,
            more: true,
            sortOrder: 'name',
            userFavoritesOnly: true,
        },
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
    );

    return response.data;
};

export const saveEntry = (entry: Partial<DirectoryEntry>) => {
    return directoryApi.post<DirectoryEntry>('entry/save', entry);
};

export const deleteMultipleEntries = (uid: string[]) => {
    return directoryApi.delete<DirectoryEntry>('entry/deleteMulti', { params: { uid } });
};

export const getEntry = async (uid: string) =>
    directoryApi.get<DirectoryEntry>('entry/get', {
        params: { uid },
    });
export const saveUserEntry = async (entry: Partial<DirectoryEntry>) => {
    return directoryApi.post<DirectoryEntry>('entry/user/save', entry);
};
export const deleteUserEntry = async (uid: string) => {
    return directoryApi.delete<DirectoryEntry>('entry/user/delete', { params: { uid } });
};
