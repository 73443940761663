(function IIFE() {
    'use strict';

    /////////////////////////////

    function SettingsFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/service/:key', {}, {
            init: {
                isArray: false,
                method: 'GET',
                params: {
                    key: 'init',
                },
            },
        });
    }

    angular.module('Factories').factory('SettingsFactory', SettingsFactory);
})();
