/* istanbul ignore file */
// Ignoring coverage for this file since we should never ever ever do polling
import { sleep } from '@lumapps/utils/time/sleep';

/**
 * PLEASE MAKE SURE THAT YOU REALLY NEED THIS BEFORE USING,
 * WE SHOULD NEVER USE POLLING IN OUR WEB APPLICATION
 *
 * Method to retry api call upon failure.
 *
 * @param fetchApi  the API call promise
 * @param maxTry    Time you want to retry in case of failure
 * @param delay     Delay before retrying
 */
export const redundantFetchApi = async (fetchApi: any, maxTry: number, delay: number): Promise<any> => {
    return fetchApi().catch((error: any) => {
        if (maxTry === 1) {
            throw error;
        }

        return sleep(delay).then(() => redundantFetchApi(() => fetchApi(), maxTry - 1, delay));
    });
};
