import React from 'react';

import range from 'lodash/range';

import { cache, CACHE_TYPE } from '@lumapps/cache';
import { adminContent } from '@lumapps/contents/routes';
import { useDataAttributes } from '@lumapps/data-attributes';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { getCustomContentTypesCacheKey } from '../../ducks/selectors';
import { ContentTypesState } from '../../ducks/types';
import { CustomContentType } from '../../types';

export interface SettingsMenuCustomContentTypeProps {
    /** function to be executed to retrieve the list of custom content types */
    fetchCustomContentTypes: (invalidateCache?: boolean) => void;
    /** Determines whether we are currently fetching the list or not */
    isLoading?: boolean;
    /** the list of custom content types to display */
    customContentTypes?: ContentTypesState['entities'];
    /** the list of parent custom content types id */
    parentCustomContentTypesIds?: string[];
    /** True if the current user is super admin */
    isSuperAdmin: boolean;
    /** True if the user is allowed to see the custom content types */
    isAllowedToAccessCCT: boolean;
    /** Key/value object where the key is the id of the custom content type and the value is true/false */
    allowedCustomContentTypes: Record<string, boolean>;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
}

const CustomContentTypeItem: React.FC<{ customContentType: CustomContentType }> = ({
    customContentType: { id, name, icon },
}) => {
    const { translateObject } = useTranslate();
    const { iconPath } = useDynamicIcon({ icon });
    return (
        <Menu.Item icon={iconPath} as={RouterLink} to={adminContent(id)}>
            {translateObject(standardizeTranslateObject(name))}
        </Menu.Item>
    );
};

export const SettingsMenuCustomContentType: React.FC<SettingsMenuCustomContentTypeProps> = ({
    fetchCustomContentTypes,
    isLoading,
    customContentTypes,
    parentCustomContentTypesIds,
    isSuperAdmin,
    allowedCustomContentTypes,
    isAllowedToAccessCCT,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const hasAccess = isAllowedToAccessCCT || isSuperAdmin;
    const { get } = useDataAttributes(scope);

    /**
     * If we have a function and we know that this is the first time that the component is rendered,
     * (since isLoading is false and the list is empty), we retrieve the list of custom content types
     */
    React.useEffect(() => {
        if (fetchCustomContentTypes && !isLoading && !parentCustomContentTypesIds && hasAccess) {
            fetchCustomContentTypes();
        }
    }, [fetchCustomContentTypes, isLoading, hasAccess, parentCustomContentTypesIds]);

    const cacheKey = useSelector(getCustomContentTypesCacheKey);
    const instanceId = useSelector(instanceIdSelector);

    // Get skeleton count from cache or default to 3.
    const skeletonCount = React.useMemo(
        () => cache.retrieve(cacheKey, CACHE_TYPE.STORAGE)?.[instanceId] || 3,
        [cacheKey, instanceId],
    );

    // If user does not have access, return null
    if (!hasAccess) {
        return null;
    }

    // If the user is not super admin, we need to check the different access that they have.
    const filteredCustomContentTypes =
        !isLoading && customContentTypes
            ? Object.values(customContentTypes).filter(
                  (customContentType) => isSuperAdmin || allowedCustomContentTypes[customContentType.id],
              )
            : undefined;

    // Skip render if CCT list empty
    if (!isLoading && !filteredCustomContentTypes?.length) {
        return null;
    }

    /**
     * If the user is super admin, we want to allow them to access all the different sections of this
     * menu. There is no restriction on which custom content type they are available to see, so we
     * render all of them with no restrictions.
     */
    return (
        <Menu.Section label={translateKey(GLOBAL.CONTENT_TYPES)} {...get({ element: 'content-types' })}>
            {isLoading
                ? range(skeletonCount).map((i) => (
                      <Menu.ItemSkeleton key={i} hasIcon loadingMessage={translateKey(GLOBAL.LOADING)} />
                  ))
                : filteredCustomContentTypes?.map((customContentType) => (
                      <CustomContentTypeItem key={customContentType.id} customContentType={customContentType} />
                  ))}
        </Menu.Section>
    );
};

SettingsMenuCustomContentType.displayName = 'SettingsMenuCustomContentType';
