(function IIFE() {
    'use strict';

    /////////////////////////////

    function FolderBlockController($scope, Document, Folder, Fsitems, Tag, Translation, WidgetFileListConstant) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Document = Document;
        vm.Folder = Folder;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the default classes to apply to the block.
         * Can be overriden by directly passing a vm.classes attribute to the directive.
         *
         * @return {Array} A list of css classes to apply to the block.
         */
        function _getDefaultClasses() {
            var defaultClasses = [];

            if (vm.theme === 'dark') {
                defaultClasses.push('folder-block--theme-dark');
            } else {
                defaultClasses.push('folder-block--theme-light');
            }

            return defaultClasses;
        }

        /**
         * Only keep the tags that have a translated name.
         */
        function _initTags() {
            if (!vm.fieldsToDisplay.tags) {
                vm.tags = [];

                return;
            }

            vm.tags = _.map(vm.folder.tags, function forEachFolderTagUid(uid) {
                var tag = Tag.keyToTag(WidgetFileListConstant.LIST_KEY_MEDIA_TAGS, uid);

                if (Translation.hasTranslation(_.get(tag, 'name'))) {
                    return tag;
                }

                return undefined;
            });
        }

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            if (angular.isUndefinedOrEmpty(vm.classes)) {
                vm.classes = _getDefaultClasses();

                /**
                 * Watch any changes to the theme to recompute the classes.
                 */
                $scope.$watch('vm.theme', function themeWatcher() {
                    vm.classes = _getDefaultClasses();
                });
            }

            vm.fieldsToDisplay = vm.fieldsToDisplay || Fsitems.getDefaultFieldsToDisplay();
            vm.onClick = vm.onClick || angular.noop;
            vm.style = vm.style || {};
            vm.theme = vm.theme || 'light';

            _initTags();
        }

        init();
    }

    /////////////////////////////

    /**
     * The folder block directive.
     * Displays a single folder item within a list.
     *
     * @param {Array}    [classes]               A list of css classes to apply to the block.
     * @param {Object}   [fieldsToDisplay=<all>] A map of fields and their visibility in the block.
     * @param {Object}   folder                  The folder to display in the block.
     * @param {Function} [onClick]               A function to execute when the folder block is clicked.
     * @param {Object}   [style]                 Style properties to be applied to the folder block.
     * @param {string}   [theme="light"]         The theme to use to display the folder block.
     *                                           Possible values are "light" or "dark".
     */

    function LsFolderBlockDirective() {
        return {
            bindToController: true,
            controller: FolderBlockController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                canManage: '<?lsCanManage',
                classes: '<?lsClasses',
                fieldsToDisplay: '<?lsFieldsToDisplay',
                folder: '<lsFolder',
                onClick: '&?lsOnClick',
                reloadFn: '&lsReloadFn',
                style: '<?lsStyle',
                theme: '@?lsTheme',
            },
            templateUrl: '/client/common/modules/folder/views/folder-block.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsFolderBlock', LsFolderBlockDirective);
})();
