import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import last from 'lodash/last';
import map from 'lodash/map';
import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';

/**
 * Returns an object with all `fields` set to `value`.
 *
 * @param  {Array}  fields The value key names to consider.
 * @param  {any}    value  The value to apply to the `fields`.
 * @return {Object} The resulting object.
 */
export function objectFromValue(fields, value) {
    return fromPairs(map(fields, (field) => [field.name, value]));
}

/**
 * Returns most commonly used value.
 *
 * @param  {Array}  fields The value key names to consider.
 * @param  {Object} values The object containing the values.
 * @return {any}    The commonly used value or undefined.
 */
export function commonValue(fields, values) {
    if (!values) {
        return null;
    }

    const result = reduce(
        fields,
        (finalResult, field) => {
            const fieldValue = values[field.name];
            finalResult.set(fieldValue, (finalResult.get(fieldValue) || 0) + 1);

            return finalResult;
        },
        new Map(),
    );

    return get(last(orderBy(Array.from(result.entries()), 1)), 0);
}

/**
 * Returns most commonly used value from `value` or `defaults`.
 *
 * @param  {Array}  fields   The value key names to consider.
 * @param  {Object} values   The object containing the values.
 * @param  {Object} defaults The object containing the default values.
 * @return {any}    The commonly used value or undefined.
 */
export function commonValueOrDefault(fields, values, defaults) {
    const a = commonValue(fields, values);
    if (a !== null && a !== undefined) {
        return a;
    }

    return commonValue(fields, defaults);
}

/**
 * Returns a mapping of `{ value: label }` from an array of `{ value, label }` choices.
 * Results are memoized in order to avoid returning a new object for the same array of choices.
 *
 * @param  {Array}  choices The array of `choices` from which to build the mapping.
 * @return {Object} The resulting mapping.
 */
export const choicesToMap = memoize(
    (choices) => (choices ? fromPairs(map(choices, ({ value, label }) => [value, label])) : null),
);

/**
 * Takes a `style` description and returns the icon location in regards to the label.
 *
 * @param  {Object} style The style description.
 * @return {string} The location of the icon in regards to the label.
 */
export function iconLocationFromStyle(style) {
    if (!style) {
        return 'left';
    }

    switch (style.flexDirection) {
        case 'row':
            return 'left';
        case 'row-reverse':
            return 'right';
        case 'column': {
            switch (style.alignItems) {
                case 'flex-start':
                    return 'top-left';
                case 'center':
                    return 'top-center';
                case 'flex-end':
                    return 'top-right';
                default:
                    return 'left';
            }
        }
        case 'column-reverse': {
            switch (style.alignItems) {
                case 'flex-start':
                    return 'bottom-left';
                case 'center':
                    return 'bottom-center';
                case 'flex-end':
                    return 'bottom-right';
                default:
                    return 'left';
            }
        }
        default:
            return 'left';
    }
}

/**
 * Takes an icon location in regards to the label and returns a `style` description.
 *
 * @param  {string} iconLocation   The location of the icon in regards to the label.
 * @param  {string} alignItems     The current horizontal alignement.
 * @param  {string} flexDirection  The current flex direction.
 * @param  {string} justifyContent The current flex alignement.
 * @param  {Object} defaults       The default values of the area we are currently working with. Can be either default
 *                                 values for `footer` or `header`
 * @return {Object} The style description.
 */
export function styleFromIconLocation(iconLocation, alignItems, flexDirection, justifyContent, defaults) {
    justifyContent = justifyContent || get(defaults, ['main', 'justifyContent'], 'flex-start');

    switch (iconLocation) {
        case 'left':
            return {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent:
                    flexDirection === 'column' || flexDirection === 'column-reverse'
                        ? alignItems
                        : justifyContent === 'center' || flexDirection === 'row'
                            ? justifyContent
                            : justifyContent === 'flex-start'
                                ? 'flex-end'
                                : 'flex-start',
            };
        case 'right':
            return {
                alignItems: 'center',
                flexDirection: 'row-reverse',
                justifyContent:
                    flexDirection === 'column' || flexDirection === 'column-reverse'
                        ? alignItems === 'center'
                            ? 'center'
                            : alignItems === 'flex-start'
                                ? 'flex-end'
                                : 'flex-start'
                        : justifyContent === 'center' || flexDirection === 'row-reverse'
                            ? justifyContent
                            : justifyContent === 'flex-start'
                                ? 'flex-end'
                                : 'flex-start',
            };
        case 'top-left':
            return {
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            };
        case 'top-center':
            return {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            };
        case 'top-right':
            return {
                alignItems: 'flex-end',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            };
        case 'bottom-left':
            return {
                alignItems: 'flex-start',
                flexDirection: 'column-reverse',
                justifyContent: 'flex-start',
            };
        case 'bottom-center':
            return {
                alignItems: 'center',
                flexDirection: 'column-reverse',
                justifyContent: 'flex-start',
            };
        case 'bottom-right':
            return {
                alignItems: 'flex-end',
                flexDirection: 'column-reverse',
                justifyContent: 'flex-start',
            };
        default:
            return {};
    }
}

/**
 * Returns `true` if couples `a` and `b` have the same values.
 *
 * @param  {Array}   a The first couple of values to compare.
 * @param  {Array}   b The second couple of values to compare.
 * @return {boolean} Whether `a` and `b` have the same values or not.
 */
export function couplesAreEqual(a, b) {
    return a === b || (a[0] === b[0] && a[1] === b[1]) || (a[0] === b[1] && a[1] === b[0]);
}
