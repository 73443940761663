/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';
import { useDynamicIcon } from '@lumapps/utils/hooks/useDynamicIcon';

import { directoryEntriesRoute } from '../../routes';
import { Directory } from '../../types';

export interface DirectoriesSettingsMenuProps {
    isAllowed: boolean;
    directories: Directory[] | undefined;
    scope: string;
}

const DirectorySettingItem = ({ directory, scope }: { directory: Directory; scope: string }) => {
    const { get } = useDataAttributes(scope);
    const { translateObject } = useTranslate();
    const { iconPath } = useDynamicIcon({ icon: directory.properties?.icon || 'book-multiple' });
    return (
        <Menu.Item
            icon={iconPath}
            as={RouterLink}
            to={directoryEntriesRoute(directory.id)}
            {...get({ element: 'directory', action: directory.id })}
        >
            {translateObject(directory.name)}
        </Menu.Item>
    );
};

export const DirectoriesSettingsMenu: React.FC<DirectoriesSettingsMenuProps> = ({ isAllowed, directories, scope }) => {
    if (!isAllowed || directories?.length === 0) {
        return null;
    }

    return (
        <>
            {directories?.map((directory) => (
                <DirectorySettingItem key={directory.id} scope={scope} directory={directory} />
            ))}
        </>
    );
};

DirectoriesSettingsMenu.displayName = 'DirectoriesSettingsMenu';
