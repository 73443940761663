(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsGalleryController($transclude, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Utils = Utils;

        $transclude(function hasTrancluded(clone) {
            // eslint-disable-next-line lumapps/angular-isdefined
            if (clone.length > 0) {
                vm.hasTranscluded = true;
            }
        });
    }

    /////////////////////////////

    function GalleryDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LsGalleryController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                mediaList: '=lsMediaList',
                size: '@?lsSize',
                theme: '@?lsTheme',
            },
            templateUrl: '/client/common/modules/gallery/views/gallery.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsGallery', GalleryDirective);
})();
