import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import type { ElementRender } from '@lumapps/wrex/types';

import { TABLE } from '../../../constants';
import type { TableElement } from '../../../types';

import './index.scss';

export const Table: ElementRender<TableElement, HTMLTableElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const theme = useThemeContext();

    return (
        <table
            {...forwardedProps}
            className={classnames(className, 'wrex-content-table', `wrex-content-table--theme-${theme}`)}
            ref={elementRef}
        >
            <tbody>{children}</tbody>
        </table>
    );
};
Table.displayName = TABLE;
