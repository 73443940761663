export enum SA_TOPICS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36883835
     */
    ADD_TOPIC = 'FRONT.SOCIAL_ADVOCACY.ADD_TOPIC.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36883836
     */
    ADD_TOPIC_HELPER = 'FRONT.SOCIAL_ADVOCACY.ADD_TOPIC.HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962679
     */
    DELETE_TOPIC = 'FRONT.SOCIAL_ADVOCACY.DELETE_TOPIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962697
     */
    DELETE_TOPIC_WARNING = 'FRONT.SOCIAL_ADVOCACY.DELETE_TOPIC.WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37726013
     */
    EDIT_TOPIC = 'FRONT.SOCIAL_ADVOCACY.EDIT_TOPIC.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45857219
     */
    PROGRAM_CREATION_STEP_TOPICS = 'FRONT.SOCIAL_ADVOCACY.PROGRAM_CREATION.STEP.TOPICS.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46646609
     */
    SEARCH_TOPICS = 'FRONT.SOCIAL_ADVOCACY.SEARCH_TOPICS.LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46646627
     */
    SEARCH_TOPICS_NO_TOPIC = 'FRONT.SOCIAL_ADVOCACY.SEARCH_TOPICS.NO_TOPIC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46646651
     */
    NO_TOPIC_REMOVE_TO_DISPLAY = 'FRONT.SOCIAL_ADVOCACY.SEARCH_TOPICS.NO_TOPIC.REMOVE_TO_DISPLAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46646652
     */
    SEARCH_TOPICS_NO_TOPIC_CLEAR_FILTER = 'FRONT.SOCIAL_ADVOCACY.SEARCH_TOPICS.NO_TOPIC.CLEAR_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46665768
     */
    NEW_TOPICS = 'FRONT.SOCIAL_ADVOCACY.TOPICS.SORT.NEW_TOPICS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962672
     */
    CREATE_TOPIC_DUPLICATE_ERROR = 'FRONT.SOCIAL_ADVOCACY.CREATE_TOPIC.DUPLICATE_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46665865
     */
    EDIT_TOPIC_ERROR_EMPTY = 'FRONT.SOCIAL_ADVOCACY.EDIT_TOPIC.ERROR.EMPTY',
}
