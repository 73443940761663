(function IIFE() {
    'use strict';

    /////////////////////////////

    function GTranslateService(Features, TranslateFactory) {
        'ngInject';

        const service = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Name of the feature.
         *
         * @type {string}
         */
        service.featureName = 'translation';
        service.secondFeatureName = 'translation-end-user';

        /**
         * If the service has reached over quota or not.
         *
         * @type {boolean}
         */
        service.quotaLimitExceeded = false;

        /**
         * Limit of translatable char.
         *
         * @type {number}
         */
        service.MAX_CHAR_TO_TRANSLATE = 3000;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the feature is active and the service has not reached the maximum quota.
         *
         * @return {boolean} Return the service available state.
         */
        function isAvailable() {
            return (Features.hasFeature(service.featureName) || Features.hasFeature(service.secondFeatureName)) && !service.quotaLimitExceeded;
        }

        /**
         * Call googleTranslate method to translate the text into the target lang.
         *
         * @param {string}   text        The text to translate.
         * @param {string}   targetLang  The lang we want to translate the text into.
         * @param {string}   [inputLang] The lang of the text.
         * @param {Function} [cb]        The success callback.
         * @param {Function} [errCb]     The error callback.
         * @param {boolean}  [isHtml]    Wether or not the content is html.
         */
        function translate(text, targetLang, inputLang, cb, errCb, isHtml) {
            // Block call + callbacks if feature is not enabled.
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;
            const params = {
                contentText: text,
                sourceFormat: isHtml ? 'html' : 'text',
                sourceLang: inputLang,
                targetLang,
            };

            service.translateQuery = TranslateFactory.googleTranslate(
                params,
                function onSuccess(response) {
                    cb(response);
                },
                function onError(err) {
                    service.quotaLimitExceeded = true;
                    errCb(err);
                },
            );
        }

        /**
         * Cancel the current translate query.
         *
         */
        function cancelCurrentQuery() {
            service.translateQuery.$cancelRequest();
        }

        /////////////////////////////

        service.isAvailable = isAvailable;
        service.translate = translate;
        service.cancelCurrentQuery = cancelCurrentQuery;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('GTranslate', GTranslateService);
})();
