import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { currentLanguageSelector } from '@lumapps/languages';
import { DatePickerField, FlexBox, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_CONTENT_FILTER } from '../../keys';
import { FormattedPublicationDates } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

import './index.scss';

interface PublicationDatesContentFilter {
    onChange: (value: FormattedPublicationDates) => void;
    publicationDates?: FormattedPublicationDates;
    theme: Theme;
    hideSubheader: boolean;
    scope: string;
}

const CLASSNAME = 'publication-dates-content-filter';

export const PublicationDatesContentFilter = ({
    theme,
    hideSubheader,
    scope,
    publicationDates,
    onChange,
}: PublicationDatesContentFilter) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope || 'date-picker');
    const { element } = useClassnames(CLASSNAME);
    const currentLanguage = useSelector(currentLanguageSelector);
    const { startDate, endDate } = publicationDates || {};

    const handleStartDate = (date: Date) => {
        date.setHours(0, 0, 0, 0);
        onChange({ endDate, startDate: date.toISOString() });
    };
    const handleEndDate = (date: Date) => {
        date.setHours(0, 0, 0, 0);
        onChange({ startDate, endDate: date.toISOString() });
    };

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.PUBLICATION_DATE)} theme={theme} />}
            <FlexBox gap="big" orientation="horizontal">
                <DatePickerField
                    className={element('date-picker')}
                    theme={theme}
                    locale={currentLanguage}
                    label={translateKey(WIDGET_CONTENT_FILTER.START_DATE)}
                    value={startDate ? new Date(startDate) : undefined}
                    maxDate={endDate ? new Date(endDate) : undefined}
                    onChange={handleStartDate}
                    nextButtonProps={{ label: translateKey(GLOBAL.NEXT_MONTH) }}
                    previousButtonProps={{ label: translateKey(GLOBAL.PREVIOUS_MONTH) }}
                    {...get({ element: 'start-date' })}
                />
                <DatePickerField
                    className={element('date-picker')}
                    theme={theme}
                    locale={currentLanguage}
                    label={translateKey(WIDGET_CONTENT_FILTER.END_DATE)}
                    value={endDate ? new Date(endDate) : undefined}
                    minDate={startDate ? new Date(startDate) : undefined}
                    onChange={handleEndDate}
                    nextButtonProps={{ label: translateKey(GLOBAL.NEXT_MONTH) }}
                    previousButtonProps={{ label: translateKey(GLOBAL.PREVIOUS_MONTH) }}
                    {...get({ element: 'end-date' })}
                />
            </FlexBox>
        </>
    );
};
