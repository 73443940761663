import { MapPlace } from './types';

export const getMapDirectionPlaces = (
    gMapDirectionData: Pick<MapPlace, 'data'>[],
    gMapDirectionNames: Pick<MapPlace, 'name'>[],
    gMapDirectionCoords: Pick<MapPlace, 'lon' | 'lat'>[],
) => {
    if (!!gMapDirectionData && !!gMapDirectionNames && !!gMapDirectionCoords) {
        const places: MapPlace[] = [];

        gMapDirectionCoords.forEach((placeCoord, index) => {
            places.push({ ...placeCoord, ...gMapDirectionNames[index], ...gMapDirectionData[index] });
        });

        return places;
    }
    return undefined;
};
