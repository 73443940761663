import { WidgetState } from '../ducks/type';
import { Widget } from '../types';

export function getUpdatedWidgetState(widget: Widget): WidgetState['state'] {
    if (!widget || !widget.widgetId) {
        return 'notmigrated';
    }
    if (!widget.body) {
        return 'empty';
    }
    return 'loaded';
}
