import type { Widget } from '@lumapps/widget-base/types';

import type { Cell, Layout, Row, WidgetReference } from '../types';

const appendWidget = (
    cellComponent: Row | WidgetReference,
    widgets: Widget[],
    getFunction: (cells: Cell[]) => Widget[],
) => {
    // Handle nested rows
    if (cellComponent.type === 'row') {
        const cellWidgets = getFunction(cellComponent.cells);
        widgets.push(...cellWidgets);
    } else {
        widgets.push(cellComponent);
    }
};

/**
 * Get all the widgets in the given content rows
 */
export const getAllWidgetsInContent = (components: Layout['components'] | Cell[]): Widget[] => {
    const widgets: Widget[] = [];

    for (const component of components) {
        if (component.type === 'row') {
            for (const cell of component.cells) {
                for (const cellComponent of cell.components) {
                    appendWidget(cellComponent, widgets, getAllWidgetsInContent);
                }
            }
        } else if (component.type === 'cell') {
            for (const cellComponent of component.components) {
                appendWidget(cellComponent, widgets, getAllWidgetsInContent);
            }
        }
    }

    return widgets;
};
