import { Block, BlockImage, BlockTagReferenceList, MetadataReference } from '@lumapps/widget-base/types';

/** Directory entry block variant. */
export enum BlockDirectoryEntryVariant {
    vertical = 'VERTICAL',
    horizontal = 'HORIZONTAL',
}

/** Directory entry custom field */
interface DirectoryEntryField {
    id: string;
    values: string[];
}

/** Directory entry block. */
export interface BlockDirectoryEntry extends Block {
    type: 'BlockDirectoryEntry';
    /** The entry's id. */
    directoryEntryId: string;
    /** The id of the directory to which this entry belongs. */
    directoryId: string;
    /** The entry's title. */
    title?: string;
    /** The entry's tags. */
    tags?: BlockTagReferenceList;
    /** The entry's url. */
    link: string;
    /** The entry's image. */
    image?: BlockImage;
    /** The entry's variant. */
    variant: BlockDirectoryEntryVariant;
    /** Whether the current user has added the entry to his favorites. */
    isFavorite: boolean;
    /** Whether the favorite icon is visible. */
    isFavoriteIconVisible: boolean;
    /** The entry's metadata. */
    metadata?: MetadataReference[];
    /** an ordered list of additional directory entry fields */
    additionalFields?: DirectoryEntryField[];
    /** Whether the current user can edit the entry */
    canEdit?: boolean;
    /** Whether the current user can delete the entry */
    canDelete?: boolean;
}

/** Add directory entry Block */
export interface BlockAddDirectoryUserEntry extends Block {
    type: 'BlockAddDirectoryUserEntry';
    /** The id of the directory to which this entry belongs. */
    directoryId: string;
}
