import { mdiTrophyAward, mdiArrowTopRightThick } from '@lumapps/lumx/icons';
import { ColorPalette } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations';

import { SEARCH } from '../keys';
import { BaseSearchResult } from '../types';

export type FlagType = {
    icon?: string;
    color: ColorPalette;
    label: string;
};
export const FLAG_TYPE = {
    promoted: {
        icon: mdiTrophyAward,
        color: ColorPalette.yellow,
        label: SEARCH.RESULT_IS_PROMOTED,
    },
    featured: {
        icon: mdiArrowTopRightThick,
        color: ColorPalette.yellow,
        label: SEARCH.RESULT_IS_FEATURED,
    },
    recommended: {
        color: ColorPalette.blue,
        label: GLOBAL.RESULT_IS_RECOMMENDED,
    },
};

export const getFlag = (document: BaseSearchResult): FlagType | null => {
    if (document && document.isTopResult) {
        return FLAG_TYPE.featured;
    }

    if (document && document.isPromoted) {
        return FLAG_TYPE.promoted;
    }

    return null;
};
