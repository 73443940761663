import React, { MouseEvent } from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface DeletePlayVideoButtonProps {
    /** callback called when clicking on the button */
    onDelete: (event: MouseEvent<HTMLButtonElement>) => void;
}

const CLASSNAME = 'delete-play-video-button';

export const DeletePlayVideoButton = ({ onDelete }: DeletePlayVideoButtonProps) => {
    const { translateKey } = useTranslate();
    const { block } = useClassnames(CLASSNAME);

    return (
        <IconButton
            className={classnames(block())}
            emphasis={Emphasis.low}
            hasBackground
            icon={mdiClose}
            label={translateKey(GLOBAL.DELETE)}
            onClick={onDelete}
        />
    );
};
