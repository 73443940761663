import React from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { FlexBox, InlineList, Orientation, Text, Tooltip } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { MAX_VISIBLE_GROUPS } from '../../constants';
import './index.scss';

export interface VideoGroupDetailsProps {
    className?: string;
    groupNames: string[];
}

const CLASSNAME = 'video-group-details';

/**
 *
 * @param VideoGroupDetailsProps
 * @returns VideoGroupDetails
 */
export const VideoGroupDetails = ({ className, groupNames }: VideoGroupDetailsProps) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const displayedGroups = groupNames.slice(0, MAX_VISIBLE_GROUPS);
    const hiddenGroups = groupNames.slice(MAX_VISIBLE_GROUPS);

    return (
        <FlexBox className={classnames(CLASSNAME, className)} orientation={Orientation.horizontal} key="group-details">
            {translateKey(GLOBAL.VISIBILITY)}:&nbsp;
            <InlineList>{displayedGroups.join(', ')}</InlineList>
            {hiddenGroups.length > 0 && (
                <Tooltip label={groupNames.join('\n')}>
                    &nbsp;
                    <Text as="span" className={element('hidden-groups')}>
                        +{hiddenGroups.length}
                    </Text>
                </Tooltip>
            )}
        </FlexBox>
    );
};
