/**
 * An object holding various options related to instances.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const InstanceConstant = {
    /**
     * An object used to hold the various projections linked to instances in the project.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    PROJECTION: {
        /**
         * The projection used to get the list of all instances in the admin.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        ADMIN_LIST: {
            items: {
                createdAt: true,
                createdBy: true,
                id: true,
                isDefaultInstance: true,
                name: true,
                slug: true,
                uid: true,
            },
        },
        /**
         * The projection used to get the parentless instances.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        EMPTY_PARENT: {
            items: {
                id: true,
                name: true,
                title: true,
                uid: true,
            },
        },
        /**
         * The projection used to get the list of all instances in the content stream.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        STREAM_LIST: {
            items: {
                createdAt: true,
                createdBy: true,
                id: true,
                isDefaultInstance: true,
                name: true,
                parent: true,
                slug: true,
                uid: true,
            },
        },
        /**
         * The projection used to get the list of all instances in the global mobile settings.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        MOBILE_SETTINGS_LIST: {
            items: {
                id: true,
                uid: true,
                name: true,
            },
        },
    },
};

angular.module('Constants').constant('InstanceConstant', InstanceConstant);

export { InstanceConstant };
