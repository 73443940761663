export enum CUSTOMIZATION {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11506028
     */
    TITLE_CUSTOMIZATION = 'ADMIN.NAV.MOBILE_SETTINGS_CUSTOMIZATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9470567
     */
    MOBILE_APP_LOGO = 'ADMIN.CUSTOMER.MOBILE_APP_LOGO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9613718
     */
    UPLOAD_LOGO_HELPER = 'ADMIN.CUSTOMER.MOBILE_APP_LOGO_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11659659
     */
    COLOR_THEME = 'ADMIN.CUSTOMER.MOBILE_COLOR_THEME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11659661
     */
    PRIMARY_COLOR_LABEL = 'ADMIN.CUSTOMER.MOBILE_PRIMARY_COLOR_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660324
     */
    PRIMARY_COLOR_HELPER = 'ADMIN.CUSTOMER.MOBILE_PRIMARY_COLOR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11674942
     */
    PRIMARY_AS_BACKGROUND = 'ADMIN.CUSTOMER.MOBILE_PRIMARY_AS_BACKGROUND',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660315
     */
    SECONDARY_COLOR_LABEL = 'ADMIN.CUSTOMER.MOBILE_SECONDARY_COLOR_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11660325
     */
    SECONDARY_COLOR_HELPER = 'ADMIN.CUSTOMER.MOBILE_SECONDARY_COLOR_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11691403
     */
    SAVE_SUCCESS = 'ADMIN.MOBILE_CUSTOMIZATION_SAVE_SUCCESS',
}
