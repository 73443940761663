import React, { ReactNode, useContext, useMemo } from 'react';

import { DEFAULT_FEED_SENSITIVITY } from './constants';

interface FeedInfiniteScrollContextProps {
    /** Whether the feed has more items to fetch */
    hasMore?: boolean;
    /** The infinite scroll sensitivity */
    sensitivity: number;
    /** Action when more items must be fetched */
    callback?(): void;
}

/** The default values of the configuration context, if no or partial context is passed */
export const feedInfiniteScrollContextDefault: FeedInfiniteScrollContextProps = {
    sensitivity: 1,
    hasMore: false,
    callback: () => null,
};

/**
 * Create a context to share all the static configuration of this navigation.
 * This is the data that won't change while the user interacts with the navigation admin.
 * */
export const FeedInfiniteScrollContext = React.createContext<FeedInfiniteScrollContextProps>(
    feedInfiniteScrollContextDefault,
);

/** Hook to get the Navigation Admin Configuration Context */
export const useFeedInfiniteScroll = () => {
    return useContext(FeedInfiniteScrollContext);
};

export interface FeedInfiniteScrollProviderProps extends FeedInfiniteScrollContextProps {
    children: ReactNode;
}

export const FeedInfiniteScrollProvider: React.FC<FeedInfiniteScrollProviderProps> = ({
    sensitivity = DEFAULT_FEED_SENSITIVITY,
    hasMore,
    callback,
    children,
}) => {
    const infiniteScrollValue = useMemo(
        () => ({
            hasMore,
            sensitivity,
            callback,
        }),
        [hasMore, sensitivity, callback],
    );

    return (
        <FeedInfiniteScrollContext.Provider value={infiniteScrollValue}>{children}</FeedInfiniteScrollContext.Provider>
    );
};
