import React from 'react';

import FroalaEditor from 'react-froala-wysiwyg';

/**
 * WARNING: YOU SHOULD NOT BE USING THIS COMPONENT
 * Legacy Froala Editor only used on legacy react pages or features that are still
 * not migrated to our own WREX
 * @deprecated
 * @returns LegacyFroalaEditor
 */
export const LegacyFroalaEditor: React.FC<any> = (props) => {
    return <FroalaEditor {...props} />;
};
