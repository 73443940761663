import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { AttachmentsTopic, ATTACHMENTS_OUTPUTCLASS, isElement, Node } from '@lumapps/wrex/serialization/dita/types';

export function createAttachmentsTopic(children: Node[] = []): AttachmentsTopic {
    return {
        type: 'element',
        name: 'topic',
        attributes: {
            id: `attachments-${generateUUID()}`,
            outputclass: ATTACHMENTS_OUTPUTCLASS,
        },
        children: [
            {
                type: 'element',
                name: 'title',
                children: [{ type: 'text', value: '' }],
            },
            {
                type: 'element',
                name: 'body',
                children: children.filter(isElement),
            },
        ],
    };
}
