function ChannelFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/chat/:methodKey`,
        {},
        {
            list: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/chat/userSpaces/:methodKey`,
            },
            listConversations: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/chat/channels/:methodKey`,
            },
            listJoined: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'joined',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/chat/userSpaces/list/:methodKey`,
            },
        },
    );
}

angular.module('Factories').factory('ChannelFactory', ChannelFactory);

/////////////////////////////

export { ChannelFactory };
