import { GDCustomDocViewType, GDPickerConfiguration } from './types';

export const FILE_FIELDS =
    'id,driveId,webViewLink,webContentLink,name,mimeType,createdTime,modifiedTime,trashed,explicitlyTrashed,thumbnailLink';

export const DRIVE_DEFAULT_VIEW = 'DOCS';

export const DRIVE_DEFAULT_CONFIG: Partial<GDPickerConfiguration> = {
    appId: '',
    supportDrives: false,
    clientId: '',
    developerKey: '',
    viewId: DRIVE_DEFAULT_VIEW,
    disabled: false,
    setParentFolder: '',
    setSelectFolderEnabled: false,
    showUploadView: false,
    setIncludeFolders: true,
    multiselect: false,
};

export const GD_FULL_VIEW_CONFIG = [
    GDCustomDocViewType.DEFAULT_VIEW,
    GDCustomDocViewType.SHARED_WITH_ME_VIEW,
    GDCustomDocViewType.STARRED_VIEW,
    GDCustomDocViewType.SHARED_DRIVE_VIEW,
];

export const GD_DEFAULT_STARRED_EN = 'Starred';

export const GDRIVE_FOLDER_CONFIG: GDPickerConfiguration = {
    // Default config for the picker
    ...(DRIVE_DEFAULT_CONFIG as GDPickerConfiguration),
    // Define the list of views available in the picker
    customDocViews: [
        GDCustomDocViewType.DEFAULT_VIEW,
        GDCustomDocViewType.SHARED_DRIVE_VIEW,
        GDCustomDocViewType.STARRED_VIEW,
    ],
    // Allow for folder selection
    setSelectFolderEnabled: true,
    /**
     * Restrict the picker selection to only allow for google drive folder
     * @see: https://developers.google.com/drive/api/guides/mime-types
     * */
    selectableMimeTypes: ['application/vnd.google-apps.folder'],
};
