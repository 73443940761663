import { NotificationGroup, Notification, NotificationSenderDetails } from '../types';

const mapNotificationGroupToProps = (
    group: NotificationGroup,
): { notification: Notification; senderDetails: NotificationSenderDetails | null } => {
    const firstActions = group.actions && group.actions[0] ? group.actions[0] : null;

    return {
        notification: group.notification,
        senderDetails: firstActions ? firstActions.senderDetails : null,
    };
};

export { mapNotificationGroupToProps };
