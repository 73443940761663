(function IIFE() {
    'use strict';

    /////////////////////////////

    function AdminItemActionsController() {
        'ngInject';

        var vm = this;
    }
    /////////////////////////////

    /**
     * Admin item actions directive.
     * Display given actions for an admin item in the data table or in the admin header.
     *
     * @param {Object} actions The admin item actions separated in primary and secondary sections.
     */

    function AdminItemActionsDirective() {
        return {
            bindToController: true,
            controller: AdminItemActionsController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                actions: '=',
                item: '=',
                viewMode: '@',
            },
            templateUrl: '/client/common/modules/layout/modules/admin/views/admin-item-actions.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('adminItemActions', AdminItemActionsDirective);
})();
