import React from 'react';

import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import { splitArray } from '@lumapps/utils/array/splitArray';

import { DraggableListDisplay, DraggableResult } from '../types';
import { generateUniqueItems, getItemArray, revertUniqueId } from '../utils';

export interface UseDraggable<T> {
    /** The given className to use in the view */
    className: string;
    /** The horizontalSize. Returned to avoid managing the default value in multilpe places */
    horizontalSize: number;
    /** The initial items */
    initialItems: T[];
    /** An empty map to store the ids, that can be used in the hook */
    itemsRef: Map<number, React.RefObject<HTMLElement>>;
    /** The items to use in the draggable list */
    itemsToUse: T[];
    /** The list ids */
    listIds: string[];
    /** The list of items to display */
    listOfItems: T[][];
    /** Callback when the drag ends for DragDropContext */
    onDragEnd: (result: DraggableResult, provided: any) => void;
    /** Callback when the drag starts for DragDropContext */
    onDragStart: (result: DraggableResult, provided: any) => void;
    /** Callback when the drag updates for DragDropContext */
    onDragUpdate: (result: DraggableResult, provided: any) => void;
}

export interface UseDraggableOptions<T> {
    /** Function to call when the drag ends */
    afterDragEnd?: (result: DraggableResult, provided: any, srcIndex: number, destIndex: number) => void;
    /** Function to call when the drag starts */
    afterDragStart?: (result: DraggableResult, provided: any, srcIndex: number) => void;
    /** Function to call when the drag updates */
    afterDragUpdate?: (result: DraggableResult, provided: any, srcIndex: number, destIndex: number) => void;
    /** The className of the view */
    className: string;
    /** The display mode */
    display: DraggableListDisplay;
    /** The droppable id */
    droppableId: string;
    /** The number of items per row in horizontal mode or in grid mode */
    // horizontalSize: number;
    /** The items to use in the draggable list */
    items: T[];
    /** The key prop name */
    keyPropName?: string;
    /** Function to call when the items are moved */
    move: (newItems: T[], srcIndex: number, destIndex: number) => void;
    /** The ref of the parent element */
    parentRef: React.RefObject<HTMLElement>;
    /** Whether the id of the items should be unique */
    shouldEnsureIdUniqueness?: boolean;
    /** The list id prefix */
    listIdPrefix?: string;
}

/**
 * Hook for a draggable list or a draggable grid.
 * Returns:
 *  - the lists of items to display
 *  - the lists ids
 *  - the callback function to pass to DragDropContext
 */
export const useDraggable = <T,>({
    afterDragEnd,
    afterDragStart,
    afterDragUpdate,
    className,
    display,
    droppableId,
    items,
    move,
    keyPropName,
    shouldEnsureIdUniqueness = false,
    listIdPrefix,
}: UseDraggableOptions<T>): UseDraggable<T> => {
    const isHorizontal = display.mode === 'horizontal' || display.mode === 'grid';
    // Default horizontalSize is 8
    const horizontalSize = isHorizontal ? display.size || 8 : 0;
    // Keep last destination updated during drag.
    let lastDestination: DraggableResult['destination'] | null = null;

    const itemsToUse: T[] = React.useMemo(() => {
        return shouldEnsureIdUniqueness ? generateUniqueItems(items, keyPropName) : items;
    }, [items, keyPropName, shouldEnsureIdUniqueness]);

    // When isHorizontal is true, we need to make an array[horizontalSize][], displayed as columns.
    const listOfItems = React.useMemo(() => {
        if (itemsToUse.length === 0) {
            return [];
        }
        if (!isHorizontal) {
            return [itemsToUse];
        }
        return splitArray(itemsToUse, horizontalSize);
    }, [horizontalSize, isHorizontal, itemsToUse]);

    // const totalArrays = listOfItems.length;
    const totalArrays = React.useMemo(() => listOfItems.length, [listOfItems]);

    const listIds = React.useMemo(() => {
        const ids = [];

        for (let i = 0; i < totalArrays; i++) {
            /**
             * the id includes the index of the array in order to easily retrieve it afterwards
             * when the item is moved and the list is in horizontal mode
             */
            const listId = `${uniqueId(listIdPrefix || droppableId)}--${i}`;

            ids.push(listId);
        }

        return ids;
    }, [droppableId, listIdPrefix, totalArrays]);

    const onDragStart = (result: DraggableResult, provided: any) => {
        /**
         * If the draggable list is horizontal, it is composed of multiple arrays, so we need to determine
         * to which array it belongs since indexes are relative to those arrays, and not the original array.
         */
        const sourceArray = isHorizontal ? getItemArray(get(result, 'source.droppableId')) : 0;

        // Source position.
        const srcIndex = get(result, 'source.index') + sourceArray * horizontalSize;

        afterDragStart?.(result, provided, srcIndex);
    };

    const onDragUpdate = (result: DraggableResult, provided: any) => {
        lastDestination = result.destination || lastDestination;
        /**
         * If the draggable list is horizontal, it is composed of multiple arrays, so we need to determine
         * to which array it belongs since indexes are relative to those arrays, and not the original array.
         */
        const sourceArray = isHorizontal ? getItemArray(get(result, 'source.droppableId')) : 0;
        const destinationArray = isHorizontal ? getItemArray(get(result, 'destination.droppableId')) : 0;

        // Source position.
        const srcIndex = get(result, 'source.index') + sourceArray * horizontalSize;
        // Drag end destination or last destination or source.
        const destIndex =
            (result.destination || lastDestination || result.source).index + destinationArray * horizontalSize;

        afterDragUpdate?.(result, provided, srcIndex, destIndex);
    };

    const onDragEnd = React.useCallback(
        (result: DraggableResult, provided: any) => {
            /**
             * If the draggable list is horizontal, it is composed of multiple arrays, so we need to determine
             * to which array it belongs since indexes are relative to those arrays, and not the original array.
             */
            const sourceArray = isHorizontal ? getItemArray(get(result, 'source.droppableId')) : 0;
            const destinationArray = isHorizontal ? getItemArray(get(result, 'destination.droppableId')) : 0;

            // Source position.
            const srcIndex = get(result, 'source.index') + sourceArray * horizontalSize;
            // Drag end destination or last destination or source.
            const destIndex =
                (result.destination || lastDestination || result.source).index + destinationArray * horizontalSize;

            // Changed position.
            if (srcIndex === destIndex) {
                afterDragEnd?.(result, provided, srcIndex, destIndex);
                return;
            }

            // reorder items
            /**
             * If we had to ensure uniqueness, we revert changes so that the items passed to the parent component
             * are the ones they provided.
             */
            const newItems = [
                ...(shouldEnsureIdUniqueness
                    ? itemsToUse.map((item) => revertUniqueId(item, keyPropName))
                    : itemsToUse),
            ];

            const tomove = newItems[srcIndex];
            if (srcIndex > destIndex) {
                // insert at new position
                newItems.splice(destIndex, 0, tomove);
                // remove the old
                newItems.splice(srcIndex + 1, 1);
            } else {
                newItems.splice(destIndex + 1, 0, tomove);
                newItems.splice(srcIndex, 1);
            }

            move(newItems, srcIndex, destIndex);

            afterDragEnd?.(result, provided, srcIndex, destIndex);
        },
        [
            afterDragEnd,
            horizontalSize,
            isHorizontal,
            itemsToUse,
            keyPropName,
            lastDestination,
            move,
            shouldEnsureIdUniqueness,
        ],
    );

    return {
        className,
        horizontalSize,
        initialItems: items,
        itemsRef: new Map(),
        itemsToUse,
        listIds,
        listOfItems,
        onDragEnd,
        onDragStart,
        onDragUpdate,
    };
};
