import React from 'react';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useSocialNetworksWithAccess } from '../../hooks/useSocialNetworks';
import { ConfirmDisconnectDialog } from './ConfirmDisconnectDialog';
import { SocialProfiles } from './SocialProfiles';

const CLASSNAME = 'sa-social-profiles';
const ConnectedSocialProfiles: React.FC = () => {
    const {
        status,
        socialNetworks,
        fetchWithStatusChange,
        onSocialNetworkClick,
        disconnect,
        confirmDisconnectSocialNetwork,
        toggleConfirmDisconnect,
        connectingStatus,
        disconnectingStatus,
    } = useSocialNetworksWithAccess();

    return (
        <>
            <ConfirmDisconnectDialog
                disconnect={disconnect}
                confirmDisconnectSocialNetwork={confirmDisconnectSocialNetwork}
                toggleConfirmDisconnect={toggleConfirmDisconnect}
                className={CLASSNAME}
            />

            <SocialProfiles
                socialNetworks={socialNetworks}
                status={status}
                onSocialNetworkClick={onSocialNetworkClick}
                onRetry={() => fetchWithStatusChange(true)}
                className={CLASSNAME}
                isOperationInProcess={
                    connectingStatus === BaseLoadingStatus.loading || disconnectingStatus === BaseLoadingStatus.loading
                }
            />
        </>
    );
};

export { ConnectedSocialProfiles as SocialProfiles };
