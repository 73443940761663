/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { learningApi } from '@lumapps/learning/constants';

import { LearningRolesData, LaunchUrlData } from '../types';

/**
 * getLearningRoles API to retrieve roles data of learning user.
 *
 */
const LearningRolesApi = () =>
    new BaseApi({
        version: BaseApi.versions.v2,
        path: 'learning',
        baseURL: learningApi.URL,
    });
const paths = {
    roles: 'identity/v2/users/current/roles/codes',
    launchUrl: 'identity/v2/authentication/mc/autologin',
};

export async function getLearningRoles(): Promise<LearningRolesData> {
    const { data } = await LearningRolesApi().get<LearningRolesData>(paths.roles);
    return data;
}

export async function getMCLaunchUrl(): Promise<LaunchUrlData> {
    const { data } = await LearningRolesApi().get<LaunchUrlData>(paths.launchUrl);
    return data;
}
