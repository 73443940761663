import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Image } from '@lumapps/lumx-images/types';
import { first } from '@lumapps/utils/iterable/first';
import { ImageEditor, ImageEditorOptions, ImageGroupElement } from '@lumapps/wrex-enhanced-image/types';
import { createImageGroup } from '@lumapps/wrex-enhanced-image/utils/createImageGroup';
import { isImageGroup } from '@lumapps/wrex-enhanced-image/utils/isImageGroup';
import { Editor, NodeEntry, Point, Range, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isSelectionEmpty } from '@lumapps/wrex/slate/utils/isSelectionEmpty';
import { WrexEditor } from '@lumapps/wrex/types';

import { IMAGE_GALLERY_VIEW_MODE } from '../constants';
import { uploadImageGallery } from './uploadImageGallery';

const insertImageGalleryNode = (
    editor: WrexEditor<ImageEditor>,
    attributes: Partial<ImageGroupElement>,
    point?: Point,
) => {
    const at = point || editor.selection?.focus;
    const after = getSiblingPath([get(at, ['path', 0]) as number], 'after');
    const canInsertOnSelection = at && isSelectionEmpty(editor, at.path);

    const node = createImageGroup({
        images: [],
        ...attributes,
        viewMode: IMAGE_GALLERY_VIEW_MODE.mosaic,
    });

    Transforms.insertNodes(editor, node, {
        at: canInsertOnSelection ? [at.path[0]] : after ?? [editor.children.length],
        select: true,
    });

    const [, firstPath] = first(
        Editor.nodes(editor, { at: editor.selection as Range, match: isImageGroup }),
    ) as NodeEntry;

    const path = canInsertOnSelection ? [at.path[0]] : firstPath;

    focusAt(editor, path);

    return path;
};

/**
 * Insert an image gallery after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export async function insertImageGallery(
    options: ImageEditorOptions,
    editor: WrexEditor<ImageEditor>,
    images: Image[],
    point?: Point | undefined,
) {
    if (isEmpty(images)) {
        return undefined;
    }

    const shouldUpload = !!images.filter((image) => !!image.file);

    if (shouldUpload) {
        const fakeImages = images.map(() => ({ src: '' }));
        const path = insertImageGalleryNode(editor, { images: fakeImages, isLoading: true }, point);

        const imageElements = await uploadImageGallery(options, editor, images);

        Transforms.setNodes(editor, { images: imageElements, isLoading: false } as Partial<ImageGroupElement>, {
            at: path,
        });

        return path;
    }

    const imageElements = await uploadImageGallery(options, editor, images);

    if (!isEmpty(imageElements)) {
        return insertImageGalleryNode(editor, { images: imageElements, isLoading: false }, point);
    }

    return undefined;
}
