import React from 'react';

import range from 'lodash/range';

import { useClassnames } from '@lumapps/classnames';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { Size, SkeletonTypography, SkeletonCircle, SideNavigationItem, FlexBox } from '@lumapps/lumx/react';

import { CLASSNAME } from '../SideNavigationItem';

// Variable lengths that we loop into to make skeletons looks like text
const LENGTHS = [Size.xl, Size.l] as const;

export interface SideNavigationSkeletonItemsProps {
    /** total of skeletons to display */
    count?: number;
}

/**
 * Display multiple skeleton SideNavigationItem
 */
export const SideNavigationSkeletonItems: React.FC<SideNavigationSkeletonItemsProps> = ({ count = 3 }) => {
    const { block } = useClassnames(CLASSNAME);

    return (
        <>
            {range(count).map((index) => {
                const width = REAL_SIZE_FOR_LUMX_SIZE[LENGTHS[index % LENGTHS.length]];

                // Icon + Text skeleton
                const label = (
                    <FlexBox orientation="horizontal" gap="big">
                        <SkeletonCircle size={Size.s} />

                        <SkeletonTypography typography="subtitle2" width={width} />
                    </FlexBox>
                ) as any;

                return (
                    <SideNavigationItem
                        className={block({ skeleton: true })}
                        key={index}
                        label={label}
                        // Won't ever be displayed
                        toggleButtonProps={{ label: '' }}
                        linkProps={{ 'aria-busy': true }}
                    />
                );
            })}
        </>
    );
};
