import { isFeatureEnabled } from '@lumapps/features';
import { isSuperAdmin, permissionsSelector } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BackOfficeStore } from '@lumapps/redux/types';

import { ASK_AI_ALPHA_FF, ASK_AI_BETA_FF, ASK_AI_FF } from '../constants';

/** Check whether the AskAI FF is enabled in the store */
export const isAskAIEnabled = isFeatureEnabled(ASK_AI_FF);

/** Check wether the AskAi alpha FF is enabled */
export const isAskAiAlphaEnabled = isFeatureEnabled(ASK_AI_ALPHA_FF);

/** Check wether the AskAi beta FF is enabled */
export const isAskAiBetaEnabled = isFeatureEnabled(ASK_AI_BETA_FF);

/**
 * Retrieves whether the user can access the ask ai admin.
 */
export const canAccessAskAiAdmin = (state: BackOfficeStore) => isAskAIEnabled(state) && isSuperAdmin(state);
/**
 * Is the user allowed to use ask ai?
 */
export const canUseAskAiFeature = createSelector(permissionsSelector, isAskAIEnabled, (permissions, isAskEnabled) => {
    // Is ask ai feature enabled AND a configration created for the user?
    return isAskEnabled && permissions.authorizations.canReadAiAugmentedSearch;
});

/**
 * Retrieve whether the user can access tha ask ai admin features that are under development
 */
export const canAccessAskAiAdminAlphaFeatures = createSelector(
    canAccessAskAiAdmin,
    isAskAiAlphaEnabled,
    (canAccessAskAiAdmin, isAskAiAlphaEnabled) => canAccessAskAiAdmin && isAskAiAlphaEnabled,
);

/**
 * Retrieve whether the user can access tha ask ai admin features that are under development
 */
export const canAccessAskAiAdminBetaFeatures = createSelector(
    canAccessAskAiAdmin,
    isAskAiBetaEnabled,
    (canAccessAskAiAdmin, isAskAiBetaEnabled) => canAccessAskAiAdmin && isAskAiBetaEnabled,
);
