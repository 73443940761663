import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';

import { isText } from '../../../slate/utils/isText';
import type { Document } from '../../../types/core';

export const isRichTextContentEmpty = (richText: Document) => {
    const body = richText.children;

    if (!body) {
        return true;
    }

    // If we have more than one child then the body is not empty
    if (body.length > 1) {
        return false;
    }

    // If the body has no children then the content is empty
    if (body.length === 0) {
        return true;
    }

    // If the first child is not a paragraph then the body is not empty
    if (!isParagraph(body[0])) {
        return false;
    }

    // If the first child (which is a paragraph here) has more than one child then the body is not empty
    if (body[0].children.length > 1) {
        return false;
    }

    // If the first child of the paragraph has no children then the body is empty
    if (body[0].children.length === 0) {
        return true;
    }

    // If the first child of this paragraph is not a text then the body is not empty
    if (!isText(body[0].children[0])) {
        return false;
    }

    // If this is a text and its value is not an empty string, then the body is not empty
    if (body[0].children[0].text !== '') {
        return false;
    }

    return true;
};
