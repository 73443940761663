import { get } from '@lumapps/constants';
import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { getDefaultUserDirectoryAttributes } from '@lumapps/user-directory/ducks/selectors';
import { connectedUserSelector, hasAcceptedCookiesSelector } from '@lumapps/user/ducks/selectors';

import { Metrics } from './Metrics';

const { isDev } = get();

const mapStateToProps = (state: FrontOfficeStore) => ({
    isPendoEnabled: isFeatureEnabled('pendo-analytics')(state) && !isDev,
    customer: customerSelector(state),
    instance: getCurrentInstance(state),
    user: connectedUserSelector(state),
    currentLanguage: currentLanguageSelector(state),
    customVisitorAttributes: getDefaultUserDirectoryAttributes(state),
    hasAcceptedCookies: hasAcceptedCookiesSelector(state),
});

const ConnectedComponent = connect(mapStateToProps)(Metrics);

export { ConnectedComponent as Metrics, mapStateToProps };
