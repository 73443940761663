import memoize from 'lodash/memoize';

import { createSelector } from '@lumapps/redux/reselect';

import { Program } from '../../types';
import { saProgramsSelector } from '../selectors';
import { SAProgramState } from '../types';
import { UserProgramsState } from './slice';

const userProgramsSelector = createSelector(
    saProgramsSelector,
    (saPrograms: SAProgramState): UserProgramsState => saPrograms.userPrograms || {},
);

const userProgramsStatusSelector = createSelector(
    userProgramsSelector,
    (programs: UserProgramsState): UserProgramsState['userProgramsStatus'] => programs.userProgramsStatus,
);

const fetchSingleProgramStatusSelector = createSelector(
    userProgramsSelector,
    (programs: UserProgramsState): UserProgramsState['fetchSingleProgramStatus'] => programs.fetchSingleProgramStatus,
);

const userProgramsListSelector = createSelector(
    userProgramsSelector,
    (programs: UserProgramsState): UserProgramsState['entities'] => programs.entities,
);

const makeUserProgramByIdSelector = createSelector(
    userProgramsListSelector,
    (userPrograms: UserProgramsState['entities']) =>
        memoize((programId: Program['id']): Program | undefined =>
            userPrograms.find((program) => program.id === programId),
        ),
);

const makeUserProgramBySlugSelector = createSelector(userProgramsListSelector, (userPrograms: Program[]) =>
    memoize((slug: string): Program | undefined =>
        userPrograms.find((program) => Object.values(program.slug.translations).includes(slug)),
    ),
);

export {
    userProgramsSelector,
    userProgramsStatusSelector,
    userProgramsListSelector,
    makeUserProgramBySlugSelector,
    makeUserProgramByIdSelector,
    fetchSingleProgramStatusSelector,
};
