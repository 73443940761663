export const USER_BOOKMARKS_SCOPE = 'user-bookmarks';
export const USER_BOOKMARKS_RESOURCE_TYPE = {
    custom: 'custom',
    content: 'content',
    post: 'post',
    community: 'community',
    video: 'play_video',
} as const;

/** Array of all user bookmarks resource types */
export const ALL_USER_BOOKMARKS_RESOURCE_TYPES = Object.values(USER_BOOKMARKS_RESOURCE_TYPE);

/** Array of all available user bookmarks resource (all except "custom") */
export const AVAILABLE_USER_BOOKMARK_RESOURCES = ALL_USER_BOOKMARKS_RESOURCE_TYPES.filter(
    (type) => type !== USER_BOOKMARKS_RESOURCE_TYPE.custom,
);

export const MAX_USER_BOOKMARK_RESULT = '30';
