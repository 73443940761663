/* istanbul ignore file */
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Metadata } from '../types';
import { State } from './types';

const initialState: State = {
    entities: {},
    families: {},
};

export const { actions, reducer } = createSlice({
    domain: 'metadata',
    initialState,
    reducers: {
        fetchMetadataListSuccess(state: State, { payload }: PayloadAction<{ metadata: Metadata[] }>) {
            Object.assign(state.entities, keyBy(payload.metadata, 'uid'));
        },
        fetchMetadataFamiliesSuccess(state: State, { payload }: PayloadAction<{ metadata: Metadata[] }>) {
            const metadataById = keyBy(payload.metadata, 'uid');
            const families = groupBy(payload.metadata, 'familyKey');
            Object.assign(state.entities, metadataById);
            Object.assign(state.families, mapValues(families, 'uid'));
        },
    },
});
