import BaseApi from '@lumapps/base-api';

/**
 * Custom content type API.
 */
const customContentTypeApi = new BaseApi({ path: 'customcontenttype' });

const listCustomContentType = (params) => customContentTypeApi.get('list', { params });

export { listCustomContentType };
