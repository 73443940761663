export enum WREX_LINK {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40496627
     */
    LINK_BUTTON_TOOLTIP = 'FRONT.TEXT_EDITOR.FORMAT.LINK_BUTTON',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=354818909
     */
    LINK_BUTTON_LABEL = 'WREX_LINK.INSERT_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470896369
     */
    LINK_BUTTON_WITH_SHORTCUT = 'WREX_LINK.LINK_BUTTON_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452543
     */
    LINK_LABEL = 'COMMUNITY_POST_LINK_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=161108713
     */
    LINK_REMOVE = 'COMMUNITY_POST_LINK_REMOVE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452544
     */
    LINK_URL = 'COMMUNITY_POST_LINK_URL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=179983707
     */
    UNAVAILABLE_LINK = 'TEXT_EDITOR.UNAVAILABLE_LINK',
}
