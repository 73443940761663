import React, { useCallback } from 'react';

import { useSelector, useDispatch } from '@lumapps/redux/react';
import { isSAEnabled } from '@lumapps/sa-connected-user/ducks/selectors';

import {
    ContentActionStatus,
    fetchContentActions,
    makeContentActionsByContentIdSelector,
    contentActionsStatusSelector,
    contentActionsCanMakeShareableOnSelector,
    contentActionsCanBeSharedOnSelector,
    allProgramsShareableOn,
} from '../ducks/ContentActions';

/**
 * useContentActions
 *
 * This hook will :
 * * Dispatch the thunk to get the action of a content if it hasn't been fetched yet.
 * * Return the fetch status (initial / loading / success / failed).
 * * Return the programs the content can be shareable on.
 * * Return the programs the content can be shared on, by social networks.
 * * Return an array of all the programs the content can be shared on, regardless of social networks.
 * * Return the function to fetch the content actions.
 *
 * @param contentId The Lumapps content to fetch the actions for.
 * @param autoFetch Whether the content should be automatically fetched or not.
 */
export const useContentActions = (contentId: string, autoFetch = true) => {
    const dispatch = useDispatch();
    const saEnabled = useSelector(isSAEnabled);

    const contentActionsSelector = useSelector(makeContentActionsByContentIdSelector);
    const contentActions = contentActionsSelector(contentId);

    const status = contentActionsStatusSelector(contentActions);

    /**
     * The fetch function.
     * Will only dispatch if a contentId is set
     */
    const handleFetchContent = useCallback(() => {
        if (saEnabled && contentId) {
            dispatch(fetchContentActions(contentId));
        }
    }, [contentId, dispatch, saEnabled]);

    /**
     * If autoFetch is true and the actions haven't been fetched yet
     * Fetch
     */
    React.useEffect(() => {
        if (status === ContentActionStatus.initial && autoFetch) {
            handleFetchContent();
        }
    }, [status, autoFetch, handleFetchContent]);

    return {
        contentActions,
        status,
        canMakeShareableOn: saEnabled ? contentActionsCanMakeShareableOnSelector(contentActions) : [],
        canBeSharedOn: contentActionsCanBeSharedOnSelector(contentActions),
        allProgramsShareableOn: saEnabled ? allProgramsShareableOn(contentActions) : [],
        fetchContentActions: handleFetchContent,
    };
};
