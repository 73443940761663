import { AnchorHTMLAttributes, useEffect, useMemo, useState } from 'react';

import { useHomeRoute } from '@lumapps/contents/hooks/useHomeRoute';
import { getCurrentInstance, getInstanceById } from '@lumapps/instance/ducks/selectors';
import { getLanguageHeader } from '@lumapps/languages';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useRouter } from '@lumapps/router';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { useTranslate } from '@lumapps/translations';

import { CommunityLinkRef } from '../types';
import { getCommunityLinkProps } from '../utils/community-link/getCommunityLinkProps';
import { updateCommunityLinkFromState } from '../utils/community-link/updateCommunityLinkFromState';
import { watchUpdateLink } from '../utils/community-link/watchUpdateLink';

interface Output {
    props: AnchorHTMLAttributes<HTMLAnchorElement>;
    ref: (anchor: HTMLAnchorElement | null) => void;
    contentLinkRef: CommunityLinkRef;
}

export function useCommunityLink(communityLinkRef: CommunityLinkRef): Output {
    const [anchor, setAnchor] = useState<HTMLAnchorElement | null>(null);
    const { getUrl, redirect } = useRouter();
    const { id: currentInstanceId } = useSelector(getCurrentInstance);
    const languageHeader = useSelector(getLanguageHeader);
    const dispatch = useDispatch();
    const { translateObject } = useTranslate();
    const instanceById = useSelector(getInstanceById) || {};
    const { isSpaceFeatureEnabled } = useSpace();
    const homeRoute = useHomeRoute();

    // Update content link ref from redux state (if possible).
    const updatedCommunityRef = useMemo(
        () =>
            updateCommunityLinkFromState(communityLinkRef, translateObject, {
                currentInstanceId,
                instanceById,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            instanceById,
            translateObject,
            // We'll only update this if the content id changes to prevent unnecessary re-render
            communityLinkRef.id,
        ],
    );

    // Watch the content link activation (click/enter) & update if needed
    useEffect(
        () => {
            // If there is no anchor attached, we do not watch for update
            if (!anchor) {
                return undefined;
            }
            return watchUpdateLink(anchor, updatedCommunityRef, {
                languageHeader,
                currentInstanceId,
                isSpaceFeatureEnabled,
                homeRoute,
                dispatch,
                translateObject,
                redirect,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            anchor,
            currentInstanceId,
            dispatch,
            languageHeader,
            redirect,
            translateObject,
            // We'll only update this if the content id changes to prevent unnecessary re-render
            updatedCommunityRef.id,
        ],
    );

    // Generate content link props.
    const props = useMemo(
        () => getCommunityLinkProps(updatedCommunityRef, { getUrl, isSpaceFeatureEnabled, homeRoute }),
        [getUrl, updatedCommunityRef, isSpaceFeatureEnabled, homeRoute],
    );
    return { props, ref: setAnchor, contentLinkRef: updatedCommunityRef };
}
