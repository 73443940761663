import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const broadcastBaseRoute: Route = {
    path: '/admin/broadcasts',
    appId: AppId.backOffice,
    legacyId: 'app.admin.broadcasts',
};

export const broadcastGlobalSettingsRoute: Route = {
    ...broadcastBaseRoute,
    path: `${broadcastBaseRoute.path}/settings`,
    legacyId: `${broadcastBaseRoute.legacyId}-settings`,
};

export const broadcastListRoute: Route = {
    path: `${broadcastBaseRoute.path}/list`,
    appId: AppId.backOffice,
    legacyId: 'app.admin.broadcasts',
};

export interface BroadcastListParams {
    title?: string;
    statuses?: string[];
    scheduledAtOrSentAtMin?: string;
    scheduledAtOrSentAtMax?: string;
}

export const broadcastList = (query?: BroadcastListParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    if (query?.statuses) {
        queryParams.statuses = query.statuses.join(',');
    }

    if (query?.scheduledAtOrSentAtMin) {
        queryParams.scheduledAtOrSentAtMin = query.scheduledAtOrSentAtMin;
    }
    if (query?.scheduledAtOrSentAtMax) {
        queryParams.scheduledAtOrSentAtMax = query.scheduledAtOrSentAtMax;
    }

    return {
        ...broadcastListRoute,
        query: queryParams,
    };
};

export const broadcastTimelineRoute: Route = {
    ...broadcastBaseRoute,
    path: `${broadcastBaseRoute.path}/timeline`,
};

export interface BroadcastTimelineParams {
    title?: string;
    statuses?: string[];
    focusDate?: string;
}

export const broadcastTimeline = (query?: BroadcastTimelineParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    if (query?.statuses) {
        queryParams.statuses = query.statuses.join(',');
    }

    if (query?.focusDate) {
        queryParams.focusDate = query.focusDate;
    }

    return {
        ...broadcastTimelineRoute,
        query: queryParams,
    };
};

export interface BroadcastCreateParams {
    campaignId?: string;
}

export const broadcastCreateRoute: Route = {
    appId: AppId.backOffice,
    path: `${broadcastBaseRoute.path}/create`,
};

export const broadcastCreate = (queryParams: BroadcastCreateParams): Route => {
    return {
        ...broadcastCreateRoute,
        ...(queryParams?.campaignId && {
            query: {
                campaignId: queryParams.campaignId,
            },
        }),
    };
};

export interface BroadcastViewEditParams {
    id: string;
}

export const broadcastEditRoute: Route = {
    appId: AppId.backOffice,
    path: `${broadcastBaseRoute.path}/edit/:id`,
};

export const broadcastEdit = (params: BroadcastViewEditParams): Route => {
    return {
        ...broadcastEditRoute,
        params: {
            id: params.id,
        },
    };
};

export enum BroadcastViewViews {
    summary = 'summary',
    insights = 'insights',
}

export interface BroadcastViewParams extends BroadcastViewEditParams {
    view?: BroadcastViewViews;
}

export const broadcastViewRoute: Route = {
    appId: AppId.backOffice,
    path: `${broadcastBaseRoute.path}/view/:id/:view?`,
};

export const broadcastView = (params: BroadcastViewParams): Route => {
    return {
        ...broadcastViewRoute,
        params: {
            id: params.id,
            view: params.view || BroadcastViewViews.summary,
        },
    };
};

export const broadcastSummary = (params: BroadcastViewParams): Route => {
    return broadcastView({ ...params, view: BroadcastViewViews.summary });
};

export const broadcastInsights = (params: BroadcastViewParams): Route => {
    return broadcastView({ ...params, view: BroadcastViewViews.insights });
};
