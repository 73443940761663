import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import { height, width, containsNode, offset } from '../../../utils';
import * as uiTypes from '../types';

/**
 * Renders a drop down menu. See <Select /> to get a menu.
 */
class DropDown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dropDownEl: null,
            dropDownWidth: null,
            dropDownHeight: null,
            isOpen: false,
            isUpward: false,
        };

        this.onGlobalClick = this.onGlobalClick.bind(this);
        this.defineDirection = this.defineDirection.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setDropDown = this.setDropDown.bind(this);
    }

    /**
     * On update, checks if the dropdown `width` changed.
     * If so, updates the state with the new `width`, which is provided to
     * the dropdown menu `<div />`.
     */
    componentDidUpdate() {
        const { dropDownEl, dropDownWidth: currentDropDownWidth } = this.state;

        if (!dropDownEl) {
            return;
        }
        const dropDownWidth = width(dropDownEl);

        if (currentDropDownWidth === dropDownWidth) {
            return;
        }

        this.setState({ dropDownWidth });
    }

    /**
     * Global click handler used to close the dropdown.
     *
     * @param {Object} evt The event.
     */
    onGlobalClick(evt) {
        const { dropDownEl } = this.state;

        if (!containsNode(dropDownEl, evt.target)) {
            this.toggle(false)();
        }
    }

    /**
     * Defines whether the dropdown should be open upward or downward
     * regarding its visibility.
     */
    defineDirection() {
        const { dropDownEl, isUpward } = this.state;
        const dropDownDomElement = dropDownEl.querySelector('.dropdown-menu');

        if (!dropDownDomElement) {
            return;
        }

        const parentHeight = height(dropDownEl);
        const dropDownMenuOffset = offset(dropDownDomElement);

        const spaceAtTheBottom =
            document.documentElement.clientHeight - dropDownMenuOffset.top - dropDownMenuOffset.height - parentHeight;

        const spaceAtTheTop = dropDownMenuOffset.top - parentHeight;

        const shouldBeUpward = spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom;

        if (shouldBeUpward !== isUpward) {
            this.setState({ isUpward: shouldBeUpward });
        }

        // Define the dropdownHeight from the available space at the bottom.
        const computedBottomSpace = Math.round(
            document.documentElement.clientHeight - dropDownMenuOffset.top - parentHeight,
        );

        this.setState({ dropDownHeight: shouldBeUpward ? spaceAtTheTop : computedBottomSpace });
    }

    /**
     * Handle key press.
     *
     * @param {Event} evt Key press event.
     */
    handleKeyPress(evt) {
        // 'Enter' key.
        if (evt.keyCode === 13) {
            this.toggle(true);
        }
    }

    /**
     * Toggles the dropdown menu.
     * When open, registers the global click listener.
     * When closed, unregisters it.
     *
     * @param  {boolean}  auto Flag which indicates that the toogle is requested by the dom element.
     * @return {Function} The actual toggle function call.
     */
    toggle(auto) {
        return () => {
            const { hasPreventAutoClose } = this.props;
            const { isOpen } = this.state;

            if (auto && isOpen && hasPreventAutoClose) {
                return;
            }

            global.document[isOpen ? 'removeEventListener' : 'addEventListener']('click', this.onGlobalClick, true);

            this.setState({ isOpen: !isOpen }, this.defineDirection);
        };
    }

    /**
     * Stores the reference of the drop-down, along with its width.
     *
     * @param  {Object} node The drop-down element.
     * @return {void}   Nothing.
     */
    setDropDown(node) {
        return node && this.setState({ dropDownEl: node, dropDownWidth: width(node) });
    }

    render() {
        const { toggle, children, align = 'left' } = this.props;
        const { isOpen, isUpward, dropDownWidth, dropDownHeight } = this.state;

        return (
            <div
                ref={this.setDropDown}
                className={classNames('dropdown', toggle && 'dropdown--has-toggle', isOpen && 'dropdown--is-open')}
                role="presentation"
                onClick={this.toggle(true)}
                onKeyPress={this.handleKeyPress}
            >
                {toggle && <div className="dropdown-toggle">{toggle}</div>}
                {isOpen && (
                    <div
                        className="dropdown-menu"
                        style={{
                            [align]: 0,
                            bottom: isUpward && align !== 'bottom' && '100%',
                            opacity: 1,
                            position: 'absolute',
                            visibility: 'visible',
                            zIndex: 1000,
                        }}
                    >
                        <div
                            className="dropdown-menu__content"
                            style={{
                                // Cuts the fifth item in half to show users that they can scroll to see more items.
                                maxHeight: dropDownHeight,
                                minWidth: dropDownWidth,
                                opacity: 1,
                                position: 'relative',
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

DropDown.propTypes = {
    /** Alignment of the dropdown panel. */
    align: uiTypes.position.isRequired,
    /** Content of the dropdown panel that is visible when `open`. */
    children: propTypes.node.isRequired,
    /** Prevent close on item clicked - useful for the multiselect behavior. */
    hasPreventAutoClose: propTypes.bool,
    /** Visible when the dropdown is closed. */
    toggle: propTypes.node,
};

DropDown.defaultProps = {
    hasPreventAutoClose: false,
    toggle: null,
};

export { DropDown };
