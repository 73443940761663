import { Editor, Node, NodeEntry, Element, Transforms } from '..';
import { MatchFn } from './matchFn';

const DEFAULT_MODE = 'split';

/**
 * This normalize function will lift the node to the top level of the editor.
 * It will split the root parent by default, but also can be inserted after or before
 * by changing the `options.mode`
 */
export function normalizeLiftToRootNode<EdType = Editor>(
    /** The target editor */
    editor: Editor & EdType,
    /** The node entry on which the normalization is running. */
    [node, path]: NodeEntry,
    /** The match function to check for, if not met, the normalization won't apply */
    match: MatchFn<Node>,
    /** Options to configure the normalization. */
    options?: {
        /**
         * The mode to use for the transformation:
         * - `split` splits the root parent on the position of the node.
         * - `before` moves the node before the root parent.
         * - `after` moves the node after the root parent.
         *
         * default value: `split`
         */
        mode?: 'before' | 'split' | 'after';
    },
) {
    if (Element.isElement(node) && Editor.isBlock(editor, node) && match(node) && path.length > 1) {
        if ((options?.mode || DEFAULT_MODE) === 'split') {
            Transforms.liftNodes(editor, {
                at: path,
                match,
                mode: 'lowest',
            });
        } else {
            Transforms.moveNodes(editor, { at: path, to: [path[0] + 1], match });
        }
    }
}
