import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function CommunityTemplateService(Community, InitialSettings, User) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The template widgets default extend configurations.
         *
         * @type {Object}
         */
        var _defaultWidgetConfig = {
            CALENDAR: {
                isMainWidget: true,
                properties: {
                    currentCommunityCalendar: true,
                    displayToolbar: true,
                    endTime: 19,
                    startTime: 8,
                    visualizationMode: 'week',
                    isCollapsible: false, 
                },
            },
            COMMUNITY_INTRO: {
                properties: {
                    marginTop: 0,
                    isCollapsible: false,
                },
            },
            COMMUNITY_NAVIGATION: {
                title: {},
                properties: {
                    isCollapsible: false,
                },
            },
            CONTENT_FILTER: {
                properties: {
                    hideSubheader: true,
                    linkedToLocalWidget: true,
                    viewMode: 'condensed',
                    isCollapsible: false,
                },
                title: {},
            },
            FEATURED_IMAGE: {
                properties: {
                    marginLeft: -24,
                    marginRight: -24,
                    marginTop: -12,
                    isCollapsible: false,
                },
            },
            FILE_LIST: {
                properties: {
                    // Scope widget on current community Drive and enable file management options by default.
                    canManageFiles: true,
                    currentCommunityDrive: true,
                    displayType: 'community-folder',
                    isCollapsible: false,
                },
            },
            POST_DETAILS: {
                isMainWidget: true,
                properties: {
                    isCollapsible: false,
                },
            },
            POST_LIST: {
                properties: {
                    displayType: 'current',
                    isCollapsible: false,
                    isContributionFieldDisplayed: true,
                },
            },
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The available page key that started the swap.
         *
         * @type {string}
         */
        service.origin = undefined;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the default widget configuration based on a widget type.
         *
         * @param  {string} type The type of widget to add.
         * @return {Object} The default widget configuration.
         */
        function _getWidgetDefaultConfig(type) {
            var widgetTpl = {
                properties: {
                    isCollapsible: false,
                },
                type: 'widget',
                uuid: generateUUID(),
                widgetType: InitialSettings.WIDGET_TYPES[type],
            };

            // Return a copy, if the user want to manipulate the element it doesn't update the default config.
            return angular.fastCopy(angular.extend(angular.fastCopy(widgetTpl), _defaultWidgetConfig[type]));
        }

        /**
         * Get the default sidebar template.
         *
         * @param  {boolean} filter If the filter widget if present.
         * @return {Object}  The default sidebar template.
         */
        function _getSidebar(filter) {
            // TODO [max]: could be great to have a more dynamic filter (not a bool but a list of allowed widget).
            filter = Boolean(filter);
            var components = [
                _getWidgetDefaultConfig('FEATURED_IMAGE'),
                _getWidgetDefaultConfig('COMMUNITY_MEMBERS'),
                _getWidgetDefaultConfig('TITLE'),
                _getWidgetDefaultConfig('COMMUNITY_INTRO'),
                _getWidgetDefaultConfig('META_SOCIAL'),
                _getWidgetDefaultConfig('COMMUNITY_NAVIGATION'),
            ];

            if (filter) {
                components.push(_getWidgetDefaultConfig('CONTENT_FILTER'));
            }

            return {
                components: components,
                properties: {
                    paddingBottom: 12,
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 12,
                    plain: true,
                    sticky: true,
                },
                type: 'cell',
                uuid: generateUUID(),
                width: 3,
            };
        }

        /**
         * Get the default calendar template.
         *
         * @return {Object} The default calendar template.
         */
        function _buildCalendarTemplate() {
            return {
                components: [
                    {
                        cells: [
                            _getSidebar(),
                            {
                                components: [_getWidgetDefaultConfig('CALENDAR')],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 9,
                            },
                        ],
                        type: 'row',
                        uuid: generateUUID(),
                    },
                ],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /**
         * Get the default dashboard template.
         *
         * @return {Object} The default dashboard template.
         */
        function _buildDashboardTemplate() {
            return {
                components: [
                    {
                        cells: [
                            _getSidebar(),
                            {
                                components: [],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 9,
                            },
                        ],
                        type: 'row',
                        uuid: generateUUID(),
                    },
                ],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /**
         * Get the default media template.
         *
         * @return {Object} The default media template.
         */
        function _buildMediaTemplate() {
            return {
                components: [
                    {
                        cells: [
                            _getSidebar(),
                            {
                                components: [_getWidgetDefaultConfig('FILE_LIST')],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 9,
                            },
                        ],
                        type: 'row',
                        uuid: generateUUID(),
                    },
                ],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /**
         * Get the default post template.
         *
         * @return {Object} The default post template.
         */
        function _buildPostTemplate() {
            return {
                components: [
                    {
                        cells: [
                            _getSidebar(),
                            {
                                components: [_getWidgetDefaultConfig('POST_DETAILS')],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 9,
                            },
                        ],
                        type: 'row',
                        uuid: generateUUID(),
                    },
                ],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /**
         * Get the default post list template.
         *
         * @return {Object} The default post list template.
         */
        function _buildPostsTemplate() {
            var mainPostsWidget = _getWidgetDefaultConfig('COMMUNITY_POST_LIST');
            mainPostsWidget.isMainWidget = true;
            mainPostsWidget.properties.viewMode = 'cascade';
            mainPostsWidget.properties.viewModeVariant = 'ungroup';
            mainPostsWidget.properties.columnCount = 2;

            var pinnedPostsWidget = _getWidgetDefaultConfig('COMMUNITY_POST_LIST');
            pinnedPostsWidget.properties.pinnedOnly = true;
            pinnedPostsWidget.properties.isContributionFieldDisplayed = false;
            pinnedPostsWidget.properties.viewMode = 'grid';
            pinnedPostsWidget.properties.viewModeVariant = 'ungroup';
            pinnedPostsWidget.properties.itemsPerLine = 3;
            pinnedPostsWidget.properties.slideshowEnabled = true;

            return {
                components: [
                    {
                        cells: [
                            _getSidebar(true),
                            {
                                components: [pinnedPostsWidget, mainPostsWidget],
                                type: 'cell',
                                uuid: generateUUID(),
                                width: 9,
                            },
                        ],
                        type: 'row',
                        uuid: generateUUID(),
                    },
                ],
                fixedLayout: false,
                fixedWidgets: false,
            };
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Return a page template with the default widget bind to it.
         *
         * @param  {string} templateName The template page name.
         * @return {Object} Default template.
         */
        function getDefaultPageTemplate(templateName) {
            var template;

            switch (templateName) {
                case Community.availableViews.post.id:
                    template = _buildPostTemplate();
                    break;

                case Community.availableViews.calendar.id:
                    template = _buildCalendarTemplate();
                    break;

                case Community.availableViews.media.id:
                    template = _buildMediaTemplate();
                    break;

                case Community.availableViews.dashboard.id:
                    template = _buildDashboardTemplate();
                    break;

                case Community.availableViews.posts.id:
                default:
                    template = _buildPostsTemplate();
                    break;
            }

            template.functionalInnerId = templateName;

            return template;
        }

        /**
         * Get a template from a community.
         *
         * @param  {Object} community The community to get templates from.
         * @param  {string} target    The template name/functionnalInnerId.
         * @return {Object} The wanted template.
         */
        function getTemplateByName(community, target) {
            return _.find(community.templates, {
                functionalInnerId: target,
            });
        }

        /**
         * Reset the 'swap' momentum.
         *
         * @param {Object} [community] If set reset the template value.
         */
        function reset(community) {
            service.origin = undefined;

            if (community) {
                community.template = {};
            }
        }

        /**
         * Set wanted template as `main` so take template in properties and set it in community.template (temporary).
         *
         * @param {Object} community The community to manipulate.
         * @param {string} orig      The origin template name.
         */
        function setAsCurrent(community, orig) {
            // Keep origin (as an index so we can access it from the outside).
            service.orig = orig;

            if (angular.isUndefined(community) && orig) {
                return;
            }

            community.template = service.getTemplateByName(community, service.orig) || {};
        }

        /**
         * Save the template into the origin and reset.
         *
         * @param {Object} community The community to manipulate.
         */
        function swapBack(community) {
            if (angular.isDefined(community)) {
                service.updateTemplate(community);

                service.reset(community);
            }
        }

        /**
         * Update the templates.
         * Swap the current template with the one matching in templates.
         *
         * @param {Object} community The community to update.
         */
        function updateTemplate(community) {
            var idx = _.findIndex(community.templates, {
                functionalInnerId: community.template.functionalInnerId,
            });

            if (idx > -1) {
                community.templates[idx] = community.template;
            }
        }

        /////////////////////////////

        service.getDefaultPageTemplate = getDefaultPageTemplate;
        service.getTemplateByName = getTemplateByName;
        service.reset = reset;
        service.setAsCurrent = setAsCurrent;
        service.swapBack = swapBack;
        service.updateTemplate = updateTemplate;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('CommunityTemplate', CommunityTemplateService);
})();
