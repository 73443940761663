import React from 'react';

import { CONTENT_STREAMS } from '@lumapps/content-streams/keys';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { contentPage } from '@lumapps/lumx/custom-icons';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { contentStreamsAdmin } from '../../routes';

interface ContentStreamSettingsMenuProps {
    scope: string;
}

export const ContentStreamSettingsMenu: React.FC<ContentStreamSettingsMenuProps> = ({ scope }) => {
    const { get } = useDataAttributes(scope);
    const { translateKey } = useTranslate();

    return (
        <Menu.Item
            icon={contentPage}
            as={RouterLink}
            to={contentStreamsAdmin()}
            {...get({ element: 'access-segments' })}
        >
            {translateKey(CONTENT_STREAMS.CONTENT_STREAM)}
        </Menu.Item>
    );
};
