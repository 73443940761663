import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiDns } from '@lumapps/lumx/icons';
import { useMetadataAdminRights } from '@lumapps/metadata/hooks/useMetadataAdminRights';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { metadataBaseRoute } from '../../routes';

export const MetadataSideNavigation = () => {
    const { isAllowed } = useMetadataAdminRights();

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return <SideNavigationItem route={metadataBaseRoute} label={translateKey(GLOBAL.METADATA)} icon={mdiDns} />;
};

MetadataSideNavigation.displayName = 'SideNavigationItem';
