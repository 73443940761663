import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ReactEditor, useSlateStatic, useSelected } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import type { ElementRender } from '@lumapps/wrex/types';

import { PLAY_VIDEO } from '../../../constants';
import type { PlayVideoEditor, PlayVideoElement } from '../../../types';
import { CLASSNAME, PlayVideo } from '../../blocks/PlayVideo';
import { DeletePlayVideoButton } from '../../buttons/DeletePlayVideoButton';

import './EditablePlayVideo.scss';

export const EditablePlayVideo: ElementRender<PlayVideoElement, HTMLDivElement> = ({
    element: PlayVideoElement = {} as PlayVideoElement,
    children,
    ...forwardedProps
}) => {
    const { element } = useClassnames(CLASSNAME);
    const editor = useSlateStatic() as ReactEditor & PlayVideoEditor;
    const selected = useSelected();
    const { onFocus, onDelete } = useInlineVoid(editor, PlayVideoElement);
    const { block } = useClassnames(CLASSNAME);

    return (
        <PlayVideo
            className={block({
                editable: true,
                selected,
            })}
            element={PlayVideoElement}
            onFocus={onFocus}
            tabIndex={0}
            {...forwardedProps}
        >
            <div className={element('actions')}>
                <DeletePlayVideoButton onDelete={onDelete} />
            </div>

            {children}
        </PlayVideo>
    );
};

/** The display name should be equal to the PlayVideoElement's type */
EditablePlayVideo.displayName = PLAY_VIDEO;
