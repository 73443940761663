export let createFolder;
export let getMediaContentByLang;
export let getObjectId;
export let trash;
export let update;

export function setDocument(documentService) {
    createFolder = documentService.createFolder;
    getMediaContentByLang = documentService.getMediaContentByLang;
    getObjectId = documentService.getObjectId;
    trash = documentService.trash;
    update = documentService.update;
}
