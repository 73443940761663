import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Text, Theme } from '@lumapps/lumx/react';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';

import { CLIENT_COMPUTED_WIDGET_TYPE } from '../../constants';
import { getWidgetTypeTranslationKey } from '../../keys';
import { EmptyStateRendererProps } from '../../types';

import './index.scss';

const CLASSNAME = 'widget-content-placeholder';

export const EmptyWidgetPlaceholder: React.FC<EmptyStateRendererProps> = ({
    widget,
    widgetContentStyle,
    theme = Theme.light,
}) => {
    const customPlaceholder = widget?.legacyWidget?.properties?.helper;

    const { translateKey, translateObject } = useTranslate();
    const { block } = useClassnames(CLASSNAME);
    const widgetType =
        widget?.widgetType === CLIENT_COMPUTED_WIDGET_TYPE ? widget?.legacyWidget?.widgetType : widget?.widgetType;

    return (
        <FlexBox style={widgetContentStyle} className={block()} vAlign="center" hAlign="center" orientation="vertical">
            <Text as="p" typography="subtitle1" color={theme === Theme.dark ? 'light' : 'dark'} colorVariant="L3">
                {translateKey(getWidgetTypeTranslationKey(widgetType || '')).toUpperCase()}
            </Text>
            {customPlaceholder && (
                <Text as="p" typography="body1" color={theme === Theme.dark ? 'light' : 'dark'} colorVariant="L2">
                    {translateObject(standardizeTranslateObject(customPlaceholder))}
                </Text>
            )}
        </FlexBox>
    );
};
