import moment from 'moment';

import { removeCookie, setCookie } from '@lumapps/utils/cookies/cookie_utils';

import { SAFE_COOKIES, HAS_ACCEPTED_COOKIES, CONSENT_DURATION } from './constants';

/**
 * Remove all the cookies from the app that are not essentials for the app to work,
 * So all the cookies that are not in the SAFE_COOKIES array, and the hasAcceptedCookies cookie
 */
export const removeUnsafeCookies = () => {
    document.cookie
        .split(';')
        .map((cookie) => cookie.split('=')[0])
        .filter((cookieName) => !SAFE_COOKIES.includes(cookieName))
        .map((cookieName) => {
            removeCookie(cookieName, '/', window.location.hostname);
            removeCookie(cookieName, '/', 'lumapps.com');

            return cookieName;
        });
};

/**
 * Disable the Google analytics Tags
 *
 */
export const disableAnalyticsTags = () => {
    // eslint-disable-next-line no-underscore-dangle
    const tags = [...(document.cookie.match(/UA-.*?(?==)/g) || [])];

    tags.map((tag) => {
        (window as any)[`ga-disable-${tag}`] = true;

        return tag;
    });
};

/**
 * Set hasAcceptedCookies value
 */
export const setAnalyticsCookies = (choice: boolean, userId?: string) => {
    setCookie(
        userId ? `${HAS_ACCEPTED_COOKIES}.${userId}` : HAS_ACCEPTED_COOKIES,
        choice.toString(),
        moment().add(CONSENT_DURATION, 'months').toDate(),
    );

    if (!choice) {
        removeUnsafeCookies();
    }
};
