import extend from 'lodash/extend';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import size from 'lodash/size';
import without from 'lodash/without';

import { mdiEmailOpenOutline, mdiLock } from '@lumapps/lumx/icons';

/////////////////////////////

function LoginController(
    $location,
    $scope,
    $stateParams,
    $timeout,
    Customer,
    FormValidation,
    Instance,
    LocalStorage,
    LoginFactory,
    LxDialogService,
    LxNotificationService,
    Translation,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The number of login buttons displayed.
     *
     * @type {number}
     */
    const _MAX_BUTTON_DISPLAYED = 15;

    /**
     * Tieout delay before disable login buttons.
     *
     * @type {number}
     */
    const _TIMEOUT_DELAY = 10000;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The type of account of the user tha tis currently loging out.
     *
     * @type {string}
     */
    vm.accountType = undefined;

    /**
     * Indicates if the login button is disabled or not.
     *
     * @type {boolean}
     */
    vm.buttonDisabled = false;

    /**
     * Contains all the information for validating the captcha.
     *
     * @type {Object}
     */
    vm.captcha = {
        id: null,
        response: null,
        valid: false,
    };

    /**
     * Indicates if there is more than 3 login buttons to display.
     *
     * @type {Boolean}
     */
    vm.hasMoreButton = false;

    /**
     * The text fields icons.
     *
     * @type {Object}
     */
    vm.icons = {
        mdiEmailOpenOutline,
        mdiLock,
    };

    /**
     * Indicates if all the login buttons are currently displayed on the login page.
     *
     * @type {Boolean}
     */
    vm.isAllButtonsVisible = false;

    /**
     * Contains the login error.
     *
     * @type {Object}
     */
    vm.loginError = {
        email: undefined,
        message: undefined,
    };

    /**
     * Indicates if the external login (without a G Suite account) is allowed.
     *
     * @type {boolean}
     */
    vm.externalLoginAllowed = false;

    /**
     * Contains the forgotten password form.
     *
     * @type {Object}
     */
    vm.form = {};

    /**
     * Indicates if we don't want to allow login with email/password combo.
     *
     * @type {boolean}
     */
    vm.loginGoogleOnly = true;

    /**
     * Indicates the current login mode.
     * Possible values are: 'google' for Google account authentication and 'email' for email/password
     * authentication.
     *
     * @type {string}
     */
    vm.loginMode = 'google';

    /**
     * Indicates if we are loging out.
     *
     * @type {boolean}
     */
    vm.logout = false;

    /**
     * Contains the redirect URL.
     * After a successful login, the user will be redirected to this URL.
     *
     * @type {string}
     */
    vm.redirectUri = undefined;

    /**
     * Contains the Relay State for the SSO authentication.
     *
     * @type {string}
     */
    vm.relayState = $location.search().RelayState;

    /**
     * Contains the SAML Request for the SSO authentication.
     *
     * @type {string}
     */
    vm.samlRequest = $location.search().SAMLRequest;

    /**
     * Contains the email/password authentication values.
     *
     * @type {Object}
     */
    vm.values = {
        email: undefined,
        password: undefined,
        repassword: undefined,
    };

    /////////////////////////////

    /*
     * Services and utilities.
     */
    vm.Customer = Customer;
    vm.FormValidation = FormValidation;
    vm.Instance = Instance;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Disabled the login button..
     */
    function disableButton() {
        vm.buttonDisabled = true;

        $timeout(() => {
            vm.buttonDisabled = false;
        }, _TIMEOUT_DELAY);
    }

    /**
     * Check if action links should be displayed (new account creation, forgotten password, google sign-in, ...).
     *
     * @return {boolean} If the actions links should be displayed.
     */
    function displayActionLinks() {
        return (vm.loginMode === 'google' && Customer.isExternalAccounts()) || vm.loginMode === 'email';
    }

    /**
     * Display all login buttons.
     */
    function displayMoreButtons() {
        vm.isAllButtonsVisible = true;
        angular.forEach(vm.samlProviders, (button) => (button.isVisible = true));
    }

    /**
     * Hide extra login buttons.
     */
    function hideMoreButtons() {
        vm.isAllButtonsVisible = false;
        angular.forEach(
            vm.samlProviders,
            (button) =>
                (button.isVisible =
                    size(without(vm.loginMethods, 'email', 'saml', 'saml_v2')) + size(vm.samlProviders) <= _MAX_BUTTON_DISPLAYED),
        );
    }

    /**
     * Open the forgotten password dialog.
     */
    function openPasswordDialog() {
        Utils.waitForAndExecute('#forgotten-password');
    }

    /**
     * Register a new form at form init.
     *
     * @param {Object} scope Scope of the controller.
     */
    function registerForm(scope) {
        $timeout(() => {
            vm.form.forgottenPasswordForm = scope.forgottenPasswordForm;
        });
    }

    /**
     * Reset the user password.
     * If the reset is successful, redirect user to the login form.
     */
    function resetPassword() {
        /* eslint-disable id-blacklist */
        LoginFactory.reset(
            {
                password: vm.values.password,
                repassword: vm.values.repassword,
                token: get($location.search(), 'token'),
            },
            (result) => {
                if (result.status) {
                    LxNotificationService.success(Translation.translate('LOGIN_PASSWORD_RESET_SUCCESS'));

                    $location.search('token', null).replace();
                    $location.search('from', 'email').replace();
                } else {
                    Utils.displayServerError({
                        data: {
                            error: {
                                message: result.error,
                            },
                        },
                    });
                }
            },
            (exception) => {
                Utils.displayServerError({
                    data: {
                        error: {
                            message: exception.data.message,
                        },
                    },
                });
            },
        );
        /* eslint-enable id-blacklist */
    }

    /**
     * Send an email with a new password.
     */
    function sendNewPassword() {
        if (angular.isUndefinedOrEmpty([vm.values.email, vm.captcha.response], 'some')) {
            return;
        }

        const form = vm.form.forgottenPasswordForm;

        if (!form.$valid) {
            FormValidation.setFormDirty(form);
        }

        /* eslint-disable id-blacklist */
        LoginFactory.newPassword(
            {
                captcha: vm.captcha.response,
                email: vm.values.email,
            },
            (result) => {
                if (result.status) {
                    LxNotificationService.success(Translation.translate('LOGIN_FORGOTTEN_PASSWORD_EMAIL_SENT'));
                    LxDialogService.close('forgotten-password');
                } else {
                    Utils.displayServerError({
                        data: {
                            error: {
                                message: result.error || 'LOGIN_FORGOTTEN_PASSWORD_UNKNOWN_EMAIL',
                            },
                        },
                    });
                }
            },
            (exception) => {
                Utils.displayServerError({
                    data: {
                        error: {
                            message: exception.data.message,
                        },
                    },
                });
            },
        );
        /* eslint-enable id-blacklist */
    }

    /**
     * Switch between login modes ('google' or 'email').
     *
     * @param {string} newMode The new mode to use for authentication.
     *                         Possible values are: 'google' or 'email'.
     */
    function switchMode(newMode) {
        $location.search('from', newMode).replace();
    }

    /////////////////////////////

    vm.contains = includes;
    vm.disableButton = disableButton;
    vm.displayActionLinks = displayActionLinks;
    vm.displayMoreButtons = displayMoreButtons;
    vm.hideMoreButtons = hideMoreButtons;
    vm.openPasswordDialog = openPasswordDialog;
    vm.registerForm = registerForm;
    vm.resetPassword = resetPassword;
    vm.sendNewPassword = sendNewPassword;
    vm.switchMode = switchMode;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the "from" URL parameter and change to/from Google account authentication to
     * email/password authentication (if allowed).
     */
    $scope.$watch(
        () => $location.search().from,
        (fromParameter) => {
            if (Customer.isSsoEnabled()) {
                vm.loginMode = angular.isDefined(fromParameter) ? fromParameter : 'email';
            } else {
                vm.loginMode = angular.isDefined(fromParameter) && vm.externalLoginAllowed ? fromParameter : 'google';
            }
        },
    );

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the forgotten password dialog closes, reset the captcha.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The id of the dialog that is being closed.
     */
    $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
        if (dialogId === 'forgotten-password' && angular.isDefinedAndFilled(vm.captcha.response)) {
            grecaptcha.reset(vm.captcha.id);

            vm.captcha.response = null;
            vm.captcha.valid = false;
        }
    });

    /**
     * When the forgotten password dialog opens, initialize the captcha.
     *
     * @param {Event}  evt      The dialog open event.
     * @param {string} dialogId The id of the dialog that is being opened.
     */
    $scope.$on('lx-dialog__open-end', (evt, dialogId) => {
        if (dialogId !== 'forgotten-password') {
            return;
        }

        Utils.loadGoogleCaptcha(() => {
            Utils.initGoogleCaptcha($scope, vm.captcha);
        });
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // Hide the ghost header. It will be removed by the settings service.
        angular.element('#ghost-header').css('display', 'none');

        // Always reset Local and Session storage when going to the login page.
        LocalStorage.reset();
        if (window.localStorage) {
            localStorage.clear();
        }

        vm.externalLoginAllowed = Customer.isExternalAccounts();
        vm.loginGoogleOnly = Customer.getCustomer() && Customer.getCustomer().loginGoogleOnly;

        // Set redirect URL.
        if (angular.isDefinedAndFilled($stateParams.r)) {
            vm.redirectUri = $stateParams.r;
        }

        // Check if we are loging out.
        vm.logout = angular.isDefined($location.search().logout);
        vm.accountType = $location.search().accountType;

        vm.loginMethods = get(Customer.getCustomer(), 'loginMethods', ['google']);
        vm.samlProviders = get(Customer.getCustomer(), 'samlProviders');

        // If login is not authorized.
        if ($location.search().denied === 'true') {
            LxNotificationService.error(Translation.translate('LOGIN_NOT_AUTHORIZED_INFO'));
        }

        // If there is a login error.
        if (angular.isDefinedAndFilled($location.search().error)) {
            extend(vm.loginError, {
                accountType: vm.accountType || 'google',
                email: Utils.escapeHtmlTags($location.search().email),
                message: Utils.escapeHtmlTags($location.search().error),
            });
        }

        vm.loginError.logoutUrl = get(Customer.getCustomer(), `logout_urls.${vm.loginError.accountType}`, '/logout');

        vm.isNewLogin = angular.isDefinedAndFilled(intersection(vm.loginMethods, ['microsoft', 'okta', 'saml', 'saml_v2']));

        // Detect the forgotten password page.
        if (angular.isDefined($stateParams['forgotten-password'])) {
            $timeout(vm.openPasswordDialog);
        }

        const NEED_LOGIN_MSG = 'NEED_LOGIN';

        // If the customer enforced Google login, submit the Google login form.
        if (
            !vm.logout &&
            vm.loginGoogleOnly &&
            includes(vm.loginMethods, 'saml') &&
            (angular.isUndefinedOrEmpty(vm.loginError.message) || vm.loginError.message === NEED_LOGIN_MSG)
        ) {
            $timeout(() => {
                const samlForm = angular.element('#samlLoginForm');

                samlForm && samlForm.submit();
            }, 0);
        } else if (
            !vm.logout &&
            vm.loginGoogleOnly &&
            includes(vm.loginMethods, 'microsoft') &&
            (angular.isUndefinedOrEmpty(vm.loginError.message) || vm.loginError.message === NEED_LOGIN_MSG)
        ) {
            $timeout(() => {
                const msForm = angular.element('#microsoftLoginForm');

                msForm && msForm.submit();
            }, 0);
        } else if (
            !vm.logout &&
            vm.loginGoogleOnly &&
            (angular.isUndefinedOrEmpty(vm.loginError.message) || vm.loginError.message === NEED_LOGIN_MSG)
        ) {
            $timeout(() => {
                const googleForm = angular.element('#googleLoginForm');
                googleForm.submit();
            }, 0);
        }

        vm.hasMoreButton =
            size(without(vm.loginMethods, 'email', 'saml', 'saml_v2')) + size(vm.samlProviders) > _MAX_BUTTON_DISPLAYED;
        vm.hideMoreButtons();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('LoginController', LoginController);

/////////////////////////////

export { LoginController };
