import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import loFind from 'lodash/find';
import set from 'lodash/set';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function ModuleAdminService(
    $state,
    Community,
    CommunityForm,
    CommunityWizardConfiguration,
    Config,
    ConfigTheme,
    Content,
    Customer,
    Features,
    InitialSettings,
    Instance,
    LxNotificationService,
    ReduxStore,
    Translation,
    Tutorial,
    User,
    UserDirectory,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = {};

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The identifier of the last version of the tip that has been clicked on.
     *
     * @type {string}
     */
    let _lastUuid;

    /**
     * The identifier of the current list of modules.
     *
     * @type {string}
     */
    let _listKey;

    /**
     * Indicates if the tip should display again or not after being deleted.
     *
     * @type {boolean}
     */
    let _notifyAgain = false;

    /**
     * A tip object to add to assign to a given module.
     *
     * @type {Object}
     */
    let _tip;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Community to edit, used to send the community datas to redux.
     *
     * @type {Object}
     */
    service.editedCommunity = undefined;

    /**
     * Indicates if a module is currently being added.
     * Contains a boolean for each module type.
     *
     * @type {Object}
     */
    service.isAdding = {
        community: false,
    };

    /**
     * Indicates if a module is currently being edited.
     * Contains a boolean for each module type.
     *
     * @type {Object}
     */
    service.isEditing = {
        community: false,
    };

    /**
     * Enumeration of all the possible dialog key.
     *
     * @type {Object}
     */
    service.dialogKeys = {
        community: 'module-community-admin-dialog',
        communityWizard: 'community-wizard-dialog-identifier',
        directory: 'module-directory-admin-dialog',
        tutorial: 'module-tutorial-admin-dialog',
        userDirectory: 'module-user-directory-admin-dialog',
    };

    /**
     * Indicates if a warning popup should be displayed before editing the community.
     * Used when a non MS user try to edit a MS community.
     *
     * @type {boolean}
     */
    service.shouldDisplayWarning = false;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Set a new tip object for a given type of entity based on the service we pass as an argument.
     *
     * @param {Service} [OverrideService=Content] The service to use to retrieve the current entity.
     */
    function _setTip(OverrideService) {
        OverrideService = OverrideService || Content;

        const { template } = OverrideService.getCurrent(_listKey);

        // Check for an existing tip on the entity.
        const entityTip = loFind(get(template, 'components'), {
            widgetType: InitialSettings.WIDGET_TYPES.TIP,
        });

        if (angular.isDefinedAndFilled(entityTip)) {
            _tip = entityTip;

            return;
        }

        _tip = {
            properties: {
                content: undefined,
            },
            title: undefined,
            type: 'widget',
            uuid: generateUUID(),
            widgetType: InitialSettings.WIDGET_TYPES.TIP,
        };
    }

    /**
     * Add a new community.
     *
     * @param {string} listKey    The identifier of the list the new item is part of.
     * @param {Object} [template] The template to use to create the community.
     */
    function _addCommunity(listKey, template) {
        _listKey = listKey;
        Community.setCurrent(service.generateNewCommunityObject(template), listKey);

        if (User.isMicrosoft() && Features.hasFeature('microsoft')) {
            service.isAdding.community = true;
            service.selectedCommunityTemplate = template;
        } else {
            Utils.waitForAndExecute(`#${service.dialogKeys.communityWizard}`);
        }
    }

    /**
     * Add a new content list module.
     *
     * @param {string} customContentTypeId The identifier of the custom content type to add the content list to.
     */
    function _addContentList(customContentTypeId) {
        if (angular.isUndefinedOrEmpty(customContentTypeId)) {
            return;
        }

        $state.go('app.front.content-create', {
            customContentType: customContentTypeId,
            type: InitialSettings.CONTENT_TYPES.CUSTOM_LIST,
        });
    }

    /**
     * Add a new directory module.
     */
    function _addDirectory() {
        $state.go('app.front.content-create-without-custom-type', {
            type: InitialSettings.CONTENT_TYPES.DIRECTORY,
        });
    }

    /**
     * Add a new tutorial.
     *
     * @param {string} listKey The identifier of the list the new item is part of.
     */
    function _addTutorial(listKey) {
        _listKey = listKey;

        const slug = {};
        slug[Translation.inputLanguage] = generateUUID();

        const customerId = Customer.getCustomerId();
        const instanceId = Instance.getCurrentInstanceId();

        Content.setCurrent(
            {
                author: User.getConnected().email,
                customer: customerId,
                instance: instanceId,
                slug,
                type: InitialSettings.CONTENT_TYPES.TUTORIAL,
            },
            _listKey,
        );

        Tutorial.setCurrent({
            customer: customerId,
            instance: instanceId,
        });

        Utils.waitForAndExecute(`#${service.dialogKeys.tutorial}`);
    }

    /**
     * Add a new user directory.
     *
     * @param {string} listKey The identifier of the list the new item is part of.
     */
    function _addUserDirectory(listKey) {
        _listKey = listKey;

        Content.setCurrent(
            {
                author: User.getConnected().email,
                customer: Customer.getCustomerId(),
                instance: Instance.getCurrentInstanceId(),
                type: Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY,
            },
            _listKey,
        );

        _setTip();
        UserDirectory.createDefaultUserDirectoryFields(Content.getCurrent(_listKey));

        Utils.waitForAndExecute(`#${service.dialogKeys.userDirectory}`);
    }

    /**
     * Edit a given community.
     *
     * @param {Module} communityModule An existing community to edit.
     * @param {string} listKey         The identifier of the list the new item is part of.
     * @param {string} mode            The display mode of the community form.
     */
    function _editCommunity(communityModule, listKey, mode) {
        if (service.isEditing.community || angular.isUndefinedOrEmpty(get(communityModule, 'uid'))) {
            return;
        }

        _listKey = listKey;

        CommunityForm.setDisplayMode(_listKey, mode);

        Community.get(
            {
                uid: communityModule.uid,
            },
            function onCommunityGetSuccess(response) {
                Community.setCurrent(angular.fastCopy(response), _listKey);

                if (
                    response.communityType === 'microsoft' ||
                    (response.communityType === 'email' && User.isMicrosoft())
                ) {
                    service.shouldDisplayWarning = !User.isMicrosoft();
                    service.editedCommunity = Community.getCurrent();
                    service.isEditing.community = !service.shouldDisplayWarning;
                } else if (response.communityType === 'google' && !User.isGoogle()) {
                    LxNotificationService.confirm(
                        Translation.translate('ADMIN.COMMUNITY.EDITION_WARNING.LIMITED_ACCESS_TITLE'),
                        Translation.translate('ADMIN.COMMUNITY.EDITION_WARNING.LIMITED_ACCESS_DETAILS.GOOGLE'),
                        {
                            cancel: Translation.translate('CANCEL'),
                            ok: Translation.translate('GLOBAL.CONTINUE'),
                        },
                        (answer) => {
                            if (answer) {
                                Utils.waitForAndExecute('#module-community-admin-dialog');
                            }
                        },
                    );
                } else {
                    Utils.waitForAndExecute('#module-community-admin-dialog');
                }
            },
            function onCommunityGetError(err) {
                Utils.displayServerError(err);
            },
        );
    }

    /**
     * Edit a given content list.
     *
     * @param {Module} contentListModule An existing content list module to edit.
     */
    function _editContentList(contentListModule) {
        if (angular.isUndefinedOrEmpty(get(contentListModule, 'id'))) {
            return;
        }

        $state.go('app.front.content-edit', {
            key: contentListModule.id,
        });
    }

    /**
     * Edit a given directory.
     *
     * @param {Module} directoryModule An existing directory module to edit.
     */
    function _editDirectory(directoryModule) {
        if (angular.isUndefinedOrEmpty(get(directoryModule, 'id'))) {
            return;
        }

        $state.go('app.front.content-edit', {
            key: directoryModule.id,
        });
    }

    /**
     * Edit a given tutorial.
     *
     * @param {Module} tutorialModule An existing tutorial to edit.
     * @param {string} listKey        The identifier of the list the new item is part of.
     */
    function _editTutorial(tutorialModule, listKey) {
        _listKey = listKey;

        Content.setCurrent(angular.fastCopy(tutorialModule), _listKey);

        Tutorial.get(
            {
                uid: Content.getCurrent(_listKey).externalKey,
            },
            function onTutorialGetSuccess(tutorial) {
                Tutorial.setCurrent(tutorial);

                Utils.waitForAndExecute(`#${service.dialogKeys.tutorial}`);
            },
            Utils.displayServerError,
        );
    }

    /**
     * Edit a given user directory module.
     *
     * @param {Module} userDirectoryModule An existing user directory module to edit.
     * @param {string} listKey             The identifier of the list the new item is part of.
     */
    function _editUserDirectory(userDirectoryModule, listKey) {
        if (angular.isUndefinedOrEmpty(get(userDirectoryModule, 'id'))) {
            return;
        }

        _listKey = listKey;

        userDirectoryModule.isDefaultUserDirectory =
            Instance.getInstance().defaultUserDirectory === userDirectoryModule.id;

        Content.setCurrent(angular.fastCopy(userDirectoryModule), _listKey);

        _setTip();
        UserDirectory.createDefaultUserDirectoryFields(Content.getCurrent(_listKey));

        Utils.waitForAndExecute(`#${service.dialogKeys.userDirectory}`);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add a new module.
     *
     * @param {string} type      The type of module to add.
     * @param {string} [listKey] The identifier of the list the new item is part of.
     * @param {Object} [options] An object of extra parameters to use depending on the type of module to add.
     */
    function add(type, listKey, options) {
        if (angular.isUndefinedOrEmpty(type)) {
            return;
        }

        options = options || {};

        switch (type) {
            case InitialSettings.CONTENT_TYPES.COMMUNITY:
                _addCommunity(listKey, options.template);

                break;

            case InitialSettings.CONTENT_TYPES.CUSTOM_LIST:
                _addContentList(options.customContentType);

                break;

            case InitialSettings.CONTENT_TYPES.DIRECTORY:
                _addDirectory();

                break;

            case InitialSettings.CONTENT_TYPES.TUTORIAL:
                _addTutorial(listKey);

                break;

            case InitialSettings.CONTENT_TYPES.USER_DIRECTORY:
                _addUserDirectory(listKey);

                break;

            default:
                break;
        }
    }

    /**
     * Add a tip object to the current entity of the overrideService.
     *
     * @param {Service} [OverrideService=Content] The service to use to retrieve the current entity.
     */
    function addTip(OverrideService) {
        OverrideService = OverrideService || Content;
        OverrideService.getCurrent(_listKey).template = OverrideService.getCurrent(_listKey).template || {};

        const { template } = OverrideService.getCurrent(_listKey);

        // Check for an existing tip on the entity.
        let entityTip = loFind(get(template, 'components'), {
            widgetType: InitialSettings.WIDGET_TYPES.TIP,
        });

        if (angular.isDefinedAndFilled(entityTip)) {
            entityTip = _tip;

            return;
        }

        if (angular.isUndefined(template.components)) {
            OverrideService.getCurrent(_listKey).template.components = [];
        }

        if (angular.isDefined(_tip)) {
            template.components.push(_tip);
        }
    }

    /**
     * Check if module classes are available for the current theme.
     *
     * @return {boolean} Whether there are any module classes for the current theme.
     */
    function classesAreAvailable() {
        return angular.isDefinedAndFilled(ConfigTheme.AVAILABLE_MODULE_CLASSES);
    }

    /**
     * Edit an existing module.
     *
     * @param {Module} existingModule The existing module to edit.
     * @param {string} [listKey]      The identifier of the list the new item is part of.
     * @param {Object} [options]      An object of extra parameters to use depending on the type of module to add.
     */
    function edit(existingModule, listKey, options) {
        if (angular.isUndefinedOrEmpty(get(existingModule, 'type'))) {
            return;
        }

        options = options || {};

        switch (existingModule.type) {
            case InitialSettings.CONTENT_TYPES.COMMUNITY:
                _editCommunity(existingModule, listKey, options.mode);

                break;

            case InitialSettings.CONTENT_TYPES.CUSTOM_LIST:
                _editContentList(existingModule);

                break;

            case InitialSettings.CONTENT_TYPES.DIRECTORY:
                _editDirectory(existingModule);

                break;

            case InitialSettings.CONTENT_TYPES.TUTORIAL:
                _editTutorial(existingModule, listKey);

                break;

            case InitialSettings.CONTENT_TYPES.USER_DIRECTORY:
                _editUserDirectory(existingModule, listKey);

                break;

            default:
                break;
        }
    }

    /**
     * Empty the variables related to the current content.
     */
    function emptyContent() {
        _listKey = undefined;
        _tip = undefined;
        _lastUuid = undefined;
        _notifyAgain = false;
    }

    /**
     * Get the current listKey in use for modules.
     *
     * @return {string} An identifier of a list of modules.
     */
    function getListKey() {
        return _listKey;
    }

    /**
     * Get the current tip object.
     *
     * @return {Object} A tip object.
     */
    function getTip() {
        return _tip;
    }

    /**
     * Add some default values to the Froala editor.
     */
    function initFroala() {
        const key = `properties.content[${Translation.inputLang}]`;
        if (angular.isUndefined(get(_tip, key))) {
            set(_tip, key, '');
        }
    }

    /**
     * Toggles whether the tip should be displayed again or not when users delete it.
     */
    function switchNotification() {
        _notifyAgain = !_notifyAgain;
        const { lastUuidEdit } = _tip.properties;

        if (angular.isDefined(lastUuidEdit) && angular.isUndefined(_lastUuid)) {
            _lastUuid = lastUuidEdit;
        }

        if (_notifyAgain) {
            _tip.properties.lastUuidEdit = generateUUID();
        } else {
            _tip.properties.lastUuidEdit = _lastUuid;
        }
    }

    /**
     * Generate a community main object for creation purpose.
     *
     * @param  {Object} [template] The template to use to create the community.
     * @return {Object} The generated community object.
     */
    function generateNewCommunityObject(template) {
        const user = User.getConnected();

        template = template || {};

        const defaultLayout = {
            components: [
                {
                    properties: {},
                    title: {},
                    type: 'widget',
                    uuid: generateUUID(),
                    widgetType: InitialSettings.WIDGET_TYPES.INTRO,
                },
            ],
        };

        return {
            adminKeys: [user.id],
            adminsDetails: [user],
            author: user.email,
            calendarId: undefined,
            chatProvider: undefined,
            customer: Customer.getCustomerId(),
            driveFolder: {},
            fromTemplate: template.uid,
            groups: [],
            hasCalendarId: false,
            hasDriveFolder: false,
            instance: Instance.getCurrentInstanceId(),
            postStatuses: template.postStatuses || [],
            postTypes: template.postTypes || CommunityWizardConfiguration.DEFAULT_POST_TYPES,
            privacy: template.privacy || Community.privacyTypes.restricted,
            tagsDetails: template.tagsDetails || [],
            template:
                loFind(template.templates || [], {
                    functionalInnerId: 'posts',
                }) || defaultLayout,
            templates: template.templates || [],
            type: InitialSettings.CONTENT_TYPES.COMMUNITY,
            useChat: false,
            userKeys: [],
            users: [],
        };
    }

    /**
     * Set the current listKey.
     *
     * @param {string} listKey The list key identifier.
     */
    function setListKey(listKey) {
        _listKey = listKey;
    }

    /**
     * Update the content list datatable.
     *
     * @param {Object} content       The content object that just got updated/created.
     * @param {string} [serviceType] Angular service used to retrieve the list of contents.
     */
    function updateList(content, serviceType) {
        if (angular.isUndefinedOrEmpty(content)) {
            return;
        }

        const list =
            serviceType === InitialSettings.CONTENT_TYPES.COMMUNITY
                ? Community.displayList(_listKey)
                : Content.displayList(_listKey);

        if (!angular.isArray(list)) {
            return;
        }

        const index = findIndex(list, {
            uid: content.uid,
        });

        // Already exists so update it.
        if (index > -1) {
            angular.extend(list[index], content);
            // Add a new one.
        } else {
            list.push(content);
        }
    }

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object} The state aka. the store shape.
     */
    function mapStateToRedux() {
        return {
            editedCommunity: service.editedCommunity,
            isDialogOpen: service.isAdding.community,
            isEditionOpen: service.isEditing.community,
            shouldDisplayWarning: service.shouldDisplayWarning,
            template: service.selectedCommunityTemplate,
            community: Community.getCurrent(),
        };
    }

    /**
     * Triggered when synced data is changed by redux.
     * It takes in the new state and should update the Angular service accordingly.
     *
     * @param {reduxState} state The part of the state that this service is concerned about.
     */
    function mapReduxToAngular({ communities }) {
        service.isAdding.community = communities?.creationWizard?.isDialogOpen;
        service.isEditing.community = communities?.creationWizard?.isEditionOpen;
        service.editedCommunity = undefined;
        service.shouldDisplayWarning = communities?.creationWizard?.shouldDisplayWarning;
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'admin';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'community-dialog/update';

    // Expose the appropriate functions.
    service.mapReduxToAngular = mapReduxToAngular;
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////

    service.add = add;
    service.addTip = addTip;
    service.classesAreAvailable = classesAreAvailable;
    service.edit = edit;
    service.emptyContent = emptyContent;
    service.generateNewCommunityObject = generateNewCommunityObject;
    service.getListKey = getListKey;
    service.getTip = getTip;
    service.initFroala = initFroala;
    service.setListKey = setListKey;
    service.switchNotification = switchNotification;
    service.updateList = updateList;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = function init() {
        ReduxStore.subscribe(service);
    };

    service.init();

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('ModuleAdmin', ModuleAdminService);

/////////////////////////////

export { ModuleAdminService };
