import { useCallback } from 'react';

import { get } from '@lumapps/constants';
import { getHomePageId } from '@lumapps/instance/ducks/selectors';
import { Media } from '@lumapps/medias/types';
import { useSelector, useDispatch } from '@lumapps/redux/react';
import { useRouteMatching, useParams, useQueryParams, useRouteMatch } from '@lumapps/router';
import { useSpace } from '@lumapps/spaces/hooks/useSpace';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { RecursivePartial } from '@lumapps/utils/types/RecursivePartial';

import {
    canUserEditCurrentContent,
    getCurrentContentExcerpt,
    getCurrentContentId,
    getCurrentContentMediaThumbnail,
    getCurrentContentSlug,
    getCurrentContentTitle,
    getCurrentContentType,
    isInDesignerMode,
} from '../ducks/selectors';
import { actions } from '../ducks/slice';
import { legacyContentViewRoute, contentViewRoute, homePageViewRoute } from '../routes';

const constants = get();

export interface UseContentApi {
    /** whether the current page is a content page or not */
    isContentPage: boolean;
    /** Whether the current page is currently edited */
    isDesignerMode?: boolean;
    /** whether the current connected user can edit the current content or not */
    canCurrentUserEditContent: boolean;
    /** The ID of the currently displayed content */
    currentContentId?: string;
    /** The slug of the currently displayed content */
    currentContentSlug?: string;
    /** The type of the currently displayed content */
    currentContentType?: string;
    /** The title of the currently displayed content */
    currentContentTitle?: string | null;
    /** The excerpt of the currently displayed content */
    currentContentExcerpt?: string | null;
    /** The thumbnail of the currently displayed content */
    currentContentMediaThumbnail?: RecursivePartial<Media> | null;
    /** layout version */
    layout?: string;
    /** reset currently displayed content */
    resetContent: () => void;
    /** whether the current page is a home page or not */
    isHomePage: boolean;
    /** home page content id */
    homePageId: string;
    /** legacy content URL if needed. Please use currentContentSlug if you do not know what you are doing */
    legacyUrl?: string;
    /** whether the current URL has a legacy format */
    isLegacyContentPage: boolean;
    /** returns the page id, which is 'home' when this is the home page and the content id when it is not */
    pageId?: string;
    /** whether the current page is in layout v2 */
    isLayout: boolean;
}

export const useContent = (): UseContentApi => {
    const { translateObject } = useTranslate();
    const { id: spaceId } = useSpace();
    const isDesignerMode = useSelector(isInDesignerMode);
    const isLegacyContentPage = useRouteMatching(legacyContentViewRoute);
    const isLegacyContentMatch = useRouteMatch(legacyContentViewRoute);
    const isNewContentPage = useRouteMatching(contentViewRoute);
    const isHomePage = useRouteMatching(homePageViewRoute);
    const canEditContent = useSelector(canUserEditCurrentContent);
    const currentContentType = useSelector(getCurrentContentType);
    const currentContentId = useSelector(getCurrentContentId);
    const currentContentSlug = useSelector(getCurrentContentSlug);
    const currentContentExcerpt = useSelector(getCurrentContentExcerpt);
    const currentContentMediaThumbnail = useSelector(getCurrentContentMediaThumbnail);
    const doesHaveACurrentContentId = Boolean(currentContentId) && currentContentId.length > 0;
    const currentContentTitle = useSelector(getCurrentContentTitle);
    const homePageId = useSelector(getHomePageId) as string;
    const { id, 0: slug }: any = useParams();
    const { layout } = useQueryParams();
    const dispatch = useDispatch();
    const resetContent = useCallback(() => {
        dispatch(actions.resetContent());
    }, [dispatch]);
    let isContentPage = false;

    /**
     * In order to determine whether we are showing a content page or not,
     * we need to figure out a few things...
     *
     * First, if we are on a legacy context, the URL is quite permissive, so basically we need to check
     * that the legacy route matches the current route and also that there is a content id on the store.
     */
    if (constants.isLegacyContext) {
        isContentPage = isLegacyContentPage && doesHaveACurrentContentId;
        /**
         * Then we pass to the new application side. In this scenario, if the URL matches the legacy content page,
         * it means that it was a URL that was copied & pasted on the browser, and the backend determined that
         * we needed to render the new application. In that scenario, we will have an initial content id on the constants
         * and there would not be any id or slug on the URL. in that case, we can say that the page is a content.
         */
    } else if (isLegacyContentPage && constants.initialContentId && !id && !slug) {
        isContentPage = true;
        /**
         * Finally, since the URL for the content page is different in the new application and does not accept any wildcards,
         * if the URL matches we are safe to say that it is a content page. We add an extra check in order to validate that we
         * have a content id as well.
         */
    } else {
        isContentPage = isNewContentPage && doesHaveACurrentContentId;
    }

    const isLegacyContent = Boolean(isLegacyContentPage && constants.initialContentId && !id && !slug);

    return {
        isContentPage,
        isDesignerMode,
        canCurrentUserEditContent: Boolean(canEditContent) && isContentPage,
        currentContentId,
        currentContentSlug: slug || translateObject(currentContentSlug),
        currentContentTitle: translateObject(standardizeTranslateObject(currentContentTitle)),
        currentContentExcerpt: translateObject(standardizeTranslateObject(currentContentExcerpt)),
        currentContentMediaThumbnail,
        isHomePage,
        layout,
        resetContent,
        homePageId,
        legacyUrl: isLegacyContentMatch ? isLegacyContentMatch.url : undefined,
        isLegacyContentPage: isLegacyContent,
        pageId: isHomePage ? homePageId : currentContentId || spaceId,
        isLayout: isContentPage && isLegacyContent,
        currentContentType,
    };
};
