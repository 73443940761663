import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { ServerListResponse } from '@lumapps/base-api';
import { background, classnames, margin, padding, useClassnames } from '@lumapps/classnames';
import { list as getCommunityListAPI } from '@lumapps/communities/api';
import { CommunityVisibility } from '@lumapps/communities/components/CommunityVisibility';
import { getCurrentContentAsCommunity } from '@lumapps/communities/ducks/selectors';
import { COMMUNITIES } from '@lumapps/communities/keys';
import { CommunityPickerDialog } from '@lumapps/community-pickers/components/CommunityPickerDialog';
import { useCommunityListPicker } from '@lumapps/community-pickers/hooks/useCommunityListPicker';
import { useDataAttributes } from '@lumapps/data-attributes';
import { getSiteIds } from '@lumapps/instance/ducks/selectors';
import { ThumbnailWithIcon } from '@lumapps/lumx-images/components/ThumbnailWithIcon';
import { SubSection } from '@lumapps/lumx-layouts/components/SubSection';
import { mdiClose, mdiGoogleCirclesExtended } from '@lumapps/lumx/icons';
import {
    Button,
    Emphasis,
    GenericBlock,
    IconButton,
    List,
    ListItem,
    RadioButton,
    RadioGroup,
    Size,
    Text,
} from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { useListWidgetSelection } from '@lumapps/widget-base/hooks/useListWidgetSelection';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';

import { SELECTION_TYPE_VALUES, SelectionSettingType } from '../../types';
import { SettingSection } from '../SettingSection';
import { SelectedEntitySkeletons } from './SelectedEntitySkeletons';

import './index.scss';

export interface SelectionSettingProps<E, P> {
    /** className to be added */
    className?: string;
    /** current widget selection properties */
    currentSelection?: SelectionSettingType;
    /** dataScope to be used for data attributes */
    dataScope?: string;
    /** what entity picker should we use and with which props */
    entityPicker: { component: React.FC<any>; props?: Partial<P> };
    /** informations to pass to the selected entity block (title, image, icon, subtitle) */
    entityBlock: {
        title: (entity: E) => string | null;
        image: (entity: E) => string | undefined;
        icon: string;
        /** component to be displayed as entity subtitle */
        subtitle?: (entity: E) => React.ReactNode;
    };
    /** text to be passed to the select manual entities button */
    manualButtonText: string;
    /** api call to get the list of the entities */
    getEntities: (params: any) => Promise<ServerListResponse<E>>;
    /** callback when some value change (radio option or picked item) */
    onChange: (selection: SelectionSettingType) => void;
}

const CLASSNAME = 'selection-setting';

/**
 * Generic component that allow to filter a list by community or by picked entities
 * Used for widget list settings
 */
export const SelectionSetting = <E, P>({
    className,
    currentSelection,
    dataScope,
    entityBlock,
    entityPicker,
    manualButtonText,
    getEntities,
    onChange,
}: SelectionSettingProps<E, P>) => {
    const { translateKey, translate } = useTranslate();
    const currentCommunity = useSelector(getCurrentContentAsCommunity);
    const instanceId = useSelector(getSiteIds);

    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(dataScope || 'widget-settings-selection');

    const [selectedOption, setSelectedOption] = React.useState(
        currentSelection?.selectedOption ||
            (currentCommunity ? SELECTION_TYPE_VALUES.CURRENT_COMMUNITY : SELECTION_TYPE_VALUES.DYNAMIC),
    );
    const [selectedSubOption, setSelectedSubOption] = React.useState(
        currentSelection?.selectedSubOption || SELECTION_TYPE_VALUES.ALL_COMMUNITIES,
    );

    const onValueChange = (newValue: SELECTION_TYPE_VALUES) => setSelectedOption(newValue);
    const onSubSectionValueChange = (newValue: SELECTION_TYPE_VALUES) => setSelectedSubOption(newValue);

    // Everything related to community selection
    const [communityPickerDialogIsOpen, setCommunityPickerDialogIsOpen] = React.useState(false);
    const isManualCommunitySelected = selectedSubOption === SELECTION_TYPE_VALUES.MANUAL_COMMUNITY;
    const isCurrentCommunitySelected = selectedOption === SELECTION_TYPE_VALUES.CURRENT_COMMUNITY;
    const communitiesButtonAnchorRef = React.useRef(null);

    const {
        itemListStatus: communityListStatus,
        selectedItemsIds: selectedCommunitiesIds,
        selectedItems: selectedCommunities,
        onItemRemove: onCommunityRemove,
        onItemsSelectionConfirm: onCommunitiesSelectionConfirm,
    } = useListWidgetSelection(
        currentSelection?.selectedCommunitiesIds,
        isManualCommunitySelected,
        getCommunityListAPI,
    );

    React.useEffect(() => {
        if (communityPickerDialogIsOpen) {
            setCommunityPickerDialogIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCommunitiesIds]);

    // Everything related to entity selection
    const { component: SelectionEntityPicker, props: entityPickerProps } = entityPicker;
    const {
        icon: pickedEntityIcon,
        image: pickedEntityImage,
        title: pickedEntityTitle,
        subtitle: pickedEntitySubtitle,
    } = entityBlock;
    const [entityPickerDialogIsOpen, setEntityPickerDialogIsOpen] = React.useState(false);
    const isManualEntitySelected = selectedOption === SELECTION_TYPE_VALUES.MANUAL;
    const manualButtonAnchorRef = React.useRef(null);

    const {
        itemListStatus: entityListStatus,
        selectedItemsIds: selectedEntitiesIds,
        selectedItems: selectedEntities,
        onItemRemove: onEntityRemove,
        onItemsSelectionConfirm: onEntitiesSelectionConfirm,
    } = useListWidgetSelection(currentSelection?.selectedEntitiesIds, isManualEntitySelected, getEntities);

    React.useEffect(() => {
        if (entityPickerDialogIsOpen) {
            setEntityPickerDialogIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntitiesIds]);

    React.useEffect(() => {
        onChange({
            selectedOption,
            selectedSubOption,
            selectedCommunitiesIds:
                isCurrentCommunitySelected && currentCommunity ? [currentCommunity.id] : selectedCommunitiesIds,
            selectedEntitiesIds,
        });
    }, [
        currentCommunity,
        isCurrentCommunitySelected,
        isManualEntitySelected,
        isManualCommunitySelected,
        onChange,
        selectedCommunitiesIds,
        selectedCommunitiesIds.length,
        selectedSubOption,
        selectedOption,
        selectedEntitiesIds,
    ]);

    return (
        <SettingSection
            title={translateKey(WIDGET_BASE.SETTINGS_SELECTION_TYPE_TITLE)}
            className={block([className, margin('vertical', 'huge')])}
        >
            <RadioGroup>
                <RadioButton
                    isChecked={isCurrentCommunitySelected}
                    isDisabled={!currentCommunity}
                    label={translateKey(COMMUNITIES.CURRENT_COMMUNITY)}
                    value={SELECTION_TYPE_VALUES.CURRENT_COMMUNITY}
                    onChange={onValueChange}
                    name="selection-type"
                    {...get({ element: 'radio', action: 'current-community' })}
                />
                <RadioButton
                    isChecked={selectedOption === SELECTION_TYPE_VALUES.DYNAMIC}
                    label={translateKey(GLOBAL.DYNAMIC)}
                    value={SELECTION_TYPE_VALUES.DYNAMIC}
                    onChange={onValueChange}
                    name="selection-type"
                    {...get({ element: 'radio', action: 'dynamic' })}
                />
                {selectedOption === SELECTION_TYPE_VALUES.DYNAMIC && (
                    <SubSection
                        className={element('sub-section', [
                            padding('left', 'big'),
                            padding('right', null),
                            margin('bottom', 'regular'),
                            margin('right', null),
                        ])}
                    >
                        <RadioGroup>
                            <RadioButton
                                isChecked={selectedSubOption === SELECTION_TYPE_VALUES.ALL_COMMUNITIES}
                                label={translateKey(COMMUNITIES.SEARCH_SCOPE_ALL)}
                                value={SELECTION_TYPE_VALUES.ALL_COMMUNITIES}
                                onChange={onSubSectionValueChange}
                                name="selection-dynamic"
                                {...get({ element: 'radio', action: 'dynamic-all-communities' })}
                            />
                            <RadioButton
                                isChecked={isManualCommunitySelected}
                                label={translateKey(COMMUNITIES.COMMUNITY_SELECTION)}
                                value={SELECTION_TYPE_VALUES.MANUAL_COMMUNITY}
                                onChange={onSubSectionValueChange}
                                name="selection-dynamic"
                                {...get({ element: 'radio', action: 'dynamic-manual-community' })}
                            />
                        </RadioGroup>
                        {isManualCommunitySelected && (
                            <>
                                <Button
                                    emphasis={Emphasis.medium}
                                    onClick={() => setCommunityPickerDialogIsOpen(true)}
                                    size={Size.s}
                                    fullWidth
                                    className={element('selection-button', [margin('top', 'regular')])}
                                    ref={communitiesButtonAnchorRef}
                                    title={translateKey(COMMUNITIES.SELECT_COMMUNITIES)}
                                >
                                    {translateKey(COMMUNITIES.SELECT_COMMUNITIES)}
                                </Button>
                                {communityListStatus === BaseLoadingStatus.loading && (
                                    <List>{SelectedEntitySkeletons}</List>
                                )}
                                {communityListStatus !== BaseLoadingStatus.loading && !isEmpty(selectedCommunities) && (
                                    <List className={padding('bottom', null)}>
                                        {selectedCommunities.map((community) => (
                                            <ListItem
                                                key={community.id}
                                                className={classnames(
                                                    background('dark', 'L6'),
                                                    margin('bottom', 'regular'),
                                                    padding('horizontal', 'regular'),
                                                )}
                                            >
                                                <GenericBlock hAlign="center">
                                                    <GenericBlock.Figure>
                                                        <ThumbnailWithIcon
                                                            size={Size.m}
                                                            alt=""
                                                            image={community.thumbnail}
                                                            icon={mdiGoogleCirclesExtended}
                                                        />
                                                    </GenericBlock.Figure>
                                                    <GenericBlock.Content
                                                        className={element('picked-item__informations')}
                                                    >
                                                        <Text
                                                            as="p"
                                                            typography="subtitle2"
                                                            title={translate(community.title)}
                                                            truncate
                                                        >
                                                            {translate(community.title)}
                                                        </Text>
                                                        <CommunityVisibility
                                                            className={element('picked-item__visibility')}
                                                            visibility={community.privacy}
                                                        />
                                                    </GenericBlock.Content>
                                                    <GenericBlock.Actions className={element('picked-item__actions')}>
                                                        <IconButton
                                                            label={translateKey(GLOBAL.REMOVE)}
                                                            emphasis={Emphasis.low}
                                                            icon={mdiClose}
                                                            onClick={() => onCommunityRemove(community.id)}
                                                            style={{ alignItem: 'right' }}
                                                            {...get({
                                                                element: 'button',
                                                                action: 'remove-selected-community',
                                                            })}
                                                        />
                                                    </GenericBlock.Actions>
                                                </GenericBlock>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </>
                        )}
                    </SubSection>
                )}
                <RadioButton
                    isChecked={isManualEntitySelected}
                    label={translateKey(GLOBAL.MANUAL)}
                    value={SELECTION_TYPE_VALUES.MANUAL}
                    onChange={onValueChange}
                    name="selection-type"
                    {...get({ element: 'radio', action: 'manual' })}
                />
                {isManualEntitySelected && (
                    <SubSection
                        className={element('sub-section', [
                            padding('left', 'big'),
                            padding('right', null),
                            margin('bottom', 'regular'),
                            margin('right', null),
                        ])}
                    >
                        <Button
                            emphasis={Emphasis.medium}
                            onClick={() => setEntityPickerDialogIsOpen(true)}
                            size={Size.s}
                            fullWidth
                            title={translateKey(manualButtonText)}
                            className={element('selection-button', [margin('top', 'regular')])}
                            ref={manualButtonAnchorRef}
                        >
                            {translateKey(manualButtonText)}
                        </Button>
                        {entityListStatus === BaseLoadingStatus.loading && (
                            <List>
                                <SelectedEntitySkeletons hasSubtitle={!!pickedEntitySubtitle} />
                            </List>
                        )}
                        {entityListStatus !== BaseLoadingStatus.loading && !isEmpty(selectedEntities) && (
                            <List className={padding('bottom', null)}>
                                {selectedEntities.map((entity: E & { id: string }) => {
                                    return (
                                        <ListItem
                                            key={entity.id}
                                            className={classnames(
                                                background('dark', 'L6'),
                                                margin('bottom', 'regular'),
                                                padding('horizontal', 'regular'),
                                            )}
                                        >
                                            <GenericBlock hAlign="center">
                                                <GenericBlock.Figure>
                                                    <ThumbnailWithIcon
                                                        size={Size.m}
                                                        alt=""
                                                        image={pickedEntityImage(entity)}
                                                        icon={pickedEntityIcon}
                                                    />
                                                </GenericBlock.Figure>
                                                <GenericBlock.Content className={element('picked-item__informations')}>
                                                    <Text
                                                        as="p"
                                                        typography="subtitle2"
                                                        title={pickedEntityTitle(entity)}
                                                        truncate
                                                    >
                                                        {pickedEntityTitle(entity)}
                                                    </Text>
                                                    {!!pickedEntitySubtitle && pickedEntitySubtitle(entity)}
                                                </GenericBlock.Content>
                                                <GenericBlock.Actions className={element('picked-item__actions')}>
                                                    <IconButton
                                                        label={translateKey(GLOBAL.REMOVE)}
                                                        emphasis={Emphasis.low}
                                                        icon={mdiClose}
                                                        onClick={() => onEntityRemove(entity.id)}
                                                        style={{ alignItem: 'right' }}
                                                        {...get({
                                                            element: 'button',
                                                            action: 'remove-selected-entity',
                                                        })}
                                                    />
                                                </GenericBlock.Actions>
                                            </GenericBlock>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </SubSection>
                )}
            </RadioGroup>

            {isManualCommunitySelected && (
                <CommunityPickerDialog
                    isOpen={communityPickerDialogIsOpen}
                    initSelectedItems={selectedCommunities.map((community) => {
                        return { community };
                    })}
                    isMultipleSelect
                    onCancel={() => setCommunityPickerDialogIsOpen(false)}
                    onConfirm={onCommunitiesSelectionConfirm}
                    communityPickerHook={useCommunityListPicker}
                    parentElement={communitiesButtonAnchorRef}
                    fetchParams={{ instanceId, sortOrder: 'title' }}
                    confirmButtonProps={{ children: translateKey(GLOBAL.SELECT) }}
                />
            )}
            {isManualEntitySelected && (
                <SelectionEntityPicker
                    {...entityPickerProps}
                    isOpen={entityPickerDialogIsOpen}
                    initSelectedItems={selectedEntities.map((entity) => {
                        return entity;
                    })}
                    onCancel={() => setEntityPickerDialogIsOpen(false)}
                    onConfirm={onEntitiesSelectionConfirm}
                    parentElement={manualButtonAnchorRef}
                />
            )}
        </SettingSection>
    );
};
