(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetEmailSettingsController($rootScope, $timeout, Widget) {
        'ngInject';

        var widgetEmailSettings = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Update widget on settings change.
         */
        function updateWidget() {
            $timeout(function timeoutBroadcast() {
                $rootScope.$broadcast('widget-email-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetEmailSettings.updateWidget = updateWidget;

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            if (angular.isUndefinedOrEmpty(properties.truncate)) {
                properties.truncate = 200;
            }
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetEmailSettingsController', WidgetEmailSettingsController);
})();
