import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import type { ElementRender } from '@lumapps/wrex/types';

import { INLINE_CODE } from '../../../constants';
import type { InlineCodeElement } from '../../../types';

import './index.scss';

export const InlineCode: ElementRender<InlineCodeElement, HTMLPreElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const theme = useThemeContext();

    return (
        <code
            {...forwardedProps}
            ref={elementRef}
            className={classnames(className, 'wrex-content-code', `wrex-content-code--theme-${theme}`)}
        >
            {children}
        </code>
    );
};
InlineCode.displayName = INLINE_CODE;
