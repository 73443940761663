import produce from 'immer';

import compact from 'lodash/compact';
import findIndex from 'lodash/findIndex';
import loFind from 'lodash/find';

// eslint-disable-next-line import/no-cycle
import { FETCH_DOCUMENTS_SUCCESS, ADD_ENTITY, REMOVE_ENTITY, SET_IS_LOADING } from '.';

// Default state.
const defaultState = [];

/**
 * Updates a set of properties for an item corresponding to the uuid given in parameters.
 *
 * @param  {Object} currentState  The current `store.getState()` value retrived from the reducer.
 * @param  {string} uuid          The uuid of the entity we want to update.
 * @param  {Object} updatedValues The properties to update.
 * @return {Object} The updated state.
 */
const stateWithUpdatedEntity = ({ currentState, uuid, updatedValues }) => {
    return currentState.map((entity) => {
        if (entity.uuid !== uuid) {
            return entity;
        }

        return { ...entity, ...updatedValues };
    });
};

/**
 * In the case cursor is defined in the request, we make sure we concat
 * the newly fetched item response with the existing one.
 *
 * @param  {Object} payload The current action payload.
 * @param  {Object} state   The current state.
 * @return {Object} The up to date `documentList`.
 */
const concatDocumentListItems = ({ payload, state }) => {
    const { documentList = {}, uuid } = payload;
    const currentWidget = loFind(state, { uuid });

    const { documentList: currentDocumentList = {} } = currentWidget;

    currentDocumentList.items = currentDocumentList.items || [];

    documentList.items = compact(currentDocumentList.items.concat(documentList.items));

    return documentList;
};

/**
 * The widget entities reducer.
 *
 * @param  {Object} state  The current state.
 * @param  {Object} action The action that will help generate the next state.
 * @return {Object} The newly produces immutable state.
 */
const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_ENTITY:
            return produce(state, (draft) => {
                draft.push(action.payload);
            });

        case SET_IS_LOADING:
            return produce(state, (draft) =>
                stateWithUpdatedEntity({
                    currentState: draft,
                    updatedValues: { isLoading: action.payload.value },
                    uuid: action.payload.uuid,
                }),
            );

        case REMOVE_ENTITY:
            return produce(state, (draft) => {
                draft.splice(
                    findIndex(state, {
                        uuid: action.payload.uuid,
                    }),
                    1,
                );
            });

        case FETCH_DOCUMENTS_SUCCESS:
            return produce(state, (draft) =>
                stateWithUpdatedEntity({
                    currentState: draft,
                    updatedValues: {
                        documentList: action.payload.append
                            ? concatDocumentListItems({ payload: action.payload, state: draft })
                            : action.payload.documentList,
                    },
                    uuid: action.payload.uuid,
                }),
            );

        default:
            return state;
    }
};

export { reducer };
