import React from 'react';

import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { ButtonProps, DropdownProps } from '@lumapps/lumx/react';

interface CustomizationDropdownProps extends DropdownProps {
    children: React.ReactNode;
    buttonProps?: ButtonProps;
    label: string;
}

/**
 * Wrapper for the DS Dropdown in order to allow the customizations API to use
 * dropdowns as easily as possible. This specifically avoids developers from the customizations API
 * to need the `useRef` hook outside of React.
 * @param CustomizationDropdownProps
 */
export const CustomizationDropdown: React.FC<CustomizationDropdownProps> = ({
    children,
    buttonProps = {},
    label,
    ...props
}) => {
    return (
        <Menu.Trigger popoverProps={props}>
            <Menu.TriggerButton {...buttonProps}>{label}</Menu.TriggerButton>
            <Menu>{children}</Menu>
        </Menu.Trigger>
    );
};

CustomizationDropdown.displayName = 'Dropdown';
