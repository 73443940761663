import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export interface UseParseQueryParamsOptions {
    filter?: (key: string, value: string) => boolean;
    rawUrlParams?: string;
}

/**
 * Parses a query param string into a record.
 * @param UseParseQueryParamsOptions
 * @returns Record<string, string>
 */
const useParseQueryParams = ({ rawUrlParams, filter }: UseParseQueryParamsOptions) => {
    const queryParams: Record<string, string> = useMemo(() => {
        const parsedParams: Record<string, string> = {};

        if (rawUrlParams && rawUrlParams.replace) {
            const params = rawUrlParams.replace('?', '').split('&');

            params.forEach((param) => {
                const tokens = param.split('=');
                if (tokens.length === 2) {
                    const [key, value] = tokens;
                    if (!filter || (filter && filter(key, value))) {
                        parsedParams[key] = decodeURIComponent(value);
                    }
                } else if (tokens.length === 1) {
                    const key = tokens[0];
                    if (!filter || (filter && filter(key, 'true'))) {
                        parsedParams[key] = 'true';
                    }
                }
            });
        }

        return parsedParams;
    }, [rawUrlParams, filter]);

    return queryParams;
};

export interface UseQueryParamsOptions {
    filter?: UseParseQueryParamsOptions['filter'];
}
export type UseQueryParams = Record<string, string>;

/**
 * Retrieves the current query params for the current URL.
 * @param UseQueryParamsOptions
 * @returns Record<string, string>
 */
const useQueryParams = (options?: UseQueryParamsOptions): UseQueryParams => {
    const { filter } = options || {};
    let rawUrlParams = '';
    const { search } = useLocation();

    if (!search || search === '') {
        rawUrlParams = window.location.search;
    } else {
        rawUrlParams = search;
    }

    const queryParams = useParseQueryParams({ rawUrlParams, filter });

    return queryParams;
};

export { useQueryParams, useParseQueryParams };
