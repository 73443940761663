import { CommunityPrivacy } from '@lumapps/communities/types';
import { Interest } from '@lumapps/content-interest/types';
import { TranslateObject } from '@lumapps/translations';
import { User } from '@lumapps/user/types';

export enum SubscriptionsTypes {
    user = 'user',
    content = 'content',
    interest = 'interest',
}

export enum SubscriptionContentTypes {
    community = 'community',
    content = 'content',
}

export enum FollowingContentType {
    community = 'community',
    directory = 'directory',
    custom = 'custom',
    customList = 'custom_list',
    news = 'news',
    newsList = 'news_list',
    page = 'page',
}

export enum UseSubscriptionStatuses {
    initial = 'initial',
    idle = 'idle',
    loading = 'loading',
    changing = 'changing',
    error = 'error',
}

export interface SubscriptionType {
    followerUserDetails: Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'apiProfile'>;
    uid: string;
    customerKey: string;
    instanceKey: string;
    id: string;
    followingContentType: FollowingContentType;
    followingContentDetails: {
        postTypes: ['DEFAULT'];
        publishers: string[];
        uid: string;
        excerpt: TranslateObject;
        thumbnail: string;
        authorId: string;
        id: string;
        fullName: string;
        canonicalUrl: TranslateObject;
        privacy: CommunityPrivacy;
        title: TranslateObject;
        author: string;
        slug: TranslateObject;
        instance: string;
        thumbnailBlobkey: string;
        adminKeys: string[];
        type: FollowingContentType;
        link?: TranslateObject;
    };
    followingContentKey: string;
    notify: boolean;
    followerUserKey: string;
    type: 'USER_CONTENT';
}

/** enum with all the available Chip filters for the subscriptions page */
export enum USER_SUBSCRIPTIONS_FILTERS {
    communities = 'communities',
    content = 'content',
    people = 'people',
    interests = 'interests',
    followers = 'followers',
}

/**
 * The resource type expected for each filters.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type SUBSCRIPTION_FILTER_RETURN_TYPE<T = USER_SUBSCRIPTIONS_FILTERS> =
    // Communities and content return the same resource
    T extends USER_SUBSCRIPTIONS_FILTERS.communities | USER_SUBSCRIPTIONS_FILTERS.content
        ? SubscriptionType // People and Followers return the same resource
        : T extends USER_SUBSCRIPTIONS_FILTERS.people | USER_SUBSCRIPTIONS_FILTERS.followers
        ? User // Interests has its own resource
        : T extends USER_SUBSCRIPTIONS_FILTERS.interests
        ? Interest
        : unknown;

/** Asserts that the given object is either a content or a community */
export const subscriptionIsContent = (
    item: SUBSCRIPTION_FILTER_RETURN_TYPE,
): item is
    | SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.content>
    | SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.communities> => {
    return Boolean(
        (item as SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.content>)?.followingContentDetails,
    );
};
/** Asserts that the given object is a user */
export const subscriptionIsUser = (
    item: SUBSCRIPTION_FILTER_RETURN_TYPE,
): item is
    | SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.people>
    | SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.followers> => {
    return Boolean((item as SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.people>)?.email);
};

/** Asserts that the given object is an interest */
export const subscriptionIsInterest = (
    item: SUBSCRIPTION_FILTER_RETURN_TYPE,
): item is SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.interests> => {
    return Boolean((item as SUBSCRIPTION_FILTER_RETURN_TYPE<USER_SUBSCRIPTIONS_FILTERS.interests>)?.customContentType);
};
