import React, { useMemo, useRef } from 'react';

import omit from 'lodash/omit';

import { paramsSerializer } from '@lumapps/base-api';
import { getCurrentContentId } from '@lumapps/contents/ducks/selectors';
import { getFunctionalOverride, Targets } from '@lumapps/customizations/api';
import { useSelector } from '@lumapps/redux/react';
import { useLocation } from '@lumapps/router';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { getToken } from '@lumapps/user/ducks/selectors';
import { secureUrl } from '@lumapps/utils/string/isUrl';

import { BlockIframeProps, PlayerAspectRatio } from '../../types';

import './index.scss';

export interface Params {
    parentUrl: string;
    iframeId: string;
    contentId?: string;
    token?: string;
}

export const BlockIframe: React.FC<BlockIframeProps> = (props) => {
    // TODO => DECIDE IF WE KEEP THE "DISABLE SCROLL" FEATURE SINCE IT'S A DEPRECATED PROPERTY
    const { id, properties = {}, aspectRatio = PlayerAspectRatio.auto, defaultHeight = '500px' } = props;
    const token = useSelector(getToken);
    const contentId = useSelector(getCurrentContentId);
    const { pathname } = useLocation();
    const { translateObject } = useTranslate();
    const ref = useRef<any>();

    const { displayBorders, height, scrollDisabled, sendContentId, sendUserIdentity, title, url, width } = properties;

    const currentUrl = window.location.href;
    const currentAbsoluteUrl = `${currentUrl}${pathname}`;
    const translatedUrl = translateObject(standardizeTranslateObject(url));
    const translatedTitle = translateObject(standardizeTranslateObject(title));

    // Removing the potential fragment from the url, in order to report it later in the end of the url
    const [translateUrlWithoutFragment = null, urlFragment = ''] = translatedUrl?.split(/[#](.+)/) ?? [];

    const defaultCustomStyles = {
        overflow: scrollDisabled ? 'hidden' : 'scroll',
        border: 'none', // with this line, by default, the iframe has no border
        aspectRatio,
    };

    // if the user checks the "display border" option in iframe settings we remove the border property from component styles
    const customStyles = displayBorders ? omit(defaultCustomStyles, 'border') : defaultCustomStyles;

    const srcUrl = useMemo(() => {
        if (!translateUrlWithoutFragment) {
            return undefined;
        }
        const getCustomParams = () => {
            const params: Params = {
                parentUrl: currentAbsoluteUrl,
                iframeId: id,
            };
            if (sendContentId) {
                // add contentId params to the iframe url if option checked in widget advanced settings
                params.contentId = contentId;
            }
            if (sendUserIdentity) {
                // add user token params to the iframe url if option checked in widget advanced settings
                params.token = token;
            }
            return params;
        };

        // prevent url xss => url startsWith http  => if no add http://+url
        const baseUrl = secureUrl(translateUrlWithoutFragment);
        // build final srcUrl with params depending on user options
        return `${baseUrl}${baseUrl?.includes('?') ? '&' : '?'}${paramsSerializer(getCustomParams())}${
            urlFragment ? `#${urlFragment}` : ''
        }`;
    }, [
        contentId,
        currentAbsoluteUrl,
        id,
        sendContentId,
        sendUserIdentity,
        token,
        translateUrlWithoutFragment,
        urlFragment,
    ]);

    const overrides = getFunctionalOverride(Targets.WIDGET_IFRAME);

    return (
        <iframe
            ref={ref}
            src={srcUrl}
            title={translatedTitle || srcUrl}
            style={customStyles}
            scrolling={scrollDisabled ? 'no' : 'yes'}
            onLoad={overrides.onload ? () => overrides.onload(ref.current) : undefined}
            allow="fullscreen"
            width={width || '100%'}
            height={height || defaultHeight}
        />
    );
};
