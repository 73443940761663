import { Theme } from '@lumapps/lumx/react';
import { LegacyWidgetProperties } from '@lumapps/widget-base/types';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

export interface ShareLinkProps {
    theme?: Theme;
    id: string;
}

export const ShareTypes = ['email', 'facebook', 'google-currents', 'linkedin', 'twitter'];
interface ShareField {
    id: string;
    enable: boolean;
}

export interface ShareProps {
    shareOn: ShareField[];
    style: LegacyWidgetProperties['style'];
}

export interface ClientComputedShareProps extends ClientComputedProps {
    properties?: ShareProps;
}
