import loFind from 'lodash/find';

import { documentTypes } from 'front/modules/media/js/media_constant';
import { WidgetSettingsConstant } from 'front/modules/content/modules/widget/common/js/widget-settings_constant';

import { fetchProviders } from '../../../document/ducks/providers';
import { getMultiDocumentsTask, fetchDocumentListTask } from '../../../document/ducks/documents/documents_tasks';

const ADD_ENTITY = 'widget/entities/ADD_ENTITY';

const REMOVE_ENTITY = 'widget/entities/REMOVE_ENTITY';

const FETCH_DOCUMENTS = 'widget/entities/FETCH_DOCUMENTS';

const FETCH_DOCUMENTS_ERROR = 'widget/entities/FETCH_DOCUMENTS_ERROR';

const FETCH_DOCUMENTS_SUCCESS = 'widget/entities/FETCH_DOCUMENTS_SUCCESS';

const SET_IS_LOADING = 'widget/entities/SET_IS_LOADING';

/**
 * Fetch documents for a widget entity.
 *
 * @param  {Object}   params The params that will help build the correct request.
 * @return {Function} The action creator's thunk.
 */
const fetchDocuments = (params) => (dispatch, getState) => {
    dispatch(fetchProviders()).then(async () => {
        const { providers } = getState().document;
        const { displayType = WidgetSettingsConstant.SELECTION_TYPES.PICK } = params;
        let response;

        dispatch({
            payload: {
                uuid: params.uuid,
                value: true,
            },
            type: SET_IS_LOADING,
        });

        try {
            if (displayType === WidgetSettingsConstant.SELECTION_TYPES.PICK) {
                response = await getMultiDocumentsTask(providers, params);
            } else {
                response = await fetchDocumentListTask(providers, params);
            }

            dispatch({
                payload: { append: Boolean(params.cursor), documentList: response.data, uuid: params.uuid },
                type: FETCH_DOCUMENTS_SUCCESS,
            });

            dispatch({
                payload: {
                    uuid: params.uuid,
                    value: false,
                },
                type: SET_IS_LOADING,
            });
        } catch (exception) {
            dispatch({
                payload: exception,
                type: FETCH_DOCUMENTS_ERROR,
            });

            dispatch({
                payload: {
                    uuid: params.uuid,
                    value: false,
                },
                type: SET_IS_LOADING,
            });
        }
    });
};

/**
 * Fetch images for a widget entity.
 * This function is just a `fetchDocuments` decorator.
 *
 * @param  {Object}   params The params that will help build the correct request.
 * @return {Function} The action creator's thunk.
 */
const fetchImages = (params) => (dispatch) => {
    dispatch(fetchDocuments({ ...params, searchTypes: documentTypes.images }));
};

/**
 * Fetches a new page for a widget which is displaying documents.
 *
 * @param {Object} params All the request parameters.
 */
const fetchNextPage = (params) => (dispatch, getState) => {
    const currentWidget =
        loFind(getState().widgets.entities, {
            uuid: params.uuid,
        }) || {};

    const { documentList, isLoading } = currentWidget;

    if (documentList && documentList.cursor && !isLoading) {
        dispatch(fetchDocuments({ cursor: documentList.cursor, ...params, searchTypes: documentTypes.images }));
    }
};

/**
 * Adds a widget entity into redux store.
 *
 * @param  {Object}   entity The corresponding widget entity.
 * @return {Function} The action creator's thunk.
 */
const addEntity = (entity) => (dispatch) => {
    dispatch({
        payload: entity,
        type: ADD_ENTITY,
    });
};

/**
 * Removes a widget entity into redux store.
 *
 * @param  {Object}   entity The corresponding widget entity.
 * @return {Function} The action creator's thunk.
 */
const removeEntity = (entity) => (dispatch) => {
    dispatch({
        payload: entity,
        type: REMOVE_ENTITY,
    });
};

export {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_SUCCESS,
    ADD_ENTITY,
    REMOVE_ENTITY,
    SET_IS_LOADING,
    addEntity,
    fetchDocuments,
    fetchImages,
    fetchNextPage,
    removeEntity,
};
