import mdast from 'mdast';

import { DeserializeOptions } from '@lumapps/wrex/serialization/markdown/fromMarkdown/types';

import { createCodeBlock } from '../../utils/createCodeBlock';
import { createHeadline } from '../../utils/createHeadline';
import { createInlineCode } from '../../utils/createInlineCode';
import { createParagraph } from '../../utils/createParagraph';
import { createTitle } from '../../utils/createTitle';

export const TYPOGRAPHY_MARKS: DeserializeOptions['marks'] = {
    strong: 'bold',
    emphasis: 'italic',
    delete: 'strikethrough',
};

export const TYPOGRAPHY_ELEMENTS: DeserializeOptions['elements'] = {
    paragraph: {
        convert: (_, children) => createParagraph(children),
    },
    heading: {
        convert: ({ depth }: mdast.Heading, children) => {
            if (!children.length) {
                // Children can't be empty.
                children.push({ text: '' });
            }
            if (depth === 1) {
                return createHeadline(children);
            }
            return createTitle(children);
        },
    },
    code: {
        convert: (node) => {
            return createCodeBlock([{ text: node.value }]);
        },
    },
    inlineCode: {
        convert: (node) => {
            return createInlineCode([{ text: node.value }]);
        },
    },
};
