import React from 'react';

import { ARTICLES_SORTING } from '@lumapps/articles/types';
import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { RadioButton, RadioGroup } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { SettingSection } from '@lumapps/widget-settings/components/SettingSection';

import { DATA_SCOPE } from '../../../constants';
import { WidgetArticleListPropertiesType } from '../../../types';

export interface SortingSectionProps {
    currentSorting?: ARTICLES_SORTING;
    onChange: (properties: Pick<WidgetArticleListPropertiesType, 'sort'>) => void;
}

const CLASSNAME = 'sorting-section';

export const SortingSection: React.FC<SortingSectionProps> = ({ currentSorting, onChange }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(`${DATA_SCOPE}-sorting`);
    const { block } = useClassnames(CLASSNAME);
    const [value, setValue] = React.useState(currentSorting || ARTICLES_SORTING.CREATED_ASC);

    const onValueChange = (newValue: ARTICLES_SORTING) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        onChange({ sort: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <SettingSection title={translateKey(GLOBAL.SORTING)} className={block([margin('vertical', 'huge')])}>
            <RadioGroup>
                <RadioButton
                    isChecked={value === ARTICLES_SORTING.CREATED_ASC}
                    label={translateKey(GLOBAL.OLDEST_TO_LATEST)}
                    value={ARTICLES_SORTING.CREATED_ASC}
                    onChange={onValueChange}
                    name="sorting-mode"
                    {...get({ element: 'radio', action: 'oldest-to-latest' })}
                />
                <RadioButton
                    isChecked={value === ARTICLES_SORTING.CREATED_DESC}
                    label={translateKey(GLOBAL.LATEST_TO_OLDEST)}
                    value={ARTICLES_SORTING.CREATED_DESC}
                    onChange={onValueChange}
                    name="sorting-mode"
                    {...get({ element: 'radio', action: 'latest-to-oldest' })}
                />
            </RadioGroup>
        </SettingSection>
    );
};
