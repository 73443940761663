import { CHANNEL_TYPES } from '@lumapps/journeys/types';
import { lumappsBroadcast } from '@lumapps/lumx/custom-icons';
import { mdiArchiveOutline, mdiPencil } from '@lumapps/lumx/icons';
import { ColorPalette } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations';

import { BROADCASTS } from './keys';
import { BROADCAST_STATUS } from './types';

export const SCOPE = 'broadcast';

export const BROADCAST_FF = 'journey-broadcast';

export enum BROADCAST_ACTIONS {
    EDIT = 'EDIT',
    DUPLICATE = 'DUPLICATE',
    ARCHIVE = 'ARCHIVE',
    UNARCHIVE = 'UNARCHIVE',
    DELETE = 'DELETE',
    BACK_TO_DRAFT = 'BACK_TO_DRAFT',
    INSIGHTS = 'INSIGHTS',
}

export const DEFAULT_FILTERS_STATUSES = [BROADCAST_STATUS.DRAFT, BROADCAST_STATUS.SCHEDULED, BROADCAST_STATUS.SENT];

/**
 * Configuration to be used for displaying status with their associated
 * colors and labels.
 */

const sharedActions = {
    [BROADCAST_STATUS.DRAFT]: {
        [BROADCAST_ACTIONS.EDIT]: false,
        [BROADCAST_ACTIONS.DUPLICATE]: true,
        [BROADCAST_ACTIONS.ARCHIVE]: false,
        [BROADCAST_ACTIONS.UNARCHIVE]: false,
        [BROADCAST_ACTIONS.BACK_TO_DRAFT]: false,
        [BROADCAST_ACTIONS.DELETE]: true,
        [BROADCAST_ACTIONS.INSIGHTS]: false,
    },
    [BROADCAST_STATUS.SCHEDULED]: {
        [BROADCAST_ACTIONS.EDIT]: false,
        [BROADCAST_ACTIONS.DUPLICATE]: true,
        [BROADCAST_ACTIONS.ARCHIVE]: false,
        [BROADCAST_ACTIONS.UNARCHIVE]: false,
        [BROADCAST_ACTIONS.BACK_TO_DRAFT]: true,
        [BROADCAST_ACTIONS.DELETE]: true,
        [BROADCAST_ACTIONS.INSIGHTS]: false,
    },
    [BROADCAST_STATUS.SENT]: {
        [BROADCAST_ACTIONS.EDIT]: false,
        [BROADCAST_ACTIONS.DUPLICATE]: true,
        [BROADCAST_ACTIONS.ARCHIVE]: true,
        [BROADCAST_ACTIONS.UNARCHIVE]: false,
        [BROADCAST_ACTIONS.BACK_TO_DRAFT]: false,
        [BROADCAST_ACTIONS.DELETE]: false,
        [BROADCAST_ACTIONS.INSIGHTS]: false,
    },
    [BROADCAST_STATUS.ARCHIVED]: {
        [BROADCAST_ACTIONS.EDIT]: false,
        [BROADCAST_ACTIONS.DUPLICATE]: true,
        [BROADCAST_ACTIONS.ARCHIVE]: false,
        [BROADCAST_ACTIONS.UNARCHIVE]: false,
        [BROADCAST_ACTIONS.BACK_TO_DRAFT]: false,
        [BROADCAST_ACTIONS.DELETE]: false,
        [BROADCAST_ACTIONS.INSIGHTS]: false,
    },
};

export const STATUS_CONFIG = {
    labels: {
        [BROADCAST_STATUS.DRAFT]: GLOBAL.DRAFT,
        [BROADCAST_STATUS.SCHEDULED]: GLOBAL.SCHEDULED,
        [BROADCAST_STATUS.SENT]: GLOBAL.SENT,
        [BROADCAST_STATUS.ARCHIVED]: GLOBAL.ARCHIVED,
    },
    colors: {
        [BROADCAST_STATUS.DRAFT]: ColorPalette.yellow,
        [BROADCAST_STATUS.SCHEDULED]: ColorPalette.green,
        [BROADCAST_STATUS.SENT]: ColorPalette.blue,
        [BROADCAST_STATUS.ARCHIVED]: ColorPalette.dark,
    },
    successLabels: {
        [BROADCAST_STATUS.DRAFT]: BROADCASTS.BROADCAST_SAVED,
        [BROADCAST_STATUS.SCHEDULED]: BROADCASTS.BROADCAST_SCHEDULED,
        [BROADCAST_STATUS.SENT]: BROADCASTS.BROADCAST_SCHEDULED,
        [BROADCAST_STATUS.ARCHIVED]: BROADCASTS.BROADCAST_ARCHIVED,
    },
    cardActions: {
        [BROADCAST_STATUS.DRAFT]: {
            [BROADCAST_ACTIONS.EDIT]: true,
            [BROADCAST_ACTIONS.DUPLICATE]: true,
            [BROADCAST_ACTIONS.ARCHIVE]: false,
            [BROADCAST_ACTIONS.UNARCHIVE]: false,
            [BROADCAST_ACTIONS.BACK_TO_DRAFT]: false,
            [BROADCAST_ACTIONS.DELETE]: true,
            [BROADCAST_ACTIONS.INSIGHTS]: false,
        },
        [BROADCAST_STATUS.SCHEDULED]: {
            [BROADCAST_ACTIONS.EDIT]: true,
            [BROADCAST_ACTIONS.DUPLICATE]: true,
            [BROADCAST_ACTIONS.ARCHIVE]: false,
            [BROADCAST_ACTIONS.UNARCHIVE]: false,
            [BROADCAST_ACTIONS.BACK_TO_DRAFT]: true,
            [BROADCAST_ACTIONS.DELETE]: true,
            [BROADCAST_ACTIONS.INSIGHTS]: false,
        },
        [BROADCAST_STATUS.SENT]: {
            [BROADCAST_ACTIONS.EDIT]: false,
            [BROADCAST_ACTIONS.DUPLICATE]: true,
            [BROADCAST_ACTIONS.ARCHIVE]: true,
            [BROADCAST_ACTIONS.UNARCHIVE]: false,
            [BROADCAST_ACTIONS.BACK_TO_DRAFT]: false,
            [BROADCAST_ACTIONS.DELETE]: false,
            [BROADCAST_ACTIONS.INSIGHTS]: true,
        },
        [BROADCAST_STATUS.ARCHIVED]: {
            [BROADCAST_ACTIONS.EDIT]: false,
            [BROADCAST_ACTIONS.DUPLICATE]: true,
            [BROADCAST_ACTIONS.ARCHIVE]: false,
            [BROADCAST_ACTIONS.UNARCHIVE]: true,
            [BROADCAST_ACTIONS.BACK_TO_DRAFT]: false,
            [BROADCAST_ACTIONS.DELETE]: false,
            [BROADCAST_ACTIONS.INSIGHTS]: true,
        },
    },
    drawerActions: sharedActions,
    drawerButtonAction: {
        [BROADCAST_STATUS.DRAFT]: {
            label: GLOBAL.EDIT,
            icon: mdiPencil,
            action: BROADCAST_ACTIONS.EDIT,
        },
        [BROADCAST_STATUS.SCHEDULED]: {
            label: GLOBAL.EDIT,
            icon: mdiPencil,
            action: BROADCAST_ACTIONS.EDIT,
        },
        [BROADCAST_STATUS.SENT]: null,
        [BROADCAST_STATUS.ARCHIVED]: {
            label: GLOBAL.UNARCHIVE,
            icon: mdiArchiveOutline,
            action: BROADCAST_ACTIONS.UNARCHIVE,
        },
    },
    viewActions: {
        ...sharedActions,
        [BROADCAST_STATUS.ARCHIVED]: {
            ...sharedActions[BROADCAST_STATUS.ARCHIVED],
            [BROADCAST_ACTIONS.UNARCHIVE]: true,
        },
    },
    statusForAction: {
        [BROADCAST_ACTIONS.ARCHIVE]: BROADCAST_STATUS.ARCHIVED,
        [BROADCAST_ACTIONS.UNARCHIVE]: BROADCAST_STATUS.SENT,
        [BROADCAST_ACTIONS.BACK_TO_DRAFT]: BROADCAST_STATUS.DRAFT,
        [BROADCAST_ACTIONS.EDIT]: BROADCAST_STATUS.DRAFT,
        [BROADCAST_ACTIONS.DELETE]: BROADCAST_STATUS.DRAFT,
        [BROADCAST_ACTIONS.DUPLICATE]: BROADCAST_STATUS.DRAFT,
        [BROADCAST_ACTIONS.INSIGHTS]: undefined,
    },
    successLabelsForActions: {
        [BROADCAST_ACTIONS.UNARCHIVE]: BROADCASTS.BROADCAST_UNARCHIVED,
    },
};

/**
 * List of translatable fields for a broadcast. These fields will be considered during the translations
 * process. However adding a new field does not automatically display the field in the translations dialog.
 * For that, the component BroadcastDetailedTranslation needs to be edited.
 */
export const BROADCAST_TRANSLATABLE_FIELDS: Record<string, { id: string; path: string }> = {
    resourceTitle: {
        id: 'resourceTitle',
        path: 'resource.title',
    },
    resourceDescription: {
        id: 'resourceDescription',
        path: 'resource.description',
    },
    senderName: {
        id: 'senderName',
        path: 'channelConfiguration.senderName',
    },
    emailSubject: {
        id: 'emailSubject',
        path: 'channelConfiguration.subject',
    },
    emailMessage: {
        id: 'emailMessage',
        path: 'channelConfiguration.message',
    },
    richText: {
        id: 'richText',
        path: 'richText',
    },
};

export const BROADCAST_ICON = lumappsBroadcast;

export const BROADCAST_TIMELINE_ENTRY_MIN_HEIGHT = 152;

export const INSIGHTS_ALLOWED_CHANNELS = [
    CHANNEL_TYPES.EMAIL,
    CHANNEL_TYPES.MARKETPLACE_EXTENSION,
    CHANNEL_TYPES.MOBILE_PUSH,
];
