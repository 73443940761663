import React from 'react';

import { ButtonSize, FlexBox, Size, SkeletonCircle, SkeletonTypography, Theme } from '@lumapps/lumx/react';

/**
 *  Press tab to update CLASSNAME, then tab again to fill in Component description
 */
export const MetadataButtonSkeleton: React.FC<{ labelSize?: number; theme?: Theme; buttonSize?: ButtonSize }> = ({
    labelSize = 10,
    theme = Theme.light,
    buttonSize,
}) => {
    return (
        <FlexBox
            style={buttonSize === Size.s ? { padding: '0 4px' } : { padding: '6px 8px' }}
            hAlign="center"
            orientation="horizontal"
        >
            <SkeletonCircle theme={theme} size={buttonSize === Size.s ? Size.xxs : Size.xs} />
            <SkeletonTypography
                theme={theme}
                width={labelSize}
                style={{ marginLeft: buttonSize === Size.s ? 4 : 8 }}
                typography="subtitle2"
            />
        </FlexBox>
    );
};
