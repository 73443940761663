import React, { useMemo, RefObject } from 'react';

import { margin } from '@lumapps/classnames';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import {
    Orientation,
    UserBlock,
    Size,
    DropdownProps,
    Popover,
    Placement,
    SkeletonTypography,
    Typography,
} from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useSubscription } from '@lumapps/subscriptions/hooks/useSubscription';
import { UseSubscriptionStatuses, SubscriptionsTypes } from '@lumapps/subscriptions/types';

import { isHideEmailEnabled, isConnected } from '../../ducks/selectors';
import { User } from '../../types';
import { getUserFullName } from '../../utils/getUserFullName';
import { getUserProfilePictureUrl } from '../../utils/getUserProfilePicture';
import { UserCardActions } from '../UserCard/UserCardActions';

import './index.scss';

export interface UserCardPopoverProps {
    /** user to display */
    user: User;
    /** element that the card will be anchored to */
    anchorRef: RefObject<HTMLElement>;
    /** determines whether the card is currently open or not */
    isDetailsOpen: boolean;
    /** picture to be used for the user card */
    profilePicture?: string;
    /** whether the user card should display the email or not */
    hideEmail?: boolean;
    /** whether the user card should display the email or not */
    showFollow?: boolean;
    /** callback to be executed */
    onClose: DropdownProps['onClose'];
    /** whether the user card is loading or not */
    isLoading?: boolean;
    /** callback on mouse enter */
    onMouseEnter?: () => void;
    /** optional user block classname */
    className?: string;
    /** optional popover placement */
    placement?: Placement;
}

const NAMESPACE = 'user-card-popover';
const UserCardPopover: React.FC<UserCardPopoverProps> = ({
    user,
    anchorRef,
    isDetailsOpen,
    profilePicture,
    hideEmail = false,
    showFollow = false,
    isLoading = false,
    onMouseEnter,
    className = NAMESPACE,
    onClose,
    placement,
}) => {
    const {
        status: useSubscriptionStatus,
        isSubscribed,
        toggleSubscription,
        fetchResourceSubscription,
    } = useSubscription({
        resourceType: SubscriptionsTypes.user,
        resourceId: user?.id,
        autoFetch: false,
    });

    const isEmailHidden = useSelector(isHideEmailEnabled);

    const isCurrentUserConnected = useSelector(isConnected);
    const currentLanguage = useSelector(currentLanguageSelector);

    React.useEffect(() => {
        if (isDetailsOpen && showFollow && useSubscriptionStatus === UseSubscriptionStatuses.initial) {
            fetchResourceSubscription();
        }
    }, [showFollow, isDetailsOpen, useSubscriptionStatus, fetchResourceSubscription]);

    const onMouseLeave = () => {
        if (onClose) {
            onClose();
        }
    };

    const userFields = useMemo(() => {
        const uF: string[] = [];
        if (user.email && !hideEmail && !isEmailHidden) {
            uF.push(user.email);
        }
        if (user.apiProfile?.organizations?.[0]?.title) {
            uF.push(user.apiProfile?.organizations[0].title);
        }
        if (user.apiProfile?.organizations?.[0]?.department) {
            uF.push(user.apiProfile?.organizations[0].department);
        }

        return uF;
    }, [user, hideEmail, isEmailHidden]);

    const avatarImage = profilePicture || getUserProfilePictureUrl(user);

    if (!isCurrentUserConnected) {
        return null;
    }

    return (
        <Popover
            anchorRef={anchorRef}
            isOpen={isDetailsOpen}
            onClose={onClose}
            offset={{ away: 8 }}
            placement={placement}
        >
            <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                {user && (
                    <UserBlock
                        name={getUserFullName(user, currentLanguage)}
                        /** Don't show fields until they are all loaded. */
                        fields={isLoading ? undefined : userFields}
                        className={className}
                        simpleAction={
                            <>
                                {/* userFields loading skeletons. */}
                                {isLoading && (
                                    <div className={margin('bottom', 'big')}>
                                        <SkeletonTypography
                                            typography={Typography.overline}
                                            style={{ width: '90px', margin: '-10px auto 4px auto' }}
                                        />
                                        <SkeletonTypography
                                            typography={Typography.overline}
                                            style={{ width: '110px', margin: '0 auto' }}
                                        />
                                    </div>
                                )}
                                <UserCardActions
                                    userLoading={isLoading}
                                    showFollow={showFollow}
                                    isSubscribed={isSubscribed}
                                    toggleSubscription={toggleSubscription}
                                    useSubscriptionStatus={useSubscriptionStatus}
                                    userId={user.id}
                                    userData={{
                                        id: user.id,
                                        fullName: user.fullName,
                                    }}
                                />
                            </>
                        }
                        size={Size.l}
                        orientation={Orientation.vertical}
                        avatarProps={
                            avatarImage
                                ? {
                                      image: avatarImage,
                                      alt: user.fullName,
                                  }
                                : undefined
                        }
                    />
                )}
            </div>
        </Popover>
    );
};

export * from './useCardPopover';

export { UserCardPopover };
