(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSubNavSettingsController(Content, MainNav, Translation, Utils, Widget) {
        'ngInject';

        var widgetSubNavSettings = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetSubNavSettings.MainNav = MainNav;
        widgetSubNavSettings.Translation = Translation;
        widgetSubNavSettings.Utils = Utils;
        widgetSubNavSettings.Widget = Widget;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Get all the nav items that point to the current content.
            widgetSubNavSettings.navItemContentList = MainNav.findNavItemsById(Content.getCurrent().id);

            /*
             * Keep a ref of all the slugs used in the main nav so it's easier to compare further down.
             * These are the result for the current language only.
             */
            var subNavSettingsSlugs = widgetSubNavSettings.navItemContentList.map(function mapNavItems(value) {
                if (value.slugFull) {
                    return Utils.getParentFullSlug(Translation.translate(value.slugFull));
                }

                return undefined;
            });

            // This is our value for the default sub nav custom settings.
            subNavSettingsSlugs.push('__default');

            var currentWidget = Widget.getCurrent();
            /*
             * Cleanup the existing keys / objects (in case they're not in the main nav anymore, there's no need to
             * keep them).
             * Only remove the ones in the current input language.
             */
            angular.forEach(_.get(currentWidget, 'properties[Translation.inputLanguage]'),
                function forEachProperties(value, key) {
                    if (value.listCurrentSub && Translation.hasTranslation(value.navSelected) &&
                        !_.includes(subNavSettingsSlugs, key)) {
                        delete currentWidget.properties.key;
                    }
                }
            );
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetSubNavSettingsController', WidgetSubNavSettingsController);
})();
