import { combineReducers } from '@lumapps/redux';

import { membersReducer } from './community_members_reducer';
import { communityReducer } from './community_reducer';
import { filesReducer } from './community_files_reducer';
import { groupReducer } from './community_group_reducer';
import { appsReducer } from './community_apps_reducer';

const communityreducer = combineReducers({
    apps: appsReducer,
    community: communityReducer,
    files: filesReducer,
    group: groupReducer,
    members: membersReducer,
});

export default communityreducer;
