import { Route } from '@lumapps/router';
import { URL_PREFIX, AppId } from '@lumapps/router/constants';

const savedItemsRoute: Route = {
    path: `/${URL_PREFIX}/saved-items`,
    legacyId: 'app.front.saved-items',
    appId: AppId.frontOffice,
};

const savedItems = (): Route => ({
    ...savedItemsRoute,
    params: {},
});

export { savedItems, savedItemsRoute };
