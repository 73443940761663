import { getCurrentContentId, getCurrentContentSlug } from '@lumapps/contents/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useRouteMatching, useRouter } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { CommunityView } from '../constants';
import {
    isCurrentCommunityEditableByUser,
    doesCurrentCommunityHaveDashboard,
    canCurrentUserViewCommunityAnalytics,
    getCommunityPrivacy,
    getCommunityTitle,
    isUserManagerForCurrentCommunity,
} from '../ducks/selectors';
import { communityPagesRoute } from '../routes';

export interface UseCommunityApi {
    isCommunityPage: boolean;
    canEditCommunity: boolean;
    canViewCommunityAnalytics: boolean;
    currentCommunityId?: string;
    view?: CommunityView;
    slug?: string | null;
    privacy?: string | null;
    title?: string | null;
    isManager: boolean;
}

export const useCommunity = (): UseCommunityApi => {
    const { context } = useRouter();
    const { translate } = useTranslate();
    const isCommunityPage = useRouteMatching(communityPagesRoute);
    const canEditCommunity = useSelector(isCurrentCommunityEditableByUser);
    const isManager = useSelector(isUserManagerForCurrentCommunity);
    const currentCommunityId = useSelector(getCurrentContentId);
    const canViewCommunityAnalytics = useSelector(canCurrentUserViewCommunityAnalytics);
    const hasDasboard = useSelector(doesCurrentCommunityHaveDashboard);
    const slug = useSelector(getCurrentContentSlug);
    const privacy = useSelector(getCommunityPrivacy);
    const title = useSelector(getCommunityTitle);
    const defaultView = hasDasboard ? CommunityView.dashboard : CommunityView.posts;

    return {
        isCommunityPage,
        canEditCommunity: Boolean(canEditCommunity),
        isManager,
        currentCommunityId,
        view: context?.params?.view ? context?.params?.view : defaultView,
        slug: slug ? translate(slug) : '',
        canViewCommunityAnalytics: Boolean(canViewCommunityAnalytics) && isCommunityPage,
        privacy,
        title: translate(title),
    };
};
