import { ReactNode } from 'react';

import type { BlockArticlePreview } from '@lumapps/widget-article-list/types';
import type { BackendRecomputedWidgetProps } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import type {
    BaseResourceContainerBlock,
    NgiCommunityReference,
    SiteReference,
    UserReference,
} from '@lumapps/widget-base/types';
import { BlockCommunityPreview } from '@lumapps/widget-community-list/types';
import type { BlockPagePreview } from '@lumapps/widget-content-list/type';
import type { BlockEventPreview } from '@lumapps/widget-event-list/type';
import type { BlockPlayVideoInterface } from '@lumapps/widget-play/types';
import type { BlockPlayVideoPlaylistInterface } from '@lumapps/widget-playlist/types';
import type { BlockPost } from '@lumapps/widget-post-list/types';
import type { BlockLearningPreview } from '@lumapps/widget-recommended-training-courses/types';

/** The list of resources managed by the stream widget, in the order we want to display them in the filters */
export const MANAGED_STREAM_RESOURCES = [
    'article',
    'community', // This resource is both for communities and spaces
    'content',
    'event',
    'learning',
    'play_video',
    'play_video_playlist',
    'post',
] as const;

export type ManagedStreamResources = (typeof MANAGED_STREAM_RESOURCES)[number];

/** Check that the given string matches a managed stream resource */
export const isManagedStreamResource = (value: string): value is ManagedStreamResources => {
    return (MANAGED_STREAM_RESOURCES as ReadonlyArray<string>).includes(value);
};

/** All available stream actions  */
export type StreamActions =
    | 'new_attendee'
    | 'new_comment'
    | 'new_enrollment'
    | 'new_follow'
    | 'new_publication'
    | 'new_share';

/**
 * Props for the BlockStreamItem block.
 * Depending on the given resourceType, a matching property should be set
 * (ex: ` { resourceType: 'post', post: BlockPost } `)
 */
export type BlockStreamItem = BaseResourceContainerBlock<ManagedStreamResources> & {
    type: 'BlockStreamItem';
    children: ReactNode;
    userAction: {
        action: StreamActions;
        actionDate: string;
        user: UserReference;
        community?: NgiCommunityReference;
        site?: SiteReference;
    };
};

/** The resource to expect for each types */
type ResourceByResourceType = {
    article: BlockArticlePreview;
    community: BlockCommunityPreview;
    content: BlockPagePreview;
    event: BlockEventPreview;
    learning: BlockLearningPreview;
    play_video: BlockPlayVideoInterface;
    play_video_playlist: BlockPlayVideoPlaylistInterface;
    post: BlockPost;
};

/** Generate the correct BlockStreamItem type for the given resource */
export type BlockStreamItemByResource<T extends ManagedStreamResources> = BlockStreamItem &
    Pick<ResourceByResourceType, T>;

/** Available modes for the widget stream. */
export type StreamWidgetModes = 'space_stream' | 'personal_stream';

/**
 * Base props for the RecomputedWidgetStream
 */
export type RecomputedWidgetStreamBase = {
    resourceTypes: Array<ManagedStreamResources>;
    mode: StreamWidgetModes;
    emptyStateRenderer?: BackendRecomputedWidgetProps['emptyStateRenderer'];
    errorStateRenderer?: BackendRecomputedWidgetProps['errorStateRenderer'];
    shouldDisplayContributionField?: boolean;
    shouldDisplayFilters?: boolean;
    className?: string;
    /** id of the label used for the feed aria-labelledBy value */
    ariaLabelledBy: string;
};

/** Props to set on the RecomputedWidgetStream to display a space feed */
export type SpaceStreamProps = RecomputedWidgetStreamBase & {
    mode: 'space_stream';
    spaceId: string;
};

/** Props to set on the RecomputedWidgetStream to display a user personal feed */
export type UserPersonalFeedProps = RecomputedWidgetStreamBase & {
    mode: 'personal_stream';
    spaceId?: never;
};

/** Props for the recomputed stream widget  */
export type RecomputedWidgetProps = SpaceStreamProps | UserPersonalFeedProps;

/**
 * Props for the EmptyStreamStateAction component
 */
export type EmptyStreamStateAction = {
    resourceType: ManagedStreamResources;
    menuItemKey: string;
    singleActionKey: string;
    icon: string;
    onClick(): void;
};
export type EmptyStreamStateActionsProps = {
    resourceTypesData: Array<EmptyStreamStateAction>;
    scope: string;
};
