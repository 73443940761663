import React from 'react';

import { Menu } from '../Menu';
import { MenuItem } from '../MenuItem';
import { BaseItemProps } from '../MenuItem/types';
import { MenuTrigger, MenuTriggerProps } from '../MenuTrigger';
import { MenuTriggerButton } from '../MenuTriggerButton';

interface MenuItemSubmenuProps extends BaseItemProps {
    /**
     * Submenu label
     */
    label: React.ReactNode;
    /**
     * Additional menu trigger props
     */
    triggerProps?: Partial<MenuTriggerProps>;
}

/**
 * Item submenu (nested menu popup).
 *
 * Wrapper component around the `Menu.Trigger`, `Menu.TriggerButton` (attached to a `Menu.Item`) and `Menu`
 * to provide a simple submenu.
 *
 * To use inside a `Menu` or `Menubar`.
 *
 * @family Menus
 */
export const MenuItemSubmenu = React.forwardRef<HTMLElement, MenuItemSubmenuProps>(
    ({ label, children, triggerProps, ...forwardedProps }, ref) => (
        <MenuTrigger {...triggerProps}>
            <MenuTriggerButton ref={ref} as={MenuItem} {...forwardedProps}>
                {label}
            </MenuTriggerButton>
            <Menu>{children}</Menu>
        </MenuTrigger>
    ),
);
MenuItemSubmenu.displayName = 'MenuItemSubmenu';
