/* istanbul ignore file */
import React from 'react';

import { isNewContributionExperienceEnabled } from '@lumapps/contents/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { useCustomContentTypes } from '../../hooks/useCustomContentTypes';
import { ContentContributionMenu } from './ContentContributionMenu';

const ConnectedContentContributionMenu: React.FC = () => {
    const {
        allowedCustomContentTypes,
        parentCustomContentTypes,
        canDesignAuthorizations,
        isAllowed,
        isLoading,
        isSuperAdmin,
    } = useCustomContentTypes({});
    const isNewContributionExperienceFFEnabled = useSelector(isNewContributionExperienceEnabled);

    return (
        <ContentContributionMenu
            allowedCustomContentTypes={allowedCustomContentTypes}
            isAllowed={isAllowed}
            isSuperAdmin={isSuperAdmin}
            parentCustomContentTypes={parentCustomContentTypes}
            isLoadingCustomTypes={isLoading}
            canDesignAuthorizations={canDesignAuthorizations}
            isNewContributionExperienceEnabled={isNewContributionExperienceFFEnabled}
        />
    );
};

export { ConnectedContentContributionMenu as ContentContributionMenu };
