import React from 'react';

import {
    LanguageSwitcher,
    LanguageSwitcherProps,
} from '@lumapps/languages/components/LanguageSwitcher/LanguageSwitcher';
import { Emphasis } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export type FormLanguageSwitcherProps = Pick<
    LanguageSwitcherProps,
    'defaultLanguage' | 'languages' | 'className' | 'shouldAdaptToScreen' | 'isDisabled' | 'marginRight' | 'placement'
> &
    Omit<BaseInput, 'name'> & {
        name?: string;
    };

/**
 * Component to be used for selecting a language in the context of a multilanguage form
 * @family Forms
 * @param FormLanguageSwitcherProps
 * @returns FormLanguageSwitcher
 */
export const FormLanguageSwitcher: React.FC<FormLanguageSwitcherProps & Omit<BaseInput, 'name'>> = ({
    defaultLanguage,
    name = 'lang',
    controllerProps,
    languages,
    isRequired,
    isDisabled,
    className,
    shouldAdaptToScreen = true,
    marginRight,
    placement,
}) => {
    const { translateKey } = useTranslate();
    const { field, isFormLoading, isFieldDisabled, valueToUse, scope } = useFormField({
        name,
        isRequired,
        controllerProps: {
            ...controllerProps,
            defaultValue: defaultLanguage,
        },
        label: translateKey(GLOBAL.LANGUAGE),
    });

    return (
        <LanguageSwitcher
            className={className}
            defaultLanguage={valueToUse}
            inputLanguage={defaultLanguage}
            scope={scope}
            isDisabled={isFieldDisabled || isDisabled || isFormLoading}
            onChange={field.onChange}
            languages={languages}
            isVisible
            shouldAdaptToScreen={shouldAdaptToScreen}
            emphasis={Emphasis.medium}
            marginRight={marginRight}
            placement={placement}
        />
    );
};
