import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';

import {
    programSelectionModalProgramIdsByNameSelector,
    programSelectionModalValueSelector,
    programSelectionModalStatusSelector,
    programSelectionModalSearchValueSelector,
} from '../../ducks/ProgramSelectionModal/selector';
import { actions } from '../../ducks/ProgramSelectionModal/slice';
import { fetchProgramsByIds } from '../../ducks/ProgramSelectionModal/thunks';
import { ProgramSelectionModal } from './ProgramSelectionModal';

const mapStatetoProps = (state: BaseStore) => ({
    programIdsByName: programSelectionModalProgramIdsByNameSelector(state),
    searchValue: programSelectionModalSearchValueSelector(state),
    value: programSelectionModalValueSelector(state),
    modalStatus: programSelectionModalStatusSelector(state),
});

const mapDispatchToProps = {
    setValue: actions.setValue,
    setSearchValue: actions.setSearchValue,
    fetchProgramsByIds,
};

const ConnectedProgramSelectionmodal = connect(mapStatetoProps, mapDispatchToProps)(ProgramSelectionModal);

export { ConnectedProgramSelectionmodal as ProgramSelectionModal };
