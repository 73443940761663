import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const platformAnalyticsBaseRoute = 'analytics/platform';
const platformAnalyticsRouteMatcher = `/${platformAnalyticsBaseRoute}/*`;
const platformAnalyticsRoutes: Route = createPageRoute({
    slug: `${platformAnalyticsBaseRoute}/:tab?`,
    id: 'platform-analytics',
    appId: AppId.backOffice,
});

const platformAnalytics = (tab?: string): Route => {
    return {
        ...platformAnalyticsRoutes,
        params: tab
            ? {
                  tab,
              }
            : undefined,
    };
};

export { platformAnalytics, platformAnalyticsRoutes, platformAnalyticsRouteMatcher };
