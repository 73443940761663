import { generateUUID } from '@lumapps/utils/string/generateUUID';

/* global angular */
'use strict'; // jshint ignore:line

angular.module('Services').service('DriveFileService', [
    'Drive',
    'DriveFilesFactory',
    'Utils',
    'Translation',
    'Config',
    'LxNotificationService',
    function(Drive, DriveFileApi, Utils, Translation, Config, LxNotificationService) {
        // static
        // fixme: maybe use prototype object & _.create ?
        var emptyFile = {
            title: undefined,
            id: undefined,
            webContentLink: undefined,
            isParent: false,
            isFolder: false,
            isGoogle: false,
            icon: '',
            fileSize: undefined,
            ownersName: [],
            roles: {
                read: false,
                write: false,
            },
            link: undefined,
            lastUpdate: undefined,
            isSelected: false,
            displayChildren: false,
            isLoading: false,
            children: [],
            lastModifyingUserName: undefined,
        };
        var defaultListKey = 'default';
        var defaultFilter = {
            callId: '',
            complete: false,
            cursor: undefined,
            kind: '',
            maxResults: 25,
            more: false,
        };
        var _DF = [];

        //
        // PRIVATE ATTRIBUTES
        //

        var _CI = []; // current instances

        var _IO = {
            // instance default option
            mainFolderId: undefined, // root id
            trashFolderId: undefined, // if defined don't trash files but move them to the folder id
            currentFolderId: undefined, // current folder id
            currentFolder: undefined, // current folder reference, ONLY set in _loadSubFolders to avoid recursivity
            folders: angular.copy(emptyFile), // all loaded folders
            actionLoading: false, // used when performing an action like copy/delete/...

            currentFolderFiles: [], // files of the current folder
            currentFolderFolders: [], // folders of the current folder
            currentFolderLoading: false, //  loading state of the current folder
            currentFolderFilter: angular.copy(defaultFilter), // filter of the current folder

            selectedFileId: undefined, // id of the selected file
            selectedFile: undefined, // the full selected file object
            fileSelected: false, // if a file is selected
            breadcrumb: [], // Breadcrumb elements
        };

        //
        // PRIVATE METHODS
        //
        /**
         * Find the mime of a google file
         * @param mime the mime we want the type of google mime or classic
         * example: `application/vnd.google-apps.folder` will return folder
         * but `image/gif` will return image
         * @returns {*} the file type or undefined
         * @private
         */
        function _getFileType(mime) {
            var res = {
                isGoogle: false,
                mime: undefined,
            };
            var iconAvailable = ['document', 'sheet', 'spreadsheet', 'folder', 'presentation', 'image'];

            if (angular.isDefined(mime)) {
                var re;
                if (mime.toLowerCase().indexOf('google') >= 0) {
                    res.isGoogle = true;
                    re = /\.([^.][a-z]\w+)$/;
                } else {
                    // if not google but doc/sheet user same pattern as above
                    re = mime.toLowerCase().match(/(document|spreadsheet)/) ? /\.([^.][a-z]\w+)$/ : /([a-z]\w+)[\/]/;
                }

                var result = re.exec(mime);

                if (result && angular.isDefined(result[1])) {
                    res.mime = result[1];
                }
            }

            if (iconAvailable.indexOf(res.mime) === -1) {
                res.mime = 'default';
            }

            return res;
        }

        /**
         * set wanted file to unSelect
         * @param ids
         * @param list
         * @private
         */
        function _unSelectFiles(ids, list) {
            for (var idx = 0; idx < ids.length; idx++) {
                if (angular.isDefined(list)) {
                    for (var fileIdx = 0; fileIdx < list.length; fileIdx++) {
                        if (ids[idx] === list[fileIdx].id) {
                            list[fileIdx].isSelected = false;
                        }
                    }
                }
            }
        }

        /**
         * Iterate over the folders and return the wanted folder
         * @param key list key identifier
         * @param folderId the wanted folder id
         * @param folders the global list of folder
         * @returns {*} the wanted folder or undefined if not found
         * @private
         */
        function _getFolderById(key, folderId, folders) {
            key = _getListKey(key);
            // if wanted id is main folder id return main folder
            if (folderId === _CI[key].mainFolderId) {
                return _CI[key].folders;
            }

            if (angular.isDefined(folders) && folders.length > 0) {
                for (var idx = 0; idx < folders.length; idx++) {
                    if (folderId === folders[idx].id) {
                        return folders[idx];
                    }

                    if (angular.isDefined(folders[idx].children) && folders[idx].children.length > 0) {
                        var result = _getFolderById(key, folderId, folders[idx].children);

                        if (angular.isDefined(result)) {
                            return result;
                        }
                    }
                }
            }

            return undefined;
        }

        /**
         * Get the family tree of the folder ( get all his parents )
         * useful for the breadcrumb
         * @param folderId the wanted child
         * @param folders the list of folders to iterate over
         * @param idOnly if the function returns only folder ids
         * @returns {*|Array} the new family
         * @private
         */
        function _getFolderFamilyTree(folderId, folders, idOnly) {
            var family = [];
            idOnly = idOnly || false;

            if (angular.isDefined(folders) && angular.isDefined(folderId)) {
                if (folderId === folders.id) {
                    family.unshift(idOnly ? folders.id : { id: folders.id, title: folders.title });
                    return family;
                } else {
                    if (folders.children.length > 0) {
                        for (var idx = 0; idx < folders.children.length; idx++) {
                            family = _getFolderFamilyTree(folderId, folders.children[idx], idOnly);
                            if (family.length > 0) {
                                family.unshift(idOnly ? folders.id : { id: folders.id, title: folders.title });

                                return family;
                            }
                        }
                    }
                }
            }
            return family;
        }

        /**
         * Search for a folder and remove it
         * @param folderId
         * @param folders
         * @returns {boolean}
         * @private
         */
        function _removeNavigationFolder(folderId, folders) {
            if (angular.isArray(folders)) {
                for (var idx = 0; idx < folders.length; idx++) {
                    if (folders[idx].id === folderId) {
                        folders.splice(idx, 1);
                        return true;
                    }

                    if (
                        angular.isArray(folders[idx].children) &&
                        _removeNavigationFolder(folderId, folders[idx].children)
                    ) {
                        return true;
                    }
                }
            }

            return false;
        }

        /**
         * Remove a file from the displayed files or displayed folders
         * @param key list key identifier
         * @param fileId id of the file to remove
         * @private
         */
        function _removeFileFromCurrentLists(key, fileId) {
            var inst = _getCurrentInstanceParams(key);

            if (angular.isDefined(fileId)) {
                for (var idx = 0; idx < inst.currentFolderFiles.length; idx++) {
                    if (inst.currentFolderFiles[idx].id === fileId) {
                        inst.currentFolderFiles.splice(idx, 1);
                        return;
                    }
                }

                // else if file not in current file list, search in current folders list
                var found = false;
                for (var fdx = 0; fdx < inst.currentFolderFolders.length; fdx++) {
                    if (inst.currentFolderFolders[fdx].id === fileId) {
                        inst.currentFolderFolders.splice(fdx, 1);
                        found = true;
                    }
                }

                // then remove folder from navigation
                if (found) {
                    _removeNavigationFolder(fileId, inst.folders.children);
                }
            }
        }

        /**
         * Initialize the main folder.
         *
         * @param {String} key      List key identifier
         * @param {String} id       Main folder id
         * @param {Function} cb     Success callback function
         * @param {Function} errCb  Error callback function
         * @private
         */
        function _initMainFolder(key, id, cb, errCb) {
            var inst = _getCurrentInstanceParams(key);

            if (inst.folders.children.length === 0) {
                inst.mainFolderId = id;
                _get(
                    key,
                    id,
                    function getMainFolderSuccess(resp) {
                        angular.extend(resp, { id: id });
                        inst.folders = _formatFile(resp);
                        inst.currentFolder = inst.folders;

                        // fetch subFolders
                        _loadSubFolders(key, id, true, function(response) {
                            // then load sub folder sub folders ..
                            if (angular.isArray(response.items)) {
                                angular.forEach(response.items, function(item) {
                                    if (item.id) {
                                        _loadSubFolders(key, item.id);
                                    }
                                });
                            }
                        });

                        (cb || angular.noop)(resp);
                    },
                    function getMainFolderError(err) {
                        (errCb || angular.noop)(err);
                    },
                );
            }
        }

        /**
         * Initialize drive
         * @param key
         * @param folderId
         * @param config
         * @private
         */
        function _init(key, folderId, config) {
            // if first init
            if (angular.isUndefined(_CI[key])) {
                var inst = _getCurrentInstanceParams(key);
                config = config || {};

                // update defaultFilter
                _DF[key] = angular.copy(defaultFilter);
                _DF[key].maxResults = config.maxResults || defaultFilter.maxResults;
                _DF[key].kind = config.kind || '';
                inst.trashFolderId = config.trashFolderId;

                // init the main folder
                _initMainFolder(
                    key,
                    folderId,
                    function initMainFolderSuccess() {
                        // calculate breadcrumb
                        inst.breadcrumb = _getFolderFamilyTree(folderId, inst.folders, false);
                    },
                    config.initMainFolderErrorCb,
                );

                // first fetch
                _fetch(key, folderId);
            }
        }

        /**
         * Reset current instance with new params
         * @param key
         * @param folderId
         * @param config
         * @private
         */
        function _reset(key, folderId, config) {
            _CI[key] = undefined;
            _init(key, folderId, config);
        }

        /**
         * Async load folder id sub folders
         * @param key list key identifier
         * @param folderId the folder to load sub folders
         * @param update if has children but we want to update
         * @param cb the success callback function
         * @param errCb the error callback function
         * @private
         */
        function _loadSubFolders(key, folderId, update, cb, errCb) {
            key = _getListKey(key);
            var parentFolder = _getFolderById(key, folderId, _CI[key].folders.children);
            update = update || false;
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if ((angular.isDefined(parentFolder) && parentFolder.children.length === 0) || update) {
                parentFolder.isLoading = true;

                DriveFileApi.list(
                    {
                        fetchAll: true,
                        folderId: folderId,
                        folderOnly: true,
                        ignore403: true,
                        kind: _getKindParam(key, folderId),
                    },
                    function onSubFolderListSuccess(response) {
                        if (angular.isDefinedAndFilled(response.items) && angular.isDefined(parentFolder)) {
                            parentFolder.isParent = true;
                            var idAlreadyInUse = _.map(parentFolder.children, 'id');

                            // Set if not exist.
                            angular.forEach(response.items, function forEachSubFolder(folder) {
                                if (!_.includes(idAlreadyInUse, folder.id)) {
                                    parentFolder.children.push(angular.extend(_formatFile(folder)));
                                }
                            });
                        }
                        parentFolder.isLoading = false;
                        cb(response);
                    },
                    errCb,
                );
            }
        }

        /**
         * Check if the user role belong to the right permissions
         * @param type the permission type (owner|writer|reader)
         * @param role the user role
         * @returns {bool}
         * @private
         */
        function _checkFilePermission(type, role) {
            if (
                angular.isDefined(Config.WIDGET_DRIVE_PERMISSION) &&
                angular.isArray(Config.WIDGET_DRIVE_PERMISSION[type]) &&
                angular.isDefined(role)
            ) {
                return Config.WIDGET_DRIVE_PERMISSION[type].indexOf(role) > -1;
            }

            return false;
        }

        /**
         * Format a file to a typed file, used to format server response
         * @param item the file to format
         * @returns {*}
         * @private
         */
        function _formatFile(item) {
            var newFile = angular.extend(angular.fastCopy(emptyFile), item);
            var fileType = _getFileType(item.mimeType);

            newFile.icon = fileType.mime;
            newFile.isGoogle = fileType.isGoogle;
            newFile.isFolder = fileType.mime === 'folder';
            newFile.fileSize = item.fileSize ? Utils.formatBytes(item.fileSize) : undefined;

            if (!newFile.isFolder) {
                newFile.children = undefined;
            }

            // permissions
            if (item.role) {
                newFile.roles.write = _checkFilePermission('write', item.role);
                newFile.roles.read = _checkFilePermission('read', item.role);
            }

            return newFile;
        }

        /**
         * Get a file/folder of the current folder
         * @param key
         * @param id
         * @returns {T}
         * @private
         */
        function _getCurrentFolderFile(key, id) {
            var inst = _getCurrentInstanceParams(key);

            var files = inst.currentFolderFiles.concat(inst.currentFolderFolders);

            for (var idx = 0; idx < files.length; idx++) {
                if (files[idx].id === id) {
                    return files[idx];
                }
            }
        }

        //
        // SERVER ACTIONS
        //

        /**
         * Fetch one file
         * @param key list key identifier
         * @param fileId id of the file to get
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _get(key, fileId, cb, errCb) {
            if (!angular.isDefined(fileId)) {
                return;
            }

            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            DriveFileApi.get(
                {
                    fileId: fileId,
                    kind: _getKindParam(key, fileId),
                },
                cb,
                errCb,
            );
        }

        /**
         * Gets the kind parameter to be applied to a call.
         *
         * @param  {string} key The list key used for the call we're about to do.
         * @param  {string} id  The id of the file/folder we want to get the kind of.
         * @return {string} The kind of the file/folder. Can be 'drive#file' or 'drive#teamDrive'.
         */
        function _getKindParam(key, id) {
            var inst = _getCurrentInstanceParams(key);

            // We only want to pass a kind of 'drive#teamDrive' when we do actions on the team drive folder itself.
            if (inst.mainFolderId === id && _DF[key].kind === 'drive#teamDrive') {
                return 'drive#teamDrive';
            }

            return 'drive#file';
        }

        /**
         * Fetch content from server with filter params
         * @param key list key identifier
         * @param folderId the wanted folder id
         * @param cb the success callback function
         * @param errCb the error callback function
         * @private
         */
        function _fetch(key, folderId, cb, errCb) {
            var inst = _getCurrentInstanceParams(key);

            if (false === inst.currentFolderLoading) {
                // if loading a new folder
                if (
                    inst.currentFolderFilter.folderId !== folderId ||
                    angular.isUndefined(inst.currentFolderFilter.cursor)
                ) {
                    // reset filter
                    inst.currentFolderFilter = angular.copy(_DF[key]);

                    // empty current folder files
                    inst.currentFolderFiles = [];
                    inst.currentFolderFolders = [];
                }

                inst.currentFolderFilter.callId = generateUUID();
                inst.currentFolderFilter.folderId = folderId;
                inst.currentFolderFilter.ignore403 = true;

                inst.currentFolderLoading = true;

                DriveFileApi.list(
                    inst.currentFolderFilter,
                    function(resp) {
                        // fetch success
                        if (angular.isDefined(inst.currentFolder) && folderId !== inst.currentFolder.id) {
                            inst.currentFolder =
                                _getFolderById(key, folderId, inst.folders.children) || inst.currentFolder;
                        }

                        // build filter
                        inst.currentFolderFilter.more = resp.more;
                        inst.currentFolderFilter.cursor = resp.cursor;

                        // handle data
                        if (angular.isDefined(resp.items)) {
                            for (var idx = 0; idx < resp.items.length; idx++) {
                                var newItem = _formatFile(resp.items[idx]);

                                // push to the right collection
                                (newItem.isFolder ? inst.currentFolderFolders : inst.currentFolderFiles).push(newItem);
                            }
                        }

                        inst.currentFolderId = folderId;
                        inst.currentFolderLoading = false;

                        (cb || angular.noop)(resp);
                    },
                    function(err) {
                        inst.currentFolderLoading = false;

                        (errCb || angular.noop)(err);
                    },
                );
            }
        }

        /**
         * Trash a file
         * @param key list key identifier
         * @param fileId id of the file to trash
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _trash(key, fileId, cb, errCb) {
            key = _getListKey(key);

            if (angular.isDefined(fileId)) {
                _CI[key].actionLoading = true;

                cb = cb || angular.noop;
                errCb = errCb || angular.noop;

                DriveFileApi.trash(
                    {
                        fileId: fileId,
                        kind: _getKindParam(key, fileId),
                    },
                    function onFileTrashSuccess(response) {
                        // Remove file from current file list.
                        _removeFileFromCurrentLists(key, fileId);

                        cb(response);
                        _CI[key].actionLoading = false;
                    },
                    function onFileTrashError(err) {
                        errCb(err);
                        _CI[key].actionLoading = false;
                    },
                );
            }
        }

        /**
         * Move a file
         * @param key list key identifier
         * @param fileId id of the file to move
         * @param targetId id of the target folder
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _move(key, fileId, targetId, cb, errCb) {
            var inst = _getCurrentInstanceParams(key);

            if (angular.isDefined(fileId) && angular.isDefined(targetId)) {
                var file = _getCurrentFolderFile(key, fileId);
                var query = {
                    fileId: fileId,
                    kind: _getKindParam(key, fileId),
                    target: targetId,
                };

                inst.actionLoading = true;
                DriveFileApi.move(
                    query,
                    function(resp) {
                        if (targetId !== inst.currentFolderId) {
                            // remove file from current file list
                            _removeFileFromCurrentLists(key, fileId);
                        }

                        if (file.isFolder) {
                            // move folder in _folders
                            var parent = _getFolderById(key, targetId, inst.folders.children);
                            if (parent && angular.isArray(parent.children)) {
                                parent.children.push(file);
                            }
                        }

                        (cb || angular.noop)(resp);
                        inst.actionLoading = false;
                    },
                    function(err) {
                        (errCb || angular.noop)(err);
                        inst.actionLoading = false;
                    },
                );
            }
        }

        /**
         * Update file
         * @param key list key identifier
         * @param fileId id of the file to move
         * @param content the file content to update
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _update(key, fileId, content, cb, errCb) {
            var inst = _getCurrentInstanceParams(key);

            if (angular.isDefined(fileId)) {
                var file = _getCurrentFolderFile(key, fileId);
                var query = {
                    description: content.description,
                    fileId: fileId,
                    kind: _getKindParam(key, fileId),
                    title: content.title,
                };

                cb = cb || angular.noop;
                errCb = errCb || angular.noop;

                inst.actionLoading = true;

                DriveFileApi.update(
                    query,
                    function onFileUpdateSuccess(response) {
                        // Update file info.
                        angular.extend(file, response);

                        cb(response);
                        inst.actionLoading = false;
                    },
                    function onFileUpdateError(response) {
                        errCb(err);
                        inst.actionLoading = false;
                    },
                );
            }
        }

        /**
         * Rename the file, Update alias
         * @param key list key identifier
         * @param fileId fileId id of the file to move
         * @param title file new title
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _rename(key, fileId, title, cb, errCb) {
            if (angular.isDefined(title)) {
                _update(key, fileId, { title: title }, cb, errCb);
            }
        }

        /**
         * Duplicate a file in the current folder
         * @param key list key identifier
         * @param file the file to duplicate
         * @param title the file new title
         * @param cb the success callback function
         * @param errCb the error callback function
         * @private
         */
        function _duplicate(key, file, title, cb, errCb) {
            var inst = _getCurrentInstanceParams(key);

            if (angular.isDefined(file) && file.id && !file.isFolder) {
                var query = {
                    fileId: file.id,
                    folder: inst.currentFolderId,
                    kind: _getKindParam(key, file.id),
                    title: title || Translation.translate(Config.WIDGET_DRIVE_DEFAULT.copyName) + ' ' + file.title,
                };

                cb = cb || angular.noop;
                errCb = errCb || angular.noop;

                inst.actionLoading = true;

                DriveFileApi.copy(
                    query,
                    function onFileCopySuccess(response) {
                        // Add file to current file list.
                        inst.currentFolderFiles.push(_formatFile(response));

                        cb(response);
                        inst.actionLoading = false;
                    },
                    function(response) {
                        errCb(response);
                        inst.actionLoading = false;
                    },
                );
            }
        }

        /**
         * Create a new folder, based on insert
         * @param key list key identifier
         * @param folderName folder wanted name
         * @param targetId folder target id
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _createFolder(key, folderName, targetId, cb, errCb) {
            var inst = _getCurrentInstanceParams(key);

            folderName = folderName || Translation.translate(Config.WIDGET_DRIVE_DEFAULT.newFolderName);
            targetId = targetId || inst.currentFolderId; // if no target create folder in current folder

            var query = {
                kind: _getKindParam(key, undefined),
                mimeType: 'application/vnd.google-apps.folder',
                parentId: targetId,
                title: folderName,
            };

            inst.actionLoading = true;
            DriveFileApi.insert(
                query,
                function(resp) {
                    if (targetId === inst.currentFolderId) {
                        inst.currentFolderFolders.push(_formatFile(resp));
                    }

                    // add folder to _folders
                    var parent = _getFolderById(key, targetId, inst.folders.children);
                    if (parent && angular.isArray(parent.children)) {
                        parent.children.push(_formatFile(resp));
                    }

                    (cb || angular.noop)(resp);
                    inst.actionLoading = false;
                },
                function(err) {
                    (errCb || angular.noop)(err);
                    inst.actionLoading = false;
                },
            );
        }

        /**
         * Insert a new file
         * @param key list key identifier
         * @param fileName new file name
         * @param mimeType mimeType
         * @param targetId target folder id
         * @param cb success callback function
         * @param errCb error callback function
         * @private
         */
        function _insert(key, fileName, mimeType, targetId, cb, errCb) {
            if (angular.isDefined(fileName) && angular.isDefined(mimeType)) {
                var inst = _getCurrentInstanceParams(key);
                targetId = targetId || inst.currentFolderId;

                var query = {
                    kind: _getKindParam(key, undefined),
                    mimeType: mimeType,
                    parentId: targetId,
                    title: fileName,
                };

                inst.actionLoading = true;
                DriveFileApi.insert(
                    query,
                    function(resp) {
                        inst.currentFolderFiles.push(_formatFile(resp));

                        (cb || angular.noop)(resp);
                        inst.actionLoading = false;
                    },
                    function(err) {
                        (errCb || angular.noop)(err);
                        inst.actionLoading = false;
                    },
                );
            }
        }

        function _getListKey(listKey) {
            return listKey || defaultListKey;
        }

        function _getCurrentInstanceParams(key) {
            key = key || defaultFilter;

            if (angular.isUndefined(_CI[key])) {
                _CI[key] = angular.copy(_IO);
            }

            return _CI[key];
        }

        /**
         * Move a file to the specified target
         * @param sourceId the source id
         * @param targetId the target id
         * @param listKey
         * @private
         */
        function _moveFile(sourceId, targetId, listKey) {
            if (angular.isDefined(sourceId) && angular.isDefined(targetId)) {
                move(
                    listKey,
                    sourceId,
                    targetId,
                    function() {
                        LxNotificationService.success(
                            Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_MOVE_SUCCESS'),
                        );
                    },
                    function() {
                        LxNotificationService.error(Translation.translate('WIDGET_TYPE_FILE_MANAGEMENT_MOVE_ERROR'));
                    },
                );
            }
        }

        //
        // PUBLIC METHODS
        //

        /**
         * Initialize
         * @param listKey
         * @param mainFolderId
         * @param config
         */
        function init(listKey, mainFolderId, config) {
            _init(listKey, mainFolderId, config);
        }

        /**
         * Called when a file is dropped on a drive navigation folder.
         *
         * @param {Event}  evt     The original drop event triggering this method.
         * @param {number} index   The position in the list at which the element would be dropped.
         * @param {Object} file    The file being dropped.
         * @param {string} type    The dnd-type set on the dnd-draggable.
         * @param {Object} folder  The target folder to drop the file into.
         * @param {string} listKey The identifier of the list the file is moved from.
         */
        function dropCallback(evt, index, file, type, folder, listKey) {
            if (angular.isUndefinedOrEmpty(folder.id) || file.id === folder.id) {
                return;
            }

            var parentFolderIds = getFolderParentIds(listKey, folder.id);

            if (_.includes(parentFolderIds, file.id)) {
                return;
            }

            _moveFile(file.id, folder.id, listKey);
        }

        /**
         * Reset current instance, re-initialize
         * @param listKey
         * @param mainFolderId
         * @param config
         */
        function reset(listKey, mainFolderId, config) {
            _reset(listKey, mainFolderId, config);
        }

        /**
         * Call _fetch
         * @param listKey
         * @param folderId
         * @param cb
         * @param errCb
         */
        function fetch(listKey, folderId, cb, errCb) {
            _fetch(listKey, folderId, cb, errCb);
        }

        /**
         * Call _insert
         * @param listKey list key identifier
         * @param name new file name
         * @param type mimeType
         * @param target target folder id
         * @param cb success callback function
         * @param errCb error callback function
         */
        function insert(listKey, name, type, target, cb, errCb) {
            _insert(listKey, name, type, target, cb, errCb);
        }

        /**
         * Duplicate a file in the current folder
         * @param listKey
         * @param file
         * @param title
         * @param cb
         * @param errCb
         */
        function duplicate(listKey, file, title, cb, errCb) {
            _duplicate(listKey, file, title, cb, errCb);
        }

        /**
         * Trash a file
         * @param listKey list key identifier
         * @param fileId Id of the file to trash
         * @param cb success callback function
         * @param errCb error callback function
         */
        function trash(listKey, fileId, cb, errCb) {
            _trash(listKey, fileId, cb, errCb);
        }

        /**
         * Move a file to the target folders
         * @param listKey list key identifier
         * @param fileId Id of the file to move
         * @param targetId Id of the target folder
         * @param cb success callback function
         * @param errCb error callback function
         */
        function move(listKey, fileId, targetId, cb, errCb) {
            _move(listKey, fileId, targetId, cb, errCb);
        }

        /**
         * Rename a file
         * @param listKey list key identifier
         * @param fileId Id of the file to move
         * @param title file new title
         * @param cb success callback function
         * @param errCb error callback function
         */
        function rename(listKey, fileId, title, cb, errCb) {
            _rename(listKey, fileId, title, cb, errCb);
        }

        /**
         * Create a new folder
         * @param listKey list key identifier
         * @param folderName new folder name
         * @param targetId target id
         * @param cb success callback function
         * @param errCb error callback function
         */
        function createFolder(listKey, folderName, targetId, cb, errCb) {
            _createFolder(listKey, folderName, targetId, cb, errCb);
        }

        /**
         * Return the folder list
         * @param listKey
         * @returns {Array}
         */
        function getNavigationFolders(listKey) {
            return _getCurrentInstanceParams(listKey).folders;
        }

        /**
         * Return the current folder files
         * @param listKey
         * @returns {Array}
         */
        function getCurrentFolderFiles(listKey) {
            return _getCurrentInstanceParams(listKey).currentFolderFiles;
        }

        /**
         * Return the current folder folders
         * @param listKey
         * @returns {Array}
         */
        function getCurrentFolderFolders(listKey) {
            return _getCurrentInstanceParams(listKey).currentFolderFolders;
        }

        /**
         * Return the current folder ID
         * @param listKey
         * @returns {*}
         */
        function getCurrentFolderId(listKey) {
            return _getCurrentInstanceParams(listKey).currentFolderId;
        }

        /**
         * Indicates if the current folder is a team drive or not.
         *
         * @return string
         */
        function isTeamDrive(listKey) {
            return _getKindParam(listKey, getCurrentFolderId(listKey)) === 'drive#teamDrive';
        }

        /**
         * Change folder id and refresh
         * @param listKey
         * @param folderId
         * @param cb
         * @param errCb
         */
        function changeFolder(listKey, folderId, cb, errCb) {
            if (isCurrentFolderLoading(listKey)) {
                return;
            }

            var inst = _getCurrentInstanceParams(listKey);

            if (angular.isDefined(folderId) && folderId !== inst.currentFolderId) {
                // unset selected file
                inst.selectedFileId = undefined;
                inst.fileSelected = false;

                _fetch(
                    listKey,
                    folderId,
                    function(resp) {
                        // update navigation folders, loop over inst.currentFolderFolders to avoid overriding folders already set.
                        var currentFolderChildrenIds = _.map(inst.currentFolder.children, 'id');
                        for (var idx = 0; idx < inst.currentFolderFolders.length; idx++) {
                            if (currentFolderChildrenIds.indexOf(inst.currentFolderFolders[idx].id) === -1) {
                                inst.currentFolder.children.push(inst.currentFolderFolders[idx]);
                            }
                        }

                        // update breadcrumb
                        inst.breadcrumb = _getFolderFamilyTree(folderId, inst.folders, false);

                        (cb || angular.noop)(resp);
                    },
                    function(err) {
                        (errCb || angular.noop)(err);
                    },
                );
            }
        }

        /**
         * Check if the file list is loading
         * @param listKey
         * @returns {boolean}
         */
        function isCurrentFolderLoading(listKey) {
            return _getCurrentInstanceParams(listKey).currentFolderLoading;
        }

        /**
         * Check if there is files  in the current folder
         * @param listKey
         * @returns {boolean}
         */
        function isCurrentFolderEmpty(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return inst.currentFolderFiles.length === 0 && inst.currentFolderFolders.length === 0;
        }

        /**
         * Return an array of the parent folder of folderId
         * @param listKey
         * @returns {*|Array}
         */
        function getBreadcrumb(listKey) {
            return _getCurrentInstanceParams(listKey).breadcrumb;
        }

        /**
         * Return an array of folder parent id
         * @param listKey
         * @param folderId
         * @param removeRoot
         * @returns {*|Array}
         */
        function getFolderParentIds(listKey, folderId, removeRoot) {
            var inst = _getCurrentInstanceParams(listKey);
            removeRoot = removeRoot || false;

            var parentIds = _getFolderFamilyTree(folderId, inst.folders, true);
            if (removeRoot) {
                var idx = parentIds.indexOf(inst.mainFolderId);

                if (idx !== -1) {
                    parentIds.splice(idx, 1);
                }
            }

            return parentIds;
        }

        /**
         * Set the current selected file
         * @param listKey
         * @param file
         */
        function setSelectedFile(listKey, file) {
            if (angular.isDefined(file) && angular.isDefined(file.id)) {
                var inst = _getCurrentInstanceParams(listKey);

                if (inst.selectedFileId !== file.id) {
                    // unset old file
                    if (angular.isDefined(inst.selectedFileId)) {
                        unSelectFiles(listKey, inst.selectedFileId);
                    }

                    // set new file
                    inst.selectedFileId = file.id;
                    inst.selectedFile = file;
                    file.isSelected = true;
                    inst.fileSelected = true;
                }
            }
        }

        /**
         * Return the selected file
         * @param listKey
         * @returns {*}
         */
        function getSelectedFile(listKey) {
            return _getCurrentInstanceParams(listKey).selectedFile;
        }

        /**
         * Find folder by ids and set isSelected to false
         * @param listKey list key identifier
         * @param ids the folder ids to find
         */
        function unSelectFiles(listKey, ids) {
            var inst = _getCurrentInstanceParams(listKey);
            if (!angular.isArray(ids)) {
                ids = [ids];
            }

            if (inst.fileSelected) {
                // iter over files
                _unSelectFiles(ids, inst.currentFolderFiles);
                // iter over folders
                _unSelectFiles(ids, inst.currentFolderFolders);
            }
        }

        /**
         * Check if user can read this file
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isSelectedFileReadable(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return (
                inst.fileSelected &&
                angular.isDefined(inst.selectedFile) &&
                angular.isObject(inst.selectedFile.roles) &&
                inst.selectedFile.roles.read
            );
        }

        /**
         * Check if user can write on this file
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isSelectedFileWritable(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return (
                inst.fileSelected &&
                angular.isDefined(inst.selectedFile) &&
                angular.isObject(inst.selectedFile.roles) &&
                inst.selectedFile.roles.write
            );
        }

        /**
         * Check if user can write on the current folder
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isCurrentFolderWritable(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return (
                angular.isDefined(inst.currentFolder) &&
                angular.isObject(inst.currentFolder.roles) &&
                inst.currentFolder.roles.write
            );
        }

        /**
         * Check if selected folder is the trash folder
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isCurrentFileTrashFolder(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return inst.selectedFile.id === inst.trashFolderId;
        }

        /**
         * Check if a file or more is selected
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isFileSelected(listKey) {
            return _getCurrentInstanceParams(listKey).fileSelected;
        }

        /**
         * Check if the selected file is a folder
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isSelectedFileFolder(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return angular.isDefined(inst.selectedFile) && inst.selectedFile.isFolder;
        }

        /**
         *  Check if the file is a google file (checked with the mime type)
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isSelectedFileGoogle(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return angular.isDefined(inst.selectedFile) && inst.selectedFile.isGoogle;
        }

        /**
         * Check if selected file has a download link
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isSelectedFileDownloadAvailable(listKey) {
            var inst = _getCurrentInstanceParams(listKey);

            return angular.isDefined(inst.selectedFile) && inst.selectedFile.webContentLink;
        }

        /**
         * Load folderIds sub folder
         * @param listKey list key identifier
         * @param folderIds an id or an array of id
         */
        function loadSubFolders(listKey, folderIds) {
            if (angular.isDefined(folderIds)) {
                if (!angular.isArray(folderIds)) {
                    folderIds = [folderIds];
                }

                angular.forEach(folderIds, function(id) {
                    if (id) {
                        _loadSubFolders(listKey, id);
                    }
                });
            }
        }

        /**
         * Check if current folder has more content to fetch
         * @param listKey list key identifier
         * @returns {*}
         */
        function currentFolderHasMore(listKey) {
            return _getCurrentInstanceParams(listKey).currentFolderFilter.more;
        }

        /**
         * Load more files for the current folderId
         * @param listKey list key identifier
         * @param cb the success callback function
         * @param errCb the error callback function
         */
        function loadMoreFiles(listKey, cb, errCb) {
            var inst = _getCurrentInstanceParams(listKey);

            if (angular.isDefined(inst.currentFolderFilter.folderId) && inst.currentFolderFilter.more) {
                _fetch(listKey, inst.currentFolderFilter.folderId, cb, errCb);
            }
        }

        /**
         * if performing a action like copy/delete/etc ... return true
         * @param listKey list key identifier
         * @returns {boolean}
         */
        function isPerformingAction(listKey) {
            return _getCurrentInstanceParams(listKey).actionLoading;
        }

        /**
         * Set the action loading status
         * @param listKey list key identifier
         * @param val
         */
        function setIsPerformingAction(listKey, val) {
            if (typeof val === 'boolean') {
                _getCurrentInstanceParams(listKey).actionLoading = val;
            }
        }

        return {
            init: init,
            dropCallback: dropCallback,
            reset: reset,
            filter: fetch,
            insert: insert,
            duplicate: duplicate,
            trash: trash,
            move: move,
            rename: rename,
            createFolder: createFolder,
            getNavigationFolders: getNavigationFolders,
            getCurrentFolderFiles: getCurrentFolderFiles,
            getCurrentFolderFolders: getCurrentFolderFolders,
            getCurrentFolderId: getCurrentFolderId,
            changeFolder: changeFolder,
            isCurrentFolderLoading: isCurrentFolderLoading,
            isCurrentFolderEmpty: isCurrentFolderEmpty,
            isTeamDrive: isTeamDrive,
            getBreadcrumb: getBreadcrumb,
            getFolderParentIds: getFolderParentIds,
            setSelectedFile: setSelectedFile,
            getSelectedFile: getSelectedFile,
            unSelectFiles: unSelectFiles,
            isSelectedFileReadable: isSelectedFileReadable,
            isSelectedFileWritable: isSelectedFileWritable,
            isCurrentFolderWritable: isCurrentFolderWritable,
            isCurrentFileTrashFolder: isCurrentFileTrashFolder,
            isFileSelected: isFileSelected,
            isSelectedFileFolder: isSelectedFileFolder,
            isSelectedFileGoogle: isSelectedFileGoogle,
            isSelectedFileDownloadAvailable: isSelectedFileDownloadAvailable,
            loadSubFolders: loadSubFolders,
            currentFolderHasMore: currentFolderHasMore,
            loadMoreFiles: loadMoreFiles,
            isPerformingAction: isPerformingAction,
            setIsPerformingAction: setIsPerformingAction,
        };
    },
]);
