import { ReactEditor } from '@lumapps/wrex/slate';

import { TableEditor, TableToolbarButtons } from '../types';
import { getCurrentSelectionInfos } from './getCurrentSelectionInfos';

export const getActiveTableButtons = (editor: ReactEditor & TableEditor) => {
    const activeButtons: TableToolbarButtons[] = [
        'add-row-before',
        'add-row-after',
        'add-column-before',
        'add-column-after',
    ];

    const selectionInfos = getCurrentSelectionInfos(editor);
    if (!selectionInfos) {
        return { activeButtons: [] };
    }

    const {
        isSelectionInOneCell,
        oneRowFullySelected,
        oneColFullySelected,
        allTableSelected,
        selectedRows,
        selectedCols,
    } = selectionInfos;

    if (isSelectionInOneCell) {
        activeButtons.push('delete-row', 'delete-column', 'delete-table');
        return { activeButtons };
    }

    if (oneRowFullySelected) {
        activeButtons.push('delete-row');
        return { activeButtons };
    }
    if (oneColFullySelected) {
        activeButtons.push('delete-column');
        return { activeButtons };
    }
    if (allTableSelected) {
        activeButtons.push('delete-table');
        return { activeButtons };
    }
    activeButtons.push('delete-column', 'delete-row');
    return { activeButtons, selectedRows, selectedCols };
};
