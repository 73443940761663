import React from 'react';

import moment from 'moment';

import { classnames, margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiRefresh } from '@lumapps/lumx/icons';
import { Alignment, Button, Emphasis, FlexBox, Orientation, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SCOPE, SEGMENT_DATA_IDS_MAPPING } from '../../constants';
import { SEGMENT } from '../../keys';

import './SegmentSizeField.scss';

const CLASSNAME = 'segment-size-field';

export interface SegmentSizeFieldProps {
    currentSize: number | null | undefined;
    onRefreshSize?: () => void;
    layout: 'line' | 'column';
    isDisabled?: boolean;
    lastUpdateAt?: string;
    isLoading?: boolean;
    canCalculateSize?: boolean;
    mode: 'create' | 'edit' | 'drawer' | 'popover';
}

export const SegmentSizeField = ({
    currentSize,
    onRefreshSize,
    lastUpdateAt,
    layout,
    isDisabled,
    isLoading,
    canCalculateSize = true,
    mode,
}: SegmentSizeFieldProps) => {
    const { translateAndReplace, pluralize, translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(`${SCOPE}-${SEGMENT_DATA_IDS_MAPPING[mode]}`);

    const actionLabelKey = React.useMemo(() => {
        if (isLoading) {
            return SEGMENT.CALCULATING_SEGMENT_SIZE;
        }

        if (currentSize !== 0 && !currentSize) {
            return SEGMENT.CALCULATE_SEGMENT_SIZE;
        }

        return GLOBAL.REFRESH;
    }, [isLoading, currentSize]);

    const wasComputed = Boolean(currentSize || currentSize === 0);

    return (
        <FlexBox
            {...get({
                element: 'size-field',
            })}
            orientation={layout === 'line' ? Orientation.horizontal : Orientation.vertical}
            vAlign={Alignment.left}
            hAlign="center"
        >
            {wasComputed && (
                <div className={layout === 'column' ? element('content-wrapper') : undefined}>
                    <Text as="p" typography="body1" className={element('content')}>
                        {translateAndReplace(pluralize(GLOBAL.NB_PEOPLE, currentSize as number), {
                            nb: currentSize as number,
                        })}
                    </Text>

                    <Text as="p" typography="body1" color="dark" colorVariant="L2" className={element('last-update')}>
                        {translateAndReplace(SEGMENT.SEGMENT_LAST_UPDATED_ON, {
                            date: moment(lastUpdateAt).format(translateKey(GLOBAL.DATE_FORMAT)),
                        })}
                    </Text>
                </div>
            )}
            {canCalculateSize && (
                <Button
                    size="s"
                    emphasis={Emphasis.medium}
                    isDisabled={isDisabled || isLoading}
                    leftIcon={mdiRefresh}
                    onClick={onRefreshSize}
                    className={classnames({
                        [margin('left', 'regular')]: layout === 'line' && wasComputed,
                        [margin('top', 'regular')]: layout === 'column',
                    })}
                    {...get({
                        element: wasComputed ? 'refresh' : 'calculate',
                    })}
                >
                    {translateKey(actionLabelKey)}
                </Button>
            )}
        </FlexBox>
    );
};
