import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetTipSettingsController($timeout, Utils, Widget) {
        'ngInject';

        var widgetTipSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * A temporary uuid value to compare future values with.
         *
         * @type {string}
         */
        var _lastUuid;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the tip should be displayed again after being dismissed or not.
         *
         * @type {boolean}
         */
        widgetTipSettings.notifyAgain = false;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Update the widget properties to notify the user again.
         */
        function switchNotification() {
            $timeout(function timedOut() {
                var currentWidget = Widget.getCurrent();
                currentWidget.properties = currentWidget.properties || {};
                var properties = currentWidget.properties;

                if (angular.isDefined(properties.lastUuidEdit) && angular.isUndefined(_lastUuid)) {
                    _lastUuid = properties.lastUuidEdit;
                }

                properties.lastUuidEdit = (widgetTipSettings.notifyAgain) ? generateUUID() : _lastUuid;
            });
        }

        /////////////////////////////

        widgetTipSettings.switchNotification = switchNotification;
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetTipSettingsController', WidgetTipSettingsController);
})();
