function LsFabController(Layout) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    vm.isOpen = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Layout = Layout;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

}

function LsFabDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: LsFabController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            direction: '@?lsDirection',
            triggerOnClick: '=?lsTriggerOnClick',
            isOpen: '=?lsIsOpen',
        },
        templateUrl: '/client/common/modules/fab/views/fab.html',
        transclude: true,
    };
}

function LsFabTriggerDirective() {
    'ngInject';

    return {
        replace: true,
        require: '^lsFab',
        restrict: 'E',
        templateUrl: '/client/common/modules/fab/views/fab-trigger.html',
        transclude: true,
    };
}

function LsFabActionsDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrl) {
        scope.parentCtrl = ctrl;
    }

    return {
        link,
        replace: true,
        require: '^lsFab',
        restrict: 'E',
        templateUrl: '/client/common/modules/fab/views/fab-actions.html',
        transclude: true,
    };
}

/////////////////////////////

angular
    .module('Directives')
    .directive('lsFab', LsFabDirective)
    .directive('lsFabTrigger', LsFabTriggerDirective)
    .directive('lsFabActions', LsFabActionsDirective);

/////////////////////////////

export { LsFabDirective, LsFabTriggerDirective, LsFabActionsDirective };
