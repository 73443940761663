import React from 'react';

import { useDispatch } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { useGetDeleted } from '../../hooks/useGetDeleted';
import { useSetDeleted } from '../../hooks/useSetDeleted';
import { WidgetHelpProperties } from '../../types';
import { HelpMessage } from './HelpMessage';

interface ClientComputedHelpProps extends ClientComputedProps {
    properties?: WidgetHelpProperties;
}

/** Display widget help content message */
export const ClientComputedHelp: React.FC<ClientComputedHelpProps> = ({
    properties: { content, lastUuidEdit, isDeletable } = {},
    theme,
    uuid: widgetId,
    legacyWidgetId,
}) => {
    const dispatch = useDispatch();
    const { translateObject } = useTranslate();

    const translatedContent = translateObject(content) || '';

    // Check that the user deleted the help message
    const shouldGetDeletedStatus = !!isDeletable && !!translatedContent;
    const deletedStatus = useGetDeleted(shouldGetDeletedStatus, { legacyWidgetId, lastUuidEdit });

    // Delete callback
    const onDelete = useSetDeleted(isDeletable, { legacyWidgetId: legacyWidgetId as string, lastUuidEdit });

    // Widget state
    const isEmpty = !translatedContent || deletedStatus.wasDeleted;
    React.useEffect(() => {
        if (isEmpty) {
            dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, widgetId, isEmpty]);

    if (isEmpty) {
        return null;
    }
    if (deletedStatus.isLoading) {
        return <WidgetSkeletonRectangle />;
    }

    return <HelpMessage theme={theme} html={translatedContent} onDelete={onDelete} />;
};
ClientComputedHelp.displayName = 'ClientComputedHelp';
