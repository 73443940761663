import BaseApi from '@lumapps/base-api';

/**
 * Internal integrations generative ai API.
 */
export const generativeAiApi = new BaseApi({ version: BaseApi.versions.v2, path: 'text-completions' });

interface GetCompletionsRequest {
    prompt: string;
    stream?: boolean;
}

interface GetCompletionsResponse {
    completions: string;
}

export const getCompletions = async (params: GetCompletionsRequest) => {
    return generativeAiApi.post<GetCompletionsResponse>('', params);
};
