import { useMemo } from 'react';

import { MODES, useModes } from '@lumapps/customizations/modes';
import { PLACEMENT, Targets } from '@lumapps/customizations/types';
import { canAccessEndUserJourneys } from '@lumapps/journeys-front-office/ducks/selectors';
import { endUserJourneysList } from '@lumapps/journeys-front-office/routes';
import { lumappsJourney } from '@lumapps/lumx/custom-icons';
import {
    mdiBookmarkOutline,
    mdiCardsOutline,
    mdiHistory,
    mdiMessageAlertOutline,
    mdiNewspaperVariantOutline,
    mdiPlaylistCheck,
} from '@lumapps/lumx/icons';
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { useSelector } from '@lumapps/redux/react';
import { isReportFeatureEnabled } from '@lumapps/reports/ducks/selectors';
import { REPORTS } from '@lumapps/reports/keys';
import { reviews } from '@lumapps/reports/routes';
import { GLOBAL } from '@lumapps/translations';
import { feed } from '@lumapps/user-feed/routes';
import { history } from '@lumapps/user-history/routes';
import { USER_PROFILE_NAVIGATION_LABELS } from '@lumapps/user-profile/constants';
import { isUserProfileReactEnabled } from '@lumapps/user-profile/ducks/selector';
import { useUserPermissions } from '@lumapps/user-profile/hooks/useUserPermissions';
import { socialProfile } from '@lumapps/user-profile/routes';
import { USER_PROFILE_VIEW } from '@lumapps/user-profile/types';
import { USER_SAVED_ITEMS } from '@lumapps/user-saved-items/keys';
import { savedItems } from '@lumapps/user-saved-items/routes';
import { subscriptions } from '@lumapps/user-subscriptions/routes';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { isConnected, isSocialFeatureEnabled } from '../ducks/selectors';
import { USER_MENU_LINK_TYPE, UserMenuLink } from '../types';
import { useBaseUserMenuLinks } from './useBaseUserMenuLinks';

export interface UseTopBarUserMenuLink {
    topBarUserMenuLinks: UserMenuLink[];
}

/**
 * Returns the links to be displayed on the user menu profile dropdown on the top bar.
 * @returns - links to use on the user menu dropdown
 */
export const useTopBarUserMenuLinks = (shouldFetchPermissions?: boolean): UseTopBarUserMenuLink => {
    const { isModeEnabled } = useModes();
    const isReportEnabled = useSelector(isReportFeatureEnabled);
    const hasJourneysEnabled = useSelector(canAccessEndUserJourneys);
    const hasSocialEnabled = useSelector(isSocialFeatureEnabled);
    const hasUserProfileReactFeature =
        useSelector(isUserProfileReactEnabled) || isModeEnabled(MODES.USER_PROFILE_REACT);
    const hasUserSavedItemsEnabled = useSelector(isConnected);

    const isSuperAdmin = useSelector(isInstanceSuperAdmin);
    const { isCommunityAdmin, getUserPermisionsStatus } = useUserPermissions(shouldFetchPermissions);
    const isGetUserPermissionsLoading =
        getUserPermisionsStatus === BaseLoadingStatus.initial || getUserPermisionsStatus === BaseLoadingStatus.loading;
    const { baseUserMenuLinks } = useBaseUserMenuLinks();

    const topBarUserMenuLinks: UserMenuLink[] = useMemo(() => {
        const userMenuLinks = [];

        const showReports = isReportEnabled && (isSuperAdmin || isCommunityAdmin);

        /** Reports link + Divider */
        if (showReports) {
            userMenuLinks.push(
                {
                    key: 'reports',
                    label: REPORTS.REPORTS_TITLE,
                    icon: mdiMessageAlertOutline,
                    route: reviews(),
                    target: '_self',
                    type: USER_MENU_LINK_TYPE.LINK,
                    isLoading: isGetUserPermissionsLoading,
                },
                {
                    key: 'private-divider',
                    type: USER_MENU_LINK_TYPE.DIVIDER,
                },
            );
        }

        /**
         * Journeys.
         * */
        if (hasJourneysEnabled) {
            userMenuLinks.push({
                key: 'journeys',
                label: GLOBAL.JOURNEYS,
                icon: lumappsJourney,
                route: endUserJourneysList(),
                target: '_self',
                type: USER_MENU_LINK_TYPE.LINK,
            });
        }

        /**
         * User feed.
         * */
        userMenuLinks.push({
            key: 'feed',
            label: GLOBAL.FEED,
            icon: mdiNewspaperVariantOutline,
            route: feed(),
            target: '_self',
            type: USER_MENU_LINK_TYPE.LINK,
        });

        /** Saved items link */
        if (hasUserSavedItemsEnabled) {
            userMenuLinks.push({
                key: 'saved-items',
                label: USER_SAVED_ITEMS.SAVED_ITEMS,
                icon: mdiBookmarkOutline,
                route: savedItems(),
                target: '_self',
                type: USER_MENU_LINK_TYPE.LINK,
            });
        }

        /**
         * User subscriptions link.
         */
        if (hasSocialEnabled) {
            userMenuLinks.push({
                key: 'subscriptions',
                label: GLOBAL.SUBSCRIPTIONS,
                icon: mdiPlaylistCheck,
                route: subscriptions(),
                target: '_self',
                type: USER_MENU_LINK_TYPE.LINK,
            });
        }

        /**
         * Activity.
         */
        if (hasSocialEnabled && hasUserProfileReactFeature) {
            userMenuLinks.push({
                key: 'activity',
                label: USER_PROFILE_NAVIGATION_LABELS.activity,
                icon: mdiCardsOutline,
                route: socialProfile({
                    view: USER_PROFILE_VIEW.activity,
                    isUserSpaceEnabled: hasUserProfileReactFeature,
                }),
                target: '_self',
                type: USER_MENU_LINK_TYPE.LINK,
            });
        }

        /**
         * History.
         */
        userMenuLinks.push({
            key: 'history',
            label: GLOBAL.HISTORY,
            icon: mdiHistory,
            route: history(),
            target: '_self',
            type: USER_MENU_LINK_TYPE.LINK,
        });

        /**
         * Divider, then other menu links
         */
        userMenuLinks.push(
            {
                key: 'profile-divider',
                type: USER_MENU_LINK_TYPE.DIVIDER,
            },
            {
                key: 'user-menu-above-customization',
                type: USER_MENU_LINK_TYPE.CUSTOMIZATION,
                customizationProps: {
                    target: Targets.USER_DROPDOWN_LINKS,
                    placement: PLACEMENT.ABOVE,
                },
            },
            ...baseUserMenuLinks,
            {
                key: 'user-menu-under-customization',
                type: USER_MENU_LINK_TYPE.CUSTOMIZATION,
                customizationProps: {
                    target: Targets.USER_DROPDOWN_LINKS,
                    placement: PLACEMENT.UNDER,
                },
            },
        );

        return userMenuLinks;
    }, [
        isReportEnabled,
        hasJourneysEnabled,
        isSuperAdmin,
        isCommunityAdmin,
        hasSocialEnabled,
        hasUserSavedItemsEnabled,
        hasUserProfileReactFeature,
        baseUserMenuLinks,
        isGetUserPermissionsLoading,
    ]);

    return { topBarUserMenuLinks };
};
