(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetDrawioSettingsController($rootScope, $timeout, Translation, Widget) {
        'ngInject';

        var widgetDrawioSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Flag to reset radio buttons when deleting a file.
         *
         * @type {boolean}
         */
        var _onDelete = false;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Whether to display a warning regarding mixed https & http content.
         *
         * @type {boolean}
         */
        widgetDrawioSettings.displayHttpsWarning = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetDrawioSettings.Translation = Translation;
        widgetDrawioSettings.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Updates widget when url or height is changed.
         */
        function updateWidget() {
            $timeout(function timedOutFilePreviewSettingsBroadcast() {
                $rootScope.$broadcast('widget-iframe-settings', Widget.getCurrent().uuid);
            });
        }

        /**
         * Deletes selected file by clearing all properties.
         *
         * @param {Object} target The file to delete.
         */
        function deleteSelectedFile(target) {
            var currentWidget = Widget.getCurrent();
            var properties = _.get(currentWidget, 'properties');
            if (angular.isUndefined(properties) || angular.isUndefinedOrEmpty(target)) {
                return;
            }

            properties[target + 'Id'] = undefined;
            properties.embedUrl = undefined;
            properties[target + 'Name'] = undefined;
            properties[target + 'Url'] = undefined;
            properties.url = undefined;
            properties.height = undefined;
            currentWidget.title[Translation.inputLanguage] = undefined;
            properties.more.link[Translation.inputLanguage] = undefined;

            _onDelete = true;
            widgetDrawioSettings.updateWidget();
            widgetDrawioSettings.init();
            _onDelete = false;
        }

        /**
         * Triggered when a file has been selected.
         *
         * @param {Object} fileInfo The file selected by the user.
         * @param {Object} target   In this case is "picked" given by the API.
         */
        function drivePickerCallback(fileInfo, target) {
            if (angular.isDefinedAndFilled(fileInfo) && angular.isDefinedAndFilled(target)) {
                var currentWidget = Widget.getCurrent();
                currentWidget.properties = currentWidget.properties || {};
                // FIXME [elie]: remove when the TD picker is available.
                var properties = currentWidget.properties;
                if (angular.isDefinedAndFilled(fileInfo.id)) {
                    properties[target + 'Id'] = fileInfo.id;
                    properties.embedUrl = fileInfo.embedUrl;
                    properties[target + 'Name'] = fileInfo.name;
                    properties[target + 'Url'] = fileInfo.url;
                    properties.url = fileInfo.url;
                } else if (angular.isDefinedAndFilled(fileInfo.url)) {
                    properties[target + 'Id'] = fileInfo.url.split('/').pop();
                }
            }

            widgetDrawioSettings.updateWidget();
        }

        /**
         * Sets file name as title of the widget.
         */
        function fileAsTitle() {
            var currentWidget = Widget.getCurrent();
            _.set(currentWidget, 'title.' + Translation.inputLanguage, currentWidget.properties.pickedName);

            widgetDrawioSettings.updateWidget();
        }

        /**
         * Sets link to file as link of the widget.
         */
        function fileAsLink() {
            var currentWidgetProperties = Widget.getCurrent().properties;
            _.set(currentWidgetProperties, 'more.link.' + Translation.inputLanguage, currentWidgetProperties.pickedUrl);

            widgetDrawioSettings.updateWidget();
        }

        /**
         * Format the iframe url.
         */
        function formatUrl() {
            var iframeUrl = Widget.getCurrent().properties.url[Translation.inputLanguage];

            if (angular.isDefinedAndFilled(iframeUrl) && !iframeUrl.startsWith('http')) {
                iframeUrl = 'http://' + iframeUrl;
            }

            widgetDrawioSettings.updateWidget();
        }

        /**
         * Retrieves the id of the file.
         *
         * @param  {Object} target The file we want to get the id of.
         * @return {string} File id if there is one.
         */
        function getFileId(target) {
            if (angular.isUndefinedOrEmpty(target)) {
                return undefined;
            }

            return Widget.getCurrent().properties[target + 'Id'];
        }

        /**
         * Gets the file name, if no file name, return the file id.
         *
         * @param  {Object} target The file we want to get the name of.
         * @return {string} The file name if there is one.
         */
        function getFileName(target) {
            if (angular.isUndefinedOrEmpty(target)) {
                return undefined;
            }

            var currentWidget = Widget.getCurrent();
            var currentWidgetProperties = _.get(Widget.getCurrent(), 'properties', {});
            if (angular.isDefinedAndFilled(currentWidgetProperties[target + 'Name'])) {
                currentWidgetProperties.title = currentWidgetProperties[target + 'Name'];

                return currentWidgetProperties[target + 'Name'];
            }

            return currentWidget.properties[target + 'Id'];
        }

        /**
         * Retrieves the file url.
         *
         * @param  {Object} target The file.
         * @return {string} The file id if there is one.
         */
        function getFileUrl(target) {
            if (angular.isUndefinedOrEmpty(target)) {
                return undefined;
            }

            var currentWidgetProperties = Widget.getCurrent().properties;
            if (angular.isDefinedAndFilled(currentWidgetProperties[target + 'Url'])) {
                currentWidgetProperties.url = currentWidgetProperties[target + 'Url'];

                return currentWidgetProperties[target + 'Url'];
            }

            return currentWidgetProperties[target + 'Url'];
        }

        /**
         * Checks if a file/ file id is set else clear the folder property.
         *
         * @param  {Object}  target The file.
         * @return {boolean} Whether or not the file is selected.
         */
        function isFileSelected(target) {
            if (angular.isUndefined(target)) {
                return undefined;
            }

            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var currentWidgetProperties = currentWidget.properties;
            if (angular.isDefined(currentWidgetProperties[target + 'Id'])) {
                return true;
            }

            currentWidgetProperties[target + 'Id'] = undefined;
            currentWidgetProperties[target + 'Name'] = undefined;

            return false;
        }

        /////////////////////////////

        widgetDrawioSettings.deleteSelectedFile = deleteSelectedFile;
        widgetDrawioSettings.drivePickerCallback = drivePickerCallback;
        widgetDrawioSettings.fileAsLink = fileAsLink;
        widgetDrawioSettings.fileAsTitle = fileAsTitle;
        widgetDrawioSettings.formatUrl = formatUrl;
        widgetDrawioSettings.getFileId = getFileId;
        widgetDrawioSettings.getFileName = getFileName;
        widgetDrawioSettings.getFileUrl = getFileUrl;
        widgetDrawioSettings.isFileSelected = isFileSelected;
        widgetDrawioSettings.updateWidget = updateWidget;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        widgetDrawioSettings.init = function init() {
            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var currentWidgetProperties = currentWidget.properties;
            if (_onDelete === true) {
                currentWidgetProperties.titleType = 'addOwnTitle';
                currentWidgetProperties.linkType = 'addOtherLink';
            } else {
                currentWidgetProperties.titleType = currentWidgetProperties.titleType || 'addOwnTitle';
                currentWidgetProperties.linkType = currentWidgetProperties.linkType || 'addOtherLink';
            }
        };

        widgetDrawioSettings.init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetDrawioSettingsController',
        WidgetDrawioSettingsController);
})();
