import find from 'lodash/find';
import includes from 'lodash/includes';

import { CUSTOM_CONTENT_TYPE_PERMISSIONS } from '@lumapps/content-types/permissions';
import { Subscription } from '@lumapps/user/types';

import { Content } from '../types';

export interface IsUserEditorOfContentProps {
    content: Content;
    subscriptions: Subscription[] | undefined;
    auths: Record<string, boolean>;
    canEditOnlyOwnContentAuths: Record<string, boolean>;
    userId?: string;
    isInstanceSuperAdmin?: boolean;
}

/**
 * Determines whether the current user has access to the current content displayed.
 * It takes into account the permissions that the user has, evaluating if the user has access
 * to the edition of the custom content type of the current content, or if the user has access
 * as an editor to the current content.
 */
export const isUserEditorOfContent = ({
    content,
    subscriptions,
    auths,
    canEditOnlyOwnContentAuths,
    userId,
    isInstanceSuperAdmin = false,
}: IsUserEditorOfContentProps) => {
    if (content) {
        /**
         * if the user is super admin (of this instance or in general), they are always able to edit
         * any content.
         */
        if (isInstanceSuperAdmin) {
            return true;
        }

        const { editors, template, customContentType, authorId } = content;

        /**
         * Custom content type can be located on the template but for specific scenarios like the home page
         * the custom content type id can be at the root level.
         */
        const customContentTypeId =
            template && template.customContentType ? template.customContentType : customContentType;

        /**
         * We check whether the current user has acces to the custom content type of the current content
         */
        const cctPermissionId = customContentTypeId
            ? `${CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT}_${customContentTypeId}`
            : '';

        let isEditorOfCustomContentType = auths[cctPermissionId];

        /**
         * If the role for this particular custom content type allows users to only edit their own
         * content, we need to check that the content was created by the same user as the current one.
         */
        if (canEditOnlyOwnContentAuths[cctPermissionId]) {
            isEditorOfCustomContentType = isEditorOfCustomContentType && authorId === userId;
        }

        /**
         * The user can be also an editor of the current content, which is something that can be configured
         * at the content level.
         */
        if (editors && editors.length > 0) {
            const feedInEditor = find(subscriptions, (subscription) => includes(editors, subscription.feed));

            isEditorOfCustomContentType = isEditorOfCustomContentType || Boolean(feedInEditor);
        }

        return Boolean(isEditorOfCustomContentType);
    }

    return false;
};
