import { useId } from '@lumapps/utils/hooks/useId';

import { AriaIdType } from '../ducks/reducer';
import { useRegisterAriaId } from './useRegisterAriaId';

type Options = {
    // A prefix to set before the generated id;
    prefix?: string;
    // To manage whether the id should be registered
    shouldRegister?: boolean;
};

/**
 * Hook to generate a random id and register it to an AriaIdsProvider.
 * Useful if you don't want to set a specific id or multiple ids as aria label.
 *
 * If you need more control, use `useRegisterAriaId` directly.
 */
export const useGenerateAriaId = (ariaType: AriaIdType, options: Options = {}) => {
    const { prefix, shouldRegister = true } = options;
    const titleId = useId(prefix);

    useRegisterAriaId(ariaType, shouldRegister ? titleId : undefined);

    return titleId;
};
