import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiSettings } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { MOBILE_GENERAL_SETTINGS } from '../../keys';
import { mobileGeneralSettingsRoute } from '../../routes';

export interface MobileGeneralSettingsSideNavigationProps {
    isAllowed: boolean;
}

const MobileGeneralSettingsSideNavigation = ({ isAllowed }: MobileGeneralSettingsSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            label={translateKey(MOBILE_GENERAL_SETTINGS.TITLE)}
            icon={mdiSettings}
            route={mobileGeneralSettingsRoute}
        />
    );
};

MobileGeneralSettingsSideNavigation.displayName = 'SideNavigationItem';

export { MobileGeneralSettingsSideNavigation };
