import React from 'react';

import type { LumappsTrackingLinkPreviewAction, NotificationTargetType } from '@lumapps/analytics-tracking/types';

export interface LinkPreviewTrackingActionProps {
    resourceType: NotificationTargetType;
    resourceId: string;
    title: string;
    url: string;
}
export type GetLinkPreviewTrackingAction = (
    trackingActionProps: LinkPreviewTrackingActionProps,
) => LumappsTrackingLinkPreviewAction;

/** Context to be used through LinkPreviewTrackingContext.Provider to enable tracking */
export const LinkPreviewTrackingContext = React.createContext<
    undefined | { getTrackingAction: GetLinkPreviewTrackingAction }
>(undefined);

/** Custom hook to retrieve value of context */
export const useLinkPreviewTrackingContext = () => React.useContext(LinkPreviewTrackingContext);
