/* istanbul ignore file */
import { SA_PROGRAMS } from './keys';
import { FormSteps, ProgramPostError, Step } from './types';

export const SA_PROGRAMS_FEATURE_FLAG: { [key: string]: boolean } = {
    multiLangForm: false,
};

/**
 * The form steps.
 * Adding components here will automatically add them to the wizard.
 */
export const FORM_STEPS: Step[] = [
    {
        slug: FormSteps.informationForm,
        label: SA_PROGRAMS.PROGRAM_CREATION_STEP_INFORMATION_LABEL,
    },
    {
        slug: FormSteps.members,
        label: SA_PROGRAMS.PROGRAM_CREATION_STEP_MEMBERS_LABEL,
    },
    {
        slug: FormSteps.topics,
        label: SA_PROGRAMS.PROGRAM_CREATION_STEP_TOPICS_LABEL,
    },
    {
        slug: FormSteps.socialNetworks,
        label: SA_PROGRAMS.PROGRAM_CREATION_STEP_SOCIAL_NETWORKS_LABEL,
    },
];

export const ERROR_CASES: Record<string, ProgramPostError> = {
    'domain.program.create.duplicate_on_slug': {
        goToStep: FormSteps.informationForm,
        errorField: 'slug',
        errorMessage: SA_PROGRAMS.PROGRAM_CREATION_ERROR_DUPLICATE_SLUG,
    },
    'domain.program.create.duplicate_on_name': {
        goToStep: FormSteps.informationForm,
        errorField: 'name',
        errorMessage: SA_PROGRAMS.PROGRAM_CREATION_ERROR_DUPLICATE_NAME,
    },
    'domain.program.update.duplicate_on_slug': {
        goToStep: FormSteps.informationForm,
        errorField: 'slug',
        errorMessage: SA_PROGRAMS.PROGRAM_CREATION_ERROR_DUPLICATE_SLUG,
    },
    'domain.program.update.duplicate_on_name': {
        goToStep: FormSteps.informationForm,
        errorField: 'name',
        errorMessage: SA_PROGRAMS.PROGRAM_CREATION_ERROR_DUPLICATE_NAME,
    },
};

/*
 * The maximum members a program can have.
 *
 * IF this value changes, please also change the helper condition in the
 * `ProgramMemberShipForm` component
 */
export const MAX_PROGRAM_MEMBERSHIPS = 50000;

/*
 * The number of retrieved program members per fetch to backend.
 */
export const MEMBERS_PER_FETCH = 30;

/*
 * The unique language for slugs. For historical reasons, slugs must be saved as multi-languages but actually is only
 * saved in english
 */
export const PROGRAM_SLUG_LANGUAGE = 'en';

/*
 * The number of retrieved users per fetch to backend when searching users to add.
 */
export const USERS_PER_FETCH = 20;

/*
 * The number of retrieved groups per fetch to backend when searching groups to add.
 */
export const GROUPS_PER_FETCH = 20;
