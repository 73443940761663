import React from 'react';

import { Dialog, Emphasis, Toolbar, Button, Size } from '@lumapps/lumx/react';
import { mdiEye } from '@lumapps/lumx/icons';
import { func, node, bool } from 'prop-types';

import { translate as t } from 'components/translations';

const SAShareableWorkflowDialog = ({
    isOpen,
    isEdit,
    canSave,
    canCancel,
    canPreview,
    isLoading,
    isPreviewOpen,
    children,
    onSubmit,
    onCancel,
    toggleWorkflowPreview,
}) => {
    const closePreview = () => {
        toggleWorkflowPreview(false);
    };

    const dialogTitle = isEdit
        ? t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.EDIT.TITLE')
        : t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.NEW.TITLE');

    return (
        <Dialog
            footer={
                <Toolbar
                    after={
                        <>
                            <Button
                                disabled={!canCancel}
                                emphasis={Emphasis.medium}
                                type="button"
                                onClick={isPreviewOpen ? closePreview : onCancel}
                            >
                                {isPreviewOpen ? t('GLOBAL.BACK') : t('GLOBAL.CANCEL')}
                            </Button>
                            <Button
                                className="shareable-workflow__submit-btn"
                                disabled={!canSave}
                                emphasis={Emphasis.high}
                                type="button"
                                onClick={onSubmit}
                            >
                                {isEdit
                                    ? t('GLOBAL.SAVE')
                                    : t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.CREATE_SHAREABLE_CONTENT')}
                            </Button>
                        </>
                    }
                />
            }
            header={
                <Toolbar
                    after={
                        !isPreviewOpen && (
                            <Button
                                disabled={!canPreview}
                                emphasis={Emphasis.medium}
                                leftIcon={mdiEye}
                                type="button"
                                onClick={() => toggleWorkflowPreview(true)}
                            >
                                {t('GLOBAL.PREVIEW')}
                            </Button>
                        )
                    }
                    label={
                        <span className="lumx-typography-title">
                            {isPreviewOpen ? t('GLOBAL.PREVIEW') : dialogTitle}
                        </span>
                    }
                />
            }
            isLoading={isLoading}
            isOpen={isOpen}
            size={Size.regular}
        >
            {children}
        </Dialog>
    );
};

SAShareableWorkflowDialog.propTypes = {
    canCancel: bool,
    canPreview: bool,
    canSave: bool,
    children: node,
    isEdit: bool,
    isLoading: bool,
    isOpen: bool,
    isPreviewOpen: bool,
    onCancel: func,
    onSubmit: func,
    toggleWorkflowPreview: func,
};

SAShareableWorkflowDialog.defaultProps = {
    canCancel: false,
    canPreview: false,
    canSave: true,
    children: null,
    isEdit: false,
    isLoading: false,
    isOpen: false,
    isPreviewOpen: false,
    onCancel: null,
    onSubmit: null,
    toggleWorkflowPreview: null,
};

export { SAShareableWorkflowDialog };
