export enum WREX_TYPOGRAPHY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40496111
     */
    BOLD_BUTTON = 'FRONT.TEXT_EDITOR.FORMAT.BOLD_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470846375
     */
    BOLD_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.BOLD_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=132617936
     */
    CODEBLOCK_LABEL = 'FRONT.TEXT_EDITOR.HEADINGS.CODE_BLOCK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40519471
     */
    HEADINGS_TYPE_SELECTION = 'FRONT.TEXT_EDITOR.HEADINGS.TYPE_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32469798
     */
    HEADING1_LABEL = 'FRONT.TEXT_EDITOR.HEADINGS.HEADING1',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32469800
     */
    HEADING2_LABEL = 'FRONT.TEXT_EDITOR.HEADINGS.HEADING2',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473536573
     */
    HEADING3_LABEL = 'WREX_TYPOGRAPHY.HEADING3',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=64588145
     */
    INLINE_CODE_BUTTON = 'FRONT.TEXT_EDITOR.FORMAT.CODE_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=40496103
     */
    ITALIC_BUTTON = 'FRONT.TEXT_EDITOR.FORMAT.ITALIC_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470849578
     */
    ITALIC_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.ITALIC_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32469803
     */
    NORMAL_TEXT_LABEL = 'FRONT.TEXT_EDITOR.HEADINGS.NORMAL_TEXT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86785371
     */
    REDO_BUTTON = 'FRONT.TEXT_EDITOR.HISTORY.REDO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470841393
     */
    REDO_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.REDO_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=474305657
     */
    STRIKETHROUGH = 'WREX_TYPOGRAPHY.STRIKETHROUGH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=474305663
     */
    STRIKETHROUGH_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.STRIKETHROUGH_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473676034
     */
    UNDERLINE = 'WREX_TYPOGRAPHY.UNDERLINE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=473613984
     */
    UNDERLINE_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.UNDERLINE_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86785368
     */
    UNDO_BUTTON = 'FRONT.TEXT_EDITOR.HISTORY.UNDO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470793436
     */
    UNDO_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.UNDO_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=440667337
     */
    FORMATTING_OPTIONS_TOOLTIP = 'FRONT.TEXT_EDITOR.FORMAT.FORMATTING_OPTIONS_TOOLTIP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468104480
     */
    MORE_INSERT_OPTIONS = 'FRONT.TEXT_EDITOR.MORE_INSERT_OPTIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481742615
     */
    ALIGN_START = 'WREX_TYPOGRAPHY.ALIGN_START',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481742690
     */
    ALIGN_START_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.ALIGN_START_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481747143
     */
    ALIGN_CENTER = 'WREX_TYPOGRAPHY.ALIGN_CENTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481747153
     */
    ALIGN_CENTER_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.ALIGN_CENTER_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481747901
     */
    ALIGN_END = 'WREX_TYPOGRAPHY.ALIGN_END',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=481748006
     */
    ALIGN_END_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.ALIGN_END_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540665996
     */
    ALIGN_JUSTIFY = 'WREX_TYPOGRAPHY.ALIGN_JUSTIFY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=540650263
     */
    ALIGN_JUSTIFY_WITH_SHORTCUT = 'WREX_TYPOGRAPHY.ALIGN_JUSTIFY_WITH_SHORTCUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=482265172
     */
    ALIGN = 'WREX_TYPOGRAPHY.ALIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=549302798
     */
    DIVIDER = 'WREX_TYPOGRAPHY.DIVIDER',
}
