/* eslint-disable */
import get from 'lodash/get';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function FollowButtonController($scope, Instance, Interest, SocialSubscription, Translation, User, Utils) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The entry of the _followingCache the follow button relates to.
     * Used for user and content types.
     *
     * @type {Object}
     */
    let _currentFollowingEntry;

    /**
     * The entry of the _followingCache the follow button relates to.
     * Used for interests type.
     *
     * @type {Object}
     */
    let _currentFollowingInterestEntries = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the current subscription is for a feed or not.
     *
     * @type {boolean}
     */
    vm.isFeedSubscription = false;

    /**
     * Indicates if the current entity is being followed by the user or not.
     *
     * @type {boolean}
     */
    vm.isFollowed = false;

    /**
     * Indicates if the user is currently subscribed to notifications on this entity.
     *
     * @type {boolean}
     */
    vm.isNotified = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Interest = Interest;
    vm.SocialSubscription = SocialSubscription;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the name of the parameter for the entity identifier used in the social subscription call.
     *
     * @return {string} The name of the parameter.
     */
    function _getIdentifierParamName() {
        return vm.kind.toLowerCase() === 'user' ? 'followingUserKey' : 'followingContentKey';
    }

    /**
     * Initialize the directive in the context of Contents or Users.
     */
    function _contentOrUserInit() {
        _currentFollowingEntry = SocialSubscription.getFollowing(vm.entity.id, vm.kind);

        if (angular.isUndefinedOrEmpty(_currentFollowingEntry) && angular.isDefinedAndFilled(vm.entity.subscription)) {
            _currentFollowingEntry = SocialSubscription.addFollowing(vm.entity.id, vm.kind, vm.entity.subscription);
        }

        // Check if user is not already following this entity, to avoid doing a useless get call.
        if (angular.isDefinedAndFilled(_currentFollowingEntry)) {
            vm.isFollowed = true;
            vm.isNotified = get(_currentFollowingEntry.subscription, 'notify', false);
            vm.isFeedSubscription =
                get(_currentFollowingEntry.subscription, 'type') === SocialSubscription.SUBSCRIPTION_TYPES.FEED_CONTENT;
        } else if (vm.kind === 'USER') {
            const params = {
                instanceKey: Instance.getCurrentInstanceId(),
                status: 'LIVE',
            };

            params[_getIdentifierParamName()] = vm.entity.id;

            SocialSubscription.get(
                params,
                function onSocialSubscriptionGetSuccess(response) {
                    _currentFollowingEntry = SocialSubscription.addFollowing(vm.entity.id, vm.kind, response);

                    vm.isNotified = response.notify;
                    vm.isFollowed = true;
                    vm.isFeedSubscription = response.type === SocialSubscription.SUBSCRIPTION_TYPES.FEED_CONTENT;
                },
                undefined,
                vm.listKey,
                undefined,
                vm.kind,
            );
        }
    }

    /**
     * Initialize the directive in the context of Interest.
     */
    function _interestInit() {
        vm.findingSubscription = true;

        // Subscription endpoints do not allow for multiple content types
        // Therefore, follow, unfollow, activate notifications and deactivate notifications are executed within a loop
        // However, to reduce the amount of work and the risk of regression, isFollowed and isNotified are
        //     only computed based on the first selected content type.
        // This means that if you already follow 2 content types and want to add a third one, you'll have to
        //     click on unfollow and then back on follow.
        _currentFollowingInterestEntries = [];
        function onFirstInterestListSuccess(response) {
            vm.findingSubscription = false;
            vm.isFollowed = angular.isDefinedAndFilled(response);
            vm.isNotified = Boolean(get(response, 'notify', false));
            onInterestListSuccess(response);
        }
        function onFirstInterestFindError() {
            vm.findingSubscription = false;
        }
        function onInterestListSuccess(response) {
            _currentFollowingInterestEntries.push(response);
        }
        angular.forEach(vm.params.customContentTypes, (cct, index) => {
            Interest.findSubscription(
                cct,
                vm.params.customContentTypeTags,
                vm.params.orMetadata,
                index === 0 ? onFirstInterestListSuccess : onInterestListSuccess,
                index === 0 ? onFirstInterestFindError : undefined,
            );
        });

    }

    /**
     * Toggles the subscription in the case we are dealing with User or Content.
     */
    function _toggleContentOrUserSubscription() {
        const followingEntity = SocialSubscription.getFollowing(vm.entity.id, vm.kind);
        const subscription = get(followingEntity, 'subscription');
        const followingEntityId = get(followingEntity, 'entityId');

        if (angular.isDefinedAndFilled(subscription)) {
            SocialSubscription.unfollow(
                subscription.id,
                followingEntityId,
                vm.kind,
                function onUnfollowSuccess() {
                    _currentFollowingEntry = undefined;
                    vm.isFollowed = false;
                    vm.isNotified = false;
                    vm.isFeedSubscription = false;
                },
                undefined,
                vm.listKey,
            );
        } else if (
            angular.isUndefinedOrEmpty(subscription) ||
            !SocialSubscription.isFollowing(subscription.id, vm.kind)
        ) {
            SocialSubscription.follow(
                vm.entity.id,
                vm.kind,
                User.getConnected().uid,
                get(vm.entity, 'properties.followAndNotify', false),
                function onFollowSuccess(response) {
                    _currentFollowingEntry = SocialSubscription.isFollowing(vm.entity.id, vm.kind);

                    vm.isFollowed = true;
                    // Note: response.notify is always false.
                    vm.isNotified = response.notify;
                    vm.isFeedSubscription = response.type === SocialSubscription.SUBSCRIPTION_TYPES.FEED_CONTENT;
                },
                undefined,
                vm.listKey,
            );
        }
    }

    /**
     * Toggles the subscription when we are dealing with Interests.
     */
    function _toggleInterestSubscription() {
        const instanceId = Instance.getCurrentInstanceId()

        if (vm.isFollowed) {
            if (angular.isUndefinedOrEmpty(_currentFollowingInterestEntries)) {
                return;
            }

            try {
                angular.forEach(_currentFollowingInterestEntries, (entry) => {
                    Interest.unsubscribe(
                        entry,
                        function onSubscriptionSuccess() {
                            vm.isFollowed = false;
                            _currentFollowingInterestEntries = [];
                        },
                        undefined,
                    );
                });
            } catch (e) {}
        } else {
            angular.forEach(vm.params.customContentTypes, (cct) => {
                try {
                    Interest.subscribe(
                        cct,
                        vm.params.customContentTypeTags,
                        vm.params.orMetadata,
                        instanceId,
                        function onSubscriptionSuccess(subscription) {
                            vm.isFollowed = true;
                            _currentFollowingInterestEntries.push(subscription);
                        },
                        undefined,
                    );
                } catch (e) {}
            });
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Toggle the notification status of the user for the current entity.
     */
    function toggleNotificationStatus() {
        if (vm.kind === SocialSubscription.FOLLOWING_TYPES.interest) {
            const currentFollowingInterestEntriesCopy = angular.fastCopy(_currentFollowingInterestEntries);
            _currentFollowingInterestEntries = [];
            const willBeNotified = !vm.isNotified;
            angular.forEach(currentFollowingInterestEntriesCopy, (entry) => {
                if (angular.isObject(entry)) {
                    entry.notify = willBeNotified;
                    Interest.save(
                        entry,
                        function onSaveSuccess(response) {
                            _currentFollowingInterestEntries.push(response);
                            vm.isNotified = willBeNotified;
                        },
                        undefined,
                    );
                }
            });
        } else {
            const subscription = angular.fastCopy(_currentFollowingEntry.subscription || _currentFollowingEntry);
            if (angular.isUndefinedOrEmpty(subscription)) {
                return;
            }

            if (subscription.type === SocialSubscription.SUBSCRIPTION_TYPES.FEED_CONTENT) {
                subscription.type = SocialSubscription.SUBSCRIPTION_TYPES.USER_CONTENT;
                delete subscription.followerFeedKey;
                delete subscription.uid;
                delete subscription.id;
                subscription.followerUserKey = get(User.getConnected(), 'uid');
            }

            subscription.notify = !subscription.notify;

            SocialSubscription.save(
                subscription,
                function onSocialSubscriptionSaveSuccess(result) {
                    SocialSubscription.addFollowing(
                        _currentFollowingEntry.entityId,
                        _currentFollowingEntry.type,
                        subscription,
                    );
                    vm.isNotified = !vm.isNotified;
                    vm.isFeedSubscription = false;
                    _currentFollowingEntry.subscription = result;
                },
                undefined,
                vm.listKey,
            );
        }
    }

    /**
     * Handle subscription / unsubscription to the current entity.
     */
    function toggleSubscriptionStatus() {
        if (vm.kind === SocialSubscription.FOLLOWING_TYPES.interest) {
            _toggleInterestSubscription();
        } else {
            _toggleContentOrUserSubscription();
        }
        localStorage.removeItem('serviceInitCache');
    }

    /////////////////////////////

    vm.toggleNotificationStatus = toggleNotificationStatus;
    vm.toggleSubscriptionStatus = toggleSubscriptionStatus;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    if (vm.kind === SocialSubscription.FOLLOWING_TYPES.interest) {
        /**
         * If the params are changing, we have to reset the button in order to check if the new
         * current interest is followed or not.
         *
         * There is no point to add this watcher if the entity is not an Interest.
         */
        $scope.$watch(
            function watchQuery() {
                return vm.params;
            },
            function onParamChange(newValue, oldValue) {
                if (newValue !== oldValue) {
                    vm.init();
                }
            },
        );

        /**
         * If the canSubscribe is changing, we have to update the whole directive according to this new value.
         *
         * There is no point to add this watcher if the entity is not an Interest.
         */
        $scope.$watch(
            function watchQuery() {
                return vm.canSubscribe;
            },
            function onParamChange(newValue, oldValue) {
                if (newValue !== oldValue && !newValue) {
                    vm.init();
                }
            },
        );
    }
    if (vm.kind?.toLowerCase() === SocialSubscription.FOLLOWING_TYPES.user) {
        /**
         * If the user changes, reload.
         */
        $scope.$watch(
            function watchQuery() {
                return vm.entity?.id;
            },
            function onUserChange(newValue, oldValue) {
                if (newValue !== oldValue) {
                    vm.isNotified = false;
                    vm.isFollowed = false;
                    vm.isFeedSubscription = false;

                    vm.init();
                }
            },
        );
    }

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        vm.entity = vm.entity || {};
        vm.kind = vm.kind || SocialSubscription.FOLLOWING_TYPES.content;
        vm.canSubscribe = angular.isDefined(vm.canSubscribe) ? vm.canSubscribe : true;
        vm.textFollow = vm.textFollow || Translation.translate('GLOBAL.FOLLOW');
        vm.textFollowed = vm.textFollowed || Translation.translate('GLOBAL.FOLLOWED');
        vm.listKey = `follow-${generateUUID()}`;

        if (angular.isUndefinedOrEmpty([vm.entity, vm.params], 'every') || !vm.canSubscribe) {
            vm.isFollowed = false;

            return;
        }

        if (vm.kind === SocialSubscription.FOLLOWING_TYPES.interest) {
            _interestInit();
        } else {
            _contentOrUserInit();
        }
    };

    vm.init();
}

/////////////////////////////

/**
 * A directive to display a follow button.
 *
 * @param {boolean} [canSubscribe=true]   Whether the user can subscribe to the given params/entity.
 * @param {Object}  [entity]              The entity (Content, Community or User) we want to display the follow
 *                                        button for.
 *                                        If it's a content or a community, if there is a `subscription` property,
 *                                        then the entity is already followed, else it's not followed.
 *                                        If it's an user, then the subscription will be get via the endpoint.
 * @param {string}  [kind="CONTENT"]      The kind of entity.
 *                                        Possible values are: "CONTENT" (for a Content or a Community), "USER" and
 *                                        "INTEREST" in the case the button is displayed in a widget content filter.
 * @param {string}  [params]              In the case of an interest entity, we have to keep the parameters in
 *                                        order to toggle the subscriptions.
 *                                        Possible values are: "CONTENT" (for a Content or a Community) or "USER".
 * @param {string}  [size="m"]            The size of the follow button.
 *                                        Possible values are: "s", "m" or "l".
 * @param {string}  [textFollow]          The text displayed on the button when the button allows to follow.
 * @param {string}  [textFollowed]        The text displayed on the button when the button allows to unfollow.
 * @param {string}  [viewMode="extended"] The type of follow button to display.
 *                                        Possible values are: "basic" (to display only the "Follow/Unfollow"
 *                                        button) or "extended" (to also display the bell for enabling/disabling
 *                                        notifs).
 */

function followButtonComponent() {
    'ngInject';

    return {
        bindings: {
            canSubscribe: '<?lsCanSubscribe',
            entity: '=?lsEntity',
            kind: '@?lsKind',
            params: '<?lsParams',
            size: '@?lsSize',
            textFollow: '@?lsTextFollow',
            textFollowed: '@?lsTextFollowed',
            viewMode: '@?lsViewMode',
        },
        controller: FollowButtonController,
        controllerAs: 'vm',
        templateUrl: '/client/front-office/modules/social/views/follow-button.html',
    };
}

/////////////////////////////

angular.module('Directives').component('lsFollowButton', followButtonComponent());

/////////////////////////////

export { followButtonComponent, FollowButtonController };
