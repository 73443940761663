import { useEffect } from 'react';

// eslint-disable-next-line lumapps/do-not-import-react-query
import {
    type QueryKey as BaseQueryKey,
    useInfiniteQuery,
    useQueryClient,
    UseInfiniteQueryOptions,
    InfiniteData,
} from '@tanstack/react-query';

/**
 * Custom hook to override the base behavior of React Query's `useInfiniteQuery`.
 * The main thing overridden is having only the first stale page being refetched
 * instead of all of them.
 *
 * This is to avoid multiplying the number of refetches done after a long navigation was made.
 */
export const useCustomUseInfiniteQuery = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
>(
    options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
) => {
    const queryClient = useQueryClient();

    // Get the defaulted options, which also allows us to have a stable query hash
    const defaultedOptions = queryClient.defaultQueryOptions(options);
    // Get the query hash to have a stable key and the stale time
    const { queryHash, staleTime = 0 } = defaultedOptions;

    /**
     * By default, react-query fetches all pages sequentially when the query becomes stale
     * This is not a behavior that we want because it can be resource intensive for our infrastructure.
     *
     * Here, when the hook is unmounted, we modify the cache to keep only the first page in it. This way,
     * when we refetch the data, we are fetching only the first page.
     *
     * @see https://tanstack.com/query/v4/docs/react/guides/infinite-queries#what-happens-when-an-infinite-query-needs-to-be-refetched
     * @see https://github.com/TanStack/query/discussions/1670
     * @see https://github.com/TanStack/query/discussions/3576
     */
    useEffect(() => {
        // Parse the query hash to convert to array
        let key: TQueryKey | undefined;
        try {
            key = queryHash ? JSON.parse(queryHash) : undefined;
        } catch (error) {
            key = undefined;
        }

        /**
         * Find the query with the same query hash to find how many observers it currently have
         */
        const queryCache = queryClient.getQueryCache();
        const query = queryCache.getAll().find((query) => query.queryHash === queryHash);
        const observerCount = query?.getObserversCount() || 0;

        /**
         * Remove all pages except the first from cache.
         */
        const invalidateAllNextPages = () => {
            /** Don't invalidate if at least one observer is remaining */
            if (observerCount > 0 || !key || !query) {
                return;
            }
            /**
             * Check if the query should be considered as stale.
             */
            const { dataUpdatedAt = 0 } = query.state || {};
            // The date at which the query should be considered stale
            const staleDate = dataUpdatedAt + staleTime;
            /**
             * A query should be considered stale if
             * * there has been at least one fetch
             * * No stale time has been set (always stale)
             * * The current time is bigger than the stale date.
             */
            const isStale = dataUpdatedAt > 0 ? !staleTime || Date.now() >= staleDate : false;

            if (isStale) {
                queryClient.setQueryData<InfiniteData<TData>>(key, (prev) => ({
                    pages: prev?.pages.slice(0, 1) || [],
                    pageParams: prev?.pageParams.slice(0, 1) || [],
                }));
            }
        };

        invalidateAllNextPages();

        return () => {
            invalidateAllNextPages();
        };
    }, [queryClient, queryHash, staleTime]);

    return useInfiniteQuery(options);
};

export { useCustomUseInfiniteQuery as useInfiniteQuery };
