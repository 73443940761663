import React, { useEffect, useRef, useState } from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { useSelector } from '@lumapps/redux/react';
import { pageTitleSelector } from '@lumapps/router/ducks/selectors';
import { GLOBAL, useTranslate } from '@lumapps/translations';

/**
 * Component to announce page changes to screen readers.
 * The component will retrieve the current page title and read it whenever it changes.
 */
export const RouteAnnouncer = () => {
    const isFirstLoad = useRef(true);
    const [message, setMessage] = useState('');
    const { translateAndReplace } = useTranslate();
    const pageTitle = useSelector(pageTitleSelector);

    useEffect(() => {
        const firstLoad = isFirstLoad.current;
        if (pageTitle) {
            if (firstLoad) {
                isFirstLoad.current = false;
            } else {
                setMessage(
                    translateAndReplace(GLOBAL.NAVIGATED_TO_PAGE, {
                        PAGE_TITLE: pageTitle,
                    }),
                );
            }
        }
    }, [pageTitle, translateAndReplace]);

    return (
        <div id="route-announcer" className={visuallyHidden()} aria-live="assertive" aria-atomic="true">
            {message}
        </div>
    );
};
