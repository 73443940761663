import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';

import { clearLoadingState, setLoadingState } from 'components/components/ducks/loadingStates';
// eslint-disable-next-line id-blacklist
import { error as displayError, success as displaySuccess } from 'components/services/notification';
import { translate as t } from 'components/translations';

import { TERMS_AND_CONDITIONS_FIELDS, EMPTY_TERMS_AND_CONDITIONS } from '../termsAndConditions_constants';

import {
    getTermsAndConditionsTask,
    resetTermsAndConditionsTask,
    saveTermsAndConditionsTask,
} from './termsAndConditions_tasks';

const DOMAIN = 'TERMS_AND_CONDITIONS';
const FETCH_TERMS_AND_CONDITIONS_SUCCESS = `${DOMAIN}/FETCH_TERMS_AND_CONDITIONS_SUCCESS`;
const FETCH_TERMS_AND_CONDITIONS_ERROR = `${DOMAIN}/FETCH_TERMS_AND_CONDITIONS_ERROR`;
const RESET_TERMS_AND_CONDITIONS_SUCCESS = `${DOMAIN}/RESET_TERMS_AND_CONDITIONS_SUCCESS`;
const SAVE_TERMS_AND_CONDITIONS_SUCCESS = `${DOMAIN}/SAVE_TERMS_AND_CONDITIONS_SUCCESS`;
const SAVE_TERMS_AND_CONDITIONS_ERROR = `${DOMAIN}/SAVE_TERMS_AND_CONDITIONS_ERROR`;
const UPDATE_TERMS_AND_CONDITIONS_SUCCESS = `${DOMAIN}/UPDATE_TERMS_AND_CONDITIONS_SUCCESS`;

const HTTP_STATUS_OK = 200;

/**
 * Fetch the terms and conditions of the customer.
 *
 * @return {Object} The current terms and conditions.
 */
const fetchTermsAndConditions = () => async (dispatch, getState) => {
    dispatch(setLoadingState(DOMAIN));

    const customerId = get(getState(), 'customer.id');

    try {
        const { data, status } = await getTermsAndConditionsTask(customerId);

        if (status === HTTP_STATUS_OK) {
            const { termsAndConditions } = data;

            dispatch({
                payload: {
                    termsAndConditions,
                },
                type: FETCH_TERMS_AND_CONDITIONS_SUCCESS,
            });
        } else {
            dispatch({
                type: FETCH_TERMS_AND_CONDITIONS_ERROR,
            });
        }
    } catch (exception) {
        displayError(exception);
    } finally {
        dispatch(clearLoadingState(DOMAIN));
    }
};

/**
 * Update the terms and conditions, without save.
 *
 * @param  {Object} params The query params.
 * @return {Object} The current terms and conditions.
 */
const updateTermsAndConditions = ({ fieldName, value }) => (dispatch, getState) => {
    const { inputCustomerLanguage } = getState().locale;
    const termsAndConditions = get(getState(), 'termsAndConditions', EMPTY_TERMS_AND_CONDITIONS);

    // Check if the field is translatable (= is in TERMS_AND_CONDITIONS_FIELDS).
    if (includes(TERMS_AND_CONDITIONS_FIELDS, fieldName)) {
        termsAndConditions[fieldName] = { ...termsAndConditions[fieldName], [inputCustomerLanguage]: value };
    } else {
        termsAndConditions[fieldName] = value;
    }

    dispatch({
        payload: {
            termsAndConditions,
        },
        type: UPDATE_TERMS_AND_CONDITIONS_SUCCESS,
    });
};

/**
 * Save the terms and conditions.
 *
 * @param  {Object} params The query params.
 * @return {Object} The current terms and conditions.
 */
const saveTermsAndConditions = () => async (dispatch, getState) => {
    const { termsAndConditions } = getState();
    dispatch(setLoadingState(DOMAIN));

    if (isUndefined(get(termsAndConditions, 'customer'))) {
        const { id: customer } = getState().customer;
        termsAndConditions.customer = customer;
    }

    // Remove empty URL to avoid backend reject.
    forEach(termsAndConditions[TERMS_AND_CONDITIONS_FIELDS.REDIRECT_URL], (value, language) => {
        if (value === '') {
            delete termsAndConditions[TERMS_AND_CONDITIONS_FIELDS.REDIRECT_URL][language];
        }
    });

    try {
        const savedTermsAndConditions = await saveTermsAndConditionsTask(termsAndConditions);

        displaySuccess(t('ADMIN.TERMS_AND_CONDITIONS.SAVE.SUCCESS'));
        dispatch({
            payload: {
                termsAndConditions: savedTermsAndConditions,
            },
            type: SAVE_TERMS_AND_CONDITIONS_SUCCESS,
        });
    } catch (exception) {
        displayError(t('ADMIN.TERMS_AND_CONDITIONS.SAVE.ERROR'));
        dispatch({
            type: SAVE_TERMS_AND_CONDITIONS_ERROR,
        });
    } finally {
        dispatch(clearLoadingState(DOMAIN));
    }
};

/**
 * Reset the terms and conditions approuvment for all the users.
 *
 * @return {Object} The current terms and conditions.
 */
const resetUsersApproval = () => async () => {
    try {
        await resetTermsAndConditionsTask();

        displaySuccess(t('ADMIN.TERMS_AND_CONDITIONS.RESET.SUCCESS'));
    } catch (exception) {
        displayError(t('ADMIN.TERMS_AND_CONDITIONS.RESET.ERROR'));
    }
};

/////////////////////////////

export {
    DOMAIN,
    FETCH_TERMS_AND_CONDITIONS_SUCCESS,
    FETCH_TERMS_AND_CONDITIONS_ERROR,
    RESET_TERMS_AND_CONDITIONS_SUCCESS,
    SAVE_TERMS_AND_CONDITIONS_SUCCESS,
    UPDATE_TERMS_AND_CONDITIONS_SUCCESS,
    fetchTermsAndConditions,
    updateTermsAndConditions,
    resetUsersApproval,
    saveTermsAndConditions,
};
