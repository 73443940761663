let getFeed;

/**
 * Exposes the Rss services methods outside angular.
 *
 * @param {Object} rss The angular Rss service.
 */
function setRss(rss) {
    getFeed = rss.getFeed;
}

export { getFeed, setRss };
