import { isAlignedImageWrapper } from '@lumapps/wrex-enhanced-image/utils/isAlignedImageWrapper';
import { isImageGroup } from '@lumapps/wrex-enhanced-image/utils/isImageGroup';
import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import {
    Descendant,
    Editor,
    Element,
    Node,
    NodeEntry,
    Path,
    PathRef,
    ReactEditor,
    Text,
    Transforms,
} from '@lumapps/wrex/slate';
import { getSibling, getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import type { Wrex } from '@lumapps/wrex/types';

import { T_R } from '../../constants';
import type { TableEditor, TDElement } from '../../types';
import { isTable } from '../../utils/isTable';
import { isTableCell } from '../../utils/isTableCell';
import { isTableRow } from '../../utils/isTableRow';

export const normalizeTableCell = ([node, path]: NodeEntry, editor: ReactEditor & TableEditor) => {
    if (!isTableCell(node)) {
        return false;
    }
    const cellPathRef = Editor.pathRef(editor, path);

    const nodeAsTableCell = node as TDElement;

    // A cell should always be in a row
    if (cellPathRef.current && !isTableRow(Editor.parent(editor, cellPathRef.current)[0])) {
        Transforms.wrapNodes(
            editor,
            {
                type: T_R,
                children: [],
            } as Wrex.Element,
            {
                at: cellPathRef.current,
            },
        );
    }

    if (cellPathRef.current && nodeAsTableCell.children.length === 0) {
        // if cell empty > insert empty paragraph
        Transforms.insertNodes(editor, createParagraph(), {
            at: [...cellPathRef.current, 0],
        });
    } else if (cellPathRef.current) {
        Editor.withoutNormalizing(editor, () => {
            nodeAsTableCell.children
                .map<[Descendant, PathRef]>((child, i) => [
                    child,
                    Editor.pathRef(editor, [...(cellPathRef.current as Path), i]),
                ])
                .forEach(([child, pathRef]) => {
                    if (
                        (pathRef.current && Element.isElement(child) && Editor.isInline(editor, child)) ||
                        Text.isText(child)
                    ) {
                        // Wrap every direct inline or text children in a paragraph
                        const childPath = pathRef.current as Path;
                        const [previousSibling, previousSiblingPath] = getSibling(editor, childPath, 'before') || [];
                        if (previousSiblingPath && previousSibling && isParagraph(previousSibling)) {
                            // If previous sibling is a paragraph, move the current inline node in this sibling
                            Transforms.moveNodes(editor, {
                                at: childPath,
                                to: [...previousSiblingPath, previousSibling.children.length],
                            });
                        } else {
                            // If previous sibling is not a paragraph, then wrap the current inline node into a paragraph
                            Transforms.wrapNodes(editor, createParagraph(), {
                                at: childPath,
                            });
                        }
                        pathRef.unref();
                    } else if (pathRef.current && !isParagraph(child)) {
                        // Cell cannot have a block as child, so we move those blocks out of the table.
                        const childPath = pathRef.unref() as Path;
                        const [[, tablePath]] = Editor.nodes(editor, {
                            at: childPath,
                            match: isTable,
                        });
                        const afterPath = getSiblingPath(tablePath, 'after') as Path;

                        if (isAlignedImageWrapper(child)) {
                            const alignedImageChilds = Array.from(Node.children(editor, childPath));
                            const imageGroup = alignedImageChilds.find((nodeEntry) => isImageGroup(nodeEntry[0]));
                            if (imageGroup) {
                                Transforms.moveNodes(editor, {
                                    at: imageGroup[1],
                                    to: afterPath,
                                });
                            }
                        } else {
                            Transforms.moveNodes(editor, {
                                at: childPath,
                                to: afterPath,
                            });
                        }
                    }
                });
        });
    }

    return false;
};
