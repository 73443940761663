import React from 'react';

import last from 'lodash/last';

import { useInfiniteQuery, useQueryClient } from '@lumapps/base-api/react-query';
import { DialogProps } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { UserListDialog } from '@lumapps/user/components/UserListDialog';

import { FetchLikeUserList, reactions } from '../../../api/query';
import { MinimalUserList, ResourceRef } from '../../../api/types';
import { REACTIONS } from '../../../keys';

export interface LikeUserListDialogProps {
    /** Like resource ref */
    resourceRef: ResourceRef<string>;
    /** Dialog is open */
    isOpen: boolean;
    /** Callback on close (click away or close button pressed) */
    onClose(): void;
    /** XHR listing all users who liked */
    fetchLikeUserList: FetchLikeUserList;
    /** Number passed by parent component that indicates the number of people who liked */
    likeCount: number;
    /** Element to focus on close */
    parentElement?: DialogProps['parentElement'];
}

const BASE_PAGE_PARAMS = { maxResults: '20' };

/**
 * List users that liked a page.
 */
export const LikeUserListDialog: React.FC<LikeUserListDialogProps> = ({
    resourceRef,
    isOpen,
    onClose,
    fetchLikeUserList,
    likeCount,
    parentElement,
}) => {
    const { translateKey } = useTranslate();

    const queryClient = useQueryClient();

    const getNextPageParam = ({ cursor, more }: MinimalUserList) => ({ cursor, more });

    // Fetch like list
    const { isLoading, data, fetchNextPage } = useInfiniteQuery({
        queryKey: reactions.listLikesQueryKeys(resourceRef, BASE_PAGE_PARAMS),
        queryFn: ({ pageParam }) => fetchLikeUserList(resourceRef, { ...BASE_PAGE_PARAMS, ...pageParam }),
        getNextPageParam,
        enabled: isOpen,
    });

    // Format user list
    const users = React.useMemo(() => {
        // Flatten fetched pages
        if (data?.pages) {
            return data.pages.flatMap((page) => page.items) || [];
        }
        // Fallback to the initial list (if any)
        const initialPage = isOpen
            ? (queryClient.getQueryData(reactions.listLikesQueryKeys(resourceRef)) as any)
            : null;
        return initialPage?.items || [];
    }, [isOpen, data?.pages, queryClient, resourceRef]);

    // Title:  {likeCount} Users who like this
    const title = [likeCount, translateKey(REACTIONS.USER_LIST_LIKES)].filter(Boolean).join(' ');

    const hasMore = last(data?.pages || [])?.more;
    const onLoadMore = hasMore ? fetchNextPage : undefined;

    return (
        <UserListDialog
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isLoading}
            title={title}
            onLoadMore={onLoadMore}
            users={users}
            parentElement={parentElement}
        />
    );
};
LikeUserListDialog.displayName = 'LikeUserListDialog';
