import { Selector } from '../types';

/** Get value with a string of function selector */
export const getWithSelector = <O>(selector: Selector<O> | undefined, object: O): string => {
    // Use the provided selector function
    if (typeof selector === 'function') {
        return selector(object);
    }
    // Use the provided selector as a property name
    if (typeof selector === 'string') {
        return object[selector] as string;
    }
    return String(object);
};
