/* eslint-disable import/no-mutable-exports */

import noop from 'lodash/noop';

let handleTaggingMap = noop;

/**
 * Binds functions to angular `analytics` service.
 *
 * @param {GoogleAnalytics} GoogleAnalytics The angular GoogleAnalytics service.
 */
function setGoogleAnalytics(GoogleAnalytics) {
    handleTaggingMap = GoogleAnalytics.handleTaggingMap;
}

/////////////////////////////

export { handleTaggingMap, setGoogleAnalytics };
