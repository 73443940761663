/* istanbul ignore file */
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { InstanceAdminSideNavigation } from './InstanceAdminSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isInstanceSuperAdmin(state),
});

const ConnectedInstanceAdminSideNavigation = connect(mapStateToProps)(InstanceAdminSideNavigation);

export { ConnectedInstanceAdminSideNavigation as InstanceAdminSideNavigation };
