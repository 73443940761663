import React, { Ref } from 'react';

import includes from 'lodash/includes';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { SearchField, SearchFieldProps } from '@lumapps/lumx-filter-and-sort/components/SearchField';
import { NoResultsState } from '@lumapps/lumx-states/components/NoResultsState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { Select, SelectProps, List, ListDivider, ListSubheader, ListItem, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { GenericEntityPickerSkeletons } from './GenericEntityPickerSkeletons';

import './index.scss';

const CLASSNAME = 'generic-entity-picker';

export interface GenericEntityPickerProps<I> extends Omit<SelectProps, 'value'> {
    /** Whether the choices are open. */
    isOpen?: boolean;
    /** The choices displayed. Should be an array of typed entity. */
    choices?: I[];
    /** The value displayed in the search field. */
    searchValue?: string;
    /** additional props for the search field */
    searchFieldProps?: Partial<SearchFieldProps>;
    /** The current status of the picker. */
    status?: BaseLoadingStatus;
    /** The value displayed in the select. Should be the currently selected value. */
    displayedValue: string;
    /** The scope the component is used in. Will be used for data-attributes */
    scope?: string;
    /** If Search bar is visible, will display it in full width. */
    isSearchFullWidth?: boolean;
    /** Callback triggered when the input is clicked. */
    onInputClick?(): void;
    /** Selector function to select the entity field to use as choice react key. */
    choiceKeySelector?(item: I): React.Key;
    /** Selector function to set an item as selected. Should return a boolean. */
    choiceSelectedSelector?(item: I): boolean;
    /** Selector function to format each choice. Should return a string or another component. */
    choiceDisplayedValueSelector?(item: I): string | JSX.Element;
    /** Callback triggered when the dropdown should close. */
    onDropdownClose?(): void;
    /** Callback triggered when the search value should change. */
    onChangeSearchValue?(value: string): void;
    /** Callback triggered when a value is selected. */
    onChange?(value?: I): void;
    /** Callback triggered when the retry button is clicked when the error state is displayed. */
    onRetry?(): void;
    /** custom classname */
    className?: string;
    /** Ref to forward to the select */
    selectRef?: Ref<HTMLDivElement>;
}

/**
 * Generic component for the common pattern of:
 * * Listing entities
 * * Having a search field to search for specific entities
 * * Having loading / error / empty states
 *
 * This component is stateless and can be fully controlled from a parent component.
 * However, it comes with a `useGenericEntityPicker` hook that  a built in reducer and
 * state management.
 *
 * As entities all have different interfaces, use the "Selector" suffixed props to
 * customize the component.
 * If a selector prop is not defined and the choices are strings, their value will be used by default.
 *
 * Furthermore, you can easily set the expected entity interface using typescript as such:
 *      <GenericEntityPicker<User>
 *           label="User"
 *           choiceDisplayedValueSelector={(user) => (
 *              <UserBlock
 *                   name={getUserFullName(user, 'fr')}
 *                   avatarProps={{ image: getUserProfilePictureUrl(user as User), alt: '' }}
 *               />
 *           )}
 *           choiceKeySelector={(user) => user.id}
 *       />
 *
 * See storybook for more examples:
 * packages/lumx/components/GenericEntityPicker/GenericEntityPicker.stories.tsx
 * @family Pickers
 */
export const GenericEntityPicker = <I,>({
    choices = [],
    searchValue = '',
    status = BaseLoadingStatus.idle,
    isOpen,
    displayedValue,
    scope = 'entity-picker',
    choiceSelectedSelector,
    choiceDisplayedValueSelector,
    choiceKeySelector,
    isSearchFullWidth,
    onChange,
    onChangeSearchValue,
    onInputClick,
    onDropdownClose,
    onRetry,
    onClear,
    searchFieldProps = {},
    className,
    onInfiniteScroll,
    selectRef,
    ...selectProps
}: GenericEntityPickerProps<I>) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);
    const { block, element } = useClassnames(CLASSNAME);

    /** On select clear */
    const handleClear = onClear
        ? (evt: React.SyntheticEvent, currentValue: string) => {
              evt.stopPropagation();
              onClear(evt, currentValue);
          }
        : undefined;

    /** Action when the search is cleared. */
    const handleClearSearch = onChangeSearchValue ? () => onChangeSearchValue('') : undefined;

    /** Action when a choice is selected. */
    const handleSelect = onChange
        ? (selectedValue: I) => () => {
              onChange(selectedValue);
          }
        : undefined;

    return (
        <Select
            ref={selectRef}
            className={block([className])}
            // Set closeOnClick to false to allow clicking on the search field.
            closeOnClick={false}
            isOpen={isOpen}
            value={displayedValue}
            onClear={handleClear}
            clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
            onInputClick={onInputClick}
            onDropdownClose={onDropdownClose}
            {...get({ element: 'select' })}
            {...selectProps}
        >
            <List {...get({ element: 'option-list' })}>
                {onChangeSearchValue && (
                    <>
                        <ListSubheader>
                            <SearchField
                                label={translateKey(GLOBAL.SEARCH)}
                                value={searchValue}
                                onChange={onChangeSearchValue}
                                onSearch={onChangeSearchValue}
                                textFieldProps={{
                                    style: { width: '100%' },
                                    ...get({ element: 'input', action: 'search' }),
                                }}
                                wrapperProps={{
                                    className: element('search-field', {
                                        'full-width': Boolean(isSearchFullWidth),
                                    }),
                                }}
                                {...searchFieldProps}
                            />
                        </ListSubheader>
                        <ListDivider />
                    </>
                )}
                {/* Display the list only when idle or loadingMore data */}
                {includes([BaseLoadingStatus.idle, BaseLoadingStatus.loadingMore], status) &&
                    choices.length > 0 &&
                    choices.map((choice, index) => (
                        <ListItem
                            isSelected={
                                typeof choice === 'string' && !choiceSelectedSelector
                                    ? displayedValue === choice
                                    : choiceSelectedSelector?.(choice)
                            }
                            key={typeof choice === 'string' ? choice : choiceKeySelector?.(choice)}
                            onItemSelected={handleSelect?.(choice)}
                            size={Size.tiny}
                            {...get({
                                element: 'options-list-item',
                                position: index + 1,
                            })}
                        >
                            {typeof choice === 'string' && !choiceDisplayedValueSelector
                                ? choice
                                : choiceDisplayedValueSelector?.(choice)}
                        </ListItem>
                    ))}

                {/* Show skeletons on all loading states  */}
                {includes(
                    [BaseLoadingStatus.debouncing, BaseLoadingStatus.loading, BaseLoadingStatus.loadingMore],
                    status,
                ) && (
                    <GenericEntityPickerSkeletons
                        // Only show one skeleton on loading more, three on initial loading
                        number={status === BaseLoadingStatus.loadingMore ? 1 : 3}
                    />
                )}

                {/* Error state */}
                {status === BaseLoadingStatus.error && (
                    <ListItem key={0} size={Size.tiny}>
                        <ServiceNotAvailableState
                            onRetry={onRetry}
                            buttonProps={get({ element: 'button', action: 'retry' })}
                        />
                    </ListItem>
                )}
                {/* If no result */}
                {includes([BaseLoadingStatus.idle, BaseLoadingStatus.initial], status) && choices.length === 0 && (
                    <ListItem key={0} size={Size.tiny}>
                        <NoResultsState
                            searchValue={searchValue}
                            onClear={searchValue ? handleClearSearch : undefined}
                        />
                    </ListItem>
                )}
                {includes([BaseLoadingStatus.idle], status) && choices.length > 0 && onInfiniteScroll && (
                    <InfiniteScroll callback={onInfiniteScroll} />
                )}
            </List>
        </Select>
    );
};

GenericEntityPicker.displayName = 'GenericEntityPicker';
