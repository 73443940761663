import { useMemo } from 'react';

import { block, element } from '../utils';

/**
 * Hook that retrieves several utilities for the classnames feature.
 * @param classname - base component clasname
 * @returns utility functions
 */
export const useClassnames = (classname: string) => {
    return useMemo(() => {
        return {
            /**
             * Returns the BEM class for the given block (and modifier if it is defined)
             *
             * Executing `block()` returns`CLASSNAME`
             *
             * Executing `block('modifier')` returns  `CLASSNAME CLASSNAME--modifier`
             *
             * Executing `block({ modifier1: true, modifier2: false })` returns `CLASSNAME CLASSNAME--modifier1`
             *
             * Executing `block(['className'])` returns `CLASSNAME className`
             *
             * @param modifier - string or Record<string, boolean> or list of css classes
             * @param additionalClasses - string | string[] - list of additional classes to be added
             * @returns string
             */
            block: block(classname),
            /**
             * Returns the BEM class for the given element (and modifier if it is defined)
             *
             * Executing `element('element')` returns `CLASSNAME__element`
             *
             * Executing `element('element', 'modifier')` returns `CLASSNAME__element CLASSNAME__element--modifier`
             *
             * Executing `element('element', { modifier1: true, modifier2: false })` returns `CLASSNAME__element CLASSNAME__element--modifier1`
             *
             * Executing `element('element', ['classname'])` returns `CLASSNAME__element clasname`
             *
             * @param modifier - string or Record<string, boolean> or list of css classes
             * @param additionalClasses - string | string[] - list of additional classes to be added
             * @returns string
             */
            element: element(classname),
        };
    }, [classname]);
};
