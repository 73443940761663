import React, { useEffect } from 'react';

import { useDispatch } from '@lumapps/redux/react';
import { actions } from '@lumapps/widget-base/ducks/slice';

import { ClientComputedContactFormProps } from '../../types';
import { ContactFormBlock } from '../ContactFormBlock';

export const ClientComputedContactForm: React.FC<ClientComputedContactFormProps> = ({
    properties = {},
    uuid,
    legacyWidgetId,
}) => {
    const dispatch = useDispatch();
    const { to, style } = properties;

    useEffect(() => {
        if (!to) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, properties, to, uuid]);

    return to ? (
        <ContactFormBlock theme={style?.content?.theme} to={to as string} legacyWidgetId={legacyWidgetId as string} />
    ) : null;
};
ClientComputedContactForm.displayName = 'ClientComputedContactForm';
