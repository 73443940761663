import React from 'react';

import { margin, margins, useClassnames } from '@lumapps/classnames';
import { Message, MessageProps, Size, SkeletonRectangle, Heading } from '@lumapps/lumx/react';

import { useCodeMirrorTextArea, UseCodeTextAreaOptions } from './useCodeMirrorTextArea';

export interface CodeTextAreaOptions extends UseCodeTextAreaOptions {
    /** optional className that will be used on the text area */
    className?: string;
    /** value for the text field */
    value?: string;
    /** title to be displayed for the field  */
    title?: string;
    /** optional message to be displayed between the title and the text area */
    message?: MessageProps;
    /** optional className to be displayed on this component's wrapper */
    wrapperClassName?: string;
    /** optional boolean to fold/unfold code mirror */
    isFolded?: boolean;
    /** optional boolean to if code mirror is read only */
    readOnly?: boolean;
}

const CLASSNAME = 'code-mirror-text-area';
/**
 * Text Area with syntax highlighting provided by Code Mirror. It needs to be used with the useThirdPartyLibrary hook
 * @param CodeTextAreaOptions
 * @returns CodeMirrorTextArea
 */
export const CodeMirrorTextArea: React.FC<CodeTextAreaOptions> = ({
    language,
    hasFinishedLoading,
    id,
    className,
    value = '',
    setValue,
    title,
    message = null,
    shouldApplyHighlightSyntax = false,
    wrapperClassName,
    isFolded,
    readOnly = false,
    editorSize,
    defaultIndentationLevel,
}) => {
    const { element, block } = useClassnames(CLASSNAME);
    const editorWrapperId = element(id);
    useCodeMirrorTextArea({
        language,
        hasFinishedLoading,
        id: editorWrapperId,
        setValue,
        shouldApplyHighlightSyntax,
        value,
        isFolded,
        readOnly,
        editorSize,
        defaultIndentationLevel,
    });

    return (
        <div className={block([wrapperClassName, editorWrapperId])}>
            {title ? (
                <Heading
                    as="h3"
                    typography="subtitle2"
                    className={margins([
                        { direction: 'top', size: 'huge' },
                        { direction: 'bottom', size: 'big' },
                    ])}
                >
                    {title}
                </Heading>
            ) : null}
            {message ? <Message {...message} className={margin('bottom', 'big')} /> : null}
            {hasFinishedLoading && shouldApplyHighlightSyntax && <div id={editorWrapperId} className={className} />}
            {!hasFinishedLoading || !shouldApplyHighlightSyntax ? <SkeletonRectangle height={Size.xxl} /> : null}
        </div>
    );
};
