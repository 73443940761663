/* istanbul ignore file */
import { TRAININGS } from '@lumapps/learning-trainings/keys';
import { PLAYLIST } from '@lumapps/play-playlists/keys';
import { GenericDocumentResult } from '@lumapps/search/types';
import { GLOBAL, TranslationAPI } from '@lumapps/translations';

import { SEARCH_RESULT_CLASSNAME } from './constants';
import { buildCommentMimeType } from './utils/buildCommentMimeType';
import { isCommentSearchResultMimeType } from './utils/isCommentSearchResultMimeType';

const getBaseClassName = (namespace: string) => {
    return `${SEARCH_RESULT_CLASSNAME}-${namespace}`;
};

const ICON = {
    AUDIO: 'volume-high',
    FILE: 'file',
    FOLDER: 'folder',
    GOOGLE_CALENDAR: 'calendar',
    GOOGLE_DOCS: 'file-document-box',
    GOOGLE_DRAWING: 'drawing-box',
    GOOGLE_DRIVE: 'google-drive',
    GOOGLE_FORMS: 'format-list-checks',
    GOOGLE_GROUPS: 'account-multiple',
    GOOGLE_KEEP: 'google-keep',
    GOOGLE_MAIL: 'email',
    MAIL: 'email',
    GOOGLE_SHEETS: 'google-spreadsheet',
    GOOGLE_SITES: 'web-box',
    GOOGLE_SLIDES: 'file-presentation-box',
    IMAGE: 'image',
    MICROSOFT_EXCEL: 'file-excel-box',
    MICROSOFT_POWERPOINT: 'file-powerpoint-box',
    MICROSOFT_WORD: 'file-word-box',
    PDF: 'file-pdf-box',
    VIDEO: 'movie',
    PLAY: 'play',
    PLAYLIST: 'play-box-multiple',
    NEWSPAPER: 'newspaper',
    POST: 'message-text-outline',
    DIRECTORY: 'star',
    COMMUNITY: 'google-circles-extended',
    TRAINING_COURSE: 'school',
    LEARNING_PATH: 'collage',
    COMMENT: 'comment-outline',
};

/** Types that matches native LumApps entities. */
export const NATIVE_TYPES = {
    TRAINING_COURSE: 'training_course',
    LEARNING_PATH: 'learning_path',
    VIDEO_PLAY: 'video-play',
    VIDEO_PLAYLIST: 'video-playlist',
    EVENT: 'event',
    ARTICLE: 'article',
    POST: 'post',
    DIRECTORY: 'directory',
    COMMUNITY: 'community',
    COMMENT_POST: buildCommentMimeType('post'),
    COMMENT_ARTICLE: buildCommentMimeType('article'),
    COMMENT_CONTENT: buildCommentMimeType('content'),
    COMMENT_EVENT: buildCommentMimeType('event'),
} as const;

// eslint-disable-next-line default-param-last
const getIconFromType = (type?: string | null, fallback?: string): string => {
    const fallbackIcon = fallback ? getIconFromType(fallback) : ICON.FILE;

    if (!type) {
        return fallbackIcon;
    }

    const lowercaseType = type.toLowerCase();

    /** Display the same icon for all `comment` type search results */
    if (isCommentSearchResultMimeType(lowercaseType)) {
        return ICON.COMMENT;
    }

    switch (type.toLowerCase()) {
        case 'application/vnd.google-apps.audio':
        case 'audio/mp3':
        case 'audio/mpeg':
        case 'mp3':
        case 'mid':
        case 'wav':
            return ICON.AUDIO;

        case 'application/vnd.google-apps.file':
        case 'application/x-rar':
        case 'application/zip':
        case 'zip':
        case 'rar':
        case 'txt':
            return ICON.FILE;

        case 'application/vnd.google-apps.folder':
        case 'onedrive_folder':
            return ICON.FOLDER;

        case 'application/vnd.google-apps.document':
            return ICON.GOOGLE_DOCS;

        case 'application/vnd.google-apps.groups':
        case 'groups':
            return ICON.GOOGLE_GROUPS;

        case 'application/vnd.google-apps.drive':
        case 'drive':
            return ICON.GOOGLE_DRIVE;

        case 'application/vnd.google-apps.drawing':
            return ICON.GOOGLE_DRAWING;

        case 'application/vnd.google-apps.form':
            return ICON.GOOGLE_FORMS;

        case 'application/vnd.google-apps.spreadsheet':
        case 'spreadsheet':
        case 'odt':
        case 'rtf':
            return ICON.GOOGLE_SHEETS;

        case 'application/vnd.google-apps.mail':
        case 'mail':
            return ICON.GOOGLE_MAIL;

        case 'keep':
            return ICON.GOOGLE_KEEP;

        case 'application/vnd.google-apps.sites':
        case 'application/vnd.google-apps.site':
        case 'application/google-sites-page':
        case 'sites':
            return ICON.GOOGLE_SITES;

        case 'application/vnd.google-apps.presentation':
        case 'presentation':
            return ICON.GOOGLE_SLIDES;

        case 'application/vnd.google-apps.photo':
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/webp':
        case 'image/gif':
        case 'image/png':
        case 'image/x-photoshop':
        case 'bmp':
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            return ICON.IMAGE;

        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-excel.addin.macroEnabled.12':
        case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
        case 'application/vnd.ms-excel.template.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
        case 'application/vnd.ms-excel.sheet.macroenabled.12':
        case 'application/vnd.ms-excel.template.macroenabled.12':
        case 'application/vnd.ms-excel.addin.macroenabled.12':
        case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
        case 'xls':
        case 'xlsx':
            return ICON.MICROSOFT_EXCEL;

        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
        case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
        case 'application/vnd.ms-powerpoint.slide.macroEnabled.12':
        case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
        case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.presentationml.slide':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
        case 'application/vnd.ms-powerpoint.addin.macroenabled.12':
        case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
        case 'application/vnd.ms-powerpoint.template.macroenabled.12':
        case 'application/vnd.ms-powerpoint.slideshow.macroenabled.12':
        case 'ppt':
        case 'pptx':
            return ICON.MICROSOFT_POWERPOINT;

        case 'application/msword':
        case 'application/vnd.ms-word.document.macroEnabled.12':
        case 'application/vnd.ms-word.template.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'application/vnd.ms-word.document.macroenabled.12':
        case 'application/vnd.ms-word.template.macroenabled.12':
        case 'doc':
        case 'docx':
            return ICON.MICROSOFT_WORD;

        case 'application/pdf':
        case 'pdf':
            return ICON.PDF;

        case 'application/vnd.google-apps.video':
        case 'video/avi':
        case 'video/mov':
        case 'video/mp4':
        case 'video/mpeg':
        case 'mp4':
        case 'mov':
        case 'avi':
        case 'mpeg':
            return ICON.VIDEO;

        case NATIVE_TYPES.TRAINING_COURSE:
            return ICON.TRAINING_COURSE;
        case NATIVE_TYPES.LEARNING_PATH:
            return ICON.LEARNING_PATH;
        case NATIVE_TYPES.VIDEO_PLAY:
            return ICON.PLAY;
        case NATIVE_TYPES.EVENT:
            return ICON.GOOGLE_CALENDAR;
        case NATIVE_TYPES.ARTICLE:
            return ICON.NEWSPAPER;
        case NATIVE_TYPES.VIDEO_PLAYLIST:
            return ICON.PLAYLIST;
        case NATIVE_TYPES.POST:
            return ICON.POST;
        case NATIVE_TYPES.DIRECTORY:
            return ICON.DIRECTORY;
        case NATIVE_TYPES.COMMENT_POST:
        case NATIVE_TYPES.COMMENT_ARTICLE:
        case NATIVE_TYPES.COMMENT_CONTENT:
        case NATIVE_TYPES.COMMENT_EVENT:
            return ICON.COMMUNITY;
        case NATIVE_TYPES.COMMUNITY:
            return ICON.COMMUNITY;
        default:
            return fallbackIcon;
    }
};

/**
 * Get a translation key from a content type.
 * Only for a select list of types.
 * If the given type doesn't have a match, an empty string will be returned.
 */
const getResourceLabelFromType = (type: string) => {
    switch (type.toLowerCase()) {
        case NATIVE_TYPES.VIDEO_PLAY:
            return GLOBAL.VIDEO;
        case NATIVE_TYPES.VIDEO_PLAYLIST:
            return PLAYLIST.PLAYLIST;
        case NATIVE_TYPES.TRAINING_COURSE:
            return TRAININGS.TRAINING_COURSE;
        case NATIVE_TYPES.LEARNING_PATH:
            return TRAININGS.LEARNING_PATH;
        default:
            return '';
    }
};

/**
 * Build a custom metadata array from a generic document object.
 * These metadata will be displayed beneath the title.
 */
const buildGenericDocumentCustomMetadata = (
    genericDocument: GenericDocumentResult,
    translate: TranslationAPI['translate'],
) => {
    const customMetadata = [];
    const resourceTypeFromMimetype = translate(getResourceLabelFromType(genericDocument.mimeType));
    if (resourceTypeFromMimetype) {
        customMetadata.push(resourceTypeFromMimetype);
    }
    if (genericDocument.metadata) {
        customMetadata.push(...genericDocument.metadata);
    }

    return customMetadata;
};

export {
    getIconFromType,
    getBaseClassName,
    ICON,
    getResourceLabelFromType as getLabelFromType,
    buildGenericDocumentCustomMetadata,
};
