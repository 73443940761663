/* eslint-disable react/forbid-elements */
/* eslint-disable lumapps/no-ds-classes */
/* eslint-disable lumapps/no-manual-bems */
/* istanbul ignore file */
/* eslint-disable react/no-danger */
import React, { useMemo, useRef, useEffect, useState } from 'react';

import keyBy from 'lodash/keyBy';

import { classnames } from '@lumapps/classnames';
import { isFeatureEnabled } from '@lumapps/features';
import { mdiChevronDown, mdiChevronUp, mdiTranslate } from '@lumapps/lumx/icons';
import { Icon, Theme, Size, Link, ProgressLinear } from '@lumapps/lumx/react';
import { POST_WREX_MARKS, POST_WREX_ELEMENTS } from '@lumapps/posts/constants';
import { Post } from '@lumapps/posts/types';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ContentView } from '@lumapps/wrex/components/ContentView';
import { fromMarkdown } from '@lumapps/wrex/serialization/markdown/fromMarkdown';

import './index.scss';

const UNDETAILED_POST_CONTENT_LINE_HEIGHT = 20;
const DEFAULT_LINE_LIMIT = 10;

const CLASSNAME = 'post-block-content';

interface PostContentProps {
    /** Corresponds to data-id. */
    id: string;
    /** The post you want to display, full object. */
    post: Post;
    /** Whether or not the post is detailed. */
    detailsOpen: boolean;
    /** Whether or not the post has attachments. */
    hasAttachments: boolean;
    /** Whether or not the post has inline images. */
    hasInlineImages: boolean;
    /** Whether or not the post should display attachments when un-detailed. */
    shouldDisplayAttachments: boolean;
    /** Lumx Theme. */
    theme: Theme;
    /** The background color of the post block. */
    backgroundColor: string;
    /** The current lang of the user. */
    lang: string;
    /** The number of line to display when the post is in un-detailed. */
    linesLimit?: number;
    /** The function changing the state of the post. */
    setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    /** Is detail view open. */
    isOnDetailPostView: boolean;
    /** The function called when the user clicks on the translate button. */
    onTranslate?(postId: string, content: string): void;
}

/**
 * Display the title wrapped in a link to the post.
 */
export const PostContent: React.FC<PostContentProps> = ({
    id: dataId,
    post,
    detailsOpen,
    setDetailsOpen,
    hasAttachments,
    hasInlineImages,
    onTranslate,
    lang,
    theme,
    isOnDetailPostView,
    backgroundColor,
    linesLimit = DEFAULT_LINE_LIMIT,
    shouldDisplayAttachments,
}) => {
    const { translateKey, translateObject } = useTranslate();
    const mdString = translateObject(post.content)?.trim() || '';
    const isTranslationFeatureEnabled = useSelector(isFeatureEnabled('translation'));
    const isEndUserTranslationEnabled = useSelector(isFeatureEnabled('translation-end-user'));
    const nodes = useMemo(
        () => fromMarkdown(mdString, { mentionsDetails: post.mentionsDetails }),
        [mdString, post.mentionsDetails],
    );
    const contentRef = useRef<HTMLDivElement>(null);
    const [hasDetails, setHasDetails] = useState(true);
    const toggleDetails = () => {
        setDetailsOpen((oldVal) => !oldVal);
    };
    const [translationDisplayed, setTranslationDisplayed] = useState(false);

    const mentionIndex = React.useMemo(() => keyBy((post as any).mentionsDetails || [], 'id'), [post]);

    useEffect(() => {
        if (
            (contentRef.current &&
                contentRef.current.clientHeight >
                    UNDETAILED_POST_CONTENT_LINE_HEIGHT * (linesLimit || DEFAULT_LINE_LIMIT)) ||
            hasInlineImages ||
            (hasAttachments && !shouldDisplayAttachments)
        ) {
            setDetailsOpen(false);
        } else {
            setDetailsOpen(true);
            setHasDetails(false);
        }
    }, [hasAttachments, hasInlineImages, linesLimit, setDetailsOpen, setHasDetails, shouldDisplayAttachments]);

    return (
        <>
            <div
                ref={contentRef}
                className={classnames(`${CLASSNAME}__content`, {
                    [`${CLASSNAME}__content--preview`]: !detailsOpen,
                })}
                data-id={dataId}
                style={{
                    maxHeight: detailsOpen
                        ? undefined
                        : UNDETAILED_POST_CONTENT_LINE_HEIGHT * (linesLimit || DEFAULT_LINE_LIMIT),
                }}
                dir="auto"
            >
                <ContentView
                    theme={theme}
                    content={nodes}
                    elements={POST_WREX_ELEMENTS}
                    marks={POST_WREX_MARKS}
                    mentionIndex={mentionIndex}
                />
            </div>
            {(isEndUserTranslationEnabled || isTranslationFeatureEnabled) &&
                mdString &&
                onTranslate &&
                !translationDisplayed &&
                detailsOpen && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                        className={classnames(`${CLASSNAME}__translate-btn`, 'lumx-spacing-margin-top-regular')}
                        leftIcon={mdiTranslate}
                        color={theme === Theme.light ? 'primary' : 'light'}
                        onClick={() => {
                            setTranslationDisplayed(true);
                            onTranslate(post.uid, contentRef.current?.innerHTML || '');
                        }}
                    >
                        {translateKey(GLOBAL.SEE_TRANSLATION)}
                    </Link>
                )}
            {translationDisplayed &&
                (post.content[`google_${lang}`] ? (
                    <div
                        className={classnames(`${CLASSNAME}__translation-wrapper`, {
                            'lumx-color-font-white-N': theme === Theme.dark,
                        })}
                        dangerouslySetInnerHTML={{
                            __html: post.content[`google_${lang}`],
                        }}
                    />
                ) : (
                    <ProgressLinear theme={theme} />
                ))}
            {hasDetails && !isOnDetailPostView && (
                <div
                    className={classnames(`${CLASSNAME}__see-more-btn-wrapper`, {
                        [`${CLASSNAME}__see-more-btn-wrapper--preview`]: !detailsOpen,
                    })}
                >
                    <div
                        className={classnames({
                            [`${CLASSNAME}__see-more-btn-wrapper--preview-before`]: !detailsOpen,
                        })}
                        style={{
                            background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${backgroundColor} 100%)`,
                        }}
                    />
                    <button
                        type="button"
                        className={classnames('lumx-link', `lumx-link--theme-${theme}`, `${CLASSNAME}__see-more-btn`, {
                            'lumx-link--color-light': theme === Theme.dark,
                            'lumx-link--color-secondary': theme === Theme.light,
                        })}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDetails();
                        }}
                    >
                        <Icon size={Size.xs} icon={detailsOpen ? mdiChevronUp : mdiChevronDown} />
                        <span>{` ${translateKey(detailsOpen ? GLOBAL.SEE_LESS : GLOBAL.SEE_MORE)}`}</span>
                    </button>
                </div>
            )}
        </>
    );
};
