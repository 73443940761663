import React, { RefObject } from 'react';

import { classnames, margin, padding } from '@lumapps/classnames';
import { isInDesignerMode as isInDesignerModeSelector } from '@lumapps/contents/ducks/selectors';
import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import { Kind, Message, Size, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useDimensions } from '@lumapps/responsive';
import { useTranslate } from '@lumapps/translations';
import { useHeaderFixedHeight } from '@lumapps/utils/hooks/useHeaderHeight';
import { ToolbarItem } from '@lumapps/wrex/components/Toolbar/ToolbarItem';
import { WREX_CORE } from '@lumapps/wrex/keys';

import { STRUCTURED_CONTENT } from '../../../keys';
import { STRUCTURED_CONTENT_TOOLBAR_BREAKPOINTS } from '../constants';
import { useStructuredContentToolbar } from './useStructuredContentToolbar';

const CLASSNAME = 'structured-content-toolbar';

interface StructuredContentToolbarProps {
    parentDialogRef?: RefObject<HTMLDivElement>;
    theme?: Theme;
    isReadOnly?: boolean;
}

export const StructuredContentToolbar: React.FC<StructuredContentToolbarProps> = ({
    parentDialogRef,
    theme,
    isReadOnly,
}) => {
    const { currentBreakpoint, ref: dimensionRef } = useDimensions({
        breakpoints: STRUCTURED_CONTENT_TOOLBAR_BREAKPOINTS,
    });

    const {
        GoogleUploadDialog,
        toolbarButtons,
        insertFileHiddenInput,
        insertImageGalleryHiddenInput,
        insertImageHiddenInput,
        uploadImageDialog,
        insertEmojiPicker,
        insertLinkDialog,
        insertEmbeddedMapDialog,
        insertImageGalleryDialog,
        insertLinkVideoDialog,
        videoUploadDialog,
        videoUploadInput,
        insertPlayVideoDialog,
        oneDrivePicker,
    } = useStructuredContentToolbar({ parentDialogRef, theme, isReadOnly, currentBreakpoint });

    const { translateKey } = useTranslate();

    const isInDesignerMode = useSelector(isInDesignerModeSelector);
    const headerHeight = useHeaderFixedHeight();

    const toolbarTopPositionOverride = isInDesignerMode ? headerHeight + 16 : undefined;

    return (
        <>
            <div ref={dimensionRef} />

            <div
                className={classnames(CLASSNAME, padding('all', 'regular'))}
                style={{
                    top: toolbarTopPositionOverride,
                }}
            >
                <Menubar ariaLabel={translateKey(WREX_CORE.EDITOR_TOOLBAR_LABEL)} size={Size.s}>
                    {toolbarButtons.map((item: any) => {
                        return <ToolbarItem {...item} key={`toolbar-item-${item.itemKey}`} />;
                    })}
                </Menubar>
                {oneDrivePicker}
                {insertFileHiddenInput}
                {insertImageHiddenInput}
                {uploadImageDialog}
                {insertImageGalleryHiddenInput}
                {insertLinkDialog}
                {insertEmbeddedMapDialog}
                {insertLinkVideoDialog}
                {videoUploadDialog}
                {videoUploadInput}
                {insertPlayVideoDialog}
                {insertImageGalleryDialog}
                {insertEmojiPicker}
                {GoogleUploadDialog}
            </div>

            {currentBreakpoint === 'too-small' && (
                <Message kind={Kind.warning} hasBackground className={margin('top', Size.big)}>
                    {translateKey(STRUCTURED_CONTENT.WARNING_MESSAGE_SMALL_DISPLAY)}
                </Message>
            )}
        </>
    );
};

StructuredContentToolbar.displayName = 'StructuredContentToolbar';
