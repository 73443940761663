/* eslint-disable no-param-reassign */
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { BOOKMARKS_ICONS } from '../constants';
import { BookmarkType } from '../types';
import { BookmarksSliceProps } from './types';

const initialState: BookmarksSliceProps = {
    items: undefined,
};

const { actions, reducer } = createSlice({
    domain: 'bookmarks',
    initialState,
    reducers: {
        setBookmarks: (state: BookmarksSliceProps, action: PayloadAction<BookmarkType[] | undefined>) => {
            state.items = (action?.payload ?? []).map((item) => {
                const icon = item.icon.match(/^https?:\/\//)
                    ? item.icon
                    : BOOKMARKS_ICONS[item.icon as keyof typeof BOOKMARKS_ICONS];

                return {
                    ...item,
                    icon,
                };
            });
        },
    },
});

export { actions, reducer };
