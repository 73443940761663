import React from 'react';

import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { Icon, Text } from '@lumapps/lumx/react';

import './index.scss';

/**
 * Type when the key displayed uses an icon.
 * If so, the label is required.
 */
type IconKey = {
    /** accessible label of the keyboard key */
    label: string;
    /** icon to display as keyboard key */
    icon: string;
    keyboardKey?: never;
};

/**
 * Type when the the key displayed uses an string.
 * If so, the label is optional and can be used to override the string read to
 * screen readers.
 */
type StringKey = {
    /**
     * accessible label of the keyboard key.
     * Can be used to override the given string for screen readers.
     */
    label?: string;
    /** icon to display as keyboard key */
    keyboardKey: string;
    icon?: never;
};

type KeyboardKeyType = IconKey | StringKey;

const CLASSNAME = 'keyboard-key';

export type KeyboardKeyProps = KeyboardKeyType & {
    className?: string;
};

export const KeyboardKey = ({ className, label, icon, keyboardKey }: KeyboardKeyProps) => {
    const { block } = useClassnames(CLASSNAME);

    return (
        <kbd key={label} className={block(undefined, className)}>
            {label && (
                <Text as="span" className={visuallyHidden()}>
                    {label}
                </Text>
            )}
            {icon ? (
                <Icon icon={icon} />
            ) : (
                <Text as="span" aria-hidden={Boolean(label)}>
                    {keyboardKey}
                </Text>
            )}
        </kbd>
    );
};
