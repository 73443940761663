(function IIFE() {
    'use strict';

    /////////////////////////////

    var _RESULT_TYPE_ARTICLE = 'article';
    var _RESULT_TYPE_TICKET = 'ticket';

    /**
     * An object holding various options related to Zendesk.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    var ZendeskConstant = {
        /**
         * An enum of list identifiers used in the application.
         *
         * @type {Object}
         */
        LIST_KEY: {
            TAGS: 'zendesk-tags',
        },

        /**
         * The value of the type 'article'.
         *
         * @type {string}
         */
        RESULT_TYPE_ARTICLE: _RESULT_TYPE_ARTICLE,

        /**
         * The value of the type 'ticket'.
         *
         * @type {string}
         */
        RESULT_TYPE_TICKET: _RESULT_TYPE_TICKET,

        /**
         * An enum of the types of Zendesk results.
         *
         * @type {Array}
         */
        RESULT_TYPES: [
            {
                key: 'FRONT.ZENDESK.TYPE.ARTICLE',
                value: _RESULT_TYPE_ARTICLE,
            },
            {
                key: 'FRONT.ZENDESK.TYPE.TICKET',
                value: _RESULT_TYPE_TICKET,
            },
        ],

        /**
         * An enum of the types of Zendesk ticket statuses.
         *
         * @type {Array}
         */
        TICKET_STATUSES: [
            {
                key: 'FRONT.ZENDESK.TICKET_STATUS.CLOSED',
                value: 'closed',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_STATUS.HOLD',
                value: 'hold',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_STATUS.NEW',
                value: 'new',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_STATUS.OPEN',
                value: 'open',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_STATUS.PENDING',
                value: 'pending',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_STATUS.SOLVED',
                value: 'solved',
            },
        ],

        /**
         * An enum of the types of Zendesk tickets.
         *
         * @type {Array}
         */
        TICKET_TYPES: [
            {
                key: 'FRONT.ZENDESK.TICKET_TYPE.INCIDENT',
                value: 'incident',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_TYPE.PROBLEM',
                value: 'problem',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_TYPE.QUESTION',
                value: 'question',
            },
            {
                key: 'FRONT.ZENDESK.TICKET_TYPE.TASK',
                value: 'task',
            },
        ],
    };

    /////////////////////////////

    angular.module('Constants').constant('ZendeskConstant', ZendeskConstant);
})();
