import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { getContent } from '@lumapps/contents/ducks/thunks/getContent';
import { getContentMetadata } from '@lumapps/contents/ducks/thunks/getContentMetadata';
import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { Dispatch, FrontOfficeStore } from '@lumapps/redux/types';

import { ClientComputedMetadata } from './ClientComputedMetadata';

export const mapStateToProps = (state: FrontOfficeStore) => ({
    currentContent: contentSelector(state),
    customer: customerSelector(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchMetadata: (currentContentId: string) => dispatch(getContentMetadata(currentContentId)),
    fetchLikedStatus: (currentContentId: string) =>
        dispatch(
            getContent({ uid: currentContentId, fields: 'id,liked' }, 'liked', true, { 'x-lumapps-analytics': 'off' }),
        ),
});

const ConnectedClientComputedMetadataMetadata = connect(mapStateToProps, mapDispatchToProps)(ClientComputedMetadata);

export { ConnectedClientComputedMetadataMetadata as ClientComputedMetadata };
