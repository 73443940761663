import { useFetchWithStatus, UseFetchPropsReturn } from '@lumapps/utils/hooks/useFetchWithStatus';

import { get, GetDirectoryParams } from '../api';
import { Directory } from '../types';

export type UseDirectory = Omit<UseFetchPropsReturn<Directory>, 'response'> & { directory?: Directory };

export interface UseDirectoryOptions {
    params?: GetDirectoryParams;
    shouldFetch?: boolean;
}

/**
 * Hook in order to retrieve a single directory
 *
 * @returns UseDirectory
 */
export const useDirectory = ({ params, shouldFetch }: UseDirectoryOptions): UseDirectory => {
    const { fetch, status, response, reset, ...rest } = useFetchWithStatus<Directory>({
        onFetch: get,
        fetchOnMount: shouldFetch,
        initialFetchParams: {
            params,
        },
    });

    return {
        ...rest,
        reset,
        fetch,
        status,
        directory: response,
    };
};
