(function IIFE() {
    'use strict';

    /////////////////////////////

    function ModeratePostDialogController($rootScope, $scope, $state, $stateParams, Community, LxDialogService, Post) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The handlers to call after a moderation process.
         *
         * @type {Object}
         * @constant
         */
        var _MODERATION_HANDLERS = {};

        /**
         * Whether the post has to be deteled, or if we have to be redirected to the community
         * on dialog close.
         *
         * @type {boolean}
         */
        var _deletePostOnDialogClose = false;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Whether a moderate call is in progress or not.
         *
         * @type {boolean}
         */
        vm.isModerating = false;

        /**
         * Defines if the moderation is done or not.
         *
         * @type {boolean}
         */
        vm.isModerated = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Post = Post;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * The function to call after a moderate call has been made with the decline action.
         *
         * @param {Object} response The response of the call.
         */
        function declineHandler(response) {
            if (angular.isUndefinedOrEmpty(response.report)) {
                delete vm.post.report;
            }

            delete vm.post.reportStatus;
            vm.post.alreadyReported = false;

            $rootScope.$broadcast('post-moderated', vm.post.uid, false);

            LxDialogService.close(vm.dialogId);
        }

        /**
         * The function to call after a moderate call has been made with the delete action.
         *
         * @param {Object} response The response of the call.
         */
        function deleteHandler() {
            _deletePostOnDialogClose = true;
            $rootScope.$broadcast('post-moderated', vm.post.uid, true);
            vm.isModerated = true;
        }

        /**
         * Send the moderate call.
         *
         * @param {string} action The moderation action to set as parameter.
         */
        function moderatePost(action) {
            vm.isModerating = true;

            var params = {
                action: action,
                uid: vm.post.uid || vm.post.id,
            };

            Post.moderatePost(params).then(function onModerateSuccess(response) {
                if (response.postId !== vm.post.uid) {
                    return;
                }

                if (angular.isFunction(_MODERATION_HANDLERS[action])) {
                    _MODERATION_HANDLERS[action](response);
                }
            }).catch(function onModerateError() {
                LxDialogService.close(vm.dialogId);
            }).finally(function onModerateFinally() {
                vm.isModerating = false;
            });
        }

        /////////////////////////////

        vm.declineHandler = declineHandler;
        vm.deleteHandler = deleteHandler;
        vm.moderatePost = moderatePost;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the dialog closes, remove the post from the list, or redirect to community if
         * we are on post details page.
         *
         * @param {evt}    evt      The event triggering this method.
         * @param {string} dialogId The dialog beeing closed.
         */
        $scope.$on('lx-dialog__close-end', function onDialogCloseEnd(evt, dialogId) {
            if (vm.dialogId !== dialogId || !_deletePostOnDialogClose) {
                return;
            }

            var isPostDetails = ($state.current.name === 'app.front.community') &&
                angular.isDefinedAndFilled($stateParams.identifier);

            if (isPostDetails) {
                Community.goTo(vm.post.parentContentDetails);
            } else {
                Post.del(vm.post.uid, function onPostDeleted() {
                    $rootScope.$broadcast('community__post-deleted', vm.post.uid);
                }, undefined, vm.postListKey);
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Populate the handler object based on the moderation action possibilities.
            angular.forEach(Post.MODERATION_ACTIONS, function forEachReportAction(action) {
                var handler = vm[action.toLowerCase() + 'Handler'];

                if (angular.isFunction(handler)) {
                    _MODERATION_HANDLERS[action] = handler;
                }
            });
        }

        init();
    }

    /**
     * A dialog to handle post moderation.
     *
     * @param {string} dialogId    The identifier of the dialog.
     * @param {string} postListKey The list key of the post list this dialog refers to.
     */

    function ModeratePostDialogDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ModeratePostDialogController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                dialogId: '@lsDialogId',
                post: '<lsPost',
                postListKey: '@lsPostListKey',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/communities/post/views/partials/moderate-post-dialog.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsModeratePostDialog', ModeratePostDialogDirective);
})();
