import { TranslatableObject } from '@lumapps/translations';
import { UserReference } from '@lumapps/widget-base/types';
import { DITA, FeaturedImageMedia, SlateStructuredContent } from '@lumapps/wrex/serialization/dita/types';
import type { Wrex } from '@lumapps/wrex/types';

export enum STRUCTURED_CONTENT_FORMAT {
    RICH_TEXT = 'lumapps',
    DITA = 'dita',
}

/**
 * @see https://lumapps-api.stoplight.io/docs/lumapps-private-api/8f8c600ab3d3e-structured-content
 */
export interface StructuredContent {
    /** Identifier. */
    id: string;
    /** Title. */
    title: TranslatableObject;
    /** Intro. */
    intro: TranslatableObject;
    /** Featured image URL. */
    featuredImage: TranslatableObject<FeaturedImageMedia>;
    /** DITA content. */
    dita: TranslatableObject<DITA>;
    /** RichText content */
    richText: TranslatableObject<SlateStructuredContent>;
    /** Author. */
    authorId: UserReference;
    /** The structured content version */
    version: string;
}

export interface EditedFeaturedImage extends FeaturedImageMedia {
    /** Featured image file present when the image was not yet uploaded to the backend. */
    file?: File | null;
}

export interface GetPreparedStructuredContentProps {
    featuredImage?: EditedFeaturedImage;
    content: Wrex.Nodes;
    title: string;
}
