import React from 'react';

import range from 'lodash/range';

import { padding } from '@lumapps/classnames';
import { FlexBox, Orientation, Size, SkeletonRectangle, SkeletonRectangleVariant } from '@lumapps/lumx/react';
import { getRandomValue } from '@lumapps/utils/array/getRandomValue';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

export const OptionsLoadingState: React.FC = () => {
    return (
        <FlexBox className={padding('all')} orientation={Orientation.vertical} gap={Size.regular}>
            {range(3).map((_, index) => (
                <SkeletonRectangle
                    key={generateUUID()}
                    variant={SkeletonRectangleVariant.pill}
                    width={getRandomValue([Size.xl, Size.xxl, Size.l], index.toString())}
                    height={Size.xs}
                />
            ))}
        </FlexBox>
    );
};
