import React, { ReactElement, ReactNode, useMemo } from 'react';

import { useOverflowTooltipLabel } from '@lumapps/utils/hooks/useOverflowTooltipLabel';

import { ItemVariant } from '../constants';

export interface UseTooltipLabelProps {
    /** Label content. */
    label: string | React.ReactNode;
    /** Variant of the navigation item to display text and/or icon (default = both) */
    variant?: ItemVariant;
}

const getLabelNodeText = (node: ReactNode): string => {
    if (typeof node === 'string') {
        return node;
    }

    if (typeof node === 'number') {
        return node.toString();
    }

    if (typeof node === 'object' && node && !Array.isArray(node)) {
        return getLabelNodeText((node as ReactElement).props.children);
    }

    return '';
};

export const useTooltipLabel = ({ label, variant }: UseTooltipLabelProps) => {
    const { labelRef, tooltipLabel: labelFromOverflow } = useOverflowTooltipLabel();

    const tooltipLabel: string = useMemo(() => {
        if (variant === ItemVariant.iconOnly) {
            return getLabelNodeText(label);
        }

        return labelFromOverflow ?? '';
    }, [labelFromOverflow, label, variant]);

    return { labelRef, tooltipLabel };
};
