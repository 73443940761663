export enum CERTIFICATE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=522436546
     */
    ISSUED = 'CERTIFICATE.ISSUED',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=522995953
     */
    EMPTY_TITLE = 'CERTIFICATE.EMPTY_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=522995987
     */
    EMPTY_DESCRIPTION = 'CERTIFICATE.EMPTY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=526786743
     */
    DOWNLOAD_SUCCESSFUL = 'CERTIFICATE.DOWNLOAD_SUCCESSFUL',
}
