import type { ServerListResponse } from '@lumapps/base-api/types';
import { get } from '@lumapps/constants';
import type { Feature, FeaturePackage } from '@lumapps/features/types';
import type { Group } from '@lumapps/groups/types';
import type { EnabledListItem } from '@lumapps/lumx-forms/components/FormDraggableEnabledList/FormDraggableEnabledListSwitch';
import type { LegacySearchTab } from '@lumapps/search-settings/types';
import type { CustomSearchTab } from '@lumapps/search/types';
import type { TranslatableObject } from '@lumapps/translations/types';
import { DEFAULT_API_PROFILE_FIELD_PATTERNS } from '@lumapps/user/constants';
import { DEFAULT_FROALA_HTML_TOOLBAR } from '@lumapps/wrex-legacy-froala/constants';

import {
    BLOCK_XSS_HTML_TAGS,
    BROSWER_TRANSLATION,
    CONTENT_LIST_LINK_OPEN_NEW_TAB,
    type CUSTOMER_STATUS,
    type ORGANIZATION_VALUE,
    WYSIWYG_PASTEPLAIN,
} from './constants';

const { defaultContributionLanguages } = get();

/**
 * Mobile login configuration object for a given customer
 */
export type CustomerMobileLoginConfiguration = {
    /** link towards the password recovery page */
    linkDocPasswordRecover?: string;
    /** link towards the how to login docs */
    linkDocHowToLogin?: string;
    /** whether the config is loading or not */
    isLoading?: boolean;
};

/**
 * Legacy properties used in order to add specific customisations
 * to a given customer. Should be DEPRECATED.
 * @deprecated
 */
export type CustomerProperties = {
    contentListLinkOpenNewTab?: CONTENT_LIST_LINK_OPEN_NEW_TAB;
    searchTabs?: LegacySearchTab[];
    customSearchTabs?: Record<string, CustomSearchTab>;
    browserTranslation?: BROSWER_TRANSLATION;
    availableLangs?: string[];
    fallbackLangs?: string[];
    wysiwygToolbar?: string[];
    contentListExcerpt?: number;
    contentFields?: EnabledListItem[];
    communityFilters?: EnabledListItem[];
    contentFilters?: EnabledListItem[];
    fileFields?: EnabledListItem[];
    directoryFilters?: EnabledListItem[];
    metaSocialFields?: EnabledListItem[];
    metaFields?: EnabledListItem[];
    userBlockUserDirectoryField?: string;
    userBlockDefaultField?: string;
    wysiwygPasteplain?: WYSIWYG_PASTEPLAIN;
    blockXssHtmlTags?: BLOCK_XSS_HTML_TAGS;
    __overridden?: Record<string, boolean>;
    __dirty?: string[];
    [key: string]: any;
};

// eslint-disable-next-line deprecation/deprecation
export const PROPERTIES_DEFAULT_VALUES: CustomerProperties = {
    browserTranslation: BROSWER_TRANSLATION.YES,
    availableLangs: defaultContributionLanguages,
    fallbackLangs: defaultContributionLanguages,
    contentListExcerpt: 100,
    contentListLinkOpenNewTab: CONTENT_LIST_LINK_OPEN_NEW_TAB.NEW_TAB,
    userBlockDefaultField: DEFAULT_API_PROFILE_FIELD_PATTERNS.emailsPrimary,
    userBlockUserDirectoryField: DEFAULT_API_PROFILE_FIELD_PATTERNS.title,
    wysiwygPasteplain: WYSIWYG_PASTEPLAIN.YES,
    wysiwygToolbar: DEFAULT_FROALA_HTML_TOOLBAR,
    searchTabs: [],
    customSearchTabs: {},
    blockXssHtmlTags: BLOCK_XSS_HTML_TAGS.ALL,
    communityFilters: [
        {
            enable: true,
            name: 'query',
        },
    ],
    contentFields: [
        {
            enable: true,
            name: 'author',
        },
        {
            enable: true,
            name: 'publication-date',
        },
        {
            enable: true,
            name: 'tags',
        },
        {
            enable: true,
            name: 'title',
        },
        {
            enable: true,
            name: 'excerpt',
        },
        {
            enable: true,
            name: 'metadata',
        },
        {
            enable: true,
            name: 'social',
        },
    ],
    contentFilters: [
        {
            enable: true,
            name: 'query',
        },
        {
            enable: true,
            name: 'tags',
        },
        {
            enable: true,
            name: 'metadata',
        },
        {
            enable: true,
            name: 'author',
        },
        {
            enable: true,
            name: 'dates',
        },
        {
            enable: true,
            name: 'feature-feeds',
        },
    ],
    fileFields: [
        {
            enable: true,
            name: 'thumbnail',
        },
        {
            enable: true,
            name: 'name',
        },
        {
            enable: true,
            name: 'tags',
        },
        {
            enable: true,
            name: 'description',
        },
    ],
    metaSocialFields: [
        {
            enable: true,
            name: 'like',
        },
        {
            enable: true,
            name: 'comment',
        },
        {
            enable: true,
            name: 'follow',
        },
    ],
    metaFields: [
        {
            enable: true,
            name: 'author',
        },
        {
            enable: true,
            name: 'publication-date',
        },
        {
            enable: true,
            name: 'tags',
        },
        {
            enable: true,
            name: 'metadata',
        },
        {
            enable: true,
            name: 'social',
        },
    ],
    directoryFilters: [
        {
            enable: true,
            name: 'query',
        },
        {
            enable: true,
            name: 'directory-tags',
        },
        {
            enable: true,
            name: 'metadata',
        },
    ],
    postFields: [
        {
            enable: true,
            name: 'sharedInCommunities',
        },
        {
            enable: true,
            name: 'metadata',
        },
        {
            enable: true,
            name: 'content',
        },
        {
            enable: true,
            name: 'title',
        },
        {
            enable: true,
            name: 'dates',
        },
        {
            enable: false,
            name: 'attachments',
        },
        {
            enable: false,
            name: 'comments',
        },
    ],
    socialSettings: [
        {
            enable: true,
            name: 'multiposting_allowed',
        },
    ],
};

/**
 * SAML providers interface
 */
export interface SamlProviders {
    /** SAML provider color button */
    buttonColor: string;
    /** SAML provider ID */
    id: string;
    /** SAML provider name */
    name: string;
    /** SAML provider logo */
    logo: {
        fileKey: string;
        url: string;
    };
}

export interface MobileLogo {
    /** Url for mobile logo */
    url?: string;
    /** Blob key for mobile logo  */
    blobKey?: string;
}

export interface MobileCustomization {
    /** Logo for page Mobile > Customization */
    logo?: MobileLogo;
    /** Primary color as background  */
    primaryAsBackground: boolean;
    /** Primary color  */
    primaryColor: string;
}

export interface CustomerLegacyCustomizationOptions {
    /**
     * Legacy configuration for customizations. Do not use.
     * @deprecated
     */
    configurations?: string[];
    /**
     * Legacy theme for customizations. Do not use.
     * @deprecated
     */
    themes?: string[];
    /**
     * Legacy override for customizations. Do not use.
     * @deprecated
     */
    overrides?: string[];
}

export type FeatureFlag = Pick<Feature, 'key' | 'defaultValue'> & {
    /* The organization value for this feature flag */
    organizationValue: ORGANIZATION_VALUE;
};

export interface Customer extends CustomerLegacyCustomizationOptions {
    id: string;
    /** List of available languages defined for the customer */
    availableLanguages: string[];
    /** The base API URL to use for this organization */
    cellUrl: string;
    /** The date and time when this object was created */
    createdAt?: string;
    /** The email of the person who created this object */
    creatorEmail?: string;
    /** The email of the person who last updated this object */
    editorEmail?: string;
    /** List of every enabled feature for this customer (including enabled by default) */
    enabledFeatures: string[];
    /** List of every forced disabled feature */
    excludedFeatures: string[];
    /** The Organization's feature flags */
    featureFlags?: FeatureFlag[];
    /** The list of the customer feature packages */
    featurePackages?: FeaturePackage[];
    /** List of features explicitly enabled for this customer */
    features: string[];
    /** Specific group ALL associated to the customer */
    feedAll: Group;
    /** Specific group ALL associated to the customer */
    feedPublic: Group;
    /** Whether the customer is a Google customer or not */
    googleCustomer?: boolean;
    /** Whether the customer has terms and conditions associated or not */
    hasTermsAndConditions?: boolean;
    /** List of the customer hosts */
    hosts?: string[];
    /** Whether the customer is reseller or not */
    isReseller?: boolean;
    /** The type of license for the organization */
    license?: string;
    /** The date and time (ISO formatted) when the organization's license expires */
    licenseExpiresAt?: string;
    /** The number of users for the organization's license */
    licenseUserCount?: number;
    /** Login methods list */
    loginMethods: string[];
    /** The language-specific URL to the organization's logo */
    logo?: TranslatableObject;
    /** Parameters for mobile customization page */
    mobileCustomization?: MobileCustomization | null;
    /** Site IDs used for determining which sites are used on the mobile app for displaying communities */
    mobileInstanceKeys?: string[];
    /** Mobile configuration */
    mobileLoginConfiguration?: CustomerMobileLoginConfiguration;
    /** Site IDs used for determining which sites are used on the mobile app for displaying search results */
    mobileSearchInstanceKeys?: string[];
    /** The name of the customer */
    name: string;
    /** List of properties for the current customer */
    // eslint-disable-next-line deprecation/deprecation
    properties?: CustomerProperties;
    /** whether the customer allows to have public content or not */
    publicContentAuthorized?: boolean;
    /** The ID of the organization that is reseller for this organization */
    resellerId?: string;
    /** SAML providers list */
    samlProviders: SamlProviders[];
    /** The slug key of the customer */
    slug: string;
    /** Whether SSO is enabled or not */
    ssoEnabled?: boolean;
    /** Customer status */
    status: CUSTOMER_STATUS;
    /** The date and time when this object was last updated */
    updatedAt?: string;
}

export type CustomerListResponse = ServerListResponse<Customer>;
