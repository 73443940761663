(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetVideoSettingsController($rootScope, $timeout, DriveFilesFactory, LxNotificationService, Media,
        Translation, User, Utils, Widget) {
        'ngInject';

        var widgetVideoSettings = this;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Reset the drive video settings when deleting file.
         *
         * @param {string} target The drive picker action associate to the file.
         *                        In this case is "picked" given by the API.
         */
        function _resetDriveVideoSettings(target) {
            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            if (angular.isUndefined(properties) || angular.isUndefinedOrEmpty(target)) {
                return;
            }

            currentWidget.properties = _.omit(properties, ['error', 'embedUrl', 'encoded', 'extension', 'height',
                target + 'Id', target + 'Name', target + 'Url', target + 'Size', 'thumbnail', 'url']);

            if (properties.titleType === 'useFile') {
                _.set(currentWidget, 'title[' + Translation.inputLanguage + ']', undefined);
            }

            if (properties.linkType === 'useFileLink') {
                _.set(properties, 'more.link[' + Translation.inputLanguage + ']', undefined);
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Delete selected file by clearing all properties.
         *
         * @param {Object} target The file to delete.
         *                        In this case is "picked" given by the API.
         */
        function deleteSelectedVideo(target) {
            LxNotificationService.confirm(
                Translation.translate('FRONT.VIDEO.CONFIRM_DELETE_TITLE'),
                Translation.translate('FRONT.VIDEO.CONFIRM_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function confirmDeleteWidget(answer) {
                    if (!answer) {
                        return;
                    }

                    _resetDriveVideoSettings(target);
                    widgetVideoSettings.updateWidget();
                });
        }

        /**
         * Triggered when a file has been selected.
         *
         * @param {Object} fileInfo The file selected by the user.
         * @param {Object} target   In this case is "picked" given by the API.
         */
        function drivePickerCallback(fileInfo, target) {
            if (angular.isUndefinedOrEmpty(fileInfo) || angular.isUndefinedOrEmpty(target)) {
                return;
            }

            if (fileInfo.type !== 'video') {
                return;
            }

            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            if (angular.isDefinedAndFilled(fileInfo.id)) {
                properties.url = {};
                properties.url[Translation.inputLanguage] = fileInfo.url;
                properties.extension = _.last(fileInfo.mimeType.split('/'));
                properties.encoded = false;
                properties[target + 'Id'] = fileInfo.id;
                properties[target + 'Name'] = fileInfo.name;
                properties[target + 'Url'] = fileInfo.url;
                properties.thumbnail = Media.getDrivePreviewUrl(fileInfo.id);
                properties[target + 'Size'] = Utils.formatBytes(fileInfo.sizeBytes, 2);

                if (angular.isDefinedAndFilled(fileInfo.embedUrl)) {
                    properties.embedUrl = fileInfo.embedUrl;
                    properties.encoded = true;
                }
            } else if (angular.isDefinedAndFilled(fileInfo.url)) {
                properties[target + 'Id'] = fileInfo.url.split('/').pop();
            }

            delete properties.error;

            widgetVideoSettings.updateWidget();
        }

        /**
         * Checks if a file / file id is set else clear the folder property.
         *
         * @param  {Object}  target The file.
         *                          In this case is "picked" given by the API.
         * @return {boolean} Whether or not the file is selected.
         */
        function isFileSelected(target) {
            if (angular.isUndefined(target)) {
                return undefined;
            }

            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var currentWidgetProperties = currentWidget.properties;

            if (angular.isDefined(currentWidgetProperties[target + 'Id'])) {
                return true;
            }

            delete currentWidgetProperties[target + 'Id'];
            delete currentWidgetProperties[target + 'Name'];

            return false;
        }

        /**
         * Switch widget source.
         *
         * @param {string} source The source of the video.
         */
        function switchSource(source) {
            var currentWidget = Widget.getCurrent();
            var properties = currentWidget.properties || {};

            if (properties.source === source) {
                return;
            }

            properties.source = source;
        }

        /**
         * Updates video iframe when the video from drive change.
         */
        function updateWidget() {
            $timeout(function timedOutFilePreviewSettingsBroadcast() {
                $rootScope.$broadcast('widget-iframe-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetVideoSettings.deleteSelectedVideo = deleteSelectedVideo;
        widgetVideoSettings.drivePickerCallback = drivePickerCallback;
        widgetVideoSettings.isFileSelected = isFileSelected;
        widgetVideoSettings.switchSource = switchSource;
        widgetVideoSettings.updateWidget = updateWidget;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentWidget = Widget.getCurrent();
            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            if (angular.isUndefinedOrEmpty(properties.source)) {
                properties.source = 'embed';
            }

            if (properties.source !== 'drive' || !isFileSelected('picked') || !User.isGoogle(User.getConnected())) {
                return;
            }

            DriveFilesFactory.get({
                fileId: properties.pickedId,
            }, function onGetFileSuccess(data) {
                if (angular.isDefinedAndFilled(data.videoMediaMetadata) && !properties.encoded) {
                    properties.thumbnail = Media.getDrivePreviewUrl(data.id);
                    properties.embedUrl = data.embedLink;
                    properties.encoded = true;

                    widgetVideoSettings.updateWidget();
                }

                properties.pickedSize = Utils.formatBytes(data.fileSize, 2);
                properties.pickedName = data.title;
            }, function onGetDriveFileError(error) {
                Utils.displayServerError(error);

                if (error.status === 404) {
                    _resetDriveVideoSettings('picked');
                    currentWidget.properties.error = true;
                }
            });
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetVideoSettingsController', WidgetVideoSettingsController);
})();
