import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

const initialState = {
    reportDialog: {
        isOpen: false,
        isLoading: false,
    },
    thanksDialog: {
        isOpen: false,
    },
    alreadyReportedDialog: {
        isOpen: false,
    },
};

const { reducer, actions } = createSlice({
    domain: 'reports',
    initialState,
    reducers: {
        openReportDialog: (state: typeof initialState) => {
            set(state, 'reportDialog.isOpen', true);
        },
        closeReportDialog: (state: typeof initialState) => {
            set(state, 'reportDialog.isOpen', false);
        },
        setReportDialogStatus: (state: typeof initialState, action: PayloadAction<boolean>) => {
            set(state, 'reportDialog.isLoading', action.payload);
        },
        openThanksDialog: (state: typeof initialState) => {
            set(state, 'thanksDialog.isOpen', true);
        },
        closeThanksDialog: (state: typeof initialState) => {
            set(state, 'thanksDialog.isOpen', false);
        },
        openAlreadyReportedDialog: (state: typeof initialState) => {
            set(state, 'alreadyReportedDialog.isOpen', true);
        },
        closeAlreadyReportedDialog: (state: typeof initialState) => {
            set(state, 'alreadyReportedDialog.isOpen', false);
        },
    },
});
export { actions, reducer, initialState };
