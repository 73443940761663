export enum CONTENT_PREVIEW {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510581357
     */
    PREVIEW_UNAVAILABLE = 'CONTENT_PREVIEW.PREVIEW_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=510581361
     */
    PREVIEW_UNAVAILABLE_DESCRIPTION = 'CONTENT_PREVIEW.PREVIEW_UNAVAILABLE_DESCRIPTION',
}
