import keys from 'lodash/keys';
import { useParams } from 'react-router-dom';

import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

const useDecodedParameters = () => {
    const params = useParams() as any;
    const decodedParameters: Record<string, any> = {};

    keys(params).forEach((key) => {
        const value = params[key];

        decodedParameters[key] = decodeURIComponentSafe(value);
    });

    return decodedParameters;
};

export { useDecodedParameters };
