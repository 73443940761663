/* istanbul ignore file */

import React from 'react';

import { SkeletonRectangle } from '@lumapps/lumx/react';
import { Suspense } from '@lumapps/utils/components/Suspense';

export const loadBlockStructuredContent = () =>
    import(
        /* webpackChunkName: 'block-structured-content' */
        './BlockStructuredContent'
    );

const LazyBlockStructuredContent = React.lazy(loadBlockStructuredContent);

export const BlockStructuredContent: React.FC<React.ComponentProps<typeof LazyBlockStructuredContent>> = (props) => (
    <Suspense loadingFallback={<SkeletonRectangle style={{ height: '100vh' }} />}>
        <LazyBlockStructuredContent {...props} />
    </Suspense>
);
