export enum LUMX_DIALOGS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244380
     */
    MAIN_NAV = 'ADMIN.NAV.MAIN_NAV',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=358723295
     */
    INSERT_ALT_TEXT_DIALOG_HELP_MESSAGE = 'LUMX.INSERT_ALT_TEXT_DIALOG_HELP_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=358723287
     */
    INSERT_ALT_TEXT_DIALOG_INPUT_PLACEHOLDER = 'LUMX.INSERT_ALT_TEXT_DIALOG_INPUT_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=358721769
     */
    INSERT_ALT_TEXT_DIALOG_TEXTFIELD_LABEL = 'LUMX.INSERT_ALT_TEXT_DIALOG_TEXTFIELD_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=358720664
     */
    INSERT_ALT_TEXT_DIALOG_ADD_TITLE = 'LUMX.INSERT_ALT_TEXT_DIALOG_ADD_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=361807523
     */
    INSERT_ALT_TEXT_DIALOG_EDIT_TITLE = 'LUMX.INSERT_ALT_TEXT_DIALOG_EDIT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501872744
     */
    LINK_DIALOG_INPUT_PLACEHOLDER = 'LUMX.LINK_DIALOG_INPUT_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=354818909
     */
    LINK_DIALOG_TEXTFIELD_LABEL = 'WREX_LINK.INSERT_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501884723
     */
    LINK_DIALOG_ADD_TITLE = 'LUMX.LINK_DIALOG_ADD_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=501889820
     */
    LINK_DIALOG_EDIT_TITLE = 'LUMX.LINK_DIALOG_EDIT_TITLE',
}
