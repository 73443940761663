import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetTodoController($timeout, Content, LxNotificationService, Translation, User, UserContent, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * User's content widget object.
         *
         * @type {Object}
         */
        var _userContentWidget = {};

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Display checked items option.
         *
         * @type {boolean}
         */
        vm.displayCheckedItems = false;

        /**
         * New todo object.
         *
         * @type {Object}
         */
        vm.newTodo = {};

        /**
         * Todo list.
         *
         * @type {Array}
         */
        vm.todoItems = [];

        /////////////////////////////

        /**
        * Services and utilities.
        */

        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Bind the items to the scope.
         */
        function _bindTodoItems() {
            vm.todoItems = _.get(_userContentWidget, 'params.todo', []);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add an item to the to do list.
         */
        function addTodo() {
            var currentContent = Content.getCurrent();
            var userContent = currentContent.userContent;

            if (angular.isDefined(vm.newTodo) && Translation.hasTranslations(vm.newTodo, true)) {
                if (angular.isUndefined(userContent)) {
                    userContent = {
                        userContent: currentContent.id,
                        values: {},
                    };

                    currentContent.userContent = userContent;
                }

                userContent.values = userContent.values || {};

                userContent.values.widgets = userContent.values.widgets || [];

                if (angular.isUndefinedOrEmpty(_userContentWidget)) {
                    userContent.values.widgets.push({
                        active: true,
                        params: {
                            todo: [],
                        },
                        uuid: vm.widget.uuid,
                    });

                    _userContentWidget = UserContent.getUserContentWidget(currentContent, vm.widget.uuid);

                    _bindTodoItems();

                    vm.addTodo();

                    return;
                }

                _userContentWidget.params = _userContentWidget.params || {};
                _userContentWidget.params.todo = _userContentWidget.params.todo || [];

                _userContentWidget.params.todo.push({
                    id: generateUUID(),
                    isChecked: false,
                    value: vm.newTodo,
                });

                _bindTodoItems();

                UserContent.save({
                    content: currentContent.id,
                    user: User.getConnected().uid,
                    values: userContent.values,
                });

                vm.newTodo = {};
            }
        }

        /**
         * Save check or uncheck item state.
         */
        function checkItem() {
            $timeout(function onCheckItem() {
                var currentContent = Content.getCurrent();

                UserContent.save({
                    content: currentContent.id,
                    user: User.getConnected().uid,
                    values: angular.fastCopy(currentContent.userContent.values),
                });
            });
        }

        /**
         * Get widget classes.
         *
         * @return {Array} A list of CSS classes.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * Check if the widget is empty.
         * The widget is never empty, even if it has not to do item.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            return false;
        }

        /**
         * Check if the widget must be hidden.
         * The widget is never hidden.
         *
         * @return {boolean} Is the widget is hidden or not.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = false;

            return false;
        }

        /**
         * Remove an item from the to do list.
         *
         * @param {Object} todoItem The item of the to do list which we want to remove.
         */
        function removeTodo(todoItem) {
            LxNotificationService.confirm(
                Translation.translate('WIDGET_TYPE_TODO_DELETE'),
                Translation.translate('WIDGET_TYPE_TODO_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                },
                function onDeleteConfim(answer) {
                    if (!answer || angular.isUndefinedOrEmpty(_userContentWidget) ||
                        angular.isUndefinedOrEmpty(_.get(_userContentWidget, 'params.todo'))) {
                        return;
                    }

                    var currentContent = Content.getCurrent();

                    // Find current item and remove it.
                    _userContentWidget.params.todo = _.reject(_userContentWidget.params.todo, {
                        id: todoItem.id,
                    });

                    UserContent.save({
                        content: currentContent.id,
                        user: User.getConnected().uid,
                        values: currentContent.userContent.values,
                    }, _bindTodoItems);
                }
            );
        }

        /**
         * Toggle item state.
         */
        function toggleDisplayCheckedItems() {
            vm.displayCheckedItems = !vm.displayCheckedItems;
        }

        /////////////////////////////

        vm.addTodo = addTodo;
        vm.checkItem = checkItem;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;
        vm.removeTodo = removeTodo;
        vm.toggleDisplayCheckedItems = toggleDisplayCheckedItems;

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        vm.init = function init() {
            // Init user's content widget.
            _userContentWidget = UserContent.getUserContentWidget(Content.getCurrent(), vm.widget.uuid);
            _bindTodoItems();
        };

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            vm.init();
        };
    }

    /**
     * The To Do widget.
     * Displays the current user's to do list.
     *
     * @param {Object} widget The widget configuration object.
     */

    function widgetTodoDirective() {
        'ngInject';

        function widgetToDoLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetTodoController,
            controllerAs: 'vm',
            link: widgetToDoLink,
            replace: true,
            require: ['widgetTodo', '^widget'],
            restrict: 'E',
            scope: {
                widget: '=',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-todo/views/widget-todo.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetTodo', widgetTodoDirective);
})();
