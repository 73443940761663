import React, { forwardRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonRectangle } from '@lumapps/lumx/react';

interface WidgetSkeletonRectangleProps {
    htmlId?: string;
    style?: any;
    useDefaultHeight?: boolean;
}

const CLASSNAME = 'widget-skeleton';
export const WidgetSkeletonRectangle = forwardRef<HTMLDivElement, WidgetSkeletonRectangleProps>(
    ({ htmlId, style, useDefaultHeight = true }, ref) => {
        const { block, element } = useClassnames(CLASSNAME);
        const className = block({
            'default-height': useDefaultHeight,
        });

        return (
            <div ref={ref} id={htmlId} className={className} style={style}>
                <SkeletonRectangle className={element('content')} />
            </div>
        );
    },
);

WidgetSkeletonRectangle.displayName = 'WidgetSkeletonRectangle';
