import { CSSProperties } from 'react';

import type { AllStyles, InnerSpacingStyles, OuterSpacingStyles } from './types';

export const DEFAULT_OPACITY = 14;

export const MAP_SHORTHAND_PROPERTY_TO_LONGHAND_PROPERTIES = {
    borderRadius: ['borderBottomLeftRadius', 'borderBottomRightRadius', 'borderTopLeftRadius', 'borderTopRightRadius'],
    borderColor: ['borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor'],
    borderWidth: ['borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth'],
    margin: ['marginLeft', 'marginRight', 'marginTop', 'marginBottom'],
    padding: ['paddingLeft', 'paddingRight', 'paddingBottom', 'paddingTop'],
} as const;

/** Styles that should be applied to the blocks in an ungrounded container. */
export const WIDGET_CONTENT_STYLES: Array<keyof CSSProperties> = [
    'backgroundColor',
    'backgroundImage',
    'backgroundPosition',
    'backgroundSize',
    'boxShadow',
    'borderColor',
    'borderRadius',
    'borderWidth',
    'borderBottomColor',
    'borderBottomWidth',
    'borderLeftColor',
    'borderLeftWidth',
    'borderRightColor',
    'borderRightWidth',
    'borderTopColor',
    'borderTopWidth',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'padding',
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
];

export const DEFAULT_UNGROUPED_CONTENT_STYLE: AllStyles = {
    shadowElevation: 1,
    backgroundColor: 'white',
};

/** List of keys from innerSpacingStyle interface */
export const INNER_SPACING_STYLES: Array<keyof InnerSpacingStyles> = [
    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',
    'padding',
];

/** List of keys from outerSpacingStyle interface */
export const OUTER_SPACING_STYLES: Array<keyof OuterSpacingStyles> = [
    'marginBottom',
    'marginTop',
    'marginLeft',
    'marginRight',
    'margin',
];
