import loFind from 'lodash/find';

/////////////////////////////

function CalendarService(CalendarFactory, LumsitesBaseService) {
    'ngInject';

    /* eslint-disable consistent-this */
    const service = LumsitesBaseService.createLumsitesBaseService(CalendarFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get a calendar from the list.
     *
     * @param  {string} calendarId          The calendar identifier.
     * @param  {string} [listKey="default"] The list key of the list to search within.
     * @return {Object} The calendar.
     */
    function get(calendarId, listKey) {
        return loFind(service.displayList(listKey), {
            id: calendarId,
        });
    }

    /////////////////////////////

    service.get = get;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Calendar', CalendarService);

/////////////////////////////

export { CalendarService };
