import { MutableRefObject } from 'react';

import { Falsy } from '../types/Falsy';

type FnRef<T> = (value: T) => void;

/**
 * Merge refs into a single function ref.
 */
export function mergeRefs<T>(refs: Array<MutableRefObject<T> | FnRef<T> | Falsy>): FnRef<T> {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref) {
                // eslint-disable-next-line no-param-reassign
                ref.current = value;
            }
        });
    };
}
