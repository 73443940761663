import React from 'react';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { margin } from '@lumapps/classnames';
import { AppId } from '@lumapps/constants/app';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ToggleTipButton } from '@lumapps/lumx-buttons/components/ToggleTipButton';
import { DOCUMENTATION_LINKS } from '@lumapps/lumx-texts/components/ReferToDocumentation';
import { mdiHelpCircleOutline, mdiOpenInNew } from '@lumapps/lumx/icons';
import { Button, ColorPalette, Emphasis, FlexBox, Link, Theme, Typography, Text } from '@lumapps/lumx/react';
import { useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useFollowInterest } from '../../hooks/useFollowInterest';
import { WIDGET_CONTENT_FILTER } from '../../keys';
import { GenericListFilterType } from '../../types';
import { FilterSubheader } from '../FilterSubheader/FilterSubheader';

interface FollowThisInterestProps {
    filtersValues?: GenericListFilterType;
    scope: string;
    theme: Theme;
}

export const FollowThisInterest = ({ filtersValues, scope, theme }: FollowThisInterestProps) => {
    const { translateKey, translateObject, translateAndReplaceWithComponents } = useTranslate();
    const { getUrl } = useRouter();
    const { get } = useDataAttributes(scope);

    /** Selected filters (not necessarily applied) are taking precedence over the pre-filters from properties */
    const { contentTypes, tags, metadata } = filtersValues || {};
    const contentTypesIds = map(contentTypes, 'id');
    const tagIds = map(tags, 'id');
    const metadataIds = map(metadata, 'id');

    const { handleInterest, isFollowed, isLoading } = useFollowInterest({
        contentTypes: contentTypesIds,
        tags: tagIds,
        metadata: metadataIds,
    });

    const subscriptionsUrl = getUrl({
        appId: AppId.frontOffice,
        path: '/ls/subscriptions',
        query: { filter: 'interests' },
    });

    return (
        <FlexBox>
            <FilterSubheader
                theme={theme}
                label={
                    <FlexBox orientation="horizontal" gap="tiny" hAlign="center">
                        <Text as="span">{translateKey(WIDGET_CONTENT_FILTER.INTEREST_SUBHEADER)}</Text>
                        <ToggleTipButton
                            className={margin('left', 'tiny')}
                            iconColor={theme === Theme.light ? ColorPalette.dark : ColorPalette.light}
                            icon={mdiHelpCircleOutline}
                            iconButtonProps={{ label: translateKey(GLOBAL.MORE_INFORMATION), size: 's' }}
                            content={
                                <Text
                                    as="p"
                                    color={ColorPalette.light}
                                    typography={Typography.body1}
                                    style={{ whiteSpace: 'break-spaces' }}
                                >
                                    {/* TODO: Split into two translations */}
                                    {translateAndReplaceWithComponents(WIDGET_CONTENT_FILTER.INTEREST_INFORMATION, {
                                        SUBSCRIPTION_LINK: (
                                            <Link
                                                typography={Typography.subtitle1}
                                                color="light"
                                                href={subscriptionsUrl}
                                            >
                                                {translateKey(GLOBAL.SUBSCRIPTIONS)}
                                            </Link>
                                        ),
                                        DOCUMENTATION_LINK: (
                                            <Link
                                                color="light"
                                                typography={Typography.subtitle1}
                                                rightIcon={mdiOpenInNew}
                                                href={DOCUMENTATION_LINKS.notifications.interests}
                                                target="_blank"
                                            >
                                                {translateKey(GLOBAL.DOCUMENTATION)}
                                            </Link>
                                        ),
                                    })}
                                </Text>
                            }
                        />
                    </FlexBox>
                }
            />
            {(!isEmpty(contentTypesIds) || !isEmpty(metadataIds) || !isEmpty(tagIds)) && (
                <FlexBox className={margin('bottom', 'huge')}>
                    {!isEmpty(contentTypesIds) && (
                        <div>
                            <Text theme={theme} typography="body1" color="dark" colorVariant="N" as="span">
                                {translateKey(GLOBAL.CONTENT_TYPES)}:{' '}
                            </Text>
                            <Text theme={theme} typography="body1" color="dark" colorVariant="L2" as="span">
                                {contentTypes
                                    ?.map((ct) => ct.name.value ?? translateObject(ct.name.translations))
                                    .join(' • ')}
                            </Text>
                        </div>
                    )}
                    {!isEmpty(metadataIds) && (
                        <div>
                            <Text theme={theme} typography="body1" color="dark" colorVariant="N" as="span">
                                {translateKey(GLOBAL.METADATA)}:{' '}
                            </Text>
                            <Text theme={theme} typography="body1" color="dark" colorVariant="L2" as="span">
                                {metadata?.map((meta) => translateObject(meta.name)).join(' • ')}
                            </Text>
                        </div>
                    )}
                    {!isEmpty(tagIds) && (
                        <div>
                            <Text theme={theme} typography="body1" color="dark" colorVariant="N" as="span">
                                {translateKey(GLOBAL.TAGS)}:{' '}
                            </Text>
                            <Text theme={theme} typography="body1" color="dark" colorVariant="L2" as="span">
                                {tags?.map((tag) => tag.name).join(' • ')}
                            </Text>
                        </div>
                    )}
                </FlexBox>
            )}
            <Button
                emphasis={Emphasis.medium}
                onClick={handleInterest}
                theme={theme}
                isSelected={isFollowed}
                /**
                 * Disable if there is no interests to follow (if no tags and metadata)
                 * or while loading
                 */
                isDisabled={(isEmpty(tagIds) && isEmpty(metadataIds)) || isLoading}
                {...get({ element: 'button', action: 'save-interest' })}
            >
                {translateKey(
                    isFollowed ? WIDGET_CONTENT_FILTER.INTEREST_SAVED : WIDGET_CONTENT_FILTER.SAVE_AS_INTEREST,
                )}
            </Button>
        </FlexBox>
    );
};
