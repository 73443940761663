import get from 'lodash/get';

import { NANNY_ANALYSE_ERROR_CODE } from '@lumapps/nanny/constants';
import { isNannyEnabled } from '@lumapps/nanny/ducks/selectors';
import { PayloadAction } from '@lumapps/redux/createSlice';

import { actions, NannyAlertAction } from '../ducks/slice';

const hasNannyError = (error: any) => {
    const realError = get(error, 'data.error', null);
    if (realError) {
        const hasErrorCode = realError.code === NANNY_ANALYSE_ERROR_CODE;
        if (hasErrorCode) {
            // Try to read the message.
            try {
                const message = JSON.parse(realError.message);
                // If the message contains the NANNY key
                return Boolean(message.violations);
            } catch (e) {
                return false;
            }
        }
    }
    return false;
};

const shouldUseNannyErrorHandler = (state: any, error: any) => {
    const hasNanny = isNannyEnabled(state);
    const hasReadableNannyError = hasNannyError(error);
    /**
     * Returns true is the nanny FF is enabled and that the error message
     * is readable for the nanny dedicated dialog.
     */
    return hasNanny && hasReadableNannyError;
};

const handleNannyError = (error: any): PayloadAction<NannyAlertAction> => {
    const message = JSON.parse(error.data.error.message);

    const { violations, more } = message;

    // Fire the event to display the modal.
    return actions.displayNannyAlert({ violations, more });
};

export { shouldUseNannyErrorHandler, handleNannyError, hasNannyError };
