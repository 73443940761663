import React from 'react';

import { useLumAppsAnalytics } from '../../analytics';
import { usePendo } from '../../hooks/usePendo';

export interface MetricsProps {
    /** If the pendo feature is enabled */
    isPendoEnabled: boolean;
    /** The current customer */
    customer: any;
    /** The current instance */
    instance: any;
    /** The connected user */
    user: any;
    /** Custom data from the userDirectory */
    customVisitorAttributes?: any;
    /* current user language */
    currentLanguage: string;
    /** whether the user has accepted cookies */
    hasAcceptedCookies: boolean;
}

const Metrics: React.FC<MetricsProps> = ({
    isPendoEnabled = false,
    customer,
    instance,
    user,
    customVisitorAttributes = {},
    currentLanguage,
    hasAcceptedCookies,
}) => {
    useLumAppsAnalytics();

    // Pendo
    usePendo({
        isPendoEnabled,
        customer,
        instance,
        user,
        customVisitorAttributes,
        currentLanguage,
        hasAcceptedCookies,
    });

    return null;
};

export { Metrics };
