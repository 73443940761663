import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Orientation, Size } from '@lumapps/lumx/react';

import './index.scss';

export interface MasonryProps {
    /** number of columns to display */
    columns: number;
    /** space between columns */
    gutterSize?: Extract<Size, 'tiny' | 'regular' | 'big' | 'huge'>;
    /** items to display */
    children: React.ReactNode;
    /** custom classname */
    className?: string;
}

const CLASSNAME = 'masonry';
/**
 * Component that displays a list of children in a Masonry fashion.
 *
 * Masonry is a grid layout based on columns
 * Unlike other grid layouts, it doesn’t have fixed height rows. Basically, Masonry layout optimizes the use of
 * space inside the web page by reducing any unnecessary gaps.
 *
 * Without this type of layout, certain restrictions are required to maintain the structure of layout.
 *
 * @family Layouts
 * @param MasonryProps
 * @returns Masonry
 */
export const Masonry = React.forwardRef<HTMLDivElement, MasonryProps>((props, ref) => {
    const { columns = 2, gutterSize = Size.big, children, className } = props;
    const { block, element } = useClassnames(CLASSNAME);
    const wrapperClassName = block([className]);

    const ColumnsToDisplay = React.useMemo(() => {
        const Columns: any[] = [];
        const childrenAsArray = React.Children.toArray(children);

        childrenAsArray.forEach((child, index) => {
            const column = index % columns;
            let Column = Columns[column];

            if (!Column) {
                Column = [];
            }

            Column.push(child);

            Columns[column] = Column;
        });

        return Columns;
    }, [children, columns]);

    return (
        <FlexBox ref={ref} className={wrapperClassName} orientation={Orientation.horizontal}>
            {ColumnsToDisplay.map((column, key) => {
                return (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={key}
                        className={element('column', {
                            [`gutter-${gutterSize}`]: Boolean(gutterSize),
                            [`${key}`]: true,
                        })}
                        style={{ width: `${100 / columns}%`, minWidth: 0 }}
                    >
                        {column}
                    </div>
                );
            })}
        </FlexBox>
    );
});
Masonry.displayName = 'Masonry';
