import partial from 'lodash/partial';

import { Editor } from '@lumapps/wrex/slate';
import { createPlugin, CreatePluginEditor } from '@lumapps/wrex/slate/plugin';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';
import { selectElementOnDelete } from '@lumapps/wrex/slate/utils/selectElementOnDelete';
import { WrexEditor } from '@lumapps/wrex/types';

import { EditablePlayVideo } from '../components/editableBlocks/EditablePlayVideo';
import type { PlayVideoEditor } from '../types';
import { insertPlayVideo } from '../utils/insertPlayVideo';
import { isPlayVideo } from '../utils/isPlayVideo';

const createPluginEditor = (): CreatePluginEditor<PlayVideoEditor, WrexEditor<PlayVideoEditor>> => (editor) => {
    const { isVoid, normalizeNode, deleteBackward, deleteForward } = editor;

    return {
        normalizeNode([node, path]) {
            const pathRef = Editor.pathRef(editor, path);
            normalizeLiftToRootNode(editor, [node, path], isPlayVideo);
            const changedPath = pathRef.unref();
            if (changedPath) {
                normalizeNode([node, changedPath]);
            }
        },

        isVoid: (element) => isPlayVideo(element) || isVoid(element),

        deleteBackward(unit) {
            if (unit === 'character' && selectElementOnDelete(editor, false, isPlayVideo)) {
                return;
            }
            deleteBackward(unit);
        },

        deleteForward(unit) {
            if (unit === 'character' && selectElementOnDelete(editor, true, isPlayVideo)) {
                return;
            }
            deleteForward(unit);
        },

        insertPlayVideo: partial(insertPlayVideo, editor),
    };
};
export const withPlayVideo = () =>
    createPlugin({
        createPluginEditor: createPluginEditor(),
        elements: [EditablePlayVideo],
    });
