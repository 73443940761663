import dropRight from 'lodash/dropRight';
import last from 'lodash/last';
import { Editor, NodeEntry, Path } from 'slate';

type SiblingDirection = 'after' | 'before';

/**
 * Get path before or after.
 */
export const getSiblingPath = (path: Path, direction: SiblingDirection): Path | null => {
    const index = last(path);
    if (index === undefined) {
        return null;
    }
    return [...dropRight(path), index + (direction === 'after' ? 1 : -1)];
};

/**
 * Get before or after sibling node entry at path.
 */
export const getSibling = (editor: Editor, path: Path, direction: SiblingDirection): NodeEntry | null => {
    const newPath = getSiblingPath(path, direction);
    if (!newPath) {
        return null;
    }
    try {
        return Editor.node(editor, newPath);
    } catch (e) {
        return null;
    }
};
