export enum FOLKSONOMY_ADMIN {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66428326
     */
    TITLE = 'ADMIN.FOLKSONOMY.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66428356
     */
    ALL_TAGS = 'ADMIN.FOLKSONOMY.ALL_TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66432785
     */
    NO_TAGS = 'ADMIN.FOLKSONOMY.NO_TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66432798
     */
    NO_TAGS_DESC = 'ADMIN.FOLKSONOMY.NO_TAGS_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66432806
     */
    NOT_ENABLED = 'ADMIN.FOLKSONOMY.NOT_ENABLED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66432875
     */
    NOT_ENABLED_DESC = 'ADMIN.FOLKSONOMY.NOT_ENABLED_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73551513
     */
    TRENDING_TAGS = 'ADMIN.FOLKSONOMY.TRENDING_TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73551519
     */
    RECENT_TAGS = 'ADMIN.FOLKSONOMY.RECENT_TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73551536
     */
    RECENT_TAGS_DESCRIPTION = 'ADMIN.FOLKSONOMY.RECENT_TAGS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73551626
     */
    TAGS_NUMBER = 'ADMIN.FOLKSONOMY.TAGS_NUMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73719435
     */
    TRENDING_TAGS_DESCRIPTION = 'ADMIN.FOLKSONOMY.TRENDING_TAGS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=73743490
     */
    LOAD_MORE = 'ADMIN.FOLKSONOMY.LOAD_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74386086
     */
    DETAILS_HEADER = 'ADMIN.FOLKSONOMY.DETAILS_HEADER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404216
     */
    OCCURRENCES = 'ADMIN.FOLKSONOMY.OCCURRENCES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404236
     */
    OCCURRENCES_DESC = 'ADMIN.FOLKSONOMY.OCCURRENCES_DESC',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404277
     */
    OCCURRENCES_POST = 'ADMIN.FOLKSONOMY.OCCURRENCES_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=118839470
     */
    OCCURRENCES_COMMUNITY = 'ADMIN.FOLKSONOMY.OCCURRENCES_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232309655
     */
    OCCURRENCES_VIDEO = 'ADMIN.FOLKSONOMY.OCCURRENCES_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404279
     */
    ORIGIN = 'ADMIN.FOLKSONOMY.ORIGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404285
     */
    CREATED_THE = 'ADMIN.FOLKSONOMY.CREATED_THE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74404330
     */
    RELATED_TAGS = 'ADMIN.FOLKSONOMY.RELATED_TAGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74614634
     */
    TRENDING_TAGS_EMPTY = 'ADMIN.FOLKSONOMY.TRENDING_TAGS.EMPTY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74614639
     */
    TRENDING_TAGS_EMPTY_DESCRIPTION = 'ADMIN.FOLKSONOMY.TRENDING_TAGS.EMPTY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74616933
     */
    RECENT_TAGS_EMPTY = 'ADMIN.FOLKSONOMY.RECENT_TAGS.EMPTY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74617583
     */
    RECENT_TAGS_EMPTY_DESCRIPTION = 'ADMIN.FOLKSONOMY.RECENT_TAGS.EMPTY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74617925
     */
    RELATED_TAGS_EMPTY_DESCRIPTION = 'ADMIN.FOLKSONOMY.RELATED_TAGS.EMPTY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74617929
     */
    RELATED_TAGS_EMPTY = 'ADMIN.FOLKSONOMY.RELATED_TAGS.EMPTY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74650404
     */
    SEE_POSTS = 'ADMIN.FOLKSONOMY.SEE_POSTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=75234503
     */
    TAG_DETAILS_ERROR = 'ADMIN.FOLKSONOMY.TAG_DETAILS.ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=76088680
     */
    RELATED_TAGS_DESCRIPTION = 'ADMIN.FOLKSONOMY.RELATED_TAGS.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=77149950
     */
    TAG_DETAILS_ERROR_SUBTITLE = 'ADMIN.FOLKSONOMY.TAG_DETAILS.ERROR_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=77170369
     */
    SEE_POSTS_HELPER = 'ADMIN.FOLKSONOMY.SEE_POSTS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=118885375
     */
    SEE_RESULTS = 'ADMIN.FOLKSONOMY.SEE_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=118885389
     */
    SEE_RESULTS_HELPER = 'ADMIN.FOLKSONOMY.SEE_RESULTS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=90710267
     */
    MANAGE_TAGZ = 'ADMIN.FEATURES.TAGZ.MANAGE_TAGZ',
}
