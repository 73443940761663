import { useMemo } from 'react';

import { get } from '../utils/attributes';

/**
 * Hook that retrieves the necessary functions in order to generate data attributes for
 * tracking purposes.
 *
 * For more information, check out the following link:
 * https://we.lumapps.com/battlefront/ls/content/5346604445204480/data-attributes
 *
 * @param scope scope of the feature. This is usually the name of the feature or the package you are going to call this function from
 * @returns data attributes function
 */
export const useDataAttributes = (scope: string) => {
    return useMemo(() => {
        return {
            get: get(scope),
        };
    }, [scope]);
};
