import React from 'react';
import { bool, func, node, string } from 'prop-types';
import classNames from 'classnames';

import noop from 'lodash/noop';

import { offset } from '../../../utils';
import * as uiTypes from '../types';
import * as buttonTypes from './types';

/**
 * Renders a button.
 */
class Button extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            radius: 0,
            value: false,
            x: 0,
            y: 0,
        };

        /**
         * Stores the top right corner of the ripple and its radius.
         * Negates `value` to trigger an update of `<Ripple />`.
         *
         * @param {Object} occurence The event object.
         */
        this.onMouseDown = (occurence) => {
            const { onMouseDown } = this.props;
            if (onMouseDown) {
                onMouseDown(occurence);
            }
            const button = occurence.currentTarget;
            const bounds = offset(button);
            const radius = Math.max(bounds.width, bounds.height);
            const { value } = this.state;
            this.setState({
                radius,
                value: !value,
                // eslint-disable-next-line no-magic-numbers
                x: occurence.pageX - bounds.left - radius / 2,
                // eslint-disable-next-line no-magic-numbers
                y: occurence.pageY - bounds.top - radius / 2,
            });
        };
    }

    render() {
        const { children, className, size, color, variant, ripple, onMouseDown, type, ...props } = this.props;

        return (
            // eslint-disable-next-line react/button-has-type
            <button
                className={classNames(className, `btn btn--${size} btn--${color} btn--${variant}`)}
                style={
                    ripple
                        ? {
                              overflow: 'hidden',
                              position: 'relative',
                          }
                        : undefined
                }
                type={type}
                {...props}
                onMouseDown={ripple ? this.onMouseDown : onMouseDown}
            >
                {children}
            </button>
        );
    }
}

Button.propTypes = {
    /** Any extra child that should appear inside the button. */
    children: node,
    /** A custom css selector to apply to the wrapper of the component. */
    className: string,
    /** Color name as defined in a `btn--${color}` CSS selector. */
    color: string,
    /** See DOM `button`. */
    onMouseDown: func,
    /** Whether a ripple effect should appear on click or not. */
    // eslint-disable-next-line react/boolean-prop-naming
    ripple: bool,
    /** Button size. */
    size: uiTypes.size,
    /** Button type. */
    type: string,
    /** Button variant. */
    variant: buttonTypes.variant,
};

Button.defaultProps = {
    children: undefined,
    className: '',
    color: 'primary',
    onMouseDown: noop,
    ripple: true,
    size: 'm',
    type: 'button',
    variant: 'raised',
};

export { Button };
