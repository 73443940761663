import React from 'react';

import { ArticlePreview } from '@lumapps/articles/components/ArticlePreview/ConnectedArticlePreview';
import { articleView } from '@lumapps/articles/routes';
import { classnames, margin, useClassnames } from '@lumapps/classnames';
import { EventPreviewWrapper } from '@lumapps/events/components/EventPreviewWrapper';
import { ImageLightboxProvider } from '@lumapps/lumx-images/components/ImageLightboxProvider';
import { ImagesAttachments } from '@lumapps/lumx-images/components/ImagesAttachments';
import { DefaultPreview as LumxLinkPreview } from '@lumapps/lumx-preview/components/DefaultPreview';
import { mdiClose } from '@lumapps/lumx/icons';
import {
    Alignment,
    AspectRatio,
    FlexBox,
    Orientation,
    ProgressCircular,
    Theme,
    ThumbnailProps,
    Button,
    Size,
    Emphasis,
    SkeletonRectangle,
} from '@lumapps/lumx/react';
import { PdfPreviewBlock } from '@lumapps/medias/components/PdfPreviewBlock/PdfPreviewBlock';
import { VideoWebsites, WithBlob } from '@lumapps/medias/types';
import {
    getEmbedUrl,
    getMediaIcon,
    getVideoId,
    getVideoUrlWebsiteName,
    makeSecuredMediaURLRelative,
} from '@lumapps/medias/utils';
import { Skeleton } from '@lumapps/play-player/components/Skeleton';
import { AttachmentTypes } from '@lumapps/posts/types';
import { addLocationOriginToUrl, createUrl } from '@lumapps/router/utils';
import { useTranslate } from '@lumapps/translations';
import { ImportOnVisibility } from '@lumapps/utils/components/ImportOnVisibility';
import { ArticlePreview as ArticlePreviewTypes, EventPreview, PlayVideoPreview } from '@lumapps/widget-post-list/types';
import { WREX_LINK } from '@lumapps/wrex-link/keys';

import { WIDGET_BASE } from '../../../keys';
import { LinkAttachment, MediaPreview } from '../../../types';
import { RemoveButton } from '../../RemoveButton';
import { FileAttachmentMultiple } from './FileAttachmentMultiple/FileAttachmentMultiple';
import { getImageAlt, getImageUrl } from './utils';

import './index.scss';

const VideoPlayerWrapper = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'player-monolite-facade' */
            '@lumapps/play-player/components/PlayerMonoliteFacade'
        ),
);

const CLASSNAME = 'block-post-attachments';

export interface AttachmentsProps {
    className?: string;
    images?: Partial<MediaPreview & WithBlob>[];
    files?: Array<MediaPreview & WithBlob>;
    link?: LinkAttachment;
    articlePreview?: ArticlePreviewTypes;
    eventPreview?: EventPreview;
    playVideoPreview?: PlayVideoPreview;
    theme?: Theme;
    isLoading?: boolean;
    readOnly?: boolean;
    shouldDisplayEventAttendButton?: boolean;
    // Whether attachments like pdf should be embedded
    shouldDisplayEmbed?: boolean;
    onClearAttachments?(): void;
    /** Callback for switching the link preview thumbnail */
    onLinkPreviewThumbnailSwitch?: () => void;
    // Callback for remove attachment
    onRemoveAttachment?(attachmentType: AttachmentTypes, attachmentIndex: number): void;
    // If we use attachments in comments context (added for render different view)
    isCommentContext?: boolean;
}

const mediaToThumbnailProps = (image: Partial<MediaPreview & WithBlob>): ThumbnailProps => ({
    focusPoint: { x: image.thumbnail?.focalX || 0, y: image.thumbnail?.focalY || 0 },
    image: image.blobUrl || (getImageUrl(image, true) as any),
    alt: getImageAlt(image),
    imgProps: { height: image.height },
});

const Attachments: React.FC<AttachmentsProps> = ({
    className,
    images,
    link,
    articlePreview,
    eventPreview,
    playVideoPreview,
    files: medias,
    readOnly,
    theme,
    isLoading,
    shouldDisplayEventAttendButton = true,
    shouldDisplayEmbed = false,
    onClearAttachments,
    onRemoveAttachment,
    isCommentContext = false,
    onLinkPreviewThumbnailSwitch,
}) => {
    const { pluralize, translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);
    const hasAttachments =
        (images && images.length) ||
        link ||
        (medias && medias.length) ||
        articlePreview ||
        eventPreview ||
        playVideoPreview;

    const videoId =
        link && link.videoId ? link.videoId : getVideoId(link?.url, getVideoUrlWebsiteName(link?.url) as VideoWebsites);
    const thumbnails = images?.map(mediaToThumbnailProps);
    const canDelete = !articlePreview && !eventPreview;
    const linkImage =
        (link?.image && link?.image?.url) ||
        (link?.thumbnailIndex && link?.images?.[link?.thumbnailIndex]) ||
        link?.images?.[0];
    const attachmentIndexSingleElement = 0;

    const getAttachmentDisplay = () => {
        if (medias && medias.length === 1 && medias[0].mimeType === 'application/pdf' && shouldDisplayEmbed) {
            return (
                <FlexBox theme={theme}>
                    <PdfPreviewBlock media={medias[0]} theme={theme} />
                    {canDelete && onRemoveAttachment && (
                        <RemoveButton
                            onClick={() => onRemoveAttachment('files', attachmentIndexSingleElement)}
                            label={translateKey(WIDGET_BASE.REMOVE_FILE)}
                        />
                    )}
                </FlexBox>
            );
        }
        if (medias && medias.length > 0) {
            return (
                <FlexBox theme={theme} className={element('type', 'files')}>
                    <FileAttachmentMultiple
                        onRemoveAttachment={onRemoveAttachment}
                        isCommentContext={isCommentContext}
                        canDelete={canDelete}
                        theme={theme}
                        files={medias.map((media) => {
                            const imageUrl = media.mimeType?.startsWith('image') && media.url;
                            const thumbnailUrl = media.thumbnail?.url || imageUrl;
                            const shouldUseIcon = !thumbnailUrl;
                            return {
                                mimeType: media.mimeType,
                                url: media.url || '',
                                name: media.fileName,
                                thumbnail: shouldUseIcon ? {} : { image: media.blobUrl || thumbnailUrl },
                                icon: getMediaIcon({ mimeType: media.mimeType }),
                                uuid: media.mediaId,
                                source: media.source,
                            };
                        })}
                    />
                </FlexBox>
            );
        }

        return null;
    };

    return (
        <div className={block([className])}>
            <FlexBox theme={theme} className={element('types')} orientation={Orientation.vertical}>
                {Boolean(hasAttachments) && !isLoading && (
                    <>
                        {thumbnails && thumbnails.length > 0 && (
                            <ImageLightboxProvider isDisabled={!readOnly}>
                                <ImagesAttachments
                                    images={thumbnails}
                                    theme={theme}
                                    className={element('type', 'images')}
                                />
                                {onRemoveAttachment && canDelete && (
                                    <RemoveButton
                                        // we use attachmentIndexSingleElement, because one or few images this is one attachment
                                        onClick={() => onRemoveAttachment('images', attachmentIndexSingleElement)}
                                        label={pluralize(WIDGET_BASE.REMOVE_IMAGE_PLURAL, thumbnails.length)}
                                    />
                                )}
                            </ImageLightboxProvider>
                        )}
                        {getAttachmentDisplay()}
                        {articlePreview && (
                            <FlexBox
                                theme={theme}
                                className={element('type', {
                                    link: true,
                                    'article-preview': true,
                                })}
                            >
                                <ArticlePreview
                                    id={articlePreview.id}
                                    hasImage={Boolean(articlePreview.image)}
                                    hasDescription={Boolean(articlePreview.description)}
                                    theme={theme}
                                    title={articlePreview.title}
                                    description={articlePreview.description}
                                    link={addLocationOriginToUrl(createUrl(articleView(articlePreview.id)))}
                                    size="big"
                                    thumbnailProps={
                                        articlePreview.image
                                            ? {
                                                  aspectRatio: AspectRatio.wide,
                                                  image: makeSecuredMediaURLRelative(articlePreview.image) as string,
                                                  alt: articlePreview.description || '',
                                              }
                                            : undefined
                                    }
                                    article={articlePreview}
                                />
                            </FlexBox>
                        )}
                        {eventPreview && (
                            <FlexBox
                                theme={theme}
                                className={element('type', {
                                    link: true,
                                    'event-preview': true,
                                })}
                            >
                                <EventPreviewWrapper
                                    id={eventPreview.id}
                                    hasImage={eventPreview.image}
                                    hasDescription={Boolean(eventPreview.description)}
                                    theme={theme}
                                    event={eventPreview}
                                    shouldDisplayAttendButton={shouldDisplayEventAttendButton}
                                />
                            </FlexBox>
                        )}

                        {playVideoPreview && (
                            <ImportOnVisibility
                                options={{
                                    rootMargin: '200px', // Loads the component early for smoother UX
                                }}
                                loadingFallback={<Skeleton />}
                            >
                                <VideoPlayerWrapper videoId={playVideoPreview.id} />
                                {canDelete && onRemoveAttachment && (
                                    <RemoveButton
                                        onClick={() => onRemoveAttachment('links', attachmentIndexSingleElement)}
                                        label={translateKey(WIDGET_BASE.REMOVE_VIDEO)}
                                    />
                                )}
                            </ImportOnVisibility>
                        )}

                        {link && !articlePreview && !eventPreview && !playVideoPreview && !videoId && (
                            <FlexBox theme={theme} className={element('type', 'link')}>
                                {link.isLoading ? (
                                    <SkeletonRectangle height="xl" style={{ width: '100%' }} />
                                ) : (
                                    <LumxLinkPreview
                                        preview={link}
                                        onPreviewThumbnailSwitch={onLinkPreviewThumbnailSwitch}
                                        onRemove={
                                            canDelete && onRemoveAttachment
                                                ? () => onRemoveAttachment('links', attachmentIndexSingleElement)
                                                : undefined
                                        }
                                        removeButtonProps={{
                                            label: translateKey(WREX_LINK.LINK_REMOVE),
                                            emphasis: Emphasis.low,
                                            size: Size.m,
                                            icon: mdiClose,
                                        }}
                                        imgProps={{ image: linkImage || '' }}
                                    />
                                )}
                            </FlexBox>
                        )}
                        {link && videoId && (
                            <FlexBox theme={theme} className={element('video-wrapper')}>
                                <iframe
                                    className={element('video-iframe')}
                                    title={link.title}
                                    src={getEmbedUrl(link.url || '')}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                                {canDelete && onRemoveAttachment && (
                                    <RemoveButton
                                        onClick={() => onRemoveAttachment('links', attachmentIndexSingleElement)}
                                        label={translateKey(WIDGET_BASE.REMOVE_VIDEO)}
                                    />
                                )}
                            </FlexBox>
                        )}
                        {onClearAttachments && canDelete && !onRemoveAttachment && (
                            <FlexBox theme={theme} orientation={Orientation.horizontal}>
                                <Button
                                    className={classnames(
                                        'lumx-flex-box--margin-auto-left',
                                        margin('vertical', 'regular'),
                                    )}
                                    theme={theme}
                                    size={Size.s}
                                    emphasis={Emphasis.medium}
                                    onClick={onClearAttachments}
                                    leftIcon={mdiClose}
                                >
                                    {translateKey(WIDGET_BASE.ATTACHMENTS_DELETE_ALL)}
                                </Button>
                            </FlexBox>
                        )}
                    </>
                )}
                {isLoading && (
                    <FlexBox
                        theme={theme}
                        className={element('loader-wrapper')}
                        vAlign={Alignment.center}
                        hAlign={Alignment.center}
                    >
                        <ProgressCircular theme={theme} />
                    </FlexBox>
                )}
            </FlexBox>
            <div className={element('crawler-loader')} />
        </div>
    );
};

export { Attachments };
