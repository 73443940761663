import React from 'react';

export interface TreeOpenStateContextOptions {
    /** ID of the currently opened sub menu */
    openedSubMenu: string | undefined;
    /** Open submenu. */
    setOpenedSubMenu: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const TreeOpenStateContext = React.createContext<TreeOpenStateContextOptions | undefined>(undefined);

/**
 * Init a value for a `TreeOpenStateContext`.
 */
export const useTreeOpenState = () => {
    const [openedSubMenu, setOpenedSubMenu] = React.useState<string | undefined>();

    return React.useMemo(
        () => ({
            openedSubMenu,
            setOpenedSubMenu,
        }),
        [openedSubMenu],
    );
};
