/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */

/**
 * Build the filter used as an input of the api.
 * Several steps may be involved here:
 *  1. create a dateFrom based on the timeframe (== number of days) the user selected.
 *  2. add topics if needed.
 *  3. add ambassadors if needed.
 *
 * @param  {Object} stateFilter Filter object.
 * @return {Object} The API filters.
 */
export const buildFilters = (stateFilter) => {
    const { timeframe, topics, ambassadors, programId } = stateFilter;
    const apiFilters = {};

    if (timeframe !== undefined) {
        const date = new Date();
        date.setDate(date.getDate() - timeframe);
        date.setHours(0);
        date.setMinutes(0, 0, 0);
        apiFilters.dateFrom = date.toISOString();
    }

    if (topics !== undefined && topics.length > 0) {
        apiFilters.topicIds = [...topics];
    }

    if (ambassadors !== undefined && ambassadors.length > 0) {
        apiFilters.sharerIds = [...ambassadors];
    }

    if (programId !== undefined && programId !== null) {
        apiFilters.programIds = [programId];
    }

    return apiFilters;
};
