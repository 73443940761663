(function IIFE() {
    'use strict';

    /////////////////////////////

    function HeaderService($q, Customer, HeaderFactory, Instance, LumsitesBaseService, Utils) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(HeaderFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The default header of the instance.
         *
         * @type {Object}
         */
        var _defaultHeader;

        /**
         * The promise of the default header initialization.
         *
         * @type {Promise}
         */
        var _defaultHeaderDeferred = $q.defer();

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The default parameters for the service requests.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /**
         * Contains various indicators about the state of the service.
         *
         * @type {Object}
         */
        service.is = {
            initialized: false,
            initializing: false,
        };

        /**
         * Indicates if the header is currently active while in designer mode.
         *
         * @type {boolean}
         */
        service.isActive = false;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the first header image's background-image url.
         *
         * @return {Object} An object of css properties.
         */
        function getBackgroundImageUrl() {
            if (angular.isUndefinedOrEmpty(_.get(service.getCurrent(), 'images'))) {
                return {};
            }

            var url = Utils.getMediaUrl('/serve/' + service.getCurrent().images[0]);

            return {
                'background-image': 'url(' + url + ')',
            };
        }

        /**
         * Get the default header.
         *
         * @return {Object} The default header object.
         */
        function getDefaultHeader() {
            _defaultHeader = _defaultHeader || {};
            _defaultHeader.name = _defaultHeader.name || 'default';

            return angular.fastCopy(_defaultHeader);
        }

        /**
         * Set the default header to a specific one.
         *
         * @param {Object} header The header object to set as the default header.
         */
        function setDefaultHeader(header) {
            if (angular.isUndefinedOrEmpty(header)) {
                return;
            }

            _defaultHeader = angular.fastCopy(header);
        }

        /////////////////////////////

        service.getBackgroundImageUrl = getBackgroundImageUrl;
        service.getDefaultHeader = getDefaultHeader;
        service.setDefaultHeader = setDefaultHeader;

        /////////////////////////////

        /**
         * Initialize the service.
         *
         * @return {Promise} The promise of the initialization.
         */
        service.init = function init() {
            service.defaultParams = {
                customer: Customer.getCustomerId(),
                instance: Instance.getCurrentInstanceId(),
            };

            if (service.is.initializing) {
                return _defaultHeaderDeferred.promise;
            }

            service.is.initializing = true;

            var instance = Instance.getInstance();

            if (angular.isDefinedAndFilled(_defaultHeader)) {
                _defaultHeaderDeferred.resolve(_defaultHeader);

                return _defaultHeaderDeferred.promise;
            }

            _defaultHeader = instance.header;
            if (angular.isDefinedAndFilled(_defaultHeader)) {
                _defaultHeaderDeferred.resolve(_defaultHeader);
            } else if (angular.isUndefinedOrEmpty(instance.defaultHeader)) {
                _defaultHeaderDeferred.resolve();
            } else {
                service.get(instance.defaultHeader, function onHeaderGetSuccess(header) {
                    _defaultHeader = header;

                    _defaultHeaderDeferred.resolve(_defaultHeader);
                }, _defaultHeaderDeferred.resolve);

                _defaultHeaderDeferred.promise.finally(function onDefaultHeaderInitializeFinally() {
                    service.is.initializing = false;
                    service.is.initialized = true;
                });
            }

            return _defaultHeaderDeferred.promise;
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Header', HeaderService);
})();
