import endsWith from 'lodash/endsWith';
import startsWith from 'lodash/startsWith';

import { FORBIDDEN_SLUG_CHARACTERS_REGEXP } from './constants';
import { doesUrlContainJavascript } from './doesUrlContainJavascript';

/**
 * RegExp matching URI strings.
 */
const URI_REGEXP_WITH_PROTOCOL =
    /^((([[A-Za-z][A-Za-z0-9.-]*:(?:\/\/)?)([-;:&=+$,\w]+@)?[A-Za-z0-9.-]*|([-;:&=+$,\w]+@)[A-Za-z0-9.-]*)\.?[+~%/.\w-_]+((\/[+~%/.\w-_]*)?\??([-+=&;%@.\w_]*)#?([\w]*))?)/;

const URI_REGEXP_WITHOUT_PROTOCOL =
    /^((([[A-Za-z][A-Za-z0-9.-]*:(?:\/\/)?){0}([-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|([-;:&=+$,\w]+@)[A-Za-z0-9.-]+)\.[+~%/.\w-_]+((\/[+~%/.\w-_]*)?\??([-+=&;%@.\w_]*)#?([\w]*))?)/;

const URI_REGEXP_RELATIVE = /^\/(?!\/)[a-zA-Z0-9-_/]+(\?[a-zA-Z0-9-._&=]+)?(#[a-zA-Z0-9-_/]+)?\/?$/;

/**
 * RegExp matching string starting with a protocol.
 */
const PROTOCOL_REGEXP = /^((([[A-Za-z][A-Za-z0-9.-]*)?:(?:\/\/)?)).+/;

/**
 * Returns `true` if the given string is a URL.
 */
export const isUrl = (string: string | null | undefined) =>
    Boolean(
        string &&
            (Boolean(string.match(URI_REGEXP_WITH_PROTOCOL)) || Boolean(string.match(URI_REGEXP_WITHOUT_PROTOCOL))) &&
            !doesUrlContainJavascript(string),
    );

export const isRelativeUrlValid = (string: string | null | undefined): boolean =>
    Boolean(
        string &&
            (Boolean(string.match(URI_REGEXP_WITH_PROTOCOL)) ||
                Boolean(string.match(URI_REGEXP_WITHOUT_PROTOCOL)) ||
                Boolean(string.match(URI_REGEXP_RELATIVE))) &&
            !doesUrlContainJavascript(string),
    );

/**
 * Returns `true` if the given string starts with a protocol pattern.
 * When using this util, you should check before if isUrl === true.
 */
export const hasProtocol = (string: string | null | undefined) =>
    string ? Boolean(string.match(PROTOCOL_REGEXP)) : false;

// prevent url xss => url startsWith http  => if no add http://+url
export const secureUrl = (string: string | null | undefined) =>
    string && !startsWith(string, 'http') ? `http://${string}` : string;

export const removeTrailingSlash = (string: string | null | undefined) =>
    string && endsWith(string, '/') ? string.substring(0, string.length - 1) : string;

export const isURLValid = (url: string): boolean => {
    return url ? isUrl(url) && hasProtocol(url) : false;
};

/**
 * Validate slug value. It should not conaint any forbidden characters and
 * it should not contain any javascript
 *
 * @param  {string} slug The slug
 * @param  {boolean} ignoreSlash Whether the first slash of the slug should be ignored
 * @return {boolean} If the slug is valid or not.
 */
export const isSlugValid = (slug: string, ignoreSlash = false) => {
    if (!slug || slug === '') {
        return false;
    }

    let slugToValidate = slug;

    if (ignoreSlash && slug[0] === '/') {
        slugToValidate = slugToValidate.replace('/', '');
    }

    return !slugToValidate.match(FORBIDDEN_SLUG_CHARACTERS_REGEXP) && !doesUrlContainJavascript(slugToValidate);
};

/**
 * Validate whether the provided string is either a slug or a full URL.
 *
 * @param  {string} slugOrUrl
 * @return {boolean} If the string is valid or not.
 */
export const isSlugOrURLValid = (slugOrUrl: string, ignoreSlash = false) => {
    return isSlugValid(slugOrUrl, ignoreSlash) || isURLValid(slugOrUrl);
};
