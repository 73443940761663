import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { Element } from '@lumapps/wrex/serialization/dita/types';

import type { LinkElement } from '../../types';

/** Convert slate link element to DITA xref. */
export const LINK_CONVERT: Converter<LinkElement> = (node, children) => {
    return {
        type: 'element',
        name: 'xref',
        attributes: {
            scope: 'external',
            /**
             * Encode url to fix unwell formatted href (like url with whitespace),
             * as saving an url with whitespace is incorrect and trigger an error
             */
            href: new URL(node?.href || '', window.location.origin).toString(),
        },
        children: children as Element[],
    };
};
