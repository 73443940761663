import last from 'lodash/last';

import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { Editor, Location } from '@lumapps/wrex/slate';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';

/**
 * Check if current selection allows insertion of mention.
 */
export function isMentionAllowed(editor: Editor, at: Location | null = editor.selection) {
    // Editor cursor should be available.
    if (!at) {
        return false;
    }
    try {
        // Get last element of the current selection.
        const [lastElement] =
            last(Array.from(Editor.nodes(editor, { at: Editor.end(editor, at), match: isElement, mode: 'lowest' }))) ||
            [];
        // Last element should be a paragraph (mentions are inserted at the end of the selection).
        return isParagraph(lastElement);
    } catch (ignored) {
        return false;
    }
}
