/* eslint-disable no-param-reassign */
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';

import { produce } from '@lumapps/redux/immer';

import {
    FETCH_INSTANCE_ERROR,
    FETCH_INSTANCE_LIST_ERROR,
    FETCH_INSTANCE_LIST_LOADING,
    FETCH_INSTANCE_LIST_SUCCESS,
    FETCH_INSTANCE_LOADING,
    FETCH_INSTANCE_SIBLINGS_ERROR,
    FETCH_INSTANCE_SIBLINGS_LOADING,
    FETCH_INSTANCE_SIBLINGS_SUCCESS,
    FETCH_INSTANCE_SUCCESS,
} from './actions';

let initialState = {
    entities: {},
    siblings: {},
    id: '',
    isLoading: false,
    isLoadingList: false,
    isLoadingSiblings: false,
    logo: {},
    parent: '',
    slug: '',
    defaultInstanceId: null,
};

/**
 * Set initial state from instance service (set at init).
 * @param {Object} state The initial state.
 */
function setInitialState(state) {
    initialState = state;
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case '__angular__/instance/update': {
                const { id, logo, parent, slug, entities } = action.newState;

                draft.id = id;
                draft.logo = logo;
                draft.parent = parent;
                draft.slug = slug;

                Object.assign(draft.entities, entities);

                return draft;
            }

            case FETCH_INSTANCE_LIST_LOADING: {
                draft.isLoadingList = true;

                return draft;
            }

            case FETCH_INSTANCE_LIST_SUCCESS: {
                const { instances } = action.payload;

                const defaultInstance = find(instances, 'isDefaultInstance');
                if (defaultInstance) {
                    draft.defaultInstanceId = defaultInstance.id;
                }

                draft.entities = { ...draft.entities, ...keyBy(instances, 'uid') };
                draft.isLoadingList = false;

                return draft;
            }

            case FETCH_INSTANCE_LIST_ERROR: {
                draft.isLoadingList = false;

                return draft;
            }

            case FETCH_INSTANCE_LOADING: {
                draft.isLoading = true;

                return draft;
            }

            case FETCH_INSTANCE_SUCCESS: {
                const instance = action.payload;

                if (!draft.entities) {
                    draft.entities = {};
                }

                draft.entities[instance.id] = instance;

                if (instance.isDefaultInstance) {
                    draft.defaultInstanceId = instance.id;
                }
                draft.isLoading = false;

                return draft;
            }

            case FETCH_INSTANCE_ERROR: {
                draft.isLoading = false;

                return draft;
            }

            case FETCH_INSTANCE_SIBLINGS_LOADING: {
                draft.isLoadingSiblings = true;

                return draft;
            }

            case FETCH_INSTANCE_SIBLINGS_SUCCESS: {
                const { instances } = action.payload;
                const siblings = keyBy(instances, 'uid');

                const defaultInstance = find(instances, 'isDefaultInstance');

                if (defaultInstance) {
                    draft.defaultInstanceId = defaultInstance.id;
                }

                // we add siblings to the list of known entities
                draft.entities = { ...draft.entities, ...siblings };

                // and we also create/replace their own list
                draft.siblings = siblings;

                draft.isLoadingSiblings = false;

                return draft;
            }

            case FETCH_INSTANCE_SIBLINGS_ERROR: {
                draft.isLoadingSiblings = false;

                return draft;
            }

            default:
                return draft;
        }
    });
};

export { reducer, setInitialState };
