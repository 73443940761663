import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { ActionMenu } from '@lumapps/lumx-menu/components/ActionMenu';
import { mdiCheck, mdiCircle, mdiDeleteForever, mdiDotsVertical, mdiUndoVariant } from '@lumapps/lumx/icons';
import { ColorPalette, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { DATA_NAMESPACE } from '../../constants';

export interface NotificationContextMenuProps {
    /** The classname to set on the button  */
    className?: string;
    /** The id of the notification to manage  */
    notificationId: string;
    /** Whether the notification has already been read  */
    isRead?: boolean;
    /** Whether the icon should be replaced by a circle  */
    showCircle?: boolean;
    /** Callback action when marking a notification as read / unread  */
    onMarkAs: (status: boolean) => void;
    /** Callback action when deleting a notification  */
    onDelete: () => void;
}

export const NotificationContextMenu: React.FC<NotificationContextMenuProps> = ({
    className,
    showCircle,
    isRead,
    notificationId,
    onMarkAs,
    onDelete,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(DATA_NAMESPACE);

    const notificationIconProps = React.useMemo(
        () =>
            showCircle
                ? {
                      icon: mdiCircle,
                      color: ColorPalette.primary,
                      size: Size.s,
                      style: {
                          margin: '6px',
                      },
                  }
                : {
                      icon: mdiDotsVertical,
                  },
        [showCircle],
    );

    const menuOptions = React.useMemo(() => {
        const markAsOption = isRead
            ? {
                  labelKey: 'NOTIFICATIONS.MARK_AS_UNREAD',
                  icon: mdiUndoVariant,
                  onSelect: onMarkAs(false),
                  otherProps: { ...get({ element: 'mark-as-unread', type: notificationId }) },
                  type: 'option',
              }
            : {
                  labelKey: 'NOTIFICATIONS.MARK_AS_READ',
                  icon: mdiCheck,
                  onSelect: onMarkAs(true),
                  otherProps: { ...get({ element: 'mark-as-read', type: notificationId }) },
                  type: 'option',
              };

        return [
            markAsOption,
            {
                labelKey: GLOBAL.DELETE,
                icon: mdiDeleteForever,
                onSelect: onDelete,
                otherProps: { ...get({ element: 'delete', type: notificationId }), color: ColorPalette.red },
                type: 'option',
            },
        ];
    }, [isRead, onMarkAs, get, notificationId, onDelete]);

    return (
        <ActionMenu
            className={className}
            label={translateKey(GLOBAL.OPTIONS)}
            {...notificationIconProps}
            {...get({ element: 'context-menu-button', action: 'more' })}
        >
            {menuOptions.map((option) => (
                <ActionMenu.Item
                    key={option.labelKey}
                    onClick={option.onSelect}
                    icon={option.icon}
                    {...option.otherProps}
                >
                    {translateKey(option.labelKey)}
                </ActionMenu.Item>
            ))}
        </ActionMenu>
    );
};
