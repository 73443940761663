import React from 'react';

import { ManagedStreamResources } from '../../../types';
import { ArticleStreamItem } from './ArticleStreamItem';
import { EventStreamItem } from './EventStreamItem';
import { PlayVideoStreamItem } from './PlayVideoStreamItem';
import { PostStreamItem } from './PostStreamItem';

export const streamResourceBlockByResourceType: {
    [Resource in ManagedStreamResources]?: React.FC<any>;
} = {
    event: EventStreamItem,
    post: PostStreamItem,
    article: ArticleStreamItem,
    play_video: PlayVideoStreamItem,
};
