import { TranslationAPI } from '@lumapps/translations';

import { MainTopNavigationItemProps } from '../types';

/**
 * These are several indexes that we keep in order to quickly access navigation items without recursively going through
 * them. We have navigation items by ID, navigation items by Slug and navigation items grouped by repetition.
 * - navigationItemsById: has navigation items by their id. Usually only contents
 * - repeatedNavigationItemsById: has navigation items that are on multiple branches at the same time. They are grouped by ID and then by URL
 * - navigationItemsByURL: has navigation items by their url.
 */
const navigationItemsById: Record<string, MainTopNavigationItemProps> = {};
const repeatedNavigationItemsById: Record<string, Record<string, MainTopNavigationItemProps>> = {};
const navigationItemsByURL: Record<string, MainTopNavigationItemProps> = {};

/**
 * Indexes the navigation item in order to be used by other functionalities such as the breadcrumb widget.
 * @param navigationItemProps - navigation item
 * @param translate - translations API
 */
const saveNavigationItem = (
    navigationItemProps: MainTopNavigationItemProps,
    translate: TranslationAPI['translate'],
) => {
    const { slug, uid } = navigationItemProps;

    /**
     * If the item has an ID, it means that it is a content.
     */
    if (uid) {
        /**
         * Contents might by under several navigation branches, so we need to determine whether we have already
         * added that item to the index or not. If we haven't, we just add it to the index.
         */
        if (!navigationItemsById[uid]) {
            navigationItemsById[uid] = navigationItemProps;
        } else if (slug) {
            /**
             * If it was already added, we need to keep track of these different branches. We then use the repeatedNavigationItemsById
             * object in order to index these items by ID and then by Slug.
             */
            const translatedSlug = translate(navigationItemProps.slug) as string;

            if (repeatedNavigationItemsById[uid]) {
                repeatedNavigationItemsById[uid][translatedSlug] = navigationItemProps;
            } else {
                const previouslyCreatedNode = navigationItemsById[uid];
                const previouslyCreatedTranslatedSlug = translate(previouslyCreatedNode.slug) as string;

                repeatedNavigationItemsById[uid] = {
                    [translatedSlug]: navigationItemProps,
                    [previouslyCreatedTranslatedSlug]: previouslyCreatedNode,
                };
            }
        }
    }

    /**
     * Finally, we also index items by slug. Here we can have either menus or content. In that case,
     * we just translate the slug, retrieve the last part (since navigation items have a full slug with all the levels)
     * and we add it to another separate index.
     */
    if (slug) {
        const translatedSlug = translate(navigationItemProps.slug) as string;

        if (translatedSlug) {
            const tokens = translatedSlug.split('/');
            navigationItemsByURL[tokens[tokens.length - 1]] = navigationItemProps;
        }
    }
};

/**
 * Returns the navigation item given a slug
 * @param id - current content id
 * @param currentSlug - given slug (if undefined, this returns undefined)
 * @returns a navigation item if found by slug, or undefined
 */
const getNavigationItemBySlug = (id: string, currentSlug?: string) => {
    /**
     * indexes do not include the first slash so if the slug contains it, we remove it.
     */
    const slug = currentSlug && currentSlug.indexOf('/') === 0 ? currentSlug.substring(1) : currentSlug;

    /**
     * If the slug defined in the dictionary of navigation node by id is the same as the one we're looking for, then
     * that means the slug is valid and we can return this navigation node
     */
    if (slug && navigationItemsById[id]?.url === slug) {
        return navigationItemsById[id];
    }

    /**
     * Else if there is a slug provided and the provided id is of a repeated navigation item, we use the slug
     * to determine the correct navigation item.
     */
    if (slug && repeatedNavigationItemsById[id] && repeatedNavigationItemsById[id][slug]) {
        return repeatedNavigationItemsById[id][slug];
    }

    /** Else no node has been found, return undefined */
    return undefined;
};

/**
 * Returns a navigation item from a given id. If you do not want to retrieve the first time the navigation
 * item is present on the navigation, you can pass in a URL in order to determine the correct navigation item.
 * You can also get a navigationItem if the given slug is valid
 * @param id - navigation item id
 * @param currentSlug - navigation item slug
 * @returns navigation item
 */
const getNavigationItemByContentId = (id: string, currentSlug?: string) => {
    return getNavigationItemBySlug(id, currentSlug) || navigationItemsById[id];
};

/**
 * Retrieves the branch for a given node.
 * @param id - node id.
 * @param displayHiddenNodes - whether in the branch we should include hidden navigation items
 * @returns array representing the branch for the given content.
 */
const getBranchForNode = (id: string, currentSlug: string, displayHiddenNodes = true) => {
    const node = getNavigationItemByContentId(id, currentSlug);
    const branch = [];

    /**
     * From the object that has each navigation item by id, we retrieve the node corresponding to the provided
     * id and we then retrieve the URL.
     */
    if (node) {
        const { url } = node;

        /**
         * With the URL, we split it by / and retrieve each token. Each of these tokens should be a different level
         * in the navigation, therefore a different node.
         */
        if (url) {
            const urlTokens = url.split('/') || [];

            /**
             * For each token we look for the navigation item
             * that corresponds to that given token in the object that has each navigation item by slug. If we find an item
             * and that item is visible (or the widget allows to display hidden nodes), we add it to the branch.
             */
            for (let i = 0; i < urlTokens.length; i++) {
                const token = urlTokens[i];
                const navigationItemForUrl = navigationItemsByURL[token];

                if (navigationItemForUrl) {
                    if (displayHiddenNodes || navigationItemForUrl.isVisible) {
                        branch.push(navigationItemsByURL[token]);
                    }
                }
            }
        }
    }

    return branch;
};

/**
 * The following object and functions are used from the Customizations API to retrieve the navigation
 * items. Customers will retrieve the navigation so that they can code their own navigation on their end.
 * Since the navigation comes from an API, we need to return a promise rather than hard data.
 */
const entireNavigation: Record<string, MainTopNavigationItemProps[]> = {};

/**
 * Promise that will be resolved when the current site's API is finished and
 * a getter and a resolver for this callback.
 */
let navigationPromiseResolve: (nav: MainTopNavigationItemProps[]) => void;
const navigationPromise = new Promise((resolve) => {
    navigationPromiseResolve = resolve;
});

const getNavigationPromise = () => {
    /** if we already have the data, just do a Promise.resolve */
    return entireNavigation.current ? Promise.resolve(entireNavigation.current) : navigationPromise;
};

const resolveNavigationPromise = (navigation: MainTopNavigationItemProps[]) => {
    /**
     * Resolves the promise and saves the data for later use.
     */
    navigationPromiseResolve(navigation);
    entireNavigation.current = navigation;
};

let parentNavigationPromiseResolve: (nav: MainTopNavigationItemProps[]) => void;
const parentNavigationPromise = new Promise((resolve) => {
    parentNavigationPromiseResolve = resolve;
});

const getParentNavigationPromise = () => {
    /** if we already have the data, just do a Promise.resolve */
    return entireNavigation.parent ? Promise.resolve(entireNavigation.parent) : parentNavigationPromise;
};

const resolveParentNavigationPromise = (navigation: MainTopNavigationItemProps[]) => {
    /**
     * Resolves the promise and saves the data for later use.
     */
    parentNavigationPromiseResolve(navigation);
    entireNavigation.parent = navigation;
};

export {
    getNavigationItemBySlug,
    getNavigationItemByContentId,
    getBranchForNode,
    saveNavigationItem,
    resolveNavigationPromise,
    getNavigationPromise,
    getParentNavigationPromise,
    resolveParentNavigationPromise,
};
