import first from 'lodash/first';

import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { isParagraph } from '@lumapps/wrex-typography/utils/isParagraph';
import { Editor, Path, ReactEditor, Transforms } from '@lumapps/wrex/slate';

import { TableEditor } from '../types';

export const deleteContent = (editor: ReactEditor & TableEditor) => {
    const selection = editor.getCellSelection();

    selection.forEach((cellPath: number[]) => {
        Editor.withoutNormalizing(editor, () => {
            Transforms.removeNodes(editor, {
                at: [...cellPath],
                match: isParagraph,
            });
            Transforms.insertNodes(editor, createParagraph(), {
                at: [...cellPath, 0],
            });
        });
    });

    Transforms.select(editor, {
        anchor: {
            offset: 0,
            path: [...(first(selection) as Path), 0, 0],
        },
        focus: {
            offset: 0,
            path: [...(first(selection) as Path), 0, 0],
        },
    });

    return [...(first(selection) as Path), 0, 0];
};
