import { connect } from '@lumapps/redux/react';

import { openModal, setSourceLang } from './_ducks/widget_translation_action';

import { WidgetTranslationSettings } from './WidgetTranslationSettings';

const mapStateToProps = (state) => {
    return {
        inputLanguage: state.locale.inputLanguage,
        widget: state.legacyWidget.currentWidget,
    };
};

const mapDispatchToProps = {
    openModal,
    setSourceLang,
};

const connectedWidgetTranslationSettings = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WidgetTranslationSettings);

/////////////////////////////

export { connectedWidgetTranslationSettings as WidgetTranslationSettings };
