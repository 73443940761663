/* eslint-disable no-magic-numbers */
/**
 * This holds objects that are common to multiple widgets.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const WidgetSettingsConstant = {
    /**
     * The delay for debounced functions.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    DEBOUNCE_DELAY: 500,

    /**
     * Max number of items to display.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    MAX_NUMBER_TO_DISPLAY: 10,

    /**
     * Enum of order by directions.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    ORDER_DIRECTION: {
        ASCENDING: 'asc',
        DESCENDING: 'desc',
    },

    /**
     * Enum of list item selection types.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    SELECTION_TYPES: {
        COMMUNITY_FOLDER: 'community-folder',
        CURRENT_COMMUNITY: 'current_community',
        LIST: 'list',
        PICK: 'pick',
    },
};

/////////////////////////////

angular.module('Constants').constant('WidgetSettingsConstant', WidgetSettingsConstant);

/////////////////////////////

export { WidgetSettingsConstant };
