import React, { Children, Fragment } from 'react';

import { LoadMoreFocusHelper } from '@lumapps/a11y/components/LoadMoreFocusHelper';
import { useClassnames, visuallyHidden } from '@lumapps/classnames';
import { Masonry } from '@lumapps/lumx-layouts/components/Masonry';
import { Alignment, Button, Emphasis, FlexBox, Orientation, ProgressCircular, Size, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { WIDGET_BASE } from '../../../keys';
import { BlockComponent, BlockCascade as BlockCascadeProps } from '../../../types';
import { useColumns } from '../../../utils/useColumns';

import './index.scss';

const CLASSNAME = 'block-cascade';
const DEFAULT_COLUMNS = 1;

export const BlockCascade: BlockComponent<BlockCascadeProps> = ({
    children,
    columns = DEFAULT_COLUMNS,
    contentStyles,
    onLoadMore,
    loadingState,
    loadingStateRenderer: LoadingStateRenderer = ProgressCircular,
    maxItemsPerPage,
}) => {
    const { columns: columnsToUse, ref } = useColumns({ columns, children });
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);

    return (
        <>
            <Masonry ref={ref} className={block()} columns={columnsToUse} gutterSize={Size.huge}>
                {Children.map(children, (child, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={i}>
                        <LoadMoreFocusHelper
                            itemIndex={i}
                            itemsDisplayedLength={Children.toArray(children).length}
                            itemsPerPage={maxItemsPerPage}
                        />
                        <div className={element('item')} style={contentStyles}>
                            {child}
                        </div>
                    </Fragment>
                ))}
            </Masonry>
            {onLoadMore && loadingState === 'loaded' && (
                <Button className={element('load-more')} emphasis={Emphasis.medium} onClick={onLoadMore}>
                    {translateKey(WIDGET_BASE.LOAD_MORE)}
                </Button>
            )}
            {loadingState === 'loadingmore' && (
                <FlexBox orientation={Orientation.vertical} vAlign={Alignment.center} role="alert" aria-live="polite">
                    <Text as="p" className={visuallyHidden()}>
                        {translateKey(GLOBAL.LOADING)}
                    </Text>
                    <LoadingStateRenderer />
                </FlexBox>
            )}
        </>
    );
};

BlockCascade.displayName = 'BlockCascade';
