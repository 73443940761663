/* istanbul ignore file */
import BaseApi, { ServerListResponse } from '@lumapps/base-api';

import { LegacyCalendarEvent, ListEventParams } from '../types';

const PATH = 'calendar';

export const calendarApi = new BaseApi({
    path: PATH,
});

export const eventsQueryKeys = {
    all: () => [PATH] as const,
    list: (params: ListEventParams[]) => [...eventsQueryKeys.all(), 'list', ...params] as const,
};

export const listEvents = (params: ListEventParams) =>
    calendarApi.get<ServerListResponse<LegacyCalendarEvent>>('/event/list', { params });

export const fetchCalendarEvents = async (params: ListEventParams) => {
    const events = await listEvents({ ...params, callId: params.calendarId });

    return events.data as ServerListResponse<LegacyCalendarEvent>;
};

/**
 * Fetch events in bulk for each calendar's specified.
 * We're using the `Promise.allSettled` in order to combine the request instead of a Promise.all.
 * This is because the calls will frequently fail, since calendar's are restricted based on the user rights and provider.
 * */
export const fetchCalendarsEventsMulti = async (fetchParams: ListEventParams[]) => {
    return Promise.allSettled(fetchParams.map((params) => fetchCalendarEvents(params)));
};
