import { Media } from '@lumapps/medias/types';

export interface Image {
    alt?: string;
    url?: string;
    blobUrl?: string;
    file?: File;
    name?: string;
    id?: string;
    rawMedia?: Media;
    originalImage?: Image;
    width?: number;
    height?: number;
}

export enum UPLOAD_IMAGE_OPTIONS {
    FROM_COMPUTER = 'upload-from-computer',
    /** WARNING: Only works on legacy context */
    FROM_MEDIA_LIBRARY = 'pick-from-media-library',
    WITH_AI = 'generate-with-ai',
}

export interface UploadImageOption {
    type: UPLOAD_IMAGE_OPTIONS;
    /** Callback when an image have been selected
     * - image can be a picked File or an url
     * - Original picture is used for cropper dialog to keep modifications visible
     */
    onConfirm: (image: File, originalPicture?: { url: string; file?: File }) => void;
}
