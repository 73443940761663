(function IIFE() {
    'use strict';

    /////////////////////////////

    function InstanceSiblingsSelectorController($scope, Instance, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Debounce time needed to refresh lxSelect.
         *
         * @type {number}
         */
        var _debounceTime = 0;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The list of instances siblings.
         *
         * @type {Array}
         */
        vm.instancesSiblings = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Instance = Instance;
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Called when the model of the LxSelect is updated.
         */
        function _selectCallback() {
            if (angular.isFunction(vm.selectCallback)) {
                vm.selectCallback()(vm.ngModel);
            }
        }
        var _debounceSelectCallback = _.debounce(_selectCallback, _debounceTime);

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Reset ngModel to fit with the instance configuration.
         */
        function resetModel() {
            vm.ngModel = [Instance.getCurrentInstanceId()];
            vm.updateModel();

            $scope.$emit('reset-instance-siblings-selector');
        }

        /**
         * Call debounce method on ngChange to update model.
         */
        function updateModel() {
            _debounceSelectCallback();
        }

        /////////////////////////////

        vm.resetModel = resetModel;
        vm.updateModel = updateModel;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            Instance.getSiblings(false).then(function onSiblingsListSuccess(siblings) {
                vm.instancesSiblings = siblings;
            });

            if (angular.isDefinedAndFilled(vm.debounce)) {
                var debounceValue = parseInt(vm.debounce, 10);
                _debounceTime = (angular.isNumber(debounceValue)) ? debounceValue : _debounceTime;
            }
        }

        init();
    }

    /////////////////////////////

    /**
     * The instance siblings selector.
     * Allow the user to pick instances that are siblings of the current instances.
     *
     * @param {number}   [debounce=0]       Indicates if we want to debounce the update of the model.
     * @param {number}   [hideLoader=false] Indicates if we want to hide the loader while the siblings are loading.
     * @param {Array}    ngModel            The model to store the selected instances in.
     * @param {Function} [change]           A function to execute (eventually debounced) each time the model is updated.
     * @param {Function} [error]            A function to execute to check if there is an error.
     * @param {string}   [placeholder]      The placeholder to display inside of the LxSelect of the picker.
     */

    function instanceSiblingsSelectorDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: InstanceSiblingsSelectorController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                debounce: '@lsDebounce',
                hideLoader: '<lsHideLoader',
                max: '<?lsMax',
                ngModel: '=',
                selectCallback: '&lsChange',
                selectError: '&lsError',
                selectPlaceholder: '@lsPlaceholder',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/common/modules/instance-siblings-selector/views/instance-siblings-selector.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsInstanceSiblingsSelector', instanceSiblingsSelectorDirective);
})();
