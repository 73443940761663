import React, { PureComponent } from 'react';

import LegacyAppBlockRemote from '@lumapps/marketplace/components/LegacyAppBlockRemote';
import { ComponentTypes } from '@lumapps/marketplace/types';

import { isDesignerMode } from '../../../utils';

/**
 * Display the remote widget content.
 */
export class WidgetRemote extends PureComponent {
    /**
     * Defines this widget as not editable.
     *
     * @return {boolean} False.
     */
    static isEditable() {
        return true;
    }

    /**
     * Defines whether the widget is empty or not.
     *
     * @param  {Array}   value The widget properties.
     * @return {boolean} Whether the list of channel is empty or not.
     */
    static isWidgetEmpty({ extensionEnabled }: any = {}) {
        return !extensionEnabled;
    }

    /**
     * Determines whether the widget is hidden or not.
     *
     * @param  {Object}  params The widget props.
     * @return {boolean} Whether the widget is hidden or not.
     */
    static isWidgetHidden(params: any) {
        return WidgetRemote.isWidgetEmpty(params);
    }

    render() {
        const { globalValue, properties = {}, theme, contributionMode, uuid, widgetRef } = this.props as any;
        // Removes other properties from value
        const { isCollapsible, more, remoteExtension, style, stylesMigrated, ...value } = properties;
        const { id: extensionId } = remoteExtension;
        const designerMode = isDesignerMode() as unknown as boolean;

        return (
            <div className="remote-component">
                {extensionId && (
                    <LegacyAppBlockRemote
                        componentType={ComponentTypes.content}
                        contributionMode={contributionMode}
                        extensionId={extensionId}
                        extensionProperties={value}
                        globalProperties={globalValue}
                        theme={theme}
                        uuid={uuid}
                        widgetRef={widgetRef}
                    />
                )}

                {designerMode && properties.needMaintenance && <p>Need maintenance</p>}
            </div>
        );
    }
}
