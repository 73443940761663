/* eslint-disable */
import first from 'lodash/first';
import includes from 'lodash/includes';
import union from 'lodash/union';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function RichTextEditorController(
    $compile,
    $document,
    $element,
    $filter,
    $rootScope,
    $scope,
    $timeout,
    LxDropdownService,
    LsTextEditor,
    LsTextEditorMarkdown,
    User,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const $editor = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The delay to trigger the autogrow of the input field.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _AUTOGROW_DELAY = 500;

    /**
     * The list of key codes to use with the key events.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _KEY_CODES = {
        /* eslint-disable id-length, no-magic-numbers */
        b: 66,
        down: 40,
        enter: 13,
        escape: 27,
        i: 73,
        left: 37,
        right: 39,
        tab: 9,
        up: 38,
        /* eslint-enable id-length, no-magic-numbers */
    };

    /**
     * An array of key codes we use to cancel the search or close the dropdown of the mention.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _CANCEL_KEY_CODES = [_KEY_CODES.escape];

    /**
     * The delay to open the mention dropdown.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _OPEN_MENTION_DROPDOWN_DELAY = 250;

    /**
     * Contains the timeout cancelling function for opening the mention dropdown.
     *
     * @type {Function}
     */
    let _cancelMentionDropdown;

    /**
     * An array of characters that trigger the mention functionality.
     *
     * @type {Array}
     */
    const _identifierChar = LsTextEditor.defaultIdentifierChar;

    /**
     * The original model object that hold the value with the mentions.
     *
     * @type {Object}
     */
    let _modelController;

    /**
     * An array of event types that trigger the search.
     *
     * @type {Array}
     */
    const _searchTriggerEvent = ['keyup', 'click', 'focus'];

    /**
     * A temporary element used for parsing content.
     *
     * @type {HTMLElement}
     */
    const _tempParser = $document[0].createElement('textarea');

    /**
     * Markdown default settings.
     *
     * @type {Object}
     */
    const _markdownDefaultSettings = {
        displayStyleButton: true,
        displayViewSwitch: true,
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The index of the current active choice in the mention dropdown.
     *
     * @type {number}
     */
    $editor.activeChoiceIndex = 0;

    /**
     * An array holding the mention results.
     *
     * @type {Array}
     */
    $editor.choices = [];

    /**
     * The mentions dropdown id.
     *
     * @type {number}
     */
    $editor.dropDownIdentifier = generateUUID();

    /**
     *
     * @type {string}
     */
    $editor.beaconIdentifier = `b-${generateUUID()}`;

    /**
     * Indicates if the mention dropdown is open or not.
     *
     * @type {boolean}
     */
    $editor.dropDownOpen = false;

    /**
     * Indicates if the mention results are loading.
     *
     * @type {boolean}
     */
    $editor.isLoading = false;

    /**
     * Whether the current active choice has just been moved up or down or not at all.
     *
     * @type {boolean}
     */
    $editor.moved = false;

    /**
     * The pattern to capture a mention.
     *
     * @type {RegExp}
     */
    $editor.pattern = new RegExp(`(?:\\s|^)([${_identifierChar.join('|')}])(\\S+)$`);

    /**
     * The element holding the HTML of the text editor.
     *
     * @type {HTMLElement}
     */
    $editor.renderTarget = $element.find('.ls-rich-text-editor__view');

    /**
     * The results that match our pattern search.
     *
     * @type {Array}
     */
    $editor.searching = undefined;

    /**
     * A model object used to hold the temporary text editor value while it's being edited.
     *
     * @type {Object}
     */
    $editor.tempModel = '';

    /**
     * The original textarea element being used as a rich text editor.
     *
     * @type {HTMLElement}
     */
    $editor.textAreaElement = $element.find('.ls-rich-text-editor__textarea');

    /////////////////////////////

    /**
     * Services and utilities.
     */
    $editor.LsTextEditor = LsTextEditor;
    $editor.User = User;

    /**
     * The current selected/applied style (at caret position).
     *
     * @type {Object}
     */
    $editor.actionSelected = {
        bold: false,
        bulletList: false,
        image: false,
        italic: false,
        link: false,
    };

    /**
     * Current editor mode.
     *
     * @type {string}
     */
    $editor.currentMode = LsTextEditor.availableModes.write;

    /**
     * Markdown options.
     *
     * @type {Object}
     */
    $editor.editorSettings = angular.fastCopy(_markdownDefaultSettings);

    /////////////////////////////

    /**
     * Services and utilities.
     */
    $editor.LsTextEditorMarkdown = LsTextEditorMarkdown;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Open up some of the directive action.
     */
    function _bindExternalControl() {
        if (angular.isDefined($editor.externalControls)) {
            // Bind controls.
            $editor.externalControl = $editor.externalControls;

            // Open control functions.
            $editor.externalControl.triggerAction = $editor.triggerAction;
            $editor.externalControl.changeHeading = $editor.changeHeading;
            $editor.externalControl.switchMode = $editor.switchMode;
            $editor.externalControl.getCurrentMode = $editor.getCurrentMode;
            $editor.externalControl.textAreaElement = $editor.textAreaElement;
        }
    }

    /**
     * Decode encoded special char.
     * Currently only (& char).
     *
     * @param  {string} str The string to decode.
     * @return {string} The decoded string.
     */
    function _decodeSpecialChar(str) {
        if (angular.isString(str)) {
            return str.replace(/&amp;/g, '&');
        }

        return str;
    }

    /**
     * Add special mentions choices to the original backend list of results.
     *
     * @param  {Array}  originalChoices The list of choices returned by the backend search for our query.
     * @param  {string} query           The original query typed in by the user.
     * @return {Array}  A new array array of choices.
     */
    function _addSpecialMentionsChoices(originalChoices, query) {
        const choicesWithSpecialMentions = originalChoices.slice(0);

        angular.forEach($editor.specialMentions, (specialMentionKeywords, specialMentionFeedKey) => {
            angular.forEach(specialMentionKeywords, (keyword) => {
                // Is our query part of some of our keywords?
                if (keyword.indexOf(query) !== 0) {
                    return;
                }

                /*
                 * We create a mocked object that can be used within a user-block and displayed in the
                 * choice capitalize the first letter of the keyword for display purposes.
                 */
                choicesWithSpecialMentions.unshift({
                    id: specialMentionFeedKey,
                    name: keyword.charAt(0).toUpperCase() + keyword.slice(1),
                    type: 'feed',
                });
            });
        });

        return choicesWithSpecialMentions;
    }

    /**
     * Function added in model $parsers.
     * Called whenever the model value changes.
     * What the real model value will be.
     *
     * @param  {string} value The textarea value.
     * @return {string} The modified textarea value.
     */
    function _buildParser(value) {
        // HTML tags as string.
        let html = $editor.parseContentAsText(value);

        const filterInlineObjects = (objects) =>
            objects.filter((object) => {
                let keepObject = false;
                const label = LsTextEditor.label(object);
                const encoded = LsTextEditor.encode(object);
                while (includes(html, label)) {
                    html = html.replace(label, encoded);
                    keepObject = true;
                }

                return keepObject;
            });

        $editor.userMentioned = filterInlineObjects($editor.userMentioned);

        $editor.feedMentioned = filterInlineObjects($editor.feedMentioned);

        $editor.inlineImages = filterInlineObjects($editor.inlineImages);

        $editor.render(html);

        return $editor.parseTextAsContent(html);
    }

    /**
     * Function added in model $formatters called whenever the DOM value changes.
     * Updates the mention list and formats the mentions (what gets actually displayed on screen).
     * What the view value of the model is.
     *
     * @param  {string} [value=''] The whole textarea value.
     * @return {string} The updated value with replaced user and feed mentions.
     */
    function _buildFormatter(value) {
        // Default value and cast as string in case of different type.
        value = _decodeSpecialChar((value || '').toString());

        const filterInlineObjects = (objects) =>
            objects.filter((object) => {
                let keepObject = false;
                const encoded = LsTextEditor.encode(object);
                const label = LsTextEditor.label(object);
                while (includes(value, encoded)) {
                    value = value.replace(encoded, label);
                    keepObject = true;
                }

                return keepObject;
            });

        // Remove unused mentions and replace encoded values by labels.
        $editor.userMentioned = filterInlineObjects($editor.userMentioned);

        // Remove unused mentions and replace encoded values by labels.
        $editor.feedMentioned = filterInlineObjects($editor.feedMentioned);

        // Remove unused inline images.
        $editor.inlineImages = filterInlineObjects($editor.inlineImages);

        // Replace encode not found, match[2] is the user Id we could try to fetch it and if not found remove it.
        let match = LsTextEditor.encodedMentionPattern.exec(value);
        while (match !== null) {
            if (match.index === LsTextEditor.encodedMentionPattern.lastIndex) {
                LsTextEditor.encodedMentionPattern.lastIndex++;
            }

            value = value.replace(match[0], match[1]);

            match = LsTextEditor.encodedMentionPattern.exec(value);
        }

        // Update textarea value.
        $editor.tempModel = value;

        return value;
    }

    /**
     * Close the mention choices drop down.
     */
    function _closeDropDown() {
        if ($editor.dropDownOpen) {
            LxDropdownService.close($editor.dropDownIdentifier);
        }

        $editor.dropDownOpen = false;
    }

    /**
     * Update the real model.
     *
     * @param {string} text The text of the temporary model to set in the real model.
     */
    function _updateModel(text) {
        $rootScope.$safeApply(() => {
            _modelController.$setViewValue(text);
        });
    }

    /**
     * Add a 300ms debounce to the _updateModal method to improve performance.
     */
    const _debouncedUpdateModel= _.debounce(_updateModel, 300);

    /**
     * Trigger all the search events over the textarea.
     *
     * @param {Event} evt The event.
     */
    function _triggerSearch(evt) {
        // When a key is cancel (ESCape for example), watcher is trigger so return nothing.
        if (includes(_CANCEL_KEY_CODES, evt.keyCode)) {
            return;
        }

        // If event is fired AFTER activeChoice move is performed.
        if ($editor.moved) {
            $editor.moved = false;

            return;
        }

        // Don't trigger on selection.
        if ($editor.textAreaElement[0].selectionStart !== $editor.textAreaElement[0].selectionEnd) {
            return;
        }

        const text = $editor.textAreaElement.val();

        _debouncedUpdateModel(text);

        // Text to left of cursor ends with `@sometext`.
        const match = $editor.pattern.exec(text.substr(0, $editor.textAreaElement[0].selectionStart));
        if (match) {
            $editor.search(match);
        } else {
            $editor.cancelSelection();
        }
    }

    /**
     * Force the origin model to catch up to the model value.
     */
    function _forceOriginModelUpdate() {
        $editor.ngModel = _buildParser(_modelController.$viewValue);
    }

    /**
     * Select the next available mention.
     * If we were at the last choice, select the first.
     */
    function _keyDown() {
        if (!$editor.dropDownOpen || angular.isUndefinedOrEmpty($editor.choices)) {
            return;
        }

        $editor.activeChoiceIndex += 1;

        if ($editor.activeChoiceIndex >= $editor.choices.length) {
            $editor.activeChoiceIndex = 0;
        }
    }

    /**
     * Validate the currently selected mention choice.
     */
    function _keySelect() {
        if (!$editor.dropDownOpen || angular.isUndefinedOrEmpty($editor.choices)) {
            return;
        }

        $editor.select($editor.choices[$editor.activeChoiceIndex]);
    }

    /**
     * Select the previous available mention.
     * If we were at the top, select the last choice.
     */
    function _keyUp() {
        if (!$editor.dropDownOpen || angular.isUndefinedOrEmpty($editor.choices)) {
            return;
        }

        $editor.activeChoiceIndex -= 1;

        if ($editor.activeChoiceIndex < 0) {
            $editor.activeChoiceIndex = $editor.choices.length - 1;
        }
    }

    /**
     * Open mention choices drop down at the right position.
     * Wrap pending mention into a span beacon to get position (then remove beacon).
     */
    function _openDropDown() {
        if (angular.isFunction(_cancelMentionDropdown)) {
            _cancelMentionDropdown();
            _cancelMentionDropdown = undefined;
        }

        if ($editor.dropDownOpen) {
            return;
        }
        const beacon = angular.element(`#${$editor.beaconIdentifier}`);

        if (angular.isDefinedAndFilled(beacon)) {
            // Wait HTML rendering.
            _cancelMentionDropdown = $timeout(() => {
                // Look for Dropdown and open it / set position.
                LxDropdownService.open($editor.dropDownIdentifier, `#${$editor.beaconIdentifier}`);

                $editor.dropDownOpen = true;
            }, _OPEN_MENTION_DROPDOWN_DELAY);
        }
    }

    /**
     * Called when there is no mention result or error on search.
     */
    function _rejectSearch() {
        $editor.cancelSelection();
        _forceOriginModelUpdate();
        $editor.isLoading = false;
    }

    /**
     * Perform a markdown action.
     *
     * @param {Object} config The action configuration.
     */
    function _executeMarkdownAction(config) {
        if (!$editor.markdown) {
            return;
        }

        const textarea = first($editor.textAreaElement);

        const hasSelection = textarea.selectionStart !== textarea.selectionEnd;
        // When nothing selected, select element if needed.
        if (!hasSelection && !config.newLine) {
            LsTextEditorMarkdown.selectCloseElement(textarea);
        }

        if (config.prefixLine) {
            LsTextEditorMarkdown.selectLine(textarea);
            LsTextEditorMarkdown.prefixLine(textarea, config);
        } else if ((!config.newLine || hasSelection) && LsTextEditorMarkdown.isWrapped(textarea, config)) {
            LsTextEditorMarkdown.unWrap(textarea, config);
        } else {
            LsTextEditorMarkdown.wrap(textarea, config);
        }

        _debouncedUpdateModel(textarea.value);

        // Autogrow text area so it follow line addition.
        // eslint-disable-next-line no-use-before-define
        $editor.autogrow();
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Handle auto grow of the textarea.
     */
    function autogrow() {
        // Autoshrink - need accurate scrollHeight.
        $editor.textAreaElement[0].style.height = 0;
        const style = getComputedStyle($editor.textAreaElement[0]);

        if (style.boxSizing === 'border-box') {
            $editor.textAreaElement[0].style.height = `${$editor.textAreaElement[0].scrollHeight}px`;
        }
    }

    /**
     * Cancel the mention: reset the choices, the search and close the dropdown.
     */
    function cancelSelection() {
        $editor.choices.length = 0;
        $editor.searching = undefined;
        $editor.activeChoiceIndex = 0;

        _closeDropDown();
    }

    /**
     * Displays the missing available mentions in the editor. This function calls the `nextPage` method
     * from the base service to merge new results with old ones.
     * This function is trigger by an infinite scroll directive.
     */
    function displayNextSearches() {
        // We get the list attached to the service because we want to get the list of items without special choices.
        const choicesBeforeNextPage = angular.fastCopy(User.displayList(LsTextEditor.MENTION_EDITOR_LIST_LEY));

        User.nextPage(
            (response) => {
                // Only select new items since $editor.choices is also populated by `_addSpecialMentionsChoices`.
                const responseToAdd = response.slice(choicesBeforeNextPage.length);

                // Simply push all array entries from `responseToAdd` in the editor choices.
                Array.prototype.push.apply($editor.choices, responseToAdd);
            },
            Utils.displayServerError,
            LsTextEditor.MENTION_EDITOR_LIST_LEY,
        );
    }

    /**
     * Trick to get a content as a text.
     *
     * @param  {string} content The content to cast/parse.
     * @return {string} The parsed content.
     */
    function parseContentAsText(content) {
        try {
            _tempParser.textContent = content;

            return _tempParser.innerHTML;
        } finally {
            _tempParser.textContent = undefined;
        }
    }

    /**
     * Transforms parsed text into a content.
     *
     * @param  {string} text The parsed text to be transformed into a content.
     * @return {string} The content text.
     */
    function parseTextAsContent(text) {
        try {
            _tempParser.innerHTML = text;

            return _tempParser.textContent;
        } finally {
            _tempParser.innerHTML = undefined;
        }
    }

    /**
     * Compute new HTML, what we see on screen.
     *
     * @param  {string} [html=<model || ''>] The HTML content to render.
     * @return {string} The rendered HTML.
     */
    function render(html) {
        html = (html || _modelController.$modelValue || '').toString();

        for (const object of union([], $editor.userMentioned, $editor.feedMentioned, $editor.inlineImages)) {
            const encoded = LsTextEditor.encode(object);
            const highlighted = LsTextEditor.highlight(object);
            html = html.replace(new RegExp(encoded.replace(/([![\]()])/g, '\\$1'), 'g'), highlighted);
        }

        $editor.renderElement($filter('escapeScript')(html));

        return html;
    }

    /**
     * Render Html in the specified element.
     *
     * @param {string}  html           The HTML to render.
     * @param {boolean} [compile=true] Whether the HTML needs to be compiled.
     */
    function renderElement(html, compile) {
        compile = angular.isUndefined(compile) ? true : compile;
        $editor.renderTarget.html($filter('safeAngular')(html, compile));

        // Compile target HTML to render directive.
        if (compile) {
            $compile($editor.renderTarget)($scope);
        }
    }

    /**
     * Does a search based on the matched results, and then set choices / loading status.
     *
     * @param {Array} match The results that match our pattern search (see $editor.pattern):
     *                          - match[0] the text captured (everything in the textarea).
     *                          - match[1] the char used to mention (in the var _identifierChar) - i.e. + or @.
     *                          - match[2] the query sent to the server - everything after the mention char.
     */
    function search(match) {
        $editor.searching = match;
        $editor.isLoading = true;

        // Insert beacon.
        // + 1 is used to skip captured space, if pos is 0 no space to escape.
        const offset = $editor.searching.index + 1;

        const html = `${_modelController.$viewValue.substr(0, offset)}<span class="dd-beacon" id="${
            $editor.beaconIdentifier
        }">${_modelController.$viewValue.substr(offset)}</span>`;
        $editor.render(html);

        _openDropDown();

        // Note: match[2] is the query, the pending searched name.
        LsTextEditor.search(
            match[2],
            (response) => {
                $editor.isLoading = false;

                const list = _addSpecialMentionsChoices(response, match[2]);

                if (angular.isDefinedAndFilled(list)) {
                    $editor.choices = list;
                    $editor.activeChoiceIndex = 0;
                } else {
                    _rejectSearch();
                }
            },
            _rejectSearch,
        );
    }

    /**
     * Action that triggers an entity selection and add it to mention.
     *
     * @param {Object} choice The selected entity in the list of suggested results.
     */
    function select(choice) {
        _closeDropDown();

        if (angular.isDefinedAndFilled(choice.type) && choice.type === 'feed') {
            $editor.feedMentioned.push(choice);
        } else {
            $editor.userMentioned.push(choice);
        }

        // Replace the search with the label.
        _modelController.$setViewValue(
            LsTextEditor.replace(choice, $editor.searching, _modelController.$viewValue, $editor.searching[1]),
        );

        // Close user selection pop-up.
        $editor.cancelSelection();

        // Update text area model.
        _modelController.$render();
    }

    /**
     * Add markdown to text.
     *
     * @param {string} action           The action we want to perform.
     * @param {Object} [overrideConfig] The markdown configuration to override the service's default one.
     */
    function triggerAction(action, overrideConfig) {
        if (angular.isUndefinedOrEmpty($editor.actionSelected[action])) {
            return;
        }

        const config = overrideConfig || LsTextEditorMarkdown.MARKDOWN_CONFIG[action];

        if (action === 'image') {
            $editor.inlineImages.push(config);
            config.value = `${LsTextEditor.label(config)}\n`;
        }

        // Reverse current state.
        $editor.actionSelected[action] = !$editor.actionSelected[action];

        // Re-set focus to textarea after click.
        // If content was selected, it will be selected again.
        $editor.textAreaElement.focus();

        _executeMarkdownAction(config);
    }

    /**
     * Add markdown corresponding to the heading selected.
     * @param {string} choice The heading selected.
     */
    function changeHeading(choice) {
        // Re-set focus to textarea after click.
        // If content was selected, it will be selected again.
        $editor.textAreaElement.focus();
        if (LsTextEditorMarkdown.MARKDOWN_CONFIG[choice]) {
            $rootScope.$broadcast('ls-rich-text-editor__movement', {
                prefixMode: choice,
            });
            _executeMarkdownAction(LsTextEditorMarkdown.MARKDOWN_CONFIG[choice]);
        }
    }

    /**
     * Switch editor mode.
     *
     * @param {string} mode Mode we want to switch to.
     */
    function switchMode(mode) {
        if (angular.isDefined(LsTextEditor.availableModes[mode])) {
            $editor.currentMode = mode;
        }
    }

    /**
     * Current mode getter.
     *
     * @return {string} The current editor mode.
     */
    function getCurrentMode() {
        return $editor.currentMode;
    }

    /////////////////////////////

    $editor.autogrow = autogrow;
    $editor.cancelSelection = cancelSelection;
    $editor.displayNextSearches = displayNextSearches;
    $editor.getCurrentMode = getCurrentMode;
    $editor.parseContentAsText = parseContentAsText;
    $editor.parseTextAsContent = parseTextAsContent;
    $editor.render = render;
    $editor.renderElement = renderElement;
    $editor.search = search;
    $editor.select = select;
    $editor.switchMode = switchMode;
    $editor.triggerAction = triggerAction;
    $editor.changeHeading = changeHeading;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Interactions to trigger searching.
     *
     * @param {Event} evt The origin event triggering the search.
     */
    $editor.textAreaElement.on(_searchTriggerEvent.join(' '), _triggerSearch);

    /**
     * When the rich text editor is focused, focus the textarea inside of it.
     */
    $scope.$on('ls-rich-text-editor__focus', () => {
        $editor.textAreaElement.focus();
    });

    /**
     * When something is typed in the textarea, auto-grow it and re-calculate current prefix.
     */
    $editor.textAreaElement.on('input', () => {
        $editor.autogrow();
        $rootScope.$broadcast('ls-rich-text-editor__movement', {
            prefixMode: LsTextEditorMarkdown.getCurrentPrefixMode(first($editor.textAreaElement)),
        });
    });

    /**
     * When the cursor is moved by clicking in the textarea, re-calculate current prefix.
     */
    $editor.textAreaElement.on('mouseup', () => {
        $rootScope.$broadcast('ls-rich-text-editor__movement', {
            prefixMode: LsTextEditorMarkdown.getCurrentPrefixMode(first($editor.textAreaElement)),
        });
        setTimeout(() => {
            $rootScope.$safeApply();
        }, 0);
    });

    /**
     * When the cursor is moved by key press (arrows) in the textarea, re-calculate current prefix.
     */
    $editor.textAreaElement.on('keyup', (evt) => {
        if (
            !$editor.searching &&
            (evt.keyCode === _KEY_CODES.down ||
                evt.keyCode === _KEY_CODES.up ||
                evt.keyCode === _KEY_CODES.left ||
                evt.keyCode === _KEY_CODES.right)
        ) {
            $rootScope.$broadcast('ls-rich-text-editor__movement', {
                prefixMode: LsTextEditorMarkdown.getCurrentPrefixMode(first($editor.textAreaElement)),
            });
        }
    });

    /**
     * Trigger actions whenever a key is being pressed.
     *
     * @param {Event} evt The original keydown event.
     */
    $editor.textAreaElement.on('keydown', (evt) => {
        if (!$editor.searching) {
            switch (evt.keyCode) {
                // Handle bold shortcut <ctrl|cmd+b>.
                case _KEY_CODES.b:
                    if (evt.ctrlKey || evt.metaKey) {
                        _executeMarkdownAction(LsTextEditorMarkdown.MARKDOWN_CONFIG.bold);
                    }

                    break;
                // Handle emphasize shortcut <ctrl|cmd+i>.
                case _KEY_CODES.i:
                    if (evt.ctrlKey || evt.metaKey) {
                        _executeMarkdownAction(LsTextEditorMarkdown.MARKDOWN_CONFIG.italic);
                    }

                    break;
                // Enter.
                case _KEY_CODES.enter:
                    // If we pressed <ctrl|cmd+enter>, simply submit the form bound to the rich text editor.
                    if ((evt.ctrlKey || evt.metaKey) && angular.isFunction($editor.submit)) {
                        _triggerSearch(evt);
                        // _forceOriginModelUpdate();
                        $editor.submit(evt);
                    }

                    break;

                default:
                    if (includes(_CANCEL_KEY_CODES, evt.keyCode) && !$editor.preventCancelOnEscape) {
                        $editor.cancel(evt);

                        // Impossible to write the short version, it doesn't work!
                        $timeout(() => {
                            $editor.textAreaElement.blur();
                        }, 1);
                    }

                    break;
            }

            return;
        }

        switch (evt.keyCode) {
            // Tab.
            case _KEY_CODES.tab:
                _keySelect();

                break;

            // Enter.
            case _KEY_CODES.enter:
                _keySelect();

                break;

            // Up.
            case _KEY_CODES.up:
                _keyUp();

                break;

            // Down.
            case _KEY_CODES.down:
                _keyDown();

                break;

            // Any other key (including escape).
            default:
                if ($editor.dropDownOpen && includes(_CANCEL_KEY_CODES, evt.keyCode)) {
                    _closeDropDown();
                }

                return;
        }

        $editor.moved = true;

        evt.stopPropagation();
        evt.preventDefault();

        $rootScope.$safeApply();
    });

    /////////////////////////////

    /**
     * Initialize controller.
     *
     * @param {Object} model The model controller.
     */
    this.init = function init(model) {
        // Extend markdown option.
        angular.extend($editor.editorSettings, $editor.editorOption);

        _bindExternalControl();

        _closeDropDown();

        $editor.markdown = Boolean($editor.markdown);

        // Set ng-model (inheritance).
        _modelController = model;

        // Add parser, parsers function are executed in list order (if we have several fct).
        _modelController.$parsers.push(_buildParser);

        // Add formatter, formatters function are executed in REVERSE list order (if we have several fct).
        _modelController.$formatters.push(_buildFormatter);

        // Set render.
        _modelController.$render = function setRender() {
            // Set value.
            $editor.textAreaElement.val(_modelController.$viewValue || '');
            $editor.render();

            $timeout($editor.autogrow);
        };

        $timeout($editor.autogrow, _AUTOGROW_DELAY);

        $editor.userMentioned = $editor.userMentioned || [];
        $editor.feedMentioned = $editor.feedMentioned || [];
        $editor.inlineImages = $editor.inlineImages || [];
    };
}

function RichTextEditorDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrl) {
        ctrl[1].init(ctrl[0]);
    }

    return {
        bindToController: true,
        controller: RichTextEditorController,
        controllerAs: '$editor',
        link,
        require: ['ngModel', 'lsRichTextEditor'],
        restrict: 'AE',
        scope: {
            cancel: '&?',
            editorOption: '=?',
            externalControls: '=?',
            feedMentioned: '=?',
            inlineImages: '=?',
            markdown: '=?',
            ngFocus: '&?',
            ngModel: '=',
            placeholder: '@',
            specialMentions: '=?',
            submit: '&?',
            userMentioned: '=?',
            preventCancelOnEscape: '=?',
        },
        templateUrl: '/client/common/modules/rich-text/views/ls-rich-text-editor.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsRichTextEditor', RichTextEditorDirective);

/////////////////////////////

export { RichTextEditorController, RichTextEditorDirective };
