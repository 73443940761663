/* istanbul ignore file */
import React from 'react';

import { useSelector } from '@lumapps/redux/react';
import {
    isSAEnabled,
    canManagePlaftormPrograms,
    canManagePlatformSocialNetworks,
} from '@lumapps/sa-connected-user/ducks/selectors';

import { SettingsNavigation } from './SettingsNavigation';

const ConnectedSettingsNavigation: React.FC = () => {
    const SAEnabled = useSelector(isSAEnabled);
    const userCanManagePlatformPrograms = useSelector(canManagePlaftormPrograms);
    const userCanManagePlatformSocialNetworks = useSelector(canManagePlatformSocialNetworks);

    if (!SAEnabled || (!userCanManagePlatformPrograms && !userCanManagePlatformSocialNetworks)) {
        return null;
    }

    return (
        <SettingsNavigation
            userCanManagePlatformPrograms={userCanManagePlatformPrograms}
            userCanManagePlatformSocialNetworks={userCanManagePlatformSocialNetworks}
        />
    );
};

export { ConnectedSettingsNavigation as SettingsNavigation };
