import { Alignment } from '@lumapps/wrex/types/core';

import { SUBTITLE } from '../constants';
import type { SubtitleElement } from '../types';

/** Create title element */
export function createSubTitle(
    children: SubtitleElement['children'] = [{ text: '' }],
    alignment: Alignment | undefined = undefined,
): SubtitleElement {
    return { type: SUBTITLE, children, ...(alignment ? { alignment } : undefined) };
}
