/* istanbul ignore file */
/** tested with integration tests */
import React from 'react';

import { ENVS } from '@lumapps/constants';

/**
 * Custom colors hook that removes/adds the custom colors stylesheet to the DOM
 * depending whether the mode is enabled and the environmnent
 */
const useCustomColors = ({ isEnabled = true, env = ENVS.DEVELOPMENT }) => {
    React.useLayoutEffect(() => {
        if (isEnabled) {
            /**
             * Since by default this mode is disabled in development, if the mode is enabled
             * and we are in development, it means that someone wanted to enable it. In that case,
             * we add to the head the stylesheet for custom colors
             */
            if (env === ENVS.DEVELOPMENT) {
                const css = window.CUSTOM_COLORS;
                if (css) {
                    const head = document.head || document.getElementsByTagName('head')[0];
                    const style = document.createElement('style');

                    style.type = 'text/css';
                    style.appendChild(document.createTextNode(css));

                    head.appendChild(style);
                }
            }
        } else {
            /**
             * If the mode is not enabled, it means that someone wanted to disable it and they are in production or development
             * In that case, we remove the stylesheet from the DOM if we can find it.
             */
            const instanceCustomColorsStylesheet = document.getElementById('custom-css-for-instance');

            if (instanceCustomColorsStylesheet) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                instanceCustomColorsStylesheet.disabled = true;
            }
        }
    });
};

export { useCustomColors };
