import { NodeEntry, ReactEditor, Node } from '@lumapps/wrex/slate';
import { findParent } from '@lumapps/wrex/slate/utils/findParent';
import { normalizeLiftToRootNode } from '@lumapps/wrex/slate/utils/normalizeLiftToRootNode';

import { IMAGE_FEATURES } from '../../constants';
import { ImageEditor, ImageEditorOptions } from '../../types';
import { isAlignedImageWrapper } from '../../utils/isAlignedImageWrapper';
import { isImageGroup } from '../../utils/isImageGroup';
import { uploadExternalImage } from '../../utils/uploadExternalImage';

export function normalizeImage(
    options: ImageEditorOptions,
    editor: ReactEditor & ImageEditor,
    [node, path]: NodeEntry,
) {
    if (!isImageGroup(node)) {
        return false;
    }
    // TODO: Make this call async to only upload the image one time
    if (node.external) {
        uploadExternalImage(options, editor, [node, path]);
    }
    const parentAlignmentWrapper = findParent(editor, path, isAlignedImageWrapper);
    if (!parentAlignmentWrapper && path.length > 1) {
        normalizeLiftToRootNode(editor, [node, path], isImageGroup);
    }

    // If link option is disabled, remove the link from the image
    if (!options.enabledFeatures?.includes(IMAGE_FEATURES.link)) {
        const hasLinks = node.images?.some((image) => !!image.link);
        if (hasLinks) {
            editor.setNodes(
                {
                    images: node.images.map((image) => {
                        // Completly remove the link property from the image object
                        return { ...image, link: undefined };
                    }),
                } as Partial<Node>,
                {
                    at: path,
                },
            );
        }
    }

    return false;
}
