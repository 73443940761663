/* eslint-disable no-param-reassign, no-extend-native, import/unambiguous */
import extend from 'lodash/extend';

/**
 * Clone an object recursively.
 *
 * @param  {Object} obj The source object to clone.
 * @return {Object} The deeply cloned object.
 */
function cloneObject(obj) {
    const Ctor = obj.constructor;

    switch (Ctor) {
        // Given the fact that a function should always work as it was defined, there is no need to clone it.
        case Function:
            return obj;

        /*
         * Cannot directly instantiate new Boolean object by giving it a Boolean object instance in his constuctor,
         * we have to assign a value to the constructor.
         * See https://www.ecma-international.org/ecma-262/5.1/#sec-15.6.2.1 for further information.
         */
        case Boolean:
            // eslint-disable-next-line no-new-wrappers
            return Boolean(obj.valueOf());

        case Array: {
            const clone = [];

            for (let i = 0; i < obj.length; i++) {
                clone[i] = obj[i] !== null && typeof obj[i] === 'object' ? cloneObject(obj[i]) : obj[i];
            }

            return clone;
        }

        case Object: {
            const clone = { ...obj };

            const props = Object.getOwnPropertyNames(obj);

            for (let i = 0; i < props.length; i++) {
                const propName = props[i];

                // Clean the object from unwanted angular built-in props (like '$promise').
                if (propName.charAt(0) === '$') {
                    continue;
                }

                clone[propName] =
                    obj[propName] !== null && typeof obj[propName] === 'object'
                        ? cloneObject(obj[propName])
                        : obj[propName];
            }

            return clone;
        }

        case Date:
        case Number:
        case RegExp:
        case String:
            return angular.copy(obj);

        /*
         * If the type of the object's constructor is not an Object, Array, Boolean, nor a Function,
         * perform a regular copy.
         */
        default:
            return JSON.parse(JSON.stringify(obj));
    }
}

/**
 * Improve the legacy angular "copy" function.
 * This copy provides a cloned version of any objects which works recursivly in order to erase any
 * object references.
 *
 * @param  {*}       source               The source to copy.
 * @param  {boolean} [dontFallBack=false] Don't fallback to legacy deep copy in case of error, simply return
 *                                        undefined.
 * @param  {boolean} [simple=false]       Indicates if we want to use a simple fast copy (based on JSON
 *                                        stringify/parse.
 * @return {*}       The copied thing.
 */
function angularFastCopy(source, dontFallBack, simple) {
    try {
        if (simple && angular.isDefined(source)) {
            return JSON.parse(JSON.stringify(source));
        }

        // If not object, return the source since primitive values are immutable.
        return angular.isObject(source) ? cloneObject(source) : source;
    } catch (exception) {
        // eslint-disable-next-line no-console
        console.error(exception, source);

        if (!dontFallBack) {
            return angular.copy(source);
        }
    }

    return undefined;
}

/**
 * Overrides the legacy angular "isDefined" function.
 * Also check for the "null" type.
 *
 * @param  {*}       value        The value(s) to check if not undefined and null.
 * @param  {string}  [methodName] The name of the method to apply to the values of the array (if value is an array).
 *                                Possible values are 'some' or 'every'.
 * @return {boolean} If the value is not undefined and null or not.
 */
function angularIsDefined(value, methodName) {
    const allowedMethodNames = ['every', 'some'];

    if (Array.isArray(value) && allowedMethodNames.indexOf(methodName) > -1) {
        return value[methodName](function loopArrayItem(item) {
            return angular.isDefined(item);
        });
    }

    return !angular.isUndefined(value);
}

/**
 * Add a new function to check if a value is not undefined, null or empty.
 * Non emptyness is check only in the case of a string, an array or an object.
 *
 * @param  {*}       value         The value(s) to check if not undefined, null or empty.
 * @param  {string}  [methodName]  The name of the method to apply to the values (if value is an array).
 *                                 Possible values are 'some' or 'every'.
 * @param  {boolean} [trimStrings] Indicates if we don't want strings to be trimed before checking their length.
 * @return {boolean} If the value is not undefined, null or empty or not.
 */
function angularIsDefinedAndFilled(value, methodName, trimStrings) {
    if (typeof methodName === 'boolean') {
        trimStrings = methodName;
        methodName = trimStrings;
    }

    // If it's undefined, null etc...
    if (trimStrings === undefined || trimStrings === null) {
        trimStrings = true;
    }

    // Get the opposite methodName since we use isUndefinedAndEmpty in the return statement.
    if (methodName === 'some') {
        methodName = 'every';
    } else if (methodName === 'every') {
        methodName = 'some';
    } else {
        methodName = undefined;
    }

    return !angular.isUndefinedOrEmpty(value, methodName, trimStrings);
}

/**
 * Overrides the legacy angular "isUndefined" function.
 * Also check for the "null" type.
 *
 * @param  {*}       value        The value to check if undefined or null.
 * @param  {string}  [methodName] The name of the method to apply to the values of the array (if value is an array).
 *                                Possible values are 'some' or 'every'.
 * @return {boolean} If the value is undefined or null or not.
 */
function angularIsUndefined(value, methodName) {
    const allowedMethodNames = ['every', 'some'];

    if (
        Array.isArray(value) &&
        allowedMethodNames.indexOf(methodName) > -1 &&
        typeof value[methodName] === 'function'
    ) {
        return value[methodName](function loopArrayItem(item) {
            return angular.isUndefined(item);
        });
    }

    return typeof value === 'undefined' || value === null;
}

/**
 * Add a new function to check if a value is undefined, null or empty.
 * Emptyness is check only in the case of a string, an array or an object.
 *
 * @param  {*}       value         The value to check if undefined, null or empty.
 * @param  {string}  [methodName]  The name of the method to apply to the values (if value is an array).
 *                                 Possible values are 'some' or 'every'.
 * @param  {boolean} [trimStrings] Indicates if we want strings to be trimmed before checking their length.
 * @return {boolean} If the value is undefined, null or empty or not.
 */
function angularIsUndefinedOrEmpty(value, methodName, trimStrings) {
    const allowedMethodNames = ['every', 'some'];

    if (typeof methodName === 'boolean') {
        trimStrings = methodName;
        methodName = trimStrings;
    }

    // If it's undefined, null etc...
    if (trimStrings === undefined || trimStrings === null) {
        trimStrings = true;
    }

    const valueType = typeof value;

    value = trimStrings && valueType === 'string' ? value.trim() : value;

    if (Array.isArray(value)) {
        if (
            value.length > 0 &&
            allowedMethodNames.indexOf(methodName) > -1 &&
            typeof value[methodName] === 'function'
        ) {
            return value[methodName](function loopArrayItem(item) {
                return angular.isUndefinedOrEmpty(item, undefined, trimStrings);
            });
        }

        return value.length === 0;
    }

    if (angular.isUndefined(value, methodName)) {
        return true;
    }

    const toStringValue = Object.prototype.toString.call(value);

    const isAttr = toStringValue === '[object Attr]';
    const isDate = toStringValue === '[object Date]';
    const isJqObj = value instanceof jQuery || value instanceof angular.element;
    const isRegexp = toStringValue === '[object RegExp]';

    const isClassicObject = !isDate && !isRegexp && !isAttr && !isJqObj;

    return (
        (valueType === 'object' && isClassicObject && Object.keys(value).length === 0) ||
        (isAttr && value.value.toString().length === 0) ||
        (isDate && value.toString().length === 0) ||
        (isJqObj && value.length === 0) ||
        (isRegexp && value.toString().length - 2 === 0) ||
        (valueType === 'string' && value.length === 0)
    );
}

/**
 * Generates a hashcode for a string.
 *
 * @return {number} The hashcode value of the number.
 */
function hashCode() {
    let hash = 0;

    const len = this.length;
    if (len === 0) {
        return hash;
    }

    for (let i = 0; i < len; i++) {
        const char = this.charCodeAt(i);
        /* eslint-disable no-bitwise */
        hash = (hash << 5) - hash + char;
        // Convert to 32bits integer.
        hash |= 0;
        /* eslint-enable no-bitwise */
    }

    return hash;
}

/**
 * Empty an object from all it's (own) properties without changing it's reference.
 *
 * @param  {Object} target The object to empty.
 * @return {Object} The emptied object.
 */
function objectEmpty(target) {
    for (const propertyName in target) {
        if (target.hasOwnProperty(propertyName)) {
            delete target[propertyName];
        }
    }

    return target;
}

/**
 * Count the number of properties in an object.
 *
 * @param  {Object} object The object to count the properties in.
 * @return {number} The number of properties of the object.
 */
function objectSize(object) {
    let size = 0;

    if (typeof Object.keys === 'function') {
        size = Object.keys(object).length;
    } else {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                size++;
            }
        }
    }

    return size;
}

/**
 * Swap an object with an other without changing references of both objects.
 *
 * @param {Object} destination The destination object.
 * @param {Object} source      The source object.
 */
function objectSwap(destination, source) {
    for (const destinationKey in destination) {
        if (destination.hasOwnProperty(destinationKey)) {
            delete destination[destinationKey];
        }
    }

    // eslint-disable-next-line no-negated-condition, no-undef
    if (_ !== undefined) {
        // eslint-disable-next-line no-undef
        extend(destination, source);
    } else {
        for (const sourceKey in source) {
            if (source.hasOwnProperty(sourceKey)) {
                destination[sourceKey] = source[sourceKey];
            }
        }
    }
}

/////////////////////////////

angular.fastCopy = angularFastCopy;
angular.isDefined = angularIsDefined;
angular.isDefinedAndFilled = angularIsDefinedAndFilled;
angular.isUndefined = angularIsUndefined;
angular.isUndefinedOrEmpty = angularIsUndefinedOrEmpty;
if (!Object.assign) {
    // Make a simple polyfill for Object.assign with lodash extend.
    Object.assign = extend;
}
Object.empty = objectEmpty;
Object.size = objectSize;
Object.swap = objectSwap;
String.prototype.hashCode = hashCode;

/////////////////////////////

export {
    angularFastCopy,
    angularIsDefined,
    angularIsDefinedAndFilled,
    angularIsUndefined,
    angularIsUndefinedOrEmpty,
    cloneObject,
    hashCode,
    objectEmpty,
    objectSize,
    objectSwap,
};
