import { createSelector } from '@lumapps/redux/reselect';

import get from 'lodash/get';
import curry from 'lodash/curry';
import orderBy from 'lodash/orderBy';
import reject from 'lodash/reject';
import loFind from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import keys from 'lodash/keys';

import { getTopTopicsByShareable } from 'components/components/social-advocacy/ducks/analytics/selector';

import { translate as t } from 'components/translations';
import { SA_FEATURE_FLAGS } from 'components/components/social-advocacy/constants';

import { getTopicsList, fetchingTopicsSelector } from 'components/components/social-advocacy/ducks/topics/selectors';

import { SHAREABLE_CONTENT_TYPES } from 'components/components/social-advocacy/ducks/shareableContents/constants';
import { TopicStatuses } from '@lumapps/sa-topics/types';

export const searchMatches = curry((searchText, text) => text.trim().toLocaleLowerCase().includes(searchText));

export const filterLocalizedNamedObjectsBySearchText = (objects, searchText) =>
    objects.filter((object) => searchMatches(searchText, t(object.name)));

// Selectors.
export const getShareableWorkflow = (state) => get(state, 'socialAdvocacy.shareableWorkflow');
export const getShareableWorkflowContentId = (state) => get(state, 'socialAdvocacy.shareableWorkflow.contentId');

export const getShareableWorkflowContentPreview = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.contentPreview');

export const getShareableWorkflowCurrentPreviewTab = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.currentPreviewTab');

export const getShareableworkflowForm = (state) => get(state, 'socialAdvocacy.shareableWorkflow.form');

export const getShareableWorkflowSocialNetworksStatus = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.socialNetworksStatus');

export const getShareableWorkflowIncompatibleNetworks = createSelector(getShareableWorkflow, (shareableWorkflow) =>
    get(shareableWorkflow, 'incompatibleSocialNetworks'),
);

export const getShareableWorkflowIsPreviewOpen = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.isPreviewOpen');

export const getShareableWorkflowisWorkflowOpen = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.isWorkflowOpen');

export const getShareableWorkflowShareableContent = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.shareableContent');

export const getShareableWorkflowProgramId = (state) => get(state, 'socialAdvocacy.shareableWorkflow.programId');

export const getShareableWorkflowFormFieldValue = (state, fieldName) =>
    get(state, `socialAdvocacy.shareableWorkflow.form.fields.${fieldName}.value`);

export const getSelectedTopics = (state) => getShareableWorkflowFormFieldValue(state, 'topics');

export const getTopicsSearchText = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.form.fields.topics.searchText', '').trim().toLocaleLowerCase();

export const getShareableworkflowFormValues = createSelector(getShareableworkflowForm, (form) =>
    get(form, 'fields', {}),
);

/**
 * Creates a selector by fieldName.
 */
export const makeShareableworkflowFormFieldelector = createSelector(getShareableworkflowFormValues, (formValues) =>
    memoize((fieldName) => get(formValues, [fieldName], {})),
);

export const getFilteredTopics = createSelector([getTopicsList, getTopicsSearchText], (topics, searchText) =>
    filterLocalizedNamedObjectsBySearchText(Object.values(topics), searchText),
);

export const getAvailableSocialNetworks = (state) =>
    get(state, 'socialAdvocacy.shareableWorkflow.socialNetworks', [])
        /*
         * Until backend is done we need this part to filter facebook
         */
        .filter((socialNetwork) => socialNetwork !== 'facebook');

/**
 *
 * Recent topics selector.
 *
 * @param  {Object} state The redux state.
 * @return {max}    Array of topics.
 */
export const getRecentTopics = createSelector([getTopicsList, getSelectedTopics], (topics, selectedTopics) => {
    const orderedTopics = orderBy(topics, 'createAt', 'desc');

    return reject(orderedTopics, (topic) => loFind(selectedTopics, { id: topic.id }));
});

/**
 *
 * Most used topics selector.
 *
 * @param  {Object} state The redux state.
 * @return {max}    Array of topics.
 */
export const getMostUsedTopics = createSelector(
    [getTopTopicsByShareable, getSelectedTopics],
    (mostUsedTopics, selectedTopics) => {
        return mostUsedTopics.filter(
            (mostUsedTopic) =>
                mostUsedTopic.status === TopicStatuses.live &&
                !loFind(selectedTopics, (selectedTopic) => mostUsedTopic.id === selectedTopic.id),
        );
    },
);

/**
 *
 * Most used topics selector.
 *
 * @param  {Object}  state The redux state.
 * @return {boolean} Whether there is something loading.
 */
export const getWorkflowLoadingStatus = (state) => {
    const { loadingState } = state;
    const loaders = {
        isFetchingMostUsedTopics: state.socialAdvocacy.saAnalytics.topTopics.byShareable.fetching,
        isFetchingTopics: fetchingTopicsSelector(state),
        isSubmitting: loadingState.CREATE_SHAREABLE_CONTENT,
        isValidating: loadingState.VALIDATE_SHAREABLE_CONTENT,
    };

    return Object.values(loaders).some((status) => Boolean(status) === true);
};

/**
 * Check whether the form is valid or not.
 *
 * @param  {Object}  state The redux state.
 * @return {boolean} Whether the form is valid or not.
 */
export const getFormIsValid = createSelector(
    [getShareableworkflowFormValues, getShareableWorkflowContentPreview, makeShareableworkflowFormFieldelector],
    (formValues, contentPreview, fieldSelector) => {
        const { value: contentType } = fieldSelector('type');
        const { value: images } = fieldSelector('images');

        switch (contentType) {
            case SHAREABLE_CONTENT_TYPES.link: {
                const urlHasError = get(formValues, 'contentUrl.hasError', '');

                return Boolean(contentPreview.url && !urlHasError);
            }

            case SHAREABLE_CONTENT_TYPES.images: {
                const hasImages = Boolean(images && images.length > 0);

                return hasImages && get(images, '[0].type') !== 'image/gif';
            }

            default:
                return true;
        }
    },
);

/**
 * Get validity of content for each active social networks.
 *
 * @param  {Object} state The redux state.
 * @return {Object} An object with each active social network as keys and the validity as value (true/false).
 */
export const getContentValidityBySocialNetwork = createSelector(
    [getShareableWorkflowIncompatibleNetworks, makeShareableworkflowFormFieldelector],
    (incompatibleSocialNetworks, fieldSelector) => {
        const { value: shareableOn } = fieldSelector('shareableOn');
        const socialNetworks = keys(shareableOn);

        return socialNetworks.reduce(
            (acc, socialNetwork) => ({
                ...acc,
                [socialNetwork]:
                    shareableOn[socialNetwork] && isEmpty(get(incompatibleSocialNetworks, socialNetwork, [])),
            }),
            {},
        );
    },
);

/**
 * Can save workflow selector.
 *
 * @param  {Object}  state The redux state.
 * @return {boolean} Whether the shareableWorkflow can be save or not.
 */
export const canSaveContent = createSelector(
    [getFormIsValid, getContentValidityBySocialNetwork, getAvailableSocialNetworks],
    (formIsValid, contentValidityBySocialNetwork, socialNetworks) => {
        if (socialNetworks.length === 0) {
            return false;
        }

        if (SA_FEATURE_FLAGS.selectSNForShareableContent) {
            return formIsValid && Object.values(contentValidityBySocialNetwork).some((contentValid) => contentValid);
        }
        return formIsValid;
    },
);
