/* istanbul ignore file */
import React from 'react';

export const ShareContentDropdown = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'share-content-dropdown' */
            './ConnectedShareContentDropdown'
        ),
);
