import React, { useMemo } from 'react';

import { classnames } from '@lumapps/classnames';
import { AspectRatio, Thumbnail, ThumbnailProps } from '@lumapps/lumx/react';

import * as type from '../../../types';

export interface BlockImageProps extends type.BlockImage {
    className?: string;
    thumbnailProps?: Partial<ThumbnailProps>;
}

const CLASSNAME = 'block-image';

/**
 * This block renders an image, with all styles and focalPoint handled.
 */
export const BlockImage: React.FC<BlockImageProps> = (props) => {
    const { className, theme, focalX, focalY, url, fit, cornerVariant, coverRatio, thumbnailProps } = props;
    const aspectRatio = useMemo(() => {
        if (fit !== 'cover') {
            return AspectRatio.original;
        }
        if (!coverRatio || thumbnailProps?.fillHeight) {
            return AspectRatio.free;
        }
        return coverRatio;
    }, [coverRatio, fit, thumbnailProps]);

    return (
        <Thumbnail
            className={classnames(CLASSNAME, className)}
            alt={thumbnailProps?.alt || ''}
            focusPoint={{ x: focalX || 0, y: focalY || 0 }}
            image={url}
            variant={cornerVariant}
            aspectRatio={aspectRatio}
            theme={theme}
            {...thumbnailProps}
        />
    );
};
BlockImage.displayName = 'BlockImage';
