import React from 'react';

/**
 * Compute a tooltip label based on a label element `innerText` if the text overflows.
 *
 * Warning: only works on first render, does not update on label element resize.
 */
export const useOverflowTooltipLabel = () => {
    const [tooltipLabel, setTooltipLabel] = React.useState<string | null>(null);
    const labelRef = React.useCallback((labelElement: HTMLElement | null) => {
        if (!labelElement) {
            return;
        }

        // Label overflowing
        if (labelElement.offsetWidth < labelElement.scrollWidth) {
            setTooltipLabel(labelElement.innerText);
        }
    }, []);

    return { labelRef, tooltipLabel };
};
