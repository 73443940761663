import get from 'lodash/get';

import BaseApi from '@lumapps/base-api';

import { MetricRequestParams, CustomerProps, InstanceProps } from '../types';

declare const window: any;

const miscApi = new BaseApi({ path: 'misc' });

/**
 * Call /metric endpoint to send timestamp and duration.
 */
const sendMetric = ({ path, value, time, customer, instance, kind = 'GAUGE' }: MetricRequestParams) => {
    const params = {
        kind,
        interval: {
            endTime: new Date(time).toISOString(),
            startTime: new Date(time).toISOString(),
        },
        labels: {
            customer: `${get(customer, 'id', 'Unknown ID')} - ${get(customer, 'domain', 'Unknown domain')}`,
            instance: `${get(instance, 'id', 'Unknown ID')} - ${get(instance, 'name', 'Unknown name')}`,
        },
        path,
        value: {
            int64Value: String(value),
        },
        valueType: 'INT64',
    };

    miscApi.post('metric', params).catch(() => {
        // Here we are catching this expcetion in order to avoid error logs in the console
        // But sometimes this endpoint can return an error, but there is no backup plan for it
        // since it is just metrics.
    });
};

/**
 * Compute duration before first paint.
 */
const sendFirstPaintMetric = (customer: CustomerProps, instance: InstanceProps) => {
    sendMetric({
        path: 'initial-load/first-paint_new',
        value: window.lumappsPerformances.firstPaint - window.performance.timing.navigationStart,
        time: window.lumappsPerformances.firstPaint,
        customer,
        instance,
    });
};

/**
 * Compute duration for HTML to load.
 */
const sendHtmlLoadMetric = (customer: CustomerProps, instance: InstanceProps) => {
    sendMetric({
        path: 'initial-load/html-load_new',
        value: window.performance.timing.responseEnd - window.performance.timing.navigationStart,
        time: window.performance.timing.responseEnd,
        customer,
        instance,
    });
};

/**
 * Compute duration before end of loading.
 */
const sendLoaderHiddenMetric = (customer: CustomerProps, instance: InstanceProps) => {
    sendMetric({
        path: 'initial-load/loader-hidden_new',
        value: window.lumappsPerformances.loaderHidden - window.performance.timing.navigationStart,
        time: window.lumappsPerformances.loaderHidden,
        customer,
        instance,
    });
};

export { sendMetric, sendFirstPaintMetric, sendHtmlLoadMetric, sendLoaderHiddenMetric };
