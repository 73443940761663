/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { ContentPickerDialogProps } from '../../types';

const LazyContentPickerDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'content-picker-dialog' */
            './ContentPickerDialog'
        ),
);

const ContentPickerDialog: React.FC<ContentPickerDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            <LazyContentPickerDialog {...props} />
        </DelayedSuspense>
    );
};

export { ContentPickerDialog };
