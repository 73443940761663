import produce from 'immer';

import { FETCH_FILES_LOADING, FETCH_FILES_SUCCESS, FETCH_FILES_ERROR } from '../actions/community_files_actions';

// Default state.
const defaultState = { errorCode: undefined, errorMessage: '', fileIsLoading: false, items: [] };

const filesReducer = (state = defaultState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FETCH_FILES_LOADING:
                draft.fileIsLoading = true;

                break;
            case FETCH_FILES_SUCCESS:
                draft.items = action.payload.items || [];
                draft.fileIsLoading = false;

                break;
            case FETCH_FILES_ERROR:
                draft.errorCode = action.payload.errorCode;
                draft.errorMessage = action.payload.errorMessage;
                draft.fileIsLoading = false;

                break;

            default:
        }

        return draft;
    });

export { filesReducer };
