import React from 'react';

import { useFormContext } from 'react-hook-form';

import { classnames, padding } from '@lumapps/classnames';
import { URLTextFieldProps, URLTextField } from '@lumapps/lumx-inputs/components/URLTextField';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export type FormURLTextFieldProps = Omit<URLTextFieldProps, 'onUrlChange' | 'url'> & BaseInput;

/**
 * A URL text field component that can be added into a Form in order to be used with react-hook-form.
 * @family Forms
 * @param FormURLTextFielProps
 * @returns FormURLTextField
 */
export const FormURLTextField: React.FC<FormURLTextFieldProps> = ({
    name,
    isRequired,
    controllerProps,
    isMultiLanguage,
    languagesFormat,
    label,
    ...props
}) => {
    const { setError, clearErrors } = useFormContext();
    const { field, fieldState, valueToUse, onMultilanguageChange, inputProps, getDataAttributes } =
        useFormField<string>({
            name,
            controllerProps,
            isMultiLanguage,
            isRequired,
            languagesFormat,
            label,
        });

    return (
        <URLTextField
            inputRef={field.ref}
            url={valueToUse}
            name={field.name}
            isRequired={isRequired}
            label={label}
            forceErrorDisplay={Boolean(fieldState.error)}
            {...(fieldState.error?.message && { error: fieldState.error.message })}
            shouldDisplayValidity={fieldState.isTouched}
            {...getDataAttributes({ element: 'url-text-field', action: name })}
            {...props}
            onUrlChange={(value, isValid) => {
                if (isMultiLanguage) {
                    onMultilanguageChange(value);
                } else {
                    field.onChange(value);
                }

                if (!isValid) {
                    setError(name, { type: 'custom' });
                } else {
                    clearErrors(name);
                }
            }}
            onBlur={() => {
                if (isRequired && !valueToUse) {
                    setError(name, { type: 'required' });
                } else if (isMultiLanguage) {
                    onMultilanguageChange(valueToUse);
                }

                field.onBlur();
            }}
            {...inputProps}
            className={classnames(props.className, padding('vertical', null))}
        />
    );
};
