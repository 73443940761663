import { Header } from '@lumapps/header';
import { useSelector } from '@lumapps/redux/react';
import { wasUserInitialized } from '@lumapps/user/ducks/selectors';

import React from 'react';
import { RouterWrapper } from './router-wrapper';

const FrontOfficeHeader = ({ current, params }) => {
    const wasUserInit = useSelector(wasUserInitialized);

    return (
        <RouterWrapper current={current} params={params}>
            <Header isLoading={!wasUserInit} />
        </RouterWrapper>
    );
};

export { FrontOfficeHeader as Header };
