import { connect } from '@lumapps/redux/react';

import { isTagzEnabled } from '@lumapps/folksonomy/ducks/selectors';

import { WidgetCommunityIntro } from './WidgetCommunityIntro';

const mapStateToProps = (state) => {
    return {
        isTagzEnabled: isTagzEnabled(state),
    };
};

const ConnectedWidgetCommunityIntro = connect(mapStateToProps, null)(WidgetCommunityIntro);

export { ConnectedWidgetCommunityIntro as WidgetCommunityIntro };
