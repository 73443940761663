function SocialAdvocacyFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/sa/v2/users/:methodKey`,
        {},
        {
            getUser: {
                method: 'GET',
                params: {
                    methodKey: 'me',
                },
            },
        },
    );
}
/////////////////////////////

angular.module('Factories').factory('SocialAdvocacyFactory', SocialAdvocacyFactory);

export { SocialAdvocacyFactory };
