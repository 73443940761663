import React from 'react';

import concat from 'lodash/fp/concat';
import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import remove from 'lodash/fp/remove';
import sortBy from 'lodash/fp/sortBy';

import { padding } from '@lumapps/classnames';
import { Theme, Checkbox, FlexBox, Text, Orientation, Size, Typography, ColorVariant } from '@lumapps/lumx/react';
import { PostType } from '@lumapps/posts/types';

import { PostListFilterBlockProps } from '../../../types';

export const PostTypeFilterBlock: React.FC<PostListFilterBlockProps<PostType>> = ({
    choices = [],
    selectedValues = [],
    onSelect,
    getChoiceName,
    theme,
    filterId,
    label,
}) => {
    const isDarkTheme = theme === Theme.dark;

    const handleChange = (isChecked: boolean, value: PostType) => {
        const nextSelectedValues = sortBy(
            identity,
            isChecked ? concat(value, selectedValues) : remove(isEqual(value), selectedValues),
        );

        onSelect(filterId, nextSelectedValues);
    };

    return (
        <FlexBox orientation={Orientation.vertical} gap={Size.regular}>
            {label && (
                <label htmlFor={filterId} className={padding('bottom', 'regular')}>
                    <Text
                        typography={Typography.subtitle1}
                        as="span"
                        color={isDarkTheme ? 'light' : 'dark'}
                        colorVariant={ColorVariant.N}
                    >
                        {label}
                    </Text>
                </label>
            )}
            <FlexBox id={filterId} orientation={Orientation.vertical} gap={Size.regular}>
                {map(
                    ({ value }) => (
                        <Checkbox
                            key={value}
                            isChecked={includes(value, selectedValues)}
                            label={getChoiceName(value)}
                            theme={theme}
                            value={value}
                            onChange={handleChange}
                        />
                    ),
                    choices,
                )}
            </FlexBox>
        </FlexBox>
    );
};
