import assign from 'lodash/assign';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import map from 'lodash/map';
import set from 'lodash/set';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import { mediaTypes } from './media_constant';

// eslint-disable-next-line require-jsdoc
function MediaService(
    $window,
    Config,
    Content,
    Features,
    InitialSettings,
    Instance,
    LumsitesBaseService,
    MediaConstant,
    MediaFactory,
    Translation,
    User,
    UserAccess,
    Utils,
) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(MediaFactory, {
        autoInit: false,
        fullListResponse: true,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The list of media that have not been assigned to any content during upload.
     *
     * @type {Array}
     */
    let _unassignedMedia = [];

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list of types of media we can filter on in the Media tab of the search.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    service.SEARCH_MEDIA_TYPES = [
        {
            name: 'DOCUMENT',
            value: Config.FILE_TYPES.DOCUMENT,
        },
        {
            name: 'IMAGE',
            value: Config.FILE_TYPES.IMAGE,
        },
    ];

    /**
     * The service default parameters.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     * The size the image should get according to widget size.
     *
     * @type {Object}
     * @readonly
     */
    /* eslint-disable id-length */
    service.imageSizeBreakpoints = {
        l: 1920,
        m: 1400,
        s: 800,
    };
    /* eslint-enable id-length */

    /**
     * The parameters for default search results in abstract picker.
     *
     * @type {Object}
     */
    service.defaultPickerParams = {
        attached: false,
        origin: 'FRONT.MEDIA_PICKER.FILTER_DIRECTORY.OFFICIAL_FILES',
        provider: 'FRONT.MEDIA_PICKER.PROVIDER_ORIGIN.LUMAPPS',
        sortOrder: MediaConstant.SORT_ORDER,
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the translation of a media property.
     *
     * @param  {Object}  property           An object to get the translation from.
     * @param  {boolean} [noFallback=false] Whether to use a fallback for the translation or not.
     * @return {string}  The translated property.
     */
    function _getMediaPropertyTranslation(property, noFallback = false) {
        if (angular.isUndefinedOrEmpty(property)) {
            return '';
        }

        const action = Content.getAction();

        if (angular.isDefinedAndFilled(action) && action !== 'get') {
            return Translation.translate_(property, Translation.inputLanguage, false);
        }

        return Translation.translate_(property, Translation.getLang('current'), !noFallback);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the id of a media.
     *
     * @param  {Object} media The media object to get the source of.
     * @return {string} The id.
     *
     */
    function getMediaId(media) {
        return media && (media.id || media.uid || media.uuid);
    }

    /**
     * Create a document from an old media.
     *
     * @param  {Object} media      The original media.
     * @param  {string} [provider] The origin of the media.
     *                             Possible values are: 'local' for LumDrive, 'drive' for Google Drive or 'onedrive'
     *                             for Microsoft SharePoint/OneDrive.
     *                             If none given, try to guess the origin of the media from the properties already
     *                             defined in the media.
     * @return {Object} The document.
     */
    function mediaToDocument(media, provider = media.source) {
        if (angular.isDefinedAndFilled(media.provider)) {
            return media;
        }

        /*
         * If there is no provider, try to guess it from the media to convert.
         * If there is a `parentFolderId` property, then the media is coming from a drive service.
         * At the time of the creation of old formatted media, there was only Google Drive available. So it has to
         * be GDrive, it can't be OneDrive.
         */
        const firstMediaContent = first(media.content) || {};

        const doc = {
            createdAt: media.createdAt,
            id: getMediaId(media),
            instanceId: media.instance,
            isFolder: false,
            isShared: false,
            isStarred: false,
            isTrashed: media.trashed,
            kind: 'Media',
            langProps: {
                default: {
                    // eslint-disable-next-line id-blacklist
                    data: firstMediaContent.servingUrl,
                    fileName: firstMediaContent.name,
                    mimeType: firstMediaContent.mimeType,
                    size: firstMediaContent.size,
                    thumbnail: `${firstMediaContent.servingUrl}=s176`,
                },
            },
            parentFolderId: media.parentFolderId,
            provider,
            tags: media.tags,
            uid: getMediaId(media),
            updatedAt: media.updatedAt,
        };

        if (angular.isDefinedAndFilled(media.title)) {
            angular.forEach(Object.keys(media.title), (lang) => {
                if (angular.isUndefined(doc.langProps[lang])) {
                    doc.langProps[lang] = {};
                }

                doc.langProps[lang].title = media.title[lang];
            });
        }

        if (angular.isDefinedAndFilled(media.description)) {
            angular.forEach(Object.keys(media.description), (lang) => {
                if (angular.isUndefined(doc.langProps[lang])) {
                    doc.langProps[lang] = {};
                }

                doc.langProps[lang].description = media.description[lang];
            });
        }

        return doc;
    }

    /**
     * Empty the trash.
     *
     * @param  {Function} cb      The success callback function.
     * @param  {Function} errorCb The error callback function.
     * @param  {string}   listKey The identifier of the list that is linked to the display of the loader.
     * @return {Object}   The return result, cb, errorCb and listKey.
     */
    function emptyTrash(cb, errorCb, listKey) {
        return MediaFactory.emptyTrash(
            {
                instance: Instance.getCurrentInstanceId(),
            },
            cb,
            errorCb,
            listKey,
        );
    }

    /**
     * Check if user can edit media.
     *
     * @param  {Object}  media The media object to check permissions of.
     * @return {boolean} Whether the media can be edited by the current user or not.
     */
    function canEditMedia(media) {
        if (UserAccess.canManageInstanceSettings()) {
            return true;
        }

        if (angular.isUndefinedOrEmpty(media)) {
            return false;
        }

        const user = User.getConnected();
        const props = media.properties;

        return angular.isDefined(props) && props.user && props.user.toString() === user.id.toString();
    }

    /**
     * Create a media link.
     *
     * @param  {Object} media The source media to create a link.
     * @return {string} The url to the media.
     */
    function createMediaLink(media) {
        if (angular.isUndefinedOrEmpty(get(media, 'content'))) {
            return '';
        }

        const mediaContent = service.getMediaContent(media, Translation.inputLanguage, true);

        const ext = get(mediaContent, 'ext');
        let link = '/serve/';

        link += get(mediaContent, 'value');
        link += '/';

        let mediaName = angular.copy(service.getMediaName(media, false));

        if (!includes(mediaName, ext)) {
            mediaName += angular.isDefined(ext) && ext.charAt(0) !== '.' ? '.' : '';
            mediaName += angular.isDefined(ext) ? ext : '';
        }

        link += encodeURIComponent(mediaName);

        return link;
    }

    /**
     * Download selected media.
     *
     * @param {Object} mediaLink The media link to download.
     */
    function downloadSelectedMedia(mediaLink) {
        $window.open(mediaLink, '_blank');
    }

    /**
     * Create a content from a file for a language.
     *
     * @param  {Object} file        The uploaded file to format.
     * @param  {string} lang        The language code to format the uploaded file in.
     * @param  {string} [source=''] The source of the file. Can be either Drive or local (library or upload).
     * @return {Object} A formatted media content for a language.
     */
    function formatContentForCurrentLang(file, lang, source = '') {
        const isFromDrive = source.toLowerCase() === MediaConstant.KIND.DRIVE.toLowerCase();

        return {
            ext: file.ext,
            fileId: file.id,
            height: file.height,
            lang,
            mimeType: file.mimeType,
            name: file.name,
            servingUrl: file.servingUrl || file.url,
            size: file.fileSize || file.sizeBytes,
            type: file.type,
            url: file.url,
            uuid: isFromDrive ? generateUUID() : undefined,
            value: file.blobKey,
            width: file.width,
        };
    }

    /**
     * Format an uploaded file to a Media object for a given language.
     *
     * @param  {Object} file       The uploaded file to format.
     * @param  {string} lang       The language code to format the uploaded file in.
     * @param  {string} [source]   The source of the file. Can be either drive or local (library or upload).
     * @param  {Object} [folderId] The current folder ID.
     * @return {Object} A formatted media object.
     */
    function formatUploadedFile(file, lang, source, folderId) {
        if (angular.isUndefinedOrEmpty([file, lang], 'some')) {
            return {};
        }

        const media = {
            content: [formatContentForCurrentLang(file, lang, source)],
            instance: Instance.getCurrentInstanceId(),
            name: {},
            owner: get(file, 'owner'),
            parentFolder: folderId,
            source: source || 'local',
        };

        media.name[lang] = file.name;

        return media;
    }

    /**
     * Return a preview url for the given file / image from drive.
     *
     * @param  {string}        id          The identifier of the file.
     * @param  {number|string} [width=512] The width (in pixels) of the preview image we want to retrieve.
     * @return {string}        The url of the preview image for a given file on drive.
     */
    function getDrivePreviewUrl(id, width = 512) {
        if (angular.isUndefinedOrEmpty(id)) {
            return undefined;
        }

        width = parseInt(width, 10);

        return `https://drive.google.com/thumbnail?authuser=0&sz=w${width}&id=${id}`;
    }

    /**
     * Get a file icon based on its mime type.
     * Note: these are NOT mdi-icon names.
     *
     * @param  {string} mimeType The mime-type name of the file we want the icon for.
     * @return {string} The name of the icon for a given mime-type.
     */
    function getIcon(mimeType) {
        if (angular.isUndefinedOrEmpty(mimeType)) {
            return '';
        }

        switch (mimeType.toLowerCase()) {
            case 'application/pdf':
                return 'pdf';

            case 'application/vnd.google-apps.audio':
            case 'audio/mpeg':
                return 'audio';

            case 'application/vnd.google-apps.document':
                return 'document';

            case 'application/vnd.google-apps.drawing':
                return 'drawing';

            case 'application/vnd.google-apps.file':
                return 'file';

            case 'application/vnd.google-apps.folder':
                return 'folder';

            case 'application/vnd.google-apps.form':
                return 'form';

            case 'application/vnd.google-apps.photo':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/gif':
            case 'image/png':
                return 'photo';

            case 'application/vnd.google-apps.presentation':
                return 'slide';

            case 'application/vnd.google-apps.spreadsheet':
                return 'sheet';

            case 'application/vnd.google-apps.video':
            case 'video/avi':
            case 'video/mov':
            case 'video/mp4':
            case 'video/mpeg':
            case 'video/quicktime':
                return 'video';

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
            case 'application/vnd.ms-word.document.macroenabled.12':
            case 'application/vnd.ms-word.template.macroenabled.12':
                return 'word';

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
            case 'application/vnd.ms-excel.sheet.macroenabled.12':
            case 'application/vnd.ms-excel.template.macroenabled.12':
            case 'application/vnd.ms-excel.addin.macroenabled.12':
            case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
                return 'excel';

            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'application/vnd.ms-powerpoint.addin.macroenabled.12':
            case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
            case 'application/vnd.ms-powerpoint.template.macroenabled.12':
            case 'application/vnd.ms-powerpoint.slideshow.macroenabled.12':
                return 'powerpoint';

            case 'application/x-rar':
            case 'application/zip':
                return 'archive';

            default:
                return 'default';
        }
    }

    /**
     * Get media icon from the extension of the file.
     *
     * @param  {string} mediaExt The extension of the file.
     * @return {string} The icon of the media.
     */
    function getMediaIconFromExt(mediaExt) {
        const defaultIcon = 'default';
        if (angular.isUndefinedOrEmpty(mediaExt)) {
            return defaultIcon;
        }

        let icon = defaultIcon;
        switch (mediaExt) {
            case 'mp3':
            case 'mid':
            case 'wav':
                icon = 'audio';

                break;

            case 'pdf':
                icon = 'pdf';

                break;

            case 'bmp':
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                icon = 'photo';

                break;

            case 'ppt':
            case 'pptx':
                icon = 'powerpoint';

                break;

            case 'xls':
            case 'xlsx':
                icon = 'excel';

                break;

            case 'doc':
            case 'docx':
                icon = 'word';

                break;

            case 'odt':
            case 'rtf':
            case 'txt':
                icon = 'sheet';

                break;

            case 'mp4':
            case 'mov':
            case 'avi':
            case 'mpeg':
                icon = 'video';

                break;

            case 'zip':
            case 'rar':
                icon = 'archive';

                break;

            default:
                icon = defaultIcon;

                break;
        }

        return icon;
    }

    /**
     * Get the media content in a given language.
     *
     * @param  {Object}  media               The media object to get the content of.
     * @param  {string}  [lang]              The language code in which to get the translation.
     * @param  {boolean} [useFallback=false] Whether or not to use the media content of the fallback language.
     * @return {Object}  The content of the media in the wanted language.
     */
    function getMediaContent(media, lang, useFallback = false) {
        if (angular.isUndefinedOrEmpty(media)) {
            return {};
        }

        const mediaContents = angular.isDefinedAndFilled(media.croppedContent) ? media.croppedContent : media.content;
        const refactoredMedia = {};

        angular.forEach(
            mediaContents,
            ({
                downloadUrl,
                ext,
                fileId: id,
                height,
                lang: mediaLang,
                mimeType,
                name: mediaName,
                servingUrl,
                size,
                type,
                url,
                value,
                width,
            }) => {
                refactoredMedia[mediaLang] = {
                    downloadUrl,
                    ext,
                    height,
                    id,
                    mimeType,
                    name: mediaName,
                    servingUrl,
                    size,
                    source: service.getSource(media),
                    type,
                    url,
                    value,
                    width,
                };
            },
        );
        if (angular.isDefinedAndFilled(lang)) {
            if (angular.isDefined(refactoredMedia[lang])) {
                return refactoredMedia[lang];
            } else if (useFallback && Translation.hasTranslations(refactoredMedia)) {
                return Translation.translate(refactoredMedia);
            }
            return {};
        }
        return refactoredMedia;
    }

    /**
     * Get media icon depending on the media object.
     *
     * @param  {Object}  media       The media object to get the media icon of.
     * @param  {boolean} useFallback Whether or not to use a fallback value if none found.
     * @return {string}  The icon classname.
     */
    function getMediaIcon(media, useFallback) {
        const mediaContent = service.getMediaContent(media, Translation.inputLanguage, useFallback);

        if (get(media, 'source', '').toLowerCase() === MediaConstant.KIND.DRIVE.toLowerCase()) {
            return 'mdi-google-drive';
        }

        if (get(media, 'kind', '').toLowerCase() === MediaConstant.KIND.FOLDER.toLowerCase()) {
            return 'mdi-folder';
        }

        if (angular.isUndefinedOrEmpty(mediaContent)) {
            return 'mdi-close';
        }

        if (service.isImage(mediaContent.type) && angular.isDefined(mediaContent.value)) {
            return 'mdi-image';
        }

        if (service.isDocument(mediaContent.type) && angular.isDefined(mediaContent.value)) {
            return 'mdi-file-document-box';
        }

        return 'mdi-close';
    }

    /**
     * Get media thumbnail if document otherwise get media content.
     *
     * @param  {Object}  media       The media object to get the image of.
     * @param  {boolean} useFallback Whether or not to use a fallback value if none found.
     * @param  {number}  [size]      The new size of the image we want it resized to.
     * @return {string}  The media image.
     */
    function getBackgroundImage(media, useFallback, size) {
        const lang = Translation.inputLanguage;
        const mediaContent = getMediaContent(media, lang, useFallback);
        let backgroundImage;

        if (angular.isUndefinedOrEmpty(mediaContent)) {
            return undefined;
        }

        if (
            angular.isDefinedAndFilled(service.getSource(mediaContent)) &&
            service.getSource(mediaContent).toLowerCase() === MediaConstant.KIND.DRIVE.toLowerCase() &&
            !Translation.hasTranslations(media.thumbnail)
        ) {
            backgroundImage = service.getDrivePreviewUrl(mediaContent.id);
        } else if (service.isImage(mediaContent.type)) {
            // We dont want to resize svg nor gifs.
            size = includes(['image/gif', 'image/svg+xml'], mediaContent.mimeType) ? -1 : size;

            backgroundImage = service.getMediaImage(media, mediaContent, undefined, size);
        } else if (service.isDocument(mediaContent.type)) {
            if (angular.isDefined(media.thumbnail)) {
                backgroundImage = Utils.getMediaUrl(
                    Translation.translate_(media.thumbnail, lang, useFallback),
                );
            }
        } else if (angular.isDefinedAndFilled(mediaContent.url)) {
            backgroundImage = Utils.getMediaUrl(mediaContent.url);
        }

        return angular.isDefinedAndFilled(backgroundImage) ? Utils.getBackgroundImage(backgroundImage, -1) : undefined;
    }

    /**
     * Get the description of a media object.
     *
     * @param  {Object}  media              The media object to get the description of.
     * @param  {boolean} [noFallback=false] Whether to use a fallback for the translation or not.
     * @return {string}  The media object description.
     */
    function getMediaDescription(media, noFallback) {
        if (angular.isUndefinedOrEmpty(media)) {
            return '';
        }

        if (Translation.hasTranslation(get(media, 'override.description'), noFallback ? undefined : 'any')) {
            return _getMediaPropertyTranslation(media.override.description, noFallback);
        }

        return _getMediaPropertyTranslation(get(media, 'description', ''), noFallback);
    }

    /**
     * Build a media object before save or after drive selection.
     *
     * @param  {Object} file       A file object.
     * @param  {Object} content    The content which will contain the file.
     * @param  {string} [from]     Provenance of the file either Drive or local (library or upload).
     * @param  {string} [folderId] If the file has to be added to a specific folder.
     * @return {Object} A media object.
     */
    function getMediaFromFile(file, content, from, folderId) {
        const mediaToSave = formatUploadedFile(file, Translation.inputLanguage, from, folderId);

        if (angular.isDefinedAndFilled(content)) {
            set(mediaToSave, 'properties.content', content.id);

            mediaToSave.customContentType = content.customContentType;

            if (isNaN(content.id)) {
                mediaToSave.status = Config.CONTENT_STATUS.TO_VALIDATE.value;
            } else {
                mediaToSave.contentKey = content.id;
            }
        }

        return mediaToSave;
    }

    /**
     * Get the media image url.
     *
     * @param  {Object|string} media                   The media object to get the image from.
     * @param  {Object|string} [mediaContent={Object}] The content details of the media object.
     *                                                 Defaults to the details of the media parameter.
     * @param  {boolean}       [useServingUrl=false]   Whether or not to use the serving url for the image.
     * @param  {number}        [size=512]              A specific size to resize the media image to.
     * @param  {Object}        [overrides={}]          Contains some overrides for the media thumbnail.
     * @return {string}        The url of the media image.
     */
    function getMediaImage(
        media,
        mediaContent = Translation.translate(service.getMediaContent(media)),
        useServingUrl = false,
        size = 512,
        overrides = {},
    ) {
        if (angular.isUndefinedOrEmpty(media)) {
            return '';
        }

        if (angular.isString(media)) {
            return Utils.resizeImage(Utils.getMediaUrl(media), size);
        }

        if (angular.isUndefinedOrEmpty(mediaContent) && !service.isDocument(get(first(mediaContent), 'type'))) {
            return '';
        }

        if (angular.isString(mediaContent)) {
            return Utils.getMediaUrl(mediaContent);
        }

        const { thumbnail: mediaThumbnail } = media;
        const {
            id: mediaContentId,
            mimeType: mediaContentMimeType,
            servingUrl: mediaContentServingUrl,
            type: mediaContentType,
            value: mediaContentValue,
            width: mediaContentWidth,
        } = mediaContent;
        if (service.getSource(mediaContent) === 'drive' && !Translation.hasTranslations(mediaThumbnail)) {
            return service.getDrivePreviewUrl(mediaContentId);
        }
        if (service.isImage(mediaContentType)) {
            // Don't resize if gif, svg or image smaller than current defined size.
            size =
                mediaContentMimeType === 'image/gif' ||
                mediaContentMimeType === 'image/svg+xml' ||
                mediaContentWidth < size
                    ? 0
                    : size;

            let mediaUrl;

            if (angular.isDefinedAndFilled(mediaContentServingUrl) && useServingUrl) {
                mediaUrl = mediaContentServingUrl;
            } else if (angular.isDefinedAndFilled(mediaContentValue)) {
                mediaUrl = Utils.getMediaUrl(mediaContentValue);
            }

            return size === -1 ? mediaUrl : Utils.resizeImage(mediaUrl, size);
        } else if (
            service.isDocument(mediaContentType) ||
            service.isDocument(media.content[0].type) ||
            includes([mediaTypes.GOOGLE.FILE, mediaTypes.ONEDRIVE.FILE], service.getType(media))
        ) {
            if (Translation.hasTranslations(overrides.thumbnail)) {
                return Utils.getMediaUrl(Translation.translate(overrides.thumbnail));
            }

            if (angular.isDefinedAndFilled(mediaThumbnail)) {
                return Utils.getMediaUrl(Translation.translate(mediaThumbnail));
            }
        }

        return '';
    }

    /**
     * Get a media picker media model.
     *
     * @param  {Object} media The media to get model.
     * @return {Object} The media as a media picker model.
     */
    function getMediaPickerMediaModel(media) {
        return {
            id: getMediaId(media),
            object: media,
        };
    }

    /**
     * Adjust background image of a `style` object by transforming
     * the `backgroundImage` object into a CSS directive.
     *
     * @param  {Object} style The style object that `ng-style` can understand.
     * @return {Object} The same style object.
     */
    function adjustBackgroundImage(style) {
        if (angular.isDefinedAndFilled(style.backgroundImage)) {
            return assign({}, style, {
                backgroundImage: Utils.getImageURL(
                    service.getMediaImage(style.backgroundImage, undefined, false, InitialSettings.MAX_IMAGE_SIZE),
                ),
            });
        }

        return style;
    }

    /**
     * Get the name of a media object.
     *
     * @param  {Object}  media              The media object to get the name of.
     * @param  {boolean} [noFallback=false] Whether to use a fallback for the translation or not.
     * @return {string}  The name of the media.
     */
    function getMediaName(media, noFallback = false) {
        if (angular.isUndefinedOrEmpty(media)) {
            return '';
        }

        if (Translation.hasTranslation(get(media, 'override.name'), noFallback ? undefined : 'any')) {
            return _getMediaPropertyTranslation(media.override.name, noFallback);
        }

        return _getMediaPropertyTranslation(media.name, noFallback);
    }

    /**
     * Get the source of a media.
     *
     * @param  {Object} media The media object to get the source of.
     * @return {string} The source name.
     *
     * TODO [Arnaud]: remove this backward (run backend script to remove the 'from' property).
     */
    function getSource(media) {
        // Backward: 'from' has been replaced by 'source'.
        return media.source || media.from;
    }

    /**
     * Get the docPath of a media.
     *
     * @param  {Object} media The media object to get the docPath of.
     * @return {string} The docPath.
     *
     */
    function getDocPath(media) {
        let provider = service.getSource(media) || MediaConstant.PROVIDERS.lumapps;
        let resource = media.content[0].fileId || getMediaId(media);
        if (provider === MediaConstant.PROVIDERS.lumapps) {
            const instanceId = media.instance || Instance.getCurrentInstanceId();
            provider += `/site=${instanceId}`;
        }

        return media.docPath || `provider=${provider}/resource=${resource}`;
    }

    /**
     * Get the type of the media.
     *
     * @param  {Object} media The media to get the type.
     * @return {string} Type of the media.
     */
    function getType(media) {
        return get(media, 'properties.type');
    }

    /**
     * Get the list of unassigned media.
     *
     * @return {Array} A list of media items.
     */
    function getUnassignedMedia() {
        return _unassignedMedia;
    }

    /**
     * Check if a media item is a document.
     *
     * @param  {string}  type The type of the file to check.
     * @return {boolean} Whether the media item is a document or not.
     */
    function isDocument(type) {
        return includes(Config.FILE_TYPES.DOCUMENT, type);
    }

    /**
     * Check if mime type is that of a document.
     *
     * @param  {string}  mimeType MimeType to check.
     * @return {boolean} Whether or not it's a document mimeType.
     */
    function isDocumentMimeType(mimeType) {
        return service.isDocument(Utils.getMimeTypeMediaType(mimeType));
    }

    /**
     * Check if a media item is an image.
     *
     * @param  {string}  type The type of the file to check.
     * @return {boolean} Whether the media item is an image or not.
     */
    function isImage(type) {
        return includes(Config.FILE_TYPES.IMAGE, type);
    }

    /**
     * Check if mime type is that of an image.
     *
     * @param  {string}  mimeType MimeType to check.
     * @return {boolean} Whether or not it's an image mimeType.
     */
    function isImageMimeType(mimeType) {
        return service.isImage(Utils.getMimeTypeMediaType(mimeType));
    }

    /**
     * Check if a media item is a video.
     *
     * @param  {string}  type The type of the file to check.
     * @return {boolean} Whether the media item is a video or not.
     */
    function isVideo(type) {
        return includes(Config.FILE_TYPES.VIDEO, type);
    }

    /**
     * Check if mime type is that of a video.
     *
     * @param  {string}  mimeType MimeType to check.
     * @return {boolean} Whether or not it's a video mimeType.
     */
    function isVideoMimeType(mimeType) {
        return service.isVideo(Utils.getMimeTypeMediaType(mimeType));
    }

    /**
     * Find the filter type from the selected file type.
     *
     * @param  {Object}   type The type media object that is selected.
     * @param  {Function} [cb] A callback function to execute.
     * @return {string}   The media type.
     */
    function mediaTypeToName(type, cb = angular.noop) {
        const { name: mediaTypeName } = type;

        cb(mediaTypeName);

        return mediaTypeName;
    }

    /**
     * Retrieve the full media type object from its name.
     *
     * @param  {string}   typeName The name of the file type to find.
     * @param  {Function} [cb]     A callback function to execute once the media type is found.
     * @return {Object}   A type of media.
     */
    function nameToMediaType(typeName, cb = angular.noop) {
        const type = loFind(service.SEARCH_MEDIA_TYPES, { name: typeName });

        cb(type);

        return type;
    }

    /**
     * Transform model for media picker.
     *
     * @param  {Object|Array} media        The media to transform.
     * @param  {boolean}      isMediaModel Whether or not the model is a media.
     * @return {Object}       The transformed model.
     */
    function transformForMediaPicker(media, isMediaModel) {
        if (angular.isUndefinedOrEmpty(media) || !isMediaModel) {
            return angular.fastCopy(media);
        }

        if (angular.isArray(media)) {
            return map(media, getMediaPickerMediaModel);
        }

        return getMediaPickerMediaModel(media);
    }

    /**
     * Transform model for the "client", such as file list widget, or featured image.
     *
     * @param  {Object|Array} media        The media to transform.
     * @param  {boolean}      isMediaModel Whether or not the model is a media.
     * @return {Object}       The transformed model.
     */
    function transformForMediaClient(media, isMediaModel) {
        if (angular.isUndefinedOrEmpty(media) || !isMediaModel) {
            return media;
        }

        if (angular.isArray(media)) {
            return map(media, 'object');
        }

        return media.object;
    }

    /**
     * Untrash mediaLits items.
     *
     * @param  {Array}    mediaList The mediaList items to remove from trash.
     * @param  {Function} cb        Whether to use a fallback for the translation or not.
     * @param  {Function} errorCb   Whether to use a fallback for the translation or not.
     * @param  {string}   listKey   Whether to use a fallback for the translation or not.
     * @return {Object}   The return answer, cb, errorCb and listKey.
     */
    function untrash(mediaList, cb, errorCb, listKey) {
        const tempIdsToUntrash = [];

        angular.forEach(mediaList, (media) => {
            tempIdsToUntrash.push({
                kind: MediaConstant.KIND.MEDIA,
                uid: getMediaId(media),
            });
        });

        return MediaFactory.untrash(
            {
                instance: Instance.getCurrentInstanceId(),
                itemIds: tempIdsToUntrash,
            },
            cb,
            errorCb,
            listKey,
        );
    }

    /**
     * Set the list of unassigned media.
     *
     * @param {Array} media A list of media items.
     */
    function setUnassignedMedia(media) {
        _unassignedMedia = media;
    }

    /////////////////////////////

    service.adjustBackgroundImage = adjustBackgroundImage;
    service.canEditMedia = canEditMedia;
    service.createMediaLink = createMediaLink;
    service.downloadSelectedMedia = downloadSelectedMedia;
    service.emptyTrash = emptyTrash;
    service.formatContentForCurrentLang = formatContentForCurrentLang;
    service.formatUploadedFile = formatUploadedFile;
    service.getBackgroundImage = getBackgroundImage;
    service.getDrivePreviewUrl = getDrivePreviewUrl;
    service.getIcon = getIcon;
    service.getMediaContent = getMediaContent;
    service.getMediaDescription = getMediaDescription;
    service.getMediaFromFile = getMediaFromFile;
    service.getMediaIcon = getMediaIcon;
    service.getMediaIconFromExt = getMediaIconFromExt;
    service.getMediaId = getMediaId;
    service.getMediaImage = getMediaImage;
    service.getMediaName = getMediaName;
    service.getSource = getSource;
    service.getDocPath = getDocPath;
    service.getType = getType;
    service.getUnassignedMedia = getUnassignedMedia;
    service.isDocument = isDocument;
    service.isDocumentMimeType = isDocumentMimeType;
    service.isImage = isImage;
    service.isImageMimeType = isImageMimeType;
    service.mediaToDocument = mediaToDocument;
    service.mediaTypeToName = mediaTypeToName;
    service.nameToMediaType = nameToMediaType;
    service.isVideo = isVideo;
    service.isVideoMimeType = isVideoMimeType;
    service.transformForMediaClient = transformForMediaClient;
    service.transformForMediaPicker = transformForMediaPicker;
    service.untrash = untrash;
    service.setUnassignedMedia = setUnassignedMedia;
    service.untrash = untrash;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = () => {
        service.defaultParams = {
            instance: Instance.getCurrentInstanceId(),
        };

        service.imageSizeBreakpoints.l = InitialSettings.MAX_IMAGE_SIZE;
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Media', MediaService);

/////////////////////////////

export { MediaService };
