import { useSelector } from '@lumapps/redux/react';
import { getSuggestions } from '@lumapps/search/ducks/selectors';
import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';
/**
 * Get queries suggestions from the store
 * and convert them to "suggestion" type search suggestions.
 * @param instanceId the current instance id
 */
export const useQueriesSuggestions = (): SearchSuggestion[] => {
    const queriesSuggestions = useSelector(getSuggestions).map((querySuggestion) => {
        return { ...querySuggestion, type: SearchSuggestionType.SUGGESTION };
    });
    return queriesSuggestions;
};
