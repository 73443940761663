import React from 'react';

import repeat from 'lodash/repeat';

import { useTranslate } from '@lumapps/translations';

import { PickerMetaData, MetadataPickerProps } from '../types';
import { useFetchMetadataFamilyTree } from './useFetchMetadataFamilyTree';

export interface UseMetadataPickerProps
    extends Pick<
        MetadataPickerProps,
        | 'familyId'
        | 'instanceId'
        | 'defaultDictionary'
        | 'onChange'
        | 'extraRequestFields'
        | 'useInstanceMetadata'
        | 'showMultipleLevels'
    > {
    multiple?: boolean;
}

/**
 * Hook to manage the Metadata Pickers (simple and multiple).
 *
 * The hook will
 * * Fetch the metadata whole family on select open
 * * Manage the search field and choice filtering
 * * Manage choice name formatting with the correct level
 * * Manage action on choice select
 */
export const useMetadataPicker = ({
    familyId,
    instanceId,
    defaultDictionary = {},
    onChange,
    multiple,
    extraRequestFields,
    useInstanceMetadata,
    showMultipleLevels,
}: UseMetadataPickerProps) => {
    const { translateObject } = useTranslate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');

    const { status, metadataList, metadataDictionary, fetchMetadataFamily } = useFetchMetadataFamilyTree({
        familyId,
        instanceId,
        shouldFetch: isOpen,
        defaultDictionary,
        extraRequestFields,
        useInstanceMetadataApi: useInstanceMetadata,
        showMultipleLevels,
    });

    /** Choices filtered by the current search text */
    const filteredChoices = React.useMemo(
        () =>
            metadataList.filter((metadata: PickerMetaData) => {
                if (metadata.id === familyId) {
                    return false;
                }

                if (!searchText) {
                    return true;
                }
                const translatedName = translateObject(metadata.name) || metadata.id;

                return translatedName.trim().toLowerCase().includes(searchText.trim().toLowerCase());
            }),
        [familyId, metadataList, searchText, translateObject],
    );

    /** Action when a picker value is selected.  */
    const handleSelect: MetadataPickerProps['onChange'] = (selectedValue) => {
        if (onChange) {
            onChange(selectedValue);
        }
        // Keep select open for multi select
        if (!multiple) {
            setIsOpen(false);
        }
    };

    /** Get the metadata value name with level indicator */
    const getValueName = (metadata: PickerMetaData) => {
        if (!metadata) {
            return '';
        }

        if (metadata && metadata.name) {
            const metadataName = translateObject(metadata.name);
            return `${repeat('— ', metadata.level || 0)}${metadataName}`;
        }

        return metadata.id;
    };

    return {
        isOpen,
        setIsOpen,
        status,
        choices: metadataList,
        metadataDictionary,
        filteredChoices,
        searchText,
        setSearchText,
        fetchMetadataFamily,
        handleSelect,
        getValueName,
    };
};
