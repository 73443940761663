/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import { combineReducers } from '@lumapps/redux';

import * as topicEntitiesSlice from './TopicEntities/slice';
import * as topicManagementFormSlice from './TopicManagementForm/slice';
import { SaTopicsState } from './types';

export const initialState: SaTopicsState = {
    entities: topicEntitiesSlice.initialState,
    topicManagementForm: topicManagementFormSlice.initialState,
};

export const reducer = combineReducers({
    entities: topicEntitiesSlice.reducer,
    topicManagementForm: topicManagementFormSlice.reducer,
});

export const topicEntitiesActions = topicEntitiesSlice.actions;
export const topicManagementFormActions = topicManagementFormSlice.actions;
