import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import get from 'lodash/get';

import * as styleTypes from '../../types';
import { AreaLayout } from './AreaLayout';
import { AreaSettings } from './AreaSettings';

/**
 * Renders an area proxy enabling the edition of the icon and label position
 * and, through an expandable inspector, the edition of styling properties.
 */
export class Area extends React.PureComponent {
    static propTypes = {
        /** Whether this area is active (that is, selected and editable) or not. */
        active: propTypes.bool,
        /** Default values. */
        defaults: styleTypes.areaStyle.isRequired,
        name: styleTypes.areaType.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Called when toggling between default/hovering style state. */
        onChangeState: propTypes.func,
        /** Called when clicking on the area to toggle its activation. */
        onToggle: propTypes.func.isRequired,
        /** Current state style. */
        state: styleTypes.state,
        /** Current area style object. */
        value: styleTypes.areaStyle,
    };

    constructor(props) {
        super(props);
        this.toggleActive = (occurrence) => this.props.onToggle(this.props.name, occurrence);
    }

    render() {
        const { value, name: key, active, onChange, defaults, state, onChangeState, hide } = this.props;
        const display = get(value, 'main.display', 'block');

        return (
            <div
                className={classNames(
                    'widget-style-area',
                    `widget-style-area--${key}`,
                    active && 'widget-style-area--is-active',
                    `widget-style-area--mode-${display}`,
                )}
            >
                <AreaLayout
                    hide={hide}
                    value={value}
                    name={key}
                    onChange={onChange}
                    defaults={defaults}
                    display={display}
                    onClick={this.toggleActive}
                />
                <div className="widget-style-area__settings">
                    {active && (
                        <AreaSettings
                            value={value}
                            onChange={onChange}
                            name={key}
                            defaults={defaults}
                            onChangeState={onChangeState}
                            state={state}
                        />
                    )}
                </div>
            </div>
        );
    }
}
