import { Template } from '@lumapps/content-templates/types';
import { DirectoryTag } from '@lumapps/directories-tags/types';
import { TranslateObject } from '@lumapps/translations';

/** The different field type available in a directory template. */
export enum DirectoryTemplateComponentType {
    inputText = 'inputText',
    /** A select field. The type of select (user etc...) is defined by the secondaryType. */
    inputSelect = 'inputSelect',
}

export interface DirectoryComponentProperties {
    availableValues?: TranslateObject[];
    mandatory?: boolean;
    textarea?: boolean;
    multiple?: boolean;
}

export interface DirectoryTemplateComponent {
    /** The Id of the template component */
    uuid: string;
    /** The type of field. */
    type: DirectoryTemplateComponentType;
    /** The title of the field. */
    title: TranslateObject;
    /** The properties of the field. */
    properties?: DirectoryComponentProperties;
}

/** The directory taken from the server */
export type Directory = {
    instanceSlug: string;
    id: string;
    slug: Record<string, string>;
    contentId: string;
    instance: string;
    properties: {
        icon?: string;
    };
    name: Record<string, string>;
    type?: string;
    favorites?: boolean;
    inherited?: boolean;
    tags?: DirectoryTag[];
    entriesHasMetadata?: boolean;
    template?: Template<DirectoryTemplateComponent>;
};
