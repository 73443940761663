import { isMacUser } from '@lumapps/utils/browser/isMacUser';

/**
 * Keyboard key for shortcuts, depending on the operating system
 */
export const SYSTEM_KEY = isMacUser() ? '⌘' : 'Ctrl';

/**
 * Function for a return correct combination shortcuts, depending on the operating system
 */
export const formatShortcut = (isOSDependent: boolean, keyCombination: string) => {
    return isOSDependent && SYSTEM_KEY === '⌘' ? `${SYSTEM_KEY}${keyCombination}` : `${SYSTEM_KEY}+${keyCombination}`;
};
