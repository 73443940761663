import { useEffect } from 'react';

import flow from 'lodash/fp/flow';
import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import mapKeys from 'lodash/fp/mapKeys';
import omitBy from 'lodash/fp/omitBy';
import snakeCase from 'lodash/fp/snakeCase';

import { and } from '@lumapps/utils/function/predicate/and';
import { not } from '@lumapps/utils/function/predicate/not';
import { or } from '@lumapps/utils/function/predicate/or';
import { useUpdateWidgetURLFilters } from '@lumapps/widget-base/hooks/useUpdateWidgetURLFilters';

import { GenericListFilterType, URLGenericListFiltersType } from '../types';
import { formatGenericFiltersForApi } from '../utils/formatGenericFiltersForApi';

/*
 * This hook gather Content List active filters, format them, then update the URL accordingly.
 * @param activeFiltersValues currently used filters for the content list
 */
export const useUpdateGenericListURLFilters = (activeFiltersValues?: Partial<GenericListFilterType>) => {
    const { updateURLFilters } = useUpdateWidgetURLFilters();

    /** Making sure to transform the filter redux store type in a digestible way for the API */
    const trimmedFilters = formatGenericFiltersForApi(activeFiltersValues);

    /*
     * This function is aiming to remove any undefined or empty values of the provided object,
     * then turning the object keys from camel case into snake case.
     */
    const formatFiltersForURL = flow(omitBy(or(isNil, and(isEmpty, not(isBoolean)))), mapKeys(snakeCase));

    useEffect(() => {
        if (activeFiltersValues) {
            const filters: URLGenericListFiltersType = formatFiltersForURL(trimmedFilters);

            updateURLFilters(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFiltersValues]);
};
