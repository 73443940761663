/* eslint-disable no-param-reassign */
import keyBy from 'lodash/keyBy';
import unset from 'lodash/unset';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Topic } from '../../types';

export interface TopicEntitiesState {
    [topicId: string]: Topic;
}

const initialState: TopicEntitiesState = {};

const { actions, reducer } = createSlice({
    domain: 'saTopics/entities',
    initialState,
    reducers: {
        addTopics: (state: TopicEntitiesState, action: PayloadAction<Topic[]>) => {
            return { ...state, ...keyBy(action.payload, 'id') };
        },
        addTopic: (state: TopicEntitiesState, action: PayloadAction<Topic>) => {
            const topic = action.payload;
            return { ...state, [topic.id]: topic };
        },
        removeTopic: (state: TopicEntitiesState, action: PayloadAction<Topic['id']>) => {
            unset(state, action.payload);
        },
    },
});

export { actions, reducer, initialState };
