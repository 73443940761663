import React from 'react';

import { typography } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { ColorBubbleLetter } from '@lumapps/lumx-texts/components/ColorBubbleLetter';
import { GenericEntityBlock } from '@lumapps/lumx/components/Deprecated/GenericEntityBlock';
import {
    AspectRatio,
    ColorPalette,
    Link as LinkUI,
    Size,
    Theme,
    Thumbnail,
    ThumbnailVariant,
} from '@lumapps/lumx/react';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockLink as BlockLinkProps } from '../../types';

const Config = get();

export const BlockLink: BlockComponent<BlockLinkProps> = ({
    theme = Theme.light,
    targetBlank,
    thumbnail,
    title,
    link,
    description,
    hasThumbnail = true,
}) => {
    const itemThumbnail = makeSecuredMediaURLRelative(thumbnail);

    const url = sanitizeUrl(link);

    return (
        <GenericEntityBlock
            theme={theme}
            noWrap={false}
            thumbnail={
                hasThumbnail && (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {itemThumbnail ? (
                            <Thumbnail
                                size={Size.m}
                                image={itemThumbnail}
                                alt={title}
                                variant={ThumbnailVariant.squared}
                                aspectRatio={AspectRatio.square}
                            />
                        ) : (
                            <ColorBubbleLetter size={Size.m} colors={Config.defaultColors} text={title} />
                        )}
                    </>
                )
            }
            title={
                <LinkUI
                    color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                    href={url}
                    target={targetBlank ? '_blank' : undefined}
                >
                    {title}
                </LinkUI>
            }
            titleProps={{ className: typography('custom-title5') }}
            description={description}
        />
    );
};
