import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiDotsVertical, mdiCircle, mdiMenuDown, mdiPlus } from '@lumapps/lumx/icons';
import {
    ButtonSize,
    ColorPalette,
    Emphasis,
    Icon,
    Size,
    Theme,
    ButtonProps,
    IconButton,
    Button,
} from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { Menu } from '../Menu';
import { MenuProps } from '../Menu/Menu';
import { MenuItems } from './MenuItems';
import { MenuOption, MenuOptionBasic, MenuOptionType } from './types';

import './index.scss';

export interface ContextMenuProps {
    /** set this to true if you want a circle icon instead of three vertical dots */
    showCircle?: boolean;
    /** set this to true if you want a double icon button with a select look like */
    plusButtonStyle?: boolean;
    /** The options to pass to create the submenu. If it's an Array of arrays, we have multiple sections */
    menuOptions: Array<MenuOption> | Array<Array<MenuOption>>;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
    /** className of the Icon button */
    className?: string;
    /** Light/dark theme. */
    theme?: Theme;
    /** Translated label to set on the context menu. Will default to GLOBAL.OPTION */
    label?: string;
    /** Props to forward to the dropdown element. */
    dropdownProps?: Partial<MenuProps>;
    /** custom icon to use */
    icon?: string;
    /** whether the menu is open by default or not */
    isMenuOpen?: boolean;
    /** whether the context menu is disabled or not */
    isDisabled?: boolean;
    /** props passed to the button */
    buttonProps?: Partial<ButtonProps>;
}

const CLASSNAME = 'context-menu';

/**
 * Small wrapper component that takes a configuration props and create an icon that opens a context menu.
 * Has an option to either show a circle (for unread notification) or vertical dots icon.
 *
 * @family Menus
 */
export const ContextMenu: React.FC<ContextMenuProps> = ({
    showCircle = false,
    plusButtonStyle = false,
    menuOptions,
    scope,
    className,
    theme,
    label,
    dropdownProps,
    icon,
    isMenuOpen = false,
    isDisabled = false,
    buttonProps,
    ...forwardedProps
}) => {
    const { get } = useDataAttributes(scope || 'context-menu');
    const { translateKey } = useTranslate();
    const [submenuOpen, setSubmenuOpen] = React.useState(isMenuOpen);
    const [panelOpenedIndex, setPanelOpenedIndex] = React.useState<number>();
    const submenuRef = React.useRef(null);
    const buttonRefs = mergeRefs([submenuRef, buttonProps?.ref]);
    const { element, block } = useClassnames(CLASSNAME);

    const onToggleSubMenuClick = (evt: React.MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();
        setSubmenuOpen(!submenuOpen);
    };

    const onToggleSubMenuItemClick = (evt: React.MouseEvent, currentIndex: number) => {
        evt.stopPropagation();
        evt.preventDefault();
        setPanelOpenedIndex(panelOpenedIndex === currentIndex ? undefined : currentIndex);
    };

    const handleSelect = (onSelect?: (evt?: React.MouseEvent) => void) => (evt: React.MouseEvent) => {
        evt.stopPropagation();

        if (onSelect) {
            onSelect(evt);
        }
        setSubmenuOpen(false);
    };

    const notificationIconProps = React.useMemo(
        () =>
            showCircle
                ? {
                      icon: mdiCircle,
                      color: ColorPalette.primary,
                      size: Size.s as ButtonSize,
                      style: {
                          margin: '6px',
                      },
                  }
                : {
                      icon: icon || mdiDotsVertical,
                  },
        [icon, showCircle],
    );

    const renderMenuItems = (menuOptionsArray: Array<MenuOption>) => {
        return menuOptionsArray.map((option, index) => {
            // Divider Option
            if (option.type === MenuOptionType.divider) {
                // eslint-disable-next-line react/no-array-index-key
                return <Menu.Divider key={`${option.type}_${index}`} />;
            }

            return (
                <MenuItems
                    key={(option as MenuOptionBasic).labelKey}
                    option={option as MenuOptionBasic}
                    submenuRef={submenuRef}
                    className={CLASSNAME}
                    onToggleSubMenuItemClick={(evt: React.MouseEvent) => onToggleSubMenuItemClick(evt, index)}
                    handleSelect={handleSelect((option as MenuOptionBasic).onSelect)}
                    renderMenuItems={renderMenuItems}
                />
            );
        });
    };

    /**
     * Create a section within the menu
     *
     * @param {Array<MenuOption>} menuOptionsArray
     */
    const renderMenuSection = (menuOptionsArray: Array<MenuOption>) => renderMenuItems(menuOptionsArray);

    const buttonLabel = label || translateKey(GLOBAL.OPTIONS);

    const triggerButtonProps = plusButtonStyle
        ? {
              className: element('plus-button'),
              rightIcon: mdiMenuDown,
              icon: mdiPlus,
              alt: buttonLabel,
              ...get({ element: 'button', action: 'plus-button' }),
          }
        : {
              className,
              theme,
              ...get({ element: 'button', action: 'more' }),
              ...notificationIconProps,
          };

    return (
        <Menu.Trigger popoverProps={{ placement: 'bottom-end' }}>
            <Menu.TriggerButton
                as={plusButtonStyle ? Button : IconButton}
                ref={buttonRefs}
                emphasis={Emphasis.low}
                label={buttonLabel}
                isDisabled={isDisabled}
                theme={theme}
                onClick={onToggleSubMenuClick}
                {...buttonProps}
                {...forwardedProps}
                {...triggerButtonProps}
            >
                {plusButtonStyle && <Icon icon={mdiPlus} alt={buttonLabel} />}
            </Menu.TriggerButton>

            <Menu ref={submenuRef} className={block()} {...dropdownProps}>
                {!isEmpty(menuOptions) &&
                    (Array.isArray(menuOptions[0])
                        ? // If it's an aray of array, we iterate over the list of sections.
                          (menuOptions as MenuOption[][]).map((menuSection: MenuOption[]) =>
                              renderMenuSection(menuSection),
                          )
                        : // Else we generate the unique section.
                          renderMenuSection(menuOptions as MenuOption[]))}
            </Menu>
        </Menu.Trigger>
    );
};

ContextMenu.displayName = 'ContextMenu';
