(function IIFE() {
    'use strict';

    /////////////////////////////

    function UserDirectoryToolsService(Metadata, Translation, Utils) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the value of a component of the user directory.
         *
         * FIXME [max]: recode it in a clearer way.
         *
         * @param  {Object} user      The user of which we want to get the component value.
         * @param  {Object} component The component of which we want to get the value.
         * @return {*}      The component value.
         */
        function getComponentValue(user, component) {
            var value;

            // If is google api profile.
            if (angular.isDefined(component.properties.isBound) && component.properties.isBound) {
                value = Utils.getApiProfileFieldFromMap(component.properties.boundMap.text, user);

                return (angular.isString(value) || angular.isNumber(value)) ? value : undefined;
            }

            if (angular.isUndefinedOrEmpty(user.customProfile)) {
                return undefined;
            }

            if (component.type === 'metadata') {
                value = '';

                // I known, I know, data doesn't take an 's' in plural!
                var metadatas = user.customProfile[component.uuid];
                metadatas = (angular.isDefinedAndFilled(metadatas) && angular.isArray(metadatas)) ?
                    metadatas : [metadatas];

                if (angular.isDefinedAndFilled(metadatas)) {
                    angular.forEach(metadatas, function forEachValues(metadata) {
                        var metadataName = service.getMetadataName(metadata);

                        if (angular.isDefinedAndFilled(metadataName) && Translation.hasTranslations(metadataName)) {
                            value += (angular.isDefinedAndFilled(value)) ? ', ' : '';
                            value += Translation.translate(metadataName);
                        }
                    });
                }

                return value;
            }

            return user.customProfile[component.uuid];
        }

        /**
         * Return the metadata name based on its id.
         *
         * @param  {string}  metadataId      The metadata id.
         * @param  {boolean} [isBound=false] Indicates if it is bound to a google profile field.
         * @return {string}  The metadata name.
         */
        function getMetadataName(metadataId, isBound) {
            isBound = isBound || false;

            if (isBound) {
                return metadataId;
            }

            var metadata = (angular.isDefinedAndFilled(metadataId)) ?
                Metadata.getMetadataFromKey(metadataId, false, true) : undefined;

            return _.get(metadata, 'name');
        }

        /////////////////////////////

        service.getMetadataName = getMetadataName;
        service.getComponentValue = getComponentValue;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('UserDirectoryTools', UserDirectoryToolsService);
})();
