import isEmpty from 'lodash/isEmpty';

export const parseInlineCSS = (inlineCss: string): Record<string, string> | undefined => {
    if (!inlineCss) {
        return undefined;
    }
    const isInlineStyle = !!inlineCss.match(/^([^:]+:[^;]+;)+$/i);

    if (!isInlineStyle) {
        return undefined;
    }

    let cssObject: Record<string, string> = {};
    const styleValues = inlineCss.split(/\s*;\s*/g);
    for (const styleValue of styleValues) {
        const cssProperty = styleValue.match(/^([^\s:]+)\s*:\s*(.+)/);
        if (cssProperty) {
            const [, property, value] = cssProperty;

            cssObject = { ...cssObject, [property]: value };
        }
    }

    return isEmpty(cssObject) ? undefined : cssObject;
};
