import React, { useEffect, useRef } from 'react';

import { utc } from 'moment';

import Chart from 'chart.js';

/**
 * Display the widget tasks graph list component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The tasks graph list react element.
 */
const TaskGraphList = ({ items }) => {
    const chartRef = useRef(null);

    /**
     * A function that return a filter token based on the task status.
     *
     * @param  {string} status      The task status.
     * @param  {Date}   dueDateTime The task due date.
     * @return {string} The filter token.
     */
    const getTaskFilter = (status, dueDateTime) => {
        const taskDueDate = utc(dueDateTime);
        const todayDate = utc();

        switch (status) {
            case 'completed':
                return 'completed';
            case 'inProgress':
            case 'notStarted':
                if (dueDateTime && taskDueDate.diff(todayDate, 'day') < 0) {
                    return 'late';
                } else if (
                    dueDateTime &&
                    (taskDueDate.isAfter(todayDate, 'day') || taskDueDate.isSame(todayDate, 'day')) &&
                    taskDueDate.diff(todayDate, 'week') < 1
                ) {
                    return 'risky';
                } else if (
                    dueDateTime &&
                    (taskDueDate.isAfter(todayDate, 'day') || taskDueDate.isSame(todayDate, 'day')) &&
                    taskDueDate.diff(todayDate, 'week') >= 1
                ) {
                    return 'in-progress';
                }

                return 'in-progress';

            default:
                return 'in-progress';
        }
    };

    /* UseEffect for rendering the doughnut graph. */
    useEffect(() => {
        if (!chartRef) {
            return;
        }
        const riskyTasks = items.filter((_task) => getTaskFilter(_task.status, _task.dueDateTime) === 'risky');
        const completedTasks = items.filter((_task) => getTaskFilter(_task.status, _task.dueDateTime) === 'completed');
        const lateTasks = items.filter((_task) => getTaskFilter(_task.status, _task.dueDateTime) === 'late');
        const inProgressTasks = items.filter(
            (_task) => getTaskFilter(_task.status, _task.dueDateTime) === 'in-progress',
        );

        // eslint-disable-next-line no-unused-vars
        const chart = new Chart(chartRef.current, {
            data: {
                datasets: [
                    {
                        backgroundColor: [
                            'rgba(255, 218, 150, 0.7)',
                            'rgba(159, 203, 138, 0.7)',
                            'rgba(246, 173, 142, 0.7)',
                            'rgba(160, 192, 229, 0.7)',
                        ],
                        borderColor: [
                            'rgba(255, 218, 150, 1)',
                            'rgba(159, 203, 138, 1)',
                            'rgba(246, 173, 142, 1)',
                            'rgba(160, 192, 229, 1)',
                        ],
                        borderWidth: 1,
                        data: [riskyTasks.length, completedTasks.length, lateTasks.length, inProgressTasks.length],
                        label: '',
                    },
                ],
                labels: ['Risky', 'Completed', 'Late', 'In progress'],
            },
            type: 'doughnut',
        });
    }, [items, chartRef]);

    return (
        <div className="graphContent">
            <canvas ref={chartRef} id="taskChart" />
        </div>
    );
};

export default TaskGraphList;
