import { Alignment } from '@lumapps/wrex/types/core';

import { PARAGRAPH } from '../constants';
import type { ParagraphElement } from '../types';

export const createParagraph = (
    children: ParagraphElement['children'] = [{ text: '' }],
    alignment: Alignment | undefined = undefined,
): ParagraphElement => {
    return { type: PARAGRAPH, children, ...(alignment ? { alignment } : undefined) };
};
