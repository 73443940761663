import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { GenericProps } from '@lumapps/lumx/react';

import './index.scss';

export interface CardProps extends GenericProps {
    /** Classname to forward on the card. */
    className?: string;
    /** Tag to use for wrapper. Default as article. */
    as?: React.ElementType;
    /** Card elevation (drop shadow). */
    elevation?: 1 | 2 | 3 | 5;
}

const CLASSNAME = 'page-card';

/**
 * Component displaying a simple content card with white background and padding.
 *
 * @family Cards
 * @param CardProps
 * @returns Card
 */
export const Card: React.FC<CardProps> = ({
    className,
    children,
    as: Component = 'article',
    elevation,
    ...forwardedProps
}) => {
    const { block } = useClassnames(CLASSNAME);
    return (
        <Component className={block({ [`elevation-${elevation}`]: !!elevation }, className)} {...forwardedProps}>
            {children}
        </Component>
    );
};

Card.displayName = 'Card';
