import React, { ReactNode } from 'react';

import isEmpty from 'lodash/isEmpty';

import { mdiMap } from '@lumapps/lumx/icons';
import { DialogSizes } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { MAP_URL_PATTERNS } from '@lumapps/utils/maps/constants';
import { getEmbedMapUrlFromUrl } from '@lumapps/utils/maps/getEmbedMapUrlFromUrl';
import { getMapWebsiteName } from '@lumapps/utils/maps/getMapWebsiteName';
import { MapUrlPatterns } from '@lumapps/utils/maps/types';
import { isUrl } from '@lumapps/utils/string/isUrl';

import { useLinkPreview } from '../../hooks/useLinkPreview';
import { PREVIEW } from '../../keys';
import { LinkPreviewMetadata } from '../../types';
import { InsertUrlPreviewDialog } from './InsertUrlPreviewDialog';
import { useValidationState } from './useValidationState';

const CLASSNAME = 'insert-map-dialog';

export interface InsertMapDialogProps {
    /** Cancel button callback. */
    onClose(): void;
    /** Confirm button callback. */
    onInsert(link: LinkPreviewMetadata): void;
    /** Open/close dialog. */
    isOpen?: boolean;
    /** Dialog size. */
    size?: DialogSizes;
    /** Provide a message in to put inside the */
    children?: ReactNode;
}

export const InsertMapDialog: React.FC<InsertMapDialogProps> = (props) => {
    const { onClose, onInsert, isOpen } = props;
    const [url, setUrl] = React.useState('');
    const websiteName = getMapWebsiteName(url);

    const isMapUrlCorrect = React.useMemo(() => {
        return Boolean(
            Object.keys(MAP_URL_PATTERNS).find((name) => {
                return Boolean(
                    MAP_URL_PATTERNS[name as keyof MapUrlPatterns].find((pattern: string) => url.indexOf(pattern) > -1),
                );
            }),
        );
    }, [url]);

    const { isValid: isInputUrlValid, isDirty: isInputUrlDirty } = useValidationState(
        url,
        (urlToCheck) => Boolean(isUrl(urlToCheck)) && isMapUrlCorrect,
    );

    const { preview, previewError, isPreviewLoading, onPreviewThumbnailSwitch, fetchPreview } = useLinkPreview(
        url,
        isInputUrlValid && Boolean(url),
        true,
    );

    const { translateKey } = useTranslate();
    const isPreviewUrlValid = preview.url ? preview.code === 200 : true;

    const embedUrl = React.useMemo(() => {
        // Use preview url instead of url to support short link
        if (isInputUrlValid && preview.url && isPreviewUrlValid) {
            const createdEmbedMapUrl = getEmbedMapUrlFromUrl(preview.url);

            return createdEmbedMapUrl;
        }

        return undefined;
    }, [preview, isInputUrlValid, isPreviewUrlValid]);

    const propsToPass = {
        setUrl,
        url,
        isUrlDirty: isInputUrlDirty,
        isUrlValid: isInputUrlValid && isPreviewUrlValid,
        preview: isPreviewUrlValid ? { ...preview, embedUrl } : {},
        previewError,
        isPreviewLoading,
        onPreviewThumbnailSwitch,
        fetchPreview,
    };

    return (
        <InsertUrlPreviewDialog
            isOpen={isOpen}
            title={translateKey(PREVIEW.EMBED_MAP)}
            inputIcon={mdiMap}
            inputErrorMessage={
                url
                    ? translateKey(PREVIEW.INSERT_MAP_DIALOG_URL_FIELD_ERROR_BAD_FORMAT)
                    : translateKey(GLOBAL.ERROR_MANDATORY_FIELD)
            }
            inputLabel={translateKey(GLOBAL.LINK)}
            inputPlaceholder="https://…"
            infoMessage={isEmpty(url) ? translateKey(PREVIEW.INSERT_MAP_DIALOG_INFO_MESSAGE) : undefined}
            errorMessage={
                !isPreviewUrlValid && websiteName === 'googleMap'
                    ? translateKey(PREVIEW.INSERT_MAP_DIALOG_GMAP_URL_BAD_FORMAT)
                    : undefined
            }
            insertButtonText={translateKey(GLOBAL.EMBED)}
            {...propsToPass}
            onClose={onClose}
            onInsert={onInsert}
            classNamePrefix={CLASSNAME}
        />
    );
};
InsertMapDialog.displayName = 'InsertMapDialog';
