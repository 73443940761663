/* eslint-disable no-param-reassign */
import findLastIndex from 'lodash/findLastIndex';
import uniqueId from 'lodash/uniqueId';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import type { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { NOTIFICATION_EXTENDED_MAX_COUNT, NOTIFICATION_SIMPLE_MAX_COUNT, DOMAIN } from '../constants';
import { isSimpleNotification, NotificationAPIv2, NotificationInStore, State } from './types';

export const initialState: State = {
    notifications: [],
};

export const { reducer, actions } = createSlice({
    domain: DOMAIN,
    initialState,
    reducers: {
        reset: () => initialState,
        /**
         * Add a notification.
         * - Simple notifications are always on top
         * - Extended notifications are always at the bottom
         * - Both are limited, if the limit is exceeded, the oldest notifications are hidden (and later removed)
         */
        add(state: State, action: PayloadAction<NotificationAPIv2>) {
            const notification = action.payload;
            const id = notification.id || uniqueId(DOMAIN);
            const notificationForStore = { ...notification, id };

            const lastSimpleNotif = findLastIndex(state.notifications, isSimpleNotification);

            if (notification.type === 'extended') {
                const firstExtendedNotif = lastSimpleNotif + 1;
                const firstVisibleExtendedNotif = state.notifications.length - NOTIFICATION_EXTENDED_MAX_COUNT;

                // Hide all extended notif exceeding the limit
                for (let i = firstExtendedNotif; i <= firstVisibleExtendedNotif; i++) {
                    state.notifications[i].visible = false;
                }

                // Add extended notif
                state.notifications.push(notificationForStore);
            } else {
                if (isSimpleNotification(state.notifications[0])) {
                    const firstVisibleSimpleNotif = lastSimpleNotif + 1 - NOTIFICATION_SIMPLE_MAX_COUNT;

                    // Hide all simple notif exceeding the limit
                    for (let i = 0; i <= firstVisibleSimpleNotif; i++) {
                        state.notifications[i].visible = false;
                    }
                }

                // Add simple notif
                state.notifications.splice(lastSimpleNotif + 1, 0, notificationForStore);
            }
        },
        /**
         * Show notification by id (requires rendered height)
         */
        show(state: State, action: PayloadAction<{ id: string; height: number }>) {
            const index = state.notifications.findIndex(({ id }) => id === action.payload.id);
            if (index > -1) {
                state.notifications[index].height = action.payload.height;
                state.notifications[index].visible = true;
            }
        },
        /**
         * Hide notification by id
         */
        hide(state: State, action: PayloadAction<{ id: string }>) {
            const index = state.notifications.findIndex(({ id }) => id === action.payload.id);
            if (index > -1) {
                state.notifications[index].visible = false;
            }
        },
        /**
         * Remove notification by id.
         */
        remove(state: State, action: PayloadAction<{ id: string }>) {
            const index = state.notifications.findIndex(({ id }) => id === action.payload.id);
            if (index > -1) {
                state.notifications.splice(index, 1);
            }
        },
        /**
         * Update an extended notification by id.
         * Do nothing if the notification is not an extended notification.
         */
        updateExtended(
            state: State,
            action: PayloadAction<{ id: string; notification: Partial<NotificationInStore & { type: 'extended' }> }>,
        ) {
            const notification = state.notifications.find(
                ({ id, type }) => id === action.payload.id && type === 'extended',
            );
            if (!notification) {
                return;
            }
            Object.assign(notification, action.payload.notification);
        },
    },
});

export type Actions = ReturnType<ObjectValues<typeof actions>>;
