import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import { DEFAULT_VIEW } from './constants';

// TODO: LUM-14358 remove this legacy route when switching definitely to the reworked page
export const communitiesTemplatesRoute: Route = {
    path: '/admin/communities/templates',
    legacyId: 'app.admin.community-templates',
    appId: AppId.legacy,
};

// TODO: LUM-14358 rename the path to /admin/communities/templates when switching definitely to the reworked page
export const communityTemplatesRoute: Route = {
    path: '/admin/community-templates',
    legacyId: 'app.admin.community-template',
    appId: AppId.backOffice,
};

export const communityTemplateCreationRoute: Route = {
    path: '/community/create/template/posts',
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

export const communityTemplateEditLayoutRoute: Route = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: '/community/edit/:key/:subTemplate',
    params: {
        contentType: 'community',
        isTemplate: 'true',
        // The community template id.
        key: undefined,
        // The community view (dashboard, posts, etc.)
        subTemplate: undefined,
    },
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

/**
 * Retrieves the route for module's templates admin.
 */
export const adminCommunityTemplate = (): Route => {
    return communityTemplatesRoute;
};

// TODO: remove when enabling the reworked communities admin page for users.
export const adminCommunityTemplates = (): Route => {
    return communitiesTemplatesRoute;
};

export const newCommunityTemplate = (): Route => {
    return communityTemplateCreationRoute;
};

export const communityTemplateEditlayout = (templateId: string): Route => {
    return {
        ...communityTemplateEditLayoutRoute,
        params: {
            ...communityTemplateEditLayoutRoute.params,
            key: templateId,
            subTemplate: DEFAULT_VIEW,
        },
        query: {
            isTemplate: 'true',
        },
    };
};
