import React from 'react';

import { ArticlePickerDialog } from '@lumapps/article-pickers/components/ArticlePickerDialog';
import { CommunityPickerDialog } from '@lumapps/community-pickers/components/CommunityPickerDialog';
import { useCommunityListPicker } from '@lumapps/community-pickers/hooks/useCommunityListPicker';
import { ContentPickerDialog } from '@lumapps/content-pickers/components/ContentPickerDialog';
import { EventPickerDialog } from '@lumapps/event-pickers/components/EventPickerDialog';
import { currentLanguageSelector } from '@lumapps/languages';
import { TrainingPickerDialog } from '@lumapps/learning-pickers/components/TrainingPickerDialog';
import { MicroAppPickerDialog } from '@lumapps/micro-app-pickers/components/MicroAppPickerDialog';
import { PlaylistPickerDialog } from '@lumapps/play-pickers/components/PlaylistPickerDialog';
import { VideoPickerDialog } from '@lumapps/play-pickers/components/VideoPickerDialog';
import { PostPickerDialog } from '@lumapps/post-pickers/components/PostPickerDialog';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../../../constants';
import type { LinkPreviewAttributes } from '../../../types';
import { mapPickerResourceToPreview, type PickerResource } from '../../../utils/mapPickerResourceToPreview';

export interface ReplaceResourcePreviewDialogProps {
    /** whether the dialog is open or not. */
    isOpen: boolean;
    /** callback to be executed when the dialog is closed */
    onClose: () => void;
    /** callback to be executed once the preview has been changed */
    onChange: (preview: LinkPreviewAttributes) => void;
    /** ref of parent element to make keyboard navigation easy */
    parentElement: React.RefObject<any>;
    /** resource preview type */
    resourceType: LINK_PREVIEW_RESOURCE_TYPES | undefined;
}

/**
 * Opens a resource corresponding dialog when clicking on replace button, to pick a new resource
 * @param ReplaceResourcePreviewDialogProps
 * @returns ReplaceResourcePreviewDialog
 */
export const ReplaceResourcePreviewDialog: React.FC<ReplaceResourcePreviewDialogProps> = ({
    isOpen,
    onClose,
    onChange,
    parentElement,
    resourceType,
}) => {
    const translate = useTranslate();

    const currentLanguage = useSelector(currentLanguageSelector);

    const onConfirm = React.useCallback(
        (typeOfResource: LINK_PREVIEW_RESOURCE_TYPES) => {
            const mapper = mapPickerResourceToPreview(typeOfResource);

            return (items: Array<PickerResource[typeof typeOfResource]>) => {
                if (items?.length) {
                    onChange(mapper(items[0], translate, currentLanguage));
                }
                onClose();
            };
        },
        [currentLanguage, onChange, onClose, translate],
    );

    switch (resourceType) {
        case LINK_PREVIEW_RESOURCE_TYPES.ARTICLE:
            return (
                <ArticlePickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={onConfirm(resourceType)}
                    context="platform"
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY: {
            const fetchCommunityParams = {
                // fields params overrides for fetching community to add instanceDetails
                fields: 'items(id,uid,title,description,renderingType,privacy,slug,thumbnail,customer,instance,instanceDetails,feedKeys),more,cursor',
            };
            return (
                <CommunityPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={onConfirm(resourceType)}
                    fetchParams={fetchCommunityParams}
                    communityPickerHook={useCommunityListPicker}
                />
            );
        }

        case LINK_PREVIEW_RESOURCE_TYPES.CONTENT: {
            const fetchContentParams = {
                // fields params overrides for fetching content, so we get the excerpt
                fields: 'items(id,title,slug,type,customContentType,mediaThumbnail,feedKeys,createdAt,instance,excerpt),more,cursor',
            };
            return (
                <ContentPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={onConfirm(resourceType)}
                    fetchParamsOverrides={fetchContentParams}
                />
            );
        }

        case LINK_PREVIEW_RESOURCE_TYPES.EVENT:
            return (
                <EventPickerDialog
                    isOpen={isOpen}
                    //! FIXME: we should NOT restrict to current instance but picker does not provide event's instance so, for now, it's safer this way
                    onlyUseCurrentInstance
                    onCancel={() => onClose()}
                    onConfirm={onConfirm(resourceType)}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE:
        case LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH:
            return (
                <TrainingPickerDialog
                    isOpen={
                        isOpen &&
                        (resourceType === LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH ||
                            resourceType === LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE)
                    }
                    onCancel={() => onClose()}
                    onConfirm={onConfirm(resourceType)}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP:
            return (
                <MicroAppPickerDialog isOpen={isOpen} onCancel={() => onClose()} onConfirm={onConfirm(resourceType)} />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO:
            return (
                <VideoPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onSelectionCancel={() => onClose()}
                    onSelectionConfirm={onConfirm(resourceType)}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST:
            return (
                <PlaylistPickerDialog
                    parentElement={parentElement}
                    isOpen={isOpen}
                    onSelectionCancel={() => onClose()}
                    onSelectionConfirm={onConfirm(resourceType)}
                />
            );

        case LINK_PREVIEW_RESOURCE_TYPES.POST:
            return (
                <PostPickerDialog
                    isOpen={isOpen}
                    onCancel={() => onClose()}
                    onConfirm={onConfirm(resourceType)}
                    params={{ excerptFormat: 'PLAIN_TEXT' }}
                />
            );

        default:
            return null;
    }
};
