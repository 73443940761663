/* eslint-disable import/no-mutable-exports */
import forEach from 'lodash/forEach';
import noop from 'lodash/noop';
import map from 'lodash/map';
import memoize from 'lodash/memoize';

let getInputLanguage = noop;

let getLang = noop;

let hasTranslations = noop;

let hasTranslation = noop;

let pluralize = noop;

let translate = noop;

let transRep = noop;

/**
 * Binds functions to angular `Translation` service.
 *
 * @param {Translation} translations The angular translation service.
 */
function setTranslations(translations) {
    getInputLanguage = translations.getInputLanguage;
    getLang = translations.getLang;
    hasTranslation = translations.hasTranslation;
    hasTranslations = translations.hasTranslations;
    pluralize = translations.pluralize;
    translate = translations.translate;
    transRep = translations.replace;
}

/**
 * Returns a shallow copy of a collection in which all values matching the keys parameter
 * are translated.
 *
 * @param  {Object[]} collection The collection to translate.
 * @param  {string[]} keys       The list of key we need the value translated.
 * @return {Object[]} The translated collection.
 */
const translateCollection = memoize(({ collection, keys = ['label'] }) => {
    return map(collection, (object) => {
        forEach(keys, (key) => {
            object[key] = translate(object[key]);
        });

        return object;
    });
});

export {
    getInputLanguage,
    getLang,
    hasTranslation,
    hasTranslations,
    pluralize,
    translate,
    transRep,
    setTranslations,
    translateCollection,
};
