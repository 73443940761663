import React from 'react';

import { classnames } from '@lumapps/classnames';
import { makeSecuredMediaURLRelative } from '@lumapps/medias/utils';
import { IMAGE_GROUP } from '@lumapps/wrex-enhanced-image/constants';
import { ImageGroupElement } from '@lumapps/wrex-enhanced-image/types';
import { ElementRender } from '@lumapps/wrex/types';

import { CLASSNAME, IMAGE_GALLERY_VIEW_MODE } from '../../../constants';
import { ImageWall } from '../ImageWall';
import { SlideshowView } from '../SlideshowView';

import './index.scss';

interface ImageGalleryProps {
    isEditable?: boolean;
}

export const ImageGallery: ElementRender<ImageGroupElement, HTMLDivElement, ImageGalleryProps> = (props) => {
    const { className, elementRef, element, isEditable, children, ...forwardedProps } = props;
    const { images = [], title, isLoading } = element ?? {};

    const thumbnails = React.useMemo(() => {
        return images.map(({ src = '', alt, tempSrc, height, width }) => ({
            image: tempSrc || makeSecuredMediaURLRelative(src) || '',
            alt: alt || '',
            title,
            imgProps: height && width ? { height, width } : undefined,
        }));
    }, [images, title]);

    return (
        <figure {...forwardedProps} ref={elementRef} className={classnames(CLASSNAME, className)}>
            {element?.viewMode === IMAGE_GALLERY_VIEW_MODE.slideshow ? (
                <SlideshowView images={thumbnails} isEditable={isEditable} isLoading={Boolean(isLoading)} />
            ) : (
                <ImageWall images={thumbnails} isEditable={isEditable} isLoading={Boolean(isLoading)} />
            )}
            {children}
        </figure>
    );
};

ImageGallery.displayName = IMAGE_GROUP;
