import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { isConnected } from '@lumapps/user/ducks/selectors';

/**
 * Check the layout v2 feature flag.
 * And that the user is authenticated, the layout does not support unauthenticated users.
 * */
export const isLayoutEnabled = createSelector(
    isConnected,
    isFeatureEnabled('layout-v2'),
    (isUserConnected, isFeatureEnabled) => isUserConnected && isFeatureEnabled,
);
