import findLastKey from 'lodash/findLastKey';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import loFind from 'lodash/find';
import isElement from 'lodash/isElement';

import { Style as StyleService } from '../../services/styles';
import { slugify, width } from '../../utils';

/**
 * Defines all widget size breakpoints.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const WIDGET_SIZE_BREAKPOINTS = {
    /* eslint-disable id-length, sort-keys */
    S: 400,
    M: 700,
    L: -1,
    /* eslint-enable id-length, sort-keys */
};

/**
 * Return the a widget size key code depending on its width.
 *
 * @param  {Element} node The native DOM element.
 * @return {string}  The widget's size key code.
 */
const getWidgetSize = (node) => {
    let size = findLastKey(WIDGET_SIZE_BREAKPOINTS);

    if (isElement(node)) {
        const widgetWidth = width(node);

        forEach(WIDGET_SIZE_BREAKPOINTS, (breakpoint, key) => {
            if (widgetWidth <= breakpoint) {
                size = key;

                // Break lodash forEach.
                return false;
            }

            return undefined;
        });
    }

    return size.toLowerCase();
};

/**
 * Get a slugified widget global style name.
 *
 * @param  {string} id The style id.
 * @return {string} The style name.
 */
const getGlobalStyleName = (id) =>
    slugify(
        get(
            loFind(StyleService.displayList('widget'), {
                id,
            }),
            'name',
        ),
    );

export { getWidgetSize, getGlobalStyleName };
