import { useCallback } from 'react';

import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { GLOBAL } from '@lumapps/translations';
import { copyText } from '@lumapps/utils/browser/copyText';

export const useCopyToClipboard = () => {
    const { success } = useNotification();

    const copyToClipboard = useCallback(
        (string: string) => {
            copyText(string);
            success({ translate: GLOBAL.COPIED });
        },
        [success],
    );

    return copyToClipboard;
};
