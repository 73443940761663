import snakeCase from 'lodash/snakeCase';

/**
 * Convert string to UPPER_SNAKE_CASE.
 *
 * @param  {string} string The string to convert.
 * @return {string} The string converted in UPPER_SNAKEL_CASE.
 */
export function upperSnakeCase(string: string) {
    return snakeCase(string).toUpperCase();
}
