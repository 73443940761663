import createFormSlice, { FormState, SchemaCreator } from '@lumapps/redux/createFormSlice';

import { PROGRAM_SLUG_LANGUAGE } from '../../constants';
import { SA_PROGRAMS } from '../../keys';
import { PostProgram } from '../../types';

export type ProgramInformationFormState = FormState<Pick<PostProgram, 'name' | 'description' | 'slug'>>;

export const initialValues: ProgramInformationFormState['values'] = {
    name: {
        translations: {},
    },
    description: {
        translations: {},
    },
    slug: { translations: {} },
};

const validationSchema: SchemaCreator<ProgramInformationFormState['values']> = (formValues) => {
    const currentLang = formValues?.meta?.lang?.current;
    // Find if at least a name has been defined in any language
    const hasANameDefined = Object.values(formValues?.values?.name?.translations).some((value = '') =>
        Boolean(value.trim()),
    );

    return {
        name: {
            translations: {
                /**
                 * Set an error on the current name field if its empty
                 * and undefined on any other languages.
                 */
                [currentLang]: (value) =>
                    !hasANameDefined && (!value || !value.trim())
                        ? SA_PROGRAMS.PROGRAM_CREATION_ERROR_REQUIRED_NAME
                        : '',
            },
        },
        slug: {
            translations: {
                // For historical reasons, slugs must be treated as multi-languages but actually is only used in english
                [PROGRAM_SLUG_LANGUAGE]: (value) =>
                    !value || !value.trim() ? SA_PROGRAMS.PROGRAM_CREATION_ERROR_REQUIRED_SLUG : '',
            },
        },
    };
};

const { actions, reducer, initialState } = createFormSlice({
    domain: 'ProgramForm/informationForm',
    initialValues,
    validationSchema,
});

export { actions, reducer, initialState };
