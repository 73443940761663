import loFind from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import set from 'lodash/set';

import { WidgetImageGallery } from 'components/components/widgets/image-gallery/WidgetImageGallery';

function WidgetImageGallerySettingsController(Config, Instance, Widget) {
    'ngInject';

    const widgetImageGallerySettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The current widget we get from `Widget` service.
     *
     * @type {Object}
     */
    let _currentWidget = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    widgetImageGallerySettings.Widget = Widget;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Initialize widget image gallery fields.
     */
    function _initFields() {
        _currentWidget.properties = _currentWidget.properties || {};

        const availableFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.IMAGE_GALLERY_FIELDS);
        const { fields = [] } = _currentWidget.properties;

        angular.forEach(availableFields, (fieldName) => {
            const alreadySetField = loFind(fields, {
                name: fieldName,
            });

            if (angular.isUndefinedOrEmpty(alreadySetField)) {
                fields.push({
                    enable: true,
                    name: fieldName,
                });
            }
        });

        _currentWidget.properties.fields = fields;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Set the newly updated properties to the current widget.
     *
     * @param {Object} value        The updated property value.
     * @param {Object} propertyName The updated property name.
     */
    function onChange(value, propertyName) {
        let properties = { ...get(_currentWidget, 'properties', {}) };

        // If changing view mode, reset all default properties.
        if (propertyName.length === 1 && first(propertyName) === 'viewMode' && properties.viewMode !== value) {
            properties = { ...properties, ...WidgetImageGallery.defaultProps.properties };
        }

        set(properties, propertyName, value);

        _currentWidget.properties = properties || {};
    }

    /**
     * Toggles a display field.
     *
     * @param {Object} field The field to toggle.
     */
    function toggleField(field) {
        _currentWidget.properties = _currentWidget.properties || {};

        const { fields = [] } = _currentWidget.properties;

        const toggledField = loFind(fields, {
            name: field.name,
        });

        if (angular.isUndefinedOrEmpty(toggleField)) {
            return;
        }

        toggledField.enable = !toggledField.enable;

        _currentWidget.properties.fields = fields;
    }

    /////////////////////////////

    widgetImageGallerySettings.onChange = onChange;
    widgetImageGallerySettings.toggleField = toggleField;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _currentWidget = Widget.getCurrent();
        const defaultProperties = WidgetImageGallery.defaultProps.properties;

        _currentWidget.properties = _currentWidget.properties
            ? { ...defaultProperties, ..._currentWidget.properties }
            : defaultProperties;

        _initFields();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetImageGallerySettingsController', WidgetImageGallerySettingsController);

/////////////////////////////

export { WidgetImageGallerySettingsController };
