/**
 * This holds objects that are to do with the widget File list.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const WidgetFileListConstant = {
    /**
     * The identifier of the list of media tags.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    LIST_KEY_MEDIA_TAGS: 'tag-admin',
};

/**
 * Available folder types.
 * @type {Object}
 */
const FOLDER_TYPES = {
    CATEGORY: 'category',
    CUSTOM: 'custom',
};

/////////////////////////////

angular.module('Constants').constant('WidgetFileListConstant', WidgetFileListConstant);

export { FOLDER_TYPES, WidgetFileListConstant };
