import React, { CSSProperties } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size, SkeletonCircle, SkeletonTypography, Theme, Typography } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';

import { ViewMode } from '../../constants';

import './index.scss';

const CLASSNAME = 'navigation-skeleton';

const BREAKPOINTS = { 1: 0, 2: 256, 3: 480 };

export interface NavigationSkeletonProps {
    /** Theme that will be applied to the element, either Theme.dark or Theme.light */
    theme?: Theme;
    /** The view mode that will be used to display the nav, either 'horizontal' or 'vertical', defaults to 'vertical' */
    viewMode?: ViewMode;
    /** Whether the icon skeleton should be displayed. Defaults to false */
    hideIcon?: boolean;
}

type NavigationSkeletonItemProps = NavigationSkeletonProps & {
    width?: CSSProperties['width'];
};

const NavigationSkeletonItem: React.FC<NavigationSkeletonItemProps> = ({
    theme,
    hideIcon = false,
    width,
    viewMode,
    ...forwardedProps
}) => {
    const { element } = useClassnames(CLASSNAME);
    return (
        <div
            {...forwardedProps}
            className={element('item', {
                fill: !width,
                horizontal: viewMode === ViewMode.horizontal,
            })}
        >
            {!hideIcon && <SkeletonCircle className={element('circle')} size={Size.xs} theme={theme} />}
            <SkeletonTypography
                className={element('typography')}
                typography={Typography.subtitle1}
                theme={theme}
                width={width}
            />
        </div>
    );
};

const NavigationSkeleton: React.FC<NavigationSkeletonProps> = ({ theme, viewMode, hideIcon }) => {
    const { ref, currentBreakpoint } = useDimensions({ breakpoints: BREAKPOINTS });
    const { block } = useClassnames(CLASSNAME);
    const isCompact = currentBreakpoint < 3 && viewMode === ViewMode.horizontal;
    const isVeryCompact = currentBreakpoint < 2 && viewMode === ViewMode.horizontal;

    return (
        <div ref={ref} className={block({ horizontal: viewMode === ViewMode.horizontal })}>
            <NavigationSkeletonItem theme={theme} hideIcon={hideIcon} />
            {!isCompact && <NavigationSkeletonItem theme={theme} hideIcon={hideIcon} />}
            {!isVeryCompact && <NavigationSkeletonItem theme={theme} hideIcon={hideIcon} />}
        </div>
    );
};

NavigationSkeleton.displayName = 'NavigationSkeleton';

export { NavigationSkeleton, NavigationSkeletonItem };
