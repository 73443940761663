import findIndex from 'lodash/findIndex';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { SocialNetworkStatus } from '@lumapps/sa-social-networks/types';

export enum ProgramSocialNetworkStatuses {
    initial = 'initial',
    loading = 'loading',
    success = 'success',
    edited = 'edited',
    failure = 'failure',
}

export interface ProgramSocialNetworksFormState {
    programSocialNetworkStatus: ProgramSocialNetworkStatuses;
    socialNetworks: Array<SocialNetworkStatus>;
}

const initialState: ProgramSocialNetworksFormState = {
    programSocialNetworkStatus: ProgramSocialNetworkStatuses.initial,
    // ex:  [{ socialNetworkId: 'facebook', active: true }]
    socialNetworks: [],
};

export const programSocialNetworksFormReducers = {
    fetchSocialNetworks: (state: ProgramSocialNetworksFormState) => {
        set(state, 'programSocialNetworkStatus', ProgramSocialNetworkStatuses.loading);
    },
    fetchSocialNetworksSuccess: (
        state: ProgramSocialNetworksFormState,
        action: PayloadAction<ProgramSocialNetworksFormState['socialNetworks']>,
    ) => {
        set(state, 'programSocialNetworkStatus', ProgramSocialNetworkStatuses.success);
        set(state, 'socialNetworks', action.payload);
    },
    fetchSocialNetworksFailure: (state: ProgramSocialNetworksFormState) => {
        set(state, 'programSocialNetworkStatus', ProgramSocialNetworkStatuses.failure);
        set(state, 'socialNetworks', []);
    },
    setSocialNetworkStatus: (state: ProgramSocialNetworksFormState, action: PayloadAction<SocialNetworkStatus>) => {
        const { socialNetworkId: modifiedSocialNetwork } = action.payload;
        const editedSocialNetworkIndex = findIndex(
            state.socialNetworks,
            ({ socialNetworkId }) => socialNetworkId === modifiedSocialNetwork,
        );

        if (editedSocialNetworkIndex > -1) {
            set(state, `socialNetworks[${editedSocialNetworkIndex}]`, action.payload);
        } else {
            set(state, 'socialNetworks', [...state.socialNetworks, action.payload]);
        }

        set(state, 'programSocialNetworkStatus', ProgramSocialNetworkStatuses.edited);
    },
    setSocialNetworks: (state: ProgramSocialNetworksFormState, action: PayloadAction<SocialNetworkStatus[]>) => {
        set(state, 'socialNetworks', action.payload);
    },
    reset: () => {
        return initialState;
    },
};

const { actions, reducer } = createSlice({
    domain: 'ProgramForm/socialNetworks',
    initialState,
    reducers: programSocialNetworksFormReducers,
});

export { actions, reducer, initialState };
