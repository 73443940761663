import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { Style as StyleService } from '../../../services/styles';
import { withOnChangeProperty } from '../../../utils';
import { stringsToChoices } from '../../ui/inputs/utils';
import { FONT_WEIGHT_CHOICES } from '../constants';
import { ColorSelect, Input, Select, TextField } from '../../ui';

/**
 * Renders a form that edits font settings (weight, size and color).
 */
export class FontSettings extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.fontStyle.isRequired,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Style with font styling properties. */
        value: styleTypes.fontStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { value = {}, defaults } = this.props;

        return (
            <div className="p+">
                <Select
                    label={t('STYLE.FONT_WEIGHT')}
                    value={value.fontWeight}
                    name="fontWeight"
                    onChange={this.onChangeProperty}
                    choices={FONT_WEIGHT_CHOICES}
                    placeholder={defaults.fontWeight}
                    hasClearChoice
                />
                <div className="mt+" flex-container="row" flex-gutter="16">
                    <div flex-item="flex-item">
                        <TextField label={t('STYLE.SIZE')} suffix="px">
                            <Input
                                value={value.fontSize}
                                name="fontSize"
                                onChange={this.onChangeProperty}
                                type="number"
                                min={1}
                                placeholder={defaults.fontSize}
                            />
                        </TextField>
                    </div>
                    <div flex-item="flex-item">
                        <ColorSelect
                            label={t('STYLE.COLOR')}
                            value={value.color}
                            name="color"
                            onChange={this.onChangeProperty}
                            choices={stringsToChoices(StyleService.getCurrent('global').properties.colors)}
                            align="right"
                            placeholder={defaults.color}
                            hasClearChoice
                        />
                    </div>
                </div>
            </div>
        );
    }
}
