import React, { RefObject } from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiSparkles } from '@lumapps/lumx/icons';
import { Button, ColorPalette, Emphasis, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WREX_GENERATED_CONTENT } from '../../keys';
import { GenerativeContentDialog } from '../GenerativeContentDialog';

export interface GenerativeContentButtonProps {
    parentDialogRef?: RefObject<HTMLDivElement>;
    className?: string;
    theme?: Theme;
}

export const GenerativeContentButton = ({ parentDialogRef, className, theme }: GenerativeContentButtonProps) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('poc-ai-gen-content');

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <Button
                emphasis={Emphasis.low}
                hasBackground={false}
                theme={theme}
                color={ColorPalette.primary}
                className={className}
                leftIcon={mdiSparkles}
                onClick={() => setIsOpen(true)}
                {...get({ element: 'ai-generative-content' })}
            >
                {translateKey(WREX_GENERATED_CONTENT.GENERATE_TEXT)}
            </Button>

            <GenerativeContentDialog
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                parentDialogRef={parentDialogRef}
            />
        </>
    );
};
