import get from 'lodash/get';
import { URL_PREFIX } from '@lumapps/router/constants';

/////////////////////////////
//                         //
//    Public attributes    //
//                         //
/////////////////////////////

/**
 * An enum of permission names to access some states of the application.
 *
 * @type {Object}
 */
const PERMISSIONS = {
    instanceAdmin: 'instanceAdmin',
    superAdmin: 'superAdmin',
};

/**
 * The path that cannot be used as slugs.
 *
 * @type {Array}
 */
const FORBIDDEN_PATH = ['content', 'admin', 'search', '404', URL_PREFIX];

/**
 * The characters that cannot be used in slugs.
 *
 * @type {Array}
 */
const FORBIDDEN_SLUG_CHARACTERS = [
    ':',
    '/',
    '?',
    '#',
    '[',
    ']',
    '@',
    '!',
    '$',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    ';',
    '=',
    '.',
    // Will be transformed to `\s` to forbid whitespaces.
    's',
];

/**
 * Duplicate a content.
 *
 * @param {Angular} $stateParams The angular-ui-router state params.
 * @param {Service} Config       The Config service.
 * @param {Service} Content      The content service.
 * @param {Service} Header       The header service.
 * @param {Service} Instance     The instance service.
 * @param {Service} Translation  The translation service.
 * @param {boolean} isVeolia     Indicates if we are duplicating for veolia.
 */
const duplicateContent = ($stateParams, Config, Content, Header, InitialSettings, Instance, Translation, Widget, isVeolia) => {
    const content = Content.getCurrent();
    if (angular.isUndefinedOrEmpty(content)) {
        return;
    }

    const [ contributionWidget ] = Widget.findWidgetsByType(content.template, InitialSettings.WIDGET_TYPES.CONTRIBUTION);

    if (contributionWidget) {
        // If there is a contribution widget in the original content, we remove the structured content
        // id and version from its properties to avoid multiple contents/templates refer to the same entity.
        contributionWidget.properties.structuredContentId = undefined;
        contributionWidget.properties.structuredContentVersion = undefined;
    }

    const originalAuthor = content.authorDetails || content.writerDetails;
    content.properties = {
        class: get(content, 'properties.class'),
        duplicateContent: {
            id: content.id,
            authorId: originalAuthor.id,
        },
        id: get(content, 'properties.id'),
    };

    // Reset the id, author and writer to add the new content duplicated.
    content.id = undefined;
    content.uid = undefined;
    content.author = undefined;
    content.authorDetails = undefined;
    content.authorId = undefined;
    content.writer = undefined;
    content.writerDetails = undefined;
    content.writerId = undefined;

    // Reset social.
    content.likes = 0;
    content.comments = 0;

    content.status = Config.CONTENT_STATUS.DRAFT.value;

    // De-associate header but keep header's content.
    content.header = undefined;
    content.headerDetails = angular.isUndefinedOrEmpty(content.headerDetails)
        ? Header.getCurrent()
        : content.headerDetails;
    if (angular.isDefinedAndFilled(content.headerDetails)) {
        content.headerDetails.id = undefined;
    }

    content.isHomepage = false;

    // To save the content thumbnail, we must set the real blobKey to avoid errors.
    if (angular.isDefinedAndFilled(content.thumbnailBlobkey)) {
        content.thumbnail = content.thumbnailBlobkey;
    }

    const copyName = Translation.translate('COPY_NAME');
    // eslint-disable-next-line no-control-regex
    const asciiRegex = new RegExp('^[\\u0000-\\u007F]*$', 'g');
    // If basic ASCII characters are detected (japanese and so), add a simple keyword to the slug
    const copySlug = asciiRegex.test(copyName) ? copyName : '-copy';

    // Update the slug & title.
    angular.forEach(content.title, function forEachTitleTranslation(title, lang) {
        content.title[lang] += `-${copyName}`;
    });

    const instance = Instance.getInstance();
    angular.forEach(content.slug, function forEachTitleTranslation(slug, lang) {
        if (
            angular.isDefined(instance) &&
            angular.isDefinedAndFilled(instance.langs) &&
            instance.langs.indexOf(lang) !== -1
        ) {
            content.slug[lang] += `-${copySlug.toLowerCase()}`;
        } else {
            content.slug[lang] = undefined;
        }
    });

    if (angular.isDefinedAndFilled($stateParams.instanceId)) {
        content.instance = $stateParams.instanceId;
    }

    if (angular.isDefinedAndFilled($stateParams.customContentType)) {
        content.customContentType = $stateParams.customContentType;
    }

    if (angular.isDefinedAndFilled($stateParams.contentType)) {
        content.contentType = $stateParams.contentType;
    }

    // For Veolia: the custom content type of the target instance will be fetched server side.
    if (isVeolia && angular.isDefined(content.type) && content.type === 'news') {
        content.customContentType = undefined;
        content.metadata = [];
    }
};

/**
 * Get a template from a theme if needed.
 *
 * @param  {Service} Instance     The instance service.
 * @param  {string}  templatePath The final part of the template path to load.
 * @param  {boolean} condition    The condition to determine if we want to load from the theme.
 * @param  {boolean} from         If the current template we are trying to fetch is shared between front-office
 *                                and back-office.
 * @return {string}  The template path.
 */
const getTemplatePath = (Instance, templatePath, condition, from = 'front-office') => {
    if (angular.isUndefinedOrEmpty(templatePath)) {
        return undefined;
    }

    let fullTemplatePath = `/client/${from}/modules/${templatePath}`;
    if (condition) {
        fullTemplatePath = `/client/front-office/specifics/themes/${
            Instance.getInstance().theme
        }/modules/${templatePath}`;
    }

    return fullTemplatePath;
};

/**
 * Get and cache a template.
 *
 * @param  {Angular} $http          The http service.
 * @param  {Angular} $templateCache The template cache service.
 * @param  {string}  templatePath   The path to the template.
 * @return {Promise} The promise of the get of the template.
 */
const provideTemplate = ($http, $templateCache, templatePath) => {
    const templateCacheValue = $templateCache.get(templatePath);
    if (angular.isDefinedAndFilled(templateCacheValue)) {
        return templateCacheValue;
    }

    return $http
        .get(templatePath, {
            cache: $templateCache,
        })
        .then(function appTemplateGetSuccess(html) {
            return get(html, 'data', '');
        });
};

/**
 * Resolve a promise.
 *
 * @param  {Object}          promiseContainer The promise container.
 * @return {Promise|boolean} The promise or false if no promise has been found.
 */
const resolvePromise = (promiseContainer) => {
    if (angular.isDefined(promiseContainer && angular.isDefined(promiseContainer.$promise))) {
        return promiseContainer.$promise;
    }

    return false;
};

/**
 * Setup the router and all URL related configuration.
 *
 * @param {Service} $locationProvider          The location provider.
 * @param {Service} $urlMatcherFactoryProvider The URL Matcher factory provider.
 * @param {Service} $urlRouterProvider         The URL Router provider.
 */
const setupRouter = ($locationProvider, $urlMatcherFactoryProvider, $urlRouterProvider) => {
    const forbiddenPaths = FORBIDDEN_PATH.join('|');
    const forbiddenSlugCharacters = FORBIDDEN_SLUG_CHARACTERS.join('\\');

    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
    });

    // eslint-disable-next-line unicorn/no-unsafe-regex
    const customerPathRegexp = new RegExp('(?:/a/[^/]+)*');
    $urlMatcherFactoryProvider.type('customerPath', {
        decode: (value) => (value ? value.toString() : value),
        encode: (value) => (value ? value.toString() : value),
        is(value) {
            return this.pattern.test(value);
        },

        pattern: customerPathRegexp,

        urlEncode: false,
    });

    /*
     * Can be /xxx/yyy/zzz, /adminxxx.
     * Can't be /admin, /admin/xxx.
     * The ^ and $ around the non capturing group with the forbidden paths ensures that /adminxxx works.
     *
     * To test it: https://regex101.com/r/lB0oX5/2.
     */
    const slugPathRegexp = new RegExp(
        `(?:(?!^(?:${forbiddenPaths})$)[^${forbiddenSlugCharacters}]+)(?:/(?!^(?:${forbiddenPaths})$)[^${forbiddenSlugCharacters}]+)*`,
    );
    $urlMatcherFactoryProvider.type('path', {
        decode: (value) => (value ? value.toString() : value),
        encode: (value) => (value ? value.toString() : value),
        is(value) {
            return this.pattern.test(value);
        },

        pattern: slugPathRegexp,
    });

    $urlRouterProvider.rule(function urlRouterProvider($injector, $location) {
        const urlPath = $location.path() || '';
        const hasTrailingSlash = urlPath[urlPath.length - 1] === '/';
        const splitedPath = urlPath.split('/');

        // A single instance path means an url like /:instance with trailing slash or not and no login page.
        const isSingleInstanceHomePath =
            (angular.isDefined(splitedPath[2]) && splitedPath[2] === '') ||
            (urlPath !== '/login' && angular.isUndefined(splitedPath[2]) && splitedPath[1] !== 'a');

        /*
         * A multi instance path means an url like /a/:customer/:instance with trailing slash or not and no login
         * page.
         */
        const isMultiInstanceHomePath =
            angular.isDefined(splitedPath[3]) &&
            splitedPath[1] === 'a' &&
            ((angular.isUndefined(splitedPath[4]) && splitedPath[3] !== 'login') ||
                (angular.isDefined(splitedPath[4]) && splitedPath[4] === ''));

        /*
         * If we are on another page than the singleInstanceHomePath or MultiInstanceHomePath remove the trailing
         * slash.
         */
        if (hasTrailingSlash && !isSingleInstanceHomePath && !isMultiInstanceHomePath) {
            // If last character is a slash, return the same url without the slash.
            return urlPath.substr(0, urlPath.length - 1);
        }

        if (!hasTrailingSlash && (isSingleInstanceHomePath || isMultiInstanceHomePath)) {
            return `${urlPath}/`;
        }

        return undefined;
    });
};

export {
    PERMISSIONS,
    FORBIDDEN_PATH,
    FORBIDDEN_SLUG_CHARACTERS,
    duplicateContent,
    getTemplatePath,
    provideTemplate,
    resolvePromise,
    setupRouter,
};
