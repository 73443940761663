import React, { lazy } from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import type { VideoUploadDialogProps } from './VideoUploadDialog';

const LazyVideoUploadDialog = lazy(
    () =>
        import(
            /* webpackChunkName: 'video-upload-dialog' */
            './VideoUploadDialog'
        ),
);

export const VideoUploadDialog = ({ isOpen, ...props }: VideoUploadDialogProps) => (
    <DelayedSuspense shouldLoad={isOpen}>
        {(setShouldMount) => <LazyVideoUploadDialog isOpen={isOpen} onVisibilityChange={setShouldMount} {...props} />}
    </DelayedSuspense>
);

export type { VideoUploadDialogProps };
