//! PLEASE keep this list in alphabetical order for a better readability
export enum BROADCASTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281827969
     */
    ABSOLUTE_DATE_HELPER = 'BROADCASTS.ABSOLUTE_DATE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182790715
     */
    AUDIENCE_MIGHT_EVOLVE = 'BROADCASTS.AUDIENCE_MIGHT_EVOLVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294290902
     */
    BASELINE_DATE_HELPER = 'BROADCASTS.BASELINE_DATE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182779045
     */
    BROADCAST_ARCHIVED = 'BROADCASTS.BROADCAST_ARCHIVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=279095882
     */
    BROADCAST_AUDIENCE_DESCRIPTION = 'BROADCASTS.BROADCAST_AUDIENCE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=279095918
     */
    BROADCAST_AUDIENCE_TITLE = 'BROADCASTS.BROADCAST_AUDIENCE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182657542
     */
    BROADCAST_DELETED = 'BROADCASTS.BROADCAST_DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182657697
     */
    BROADCAST_DUPLICATED = 'BROADCASTS.BROADCAST_DUPLICATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182595896
     */
    BROADCAST_SAVED = 'BROADCASTS.BROADCAST_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182656177
     */
    BROADCAST_SCHEDULED = 'BROADCASTS.BROADCAST_SCHEDULED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182592622
     */
    BROADCAST_TITLE = 'BROADCASTS.BROADCAST_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182777705
     */
    BROADCAST_UNARCHIVED = 'BROADCASTS.BROADCAST_UNARCHIVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182779582
     */
    BROADCAST_WILL_BE_SENT = 'BROADCASTS.BROADCAST_WILL_BE_SENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281871217
     */
    CAMPAIGN_FIELD_PLACEHOLDER = 'BROADCASTS.CAMPAIGN_FIELD_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182785169
     */
    DELETE_CONFIRMATION_DESCRIPTION = 'BROADCASTS.DELETE_CONFIRMATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182779768
     */
    DELETE_CONFIRMATION_TITLE = 'BROADCASTS.DELETE_CONFIRMATION_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182517166
     */
    EMPTY_STATE_DESCRIPTION = 'BROADCASTS.EMPTY_STATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327350706
     */
    EMPTY_STATE_DESCRIPTION_TIMELINE = 'BROADCASTS.EMPTY_STATE_DESCRIPTION_TIMELINE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182507451
     */
    EMPTY_STATE_TITLE = 'BROADCASTS.EMPTY_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327344817
     */
    EMPTY_STATE_TITLE_TIMELINE = 'BROADCASTS.EMPTY_STATE_TITLE_TIMELINE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182780004
     */
    FIELDS_REQUIRED = 'BROADCASTS.FIELDS_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194096932
     */
    LINK_TO_CAMPAIGN = 'BROADCASTS.LINK_TO_CAMPAIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182517180
     */
    NEW_BROADCAST = 'BROADCASTS.NEW_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=303414998
     */
    NEXT_BROADCASTS = 'BROADCASTS.NEXT_BROADCASTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375272945
     */
    NO_AUDIENCE_SET = 'BROADCASTS.NO_AUDIENCE_SET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=197752007
     */
    NO_INSIGHTS_DESCRIPTION = 'BROADCASTS.NO_INSIGHTS_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182570278
     */
    NO_SCHEDULE_SET = 'BROADCASTS.NO_SCHEDULE_SET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=396813748
     */
    OPEN_BROADCAST = 'BROADCASTS.OPEN_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=303421701
     */
    PREVIOUS_BROADCASTS = 'BROADCASTS.PREVIOUS_BROADCASTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194509976
     */
    PROJECTED_SENDING_DATE = 'BROADCASTS.PROJECTED_SENDING_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498471245
     */
    RESOURCE_UNAVAILABLE = 'BROADCASTS.RESOURCE_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182558903
     */
    SCHEDULE_BROADCAST_QUESTION = 'BROADCASTS.SCHEDULE_BROADCAST_QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182559948
     */
    SCHEDULE_DATE = 'BROADCASTS.SCHEDULE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182779636
     */
    SCHEDULE_DATE_ON = 'BROADCASTS.SCHEDULE_DATE_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281861048
     */
    SCHEDULE_THE_BROADCAST = 'BROADCASTS.SCHEDULE_THE_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281861903
     */
    SCHEDULING_DESCRIPTION = 'BROADCASTS.SCHEDULING_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182553201
     */
    SEND_BROADCAST_QUESTION = 'BROADCASTS.SEND_BROADCAST_QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=556923218
     */
    SEND_IMMEDIATELY = 'BROADCASTS.SEND_IMMEDIATELY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294276341
     */
    SEND_RELATIVELY_TO_BASELINE_DATE = 'BROADCASTS.SEND_RELATIVELY_TO_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=281835305
     */
    SEND_IMMEDIATELY_HELPER = 'BROADCASTS.SEND_IMMEDIATELY_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=182790769
     */
    SENDING_IMMEDIATELY_MESSAGE = 'BROADCASTS.SENDING_IMMEDIATELY_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=368024240
     */
    SETTINGS = 'BROADCASTS.SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189930934
     */
    TAGZ_INFORMATION_MESSAGE = 'BROADCASTS.TAGZ_INFORMATION_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=164336793
     */
    TITLE = 'BROADCASTS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277361378
     */
    TITLE_PLACEHOLDER = 'BROADCASTS.TITLE_TITLE_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=293781507
     */
    VIEW_BROADCAST_DETAILS = 'BROADCASTS.VIEW_BROADCAST_DETAILS',
}
