/* istanbul ignore file */
import { ServerListResponse } from '@lumapps/base-api/types';
import { ListFeedParams } from '@lumapps/groups/api';
import { Group } from '@lumapps/groups/types';
import { MemberRoles, MemberPermissions, MemberType } from '@lumapps/sa-members/types';
import { TopicsEdition } from '@lumapps/sa-topics/types';
import { TranslatableObject } from '@lumapps/translations';
import { User } from '@lumapps/user/types';

/**
 * The program fields that should be unique between all platform programs.
 */
export enum ProgramUniqueFields {
    name = 'name',
    slug = 'slug',
}

/**
 * The sortable field of a program.
 */
export enum FetchProgramsSortFields {
    name = 'name',
    createdAt = 'createdAt',
}

/**
 * The directions the programs can be sorted.
 */
export enum FetchProgramsSortDir {
    asc = 1,
    desc = -1,
}

/**
 * Available statuses for a program.
 */
export enum ProgramStatus {
    live = 'live',
    disabled = 'disabled',
    deleted = 'deleted',
}

/**
 * The steps available for program creation / edition.
 */
export enum FormSteps {
    informationForm = 'informationForm',
    topics = 'topics',
    members = 'members',
    socialNetworks = 'socialNetworks',
}

/**
 * The step object, to add a label to a step slug.
 */
export type Step = {
    slug: FormSteps;
    label: string;
};

/**
 * The object to manage errors on program creation.
 */
export interface ProgramPostError {
    // The step we whould go to when this error occurs
    goToStep: FormSteps;
    // The field that should be shown as in error
    errorField: string;
    // The error message to show on the field
    errorMessage: string;
}

/**
 * The program social network interface when managing social networks.
 */
export interface ProgramSocialNetwork {
    /**
     * The social platform's id
     */
    socialNetworkId: string;
    /**
     * Whether it should be active or not
     */
    active: boolean;
}

/**
 * The program membership interface that will be passed as payloads when saving program.
 */
export interface ProgramMembershipPayload {
    /**
     * The user's id, if it is an user
     */
    userId?: string;
    /**
     * The group id, if it is a group
     */
    groupId?: string;
    /**
     * The type of member
     */
    memberType: MemberType;
    /**
     * The member's role
     */
    role: MemberRoles;
}

/**
 * The Program entity
 */
export interface Program {
    /**
     * The program Id.
     */
    id: string;
    /**
     * true if the current user can edit the program
     */
    canEdit: boolean;
    /**
     * The creation date
     */
    createdAt: string;
    /**
     * The last updated date
     */
    updatedAt: string;
    /**
     * The user who created the program
     */
    createdBy: User;
    /**
     * The user who updated the program
     */
    updatedBy: User;
    /**
     * The program's name
     */
    name: TranslatableObject;
    /**
     * The program's description
     */
    description: TranslatableObject | null;
    /**
     * The program's slug
     */
    slug: TranslatableObject;
    /**
     * The activated social networks
     */
    socialNetworks?: ProgramSocialNetwork[];
    /**
     * The social networks with resolved state from the backend.
     */
    resolvedSocialNetworks?: ProgramSocialNetwork[];
    role: MemberRoles;
    permissions: MemberPermissions[];
}

/**
 * The payload for creating a program.
 */
export interface PostProgram extends Pick<Program, 'name' | 'description' | 'slug' | 'socialNetworks'> {
    /**
     * The program's topics.
     * Can only be "added" when creating a program.
     */
    topics?: Pick<TopicsEdition<TranslatableObject>, 'added'>;
    /**
     * The program's members.
     * Can only be "added" when creating a program.
     */
    memberships: {
        added: ProgramMembershipPayload[];
    };
}

/**
 * The payload for updating a program.
 */
export interface PutProgram extends Pick<Program, 'name' | 'description' | 'socialNetworks'> {
    /**
     * The program's members.
     */
    memberships?: {
        added?: ProgramMembershipPayload[];
        updated?: ProgramMembershipPayload[];
        deleted?: Omit<ProgramMembershipPayload, 'role'>[];
    };
    /**
     * The program's topics.
     */
    topics?: Partial<TopicsEdition<TranslatableObject>>;
}

/**
 * The returned user item from SA user search endpoint
 */
export interface UserForProgram extends User {
    organizationId: string;
    isInProgram: boolean;
}

/**
 * The params passed to SA group search endpoint
 */
export interface SearchGroupPayload extends ListFeedParams {
    customer: string;
    programId?: string | null;
}

/**
 * The returned group item from SA group search endpoint
 */
export interface SearchGroupResultItem extends Group {
    customer: string;
    isInProgram?: boolean;
}

/**
 * The return response from SA group search endpoint
 */
export interface SearchGroupResult extends ServerListResponse<SearchGroupResultItem> {
    items: Array<SearchGroupResultItem>;
    more: boolean;
}
