/**
 * Feature selectors.
 */

/** Base selector for currentCommunity.
 * @param  {Object} state The global store.
 * @return {Object} The wrapper object for feature current community in store.
 */
const baseCurrentCommunitySelector = (state) => state.currentCommunity;

/** Current community selector for currentCommunity.
 * @param  {Object} state The global store.
 * @return {Object} The current community in store.
 */
const selectCommunity = (state) => baseCurrentCommunitySelector(state).community;

/** Conversations selector for current Community .
 * @param  {Object} state The global store.
 * @return {Object} The current community conversations in store.
 */
const selectConversations = (state) => baseCurrentCommunitySelector(state).conversations;

/** Members selector for currentCommunity.
 * @param  {Object} state The global store.
 * @return {Object} The current community members in store.
 */
const selectMembers = (state) => baseCurrentCommunitySelector(state).members;

/** Apps selector for currentCommunity.
 * @param  {Object} state The global store.
 * @return {Object} The current community members in store.
 */
const selectApps = (state) => baseCurrentCommunitySelector(state).apps;

/** Group selector for currentCommunity.
 * @param  {Object} state The global store.
 * @return {Object} The current community group in store.
 */
const selectGroup = (state) => baseCurrentCommunitySelector(state).group;

/** Document selector for the files of Community .
 *
 * @param  {Object} state The global store.
 * @return {Object} The current community files in store.
 */
const selectFiles = (state) => baseCurrentCommunitySelector(state).files;

export { selectApps, selectCommunity, selectConversations, selectFiles, selectMembers, selectGroup };
