import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const contentAnalyticsRoute: Route = {
    path: '/admin/content/:type/analytics/:id',
    legacyId: 'app.admin.content-analytics',
    appId: AppId.backOffice,
};

const contentAnalytics = (contentType: string, contentId: string): Route => ({
    ...contentAnalyticsRoute,
    params: {
        type: contentType,
        id: contentId,
    },
});

export { contentAnalytics, contentAnalyticsRoute };
