/* istanbul ignore file */
import { BaseApiResponse } from '@lumapps/base-api';
import * as api from '@lumapps/posts/api';
import { actions } from '@lumapps/posts/ducks/slice';
import { IdeaVote } from '@lumapps/posts/types';
import { Dispatch } from '@lumapps/redux/types';

/**
 * Update the vote for an IDEA type post.
 * @param {IdeaVote} vote    The value of your vote (up or down).
 * @param {string}   postId  The id of the post you want to vote for.
 */
// eslint-disable-next-line no-unused-vars
export const updatePostVote = (vote: IdeaVote, postId: string) => async (dispatch: Dispatch) => {
    dispatch(
        actions.updatePost({
            postId,
            post: { isVoteLoading: true },
        }),
    );
    api.vote(vote, postId)
        .then((response: BaseApiResponse) => {
            dispatch(
                actions.updatePost({
                    postId,
                    post: { ...response.data, isVoteLoading: false },
                }),
            );
        })
        .catch(() => {
            dispatch(
                actions.updatePost({
                    postId,
                    post: { isVoteLoading: false },
                }),
            );
        });
};
