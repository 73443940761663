import * as React from 'react';
import * as propTypes from 'prop-types';

import { Switch, Alignment } from '@lumapps/lumx/react';

import * as types from '../../types';
import * as styleTypes from '../types';
import { translate as t } from '../../../translations';
import { InputTextField } from '../../ui';
import { withOnChangeProperty } from '../../../utils';

/**
 * Renders a form that enables editing height settings.
 */
export class HeightSettings extends React.PureComponent {
    static propTypes = {
        /** Whether `fullHeight` should be editable or not. */
        fullHeight: propTypes.bool,
        name: types.properties,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func,
        /** Current height style. */
        value: styleTypes.heightStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { value = {}, fullHeight = false } = this.props;

        return (
            <div style={{ marginTop: -16 }}>
                <InputTextField
                    value={value.height}
                    name="height"
                    onChange={this.onChangeProperty}
                    label={t(value.fullHeight ? 'STYLE.MIN_HEIGHT' : 'STYLE.FIXED_HEIGHT')}
                    helpText={t(value.fullHeight ? 'STYLE.MIN_HEIGHT_HELPER' : 'STYLE.FIXED_HEIGHT_HELPER')}
                    type="number"
                    min={0}
                />
                {fullHeight && (
                    <div className="pt+ pb0">
                        <Switch
                            checked={value.fullHeight}
                            helper={t('STYLE.FULL_HEIGHT_HELPER')}
                            name="fullHeight"
                            position={Alignment.right}
                            onChange={(checked) => this.onChangeProperty(checked, 'fullHeight')}
                        >
                            {t('STYLE.FULL_HEIGHT')}
                        </Switch>
                    </div>
                )}
            </div>
        );
    }
}
