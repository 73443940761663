import React, { PureComponent } from 'react';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import isEmpty from 'lodash/isEmpty';

import { Tagz } from '@lumapps/folksonomy/components/Tagz';
import { getWidgetTranslation } from 'components/services/widget';

import { widgetCommunityIntroTypes } from './types';

/**
 * Displays the widget community intro.
 */
export class WidgetCommunityIntro extends PureComponent {
    static propTypes = widgetCommunityIntroTypes;

    /**
     * Defines this widget as non-editable.
     *
     * @return {boolean} False.
     */
    static isEditable() {
        return false;
    }

    /**
     * Defines whether the widget is empty or not.
     *
     * @param  {Array}   value The current community description.
     * @return {boolean} Whether community description is empty or not.
     */
    static isWidgetEmpty({ value }) {
        return value === undefined || !getWidgetTranslation(value);
    }

    /**
     * Determines whether the widget is hidden or not.
     *
     * @param  {Object}  params The widget props.
     * @return {boolean} Whether the widget is hidden or not.
     */
    static isWidgetHidden(params) {
        return WidgetCommunityIntro.isWidgetEmpty(params);
    }

    render() {
        const {
            properties,
            value: { description, tagz },
            isTagzEnabled,
            theme,
        } = this.props;

        const translatedDescription = getWidgetTranslation(description, properties.noFallback);
        const sanitizedValue = sanitizeHTML(translatedDescription, {
            allowedAttr: ['href', 'src'],
            allowedTags: ['a', 'b', 'br', 'em', 'i', 'img', 'p', 'strong'],
        });

        const htmlValue = {
            __html: sanitizedValue,
        };

        return (
            <>
                {translatedDescription && !isEmpty(translatedDescription) && (
                    <div dangerouslySetInnerHTML={htmlValue} className="widget-community-intro__content" />
                )}
                {isTagzEnabled && tagz && tagz.length > 0 && (
                    <Tagz className={!isEmpty(translatedDescription) ? 'has-description': undefined} values={tagz} theme={theme} />
                )}
            </>
        );
    }
}
