import React, { useMemo } from 'react';

import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import includes from 'lodash/fp/includes';
import matchesProperty from 'lodash/fp/matchesProperty';
import values from 'lodash/fp/values';

import { classnames, padding } from '@lumapps/classnames';
import { GenericEntityBlock } from '@lumapps/lumx-blocks/components/GenericEntityBlock';
import { Size } from '@lumapps/lumx/react';
import { spaceToCommunityVisibilityMap } from '@lumapps/spaces/constants';
import { SpaceVisibility } from '@lumapps/spaces/types';
import { useTranslate } from '@lumapps/translations';

import { COMMUNITY_VISIBILITIES, SPACE_VISIBILITIES } from '../../constants';
import { CommunityPrivacy, VisibilityOptionType } from '../../types';

interface CommunityVisibilityBlockProps {
    /** custom classname applied to the block */
    className?: string;
    /** display the word "community" in translations even if it is a space */
    displayAsCommunity?: boolean;
    /** whether the visibility is one of space (true) or of community (false, by default) */
    isSpace?: boolean;
    /** the visibility of the community or space */
    visibility?: Exclude<CommunityPrivacy, CommunityPrivacy.secret> | SpaceVisibility;
}

export const CommunityVisibilityBlock: React.FC<CommunityVisibilityBlockProps> = ({
    className,
    displayAsCommunity = false,
    isSpace = false,
    visibility: visibilityProp,
    ...genericEntityBlockProps
}) => {
    const { translateKey } = useTranslate();

    const visibility = useMemo(() => {
        // To find visibility info we must first transform the visibility we get in props if the community is a space but a
        // community visibility is being passed instead of a space one, which happens when we use old APIs
        const visibilityId =
            visibilityProp && isSpace && flow(values, includes(visibilityProp))(SpaceVisibility)
                ? spaceToCommunityVisibilityMap[visibilityProp as SpaceVisibility]
                : (visibilityProp as Exclude<CommunityPrivacy, CommunityPrivacy.secret>);

        return find(
            matchesProperty('id', visibilityId),
            isSpace && !displayAsCommunity ? SPACE_VISIBILITIES : COMMUNITY_VISIBILITIES,
        );
    }, [displayAsCommunity, isSpace, visibilityProp]) as VisibilityOptionType;

    if (!visibility) {
        return null;
    }

    return (
        <GenericEntityBlock
            title={translateKey(visibility.title)}
            className={classnames(className, padding('vertical', 'huge'))}
            description={translateKey(visibility.description)}
            titleProps={{
                id: `option-${visibility.id}-title`,
            }}
            descriptionProps={{
                id: `option-${visibility.id}-description`,
                noWrap: false,
            }}
            thumbnail={{
                icon: visibility.icon,
                size: Size.m,
            }}
            {...genericEntityBlockProps}
        />
    );
};
