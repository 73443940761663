(function IIFE() {
    'use strict';

    /////////////////////////////

    function AdminSectionHeaderController() {
        'ngInject';

        var vm = this;
    }

    /////////////////////////////

    /**
     * Admin section header directive.
     *
     * Display a section at the top of an admin page that includes a title or some help text.
     */

    function AdminSectionHeaderDirective() {
        return {
            bindToController: true,
            controller: AdminSectionHeaderController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: '/client/common/modules/layout/modules/admin/views/admin-section-header.html',
            transclude: {
                actions: '?adminSectionHeaderActions',
                filter: '?adminSectionHeaderFilter',
                title: 'adminSectionHeaderTitle',
            },
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('adminSectionHeader', AdminSectionHeaderDirective);
})();
