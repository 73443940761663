export enum WIDGET_SHARE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?view=multi&search=share%20on%20google
     */
    SHARE_ON_GOOGLE_CURRENTS = 'WIDGET_SHARE.SHARE_ON_GOOGLE_CURRENTS',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?view=multi&search=share%20on%20vi
     */
    SHARE_ON_VIADEO = 'WIDGET_SHARE.SHARE_ON_VIADEO',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?view=multi&search=share%20on%20email
     */

    SHARE_ON_EMAIL = 'WIDGET_SHARE.SHARE_ON_EMAIL',

    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?view=multi&search=FRONT.SHARE_ON
     */
    SHARE_ON = 'FRONT.SHARE_ON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23400231
     */
    SHARE_FACEBOOK_BTN_TITLE = 'SOCIAL_ADVOCACY_FORM_SHARE_FACEBOOK_BTN_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=16050071
     */
    SHARE_MODAL_LINKEDIN = 'FRONT.SOCIAL_ADVOCACY.SHARE_MODAL.LINKEDIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24064314
     */
    SHARE_MODAL_TWITTER = 'FRONT.SOCIAL_ADVOCACY.SHARE_MODAL.TWITTER',
}
