import { getVideoUrlWebsiteName } from '@lumapps/medias/utils';
import { and } from '@lumapps/utils/function/predicate/and';
import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchAttribute } from '@lumapps/wrex/serialization/dita/fromDITA/utils';
import { EmbeddedType, OgDescription, OgImage, OgTitle } from '@lumapps/wrex/serialization/dita/types';

import { EmbeddedVideoElement } from '../../types';
import { createEmbeddedVideo } from '../../utils/createEmbeddedVideo';

/**
 * Convert Dita Embedded Video into slate Embedded Video element.
 */
export const EMBEDDED_VIDEO_CONVERT: ElementConvertOption = {
    test: and(matchAttribute('type', /lumapps:embedded/), (node) =>
        Boolean(getVideoUrlWebsiteName((node as EmbeddedType)?.attributes?.href)),
    ),
    transform: (nd): EmbeddedVideoElement => {
        const node = nd as EmbeddedType;
        const ogTitleNode = node.children.find(matchAttribute('name', /og:title/)) as OgTitle;
        const ogDescriptionNode = node.children.find(matchAttribute('name', /og:description/)) as OgDescription;
        const ogImageNode = node.children.find(matchAttribute('name', /og:image/)) as OgImage;

        const { href } = node?.attributes || {};

        return createEmbeddedVideo({
            title: ogTitleNode?.attributes.value,
            thumbnailUrl: ogImageNode?.attributes.value,
            description: ogDescriptionNode?.attributes.value,
            url: href,
        });
    },
};
