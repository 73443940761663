import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import {
    Alignment,
    Button,
    ColorPalette,
    ColorVariant,
    Divider,
    Emphasis,
    FlexBox,
    Heading,
    Orientation,
    Size,
    Switch,
    Text,
    Typography,
} from '@lumapps/lumx/react';
import { InstalledExtensionAPIProps } from '@lumapps/marketplace/legacy-types';
import { MicroAppPickerDialog } from '@lumapps/micro-app-pickers/components/MicroAppPickerDialog';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { SettingSection } from '@lumapps/widget-settings/components/SettingSection';

import { SCOPE } from '../../constants';
import { WIDGET_MICRO_APP } from '../../keys';
import { WidgetMicroAppSettingsProps } from '../../types';
import { MicroAppPreview } from './MicroAppPreview';

const CLASSNAME = 'widget-micro-app-settings';

/**
 * React component adding custom settings to the micro-app core widget
 * See: `apps/legacy/client/front-office/modules/content/modules/widget/modules/widget-micro-app/`
 * This component displays:
 * - If the extensionId is provided: `MicroAppPreview``
 * - Else, a button to open `MicroAppPicker`
 * @param WidgetMicroAppSettingsProps
 * @returns WidgetMicroAppSettings
 */
export const WidgetMicroAppSettings: React.FC<WidgetMicroAppSettingsProps> = ({ properties, onChange }) => {
    const { extensionId, hasIcon, hasTitle, hasRefreshButton } = properties || {};
    const displaySettings = React.useMemo(
        () => ({
            hasIcon: hasIcon ?? true,
            hasTitle: hasTitle ?? true,
            hasRefreshButton: hasRefreshButton ?? true,
        }),
        [hasIcon, hasRefreshButton, hasTitle],
    );
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const { block } = useClassnames(CLASSNAME);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleConfirm = React.useCallback(
        (microApps: InstalledExtensionAPIProps[]) => {
            const { id, extension } = microApps[0];
            onChange({
                extensionId: id,
                thumbnail: extension?.medias?.previewImage,
                extensionName: extension?.name,
                extensionIcon: extension?.icon,
                ...displaySettings,
            });
            setIsOpen(false);
        },
        [displaySettings, onChange],
    );

    return (
        <>
            {/* micro-app selection section */}
            <SettingSection
                title={translateKey(WIDGET_MICRO_APP.MICRO_APP_SELECTION)}
                className={block([margin('vertical', Size.huge)])}
            >
                {extensionId ? (
                    <MicroAppPreview properties={properties} onChange={onChange} />
                ) : (
                    <FlexBox
                        vAlign={Alignment.center}
                        hAlign={Alignment.center}
                        orientation={Orientation.vertical}
                        gap={Size.big}
                    >
                        <FlexBox vAlign={Alignment.center} hAlign={Alignment.center} orientation={Orientation.vertical}>
                            <Heading as="h2" typography={Typography.subtitle1}>
                                {translateKey(WIDGET_MICRO_APP.WIDGET_NOT_CONFIGURED)}
                            </Heading>
                            <Text as="p" typography={Typography.body1} style={{ textAlign: 'center' }}>
                                {translateKey(WIDGET_MICRO_APP.CONFIGURATION_INSTRUCTIONS)}
                            </Text>
                        </FlexBox>
                        <Button emphasis={Emphasis.medium} onClick={() => setIsOpen(!isOpen)}>
                            {translateKey(GLOBAL.SELECT)}
                        </Button>
                        <MicroAppPickerDialog
                            onConfirm={(microApps) => handleConfirm(microApps)}
                            isMultipleSelect={false}
                            isOpen={isOpen}
                            onCancel={() => setIsOpen(false)}
                            onVisibilityChange={(open) => {
                                if (!open) {
                                    setIsOpen(false);
                                }
                            }}
                        />
                    </FlexBox>
                )}
            </SettingSection>

            {/* micro-app information displayed */}
            {extensionId ? (
                <>
                    <Divider />
                    <SettingSection
                        title={translateKey(WIDGET_BASE.SETTINGS_INFORMATION_DISPLAYED_TITLE)}
                        className={block([margin('top', Size.huge)])}
                    >
                        <FlexBox orientation={Orientation.vertical} gap={Size.big}>
                            <Heading
                                as="h3"
                                typography={Typography.overline}
                                color={ColorPalette.dark}
                                colorVariant={ColorVariant.L2}
                            >
                                {translateKey(WIDGET_MICRO_APP.MICRO_APP_HEADER)}
                            </Heading>
                            <Switch
                                isChecked={hasIcon}
                                onChange={(isChecked) => onChange({ hasIcon: isChecked })}
                                {...get({ element: 'switch', action: 'toggle-icon' })}
                            >
                                {translateKey(GLOBAL.ICON)}
                            </Switch>
                            <Switch
                                isChecked={hasTitle}
                                onChange={(isChecked) => onChange({ hasTitle: isChecked })}
                                {...get({ element: 'switch', action: 'toggle-title' })}
                            >
                                {translateKey(GLOBAL.TITLE)}
                            </Switch>
                            <Switch
                                isChecked={hasRefreshButton}
                                onChange={(isChecked) => onChange({ hasRefreshButton: isChecked })}
                                {...get({ element: 'switch', action: 'toggle-refresh-button' })}
                            >
                                {translateKey(WIDGET_MICRO_APP.REFRESH_BUTTON)}
                            </Switch>
                        </FlexBox>
                    </SettingSection>
                </>
            ) : null}
        </>
    );
};
