import React from 'react';

import { Select, SelectProps, TextFieldProps } from '@lumapps/lumx/react';
import { useTranslate, getKeyForLanguage } from '@lumapps/translations';

import { useLanguageSelector } from '../../hooks/useLanguageSelector';
import { TranslatedLanguage } from '../../types';
import { LanguageSelectorList } from './LanguageSelectorList';

export interface LanguageSelectorProps {
    /** Should the searchbar be focused when dropdown is open ? */
    autofocus?: boolean;
    /** Custom class name */
    className?: string;
    /** By default the languages list is A-Z sorted.
     *
     * Use this prop to keep the language list in the same order as it was passed
     * via the languages prop.
     *
     * Use-case: the language list contains a top 5, those values are duplicated
     * in the rest of the list. In this scenario, you are responsible for sorting
     * the list
     */
    keepLanguageOrder?: boolean;
    /** list of languages ids ('es', 'en', 'fr') */
    languages: string[];
    /** selected language id */
    selected?: string;
    /** whether the dropdown should be open or not */
    open?: boolean;
    /** callback to be executed once the language has changed */
    onChange: (language: string) => void;
    /** props that will be passed into the Select */
    selectProps?: Partial<Omit<SelectProps, 'value'>>;
    /** props that will be passed into the Textfield (searchbar) */
    textFieldProps?: Partial<TextFieldProps>;
    /** whether the dropdown options can be searched or not */
    isSearchable?: boolean;
    /**
     * Optional separator in the language list.
     *
     * This value is zero-indexed, so to display a separator
     * after the 5th item, separatorPosition should be 4 :)
     */
    separatorPosition?: number;
    /** Optional custom language translation function
     *
     * Using this prop is reserved for very specific scenarios.
     * If you are using it, please make sure that you understand what you are doing and the consequences
     */
    translateLanguages?(codes: string[]): TranslatedLanguage[];
    /** whether the selector is on read only mode or not */
    isReadOnly?: boolean;
}

/**
 * Displays a dropdown in order to select a language from a list of languages
 *
 * @family Translations
 * @param LanguageSelectorProps
 * @returns LanguageSelector
 */
const LanguageSelector = ({
    autofocus = false,
    className,
    isSearchable = false,
    keepLanguageOrder = false,
    languages,
    onChange,
    open = false,
    selected = '',
    selectProps = {},
    separatorPosition,
    textFieldProps = {},
    translateLanguages,
    isReadOnly = false,
}: LanguageSelectorProps) => {
    const { translateKey } = useTranslate();

    const {
        inputRef,
        getShouldDisplaySeparator,
        filteredLanguages,
        onLanguageSelected,
        onDropdownClose,
        onClick,
        isOpen,
        filterValue,
        setFilterValue,
    } = useLanguageSelector({
        closeOnSelect: true,
        defaultOpen: open,
        translateLanguages,
        languages,
        onChange,
        keepLanguageOrder,
        isSearchable,
        separatorPosition,
        autofocus,
    });

    /** The selector value should be:
     *
     * - empty if no language was selected
     * - translated via the default translation function
     * - in rare cases, translated via the custom translation function passed as prop
     */
    const langValue = React.useMemo(() => {
        if (!selected || selected === '') {
            return '';
        }
        if (!translateLanguages) {
            return translateKey(getKeyForLanguage(selected));
        }
        const [lang] = translateLanguages([selected]);
        return lang.label;
    }, [selected, translateLanguages, translateKey]);

    /** Callback to define whether a value is selected or not */
    const selectedValueSelector = (langKey: string) => selected === langKey;

    return (
        <Select
            className={className}
            value={langValue}
            isOpen={isReadOnly ? false : isOpen}
            onInputClick={onClick}
            onDropdownClose={onDropdownClose}
            {...selectProps}
            closeOnClick={isSearchable ? false : selectProps.closeOnClick}
        >
            <LanguageSelectorList
                isSearchable={isSearchable}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                searchInputRef={inputRef}
                textFieldProps={textFieldProps}
                languages={filteredLanguages}
                getShouldDisplayAsSelected={selectedValueSelector}
                getShouldDisplaySeparator={getShouldDisplaySeparator}
                onLanguageSelected={onLanguageSelected}
            />
        </Select>
    );
};

export { LanguageSelector };
