import { LEGACY_ACTIVITY_ACTION_TYPE, ACTIVITY_CONTENT_TYPE } from './types';

export enum STREAMS {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452996
     */
    OTHER_PEOPLE = 'GROUPED_ACTIVITY_OTHER_PEOPLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452997
     */
    OTHER_PEOPLE_PLURAL = 'GROUPED_ACTIVITY_OTHER_PEOPLE_PLURAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452990
     */
    ACTION_TYPE_UPDATE = 'GROUPED_ACTIVITY_ACTION_TYPE_UPDATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452991
     */
    ACTION_TYPE_UPDATE_PLURAL = 'GROUPED_ACTIVITY_ACTION_TYPE_UPDATE_PLURAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452988
     */
    ACTION_TYPE_PROFILE = 'GROUPED_ACTIVITY_ACTION_TYPE_PROFILE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452989
     */
    ACTION_TYPE_PROFILE_PLURAL = 'GROUPED_ACTIVITY_ACTION_TYPE_PROFILE_PLURAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452986
     */
    ACTION_TYPE_LIKE = 'GROUPED_ACTIVITY_ACTION_TYPE_LIKE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452987
     */
    ACTION_TYPE_LIKE_PLURAL = 'GROUPED_ACTIVITY_ACTION_TYPE_LIKE_PLURAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452984
     */
    ACTION_TYPE_CREATE = 'GROUPED_ACTIVITY_ACTION_TYPE_CREATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452985
     */
    ACTION_TYPE_CREATE_PLURAL = 'GROUPED_ACTIVITY_ACTION_TYPE_CREATE_PLURAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452982
     */
    ACTION_TYPE_COMMENT = 'GROUPED_ACTIVITY_ACTION_TYPE_COMMENT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452983
     */
    ACTION_TYPE_COMMENT_PLURAL = 'GROUPED_ACTIVITY_ACTION_TYPE_COMMENT_PLURAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452995
     */
    CONTENT_TYPE_POST = 'GROUPED_ACTIVITY_CONTENT_TYPE_POST',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452992
     */
    CONTENT_TYPE_CUSTOM = 'GROUPED_ACTIVITY_CONTENT_TYPE_CUSTOM',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=15750045
     */
    CONTENT_TYPE_CUSTOM_LIST = 'GROUPED_ACTIVITY_CONTENT_TYPE_CUSTOM_LIST',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452993
     */
    CONTENT_TYPE_NEWS = 'GROUPED_ACTIVITY_CONTENT_TYPE_NEWS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452994
     */
    CONTENT_TYPE_PAGE = 'GROUPED_ACTIVITY_CONTENT_TYPE_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300257302
     */
    PUBLISHED_AN_EVENT = 'STREAMS.PUBLISHED_AN_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=300756750
     */
    PUBLISHED_AN_ARTICLE = 'STREAMS.PUBLISHED_AN_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=344281359
     */
    PUBLISHED_A_PIECE_OF_CONTENT = 'STREAMS.PUBLISHED_A_PIECE_OF_CONTENT',
}

/** Check if we should use the '_PLURAL' key instead, when actionCount is more than 1. Used by stream activity banner */
export const checkPlural = (key: string, actionCount = 1) => `${key}${actionCount > 2 ? '_PLURAL' : ''}`;

/** Compute the correct key depending on the action type and the action count. Used by stream activity banner */
export const getActionTypeKey = (actionType: LEGACY_ACTIVITY_ACTION_TYPE | undefined, actionCount?: number) =>
    actionType ? checkPlural(`GROUPED_ACTIVITY_ACTION_TYPE_${actionType?.toUpperCase()}`, actionCount) : '';

/** Dictionary of the keys for the defined content types */
export const CONTENT_TYPE_KEYS: Record<ACTIVITY_CONTENT_TYPE, string> = {
    [ACTIVITY_CONTENT_TYPE.post]: STREAMS.CONTENT_TYPE_POST,
    [ACTIVITY_CONTENT_TYPE.news]: STREAMS.CONTENT_TYPE_NEWS,
    [ACTIVITY_CONTENT_TYPE.page]: STREAMS.CONTENT_TYPE_PAGE,
    [ACTIVITY_CONTENT_TYPE.custom]: STREAMS.CONTENT_TYPE_CUSTOM,
    [ACTIVITY_CONTENT_TYPE.customList]: STREAMS.CONTENT_TYPE_PAGE,
    [ACTIVITY_CONTENT_TYPE.directory]: STREAMS.CONTENT_TYPE_PAGE,
    [ACTIVITY_CONTENT_TYPE.community]: STREAMS.CONTENT_TYPE_PAGE,
};
