/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';
import { isSAEnabled, shouldGetUser } from '@lumapps/sa-connected-user/ducks/selectors';
import { getUser } from '@lumapps/sa-connected-user/ducks/thunks';

import { AdminSideNavigation } from './AdminSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isSAEnabled: isSAEnabled(state),
    shouldGetUser: shouldGetUser(state),
});

const mapDispatchToProps = {
    getUser,
};

const ConnectedAdminSideNavigation = connect(mapStateToProps, mapDispatchToProps)(AdminSideNavigation);
ConnectedAdminSideNavigation.displayName = 'SideNavigationItem';

export { ConnectedAdminSideNavigation as AdminSideNavigation };
