import React, { Fragment } from 'react';

import { SkeletonWrapper } from '@lumapps/a11y/components/SkeletonWrapper';
import { useClassnames } from '@lumapps/classnames';
import { get as getConfig } from '@lumapps/constants';
import { List, Size } from '@lumapps/lumx/react';

import { NotificationGroup } from '../../types';
import { mapNotificationGroupToProps } from '../../utils/mapNotificationGroupToProps';
import { NotificationListItem } from '../NotificationListItem/NotificationListItem';
import { NotificationListItemSkeleton } from '../NotificationListItem/NotificationListItemSkeleton';
import { NotificationEmptyList } from './NotificationEmptyList';
import { NotificationErrorState } from './NotificationErrorState';
import { NotificationListSubHeader } from './NotificationListSubHeader';

import './index.scss';

const Config = getConfig();

export type NotificationListProps = {
    notifications: NotificationGroup[];
    isLoading: boolean;
    hasError: boolean;
    hasNotifications: boolean;
    setNotificationRead?(notificationId: string, isRead: boolean, shouldCloseDropdown?: boolean): void;
    deleteNotification?(notificationId: string, isNotificationRead: boolean): void;
    closeNotificationMenu?(): void;
    listNotifications(): void;
};

const CLASSNAME = 'notification-center-list';

const NotificationList: React.FC<NotificationListProps> = ({
    notifications,
    isLoading,
    hasError,
    hasNotifications,
    setNotificationRead,
    deleteNotification,
    closeNotificationMenu,
    listNotifications,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const notificationItemClassName = element('item');
    const notificationContextMenuClassName = element('context-menu');
    const notificationListClassName = element('list');
    const showSubHeader = (priority: number, notificationIndex: number) => {
        return (
            (notificationIndex === 0 && priority !== 0) ||
            (notificationIndex !== 0 && priority !== notifications[notificationIndex - 1].notification.priority)
        );
    };

    /**
     * Let me tell you a little story about Angular JS and React and how the Angular JS router works.
     * On the legacy side, there is a listener on every single click on the page, that checks if you have
     * clicked a link. If you have, that listener will determine whether the URL is present in the
     * Angular router (meaning there is a regexp that matches with that url) and after some weird
     * computation magic, they decide to redirect to that URL. Since that listener is added on every single click on the page,
     * it detects the click on the context menu icon for the notification, and since that icon is inside a Link,
     * voila, the event gets triggered, the obscure logic is executed, making the application to navigate
     * to the notification while you only wanted to open a menu.
     *
     * The only way to prevent this is to prevent the default behaviour when that icon is clicked, but still
     * allow to trigger the action on React side. We do that by calling preventDefault() but NOT stopProgragation().
     *
     * I know, this is horrible, and TODO => remove this after the migration.
     */
    React.useEffect(() => {
        const notificationList = document.querySelector(`.${notificationListClassName}`);
        const onNotificationListClick = (e: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (e && e.target && e.target.classList.contains(notificationContextMenuClassName)) {
                // Prevent the user to navigate to the notification link when he clicks on the context menu
                e.preventDefault();
            }
        };

        if (Config.isLegacyContext && notificationList) {
            notificationList.addEventListener('click', onNotificationListClick);
        }

        return () => {
            if (Config.isLegacyContext && notificationList) {
                notificationList.removeEventListener('click', onNotificationListClick);
            }
        };
    });

    return (
        <div className={CLASSNAME} aria-busy={isLoading} aria-live="polite">
            {hasNotifications && !hasError && (
                <List className={notificationListClassName} aria-busy={isLoading}>
                    {notifications.map((group, index) => (
                        <Fragment key={group.notification.uid}>
                            {showSubHeader(group.notification.priority, index) && (
                                <NotificationListSubHeader priority={group.notification.priority} />
                            )}
                            <NotificationListItem
                                {...mapNotificationGroupToProps(group)}
                                className={notificationItemClassName}
                                notificationContextMenuClassName={notificationContextMenuClassName}
                                setNotificationRead={setNotificationRead}
                                deleteNotification={deleteNotification}
                                closeNotificationMenu={closeNotificationMenu}
                            />
                        </Fragment>
                    ))}
                </List>
            )}
            {hasError && !isLoading && <NotificationErrorState listNotifications={listNotifications} />}
            {isLoading && (
                <SkeletonWrapper>
                    <List role="presentation" className={element('list')} itemPadding={Size.huge}>
                        <NotificationListItemSkeleton className={element('skeleton')} />
                        {notifications && notifications.length === 0 && (
                            <>
                                <NotificationListItemSkeleton className={element('skeleton')} />
                                <NotificationListItemSkeleton className={element('skeleton')} />
                            </>
                        )}
                    </List>
                </SkeletonWrapper>
            )}
            {(!notifications || (notifications.length === 0 && !hasError && !isLoading)) && <NotificationEmptyList />}
        </div>
    );
};

export { NotificationList, CLASSNAME };
