import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    GroupChipPickerField,
    GroupChipPickerFieldProps,
} from '@lumapps/group-pickers/components/GroupChipPickerField';
import { Group } from '@lumapps/groups/types';
import { doesCurrentInstanceAllowPublicContent } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { SegmentDetailsPopover } from '@lumapps/segment/components/SegmentDetailsPopover/SegmentDetailsPopover';
import { SEGMENT_IN_GROUP_PICKER_IDENTIFIER } from '@lumapps/segment/constants';
import { useTranslate } from '@lumapps/translations';

import { CONTENTS } from '../../keys';

import './index.scss';

export type ContentAudienceTargetingProps = Pick<GroupChipPickerFieldProps, 'groupAll' | 'groupPublic'> & {
    defaultVisibleByIds: string[];
    defaultHighlightedForIds: string[];
    restrictToFeedsIds?: string[];
    isVisibleByMissing?: boolean;
    isDisabled?: boolean;
    onVisibleChange: (visibleByGroups: Group[]) => void;
    onHighlightedChange: (highlightedForGroups: Group[]) => void;
};

const CLASSNAME = 'content-audience-targeting';

export const ContentAudienceTargeting = ({
    defaultVisibleByIds,
    defaultHighlightedForIds,
    restrictToFeedsIds,
    groupAll,
    groupPublic,
    isVisibleByMissing,
    isDisabled = false,
    onVisibleChange,
    onHighlightedChange,
}: ContentAudienceTargetingProps) => {
    const { translateKey } = useTranslate();
    const isPublicContentAuthorized = useSelector(doesCurrentInstanceAllowPublicContent);
    const { block, element } = useClassnames(CLASSNAME);

    // Need internal state to have great perf in legacy context when the user pick a group
    const [visibleBy, setVisibleBy] = React.useState<Group[]>([]);
    const [highlightedFor, setHighlightedFor] = React.useState<Group[]>([]);

    // We need to wait for the onVisibleByChange to be completed to launch onHighlightedForChange to avoid empty state
    const [visibleByCompleted, setVisibleByCompleted] = React.useState(false);

    const containsAllOrPublic = React.useCallback(
        (ids: string[]) => {
            return (groupAll && ids.includes(groupAll?.id)) || (groupPublic && ids.includes(groupPublic.id));
        },
        [groupAll, groupPublic],
    );

    const isAllInRestriction = React.useMemo(() => {
        if (groupAll && restrictToFeedsIds) {
            return restrictToFeedsIds.includes(groupAll.id);
        }

        return false;
    }, [restrictToFeedsIds, groupAll]);

    const isPublicInRestriction = React.useMemo(() => {
        if (groupPublic && restrictToFeedsIds) {
            return restrictToFeedsIds.includes(groupPublic.id);
        }

        return false;
    }, [restrictToFeedsIds, groupPublic]);

    /** Build custom params */
    const customVisibleByParams = React.useMemo<GroupChipPickerFieldProps['customSearchParams']>(
        () => ({
            restrictToFeeds: isAllInRestriction || isPublicInRestriction ? [] : restrictToFeedsIds,
            excludeSegments: false,
        }),
        [restrictToFeedsIds, isAllInRestriction, isPublicInRestriction],
    );
    const customHighlightedForParams = React.useMemo<GroupChipPickerFieldProps['customSearchParams']>(() => {
        const visibleByIds = visibleBy.map((gr) => gr.id);
        return {
            restrictToFeeds: containsAllOrPublic(visibleByIds) ? [] : visibleByIds,
            excludeSegments: false,
        };
    }, [visibleBy, containsAllOrPublic]);

    const shouldDisplayDefaultGroups = React.useMemo(() => {
        const ids = visibleBy.map((gr) => gr.id);
        return groupAll && ids.includes(groupAll?.id);
    }, [groupAll, visibleBy]);

    const onHighlightedForChange = (groups: Group[]) => {
        setHighlightedFor(groups);
        onHighlightedChange(groups);
    };

    const onVisibleByChange = (groups: Group[]) => {
        const groupIds = groups.map((gr) => gr.id);

        setVisibleBy(groups);
        onVisibleChange(groups);

        // Can only highlight for groups that are in the visible by
        if (!containsAllOrPublic(groupIds)) {
            // check if some highlighted for groups should be removed
            const cleanedGroups = highlightedFor.filter((group) => groupIds.includes(group.id));

            onHighlightedForChange(cleanedGroups);
        }

        setVisibleByCompleted(true);
    };

    const getGroupAfter = (group: Group) => {
        const { functionalInnerId: id } = group;

        // Segment ids always start with this identifier, so we use this to identify segments
        if (id?.startsWith(SEGMENT_IN_GROUP_PICKER_IDENTIFIER)) {
            return (
                <SegmentDetailsPopover
                    scope="designer"
                    segmentId={id.slice(SEGMENT_IN_GROUP_PICKER_IDENTIFIER.length)}
                />
            );
        }
        return null;
    };

    return (
        <div className={block()}>
            <GroupChipPickerField
                label={translateKey(CONTENTS.SETTINGS_VISIBLE_BY)}
                helper={translateKey(CONTENTS.SETTINGS_VISIBLE_BY_HELP)}
                selectedGroups={visibleBy}
                onGroupsSelected={onVisibleByChange}
                isRequired
                fitToAnchorWidth="width"
                shouldCloseOnSelect={false}
                customSearchParams={customVisibleByParams}
                hasClearButton={false}
                defaultValuesIds={defaultVisibleByIds}
                displayAllGroup={!restrictToFeedsIds || (groupAll && (isAllInRestriction || isPublicInRestriction))}
                displayPublicGroup={
                    isPublicContentAuthorized &&
                    (!restrictToFeedsIds || (groupPublic && (isPublicInRestriction || isAllInRestriction)))
                }
                searchInPlatform={false}
                errorMessage={
                    isVisibleByMissing && visibleBy.length === 0 ? translateKey(CONTENTS.FEED_REQUIRED) : undefined
                }
                isDisabled={isDisabled}
                pickerClassName={element('picker')}
                className={element('visible-by')}
                getGroupAfter={getGroupAfter}
            />
            <GroupChipPickerField
                label={translateKey(CONTENTS.SETTINGS_HIGHLIGHTED_FEEDS)}
                helper={translateKey(CONTENTS.SETTINGS_HIGHLIGHTED_FEEDS_HELP)}
                selectedGroups={highlightedFor}
                onGroupsSelected={onHighlightedForChange}
                isDisabled={visibleBy.length === 0 || isDisabled}
                fitToAnchorWidth="width"
                shouldCloseOnSelect={false}
                customSearchParams={customHighlightedForParams}
                hasClearButton={false}
                displayAllGroup={shouldDisplayDefaultGroups}
                displayPublicGroup={false}
                defaultValuesIds={visibleByCompleted ? defaultHighlightedForIds : undefined}
                searchInPlatform={false}
                pickerClassName={element('picker')}
                getGroupAfter={getGroupAfter}
                className={element('highlighted-for')}
                shouldDisplayLoadingState
                refreshSuggestionsOnOpen
            />
        </div>
    );
};
