import React, { forwardRef } from 'react';

import { classnames } from '@lumapps/classnames';
import { Theme } from '@lumapps/lumx/react';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { useContentLink } from '../../hooks/useContentLink';
import { ContentLinkRef } from '../../types';

export interface ContentLinkProps {
    /** Where this content link will redirect the user to */
    to: ContentLinkRef;
    /** Set this flag to open cross instance content in a new tab. */
    crossInstanceInNewTab?: boolean;
    theme?: Theme;
    // Forwarded props.
    [key: string]: any;
}

const CLASSNAME = 'content-link';

export const ContentLink = forwardRef<HTMLAnchorElement, ContentLinkProps>(
    ({ to, children, theme, className, crossInstanceInNewTab, onClick, ...props }, ref) => {
        const classNameColor = theme === Theme.dark ? 'lumx-link--color-light' : undefined;
        const contentLink = useContentLink(to, onClick);
        // The prop target takes priority over the default content link target.
        let target = props.target || contentLink.props.target;

        /**
         * Force the `target` to `_blank`, make sure that the link is opened in a new window when :
         * - The content is from another instance
         * */
        if (crossInstanceInNewTab && contentLink.contentLinkRef.instance) {
            target = '_blank';
        }

        // Anchor rel should be set when target is _blank.
        const rel = target === '_blank' ? 'noreferrer noopener' : undefined;
        return (
            <a
                {...props}
                href={contentLink.props.href}
                target={target}
                rel={rel}
                className={classnames(className, CLASSNAME, classNameColor)}
                ref={mergeRefs([ref, contentLink.ref])}
            >
                {children}
            </a>
        );
    },
);
ContentLink.displayName = 'ContentLink';
