/* istanbul ignore file */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

export interface ShareToTeamsState {
    currentDialogPostId?: string;
    userSpacesStatus: null | 'loading' | 'loaded';
    userSpaces: Array<any>;
    filterUserSpaceValue: string;
    showUserSpaceSuggestions: boolean;
    selectedUserSpace?: any;
    channelsStatus: null | 'loading' | 'loaded';
    channels: Array<any>;
    filterChannelValue: string;
    showChannelSuggestions: boolean;
    navigationChannelSuggestionValue: string;
    selectedChannelValues: Array<any>;
    message: string;
    setFilterChannelValue: any;
    postingToChannel: boolean;
}

export const initialState: ShareToTeamsState = {
    currentDialogPostId: undefined,
    userSpacesStatus: null,
    userSpaces: [],
    filterUserSpaceValue: '',
    showUserSpaceSuggestions: false,
    selectedUserSpace: undefined,
    channelsStatus: null,
    channels: [],
    filterChannelValue: '',
    showChannelSuggestions: false,
    navigationChannelSuggestionValue: '',
    selectedChannelValues: [],
    message: '',
    setFilterChannelValue: null,
    postingToChannel: false,
};

export const { actions, reducer } = createSlice({
    domain: 'shareToTeams',
    initialState,
    reducers: {
        toggleDialog: (state: ShareToTeamsState, action: PayloadAction<string>) => {
            set(state, 'currentDialogPostId', action.payload);
        },
        fetchJoinedUserSpacesLoading: (state: ShareToTeamsState) => {
            set(state, 'userSpacesStatus', 'loading');
        },
        fetchJoinedUserSpacesSuccess: (state: ShareToTeamsState, action: PayloadAction<any>) => {
            const { items } = action.payload;
            set(state, 'userSpaces', items);
            set(state, 'userSpacesStatus', 'loaded');
        },
        fetchJoinedUserSpacesError: (state: ShareToTeamsState) => {
            set(state, 'userSpaces', initialState.userSpaces);
            set(state, 'userSpacesStatus', null);
        },
        fetchChannelsLoading: (state: ShareToTeamsState) => {
            set(state, 'channelsStatus', 'loading');
        },
        fetchChannelsSuccess: (state: ShareToTeamsState, action: PayloadAction<any>) => {
            const { items } = action.payload;
            set(state, 'channels', items);
            set(state, 'channelsStatus', 'loaded');
        },
        fetchChannelsError: (state: ShareToTeamsState) => {
            set(state, 'channels', initialState.channels);
            set(state, 'channelsStatus', null);
        },
        setFilterUserSpaceValue: (state: ShareToTeamsState, action: PayloadAction<string>) => {
            set(state, 'filterUserSpaceValue', action.payload);
            set(state, 'selectedUserSpace', initialState.selectedUserSpace);
            set(state, 'selectedChannelValues', initialState.selectedChannelValues);
            set(state, 'channels', initialState.channels);
            set(state, 'channelsStatus', null);
        },
        setShowUserSpaceSuggestions: (state: ShareToTeamsState, action: PayloadAction<boolean>) => {
            set(state, 'showUserSpaceSuggestions', action.payload);
        },
        setSelectedUserSpace: (state: ShareToTeamsState, action: PayloadAction<any>) => {
            set(state, 'selectedUserSpace', action.payload);
        },
        setFilterChannelValue: (state: ShareToTeamsState, action: PayloadAction<string>) => {
            set(state, 'filterChannelValue', action.payload);
        },
        setShowChannelSuggestions: (state: ShareToTeamsState, action: PayloadAction<boolean>) => {
            set(state, 'showChannelSuggestions', action.payload);
        },
        setNavigationChannelSuggestionValue: (state: ShareToTeamsState, action: PayloadAction<string>) => {
            set(state, 'navigationChannelSuggestionValue', action.payload);
        },
        setSelectedChannelValues: (state: ShareToTeamsState, action: PayloadAction<any>) => {
            set(state, 'selectedChannelValues', action.payload);
        },
        setMessage: (state: ShareToTeamsState, action: PayloadAction<string>) => {
            set(state, 'message', action.payload);
        },
        postingToChannel: (state: ShareToTeamsState) => {
            set(state, 'postingToChannel', true);
        },
        resetForm: (state: ShareToTeamsState) => {
            set(state, 'currentDialogPostId', initialState.currentDialogPostId);
            set(state, 'userSpacesStatus', initialState.userSpacesStatus);
            set(state, 'userSpaces', initialState.userSpaces);
            set(state, 'filterUserSpaceValue', initialState.filterUserSpaceValue);
            set(state, 'showUserSpaceSuggestions', initialState.showUserSpaceSuggestions);
            set(state, 'selectedUserSpace', initialState.selectedUserSpace);
            set(state, 'channelsStatus', initialState.channelsStatus);
            set(state, 'channels', initialState.channels);
            set(state, 'filterChannelValue', initialState.filterChannelValue);
            set(state, 'showChannelSuggestions', initialState.showChannelSuggestions);
            set(state, 'navigationChannelSuggestionValue', initialState.navigationChannelSuggestionValue);
            set(state, 'selectedChannelValues', initialState.selectedChannelValues);
            set(state, 'message', initialState.message);
            set(state, 'postingToChannel', initialState.postingToChannel);
        },
    },
});
