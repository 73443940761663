import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiStar, mdiStarOutline } from '@lumapps/lumx/icons';
import {
    Text,
    ColorPalette,
    Link,
    Theme,
    Typography,
    ColorVariant,
    IconButton,
    FlexBox,
    Orientation,
    Emphasis,
    TypographyTitleCustom,
} from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_SITE_LIST } from '../../keys';
import { SiteBlockProps } from '../../types';

import './index.scss';

export const CLASSNAME = 'site-block';

export const SiteBlock: React.FC<SiteBlockProps> = ({
    theme,
    orientation,
    favorite,
    unfavorite,
    orderedInfo,
    title,
    name,
    subtitle,
    slug,
    isFavorite,
    isFavoriteIconVisible,
    isFavoriteDisabled,
}) => {
    const color = theme === Theme.dark ? ColorPalette.light : ColorPalette.dark;
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes('widget-site-list');

    const renderTitle = () => {
        return (
            title &&
            slug && (
                <Link
                    key="title"
                    linkAs={ContentLink}
                    to={{ instance: { slug }, isHomePage: true }}
                    target="_blank"
                    typography={TypographyTitleCustom.title5}
                    color={color}
                    {...get({ element: 'title', action: 'link' })}
                >
                    {title}
                </Link>
            )
        );
    };

    const renderName = () => {
        return (
            name &&
            slug && (
                <Link
                    key="name"
                    linkAs={ContentLink}
                    to={{ instance: { slug }, isHomePage: true }}
                    target="_blank"
                    typography={TypographyTitleCustom.title5}
                    color={color}
                    {...get({ element: 'name', action: 'link' })}
                >
                    {name}
                </Link>
            )
        );
    };

    const renderSubtitle = () => {
        return (
            subtitle && (
                <Text key="subtitle" as="p" typography={Typography.body1} color={color} colorVariant={ColorVariant.L2}>
                    {subtitle}
                </Text>
            )
        );
    };

    const buildOrderedInfo = () => {
        return orderedInfo.map((element) => {
            switch (element) {
                case 'title':
                    return renderTitle();
                case 'name':
                    return renderName();
                case 'subtitle':
                    return renderSubtitle();
                default:
                    return null;
            }
        });
    };

    return (
        <FlexBox fillSpace orientation={orientation} hAlign="center" className={block(`orientation-${orientation}`)}>
            <FlexBox className={element('content')} fillSpace orientation={Orientation.vertical}>
                {buildOrderedInfo()}
            </FlexBox>
            {isFavoriteIconVisible && (
                <IconButton
                    label={translateKey(
                        isFavorite ? WIDGET_SITE_LIST.REMOVE_FROM_MY_SITES : WIDGET_SITE_LIST.ADD_TO_MY_SITES,
                    )}
                    icon={isFavorite ? mdiStar : mdiStarOutline}
                    onClick={isFavorite ? unfavorite : favorite}
                    theme={theme}
                    emphasis={Emphasis.low}
                    style={{
                        alignSelf: 'center',
                    }}
                    isDisabled={isFavoriteDisabled}
                />
            )}
        </FlexBox>
    );
};
