(function IIFE() {
    'use strict';

    /////////////////////////////

    function InstancePickerService($rootScope, $timeout, LxDepthService) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The delay between the actual closing/opening of the picker and the sending of the event.
         *
         * @type {number}
         */
        var _EVENT_DELAY = 600;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close an instance picker.
         *
         * @param {string} pickerId            The id of the instance picker.
         * @param {Array}  [selectedInstances] The list of full instances that have been picked.
         */
        function closePicker(pickerId, selectedInstances) {
            if (angular.isUndefinedOrEmpty(pickerId)) {
                return;
            }

            $rootScope.$broadcast('instance-picker__close-start', pickerId, selectedInstances);

            angular.element('body').css({
                overflow: 'visible',
            });

            $timeout(function delayCloseEvent() {
                $rootScope.$broadcast('instance-picker__close-end');
            }, _EVENT_DELAY);
        }

        /**
         * Open an instance picker.
         *
         * @param {string} pickerId The id of the instance picker.
         */
        function openPicker(pickerId) {
            if (angular.isUndefinedOrEmpty(pickerId)) {
                return;
            }

            LxDepthService.register();
            $rootScope.$broadcast('instance-picker__open-start', pickerId);

            angular.element('body').css({
                overflow: 'hidden',
            });

            $timeout(function delayPickerShow() {
                angular.element('#' + pickerId)
                    .css('z-index', LxDepthService.getDepth());
            });

            $timeout(function delayOpenEvent() {
                $rootScope.$broadcast('instance-picker__open-end', pickerId);
            }, _EVENT_DELAY);
        }

        /////////////////////////////

        service.close = closePicker;
        service.open = openPicker;
    }

    /////////////////////////////

    angular.module('Services').service('InstancePicker', InstancePickerService);
})();
