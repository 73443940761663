export enum DIRECTORY_ENTRY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=290128221
     */
    PICKER_TITLE = 'DIRECTORY_ENTRIES.PICKER_TITLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451719
     */
    CREATION_DIALOG_TITLE = 'ADMIN_DIRECTORY_ENTRY_SETTINGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451716
     */
    ENTRY_NAME = 'ADMIN_DIRECTORY_ENTRY_NAME',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13451712
     */
    IN_FAVORITE_FEEDS = 'ADMIN_DIRECTORY_ENTRY_IN_FAVORITE_FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7141189
     */
    IS_DELETABLE_FROM_FAVORITE = 'ADMIN.INSTANCE.IS_DELETABLE_FROM_FAVORITE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468944959
     */
    DELETE_ENTRY = 'DIRECTORY_ENTRIES.DELETE_ENTRY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468951721
     */
    DELETE_ENTRIES = 'DIRECTORY_ENTRY.DELETE_ENTRIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468952884
     */
    DELETE_ENTRY_DESCRIPTION = 'DIRECTORY_ENTRIES.DELETE_ENTRY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468952987
     */
    DELETE_ENTRIES_DESCRIPTION = 'DIRECTORY_ENTRIES.DELETE_ENTRIES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468966193
     */
    NEW_ENTRY = 'DIRECTORY_ENTRIES.NEW_ENTRY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468966245
     */
    EDIT_ENTRY = 'DIRECTORY_ENTRIES.EDIT_ENTRY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=469100071
     */
    ENTRY_DELETED = 'DIRECTORY_ENTRIES.ENTRY_DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=469101063
     */
    ENTRIES_DELETED = 'DIRECTORY_ENTRIES.ENTRIES_DELETED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=469105599
     */
    NO_ENTRIES_YET = 'DIRECTORY_ENTRIES.NO_ENTRIES_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=470725022
     */
    EMPTY_STATE_DESCRIPTION = 'DIRECTORY_ENTRIES.EMPTY_STATE_DESCRIPTION',
}
