import { WIDGET_SIZE_BREAKPOINTS } from '../../constants';

/**
 * Default block min-width
 */
export const BLOCK_DEFAULT_MIN_WIDTH = 216;

/**
 * Base min-width values for each block types to determine how many to display at one time.
 */
export const BLOCK_ITEM_MIN_WIDTH: { [key: string]: number } = {
    BlockCommunityPreview: BLOCK_DEFAULT_MIN_WIDTH,
    BlockPagePreview: BLOCK_DEFAULT_MIN_WIDTH,
    BlockPost: BLOCK_DEFAULT_MIN_WIDTH,
    BlockDirectoryEntry: 96,
    BlockImagePreview: 96,
};

/**
 * Breakpoints used at block level.
 */
export const BLOCK_BREAKPOINT = {
    xs: 0,
    s: 400,
    m: WIDGET_SIZE_BREAKPOINTS.m,
    l: WIDGET_SIZE_BREAKPOINTS.l,
};
