import { useMemo } from 'react';

import { mdiUndo, mdiRedo } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { PLUGIN_SHORTCUTS } from '@lumapps/wrex/constants';
import { ReactEditor, useSlate, HistoryEditor } from '@lumapps/wrex/slate';
import { ToolbarItem } from '@lumapps/wrex/types';

import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

const ICONS = {
    undo: mdiUndo,
    redo: mdiRedo,
};

const KEYS = {
    undo: WREX_TYPOGRAPHY.UNDO_WITH_SHORTCUT,
    redo: WREX_TYPOGRAPHY.REDO_WITH_SHORTCUT,
};

/** Check if the editor has undo or redo operations in history. */
const hasOperations = (editor: HistoryEditor, type: 'undo' | 'redo') => {
    const operationArray = type === 'redo' ? 'redos' : 'undos';

    return editor.history[operationArray].some(
        // Has operations and operations are not only set_selection operations.
        ({ operations = [] }) =>
            operations.length && operations.some((operation) => operation.type !== 'set_selection'),
    );
};

export const useHistoryButton = (type: 'undo' | 'redo', otherProps?: any) => {
    const editor = useSlate() as ReactEditor & TypographyEditor & HistoryEditor;
    const { translateAndReplace } = useTranslate();

    const isDisabled = !hasOperations(editor, type);

    const historyButton = useMemo(() => {
        const onClick = () => {
            if (type === 'undo') {
                editor.undo();
            } else {
                editor.redo();
            }
        };

        const historyButtonProps: ToolbarItem = {
            type: 'option',
            itemKey: type,
            icon: ICONS[type],
            tooltipLabel: translateAndReplace(KEYS[type], {
                shortcut: PLUGIN_SHORTCUTS[type].shortcut,
            }),
            onClick,
            'aria-disabled': isDisabled,
            verticalModeProps: {
                label: translateAndReplace(KEYS[type], {
                    shortcut: PLUGIN_SHORTCUTS[type].shortcut,
                }),
                tooltipLabel: undefined,
            },
            otherProps,
        };

        return historyButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translateAndReplace, isDisabled, type, otherProps?.isDisabled]);

    return historyButton;
};
