import React from 'react';

import { ThumbnailProps, Thumbnail } from '@lumapps/lumx/react';

import { Image } from '../../types';
import { doesBlobExist, getBlob } from './store';

export interface BlobThumbnailProps extends Omit<ThumbnailProps, 'image'> {
    img?: Image;
    alt: ThumbnailProps['alt'];
}

export const getImageUrl = (image?: Image) => {
    if (image) {
        if (image.id && doesBlobExist(image.id)) {
            return getBlob(image.id);
        }

        return image.url || image.blobUrl || '';
    }

    return '';
};

/**
 * Display an uploaded image, using either blobs or URLs. This is a special component
 * that should only be used when you are displaying an image that has been recently uploaded.
 *
 * It basically uses an internal memory store that has the blobs for a given image, and uses
 * that blob instead of the actual URL to display the image. This is helpful when:
 * - The image is currently being uploaded
 * - The image needs to be added to the blob store by using the `addBlob` function from `./store`
 *
 * @family Images
 * @param BlobThumbnailProps
 * @returns BlobThumbnail
 */
export const BlobThumbnail: React.FC<BlobThumbnailProps> = ({ img, ...props }) => {
    return <Thumbnail {...props} image={getImageUrl(img)} />;
};
