/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import { classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { useResponsive } from '@lumapps/responsive';

import './index.scss';

export type HeaderProps = {
    /** Custom? className */
    className?: string;
    /** children? */
    children?: React.ReactNode;
    /** whether the header includes a navigation or not */
    hasNavigation?: boolean;
    /** whether the content position should be ignored or not */
    shouldIgnoreContentPosition?: boolean;
    /** whether there should be a default bottom margin for the header */
    shouldAddDefaultBottomMargin?: boolean;
    /** whether the component is displayed inside a legacy context or not */
    isLegacyContext?: boolean;
    /** custom styles, normally associated to the current customer */
    style?: any;
    /** Element that the header will be anchored to */
    anchorRef?: React.RefObject<HTMLElement>;
};

const CLASSNAME = 'header';
/**
 * Front office Header that only prints the <header /> tag. It should not be used, it was only meant for the front office page.
 * @family Front office
 * @param HeaderProps
 * @returns Header
 */
export const Header = ({
    className,
    children,
    hasNavigation = false,
    anchorRef,
    shouldIgnoreContentPosition = false,
    shouldAddDefaultBottomMargin = true,
    ...forwardedProps
}: HeaderProps) => {
    const { get } = useDataAttributes('front-office');
    const { isMobile } = useResponsive();
    const dataAttributes = get({
        isMobile,
    });

    const style = forwardedProps?.style;

    /**
     * If content position should be ignored, then we remove the margin bottom from the provided
     * style and let the css classes manage the margin displayed between the header and the page.
     */
    if (style && style.marginBottom && shouldIgnoreContentPosition) {
        delete style.marginBottom;
    }

    return (
        <header
            /**
             * Even though it might seem redundant to add a banner role to the header,
             * it's actually a good practice as it has a larger assistive technology compatibility.
             * This must be the only "banner" on the page though, other "headers" must not have this role.
             */
            role="banner"
            className={classnames(CLASSNAME, `${CLASSNAME}--reworked-ui`, className, {
                [`${CLASSNAME}--has-navigation`]: hasNavigation,
                [`${CLASSNAME}--no-content-header`]: shouldAddDefaultBottomMargin,
            })}
            ref={anchorRef}
            {...dataAttributes}
            {...forwardedProps}
            style={style}
        >
            {children}
        </header>
    );
};
