import { cache, CACHE_TYPE } from '@lumapps/cache';
import { getBaseUrl } from '@lumapps/router/utils';

import { didRequestSucceed, didRequestFail } from './utils';

declare const window: any;

const RETRIES_INIT_CACHE_ID = 'retries-in-cache-id';
const savedRetries = cache.retrieve(RETRIES_INIT_CACHE_ID, CACHE_TYPE.STORAGE);

/**
 * Managing retries outside of the hook in order to avoid having the init service
 * fail and the hook not re rendered in time.
 */
let retries = savedRetries ? parseInt(savedRetries, 10) : 0;

/**
 * Update retries locally and in local storage
 * @param newRetries
 */
const saveRetries = (newRetries: number) => {
    retries = newRetries;

    cache.store(RETRIES_INIT_CACHE_ID, retries, CACHE_TYPE.STORAGE);
};

/**
 * Log the given string as an error in the cosnole for tracking purposes
 * and redirect the user to the home site, which is the URL with just
 * the customer slug, and no instance slug. We do that so that the backend
 * can determine whether they can send the user their home page or redirect
 * the user to the login.
 *
 * @param error
 */
const logErrorAndReturnToHome = (error: string) => {
    const baseUrl = getBaseUrl(window.location.pathname, false);

    // eslint-disable-next-line no-console
    console.error(error, { initUrl: window.INIT_URL });

    saveRetries(0);

    /**
     * We add a timeout in order to let the local storage update kick in before reloading
     */
    setTimeout(() => {
        window.location.href = baseUrl;
    }, 500);
};

/** callback to be executed once the initial state request has finished */
export const onInitialStateLoaded = (setInitialState: (state: any) => void, ignore404 = false) => {
    /**
     * If the request succeeds, we set the initial state with the response, which
     * will trigger the rendering of the application.
     */
    if (didRequestSucceed(window.initRequest)) {
        const { responseText: responseTextInit } = window.initRequest;

        setInitialState(JSON.parse(responseTextInit));

        saveRetries(0);

        return;
    }

    /**
     * If it fails with an Unauthorized request, we refresh the page and let the backend
     * return a new token in the HTML.
     *
     * If that fails, we send the user to their home page.
     */
    if (didRequestFail(window.initRequest, 401)) {
        if (retries === 0) {
            retries += 1;

            saveRetries(retries);

            /**
             * We add a timeout in order to let the local storage update kick in before reloading
             */
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            logErrorAndReturnToHome(
                `Failed to initialise application, init service returns a 401. Retried ${retries} times, redirecting user to their home page`,
            );
        }

        return;
    }

    /**
     * If the init request gives a 500, we retry the request one time.
     * If it fails again, we send the user to their home page
     */
    if (didRequestFail(window.initRequest, 500)) {
        if (retries === 0) {
            retries += 1;
            window.initRequest.open('GET', window.INIT_URL);
            window.initRequest.send();
        } else {
            logErrorAndReturnToHome(
                `Failed to initialise application, init service returns a 500. Retried ${retries} times, redirecting user to their home page`,
            );
        }

        return;
    }

    /**
     * If finally, the request fails with a 404, we send the user to their home page
     * This 404 means that there is either something very wrong with our backend (like the customer is no longer in the DB)
     * or the instance or customer slugs are incorrect. In both scenarios, there is not much that we can do, so we
     * send the user to the login page.
     */
    if (didRequestFail(window.initRequest, 404) && !ignore404) {
        logErrorAndReturnToHome('Failed to initialise application, initial request returned a 404 error');
        return;
    }

    /**
     * If we are ignoring 404s, it means that we are using the init on an environment that we should not be doing
     * This is unfortunately the case for the legacy application. In this scenario we let the app handle itself
     * as its sees fit.
     */
    if (didRequestFail(window.initRequest, 404) && ignore404) {
        setInitialState({});

        saveRetries(0);
    }
};
