import React, { useEffect, useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { useFetchPlaylist } from '@lumapps/play-playlists';
import { Playlist } from '@lumapps/play-playlists/components/Playlist';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';
import { actions } from '@lumapps/widget-base/ducks/slice';
import { ContainerBlockVariant } from '@lumapps/widget-base/types';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { MAX_VIDEOS_PER_PLAYLIST } from '../../constants';
import type { WidgetPlaylistProperties } from '../../types';

import './index.scss';

export interface ClientComputedPlaylistProps extends ClientComputedProps {
    properties?: WidgetPlaylistProperties;
}

const CLASSNAME = 'client-computed-playlist';
const fetchKey = CLASSNAME;

export const ClientComputedPlaylist = ({ properties, uuid }: ClientComputedPlaylistProps) => {
    const { block } = useClassnames(CLASSNAME);
    const dispatch = useDispatch();
    const currentContent = useSelector(contentSelector);
    const theme = properties?.style?.content?.theme;
    const playlistId = properties?.playlist.id;

    /** Fetch global playlist details */
    const { fetchPlaylist, playlist, status } = useFetchPlaylist({ fetchKey: `${fetchKey}-${playlistId}` });

    useEffect(() => {
        if (playlistId) {
            fetchPlaylist({
                fields: ['title', 'thumbnail', 'updatedAt', 'videosCount'],
                id: playlistId,
            });
        }
    }, [fetchPlaylist, playlistId]);

    const videoCount = playlist?.videosCount;

    const isEmpty = useMemo(
        () => !playlistId || status === BaseLoadingStatus.error || (status === BaseLoadingStatus.idle && !videoCount),
        [playlistId, status, videoCount],
    );

    useEffect(() => {
        if (isEmpty) {
            dispatch(actions.setWidgetProperties({ widgetId: uuid, widgetProperties: { state: 'empty' } }));
        }
    }, [dispatch, uuid, isEmpty]);

    const videoVisibleElements = React.useMemo(() => {
        return properties?.videoItemFieldOrder?.map((element) => {
            switch (element) {
                case 'thumbnail': {
                    return 'FIGURE';
                }

                default: {
                    return element.toUpperCase() as Uppercase<typeof element>;
                }
            }
        });
    }, [properties?.videoItemFieldOrder]);

    /** When there are no videos in the playlist, we want to hide the widget in read mode,
     * whereas we show a widget without videos in designer mode
     */
    if (isEmpty) {
        if (!currentContent.isDesignerMode) {
            return null;
        }
    }

    const variant =
        properties?.viewModeVariant === 'ungrouped' ? ContainerBlockVariant.ungrouped : ContainerBlockVariant.grouped;

    /** We need to handle the content's box shadow ourselves as the elevation/opacity are not computed
     * with this implementation
     */
    const contentShadow = properties?.style?.content?.shadowElevation
        ? {
              boxShadow: `0 ${2 * Number(properties.style.content.shadowElevation)}px ${
                  4 * Number(properties?.style?.content?.shadowElevation)
              }px rgba(0, 0, 0, ${Number(properties.style?.content?.shadowOpacity) / 100})`,
          }
        : {};

    return properties ? (
        <Playlist
            className={block()}
            headerProps={{
                visibleElements: properties.playlistFieldOrder,
            }}
            maxVideoCount={properties?.maxNumber || MAX_VIDEOS_PER_PLAYLIST}
            playlistDetails={{
                fetchStatus: status,
                id: properties.playlist.id,
                playlist,
            }}
            theme={theme}
            videoVisibleElements={videoVisibleElements}
            videosBlockProps={{
                variant,
                isSlideshow: properties.slideshowEnabled,
                itemsPerLine: properties?.itemsPerLine,
                ...properties?.style?.content,
                contentShadow,
            }}
        />
    ) : null;
};
