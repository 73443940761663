import React from 'react';

import { FilterHookApi, FilterHookOptions } from '../types';

export type UseObjectOptions<T> = Omit<FilterHookOptions<T | null>, 'getId'>;

export type UseObjectFilter<T> = FilterHookApi<T | null>;

export const useObjectFilter = <T>(options?: UseObjectOptions<T>): UseObjectFilter<T> => {
    const [selected, setSelected] = React.useState<T | null>((options && options.defaultSelected) || null);
    const [selection, setSelection] = React.useState<T | null>(selected);

    const { defaultSelected, hasDynamicDefaultSelected } = options || {};

    /**
     * If the default values change, which could happen if the hook is displayed in a different
     * context, we need to reset the selected values on the internal state.
     */
    React.useEffect(() => {
        if (defaultSelected && hasDynamicDefaultSelected) {
            setSelected(defaultSelected);
        }
    }, [defaultSelected, hasDynamicDefaultSelected]);

    const onSelected = (sel: T | null) => {
        setSelection(
            sel !== null
                ? {
                      ...(selection || {}),
                      ...sel,
                  }
                : null,
        );
    };

    const onFilter = () => {
        setSelected(selection);

        return selection;
    };

    const onClearSelection = () => {
        setSelection(selected);
    };

    const onClearSelected = () => {
        setSelected(null);
        setSelection(null);
    };

    const onResetFilters = () => {
        if (options?.defaultSelected) {
            setSelected(options?.defaultSelected);
            setSelection(options?.defaultSelected);
        } else {
            onClearSelected();
        }
    };

    return {
        onSelected,
        onFilter,
        selected,
        selection,
        onClearSelection,
        onClearSelected,
        onResetFilters,
    };
};
