import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { Headline } from '@lumapps/wrex-typography/components/blocks/Headline';
import { Subtitle } from '@lumapps/wrex-typography/components/blocks/Subtitle';
import { Title as TitleBlock } from '@lumapps/wrex-typography/components/blocks/Title';
import { HEADLINE, TITLE, SUBTITLE } from '@lumapps/wrex-typography/constants';

import { isElementType } from '../../../slate/utils/isElementType';
import type { Wrex } from '../../../types';
import { Alignment } from '../../../types/core';
import { Body, Element, Title, Topic } from '../types';
import { convertNode } from './convertNode';

const UID_PREFIX = 'art-';

export const generateBody = (): Body => ({
    type: 'element',
    name: 'body',
    children: [],
});

export const generateTitle = (alignment?: Alignment): Title => {
    return {
        type: 'element',
        name: 'title',
        ...(alignment
            ? {
                  attributes: {
                      outputclass: `align-${alignment}`,
                  },
              }
            : undefined),
        children: [],
    };
};

export const generateTopic = (): Topic => {
    return {
        type: 'element',
        name: 'topic',
        attributes: {
            id: UID_PREFIX + generateUUID(),
        },
        children: [],
    };
};

export const generateHeader = (alignment?: Alignment) => {
    const title = generateTitle(alignment);
    const topic = generateTopic();
    const body = generateBody();
    topic.children.push(title, body);
    return { topic, title, body };
};

export const generateHeader2 = (alignment?: Alignment) => {
    const { topic: topicLvl1 } = generateHeader();

    const { topic: topicLvl2, title: titleLvl2, body: bodyLvl2 } = generateHeader(alignment);
    topicLvl1.children.push(topicLvl2);
    return { topicLvl1, titleLvl2, bodyLvl2, topicLvl2 };
};

export const generateHeader3 = (alignment?: Alignment) => {
    const { topicLvl1, topicLvl2 } = generateHeader2();

    const { topic: topicLvl3, title: titleLvl3, body: bodyLvl3 } = generateHeader(alignment);
    topicLvl2.children.push(topicLvl3);
    return { topicLvl1, titleLvl3, bodyLvl3, topicLvl2 };
};

const isHeadline = isElementType(Headline);
const isTitle = isElementType(TitleBlock);
const isSubtitle = isElementType(Subtitle);

export const fromSlate = (nodes: Wrex.Nodes) => {
    const rootBody = generateBody();
    const parentRoot: { body?: Element; node?: Wrex.Node; topic?: Element } = {};
    let lastTitle: {
        topic: Topic;
        level: typeof HEADLINE | typeof TITLE | typeof SUBTITLE;
        parentTopic: Topic | null;
    } | null = null;

    const appendTopic = ({
        level,
        parentTopic,
        topic,
    }: {
        level: typeof HEADLINE | typeof TITLE | typeof SUBTITLE;
        parentTopic: Topic;
        topic: Topic;
    }) => {
        parentTopic.children.push(topic);
        lastTitle = { topic, level, parentTopic };
    };

    const ditaNodes = nodes
        .map((node) => {
            if (isHeadline(node)) {
                const { topic, title, body } = generateHeader(node.alignment);
                parentRoot.body = body;
                parentRoot.topic = topic;
                parentRoot.node = node;
                convertNode(node, title);
                lastTitle = { topic, level: HEADLINE, parentTopic: null };
                return topic;
            }

            if (isTitle(node)) {
                // If the lastTitle is a TITLE or a HEADLINE, continue in hierarchie
                if (lastTitle && [HEADLINE, TITLE].includes(lastTitle.level)) {
                    const { topic, title, body } = generateHeader(node.alignment);

                    if (lastTitle.level === HEADLINE && lastTitle.topic?.children) {
                        // If the lastTitle was a HEADLINE, insert in the HEADLINE's children
                        appendTopic({ level: TITLE, parentTopic: lastTitle.topic, topic });
                    } else if (lastTitle.level === TITLE && lastTitle.parentTopic?.children) {
                        // If the lastTitle was already a TITLE, insert after it in the parent HEADLINE's children
                        appendTopic({ level: TITLE, parentTopic: lastTitle.parentTopic, topic });
                    }

                    parentRoot.body = body;
                    convertNode(node, title);
                    return null;
                }
                // If the lastTitle is a SUBTITLE, restart from level 0
                // Because TITLE can't be the direct child of a SUBTITLE
                const { topicLvl1, titleLvl2, bodyLvl2, topicLvl2 } = generateHeader2(node.alignment);
                convertNode(node, titleLvl2);
                lastTitle = { topic: topicLvl2, level: TITLE, parentTopic: topicLvl1 };
                parentRoot.body = bodyLvl2;
                parentRoot.topic = topicLvl1;
                parentRoot.node = node;
                return topicLvl1;
            }

            if (isSubtitle(node)) {
                // If the lastTitle is a TITLE or a SUBTITLE, continue in hierachie
                if (lastTitle && [TITLE, SUBTITLE].includes(lastTitle.level)) {
                    const { topic, title, body } = generateHeader(node.alignment);

                    if (lastTitle.level === TITLE && lastTitle.topic?.children) {
                        // If the lastTitle was a TITLE, insert in the TITLE's children
                        appendTopic({ level: SUBTITLE, parentTopic: lastTitle.topic, topic });
                    } else if (lastTitle.level === SUBTITLE && lastTitle.parentTopic?.children) {
                        // If the lastTitle was already a SUBTITLE, insert after it in the parent TITLE's children
                        appendTopic({ level: SUBTITLE, parentTopic: lastTitle.parentTopic, topic });
                    }

                    parentRoot.body = body;
                    convertNode(node, title);
                    return null;
                }
                // If the lastTitle is a HEADLINE, restart from level 0
                // Because SUBTITLE can be the direct child of a HEADLINE
                const { topicLvl1, titleLvl3, bodyLvl3, topicLvl2 } = generateHeader3(node.alignment);
                convertNode(node, titleLvl3);
                lastTitle = { topic: topicLvl1, level: SUBTITLE, parentTopic: topicLvl2 };
                parentRoot.body = bodyLvl3;
                parentRoot.node = node;
                return topicLvl1;
            }

            if (!parentRoot.body) {
                convertNode(node, rootBody);
                parentRoot.body = rootBody;
                parentRoot.node = node;
                return null;
            }

            convertNode(node, parentRoot.body);
            return null;
        })
        .filter(Boolean) as Array<Element>;

    return [rootBody, ...ditaNodes];
};
