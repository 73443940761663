import { PostType } from '@lumapps/posts/types';

export const getPostType = (availablePostTypes: PostType[], postType: PostType = PostType.DEFAULT) => {
    // undefined or incorrect post type requested does not exist in community.
    if (!availablePostTypes.includes(postType)) {
        // Use the first available post type.
        return availablePostTypes[0];
    }
    return postType;
};
