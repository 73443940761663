import { Image } from '../../types';

export const MAX_BLOBS_TO_STORE = 7;

/**
 * This is an internal store in order to locally save blobs
 * for recently uploaded images. This is just a simple object, not connected to
 * redux nor a context since it will be used on the legacy side as well.
 *
 * Unfortunately, when the legacy application creates its bundles, it separates
 * the Angular JS files in one bundle and the React files in another. Since this file
 * is used on both sides, this Singleton gets created TWICE (yes seriously), since the
 * file is two times in our bundles, once on the legacy side and once on the migrated code.
 *
 * Therefore, stores have different values, depending where they are executed. In order to have
 * only just one store, we use a global variable that will allow us to really have only one store.
 *
 * Fortunately, this came in helpful since we needed to have a way to debug and evaluate the blobs
 * that were added to the store, so this is the perfect excuse to expose the store.
 */
if (window.lumapps) {
    window.lumapps.blobStore = {};
}

/** Retrieve the blob store */
export const getBlobStore = () => {
    return window.lumapps.blobStore || {};
};

/** Reset the blob store */
export const resetBlobStore = () => {
    window.lumapps.blobStore = {};
};

/**
 * Adds an image as a blob in order to use it in the future in order to
 * retrieve a given image by its id. This should be executed once you have
 * uploaded an image to the server and you want to show that image right away.
 * @param Image
 */
export const addBlob = ({ id, blobUrl }: Image, checkMaxSize = true) => {
    const blobs = getBlobStore();

    /**
     * We only save a maximum of images, if we surpass that, we reset the store and start over
     */
    if (Object.keys(blobs).length >= MAX_BLOBS_TO_STORE && checkMaxSize) {
        resetBlobStore();
    }

    if (id && blobUrl && window.lumapps.blobStore) {
        window.lumapps.blobStore[id] = blobUrl;
    }
};

export const doesBlobExist = (id: string) => {
    const blobs = getBlobStore();

    return Boolean(blobs[id]);
};

/** Retrieve a specific blob */
export const getBlob = (id: string) => {
    const blobs = getBlobStore();

    return blobs[id];
};
