import { EDIT_COMMUNITY_DIALOG_EVENT } from '@lumapps/community-configuration/constants';
import { ColorPalette } from '@lumapps/lumx/react';
import { GLOBAL } from '@lumapps/translations';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta, NotificationLinkUrlParams } from '../../types';
import { getContentTitle, getContextTitle, getContextDescription } from '../contentUtils';
import { getCommunityLink, getEmptyRoute } from '../linksUtils';

const notificationTypeCommunity: Record<string, NotificationMeta> = {
    [NotificationTypes.CommunityExtendedServiceManagement]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.green,
            icon: 'check',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(
                NOTIFICATIONS_CENTER.COMMUNITY_EXTENDED_SERVICE_MANAGEMENT_DESCRIPTION,
                {
                    COMMUNITY_TITLE: getContextTitle(translation, notification),
                    COMMUNITY_DESC: getContextDescription(translation, notification),
                },
            );
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommunityLink,
    },

    [NotificationTypes.CommunityNewAccessRequest]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.yellow,
            icon: 'account-question',
        },
        getDescription: ({ notification, count, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.COMMUNITY_NEW_ACCESS_REQUEST_DESCRIPTION, {
                NB_REQUESTS: count,
                ACCESS_REQUESTS: translation.translateKey(count === 1 ? GLOBAL.ACCESS_REQUEST : GLOBAL.ACCESS_REQUESTS),
                COMMUNITY_TITLE: getContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: (params: NotificationLinkUrlParams) =>
            getCommunityLink({
                ...params,
                anchor: EDIT_COMMUNITY_DIALOG_EVENT,
            }),
    },

    [NotificationTypes.CommunityNewCredential]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.green,
            icon: 'account-check',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.COMMUNITY_NEW_CREDENTIAL_DESCRIPTION, {
                COMMUNITY_TITLE: getContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommunityLink,
    },
};

export { notificationTypeCommunity };
