import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';

import { userListSettingsDirectoryListSelector, userListSettingsStateSelector } from '../../ducks/selectors';
import { fetchUserDirectoriesForUserListSettings } from '../../ducks/thunks';
import { UserDirectorySearchField } from './UserDirectorySearchField';

const mapStateToProps = (state: any) => {
    const userDirectories = userListSettingsDirectoryListSelector(state);
    const { cursor, hasMore, isLoading } = userListSettingsStateSelector(state);
    const lang = currentLanguageSelector(state);

    return {
        isLoading,
        userDirectories,
        cursor,
        hasMore,
        lang,
    };
};

const mapDispatchToProps = {
    fetchUserDirectories: fetchUserDirectoriesForUserListSettings,
};

const ConnectedUserDirectorySearchField = connect(mapStateToProps, mapDispatchToProps)(UserDirectorySearchField);

export { ConnectedUserDirectorySearchField as UserDirectorySearchField };
