import { DEFAULT_AVATAR } from '../constants';
import { User } from '../types';

/**
 * Get the avatar of a user.
 *
 * Note: this is the new and improved way of getting a user profile picture since the backend handles it all.
 *
 * @param  {string} userId The identifier of the user to get the avatar of.
 * @param  {string} customerId The identifier of the user to get the avatar of.
 * @return {string} The url to the profile picture of a given user or the default avatar if one of the param is null
 */
export function getAvatarUrl(userId?: string, customerId?: string) {
    return userId && customerId ? `/serve/profilepicture/${customerId}/${userId}` : DEFAULT_AVATAR;
}

export const getUserProfilePictureUrl = (user: Pick<User, 'id' | 'customer'>): string => {
    return getAvatarUrl(user.id, user.customer);
};
