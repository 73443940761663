import { useCallback, useState } from 'react';

import { cache, CACHE_TYPE } from '@lumapps/cache';

const CACHE_KEY = 'contextualActions.isCollapsed';

export type UseCollapseContextualActions = [boolean, () => void];

export const useCollapseContextualActions = (): UseCollapseContextualActions => {
    const { retrieve, store } = cache;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(retrieve(CACHE_KEY, CACHE_TYPE.STORAGE) ?? false);
    const toggleCollapse = useCallback(() => {
        const newState = !isCollapsed;
        store(CACHE_KEY, newState, CACHE_TYPE.STORAGE);
        setIsCollapsed(newState);
    }, [isCollapsed, store]);

    return [isCollapsed, toggleCollapse];
};
