import loFind from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import reject from 'lodash/reject';
import union from 'lodash/union';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function CommunitySearchService(
    $location,
    $state,
    BaseService,
    Community,
    CommunitySearchFactory,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Contains the available search method according to the type of list.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _AVAILABLE_METHODS = {
        communityList: CommunitySearchFactory.searchCommunitiesForSharing,
    };

    /**
     * An instance of the base service to be able to compute projections.
     *
     * @type {BaseService}
     * @constant
     * @readonly
     */
    const _BASE_SERVICE = BaseService.createService(CommunitySearchFactory);

    /**
     * Contains the default filters.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _DEFAULT_FILTER = {
        callId: '',
        cursor: undefined,
        maxResults: 5,
        more: false,
    };

    /**
     * Contains the filters of the lists.
     *
     * @type {Object}
     */
    const _filters = {
        default: angular.copy(_DEFAULT_FILTER),
    };

    /**
     * Indicates if a list of result is loading.
     *
     * @type {boolean}
     */
    const _isLoading = {
        default: false,
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The query input in the main search input (search engine).
     *
     * @type {string}
     */
    service.query = undefined;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the correct key for the list.
     *
     * @param  {string} listKey The list key.
     * @return {string} The list key.
     */
    function _getListKey(listKey) {
        return angular.isDefinedAndFilled(listKey) ? listKey : 'default';
    }

    /**
     * Call the API to list items according to the local filter, handle the pagination and update the lists.
     *
     * Use a `callId` in case of multiple request during a short time.
     *
     * @param  {string}              listKey      The list key.
     * @param  {string}              method       The method to use in the API.
     * @param  {Function}            [cb]         The function to call when the search is successfull.
     * @param  {Function}            [errCb]      The function to call when there was an error during the search.
     * @param  {string|Object|Array} [projection] The field we want to get in the result object.
     * @return {Promise}             The search promise.
     */
    function _list(listKey, method, cb, errCb, projection) {
        if (
            angular.isUndefinedOrEmpty([listKey, method, _AVAILABLE_METHODS[method]], 'some') ||
            !angular.isFunction(_AVAILABLE_METHODS[method])
        ) {
            return undefined;
        }

        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        _isLoading[listKey] = true;

        if (angular.isDefinedAndFilled(projection)) {
            _BASE_SERVICE._computeProjection(_filters[listKey], projection);
        }
        _filters[listKey].callId = generateUUID();

        return _AVAILABLE_METHODS[method](
            _filters[listKey],
            function onSearchSuccess(response) {
                if (response.callId === _filters[listKey].callId) {
                    _filters[listKey].more = response.more;
                    _filters[listKey].cursor = response.cursor;
                    cb(response);
                }
                _isLoading[listKey] = false;
            },
            function onSearchError(err) {
                errCb(err);
                _isLoading[listKey] = false;
            },
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Launch the search.
     *
     * @param  {string}              listKey      The list key.
     * @param  {string}              method       The method to use in the API.
     * @param  {Object}              params       The parameters of the search.
     * @param  {Function}            [cb]         The function to call when the search is successfull.
     * @param  {Function}            [errCb]      The function to call when there was an error during the search.
     * @param  {string|Object|Array} [projection] The field we want to get in the result object.
     *
     * @return {Promise}             The search promise.
     */
    function search(listKey, method, params, cb, errCb, projection) {
        params = params || {};

        listKey = _getListKey(listKey);

        _filters[listKey] = angular.fastCopy(_DEFAULT_FILTER);
        angular.extend(_filters[listKey], params);

        return _list(listKey, method, cb, errCb, projection);
    }

    /**
     * Check if there a more results available in a list.
     *
     * @param  {string}  listKey The list key.
     * @return {boolean} If there are more results available in the given list.
     */
    function hasMore(listKey) {
        return get(_filters[_getListKey(listKey)], 'more', false);
    }

    /**
     * Get the next page from the API according to the local filter attributes if there are more results available.
     *
     * @param {string}   listKey The list key.
     * @param {string}   method  The method to use in the API.
     * @param {Function} [cb]    The function to call when the search is successfull.
     * @param {Function} [errCb] The function to call when there was an error during the search.
     */
    function nextPage(listKey, method, cb, errCb) {
        listKey = _getListKey(listKey);

        if (_filters[listKey].more && !_isLoading[listKey]) {
            _list(listKey, method, cb, errCb);
        }
        else{
            errCb();
        }
    }


    /////////////////////////////

    service.search = search;
    service.hasMore = hasMore;
    service.nextPage = nextPage;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        // Set query according to the query state parameters.
        if (angular.isDefinedAndFilled($location.search().q)) {
            service.query = $location.search().q;
        } else if (angular.isDefinedAndFilled($state.params.query)) {
            service.query = $state.params.query;
        }
    }

    init();

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('CommunitySearch', CommunitySearchService);

/////////////////////////////

export { CommunitySearchService };
