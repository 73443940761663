(function IIFE() {
    'use strict';

    /////////////////////////////

    function InterestBlockController(Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////

        /**
        * Services and utilities.
        */
        vm.Utils = Utils;
    }

    /////////////////////////////

    /**
     * A directive that handles the display of an interest.
     *
     * @param {Object} interest The interest object to be displayed.
     */

    function InterestBlockDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: InterestBlockController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                interest: '<lsInterest',
            },
            templateUrl: '/client/front-office/modules/social/views/interest-block.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsInterestBlock', InterestBlockDirective);
})();
