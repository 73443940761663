(function IIFE() {
    'use strict';

    /////////////////////////////

    function DirectoryEntryFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/directory/entry/:methodKey', {}, {
            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            'deleteMulti': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'deleteMulti',
                },
            },

            'get': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            'list': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('DirectoryEntryFactory', DirectoryEntryFactory);
})();
