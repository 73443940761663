import { Editor, NodeEntry } from '@lumapps/wrex/slate';

import { normalizeList } from './normalizeList';
import { normalizeListItem } from './normalizeListItem';

export * from './types';

export function normalizeListNodes(editor: Editor, normalizeNode: Editor['normalizeNode'], entry: NodeEntry) {
    if (normalizeList(editor, entry)) {
        return;
    }
    if (normalizeListItem(editor, entry)) {
        return;
    }
    normalizeNode(entry);
}
