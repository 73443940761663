import { Dispatch } from '@lumapps/redux';

import { actions } from './slice';
import type { ExtendedNotificationAPI, NotificationAPIv2, NotificationInStore, NotificationsAPI } from './types';

/** Create notification show thunk for given type */
function createShowThunk<T extends NotificationAPIv2['type']>(type: T) {
    return (options: T extends 'extended' ? ExtendedNotificationAPI : NotificationsAPI) => (dispatch: Dispatch) => {
        if ((options as any).isOpen === false) {
            // No need to open
            return Promise.resolve();
        }

        return new Promise<void>((resolve) => {
            const notification = {
                ...options,
                type,
                onNotificationHidden() {
                    options.onNotificationHidden?.();
                    resolve();
                },
            } as NotificationAPIv2;
            dispatch(actions.add(notification));
        });
    };
}

/** Show info notification */
export const info = createShowThunk('info');

/** Show success notification */
export const success = createShowThunk('success');

/** Show warning notification */
export const warning = createShowThunk('warning');

/** Show error notification */
export const error = createShowThunk('error');

/** Show extended notification */
export const extended = createShowThunk('extended');

/** Update extended notification */
export const updateExtended =
    (options: { id: string; notification: Partial<NotificationInStore & { type: 'extended' }> }) =>
    (dispatch: Dispatch) => {
        dispatch(actions.updateExtended(options));
    };

/** Remove notification */
export const remove = (id: string) => (dispatch: Dispatch) => {
    dispatch(actions.remove({ id }));
};
