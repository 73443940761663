import React from 'react';

import { SPACES } from '@lumapps/communities/keys';
import { Tooltip } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { useHeaderLegacyPreview } from '../../hooks/useHeaderLegacyPreview';
import { SpaceHeader } from './index';
import { SpaceHeaderSkeleton } from './SpaceHeaderSkeleton';

interface HeaderLegacyPreviewProps {
    spaceId: string;
    spaceSlug: string;
}

const CLASSNAME = 'space-header-legacy-preview';
export const SpaceHeaderLegacyPreview: React.FC<HeaderLegacyPreviewProps> = ({ spaceId, spaceSlug }) => {
    const { translateKey } = useTranslate();

    const { hasInit, isLoading, data, hasError } = useHeaderLegacyPreview({ spaceId, spaceSlug });

    if (!hasInit || isLoading) {
        return <SpaceHeaderSkeleton />;
    }

    if (hasError || !data) {
        return null;
    }

    return (
        <Tooltip label={translateKey(SPACES.HEADER_NOT_EDITABLE)}>
            <div className={CLASSNAME}>
                <SpaceHeader isPreview spaceId={spaceId} {...data} />
            </div>
        </Tooltip>
    );
};
