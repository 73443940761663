/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable id-blacklist */
import React, { useEffect } from 'react';

import { Progress } from '@lumapps/lumx/react';

import get from 'lodash/get';

import { any, arrayOf, func, string, bool, objectOf } from 'prop-types';

import { getConnected, isConnected, isExternal, isMicrosoft } from 'components/services/user';

import WidgetDelveUser from './delveUser/DelveUser';

import WidgetDelveContent from './delveContent/DelveContent';

const PEOPLE_MAX_RESULTS = 200;

/**
 * Display the widget delve component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The delve react element.
 */

// eslint-disable-next-line lumapps/max-params
const WidgetDelve = ({
    currentlyOpenedPopover,
    getCommunities,
    getPeople,
    getSharedDocuments,
    getTrendingDocuments,
    getUsedDocuments,
    documents,
    initState,
    isLoading,
    people,
    setCurrentlyOpenedPopover,
    value,
    uuid,
}) => {
    if (!isConnected() || isExternal() || !isMicrosoft()) {
        return null;
    }

    const { contentType, isTargetedUserDisplayed, user, maxResults, orderBy } = value;
    const theme = get(value, ['style', 'content', 'theme'], 'light');

    /* UseEffect for init and cleaning the widgetDelve component properties in the redux store. */
    useEffect(() => {
        initState({ uuid });
    }, []);

    /* UseEffect for retreiving the content (documents or people). */
    useEffect(() => {
        if (!contentType) {
            return;
        }
        switch (contentType) {
            case 'INSIGHT_PEOPLE': {
                getPeople(PEOPLE_MAX_RESULTS, maxResults, uuid);

                break;
            }

            case 'INSIGHT_COMMUNITIES': {
                getCommunities(PEOPLE_MAX_RESULTS, maxResults, uuid);

                break;
            }

            case 'INSIGHT_DOCUMENTS': {
                switch (orderBy) {
                    case 'MOST_SHARED': {
                        getSharedDocuments(maxResults, uuid);

                        break;
                    }

                    case 'MOST_USED': {
                        getUsedDocuments(maxResults, uuid);

                        break;
                    }

                    case 'MOST_TRENDING': {
                        getTrendingDocuments(maxResults, uuid);

                        break;
                    }

                    default:
                }

                break;
            }

            default:
        }
    }, [contentType, uuid, maxResults, orderBy, user]);

    /**
     * Function that build fields array of the user.
     *
     * @param  {Task}  targetedUser The file item.
     * @return {Array} The fields array.
     */
    const userFields = (targetedUser) => {
        const fieldsArray = [];

        if (targetedUser && targetedUser.apiProfile) {
            if (targetedUser.apiProfile.jobTitle) {
                fieldsArray.push(targetedUser.apiProfile.jobTitle);
            }
            if (targetedUser.apiProfile.city) {
                fieldsArray.push(targetedUser.apiProfile.city);
            }
        }

        return fieldsArray;
    };

    return (
        <div className="widget-delve">
            {user !== undefined && (
                <>
                    {isTargetedUserDisplayed && (
                        <WidgetDelveUser
                            currentlyOpenedPopover={currentlyOpenedPopover}
                            fields={userFields(user ? user : getConnected())}
                            setCurrentlyOpenedPopover={setCurrentlyOpenedPopover}
                            theme={theme}
                            user={user ? user : getConnected()}
                            uuid={uuid}
                        />
                    )}
                    {isLoading && (
                        <div className="loader-container">
                            <Progress theme={theme} />
                        </div>
                    )}
                    {!isLoading && (people.length > 0 || documents.length > 0) && (
                        <WidgetDelveContent
                            contentType={people.length > 0 ? 'people' : 'document'}
                            currentlyOpenedPopover={currentlyOpenedPopover}
                            items={people.length > 0 ? people : documents}
                            setCurrentlyOpenedPopover={setCurrentlyOpenedPopover}
                            theme={theme}
                            uuid={uuid}
                        />
                    )}
                </>
            )}
        </div>
    );
};

WidgetDelve.propTypes = {
    /* The id of the current opened popover of the user block. */
    currentlyOpenedPopover: string,
    /* The retrieved documents. */
    documents: arrayOf(any),
    /* Redux action for retrieving the communities. */
    getCommunities: func.isRequired,
    /* Redux action for retrieving the people. */
    getPeople: func.isRequired,
    /* Redux action for retrieving the most shared documents. */
    getSharedDocuments: func.isRequired,
    /* Redux action for retrieving the most trending documents. */
    getTrendingDocuments: func.isRequired,
    /* Redux action for retrieving the most used documents. */
    getUsedDocuments: func.isRequired,
    /* A function to initialize the component's properties in the redux store. */
    initState: func.isRequired,
    /* Define if we are currently retrieving datas. */
    isLoading: bool,
    /* The retrieved people. */
    people: arrayOf(any),
    /* A function for setting the value of the "currentlyOpenedPopover" prop. */
    setCurrentlyOpenedPopover: func.isRequired,
    /* The current widget uuid. */
    uuid: string.isRequired,
    /* The properties of the widget passed by the angular directive. */
    value: objectOf(any).isRequired,
};

WidgetDelve.defaultProps = {
    currentlyOpenedPopover: '',
    documents: [],
    isLoading: false,
    people: [],
};

WidgetDelve.isEditable = () => false;

/**
 * Defines whether the widget is empty or not.
 *
 * @param  {string}  value The recipent email address.
 * @return {boolean} Whether the recipent email address is empty or not.
 */

WidgetDelve.isWidgetEmpty = (value) => {
    return value === undefined || value.length === 0;
};

/**
 * Determines whether the widget is hidden or not.
 *
 * @param  {Object}  params The widget props.
 * @return {boolean} Whether the widget is hidden or not.
 */
WidgetDelve.isWidgetHidden = (params) => {
    return !isConnected() || isExternal() || !isMicrosoft() || WidgetDelve.isWidgetEmpty(params);
};

export default WidgetDelve;
