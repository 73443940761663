/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { classnames, margin, paddings, sizing, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiSettings } from '@lumapps/lumx/icons';
import {
    Emphasis,
    IconButton,
    Toolbar,
    ChipGroup,
    Chip,
    Size,
    FlexBox,
    Alignment,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    SkeletonCircle,
    Heading,
    Typography,
} from '@lumapps/lumx/react';
import { Link } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';
import { getUserSettingsRoute, USER_SETTINGS_VIEWS } from '@lumapps/user-settings/routes';

import { DATA_NAMESPACE } from '../../constants';
import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationStatus } from '../../types';
import { DeleteAllNotificationConfirmModal } from '../DeleteAllNotificationConfirmModal';
import { NotificationCount } from './NotificationCount';
import { NotificationListHeaderContextMenu } from './NotificationListHeaderContextMenu';

import './index.scss';

export type NotificationListHeaderProps = {
    hasError: boolean;
    hasNotifications: boolean;
    unreadNotificationsCount: number;
    isLoading: boolean;
    onConfirmOpen?(): void;
    deleteAll(): void;
    setAllAsRead(): void;
    onSettingsClick: (evt: React.MouseEvent) => void;
    labelRef?: React.Ref<HTMLElement>;
    onFilterAll: () => void;
    onFilterUnread: () => void;
    notificationStatus: NotificationStatus;
};

const CLASSNAME = 'notification-center-list-header';
// ID set on the notification list header title to be set as dialog label
export const TITLE_ID = 'notification-center-title';

const NotificationListHeader: React.FC<NotificationListHeaderProps> = ({
    hasError,
    hasNotifications,
    unreadNotificationsCount,
    isLoading,
    deleteAll,
    setAllAsRead,
    onConfirmOpen,
    onSettingsClick,
    labelRef,
    onFilterAll,
    onFilterUnread,
    notificationStatus,
}) => {
    const { translateKey } = useTranslate();
    const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
    const { get } = useDataAttributes(DATA_NAMESPACE);
    const { element } = useClassnames(CLASSNAME);

    const shouldDisplayFilters = React.useMemo(() => {
        return !hasError && hasNotifications;
    }, [hasError, hasNotifications]);

    const shouldDisplaySkeletons = React.useMemo(() => {
        return !hasError && !hasNotifications && isLoading;
    }, [hasError, hasNotifications, isLoading]);

    const openConfirm =
        (isOpen = true) =>
        () => {
            if (isOpen && onConfirmOpen) {
                onConfirmOpen();
            }
            setIsConfirmOpen(isOpen);
        };

    const onValidate = () => {
        setIsConfirmOpen(false);
        deleteAll();
    };

    return (
        <>
            <div className={classnames((shouldDisplayFilters || isLoading) && element('with-filters'))}>
                <Toolbar
                    label={
                        <FlexBox vAlign={Alignment.left} hAlign={Alignment.center}>
                            <Heading id={TITLE_ID} ref={labelRef} tabIndex={-1} typography={Typography.body2}>
                                {translateKey(NOTIFICATIONS_CENTER.NOTIFICATIONS)}
                            </Heading>
                            {shouldDisplaySkeletons ? (
                                <SkeletonCircle
                                    size={Size.xs}
                                    className={element('count-skeleton', [margin('horizontal', 'regular')])}
                                />
                            ) : (
                                <NotificationCount
                                    count={unreadNotificationsCount}
                                    className={element('count', [margin('horizontal', 'regular')])}
                                />
                            )}
                        </FlexBox>
                    }
                    after={
                        <>
                            {shouldDisplaySkeletons ? (
                                <SkeletonCircle size={Size.m} />
                            ) : (
                                <>
                                    {hasNotifications ? (
                                        <NotificationListHeaderContextMenu
                                            onDeleteAll={openConfirm(true)}
                                            onSetAllAsRead={setAllAsRead}
                                            onSettingsClick={onSettingsClick}
                                        />
                                    ) : (
                                        <IconButton
                                            emphasis={Emphasis.low}
                                            icon={mdiSettings}
                                            onClick={onSettingsClick}
                                            color="grey"
                                            {...get({ element: 'settings' })}
                                            label={translateKey(NOTIFICATIONS_CENTER.NOTIFICATIONS_SETTINGS)}
                                            linkAs={Link}
                                            to={getUserSettingsRoute(USER_SETTINGS_VIEWS.NOTIFICATIONS)}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    }
                />

                {shouldDisplaySkeletons ? (
                    <ChipGroup
                        className={paddings([
                            { direction: 'horizontal', size: 'huge' },
                            { direction: 'bottom', size: 'huge' },
                        ])}
                    >
                        <SkeletonRectangle
                            className={element('chip-skeleton', [sizing(Size.m)])}
                            variant={SkeletonRectangleVariant.pill}
                        />
                        <SkeletonRectangle
                            className={element('chip-skeleton', [sizing(Size.m)])}
                            variant={SkeletonRectangleVariant.pill}
                        />
                    </ChipGroup>
                ) : (
                    shouldDisplayFilters && (
                        <ChipGroup
                            className={paddings([
                                { direction: 'horizontal', size: 'huge' },
                                { direction: 'bottom', size: 'huge' },
                            ])}
                            role="radiogroup"
                            aria-label={translateKey(NOTIFICATIONS_CENTER.FILTER_GROUP_ARIA)}
                        >
                            <Chip
                                role="radio"
                                isSelected={notificationStatus === NotificationStatus.all}
                                aria-checked={notificationStatus === NotificationStatus.all}
                                onClick={onFilterAll}
                                isDisabled={isLoading}
                                {...get({ element: 'all', action: 'filter' })}
                            >
                                {translateKey(NOTIFICATIONS_CENTER.FILTER_ALL)}
                            </Chip>
                            <Chip
                                role="radio"
                                isDisabled={unreadNotificationsCount === 0 || isLoading}
                                isSelected={notificationStatus === NotificationStatus.unread}
                                aria-checked={notificationStatus === NotificationStatus.unread}
                                onClick={onFilterUnread}
                                {...get({ element: 'unread', action: 'filter' })}
                            >
                                {translateKey(NOTIFICATIONS_CENTER.FILTER_UNREAD)}
                            </Chip>
                        </ChipGroup>
                    )
                )}
            </div>
            <DeleteAllNotificationConfirmModal
                isOpen={isConfirmOpen}
                onCancel={openConfirm(false)}
                onConfirm={onValidate}
            />
        </>
    );
};

export { NotificationListHeader };
