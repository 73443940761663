const Timezones = {
    'Africa/Abidjan': 'GMT',
    'Africa/Nairobi': 'EAT',
    'Africa/Algiers': 'CET',
    'Africa/Lagos': 'WAT',
    'Africa/Maputo': 'CAT',
    'Africa/Cairo': 'EET',
    'Europe/Paris': 'CET',
    'Africa/Johannesburg': 'SAST',
    'Africa/Khartoum': 'EAT',
    'Africa/Sao_Tome': 'GMT',
    'Africa/Tripoli': 'EET',
    'Africa/Windhoek': 'CAT',
    'America/Adak': 'HST',
    'America/Anchorage': 'AKST',
    'America/Santo_Domingo': 'AST',
    'America/Panama': 'EST',
    'America/Mexico_City': 'CST',
    'America/Managua': 'CST',
    'America/Denver': 'MST',
    'America/Cancun': 'CST',
    'America/Chicago': 'CST',
    'America/Chihuahua': 'MST',
    'America/Phoenix': 'MST',
    'America/Los_Angeles': 'PST',
    'America/New_York': 'EST',
    'America/Fort_Nelson': 'PST',
    'America/Halifax': 'AST',
    'America/Grand_Turk': 'EST',
    'America/Havana': 'CST',
    'America/Metlakatla': 'PST',
    'America/Port-au-Prince': 'EST',
    'America/St_Johns': 'NST',
    'Pacific/Auckland': 'NZDT',
    'Asia/Amman': 'EET',
    'Asia/Beirut': 'EET',
    'Asia/Kolkata': 'IST',
    'Asia/Shanghai': 'CST',
    'Asia/Damascus': 'EET',
    'Asia/Gaza': 'EET',
    'Asia/Hong_Kong': 'HKT',
    'Asia/Jakarta': 'WIB',
    'Asia/Jayapura': 'WIT',
    'Asia/Jerusalem': 'IST',
    'Asia/Karachi': 'PKT',
    'Asia/Makassar': 'WITA',
    'Asia/Manila': 'PST',
    'Europe/Athens': 'EET',
    'Asia/Pyongyang': 'KST',
    'Asia/Seoul': 'KST',
    'Asia/Tokyo': 'JST',
    'Europe/Lisbon': 'WET',
    'Australia/Sydney': 'AEDT',
    'Australia/Adelaide': 'ACDT',
    'Australia/Brisbane': 'AEST',
    'Australia/Darwin': 'ACST',
    'Australia/Perth': 'AWST',
    'Europe/Dublin': 'GMT',
    'Etc/UTC': 'UTC',
    'Europe/London': 'GMT',
    'Europe/Chisinau': 'EET',
    'Europe/Moscow': 'MSK',
    'Europe/Simferopol': 'EET',
    'Pacific/Honolulu': 'HST',
    MET: 'MET',
    'Pacific/Guam': 'ChST',
    'Pacific/Pago_Pago': 'SST',
};

/**
 * get timezone abbreviation from full timezone name
 * list of corresponding timezone name/abbreviation comming from https://github.com/prantlf/timezone-support
 * /!\ this list only works with timezone created after 2012 and may not be exhaustive.
 */
export const getTimezoneAbbr = (timezoneName: string): string | undefined => (Timezones as any)[timezoneName];
