import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Icon, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { VISIBILITY_ICONS } from '../../constants';
import { getCommunityPrivacy } from '../../keys';
import { CommunityPrivacy } from '../../types';

import './index.scss';

/**
 * Defines the props of the CommunityVisiblity.
 */
export interface CommunityVisibilityProps {
    /** Custom className */
    className?: string;
    /** Community visibility. */
    visibility: CommunityPrivacy;
}

/**
 * The default class name and classes prefix for this component.
 */
const CLASSNAME = 'community-visibility';

/**
 * Render a community visibility with its associated icon.
 *
 * @return The component.
 */
const CommunityVisibility: React.FC<CommunityVisibilityProps> = ({ className = '', visibility }) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);

    if (!visibility || !VISIBILITY_ICONS[visibility]) {
        return null;
    }

    return (
        <Text as="span" className={block([className])}>
            <Icon className={element('icon')} icon={VISIBILITY_ICONS[visibility]} />
            <Text as="span" className={element('type')}>
                {translateKey(getCommunityPrivacy(visibility))}
            </Text>
        </Text>
    );
};

export { CLASSNAME, CommunityVisibility };
