import { NotificationGroup } from '../types';

/** Returns the notification with the most recent updatedAt field */
export const getMostRecentNotification = (notifications: NotificationGroup[] = []) =>
    notifications.reduce((prev: NotificationGroup, curr: NotificationGroup) => {
        return curr?.notification?.updatedAt > prev?.notification?.updatedAt ? curr : prev;
    }, notifications[0]);

/** Returns the notification with the given "read" status */
// eslint-disable-next-line default-param-last
export const getNotificationsByReadStatus = (notifications: NotificationGroup[] = [], isRead: boolean) =>
    notifications.filter((notification) =>
        // Some notifications seem to have isRead undefined. In that case, return the opposite
        notification.notification.isRead === undefined ? !isRead : notification.notification.isRead === isRead,
    );
