/* eslint-disable no-use-before-define */
/* istanbul ignore file */
import React from 'react';

import { CommunityPrivacy } from '@lumapps/communities/types';
import { Directory } from '@lumapps/directories/types';
import { Instance } from '@lumapps/instance/types';
import { Placement, Emphasis } from '@lumapps/lumx/react';
import { TranslateObject, TranslatableObject } from '@lumapps/translations/types';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

/** All the possible NavigationItem Type */
export enum NavigationItemType {
    // Legacy, can appear in very old navigation but should not be used anymore
    HOME_PAGE = 'homePage',
    ROOT = 'root',
    SITE = 'site',
    MENU = 'menu',
    NEWS = 'news',
    CUSTOM = 'custom',
    MORE = 'more',

    // Simple types
    LINK = 'link',
    SECTION = 'section',

    // Page type family
    PAGE = 'page',
    CONTENT = 'content',
    COMMUNITY = 'community',
    ARTICLE = 'article',
    DIRECTORY = 'directory',
    USER_DIRECTORY = 'user_directory',
    CUSTOM_LIST = 'custom_list',

    // System Page type family
    SYSTEM_PAGE = 'system_page',
    JOURNEYS = 'journeys',
    PERSONAL_FEED = 'personal_feed',
    VIDEO_GALLERY = 'video_gallery',
}

/** readonly array containing all the possible types for systemPageReference. Used in legacy and to generate the type below */
export const SYSTEM_PAGE_REFERENCES = [
    NavigationItemType.JOURNEYS,
    NavigationItemType.PERSONAL_FEED,
    NavigationItemType.VIDEO_GALLERY,
] as const;

/** All the possible types for a SystemPageReference. Used in both NavigationItem and NavigationMapItem */
export type SystemPageReference = (typeof SYSTEM_PAGE_REFERENCES)[number];

/** ************************************************************************ */
/* Everything related to Navigation. Navigation is the GET object to display */
/** ************************************************************************ */

/**
 * Navigation nodes returned by GET V2 /xxx-navigation endpoint
 */
export type NavigationItem = {
    /** The identifier. UUID */
    id: string;
    /** i18n title */
    title?: TranslateObject;
    /** The item slug i18n */
    slug?: TranslateObject;
    /** The item icon */
    icon?: string;
    /** Whether the title should be displayed or not */
    hideTitle?: boolean;
    /** Child items */
    children?: NavigationItem[];
    /** Props like css class */
    properties?: {
        class?: string;
        id?: string;
    };
    /** The type of item */
    type?: NavigationItemType;
    /** external link if any */
    url?: string;
    /** visibility for the navigation item */
    visibleBy?: string[];
    /** Override the title */
    menuTitle?: TranslateObject;
    /** if type === page, then this prop will say what type of content it is */
    pageType?: NavigationItemType;
    /** if type === page, then this prop will say what the id of the page is */
    pageId?: number | string;
    /** if type === system_page, then this prop will say what type of system page it is */
    systemPageReference?: {
        type: SystemPageReference;
    };
    /** featured image id to rebuild image url for navigation item */
    featuredImageDocId?: string;
    /** Should be opened in new tab? */
    newTab?: boolean;
};

/** Interface abstraction for the Main navigation. It constructed using util function parseNavigationItem() */
export type MainTopNavigationItemProps = {
    /** The item's technical id */
    id?: NavigationItem['id'];
    /** The item's custom HTML id if defined */
    customHtmlId?: string;
    /** the item's pageId if its type is page, or its technical id otherwise */
    uid?: NavigationItem['id'];
    /** The i18n title or the overridden title */
    title: NavigationItem['title'] | string;
    /** The content slug */
    slug?: NavigationItem['slug'];
    /** The content slug */
    link?: TranslateObject;
    /** Complete URL for the navigation item */
    url?: string;
    /** The child items formatted for the UI */
    items?: MainTopNavigationItemProps[];
    /** Should be opened in a new tab? */
    openInNewTab?: NavigationItem['newTab'];
    /** Icon like 'account' */
    icon?: NavigationItem['icon'];
    /** Whether the title should be hidden. */
    hideTitle?: NavigationItem['hideTitle'];
    /** The type of item */
    type: NavigationItemType;
    /** the tree level of the item */
    level?: number;
    /** Is the item active */
    isActive?: boolean;
    /** Is the item the current url */
    isCurrentUrl?: boolean;
    /** Raw custom className */
    className?: string;
    /** CSS classes containing the custom child prefixes */
    classes?: string[];
    /** The item has children? */
    hasItems?: boolean;
    /** Is the dropdown menu open? */
    isDropdownOpen?: boolean;
    /** The dropdown placement */
    placement?: Placement;
    /** Item emphasis */
    emphasis?: Emphasis;
    /** Action called when an item is clicked */
    onItemClick?(): void;
    /** whether the item should be visible or not */
    isVisible?: boolean;
    /** parent instance if the item belongs to another instance */
    parentInstance?: Instance;
    /** whether the links shown are from a parent navigation or not */
    isParentNav?: boolean;
    /** whether the nav inheritance feature is enabled */
    isMainNavInheritanceEnabled?: boolean;
};

export type MainTopNavigationProps = {
    /** Id to set on the nav component */
    id?: string;
    /** Is the nav loading */
    isLoading?: boolean;
    /** Is the nav disabled */
    isDisabled?: boolean;
    /** Function to get items */
    getItems?(params: MenuQuery): Promise<NavigationItem[]>;
    /** The user lang */
    lang: string;
    /** The user alternative lang */
    alternativeLang: string;
    /** The customer identifier */
    customer: string;
    /** The instance identifier */
    instance: string;
    /** Custom? className */
    className?: string;
    /** Action when the side nav is closing */
    onClose?(): void;
    /** custom styles for the main nav */
    styles?: Record<string, any>;
    /** Element that the header will be anchored to */
    anchorRef?: React.RefObject<HTMLElement>;
    /** whether the links shown are from a parent navigation or not */
    isParentNav?: boolean;
    /** whether the nav inheritance feature is enabled */
    isMainNavInheritanceEnabled?: boolean;
    /** parent instance id */
    parentInstance?: string;
    /** id of the site's home page */
    homePageId?: string;
};

export type ResponsiveMainSideNavigationProps = {
    /** Is the side nav open? */
    isOpen?: boolean;
    /** Action when the side nav is closing */
    onClose?(): void;
    /** Is the nav loading */
    isLoading?: boolean;
    /** Function to get items */
    getItems?(params: MenuQuery): Promise<NavigationItem[]>;
    /** The user lang */
    lang: string;
    /** The user alternative lang */
    alternativeLang: string;
    /** The customer identifier */
    customer: string;
    /** The instance identifier */
    instance: string;
    /** Custom? className */
    className?: string;
    /** All directories to display */
    directories?: Directory[];
    /** whether the nav inheritance feature is enabled */
    isMainNavInheritanceEnabled?: boolean;
    /** The parent instance identifier */
    parentInstanceId?: string;
};

export type MenuQuery = {
    /** The user lang */
    lang: string;
    /** The alternative lang */
    alternativeLang: string;
    /** The customer id */
    customer: string;
    /** The instance id */
    instance: string;
};

export interface Navigation {
    id?: string;
    type: NavigationItemType.ROOT;
    children: NavigationItem[];
    version?: number;
}

/** The Types of Node for Navigation object */
type NavigationHomePageNode = NavigationItem & { slug: string; type: NavigationItemType.HOME_PAGE };
type NavigationLinkNode = NavigationItem & { url: string; groups?: string; type: NavigationItemType.LINK };
type NavigationPageNode = NavigationItem & {
    pageId: string;
    pageType: NavigationItemType;
    type: NavigationItemType.PAGE;
};
type NavigationSectionNode = NavigationItem & {
    children: (NavigationHomePageNode | NavigationLinkNode | NavigationPageNode | NavigationSectionNode)[];
    type: NavigationItemType.SECTION;
};

export type NavigationMapPageReferenceType =
    | NavigationItemType.ARTICLE
    | NavigationItemType.CONTENT
    | NavigationItemType.COMMUNITY
    | NavigationItemType.DIRECTORY
    | NavigationItemType.SITE
    | NavigationItemType.SYSTEM_PAGE;

/** ******************************************************************************************** */
/* Everything related to NavigationMap. NavigationMap is the admin object to update Navigation.  */
/** ******************************************************************************************** */

/** Object of all possible navigation map permissions */
export const NavigationMapItemPermissions = {
    none: 'none',
    view: 'view',
    edit: 'edit',
    all: 'all',
} as const;

/** Values of all possible navigation map permissions */
export type NavigationMapItemPermissionsType = ObjectValues<typeof NavigationMapItemPermissions>;

/** NavigationMap nodes, for navigation admin GET and PUT */
export type NavigationMapItem = {
    /** The identifier. UUID if already in the base, or empty if new node that is not saved yet */
    id?: string;
    /** i18n title */
    title?: TranslatableObject;
    /** The item slug i18n */
    slug?: TranslatableObject;
    /** The item icon */
    icon?: string;
    /** Child items */
    children?: NavigationMapItem[];
    /** The custom classes and id properties, if any */
    properties?: {
        class?: string;
        id?: string;
    };
    /** The type of item */
    type?:
        | NavigationItemType.ROOT
        | NavigationItemType.PAGE
        | NavigationItemType.LINK
        | NavigationItemType.SECTION
        | NavigationItemType.SYSTEM_PAGE;
    /** external link if any */
    url?: string;
    /** visibility for the navigation item */
    visibleBy?: string[];
    /** Whether the current node is hidden (only for GET) */
    isHidden?: boolean;
    /** Whether the title should be displayed or not */
    hideTitle?: boolean;
    /** Whether the link should be opened in new tab */
    newTab?: boolean;
    /** Whether the children are hidden (only for GET) */
    areChildrenHidden?: boolean;
    /** The langs that are set for this node, ex 'en', 'fr'... */
    langs?: string[];
    /** The title override, if any */
    nodeTitle?: TranslatableObject;
    /** The related content type for pieces of content */
    contentType?: {
        id: string;
        icon: string;
        name: TranslateObject;
    };
    /** The information of the referenced page  */
    pageReference?: {
        /** The page id */
        id?: string;
        /** The type of page */
        type: NavigationMapPageReferenceType;
        /** The list of groups this node is visible for */
        groupIds?: string[];
        /** The privacy for page of type community */
        privacy?: CommunityPrivacy;
    };
    /** The information of the page's site */
    siteReference?: {
        /** site id */
        id: string;
        /** site name */
        name: string;
        /** site slug */
        slug: string;
    };
    /** The type of system page */
    systemPageReference?: {
        type: SystemPageReference;
    };
    /** featured image id to rebuild image url for navigation item */
    imageDocId?: string;
    /** thumbnail for the content, if any */
    thumbnailUrl?: string;
    /** Technical field for the backend to manage legacy nodes. Must be sent backed on save */
    merged_uuid?: string;
    /** Technical field for the backend to manage legacy nodes. Must be sent backed on save */
    legacyId?: string;
    /** If partial edit role is set for this user, value for the edit */
    partialPermission?: NavigationMapItemPermissionsType;
};

/** https://github.com/lumapps/core/blob/master/specs/navigation_api_v2.yaml#L1120 */
export interface NavigationMap {
    /** Id of the navigation-map */
    id: string;
    /** The NavigationMap is always of type root node */
    type: NavigationItemType.ROOT;
    /** List of child nodes */
    children: NavigationMapItem[];
    /** The Navigation entryPoint id */
    entrypointId?: string;
    /** n-th update of this navigation map */
    version?: number;
    /** Dictionary of Groups id and their names, so we can display the list of visibleBy per node without extra call */
    visibleByGroupReferences?: Record<string, string>;
    /** If partial edit role is set for this user, value for the edit */
    partialPermission?: NavigationMapItemPermissionsType;
}

/** The type of Node for NavigationMap object */
type NavigationMapLinkNode = NavigationMapItem & { type: NavigationItemType.LINK; url: string };
type NavigationMapPageNode = NavigationMapItem & {
    type: NavigationItemType.PAGE;
    pageReference: NavigationMapItem['pageReference'];
};
type NavigationMapSectionNode = NavigationMapItem & {
    type: NavigationItemType.SECTION;
    children: (NavigationMapLinkNode | NavigationMapPageNode | NavigationMapSectionNode)[];
};

/**
 * Util functions to check if a Navigation or NavigationMap Node is of a specific type
 */
export const isParentNode = (
    node: Navigation | NavigationItem | NavigationMap | NavigationMapItem,
): node is NavigationSectionNode | Navigation | NavigationMapSectionNode | NavigationMap => 'children' in node;

export const isSectionNode = (
    node: Navigation | NavigationItem | NavigationMap | NavigationMapItem,
): node is NavigationSectionNode | NavigationMapSectionNode => node.type === NavigationItemType.SECTION;

export const isLinkNode = (
    node: Navigation | NavigationItem | NavigationMap | NavigationMapItem,
): node is NavigationLinkNode | NavigationMapLinkNode => node.type === NavigationItemType.LINK;

export const isPageNode = (
    node: Navigation | NavigationItem | NavigationMap | NavigationMapItem,
): node is NavigationPageNode | NavigationMapPageNode => node.type === NavigationItemType.PAGE;

export const isSystemPageNode = (
    node: Navigation | NavigationItem | NavigationMap | NavigationMapItem,
): node is NavigationPageNode | NavigationMapPageNode => node.type === NavigationItemType.SYSTEM_PAGE;

/**
 * The filters set on the navigation admin.
 */
export interface NavigationListFilters {
    /** Filter by user group. */
    group?: string;
    /** Filter by language. */
    language?: string;
}
