/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const identityProviderRoute: Route = {
    path: '/admin/identity-providers',
    legacyId: 'app.admin.providers-idp',
    appId: AppId.backOffice,
};

/** Retrieves the route for the identity provider admin */
const identityProvider = (): Route => identityProviderRoute;

const serviceProviderRoute: Route = {
    path: '/admin/service-providers',
    legacyId: 'app.admin.providers-service',
    appId: AppId.backOffice,
};

const serviceProvider = (): Route => serviceProviderRoute;

export { identityProvider, serviceProvider, identityProviderRoute, serviceProviderRoute };
