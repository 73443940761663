import React, { useMemo } from 'react';

import pick from 'lodash/pick';

import { getCurrentContentAsCommunity } from '@lumapps/communities/ducks/selectors';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { Divider } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { ListWidgetPropertiesSetting } from '@lumapps/widget-settings/components/ListWidgetPropertiesSetting';
import { ListWidgetProperties, SELECTION_TYPE_VALUES, SelectionSettingType } from '@lumapps/widget-settings/types';

import { DATA_SCOPE } from '../../constants';
import { WIDGET_EVENT_LIST } from '../../keys';
import { WidgetEventListPropertiesType } from '../../type';
import { FilteringSection } from './FilteringSection';
import { InformationDisplayedSection } from './InformationDisplayedSection';
import { RestrictionSection } from './RestrictionSection';
import { SelectionSection } from './SelectionSection';
import { SortingSection } from './SortingSection';

export interface WidgetEventListSettingsProps {
    properties?: WidgetEventListPropertiesType;
    onChange: (properties: WidgetEventListPropertiesType) => void;
}

const CLASSNAME = 'widget-event-list-settings';

export const WidgetEventListSettings: React.FC<WidgetEventListSettingsProps> = ({ properties, onChange }) => {
    const { translateKey } = useTranslate();
    const currentCommunity = useSelector(getCurrentContentAsCommunity);
    const instanceId = useSelector(instanceIdSelector);
    const isCurrentCommunityChecked =
        properties?.displayType === 'current_community'
            ? true
            : currentCommunity &&
              properties?.communityIds?.length === 1 &&
              properties.communityIds[0] === currentCommunity.id;

    const propertiesToDisplay = React.useMemo(() => {
        return properties?.viewMode === 'slideshow'
            ? [ListWidgetProperties.maxNumber]
            : [ListWidgetProperties.filters, ListWidgetProperties.maxNumber, ListWidgetProperties.loadMore];
    }, [properties?.viewMode]);

    const computedSelection: SelectionSettingType = useMemo(() => {
        let selectedOption;
        let selectedSubOption;

        if (properties?.displayType === 'pick') {
            selectedOption = SELECTION_TYPE_VALUES.MANUAL;
        } else if (isCurrentCommunityChecked) {
            selectedOption = SELECTION_TYPE_VALUES.CURRENT_COMMUNITY;
        } else {
            selectedOption = SELECTION_TYPE_VALUES.DYNAMIC;
            if (!properties?.communityIds?.length) {
                selectedSubOption = SELECTION_TYPE_VALUES.ALL_COMMUNITIES;
            } else {
                selectedSubOption = SELECTION_TYPE_VALUES.MANUAL_COMMUNITY;
            }
        }

        return {
            selectedOption,
            selectedCommunitiesIds: properties?.communityIds,
            selectedEntitiesIds: properties?.eventIds,
            selectedSubOption,
        };
    }, [isCurrentCommunityChecked, properties]);

    return (
        <div className={CLASSNAME}>
            <SelectionSection
                onChange={({ selection }) => {
                    if (selection.selectedOption === SELECTION_TYPE_VALUES.MANUAL) {
                        onChange({
                            eventIds: selection.selectedEntitiesIds,
                            communityIds: [],
                            displayType: 'pick',
                            siteId: instanceId,
                        });
                    } else if (selection.selectedOption === SELECTION_TYPE_VALUES.CURRENT_COMMUNITY) {
                        if (currentCommunity) {
                            onChange({
                                eventIds: [],
                                communityIds: [currentCommunity.id],
                                displayType: 'current_community',
                                siteId: instanceId,
                            });
                        }
                    } else if (selection.selectedSubOption === SELECTION_TYPE_VALUES.ALL_COMMUNITIES) {
                        onChange({
                            eventIds: [],
                            communityIds: [],
                            displayType: 'list',
                            siteId: instanceId,
                        });
                    } else {
                        onChange({
                            eventIds: [],
                            communityIds: selection.selectedCommunitiesIds,
                            displayType: 'list',
                            siteId: instanceId,
                        });
                    }
                }}
                currentSelection={computedSelection}
            />
            <Divider />
            <RestrictionSection
                onChange={onChange}
                currentSelectionType={computedSelection}
                currentRestrictions={properties && pick(properties, 'isFollowedCommunityFilterActive')}
            />
            <Divider />
            <FilteringSection onChange={onChange} currentFiltering={properties?.eventDateFilter} />
            <Divider />
            <SortingSection onChange={onChange} currentSorting={properties?.listOrderDir} />
            <Divider />
            <ListWidgetPropertiesSetting
                onChange={(input) =>
                    onChange({
                        canLoadMore: input.canLoadMore,
                        hasFilters: input.hasFilters,
                        maxNumber: input?.maxNumber,
                    })
                }
                currentPropertiesSetting={{
                    canLoadMore: properties?.canLoadMore ?? true,
                    hasFilters: properties?.hasFilters ?? false,
                    maxNumber: properties?.maxNumber,
                }}
                loadMoreHelper={translateKey(WIDGET_EVENT_LIST.SETTINGS_SHOW_LOAD_MORE_HELPER)}
                defaultMaxNumber="3"
                dataScope={DATA_SCOPE}
                propertiesToDisplay={propertiesToDisplay}
            />
            <Divider />
            <InformationDisplayedSection onChange={onChange} currentInformationDisplayedSetting={properties?.fields} />
        </div>
    );
};
