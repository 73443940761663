import { ReactNode } from 'react';

import { SearchSuggestion } from '@lumapps/search/types';
import { WithRequiredProperty } from '@lumapps/utils/types/WithRequiredProperty';

type SearchSuggestionWithItem = WithRequiredProperty<SearchSuggestion, 'item'>;

/** Props for the SearchBoxOption component */
export interface SearchBoxOptionProps {
    id: string;
    className?: string;
    suggestion: SearchSuggestion;
    onSelect: (suggestion: SearchSuggestion) => void;
    after?: ReactNode;
}

/** Props for each search box option types components (interaction, users, etc...) */
export interface SearchBoxOptionTypeProps extends SearchBoxOptionProps {
    onSelect: () => void;
}

export type SearchBoxOptionWithItemProps = SearchBoxOptionTypeProps & { suggestion: SearchSuggestionWithItem };

/** Checks if the current suggestion has an item */
export const isSuggestionWithItem = (suggestion: SearchSuggestion): suggestion is SearchSuggestionWithItem =>
    Boolean(suggestion.item);
