import React from 'react';

import { ComboboxOption } from '@lumapps/combobox/components/ComboboxOption';
import { mdiMagnify } from '@lumapps/lumx/icons';
import { Icon, Size, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { QUICK_SEARCH } from '../../keys';

export interface SearchForOptionProps {
    /** Classname to set on the option */
    className?: string;
    /** Action when the option is selected  */
    onSelect: () => void;
    /** Current query set on the search box */
    query: string;
}

/**
 * Component to display an option that invites the
 * user to "search for" their query.
 *  */
export const SearchForOption: React.FC<SearchForOptionProps> = ({ className, onSelect, query }) => {
    const { translateAndReplaceWithComponents } = useTranslate();

    const searchForMessageWithBold = translateAndReplaceWithComponents(QUICK_SEARCH.SEARCH_FOR, {
        query: <strong key="query">{query}</strong>,
    });

    return (
        <ComboboxOption
            id="quick-search-search-for"
            className={className}
            before={<Icon icon={mdiMagnify} size={Size.s} />}
            onSelect={onSelect}
            textValue={query}
        >
            <Text as="span" truncate>
                {searchForMessageWithBold}
            </Text>
        </ComboboxOption>
    );
};
