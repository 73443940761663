import React from 'react';

import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

const CLASSNAME = 'wrex-embedded-video__actions';
export const DeleteButton = ({ onDelete, className }: { onDelete: (event: any) => void; className?: string }) => {
    const { translateKey } = useTranslate();

    return (
        <div className={className || CLASSNAME}>
            <IconButton
                icon={mdiClose}
                emphasis={Emphasis.low}
                hasBackground
                onClick={onDelete}
                label={translateKey(GLOBAL.DELETE)}
            />
        </div>
    );
};
