import { Editor } from '@lumapps/wrex/slate';
import { isElement } from '@lumapps/wrex/slate/utils/isElement';
import type { Wrex } from '@lumapps/wrex/types';

import { LinkOptions } from '../types';

export const isLinkAllowed = (editor: Editor, options: LinkOptions) => (): boolean => {
    // Editor cursor should be available.
    if (!editor.selection) {
        return true;
    }
    const elements = Array.from(Editor.nodes(editor, { at: editor.selection, match: isElement, mode: 'lowest' }));

    // Editor selection should include only one block.
    if (elements.length !== 1) {
        return false;
    }
    // Check allowed parent block.
    if (options.allowedParentBlock) {
        // First block in node entry list is an allowed parent block.
        return (elements[0][0] as Wrex.Element).type === options.allowedParentBlock;
    }
    return true;
};
