(function IIFE() {
    /////////////////////////////

    function WidgetSettingsController(Content, Instance, Translation, Widget) {
        'ngInject';

        const vm = this;

        /////////////////////////////

        vm.Content = Content;
        vm.Instance = Instance;
        vm.Translation = Translation;
        vm.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            if (angular.isUndefined(vm.dblclickHelper)) {
                vm.dblclickHelper = false;
            }

            if (angular.isUndefined(vm.hideMoreLink)) {
                vm.hideMoreLink = false;
            }

            if (angular.isUndefined(vm.hideNoResults)) {
                vm.hideNoResults = false;
            }

            if (angular.isUndefined(vm.hideTitle)) {
                vm.hideTitle = false;
            }

            if (angular.isUndefined(vm.hideStyleTitle)) {
                vm.hideStyleTitle = vm.hideTitle;
            }

            if (angular.isUndefined(vm.hideSpecificSettings)) {
                vm.hideSpecificSettings = false;
            }

            if (angular.isUndefined(vm.hideSpecificStyles)) {
                vm.hideSpecificStyles = false;
            }

            Widget.settingsActiveTab = 0;
        }

        init();
    }

    /////////////////////////////

    /**
     * The global widget settings.
     *
     * @param {boolean} [dblclickHelper=false]       Determine if the double click helper is enable.
     * @param {boolean} [hideMoreLink=false]         Determine if the 'More link' block is displayed.
     * @param {boolean} [hideNoResults=false]        Determine if the 'NO results' block is displayed..
     * @param {boolean} [hideSpecificSettings=false] Determine if the 'Specific settings' block is dsiplayed.
     * @param {boolean} [hideSpecificStyles=false]   Determine if the 'Specific styles' block is displayed.
     * @param {boolean} [hideStyleTitle=false]       Determine if the 'Header Style' block is displayed in style tab.
     * @param {boolean} [hideTitle=false]            Determine if the 'Title' block is displayed.
     */

    function widgetSettingsDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: WidgetSettingsController,
            controllerAs: 'widgetSettings',
            restrict: 'E',
            scope: {
                dblclickHelper: '<?',
                hideMoreLink: '<?',
                hideNoResults: '<?',
                hideSpecificSettings: '<?',
                hideSpecificStyles: '<?',
                hideStyleTitle: '<?',
                hideTitle: '<?',
            },
            templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-settings.html',
            transclude: true,
            replace: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('widgetSettings', widgetSettingsDirective);
})();
