(function IIFE() {
    'use strict';

    /////////////////////////////

    function InterestService($cacheFactory, InterestFactory, LumsitesBaseService) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(InterestFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * A cache object filled with objects holding subscriptions of the current user.
         * The elements are only added after a list/get or after a subscription.
         *
         * @type {Cache}
         */
        var _subscriptionsCache = $cacheFactory('subscriptionCache');

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if we already have a subscription with the given key (computed from Custom Content Type id, tags and
         * metadata, see `computeSubscriptionKey`) in the subscriptions cache.
         *
         * @param  {string}  subscriptionKey The subscription key we want to check.
         * @return {boolean} If the subscription is already cached or not.
         */
        function _isCached(subscriptionKey) {
            return angular.isDefinedAndFilled(_subscriptionsCache.get(subscriptionKey));
        }

        /**
         * Remove a subscription from the cache.
         *
         * @param {Object} subscription    The subscription to remove from the cache.
         * @param {string} subscriptionKey The cache key of the subscription we want to remove from the cache.
         */
        function _removeSubscription(subscription, subscriptionKey) {
            if (angular.isUndefinedOrEmpty(subscription)) {
                return;
            }

            subscriptionKey = subscriptionKey || service.computeSubscriptionKey(
                subscription.customContentType, subscription.customContentTypeTags, subscription.orMetadata
            );

            if (_isCached(subscriptionKey)) {
                _subscriptionsCache.remove(subscription.id);
                _subscriptionsCache.remove(subscriptionKey);
            }
        }

        /**
         * Add an entity to the cache.
         *
         * @param {Object} subscription The the subscription to cache.
         */
        function _addSubscription(subscription) {
            if (angular.isUndefinedOrEmpty(subscription)) {
                return;
            }

            var subscriptionKey = service.computeSubscriptionKey(
                subscription.customContentType, subscription.customContentTypeTags, subscription.orMetadata
            );

            /**
             * To update an existing item in the cache list, we need to remove it completely first.
             * In theory we only want to update the entity property but we may as well update it all.
             */
            _removeSubscription(subscription, subscriptionKey);

            _subscriptionsCache.put(subscription.id, subscription);
            _subscriptionsCache.put(subscriptionKey, subscription);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Compute the cache key corresponding to the subscription.
         * It simply is a base64 encoding of the Custom Content Type id and the string representation of the tags and
         * the metadata.
         *
         * @param  {string} cctId      The id of the custom content type you want to get the subscription key.
         * @param  {Array}  [tags]     The tags used to filter the results of the subscription.
         * @param  {Array}  [metadata] The metadata used to filter the results of the subscription.
         * @return {string} The subscription key.
         */
        function computeSubscriptionKey(cctId, tags, metadata) {
            return btoa(cctId + (tags || '').toString() + (metadata || '').toString());
        }

        /**
         * Override the filterize method so that we can cache results.
         *
         * @param  {Object}              params       The parameters of the query.
         * @param  {Function}            [cb]         A callback to execute on success.
         * @param  {Function}            [errCb]      A callback to execute on error.
         * @param  {string}              [listKey]    The list key to reference the result of the call.
         * @param  {string|Object|Array} [projection] The field we want to get in the result object.
         * @return {Promise}             The Promise of the call.
         */
        function filterize(params, cb, errCb, listKey, projection) {
            // eslint-disable-next-line func-style
            var overrideCb = function overrideCb(subscriptions) {
                angular.forEach((subscriptions || []), _addSubscription);

                cb(subscriptions);
            };

            return LumsitesBaseService.proto.prototype.filterize.call(
                // eslint-disable-next-line no-invalid-this
                this, params, overrideCb, errCb, listKey, projection
            );
        }

        /**
         * Find a subscription from its custom content type, tags and metadata.
         *
         * @param {string}   cctId      The cct identifier
         * @param {Array}    [tags]     The tags
         * @param {Array}    [metadata] The metadata
         * @param {Function} [cb]       A callback to execute on success.
         * @param {Function} [errCb]    A callback to execute on error.
         */
        function findSubscription(cctId, tags, metadata, cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if (angular.isUndefinedOrEmpty(cctId)) {
                errCb();

                return;
            }

            var subscription = service.getSubscription(cctId, tags, metadata);

            if (angular.isDefinedAndFilled(subscription)) {
                cb(subscription);

                return;
            }

            InterestFactory.find({
                customContentType: cctId,
                customContentTypeTags: tags,
                orMetadata: metadata,
            }, function onInterestFindSuccess(foundSubscription) {
                _addSubscription(foundSubscription);
                cb(foundSubscription);
            }, function onInterestFindError(err) {
                if (err.status === 404) {
                    cb(undefined);

                    return;
                }

                errCb(err);
            });
        }

        /**
         * Override the get method so that we can cache the subscription.
         *
         * @param  {Object}              params       The parameters of the query.
         * @param  {Function}            [cb]         A callback to execute on success.
         * @param  {Function}            [errCb]      A callback to execute on error.
         * @param  {string}              [listKey]    The list key to reference the result of the call.
         * @param  {string|Object|Array} [projection] The field we want to get in the result object.
         * @return {Object}              The Promise of the call.
         *
         * @override
         */
        function get(params, cb, errCb, listKey, projection) {
            cb = cb || angular.noop;

            // eslint-disable-next-line func-style
            var overrideCb = function overrideCb(subscription) {
                _addSubscription(subscription);
                cb(subscription);
            };

            // eslint-disable-next-line no-invalid-this
            return LumsitesBaseService.proto.prototype.get.call(this, params, overrideCb, errCb, listKey, projection);
        }

        /**
         * Get a subscription.
         *
         * @param  {string} cctId      The id of the custom content type you want to get the subscription.
         * @param  {Array}  [tags]     The tags used to filter the results of the subscription.
         * @param  {Array}  [metadata] The metadata used to filter the results of the subscription.
         * @return {string} The id of the subscription.
         */
        function getSubscription(cctId, tags, metadata) {
            var subscriptionKey = service.computeSubscriptionKey(cctId, tags, metadata);

            if (!_isCached(subscriptionKey)) {
                return undefined;
            }

            return _subscriptionsCache.get(subscriptionKey);
        }

        /**
         * Check if we already have a subscription with the given key (computed from Custom Content Type id, tags and
         * metadata, see `computeSubscriptionKey`).
         *
         * @param  {string}  cctId      The id of the custom content type you want to check the subscription state.
         * @param  {Array}   [tags]     The tags used to filter the results of the subscription.
         * @param  {Array}   [metadata] The metadata used to filter the results of the subscription.
         * @return {boolean} If already subscribed or not.
         */
        function isSubscribedTo(cctId, tags, metadata) {
            return angular.isDefinedAndFilled(service.getSubscription(cctId, tags, metadata));
        }

        /**
         * Subscribe to a custom content type search (with eventual tags and metadata).
         *
         * @param {string}   cctId      The id of the custom content type you want to subscribe to.
         * @param {Array}    [tags]     The tags to filter the results of the subscription.
         * @param {Array}    [metadata] The metadata to filter the results of the subscription.
         * @param {Function} [cb]       The callback to execute when the subscribe is successful.
         * @param {Function} [errCb]    The callback to execute when the subscribe is in error.
         * @param {string}   [listKey]  The list key.
         */
        function subscribe(cctId, tags, metadata, instanceId, cb, errCb, listKey) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if (angular.isUndefinedOrEmpty(cctId)) {
                return;
            }

            var query = {
                customContentType: cctId,
                customContentTypeTags: tags,
                orMetadata: metadata,
                instanceId,
            };

            service.save(query, function onSubscriptionSaveSuccess(newSubscription) {
                _addSubscription(newSubscription);
                cb(newSubscription);
            }, errCb, listKey);
        }

        /**
         * Unsubscribe from a custom content type search (with eventual tags and metadata).
         *
         * @param {Object}   subscription The identifier of the subscription we want to unsubscribe.
         * @param {Function} [cb]         The callback to execute when the subscribe is successful.
         * @param {Function} [errCb]      The callback to execute when the subscribe is in error.
         * @param {string}   [listKey]    The list key.
         */
        function unsubscribe(subscription, cb, errCb, listKey) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if (angular.isUndefinedOrEmpty(subscription)) {
                return;
            }

            service.del(subscription.uid, function onSubscriptionDeleteSuccess() {
                _removeSubscription(subscription);
                cb();
            }, errCb, listKey);
        }

        /////////////////////////////

        service.computeSubscriptionKey = computeSubscriptionKey;
        service.filterize = filterize;
        service.findSubscription = findSubscription;
        service.get = get;
        service.getSubscription = getSubscription;
        service.isSubscribedTo = isSubscribedTo;
        service.subscribe = subscribe;
        service.unsubscribe = unsubscribe;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Interest', InterestService);
})();
