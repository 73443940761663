import React, { forwardRef } from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiPlus } from '@lumapps/lumx/icons';
import { IconButton, Emphasis, Button, Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { HEADER } from '../../keys';

export interface CreateButtonProps {
    /** on click action */
    onClick?(): void;
    /** button variant */
    variant?: 'regular' | 'compact';
    /** dark theme  */
    theme?: Theme;
}

/**
 * Create contribution button
 */
export const CreateButton = forwardRef<HTMLButtonElement, CreateButtonProps>((props, ref) => {
    const { onClick, variant = 'regular', theme, ...forwardedProps } = props;

    const { translateKey } = useTranslate();
    const { get } = useDataAttributes('contribution');

    const baseProps = {
        emphasis: Emphasis.high,
        className: 'contribution-button',
        theme,
        onClick,
        ...get({ element: 'button' }),
        ...forwardedProps,
    };

    if (variant === 'compact') {
        return <IconButton icon={mdiPlus} ref={ref} label={translateKey(HEADER.CREATE_BUTTON)} {...baseProps} />;
    }
    return (
        <Button ref={ref} {...baseProps}>
            {translateKey(HEADER.CREATE_BUTTON)}
        </Button>
    );
});
