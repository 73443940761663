(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialActivityHistoryService(LumsitesBaseService, SocialActivityHistoryFactory) {
        'ngInject';

        return LumsitesBaseService.createLumsitesBaseService(SocialActivityHistoryFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });
    }

    /////////////////////////////

    angular.module('Services').service('SocialActivityHistory', SocialActivityHistoryService);
})();
