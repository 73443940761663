import { isFeatureEnabled } from '@lumapps/features';
import { isSuperAdmin, isInstanceSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

/** Returns true if the feature flag for promoted results feature flag is enabled */
export const isPromotedResultsEnabled = isFeatureEnabled('promoted-results');

/** Returns true if the promoted-results-v2 FF is enabled  */
export const isPromotedResultsKeywordsEnabled = isFeatureEnabled('promoted-results-v2');

/**
 * Should be true if the flag is enabled
 * and if the user is global admin or admin of the site
 */
export const canAccessPromotedResults = createSelector(
    isPromotedResultsEnabled,
    isSuperAdmin,
    isInstanceSuperAdmin,
    (isEnabled, isAdmin, isSiteAdmin) => Boolean(isEnabled && (isAdmin || isSiteAdmin)),
);
