import React from 'react';

import { get as getConstants } from '@lumapps/constants';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { Route, Link as RouterLink } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';
import { createUrl } from '@lumapps/router/utils';
import { useTranslate } from '@lumapps/translations';

import { getNotificationMeta } from '../notification-meta';
import { Notification, NotificationMetaLinkAs } from '../types';

export interface UseNotificationRoute {
    /** props to be passed in as the attributes to be used on the link */
    linkProps: Record<string, any>;
    /** route for the notification content */
    route: Route | null;
    /** specific component that will be used for rendering the link. */
    linkAs?: 'a' | any;
}

/**
 * Returns the necessary props to render a link towards a Notification.
 */
const useNotificationRoute = (notification: Notification): UseNotificationRoute => {
    const translation = useTranslate();
    const { isLegacyContext } = getConstants();
    const currentInstanceId = useSelector(instanceIdSelector);

    /**
     * TODO: remove this once we have migrated to REACT
     */
    const target = React.useMemo(() => {
        if (isLegacyContext) {
            return '_self';
        }
        return undefined;
    }, [isLegacyContext]);

    return React.useMemo(() => {
        const { type } = notification;
        const meta = getNotificationMeta(type);

        if (meta && meta.getRoute) {
            const route = meta.getRoute({
                notification,
                translation,
                currentInstanceId,
            });

            const { linkAs, ...linkAsProps }: Partial<NotificationMetaLinkAs> =
                meta.getLinkAs?.({ translation, notification }) || {};

            if (route) {
                const isExternal = route.appId === AppId.external;
                const linkProps = {
                    href: createUrl(route, null, notification.instanceDetails?.slug),
                    target: isExternal ? '_blank' : target,
                    rel: isExternal ? 'noopener noreferrer' : undefined,
                    ...linkAsProps,
                };

                /** if a correct link has been generated, use RouterLink for better router management */
                const defaultLinkElement = linkAsProps?.to ? RouterLink : 'a';

                return {
                    linkProps,
                    route,
                    linkAs: linkAs || defaultLinkElement,
                };
            }
        }

        return { linkProps: {}, route: null };
    }, [notification, translation, currentInstanceId, target]);
};

export { useNotificationRoute };
