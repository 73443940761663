export enum WREX_TABLE {
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=180985280
     */
    TABLE_BUTTON = 'WREX_TABLE.TABLE_BUTTON',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=474883365
     */
    TABLE_TOOLBAR_INFORMATION_MESSAGE = 'WREX_TABLE.TABLE_TOOLBAR_INFORMATION_MESSAGE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170044730
     */
    TABLE_TOOLBAR_ADD_ROW_BEFORE = 'WREX_TABLE.TABLE_TOOLBAR_ADD_ROW_BEFORE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170044761
     */
    TABLE_TOOLBAR_ADD_ROW_AFTER = 'WREX_TABLE.TABLE_TOOLBAR_ADD_ROW_AFTER',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170044787
     */
    TABLE_TOOLBAR_ADD_COLUMN_BEFORE = 'WREX_TABLE.TABLE_TOOLBAR_ADD_COLUMN_BEFORE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170052909
     */
    TABLE_TOOLBAR_ADD_COLUMN_AFTER = 'WREX_TABLE.TABLE_TOOLBAR_ADD_COLUMN_AFTER',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170052992
     */
    TABLE_TOOLBAR_DELETE_ROW = 'WREX_TABLE.TABLE_TOOLBAR_DELETE_ROW',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222516684
     */
    TABLE_TOOLBAR_DELETE_ROWS = 'WREX_TABLE.TABLE_TOOLBAR_DELETE_ROWS',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170053021
     */
    TABLE_TOOLBAR_DELETE_COLUMN = 'WREX_TABLE.TABLE_TOOLBAR_DELETE_COLUMN',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=222517529
     */
    TABLE_TOOLBAR_DELETE_COLUMNS = 'WREX_TABLE.TABLE_TOOLBAR_DELETE_COLUMNS',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=170053067
     */
    TABLE_TOOLBAR_DELETE_TABLE = 'WREX_TABLE.TABLE_TOOLBAR_DELETE_TABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=498329679
     */
    DELETE_TABLE_TITLE_CONFIRMATION = 'WREX_TABLE.DELETE_TABLE_TITLE_CONFIRMATION',
}
