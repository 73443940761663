export enum LUMX_LISTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428217013
     */
    DRAG_STARTING = 'LUMX.DRAGGABLE.DRAG_STARTING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428218332
     */
    DRAG_UPDATES = 'LUMX.DRAGGABLE.DRAG_UPDATES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428218351
     */
    DRAG_CANCELED = 'LUMX.DRAGGABLE.DRAG_CANCELED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=428218442
     */
    DRAG_ENDED = 'LUMX.DRAGGABLE.DRAG_ENDED',
}
