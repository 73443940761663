import { useMemo } from 'react';

import { mdiXml } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlate } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { INLINE_CODE } from '../../../constants';
import { WREX_TYPOGRAPHY } from '../../../keys';
import { TypographyEditor } from '../../../types';

export const useInlineCodeButton = (otherProps?: any) => {
    const editor = useSlate() as ReactEditor & TypographyEditor;

    const isCurrentNodeAnInlineCode = editor.getInlineType() === INLINE_CODE;
    const { translateKey } = useTranslate();

    const isSelected = isCurrentNodeAnInlineCode;
    const isDisabled = !editor.isInlineAllowed() && !isCurrentNodeAnInlineCode;

    const inlineCodeButton = useMemo(() => {
        const onClick = () => {
            if (!editor.selection) {
                initSelection(editor, 'inline');
            }
            editor.toggleCode();
            focusAt(editor, editor.selection);
        };

        const inlineCodeButtonProps: ToolbarItem = {
            type: 'toggle-option',
            itemKey: INLINE_CODE,
            icon: mdiXml,
            tooltipLabel: translateKey(WREX_TYPOGRAPHY.INLINE_CODE_BUTTON),
            onClick,
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            verticalModeProps: {
                label: translateKey(WREX_TYPOGRAPHY.INLINE_CODE_BUTTON),
                tooltipLabel: undefined,
            },
            otherProps,
        };
        return inlineCodeButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, isSelected, translateKey, otherProps?.isDisabled]);

    return inlineCodeButton;
};
