import React from 'react';

import pick from 'lodash/pick';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiTranslate } from '@lumapps/lumx/icons';
import { TextField, TextFieldProps, Size, Text, Emphasis, Icon, Button, FlexBox } from '@lumapps/lumx/react';
import {
    GLOBAL,
    TranslatableObject,
    translateFromApiV2Format,
    TranslateObject,
    translateToApiV2Format,
    useTranslate,
} from '@lumapps/translations';

import { SCOPE } from '../../constants';
import { TranslationsDropdown, TranslationsDropdownProps } from '../TranslationsDropdown/TranslationsDropdown';

import './index.scss';

export interface TranslatableTextFieldProps {
    /** Language in which the main text field will be displayed in */
    defaultLanguage: string;
    /** key/value object where the key is the language and the value is the translation for that language */
    values: TranslateObject | TranslatableObject;
    /** key/value object where the key is the language and the value is the label for that language */
    labels?: Record<string, string>;
    /** list of languages ids ('es', 'en', 'fr') */
    languages: string[];
    /** callback to be executed if any text field is changed */
    onChange: (languages: Record<string, string> | TranslatableObject) => void;
    /** callback to be executed once OK button is clicked */
    onAccept?: () => void;
    /** callback to be executed when dropdown closes */
    onDropdownClose?: () => void;
    /** props to be passed down to the main text field */
    textField?: Partial<TextFieldProps>;
    /** Whether the field should get focused on mount */
    focusOnMount?: boolean;
    /** classname to append to the component */
    className?: string;
    /** classname to append to the translations dropdown */
    dropdownClassName?: TranslationsDropdownProps['className'];
    /** whether the text field is displayed in a modal */
    isInModal?: TranslationsDropdownProps['isInModal'];
    /** scope to be used for tracking purposes */
    scope?: string;
    /**
     * if set to true, this component will use the API V2 format for receiving and returning the
     * entered languages.
     * if useApiv2Format = false => { en: 'text', es: texto }
     * if useApiv2Format = true => { lang: 'en', value: 'text', translations: { es: 'texto' } }
     */
    useApiv2Format?: boolean;
}

const CLASSNAME = 'translatable-text-field';
/**
 * Component that displays a text field in a base language, and that on the right hand side, it displays
 * a button in order to enter translations for each of the provided languages
 *
 * @family Translations
 * @param TranslatableTextFieldProps
 * @returns TranslatableTextField
 */
const TranslatableTextField: React.FC<TranslatableTextFieldProps> = ({
    defaultLanguage,
    className,
    values = {},
    languages,
    onChange,
    onAccept,
    onDropdownClose,
    textField = {},
    labels,
    focusOnMount,
    dropdownClassName,
    isInModal = false,
    scope = SCOPE,
    useApiv2Format = false,
}) => {
    const textFieldRef: React.RefObject<HTMLDivElement> = React.useRef(null);
    const inputRef: React.RefObject<HTMLInputElement> = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    const translations =
        useApiv2Format && defaultLanguage
            ? translateFromApiV2Format(values as TranslatableObject)
            : (values as TranslateObject);
    const { get: getDataAttributes } = useDataAttributes(scope);

    // Focus the component if focusOnMount is set.
    React.useEffect(() => {
        if (inputRef && inputRef.current && focusOnMount) {
            inputRef.current.focus();
        }

        if (isInModal) {
            // Need timeout to focus after animation if in dialog
            setTimeout(() => {
                if (inputRef && inputRef.current && focusOnMount) {
                    inputRef.current.focus();
                }
            }, 0);
        }
    }, [focusOnMount, isInModal]);

    /**
     * We only want to display the languages that are not the default one on the dropdown, since the main text field
     * for this component will take care of that responsibility
     */
    const languagesForDropdown = React.useMemo(() => {
        if (!languages) {
            return [];
        }

        return languages.filter((lang) => lang !== defaultLanguage);
    }, [languages, defaultLanguage]);

    /**
     * We should only display the button to edit other languages if there are only languages
     * on the dropdown. languagesForDropdown will hold all languages expect the current language, which is managed
     * by the text field. This means that `languagesForDropdown.length > 0` it means that there is at least another
     * language that can be translated.
     */
    const shouldDisplayButton = languagesForDropdown.length > 0;

    /**
     * Compute additional information about the translation
     * to display a counter next to the translation icon
     * like {filledTranslationsNb}/{totalPossibleTranslations}
     */
    const totalPossibleTranslations = languages?.length || 0;
    const filledTranslationsNb = React.useMemo(
        () => Object.values(translations || {}).filter(Boolean).length,
        [translations],
    );

    /**
     * If the defaultLanguage is not present in the list of provided languages,
     * we will return null since this would be an inconsistent use case and we want
     * to prevent the developer that something is wrong, rather than render a dropdown
     * that provides a functionality that should not be provided.
     */
    if (!languages || (languages && languages.indexOf(defaultLanguage) === -1)) {
        return null;
    }

    /**
     * Callback to be executed once the translations button is clicked.
     */
    const toggleDropdown = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    /**
     * Callback to be executed once the dropdown is closed
     */
    const onCloseDropdown = () => {
        if (onDropdownClose) {
            onDropdownClose();
        }
        setIsOpen(false);
    };

    /** Callback to be executed once the translations dropdown changed */
    const onTranslationsChange = (trads: TranslateObject) => {
        onChange(useApiv2Format ? translateToApiV2Format(trads, defaultLanguage) : trads);
    };

    /** Callback to be executed once the default translation text field changes */
    const onDefaultTranslationChange = (value: string) => {
        const updatedValues: TranslateObject = {
            ...translations,
            [defaultLanguage]: value,
        };

        onChange(useApiv2Format ? translateToApiV2Format(updatedValues, defaultLanguage) : updatedValues);
    };

    /**
     * Callback to be executed once the OK button is clicked. It will trigger the onChange
     * callback and close the dropdown
     */
    const onTranslationsAccepted = () => {
        if (onAccept) {
            onAccept();
        }
        onCloseDropdown();
    };

    return (
        <>
            <TextField
                textFieldRef={textFieldRef}
                inputRef={inputRef}
                className={block({ 'with-button': shouldDisplayButton }, [className, element('text-field')])}
                value={translations[defaultLanguage] || ''}
                onChange={onDefaultTranslationChange}
                afterElement={
                    shouldDisplayButton ? (
                        <Button
                            className={element('icon')}
                            emphasis={Emphasis.medium}
                            size={Size.s}
                            onClick={toggleDropdown}
                            label={translateKey(GLOBAL.TRANSLATE)}
                            {...getDataAttributes({
                                element: 'dropdown-trigger',
                            })}
                        >
                            <FlexBox orientation="horizontal" hAlign="center">
                                <Icon icon={mdiTranslate} alt={translateKey(GLOBAL.TRANSLATE)} />
                                {filledTranslationsNb > 0 && (
                                    <Text
                                        as="span"
                                        {...getDataAttributes({
                                            element: 'translations-nb-indicator',
                                        })}
                                    >
                                        {filledTranslationsNb}/{totalPossibleTranslations}
                                    </Text>
                                )}
                            </FlexBox>
                        </Button>
                    ) : undefined
                }
                {...getDataAttributes({
                    element: 'text-field-default-language',
                })}
                {...textField}
            />
            {shouldDisplayButton && (
                <TranslationsDropdown
                    languages={languagesForDropdown}
                    onAccept={onTranslationsAccepted}
                    onChange={onTranslationsChange}
                    values={translations}
                    labels={labels}
                    onClose={onCloseDropdown}
                    isOpen={isOpen}
                    anchorRef={textFieldRef}
                    className={dropdownClassName}
                    isInModal={isInModal}
                    scope={scope}
                    textField={pick(textField, 'maxLength', 'minimumRows', 'multiline')}
                />
            )}
        </>
    );
};

export { TranslatableTextField };
