import React from 'react';
import { bool, func } from 'prop-types';

import { Button, Emphasis, ColorPalette } from '@lumapps/lumx/react';

import { translate as t } from 'components/translations';

const TermsAndConditionsSaveButton = ({ isLoading, saveTermsAndConditions }) => {
    return (
        <Button
            color={ColorPalette.primary}
            disabled={isLoading}
            emphasis={Emphasis.high}
            onClick={saveTermsAndConditions}
        >
            {t('GLOBAL.SAVE')}
        </Button>
    );
};

/////////////////////////////

TermsAndConditionsSaveButton.propTypes = {
    /** Wether the terms and conditions is loading. */
    isLoading: bool.isRequired,
    /** Save function. */
    saveTermsAndConditions: func.isRequired,
};

/////////////////////////////

export { TermsAndConditionsSaveButton };
