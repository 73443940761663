import { fixBackendMissingUTC } from '@lumapps/utils/date/fixBackendMissingUTC';

export function getTutorialToDisplay(moment, tutorials, tutorialLastSeenDates) {
    let tutorialIds = [];

    angular.forEach(tutorials, function forEachTutorial(tutorial) {
        if (!tutorialLastSeenDates[tutorial.id]) {
            tutorialIds.push(tutorial.id);
            return;
        }

        if (!tutorial.displayAgainTime) {
            return;
        }

        let displayAgainTime = moment(fixBackendMissingUTC(tutorial.displayAgainTime));
        let tutorialLastSeenDate = moment(fixBackendMissingUTC(tutorialLastSeenDates[tutorial.id]));

        if (displayAgainTime.isAfter(tutorialLastSeenDate)) {
            tutorialIds.push(tutorial.id);
        }
    });
    return tutorialIds;
}

(function IIFE() {
    'use strict';

    /////////////////////////////

    function TutorialService(Customer, Instance, LumsitesBaseService, LxNotificationService,
        Translation, TutorialFactory, User, UserSettings) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(TutorialFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The current tutorial to be displayed for the current user.
         *
         * @type {Tutorial}
         */
        service.currentTutorial = undefined;

        /**
         * The default parameters for the service requests.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /**
         * Contains the iterator through the list of tutorials.
         *
         * @type {Object}
         */
        service.tutorialIterator = undefined;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Build an iterator of tutorial ids.
         *
         * @param  {Array}  tutorialIds A list of tutorial identifiers.
         * @return {Object} An object with a next method that simulates an iterator.
         */
        function _getTutorialIdIterator(tutorialIds) {
            var nextIndex = 0;

            return {
                hasNext: function hasNext() {
                    return nextIndex < tutorialIds.length;
                },
                next: function next() {
                    var tutorial;

                    if (nextIndex < tutorialIds.length) {
                        tutorial = {
                            done: false,
                            value: tutorialIds[nextIndex++],
                        };
                    } else {
                        tutorial = {
                            done: true,
                        };
                    }

                    return tutorial;
                },
            };
        }

        /**
         * Display tutorial.
         *
         * @param {Object} response The response of the fetch.
         */
        function _displayTutorial(response) {
            let user = User.getConnected();
            let instanceId = Instance.getCurrentInstanceId();
            let tutorialLastSeenDates = {};

            if (angular.isDefinedAndFilled(user.tutorials)) {
                tutorialLastSeenDates = user.tutorials[instanceId] || {};
            }

            let tutorialIds = getTutorialToDisplay(moment, response, tutorialLastSeenDates);

            service.tutorialIterator = _getTutorialIdIterator(tutorialIds);
            service.displayNextTutorial();
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the next tutorial id available (if any). Fetch it and display it.
         */
        function displayNextTutorial() {
            var tutorial = service.tutorialIterator.next();

            if (tutorial.done) {
                service.currentTutorial = undefined;

                return;
            }

            service.get(tutorial.value,
                function onTutorialGetSuccess(response) {
                    if (angular.isUndefinedOrEmpty(response.slides)) {
                        service.displayNextTutorial();

                        return;
                    }

                    service.currentTutorial = response;
                }, function onTutorialGetError() {
                    LxNotificationService.error(Translation.translate('TUTORIAL_DISPLAY_ERROR_INVALID_TUTORIAL'));
                });
        }

        /**
         * Executed when a tutorial is closed.
         *
         * @param {string}   tutorialId The identifier of the tutorial to save.
         * @param {Function} [cb]       A function to execute after marking the tutorial as read.
         */
        function onTutorialSave(tutorialId, cb) {
            if (angular.isUndefinedOrEmpty(tutorialId)) {
                return;
            }

            cb = cb || angular.noop;

            var user = User.getConnected();
            var settings = {
                instance: Instance.getCurrentInstanceId(),
                tutorialId: tutorialId,
            };

            UserSettings.save({
                tutorials: settings,
                settings: user.settings
            }, cb, function onUserSettingsSaveError() {
                LxNotificationService.error(Translation.translate('TUTORIAL_DISPLAY_ERROR_UNABLE_TO_SAVE_SETTINGS'));
            });
        }

        /**
         * Open a given tutorial.
         *
         * @param {string} functionalInnerId The identifier of the tutorial to open.
         *
         * Todo [Arnaud]: this seems to be used on manpower only? should it even be here?
         */
        function openTutorial(functionalInnerId) {
            if (angular.isUndefinedOrEmpty(functionalInnerId)) {
                return;
            }

            var matchingTutorial = _.find(service.getList(), {
                functionalInnerId: functionalInnerId,
            });

            if (angular.isUndefinedOrEmpty(matchingTutorial)) {
                return;
            }

            service.currentTutorial = matchingTutorial;
        }

        /////////////////////////////

        service.displayNextTutorial = displayNextTutorial;
        service.onTutorialSave = onTutorialSave;
        service.openTutorial = openTutorial;

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        service.init = function init() {
            var instanceId = Instance.getCurrentInstanceId();

            service.defaultParams = {
                customer: Customer.getCustomerId(),
                instance: instanceId,
            };

            if (!User.isConnected()) {
                return;
            }

            service.filterize({
                instance: instanceId,
            }, function onTutorialListSuccess(response) {
                if (angular.isUndefinedOrEmpty(response)) {
                    return;
                }

                
                _displayTutorial(response);
            });
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Tutorial', TutorialService);
})();
