import { useHistory } from 'react-router-dom';

import { get as getConstants } from '@lumapps/constants';

import { Route } from '../types';

const constants = getConstants();

/**
 * Returns the previous path stored in the react router history state.
 */
export const usePreviousPath = () => {
    const history = useHistory<Route['state']>();
    const { previousPath } = history?.location?.state || {};

    if (constants.isLegacyContext) {
        return undefined;
    }

    return previousPath;
};
