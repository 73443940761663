(function IIFE() {
    'use strict';

    /////////////////////////////

    function DriveFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/drive/:methodKey', {}, {
            getToken: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'getToken',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('DriveFactory', DriveFactory);
})();
