import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import {
    Divider,
    Size,
    SkeletonTypography,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    Typography,
} from '@lumapps/lumx/react';

import { CLASSNAME } from '../../constants';
import { SurveySkeletonProps } from '../../types';

import './index.scss';

export const SurveySkeleton: React.FC<SurveySkeletonProps> = ({ isAnswersCountHidden, theme }) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <div className={block()}>
            {!isAnswersCountHidden && (
                <>
                    <SkeletonTypography
                        typography={Typography.body1}
                        className={element('answers-count')}
                        width="20%"
                        theme={theme}
                    />
                    <Divider />
                </>
            )}
            <SkeletonTypography
                typography={Typography.subtitle2}
                className={element('question')}
                width="80%"
                theme={theme}
            />
            <SkeletonTypography typography={Typography.body1} width="40%" className={element('choice')} theme={theme} />
            <SkeletonTypography typography={Typography.body1} width="50%" className={element('choice')} theme={theme} />
            <SkeletonTypography typography={Typography.body1} width="40%" className={element('choice')} theme={theme} />
            <Divider theme={theme} />
            <SkeletonRectangle
                width={Size.xl}
                height={Size.m}
                variant={SkeletonRectangleVariant.rounded}
                className={element('submit')}
                theme={theme}
            />
        </div>
    );
};
