/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

const LazyComments = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'widget-comments-block-comments-v2' */
            './Comments'
        ),
);

export const Comments = (props: any) => (
    <Suspense loadingFallback={<WidgetSkeletonRectangle style={{ height: '84px' }} useDefaultHeight={false} />}>
        <LazyComments {...props} />
    </Suspense>
);
