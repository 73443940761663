(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetAwesomeTableController($scope, $window) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Remove $window awesome table (AWT) attribut for script AWT execute again.
         */
        function _clearAWTWindow() {
            if ('AwesomeTableViewInclude' in $window) {
                delete $window.AwesomeTableViewInclude;
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get widget class.
         *
         * @return {Array} Widget classes.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            return widgetClass;
        }

        /**
         * The widget is empty in designer mode if we don't have an url for the current language.
         * The widget is empty in read mode if we don't have any url.
         *
         * @return {boolean} Is widget empty.
         */
        function isWidgetEmpty() {
            var url = _.get(vm.widget, 'properties.embedUrl');

            return angular.isUndefinedOrEmpty(vm.parentCtrl.getWidgetTranslation(url));
        }

        /**
         * The widget is never hidden in designer mode.
         * The widget is hidden in read mode if we don't have any viewId set and any no result message.
         *
         * @return {boolean} Is widget hidden.
         */
        function isWidgetHidden() {
            vm.parentCtrl.isHidden = !vm.parentCtrl.designerMode() && !vm.widget.properties.noResults &&
                vm.isWidgetEmpty();

            return vm.parentCtrl.isHidden;
        }

        /////////////////////////////

        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * Watch widget setting update.
         *
         * @param {Event}  evt        The original event trigger the widget update.
         * @param {string} widgetUuid The unique identifier of the widget that needs updating.
         */
        $scope.$on('widget-awesome-table-settings', function onWidgetSettingsChange(evt, widgetUuid) {
            if (vm.widget.uuid !== widgetUuid) {
                return;
            }
            vm.init();
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            if (angular.isUndefinedOrEmpty(vm.widget.properties.embedUrl)) {
                return;
            }

            var urlSplit = vm.widget.properties.embedUrl.split('/');

            if (urlSplit.length < 2) {
                return;
            }

            vm.viewId = urlSplit[urlSplit.length - 2];
        };

        /**
         * Set parent controller.
         *
         * @param {Object} parentCtrl Parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;

            _clearAWTWindow();

            vm.init();
        };
    }

    /////////////////////////////

    function WidgetAwesomeTableDirective() {
        'ngInject';

        function WidgetAwesomeTableLink(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetAwesomeTableController,
            controllerAs: 'vm',
            link: WidgetAwesomeTableLink,
            replace: true,
            require: ['widgetAwesomeTable', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-awesome-table/views/widget-awesome-table.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetAwesomeTable', WidgetAwesomeTableDirective);
})();
