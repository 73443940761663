/* istanbul ignore file */
import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';

const LazyLegacyContributionField = React.lazy(() =>
    /* webpackChunkName: 'legacy-post-list-contribution-field' */
    import('./LegacyContributionField'),
);

export const LegacyContributionField = (props) => (
    <Suspense>
        <LazyLegacyContributionField {...props} />
    </Suspense>
);
