(function IIFE() {
    'use strict';

    /////////////////////////////

    function ColorBubbleController(ConfigTheme) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Get a random color from the colors list.
         * The color is not really random, it's based on the length of the text or icon.
         *
         * Note: it means that for the exact same text, you'll get the exact same colour twice.
         *
         * @return {string} The color.
         */
        function _getRandomColor() {
            var hashCode = Math.abs((vm.image || vm.icon || vm.text || '').hashCode());

            return vm.colors[hashCode % vm.colors.length] || vm.colors[0];
        }

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.colors = vm.colors || ConfigTheme.COLORS_WIDGET;
            vm.colors = _.without(vm.colors, 'transparent');

            vm.color = vm.color || _getRandomColor();
            if (!vm.allowCustomColor && !_.includes(vm.colors, vm.color)) {
                vm.color = _getRandomColor();
            }

            vm.maxLength = (angular.isUndefined(vm.maxLength)) ? 1 : vm.maxLength;
            vm.size = vm.size || 'm';
            vm.type = vm.type || 'round';
        }

        init();
    }

    /**
     * Color Bubble directive.
     * Display a bubble with a background color and an icon or a text inside of it.
     *
     * @param {boolean} [allowCustomColor=false]      Indicates if we want to allow a background color that is not in
     *                                                the (given or computed) color list.
     * @param {string}  [color=<random>]              The color to use as the background.
     *                                                If no color is given, then a random color will be picked in the
     *                                                (given or computed) color list based on the lenght of the text (or
     *                                                the length of the icon name).
     *                                                Note that you can pass a color that doesn't belong to the (given
     *                                                or computed) color list if you pass the `allowCustomColor`
     *                                                parameter to `true`. Else, if the color cannot be found in the
     *                                                list, then a random one will be picked.
     * @param {Array}   [colors=<instance's palette>] The list of colors in which to pick the color (if none given).
     *                                                If no list is given, defaults to the instance's style color
     *                                                palette.
     * @param {string}  [icon]                        The icon to display in the bubble.
     *                                                The icon will be displayed only if `text` is not given. If `text`
     *                                                and `icon` are both given, then `text` will have priority.
     * @param {number}  [maxLength=1]                 When using `text`, define the number of letter of the text to show
     *                                                in the bubble.
     * @param {string}  [size='m']                    Define the size of the color bubble. Can be 's', 'l' or 'm'.
     *                                                By default, only show the first letter of the text.
     * @param {string}  [text]                        The text to be displayed in the bubble.
     *                                                In fact, only a part of this text will be displayed (based on the
     *                                                `maxLength` parameter).
     *                                                `text` takes precedence over `icon`. If both are given, then
     *                                                `icon` will not be displayed and `text` will be used instead.
     * @param {string}  [type='round']                The type of color bubble. By default, it is `round` but can be
     *                                                `roundedSquare`.
     */

    function ColorBubbleDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: ColorBubbleController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                allowCustomColor: '<?lsAllowCustomColor',
                color: '<?lsColor',
                colors: '<?lsColors',
                icon: '@?lsIcon',
                maxLength: '<?lsMaxLength',
                size: '@?lsSize',
                text: '@?lsText',
                type: '@?lsType',
            },
            templateUrl: '/client/common/modules/color-bubble/views/color-bubble.html',
            transclude: true,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsColorBubble', ColorBubbleDirective);
})();
