import { ContentContextualActions } from '@lumapps/contents/components/ContentContextualActions';

import React from 'react';
import { RouterWrapper } from './router-wrapper';

export const FrontOfficeContentContextualActions = ({ current, params, ...props }) => {
    return (
        <RouterWrapper current={current} params={params}>
            <ContentContextualActions {...props} />
        </RouterWrapper>
    );
};

export { FrontOfficeContentContextualActions as ContentContextualActions };
