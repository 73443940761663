import React, { ReactElement } from 'react';

import last from 'lodash/last';

import { Theme } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_BASE } from '../../keys';
import {
    Block,
    Blocks,
    isArrayContainerBlock,
    isBlockClientComputed,
    isResourceContainerBlock,
    isSingleContainerBlock,
    Widget,
} from '../../types';
import { type ClientComputedWidgetTypes } from '../../types/client-computed';
import { BlockNoResults } from './BlockNoResults';
import { BLOCK_RENDERERS } from './blocks';
import { CLIENT_COMPUTED_RENDERERS } from './client-computed';

/**
 * Recursively render blocks.
 */
export function renderBlock(path: Required<Block>['path'], block: Blocks, widget: Widget) {
    const Renderer: React.FC<any> | undefined = block.type && (BLOCK_RENDERERS as any)[block.type];
    const { theme } = block;
    if (!Renderer) {
        return null;
    }
    const lastPath = last(path);

    if (isArrayContainerBlock(block)) {
        const { items, ...container } = block;
        return (
            <Renderer key={lastPath} path={path} {...container}>
                {(items as Blocks[]).map((item, index) =>
                    renderBlock([...path, 'items', index.toString()], { ...item, theme }, widget),
                )}
            </Renderer>
        );
    }

    if (isSingleContainerBlock(block)) {
        return (
            <Renderer key={lastPath} path={path} widget={widget} legacyWidget={widget.legacyWidget} {...block}>
                {renderBlock([...path, 'container'], { ...block.container, theme }, widget)}
            </Renderer>
        );
    }

    if (isResourceContainerBlock(block)) {
        const { resourceType, ...container } = block;
        const resourceBlock = container[resourceType];
        // If the resource container does not have the matching resource block, don't render the block.
        if (!resourceBlock) {
            return null;
        }
        return (
            <Renderer key={lastPath} path={path} {...block}>
                {renderBlock([...path, 'resource'], { ...resourceBlock, theme }, widget)}
            </Renderer>
        );
    }

    return <Renderer key={lastPath} path={path} {...block} widget={widget} />;
}

const NotMigratedMessage: React.FC<{ theme?: Theme }> = ({ theme }) => {
    const { translateKey } = useTranslate();
    return <BlockNoResults theme={theme} placeholderText={translateKey(WIDGET_BASE.NOT_MIGRATED)} />;
};

/**
 * Render widget content block tree.
 */
export function renderBlockTree(widget: Widget): ReactElement | null {
    const { widgetId, body } = widget;
    const { theme } = body?.style || {};

    if (!widgetId || !body) {
        return null;
    }

    if (isBlockClientComputed(body)) {
        const Renderer = CLIENT_COMPUTED_RENDERERS[body.widgetType as ClientComputedWidgetTypes];

        if (Renderer) {
            return (
                <Renderer
                    properties={body.properties}
                    uuid={widget.widgetId}
                    widgetType={widget.widgetType}
                    theme={theme}
                    legacyWidgetId={body.legacyWidgetId}
                    isMainWidget={body.isMainWidget}
                />
            );
        }

        // Client computed not migrated => display not migrated error.
        return <NotMigratedMessage theme={theme} />;
    }

    return renderBlock([widgetId, 'body'], { ...body, theme }, widget);
}
