import every from 'lodash/every';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import { omitBy } from 'lodash/object';
import isUndefined from 'lodash/isUndefined';

import { sanitizeUrl } from '@lumapps/router/utils/sanitizeUrl';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import { getDirectoryEntryImage } from '@lumapps/directory-entries/utils';

// ///////////////////////////

function DirectoryEntryAdminController(
    $rootScope,
    $scope,
    $stateParams,
    Config,
    ConfigTheme,
    Directory,
    DirectoryEntry,
    DirectoryEntryUser,
    Feed,
    FormValidation,
    LxDialogService,
    LxNotificationService,
    Metadata,
    Translation,
    UserFavorite,
    Utils,
) {
    'ngInject';

    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The list key to get the list of contents (library mode).
     *
     * @type {string}
     * @constant
     */
    let _DIRECTORY_ENTRY_LIST_KEY = 'directory-entry-';

    /**
     * The current Directory we are working on.
     *
     * @type {Object}
     */
    let _currentDirectory;
    /**
     * The current Directory Entry we are working on.
     *
     * @type {Object}
     */
    let _currentDirectoryEntry;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The entry dialog id.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.ENTRY_DIALOG_ID = 'directory-entry-settings';

    /**
     * The empty form object used by the template.
     *
     * @type {Object}
     */
    vm.form = {};

    vm.isOutsideAdmin = undefined;

    /**
     * Set to true when ready to display the feed selector.
     *
     * @type {boolean}
     */
    vm.feedsInitialized = false;

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Directory = Directory;
    vm.DirectoryEntry = DirectoryEntry;
    vm.FormValidation = FormValidation;
    vm.Metadata = Metadata;
    vm.Translation = Translation;
    vm.Utils = Utils;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Check validity of mandatory fields for the entry.
     *
     * @return {boolean} Whether the component is valid.
     */
    function _checkTemplateComponentsValidity() {
        const components = get(_currentDirectory, 'template.components', {});

        if (angular.isUndefinedOrEmpty(components)) {
            return true;
        }

        return every(components, function forEveryComponent(component) {
            return vm.isFieldValid(component, true);
        });
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Defines the field validity.
     *
     * @param  {Object}  field                  The field we want to check validity.
     * @param  {bool}    [bypassPristine=false] Whether we want to bypass pristine check.
     * @return {boolean} Whether the field is valid.
     */
    function isFieldValid(field, bypassPristine) {
        bypassPristine = Boolean(bypassPristine);
        const directoryEntryForm = vm.form.directoryEntrySettings || {};

        if (angular.isUndefinedOrEmpty(directoryEntryForm[field.uuid])) {
            return false;
        }

        const properties = field.properties || {};

        const isMandatory = Boolean(properties.mandatory);

        if (!isMandatory) {
            return true;
        }

        const isPristine = directoryEntryForm[field.uuid].$pristine;

        if (isPristine && !bypassPristine) {
            return true;
        }

        const currentDirectoryFieldValue = _currentDirectoryEntry.values[field.uuid];

        switch (field.type) {
            case 'inputSelect':
                return angular.isDefinedAndFilled(currentDirectoryFieldValue);

            case 'inputText':
            // eslint-disable-next-line no-fallthrough, padding-line-between-statements
            default:
                return Translation.hasTranslations(currentDirectoryFieldValue);
        }
    }

    function sanitizeLinks() {
        const { link } = _currentDirectoryEntry;

        _currentDirectoryEntry.link = link && omitBy(mapValues(link, (value) => sanitizeUrl(value)), isUndefined);
    }

    /**
     * Save an entry.
     */
    function saveEntry() {
        const directoryEntryForm = vm.form.directoryEntrySettings || {};

        if (
            directoryEntryForm.$invalid ||
            !Translation.hasTranslations(directoryEntryForm.name) ||
            !_checkTemplateComponentsValidity()
        ) {
            if (angular.isDefinedAndFilled(directoryEntryForm)) {
                FormValidation.setFormDirty(directoryEntryForm);
            }

            LxNotificationService.error(Translation.translate('ERROR_MANDATORY_FIELDS'));

            return;
        }

        sanitizeLinks();

        if (!vm.isOutsideAdmin) {
            DirectoryEntry.save(
                _currentDirectoryEntry,
                function onDirectoryEntrySaveSuccess() {
                    LxNotificationService.success(Translation.translate('ADMIN_DIRECTORY_ENTRY_SAVE_SUCCESS'));
                    LxDialogService.close(vm.ENTRY_DIALOG_ID);
                },
                Utils.displayServerError,
            );

            return;
        }

        DirectoryEntryUser.save(
            _currentDirectoryEntry,
            function onDirectoryEntryUserSaveSuccess(response) {
                response.uid = response.uid || response.id;

                if (
                    Directory.getCurrent().favorites &&
                    angular.isUndefinedOrEmpty(_currentDirectoryEntry.id) &&
                    !UserFavorite.isFavorite(response)
                ) {
                    UserFavorite.toggleFavorite(response);
                    response.markedAsFavorite = true;
                }

                // Update entry fields, like the field: isToggable.
                response = DirectoryEntry.updateEntryStatus(response);

                const directoryEntries =
                    get($scope.$parent, 'vm.parentCtrl.directoryEntries') ||
                    DirectoryEntry.displayList(_DIRECTORY_ENTRY_LIST_KEY);

                if (angular.isUndefined(directoryEntries)) {
                    return;
                }

                const existingEntryIndex = findIndex(directoryEntries, {
                    id: response.id,
                });

                if (existingEntryIndex === -1) {
                    directoryEntries.push(response);
                } else {
                    angular.extend(directoryEntries[existingEntryIndex], response);
                }

                // Usefull to update directory entry block.
                $rootScope.$broadcast(`directory-entry-save-${response.id}`);

                LxNotificationService.success(Translation.translate('ADMIN_DIRECTORY_ENTRY_SAVE_SUCCESS'));
                LxDialogService.close(vm.ENTRY_DIALOG_ID);
            },
            Utils.displayServerError,
        );
    }

    /**
     * Set uploaded file as directory entry thumbnail.
     *
     * @param {Object} file The file to set as thumbnail.
     */
    function setUploadThumbnail(file) {
        // We can't check files with angular.isDefineAndFilled.
        if (file) {
            _currentDirectoryEntry.thumbnail = file.id;
        } else {
            _currentDirectoryEntry.thumbnail = undefined;
        }
    }

    function getDirectoryEntryDocument() {
        return getDirectoryEntryImage(_currentDirectoryEntry);
    }

    // ///////////////////////////

    vm.isFieldValid = isFieldValid;
    vm.saveEntry = saveEntry;
    vm.setUploadThumbnail = setUploadThumbnail;
    vm.getDirectoryEntryDocument = getDirectoryEntryDocument;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    /**
     * When the input language changes, reset the form to its pristine state.
     */
    $scope.$on('inputLanguage', function onInputLanguageChange() {
        if (angular.isDefined(vm.form.directoryEntrySettings)) {
            vm.form.directoryEntrySettings.$setPristine();
        }
    });

    /**
     * When the dialog opens, re-initialize the controller.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId The identifier of the dialog triggering this method.
     */
    $scope.$on('lx-dialog__open-start', function onEntryDialogOpenStart(evt, dialogId) {
        if (vm.ENTRY_DIALOG_ID === dialogId) {
            vm.init();
        }
    });

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    vm.init = function init() {
        _currentDirectory = Directory.getCurrent();
        _currentDirectoryEntry = DirectoryEntry.getCurrent();

        if (angular.isUndefinedOrEmpty([_currentDirectory, _currentDirectoryEntry], 'some')) {
            return;
        }

        if (!_currentDirectoryEntry.isInFavoriteFeedKeys || _currentDirectoryEntry.isInFavoriteFeedKeys.length === 0) {
            vm.feedsInitialized = true;
        } else {
            Feed.getMulti(_currentDirectoryEntry.isInFavoriteFeedKeys, _currentDirectoryEntry).then(() => {
                vm.feedsInitialized = true;
            });
        }
        if (!_currentDirectoryEntry.feedKeys || _currentDirectoryEntry.feedKeys.length === 0) {
            vm.feedsInitialized = true;
        } else {
            Feed.getMulti(_currentDirectoryEntry.feedKeys, _currentDirectoryEntry).then(() => {
                vm.feedsInitialized = true;
            });
        }


        vm.isOutsideAdmin = angular.isDefinedAndFilled($stateParams.slug);
        _DIRECTORY_ENTRY_LIST_KEY += _currentDirectory.id;
    };

    vm.init();
}

angular.module('Controllers').controller('DirectoryEntryAdminController', DirectoryEntryAdminController);

// ///////////////////////////

export { DirectoryEntryAdminController };
