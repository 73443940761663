(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetProcessMakerSettingsController($rootScope, $timeout, Translation, Widget) {
        'ngInject';

        var widgetProcessMakerSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The delay for the execution of the widget update.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _DEBOUNCE_DELAY = 500;

        /**
         * The path of the process maker plugin, used to build the ProcessMaker instance url.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        var _PROCESSMAKER_PLUGIN_PATH = '/bpm3PluginLumApps/index.php/material/';

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The current sidebar form.
         *
         * @type {Object}
         */
        widgetProcessMakerSettings.form = {};

        /**
         * The list of ProcessMaker views available.
         *
         * @type {Array}
         */
        widgetProcessMakerSettings.listViews = [{
            name: 'FRONT.PROCESS_MAKER.VIEW.INBOX',
            value: 'cases/inbox',
        },
        {
            name: 'FRONT.PROCESS_MAKER.VIEW.START_CASE',
            value: 'process/start',
        }];

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Update Url of ProcessMaker instance for the iframe.
         */
        function _updateUrl() {
            var widget = Widget.getCurrent();
            widget.properties = widget.properties || {};
            var properties = widget.properties;

            // Remove protocol from server ip address.
            properties.serverIP = properties.serverIP.replace(/^(http:\/\/|https:\/\/)/, '');

            if (angular.isUndefinedOrEmpty([properties.serverIP, properties.workspace, properties.language,
                properties.skin, properties.view], 'some')) {
                properties.url = {};
                properties.embedUrl = undefined;

                return;
            }

            var url = 'https://' + properties.serverIP + '/sys' + properties.workspace + '/' + properties.language +
                '/' + properties.skin + _PROCESSMAKER_PLUGIN_PATH + properties.view.value;

            properties.url = {};
            properties.url[Translation.inputLanguage] = url;
            properties.embedUrl = url;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Updates process maker iframe when the widget settings change.
         */
        function updateWidget() {
            _updateUrl();

            $timeout(function timedOutFilePreviewSettingsBroadcast() {
                $rootScope.$broadcast('widget-iframe-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetProcessMakerSettings.updateWidget = updateWidget;
        widgetProcessMakerSettings.debouncedUpdateWidget = _.debounce(updateWidget, _DEBOUNCE_DELAY);

        /////////////////////////////

        /**
        * Initialize the controller.
        */
        function init() {
            var widget = Widget.getCurrent();
            widget.properties = widget.properties || {};
            var properties = widget.properties;

            angular.extend(properties, {
                language: properties.language || 'en',
                skin: properties.skin || 'neoclassic',
                workspace: properties.workspace || 'workflow',
            });
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetProcessMakerSettingsController',
        WidgetProcessMakerSettingsController);
})();
