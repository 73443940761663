import React, { forwardRef } from 'react';

import { cache, CACHE_TYPE } from '@lumapps/cache';
import { useContent } from '@lumapps/contents/hooks/useContent';

import { WIDGET_SKELETON_CACHE_KEY } from '../../constants';
import { WidgetSkeletonRectangle } from './WidgetSkeletonRectangle';

import './index.scss';

interface WidgetSkeletonProps {
    /** An HTML id (from the designer) to add to the skeleton wrapping element. */
    htmlId?: string;
    /** The widget type. */
    widgetType?: string;
    /** The widget technical identifier in layout. */
    widgetId?: string;
}

export const WidgetSkeleton = forwardRef<HTMLDivElement, WidgetSkeletonProps>((props, ref) => {
    const { pageId } = useContent();
    const { htmlId, widgetType, widgetId } = props;
    /**
     * We search in local storage if there was already a calculated height for the current widget.
     * If there is, we just use it instead of the default height so that the skeleton is more faithful to the final widget
     */
    const widgetSkeletonsCache = widgetId ? cache.retrieve(WIDGET_SKELETON_CACHE_KEY, CACHE_TYPE.STORAGE) : {};

    const height =
        widgetSkeletonsCache &&
        widgetId &&
        pageId &&
        widgetSkeletonsCache[pageId] &&
        widgetSkeletonsCache[pageId][widgetId]
            ? widgetSkeletonsCache[pageId][widgetId]
            : undefined;

    switch (widgetType) {
        /**
         * For now, we return a standard skeleton with a fixed height because we don't know the display mode.
         * Once the backend will return the display mode, we will be able to return better skeletons
         *     based on the widget type (content-list, community-list, ...) which will used the display
         *     mode (list, grid, slideshow) to adapt the skeleton as following:
         *
         * case 'community-list':
         *     return <CommunityListSkeleton mode={widgetDisplayMode} />
         */

        default:
            return (
                <WidgetSkeletonRectangle
                    htmlId={htmlId}
                    ref={ref}
                    style={{ height, minHeight: height ? 'unset' : 400 }}
                />
            );
    }
});
WidgetSkeleton.displayName = 'WidgetSkeleton';
