import React from 'react';

import { margin } from '@lumapps/classnames';
import { SkeletonRectangle } from '@lumapps/lumx/react';

export const SkeletonSocialProfile: React.FC = () => {
    return (
        <>
            <SkeletonRectangle className={margin('bottom', 'big')} style={{ width: '100%', height: '100px' }} />
            <SkeletonRectangle className={margin('bottom', 'big')} style={{ width: '100%', height: '100px' }} />
            <SkeletonRectangle className={margin('bottom', 'big')} style={{ width: '100%', height: '100px' }} />
        </>
    );
};
