(function IIFE() {
    'use strict';

    /////////////////////////////

    function DrivePickerService(DriveFactory) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The last time the token was refreshed.
         *
         * @type {number}
         */
        var _lastRefreshDate;

        /**
         * The current token to access drive.
         *
         * @type {string}
         */
        var _oauthToken;

        /**
         * The rate at which we need to refresh the token (in ms).
         *
         * @type {number}
         */
        var _tokenRefreshRate;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if we should keep trying to fetch a new token or not.
         *
         * @type {boolean}
         */
        service.alwaysFetchToken = true;

        /**
         * Indicates if the user is currently picking some files in the picker or not.
         *
         * @type {boolean}
         */
        service.isPicking = false;

        /**
         * Indicates if the token for the drive picker is currently loading or not.
         *
         * @type {boolean}
         */
        service.isTokenLoading = false;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Try to get a token to authenticate the user to drive.
         *
         * @param {Function} [cb]    A function to execute when the token is retrieved successfully.
         * @param {Function} [errCb] A function to execute if the token retrieval fails.
         */
        function _generateToken(cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            DriveFactory.getToken(undefined, function onGetTokenSuccess(response) {
                _oauthToken = response.accessToken;

                _lastRefreshDate = Date.now();

                // Set refresh rate minus 5% just to be sure.
                _tokenRefreshRate = response.expiresIn * 0.95;

                cb(_oauthToken);
            }, errCb);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the oauth token.
         *
         * @param {Function} [cb]    A function to execute when the token is retrieved successfully.
         * @param {Function} [errCb] A function to execute if the token retrieval fails.
         */
        function getToken(cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            if (service.isTokenLoading) {
                return;
            }

            service.isTokenLoading = true;
            var isTokenOutdated = Math.floor((Date.now() - _lastRefreshDate) / 1000) >= _tokenRefreshRate;

            if ((angular.isUndefined([_lastRefreshDate, _tokenRefreshRate, _oauthToken], 'some') || isTokenOutdated) &&
                service.alwaysFetchToken) {
                _generateToken(function onGenerateTokenSuccess(response) {
                    service.isTokenLoading = false;
                    cb(response);
                }, function onGenerateTokenError(error) {
                    service.isTokenLoading = false;
                    errCb(error);
                });
            } else {
                service.isTokenLoading = false;

                cb(_oauthToken, service.alwaysFetchToken);
            }
        }

        /**
         * Set isPicking to a new value.
         *
         * @param {boolean} status The new status value of isPicking.
         */
        function setIsPicking(status) {
            service.isPicking = Boolean(status);
        }

        /////////////////////////////

        service.getToken = getToken;
        service.setIsPicking = setIsPicking;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('DrivePicker', DrivePickerService);
})();
