import { communityHomeRoute } from '@lumapps/communities/routes';
import { Route } from '@lumapps/router';

import { EDIT_COMMUNITY_DIALOG_EVENT } from './constants';

export const communitySettingsRoute: Route = {
    ...communityHomeRoute,
    anchor: EDIT_COMMUNITY_DIALOG_EVENT,
    legacyForceReload: true,
};

export const communitySettings = (slug: string): Route => {
    return {
        ...communitySettingsRoute,
        params: {
            slug,
        },
    };
};
