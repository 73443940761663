/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { get } from '@lumapps/constants';

import { Library, STATUS, useInjectLibrary, UseInjectLibraryOptions } from './useInjectLibrary';

const constants = get();

/**
 * We are declaring these window variables here rather than in lumapps constants since these
 * are values that we want to have associated to the libs utility rather than the constant ones.
 *
 * We also do not want to have these types exposed on the lumapps/constants package, they should not be reused
 * anywhere on the appliaction.
 */
declare global {
    interface Window {
        CodeMirror: any;
    }
}

/**
 * List of available libraries that we currently have.
 */
export enum THIRD_PARTY_LIBRARIES {
    /**
     * Code mirror is used for displaying text areas with code syntax highlighting, mainly used
     * for editing code CSS and HTML on the back office
     */
    CODE_MIRROR = 'codemirror',
    /**
     * Code mirror v5 is still used in the froala editor, we need to keep it for now
     */
    CODE_MIRROR_V5 = 'codemirror-v5',
}

/**
 * Libraries can have multiple versions on the CDN, so we have a Record were we store the lastest version
 * of the library. We should always use the latest one and disallow choosing a specific version.
 *
 * Also, in order to avoid downloading a library multiple times during a session, we create a simple object
 * that we will update when a library is loaded. We could argue that since libraries are loaded from a CDN,
 * downloading them multiple times won't have any impact since they will be served in cache, but it could happen
 * that this utility is called multiple times in a small time window, triggering multiple downloads since the
 * lib is not yet in cache. Just to be safe, we add this flag.
 */
const libraries: Record<THIRD_PARTY_LIBRARIES, { name: string; version: string; status: STATUS }> = {
    [THIRD_PARTY_LIBRARIES.CODE_MIRROR]: {
        name: 'codemirror',
        version: 'v6.0.1',
        status: STATUS.INITIAL,
    },
    [THIRD_PARTY_LIBRARIES.CODE_MIRROR_V5]: {
        name: 'codemirror',
        version: 'v6', // Mismatch in the CDN versioning, v5 is tagged as v6
        status: STATUS.INITIAL,
    },
};

export interface UseThirdPartyLibraryOptions extends Omit<UseInjectLibraryOptions, 'library'> {
    /**
     * Library to download.
     */
    lib: THIRD_PARTY_LIBRARIES;
}

/**
 * Returns the library's name
 * @param lib
 * @returns version
 */
const getLibraryName = (lib: THIRD_PARTY_LIBRARIES): string | null => {
    const library = libraries[lib];

    return library ? library.name : null;
};

/**
 * Returns the library's version
 * @param lib
 * @returns version
 */
const getLibraryVersion = (lib: THIRD_PARTY_LIBRARIES): string | null => {
    const library = libraries[lib];

    return library ? library.version : null;
};

/**
 * Sets a library to a given status
 * @param lib
 * @param status
 */
const setLibraryStatus = (lib: THIRD_PARTY_LIBRARIES, status: STATUS) => {
    const library = libraries[lib];

    libraries[lib] = {
        ...library,
        status,
    };
};

/**
 * Returns the library's status
 * @param lib
 * @returns status
 */
const getLibraryStatus = (lib: THIRD_PARTY_LIBRARIES): STATUS => {
    const library = libraries[lib];

    return library ? library.status : STATUS.ERROR;
};

/**
 * Resets the given library, so it can be downloaded again.
 * @param lib
 * @returns boolean
 */
export const resetLibrary = (lib: THIRD_PARTY_LIBRARIES) => {
    setLibraryStatus(lib, STATUS.INITIAL);
};

/**
 * Returns the URL for the asset to download from the CDN.
 * @param lib - library to download.
 * @param shouldImportCss - if the asset to download is CSS.
 * @returns path to asset
 */
const getAssetUrlForLibrary = (lib: THIRD_PARTY_LIBRARIES, shouldImportCss = false) => {
    const name = getLibraryName(lib);
    const version = getLibraryVersion(lib);
    /**
     * We always want to use the CDN to retrieve these files, no matter whether we are in a local
     * environment, deployed environment or prod. These files should always be served from the CDN
     */
    const publicPath = constants.cdnHost;
    /**
     * They also need to follow this naming convention:
     * <lib-name>.min.(css|js).gzip.
     *
     * This forces anyone that wants to upload a lib to minify and gzip the code.
     */
    const fileName = `${name}.min.${shouldImportCss ? 'css' : 'js'}.gzip`;

    return `${publicPath}libs/${name}/${version}/${fileName}`;
};

/**
 * Hook that retrieves a specific library from the CDN in order to lazy load it into our frontend app.
 * The idea behind this is to avoid installing, importing and bundle manage these libraries, knowing that
 * we can just easily download them from our CDN and use them as any other library.
 * @param options - use third party lib options.
 */
export const useThirdPartyLibrary = (options: UseThirdPartyLibraryOptions) => {
    const { lib, shouldImportCss, onLoad, shouldLoadLibrary = true, onError } = options;
    const library: Library = {
        url: getAssetUrlForLibrary(lib),
        status: getLibraryStatus(lib),
        ...(shouldImportCss && { cssUrl: getAssetUrlForLibrary(lib, true) }),
    };
    useInjectLibrary({
        shouldImportCss,
        onLoad,
        shouldLoadLibrary,
        onError,
        library,
    });
};

/**
 * ThirdPartyLibrary component that allows to use the useThirdPartyLibrary in a react-element context.
 * @param options - UseThirdPartyLibraryOptions
 * @returns empty component.
 */
export const ThirdPartyLibrary: React.FC<UseThirdPartyLibraryOptions> = (options) => {
    useThirdPartyLibrary(options);

    return null;
};
