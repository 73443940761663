import { ElementConvertOption } from '@lumapps/wrex/serialization/dita/fromDITA/types';
import { matchNode } from '@lumapps/wrex/serialization/dita/fromDITA/utils';

import { createQuote } from '../../utils/createQuote';

/**
 * Convert Dita lq into slate Quote element.
 */
export const QUOTE_CONVERT: ElementConvertOption = {
    test: matchNode({ name: 'lq' }),
    transform: (node, children) => createQuote(children),
};
