export enum WREX_EMOJI {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=183589603
     */
    EMOJI_BUTTON = 'WREX_EMOJI.EMOJI_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339042742
     */
    EMOJI_TOOLTIP = 'WREX_EMOJI.EMOJI_BUTTON_TOOLTIP',
}
