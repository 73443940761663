(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetDriveService(Translation, User) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the widget's property in the given lang.
         *
         * @param  {Object}  widgetProperties     The properties of the drive widget.
         * @param  {string}  property             The property to get in the widget.
         * @param  {string}  [lang=<user's lang>] The language to get in the property.
         * @param  {boolean} [useFallback=true]   Indicates if we want to use the fallback language.
         * @return {*}       The value of the widget's property in the wanted lang.
         */
        function getFolderLangProperty(widgetProperties, property, lang, useFallback) {
            var user = User.getConnected();
            useFallback = (angular.isDefined(useFallback)) ? useFallback : true;
            lang = lang || user.lang;

            if (angular.isUndefinedOrEmpty(widgetProperties[property])) {
                return undefined;
            }

            if (!angular.isObject(widgetProperties[property])) {
                return widgetProperties[property];
            }

            if (angular.isDefinedAndFilled(lang) &&
                angular.isDefined(widgetProperties[property][lang])) {
                return widgetProperties[property][lang];
            } else if (useFallback) {
                if (angular.isDefinedAndFilled(user.langs)) {
                    for (var i = 0, len = user.langs.length; i < len; i++) {
                        if (angular.isDefined(widgetProperties[property][user.langs[i]])) {
                            return widgetProperties[property][user.langs[i]];
                        }
                    }
                }

                // If still not found return first iteration available.
                return widgetProperties[property][Object.keys(widgetProperties[property])[0]];
            }

            return undefined;
        }

        /**
         * Set the widget's property in the given lang.
         *
         * @param {Object} widgetProperties The properties of the drive widget.
         * @param {string} property         The property to set in the widget.
         * @param {string} value            The value to set in the widget's property.
         */
        function setFolderLangProperty(widgetProperties, property, value) {
            // Use to keep backward compatibility.
            if (!angular.isObject(widgetProperties[property])) {
                widgetProperties[property] = {};
            }

            widgetProperties[property][Translation.inputLanguage] = value;
        }

        /////////////////////////////

        service.getFolderLangProperty = getFolderLangProperty;
        service.setFolderLangProperty = setFolderLangProperty;
    }

    /////////////////////////////

    angular.module('Services').service('WidgetDrive', WidgetDriveService);
})();
