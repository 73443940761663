import React from 'react';

import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { NotificationListItemCommon, NotificationListItemCommonProps } from './NotificationListItemCommon';
import { NotificationListItemGroup, NotificationListItemGroupProps } from './NotificationListItemGroup';

export type NotificationListItemProps = NotificationListItemCommonProps & {
    getNotificationsForGroup?: NotificationListItemGroupProps['getNotifications'];
};

const NotificationListItem: React.FC<NotificationListItemProps> = ({ getNotificationsForGroup, ...props }) => {
    // TODO: add a notification error?
    return (
        <ErrorBoundary fallback={null}>
            <NotificationListItemCommon {...props} />
            <NotificationListItemGroup getNotifications={getNotificationsForGroup} {...props} />
        </ErrorBoundary>
    );
};
NotificationListItem.displayName = 'ListItem';

export { NotificationListItem };
