import useDimensionsHook from 'react-cool-dimensions';

interface Output {
    /** Number of items that can be displayed depending on:
     * - the max visible items,
     * - the min width of the item,
     * - the width of the container.
     */
    itemsToDisplay: number;
    /** Reference of the container to observe */
    ref: (element?: HTMLElement | undefined | null) => void;
    /** The current breakpoint according to the inputed list */
    currentBreakpoint?: string;
}

/**
 * Compute the number of items to display given the current block size, the items min width and default visible items count.
 * @param itemMinWidth the minimum width of the item
 * @param maxVisibleItems the maximum quantity of items
 * @param breakpoints an object of breakpoints associated to a width (optional)
 * @returns the number of items that can fit on the observed container, depending on the inputs and optionnaly the current breakpoint
 */
export function useComputeItemsToDisplay(
    itemMinWidth: number,
    maxVisibleItems: number,
    breakpoints?: Record<string, number>,
): Output {
    const { observe, width, currentBreakpoint } = useDimensionsHook({ breakpoints });
    const responsiveOverrideVisibleItems = Math.floor(width / itemMinWidth) || 1;
    const itemsToDisplay = Math.min(responsiveOverrideVisibleItems, maxVisibleItems);
    return { itemsToDisplay, currentBreakpoint, ref: observe as (element?: HTMLElement | null) => void };
}
