/* istanbul ignore file */

import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';
import { WidgetSkeletonRectangle } from '@lumapps/widget-base/components/WidgetSkeleton/WidgetSkeletonRectangle';

export const loadClientComputedFileList = () =>
    import(
        /* webpackChunkName: 'widget-file-list' */
        './ClientComputedFileList'
    );

const LazyClientComputedFileList = React.lazy(loadClientComputedFileList);

export const ClientComputedFileList: React.FC<React.ComponentProps<typeof LazyClientComputedFileList>> = (props) => (
    <Suspense loadingFallback={<WidgetSkeletonRectangle />}>
        <LazyClientComputedFileList {...props} />
    </Suspense>
);
