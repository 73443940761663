import React from 'react';

import { cache, CACHE_TYPE } from '@lumapps/cache';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { getCustomContentTypesCacheKey } from '../ducks/selectors';

/** Get skeleton count from cache or default to 3. */
export const useCustomContentTypeSkeletonCount = () => {
    const cacheKey = useSelector(getCustomContentTypesCacheKey);
    const instanceId = useSelector(instanceIdSelector);

    // Get skeleton count from cache or default to 3.
    return React.useMemo(() => cache.retrieve(cacheKey, CACHE_TYPE.STORAGE)?.[instanceId] || 3, [cacheKey, instanceId]);
};
