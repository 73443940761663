import { DOCUMENTATION_LINKS } from '@lumapps/lumx-texts/components/ReferToDocumentation';

/**
 * Cookie flag to save the user closed the banner.
 */
export const HAS_ACCEPTED_COOKIES = 'hasAcceptedCookies';

/**
 * List of the cookies that we should always keep even if cookie use is declined.
 */
export const SAFE_COOKIES = ['session'];

/**
 * The duration of the consent in months. After this period, the user will be asked again to accept cookies or not.
 */
export const CONSENT_DURATION = 13;

/**
 * Link to the doc about "Working with cookies" details
 */
export const PRIVACY_PREFERENCES_DETAILS_LINK = DOCUMENTATION_LINKS.cookies.overview;
