import { AnchorHTMLAttributes, useEffect, useMemo, useState } from 'react';

import { isNewInterfaceRoutingEnabled as isNewInterfaceRoutingEnabledSelector } from '@lumapps/content-layout/ducks/selectors';
import { getDirectories } from '@lumapps/directories/ducks/selectors';
import { getCurrentInstance, getInstanceById } from '@lumapps/instance/ducks/selectors';
import { getAcceptedContributionLanguageHeader } from '@lumapps/languages';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useRouter } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations/hooks/useTranslate';
import { isReactUserDirectory } from '@lumapps/user-directory-front-office/ducks/selectors';
import { isLayoutEnabled } from '@lumapps/widget-layout/ducks/selectors';

import { getContentEntities } from '../ducks/selectors';
import { ContentLinkRef } from '../types';
import { getContentLinkProps } from '../utils/content-link/getContentLinkProps';
import { updateContentLinkFromState } from '../utils/content-link/updateContentLinkFromState';
import { watchUpdateLink } from '../utils/content-link/watchUpdateLink';

interface Output {
    props: AnchorHTMLAttributes<HTMLAnchorElement>;
    ref: (anchor: HTMLAnchorElement | null) => void;
    contentLinkRef: ContentLinkRef;
}

/**
 * Generate content link props, watch and update if needed.
 */
export function useContentLink(contentLinkRef: ContentLinkRef, onClick?: () => void): Output {
    const [anchor, setAnchor] = useState<HTMLAnchorElement | null>(null);
    const isLayoutOn = useSelector(isLayoutEnabled);
    const isReactUserDirectoryEnabled = useSelector(isReactUserDirectory);
    const isNewInterfaceRoutingEnabled = useSelector(isNewInterfaceRoutingEnabledSelector);
    const { redirect } = useRouter();
    const { id: currentInstanceId } = useSelector(getCurrentInstance);
    const languageHeader = useSelector(getAcceptedContributionLanguageHeader);
    const dispatch = useDispatch();
    const { translateObject } = useTranslate();
    const contentById = useSelector(getContentEntities) || {};
    const instanceById = useSelector(getInstanceById) || {};
    const directories = useSelector(getDirectories);

    // Update content link ref from redux state (if possible).
    const updatedContentLinkRef = useMemo(
        () =>
            updateContentLinkFromState(contentLinkRef, translateObject, {
                currentInstanceId,
                contentById,
                instanceById,
                directories,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            contentById,
            instanceById,
            translateObject,
            // We'll only update this if the content id changes to prevent unnecessary re-render
            contentLinkRef.id,
        ],
    );

    // Watch the content link activation (click/enter) & update if needed
    useEffect(
        () => {
            if (!anchor) {
                return undefined;
            }
            return watchUpdateLink(anchor, updatedContentLinkRef, {
                isLayoutOn,
                isNewInterfaceRoutingEnabled,
                isReactUserDirectoryEnabled,
                languageHeader,
                currentInstanceId,
                dispatch,
                translateObject,
                redirect,
                onClick,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            anchor,
            currentInstanceId,
            dispatch,
            isLayoutOn,
            languageHeader,
            redirect,
            translateObject,
            // We'll only update this if the content id changes to prevent unnecessary re-render
            updatedContentLinkRef.id,
        ],
    );

    // Generate content link props.
    const props = useMemo(
        () => getContentLinkProps(updatedContentLinkRef, { isLayoutOn, isReactUserDirectoryEnabled }),
        [isLayoutOn, isReactUserDirectoryEnabled, updatedContentLinkRef],
    );
    return { props, ref: setAnchor, contentLinkRef: updatedContentLinkRef };
}
