import React, { useEffect } from 'react';

import { MAX_RESULTS } from '../../constants';
import { SlackSettings } from '../SlackSettings';

import './index.scss';

/**
 * Display the Native Integrations Settings, usually displayed on User Settings
 *
 * @return {ReactDOM} the native integrations settings element.
 */
const NativeIntegrations: React.FC<any> = ({ hasSlackFeature, slackProvider, userSpaces, fetchUserSpaces }) => {
    useEffect(() => {
        if (!hasSlackFeature || !slackProvider) {
            return;
        }

        fetchUserSpaces({ maxResults: MAX_RESULTS, provider: slackProvider });
    }, [hasSlackFeature, fetchUserSpaces, slackProvider]);

    useEffect(() => {
        if (!hasSlackFeature || !userSpaces) {
            return;
        }

        if (userSpaces.more && userSpaces.cursor) {
            fetchUserSpaces({ maxResults: MAX_RESULTS, cursor: userSpaces.cursor, provider: slackProvider });
        }
    }, [hasSlackFeature, fetchUserSpaces, slackProvider, userSpaces]);

    return hasSlackFeature ? <SlackSettings userSpaces={userSpaces} /> : null;
};

export { NativeIntegrations };
