import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../../types';
import * as styleTypes from '../../types';
import { translate as t } from '../../../../translations';
import { withOnChangeProperty } from '../../../../utils';
import { BACKGROUND_SIZE_CHOICES } from '../../constants';
import { ImageChooser, Select } from '../../../ui';
import { BackgroundImagePosition } from './BackgroundImagePosition';

/**
 * Renders a form that enables editing the background image.
 */
export class BackgroundImage extends React.PureComponent {
    static propTypes = {
        /** Default values. */
        defaults: styleTypes.backgroundStyle,
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func,
        /** Current background style. */
        value: styleTypes.backgroundStyle.isRequired,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { value, defaults } = this.props;

        const sizeValue =
            value.backgroundSize !== null && value.backgroundSize !== undefined
                ? value.backgroundSize
                : defaults.backgroundSize;

        const positionValue =
            value.backgroundPosition !== null && value.backgroundPosition !== undefined
                ? value.backgroundPosition
                : defaults.backgroundPosition;

        return (
            <div className="background-image-manager">
                <div className="background-image-manager__image">
                    <ImageChooser
                        value={value.backgroundImage}
                        name="backgroundImage"
                        onChange={this.onChangeProperty}
                        style={{
                            backgroundPosition: value.backgroundPosition,
                            backgroundSize: value.backgroundSize,
                        }}
                    />
                </div>
                <div className="background-image-manager__wrapper">
                    <div className="background-image-manager__mode">
                        <Select
                            value={sizeValue}
                            name="backgroundSize"
                            onChange={this.onChangeProperty}
                            choices={BACKGROUND_SIZE_CHOICES}
                            hasBackground={false}
                            placeholder={t('GLOBAL.INPUT.CLEAR_LABEL')}
                            align="right"
                            hasClearChoice
                        />
                    </div>
                    <BackgroundImagePosition
                        value={positionValue}
                        name="backgroundPosition"
                        onChange={this.onChangeProperty}
                    />
                </div>
            </div>
        );
    }
}
