/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';
import { isAdministratorsAllowed } from '@lumapps/user/ducks/selectors';

import { UserManagementSideNavigation } from './UserManagementSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => ({
    isAllowed: isAdministratorsAllowed(state),
});

const ConnectedUserManagementSideNavigation = connect(mapStateToProps)(UserManagementSideNavigation);

export { ConnectedUserManagementSideNavigation as UserManagementSideNavigation };
