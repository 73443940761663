import memoize from 'lodash/memoize';
import map from 'lodash/map';

/**
 * Forces a value from an `<input type='number' />` to be in its min-max range.
 *
 * @param  {string|number} value The value to convert
 * @param  {number}        min   The minimum value
 * @param  {number}        max   The maximum value
 * @return {number}        The converted value.
 */
export const handleNumberInput = ({ value, min, max }) => {
    value = parseFloat(value);

    if (!isNaN(parseFloat(min))) {
        value = value < min ? min : value;
    }

    if (!isNaN(parseFloat(max))) {
        value = value > max ? max : value;
    }

    return value;
};

/**
 * Converts an array of strings into an array of `{ label: string, value: string }`.
 * The result is memoized to ensure that the same result is returned for the same array.
 *
 * @param  {Array} choices The array of string values.
 * @return {Array} The array of `{ label: string, value: string }` choices.
 */
export const stringsToChoices = memoize((choices) => map(choices, (choice) => ({ label: choice, value: choice })));
