import { ColorPalette } from '@lumapps/lumx/react';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta } from '../../types';
import { getContentTitle, getParentContentTitle } from '../contentUtils';
import { getArticleLink, getEmptyRoute } from '../linksUtils';

const notificationTypeArticle: Record<string, NotificationMeta> = {
    [NotificationTypes.ArticleNew]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'newspaper',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const articleTitle = getContentTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.ARTICLE_NEW;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                CONTENT_TITLE: articleTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getArticleLink,
    },
    [NotificationTypes.ArticleMention]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'message-text',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const articleTitle = getContentTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.CONTENT_MENTION_WITH_TITLE_DESCRIPTION;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                CONTENT_TITLE: articleTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getArticleLink,
    },
};

export { notificationTypeArticle };
