import React from 'react';

import { SideNavigationItem } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiFolderAccount } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { PLATFORM } from '../../keys';
import { reseller } from '../../routes';

export interface ResellerSideNavigationProps {
    isAllowed: boolean;
}

const ResellerSideNavigation = ({ isAllowed }: ResellerSideNavigationProps) => {
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return <SideNavigationItem label={translateKey(PLATFORM.RESELLER)} icon={mdiFolderAccount} route={reseller()} />;
};

ResellerSideNavigation.displayName = 'ResellerSideNavigation';

export { ResellerSideNavigation };
