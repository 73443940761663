import { Editor, Element, NodeEntry, Transforms } from '@lumapps/wrex/slate';
import { getNode } from '@lumapps/wrex/slate/utils/getNode';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';

import { createParagraph } from '../../utils/createParagraph';

export function normalizeVoidElement(editor: Editor, [node, path]: NodeEntry) {
    if (
        !Element.isElement(node) ||
        !Editor.isVoid(editor, node) ||
        // Not at the root of the document
        path.length > 1
    ) {
        return false;
    }
    const after = getSiblingPath(path, 'after');

    if (!after || getNode(editor, after)) {
        return false;
    }

    Transforms.insertNodes(editor, createParagraph(), { at: after });
    return true;
}
