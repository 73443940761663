import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Text, TextProps } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { isDateUnderThreshold } from '@lumapps/utils/date/isDateUnderThreshold';

import { RelativeDate, RelativeDateProps } from '../RelativeDate';

import './index.scss';

interface TranslationProps {
    /** The translation key to use */
    translationKey: string;
    /**
     * The variable to replace the date with in the translation key.
     * Defaults to 'date'
     */
    dateReplacementKey?: string;
}

interface ThresholdTranslationProps {
    /** The values to use when the date is under the given threshold */
    underThreshold: TranslationProps;
    /** The values to use when the date is over the given threshold */
    overThreshold: TranslationProps;
}

export interface RelativeDateWithTranslationProps {
    as?: TextProps['as'];
    /** The date to display. */
    date: RelativeDateProps['date'];
    /** The translation keys to use depending on threshold */
    translations: ThresholdTranslationProps;
    /** Props to forward to the RelativeDate component */
    relativeDateProps?: Omit<RelativeDateProps, 'date'>;
}

const DISPLAY_FULL_DATE_THRESHOLD = 7;
const DEFAULT_REPLACEMENT_KEY = 'date';

const CLASSNAME = 'relative-date-with-translation';

/**
 * Displays a relative date within a different translation key depending on whether the date is
 * before or after the given threshold.
 *
 * Ex:
 * After => Modified Yesterday
 * Before => Modified on September 7, 2024
 *
 * @family Texts
 * @param RelativeDateWithTranslationProps
 * @returns RelativeDateWithTranslation
 */
export const RelativeDateWithTranslation: React.FC<RelativeDateWithTranslationProps> = ({
    as = 'p',
    date,
    translations,
    relativeDateProps,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateAndReplaceWithComponents } = useTranslate();
    const thresholdDisplayFullDate =
        relativeDateProps?.options?.thresholdDisplayFullDate || DISPLAY_FULL_DATE_THRESHOLD;

    const { overThreshold, underThreshold } = translations;

    const isUnderThreshold = isDateUnderThreshold(date, thresholdDisplayFullDate);

    const translationKey = isUnderThreshold ? underThreshold.translationKey : overThreshold.translationKey;

    const replacementVariable = isUnderThreshold
        ? underThreshold.dateReplacementKey || DEFAULT_REPLACEMENT_KEY
        : overThreshold.dateReplacementKey || DEFAULT_REPLACEMENT_KEY;

    return (
        <Text as={as} className={block()}>
            {translateAndReplaceWithComponents(translationKey, {
                [replacementVariable]: (
                    <RelativeDate
                        className={element('relative-date', {
                            lowercase: isUnderThreshold,
                        })}
                        date={date}
                        {...relativeDateProps}
                        options={{
                            thresholdDisplayFullDate,
                            ...relativeDateProps?.options,
                        }}
                    />
                ),
            })}
        </Text>
    );
};
