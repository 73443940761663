import React from 'react';

import intersection from 'lodash/intersection';

import { Orientation, Theme } from '@lumapps/lumx/react';

import type { Playlist, VisibleElement } from './types';

type BaseBlockPlaylistContextProviderProps = {
    /** Component children */
    children: React.ReactNode;

    /** Orientation of the component. */
    orientation: Orientation;

    /** UI theme */
    theme?: Theme;

    /** Playlist */
    playlist?: Playlist;

    /** Elements to display */
    visibleElements?: VisibleElement[];
};

type ReadyBlockPlaylistContextProviderProps = BaseBlockPlaylistContextProviderProps & {
    isLoading?: false;
    playlist: Playlist;
};

type LoadingBlockPlaylistContextProviderProps = BaseBlockPlaylistContextProviderProps & {
    isLoading: true;
    playlist?: undefined;
};

export type BlockPlaylistContextProviderProps =
    | ReadyBlockPlaylistContextProviderProps
    | LoadingBlockPlaylistContextProviderProps;

interface BaseState {
    /**
     * Predicate function used to know if at least one of the elements is visible or not
     *
     * @example
     * canDisplay('title', 'description') returns true if 'title' OR 'description' is visible
     */
    canDisplay: (...elements: VisibleElement[]) => boolean;
}

type ReadyState = BaseState & Omit<ReadyBlockPlaylistContextProviderProps, 'children'>;

type LoadingState = BaseState & Omit<LoadingBlockPlaylistContextProviderProps, 'children'>;

type State = ReadyState | LoadingState;

const BlockPlaylistContext = React.createContext<State | undefined>(undefined);

export function BlockPlaylistContextProvider({
    children,
    isLoading,
    playlist,
    orientation,
    theme,
    visibleElements,
}: BlockPlaylistContextProviderProps) {
    const canDisplay = React.useCallback(
        (...elements: VisibleElement[]) => {
            return !visibleElements || !!intersection(elements, visibleElements).length;
        },
        [visibleElements],
    );

    const value: State = React.useMemo(
        () => ({
            canDisplay,
            orientation,
            theme,
            ...(isLoading ? { isLoading } : { playlist }),
        }),
        [canDisplay, isLoading, orientation, playlist, theme],
    );

    return <BlockPlaylistContext.Provider value={value}>{children}</BlockPlaylistContext.Provider>;
}

export function useBlockPlaylistContext() {
    const context = React.useContext(BlockPlaylistContext);

    if (!context) {
        throw new Error('Child components of BlockPlaylist cannot be rendered outside the BlockPlaylist component!');
    }

    return context;
}
