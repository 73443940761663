import React, { ReactElement } from 'react';

import { padding } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Theme, TabProvider, TabList, Tab, TabPanel, FlexBox, Alignment } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BlockAddDirectoryUserEntry } from '@lumapps/widget-directory-entries/components/BlockAddDirectoryUserEntry';

import { WidgetState } from '../../../ducks/type';
import {
    BlockComponent,
    BlockTabFilteredContainer as BlockTabFilteredContainerType,
    TagReference,
} from '../../../types';
import { WidgetSkeleton } from '../../WidgetSkeleton';

const CLASSNAME = 'block-tab-filtered-container';

export interface BlockTabFilteredContainerProps extends BlockTabFilteredContainerType {
    loadingState: WidgetState['state'];
    theme?: Theme;
    widget: WidgetState;
    widgetType: string;
    blockResolutionInfo?: WidgetState['blockResolutionInfo'];
    onFetchTabContent: (
        widgetType: string,
        blockResolutionInfo?: WidgetState['blockResolutionInfo'],
        tabId?: string,
    ) => void;
    onFetchMoreTabContent: (
        widgetType: string,
        cursor?: string,
        blockResolutionInfo?: WidgetState['blockResolutionInfo'],
        tabId?: string,
    ) => void;
    onLoadMore?(): void;
}

/**
 * This block renders a tab list with a list of contents' preview.
 */
export const BlockTabFilteredContainer: BlockComponent<BlockTabFilteredContainerProps> = ({
    children,
    filter,
    header,
    widget,
    widgetType,
    blockResolutionInfo,
    loadingState,
    theme = Theme.light,
    onFetchTabContent,
    onFetchMoreTabContent,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(CLASSNAME);
    const tabItemAll: Partial<TagReference> = React.useMemo(() => {
        return { name: translateKey(GLOBAL.ALL), tagId: 'ALL' };
    }, [translateKey]);
    const tabItems = React.useMemo(
        () => (filter.isNoFilterAllowed ? [tabItemAll, ...filter.tags] : filter.tags),
        [filter.isNoFilterAllowed, filter.tags, tabItemAll],
    );

    const [activeTab, setActiveTab] = React.useState(0);

    const toRender = React.useMemo(() => {
        if (loadingState === 'loadingcontent') {
            return (
                <FlexBox vAlign={Alignment.center}>
                    <WidgetSkeleton />
                </FlexBox>
            );
        }

        const childrenArray = React.Children.toArray(children).filter(Boolean).filter(React.isValidElement);

        if (childrenArray.length === 1 && widget.more && widget.paginationType === 'load_more') {
            // Inject load more in block children.
            return React.cloneElement(childrenArray[0] as ReactElement, {
                onLoadMore: () =>
                    onFetchMoreTabContent(widgetType, widget.cursor, blockResolutionInfo, tabItems[activeTab].tagId),
                loadingState,
                loadMoreButtonProps: { fullWidth: true },
            });
        }

        return children;
    }, [loadingState, children, widget, onFetchMoreTabContent, widgetType, blockResolutionInfo, tabItems, activeTab]);

    return (
        <div className={CLASSNAME}>
            <TabProvider
                activeTabIndex={activeTab}
                onChange={(index) => {
                    setActiveTab(index);
                    onFetchTabContent(widgetType, blockResolutionInfo, tabItems[index].tagId);
                }}
            >
                <TabList theme={theme} aria-label="">
                    {filter.tags.length > 1 &&
                        tabItems.map((t) => (
                            <Tab key={t.tagId} label={t.name} {...get({ element: 'filter', action: t.tagId })} />
                        ))}
                </TabList>
                {header && <BlockAddDirectoryUserEntry {...header} theme={theme} widget={widget} />}
                {tabItems.map((tab) => (
                    <TabPanel key={tab.tagId} className={padding('vertical', 'huge')}>
                        {toRender}
                    </TabPanel>
                ))}
            </TabProvider>
        </div>
    );
};
BlockTabFilteredContainer.displayName = 'BlockTabFilteredContainer';
