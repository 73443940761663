export let Style;
export let adjustFooterStyle;
export let adjustStyle;
export let getWidgetContentStyle;

export function setStyles(styles) {
    Style = styles;
    adjustFooterStyle = styles.adjustFooterStyle;
    adjustStyle = styles.adjustStyle;
    getWidgetContentStyle = styles.getWidgetContentStyle;
}
