import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';

import { LumappsComponentInput, LumappsComponentIntegratedBaseProps, LumappsComponentType } from '../../types';

export interface GetLumappsComponentParams {
    /** The component and its parameters we need to render */
    component: LumappsComponentInput;
    /** The list of integrated components we support */
    choices: Record<LumappsComponentType, React.FC<LumappsComponentIntegratedBaseProps>>;
}

export const getLumappsComponent = ({ component, choices }: GetLumappsComponentParams) => {
    if (!choices[component.type]) {
        return ServiceNotAvailableState;
    }
    return choices[component.type];
};
