import isEmpty from 'lodash/isEmpty';

import { ELEMENT_TYPE_TO_NOT_TRANSLATE, ELEMENT_TYPE_TO_TRANSLATE } from '../../../constants';
import { DITA } from '../types';
import { isDitaContentEmpty } from './isDitaContentEmpty';

export const getDitaTextToArray = (dita: DITA) => {
    // Only take nodes from the body (nodes without titles + nodes with titles)
    const ditaContents = dita.children.slice(3);
    const ditaBody = dita.children.find((n) => n.name === 'body');

    if (!ditaBody || isDitaContentEmpty(dita)) {
        return undefined;
    }

    const getElementValues = (child: any) => {
        // Skip element type we don't want to translate (like user mention)
        if (ELEMENT_TYPE_TO_NOT_TRANSLATE.includes(child?.attributes?.type)) {
            return [];
        }

        // Return the element text value if it is included to the translatable types
        if (ELEMENT_TYPE_TO_TRANSLATE.includes(child.type || child.name) && !isEmpty(child.value.trim())) {
            return [child.value];
        }

        if (!isEmpty(child.children)) {
            return child.children.flatMap(getElementValues);
        }

        // Handle type and values on the attributes property (in link preview for example)
        if (child.attributes) {
            const { name: childName, value: childValue } = child.attributes;
            if (ELEMENT_TYPE_TO_TRANSLATE.includes(childName) && !isEmpty(childValue.trim())) {
                return [childValue];
            }
        }

        return [];
    };

    let ditaTextArray: string[] = [];

    // Iterate through each nodes (topic and body) because adding a title add a topic node
    ditaContents.forEach((ditaContent) => {
        ditaTextArray = ditaTextArray.concat(ditaContent.children.flatMap(getElementValues));
    });

    return ditaTextArray;
};
