import { useInView, type IntersectionOptions, type InViewHookResponse } from 'react-intersection-observer';

/**
 * Hook that allows to determine whether an element is in the view port or not.
 * Usage:
 * const { ref, inView } = useIsInView();
 *
 * Where:
 * - ref is the prop that will go into the node as ref={ref}
 * - inView is a boolean that determines whether the element is in the viewport or not
 */
const useIsInView = (options?: IntersectionOptions): InViewHookResponse => {
    return useInView(options);
};

export { useIsInView, type IntersectionOptions };
