(function IIFE() {
    'use strict';

    /////////////////////////////

    function LanguageSelectorController($window, Instance, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /*
         * Services and utilities.
         */
        vm.Instance = Instance;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the current language of the user.
         *
         * @return {string} The current language code of the user.
         */
        function getLanguage() {
            return Translation.getLang('current');
        }

        /**
         * Select a given language.
         *
         * @param {string} language The selected language.
         */
        function selectLanguage(language) {
            if (angular.isUndefinedOrEmpty(language)) {
                return;
            }

            $window.location.href = Utils.updateUrlParameter($window.location.href, 'lang', language);
        }

        /////////////////////////////

        vm.getLanguage = getLanguage;
        vm.selectLanguage = selectLanguage;
    }

    /////////////////////////////

    /**
     * A directive used to switch between languages allowed in the current instance.
     */

    function LanguageSelectorDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LanguageSelectorController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: '/client/common/modules/language-selector/views/language-selector.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsLanguageSelector', LanguageSelectorDirective);
})();
