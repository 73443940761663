import React from 'react';

import { background, classnames, padding } from '@lumapps/classnames';
import { EmptyState } from '@lumapps/lumx-states/components/EmptyState';
import { mdiNewspaperVariantMultipleOutline } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { EmptyStateRendererProps } from '@lumapps/widget-base/types';

import { USER_FEED } from '../../keys';

const CLASSNAME = 'personal-feed-list-empty';

/**
 * Custom Empty State for the Personal Feed List.
 */
export const PersonalFeedListEmptyState: React.FC<EmptyStateRendererProps> = () => {
    const { translateKey } = useTranslate();

    return (
        <article className={classnames(CLASSNAME, background('light', 'N'), padding('all', 'huge'))}>
            <EmptyState
                iconProps={{ icon: mdiNewspaperVariantMultipleOutline }}
                title={translateKey(USER_FEED.YOUR_FEED_IS_EMPTY)}
                message={translateKey(USER_FEED.NEW_PUBLICATIONS_FROM_SUBSCRIPTIONS_WILL_BE_LISTED_HERE)}
            />
        </article>
    );
};
