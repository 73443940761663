import React, { FC } from 'react';

import { classnames } from '@lumapps/classnames';
import { Size, Theme } from '@lumapps/lumx/react';
import { BaseVideo, CreatedByDetails } from '@lumapps/play/api/types';
import { UserBlock, UserBlockProps } from '@lumapps/user/components/UserBlock';

import './index.scss';

export interface VideoPublisherProps extends Omit<UserBlockProps, 'user' | 'userId'> {
    className?: string;
    id: BaseVideo['createdById'];
    size?: UserBlockProps['size'];
    theme?: Theme;
    user: CreatedByDetails;
}

const CLASSNAME = 'video-publisher';

export const VideoPublisher: FC<VideoPublisherProps> = ({ className, id, size, theme = 'light', user, ...props }) => {
    const { fullName, profilePictureUrl } = user;

    return (
        <address className={classnames(CLASSNAME, className)}>
            <UserBlock
                fullName={fullName}
                picture={profilePictureUrl}
                size={size || Size.s}
                theme={theme}
                {...props}
                userId={id}
            />
        </address>
    );
};
