import { ConfigurationDialogSteps } from '../types';

const steps = Object.values(ConfigurationDialogSteps);

export const getDisplayedSteps = (isSuperAdmin: boolean, isSpace: boolean) =>
    steps.filter((step) => {
        switch (step) {
            case ConfigurationDialogSteps.options:
                return isSuperAdmin;
            case ConfigurationDialogSteps.services:
            case ConfigurationDialogSteps.posts:
                return !isSpace;
            case ConfigurationDialogSteps.spaceServices:
                return isSpace;
            default:
                // show step by default
                return true;
        }
    });
