import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    /**
     * A directive that will fix an element on the page as the user scrolls down the page and return it to its natural
     * position when scrolling back up.
     */

    function StickyElementDirective($rootScope, $window, Utils) {
        'ngInject';

        function StickyElementLink(scope, el, attrs) {
            /////////////////////////////
            //                         //
            //    Private attributes   //
            //                         //
            /////////////////////////////

            /**
             * An unique identifier for this sticky element.
             *
             * @type {string}
             */
            var _ID = generateUUID();

            /**
             * A jQElement for the whole window.
             *
             * @type {jQElement}
             */
            var _WINDOW = angular.element($window);

            /**
             * A jQElement for the placeholder element.
             *
             * @type {jQElement}
             */
            var _$placeholder;

            /**
             * The outer height of the original element.
             *
             * @type {number}
             */
            var _elementHeight = 0;

            /**
             * The offset of the original element from the top of the window (in pixels).
             *
             * @type {number}
             */
            var _elementOffset = 0;

            /**
             * The outer width of the original element.
             *
             * @type {number}
             */
            var _elementWidth = 0;

            /**
             * The offset from the top of the window (in pixels).
             *
             * @type {number}
             */
            var _scrollOffset = 0;

            /////////////////////////////
            //                         //
            //    Private functions    //
            //                         //
            /////////////////////////////

            /**
             * Adjust the position of the sticky element.
             */
            function _updatePosition() {
                if (angular.isUndefined(_$placeholder)) {
                    _elementOffset = el.offset();
                    _elementWidth = el.outerWidth();
                    _elementHeight = el.outerHeight();
                } else {
                    _elementOffset = _$placeholder.offset();
                }

                if ((_WINDOW.scrollTop() + _scrollOffset) > _elementOffset.top) {
                    if (angular.isUndefined(_$placeholder)) {
                        // Create a clone placeholder - the same tag as the original element.
                        _$placeholder = el.clone();

                        // Replace inner html with nothing to avoid to collapse ids.
                        _$placeholder[0].innerHTML = '';

                        _$placeholder.insertBefore(el).css({
                            height: _elementHeight,
                            visibility: 'hidden',
                        });

                        // Make origin element sticky.
                        el.css({
                            left: _elementOffset.left,
                            position: 'fixed',
                            top: 0 + _scrollOffset,
                            width: _elementWidth,
                            zIndex: 100,
                        }).addClass('sticky-element').appendTo('body');
                    }
                } else if (angular.isDefined(_$placeholder)) {
                    // Replace placeholder with sticky element and remove sticky css.
                    _$placeholder.replaceWith(el);

                    el.removeAttr('style');

                    _$placeholder = undefined;
                }
            }
            var _throttledUpdatePosition = _.throttle(_updatePosition, 100);

            /**
             * Update the size of the sticky element based on its placeholder (if it has one).
             */
            function _updateSize() {
                if (angular.isUndefined(_$placeholder)) {
                    return;
                }

                el.css({
                    width: _$placeholder.outerWidth(),
                });
            }

            /////////////////////////////
            //                         //
            //          Events         //
            //                         //
            /////////////////////////////

            /**
             * When the element is initialised, udpate its size.
             *
             * @param {Event}  evt     The original event triggering this method.
             * @param {Object} options Options passed whenever the method is triggered.
             * @param {string} uuid    The identifier of the sticky element instance.
             */
            var _onStickyElementSizeUpdate = $rootScope.$on('stickyElement',
                function onStickyElementSizeUpdate(evt, options) {
                    if (options.uuid !== _ID) {
                        _updateSize();
                    }
                });

            /**
             * When the directive is destroyed, get rid of the placeholder element and unbind events.
             */
            scope.$on('$destroy', function onDestroy() {
                if (angular.isFunction(_onStickyElementSizeUpdate)) {
                    _onStickyElementSizeUpdate();
                }

                _WINDOW.unbind('scroll', _throttledUpdatePosition);

                if (angular.isDefined(_$placeholder)) {
                    el.remove();
                    _$placeholder.remove();
                    _$placeholder = undefined;
                }

                $rootScope.$broadcast('stickyElement', {
                    uuid: _ID,
                });
            });

            /////////////////////////////

            /**
             * Initialize the controller.
             */
            function init() {
                _scrollOffset = parseInt(_.get(attrs, 'offset', 0), 10);

                // If the element was hidden or didn't exist and appear in an already scrolled context.
                if ((_WINDOW.scrollTop() + _scrollOffset) > el.offset().top) {
                    // Check initial position.
                    _updatePosition();
                }

                $rootScope.$broadcast('stickyElement', {
                    uuid: _ID,
                });

                _WINDOW.bind('scroll', _throttledUpdatePosition);
            }

            init();
        }

        /////////////////////////////

        return {
            link: StickyElementLink,
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('stickyElement', StickyElementDirective);
})();
