(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialActivityService(LumsitesBaseService, SocialActivityFactory) {
        'ngInject';

        return LumsitesBaseService.createLumsitesBaseService(SocialActivityFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });
    }

    /////////////////////////////

    angular.module('Services').service('SocialActivity', SocialActivityService);
})();
