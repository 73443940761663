function WidgetDriveFilePreviewDirective() {
    'ngInject';

    function WidgetDriveFilePreviewLink(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: 'WidgetIframeController',
        controllerAs: 'vm',
        link: WidgetDriveFilePreviewLink,
        replace: true,
        require: ['widgetDriveFilePreview', '^widget'],
        restrict: 'E',
        scope: {
            widget: '=',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-iframe/views/widget-iframe.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetDriveFilePreview', WidgetDriveFilePreviewDirective);

export { WidgetDriveFilePreviewDirective };
