import React, { useEffect, useRef } from 'react';

type UseInfiniteScroll = (
    ref: React.RefObject<HTMLElement>,
    // eslint-disable-next-line no-use-before-define
    callback?: EventCallback,
    callbackOnMount?: boolean,
    scrollTriggerMargin?: number,
) => void;
type EventCallback = (evt?: Event) => void;

// The error margin in px we want to have for triggering infinite scroll
const SCROLL_TRIGGER_MARGIN = 5;
const CLASSNAME = 'lumx-infinite-scroll-anchor';
/**
 * @deprecated Please use <InfiniteScroll /> Component which uses IntersectionObserver (more efficient)
 *
 * Listen to scroll on an element and callback the passed in function if element is at bottom.
 *
 * @param  ref               A reference to the element on which you want to listen scroll event.
 * @param  [callback]        A callback function to call when the bottom of the reference element is reached.
 * @param  [callbackOnMount] A callback function to call when the component is mounted.
 * @param  [scrollTriggerMargin] The error margin in px we want to have for triggering infinite scroll
 *
 */
export const useInfiniteScroll: UseInfiniteScroll = (
    ref,
    callback,
    callbackOnMount = false,
    scrollTriggerMargin = SCROLL_TRIGGER_MARGIN,
) => {
    useEffect(() => {
        const { current } = ref;

        if (!callback || !current) {
            return undefined;
        }

        const isAtBottom = () =>
            Boolean(
                current && current.scrollHeight - (current.scrollTop + current.clientHeight) <= scrollTriggerMargin,
            );

        const onScroll = (e?: Event): void => {
            if (isAtBottom()) {
                callback(e);
            }
        };

        if (isAtBottom()) {
            onScroll();
        }

        current.addEventListener('scroll', onScroll);
        current.addEventListener('resize', onScroll);

        return () => {
            current.removeEventListener('scroll', onScroll);
            current.removeEventListener('resize', onScroll);
        };
    }, [ref, callback, scrollTriggerMargin]);

    useEffect(() => {
        if (callback && callbackOnMount) {
            callback();
        }
    }, [callback, callbackOnMount]);
};

export interface InfiniteScrollProps {
    callback: EventCallback;
    options?: IntersectionObserverInit;
}

/**
 * Handles basic callback pattern by using intersection observers.
 *
 * @param  {Function} callback The callback to execute once the element is in the viewport or is intersecting
 *                             with its root element.
 * @param  {Object}   options  The set of options we want to set to the intersection observer.
 * @return {Element}  The Infinite scroll element.
 */
export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({ callback, options }) => {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries = []) => {
            const hasIntersection = entries.some((entry) => entry.isIntersecting);

            // Make sure at least one target element has intersected with the root element.
            if (!hasIntersection) {
                return;
            }

            callback();
        }, options);

        const currentRef = elementRef.current;
        if (elementRef && elementRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            observer.observe(elementRef.current);
        }

        return () => {
            if (currentRef) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                observer.unobserve(currentRef);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef.current, callback]);

    // In order to avoid issues when a zoom is added to the browser, we add a small height to the div so that
    // the intersection has a higher chance of working correctly.
    return <div ref={elementRef} aria-hidden="true" className={CLASSNAME} style={{ height: '4px' }} />;
};
