import type { Article } from '@lumapps/articles/types';
import type { Community } from '@lumapps/communities/types';
import type { Content } from '@lumapps/contents/types';
import type { Event } from '@lumapps/events/types';
import { trainingView } from '@lumapps/learning-trainings/routes';
import { type Training } from '@lumapps/learning-trainings/types';
import { InstalledExtensionAPIProps } from '@lumapps/marketplace/legacy-types';
import type { Media } from '@lumapps/medias/types';
import { getImageUrlFromContent, getMediaContent } from '@lumapps/medias/utils';
import type { Playlist } from '@lumapps/play-playlists/types';
import type { Video } from '@lumapps/play/api/types';
import type { Post } from '@lumapps/posts/types';
import { addLocationOriginToUrl, createUrl } from '@lumapps/router/utils';
import { GLOBAL, TranslationAPI, normalizeV2Format } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';

import { LINK_PREVIEW_RESOURCE_TYPES } from '../constants';
import type { LinkPreviewAttributes } from '../types';

export interface PickerResource {
    [LINK_PREVIEW_RESOURCE_TYPES.ARTICLE]: Article;
    [LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY]: Community;
    [LINK_PREVIEW_RESOURCE_TYPES.CONTENT]: Content;
    [LINK_PREVIEW_RESOURCE_TYPES.EVENT]: Event;
    [LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH]: Training;
    [LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP]: InstalledExtensionAPIProps;
    [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO]: Video;
    [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST]: Playlist;
    [LINK_PREVIEW_RESOURCE_TYPES.POST]: Post;
    [LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE]: Training;
}

const mapLearning = (item: Training) => {
    const { cover, description, identifier, title, type } = item;

    return {
        id: identifier,
        // type can be learning_path or training_course, hence why we return it here
        type,
        title,
        thumbnailUrl: cover,
        description: sanitizeHTML(description),
    };
};

/** All resource types are mapped here. The Record type is necessary mapPickerResourceToPreview cannot use resourceMapper. "any" is enough since all mappers are different */
const resourceMapper: Record<LINK_PREVIEW_RESOURCE_TYPES, any> = {
    [LINK_PREVIEW_RESOURCE_TYPES.ARTICLE]: (item: Article, { translateObject }: TranslationAPI, language: string) => {
        const { id, structuredContent, title, site } = item;

        const featuredImage = structuredContent?.featuredImage;
        const featureImageTranslations = featuredImage?.translations;
        const imageUrl = featureImageTranslations ? featureImageTranslations[featuredImage.lang as string]?.url : '';

        return {
            id: id || '',
            siteSlug: site?.siteId || '',
            title: translateObject(title, language) || undefined,
            thumbnailUrl: imageUrl,
            description: translateObject(structuredContent?.intro?.translations, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.COMMUNITY]: (
        item: Community,
        { translateObject }: TranslationAPI,
        language: string,
    ) => {
        const { description, id, instanceDetails, thumbnail, title, slug } = item;

        return {
            id,
            siteSlug: instanceDetails?.id || '',
            slug: translateObject(slug, language) || '',
            title: translateObject(title, language) || undefined,
            thumbnailUrl: thumbnail,
            description: translateObject(description, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.CONTENT]: (item: Content, { translateObject }: TranslationAPI, language: string) => {
        const { excerpt, id, instance, mediaThumbnail, slug, title } = item;

        return {
            id,
            siteSlug: instance || '',
            slug: translateObject(slug, language) || '',
            title: translateObject(title, language) || undefined,
            thumbnailUrl: getImageUrlFromContent(getMediaContent(mediaThumbnail as Media, translateObject, language)),
            description: translateObject(excerpt, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.EVENT]: (item: Event, { translateObject }: TranslationAPI, language: string) => {
        const {
            id,
            structuredContent: { featuredImage, title, intro },
        } = item;

        const featureImageTranslations = featuredImage?.translations;
        const imageUrl = featureImageTranslations ? featureImageTranslations[featuredImage.lang as string]?.url : '';

        return {
            id: id || '',
            //! FIXME: we should have siteSlug here but picker does not provide anything related to instance :/
            title: translateObject(title.translations, language) || undefined,
            thumbnailUrl: imageUrl,
            description: translateObject(intro.translations, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH]: mapLearning,

    [LINK_PREVIEW_RESOURCE_TYPES.MICRO_APP]: (
        item: InstalledExtensionAPIProps,
        { translateObject }: TranslationAPI,
        language: string,
    ) => {
        const { id, extension } = item;
        const { name, icon, description } = extension;

        return {
            id,
            title: translateObject(name, language) || undefined,
            thumbnailUrl: translateObject(icon, language) || '',
            description: translateObject(description, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO]: (item: Video, { translateObject }: TranslationAPI, language: string) => {
        const { id, title, description, siteId, thumbnail } = item;
        // video.title/video.description are typed as a translatableObject, but they do not return the `translations` field,
        // so, we use normalizeV2Format in here to ensure that all the fields are there.
        const titleTranslations = normalizeV2Format(title).translations;
        const descriptionTranslations = normalizeV2Format(description).translations;

        return {
            id,
            siteSlug: siteId,
            title: translateObject(titleTranslations, language) || undefined,
            thumbnailUrl: thumbnail?.url,
            description: translateObject(descriptionTranslations, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.PLAY_VIDEO_PLAYLIST]: (
        item: Playlist,
        { translateObject }: TranslationAPI,
        language: string,
    ) => {
        const { id, title, description, siteId, thumbnail } = item;

        return {
            id,
            siteSlug: siteId,
            title: translateObject(title.translations, language) || undefined,
            thumbnailUrl: thumbnail?.url,
            description: translateObject(description?.translations, language) || undefined,
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.POST]: (
        item: Post,
        { translateObject, translateAndReplace, translateKey }: TranslationAPI,
        language: string,
    ) => {
        const { id, title, parentContentDetails, excerpt, instance } = item;

        const postTitle =
            translateObject(title, language) ||
            translateAndReplace(GLOBAL.CONTENTS_IN_CONTAINER, {
                contents: translateKey(GLOBAL.POST),
                container: translateObject(parentContentDetails?.title) || '',
            });

        return {
            id: id || '',
            communityId: parentContentDetails?.id || '',
            communitySlug: translateObject(parentContentDetails?.slug) || '',
            siteSlug: instance,
            title: postTitle || undefined,
            // posts never have a thumbnail
            thumbnailUrl: '',
            description: translateObject(excerpt, language) || '',
        };
    },

    [LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE]: mapLearning,
};

/**
 * Maps the object we get from a picker to link preview resource according to its type
 * @param item - the item (article, content...) we want to map
 * @param translate - an object containing all translation functions
 * @param  language - source language or user's current language
 * @returns LinkPreviewAttributes
 */
export const mapPickerResourceToPreview = (resourceType: LINK_PREVIEW_RESOURCE_TYPES) => {
    const getResourceData = resourceMapper[resourceType];
    const getPreview = (item: PickerResource[typeof resourceType]) => {
        if (
            resourceType === LINK_PREVIEW_RESOURCE_TYPES.LEARNING_PATH ||
            resourceType === LINK_PREVIEW_RESOURCE_TYPES.TRAINING_COURSE
        ) {
            const { identifier, cover } = item as Training;

            return {
                // for learning, we need to send the url to backend because it needs it
                url: addLocationOriginToUrl(createUrl(trainingView(identifier))),
                // and we must NOT omit thumbnailUrl here (unlike other resource types) because learnings are NOT exactly LumApps resources yet
                thumbnailUrl: cover,
            };
        }

        // url is a data given by backend to display a linkPreview. When using pickers we have no data to send to backend
        // hence the empty string here + they don't need it to create the url.
        return { url: '' };
    };

    return (
        item: PickerResource[typeof resourceType],
        translate: TranslationAPI,
        language: string,
    ): LinkPreviewAttributes => {
        const resource = getResourceData(item, translate, language);
        const preview = getPreview(item);

        return {
            ...preview,
            resource: {
                type: resourceType,
                ...resource,
            },
        };
    };
};
