import { AUTO_TRANSLATION } from '@lumapps/auto-translation/keys';
import { get as getConstants } from '@lumapps/constants';
import { currentLanguageSelector } from '@lumapps/languages';
import { error as notifyError } from '@lumapps/notifications';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { getConnectedUserLanguage, isConnected } from '@lumapps/user/ducks/selectors';
import { getTranslatableWidgets } from '@lumapps/widget-base/ducks/selectors';
import { translateTitle } from '@lumapps/widget-base/ducks/thunks/translateTitle';
import { translateWidget } from '@lumapps/widget-base/ducks/thunks/translateWidget';

const DEFAULT_LANG = getConstants().defaultLanguage;

/**
 * Automatic google translation of the content widgets.
 *
 * @param translate     whether to translate or restore the original title.
 * @param requestedLang the target language to translate to.
 * @return the translated content title.
 * @throws on API error.
 */
export const translateCurrentContent =
    (translate: boolean, requestedLang?: string, shouldResetTranslation?: boolean) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState): Promise<string> => {
        const state = getState();
        const currentLanguage = currentLanguageSelector(state);
        const userLanguage = getConnectedUserLanguage(state);

        const targetLang = requestedLang || userLanguage || currentLanguage || DEFAULT_LANG;
        const { titleWidgets = [], otherWidgets = [] } = getTranslatableWidgets(state) || {};

        const [translatedTitle] = await Promise.all([
            // Translate content title & update title widgets
            dispatch(translateTitle(translate, targetLang, titleWidgets)),
            // Translate all other translatable widgets
            ...otherWidgets.map((widget) =>
                dispatch(translateWidget(widget, translate, targetLang, shouldResetTranslation)),
            ),
        ]);
        return translatedTitle;
    };

/**
 * Same as `translateCurrentContent` but catching errors to display a generic error notification.
 */
export const translateCurrentContentWithErrorNotification =
    (translate: boolean, requestedLang?: string, shouldResetTranslation?: boolean) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState): Promise<void> => {
        try {
            await dispatch(translateCurrentContent(translate, requestedLang, shouldResetTranslation));
        } catch (e) {
            const state = getState();

            const isUserConnected = isConnected(state);

            dispatch(
                notifyError({
                    translate:
                        (e as any).error.code === 400 &&
                        (e as any).error.message === 'REQUEST_TOO_LARGE' &&
                        !isUserConnected
                            ? AUTO_TRANSLATION.NOT_LOGGED_RATE_LIMIT_EXCEEDED
                            : GLOBAL.GENERIC_ERROR,
                }),
            );
        }
    };
