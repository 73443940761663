import { Converter } from '@lumapps/wrex/serialization/dita/toDITA/types';
import { EmbeddedType } from '@lumapps/wrex/serialization/dita/types';

import { EmbeddedVideoElement } from '../../types';

/** Convert slate Embed Video to DITA Embed Video. */
export const EMBEDDED_VIDEO_CONVERT: Converter<EmbeddedVideoElement> = (node) => {
    const embeddedVideoChildren: EmbeddedType['children'] = [
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:type',
                value: 'video.other',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:title',
                value: node?.title || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:description',
                value: node?.description || '',
            },
            children: [],
        },
        {
            type: 'element',
            name: 'data',
            attributes: {
                name: 'og:image',
                value: node?.thumbnailUrl || '',
            },
            children: [],
        },
    ];

    const ditaNode: EmbeddedType = {
        type: 'element',
        name: 'data-about',
        attributes: {
            href: node?.url || '',
            scope: 'external',
            type: 'lumapps:embedded',
        },
        children: embeddedVideoChildren,
    };
    return ditaNode;
};
