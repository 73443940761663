/* eslint-disable no-param-reassign */
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { SpaceUserRole } from '../../types';

interface SpaceUserListItem {
    id: string;
    fullName: string;
    profilePictureUrl: string;
    role: SpaceUserRole;
}

interface SpaceUserList {
    users: Array<SpaceUserListItem>;
    hasInit: boolean;
    isLoading: boolean;
    hasError: boolean;
    hasMore: boolean;
    cursor: string | null;
    searchQuery?: string;
}

const initialState: SpaceUserList = {
    users: [],
    hasInit: false,
    isLoading: false,
    hasError: false,
    hasMore: false,
    cursor: null,
    searchQuery: undefined,
};

const { actions, reducer } = createSlice({
    domain: 'space/members',
    initialState,
    reducers: {
        request: (state: SpaceUserList) => {
            state.hasInit = true;
            state.isLoading = true;
        },
        success: (
            state: SpaceUserList,
            action: PayloadAction<Pick<SpaceUserList, 'users' | 'hasMore' | 'cursor'> & { shouldAdd: boolean }>,
        ) => {
            const { users, hasMore, cursor, shouldAdd } = action.payload;

            state.isLoading = false;
            state.users = shouldAdd ? [...state.users, ...users] : users;
            state.hasMore = hasMore;
            state.cursor = cursor;
            state.hasError = false;
        },
        failure: (state: SpaceUserList) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSearchQuery: (state: SpaceUserList, action: PayloadAction<string>) => {
            if (state.searchQuery !== action.payload) {
                state.searchQuery = action.payload;
                state.cursor = null;
                state.hasMore = false;
            }
        },
    },
});

export { actions, initialState, reducer };
