/* eslint-disable lines-around-directive */
(function IIFE() {
  // eslint-disable-next-line strict
  'use strict';

  function WidgetLearningCertificateController(ReduxStore, Community, WidgetSettingsConstant) {
    'ngInject';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller object.
     */
    this.setParentController = function setParentController(parentCtrl) {
      vm.parentCtrl = parentCtrl;
    };

    /**
     * Get widget classes.
     *
     * @return {Array} An array of widget css classes.
     */
    function getWidgetClass() {
      const widgetClass = [];

      vm.parentCtrl.getWidgetClass(widgetClass);

      return widgetClass;
    }


    function isEmpty() {
      const state = ReduxStore.store.getState();
      const widgetStatus = state.widget?.entities[vm.widget.uuid]?.state

      vm.hasNoResults = widgetStatus === 'empty' ? true : false

      return vm.hasNoResults;
    }

    /**
     * Function that conditions the rendering of the widget content.
     */
    function isWidgetEmpty() {
      return vm.parentCtrl.designerMode() && isEmpty();
    }

    /**
     * In designer mode, when the widget is empty
     *
     * In reader mode, when the widget is empty, it is not displayed at all
     */
    function isWidgetHidden() {
      return !vm.parentCtrl.designerMode() && isEmpty();
    }

    vm.getWidgetClass = getWidgetClass;
    vm.isWidgetEmpty = isWidgetEmpty;
    vm.isWidgetHidden = isWidgetHidden;

    function init() {
      const properties = vm.widget.properties;
      const community = Community.getCurrent();
      const isInSpace = community ? community.renderingType === 'space' : false;
      const spaceId = community ? community.id : ""

      // If there is no display type, we set 'PICK' or 'current' (if in space) by default.
      const defaultDisplayType = isInSpace
        ? WidgetSettingsConstant.SELECTION_TYPES.CURRENT_COMMUNITY
        : WidgetSettingsConstant.SELECTION_TYPES.PICK;
      

      properties.spaceId = properties.spaceId || spaceId
      properties.displayType = properties.displayType || defaultDisplayType;

    }
    init();
   }

  function widgetLearningCertificateDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
      ctrls[0].setParentController(ctrls[1]);
    }

    return {
      bindToController: true,
      controller: WidgetLearningCertificateController,
      controllerAs: 'vm',
      replace: true,
      require: ['widgetLearningCertificate', '^widget'],
      restrict: 'E',
      link,
      scope: {
        widget: '=',
      },
      templateUrl:
        '/client/front-office/modules/content/modules/widget/modules/widget-learning-certificate/views/widget-learning-certificate.html',
    };
  }

  window.angular.module('Widgets').directive('widgetLearningCertificate', widgetLearningCertificateDirective);
})();