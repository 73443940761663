import { LikeState } from '../api/types';
import { WithReactions } from '../api/v2/types';

/**
 * Convert reactions to like state
 */
export const asLikeState = (reactions: Partial<WithReactions>): LikeState => {
    const { reactionCounts, currentUserReactions } = reactions;
    const likeCount = reactionCounts?.details?.find(({ type }) => type === 'like')?.count || 0;
    const isLiked = Boolean(currentUserReactions?.includes('like'));
    return { isLiked, likeCount };
};
