import React from 'react';

import { get as getConfig } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { MODES, useModes } from '@lumapps/customizations/modes';
import { useSelector } from '@lumapps/redux/react';
import { Route } from '@lumapps/router';
import { ROUTER_DATA_NAMESPACES } from '@lumapps/router/ducks/types';
import { useLinkProps, UseLinkOptions, UseLinkProps } from '@lumapps/router/hooks/useLinkProps';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';

import { isUserProfileReactEnabled } from '../ducks/selector';
import { socialProfile, SocialProfileRouteParams } from '../routes';
import { UserProfileRoutingData } from '../types';

export interface UseSocialProfileRouteProps {
    /** The params for the social profile route. */
    routeParams?: Omit<SocialProfileRouteParams, 'isUserSpaceEnabled'>;
    /** The params for the link. Sames as `useLinkProps` hook. */
    linkPropsParams?: Omit<UseLinkOptions, 'route' | 'routerData'>;
    /** user data to pre-fill when redirected to the profile. */
    userData?: UserProfileRoutingData['user'];
    /** current application id */
    appId?: AppId;
}

export interface UseSocialProfileRouteResponse {
    /** The resolved route to the social profile. */
    route: Route;
    /** The props to set on the link to properly manage redirection. */
    linkProps: UseLinkProps;
}

const { applicationId } = getConfig();

/**
 * Manage routing to a user's profile page.
 * This hook will:
 * * Return the correct route between legacy / new user page depending on feature flag / parameters, etc...
 * * Return the correct link props to manage proper routing using `useLinkProps`
 * * Store given user data to display while arriving on the user profile.
 *
 * When in React context, use it instead of directly calling socialProfile()
 *
 ** Usage example with route:
 * ```
 * const { route: userProfileRoute } = useSocialProfileRoute({
 *      routeParams: { userId },
 *  });
 *
 *
 * const Component = () => (
 *     <Link to={userProfileRoute}>
 *         Redirect to the user profile page
 *     </Link>
 * );
 * ```
 *
 * * Usage example with link Props:
 * ```
 * const { linkProps } = useSocialProfileRoute({
 *      routeParams: { userId },
 *      linkPropsParams: { openInNewTab: true }
 *  });
 *
 *
 * const Component = () => (
 *     <a {...linkProps}>
 *         Redirect to the user profile page
 *     </a>
 * );
 * ```
 *
 * * Usage example with userData:
 *
 *  const { linkProps } = useSocialProfileRoute({
 *      routeParams: { userId },
 *      userData: {
 *          id: userId,
 *          fullName: user.fullName,
 *      }
 *  });
 *
 *  const Component = () => (
 *     <a {...linkProps}>
 *         Redirect to the user profile page
 *     </a>
 * );
 */
export const useSocialProfileRoute = ({
    routeParams = {},
    linkPropsParams,
    userData,
    appId = applicationId,
}: UseSocialProfileRouteProps): UseSocialProfileRouteResponse => {
    const { isModeEnabled } = useModes();
    const hasUserProfileReactEnabled =
        useSelector(isUserProfileReactEnabled) || isModeEnabled(MODES.USER_PROFILE_REACT);
    const currentUser = useSelector(connectedUserSelector);

    const socialProfileRoute = React.useMemo(() => {
        const isWebviewApp = appId === AppId.webview;

        let userId: string | undefined;
        // Only set id if not the current user id or on the webview app.
        if (isWebviewApp || currentUser?.id !== routeParams.userId) {
            userId = routeParams.userId;
        }

        return socialProfile({
            ...routeParams,
            isUserSpaceEnabled: hasUserProfileReactEnabled,
            view: routeParams.view,
            userId,
        });
    }, [appId, currentUser?.id, hasUserProfileReactEnabled, routeParams]);

    const routerData = userData
        ? {
              id: ROUTER_DATA_NAMESPACES.USER_PROFILE,
              data: {
                  user: userData,
              },
          }
        : undefined;

    const profileLinkProps = useLinkProps({ route: socialProfileRoute, routerData, ...linkPropsParams });

    return { route: socialProfileRoute, linkProps: profileLinkProps };
};
