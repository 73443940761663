(function IIFE() {
    'use strict';

    /////////////////////////////

    /**
     * An object that contains the fields required for the community.get and community.list endpoint calls.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    var _COMMUNITY_FIELDS = {
        adminKeys: true,
        adminsDetails: true,
        author: true,
        authorDetails: true,
        calendarId: true,
        calendarSettings: true,
        canContribute: true,
        canMarkRelevant: true,
        chat: true,
        communityFeedKey: true,
        communityType: true,
        customer: true,
        description: true,
        driveFolder: true,
        editors: true,
        feedKeys: true,
        hasCalendarId: true,
        hasDriveFolder: true,
        header: true,
        headerDetails: true,
        id: true,
        instance: true,
        postStatuses: true,
        postTypes: true,
        privacy: true,
        properties: true,
        hasArticlesEnabled: true,
        hasEventsEnabled: true,
        publishers: true,
        renderingType: true,
        slug: true,
        securedRepository: true,
        subscription: true,
        tagsDetails: true,
        tagz: true,
        template: true,
        templates: true,
        thumbnail: true,
        title: true,
        type: true,
        uid: true,
        useChat: true,
        userContent: true,
        userKeys: true,
        usersDetails: true,
        version: true,
        workspace: true,
    };

    /**
     * An object that contains the fields required for the community.list endpoint call when called from the
     * community picker. This picker can be found by clicking on the `new post` button in the application header
     * and in the `move post` dialog.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    var _COMMUNITY_PICKER_FIELDS = {
        customer: true,
        description: true,
        editors: true,
        feedKeys: true,
        id: true,
        instance: true,
        privacy: true,
        properties: true,
        publishers: true,
        slug: true,
        thumbnail: true,
        title: true,
        type: true,
        uid: true,
        userKeys: true,
    };

    /**
     * An object that contains all required fields needed for the community user list call.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    var _COMMUNITY_USER_FIELDS = {
        apiProfile: true,
        customProfile: true,
        customer: true,
        email: true,
        firstName: true,
        fullName: true,
        id: true,
        lastName: true,
        primaryEmail: true,
        profilePicture: true,
        profilePictureUrl: true,
        profileStatus: true,
        uid: true,
    };

    /**
     * An object holding various options related to community.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    var CommunityConstant = {
        /**
         * The fields and properties to be listed in the response of the community endpoint.
         *
         * @type {Object}
         */
        PROJECTION: {
            GET: _COMMUNITY_FIELDS,
            LIST: {
                items: _COMMUNITY_FIELDS,
            },
            PICKER: {
                items: _COMMUNITY_PICKER_FIELDS,
            },
            USER_LIST: {
                items: _COMMUNITY_USER_FIELDS,
            },
        },
    };

    /////////////////////////////

    angular.module('Constants').constant('CommunityConstant', CommunityConstant);
})();
