import React from 'react';

import { mdiImage } from '@lumapps/lumx/icons';
import { UploadDocument, UploadDocumentProps } from '@lumapps/medias-document/components/UploadDocument';
import { useTranslate } from '@lumapps/translations';

import { INSTANCE } from '../../keys';

export interface InstanceFavIconProps {
    /** callback to be executed once the image has been uploaded */
    onUploaded?: UploadDocumentProps['onDocumentUploaded'];
    /** already selected image */
    selected?: UploadDocumentProps['selectedDocument'];
}

/**
 * Component that manages the favicon of an instance
 * @param InstanceFavIconProps
 * @returns InstanceFavIcon
 */
export const InstanceFavIcon: React.FC<InstanceFavIconProps> = ({ onUploaded, selected }) => {
    const { translateKey } = useTranslate();

    return (
        <UploadDocument
            thumbnailProps={{
                size: 'xxl',
                aspectRatio: 'horizontal',
            }}
            icon={mdiImage}
            size="xxl"
            onDocumentUploaded={onUploaded}
            selectedDocument={selected}
            label={translateKey(INSTANCE.FAVICON)}
            canBePreviewed
            uploaderLabel={translateKey(INSTANCE.UPLOAD_FAVICON)}
            useBlobLocalStorage
        />
    );
};
