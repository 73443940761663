/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';

import { mapStateToProps } from '../../mapStateToProps';
import { BroadcastSideNavigation } from './BroadcastSideNavigation';

const mapDispatchToProps = {};

const ConnectedSideNavigation = connect(mapStateToProps, mapDispatchToProps)(BroadcastSideNavigation);

export { ConnectedSideNavigation as BroadcastSideNavigation };
