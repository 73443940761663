import React from 'react';

import { classnames, visuallyHidden } from '@lumapps/classnames';

import { BASE_CLASSNAME, LOADING_ALERT_DELAY } from '../constants';
import { MenuItemsSkeleton, MenuItemsSkeletonProps } from './MenuItemsSkeleton';

export interface MenuSkeletonProps extends MenuItemsSkeletonProps {
    /**
     * Class name
     */
    className?: string;
    /**
     * Width style.
     */
    width?: React.CSSProperties['width'];
    /**
     * Customize menu skeleton content.
     * (Use `Menu.ItemSkeleton` and  `Menu.SectionSkeleton`).
     */
    children?: React.ReactNode;
}

export const CLASSNAME = `${BASE_CLASSNAME}-skeleton`;

/**
 * Menu skeleton.
 *
 * @family Menus
 */
export const MenuSkeleton = React.forwardRef<HTMLElement, MenuSkeletonProps>(
    ({ className, hasIcon, width = 200, loadingMessage, children, itemCount }, ref) => {
        if (!loadingMessage) {
            throw new Error(
                'Prop `loadingMessage` must be filled to announce the loading state of the menu to screen readers.',
            );
        }
        // Delay showing the loading message in case the loading is very short.
        const [showLoadingMessage, setShowLoadingMessage] = React.useState(false);
        React.useEffect(() => {
            const timer = setTimeout(() => setShowLoadingMessage(true), LOADING_ALERT_DELAY);
            return () => clearTimeout(timer);
        }, []);
        return (
            <ul
                ref={ref as React.Ref<HTMLUListElement>}
                role="none"
                className={classnames(className, CLASSNAME, 'lumx-list lumx-list--item-padding-big')}
                style={{ width }}
            >
                <li role="status" className={visuallyHidden()}>
                    {showLoadingMessage && loadingMessage}
                </li>
                {React.Children.count(children) > 0 ? (
                    children
                ) : (
                    <MenuItemsSkeleton hasIcon={hasIcon} itemCount={itemCount} loadingMessage={loadingMessage} />
                )}
            </ul>
        );
    },
);
