/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ReactElement, useMemo } from 'react';

import values from 'lodash/values';

import { BlockContributionField } from '@lumapps/communities/components/BlockContributionField';
import { canUserContribute } from '@lumapps/communities/ducks/selectors';
import { ContributionFieldProps } from '@lumapps/communities/types';
import { get as getConfig } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { Divider, FlexBox, Theme } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { selectSpacePermissions } from '@lumapps/spaces/ducks/selectors';
import { BlockAddDirectoryUserEntry } from '@lumapps/widget-directory-entries/components/BlockAddDirectoryUserEntry';
import type { BackgroundStyles, BorderStyles, InnerSpacingStyles } from '@lumapps/widget-styles/types';

import { WidgetState } from '../../../ducks/type';
import {
    BaseArrayContainerBlock,
    BlockAugmentedContainer as BlockAugmentedContainerType,
    BlockComponent,
    ContainerBlockVariant,
    isBlockSlideshow,
    LegacyWidget,
} from '../../../types';
import { WidgetContentProps } from '../../WidgetContent';
import { WidgetSkeleton } from '../../WidgetSkeleton';
import { BlockList } from '../BlockList/BlockList';
import { BlockNoResults } from '../BlockNoResults';
import { renderBlock } from '../renderBlockTree';
import { BlockContainerFilters } from './BlockContainerFilters';
import { useBlockFilters } from './useBlockFilters';

import './index.scss';

export interface BlockAugmentedContainerProps extends BlockAugmentedContainerType {
    theme?: Theme;
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;
    loadingState: WidgetState['state'];
    /** The legacy widget object, to handle widgets used out of layout. */
    legacyWidget?: LegacyWidget;
    /** The component displayed when the container list status is loadingmore. default: <Progress /> */
    loadingStateRenderer?: React.FC;
    /** The function triggered when clicking the load more button, passed to the container block. */
    onLoadMore?(): void;
    contributionField?: ContributionFieldProps;
    /** Optional blockProperties passed down from a WidgetContent */
    blockProperties?: WidgetContentProps<'blockProperties'>;
}

const CLASSNAME = 'block-augmented-container';

/**
 * This block renders an augmented container block, with possible filters and contribution field.
 */
export const BlockAugmentedContainer: BlockComponent<BlockAugmentedContainerProps> = ({
    theme = Theme.light,
    children,
    filters,
    widget,
    legacyWidget,
    loadingState,
    loadingStateRenderer,
    contentStyles,
    onLoadMore,
    contributionField,
    container = {},
    blockProperties,
    header,
    path = [],
}) => {
    const { activeFilters, onApply, onReset, onFilterChange } = useBlockFilters({
        widgetId: widget?.widgetId,
        widgetType: widget?.widgetType,
        isMainWidget: widget?.isMainWidget,
        blockResolutionInfo: widget?.blockResolutionInfo,
        legacyWidget,
    });

    const constants = getConfig();
    const { hasSeparator = true, variant = ContainerBlockVariant.grouped } = container as BaseArrayContainerBlock;
    const { canContribute: spaceUserCanContribute } = useSelector(selectSpacePermissions) || {};
    const communityUserCanContribute = useSelector(canUserContribute);
    const shouldDisplayContributionField =
        contributionField &&
        (spaceUserCanContribute || communityUserCanContribute) &&
        // Hide contribution field on webview (mobile app)
        constants.applicationId !== AppId.webview;
    const shouldDisplayFilters = filters && widget?.widgetType;

    const toRender = useMemo(() => {
        if (loadingState === 'loadingcontent') {
            return <WidgetSkeleton widgetType={widget?.widgetType} widgetId={widget?.widgetId} />;
        }

        const childrenArray = React.Children.toArray(children).filter(Boolean).filter(React.isValidElement);

        if ((childrenArray[0].type as any).displayName === BlockNoResults.displayName) {
            return React.cloneElement(childrenArray[0] as ReactElement, {
                style: contentStyles,
                loadingState,
                isFiltered: values(activeFilters).some(Boolean),
                onClearFilters: onReset,
                searchValue: activeFilters?.searchTerm,
            });
        }

        if (childrenArray.length === 1) {
            // Inject load more and content styles props in block children.
            return React.cloneElement(childrenArray[0] as ReactElement, {
                loadingStateRenderer,
                contentStyles,
                onLoadMore,
                loadingState,
                blockProperties,
            });
        }

        return children;
    }, [
        loadingState,
        children,
        widget?.widgetType,
        widget?.widgetId,
        contentStyles,
        onReset,
        activeFilters,
        loadingStateRenderer,
        onLoadMore,
        blockProperties,
    ]);

    return !isBlockSlideshow(container) && (filters || contributionField || header) ? (
        <FlexBox orientation="vertical" gap="huge">
            {header && <BlockAddDirectoryUserEntry {...header} theme={theme} widget={widget} />}
            {shouldDisplayContributionField && <BlockContributionField theme={theme} style={contentStyles} />}
            <FlexBox className={CLASSNAME} orientation="vertical" gap="huge">
                {shouldDisplayFilters && (
                    <BlockList
                        theme={theme}
                        hasSeparator={hasSeparator}
                        contentStyles={contentStyles}
                        variant={variant}
                        loadingState="loaded"
                    >
                        <BlockContainerFilters
                            theme={theme}
                            activeFilters={activeFilters!}
                            onApply={onApply!}
                            onReset={onReset!}
                            onFilterChange={onFilterChange!}
                            widgetType={widget?.widgetType}
                            loadingState={loadingState}
                            {...filters}
                        />
                    </BlockList>
                )}
                {variant === ContainerBlockVariant.grouped &&
                    hasSeparator &&
                    // Don't dispay sepeartor if there's nothing before
                    (shouldDisplayContributionField || shouldDisplayFilters) && <Divider theme={theme} />}
                {toRender}
            </FlexBox>
        </FlexBox>
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <FlexBox orientation="vertical" gap="huge">
            {header && renderBlock(path, header, widget)}
            {toRender}
        </FlexBox>
    );
};

BlockAugmentedContainer.displayName = 'BlockAugmentedContainer';
