import last from 'lodash/last';

import { isHotKey } from '@lumapps/utils/browser/isHotKey';
import { onKey } from '@lumapps/utils/event/onKey';
import { and } from '@lumapps/utils/function/predicate/and';
import { or } from '@lumapps/utils/function/predicate/or';
import { Editor, Element, Location, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { isOnFirstLineOfBlock } from '@lumapps/wrex/slate/utils/isOnFirstLineOfBlock';
import { isOnLastLineOfBlock } from '@lumapps/wrex/slate/utils/isOnLastLineOfBlock';

import type { TableEditor, TRElement } from '../../types';
import { getCurrentRowsInTable } from '../../utils/getCurrentRowsInTable';
import { isTable } from '../../utils/isTable';
import { isTableCell } from '../../utils/isTableCell';
import { isTableRow } from '../../utils/isTableRow';

/**
 * Checks the exit of a cell from the bottom of it.
 * It returns true only if the cursor is on the last LINE of the last BLOCK child of a cell.
 * @param editor
 * @returns Returns whether if we are navigating out of a cell with the down arrow.
 */
export const isExtendingSelectionToBottom = (editor: ReactEditor & TableEditor) => (event: KeyboardEvent) => {
    // If selection is not defined, we don't want to apply the effect
    if (!editor.selection) {
        return false;
    }

    // If the pressed key is not the Shift+ArrowDown, we don't want to apply the effect
    const isArrowDownPressed = isHotKey('Shift+ArrowDown')(event);
    if (!isArrowDownPressed) {
        return false;
    }

    // We get the parent cell of the current selection
    const [parentCellEntry] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isTableCell }));
    if (!parentCellEntry) {
        return false;
    }

    // We get the current block at selection
    const [currentBlockEntry] = Array.from(
        Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
            mode: 'lowest',
        }),
    );

    // If the current block is not the last block of the cell, we don't want to apply the effect
    const isLastBlockOfCell = parentCellEntry[0].children.length - 1 === last(currentBlockEntry[1]);
    if (!isLastBlockOfCell) {
        return false;
    }

    return isOnLastLineOfBlock(editor, currentBlockEntry[0]);
};

/**
 * Checks the exit of a cell from the top of it.
 * It returns true only if the cursor is on the first LINE of the first BLOCK child of a cell.
 * @param editor
 * @returns Returns whether if we are navigating out of a cell with the up arrow.
 */
export const isExtendingSelectionToTop = (editor: ReactEditor & TableEditor) => (event: KeyboardEvent) => {
    // If selection is not defined, we don't want to apply the effect
    if (!editor.selection) {
        return false;
    }

    // If the pressed key is not the Shift+ArrowUp, we don't want to apply the effect
    const isArrowUpPressed = isHotKey('Shift+ArrowUp')(event);
    if (!isArrowUpPressed) {
        return false;
    }

    // We get the parent cell of the current selection
    const [parentCellEntry] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isTableCell }));
    if (!parentCellEntry) {
        return false;
    }

    // We get the current block at selection
    const [currentBlockEntry] = Array.from(
        Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
            mode: 'lowest',
        }),
    );

    // If the current block is not the first block of the cell, we don't want to apply the effect
    const isFirstBlockOfCell = last(currentBlockEntry[1]) === 0;
    if (!isFirstBlockOfCell) {
        return false;
    }

    return isOnFirstLineOfBlock(editor, currentBlockEntry[0]);
};

/**
 * Checks the exit of a cell from the bottom of it.
 * It returns true only if the cursor is on the last LINE of the last BLOCK child of a cell.
 * @param editor
 * @returns Returns whether if we are navigating out of a cell with the down arrow.
 */
export const isExitingCellFromBottom = (editor: ReactEditor & TableEditor) => (event: KeyboardEvent) => {
    // If selection is not defined, we don't want to apply the effect
    if (!editor.selection) {
        return false;
    }

    // If the pressed key is not the ArrowDown, we don't want to apply the effect
    const isArrowDownPressed = isHotKey('ArrowDown')(event);
    if (!isArrowDownPressed) {
        return false;
    }

    // We get the parent cell of the current selection
    const [parentCellEntry] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isTableCell }));
    if (!parentCellEntry) {
        return false;
    }

    // We get the current block at selection
    const [currentBlockEntry] = Array.from(
        Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
            mode: 'lowest',
        }),
    );

    // If the current block is not the last block of the cell, we don't want to apply the effect
    const isLastBlockOfCell = parentCellEntry[0].children.length - 1 === last(currentBlockEntry[1]);
    if (!isLastBlockOfCell) {
        return false;
    }

    return isOnLastLineOfBlock(editor, currentBlockEntry[0]);
};

/**
 * Checks the exit of a cell from the top of it.
 * It returns true only if the cursor is on the first LINE of the first BLOCK child of a cell.
 * @param editor
 * @returns Returns whether if we are navigating out of a cell with the up arrow.
 */
export const isExitingCellFromTop = (editor: ReactEditor & TableEditor) => (event: KeyboardEvent) => {
    // If selection is not defined, we don't want to apply the effect
    if (!editor.selection) {
        return false;
    }

    // If the pressed key is not the ArrowUp, we don't want to apply the effect
    const isArrowUpPressed = isHotKey('ArrowUp')(event);
    if (!isArrowUpPressed) {
        return false;
    }

    // We get the parent cell of the current selection
    const [parentCellEntry] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isTableCell }));
    if (!parentCellEntry) {
        return false;
    }

    // We get the current block at selection
    const [currentBlockEntry] = Array.from(
        Editor.nodes(editor, {
            at: editor.selection,
            match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
            mode: 'lowest',
        }),
    );

    // If the current block is not the first block of the cell, we don't want to apply the effect
    const isFirstBlockOfCell = last(currentBlockEntry[1]) === 0;
    if (!isFirstBlockOfCell) {
        return false;
    }

    return isOnFirstLineOfBlock(editor, currentBlockEntry[0]);
};

export const isInATable = (editor: ReactEditor & TableEditor) => () => {
    if (!editor.selection) {
        return false;
    }

    const currentTable = Array.from(
        Editor.nodes(editor, {
            at: editor.selection,
            match: isTable,
        }),
    );

    return currentTable.length > 0;
};

/**
 * Initialize key event handler for the table editor.
 *
 * @param editor          The editor
 */
export const getKeyHandler = (editor: ReactEditor & TableEditor) => {
    return onKey(
        [
            {
                match: isExitingCellFromBottom(editor),
                handler: () => {
                    if (!editor.selection) {
                        return;
                    }
                    // We get the table parent at the current selection
                    const [tableEntry] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isTable }));
                    const tablePath = tableEntry[1];
                    const { rowsLength } = getCurrentRowsInTable(editor, tablePath);
                    const [[, currentCellPath]] = Array.from(
                        Editor.nodes(editor, { at: editor.selection, match: isTableCell }),
                    );
                    const currentCellCoordinates = currentCellPath.slice(-2);

                    if (!currentCellCoordinates) {
                        return;
                    }

                    const nextRowIndex = currentCellCoordinates[0] + 1;
                    const isThereARowAfterCurrent = nextRowIndex < rowsLength;

                    if (!isThereARowAfterCurrent) {
                        // If there is no row after, then we move the cursor out of the table (after)
                        const newTargetPoint = Editor.after(editor, tablePath, {
                            distance: 1,
                            unit: 'block',
                        });
                        if (newTargetPoint) {
                            Transforms.select(editor, {
                                anchor: newTargetPoint,
                                focus: newTargetPoint,
                            });
                        }
                    } else {
                        // If there is a row after, then we move the cursor in the next row (same column)
                        Transforms.select(editor, {
                            anchor: { path: [...tablePath, nextRowIndex, currentCellCoordinates[1], 0, 0], offset: 0 },
                            focus: { path: [...tablePath, nextRowIndex, currentCellCoordinates[1], 0, 0], offset: 0 },
                        });
                    }
                },
            },
            {
                match: isExitingCellFromTop(editor),
                handler: () => {
                    if (!editor.selection) {
                        return;
                    }

                    // We get the table parent at the current selection
                    const [tableEntry] = Array.from(Editor.nodes(editor, { at: editor.selection, match: isTable }));
                    const tablePath = tableEntry[1];
                    const [[, currentCellPath]] = Array.from(
                        Editor.nodes(editor, { at: editor.selection, match: isTableCell }),
                    );
                    const currentCellCoordinates = currentCellPath.slice(-2);

                    if (!currentCellCoordinates) {
                        return;
                    }

                    const previousRowIndex = currentCellCoordinates[0] - 1;
                    const isThereARowBeforeCurrent = previousRowIndex >= 0;

                    if (!isThereARowBeforeCurrent) {
                        // If there is no row after, then we move the cursor out of the table (before)
                        const newTargetPoint = Editor.before(editor, tablePath, {
                            distance: 1,
                            unit: 'block',
                        });
                        if (newTargetPoint) {
                            Transforms.select(editor, {
                                anchor: newTargetPoint,
                                focus: newTargetPoint,
                            });
                        }
                    } else {
                        // If there is a row before, then we move the cursor in the previous row (same column)
                        Transforms.select(editor, {
                            anchor: {
                                path: [...tablePath, previousRowIndex, currentCellCoordinates[1], 0, 0],
                                offset: 0,
                            },
                            focus: {
                                path: [...tablePath, previousRowIndex, currentCellCoordinates[1], 0, 0],
                                offset: 0,
                            },
                        });
                    }
                },
            },
            {
                match: and(isInATable(editor), isHotKey('PageUp')),
                handler: () => {
                    const [[, currentCellPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTableCell,
                        }),
                    );
                    const currentCellIndex = last(currentCellPath) as number;

                    const currentRowPath = currentCellPath.slice(0, -1);
                    const currentTablePath = currentRowPath.slice(0, -1);

                    const currentRowIndex = last(currentRowPath) as number;

                    const targetRowIndex = Math.max(0, currentRowIndex - 5);

                    // Move cursor 5 rows up
                    Transforms.select(editor, {
                        path: [...currentTablePath, targetRowIndex, currentCellIndex, 0, 0],
                        offset: 0,
                    });
                },
            },
            {
                match: and(isInATable(editor), isHotKey('PageDown')),
                handler: () => {
                    const [[currentTable, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );
                    const [[, currentCellPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTableCell,
                        }),
                    );
                    const currentCellIndex = last(currentCellPath) as number;

                    const currentRowPath = currentCellPath.slice(0, -1);

                    const currentRowIndex = last(currentRowPath) as number;
                    const currentTableHeight = currentTable.children.length;

                    const targetRowIndex = Math.min(currentTableHeight - 1, currentRowIndex + 5);

                    // Move cursor 5 rows down
                    Transforms.select(editor, {
                        path: [...currentTablePath, targetRowIndex, currentCellIndex, 0, 0],
                        offset: 0,
                    });
                },
            },
            {
                match: and(isInATable(editor), isHotKey('Home')),
                handler: () => {
                    const [[, currentRowPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTableRow,
                        }),
                    );

                    // Move cursor to the first cell of the current row
                    Transforms.select(editor, { path: [...currentRowPath, 0, 0, 0], offset: 0 });
                },
            },
            {
                match: and(isInATable(editor), isHotKey('End')),
                handler: () => {
                    const [[currentRow, currentRowPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTableRow,
                        }),
                    );

                    // Move cursor to the last cell of the current row
                    Transforms.select(editor, {
                        path: [...currentRowPath, currentRow.children.length - 1, 0, 0],
                        offset: 0,
                    });
                },
            },
            {
                match: and(isInATable(editor), isHotKey('Ctrl+Home')),
                handler: () => {
                    const [[, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );

                    // Move cursor to the first cell of the first row of the table
                    Transforms.select(editor, { path: [...currentTablePath, 0, 0, 0, 0], offset: 0 });
                },
            },
            {
                match: and(isInATable(editor), isHotKey('Ctrl+End')),
                handler: () => {
                    const [[currentTable, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );
                    const lastRowIndex = currentTable.children.length - 1;
                    const lastCellIndex = (currentTable.children[lastRowIndex] as TRElement).children.length - 1;

                    // Move cursor to the last cell of the last row of the table
                    Transforms.select(editor, {
                        path: [...currentTablePath, lastRowIndex, lastCellIndex, 0, 0],
                        offset: 0,
                    });
                },
            },
            {
                // If we are in multicell selection mode, we want the Shift+Nav to add (or remove) cells from the selection
                // To do so, we move the focus of the selection (the anchor will never move), to extend or reduce the selection.
                match: or(
                    and(isInATable(editor), () => editor.getCellSelection().length > 0, isHotKey('Shift+ArrowDown')),
                    isExtendingSelectionToBottom(editor),
                ),
                handler: () => {
                    // Get the currently selected table
                    const [[currentTable, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );

                    // The table height (number of rows), direct children of table are always rows.
                    const tableHeight = currentTable.children.length;

                    // Get the cell at the focus point of the selection.
                    const focusSelectionPoint = editor.selection?.focus;
                    const [[, focusedCellPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: focusSelectionPoint,
                            match: isTableCell,
                        }),
                    );

                    // Get the focused cell coordinates
                    const [focusedCellRowIndex, focusedCellColumnIndex] = focusedCellPath.slice(-2);

                    // The target path to move the focus to.
                    const targetPath = [...currentTablePath, focusedCellRowIndex + 1, focusedCellColumnIndex, 0, 0];

                    // If we are not on the last row, move the selection focus one row down.
                    if (focusedCellRowIndex + 1 < tableHeight) {
                        Transforms.setSelection(editor, {
                            focus: {
                                offset: 0,
                                path: targetPath,
                            },
                        });
                    }
                },
            },
            {
                // If we are in multicell selection mode, we want the Shift+Nav to add (or remove) cells from the selection
                // To do so, we move the focus of the selection (the anchor will never move), to extend or reduce the selection.
                match: or(
                    and(isInATable(editor), () => editor.getCellSelection().length > 0, isHotKey('Shift+ArrowUp')),
                    isExtendingSelectionToTop(editor),
                ),
                handler: () => {
                    // Get the currently selected table
                    const [[, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );

                    // Get the cell at the focus point of the selection.
                    const focusSelectionPoint = editor.selection?.focus;
                    const [[, focusedCellPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: focusSelectionPoint,
                            match: isTableCell,
                        }),
                    );

                    // Get the focused cell coordinates
                    const [focusedCellRowIndex, focusedCellColumnIndex] = focusedCellPath.slice(-2);

                    // The target path to move the focus to.
                    const targetPath = [...currentTablePath, focusedCellRowIndex - 1, focusedCellColumnIndex, 0, 0];

                    // If we are not on the first row, move the selection focus one row up.
                    if (focusedCellRowIndex - 1 >= 0) {
                        Transforms.setSelection(editor, {
                            focus: {
                                offset: 0,
                                path: targetPath,
                            },
                        });
                    }
                },
            },
            {
                // If we are in multicell selection mode, we want the Shift+Nav to add (or remove) cells from the selection
                // To do so, we move the focus of the selection (the anchor will never move), to extend or reduce the selection.
                match: and(isInATable(editor), () => editor.getCellSelection().length > 0, isHotKey('Shift+ArrowLeft')),
                handler: () => {
                    // Get the currently selected table
                    const [[, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );

                    // Get the cell at the focus point of the selection.
                    const focusSelectionPoint = editor.selection?.focus;
                    const [[, focusedCellPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: focusSelectionPoint,
                            match: isTableCell,
                        }),
                    );

                    // Get the focused cell coordinates
                    const [focusedCellRowIndex, focusedCellColumnIndex] = focusedCellPath.slice(-2);

                    // The target path to move the focus to.
                    const targetPath = [...currentTablePath, focusedCellRowIndex, focusedCellColumnIndex - 1, 0, 0];

                    // If we are not on the first column, move the selection focus one column left.
                    if (focusedCellColumnIndex - 1 >= 0) {
                        Transforms.setSelection(editor, {
                            focus: {
                                offset: 0,
                                path: targetPath,
                            },
                        });
                    }
                },
            },
            {
                // If we are in multicell selection mode, we want the Shift+Nav to add (or remove) cells from the selection
                // To do so, we move the focus of the selection (the anchor will never move), to extend or reduce the selection.
                match: and(
                    isInATable(editor),
                    () => editor.getCellSelection().length > 0,
                    isHotKey('Shift+ArrowRight'),
                ),
                handler: () => {
                    // Get the currently selected table
                    const [[currentTable, currentTablePath]] = Array.from(
                        Editor.nodes(editor, {
                            at: editor.selection as Location,
                            match: isTable,
                        }),
                    );

                    // Get the cell at the focus point of the selection.
                    const focusSelectionPoint = editor.selection?.focus;
                    const [[, focusedCellPath]] = Array.from(
                        Editor.nodes(editor, {
                            at: focusSelectionPoint,
                            match: isTableCell,
                        }),
                    );

                    // Get the focused cell coordinates
                    const [focusedCellRowIndex, focusedCellColumnIndex] = focusedCellPath.slice(-2);

                    // The width of the table (number of cells in a row), all rows have the same count of cells.
                    const tableWidth = (currentTable.children[0] as TRElement).children.length;

                    // The target path to move the focus to.
                    const targetPath = [...currentTablePath, focusedCellRowIndex, focusedCellColumnIndex + 1, 0, 0];

                    // If we are not on the last column, move the selection focus one column right.
                    if (focusedCellColumnIndex + 1 < tableWidth) {
                        Transforms.setSelection(editor, {
                            focus: {
                                offset: 0,
                                path: targetPath,
                            },
                        });
                    }
                },
            },
        ],
        { preventDefault: true },
    );
};
