/**
 * Receives a list ['string 1', 'string 2', 'string 3', 'string 4'],
 * and returns { firstFilter: 'string 1, string 2, string 3', filterNumber: 1 }.
 * so we can display 'string 1, string 2, string 3 + 1' thanks to the result
 * @param list - list of string to limit
 * @param limit - number of items to limit
 * @returns an object with a string limited to the amount of items + the number of missing strings
 */
export const getLimitedListToString = (list: string[], limit = 3) => {
    if (list.length) {
        return {
            firstFilter: list.slice(0, limit).join(', '),
            filterNumber: Math.max(0, list.length - limit),
        };
    }

    return undefined;
};
