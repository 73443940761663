import { useQuery } from '@lumapps/base-api/react-query';

import { getIcon, GetIconParams, iconQueryKeys } from '../api/icons';

const DEFAULT_SVG = 'M20 20';

export interface UseDynamicIconProps extends Omit<GetIconParams, 'icon'> {
    icon?: GetIconParams['icon'];
}

/**
 * Hook used to retrieve an icon when the icon was chosen by
 * a user rather than defined in code. While this hook is retrieving the icon from
 * the CDN, it will return an placeholder svg in order to avoid weird UI movements
 * once the icon has appeared on the screen.
 */
const useDynamicIcon = (params: UseDynamicIconProps) => {
    const { icon } = params;

    const { data } = useQuery({
        queryKey: iconQueryKeys.getIcon(icon),
        queryFn: icon ? ({ signal }) => getIcon({ icon }, signal) : undefined,
        enabled: Boolean(icon),
        select: (data) => data.path,
        placeholderData: { path: DEFAULT_SVG },
        cacheTime: Infinity,
        staleTime: Infinity,
    });

    return {
        iconPath: data || DEFAULT_SVG,
    };
};

export { useDynamicIcon, DEFAULT_SVG };
