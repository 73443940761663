import React from 'react';

import classNames from 'classnames';

import { any, func, string, objectOf } from 'prop-types';

import { translate as t } from '../../../../../translations';

/**
 * Display a task in the widget MyTasks component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The MyTasks task item react element.
 */
const TaskItem = ({ task, uuid, checkItem, removeTodo }) => {
    return (
        <div className="widget-todo-task">
            <input
                checked={task.isChecked}
                className={classNames('widget-todo-task__checkbox', {
                    'widget-todo-task__checkbox--is-checked': task.isChecked,
                    'widget-todo-task__checkbox--is-unchecked': !task.isChecked,
                })}
                id={`widget-${uuid}-checkbox-${task.id}`}
                type="checkbox"
                onChange={() => checkItem(task.id)}
            />
            <label className="widget-todo-task__label" htmlFor={`widget-${uuid}-checkbox-${task.id}`}>
                {t(task.value)}
            </label>
            <button className="widget-todo-task__remove" type="button" onClick={() => removeTodo(task)}>
                <i className="mdi mdi-close-circle" />
            </button>
        </div>
    );
};

TaskItem.propTypes = {
    checkItem: func.isRequired,
    removeTodo: func.isRequired,
    task: objectOf(any).isRequired,
    uuid: string.isRequired,
};

export default TaskItem;
