import useDimensionsHook from 'react-cool-dimensions';

import { BreakpointsObject } from '../utils/breakpoints';

type Breakpoints = BreakpointsObject<string>;

interface Input<B extends Breakpoints> {
    /** Breakpoint names as keys, associated min width as values. */
    breakpoints: B;
}

interface Output<B extends Breakpoints> {
    /** Function ref to attach on the target element. */
    ref: (e?: HTMLElement | null) => void;
    /** Current size breakpoint */
    currentBreakpoint: keyof B;
}

/**
 * Similar to `useResponsive` but working at the component level.
 *
 * In input, you provide breakpoints with associated min width.
 * In output, you get a function ref to attach on the element to target and the current breakpoint.
 */
export function useDimensions<B extends Breakpoints>(options: Input<B>): Output<B> {
    const { observe, currentBreakpoint } = useDimensionsHook({
        // Dimension breakpoints
        breakpoints: options.breakpoints,
        // Will only update the state on breakpoint changed.
        updateOnBreakpointChange: true,
    });

    return { ref: observe as any, currentBreakpoint };
}
