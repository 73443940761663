import React from 'react';
import { Size, Uploader, AspectRatio, ImageBlock, Grid, IconButton, Emphasis } from '@lumapps/lumx/react';
import { mdiImagePlus, mdiDelete } from '@lumapps/lumx/icons';

import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { bool, shape, arrayOf, string, func } from 'prop-types';

import { translate as t } from 'components/translations';
import { open as openMediaPicker } from 'components/services/media-picker';
import { MediaConstant } from 'front/modules/media/js/media_constant';
import { MAX_PHOTOS } from 'components/components/social-advocacy/ducks/shareableWorkflow/constants';
import { GLOBAL } from '@lumapps/translations';

import { getShareableImageUrl } from '../../../utils';

const ShareableImagesSelect = ({ images, onChange, onRemove, canEdit }) => {
    const handleChooseImages = (imageData) => {
        const selectedImages = isArray(imageData) ? imageData : [imageData];

        const newImages = selectedImages.map((image) => {
            const imageContent = get(image, 'content[0]', image);
            const imageUrl = imageContent.servingUrl || imageContent.url;
            const imageType = imageContent.mimeType || imageContent.type;
            const imageSize = imageContent.size;

            return { id: image.id, size: imageSize, type: imageType, url: imageUrl };
        });

        onChange(newImages);
    };

    const handleOpenUploader = () =>
        openMediaPicker(images, handleChooseImages, {
            allowedFileTypes: MediaConstant.DOCUMENT_TYPES.imagesAndFolders,
            maxSelect: MAX_PHOTOS,
            multi: true,
        });

    return (
        <div className="sa-shareable-images-select">
            {images &&
                images.length > 0 &&
                images.map((image) => (
                    <ImageBlock
                        key={image.id}
                        actions={
                            canEdit && (
                                <Grid>
                                    <IconButton
                                        hasBackground
                                        color="dark"
                                        emphasis={Emphasis.low}
                                        icon={mdiDelete}
                                        onClick={() => onRemove(image.id)}
                                        label={t('GLOBAL.DELETE')}
                                    />
                                </Grid>
                            )
                        }
                        thumbnailProps={{
                            aspectRatio: AspectRatio.square,
                        }}
                        className="sa-shareable-images-select__thumbnail"
                        image={getShareableImageUrl(image)}
                        alt=""
                    />
                ))}

            {images.length < MAX_PHOTOS && canEdit && (
                <Uploader
                    aspectRatio={AspectRatio.square}
                    icon={mdiImagePlus}
                    label={t(GLOBAL.ADD_PHOTOS)}
                    size={Size.xl}
                    onClick={handleOpenUploader}
                />
            )}
        </div>
    );
};

ShareableImagesSelect.propTypes = {
    canEdit: bool,
    images: arrayOf(
        shape({
            type: string,
            url: string,
        }),
    ),
    onChange: func,
    onRemove: func,
};

ShareableImagesSelect.defaultProps = {
    canEdit: true,
    images: [],
    onChange: null,
    onRemove: null,
};

export { ShareableImagesSelect };
