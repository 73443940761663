/* istanbul ignore file */
export enum COMMUNITY_EVENTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211214504
     */
    EVENTS_PAGE_TITLE = 'COMMUNITY_EVENTS.EVENTS_PAGE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211304351
     */
    EVENTS_PAGE_NAVIGATION_BUTTON = 'COMMUNITY_EVENTS.EVENTS_PAGE_NAVIGATION_BUTTON',
}
