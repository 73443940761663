import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { PlaylistPickerDialogProps } from '../../types';

const LazyPlaylistPickerDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'playlist-picker' */
            './PlaylistPickerDialog'
        ),
);

export const PlaylistPickerDialog = ({ isOpen, ...props }: PlaylistPickerDialogProps) => (
    <DelayedSuspense shouldLoad={isOpen}>
        {(setShouldMount) => (
            <LazyPlaylistPickerDialog onVisibilityChange={setShouldMount} isOpen={isOpen} {...props} />
        )}
    </DelayedSuspense>
);

export type { PlaylistPickerDialogProps };
