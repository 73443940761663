import rehypeParse from 'rehype-dom-parse';
import rehypeMinifyWhitespace from 'rehype-minify-whitespace';
import unified from 'unified';

import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import { HTMLPasteOptions } from '@lumapps/wrex-html-paste/types';

import type { Wrex } from '../../../types';
import { HTML_TXT_TAG_WHITELIST, HTML_TXT_ATTR_WHITELIST } from './constants';
import { rehype2slate } from './rehype2slate';

/**
 * Parse a HTML fragment into a slate fragment.
 *
 * @param html     A HTML fragment in string.
 * @param options  HTML conversion options.
 * @return A slate fragment.
 */
export const fromHTML = (html: string, options: HTMLPasteOptions) => {
    const sanitizedHtml = sanitizeHTML(html, {
        allowedTags: HTML_TXT_TAG_WHITELIST,
        allowedAttr: HTML_TXT_ATTR_WHITELIST,
    });
    const pipeline = unified()
        // Parse HTML into HAST
        .use(rehypeParse)
        // Minify white space characters.
        .use(rehypeMinifyWhitespace)
        // Transform HAST to Slate nodes.
        .use(rehype2slate, options);

    return pipeline.processSync(sanitizedHtml).result as Wrex.Nodes;
};
