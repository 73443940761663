import { CommunityThumbnailDisplayType } from '@lumapps/communities/types';
import { Tag } from '@lumapps/folksonomy/types';
import { FocusPoint } from '@lumapps/lumx/react';
import { TranslateObject } from '@lumapps/translations';
import { PostType } from '@lumapps/widget-post-list/types';

/**
 * List of subviews available for a Space.
 * All the views should be listed here.
 * */
export enum SpaceViews {
    article = 'article',
    articles = 'articles',
    calendar = 'calendar',
    documents = 'documents',
    drive = 'drive',
    event = 'event',
    events = 'events',
    feed = 'feed',
    home = 'home',
    learning = 'learning',
    posts = 'posts',
    post = 'post',
}

export interface SpaceRouteParameters {
    id: string;
    slug: string;
    view?: SpaceViews;
    identifier?: string;
}

export interface SpaceLayoutParameters {
    id: string;
    view?: SpaceViews;
}

export enum SpaceAccessRequestStatus {
    accepted = 'accepted',
    pending = 'pending',
    rejected = 'rejected',
}

export enum SpaceServices {
    article = 'article',
    calendar = 'calendar',
    documents = 'documents',
    drive = 'drive',
    event = 'event',
    feed = 'feed',
    homepage = 'homepage',
    learning = 'learning',
    posts = 'posts',
}

export enum SpaceRessources {
    post = 'post',
    event = 'event',
    article = 'article',
}

export enum SpaceVisibility {
    open = 'open',
    read_only = 'read_only',
    private = 'private',
    secret = 'secret',
}

export enum SpaceUserRole {
    member = 'member',
    manager = 'admin',
}

export interface SpaceSubscriptions {
    isNotified: boolean;
    isSubscribed: boolean;
}

export interface SpaceSubscriptionSettings {
    areNotificationsEnabledOnSubscription: boolean;
}

export interface SpacePermissions {
    canContribute: boolean;
    canDelete: boolean;
    canEdit: boolean;
    canRequestMembership: boolean;
    canSubscribe: boolean;
}

export interface SpaceMembership {
    requestDetails: {
        status: SpaceAccessRequestStatus;
    };
}

export interface SpaceCoverImage {
    url: string;
    name: string;
    mediaId: string;
    focalPoint: FocusPoint;
}

export interface SpaceGroups {
    technicalGroupId: string;
    adminGroupIds: string[];
    memberGroupIds: string[];
}

export enum SpaceServiceProvider {
    google = 'google',
    microsoft = 'microsoft',
}

export type SpaceServicesProviders = Partial<Record<SpaceServices, SpaceServiceProvider | null>>;

export interface SpaceData {
    id: string;
    slug: string;
    title: TranslateObject;
    description: TranslateObject;
    coverImage?: SpaceCoverImage;
    headerDisplayMode: CommunityThumbnailDisplayType;
    enabledServices: SpaceServices[];
    myMembership: SpaceMembership | null;
    myPermissions: SpacePermissions;
    mySubscriptions: SpaceSubscriptions;
    subscriptionSettings: SpaceSubscriptionSettings;
    visibility: SpaceVisibility;
    folksonomyTags?: Tag[];
    spaceGroups: SpaceGroups;
    providers: SpaceServicesProviders;
    postTypes: PostType[];
}

export interface SpaceMembersApiOptions {
    role?: SpaceUserRole;
    searchQuery?: string;
    cursor?: string | null;
    maxResults?: number;
}

export interface SpaceMembersApiResponse {
    items: Array<{
        id: string;
        fullName: string;
        pictureUrl: string;
        profilePrimaryFieldValues: string[];
        role: SpaceUserRole;
    }>;
    cursor: string | null;
    more: boolean;
}

export type EmptyStateAction = {
    resourceType: string;
    menuItemKey: string;
    singleActionKey: string;
    icon: string;
    onClick(): void;
};
