import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { CropperDialog } from '@lumapps/focal-point/components/CropperDialog';
import { CropperDialogProps } from '@lumapps/focal-point/types';
import { mdiCrop, mdiEye, mdiPencil, mdiTrashCan } from '@lumapps/lumx/icons';
import { FlexBox, ThumbnailProps, IconButton, Emphasis, ThumbnailSize } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { Image } from '../../types';
import { BlobThumbnail, getImageUrl } from '../BlobThumbnail';
import { useImageLightbox, ImageLightbox } from '../ImageLightbox';

import './index.scss';

export interface ImageWithActionsProps extends Omit<ThumbnailProps, 'image' | 'size'> {
    /** already selected image */
    img?: Image;
    /** unmodified image in order to be displayed on the cropper dialog */
    originalImage?: Image;
    /** whether the image is loading or not */
    isImageLoading?: boolean;
    /** callback on deleting an image */
    onDelete?: () => void;
    /** callback on edition an image */
    onEdit?: () => void;
    /** callback on image cropped */
    onCrop?: CropperDialogProps['onConfirm'];
    /** whether the image can be previewed, by opening it in a lightbox */
    canBePreviewed?: boolean;
    /** whether the image can be cropped or not */
    canBeCropped?: boolean;
    /** size of the image */
    size?: ThumbnailSize;
    /** additional cropping props */
    croppingProps?: Partial<CropperDialogProps>;
    /** whether image actions are disabled or not */
    areActionsDisabled?: boolean;
}

const CLASSNAME = 'image-with-actions';
/**
 * Displays a component that allows to display an image, preview it and edit it with
 * callbacks to each action. This component only displays the image, it does not
 * control the upload and edition of the image. For that, use the component `UploadMedia`
 * or `UploadDocument` from the `medias` package.
 *
 * If you need to control the upload itself and use a custom API, please use the `EditableImage`
 * component from `@lumapps/lumx`.
 *
 * @family Images
 * @param ImageWithActionsProps
 * @returns ImageWithActions
 */
export const ImageWithActions: React.FC<ImageWithActionsProps> = ({
    thumbnailProps,
    img,
    isImageLoading,
    onDelete,
    onEdit,
    canBePreviewed = false,
    canBeCropped = false,
    onCrop,
    size = 'xl',
    croppingProps = {},
    originalImage,
    areActionsDisabled = false,
}) => {
    const [isCroppingDialogOpen, setIsCroppingDialogOpen] = React.useState(false);
    const previewElementRef = React.useRef(null);
    const { element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    const isImageEditable = !isImageLoading;
    const { getTriggerProps, imageLightboxProps } = useImageLightbox([{ alt: '', image: getImageUrl(img) }]);

    if (!img) {
        return null;
    }

    return (
        <>
            <FlexBox className={element('image-wrapper', `size-${size}`)} orientation="horizontal">
                <BlobThumbnail
                    alt=""
                    isLoading={isImageLoading}
                    {...thumbnailProps}
                    className={element('image', [thumbnailProps?.className])}
                    img={img}
                    size={size}
                    aspectRatio="horizontal"
                    focusPoint={croppingProps.focalPoint}
                />

                <FlexBox className={element('actions')} orientation="horizontal">
                    {canBePreviewed ? (
                        <div className={element('action')}>
                            <IconButton
                                size="s"
                                className={element('preview-thumbnail-preview-btn')}
                                {...getTriggerProps()}
                                label={translateKey(GLOBAL.PREVIEW)}
                                emphasis={Emphasis.medium}
                                icon={mdiEye}
                                ref={previewElementRef}
                                isDisabled={areActionsDisabled}
                            />
                        </div>
                    ) : null}

                    {isImageEditable && canBeCropped && onCrop ? (
                        <div className={element('action')}>
                            <IconButton
                                size="s"
                                className={element('preview-thumbnail-crop-btn')}
                                onClick={() => setIsCroppingDialogOpen(true)}
                                label={translateKey(GLOBAL.CROP)}
                                emphasis={Emphasis.medium}
                                icon={mdiCrop}
                                isDisabled={areActionsDisabled}
                            />
                        </div>
                    ) : null}

                    {isImageEditable && onEdit ? (
                        <div className={element('action')}>
                            <IconButton
                                size="s"
                                className={element('preview-thumbnail-edit-btn')}
                                onClick={onEdit}
                                label={translateKey(GLOBAL.EDIT)}
                                emphasis={Emphasis.medium}
                                icon={mdiPencil}
                                isDisabled={areActionsDisabled}
                            />
                        </div>
                    ) : null}

                    {isImageEditable && onDelete ? (
                        <div className={element('action')}>
                            <IconButton
                                size="s"
                                className={element('preview-thumbnail-delete-btn')}
                                onClick={() => onDelete()}
                                label={translateKey(GLOBAL.DELETE)}
                                emphasis={Emphasis.medium}
                                icon={mdiTrashCan}
                                isDisabled={areActionsDisabled}
                            />
                        </div>
                    ) : null}
                </FlexBox>
            </FlexBox>

            {canBePreviewed ? <ImageLightbox {...imageLightboxProps} /> : null}

            {canBeCropped && onCrop ? (
                <CropperDialog
                    imageUrl={getImageUrl(originalImage || img)}
                    onConfirm={(focus, blob, cropInfo) => {
                        onCrop(focus, blob, cropInfo);

                        setIsCroppingDialogOpen(false);
                    }}
                    onCancel={() => setIsCroppingDialogOpen(false)}
                    isOpen={isCroppingDialogOpen}
                    {...croppingProps}
                />
            ) : null}
        </>
    );
};
