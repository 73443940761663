import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import set from 'lodash/set';
import uniq from 'lodash/uniq';

import {
    CommunitySearchSuccessPayload,
    CommunitySearchInitiatedPayload,
    SearchCommunityState,
    SearchSortOrder,
} from '@lumapps/communities/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { BackofficeState, DeleteCommunitiesDialogState, SaveAsTemplateState } from '../types';

const initialState: BackofficeState = {
    entities: {},
    search: {
        query: '',
        cursor: '',
        ids: [],
        hasMore: false,
        sortOrder: SearchSortOrder.reverseUpdatedAt,
        status: SearchCommunityState.initial,
    },
    selectedCommunitiesIds: [],
    delete: {
        status: DeleteCommunitiesDialogState.closed,
        communities: [],
    },
    saveAsTemplateStatus: SaveAsTemplateState.idle,
    isCreationWizardOpen: false,
};

type ErrorMessage = string;

const { actions, reducer } = createSlice({
    domain: 'community',
    initialState,
    reducers: {
        setAdminCommunitySearchInitiated: (
            state: BackofficeState,
            action: PayloadAction<CommunitySearchInitiatedPayload>,
        ) => {
            const sortChanged = action.payload.sortOrder && action.payload.sortOrder !== state.search.sortOrder;
            if (!action.payload.isLoadingMore) {
                set(state, ['search', 'ids'], []);
                set(state, ['selectedCommunitiesIds'], []);
            }
            if (action.payload.query !== undefined) {
                set(state, ['search', 'query'], action.payload.query);
            }
            if (sortChanged) {
                set(state, ['search', 'sortOrder'], action.payload.sortOrder);
                set(state, ['search', 'status'], SearchCommunityState.loadingNewSortOrder);
            } else {
                set(
                    state,
                    ['search', 'status'],
                    action.payload.isLoadingMore ? SearchCommunityState.loadingMore : SearchCommunityState.loading,
                );
            }
        },
        getAdminCommunitiesSearchSuccess: (
            state: BackofficeState,
            action: PayloadAction<CommunitySearchSuccessPayload>,
        ) => {
            const items = action.payload.items || [];
            const listByIds = keyBy(items, 'id');
            set(state, 'entities', { ...state.entities, ...listByIds });

            set(
                state,
                ['search', 'ids'],
                action.payload.add
                    ? uniq([...get(state, ['search', 'ids']), ...Object.keys(listByIds)])
                    : Object.keys(listByIds),
            );
            set(
                state,
                ['search', 'status'],
                items && items.length > 0 ? SearchCommunityState.success : SearchCommunityState.noCommunities,
            );
            set(state, ['search', 'cursor'], action.payload.cursor);
            set(state, ['search', 'hasMore'], action.payload.more);
        },
        getAdminCommunitiesSearchError: (state: BackofficeState, action: PayloadAction<ErrorMessage>) => {
            set(state, ['search', 'ids'], initialState.search.ids);
            set(state, ['search', 'status'], SearchCommunityState.failure);
            set(state, ['search', 'errorMessage'], action.payload);
        },
        selectCommunity: (state: BackofficeState, action: PayloadAction<string>) => {
            set(state, ['selectedCommunitiesIds'], state.selectedCommunitiesIds.concat([action.payload]));
        },
        unselectCommunity: (state: BackofficeState, action: PayloadAction<string>) => {
            set(
                state,
                ['selectedCommunitiesIds'],
                state.selectedCommunitiesIds.filter((id: string) => action.payload !== id),
            );
        },
        selectAllCommunities: (state: BackofficeState) => {
            set(state, ['selectedCommunitiesIds'], state.search.ids);
        },
        unselectAllCommunities: (state: BackofficeState) => {
            set(state, ['selectedCommunitiesIds'], initialState.selectedCommunitiesIds);
        },
        openDeleteDialog: (state: BackofficeState, action: PayloadAction<string[]>) => {
            set(state, ['delete', 'status'], DeleteCommunitiesDialogState.opened);
            set(state, ['delete', 'communities'], action.payload);
        },
        closeDeleteDialog: (state: BackofficeState) => {
            set(state, ['delete', 'status'], DeleteCommunitiesDialogState.closed);
        },
        requestDelete: (state: BackofficeState) => {
            set(state, ['delete', 'status'], DeleteCommunitiesDialogState.loading);
        },
        deleteError: (state: BackofficeState) => {
            set(state, ['delete', 'status'], DeleteCommunitiesDialogState.opened);
        },
        saveAsTemplate: (state: BackofficeState) => {
            set(state, 'saveAsTemplateStatus', SaveAsTemplateState.loading);
        },
        saveAsTemplateSuccess: (state: BackofficeState) => {
            set(state, 'saveAsTemplateStatus', SaveAsTemplateState.idle);
        },
        saveAsTemplateError: (state: BackofficeState) => {
            set(state, 'saveAsTemplateStatus', SaveAsTemplateState.idle);
        },
        openCreationWizard(state: BackofficeState) {
            set(state, 'isCreationWizardOpen', true);
        },
        closeCreationWizard(state: BackofficeState) {
            set(state, 'isCreationWizardOpen', false);
        },
        removeCommunity(state: BackofficeState, action: PayloadAction<string>) {
            set(
                state,
                ['search', 'ids'],
                state.search.ids.filter((id) => id !== action.payload),
            );
            set(
                state,
                'selectedCommunitiesIds',
                state.selectedCommunitiesIds.filter((id) => id !== action.payload),
            );
            set(state, 'entities', { ...omit(state.entities, action.payload) });
        },
    },
});

export { actions, reducer, initialState };
