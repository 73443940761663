import { getCurrentInstanceSummary } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { getConnectedUserSummary, isConnected } from '@lumapps/user/ducks/selectors';
import { getWhitelistedPropsForCustomisationsApi } from '@lumapps/widget-base/utils/getWhitelistedPropsForCustomisationsApi';

import { Session } from '../types';

export const contentSelector = (state: FrontOfficeStore) => {
    const { content, widget } = state;

    if (!content && !widget) {
        return {};
    }

    const { entities = {} } = widget || {};

    const widgets: Record<string, object[]> = {};

    /**
     * In this section, we retrieve all the widgets that are available on the page
     * at the moment and group them by type. We then parse the information that each
     * widget has in order to simplify accessibility and create a list of widgets
     * to return.
     *
     * This will generate something like this:
     * {
     *      ...content,
     *      loadedWidgets: {
     *           html: [{...}],
     *           'content-list': [{...}],
     *      }
     * }
     *
     * This will make it easier for developers to find and retrieve widgets on the page.
     */
    Object.keys(entities).forEach((key) => {
        const widget = entities[key];

        const { widgetType, body } = widget;
        const type = body && body.widgetType ? body.widgetType : widgetType;

        if (!widgets[type]) {
            widgets[type] = [];
        }

        widgets[type].push({
            ...getWhitelistedPropsForCustomisationsApi(widget),
            widgetType: type,
        });
    });

    return {
        ...content,
        loadedWidgets: widgets,
    };
};

export const getSession = createSelector(
    currentLanguageSelector,
    getCurrentInstanceSummary,
    getConnectedUserSummary,
    isConnected,
    (language, instance, user, isUserConnected): Session => {
        return {
            language,
            instance,
            user,
            isConnected: isUserConnected,
        };
    },
);
