import { addFrontVersionToUrl, getBaseUrl } from '@lumapps/router/utils';
import { cleanSpaces } from '@lumapps/utils/string/cleanSpaces';
import { encodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { ENGINES_TRANSLATIONS, ENGINES, SEARCH_RESULT_TYPES, DEFAULT_FILTER, FILTER_TYPES } from '../constants';
import { SearchTab, SearchFilter, CustomSearchTab, SearchFilterExtension } from '../types';

/**
 * Returns true if the search query is valid. At the moment, it means that
 * it is not undefined and it is not empty.
 *
 * @param  {string}  query Search term.
 * @return {boolean} returns true if the search term is valid.
 */
const isSearchQueryValid = (query = '') => {
    if (!query) {
        return false;
    }

    const trimmedQuery = cleanSpaces(query);

    return Boolean(trimmedQuery) && trimmedQuery.length > 0;
};

/** Converts the backends API response for filters into the desired format */
const convertTabToSearchFilter = (tab: SearchTab): SearchFilter | SearchFilterExtension => {
    const type = tab.type || tab.kind;
    /**
     * The backend is returning `lumapps` instead of `all` displayed in the url.
     */
    const value = tab.uid === ENGINES.LUMAPPS ? DEFAULT_FILTER : tab.uid;

    if (type === FILTER_TYPES.EXTENSION) {
        const filter: SearchFilterExtension = {
            value: tab.uid,
            type: FILTER_TYPES.EXTENSION,
            label: tab.localizedName,
            extensionId: tab.extensionId ?? '',
            connectorId: tab.connectorId,
            extensionSettings: tab.extensionSettings,
        };

        return filter;
    }

    return {
        value: tab.type ? tab.uid : value,
        label: tab.localizedName,
        count: tab.resultsCount,
        totalCount: tab.totalResultsCount || tab.resultsCount,
        type: type as FILTER_TYPES,
        url: tab.url,
        height: tab.height,
        sendUserIdentity: Boolean(tab.sendUserIdentity),
    };
};

/**
 * Convert a customSearchTab to a classic Search filter
 * @param tab the custom tab
 */
const convertCustomSearchTabToSearchFilter = (tab: CustomSearchTab): SearchFilter => {
    return {
        value: tab.id,
        label: tab.name,
        type: tab.type,
        url: tab.url,
    };
};

/**
 * Adds the base slug to the provided URL
 * @param url - url to fix
 */
const addBaseSlugToSearchUrl = (url: string, location?: string) => {
    let urlWithSlug = url;

    if (url && url !== '') {
        const currentLocation = location || window.location.pathname;
        const isCurrentLocationACustomDomain = currentLocation.indexOf('/a/') === -1;
        const doesUrlIncludeSlug = url.indexOf('/a/') > -1;
        const isURLAbsolute = url.indexOf('http://') === 0 || url.indexOf('https://') === 0;

        if (!isCurrentLocationACustomDomain && !doesUrlIncludeSlug && !isURLAbsolute) {
            const baseUrl = getBaseUrl(currentLocation, false);

            urlWithSlug = `${baseUrl}${url}`;
        }

        urlWithSlug = addFrontVersionToUrl(urlWithSlug);
    }

    return urlWithSlug;
};

/**
 * Returns the object used to represent a filter for a specific engine.
 * @param engine - type of engine
 */
const createSearchEngineFilter = (engine: ENGINES, label?: string): SearchFilter => {
    return {
        value: engine,
        label: label || ENGINES_TRANSLATIONS[engine],
        type: engine === ENGINES.LUMAPPS ? FILTER_TYPES.LUMAPPS : FILTER_TYPES.THIRD_PARTY,
    };
};

/**
 * Returns the object used to represent metadta for a specific engine.
 * @param engine - type of engine
 * @param overrides - whether we want to change the default logic for this metadata
 */
const createSearchEngineMetadata = (engine: ENGINES, overrides?: Partial<SearchTab>): SearchTab => {
    return {
        kind: engine,
        localizedName: ENGINES_TRANSLATIONS[engine],
        uid: engine,
        resultsCount: 0,
        totalResultsCount: 0,
        template: engine,
        isMetadataVisible: true,
        isSnippetVisible: true,
        ...overrides,
    };
};

/**
 * From a given URL and a query, we create a new URL by adding that query into the provided URL.
 * The URL provided needs to have the placeholder `{query}` so it can be replaced
 */
const createExternalSearchQuery = (
    url: string,
    replaceParams: Record<string, string> = {},
    queryParams: Record<string, string> = {},
) => {
    let queryUrl = url;
    Object.keys(replaceParams).forEach((param) => {
        queryUrl = queryUrl.replace(`{${param}}`, encodeURIComponentSafe(replaceParams[param]));
    });

    Object.keys(queryParams).forEach((param) => {
        const separator = queryUrl.indexOf('?') >= 0 ? '&' : '?';
        queryUrl = `${queryUrl}${separator}token=${encodeURIComponentSafe(queryParams[param])}`;
    });

    return queryUrl;
};

/**
 * Indicates if a given datasource is a LumApps one or not.
 *
 * @param  {Object}  datasource The datasource to check the kind of.
 * @return {boolean} Whether the datasource is a LumApps one or not.
 */
const isLumApps = (kind: string) => {
    return kind === 'lumapps';
};

/**
 * Indicates if a given datasource is a third-party one or not.
 * Used in GCS Settings in legacy apps/legacy/client/back-office/modules/gcs-search-settings/gcs-search-settings.html
 *
 * @param  {Object}  datasource The datasource to check the kind of.
 * @return {boolean} Whether the datasource is a third-party one or not.
 */
const isThirdParty = (kind: string) => {
    return kind === SEARCH_RESULT_TYPES.THIRD_PARTY;
};

export {
    isSearchQueryValid,
    convertTabToSearchFilter,
    addBaseSlugToSearchUrl,
    createSearchEngineFilter,
    createSearchEngineMetadata,
    createExternalSearchQuery,
    convertCustomSearchTabToSearchFilter,
    isLumApps,
    isThirdParty,
};
