import React from 'react';

import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { getInstanceContentListLinkOpenNewTab } from '@lumapps/instance/ducks/selectors';
import { Link } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';

import { BlockPagePreviewContentType, BlockPagePreviewLink as BlockPagePreviewLinkProps } from '../../type';

export const BlockPagePreviewLink: React.FC<BlockPagePreviewLinkProps> = ({
    children,
    className,
    linkData,
    pageType,
    externalUrl,
    ...linkProps
}) => {
    const crossInstanceInNewTab = useSelector(getInstanceContentListLinkOpenNewTab);

    if (externalUrl) {
        return (
            <Link className={className} href={externalUrl} target="_blank" {...linkProps}>
                {children}
            </Link>
        );
    }

    if (pageType === BlockPagePreviewContentType.community) {
        return (
            <Link
                linkAs={CommunityLink}
                className={className}
                to={linkData}
                crossInstanceInNewTab={crossInstanceInNewTab}
                {...linkProps}
            >
                {children}
            </Link>
        );
    }

    return (
        <Link
            linkAs={ContentLink}
            className={className}
            to={linkData}
            crossInstanceInNewTab={crossInstanceInNewTab}
            {...linkProps}
        >
            {children}
        </Link>
    );
};
