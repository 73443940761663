(function IIFE() {
    'use strict';

    /////////////////////////////

    function FsitemsService(Config, FsitemsFactory, Instance, LumsitesBaseService, Utils) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(FsitemsFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The service default parameters.
         *
         * @type {Object}
         */
        service.defaultParams = {};

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the default fields of the folder / media block. They will all be enabled by default.
         *
         * @return {Object} A map of field names and their visibility.
         */
        function getDefaultFieldsToDisplay() {
            var defaultFileFields = Instance.getProperty(Config.INSTANCE_PROPERTIES.FILE_FIELDS);
            defaultFileFields = Utils.mapEnabledEntries(defaultFileFields);

            if (angular.isUndefinedOrEmpty(defaultFileFields)) {
                return {};
            }

            var fieldsToDisplay = {};

            angular.forEach(defaultFileFields, function forEachDefaultFileField(fieldName) {
                fieldsToDisplay[fieldName] = true;
            });

            return fieldsToDisplay;
        }

        /**
         * Move items to trash.
         *
         * @param  {Array}    mediaList  The folderList items to trash.
         * @param  {Array}    folderList The mediaList items to trash.
         * @param  {Function} cb         The succes callback function.
         * @param  {Function} errorCb    The error callback function.
         * @param  {string}   listKey    The identifier of the list that is linked to the display of the loader.
         * @return {Object}   The return result, cb, erroCb and listKey.
         */
        function putItemsToTrash(mediaList, folderList, cb, errorCb, listKey) {
            var tempIdsToTrash = [];

            angular.forEach(folderList, function forFolderToTrash(folder) {
                tempIdsToTrash.push({
                    kind: 'Folder',
                    uid: folder.id,
                });
            });

            angular.forEach(mediaList, function forMediaToTrash(media) {
                tempIdsToTrash.push({
                    kind: 'Media',
                    uid: media.id,
                });
            });

            return FsitemsFactory.trash({
                instance: Instance.getCurrentInstanceId(),
                itemIds: tempIdsToTrash,
            }, cb, errorCb, listKey);
        }

        /////////////////////////////

        service.getDefaultFieldsToDisplay = getDefaultFieldsToDisplay;
        service.putItemsToTrash = putItemsToTrash;

        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                instance: Instance.getCurrentInstanceId(),
            };
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Fsitems', FsitemsService);
})();
