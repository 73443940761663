import { Editor, ReactEditor } from '@lumapps/wrex/slate';

import { isTableCell } from './isTableCell';

export const getCurrentCellsInRow = (editor: ReactEditor, rowPath: number[]) => {
    /* get the number of cells in the row  */
    const currentNumberOfCellsInRow = Array.from(Editor.nodes(editor, { match: isTableCell, at: rowPath }));
    return {
        cellsInRow: currentNumberOfCellsInRow,
        cellsLength: currentNumberOfCellsInRow.length,
    };
};
