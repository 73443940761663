import React, { useEffect, useState } from 'react';

import { padding, margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { GenericEntityBlock } from '@lumapps/lumx-blocks/components/GenericEntityBlock';
import { EmptyState } from '@lumapps/lumx-states/components/EmptyState';
import { ServiceNotAvailableState } from '@lumapps/lumx-states/components/ServiceNotAvailableState';
import { learningHatImage } from '@lumapps/lumx/custom-icons';
import { mdiDownload, mdiSchool } from '@lumapps/lumx/icons';
import { FlexBox, Size, Orientation, Button, Emphasis, Alignment } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { User } from '@lumapps/user/types';
import { formatDate } from '@lumapps/utils/date/formatDate';
import { BaseLoadingStatus, isError, isLoading, isEmpty } from '@lumapps/utils/types/BaseLoadingStatus';

import { downloadCertificate, getCertificates } from '../../api';
import { SCOPE } from '../../constants';
import { CERTIFICATE } from '../../keys';
import { CertificatesDataType } from '../../types';
import { CertificateSkeleton } from '../CertificateSkeleton';

/**
 * Certificate component wher user can download certificates earn by finishing some learning trainings
 * @param CertificatesProps
 * @returns Certificates
 */

export const Certificate: React.FC = () => {
    const [status, setStatus] = useState<BaseLoadingStatus>(BaseLoadingStatus.initial);
    const [certificates, setCertificates] = useState<CertificatesDataType>();
    const currentUser = useSelector(connectedUserSelector) as User;
    const { get } = useDataAttributes(SCOPE);
    const { translateKey, translateAndReplace } = useTranslate();
    const { success, error } = useNotification();

    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                setStatus(BaseLoadingStatus.loading);
                const response = await getCertificates(currentUser.id);
                if (response.items?.length > 0) {
                    setCertificates(response);
                    setStatus(BaseLoadingStatus.idle);
                } else {
                    setStatus(BaseLoadingStatus.empty);
                }
            } catch (e) {
                setStatus(BaseLoadingStatus.error);
            }
        };
        if (status === BaseLoadingStatus.initial) {
            fetchCertificates();
        }
    }, [currentUser.id, status]);

    const handleDownloadCertificates = async (
        certificateIdentifier: string,
        certificationDate: string,
        certificationTitle: string,
    ) => {
        try {
            await downloadCertificate(
                certificateIdentifier,
                formatDate(certificationDate),
                certificationTitle,
                currentUser.firstName,
            );
            success({ translate: CERTIFICATE.DOWNLOAD_SUCCESSFUL });
        } catch {
            error({ translate: GLOBAL.ERROR_OCCURRED_TRY_AGAIN });
        }
    };

    if (isLoading(status)) {
        return <CertificateSkeleton />;
    }
    if (isError(status)) {
        return <ServiceNotAvailableState shouldShowIcon onRetry={() => getCertificates(currentUser.id)} />;
    }
    if (isEmpty(status)) {
        return (
            <EmptyState
                title={translateKey(CERTIFICATE.EMPTY_TITLE)}
                message={translateKey(CERTIFICATE.EMPTY_DESCRIPTION)}
                iconProps={{ icon: learningHatImage, size: Size.l, className: margin('top', Size.huge) }}
            />
        );
    }

    return (
        <FlexBox orientation={Orientation.vertical} gap="big" className={padding('all', 'big')}>
            {certificates &&
                certificates.items.map((certificate) => (
                    <FlexBox
                        key={certificate.identifier}
                        orientation={Orientation.horizontal}
                        hAlign="center"
                        vAlign={Alignment.spaceBetween}
                    >
                        <GenericEntityBlock
                            key={certificate.identifier}
                            title={certificate.learningObject.title}
                            thumbnail={{
                                image: certificate.learningObject.cover,
                                icon: mdiSchool,
                                size: Size.l,
                            }}
                            metadata={[
                                <GenericEntityBlock.Attribute
                                    value={translateAndReplace(GLOBAL.FORMAT_COLON, {
                                        KEY: translateKey(CERTIFICATE.ISSUED),
                                        VALUE: formatDate(certificate.certificationDate),
                                    })}
                                    key="metadata"
                                />,
                            ]}
                        />
                        <Button
                            emphasis={Emphasis.low}
                            leftIcon={mdiDownload}
                            onClick={() =>
                                handleDownloadCertificates(
                                    certificate.identifier,
                                    certificate.certificationDate,
                                    certificate.learningObject.title,
                                )
                            }
                            {...get({ element: 'list-element', action: 'download-certificate' })}
                        />
                    </FlexBox>
                ))}
        </FlexBox>
    );
};
