import filter from 'lodash/filter';
import find from 'lodash/find';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { isUserAllowed, MATCHES_METHODS } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

import { DOMAIN } from '../constants';
import { MODULES_PERMISSIONS } from '../permissions';
import { Directory } from '../types';
import { DirectoriesState } from './types';

const directoriesSelector = (state: BaseStore): DirectoriesState => state[DOMAIN] || {};

const isModuleAllowed = isUserAllowed(
    [MODULES_PERMISSIONS.DIRECTORY_EDIT, MODULES_PERMISSIONS.TUTORIAL_EDIT, MODULES_PERMISSIONS.USER_DIRECTORY_EDIT],
    { matches: MATCHES_METHODS.SOME },
);

const isDirectoriesAllowed = isUserAllowed(MODULES_PERMISSIONS.DIRECTORY_EDIT);

const getDirectories = createSelector(directoriesSelector, (state: DirectoriesState) => state.directories || []);

const getInstanceDirectories = createSelector(
    getDirectories,
    instanceIdSelector,
    (directories: Directory[], instanceId) => {
        return filter(directories, (directory: Directory) => directory.instance === instanceId);
    },
);

const getDirectoryById = (directoryId: string) =>
    createSelector(getDirectories, (directories: Directory[]) => {
        return find(directories, { id: directoryId });
    });

export {
    directoriesSelector,
    getDirectories,
    isModuleAllowed,
    isDirectoriesAllowed,
    getInstanceDirectories,
    getDirectoryById,
};
