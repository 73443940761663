import React from 'react';

import { mdiArrowRight } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { SHORTCUTS } from '../../keys';
import { KeyboardKey } from '../KeyboardKey';

/** Display the "Right" arrow key with an accessible label. */
export const KeyRightArrow = () => {
    const { translateKey } = useTranslate();
    return <KeyboardKey label={translateKey(SHORTCUTS.RIGHT_ARROW_LABEL)} icon={mdiArrowRight} />;
};
