import { getEmbedCode, getVideoUrlWebsiteName, getVideoId } from 'components/utils/video_utils';

(function IIFE() {
    // ///////////////////////////

    function AttachmentsLinksController($rootScope, LxNotificationService, Translation, Utils) {
        'ngInject';

        const vm = this;

        // ///////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        // ///////////////////////////

        // ///////////////////////////

        /**
         * Services and utilities.
         */
        vm.Utils = Utils;

        // ///////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        // ///////////////////////////

        /**
         * Get video ratio.
         *
         * @param  {Object} link The video link.
         * @return {Object} The video container padding bottom.
         *
         * Todo [Arnaud]: move to video utils.
         */
        function getVideoRatio(link) {
            const html = getEmbedCode(link.videoId, getVideoUrlWebsiteName(link.url));

            if (angular.isUndefinedOrEmpty(html)) {
                return undefined;
            }

            const regEx = /(width|height)=["']([^"']*)["']/gi;
            const sizes = {};
            let ratio = 56.25;

            html.replace(regEx, function replaceSizes(all, type, value) {
                sizes[type] = value;
            });

            if (angular.isDefined(sizes.width) && angular.isDefined(sizes.height)) {
                ratio = (sizes.height / sizes.width) * 100;
            }

            return {
                'padding-bottom': `${ratio}%`,
            };
        }

        /**
         * Display the next preview image of a link.
         *
         * @param {number} linkIndex The index of the preview images link we want to increase.
         */
        function nextImage(linkIndex) {
            vm.links[linkIndex].thumbnailIndex++;

            if (vm.links[linkIndex].thumbnailIndex === vm.links[linkIndex].images.length) {
                vm.links[linkIndex].thumbnailIndex = 0;
            }
        }

        // ///////////////////////////

        vm.getEmbedCode = getEmbedCode;
        vm.getVideoRatio = getVideoRatio;
        vm.getVideoUrlWebsiteName = getVideoUrlWebsiteName;
        vm.nextImage = nextImage;
        // In order to handle video links saved without videoIds, we inject the videoId if the link is a video and there is no videoId.
        vm.links = vm.links.map((link) => {
            return {
                ...link,
                videoId: link.videoId ? link.videoId : getVideoId(link.url, getVideoUrlWebsiteName(link.url)) || null,
            };
        });
    }

    /**
     * Displays link attachments for posts or comments and allow their removal depending on the permissions of the user.
     *
     * @param {Array} links A list of attachment links objects to be displayed.
     */

    function attachmentsLinksDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: AttachmentsLinksController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                editing: '=',
                links: '=',
            },
            templateUrl: '/client/front-office/modules/social/attachments/views/attachments-links.html',
        };
    }

    // ///////////////////////////

    angular.module('Directives').directive('attachmentsLinks', attachmentsLinksDirective);
})();
