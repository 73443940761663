import { getFocusableTreeWalker, last } from './getFocusableTreeWalker';

/**
 * Focus previous element in `root` (optionally before a specific element)
 */
export function focusPrevious(root: Element, before?: Element | null) {
    const node = before || document.activeElement;
    const walker = getFocusableTreeWalker(root);
    if (root.contains(node)) {
        // Set current node
        walker.currentNode = node as Node;
    } else {
        // Current node not in root => Focus the last focusable element
        const next = last(walker);
        if (next) {
            next.focus();
        }
        return next;
    }
    // Focus the previous node
    const previousNode = walker.previousNode() as HTMLElement;
    if (previousNode) {
        previousNode.focus();
    }
    return previousNode;
}
