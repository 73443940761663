import { fetchUsersTask } from './users_tasks';

const ADD_USERS = 'users/ADD_USERS';
const FETCH_USERS_ERROR = 'users/FETCH_USERS_ERROR';
const FETCH_USERS_LOADING = 'users/FETCH_USERS_LOADING';
const FETCH_USERS_SUCCESS = 'users/FETCH_USERS_SUCCESS';

/**
 * Fetch user list.
 *
 * @param  {Array}    [cursor]     Optional cursor to get next results page.
 * @param  {Array}    [query]      Optional query to filter user list.
 * @param  {boolean}  [showHidden] Also request hidden users.
 * @param  {Array}    [users]      Optional list of users ids.
 * @return {Function} The actions creator's thunk.
 */
const fetchUsers = ({ cursor, query, showHidden, users } = {}) => (dispatch, getState) => {
    const { users: fetchedUsers, instance } = getState();
    const { id: instanceId } = instance;

    dispatch({
        type: FETCH_USERS_LOADING,
    });

    return fetchUsersTask({ cursor, instanceId, query, showHidden, users })
        .then(({ cursor: newCursor, hasMore, list }) => {
            dispatch({
                payload: { hasMore, list },
                type: FETCH_USERS_SUCCESS,
            });

            return { cursor: newCursor, users: list };
        })
        .catch((exception) => {
            dispatch({
                // eslint-disable-next-line id-blacklist
                payload: { error: exception },
                type: FETCH_USERS_ERROR,
            });
        });
};

export { ADD_USERS, FETCH_USERS_ERROR, FETCH_USERS_LOADING, FETCH_USERS_SUCCESS, fetchUsers };
