import isEmpty from 'lodash/isEmpty';

import { get } from '@lumapps/constants';

import { useStyles } from './useStyles';

const constants = get();

export interface UseNavStylesProps {
    /** background color for the top bar */
    backgroundColor: string | null;
    /** determines whether the style is the default one or not */
    isDefault?: boolean;
}

/**
 * Retrieves the customizations implemented to the current instance that are related to the navigation bar
 */
const useNavStyles = (): UseNavStylesProps => {
    const { styles } = useStyles({ property: 'mainNav' }) || {};

    return {
        ...styles,
        backgroundColor: styles.backgroundColor || constants.instanceNavBackgroundColor,
        isDefault: isEmpty(styles),
    };
};

export { useNavStyles };
