import { SearchSuggestionType, SearchSuggestion } from '@lumapps/search/types';
import { escapeRegExp } from '@lumapps/utils/string/escapeRegExp';

import { QuickSearchEntity } from '../types';

export const entitiesToSuggestions = (items?: QuickSearchEntity[]): SearchSuggestion[] => {
    if (!items) {
        return [];
    }

    return items.map((item) => {
        const siteIdFrom = item.originSiteId || item.siteId;

        return {
            label: item.title,
            siteId: item.siteId,
            item: {
                ...item,
                originSiteId: siteIdFrom,
            },
            query: escapeRegExp(item.title),
            type: SearchSuggestionType.INTERACTION,
            counterClick: item.counterClick,
        };
    });
};
