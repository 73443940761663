/* eslint-disable no-param-reassign */
import produce from 'immer';

import assignWith from 'lodash/assignWith';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

import { SET_FORM_FIELD_ERROR, TOGGLE_EXPORT_MODAL, UPDATE_FORM } from './actions';

const defaultState = {
    form: {
        fields: {
            endDate: {
                hasError: false,
                isOpen: false,
                monthOffset: 0,
                value: undefined,
                wasTouched: false,
            },
            startDate: {
                hasError: false,
                isOpen: false,
                monthOffset: 0,
                value: undefined,
                wasTouched: false,
            },
        },
        hasError: false,
        wasTouched: false,
    },
    isExportModalOpen: false,
};

export const reducer = produce((draft, action) => {
    switch (action.type) {
        case TOGGLE_EXPORT_MODAL:
            draft.isExportModalOpen = action.isOpen;

            return draft;

        case UPDATE_FORM: {
            const { form } = action.payload;

            assignWith(draft.form, form, mergeObjectOnly);

            return draft;
        }

        case SET_FORM_FIELD_ERROR: {
            const { fieldId, errorMessage } = action.payload;
            const { form } = draft;

            set(form, ['fields', fieldId, 'errorMessage'], errorMessage);

            const formHasError = Object.values(form.fields).find((field) => !isEmpty(field.errorMessage));
            set(form, ['hasError'], Boolean(formHasError));

            return draft;
        }

        default:
            return draft;
    }
}, defaultState);
