import BaseApi from '@lumapps/base-api';

export enum PLACEMENT {
    RIGHT = 'right',
    LEFT = 'left',
    ABOVE = 'above',
    UNDER = 'under',
    REPLACE = 'replace',
}

export enum Targets {
    SEARCH = 'search',
    SEARCH_BOX = 'search-box',
    SEARCH_TAB = 'search-tab',
    CONTEXTUAL_ACTIONS = 'contextual-actions',
    SETTINGS = 'settings',
    CONTRIBUTION_MENU = 'contribution-menu',
    CONTRIBUTION_BUTTON = 'contribution-button',
    NAVIGATION = 'navigation',
    NAVIGATION_UI = 'navigation-ui',
    SUB_NAVIGATION = 'sub-navigation',
    SUB_NAVIGATION_UI = 'sub-navigation-ui',
    APP = 'app',
    HEADER = 'header',
    CONTENT = 'content',
    COMMUNITY = 'community',
    PROFILE = 'user-profile',
    PAGE = 'page',
    WIDGET = 'widget',
    FAVORITES = 'favorites',
    BOOKMARKS = 'bookmarks',
    NOTIFICATIONS_BUTTON = 'notifications-button',
    SETTINGS_BUTTON = 'settings-button',
    LOGO = 'logo',
    STICKY_HEADER = 'sticky-header',
    SEARCH_CUSTOM_METADATA = 'search-custom-metadata',
    USER_DIRECTORY = 'user_directory',
    NEWS = 'news',
    CUSTOM_LIST = 'custom_list',
    DIRECTORY = 'directory',
    USER_PROFILE_ORG_CHART = 'user-profile-org-chart',
    USER_FEED_SIDEBAR = 'user-feed-sidebar',
    USER_DROPDOWN_LINKS = 'user-dropdown-links',
    WIDGET_IFRAME = 'widget-iframe',
    GDPR_BANNER = 'gdpr-banner',
    ERROR_PAGE = 'error-page',
    NOT_FOUND_PAGE = 'not-found-page',
    SEARCH_FILTERS = 'search-filters',
}

export enum Events {
    NAVIGATION = 'navigation',
    WIDGET_RENDERED = 'widget-rendered',
    SEARCH_FILTER = 'search-filter',
    SEARCH_BOX = 'search-box',
    SEARCH_RESULT = 'search-result',
    SEARCH_SORT = 'search-sort',
}

export interface CustomizationComponentProps {
    /** id of the component that will be customized */
    target: Targets;
    /** where the customized component will be placed */
    placement: PLACEMENT;
    /** context that can be useful for the component that will be rendered */
    context?: any;
    /** id of the event that will be customized */
    eventType?: Events;
}

export interface CustomComponent {
    /** where the component will be placed */
    placement: PLACEMENT;
    /** component where the customization will be rendered */
    target: Targets;
    /** list of components where the customization will be rendered */
    targets?: Targets[];
    /**
     * components to be rendered. The type is actually complex,
     * so we will go with any and check the type on the fly
     */
    toRender: any;
    /**
     * sometimes rendering components need a context, this function allows devs to create custom components
     * that are not hardcoded, using something from their current context.
     */
    toRenderWithContext?: (options: any) => any;
}

export type ComponentConstructor = (component: string) => (props: any) => { component: string; props: any };

export interface Subscriber {
    callback: (props: any) => void;
    event?: Events;
    configuration?: {
        shouldRenderOnNavigation?: boolean;
    };
}

export interface CustomizationApiProps {
    /** expose API to allow XHR requets */
    api: BaseApi;
    /** expose API V2 to allow XHR requests */
    apiV2: BaseApi;
    /** method that will be executed when we want to render a specific component */
    render: (component: CustomComponent) => void;
    /** method that will be executed when there is a navigation in place */
    onNavigation: (callback: Subscriber['callback']) => void;
    /** method that will be executed when a widget has been rendered */
    onWidgetRendered: (callback: Subscriber['callback']) => void;
    /** generic method that will be executed if an event match with the list of authorized events */
    on: (eventType: Events, callback: Subscriber['callback']) => void;
    /** Available UI components that can be used */
    components: Record<string, ComponentConstructor>;
    /** constants that will be available for creating and configuring the UI */
    constants: Record<string, any>;
    /** available placements */
    placement: typeof PLACEMENT;
    /** available components to customize */
    targets: typeof Targets;
    /** available events to listen to */
    events: typeof Events;
}

export interface Customizations {
    /** key/value object that determines whether a mode is enabled or not */
    modes: Record<string, boolean>;
    /** utilit function to determine whether a mode is enabled or not */
    isModeEnabled?: (mode: string) => boolean;
    /** feature flags that could affect the modes visibility */
    featureFlags: Record<string, boolean>;
}

export interface BaseSubscription {
    callback: any;
}

export interface EventSubscription extends BaseSubscription {
    event: Events;
}
export interface CustomizationSubscription extends BaseSubscription {
    target: Targets;
    placement: PLACEMENT;
}

export interface Session {
    language: string;
    instance: any;
    user: any;
    isConnected: boolean;
}

/**
 * Here is the internal store for managing customizations.
 * Why are we managing customizations like this and not using Redux or a React state or a Context?
 * - These components can be added from an external source, meaning that these are components added from the head or instance scripts,
 * which basically comes down to being code not developed by LumApps.
 * - External developers can do just about anything to our application from that code, for example, rendering a component every 1ms. This
 * can have a direct impact on the application itself, so we needed to have a way to make our application independent from these customizations
 * meaning that changing these customizations does not affect the entire application, thus avoiding rerenderings of our app
 * - In order to avoid that, state is managed in memory, independently from redux or react, which allows us to avoid triggering a rerender of
 * our application and only make the custom component rerender.
 */
export interface Store {
    /** Variable that holds the different components that were added to the frontend application */
    customComponents: Record<string, Record<PLACEMENT, CustomComponent>>;
    functionalOverrides: Record<string, Record<string, any>>;

    /**
     * since this components may change from an external source, we need to keep a list of observers that will
     * essentially determine whether the customization has changed and let the component know of that change, triggering a rerender
     */
    observers: Record<string, Record<PLACEMENT, any>>;
    /**
     * on subscribers what will be notified when an event occured
     */
    onSubscribers: EventSubscription[];
    /** whether the store was initialized */
    initialized: boolean;

    /** list of components that are not supposed to be displayed on the UI */
    disabledComponents: Record<string, boolean>;

    disabledObservers: any;

    /** overrides that change the text of a specific target */
    textsOverrides: Record<string, Record<string, string>>;
}

/**
 * We are declaring these window variables here rather than in lumapps constants since these
 * are values that we want to have associated to the customizations packages rather than the constant ones.
 *
 * We also do not want to have these types exposed on the lumapps/constants package, they should not be reused
 * anywhere on the appliaction.
 */
declare global {
    interface Window {
        /**
         * Entrypoint for our customizations API
         */
        lumapps: {
            /**
             * from a given slug, it returns the entire slug of the page, taking into consideration
             * the customer and instance slug.
             *
             * For example => window.lumapps.getInternalUrl('/admin/instance-settings') => '/a/lumapps/home/admin/instance-settings'
             */
            getInternalUrl: (url: string) => string;
            /**
             * function to be exposed in order to allow external code to interact with internal code and add customizations
             */
            customize?: (callback: Subscriber['callback']) => void;
            /**
             * List of subscribers that will be added to the page directly from the HTML.
             */
            subscribers: Subscriber[];
            /** Customizations store */
            store: any;
            /** retrieves the currently displayed content */
            getCurrentContent: () => any;
            /** function to disable components in the UI */
            disable: (id: string) => void;
            /** key/value object for disabled components and its observers */
            disabledComponents: Store['disabledComponents'];
            disabledObservers: Store['disabledObservers'];
            /** function to override texts on specific components */
            setText: (id: string, translations: Record<string, string>) => void;
            override: (target: Targets, func: string, override: any) => void;
            /** key/value object for text overrides, where the key is a component and the value is an key/value object of lang ids and translations */
            textsOverrides: Store['textsOverrides'];
            functionalOverrides: Store['functionalOverrides'];
            /** refresh the user token */
            refreshToken: () => Promise<any>;
            displayNotification: (type: string, text: string) => void;
            blobStore?: Record<string, string>;
        };
    }
}
