import isNil from 'lodash/isNil';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { injectReducer } from '@lumapps/redux/manager';
import { connect } from '@lumapps/redux/react';
import { Dispatch, FrontOfficeStore } from '@lumapps/redux/types';
import { debounceThunk } from '@lumapps/redux/utils/debounceThunk';

import { actions, reducer as articlePreviewReducer } from '../../ducks/articlePreviewSlice';
import { articleEntitySelector, articleContainerIdSelector } from '../../ducks/selectors';
import { fetchArticleListByIds } from '../../ducks/thunks/fetchArticleListByIds';
import { ArticlePreviewWrapper } from './ArticlePreviewWrapper';

// Dynamically inject the articlePreview reducer.
injectReducer('articlePreview', articlePreviewReducer);

const debouncedfetchArticleListByIds = debounceThunk(fetchArticleListByIds, DEBOUNCE_DELAY);
const debouncedFetchArticles = (containerId: string) => debouncedfetchArticleListByIds([containerId]);

const mapStateToProps = (state: FrontOfficeStore, props: any) => {
    const entity = articleEntitySelector(props.id)(state);

    const isLegacy = !isNil(props.article.isFromLegacy);
    const containerId = props.article?.shares?.[0].container.containerId || articleContainerIdSelector(state);
    return {
        article: isLegacy ? entity : props.article,
        isLoading: isLegacy && entity === undefined,
        containerId,
        isLegacy,
    };
};

const mapDispatchToProps = {
    pushBatchIdArticle: (articleId: string) => (dispatch: Dispatch) => {
        dispatch(actions.pushBatchIdArticle(articleId));
    },
    fetchArticles: debouncedFetchArticles,
};

const ConnectedArticlePreview = connect(mapStateToProps, mapDispatchToProps)(ArticlePreviewWrapper);

export { ConnectedArticlePreview as ArticlePreview, mapStateToProps, mapDispatchToProps };
