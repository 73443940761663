import { createSelector } from '@lumapps/redux/reselect';

const getTopics = (state) => state.socialAdvocacy.topics;

/**
 * Get the list of topics.
 *
 * @param  {Object} state The redux state.
 * @return {Array}  List of topics.
 */
const getTopicsList = createSelector(getTopics, (topics) => topics.list);

/**
 * Get the list of topics.
 *
 * @param  {Object} state The redux state.
 * @return {Array}  List of topics.
 */
const fetchingTopicsSelector = createSelector(getTopics, (topics) => topics.isFetching);

export { getTopics, getTopicsList, fetchingTopicsSelector };
