import { mdiFolderPlus, mdiUpload } from '@lumapps/lumx/icons';

import { Icon, Size } from '@lumapps/lumx/react';

import { cloneDeep, chain, map } from 'lodash';

import { arrayOf, bool, func, object, string } from 'prop-types';

import React, { Fragment, PureComponent } from 'react';

import { DocumentUploader } from 'components/components/document/uploader';

import * as driveApi from 'components/components/drive/api';

import { Button, DropDown } from 'components/components/ui';

import * as documentService from 'components/services/document';

import { getInputLanguage, translate as t } from 'components/translations';

import { CreateFileFolderDialog } from './CreateFileFolderDialog';

/**
 * List of drive insert file action types.
 */
const DRIVE_INSERT_ACTIONS = ['DOCUMENT', 'PRESENTATION', 'SPREADSHEET'];

/**
 * Associative map of icon per action type.
 */
const ICONS = {
    DOCUMENT: <i className="icon advanced-drive-table__file-thumb--document" />,
    FOLDER: <Icon icon={mdiFolderPlus} size={Size.xs} />,
    PRESENTATION: <i className="icon advanced-drive-table__file-thumb--presentation" />,
    SPREADSHEET: <i className="icon advanced-drive-table__file-thumb--spreadsheet" />,
    UPLOAD: <Icon icon={mdiUpload} size={Size.xs} />,
};

/**
 * Associative map of label per action type.
 */
const LABELS = {
    DOCUMENT: 'WIDGET_TYPE_FILE_MANAGEMENT_GOOGLE_DOCUMENT',
    FOLDER: 'FRONT.MEDIA_PICKER.HEADER_CREATE_FOLDER',
    PRESENTATION: 'WIDGET_TYPE_FILE_MANAGEMENT_GOOGLE_PRESENTATION',
    SPREADSHEET: 'WIDGET_TYPE_FILE_MANAGEMENT_GOOGLE_SPREADSHEET',
    UPLOAD: 'FRONT.MEDIA_PICKER.UPLOAD_MEDIA',
};

/**
 * Regular expression used to extract the document id from a drive document path.
 * @type {RegExp}
 */
const DRIVE_DOC_ID_RGX = /provider=drive.*resource=([^/]*)/;

/**
 * Button that display a dialog to create a folder.
 */
export class FileListWidgetActionsMenu extends PureComponent {
    static propTypes = {
        /** Is button disabled. */
        isDisabled: bool,
        // Todo: find a way to get rid of this (w/ backend dev).
        /** Parent "shared" search parameter. */
        isShared: bool,
        /** Function called after content changed. */
        onChange: func.isRequired,
        /** Function called when loading state change. */
        onStatusChange: func.isRequired,
        /** Parent folder path for creation. */
        parentPath: string.isRequired,
        /** List of possible actions. */
        providerActions: arrayOf(object).isRequired,
    };

    static defaultProps = {
        isDisabled: false,
        isShared: false,
    };

    constructor(props) {
        super(props);

        this._INITIAL_STATE = {
            newFile: null,
            newFolder: null,
        };
        this.state = cloneDeep(this._INITIAL_STATE);

        this.onClickHandlerFactory = this.onClickHandlerFactory.bind(this);
    }

    /**
     * Creates handler for a given action.
     *
     * @param  {string}   type     Action type.
     * @param  {string}   mimeType Mime-type for file insert action.
     * @return {Function} Click handler for action.
     */
    onClickHandlerFactory({ type, mimeType }) {
        const { parentPath } = this.props;

        // Create folder action handler.
        if (type === 'FOLDER') {
            const { isShared } = this.props;

            return () => {
                // Open create folder dialog.
                this.setState({ newFolder: { isShared, parentPath } });
            };
        }

        // Upload file action handler.
        if (type === 'UPLOAD') {
            return () => {
                // Trigger file selector system dialog.
                this.uploadComponent.selectFiles();
            };
        }

        // Parse parent id from parent path with regexp.
        const [unused, parentId] = parentPath.match(DRIVE_DOC_ID_RGX) || [];

        // Drive insert file action.
        if (DRIVE_INSERT_ACTIONS.includes(type) && parentId) {
            // Create handler for the new file action.
            return () => {
                // Open insert file dialog.
                this.setState({ newFile: { mimeType, parentId } });
            };
        }

        return undefined;
    }

    render() {
        const { newFolder, newFile } = this.state;
        const { parentPath, onChange, onStatusChange, isDisabled, providerActions } = this.props;

        // Get icon, label and onClick; Keep only the actions handled by this widget.
        const handledActions = chain(providerActions)
            .map((action) => {
                const icon = ICONS[action.type];
                const label = t(LABELS[action.type]);
                const onClick = this.onClickHandlerFactory(action);

                if (icon && label && onClick) {
                    return { ...action, icon, label, onClick };
                }

                return undefined;
            })
            .filter(Boolean)
            .value();

        const disableActions = isDisabled || handledActions.length < 1;

        return (
            <Fragment>
                <DropDown
                    align="right"
                    toggle={
                        <Button disabled={disableActions} size={Size.s}>
                            {t('NEW')}
                        </Button>
                    }
                >
                    <ul>
                        {map(handledActions, ({ type, label, icon, onClick }) => (
                            <li key={type} className="dropdown-link" onClick={onClick}>
                                {icon}
                                <span>{label}</span>
                            </li>
                        ))}
                    </ul>
                </DropDown>

                <DocumentUploader
                    multiple
                    parentPath={parentPath}
                    onChange={onChange}
                    onRef={(ref) => (this.uploadComponent = ref)}
                    onStatusChange={onStatusChange}
                />

                {newFolder && (
                    <CreateFileFolderDialog
                        action={(folderName) =>
                            documentService.createFolder({
                                ...newFolder,
                                names: { [getInputLanguage()]: folderName },
                            })
                        }
                        title="FRONT.MEDIA_PICKER.HEADER_CREATE_FOLDER"
                        onValidate={(success) => {
                            this.setState({ newFolder: null });
                            // Reload content on success.
                            if (success) {
                                onChange();
                            }
                        }}
                    />
                )}

                {newFile && (
                    <CreateFileFolderDialog
                        action={(fileName) =>
                            driveApi.insertFile({
                                ...newFile,
                                title: fileName,
                            })
                        }
                        title="FRONT.MEDIA_PICKER.DETAIL.FILENAME"
                        onValidate={(success) => {
                            this.setState({ newFile: null });
                            // Reload content on success.
                            if (success) {
                                onChange();
                            }
                        }}
                    />
                )}
            </Fragment>
        );
    }
}
