import React, { RefObject } from 'react';

import { classnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Button, Dialog, Emphasis, Heading, Size, Toolbar } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { UserList, UserListProps } from '../UserList';

export interface UserListDialogProps {
    className?: string;
    isOpen: boolean;
    title?: string;
    users?: UserListProps['users'];
    onClose?: () => void;
    onLoadMore?: () => void;
    isLoading?: boolean;
    parentElement?: RefObject<HTMLElement>;
}

const CLASSNAME = 'user-list-dialog';

export const UserListDialog: React.FC<UserListDialogProps> = ({
    className,
    isOpen,
    isLoading,
    users = [],
    title,
    parentElement,
    onClose,
    onLoadMore,
}) => {
    const { translateKey } = useTranslate();

    return (
        <Dialog
            className={classnames(CLASSNAME, className)}
            size={Size.regular}
            onClose={onClose}
            header={<Toolbar label={<Heading typography="title">{title}</Heading>} />}
            isOpen={isOpen}
            forceHeaderDivider
            forceFooterDivider
            parentElement={parentElement}
        >
            <UserList users={users} onLoadMore={onLoadMore} isLoading={isLoading} withDividers />
            <footer>
                <Toolbar
                    after={
                        <Button emphasis={Emphasis.medium} icon={mdiClose} onClick={onClose}>
                            {translateKey(GLOBAL.CLOSE)}
                        </Button>
                    }
                />
            </footer>
        </Dialog>
    );
};
