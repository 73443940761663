import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Heading, RadioButton, RadioGroup } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { DATA_SCOPE, SORTING_VALUES } from '../../../constants';
import { WidgetEventListPropertiesType } from '../../../type';

export interface SortingSectionProps {
    currentSorting?: SORTING_VALUES;
    onChange: (properties: Pick<WidgetEventListPropertiesType, 'listOrderDir'>) => void;
}

const CLASSNAME = 'sorting-section';

export const SortingSection: React.FC<SortingSectionProps> = ({ currentSorting, onChange }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(`${DATA_SCOPE}-sorting`);
    const { block } = useClassnames(CLASSNAME);
    const [value, setValue] = React.useState(currentSorting || SORTING_VALUES.OLDEST_TO_LATEST);

    const onValueChange = (newValue: SORTING_VALUES) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        onChange({ listOrderDir: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <section className={block([margin('vertical', 'huge')])}>
            <Heading as="h4" typography="subtitle1" className={margin('bottom', 'huge')}>
                {translateKey(GLOBAL.SORTING)}
            </Heading>
            <RadioGroup>
                <RadioButton
                    isChecked={value === SORTING_VALUES.OLDEST_TO_LATEST}
                    label={translateKey(GLOBAL.OLDEST_TO_LATEST)}
                    value={SORTING_VALUES.OLDEST_TO_LATEST}
                    onChange={onValueChange}
                    name="sorting-mode"
                    {...get({ element: 'radio', action: 'oldest-to-latest' })}
                />
                <RadioButton
                    isChecked={value === SORTING_VALUES.LATEST_TO_OLDEST}
                    label={translateKey(GLOBAL.LATEST_TO_OLDEST)}
                    value={SORTING_VALUES.LATEST_TO_OLDEST}
                    onChange={onValueChange}
                    name="sorting-mode"
                    {...get({ element: 'radio', action: 'latest-to-oldest' })}
                />
            </RadioGroup>
        </section>
    );
};
