import React from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { SORT_FIELDS } from '@lumapps/articles/constants';
import { ARTICLES_SORTING } from '@lumapps/articles/types';
import { useClassnames } from '@lumapps/classnames';
import { ButtonSelect } from '@lumapps/combobox/components/ButtonSelect';
import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { SortersType } from '@lumapps/lumx-filter-and-sort/types';
import { mdiMagnify, mdiSort } from '@lumapps/lumx/icons';
import { Emphasis, FlexBox, TextField } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { FilterComponentProps } from '@lumapps/widget-base/types';
import { getFilterByName } from '@lumapps/widget-base/utils/getFilterByName';

import { WIDGET_ARTICLE_LIST } from '../../keys';

import './index.scss';

const CLASSNAME = 'article-list-filters';

export interface ArticleListFiltersType {
    /** Value of the SearchField */
    searchTerm?: string;
    /** Current selected sort item */
    sort?: SortersType;
}

/**
 * Component to display filters of the widget-article-list
 * @param FilterComponentProps
 * @returns ArticleListFilters
 */
export const ArticleListFilters: React.FC<FilterComponentProps<ArticleListFiltersType>> = ({
    onFilterChange,
    onApply,
    activeFilters,
    availableFilters,
    loadingState,
    theme,
}) => {
    const { translateKey } = useTranslate();
    const isFilterDisabled = loadingState === 'error' || loadingState === 'loading';
    const { get: getDataAttributes } = useDataAttributes(CLASSNAME);
    const { block, element } = useClassnames(CLASSNAME);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedApply = React.useCallback(
        debounce(() => {
            onApply();
        }, DEBOUNCE_DELAY),
        [onApply],
    );

    const debouncedOnChange = React.useCallback(
        (filterValue: any, filterName: keyof ArticleListFiltersType) => {
            if (isEmpty(filterValue)) {
                onFilterChange({ [filterName]: undefined });
            } else {
                onFilterChange({ [filterName]: filterValue });
            }
            debouncedApply();
        },
        [onFilterChange, debouncedApply],
    );

    const sortingValue = React.useMemo(() => {
        const activeSortValue = activeFilters?.sort;
        const sortOption = SORT_FIELDS.find((el) => el.id === activeFilters?.sort);

        if (activeSortValue && sortOption) {
            return sortOption;
        }

        const initialSortValue = getFilterByName('SORT', availableFilters)?.defaultValue;
        const initialSortOption = SORT_FIELDS.find((el) => el.id === initialSortValue);

        if (initialSortValue && initialSortOption) {
            return initialSortOption;
        }

        return SORT_FIELDS.find((el) => el.id === ARTICLES_SORTING.CREATED_DESC);
    }, [activeFilters, availableFilters]);

    return (
        <FlexBox fillSpace orientation="horizontal" hAlign="center" gap="big" className={block()}>
            {getFilterByName('SEARCH_TERM', availableFilters) && (
                <TextField
                    className={element('filter')}
                    onChange={(value) => debouncedOnChange(value, 'searchTerm')}
                    name="searchTerm"
                    value={activeFilters?.searchTerm || ''}
                    clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                    placeholder={translateKey(WIDGET_ARTICLE_LIST.SEARCH_BY_TEXT)}
                    icon={mdiMagnify}
                    theme={theme}
                    isDisabled={isFilterDisabled}
                    {...getDataAttributes({ element: 'search-field', action: 'search' })}
                />
            )}
            {getFilterByName('SORT', availableFilters) ? (
                <FlexBox marginAuto="left">
                    <ButtonSelect
                        emphasis={Emphasis.low}
                        leftIcon={mdiSort}
                        label={translateKey(GLOBAL.SORT_BY)}
                        options={SORT_FIELDS}
                        valueRenderer={(option) => (option ? translateKey(option.label) : '')}
                        className={element('filter')}
                        value={sortingValue}
                        onChange={(option) => debouncedOnChange(option?.id, 'sort')}
                        theme={theme}
                        isDisabled={isFilterDisabled}
                        {...getDataAttributes({ element: 'sorters', action: 'sort' })}
                    />
                </FlexBox>
            ) : undefined}
        </FlexBox>
    );
};
