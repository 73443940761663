/* eslint-disable import/unambiguous */
function WidgetWallController() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };
}

/////////////////////////////

function WidgetWallDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetWallController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetWall', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-wall/views/widget-wall.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetWall', WidgetWallDirective);

export { WidgetWallDirective, WidgetWallController };
