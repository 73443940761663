import React from 'react';

import { useDispatch } from '@lumapps/redux/react';

import { NOTIFICATION_TIMEOUT } from '../../../constants';
import { actions } from '../../../ducks/slice';
import { NotificationInStore, NotificationsAPI } from '../../../ducks/types';

/**
 * Handle hide notification after timeout, handle pause and unpause.
 */
export function useHandleHideTimeout({
    notification,
    wasVisible,
    pausedAt,
}: {
    notification: NotificationInStore;
    pausedAt?: Date;
    wasVisible: React.RefObject<boolean>;
}) {
    const dispatch = useDispatch();
    const { id, visible, type } = notification;

    const timeout = React.useMemo(() => {
        if (type === 'extended') {
            /** Extended notifications don't have timeout prop because they are not dismissed automatically */
            return Infinity;
        }
        return (notification as NotificationsAPI).timeout || NOTIFICATION_TIMEOUT;
    }, [type, notification]);

    const remainingTimeRef = React.useRef(timeout);
    const timerStartAt = React.useRef(new Date());
    const wasPaused = React.useRef<boolean>();

    // Handle timeout time when appearing or un-pausing
    React.useEffect(() => {
        if (timeout === Infinity) {
            return undefined;
        }

        const isAppearing = !wasVisible.current && visible;
        const isUnPausing = wasPaused.current && !pausedAt;
        if (isAppearing || isUnPausing) {
            // Start timeout timer
            timerStartAt.current = new Date();
            const timerId = setTimeout(() => {
                // Hide notification
                dispatch(actions.hide({ id }));
            }, remainingTimeRef.current);
            return () => clearTimeout(timerId);
        }
        return undefined;
    }, [id, visible, pausedAt, wasVisible, timeout, dispatch]);

    // Handle pause and remaining time
    React.useEffect(() => {
        if (timeout === Infinity) {
            return undefined;
        }
        if (pausedAt) {
            // Calc the remaining time
            remainingTimeRef.current -= pausedAt.getTime() - timerStartAt.current.getTime();
        }
        wasPaused.current = !!pausedAt;
        return undefined;
    }, [timeout, pausedAt]);
}
