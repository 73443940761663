import React from 'react';

import { THUMBNAIL_REGULAR } from '@lumapps/play/constants';
import { notFoundRoute, useRouter } from '@lumapps/router';
import { withApiv1Languages } from '@lumapps/translations';
import { FetchCallbackParams, useFetchWithStatus } from '@lumapps/utils/hooks/useFetchWithStatus';

import { cancelGetPlaylist, getPlaylist } from '../../api';
import type { GetPlaylistParams } from '../../api/types';
import type { Playlist } from '../../types';

export interface UseFetchPlaylistsParams {
    /**
     * Fetch key used to cancel api calls
     */
    fetchKey?: string;
}

export const useFetchPlaylist = ({ fetchKey }: UseFetchPlaylistsParams) => {
    const [playlist, setPlaylist] = React.useState<Playlist>();
    const { cancelFetch, fetch, response, status } = useFetchWithStatus({
        onFetch: getPlaylist,
        onFetchCancel: cancelGetPlaylist,
    });

    const { redirect } = useRouter();

    const handleFetchCallback = React.useCallback(
        (shouldRedirect = false) =>
            ({ success, response: callbackResponse }: FetchCallbackParams) => {
                if (success) {
                    setPlaylist(withApiv1Languages(callbackResponse));
                    return;
                }
                if (shouldRedirect) {
                    redirect(notFoundRoute);
                }
            },
        [redirect],
    );

    const fetchPlaylist = React.useCallback(
        ({ fields, id, shouldRedirect }: GetPlaylistParams<keyof Playlist>) => {
            fetch({
                callback: handleFetchCallback(shouldRedirect),
                params: [
                    {
                        id,
                        ...(fields && { fields }),
                        ...THUMBNAIL_REGULAR,
                    },
                    fetchKey,
                ],
            });
        },
        [fetch, fetchKey, handleFetchCallback],
    );

    React.useEffect(() => {
        return () => {
            cancelFetch({ params: [fetchKey] });
        };
    }, [cancelFetch, fetchKey]);

    return {
        fetchPlaylist,
        response,
        status,
        playlist,
    };
};
