import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const logoutRoute: Route = {
    path: '/logout',
    legacyId: 'logout',
    appId: AppId.legacy,
};

const loginRoute: Route = {
    path: '/login',
    legacyId: 'login',
    appId: AppId.legacy,
};

const logout = (): Route => logoutRoute;

const login = (): Route => loginRoute;

export { logout, login, loginRoute };
