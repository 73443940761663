(function IIFE() {
    'use strict';

    /////////////////////////////

    function AddLinkController($timeout, Config, FormValidation, LsTextEditor, LsTextEditorMarkdown, LxDialogService,
        LxNotificationService, Translation, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The object that will hold references to the forms used to add/edit links.
         *
         * @type {Object}
         */
        vm.form = {};

        /**
         * The link entered in the add link dialog.
         *
         * @type {string}
         */
        vm.linkInput = '';

        /**
         * The current textarea used in edition.
         *
         * @type {Object}
         */
        vm.currentTextarea = undefined;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Config = Config;
        vm.FormValidation = FormValidation;
        vm.LsTextEditor = LsTextEditor;
        vm.LsTextEditorMarkdown = LsTextEditorMarkdown;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Open the add link dialog.
         */
        function _openAddLinkDialog() {
            Utils.waitForAndExecute('#' + vm.dialogKey);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add link as attachment from the dialog. In case of markdown action, it will format the link.
         */
        function addLink() {
            var form = vm.form.addLinkDialog;

            vm.selectedText = undefined;

            if (form.$valid) {
                if (vm.linkType === 'MARKDOWN') {
                    LsTextEditorMarkdown.formatLink(vm.linkInput, vm.textEditorControl);
                }

                if (angular.isUndefinedOrEmpty(vm.content.links) || vm.linkType === 'ATTACHMENT') {
                    if (!angular.isFunction(vm.urlCallback)) {
                        LxDialogService.close(vm.dialogKey);

                        return;
                    }

                    var linkCallback = {
                        link: vm.linkInput,
                    };

                    vm.urlCallback(linkCallback);
                }

                LxDialogService.close(vm.dialogKey);
            } else {
                if (angular.isDefinedAndFilled(form)) {
                    FormValidation.setFormDirty(form);
                }

                LxNotificationService.error(Translation.translate('ERROR_MANDATORY_FIELDS'));
            }
        }

        /**
         * Register a form so we can reference it easily.
         *
         * @param {Object} scope    The form scope.
         * @param {string} formName The form name.
         */
        function registerForm(scope, formName) {
            $timeout(function registerFormInScope() {
                vm.form[formName] = (angular.isDefinedAndFilled(scope[formName])) ? scope[formName] : undefined;
            });
        }

        /////////////////////////////

        /**
         * Open the add link dialog to input a url to be crawled or reset the link markdown if the selected text is
         * already wrapped.
         */
        function editLink() {
            vm.linkInput = (vm.linkType === 'ATTACHMENT') ? _.get(vm.content, 'links[0].url') : undefined;

            if (vm.linkType === 'ATTACHMENT' || !LsTextEditorMarkdown.isSelectionLinkWrapped(vm.textEditorControl)) {
                if (angular.isDefinedAndFilled(vm.currentTextarea)) {
                    vm.selectedText = vm.currentTextarea.value.substring(vm.currentTextarea.selectionStart,
                        vm.currentTextarea.selectionEnd);
                }

                _openAddLinkDialog();

                return;
            }

            LsTextEditorMarkdown.formatLink(undefined, vm.textEditorControl);
        }

        /////////////////////////////

        vm.addLink = addLink;
        vm.registerForm = registerForm;
        vm.editLink = editLink;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.urlCallback = vm.urlCallback || angular.noop;

            if (angular.isUndefinedOrEmpty(vm.linkType)) {
                vm.linkType = 'ATTACHMENT';
            }

            if (angular.isDefinedAndFilled(vm.textEditorControl)) {
                vm.currentTextarea = _.first(vm.textEditorControl.textAreaElement);
            }

            vm.dialogKey += '-' + vm.linkType;
        }

        init();
    }

    /////////////////////////////

    /**
     *  Display the add link button which open the add link dialog.
     *
     * @param {Object}   content                 The object in which the link will be attached.
     * @param {string}   dialogKey               The key to identify the add link dialog.
     * @param {string}   [linkType="ATTACHMENT"] The type of the link, use to display the right button to edit the
     *                                           textarea markup or add a link as attachment.
     * @param {Object}   textEditorControl       The controls to edit the rich text markdown.
     * @param {string}   [type]                  The attachment type.
     * @param {Function} [urlCallback]           The callback function called when a link is set in the markdwon.
     */

    function AddLinkDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: AddLinkController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                content: '=lsContent',
                dialogKey: '<lsDialogKey',
                linkType: '@?lsLinkType',
                textEditorControl: '=lsTextEditorControl',
                type: '<?lsType',
                urlCallback: '&?lsUrlCallback',
            },
            templateUrl: '/client/front-office/modules/communities/common/views/partials/add-link.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsAddLink', AddLinkDirective);
})();
