import { connect } from '@lumapps/redux/react';

import { setPreviewTab } from 'components/components/social-advocacy/ducks/shareableWorkflow/actions';

import {
    makeShareableworkflowFormFieldelector,
    getContentValidityBySocialNetwork,
    getShareableWorkflowContentId,
    getShareableWorkflowContentPreview,
    getShareableWorkflowCurrentPreviewTab,
    getShareableworkflowForm,
    getAvailableSocialNetworks,
} from 'components/components/social-advocacy/ducks/shareableWorkflow/selectors';

import { SAShareableWorkflowPreview } from './SAShareableWorkflowPreview';

const mapStateToProps = (state) => {
    const selectField = makeShareableworkflowFormFieldelector(state);

    const contentType = selectField('type');

    return {
        contentId: getShareableWorkflowContentId(state),
        contentPreview: getShareableWorkflowContentPreview(state),
        contentType: contentType.value,
        contentValidityBySocialNetwork: getContentValidityBySocialNetwork(state),
        currentPreviewTab: getShareableWorkflowCurrentPreviewTab(state),
        form: getShareableworkflowForm(state),
        socialNetworks: getAvailableSocialNetworks(state),
    };
};

const mapDispatchToProps = {
    setPreviewTab,
};

const ConnectedSAShareableWorkflowPreview = connect(mapStateToProps, mapDispatchToProps)(SAShareableWorkflowPreview);

export { ConnectedSAShareableWorkflowPreview as SAShareableWorkflowPreview };
