import { IMAGE_GALLERY_VIEW_MODE } from '@lumapps/wrex-image-gallery/constants';

import { ImageGroupElement } from '../types';

/**
 * Whether the current element is an image gallery or an enhanced image
 */
export function isImageGallery(isImageGalleryEnabled: boolean, element?: Partial<ImageGroupElement>): boolean {
    return (
        isImageGalleryEnabled &&
        Boolean(element?.viewMode) &&
        (element?.viewMode === IMAGE_GALLERY_VIEW_MODE.mosaic ||
            element?.viewMode === IMAGE_GALLERY_VIEW_MODE.slideshow)
    );
}
