import React from 'react';

import { classnames } from '@lumapps/classnames';
import type { ElementRender } from '@lumapps/wrex/types';

import { T_D } from '../../../constants';
import type { TDElement } from '../../../types';

import './index.scss';

export const TD: ElementRender<TDElement, HTMLTableCellElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;

    return (
        <td
            {...forwardedProps}
            className={classnames(className, 'wrex-content-td', {
                'wrex-content-td--is-in-selection': element?.isInCellSelection,
            })}
            ref={elementRef}
        >
            {children}
        </td>
    );
};
TD.displayName = T_D;
