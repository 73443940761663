import { useEffect } from 'react';

export function useWindowScroll(callback: VoidFunction, isEnabled = true) {
    useEffect(() => {
        if (isEnabled) {
            window.addEventListener('scroll', callback);
        }

        return () => {
            if (isEnabled) {
                window.removeEventListener('scroll', callback);
            }
        };
    });
}
