// eslint-disable-next-line id-blacklist
import { error } from 'components/services/notification';
import { getFroala } from 'components/services/froalaLoader';
import { translate as t } from 'components/translations';

const DOMAIN = 'FROALA';
const LOAD_FROALA_SUCCESS = `${DOMAIN}/LOAD_FROALA_SUCCESS`;
const LOAD_FROALA_ERROR = `${DOMAIN}/LOAD_FROALA_ERROR`;
const SET_IS_LOADING = `${DOMAIN}/SET_IS_LOADING`;

/**
 * Ask the API to get the list of the communities with their KPIs.
 *
 * @return {Function} The associated thunk.
 */
const fetchFroala = () => async (dispatch) => {
    // Populate reducer here.
    try {
        const isLoaded = await getFroala();

        dispatch({
            payload: { isLoaded, isLoading: false },
            type: LOAD_FROALA_SUCCESS,
        });
    } catch (exception) {
        dispatch({
            payload: { isLoaded: true, isLoading: false },
            type: LOAD_FROALA_ERROR,
        });
        error(t('FROALA.LOADING_ERROR'));
    }
};

/////////////////////////////

export { LOAD_FROALA_SUCCESS, LOAD_FROALA_ERROR, SET_IS_LOADING, fetchFroala };
