import assignWith from 'lodash/assignWith';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Merge two objects together.
 *
 * @param  {Object} obj1 The first object to merge.
 * @param  {Object} obj2 The second object to merge.
 * @return {Object} The merged object.
 */
export const mergeObjectOnly = (obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> => {
    if (isPlainObject(obj1) && isPlainObject(obj2)) {
        return assignWith(obj1, obj2, mergeObjectOnly);
    }

    return obj2;
};
