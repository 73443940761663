import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const communityAnalyticsRoute: Route = {
    path: '/admin/community-analytics/:id',
    appId: AppId.backOffice,
};

const communityAnalytics = (communityId: string): Route => ({
    ...communityAnalyticsRoute,
    params: {
        id: communityId,
    },
});

export { communityAnalytics, communityAnalyticsRoute };
