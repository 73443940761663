export enum WIDGET_STREAM {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412597161
     */
    USER_ATTENDS_AN_EVENT = 'WIDGET_STREAM.USER_ATTENDS_AN_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412597150
     */
    USER_COMMENTED_AN_ARTICLE = 'WIDGET_STREAM.USER_COMMENTED_AN_ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412597148
     */
    USER_COMMENTED_AN_EVENT = 'WIDGET_STREAM.USER_COMMENTED_AN_EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412597139
     */
    USER_COMMENTED_A_POST = 'WIDGET_STREAM.USER_COMMENTED_A_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=412597123
     */
    USER_COMMENTED_IN_CONTENT = 'WIDGET_STREAM.USER_COMMENTED_IN_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=417796730
     */
    USER_STARTED_FOLLOWING_A_COMMUNITY = 'WIDGET_STREAM.USER_STARTED_FOLLOWING_A_COMMUNITY',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=491385423
     */
    USER_ENROLLED_IN_A_TRAINING = 'WIDGET_STREAM.USER_ENROLLED_IN_A_TRAINING',
}
