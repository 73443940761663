import React from 'react';

import { DragDropContext, Draggable, Droppable, useMouseSensor, useTouchSensor } from 'react-beautiful-dnd';

import { useClassnames } from '@lumapps/classnames';
import { Divider } from '@lumapps/lumx/react';
import { gridNavigationEventHandler } from '@lumapps/utils/event/gridNavigationEventHandler';
import { useRenderInPortal } from '@lumapps/utils/hooks/useRenderInPortal';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

import { UseDraggable } from '../../../hooks/useDraggable';
import { DraggableListDisplayGrid } from '../../../types';
import { getKeyForItem, revertUniqueId } from '../../../utils';

import './index.scss';

export type DraggableListProps<T> = {
    /** node to be rendered after all items have been rendered */
    afterItems?: React.ReactNode;
    /** Custom class name */
    className?: string;
    /** index of the item being dragged is any */
    currentDraggedItemIndex?: string;
    /** The display properties of the list */
    display: DraggableListDisplayGrid;
    /** the useDraggable return */
    draggable: UseDraggable<T>;
    /** the position where to dropzone will be displayed */
    dropZoneIndexes?: { listIndex: number; index: number; showAfter: boolean } | undefined;
    /** whether the entire list is disabled or not */
    isDisabled?: boolean;
    /** whether the drag is made with keyboard */
    isDraggingWithKeyboard?: boolean;
    /** Callback to render an item */
    itemRenderer: (
        item: T,
        dragHandleProps: any,
        index: number,
        ref?: React.RefObject<HTMLElement> | ((value: HTMLElement | null) => void),
        onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void,
        isDragging?: boolean,
    ) => React.ReactNode;
    /** Callback when an item is focused */
    onFocus?: (index: number) => void;
    /** Key to identify an item ex: id */
    keyPropName?: string;
    /** ref to the parent element */
    parentRef: React.RefObject<HTMLDivElement>;
    /** ref to the first element of the list */
    firstElementRef?: React.RefObject<HTMLElement>;
};

/**
 * Generic simple Draggable list
 * Only support one drop zone
 * @family Lists
 * @param param0 DraggableListProps
 * @returns component
 */
export const DraggableGridView = <T,>({
    afterItems,
    className,
    currentDraggedItemIndex,
    display,
    draggable,
    dropZoneIndexes,
    isDisabled,
    isDraggingWithKeyboard,
    itemRenderer,
    onFocus,
    keyPropName,
    parentRef,
    firstElementRef,
}: DraggableListProps<T>) => {
    const { block, element } = useClassnames(draggable.className);
    const renderDraggable = useRenderInPortal();

    const focusItem = (index: number) => {
        draggable.itemsRef.get(index)?.current?.focus();
        onFocus?.(index);
    };

    /**
     * Function used to customize the dragging style of an element
     * See https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/drop-animation.md for more info
     */
    const getDraggableStyle = (style: any, snapshot: any) => {
        return {
            ...style,
            transform: snapshot.isDragging ? style.transform : undefined,
        };
    };

    const getDraggableItem = (item: any, itemKey: string, index: number, listIndex: number) => {
        const isDragDisabled = draggable.itemsToUse.length === 1 || isDisabled || item.isDragDisabled;
        const currentIndex = listIndex + index * draggable.horizontalSize;

        // Create a ref for this item
        const itemRef = React.createRef<HTMLElement>();
        draggable.itemsRef.set(currentIndex, itemRef);

        return (
            <Draggable key={itemKey} draggableId={itemKey} isDragDisabled={isDragDisabled} index={listIndex}>
                {(provided: any, snapshot: any) => {
                    const isDragging = snapshot.isDragging && !snapshot.isDropAnimating;

                    const itemElement = (
                        <div
                            className={element('item')}
                            ref={provided.innerRef}
                            onDragEnd={draggable.onDragEnd}
                            {...provided.draggableProps}
                            style={getDraggableStyle(provided.draggableProps.style, snapshot)}
                        >
                            {itemRenderer(
                                revertUniqueId(item, keyPropName),
                                provided.dragHandleProps,
                                currentIndex,
                                currentIndex === 0 ? mergeRefs([itemRef, firstElementRef]) : itemRef,
                                (event: React.KeyboardEvent<HTMLDivElement>) => {
                                    gridNavigationEventHandler({
                                        callback: (index) => focusItem(index),
                                        currentIndex,
                                        event,
                                        horizontalSize: draggable.horizontalSize,
                                        itemsCount: draggable.initialItems.length,
                                        listOfItems: draggable.listOfItems,
                                    });
                                },
                                isDragging,
                            )}
                        </div>
                    );

                    if (provided.draggableProps.style.position === 'fixed') {
                        return renderDraggable(() => itemElement)();
                    }
                    return itemElement;
                }}
            </Draggable>
        );
    };

    return (
        <DragDropContext
            onDragUpdate={draggable.onDragUpdate}
            onDragEnd={draggable.onDragEnd}
            onDragStart={draggable.onDragStart}
            enableDefaultSensors={false}
            sensors={[useMouseSensor, useTouchSensor]}
        >
            <div className={block()} ref={parentRef}>
                {draggable.listOfItems.map((list, index) => {
                    /**
                     * If we are in horizontal mode, items that should be displayed after the original
                     * list of items are just displayed as the last item of the last list. To figure out if we need to display
                     * them, we check if the last list has a length that is equal or less than the horizontal size.
                     * If it is less, then we add the after items as another item in the list.
                     *
                     * If it is not the case, meaning that the last list has reached the limit of total items
                     * to be displayed, then we need to create a new list and display the after items there.
                     */
                    const shouldDisplayAfterItemsInHorizontalModeAsAnItem =
                        afterItems &&
                        draggable.listOfItems.length - 1 === index &&
                        draggable.listOfItems[index].length < draggable.horizontalSize;

                    const shouldDisplayAfterItemsInHorizontalModeAsAList =
                        afterItems &&
                        draggable.listOfItems.length - 1 === index &&
                        draggable.listOfItems[index].length === draggable.horizontalSize;

                    return (
                        <React.Fragment key={draggable.listIds[index]}>
                            <Droppable droppableId={draggable.listIds[index]} direction="horizontal">
                                {(dropProvided: any) => (
                                    <div
                                        ref={dropProvided.innerRef}
                                        className={element(
                                            'row',
                                            {
                                                'has-dragging':
                                                    currentDraggedItemIndex !== undefined &&
                                                    parseInt(currentDraggedItemIndex, 10) % draggable.horizontalSize ===
                                                        index,
                                            },
                                            [className],
                                        )}
                                    >
                                        {list.map((item: any, listIndex) => {
                                            const itemKey = getKeyForItem(item, keyPropName);
                                            const shouldDisplayDropIndication =
                                                !isDraggingWithKeyboard &&
                                                !!dropZoneIndexes &&
                                                dropZoneIndexes.listIndex === listIndex &&
                                                dropZoneIndexes.index === index;

                                            const shouldDisplayBeforeDropIndication =
                                                !!dropZoneIndexes &&
                                                shouldDisplayDropIndication &&
                                                !dropZoneIndexes.showAfter;

                                            const shouldDisplayAfterDropIndication =
                                                !!dropZoneIndexes &&
                                                shouldDisplayDropIndication &&
                                                dropZoneIndexes.showAfter;

                                            return (
                                                <div
                                                    className={element('item-wrapper', {
                                                        'is-dragged': currentDraggedItemIndex === itemKey,
                                                    })}
                                                    key={itemKey}
                                                >
                                                    <Divider
                                                        className={element('item-divider')}
                                                        style={{
                                                            background: shouldDisplayBeforeDropIndication
                                                                ? 'var(--primary-n, #2196F3)'
                                                                : 'transparent',
                                                        }}
                                                        aria-hidden="true"
                                                    />
                                                    <div
                                                        style={{
                                                            width: display.itemsWidth,
                                                            height: display.itemsHeight,
                                                        }}
                                                    >
                                                        {dropProvided.placeholder}
                                                        {getDraggableItem(item, itemKey, index, listIndex)}
                                                    </div>
                                                    <Divider
                                                        className={element('item-divider')}
                                                        style={{
                                                            background: shouldDisplayAfterDropIndication
                                                                ? 'var(--primary-n, #2196F3)'
                                                                : 'transparent',
                                                        }}
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            );
                                        })}

                                        {shouldDisplayAfterItemsInHorizontalModeAsAnItem ? (
                                            <div className={element('item')}>{afterItems}</div>
                                        ) : null}
                                    </div>
                                )}
                            </Droppable>

                            {shouldDisplayAfterItemsInHorizontalModeAsAList ? (
                                <div className={block({ horizontal: true }, [className])}>
                                    <div className={element('item')}>{afterItems}</div>
                                </div>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </div>
        </DragDropContext>
    );
};
