import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiHelpCircleOutline } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PENDO_SCOPES } from '../../constants';

export interface PendoTriggerProps {
    isVisible: boolean;
    scope: PENDO_SCOPES;
    theme?: string;
}

const CLASSNAME = 'pendo-trigger';
const PendoTrigger: React.FC<PendoTriggerProps> = ({ isVisible = false, scope, theme }) => {
    const { get } = useDataAttributes('lumapps');
    const { translateKey } = useTranslate();

    /**
     * Unfortunately, on the back office on the legacy side, we are displaying the header on the DOM, which makes
     * this component appear twice on the page, once on the side nav of the back office and another time on the
     * header. In order to avoid this, we check that if we are on the back office, the only component that we render
     * is the trigger located on the side nav.
     */
    const { context = { isBackOffice: false } } = useRouter();
    const { isBackOffice } = context;
    const isLegacyHeaderScopeWhileInBackOffice = scope !== PENDO_SCOPES.SIDE_NAV && isBackOffice;

    if (!isVisible || isLegacyHeaderScopeWhileInBackOffice) {
        return null;
    }

    return (
        <IconButton
            className={CLASSNAME}
            emphasis={Emphasis.low}
            icon={mdiHelpCircleOutline}
            color={theme as any}
            {...get({ element: 'resource', action: 'center' })}
            label={translateKey(GLOBAL.RESOURCE_CENTER)}
        />
    );
};

export { PendoTrigger };
