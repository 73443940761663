/* istanbul ignore file */
import React from 'react';

import debounce from 'lodash/debounce';
import includes from 'lodash/includes';

import { isCancel, BaseApiPromise } from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse } from '@lumapps/base-api/types';
import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { GenericEntityPickerProps } from '../components/GenericEntityPicker';
import { actions, reducer, initialState } from '../ducks/slice';

export interface UseGenericPickerProps<I> {
    /**
     * The fetch function to use with the picker.
     * The hook only works with ServerListRequest type params and
     * BaseApiPromise<ServerListResponse<I>> type responses for now.
     *
     * You can type your custom request and response as follow:
     *
     * export interface RequestWithQuery extends ServerListRequest {
     *     query?: string;
     * }
     *
     * export type TypedResponse = ServerListResponse<MyType>;
     * */
    onFetch?(params?: ServerListRequest, fetchKey?: string): BaseApiPromise<ServerListResponse<I>>;
    /** The cancel function */
    onFetchCancel?(fetchKey?: string): void;
    /** Callback when a choice is selected */
    onChange?(item?: I): void;
    /** Callback to manage what value to use for the select display value */
    displayedValueSelector?(item: I): string;
    /** The params to use when fetching. Cursor and search query will be added within the hook. */
    fetchParams?: any;
    /**
     * Whether the entities should be fetch on select open or not.
     * If at false, will be fetched on mount.
     * */
    fetchOnOpen?: boolean;
    /**
     * The fetch key to use for cancelling.
     * If undefined, generateUUID() will be used
     * */
    fetchKey?: string;
    /**
     * The value initially selected
     */
    initialValue?: I;
    /**
     * The field name of the query, defaults to 'query'
     * This will be the property used in order to search through the entities
     */
    queryFieldName?: string;
}

export type UseGenericEntityPicker<I> = GenericEntityPickerProps<I> & {
    /** Used to reset the picker reduce store */
    reset: () => void;
};

/**
 * Hook to use with the GenericEntityPicker component.
 * This hook will manage all the following logic for you:
 *
 * * Fetching data (on mount or on open)
 * * Fetching more data on infinite scroll
 * * Refetching in case of error
 * * Debouncing and canceling request on search
 * * Managing the search field.
 *
 * TODO: For now, everything is managed here and is very specific to this component, but part of
 * TODO: the logic could be separated into another more reusable component.
 */
export const useGenericEntityPicker = <I,>({
    onFetch,
    onFetchCancel,
    onChange,
    displayedValueSelector,
    fetchKey,
    fetchParams,
    fetchOnOpen = true,
    initialValue,
    queryFieldName = 'query',
}: UseGenericPickerProps<I> = {}): UseGenericEntityPicker<I> => {
    const itemDisplayedValueFormatter = (value?: I): string => {
        if (!value) {
            return '';
        }

        if (displayedValueSelector) {
            return displayedValueSelector(value);
        }

        return typeof value === 'string' ? value : '';
    };

    const [{ fetchData, ...state }, dispatch] = React.useReducer(reducer, {
        ...initialState,
        displayedValue: itemDisplayedValueFormatter(initialValue),
    });

    const fetchKeyRef = React.useRef(fetchKey || generateUUID());

    /** Action when input is clicked */
    const onInputClick = () => dispatch(actions.onToggleOpen());

    /** Action when search is set */
    const onChangeSearchValue = (value: string) => dispatch(actions.setSearchValue(value));

    /** Action when a choice is selected */
    const handleChange = (item?: I) => {
        const displayedValue = itemDisplayedValueFormatter(item);
        dispatch(actions.setSelectedChoice(displayedValue));
        // Calls given function
        if (onChange) {
            onChange(item);
        }
    };

    /** Action when dropdown clear is requested */
    const onClear = () => handleChange(undefined);

    /** Action when dropdown close is requested   */
    const onDropdownClose = () => {
        dispatch(actions.onToggleOpen(false));
    };

    const reset = () => {
        dispatch(actions.reset());
    };

    /**
     * Fetch given query.
     * Uses params given as hook parameter and the query and cursor set by the hook.
     */
    const fetchQuery = React.useCallback(
        async (query, cursor?: string) => {
            if (!onFetch) {
                return;
            }
            try {
                const { data } = await onFetch(
                    { ...fetchParams, [queryFieldName]: query, cursor },
                    fetchKeyRef.current,
                );

                dispatch(
                    actions.onFetchSuccess({
                        items: data.items,
                        more: data.more,
                        cursor: data.cursor,
                    }),
                );
            } catch (exception) {
                /* istanbul ignore next */
                if (isCancel(exception)) {
                    return;
                }

                dispatch(actions.onFetchFailure());
            }
        },
        [fetchParams, onFetch, queryFieldName],
    );

    /**
     * Set as reloading when a retry is requested.
     */
    const onRetry = () => {
        dispatch(actions.setStatus(BaseLoadingStatus.loading));
    };

    /**
     * On infinite scroll, set as loadingMore if no loading is already in progress.
     */
    const hasMore = fetchData?.more && fetchData?.cursor && state.status === BaseLoadingStatus.idle;
    const onInfiniteScroll = hasMore
        ? () => {
              if (fetchData?.more && fetchData?.cursor && state.status === BaseLoadingStatus.idle) {
                  dispatch(actions.setStatus(BaseLoadingStatus.loadingMore));
              }
          }
        : undefined;

    // Debounced fetch query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetch = React.useCallback(debounce(fetchQuery, 500), []);

    /**
     * If no load has been done yet and the select is open,
     * set as loading.
     */
    React.useEffect(() => {
        if (state.status === BaseLoadingStatus.initial && onFetch && fetchOnOpen && state.isOpen) {
            dispatch(actions.setStatus(BaseLoadingStatus.loading));
        }
    }, [fetchOnOpen, fetchParams, onFetch, state.isOpen, state.status]);

    /**
     * When status is set as loading, trigger a debounced fetch
     * and using current search value and cursor
     */
    React.useEffect(() => {
        const keyRef = fetchKeyRef.current;

        if (state.status === BaseLoadingStatus.loading && onFetch) {
            if (onFetchCancel) {
                onFetchCancel(keyRef);
            }
            debouncedFetch(state.searchValue);
        }

        // Cancel request if a request is in progress
        return () => {
            if (includes([BaseLoadingStatus.loading, BaseLoadingStatus.loadingMore], state.status) && onFetchCancel) {
                if (onFetchCancel) {
                    onFetchCancel(keyRef);
                }
            }
        };
    }, [debouncedFetch, onFetchCancel, onFetch, state.searchValue, state.status]);

    /**
     * When status is set as loadingMore (infinite scroll) or reloading (after an error),
     * trigger a fetch without debounce and using current search value and cursor.
     */
    React.useEffect(() => {
        if (includes([BaseLoadingStatus.loadingMore], state.status) && onFetch) {
            if (onFetchCancel) {
                onFetchCancel(fetchKeyRef.current);
            }
            fetchQuery(state.searchValue, fetchData?.cursor);
        }
    }, [fetchData, fetchQuery, onFetch, onFetchCancel, state.searchValue, state.status]);

    return {
        ...state,
        onChange: handleChange,
        onChangeSearchValue,
        onClear,
        onDropdownClose,
        onInfiniteScroll,
        onInputClick,
        onRetry,
        reset,
    };
};
