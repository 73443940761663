import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useSelector } from '@lumapps/redux/react';

import { DOMAIN } from '../../constants';
import { State } from '../../ducks/types';
import { calculateOffset } from './calculateOffset';
import { ManagedNotification } from './ManagedNotification';

import './index.scss';

const CLASSNAME = 'notification-stack';

/**
 * Notification "toaster" container hooking in the store and renders every
 * active notifications in a stack.
 */
export const NotificationStack = () => {
    const { block } = useClassnames(CLASSNAME);
    const { notifications } = useSelector((state: any) => state[DOMAIN] as State);

    const [pausedAt, setPausedAt] = React.useState<Date>();
    const pause = React.useCallback(() => setPausedAt(new Date()), []);
    const unpause = React.useCallback(() => setPausedAt(undefined), []);

    return (
        <div className={block()}>
            {notifications.map((notification, index) => (
                <ManagedNotification
                    key={notification.id}
                    notification={notification}
                    // Adapt position in the stack
                    offset={calculateOffset(index, notifications)}
                    pause={pause}
                    unpause={unpause}
                    pausedAt={pausedAt}
                />
            ))}
        </div>
    );
};
