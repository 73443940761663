import debounce from 'lodash/debounce';
import first from 'lodash/first';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import set from 'lodash/set';

import { shouldDisplayField } from '@lumapps/user-directory/utils/shouldDisplayField';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    /////////////////////////////

    function UserService(
        $injector,
        $location,
        $q,
        $state,
        $window,
        Config,
        Customer,
        Features,
        Feed,
        InitialSettings,
        Instance,
        LocalStorage,
        LumsitesBaseService,
        NotificationSettings,
        ReduxStore,
        Translation,
        UserAuthFactory,
        UserConstant,
        UserFactory,
        Utils,
    ) {
        'ngInject';

        /**
         * Maximum number of results for the call to API.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        const MAX_RESULTS = 30;

        // eslint-disable-next-line consistent-this
        const service = LumsitesBaseService.createLumsitesBaseService(UserFactory, {
            autoInit: false,
            maxResults: MAX_RESULTS,
            objectIdentifier: 'id',
        });

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The debounce time needed to filterize a list of users.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        const _DEBOUNCE_DELAY = 500;

        /**
         * A list of names of notification types that are temporarily disabled.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        const _DISABLED_NOTIFICATION_TYPES = [
            'post_merged',
            'post_merged_master',
            'post_merged_slave',
            'post_merge_pending',
        ];

        /**
         * A stub empty user used for guests (not connected) users.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        const _GUEST_USER = {
            isGuest: true,
            uid: generateUUID(),
        };

        /**
         * Contains the list of accounts available for the user.
         *
         * @type {Array}
         */
        let _accounts = [];

        /**
         * The currently connected user.
         *
         * @type {Object}
         */
        let _connectedUser;

        /**
         * The current authentication token of the connected user.
         *
         * @type {string}
         */
        let _token;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Contains the promise of the loading of the connected user.
         *
         * @type {Promise}
         */
        // eslint-disable-next-line angular/deferred
        service.connectedUserDeferred = $q.defer();

        /**
         * Contains the error of the service.
         * Mostly used for user initialization.
         *
         * @type {Object}
         */
        // eslint-disable-next-line id-blacklist
        service.error = {};

        /**
         * The parameters for default search results in user picker.
         *
         * @type {Object}
         */
        service.defaultPickerParams = {
            showHidden: true,
        };

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Hide some types of notifications from the preferences table in case the customer doesn't have the correct
         * features activated or they have been disabled temporarily in the backend.
         *
         * @return {Array} The status for each notification type for the current user.
         */
        function _filterNotificationTypesStatuses() {
            const notificationPreferences = get(service.getConnected(), 'settings.notificationPreferences');

            if (angular.isUndefinedOrEmpty(notificationPreferences)) {
                return [];
            }

            let statuses = notificationPreferences.notificationTypeStatus || [];

            statuses = statuses.filter(function filterNotificationTypeStatus(nts) {
                const kind = nts.kind.toLowerCase();
                const settings = get(NotificationSettings, kind);

                return (
                    !includes(_DISABLED_NOTIFICATION_TYPES, kind) &&
                    angular.isDefinedAndFilled(settings) &&
                    (angular.isUndefinedOrEmpty(settings.requiredFeatures) || Features.hasAllRequiredFeatures(settings))
                );
            });

            return statuses;
        }

        /**
         * Handle user lang settings.
         */
        function _setLang() {
            const langParam = $location.search().lang;

            // Check if the user is forcing the language through the url.
            if (angular.isDefinedAndFilled(langParam)) {
                Translation.setLang('current', langParam);
            } else if (angular.isDefinedAndFilled(_connectedUser)) {
                if (angular.isDefinedAndFilled(_connectedUser.lang)) {
                    Translation.setLang('current', _connectedUser.lang);
                }

                if (angular.isDefinedAndFilled(_connectedUser.langs)) {
                    Translation.setLang('preferred', first(_connectedUser.langs));
                }
            }
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Return the list of accounts the user can login with.
         *
         * @return {Array} The account choices.
         */
        function getAccountChoices() {
            return _accounts || [];
        }

        /**
         * Return the account type.
         *
         * @param  {Object} user The user to check.
         * @return {string} The user account type.
         */
        function getAccountType(user) {
            user = user || service.getConnected() || {};

            return user.accountType;
        }

        /**
         * Get all user statuses available.
         *
         * @return {Array} An array of user statuses objects.
         */
        function getAllStatus() {
            return Config.USER_STATUS || [];
        }

        /**
         * Get the API profile for a given user object.
         * Note: Due to an inconsistent API, we sometimes get a full user on the frontend or just the ApiProfile.
         *
         * @param  {Object} user A user object or an apiProfile property of a user object.
         * @return {Object} The apiProfile object for the given user.
         */
        function getApiProfile(user) {
            if (angular.isUndefinedOrEmpty(user)) {
                user = _connectedUser;
            }

            return angular.isDefined(get(user, 'apiProfile')) ? user.apiProfile : user;
        }

        /**
         * Get the default author/writer projection.
         *
         * @return {Object} The default author/writer projection.
         */
        function getAuthorProjection() {
            const projection = angular.fastCopy(UserConstant.PROJECTION.AUTHOR_PROJECTION);

            let secondaryFieldName =
                Instance.getProperty(Config.INSTANCE_PROPERTIES.USER_BLOCK_DEFAULT_FIELD) || 'primaryEmail';

            // Remove all the informations after organizations field.
            secondaryFieldName = secondaryFieldName.replace(/([a-zA-Z0-9_-]+).*/g, '$1');

            projection[secondaryFieldName] = true;

            return projection;
        }

        /**
         * Get the avatar of a user.
         *
         * Note: this is the new and improved way of getting a user profile picture since the backend handles it all.
         *
         * @param  {string} userId The identifier of the user to get the avatar of.
         * @return {string} The url to the profile picture of a given user.
         */
        function getAvatarUrl(userId) {
            return `/serve/profilepicture/${Customer.getCustomerId()}/${userId}`;
        }

        /**
         * Get the connected user.
         *
         * @return {Object} The connected user.
         */
        function getConnected() {
            return _connectedUser || {};
        }

        /**
         * Get the user fullname regarding current language.
         *
         * @param  {Object} [user] The user to return fullname from.
         * @return {Object} The formatted user fullname.
         */
        function getUserFullName(user) {
            user = user || _connectedUser;

            if (angular.isUndefinedOrEmpty(user)) {
                return '';
            }

            if (
                angular.isUndefinedOrEmpty([user.firstName, user.lastName], 'some') &&
                angular.isUndefinedOrEmpty(get(user.apiProfile, 'name'))
            ) {
                return user.firstName || user.lastName;
            }
            const fullnameArray = [];

            // Set  firstName.
            fullnameArray.push(
                angular.isDefinedAndFilled([user.firstName, user.lastName], 'every')
                    ? user.firstName
                    : get(user.apiProfile, 'name.givenName'),
            );
            // Set lastName.
            fullnameArray.push(
                angular.isDefinedAndFilled([user.firstName, user.lastName], 'every')
                    ? user.lastName
                    : get(user.apiProfile, 'name.familyName'),
            );

            const hasToReverseFirstNameLastName = includes(
                InitialSettings.REVERTED_FULLNAME_LANGS,
                Translation.getLang('current'),
            );

            if (
                angular.isDefinedAndFilled(user.fullName) &&
                !hasToReverseFirstNameLastName &&
                user.fullName !== 'None None'
            ) {
                /*
                 * CPT 2020/03/04 - LUM-8383
                 * The fullName check to None None must not be done and the
                 * data must be realigned in the backend. The Haussmann
                 * project will handle this part
                 */
                return user.fullName;
            }

            if (hasToReverseFirstNameLastName) {
                fullnameArray.reverse();
            }

            return fullnameArray.join(' ');
        }

        /**
         * Return the mailto link according to user account type.
         *
         * @param  {Object} user The user to generate the link for.
         * @return {string} A mailto url.
         */
        function getMailToLink(user) {
            const email = service.getMail(user);
            if (angular.isUndefinedOrEmpty(user) && email) {
                return undefined;
            }

            let mailTo = `mailto:${email}`;

            if (service.isGoogle(service.getConnected())) {
                mailTo = `https://mail.google.com/mail?view=cm&tf=0&to=${email}`;
            } else if (service.isMicrosoft(service.getConnected())) {
                mailTo = `https://outlook.office365.com/owa/?path=/mail/action/compose&to=${email}`;
            }

            return mailTo;
        }

        function getMail(user) {
            if (angular.isUndefinedOrEmpty(user) || Features.hasFeature('hide_email_addresses')) {
                return undefined;
            }

            if (service.isMicrosoft(service.getConnected())) {
                return angular.isDefinedAndFilled(user.apiProfile) ? user.apiProfile.primaryEmail : user.email;
            }

            return user.email;
        }

        /**
         * Get the profile picture of a user.
         *
         * @param  {Object} [user=<_connectedUser>] The user to get the profile picture of.
         * @return {string} The url to the profile picture of a given user.
         */
        function getProfilePicture(user) {
            user = user || service.getConnected();

            return user.profilePictureUrl;
        }

        /**
         * Return the secondary field to display. It is displayed below user name in user block.
         *
         * @param  {Object} userApiProfile The API profile of the user we want to get the field from.
         * @param  {string} [contentType]  The name of the content type context to retrieve the secondary field name.
         * @return {Object} The secondary field from the user API profile.
         */
        function getSecondaryField(userApiProfile, contentType) {
            if (angular.isUndefinedOrEmpty(userApiProfile)) {
                return undefined;
            }

            const fieldName = service.getSecondaryFieldName(contentType);

            if (angular.isUndefinedOrEmpty(fieldName)) {
                return undefined;
            }

            if (!shouldDisplayField(fieldName, Features.hasFeature('hide_email_addresses'))) {
                return undefined;
            }

            return Utils.getApiProfileFieldFromMap(fieldName, {
                apiProfile: userApiProfile,
            });
        }

        /**
         * Return the name of the secondary field based on the content type passed in.
         *
         * @param  {string} contentType The name of the content type context where the user block is being displayed.
         * @return {string} The name of the secondary field.
         */
        function getSecondaryFieldName(contentType) {
            let fieldFromInstanceSettings;
            let fieldName;

            if (contentType === get(Config.AVAILABLE_CONTENT_TYPES, 'USER_DIRECTORY')) {
                fieldFromInstanceSettings = Instance.getProperty(
                    Config.INSTANCE_PROPERTIES.USER_BLOCK_USER_DIRECTORY_FIELD,
                );
                fieldName = angular.isUndefined(fieldFromInstanceSettings)
                    ? 'organizations[primary=true]/title'
                    : fieldFromInstanceSettings;
            } else {
                fieldFromInstanceSettings = Instance.getProperty(Config.INSTANCE_PROPERTIES.USER_BLOCK_DEFAULT_FIELD);
                fieldName = angular.isUndefined(fieldFromInstanceSettings) ? 'primaryEmail' : fieldFromInstanceSettings;
            }

            return fieldName;
        }

        /**
         * Get the authentication token.
         *
         * @return {string} The authentication token of the connected user.
         */
        function getToken() {
            return _token;
        }

        /**
         * Check if user has at least one subscription matching the given feeds.
         *
         * @param  {Array|Object} feeds A list of feeds or a feed object.
         * @return {boolean}      Whether the current user has subscriptions to one or several of the given feeds.
         */
        function hasSubscriptions(feeds) {
            if (angular.isUndefinedOrEmpty(feeds)) {
                return false;
            }

            feeds = angular.isArray(feeds) ? feeds : [feeds];
            let currentUserSubs;

            if (angular.isDefinedAndFilled(get(service.getConnected(), 'subscriptions'))) {
                currentUserSubs = map(service.getConnected().subscriptions, 'feed');
            } else {
                currentUserSubs = [Feed.PUBLIC.id];
            }

            return angular.isDefinedAndFilled(intersection(currentUserSubs, feeds));
        }

        /**
         * Initialize langs and notification settings for the connected user.
         */
        function initUserLangsAndNotifications() {
            const connectedUser = service.getConnected();

            _setLang();

            if (angular.isDefinedAndFilled(connectedUser) && !service.isGuest()) {
                set(
                    connectedUser,
                    'settings.notificationPreferences.notificationTypeStatus',
                    _filterNotificationTypesStatuses(),
                );
            }
        }

        /**
         * Check if a user is super (global) admin or instance admin.
         *
         * @return {boolean} If the user is admin or not.
         */
        function isAdmin() {
            return get(service.getConnected(), 'isSuperAdmin', false) || service.isInstanceAdmin();
        }

        /**
         * Check if the user is connected.
         *
         * @return {boolean} If the user is connected or not.
         */
        function isConnected() {
            return angular.isDefinedAndFilled(get(service.getConnected(), 'uid')) && !service.isGuest();
        }

        /**
         * Check if a user is connected through the `as` process.
         *
         * @return {boolean} If the user is connected as.
         */
        function isConnectedAs() {
            return get(service.getConnected(), 'isConnectedAs', false);
        }

        /**
         * Check if user is a designer.
         *
         * @param  {Object}  user The user to check.
         * @return {boolean} Whether the user is a designer or not.
         */
        function isDesigner(user) {
            user = user || service.getConnected() || {};

            return Boolean(user.isDesigner);
        }

        /**
         * Indicates if a user is an external account.
         *
         * @param  {Object}  user The user to check.
         * @return {boolean} Whether or not the given user is an external account or not.
         */
        function isExternal(user) {
            user = user || service.getConnected() || {};

            return user.accountType === 'external';
        }

        /**
         * Indicates if the current user is allowed to go full :godmode:.
         * Restricted to lumapps and MMB employees.
         *
         * @param  {Object}  [user] The user to check.
         * @return {boolean} Whether the user is god or not.
         */
        function isGod(user) {
            user = user || service.getConnected() || {};

            return Boolean(user.isGod);
        }

        /**
         * Indicates if a user is a Google account.
         *
         * @param  {Object}  user The user to check.
         * @return {boolean} Whether or not the given user is a Google account or not.
         */
        function isGoogle(user) {
            user = user || service.getConnected() || {};

            return user.accountType === 'google';
        }

        /**
         * Check if the user is not connected (a guest).
         *
         * @param  {Object}  user The user to check.
         * @return {boolean} If the user is guest or not.
         */
        function isGuest(user) {
            user = user || service.getConnected() || {};

            return Boolean(user.isGuest);
        }

        /**
         * Get user login provider.
         *
         * @param  {Object} [user] User from whom to get login provider.
         * @return {string} User login provider.
         */
        const getUserLoginProvider = (user = service.getConnected()) => {
            return get(user, 'loginProvider', undefined);
        };

        /**
         * Check if the user is connected with Google.
         *
         * @param  {Object}  [user] The user to check.
         * @return {boolean} If the user is connected with Google.
         */
        function isLoggedWithGoogle(user) {
            return getUserLoginProvider(user) === 'google';
        }

        /**
         * Check if the user is connected with Microsoft.
         *
         * @param  {Object}  [user] The user to check.
         * @return {boolean} If the user is connected with Microsoft.
         */
        function isLoggedWithMicrosoft(user) {
            return getUserLoginProvider(user) === 'microsoft';
        }

        /**
         * Check if the current user is an admin of the current instance.
         *
         * @return {boolean} Whether the current user is an instance admin or not.
         */
        function isInstanceAdmin() {
            return (
                service.isConnected() &&
                includes(get(service.getConnected(), 'instancesSuperAdmin', []), Instance.getCurrentInstanceId())
            );
        }

        /**
         * Indicates if a user is a Microsoft account.
         *
         * @param  {Object}  user The user to check.
         * @return {boolean} Whether or not the given user is a Microsoft account or not.
         */
        function isMicrosoft(user) {
            user = user || service.getConnected() || {};

            return user.accountType === 'microsoft';
        }

        /**
         * Indicates if a user is an Okta account.
         *
         * @param  {Object}  user The user to check.
         * @return {boolean} Whether or not the given user is an Okta account or not.
         */
        function isOkta(user) {
            user = user || service.getConnected() || {};

            return user.accountType === 'okta';
        }

        /**
         * Check if the password of a given user can be edited.
         *
         * @param  {Object}  [user=<_connectedUser>] The user we want to check if the password can be edited or not.
         * @return {boolean} Whether or not the password can be edited.
         */
        function isPasswordEditable(user) {
            user = user || service.getConnected() || {};

            return service.isExternal(user) || (service.isGoogle(user) && Customer.isSsoEnabled());
        }

        /**
         * List the accounts the user can log with.
         *
         * @return {Promise} The promise of the loading.
         */
        function listAccountChoices() {
            const promise = UserFactory.accountChoices({
                // TODO [Clément]: implement projections.
                fields: undefined,
            }).$promise;

            promise.then(function onGetAccountsSuccess(response) {
                _accounts = response.items;
            });

            return promise;
        }

        /**
         * Redirect to profile or user directory.
         *
         * @param {Object} user The user to redirect to.
         */
        function redirectToProfile(user) {
            if (angular.isUndefinedOrEmpty(user)) {
                return;
            }

            const UserDirectoryService = $injector.get('UserDirectory');

            const isUserEmailDefined = angular.isDefinedAndFilled(get(user, 'email'));

            /*
             * For customers with an external users directory defined, open it in a new window.
             */
            if (angular.isDefinedAndFilled(Customer.getExternalDirectory()) && isUserEmailDefined) {
                const emailSplit = user.email.split('@');
                const username = emailSplit[0];
                const domain = emailSplit[1];
                const url = Customer.getExternalDirectory()
                    .replace('{username}', username)
                    .replace('{domain}', domain)
                    .replace('{primaryEmail}', user.email);

                $window.open(url, 'blank_');
            } else {
                Instance.getSiblings(false).then(function onSiblingsListSuccess() {
                    UserDirectoryService.setDisplayedUserOrRedirect(user.id);
                });
            }
        }

        /**
         * Navigate to the social profile of a given user.
         *
         * @param {Object} [user=<_connectedUser>] A given user which will determine the social profile to navigate to.
         */
        function redirectToSocialProfile(user) {
            user = user || service.getConnected();

            if (angular.isUndefinedOrEmpty(user.id)) {
                return;
            }

            $state.go('app.front.profile', {
                userIdentifier: user.id === service.getConnected().id ? 'me' : user.id,
            });
        }

        /**
         * Redirect to a user profile, get default directory or first one available.
         *
         * @param {Object} user A user object to figure out where to redirect the connected user to.
         */
        function redirectToUserDirectoryProfile(user) {
            user = user || service.getConnected();

            const directories = [InitialSettings.USER_DIRECTORIES, InitialSettings.PARENT_USER_DIRECTORIES];

            if (angular.isUndefinedOrEmpty(user)) {
                return;
            }

            const isUserEmailDefined = angular.isDefinedAndFilled(get(user, 'email'));

            /*
             * For customers with an external users directory defined, open it in a new window.
             * Example: https://directory.customer.com/people/v1/{username}-{domain}.
             */
            if (angular.isDefinedAndFilled(Customer.getExternalDirectory()) && isUserEmailDefined) {
                const emailSplit = user.email.split('@');
                const username = emailSplit[0];
                const domain = emailSplit[1];
                const url = Customer.getExternalDirectory()
                    .replace('{username}', username)
                    .replace('{domain}', domain)
                    .replace('{primaryEmail}', user.email);

                $window.open(url, 'blank_');
            } else if (angular.isDefinedAndFilled(user) && angular.isDefinedAndFilled(directories, 'some')) {
                if (isUserEmailDefined) {
                    $injector.get('UserDirectory').setDisplayedUserOrRedirect(user.email, true);
                } else {
                    $injector.get('UserDirectory').setDisplayedUserOrRedirect(user.id, false);
                }
            }
        }

        /**
         * Refresh the user authentication token.
         *
         * @param {Function} [cb]    A callback function to execute when token is successfully refreshed.
         * @param {Function} [errCb] A callback function to execute if no token have been generated.
         */
        function refreshToken(cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            UserAuthFactory.refreshToken(
                {
                    customerId: Customer.getCustomerId(),
                },
                function onRefreshTokenSuccess(response) {
                    const token = get(response, 'token');
                    const status = get(response, 'status');

                    if (token !== '' && status) {
                        service.setConnectedUser(_connectedUser, get(response, 'token'));

                        window.USER_ACCESS_TOKEN = get(response, 'token');
                    } else if (angular.isUndefinedOrEmpty(_token)) {
                        errCb(Translation.translate('INVALID_TOKEN_ERROR'));

                        return;
                    } else if (token === '' && !status) {
                        errCb(Translation.translate('INVALID_TOKEN_ERROR'));

                        return;
                    } 

                    cb(_token);
                },
                function onRefreshTokenError(err) {
                    service.setToken(undefined);
                    errCb(err);
                },
            );
        }

        /**
         * Set the connected user.
         *
         * @param {Object} user    The user to set as connected.
         * @param {string} [token] The connected user's token.
         */
        function setConnectedUser(user = {}, token) {
            _connectedUser = user;
            LocalStorage.put('user', _connectedUser);

            if (!angular.isUndefinedOrEmpty(token)) {
                service.setToken(token);

                // Store the token inside CONNECTED_USER for use outside of angular.
                angular.extend(_connectedUser, { token });
            }

            // eslint-disable-next-line angular/window-service
            window.CONNECTED_USER = _connectedUser;
        }

        /**
         * Set the profile picture of a connected user to a given value.
         *
         * @param {string} profilePicture The url to set the profile picture to.
         */
        function setProfilePicture(profilePicture) {
            if (angular.isUndefinedOrEmpty([profilePicture, _connectedUser], 'some')) {
                return;
            }

            _connectedUser.profilePicture = profilePicture;
        }

        /**
         * Set the connected user's authentication token.
         *
         * @param {string} token The connected user's token.
         */
        function setToken(token) {
            if (angular.isUndefinedOrEmpty(token)) {
                LocalStorage.remove('token');
                _token = undefined;

                return;
            }

            _token = token;

            LocalStorage.put('token', _token, undefined, true);
        }

        /////////////////////////////
        //                         //
        //          Redux          //
        //                         //
        /////////////////////////////

        /**
         * Should return the service data that need to be synced with redux.
         *
         * @return {Object } The state aka. the store shape.
         */
        function mapStateToRedux() {
            return {
                connectedUser: {
                    ..._connectedUser,
                    token: _token,
                },
                initialized: true,
            };
        }

        /**
         * Should return the redux data that need to be synced with angular.
         */
        function mapReduxToAngular({ connectedUser, token, initialized }) {
            /**
             * For now, the user is fetched by angular and should always be set by angular first,
             * to be sure we don't push an empty user to the angular state,
             * we check if it has been initialized by angular.
             */
            if (initialized) {
                setConnectedUser({ ...connectedUser }, token);
            }
        }

        // The namespace for this service in the redux store.
        service.reduxReducerName = 'user';

        // The action type triggered when Angular updated the state.
        service.reduxUpdateActionType = 'user/update';

        // Expose the appropriate functions.
        service.mapStateToRedux = mapStateToRedux;
        service.mapReduxToAngular = mapReduxToAngular;

        /////////////////////////////

        service.debouncedFilterize = debounce(service.filterize, _DEBOUNCE_DELAY);
        service.getAccountChoices = getAccountChoices;
        service.getAccountType = getAccountType;
        service.getAllStatus = getAllStatus;
        service.getApiProfile = getApiProfile;
        service.getAuthorProjection = getAuthorProjection;
        service.getAvatarUrl = getAvatarUrl;
        service.getConnected = getConnected;
        service.getMailToLink = getMailToLink;
        service.getProfilePicture = getProfilePicture;
        service.getSecondaryField = getSecondaryField;
        service.getSecondaryFieldName = getSecondaryFieldName;
        service.getToken = getToken;
        service.getUserFullName = getUserFullName;
        service.hasSubscriptions = hasSubscriptions;
        service.initUserLangsAndNotifications = initUserLangsAndNotifications;
        service.isAdmin = isAdmin;
        service.isConnected = isConnected;
        service.isConnectedAs = isConnectedAs;
        service.isExternal = isExternal;
        service.isDesigner = isDesigner;
        service.isGod = isGod;
        service.isGoogle = isGoogle;
        service.isGuest = isGuest;
        service.isLoggedWithGoogle = isLoggedWithGoogle;
        service.isLoggedWithMicrosoft = isLoggedWithMicrosoft;
        service.isInstanceAdmin = isInstanceAdmin;
        service.isMicrosoft = isMicrosoft;
        service.isOkta = isOkta;
        service.isPasswordEditable = isPasswordEditable;
        service.listAccountChoices = listAccountChoices;
        service.redirectToProfile = redirectToProfile;
        service.redirectToSocialProfile = redirectToSocialProfile;
        service.redirectToUserDirectoryProfile = redirectToUserDirectoryProfile;
        service.refreshToken = refreshToken;
        service.setConnectedUser = setConnectedUser;
        service.setProfilePicture = setProfilePicture;
        service.setToken = setToken;
        service.getMail = getMail;

        /////////////////////////////

        /**
         * Initialize the service.
         *
         * @param  {boolean} [fromAdminCustomer=false] Indicates if we are logging in from the admin customer (this
         *                                             state doesn't have any customer slug).
         * @param  {boolean} [initEmptyOnError=true]   Initialize an empty guest user on error.
         * @return {Promise} The promise of the initialization of the user.
         */
        service.init = function init(fromAdminCustomer) {
            if (fromAdminCustomer && angular.isUndefined($location.search().token)) {
                UserAuthFactory.getCustomerAdminToken()
                    .$promise.then(function onGetAdminTokenSuccess(response) {
                        service.setToken(response.token);

                        service.connectedUserDeferred.resolve();
                    })
                    .catch(service.connectedUserDeferred.reject);
            }

            if (angular.isUndefinedOrEmpty(service.getConnected())) {
                service.setConnectedUser(_GUEST_USER, undefined);
            }

            service.connectedUserDeferred.resolve(service.getConnected());

            // Enable Redux sync.
            ReduxStore.subscribe(service, true);

            return service.connectedUserDeferred.promise;
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('User', UserService);
})();
