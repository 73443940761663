import { saApi } from './constants';

/**
 * Get a shareable content by it's Core ID.
 *
 * @param  {number}                contentCoreId The content ID.
 * @param  {string}                activeFilter  Filter parameter used to get either
 *                                               non archived ( = 1) / archived ( = 2 ) / all content ( = undefined )
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const getShareableContent = (contentCoreId, activeFilter = '1') => {
    const filter = activeFilter ? `&active=${activeFilter}` : '';

    return saApi.get(`shareable-contents?contentInternalId=${contentCoreId}${filter}`).then((response) => {
        return response.data.items && response.data.items.length > 0 ? response.data.items[0] : undefined;
    });
};

/**
 * Create a new shareable content.
 *
 * @param  {Object}                shareableContent As required by the API.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const postShareableContent = (shareableContent) => saApi.post('shareable-contents', shareableContent);

/**
 * Validates a shareable content.
 *
 * @param  {Object}                shareableContent As required by the API.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const validateShareableContent = (shareableContent) =>
    saApi.post('shareable-contents/validate-creation', shareableContent);

/**
 * Update a shareable item.
 *
 * @param  {string}                     contentId        The Id of the content to update .
 * @param  {Object} shareableContent The content to update.
 * @return {Promise<HTTPResponse>}      HTTP Response.
 */
export const putShareableContent = (contentId, shareableContent) =>
    saApi.put(`shareable-contents/${contentId}`, shareableContent);

/**
 * Archive/delete/deactive this shareable content.
 *
 * @param  {string}                contentId Content id to deasctivate / Archive.
 * @return {Promise<HTTPResponse>} HTTP Response.
 */
export const deactivateShareableContent = (contentId) => saApi.put(`shareable-contents/${contentId}/deactivate`);
