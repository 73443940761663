/* istanbul ignore file */
import { get } from '@lumapps/constants';

const constants = get();

/**
 * ==================================================================================================================
 * PLEASE READ THE DESCRIPTION OF THIS FUNCTION BEFORE USING IT. WITH GREAT POWER COMES GREAT RESPONSIBILITY.
 * ==================================================================================================================
 *
 * Broadcasts an event to the angular application. This is extremely hacky and something that we want
 * to remove as soon as the migration is over. Unfortunately, there are non migrated functionalities that need
 * to be triggered from a React component and that we want to migrate them later on. This is definitely a last resort,
 * if you can trigger a functionality by navigating to a specific URL, or if you can do it with a change on the redux
 * store, please DO IT, this should really really be a last resort, open-in-case-of-fire-solution.
 *
 * @param event
 * @param payload
 */
export const broadcast = (event: string, payload?: any) => {
    const { angular, legacyApplicationName } = constants;

    if (angular) {
        angular.module(legacyApplicationName).run([
            '$rootScope',
            function rootScope($rootScope: any) {
                $rootScope.$broadcast(event, payload);
            },
        ]);
    }
};
