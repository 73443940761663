(function IIFE() {
    'use strict';

    /////////////////////////////

    function Template(Content, Customer, Header, Instance, LumsitesBaseService, Metadata, TemplateFactory, Utils) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(TemplateFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
            // eslint-disable-next-line no-use-before-define
            postGet: _postGet,
            // eslint-disable-next-line no-use-before-define
            postSave: _postSave,
            // eslint-disable-next-line no-use-before-define
            preSave: _preSave,
        });

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        service.defaultParams = {};

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Format the server object to send the correct item to the client.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formated object.
         */
        function _formatServerObjectToClient(obj) {
            Utils.initComponents(obj);
            Metadata.formatMetadataForClient(obj, false);

            return obj;
        }

        /**
         * Pre Save function to override object.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formated object.
         */
        function _preSave(obj) {
            if (angular.isUndefined(obj.instance)) {
                obj.instance = Instance.getCurrentInstanceId();
            }
            if (!obj.fixedLayout) {
                obj.fixedWidgets = false;
            }

            Metadata.formatMetadataForServer(obj);

            return obj;
        }

        /**
         * Post Save function to override object.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formated object.
         */
        function _postSave(obj) {
            return _formatServerObjectToClient(angular.copy(obj));
        }

        /**
         * Post Get function to override object.
         *
         * @param  {Object} obj The object to format.
         * @return {Object} The formated object.
         */
        function _postGet(obj) {
            Content.originalContent = angular.fastCopy(obj);

            return _formatServerObjectToClient(angular.copy(obj));
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Duplicate the obj template.
         *
         * @param {Object} obj    Template object to duplicate
         * @param {Object} params Params corresponding to the $stateParams given.
         */
        function duplicate(obj, params) {
            if (angular.isUndefinedOrEmpty(obj.type)) {
                obj.properties = obj.properties || {};
                obj.properties.duplicatedTemplate = {
                    id: obj.id,
                };
            } else {
                var properties = obj.properties || {};
                var headerDetails;
                if (angular.isDefinedAndFilled(obj.headerDetails)) {
                    headerDetails = obj.headerDetails;
                }

                obj = obj.template;
                obj.name = {};
                obj.properties = properties;
                obj.headerDetails = headerDetails;

                service.setCurrent(obj);
            }

            // Remove tags whenever we are switching a template from a CCT to another one
            if (obj.customContentType !== params.customContentType) {
                obj.customContentTypeTags = [];
            }

            // Reset the id to add the new template duplicated.
            obj.id = undefined;
            obj.uid = undefined;
            obj.contentType = undefined;

            // Deassociate header but keep header's content (only if the template use the default header).
            if (_.get(Header.getDefaultHeader(), 'id') === obj.header) {
                obj.header = undefined;
            }

            if (angular.isDefined(obj.headerDetails)) {
                obj.headerDetails.id = undefined;
            }

            if (angular.isDefinedAndFilled(params.instanceId)) {
                obj.instance = params.instanceId;
            } else {
                obj.instance = Instance.getCurrentInstanceId();
            }

            if (angular.isDefinedAndFilled(params.customContentType)) {
                obj.customContentType = params.customContentType;
            }

            if (angular.isDefinedAndFilled(params.contentType)) {
                obj.contentType = params.contentType;
            }
        }

        /**
         * Check if the user has rights to modify the template and his widgets
         * according to the template inheritance.
         *
         * @param  {Object}  template The template object.
         * @return {boolean} If the template is editable or not.
         */
        function isEditable(template) {
            return angular.isDefinedAndFilled(template) && template.instance === Instance.getCurrentInstanceId();
        }

        /**
         * Check if the user has rights to modify the template and his widgets for a specific content
         * according to the template inheritance.
         *
         * @param  {Object}  content  The content object.
         * @param  {Object}  template The template object.
         * @return {boolean} If the content is an editable template or not.
         */
        function isEditableForContent(content, template) {
            return angular.isDefinedAndFilled(content) &&
                (content.type !== 'template' || Content.getAction() === 'create' ||
                (content.type === 'template' && (angular.isUndefinedOrEmpty(template) ||
                    service.isEditable(template))));
        }

        /////////////////////////////

        service.duplicate = duplicate;
        service.isEditable = isEditable;
        service.isEditableForContent = isEditableForContent;

        /////////////////////////////

        /**
         * Initialize the service.
         */
        service.init = function init() {
            service.defaultParams = {
                customer: Customer.getCustomerId(),
                instance: Instance.getCurrentInstanceId(),
            };
        };

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Template', Template);
})();
