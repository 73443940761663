/* eslint-disable import/no-mutable-exports */
import noop from 'lodash/noop';

let translate = noop;

let isAvailable = noop;

let cancelCurrentQuery = noop;

/**
 * Expose the methods from the AngularJS service to the React world.
 *
 * @param {Service} gTranslate The AngularJS Google Translate service.
 */
function setGTranslate(gTranslate) {
    isAvailable = gTranslate.isAvailable;
    translate = gTranslate.translate;
    cancelCurrentQuery = gTranslate.cancelCurrentQuery;
}

export { setGTranslate, translate, isAvailable, cancelCurrentQuery };
