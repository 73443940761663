/* istanbul ignore file */
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

export interface ShareToExtensionState {
    currentDialogPostId?: string;
}

export const initialState: ShareToExtensionState = {
    currentDialogPostId: undefined,
};

export const { actions, reducer } = createSlice({
    domain: 'shareToExtension',
    initialState,
    reducers: {
        toggleDialog: (state: ShareToExtensionState, action: PayloadAction<string>) => {
            set(state, 'currentDialogPostId', action.payload);
        },
    },
});
