import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { TranslateObject } from '@lumapps/translations';

import { getCurrentHeader } from '../../ducks/selectors';
import { ContentHeader, ContentHeaderProps } from './ContentHeader';

const mapStateToProps = (state: FrontOfficeStore): ContentHeaderProps => {
    const header = getCurrentHeader(state);
    const instance = getCurrentInstance(state);
    const currentLanguage = currentLanguageSelector(state);

    return {
        mediaList: header?.properties?.media || [],
        hasAutoplay: header?.properties?.autoplay ?? false,
        hasRandom: header?.properties?.random ?? false,
        autoplayInterval: header?.properties?.interval ?? 0,
        displayType: header?.properties?.style,
        wrapperHeight: header?.properties?.wrapperHeight,
        mediaClass: header?.properties?.mediaClass,
        slideshowHeight: header?.height ?? 0,
        defaultTitle: instance?.title as TranslateObject,
        defaultDescription: instance?.subtitle as TranslateObject,
        currentLanguage,
    };
};

const ConnectedContentHeader = connect(mapStateToProps, null)(ContentHeader);

export { ConnectedContentHeader as ContentHeader };
