import isEqual from 'lodash/isEqual';
import last from 'lodash/last';

import { Node, Editor, ReactEditor } from '..';

/**
 * Check if the cursor is placed on the last line of a given block,
 * @param editor The editor to evaluate
 * @param block  The block we are checking
 * @returns Whether the cursor is on the last line of a block
 */
export const isOnLastLineOfBlock = (editor: ReactEditor, block: Node) => {
    if (!editor.selection) {
        return false;
    }

    const isCursorInBlock = Array.from(Editor.nodes(editor, { at: editor.selection, match: (n) => isEqual(n, block) }));

    if (!isCursorInBlock.length) {
        return false;
    }

    // Slate is not aware of the display of a textnode, so there is no way to know, through Slate, if the cursor
    // is on the last line of a block. If your paragraph is too long, or contains softbreaks, it will be on
    // multiple lines.
    // The workaround used here is to convert the Slate node and the selection range to DOMNodes and compare
    // their positions. If they have the same bottom position, then the selection is on the last line of the block.
    const currentNodeAsDOM = ReactEditor.toDOMNode(editor, block);

    const selectionRangeAsDOM = ReactEditor.toDOMRange(editor, editor.selection);

    // The selection can be multiline, when this is the case, the DOMRange is represented by multiple rects,
    // corresponding to each part of the selection. Here we only want to check the last one, which will be the bottom rect.
    const selectionRangeRects = selectionRangeAsDOM.getClientRects();
    const lastSelectionRect = last(selectionRangeRects) as DOMRect;

    // When comparing the positions, we have to consider that the selection rect height and the line height of the block.
    // Considering the selection is always centered related to the block line, then the
    // offset is (LINE_HEIGHT - SELECTION_SIZE)/2
    const bodyLineHeight = parseFloat(getComputedStyle(currentNodeAsDOM).lineHeight);
    const selectionRectHeight = lastSelectionRect.height;
    const selectionOffset = (bodyLineHeight - selectionRectHeight) / 2;

    // Now that we have everything, we can compare the bottom position of the block and the bottom position of the selection plus the offset.
    // We let a tolerance of 5px to deal with different alignments depending on the browser.
    return (
        Math.abs(currentNodeAsDOM.getBoundingClientRect().bottom - (lastSelectionRect.bottom + selectionOffset)) <= 5
    );
};
