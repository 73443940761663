import { CustomerMobileLoginConfiguration } from '@lumapps/customer/types';
import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { BackOfficeStore } from '@lumapps/redux/types';

import { QR_CODE_LOGIN } from '../constants';

export const getMobileLoginConfigurationForm = (state: BackOfficeStore) => state?.mobile?.login || {};

export const isMobileLoginConfigurationLoading = createSelector(
    getMobileLoginConfigurationForm,
    (login: CustomerMobileLoginConfiguration) => login?.isLoading || false,
);

export const isQrCodeLoginFeatureEnabled = isFeatureEnabled(QR_CODE_LOGIN);
