import React, { RefObject } from 'react';

import isEmpty from 'lodash/isEmpty';

import { reducer, initialState, actions } from '../ducks/slice/reportSlice';
import { fetchUserReport } from '../ducks/thunks/fetchUserReport';
import { ReportedResourceType } from '../types';

export const useReport = ({
    resourceType,
    resourceId,
    reportBtnRef,
}: {
    resourceType: ReportedResourceType;
    resourceId: string;
    reportBtnRef?: RefObject<HTMLButtonElement>;
}) => {
    const [isResourceAlreadyReported, setIsResourceAlreadyReported] = React.useState(false);
    const [{ reportDialog, alreadyReportedDialog, thanksDialog }, dispatchDialogActions] = React.useReducer(
        reducer,
        initialState,
    );

    const onReportButtonClick = React.useCallback(async () => {
        dispatchDialogActions(actions.openReportDialog());
        dispatchDialogActions(actions.setReportDialogStatus(true));
        const userReport = await fetchUserReport({ resourceId, resourceType });

        if (!isEmpty(userReport)) {
            dispatchDialogActions(actions.openAlreadyReportedDialog());
            dispatchDialogActions(actions.closeReportDialog());
            setIsResourceAlreadyReported(true);
        }
        dispatchDialogActions(actions.setReportDialogStatus(false));
    }, [resourceId, resourceType]);

    return {
        onReportButtonClick,
        isResourceAlreadyReported,
        ReportTunnelProps: {
            isReportDialogLoading: reportDialog.isLoading,
            isReportDialogOpen: reportDialog.isOpen,
            isThanksDialogOpen: thanksDialog.isOpen,
            resourceId,
            resourceType,
            reportBtnRef,
            setIsResourceAlreadyReported,
            isAlreadyReportedDialogOpen: alreadyReportedDialog.isOpen,
            dispatchDialogActions,
        },
    };
};
