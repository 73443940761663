import React from 'react';

import { spacings } from '@lumapps/classnames';
import { Chip, ChipProps } from '@lumapps/lumx/react';

import { UseSingleSelectFilter } from '../../hooks/useSingleSelectFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

export interface SingleChipFilterProps<T> extends FilterProps {
    /** list of options to display as chips */
    options: T[];
    /** determines how to render the react node for the chip */
    renderChip: (entity: T) => React.ReactNode;
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelected: UseSingleSelectFilter<T>['onSelected'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    selection: UseSingleSelectFilter<T>['selection'];
    /** custom props for the rendered chip */
    chipProps?: (entity: T) => Partial<ChipProps>;
    /** Callback to get the id for each filter.  */
    getId?: (entity: T) => string;
}

/**
 * Component that renders a single select chip filter, used in the `@lumapps/lumx-filter-and-sort` pattern.
 * Commonly used for showing statuses, such as `Draft`, `Active`, `Inactive`, `Archived`
 *
 * @family Filters
 * @param SingleChipFilterProps
 * @returns SingleChipFilter
 */
export const SingleChipFilter = <T,>({
    options,
    renderChip,
    onSelected,
    selection,
    chipProps = () => ({}),
    getId,
    ...props
}: SingleChipFilterProps<T>) => {
    return (
        <Filter {...props} isFieldset>
            {options.map((option, index) => (
                <Chip
                    key={getId ? getId(option) : index}
                    className={spacings([
                        { type: 'margin', direction: 'right' },
                        { type: 'margin', direction: 'bottom' },
                    ])}
                    onClick={() => onSelected(option)}
                    isSelected={selection && getId ? getId(option) === getId(selection) : false}
                    {...chipProps(option)}
                >
                    {renderChip(option)}
                </Chip>
            ))}
        </Filter>
    );
};
