import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import property from 'lodash/fp/property';

import evolve from '@lumapps/utils/function/evolve';

export const formatPostListFiltersForApi: (filters: Record<string, any>) => Record<string, any> = evolve({
    authorEmail: flow(head, property('email')),
    hasRelevantComment: (value: string) => property(value, { only: true, exclude: false }),
});
