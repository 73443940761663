import { useState } from 'react';

export const usePostAnalyticsDialog = (postId: string) => {
    const [isOpen, setIsOpen] = useState(false);

    const openPostAnalyticsDialog = () => {
        setIsOpen(true);
    };

    const closeAnalyticsDialog = () => {
        setIsOpen(false);
    };

    const postAnalyticsDashboardName = 'post-overview';
    const postAnalyticsDashboardUrl = `/posts/${postId}/analytics/embedded/${postAnalyticsDashboardName}`;

    return {
        isPostAnalyticsDialogOpen: isOpen,
        postAnalyticsDashboardName,
        postAnalyticsDashboardUrl,
        openPostAnalyticsDialog,
        closeAnalyticsDialog,
    };
};
