import isString from 'lodash/isString';

import { currentArticleTitleSelector } from '@lumapps/articles/ducks/selectors';
import { gTranslate } from '@lumapps/auto-translation';
import { getCurrentContentTitle } from '@lumapps/contents/ducks/selectors';
import { currentEventTitleSelector } from '@lumapps/events/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { BlockTitle } from '@lumapps/widget-title/type';

import { actions } from '../slice';

/**
 * Translate content title & update title widgets.
 *
 * @param translate    whether to translate or restore the original title
 * @param targetLang   the target language to translate to
 * @param titleWidgets title widgets to update after translation.
 * @return the new content title
 */
export const translateTitle =
    (translate: boolean, targetLang: string, titleWidgets: Array<{ widgetId: string }> = []) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState): Promise<string> => {
        const state = getState();
        const currentLanguage = currentLanguageSelector(state);
        // Get the entity title
        const contentTitle =
            getCurrentContentTitle(state) ||
            currentArticleTitleSelector(state) ||
            currentEventTitleSelector(state) ||
            {};

        const currentTitle = isString(contentTitle)
            ? contentTitle
            : contentTitle[currentLanguage] || Object.values(contentTitle)[0];

        // Reset translation.
        if (!translate) {
            for (const { widgetId } of titleWidgets) {
                dispatch(
                    actions.setWidgetProperties({
                        widgetId,
                        widgetProperties: { body: { text: currentTitle } as Partial<BlockTitle> } as any,
                    }),
                );
            }
            return currentTitle;
        }

        // Set title widgets loading.
        for (const { widgetId } of titleWidgets) {
            dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'loading' } }));
        }
        try {
            const translatedTitle = await gTranslate({ content: currentTitle, isContentHTML: false, targetLang });

            // Set title widgets loaded & replace content with translation.
            for (const { widgetId } of titleWidgets) {
                dispatch(
                    actions.setWidgetProperties({
                        widgetId,
                        widgetProperties: {
                            body: { text: translatedTitle },
                            state: 'loaded',
                        } as any,
                    }),
                );
            }

            return translatedTitle;
        } catch (e) {
            // Set title widgets loaded.
            for (const { widgetId } of titleWidgets) {
                dispatch(actions.setWidgetProperties({ widgetId, widgetProperties: { state: 'loaded' } }));
            }

            // Rethrow error. To be handled by the parent caller.
            throw e;
        }
    };
