import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useThemeContext } from '@lumapps/utils/hooks/useThemeContext';
import { getEmbedMapUrl } from '@lumapps/utils/maps/getEmbedMapUrl';
import { getMapUrlCoordinates } from '@lumapps/utils/maps/getMapUrlCoordinates';
import { getMapWebsiteName } from '@lumapps/utils/maps/getMapWebsiteName';
import { ElementRender } from '@lumapps/wrex/types';

import { EmbeddedMapElement } from '../../../types';

import './index.scss';

export const CLASSNAME = 'wrex-embedded-map';

export const EmbeddedMap: ElementRender<EmbeddedMapElement, HTMLDivElement> = ({
    element,
    elementRef,
    children,
    className,
    ...forwardedProps
}) => {
    const { url, title } = element ?? {};
    const { block, element: elementClassName } = useClassnames(CLASSNAME);
    const embedUrl = React.useMemo(() => {
        const urlCoords = url && getMapUrlCoordinates(url);
        return urlCoords && getEmbedMapUrl(urlCoords, getMapWebsiteName(url));
    }, [url]);
    const theme = useThemeContext();

    return (
        <div className={block(`theme-${theme}`, [className])} ref={elementRef} {...forwardedProps}>
            {children}
            <iframe
                className={elementClassName('embed')}
                title={title}
                src={embedUrl}
                frameBorder="0"
                allowFullScreen
                contentEditable={false}
            />
        </div>
    );
};

EmbeddedMap.displayName = 'embedded-map';
