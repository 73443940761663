import { type GetConnectorListParams } from '@lumapps/connectors/api/connectorsApi';

import type { InstalledExtensionAPIProps } from '../legacy-types';
import { GetInstalledExtensionsParams } from './extensionApi';

/* istanbul ignore file */
export * from './connectorApi';
export * from './extensionApi';
export * from './partnerApi';

export const extensionConnectorsQueryKeys = {
    all: ['marketplace', 'connectors'] as const,
    list: (filters: GetConnectorListParams) => [...extensionConnectorsQueryKeys.all, { ...filters }] as const,
    item: (id: string) => [...extensionConnectorsQueryKeys.all, id] as const,
};

export const extensionsTopicsQueryKeys = {
    all: () => ['installed-extensions-topics'] as const,
};

export const installedExtensionsQueryKeys = {
    all: () => ['installed-extensions'] as const,
    list: (params: GetInstalledExtensionsParams) => [...installedExtensionsQueryKeys.all(), params] as const,
    item: (extensionId: InstalledExtensionAPIProps['id']) =>
        [...installedExtensionsQueryKeys.all(), extensionId] as const,
};
