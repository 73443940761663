import { CalendarSpec, MomentInput } from 'moment';

import { getMoment } from '../time/getMoment';

/**
 * Return the given date with a moment "calendar" format.
 * From the docs: moment#calendar will format a date with different strings depending
 * on how close to referenceDay's date (today by default) the date is.
 *
 * See https://momentjs.com/docs/#/displaying/calendar-time/
 */
export const formatAsCalendar = (date?: MomentInput, formats?: CalendarSpec) => {
    if (!formats || !date) {
        return undefined;
    }

    const moment = getMoment();
    const momentDate = moment.utc(date);

    return momentDate.calendar({
        /**
         * Reset all default formats to only use the given overrides.
         * Without this, the `calendar` method will return unwanted formatted dates.
         * By setting empty strings for all, we can use `fromNow` as fallback if this function
         * wasn't able to format the date
         * */
        nextDay: '[]',
        nextWeek: '[]',
        lastDay: '[]',
        lastWeek: '[]',
        sameDay: '[]',
        sameElse: '[]',
        ...formats,
    });
};
