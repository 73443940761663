import { ReactEditor, BaseSelection } from '@lumapps/wrex/slate';

export const getCurrentRange = (editor: ReactEditor, slateSelection: BaseSelection, domSelection: Selection | null) => {
    const hasSlateSelection = slateSelection && slateSelection.anchor.offset !== slateSelection.focus.offset;
    const hasDOMSelection = domSelection && domSelection.anchorOffset !== domSelection.focusOffset;

    if (hasSlateSelection) {
        try {
            return ReactEditor.toDOMRange(editor, slateSelection);
        } catch (e) {
            return undefined;
        }
    }

    if (hasDOMSelection) {
        try {
            return domSelection.getRangeAt(0);
        } catch (e) {
            return undefined;
        }
    }

    return undefined;
};
