import React, { useState, useRef } from 'react';
import { func, bool } from 'prop-types';

import { mdiMenuDown, mdiCloseCircle } from '@lumapps/lumx/icons';
import { List, ListItem, Size, Chip, Icon, Dropdown, Placement } from '@lumapps/lumx/react';

import { translate as t } from 'components/translations';

const SEARCH_SCOPES = {
    FOLLOWING: 'following',
    ALL: 'all',
};

/**
 * Provides a Select component in order to change the community search's scope
 *
 * @return {JSX.Element | null} The Select component.
 */
const CommunitySearchScopeSelection = ({ filterFollowingOnly, onSelectSearchScope }) => {
    const anchorSimpleRef = useRef(null);
    const [isSimpleOpen, setSimpleIsOpen] = useState(false);

    const onItemSelectedHandler = (item, onClose) => {
        onSelectSearchScope(item === SEARCH_SCOPES.FOLLOWING);
        onClose();
    };

    const toggleSimpleMenu = () => {
        setSimpleIsOpen(!isSimpleOpen);
    };

    const closeSimpleMenu = () => {
        setSimpleIsOpen(false);
    };

    const handleAfterClick = () => {
        if (filterFollowingOnly) {
            toggleSimpleMenu();
        } else {
            onSelectSearchScope(true);
        }
    };

    return (
        <>
            <Chip
                after={
                    filterFollowingOnly ? (
                        <Icon icon={mdiMenuDown} size={Size.xs} />
                    ) : (
                        <Icon icon={mdiCloseCircle} size={Size.xs} />
                    )
                }
                isSelected={!filterFollowingOnly}
                onClick={toggleSimpleMenu}
                onAfterClick={handleAfterClick}
                ref={anchorSimpleRef}
            >
                <span>
                    {filterFollowingOnly
                        ? t('FRONT.COMMUNITY.SEARCH.SCOPE.FOLLOWING')
                        : t('FRONT.COMMUNITY.SEARCH.SCOPE.ALL')}
                </span>
            </Chip>
            <Dropdown
                isOpen={isSimpleOpen}
                closeOnClickAway
                closeOnEscape
                onClose={closeSimpleMenu}
                placement={Placement.BOTTOM_START}
                anchorRef={anchorSimpleRef}
            >
                <List isClickable>
                    <ListItem
                        isSelected={filterFollowingOnly}
                        onItemSelected={() => onItemSelectedHandler(SEARCH_SCOPES.FOLLOWING, closeSimpleMenu)}
                        size={Size.tiny}
                    >
                        {t('FRONT.COMMUNITY.SEARCH.SCOPE.FOLLOWING')}
                    </ListItem>

                    <ListItem
                        isSelected={!filterFollowingOnly}
                        onItemSelected={() => onItemSelectedHandler(SEARCH_SCOPES.ALL, closeSimpleMenu)}
                        size={Size.tiny}
                    >
                        {t('FRONT.COMMUNITY.SEARCH.SCOPE.ALL')}
                    </ListItem>
                </List>
            </Dropdown>
        </>
    );
};

CommunitySearchScopeSelection.propTypes = {
    /** The current value of the filter. */
    filterFollowingOnly: bool.isRequired,
    /** The callback action to call when a value of the dropdown list is selected. */
    onSelectSearchScope: func.isRequired,
};

export { CommunitySearchScopeSelection };
