/**
 * Interface that any cache system in LumApps needs to implement
 */
export interface Cache {
    /** store a value on the given key */
    store: (key: string, value: any) => void;
    /** remove a given key from the cache */
    remove: (key: string) => any;
    /** retrieve a given key from the cache */
    retrieve: (key: string) => any;
    /** clear the entire cache */
    clearAll: () => void;
}

/**
 * Types of available caches
 */
export enum CACHE_TYPE {
    /** memory cache, TTL user session */
    MEMORY = 'memory',
    /** local storage cache, data will be there until it is purged */
    STORAGE = 'storage',
}
