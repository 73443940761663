(function IIFE() {
    'use strict';

    /////////////////////////////

    function DriveFilesFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/drive/files/:methodKey', {}, {
            copy: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'copy',
                },
            },

            destroy: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'destroy',
                },
            },

            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            insert: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'insert',
                },
            },

            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            move: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'move',
                },
            },

            search: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'search',
                },
            },

            trash: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'trash',
                },
            },

            untrash: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'untrash',
                },
            },

            update: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'update',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('DriveFilesFactory', DriveFilesFactory);
})();
