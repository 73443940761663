(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsSlideshowController($element, $scope, $window) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The number of pixels already slided.
         *
         * @type {number}
         */
        var _xPosition = 0;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The active slide index.
         *
         * @type {integer}
         */
        vm.activeIndex = 1;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Refresh slideshow navigation state and check if all the remaining carousel is visible.
         */
        function _refreshSlideshow() {
            var slideshowWidth = $element.find('.slideshow').width();
            var lastSlide = $element.find('.slideshow__slide:last-child');

            // If we display the first slide, we don't need the previous button.
            vm.displayPrevious = vm.activeIndex > 1;

            // If we see the end of the last slide we hide the next button.
            if (angular.isUndefinedOrEmpty(lastSlide)) {
                vm.displayNext = true;
            } else if (vm.activeIndex < vm.mediaList.length) {
                vm.displayNext =
                    slideshowWidth <= (_.get(lastSlide.position(), 'left', 0) + _xPosition + lastSlide.width());
            } else {
                vm.displayNext = false;
            }
        }

        /**
         * Animate slideshow wrapper;
         */
        function _slideshowAnimation() {
            _xPosition =
                _.get($element.find('.slideshow__slide:nth-child(' + vm.activeIndex + ')').position(), 'left', 0) * -1;

            $element.find('.slideshow__wrapper')
                .css({
                    '-moz-transform': 'translate3d(' + _xPosition + 'px, 0, 0)',
                    '-ms-transform': 'translate3d(' + _xPosition + 'px, 0, 0)',
                    '-o-transform': 'translate3d(' + _xPosition + 'px, 0, 0)',
                    '-webkit-transform': 'translate3d(' + _xPosition + 'px, 0, 0)',
                    'transform': 'translate3d(' + _xPosition + 'px, 0, 0)',
                });

            _refreshSlideshow();
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Display next image in slideshow.
         */
        function next() {
            if (vm.activeIndex === vm.mediaList.length) {
                return;
            }

            vm.activeIndex++;

            _slideshowAnimation();
        }

        /**
         * Display previous image in slideshow.
         */
        function previous() {
            if (vm.activeIndex === 1) {
                return;
            }

            vm.activeIndex--;

            _slideshowAnimation();
        }

        /////////////////////////////

        vm.next = next;
        vm.previous = previous;

        /////////////////////////////
        //                         //
        //        Watchers         //
        //                         //
        /////////////////////////////

        /**
         * Watch media list to refresh after list update.
         *
         * @param {Object} newValue The new filter object.
         * @param {Object} oldValue The old filter object.
         */
        $scope.$watch('vm.mediaList', function onMediaListChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                _refreshSlideshow();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.fieldsToDisplay = {
                description: false,
                name: false,
                tags: false,
                thumbnail: true,
            };

            if (angular.isUndefinedOrEmpty(vm.mediaList) || vm.mediaList.length === 1) {
                return;
            }

            _refreshSlideshow();

            // Update the slideshow on window resize.
            angular.element($window).bind('resize', _refreshSlideshow);
        }

        init();
    }

    /////////////////////////////

    function SlideshowDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: LsSlideshowController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                mediaList: '=lsMediaList',
            },
            templateUrl: '/client/common/modules/slideshow/views/slideshow.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsSlideshow', SlideshowDirective);
})();
