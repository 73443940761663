import React from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Text, FlexBox, Icon, Orientation, Size } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';
import { ViewModeType } from '@lumapps/widget-settings/types';

import './index.scss';

export interface ViewModeBlockProps<VM> {
    className?: string;
    viewMode: ViewModeType<VM>;
    dataScope?: string;
}

const CLASSNAME = 'view-mode-block';

export const ViewModeBlock = <VM,>({ className, dataScope, viewMode }: ViewModeBlockProps<VM>) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(dataScope ? `${dataScope}-view-mode` : 'view-mode');
    const { element, block } = useClassnames(CLASSNAME);

    return (
        <FlexBox
            className={block([element(`${viewMode.value}`), padding('vertical', 'big'), className])}
            orientation={Orientation.vertical}
            vAlign="center"
            {...get({ element: 'radio', action: `select-${viewMode.value}-view` })}
        >
            <Icon size={Size.l} icon={viewMode.icon} />
            <Text
                as="span"
                typography="subtitle1"
                className={element('label', [margin('top', 'regular'), padding('horizontal', 'regular')])}
                truncate
                title={translateKey(viewMode.label)}
            >
                {translateKey(viewMode.label)}
            </Text>
        </FlexBox>
    );
};
