/* eslint-disable @typescript-eslint/ban-ts-comment */
import find from 'lodash/find';
import keys from 'lodash/keys';

import { ENGINES } from '@lumapps/search/constants';
import { BaseSearchResult, SearchTab } from '@lumapps/search/types';

/**
 * Retrieves the corresponding component for the search result.
 */
const getComponent = (
    result: BaseSearchResult,
    metadata: Record<string, SearchTab>,
    // eslint-disable-next-line default-param-last
    engine = ENGINES.LUMAPPS,
    choices: Record<string, any>,
) => {
    const types = keys(choices);

    /**
     * Since this is determining the component
     * to use in a dynamic way, TypeScript goes a little bit crazy and gives out a bunch of errors since
     * it cannot resolve the type for the `type` variable. That is why there are a couple of @ts-ignore
     *
     * We first retrieve the template from the metadata returned by the backend. If the variable
     * template is present and we have a component that matches that template, we render it.
     *
     * It could be that there is a specific component that englobes all the results of the same kind,
     * for example google results. In that case, we check kind if there is not a specific component
     * defined for the template.
     */
    if (result.metadata) {
        const { sourceUid } = result.metadata;
        const resultMetadata = metadata[sourceUid];

        if (resultMetadata) {
            const { template } = resultMetadata;

            // @ts-ignore
            if (template && choices[template]) {
                // @ts-ignore
                return choices[template];
            }

            const { kind } = resultMetadata;

            // @ts-ignore
            if (kind && choices[kind]) {
                // @ts-ignore
                return choices[kind];
            }
        }

        /**
         * If the result metadatas has fields, return the ThirdPartyResult component
         */
        const { fields } = result.metadata;

        if (fields) {
            return choices['3p'];
        }
    }

    /**
     * We then check if there is a component associated to the type of engine that we are displaying.
     */
    // @ts-ignore
    if (engine && choices[engine]) {
        // @ts-ignore
        return choices[engine];
    }

    /**
     * If we could not determine the template, we go through the different keys that the result has
     * and if we have a component mapped to any of those keys, we use that component
     */
    // @ts-ignore
    const type = find(types, (typeKey: string) => result[typeKey] !== undefined);

    // @ts-ignore
    if (type && choices[type]) {
        // @ts-ignore
        return choices[type];
    }

    /** Worst case we render the default component */
    return choices.default;
};

export { getComponent };
