export enum LANGUAGES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=61562229
     */
    LANGUAGE_SWITCHER_ARIA_LABEL = 'FRONT.LANGUAGE_SWITCHER.ARIA_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191171276
     */
    SOURCE_LANGUAGE_DESCRIPTION = 'LANGUAGES.SOURCE_LANGUAGE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191171395
     */
    REFERENCE_LANGUAGE = 'LANGUAGES.REFERENCE_LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191171576
     */
    CONFIRM_SOURCE_LANGUAGE = 'LANGUAGES.CONFIRM_SOURCE_LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191173563
     */
    SOURCE_LANGUAGE = 'LANGUAGES.SOURCE_LANGUAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=191316381
     */
    TRANSLATION_MANAGER = 'LANGUAGES.TRANSLATION_MANAGER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206217309
     */
    VERSIONS = 'LANGUAGES.VERSIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206217247
     */
    MANAGE_TRANSLATIONS = 'LANGUAGES.MANAGE_TRANSLATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206217423
     */
    COMPLETED_TRANSLATIONS = 'LANGUAGES.COMPLETED_TRANSLATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206217414
     */
    USER_CONTENT_ACCESS = 'LANGUAGES.USER_CONTENT_ACCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206217932
     */
    SET_AS_SOURCE = 'LANGUAGES.SET_AS_SOURCE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=206398340
     */
    ADD_VERSION = 'LANGUAGES.ADD_VERSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=232024898
     */
    DISPLAY_ONLY_FOR_LANGUAGES = 'LANGUAGES.DISPLAY_ONLY_FOR_LANGUAGES',
}
