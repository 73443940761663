import type { Content } from '@lumapps/contents/types';
import type { Theme } from '@lumapps/lumx/react';
import { WidgetContentStyles, WidgetGlobalStyles } from '@lumapps/widget-styles/types';

import { Blocks } from './blocks';
import { WidgetContent } from './content';
import { Footer } from './footer';
import { Header } from './header';
import { LegacyWidget } from './legacy';

export interface Widget<WidgetType = string> {
    /** The widget's current cursor, for list widgets.. */
    cursor?: string;
    /** Whether the widget has more results to display. */
    more?: boolean;
    /** The pagination type to apply */
    paginationType?: 'infinite' | 'load_more';
    /** The widget's id.  */
    widgetId: string;
    /** The widget's type. */
    widgetType: WidgetType;
    /** The object describing the widget's header. */
    header?: Header;
    /** The object describing the widget's footer. */
    footer?: Footer;
    /** The object describing the content of the widget, containing the functional widget blocks. */
    body?: WidgetContent & Blocks;
    /** Whether the widget is collapsed upon load. If not, the widget is not collapsible. */
    isCollapsed?: boolean;
    /** Whether the widget is main widget */
    isMainWidget?: boolean;
    /** The id of the global style to use when displaying the widget, if any. */
    styleId?: string;
    /** The styles applied to the global widget wrapper. */
    style?: WidgetGlobalStyles;
    /** HTML id attribute. */
    htmlId?: string;
    /** HTML class attribute. */
    cssClass?: string;
    /** The legacy widget object. Used for BackendRecomputedWidgets */
    legacyWidget?: LegacyWidget;
}

export const isWidget = (o: any): o is Widget => Boolean(o && o.widgetType && o.widgetId);

export enum WidgetSizes {
    xs = 'xs',
    s = 's',
    m = 'm',
    l = 'l',
}

export interface EmptyStateRendererProps {
    widget?: Widget;
    widgetContentStyle?: WidgetContentStyles;
    theme?: Theme;
}

export interface ErrorStateRendererProps {
    widget?: Widget;
    widgetContentStyle?: WidgetContentStyles;
    refetchWidget(): void;
}

export interface OwnerResourceInfo {
    ownerResourceId: string;
    ownerResourceType: 'content' | 'community';
    properties: {
        instance: Content['instance'];
        title?: Content['title'];
        thumbnail?: Content['thumbnail'];
        mediaThumbnail?: Content['mediaThumbnail'];
    };
}
