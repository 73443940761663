/* istanbul ignore file */
import find from 'lodash/find';

import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { SLACK_FEATURE, TEAMS_FEATURE, YAMMER_FEATURE, WORKPLACE_FEATURE } from '../constants';
import { ChatsProps } from '../types';

export const organizationIdSelector = createSelector([customerSelector], (customer) => customer.id);

// Chat

export const chatSelector = (state: FrontOfficeStore): ChatsProps => state.chat;

/** Check if chat providers are activated */
export const isSlackEnabled = isFeatureEnabled(SLACK_FEATURE);
export const isTeamsEnabled = isFeatureEnabled(TEAMS_FEATURE);
export const isYammerEnabled = isFeatureEnabled(YAMMER_FEATURE);
export const isWorkplaceEnabled = isFeatureEnabled(WORKPLACE_FEATURE);

/** Check if at least one chat provider is activated. */
export const areChatProvidersActivated = createSelector(
    [isSlackEnabled, isTeamsEnabled, isYammerEnabled, isWorkplaceEnabled],
    (slackEnabled, teamsEnabled, yammerEnabled, workspaceEnabled) =>
        slackEnabled || teamsEnabled || yammerEnabled || workspaceEnabled,
);

export const chatProvidersSelector = createSelector(chatSelector, (state) => state.providers);

export const chatProvidersStatusSelector = createSelector(chatSelector, (state) => state?.chatProvidersStatus);

export const chatUserTokenStatusSelector = createSelector(chatSelector, (state) => state.userTokenStatus);

export const chatIsUserTokenStatusSelector = createSelector(chatSelector, (state) => state.isUserTokenStatus);

export const chatIsDisconnectingSelector = createSelector(chatSelector, (state) => state.isDisconnecting);

export const chatIsFetchAuthorizeLinkSelector = createSelector(chatSelector, (state) => state.isFetchAuthorizeLink);

export const chatUserSpacesSelector = createSelector(chatSelector, (state) => state.userSpaces);

export const chatUserSpacesStatusSelector = createSelector(chatSelector, (state) => state.userSpacesStatus);

export const chatUserSpacesTokenLoadingStatusSelector = createSelector(
    chatSelector,
    (state) => state.userTokenLoadingStatus,
);

export const chatSlackTokenStatusSelector = createSelector(chatUserTokenStatusSelector, (state) => state?.slack);

export const wasChatProvidersRequestedSelector = createSelector(
    chatProvidersStatusSelector,
    (chatProvidersStatus) => chatProvidersStatus === 'loading' || chatProvidersStatus === 'loaded',
);

export const areChatProvidersLoadingSelector = createSelector(
    chatProvidersStatusSelector,
    (chatProvidersStatus) => chatProvidersStatus === 'loading',
);

export const teamsSelector = createSelector(chatSelector, (state) => find(state?.providers, ['name', 'teams']));
export const slackSelector = createSelector(chatSelector, (state) => find(state?.providers, ['name', 'slack']));

export const isSlackDefaultAppEnabled = isFeatureEnabled('slack-default-app');
