import first from 'lodash/first';
import get from 'lodash/get';
import loFind from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';
import pick from 'lodash/pick';
import loReject from 'lodash/reject';
import startsWith from 'lodash/startsWith';
import without from 'lodash/without';

import { NavigationItemType } from '@lumapps/navigation/types';
import { getSystemPageDefaultTitle, getSystemPageUrl } from '@lumapps/navigation/utils/systemPageUtils';
import { toCompatibleLanguage } from '@lumapps/translations';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function MainNavService(
    $injector,
    $q,
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    $window,
    BaseService,
    Config,
    Customer,
    InitialSettings,
    Instance,
    MainNavFactory,
    ReduxStore,
    Translation,
    Features,
    Utils,
) {
    'ngInject';

    /* eslint-disable consistent-this */
    const service = BaseService.createListKeyService(MainNavFactory, {
        // eslint-disable-next-line no-use-before-define
        preSave: _preSave,
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The main language.
     *
     * @type {string}
     */
    var _mainLang;

    /**
     * Contains the parent element of the list in which the currently dragged element is being moved.
     *
     * @type {Object}
     */
    let _currentDragList;

    /**
     * Contains the parent that will receive the picked main nav element in the main nav administration.
     *
     * @type {Object}
     */
    // eslint-disable-next-line one-var
    let _currentPickerTarget;

    /**
     * The default route when the routing doesn't give anything for the given content type.
     *
     * @type {string}
     */
    const _defaultContentRoute = 'app.front.content-get';

    /**
     * Contains the elements we want to delete from the main nav in the main nav administration.
     *
     * @type {Array}
     */
    let _deletedElements = [];

    /**
     * Contains the list of editable contents.
     *
     * @type {Array}
     */
    let _editableContentIds = [];

    /**
     * Contains the currently in edition element in the main nav administration.
     *
     * @type {Object}
     */
    let _editedItem;

    /**
     * Indicates that an element is beeing dragged in the main nav administation.
     *
     * @type {boolean}
     */
    let _isItemDragged;

    /**
     * Indicates if we are editing an element in the main nav administration.
     *
     * @type {boolean}
     */
    let _isItemInEdition = false;

    /**
     * Indicates if the currently dragged element is a copy of the source element in the main nav administration.
     *
     * @type {boolean}
     */
    let _isDraggedItemFromCopy = false;

    /**
     * The promise of the loading of the instance.
     *
     * @type {Promise}
     */
    // eslint-disable-next-line angular/deferred
    const _mainNavLoadingDeferred = $q.defer();

    /**
     * Contains the list of modified elements in the main nav administration.
     *
     * @type {Object}
     */
    let _modifiedElements = {};

    /**
     * Contains the list of the ids of the modified elements in the main nav administration.
     *
     * @type {Array}
     */
    let _modifiedIds = [];

    /**
     * Contains the item currently in edition before any modification.
     *
     * @type {Object}
     */
    let _originalItem;

    /**
     * Contains the list of already picked contents in the main nav. Thus, theses content are no pickable anymore in
     * the main nav administration.
     *
     * @type {Array}
     */
    // eslint-disable-next-line one-var
    let _pickedContents;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains the default parameters for the main nav.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /**
     * Contains various status about the service.
     *
     * @type {Object}
     */
    service.is = {
        initialized: false,
        initializing: false,
        routing: false,
    };

    /**
     * Exposes $state.go for `<react-element />`.
     */
    service.goToState = $state.go;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Function that calls Translation service's translate, with v1 compatible language (works when current language is e.g. pt-BR)
     */
    function _navigationTranslate(content) {
        return Translation.translate(content, undefined, _mainLang, false, false, true);
    }

    /**
     * Recursively check if a navigation item or any of its children is active.
     *
     * @param  {string}  idOrSlug The unique id of the current content (or community).
     * @param  {Object}  navItem  The navigation item to check.
     * @param  {boolean} fromId   Indicates if we are checking the id (true) or the slugs (false).
     * @return {boolean} If the navigation item or any of its children active or not.
     */
    function _isActive(idOrSlug, navItem, fromId) {
        const translatedSlug = _navigationTranslate(navItem.slugFull);

        const isActive = fromId ? idOrSlug === navItem.id : idOrSlug === translatedSlug;

        if (isActive || angular.isUndefinedOrEmpty(navItem.children)) {
            return isActive;
        }

        for (let i = 0, len = navItem.children.length; i < len; i++) {
            const child = navItem.children[i];
            if (
                (fromId && child.id === idOrSlug) ||
                (!fromId && _navigationTranslate(navItem.slugFull) === idOrSlug) ||
                _isActive(idOrSlug, child, fromId)
            ) {
                return true;
            }
        }

        return isActive;
    }

    /**
     * Return the right state for the content according to its type.
     *
     * @param  {string} type The content type (page/community/etc...).
     * @return {string} The right state for the content.
     */
    function _getContentRoute(type) {
        if (type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
            return 'app.front.community';
        }

        return _defaultContentRoute;
    }

    /**
     * Open a new window with a specific URL and focus it.
     *
     * @param {string} url         The url to open.
     * @param {Object} [newWindow] The window object previously opened.
     */
    function _openWindow(url, newWindow) {
        if (angular.isUndefinedOrEmpty(url)) {
            return;
        }

        const win = newWindow || $window.open('', '_blank');
        win.location = url;
        win.focus();
    }

    /**
     * Remove empty elements from the main nav at save.
     * This way, we reduce the payload.
     *
     * @param  {Array} elements The elements of the main nav.
     * @return {Array} The main nav without empty elements.
     */
    function _removeEmptyItems(elements) {
        if (!angular.isArray(elements) || angular.isUndefinedOrEmpty(elements)) {
            return elements;
        }

        angular.forEach(elements, (el, index) => {
            // If items is defined but empty.
            if (angular.isUndefinedOrEmpty(el.items)) {
                elements.splice(index, 1);

                return;
            }

            // Lighten the payload by removing the children.
            angular.forEach(el.items, (item) => {
                delete item.children;
            });
        });

        return elements;
    }

    /**
     * Remove an element from the modified element list (only for the given langs).
     *
     * @param {string}       identifier The identifier of the element we want to remove.
     * @param {string|Array} langs      The langs we want to remove from the modified elements.
     */
    function _removeModifiedElement(identifier, langs) {
        langs = langs || [];
        langs = angular.isString(langs) ? [langs] : [];

        if (angular.isUndefinedOrEmpty(langs) || !includes(_modifiedIds, identifier)) {
            return;
        }

        angular.forEach(langs, (lang) => {
            if (angular.isUndefinedOrEmpty(get(_modifiedElements, `[${lang}].children`))) {
                return;
            }

            // Remove element.
            _modifiedElements[lang].children = loReject(_modifiedElements[lang].children, {
                uuid: identifier,
            });

            // Remove id from the list of ids of modified elements.
            _modifiedIds.splice(_modifiedIds.indexOf(identifier), 1);
        });
    }

    /**
     * Remove elements that shouldn't be sent to backend like new items that have been then deleted.
     *
     * @param  {Array} elements The elements that are going to be sent to the backend.
     * @return {Array} The cleaned up elements.
     */
    function _removeUnnecessaryDelete(elements) {
        if (!angular.isArray(elements) || angular.isUndefinedOrEmpty(elements)) {
            return elements;
        }

        return loReject(elements, (el) => {
            return el.$isNew;
        });
    }

    /**
     * Cleanup the payload before the save.
     *
     * @param  {Object} payload The payload to send to the backend.
     * @return {Object} The cleaned up payload.
     */
    function _preSave(payload) {
        payload.items = _removeEmptyItems(angular.copy(payload.items));
        // Re-allocation and copy to avoid splicing the reference, deleted is needed after.
        payload.deleted = _removeUnnecessaryDelete(angular.fastCopy(payload.deleted));

        return payload;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add an item to the list of deleted items.
     *
     * @param {Object} item The item to add to the list of deleted items.
     * @param {string} lang The language of the deleted item.
     */
    function addDeletedElement(item, lang) {
        // Mark item as deleted.
        item.deleted = true;

        // Remove from modified element, in case of.
        _removeModifiedElement(item.uuid, lang);

        // Add to remove list.
        _deletedElements.push({
            $isNew: Boolean(item.$isNew),
            lang,
            uuid: item.uuid,
        });
    }

    /**
     * Add an item to the list of modified items.
     *
     * @param {Object} item The item to add to the list of modified items.
     * @param {string} lang The lang of the modified item.
     */
    function addModifiedElement(item, lang) {
        if (angular.isUndefined(_modifiedElements[lang])) {
            _modifiedElements[lang] = {
                children: [],
            };
        }

        if (item.$isNew && angular.isUndefinedOrEmpty(item.uuid)) {
            item.uuid = generateUUID();
        }

        // Remove the element if it already is in the list (if it has already been updated).
        _removeModifiedElement(item.uuid, lang);

        // Add modified element to the list and add identifier to keep track of it.
        _modifiedElements[lang].children.push(item);
        _modifiedIds.push(item.uuid);
    }

    /**
     * Add the given items to the list of modified items.
     *
     * @param {Array} items The items to add to the list of modified items.
     */
    function addModifiedElements(items) {
        if (!angular.isArray(items)) {
            return;
        }

        angular.forEach(items, (item) => {
            service.addModifiedElement(item, Translation.inputLanguage);
        });
    }

    /**
     * Add a new nav item to the main nav list.
     *
     * @param {Object} item  The item to add.
     * @param {number} index The position to which add the new nav item.
     * @param {string} lang  The lang of the nav item.
     */
    function addNewElementToTarget(item, index, lang) {
        lang = lang || Translation.inputLanguage;

        item.$isNew = true;
        item.sortOrder = index;
        item.hidden = false;
        if (angular.isUndefinedOrEmpty(item.uuid)) {
            item.uuid = generateUUID();
        }

        service.addModifiedElement(item, lang);
        _currentPickerTarget.children.push(item);
    }

    /**
     * Clear the item in edition.
     */
    function clearEditItem() {
        service.setEditedItem();
    }

    /**
     * Copy a nav item to a new position.
     *
     * @param  {number}         index      The index position where the item should be copied to.
     * @param  {Object}         item       The item to copy.
     * @param  {Object}         targetItem The item in which to copy the item.
     * @param  {Function}       [errCb]    The callback to call when the item cannot be copied.
     * @return {boolean|Object} False if there has been an error else the copied item.
     */
    function copyElement(index, item, targetItem, errCb) {
        errCb = errCb || angular.noop;

        // Flag used to avoid nested call.
        if (_isItemDragged || item.deleted) {
            return false;
        }
        _isItemDragged = true;

        // Get all target id and check if item is in it.
        const alreadyInTargetChildren = includes(map(targetItem.children, 'id'), item.id);
        const isOriginRoot = angular.isUndefined(_currentDragList);

        // Check if target has a parentId and get it.
        const targetParentId = targetItem ? targetItem.id : undefined;
        const targetParentUuid = targetItem ? targetItem.uuid : undefined;

        /**
         * If no index is specified, add it to the end of the children of the targetItem OR as the first item if
         * there are no children.
         */
        // eslint-disable-next-line no-nested-ternary
        index = angular.isDefined(index)
            ? index
            : angular.isDefinedAndFilled(targetItem.children)
            ? targetItem.children.length
            : 0;

        const isMovingExistingRootElement =
            angular.isUndefinedOrEmpty([_currentDragList, targetParentId], 'every') &&
            isOriginRoot &&
            !_isDraggedItemFromCopy;
        const isAddingViaDrag =
            angular.isDefinedAndFilled([_currentDragList, targetParentId], 'every') &&
            targetParentId === _currentDragList.id &&
            !_isDraggedItemFromCopy;
        const isReordering =
            (isMovingExistingRootElement || angular.isDefinedAndFilled(item.parentUuid)) &&
            item.parentUuid === targetParentUuid;

        _currentDragList = undefined;

        if (alreadyInTargetChildren && !isReordering) {
            errCb();

            return false;
        }

        item.parentId = targetParentId;
        // Add a flag if the element is new.
        item.$isNew = _isDraggedItemFromCopy;
        item.sortOrder = index;

        if (!(isAddingViaDrag || isMovingExistingRootElement)) {
            $timeout(function delayAddModifiedElements() {
                service.addModifiedElements(service.updateSortOrder(targetItem.children));
            });
        }

        _isDraggedItemFromCopy = false;

        return item;
    }

    /**
     * Find a nav item of the main nav by its content id.
     *
     * @param  {string} contentId                     The id OR uuid of the content we want the nav item of.
     * @param  {Array}  [navItems=<root of main nav>] The elements in which we want to search.
     * @return {Object} The nav item corresponding to the slug in the given items.
     */
    function findNavItemById(contentId, navItems) {
        navItems = navItems || get(service.getCurrent(), 'items', []);
        if (angular.isUndefinedOrEmpty(navItems)) {
            return undefined;
        }

        let matchedNavItem;
        for (let i = 0, len = navItems.length; i < len; i++) {
            if (navItems[i].id === contentId || navItems[i].uuid === contentId) {
                matchedNavItem = navItems[i];
            }

            if (angular.isDefinedAndFilled(matchedNavItem)) {
                break;
            } else if (angular.isDefinedAndFilled(navItems[i].children)) {
                matchedNavItem = service.findNavItemById(contentId, navItems[i].children);
            }
        }

        return matchedNavItem;
    }

    /**
     * Find a nav item of the main nav by its slug.
     *
     * @param  {string} slug                          The slug we want to find.
     * @param  {Array}  [navItems=<root of main nav>] The elements in which we want to search.
     * @return {Object} The nav item corresponding to the slug in the given items.
     */
    function findNavItemBySlug(slug, navItems) {
        navItems = navItems || get(service.getCurrent(), 'items', []);
        if (angular.isUndefinedOrEmpty(navItems)) {
            return undefined;
        }

        let matchedNavItem;
        for (let i = 0, len = navItems.length; i < len; i++) {
            if (angular.isUndefinedOrEmpty(slug) && navItems[i].uid === get(Instance.getInstance(), 'homePage')) {
                matchedNavItem = navItems[i];

                break;
            }

            if (_navigationTranslate(navItems[i].slugFull) === slug) {
                matchedNavItem = navItems[i];

                break;
            }

            for (const l in navItems[i].slugFull) {
                if (navItems[i].slugFull[l] === slug || navItems[i].slugFull[l].endsWith(`/${slug}`)) {
                    matchedNavItem = navItems[i];

                    break;
                }
            }

            if (angular.isDefinedAndFilled(matchedNavItem)) {
                break;
            } else if (angular.isDefinedAndFilled(navItems[i].children)) {
                matchedNavItem = service.findNavItemBySlug(slug, navItems[i].children);
            }
        }

        return matchedNavItem;
    }

    /**
     * WTF [Clément]: I'm not sure why an IFFE is used here, so I won't touch anything.
     *
     * Find all nav item corresponding to a content id.
     *
     * @param  {string} contentId The content identifier.
     * @param  {Array}  children  The children
     * @return {Object} The nav item corresponding to the id in the given items.
     */
    function findNavItemsById(contentId, children) {
        const matchedNavItemList = [];
        (function subFindNavItemsById(_contentId, _children) {
            const navItems = _children || (service.getCurrent() || {}).items;
            angular.forEach(navItems, (navItem) => {
                if (navItem.id === _contentId || navItem.uuid === _contentId) {
                    matchedNavItemList.push(navItem);
                }

                if (angular.isDefinedAndFilled(navItem.children) && angular.isArray(navItem.children)) {
                    subFindNavItemsById(_contentId, navItem.children);
                }
            });
        })(contentId, children);

        return matchedNavItemList;
    }

    /**
     * Gets the list of already picked contents.
     *
     * @return {Array} The list already picked contents.
     */
    function getAlreadyPickedContents() {
        return _pickedContents;
    }

    /**
     * Gets the current picker target.
     *
     * @return {Object} The current picker target.
     */
    function getCurrentPickerTarget() {
        return _currentPickerTarget;
    }

    /**
     * Return the list of items to delete.
     *
     * @return {Array} The list of deleted items.
     */
    function getDeletedElements() {
        return _deletedElements;
    }

    /**
     * Return the list of extra editable contents.
     *
     * @return {Array} The list of editable content identifiers.
     */
    function getEditableContentIds() {
        return _editableContentIds || [];
    }

    /**
     * Return the item currently in edition.
     *
     * @return {Object} The item in edition.
     */
    function getEditedItem() {
        return _editedItem;
    }

    /**
     * Return the absolute link of an external content (a content from another instance of the current customer).
     *
     * @param  {string} state   The state we want to go to.
     * @param  {Object} params  The params of the content.
     * @param  {Object} options The options of the link.
     * @return {string} The absolute URL of the external content.
     *
     */
    function getContentLink(state, params, options) {
        const contentLink = $state.href(state, params, options);

        return decodeURIComponent(contentLink);
    }

    /**
     * Get the list of nav items for a given lang.
     *
     * @param  {string} lang The lang we want items for.
     * @return {Object} The formatted items.
     */
    function getFormattedElementByLang(lang) {
        if (angular.isUndefinedOrEmpty(lang)) {
            return [];
        }

        return loFind(service.getFormattedElements(), {
            lang,
        });
    }

    /**
     * Get the list of nav items and their lang.
     *
     * @return {Array} The formatted items.
     */
    function getFormattedElements() {
        // _modifiedElements is an Object.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        return map(_modifiedElements, (item, key) => {
            return {
                items: item.children,
                lang: key,
            };
        });
    }

    /**
     * Get the nav item content title.
     * Returns the content title if there is a menu title overriding it, else, returns nothing.
     *
     * @param  {Object} navItem The navigation item.
     * @return {string} The navigation item original title.
     */
    function getNavItemContentTitle(navItem) {
        if (angular.isDefinedAndFilled(navItem.menuTitle)) {
            return _navigationTranslate(navItem.title);
        }

        return undefined;
    }

    /**
     * Get the url for a nav item.
     *
     * @param  {Object} navItem          The nav item we want to get the url.
     * @param  {string} [state]          The current item state, only if the item he item is in an inherited navigation.
     * @param  {Object} [parentInstance] The parent object if the item is in an inherited navigation.
     * @return {string} The navigation item url.
     */
    function getNavItemHref(navItem, state, parentInstance) {
        // If the item is a navigation element.
        if (navItem.type === Config.AVAILABLE_CONTENT_TYPES.MENU) {
            if (!Translation.hasTranslations(navItem.link, true)) {
                return undefined;
            }

            return Utils.replaceVariables(_navigationTranslate(navItem.link));
        }
        // If the item is from the inherited navigation.
        if (angular.isDefinedAndFilled(parentInstance)) {
            const params = {
                instance: parentInstance.slug,
                slug: _navigationTranslate(navItem.slugFull),
            };

            return service.getContentLink(state, params, { absolute: true });
        }

        // If the item is from the current instance, use the full slug only on the default route.
        const route = _getContentRoute(navItem.type);
        const slugFull = _navigationTranslate(navItem.slugFull);
        const slug = route === _defaultContentRoute ? slugFull : slugFull.split('/').pop();

        return $state.href(route, {
            identifier: undefined,
            slug,
            view: undefined,
        });
    }

    /**
     * Get the nav item title.
     * Display content title if there is not menu title overriding it.
     *
     * @param  {Object} navItem The nav item we want the title.
     * @return {string} The title.
     */
    function getNavItemTitle(navItem) {
        if (angular.isDefinedAndFilled(navItem.menuTitle)) {
            return angular.isString(navItem.menuTitle) ? navItem.menuTitle : _navigationTranslate(navItem.menuTitle);
        }

        if (navItem.type === NavigationItemType.SYSTEM_PAGE) {
            return (
                _navigationTranslate(navItem.title) ||
                getSystemPageDefaultTitle(_navigationTranslate, navItem.systemPageReference?.type)
            );
        }

        return _navigationTranslate(navItem.title || 'NEW_CONTENT_TITLE_FALLBACK');
    }

    /**
     * Get the original item of the item in edition.
     *
     * @return {Object} The original item.
     */
    function getOriginalItem() {
        return _originalItem;
    }

    /**
     * Go to a state from the main nav.
     *
     * @param {string}  state         The state we want to go to.
     * @param {string}  contentId     The id of the content we want to go to.
     * @param {Object}  instanceId    The instance id of the content we want to go to.
     * @param {string}  slug          The slug of the content we want to go to.
     * @param {string}  [postId]      The id of the post we want to scroll to (if any).
     * @param {string}  contentType   The contentType.
     * @param {boolean} [blank=false] Wheter the page should open in a new page or not.
     * @param {Object}  [newWindow]   If new window, the window object we want our URL to be set.
     *                                This object might be needed to avoid popups blocking problem.
     */
    function goTo(state, contentId, instanceId, slug, postId, contentType, blank, newWindow) {
        const params = {
            identifier: undefined,
            view: undefined,
        };

        let contentLink;

        if (angular.isDefinedAndFilled(postId)) {
            if (postId !== -1) {
                params.view = 'post';
                params.identifier = postId;
            } else {
                params.view = 'posts';
            }
        }

        if (Instance.getCurrentInstanceId() === instanceId) {
            blank = blank || false;
            const parentNavElement = service.findNavItemBySlug($stateParams.slug);
            const childOfCurrentNavElement = loFind(get(parentNavElement, 'children', []), {
                id: contentId,
            });

            /*
             * If the content is NOT in the main nav, or it's in the main nav but it's a child of the current
             * navigation element and is NOT a community, append the current content slug to create an artificial
             * breacrumb.
             */
            if (
                angular.isDefinedAndFilled($stateParams.slug) &&
                (angular.isDefinedAndFilled(childOfCurrentNavElement) ||
                    angular.isUndefinedOrEmpty(service.findNavItemById(contentId))) &&
                (angular.isUndefinedOrEmpty(contentType) || contentType !== Config.AVAILABLE_CONTENT_TYPES.COMMUNITY)
            ) {
                slug = `${$stateParams.slug}/${_navigationTranslate(slug)}`;
            } else {
                slug = _navigationTranslate(slug);
            }

            params.slug = slug;

            if (blank) {
                contentLink = $state.href(state, params, {
                    absolute: true,
                });

                _openWindow(decodeURIComponent(contentLink), newWindow);
            } else {
                $state.go(state, params);
            }
        } else {
            Instance.getInstanceById(instanceId, function onGetInstanceByIdSuccess(response) {
                params.instance = response.slug;
                params.slug = _navigationTranslate(slug);

                const absoluteLink = service.getContentLink(state, params, { absolute: true });

                if (blank) {
                    _openWindow(absoluteLink, newWindow);
                } else {
                    $window.location = absoluteLink;
                }
            });
        }
    }

    /**
     * Check if a nav item has visible children.
     *
     * @param  {Object}  navItem The nav item.
     * @return {boolean} If the nav item has visible children or not.
     */
    function hasVisibileChildren(navItem) {
        if (angular.isUndefinedOrEmpty(get(navItem, 'children')) || navItem.hideChildren) {
            return false;
        }

        const visibleChild = loFind(navItem.children, (child) => {
            return !child.hidden;
        });

        return angular.isDefinedAndFilled(visibleChild);
    }

    /**
     * Whether or not the navItem is the current item.
     *
     * @param  {Object}  navItem The navItem to check.
     * @return {boolean} True if the navItem is active.
     */
    function isActiveItem(navItem) {
        let isActive = false;

        const isCommunity = $state.current.name === 'app.front.community';

        if (angular.isDefinedAndFilled($stateParams.slug) && !isCommunity) {
            isActive = _isActive($stateParams.slug, navItem, false);
        } else {
            const Content = $injector.get('Content');
            const Community = $injector.get('Community');

            const current = isCommunity ? Community.getCurrent() : Content.getCurrent();
            const currentId = get(current, 'uid', get(current, 'id'));

            isActive = _isActive(currentId, navItem, true);
        }

        return isActive;
    }

    /**
     * Indicates if an item is currently beeing dragged.
     *
     * @return {boolean} If there is an item beeing dragged.
     */
    function isItemDragged() {
        return _isItemDragged;
    }

    /**
     * Get the edit status.
     *
     * @return {boolean} If we are editing or not.
     */
    function isItemInEdition() {
        return _isItemInEdition;
    }

    /**
     * After the save, remove the deleted items, reset everything and re-initialize the service.
     *
     * @param {Array} items The nav items to clean.
     */
    function onSaveSuccess(items) {
        if (angular.isDefinedAndFilled(items) && angular.isArray(items)) {
            service.removeItems(items, map(service.getDeletedElements(), 'uuid'));
        }

        service.resetElements();
        service.init();
    }

    /**
     * Follow the link of the nav item.
     *
     * @param {Object}  navItem The nav item we want to follow.
     * @param {boolean} newTab  Indicates if we want to open it in a new tab/window.
     */
    function openNavItemLink(navItem, newTab) {
        // If the item is a system page element, go to its url. Since it's v2 only pages, they are not in states.js, replace url directly
        if (navItem.type === NavigationItemType.SYSTEM_PAGE) {
            window.location.href = getSystemPageUrl(navItem.systemPageReference?.type);
        } else if (navItem.type === Config.AVAILABLE_CONTENT_TYPES.MENU) {
            if (!Translation.hasTranslations(navItem.link, true)) {
                return;
            }

            const link = Utils.replaceVariables(_navigationTranslate(navItem.link));

            if (angular.isUndefined(navItem.newTab) || navItem.newTab || newTab) {
                _openWindow(_navigationTranslate(link));
            } else {
                $window.location.href = link;
            }
        } else if (Translation.hasTranslations(navItem.link, true)) {
            // Check if content has an external link.
            _openWindow(_navigationTranslate(navItem.link));
        } else {
            if (service.is.routing) {
                return;
            }

            service.is.routing = true;

            // Use the full slug only on the default route.
            const route = _getContentRoute(navItem.type);
            const slugFull = _navigationTranslate(navItem.slugFull);
            let slug = route === _defaultContentRoute ? slugFull : slugFull.split('/').pop();
            slug = startsWith(slug, '/') ? slug.substr(1) : slug;

            const routeParams = {
                identifier: undefined,
                slug,
                view: undefined,
            };

            if ((angular.isUndefined(navItem.newTab) || !navItem.newTab) && !newTab) {
                $state.go(route, routeParams).finally(function onRoutingTerminated() {
                    service.is.routing = false;
                });
            } else {
                _openWindow($state.href(route, routeParams));
                service.is.routing = false;
            }
        }
    }

    /**
     * Remove the items with the given ids from the given list of items.
     *
     * @param {Array} from The list of items we want to clean.
     * @param {Array} ids  The ids of the items to delete from the list.
     */
    function removeItems(from, ids) {
        if (!angular.isArray(from) || !angular.isArray(ids)) {
            return;
        }

        for (let idx = from.length - 1; idx >= 0; idx--) {
            const item = from[idx];

            if (includes(ids, item.uuid)) {
                ids = without(ids, item.uuid);
                from.splice(idx, 1);

                if (angular.isUndefinedOrEmpty(ids)) {
                    break;
                }
            } else if (angular.isDefinedAndFilled(item.children)) {
                service.removeItems(item.children, ids);
            }
        }
    }

    /**
     * Reset removed/modified items.
     */
    function resetElements() {
        _modifiedElements = {};
        _modifiedIds = [];
        _deletedElements = [];
        _editableContentIds = [];
    }

    /**
     * Restore an item that has previously been deleted.
     *
     * @param  {Object}  item The item to restore.
     * @return {boolean} If the item has been restored or not.
     */
    function restoreElement(item) {
        for (let idx = 0, len = _deletedElements.length; idx < len; ++idx) {
            if (_deletedElements[idx].uuid === item.uuid) {
                // Un-mark as deleted.
                item.deleted = false;

                _deletedElements.splice(idx, 1);

                return true;
            }
        }

        return false;
    }

    /**
     * Save/update a nav item in the main nav.
     *
     * @param {string} lang The language of the updated nav item.
     */
    function saveItemInEdition(lang) {
        // Get edited content.
        const item = _editedItem;
        const originalItem = _originalItem;

        // Remove children to avoid override.
        delete item.children;

        item.$modified = true;

        // Extend on origin item.
        angular.extend(originalItem, item);

        // Add to item list to save.
        service.addModifiedElement(originalItem, lang);

        service.clearEditItem();
    }

    /**
     * Sets the list of already picked contents.
     *
     * @param {Array} contents The already picked contents.
     */
    function setAlreadyPickedContents(contents) {
        _pickedContents = contents;
    }

    /**
     * Sets the current picker target.
     *
     * @param {Object} target The target.
     */
    function setCurrentPickerTarget(target) {
        _currentPickerTarget = target;
    }

    /**
     * Sets the drag origin.
     *
     * @param {Object}  from   The origin of the drag.
     * @param {boolean} isCopy Indicates if it's a copy of the origin.
     */
    function setDragOrigin(from, isCopy) {
        _isItemDragged = false;
        _isDraggedItemFromCopy = Boolean(isCopy);

        if (angular.isUndefinedOrEmpty(get(from, 'parentId'))) {
            _currentDragList = undefined;

            return;
        }

        _currentDragList = service.findNavItemById(from.parentId);
    }

    /**
     * Set extra editable contents.
     * E.g: the directive is nested, and you want all content with id: 123 to be editable, you can set it here!
     *
     * @param {Array} ids The identifiers of the content we want editable.
     */
    function setEditableContentIds(ids) {
        ids = angular.isDefined(ids) && !angular.isArray(ids) ? [ids] : ids;
        _editableContentIds = ids;
    }

    /**
     * Set the item for edition.
     * Note: set item to undefined to unset edition.
     *
     * @param {Object} [item] The item to set in edition.
     */
    function setEditedItem(item) {
        _editedItem = item;
        _isItemInEdition = angular.isDefined(item);
    }

    /**
     * Set the drag status.
     *
     * @param {boolean} isDragged If an item is beeing dragged or not.
     */
    function setItemIsDragged(isDragged) {
        _isItemDragged = isDragged;
    }

    /**
     * Set the original item of the item in edition.
     *
     * @param {Object} item The item to set as original.
     */
    function setOriginalItem(item) {
        _originalItem = item;
    }

    /**
     * Update the sort order property of each item according to its current position.
     *
     * @param  {Array} items The items to update.
     * @return {Array} The updated items.
     */
    function updateSortOrder(items) {
        angular.forEach(items, (item, idx) => {
            item.sortOrder = idx;
        });

        return items;
    }

    /**
     * "Hybrid" items are "menu" items that have been created on the new app
     * that we are trying to display on the legacy app.
     * As the legacy api created actual "content" for these elements but the new api doesn't,
     * they don't have ids anymore.
     * To temporary fix this until the feature flag is removed (soon), the backend generates uid
     * based on their uuids.
     * The only way to know which is which is to check their id lengths as the "hybrid" ids are longer.
     */
    function itemIsHybrid(item) {
        return item && item.id.length > 25;
    }

    /////////////////////////////

    service.addDeletedElement = addDeletedElement;
    service.addModifiedElement = addModifiedElement;
    service.addModifiedElements = addModifiedElements;
    service.addNewElementToTarget = addNewElementToTarget;
    service.clearEditItem = clearEditItem;
    service.copyElement = copyElement;
    service.findNavItemById = findNavItemById;
    service.findNavItemBySlug = findNavItemBySlug;
    service.findNavItemsById = findNavItemsById;
    service.getAlreadyPickedContents = getAlreadyPickedContents;
    service.getContentLink = getContentLink;
    service.getCurrentPickerTarget = getCurrentPickerTarget;
    service.getDeletedElements = getDeletedElements;
    service.getEditableContentIds = getEditableContentIds;
    service.getEditedItem = getEditedItem;
    service.getFormattedElementByLang = getFormattedElementByLang;
    service.getFormattedElements = getFormattedElements;
    service.getNavItemContentTitle = getNavItemContentTitle;
    service.getNavItemHref = getNavItemHref;
    service.getNavItemTitle = getNavItemTitle;
    service.getOriginalItem = getOriginalItem;
    service.goTo = goTo;
    service.hasVisibileChildren = hasVisibileChildren;
    service.isActiveItem = isActiveItem;
    service.isItemDragged = isItemDragged;
    service.isItemInEdition = isItemInEdition;
    service.onSaveSuccess = onSaveSuccess;
    service.openNavItemLink = openNavItemLink;
    service.removeItems = removeItems;
    service.resetElements = resetElements;
    service.restoreElement = restoreElement;
    service.saveItemInEdition = saveItemInEdition;
    service.setAlreadyPickedContents = setAlreadyPickedContents;
    service.setCurrentPickerTarget = setCurrentPickerTarget;
    service.setDragOrigin = setDragOrigin;
    service.setEditableContentIds = setEditableContentIds;
    service.setEditedItem = setEditedItem;
    service.setItemIsDragged = setItemIsDragged;
    service.setOriginalItem = setOriginalItem;
    service.updateSortOrder = updateSortOrder;
    service.itemIsHybrid = itemIsHybrid;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    $timeout(function delayBindMouseover() {
        /**
         * When the mouse is over the main nav, remove the loader from the content.
         */
        angular.element('.main-nav').bind('mouseover', () => {
            angular.element('.main-nav--is-content-loading').removeClass('main-nav--is-content-loading');
        });
    });

    /////////////////////////////
    //                         //
    //          Redux          //
    //                         //
    /////////////////////////////

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object} The data. Aka. store shape.
     */
    function mapStateToRedux() {
        const currentNav = pick(service.getCurrent(), ['items', 'lang']);

        return {
            data: currentNav,
            id: 'current',
            isLoading: !service.is.initialized,
        };
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'mainNav';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = 'mainNav/update';

    // Expose the appropriate function.
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////
    /**
     * Initialize the main nav redux store.
     */
    service.initReduxStore = function initReduxStore() {
        // Enable Redux sync.
        ReduxStore.subscribe(service);
    };

    /**
     * Initialize the service.
     *
     * @return {Promise} The promise of the initialization.
     */
    service.init = function init() {
        const currentInstance = Instance.getInstance();

        service.defaultParams = {
            customer: Customer.getCustomerId(),
            instance: currentInstance.uid,
        };

        const Content = $injector.get('Content');

        /*
         * Use fallback language as the alternative language if default language is undefined.
         * Otherwise, use the first language of the site.
         * The backend checks before if some languages are defined in the user's profile.
         */
        _mainLang = toCompatibleLanguage(
            angular.isDefined(Content.getAction()) && Content.getAction() !== 'get'
                ? Translation.inputLanguage
                : Translation.getLang('current'),
        );
        let alternativeLang = toCompatibleLanguage(currentInstance.defaultLang || Translation.getLang('fallback'));

        if (angular.isDefinedAndFilled(get(currentInstance, 'langs'))) {
            if (!includes(currentInstance.langs, _mainLang)) {
                _mainLang = currentInstance.defaultLang || first(currentInstance.langs);
            }

            if (!includes(currentInstance.langs, alternativeLang)) {
                alternativeLang = currentInstance.defaultLang || first(currentInstance.langs);
            }
        }

        // Don't lock initialization of the service with a previous one, let them overwrite each other.
        service.is.initializing = true;

        service.get(
            {
                alternativeLang,
                lang: _mainLang,
            },
            function onMainNavGetSuccess() {
                // Generate content breadcrumb.
                // We need to navigate through main nav to generate breadcrumb.
                Content.createBreadcrumb();

                _mainNavLoadingDeferred.resolve();
            },
            _mainNavLoadingDeferred.reject,
        );

        _mainNavLoadingDeferred.promise.finally(function onMainNavLoadingFinally() {
            service.is.initialized = true;
            service.is.initializing = false;

            $rootScope.$broadcast('main-nav-loaded');
        });

        return _mainNavLoadingDeferred.promise;
    };

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('MainNav', MainNavService);

/////////////////////////////

export { MainNavService };
