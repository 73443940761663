import React from 'react';

import { ListWidgetStyleSetting } from '@lumapps/widget-settings/components/ListWidgetStyleSetting';
import { AVAILABLE_OPTIONS, StyleSettingType } from '@lumapps/widget-settings/types';

import { SCOPE } from '../../constants';
import {
    WIDGET_PERSONAL_FEED_PREVIEW_LIST_VIEW_MODES,
    WidgetPersonalFeedPreviewListViewModeType,
    WidgetPersonalFeedPreviewListViewModeVariantType,
} from '../../types';

export interface WidgetPersonalFeedPreviewStylesProps {
    className?: string;
    currentStylesSetting?: StyleSettingType<
        WidgetPersonalFeedPreviewListViewModeType,
        WidgetPersonalFeedPreviewListViewModeVariantType
    >;
    onChange: (
        properties: StyleSettingType<
            WidgetPersonalFeedPreviewListViewModeType,
            WidgetPersonalFeedPreviewListViewModeVariantType
        >,
    ) => void;
}

export const WidgetPersonalFeedPreviewStyles: React.FC<WidgetPersonalFeedPreviewStylesProps> = ({
    className,
    currentStylesSetting,
    onChange,
}) => {
    return currentStylesSetting ? (
        <ListWidgetStyleSetting<
            WidgetPersonalFeedPreviewListViewModeType,
            WidgetPersonalFeedPreviewListViewModeVariantType
        >
            onChange={onChange}
            currentStylesSetting={currentStylesSetting}
            className={className}
            dataScope={SCOPE}
            viewModesArray={WIDGET_PERSONAL_FEED_PREVIEW_LIST_VIEW_MODES}
            excludeAvailableOptions={[AVAILABLE_OPTIONS.SEPARATOR]}
        />
    ) : null;
};
