import React from 'react';
import { MemoryRouter, Context } from '@lumapps/router';

import SideNavigation from '@apps/back-office/src/components/SideNavigation';

const BackOfficeNavigation = ({ current, params }) => {
    return (
        <Context.Provider
            value={{
                currentView: current,
                params,
            }}
        >
            <MemoryRouter>
                <SideNavigation />
            </MemoryRouter>
        </Context.Provider>
    );
};

export { BackOfficeNavigation };
