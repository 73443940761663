import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { COMMUNITIES } from '@lumapps/communities/keys';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Switch, FlexBox, Orientation, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';
import { SettingSection } from '@lumapps/widget-settings/components/SettingSection';

import { DATA_SCOPE, INFORMATION_DISPLAYED } from '../../../constants';
import { WidgetArticleListPropertiesType } from '../../../types';

export interface InformationDisplayedSectionProps {
    currentInformationDisplayedSetting?: string[];
    onChange: (properties: Pick<WidgetArticleListPropertiesType, 'fields'>) => void;
}

const CLASSNAME = 'informations-displayed-section';

export const InformationDisplayedSection: React.FC<InformationDisplayedSectionProps> = ({
    currentInformationDisplayedSetting,
    onChange,
}) => {
    const { translateKey } = useTranslate();
    const { block } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(`${DATA_SCOPE}-information-displayed`);
    const [isAuthorChecked, setIsAuthorChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.AUTHOR)
            : true,
    );
    const [isPublicationDateChecked, setIsPublicationDateChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.PUBLICATION_DATE)
            : true,
    );
    const [isCommunityNameChecked, setIsCommunityNameChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.COMMUNITY_NAME)
            : true,
    );
    const [isTitleChecked, setIsTitleChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.TITLE)
            : true,
    );
    const [isExcerptChecked, setIsExcerptChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.EXCERPT)
            : true,
    );
    const [isSocialChecked, setIsSocialChecked] = React.useState<boolean>(
        currentInformationDisplayedSetting
            ? currentInformationDisplayedSetting?.includes(INFORMATION_DISPLAYED.SOCIAL)
            : true,
    );

    React.useEffect(() => {
        const fields = [];

        if (isAuthorChecked) {
            fields.push(INFORMATION_DISPLAYED.AUTHOR);
        }
        if (isPublicationDateChecked) {
            fields.push(INFORMATION_DISPLAYED.PUBLICATION_DATE);
        }
        if (isCommunityNameChecked) {
            fields.push(INFORMATION_DISPLAYED.COMMUNITY_NAME);
        }
        if (isTitleChecked) {
            fields.push(INFORMATION_DISPLAYED.TITLE);
        }
        if (isExcerptChecked) {
            fields.push(INFORMATION_DISPLAYED.EXCERPT);
        }
        if (isSocialChecked) {
            fields.push(INFORMATION_DISPLAYED.SOCIAL);
        }
        onChange({
            fields,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isAuthorChecked,
        isPublicationDateChecked,
        isCommunityNameChecked,
        isTitleChecked,
        isExcerptChecked,
        isSocialChecked,
    ]);

    return (
        <SettingSection
            title={translateKey(WIDGET_BASE.SETTINGS_INFORMATION_DISPLAYED_TITLE)}
            className={block([margin('vertical', 'huge')])}
        >
            <FlexBox gap={Size.huge} orientation={Orientation.vertical}>
                <Switch
                    isChecked={isAuthorChecked}
                    onChange={setIsAuthorChecked}
                    {...get({ element: 'switch', action: 'toggle-author' })}
                >
                    {translateKey(GLOBAL.AUTHOR)}
                </Switch>
                <Switch
                    isChecked={isPublicationDateChecked}
                    onChange={setIsPublicationDateChecked}
                    {...get({ element: 'switch', action: 'toggle-publication-date' })}
                >
                    {translateKey(GLOBAL.PUBLICATION_DATE)}
                </Switch>
                <Switch
                    isChecked={isCommunityNameChecked}
                    onChange={setIsCommunityNameChecked}
                    {...get({ element: 'switch', action: 'toggle-community-name' })}
                >
                    {translateKey(COMMUNITIES.COMMUNITY_NAME)}
                </Switch>
                <Switch
                    isChecked={isTitleChecked}
                    onChange={setIsTitleChecked}
                    {...get({ element: 'switch', action: 'toggle-title' })}
                >
                    {translateKey(GLOBAL.TITLE)}
                </Switch>
                <Switch
                    isChecked={isExcerptChecked}
                    onChange={setIsExcerptChecked}
                    {...get({ element: 'switch', action: 'toggle-excerpt' })}
                >
                    {translateKey(GLOBAL.EXCERPT)}
                </Switch>
                <Switch
                    isChecked={isSocialChecked}
                    onChange={setIsSocialChecked}
                    {...get({ element: 'switch', action: 'toggle-social' })}
                >
                    {translateKey(GLOBAL.SOCIAL)}
                </Switch>
            </FlexBox>
        </SettingSection>
    );
};
