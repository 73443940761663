import React from 'react';

import { SkeletonRectangle, SkeletonRectangleVariant } from '@lumapps/lumx/react';

export type LoadingSearchboxProps = {
    style?: Record<string, any>;
};

const LoadingSearchbox: React.FC<LoadingSearchboxProps> = ({ style = {} }) => {
    return (
        <SkeletonRectangle
            variant={SkeletonRectangleVariant.squared}
            style={{
                width: '256px',
                borderRadius: '2px',
                height: '36px',
                marginRight: '0px',
                marginTop: '0px',
                ...style,
            }}
        />
    );
};

export { LoadingSearchbox };
