import { shape, bool, string } from 'prop-types';

import { FroalaSettings } from 'common/froala/modules/froala_constant';
import { translate as t } from 'components/translations';

/* eslint-disable no-magic-numbers */

/**
 * The terms and conditions fields.
 */
const TERMS_AND_CONDITIONS_FIELDS = {
    DESCRIPTION: 'description',
    REDIRECT_URL: 'redirectUrl',
};

/**
 * The terms and conditions available status.
 */
const TERMS_AND_CONDITIONS_STATUS = {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED',
};

/**
 * The terms and conditions fields.
 */
const EMPTY_TERMS_AND_CONDITIONS = {
    [TERMS_AND_CONDITIONS_FIELDS.DESCRIPTION]: {},
    [TERMS_AND_CONDITIONS_FIELDS.REDIRECT_URL]: {},
    status: TERMS_AND_CONDITIONS_STATUS.DISABLED,
};

/**
 * The terms and conditions type.
 */
const TERMS_AND_CONDITIONS_TYPE = shape({
    /** The customer id. */
    customedId: string.isRequired,
    /** The description. */
    description: shape(),
    /** The redirect URL. */
    redirectUrl: string,
    /** The terms and conditions status. */
    status: bool.isRequired,
});

/** The toolbar of the wysiwyg. */
const toolBarConfig = [
    'fullscreen',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'fontSize',
    'color',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    'insertHR',
    '|',
    'nativeInsertLink',
    'insertTable',
    '|',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
];

/**
 * Froala configuration.
 */
const FROALA_CONFIG = {
    charCounterCount: false,
    embedlyKey: FroalaSettings.key,
    heightMax: 300,
    heightMin: 250,
    htmlExecuteScripts: false,
    linkEditButtons: FroalaSettings.linkEditButtons,
    linkInsertButtons: FroalaSettings.linkInsertButtons,
    multiLine: true,
    pastePlain: true,
    placeholderText: t('ADMIN.TERMS_AND_CONDITIONS.DESCRIPTION.PLACEHOLDER'),
    toolbarButtons: toolBarConfig,
    toolbarButtonsMD: toolBarConfig,
    toolbarButtonsSM: toolBarConfig,
    toolbarButtonsXS: toolBarConfig,
    toolbarSticky: false,
};

/////////////////////////////

export {
    EMPTY_TERMS_AND_CONDITIONS,
    FROALA_CONFIG,
    TERMS_AND_CONDITIONS_FIELDS,
    TERMS_AND_CONDITIONS_STATUS,
    TERMS_AND_CONDITIONS_TYPE,
};
