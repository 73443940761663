import { fetchGroupTask } from '../community_tasks';

/**
 * Actions types.
 */
const FETCH_GROUP_LOADING = 'community/FETCH_GROUP_LOADING';
const FETCH_GROUP_SUCCESS = 'community/FETCH_GROUP_SUCCESS';
const FETCH_GROUP_ERROR = 'community/FETCH_GROUP_ERROR';

/**
 * Dispatcher methods.
 * @param  {string} workspaceId Workspace group Id
 * @return {void}   Returns.
 */

const dispatchFetchGroup = (workspaceId) => (dispatch) => {
    dispatch({
        type: FETCH_GROUP_LOADING,
    });

    return fetchGroupTask({ workspaceId })
        .then(({ payload } = {}) => {
            dispatch({
                payload,
                type: FETCH_GROUP_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                payload: exception,
                type: FETCH_GROUP_ERROR,
            });
        });
};

/**
 * Exports.
 */

export { dispatchFetchGroup, FETCH_GROUP_ERROR, FETCH_GROUP_LOADING, FETCH_GROUP_SUCCESS };
