import includes from 'lodash/includes';

/**
 * Check if we should display a field or not
 * @param field the field key like primaryEmail
 * @param isHideEmailAddressesEnabled is the FF hide_email_addresses enabled?
 * @returns if we should display the field
 */
export const shouldDisplayField = (field: string, isHideEmailAddressesEnabled: boolean) => {
    return !(includes(['primaryEmail', 'email'], field) && isHideEmailAddressesEnabled);
};
