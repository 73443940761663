/* istanbul ignore file */
import isEmpty from 'lodash/isEmpty';

import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { error as notifyError, success as notifySuccess } from '@lumapps/notifications';
import { batch } from '@lumapps/redux/react';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';

import * as api from '../api';
import { NOTIFICATIONS_CENTER } from '../keys';
import { NotificationStatus } from '../types';
import { getMostRecentNotification, getNotificationsByReadStatus } from '../utils/notificationGroupUtils';
import { lastNotificationSelector, notificationGroupsSelector, unreadNotificationsCountSelector } from './selectors';
import { actions } from './slice';

const listAllNotifications = (cursor?: string) => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
    const currentState = getState();
    const currentLanguage = currentLanguageSelector(currentState);
    const currentLastNotification = lastNotificationSelector(currentState);

    /** When switching between notification filters for instance, or any other action for which we want
     * to display a full loading state with no existing notifications, we need to clear all existing
     * notifications. The only case where we should absolutely not clear the notifications is when
     * fetching additional pages of data -- that is when we call listNotifications with a valid cursor.
     */
    if (!cursor) {
        dispatch(actions.clearNotifications());
    }
    dispatch(actions.setIsLoadingNotifications(true));

    try {
        const { data } = await api.list({
            cursor,
            lang: currentLanguage,
        });

        // Are we in an `load more` context or not
        if (cursor) {
            dispatch(
                actions.addNotifications({
                    notifications: data.items,
                    more: data.more,
                    cursor: data.cursor,
                    unreadNotificationsCount: Number(data.unreadNotificationsCount),
                }),
            );
        } else {
            batch(() => {
                dispatch(
                    actions.setNotifications({
                        notifications: data.items,
                        more: data.more,
                        cursor: data.cursor,
                        unreadNotificationsCount: Number(data.unreadNotificationsCount),
                    }),
                );

                /**
                 * When fetching all notifications, we set the most recent notification returned
                 * as being the user's last notification.
                 * This will then be used when checking if an update is necessary.
                 */
                if (data.items?.length > 0) {
                    const { notification: lastNotification } = getMostRecentNotification(data.items);

                    /**
                     * Only set it if different than the one we already know,
                     * to avoid unnecessary dispatches.
                     */
                    if (lastNotification.uid !== currentLastNotification?.uid) {
                        dispatch(actions.setLastNotification({ uid: lastNotification.uid }));
                    }
                }
            });
        }
    } catch (error) {
        dispatch(actions.setNotificationsError(true));
    } finally {
        dispatch(actions.setIsLoadingNotifications(false));
    }
};

const listUnreadNotifications = (cursor?: string) => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
    const currentState = getState();
    const currentLanguage = currentLanguageSelector(currentState);
    const currentNotifications = notificationGroupsSelector(currentState);
    const currentUnreadNotifications = getNotificationsByReadStatus(currentNotifications, false);
    const totalUnreadNotificationsCount = unreadNotificationsCountSelector(currentState);
    const hasMoreUnread = currentUnreadNotifications.length < totalUnreadNotificationsCount;

    /**
     * When switching to unread notifications, we first set the current notifications
     * to those that may have already been fetched.
     */
    if (!cursor) {
        dispatch(
            actions.setNotifications({
                notifications: currentUnreadNotifications,
                more: hasMoreUnread,
                unreadNotificationsCount: totalUnreadNotificationsCount,
            }),
        );
    }

    // If there are no more notifications to fetch, stop there.
    if (hasMoreUnread) {
        dispatch(actions.setIsLoadingNotifications(true));

        try {
            const { data } = await api.list({
                cursor,
                lang: currentLanguage,
                isRead: false,
            });

            if (cursor) {
                dispatch(
                    actions.addNotifications({
                        notifications: data.items,
                        more: data.more,
                        cursor: data.cursor,
                        unreadNotificationsCount: Number(data.unreadNotificationsCount),
                    }),
                );
            } else {
                dispatch(
                    actions.setNotifications({
                        notifications: data.items,
                        more: data.more,
                        cursor: data.cursor,
                        unreadNotificationsCount: Number(data.unreadNotificationsCount),
                    }),
                );
            }
        } catch (error) {
            dispatch(actions.setNotificationsError(true));
        } finally {
            dispatch(actions.setIsLoadingNotifications(false));
        }
    }
};

const setAsRead = (notificationId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.setNotificationRead(notificationId));
        await api.setAsRead(notificationId);
    } catch (error) {
        dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        dispatch(actions.setNotificationUnread(notificationId));
    }
};

const setAsUnread = (notificationId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.setNotificationUnread(notificationId));
        await api.setAsUnread(notificationId);
    } catch (error) {
        dispatch(notifyError({ translate: GLOBAL.GENERIC_ERROR }));
        dispatch(actions.setNotificationRead(notificationId));
    }
};

const setAllAsRead = () => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.setAllNotificationRead());

        const success = await api.setAllAsRead();

        if (!isEmpty(success)) {
            dispatch(notifySuccess({ translate: NOTIFICATIONS_CENTER.MARK_ALL_AS_READ_SUCCESS }));
        }
    } catch (error) {
        dispatch(
            notifyError({
                translate: GLOBAL.ACTION_NOT_COMPLETED_TRY_AGAIN,
                actionLabel: GLOBAL.TRY_AGAIN,
                onActionClick: () => setAllAsRead()(dispatch),
            }),
        );
    }
};

const deleteNotification = (notificationId: string, isNotificationRead: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.deleteNotification({ uid: notificationId, isRead: isNotificationRead }));

        await api.deleteNotification(notificationId);
    } catch (error) {
        dispatch(notifyError({ translate: GLOBAL.ACTION_NOT_COMPLETED_TRY_AGAIN }));
    }
};

const deleteAll = () => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.clearNotifications());

        await api.deleteAll();

        dispatch(notifySuccess({ translate: NOTIFICATIONS_CENTER.DELETE_ALL_SUCCESS }));
        dispatch(actions.setLastNotification({}));
    } catch (error) {
        dispatch(
            notifyError({
                translate: GLOBAL.ACTION_NOT_COMPLETED_TRY_AGAIN,
                actionLabel: GLOBAL.TRY_AGAIN,
                onActionClick: () => deleteAll()(dispatch),
            }),
        );
    }
};

/**
 * Call an enpoint to get the the user's last notification id.
 * If it's different than the one we already have, refetch notifications with given status
 * and update the last notification.
 */
const refreshNotifications =
    (notificationStatus?: NotificationStatus, forceRefresh?: boolean) =>
    async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
        try {
            const currentState = getState();
            const currentLastNotification = lastNotificationSelector(currentState);

            let needRefresh = true;
            // Check last notification if last notification is not empty.
            if (!forceRefresh && !isEmpty(currentLastNotification)) {
                const { data: lastNotification } = await api.checkLast();
                needRefresh = currentLastNotification.uid !== lastNotification?.uid;
                if (needRefresh) {
                    // Update last notification in state
                    dispatch(actions.setLastNotification(lastNotification));
                }
            }

            /** If there are new notifications, refetch list */
            if (needRefresh) {
                // Only refresh the given status
                dispatch(
                    notificationStatus === NotificationStatus.unread
                        ? listUnreadNotifications()
                        : listAllNotifications(),
                );
            }
        } catch (error) {
            // This action should be transparent and shouldn't show an error to the user if it breaks.
        }
    };

/** Get the count of unread notification for current user */
const countUnread = () => async (dispatch: Dispatch) => {
    try {
        const unreadNotificationsCount = await api.countUnread();
        dispatch(actions.setNotificationUnreadCount(unreadNotificationsCount));
    } catch (ignored) {
        // Error ignored, unread count kept to last redux state.
    }
};

export {
    listAllNotifications,
    listUnreadNotifications,
    setAsRead,
    setAsUnread,
    setAllAsRead,
    deleteNotification,
    deleteAll,
    refreshNotifications,
    countUnread,
};
