import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function () {
    'use strict';

    angular
        .module('Services')
        .service('Question', QuestionService);

    // Transpiled TypeScript
    var __extends = (this && this.__extends) || function (d, b)
        {
            for (var p in b)
            {
                if (b.hasOwnProperty(p))
                {
                    d[p] = b[p];
                }
            }
            function T__() {
                this.constructor = d;
            }

            d.prototype = b === null ? Object.create(b) : (T__.prototype = b.prototype, new T__());
        };

    // Enum
    var QType;
    (function (QType)
    {
        QType[QType['open'] = 0] = 'open';
        QType[QType['choices'] = 1] = 'choices';
    })(QType || (QType = {}));


    function QuestionService(Utils)
    {
        'ngInject';

        var service = this;

        var Question = (function ()
        {
            function Question(title)
            {
                if (title === void 0)
                {
                    title = {};
                }

                this.type = QType.choices;
                this.title = title;
            }

            Question.prototype.getType = function ()
            {
                return QType[this.type];
            };

            return Question;
        }());

        var ChoicesQuestion = (function (_super)
        {
            __extends(ChoicesQuestion, _super);

            function ChoicesQuestion(title, multi)
            {
                _super.call(this, title);

                this.choices = [];
                this.type = QType.choices;
                this.isMultiple = multi;
            }

            ChoicesQuestion.prototype.setChoice = function (choice)
            {
                if (!choice.identifier && !this.getChoice(choice.identifier))
                {
                    choice.identifier = generateUUID();
                }

                this.choices.push(choice);
            };

            ChoicesQuestion.prototype.getChoice = function (identifier)
            {
                return _.find(this.choices, function (choice) {
                    return identifier === choice.identifier;
                });
            };

            ChoicesQuestion.prototype.removeChoice = function (identifier)
            {
                if (identifier)
                {
                    var idx = _.findIndex(this.choices, function (choice) {
                        return choice.identifier === identifier;
                    });

                    if (idx > -1)
                    {
                        this.choices.splice(idx, 1);
                        return true;
                    }
                }

                return false;
            };

            return ChoicesQuestion;
        }(Question));

        var Answers = (function ()
        {
            function Answers(type, answers)
            {
                if (answers === void 0) { answers = []; }

                this.answers = [];
                this.count = 0;
                this.answers = answers;
                this.count = this.answers.length;
                this.type = type;
            }

            Answers.prototype.getAnswerByUserId = function (userIdentifier)
            {
                return _.find(this.answers, function (answer) {
                    return userIdentifier && userIdentifier === answer.userId;
                });
            };

            Answers.prototype.add = function (answer)
            {
                if (answer && answer.value)
                {
                    this.answers.push(answer);
                    this.count++;
                }
            };

            return Answers;
        }());

        // FIXME module/export classes above to avoid messy code

        service.Answers = Answers;
        service.typeEnum = QType;
        service.Question = Question;
        service.ChoicesQuestion = ChoicesQuestion;
        service.buildQuestion = buildQuestion;

        /**
         * Build a question, can also be use to downcast a question into a choicesQuestion and reverse
         */
        function buildQuestion(settings)
        {
            var C = (settings.type === service.typeEnum.open) ? service.Question : service.ChoicesQuestion;
            var newQuestionSettings = new C();

            angular.forEach(settings, function (value, key)
            {
                if (newQuestionSettings.hasOwnProperty(key))
                {
                    newQuestionSettings[key] = value;
                }
            });

            return newQuestionSettings;
        }


    }
})();
