(function () {
    angular
        .module('Controllers')
        .controller('WidgetDirectoryEntryListSettingsController', WidgetDirectoryEntryListSettingsController);

    function WidgetDirectoryEntryListSettingsController(
        $rootScope,
        $scope,
        $timeout,
        ConfigTheme,
        Content,
        ContentTemplate,
        CustomContentType,
        Directory,
        DirectoryEntryPicker,
        InitialSettings,
        Instance,
        Metadata,
        Translation,
        Utils,
        Widget,
    ) {
        'ngInject';

        const widgetDirectoryEntryListSettings = this;
        let flatDirectoryList = []; // for selected instances

        widgetDirectoryEntryListSettings.displayDirectorySelector = displayDirectorySelector;
        widgetDirectoryEntryListSettings.isEmpty = angular.isUndefinedOrEmpty;
        widgetDirectoryEntryListSettings.keys = Object.keys;
        widgetDirectoryEntryListSettings.openDirectoryEntryPicker = openDirectoryEntryPicker;
        widgetDirectoryEntryListSettings.updateDirectoryList = updateDirectoryList;
        widgetDirectoryEntryListSettings.updateTags = updateTags;
        widgetDirectoryEntryListSettings.updateWidget = updateWidget;
        widgetDirectoryEntryListSettings.modelToSelect = modelToSelect;
        widgetDirectoryEntryListSettings.setAsMainWidget = setAsMainWidget;
        widgetDirectoryEntryListSettings.switchViewMode = switchViewMode;
        widgetDirectoryEntryListSettings.switchViewModeVariant = switchViewModeVariant;
        widgetDirectoryEntryListSettings.resetSlideshow = resetSlideshow;

        widgetDirectoryEntryListSettings.directories = {};
        widgetDirectoryEntryListSettings.tags = {};
        widgetDirectoryEntryListSettings.pickerId = `widget-directory-entry-list-picker-${Widget.getCurrent().uuid}`;
        widgetDirectoryEntryListSettings.listOrderSelection = ['createdAt', 'updatedAt', 'name'];

        /**
         * Alias to the CONTENT_TYPES constant.
         *
         * @type {Array}
         */
        widgetDirectoryEntryListSettings.contentTypes = InitialSettings.CONTENT_TYPES;

        widgetDirectoryEntryListSettings.Content = Content;
        widgetDirectoryEntryListSettings.CustomContentType = CustomContentType;
        widgetDirectoryEntryListSettings.Instance = Instance;
        widgetDirectoryEntryListSettings.Directory = Directory;
        widgetDirectoryEntryListSettings.Metadata = Metadata;

        $scope.$on('directory-entry-picker__close-start', function (_event, _pickerId) {
            if (_pickerId === widgetDirectoryEntryListSettings.pickerId) {
                updateWidget();
            }
        });

        // Private methods

        function init() {
            if (ConfigTheme.DIRECTORY_ENTRY_CUSTOM_SORT_ORDER) {
                widgetDirectoryEntryListSettings.listOrderSelection.push('order');
            }

            if (angular.isUndefined(Widget.getCurrent().properties.instance)) {
                Widget.getCurrent().properties.instance = [Instance.getCurrentInstanceId()];
            }

            // backward compatibility
            if (angular.isDefined(Widget.getCurrent().properties.directory)) {
                if (!angular.isArray(Widget.getCurrent().properties.directory)) {
                    // if not array cast it as it
                    if (angular.isObject(Widget.getCurrent().properties.directory)) {
                        Widget.getCurrent().properties.directory = [Widget.getCurrent().properties.directory.id];
                    } else {
                        Widget.getCurrent().properties.directory = [Widget.getCurrent().properties.directory];
                    }
                }
            }

            if (
                angular.isDefined(Widget.getCurrent().properties.instance) &&
                Widget.getCurrent().properties.instance.length > 0
            ) {
                updateDirectoryList(Widget.getCurrent().properties.instance);
            }
        }

        /**
         * Build tag select with header
         * @param directoriesIds
         */
        function buildTagHeaderList(directoriesIds) {
            const tags = {};

            if (!Widget.getCurrent().isMainWidget || !Directory.getCurrent()) {
                angular.forEach(flatDirectoryList, function forEachDirectory(dir) {
                    if (
                        angular.isDefinedAndFilled(directoriesIds) &&
                        angular.isDefinedAndFilled(dir.id) &&
                        _.includes(directoriesIds, dir.id) &&
                        angular.isDefinedAndFilled(dir.tags)
                    ) {
                        tags[Translation.translate(dir.name)] = dir.tags;
                    }
                });

                /**
                 * Extract all available tags based on currently selected directory ids.
                 * */
                const availableTags = flatDirectoryList
                    .filter(
                        (directory) => directory.id && directory.tags && (directoriesIds ?? []).includes(directory.id),
                    )
                    .flatMap(({ tags }) => tags);
                /**
                 * Remove all outdated tags.
                 * Iterate through all currently selected tags, and check that they are still available.
                 * */
                const modelKeep = (Widget.getCurrent().properties.directoryTags ?? []).filter((selectedTag) =>
                    availableTags.some((tag) => tag.uuid === selectedTag),
                );

                Widget.getCurrent().properties.directoryTags = modelKeep;
                widgetDirectoryEntryListSettings.tags = tags;
            } else {
                widgetDirectoryEntryListSettings.tags[Translation.translate(Directory.getCurrent().name)] =
                    Directory.getCurrent().tags;
            }
        }

        // Public methods

        function resetSlideshow() {
            Widget.getCurrent().properties.slideshowEnabled = false;
            Widget.getCurrent().properties.slideshowAutoPlay = false;
            delete Widget.getCurrent().properties.slideshowInterval;
        }

        /**
         * Check if directory selector can be displayed
         */
        function displayDirectorySelector() {
            return (
                angular.isDefined(Widget.getCurrent().properties.instance) &&
                Widget.getCurrent().properties.instance.length &&
                angular.isDefinedAndFilled(widgetDirectoryEntryListSettings.directories)
            );
        }

        /**
         * Open directory entry picker
         */
        function openDirectoryEntryPicker() {
            Utils.waitForAndExecute(`#${widgetDirectoryEntryListSettings.pickerId}`, DirectoryEntryPicker);
        }

        /**
         * Update tag list
         */
        function updateTags() {
            buildTagHeaderList(Widget.getCurrent().properties.directory);
            updateWidget();
        }

        /**
         * Update widget on settings change
         */
        function updateWidget() {
            $timeout(function () {
                $rootScope.$broadcast('widget-directory-entry-list-settings', Widget.getCurrent().uuid);
            });
        }

        /**
         * Model to selection
         * @param data
         * @param callback
         * @param haystack
         * @param identifier
         */
        function modelToSelect(data, callback, haystack, identifier) {
            for (const key in haystack) {
                if (haystack.hasOwnProperty(key)) {
                    const needle = _.find(haystack[key], function (n) {
                        return n[identifier] === data;
                    });

                    if (angular.isDefined(needle)) {
                        (callback || _.noop)(needle);
                    }
                }
            }
        }

        /**
         * Update the list of directory
         * @param selectedInstances
         */
        function updateDirectoryList(selectedInstances) {
            // build select /w/ header
            Directory.getMultiInstanceDirectories(selectedInstances, function (list, dirIds) {
                // intersection, only keep ids that are in both list
                Widget.getCurrent().properties.directory = _.intersection(
                    Widget.getCurrent().properties.directory,
                    dirIds,
                );
                widgetDirectoryEntryListSettings.directories = list;

                // get flat directory list
                for (const instance in list) {
                    if (list.hasOwnProperty(instance) && angular.isArray(list[instance])) {
                        flatDirectoryList = flatDirectoryList.concat(list[instance]);
                    }
                }

                updateTags();
            });
        }

        /**
         * If this widget is now a "Main Widget", remove the isMainWidget attribute to the other widgets.
         */
        function setAsMainWidget() {
            const currentWidget = Widget.getCurrent();
            if (currentWidget.isMainWidget) {
                const _widgetList = ContentTemplate.getElementList(
                    Content.getCurrent(),
                    'widget',
                    'isMainWidget',
                    true,
                );
                angular.forEach(_widgetList, function forEachWidget(widget) {
                    if (widget.uuid !== currentWidget.uuid) {
                        widget.isMainWidget = false;
                    }
                });
            }

            $rootScope.$broadcast('widget-is-main-toggled', currentWidget.uuid, currentWidget.isMainWidget);
        }

        /**
         * Switch widget view mode.
         *
         * @param {string} viewMode The wanted view mode
         */
        function switchViewMode(viewMode) {
            if (Widget.getCurrent().properties.viewMode === viewMode) {
                return;
            }

            Widget.getCurrent().properties.viewMode = viewMode;
            Widget.getCurrent().properties.viewModeVariant = 'group';

            if (viewMode === 'list') {
                widgetDirectoryEntryListSettings.resetSlideshow();
            }

            widgetDirectoryEntryListSettings.updateWidget();
        }

        /**
         * Switch widget view mode variant.
         *
         * @param {string} viewModeVariant The wanted view mode variant
         */
        function switchViewModeVariant(viewModeVariant) {
            if (Widget.getCurrent().properties.viewModeVariant === viewModeVariant) {
                return;
            }

            Widget.getCurrent().properties.viewModeVariant = viewModeVariant;

            widgetDirectoryEntryListSettings.updateWidget();
        }

        /**
         * When instance siblings selector is reset, reset all filters.
         */
        $scope.$on('reset-instance-siblings-selector', function onInstanceSiblingsSelectorReset() {
            const _properties = Widget.getCurrent().properties;

            _properties.instance = [Instance.getCurrentInstanceId()];
            _properties.directory = [];
            _properties.directoryTags = [];
            _properties.metadata = [];
        });

        init();
    }
})();
