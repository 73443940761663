/* istanbul ignore file */
// eslint-disable-next-line lumapps/do-not-import-axios
import axios from 'axios';

export const fileReader = (file: File) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };

        reader.readAsArrayBuffer(file);
    });
};

export const uploadFiles = ({
    files,
    abortController,
    uploadFilesConfiguration,
}: {
    files: File[] | FileList;
    abortController?: AbortController;
    uploadFilesConfiguration: any;
}) => {
    return Promise.all(
        Array.from(files).map((file, index) => {
            const callConfig = uploadFilesConfiguration.items[index];

            return axios({
                headers: {
                    'Content-Type': files[index].type,
                    ...callConfig.headers,
                },
                method: (callConfig.httpMethod as any) || 'POST',
                url: callConfig.uploadUrl,
                data: file,
                signal: abortController?.signal,
            });
        }),
    );
};

type FileReaderAsTextSuccess = {
    /** The data of the file */
    data: string;
    /** Reading error */
    error: null;
};

type FileReaderAsTextError = {
    /** The data of the file */
    data: null;
    /** Reading error */
    error: Error;
};

export type FileReaderAsTextResponse = FileReaderAsTextSuccess | FileReaderAsTextError;

/**
 * Read a file's content as text
 *
 * @param {File} file - The file to read
 * @returns {Promise<FileReaderAsTextResponse>}
 */
export const fileReaderAsText = (file: File): Promise<FileReaderAsTextResponse> => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = () => resolve({ data: reader.result as string, error: null });

        reader.onerror = () => {
            // Resolve with no data and an error
            resolve({ data: null, error: new Error('Failed to read file') });
        };

        reader.readAsText(file);
    });
};
