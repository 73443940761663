/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import { batch } from '@lumapps/redux/react';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';

import * as programsApi from '@lumapps/sa-programs/api';
import { fetchAllTopics } from 'components/components/social-advocacy/ducks/topics/actions';

import { translate as t, transRep as tr } from 'components/translations';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

import * as notify from 'components/services/notification';

import { setLoadingState, clearLoadingState } from 'components/components/ducks/loadingStates';

import {
    getUrlInfo,
    postShareableContent,
    putShareableContent,
    backendErrorCodeToIntlMessage,
} from 'components/components/social-advocacy/api';

import {
    NOTIFICATIONS,
    ERRORS,
    AUTHORIZED_IMAGE_TYPES,
    MAX_PHOTOS,
    MAX_IMAGE_SIZE,
} from 'components/components/social-advocacy/ducks/shareableWorkflow/constants';

import {
    getShareableWorkflowContentPreview,
    makeShareableworkflowFormFieldelector,
    getShareableWorkflowIncompatibleNetworks,
} from 'components/components/social-advocacy/ducks/shareableWorkflow/selectors';

import { getTopTopicsWithMetric } from 'components/components/social-advocacy/ducks/analytics/actions';
import { addContentShareable } from 'components/components/social-advocacy/ducks/shareableContents/actions';
import { SHAREABLE_CONTENT_TYPES } from 'components/components/social-advocacy/ducks/shareableContents/constants';

import { mapActionsToNamespace } from 'components/components/ducks/form';

import { SA_FEATURE_FLAGS } from 'components/components/social-advocacy/constants';

import {
    getShareableworkflowFormValues,
    getShareableWorkflowProgramId,
    getShareableWorkflowShareableContent,
} from './selectors';

const DOMAIN = 'SA/SHAREABLE_WORKFLOW';

const TOGGLE_OPEN_WORKFLOW = `${DOMAIN}/TOGGLE_OPEN_WORKFLOW`;
const UPDATE_WORKFLOW = `${DOMAIN}/UPDATE_WORKFLOW`;
const SET_SELECT_OPEN = `${DOMAIN}/SET_SELECT_OPEN`;
const SET_SELECT_SEARCH = `${DOMAIN}/SET_SELECT_SEARCH`;
const SET_CONTENT_PREVIEW = `${DOMAIN}/SET_CONTENT_PREVIEW`;
const CLEAR_CONTENT_PREVIEW = `${DOMAIN}/CLEAR_CONTENT_PREVIEW`;
const SET_FORM_FIELD_ERROR = `${DOMAIN}/SET_FORM_FIELD_ERROR`;
const RESET_WORKFLOW_FORM = `${DOMAIN}/RESET_WORKFLOW_FORM`;
const ON_FETCH_MOST_USED_TOPICS_RESULT = `${DOMAIN}/ON_FETCH_MOST_USED_TOPICS_RESULT`;
const TOGGLE_WORKFLOW_PREVIEW = `${DOMAIN}/TOGGLE_WORKFLOW_PREVIEW`;
const SET_PREVIEW_TAB = `${DOMAIN}/SET_PREVIEW_TAB`;
const SET_CONTENT_TYPE = `${DOMAIN}/SET_CONTENT_TYPE`;
const SET_INCOMPATIBLE_SOCIAL_NETWORKS = `${DOMAIN}/SET_INCOMPATIBLE_SOCIAL_NETWORKS`;
const FETCH_SOCIAL_NETWORKS = `${DOMAIN}/FETCH_SOCIAL_NETWORKS`;
const FETCH_SOCIAL_NETWORKS_SUCCESS = `${DOMAIN}/FETCH_SOCIAL_NETWORKS_SUCCESS`;
const FETCH_SOCIAL_NETWORKS_FAILURE = `${DOMAIN}/FETCH_SOCIAL_NETWORKS_FAILURE`;
const FETCH_TOPICS = `${DOMAIN}/FETCH_TOPICS`;
const FETCH_TOPICS_SUCCESS = `${DOMAIN}/FETCH_TOPICS_SUCCESS`;
const FETCH_TOPICS_FAILURE = `${DOMAIN}/FETCH_TOPICS_FAILURE`;

const { setFormFieldError, setFormFieldValue, setFormFieldMeta, resetForm, setFormFieldValues } =
    mapActionsToNamespace(DOMAIN);

const DEBOUNCE_TIME = 500;
const MOST_USED_TOPICS_LIMIT = 50;

/**
 * Useful methods to format shareable content from and to the api.
 */
const formatShareableContent = {
    fromApi: (shareableContent) => {
        const customizations = get(shareableContent, 'resolvedSocialNetworks', []);

        const customizedValues = customizations.reduce(
            (acc, { socialNetworkId, suggestedComment }) => ({
                ...acc,
                customizedMessages:
                    !suggestedComment?.value || isNull(suggestedComment?.value)
                        ? acc.customizedMessages
                        : {
                              ...acc.customizedMessages,
                              [socialNetworkId]: suggestedComment.value,
                          },
            }),
            {
                customizedMessages: {},
            },
        );

        return {
            commonMessage: get(shareableContent, 'suggestedComment.value', ''),
            contentTitle: get(shareableContent, 'title.value'),
            contentUrl: get(shareableContent, 'contentShareableUrl.value'),
            customizedMessages: customizedValues.customizedMessages,
            id: get(shareableContent, 'id'),
            images: get(shareableContent, 'mediaList', []).map(({ url, type }) => ({
                id: generateUUID(),
                type,
                url,
            })),
            sameMessage: !isNull(shareableContent.suggestedComment),
            shareableOn: customizedValues.shareableOn,
            topics: get(shareableContent, 'topics', []),
            type: get(shareableContent, 'type', SHAREABLE_CONTENT_TYPES.link),
        };
    },
    toApi: (formValues) => {
        const isSameMessage = get(formValues, 'sameMessage.value', true);
        const commonMessage = get(formValues, 'commonMessage.value', '');
        const customizedMessages = get(formValues, 'customizedMessages.value', {});
        const type = get(formValues, 'type.value', SHAREABLE_CONTENT_TYPES.link);
        const shareableOn = get(formValues, 'shareableOn.value', {});

        const formattedValues = {
            suggestedComment:
                isSameMessage && commonMessage
                    ? {
                          translations: {
                              // Set as 'en' because i18n is not managed.
                              en: commonMessage,
                          },
                      }
                    : null,
            socialNetworks: Object.keys(shareableOn).map((socialNetworkId) => {
                const sharingEnabled = shareableOn[socialNetworkId];
                return {
                    socialNetworkId,
                    suggestedComment: !isEmpty(customizedMessages[socialNetworkId])
                        ? { translations: { en: customizedMessages[socialNetworkId] } }
                        : null,
                    sharingEnabled,
                };
            }),
            topicIds:
                (formValues?.topics?.value?.length > 0 && formValues?.topics?.value.map((topic) => topic.id)) || [],
            type,
            customTitle:
                type === SHAREABLE_CONTENT_TYPES.images && formValues?.contentTitle?.value
                    ? { translations: { en: formValues.contentTitle.value } }
                    : null,
            mediaUrls:
                type === SHAREABLE_CONTENT_TYPES.images && formValues?.images?.value?.length > 0
                    ? formValues.images.value.map(({ url }) => url)
                    : [],
            linkUrl:
                type === SHAREABLE_CONTENT_TYPES.link && formValues?.contentUrl?.value
                    ? { translations: { en: formValues.contentUrl.value } }
                    : null,
        };

        return formattedValues;
    },
};

/**
 * Extract URL from a string if one exists.
 * @param  {string}           value The input string.
 * @return {boolean | string} Return the URL if any.
 */
const findUrlsInString = (value) => {
    const urlRegex = /http(s?):\/\/[-a-zA-Z0-9:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9:%_+.~#?&/=]*)/g;

    return value.match(urlRegex) || [];
};

/**
 * Format errors by social network, and get the code's translation key for each.
 * Then set the errors in the store.
 * @param {array} socialNetworkErrors Array of errors
 */
const setIncompatibleSocialNetworksErrors = (socialNetworkErrors) => (dispatch) => {
    const socialNetworkCompatibilityErrors = socialNetworkErrors.reduce((acc, { code, metadata }) => {
        const errorTranslationKey = backendErrorCodeToIntlMessage[code];
        const currentErrors = acc[metadata.social_network_id] || [];

        return {
            ...acc,
            [metadata.social_network_id]: errorTranslationKey ? [...currentErrors, errorTranslationKey] : currentErrors,
        };
    }, {});

    /**
     * If a social network has an empty array of errors, it means we haven't anticipated this error code.
     * In order to still show an error, we set a fallback error.
     */
    Object.keys(socialNetworkCompatibilityErrors).forEach((socialNetworkId) => {
        if (socialNetworkCompatibilityErrors[socialNetworkId].length === 0) {
            socialNetworkCompatibilityErrors[socialNetworkId][0] =
                'FRONT.SOCIAL_ADVOCACY.SHAREABLE_CONTENT.CONTENT_TYPE_NOT_SUPPORTED';
        }
    });

    dispatch({ payload: socialNetworkCompatibilityErrors, type: SET_INCOMPATIBLE_SOCIAL_NETWORKS });
};

/**
 * Request backend to validate the form.
 * Catches for errors and dispatch corresponding action.
 */
const validateForm = () => async (dispatch, getState) => {
    dispatch(setLoadingState('VALIDATE_SHAREABLE_CONTENT'));

    try {
        const state = getState();
        const formValues = getShareableworkflowFormValues(state);
        const programId = getShareableWorkflowProgramId(state);
        const formattedValues = formatShareableContent.toApi(formValues);

        // To get validation for each social networks, set them all as enabled.
        const payload = {
            ...formattedValues,
            programId,
            socialNetworks: formattedValues.socialNetworks.map((socialNetwork) => ({
                ...socialNetwork,
                sharingEnabled: true,
            })),
        };

        // No error caught, empty all social network errors.
        dispatch(setIncompatibleSocialNetworksErrors([]));
    } catch (exception) {
        const errors = get(exception, 'response.data.errors', []);
        const socialNetworkErrors = errors.filter(({ metadata }) => get(metadata, 'social_network_id'));
        dispatch(setIncompatibleSocialNetworksErrors(socialNetworkErrors));
    }

    return dispatch(clearLoadingState('VALIDATE_SHAREABLE_CONTENT'));
};

/**
 * Debounce validateForm action.
 */
const debounceValidateForm = debounce((dispatch) => {
    dispatch(validateForm());
}, DEBOUNCE_TIME);

/**
 * Sets the Workflow form value and dispatches
 * social network compatibility check action.
 *
 * @param  {string}   fieldId The field id to update.
 * @param  {mixed}    value   The value to set.
 * @return {Function} thunk.
 */
const setWorkflowFormValue = (fieldId, value) => (dispatch) => {
    const watchedFields = ['type', 'images'];

    batch(() => {
        dispatch(setFormFieldValue(fieldId, value));

        if (watchedFields.includes(fieldId) && SA_FEATURE_FLAGS.selectSNForShareableContent) {
            debounceValidateForm(dispatch);
        }
    });
};

/**
 *
 * Fill in form with given values.
 *
 * @param  {Object}   shareableContent The shareableContent object.
 * @return {Function} thunk.
 */
const setDefaultFormValues = (shareableContent) => (dispatch) => {
    const formattedValues = formatShareableContent.fromApi(shareableContent);

    batch(() => {
        dispatch(setFormFieldValues(formattedValues));
        if (shareableContent && get(shareableContent, 'link')) {
            dispatch({
                payload: {
                    description: get(shareableContent, 'link.description.value'),
                    imageUrl: get(shareableContent, 'link.imageUrl.value'),
                    title: get(shareableContent, 'link.contentTitle.value'),
                    url: get(shareableContent, 'link.shareableUrl.value'),
                },
                type: SET_CONTENT_PREVIEW,
            });
        }
    });
};

/**
 * Fetch most used topics
 * @return {Function} thunk.
 */
const fetchMostUsedTopics = (programId) => (dispatch) => {
    const filters = {
        dataTo: new Date().toISOString(),
        programId,
    };

    dispatch(
        getTopTopicsWithMetric('shareable_content_created_count', 'topic.shareable', filters, MOST_USED_TOPICS_LIMIT),
    );
};

const toggleOpenWorkflow =
    (isWorkflowOpen = false, options = {}) =>
    async (dispatch) => {
        const { shareableContent = false, contentId = false, programId = null } = options;

        dispatch({
            contentId,
            programId,
            isWorkflowOpen,
            shareableContent,
            type: TOGGLE_OPEN_WORKFLOW,
        });

        if (isWorkflowOpen) {
            dispatch(setDefaultFormValues(shareableContent));
        }
    };

const toggleWorkflowPreview = (isPreviewOpen) => {
    return {
        payload: isPreviewOpen,
        type: TOGGLE_WORKFLOW_PREVIEW,
    };
};

const resetWorkflowForm = () => (dispatch) => {
    batch(() => {
        dispatch(resetForm());
        dispatch({
            type: RESET_WORKFLOW_FORM,
        });
    });
};

/**
 * Set select open state.
 *
 * @param  {string}   fieldId Field id for the select.
 * @param  {boolean}  isOpen  Open status.
 * @return {Function} thunk.
 */
const setSelectOpen = (fieldId, isOpen) => (dispatch) => dispatch(setFormFieldMeta(fieldId, 'isOpen', isOpen));

/**
 * Set select search state.
 *
 * @param  {string}   fieldId    Field id for the select.
 * @param  {string}   searchText Select search text.
 * @return {Function} thunk.
 */
const setSelectSearch = (fieldId, searchText) => (dispatch) =>
    dispatch(setFormFieldMeta(fieldId, 'searchText', searchText));

/**
 *
 * Get content preview from backend.
 *
 * @param  {string}   contentUrl The url to get the content from.
 * @param  {string}   contentId  If edit, the id of the content to edit.
 * @return {Function} thunk.
 */
const getContentPreview = (contentUrl, contentId) => async (dispatch, getState) => {
    dispatch(setLoadingState('GET_CONTENT_PREVIEW'));

    try {
        const state = getState();
        const programId = getShareableWorkflowProgramId(state);
        const { data: responseData } = await getUrlInfo({ url: contentUrl, contentId, programId });

        if (!responseData.canBeMadeShareable) {
            dispatch(setFormFieldError('contentUrl', true, ERRORS.INVALID_URL));
        } else if (responseData.shareable && !contentId) {
            dispatch(setFormFieldError('contentUrl', true, ERRORS.ALREADY_EXISTS));
        } else {
            const { url, description, title, imageUrl } = responseData.meta;

            dispatch({
                payload: {
                    description,
                    imageUrl,
                    title,
                    url,
                },
                type: SET_CONTENT_PREVIEW,
            });
        }
    } catch (exception) {
        dispatch(setFormFieldError('contentUrl', true, ERRORS.INVALID_URL));
    } finally {
        dispatch(clearLoadingState('GET_CONTENT_PREVIEW'));
    }
};

/**
 *
 * Validates url format and get contentPreview if valid.
 *
 * @param  {string}    value     The value to set as content url.
 * @param  {contentId} contentId The contentid if content is being edited.
 * @return {Function}  thunk.
 */
const validateUrl = (value, contentId) => (dispatch) => {
    const url = value.includes('localhost') ? value : findUrlsInString(value)[0];

    if (url) {
        dispatch(setFormFieldError('contentUrl', false));
        dispatch(getContentPreview(value, contentId));
    } else if (isEmpty(value)) {
        dispatch(setFormFieldError('contentUrl', false));
    } else {
        dispatch(setFormFieldError('contentUrl', true, ERRORS.INVALID_URL));
    }
};

/**
 * Debounce validateUrl action.
 */
const debounceValidateUrl = debounce((value, contentId, dispatch) => {
    dispatch(validateUrl(value, contentId));
}, DEBOUNCE_TIME);

/**
 *
 * Set content url value + validations.
 *
 * @param  {string}    value     The value to set as content url.
 * @param  {contentId} contentId The contentid if content is being edited.
 * @return {Function}  thunk.
 */
const setContentUrlValue = (value, contentId) => (dispatch, getState) => {
    dispatch(setWorkflowFormValue('contentUrl', value));

    const state = getState();
    const currentContent = getShareableWorkflowContentPreview(state);

    if (currentContent.url) {
        dispatch({ type: CLEAR_CONTENT_PREVIEW });
    }

    debounceValidateUrl(value, contentId, dispatch);
};

/**
 *
 * Create shareable content.
 *
 * @param  {Object}   formValues The form values.
 * @return {Function} thunk.
 */
const createShareableContent = (formValues, programId, successCallback) => async (dispatch, getState) => {
    dispatch(setLoadingState('CREATE_SHAREABLE_CONTENT'));

    try {
        const state = getState();
        const incompatibleSocialNetworks = getShareableWorkflowIncompatibleNetworks(state);
        const contentId = get(formValues, 'id.value');

        const formattedValues = formatShareableContent.toApi(formValues);
        const payload = {
            ...formattedValues,
            programId: programId || null,
            /**
             * Disable sharing for incompatible social networks.
             */
            socialNetworks: formattedValues.socialNetworks
                .filter((socialNetwork) => socialNetwork.programId !== 'facebook')
                .map((socialNetwork) => ({
                    ...socialNetwork,
                    sharingEnabled:
                        socialNetwork.sharingEnabled && !incompatibleSocialNetworks[socialNetwork.socialNetworkId],
                })),
        };

        const respData = contentId
            ? await putShareableContent(contentId, payload)
            : await postShareableContent(payload);

        if (respData) {
            dispatch(addContentShareable(respData.data));
            dispatch(resetWorkflowForm());
            notify.success(t(NOTIFICATIONS.SUBMIT_SUCCESS));

            if (successCallback) {
                successCallback();
            }
        }
    } catch (exception) {
        const errorCode = get(exception, 'response.data.error.code');
        const errorMessage = get(backendErrorCodeToIntlMessage, errorCode);
        // In case we haven't anticipated the translation.
        const fallbackMessage = errorCode ? NOTIFICATIONS.VALIDATION_ERROR : NOTIFICATIONS.SUBMIT_ERROR;

        notify.error(t(errorMessage || fallbackMessage));
    } finally {
        dispatch(clearLoadingState('CREATE_SHAREABLE_CONTENT'));
    }
};

const setPreviewTab = (tab) => (dispatch) => {
    dispatch({
        payload: tab,
        type: SET_PREVIEW_TAB,
    });
};

const setContentType = (contentType) => (dispatch) => {
    dispatch({
        payload: contentType,
        type: SET_CONTENT_TYPE,
    });
};

/**
 * Set images to the store with restrictions.
 * Notify the user if an image isn't compatible.
 *
 * @param  {Array}    images Images to add.
 * @return {Function} thunk.
 */
const setImages = (images) => (dispatch) => {
    let gifNotified = false;

    const filteredImages = images.filter((image) => {
        if (!image.url) {
            notify.error(t('GLOBAL.ERROR.GENERIC'));

            return false;
        }
        if (!AUTHORIZED_IMAGE_TYPES.includes(image.type)) {
            if (!gifNotified) {
                notify.error(`${t('GLOBAL.ERROR.IMAGE_FORMAT_NOT_SUPPORTED')} : ${image.type}`);
                gifNotified = true;
            }

            return false;
        }
        if (image.size >= MAX_IMAGE_SIZE) {
            notify.error(tr('FRONT.SOCIAL_ADVOCACY.IMAGE_TOO_LARGE', ['MAX_SIZE'], [MAX_IMAGE_SIZE]));

            return false;
        }

        return true;
    });

    if (filteredImages.length > MAX_PHOTOS) {
        notify.error(
            MAX_PHOTOS > 1
                ? tr('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.MAX_PHOTOS.MORE', ['NB'], [MAX_PHOTOS])
                : t('FRONT.SOCIAL_ADVOCACY.SHAREABLE_WORKFLOW.MAX_PHOTOS.ONE'),
        );
        filteredImages.splice(MAX_PHOTOS);
    }

    dispatch(setWorkflowFormValue('images', filteredImages));
};

const removeImage = (imageId) => (dispatch, getState) => {
    const state = getState();
    const formFieldSelector = makeShareableworkflowFormFieldelector(state);
    const { value: currentImages } = formFieldSelector('images');

    dispatch(
        setWorkflowFormValue(
            'images',
            currentImages.filter(({ id }) => imageId !== id),
        ),
    );
};

/**
 * Fetch the program's social networks and
 * init the shareable values.
 * @param {string} programId The programId to get the social networks from
 */
export const initSocialNetworks = (programId) => async (dispatch, getState) => {
    try {
        dispatch({ type: FETCH_SOCIAL_NETWORKS });

        const state = getState();
        const shareableContent = getShareableWorkflowShareableContent(state);
        const shareableContentSocialNetworks = get(shareableContent, 'resolvedSocialNetworks', []);

        const { data } = await programsApi.fetchProgram(programId);

        /**
         * Filter the program's social networks to get only
         * the active ones.
         */
        const activeSocialNetworks = data.resolvedSocialNetworks
            .filter(({ active }) => active)
            .map(({ socialNetworkId }) => socialNetworkId);

        batch(() => {
            dispatch({
                type: FETCH_SOCIAL_NETWORKS_SUCCESS,
                payload: activeSocialNetworks,
            });
            /**
             * Set initial values for each active social network.
             * If it is not already set in given shareableContent, set as true.
             *
             */
            dispatch(
                setWorkflowFormValue(
                    'shareableOn',
                    activeSocialNetworks.reduce((acc, curr) => {
                        const shareableContentValue = shareableContentSocialNetworks.find(
                            ({ socialNetworkId }) => socialNetworkId === curr,
                        );

                        return {
                            ...acc,
                            [curr]: get(shareableContentValue, 'sharingEnabled', true),
                        };
                    }, {}),
                ),
            );
        });

        // Validate form now that the social networks are all setup.
        dispatch(validateForm());
    } catch (exception) {
        dispatch({ type: FETCH_SOCIAL_NETWORKS_FAILURE });
    }
};

/**
 * Fetch the program's topics and most used topics.
 * @param {string} programId The programId to get the social networks from
 */
export const fetchProgramTopics = (programId) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_TOPICS });

        await Promise.all([dispatch(fetchAllTopics(programId)), dispatch(fetchMostUsedTopics(programId))]);

        dispatch({ type: FETCH_TOPICS_SUCCESS });
    } catch (exception) {
        dispatch({ type: FETCH_TOPICS_FAILURE });
    }
};

export {
    createShareableContent,
    resetWorkflowForm,
    fetchMostUsedTopics,
    getContentPreview,
    setWorkflowFormValue as setFormFieldValue,
    setSelectOpen,
    setSelectSearch,
    setFormFieldError,
    setContentUrlValue,
    setDefaultFormValues,
    toggleOpenWorkflow,
    toggleWorkflowPreview,
    setPreviewTab,
    setContentType,
    setImages,
    removeImage,
    DOMAIN,
    UPDATE_WORKFLOW,
    TOGGLE_OPEN_WORKFLOW,
    SET_SELECT_OPEN,
    SET_SELECT_SEARCH,
    SET_FORM_FIELD_ERROR,
    SET_CONTENT_PREVIEW,
    CLEAR_CONTENT_PREVIEW,
    RESET_WORKFLOW_FORM,
    ON_FETCH_MOST_USED_TOPICS_RESULT,
    TOGGLE_WORKFLOW_PREVIEW,
    SET_PREVIEW_TAB,
    SET_CONTENT_TYPE,
    SET_INCOMPATIBLE_SOCIAL_NETWORKS,
    FETCH_SOCIAL_NETWORKS,
    FETCH_SOCIAL_NETWORKS_SUCCESS,
    FETCH_SOCIAL_NETWORKS_FAILURE,
};
