import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';
import uniq from 'lodash/uniq';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import {
    Community,
    CommunitySearchInitiatedPayload,
    CommunitySearchSuccessPayload,
    CommunityNavigationEditState,
    FrontofficeState,
    SearchCommunityState,
    CommunityMetadata,
} from '../types';

const initialState: FrontofficeState = {
    entities: {},
    metadata: null,
    search: {
        query: '',
        cursor: '',
        ids: [],
        hasMore: true,
        followingOnly: true,
        status: SearchCommunityState.initial,
    },
    isLoadingForPostDialog: false,
    selectedCommunityId: '',
    selectedCommunity: {} as Community,
    currentCommunity: {} as Community,
    isConfigurationWizardOpen: false,
    communityNavigationEdit: { isOpen: false },
};

type ErrorMessage = string;

const { actions, reducer } = createSlice({
    domain: 'community',
    initialState,
    reducers: {
        setCommunitySearchInitiated: (
            state: FrontofficeState,
            action: PayloadAction<CommunitySearchInitiatedPayload>,
        ) => {
            if (!action.payload.isLoadingMore) {
                set(state, ['search', 'ids'], []);
            }
            set(
                state,
                ['search', 'status'],
                action.payload.isLoadingMore ? SearchCommunityState.loadingMore : SearchCommunityState.loading,
            );
        },
        setFilterFollowingOnly: (state: FrontofficeState, action: PayloadAction<boolean>) => {
            set(state, ['search', 'followingOnly'], action.payload);
        },
        /**
         * payload.items is used in 2 ways:
         * 1/ for the slice community/entities: we add the complete community
         * objects into a dictionary/cache of communities: ids => community
         * 2/ for the slice community/search/ids: we store in "search"
         * (current status of the latest commmunity search done) only the list
         * of ids that are returned. adding them to the existing ones if the fetch
         * is done for the additional data --pagination-- in which case payload.add
         * it true.
         */
        fetchCommunitiesSearchSuccess: (
            state: FrontofficeState,
            action: PayloadAction<CommunitySearchSuccessPayload>,
        ) => {
            const items = action.payload.items || [];
            const listByIds = keyBy(items, 'id');
            set(state, 'entities', { ...state.entities, ...listByIds });

            set(
                state,
                ['search', 'ids'],
                action.payload.add
                    ? uniq([...get(state, ['search', 'ids']), ...Object.keys(listByIds)])
                    : Object.keys(listByIds),
            );

            set(
                state,
                ['search', 'status'],
                items && items.length > 0 ? SearchCommunityState.success : SearchCommunityState.noCommunities,
            );
            set(state, ['search', 'cursor'], action.payload.cursor);
            set(state, ['search', 'hasMore'], action.payload.more);
        },
        fetchCommunitiesSearchError: (state: FrontofficeState, action: PayloadAction<ErrorMessage>) => {
            set(state, ['search', 'ids'], initialState.search.ids);
            set(state, ['search', 'status'], SearchCommunityState.failure);
            set(state, ['search', 'errorMessage'], action.payload);
        },
        setSelectedCommunity: (state: FrontofficeState, action: PayloadAction<Community>) => {
            set(state, 'selectedCommunity', action.payload);
        },
        resetSelectedCommunity: (state: FrontofficeState) => {
            set(state, 'selectedCommunity', initialState.selectedCommunity);
        },
        setSelectedCommunityId: (state: FrontofficeState, action: PayloadAction<string>): void => {
            set(state, 'selectedCommunityId', action.payload);
        },
        setIsLoadingForPostDialog: (state: FrontofficeState, action: PayloadAction<boolean>): void => {
            set(state, 'isLoadingForPostDialog', action.payload);
        },
        setCommunityNavigationEdit: (
            state: FrontofficeState,
            action: PayloadAction<CommunityNavigationEditState>,
        ): void => {
            set(state, 'communityNavigationEdit', action.payload);
        },
        setCommunities(state: FrontofficeState, action: PayloadAction<Community[]>) {
            Object.assign(state.entities, keyBy(action.payload, 'id'));
        },
        openConfigurationWizard(state: FrontofficeState) {
            set(state, 'isConfigurationWizardOpen', true);
        },
        closeConfigurationWizard(state: FrontofficeState) {
            set(state, 'isConfigurationWizardOpen', false);
        },
        setCommunityMetadata(state: FrontofficeState, action: PayloadAction<CommunityMetadata>) {
            set(state, 'metadata', action.payload);
        },
        resetCommunityMetadata(state: FrontofficeState) {
            set(state, 'metadata', initialState.metadata);
        },
    },
});

export { actions, reducer, initialState };
