import React from 'react';

import { classnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

export const DeleteButton = ({ onDelete, className }: { onDelete: (event: any) => void; className?: string }) => {
    const { translateKey } = useTranslate();

    return (
        <div className={className || classnames('wrex-embedded-map__actions')}>
            <IconButton
                icon={mdiClose}
                emphasis={Emphasis.low}
                hasBackground
                onClick={onDelete}
                label={translateKey(GLOBAL.DELETE)}
            />
        </div>
    );
};
