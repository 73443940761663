import React from 'react';

import { Checkbox, CheckboxProps } from '@lumapps/lumx/react';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export type FormCheckboxFieldProps = Omit<CheckboxProps, 'onChange' | 'isChecked'> & BaseInput;

/**
 * Component that displays a checkbox and manages it inside a form.
 * @family Forms
 * @param FormCheckboxFieldProps
 * @returns FormCheckboxField
 */
export const FormCheckboxField: React.FC<FormCheckboxFieldProps> = ({ name, controllerProps, label, ...props }) => {
    const { valueToUse, field, getDataAttributes } = useFormField<boolean>({
        name,
        controllerProps,
        label,
    });

    return (
        <Checkbox
            {...getDataAttributes({ element: 'checkbox', action: name })}
            {...props}
            label={label}
            isChecked={Boolean(valueToUse)}
            onChange={field.onChange}
            inputProps={
                {
                    ...getDataAttributes({ element: 'checkbox', action: `${name}-input` }),
                } as CheckboxProps['inputProps']
            }
        />
    );
};
