import React, { useState } from 'react';

import { classnames, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Alignment, ColorPalette, ColorVariant, FlexBox, ListItem, Orientation, Size, Text } from '@lumapps/lumx/react';
import { getElapsedTimeFromUtcDate } from '@lumapps/utils/time/getElapsedTimeFromUtcDate';

import { DATA_NAMESPACE } from '../../constants';
import { useDescription } from '../../hooks/useDescription';
import { useNotificationRoute } from '../../hooks/useNotificationRoute';
import { Notification, NotificationSenderDetails } from '../../types';
import { NotificationContextMenu } from './NotificationContextMenu';
import { NotificationListItemBefore } from './NotificationListItemBefore';

import './index.scss';

const CLASSNAME = 'notification-list-item';

export type NotificationListItemCommonProps = {
    notification: Notification;
    senderDetails?: NotificationSenderDetails | null;
    setNotificationRead?(notificationId: string, isRead: boolean, shouldCloseDropdown?: boolean): void;
    deleteNotification?(notificationId: string, isNotificationRead: boolean | undefined): void;
    closeNotificationMenu?(): void;
    className?: string;
    notificationContextMenuClassName?: string;
    isChild?: boolean;
};

const NotificationListItemCommon: React.FC<NotificationListItemCommonProps> = ({
    notification,
    senderDetails,
    setNotificationRead,
    deleteNotification,
    closeNotificationMenu,
    className,
    notificationContextMenuClassName,
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(DATA_NAMESPACE);
    const description = useDescription(notification, senderDetails);
    const { linkProps, route, linkAs: LinkAs = 'div' } = useNotificationRoute(notification);
    const updatedFrom = getElapsedTimeFromUtcDate(notification.updatedAt);
    const [isNotificationHover, setisNotificationHover] = useState(false);
    const onClick = React.useCallback(
        (evt: React.MouseEvent) => {
            evt.stopPropagation();

            if (route) {
                // setNotificationRead will also close the notification menu
                if (!notification.isRead && setNotificationRead) {
                    setNotificationRead(notification.uid, true);
                }
                // if the notification is already read we just want to close the notification menu
                if (closeNotificationMenu) {
                    closeNotificationMenu();
                }
            }
        },
        [notification.uid, setNotificationRead, closeNotificationMenu, route, notification.isRead],
    );

    const notificationLinkProps = { ...linkProps, onClick: route ? onClick : undefined };

    const handleMarkAs = React.useCallback(
        (isRead: boolean) => () => {
            if (setNotificationRead) {
                setNotificationRead(notification.uid, isRead, false);
                setisNotificationHover(false);
            }
        },
        [notification.uid, setNotificationRead],
    );

    const handleDelete = React.useCallback(() => {
        if (deleteNotification) {
            deleteNotification(notification.uid, notification.isRead);
        }
    }, [notification.uid, notification.isRead, deleteNotification]);

    return (
        <ListItem
            className={classnames(
                block({
                    clickable: Boolean(route),
                }),
                className,
            )}
            size={Size.tiny}
            onMouseLeave={() => {
                setisNotificationHover(false);
            }}
            onMouseEnter={() => {
                setisNotificationHover(true);
            }}
            {...get({ element: 'row', type: notification.type })}
        >
            <FlexBox orientation={Orientation.horizontal} vAlign={Alignment.spaceBetween} hAlign={Alignment.center}>
                <LinkAs className={element('content')} {...notificationLinkProps}>
                    <FlexBox orientation={Orientation.horizontal} vAlign={Alignment.center} gap={Size.huge}>
                        <NotificationListItemBefore
                            className={element('before')}
                            notification={notification}
                            senderDetails={senderDetails}
                        />
                        <FlexBox>
                            <div>{description}</div>

                            <Text as="p" color={ColorPalette.dark} colorVariant={ColorVariant.L2}>
                                {updatedFrom}
                            </Text>
                        </FlexBox>
                    </FlexBox>
                </LinkAs>
                <div className={element('menu')}>
                    <NotificationContextMenu
                        className={notificationContextMenuClassName}
                        isRead={notification.isRead}
                        showCircle={!notification.isRead && !isNotificationHover}
                        notificationId={notification.uid}
                        onMarkAs={handleMarkAs}
                        onDelete={handleDelete}
                    />
                </div>
            </FlexBox>
        </ListItem>
    );
};
NotificationListItemCommon.displayName = 'ListItem';

export { NotificationListItemCommon };
