import omit from 'lodash/omit';

import { Theme } from '@lumapps/lumx/react';

import { OUTER_SPACING_STYLES } from '../constants';
import { WidgetContentStyles, WidgetGlobalStyles } from '../types';
import { computeStyles } from './computeStyles';

/** Compute widget CSS styles. */
export function computeWidgetStyle(widget?: WidgetGlobalStyles, content?: WidgetContentStyles, isMobile?: boolean) {
    const widgetStyle = computeStyles(isMobile ? omit(widget, OUTER_SPACING_STYLES) : widget);

    /* fullHeight is set in the body style, but used at this level. */
    const { fullHeight, theme = Theme.light, ...widgetContentStyle } = content || {};

    return { isFullHeight: fullHeight, theme, widgetContentStyle, widgetStyle };
}
