import type { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const journeysBaseRoute: Route = {
    path: '/admin/journeys',
    appId: AppId.backOffice,
    legacyId: 'app.admin.journeys',
};

export enum JOURNEY_VIEWS {
    EDIT = 'edit',
    VIEW = 'view',
    ITEM_CONFIGURATIONS = 'item-configurations',
    ENROLLMENTS = 'enrollments',
}

export interface JourneyPageParams {
    id: string;
    view?: JOURNEY_VIEWS;
}

export const journeyPageRoute: Route = {
    appId: AppId.backOffice,
    path: `${journeysBaseRoute.path}/:id/:view?`,
};

export const journeyPage = (params: JourneyPageParams, query?: { fullName?: string }): Route => {
    return {
        ...journeyPageRoute,
        params: {
            id: params.id,
            view: params.view || JOURNEY_VIEWS.VIEW,
        },
        query: query?.fullName ? { name: query.fullName } : {},
    };
};
