import flatMap from 'lodash/flatMap';
import is from 'unist-util-is';

import { isText as isSlateText } from '../../../slate/utils/isText';
import type { Wrex } from '../../../types';
import { DITA, Element, Node, SlateStructuredContent } from '../types';
import { featuredImageFromDita } from '../utils/featuredImageFromDita';
import { getDescription } from '../utils/getDescription';
import { getTitle } from '../utils/getTitle';
import { isElement, isText } from '../utils/test';
import { OPTIONS } from './options';
import { Marks } from './types';

/**
 * Recursively transform DITA JSON representation node into slate node(s).
 *
 * @param node             DITA node to convert.
 * @param index            DITA node index in parent.
 * @param parent           Parent DITA node.
 * @param activeMarks      Active marks in the context.
 * @param parents          The cumulate parents.
 */
export function toSlate(
    node: Node,
    index?: number,
    parent?: Element,
    activeMarks?: Marks,
    parents: Element[] = [],
): Wrex.Nodes {
    // If text node (& has a slate parent).
    if (isText(node)) {
        return [{ text: node.value, ...activeMarks }];
    }

    const newActiveMarks: Marks = { ...activeMarks };

    // If mark node.
    const markOption = OPTIONS.marks.find(({ test }) => is(node, test, index, parent));
    if (markOption) {
        newActiveMarks[markOption.mark] = markOption.markValue ? markOption.markValue(node) : true;
    }

    const elementOption = OPTIONS.elements.find(({ test, testParents }) => {
        if (testParents && !testParents(parents)) {
            return false;
        }
        return is(node, test, index, parent);
    });

    // Transform node children into slate nodes.
    const slateChildren = isElement(node)
        ? flatMap(node.children, (child, i) => toSlate(child, i, node, newActiveMarks, [...parents, node]))
        : [];

    // If element node.
    if (elementOption) {
        const transformedNode = elementOption.transform(node as Element, slateChildren);
        return [transformedNode];
    }

    // Keep text node or non empty elements.
    return slateChildren.filter((child) => isSlateText(child) || child.children?.length);
}

/**
 * Parse a DITA structured content.
 *
 * @param dita     A DITA document.
 */
export const fromDITA = (dita: DITA): SlateStructuredContent => {
    const title = getTitle(dita);
    const description = getDescription(dita);
    const featuredImage = featuredImageFromDita(dita);
    const contentNodes = dita?.children?.filter((el) => el.name === 'body' || el.name === 'topic');
    const content = flatMap(contentNodes, (node) => toSlate(node));

    return {
        title,
        description,
        featuredImage,
        content,
    };
};
