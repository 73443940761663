import get from 'lodash/fp/get';

import { listInstances } from '@lumapps/instance/api';
import { ListInstanceParams } from '@lumapps/instance/api/types';
import { Order } from '@lumapps/lumx-filter-and-sort/constants';
import { NotificationsAPI } from '@lumapps/notifications';
import { ListState } from '@lumapps/redux/genericListDucks';
import { GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { markFavorite, markUnFavorite } from '../api';
import { WIDGET_SITE_LIST } from '../keys';
import { SiteListType, SiteListState } from '../types';
import { listActions, widgetActions } from './slice';

export const DEFAULT_LIST_INSTANCE_FIELDS =
    'items(title,name,id,slug,subtitle,properties,markedAsFavorite,deletableFromFavorite,isInFavoriteFeedKeys),more,callId,cursor';

export const fetchSiteList =
    ({
        dispatchAction,
        type,
        instancesIds,
        maxResults,
        listOrder,
        listOrderDir,
    }: {
        dispatchAction: React.Dispatch<any>;
        type?: SiteListType;
        instancesIds?: string[];
        maxResults: number;
        listOrder?: string;
        listOrderDir?: Order;
    }) =>
    async () => {
        let sortOrder = listOrder || 'name';
        if (listOrderDir === 'desc') {
            sortOrder = `-${sortOrder}`;
        }
        const params: ListInstanceParams = {
            maxResults: type === 'pick' ? instancesIds?.length : maxResults,
            sortOrder,
            fields: DEFAULT_LIST_INSTANCE_FIELDS,
            userFavoritesOnly: type === 'favorites',
            ids: type === SiteListType.pick ? instancesIds : undefined,
        };

        // Set loading status
        dispatchAction(listActions.setStatus(BaseLoadingStatus.loading));

        // Try to fetch data
        try {
            const { data } = await listInstances(params);
            const { items = [] } = data;

            // dispatch results
            dispatchAction(
                listActions.updateResult({
                    ids: items.map(get('id')) as string[],
                    items,
                    hasMore: data.more,
                    cursor: data.cursor,
                    params,
                }),
            );
            dispatchAction(listActions.setStatus(BaseLoadingStatus.idle));
        } catch (e) {
            dispatchAction(listActions.setStatus(BaseLoadingStatus.error));
        }
    };

export const fetchMoreList =
    ({ state, dispatchAction }: { state: ListState; dispatchAction: React.Dispatch<any> }) =>
    async () => {
        const { cursor, hasMore, status, params } = state;
        if (!cursor || !hasMore || status !== BaseLoadingStatus.idle) {
            return;
        }
        dispatchAction(listActions.setStatus(BaseLoadingStatus.loadingMore));

        try {
            const { data } = await listInstances({ ...params, cursor });
            const { items } = data;
            dispatchAction(
                listActions.updateResult({
                    ids: items.map(get('id')) as string[],
                    items,
                    hasMore: data.more,
                    cursor: data.cursor,
                    params,
                    append: true,
                }),
            );

            dispatchAction(listActions.setStatus(BaseLoadingStatus.idle));
        } catch (e) {
            dispatchAction(listActions.setStatus(BaseLoadingStatus.error));
        }
    };

export const markSiteFavorite =
    ({
        dispatchAction,
        success,
        error,
    }: {
        state: SiteListState;
        dispatchAction: React.Dispatch<any>;
        success(options: NotificationsAPI): void;
        error(options: NotificationsAPI): void;
    }) =>
    async (id: string) => {
        // Set loading status
        dispatchAction(widgetActions.setFavoriteStatus({ id, status: BaseLoadingStatus.loading }));

        // Try to fetch data
        try {
            await markFavorite({ kind: 'Instance', uid: id });
            dispatchAction(widgetActions.setFavoriteStatus({ id, status: BaseLoadingStatus.idle }));
            dispatchAction(widgetActions.deleteUnfavoriteStatusHandled(id));
            success({ translate: WIDGET_SITE_LIST.USER_FAVORITES_ADD });
        } catch (e) {
            dispatchAction(widgetActions.setFavoriteStatus({ id, status: BaseLoadingStatus.error }));
            error({ translate: GLOBAL.GENERIC_ERROR });
        }
    };

export const markSiteUnFavorite =
    ({
        dispatchAction,
        success,
        error,
    }: {
        state: SiteListState;
        dispatchAction: React.Dispatch<any>;
        success(options: NotificationsAPI): void;
        error(options: NotificationsAPI): void;
    }) =>
    async (id: string) => {
        // Set loading status
        dispatchAction(widgetActions.setUnFavoriteStatus({ id, status: BaseLoadingStatus.loading }));

        // Try to fetch data
        try {
            await markUnFavorite({ kind: 'Instance', uid: id });
            dispatchAction(widgetActions.setUnFavoriteStatus({ id, status: BaseLoadingStatus.idle }));
            dispatchAction(widgetActions.deleteFavoriteStatusHandled(id));
            success({ translate: WIDGET_SITE_LIST.USER_FAVORITES_DELETE });
        } catch (e) {
            dispatchAction(widgetActions.setUnFavoriteStatus({ id, status: BaseLoadingStatus.error }));
            error({ translate: GLOBAL.GENERIC_ERROR });
        }
    };
