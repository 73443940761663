import { Menu as BaseMenu } from './Menu';
import { MenuDivider } from './MenuDivider';
import { MenuItem } from './MenuItem';
import { MenuItemCheckbox } from './MenuItemCheckbox';
import { MenuItemRadio } from './MenuItemRadio';
import { MenuItemSubmenu } from './MenuItemSubmenu';
import { MenuSection } from './MenuSection';
import { MenuSectionRadioGroup } from './MenuSectionRadioGroup';
import { MenuTrigger } from './MenuTrigger';
import { MenuTriggerButton } from './MenuTriggerButton';
import { MenuItemSkeleton } from './Skeleton/MenuItemSkeleton';
import { MenuItemsSkeleton } from './Skeleton/MenuItemsSkeleton';
import { MenuSectionSkeleton } from './Skeleton/MenuSectionSkeleton';
import { MenuSkeleton } from './Skeleton/MenuSkeleton';

import './index.scss';

export const SUB_COMPONENTS = {
    /**
     * Menu divider (ARIA `separator`).
     *
     * To use inside a `Menu` or `Menubar`.
     *
     * @family Menus
     */
    Divider: MenuDivider,
    /**
     * Menu item (ARIA `menuitem`).
     *
     * To use inside a `Menu` or `Menubar`.
     *
     * @family Menus
     */
    Item: MenuItem,
    /**
     * Menu radio item (ARIA `menuitemradio`).
     * A specialised version of `Menu.Item` with a radio state.
     * Needs to be wrapped in a `Menu.SectionRadioGroup`
     *
     * @family Menus
     */
    ItemRadio: MenuItemRadio,
    /**
     * Menu toggleable item (ARIA `menuitemcheckbox`).
     * A specialised version of `Menu.Item` with a toggleable checked state.
     *
     * To use inside a `Menu` or `Menubar`.
     *
     * @family Menus
     */
    ItemCheckbox: MenuItemCheckbox,
    /**
     * Menu section (ARIA `group`).
     *
     * To use inside a `Menu` or `Menubar`.
     *
     * @family Menus
     */
    Section: MenuSection,
    /**
     * Menu radio group (ARIA `radiogroup`).
     * To use inside a Menu.
     *
     * @family Menus
     */
    SectionRadioGroup: MenuSectionRadioGroup,
    /**
     * ItemSubmenu (nested menu popup).
     *
     * Wrapper component around the `Menu.Trigger`, `Menu.TriggerButton` (attached to a `Menu.Item`) and `Menu`
     * to provide a simple submenu.
     *
     * To use inside a `Menu` or `Menubar`.
     *
     * @family Menus
     */
    ItemSubmenu: MenuItemSubmenu,
    /**
     * Menu trigger attaching the trigger button with the menu popup.
     *
     * @family Menus
     */
    Trigger: MenuTrigger,
    /**
     * Menu trigger button.
     * Defaults to the LumX Button, but it can be customized with the `as` prop.
     *
     * @family Menus
     */
    TriggerButton: MenuTriggerButton,
    /**
     * Menu skeleton.
     *
     * @family Menus
     */
    Skeleton: MenuSkeleton,
    /**
     * Menu item skeleton.
     *
     * @family Menus
     */
    ItemSkeleton: MenuItemSkeleton,
    /**
     * Wrapper component on `MenuItemSkeleton` to produce 3 item skeleton.
     *
     * @family Menus
     */
    ItemsSkeleton: MenuItemsSkeleton,
    /**
     * Menu section skeleton.
     *
     * @family Menus
     */
    SectionSkeleton: MenuSectionSkeleton,
};

/**
 * Base menu list (ARIA `menu`)
 * Use Menu.Trigger & Menu.TriggerButton to attach it to a trigger button in a popup.
 *
 * @family Menus
 */
export const Menu = Object.assign(BaseMenu, SUB_COMPONENTS);
