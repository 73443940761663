import { createParagraph } from '@lumapps/wrex-typography/utils/createParagraph';
import { Editor, Path, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import { T_D } from '../constants';
import type { TableEditor, TDElement } from '../types';
import { getCurrentRowsInTable } from './getCurrentRowsInTable';
import { getCurrentSelectionInfos } from './getCurrentSelectionInfos';

interface AddColumnOptions {
    at?: Path;
    preventFocus?: boolean;
}

export const addColumn = (
    editor: ReactEditor & TableEditor,
    position: 'before' | 'after',
    options?: AddColumnOptions,
) => {
    /* get selection details (null if no selection or if selection not in table) */
    const selectionInfos = getCurrentSelectionInfos(editor);

    if (!selectionInfos) {
        return undefined;
    }

    const { isSelectionInOneCell, startCol, endCol } = selectionInfos;

    /* column to insert the first cell */
    const getInsertionCol = () => {
        if (position === 'before') {
            return startCol;
        }
        return isSelectionInOneCell ? startCol + 1 : endCol + 1;
    };

    const tablePath = [(options?.at || Range.start(editor.selection as Range).path)[0]];
    /* get the rows in current table */
    const { rowsInTable } = getCurrentRowsInTable(editor, tablePath);
    const createNode = () => ({ type: T_D, children: [createParagraph()] }) as TDElement;

    /* we skip the normalization here to avoid cells insertion fixes during the column insertion processus */
    Editor.withoutNormalizing(editor, () => {
        /* insert a new cell in each row */
        rowsInTable.forEach((_, index) => {
            /* define the position to insert each cell (before or after the current position in table) */
            const pathToInsert = [...tablePath, index, getInsertionCol()];
            /* insert the new cell in each row */
            Transforms.insertNodes(editor, createNode(), { at: pathToInsert });
        });
    });

    /* focus on the first cell of the new column */
    const newCellPosition = {
        anchor: {
            path: [...tablePath, 0, getInsertionCol(), 0, 0],
            offset: 0,
        },
        focus: {
            path: [...tablePath, 0, getInsertionCol(), 0, 0],
            offset: 0,
        },
    };

    // For some reason, the focusAt doesn't work properly in the table context if we do not delay it.
    if (!options?.preventFocus) {
        setTimeout(() => {
            focusAt(editor, newCellPosition);
        }, 1);
    }

    return newCellPosition;
};
