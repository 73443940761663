/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import { classnames, margin, padding, visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiArrowDown, mdiArrowUp, mdiSort } from '@lumapps/lumx/icons';
import {
    Button,
    ButtonGroup,
    ButtonProps,
    Dropdown,
    Emphasis,
    FlexBox,
    Icon,
    IconButton,
    List,
    ListItem,
    Placement,
    Size,
    Text,
} from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SORT_FIELDS, SORT_ORDER } from '../../constants';
import { SortersType } from '../../types';

export type SortersProps = {
    /** List of available sort items */
    choices?: SortersType[];
    /** Current selected sort item */
    sort: SortersType['id'];
    /** The order in which we are currently sorting */
    order?: SORT_ORDER;
    /** Whether we can change the order of the sorting */
    isChangingOrderEnabled?: boolean;
    /** is sorting loading */
    isLoading?: boolean;
    /** whether sorting is disabled */
    isDisabled?: boolean;
    /** whether the "Sorted by" prefix is displayed on the button or not */
    shouldDisplaySortedByLabel?: boolean;
    /** Action when the sort item changes */
    onChange(sort: SortersType['id'], order: SORT_ORDER): void;
    /** If we would like to have sorting name in mobile version */
    hasMobileText?: boolean;
    /** Class name of component */
    className?: string;
    /** Sort button props for select button  */
    sortButtonProps?: Partial<ButtonProps>;
};

const sortIcons = {
    [SORT_ORDER.DECREASING]: mdiArrowDown,
    [SORT_ORDER.INCREASING]: mdiArrowUp,
};

export const SORT_TRANSITIONS = {
    [SORT_ORDER.INCREASING]: SORT_ORDER.DECREASING,
    [SORT_ORDER.DECREASING]: SORT_ORDER.INCREASING,
};

export const SORT_TRANSLATIONS = {
    [SORT_ORDER.INCREASING]: GLOBAL.ASCENDING,
    [SORT_ORDER.DECREASING]: GLOBAL.DESCENDING,
};

const CLASSNAME = 'lumx-sorters';

/**
 * Component that displays sorting options and allows the user to select between them. If you are displaying a select
 * with options Ascending/Descending for any search results, this is the component to use.
 * @family Filters
 * @param SortersProps
 * @returns Sorters
 */
export const Sorters: React.FC<SortersProps> = ({
    choices = SORT_FIELDS,
    onChange,
    sort,
    order = SORT_ORDER.INCREASING,
    isChangingOrderEnabled = true,
    isLoading = false,
    isDisabled = false,
    hasMobileText = false,
    className,
    sortButtonProps,
    shouldDisplaySortedByLabel = false,
}) => {
    const buttonRef = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const { translate, translateKey, translateAndReplace } = useTranslate();
    const { get } = useDataAttributes('sorters');
    const { isMobile } = useResponsive();

    const value = React.useMemo(() => {
        if (choices) {
            return choices.find((choice) => choice.id === sort);
        }

        return null;
    }, [choices, sort]);

    const onSelectChange = (sorter: SortersType) => () => {
        if (sort !== sorter.id) {
            setIsOpen(false);
            return onChange(sorter.id, order);
        }

        return null;
    };

    const onOrderChange = () => {
        return onChange(sort, SORT_TRANSITIONS[order]);
    };

    const openDropdown = (open: boolean) => () => setIsOpen(open);
    const isSortingDisabled = isLoading || isDisabled;
    const shouldShowFullVersion = hasMobileText || !isMobile;
    const sortButtonText = shouldDisplaySortedByLabel
        ? translateAndReplace(GLOBAL.SORTED_BY, {
              sort: translate(value?.label) as string,
          })
        : translate(value?.label);

    const sortButton = shouldShowFullVersion ? (
        <Button
            ref={buttonRef}
            leftIcon={mdiSort}
            onClick={openDropdown(!isOpen)}
            emphasis={Emphasis.low}
            isDisabled={isSortingDisabled}
            className={classnames({
                [`${CLASSNAME}`]: !isChangingOrderEnabled,
                [`${className}`]: !isChangingOrderEnabled && className,
                [`${CLASSNAME}--disabled`]: isSortingDisabled,
            })}
            {...sortButtonProps}
            {...get({ element: 'sort-button', action: sort })}
        >
            {sortButtonText}
        </Button>
    ) : (
        <IconButton
            ref={buttonRef}
            icon={mdiSort}
            onClick={openDropdown(!isOpen)}
            emphasis={Emphasis.low}
            isDisabled={isSortingDisabled}
            label={translateKey(GLOBAL.SORTING)}
            {...sortButtonProps}
            className={classnames({
                [`${CLASSNAME}`]: !isChangingOrderEnabled,
                [`${className}`]: !isChangingOrderEnabled && className,
                [`${CLASSNAME}--disabled`]: isSortingDisabled,
            })}
            {...get({ element: 'sort-button', action: sort, isMobile })}
        />
    );

    const orderChangeButtonId = 'change-sort-order-button';
    const orderChangeButton = (
        <>
            <IconButton
                onClick={onOrderChange}
                icon={sortIcons[order]}
                emphasis={Emphasis.low}
                isDisabled={isSortingDisabled}
                size={sortButtonProps?.size}
                className={classnames({
                    [`${CLASSNAME}--disabled`]: isSortingDisabled,
                })}
                label={translateAndReplace(GLOBAL.FORMAT_COLON, {
                    key: translateKey(GLOBAL.TOGGLE),
                    value: translateKey(GLOBAL.ORDER),
                })}
                aria-describedby={orderChangeButtonId}
                {...get({ element: 'sort', action: 'order' })}
            />

            <Text as="p" className={visuallyHidden()} id={orderChangeButtonId}>
                {translateKey(SORT_TRANSLATIONS[order])}
            </Text>
        </>
    );

    if (!choices || choices.length === 0) {
        return null;
    }

    if (choices.length === 1) {
        return (
            <FlexBox
                orientation="horizontal"
                gap="regular"
                hAlign="center"
                className={sortButtonProps?.size === Size.m ? padding('all', 'regular') : undefined}
            >
                <Icon
                    icon={mdiSort}
                    size={sortButtonProps?.size || Size.xs}
                    alt={translateKey(GLOBAL.SORTING)}
                    color="dark"
                    colorVariant="L1"
                />

                {shouldShowFullVersion ? (
                    <Text
                        as="span"
                        typography={sortButtonProps?.size !== Size.m ? 'caption' : 'body1'}
                        color="dark"
                        colorVariant="L1"
                    >
                        <b>{sortButtonText}</b>
                    </Text>
                ) : null}

                {isChangingOrderEnabled && (
                    <Icon
                        icon={sortIcons[order]}
                        size={sortButtonProps?.size || Size.xs}
                        alt={translateKey(SORT_TRANSLATIONS[order])}
                        color="dark"
                        colorVariant="L1"
                        className={margin('left', 'regular')}
                    />
                )}
            </FlexBox>
        );
    }

    return (
        <>
            {isChangingOrderEnabled ? (
                <ButtonGroup className={classnames(CLASSNAME, className)}>
                    {sortButton}

                    {orderChangeButton}
                </ButtonGroup>
            ) : (
                sortButton
            )}

            {choices.length > 1 ? (
                <Dropdown
                    isOpen={isOpen}
                    closeOnClick
                    closeOnEscape
                    onClose={openDropdown(false)}
                    anchorRef={buttonRef}
                    placement={Placement.BOTTOM_END}
                >
                    <List {...get({ action: 'list', element: 'sort' })}>
                        {choices.map((sorter, index) => (
                            <ListItem
                                key={sorter.label}
                                isSelected={value?.id === sorter.id}
                                onItemSelected={onSelectChange(sorter)}
                                size={Size.tiny}
                                {...get({ action: sorter.id, element: 'sort', position: index + 1 })}
                            >
                                {translate(sorter.label)}
                            </ListItem>
                        ))}
                    </List>
                </Dropdown>
            ) : null}
        </>
    );
};
