import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { getCurrentContentAsCommunity } from '@lumapps/communities/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { FlexBox, Switch, TextField } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { WIDGET_BASE } from '@lumapps/widget-base/keys';

import { ListWidgetProperties, ListWidgetPropertiesSettingType } from '../../types';
import { SettingSection } from '../SettingSection';

import './index.scss';

export interface ListWidgetPropertiesSettingProps {
    className?: string;
    currentPropertiesSetting?: ListWidgetPropertiesSettingType;
    loadMoreHelper?: string;
    defaultMaxNumber?: string;
    dataScope?: string;
    propertiesToDisplay?: ListWidgetProperties[];
    onChange: (propertiesSetting: ListWidgetPropertiesSettingType) => void;
}

const CLASSNAME = 'properties-setting';

export const ListWidgetPropertiesSetting: React.FC<ListWidgetPropertiesSettingProps> = ({
    className,
    loadMoreHelper,
    currentPropertiesSetting,
    defaultMaxNumber = '3',
    dataScope,
    propertiesToDisplay = [ListWidgetProperties.filters, ListWidgetProperties.maxNumber, ListWidgetProperties.loadMore],
    onChange,
}) => {
    const currentCommunity = useSelector(getCurrentContentAsCommunity);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(`${dataScope}-properties`);
    const { block } = useClassnames(CLASSNAME);
    const [showFilters, setshowFilters] = React.useState(currentPropertiesSetting?.hasFilters ?? !!currentCommunity);
    const [isLoadMoreFieldChecked, setIsLoadMoreFieldChecked] = React.useState<boolean>(
        currentPropertiesSetting?.canLoadMore ?? true,
    );

    const [maxNumber, setMaxNumber] = React.useState(() => {
        if (!currentPropertiesSetting?.maxNumber || currentPropertiesSetting?.maxNumber <= 0) {
            return defaultMaxNumber;
        }
        return currentPropertiesSetting.maxNumber.toString();
    });

    const setFilteredMaxNumber = (maxNb: string) => {
        if (parseInt(maxNb, 10) <= 0) {
            setMaxNumber('');
        } else {
            setMaxNumber(maxNb.replace(/[^0-9]/g, ''));
        }
    };

    React.useEffect(() => {
        onChange({ maxNumber: parseInt(maxNumber, 10), hasFilters: showFilters, canLoadMore: isLoadMoreFieldChecked });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFilters, isLoadMoreFieldChecked, maxNumber]);

    return (
        <SettingSection title={translateKey(GLOBAL.PROPERTIES)} className={block(CLASSNAME, [className])}>
            <FlexBox orientation="vertical" gap="huge">
                {propertiesToDisplay.includes(ListWidgetProperties.filters) && (
                    <Switch
                        isChecked={showFilters}
                        onChange={setshowFilters}
                        {...get({ element: 'switch', action: 'toggle-filters' })}
                    >
                        {translateKey(WIDGET_BASE.SETTINGS_SHOW_SEARCH)}
                    </Switch>
                )}

                {propertiesToDisplay.includes(ListWidgetProperties.maxNumber) && (
                    <TextField
                        onChange={setFilteredMaxNumber}
                        label={translateKey(WIDGET_BASE.SETTINGS_MAX_RESULTS)}
                        helper={translateKey(WIDGET_BASE.SETTINGS_MAX_RESULTS_HELPER)}
                        value={maxNumber}
                        type="number"
                        min="1"
                        placeholder={defaultMaxNumber}
                        {...get({ element: 'input', action: 'max-results' })}
                    />
                )}
                {propertiesToDisplay.includes(ListWidgetProperties.loadMore) && (
                    <Switch
                        isChecked={isLoadMoreFieldChecked}
                        onChange={setIsLoadMoreFieldChecked}
                        helper={loadMoreHelper}
                        {...get({ element: 'switch', action: 'toggle-load-more-button' })}
                    >
                        {translateKey(WIDGET_BASE.SETTINGS_SHOW_LOAD_MORE)}
                    </Switch>
                )}
            </FlexBox>
        </SettingSection>
    );
};
