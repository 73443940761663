import { RouterData, ROUTER_DATA_NAMESPACES } from '@lumapps/router/ducks/types';
import { useDispatchRouterData, useRouterData } from '@lumapps/router/hooks/useRouterData';

export interface UseSearchRoutingDataOptions {
    id: string;
    mergeData?: boolean;
}

/**
 * Dispatches and retrieves data from the router in order to have search page navigation
 * @param UseSearchRoutingDataOptions
 * @returns Routing data
 */
export const useSearchRoutingData = ({ id, mergeData }: UseSearchRoutingDataOptions) => {
    const routerData = useRouterData(ROUTER_DATA_NAMESPACES.SEARCH_PAGE);

    const { dispatch } = useDispatchRouterData({
        routerData: {
            id: ROUTER_DATA_NAMESPACES.SEARCH_PAGE,
            data: {
                lastVisitedPage: id,
            },
            mergeData,
        },
        shouldDispatch: Boolean(routerData) && routerData.lastVisitedPage !== id,
    });

    return {
        dispatchRouter: (data: RouterData['data']) => {
            dispatch({
                id: ROUTER_DATA_NAMESPACES.SEARCH_PAGE,
                data,
            });
        },
        routerData,
    };
};
