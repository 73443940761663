import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SkeletonTypography, Text, TextProps, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useBlockPlaylistContext } from '../BlockPlaylistContext';
import { CLASSNAME as BLOCK_PLAYLIST_CLASSNAME } from '../constants';

export const CLASSNAME = `${BLOCK_PLAYLIST_CLASSNAME}-videos-count` as const;

type VideosCountProps = Omit<TextProps, 'as'>;

/**
 * Displays the videos count of a playlist
 */
export const VideosCount = ({ typography = Typography.body1, props }: VideosCountProps) => {
    const { pluralize, translateAndReplace } = useTranslate();
    const { block } = useClassnames(CLASSNAME);

    const { canDisplay, isLoading, playlist, theme } = useBlockPlaylistContext();

    if (!canDisplay('VIDEO_COUNT')) {
        return null;
    }

    if (isLoading) {
        return <SkeletonTypography className={block('skeleton')} theme={theme} typography={typography} width="10%" />;
    }

    if (playlist.videosCount === undefined) {
        return null;
    }

    return (
        <Text {...props} as="span" noWrap>
            {translateAndReplace(pluralize(GLOBAL.NB_VIDEOS, playlist.videosCount), {
                NB: playlist.videosCount,
            })}
        </Text>
    );
};
