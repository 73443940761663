import { generateUUID } from '@lumapps/utils/string/generateUUID';

function ChannelService(ChannelFactory, LumsitesBaseService, Utils) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(ChannelFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Retrieve the list of channels in a user space.
     *
     * @param  {string}  provider      The provider of channels.
     * @param  {string}  spaceId       The identifier of user space.
     * @param  {number}  [nbChannel=5] The number of conversations to retrieve.
     * @param  {number}  [page]        The current page number.
     * @return {Promise} The promise of loading conversations.
     */
    function getListConversations(provider, spaceId, nbChannel, page) {
        return ChannelFactory.listConversations({
            callId: generateUUID(),
            maxResults: nbChannel,
            page,
            provider,
            spaceId,
        }).$promise;
    }

    /**
     * Returns the list of user joined Teams.
     *
     * @param  {string}  provider       The provider of channels.
     * @param  {number}  [maxResults]   The number of spaces to retrieve.
     * @param  {number}  [page]         The current page number.
     * @param  {string}  [searchFilter] The search filter term.
     * @return {Promise} The list of joined channels.
     */
    function listJoined({ provider, maxResults, page, searchFilter }) {
        return ChannelFactory.listJoined({
            callId: generateUUID(),
            maxResults,
            page,
            provider,
            searchFilter,
        }).$promise;
    }

    /////////////////////////////

    service.getListConversations = getListConversations;
    service.listJoined = listJoined;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Channel', ChannelService);

/////////////////////////////

export { ChannelService };
