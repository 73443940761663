import React, { useEffect, useCallback, useState } from 'react';

import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { fetchReadState, acknowledgeContent } from '../ducks/thunks';
import { MandatoryReadBlock, MandatoryReadBlockProps } from './MandatoryReadBlock';

export interface MandatoryReadProps extends MandatoryReadBlockProps {
    contentId: string;
    displayError(messageKey: string): void;
    inputLang?: string;
}

export const MandatoryReadWithEffect: React.FC<MandatoryReadProps> = ({ contentId, displayError, ...props }) => {
    const [isLoading, , setIsLoaded, setIsLoading] = useBooleanState(true);
    const [readState, setReadState] = useState<Pick<MandatoryReadBlockProps, 'isRead' | 'confirmedAt'>>({
        isRead: false,
    });
    const onFetch = useCallback(
        (fetchResult) => {
            setReadState(fetchResult);
            setIsLoaded();
        },
        [setIsLoaded],
    );
    const onError = useCallback(
        (messageKey: string) => {
            setIsLoaded();
            displayError(messageKey);
        },
        [displayError, setIsLoaded],
    );

    const onConfirm = useCallback(() => {
        setIsLoading();
        acknowledgeContent({ contentId, onSuccess: onFetch, onError });
    }, [contentId, onError, onFetch, setIsLoading]);

    useEffect(() => {
        if (contentId) {
            setIsLoading();
            fetchReadState({ contentId, onSuccess: onFetch, onError });
        } else {
            onFetch({ isRead: false });
        }
    }, [contentId, onError, onFetch, setIsLoading]);

    return <MandatoryReadBlock {...props} isLoading={isLoading} onConfirm={onConfirm} {...readState} />;
};
