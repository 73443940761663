function WidgetImageGalleryController(Features) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Features = Features;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Set parent controller.
     *
     * @param {Object} parentCtrl The parent controller.
     */
    this.setParentController = function setParentController(parentCtrl) {
        vm.parentCtrl = parentCtrl;
    };
}

/////////////////////////////

function WidgetImageGalleryDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].setParentController(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: WidgetImageGalleryController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['widgetImageGallery', '^widget'],
        restrict: 'E',
        scope: {
            widget: '<',
        },
        // eslint-disable-next-line max-len
        templateUrl:
            '/client/front-office/modules/content/modules/widget/modules/widget-image-gallery/views/widget-image-gallery.html',
    };
}

/////////////////////////////

angular.module('Widgets').directive('widgetImageGallery', WidgetImageGalleryDirective);

/////////////////////////////

export { WidgetImageGalleryController, WidgetImageGalleryDirective };
