/* istanbul ignore file */
import get from 'lodash/get';

import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';
import { connectSocialNetwork as connect } from '@lumapps/sa-social-networks/utils/connect';
import { GLOBAL } from '@lumapps/translations';

import * as api from '../api';
import { getSingleSocialNetworkData, hasSocialNetworkAccessesLoadedSelector } from './selectors';
import { actions } from './slice';

export const getSocialNetworkAccesses =
    (useCache = true) =>
    async (dispatch: Dispatch) => {
        try {
            const response = await api.getSocialNetworkAccesses(useCache);
            const { data } = response;
            dispatch(actions.fetchSocialNetworkAccessesSuccess(data));
        } catch (exception) {
            // log the error silently
            // eslint-disable-next-line no-console
            console.error(exception);
        }
    };

export const getUser = () => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.startFetchUser());
        const response = await api.getUser();
        const { data } = response;
        dispatch(actions.fetchUserSuccess(data));

        return data;
    } catch (exception) {
        // log the error silently
        // eslint-disable-next-line no-console
        console.error(exception);
        return undefined;
    }
};

export const disconnectSocialNetwork = (id: string) => async (dispatch: Dispatch) => {
    try {
        await api.deleteSocialNetworkAccess(id);
        dispatch(actions.disconnectSuccess(id));
    } catch (exception) {
        // log the error silently
        // eslint-disable-next-line no-console
        console.error(exception);
    }
};

export const connectSocialNetwork = (socialNetwork: SocialNetworks) => (dispatch: Dispatch) => {
    return connect(socialNetwork, async () => {
        await dispatch(getSocialNetworkAccesses(false));
    });
};

/**
 * Checks that user currently has fresh access to a social network.
 *
 * If the requestLogIn param is set to true, the login window for social network
 * will open.
 *
 *
 * @param  {string}   socialNetworkId      The social network to verify access for.
 * @param  {boolean}  requestLogIn         Whether log inshould be requested if user doesn't have access.
 * @param  {boolean}  fetchFreshAccesses   Whether we should force a fresh fetch. Else it will be automatically checked
 * @return {Function} The actions creator's thunk.
 */
export const verifySocialNetworkAccess =
    (socialNetwork: SocialNetworks, requestLogIn = false, fetchFreshAccesses = false) =>
    async (dispatch: Dispatch, getState: GetBaseState) => {
        try {
            const hasSocialNetworkAccessesLoaded = hasSocialNetworkAccessesLoadedSelector(getState());

            if (fetchFreshAccesses || !hasSocialNetworkAccessesLoaded) {
                await dispatch(getSocialNetworkAccesses());
            }
            const userAccessSelector = getSingleSocialNetworkData(getState());
            const userHasAccess = !get(userAccessSelector(socialNetwork), 'authorizationRefreshRequired', true);

            if (userHasAccess) {
                return userHasAccess;
            }
            if (requestLogIn) {
                await dispatch(connectSocialNetwork(socialNetwork));

                return dispatch(verifySocialNetworkAccess(socialNetwork));
            }

            throw new Error(GLOBAL.GENERIC_ERROR);
        } catch (exception) {
            throw new Error(exception as string);
        }
    };
