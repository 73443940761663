import { useQuery } from '@lumapps/base-api/react-query';
import { useSelector } from '@lumapps/redux/react';
import { isMicrosoftAccount } from '@lumapps/user/ducks/selectors';

import { BatchOneDriveFilesRequestParam, getBatchOneDriveFilesApi, oneDriveQueryKeys } from '../../api';

export function useGetOneDriveFiles(apiParams?: BatchOneDriveFilesRequestParam) {
    const isMicrosoftUser = useSelector(isMicrosoftAccount);
    const params = { ...apiParams, isMicrosoftUser };

    const query = useQuery({
        queryKey: oneDriveQueryKeys['batch-files'](params),
        queryFn: async ({ signal }) => getBatchOneDriveFilesApi(params, signal),
    });

    return query;
}
