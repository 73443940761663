import React from 'react';

import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import unionBy from 'lodash/unionBy';

import { classnames, margin } from '@lumapps/classnames';
import { Message, Theme } from '@lumapps/lumx/react';
import { MetadataFilter } from '@lumapps/metadata-pickers/types';
import { useRootMetadata } from '@lumapps/metadata/hooks/useRootMetadata';
import { METADATA } from '@lumapps/metadata/keys';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { FilterSubheader } from '../FilterSubheader/FilterSubheader';
import { MultiSelectMetadataFilter } from '../MultiSelectMetadataFilter';
import { SkeletonFilter } from '../SkeletonFilter';

interface MetadataContentFilterProps {
    /** List of metadata selected */
    selectedMetadata?: MetadataFilter[];
    /** List of content types applicable for this filter */
    contentTypes?: string[];
    /** Theme */
    theme: Theme;
    /** Handler */
    onChange: (values: MetadataFilter[]) => void;
    /** Scope */
    scope: string;
    hideSubheader?: boolean;
}

const LOADING_SKELETON_QUANTITY = 3;

/**
 * Component that fetch metadata root attached to the provided content types,
 * and then displays one multi select filter for each of these metadata root.
 * Each select allow the selection of several metadata inherited from their metadata root.
 * @family Filters
 * @param MetadataContentFilterProps
 * @returns MetadataContentFilterProps
 */
export const MetadataContentFilter = ({
    selectedMetadata = [],
    contentTypes,
    theme,
    scope,
    onChange,
    hideSubheader,
}: MetadataContentFilterProps) => {
    const { translateObject, translateKey } = useTranslate();
    const { isLoading, hasError, rootMetadata } = useRootMetadata({
        contentTypes,
        displayInFilter: true,
        withoutCustomContentTypes: true,
    });

    const handleSelectMetadata = (newMetadata?: MetadataFilter[]) => {
        const updatedMetadata = unionBy(selectedMetadata, newMetadata, 'id');
        onChange(updatedMetadata);
    };

    const handleRemoveMetadata = (metadataId?: string) => {
        const updatedMetadata = selectedMetadata?.filter(({ id }) => id !== metadataId);
        onChange(updatedMetadata);
    };

    if (isLoading && isEmpty(rootMetadata)) {
        return <SkeletonFilter quantity={LOADING_SKELETON_QUANTITY} />;
    }

    if (hasError) {
        return (
            <Message kind="error" hasBackground className={classnames(margin('top', 'big'), margin('bottom', 'big'))}>
                {translateKey(METADATA.LOADING_METADATA_ERROR)}
            </Message>
        );
    }

    if (isEmpty(rootMetadata)) {
        return null;
    }

    return (
        <>
            {!hideSubheader && <FilterSubheader label={translateKey(GLOBAL.METADATA)} theme={theme} />}
            {rootMetadata.map(({ id, name }) => {
                const metadataFromRoot = selectedMetadata?.filter(({ rootId }) => rootId === id);
                return (
                    <MultiSelectMetadataFilter
                        key={id}
                        label={translateObject(name) || ''}
                        rootId={id}
                        theme={theme}
                        scope={scope}
                        values={metadataFromRoot?.map(({ id }) => id)}
                        defaultDictionary={keyBy(metadataFromRoot, 'id')}
                        handleSelectMetadata={handleSelectMetadata}
                        handleRemoveMetadata={handleRemoveMetadata}
                    />
                );
            })}
        </>
    );
};
