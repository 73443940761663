import * as React from 'react';
import * as propTypes from 'prop-types';

import * as types from '../../types';
import { Icon } from '../icons/Icon';
import { Button } from '../buttons/Button';

/**
 * Renders a lockable group of inputs which, when locked, sets the same
 * value to all inputs.
 */
export class LockGroup extends React.PureComponent {
    static propTypes = {
        /** Whether all inputs are disabled or not. */
        disabled: propTypes.bool,
        /** Left set of inputs. */
        left: propTypes.any,
        name: types.properties,
        /** Called when the value changes with `(value, name, occurence)`. */
        onChange: propTypes.func,
        /** Right set of inputs. */
        right: propTypes.any,
        /** Whether the group is locked or not. */
        value: propTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.onClick = (occurence) => {
            const { value, name: key, onChange } = this.props;

            return onChange(!value, key, occurence);
        };
    }

    render() {
        const { value, left, right, onChange, disabled } = this.props;

        return (
            <div className="lock-group">
                <div className="lock-group__left">{left}</div>
                <div className="lock-group__lock-btn">
                    <Button
                        color="grey"
                        disabled={disabled}
                        size="s"
                        variant="icon"
                        onClick={onChange ? this.onClick : undefined}
                    >
                        <Icon value={value ? 'lock' : 'lock-open'} />
                    </Button>
                </div>
                <div className="lock-group__right">{right}</div>
            </div>
        );
    }
}
