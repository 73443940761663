import React from 'react';

import { useClassnames } from '@lumapps/classnames';

import { SelectableCardProvider } from '../../hooks/useSelectableCardContext';
import { getMainActionCount } from '../../utils';
import { ColorCard, ColorCardProps } from '../ColorCard';
import { CLASSNAME } from './constants';
import { MainAction, SecondaryAction } from './SelectableCard.Actions';

import './index.scss';

export interface SelectableCardComposition {
    MainAction: typeof MainAction;
    SecondaryAction: typeof SecondaryAction;
}

export interface SelectableCardProps extends ColorCardProps {
    /** whether the card is selected or not */
    isSelected?: boolean;
}

/**
 * Component displaying a complex card with simple padding and colored background that is also able to handle a "selected" state
 * Fully accessible to keyboard navigation and screen-reader thx to sub components:
 * - SelectableCard.MainAction: there can only be one in the card. Can be a button or a link. Will have the focus all over the card itself
 * - SelectableCard.SecondaryAction: there can be as many as you like in a card. Can be any button (select, dropdown...) or a link
 *
 * @family Cards
 * @param SelectableCardProps
 * @returns SelectableCard
 */
export const SelectableCard: SelectableCardComposition & React.FC<SelectableCardProps> = ({
    className = '',
    color = 'light',
    children,
    isSelected = false,
    ...forwardedProps
}) => {
    const [isHover, setIsHover] = React.useState(false);
    const { block } = useClassnames(CLASSNAME);

    const mainActionCount = React.useMemo(() => getMainActionCount(children), [children]);

    if (mainActionCount > 1) {
        throw new Error('SelectableCard cannot have more than 1 MainAction');
    }

    return (
        <SelectableCardProvider value={{ isHover, setIsHover }}>
            <ColorCard
                color={color}
                className={block(
                    {
                        [`${color}-is-hover`]: isHover,
                        [`${color}-is-selected`]: isSelected,
                    },
                    [className],
                )}
                {...forwardedProps}
            >
                {children}
            </ColorCard>
        </SelectableCardProvider>
    );
};

SelectableCard.MainAction = MainAction;
SelectableCard.SecondaryAction = SecondaryAction;
