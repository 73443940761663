import { BROADCAST_FF } from '@lumapps/broadcasts/constants';
import { isFeatureEnabled } from '@lumapps/features';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

import { CAMPAIGN_FF } from '../constants';

export const isCampaignsEnabled = isFeatureEnabled(CAMPAIGN_FF);
export const isBroadcastsEnabled = isFeatureEnabled(BROADCAST_FF);
export const canManageCampaigns = isUserAllowed('canManageCampaigns');

/**
 * canAccessCampaign if:
 * - the campaign feature flag is activated AND
 * - the broadcast feature flag is activated AND
 * - EITHER the user is platform admin/site admin of the current site
 * - OR the user has the permission canManageCampaigns (isUserAllowed firstly checks if the user is platform admin/site admin.
 * If so, it automatically allows the user to access the feature. Otherwise, it checks if canManageCampaigns is set to true)
 */
export const canAccessCampaign = createSelector(
    [isCampaignsEnabled, isBroadcastsEnabled, canManageCampaigns],
    (campaignsEnabled, broadcastsEnabled, canManageCampaigns) =>
        campaignsEnabled && broadcastsEnabled && canManageCampaigns,
);
