import React from 'react';

import { block, classnames } from '@lumapps/classnames';
import { Button, ButtonProps, Emphasis } from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'reaction-button';

export const ReactionButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { className, emphasis = Emphasis.low, children, isDisabled, ...forwardedProps } = props;
    return (
        <Button
            ref={ref}
            className={classnames(className, block(CLASSNAME)({ 'is-disabled': !!isDisabled }))}
            isDisabled={isDisabled}
            emphasis={emphasis}
            {...forwardedProps}
        >
            {children}
        </Button>
    );
});
