import React from 'react';

import uniq from 'lodash/uniq';

import { padding, useClassnames } from '@lumapps/classnames';
import { Form } from '@lumapps/lumx-forms/components/Form';
import { FormSelect } from '@lumapps/lumx-forms/components/FormSelect';
import {
    Alignment,
    Button,
    Dialog,
    DialogProps,
    Emphasis,
    FlexBox,
    Orientation,
    Size,
    Text,
    Toolbar,
    Typography,
} from '@lumapps/lumx/react';
import { usePlayUsers } from '@lumapps/play-roles';
import { PlayUser } from '@lumapps/play-roles/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { usePlayDataAttributes } from '../../hooks/usePlayDataAttributes';

import './index.scss';

type SelectedEntity = {
    /** Id of the video author */
    createdById: string;
    /** Id of the video */
    id: string;
};

interface ChangeAuthorsDialog extends Pick<DialogProps, 'isOpen' | 'onVisibilityChange'> {
    /** String used to fill data-attributes and tracking id */
    entityType: 'video' | 'podcast' | 'playlist';
    /** Array of entities for which authors are to be changed */
    selectedEntities: SelectedEntity[];
    /** Select input helper text */
    selectHelper: string;
    /** Text describing the selection that was made "You selected x entities from x authors" */
    selectionDescription: ({ authorCount, entityCount }: { authorCount: number; entityCount: number }) => string;
    /** Dialog title */
    title: string;
    /** Callback called when the user clicks on the `Cancel` button. */
    onCancel: () => void;
    /** Callback called when there was an error updating entities authors */
    onError: () => void;
    /** Callback called when the user clicks on the `Save` button. */
    onSave: ({ entityId, authorId }: { entityId: SelectedEntity['id']; authorId: PlayUser['id'] }) => void;
    /** Callback called once all selected entities have been updated */
    onSuccess: () => void;
}

const CLASSNAME = 'change-authors-dialog';
const FORM_NAME = 'change-authors-dialog-form';

/** This dialog allows our users to change authors */
export const ChangeAuthorsDialog = ({
    entityType,
    selectedEntities,
    selectionDescription,
    selectHelper,
    title,
    onCancel,
    onError,
    onSave,
    onSuccess,
    ...dialogProps
}: ChangeAuthorsDialog) => {
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { get } = usePlayDataAttributes();

    const [formStatus, setFormStatus] = React.useState(BaseLoadingStatus.initial);

    const { isLoading, isFetchingNextPage, refetch, isLoadingError, searchText, users, fetchNextPage, setSearchText } =
        usePlayUsers({
            hasRoleAssignment: true,
            sort: 'lastName',
        });

    const entityCount = selectedEntities.length;

    const authorCount = React.useMemo(
        () => uniq(selectedEntities.map((entity) => entity.createdById)).length,
        [selectedEntities],
    );

    const handleSave = async ({ author }: { author: PlayUser }) => {
        setFormStatus(BaseLoadingStatus.loading);

        try {
            await Promise.all(selectedEntities.map((entity) => onSave({ entityId: entity.id, authorId: author.id })));

            onSuccess();
        } catch (error) {
            onError();
        }

        setFormStatus(BaseLoadingStatus.idle);
    };

    return (
        <Dialog
            className={block()}
            footer={
                <FlexBox
                    className={padding('all', 'big')}
                    gap={Size.regular}
                    orientation={Orientation.horizontal}
                    vAlign={Alignment.right}
                >
                    <Button
                        emphasis={Emphasis.medium}
                        {...get({
                            element: `change-${entityType}-authors-dialog-button`,
                            type: 'cancel',
                            action: 'cancel',
                        })}
                        form={FORM_NAME}
                        onClick={onCancel}
                    >
                        {translateKey(GLOBAL.CANCEL)}
                    </Button>

                    <Button
                        form={FORM_NAME}
                        isDisabled={formStatus === BaseLoadingStatus.loading}
                        {...get({
                            element: `change-${entityType}-authors-dialog-button`,
                            type: 'save',
                            action: 'save',
                        })}
                        type="submit"
                    >
                        {translateKey(GLOBAL.SAVE)}
                    </Button>
                </FlexBox>
            }
            header={
                <Toolbar
                    label={
                        <Text as="h3" typography={Typography.title}>
                            {title}
                        </Text>
                    }
                />
            }
            isLoading={formStatus === BaseLoadingStatus.loading}
            size={Size.tiny}
            {...dialogProps}
        >
            <Form
                form={{ defaultValues: { author: '' } }}
                formProps={{ id: FORM_NAME }}
                useExternalActions
                onSubmit={handleSave}
                status={formStatus}
            >
                <Text as="p" typography={Typography.body1}>
                    {selectionDescription({ authorCount, entityCount })}
                </Text>

                <FormSelect
                    label={translateKey(GLOBAL.SELECT_AUTHOR)}
                    name="author"
                    choices={users}
                    isLoading={isLoading}
                    isLoadingMore={isFetchingNextPage}
                    isRequired
                    searchText={searchText}
                    selectProps={{ helper: selectHelper }}
                    getValueId={(user) => user.id}
                    getValueName={(user) => user.fullName}
                    hasLoadingError={isLoadingError}
                    onInfiniteScroll={fetchNextPage}
                    onRetryLoading={refetch}
                    onSearch={setSearchText}
                />
            </Form>
        </Dialog>
    );
};
