import once from 'lodash/once';
import throttle from 'lodash/throttle';

import { DEBOUNCE_DELAY } from '@lumapps/constants';

/**
 * Attach event listener on an element to emulate link activation (on click + on space/enter pressed).
 * Can also attach event listener on first mouse enter and focus to preload stuff.
 *
 * @example
 * ```js
 *      const ref = useRef(null)
 *
 *      useEffect(() => watchLinkActivation({ element: ref.current, onActivate: console.log }), [ref]);
 *
 *      <Button ref={ref} />
 *```
 *
 * @param params.element Element to listen click/keypress onto.
 * @param params.onActivate Callback to be called on link activation (click/enter pressed/space pressed).
 * @param params.onPreActivate Callback to be called on link first focus/hover.
 * @return a callback to detach the event listeners.
 */
export function watchLinkActivation(params: {
    element: HTMLElement;
    onActivate: (openInNewTab: boolean, evt: Event) => void;
    onPreActivate?: () => void;
}) {
    const onClick = throttle((evt: MouseEvent) => {
        const isTargetBlank = params.element.getAttribute('target') === '_blank';
        // Open in a new tab if the link has target _blank or if it was clicked with the middle button, with ctrl key or
        // the meta key
        const openInNewTab = isTargetBlank || evt.ctrlKey || evt.metaKey || evt.button === 1;

        evt.preventDefault();
        params.onActivate(openInNewTab, evt);
    }, DEBOUNCE_DELAY);

    const onKeyPress = throttle((evt: KeyboardEvent) => {
        // Activate link on Enter or Space key press.
        if (evt.key !== 'Enter' && evt.key !== ' ') {
            return;
        }
        const isTargetBlank = params.element.getAttribute('target') === '_blank';
        // Open in a new tab if the link has target _blank or if it was pressed with the ctrl key or the meta key
        const openInNewTab = isTargetBlank || evt.ctrlKey || evt.metaKey;

        evt.preventDefault();
        params.onActivate(openInNewTab, evt);
    }, DEBOUNCE_DELAY);

    // Callback once on first mouseenter/focus
    const onFirstMouseEnterOrFocus = params.onPreActivate && once(params.onPreActivate);

    params.element.addEventListener('click', onClick);
    params.element.addEventListener('keypress', onKeyPress);
    if (onFirstMouseEnterOrFocus) {
        params.element.addEventListener('mouseenter', onFirstMouseEnterOrFocus);
        params.element.addEventListener('focus', onFirstMouseEnterOrFocus);
    }
    return () => {
        params.element.removeEventListener('click', onClick);
        params.element.removeEventListener('keypress', onKeyPress);
        if (onFirstMouseEnterOrFocus) {
            params.element.removeEventListener('mouseenter', onFirstMouseEnterOrFocus);
            params.element.removeEventListener('focus', onFirstMouseEnterOrFocus);
        }
    };
}
