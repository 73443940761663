/* @ngInject */
function DocumentProviderFactory($resource, Config) {
    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/document/provider/:methodKey`,
        {},
        {
            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },
        },
    );
}

angular.module('Factories').factory('DocumentProviderFactory', DocumentProviderFactory);

export { DocumentProviderFactory };
