import { get } from '@lumapps/constants';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { CustomElementParser, DeserializeOptions } from '@lumapps/wrex/serialization/markdown/fromMarkdown/types';

import { createUserMention } from '../../utils/createUserMention';

const constants = get();

/**
 * Parse user mention embedded inside.
 */
const parseUserMention: CustomElementParser = {
    parse: ({ context }, match) => {
        const [fullMatch, fullNameRaw, id] = match;
        const start = match.index;
        const { length } = fullMatch;
        if (!length || start === undefined) {
            return undefined;
        }

        const fullName =
            getUserFullName(context?.mentionsDetails?.find((mD: any) => mD.uid === id), constants.userLang) ||
            fullNameRaw.replace(/-/g, ' ');

        const mention = createUserMention({ id, fullName });

        return { parsedNode: mention, start, end: length + start };
    },
};

export const USER_MENTION_REGEXP = '@\\[([^:]*):([^\\]]+)]';

export const USER_MENTION_CUSTOM_ELEMENTS: DeserializeOptions['customElements'] = {
    /**
     * Match text before a mention, then the mention full name and the mention id.
     * example: {text before}@[{mention fullName}:{mention id}].
     */
    [USER_MENTION_REGEXP]: parseUserMention,
};
