import get from 'lodash/get';

import { first } from '@lumapps/utils/iterable/first';
import { Editor, NodeEntry, Point, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import { isSelectionEmpty } from '@lumapps/wrex/slate/utils/isSelectionEmpty';

import { FileAttributes, FileEditor, FileEditorOptions } from '../types';
import { createFile } from './createFile';
import { isFile } from './isFile';
import { uploadFile } from './uploadFile';

/**
 * Insert file after editor selection.
 * Returns the final insert position of the node. Useful for targeting the inserted node.
 */
export function insertFile(
    options: FileEditorOptions,
    editor: ReactEditor & FileEditor,
    attributes: FileAttributes,
    file: File,
    point?: Point | undefined,
    insertOnSelection = true,
) {
    const at = point || editor.selection?.focus;
    const after = getSiblingPath([get(at, ['path', 0]) as number], 'after');
    const canInsertOnSelection = at && isSelectionEmpty(editor, at.path) && insertOnSelection;
    const node = createFile({
        createdAt: new Date().toISOString(),
        name: file?.name,
        mimeType: file?.type,
        ...attributes,
        isLoading: true,
    });

    Transforms.insertNodes(editor, node, {
        at: canInsertOnSelection ? [at.path[0]] : after ?? [editor.children.length],
        select: true,
    });

    const [, firstPath] = first(Editor.nodes(editor, { at: editor.selection as Range, match: isFile })) as NodeEntry;

    const path = canInsertOnSelection ? [at.path[0]] : firstPath;

    focusAt(editor, path);

    if (file) {
        const pathRef = Editor.pathRef(editor, path);
        uploadFile(options, editor, file, pathRef).finally(() => {
            pathRef.unref();
        });
    }
    return path;
}
