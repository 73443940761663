import React, { useReducer } from 'react';

import isEmpty from 'lodash/isEmpty';

import { get } from '@lumapps/constants';
import { getCurrentContentId } from '@lumapps/contents/ducks/selectors';
import { TextField, Button, Theme } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { selectSpaceId } from '@lumapps/spaces/ducks/selectors';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { connectedUserSelector } from '@lumapps/user/ducks/selectors';
import { User } from '@lumapps/user/types';

import { sendEmail } from '../../api';
import { initialState, actions, reducer } from '../../ducks/slice';
import { WIDGET_CONTACT_FORM } from '../../keys';
import { LegacyContactFormProperties } from '../../types';

export interface ContactFormBlockProps {
    to: LegacyContactFormProperties['to'];
    legacyWidgetId: string;
    theme?: Theme;
}

export const ContactFormBlock: React.FC<ContactFormBlockProps> = ({ theme, to, legacyWidgetId }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { success, error } = useNotification();
    const contentId = useSelector(getCurrentContentId);
    const spaceId = useSelector(selectSpaceId);
    const currentUser = useSelector(connectedUserSelector) as User;
    const constants = get();
    const { translateKey } = useTranslate();

    /** Send the message to the given email adress */
    const onSubmit = async (event: any) => {
        event.preventDefault();
        dispatch(actions.request());
        try {
            await sendEmail({
                body: currentUser?.email ? `${currentUser?.email}<br/>${state.message.value}` : state.message.value,
                captcha: '',
                customerId: constants.customerId,
                replyTo: currentUser?.email,
                subject: state.subject.value,
                to,
                contentId: spaceId ?? contentId,
                widgetId: legacyWidgetId,
            });
            success({ translate: WIDGET_CONTACT_FORM.SEND_SUCCESS });
            dispatch(actions.success());
        } catch (exception) {
            error({ translate: WIDGET_CONTACT_FORM.SEND_ERROR });
            dispatch(actions.error());
        }
    };

    const handleChangeSubject = (value: string) => dispatch(actions.setSubject(value));
    const handleChangeMessage = (value: string) => dispatch(actions.setMessage(value));

    return (
        <form onSubmit={onSubmit}>
            <TextField
                label={translateKey(GLOBAL.SUBJECT)}
                value={state.subject.value}
                onChange={handleChangeSubject}
                hasError={!state.subject.isPristine && isEmpty(state.subject.value)}
                error={translateKey(GLOBAL.FIELD_IS_REQUIRED)}
                isRequired
                theme={theme}
            />
            <TextField
                label={translateKey(GLOBAL.MESSAGE)}
                value={state.message.value}
                onChange={handleChangeMessage}
                hasError={!state.message.isPristine && isEmpty(state.message.value)}
                error={translateKey(GLOBAL.FIELD_IS_REQUIRED)}
                isRequired
                theme={theme}
                multiline
            />
            <Button
                disabled={isEmpty(state.subject.value) || isEmpty(state.message.value) || state.isLoading}
                type="submit"
                theme={theme}
            >
                {translateKey(GLOBAL.SEND)}
            </Button>
        </form>
    );
};
