import React from 'react';

import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { useArticle } from '@lumapps/articles/hooks/useArticle';
import { communityPagesRoute } from '@lumapps/communities/routes';
import { ContentState } from '@lumapps/contents/ducks/types';
import { useCurrentEventPage } from '@lumapps/events/hooks/useCurrentEventPage';
import { CommentButton } from '@lumapps/reactions/components/CommentButton';
import { LikeButton, LikeButtonProps } from '@lumapps/reactions/components/LikeButton';
import { ReactionsBlock } from '@lumapps/reactions/components/ReactionsBlock';
import { asLikeState } from '@lumapps/reactions/utils/asLikeState';
import { Link as RouterLink, useLocation, useRouteMatching } from '@lumapps/router';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';
import { BlockReactions } from '@lumapps/widget-base/components/Block/BlockReactions';
import { BlockReactionVariants } from '@lumapps/widget-base/components/Block/BlockReactions/types';
import { ClientComputedProps } from '@lumapps/widget-base/types/client-computed';

import { LegacySocialMetadataProperties } from '../../types';

export interface ClientComputedSocialMetadataProps extends ClientComputedProps {
    /** Current content. */
    currentContent: ContentState;
    /** Widget properties. */
    properties?: LegacySocialMetadataProperties;
    /** Is the current content contain a comment widget ? */
    hasCommentWidget?: boolean;
    /** Method fetching liked status for a content. */
    fetchLikedStatus: (currentContentId: string) => void;
}

export const ClientComputedSocialMetadata: React.FC<ClientComputedSocialMetadataProps> = ({
    currentContent,
    properties = {},
    hasCommentWidget,
    fetchLikedStatus,
}) => {
    const { pathname, search } = useLocation();
    const isCommunityPage = useRouteMatching(communityPagesRoute);
    const article = useArticle();
    const event = useCurrentEventPage();
    const { id, comments, liked, likes, likesList, actionState } = currentContent;
    const { fields = [], style } = properties;

    if (isCommunityPage && isEmpty(fields)) {
        fields.push({ enable: true, name: 'follow' });
        fields.push({ enable: false, name: 'comment' });
    }

    const settings = {
        displayLikeButton: find(fields, ['name', 'like'])?.enable,
        displayFollowButton: find(fields, ['name', 'follow'])?.enable,
    };
    const isCommentCountVisible = find(fields, ['name', 'comment'])?.enable && hasCommentWidget;

    React.useEffect(() => {
        if (
            // if liked status hasn't already been fetched (in which case the status would be `loading`, `loaded` or `error`)
            !includes(['loading', 'loaded', 'error'], currentContent?.actionState?.fetch?.liked) &&
            // if there is no content, we do not want to do this API call
            Boolean(currentContent.id)
        ) {
            fetchLikedStatus(currentContent.id);
        }
    }, [currentContent, fetchLikedStatus]);

    if (article || event) {
        let like: LikeButtonProps | undefined;
        if (article?.id) {
            like = { resourceType: 'articles', resourceId: article.id, ...asLikeState(article) };
        }
        if (event?.id) {
            like = { resourceType: 'events', resourceId: event.id, ...asLikeState(event) };
        }
        return (
            <ReactionsBlock>
                {like && <LikeButton theme={style?.content?.theme} {...like} />}
                <CommentButton
                    theme={style?.content?.theme}
                    commentCount={article?.commentCount || event?.commentCount}
                    linkAs={RouterLink}
                    to={{ anchor: '#comments', path: pathname, query: search }}
                />
            </ReactionsBlock>
        );
    }
    if (!id) {
        return null;
    }

    return (
        <ErrorBoundary>
            <BlockReactions
                resourceId={id}
                resourceType="CONTENT"
                likes={likes}
                isLikeLoading={actionState?.like === 'loading'}
                isLiked={Boolean(liked)}
                likesList={likesList}
                comments={comments}
                displayOptions={{
                    variant: BlockReactionVariants.content,
                }}
                settings={settings}
                theme={style?.content?.theme}
                isCommentCountVisible={isCommentCountVisible}
                areNotificationsEnabled
            />
        </ErrorBoundary>
    );
};
