/* eslint-disable import/unambiguous */

import AVAILABLE_ICONS from '@lumapps/generate-icons/constants/icon-picker-list';

// eslint-disable-next-line require-jsdoc-except/require-jsdoc
function LsIconPickerControler() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The list of icons available for selection.
     *
     * @type {Array}
     */
    vm.AVAILABLE_ICONS = AVAILABLE_ICONS;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * When the value is emptied or changed.
     */
    function onChange() {
        vm.ngChange();
    }

    /////////////////////////////

    vm.onChange = onChange;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.ngChange = vm.ngChange || angular.noop;
        vm.allowClear = angular.isUndefined(vm.allowClear) ? true : vm.allowClear;

        // Backward for names breaking change.
        // [TODO] A backend script to save the new value.
        if (vm.ngModel === 'youtube-play') {
            vm.ngModel = 'youtube';
        }
    }

    init();
}

/////////////////////////////

/**
 * Allows to select an icon in the given list.
 *
 * @param  {string}    [label]    The text to display as the label of the lx-select control.
 * @param  {string}    ngDisabled Indicates if we want to disable the picker.
 * @param  {string}    ngModel    The selected icon name.
 * @return {Component} The icon picker.
 */
function LsIconPickerDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: LsIconPickerControler,
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            allowClear: '=?lsAllowClear',
            label: '@?lsLabel',
            ngChange: '&?',
            ngDisabled: '=?',
            ngModel: '=',
        },
        templateUrl: '/client/common/modules/icon-picker/views/icon-picker.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsIconPicker', LsIconPickerDirective);

/////////////////////////////

export { LsIconPickerDirective };
