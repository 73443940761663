import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ButtonProps, Text, TextProps } from '@lumapps/lumx/react';
import { Route } from '@lumapps/router';

import { useSelectableCardContext } from '../../hooks/useSelectableCardContext';
import { CLASSNAME } from './constants';

interface SelectableCardActionProps {
    /** a custom classname to be given to the component */
    className?: string;
    /** click callback in case of a button */
    onClick?: ButtonProps['onClick'] | React.HTMLProps<'button'>['onClick'];
}

export interface SelectableCardMainActionProps extends SelectableCardActionProps {
    /** the component to be used */
    as?: React.ElementType;
    /** whether the action is disabled */
    disabled?: boolean;
    /** in case of link, href */
    href?: string;
    /** whether the action should keep the way it looks as a link or not */
    isLinkStyleHidden?: boolean;
    /** props to be given to the Text element */
    textProps?: Partial<TextProps>;
    /** Target route when using a router link */
    to?: Route;
    /** ref to forward */
    ref?: React.Ref<HTMLAnchorElement>;
    /** <a> target attribute */
    target?: HTMLAnchorElement['target'];
}

/**
 * This action will be considered as the whole card in term of hover/focus state.
 * A SelectableCard should NOT have several main action! (the component will throw if there are)
 */
export const MainAction: React.FC<SelectableCardMainActionProps> = React.forwardRef(
    (
        {
            as: Component = 'button',
            className = '',
            children,
            disabled = false,
            isLinkStyleHidden = true,
            textProps,
            ...forwardedProps
        },
        ref,
    ) => {
        const { setIsHover } = useSelectableCardContext('MainAction');
        const { element } = useClassnames(CLASSNAME);

        const props = {
            ...forwardedProps,
            disabled,
            className: element('main-action', { 'no-link-style': isLinkStyleHidden, disabled }, [className]),
            onMouseEnter: () => setIsHover(true),
            onMouseLeave: () => setIsHover(false),
        };

        if (Component === 'button') {
            return (
                <Component type="button" {...props} ref={ref as React.LegacyRef<HTMLButtonElement>}>
                    {/* Text is needed here to make sure to display the button content like any other text */}
                    <Text as="span" {...textProps}>
                        {children}
                    </Text>
                </Component>
            );
        }

        return (
            <Component {...props} ref={ref}>
                {children}
            </Component>
        );
    },
);
MainAction.displayName = 'SelectableCard.MainAction';

export interface SelectableCardSecondaryActionProps extends SelectableCardActionProps {
    /** the component to be used - deliberately mandatory for secondary action to force the user to give a component */
    as: React.ElementType;
    /** children */
    children?: React.ReactNode;
}

/**
 * Each secondary action can trigger a specific behavior without any a11n conflict with the card/main action
 * You can use as many secondary actions as you like
 */
export const SecondaryAction = <T,>({
    as: Component,
    className,
    ...forwardedProps
}: T & SelectableCardSecondaryActionProps): React.ReactElement => {
    useSelectableCardContext('SecondaryAction');
    const { element } = useClassnames(CLASSNAME);

    return <Component className={element('secondary-action', [className])} {...forwardedProps} />;
};
SecondaryAction.displayName = 'SelectableCard.SecondaryAction';
