import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Renders a simple text field wrapper (usually an `<Input />`) with a label.
 */
export class TextField extends React.PureComponent {
    static propTypes = {
        /** Whether it should have a background or not. */
        background: propTypes.bool,
        /** Input element. */
        children: propTypes.node,
        /** Label displayed on top. */
        label: propTypes.string,
        /** Suffix shown next to the input element (usually describes the unit of the value). */
        suffix: propTypes.string,
    };

    render() {
        const { children, label, suffix, background = true } = this.props;

        return (
            <div className={classNames('custom-text-field', !background && 'custom-text-field--no-background')}>
                {label && <span className="custom-text-field__label">{label}</span>}
                <div className="custom-text-field__input">
                    <div className="custom-text-field__transclude">{children}</div>
                    {suffix && <span className="custom-text-field__suffix">{suffix}</span>}
                </div>
            </div>
        );
    }
}
