import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

const playGalleryRoute: Route = {
    appId: AppId.frontOffice,
    path: `/${URL_PREFIX}/play-video-gallery`,
};

interface GetPlayGalleryRouteProps extends Omit<Route, 'appId' | 'mobilePageDescriptor' | 'path'> {
    /** Instance slug */
    instanceSlug?: Route['instanceSlug'];
}

const getPlayGalleryRoute = ({ instanceSlug }: GetPlayGalleryRouteProps = {}): Route => ({
    ...playGalleryRoute,
    ...(instanceSlug && { instanceSlug }),
});

interface GetPlayGalleryRouteWithMobilePageDescriptor extends GetPlayGalleryRouteProps {
    /** Instance Id */
    instanceId: string;
}

const getPlayGalleryRouteWithMobilePageDescriptor = ({
    instanceId,
    ...routeProps
}: GetPlayGalleryRouteWithMobilePageDescriptor): Route => ({
    ...getPlayGalleryRoute(routeProps),
    /**
     * The mobilePageDescriptor key is used in the Mobile Webview React App to trigger
     * an event to the host mobile app indicating which page should be opened next.
     *
     * @see https://we.lumapps.com/battlefront/ls/content/5831209251635200/react-webview-app
     */
    mobilePageDescriptor: { type: 'video-gallery', instanceId },
});

export { getPlayGalleryRoute, getPlayGalleryRouteWithMobilePageDescriptor, playGalleryRoute };
