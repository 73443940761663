/* istanbul ignore file */
import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { EventPickerDialogProps } from '../../types';

const EventPickerDialogLazy = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'event-picker-dialog' */
            './EventPickerDialog'
        ),
);

const EventPickerDialog: React.FC<EventPickerDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            {(setShouldMount) => <EventPickerDialogLazy onVisibilityChange={setShouldMount} {...props} />}
        </DelayedSuspense>
    );
};

export { EventPickerDialog };
