import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { learningFilledImage } from '@lumapps/lumx/custom-icons';
import { Emphasis, IconButton, Size, IconButtonProps } from '@lumapps/lumx/react';
import { useLinkProps } from '@lumapps/router';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { SCOPE } from '../../constants';
import { learningRoute } from '../../routes';

export interface LearningMenuProps {
    styles?: Record<string, any>;
}

export const LearningMenu: React.FC<LearningMenuProps> = ({ styles }) => {
    const linkProps = useLinkProps({ route: learningRoute });
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    return (
        <IconButton
            emphasis={Emphasis.low}
            icon={learningFilledImage}
            label={translateKey(GLOBAL.LEARNING)}
            color={styles?.themeColor}
            size={Size.m}
            {...linkProps}
            target={linkProps.target as IconButtonProps['target']}
            {...get({ element: 'header-icon', action: 'open-learning-page' })}
        />
    );
};
