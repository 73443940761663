import { AskAiFeedbackReasonTypes, AskAiFeedbackTypes } from '@lumapps/ask-ai/types';
import { NavigationItemType } from '@lumapps/navigation/types';
import { SearchType, SelectedFacets } from '@lumapps/search/types';

export enum TrackingEventType {
    CLICK_ACTION = 'click_action',
    NOTIFICATION_CLICK = 'notification_click',
    NAVIGATION_CLICK = 'navigation_click',
    SEARCH = 'search',
    SEARCH_CLICK = 'search_click',
    SEARCH_CLICK_PREVIEW = 'search_click_preview',
    SEARCH_FILTER = 'search_filter',
    SEARCH_LOAD_MORE = 'search_load_more',
    VIEW = 'view',
}

export enum TrackingAppType {
    IOS = 'ios',
    ANDROID = 'android',
    RESPONSIVE = 'responsive',
    DESKTOP = 'desktop',
    EXTERNAL = 'external',
}

export enum TrackingReferrerType {
    EMAIL = 'email',
    NOTIFICATION = 'notification',
    LINK = 'link',
    MOBILE_APP = 'mobile-app',
}

export enum TargetType {
    ARTICLE = 'article',
    ASK_REACTION = 'ask_reaction',
    COMMENT = 'comment',
    COMMUNITY = 'community',
    CONTENT = 'content',
    DIRECTORY_ENTRY = 'directory_entry',
    EVENT = 'event',
    EXTERNAL_LINK = 'external_link',
    GROUP = 'group',
    LEARNING_PATH = 'learning_path',
    MEDIA = 'media',
    MOBILE = 'mobile',
    NAVIGATION_ITEM = 'navigation_item',
    ORGANIZATION = 'organization',
    PLAY_VIDEO = 'play_video',
    PLAYLIST = 'playlist',
    POST = 'post',
    PROGRAM = 'program',
    SEARCH = 'search',
    SEARCH_EXTENSION = 'search_extension',
    SEARCH_REACTION = 'search_reaction',
    SHAREABLE_CONTENT = 'shareable_content',
    SITE = 'site',
    TRAINING_COURSE = 'training_course',
    USER = 'user',
}

export enum SearchDocumentType {
    PLAY_VIDEO = 'video',
    PLAY_PLAYLIST = 'video-playlist',
    USER = 'user',
    CONTENT = 'content',
    POST = 'post',
    COMMUNITY = 'community',
    ARTICLE = 'entity.article',
    EVENT = 'event',
    DIRECTORY_ENTRY = 'directoryentry',
    MEDIA = 'media',
    EXTENSION = 'extension',
    GCS_ARTICLE = 'articles',
    GCS_DIRECTORY_ENTRY = 'directory_entries',
    GCS_POST = 'posts',
    GCS_COMMUNITY = 'communities',
    GCS_USER = 'users',
}

/** /!\ this enum is a copy of LINK_PREVIEW_RESOURCE_TYPES from wrex/link-preview package. Make sure it's ALWAYS 100% the same */
export enum NotificationTargetType {
    ARTICLE = 'article',
    COMMUNITY = 'community',
    CONTENT = 'content',
    EVENT = 'event',
    LEARNING_PATH = 'learning_path',
    MICRO_APP = 'micro_app',
    PLAY_VIDEO = 'play_video',
    PLAY_VIDEO_PLAYLIST = 'play_video_playlist',
    POST = 'post',
    TRAINING_COURSE = 'training_course',
}

/** /!\ this enum is a copy of CHANNEL_TYPES from journeys/core package. Make sure it's ALWAYS 100% the same */
export enum NotificationChannelType {
    EMAIL = 'email',
    MARKETPLACE_EXTENSION = 'marketplace_extension',
    MOBILE_PUSH = 'mobile_push',
}

export type LumappsTrackingEventAction = {
    traceId: string;
    type: TrackingEventType;
    query: string;
    resultCount: number;
    searchEngine: string | null;
    extensionId?: string;
    sortedBy?: string;
    tabId?: string;
    promotedIds?: string[];
    filters?: SelectedFacets;
    position?: number;
    isPromoted?: boolean;
    targetType?: string;
    targetId?: string;
    title?: string;
    url?: string;
    thumbnail?: string;
};

export type LumappsTrackingSearchClickEventAction = {
    type: TrackingEventType;
    query: string;
    tabId?: string;
    resultCount: number;
    isPromoted: boolean;
    position: number;
    targetId: string;
    targetType: SearchDocumentType;
    traceId: string;
};

export type LumappsTrackingClickAction = {
    type: TrackingEventType.CLICK_ACTION;
    targetId: string;
    targetType: TargetType;
    url?: string;
    thumbnail?: string;
    title?: string;
};

export type LumappsTrackingNotificationClickAction = {
    type: TrackingEventType.NOTIFICATION_CLICK;
    targetId: string;
    targetType: NotificationTargetType;
    url?: string;
    title?: string;
    notificationId: string;
    channelType: NotificationChannelType;
};
// This type will include all tracking actions possible from WREX link preview
export type LumappsTrackingLinkPreviewAction = LumappsTrackingNotificationClickAction;

export type LumappsTrackingImpression = {
    targetId: string;
    targetType: TargetType;
    title?: string;
};

export type LumappsTrackingViewAction = {
    type: TrackingEventType.VIEW;
    targetId: string;
    targetType: TargetType;
    url?: string;
    title?: string;
};

export type LumappsTrackingAskReaction = {
    type: TargetType.ASK_REACTION;
    reactionType: AskAiFeedbackTypes;
    traceId: string;
    feedbackReason?: AskAiFeedbackReasonTypes;
    comment?: string;
};

export type LumappsTrackingSearchReaction = {
    type: TargetType.SEARCH_REACTION;
    reactionType: AskAiFeedbackTypes;
    traceId: string;
    searchType: SearchType;
};

export interface LumappsTrackingEventContext {
    siteId?: string;
    app: {
        type?: TrackingAppType;
        branding?: string;
        version: string;
        build?: string;
    };
    locale?: string;
    page?: {
        path?: string;
        referrer?: string;
        search?: string;
        title?: string;
        url?: string;
    };
    referrer?: {
        type?: TrackingReferrerType;
        name?: string;
        url?: string;
        link?: string;
    };
    screen?: {
        width?: string;
        height?: string;
        density?: string;
    };
    timezone?: string;
}

export interface NavigationItemProperties {
    depth: number;
    id: string;
    position: number;
    siblingCount: number;
    title: string;
    type: NavigationItemType;
}

export interface LumappsTrackingNavigationNodeClickAction {
    navigationItemProperties: NavigationItemProperties;
    targetId: string;
    targetType: TargetType.NAVIGATION_ITEM;
    type: TrackingEventType.NAVIGATION_CLICK;
}

export type LumappsTrackingActionType =
    | LumappsTrackingSearchReaction
    | LumappsTrackingAskReaction
    | LumappsTrackingClickAction
    | LumappsTrackingEventAction
    | LumappsTrackingNavigationNodeClickAction
    | LumappsTrackingViewAction
    | LumappsTrackingLinkPreviewAction;

export interface LumappsTrackingEvent {
    action: LumappsTrackingActionType;
    sessionId: string;
    performedAt: string;
    context: LumappsTrackingEventContext;
}

export interface Config {
    apiUrl: string;
}

export interface LumappsPageViewEventPayload {
    id?: string;
    status?: string;
}

export interface LumappsTrackEventPayload {
    action: LumappsTrackingActionType;
}

export interface SessionId {
    id: string;
    ttl: string;
}
