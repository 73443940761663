export const UNIQUE_SEPARATOR = '__';

/**
 * Retrieve the key for a given item
 * @param item
 * @param keyPropName
 * @returns key
 */
export const getKeyForItem = (item: any, keyPropName?: string): string => {
    return keyPropName ? item[keyPropName] : item;
};

/**
 * Generates a list of unique items by going through each item and validating that
 * the ids passed in are unique among list. This can happen for edge cases like `Froala`
 * where ids can be repeated.
 * @param items
 * @param keyPropName
 * @returns
 */
export const generateUniqueItems = (items: any[], keyPropName?: string): any[] => {
    const counter: Record<string, number> = {};

    return items.map((item) => {
        const itemKey = getKeyForItem(item, keyPropName);

        /**
         * With `counter`, we are keeping track of how many items there are with the same id.
         * If there are more than one for the same id, then `counter[itemKey]` will increase
         * and the new item key will have a suffix in order to make it unique
         */
        if (counter[itemKey] === undefined) {
            counter[itemKey] = 0;
        }

        counter[itemKey] += 1;

        const newItemKey = counter[itemKey] > 1 ? `${itemKey}${UNIQUE_SEPARATOR}${counter[itemKey]}` : itemKey;

        return keyPropName
            ? {
                  ...item,
                  [keyPropName]: newItemKey,
              }
            : newItemKey;
    });
};

/**
 * Takes an item that had a unique id change and reverts the changes so that it can be returned
 * to the parent component without the need for them to be formatting the data.
 * @param item
 * @param keyPropName
 * @returns reverted item
 */
export const revertUniqueId = (item: any, keyPropName?: string) => {
    const itemKey = getKeyForItem(item, keyPropName).split(UNIQUE_SEPARATOR)[0];

    return keyPropName
        ? {
              ...item,
              [keyPropName]: itemKey,
          }
        : itemKey;
};

/**
 * Retrieves the array where the item is present. Usually used
 * when the list is displayed horizontally.
 * @param id
 * @returns number
 */
export const getItemArray = (id: string): number => {
    try {
        return parseInt(id.split('--')[1], 10);
    } catch (excp) {
        return 0;
    }
};
