import React from 'react';

import { Navigation as NavigationWrapperComponent } from '@lumapps/lumx-navigation/components/Navigation';
import { Theme } from '@lumapps/lumx/react';
import { Navigation, NavigationItem } from '@lumapps/navigation/types';

import { SubNavigationElement } from './SubNavigationElement';

export interface SubNavigationProps {
    theme?: Theme;
    subNavigation: Navigation;
    showParent: boolean;
    currentContentId?: string;
    defaultOpenNodes: Record<string, boolean>;
}

const CLASSNAME = 'sub-navigation';
const SCOPE = 'widget-sub-navigation';

/**
 * Sub Navigation component. Takes a Navigation as props and render the elements and children recursively
 */
export const SubNavigation: React.FC<SubNavigationProps> = ({
    theme,
    subNavigation,
    showParent,
    currentContentId = '',
    defaultOpenNodes,
}) => {
    // Extract the selected node in the widget (manually selected or current content)
    const selectedNode = subNavigation.children?.[0];
    const children = selectedNode?.children;

    return children && children.length > 0 ? (
        <NavigationWrapperComponent className={CLASSNAME} theme={theme} scope={SCOPE}>
            {
                /** If we show parent, display it as the first element of the list, but without children */
                showParent && (
                    <SubNavigationElement
                        node={{ ...selectedNode, children: undefined }}
                        currentContentId={currentContentId}
                        isParent
                        defaultOpenNodes={{}}
                    />
                )
            }
            {children.map((node: NavigationItem) => (
                <SubNavigationElement
                    key={node.id}
                    node={node}
                    currentContentId={currentContentId}
                    defaultOpenNodes={defaultOpenNodes}
                />
            ))}
        </NavigationWrapperComponent>
    ) : null;
};

SubNavigation.displayName = 'SubNavigation';
