import React from 'react';

import { classnames, visuallyHidden } from '@lumapps/classnames';
import { HeaderLabel, HeaderLabelProps } from '@lumapps/lumx-texts/components/HeaderLabel';
import { Typography, Toolbar, ToolbarProps } from '@lumapps/lumx/react';

import './index.scss';

export interface PageHeaderProps extends Omit<HeaderLabelProps, 'title' | 'headerTitleId'> {
    /** The id for this page, usually used for tracking purposes */
    id: string;
    /** Page title */
    title: string;
    /**
     * Whether the default title is visually hidden or not. If true, then the title
     * will be visually hidden from the page, but still displayed
     */
    isTitleVisuallyHidden?: boolean;
    /** actions displayed on the right hand side of the header */
    actions?: React.ReactNode;
    /** additional props for the wrapper when the header uses actions */
    wrapperProps?: Partial<ToolbarProps>;
    keepDefaultTypography?: boolean;
}

const CLASSNAME = 'lumx-front-office-page-header';

/**
 * Page header for front office pages
 * @family Front office
 */
export const PageHeader: React.FC<PageHeaderProps> = ({
    id,
    title,
    isTitleVisuallyHidden = false,
    titleProps = {},
    subtitleProps = {},
    actions,
    wrapperProps = {},
    goBackButtonProps,
    subtitle,
    thumbnail,
    parentTitle,
    keepDefaultTypography = false,
    ...props
}) => {
    const typography =
        !keepDefaultTypography && (subtitle || goBackButtonProps || parentTitle)
            ? Typography.headline
            : Typography.display1;

    const Header = (
        <HeaderLabel
            title={title}
            {...props}
            headerTitleId={`${id}-front-office-title`}
            headerSubtitleId={`${id}-front-office-subtitle`}
            subtitle={subtitle}
            goBackButtonProps={goBackButtonProps}
            thumbnail={thumbnail}
            parentTitle={parentTitle}
            titleProps={{
                ...titleProps,
                typography,
                className: classnames(
                    CLASSNAME,
                    !actions && props.className,
                    {
                        [visuallyHidden()]: isTitleVisuallyHidden,
                    },
                    titleProps.className,
                ),
            }}
            subtitleProps={{
                ...subtitleProps,
                typography: Typography.body1,
            }}
        />
    );

    if (!actions) {
        return Header;
    }

    return (
        <Toolbar
            label={Header}
            after={actions}
            {...wrapperProps}
            className={classnames(CLASSNAME, props.className, wrapperProps.className)}
        />
    );
};
