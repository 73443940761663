function LsStepNavController() {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the component classes.
     *
     * @return {Array} The list of classes.
     */
    function getClasses() {
        const classes = [];

        if (parseInt(vm.step.index, 10) === parseInt(vm.activeIndex, 10)) {
            classes.push('ls-step-nav--is-active');
        }

        if (vm.step.isValid === true) {
            classes.push('ls-step-nav--is-valid');
        } else if (vm.step.isValid === false) {
            classes.push('ls-step-nav--has-error');
        }

        if (vm.step.isEditable) {
            classes.push('ls-step-nav--is-editable');
        }

        if (vm.step.isOptional) {
            classes.push('ls-step-nav--is-optional');
        }

        return classes;
    }

    /**
     * Initialize the controller.
     *
     * @param {Object} parent The parent controller.
     */
    function init(parent) {
        vm.parent = parent;
    }

    /////////////////////////////

    vm.getClasses = getClasses;
    vm.init = init;
}

function LsStepNavDirective() {
    'ngInject';

    function link(scope, el, attrs, ctrls) {
        ctrls[0].init(ctrls[1]);
    }

    return {
        bindToController: true,
        controller: LsStepNavController,
        controllerAs: 'vm',
        link,
        replace: true,
        require: ['lsStepNav', '^lsStepper'],
        restrict: 'E',
        scope: {
            activeIndex: '@lsActiveIndex',
            step: '=lsStep',
        },
        templateUrl: '/client/common/modules/stepper/views/step-nav.html',
        transclude: false,
    };
}

/////////////////////////////

angular.module('Directives').directive('lsStepNav', LsStepNavDirective);

/////////////////////////////

export { LsStepNavDirective };
