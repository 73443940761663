import React, { PureComponent } from 'react';
import { bool, func, string } from 'prop-types';

import { Dialog, Toolbar } from '@lumapps/lumx/react';

import cloneDeep from 'lodash/cloneDeep';

import { stringOrNumber } from '../../../types';

import { Button } from '../../buttons';
import { InputTextField } from '../../inputs';

import { getInputLanguage, translate as t } from '../../../../translations';

import { InputLanguageMenu } from '../../../shared/input-language-menu/InputLanguageMenu';

/**
 * Simple dialog for text input.
 */
export class InputTextFieldDialog extends PureComponent {
    static propTypes = {
        /** Display input language selector. */
        canChangeLanguage: bool,
        /** Input label. */
        label: string.isRequired,
        /** Disable inputs and buttons. */
        loading: bool,
        /** Minimum input length. */
        min: stringOrNumber,
        /** Wheter or not the content must be different to validate. */
        mustDiffer: bool,
        /** Input name. */
        name: string.isRequired,
        /** Called when closing dialog. */
        onClose: func.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onValidate: func.isRequired,
        /** Should open dialog. */
        open: bool,
        /** Dialog title. */
        title: string.isRequired,
        /** Input initial value. */
        value: string,
    };

    static defaultProps = {
        canChangeLanguage: false,
        loading: false,
        min: 0,
        mustDiffer: false,
        open: true,
        value: '',
    };

    constructor(props) {
        super(props);

        this._INITIAL_STATE = {
            inputLanguage: getInputLanguage(),
            loading: false,
        };
        this.state = cloneDeep(this._INITIAL_STATE);

        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
    }

    /**
     * Called when value of text input or language change.
     *
     * @param {*}      value Value.
     * @param {string} key   State attribute name.
     */
    handleChange(value, key) {
        this.setState({
            [key]: value,
        });
    }

    /**
     * Reset state and call onClose props method.
     */
    handleClose() {
        const { onClose } = this.props;

        this.setState(cloneDeep(this._INITIAL_STATE));
        onClose();
    }

    /**
     * Handle key press.
     *
     * @param {Event} evt Key press event.
     */
    handleKeyPress(evt) {
        // 'Enter' key.
        if (evt.keyCode === 13) {
            this.handleValidate();
        }
    }

    /**
     * Call props onValidate method with selected value and language.
     */
    handleValidate() {
        const { name: inputName, onValidate } = this.props;
        const { [inputName]: value, inputLanguage: language } = this.state;

        onValidate({
            [inputName]: value,
            language,
        });
    }

    render() {
        const {
            canChangeLanguage,
            loading,
            title,
            label,
            min,
            mustDiffer,
            name: inputName,
            open: openDialog,
            value: initValue,
            ...props
        } = this.props;
        const { [inputName]: value = initValue, inputLanguage: lang } = this.state;
        const isLongEnough = value && value.length >= min;
        const passContentCheck = isLongEnough && (!mustDiffer || value !== initValue);

        return (
            <Dialog
                isOpen={openDialog}
                header={
                    <Toolbar
                        label={t(title)}
                        after={
                            canChangeLanguage ? (
                                <InputLanguageMenu
                                    disabled={loading}
                                    name="lang"
                                    value={lang}
                                    onChange={this.handleChange}
                                />
                            ) : null
                        }
                    />
                }
                footer={
                    <>
                        <Button color="black" disabled={loading} size="m" variant="flat" onClick={this.handleClose}>
                            {t('GLOBAL.CANCEL')}
                        </Button>

                        <Button
                            color="primary"
                            disabled={!passContentCheck || loading}
                            size="m"
                            variant="flat"
                            onClick={this.handleValidate}
                        >
                            {t('GLOBAL.OK')}
                        </Button>
                    </>
                }
                isLoading={loading}
                {...props}
            >
                <InputTextField
                    focusOnMount
                    disabled={loading}
                    label={t(label)}
                    min={min}
                    name={inputName}
                    type="string"
                    value={value}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                />
            </Dialog>
        );
    }
}
