import React from 'react';

import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { TrainingPickerDialogProps } from '../../types';

const TrainingPickerDialogLazy = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'training-picker-dialog' */
            './TrainingPickerDialog'
        ),
);

const TrainingPickerDialog: React.FC<TrainingPickerDialogProps> = (props) => {
    const { isOpen } = props;

    return (
        <DelayedSuspense isDialog shouldLoad={isOpen}>
            {(setShouldMount) => <TrainingPickerDialogLazy onVisibilityChange={setShouldMount} {...props} />}
        </DelayedSuspense>
    );
};

export { TrainingPickerDialog };
export type { TrainingPickerDialogProps };
