import React, { useEffect } from 'react';

import { classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { AlertDialog, Kind, Text, Typography } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { Editor, Path, ReactEditor, useFocused, useSelected, useSlate } from '@lumapps/wrex/slate';
import { useInlineVoid } from '@lumapps/wrex/slate/utils/useInlineVoid';
import { withoutHistory } from '@lumapps/wrex/slate/utils/withoutHistory';
import { ElementRender } from '@lumapps/wrex/types';

import { TABLE } from '../../../constants';
import { WREX_TABLE } from '../../../keys';
import type { TableEditor, TableElement } from '../../../types';
import { Table } from '../../blocks/Table';
import { TableToolbar } from '../../blocks/TableToolbar';

const CLASSNAME = 'wrex-content-table';

export const EditableTable: ElementRender<TableElement, HTMLTableElement> = (props) => {
    const { elementRef, children, readOnly, element = {} as TableElement, ...forwardedProps } = props;

    const { get } = useDataAttributes(CLASSNAME);
    const { translateKey } = useTranslate();
    const editor = useSlate() as ReactEditor & TableEditor;
    const isFocused = useFocused();

    const ref = React.useRef(null);
    const [isTableToolbarOpen, setIsTableToolbarOpen] = React.useState(true);

    const isSelected = useSelected();

    const path = ReactEditor.findPath(editor, element as TableElement) as Path;
    const selectionIsOnlyInTable =
        isSelected &&
        Array.from(Editor.nodes(editor, { mode: 'highest', match: (n) => !Editor.isEditor(n) })).length === 1;

    const cellSelection = editor.getCellSelection();

    const [isConfirmDialogOpen, , closeConfirmDialog, openConfirmDialog] = useBooleanState(false);
    const { onDelete } = useInlineVoid(editor, element);
    const handleDelete = () => openConfirmDialog();

    useEffect(() => {
        // Whether the user is leaving the table while a multi cell selection was active.
        const isLeavingTheTable = !isSelected && cellSelection.length > 0 && editor.isTableCurrentlySelected(path);

        // We need to update the cell selection everytime the selection is moving
        // within the table AND when we are leaving it.
        // It is better to update only on these particular casPes to avoid unnecessery calls to this function.
        if (isLeavingTheTable || isSelected) {
            withoutHistory(editor, () => editor.updateCellSelection(path));
        }
    }, [cellSelection, cellSelection.length, editor, editor.selection, isSelected, path]);

    useEffect(() => {
        setIsTableToolbarOpen(selectionIsOnlyInTable);
    }, [selectionIsOnlyInTable, isFocused]);

    return (
        <>
            <Table
                contentEditable={readOnly ? false : undefined}
                {...forwardedProps}
                element={element}
                elementRef={mergeRefs([elementRef as any, ref]) as any}
                className={classnames({
                    'wrex-content-table--in-cell-selection-mode': isSelected && cellSelection.length,
                })}
            >
                {children}

                <TableToolbar
                    anchorRef={ref}
                    isOpen={!!isTableToolbarOpen}
                    onClose={() => setIsTableToolbarOpen(false)}
                    editor={editor}
                    handleDelete={handleDelete}
                />
            </Table>
            <AlertDialog
                kind={Kind.error}
                isOpen={isConfirmDialogOpen}
                title={translateKey(WREX_TABLE.DELETE_TABLE_TITLE_CONFIRMATION)}
                confirmProps={{
                    onClick: onDelete,
                    label: translateKey(GLOBAL.DELETE),
                    ...get({ element: 'button', action: 'confirm-delete-table' }),
                }}
                cancelProps={{
                    onClick: closeConfirmDialog,
                    label: translateKey(GLOBAL.CANCEL),
                    ...get({ element: 'button', action: 'cancel-delete-table' }),
                }}
            >
                <Text as="p" typography={Typography.body1}>
                    {translateKey(GLOBAL.WARNING_DELETE)}
                </Text>
            </AlertDialog>
        </>
    );
};
EditableTable.displayName = TABLE;
