import get from 'lodash/get';
import pick from 'lodash/pick';
import reject from 'lodash/reject';

import { angularApi } from '@lumapps/router/routers';
import { EVENTS_FF } from '@lumapps/events/ducks/selectors';
import { spaceView } from '@lumapps/spaces/routes';
import { RenderingType } from '@lumapps/communities/types'
import { EDIT_COMMUNITY_DIALOG_EVENT } from '@lumapps/community-configuration/constants';

function CommunityController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $window,
    Community,
    CommunityForm,
    CommunityTemplate,
    Config,
    ConfigTheme,
    ContentTemplate,
    Content,
    Features,
    Header,
    InitialSettings,
    Layout,
    ModuleAdmin,
    ModuleFront,
    Post,
    Translation,
    User,
    ReduxStore,
    Utils,
    Widget,
) {
    'ngInject';

    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The default parameters for the community post search.
     *
     * @type {Object}
     */
    const _defaultPostFilter = {
        action: 'COMMUNITY_PUBLISH',
        maxResults: 5,
        tags: [],
        type: [Config.AVAILABLE_CONTENT_TYPES.POST],
    };

    /**
     * Community edition dialog identifier.
     *
     * @type {string}
     */
    let _editionDialogKey;

    /**
     * The available tabs on the community page for the current user.
     *
     * @type {Array}
     */
    let _viewsAvailable = [];

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * A static content filter widget to handle the post filtering.
     *
     * @type {Object}
     */
    vm.contentFilterWidget = {
        component: {
            properties: {
                community: [Community.getCurrent().uid],
                hideSubheader: true,
                linkedToLocalWidget: true,
                linkedWidget: {
                    properties: {
                        widgetType: InitialSettings.WIDGET_TYPES.COMMUNITY_POST_LIST,
                    },
                },
                style: {
                    content: {
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                    },
                    global: {
                        boxShadow: 0,
                    },
                },
            },
            uuid: `community-filter-${Community.getCurrent().uid}`,
            widgetType: InitialSettings.WIDGET_TYPES.CONTENT_FILTER,
        },
    };

    /**
     * Indicates if a call is in progress.
     *
     * @type {boolean}
     */
    vm.isCallInProgress = false;

    /**
     * Indicates if the add new post FAB button is currently open.
     * Used in responsive mode to avoid opening default type new post dialog before viewing
     * other available post types in fab button.
     *
     * @type {boolean}
     */
    vm.isFabOpen = false;

    /**
     * Indicates if we are filtering.
     *
     * @type {boolean}
     */
    vm.isFiltering = false;

    /**
     * If new content is available.
     *
     * @type {boolean}
     */
    vm.newContentAvailable = false;

    /**
     * The current view template.
     *
     * @type {Object}
     */
    vm.currentTemplate = undefined;

    /**
     * If the community has a chat configured
     * @type {string}
     */
    vm.chatIsConfigured = false;

    /**
     * Indicates if a call is in progress.
     *
     * @type {boolean}
     */
    vm.isCallInProgress = false;

    /**
     * Indicates if the add new post FAB button is currently open.
     * Used in responsive mode to avoid opening default type new post dialog before viewing
     * other available post types in fab button.
     *
     * @type {boolean}
     */
    vm.isFabOpen = false;

    /**
     * Indicates if we are filtering.
     *
     * @type {boolean}
     */
    vm.isFiltering = false;

    /**
     * If new content is available.
     *
     * @type {boolean}
     */
    vm.newContentAvailable = false;

    /**
     * The current view template.
     *
     * @type {Object}
     */
    vm.currentTemplate = undefined;
    
    /**
     * The event dialog state.
     *
     * @type {boolean}
     */
    vm.isEventDialogOpen = false;

    function openEventDialog() {
        vm.isEventDialogOpen = true;
    }

    function closeEventDialog() {
        $scope.$apply(() => {
            vm.isEventDialogOpen = false; 
        });
    }

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.Features = Features;
    vm.Header = Header;
    vm.Layout = Layout;
    vm.ModuleAdmin = ModuleAdmin;
    vm.Post = Post;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;
    vm.getMarginTop = () => Utils.getMarginTop(Header, Features, Layout);

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Build available views (tabs in the sidebar).
     */
    function _setAvailableViews() {
        _viewsAvailable = Community.getAvailableViews(Community.getCurrent());
    }

    /**
     * Sets the FAB to create a new post in the community.
     */
    function _setCreatePostFab() {
        const community = Community.getCurrent();
        const postType = community.postTypes;

        vm.postTypesConfig = pick(Community.availablePostTypes, postType);

        // This one will be the main CTA of the FAB.
        vm.defaultPostType = Community.getFirstAvailablePostTypes(vm.postTypesConfig);

        // So exclude it from the list of sub CTA.
        vm.postTypesConfig = reject(vm.postTypesConfig, {
            value: vm.defaultPostType.value,
        });
    }

    /**
     * Switch the template to be displayed to the wanted one, if available.
     *
     * @param {string} target The wanted target.
     */
    function _setCurrentTemplate(target) {
        vm.currentTemplate = CommunityTemplate.getTemplateByName(Content.getCurrent(), target);
        Content.getCurrent().template = vm.currentTemplate;

        // Now that the template is set, fix global widgets by replacing current widgets by global widgets
        const globalWidgets = ContentTemplate.getElementList(Content.getCurrent(), 'widget', 'isGlobal', true);

        angular.forEach(globalWidgets, function forEachGlobalWidgets(contentGlobalWidget) {
            const globalWidget = _.find(Widget.getGlobalWidgets(), {
                id: contentGlobalWidget.id,
            });

            if (angular.isDefinedAndFilled(globalWidget)) {
                const { uuid } = contentGlobalWidget;

                Utils.swapObject(contentGlobalWidget, angular.fastCopy(globalWidget));
                contentGlobalWidget.uuid = uuid;
                // The global widget doesn't exists anymore, remove the properties.
            } else {
                contentGlobalWidget.isGlobal = false;

                delete contentGlobalWidget.id;
                delete contentGlobalWidget.instance;
                delete contentGlobalWidget.customer;
            }
        });
    }

    /**
     * Clean the controller.
     */
    function _destroy() {
        // The resolve function, that set the current community, is called before the destroy ...
        // ...(if we switch to another community). So when we switch to another community we do not...
        // ...want our community to be set to undefined. But we want to in some context (ex: quick post creation).
        if ($state.current.name !== 'app.front.community') {
            Community.setCurrent(undefined);
        }
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Open the create new post dialog.
     *
     * @param {string}  [type]         A type of post to create.
     * @param {boolean} [isDefaultFab] Indicates if the action is triggered by the default post type fab button.
     * @param {string}  [postContent]  Content of the post to pre-set.
     */
    function addNewPost(type, isDefaultFab, postContent) {
        if (isDefaultFab && Layout.breakpoint !== 'desk' && !vm.isFabOpen) {
            vm.isFabOpen = !vm.isFabOpen;

            return;
        }
        if (
            Features.hasFeature(EVENTS_FF) &&
            type === InitialSettings.POST_TYPES.EVENT &&
            Community.getCurrent().hasEventsEnabled
        ) {
            openEventDialog();
        } else {
            $rootScope.$broadcast('create-new-post__open', undefined, Community.getCurrent(), type, postContent);
        }

        vm.isFabOpen = false;
    }

    /**
     * Get community class names.
     *
     * @return {Array} An array of css classes.
     */
    function getCommunityClass() {
        const communityClass = [];
        const properties = Community.getCurrent().properties || {};

        if (angular.isDefinedAndFilled(properties.class)) {
            communityClass.push(ModuleFront.getModuleClass(properties.class));
        }

        return communityClass;
    }

    // ///////////////////////////

    vm.addNewPost = addNewPost;
    vm.getCommunityClass = getCommunityClass;
    vm.closeEventDialog = closeEventDialog;

    // ///////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    // ///////////////////////////

    /**
     * Watch for changes on the google calendar option to add or remove the associated tab.
     */
    $scope.$watch(() => get(Community.getCurrent(), 'hasCalendarId'), _setAvailableViews);

    /**
     * Watch for changes on the google drive option to add or remove the associated tab.
     */
    $scope.$watch(() => get(Community.getCurrent(), 'hasDriveFolder'), _setAvailableViews);

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    if (User.isGod()) {
        /**
         * Remove a temporary variable needed for the debug info.
         */
        $scope.$on('$destroy', () => {
            if (angular.isDefinedAndFilled($rootScope.COMMUNITY_ID)) {
                delete $rootScope.COMMUNITY_ID;
            }
        });
    }

    /**
     * When leaving the page with the admin community edition opened, reset the service.
     */
    $scope.$on('$stateChangeStart', () => {
        if (ModuleAdmin.isEditing.community) {
            CommunityForm.resetContentForm();
            ModuleAdmin.isEditing.community = false;
            $window.location.hash = '';
        }
    });

    /**
     * Update the current post with whatever we just saved in the create post modal.
     * This is useful for the post details page so the view stays up to date with the latest content.
     *
     * @param {Event}  evt  The original event triggering this method.
     * @param {Object} post The post that just got saved / edited.
     */
    $scope.$on('community__post-saved', (evt, post) => {
        const content = Content.getCurrent();
        // Only update the post if it's the current one.
        if (content && content.id === post.uid) {
            Content.setCurrent(post);
        }

        // Force update the calendar.
        if (post.postType === InitialSettings.POST_TYPES.EVENT) {
            $scope.$broadcast('widget-calendar-settings');
        }
    });

    /**
     * When a post is moved, delete this post from the post list of the community.
     *
     * @param {Event}  evt  The event triggering this method.
     * @param {Object} post The post moved.
     */
    $scope.$on('community__post-moved', (evt, post) => {
        const posts = Post.displayList(vm.listKeyPost);

        Utils.reject(posts, {
            uid: post.uid,
        });
    });

    /*
     * Whenever we switch view, update the current view so we can display the create post button or not.
     *
     * @param {Event}  evt  The original event triggering this method.
     * @param {string} view The name of the view the user is switching to.
     */
    $scope.$on('community__switch-view', (evt, view) => {
        if (angular.isUndefinedOrEmpty(view) || view === vm.currentView) {
            return;
        }

        vm.currentView = view;
    });

    /**
     * Called when community save is successful.
     *
     * @param {Event}  evt         The original event triggering this method.
     * @param {string} communityId Id of the edited community.
     */
    $scope.$on('admin-community-save-success', (evt, communityId) => {
        if (communityId === Community.getCurrent().id) {
            // If save is successful update current community.
            Community.setCurrent(Community.getCurrent(_editionDialogKey));
        }
    });

    /**
     * Watch edit community dialog close to stop the form watcher.
     *
     * @param {Event}  evt      The original event triggering this method.
     * @param {string} dialogId Id of the dialog being closed.
     */
    $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
        if (dialogId === ModuleAdmin.dialogKeys.community) {
            vm.isFabOpen = false;

            CommunityForm.resetContentForm();
            ModuleAdmin.isEditing.community = false;
            $window.location.hash = '';
        }
    });

    /**
     * Event that triggers the display of the community edition modal. Currently used from the new
     * settings menu on the new architecture side
     */
    $rootScope.$on(EDIT_COMMUNITY_DIALOG_EVENT, () => {
        window.location.hash = '';
    });

    /**
     * Remove subscription on controller destruction.
     */
    $scope.$on('$destroy', _destroy);

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const community = Community.getCurrent();

        if (angular.isUndefinedOrEmpty(community)) {
            return;
        }

        const { id, slug, renderingType } = community;

        /**
         * If a space is openned in the community legacy context,
         * we try to redirect to the space NGI interface
         */
        if (renderingType === RenderingType.space) {
            const translatedSlug = Translation.translate(slug);
            angularApi.redirect(
                spaceView({
                    params: { slug: translatedSlug, id, view: $stateParams.view, identifier: $stateParams.identifier },
                    anchor: window.location.hash
                })
            );
        }

        // Backward for the available post types.
        if (angular.isUndefined(community.postTypes)) {
            community.postTypes = Community.defaultPostTypes;
        }

        _setCreatePostFab();

        angular.extend(_defaultPostFilter, {
            contentId: community.uid,
            externalKey: community.uid,
        });

        vm.listKeyPost = `community-${community.uid}`;

        _editionDialogKey = `admin-settings-dialog-${community.uid}`;

        // Reset the current content.
        // TODO [max]: set to undefined and replace Content.getCurrent() in cell/row directive...
        // ... with CurrentContent = Content.getCurrent || Community.getCurrent || {} ?
        Content.setCurrent(Community.getCurrent());

        _setAvailableViews();

        // Set view.
        vm.currentView = Community.getDefaultLandingView($stateParams.view, _viewsAvailable);
        _setCurrentTemplate(vm.currentView);

        // Check user permission for creating new post.
        vm.userCanWrite = Community.isWriteable();

        Community.setAllNotificationsAsRead(vm.currentView);

        if (community && community.chat) {
            vm.chatIsConfigured = true;
        }

        // Used in the hotkeys / debug info.
        if (User.isGod()) {
            $rootScope.COMMUNITY_ID = community.uid;
        }
    }

    init();
}

// ///////////////////////////

angular.module('Controllers').controller('CommunityController', CommunityController);

export { CommunityController };
