import BaseApi from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';

import { CustomContentType } from '../types';

const MAX_RESULTS = 100;

export const contentApi = new BaseApi({ path: 'customcontenttype' });

export interface ListAPIParams {
    /** instance id */
    instance: string;
    /** how many results we want to retrieve */
    maxResults?: number;
    /** current language */
    lang: string;
    /** whether cache should not be considered */
    invalidateCache?: boolean;
    /**  the pattern used to filter the list by name */
    name?: string;
    /**  the list of fields that will be returned with the response */
    fields?: string;
}

/** Retrieves the list of custom content types for the current instance */
export const list = (params: ListAPIParams = { maxResults: MAX_RESULTS, instance: '', lang: '', name: '' }) => {
    /**
     * If this API is called with invalidateCache set to true, the idea is to avoid the caching policies
     * set by the backend so that we have the latest data available. In order to do so, we just add a
     * timestamp parameter that will make each request unique, therefore invalidating the cache on the backend side
     * and retrieving the latest data
     */
    if (params.invalidateCache) {
        Object.assign(params, {
            t: Date.now(),
            invalidateCache: undefined,
        });
    }

    return contentApi.get<ServerListResponse<CustomContentType>>('list', { params });
};

export interface GetMultiParams {
    uids: string[];
    fields?: string;
}

export const getMulti = (params: GetMultiParams) => {
    return contentApi.get<ServerListResponse<CustomContentType>>('getMulti', { params });
};

export const deleteMulti = (params: { uid: string[] }) => {
    return contentApi.delete('deleteMulti', { params });
};

export const save = (params: any) => {
    return contentApi.post<any>('save', params);
};
