import { get } from '@lumapps/constants';

/**
 * /!\ There is lot's of Play specific code living here which is not ideal :(.
 *
 * TODO: We need to open discussions with the other teams about it
 */
const Config = get();

/**
 * Why are we using this custom formatter for now:
 * - We do not want to pass constants.defaultLanguage to our header
 *   (backend will take care of it through the use of *;q=0.001)
 * - It can handle a lot more languages, not only 3 maximum
 * - It is a little more versatile since it is not coupled with the Config object
 *
 * @example
 * formatLanguageHeader(["en", "fr"])
 * Will output: `en;q=1.000,fr;q=0.999,*;q=0.001`,
 *
 * TODO: We must remove this specific formatter as soon as the generic one suits our needs
 */
export function formatLanguageHeader(languages: string[]) {
    // The q-factor weight can take a minimum value of 0.001
    const MINIMUM_WEIGHT = 0.001;
    // The q-factor weight can take a maximum value of 1.000
    const MAXIMUM_WEIGHT = 1;

    /**
     * Format passed languages
     *
     * @example
     * const languages = ["en", "fr"];
     * formattedLanguages = "en;q=1.000,fr;q=0.999,"
     */
    const formattedLanguages = languages.reduce((header, language, index) => {
        // The weight will start to 1 and be decremented by 0.001 on each iteration
        const weight = MAXIMUM_WEIGHT - index / 1000;
        // With small floating point calculations, we have to take some precautions (0.1 + 0.2 !== 0.3 problem)
        const roundWeight = weight.toFixed(3);
        // If weight <= MINIMUM_WEIGHT we simply do nothing (lang is bypassed)
        return Number(roundWeight) > MINIMUM_WEIGHT ? `${header}${language};q=${roundWeight},` : header;
    }, '');

    // Return the Accept-Language header
    return `${formattedLanguages}*;q=${MINIMUM_WEIGHT}`;
}

/**
 * Custom accept language header required to retrieve data in the correct language from the Play API.
 *
 * Unlike the default `getLanguageHeader()` (and `formatLanguageHeader()`) found in `@lumapps/languages`,
 * we do not want to default to the `Config.defaultLanguage` each time a lang was not found.
 *
 * Also, we want to change the logic a bit:
 * - Get the user's language
 * - Then, its alternative language if set
 * - Finally, the language of its navigator.
 *
 * If none of these languages match the requested content, the backend will take care
 * of the rest and will fallback on the language of the site or the platform if necessary.
 *
 * (We do not pass `navigator.languages` because it's still an experimental feature.)
 *
 * TODO: We must remove these specific getter and formatter as soon as the generic one suits our needs
 */
export function getLanguageHeader() {
    /**
     * We are filtering duplicate entries via `...new Set([...])`
     * We remove falsy values from the results (`Config.userAlernativeLangs[0]` could be undefined)
     */
    return formatLanguageHeader(
        [...new Set([Config.userLang, Config.userAlernativeLangs[0], navigator.language])].filter(Boolean) as string[],
    );
}
