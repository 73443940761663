/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/boolean-prop-naming */
import React, { useEffect } from 'react';

import { Button, Emphasis, Icon, Progress, Slideshow, SlideshowItem } from '@lumapps/lumx/react';

import { mdiArrowDownDropCircleOutline, mdiInformationOutline } from '@lumapps/lumx/icons';

import { any, func, objectOf, string, bool, arrayOf } from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import get from 'lodash/get';

import { isConnected } from 'components/services/user';

import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';
import { useTranslate } from '@lumapps/translations';
import { useSlideshowControlLabels } from '@lumapps/utils/hooks/useSlideshowControlLabels';

import SocialPostBlock from './socialPostBlock/SocialPostBlock';

/**
 * Display the widget wall component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The wall react element.
 */
const WidgetWall = ({
    getNextPage,
    getPostByPostId,
    getPosts,
    hasNextPage,
    initState,
    isLoading,
    isMainWidget,
    nextPageCursor,
    posts,
    style,
    uuid,
    value,
}) => {
    const { translateKey } = useTranslate();
    const slideshowLabels = useSlideshowControlLabels();

    if (!isConnected()) {
        return null;
    }

    const {
        channel,
        contentType,
        listOrderDir,
        itemsPerLine,
        maxNumber,
        myChannel,
        orderBy,
        postType,
        provider,
        slideshowAutoplay,
        slideshowInterval,
        targetedPost,
        userSpace,
        viewMode,
    } = value;
    const theme = get(style, ['content', 'theme'], 'light');

    /*
     * UseEffect for init the widgetWall component properties in the redux store.
     */
    useEffect(() => {
        initState({ uuid });
    }, [initState, uuid]);

    /*
     * UseEffect for retrieve posts.
     */
    useEffect(() => {
        switch (contentType) {
            case 'pickGroup': {
                if (userSpace && channel) {
                    getPosts(
                        provider.name,
                        userSpace.id,
                        maxNumber,
                        postType === 'ALL' ? null : postType.toLowerCase(),
                        channel.id,
                        orderBy.toLowerCase(),
                        listOrderDir === 'asc',
                        uuid,
                    );
                }

                break;
            }

            case 'pickPost': {
                if (userSpace && targetedPost) {
                    getPostByPostId(provider.name, userSpace.id, channel.id, targetedPost, uuid);
                }

                break;
            }

            case 'currentUserGroups': {
                if (userSpace && myChannel) {
                    getPosts(
                        provider.name,
                        userSpace.id,
                        maxNumber,
                        postType === 'ALL' ? null : postType.toLowerCase(),
                        myChannel.id,
                        orderBy.toLowerCase(),
                        listOrderDir === 'asc',
                        uuid,
                    );
                }

                break;
            }

            default:
                break;
        }
    }, [
        channel,
        contentType,
        getPosts,
        getPostByPostId,
        provider,
        maxNumber,
        myChannel,
        postType,
        orderBy,
        targetedPost,
        listOrderDir,
        userSpace,
        uuid,
    ]);

    /*
     * Function that get the next page of results.
     */
    const getNextPageFunction = () => {
        if (nextPageCursor) {
            getNextPage(
                provider.name,
                userSpace.id,
                maxNumber,
                postType === 'ALL' ? null : postType.toLowerCase(),
                nextPageCursor,
                contentType === 'pickGroup' ? channel.id : myChannel.id,
                orderBy.toLowerCase(),
                listOrderDir === 'asc',
                uuid,
            );
        }
    };


    return (
        <div className="widget-wall__wrapper">
            {viewMode === 'list' && !isEmpty(posts) && provider && (
                <div className="widget-wall__list">
                    {posts.map((post) => {
                        return (
                            <SocialPostBlock
                                key={post.id + post.uid}
                                item={post}
                                provider={provider.name}
                                theme={theme}
                            />
                        );
                    })}
                </div>
            )}
            {viewMode === 'slideshow' && !isEmpty(posts) && (
                <div className="widget-wall__slideshow">
                    <Slideshow
                        autoPlay={slideshowAutoplay}
                        fillHeight
                        groupBy={itemsPerLine}
                        slideshowControlsProps={{
                            nextButtonProps: { label: slideshowLabels.next },
                            previousButtonProps: { label: slideshowLabels.previous },
                            paginationItemProps: (index) => ({
                                label: slideshowLabels.paginationItem(index),
                            }),
                        }}
                        slideGroupLabel={slideshowLabels.slideGroup}
                        interval={slideshowInterval}
                        theme={theme}
                    >
                        {posts.map((item) => {
                            return (
                                <SlideshowItem key={item.id} theme={theme}>
                                    <SocialPostBlock item={item} provider={provider.name} theme={theme} />
                                </SlideshowItem>
                            );
                        })}
                    </Slideshow>
                </div>
            )}
            {!isLoading && isMainWidget && !isEmpty(posts) && viewMode !== 'slideshow' && (
                <InfiniteScroll callback={() => getNextPageFunction()} />
            )}
            {!isLoading && !isMainWidget && hasNextPage && viewMode !== 'slideshow' && provider && (
                <div className="infinite-scroll-button">
                    <Button
                        emphasis={Emphasis.medium}
                        leftIcon={mdiArrowDownDropCircleOutline}
                        theme={theme}
                        onClick={() => getNextPageFunction()}
                        style={{ width: '100%' }}
                    >
                        {translateKey('GLOBAL.MORE_RESULTS')}
                    </Button>
                </div>
            )}
            {!isLoading && (isEmpty(posts) || !provider) && (
                <div className="no-results">
                    <Icon icon={mdiInformationOutline} />
                    <span>{translateKey('WIDGET.WALL.NO_POSTS')}</span>
                </div>
            )}
            {isLoading && (
                <div className="loader loader--progress loader--circular">
                    <Progress theme={theme} />
                </div>
            )}
        </div>
    );
};

WidgetWall.defaultProps = {
    hasNextPage: false,
    isLoading: false,
    isMainWidget: false,
    nextPageCursor: '',
    posts: [],
};

WidgetWall.propTypes = {
    /* Function that retrieve the next page of posts. */
    getNextPage: func.isRequired,
    /* A function to retrieve post by its id. */
    getPostByPostId: func.isRequired,
    /* A function to retrieve posts. */
    getPosts: func.isRequired,
    /* Define if there is more item to load or not. */
    hasNextPage: bool,
    /* A function to initialize the component's properties in the redux store. */
    initState: func.isRequired,
    /* Define if we need to display a loader or not. */
    isLoading: bool,
    /* Define if this widget is the main widget of the page or not. */
    isMainWidget: bool,
    /* The next page cursor. */
    nextPageCursor: string,
    /* The post items. */
    posts: arrayOf(any),
    /* The widget style. */
    style: objectOf(any).isRequired,
    /* The current widget uuid. */
    uuid: string.isRequired,
    /* The properties of the widget passed by the angular directive. */
    value: objectOf(any).isRequired,
};

WidgetWall.isEditable = () => false;

/**
 * Defines whether the widget is empty or not.
 *
 * @param  {string}  value The recipent email address.
 * @return {boolean} Whether the recipent email address is empty or not.
 */

WidgetWall.isWidgetEmpty = (value) => {
    return value === undefined || value.length === 0;
};

/**
 * Determines whether the widget is hidden or not.
 *
 * @param  {Object}  params The widget props.
 * @return {boolean} Whether the widget is hidden or not.
 */
WidgetWall.isWidgetHidden = (params) => {
    return !isConnected() || WidgetWall.isWidgetEmpty(params);
};

export default WidgetWall;
