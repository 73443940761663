import { connect } from '@lumapps/redux/react';
import { BaseStore } from '@lumapps/redux/types';

import {
    getLanguages,
    currentLanguageSelector as defaultLanguageSelector,
    isLanguageSwitcherEnabled,
    inputLanguageSelector,
} from '../../ducks/selectors';
import { LanguageSwitcher } from './LanguageSwitcher';

import './index.scss';

const mapStateToProps = (state: BaseStore, props: any) => {
    const { useCustomerLanguages, defaultLanguage, isVisible = false, languages = [] } = props;

    return {
        languages: getLanguages(state, useCustomerLanguages, languages),
        defaultLanguage: defaultLanguage || defaultLanguageSelector(state),
        isVisible: isLanguageSwitcherEnabled(state) || isVisible,
        inputLanguage: inputLanguageSelector(state),
    };
};

const ConnectedLanguageSwitcher = connect(mapStateToProps)(LanguageSwitcher);

export { ConnectedLanguageSwitcher as LanguageSwitcher };
