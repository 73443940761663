/**
 * Ensure that dates coming from the backend are timezone marked.
 *
 * Date coming from the Monolith are unmarked UTC, so we add a Z.
 * Date coming from the Monolite are valid ISO date format, we do nothing.
 *
 * This function should be removed once the Monolith is removed.
 *
 * @param {string} dateString The date to fix
 * @return {string} The fixed date
 */
export function fixBackendMissingUTC(dateString: string) {
    if (/.*[+-]\d{2}:\d{2}/.exec(dateString)) {
        return dateString;
    }
    if (dateString.indexOf('Z') === -1) {
        return `${dateString}Z`;
    }
    return dateString;
}
