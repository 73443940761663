import get from 'lodash/get';

import { ColorPalette } from '@lumapps/lumx/react';
import { programDashboard, ProgramDashboardViews } from '@lumapps/sa-programs/routes';
import { ShareableContentNotificationType } from '@lumapps/sa-shareable-contents/types';

import { NOTIFICATIONS_CENTER } from '../../keys';
import {
    NotificationTypes,
    NotificationMeta,
    NotificationDescriptionParams,
    NotificationLinkUrlParams,
} from '../../types';

const getContentTitle = ({ notification, translation }: NotificationDescriptionParams): string =>
    translation.translateObject(notification?.description?.content_title as any) || '';

const getProgramTitle = ({ notification, translation }: NotificationDescriptionParams): string =>
    translation.translateObject(notification?.description?.program_title as any) ||
    translation.translateKey(NOTIFICATIONS_CENTER.SOCIAL_ADVOCACY_NAME);

const getSARoute = ({ notification, translation }: NotificationLinkUrlParams) => {
    const programSlug = translation.translateObject(notification?.description?.program_slug as any) || 'default';

    return programDashboard(programSlug, ProgramDashboardViews.feed);
};

const notificationTypeSA: Record<string, NotificationMeta> = {
    [NotificationTypes.SocialAdvocacyNewShareableContent]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'bullhorn',
        },
        getDescription: (params) => {
            const shareableContentType = get(params.notification, 'description.content_type');
            const key =
                shareableContentType === ShareableContentNotificationType.image
                    ? NOTIFICATIONS_CENTER.SOCIAL_ADVOCACY_PROGRAMS_NEW_SHAREABLE_IMAGE_DESCRIPTION
                    : NOTIFICATIONS_CENTER.SOCIAL_ADVOCACY_PROGRAMS_NEW_SHAREABLE_CONTENT_DESCRIPTION;

            return params.translation.translateAndReplace(key, {
                CONTENT_TITLE: getContentTitle(params),
                PROGRAM_NAME: getProgramTitle(params),
            });
        },
        getRoute: getSARoute,
    },
    [NotificationTypes.SocialAdvocacyUserPromotedAMB]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'bullhorn',
        },
        getDescription: (params) => {
            return params.translation.translateAndReplace(
                NOTIFICATIONS_CENTER.SOCIAL_ADVOCACY_PROGRAMS_USER_PROMOTED_AMB_DESCRIPTION,
                {
                    PROGRAM_NAME: getProgramTitle(params),
                },
            );
        },
        getRoute: getSARoute,
    },
    [NotificationTypes.SocialAdvocacyUserPromotedPOM]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'bullhorn',
        },
        getDescription: (params) => {
            return params.translation.translateAndReplace(
                NOTIFICATIONS_CENTER.SOCIAL_ADVOCACY_PROGRAMS_USER_PROMOTED_POM_DESCRIPTION,
                {
                    PROGRAM_NAME: getProgramTitle(params),
                },
            );
        },
        getRoute: getSARoute,
    },
};

export { notificationTypeSA };
