import React from 'react';

import { useFilters } from '../../hooks/useFilters';
import { Filters as UIFilters } from './Filters';
import { ConnectedFiltersProps } from './types';

/**
 * Connected component in order to simply manage the filtering pattern component.
 * If you need to customise your filters, please use the component `Filter` to create your filters
 * If your filters are simple and there is not much customisation involved, use this component.
 *
 * IMPORTANT: Only use this component if `filters` is static.
 * @family Filters
 * @param FiltersProps
 * @returns Filters
 */
export const ConnectedFilters: React.FC<ConnectedFiltersProps> = ({ filterDropdownProps, ...hookProps }) => {
    const filtersProps = useFilters(hookProps);

    return <UIFilters {...filtersProps} filterDropdownProps={filterDropdownProps} />;
};

export { ConnectedFilters as Filters };
export type { ConnectedFiltersProps as FiltersProps };
