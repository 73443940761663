(function IIFE() {
    'use strict';

    /////////////////////////////

    function LocalStorageService($localStorage, $location, $sessionStorage) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The duration the data stored in Local Storage are valid.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _DEFAULT_VALIDITY_DURATION = 3600;

        /**
         * Indicates if we want to enable the Local and Session storage support.
         *
         * @type {boolean}
         * @constant
         * @readonly
         */
        var _ENABLE_LOCAL_STORAGE = false;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if there is Local/Session Storage enable on the browser.
         *
         * @return {boolean} If the browser has Socal Storage or not.
         */
        function _hasLocalStorage() {
            // eslint-disable-next-line angular/definedundefined
            return _ENABLE_LOCAL_STORAGE && typeof Storage !== 'undefined' &&
                angular.isUndefined($location.search().fresh) && angular.isUndefined($location.search().as);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get a value in Local Storage.
         *
         * @param  {string}  key                 The key to get.
         * @param  {boolean} [fromSession=false] Indicates if we want to get the value from the Session Storage instead
         *                                       of the Local Storage.
         * @return {*}       The value stored under the given key of the Local Storage.
         */
        function get(key, fromSession) {
            if (!_hasLocalStorage() || angular.isUndefinedOrEmpty(key)) {
                return undefined;
            }

            var $storage = (fromSession) ? $sessionStorage : $localStorage;

            var record = $storage[key];

            if (angular.isUndefinedOrEmpty(_.get(record, 'value')) ||
                (angular.isDefinedAndFilled(record.expiration) && Date.now() >= record.expiration)) {
                return undefined;
            }

            return record.value;
        }

        /**
         * Put a value in the Local Storage.
         *
         * @param {string}  key               The key where to store the value in the Local Storage.
         * @param {*}       value             The value to store in the Local Storage.
         * @param {number}  [expiration=3600] A validity duration for the key (in seconds).
         * @param {boolean} [asSession=false] Indicates if we want to store the value in the Session Storage instead of
         *                                    the Local Storage.
         */
        function put(key, value, expiration, asSession) {
            if (!_hasLocalStorage() || angular.isUndefinedOrEmpty(key)) {
                return;
            }

            var $storage = (asSession) ? $sessionStorage : $localStorage;

            expiration = (angular.isUndefined(expiration)) ? _DEFAULT_VALIDITY_DURATION : expiration;

            var record = {
                value: value,
            };
            if (angular.isDefined(expiration) && expiration > 0) {
                record.expiration = Date.now() + (expiration * 1000);
            }

            $storage[key] = record;
        }

        /**
         * Remove a key/value from the Local/Session Storage.
         *
         * @param {string}  key                 The key to remove.
         * @param {boolean} [fromSession=false] Indicates if we want to remove the value from the Session Storage
         *                                      instead of the Local Storage.
         */
        function remove(key, fromSession) {
            if (!_hasLocalStorage() || angular.isUndefinedOrEmpty(key)) {
                return;
            }

            var $storage = (fromSession) ? $sessionStorage : $localStorage;

            delete $storage[key];
        }

        /**
         * Reset the local and session storage.
         */
        function reset() {
            if (!_hasLocalStorage()) {
                return;
            }

            $localStorage.$reset();
            $sessionStorage.$reset();
        }

        /////////////////////////////

        service.delete = remove;
        service.get = get;
        service.put = put;
        service.remove = remove;
        service.reset = reset;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('LocalStorage', LocalStorageService);
})();
