// eslint-disable-next-line id-blacklist
import { error } from 'components/services/notification';
import { translate as t } from 'components/translations';

import { fetchChatTask, fetchWorkspaceTask } from './workspaces_tasks';

const FETCH_WORKSPACE_ERROR = 'workspace/FETCH_WORKSPACE_ERROR';
const FETCH_WORKSPACE_LOADING = 'workspace/FETCH_WORKSPACE_LOADING';
const FETCH_WORKSPACE_SUCCESS = 'workspace/FETCH_WORKSPACE_SUCCESS';

const FETCH_CHAT_ERROR = 'workspace/FETCH_CHAT_ERROR';
const FETCH_CHAT_LOADING = 'workspace/FETCH_CHAT_LOADING';
const FETCH_CHAT_SUCCESS = 'workspace/FETCH_CHAT_SUCCESS';

/**
 * Fetch a workspace.
 *
 * @param  {string}   workspaceId Id of the workspace to get.
 * @return {Function} The actions creator's thunk.
 */
const fetchWorkspace = ({ workspaceId } = {}) => (dispatch) => {
    dispatch({
        type: FETCH_WORKSPACE_LOADING,
    });

    return fetchWorkspaceTask({ workspaceId })
        .then(({ workspace }) =>
            dispatch({
                payload: { workspace },
                type: FETCH_WORKSPACE_SUCCESS,
            }),
        )
        .catch((exception) =>
            dispatch({
                payload: { exception },
                type: FETCH_WORKSPACE_ERROR,
            }),
        );
};

const fetchChat = ({ id }) => (dispatch) => {
    dispatch({
        meta: {
            id,
        },
        type: FETCH_CHAT_LOADING,
    });

    return fetchChatTask({ id })
        .then(({ payload } = {}) => {
            dispatch({
                meta: {
                    id,
                },
                payload,
                type: FETCH_CHAT_SUCCESS,
            });
        })
        .catch((exception) => {
            dispatch({
                meta: {
                    id,
                },
                payload: exception,
                type: FETCH_CHAT_ERROR,
            });
            error(t('FRONT.CHAT.FETCH_CHAT_ERROR'));
        });
};

export {
    FETCH_CHAT_ERROR,
    FETCH_CHAT_LOADING,
    FETCH_CHAT_SUCCESS,
    FETCH_WORKSPACE_ERROR,
    FETCH_WORKSPACE_LOADING,
    FETCH_WORKSPACE_SUCCESS,
    fetchChat,
    fetchWorkspace,
};
