/**
 * Layout & Widget API.
 *
 * @see https://github.com/lumapps/core/blob/master/specs/layout_api_v1.yaml
 */

import BaseApi from '@lumapps/base-api';

import { LegacyWidget, OwnerResourceInfo, Widget } from '../types';
import { getBackendWidgetType } from '../utils/getBackendWidgetType';

const path = 'widgets';
export const widgetApi = new BaseApi({
    version: BaseApi.versions.v2,
    path,
});

/** API parameterized paths. */
export const paths = {
    filterPropertiesById: (widgetType: string, widgetId: string) => `${widgetType}/${widgetId}/filter-properties`,
    filterPropertiesByProperties: (widgetType: string) => `${widgetType}/filter-properties`,
    blockById: (widgetType: string, widgetId: string) => `${widgetType}/${widgetId}/blocks`,
    blocksByProperties: (widgetType: string) => `${widgetType}/blocks`,
};

/** API query keys */
export const queryKeys = {
    getWidgetFilterProperties: ({ widgetType, widgetId }: { widgetId: string; widgetType: string }) => [
        path,
        widgetType,
        widgetId,
        'filter-properties',
    ],
};

export interface WidgetResponse {
    /** Widget content. */
    widget: Widget;
    /** Whether more display elements can be loaded for the widget. */
    more: boolean;
    /** The cursor for loading more display elements, if any. */
    cursor?: string;
    /**
     * Wether the pagination must be handle using a "loadMore" button
     * or an "infinite" scroll
     */
    paginationType?: Widget['paginationType'];
}

export interface FetchBlockParams {
    /** Cursor to fetch more content */
    cursor?: string;
    /** Params to filter result by tab id */
    filters?: { tab?: string };
    /** Whether the widget display should be forced or not, even if empty. */
    forceDisplay?: boolean;
    /**
     * Whether the widget content should fallback to another language
     * This is used on some editable widget like the widget summary to avoid issue with the content preview
     * WARNING: This should be used only on designer and on content preview, not on read endpoints
     */
    canUseLangFallback?: boolean;
}

/** Get display blocks for a widget. */
export async function getWidgetBlocks(
    widgetType: string,
    widgetId: string,
    params: FetchBlockParams,
    acceptedLanguages: string,
): Promise<Widget> {
    const {
        data: { widget, cursor: newCursor, more, paginationType },
    } = await widgetApi.get<WidgetResponse>(
        paths.blockById(widgetType, widgetId),
        {
            params,
            headers: { 'Accept-Language': acceptedLanguages },
        },
        true /* applyBaseConfig */,
        true /* cancelable */,
    );

    return { ...widget, cursor: newCursor, more, paginationType };
}

/** Get display blocks for a widget given its legacy properties. */
export async function getWidgetBlocksByProperties(
    legacyWidget: LegacyWidget,
    params?: FetchBlockParams,
    acceptedLanguages?: string,
    ownerResourceInfo?: OwnerResourceInfo,
): Promise<Widget> {
    const { uuid: widgetId, widgetType } = legacyWidget;

    const backendWidgetType = getBackendWidgetType(widgetType);

    const {
        data: { widget, cursor: newCursor, more, paginationType },
    } = await widgetApi.post<WidgetResponse>(
        paths.blocksByProperties(backendWidgetType),
        { widgetComponent: legacyWidget, ownerResourceInfo },
        {
            params,
            headers: { ...(acceptedLanguages && { 'Accept-Language': acceptedLanguages }) },
        },
    );

    return { ...widget, cursor: newCursor, more, widgetId, paginationType };
}

/** Get filter properties by widget type & id. */
export async function getWidgetFilterPropertiesById<WidgetSpecificProperties = object>({
    widgetType,
    widgetId,
    params,
    acceptedLanguages,
    signal,
}: {
    widgetType: string;
    widgetId: string;
    params?: FetchBlockParams;
    acceptedLanguages?: string;
    signal?: AbortSignal;
}): Promise<WidgetSpecificProperties> {
    const backendWidgetType = getBackendWidgetType(widgetType);

    const { data } = await widgetApi.get<WidgetSpecificProperties>(
        paths.filterPropertiesById(backendWidgetType, widgetId),
        {
            params,
            signal,
            headers: { ...(acceptedLanguages && { 'Accept-Language': acceptedLanguages }) },
        },
    );

    return data;
}

/** Get filter properties by legacy widget properties. */
export async function getWidgetFilterPropertiesByProperties<WidgetSpecificProperties = object>({
    legacyWidget,
    ownerResourceInfo,
    params,
    acceptedLanguages,
    signal,
}: {
    legacyWidget: LegacyWidget;
    ownerResourceInfo?: OwnerResourceInfo;
    params?: FetchBlockParams;
    acceptedLanguages?: string;
    signal?: AbortSignal;
}): Promise<WidgetSpecificProperties> {
    const { widgetType } = legacyWidget;

    const { data } = await widgetApi.post<WidgetSpecificProperties>(
        paths.filterPropertiesByProperties(widgetType),
        { widgetComponent: legacyWidget, ownerResourceInfo },
        {
            params,
            signal,
            headers: { ...(acceptedLanguages && { 'Accept-Language': acceptedLanguages }) },
        },
    );

    return data;
}
