import { CUSTOMER } from './keys';

export enum CUSTOMER_STATUS {
    PENDING = 'pending',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
}

/**
 * Statuses available for any given customer
 */
export const CUSTOMER_STATUS_MAPPINGS = [
    {
        label: CUSTOMER.ENABLED,
        value: CUSTOMER_STATUS.ENABLED,
    },
    {
        label: CUSTOMER.PENDING,
        value: CUSTOMER_STATUS.PENDING,
    },
    {
        label: CUSTOMER.DISABLED,
        value: CUSTOMER_STATUS.DISABLED,
    },
];

/**
 * Different types of licenses that a customer might have
 */
export const CUSTOMER_LICENSE_TYPES = [
    {
        label: 'CUSTOMER_LICENSE_TYPES_FULL',
        value: 'full',
    },
    {
        label: 'CUSTOMER_LICENSE_TYPES_RESELLER',
        value: 'reseller',
    },
    {
        label: 'CUSTOMER_LICENSE_TYPES_PILOT',
        value: 'pilot',
    },
    {
        label: 'CUSTOMER_LICENSE_TYPES_TRIAL',
        value: 'trial',
    },
];

/**
 * The backend application uses these strings to determine whether
 * the feature is active instead of using a boolean. No idea why
 */
export enum BROSWER_TRANSLATION {
    YES = 'Yes',
    NO = 'No',
}

/**
 * The backend application uses these strings to determine whether
 * the feature is active instead of using a boolean. No idea why
 */
export enum WYSIWYG_PASTEPLAIN {
    YES = 'Yes',
    NO = 'No',
}

export enum CONTENT_LIST_LINK_OPEN_NEW_TAB {
    NEW_TAB = 'new_tab',
    SAME_TAB = 'same_tab',
}

export enum BLOCK_XSS_HTML_TAGS {
    ALL = 'html_whitelist_all',
    PLATFORM = 'html_whitelist_platform',
    SITE = 'html_whitelist_site',
}

export enum ORGANIZATION_VALUE {
    ACTIVE = 'active',
    DEFAULT = 'default',
    INACTIVE = 'inactive',
}

export enum CUSTOMER_KIND {
    GOOGLE = 'google',
    CLASSIC = 'classic',
}

export enum CUSTOMER_FILTERS {
    STATUS = 'status',
}
export const NEXT_GEN_NGI = 'next-gen-interface';
