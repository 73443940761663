import { APPLICATION_NAME } from './config';
import 'focus-visible';
import 'core-js';

/////////////////////////////

window.lumappsPerformances = window.lumappsPerformances || {};
window.lumappsPerformances.angularBootstraping = Date.now();

angular.bootstrap(document, [APPLICATION_NAME]);

window.lumappsPerformances.angularBootstraped = Date.now();
