import { IMAGE_FEATURES, textAlignmentToImageAlignment } from '@lumapps/wrex-enhanced-image/constants';
import { ImageEditor } from '@lumapps/wrex-enhanced-image/types';
import { isImageGroup } from '@lumapps/wrex-enhanced-image/utils/isImageGroup';
import { ALIGNMENTS } from '@lumapps/wrex/constants';
import { Editor, Path, Point, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';
import { toRange } from '@lumapps/wrex/slate/utils/toRange';
import { Wrex } from '@lumapps/wrex/types';
import { Alignment } from '@lumapps/wrex/types/core';

import { isAuthorizedAlignmentNode } from './isAuthorizedAlignmentNode';

export const changeTextAlignment = (
    editor: Editor | ReactEditor,
    alignment: Alignment,
    at?: Range | Point | Path | null,
): void => {
    const selection = at || editor.selection;
    if (!selection) {
        return;
    }
    const [imageEntry] = Editor.nodes(editor, { at: selection, mode: 'highest', match: (node) => isImageGroup(node) });

    // Allow enhanced image alignment with the text align button
    if (imageEntry && (editor as ReactEditor & ImageEditor).isImageFeatureEnabled(IMAGE_FEATURES.alignment)) {
        const [, nodePath] = imageEntry;
        const imageEditor = editor as ReactEditor & ImageEditor;

        if (textAlignmentToImageAlignment[alignment]) {
            if (
                (alignment === ALIGNMENTS.center || alignment === ALIGNMENTS.justify) &&
                imageEditor.isImageFeatureEnabled(IMAGE_FEATURES.width)
            ) {
                imageEditor.changeImageWidth(nodePath, textAlignmentToImageAlignment[alignment]);
            } else {
                imageEditor.changeImageAlignment(nodePath, textAlignmentToImageAlignment[alignment]);
            }
        }

        return;
    }

    const rangeRef = Editor.rangeRef(editor, toRange(selection));

    // Change text alignment of the root blocks.
    Transforms.setNodes(editor, { alignment } as Partial<Wrex.Element>, {
        match: (node) => !Editor.isEditor(node) && isAuthorizedAlignmentNode(node),
        mode: 'highest',
        at: selection,
    });

    const newSelection = rangeRef.unref() as Range;

    // Put selection at the end of the location.
    focusAt(editor as ReactEditor, newSelection);
};
