import { KeyboardEventHandler } from 'react';

import omit from 'lodash/omit';
import type { Editor } from 'slate';
import type { Editable } from 'slate-react';

import { ElementRender, MarkRender } from '../types';

export type EditableWrapper = (E: typeof Editable) => typeof Editable;

export interface PluginOptions<E extends Editor> {
    // eslint-disable-next-line no-use-before-define
    getKeyHandler?: KeyHandlerCreator<E>;
    /** Customize element renderers (leaf node). */
    elements?: ElementRender<any, any>[];
    /** Customize mark renderers (leaf node). */
    marks?: MarkRender[];
    /** Wraps the default Editable component to customize props or inject React contexts. */
    editableWrapper?: EditableWrapper;
}

export type Plugin<PE = any, E extends Editor = Editor> = ((editor: E) => E & PE) & PluginOptions<E & PE>;

// Create plugin key handler.
export type KeyHandlerCreator<E extends Editor> = (e: E) => KeyboardEventHandler;

// Create plugin editor function.
export type CreatePluginEditor<PE, E extends Editor = Editor> = (editor: E) => PE;

export type CreatePluginOptions<PE, E extends Editor = Editor> =
    | CreatePluginEditor<PE, E>
    | (PluginOptions<E & PE> & {
          createPluginEditor: CreatePluginEditor<PE, E>;
      });

/**
 * Create slate plugin.
 */
export function createPlugin<PE, E extends Editor = Editor>(options: CreatePluginOptions<PE, E>): Plugin<PE, E> {
    const createPluginEditor = typeof options === 'function' ? options : options.createPluginEditor;
    const createEditor = (editor: any) => Object.assign(editor, createPluginEditor(editor));
    return Object.assign(createEditor, omit(options, 'createPluginEditor'));
}
