import { Instance } from './types';

export const SIDE_NAV_ID = 'instances';

/**
 * Instance to be used as the default one when creating a new one.
 */
export const DEFAULT_INSTANCE: Instance = {
    defaultLang: '',
    id: '',
    isDefaultInstance: false,
    logo: [],
    name: '',
    slug: '',
    title: {},
    uid: '',
};

export enum INSTANCE_PERMISSIONS {
    INSTANCE_EDIT = 'INSTANCE_EDIT',
}
