import { isFeatureEnabled } from '@lumapps/features';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

import { SEGMENT_ADMIN_FEATURE_TOKEN } from '../../constants';

export const isSegmentEnabled = isFeatureEnabled(SEGMENT_ADMIN_FEATURE_TOKEN);
export const isUserAdmin = isUserAllowed();

export const canAccessSegmentAdmin = createSelector(
    [isSegmentEnabled, isUserAdmin],
    (isSegmentEnabled, isUserAdmin) => isSegmentEnabled && isUserAdmin,
);
