export enum WIDGET_SUMMARY {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=492999688
     */
    GENERATE_SUMMARY = 'WIDGET_SUMMARY.GENERATE_SUMMARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493064182
     */
    INPUT_PLACEHOLDER = 'WIDGET_SUMMARY.INPUT_PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=497714196
     */
    NO_TEXTUAL_WIDGET_INFO = 'WIDGET_SUMMARY.NO_TEXTUAL_WIDGET_INFO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=491123403
     */
    ONLY_ONE_PER_CONTENT_HELPER = 'WIDGET_SUMMARY.ONLY_ONE_PER_CONTENT_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=492999887
     */
    REGENERATE_SUMMARY = 'WIDGET_SUMMARY.REGENERATE_SUMMARY',
}
