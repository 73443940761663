import first from 'lodash/first';

import { SOURCE_TYPES } from './widget-channel-list_constants';

import { getChannelListUtils } from './channel-list_utils';

function WidgetChannelListSettingsController(Utils, Widget) {
    'ngInject';

    const widgetChannelListSettings = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Default numbers of conversations to display if limit conversations is activated.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    const _DEFAULT_NUMBER_CONVERSATIONS = 5;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    const {
        addSelectedChannel,
        changeSourceType,
        deleteSelectedChannel,
        getProvider,
        getSourceType,
        getWidgetProperties,
        setProviderId,
        setSourceType,
    } = getChannelListUtils(Widget.getCurrent());

    /**
     * The list of available providers for the widget.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    widgetChannelListSettings.AVAILABLE_PROVIDERS = [
        {
            id: 'teams',
            sources: [
                {
                    icon: 'mdi-microsoft',
                    name: 'PICK_TEAMS',
                    type: SOURCE_TYPES.CUSTOM,
                },
                {
                    icon: 'mdi-account',
                    name: 'JOINED_TEAMS',
                    type: SOURCE_TYPES.CATEGORY,
                },
            ],
        },
    ];

    /**
     * The identifier of the channel list dialog.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    widgetChannelListSettings.CHANNEL_LIST_DIALOG_ID = 'channel-list-dialog';

    /**
     * Service to use in list dialog that display the available channels to add in the widget.
     *
     * @type {Object}
     */
    widgetChannelListSettings.listDialogService = {};

    /**
     * Indicates if the channel list dialog is shown.
     *
     * @type {boolean}
     */
    widgetChannelListSettings.showChannelListDialog = false;

    /**
     * List of source types.
     *
     * @type {Object}
     */
    widgetChannelListSettings.SOURCE_TYPES = SOURCE_TYPES;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Init widget properties.
     */
    function _initWidgetProperties() {
        const currentWidget = Widget.getCurrent();

        currentWidget.properties = currentWidget.properties || {};
        currentWidget.properties.selectedChannels = currentWidget.properties.selectedChannels || [];

        setProviderId(first(widgetChannelListSettings.AVAILABLE_PROVIDERS).id);
        setSourceType(currentWidget.properties.sourceType || SOURCE_TYPES.CATEGORY);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Trigger when the limit switch change.
     * Set a default number of displayed conversations.
     */
    function onLimitConversationChange() {
        const properties = getWidgetProperties();

        if (properties.limitConversation) {
            properties.numberConversations = properties.numberConversations || _DEFAULT_NUMBER_CONVERSATIONS;
        } else {
            properties.numberConversations = undefined;
        }
    }

    /**
     * Open the dialog that allows to add custom fields to a 3p data source.
     */
    async function openAddChannelDialog() {
        widgetChannelListSettings.showChannelListDialog = true;

        await Utils.waitForAndExecute(`#${widgetChannelListSettings.CHANNEL_LIST_DIALOG_ID}`);
    }

    /////////////////////////////

    widgetChannelListSettings.addSelectedChannel = addSelectedChannel;
    widgetChannelListSettings.changeSourceType = changeSourceType;
    widgetChannelListSettings.deleteSelectedChannel = deleteSelectedChannel;
    widgetChannelListSettings.getSourceType = getSourceType;
    widgetChannelListSettings.onLimitConversationChange = onLimitConversationChange;
    widgetChannelListSettings.openAddChannelDialog = openAddChannelDialog;
    widgetChannelListSettings.setProviderId = setProviderId;

    /////////////////////////////

    function _initListDialogService() {
        const provider = getProvider();

        widgetChannelListSettings.listDialogService = {
            defaultParams: {
                provider,
            },
            name: 'Channel',
            searchFilterName: 'searchFilter',
        };
    }

    /**
     * Initialize the controller.
     */
    function init() {
        _initWidgetProperties();
        _initListDialogService();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('WidgetChannelListSettingsController', WidgetChannelListSettingsController);

/////////////////////////////

export { WidgetChannelListSettingsController };
