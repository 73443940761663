import { paramsSerializer } from '@lumapps/base-api/utils/paramsSerializer';

/**
 * Generate a cache key for the given URL and parameters. Useful for identifying requests
 * when they are saved in our cache.
 * @param url
 * @param params
 * @returns string
 */
export const generateCacheKey = (url: string, params?: Record<string, any>): string => {
    let key = url;

    if (params) {
        const parameters = params;
        if (parameters.callId) {
            delete parameters.callId;
        }

        key = `${key}/${params ? paramsSerializer(parameters) : ''}`;
    }

    return key;
};
