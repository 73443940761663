import produce from 'immer';

import { EMPTY_TERMS_AND_CONDITIONS } from 'components/components/terms-and-conditions/termsAndConditions_constants';

import {
    FETCH_TERMS_AND_CONDITIONS_ERROR,
    FETCH_TERMS_AND_CONDITIONS_SUCCESS,
    SAVE_TERMS_AND_CONDITIONS_SUCCESS,
    UPDATE_TERMS_AND_CONDITIONS_SUCCESS,
} from './termsAndConditions_actions';

const reducer = produce((draft = EMPTY_TERMS_AND_CONDITIONS, action) => {
    switch (action.type) {
        // Fetch the terms and conditions.
        case FETCH_TERMS_AND_CONDITIONS_SUCCESS:
        case SAVE_TERMS_AND_CONDITIONS_SUCCESS:
        case UPDATE_TERMS_AND_CONDITIONS_SUCCESS: {
            const { termsAndConditions } = action.payload;

            return { ...termsAndConditions };
        }

        case FETCH_TERMS_AND_CONDITIONS_ERROR:
            return { ...draft };

        default:
            return { ...draft };
    }
});

/////////////////////////////

export { reducer };
