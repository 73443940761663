export enum WIDGET_PLAYLIST {
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339830543 */
    DISPLAY_PLAYLIST = 'PLAY.DISPLAY_PLAYLIST',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210424834 */
    DISPLAYED_VIDEO_NUMBER = 'PLAY.DISPLAYED_VIDEO_NUMBER',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210404910 */
    LIST_OF_VIDEOS = 'PLAY.LIST_OF_VIDEOS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=212838125 */
    NO_PLAYLIST_SELECTED = 'PLAY.NO_PLAYLIST_SELECTED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210361780 */
    NUMBER_OF_VIDEOS = 'PLAY.NUMBER_OF_VIDEOS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=199269359 */
    PLAYLISTS = 'PLAY.PLAYLISTS',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=212662863 */
    PLAYLIST_NO_LONGER_AVAILABLE = 'PLAY.PLAYLIST_NO_LONGER_AVAILABLE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=339830542 */
    PUBLISHED_PLAYLIST_IN_GALLERY = 'PLAY.PUBLISHED_PLAYLIST_IN_INSTANCE_VIDEO_GALLERY',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211315376 */
    SETTINGS_ITEMS_MARGIN = 'PLAY.SETTINGS_ITEMS_MARGIN',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211314891 */
    SETTINGS_MAX_VIDEOS_PER_LINE = 'PLAY.SETTINGS_MAX_VIDEOS_PER_LINE',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=211314979 */
    SETTINGS_MAX_VIDEOS_PER_LINE_HELPER = 'PLAY.SETTINGS_MAX_VIDEOS_PER_LINE_HELPER',
    /** This key is use in legacy app so there is not Lokalise key */
    SLIDESHOW_ENABLED = 'WIDGET_SETTINGS_SLIDESHOW_ENABLED',
    /** https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=121478395 */
    SUBTITLE = 'WIDGET_PLAY.SETTINGS_SUBTITLE',
}
