import React from 'react';

type KeyboardEventHandler = ((event: KeyboardEvent) => void) | React.KeyboardEventHandler;
type KeyboardEventMatcher = ((event: KeyboardEvent) => boolean) | ((event: React.KeyboardEvent) => boolean);

/**
 * Combines an event matcher with an event handler.
 */
export interface HandlerMatcher {
    match: KeyboardEventMatcher;
    handler: KeyboardEventHandler;
}

/**
 * Combine key event handlers into a single keyboard event handler.
 *
 * @example
 * // Use with isHotKey (package '@lumapps/utils/browser/isHotKey') to compose multiple hotkey event handler:
 * onKey([
 *   { match: isHotKey('mod+z'), handler: undo },
 *   { match: isHotKey('mod+y'), handler: redo },
 * ])
 *
 * @param  handlerMatchers Array of handlers with their matcher.
 * @param  preventDefault  Whether to prevent default event handling if a handler was matched and executed.
 * @return                 A keyboard event handler.
 */
export const onKey = (handlerMatchers: HandlerMatcher[], { preventDefault = false } = {}) => {
    return (event: KeyboardEvent | React.KeyboardEvent) => {
        const { handler } = handlerMatchers.find(({ match }) => match(event as any)) ?? {};
        if (!handler) {
            return;
        }
        handler(event as any);
        if (preventDefault) {
            event.preventDefault();
        }
    };
};
