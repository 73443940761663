import { BaseApiError } from '@lumapps/base-api';
import { getServiceServerError } from '@lumapps/base-api/utils/getServerError';
import * as notify from '@lumapps/notifications';
import { Dispatch, GetFrontOfficeState } from '@lumapps/redux/types';

import { getEvents } from '../../api/getEvents';
import { Event } from '../../api/types';
import { actions } from '../eventPreviewSlice';
import { eventBatchIdsSelector } from '../selectors';

/**
 * Fetch events by ids and store them as event entities.
 *
 * @param eventIds event identifiers
 */
export const fetchEventsByIds = () => async (dispatch: Dispatch, getState: GetFrontOfficeState) => {
    const state = getState();
    const eventBatch = eventBatchIdsSelector(state);

    if (!eventBatch) {
        return;
    }

    dispatch(actions.deleteBatchIdEvent(eventBatch));

    const eventEntities: Record<string, Event | null> = {};

    try {
        const result = await getEvents({ ids: eventBatch });
        const events: Event[] = result.items;

        for (const item of eventBatch) {
            const event = events.find((e) => e.id === item);

            eventEntities[item] = event || null;
        }
    } catch (error) {
        if (error instanceof BaseApiError) {
            if (error.response?.status === 404) {
                throw new Error('NOT_FOUND');
            } else {
                dispatch(notify.error({ translate: getServiceServerError(error.response) }));
            }
        }

        for (const item of eventBatch) {
            eventEntities[item] = null;
        }
    } finally {
        dispatch(actions.setEventEntities(eventEntities));
    }
};
