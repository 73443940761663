import React from 'react';

import { ImageLightbox, ImageLightboxProps, UseImageLightbox, useImageLightbox } from '../ImageLightbox';

type Registered = {
    /** Callback to generate image lightbox trigger props */
    getTriggerProps: UseImageLightbox['getTriggerProps'];
    /** Where the image group is inserted in the parent image array */
    index: number;
    /** Image group length */
    length: number;
};

type ContextValue = {
    register(images: ImageLightboxProps['images']): Registered;
    unregister(index: number, length: number): void;
};

export const ImageLightboxContext = React.createContext<ContextValue | null>(null);

export interface ImageLightboxProviderProps {
    isDisabled?: boolean;
    children: React.ReactNode;
}

/**
 * A context provider inside which you can register images.
 *
 * @family Images
 * @param ImageLightboxProviderProps
 * @returns ImageLightboxProvider
 */
export const ImageLightboxProvider: React.FC<ImageLightboxProviderProps> = ({ children, isDisabled }) => {
    const [images, setImages] = React.useState<ImageLightboxProps['images']>([]);

    const { getTriggerProps, imageLightboxProps } = useImageLightbox(images);

    const value = React.useMemo(
        (): ContextValue => ({
            /**
             * Register images in the context image array.
             * (Tracking the insertion point and image group length)
             */
            register(images) {
                let insertionIndex: number = 0;

                // Add image group to the state
                setImages((prevImages) => {
                    insertionIndex = prevImages.length;
                    return [...prevImages, ...images];
                });

                return {
                    index: insertionIndex,
                    length: images.length,
                    getTriggerProps({ activeImageIndex = 0 } = {}) {
                        return getTriggerProps({
                            // Map local index to global index
                            activeImageIndex: insertionIndex + activeImageIndex,
                        });
                    },
                };
            },
            /**
             * Unregister images at index & length in context image array.
             */
            unregister(index, length) {
                setImages((prevImages) => {
                    // Remove images from the state
                    const newImages = [...prevImages];
                    newImages.splice(index, length);
                    return newImages;
                });
            },
        }),
        [getTriggerProps],
    );

    if (isDisabled) {
        // Can't just return the children without a fragment
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }

    return (
        <ImageLightboxContext.Provider value={value}>
            <ImageLightbox {...imageLightboxProps} />
            {children}
        </ImageLightboxContext.Provider>
    );
};
ImageLightboxProvider.displayName = 'ImageLightboxProvider';
