import { ServerListRequest } from '@lumapps/base-api/types';
import { Community, RenderingType, SearchState } from '@lumapps/communities/types';

export interface AdminCommunitiesRequestParams extends ServerListRequest {
    add?: boolean;
    lang?: string[] | string;
    more?: boolean;
    query: string;
    sortOrder?: string;
    instance?: string;
    renderingTypes?: RenderingType[];
}

export enum DeleteCommunitiesDialogState {
    closed = 'closed',
    opened = 'opened',
    loading = 'loading',
}

export enum SaveAsTemplateState {
    idle = 'idle',
    loading = 'loading',
}

interface DeleteState {
    status: DeleteCommunitiesDialogState;
    communities: string[];
}

export interface BackofficeState {
    entities: { [key: string]: Community };
    isCreationWizardOpen: boolean;
    search: SearchState;
    selectedCommunitiesIds: string[];
    delete: DeleteState;
    saveAsTemplateStatus: SaveAsTemplateState;
}
export interface SpaceMigrationParams {
    id: string;
    name?: string | null;
    slug?: string | null;
}
