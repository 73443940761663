import React, { ReactNode } from 'react';

import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { TranslationConfig } from '../types';
import { TranslationsContext } from './TranslationsContext';

interface TranslationsProviderProps {
    children: ReactNode;
    value?: TranslationConfig;
}

const TranslationsProvider: React.FC<TranslationsProviderProps> = ({ children, value }) => {
    const instance = useSelector(getCurrentInstance);

    if (!value) {
        return null;
    }

    return (
        <TranslationsContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                ...value,
                instanceLanguages: instance?.langs,
                instanceDefaultLanguage: instance?.defaultLang,
                loaded: true,
            }}
        >
            {children}
        </TranslationsContext.Provider>
    );
};

export { TranslationsProvider };
