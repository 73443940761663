import React from 'react';

interface CustomizationRawHTMLProps {
    html: string;
    className?: string;
}

/**
 * Component that allows the customizations API to create components by directly passing in HTML.
 * @param props - CustomizationRawHTMLProps
 */
export const CustomizationRawHTML: React.FC<CustomizationRawHTMLProps> = ({ html, className }) => {
    // eslint-disable-next-line react/no-danger
    return <div className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};

CustomizationRawHTML.displayName = 'RawHTML';
