import React from 'react';

import './index.scss';
import { useClassnames } from '@lumapps/classnames';
import { Alignment, FlexBox, Orientation, Size, SkeletonCircle, SkeletonTypography } from '@lumapps/lumx/react';

const CLASSNAME = 'ranking-list-item';

/**
 * Skeleton for a ranking list item of the leaderboard compoenent
 *
 * @returns RankingListItemSkeleton
 */
export const RankingListItemSkeleton: React.FC = () => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox
            orientation={Orientation.horizontal}
            vAlign={Alignment.spaceBetween}
            wrap={false}
            className={element('skeleton-wrapper')}
        >
            <FlexBox
                orientation={Orientation.horizontal}
                hAlign={Alignment.center}
                gap={Size.big}
                vAlign={Alignment.spaceAround}
                className={element('left-column')}
            >
                <SkeletonCircle size="xs" />
                <SkeletonCircle size="m" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="40%" />
            </FlexBox>
            <FlexBox
                orientation={Orientation.horizontal}
                hAlign={Alignment.center}
                gap={Size.tiny}
                className={element('right-column')}
            >
                <SkeletonTypography typography="subtitle2" size={Size.s} width="30%" />
                <SkeletonCircle size="xs" />
            </FlexBox>
        </FlexBox>
    );
};
