import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

interface AuthenticatedIframeState {
    status: BaseLoadingStatus;
    src?: string;
}

export const initialState: AuthenticatedIframeState = {
    status: BaseLoadingStatus.initial,
};

export const reducers = {
    setLoadingStatus: (state: AuthenticatedIframeState, action: PayloadAction<BaseLoadingStatus>) => {
        set(state, 'status', action.payload);
    },
    setSrc: (state: AuthenticatedIframeState, action: PayloadAction<string>) => {
        set(state, 'src', action.payload);
        set(state, 'status', BaseLoadingStatus.idle);
    },
};

export const { actions, reducer } = createSlice({
    domain: 'authenticated-iframe',
    initialState,
    reducers,
});
