/* eslint-disable import/unambiguous */
const INIT_STATE = 'tasks/INIT_STATE';
const SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED = 'tasks/SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED';
const SET_ISFILTEREDBYLATETASKS = 'tasks/SET_ISFILTEREDBYLATETASKS';
const SET_ISFILTEREDBYREMINDERTASKS = 'tasks/SET_ISFILTEREDBYREMINDERTASKS';
const SET_ISFILTEREDBYTODAYTASKS = 'tasks/SET_ISFILTEREDBYTODAYTASKS';
const SET_ISLOADING = 'tasks/SET_ISLOADING';
const SET_ISORDEREDBYDUEDATEDESC = 'tasks/SET_ISORDEREDBYDUEDATEDESC';
const SET_SELECTEDTASKS = 'tasks/SET_SELECTEDTASKS';
const SET_FILTEREDTASKS = 'tasks/SET_FILTEREDTASKS';
const SET_MYTASKS_NEWTODO = 'tasks/SET_MYTASKS_NEWTODO';
const SET_MYTASKS_TODOITEMS = 'tasks/SET_MYTASKS_TODOITEMS';
const SET_MYTASKS_USERCONTENTWIDGET = 'tasks/SET_MYTASKS_USERCONTENTWIDGET';
const SET_TASKS = 'tasks/SET_TASKS';
const UPDATE_TASK = 'tasks/UPDATE_TASK';

/**
 * Dispatch the INIT_STATE action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const initState = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: INIT_STATE,
        });
    })();
};

/**
 * Dispatch the SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setIsMyTasksCheckedItemsDisplayed = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED,
        });
    })();
};

/**
 * Dispatch the SET_FILTEREDTASKS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setFilteredTasks = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_FILTEREDTASKS,
        });
    })();
};

/**
 * Dispatch the SET_MYTASKS_NEWTODO action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setMyTasksNewTodo = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_MYTASKS_NEWTODO,
        });
    })();
};

/**
 * Dispatch the SET_MYTASKS_TODOITEMS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setMyTasksTodoItems = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_MYTASKS_TODOITEMS,
        });
    })();
};

/**
 * Dispatch the SET_MYTASKS_USERCONTENTWIDGET action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setMyTasksUserContentWidget = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_MYTASKS_USERCONTENTWIDGET,
        });
    })();
};

/**
 * Dispatch the SET_ISFILTEREDBYLATETASKS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setIsFilteredByLateTasks = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_ISFILTEREDBYLATETASKS,
        });
    })();
};

/**
 * Dispatch the SET_ISFILTEREDBYREMINDERTASKS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setIsFilteredByReminderTasks = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_ISFILTEREDBYREMINDERTASKS,
        });
    })();
};

/**
 * Dispatch the SET_ISFILTEREDBYTODAYTASKS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setIsFilteredByTodayTasks = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_ISFILTEREDBYTODAYTASKS,
        });
    })();
};

/**
 * Dispatch the SET_ISLOADING action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setIsLoading = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_ISLOADING,
        });
    })();
};

/**
 * Dispatch the SET_ISORDEREDBYDUEDATEDESC action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setIsOrderedByDueDateDesc = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_ISORDEREDBYDUEDATEDESC,
        });
    })();
};

/**
 * Dispatch the SET_SELECTEDTASKS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setSelectedTasks = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_SELECTEDTASKS,
        });
    })();
};

/**
 * Dispatch the SET_TASKS action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const setTasks = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: SET_TASKS,
        });
    })();
};

/**
 * Dispatch the UPDATE_TASK action.
 *
 * @param  {any}      payload The action's data payload.
 * @return {Function} The action dispatcher.
 */
const updateTask = (payload) => (dispatch) => {
    return (() => {
        dispatch({
            payload,
            type: UPDATE_TASK,
        });
    })();
};

export {
    INIT_STATE,
    SET_IS_MYTASKS_CHECKEDITEMSDISPLAYED,
    SET_FILTEREDTASKS,
    SET_ISFILTEREDBYLATETASKS,
    SET_ISFILTEREDBYREMINDERTASKS,
    SET_ISFILTEREDBYTODAYTASKS,
    SET_ISLOADING,
    SET_ISORDEREDBYDUEDATEDESC,
    SET_SELECTEDTASKS,
    SET_MYTASKS_NEWTODO,
    SET_MYTASKS_TODOITEMS,
    SET_MYTASKS_USERCONTENTWIDGET,
    SET_TASKS,
    UPDATE_TASK,
    initState,
    setIsMyTasksCheckedItemsDisplayed,
    setFilteredTasks,
    setIsFilteredByLateTasks,
    setIsFilteredByReminderTasks,
    setIsFilteredByTodayTasks,
    setIsLoading,
    setIsOrderedByDueDateDesc,
    setSelectedTasks,
    setMyTasksNewTodo,
    setMyTasksTodoItems,
    setMyTasksUserContentWidget,
    setTasks,
    updateTask,
};
