import { useForm, UseFormMethods, UseFormOptions } from './useForm';

export interface UseMultilanguageFormProps extends UseFormOptions {
    defaultLanguage: string;
    languages: string[];
}

/**
 * Custom hook that wraps react-hook-form in a multilanguage context
 * @family Forms
 * @param UseMultiLanguageForm
 * @returns useMultiLanguageForm
 */
export const useMultiLanguageForm = ({
    defaultLanguage,
    form = {},
    onSubmit,
    languages,
    status,
}: UseMultilanguageFormProps): UseFormMethods => {
    const defaultValues = {
        ...(form && form.defaultValues ? form.defaultValues : {}),
        formLanguages: languages,
        lang: defaultLanguage,
        defaultLanguage,
    };

    const { methods, onSubmit: onFormSubmit } = useForm({
        form: {
            ...form,
            defaultValues,
            mode: 'onBlur',
        },
        onSubmit,
        status,
    });

    return {
        methods,
        onSubmit: onFormSubmit,
        defaultValues,
    };
};
