/**
 * Layout & Widget API.
 *
 * @see https://github.com/lumapps/core/blob/master/specs/layout_api_v1.yaml
 */

import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { Layout } from '@lumapps/widget-layout/types';

export const layoutApi = new BaseApi({
    version: BaseApi.versions.v2,
    path: 'contents',
});

/** API parameterized paths. */
export const paths = {
    contentLayoutById: (contentId: string) => `${contentId}/layout`,
};

export const cancelFetch = (contentId: string) => {
    layoutApi.cancel(paths.contentLayoutById(contentId));
};

/** Get content layout by organization & content id */
export async function getContentLayoutById(contentId: string, languages: string): Promise<Layout> {
    const { data } = await layoutApi.getCacheFirst<Layout>(
        paths.contentLayoutById(contentId),
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            headers: {
                'Accept-Language': languages,
            },
        },
        true /* applyBaseConfig */,
        true /* cancelable */,
    );

    return data;
}
