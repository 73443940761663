import React, { Component } from 'react';
import { objectOf, oneOfType, string, func, bool } from 'prop-types';
import Cropper from 'cropperjs/dist/cropper.esm';
import isEqual from 'lodash/isEqual';

import { InitialSettings } from '../../../../../common/modules/constants/initial-settings_constants';
import { mediaContentType } from '../../../document/types';
import { resizeImage } from '../../../../utils';

/**
 * The default aspect ratio tu use.
 *
 * @type {number}
 * @constant
 * @readonly
 */
const DEFAULT_ASPECT_RATIO = 3 / 2;

/**
 * Displays a cropper area which is overriden by `Cropper` instance provided by `cropperjs` library.
 */
class CropperArea extends Component {
    static propTypes = {
        /** The media trop crop. */
        media: mediaContentType.isRequired,
        /**
         * All available parameters for cropperjs API :
         * https://github.com/fengyuanchen/cropperjs/blob/master/README.md
         */
        params: objectOf(oneOfType([string, func, bool])),
    };

    static defaultProps = {
        params: {},
    };

    constructor(props) {
        super(props);

        /**
         * The current `Cropper` instance to use in the closure.
         *
         * @type {Cropper}
         */
        this.cropper = undefined;

        /**
         * The `img` element that will be attached to the current `Cropper` instance.
         *
         * @type {HTMLElement}
         */
        this.imgElement = undefined;

        this.initCropper = this.initCropper.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        const { media } = this.props;
        const { media: nextMedia } = nextProps;

        return !isEqual(media, nextMedia) && nextMedia;
    }

    /**
     *  Reset cropper related properties.
     */
    componentWillUnmount() {
        if (this.imgElement) {
            this.cropper.destroy();
            this.imgElement = undefined;
            this.cropper = undefined;
        }
    }

    /**
     * Initializes a new `Cropper` instance attached to the passed or already existing `HTMLElement`.
     *
     * @param {HTMLElement} node   The HTMLElement that will be attached to the newly created `Cropper` instance.
     * @param {Object}      params All the `cropperjs` parameters. See the link below:
     *                             https://github.com/fengyuanchen/cropperjs/blob/master/README.md#options.
     */
    initCropper({ node, params = {} } = {}) {
        const { aspectRatio = DEFAULT_ASPECT_RATIO } = params;
        this.imgElement = node || this.imgElement;

        if (this.cropper) {
            this.cropper.destroy();
        }

        if (!this.imgElement) {
            return;
        }

        this.cropper = new Cropper(this.imgElement, {
            ...params,
            aspectRatio,
        });
    }

    render() {
        const { media, params } = this.props;

        return (
            <img
                ref={(node) => this.initCropper({ node, params })}
                alt={media.name || ''}
                crossOrigin="anonymous"
                src={resizeImage(media.servingUrl, InitialSettings.MAX_IMAGE_SIZE)}
            />
        );
    }
}

export { DEFAULT_ASPECT_RATIO, CropperArea };
