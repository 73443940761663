/* eslint-disable no-param-reassign */
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

interface FieldState {
    isPristine: boolean;
    value: string;
}

interface ContactFormState {
    subject: FieldState;
    message: FieldState;
    isLoading: boolean;
}

// Default state for fields in the form.
const DEFAULT_FIELD_STATE = { isPristine: true, value: '' };

const initialState: ContactFormState = {
    subject: DEFAULT_FIELD_STATE,
    message: DEFAULT_FIELD_STATE,
    isLoading: false,
};

export const domain = 'contact-form';

const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        setMessage: (state: ContactFormState, action: PayloadAction<string>) => {
            state.message = { isPristine: false, value: action.payload };
        },
        setSubject: (state: ContactFormState, action: PayloadAction<string>) => {
            state.subject = { isPristine: false, value: action.payload };
        },
        request: (state: ContactFormState) => {
            state.isLoading = true;
        },
        success: (state: ContactFormState) => {
            state.isLoading = false;
            state.subject = DEFAULT_FIELD_STATE;
            state.message = DEFAULT_FIELD_STATE;
        },
        error: (state: ContactFormState) => {
            state.isLoading = false;
        },
    },
});

export { initialState, reducer, actions };
