(function IIFE() {
    'use strict';

    /////////////////////////////

    function CellService($rootScope, Header, LxNotificationService, Translation) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The currently selected cell.
         *
         * @type {Object}
         */
        var _current;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the currently selected cell is active.
         *
         * @type {boolean}
         */
        service.isActive = false;

        /**
         * Indicates if the currently selected cell is focused.
         *
         * @type {boolean}
         */
        service.isFocused = false;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if current cell can be deleted.
         * A cell can't be deleted if it's the only one on the first level.
         *
         * @param  {Object}  component The component that contains the current cell.
         * @return {boolean} If the current cell can be deleted.
         */
        function _currentCellCanBeDeleted(component) {
            if (angular.isUndefinedOrEmpty(_.get(component, 'components'))) {
                return false;
            }

            var count = 0;
            var firstLevel = false;

            // eslint-disable-next-line guard-for-in
            for (var rowIdx in component.components) {
                var row = component.components[rowIdx];
                if (!angular.isObject(row) || angular.isUndefinedOrEmpty(row.cells)) {
                    continue;
                }

                var cells = row.cells;
                count += cells.length;

                // eslint-disable-next-line guard-for-in
                for (var cellIdx in cells) {
                    var cell = cells[cellIdx];
                    if (!angular.isObject(cell)) {
                        continue;
                    }

                    if (cell.uuid === _current.uuid) {
                        firstLevel = true;

                        break;
                    }
                }

                // Cell is not alone or not at the first level.
                if (!firstLevel || count > 1) {
                    return true;
                }
            }

            return false;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Set a cell as active.
         */
        function activate() {
            service.isActive = true;

            if (angular.isDefinedAndFilled(_current)) {
                $rootScope.$broadcast('designer-component__active', 'cell', _current.uuid, service.isActive);
            }
        }

        /**
         * Unset a cell as focused.
         */
        function blur_() {
            service.isFocused = false;

            if (angular.isDefinedAndFilled(_current)) {
                $rootScope.$broadcast('designer-component__focus', 'cell', _current.uuid, service.isFocused);
            }
        }

        /**
         * Unset a cell as active.
         */
        function deactivate() {
            service.isActive = false;

            if (angular.isDefinedAndFilled(_current)) {
                $rootScope.$broadcast('designer-component__active', 'cell', _current.uuid, service.isActive);
            }
        }

        /**
         * Delete the current cell.
         *
         * @param {Object} component The component that contains the cell we want to delete.
         */
        function deleteCurrent(component) {
            LxNotificationService.confirm(
                Translation.translate('CELL_DELETE'),
                Translation.translate('CELL_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onDeleteCellConfirm(answer) {
                    if (!answer) {
                        return;
                    }

                    if (_currentCellCanBeDeleted(component)) {
                        var cellParents = service.getParents(component, _current);
                        var cellRow = cellParents[cellParents.length - 1];
                        var cellRowParent = (angular.isDefined(cellParents[cellParents.length - 2])) ? cellParents[
                            cellParents.length - 2] : component;

                        var deletedCell = cellRow.cells.splice(cellRow.cells.indexOf(_current), 1);

                        if (cellRow.cells.length > 0) {
                            angular.forEach(cellRow.cells, function forEachCells(cell) {
                                cell.width = 12 / cellRow.cells.length;
                            });
                        } else {
                            var deletedRow = cellRowParent.components.splice(
                                cellRowParent.components.indexOf(cellRow), 1
                            );

                            $rootScope.$broadcast('designer-component__delete', 'row',
                                _.get(deletedRow, 'uuid'));
                        }

                        $rootScope.$broadcast('designer-component__delete', 'cell', _.get(deletedCell, 'uuid'));
                    } else {
                        LxNotificationService.error(Translation.translate('CELL_ERROR_DELETE_LAST'));
                    }
                });
        }

        /**
         * Set a cell as focused.
         */
        function focus_() {
            service.isFocused = true;

            if (angular.isDefinedAndFilled(_current)) {
                $rootScope.$broadcast('designer-component__focus', 'cell', _current.uuid, service.isFocused);
            }
        }

        /**
         * Get the current cell.
         *
         * @return {Object} The current cell.
         */
        function getCurrent() {
            return _current;
        }

        /**
         * Get the parents of a cell.
         *
         * @param  {Object} component The component that contains the cell we want the parents of.
         * @param  {Object} cell      The cell we want the parents of.
         * @return {Array}  The parents of the given cell.
         */
        function getParents(component, cell) {
            var list = component.components || component.cells || [];

            if (angular.isUndefinedOrEmpty(list) || !angular.isArray(list)) {
                return undefined;
            }

            var parents = [];
            for (var i = 0, len = list.length; i < len; i++) {
                var item = list[i];

                if (item.uuid === cell.uuid) {
                    return parents;
                }

                var childResult = service.getParents(item, cell);
                if (angular.isDefined(childResult)) {
                    childResult.unshift(item);

                    return childResult;
                }
            }

            return undefined;
        }

        /*
         * Reset current cell and cell states.
         */
        function resetCell() {
            if (angular.isDefinedAndFilled(_current)) {
                $rootScope.$broadcast('designer-component__unset', 'cell', _current.uuid);
            }

            _current = undefined;

            service.blur();
            service.deactivate();
        }

        /**
         * Set the current cell.
         *
         * @param {Object} cell The cell to set.
         */
        function setCurrent(cell) {
            if (angular.isDefinedAndFilled(_current)) {
                $rootScope.$broadcast('designer-component__unset', 'cell', _current.uuid);
            }

            _current = cell;

            if (angular.isDefined(_current)) {
                Header.isActive = false;

                if (angular.isDefinedAndFilled(_current)) {
                    $rootScope.$broadcast('designer-component__set', 'cell', _current.uuid);
                }
            }
        }

        /////////////////////////////

        service.activate = activate;
        service.blur = blur_;
        service.deactivate = deactivate;
        service.deleteCurrent = deleteCurrent;
        service.focus = focus_;
        service.getCurrent = getCurrent;
        service.getParents = getParents;
        service.resetCell = resetCell;
        service.setCurrent = setCurrent;
    }

    /////////////////////////////

    angular.module('Services').service('Cell', CellService);
})();
