import { MediaSourceV2, MediaStatus } from '@lumapps/medias/types';
import { getMediaIcon, FILE_TYPES_ICONS } from '@lumapps/medias/utils';
import { OneDriveDocument } from '@lumapps/one-drive-pickers/types';

import { FileElement } from '../types';
import { createFile } from './createFile';

export const mapOneDriveFileToSlateFile = (files: OneDriveDocument[]): FileElement[] => {
    const slateFiles = files.map((file) => {
        if ((file as any).error?.code) {
            const oldFile = (file as any).error?.backupFile as FileElement;

            return createFile({
                id: oldFile.id,
                src: oldFile.src,
                name: oldFile.name,
                mimeType: oldFile.mimeType,
                createdAt: oldFile.createdAt,
                editedAt: oldFile.editedAt,
                provider: MediaSourceV2.MICROSOFT,
                driveId: oldFile.driveId,
                status: (file as any).error?.code === 'itemNotFound' ? MediaStatus.DELETED : MediaStatus.LIVE,
                isLoading: false,
            });
        }
        return createFile({
            id: file.id,
            src: file.webUrl,
            name: file.name,
            mimeType: file.file?.mimeType,
            createdAt: file.createdDateTime,
            editedAt: file.lastModifiedDateTime,
            provider: MediaSourceV2.MICROSOFT,
            driveId: file.parentReference.driveId,
            status: MediaStatus.LIVE,
            isLoading: false,
            thumbnailUrl:
                getMediaIcon({ mimeType: file.file?.mimeType }) === FILE_TYPES_ICONS.photo ? file.webUrl : undefined,
        });
    });

    return slateFiles;
};
