import BaseApi from '@lumapps/base-api';

const DelveApi = new BaseApi({ path: 'insight' });

const getInsightPeople = (maxResults) => {
    return DelveApi.get('people/list', {
        params: { maxResults },
    });
};

const getInsightTrendingDocuments = (maxResults) => {
    return DelveApi.get('documents/trending/list', {
        params: {
            maxResults,
        },
    });
};

const getInsightUsedDocuments = () => {
    return DelveApi.get('documents/used/list');
};

const getInsightSharedDocuments = () => {
    return DelveApi.get('documents/shared/list');
};

export { getInsightPeople, getInsightTrendingDocuments, getInsightUsedDocuments, getInsightSharedDocuments };
