(function IIFE() {
    'use strict';

    /////////////////////////////

    function ShareService() {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Get the mdi icon classname for a given service.
         *
         * @param  {string} serviceId The identifier of the service to get an icon for.
         * @return {string} The mdi icon classname.
         */
        function getShareIcon(serviceId) {
            return 'mdi-' + serviceId + ((serviceId === 'email') ? '' : '-box');
        }

        /////////////////////////////

        service.getShareIcon = getShareIcon;
    }

    /////////////////////////////

    angular.module('Services').service('Share', ShareService);
})();
