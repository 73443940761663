import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import omit from 'lodash/omit';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { CustomContentType } from '../types';
import { ContentTypesState } from './types';

const initialState: ContentTypesState = {
    entities: undefined,
    parentCustomContentTypeIds: undefined,
    currentContentTypeId: '',
    status: BaseLoadingStatus.initial,
};

export const { actions, reducer } = createSlice({
    domain: 'content-types',
    initialState,
    reducers: {
        setCustomContentTypes: (state: ContentTypesState, action: PayloadAction<CustomContentType[]>): void => {
            if (!state.entities) {
                // eslint-disable-next-line no-param-reassign
                state.entities = {};
            }
            Object.assign(state.entities, keyBy(action.payload, 'uid'));
        },
        setParentCustomContentTypeIds: (state: ContentTypesState, action: PayloadAction<CustomContentType[]>): void => {
            const ids = map(action.payload, 'id');
            set(state, 'parentCustomContentTypeIds', ids);
        },
        updateContentTypes(state: ContentTypesState, action: PayloadAction<CustomContentType[]>) {
            if (!state.entities) {
                // eslint-disable-next-line no-param-reassign
                state.entities = {};
            }
            if (state.entities) {
                mergeObjectOnly(state.entities, keyBy(action.payload, 'uid'));
            }
        },
        updateContentType(state: ContentTypesState, action: PayloadAction<CustomContentType>) {
            const newContentTypes = {
                ...state.entities,
                [action.payload.id]: action.payload,
            };

            set(state, 'entities', newContentTypes);
            set(
                state,
                'parentCustomContentTypeIds',
                state.parentCustomContentTypeIds?.indexOf(action.payload.id) === -1
                    ? [...state.parentCustomContentTypeIds, action.payload.id]
                    : state.parentCustomContentTypeIds,
            );
        },
        setCurrentContentTypeId: (state: ContentTypesState, action: PayloadAction<CustomContentType['id']>): void => {
            // eslint-disable-next-line no-param-reassign
            state.currentContentTypeId = action.payload;
        },
        setLoadingStatusCustomContentTypes: (state: ContentTypesState, action: PayloadAction<BaseLoadingStatus>) => {
            set(state, 'status', action.payload);
        },
        deleteContentTypes: (state: ContentTypesState, action: PayloadAction<string[]>): void => {
            const updatedCustomContentTypes = omit(state.entities, action.payload);
            const updatedparentCustomContentTypeIds = state.parentCustomContentTypeIds?.filter(
                (id) => action.payload.indexOf(id) === -1,
            );

            set(state, 'entities', updatedCustomContentTypes);
            set(state, 'parentCustomContentTypeIds', updatedparentCustomContentTypeIds);
        },
    },
});
