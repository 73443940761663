import React from 'react';

import { Suspense } from '@lumapps/utils/components/Suspense';

import type { DistributeResourceDialogProps } from './DistributeResourceDialog';

export type { DistributeResourceDialogProps };

const LazyDistributeResourceDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'distribute-resource-dialog' */
            './DistributeResourceDialog'
        ),
);

export const DistributeResourceDialog = (props: DistributeResourceDialogProps) => (
    <Suspense isDialog>
        <LazyDistributeResourceDialog {...props} />
    </Suspense>
);
