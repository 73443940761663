import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiMessageAlert } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';

import { NANNY_ADMIN } from '../../keys';
import { admin } from '../../routes';

export interface NannySideNavigationProps extends Partial<SideNavigationItemProps> {
    isAllowed: boolean;
}

const NannySideNavigation = (props: Partial<NannySideNavigationProps>) => {
    const { isAllowed, ...restOfProps } = props;

    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            route={admin()}
            label={translateKey(NANNY_ADMIN.SENSITIVE_DATA_FLAGGING)}
            icon={mdiMessageAlert}
            {...restOfProps}
        />
    );
};

NannySideNavigation.displayName = 'SideNavigationItem';

export { NannySideNavigation };
