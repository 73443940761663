/**
 * Checks whether the provided date is today
 * @param someDate - date to check
 * @returns boolean
 */
export const isToday = (someDate: Date) => {
    const today = new Date();

    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
};
