export enum NAVIGATION {
    /**
     * TODO: Delete once ADD_TO_NAVIGATION is in production
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452736
     */
    ADD_TO_THE_MENU = 'CONTENT_SETTINGS_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261017044
     */
    ADD_TO_NAVIGATION = 'NAVIGATION.ADD_TO_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261018696
     */
    ADD_PUBLISHED_CONTENT = 'NAVIGATION.ADD_PUBLISHED_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=74500022
     */
    TITLE = 'ADMIN.MOBILE.NAVIGATION.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=143448719
     */
    WIDTH_WARNING = 'NAVIGATION.WIDTH_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115790220
     */
    DEPTH_LIMIT = 'NAVIGATION.DEPTH_LIMIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=86746440
     */
    EMPTY_SECTIONS_WARNING = 'ADMIN.MOBILE.NAVIGATION.SAVE.ERROR.EMPTY_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=143952592
     */
    WIDTH_LIMIT = 'NAVIGATION.WIDTH_LIMIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=115882712
     */
    MOVED_TO_THE_FIRST_LEVEL = 'MOBILE_NAVIGATION.MOVED_TO_THE_FIRST_LEVEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=88239706
     */
    MAIN_NAVIGATION_ARIA = 'FRONT.NAV.MAIN_NAVIGATION.ARIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=88239712
     */
    PARENT_NAVIGATION_ARIA = 'FRONT.NAV.PARENT_NAVIGATION.ARIA',
}
