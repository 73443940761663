export enum DIRECTORIES {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452845
     */
    DIRECTORIES = 'DIRECTORIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244385
     */
    MODULES = 'ADMIN.NAV.MODULES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=69767452
     */
    CONTENTS = 'ADMIN.NAV.DIRECTORY_CONTENTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=287752782
     */
    SELECT_A_SITE_FIRST = 'DIRECTORIES.SELECT_A_SITE_FIRST',
}
