import * as React from 'react';
import * as propTypes from 'prop-types';
import classNames from 'classnames';

import { onClear } from '../../../utils';
import * as uiTypes from '../types';
import * as types from '../../types';
import { Input } from './Input';

/**
 * Renders an input text field, complete with label and help text.
 */
export class InputTextField extends React.PureComponent {
    static propTypes = {
        InputComponent: propTypes.func,
        /** Whether the input field is clearable with a button or not. */
        allowClear: propTypes.bool,
        className: propTypes.string,
        /** See DOM `input`. */
        disabled: propTypes.bool,
        /** Whether the input field is in error or not. */
        error: propTypes.bool,
        /** Whether the input label acts like a classic placeholder or not. */
        fixedLabel: propTypes.bool,
        helpText: propTypes.string,
        /** Icon to display next to the input. Input must have a fixed label in this case. */
        icon: propTypes.string,
        /* is required*/
        required: propTypes.bool,
        /** Input label. */
        label: propTypes.string,
        name: types.properties,
        /** Called when `value` changes with `(value, name, occurence)`. */
        onChange: propTypes.func,
        /** Sets the status style. */
        status: propTypes.oneOf(['default', 'error', 'valid']),
        theme: uiTypes.theme,
        /** Current value to display. */
        value: propTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = { active: false, focused: false };
        onClear(this);
        this.onFocus = () => this.setState({ focused: true });
        this.onBlur = () => this.setState({ focused: false });
    }

    render() {
        const {
            value,
            name: key,
            onChange,
            allowClear,
            fixedLabel = false,
            icon,
            label,
            theme = 'light',
            status: currentStatus,
            disabled = false,
            InputComponent = Input,
            helpText,
            className,
            required,
            ...props
        } = this.props;
        const { focused } = this.state;
        const requiredTxt = required ? ' *' : '';
        return (
            <React.Fragment>
                <div
                    className={classNames(
                        'text-field',
                        className,
                        currentStatus === 'error' && 'text-field--error',
                        currentStatus === 'valid' && 'text-field--valid',
                        fixedLabel && 'text-field--fixed-label',
                        icon && 'text-field--has-icon',
                        disabled && 'text-field--is-disabled',
                        `text-field--theme-${theme}`,
                        value !== null && value !== undefined && 'text-field--has-value',
                        ((value !== null && value !== undefined) || focused) && 'text-field--is-active',
                        focused && 'text-field--is-focus',
                    )}
                >
                    {icon && (
                        <div className="text-field__icon">
                            <i className={`mdi mdi-${icon}`} />
                        </div>
                    )}
                    <label className="text-field__label">{label}{requiredTxt}</label>
                    <InputComponent
                        {...props}
                        value={value}
                        name={key}
                        onChange={onChange}
                        disabled={disabled}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        className="text-field__input"
                    />
                    {allowClear && (
                        <span className="text-field__clear" onClick={this.onClear}>
                            <i className="mdi mdi-close-circle" />
                        </span>
                    )}
                </div>
                {helpText && (
                    <span className={`text-field-${currentStatus === 'error' ? 'error' : 'help'}`}>{helpText}</span>
                )}
            </React.Fragment>
        );
    }
}
