/* eslint-disable no-use-before-define,lumapps/file-format */
import loFind from 'lodash/find';
import get from 'lodash/get';

// eslint-disable-next-line valid-jsdoc
/**
 * Service to get document providers, including thoses defined for community.
 */
function CommunityDocumentProviderService(Content, DocumentProvider, Instance) {
    'ngInject';

    const service = {
        ...DocumentProvider,
        ...{ getList, getRemoteList: DocumentProvider.getList },
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get drives linked to this community.
     *
     * @return {Promise} Array of community drives.
     */
    async function _getCurrentCommunityDrives() {
        const { docPath, source: providerId } = get(Content.getCurrent(), 'driveFolder') || {};

        if (angular.isUndefinedOrEmpty(docPath)) {
            return [];
        }

        // Try to find the true provider behind this community drive, to get sortable fields.
        const communityDriveProvider = await _getRemoteProvider(providerId);
        const sortableFields = get(communityDriveProvider, 'sortableFields', []);

        return [
            {
                categories: [
                    {
                        canPick: true,
                        id: 'LIBRARY',
                        rootPath: docPath,
                    },
                ],
                display: 'inline',
                id: 'community',
                sortableFields,
            },
        ];
    }

    /**
     * Get a remote provider from it's id.
     *
     * @param  {string} providerId Provider id.
     * @return {Object} Provider.
     */
    async function _getRemoteProvider(providerId) {
        return loFind(await service.getRemoteList(), { id: providerId });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Fetch and return a copy of document providers list.
     *
     * @return {Promise} Promise resolved when the list has been fetch.
     */
    async function getList() {
        const remoteProviders = await this.getRemoteList({
            instanceId: Instance.getCurrentInstanceId(),
        });
        const communityDrives = await _getCurrentCommunityDrives();

        return angular.fastCopy([...communityDrives, ...remoteProviders]);
    }

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('CommunityDocumentProvider', CommunityDocumentProviderService);

export { CommunityDocumentProviderService };
