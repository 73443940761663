import React from 'react';

import { mdiChevronDown, mdiChevronUp } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Size, TextField, TextFieldProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { PartialBy } from '@lumapps/utils/types/PartialBy';

import { useComboboxRefs } from '../../context/ComboboxRefsContext';
import { useCombobox } from '../../hooks/useCombobox';
import { useComboboxInput } from '../../hooks/useComboboxInput';

/**
 * All TextField props that are extended.
 * We can't use "Omit" here as it is not compatible with the "GenericProps" type from the DS,
 * meaning we would loose all props.
 *
 */
type ExtendedTextFieldProps = PartialBy<
    Pick<
        TextFieldProps,
        | 'ariaLabel'
        | 'chips'
        | 'clearButtonProps'
        | 'error'
        | 'forceFocusStyle'
        | 'hasError'
        | 'afterElement'
        | 'helper'
        | 'icon'
        | 'inputRef'
        | 'textFieldRef'
        | 'isDisabled'
        | 'className'
        | 'isRequired'
        | 'isValid'
        | 'label'
        | 'maxLength'
        | 'minimumRows'
        | 'multiline'
        | 'name'
        | 'placeholder'
        | 'onBlur'
        | 'onClear'
        | 'onFocus'
        | 'theme'
    >,
    'ariaLabel'
>;

export type ComboboxInputProps = ExtendedTextFieldProps & {
    /** Whether the toggle button should be hidden */
    hideToggle?: boolean;
    /** Activate the clear button */
    hasClearButton?: boolean;
};

/**
 * Combobox input trigger.
 *
 * @family Combobox
 */
export const ComboboxInput = ({
    hideToggle,
    inputRef,
    textFieldRef,
    afterElement,
    onFocus,
    onBlur,
    clearButtonProps,
    hasClearButton,
    ...textFieldProps
}: ComboboxInputProps) => {
    const { translateKey } = useTranslate();
    const refs = useComboboxRefs();
    const context = useCombobox();
    const inputProps = useComboboxInput({ refs, context, onBlur, onFocus });
    const { listboxId, isOpen, handleOpen, handleClose, inputValue, handleInputChange } = context;

    /** Callback for when the toggle button is trigged */
    const handleManualToggle = () => {
        if (isOpen) {
            handleClose();
        } else {
            handleOpen({ manual: true });
        }
        refs.triggerRef?.current?.focus();
    };

    return (
        <TextField
            {...textFieldProps}
            inputRef={mergeRefs([refs.triggerRef as React.Ref<any>, inputRef])}
            textFieldRef={mergeRefs([refs.anchorRef as React.Ref<any>, textFieldRef])}
            // Combobox props
            {...inputProps}
            // Disable native autocomplete
            autoComplete="off"
            value={inputValue}
            onClear={() => handleInputChange('')}
            onChange={handleInputChange}
            clearButtonProps={hasClearButton ? { ...clearButtonProps, label: translateKey(GLOBAL.CLEAR) } : undefined}
            afterElement={
                (!hideToggle || afterElement) && (
                    <>
                        {afterElement}
                        {!hideToggle && (
                            <IconButton
                                onClick={handleManualToggle}
                                label={translateKey(GLOBAL.SHOW_SUGGESTIONS)}
                                emphasis={Emphasis.low}
                                size={Size.s}
                                icon={inputProps['aria-expanded'] ? mdiChevronUp : mdiChevronDown}
                                type="button"
                                tabIndex={-1}
                                aria-expanded={inputProps['aria-expanded']}
                                aria-haspopup="listbox"
                                aria-controls={listboxId}
                            />
                        )}
                    </>
                )
            }
        />
    );
};
