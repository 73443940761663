import { makeParamsSerializer } from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';

import { eventApi, paths } from '.';
import { Event, ASSOCIATED_RESOURCE_TYPE } from './types';

export interface GetEventsParams {
    /* Filter the events with the community they belong to, by their ids */
    communityIds?: string[];
    /* The ids of the events to get */
    ids?: string[];
    /* Filter the events with their title */
    title?: string;
    /* The cursor for pagination purposes */
    cursor?: string;
    /* The maximum number of events to return in the response */
    maxResult?: number;
    /* The sort direction of the events (ascending or descending order) */
    sortDirection?: string;
    /*  Filter the events with the ones that start after this date */
    startAfter?: string;
    /*  Filter the events with the ones that end before this date */
    endBefore?: string;
    /*  Filter the events with the ones that end after this date */
    endAfter?: string;
    /* Filter the events depending on site ids */
    siteIds?: string[];
    /**
     * Return the events associated only to this resource id.
     */
    associatedResourceIds?: string[];
    /**
     * Return the events associated to a given resource type.
     */
    associatedResourceType?: ASSOCIATED_RESOURCE_TYPE;
}

export const getListEvents = (params: GetEventsParams) => {
    return eventApi.get<ServerListResponse<Event>>(paths.events, {
        params,
        paramsSerializer: makeParamsSerializer({ arrayFormat: 'comma', encode: false }),
    });
};

/** Get events. */
export async function getEvents(params: GetEventsParams): Promise<ServerListResponse<Event>> {
    const { data } = await getListEvents(params);

    return data;
}
