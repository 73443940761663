import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Mosaic, Theme, Thumbnail, ThumbnailProps, AspectRatio } from '@lumapps/lumx/react';

import { useImageLightboxProvider } from '../../hooks/useImageLightboxProvider';

import './index.scss';

export interface ImagesAttachmentsProps {
    theme?: Theme;
    images: ThumbnailProps[];
    className?: string;
}
const CLASSNAME = 'images-attachments';

/**
 * Display one or more image into a clickable mosaic of images with horizontal aspect ratio.
 *
 * @family Images
 * @param ImagesAttachmentsProps
 * @returns ImagesAttachments
 */
export const ImagesAttachments: React.FC<ImagesAttachmentsProps> = ({ theme, images, className }) => {
    const [isImageLoading, setIsImageLoading] = React.useState(true);
    const { element, block } = useClassnames(CLASSNAME);
    const isHeightProvided = Boolean(images[0].imgProps?.height);
    const { getTriggerProps } = useImageLightboxProvider(images);
    const thumbnails = React.useMemo(() => {
        return images.map((image, index) => ({ ...image, ...getTriggerProps?.({ activeImageIndex: index }) }));
    }, [images, getTriggerProps]);

    return (
        <div className={block([className])}>
            {images.length === 1 && (
                <Thumbnail
                    theme={theme}
                    className={element('image')}
                    alt={images[0].alt || ''}
                    image={images[0].image}
                    aspectRatio={isHeightProvided ? AspectRatio.original : AspectRatio.horizontal}
                    {...getTriggerProps?.()}
                    imgProps={
                        isHeightProvided
                            ? {
                                  style: isImageLoading
                                      ? {
                                            height: `${images[0].imgProps?.height}px`,
                                        }
                                      : undefined,

                                  onLoad: () => {
                                      if (isImageLoading) {
                                          setIsImageLoading(false);
                                      }
                                  },
                              }
                            : undefined
                    }
                />
            )}
            {images.length > 1 && <Mosaic theme={theme} thumbnails={thumbnails} />}
        </div>
    );
};

ImagesAttachments.displayName = 'ImagesAttachments';
