import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

import { LOADING_PAGE_ID } from '../constants';
import { RouterState, RouterStatus } from './types';

const routerSelector = (state: BaseStore): RouterState => state.router || {};
const routerStatusSelector = createSelector(routerSelector, (router) => router.status);
const routerDataSelector = createSelector(routerSelector, (router) => router.data);
const routerIsLoading = createSelector(routerStatusSelector, (status) => status === RouterStatus.loading);
const blockedRedirectionSelector = createSelector(routerSelector, (router) => router.blockedRedirection);
const currentPageSelector = createSelector(routerSelector, (router) => router.currentPage);
const pageTitleSelector = createSelector(routerSelector, (router) => router.pageTitle);
/**
 * The route blocking the redirections.
 */
const getBlockingRoute = createSelector(blockedRedirectionSelector, (blockedStatus) => blockedStatus?.blockingRoute);

const isRedirectionBlocked = createSelector(
    getBlockingRoute,
    (blockingRoute) => blockingRoute === window.location.href,
);

/**
 * Check if a page is currently loading.
 * A page is considered "loading"
 * * if the router is at "loading" status
 * * the current page ID is "loading-page", generally used for lazy loading fallbacks.
 */
const isPageLoading = createSelector([currentPageSelector, routerIsLoading], (currentPage, routerLoading) => {
    return routerLoading || currentPage === LOADING_PAGE_ID;
});

export {
    routerSelector,
    routerStatusSelector,
    routerIsLoading,
    blockedRedirectionSelector,
    getBlockingRoute,
    isRedirectionBlocked,
    routerDataSelector,
    currentPageSelector,
    pageTitleSelector,
    isPageLoading,
};
