import React from 'react';

import { ClientComputedBreadcrumb } from '@lumapps/widget-breadcrumb/components/ClientComputedBreadcrumb';
import { WIDGET_BREADCRUMB_TYPE } from '@lumapps/widget-breadcrumb/constants';
import { ClientComputedCalendar } from '@lumapps/widget-calendar/components/ClientComputedCalendar';
import { WIDGET_CALENDAR_TYPE } from '@lumapps/widget-calendar/constants';
import { ClientComputedComments } from '@lumapps/widget-comments/components/ClientComputedComments';
import { WIDGET_COMMENTS_TYPE } from '@lumapps/widget-comments/constants';
import { ClientComputedContactForm } from '@lumapps/widget-contact-form/components/ClientComputedContactForm';
import { WIDGET_CONTACT_FORM_TYPE } from '@lumapps/widget-contact-form/constants';
import { ClientComputedContentFilter } from '@lumapps/widget-content-filter/components/ClientComputedContentFilter';
import { WIDGET_CONTENT_FILTER_TYPE } from '@lumapps/widget-content-filter/constants';
import { ClientComputedFileList } from '@lumapps/widget-file-list/components/ClientComputedFileList';
import { WIDGET_FILE_LIST_TYPE } from '@lumapps/widget-file-list/constants';
import { ClientComputedHelp } from '@lumapps/widget-help/components/ClientComputedHelp';
import { WIDGET_HELP_TYPE } from '@lumapps/widget-help/constants';
import { ClientComputedIframe } from '@lumapps/widget-iframe/components/ClientComputedIframe';
import { WIDGET_IFRAME_TYPE } from '@lumapps/widget-iframe/constants';
import { ClientComputedLearningCatalog } from '@lumapps/widget-learning-catalog/components/ClientComputedLearningCatalog';
import { WIDGET_LEARNING_CATALOG_TYPE } from '@lumapps/widget-learning-catalog/constants';
import { ClientComputedCertificate } from '@lumapps/widget-learning-certificate/components/ClientComputedLearningCertificate';
import { WIDGET_LEARNING_CERTIFICATE_TYPE } from '@lumapps/widget-learning-certificate/constants';
import { ClientComputedLeaderboard } from '@lumapps/widget-learning-leaderboard/components/ClientComputedLearningLeaderboard';
import { WIDGET_LEARNING_LEADERBOARD_TYPE } from '@lumapps/widget-learning-leaderboard/constants';
import { ClientComputedLinks } from '@lumapps/widget-links';
import { WIDGET_LINKS_TYPE } from '@lumapps/widget-links/constants';
import { ClientComputedMandatoryRead } from '@lumapps/widget-mandatory-read/components';
import { WIDGET_MANDATORY_READ } from '@lumapps/widget-mandatory-read/constants';
import { ClientComputedMetadataSelection } from '@lumapps/widget-metadata-selection/components/ClientComputedMetadataSelection';
import { WIDGET_METADATA_TYPE } from '@lumapps/widget-metadata-selection/constants';
import { ClientComputedMetadata } from '@lumapps/widget-metadata/components/ClientComputedMetadata';
import { WIDGET_META_TYPE } from '@lumapps/widget-metadata/constants';
import { ClientComputedMicroApp } from '@lumapps/widget-micro-app/components/ClientComputedMicroApp';
import { WIDGET_MICRO_APP_TYPE } from '@lumapps/widget-micro-app/constants';
import { ClientComputedPlay } from '@lumapps/widget-play/components/ClientComputedPlay';
import { WIDGET_PLAY_TYPE } from '@lumapps/widget-play/constants';
import { ClientComputedPlaylist } from '@lumapps/widget-playlist/components/ClientComputedPlaylist';
import { WIDGET_PLAYLIST_TYPE } from '@lumapps/widget-playlist/constants';
import { ClientComputedPostDetails } from '@lumapps/widget-post-details/components/ClientComputedPostDetails';
import { WIDGET_POST_DETAILS_TYPE } from '@lumapps/widget-post-details/constants';
import { ClientComputedRecommendedTrainingCourses } from '@lumapps/widget-recommended-training-courses/components/ClientComputedRecommendedTrainingCourses';
import { WIDGET_RECOMMENDED_TRAINING_COURSES_TYPE } from '@lumapps/widget-recommended-training-courses/constants';
import { ClientComputedShare } from '@lumapps/widget-share/components/ClientComputedShare';
import { WIDGET_SHARE_TYPE } from '@lumapps/widget-share/constants';
import { ClientComputedSiteList } from '@lumapps/widget-site-list/components/ClientComputedSiteList';
import { WIDGET_SITE_LIST_TYPE } from '@lumapps/widget-site-list/constants';
import { ClientComputedSocialMetadata } from '@lumapps/widget-social-metadata/components/ClientComputedSocialMetadata';
import { WIDGET_SOCIAL_META_TYPE } from '@lumapps/widget-social-metadata/constants';
import { ClientComputedSubNavigation } from '@lumapps/widget-sub-navigation/components/ClientComputedSubNavigation';
import { WIDGET_SUB_NAVIGATION_TYPE } from '@lumapps/widget-sub-navigation/constants';
import { ClientComputedSurvey } from '@lumapps/widget-survey/components/ClientComputedSurvey/ClientComputedSurvey';
import { WIDGET_SURVEY_TYPE } from '@lumapps/widget-survey/constants';
import { ClientComputedTeamKpi } from '@lumapps/widget-team-kpi/components/ClientComputedTeamKpi';
import { WIDGET_TEAM_KPI_TYPE } from '@lumapps/widget-team-kpi/constants';
import { ClientComputedTrainingCoursesInProgress } from '@lumapps/widget-training-courses-in-progress/components/ClientComputedTrainingCoursesInProgress';
import { WIDGET_TRAINING_COURSES_IN_PROGRESS_TYPE } from '@lumapps/widget-training-courses-in-progress/constants';
import { ClientComputedUserKpi } from '@lumapps/widget-user-kpi/components/ClientComputedUserKpi';
import { WIDGET_USER_KPI_TYPE } from '@lumapps/widget-user-kpi/constants';
import { ClientComputedVideo } from '@lumapps/widget-video/components/ClientComputedVideo';
import { WIDGET_VIDEO_TYPE } from '@lumapps/widget-video/constants';

import type { ClientComputedProps, ClientComputedWidgetTypes } from '../../types/client-computed';

/**
 * Index of client computed components by widget type.
 */
export const CLIENT_COMPUTED_RENDERERS: {
    [widgetType in ClientComputedWidgetTypes]: React.FC<ClientComputedProps<any>>;
} = {
    [WIDGET_BREADCRUMB_TYPE]: ClientComputedBreadcrumb,
    [WIDGET_COMMENTS_TYPE]: ClientComputedComments,
    [WIDGET_CALENDAR_TYPE]: ClientComputedCalendar,
    [WIDGET_IFRAME_TYPE]: ClientComputedIframe,
    [WIDGET_SOCIAL_META_TYPE]: ClientComputedSocialMetadata,
    [WIDGET_META_TYPE]: ClientComputedMetadata,
    [WIDGET_LINKS_TYPE]: ClientComputedLinks,
    [WIDGET_VIDEO_TYPE]: ClientComputedVideo,
    [WIDGET_PLAY_TYPE]: ClientComputedPlay,
    [WIDGET_PLAYLIST_TYPE]: ClientComputedPlaylist,
    [WIDGET_FILE_LIST_TYPE]: ClientComputedFileList,
    [WIDGET_CONTACT_FORM_TYPE]: ClientComputedContactForm,
    [WIDGET_METADATA_TYPE]: ClientComputedMetadataSelection,
    [WIDGET_SITE_LIST_TYPE]: ClientComputedSiteList,
    [WIDGET_SHARE_TYPE]: ClientComputedShare,
    [WIDGET_POST_DETAILS_TYPE]: ClientComputedPostDetails,
    [WIDGET_SUB_NAVIGATION_TYPE]: ClientComputedSubNavigation,
    [WIDGET_MANDATORY_READ]: ClientComputedMandatoryRead,
    [WIDGET_CONTENT_FILTER_TYPE]: ClientComputedContentFilter,
    [WIDGET_RECOMMENDED_TRAINING_COURSES_TYPE]: ClientComputedRecommendedTrainingCourses,
    [WIDGET_SURVEY_TYPE]: ClientComputedSurvey,
    [WIDGET_TRAINING_COURSES_IN_PROGRESS_TYPE]: ClientComputedTrainingCoursesInProgress,
    [WIDGET_LEARNING_CATALOG_TYPE]: ClientComputedLearningCatalog,
    [WIDGET_LEARNING_CERTIFICATE_TYPE]: ClientComputedCertificate,
    [WIDGET_TEAM_KPI_TYPE]: ClientComputedTeamKpi,
    [WIDGET_LEARNING_LEADERBOARD_TYPE]: ClientComputedLeaderboard,
    [WIDGET_USER_KPI_TYPE]: ClientComputedUserKpi,
    [WIDGET_HELP_TYPE]: ClientComputedHelp,
    [WIDGET_MICRO_APP_TYPE]: ClientComputedMicroApp,
};
