import { getAttributes } from '@lumapps/data-attributes';
import loFind from 'lodash/find';

/////////////////////////////

function CommunityBlockController(
    $scope,
    $state,
    $stateParams,
    Community,
    Config,
    Features,
    Instance,
    Style,
    Translation,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * If the current community belongs to the current instance.
     *
     * @type {boolean};
     */
    vm.belongsToTheCurentInstance = true;

    /**
     * The list of fields to display in the post block.
     * Each field has a boolean value indicating if we want to enable it or not.
     *
     * @type {Object}
     */
    vm.fieldsToDisplay = {};

    /**
     * Indicates if we should be showing the follow button or not.
     * The follow button is shown when the user can see the posts of a community.
     *
     * @type {boolean}
     */
    vm.showFollowButton = true;

    /**
     * Indicates if we should be showing the request button or not.
     * The request button is shown when the user is not a contributor of a "Read-only" community.
     *
     * @type {boolean}
     */
    vm.showRequestButton = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.Translation = Translation;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Compute the background image of the community block.
     *
     * @return {Object} The background image.
     */
    function _computeBackgroundImage() {
        if (angular.isUndefinedOrEmpty(vm.community)) {
            vm.backgroundImage = {};
        } else {
            vm.backgroundImage = Utils.getBackgroundImage(vm.community.thumbnail, undefined, false);
        }

        return vm.backgroundImage;
    }

    /**
     * Handle community request access button visibitily.
     */
    function _handleRequestAccessVisibility() {
        const userCanWrite = Community.isWriteable(vm.community);

        vm.showRequestButton =
            Features.hasFeature('advanced_community') &&
            vm.fieldsToDisplay.follow &&
            Community.isReadOnly(vm.community) &&
            !userCanWrite;
    }

    /**
     * Initialize the fields to display in the post block.
     */
    function _initFields() {
        let newFields = angular.isUndefined(vm.fields) ? Config.COMMUNITY_FIELDS : vm.fields;

        if (angular.isObject(newFields) && !angular.isArray(newFields)) {
            newFields = newFields.map((enabled, field) => {
                return { enable: enabled, name: field };
            });
        }

        vm.fieldsToDisplay = {};
        angular.forEach(newFields, function forEachFields(field) {
            if (angular.isUndefinedOrEmpty(field)) {
                return;
            }

            let newField = field;
            if (!angular.isObject(field)) {
                newField = {
                    enable: true,
                    name: field,
                };
            }

            vm.fieldsToDisplay[newField.name] = newField.enable;
        });

        _handleRequestAccessVisibility();

        // The follow button should appear if the user can post or if the community is read-only but not description-only
        vm.showFollowButton = Community.isWriteable(vm.community) || !Community.isDescriptionOnly(vm.community);

        vm.fieldsToDisplay.thumbnail = angular.isUndefinedOrEmpty(vm.fieldsToDisplay.thumbnail)
            ? true
            : vm.fieldsToDisplay.thumbnail;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get community block classes.
     *
     * @return {Array} An array of community block classes.
     */
    function getClasses() {
        const classes = [];

        const blockSize = vm.blockSize || 'l';
        classes.push(`community-block--size-${blockSize}`);

        if (vm.fieldsToDisplay.content) {
            classes.push('community-block--has-content');
        } else {
            classes.push('community-block--no-content');
        }

        if (vm.theme === 'dark') {
            classes.push('community-block--theme-dark');
        } else {
            classes.push('community-block--theme-light');
        }

        if (vm.viewMode === 'grid') {
            classes.push('community-block--view-mode-grid');
        } else {
            classes.push('community-block--view-mode-list');
        }

        if (vm.viewModeVariant === 'ungroup') {
            classes.push('community-block--view-mode-variant-ungroup');
        } else {
            classes.push('community-block--view-mode-variant-group');
        }

        return classes;
    }

    /**
     * Get community block style according to widget style properties in ungroup variant.
     *
     * @return {Object} The community block style.
     *
     * Todo [Arnaud]: this should be computed in the higher level list component
     * (computed once for all blocks rather than once PER block).
     */
    function getStyle() {
        if (angular.isUndefinedOrEmpty(vm.widgetProperties)) {
            return {};
        }

        return Style.adjustShadow(Style.getBlockStyle(vm.widgetProperties, vm.viewMode, vm.viewModeVariant));
    }

    /**
     * Get data-attributes
     */
    function getScope() {
        const id = vm.community?.uid || vm.community?.id;
        return !id ? '' : `community-${id}`;
    }

    /////////////////////////////

    vm.getClasses = getClasses;
    vm.getStyle = getStyle;
    vm.getScope = getScope;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        _computeBackgroundImage();
        _initFields();

        /**
         * The follow button has to be visible for the Social feature.
         *
         * @type {boolean}
         */
        vm.hasSocialFeature = Features.hasFeature('social');

        const { community } = vm;

        if (vm.displayInstance) {
            Instance.getSiblings().then(function onInstanceSiblingsSuccess(fetchedSiblings) {
                vm.instanceDetails = loFind(fetchedSiblings, {
                    id: community.instance,
                });
            });
        }
        Community.getCommunityLink(community)
            .then((communityLink) => {
                vm.communityLink = communityLink;
                $scope.$apply()
            });
    }

    init();
}

/////////////////////////////

/**
 * The comunity block within a community list or a search result.
 *
 * @param {string}  [blockSize]             The size of the block.
 * @param {Object}  community               The comunity to display in the block.
 * @param {boolean} [displayInstance]       Indicate if the community instance name should be displayed.
 * @param {boolean} [displayThumbnail=true] Indicate if the community thumbnail should be displayed.
 * @param {number}  [excerptLimit]          The number of caracter of community description to display.
 * @param {Array}   [fields]                The list of fields displayed in the block.
 * @param {string}  [theme]                 The theme to apply to the components.
 *                                          Can be either light or dark.
 * @param {string}  [viewMode]              The widget view mode.
 * @param {string}  [viewModeVariant]       The widget view mode variant.
 * @param {Object}  [widgetProperties]      The widget properties.
 */

function CommunityBlockDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: CommunityBlockController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            blockSize: '@?',
            community: '<',
            displayInstance: '<?',
            displayThumbnail: '<?',
            excerptLimit: '@?',
            fields: '<?',
            theme: '@?',
            viewMode: '@?',
            viewModeVariant: '@?',
            widgetProperties: '=?',
        },
        templateUrl: '/client/front-office/modules/communities/community/views/community-block.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsCommunityBlock', CommunityBlockDirective);

export { CommunityBlockDirective };
