export enum USER {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452942
     */
    FEED_GROUP_TECHNICAL = 'FEED_GROUP_TECHNICAL',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453154
     */
    LOGIN_CLASSIC_SIGN_IN = 'LOGIN_CLASSIC_SIGN_IN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244407
     */
    INSTANCE_ADMINS = 'ADMIN.NAV.INSTANCE_ADMINS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244421
     */
    USERS_MANAGEMENT = 'ADMIN.NAV.USERS_MANAGEMENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244422
     */
    CUSTOMER_FEEDS = 'ADMIN.NAV.CUSTOMER_FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46136894
     */
    USER_CARD_ACTION_TOOLTIP_PHONE = 'FRONT.USER_CARD.ACTION_TOOLTIP.PHONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46137274
     */
    USER_CARD_ACTION_TOOLTIP_CELLPHONE = 'FRONT.USER_CARD.ACTION_TOOLTIP.CELLPHONE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=46137343
     */
    USER_CARD_ACTION_TOOLTIP_EMAIL = 'FRONT.USER_CARD.ACTION_TOOLTIP.EMAIL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=47049337
     */
    USERPICKER_ADD_PEOPLE = 'USER.USERPICKER.ADD_PEOPLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=47328408
     */
    USERPICKER_HELPER = 'USER.USERPICKER.HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=273859281
     */
    SELECT_GROUP_PRESS_ENTER = 'USER.GROUPPICKER.SELECT_GROUP_PRESS_ENTER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453813
     */
    USER_SETTINGS_LOGOUT = 'USER_SETTINGS_LOGOUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7419492
     */
    ACCOUNT_TYPE = 'ADMIN.ACCOUNT_TYPE',
}
