import { Editor, PathRef, Path, Transforms } from '@lumapps/wrex/slate';

import { ImageGroupElement } from '../types';

/**
 * Update an image node at a given path or pathref.
 */
export function updateImageNode(editor: Editor, attributes: Partial<ImageGroupElement>, at: Path | PathRef) {
    const path = Path.isPath(at) ? at : at.current;
    if (path) {
        Transforms.setNodes(editor, attributes, { at: path });
    }
}
