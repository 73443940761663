import React from 'react';

import { Connector } from 'lumapps-sdk-js';

import { ConnectorListResponse } from '@lumapps/connectors/types';
import { Select, List, ListItem } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useDialogOpenState } from '@lumapps/utils/hooks/useDialogOpenState';

import { fetchExtensionConnectors } from '../../api';
import { MARKETPLACE } from '../../keys';

export interface ConnectorSelectProps {
    extensionId: string;
    initConnectorId?: string;
    initialize?: boolean;
    sitesId?: string;
    value?: Connector;
    onChange: (value?: Connector) => void;
}

export const ConnectorSelect = ({
    extensionId,
    initConnectorId,
    initialize = true,
    sitesId,
    value,
    onChange,
    ...rest
}: ConnectorSelectProps) => {
    const { translateKey } = useTranslate();
    const [isOpen, setIsOpen, open] = useDialogOpenState();
    const [isExtensionConnectorsLoading, setIsExtensionConnectorsLoading] = React.useState(false);

    const handleOnChange = (selected: Connector) => () => {
        onChange(selected);
        setIsOpen(false);
    };

    const [connectorsResponse, setConnectorsResponse] = React.useState<ConnectorListResponse>();

    React.useEffect(() => {
        setIsExtensionConnectorsLoading(false);
    }, [extensionId, sitesId]);

    React.useEffect(() => {
        if (isExtensionConnectorsLoading) {
            return;
        }

        setIsExtensionConnectorsLoading(true);

        fetchExtensionConnectors({ extensionId, site: sitesId }).then(({ data }) => {
            setConnectorsResponse(data);

            if (initialize && data.items.length === 1) {
                onChange(data.items[0]);
                return;
            }

            if (initConnectorId && !value) {
                const realConnector = data.items.find((item) => item.id === initConnectorId);
                if (realConnector) {
                    onChange(realConnector);
                }
            }
        });
    }, [
        extensionId,
        initConnectorId,
        onChange,
        sitesId,
        value,
        connectorsResponse,
        initialize,
        isExtensionConnectorsLoading,
    ]);

    return (
        <Select
            id="connector-picker"
            name="connector-picker"
            isOpen={isOpen}
            isRequired
            label={translateKey(MARKETPLACE.CONNECTOR)}
            value={value ? value.name : ''}
            onChange={handleOnChange}
            onInputClick={open(!isOpen)}
            onDropdownClose={open(false)}
            clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
            placeholder={translateKey(MARKETPLACE.SELECT_CONNECTOR)}
            {...rest}
        >
            <List>
                {connectorsResponse?.items.map((item) => (
                    <ListItem
                        key={item.id}
                        onItemSelected={handleOnChange(item)}
                        isSelected={value && value.id === item.id}
                    >
                        {item.name}
                    </ListItem>
                ))}
            </List>
        </Select>
    );
};
