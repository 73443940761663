import React from 'react';

import isUndefined from 'lodash/isUndefined';
import { CalendarSpec } from 'moment';

import { Tooltip, Text, TextProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';
import { getMoment } from '@lumapps/utils/time/getMoment';

const moment = getMoment();

export interface RelativeDateProps {
    /** The date to display. */
    date: string;
    /** Formatting options. By default, the formatting is relative until 7 days after date */
    options?: {
        /** The date format applied to the date displayed in the tooltip (see moment format). */
        tooltipDateFormat?: string;
        /** The date format applied to the date when threshold is passed (see moment format). */
        fullDateFormat?: string;
        /** Threshold of days under which we format date as the time from now and over which we display the full date. */
        thresholdDisplayFullDate?: number;
        /**
         * Format to use for the date displayed
         * Can be set to `true` to use the default configuration
         * Can be set to `false` to not use the calendar format.
         */
        calendarFormat?: boolean | CalendarSpec;
    };
    /** The span optional className */
    className?: string;
    /** additional props for the text */
    textProps?: Partial<TextProps>;
}

const isCalendarFormatObject = (calendarFormat: boolean | CalendarSpec | undefined): calendarFormat is CalendarSpec =>
    Boolean(calendarFormat && typeof calendarFormat !== 'boolean');

/**
 * Displays a date relative to the current date. If you need to display wording such as `a few seconds ago, two days ago`
 * this is the component you need.
 *
 * @family Texts
 * @param RelativeDateProps
 * @returns RelativeDate
 */
export const RelativeDate: React.FC<RelativeDateProps> = ({ date, options = {}, className, textProps }) => {
    const {
        fullDateFormat = 'LL',
        thresholdDisplayFullDate = 7,
        tooltipDateFormat = 'LLLL',
        calendarFormat = true,
    } = options;
    const { translateKey } = useTranslate();

    /** Build the default calendar format to use */
    const calendarFormatToUse = calendarFormat
        ? {
              ...(isCalendarFormatObject(calendarFormat) ? calendarFormat : undefined),
              lastDay: `[${translateKey(GLOBAL.YESTERDAY)}]`,
          }
        : undefined;

    return (
        <Tooltip placement="top" label={moment(date).format(tooltipDateFormat)}>
            <Text className={className} as="span" {...textProps}>
                {!isUndefined(fullDateFormat) && !isUndefined(thresholdDisplayFullDate)
                    ? formatFromNow({
                          date,
                          dateStringFormat: fullDateFormat,
                          thresholdDisplayFullDate,
                          calendarFormat: calendarFormatToUse,
                      })
                    : moment.utc(date).local().fromNow()}
            </Text>
        </Tooltip>
    );
};
