(function IIFE() {
    'use strict';

    /////////////////////////////

    function UploadFactory($resource) {
        'ngInject';

        return $resource('/upload', {}, {
            get: {
                isArray: false,
                method: 'GET',
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('UploadFactory', UploadFactory);
})();
