import { MouseEvent, useMemo } from 'react';

import { get as getConstants } from '@lumapps/constants';

import { AppId } from '../constants';
import { RouterData } from '../ducks/types';
import { Route } from '../types';
import { useRouter } from './useRouter';

export interface UseLinkOptions {
    /**
     * Route to go. In order to allow the hook to be called conditionally (when a route is not defined for example)
     * this parameter is optional. If nothing is passed in, the hook will just return a {}.
     */
    route?: Route;
    /** on link clicked */
    onRedirect?: () => void;
    /** target for the link if needed */
    target?: HTMLAnchorElement['target'];
    /** whether the route should be opened in a new tab */
    openInNewTab?: boolean;
    /**
     * Data to be stored during redirection.
     * This can be useful to store data you already have and that you know will need in the
     * next route.
     */
    routerData?: RouterData;
    /**
     * Optional onclick handler.
     */
    onClick?: React.MouseEventHandler;
    /**
     * By default, focus will be restored at the top of the page after a redirection.
     * If you with the focus to remain on the link, set this at true.
     */
    keepFocus?: boolean;
}

export interface UseLinkProps {
    /** url to be used on the Link. Empty if there is no route provided. */
    href?: string;
    /** event to be passed on to the Link for a click callback. */
    onClick?: (event: MouseEvent) => void;
    /** event to be passed on to the Link for a on key press callback. */
    onKeyPress?: (evt: React.KeyboardEvent) => void;
    /** target to be used on the link. */
    target?: HTMLAnchorElement['target'];
    /** rel to be used on the link. */
    rel?: string;
}

/**
 * This hook will return the necessary props to create a link that redirects to another page,
 * while managing whether the page is already migrated or not. Just do:
 * const linkProps = useLinkProps({ route });
 *
 * return <Link {...linkProps} />;
 * @param UseLinkOptions
 */
const useLinkProps = ({
    route,
    target,
    onRedirect,
    openInNewTab = false,
    routerData,
    onClick,
    keepFocus,
}: UseLinkOptions): UseLinkProps => {
    const { getUrl, redirect } = useRouter();
    const url: string | undefined = useMemo(() => (route ? getUrl(route) : undefined), [getUrl, route]);
    const { isLegacyContext } = getConstants();

    const onRedirectTriggered = (forceOpenInNewTab?: boolean) => {
        if (onRedirect) {
            onRedirect();
        }

        if (route) {
            redirect(route, routerData, forceOpenInNewTab || openInNewTab, undefined, keepFocus);
        }
    };

    /**
     * Callback which will catch the `click` event and redirect user to the route given as props.
     */
    const onLinkClick = (event: MouseEvent) => {
        onClick?.(event);
        event.preventDefault();

        // Ctrl or Meta key pressed while opening the link
        // Or middle click
        // => Force open in new tab
        const forceOpenInNewTab = event.ctrlKey || event.metaKey || event.button === 1;

        onRedirectTriggered(forceOpenInNewTab);
    };

    /**
     * Callback which will catch the `keypress` event and redirect user to the route given as props.
     */
    const onKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.key === 'Enter') {
            onRedirectTriggered();
        }
    };

    if (!route) {
        return {};
    }

    const { appId } = route || {};
    const isExternal = appId === AppId.external;

    const getTarget = () => {
        if (openInNewTab) {
            return '_blank';
        }
        /**
         * HEAD'S UP! in order to make sure that we will have the backend responding to our request,
         * we need to indicate to the a element that the target is self. This will trigger a redirect
         * on the browser, forcing the server to manage the routing and deliver the new React application,
         * rather that doing a redirection on the client side.
         *
         * TODO: remove this once we have migrated to REACT
         */
        if (isLegacyContext) {
            return '_self';
        }

        return target || undefined;
    };

    return {
        href: url,
        onClick: isExternal ? undefined : onLinkClick,
        rel: openInNewTab ? 'noopener noreferrer' : '',
        target: getTarget(),
        onKeyPress: isExternal ? undefined : onKeyPress,
    };
};

export { useLinkProps };
