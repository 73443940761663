/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';

const saApi = new BaseApi({ version: BaseApi.versions.v2, path: 'social-advocacy' });

const fetch = (params: { programId?: string | null; maxResults?: number } = {}) => {
    return saApi.get('topics', { params: { maxResults: 1000, ...params } });
};

export { fetch, saApi };
