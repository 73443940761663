import React from 'react';

import { BookmarksMenu } from '@lumapps/bookmarks/components/BookmarksMenu';
import { useClassnames } from '@lumapps/classnames';
import {
    PLACEMENT as CustomizationsPlacement,
    Targets,
    useIsComponentDisabled,
    getTextOverride,
} from '@lumapps/customizations/api';
import { CustomizationComponent } from '@lumapps/customizations/api/components/CustomizationComponent';
import { Favorites } from '@lumapps/directories-favorites/components/Favorites';
import { FavoriteSites } from '@lumapps/instance/components/FavoriteSites';
import { LanguageSwitcher } from '@lumapps/languages/components/LanguageSwitcher';
import { LearningMenu } from '@lumapps/learning-page/components/LearningMenu';
import { FlexBox, Theme } from '@lumapps/lumx/react';
import { PendoTrigger } from '@lumapps/metric/components/PendoTrigger';
import { PENDO_SCOPES } from '@lumapps/metric/constants';
import { NotificationMenu } from '@lumapps/notification-center/components';
import { PlayVideoMenu } from '@lumapps/play/components/PlayVideoMenu';
import { useResponsive } from '@lumapps/responsive';
import { addQueryParamsToUrl } from '@lumapps/router/utils';
import { SAMenu } from '@lumapps/sa-navigation/components/SAMenu';
import { SearchBox, SearchBoxMobile } from '@lumapps/search-box';
import { useSearch } from '@lumapps/search/hooks/useSearch';
import { UserProfileDropdown } from '@lumapps/user/components/UserProfileDropdown';

import { MENU_OFFSET, PLACEMENT } from '../../constants';
import { ContributionMenu } from '../Contribution';
import { HeaderSettings } from './HeaderSettings';

export type HeaderProps = {
    /** components that will be rendered between the searchbox and the actions */
    children?: React.ReactNode;
    /** custom class name for the actions wrapper */
    className: string;
    /** current language of the UI */
    currentLanguage: string;
    /** whether the user is connected or not */
    isConnected?: boolean;
    /** custom styles for the current instance */
    topStyles?: Record<string, any>;
    /** key/object of enabled features */
    features: Record<string, boolean>;
    /** current instance id */
    instanceId: string;
    /** header theme */
    theme?: Theme;
};

const HeaderActions: React.FC<HeaderProps> = ({
    className,
    children,
    isConnected = false,
    topStyles,
    instanceId,
    features = {},
    theme,
    currentLanguage,
}) => {
    const { isMobile } = useResponsive();
    const { element } = useClassnames(className);
    const searchboxClassName = element('search', {
        'not-connected': !isConnected,
    });

    const onLanguageSelected = (language: string) => {
        const currentUrl = window.location.pathname;
        const url = addQueryParamsToUrl(currentUrl, { lang: language });
        window.location.assign(url);
    };

    const headerActionsClassName = element('actions', {
        [`${theme}`]: Boolean(theme),
    });

    const isSearchBoxDisabled = useIsComponentDisabled(Targets.SEARCH_BOX);
    const isContributionButtonDisabled = useIsComponentDisabled(Targets.CONTRIBUTION_BUTTON);
    const isFavoritesDisabled = useIsComponentDisabled(Targets.FAVORITES);
    const isBookmarksDisabled = useIsComponentDisabled(Targets.BOOKMARKS);

    /**
     * If the FF 'search-contextual-searchbox' is activated :
     * - needs to execute a query with a LUMAPPS tab
     * - when no search query is done yet, the searchbox is still in the main page header
     * - when a search query is done : the search box is moved to the ResultsPage component in the search page
     * - if the query change, we keep the previous facet filters for the new search query
     */
    const { hasContextualSearchBox } = useSearch();

    return (
        <>
            {/**
             * Here you will notice that each component is wrapped around a div with a specific class. why?
             * - We need a wrapper around these components in order to be able to control them when they are used on the Header component.
             *   We want our clients to be able to target them and change their styles when these components when they are on the Header,
             *   and not in another context. On our end we want as well to target these components while they are on the header
             * - We need to maintain as much as we can the retrocompatibility with the legacy code, in order to have a lower
             *   impact on our clients code.
             */}
            <div className={headerActionsClassName}>
                {!isConnected || features.languageSwitcher ? (
                    <div className={element('language')}>
                        <LanguageSwitcher styles={topStyles} onChange={onLanguageSelected} />
                    </div>
                ) : null}

                <CustomizationComponent target={Targets.SEARCH_BOX} placement={CustomizationsPlacement.LEFT} />

                {!isSearchBoxDisabled && !hasContextualSearchBox ? (
                    <div className={searchboxClassName}>
                        {isMobile ? (
                            <SearchBoxMobile />
                        ) : (
                            <SearchBox
                                theme={theme}
                                placeholder={getTextOverride(Targets.SEARCH_BOX)[currentLanguage]}
                            />
                        )}
                    </div>
                ) : null}

                <CustomizationComponent target={Targets.SEARCH_BOX} placement={CustomizationsPlacement.RIGHT} />

                {children}

                <CustomizationComponent target={Targets.CONTRIBUTION_BUTTON} placement={CustomizationsPlacement.LEFT} />

                {isConnected && !isContributionButtonDisabled ? (
                    <div className={element('contribution')}>
                        <ContributionMenu styles={topStyles} placement={PLACEMENT} />
                    </div>
                ) : null}

                <CustomizationComponent
                    target={Targets.CONTRIBUTION_BUTTON}
                    placement={CustomizationsPlacement.RIGHT}
                />

                {!isMobile && isConnected && features.favoriteSites ? (
                    <div className={element('favorite-sites')}>
                        <FavoriteSites
                            placement={PLACEMENT}
                            offset={MENU_OFFSET}
                            styles={topStyles}
                            instanceId={instanceId}
                        />
                    </div>
                ) : null}

                {!isMobile && isConnected && !isFavoritesDisabled ? (
                    <FlexBox orientation="horizontal" className={element('directory-favorites')}>
                        <Favorites placement={PLACEMENT} offset={MENU_OFFSET} styles={topStyles} />
                    </FlexBox>
                ) : null}

                {isConnected && !isBookmarksDisabled ? (
                    <div className={element('bookmarked-apps')}>
                        <BookmarksMenu placement={PLACEMENT} offset={MENU_OFFSET} styles={topStyles} />
                    </div>
                ) : null}

                {!isMobile && isConnected && features.socialAdvocacy ? (
                    <div className={element('social-advocacy')}>
                        <SAMenu placement={PLACEMENT} offset={MENU_OFFSET} styles={topStyles} />
                    </div>
                ) : null}
                {isConnected && features.learning ? (
                    <div>
                        <LearningMenu styles={topStyles} />
                    </div>
                ) : null}
                {isConnected && features.play ? (
                    <div className={element('play-video')}>
                        <PlayVideoMenu styles={topStyles} />
                    </div>
                ) : null}

                <CustomizationComponent
                    target={Targets.NOTIFICATIONS_BUTTON}
                    placement={CustomizationsPlacement.LEFT}
                />

                {isConnected ? (
                    <div className={element('notifications')}>
                        <NotificationMenu placement={PLACEMENT} offset={MENU_OFFSET} styles={topStyles} />
                    </div>
                ) : null}

                <CustomizationComponent
                    target={Targets.NOTIFICATIONS_BUTTON}
                    placement={CustomizationsPlacement.RIGHT}
                />

                {!isMobile ? (
                    <div className={element('user-settings')}>
                        <UserProfileDropdown placement={PLACEMENT} offset={MENU_OFFSET} />
                    </div>
                ) : null}

                {isMobile && !isConnected ? (
                    <div className={element('user-settings')}>
                        <UserProfileDropdown placement={PLACEMENT} offset={MENU_OFFSET} />
                    </div>
                ) : null}

                <CustomizationComponent target={Targets.SETTINGS_BUTTON} placement={CustomizationsPlacement.LEFT} />

                <HeaderSettings theme={theme} className={className} />

                <CustomizationComponent target={Targets.SETTINGS_BUTTON} placement={CustomizationsPlacement.RIGHT} />

                {!isMobile && isConnected ? (
                    <div className={element('resource-center')}>
                        <PendoTrigger theme={topStyles?.themeColor} scope={PENDO_SCOPES.HEADER} />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export { HeaderActions };
