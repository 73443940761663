import range from 'lodash/range';

import { Path, ReactEditor } from '@lumapps/wrex/slate';

import { TableEditor } from '../types';
import { getCurrentCellsInRow } from './getCurrentCellsInRow';
import { getCurrentRowsInTable } from './getCurrentRowsInTable';

export const getAllCellsPath = (editor: ReactEditor & TableEditor, tablePath: Path) => {
    const { rowsInTable } = getCurrentRowsInTable(editor, tablePath);
    const { cellsLength } = getCurrentCellsInRow(editor, [...tablePath, 0]);

    const cells = range(cellsLength);

    const CellsPath = rowsInTable.reduce<Path[]>((acc, [, currentRowPath]) => {
        return [...acc, ...cells.map((v, i) => [...currentRowPath, i])];
    }, []);
    return CellsPath;
};
