import { useMemo } from 'react';

import isUndefined from 'lodash/fp/isUndefined';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/isEmpty';

import { useTranslate } from '@lumapps/translations';

import { FormattedSiteFilter, SiteFilteredBy, TagFilteredByReference } from '../types';

/**
 * Get all the currently available values for the tags filter.
 * Filter tagReferences based on the current site ids.
 * */
export const useAvailableTags = (
    tagReferences?: TagFilteredByReference[],
    sites?: FormattedSiteFilter[],
    siteReferences?: SiteFilteredBy[],
    includeSiblingSites?: boolean,
) => {
    const { translateObject } = useTranslate();

    /**
     * Get the id's of all available sites.
     * */
    const availableSiteIds = useMemo(() => {
        /** If the sites are not yet defined return undefined. */
        if (isUndefined(siteReferences)) {
            return undefined;
        }

        /**
         * If there isn't any selected site, but the includeSiblingsSite is true
         * We should consider all siteReferences as active, then tags can be picked from any one of them.
         * */
        if (isEmpty(sites) && includeSiblingSites) {
            return map('id', siteReferences);
        }

        return map('id', sites);
    }, [includeSiblingSites, siteReferences, sites]);

    /**
     * Take all the available tags from tagReferences
     * filters out the ones that are not available for the current configuration (not from an active site)
     * and reformat them to be FormattedTagFilter
     * This is used in order to display the list of tags available in the tag select field.
     * */
    return useMemo(() => {
        /**
         * Wait for the filter-properties to be available (not undefined).
         * */
        if (isUndefined(tagReferences) || isUndefined(availableSiteIds)) {
            return undefined;
        }

        return (
            Object.values(tagReferences)
                /** Remove all tags that are not from an active site */
                .filter((tag) => availableSiteIds.includes(tag.siteId))
                .map((tag) => ({
                    name: tag.name.value ?? (translateObject(tag.name.translations) as string),
                    id: tag.id,
                    cct: {
                        name:
                            tag.contentTypeName.value ?? (translateObject(tag.contentTypeName.translations) as string),
                        id: tag.contentTypeId,
                    },
                    site: { name: tag.siteName, id: tag.siteId },
                }))
        );
    }, [availableSiteIds, tagReferences, translateObject]);
};
