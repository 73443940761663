import * as React from 'react';
import { bool, func } from 'prop-types';

import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import * as types from '../types';
import * as styleTypes from './types';
import { translate as t } from '../../translations';
import { withOnChangeProperty } from '../../utils';
import { DEFAULT_CELL_STYLE, DEFAULT_PLAIN_CELL_STYLE, DEFAULT_NOT_PLAIN_CELL_STYLE } from './constants';
import { AccordionBox } from '../ui';
import { SpacingSettings } from './settings/SpacingSettings';
import { BorderSettings } from './settings/BorderSettings';
import { BackgroundSettings } from './settings/background/BackgroundSettings';

/**
 * Renders a cell style editor.
 */
export class CellStyle extends React.PureComponent {
    static propTypes = {
        /** Define if the cell is in plain mode */
        isPlain: bool,
        name: types.properties,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: func.isRequired,
        /** Current row style. */
        value: styleTypes.cellStyle,
    };

    constructor(props) {
        super(props);
        withOnChangeProperty(this);
    }

    render() {
        const { isPlain, value = {} } = this.props;
        let defaults = cloneDeep(DEFAULT_CELL_STYLE);
        // Manage the modifications  when the cell is in plain mode.
        defaults = merge(
            defaults,
            isPlain ? cloneDeep(DEFAULT_PLAIN_CELL_STYLE) : cloneDeep(DEFAULT_NOT_PLAIN_CELL_STYLE),
        );

        return (
            <div className="widget-style-main__settings-wrapper">
                <AccordionBox label={t('STYLE.SPACINGS')} variant="simple">
                    <SpacingSettings
                        value={value.components}
                        name="components"
                        onChange={this.onChangeProperty}
                        defaults={defaults.components}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BORDERS')} variant="simple">
                    <BorderSettings
                        value={value.components}
                        name="components"
                        onChange={this.onChangeProperty}
                        defaults={defaults.components}
                    />
                </AccordionBox>
                <AccordionBox label={t('STYLE.BACKGROUND')} variant="simple">
                    <BackgroundSettings
                        value={value.components}
                        name="components"
                        onChange={this.onChangeProperty}
                        defaults={defaults.components}
                        shadow
                    />
                </AccordionBox>
            </div>
        );
    }
}
