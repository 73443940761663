/* eslint-disable id-blacklist */

import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import { uploadSecuredDocumentForCommunity } from '@lumapps/medias-document/api';

/**
 * Upload service.
 */
function UploadService(
    $http,
    $q,
    DocumentFactory,
    LxNotificationService,
    Media,
    MediaFactory,
    Translation,
    UploadFactory,
    Utils,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Contains the form that will be used to upload the file.
     *
     * @type {Object}
     */
    let _uploadForm = {
        specificUploadUrl: '',
        uploadUrl: '',
    };

    /**
     * Contains the status of all upload, indexed by a list key.
     *
     * @type {Object}
     */
    const _uploadStatus = {
        default: false,
    };

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Document type constant.
     *
     * @type {string}
     * @constant
     */
    service.DOCUMENT_TYPE = 'document';

    /**
     * Contains the files being uploaded.
     *
     * @type {Array}
     */
    service.files = [];

    /**
     * Contains the list of callbacks to execute when an upload has been done.
     * External observers have the ability to register callbacks to execute at the end of a given upload.
     *
     * @type {Array}
     */
    service.observerCallbacks = [];

    /**
     * Contains the progress of all uploads.
     *
     * @type {Object}
     */
    service.progress = {
        current: '0',
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get upload url for "Document" endpoint.
     *
     * @param {Object}   params Request parameters.
     * @param {Function} cb     Function called after upload url as been requested.
     */
    function _createDocumentUploadUrl(params, cb) {
        DocumentFactory.createUpload(params, (result) => {
            _uploadForm.uploadUrl = result.uploadUrl;

            if (angular.isDefinedAndFilled(result.httpMethod)) {
                _uploadForm.uploadMethod = result.httpMethod;
            }

            if (angular.isDefinedAndFilled(result.formDataField)) {
                _uploadForm.formDataField = result.formDataField;
            }
            cb(result);
        });
    }

    /**
     * Get upload url for standard endpoint.
     * @param {Object} params Request parameters.
     */
    function _createStandardUploadUrl(params, cb = noop) {
        UploadFactory.get(params, (result) => {
            _uploadForm.uploadUrl = result.uploadUrl;
            cb();
        });
    }

    /**
     * Notify the observers of new events.
     */
    function _notifyObservers() {
        angular.forEach(service.observerCallbacks, (cb) => {
            if (!angular.isFunction(cb)) {
                return;
            }

            cb();
        });
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the progress value.
     *
     * @return {string} The value of the progress.
     */
    function getProgressValue() {
        return service.progress.current;
    }

    /**
     * Return the file that will be uploaded.
     *
     * @return {Object} The file that will be uploaded.
     */
    function getUploadFile() {
        return _uploadForm.file;
    }

    /**
     * Load a new upload url from the server's upload handler.
     *
     * @param {Object}   [params={}]      Additional parameters
     * @param {string}   [type=undefined] Type of request endpoint (document or undefined).
     * @param {Function} [cb=undefined]   Function called after.
     */
    function loadUploadUrl(params, type, cb) {
        params = angular.fastCopy(params) || {};

        _uploadForm.uploadUrl = '';
        _uploadForm.uploadMethod = 'POST';
        _uploadForm.formDataField = undefined;

        angular.extend(params, {
            success: '/upload',
        });

        type === service.DOCUMENT_TYPE ? _createDocumentUploadUrl(params, cb) : _createStandardUploadUrl(params);
    }

    function loadUploadUrlPromise(params = {}, type) {
        return new Promise((resolve) => {
            _uploadForm.uploadUrl = '';
            _uploadForm.uploadMethod = 'POST';
            _uploadForm.formDataField = undefined;

            angular.extend(params, {
                success: '/upload',
            });

            if (type === service.DOCUMENT_TYPE) {
                _createDocumentUploadUrl(params, resolve);
            } else {
                _createStandardUploadUrl(params, resolve);
            }
        });
    }

    /**
     * Intercept the paste event in order to upload the files present in the clipboard.
     * @param {Event}    evt  The paste event.
     * @param {Function} [cb] The callback method called after the upload of files succeed.
     */
    function handlePaste(evt, cb) {
        cb = cb || angular.noop;

        if (angular.isUndefinedOrEmpty(get(evt, 'originalEvent.clipboardData.files'))) {
            return;
        }
        service.uploadMultiple(evt.originalEvent.clipboardData.files, {}, cb, () => {
            LxNotificationService.error(Translation.translate('FILE_UPLOAD_ERROR'));
        });

        evt.preventDefault();
    }

    /**
     * Get upload request parameters as form data.
     *
     * @param  {Array}  files     Files to upload.
     * @param  {string} uploadUrl Upload url.
     * @param  {Object} dataModel Data model.
     *
     * @return {Object} Request configuration.
     */
    function _getFormDataUploadRequestConfig(files, uploadUrl, dataModel) {
        return {
            data: {
                files,
                model: angular.toJson(dataModel),
            },
            headers: {
                'Content-Type': undefined,
            },
            method: 'POST',
            transformRequest: (data) => {
                const formData = new FormData();
                const fileField = _uploadForm.formDataField || 'file';

                angular.forEach(data.files, (file) => {
                    formData.append(fileField, file);
                });
                formData.append('model', data.model);

                return formData;
            },
            url: uploadUrl,
        };
    }

    /**
     * Get upload request parameters.
     *
     * @param  {Array}  file      File to upload.
     * @param  {string} uploadUrl Upload url.
     * @return {Object} Request configuration.
     */
    function _getUploadRequestConfig(file, uploadUrl) {
        file = file[0];

        return {
            data: file,
            headers: {
                'Content-Range': `bytes 0-${file.size - 1}/${file.size}`,
                'Content-Type': undefined,
            },
            method: _uploadForm.uploadMethod || 'POST',
            url: uploadUrl,
        };
    }

    /**
     * Display a notification if there is no file to upload.
     *
     * @param  {Object | Array} files File or list of files to upload.
     * @return {boolean}        Whether or not there is files to upload.
     */
    function _hasFileToUpload(files) {
        if (angular.isUndefinedOrEmpty(files)) {
            LxNotificationService.error(Translation.translate('NO_FILE'));

            return false;
        }

        return true;
    }

    /**
     * Check files limit and display a notification if reached.
     *
     * @param  {Array}   files Files to upload.
     * @return {boolean} Is files limit reached.
     */
    function _isFilesLimitReached(files) {
        if (angular.isArray(files) && files.length > 50) {
            LxNotificationService.error(Translation.translate('FILE_UPLOAD_LIMIT_EXCEEDED'));

            return true;
        }

        return false;
    }

    /**
     * Set uploading files service attribute.
     *
     * @param {Array} files Files to upload.
     */
    function _setFiles(files) {
        service.files.length = 0;

        angular.forEach(files, (file) => {
            service.files.push({
                name: file.name,
            });
        });
    }

    /**
     * Set upload status.
     *
     * @param {boolean} status Is uploading status.
     * @param {string}  [id]   Id of upload.
     */
    function _setUploadStatus(status, id) {
        _uploadStatus.default = status;

        if (angular.isDefinedAndFilled(id)) {
            _uploadStatus[id] = status;
        }
    }

    /**
     * Send the HTTP request to upload the file.
     *
     * @param  {string}   uploadUrl         The url to which upload the file.
     * @param  {Object}   dataModel         The data model.
     * @param  {Function} [cb]              The function to execute when the upload has succeed.
     * @param  {Function} [errCb]           The function to execute when the upload has failed.
     * @param  {string}   [id="default"]    The id of the upload.
     * @param  {Array}    files             The files to upload.
     * @param  {boolean}  [isFormData=true] File(s) should be sent with a form data.
     * @return {Promise}  The promise of the upload.
     */
    function httpRequest(uploadUrl, dataModel, cb, errCb, id, files, isFormData) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;
        isFormData = angular.isDefined(isFormData) ? isFormData : true;

        const { formDataField } = _uploadForm;
        const config =
            angular.isDefinedAndFilled(formDataField) || isFormData
                ? _getFormDataUploadRequestConfig(files, uploadUrl, dataModel)
                : _getUploadRequestConfig(files, uploadUrl);

        return $http(config)
            .then((response) => {
                cb(response.data, files);
                _uploadForm = {};
                return response.data;
            })
            .catch(errCb)
            .finally(() => {
                _setUploadStatus(false, id);

                if (isFormData) {
                    loadUploadUrl();
                }
            });
    }

    /**
     * Get the status of the upload.
     *
     * @param  {string}  id The id of the upload we want to get the status of.
     * @return {boolean} The status of the wanted upload.
     */
    function isUploading(id) {
        return angular.isDefinedAndFilled(id) ? _uploadStatus[id] : _uploadStatus.default;
    }

    /**
     * Attach the files to the content (post/comment).
     *
     * @param {Array}    files           The uploaded files.
     * @param {Object}   currentContent  The current content to define the media to attach.
     * @param {Object}   content         The content (post/comment) to attach files.
     * @param {boolean}  [isMulti=false] Define the possibility to upload multiple files.
     * @param {Object}   dialogSettings  The settings for the events.
     * @param {Function} [cb]            The callback function called at the end of the upload.
     */
    function onFileUploadSuccess(files, currentContent, content, isMulti, dialogSettings, cb) {
        let fileType = '';
        const mediaToSave = [];
        let dialogId = angular.isDefinedAndFilled(content.files)
            ? get(dialogSettings, 'files.key')
            : get(dialogSettings, 'images.key');

        files = angular.isArray(files) ? files : [files];

        if (angular.isUndefinedOrEmpty([files, dialogId], 'some')) {
            return;
        }

        angular.forEach(files, (file) => {
            mediaToSave.push(Media.getMediaFromFile(file, currentContent));
        });

        MediaFactory.saveMulti(
            {
                items: mediaToSave,
            },
            function onSaveMultiSuccess(response) {
                if (angular.isDefinedAndFilled(response.items)) {
                    angular.forEach(response.items, (file) => {
                        fileType = file.content[0].type;

                        if (isMulti) {
                            if (Media.isImage(fileType)) {
                                content.images.push(file);
                            } else if (Media.isDocument(fileType)) {
                                content.files.push(file);

                                dialogId = dialogSettings.files.key;
                            }
                        } else if (fileType.indexOf('image') === 0) {
                            content.images = file;
                            content.files = [];
                        } else if (fileType.indexOf('document') === 0) {
                            content.files = file;
                            content.images = [];

                            dialogId = dialogSettings.files.key;
                        }
                    });
                }

                service.setProgressValue(0);
                cb(dialogId);
            },
        );
    }

    /**
     * Register an observer.
     *
     * @param {Function} cb The callback to execute for this observer.
     */
    function registerObserverCallback(cb) {
        if (!angular.isFunction(cb)) {
            return;
        }

        service.observerCallbacks.push(cb);
    }

    /**
     * Set the progress value.
     *
     * @param {number|string} progress The progress value.
     */
    function setProgressValue(progress) {
        service.progress.current = String(progress);
        _notifyObservers();
    }

    /**
     * Keep in memory the file that the user wants to upload.
     *
     * @param {Object} fileToUpload The file the user wants to upload.
     */
    function setUploadFile(fileToUpload) {
        _uploadForm.file = fileToUpload;
    }

    /**
     * Upload a file.
     *
     * @param {Object}   dataModel      The data model.
     * @param {Function} [cb]           The function to execute when the upload has succeed.
     * @param {Function} [errCb]        The function to execute when the upload has failed.
     * @param {string}   [id="default"] The id of the upload.
     */
    function upload(dataModel, cb, errCb, id) {
        service.uploadMultiple([_uploadForm.file], dataModel, cb, errCb, id);
    }

    /**
     * Upload multiple files.
     *
     * @param  {Array}    files          The files to upload.
     * @param  {Object}   dataModel      The data model.
     * @param  {Function} [cb]           The function to execute when the upload has succeed.
     * @param  {Function} [errCb]        The function to execute when the upload has failed.
     * @param  {string}   [id="default"] The id of the upload.
     * @return {Promise}  Upload promise.
     */
    async function uploadMultiple(files, dataModel, cb, errCb, id) {
        if (!_hasFileToUpload(files) || _isFilesLimitReached(files)) {
            return $q.reject();
        }

        _setFiles(files);
        _setUploadStatus(true, id);

        if (!_uploadForm.uploadUrl) {
            await loadUploadUrlPromise();
        }

        return httpRequest(_uploadForm.uploadUrl, dataModel, cb, errCb, id, files, true);
    }

    /**
     * Upload to haussmann API
     *
     * @param  {Object}   community      The current community.
     * @param  {Array}    file           The file to upload.
     * @param  {string}   lang           The lang of the media
     * @param  {Function} [cb]           The function to execute when the upload has succeed.
     * @param  {Function} [errCb]        The function to execute when the upload has failed.
     * @return {Promise}  Upload promise.
     */
    async function uploadMediaSecured(community, file, lang, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;
        return uploadDocumentHaussmann({
            community,
            file,
            fileName: file.name,
            lang,
            parentPath: `provider=haussmann_media/resource=${community.id}/drive=${community.id}`,
            shared: false,
            success: '/upload',
        })
            .then((response) => {
                cb(response.data);
            })
            .catch(errCb);
    }

    /**
     * Upload multiple files on multiple endpoints.
     *
     * @param  {Array}   files          The files to upload.
     * @param  {string}  [id="default"] The id of the upload.
     * @return {Promise} Upload promise.
     */
    function uploadMultipleEndpoints(files, id) {
        if (!_hasFileToUpload(files) || _isFilesLimitReached(files)) {
            return $q.reject();
        }

        const uploadsId = `${id}-all`;

        _setFiles(map(files, 'file'));
        _setUploadStatus(true, id);
        _setUploadStatus(true, uploadsId);

        return $q
            .all(
                map(files, (obj) =>
                    httpRequest(
                        obj.uploadUrl,
                        undefined,
                        undefined,
                        (exception) => Utils.displayServerError(exception),
                        id,
                        [obj.file],
                        false,
                    ),
                ),
            )
            .finally(() => {
                _setUploadStatus(false, uploadsId);
            });
    }

    /////////////////////////////

    service.getProgressValue = getProgressValue;
    service.getUploadFile = getUploadFile;
    service.handlePaste = handlePaste;
    service.httpRequest = httpRequest;
    service.onFileUploadSuccess = onFileUploadSuccess;
    service.isUploading = isUploading;
    service.loadUploadUrl = loadUploadUrl;
    service.loadUploadUrlPromise = loadUploadUrlPromise;
    service.registerObserverCallback = registerObserverCallback;
    service.setProgressValue = setProgressValue;
    service.setUploadFile = setUploadFile;
    service.upload = upload;
    service.uploadMultiple = uploadMultiple;
    service.uploadMediaSecured = uploadMediaSecured;
    service.uploadMultipleEndpoints = uploadMultipleEndpoints;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        /* eslint-disable angular/window-service */

        // Hack to be able to mofidy XHRHttpRequest with $http.
        if (!window.XMLHttpRequest || window.FileAPI) {
            return;
        }

        window.XMLHttpRequest.prototype.setRequestHeader = ((original) =>
            function customXMLHttpRequest(header, value) {
                if (header === '__setXHR_') {
                    const val = value(this);

                    if (val instanceof Function) {
                        val(this);
                    }
                } else {
                    // eslint-disable-next-line prefer-rest-params
                    original.apply(this, arguments);
                }
            })(window.XMLHttpRequest.prototype.setRequestHeader);

        /* eslint-enable angular/window-service */
    }

    init();
}

/////////////////////////////

angular.module('Services').service('Upload', UploadService);

export { UploadService as Upload };
