import { FrontOfficeStore } from '@lumapps/redux/types';

import { getWidgetLoadingState, getWidgetPaginationType } from '../ducks/selectors';

export const mapLoadingStateToProps = (state: FrontOfficeStore, props: any) => {
    const { path, loadingState, paginationType } = props;
    const widgetId = path?.[0];
    return {
        loadingState: loadingState || getWidgetLoadingState(state, { widgetId }),
        paginationType: paginationType || getWidgetPaginationType(state, { widgetId }),
    };
};
