import React from 'react';
import { node, shape, bool, string } from 'prop-types';

import get from 'lodash/get';

import { VIEW_MODE_VARIANTS_VALUES } from './constants';
import { isDesignerMode } from '../../utils';
import { translate as t } from '../../translations';
import { getWidgetContentStyle } from '../../services/styles';
import { DEFAULT_WIDGET_STYLE } from '../styles/constants';

/**
 * Displays the widget itself or a placeholder if the current widget is empty.
 *
 * @return {ReactElement} Widget content react element.
 */
const WidgetContent = ({ children, properties, isEditing = false, isWidgetEmpty, widgetType, ...props }) => {
    const { viewModeVariant, noResults, emptyMessage } = properties;

    return (
        <div
            className="widget-content"
            style={viewModeVariant === VIEW_MODE_VARIANTS_VALUES.UNGROUP || (isWidgetEmpty && !noResults) ? null : getWidgetContentStyle(properties)}
            {...props}
        >
            <div className="widget-wrapper">
                {isDesignerMode() && !isEditing && isWidgetEmpty ? (
                    <div
                        className="widget-content__placeholder"
                        style={{
                            backgroundColor:
                                get(properties.style, ['main', 'backgroundColor']) === (undefined || 'transparent') &&
                                get(properties.style, ['main', 'backgroundImage']) === undefined
                                    ? DEFAULT_WIDGET_STYLE.main.backgroundColor
                                    : get(properties.style, ['main', 'backgroundColor']),
                            ...getWidgetContentStyle(properties, false),
                        }}
                    >
                        <span className="widget-content__placeholder-title">
                            {t(`WIDGET_TYPE_${widgetType.toUpperCase()}`)}
                        </span>
                        {t(properties.helper) && (
                            <span className="widget-content__placeholder-helper">{t(properties.helper)}</span>
                        )}
                    </div>
                ) : (
                    children
                )}

                {noResults && isWidgetEmpty && !isDesignerMode() && (
                    <div className="no-results">
                        <i className="mdi mdi-information-outline" />
                        <span>{t(emptyMessage) || t('NO_RESULTS')}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

WidgetContent.propTypes = {
    /** The Widget component to display. */
    children: node.isRequired,
    /** Whether the widget is currently beeing edited (title, html...). */
    isEditing: bool.isRequired,
    /** Whether the widget is empty. */
    isWidgetEmpty: bool.isRequired,
    /** Widget's properties. */
    properties: shape().isRequired,
    /** Widget's type. */
    widgetType: string.isRequired,
};

export { WidgetContent };
