export enum CUSTOM_CONTENT_TYPE_PERMISSIONS {
    CUSTOM_CONTENT = 'CUSTOM_CONTENT',
    CUSTOM_CONTENT_READ = 'CUSTOM_CONTENT_READ',
    CUSTOM_CONTENT_DELETE = 'CUSTOM_CONTENT_DELETE',
    CUSTOM_CONTENT_EDIT = 'CUSTOM_CONTENT_EDIT',
    CUSTOM_CONTENT_PUBLISH = 'CUSTOM_CONTENT_PUBLISH',
    CUSTOM_CONTENT_ARCHIVE = 'CUSTOM_CONTENT_ARCHIVE',
}

export enum CUSTOM_CONTENT_TYPE_CONTRIBUTION_MODES {
    DESIGNER = 'DESIGNER',
    WRITER = 'WRITER',
}
