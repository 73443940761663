import { LEGACY_DEFAULT_POST_TYPES } from '@lumapps/communities/constants';

(function IIFE() {
    'use strict';

    /////////////////////////////

    angular.module('Constants').constant('CommunityWizardConfiguration', {
        COMMUNITY_TYPES: {
            /* eslint-disable sort-keys */
            share: 'share',
            ideas: 'ideas',
            questions: 'questions',
            collaborate: 'collaborate',
            /* eslint-enable sort-keys */
        },
        DEFAULT_POST_TYPES: LEGACY_DEFAULT_POST_TYPES,
        DRIVE_TYPE_CHOICES: {
            create: 'create',
            createTeam: 'createTeam',
            select: 'select',
        },
        OPTION_ELEMENTS: {
            calendar: 'calendar',
            dashboard: 'dashboard',
            securedMedia: 'securedMedia',
            media: 'media',
            post: 'post',
        },
        OPTION_PRESET: {
            collaborate: {
                options: ['post', 'media', 'calendar'],
                postTypes: ['EVENT', 'DEFAULT'],
            },
            ideas: {
                options: ['dashboard', 'post'],
                postTypes: ['IDEA'],
            },
            questions: {
                options: ['dashboard', 'post'],
                postTypes: ['QUESTION'],
            },
            share: {
                options: ['post'],
                postTypes: ['DEFAULT'],
            },
        },
        POST_TYPES: ['IDEA', 'EVENT', 'QUESTION', 'DEFAULT'],
    });
})();
