import mdast from 'mdast';

import { SerializeOptions, ElementConverter } from '@lumapps/wrex/serialization/markdown/toMarkdown/types';

const cell: ElementConverter<any> = (_, children) => ({ type: 'tableCell', children }) as mdast.TableCell;

export const TABLE_ELEMENTS: SerializeOptions['elements'] = {
    table: (_, children) => ({ type: 'table', children }) as mdast.Table,
    tr: (_, children) => ({ type: 'tableRow', children }) as mdast.TableRow,
    td: cell,
};
