import React from 'react';

import { ListWidgetStyleSetting } from '@lumapps/widget-settings/components/ListWidgetStyleSetting';
import { StyleSettingType } from '@lumapps/widget-settings/types';

import { DATA_SCOPE } from '../../../constants';
import { WidgetEventListViewModeType, WidgetEventListViewModeVariantType } from '../../../type';

export interface WidgetEventListStylesProps {
    className?: string;
    currentStylesSetting?: StyleSettingType<WidgetEventListViewModeType, WidgetEventListViewModeVariantType>;
    onChange: (properties: StyleSettingType<WidgetEventListViewModeType, WidgetEventListViewModeVariantType>) => void;
}

export const WidgetEventListStyles: React.FC<WidgetEventListStylesProps> = ({
    className,
    currentStylesSetting,
    onChange,
}) => {
    return (
        <ListWidgetStyleSetting<WidgetEventListViewModeType, WidgetEventListViewModeVariantType>
            onChange={onChange}
            currentStylesSetting={currentStylesSetting}
            className={className}
            dataScope={DATA_SCOPE}
        />
    );
};
