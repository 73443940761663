import React from 'react';

import { Alignment } from '@lumapps/lumx/react';
import { BlockImage } from '@lumapps/widget-base/components/Block/BlockImage';
import { BlockComponent } from '@lumapps/widget-base/types';

import { BlockFeaturedImage as BlockFeaturedImageProps } from '../../type';

import './index.scss';

/**
 * Block that allows rendering a featured image on a page.
 */
export const BlockFeaturedImage: BlockComponent<BlockFeaturedImageProps> = (props) => {
    const { image, theme } = props;

    return (
        <BlockImage
            {...image}
            theme={theme}
            thumbnailProps={{
                fillHeight: !image.coverRatio,
                align: Alignment.center,
                // Featured image are usually important in a page and should be loaded in priority.
                loading: 'eager',
            }}
        />
    );
};

BlockFeaturedImage.displayName = 'BlockFeaturedImage';
