import { connect } from '@lumapps/redux/react';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { isLoadingSuggestionsSelector, searchTagSuggestionsSelector } from '../../ducks/selectors';
import { changeSearchTagName } from '../../ducks/thunks';
import { TagsAutocomplete } from './TagsAutocomplete';

import './index.scss';

const mapStateToProps = (state: FrontOfficeStore) => {
    const isLoading = isLoadingSuggestionsSelector(state);
    const tagSuggestions = searchTagSuggestionsSelector(state);

    return {
        isLoading,
        tagSuggestions,
    };
};

const mapDispatchToProps = {
    onTagNameChange: changeSearchTagName,
};

const ConnectedTagsAutocomplete = connect(mapStateToProps, mapDispatchToProps)(TagsAutocomplete);

export { ConnectedTagsAutocomplete as TagsAutocomplete };
