import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Orientation, Size, SkeletonCircle, SkeletonTypography, Theme, Typography } from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'site-block-skeleton';

export const SiteBlockSkeleton: React.FC<{
    theme?: Theme;
    orderedInfo: string[];
    isFavoriteIconVisible: boolean;
    orientation: Orientation;
}> = ({ theme, orderedInfo, isFavoriteIconVisible, orientation }) => {
    const { element, block } = useClassnames(CLASSNAME);
    const buildOrderedInfo = () => {
        return orderedInfo.map((element) => {
            switch (element) {
                case 'title':
                    return <SkeletonTypography key="title" theme={theme} typography={Typography.title} width="60%" />;

                case 'name':
                    return <SkeletonTypography key="name" theme={theme} typography={Typography.title} width="80%" />;

                case 'subtitle':
                    return (
                        <SkeletonTypography key="subtitle" theme={theme} typography={Typography.body1} width="40%" />
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <FlexBox fillSpace orientation={orientation} className={block(`orientation-${orientation}`)}>
            <FlexBox className={element('content')} fillSpace orientation={Orientation.vertical}>
                {buildOrderedInfo()}
            </FlexBox>
            {isFavoriteIconVisible && (
                <SkeletonCircle
                    theme={theme}
                    size={Size.s}
                    style={{
                        alignSelf: 'center',
                    }}
                />
            )}
        </FlexBox>
    );
};
