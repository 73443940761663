import includes from 'lodash/includes';

/////////////////////////////

function CommunityMemberListController(
    $rootScope,
    $scope,
    $timeout,
    Community,
    CommunityConstant,
    InitialSettings,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The name of the method we use to call the community/user/list endpoint.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    const _GET_USER_LIST_METHOD = 'getUserList';

    /**
     * In the case of duplicated community member list on the same page,
     * use this flag to avoid listKey collision to indicate whether we want
     * to listen to an event to update member list.
     *
     * @type {boolean}
     */
    let _waitForResponseEvent = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The user list key identifier.
     *
     * @type {string}
     * @constant
     */
    vm.USER_LIST_KEY = 'community-user-';

    /**
     * The list of user to display.
     *
     * @type {Array}
     */
    vm.users = [];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.InitialSettings = InitialSettings;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Get the user list to display.
     */
    function _getUserList() {
        if (angular.isUndefinedOrEmpty(vm.community.uid)) {
            return;
        }

        // If the service is already defined, do not fetch the list an other time.
        if (Community._services[vm.USER_LIST_KEY]) {
            if (Community.isCallInProgress(vm.USER_LIST_KEY)) {
                _waitForResponseEvent = true;

                return;
            }

            // If we previously fetched more than `vm.maxUserDisplay` value.
            if (Community.displayList(vm.USER_LIST_KEY).length >= vm.maxUserDisplay) {
                vm.users = Community.displayList(vm.USER_LIST_KEY).slice(0, vm.maxUserDisplay);

                return;
            }
        }

        vm.users = [];

        Community.initList(vm.USER_LIST_KEY);

        // If the community object doesn't contains enough users, fetch them.
        Community.getUserList(
            {
                maxResults: vm.maxUserDisplay,
                uid: vm.community.uid,
            },
            (users) => {
                vm.users = users;
                $rootScope.$broadcast('community-member-update', vm.USER_LIST_KEY);
            },
            () => {
                _waitForResponseEvent = false;
            },
            vm.USER_LIST_KEY,
            CommunityConstant.PROJECTION.USER_LIST,
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Display the community members dialog.
     */
    function displayAllUsers() {
        // Wrap in timeout because the code below triggers multiple broadcast and `Utils.waitForAndExecute`.
        $timeout(() => {
            const params = {
                methodName: _GET_USER_LIST_METHOD,
                params: {
                    uid: vm.community.uid,
                },
                projection: CommunityConstant.PROJECTION.USER_LIST,
                serviceName: 'Community',
                title: Translation.translate('COMMUNITY_USERS'),
            };
            $rootScope.$broadcast('user-list-dialog__open', params);
        });
    }

    /////////////////////////////

    vm.displayAllUsers = displayAllUsers;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * Called when community save is successful.
     *
     * @param {Event}  evt         The original event triggering this method.
     * @param {string} communityId Id of the edited community.
     */
    $scope.$on('admin-community-save-success', (evt, communityId) => {
        if (communityId === vm.community.id) {
            vm.community = Community.getCurrent();

            vm.init();
        }
    });

    /**
     * Called when user filterize is successful.
     *
     * @param {Event}  evt     The original event triggering this method.
     * @param {string} listKey The list key corresponding to the filterize.
     */
    $scope.$on('community-member-update', (evt, listKey) => {
        if (listKey === vm.USER_LIST_KEY && _waitForResponseEvent) {
            vm.users = Community.displayList(vm.USER_LIST_KEY);
            _waitForResponseEvent = false;
        }
    });

    /////////////////////////////

    /**
     * Initialize controller.
     */
    vm.init = function init() {
        vm.maxUserDisplay = vm.maxUserDisplay || 4;
        vm.more = angular.isDefined(vm.more) ? vm.more : true;

        if (angular.isUndefinedOrEmpty(vm.community)) {
            return;
        }

        if (!includes(vm.USER_LIST_KEY, vm.community.uid)) {
            vm.USER_LIST_KEY += vm.community.uid;
        }

        if (User.isConnected()) {
            _getUserList();
        }
    };

    vm.init();
}

/////////////////////////////

/**
 * Displays the list of users for a community.
 *
 * @param {Object}  community          The community we want to display the users from.
 * @param {number}  [maxUserDisplay=4] The max number of users we want to display.
 * @param {boolean} [more=true]        Whether we want to display the `more` button.
 */

function CommunityMemberListDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: CommunityMemberListController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            community: '<',
            maxUserDisplay: '<?',
            more: '<?',
        },
        templateUrl: '/client/front-office/modules/communities/member-list/views/community-member-list.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsCommunityMemberList', CommunityMemberListDirective);

/////////////////////////////

export { CommunityMemberListController, CommunityMemberListDirective };
