import BaseApi from '@lumapps/base-api';

/////////////////////////////

const weatherApi = new BaseApi({ path: 'weather' });

/**
 * Get the weather forecast for a given place.
 *
 * @param  {string}  address        The adress to use to get the forecast.
 * @param  {string}  defaultAddress The default adress to use.
 * @param  {string}  unit           The unit to use for the temperature.
 * @return {Promise} The promise of the get call.
 */
const get = ({ address, defaultAddress, unit } = {}) => {
    return weatherApi.get('get', {
        params: {
            address,
            defaultAddress,
            unit,
        },
    });
};

export { get };
