import isNil from 'lodash/isNil';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { injectReducer } from '@lumapps/redux/manager';
import { connect } from '@lumapps/redux/react';
import { Dispatch, FrontOfficeStore } from '@lumapps/redux/types';
import { debounceThunk } from '@lumapps/redux/utils/debounceThunk';

import { actions, reducer } from '../../ducks/eventPreviewSlice';
import { eventEntitySelector } from '../../ducks/selectors';
import { fetchEventsByIds } from '../../ducks/thunks/fetchEventsByIds';
import { EventPreviewWrapper } from './EventPreviewWrapper';

injectReducer('eventPreview', reducer);

const mapStateToProps = (state: FrontOfficeStore, props: any) => {
    const entity = eventEntitySelector(props.id)(state);
    const isLegacy = !isNil(props.event.isFromLegacy);

    return {
        event: isLegacy ? entity : props.event,
        isLoading: isLegacy && entity === undefined,
        isLegacy,
    };
};

const mapDispatchToProps = {
    pushBatchIdEvent: (eventId: string) => (dispatch: Dispatch) => {
        dispatch(actions.pushBatchIdEvent(eventId));
    },
    fetchEvents: debounceThunk(fetchEventsByIds, DEBOUNCE_DELAY),
};

const ConnectedEventPreviewWrapper = connect(mapStateToProps, mapDispatchToProps)(EventPreviewWrapper);

export { ConnectedEventPreviewWrapper as EventPreviewWrapper, mapStateToProps, mapDispatchToProps };
