import produce from 'immer';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';

import {
    SET_FORM_FIELD_VALUE,
    SET_FIELD_ERROR,
    SET_FORM_ERRORS,
    RESET_FORM,
    SET_FIELD_META,
    SET_FORM_FIELD_VALUES,
} from './form_actions';

export const createFormReducer = (namespace = '', initialState = {}, validationSchema) => {
    const formatValues = (values) => ({
        fields: Object.entries(values).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: {
                    isTouched: false,
                    value,
                },
            }),
            {},
        ),
        hasError: false,
        isTouched: false,
    });

    const validateDraft = (draft) => {
        if (!validationSchema) {
            return;
        }

        Object.entries(validationSchema).forEach(([fieldId, validator]) => {
            const fieldValue = get(draft, ['fields', fieldId, 'value']);
            const { hasError, errorMessage } = validator(fieldValue);
            set(draft, ['fields', fieldId, 'hasError'], Boolean(hasError));

            if (hasError) {
                set(draft, ['fields', fieldId, 'errorMessage'], errorMessage);
            } else {
                unset(draft, ['fields', fieldId, 'errorMessage']);
            }
        });
    };

    return produce((draft, action) => {
        switch (action.type) {
            case `${namespace}/${SET_FORM_FIELD_VALUE}`: {
                const { fieldId, value } = action.payload;
                set(draft, ['fields', fieldId, 'value'], value);
                set(draft, ['fields', fieldId, 'isTouched'], true);
                set(draft, ['isTouched'], true);

                validateDraft(draft);

                return draft;
            }

            case `${namespace}/${SET_FORM_FIELD_VALUES}`: {
                const { values } = action.payload;
                const newDraft = formatValues(values);

                validateDraft(newDraft);

                return newDraft;
            }

            case `${namespace}/${SET_FIELD_META}`: {
                const { fieldId, metaName, metaValue } = action.payload;
                set(draft, ['fields', fieldId, metaName], metaValue);
                set(draft, ['fields', fieldId, 'isTouched'], true);
                set(draft, ['isTouched'], true);

                return draft;
            }

            case `${namespace}/${SET_FIELD_ERROR}`: {
                const { fieldId, hasError, errorMessage } = action.payload;
                set(draft, ['fields', fieldId, 'hasError'], hasError);

                if (errorMessage) {
                    set(draft, ['fields', fieldId, 'errorMessage'], errorMessage);
                }

                const formHasError = Object.values(draft.fields).some((field) => field.hasError);
                set(draft, ['hasError'], Boolean(formHasError));

                return draft;
            }

            case `${namespace}/${SET_FORM_ERRORS}`: {
                const { errors } = action.payload;

                Object.keys(draft.fields).forEach((fieldId) => {
                    const { errorMessage, hasError } = errors.fieldId || {};

                    set(draft, ['fields', fieldId, 'hasError'], hasError);

                    if (errorMessage) {
                        set(draft, ['fields', fieldId, 'errorMessage'], errorMessage);
                    }
                });

                const formHasError = Object.values(draft.fields).some((field) => field.hasError);
                set(draft, ['hasError'], Boolean(formHasError));

                return draft;
            }

            case `${namespace}/${RESET_FORM}`: {
                return formatValues(initialState);
            }

            default:
                return draft;
        }
    }, formatValues(initialState));
};
