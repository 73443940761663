/* eslint-disable no-use-before-define */
import loFind from 'lodash/find';

/////////////////////////////

/**
 * Used to match the protocol and sub-domain.
 *
 * @type {Regex}
 */
const PROTOCOL_REGEX = /^(https?:)?\/\/(www\.)?/;

/**
 * Contains some common URL pattern for the video website we handle.
 *
 * @type {Object}
 */
const URL_PATTERNS = {
    dailymotion: ['dailymotion.com/video/', 'dai.ly/', 'dailymotion.com/embed/video/'],
    vimeo: ['vimeo.com/', 'player.vimeo.com/video/'],
    youtube: ['youtube.com/watch?v=', 'youtu.be/', 'youtube.com/embed/', 'youtube.googleapis.com/v/'],
};

/////////////////////////////

/**
 * Get the embed code for the video player of a given videoId and website name.
 *
 * @param  {string}         videoId     The id of the video to embed in the player.
 * @param  {string|boolean} websiteName The name of the website the video comes from.
 * @return {string}         The embed code string for the video player.
 */
function getEmbedCode(videoId = null, websiteName = null) {
    if (!videoId || !websiteName) {
        return undefined;
    }

    switch (websiteName) {
        case 'dailymotion':
            return getEmbedDailymotionCode(videoId);

        case 'vimeo':
            return getEmbedVimeoCode(videoId);

        case 'youtube':
            return getEmbedYouTubeCode(videoId);

        default:
            return undefined;
    }
}

/**
 * Get the Dailymotion video player embed code for a given videoId.
 *
 * @param  {string} videoId The id of the video.
 * @return {string} The embed code of the video player for the given video.
 */
function getEmbedDailymotionCode(videoId = null) {
    if (!videoId) {
        return undefined;
    }

    return `<iframe src="//www.dailymotion.com/embed/video/${videoId}" frameborder="0" allowfullscreen></iframe>`;
}

/**
 * Get the Vimeo video player embed code for a given videoId.
 *
 * @param  {string} videoId The id of the video.
 * @return {string} The embed code of the video player for the given video.
 */
function getEmbedVimeoCode(videoId = null) {
    if (!videoId) {
        return undefined;
    }

    return `<iframe src="https://player.vimeo.com/video/${videoId}" frameborder="0" allowfullscreen></iframe>`;
}

/**
 * Get the YouTube video player embed code for a given videoId.
 *
 * @param  {string} videoId The id of the video.
 * @return {string} The embed code of the video player for the given video.
 */
function getEmbedYouTubeCode(videoId = null) {
    if (!videoId) {
        return undefined;
    }

    return `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
}

/**
 * Get a videoId based on a given URL and website name.
 *
 * @param  {string} urlToParse  The video URL to parse.
 * @param  {string} websiteName The website name the video comes from.
 *                              Possible values are: 'dailymotion', 'vimeo' or 'youtube (more will be supported
 *                              later).
 * @return {string} The videoId or undefined if none found.
 */
function getVideoId(urlToParse = null, websiteName = null) {
    if (!urlToParse || !websiteName) {
        return undefined;
    }

    // Protocol and www neutral.
    // eslint-disable-next-line unicorn/no-unsafe-regex
    let cleaned = urlToParse.replace(PROTOCOL_REGEX, '');
    // Remove all extra params from the url.
    cleaned = cleaned.split('&')[0];

    // Find which pattern matches our URL exactly.
    const matchingPattern = loFind(URL_PATTERNS[websiteName], (pattern) => cleaned.indexOf(pattern) === 0);

    return cleaned.substr(matchingPattern.length || 0) || undefined;
}

/**
 * Returns the website name of a video based on its URL.
 * E.g. if we detect the URL as being one of the matching youtube patterns, it will then returns "youtube".
 * Possible return values are: 'dailymotion', 'vimeo' or 'youtube' (more will be supported later).
 *
 * @param  {string} urlToCheck The URL to check our patterns against.
 * @return {string} The website name of the video or false if none found.
 */
function getVideoUrlWebsiteName(urlToCheck = null) {
    if (!urlToCheck) {
        return undefined;
    }

    for (const websiteName in URL_PATTERNS) {
        if (URL_PATTERNS.hasOwnProperty(websiteName)) {
            const found = loFind(URL_PATTERNS[websiteName], (pattern) => urlToCheck.indexOf(pattern) > -1);

            if (found) {
                return websiteName;
            }
        }
    }

    return undefined;
}

export {
    getEmbedCode,
    getEmbedDailymotionCode,
    getEmbedVimeoCode,
    getEmbedYouTubeCode,
    getVideoId,
    getVideoUrlWebsiteName,
    URL_PATTERNS,
};
