import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

/////////////////////////////

function UserOrganizationChartService($q, BaseService, UserDirectory, UserOrganizationChartFactory) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = BaseService.createService(UserOrganizationChartFactory, {
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The Organization chart displayed in the user profile.
     *
     * @type {Object}
     */
    service.orgChart = [];

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Order the users in the organization chart.
     * Users are ordered in alphabetical order of their family name.
     *
     * @param  {Array} userList The user list we want to order.
     * @return {Array} The ordered users list.
     */
    function _orderUsers(userList) {
        return sortBy(userList, 'lastName');
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Init the organization chart on profile display.
     *
     * @param  {Object} displayedUser The displayed user.
     * @return {Object} The org chart tree built the right way.
     */
    function initLegacyOrgChart(displayedUser) {
        return $q(function onResolve(resolve) {
            service.get(
                {
                    content: UserDirectory.displayedUserDirectoryId,
                    email: get(displayedUser, 'email'),
                },
                function onOrganizationChartGetSuccess(response) {
                    const orgChart = response;

                    if (!angular.isArray(orgChart.sameLevel)) {
                        orgChart.sameLevel = [];
                    }

                    orgChart.sameLevel.push(displayedUser);

                    orgChart.sameLevel = _orderUsers(orgChart.sameLevel);
                    orgChart.subLevel = _orderUsers(orgChart.subLevel);

                    resolve(orgChart);
                },
            );
        });
    }

    /**
     * Init the organization chart on profile display.
     *
     * @param {Object} displayedUser The displayed user.
     */
    function initOrgChart(displayedUser) {
        service.orgChart.length = 0;

        service.get(
            {
                content: UserDirectory.displayedUserDirectoryId,
                email: get(displayedUser, 'email'),
            },
            function onOrganizationChartGetSuccess(response) {
                const user = angular.fastCopy(displayedUser);
                const manager = angular.fastCopy(response.manager);
                const sameLevel = _orderUsers(angular.fastCopy(response.sameLevel));
                const subLevel = _orderUsers(angular.fastCopy(response.subLevel));

                user.orgChartProps = {
                    didRequest: true,
                };

                if (angular.isDefinedAndFilled(subLevel)) {
                    user.orgChartProps.children = subLevel;
                }

                if (angular.isDefinedAndFilled(sameLevel)) {
                    sameLevel.unshift(user);
                }

                if (angular.isDefinedAndFilled(manager)) {
                    manager.orgChartProps = {};
                    manager.orgChartProps.children = angular.isDefinedAndFilled(sameLevel) ? sameLevel : [user];

                    service.orgChart.push(manager);
                } else if (angular.isDefinedAndFilled(sameLevel)) {
                    service.orgChart.push(sameLevel);
                } else {
                    service.orgChart.push(user);
                }
            },
        );
    }

    /**
     * Update the organization chart while navigating into it.
     *
     * @param {Object}  user The user to navigate into.
     * @param {boolean} root Whether the user is at the root level of the tree or not..
     */
    function updateOrgChart(user, root) {
        service.get(
            {
                content: UserDirectory.displayedUserDirectoryId,
                email: get(user, 'email'),
            },
            function onOrganizationChartGetSuccess(response) {
                const manager = angular.fastCopy(response.manager);
                const sameLevel = _orderUsers(angular.fastCopy(response.sameLevel));
                const subLevel = _orderUsers(angular.fastCopy(response.subLevel));

                user.orgChartProps.didRequest = true;

                if (angular.isDefinedAndFilled(subLevel) && angular.isUndefinedOrEmpty(user.orgChartProps.children)) {
                    user.orgChartProps.children = subLevel;
                }

                if (angular.isDefinedAndFilled(sameLevel) && root) {
                    angular.forEach(sameLevel, function forEachUserAtSameLevel(userAtSameLevel) {
                        service.orgChart.push(userAtSameLevel);
                    });
                }

                if (angular.isDefinedAndFilled(manager) && root) {
                    manager.orgChartProps = {};
                    manager.orgChartProps.children = service.orgChart;

                    const tempOrgChart = [];
                    tempOrgChart.push(manager);

                    service.orgChart = tempOrgChart;
                }
            },
        );
    }

    /////////////////////////////

    service.initLegacyOrgChart = initLegacyOrgChart;
    service.initOrgChart = initOrgChart;
    service.updateOrgChart = updateOrgChart;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('UserOrganizationChart', UserOrganizationChartService);

/////////////////////////////

export { UserOrganizationChartService };
