import isEmpty from 'lodash/isEmpty';

/**
 * Default max number of results per page used in {fetchAllGen} and {fetchAll}.
 */
const DEFAULT_MAX_RESULT = 30;

interface APIResponse<I> {
    data: {
        items?: I[];
        more?: boolean;
        cursor?: string;
    };
}

type APICall<I> = (params: any) => Promise<APIResponse<I>>;

/**
 * Fetch all items for API call doing automatic pagination into an {AsyncGenerator} to consume on demand.
 *
 * @param  call       An API call function taking `params` in arguments.
 * @param  params     Call params.
 * @param  maxResults Max number of results per page.
 * @return Async iterator of elements fetched by the API call.
 */
async function* fetchAllGen<I>(
    call: APICall<I>,
    params: Record<string, any> = {},
    maxResults = DEFAULT_MAX_RESULT,
): AsyncIterableIterator<I> {
    const page = await call({
        ...params,
        maxResults,
        more: true,
    });

    const { items, more, cursor } = page.data;

    if (items && !isEmpty(items)) {
        // Yield current page items.
        yield* items;
    }

    if (more && cursor) {
        // Paginate on next page via the cursor.
        const nextPages = fetchAllGen(call, { ...params, cursor }, maxResults);
        // Yield next page items.
        yield* nextPages;
    }
}

/**
 * Fetch all items for API call doing automatic pagination into an array.
 *
 * @param  call       An API call function taking `params` in arguments.
 * @param  params     Call params.
 * @param  maxResults Max number of results per page.
 * @return Elements fetched by the API call.
 */
async function fetchAll<I = any>(
    call: APICall<I>,
    params: Record<string, any> = {},
    maxResults = DEFAULT_MAX_RESULT,
): Promise<I[]> {
    const results: Array<I> = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const result of fetchAllGen(call, params, maxResults)) {
        results.push(result);
    }

    return results;
}

export { fetchAllGen, fetchAll };
