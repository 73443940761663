import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { isFeatureEnabled } from '@lumapps/features';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiAccountMultiple } from '@lumapps/lumx/icons';
import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { SEGMENT_ADMIN_FEATURE_TOKEN } from '@lumapps/segment/constants';
import { useTranslate } from '@lumapps/translations';

import { SEGMENT_ADMIN } from '../../keys';
import { getSegmentRoute } from '../../routes';

export interface SegmentSettingMenuProps {
    scope: string;
}

export const SegmentSettingMenu: React.FC<SegmentSettingMenuProps> = ({ scope }) => {
    const { get } = useDataAttributes(scope);
    const isSiteAdmin = useSelector(isInstanceSuperAdmin);
    const isPlatformAdmin = useSelector(isSuperAdmin);
    const isSegmentEnabled = useSelector(isFeatureEnabled(SEGMENT_ADMIN_FEATURE_TOKEN));

    const canAccessSegment = (isSiteAdmin || isPlatformAdmin) && isSegmentEnabled;
    const { translateKey } = useTranslate();

    if (!canAccessSegment) {
        return null;
    }

    return (
        <Menu.Item
            icon={mdiAccountMultiple}
            as={RouterLink}
            to={getSegmentRoute()}
            {...get({ element: 'access-segments' })}
        >
            {translateKey(SEGMENT_ADMIN.SEGMENT_SIDE_NAV_TITLE)}
        </Menu.Item>
    );
};

SegmentSettingMenu.displayName = 'SegmentSettingMenu';
