import React from 'react';

import { isFeatureEnabled } from '@lumapps/features';
import { mdiSparkles } from '@lumapps/lumx/icons';
import { Badge, ColorPalette, Icon } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';

import './index.scss';

export interface AIWidgetBadgeProps {
    isWidgetWithAi: boolean;
}

const CLASSNAME = 'ai-widget-badge';

/**
 * Component to display an AI badge at the top right of the widgets with AI inside the widget picker
 */
export const AIWidgetBadge: React.FC<AIWidgetBadgeProps> = ({ isWidgetWithAi }) => {
    const isAIFeatureEnabled = useSelector(isFeatureEnabled('generative-ai'));

    if (!isWidgetWithAi || !isAIFeatureEnabled) {
        return null;
    }

    return (
        <Badge className={CLASSNAME} color={ColorPalette.blue}>
            <Icon icon={mdiSparkles} />
        </Badge>
    );
};
