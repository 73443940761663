/* istanbul ignore file */
import BaseApi, { BaseApiPromise, ServerListRequest, ServerListResponse } from '@lumapps/base-api';

import { IdeaVote, Post } from '../types';

export const miscApi = new BaseApi({
    path: 'misc',
});

export const urlInfo = (
    url: string,
    key?: string,
    isCancellable = false,
    /** Whether the backend should give the full url when you provide a short link
     * e.g. (https://youtu.be/abcde => https://www.youtube.com/watch?v=abcde)
     * Default to true as it's the expected behavior
     */
    shouldFollowRedirect = true,
): BaseApiPromise<{
    code: number;
    title: string;
    description: string;
    images?: string[];
    url: string;
}> => {
    if (isCancellable) {
        miscApi.cancel('/urlinfo', { url }, key);
    }
    return miscApi.get('/urlinfo', { params: { url, followRedirect: shouldFollowRedirect } }, true, true, key);
};

export const postApi = new BaseApi({
    path: 'community/post',
});

export const get = () => {
    return postApi.get('/get');
};

export interface PostListParams extends ServerListRequest {
    query?: string;
    sortOrder?: string;
    instanceId?: string[];
    lang: string;
    excerptFormat?: 'PLAIN_TEXT' | 'MARKDOWN';
}

export const list = (params: PostListParams) => {
    return postApi.get<ServerListResponse<Post>>('/list', { params });
};

export const getMultiPosts = (postIds: string[], instanceIds: string[], lang: string, fields?: string) => {
    return postApi.post('/search', { ids: postIds, fields, lang, instanceId: instanceIds });
};

export const getById = (postId: string, fields?: string) => {
    return postApi.get<Post>('/get', {
        params: { uid: postId, fields },
    });
};

export const save = (post: any) => {
    return postApi.post('/save', post);
};

export const deletePost = (postId: string) => {
    return postApi.delete('/delete', { params: { uid: postId } });
};

export const movePost = (postId: string, targetCommunityId: string) => {
    return postApi.post(`/${postId}/move/${targetCommunityId}`, {});
};

export const pin = (postId: string, communityId: string) => {
    return postApi.post('/pin', { uid: postId, communityId });
};

export const unpin = (postId: string, communityId: string) => {
    return postApi.post('/unpin', { uid: postId, communityId });
};

export const share = (postId: string, communitiesId: string[], callId: string) => {
    return postApi.post('/share', { postId, addToCommunities: communitiesId, callId });
};

export const discardShare = (postId: string, communitiesId: string[], callId: string) => {
    return postApi.post('/unshare', { postId, removeFromCommunities: communitiesId, callId });
};

export const vote = (voteValue: IdeaVote, postId: string) => {
    return postApi.put(`/${postId}/vote/${voteValue}`, {});
};

export const deleteVote = (postId: string) => {
    return postApi.delete(`/${postId}/vote`, {});
};
