import { Editor, Path, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import { TableEditor } from '../types';
import { isTable } from './isTable';

/**
 * Delete current table
 */

export const deleteTable = (editor: ReactEditor & TableEditor, at: Range | Path | null = editor.selection) => {
    /* first check if selection exists */
    if (!at) {
        return null;
    }

    const tablePath = [Range.isRange(at) ? Range.start(at).path[0] : at[0]];

    /* before deleting the table, check that we're actually in a table */
    const currentEntriesInTable = Array.from(Editor.nodes(editor, { match: isTable, mode: 'highest', at: tablePath }));
    if (!currentEntriesInTable.length) {
        return null;
    }

    /* delete table at current cursor position */
    Transforms.removeNodes(editor, { at: tablePath });

    /* focus a the base root path of the deleted table */
    setTimeout(() => {
        focusAt(editor, [...tablePath]);
    }, 1);

    return tablePath;
};
