import { and } from '@lumapps/utils/function/predicate/and';
import { HTMLElementConvertOption } from '@lumapps/wrex/serialization/html/fromHTML/types';
import { matchAttribute, matchNode } from '@lumapps/wrex/serialization/html/fromHTML/utils';

import { LINK } from '../../../constants';
import { createLink } from '../../../utils/createLink';

type ElementTypes = typeof LINK;

/**
 * Element convert options.
 *
 * Warning: the order of these options does matter!
 */
export const ELEMENTS: { [Element in ElementTypes]: HTMLElementConvertOption<Element> } = {
    link: {
        test: and(
            // Classic HTML a tag
            matchNode({ tagName: 'a' }),
            // href should not be empty
            matchAttribute('href', /.*/),
        ),
        type: LINK,
        transform: ({ properties: { href } }: any, children: any) => {
            return createLink(
                { href },
                // Remove color of pasted links
                children.map((child: any) => ({ ...child, color: undefined })),
            );
        },
    },
};
