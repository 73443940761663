import { FETCH_PROVIDERS_ERROR, FETCH_PROVIDERS_SUCCESS } from './providers_actions';

// Default state.
const defaultState = [];

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_PROVIDERS_ERROR:
            return action.payload;
        case FETCH_PROVIDERS_SUCCESS:
            return action.payload;

        default:
            return state;
    }
};

export { reducer };
