/* eslint-disable import/no-mutable-exports */

import noop from 'lodash/noop';

let getCustomerId = noop;
let getProperty = noop;

/**
 * Binds functions to angular `Customer` service.
 *
 * @param {Customer} Customer The angular Customer service.
 */
function setCustomer(Customer) {
    getCustomerId = Customer.getCustomerId;
    getProperty = Customer.getProperty;
}

/////////////////////////////

export { getCustomerId, getProperty, setCustomer };
