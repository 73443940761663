import React from 'react';

import noop from 'lodash/noop';

import { classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { DiscardButton, DiscardButtonProps } from '@lumapps/lumx-buttons/components/DiscardButton';
import {
    Toolbar,
    Button,
    Emphasis,
    ToolbarProps,
    ButtonProps,
    FlexBox,
    FlexBoxProps,
    Text,
    Theme,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import './index.scss';

export interface FormActionsProps {
    /** callback on form discard */
    onDiscard?: () => void;
    /** callback on form cancel */
    onCancel?: () => void;
    /** callback on form submit */
    onSubmit: (data: any) => void;
    /** additional props for the actions toolbar */
    toolbarProps?: ToolbarProps;
    /** whether the submit action is enabled or not */
    isDisabled?: boolean;
    /** customising props for the cancel button */
    cancelProps?: Partial<ButtonProps>;
    /** customising props for the save button */
    saveProps?: Partial<ButtonProps>;
    /** customising props for the discard button */
    discardProps?: Partial<DiscardButtonProps>;
    /** node to display before the actions */
    before?: React.ReactNode;
    /** status for the current form */
    status?: BaseLoadingStatus;
    /** props for the actions wrapper */
    wrapperProps?: Partial<FlexBoxProps>;
    /** scope, for tracking purposes */
    scope?: string;
    /** whether the form is dirty (has unsaved changes) or not */
    isFormDirty?: boolean;
    /** whether the discard alert dialog should be displayed only when the form is dirty */
    shouldDiscardOnlyWhenFormIsDirty?: boolean;
    /** Theme */
    theme?: Theme;
}

/**
 * Component that displays the default actions for a @lumapps/lumx-forms Form
 * @family Forms
 * @param FormActionsProps
 * @returns FormActions
 */
export const FormActions: React.FC<FormActionsProps> = ({
    onSubmit,
    onCancel,
    onDiscard,
    isDisabled,
    toolbarProps,
    cancelProps,
    saveProps,
    discardProps,
    before,
    status,
    wrapperProps = {},
    scope = 'form-actions',
    isFormDirty = false,
    shouldDiscardOnlyWhenFormIsDirty = false,
    theme = Theme.light,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    const isLoading = status === BaseLoadingStatus.loading;
    let DiscardButtonToDisplay = onDiscard ? (
        <DiscardButton
            onDiscard={isLoading ? noop : onDiscard}
            dialogProps={{
                children: (
                    <Text as="p" typography="body1">
                        {translateKey(GLOBAL.WARNING_UNSAVED_CHANGES)}
                    </Text>
                ),
            }}
            emphasis={Emphasis.medium}
            theme={theme}
            {...get({ element: 'discard' })}
            {...discardProps}
        />
    ) : undefined;

    /**
     * If the option `shouldDiscardOnlyWhenFormIsDirty` is enabled, when the form is dirty we display a regular
     * button that will prevent the component from displaying the Discard Dialog and execute the `onDiscard`
     * directly.
     */
    if (onDiscard && shouldDiscardOnlyWhenFormIsDirty && !isFormDirty) {
        DiscardButtonToDisplay = (
            <Button
                emphasis={Emphasis.medium}
                onClick={isLoading ? noop : onDiscard}
                theme={theme}
                {...get({ element: 'discard' })}
                {...discardProps}
            >
                {discardProps?.children || translateKey(GLOBAL.DISCARD)}
            </Button>
        );
    }

    return (
        <Toolbar
            style={{ paddingRight: '0px' }}
            {...toolbarProps}
            after={
                <FlexBox orientation="horizontal" gap="regular" hAlign="center" {...wrapperProps}>
                    {before}

                    {DiscardButtonToDisplay}

                    {onCancel ? (
                        <Button
                            emphasis={Emphasis.medium}
                            // eslint-disable-next-line react/no-children-prop
                            children={translateKey(GLOBAL.CANCEL)}
                            {...get({ element: 'cancel' })}
                            {...cancelProps}
                            onClick={isLoading ? noop : onCancel}
                            theme={theme}
                        />
                    ) : null}

                    <Button
                        type="submit"
                        className={classnames(isLoading && 'form-actions__action--disabled')}
                        // eslint-disable-next-line react/no-children-prop
                        children={isLoading ? translateKey(GLOBAL.SAVING) : translateKey(GLOBAL.SAVE)}
                        {...get({ element: 'save' })}
                        {...saveProps}
                        onClick={isLoading ? noop : onSubmit}
                        isDisabled={isDisabled}
                        aria-disabled={isLoading}
                        theme={theme}
                    />
                </FlexBox>
            }
        />
    );
};
