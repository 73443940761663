import allPass from 'lodash/fp/allPass';
import anyPass from 'lodash/fp/anyPass';
import entries from 'lodash/fp/entries';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import negate from 'lodash/fp/negate';
import pickBy from 'lodash/fp/pickBy';
import reduce from 'lodash/fp/reduce';
import size from 'lodash/fp/size';

import { FormattedSiteFilter, GenericListFilterType, NGIFilterId } from '../types';

/**
 * Get the number of currently active filters
 * Based on a filter whitelist, with all nil and empty values removed (except for false boolean)
 * */
export const getActiveFilters = (filters: GenericListFilterType, siteReferences?: FormattedSiteFilter[]) => {
    return flow(
        /** Remove all inactive filters */
        pickBy(
            allPass([
                /** Remove all keys that are not supported */
                (_, key) => Object.keys(NGIFilterId).includes(key),
                /**
                 * Remove all empty filters.
                 * Booleans are treated as empty if false.
                 * */
                anyPass([negate(isEmpty), isEqual(true)]),
            ]),
        ),
        /**
         * For each active filters, get the number
         * */
        entries,
        reduce((acc, curr) => {
            const [key, value] = curr;

            switch (key) {
                /**
                 * For the metadata filter, we treat each root metadata as a separated active filter.
                 * */
                case 'metadata':
                    return acc + flow(groupBy('rootId'), size)(value);
                case 'site':
                    return siteReferences && siteReferences.length > 1 ? acc + 1 : acc;
                default:
                    return acc + 1;
            }
        }, 0),
    )(filters);
};
