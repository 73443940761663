import React from 'react';

import { BROADCAST_ICON } from '@lumapps/broadcasts/constants';
import { BROADCASTS } from '@lumapps/broadcasts/keys';
import { broadcastCreateRoute } from '@lumapps/broadcasts/routes';
import { useClassnames } from '@lumapps/classnames';
import { ContentContributionMenu } from '@lumapps/content-types/dropdown';
import { PLACEMENT, Targets } from '@lumapps/customizations/api';
import { CustomizationComponent } from '@lumapps/customizations/api/components/CustomizationComponent';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiMessageText } from '@lumapps/lumx/icons';
import { Placement } from '@lumapps/lumx/react';
import { POSTS } from '@lumapps/posts/keys';
import { useResponsive } from '@lumapps/responsive';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { MENU_OFFSET } from '../../constants';
import { HEADER } from '../../keys';
import { CreateButton } from './CreateButton';
import { useCommunityContributionMenu } from './useCommunityContributionMenu';

import './index.scss';

export interface ContributionMenuProps {
    /** The dropdown placement */
    placement?: Placement;
    /** Whether the contribution menu should be displayed or not depending on permissions */
    isVisible?: boolean;
    /** Whether the current user have access to custom content or not */
    doesHaveAccessToCCT?: boolean;
    /** Is user has access to broadcast */
    isCreateBroadcastEnabled?: boolean;
    /** custom styles passed down to the button. Useful when this component needs to use custom colors  */
    styles?: Record<string, any>;
}

const CLASSNAME = 'contribution-dropdown';
const SCOPE = 'contribution-dropdown';

/**
 * Display a Button or an IconButton with a dropdown containing all contribution entries.
 * Display an IconButton in responsive mode if the viewport is small enough.
 * @param ContributionMenuProps
 */
export const ContributionMenu: React.FC<ContributionMenuProps> = ({
    placement = Placement.BOTTOM_START,
    isVisible = true,
    styles = {},
    doesHaveAccessToCCT,
    isCreateBroadcastEnabled,
}) => {
    const { translateKey } = useTranslate();
    const { isMobile } = useResponsive();
    const { get } = useDataAttributes(SCOPE);
    const { element } = useClassnames(CLASSNAME);
    const buttonRef = React.useRef(null);

    // Community contributions
    // (We can't abstract inside the menu items because we need the dialogs to be mounted when the menu is not open)
    const { communityContribDialogs, onCreatePost } = useCommunityContributionMenu({
        triggerElement: buttonRef,
    });

    if (!isVisible || (!doesHaveAccessToCCT && !onCreatePost)) {
        return null;
    }

    let button: React.ReactNode;
    if (!doesHaveAccessToCCT || isMobile) {
        // Simple button for creating posts only
        button = (
            <CreateButton
                ref={buttonRef}
                theme={styles?.theme}
                variant={isMobile ? 'compact' : 'regular'}
                onClick={onCreatePost}
            />
        );
    } else {
        // Menu button with all contribution options
        button = (
            <Menu.Trigger
                popoverProps={{ placement, offset: MENU_OFFSET }}
                popoverWidth={{ width: REAL_SIZE_FOR_LUMX_SIZE.xxl }}
            >
                <Menu.TriggerButton as={CreateButton} ref={buttonRef} theme={styles?.theme} />

                <Menu className={CLASSNAME}>
                    <CustomizationComponent target={Targets.CONTRIBUTION_MENU} placement={PLACEMENT.ABOVE} />

                    {/* Create post section */}
                    {onCreatePost && (
                        <Menu.Section
                            className={element('section')}
                            label={translateKey(POSTS.NEW_POST)}
                            {...get({ element: 'new-post' })}
                        >
                            <Menu.Item icon={mdiMessageText} onClick={onCreatePost}>
                                {translateKey(HEADER.POST_TYPE_DEFAULT)}
                            </Menu.Item>
                        </Menu.Section>
                    )}

                    {/* Create content section */}
                    <Menu.Section
                        className={element('section')}
                        label={translateKey(HEADER.NEW_CONTENT)}
                        {...get({ element: 'new-content' })}
                    >
                        <ContentContributionMenu />
                    </Menu.Section>

                    {/* Create broadcast section */}
                    {isCreateBroadcastEnabled && (
                        <Menu.Section
                            className={element('section')}
                            label={translateKey(BROADCASTS.NEW_BROADCAST)}
                            {...get({ element: 'new-broadcast' })}
                        >
                            <Menu.Item icon={BROADCAST_ICON} as={RouterLink} to={broadcastCreateRoute}>
                                {translateKey(GLOBAL.BROADCAST)}
                            </Menu.Item>
                        </Menu.Section>
                    )}

                    <CustomizationComponent target={Targets.CONTRIBUTION_MENU} placement={PLACEMENT.UNDER} />
                </Menu>
            </Menu.Trigger>
        );
    }

    return (
        <>
            {button}
            {communityContribDialogs}
        </>
    );
};
