import { useMemo } from 'react';

import { mdiFormatQuoteClose } from '@lumapps/lumx/icons';
import { useTranslate } from '@lumapps/translations';
import { ReactEditor, useSlate } from '@lumapps/wrex/slate';
import { initSelection } from '@lumapps/wrex/slate/utils/initSelection';
import { ToolbarItem } from '@lumapps/wrex/types';

import { QUOTE_BLOCK } from '../../../constants';
import { WREX_QUOTE } from '../../../keys';
import { QuoteEditor } from '../../../types';

export const useQuoteBlockButton = (otherProps?: any) => {
    const { translateKey } = useTranslate();
    const editor = useSlate() as ReactEditor & QuoteEditor;

    const isSelected = editor.isQuoteActive();
    const isDisabled = !editor.isQuoteAllowed();

    const quoteButton = useMemo(() => {
        const onClick = () => {
            if (!editor.selection) {
                initSelection(editor, 'block');
            }
            editor.toggleQuote(editor.selection);
        };

        const quoteButtonProps: ToolbarItem = {
            itemKey: QUOTE_BLOCK,
            type: 'toggle-option',
            icon: mdiFormatQuoteClose,
            tooltipLabel: translateKey(WREX_QUOTE.QUOTE_BLOCK_BUTTON),
            'aria-disabled': isDisabled,
            isChecked: isSelected,
            onClick,
            otherProps,
            verticalModeProps: {
                label: translateKey(WREX_QUOTE.QUOTE_BLOCK_BUTTON),
                tooltipLabel: undefined,
            },
        };

        return quoteButtonProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, isSelected, translateKey, otherProps?.isDisabled]);
    return quoteButton;
};
