import { CustomizationComponent, PLACEMENT, Targets } from '@lumapps/customizations/api';
import { useSelector } from '@lumapps/redux/react';
import { useRouteMatch } from '@lumapps/router';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { getSearchQuery, isInitialState, isSearchInErrorStatus } from '../ducks/selectors';
import { searchRoute } from '../routes';

export const useSearchQueryInParam = () => {
    const match = useRouteMatch<{ query: string }>({ path: searchRoute.path });
    return match ? match.params.query : '';
};

export const useSearchQuery = (isSearchPage: boolean): string => {
    const searchQuery = useSelector(getSearchQuery);
    const querInParam = useSearchQueryInParam();
    const isInError = useSelector(isSearchInErrorStatus);
    const customComponent = CustomizationComponent({
        target: Targets.SEARCH,
        placement: PLACEMENT.REPLACE,
    });
    const isInitial = useSelector(isInitialState) && customComponent === null;

    const currentQuery = isInitial && isSearchPage && !isInError ? querInParam : searchQuery;

    return decodeURIComponentSafe(currentQuery);
};
