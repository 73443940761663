/* istanbul ignore file */
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { isNewsletterAllowed } from '../../ducks/selectors';
import { NewsletterSideNavigation } from './NewsletterSideNavigation';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isNewsletterAllowed(state);

    return {
        isAllowed,
    };
};

const mapDispatchToProps = {};

const ConnectedNewsletterSideNavigation = connect(mapStateToProps, mapDispatchToProps)(NewsletterSideNavigation);

export { ConnectedNewsletterSideNavigation as NewsletterSideNavigation };
