(function IIFE() {
    'use strict';

    /////////////////////////////

    function SocialProfileUserService(LumsitesBaseService, SocialProfileUserFactory) {
        'ngInject';

        return LumsitesBaseService.createLumsitesBaseService(SocialProfileUserFactory, {
            autoInit: false,
        });
    }

    /////////////////////////////

    angular.module('Services').service('SocialProfileUser', SocialProfileUserService);
})();
