import React, { PureComponent } from 'react';
import includes from 'lodash/includes';

import { isUrl } from '@lumapps/utils/string/isUrl';
import { ColorBubbleLetter } from '@lumapps/lumx-texts/components/ColorBubbleLetter';

import { linkType } from './types';
import { getBackgroundImage, redirectTo } from '../../../utils';

/**
 * Display a link block
 */
export class LinkBlock extends PureComponent {
    static propTypes = linkType;

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Triggered when a user click on a link.
     * Redirect the user to the right page/content.
     */
    handleClick() {
        const { link, targetBlank } = this.props.link;
        const prefix = isUrl(link.toLowerCase()) ? '' : 'http://';
        const linkToReach = targetBlank || includes(link, '/') || includes(link, '.') ? prefix + link : link;
        if (!targetBlank && !includes(link, '/') && !includes(link, '.')) {
            redirectTo('app.front.content-get', { slug: linkToReach });
        } else if (targetBlank) {
            window.open(linkToReach, '_blank', 'noopener');
        } else {
            window.location.href = linkToReach;
        }
    }

    render() {
        const { link, title, thumbnail, description } = this.props.link;
        const { hasThumbnail } = this.props;

        return (
            <li>
                <a className="widget-links__link" onClick={this.handleClick} />
                {hasThumbnail && thumbnail && <div className="widget-links__thumbnail" style={getBackgroundImage(thumbnail, 512, undefined, true)} />}
                {hasThumbnail && !thumbnail && (
                    <ColorBubbleLetter
                        className="widget-links__thumbnail widget-links__thumbnail--no-thumbnail"
                        text={title}
                    />
                )}
                <div className="widget-links__wrapper">
                    {title && <span className="widget-links__title">{title}</span>}
                    {!title && <span className="widget-links__title">{link}</span>}
                    {description && <span className="widget-links__description">{description}</span>}
                </div>
            </li>
        );
    }
}
