import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose, mdiGoogleDrive, mdiLaptop, mdiMenuDown, mdiPlay } from '@lumapps/lumx/icons';
import {
    Alignment,
    AspectRatio,
    Button,
    Dropdown,
    Emphasis,
    FlexBox,
    Icon,
    IconButton,
    List,
    ListItem,
    Orientation,
    Size,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    Typography,
} from '@lumapps/lumx/react';
import { usePlayUser } from '@lumapps/play-roles';
import { BlockVideo } from '@lumapps/play-video';
import type { Video } from '@lumapps/play/api/types';
import { usePlayDataAttributes } from '@lumapps/play/hooks/usePlayDataAttributes';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { WIDGET_PLAY } from '../../keys';

import './index.scss';

export interface WidgetPlayUploaderProps {
    /** Used to show upload from Google Drive button */
    isGoogleUser?: boolean;
    /** Loading state of the uploader */
    loading: boolean;
    /** Event called when Google Drive Picker is open  */
    openDrivePickerDialog: () => void;
    /** Event called when VideoPickerDialog is open  */
    openPickerDialog: () => void;
    /** Event called when VideoUploadDialog is open  */
    openUploadDialog: () => void;
    /** Event called when reset video is clicked  */
    resetVideo: () => void;
    /** Video selected in the widget */
    video?: Video;
}

const CLASSNAME = 'widget-play-uploader';

const WidgetPlayUploader = ({
    isGoogleUser,
    loading,
    openDrivePickerDialog,
    openPickerDialog,
    openUploadDialog,
    resetVideo,
    video,
}: WidgetPlayUploaderProps) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();
    const { get } = usePlayDataAttributes();
    const buttonRef = React.useRef(null);
    const [isDropdownOpen, toggleDropdown] = useBooleanState(false);
    const { isAllowedManagingVideos } = usePlayUser();

    const handleUploadVideo = () => {
        toggleDropdown();
        openUploadDialog();
    };

    const handlePickVideo = () => {
        openPickerDialog();
        toggleDropdown();
    };

    const handleUploadFromDriveButtonPress = () => {
        openDrivePickerDialog();
        toggleDropdown();
    };

    return (
        <div className={CLASSNAME}>
            <FlexBox className={element('title')} orientation={Orientation.horizontal}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <h4 className={element('subtitle')}>{translateKey(WIDGET_PLAY.SUBTITLE)}</h4>
            </FlexBox>
            {loading ? (
                <SkeletonRectangle aspectRatio={AspectRatio.wide} variant={SkeletonRectangleVariant.rounded} />
            ) : (
                <div>
                    {video ? (
                        <FlexBox
                            className={element('selected-video')}
                            orientation={Orientation.horizontal}
                            hAlign={Alignment.center}
                        >
                            <BlockVideo
                                alignment={Alignment.center}
                                className={element('video-block')}
                                orientation={Orientation.horizontal}
                                video={video}
                            >
                                <BlockVideo.Figure size={Size.l}>
                                    <BlockVideo.Thumbnail />
                                </BlockVideo.Figure>

                                <BlockVideo.Content>
                                    <BlockVideo.Title as="span" truncate typography={Typography.body1} />
                                </BlockVideo.Content>
                            </BlockVideo>

                            <IconButton
                                label={translateKey(GLOBAL.REMOVE)}
                                className={element('remove-icon')}
                                size={Size.m}
                                emphasis={Emphasis.low}
                                icon={mdiClose}
                                onClick={resetVideo}
                                {...get({
                                    element: 'widget-remove-video-button',
                                    action: 'remove-video',
                                })}
                            />
                        </FlexBox>
                    ) : (
                        <>
                            <Button
                                className={element('button')}
                                emphasis={Emphasis.medium}
                                fullWidth
                                onClick={toggleDropdown}
                                ref={buttonRef}
                                rightIcon={mdiMenuDown}
                            >
                                {translateKey(WIDGET_PLAY.SELECT_VIDEO)}
                            </Button>
                            <Dropdown anchorRef={buttonRef} isOpen={isDropdownOpen}>
                                <List>
                                    {isAllowedManagingVideos && (
                                        <ListItem
                                            before={<Icon icon={mdiLaptop} size={Size.xs} />}
                                            onItemSelected={handleUploadVideo}
                                            size={Size.tiny}
                                        >
                                            {translateKey(WIDGET_PLAY.UPLOAD_VIDEO_FROM_COMPUTER)}
                                        </ListItem>
                                    )}
                                    {isGoogleUser && isAllowedManagingVideos && (
                                        <ListItem
                                            before={<Icon icon={mdiGoogleDrive} size={Size.xs} />}
                                            onItemSelected={handleUploadFromDriveButtonPress}
                                            size={Size.tiny}
                                        >
                                            {translateKey(WIDGET_PLAY.UPLOAD_VIDEO_FROM_DRIVE)}
                                        </ListItem>
                                    )}
                                    <ListItem
                                        before={<Icon icon={mdiPlay} size={Size.xs} />}
                                        onItemSelected={handlePickVideo}
                                        size={Size.tiny}
                                    >
                                        {translateKey(WIDGET_PLAY.PICK_VIDEO_FROM_GALLERY)}
                                    </ListItem>
                                </List>
                            </Dropdown>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export { WidgetPlayUploader };
