import getOr from 'lodash/fp/getOr';
import identity from 'lodash/fp/identity';

import { WIDGET_COMMUNITY_LIST_TYPE } from '@lumapps/widget-community-list/constants';
import { formatGenericFiltersForApi } from '@lumapps/widget-content-filter/utils/formatGenericFiltersForApi';
import { WIDGET_CONTENT_LIST_TYPE } from '@lumapps/widget-content-list/constants';
import { WIDGET_DIRECTORY_ENTRIES_TYPE } from '@lumapps/widget-directory-entries/constants';
import { WIDGET_COMMUNITY_POST_LIST_TYPE } from '@lumapps/widget-post-list/constants';
import { formatPostListFiltersForApi } from '@lumapps/widget-post-list/utils/PostListFilters/formatPostListFiltersForApi';

const WIDGET_FILTERS_FORMATTERS_FOR_API: Record<string, (rawFilters: Record<string, any>) => Record<string, any>> = {
    [WIDGET_COMMUNITY_POST_LIST_TYPE]: formatPostListFiltersForApi,
    [WIDGET_COMMUNITY_LIST_TYPE]: formatGenericFiltersForApi,
    [WIDGET_CONTENT_LIST_TYPE]: formatGenericFiltersForApi,
    [WIDGET_DIRECTORY_ENTRIES_TYPE]: formatGenericFiltersForApi,
};

export const formatWidgetFiltersForApi = (widgetType: string, filters: Record<string, any>) => {
    const formatter = getOr(identity, widgetType, WIDGET_FILTERS_FORMATTERS_FOR_API);
    return formatter(filters);
};
