import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiInformation } from '@lumapps/lumx/icons';
import { ColorPalette, FlexBox, Icon, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { WIDGET_CONTRIBUTION } from '../../keys';

import './index.scss';

const CLASSNAME = 'widget-contribution-settings';

export const WidgetContributionSettings: React.FC = () => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox className={element('wrapper')} hAlign="center" vAlign="center">
            <Icon icon={mdiInformation} color={ColorPalette.dark} className={element('icon')} />
            <Text as="p" typography="body1">
                {translateKey(WIDGET_CONTRIBUTION.SETTINGS_DOUBLE_CLICK_INFO)}
            </Text>
        </FlexBox>
    );
};
