/* istanbul ignore file */
import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { TranslatedMetadataListV2 } from '../types';

export const metadataApiV2 = new BaseApi({
    version: BaseApi.versions.v2,
    path: 'contents',
});

/** Get metadata list by content */
export async function getMetadataByContentId(contentId: string): Promise<TranslatedMetadataListV2> {
    const { data } = await metadataApiV2.getCacheFirst<any>(
        `${contentId}/metadata`,
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        undefined,
        true /* applyBaseConfig */,
        true /* cancelable */,
    );

    return data.metadata;
}
