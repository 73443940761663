import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size, SkeletonRectangle, SkeletonRectangleVariant, Theme } from '@lumapps/lumx/react';

import { NAMESPACE, DESKTOP_SKELETONS_ITEMS_COUNT } from '../../constants';

const CLASSNAME = `${NAMESPACE}-item`;

/**
 * Display a list of skeletons items
 * @param Count the number of skeleton item to display
 */
const MainTopNavigationItemsSkeletons = ({ count = DESKTOP_SKELETONS_ITEMS_COUNT }: { count?: number }) => {
    const { block } = useClassnames(CLASSNAME);
    return (
        <>
            {[...new Array(count)].map((v, index) => (
                <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={block('skeleton')}
                >
                    <SkeletonRectangle
                        variant={SkeletonRectangleVariant.pill}
                        height={Size.xxs}
                        width={Size.xl}
                        theme={Theme.light}
                    />
                </li>
            ))}
        </>
    );
};

export { MainTopNavigationItemsSkeletons, CLASSNAME };
