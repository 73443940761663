function UserFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/user/:methodKey`,
        {},
        {
            accountChoices: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'accountChoices',
                },
                url: `${Config.HAUSSMANN_HOST}/account/list`,
            },

            deleteMulti: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'deleteMulti',
                },
            },

            follow: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'follow',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/user/:id/:methodKey`,
            },

            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            getConnected: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'getConnected',
                },
            },

            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/user/:methodKey`,
            },

            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            search: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'search',
                },
            },

            unfollow: {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'unfollow',
                },
                url: `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/user/:id/:methodKey`,
            },
        },
    );
}

/////////////////////////////

angular.module('Factories').factory('UserFactory', UserFactory);

/////////////////////////////

export { UserFactory };
