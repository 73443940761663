import { Dispatch } from '@lumapps/redux/types';

import { getSpaceMembers } from '../../api';
import { SpaceMembersApiOptions } from '../../types';
import { actions } from './slice';

export const fetchSpaceMembers =
    (id: string, options: SpaceMembersApiOptions = {}) =>
    async (dispatch: Dispatch) => {
        dispatch(actions.request());

        try {
            const isLoadingMore = !!options.cursor;

            const { items, more, cursor } = await getSpaceMembers(id, options);

            dispatch(
                actions.success({
                    users: items.map(({ pictureUrl, fullName, id, role }) => ({
                        fullName,
                        id,
                        role,
                        profilePictureUrl: pictureUrl,
                    })),
                    hasMore: more,
                    cursor,
                    shouldAdd: isLoadingMore,
                }),
            );
        } catch (error) {
            dispatch(actions.failure());
        }
    };
