import { WHITE_LISTED_WIDGETS } from '../../constants';

/**
 * Flatten while keeping "order" of the widgets.
 * @param  {Object} rows The list of rows.
 * @param  {Array}  acc  The flattened rows.
 * @return {Array}  Description.
 */
const flattenWidgets = (rows, acc = []) => {
    rows.forEach((row) => {
        if (row.cells) {
            row.cells.forEach((cell) => flattenWidgets(cell.components, acc));
        }
        acc.push(row);
    });

    return acc;
};

/**
 * Filter Public widgets.
 * @param  {Array} widgetArray The list of widgets.
 * @return {Array} The list of filtered widgets.
 */
const filterPublicWidgets = (widgetArray) =>
    widgetArray.filter(({ widgetType }) => widgetType && WHITE_LISTED_WIDGETS.includes(widgetType));

const getWidgetContent = (widget, state) => {
    const { content, locale } = state;

    if (!locale) {
        return null;
    }

    switch (widget.widgetType) {
        case 'html':
            return {
                content:
                    widget.properties &&
                    widget.properties.content &&
                    (widget.properties.content[locale.current] ||
                        widget.properties.content[locale.fallback] ||
                        widget.properties.content[Object.keys(widget.properties.content)[0]]),
                format: 'html',
                id: widget.uuid,
                style: widget.properties.style,
            };
        case 'title':
            return {
                content:
                    content.title &&
                    (content.title[locale.current] ||
                        content.title[locale.fallback] ||
                        content.title[Object.keys(content.title)[0]]),
                format: 'title',
                id: widget.uuid,
                style: widget.properties.style,
            };
        case 'featured-image':
            return {
                content:
                    content.mediaThumbnail &&
                    content.mediaThumbnail.thumbnail &&
                    (content.mediaThumbnail.thumbnail[locale.current] ||
                        content.mediaThumbnail.thumbnail[locale.fallback] ||
                        content.mediaThumbnail.thumbnail[Object.keys(content.mediaThumbnail.thumbnail)[0]]),
                format: 'image',
                id: widget.uuid,
                style: widget.properties.style,
            };

        default:
        case 'intro':
            return {
                content:
                    widget.properties.content[locale.current] ||
                    widget.properties.content[locale.fallback] ||
                    widget.properties.content[Object.keys(widget.properties.content)[0]],
                format: 'text',
                id: widget.uuid,
                style: widget.properties.style,
            };
    }
};

const getCurrentWidgets = (state) =>
    state.content.template &&
    filterPublicWidgets(flattenWidgets(state.content.template.components)).map((widget) =>
        getWidgetContent(widget, state),
    );

export { getCurrentWidgets };
