import mdast from 'mdast';

import { DeserializeOptions } from '@lumapps/wrex/serialization/markdown/fromMarkdown/types';

import type { LinkElement } from '../../types';
import { createLink } from '../../utils/createLink';

export const LINK_ELEMENTS: DeserializeOptions['elements'] = {
    link: {
        convert: ({ url }: mdast.Link, children) => {
            if (!children.length) {
                return null;
            }
            return createLink({ href: url }, children) as LinkElement;
        },
        isInlineElement: true,
    },
};
