import React from 'react';

import { SideNavigationItem, SideNavigationItemProps } from '@lumapps/lumx-back-office/components/SideNavigation';
import { mdiFormatListChecks } from '@lumapps/lumx/icons';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';

import { canAccessFeaturesManagement } from '../../ducks/selectors';
import { FEATURES_MANAGEMENT } from '../../keys';
import { featuresManagementRoute } from '../../routes';

const FeaturesManagementSideNavigation = (props: Partial<SideNavigationItemProps>) => {
    const isAllowed = useSelector(canAccessFeaturesManagement);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <SideNavigationItem
            icon={mdiFormatListChecks}
            label={translateKey(FEATURES_MANAGEMENT.TITLE)}
            route={featuresManagementRoute}
            {...props}
        />
    );
};

FeaturesManagementSideNavigation.displayName = 'SideNavigationItem';

export { FeaturesManagementSideNavigation };
