import React from 'react';

import noop from 'lodash/noop';

import { UserAccountType } from '@lumapps/user/types';
import { DelayedSuspense } from '@lumapps/utils/components/DelayedSuspense';

import { useConfigurationDialog } from '../../hooks/useConfigurationDialog';
import { CommunityConfigurationDialogProps } from '../../types';

const LazyCommunityConfigurationDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'community-configuration-dialog' */
            './CommunityConfigurationDialog'
        ),
);

export const CommunityConfigurationDialog = (props: CommunityConfigurationDialogProps) => {
    const { isOpen } = props;
    return (
        <DelayedSuspense shouldLoad={isOpen}>
            <LazyCommunityConfigurationDialog {...props} />
        </DelayedSuspense>
    );
};

/**
 * Same props as CommunityConfigurationDialogProps without the props managed by redux.
 */
type ConfigurationDialogProps = Omit<
    CommunityConfigurationDialogProps,
    'communityId' | 'customerId' | 'instanceId' | 'lang' | 'displayedSteps' | 'isOpen' | 'onClose' | 'onOpen'
>;

export const ConfigurationDialog: React.FC<ConfigurationDialogProps> = ({
    openLegacyDrivePicker = noop,
    updateLegacyDrivePicker = noop,
    ...props
}) => {
    const {
        communityId,
        customerId,
        instanceId,
        userLanguage,
        userAccountType,
        userLoginProvider,
        isOpen,
        handleClose,
        displayedSteps,
        isConfigurationEnabled,
    } = useConfigurationDialog();

    if (!isConfigurationEnabled || !communityId) {
        return null;
    }

    return (
        <CommunityConfigurationDialog
            communityId={communityId}
            customerId={customerId}
            instanceId={instanceId}
            userLanguage={userLanguage}
            userAccountType={userAccountType as UserAccountType}
            userLoginProvider={userLoginProvider}
            displayedSteps={displayedSteps}
            isOpen={isOpen}
            onClose={handleClose}
            openLegacyDrivePicker={openLegacyDrivePicker}
            updateLegacyDrivePicker={updateLegacyDrivePicker}
            {...props}
        />
    );
};
