/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const contentAdminRoute: Route = {
    path: '/admin/content/:uid',
    legacyId: 'app.admin.content',
    appId: AppId.legacy,
};

const contentEditPrefix = '/content/edit/';
const contentCreatePrefix = '/content/create/';

const contentEditRoute: Route = {
    // The param names should be the same as in the legacy state.js $stateParams
    path: `${contentEditPrefix}:key`,
    params: {
        contentType: 'content',
        // The content id.
        key: undefined,
    },
    legacyId: 'app.front.content-edit',
    appId: AppId.legacy,
};

export const isDesignerUrl = (slug: string) => {
    return slug.indexOf(contentEditPrefix) === 0 || slug.indexOf(contentCreatePrefix) === 0;
};

export const adminContent = (uid: string): Route => ({
    ...contentAdminRoute,
    params: { customContentType: uid, uid },
});

export const contentEdit = (contentId: string): Route => ({
    ...contentEditRoute,
    params: {
        ...contentEditRoute.params,
        key: contentId,
    },
});
