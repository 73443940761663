import isEmpty from 'lodash/isEmpty';
import loFind from 'lodash/find';

import { getMediaContentByLang } from 'components/services/document';

import { get as getDocument, getMulti, list } from '../../api/documentApi';

/**
 * Fetch documents according to the provided list the user picked.
 *
 * @param  {Object[]} providers The current document providers.
 * @param  {Object}   params    The parameters to use to build the request.
 * @return {Promise}  The request promise.
 */
const getMultiDocumentsTask = (providers, params = {}) => {
    const { files = [] } = params;

    if (isEmpty(files)) {
        return Promise.resolve(files);
    }

    return getMulti({ docPaths: files });
};

/**
 * Fetch a list of item for a given `docPath`.
 *
 * @param  {Object[]} providers The current document providers.
 * @param  {Object}   params    The parameters to use to build the request.
 * @return {Promise}  The request promise.
 */
const fetchDocumentListTask = (providers, params = {}) => {
    const { folder = {}, searchTags = [], searchTypes = [], maxNumber, cursor } = params;
    const { categoryId, providerId, docPath } = folder;
    const currentProvider = loFind(providers, {
        id: providerId,
    });
    const currentCategory = loFind(currentProvider.categories, { id: categoryId });

    const searchParameters = currentCategory ? currentCategory.properties : undefined;

    const queryParameters = {
        cursor,
        docPath,
        searchTags,
        maxResults: maxNumber,
        searchParameters,
        searchTypes,
    };

    return list(queryParameters);
};

/**
 * Fetch a document title.
 *
 * @param  {string}  docPath The docPath of the document.
 * @return {Promise} Resolved with the document title.
 */
const fetchDocumentTitleTask = ({ docPath }) => {
    return getDocument({ docPath }).then((response) => {
        const doc = response.data;
        const { name: title } = getMediaContentByLang(doc, true);

        return { title };
    });
};

export { fetchDocumentListTask, fetchDocumentTitleTask, getMultiDocumentsTask };
