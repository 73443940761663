import { ColorPalette } from '@lumapps/lumx/react';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta } from '../../types';
import { getParentContentTitle } from '../contentUtils';
import { getEventTitle } from '../eventUtils';
import { getEmptyRoute, getEventLink } from '../linksUtils';

const notificationTypeEvent: Record<string, NotificationMeta> = {
    [NotificationTypes.EventNew]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'calendar',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const eventTitle = getEventTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.EVENT_NEW;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                CONTENT_TITLE: eventTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getEventLink,
    },
    [NotificationTypes.EventUpdated]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'calendar',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const eventTitle = getEventTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.EVENT_UPDATED;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                EVENT_TITLE: eventTitle,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getEventLink,
    },
    [NotificationTypes.EventInvitationCreated]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'calendar',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const eventTitle = getEventTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.EVENT_INVITATION_CREATED;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                EVENT_TITLE: eventTitle,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getEventLink,
    },
    [NotificationTypes.EventMention]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'calendar',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const eventTitle = getEventTitle(translation, notification);

            const key = NOTIFICATIONS_CENTER.CONTENT_MENTION_WITH_TITLE_DESCRIPTION;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                CONTENT_TITLE: eventTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getEventLink,
    },
};

export { notificationTypeEvent };
