import isEqual from 'lodash/isEqual';
import partial from 'lodash/partial';

import { Editor, NodeEntry, PathRef, Range, Transforms } from '@lumapps/wrex/slate';
import { createPlugin } from '@lumapps/wrex/slate/plugin';
import { WrexEditor } from '@lumapps/wrex/types';

import { InlineAutocompleteSearch } from '../components/editableBlocks/InlineAutocompleteSearch';
import { InlineAutocompleteHOC } from '../components/editableBlocks/InlineAutocompleteSearch/InlineAutocompleteHOC';
import { INLINE_AUTOCOMPLETE_SEARCH } from '../constants';
import { InlineAutocompleteEditor, InlineAutocompleteOptions } from '../types';
import { insertAutocompletedElement } from '../utils/insertAutocompletedElement';
import { insertAutocompleteSearch } from '../utils/insertAutocompleteSearch';
import { isAutocompleteAllowed } from '../utils/isAutocompleteAllowed';
import { isAutocompleteSearch } from '../utils/isAutocompleteSearch';
import { shouldInsertAutocompleteSearch } from '../utils/shouldTextInsertAutocompleteSearch';

/**
 * A plugin made to handle inline autocomplete
 */
export const withInlineAutocomplete = (options: InlineAutocompleteOptions[]) => {
    let autocompleteSearchPathRef: PathRef | undefined;
    let currentSearchOption: InlineAutocompleteOptions | undefined;

    /** Unref & unset the current autocomplete search */
    function closeAutocompleteSearch(editor: Editor) {
        const at = autocompleteSearchPathRef?.unref();
        if (!at) {
            return;
        }
        Transforms.unsetNodes(editor, INLINE_AUTOCOMPLETE_SEARCH, { at });
    }

    /** Close autocomplete search range if the current selection is outside. */
    function closeIfOutside(editor: Editor) {
        const autocompleteSearchPath = autocompleteSearchPathRef?.current;
        if (
            !autocompleteSearchPath ||
            (editor.selection && Range.includes(Editor.range(editor, autocompleteSearchPath), editor.selection))
        ) {
            return;
        }
        closeAutocompleteSearch(editor);
    }

    /** Watch new autocomplete search, update the path ref, keep only one if there is already one. */
    function watchInlineAutcompleteSearch(editor: Editor, [node, path]: NodeEntry) {
        if (!isAutocompleteSearch(node)) {
            return;
        }
        if (autocompleteSearchPathRef?.current && !isEqual(autocompleteSearchPathRef.current, path)) {
            // Close the current autocomplete search (making sure there is only one autocomplete search)
            closeAutocompleteSearch(editor);
        }
        // Watch path of the autocomplete search
        autocompleteSearchPathRef = Editor.pathRef(editor, path);
    }

    return createPlugin<InlineAutocompleteEditor, WrexEditor<InlineAutocompleteEditor>>({
        createPluginEditor: (editor) => {
            const { normalizeNode, onChange, insertText, insertBreak, insertSoftBreak } = editor;
            return {
                // Slate methods:
                normalizeNode(entry) {
                    watchInlineAutcompleteSearch(editor, entry);

                    normalizeNode(entry);
                },
                onChange() {
                    closeIfOutside(editor);
                    onChange();
                },
                insertBreak() {
                    insertBreak();
                    closeAutocompleteSearch(editor);
                },
                insertSoftBreak() {
                    insertSoftBreak();
                    closeAutocompleteSearch(editor);
                },
                insertText(text) {
                    const option = options.find((o) =>
                        shouldInsertAutocompleteSearch(editor, text, o.triggerCharacter),
                    );
                    if (option) {
                        currentSearchOption = option;
                        insertAutocompleteSearch(editor, text);
                    } else {
                        insertText(text);
                    }
                },

                // Custom methods:
                insertAutocompleteSearch(triggerCharacter) {
                    // Set the correct matching option
                    const option = options.find((o) => o.triggerCharacter === triggerCharacter);
                    if (option) {
                        currentSearchOption = option;
                        insertAutocompleteSearch(editor, option?.triggerCharacter);
                    }
                },
                isAutocompleteAllowed: partial(isAutocompleteAllowed, editor),
                closeAutocompleteSearch: partial(closeAutocompleteSearch, editor),
                insertAutocompletedElement(entity) {
                    const at = autocompleteSearchPathRef?.current;

                    if (!at || !currentSearchOption) {
                        return undefined;
                    }
                    const { elementCreator } = currentSearchOption;

                    // Insert autocompleted element
                    const point = insertAutocompletedElement(editor, entity, elementCreator, at);
                    // Remove autocomplete search
                    Transforms.removeNodes(editor, { at });
                    return point;
                },
                getCurrentSearchOption() {
                    return currentSearchOption;
                },
            };
        },
        marks: [InlineAutocompleteSearch],
        /** Handle autocomplete search context. */
        editableWrapper: InlineAutocompleteHOC,
    });
};
