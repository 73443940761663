import React, { Children, ReactElement } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ImageLightboxProvider } from '@lumapps/lumx-images/components/ImageLightboxProvider';
import { Slides, SlideshowControls, SlideshowItem, Theme } from '@lumapps/lumx/react';
import { useComputeItemsToDisplay } from '@lumapps/responsive';
import { useFocusWithin } from '@lumapps/utils/hooks/useFocusWithin';
import { useSlideshowControlLabels } from '@lumapps/utils/hooks/useSlideshowControlLabels';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { useIsAutoTestEnabled } from '@lumapps/utils/test/useIsAutoTestEnabled';
import { WIDGET_COMMUNITY_LIST_TYPE } from '@lumapps/widget-community-list/constants';

import {
    BlockComponent,
    BlockSlideshow as BlockSlideshowProps,
    ContainerBlockVariant,
    SlideshowBlockVariant,
} from '../../../types';
import { getBlockMinWidth } from '../../../utils/getBlockMinWidth';
import { BLOCK_BREAKPOINT } from '../constants';

import './index.scss';

export const CLASSNAME = 'block-slideshow';

const DEFAULT_VISIBLE_ITEMS = 1;

export const BlockSlideshow: BlockComponent<BlockSlideshowProps> = (props) => {
    const {
        isAutoplayEnabled,
        autoplayDelay,
        children,
        navigation,
        visibleItems = DEFAULT_VISIBLE_ITEMS,
        variant,
        contentStyles,
        theme = Theme.light,
        itemMinWidth,
    } = props;
    // Convert interval in milliseconds.
    const interval = autoplayDelay ? autoplayDelay * 1000 : undefined;
    const slides = Children.toArray(children);
    const wrapperRef = React.useRef(null);
    const isAutoTestQueryParamPresent = useIsAutoTestEnabled();
    const isAutoPlayOn = isAutoplayEnabled && !isAutoTestQueryParamPresent;
    const { block, element } = useClassnames(CLASSNAME);

    // Get childType from Children (assume that all children are of the same type)
    const blockType = (Children.toArray(children)[0] as ReactElement)?.props?.type;
    const widget = (Children.toArray(children)[0] as ReactElement)?.props?.widget;

    // For the community list, ungrouped variant, we need to implement a style exception to remove the padding of each items
    const shouldItemHaveNoPadding =
        widget?.widgetType === WIDGET_COMMUNITY_LIST_TYPE && variant === ContainerBlockVariant.ungrouped;

    // Compute items to disply based on responsive size breakpoints and children block min width
    const blockMinWidth = getBlockMinWidth({ itemMinWidth, blockType, variant, contentStyles });
    const { itemsToDisplay, ref, currentBreakpoint } = useComputeItemsToDisplay(
        blockMinWidth,
        visibleItems,
        BLOCK_BREAKPOINT,
    );

    const {
        activeIndex: currentActiveIndex,
        slideshowId,
        setSlideshow,
        isAutoPlaying,
        slideshowSlidesId,
        toggleAutoPlay,
        toggleForcePause,
        slidesCount,
        onNextClick,
        onPaginationClick,
        onPreviousClick,
        stopAutoPlay,
        startAutoPlay,
        slideshow,
    } = SlideshowControls.useSlideshowControls({
        itemsCount: slides.length,
        autoPlay: isAutoPlayOn,
        interval,
        groupBy: itemsToDisplay,
    });

    const labels = useSlideshowControlLabels({ isAutoPlaying });

    const showNavigation = navigation && slidesCount > 1;

    useFocusWithin({
        element: slideshow,
        onFocusIn: stopAutoPlay,
        onFocusOut: startAutoPlay,
        shouldFocus: isAutoPlayOn,
    });

    return (
        <div
            ref={mergeRefs([wrapperRef, ref])}
            className={block({
                [`theme-${theme}`]: Boolean(theme),
                [`size-${currentBreakpoint}`]: Boolean(currentBreakpoint),
                'with-navigation': showNavigation,
                cover: variant === SlideshowBlockVariant.cover,
                side: variant === SlideshowBlockVariant.side,
                grouped: variant === ContainerBlockVariant.grouped,
                ungrouped: variant === ContainerBlockVariant.ungrouped,
            })}
        >
            <ImageLightboxProvider>
                <Slides
                    activeIndex={currentActiveIndex}
                    theme={theme}
                    id={slideshowId}
                    ref={setSlideshow}
                    isAutoPlaying={isAutoPlaying}
                    autoPlay
                    slidesId={slideshowSlidesId}
                    toggleAutoPlay={toggleAutoPlay}
                    groupBy={itemsToDisplay}
                    slideshow={slideshow}
                    slideGroupLabel={labels.slideGroup}
                >
                    {React.Children.map(slides, (child) => (
                        <SlideshowItem className={element('item-wrapper')}>
                            <div
                                className={element('item', {
                                    'no-padding': shouldItemHaveNoPadding,
                                })}
                                style={variant === ContainerBlockVariant.ungrouped ? contentStyles : {}}
                            >
                                {child}
                            </div>
                        </SlideshowItem>
                    ))}
                </Slides>
            </ImageLightboxProvider>
            {showNavigation && (
                <SlideshowControls
                    isAutoPlaying={isAutoPlaying}
                    className={element('controls')}
                    activeIndex={currentActiveIndex}
                    slidesCount={slidesCount}
                    parentRef={wrapperRef}
                    onNextClick={onNextClick}
                    onPreviousClick={onPreviousClick}
                    onPaginationClick={onPaginationClick}
                    theme={variant === SlideshowBlockVariant.cover ? Theme.dark : theme}
                    nextButtonProps={{ label: labels.next }}
                    previousButtonProps={{ label: labels.previous }}
                    playButtonProps={
                        isAutoPlayOn
                            ? {
                                  label: labels.play,
                                  'aria-controls': slideshowSlidesId,
                                  onClick: toggleForcePause,
                              }
                            : undefined
                    }
                    paginationItemProps={(index) => ({ label: labels.paginationItem(index) })}
                />
            )}
        </div>
    );
};
BlockSlideshow.displayName = 'BlockSlideshow';
BlockSlideshow.defaultProps = {
    isAutoplayEnabled: false,
    navigation: true,
};
