import { cache, CACHE_TYPE } from '@lumapps/cache';
import {
    SEARCH_ACTIONS_HISTORY_CACHE_KEY,
    SEARCH_HISTORY_CACHE_KEY,
    SEARCH_INTERACTIONS_CACHE_KEY,
} from '@lumapps/search/constants';
import { SearchActions, SearchSuggestion } from '@lumapps/search/types';

import { SearchInteraction } from '../types';

/**
 * Get search queries from the cache
 */
export const getSearchSuggestions = (siteId: string, defaultValue: SearchSuggestion[] = []): SearchSuggestion[] => {
    return cache.retrieve(`${SEARCH_HISTORY_CACHE_KEY}-${siteId}`, CACHE_TYPE.STORAGE) || defaultValue;
};

export const storeSearchSuggestions = (history: SearchSuggestion[], siteId: string) => {
    cache.store(`${SEARCH_HISTORY_CACHE_KEY}-${siteId}`, history, CACHE_TYPE.STORAGE);
};

/**
 * Get search interactions from the cache
 */
export const getInteractionsSuggestions = (
    siteId: string,
    defaultValue: SearchInteraction = { interactions: [], ttl: Date.now().toString() },
): SearchInteraction => {
    return cache.retrieve(`${SEARCH_INTERACTIONS_CACHE_KEY}-${siteId}`, CACHE_TYPE.STORAGE) || defaultValue;
};

/**
 * Store interaction in local storage
 * @param history list of all item clicked by the user on the search page
 * @param siteId id of the site
 * @param newTtl new titmestamp to be used
 */
export const storeInteractionsSuggestions = (history: SearchSuggestion[], siteId: string, newTtl?: string) => {
    // if we receieve a new ttl we use it otherwise we keep the one already available in local storage
    if (newTtl) {
        cache.store(
            `${SEARCH_INTERACTIONS_CACHE_KEY}-${siteId}`,
            { interactions: history, ttl: newTtl },
            CACHE_TYPE.STORAGE,
        );
    } else {
        const { ttl } = getInteractionsSuggestions(siteId);
        cache.store(`${SEARCH_INTERACTIONS_CACHE_KEY}-${siteId}`, { interactions: history, ttl }, CACHE_TYPE.STORAGE);
    }
};

/**
 * Get search action history from the cache
 */
export const getActionsHistory = (siteId: string, defaultValue: SearchActions[] = []): SearchActions[] => {
    return cache.retrieve(`${SEARCH_ACTIONS_HISTORY_CACHE_KEY}-${siteId}`, CACHE_TYPE.STORAGE) || defaultValue;
};

export const storeActionsHistory = (history: SearchActions[], siteId: string) => {
    cache.store(`${SEARCH_ACTIONS_HISTORY_CACHE_KEY}-${siteId}`, history, CACHE_TYPE.STORAGE);
};
