import flow from 'lodash/fp/flow';
import replace from 'lodash/fp/replace';
import trimCharsEnd from 'lodash/fp/trimCharsEnd';
import unescape from 'lodash/fp/unescape';

/**
 * Encode the shared URL to be used with the MS Graph APIs
 * @see https://learn.microsoft.com/en-us/graph/api/shares-get?view=graph-rest-1.0&tabs=http#encoding-sharing-urls
 * */
export const encodeSharedURL = (url: string) =>
    flow(
        unescape,
        window.btoa,
        trimCharsEnd('='),
        replace('/', '_'),
        replace('+', '-'),
        (str: string) => `u!${str}`,
    )(url);
