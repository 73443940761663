import React from 'react';

import { margin } from '@lumapps/classnames';
import { FlexBox, IconProps, Text, ThumbnailProps, Icon, Thumbnail, Size } from '@lumapps/lumx/react';

export interface ActionableCardSubtitleProps {
    /** subtitle to display */
    subtitle: string;
    /** image to be displayed to the left hand side of the subtitle. */
    img?: ThumbnailProps;
    /** icon to be displayed to the left hand side of the subtitle. */
    icon?: IconProps;
}

/**
 * Subtitle to be used together with the ActionableCard component
 * @param ActionableCardSubtitleProps
 * @returns ActionableCardSubtitle
 */
export const ActionableCardSubtitle: React.FC<ActionableCardSubtitleProps> = ({ subtitle, icon, img }) => {
    return (
        <FlexBox orientation="horizontal">
            {icon ? <Icon size={Size.xs} {...icon} /> : null}

            {img ? <Thumbnail size={Size.xs} {...img} /> : null}

            <Text className={margin('left', 'regular')} as="span" typography="body1" colorVariant="L2" color="dark">
                {subtitle}
            </Text>
        </FlexBox>
    );
};
