import set from 'lodash/set';

import { Image } from '@lumapps/lumx-images/types';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { Socket, ServerToClientEvents, ClientToServerEvents } from '@lumapps/websockets';

import { MessageHandles } from '../constants';
import {
    AssistantApplication,
    HistoryMessage,
    MessageReceivedFromBot,
    MessageToSendToBot,
    UserAttachment,
} from '../types';

export interface ConversationState {
    messages: any[];
    hasMoreMessages: boolean;
    messageHistoryCursor?: string;
    appID?: string;
    siteId?: string;
    chatbotID?: string;
    respondentID?: string;
    accessToken?: string;
    application?: AssistantApplication;
    initialMessages?: MessageReceivedFromBot[];
    socket?: any;
    isTyping?: boolean;
    status?: 'live' | 'error' | 'empty';
    userAttachments: UserAttachment[];
    /** Id to send back with the Ask AI queries for conversation tracking purpose */
    conversationId?: string;
}

export enum ConversationStatusTypes {
    live = 'live',
    empty = 'empty',
    error = 'error',
}

const initialState: ConversationState = {
    messages: [],
    hasMoreMessages: false,
    messageHistoryCursor: undefined,
    appID: undefined,
    chatbotID: undefined,
    respondentID: undefined,
    accessToken: undefined,
    application: undefined,
    initialMessages: undefined,
    socket: undefined,
    isTyping: false,
    userAttachments: [],
};

const domain = 'digital-assistant';

const { actions, reducer } = createSlice({
    domain,
    initialState,
    reducers: {
        addMessage: (state: ConversationState, action: PayloadAction<{ message: any; index?: number }>) => {
            const newMessages = state.messages.slice();
            newMessages.splice(action.payload.index || state.messages.length, 0, action.payload.message);
            set(state, 'messages', newMessages);
        },
        addMessagesToHistory: (state: ConversationState, action: PayloadAction<HistoryMessage[]>) => {
            const newMessages = state.messages.slice();
            action.payload
                .filter((message) => message.text !== MessageHandles.Reboot)
                .forEach((m: MessageReceivedFromBot | MessageToSendToBot) => newMessages.splice(0, 0, m));
            set(state, 'messages', newMessages);
        },
        removeMessage: (
            state: ConversationState,
            action: PayloadAction<MessageToSendToBot | MessageReceivedFromBot>,
        ) => {
            const newMessages = state.messages.slice();
            const index = newMessages.findIndex((m) => m.createdAt === action.payload.createdAt);
            if (index >= 0) {
                newMessages.splice(
                    newMessages.findIndex((m) => m.createdAt === action.payload.createdAt),
                    1,
                );
            }
            set(state, 'messages', newMessages);
        },
        setAppID: (state: ConversationState, action: PayloadAction<string>) => {
            set(state, 'appID', action.payload);
        },
        setSocket: (
            state: ConversationState,
            action: PayloadAction<Socket<ServerToClientEvents, ClientToServerEvents>>,
        ) => {
            set(state, 'socket', action.payload);
        },
        setApplication: (state: ConversationState, action: PayloadAction<AssistantApplication>) => {
            set(state, 'application', action.payload);
        },
        resetConversation: (state: ConversationState) => {
            set(state, 'messages', []);
            set(state, 'conversationId', undefined);
        },
        setTyping: (state: ConversationState, action: PayloadAction<boolean>) => {
            set(state, 'isTyping', action.payload);
        },
        setStatus: (state: ConversationState, action: PayloadAction<'live' | 'error' | 'empty'>) => {
            set(state, 'status', action.payload);
        },
        addUserAttachment: (state: ConversationState, action: PayloadAction<Image>) => {
            set(state, 'userAttachments', [...state.userAttachments, action.payload]);
        },
        removeUserAttachment: (state: ConversationState, action: PayloadAction<UserAttachment>) => {
            set(
                state,
                'userAttachments',
                state.userAttachments.filter((a) => a.id !== action.payload.id),
            );
        },
        replaceAttachmentWithUploadedFile: (
            state: ConversationState,
            action: PayloadAction<{ file: UserAttachment }>,
        ) => {
            const newAttachments = state.userAttachments;
            const index = newAttachments.findIndex((a) => a.blobUrl === action.payload.file.blobUrl && !a.mediaId);
            /** remove the attachment with the same blobUrl but no mediaId (temporary attachment) */
            if (index >= 0) {
                newAttachments.splice(index, 1, action.payload.file);
            }
            set(state, 'userAttachments', newAttachments);
        },
        resetUserAttachments: (state: ConversationState) => {
            set(state, 'userAttachments', initialState.userAttachments);
        },
        setHasMoreMessages: (state: ConversationState, action: PayloadAction<boolean>) => {
            set(state, 'hasMoreMessages', action.payload);
        },
        setChatbotID: (state: ConversationState, action: PayloadAction<string>) => {
            set(state, 'chatbotID', action.payload);
        },
        setRespondentID: (state: ConversationState, action: PayloadAction<string>) => {
            set(state, 'respondentID', action.payload);
        },
        setHistoryCursor: (state: ConversationState, action: PayloadAction<string>) => {
            set(state, 'messageHistoryCursor', action.payload);
        },
        setConversationId: (state: ConversationState, action: PayloadAction<string>) => {
            set(state, 'conversationId', action.payload);
        },
    },
});

export { actions, initialState, reducer };
