import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { requestOnIdleCallback } from '@lumapps/utils/function/requestIdleCallback';

import store from '../api/store';
import { Events } from '../types';
import { getSession } from './selectors';

export const notifyCustomizationSubscribers = (pageId: string) => (dispatch: Dispatch, getState: GetBaseState) => {
    const state = getState();
    const session = getSession(state);

    requestOnIdleCallback(() => {
        store.notifySubscribers(
            {
                currentPage: pageId,
                ...session,
            },
            Events.NAVIGATION,
        );
    });
};
