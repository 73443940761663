import React from 'react';

import { createPortal } from 'react-dom';

/**
 * A portal lets you render some children into a different part of the DOM.
 * It is useful for example in case of a draggable item in a dialog;
 * The dragged item should be positionned absolutly, but relatively to the windows,
 * not to the dialog
 *
 * Usage:
 *      const renderDraggable = useDraggableInPortal();
 *      return (
 *          <>
 *              <p>Rendered in the DOM classically</p>
 *              {renderDraggable(() => {
 *                  <p>Rendered in it's own part of the DOM</p>
 *              })}
 *          <>
 *      );
 *
 *      const renderDraggable = useDraggableInPortal();
 *      return (
 *          <>
 *              <Draggable>
 *                  {renderDraggable((provided, snapshot) => {
 *                      <p {...provided.draggableProps}>Rendered in it's own part of the DOM</p>
 *                  })}
 *              </Draggable>
 *          <>
 *      );
 */
export const useRenderInPortal = () => {
    const self = React.useRef<{
        elt?: HTMLDivElement;
    }>({}).current;

    React.useEffect(() => {
        /**
         * Create a div at the root of the body that takes the whole window
         */
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.pointerEvents = 'none';
        div.style.top = '0';
        div.style.left = '0';
        div.style.width = '100%';
        div.style.height = '100%';
        self.elt = div;
        document.body.appendChild(div);
        return () => {
            document.body.removeChild(div);
        };
    }, [self]);

    /**
     * Pass the given arguments to the render function
     * (see above example)
     */
    return (render: (...args: any[]) => JSX.Element) =>
        (...args: any[]) => {
            const element = render(...args);
            if (self.elt) {
                return createPortal(element, self.elt);
            }
            return element;
        };
};
