import produce from 'immer';

import { INIT_STATE, RESET_STATE, SET_CHARTIMAGEURL, SET_ISLOADING, SET_WIDGETWIDTH } from './chart_action';

const initialState = [];

const initialWidgetState = {
    isLoading: false,
    chartImageUrl: '',
    widgetWidth: null,
};

const NOT_FOUND = -1;

/**
 * The reducer for the chart actions.
 *
 * @param  {Object} state  The current store state.
 * @param  {string} action The current dispatched action.
 * @return {Object} The new state of the redux store.
 */
const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const widgetIndex = draft.findIndex((widget) => {
            if (action.payload && action.payload.uuid === undefined) {
                return NOT_FOUND;
            }

            return action.payload ? widget.uuid === action.payload.uuid : NOT_FOUND;
        });

        switch (action.type) {
            case INIT_STATE: {
                if (widgetIndex === NOT_FOUND) {
                    draft.push({
                        uuid: action.payload.uuid,
                        ...initialWidgetState,
                    });
                }

                break;
            }

            case RESET_STATE: {
                if (widgetIndex === NOT_FOUND) {
                    draft.push({
                        uuid: action.payload.uuid,
                        ...initialWidgetState,
                    });
                } else {
                    Object.assign(draft[widgetIndex], { chartImageUrl: action.payload.data });
                }

                break;
            }

            case SET_ISLOADING: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { isLoading: action.payload.data });
                }

                break;
            }

            case SET_CHARTIMAGEURL: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { chartImageUrl: action.payload.data });
                }

                break;
            }

            case SET_WIDGETWIDTH: {
                if (widgetIndex !== NOT_FOUND) {
                    Object.assign(draft[widgetIndex], { widgetWidth: action.payload.data });
                }

                break;
            }

            default:
                return state;
        }

        return draft;
    });

export { reducer };
