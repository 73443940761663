import React from 'react';

import { BackendRecomputedWidget } from '@lumapps/widget-base/components/BackendRecomputedWidget';
import { useEditableWidget } from '@lumapps/widget-base/hooks/useEditableWidget';
import { EditableWidgetProps } from '@lumapps/widget-base/types/designer';

import type { LegacyWidgetIntroProps } from '../../type';
import { EditableWidgetIntro, EditableWidgetIntroProps } from '../EditableWidgetIntro';

export interface WidgetIntroWrapperProps extends EditableWidgetProps<LegacyWidgetIntroProps> {}

export interface EditableWidgetIntroProperties {
    content?: string;
}

export const WidgetIntroWrapper: React.FC<WidgetIntroWrapperProps> = ({
    legacyWidget: initLegacyWidget,
    switchToReadMode,
    saveTextInWidgetProperties,
    isEditingContent,
    isWidgetEmpty,
    ...props
}) => {
    const getTradObject = React.useCallback((properties?: LegacyWidgetIntroProps) => properties?.content, []);

    const { legacyWidget, content, editableWidgetProps } = useEditableWidget<LegacyWidgetIntroProps>(
        initLegacyWidget,
        switchToReadMode,
        saveTextInWidgetProperties,
        getTradObject,
    );

    return (
        <BackendRecomputedWidget<LegacyWidgetIntroProps, EditableWidgetIntroProperties, EditableWidgetIntroProps>
            legacyWidget={legacyWidget}
            editableWidgetRenderer={EditableWidgetIntro}
            editableWidgetProps={editableWidgetProps}
            isWidgetEmpty={isWidgetEmpty}
            blockProperties={{ text: content }}
            editingContent={isEditingContent}
            canUseLangFallback={false}
            {...props}
        />
    );
};
