import { getAttributes } from '@lumapps/data-attributes';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

// ///////////////////////////

// eslint-disable-next-line require-jsdoc
function ContentBlockController(
    $scope,
    Config,
    Content,
    Community,
    CustomContentType,
    Document,
    Feed,
    Instance,
    Metadata,
    Notification,
    Translation,
    User,
    Utils,
) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The default width of the thumbnail.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    // eslint-disable-next-line no-underscore-dangle
    const _DEFAULT_THUMBNAIL_WIDTH = 512;
    /**
     * The default width of the thumbnail.
     *
     * @type {number}
     * @constant
     * @readonly
     */
    // eslint-disable-next-line no-underscore-dangle
    const _HIGHT_RESOLUTION_THUMBNAIL_WIDTH = 1920;

    /**
     * The lang to used by the content block.
     *
     * @type {Sring}
     */
    // eslint-disable-next-line no-underscore-dangle
    let _currentLang;

    /**
     * Contains the classes of the metadata.
     *
     * @type {Array}
     */
    // eslint-disable-next-line no-underscore-dangle
    let _metadataClasses = [];

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * The full list of CSS classes of the content block.
     *
     * @type {Array}
     */
    vm.classes = [];

    /**
     * The list of fields to display in the content block.
     * Each field is represented by an object.
     *
     * @type {Array}
     */
    vm.fieldsToDisplay = [];

    /**
     * The formatted content, displayed when the full excerpt is enabled.
     *
     * @type {string}
     */
    vm.htmlFormatted = '';

    /**
     * The url of the thumbnail
     *
     * @type {string | undefined}
     */
    vm.thumbnailURL = undefined;


    /**
     * If the content is of type 'community'
     *
     * @type {boolean}
     */
    vm.isCommunity = false;

    // ///////////////////////////

    /**
     * Services and utilities.
     */
    vm.Content = Content;
    vm.Community = Community;
    vm.CustomContentType = CustomContentType;
    vm.Document = Document;
    vm.Instance = Instance;
    vm.Metadata = Metadata;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    /**
     * Compute the background image of the content block.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _computeImageURL() {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(vm.content)) {
            return;
        }

        const thumbnailSize =
            vm.forceThumbnailFullSize || vm.viewMode === 'cover'
                ? _HIGHT_RESOLUTION_THUMBNAIL_WIDTH
                : _DEFAULT_THUMBNAIL_WIDTH;

        vm.thumbnailURL = Document.getCroppedThumbnailFromRootContent(vm.content, true, thumbnailSize, _currentLang);
    }

    /**
     * Check if a content is unread.
     *
     * @return {boolean} If the content is unread or not.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _isContentUnread() {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(get(vm.content, 'id'))) {
            return false;
        }

        return Notification.hasUnreadNotifications(vm.content.id);
    }

    /**
     * Check if the content is restricted.
     * A content is restricted if its visibility is not 'ALL'.
     *
     * @return {boolean} If the content is restricted or not.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _isRestricted() {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(get(vm.content, 'feedKeys'))) {
            return false;
        }

        // eslint-disable-next-line no-undef
        return angular.isUndefinedOrEmpty(Feed.ALL) || !includes(vm.content.feedKeys, Feed.ALL.id);
    }

    /**
     * Initialize the fields to display in the content block.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _initFields() {
        // eslint-disable-next-line no-undef
        const newFields = angular.isUndefined(vm.fields)
            ? Instance.getProperty(Config.INSTANCE_PROPERTIES.CONTENT_FIELDS)
            : vm.fields;

        let hasMetadataEnabled = false;
        vm.fieldsToDisplay = [];
        // eslint-disable-next-line no-undef
        angular.forEach(newFields, (field) => {
            // eslint-disable-next-line no-undef
            if (angular.isUndefinedOrEmpty(field)) {
                return;
            }

            let newField = field;
            // eslint-disable-next-line no-undef
            if (!angular.isObject(field)) {
                newField = {
                    enable: true,
                    name: field,
                };
            }

            if (newField.enable) {
                vm.fieldsToDisplay.push(newField);
            }

            if (newField.name === 'metadata' && newField.enable) {
                hasMetadataEnabled = true;
            }
        });

        /*
         * Render metadataClasses only if metadata are enabled, but in search result we can't manage fields.
         * 'vm.fields' is an array of string out of widget content-list and a collection in widget content-list.
         */
        _metadataClasses = hasMetadataEnabled
            ? Metadata.getMetadataClasses(vm.content.metadata, 'content-block--metadata-')
            : [];
    }

    /**
     * Format the full excerpt.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _formatFullExcerpt() {
        vm.htmlFormatted = Utils.getReadableHtmlContent(Translation.translate(Content.getExcerpt(vm.content, true)));
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Compute the content block CSS classes.
     *
     * @return {Array} The content block CSS classes.
     */
    function getClasses() {
        Utils.empty(vm.classes);

        // eslint-disable-next-line no-undef
        if (!angular.isArray(vm.classes)) {
            vm.classes = [];
        }

        if (!vm.resetStyle) {
            vm.classes.push('content-block--default-style');
        }

        const theme = vm.theme || 'light';
        vm.classes.push(`content-block--theme-${theme}`);

        const viewMode = vm.viewMode || 'list';
        vm.classes.push(`content-block--view-mode-${viewMode}`);

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(vm.thumbnailPosition)) {
            vm.classes.push(`content-block--${vm.thumbnailPosition}-thumbnail`);
        }

        const blockSize = vm.blockSize || 'l';
        vm.classes.push(`content-block--size-${blockSize}`);

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(vm.hAlign)) {
            vm.classes.push(`content-block--h-align-${vm.hAlign}`);
        }

        if (_isContentUnread()) {
            vm.classes.push('content-block--unread');
        }

        if (_isRestricted()) {
            vm.classes.push('content-block--is-restricted');
        }

        if (Translation.hasTranslations(vm.content.link, true)) {
            vm.classes.push('content-has-external-link');
        }

        const customClasses = get(vm.content, 'properties.class');
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(customClasses)) {
            Array.prototype.push.apply(vm.classes, Utils.getCustomClass(customClasses, ''));
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(_metadataClasses)) {
            Array.prototype.push.apply(vm.classes, _metadataClasses);
        }

        return vm.classes;
    }

    /**
     * Add metadata class according to metadata functionnal inner id.
     *
     * @param  {string} metadataKey The key of the metadata we want to get.
     * @return {string} The metadata functional inner identifier.
     */
    function getMetadataFunctionalInnerId(metadataKey) {
        const metadata = Metadata.getMetadataFromKey(metadataKey, false, true);

        const functionalInnerId = get(metadata, 'functionalInnerId');
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(functionalInnerId)) {
            return `content-block-metadata__metadata--${functionalInnerId}`;
        }

        return '';
    }

    /**
     * Check if a metadata is displayable.
     * A metadata is displayable if it's not empty.
     *
     * @param  {Object}  meta The metadata to check.
     * @return {boolean} If the metadata is displayable or not.
     */
    function isMetadataEmpty(meta) {
        // eslint-disable-next-line no-undef
        return angular.isDefinedAndFilled(meta);
    }

    /**
     * Get data-id for pendo
     */
    function getDataId(options) {
        const id = vm.content?.uid || vm.content?.id;
        return !id ? '' : getAttributes(`content-${id}`)(options)['data-id'];
    }

    /**
     * Get scope for pendo
     */
    function getScope() {
        const id = vm.content?.uid || vm.content?.id;
        return !id ? '' : `content-${id}`;
    }

    /**
     * Show links when the data is ready
     * If we are in a current instance
     * Or if the loading of siblings instance is terminated
     */
    function shouldShowLink() {
        return !vm.Instance.is.loading.siblings || vm.content.instance === vm.Instance.getCurrentInstanceId()
    }

    /**
     * Get all props for the Thumbnail component
     * */
    function getThumbnailProps() {
        return {
            alt: vm.Translation.translate(vm.content.title),
            image: vm.thumbnailURL,
            aspectRatio: 'free',
            fillHeight: true,
            focusPoint: vm.Document.getFocusPointFromMedia(vm.content.mediaThumbnail, true),
        }
    }

    // ///////////////////////////

    vm.getClasses = getClasses;
    vm.getMetadataFunctionalInnerId = getMetadataFunctionalInnerId;
    vm.isMetadataEmpty = isMetadataEmpty;
    vm.getDataId = getDataId;
    vm.getScope = getScope;
    vm.shouldShowLink = shouldShowLink;
    vm.getThumbnailProps = getThumbnailProps;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    /**
     * When metadata has been refactored, recompute the metadata classes.
     */
    $scope.$on('metadata-refactored', () => {
        _initFields();
    });

    // eslint-disable-next-line no-undef
    if (angular.isDefinedAndFilled(vm.parentId)) {
        /**
         * Listen to an update of the size.
         *
         * @param {Event}  evt        The original event triggering this method.
         * @param {string} widgetUuid The identifier of the widget that triggered the theme update.
         * @param {string} size       The new widget size.
         */
        $scope.$on('widget-content-list-size-update', (evt, widgetUuid, size) => {
            // eslint-disable-next-line no-undef
            if (vm.parentId !== widgetUuid || angular.isUndefinedOrEmpty(size)) {
                return;
            }

            vm.blockSize = size;
        });

        /**
         * Listen to an update of the theme.
         *
         * @param {Event}  evt        The original event triggering this method.
         * @param {string} widgetUuid The identifier of the widget that triggered the theme update.
         * @param {string} theme      The new theme to use.
         */
        $scope.$on('widget-content-list-theme-update', (evt, widgetUuid, theme) => {
            // eslint-disable-next-line no-undef
            if (vm.parentId !== widgetUuid || angular.isUndefinedOrEmpty(theme)) {
                return;
            }

            vm.theme = theme;
        });
    } else {
        /**
         * When the style of the content list widget changes, re-compute the classes.
         */
        $scope.$on('cropper-preview-size-change', () => _computeImageURL());
    }

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const { content } = vm;

        // eslint-disable-next-line no-undef
        vm.fullExcerpt = angular.isUndefined(vm.fullExcerpt) ? false : vm.fullExcerpt;
        vm.excerpt = vm.excerpt || Instance.getProperty(Config.INSTANCE_PROPERTIES.CONTENT_LIST_EXCERPT);

        // Define the lang to used.
        // eslint-disable-next-line no-const-assign
        _currentLang = Utils.isDesignerMode() ? Translation.inputLanguage : Translation.getLang('current');

        _computeImageURL();
        _initFields();

        if (vm.displayInstance) {
            Instance.getInstanceById(content.instance, (response) => {
                vm.instanceDetails = response;
            });
        }

        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(vm.parentId)) {
            vm.parentId = `content-block-${generateUUID()}`;
        }

        if (vm.fullExcerpt) {
            _formatFullExcerpt();
        }
        vm.isCommunity = Community.isCommunity(content);
    }

    init();
}

// ///////////////////////////
/**
 * The content-block directive.
 * The content-block is used by the widget-content-list, the search, the media-picker, the social profile
 * and by the old widget breaking-news.
 *
 * @param  {string}  [blockSize='l']          The size of the block. The size can be "l", "m" or "s".
 * @param  {Object}  content                  The content displayed by the content block.
 * @param  {boolean} [displayAnalyticsViews]  True if the content block has to display the analytics view count.
 * @param  {boolean} [displayInstance]        True if the content block has to display the instance of the content.
 * @param  {string}  [excerpt]                How many characters that the excerpt has.
 * @param  {Array}   [fields]                 The fields to display in the right order.
 * @param  {boolean} [forceThumbnailFullSize] True if we display the thumbnail with a width of 1920px.
 * @param  {boolean} [fullExcerpt]            True if we display a formatted and fully excerpt.
 * @param  {string}  [hAlign]                 The horizontal position of the content block.
 * @param  {boolean} [hideThumbnail]          True if we hide the thumbnail.
 * @param  {boolean} [metadataParent]         True if we display the name of the parent of the metadata.
 * @param  {string}  [parentId]               The id of the widget which called the content-block.
 * @param  {string}  [resetStyle]             True if we don't want to use the content block default style.
 * @param  {string}  [theme='light']          The name of the theme: "dark" or "light".
 * @param  {string}  [thumbnailPosition]      The position of the thumbnail.
 *                                            The position can be "inline" or "background".
 * @param  {string}  [viewMode='list']        The view mode of the content block.
 *                                           The view mode can be "list", "grid", "highlight" or "cover".
 * @return {Block}   The content block.
 */

function ContentBlockDirective() {
    return {
        bindToController: true,
        controller: ContentBlockController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            blockSize: '@?',
            content: '<',
            contentClick: '&',
            displayAnalyticsViews: '<?',
            displayInstance: '<?',
            excerpt: '@?',
            fields: '<?',
            forceThumbnailFullSize: '<?',
            fullExcerpt: '<?',
            hAlign: '@?',
            hideThumbnail: '<?',
            metadataParent: '<?',
            parentId: '@?',
            resetStyle: '<?',
            theme: '@?',
            thumbnailPosition: '@?',
            viewMode: '@?',
        },
        templateUrl: '/client/front-office/modules/content/common/views/content-block.html',
    };
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular.module('Directives').directive('lsContentBlock', ContentBlockDirective);

// ///////////////////////////

export { ContentBlockController, ContentBlockDirective };
