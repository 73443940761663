import { CSSProperties } from 'react';

import hast from 'hast';
import convert from 'unist-util-is/convert';

import { parseInlineCSS } from '@lumapps/utils/css/parseInlineCSS';
import { Predicate } from '@lumapps/utils/types/Predicate';

import { HTML_TXT_TAG_WHITELIST } from './constants';

export const isText = (node?: hast.Node): node is hast.Text => node?.type === 'text';
export const isRoot = (node?: hast.Node): node is hast.Root => node?.type === 'root';
export const isElement = (node?: hast.Node): node is hast.Element => node?.type === 'element';
export const isParent = (node?: hast.Node): node is hast.Parent => !!node?.children;

export type NodeMatcher = (node: hast.Node, index?: number, parent?: hast.Parent) => boolean;

export const matchNode = convert;

/**
 * Match a HAST element on it's attribute value.
 *
 * @param attributeName    Name of the attribute to match on.
 * @param attributeValueRX RegExp used to validate the attribute value.
 * @param isMatchValid     Predicate function that validate the RegExp match.
 * @return Predicate function on HAST node.
 */
export const matchAttribute =
    (attributeName: string, attributeValueRX: RegExp, isMatchValid?: Predicate<RegExpMatchArray>) =>
    (node: hast.Node): boolean => {
        const attributeValue = isElement(node) && node.properties?.[attributeName];
        if (!attributeValue) {
            return false;
        }
        const match = String(attributeValue).match(attributeValueRX);
        if (!isMatchValid || !match) {
            return !!match;
        }
        return isMatchValid(match);
    };

/**
 * Match a HAST element on it's style attribute value.
 *
 * @param stylePropertyName    Name of the style property to match on.
 * @param isMatchValid     Predicate function that validate the property match.
 * @return Predicate function on HAST node.
 */
export const matchStyle =
    (stylePropertyName: keyof CSSProperties, isMatchValid?: Predicate<string>) =>
    (node: hast.Node): boolean => {
        const styleProperty = isElement(node) && (node.properties?.style as string);

        if (!styleProperty) {
            return false;
        }

        const styleObject = parseInlineCSS(styleProperty);
        const styleValue = styleObject?.[stylePropertyName] as string;

        if (!styleValue) {
            return false;
        }

        if (!isMatchValid) {
            return !!styleValue;
        }

        return isMatchValid(styleValue);
    };

/**
 * Check HAST node is a valid parent for a text node.
 */
export function isValidHTMLTextParent(node?: hast.Node): boolean {
    return isElement(node) && HTML_TXT_TAG_WHITELIST.includes(node.tagName);
}

/** Extract raw text from node. */
export const getRawText = (node?: hast.Node): string => {
    if (isText(node)) {
        return node.value;
    }
    if (isParent(node)) {
        return node.children.map(getRawText).join('');
    }
    return '';
};
