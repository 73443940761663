(function IIFE() {
    'use strict';

    /////////////////////////////

    function LsNavigationPickerController($scope, LxNotificationService, MainNav, NavigationPicker, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The menu items list key.
         *
         * @type {string}
         * @readonly
         */
        vm.LIST_KEY = 'navigation-picker-menu-items';

        /**
         * Indicates if the menu items are loading.
         *
         * @type {boolean}
         */
        vm.isLoading = false;

        /**
         * Indicates if the navigation picker is opened.
         *
         * @type {boolean}
         */
        vm.isOpen = false;

        /**
         * Contains the list of menu items (in the 'children' property).
         *
         * @type {Object}
         */
        vm.menuItems = {
            children: [],
        };

        /**
         * Contains the list of picked menu items.
         *
         * @type {Array}
         */
        vm.pickedItems = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.MainNav = MainNav;
        vm.Translation = Translation;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Add pending model to current edit items.
         */
        function _processPendingModel() {
            if (!angular.isArray(_.get(vm.ngModel, 'items'))) {
                return;
            }

            // Iter over lang items till it find it.
            for (var idx = 0, len = vm.ngModel.items.length; idx < len; ++idx) {
                var menuItem = vm.ngModel.items[idx];

                if (!angular.isArray(menuItem.items) || menuItem.lang !== Translation.inputLanguage) {
                    continue;
                }

                // Looking for new or modified element.
                angular.forEach(menuItem.items, function forEachMenuItemItems(item) {
                    if (!item.$modified && !item.$isNew) {
                        return;
                    }

                    var parent = (angular.isDefinedAndFilled(item.parentId)) ?
                        MainNav.findNavItemById(item.parentId, vm.menuItems.children) : vm.menuItems;

                    var index;

                    if (item.$isNew) {
                        index = item.sortOrder;
                    } else {
                        // Find elem in parent children and update it then update position of parent any way.
                        index = _.findIndex(parent.children, {
                            uuid: item.uuid,
                        });
                    }

                    if (angular.isArray(_.get(parent, 'children'))) {
                        parent.children.splice(index, (item.$isNew) ? 0 : 1, item);

                        MainNav.updateSortOrder(parent.children);
                        MainNav.addModifiedElement(item, Translation.inputLanguage);
                    }
                });

                break;
            }
        }

        /**
         * Get the menu items.
         */
        function _getMenuItems() {
            vm.isLoading = true;

            MainNav.get({
                action: 'MENU_EDIT',
                lang: Translation.inputLanguage,
            }, function onMainNavGetSuccess(response) {
                vm.isLoading = false;

                if (angular.isArray(response.items)) {
                    // Disable edition and drag.
                    angular.forEach(response.items, function forEachMenuItems(menuItem) {
                        menuItem.editable = false;
                    });

                    vm.menuItems.children = response.items;
                }

                // Process the model, add items or delete them.
                _processPendingModel();
            }, function onMainNavGetErro() {
                vm.isLoading = false;
            }, vm.LIST_KEY);
        }

        /**
         * Initialize the menu item list.
         */
        function _initList() {
            vm.isOpen = true;

            // Add editable content for nested directive.
            vm.pickedItems = vm.ngModel || [];

            // Get menu items in the current input language.
            _getMenuItems();
        }

        /**
         * Reset configuration.
         */
        function _resetList() {
            MainNav.clearEditItem();
            vm.menuItems.children.length = 0;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Cancel the edition of the current element.
         */
        function cancelEdit() {
            MainNav.clearEditItem();
        }

        /**
         * Close the navigation picker.
         */
        function closePicker() {
            NavigationPicker.close(vm.pickerId);
        }

        /**
         * When a navigation item cannot be dropped.
         */
        function dropErrorCallback() {
            LxNotificationService.warning(Translation.translate('ADMIN.MAIN_NAV.ERROR_NOT_UNIQUE'));
        }

        /**
         * Save the modifications.
         */
        function save() {
            // Set model with the payload.
            vm.ngModel = angular.fastCopy(vm.pickedItems);

            // Reset main nav.
            MainNav.clearEditItem();
            MainNav.resetElements();

            vm.closePicker();
        }

        /////////////////////////////

        vm.cancelEdit = cancelEdit;
        vm.closePicker = closePicker;
        vm.dropErrorCallback = dropErrorCallback;
        vm.save = save;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the input language changes, reload the menu items.
         */
        $scope.$on('inputLanguage', function onInputLanguageChange() {
            _getMenuItems();
        });

        /**
         * When the navigation picker closes, reset the lists and filters.
         *
         * @param {Event}  evt       The navigation picker close event.
         * @param {string} managerId The id of the navigation picker that closes.
         */
        $scope.$on('navigation-picker__close-end', function onNavigationPickerCloseEnd(evt, pickerId) {
            if (vm.pickerId === pickerId) {
                vm.isOpen = false;

                vm.init();
            }
        });

        /**
         * When the navigation picker opens, switch to the right mode and initialize the lists.
         *
         * @param {Event}  evt       The navigation picker open event.
         * @param {string} managerId The id of the navigation picker that opens.
         */
        $scope.$on('navigation-picker__open-start', function onNavigationPickerOpenStart(evt, pickerId) {
            if (vm.pickerId === pickerId) {
                vm.isOpen = true;

                _initList();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        vm.init = function init() {
            _resetList();
        };

        vm.init();
    }

    /////////////////////////////

    function LsNavigationPickerDirective() {
        'ngInject';

        function link(scope, el) {
            el.appendTo('body');

            scope.$on('$destroy', function onDestroy() {
                el.remove();
            });
        }

        return {
            bindToController: true,
            controller: LsNavigationPickerController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                ngModel: '=ngModel',
                pickerId: '@lsPickerId',
            },
            templateUrl: '/client/common/modules/navigation-picker/views/navigation-picker.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsNavigationPicker', LsNavigationPickerDirective);
})();
