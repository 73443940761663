(function IIFE() {
    'use strict';

    /////////////////////////////

    function LightboxService($rootScope, Utils) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Indicates if the lightbox is currently open.
         *
         * @type {boolean}
         */
        service.isOpen = false;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Close the lightbox.
         */
        // eslint-disable-next-line no-shadow
        function close() {
            if (!service.isOpen) {
                return;
            }

            service.isOpen = false;

            Utils.waitForAndExecute('.lightbox', function waitForLightboxElement() {
                $rootScope.$broadcast('ls-lightbox__close');
            });
        }

        /**
         * Open the lightbox with a given list of images and at a given position.
         *
         * @param {Array|string} mediaList The list of images to display in the lightbox.
         * @param {number}       [index=0] The position of the image in the list we want to open right away.
         */
        // eslint-disable-next-line no-shadow
        function open(mediaList, index) {
            if (angular.isUndefinedOrEmpty(mediaList)) {
                return;
            }

            service.isOpen = true;

            Utils.waitForAndExecute('.lightbox', function waitForLightboxElement() {
                $rootScope.$broadcast('ls-lightbox__open', mediaList, index);
            });
        }

        /////////////////////////////

        service.close = close;
        service.open = open;
    }

    /////////////////////////////

    angular.module('Services').service('Lightbox', LightboxService);
})();
