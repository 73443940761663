import React from 'react';

import { SearchSuggestionType } from '@lumapps/search/types';

import { SearchBoxDefaultOption } from './SearchBoxDefaultOption';
import { SearchBoxInteractionOption } from './SearchBoxInteractionOption';
import { SearchBoxSuggestionOption } from './SearchBoxSuggestionOption';
import { SearchHistoryOption } from './SearchHistoryOption';
import { SearchBoxOptionProps, SearchBoxOptionTypeProps } from './types';

const ComponentsBySearchOptionType: { [key in SearchSuggestionType]?: React.FC<SearchBoxOptionTypeProps> } = {
    [SearchSuggestionType.HISTORY]: SearchHistoryOption,
    [SearchSuggestionType.SUGGESTION]: SearchBoxSuggestionOption,
    [SearchSuggestionType.INTERACTION]: SearchBoxInteractionOption,
    [SearchSuggestionType.RECOMMENDATION]: SearchBoxInteractionOption,
};

/**
 * Base component to manage search box options.
 * Will render the correct component depending on the suggestion type.
 */
export const SearchBoxOption = ({ onSelect, ...props }: SearchBoxOptionProps) => {
    const { suggestion } = props;
    const Component = ComponentsBySearchOptionType[suggestion.type] || SearchBoxDefaultOption;

    const handleSelect = () => onSelect(suggestion);

    return <Component {...props} onSelect={handleSelect} />;
};
