/* istanbul ignore file */
import { createContext } from 'react';

export interface RouterProps {
    currentView: string;
    params: any;
    isBackOffice: boolean;
}

const Context = createContext<RouterProps>({
    currentView: '',
    params: {},
    isBackOffice: false,
});

export { Context };
