import { isUserAllowed } from '@lumapps/permissions';
import { BackOfficeStore } from '@lumapps/redux/types';

import { METADATA_PERMISSIONS } from '../permissions';

export const isMetadataEditAllowed = isUserAllowed(METADATA_PERMISSIONS.METADATA_EDIT);
export const isMetadataDeleteAllowed = isUserAllowed(METADATA_PERMISSIONS.METADATA_DELETE);

/**
 * Get Metadata by UID.
 */
export const getMetadataByID = (state: BackOfficeStore) => state.metadata.entities;

/**
 * Get Metadata Families.
 */
export const getMetadataFamilies = (state: BackOfficeStore) => state.metadata.families;
