import React from 'react';

import { classnames, margin } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiTranslate, mdiMenuDown } from '@lumapps/lumx/icons';
import { List, ListItem, Size, Emphasis, Button, Dropdown, Offset, Placement } from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';
import { useQueryParams } from '@lumapps/router';
import { useTranslate, getKeyForLanguage } from '@lumapps/translations';

import { LANGUAGES } from '../../keys';

export interface LanguageSwitcherProps {
    /** list of languages ids ('es', 'en', 'fr') */
    languages: string[];
    /** current language */
    defaultLanguage: string;
    /** current input language value */
    inputLanguage: string;
    /** whether the dropdown should be open or not */
    open?: boolean;
    /** whether the Language Switcher should be displayed or not */
    isVisible?: boolean;
    /** whether the language switcher should be disabled */
    isDisabled?: boolean;
    /** custom styles passed down to the button. Useful when this component needs to use custom colors  */
    styles?: Record<string, any>;
    /** if set to true, this function will return the languages defined for the current customer.
     * By default, it returns the list of languages defined for the current instance/site */
    useCustomerLanguages?: boolean;
    /** callback to be executed when the language is changed */
    onChange: (language: string) => void;
    /** scope where this component is used */
    scope?: string;
    /** emphasis that will be applied to the button */
    emphasis?: Emphasis;
    /** whether the left icon on the button should be displayed */
    shouldShowLeftIcon?: boolean;
    /** whether the right icon on the button should be displayed */
    shouldShowRightIcon?: boolean;
    /** whether the switcher should be adapted to the current screen size or not */
    shouldAdaptToScreen?: boolean;
    /** amount of margin right to be added, which may vary depending on the context */
    marginRight?: Size | null;
    /** custom class name */
    className?: string;
    placement?: Placement;
}

const LANGUAGE_MENU_OFFSET: Offset = { away: 8 };

/**
 * Language switcher displayed on the top bar.
 *
 * @family Translations
 * @param LanguageSwitcherProps
 * @returns LanguageSwitcher
 */
const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    languages,
    defaultLanguage,
    inputLanguage,
    open = false,
    isVisible = false,
    scope = 'language-selector',
    styles = {},
    emphasis = Emphasis.low,
    onChange,
    isDisabled = false,
    shouldShowLeftIcon = true,
    shouldShowRightIcon = true,
    shouldAdaptToScreen = true,
    marginRight = Size.big,
    className,
    placement = Placement.BOTTOM_START,
}) => {
    const { isWide, isMobile } = useResponsive();
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);
    const buttonAnchorRef = React.useRef(null);

    const { lang: currentLang } = useQueryParams();

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(open);
    const [selectedLanguage, setSelectedLanguage] = React.useState(currentLang || defaultLanguage);

    const translatedLanguages = React.useMemo(() => {
        if (!languages || languages.length <= 1) {
            return undefined;
        }
        const transLangs = languages.map((lang) => ({ key: lang, label: translateKey(getKeyForLanguage(lang)) }));

        return transLangs.sort((a, b) => a.label.localeCompare(b.label));
    }, [languages, translateKey]);

    if (!isVisible || !translatedLanguages || translatedLanguages.length <= 1) {
        return null;
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const onLanguageSelected = (language: string) => () => {
        setIsDropdownOpen(false);

        if (!language || language === selectedLanguage) {
            return;
        }

        setSelectedLanguage(language);
        onChange(language);
    };

    const displayLanguage = languages.includes(selectedLanguage) ? selectedLanguage : inputLanguage;
    const langValue = translateKey(getKeyForLanguage(displayLanguage));
    const adaptiveLangValueDisplay = isWide ? langValue : '';

    return (
        <>
            <Button
                ref={buttonAnchorRef}
                disabled={isDisabled}
                aria-haspopup="listbox"
                aria-expanded={isDropdownOpen}
                aria-label={`${translateKey(LANGUAGES.LANGUAGE_SWITCHER_ARIA_LABEL)} ${langValue}`}
                onClick={toggleDropdown}
                leftIcon={shouldShowLeftIcon ? mdiTranslate : undefined}
                rightIcon={!shouldShowRightIcon || (isMobile && shouldAdaptToScreen) ? undefined : mdiMenuDown}
                emphasis={emphasis}
                theme={styles.theme}
                className={classnames(className, 'language-switcher__button', {
                    'language-switcher__button--no-label': !isWide && shouldAdaptToScreen,
                    [margin('right', marginRight)]: !isMobile && shouldShowRightIcon && shouldAdaptToScreen,
                })}
                {...get({ element: 'dropdown' })}
            >
                {shouldAdaptToScreen ? adaptiveLangValueDisplay : langValue}
            </Button>
            <Dropdown
                closeOnClick
                closeOnEscape
                isOpen={isDropdownOpen}
                onClose={toggleDropdown}
                anchorRef={buttonAnchorRef}
                offset={LANGUAGE_MENU_OFFSET}
                placement={placement}
            >
                <List>
                    {translatedLanguages.length > 0 &&
                        translatedLanguages.map((lang) => (
                            <ListItem
                                isSelected={selectedLanguage === lang.key}
                                key={lang.key}
                                size={Size.tiny}
                                onItemSelected={onLanguageSelected(lang.key)}
                                {...get({ element: 'language', type: lang.key })}
                            >
                                {lang.label}
                            </ListItem>
                        ))}
                </List>
            </Dropdown>
        </>
    );
};

export { LanguageSwitcher };
