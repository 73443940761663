/* istanbul ignore file */
import remove from 'lodash/remove';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { SAConnectedUserState } from './types';

const initialState: SAConnectedUserState = {
    user: undefined,
    socialNetworkAccesses: [],
    saUserLoadingStatus: BaseLoadingStatus.initial,
    hasSocialNetworkAccessesLoaded: false,
};

const { actions, reducer } = createSlice({
    domain: 'saConnectedUser',
    initialState,
    reducers: {
        startFetchUser: (state: SAConnectedUserState) => {
            set(state, 'saUserLoadingStatus', BaseLoadingStatus.loading);
        },
        fetchUserSuccess: (state: SAConnectedUserState, action: PayloadAction<any>) => {
            set(state, 'user', action.payload);
            set(state, 'saUserLoadingStatus', BaseLoadingStatus.idle);
        },
        fetchSocialNetworkAccessesSuccess: (state: SAConnectedUserState, action: PayloadAction<any>) => {
            set(state, 'socialNetworkAccesses', action.payload);
            set(state, 'hasSocialNetworkAccessesLoaded', true);
        },
        disconnectSuccess: (state: SAConnectedUserState, action: PayloadAction<string>) => {
            remove(state.socialNetworkAccesses, (access) => access.socialNetworkId === action.payload);
        },
    },
});

export { actions, reducer };
