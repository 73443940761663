import { getAttributes } from '@lumapps/data-attributes';

/////////////////////////////

(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetSettingsHeaderController(Widget, Translation) {
        'ngInject';

        var widgetSettingsHeader = this;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetSettingsHeader.Widget = Widget;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Checks if current widget has a title.
         *
         * @return {boolean} Whether or not current widget has a title.
         */
        function hasCurrentWidgetTitle() {
            return Widget.shouldDisplayWidgetHeader(Widget.getCurrent().title);
        }

        /////////////////////////////

        widgetSettingsHeader.hasCurrentWidgetTitle = hasCurrentWidgetTitle;
        const getWidgetAttributes = getAttributes('widget');
        widgetSettingsHeader.getAttributes = getWidgetAttributes;
        widgetSettingsHeader.collapsedByDefaultActionAttributes = getWidgetAttributes({
            element: 'settings',
            action: 'collapsed-default-checkbox',
        });
        widgetSettingsHeader.enableCollapseActionAttributes = getWidgetAttributes({
            element: 'settings',
            action: 'collapsible-checkbox',
        });

        if (!widgetSettingsHeader.Widget.getCurrent().properties.isCollapsible) {
            widgetSettingsHeader.Widget.getCurrent().properties.isClosed = false;
        }

        /**
         * Define the tooltip to display for the box Collapse widget by default
         *
         * @return {string}
         */
        function defineTolltip(isLayoutEnabled) {
            if (isLayoutEnabled && !widgetSettingsHeader.hasCurrentWidgetTitle()) {
                return Translation.translate('FRONT.SETTINGS.WIDGET_HEADER_SETTINGS.ALLOW_COLLAPSE.TOOLTIP');
            }
            if (!widgetSettingsHeader.Widget.getCurrent().properties.isCollapsible && !isLayoutEnabled) {
                return Translation.translate('FRONT.SETTINGS.WIDGET_HEADER_SETTINGS.COLLAPSE_BY_DEFAULT.TOOLTIP');
            }
            return undefined
        }
        
        widgetSettingsHeader.defineTolltip = defineTolltip;

        function toggleDefaultIfIsLayoutEnabled(isLayoutEnabled) {
            if (isLayoutEnabled) {
                widgetSettingsHeader.Widget.getCurrent().properties.isCollapsible = true;
            }
        }
        widgetSettingsHeader.toggleDefaultIfIsLayoutEnabled = toggleDefaultIfIsLayoutEnabled;

        /////////////////////////////

        /**
         * Set the parent controller.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            widgetSettingsHeader.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function WidgetSettingsHeaderDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: WidgetSettingsHeaderController,
            controllerAs: 'widgetSettingsHeader',
            require: ['widgetSettingsHeader', '^widgetSettings'],
            restrict: 'E',
            templateUrl: '/client/front-office/modules/content/modules/widget/common/views/widget-settings-header.html',
            transclude: true,
        };
    }

    angular.module('Directives').directive('widgetSettingsHeader', WidgetSettingsHeaderDirective);
})();
