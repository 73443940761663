import React, { createElement, Component, createRef } from 'react';
import { connect } from '@lumapps/redux/react';
import classNames from 'classnames';

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import lowerCase from 'lodash/lowerCase';
import noop from 'lodash/noop';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import isEqual from 'lodash/isEqual';

// eslint-disable-next-line import/no-cycle
import * as Widgets from '.';
import { addEntity, removeEntity } from './ducks/entities';
import { widgetType } from './types';
import { WidgetContent } from './WidgetContent';
import { isDesignerMode } from '../../utils';
import { WidgetHeader } from './WidgetHeader';
import { getInputLanguage, hasTranslation, translate as t, getLang } from '../../translations';
import { WidgetFooter } from './WidgetFooter';
import { getGlobalStyleName, getWidgetSize } from './utils';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';

/**
 * React Widgets skeleton.
 *
 * To use React Widgets, all you need to do is to pass the `component` object from the backend.
 * This component will read its `widgetType` property in order to load the proper widget.
 */
class Widget extends Component {
    constructor(props) {
        super(props);
        const widgetName = `Widget${replace(startCase(lowerCase(props.component.widgetType)), ' ', '')}`;

        // Retrieve the good widget from the widget catalog.
        // eslint-disable-next-line import/namespace
        this.WidgetComponent = Widgets[widgetName];

        this.state = {
            isContentFolded: this.shouldBeFolded(),
            isEditable: this.WidgetComponent.isEditable(),
            isEditing: false,
            isHeaderBeingEdited: false,
            isWidgetEmpty: false,
            isWidgetHidden: false,
            widgetSize: 'm',
        };

        this.onBlur = this.onBlur.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.setWidgetSize = this.setWidgetSize.bind(this);
        this.shouldBeCollapsed = this.shouldBeCollapsed.bind(this);
        this.shouldBeFolded = this.shouldBeFolded.bind(this);
        this.shouldUseWidgetContent = this.shouldUseWidgetContent.bind(this);
        this.toggleWidgetContent = this.toggleWidgetContent.bind(this);
        this.setHeaderEditionStatus = this.setHeaderEditionStatus.bind(this);

        this.widgetRef = createRef();
    }

    componentDidMount() {
        const { addEntity: addEntityAction, component } = this.props;

        this.handleAsyncWidgetEmpty();
        addEntityAction(component);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidUpdate(prevProps) {
        /**
         * Remote widgets have to wait for an asynchronous call to finish
         * before knowing whether the extension is enabled or not.
         * At this point, the "folded" has already been set and would not have taken the
         * initial "folded" attribute into account.
         * We have to update it manually once the value changes.
         */
        const { extensionEnabled, component } = this.props;
        if(component.widgetType === 'remote' && extensionEnabled !== prevProps.extensionEnabled){
            this.setState({ isContentFolded: this.shouldBeFolded() })
        }
        this.setWidgetSize();
        this.handleAsyncWidgetEmpty();
    }

    componentWillUnmount() {
        const { removeEntity: removeEntityAction, component } = this.props;
        removeEntityAction({ uuid: component.uuid });
    }

    /**
     * Only useful for widgets that will use the `onBlur` event, like
     * the Html or Title widgets.
     */
    onBlur() {
        this.setState({ isEditing: false });
    }

    /**
     * Set the `isEditing` state on widgets when double clicking on it.
     * This behavior is only available while in designer mode with a widget marked as `isEditable`.
     */
    onDoubleClick() {
        // eslint-disable-next-line no-unused-expressions
        isDesignerMode() && this.setState({ isEditing: true });
    }

    /**
     * Applies all the widget classes.
     *
     * @return {Object} All the classes that should apply to this widget.
     */
    getWidgetClass() {
        const { component } = this.props;
        const { isContentFolded, isEditable, isEditing, isWidgetEmpty, widgetSize } = this.state;
        const {
            class: overrideClass = '',
            fullHeight = false,
            style = {},
            viewMode,
            viewModeVariant,
        } = component.properties;
        const theme = get(style, 'content.theme', 'light');
        const hasCustomHeight = Boolean(get(style, 'content.height'));
        const customClasses = overrideClass.split(',').map((className) => {
            const trimmedName = className.trim();
            if (trimmedName.length > 0) {
                return `widget--${trimmedName}`;
            }

            return undefined;
        });

        return classNames(
            'widget',
            ...customClasses,
            `widget-${component.uuid}`,
            `widget-${component.widgetType}`,
            `widget--${widgetSize}`,
            `widget--theme-${theme}`,
            component.style && `widget--shared-${getGlobalStyleName(component.style)}`,
            {
                'widget--has-custom-height': hasCustomHeight,
                'widget--has-footer': this.shouldDisplayFooter(),
                'widget--has-header': this.shouldDisplayHeader(),
                'widget--is-empty': isWidgetEmpty && !isEditing,
                'widget--is-collapsible': this.shouldBeCollapsed() && this.shouldDisplayHeader(),
                'widget--is-folded': isDesignerMode() ? false : isContentFolded,
                'widget--is-full-height': fullHeight,
                'widget-editable': isEditable,
                'widget-editable--is-editing': isEditable && isEditing,
                [`widget--view-mode-${viewMode}`]: viewMode,
                [`widget--view-mode-variant-${viewModeVariant}`]: viewModeVariant,
            },
        );
    }

    /**
     * Attach the `widgetSize` attribute when the DOM element is created.
     *
     * @param {HTMLElement} node The current widget node.
     */
    setWidgetSize(node) {
        if (!node && !this.currentNodeRef) {
            return;
        }

        this.currentNodeRef = node || this.currentNodeRef;

        const widgetSize = getWidgetSize(this.currentNodeRef);
        const { widgetSize: currentWidgetSize } = this.state;

        if (currentWidgetSize !== widgetSize) {
            this.setState({
                widgetSize,
            });
        }
    }

    /**
     * Check if the widget is collapsible.
     * If the option is undefined, it's an old widget and we accept it can be collapsed.
     *
     * @return {boolean} Wether the widget is collapsible or not.
     */
    shouldBeCollapsed() {
        const { component } = this.props;

        return get(component, 'properties.isCollapsible', true);
    }

    /**
     * Whether we should display the footer or not.
     *
     * @return {boolean} If there should be a footer or not.
     */
    shouldDisplayFooter() {
        const { component } = this.props;
        const { isWidgetEmpty } = this.WidgetComponent;
        const label = get(component, 'properties.more.label', {});
        const noFallback = get(component, 'properties.noFallback', false);
        // Todo[Marco]: remove getLang once widget component is mapped to redux state (currently on master).
        const translatedLabel = noFallback ? label[getLang('current')] : t(label);

        if (isDesignerMode()) {
            return hasTranslation(label, getInputLanguage());
        }

        return isWidgetEmpty ? !isWidgetEmpty(this.props) && Boolean(translatedLabel) : Boolean(translatedLabel);
    }

    /**
     * Whether we should display the header or not.
     *
     * @return {boolean} If there should be a header or not.
     */
    shouldDisplayHeader() {
        const { component } = this.props;
        const { isWidgetEmpty } = this.WidgetComponent;
        const title = (component && component.title) || {};
        const noFallback = get(component, 'properties.noFallback', false);
        // Todo[Marco]: remove getLang once widget component is mapped to redux state (currently on master).
        const translatedTitle = noFallback ? title[getLang('current')] : t(title);

        if (isDesignerMode()) {
            return hasTranslation(title, getInputLanguage()) || (this.state && this.state.isHeaderBeingEdited);
        }

        return isWidgetEmpty ? !isWidgetEmpty(this.props) && Boolean(translatedTitle) : Boolean(translatedTitle);
    }

    /**
     * Whether we should collapse the widget at its loading.
     *
     * @return {boolean} If there should be folded or not.
     */
    shouldBeFolded() {
        const { component } = this.props;

        return this.shouldDisplayHeader() && this.shouldBeCollapsed() && get(component, 'properties.isClosed');
    }

    /**
     * Toggle the widget content. Trigger when user click on widget header.
     */
    toggleWidgetContent() {
        const { isContentFolded } = this.state;

        if (!this.shouldBeCollapsed()) {
            return;
        }

        this.setState({ isContentFolded: !isContentFolded });
    }

    /**
     * Defines whether the widget wrapper should handle widget empty and hidden state and
     * use widget content element.
     *
     * @return {boolean} Whether it should use widget content or not.
     */
    shouldUseWidgetContent() {
        const { isWidgetEmpty, isWidgetHidden } = this.WidgetComponent;

        return isFunction(isWidgetEmpty) && isFunction(isWidgetHidden);
    }

    setHeaderEditionStatus(isBeingEdited) {
        this.setState({ isHeaderBeingEdited: isBeingEdited });
    }

    /**
     * In some cases, a widget is considered as empty only once a API call was made.
     */
    async handleAsyncWidgetEmpty() {
        const { isWidgetEmpty, isWidgetHidden } = this.WidgetComponent;

        if (!isWidgetEmpty || !isWidgetHidden) {
            return;
        }

        this.setState({
            isWidgetEmpty: await Promise.resolve(isWidgetEmpty(this.props)),
            isWidgetHidden: await Promise.resolve(isWidgetHidden(this.props)),
        });
    }

    render() {
        const { isEditable, isEditing, isWidgetEmpty, isWidgetHidden, widgetSize, isContentFolded } = this.state;
        const { component, state, widgetStyle, isBasicDesigner, onChange, ...props } = this.props;

        if (!this.WidgetComponent) {
            return null;
        }

        const widgetReactElement = createElement(this.WidgetComponent, {
            isEditing,
            isMainWidget: component.isMainWidget,
            onBlur: this.onBlur,
            properties: component.properties || {},
            theme: get(component, 'properties.style.content.theme', 'light'),
            uuid: component.uuid,
            widgetSize,
            widgetType: component.widgetType,
            widgetRef: this.widgetRef,
            ...props,
        });

        return (
            (!isWidgetHidden || component.properties.noResults || isDesignerMode()) && (
                <div
                    ref={mergeRefs([this.setWidgetSize, this.widgetRef])}
                    className={this.getWidgetClass({ isEditable, isWidgetEmpty })}
                    style={widgetStyle}
                    onDoubleClick={isEditable ? this.onDoubleClick : noop}
                >
                    {this.shouldDisplayHeader() && (
                        <WidgetHeader
                            isBasicDesigner={isDesignerMode() && isBasicDesigner}
                            icon={get(component.properties, 'headerIcon')}
                            style={get(component.properties, 'style.header', {})}
                            parentStyle={component?.properties?.style?.main}
                            title={component.title}
                            toggleWidgetContent={this.toggleWidgetContent}
                            component={component}
                            onChange={onChange}
                            setHeaderEditionStatus={this.setHeaderEditionStatus}
                            // Header is standalone when content is folded and there is no footer
                            isStandalone={!isDesignerMode() && isContentFolded && !this.shouldDisplayFooter()}
                        />
                    )}

                    {this.shouldUseWidgetContent() ? (
                        <WidgetContent
                            isEditing={isEditing}
                            isWidgetEmpty={isWidgetEmpty}
                            properties={component.properties}
                            widgetType={component.widgetType}
                        >
                            {widgetReactElement}
                        </WidgetContent>
                    ) : (
                        widgetReactElement
                    )}

                    {this.shouldDisplayFooter() && (
                        <WidgetFooter
                            component={component}
                            icon={get(component.properties, 'footerIcon')}
                            more={component.properties.more}
                            state={component.state}
                            style={get(component.properties, 'style.footer', {})}
                            parentStyle={component?.properties?.style?.main}
                        />
                    )}
                </div>
            )
        );
    }
}

Widget.propTypes = widgetType;

Widget.defaultProps = {
    components: {
        properties: {},
    },
};

const mapStateToProps = (state) => ({
    widgetEntities: state.widgets.entities,
});

const connectedWidget = connect(mapStateToProps, {
    addEntity,
    removeEntity,
})(Widget);

// eslint-disable-next-line import/prefer-default-export
export { connectedWidget as Widget };
