import first from 'lodash/first';
import get from 'lodash/get';
import union from 'lodash/union';

import { getVideoId, getVideoUrlWebsiteName } from 'components/utils/video_utils';

function AttachmentsController(
    $scope,
    $timeout,
    Config,
    CrawlerFactory,
    Document,
    Features,
    FormValidation,
    LsTextEditor,
    LsTextEditorMarkdown,
    LxDialogService,
    LxNotificationService,
    MediaConstant,
    Post,
    Translation,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * An object that will hold references to the forms used to add/edit attachments.
     *
     * @type {Object}
     */
    vm.form = {};

    /**
     * Indicates if the call to crawl a url has errored.
     *
     * @type {boolean}
     */
    vm.isCrawlerCallError = false;

    /**
     * Indicates if the call to crawl a url is in progress.
     *
     * @type {boolean}
     */
    vm.isCrawlerCallInProgress = false;

    /**
     * The value of the url input field used to add a new link attachment.
     *
     * @type {string}
     */
    vm.linkInput = undefined;

    vm.activeIndex = undefined;

    vm.attachments = [...(vm.content.files ?? []), ...(vm.content.images ?? [])];

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.Document = Document;
    vm.FormValidation = FormValidation;
    vm.MediaConstant = MediaConstant;
    vm.Post = Post;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Clears all attachments on the current content.
     * This means image / file galleries and also link previews.
     */
    function _clearAttachments() {
        vm.content.files = [];
        vm.content.images = [];
        vm.attachments = [];
        vm.content.links = [];
        vm.type = undefined;
    }

    /**
     * Convert a media object to array whether it is not already, and get only first element if not multi.
     *
     * @param  {Object|Array} media The media object to convert to array.
     * @param  {boolean}      multi Whether we only want the first element in array.
     * @return {Array}        The converted element to array single or multi.
     */
    function _getMediaObjectToArray(mediaObject, multi) {
        if (angular.isUndefinedOrEmpty(mediaObject)) {
            return [];
        }

        let array;

        if (multi) {
            array = angular.isArray(mediaObject) ? mediaObject : [mediaObject];
        } else {
            array = angular.isArray(mediaObject) ? [first(mediaObject)] : [mediaObject];
        }

        return array;
    }

    function onCloseMosaic() {
        vm.activeIndex = null;
    }

    function openMosaic(index) {
        $scope.$apply(() => {
            vm.activeIndex = index;
        });
    }

    function _convertMediaIntoThumbnails(medias) {
        if (angular.isUndefinedOrEmpty(medias)) {
            return [];
        }

        let array = angular.isArray(medias) ? medias : [medias];

        return array.map((media) => ({
            url: Document.getCroppedThumbnailFromMedia(media, true, null, null),
            focusPoint: Document.getFocusPointFromMedia(media, true, null),
        }));
    }

    /**
     * Set the attachment of the content object to a link preview.
     * Note: for now, we only allow to have ONE link attachment per post.
     *
     * @param {Object} link The link to add to the content as an attachment.
     */
    function _setAttachmentLink(link = {}) {
        const videoWebsiteName = getVideoUrlWebsiteName(link.url);

        vm.type = Config.ATTACHMENT_TYPE.LINK;

        vm.content.links = [
            {
                description: link.description,
                images: link.images || [],
                lang: Translation.getLang('current'),
                thumbnailIndex: 0,
                title: link.title,
                url: link.url,
            },
        ];

        if (angular.isDefinedAndFilled(videoWebsiteName)) {
            vm.content.links[0].videoId = getVideoId(link.url, videoWebsiteName);
        }
    }

    /**
     * Get the preview of a url.
     *
     * @param {Event}  evt   The original event triggering this method.
     * @param {string} [url] The url to fetch.
     *                       Defaults to the value of the url textfield.
     */
    function _getLinkPreview(evt, url = vm.linkInput) {
        vm.isCrawlerCallInProgress = true;
        $scope.$emit('attachments__preview-loading-update', vm.isCrawlerCallInProgress);

        CrawlerFactory.urlinfo(
            {
                url,
            },
            (response) => {
                _setAttachmentLink(response);
                vm.isCrawlerCallError = false;
                vm.isCrawlerCallInProgress = false;
                $scope.$emit('attachments__preview-loading-update', vm.isCrawlerCallInProgress);
            },
            () => {
                _clearAttachments();
                vm.isCrawlerCallError = true;
                vm.isCrawlerCallInProgress = false;

                LxNotificationService.error(Translation.translate('FRONT.POST_DIALOG.CRAWLER_ERROR'));

                $scope.$emit('attachments__preview-loading-update', vm.isCrawlerCallInProgress);
            },
        );
    }

    /**
     * Sets the attachment type when we close a media picker or we paste/drop files.
     * Set attachment type to 'image' only once we've closed the media picker and actually selected images or
     * after droping or pasting files.
     *
     * @param {Event}  evt      The event triggering this method.
     * @param {string} dialogId The id of the dialog that triggered this function.
     */
    function _setAttachments() {
        // If we added then removed all media one by one in the media picker.
        // Then we reset the current attachment type.
        vm.type = undefined;
        const attachments = vm.multi ? vm.attachments : (vm.attachments.length ? vm.attachments : [vm.attachments]);

        const attachmentsType = attachments.every(
            (att) =>
                att.content[0] &&
                att.content[0].type === Config.ATTACHMENT_TYPE.IMAGE &&
                att.content[0].ext !== 'svg' &&
                (att.source === MediaConstant.PROVIDERS.lumapps || att.source === MediaConstant.PROVIDERS.haussmann),
        )
            ? Config.ATTACHMENT_TYPE.IMAGE
            : Config.ATTACHMENT_TYPE.FILE;

        // Image picker dialog.
        if (attachmentsType === Config.ATTACHMENT_TYPE.IMAGE) {
            vm.content.images = _getMediaObjectToArray(attachments, vm.multi);
            vm.content.files = [];
            if (angular.isDefinedAndFilled(vm.content.images)) {
                vm.type = Config.ATTACHMENT_TYPE.IMAGE;
            }
            // File picker dialog.
        } else {
            vm.content.files = _getMediaObjectToArray(attachments, vm.multi);
            vm.content.images = [];

            if (angular.isDefinedAndFilled([vm.content.driveFiles, vm.content.nonDriveFiles], 'some')) {
                vm.content.driveFiles = _getMediaObjectToArray(vm.content.driveFiles, vm.multi);
                vm.content.nonDriveFiles = _getMediaObjectToArray(vm.content.nonDriveFiles, vm.multi);

                vm.content.files = union(vm.content.driveFiles, vm.content.nonDriveFiles);
            }

            if (angular.isDefinedAndFilled(vm.content.files)) {
                vm.type = Config.ATTACHMENT_TYPE.FILE;
                vm.content.files = _getMediaObjectToArray(vm.content.files, vm.multi);
            }
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Get confirmation before clearing all attachments from the post / comment.
     */
    function confirmClearAttachments() {
        LxNotificationService.confirm(
            Translation.translate('ATTACHMENTS_DELETE'),
            Translation.translate('ATTACHMENTS_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (answer) {
                    _clearAttachments();
                }
            },
        );
    }

    /////////////////////////////

    vm.confirmClearAttachments = confirmClearAttachments;
    vm.convertMediaIntoThumbnails = _convertMediaIntoThumbnails;
    vm.onCloseMosaic = onCloseMosaic;
    vm.openMosaic = openMosaic;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    if (vm.admin) {
        // Display the link preview when URL is found in the content.
        $scope.$on('attachments__get-preview', _getLinkPreview);

        /**
         * Set the attachement after droping or pasting files in a comment zone.
         */
        $scope.$on('attachments__paste-drop-files-end', () => {
            $scope.$apply(() => {
                vm.attachments = [...(vm.content.files ?? []), ...(vm.content.images ?? [])];
                _setAttachments();
            });
        });

        // When all images / files / links have been removed, clear the current attachment type etc...
        $scope.$on('attachments__remove-all', _clearAttachments);

        /**
         * Resets the content object when we close the create new post dialog.
         *
         * @param {Event}  evt      The event triggering this method
         * @param {string} dialogId The identifier of the dialog that triggered this function.
         */
        $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
            if (dialogId === vm.dialogSettings.links.key) {
                vm.linkInput = undefined;
            }
        });

        /**
         * Set the linkInput value to the existing link (if any) whenever we open the add link dialog.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The identifier of the dialog being opened.
         */
        $scope.$on('lx-dialog__open-start', (evt, dialogId) => {
            if (vm.dialogSettings.links.key === dialogId && angular.isDefinedAndFilled(get(vm.content, 'links'))) {
                vm.linkInput = vm.content.links[0].url;
            }
        });

        /**
         * Clears the attachments when we open the media picker.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The id of the dialog that triggered this function.
         */
        $scope.$on('abstract-picker__open-end', (evt, dialogId) => {
            // Only clear if we don't already have images set and it's the image picker.
            if (
                (dialogId === vm.dialogSettings.images.key && vm.type !== Config.ATTACHMENT_TYPE.IMAGE) ||
                (dialogId === vm.dialogSettings.files.key && vm.type !== Config.ATTACHMENT_TYPE.FILE)
            ) {
                _clearAttachments();
            }
        });

        /**
         * Transforms images or files properties to objects when in 'single' mode.
         *
         * @param {Event}  evt      The original event triggering this method.
         * @param {string} dialogId The id of the dialog that triggered this function.
         */
        $scope.$on('abstract-picker__open-start', (evt, dialogId) => {
            // Media / file picker use an object when we're in 'single' mode but an array when we're in multi mode.
            if (!vm.multi) {
                if (dialogId === vm.dialogSettings.images.key) {
                    vm.content.images = angular.isDefinedAndFilled(vm.content.images)
                        ? first(vm.content.images)
                        : [];
                } else if (dialogId === vm.dialogSettings.files.key) {
                    vm.content.files = angular.isDefinedAndFilled(vm.content.files)
                        ? first(vm.content.files)
                        : [];
                }
            }
        });

        /**
         * Sets the attachment type when we close a media picker.
         * Set attachment type to 'image' only once we've closed the media picker and actually selected images.
         */
        $scope.$on('abstract-picker__close-end', _setAttachments);
    }

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const hasFiles = angular.isDefinedAndFilled(vm.content.files);
        const hasDriveImages = (vm.content.images || []).some((im) => im.source !== MediaConstant.PROVIDERS.lumapps);

        vm.admin = vm.admin || false;
        vm.content.files = hasFiles || hasDriveImages ? union([], vm.content.files, vm.content.images || []) : [];
        vm.content.images = hasFiles || hasDriveImages ? [] : union([], vm.content.images);
        vm.content.links = vm.content.links || [];
        vm.attachments = union([], vm.content.files, vm.content.images);
        vm.driveOnly = vm.driveOnly || false;

        // When not admin mode, no need to have all the dialogs, so we don't need the settings.
        vm.dialogSettings = vm.admin ? vm.dialogSettings : {};
        vm.multi = vm.multi || false;
    }

    init();
}

/////////////////////////////

/**
 * Handles the display of the different attachment types. This is used for example on community posts.
 *
 * @param {boolean} [admin=false]     Indicates if we're in admin mode or not. Admin mode means the user can add /
 *                                    edit attachments of the related content object.
 * @param {Object}  content           The related content object to which we want to add the attachments.
 * @param {string}  context           The context in which the attachments are. Post or Comment.
 * @param {Object}  [dialogSettings]  An object map with all the identifiers and statuses for the dialogs / pickers.
 * @param {boolean} [driveOnly=false] Indicates if the file picker should allow to pick from Google drive only or
 *                                    from both Google drive AND the media picker filtered on files only.
 * @param {boolean} [multi=false]     Whether the user can select multiple files as attachments or not.
 * @param {string}  type              The current attachment type associated with the content object.
 */

function AttachmentsDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: AttachmentsController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'E',
        scope: {
            admin: '=?lsAdmin',
            content: '=lsContent',
            context: '@lsContext',
            dialogSettings: '=?lsDialogSettings',
            driveOnly: '=?lsDriveOnly',
            multi: '=?lsMulti',
            theme: '=?lsTheme',
            type: '=lsCurrentType',
        },
        templateUrl: '/client/front-office/modules/social/attachments/views/attachments.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsAttachments', AttachmentsDirective);

export { AttachmentsController, AttachmentsDirective };
