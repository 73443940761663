import { connect } from '@lumapps/redux/react';
import { Dispatch } from '@lumapps/redux/types';

import { toggleFavorite } from '../../ducks';
import { BlockDirectoryEntry as BlockDirectoryEntryType } from '../../types';
import { BlockDirectoryEntry } from './BlockDirectoryEntry';

type ConnectedProps = BlockDirectoryEntryType;

interface DispatchProps {
    favorite(): void;
    unfavorite(): void;
}

const mapDispatchToProps = (dispatch: Dispatch, props: ConnectedProps): DispatchProps => {
    const [widgetId, ...blockPath] = props.path as string[];
    return {
        favorite: () => dispatch(toggleFavorite(false, props.directoryEntryId, widgetId, blockPath)),
        unfavorite: () => dispatch(toggleFavorite(true, props.directoryEntryId, widgetId, blockPath)),
    };
};

export const ConnectedBlockDirectoryEntry = connect(null, mapDispatchToProps)(BlockDirectoryEntry);

export { ConnectedBlockDirectoryEntry as BlockDirectoryEntry };
