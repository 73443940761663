import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { SelectableCard } from '@lumapps/lumx-cards/components/SelectableCard';
import { ImageLightbox, useImageLightbox } from '@lumapps/lumx-images/components/ImageLightbox';
import { mdiDelete, mdiGoogleDrive, mdiMicrosoftSharepoint } from '@lumapps/lumx/icons';
import {
    AspectRatio,
    ButtonProps,
    ColorPalette,
    GenericBlock,
    Icon,
    InlineList,
    Size,
    Text,
    Theme,
    Thumbnail,
    ThumbnailVariant,
    Link,
    Typography,
} from '@lumapps/lumx/react';
import { sendWebviewMobileMessage } from '@lumapps/mobile-webview/message-emit';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { formatDate } from '@lumapps/utils/date/formatDate';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';

import { FILE_PREVIEW_BLOCK_CLASSNAME as CLASSNAME } from '../../constants';
import { MEDIAS } from '../../keys';
import { MediaSource, MediaSourceV2, MediaStatus } from '../../types';
import { FILE_TYPES_ICONS, getMediaIcon } from '../../utils';
import { resizeImage } from '../../utils/resizeImage';
import { FileBlockSkeleton } from './FilePreviewBlockSkeleton';
import { FilePreviewDataElement } from './FilePreviewDataElement';
import { FilePreviewProviderElement } from './FilePreviewProviderElement';

import './index.scss';

export interface FilePreviewBlockProps {
    /** The file id */
    id?: string;
    /** The file name */
    name?: string;
    /** The file url */
    url?: string;
    /** The url of the thumbnail */
    thumbnailUrl?: string;
    /** The file mime type */
    mimeType?: string;
    /** The file status (deleted or live) */
    status?: MediaStatus;
    /** The date of the file creation */
    createdAt?: string;
    /** The date of the file last edition */
    editedAt?: string;
    /** The file provider google, microsoft or local (uploaded from computer) */
    provider?: MediaSource | MediaSourceV2;
    /** The alt defined on image file */
    alt?: string;
    /** Whether to display image files with a thumbnail instead of an icon */
    shouldDisplayImageAsThumbnail?: boolean;
    /** React nodes as action to put right to the file name */
    actions?: ReactNode[];
    /** Whether the file preview block is loading */
    isLoading?: boolean;
    /** The theme to be applied */
    theme?: Theme;
    /** Link for aria label file element */
    linkAriaLabel?: string;
}

/**
 *  File block to display a file with an icon, name with link and informations beneath (date & provider)
 */
export const FilePreviewBlock: React.FC<FilePreviewBlockProps> = ({
    id,
    name,
    mimeType,
    url,
    createdAt,
    editedAt,
    provider,
    alt,
    actions,
    thumbnailUrl,
    shouldDisplayImageAsThumbnail = true,
    theme = Theme.light,
    isLoading = false,
    status = MediaStatus.LIVE,
    linkAriaLabel,
    ...forwardedProps
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { translateKey, translateAndReplace } = useTranslate();
    const app = getConstants().applicationId;
    const isWebview = app === AppId.webview;
    const ref = React.useRef(null);
    const isMediaProvided = !!id && !!name && !!url;

    const createdAtLabel =
        createdAt &&
        translateAndReplace(GLOBAL.RELATIVE_CREATED_AT, {
            RELATIVE_DATE: formatFromNow({ date: createdAt, thresholdDisplayFullDate: 1, dateStringFormat: 'LL' }),
        });
    const editedAtLabel =
        editedAt &&
        translateAndReplace(GLOBAL.EDITED_AT_DATE, {
            DATE: formatDate(editedAt),
        });
    const mediaDate = editedAtLabel || createdAtLabel;

    const mediaIcon = mimeType ? getMediaIcon({ mimeType }) : FILE_TYPES_ICONS.folder;
    const isImageFile = mediaIcon === FILE_TYPES_ICONS.photo;
    const isFolder = mediaIcon === FILE_TYPES_ICONS.folder;

    const providerInfos = React.useMemo(() => {
        if (!provider) {
            return undefined;
        }
        switch (provider) {
            case MediaSourceV2.GOOGLE_DRIVE:
                return { icon: mdiGoogleDrive, label: 'Google Drive' };
            case MediaSourceV2.MICROSOFT:
                return { icon: mdiMicrosoftSharepoint, label: 'SharePoint' };
            default:
                return undefined;
        }
    }, [provider]);

    const label = React.useMemo(() => {
        if (!isMediaProvided) {
            return '';
        }

        if (isImageFile) {
            return translateAndReplace(GLOBAL.DISPLAY_SOMETHING, {
                ENT: name,
            });
        }

        return translateAndReplace(GLOBAL.OPEN_SOMETHING, {
            ENT: name,
        });
    }, [isImageFile, isMediaProvided, name, translateAndReplace]);

    const { imageLightboxProps, getTriggerProps } = useImageLightbox(
        isImageFile && isMediaProvided ? [{ image: url, alt: alt || '' }] : undefined,
    );

    let buttonProps: Partial<ButtonProps> | undefined;
    // if image file from drive we provide a link instead open in Lightbox
    if (isImageFile && provider !== MediaSourceV2.GOOGLE_DRIVE && provider !== MediaSourceV2.MICROSOFT) {
        buttonProps = getTriggerProps();
    } else if (isWebview && !isFolder && isMediaProvided) {
        // "Other" files should be sent to the host mobile app in webview app.
        buttonProps = {
            onClick: (e: MouseEvent) => {
                e.preventDefault();
                sendWebviewMobileMessage({
                    type: 'redirect',
                    payload: {
                        type: 'document',
                        url,
                        document: {
                            docId: id,
                            provider: 'haussmann_media',
                            mimeType: mimeType || '',
                            name,
                        },
                    },
                });
            },
        };
    }

    const linkProps = { href: url, target: '_blank' } as const;

    const iconButtonElement = (
        <Icon
            color={theme === Theme.dark && isFolder ? ColorPalette.light : mediaIcon.color}
            icon={mediaIcon.icon}
            size={Size.m}
        />
    );

    const figureElement = () => {
        if (status === MediaStatus.DELETED) {
            return (
                <Icon
                    icon={mdiDelete}
                    size={Size.m}
                    color={theme === Theme.dark && isFolder ? ColorPalette.light : ColorPalette.dark}
                />
            );
        }

        const imageThumbnailUrl = isImageFile && isMediaProvided ? url : undefined;
        let figureThumbnailUrl: string | undefined;
        if (shouldDisplayImageAsThumbnail && imageThumbnailUrl) {
            // Use the image if specified, and it's an image type
            figureThumbnailUrl = imageThumbnailUrl;
        } else if (thumbnailUrl !== undefined && !shouldDisplayImageAsThumbnail) {
            // Use the thumbnail if given, and image as thumbnail is not forced
            figureThumbnailUrl = thumbnailUrl;
        }

        if (figureThumbnailUrl) {
            return (
                <Thumbnail
                    size={Size.m}
                    image={provider === 'local' ? resizeImage(figureThumbnailUrl, 80) : figureThumbnailUrl}
                    {...getTriggerProps()}
                    alt={label}
                    variant={ThumbnailVariant.rounded}
                    aspectRatio={AspectRatio.square}
                    fallback={iconButtonElement}
                    theme={theme}
                    tabIndex="0"
                />
            );
        }

        if (mediaIcon) {
            // Use media icon otherwise
            return iconButtonElement;
        }

        return null;
    };

    return isLoading ? (
        <FileBlockSkeleton
            theme={theme}
            actions={actions}
            dateElement={!!mediaDate}
            providerElement={!!providerInfos}
        />
    ) : (
        <>
            <SelectableCard color={theme} style={theme === Theme.dark ? { background: 'none' } : {}}>
                <GenericBlock
                    className={block({ [`theme-${theme}`]: Boolean(theme) })}
                    ref={ref}
                    hAlign="center"
                    {...forwardedProps}
                >
                    <GenericBlock.Figure className={element('icon')}>{figureElement()}</GenericBlock.Figure>
                    <GenericBlock.Content className={element('content')} gap="tiny">
                        {status === MediaStatus.DELETED ? (
                            <Text
                                className={element('file-name')}
                                truncate
                                title={translateKey(MEDIAS.FILE_DELETED)}
                                as="span"
                                typography={Typography.subtitle1}
                                color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                            >
                                {translateKey(MEDIAS.FILE_DELETED)}
                            </Text>
                        ) : (
                            <>
                                <SelectableCard.MainAction
                                    as={Link}
                                    aria-label={linkAriaLabel}
                                    {...(buttonProps || linkProps)}
                                >
                                    <Text
                                        className={element('file-name')}
                                        truncate
                                        title={name}
                                        as="span"
                                        typography={Typography.subtitle1}
                                        color={theme === Theme.dark ? ColorPalette.light : ColorPalette.dark}
                                    >
                                        {name}
                                    </Text>
                                </SelectableCard.MainAction>

                                <InlineList typography={Typography.caption}>
                                    {providerInfos && (
                                        <FilePreviewProviderElement
                                            theme={theme}
                                            icon={providerInfos?.icon}
                                            label={providerInfos?.label}
                                        />
                                    )}
                                    {mediaDate && <FilePreviewDataElement mediaDate={mediaDate} theme={theme} />}
                                </InlineList>
                            </>
                        )}
                    </GenericBlock.Content>
                    {actions && <GenericBlock.Actions className={element('actions')}>{actions}</GenericBlock.Actions>}
                </GenericBlock>
            </SelectableCard>
            <ImageLightbox {...imageLightboxProps} />
        </>
    );
};
