import difference from 'lodash/difference';
import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import map from 'lodash/map';
import set from 'lodash/set';

/////////////////////////////

function ChartController($element, $q, $scope, Charts, Translation) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Chart element.
     *
     * @type {Element}
     */
    let _chartElement;

    /**
     * The `chart.js` module async fetch promise.
     *
     * @type {Promise}
     */
    let _chartModulePromise;

    /**
     * Chart plugins (depends on chart type and options).
     *
     * @type {Array}
     */
    const _chartPlugins = [];

    /**
     * Charts colors, one by item.
     *
     * @type {Object}
     */
    let _colors = {
        backgroundColor: [],
        hoverBackgroundColor: [],
    };

    /**
     * Chart data, formatted for ChartJS.
     *
     * @type {Object}
     */
    let _chartData = {};

    /**
     * If dataset label is displayed or not.
     *
     * @type {boolean}
     */
    let _displayLegend = false;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Contains various indicators about the state of the widget.
     *
     * @type {Object}
     */
    vm.is = {
        initialized: false,
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if every dataset has same keys value, if not we add the missing key(s).
     *
     * @param  {Object} datasets The datasets to check.
     * @return {Object} Datasets, completed if needed.
     */
    function _checkDatasets(datasets) {
        let keysValue = [];

        // Record all key values.
        angular.forEach(datasets, function forEachDatasets(dataset) {
            keysValue = keysValue.concat(difference(Object.keys(dataset), keysValue));
        });

        // Look for missing keys value, add them and return.
        angular.forEach(datasets, function forEachDatasets(dataset, index) {
            if (Object.keys(dataset).length === keysValue.length) {
                return;
            }

            angular.forEach(keysValue, function forEachKeysValue(keyValue) {
                datasets[index][keyValue] = dataset[keyValue] || 0;
            });
        });

        return datasets;
    }

    /**
     * Formatted chart data according with chartJS model.
     *
     * @return {Object} Chart data.
     */
    function _getChartData() {
        if (angular.isUndefinedOrEmpty(vm.chartData)) {
            return {};
        }

        const chartData = {
            datasets: [],
            labels: [],
        };

        let datasetsValues = [];
        let datasetLabels = [];

        // Organize data values and data labels.
        angular.forEach(vm.chartData, function forEachChartData(item) {
            chartData.labels.push(item.label);
            datasetsValues.push(item.values);
        });

        // We need to be sure to have the same number of values in each dataset.
        if (angular.isObject(datasetsValues[0])) {
            datasetsValues = _checkDatasets(datasetsValues);
        }

        // Extract datasets labels from the first dataset.
        const datasetLength = angular.isObject(datasetsValues[0])
            ? Object.keys(datasetsValues[0]).length
            : datasetsValues.length;

        // Check if we have dataset labels translation, if not, dataset labels aren't displayed.
        if (angular.isObject(datasetsValues[0])) {
            datasetLabels = Object.keys(datasetsValues[0]);

            if (angular.isDefinedAndFilled(vm.chartDatasetLabels) && vm.chartDatasetLabels.length === datasetLength) {
                _displayLegend = true;
            } else {
                _displayLegend = false;
            }
        } else if (
            angular.isDefinedAndFilled(vm.chartDatasetLabels) &&
            vm.chartDatasetLabels.length === datasetLength
        ) {
            angular.forEach(vm.chartDatasetLabels, function forEachChartDatasetLabel(labelTranslationKey, labelIndex) {
                chartData.labels[labelIndex] = Translation.translate(labelTranslationKey);
            });
        }

        // Check if we have enough color, if not we generate a new pallet, if yes, generate hover color.
        if (angular.isDefinedAndFilled(vm.chartColors) && vm.chartColors.length === datasetLength) {
            _colors.backgroundColor = vm.chartColors;
            _colors.hoverBackgroundColor = [];

            angular.forEach(vm.chartColors, function forEachChartColor(color) {
                _colors.hoverBackgroundColor.push(Charts.getHoverColor(color));
            });
        } else {
            _colors = Charts.getChartItemsColors(datasetLength);
        }

        // Organize chart data according with chartType.
        if (includes(['bar', 'line'], vm.chartType)) {
            angular.forEach(datasetLabels, function forEachDatasetLabels(datasetName, idx) {
                chartData.datasets.push({
                    backgroundColor: _colors.backgroundColor[idx],
                    borderColor: _colors.backgroundColor[idx],
                    // eslint-disable-next-line id-blacklist
                    data: map(datasetsValues, datasetName),
                    fill: false,
                    hoverBackgroundColor: _colors.hoverBackgroundColor[idx],
                    label: _displayLegend ? Translation.translate(vm.chartDatasetLabels[idx]) : '',
                });
            });
        } else if (includes(['pie', 'doughnut'], vm.chartType)) {
            chartData.datasets.push({
                backgroundColor: _colors.backgroundColor,
                // eslint-disable-next-line id-blacklist
                data: datasetsValues,
                hoverBackgroundColor: _colors.hoverBackgroundColor,
            });
        }

        return chartData;
    }

    /**
     * Init chart element.
     *
     * @return {Promise} The chart element initialization promise.
     */
    function _initChart() {
        const chartCanvas = $element.find('canvas')[0].getContext('2d');

        return $q((resolve) => {
            _chartModulePromise.then(({ default: Chart }) => {
                if (_chartElement) {
                    resolve(_chartElement);

                    return;
                }

                _chartElement = new Chart(chartCanvas, {
                    // eslint-disable-next-line id-blacklist
                    data: _chartData,
                    options: vm.chartOptions,
                    plugins: _chartPlugins,
                    type: vm.chartType,
                });

                vm.is.initialized = true;
                resolve(_chartElement);
            });
        });
    }

    /**
     * Init chart options for widget analytics.
     */
    function _initChartOptions() {
        if (angular.isUndefinedOrEmpty(get(vm.chartOptions, 'legend.display'))) {
            set(vm.chartOptions, 'legend.display', false);
        }

        // If we want to display the legend, we need dataset label translation.
        if (!vm.chartOptions.legend.display || angular.isUndefinedOrEmpty(vm.chartDatasetLabels)) {
            _displayLegend = false;
        } else {
            _displayLegend = true;
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if chart is visible.
     * - Always true for bar and line chart.
     * - True for pie and doughnut chart if all data are equal to 0.
     *
     * @return {boolean} If the chart is visible.
     */
    function isChartVisible() {
        if (includes(['line', 'bar'], vm.chartType)) {
            return true;
        }

        return loFind(get(_chartData, 'datasets[0].data'), function checkData(chartData) {
            return angular.isDefinedAndFilled(chartData) && chartData > 0;
        });
    }

    /////////////////////////////

    vm.isChartVisible = isChartVisible;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any data change to refresh data and update chart.
     */
    $scope.$watch('vm.chartData', async (newValue, previousValue) => {
        if (angular.isUndefinedOrEmpty(vm.chartData) || previousValue === vm.chartData) {
            return;
        }

        // Refresh data with new values.
        _chartData = vm.preformatted ? vm.chartData : _getChartData();

        const chartElement = await _initChart();

        // Inject new data in the canvas.
        // eslint-disable-next-line id-blacklist
        chartElement.chart.config.data = _chartData;

        // Update chart.
        chartElement.update();
    });

    /////////////////////////////

    /**
     * Initialize controller.
     */
    function init() {
        // eslint-disable-next-line no-inline-comments
        _chartModulePromise = import(/* webpackChunkName: "chart.js", webpackPrefetch: true */ 'chart.js/dist/Chart');

        vm.chartOptions = vm.chartOptions || angular.fastCopy(Charts.chartOptions);
        if (angular.isUndefinedOrEmpty([vm.chartData, vm.chartType], 'some')) {
            return;
        }

        _chartData = vm.preformatted ? vm.chartData : _getChartData();

        _initChartOptions();

        _initChart();
    }

    init();
}

/////////////////////////////

/**
 * Chart directive.
 * Display a chart of different type, generated by chartJS from data, with optional colors and options.
 *
 * @param {Array}   [chartColors]                      Colors for each chart dataset, if it's not defined or
 *                                                     doesn't contain enougt colors, we ask Chart service to
 *                                                     generate theme.
 * @param {Object}  chartData                          The data which the chart needs. Data is composed by an array
 *                                                     of datasets and an array of labels.
 * @param {Array}   chartDatasetLabels                 The dataset labels translation key. Used to display a
 *                                                     translated label in the chart tooltip and legend.
 * @param {Object}  [chartOptions=Charts.chartOptions] Custom chart options. If is undefined, use Chart service
 *                                                     default options.
 * @param {string}  chartType                          Type of the chart: pie, bar, line, doughnut.
 * @param {boolean} [preformatted=false]               Whether the data is already in `chart.js` format.
 */

function ChartDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: ChartController,
        controllerAs: 'vm',
        replace: true,
        restrict: 'AE',
        scope: {
            chartColors: '<?lsChartColors',
            chartData: '=lsChartData',
            chartDatasetLabels: '=?lsChartDatasetLabels',
            chartOptions: '<?lsChartOptions',
            chartType: '@lsChartType',
            preformatted: '<?lsPreformatted',
        },
        templateUrl: '/client/common/modules/chart/views/chart.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsChart', ChartDirective);

/////////////////////////////

export { ChartController, ChartDirective };