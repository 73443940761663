import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { Divider as LumxDivider, Size } from '@lumapps/lumx/react';
import type { ElementRender } from '@lumapps/wrex/types';

import { DIVIDER } from '../../../constants';
import type { DividerElement } from '../../../types';

export const CLASSNAME = 'wrex-content-divider';

/**
 * Display a divider element
 */
export const Divider: ElementRender<DividerElement, HTMLHRElement> = (props) => {
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const { block } = useClassnames(CLASSNAME);

    return (
        <div className={block([className, margin('bottom', Size.huge)])} ref={elementRef} {...forwardedProps}>
            <LumxDivider />
            {children}
        </div>
    );
};

Divider.displayName = DIVIDER;
