import { translate as t } from 'components/translations';

const DOMAIN = 'FORM';

const SET_FORM_FIELD_VALUE = `${DOMAIN}/SET_FORM_FIELD_VALUE`;
const SET_FORM_FIELD_VALUES = `${DOMAIN}/SET_FORM_FIELD_VALUES`;

const SET_FIELD_ERROR = `${DOMAIN}/SET_FIELD_ERROR`;
const SET_FORM_ERRORS = `${DOMAIN}/SET_FORM_ERRORS`;
const SET_FIELD_META = `${DOMAIN}/SET_FIELD_META`;
const RESET_FORM = `${DOMAIN}/RESET_FORM`;

const mapActionsToNamespace = (namespace) => ({
    resetForm: () => ({
        type: `${namespace}/${RESET_FORM}`,
    }),

    setFormErrors: (errors) => ({
        payload: {
            errors,
        },
        type: `${namespace}/${SET_FORM_ERRORS}`,
    }),

    setFormFieldError: (fieldId, hasError, errorMessage = t('GLOBAL.INVALID_FIELD')) => ({
        payload: {
            errorMessage,
            fieldId,
            hasError,
        },
        type: `${namespace}/${SET_FIELD_ERROR}`,
    }),

    setFormFieldMeta: (fieldId, metaName, metaValue) => ({
        payload: {
            fieldId,
            metaName,
            metaValue,
        },
        type: `${namespace}/${SET_FIELD_META}`,
    }),

    setFormFieldValue: (fieldId, value) => ({
        payload: {
            fieldId,
            value,
        },
        type: `${namespace}/${SET_FORM_FIELD_VALUE}`,
    }),

    setFormFieldValues: (values) => ({
        payload: {
            values,
        },
        type: `${namespace}/${SET_FORM_FIELD_VALUES}`,
    }),
});

export {
    DOMAIN,
    SET_FORM_FIELD_VALUE,
    SET_FIELD_ERROR,
    RESET_FORM,
    SET_FORM_ERRORS,
    SET_FIELD_META,
    SET_FORM_FIELD_VALUES,
    mapActionsToNamespace,
};
