/* eslint-disable no-param-reassign */
import difference from 'lodash/difference';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import { Article, ArticlePreviewState } from '../types';

export const initialState: ArticlePreviewState = {};

export const { actions, reducer } = createSlice({
    /** Action type prefix. */
    domain: 'articlePreview',
    initialState,
    reducers: {
        pushBatchIdArticle: (state: ArticlePreviewState, action: PayloadAction<string>) => {
            const articleBatchIds = [...(state.articleBatchIds || [])];
            articleBatchIds.push(action.payload);
            state.articleBatchIds = articleBatchIds;
        },
        setArticleEntities: (state: ArticlePreviewState, action: PayloadAction<Record<string, Article | null>>) => {
            if (!state.entities) {
                state.entities = {};
            }

            state.entities = { ...state.entities, ...action.payload };
        },
        deleteBatchIdArticle: (state: ArticlePreviewState, action: PayloadAction<string[]>) => {
            state.articleBatchIds = state.articleBatchIds && difference([...state.articleBatchIds], action.payload);
        },
    },
});
