(function IIFE() {
    'use strict';

    /////////////////////////////

    function ContentFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/:methodKey', {}, {
            'archive': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'archive',
                },
            },

            'delete': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            'deleteMulti': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'deleteMulti',
                },
            },

            'follow': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'follow',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/:id/:methodKey',
            },

            'get': {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/:methodKey',
            },

            'like': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'like',
                },
            },

            'list': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'list',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/:methodKey',
            },

            'save': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },

            'setHomePage': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'setHomepage',
                },
            },

            'unarchive': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'unarchive',
                },
            },

            'unfollow': {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'unfollow',
                },
                url: Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/:id/:methodKey',
            },

            'unlike': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'unlike',
                },
            },

            'updateStatus': {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'updateStatus',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('ContentFactory', ContentFactory);
})();
