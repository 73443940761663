import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function MediaManagerController(AbstractPicker, Config, Features, MediaConstant, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Generate a random media picker id.
         *
         * @type {string}
         */
        vm.mediaPickerId = generateUUID();

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Config = Config;
        vm.MediaConstant = MediaConstant;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Opens the media picker.
         */
        function openMediaPicker() {
            Utils.waitForAndExecute(`#${vm.mediaPickerId}`, AbstractPicker);
        }

        /////////////////////////////

        vm.openMediaPicker = openMediaPicker;
    }

    /////////////////////////////

    function MediaManagerDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: MediaManagerController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                multi: '=?lsMulti',
                ngModel: '=',
            },
            templateUrl: '/client/common/modules/media-manager/views/media-manager.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('lsMediaManager', MediaManagerDirective);
})();
