(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetUserListSettingsController($rootScope, $scope, $timeout, Content, Feed, User, UserPicker, Utils,
        Widget) {
        'ngInject';

        var widgetUserListSettings = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * The list key for the users list.
         *
         * @type {string}
         */
        var _listKey = 'widget-';

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the user picker in the settings sidebar.
         *
         * @type {string}
         * @constant
         */
        widgetUserListSettings.PICKER_ID = 'widget-user-list-picker-';

        /**
         * Contains the list of field pickable for ordering the users list.
         *
         * @type {Array}
         */
        widgetUserListSettings.listOrderSelection = [
            'registrationDate',
            'email',
            'firstName',
            'lastName',
        ];

        /**
         * Contains the list of picked users id.
         *
         * @type {Array}
         */
        widgetUserListSettings.userList = [];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        widgetUserListSettings.Feed = Feed;
        widgetUserListSettings.User = User;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Open the user picker.
         */
        function openUserPicker() {
            Utils.waitForAndExecute('#' + widgetUserListSettings.PICKER_ID, UserPicker);
        }

        /**
         * Update the widget each time the settings changes.
         */
        function updateWidget() {
            $timeout(function delayUpdateEventBroadcast() {
                $rootScope.$broadcast('widget-user-list-settings', Widget.getCurrent().uuid);
            });
        }

        /////////////////////////////

        widgetUserListSettings.openUserPicker = openUserPicker;
        widgetUserListSettings.updateWidget = updateWidget;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the user picker closes, update the widget's picked users.
         *
         * @param {Event}  evt      The picker close event.
         * @param {string} pickerId The id of the picker beeing closed.
         */
        $scope.$on('user-picker__close-start', function onUserPickerCloseStart(evt, pickerId) {
            if (widgetUserListSettings.PICKER_ID === pickerId) {
                Widget.getCurrent().properties.usersIds = _.map(widgetUserListSettings.userList, 'id');
            }

            widgetUserListSettings.updateWidget();
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            var currentContent = Content.getCurrent();
            var currentWidget = Widget.getCurrent();

            if (angular.isDefinedAndFilled(_.get(currentContent, 'id'))) {
                _listKey = 'widget-' + currentWidget.uuid + '-' + currentContent.id;
            } else {
                /*
                 * Note: for a new content, the widget_directive generates a uuid on the fly.
                 * Unfortunately, we cannot retrieve that key from the settings here other than searching for it here.
                 */
                var userListKeys = Object.keys(User._services);

                // We want to find the listKey of the user list widget associated with these settings.
                var widgetUserListKey = _.find(userListKeys, function findListForCurrentWidget(listKey) {
                    return _.startsWith(listKey, ('widget-' + currentWidget.uuid));
                });

                if (angular.isDefinedAndFilled(widgetUserListKey)) {
                    _listKey = widgetUserListKey;
                }
            }

            widgetUserListSettings.PICKER_ID += currentWidget.uuid;

            currentWidget.properties = currentWidget.properties || {};
            var properties = currentWidget.properties;

            if (angular.isUndefinedOrEmpty(properties.type)) {
                properties.type = 'pick';
            }

            if (properties.type !== 'pick' || !angular.isArray(properties.usersIds) ||
                angular.isUndefinedOrEmpty(properties.usersIds)) {
                return;
            }

            _listKey += '-' + properties.type;

            widgetUserListSettings.isCallInProgress = User.isCallInProgress(_listKey);

            if (!widgetUserListSettings.isCallInProgress) {
                widgetUserListSettings.userList = User.displayList(_listKey);

                return;
            }

            var unregisterWatcher = $scope.$watch(function isUserCallInProgressWatcher() {
                return User.isCallInProgress(_listKey);
            }, function isUserCallInProgressWatch(isCallInProgress) {
                widgetUserListSettings.isCallInProgress = isCallInProgress;

                if (!widgetUserListSettings.isCallInProgress) {
                    widgetUserListSettings.userList = User.displayList(_listKey);

                    if (angular.isFunction(unregisterWatcher)) {
                        unregisterWatcher();
                        unregisterWatcher = undefined;
                    }
                }
            });
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('WidgetUserListSettingsController', WidgetUserListSettingsController);
})();
