import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { Badge, ColorPalette, Text, Typography } from '@lumapps/lumx/react';

import { MAX_NOTIFICATION_NUMBER } from '../../constants';
import { useNotificationLabel } from '../../hooks/useNotificationLabel';

export type NotificationCountProps = {
    /** The number of notification */
    count: number;
    /** Custom? className */
    className?: string;
};

const NotificationCount: React.FC<NotificationCountProps> = ({ count = 0, className }) => {
    const accessibleLabel = useNotificationLabel(count);

    if (count <= 0) {
        return null;
    }

    // If we have more than the max notifications, show max+
    const label = count > MAX_NOTIFICATION_NUMBER ? `${MAX_NOTIFICATION_NUMBER}+` : count;

    return (
        <Badge className={className} color={ColorPalette.primary}>
            <Text as="p" typography={Typography.overline}>
                <Text as="span" aria-hidden="true">
                    {label}
                </Text>
                <Text as="span" className={visuallyHidden()}>
                    {accessibleLabel}
                </Text>
            </Text>
        </Badge>
    );
};

export { NotificationCount };
