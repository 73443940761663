import { Targets, useIsComponentDisabled } from '@lumapps/customizations/api';

export interface UseIsNavigationDisabledResult {
    /**
     * whether the entire main navigation is disabled or not, which means that
     * neither the data nor the UI should be fetched
     */
    isNavigationDisabled: boolean;
    /**
     * whether the UI for the main navigation is disabled or not, which means that
     * the data should be fetched but the UI should not be displayed
     */
    isNavigationUiDisabled: boolean;
    /**
     * whether the entire sub main navigation is disabled or not, which means that
     * neither the data nor the UI should be fetched
     */
    isSubNavigationDisabled: boolean;
    /**
     * whether the UI for the sub main navigation is disabled or not, which means that
     * the data should be fetched but the UI should not be displayed
     */
    isSubNavigationUiDisabled: boolean;
}

/**
 * Evaluates the different conditions that need to occur in order to have a navigation
 * disabled in the site.
 */
export const useIsNavigationDisabled = (): UseIsNavigationDisabledResult => {
    const isNavigationDisabled = useIsComponentDisabled(Targets.NAVIGATION);
    const isSubNavigationDisabled = useIsComponentDisabled(Targets.SUB_NAVIGATION);
    const isNavigationUiDisabled = useIsComponentDisabled(Targets.NAVIGATION_UI);
    const isSubNavigationUiDisabled = useIsComponentDisabled(Targets.SUB_NAVIGATION_UI);

    return {
        isNavigationDisabled,
        isSubNavigationDisabled,
        isNavigationUiDisabled,
        isSubNavigationUiDisabled,
    };
};
