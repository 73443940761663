import React from 'react';

import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';

import { padding } from '@lumapps/classnames';
import {
    MetadataPickerField,
    MetadataPickerFieldProps,
} from '@lumapps/metadata-pickers/components/MetadataPickerField';
import { MetadataPickerFieldMultiple } from '@lumapps/metadata-pickers/components/MetadataPickerFieldMultiple';
import { PickerMetaData } from '@lumapps/metadata-pickers/types';
import { Metadata } from '@lumapps/metadata/types';
import { useTranslate } from '@lumapps/translations';

import { useFormField } from '../../hooks/useFormField';
import { BaseInput } from '../../types';

export interface FormMetadataFieldProps
    extends Omit<MetadataPickerFieldProps, 'onChange' | 'onBlur' | 'value' | 'choices' | 'familyId'> {
    family: Metadata;
    /** Optional callback for MetadataPickerSimple selected selector. Takes current value as param and returns
     * a callback used as the `choiceSelectedSelector` props of the MetadataPickerSimple. */
    choiceSelectedSelectorWithValue?: (
        metadataCurrentValue?: string,
    ) => MetadataPickerFieldProps['choiceSelectedSelector'];
}

/**
 * Metadata field in order to use in a context of a Form
 * @param FormMetadataFieldProps
 * @returns FormMetadataField
 */
export const FormMetadataField: React.FC<FormMetadataFieldProps & Omit<BaseInput, 'label'>> = ({
    controllerProps,
    family,
    name,
    choiceSelectedSelectorWithValue,
    showMultipleLevels,
    ...props
}) => {
    const { translateObject } = useTranslate();
    const label = translateObject(family.name) || `metadatas.${family.id}`;
    const { field, valueToUse = [] } = useFormField<Metadata[] | Metadata | undefined>({
        name,
        controllerProps,
        label,
    });

    /** MetadataPickerSimple's onChange can be called with undefined. in this case, change it to null so it works
     * correctly with react-hook-forms */
    const onMetadataPickerSimpleChange = React.useCallback(
        (e?: PickerMetaData) => {
            field.onChange(e === undefined ? null : e);
        },
        [field],
    );

    const baseProps = {
        label,
        familyId: family.id,
        onBlur: field.onBlur,
        showMultipleLevels,
        instanceId: family.instance,
    };

    if (family.multiple && isArray(valueToUse)) {
        const value = valueToUse?.map((metadata) => metadata.id);
        return (
            <MetadataPickerFieldMultiple
                {...props}
                {...baseProps}
                onChange={field.onChange}
                value={value}
                // fields displayed inside a form should have no padding since distances between
                // fields are managed by the fieldset they are displayed in. This removes the
                // default padding for the Select
                className={padding('vertical', null)}
                defaultDictionary={keyBy(valueToUse, 'id')}
            />
        );
    }

    /**
     * Sometimes the backend sends over a list of metadata but there is only one selected since it
     * is not multiple. This is probably because the metadata used to be multiple selection and now
     * it was changed to single selection.
     *
     * Because of this reason, if it is an array, we retrieve the first one.
     */
    const metadataToUse = (isArray(valueToUse) ? valueToUse[0] : valueToUse) as Metadata | undefined;
    const pickerSimpleValue = metadataToUse ? metadataToUse.id : undefined;

    // If choiceSelectedSelectorWithValue is set, call it with current value to get the choiceSelectedSelector callback
    // and pass it as a props for MetadataPickerSimple
    if (choiceSelectedSelectorWithValue) {
        (baseProps as Partial<MetadataPickerFieldProps>).choiceSelectedSelector =
            choiceSelectedSelectorWithValue(pickerSimpleValue);
    }

    return (
        <MetadataPickerField
            {...props}
            {...baseProps}
            // fields displayed inside a form should have no padding since distances between
            // fields are managed by the fieldset they are displayed in. This removes the
            // default padding for the Select
            className={padding('vertical', null)}
            onChange={onMetadataPickerSimpleChange}
            value={pickerSimpleValue}
            defaultDictionary={
                metadataToUse
                    ? {
                          [metadataToUse.id]: metadataToUse,
                      }
                    : undefined
            }
        />
    );
};
