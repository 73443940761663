import * as React from 'react';
import * as propTypes from 'prop-types';

import { RadioButton, RadioGroup } from '@lumapps/lumx/react';

import * as types from '../../types';
import * as uiTypes from '../../ui/types';
import { translate as t } from '../../../translations';

/**
 * Renders a form that switches between light and dark themes.
 */
export class ThemeSettings extends React.PureComponent {
    static propTypes = {
        name: types.properties.isRequired,
        /** Called when the `value` changes, with `(value, name, payload)`. */
        onChange: propTypes.func.isRequired,
        /** Currently selected theme. */
        value: uiTypes.theme,
    };

    render() {
        const { value, name: key, onChange } = this.props;
        const changeHandler = (newValue) => onChange(newValue, key);

        return (
            <RadioGroup className="p+">
                <RadioButton
                    checked={!value || value === 'light'}
                    helper={t('FRONT.STYLE.LIGHT_THEME.HELPER')}
                    label={t('STYLE.LIGHT_THEME')}
                    name={key}
                    value="light"
                    onChange={changeHandler}
                />
                <RadioButton
                    checked={value === 'dark'}
                    helper={t('FRONT.STYLE.DARK_THEME.HELPER')}
                    label={t('STYLE.DARK_THEME')}
                    name={key}
                    value="dark"
                    onChange={changeHandler}
                />
            </RadioGroup>
        );
    }
}
