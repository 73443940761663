import { LegacyCalendar } from '../types';
import { getFetchParams } from './getFetchParams';

/**
 * Extract fetchParams for each selected calendars.
 * Return a list of fetchParams used to trigger a new API call.
 * */
export const getFetchParamsList = (
    selectedCalendars: LegacyCalendar[],
    dateRange: [Date, (Date | undefined)?],
    maxResults: number,
) => {
    return selectedCalendars.map((calendar) => getFetchParams(calendar.id, maxResults, ...dateRange));
};
