import last from 'lodash/last';

import { Editor, Element, Path, PathRef, Transforms } from '@lumapps/wrex/slate';
import { getSiblingPath } from '@lumapps/wrex/slate/utils/getSibling';
import type { Wrex } from '@lumapps/wrex/types';

import { ListItem } from '../../components/blocks/ListItem';
import { ListType } from '../../types';
import { isListItem } from '../../utils/isListItem';
import { decreaseListIndent } from './decreaseListIndent';

/**
 * Set list item list type.
 */
function changeListItemListType(editor: Editor, listType: ListType, listItemPathRef: PathRef) {
    const originalPath = listItemPathRef.current as Path;
    const [list] = Editor.parent(editor, originalPath);
    const listLength = list.children.length;
    if (listLength > 1) {
        const position = last(originalPath);
        if (position !== 0) {
            Transforms.splitNodes(editor, { at: listItemPathRef.current as Path });
        }
        if (position !== listLength - 1) {
            Transforms.splitNodes(editor, { at: getSiblingPath(listItemPathRef.current as Path, 'after') as Path });
        }
    }
    const parentPath = Editor.parent(editor, listItemPathRef.current as Path)[1];
    Transforms.setNodes(editor, { type: listType } as Wrex.Element, { at: parentPath });
}

/**
 * Move element content in a new list item of given list type.
 */
function moveToListType(
    editor: Editor,
    listType: ListType,
    pathRef: PathRef,
    element: Element,
    defaultElementType: string,
) {
    const insertionPath = pathRef.current as Path;
    Transforms.insertNodes(
        editor,
        {
            type: listType,
            children: [
                {
                    type: ListItem.displayName,
                    children: [{ type: defaultElementType, children: [] } as Wrex.Element],
                } as Wrex.Element,
            ],
        } as Wrex.Element,
        { at: insertionPath },
    );

    const elementPath = pathRef.current as Path;
    const newPath = [...insertionPath, 0, 0, 0];
    // Move all children to the new list.
    for (let i = element.children.length - 1; i >= 0; i--) {
        editor.apply({
            type: 'move_node',
            path: [...elementPath, i],
            newPath,
        });
    }
    Transforms.removeNodes(editor, { at: pathRef.current as Path });
}

/**
 * Set current editor selection to list items of the given list type.
 *
 * @param editor             The editor
 * @param defaultElementType The element type to use when moving selection of any list.
 * @param listType           The list type in which the selection will be turned to; or null to move the selection out of any list.
 */
export function setListType(editor: Editor, defaultElementType: string, listType: ListType | null) {
    if (!editor.selection) {
        return false;
    }

    if (listType === null) {
        while (
            decreaseListIndent(editor)
            // eslint-disable-next-line no-empty
        ) {}
        return true;
    }

    const elements = Array.from(
        Editor.nodes(editor, {
            match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
            mode: 'lowest',
        }),
        ([node, path]): [Element, PathRef] => [node as Element, Editor.pathRef(editor, path)],
    );

    Editor.withoutNormalizing(editor, () => {
        for (const [node, pathRef] of elements) {
            const [parent, parentPath] = Editor.parent(editor, pathRef.current as Path);
            if (isListItem(parent)) {
                const parentPathRef = Editor.pathRef(editor, parentPath);
                changeListItemListType(editor, listType, parentPathRef);
                parentPathRef.unref();
            } else {
                moveToListType(editor, listType, pathRef, node, defaultElementType);
            }
            pathRef.unref();
        }
    });
    return true;
}
