import BaseApi from '@lumapps/base-api';
import { PRIORITY } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { MAX_ASIDE_SEARCH_RESULTS, ENGINES, SEARCH_API_TIMEOUT } from '../constants';
import { LumworkSearchResult, SearchResults, SearchParams, SearchTab } from '../types';
import { createSearchEngineFilter, createSearchEngineMetadata } from '../utils';

const lumworkApi = new BaseApi({ path: 'lumwork/document' });

export interface LumworkSearchResults extends SearchResults {
    items: LumworkSearchResult[];
}

/**
 * Returns a promise with the search results from the lumwork API.
 * @param params parameters that will be used to search on Lumwork
 */
const search = (params: SearchParams): Promise<LumworkSearchResults> => {
    const { query, maxResults = MAX_ASIDE_SEARCH_RESULTS, timeout = SEARCH_API_TIMEOUT } = params;

    return lumworkApi
        .getCacheFirst<LumworkSearchResults>('search', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
            params: { maxResults, searchPattern: decodeURIComponentSafe(query) },
            // LumWork's API is extremely slow, but it responds, so we increase the timeout a little bit
            timeout: timeout * 2,
        })
        .then((response) => {
            const { items = [], more } = response.data;

            /**
             * Since LumWork does not return the selected filters from the backend,
             * we need to add them to the response so that the UI shows them appropriately.
             *
             * So we add the necessary information (filters and metadata) so that the UI
             * can properly render the results.
             */
            const filters = [createSearchEngineFilter(ENGINES.LUMWORK)];

            const metadata: Record<string, SearchTab> = {
                [ENGINES.LUMWORK]: createSearchEngineMetadata(ENGINES.LUMWORK),
            };

            return { items, more, filters, metadata } as LumworkSearchResults;
        });
};

export { lumworkApi, search };
