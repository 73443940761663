import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, FlexBoxProps, Orientation, Size, Theme } from '@lumapps/lumx/react';
import { useDimensions } from '@lumapps/responsive';

import { Author } from './Author';
import { BlockVideoContextProvider } from './BlockVideoContext';
import { Button } from './Button';
import { CLASSNAME } from './constants';
import { Content } from './Content';
import { Date as VideoDate } from './Date';
import { Description } from './Description';
import { Figure } from './Figure';
import { GroupDetails } from './GroupDetails';
import { Link } from './Link';
import { Metadata } from './Metadata';
import { Player } from './Player';
import { SiteDetails } from './SiteDetails';
import { Status } from './Status';
import { Thumbnail } from './Thumbnail';
import { Title } from './Title';
import type { Video, VisibleElement } from './types';
import { Views } from './Views';

const breakpoints: Record<Exclude<Orientation, 'auto'>, number> = { vertical: 0, horizontal: 550 };

type BaseBlockVideoProps = Pick<FlexBoxProps, 'as' | 'className' | 'orientation'> & {
    /** Vertical alignment. */
    alignment?: FlexBoxProps['hAlign'];

    /** Component children */
    children: React.ReactNode;

    /** Whether the component is pending or not (will display components skeleton) */
    isLoading?: boolean;

    /** UI theme */
    theme?: Theme;

    /** Video */
    video?: Video;

    /** Elements to display */
    visibleElements?: VisibleElement[];
};

type ReadyBlockVideoProps = BaseBlockVideoProps & { isLoading?: false; video: Video };

type LoadingBlockVideoProps = BaseBlockVideoProps & { isLoading: true };

export type BlockVideoProps = ReadyBlockVideoProps | LoadingBlockVideoProps;

export const BlockVideo = ({
    alignment,
    as = 'article',
    children,
    className,
    isLoading,
    orientation: orientationProp,
    theme = Theme.light,
    video,
    visibleElements,
}: BlockVideoProps) => {
    const { block } = useClassnames(CLASSNAME);
    const { ref, currentBreakpoint: autoOrientation } = useDimensions({ breakpoints });

    if (Array.isArray(visibleElements) && !visibleElements.length) {
        return null;
    }

    const orientation = orientationProp ?? autoOrientation;

    return (
        <BlockVideoContextProvider
            orientation={orientation}
            theme={theme}
            visibleElements={visibleElements}
            // Calculating props this way is needed to make Typescript happy 😬
            {...(isLoading ? { isLoading } : { video })}
        >
            <FlexBox
                as={as}
                className={block([className])}
                gap={Size.big}
                orientation={orientation}
                ref={ref}
                hAlign={alignment}
            >
                {children}
            </FlexBox>
        </BlockVideoContextProvider>
    );
};

BlockVideo.Author = Author;
BlockVideo.Button = Button;
BlockVideo.Content = Content;
BlockVideo.Date = VideoDate;
BlockVideo.Description = Description;
BlockVideo.Figure = Figure;
BlockVideo.GroupDetails = GroupDetails;
BlockVideo.Link = Link;
BlockVideo.Metadata = Metadata;
BlockVideo.Player = Player;
BlockVideo.SiteDetails = SiteDetails;
BlockVideo.Status = Status;
BlockVideo.Thumbnail = Thumbnail;
BlockVideo.Title = Title;
BlockVideo.Views = Views;
