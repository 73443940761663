import { ColorPalette, ColorVariant } from '@lumapps/lumx/react';
import { reviews } from '@lumapps/reports/routes';
import { Link } from '@lumapps/router';
import { PluralTypes, getPluralType } from '@lumapps/translations';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta } from '../../types';
import { getContextTitle, getContentTitle, getParentContentTitle, getContentPostDetailTitle } from '../contentUtils';
import { getCommentLink, getEmptyRoute, getPostLink } from '../linksUtils';

const notificationTypePost: Record<string, NotificationMeta> = {
    [NotificationTypes.PostMention]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'message-text',
        },
        getDescription: ({ notification, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key = contentTitle
                ? NOTIFICATIONS_CENTER.POST_MENTION_WITH_TITLE_DESCRIPTION
                : NOTIFICATIONS_CENTER.POST_MENTION_WITHOUT_TITLE_DESCRIPTION;

            return translation.translateAndReplace(key, {
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getCommentLink,
    },
    [NotificationTypes.PostModerationDecline]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.green,
            icon: 'check',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.POST_MODERATION_DECLINE_DESCRIPTION, {
                POST_TITLE: getContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    [NotificationTypes.PostModerationDelete]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.yellow,
            icon: 'message-alert',
        },
        getDescription: ({ count, notification, translation }) => {
            const translationKeys = {
                [PluralTypes.ONE]: NOTIFICATIONS_CENTER.POST_MODERATION_DELETE_ONE_DESCRIPTION,
                [PluralTypes.MORE]: NOTIFICATIONS_CENTER.POST_MODERATION_DELETE_MORE_DESCRIPTION,
                [PluralTypes.TWO]: NOTIFICATIONS_CENTER.POST_MODERATION_DELETE_MORE_DESCRIPTION,
            };

            return translation.translateAndReplace(translationKeys[getPluralType(count)], {
                COMMUNITY_NAME: getContentTitle(translation, notification),
                REPORT_COUNT: notification?.actionCount || 0,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    [NotificationTypes.PostModerationDeleteSpammer]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.yellow,
            icon: 'message-alert',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.POST_MODERATION_DELETE_SPAMMER_DESCRIPTION, {
                POST_TITLE: getContextTitle(translation, notification),
                COMMUNITY_NAME: getContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    [NotificationTypes.PostNew]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'message-text',
        },
        getDescription: ({ notification, translation, senderDetails, currentLanguage }) => {
            const postTitle = getContentTitle(translation, notification);
            const key = postTitle
                ? NOTIFICATIONS_CENTER.POST_NEW_WITH_TITLE_DESCRIPTION
                : NOTIFICATIONS_CENTER.POST_NEW_DESCRIPTION;

            return translation.translateAndReplace(key, {
                COMMUNITY_TITLE: getParentContentTitle(translation, notification),
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                POST_TITLE: postTitle,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    /**
     * LEGACY REPORT
     */
    [NotificationTypes.PostReportNew]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.yellow,
            icon: 'message-alert',
        },
        getDescription: ({ notification, translation }) => {
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.POST_REPORT_NEW_DESCRIPTION, {
                POST_TITLE: getContentTitle(translation, notification),
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
                REPORT_COUNT: notification?.actionCount || 0,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    [NotificationTypes.PostStatusUpdate]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'message-text',
        },
        getDescription: ({ notification, translation }) => {
            const postTitle = getContentTitle(translation, notification);
            const key = postTitle
                ? NOTIFICATIONS_CENTER.POST_STATUS_UPDATE_WITH_TITLE_DESCRIPTION
                : NOTIFICATIONS_CENTER.POST_STATUS_UPDATE_DESCRIPTION;

            return translation.translateAndReplace(key, {
                COMMUNITY_TITLE: getParentContentTitle(translation, notification),
                STATUS_NAME: translation.translate(getContentPostDetailTitle(notification)) || '',
                POST_TITLE: postTitle,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    [NotificationTypes.PostUpdate]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.primary,
            icon: 'message-text',
        },
        getDescription: ({ notification, translation, senderDetails, currentLanguage }) => {
            const postTitle = getContentTitle(translation, notification);
            const key = postTitle
                ? NOTIFICATIONS_CENTER.POST_UPDATE_WITH_TITLE_DESCRIPTION
                : NOTIFICATIONS_CENTER.POST_UPDATE_DESCRIPTION;

            return translation.translateAndReplace(key, {
                COMMUNITY_TITLE: getParentContentTitle(translation, notification),
                AUTHOR_NAME: getUserFullName(senderDetails, currentLanguage),
                POST_TITLE: postTitle,
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    /**
     * NEW REPORT
     */
    [NotificationTypes.PostReportCreated]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, count, senderDetails, translation, currentLanguage }) => {
            const contentTitle = getContentTitle(translation, notification);

            const translationKeys = {
                postWithoutTitle: {
                    [PluralTypes.ONE]: NOTIFICATIONS_CENTER.POST_REPORT_CREATED_ONE_DESCRIPTION,
                    [PluralTypes.MORE]: NOTIFICATIONS_CENTER.POST_REPORT_CREATED_MORE_DESCRIPTION,
                },
                postWithTitle: {
                    [PluralTypes.ONE]: NOTIFICATIONS_CENTER.POST_REPORT_CREATED_WITH_TITLE_ONE_DESCRIPTION,
                    [PluralTypes.MORE]: NOTIFICATIONS_CENTER.POST_REPORT_CREATED_WITH_TITLE_MORE_DESCRIPTION,
                },
            };

            const PluralType = count === 1 ? PluralTypes.ONE : PluralTypes.MORE;

            const key = contentTitle
                ? translationKeys.postWithTitle[PluralType]
                : translationKeys.postWithoutTitle[PluralType];

            return translation.translateAndReplace(key, {
                LAST_REPORTER_NAME: getUserFullName(senderDetails, currentLanguage),
                NB_OTHER_REPORTS: count - 1,
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: () => reviews(),
        getLinkAs: () => ({ linkAs: Link, to: reviews() }),
    },
    [NotificationTypes.PostReportReviewed]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, translation }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key = contentTitle
                ? NOTIFICATIONS_CENTER.POST_REPORT_REVIEWED_WITH_TITLE_DESCRIPTION
                : NOTIFICATIONS_CENTER.POST_REPORT_REVIEWED_DESCRIPTION;

            return translation.translateAndReplace(key, {
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
    [NotificationTypes.PostReportAccepted]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.dark,
            colorVariant: ColorVariant.L2,
            icon: 'message-alert-outline',
        },
        getDescription: ({ notification, translation }) => {
            const contentTitle = getContentTitle(translation, notification);

            const key = contentTitle
                ? NOTIFICATIONS_CENTER.POST_REPORT_ACCEPTED_WITH_TITLE_DESCRIPTION
                : NOTIFICATIONS_CENTER.POST_REPORT_ACCEPTED_DESCRIPTION;

            return translation.translateAndReplace(key, {
                POST_TITLE: contentTitle,
                COMMUNITY_NAME: getParentContentTitle(translation, notification),
            });
        },
        getRoute: getEmptyRoute,
        getLinkAs: getPostLink,
    },
};

export { notificationTypePost };
