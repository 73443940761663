import React from 'react';

import { AriaIdsProvider } from '@lumapps/aria-ids/components/AriaIdsProvider';

import { FeedArticle, FeedArticleProps } from './FeedArticle';

/**
 * Article within a Feed.
 * Defaults to an `article` html element, but it can be customized with the `as` prop.
 *
 * @family Feed
 */
const FeedArticleWithContext = React.forwardRef(
    <C extends React.ElementType>(props: FeedArticleProps<C>, ref: React.Ref<HTMLElement>) => {
        return (
            <AriaIdsProvider>
                <FeedArticle ref={ref} {...props} />
            </AriaIdsProvider>
        );
    },
);

export { FeedArticleWithContext as FeedArticle };
