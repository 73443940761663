import React from 'react';

import classNames from 'classnames';

import { Emphasis, IconButton, Placement, Size, Theme } from '@lumapps/lumx/react';

import { mdiCalendar, mdiClock, mdiReminder, mdiSortAscending, mdiSortDescending } from '@lumapps/lumx/icons';

import { translate as t } from '../../../../translations';

/**
 * Display the widget tasks header component.
 *
 * @param  {Object}  props The component's props.
 * @return {Element} The tasks react header element.
 */
const TaskHeader = ({
    isFilteredByLateTasks,
    isFilteredByReminderTasks,
    isFilteredByTodayTasks,
    isOrderedByDueDateDesc,
    provider,
    setIsFilteredByLateTasks,
    setIsFilteredByTodayTasks,
    setIsFilteredByReminderTasks,
    setIsOrderedByDueDateDesc,
    theme,
    uuid,
}) => {
    return (
        <div className="task-header">
            <IconButton
                className={classNames('mr', {
                    selected: isFilteredByLateTasks,
                })}
                color={theme === Theme.dark ? 'light' : undefined}
                emphasis={Emphasis.low}
                icon={mdiClock}
                size={Size.m}
                theme={theme}
                onClick={() => setIsFilteredByLateTasks({ data: !isFilteredByLateTasks, uuid })}
                label={t('FRONT.WIDGET_TASKS.TOOLTIPS.LATE_TASKS')}
                tooltipProps={{ placement: Placement.TOP }}
            />

            <IconButton
                className={classNames('mr', {
                    selected: isFilteredByTodayTasks,
                })}
                color={theme === Theme.dark ? 'light' : undefined}
                emphasis={Emphasis.low}
                icon={mdiCalendar}
                size={Size.m}
                theme={theme}
                onClick={() => setIsFilteredByTodayTasks({ data: !isFilteredByTodayTasks, uuid })}
                label={t('FRONT.WIDGET_TASKS.TOOLTIPS.TODAY_TASKS')}
                tooltipProps={{ placement: Placement.TOP }}
            />

            {provider === 'outlook' && (
                <IconButton
                    className={classNames('mr', {
                        selected: isFilteredByReminderTasks,
                    })}
                    color={theme === Theme.dark ? 'light' : undefined}
                    emphasis={Emphasis.low}
                    icon={mdiReminder}
                    size={Size.m}
                    theme={theme}
                    onClick={() => {
                        setIsFilteredByReminderTasks({ data: !isFilteredByReminderTasks, uuid });
                    }}
                    label={t('FRONT.WIDGET_TASKS.TOOLTIPS.REMINDER_TASKS')}
                    tooltipProps={{ placement: Placement.TOP }}
                />
            )}

            <IconButton
                color={theme === Theme.dark ? 'light' : undefined}
                emphasis={Emphasis.low}
                icon={isOrderedByDueDateDesc ? mdiSortDescending : mdiSortAscending}
                size={Size.m}
                theme={theme}
                onClick={() => {
                    setIsOrderedByDueDateDesc({ data: !isOrderedByDueDateDesc, uuid });
                }}
                label={t('FRONT.WIDGET_TASKS.TOOLTIPS.ORDER_TASKS')}
                tooltipProps={{ placement: Placement.TOP }}
            />
        </div>
    );
};

export default TaskHeader;
