/* istanbul ignore file */
import BaseApi, { BaseApiPromise, ServerListResponse } from '@lumapps/base-api';

import { Directory } from '../types';

export const directoryApi = new BaseApi({
    path: 'directory',
});

export const saveDirectory = (directory: Partial<Directory>): Promise<Directory> => {
    return directoryApi.post('save', directory).then((response) => {
        return response.data as Directory;
    });
};

export interface ListDirectoriesParams {
    instance: string;
    maxResults?: string;
    cursor?: string;
}

export const listDirectories = (params: ListDirectoriesParams) => {
    return directoryApi.get<ServerListResponse<Directory>>('/list', { params });
};

export interface GetDirectoryParams {
    uid: string;
    fields?: string;
}

export const get = (params: GetDirectoryParams): BaseApiPromise<Directory> => {
    return directoryApi.get('get', { params });
};
