import isEmpty from 'lodash/isEmpty';

import { Editor, Path, Range, ReactEditor, Transforms } from '@lumapps/wrex/slate';
import { focusAt } from '@lumapps/wrex/slate/utils/focusAt';

import type { LinkEditor, LinkElement } from '../types';
import { createLink } from './createLink';

export const wrapLink = (editor: Editor, url: string, nodePath?: Path, isALink?: boolean, label?: string) => {
    const { selection } = editor;
    const cleanedUrl = !isEmpty(url) ? new URL(url, window.location.origin).toString() : url;

    const isActive =
        isALink !== undefined || !selection
            ? Boolean(isALink)
            : (editor as LinkEditor).isLinkActive(Editor.node(editor, selection));

    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = createLink(
        {
            href: cleanedUrl,
            isInEditMode: cleanedUrl === '',
        },
        isCollapsed && !isActive ? [{ text: label || url }] : [],
    );
    const hasEmptyText = nodePath && Editor.string(editor, nodePath) === '';

    if (isCollapsed && !isActive) {
        Transforms.insertNodes(editor, link);

        // Move cursor out of the link after insertion
        if (cleanedUrl) {
            Transforms.move(editor, { distance: 1, unit: 'offset' });
        }
    } else if (isActive && hasEmptyText) {
        // If no text in edited link, then it's a new one, so we have to set by default the innerText
        Transforms.setNodes(editor, { href: cleanedUrl } as LinkElement, { at: nodePath });
        Transforms.insertText(editor, url, { at: nodePath });
        const endOfLink = editor.end(nodePath);

        focusAt(editor as ReactEditor, endOfLink);

        setTimeout(() => {
            Transforms.move(editor, { distance: 1, unit: 'offset' });
        }, 1);
    } else if (isActive) {
        // Update a link
        Transforms.setNodes(editor, { href: cleanedUrl } as LinkElement, { at: nodePath });
    } else {
        Transforms.wrapNodes(editor, link, { split: true, at: nodePath });
        Transforms.collapse(editor, { edge: 'end' });
    }

    if (label && nodePath) {
        Transforms.insertText(editor, label, { at: nodePath });
    }
};
