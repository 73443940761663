function ReusableMediaPickerService() {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Media picker instance controller.
     *
     * @type {Object}
     */
    service.mediaPicker = undefined;

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Sets the media picker instance controller.
     *
     * @param {Object} mediaPicker The media picker instance controller.
     */
    function setMediaPicker(mediaPicker) {
        service.mediaPicker = mediaPicker;
    }

    /**
     * Opens a media picker.
     *
     * @param {Object}   value    The currently selected media.
     * @param {Function} onChange Called whenever a new media is selected, with `(value)`.
     * @param {Function} options  Extra options provided to the media picker`.
     */
    function openMediaPicker(value, onChange, options) {
        if (angular.isUndefined(service.mediaPicker)) {
            throw new TypeError(
                'No media picker set. Make sure <reusable-media-picker /> is present in the DOM somewhere.',
            );
        }
        service.mediaPicker.open(value, onChange, options);
    }

    /////////////////////////////

    service.open = openMediaPicker;
    service.set = setMediaPicker;
}

/////////////////////////////

angular.module('Services').service('ReusableMediaPicker', ReusableMediaPickerService);

export { ReusableMediaPickerService };
