/**
 * An enum of cloudsearch template types.
 *
 * @type {Object}
 */
const TEMPLATE_TYPE = {
    GOOGLE_CALENDAR: 'calendar',
    GOOGLE_DRIVE: 'drive',
    GOOGLE_MAIL: 'mail',
};

/**
 * An object holding various options related to a user.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const CloudSearchConstant = {
    /**
     * An enum of drive location types. Used for Google Drive documents for ex.
     *
     * @type {Object}
     */
    DRIVE_LOCATION_TYPE: {
        DRIVE: 'DRIVE',
        TEAM_DRIVE: 'TEAM_DRIVE',
    },

    /**
     * An enum of share scope types. Used for Google Drive documents for ex.
     *
     * @type {Object}
     */
    DRIVE_SHARE_SCOPE_TYPE: {
        LIMITED: 'LIMITED',
        PRIVATE: 'PRIVATE',
        PUBLIC: 'PUBLIC',
        PUBLIC_WITH_LINK: 'PUBLIC_WITH_LINK',
    },

    /**
     * An enum of list identifiers used in the application.
     *
     * @type {Object}
     */
    LIST_KEY: {
        DATASOURCES: 'cloudsearch-datasources',
    },

    /**
     * An enum of projections used throughout the application.
     *
     * @type {Object}
     */
    PROJECTION: {},

    TEMPLATE_TYPE,
};

/////////////////////////////

angular.module('Constants').constant('CloudSearchConstant', CloudSearchConstant);

/////////////////////////////

export { CloudSearchConstant, TEMPLATE_TYPE };
