import React from 'react';

import './index.scss';
import { useClassnames } from '@lumapps/classnames';
import { Alignment, FlexBox, Orientation, Size, SkeletonCircle, SkeletonTypography } from '@lumapps/lumx/react';

const CLASSNAME = 'learning-leaderboard-podium';

/**
 * Skeleton for the podium item of the leaderboard compoenent
 *
 * @returns PodiumSkeleton
 */
export const PodiumSkeleton: React.FC = () => {
    const { element } = useClassnames(CLASSNAME);

    return (
        <FlexBox orientation={Orientation.horizontal} vAlign={Alignment.spaceAround}>
            <FlexBox
                orientation={Orientation.vertical}
                vAlign={Alignment.spaceBetween}
                wrap={false}
                className={element('wrapper')}
            >
                <SkeletonCircle size="m" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="120%" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="50%" />
            </FlexBox>
            <FlexBox
                orientation={Orientation.vertical}
                vAlign={Alignment.spaceBetween}
                wrap={false}
                className={element('wrapper')}
            >
                <SkeletonCircle size="l" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="120%" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="50%" />
            </FlexBox>
            <FlexBox
                orientation={Orientation.vertical}
                vAlign={Alignment.spaceBetween}
                wrap={false}
                className={element('wrapper')}
            >
                <SkeletonCircle size="m" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="120%" />
                <SkeletonTypography typography="subtitle2" size={Size.l} width="50%" />
            </FlexBox>
        </FlexBox>
    );
};
